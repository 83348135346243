import { Button } from "reactstrap";
import Widget from "../../../components/Widget/Widget";
import classNames from "classnames";
import sb from "./recepCot.module.scss";
import { useSelector } from "react-redux";
import Datatable from "../../../components/Datatable/Datatable";
import { useHistory } from "react-router-dom";
import { doRequest, doRequestSaveRes, findEmployee } from "../../../helpers/requests";
import { useEffect, useState } from "react";
import ModalViewFile from "../../../components/Modal/ModalViewFile";
import FullFormLoader from "../../../components/Loader/FullFormLoader";
import DetalleBien from "./forms/DetalleBien";
import ModalConfirmation from "../../../components/Modal/ModalConfirmation";
import CancelarSolicitud from "./forms/CancelarSolicitud";

const headers = [
  "INDICADOR",
  "CANTIDAD",
  "COSTO UNITARIO",
  "COSTO",
  "OBSERVACIONES",
  "DESCRIPCIÓN",
  "SERVICIO",
  "PERIODO",
  "ACCIONES",
];

const columnDefs = [
  {
    orderable: true,
    targets: [0, 1, 6, 7, 8],
    className: "text-center",
  },
  {
    orderable: true,
    targets: [4, 5],
    className: "text-justify",
  },
  {
    orderable: true,
    targets: [2, 3],
    className: "text-right",
  },
];

/* Anexo Tecnico Administrativo - 41
 * Solicitud Cotizacion - 42
 * Cotizacion - 43
 * Cuadro comparativo - 44
 * Cotizacion Autorizada - 45
 */

export default function DetalleSolicitud() {
  const [loading, setLoading] = useState(true);
  const [refresh, setRefresh] = useState(false);
  const [modalFile, setModalFile] = useState(false); ///Visualziar archivos
  const [anexo, setAnexo] = useState("");
  const [cuadro, setCuadro] = useState("");
  const [modalDetalle, setModalDetalle] = useState(false); ///Visualziar detalle de cada registro en la tabla
  const [selectedBien, setSelectedBien] = useState("");
  const [selectFile, setSelectFile] = useState("");
  const [modalConfirm, setModalConfirm] = useState(false); ///confirmar guardar
  const [modalRechazo, setModalRechazo] = useState(false); //Modala rechazo
  const [showButtons, setShowButtons] = useState(true); //Mostrar botones
  const history = useHistory();
  const { id } = useSelector((state) => state.userInfo);
  const [idStaff, setIdStaff] = useState(null);
  const idQuote = localStorage.getItem("quote");

  const columns = [
    { data: "idDetail" },
    { data: "amount" },
    { data: "unitCost", format: "currency" },
    { data: "total", format: "currency" },
    { data: "notes" },
    { data: "descripcion" },
    {
      data: "isService",
      render: function (d) {
        return <p>{+d === 1 ? "SI" : "NO"}</p>;
      },
    },
    { data: "idDetail" },
    {
      data: null,
      render: function (row) {
        return (
          <div>
            <i
              className="fa fa-eye cursor-pointer text-info"
              onClick={() => {
                setSelectedBien(row);
              }}
            />
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    if(idQuote === "") history.push("/template");
    getStaff();
    getFiles(44);
    getFiles(41);
  }, []);

  async function getStaff(){
    const emp = await findEmployee(id);
    if(emp){
        setIdStaff(emp.idStaff);
    }
  }


  /* Escucha - mostar archivos en modal */
  useEffect(() => {
    if (selectFile !== "") setModalFile(true);
  }, [selectFile]);

  useEffect(() => {
    if (!modalFile) setSelectFile("");
  }, [modalFile]);

  /* Escucha - mostar detalle de bien o servicio */
  useEffect(() => {
    if (selectedBien !== "") setModalDetalle(true);
  }, [selectedBien]);

  useEffect(() => {
    if (!modalDetalle) setSelectedBien("");
  }, [modalDetalle]);

  async function getFiles(type) {
    const params = {
      action: "multiselect",
      rows: "C.src AS fileA",
      table:
      `quotes A LEFT JOIN bookcase B ON A.idQuote = B.reference AND B.keyFileType = ${type} LEFT JOIN files C ON B.idBook = C.idBook`,
      conditions: `A.enabled = 1 AND A.idQuote=${idQuote} AND C.enabled = 1`,
    };
    const res = await doRequest("receiver/receiver.php", params, false, false);
    if (res.length > 0) {
      if(type===41) setAnexo(res[0].fileA);
      if(type===44) setCuadro(res[0].fileA);
    }
    setLoading(false);
  }

  async function getData(ordCol, itemsPerPage, currentPage, search) {
    const res = await doRequestSaveRes(
      "receiver/receiver.php",
      getParams(ordCol, itemsPerPage, currentPage, search),
      false
    );
    return res;
  }

  function getParams(ordCol, itemsPerPage, currentPage, search) {
    const params = {
      action: "datatable",
      table:
        "quotesdetail A LEFT JOIN furnitures B USING (idFurniture) LEFT JOIN services C USING (idService) LEFT JOIN servicetimes D USING (keyServiceTime)",
      rows: "A.idDetail, A.amount, A.unitCost, A.total, A.notes, ISNULL(A.idFurniture) AS isService, IF(ISNULL(A.idFurniture), C.nameService, B.nameFurniture) AS descripcion, A.idFurniture, A.idService,B.brand, B.model, A.taxies, D.nameServiceTime",
      conditions: `A.enabled=1 AND A.idQuote=${idQuote}`,
      order: ordCol,
      records: itemsPerPage.toString(),
      page: (itemsPerPage * currentPage).toString(),
      search,
    };
    return params;
  }

  async function chengeStat(stat) {
    const params = {
      action: "update",
      table: "quotes",
      rows: { keyStat: stat, idStaffRevision: idStaff },
      condition: { idQuote: idQuote },
    };
    setLoading(true);
    const res = await doRequest("receiver/receiver.php", params, true);
    if (res.length > 0) {
      setRefresh(true);
      setShowButtons(false);
    }
    setLoading(false);
  }

  return (
    <Widget className="widget-p-md">
      <div className="mb-4 mx-3 d-flex justify-content-between flex-wrap text-center ">
        <h2>Detalle de la Solicitud de Cotizaciones</h2>
        <button
          title="Regresar"
          onClick={() => {
            history.goBack();
          }}
          className={classNames(["btn text-light px-3", sb.btnBack])}
        >
          Regresar
        </button>
      </div>
      <div className=" text-center text-md-left mx-2 mb-4">
        <Button
          className="m-2"
          color="add"
          onClick={() => {
            setSelectFile(anexo);
          }}
        >
          <i className={classNames(["fa fa-file-text-o mr-2", sb.icBtn])} />
          Ver Anexo Tecnico Administrativo
        </Button>
        <Button
          className="m-2"
          color="add"
          onClick={() => {
            setSelectFile(cuadro);
          }}
        >
          <i className={classNames(["fa fa-table mr-2", sb.icBtn])} />
          Ver Cuadro Comparativo
        </Button>
      </div>

      <Datatable
        headers={headers}
        columns={columns}
        columnDefs={columnDefs}
        petition={getData}
        control="back"
        stateRefresh={[refresh, setRefresh]}
      />

      {showButtons && <div className="d-flex justify-content-around flex-wrap  mx-2 mt-4">
        <Button className="m-2" color="primary" onClick={() => {setModalConfirm(true)}}>
          <i className={classNames(["fa fa-save mr-2", sb.icBtn])} />
          Guardar
        </Button>
        <Button
          className="m-2"
          color="secondary"
          onClick={() => {
            setModalRechazo(true);
          }}
        >
          <i className={classNames(["fa fa-close mr-2", sb.icBtn])} />
          Rechazar
        </Button>
      </div>}

      <DetalleBien
        info={selectedBien}
        modal={modalDetalle}
        setModal={setModalDetalle}
        setRefresh={setRefresh}
        showButtons={showButtons}
      />

      <ModalViewFile
        modal={modalFile}
        setModal={setModalFile}
        backdrop={true}
        keyboard={true}
        btnClose={true}
        fileLink={selectFile}
      />

      <CancelarSolicitud
        idQuote={idQuote}
        modal={modalRechazo}
        setModal={setModalRechazo}
        setShowButtons={setShowButtons}
      />

      <ModalConfirmation
        modalTitle="Guardar cotización"
        modal={modalConfirm}
        setModal={setModalConfirm}
        editar={() => chengeStat(105)}
        isEdit={true}
      >
        <div className="d-flex justify-content-center">
          <h6>¿Está seguro de guardar esta cotización?</h6>
        </div>
      </ModalConfirmation>

      <FullFormLoader show={loading} />
    </Widget>
  );
}

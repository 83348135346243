import React, { useEffect, useState } from "react";
import Widget from "../../../components/Widget/Widget";
import Datatable from "../../../components/Datatable/Datatable";
import { GetPermissions } from "../../../libs/permissions/getPermissions";
import { toast } from "react-toastify";
import Notification from "../../../components/Notification/Notification";
import ModalDelete from "../../../components/Modal/ModalDelete";
import { peticionesReceiver } from "../../../helpers/peticionesReceiver";
import Loader from "../../../components/Loader/Loader";
import { Button, Row, Collapse, Col } from "reactstrap";
import FormCalendar from "./FormCalendar";
import FiltroCalendar from "./FiltroCalendar";
import { useFormik } from "formik";

const PercepcionesExtraordinarias = () => {
  const [permissions, setPermissions] = useState(true);
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [collapse, setCollapse] = useState("home"); //detail, add, home
  const [data, setdata] = useState("");

  GetPermissions(setPermissions, setLoading);
  const API = peticionesReceiver();

  const { values, setFieldValue } =
    useFormik({
      initialValues: {
        period: null,
        year: null,
      },
      onSubmit: () => {},
      enableReinitialize: true,
    });

  const onChange = (e) => {
    const { name, value } = e.target;
    setFieldValue(name, value);
  };

  useEffect(() => {
    setRefresh(true);
  }, [values.period, values.year]);

  const headers = [
    "#",
    "Periodo",
    "Año",
    "Fecha de inicio",
    "Fecha de fin",
    "Editar",
    "Eliminar",
  ];

  const columns = [
    { data: "index" },
    { data: "period" },
    { data: "year" },
    { data: "startDate" },
    { data: "endDate" },
    {
      data: null,
      render: function (row) {
        return (
          <td className="text-center d-flex justify-content-center">
            <i
              className={" fa fa-pencil text-warning handCursor"}
              onClick={() => {
                changeView("add", row);
              }}
            />
          </td>
        );
      },
    },
    {
      data: null,
      render: function (row) {
        return permissions.DEL ? (
          <>
            <ModalDelete
              modalTitle="Eliminar"
              id={row.idSetup}
              table={["paymentcalendar"]}
              nameColumn={["idCalendar"]}
              elimina={() => deleteCalendar(row)}
              setRefresh={setRefresh}
              type="icon"
            >
              <h6>¿Seguro de eliminar el registro?</h6>
              No podrá recuperar la información después de ser eliminada
            </ModalDelete>
          </>
        ) : (
          "Accion no permitida"
        );
      },
    },
  ];

  const deleteCalendar = async (payment) => {
    const params = {
      action: "delete",
      table: "paymentcalendar",
      condition: {
        idCalendar: payment.idCalendar,
      },
    };
    await API.peticion(params)
      .then((res) => {
        if (res.data.status === "success" && res.data.code === "200") {
          toast(
            <Notification
              type={"elimina_exito"}
              backMessage={res.data.message}
              withIcon
            />
          );
          setRefresh(true);
        } else {
          toast(
            <Notification
              type={"elimina_error"}
              backMessage={res.data.message}
              withIcon
            />,
            { closeButton: false }
          );
        }
      })
      .catch((err) => {
        toast(<Notification type={"consultar_servidor_error"} withIcon />);
      });
  };

  const columnDefs = [
    {
      targets: [0, 1, 2, 3, 4],
      className: "text-center",
    },
    {
      orderable: false,
      targets: [0],
    },
  ];

  const isPeriodValid = () => {
    return values.period && values.period !== 0 && values.period !== ""
      ? true
      : false;
  };

  const isYearValid = () => {
    return values.year && values.year !== 0 && values.year !== ""
      ? true
      : false;
  };

  const getParamsTableElmt = (order, records, page, search) => {
    const params = {
      action: "datatable",
      table: "paymentcalendar A",
      rows: "A.idCalendar,A.period,A.year,A.startDate,A.endDate",
      conditions: `A.enabled = 1${
        isPeriodValid() ? ` and A.period = ${values.period}` : ""
      }${isYearValid() ? ` and A.year = ${values.year.getFullYear()}` : ""}`,
      order,
      records: records.toString(),
      page: (page * records).toString(),
      search,
    };

    return params;
  };

  async function getData(order, records, page, search) {
    try {
      const res = await API.peticion(
        getParamsTableElmt(order, records, page, search)
      );
      if (res.status === 200 && res.data.code === "200") {
        const data = res.data;
        data.data.forEach((value, index) => (value.index = 1 + index));
        return data;
      } else {
        toast(
          <Notification
            type={"consultar_error"}
            backMessage={res.data.message}
          />
        );
        return null;
      }
    } catch (err) {
      toast(<Notification type={"consultar_servidor_error"} withIcon />);
      return null;
    }
  }

  const changeView = (view, row) => {
    if (collapse !== "home") {
      setdata("");
      setCollapse("home");
    } else {
      setdata(row);
      setCollapse(view);
    }
  };

  return (
    <Widget className="widget-p-md">
      {loading ? (
        <div className="text-center">
          <Loader />
        </div>
      ) : (
        <>
          {permissions.CON ? (
            <>
              {collapse === "home" && (
                <div className="text-center">
                  <h2>Calendario de movimientos </h2>
                </div>
              )}

              {permissions.INS && (
                <Row className="mb-3">
                  <Col className="text-right mb-3">
                    <Button
                      onClick={() => {
                        changeView("add");
                      }}
                      color={collapse === "home" ? "success" : "danger"}
                    >
                      {collapse === "home" ? "Agregar" : "Regresar"}
                    </Button>
                  </Col>
                </Row>
              )}
              <Collapse isOpen={collapse === "home" ? true : false}>
                <div>
                  <FiltroCalendar onChange={onChange} values={values} />
                  <Datatable
                    headers={headers}
                    columns={columns}
                    columnDefs={columnDefs}
                    petition={getData}
                    control="back"
                    stateRefresh={[refresh, setRefresh]}
                    searching={true}
                    order={{ col: 1, opt: "desc" }}
                  />
                </div>
              </Collapse>

              <Collapse
                isOpen={
                  collapse === "add" || collapse === "edit" ? true : false
                }
              >
                {collapse === "add" && (
                  <FormCalendar
                    collapse={collapse}
                    setCollapse={setCollapse}
                    setRefreshP={setRefresh}
                    calendar={data}
                    setCalendar={setdata}
                    permissions={permissions}
                  />
                )}
              </Collapse>
            </>
          ) : (
            <div className="text-center">
              No tiene permisos de ver este modulo
            </div>
          )}
        </>
      )}
    </Widget>
  );
};

export default PercepcionesExtraordinarias;

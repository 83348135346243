import React, { useEffect, useState } from "react";
import { Table } from "reactstrap";
import sc from "./TableComponentFiltro.module.scss";
import { useHistory, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import getActions from "../../../../helpers/getPermissionForms";
import getRouters from "../../../../components/Layout/function";

const TableColors = (props) => {
  const {
    data,
    idActivity,
    methodObservaciones,
    methodViewFile,
    editProcessMethod,
    methodAddSpent,
  } = props;
  const history = useHistory();
  const [permissions, setPermissions] = useState("");

  const colorStat = (status) => {
    return status === "POR APROBAR"
      ? "table-warning"
      : status === "APROBADA"
      ? "table-success"
      : "table-danger";
  };

  const loc = useLocation();
  const user = useSelector((state) => state.userInfo);
  const { id, profileType } = useSelector((state) => state.userInfo);
  const getActionsUser = async () => {
    const pathname = loc.pathname.split("/")[2];
    if (pathname !== "dashboard") {
      const route = await getRouters(user.permission, pathname);
      if (!route.permission) {
        history.push("/template");
      } else {
        getPermissionsUser();
      }
    }
  };

  const getPermissionsUser = async () => {
    const pathname = loc.pathname.split("/")[2];
    const result = await getActions(
      id,
      profileType.selectProfile.keyProfile,
      pathname
    );
    setPermissions(result.actions);
  };


  useEffect(() => {
    getActionsUser()
  }, [])
  

  return (
    <>
      <Table
        className={`table-striped table-borderless table-hover mb-1`}
        responsive
      >
        <thead className="border-bottom border-secondary">
          <tr className={sc.userSelectNone}>
            <th className="text-center">Evidencia</th>
            <th className="text-center">Descripcion</th>
            <th className="text-center">Fecha de registro</th>
            <th className="text-center">Estatus</th>
            {editProcessMethod && (
              <th className="text-center">Ver evidencia</th>
            )}
            {permissions.INS && methodAddSpent && <th className="text-center">Agregar Gasto</th>}
            {methodViewFile && <th className="text-center">Visor documento</th>}
            {methodObservaciones && (
              <th className="text-center">Observaciones</th>
            )}
          </tr>
        </thead>
        <tbody>
          {data === null ||
          data === undefined ||
          data === "" ||
          data.length === 0 ? (
            <tr>
              <td colSpan={7} className="text-center">
                {" "}
                No se encontraron registros
              </td>
            </tr>
          ) : (
            <>
              {data.map((element, index) => (
                <tr
                  key={element.idProgress}
                  className={colorStat(element.nameStat)}
                >
                  <td className="text-center">{index + 1}</td>
                  <td className="text-center">{element.description}</td>
                  <td className="text-center">{element.created}</td>
                  <td className="text-center">{element.nameStat}</td>
                  {editProcessMethod && (
                    <td className="text-center">
                      <i
                        className={sc.handCursor + " fa fa-eye text-info"}
                        onClick={() => editProcessMethod(element, idActivity)}
                      />
                    </td>
                  )}
                  {permissions.INS && methodAddSpent && (
                    <td className="text-center">
                      <i
                        className={
                          sc.handCursor + " fa fa-university text-secondary"
                        }
                        onClick={() => methodAddSpent(element,idActivity)}
                      />
                    </td>
                  )}
                  {methodViewFile && (
                    <td className="text-center">
                      <i
                        className={
                          sc.handCursor + " fa fa-file-pdf-o text-danger"
                        }
                        onClick={() => methodViewFile(element)}
                      />
                    </td>
                  )}
                  {methodObservaciones && (
                    <td className="text-center">
                      <i
                        className={
                          sc.handCursor + " fa fa-commenting text-secondary"
                        }
                        onClick={() => methodObservaciones(element)}
                      />
                    </td>
                  )}
                </tr>
              ))}
            </>
          )}
        </tbody>
      </Table>
    </>
  );
};

export default TableColors;

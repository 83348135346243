import { peticionesReceiver } from "../../../helpers/peticionesReceiver";
import Notification from "../../../components/Notification/Notification";
import { toast } from "react-toastify";
const API = peticionesReceiver();

export const createSchedule = async (schedule) => {
  if (schedule) {
    const params = {
      action: "insert",
      table: "jobschedule",
      rows: schedule,
      order: "idJobSchedule asc",
      // validate: [ ["idJobSchedule", "keyTypeSchedule"] ]
      validate: [ ["nameJobSchedule", "keyTypeSchedule"] ]
    };
    try {
      const res = await API.peticion(params)
      if (res.status === 200 && res.data.code === "200") {
        toast(<Notification type={"agrega_exito"} withIcon />);
        window.location.href = "#/template/horarios-nomina"
      }
      else{
        toast(<Notification type={"agrega_error"} backMessage={res.data.message} withIcon />);
      }
    } catch (error) {
      toast(<Notification type={"consultar_servidor_error"} withIcon />);
    }
  }
};

export const updateSchedule = async (schedule) => {
    if (schedule) {
      const params = {
        action: "update",
        table: "jobschedule",
        rows: schedule,
        condition: {
          idJobSchedule: schedule.idJobSchedule,
        },
        // validate: [["idJobSchedule", "keyTypeSchedule"]]
        validate: [ ["nameJobSchedule", "keyTypeSchedule"] ]
      };
      try {
        const res = await API.peticion(params)
        if (res.status === 200 && res.data.code === "200") {
          toast(<Notification type={"modifica_exito"} withIcon />);
          window.location.href = "#/template/horarios-nomina"
        }
        else{
          toast(<Notification type={"modifica_error"} backMessage={res.data.message} withIcon />);
        }
      } catch (error) {
        toast(<Notification type={"consultar_servidor_error"} withIcon />);
      }
    }
};
import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Button, Row, Col, Nav, NavItem, NavLink } from "reactstrap";
import { peticionesReceiver } from "../../../../../helpers/peticionesReceiver";

import s from "../InputClear.module.scss";

import { toast } from "react-toastify";
import Notification from "../../../../../components/Notification/Notification";
import ModalConfirmation from "../../../../../components/Modal/ModalConfirmation";
import ModalTableVer from "../../../../../components/Modal/ModalTableVer";
import { CancelOutlined } from "@material-ui/icons";
import ListTableStaffs from "./ListTableStaffs";
import ModalTableChoose from "./TableChoose";
import ModalTableChooseAreas from "./TableChooseAreas";
import { useHistory } from "react-router-dom";

const FormIncidentsGroup = (props) => {
  const { setCollapse } = props;
  const API = peticionesReceiver();
  const [modalConfirmation, setModalConfirmation] = useState(false);
  const [editando, setEditando] = useState(false);
  const [staffList, setStaffList] = useState([]);
  const [staffDuplicate, setStaffListDuplicate] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const history = useHistory();

  const [activeTab, setActiveTab] = useState(0);

  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  // {idStaff :"", nameStaff: "", staffNumber: "" }

  // Search by staff

  const [modalTabla, setModalTabla] = useState(false);
  const [showModalTablechoose, setShowModalTableChoose] = useState(false);
  const filtroModalTabla = [true, true, true, true, true];
  const cabecerasModalTabla = [
    "#",
    "Núm. Empleado",
    "Nombre",
    "Unidad de trabajo",
    "Puesto",
    "Agregar Usuario",
  ];
  const [paramsModalTabla] = useState({
    action: "datatable",
    table:
      "staff STF INNER JOIN employtypes AS EMT ON STF.keyEmployType = EMT.keyEmployType INNER JOIN jobstaff AS JBS ON JBS.idStaff = STF.idStaff INNER JOIN areas AS AR ON AR.idArea = JBS.idArea INNER JOIN jobtypes AS JBT ON JBT.keyJobType = STF.keyEmployType",
    rows: "STF.idStaff,STF.staffNumber,CONCAT(STF.name,' ',STF.pName,' ',STF.mName) as fullName,AR.name,JBT.nameJobType",
    conditions:
      "STF.enabled = 1 AND JBS.valid = 1 AND STF.keyStat = 1 AND JBS.enabled = 1",
    page: 0,
    records: 5,
    search: "",
    order: "",
  });

  //SearchByArea
  const [showModalTableChooseAreas, setShowModalTableChooseAreas] =
    useState(false);
  /* const [showModalTablechoose, setShowModalTableChoose] = useState(false); */
  const filtroModalTablaAreas = [true, true, true, true, true];
  const cabecerasModalTablaAreas = [
    "#",
    "Area",
    "Clave area",
    "Edificio",
    "Agregar Area",
  ];
  const [paramsModalTablaAreas] = useState({
    action: "datatable",
    table: "areas A INNER JOIN buildings B on (A.idBuilding = B.idBuilding)",
    rows: "idArea,A.name, A.areaKey,B.nameBuilding",
    conditions: "A.enabled = 1 and B.enabled = 1",
    page: 0,
    records: 5,
    search: "",
    order: "",
  });

  const FormSchema = Yup.object().shape({
    nameStaff: Yup.string().required("Ingrese el empleado verificador"),
  });

  const {
    handleSubmit,
    values,
    errors,
    setFieldValue,
    touched,
    handleBlur,
    resetForm,
  } = useFormik({
    initialValues: {
      idStaffAutorized: "",
      nameStaff: "",
    },
    onSubmit: async () => {
      await validateWithBd(values.idStaffAutorized);
    },
    validationSchema: FormSchema,
    enableReinitialize: true,
  });

  const onChange = (e) => {
    const { name, value } = e.target;
    if (name === "minPayment") {
      setFieldValue("maxPayment", "");
    }
    setFieldValue([name], value);
  };

  const saveIncidentsGroup = async () => {
    let staffs = cleanArrayToSend();

    staffs = filterArray(staffs);

    const data = {
      action: "saveIncidentsgroups",
      rows: {
        idStaffAutorized: values.idStaffAutorized,
        staffs: staffs,
      },
    };

    const res = await API.peticionEndPoint(
      data,
      "app/facades/employees/employeesF.php"
    );
    if (res.status === 200 && res.data.status === "success") {
      toast(
        <Notification
          type={"agrega_exito"}
          backMessage={res.data.message}
          withIcon
        />
      );
      history.push("/template/grupos-incidencias");
    } else {
      toast(
        <Notification
          type={"agrega_error"}
          backMessage={res.data.message}
          withIcon
        />
      );
    }
  };
  const validateWithBd = async (idStaffAutorized) => {
    const data = {
      action: "multiselect",
      table:
        "incidentsgroups A INNER JOIN staff B on (A.idStaff = B.idStaff)  ",
      rows: "B.idStaff,B.staffNumber,concat(B.name,' ',B.pName,' ',B.mName) as nameStaff",
      conditions: `A.enabled = 1 and B.enabled = 1 and A.idStaffAutorized = ${idStaffAutorized}`,
    };
    let duplicates = [];
    await API.peticion(data)
      .then((res) => {
        if (res.status === 200 && res.data.status === "success") {
          let cont = 0;
          res.data.data.forEach((staff) => {
            if (
              staffList.find((obj) => obj.idStaff === staff.idStaff) !==
              undefined
            ) {
              duplicates.push(staff);
              cont += 1;
            }
          });

          setStaffListDuplicate(duplicates);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setModalConfirmation(true);
      });
  };

  const cleanArrayToSend = () => {
    return staffList.map((staff) => ({ idStaff: staff.idStaff }));
  };

  const filterArray = (staffs) => {
    let filters = [];

    staffs.forEach((staff) => {
      if (
        staffDuplicate.find((obj) => obj.idStaff === staff.idStaff) ===
        undefined
      ) {
        filters.push(staff);
      }
    });
    return filters;
  };

  const selectEmpleado = (el) => {
    setFieldValue("idStaffAutorized", el[0]);
    setFieldValue("nameStaff", `${el[1]} - ${el[2]}`);
    setModalTabla(false);
  };

  function handleClear() {
    setFieldValue("idStaff", "");
    setFieldValue("nameStaff", "");
  }

  const addfromStaffs = (arrStaffs) => {
    let tmpList = staffList.slice(0, staffList.length);
    arrStaffs.forEach((staff) => {
      const comparative = staffList.find(
        (obj) => obj.idStaff === staff.idStaff
      );

      if (comparative === undefined) {
        tmpList.push({
          idStaff: staff.idStaff,
          staffNumber: staff.staffNumber,
          nameStaff: staff.nameStaff,
        });
      } else {
        <Notification
          type={"agrega_error"}
          backMessage={`Usuario: ${staff.nameStaff} omitido , ya se encuentra en la lista`}
          withIcon
        />;
      }
    });
    setStaffList(tmpList);
    setShowModalTableChoose(false);
    setShowModalTableChooseAreas(false);
  };

  return (
    <div>
      <h1 className="text-center mb-5">Crear Grupo de Incidencias</h1>
      <form onSubmit={handleSubmit}>
        <h2 className="text-center mb-3">Empleado Autorizado</h2>
        <Row className="justify-content-around">
          <Col xs={6} lg={6}>
            <div className="form-group position-relative">
              <label htmlFor="nameStaff">
                Empleado autorizado <span className="text-danger">*</span>
              </label>
              <div className="input-group">
                <input
                  name="nameStaff"
                  onChange={onChange}
                  onBlur={handleBlur}
                  className={
                    "form-control" +
                    (errors.nameStaff && touched.nameStaff ? " is-invalid" : "")
                  }
                  value={values.nameStaff}
                  readOnly
                  onClick={() => setModalTabla(true)}
                />
                <div className="input-group-append">
                  <button
                    className="btn btn-secondary"
                    style={{ zIndex: "auto" }}
                    type="button"
                    onClick={() => setModalTabla(true)}
                  >
                    <i className="fa fa-search" />
                  </button>
                </div>
              </div>
              {values.nameStaff !== "" && (
                <CancelOutlined className={s.btnClear} onClick={handleClear} />
              )}
              {touched.nameStaff && errors.nameStaff && (
                <span style={{ color: "red" }}>{errors.nameStaff}</span>
              )}
            </div>
          </Col>
        </Row>
        <Row>
          <Nav
            fill
            tabs
            style={{ overflowY: "hidden", overflowX: "auto" }}
            className="justify-content-center"
          >
            <NavItem active={activeTab === 0}>
              <NavLink
                href="#"
                active={activeTab === 0}
                onClick={() => toggleTab(0)}
              >
                <i
                  className={"fa fa-pencil-square-o"}
                  style={{ fontSize: "19px", marginRight: "5px" }}
                />
                Agregar por usuario
              </NavLink>
            </NavItem>
            <NavItem active={activeTab === 1}>
              <NavLink
                href="#"
                active={activeTab === 1}
                onClick={() => toggleTab(1)}
              >
                <i
                  className={"eva eva-edit-2-outline"}
                  style={{ fontSize: "19px", marginRight: "5px" }}
                />
                Agregar por area
              </NavLink>
            </NavItem>
          </Nav>
          <div className="m-auto  justify-content-center">
            {/* <div className="mx-4 text-center text-md-left"><h3>{titles[activeTab]}</h3></div> */}
            {activeTab === 0 ? (
              <ModalTableChoose
                modal={showModalTablechoose}
                setModal={setShowModalTableChoose}
                title="Empleados"
                cabeceras={cabecerasModalTabla}
                filtro={filtroModalTabla}
                parametros={paramsModalTabla}
                addSelectedsMethod={addfromStaffs}
                backdrop={"true"}
                keyboard={true}
                btnClose={true}
                staffList={staffList}
                setStaffList={setStaffList}
                setRefresh={setRefresh}
              />
            ) : (
              <ModalTableChooseAreas
                modal={showModalTableChooseAreas}
                setModal={setShowModalTableChooseAreas}
                title="Areas"
                cabeceras={cabecerasModalTablaAreas}
                filtro={filtroModalTablaAreas}
                parametros={paramsModalTablaAreas}
                addSelectedsMethod={addfromStaffs}
                backdrop={"true"}
                keyboard={true}
                btnClose={true}
                staffList={staffList}
                setStaffList={setStaffList}
                setRefresh={setRefresh}
              />
            )}
          </div>
        </Row>

        <ListTableStaffs
          title={"Empleados Agregados"}
          staffList={staffList}
          setStaffList={setStaffList}
          refresh={refresh}
          setRefresh={setRefresh}
          deleteAll={true}
          deleteRow={true}
        />

        <div className="d-flex justify-content-around mt-2">
          <div>
            <Button
              color="danger"
              onClick={() => {
                history.push("/template/grupos-incidencias");
              }}
            >
              Cancelar
            </Button>
          </div>

          <div>
            <Button type="submit" color="success">
              {editando ? "Editar" : "Agregar"}
            </Button>
          </div>
        </div>

        <ModalConfirmation
          modalTitle={editando ? "Editar" : "Crear"}
          modal={modalConfirmation}
          setModal={setModalConfirmation}
          crear={saveIncidentsGroup}
          isEdit={false}
          values={values}
          size={"xs"}
        >
          <div className="text-center">
            <div className="d-flex flex-column justify-content-center">
              {staffDuplicate.length > 0 && (
                <Row className="mb-3">
                  <Col>
                    <ListTableStaffs
                      title={"Empleados Duplicados"}
                      staffList={staffDuplicate}
                      setStaffList={setStaffListDuplicate}
                      refresh={refresh}
                      setRefresh={setRefresh}
                      deleteAll={false}
                      deletedeleteRow={false}
                    />
                  </Col>
                </Row>
              )}

              <Row>
                <Col>
                  <h6>
                    ¿Está seguro de continuar ? {staffDuplicate.length > 0 && "Los empleados duplicados seomitiran en la inserción"}
                  </h6>
                </Col>
              </Row>
            </div>
          </div>
        </ModalConfirmation>
        <ModalTableVer
          modal={modalTabla}
          setModal={setModalTabla}
          title="Empleados"
          cabeceras={cabecerasModalTabla}
          filtro={filtroModalTabla}
          parametros={paramsModalTabla}
          selectRegistro={selectEmpleado}
          backdrop={"true"}
          keyboard={true}
          btnClose={true}
        />
      </form>
    </div>
  );
};

export default FormIncidentsGroup;

import React, { useState, useEffect } from 'react';
import { Carousel, CarouselItem, CarouselControl, CarouselIndicators, CarouselCaption, Row, Col, Button } from 'reactstrap';
import { FilesInput, TextInput } from '../../../../components/GenericInputsFormik';
import ModalConfirmation from "../../../../components/Modal/ModalConfirmation";
import { peticionesReceiver } from '../../../../helpers/peticionesReceiver';

import { useFormik } from "formik";
import * as Yup from "yup";
import { AddImage, DeleteArchivo } from '../functions';
import { toast } from 'react-toastify';
import Notification from '../../../../components/Notification/Notification';
import Loader from '../../../../components/Loader/Loader';


export default function CarouselInmuebles({ data }) {
    const API = peticionesReceiver();
    const [modal, setModal] = useState(false)
    const [refresh, setRefresh] = useState(true);
    const [items, setItems] = useState([]); //[{src, nameFile, idFile}]
    const [activeIndex, setActiveIndex] = useState(0);
    const [animating, setAnimating] = useState(false);

    const defaultForm = {
        reference: data.idProperty,
        nameFile: "",
        file: ""
    }

    const onRefresh = () => {
        resetForm();
        setRefresh(true)
    }

    async function getImage(id) {
        const params = {
            action: "multiselect",
            table: "properties A LEFT JOIN bookcase BC ON (BC.reference = A.idProperty) LEFT JOIN files G ON (BC.idBook = G.idBook) INNER JOIN filetypes FT ON FT.keyFileType=BC.keyFileType",
            rows: "G.src,BC.nameFile,G.idFile",
            conditions: `A.enabled = 1 AND FT.keyFileType = 25 AND A.idProperty=${id} AND G.enabled=1`,
        };
        const res = await API.peticion(params)
        if (res.status === 200 && res.data.code === "200") {
            const data = res.data.data;
            setItems(data)
            setRefresh(false);
        } else {
            setItems([])
            toast(<Notification type={"consultar_error"} withIcon />);
        }
    }

    useEffect(() => {
        if (refresh && data) {
            getImage(data.idProperty)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refresh, data])



    const next = () => {
        if (animating) return;
        const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
        setActiveIndex(nextIndex);
    };

    const previous = () => {
        if (animating) return;
        const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
        setActiveIndex(nextIndex);
    };

    const goToIndex = (newIndex) => {
        if (animating) return;
        setActiveIndex(newIndex);
    };

    const FormSchema = Yup.object().shape({
        file: Yup.mixed()
            .test("file-type", "El documento debe ser de tipo png, gif, jpeg o jpg", (value) => {
                const files = ["image/png", "image/gif", "image/jpeg", "image/jpg"]
                return value && files.includes(value.type);
            })
            .test({
                message: `El documento debe pesar menos de 4MB`,
                test: (value) => value?.size < 4000000,
            })
    })

    const { handleSubmit, values, handleBlur, errors, touched, setFieldValue, resetForm } =
        useFormik({
            initialValues: defaultForm,
            onSubmit: () => setModal(true),
            validationSchema: FormSchema,
        });

    const handleFiles = event => {
        const { files } = event.target
        setFieldValue('file', files[0]);
    }

    const onChange = (e) => {
        const { name, value } = e.target
        setFieldValue([name], value);
    }


    if (refresh) {
        return (<Loader />)
    }
    return (
        <>
            <style>
                {`.custom-tag {
                    max-width: 100%;
                    height: 500px;
                    background: black;
                    }`
                }
            </style>
            <form onSubmit={handleSubmit}>
                <Row className='align-items-end'>
                    <Col xs={12} md={5}>
                        <FilesInput
                            label="Adjuntar imagen del inmueble"
                            inputName="file"
                            fileAccept=".jpeg,.jpg,.gif,.png"
                            onChangeMethod={handleFiles}
                            onBlurMethod={handleBlur}
                            value={values.file}
                            errors={values.file}
                        />
                    </Col>
                    <Col xs={12} md={5}>
                        <TextInput
                            label="Descripcion"
                            inputName="nameFile"
                            inputType="text"
                            onChangeMethod={onChange}
                            onBlurMethod={handleBlur}
                            value={values.nameFile}
                            isRequired
                            touched={touched.nameFile}
                            errors={errors.nameFile}
                        />
                    </Col>
                    <Col xs={12} md={2}>
                        <Button className='mb-3' color='success' type='submit'>Subir imagen</Button>
                    </Col>
                </Row>
            </form>
            {items.length > 0 && (
                <Carousel activeIndex={activeIndex} next={next} previous={previous} className="mx-auto bg-transparent">
                    <CarouselIndicators
                        items={items}
                        activeIndex={activeIndex}
                        onClickHandler={goToIndex}
                    />
                    {items.map((item) => (
                        <CarouselItem
                            className="custom-tag text-center bg-transparent"
                            tag="div"
                            key={item.idFile}
                            onExiting={() => setAnimating(true)}
                            onExited={() => setAnimating(false)}
                        >
                            <div className='text-center mb-3'><Button color='danger' type='button' onClick={() => DeleteArchivo(item.idFile, onRefresh)}>Eliminar foto</Button></div>
                            <img src={item.src} alt={item.nameFile} loading="lazy" height={"100%"} />
                            {item.nameFile ? (
                                <CarouselCaption
                                    className="text-dark bg-light p-0 mb-3"
                                    captionHeader={item.nameFile}
                                    captionText={""}
                                />
                            ) : null}

                        </CarouselItem>
                    ))}
                    <CarouselControl
                        direction="prev"
                        directionText="Previous"
                        onClickHandler={previous}
                    />
                    <CarouselControl
                        direction="next"
                        directionText="Next"
                        onClickHandler={next}
                    />
                </Carousel>
            )}
            <ModalConfirmation
                modalTitle={data ? "Agregar imagen" : "ERROR"}
                modal={modal}
                crear={(rows) => AddImage(rows, onRefresh)}
                setModal={setModal}
                isEdit={false}
                values={values}
            >
                <div className="d-flex justify-content-center">
                    <h6>¿Desea continuar con el registro?</h6>
                </div>
            </ModalConfirmation>
        </>
    )
}
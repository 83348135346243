import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import CurrencyInput from "react-currency-input-field";
import { Collapse, Col, Row, Button, Alert } from "reactstrap";

import { getKindToStatus } from "./consultasSelect";
import { peticionesReceiver } from "../../../helpers/peticionesReceiver";

import Loader from "../../../components/Loader/Loader";
import Notification from "../../../components/Notification/Notification";
import ModalConfirmation from "../../../components/Modal/ModalConfirmation";
import ModalTesseract from "../../personal/expediente-personal/ModalTesseract";
import {
  TextInput,
  RadioInput,
  SelectTypeHeadSingle,
  FilesInput,
} from "../../../components/GenericInputsFormik/index";
import { useRef } from "react";
import { doRequest, uploadFile } from "../../../helpers/requests";
import { CustomInput } from "reactstrap";

import TableComponentCustom from "../../../components/TableComponent/TableComponentCustom";
import ModalViewFile from "../../../components/Modal/ModalViewFile";
import ModalComponentEliminar from "../../../components/Modal/ModalDelete";

export const ExpedienteNominaPensiones = ({
  idStaff,
  toggleTab,
  permissions,
}) => {
  const API = peticionesReceiver();
  //selects
  const [unders, setUnders] = useState([]);
  const [status, setStatus] = useState([]);
  const [cities, setCities] = useState([]);
  const [counties, setCounties] = useState([]);
  const [states, setStates] = useState([]);
  const [pensionAguinaldo, setPensionAguinaldo] = useState([]);
  const [countries, setCountries] = useState([]);
  const [banks, setBanks] = useState([]);
  const [accountTypes, setAccountType] = useState([]);
  const [paymentTypes, setPaymentTypes] = useState([]);
  const [pensionType, setPensionType] = useState([]);
  const [validaTipoPagoisDeposito, setValidaTipoPagoisDeposito] = useState(2);
  const [editHasFile, setEditHasFile] = useState(false);
  const [modalCuenta, setModalCuenta] = useState(false);
  const [fileUp, setFileUp] = useState(null);

  const [idClave, setidClave] = useState("");
  const [idNoCuenta, setidNoCuenta] = useState("");
  const [idBank, setIdBank] = useState("");

  const [staffPensionDefault, setStaffPensionDefault] = useState({
    idPension: "",
    idStaff: idStaff,
    idUnder: "",
    keyStat: "",
    numberPension: "",
    policy: "",
    policyDate: "",
    startDate: "",
    endDate: "",
    name: "",
    pName: "",
    mName: "",
    keyCity: "",
    street: "",
    subStreet: "",
    zipCode: "",
    intNumber: "",
    extNumber: "",
    phone: "",
    keyPaymentType: "",
    keyAccountType: "",
    keyBank: "",
    account: "",
    keyTypePension: "",
    valor: 0,
    noPaysMade: "",
    special: "0",
    validate: "0",
    keyCountry: "",
    keyState: "",
    keyCounty: "",
    notes: "",
    comprobante: "",
    isAmount: false,
    switfCode: "",
    collapseCuenta: false,
  });
  const refInpFile = useRef(null);

  const toggle = () => setCollapse(!collapse);
  const [collapse, setCollapse] = useState(false);
  const [collapseCuenta, setCollapseCuenta] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [modal, setModal] = useState(false);
  const [loader, setLoader] = useState(false);
  const [modalFile, setModalFile] = useState(false); ///Visualziar archivos
  const [linkFile, setLinkFile] = useState(""); ///Link de archivo
  const [idCheque, setIdCheque] = useState(0);

  const select = {
    action: "datatable",
    table:
      "pensions A LEFT JOIN cities B on (A.keyCity = B.keyCity) LEFT JOIN counties C on (B.keyCounty = C.keyCounty) LEFT JOIN states D on (C.keyState = D.keyState) LEFT JOIN countries E on (D.keyCountry = E.keyCountry) LEFT JOIN types_pensions F on (A.keyTypePension = F.keyTypePension) LEFT JOIN bookcase X ON A.idPension = X.reference AND X.keyFileType = 53 AND X.enabled = 1 LEFT JOIN files Y ON X.idBook = Y.idBook AND Y.enabled = 1",
    rows: "A.idPension,concat(A.name,' ',A.pName,' ',A.mName)as nombre, A.policyDate,A.startDate,A.endDate,ifnull(F.nameTypePension,'SIN TIPO DE PENSION'), A.numberPension,A.policy,A.name,A.pName,A.mName,A.street,A.subStreet,A.zipCode,A.intNumber,A.extNumber, A.phone,A.account,A.porcentage,A.amount,A.noPaysMade,A.special,A.validate,A.keyPaymentType,A.keyAccountType, A.keyCity,C.keyCounty,D.keyState,E.keyCountry,A.idUnder,A.keyStat,A.keyBank,A.keyTypePension, A.notes, Y.src",
    conditions: `A.enabled = 1 AND idStaff=${idStaff}`,
    page: 0,
    records: 5,
    search: "",
    order: "",
  };
  const [cabeceras] = useState([
    "Id",
    "Nombre",
    "Fecha de aplicacion",
    "Fecha de inicio",
    "Fecha de termino",
    "Tipo de pension",
    "Evidencia",
    "Editar",
    "Eliminar",
  ]);
  const [filtro] = useState([true, true, true, true, true, true]);

  const [parametros, setParametros] = useState(select);

  const getUnders = async () => {
    const params = {
      action: "multiselect",
      table: "unders",
      rows: "idUnder,concat(Under,' - ',nameUnder) as name",
      conditions: "enabled = 1 and keyRollUnder = 4",
      order: "cast(under as float) asc",
    };
    API.peticion(params)
      .then((res) => {
        if (res.status === 200 && res.data.code === "200") {
          const data = res.data.data;
          setUnders(data);
        } else {
          toast(
            <Notification
              type={"consultar_error"}
              backMessage={res.data.message}
              withIcon
            />
          );
        }
      })
      .catch((err) => {
        toast(<Notification type={"consultar_servidor_error"} withIcon />);
      });
  };
  const getPaises = async () => {
    const params = {
      action: "select",
      table: "countries",
      condition: {
        enabled: 1,
      },
      order: "nameCountry ASC",
    };
    await API.peticion(params)
      .then((res) => {
        if (res.status === 200 && res.data.status === "success") {
          setCountries(res.data.data);
          setStates([]);
          setCounties([]);
          setCities([]);
          if (!isEditing) {
            setLoader(true);
          }
        } else {
          toast(
            <Alert color="warning">Error al consultar tipos de Paises</Alert>
          );
        }
      })
      .catch((err) => {
        toast(<Notification type={"consultar_servidor_error"} withIcon />);
      });
  };

  const getEstados = async (idPais) => {
    const params = {
      action: "select",
      table: "states",
      condition: {
        enabled: 1,
        keyCountry: idPais,
      },
      order: "nameState ASC",
    };
    await API.peticion(params)
      .then((res) => {
        if (res.status === 200 && res.data.status === "success") {
          setStates(res.data.data);
          setCounties([]);
          setCities([]);
        } else {
          toast(
            <Alert color="warning">
              Error al consultar estados por falta de datos en el registro
            </Alert>
          );
        }
      })
      .catch((err) => {
        toast(<Notification type={"consultar_servidor_error"} withIcon />);
      });
  };

  const getMunicipios = async (idEstado) => {
    if (idEstado !== "") {
      const params = {
        action: "select",
        table: "counties",
        condition: {
          enabled: 1,
          keyState: idEstado,
        },
        order: "nameCounty ASC",
      };
      await API.peticion(params)
        .then((res) => {
          if (res.status === 200 && res.data.status === "success") {
            setCounties(res.data.data);
            setCities([]);
          } else {
            toast(
              <Alert color="warning">
                Error al consultar municipios por falta de datos en el registro
              </Alert>
            );
          }
        })
        .catch((err) => {
          toast(<Notification type={"consultar_servidor_error"} withIcon />);
        });
    }
  };

  const getCiudades = async (idMunicipio) => {
    if (idMunicipio !== "") {
      const params = {
        action: "select",
        table: "cities",
        condition: {
          enabled: 1,
          keyCounty: idMunicipio,
        },
        order: "nameCity ASC",
      };
      await API.peticion(params)
        .then((res) => {
          if (res.status === 200 && res.data.status === "success") {
            setCities(res.data.data);
          } else {
            toast(
              <Alert color="warning">
                Error al consultar ciudades por falta de datos en el registro
              </Alert>
            );
          }
        })
        .catch((err) => {
          toast(<Notification type={"consultar_servidor_error"} withIcon />);
        });
    }
  };

  function getDataTesserac(data, formVals) {
    if (data.banco !== "" && data.noCuenta !== "" && data.clabe !== "") {
      const rows = {
        ...formVals,
      };
      const bank = banks.find((element) => element.name === data.banco);
      if (bank) {
        // rows.keyBank = bank.value;
        setFieldValue("keyBank", bank.value);
      }
      if (Number(rows.keyBank) !== 2) {
        // rows.keyAccountType = 1;
        setFieldValue("keyAccountType", 1);
      }
      // rows.switfCode = data.clabe;
      // rows.account = data.noCuenta;

      setFieldValue("account", data.noCuenta);
      setFieldValue("switfCode", data.clabe);

      setFileUp(data.file);
      // setFieldValue("keyBank", data.keyBank);
      // setAccount(rows);
      setModalCuenta(false);
    } else {
      toast(
        <Notification type="warning" backMessage="Error al obtener los datos" />
      );
    }
  }
  const getPaymentTypes = async () => {
    const data = {
      action: "select",
      table: "paymenttypes",
      order: "namePaymentType ASC",
    };
    API.peticion(data)
      .then((res) => {
        if (res.status === 200 && res.data.status === "success") {
          setPaymentTypes(res.data.data);
          res.data.data.map((element) => {
            if (element["namePaymentType"] === "Deposito") {
              setValidaTipoPagoisDeposito(element["keyPaymentType"]);
            }
            if (element["namePaymentType"].toString() === "Cheque") {
              setIdCheque(Number(element["keyPaymentType"]));
            }
          });
        } else {
          toast(
            <Alert color="danger">Error al consultar tipos de contactos</Alert>
          );
        }
      })
      .catch((err) => {
        toast(<Notification type={"consultar_error"} withIcon />);
      });
  };
  const getPensionTypes = async () => {
    const data = {
      action: "select",
      table: "types_pensions",
      order: "nameTypePension ASC",
    };
    API.peticion(data)
      .then((res) => {
        if (res.status === 200 && res.data.status === "success") {
          setPensionType(res.data.data);
        } else {
          toast(
            <Alert color="danger">Error al consultar tipos de contactos</Alert>
          );
        }
      })
      .catch((err) => {
        toast(<Notification type={"consultar_error"} withIcon />);
      });
  };
  const getAccountTypes = async () => {
    const data = {
      action: "select",
      table: "accounttypes",
      order: "nameAccountType ASC",
    };
    API.peticion(data)
      .then((res) => {
        if (res.status === 200 && res.data.status === "success") {
          const datos = res.data.data.map((element) => {
            if (element["nameAccountType"].toString() === "CLABE") {
              setidClave(parseInt(element["keyAccountType"]));
            }
            if (element["nameAccountType"].toString() === "No. de Cuenta") {
              setidNoCuenta(parseInt(element["keyAccountType"]));
            }
            return {
              value: element["keyAccountType"],
              name: element["nameAccountType"],
            };
          });

          setAccountType(datos);
        } else {
          toast(
            <Alert color="danger">Error al consultar tipos de contactos</Alert>
          );
        }
      })
      .catch((err) => {
        toast(<Notification type={"consultar_error"} withIcon />);
      });
  };
  const getBanks = async () => {
    const data = {
      action: "select",
      table: "banks",
      order: "nameBank ASC",
      condition: { enabled: 1 },
    };
    API.peticion(data)
      .then((res) => {
        if (res.status === 200 && res.data.status === "success") {
          const datos = res.data.data.map((element) => {
            if (element.nameBank === "BBVA BANCOMER") {
              setIdBank(element.keyBank);
            }
            return { value: element["keyBank"], name: element["nameBank"] };
          });
          setBanks(datos);
        } else {
          toast(
            <Alert color="danger">Error al consultar tipos de contactos</Alert>
          );
        }
      })
      .catch((err) => {
        toast(<Notification type={"consultar_error"} withIcon />);
      });
  };

  useEffect(() => {
    cargaInfo();
  }, []);

  useEffect(() => {
    if (pensionType) {
      setPensionAguinaldo(
        pensionType.find(
          (objeto) =>
            "AGUINALDO Y PRIMA VACACIONAL" === objeto.nameTypePension.trim()
        )
      );
    }
  }, [pensionType]);

  const cargaInfo = () => {
    setLoader(false);
    setIsEditing(false);
    /* getUser() */
    getUnders().finally(() => {
      getKindToStatus(setStatus, false, null, "PENSIONS").finally(() => {
        getPaymentTypes().finally(() => {
          getAccountTypes().finally(() => {
            getBanks().finally(() => {
              getPensionTypes().finally(() => {
                getPaises();
              });
            });
          });
        });
      });
    });
  };

  // useEffect(() => {
  //   if (collapse === true) {
  //     getUnders();
  //     getKindToStatus(setStatus, false, null, "PENSIONS");
  //   }
  // }, [collapse]);

  useEffect(() => {
    if (values.idPension !== "" && values.keyCountry !== "") {
      getPaises().finally(() => {
        if (values.keyCountry !== "") {
          getEstados(values.keyCountry).finally(() => {
            if (values.keyCounty !== "") {
              getMunicipios(values.keyState).finally(() => {
                if (values.keyCity !== "") {
                  getCiudades(values.keyCounty);
                }
              });
            }
          });
        }
      });
    }
  }, [isEditing]);

  const FormSchema = Yup.object().shape({
    idUnder: Yup.string().required("Seleccione un concepto de nomina"),
    keyStat: Yup.string().required("Seleccione un estatus"),
    /* numberPension: Yup.string().required("Ingrese un numero de pension"), */
    policy: Yup.string()
      .max(30, "solo se permiten 30 caracteres")
      .required("Ingrese una poliza"),
    policyDate: Yup.date().required("Seleccione una fecha de aplicacion"),
    startDate: Yup.date().required("Seleccione una fecha de inicio"),
    endDate: Yup.date()
      .required("Seleccione una fecha de termino")
      .min(
        Yup.ref("startDate"),
        ({ min }) => "La fecha de temrion debe ser mayor a la fecha de inicio"
      ),
    name: Yup.string()
      .max(50, "solo se permiten 50 caracteres")
      .required("Ingrese un nombre"),
    pName: Yup.string()
      .max(50, "solo se permiten 30 caracteres")
      .required("Ingrese un apellido paterno"),
    mName: Yup.string()
      .max(50, "solo se permiten 30 caracteres")
      .required("Ingrese un apellido materno"),

    special: Yup.string().required("Ingrese si ha sido revisada"),
    validate: Yup.number().required("Ingrese si ha sido revisada"),
    keyPaymentType: Yup.string().required("Seleccione un tipo de pago"),

    keyAccountType: Yup.string().when("keyPaymentType", {
      is: (v) => {
        return v === "1";
      },
      then: Yup.string().required("Seleccione un tipo de cuenta"),
    }),
    switfCode: Yup.string()
      .when("keyAccountType", {
        is: (v) => {
          return +v === +idClave ? true : false;
        },
        then: Yup.string().required("Ingrese una clave interbancaria"),
      })
      .max(18, "solo puede ingresar 18 caracteres"),
    account: Yup.string().when("keyAccountType", {
      is: (v) => {
        return +v === +idNoCuenta ? true : false;
      },

      // is: idNoCuenta && collapseCuenta === false,
      then: Yup.string().required(
        "Ingrese una clave ingrese un numero de cuenta"
      ),
    }),

    // keyAccountType: Yup.string().when("keyPaymentType", {
    //   is: validaTipoPagoisDeposito.toString(),
    //   then: Yup.string().required("Seleccione un tipo de cuenta"),
    // }),
    valor: Yup.number()
      .when("keyPaymentType", {
        is: validaTipoPagoisDeposito.toString(),
        then: Yup.number().required("Ingrese un valor"),
      })
      .when("isAmount", {
        is: (val) => !val,
        then: Yup.number().max(100, "solo se puede un maximo de 100%"),
        otherwise: Yup.number().required("Ingrese una cantidad"),
      })
      .when("keyTypePension", {
        is: (val) => val === 1 || val === "1",
        then: Yup.number(),
      }),
    // keyBank: Yup.string().when("keyPaymentType", {
    //   is: validaTipoPagoisDeposito.toString(),
    //   then: Yup.string().required("Seleccione un tipo de banco"),
    // }),
    keyTypePension: Yup.string().required("Seleccione un tipo de pensión"),
    comprobante: Yup.mixed().when("act", {
      is: (v) => {
        return editHasFile;
      },
      otherwise: Yup.mixed()
        .required("Agregue un comprobante")
        .test("file-type", "El documento debe ser en formato PDF", (value) => {
          return value && value.type === "application/pdf";
        })
        .test({
          message: `El documento debe pesar menos de 4MB`,
          test: (value) => value?.size < 4000000,
        }),
    }),
  });

  const onChange = (e) => {
    if (e.target.name === "startDate") {
      if (e.target.value === "") setFieldValue("endDate", "");
    }

    setFieldValue([e.target.name], e.target.value);
  };

  const OnChangeSelect = (event) => {
    const { name, value } = event.target;
    if (name === "keyCountry") {
      /* getCiudades(''); */
      setStates("");
      setCities("");
      setCounties("");
      getEstados(value);
    } else if (name === "keyState") {
      getMunicipios(value);
      getCiudades("");
    } else if (name === "keyCounty") {
      getCiudades(value);
    }
    setFieldValue([name], value);
  };

  const onChangeFile = (e) => {
    var { files } = e.target;
    if (files.length === 0) {
      setFieldValue("comprobante", "");
    } else {
      setFieldValue("comprobante", files[0]);
    }
  };

  const {
    handleSubmit,
    handleReset,
    handleBlur,
    values,
    errors,
    touched,
    setFieldValue,
    resetForm,
  } = useFormik({
    initialValues: staffPensionDefault,
    onSubmit: () => {
      setModal(true);
    },
    onReset: () => {
      refInpFile.current.value = "";
    },
    validationSchema: FormSchema,
    enableReinitialize: true,
  });

  const agregar = (pension, reset) => {
    let staffPensionFinal;

    staffPensionFinal = {
      idPension: "",
      idStaff: idStaff,
      idUnder: pension.idUnder,
      keyStat: pension.keyStat,
      numberPension: pension.numberPension,
      policy: pension.policy,
      policyDate: pension.policyDate,
      startDate: pension.startDate,
      endDate: pension.endDate,
      name: pension.name,
      pName: pension.pName,
      mName: pension.mName,
      keyCity: pension.keyCity,
      street: pension.street,
      subStreet: pension.subStreet,
      zipCode: pension.zipCode,
      intNumber: pension.intNumber,
      extNumber: pension.extNumber,
      phone: pension.phone,
      keyPaymentType: pension.keyPaymentType,
      noPaysMade: pension.noPaysMade,
      special: pension.special,
      validate: pension.validate,

      keyTypePension: pension.keyTypePension,
      notes: pension.notes,
      porcentage: 0,
      amount: 0,
      account:
        Number(idClave) === values.keyAccountType
          ? pension.switfCode
          : pension.account,
      keyAccountType: pension.keyAccountType,
      keyBank: pension.keyBank,
    };

    if (values.isAmount) {
      staffPensionFinal.porcentage = 0;
      staffPensionFinal.amount = pension.valor;
    } else {
      staffPensionFinal.porcentage = 1;
      staffPensionFinal.amount = pension.valor;
    }

    const data = {
      action: "savePension",
      rows: staffPensionFinal,
    };
    API.peticionPensionesExpediente(data)
      .then(async (res) => {
        if (res.status === 200 && res.data.status === "success") {
          if (values.comprobante) {
            await uploadFile(
              res.data.data[0].idPension,
              "saveFileReplace",
              53,
              values.comprobante,
              false
            );
          }
          toast(
            <Notification
              type={"agrega_exito"}
              backMessage={res.data.message}
              withIcon
            />
          );
          setParametros(select);
          reset();
          setCollapse(false);
        } else {
          toast(
            <Notification
              type={"agrega_error"}
              backMessage={res.data.message}
              withIcon
            />
          );
        }
      })
      .catch((err) => {
        toast(<Notification type={"consultar_error"} withIcon />);
      });
  };

  const editar = (pension, reset) => {
    let staffPensionFinal;

    staffPensionFinal = {
      idPension: pension.idPension,
      idStaff: idStaff,
      idUnder: pension.idUnder,
      keyStat: pension.keyStat,
      numberPension: pension.numberPension,
      policy: pension.policy,
      policyDate: pension.policyDate,
      startDate: pension.startDate,
      endDate: pension.endDate,
      name: pension.name,
      pName: pension.pName,
      mName: pension.mName,
      keyCity: pension.keyCity,
      street: pension.street,
      subStreet: pension.subStreet,
      zipCode: pension.zipCode,
      intNumber: pension.intNumber,
      extNumber: pension.extNumber,
      phone: pension.phone,
      keyPaymentType: pension.keyPaymentType,
      noPaysMade: pension.noPaysMade,
      special: pension.special,
      validate: pension.validate,
      keyTypePension: pension.keyTypePension,
      notes: pension.notes,
      porcentage: 0,
      amount: 0,

      account:
        Number(idClave) === values.keyAccountType
          ? pension.switfCode
          : pension.account,
      keyAccountType: pension.keyAccountType,
      keyBank: pension.keyBank,
    };

    if (values.isAmount) {
      staffPensionFinal.porcentage = 0;
      staffPensionFinal.amount = pension.valor;
    } else {
      staffPensionFinal.porcentage = 1;
      staffPensionFinal.amount = pension.valor;
    }

    const data = {
      action: "savePension",
      rows: staffPensionFinal,
      condition: {
        idPension: staffPensionFinal.idPension,
      },
    };
    API.peticionPensionesExpediente(data)
      .then(async (res) => {
        if (res.status === 200 && res.data.status === "success") {
          if (values.comprobante) {
            await uploadFile(
              res.data.data[0].idPension,
              "saveFileReplace",
              53,
              values.comprobante,
              false
            );
          }
          toast(
            <Notification
              type={"modifica_exito"}
              backMessage={res.data.message}
              withIcon
            />
          );
          setParametros(select);
          reset();
          setCollapse(false);
          /* cargaInfo() */
        } else {
          toast(
            <Notification
              type={"modifica_error"}
              backMessage={res.data.message}
              withIcon
            />
          );
        }
      })
      .catch((err) => {
        toast(<Notification type={"consultar_error"} withIcon />);
      });
  };

  useEffect(() => {
    if (
      values.keyPaymentType.toString() === validaTipoPagoisDeposito.toString()
    ) {
      setCollapseCuenta(true);
      setFieldValue("collapseCuenta", true);
    } else {
      setCollapseCuenta(false);
      setFieldValue("false", false);
    }
  }, [values.keyPaymentType, staffPensionDefault.keyAccountType]);

  const limpia = () => {
    setIsEditing(false);
    setCollapse(false);
    setEditHasFile(false);
    resetForm();
  };

  const modoEdicion = (pension) => {
    if (!collapse) {
      if (pension[34]) setEditHasFile(true);
      setFieldValue("idPension", pension[0]);
      setFieldValue("idStaff", idStaff);
      setFieldValue("idUnder", pension[29]);
      setFieldValue("keyStat", pension[30]);
      setFieldValue("numberPension", pension[6]);
      setFieldValue("policy", pension[7]);
      setFieldValue("policyDate", pension[2]);
      setFieldValue("startDate", pension[3]);
      setFieldValue("endDate", pension[4]);
      setFieldValue("name", pension[8]);
      setFieldValue("pName", pension[9]);
      setFieldValue("mName", pension[10]);
      setFieldValue("street", pension[11]);
      setFieldValue("subStreet", pension[12]);
      setFieldValue("zipCode", pension[13]);
      setFieldValue("intNumber", pension[14]);
      setFieldValue("extNumber", pension[15]);
      setFieldValue("phone", pension[16]);

      setFieldValue("keyAccountType", pension[24]);
      if (+idClave === +pension[24]) {
        setFieldValue("switfCode", pension[17]);
      } else {
        setFieldValue("account", pension[17]);
      }

      setFieldValue("keyPaymentType", pension[23]);
      setFieldValue("keyBank", pension[31]);
      setFieldValue("keyTypePension", pension[32]);
      setFieldValue("noPaysMade", pension[20]);
      setFieldValue("special", pension[21]);
      setFieldValue("validate", pension[22]);
      setFieldValue("keyCountry", pension[28]);
      setFieldValue("keyCounty", pension[26]);
      setFieldValue("keyState", pension[27]);
      setFieldValue("keyCity", pension[25]);
      setFieldValue("notes", pension[33]);

      setFieldValue("isPorcentage", +pension[18]); // 1 porcentaje -- 0 dinero
      setFieldValue("isAmount", +pension[18] === 1 ? false : true); // 1 porcentaje -- 0 dinero
      setFieldValue("valor", +pension[19]); // 1

      setIsEditing(true);
      setCollapse(true);
    } else {
      toast(
        <Notification
          type="warning"
          backMessage="Guarde sus cambios antes de continuar"
        />
      );
    }
  };

  // -------------------- COLUMNAS TABLA ---------------- //
  const PdfCol = (el) => {
    return (
      <div>
        <i
          className="fa fa-file-pdf-o cursor-pointer"
          onClick={() => {
            showFile(el[34]);
          }}
        />
      </div>
    );
  };
  const editCol = (el) => {
    if (permissions.UPD)
      return (
        <div>
          <i
            className="fa fa-pencil text-warning cursor-pointer"
            onClick={() => modoEdicion(el)}
          />
        </div>
      );
  };
  const deleteCol = (el) => {
    if (permissions.DEL)
      return (
        <div>
          <ModalComponentEliminar
            modalTitle="Eliminar"
            id={el[0]}
            table="pensions"
            nameColumn={["idPension"]}
            limpia={limpia}
            elimina={elimianrRegistro}
          >
            <h6>¿Seguro de eliminar el registro "{el[1]}"?</h6>
            No podrá recuperar la información después de ser eliminada
          </ModalComponentEliminar>
        </div>
      );
  };

  async function elimianrRegistro(id) {
    const params = {
      action: "delete",
      table: "pensions",
      condition: { idPension: id },
      force: 1,
    };
    const data = await doRequest("receiver/receiver.php", params, true);
    if (data.length > 0) {
      limpia(resetForm);
      setParametros({ ...select });
    }
  }

  useEffect(() => {
    if (linkFile !== "") {
      setModalFile(true);
    }
  }, [linkFile]);

  useEffect(() => {
    if (!modalFile) {
      setLinkFile("");
    }
  }, [modalFile]);

  function showFile(src) {
    if (src === "") {
      toast(
        <Notification
          type={"warning"}
          backMessage="Este registro no cuenta con una evidencia"
          withIcon
        />,
        { closeButton: false }
      );
    } else setLinkFile(src);
  }

  const istypePensionAmount = (typePension) => {
    if (pensionAguinaldo) {
      if (
        typePension === 1 ||
        typePension === "1" ||
        +pensionAguinaldo.keyTypePension === +typePension
      ) {
        return true;
      }
    }

    return false;
  };

  return (
    <>
      {loader ? (
        <div>
          <TableComponentCustom
            titulo="Pensiones"
            cabecerasTabla={cabeceras}
            filtro={filtro}
            parametros={parametros}
            specialColumns={[PdfCol, editCol, deleteCol]}
          />
          <ModalConfirmation
            modalTitle={isEditing ? "Editar" : "Crear"}
            modal={modal}
            setModal={setModal}
            crear={agregar}
            editar={editar}
            isEdit={isEditing}
            values={values}
            reset={resetForm}
          >
            {isEditing ? (
              <div className="d-flex justify-content-center">
                <h6>¿Está seguro de editar el registro?</h6>
              </div>
            ) : (
              <div className="d-flex justify-content-center">
                <h6>¿Desea continuar con el registro?</h6>
              </div>
            )}
          </ModalConfirmation>

          {permissions.INS && (
            <div className="d-grid d-flex justify-content-center justify-content-sm-end">
              <Button
                color="add"
                onClick={toggle}
                style={{ marginBottom: "1rem" }}
                disabled={collapse}
              >
                Agregar
              </Button>
            </div>
          )}
          <Collapse isOpen={collapse}>
            <Col className="d-grid d-flex justify-content-sm-end justify-content-center">
              <h6 className=" text-center">
                Estado:{" "}
                <span className="text-primary">
                  {isEditing ? "Editando" : "Guardando"}{" "}
                </span>{" "}
              </h6>
            </Col>
            <Row className="d-flex justify-content-center">
              <Col>
                <h4 className="text-center">Pensiones</h4>
              </Col>
            </Row>
            <form
              onSubmit={handleSubmit}
              onReset={handleReset}
              className="mt-4"
              noValidate
            >
              <Row className="d-flex justify-content-center">
                <Col xs={12} md={12} lg={4}>
                  <TextInput
                    label="Numero de pension"
                    inputType="text"
                    inputName="numberPension"
                    onChangeMethod={onChange}
                    onBlurMethod={handleBlur}
                    value={values.numberPension}
                    isRequired={true}
                    touched={touched.numberPension}
                    errors={errors.numberPension}
                    isDisabled={true}
                  />
                </Col>
                <Col xs={12} md={12} lg={4}>
                  <TextInput
                    label="Expediente"
                    inputType="text"
                    inputName="policy"
                    onChangeMethod={onChange}
                    onBlurMethod={handleBlur}
                    value={values.policy}
                    isRequired={true}
                    touched={touched.policy}
                    errors={errors.policy}
                  />
                </Col>
                <Col xs={12} md={12} lg={4}>
                  <TextInput
                    label="Fecha de aplicacion"
                    inputType="date"
                    inputName="policyDate"
                    onChangeMethod={onChange}
                    onBlurMethod={handleBlur}
                    value={values.policyDate}
                    isRequired={true}
                    touched={touched.policyDate}
                    errors={errors.policyDate}
                  />
                </Col>
              </Row>

              <Row className="d-flex justify-content-around">
                <Col xs={12} md={12} lg={4}>
                  <TextInput
                    label="Nombre"
                    inputType="text"
                    inputName="name"
                    onChangeMethod={onChange}
                    onBlurMethod={handleBlur}
                    value={values.name}
                    isRequired={true}
                    touched={touched.name}
                    errors={errors.name}
                    isMin={0}
                  />
                </Col>
                <Col xs={12} md={12} lg={4}>
                  <TextInput
                    label="Apellido Paterno"
                    inputType="text"
                    inputName="pName"
                    onChangeMethod={onChange}
                    onBlurMethod={handleBlur}
                    value={values.pName}
                    isRequired={true}
                    touched={touched.pName}
                    errors={errors.pName}
                    isMin={0}
                  />
                </Col>
                <Col xs={12} md={12} lg={4}>
                  <TextInput
                    label="Apellido Materno"
                    inputType="text"
                    inputName="mName"
                    onChangeMethod={onChange}
                    onBlurMethod={handleBlur}
                    value={values.mName}
                    isRequired={true}
                    touched={touched.mName}
                    errors={errors.mName}
                    isMin={0}
                  />
                </Col>
              </Row>

              <Row>
                <p className="mb-4 body-3 muted ml-2">Dirección</p>
              </Row>

              <Row className="d-flex justify-content-around">
                <Col xs={12} md={12} lg={5}>
                  <SelectTypeHeadSingle
                    label="Pais"
                    isRequired={false}
                    inputName="keyCountry"
                    optionsArray={countries}
                    defaultOption="Seleccione un pais"
                    onChangeMethod={OnChangeSelect}
                    onBlur={handleBlur}
                    isDisabled={false}
                    value={values.keyCountry}
                    touched={touched.keyCountry}
                    errors={errors.keyCountry}
                    optionValue="keyCountry"
                    optionName="nameCountry"
                  />
                </Col>
                <Col xs={12} md={12} lg={5}>
                  <SelectTypeHeadSingle
                    label="Estado"
                    inputName="keyState"
                    optionsArray={states}
                    defaultOption="Seleccione un estado"
                    onChangeMethod={OnChangeSelect}
                    onBlur={handleBlur}
                    isDisabled={
                      states.length > 0 && values.keyCountry ? false : true
                    }
                    value={values.keyState}
                    touched={touched.keyState}
                    errors={errors.keyState}
                    optionValue="keyState"
                    optionName="nameState"
                    // isRequired={true}
                  />
                </Col>
              </Row>

              <Row className="d-flex justify-content-around">
                <Col xs={12} md={12} lg={5}>
                  <SelectTypeHeadSingle
                    label="Municipio"
                    isRequired={false}
                    inputName="keyCounty"
                    optionsArray={counties}
                    defaultOption="Seleccione un municipio"
                    onChangeMethod={OnChangeSelect}
                    onBlur={handleBlur}
                    isDisabled={
                      counties.length > 0 && values.keyState ? false : true
                    }
                    value={values.keyCounty}
                    touched={touched.keyCounty}
                    errors={errors.keyCounty}
                    optionValue="keyCounty"
                    optionName="nameCounty"
                  />
                </Col>
                <Col xs={12} md={12} lg={5}>
                  <SelectTypeHeadSingle
                    label="Localidad"
                    isRequired={false}
                    inputName="keyCity"
                    optionsArray={cities}
                    defaultOption="Seleccione una localidad"
                    onChangeMethod={OnChangeSelect}
                    onBlur={handleBlur}
                    isDisabled={
                      cities.length > 0 && values.keyCounty ? false : true
                    }
                    value={values.keyCity}
                    touched={touched.keyCity}
                    errors={errors.keyCity}
                    optionValue="keyCity"
                    optionName="nameCity"
                  />
                </Col>
              </Row>

              <Row className="d-flex justify-content-around">
                <Col xs={12} md={12} lg={5}>
                  <TextInput
                    label="Colonia"
                    inputType="text"
                    inputName="street"
                    onChangeMethod={onChange}
                    onBlurMethod={handleBlur}
                    value={values.street}
                    isRequired={false}
                    isMin={0}
                  />
                </Col>
                <Col xs={12} md={12} lg={5}>
                  <TextInput
                    label="Calle"
                    inputType="text"
                    inputName="subStreet"
                    onChangeMethod={onChange}
                    onBlurMethod={handleBlur}
                    value={values.subStreet}
                    isRequired={false}
                  />
                </Col>
              </Row>

              <Row className="d-flex justify-content-around">
                <Col xs={12} md={12} lg={5}>
                  <TextInput
                    label="Numero exterior"
                    inputType="text"
                    inputName="extNumber"
                    onChangeMethod={onChange}
                    onBlurMethod={handleBlur}
                    value={values.extNumber}
                    isRequired={false}
                  />
                </Col>
                <Col xs={12} md={12} lg={5}>
                  <TextInput
                    label="Numero interior"
                    inputType="text"
                    inputName="intNumber"
                    onChangeMethod={onChange}
                    onBlurMethod={handleBlur}
                    value={values.intNumber}
                    isRequired={false}
                  />
                </Col>
              </Row>

              <Row className="d-flex justify-content-around">
                <Col xs={12} md={12} lg={5}>
                  <label htmlFor="zipCode">
                    Codigo Postal
                    {/* <span className="text-danger">*</span> */}
                  </label>
                  <CurrencyInput
                    id="zipCode"
                    name="zipCode"
                    className={
                      "form-control" +
                      (errors.zipCode && touched.zipCode ? " is-invalid" : "")
                    }
                    allowDecimals={false}
                    allowNegativeValue={false}
                    onValueChange={(value, name) =>
                      onChange({ target: { name: name, value: value } })
                    }
                    value={values.zipCode}
                    onBlur={handleBlur}
                    min={1}
                    disableGroupSeparators={true}
                    maxLength={5}
                  />
                  {touched.zipCode && errors.zipCode && (
                    <span className="text-danger">{errors.zipCode}</span>
                  )}
                </Col>
              </Row>

              <Row>
                <p className="mb-4 body-3 muted ml-2">Estado de la pension</p>
              </Row>

              <Row className="d-flex justify-content-around">
                <Col xs={12} md={12} lg={5}>
                  <TextInput
                    label="Fecha de inicio"
                    inputType="date"
                    inputName="startDate"
                    onChangeMethod={onChange}
                    onBlurMethod={handleBlur}
                    value={values.startDate}
                    isRequired={true}
                    touched={touched.startDate}
                    errors={errors.startDate}
                  />
                </Col>
                <Col xs={12} md={12} lg={5}>
                  <TextInput
                    label="Fecha de termino"
                    inputType="date"
                    inputName="endDate"
                    onChangeMethod={onChange}
                    onBlurMethod={handleBlur}
                    value={values.endDate}
                    isRequired={true}
                    touched={touched.endDate}
                    errors={errors.endDate}
                    isMin={values.startDate}
                  />
                </Col>
              </Row>

              <Row className="d-flex justify-content-around">
                <Col xs={12} md={12} lg={4}>
                  <div>
                    <label htmlFor="phone">
                      Telefono
                      {/* <span className="text-danger">*</span> */}
                    </label>
                    <CurrencyInput
                      id="phone"
                      name="phone"
                      className={
                        "form-control" +
                        (errors.phone && touched.phone ? " is-invalid" : "")
                      }
                      allowDecimals={false}
                      allowNegativeValue={false}
                      onValueChange={(value, name) =>
                        onChange({ target: { name: name, value: value } })
                      }
                      value={values.phone}
                      onBlur={handleBlur}
                      min={1}
                      maxLength={10}
                      disableGroupSeparators={true}
                    />
                    {/* {touched.phone && errors.phone && (
                      <span className="text-danger">{errors.phone}</span>
                    )} */}
                  </div>
                </Col>

                <Col xs={12} md={12} lg={4}>
                  <SelectTypeHeadSingle
                    label="Concepto"
                    isRequired={true}
                    inputName="idUnder"
                    optionsArray={unders}
                    defaultOption="Seleccione un concepto"
                    onChangeMethod={OnChangeSelect}
                    onBlur={handleBlur}
                    isDisabled={false}
                    value={values.idUnder}
                    touched={touched.idUnder}
                    errors={errors.idUnder}
                    optionValue="idUnder"
                    optionName="name"
                  />
                </Col>
                <Col xs={12} md={12} lg={4}>
                  <SelectTypeHeadSingle
                    label="Estados"
                    isRequired={true}
                    inputName="keyStat"
                    optionsArray={status}
                    defaultOption="Seleccione un estados"
                    onChangeMethod={OnChangeSelect}
                    onBlur={handleBlur}
                    isDisabled={false}
                    value={values.keyStat}
                    touched={touched.keyStat}
                    errors={errors.keyStat}
                    optionValue="keyStat"
                    optionName="nameStat"
                  />
                </Col>
              </Row>

              <Row className="d-flex justify-content-center">
                <Col xs={12} md={12} lg={4}>
                  <FilesInput
                    label="Evidencia"
                    inputName="comprobante"
                    onChangeMethod={onChangeFile}
                    onBlurMethod={handleBlur}
                    fileAccept="application/pdf"
                    isRequired
                    touched={touched.comprobante}
                    errors={errors.comprobante}
                    basicReq={false}
                    refInput={refInpFile}
                  />
                </Col>
                <Col
                  xs={12}
                  md={12}
                  lg={4}
                  className="d-flex justify-content-center"
                >
                  <RadioInput
                    label="Revisarla"
                    inputName="special"
                    onChangeMethod={onChange}
                    onBlurMethod={handleBlur}
                    value={values.special}
                    isRequired={true}
                    touched={touched.special}
                    errors={errors.special}
                    optionsArray={[
                      { val: "1", name: "Si" },
                      { val: "0", name: "No" },
                    ]}
                    optionValue="val"
                    optionName="name"
                  />
                </Col>
                <Col
                  xs={12}
                  md={12}
                  lg={4}
                  className="d-flex justify-content-center"
                >
                  <RadioInput
                    label="Revisada"
                    inputName="validate"
                    onChangeMethod={onChange}
                    onBlurMethod={handleBlur}
                    value={values.validate}
                    isRequired={true}
                    touched={touched.validate}
                    errors={errors.validate}
                    optionsArray={[
                      { val: "1", name: "Si" },
                      { val: "0", name: "No" },
                    ]}
                    optionValue="val"
                    optionName="name"
                  />
                </Col>
              </Row>

              <Row>
                <p className="mb-4 body-3 muted ml-2">Tipo de pago</p>
              </Row>
              <Row className="d-flex justify-content-center">
                <Col xs={12} md={12} lg={5}>
                  <SelectTypeHeadSingle
                    label="Tipo de pago"
                    isRequired={true}
                    inputName="keyPaymentType"
                    optionsArray={paymentTypes}
                    defaultOption="Seleccione un tipo de pago"
                    onChangeMethod={OnChangeSelect}
                    onBlur={handleBlur}
                    isDisabled={false}
                    value={values.keyPaymentType}
                    touched={touched.keyPaymentType}
                    errors={errors.keyPaymentType}
                    optionValue="keyPaymentType"
                    optionName="namePaymentType"
                  />
                </Col>
              </Row>

              <Collapse isOpen={collapseCuenta}>
                <Row className="d-flex justify-content-center">
                  {values.keyPaymentType !== idCheque && (
                    <Col xs={12} md={12} lg={5}>
                      <div className="form-group">
                        <Button
                          type="button"
                          onClick={() => setModalCuenta(true)}
                          onBlur={handleBlur}
                          className="mt-4 btn-block"
                        >
                          Cargar de estado de cuenta
                        </Button>
                        {touched.file && errors.file && (
                          <span style={{ color: "red" }}>{errors.file}</span>
                        )}
                      </div>
                    </Col>
                  )}
                </Row>
                <Row className="d-flex justify-content-around mt-3">
                  <Col xs={12} md={12} lg={5}>
                    <SelectTypeHeadSingle
                      label="Banco"
                      inputName="keyBank"
                      optionsArray={banks}
                      defaultOption="Seleccione un banco"
                      onChangeMethod={onChange}
                      onBlur={handleBlur}
                      isDisabled={false}
                      value={values.keyBank}
                      touched={touched.keyBank}
                      errors={errors.keyBank}
                      optionValue="value"
                      optionName="name"
                    />
                  </Col>
                  <Col xs={12} md={12} lg={5}>
                    <SelectTypeHeadSingle
                      label="Tipo de cuenta"
                      inputName="keyAccountType"
                      optionsArray={accountTypes}
                      defaultOption="Tipo de cuenta"
                      onChangeMethod={(event) =>
                        setFieldValue([event.target.name], event.target.value)
                      }
                      onBlur={handleBlur}
                      value={values.keyAccountType}
                      touched={touched.keyAccountType}
                      errors={errors.keyAccountType}
                      optionValue="value"
                      optionName="name"
                    />
                  </Col>
                </Row>

                <Row className="d-flex justify-content-around mt-3">
                  <Col xs={12} md={12} lg={5}>
                    <TextInput
                      inputName="switfCode"
                      errors={errors.switfCode}
                      isRequired={Number(idClave) === +values.keyAccountType}
                      onBlurMethod={handleBlur}
                      value={values.switfCode}
                      label="CLABE interbancaria"
                      touched={touched.switfCode}
                      onChangeMethod={onChange}
                      isMax={18}
                    />
                  </Col>
                  <Col xs={12} md={12} lg={5}>
                    <TextInput
                      label="Cuenta"
                      inputName="account"
                      errors={errors.account}
                      onBlurMethod={handleBlur}
                      value={values.account}
                      touched={touched.account}
                      onChangeMethod={onChange}
                      isRequired={Number(idClave) !== values.keyAccountType}
                    />
                  </Col>

                  {/* )} */}
                </Row>
              </Collapse>

              <Row classname="d-flex flex-row  justify-content-center ">
                <Col xs={5} md={5} lg={5} className="text-right">
                  <p>Porcentaje </p>
                </Col>
                <Col xs={2} md={2} lg={2}>
                  <CustomInput
                    type="switch"
                    id="isAmount"
                    name="isAmount "
                    className="mx-auto text-center"
                    onChange={() => setFieldValue("isAmount", !values.isAmount)}
                    checked={values.isAmount}
                  />
                </Col>
                <Col xs={5} md={5} lg={5} className="text-left">
                  <p>Cantidad </p>
                </Col>
              </Row>

              <Row className="d-flex justify-content-around">
                <Col xs={12} md={12} lg={4}>
                  <SelectTypeHeadSingle
                    label="Tipo de pension"
                    isRequired={true}
                    inputName="keyTypePension"
                    optionsArray={pensionType}
                    defaultOption="Seleccione un tipo de pension"
                    onChangeMethod={OnChangeSelect}
                    onBlur={handleBlur}
                    isDisabled={false}
                    value={values.keyTypePension}
                    touched={touched.keyTypePension}
                    errors={errors.keyTypePension}
                    optionValue="keyTypePension"
                    optionName="nameTypePension"
                  />
                </Col>
                <Col xs={12} md={12} lg={4}></Col>
                <Col xs={12} md={12} lg={4}>
                  <label htmlFor="valor">
                    {values.isAmount ? "Cantidad" : "Porcentaje"}{" "}
                    <span className="text-danger">*</span>
                  </label>
                  <CurrencyInput
                    id="valor"
                    name="valor"
                    className={
                      "form-control" +
                      (errors.valor && touched.valor ? " is-invalid" : "")
                    }
                    // allowDecimals={values.isAmount}
                    allowNegativeValue={false}
                    onValueChange={(value, name) =>
                      onChange({ target: { name: name, value: value } })
                    }
                    value={values.valor}
                    onBlur={handleBlur}
                    min={1}
                    prefix={values.isAmount ? "$" : ""}
                    suffix={values.isAmount ? "" : "%"}
                    groupSeparator=","
                    decimalSeparator="."
                    disabled={values.keyTypePension === "" ? true : false}
                  />
                  {touched.valor && errors.valor && (
                    <span className="text-danger">{errors.valor}</span>
                  )}
                </Col>
              </Row>
              <Row className="mt-2">
                <Col xs="12">
                  <div className="form-group">
                    <label htmlFor="notes">Notas</label>
                    <textarea
                      name="notes"
                      className="form-control"
                      onChange={onChange}
                      onBlur={handleBlur}
                      value={values.notes}
                    />
                  </div>
                </Col>
              </Row>
              <div className="row mt-3">
                <div className="col-sm-6 order-2 order-sm-1 text-center text-sm-left mb-4">
                  <Button
                    color="danger"
                    type="reset"
                    onClick={() => limpia(resetForm)}
                  >
                    Cancelar
                  </Button>
                </div>
                <div className="col-sm-6 order-1 order-sm-2 text-center text-sm-right mb-4">
                  <Button color="success" type="submit">
                    Guardar
                  </Button>
                </div>
              </div>
            </form>
          </Collapse>
          <ModalViewFile
            modal={modalFile}
            setModal={setModalFile}
            backdrop={true}
            keyboard={true}
            btnClose={true}
            fileLink={linkFile}
          />
        </div>
      ) : (
        <Loader />
      )}
      <ModalTesseract
        setVisible={setModalCuenta}
        visible={modalCuenta}
        getData={getDataTesserac}
        formVals={values}
      />
    </>
  );
};
export default ExpedienteNominaPensiones;

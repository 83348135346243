import React from "react";
import { Col, Row } from "reactstrap";
import { NumberInput } from "../../../../components/GenericInputsFormik/NumberInput";
import CurrencyInput from "react-currency-input-field";

const RegistroComponent = (props) => {
  const { 
    month,  //Recibe un objeto mes {nameMonth , goal, progress}
    withInput, // boleano para activar el inputProgress
    isDisable, // boleano para deshabilitar el input progress
              //Valores para input progress, si mandas withInput false no es necesario mandar lo de abajo
    label,
    inputName,
    value, 
    isRequired,
    onChange, 
    handleBlur, 
    touched,
    errors, 
   } = props;

  return (
    <Row className="d-flex flex-column justify-content-around align-items-center mb-2">
      <Row>
        <Col>
          <span className="headline-2">{month.nameMonth}</span>
        </Col>
      </Row>

      <Col xs={12} md={12} lg={10} className="d-flex flex-row mb-3 justify-content-center align-items-center ">
        <div className="d-flex flex-column"
        >
          <Col xs={12} md={12} lg={12} className="text-center">
            <span className="headline-3 text-center">Totales</span>
          </Col>
          <Col xs={12} md={12} lg={12}>

            <CurrencyInput
              id={"total"}
              name={"total"}
              className={
                "form-control"
              }
              allowDecimals={false}
              allowNegativeValue={false}
              onValueChange={onChange}
              onBlur={handleBlur}
              value={+month.goal}
              disableGroupSeparators={true}
              disabled={true}
            />
            {/*{touched && errors && <span className="text-danger">{errors}</span>} */}
          </Col>
        </div>
        <div className="d-flex flex-column"
        >
          <Col xs={12} md={12} lg={12} className="text-center" >
            <span className="headline-3 text-center">Actual</span>
          </Col>
          <Col xs={12} md={12} lg={12}>

            <CurrencyInput
              id={"total"}
              
              name={"total"}
              className={
                "form-control" 
              }
              allowDecimals={false}
              allowNegativeValue={false}
              onValueChange={onChange}
              onBlur={handleBlur}
              value={+month.progress}
              placeholder={"Actividad"}
              disableGroupSeparators={true}
              disabled={true}
            />
            
          </Col>
        </div>
      </Col>

      {withInput && (
        <Row className="mb-2 d-flex justify-content-center align-items-center">
          <Col xs={12} md={12} lg={9}>
          <NumberInput
              label={label ? label : "Progreso"}
              inputName={inputName}
              onChangeMethod={onChange}
              onBlurMethod={handleBlur}
              value={value}
              errors={errors}
              touched={touched}
              isMin={0} 
              isMax={+month.goal} 
              isRequired = {isRequired}
              disableSep
              isDisabled={isDisable}
            />
          </Col>
        </Row>

      )}
    </Row>
  );
};

export default RegistroComponent;

/**
 *
 * @param {string} bd CURP DE LA BD
 * @param {string} api CURP DE LA API
 * @returns boolean
 */
export function compareCURP(bd, api) {
  return bd.toUpperCase() === api.toUpperCase();
}

/**
 *
 * @param {Object} bd Nombre del empleado
 * @param {Object} bd.name Nombre(s) en la bd
 * @param {Object} bd.pName Apellido paterno en la bd
 * @param {Object} bd.mName Apellio materno en la bd
 * @param {Object} api Nombre en el registro nacional
 * @param {Object} api.nombres Nombre(s) en la API
 * @param {Object} api.apellidoMaterno Apellido materno en la API
 * @param {Object} api.apellidoPaterno Apellido paterno en la API
 * @returns boolean
 */
export function compareName(bd, api) {
  return (
    `${bd.name} ${bd.pName} ${bd.mName}`.toUpperCase() ===
    `${api.nombres} ${api.apellidoPaterno} ${api.apellidoMaterno}`.toUpperCase()
  );
}

/**
 *
 * @param {Object} api
 * @param {string} api.situacionContribuyente
 * @returns boolean
 */
export function validateSituacion(api) {
  return (
    api.situacionContribuyente.toUpperCase() === "ACTIVO" ||
    api.situacionContribuyente.toUpperCase() === "REACTIVADO"
  );
}

/**
 *
 * @param {Object} api
 * @param {number} api.similitud
 * @returns boolean
 */
export function validateFace(api) {
  return +api.similitud >= 70;
}

/**
 *
 * @param {Object} api
 * @returns boolean
 */
export function validateCurps(api) {
  return (
    (api.ISSSTE.estatus === "error" ||
      compareCURP(api.INE.curp, api.ISSSTE.curp)) &&
    compareCURP(api.INE.curp, api.RENAPO.curp) &&
    compareCURP(api.INE.curp, api.SAT.datosIdentificacion.curp)
  );
}

/**
 *
 * @param {Object} bd Nombre del empleado
 * @param {Object} api Nombre en la API
 * @returns boolean
 */
export function compareNames(bd, api) {
  return bd.toUpperCase() === api.toUpperCase();
}

/**
 *
 * @param {Object} api
 * @returns boolean
 */
export function validateNames(api) {
  return (
    compareNames(
      api.IMSS.nombre,
      `${api.INE.nombres} ${api.INE.primerApellido} ${api.INE.segundoApellido}`
    ) &&
    (api.ISSSTE.estatus === "error" ||
      compareNames(
        api.IMSS.nombre,
        `${api.ISSSTE.nombre} ${api.ISSSTE.primerApellido} ${api.ISSSTE.segundoApellido}`
      )) &&
    compareNames(
      api.IMSS.nombre,
      `${api.RENAPO.nombre} ${api.RENAPO.apellidoPaterno} ${api.RENAPO.apellidoMaterno}`
    ) &&
    compareNames(
      api.IMSS.nombre,
      `${api.SAT.datosIdentificacion.nombres} ${api.SAT.datosIdentificacion.apellidoPaterno} ${api.SAT.datosIdentificacion.apellidoMaterno}`
    )
  );
}

import { differenceInYears, isBefore, isEqual, getDaysInMonth } from "date-fns"

const fmtMny = new Intl.NumberFormat("es-MX", {
    style: "currency",
    currency: "MXN",
});

var PercentageFormatter = new Intl.NumberFormat("es-MX", {
    style: 'percent',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
});

/**
 * Dar formato a una cadena de fecha YYYY-mm-dd a dd/mm/YYYY
 * @param {string} value 
 */
export function fDate(value) {
    if (!validValue(value) || !value.match(/^(\d{4})-(\d{2})-(\d{2})$/)) return value;
    const splited = value.toString().split(" ")[0];
    return splited.split("-").reverse().toString().replaceAll(",", "/");
}

/**
 * Dar formato a una cadena de fecha YYYY-mm-dd HH:nn:ss a dd/mm/YYYY
 * @param {string} value 
 */
export function fDateTime(value) {
    if (!validValue(value)) return value;
    return fDate(value.slice(0, 10));
}

/**
 * Dar formato a una cadena de fecha YYYY-mm-dd HH:nn:ss a dd/mm/YYYY HH:nn:ss
 * @param {string} value 
 */
export function fDatewhitTime(value) {
    if (!validValue(value)) return value;
    const time = value.split(" ")[1];
    return fDate(value.slice(0, 10)) + " " + time;
}

/**
 * Dar formato de moneda MXN a un numero
 * @param {string|number} value 
 */
export function fMoney(d) {
    if (!validValue(d) || isNaN(+d)) return d;
    return fmtMny.format(d);
}

/**
 * Validar una variable no este vacia
 * @param {any} value 
 */
export function validValue(value) {
    return value !== "" && value !== undefined && value !== null;
}

export function replaceAll(string, searchString, replaceString) {
    return string.split(searchString).join(replaceString);
}

/**
 * Convertir una cade na dd/mm/YYYY a tipo date
 * @param {string} dateString 
 */
export function string2Date(dateString) {
    const dateParts = dateString.split("\/");
    return new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);
}

/**
 * Convertir una cade na dd/mm/YYYY a tipo YYYY-mm-dd
 * @param {string} dateString 
 */
export function string2DateInput(dateString) {
    const dateParts = dateString.split("\/");
    return `${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`;
}

export function BornString2Years(dateString) {
    const dateParts = dateString.split("-");
    return differenceInYears(new Date(), new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]));
}

/**
 * Dar formato de procentaje a un numero
 * @param {string|number} value 
 */
export function fPercent(value) {
    if (validValue(value) && !isNaN(+value)) return PercentageFormatter.format(+value/100);
    return value;
}

/**
 * Dar formato YYYY-MM-dd a una fecha 
 * @param {Date} fecha 
 * @param {number} dif - (Opcional) Dias de diferencia
 */
export function fDateInput(date, dif) {
    if (validValue(date)) {
        try {
            const nd = new Date(date);
            if (dif) {
                nd.setDate(nd.getDate() + dif);
            }
            const dst = new Intl.DateTimeFormat('es-MX', { year: "numeric", month: "2-digit", day: "2-digit" }).format(nd);
            return dst.split("/").reverse().join("-");
        } catch (error) { }
    }
    
    return "";
}
/**
 * Dar formato YYYY-MM-dd HH:mm:ss a una fecha 
 * @param {Date} fecha 
 * @param {boolean} en
 */
export function fDateTimeInput(date, en) {
    if (validValue(date)) {
        try {
            const nd = new Date(date);
            let dst = new Intl.DateTimeFormat('es-MX', { year: "numeric", month: "2-digit", day: "2-digit", hour: "2-digit", minute: "2-digit", second: "2-digit" }).format(nd);
            if(en){
                const arr = dst.split(" ");
                const date = arr[0].split("/").reverse().join("-");
                dst = `${date} ${arr[1]}`;
            }
            return dst.replace(",", "");
        } catch (error) { }
    }
    return "";
}
/**
 * Dar formato dd/mm/YYYY a una fecha 
 * @param {Date} fecha 
 * @param {number} dif - (Opcional) Dias de diferencia
 */
export function fDateLocal(date, dif) {
    if (validValue(date)) {
        try {
            const nd = new Date(date);
            if (dif) {
                nd.setDate(nd.getDate() + dif);
            }
            const dst = new Intl.DateTimeFormat('es-MX', { year: "numeric", month: "2-digit", day: "2-digit" }).format(nd);
            return dst;
        } catch (error) { }
    }
    return "";
}

export const months = [
    { label: "Enero", value: 1 },
    { label: "Febrero", value: 2 },
    { label: "Marzo", value: 3 },
    { label: "Abril", value: 4 },
    { label: "Mayo", value: 5 },
    { label: "Junio", value: 6 },
    { label: "Julio", value: 7 },
    { label: "Agosto", value: 8 },
    { label: "Septiembre", value: 9 },
    { label: "Octubre", value: 10 },
    { label: "Noviembre", value: 11 },
    { label: "Diciembre", value: 12 },
]

/**
 * Comparar fecha A sea menor igual a fecha B
 * @param {Date | string | number | null | undefined} dA - Primera fecha 
 * @param {Date | string | number} dB  - Segunda fecha
 * @returns {boolean}
 */
export function compareDates(dA, dB) {
    if (!dA) return true;
    const fA = new Date(dA);
    const fB = new Date(dB);
    return isBefore(fA, fB) || isEqual(fA, fB)
}

/**
 * Calcular días de un periodo
 * @param {number} period 
 * @param {number} fYear 
 * @returns {number} dias
 */
export function periodDays(period, fYear){
    const year = new Date(fYear).getFullYear();
    if(!period) return 0;
    if(period % 2 !== 0) return 15;
    const month = ((period / 2) + (period % 2));
    return getDaysInMonth(new Date(year, month, 0)) - 15;
}

/**
 * Obtener el peridodo de una fecha
 * @param {Date} date 
 */
export function actualPeriod(date){
    const month = date.getMonth() + 1;
    return (month * 2) - +(date.getDate() < 16) ;
}

/**
 * Obtener fecha de inicio y termino de un periodo
 * @param {number|string} period
 * @param {number|string} year
 * @returns { { start: Date, end: Date } || null } objeto con fecha de inicio y termino del periodo
 */
export function getStartEndPeriod(period, year){
    if(isNaN(+period) || isNaN(+year) || +period < 1 || +period > 24) return null;
    const month = Math.ceil(period / 2);
    const starMonth = (period % 2 === 1) ? 1 : 16;
    const startDate = new Date(year, month - 1, starMonth);
    let endDate = null;
    if(starMonth === 1) endDate = new Date(year, month - 1, 15);
    else{
        endDate = new Date(year, month - 1, new Date(year, month, 0).getDate());
    }
    return {
        start: startDate,
        end: endDate
    };
}


export const PERIOD = [
    { label: '1', value: 1 },
    { label: '2', value: 2 },
    { label: '3', value: 3 },
    { label: '4', value: 4 },
    { label: '5', value: 5 },
    { label: '6', value: 6 },
    { label: '7', value: 7 },
    { label: '8', value: 8 },
    { label: '9', value: 9 },
    { label: '10', value: 10 },
    { label: '11', value: 11 },
    { label: '12', value: 12 },
    { label: '13', value: 13 },
    { label: '14', value: 14 },
    { label: '15', value: 15 },
    { label: '16', value: 16 },
    { label: '17', value: 17 },
    { label: '18', value: 18 },
    { label: '19', value: 19 },
    { label: '20', value: 20 },
    { label: '21', value: 21 },
    { label: '22', value: 22 },
    { label: '23', value: 23 },
    { label: '24', value: 24 }
]

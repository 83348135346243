/**
 * @param params setters from Format
 */
export function setConfigR23(params) {
    const { setHeaders, setPdfConfig, setExcelConfig, setHtmlConfig, setData, filter } = params;
    const headers = [
        "Id",
        "Clave concepto",
        "Clave presup",
        "Concepto",
        "Ocurrencias",
        "Importe",
        "Ver detalle",
    ]
    setHeaders(headers)
    const tempData = {
        action: "getRetenciones",
        period: filter.period,
        year: (new Date(filter.year)).getFullYear()
    }
    setData(tempData);
    const content = [
        { header: "Clave concepto", value: "under", format: false },
        { header: "Clave presup", value: "lcPropio", format: false },
        { header: "Concepto", value: "nameUnder", format: false },
        { header: "Ocurrencias", value: "ocurrencias", format: false },
        { header: "Importe", value: "importe", format: true },
    ];
    setHtmlConfig({
        headers,
        id: { id: "idUnder" },
        content: [content]
    });
    setPdfConfig({
        subTitle: `Periodo ${tempData.period}/${tempData.year}`,
        headers: headers.slice(0, -1).slice(1),
        sum: ["importe"],
        sizes: [10, 10, 40, 20, 20],
        content,
        orientation: "h"
    });
    setExcelConfig({
        subTitle: `Periodo ${tempData.period}/${tempData.year}`,
        headers: headers.slice(0, -1).slice(1),
        sum: ["importe"],
        sizes: [20, 20, 50, 20, 30],
        content
    });
}

/**
 * @param params setters from Format
 */
export function setConfigR23Details(params) {
    const { setHeaders, setPdfConfig, setExcelConfig, setHtmlConfig, setData, filter } = params;
    const headers = [
        "Id.",
        "Concepto",
        "Nombre",
        "Importe",
    ]
    setHeaders(headers)
    const tempData = {
        action: "getRetencionesDetalle",
        period: filter.period,
        year: (new Date(filter.year)).getFullYear(),
        idUnder: filter.idUnder
    }
    setData(tempData);
    const content = [
        { header: "Id.", value: "staffNumber", format: false },
        { header: "Concepto presup", value: "nameUnder", format: false },
        { header: "Nombre", value: "nombreCompleto", format: false },
        { header: "Importe", value: "amount", format: true },
    ];
    setHtmlConfig({
        headers,
        id: { id: "staffNumber" },
        content: [content]
    });
    setPdfConfig({
        subTitle: `Periodo ${tempData.period}/${tempData.year}`,
        headers,
        sum: ["amount"],
        sizes: [10, 40, 30, 20],
        content,
        orientation: "h"
    });
    setExcelConfig({
        subTitle: `Periodo ${tempData.period}/${tempData.year}`,
        headers,
        sum: ["amount"],
        sizes: [20, 40, 40, 20],
        content
    });
}
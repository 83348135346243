import * as Yup from "yup"
import { useFormik } from "formik";

import InputWithModal from "../../../../components/GenericInputsFormik/InputWithModal";
import { useEffect, useState } from "react";
import { Button, Col, Row } from "reactstrap";
import { CurrencyInput } from "../../../../components/GenericInputsFormik/CurrencyInput";
import { peticionesReceiver } from "../../../../helpers/peticionesReceiver";
import { toast } from "react-toastify";
import Notification from "../../../../components/Notification/Notification";
import { SelectTypeHeadSingle, TextInput } from "../../../../components/GenericInputsFormik";
import { NumberInput } from "../../../../components/GenericInputsFormik/NumberInput";
import Datatable from "../../../../components/Datatable/Datatable";
import ModalConfirmation from "../../../../components/Modal/ModalConfirmation";
import FullFormLoader from "../../../../components/Loader/FullFormLoader";
import ModalComponent from "../../../../components/Modal/ModalDelete";

export default function RegistroProveedoresEstudio({ idCot, idProviderQuote, setIdProviderQuote, setReturn }) {
    const API = peticionesReceiver()

    const [loader, setLoader] = useState(true)

    const [loadingArea, setLoadingArea] = useState(true);
    const [loadingUnits, setLoadingUnits] = useState(true);

    const [refresh, setRefresh] = useState(false)
    const [add, setAdd] = useState(true)
    const [editQuote, setEditQuote] = useState(false)

    const [modalConfirmation, setModalConfirmation] = useState(false);

    const [areas, setAreas] = useState([])
    const [units, setUnits] = useState([])

    const defaultForm = {
        idProviderQuote: "",
        idDetail: "",
        detail: "",
        idProvider: "",
        socialName: "",
        unitCost: "",
        taxes: "",
        idAreaReception: "",
        keyUnit: "",
        daysReception: "",
        validity: "",
        paymentDetail: ""
    }

    const filtroProvider = [true, true, true];
    const filtroSolicitud = [true, true, true];
    const cabecerasProvider = [
        "#",
        "RFC",
        "Razon social"
    ];
    const cabecerasSolicitud = [
        "#",
        "RFC",
        "Razon social"
    ];
    const paramsProvider = {
        action: "datatable",
        table: "providers",
        rows: "idProvider,itin,socialName",
        conditions: "enabled = 1",
        page: 0,
        records: 5,
        search: "",
        order: "",
    };
    const paramsSolicitud = {
        action: "datatable",
        table: "quotesdetail qt left join services sv on (qt.idService=sv.idService) left join furnitures f on (f.idFurniture=qt.idFurniture) left join messureunits mu on (f.keyUnit=mu.keyUnit) left join servicetimes sti on (sv.keyServiceTime=sti.keyServiceTime) ",
        rows: "qt.idDetail,if(isnull(sv.nameService),f.nameFurniture,sv.nameService) as descripcion,if(isnull(mu.nameUnit),sti.nameServiceTime,mu.nameUnit) as unit",
        conditions: `qt.idQuote=${idCot} AND qt.enabled=1`,
        page: 0,
        records: 5,
        search: "",
        order: "",
    };

    const FormSchema = Yup.object().shape({
        idProvider: Yup.number(),
        socialName: Yup.string().required("Seleccione un proveedor valido"),
        idDetail: Yup.number(),
        detail: Yup.string().required("Seleccione un bien o servicio valido"),
        unitCost: Yup.number().required("Ingrese un costo unitario"),
        taxes: Yup.number().required("Ingrese un impuesto valido"),
        idAreaReception: Yup.number().required("Seleccione un area valida"),
        keyUnit: Yup.number().required("Seleccione una unidad valida"),
        daysReception: Yup.number().required("Ingrese los dias de entrega"),
        validity: Yup.string().required("Ingrese la vigencia de la cotizacion"),
        paymentDetail: Yup.string().required("Ingrese las condiciones de pago de la cotizacion"),
    })

    const { handleSubmit, values, handleBlur, errors, touched, setFieldValue, resetForm } = useFormik({
        initialValues: defaultForm,
        onSubmit: (values) => {
            setModalConfirmation(true)
        },
        validationSchema: FormSchema,
        enableReinitialize: true,
    });

    const actionSelectProvider = (el) => {
        setFieldValue(["idProvider"], el[0]);
        setFieldValue(["socialName"], `${el[1]} - ${el[2]}`);
        setRefresh(true)
    }

    const actionSelectSolicitud = (el) => {
        setFieldValue(["idDetail"], el[0]);
        setFieldValue(["detail"], el[1]);
    }

    const onChange = (e) => {
        const { name, value } = e.target
        setFieldValue([name], value);
    }

    const clearProvider = (el) => {
        resetForm()
    }

    const clearDetail = (el) => {
        setFieldValue(["idDetail"], "");
        setFieldValue(["detail"], "");
    }

    async function getProvider(idProviderQuote) {
        try {
            const res = await API.peticion({
                action: "multiselect",
                table: "providerquotes pq join providers p using(idProvider)",
                rows: "p.idProvider,p.socialName,p.itin",
                conditions: `pq.idProviderQuote=${idProviderQuote}`
            })
            if (res.status === 200 && res.data.code === "200") {
                const data = res.data.data[0]
                actionSelectProvider(Object.values(data));
                setEditQuote(true);
            } else {
                toast(<Notification type={"consultar_error"} backMessage={res.data.message} withIcon />);
            }
        } catch (err) {
            console.log(err)
            toast(<Notification type={"consultar_error"} withIcon />);
        }
    }

    useEffect(() => {
        if (idProviderQuote !== "") {
            getProvider(idProviderQuote)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [idProviderQuote])

    const getAreaReception = async () => {
        try {
            const res = await API.peticion({
                action: "multiselect",
                table: "areas",
                rows: "idArea,name",
                conditions: `enabled=1 and storage = 1`
            })
            if (res.status === 200 && res.data.code === "200") {
                setAreas(res.data.data)
                setLoadingArea(false)
            } else {
                toast(<Notification type={"consultar_error"} backMessage={res.data.message} withIcon />);
            }
        } catch (err) {
            console.log(err)
            toast(<Notification type={"consultar_error"} withIcon />);
        }
    }

    const getUnits = async () => {
        try {
            const res = await API.peticion({
                action: "multiselect",
                table: "messureunits",
                rows: "keyUnit,nameUnit",
                conditions: `enabled=1`
            })
            if (res.status === 200 && res.data.code === "200") {
                const units = res.data.data.filter(row => row.nameUnit.toUpperCase() === "DIA" || row.nameUnit.toUpperCase() === "MES" || row.nameUnit.toUpperCase() === "AÑO")
                setUnits(units)
                setLoadingUnits(false)
            } else {
                toast(<Notification type={"consultar_error"} backMessage={res.data.message} withIcon />);
            }
        } catch (err) {
            console.log(err)
            toast(<Notification type={"consultar_error"} withIcon />);
        }
    }

    useEffect(() => {
        getAreaReception();
        getUnits();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        setLoader(loadingArea | loadingUnits)
    }, [loadingArea, loadingUnits])

    const columns = [
        {
            data: null,
            render: function (row) {
                return (
                    <>
                        <div>{row.descripcion}</div>
                        <div><b>Cantidad: </b>{row.descripcion}</div>
                        <div><b>Peridiocidad: </b>{row.unit}</div>
                    </>
                )
            },
            orderValue: "descripcion"
        },
        {
            data: "unitCost",
            format: "currency"
        },
        {
            data: "taxes",
            format: "currency"
        },
        {
            data: null,
            render: function (row) {
                return row.daysReception + " " + row.nameUnit;
            }
        },
        { data: "name" },
        { data: "validity" },
        { data: "paymentDetail" },
        {
            data: null,
            render: function (row) {
                return (
                    <>
                        <Button color="warning" size="sm" className="d-flex align-items-center text-nowrap py-1 px-2 mb-2 w-100" onClick={() => getQuoteProvider(row.idProviderQuote, row.descripcion)}>
                            <i className="fa fa-pencil mr-2" />Editar
                        </Button>
                        <ModalComponent
                            modalTitle="Eliminar"
                            id={row.idProviderQuote}
                            table={["providerquotes"]}
                            nameColumn={["idProviderQuote"]}
                            elimina={() => OnDelete(row.idProviderQuote)}
                            button={true}
                            specialIco={true}
                            fill={false}
                        >
                            <h6>
                                ¿Seguro de eliminar el registro?
                            </h6>
                            No podrá recuperar la información después de ser eliminada
                        </ModalComponent>
                    </>
                )
            }
        },
    ]

    async function OnDelete(row) {
        const API = peticionesReceiver();
        const params = {
            action: "delete",
            table: "providerquotes",
            condition: {
                idProviderQuote: row
            },
        };
        try {
            const res = await API.peticion(params)
            if (res.status === 200 && res.data.code === "200") {
                toast(<Notification type={"elimina_exito"} backMessage={res.data.message} withIcon />);
                setRefresh(true)
            } else {
                toast(<Notification type={"elimina_error"} backMessage={res.data.message} withIcon />);
            }
        } catch (err) {
            toast(<Notification type={"elimina_error"} withIcon />);
        }
    }

    const getQuoteProvider = async (row, desc) => {
        setLoader(true)
        try {
            const res = await API.peticion({
                action: "multiselect",
                table: "providerquotes",
                rows: "*",
                conditions: `enabled=1 AND idProviderQuote=${row}`
            })
            if (res.status === 200 && res.data.code === "200") {
                const data = res.data.data[0]
                setFieldValue("idProviderQuote", data.idProviderQuote)
                setFieldValue("idDetail", data.idDetail)
                setFieldValue("detail", desc)
                setFieldValue("keyUnit", data.keyUnit)
                setFieldValue("taxes", data.taxes)
                setFieldValue("unitCost", data.unitCost)
                setFieldValue("daysReception", data.daysReception)
                setFieldValue("idAreaReception", data.idAreaReception)
                setFieldValue("validity", data.validity)
                setFieldValue("paymentDetail", data.paymentDetail)
                setAdd(false)
                setLoader(false)
                window.scrollTo({ top: 0, behavior: 'smooth' })
            } else {
                toast(<Notification type={"consultar_error"} backMessage={res.data.message} withIcon />);
            }
        } catch (err) {
            console.log(err)
            toast(<Notification type={"consultar_error"} withIcon />);
        }
    }

    const headers = ["Descripción", "Precio unitario", "Impuestos", "Entrega", "Lugar de entrega", "Vigencia de precios", "Condiciones de pago", "Opciones"]

    const getParamsTableElmt = (order, records, page, search) => {
        const params = {
            action: "datatable",
            table: "providerquotes pq join quotesdetail qd on (pq.idDetail=qd.idDetail) left join services sv on (qd.idService=sv.idService) left join furnitures f on (f.idFurniture=qd.idFurniture) join messureunits ms on (ms.keyUnit=pq.keyUnit)  left join messureunits mu on (f.keyUnit=mu.keyUnit) left join servicetimes sti on (sv.keyServiceTime=sti.keyServiceTime) inner join areas ar on (pq.idAreaReception=ar.idArea)",
            rows: "pq.idProviderQuote,qd.amount,if(isnull(sv.nameService),f.nameFurniture,sv.nameService) as descripcion,pq.unitCost,pq.taxes,pq.total,pq.validity,pq.paymentDetail,pq.daysReception,ms.nameUnit,if(isnull(mu.nameUnit),sti.nameServiceTime,mu.nameUnit) as unit,ar.name",
            conditions: `pq.enabled = true AND pq.idProvider=${values.idProvider} AND pq.idDetail IN (select idDetail from quotesdetail where idQuote=${idCot})`,
            order,
            records: records.toString(),
            page: (page * records).toString(),
            search
        }
        return params
    }

    async function getDataTableElmts(order, records, page, search) {
        try {
            const res = await API.peticion(getParamsTableElmt(order, records, page, search));
            if (res.status === 200 && res.data.code === "200") {
                return res.data;
            } else {
                toast(<Notification type={"consultar_error"} backMessage={res.data.message} />);
                return null;
            }
        } catch (err) {
            toast(<Notification type={"consultar_servidor_error"} withIcon />);
            return null
        }
    }



    const AddQuoteProvider = async (rows) => {
        const params = {
            action: "insert",
            table: "providerquotes",
            rows: {
                idDetail: rows.idDetail,
                idProvider: rows.idProvider,
                keyUnit: rows.keyUnit,
                unitCost: rows.unitCost,
                taxes: rows.taxes,
                total: `${Number(rows.unitCost) + Number(rows.taxes)}`,
                idAreaReception: rows.idAreaReception,
                daysReception: rows.daysReception,
                validity: rows.validity,
                paymentDetail: rows.paymentDetail,
                winner: 0
            },
            validate: [["idProvider", "idDetail"]]
        };
        try {
            const res = await API.peticion(params)
            if (res.status === 200 && res.data.code === "200") {
                const idProvider = values.idProvider;
                const socialName = values.socialName;
                resetForm()
                setFieldValue("idProvider", idProvider)
                setFieldValue("socialName", socialName)
                setRefresh(true)
                toast(<Notification type={"modifica_exito"} backMessage={res.data.message} withIcon />);
            } else {
                toast(<Notification type={"modifica_error"} backMessage={res.data.message} withIcon />);
            }
        } catch (err) {
            console.log(err)
            toast(<Notification type={"modifica_error"} withIcon />);
        }
    };

    const UpdateQuoteProvider = async (rows) => {
        const params = {
            action: "update",
            table: "providerquotes",
            rows: {
                idDetail: rows.idDetail,
                idProvider: rows.idProvider,
                keyUnit: rows.keyUnit,
                unitCost: rows.unitCost,
                taxes: rows.taxes,
                total: `${Number(rows.unitCost) + Number(rows.taxes)}`,
                idAreaReception: rows.idAreaReception,
                daysReception: rows.daysReception,
                validity: rows.validity,
                paymentDetail: rows.paymentDetail,
                winner: 0
            },
            condition: { idProviderQuote: rows.idProviderQuote }
        };
        try {
            const res = await API.peticion(params)
            if (res.status === 200 && res.data.code === "200") {
                const idProvider = values.idProvider;
                const socialName = values.socialName;
                resetForm()
                setFieldValue("idProvider", idProvider)
                setFieldValue("socialName", socialName)
                setRefresh(true)
                setAdd(true)
                toast(<Notification type={"modifica_exito"} backMessage={res.data.message} withIcon />);
            } else {
                toast(<Notification type={"modifica_error"} backMessage={res.data.message} withIcon />);
            }
        } catch (err) {
            console.log(err)
            toast(<Notification type={"modifica_error"} withIcon />);
        }
    };

    const cancelEdition = () => {
        if (!editQuote || !add) {
            const idProvider = values.idProvider;
            const socialName = values.socialName;
            resetForm();
            setFieldValue("idProvider", idProvider)
            setFieldValue("socialName", socialName)
            setIdProviderQuote("")
            setAdd(true)
        } else {
            setIdProviderQuote("")
            setReturn(false)
        }

    }

    return (
        <>
            <form onSubmit={handleSubmit}>
                <div className="text-right">
                    <h5 className={add ? "text-info" : "text-warning"}>{add ? "Agregando..." : "Editando..."}</h5>
                </div>
                <Row>
                    <Col xs={12}>
                        <InputWithModal
                            cabeceras={cabecerasProvider}
                            error={errors.socialName}
                            filtro={filtroProvider}
                            handleBlur={handleBlur}
                            actionSelect={actionSelectProvider}
                            onClear={clearProvider}
                            parametros={paramsProvider}
                            touched={touched.socialName}
                            value={values.socialName}
                            label="Proveedor"
                            name={"socialName"}
                            title={"Seleccionar proveedor"}
                            disabled={!add | editQuote}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <InputWithModal
                            cabeceras={cabecerasSolicitud}
                            error={errors.detail}
                            filtro={filtroSolicitud}
                            handleBlur={handleBlur}
                            actionSelect={actionSelectSolicitud}
                            onClear={clearDetail}
                            parametros={paramsSolicitud}
                            touched={touched.detail}
                            value={values.detail}
                            label="Bien o servicio"
                            name={"detail"}
                            title={"Seleccionar bien o servicio"}
                            disabled={!add}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md={6}>
                        <CurrencyInput
                            errors={errors.unitCost}
                            inputName={"unitCost"}
                            isRequired
                            label={"Costo unitario"}
                            onBlurMethod={handleBlur}
                            onChangeMethod={(value, name) => onChange({ target: { name, value } })}
                            touched={touched.unitCost}
                            value={values.unitCost}
                        />
                    </Col>
                    <Col xs={12} md={6}>
                        <CurrencyInput
                            errors={errors.taxes}
                            inputName={"taxes"}
                            isRequired
                            label={"Impuestos"}
                            onBlurMethod={handleBlur}
                            onChangeMethod={(value, name) => onChange({ target: { name, value } })}
                            touched={touched.taxes}
                            value={values.taxes}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md={4}>
                        <SelectTypeHeadSingle
                            label="Lugar de entrega"
                            inputName="idAreaReception"
                            onChangeMethod={onChange}
                            onBlurMethod={handleBlur}
                            value={values.idAreaReception}
                            isRequired
                            touched={touched.idAreaReception}
                            errors={errors.idAreaReception}
                            optionsArray={areas}
                            optionValue="idArea"
                            optionName="name"
                        />
                    </Col>
                    <Col xs={12} md={4}>
                        <NumberInput
                            label="Entrega"
                            inputName="daysReception"
                            onChangeMethod={onChange}
                            onBlurMethod={handleBlur}
                            value={values.daysReception}
                            isRequired
                            touched={touched.daysReception}
                            errors={errors.daysReception}
                        />
                    </Col>
                    <Col xs={12} md={4}>
                        <SelectTypeHeadSingle
                            label="Unidades de entrega"
                            inputName="keyUnit"
                            onChangeMethod={onChange}
                            onBlurMethod={handleBlur}
                            value={values.keyUnit}
                            isRequired
                            touched={touched.keyUnit}
                            errors={errors.keyUnit}
                            optionsArray={units}
                            optionValue="keyUnit"
                            optionName="nameUnit"
                        />
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md={4}>
                        <TextInput
                            errors={errors.validity}
                            inputName={"validity"}
                            inputType="text"
                            isRequired
                            label={"Vigencia de la propuesta"}
                            onBlurMethod={handleBlur}
                            onChangeMethod={onChange}
                            touched={touched.validity}
                            value={values.validity}
                        />
                    </Col>
                    <Col xs={12} md={4}>
                        <TextInput
                            label="Condiciones de pago"
                            inputName="paymentDetail"
                            inputType="text"
                            onChangeMethod={onChange}
                            onBlurMethod={handleBlur}
                            value={values.paymentDetail}
                            isRequired
                            touched={touched.paymentDetail}
                            errors={errors.paymentDetail}
                        />
                    </Col>
                </Row>
                <div className="text-right">
                    {(!add || editQuote) && (
                        <Button color="danger" className="mr-2" type="button" onClick={cancelEdition}>{!editQuote || !add ? "Cancelar edición" : "Regresar"}</Button>
                    )}
                    <Button color={add ? "add" : "success"} type="submit">{add ? "Agregar" : "Guardar cambios"}</Button>
                </div>
            </form>
            {values.idProvider && (
                <Datatable
                    headers={headers}
                    columns={columns}
                    control="back"
                    petition={getDataTableElmts}
                    stateRefresh={[refresh, setRefresh]}
                />
            )}
            <ModalConfirmation
                modalTitle={"Registrar cotización"}
                modal={modalConfirmation}
                crear={(rows) => AddQuoteProvider(rows)}
                editar={(rows) => UpdateQuoteProvider(rows)}
                isEdit={!add}
                setModal={setModalConfirmation}
                values={values}
            >
                <div className="d-flex justify-content-center">
                    <h6>¿Desea continuar con el cambio?</h6>
                </div>
            </ModalConfirmation>
            <FullFormLoader show={loader} message="Consultando datos, espere un momento" />
        </>
    )
}
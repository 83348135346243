import { useEffect, useState } from "react";
import { toast } from "react-toastify";

import Datatable from "../../../../components/Datatable/Datatable";
import { GetPermisos } from "../../../../libs/permissions/hook";
import { peticionesReceiver } from "../../../../helpers/peticionesReceiver";

import Notification from "../../../../components/Notification/Notification";
import FullFormLoader from "../../../../components/Loader/FullFormLoader";
import { fDateLocal, getStartEndPeriod } from "../../../../libs/tools/format";

export default function NominaDesglosado(props) {
  const [dates, setDates] = useState({});
  const { period, year } = props;
  const API = peticionesReceiver();

  const [permissionsForm, setPermissionsForm] = useState("");
  const [loading, setLoading] = useState(true);

  GetPermisos(setPermissionsForm);

  const [headers, setHeaders] = useState([
    "Id",
    /* "NUMERO DE EMPLEADO", */
    "NOMBRE",
    "PUESTO",
    "ADSCRIPCION",
  ]);
  

  const [columns, setColumns] = useState([
    {
      data: "idStaff",
    },
    /* {
      data: "staffNumber",
    }, */
    {
      data: "name",
    },
    {
      data: "nameJob",
    },
    {
      data: "nameArea",
    },
  ]);

  const columnDefs = [
     /* { className: "text-center", targets: []  }, */
    
  ];

  useEffect(()=>{
    setDates(getStartEndPeriod(period, year));
  }, []);

  function getParams(order, page, search, records) {
    const params = {
      action: "report61",
      search,
      order,
      page: (page * records).toString(),
      records: records.toString(),
      period: period,
      year: year,
    };
    return params;
  }
  async function getData(order, records, page, search) {
    const route = "app/facades/reports/reportsF.php";
    setLoading(true);
    try {
      const res = await API.peticionEndPoint(
        getParams(order, page, search, records),
        route
      );
      if (res.status === 200 && res.data.code === "200") {
        res.data.data = Object.values(res.data.data)
        const keys = Object.keys(res.data.data[0]);
        keys.splice(keys.indexOf("idStaff"), 1);
        changeHeaders(keys);
        changeColumns(keys);
        setLoading(false);
        return res.data;
      } else {
        toast(
          <Notification
            type={"consultar_error"}
            backMessage={res.data.message}
          />
        );
        setLoading(false);
        return null;
      }
    } catch (err) {
      toast(<Notification type={"consultar_servidor_error"} withIcon />);
      setLoading(false);
      return null;
    }
  }

  const changeColumns = (keys) => {
    const columnsObj = [];
    keys.forEach((keyObj) => {
      columnsObj.push({ data: keyObj });
    });

    setColumns(columnsObj);
  };

  const changeHeaders = (keys) => {
    var headers = keys.slice();
    headers[headers.indexOf("name")] = "NOMBRE";
    headers[headers.indexOf("staffNumber")] = "NUMERO DE EMPLEADO";
    headers[headers.indexOf("nameJob")] = "PUESTO";
    headers[headers.indexOf("nameArea")] = "ADSCRIPCION";
    setHeaders(headers);
  };

  return (
    <>
    <div className="my-2">
      <h3 className="mt-2">Reporte de Nómina General Desglosado</h3>
      <p>
        <strong>Període de pago: </strong> 
        {period?.toString().padStart(2, '0')}/{year}
      </p>
      {
        dates && <p>
          <strong>Del: </strong> 
          {fDateLocal(dates.start)} 
          <strong> al </strong>
          {fDateLocal(dates.end)}
        </p>
      }
    </div>
      {permissionsForm.CON === "1" && (
        <Datatable
          className="mt-3 mx-0"
          headers={headers}
          petition={getData}
          columns={columns}
          columnDefs={columnDefs}
          control="back"
          key={"table"}
        />
      )}
      <FullFormLoader
        show={loading}
        message={"Consultando datos , espere un momento"}
      />
    </>
  );
}

// -- CONFIGURACION DE CALCULO  -- //
import ConfiguracionCalculo from "../../../pages/complemento-nomina/configuracion-calculo/ConfiguracionCalculo";
import CrearConfiguracionCalculo from "../../../pages/complemento-nomina/configuracion-calculo/crear/CrearConfiguracionCalculo";

// -- Licencias -- //
import Licencias from "../../../pages/complemento-nomina/licencias/Licencias";
import CrearLicencia from "../../../pages/complemento-nomina/licencias/crear/CrearLicencia";
import EditarLicencia from "../../../pages/complemento-nomina/licencias/editar/EditarLicencia";

// -- Multas -- //
import Multas from "../../../pages/complemento-nomina/multas/Multas";
import CrearMulta from "../../../pages/complemento-nomina/multas/crear/CrearMulta";
import EditarMulta from "../../../pages/complemento-nomina/multas/editar/EditarMulta";

// -- Incapacidades -- //
import Incapacidades from "../../../pages/complemento-nomina/incapacidades/Incapacidades";
import CrearIncapacidad from "../../../pages/complemento-nomina/incapacidades/crear/CrearIncapacidad";
import EditarIncapacidad from "../../../pages/complemento-nomina/incapacidades/editar/EditarIncapacidad";

// -- Pensiones -- //
import Pensiones from "../../../pages/complemento-nomina/pensiones/Pensiones";
import CrearPension from "../../../pages/complemento-nomina/pensiones/crear/CrearPension";
import EditarPension from "../../../pages/complemento-nomina/pensiones/editar/EditarPension";

// -- Prestamos -- //
import Prestamo from "../../../pages/complemento-nomina/prestamos/Prestamos";
import CrearPrestamo from "../../../pages/complemento-nomina/prestamos/crear/CrearPrestamo";
import EditarPrestamo from "../../../pages/complemento-nomina/prestamos/editar/EditarPrestamo";

//  -- Proveedores de nomina -- //
import ProveedoresNomina from "../../../pages/complemento-nomina/proveedores/ProveedoresNomina";
import CrearProveedorNomina from "../../../pages/complemento-nomina/proveedores/crear/CrearProveedor";
import EditarProveedorNomina from "../../../pages/complemento-nomina/proveedores/editar/EditarProveedor";

// -- Seguros -- //
import Seguros from "../../../pages/complemento-nomina/seguros/Seguros";
import CrearSeguro from "../../../pages/complemento-nomina/seguros/crear/CrearSeguro";
import EditarSeguro from "../../../pages/complemento-nomina/seguros/editar/EditarSeguro";

// -- Horario laboral -- //
import HorarioLaboral from "../../../pages/complemento-nomina/horario-laboral/HorarioLaboral";
import CrearHorario from "../../../pages/complemento-nomina/horario-laboral/crear/CrearHorario";
import EditarHorario from "../../../pages/complemento-nomina/horario-laboral/editar/EditarHorario";

// -- Reglas de horarios -- //
import ReglasHorarios from "../../../pages/complemento-nomina/reglas-horario/ReglasHorarios";
import CrearReglasHorarios from "../../../pages/complemento-nomina/reglas-horario/crear/CrearReglasHorarios";
import EditarReglasHorarios from "../../../pages/complemento-nomina/reglas-horario/editar/EditarReglasHorarios";

// --Leyendas --//
import Leyendas from "../../../pages/complemento-nomina/leyendas/Leyendas";
import CrearLeyenda from "../../../pages/complemento-nomina/leyendas/crear/CrearLeyenda";
import EditarLeyendas from "../../../pages/complemento-nomina/leyendas/editar/EditarLeyendas";
import TarjetaPresentacion from "../../../pages/complemento-nomina/tarjeta-presentacion/TarjetaPresentacion";

/* -- Consulta de traspasos -- */
import AjustesPrisma from "../../../pages/complemento-nomina/ajustesPrisma/AjustesPrisma";

/* Calculadora Layout */
import CalculadoraLayout from "../../../pages/complemento-nomina/calculadora-layout/CalculadoraLayout";

import MovimientosIssemym from "../../../pages/complemento-nomina/movimientos-issemym/Principal";

import CatalogoArchivosFirma from "../../../pages/complemento-nomina/catalogos-archivo-firma/CatalogoArchivosFirma";

/* conciliacion issemym */
import ConciliacionIssemym from "../../../pages/complemento-nomina/conciliacion-issemym/Inicio";

/* Consulta de pagos electronicos */
import ConsultaPagosElectronicos from "../../../pages/complemento-nomina/consulta-pagos-electronicos";

/* Conciliacion de Nominas */
import ConciliacionNominas from "../../../pages/complemento-nomina/conciliacion-nominas/Index";

/* Conciliacion de Nominas */
import Instituciones from "../../../pages/complemento-nomina/instituciones/Instituciones";

const rutasComplementoNomina = [
  //Configuracion de Calculo
  { path: "/template/config-calculo-nomina", component: ConfiguracionCalculo },
  {
    path: "/template/config-calculo-nomina/crear",
    component: CrearConfiguracionCalculo,
  },
  //Complemento Licencias
  { path: "/template/licencias-nomina", component: Licencias },
  { path: "/template/licencias-nomina/crear", component: CrearLicencia },
  { path: "/template/licencias-nomina/editar", component: EditarLicencia },
  //Complemento Multas
  { path: "/template/sanciones-nomina", component: Multas },
  { path: "/template/sanciones-nomina/crear", component: CrearMulta },
  { path: "/template/sanciones-nomina/editar", component: EditarMulta },
  //Complemento Incapacidades
  { path: "/template/incapacidades-nomina", component: Incapacidades },
  { path: "/template/incapacidades-nomina/crear", component: CrearIncapacidad },
  {
    path: "/template/incapacidades-nomina/editar",
    component: EditarIncapacidad,
  },
  //Complemento Pensiones
  { path: "/template/pensiones-nomina", component: Pensiones },
  { path: "/template/pensiones-nomina/crear", component: CrearPension },
  { path: "/template/pensiones-nomina/editar", component: EditarPension },
  //Complemento Proveedores Nomina
  { path: "/template/proveedoresnomina", component: ProveedoresNomina },
  {
    path: "/template/proveedoresnomina/crear",
    component: CrearProveedorNomina,
  },
  {
    path: "/template/proveedoresnomina/editar",
    component: EditarProveedorNomina,
  },
  //Complemento Prestamos
  { path: "/template/prestamos-nomina", component: Prestamo },
  { path: "/template/prestamos-nomina/crear", component: CrearPrestamo },
  { path: "/template/prestamos-nomina/editar", component: EditarPrestamo },
  //Complemento Seguros
  { path: "/template/seguros-nomina", component: Seguros },
  { path: "/template/seguros-nomina/crear", component: CrearSeguro },
  { path: "/template/seguros-nomina/editar", component: EditarSeguro },
  //Complemento Horario Laboral
  { path: "/template/horarios-nomina", component: HorarioLaboral },
  { path: "/template/horarios-nomina/crear", component: CrearHorario },
  { path: "/template/horarios-nomina/editar", component: EditarHorario },
  //Complemento Reglas horario
  { path: "/template/reglas-horarios", component: ReglasHorarios },
  { path: "/template/reglas-horarios/crear", component: CrearReglasHorarios },
  { path: "/template/reglas-horarios/editar", component: EditarReglasHorarios },
  //Complemento Leyendas
  { path: "/template/leyendas", component: Leyendas },
  { path: "/template/leyendas/crear", component: CrearLeyenda },
  { path: "/template/leyendas/editar", component: EditarLeyendas },
  //Tarjeta de Presentación
  { path: "/template/tarjeta-presentacion", component: TarjetaPresentacion },
  //Ajustes de prisma
  { path: "/template/ajusteprisma", component: AjustesPrisma },
  //Calculadora Layout
  { path: "/template/calculadora-layout", component: CalculadoraLayout },
  //Movimientos issemym
  { path: "/template/movimientos-issemym", component: MovimientosIssemym },
  //Catalogos de archivo firma
  {
    path: "/template/catalogo-archivos-firma",
    component: CatalogoArchivosFirma,
  },
  // conciliacion issemym
  { path: "/template/conciliacion-issemym", component: ConciliacionIssemym },
  //Consulta de pagos electrónicos
  { path: "/template/cep", component: ConsultaPagosElectronicos },
  /* Conciliacion de Nominas */
  { path: "/template/conciliacion-nominas", component: ConciliacionNominas },
  /* Conciliacion de instituciones */
  { path: "/template/instituciones", component: Instituciones },
];

export default rutasComplementoNomina;

import axios from "axios";
import { toast } from "react-toastify";
import Notification from "../components/Notification/Notification";
import { peticionEncript } from "./peticionesEncripted";

/**
 * Hacer una solicitud al back
 * @param {string} route - Ruta endpoint
 * @param {object} params - Parametros
 * @param {boolean} seeOk - (Opcional) Mostrar alerta cuando regresa un success
 * @param {boolean} seeError - (Opcional) False para ocultar la alerta cuando regresa un error
 * @param {boolean} encrypt - (Opcional) false para desactivar la ecriptacion
 * @param {string} errorMessage - (Opcional) Mensaje a mostrar en alerta de error
 * 
 * @returns {Promise<Array>}
 */
export async function doRequest(route, params, seeOk, seeError, encrypt, errorMessage) {
  let data = encrypt !== false ? peticionEncript(params) : params;
  try {
    const res = await axios.post(process.env.REACT_APP_API + route, data);
    if (res.status === 200 &&  ["success", "empty"].includes(res.data.status)) {
      if (seeOk !== false) {
        toast(
          <Notification
            type={"agrega_exito"}
            backMessage={cleanMessage(res.data.message)}
            withIcon
          />,
          { closeButton: false }
        );
      }
      return res.data.data;
    } else {
      if (seeError !== false) {
        toast(
          <Notification
            type={"consultar_error"}
            backMessage={errorMessage || res.data.message}
            withIcon
          />,
          { closeButton: false }
        );
      }
    }
  } catch (e) {
    toast(<Notification type={"consultar_servidor_error"} withIcon />, {
      closeButton: false,
    });
  }

  return [];
}

/**
 * Subir un archivo
 * @param {string|number} idRef - id del registro al que pertenecera el archivo
 * @param {"saveFile" | "saveFileReplace"} action - Forma en que se subira el archivo, saveFile: manteniene el historico | saveFileReplace: remplaza el archivo si existe
 * @param {string|number} keyFileType - Tipo de archivo
 * @param {blob} binary - Archivo
 * @param {boolean} seeOk - Mostrar alerta cuando regresa un success
 * @param {boolean} seeError - (Opcional) Mostrar alerta cuando regresa un error. True default
 * @param {string} type - (Opcional) Tipo de archivo a subir. Pdf si no se recibe
 * @returns {Promise<boolean>}
 */
export async function uploadFile(
  idRef,
  action,
  keyFileType,
  binary,
  seeOk,
  seeError,
  type
) {
  const file = new File([binary], "namfile."+(type||"pdf"));
  const formData = new FormData();
  formData.append("action", action);
  formData.append("reference", idRef);
  formData.append("keyFileType", keyFileType);
  formData.append("binary", file);
  try {
    const res = await axios.post(
      process.env.REACT_APP_API + "app/facades/files/fileReceiver.php",
      formData
    );
    if (res.status === 200 && res.data.status === "success") {
      if (seeOk) {
        toast(
          <Notification
            type={"elimina_exito"}
            backMessage={res.data.message}
            withIcon
          />,
          { closeButton: false }
        );
      }
      return true;
    } else {
      if (seeError !== false) {
        toast(
          <Notification
            type={"consultar_error"}
            backMessage={res.data.message}
            withIcon
          />,
          { closeButton: false }
        );
      }
    }
  } catch (err) {
    toast(<Notification type={"consultar_servidor_error"} withIcon />, {
      closeButton: false,
    });
  }
  return false;
}

/**
 * Hacer una solicitud al back y guardar toda la respuesta
 * @param {string} route - Ruta endpoint
 * @param {object} params - Parametros
 * @param {boolean} seeOk - Mostrar alerta cuando regresa un success
 * @param {false} seeError - (Opcional - false) Mostrar alerta cuando regresa un error
 * @param {true} withErrors - (Opcional - true) Devolver respuesta con errores. No muestra notificacion de error
 * @param {Object} config - (Opcional) Configuración de la solicitud
 * @param {boolean} encrypt - (Opcional) false para desactivar la ecriptacion
 */
export async function doRequestSaveRes(route, params, seeOk, seeError, withErrors, config, encrypt) {
  let data = encrypt !== false ? peticionEncript(params) : params;
  try {
    const res = await axios.post(
      process.env.REACT_APP_API + route,
      data,
      config
    )
    if(withErrors) return res.data;
    if (res.status === 200 && res.data.status === "success") {
      if (seeOk) {
        toast(
          <Notification
            type={"elimina_exito"}
            backMessage={res.data.message}
            withIcon
          />,
          { closeButton: false }
        );
      }
      return res.data;
    } else {
      if (seeError !== false) {
        toast(
          <Notification
            type={"consultar_error"}
            backMessage={res.data.message}
            withIcon
          />,
          { closeButton: false }
        );
      }
    }
  } catch (err) {
    toast(<Notification type={"consultar_servidor_error"} withIcon />, {
      closeButton: false,
    });
  }
  return "";
}

/**
 * Hacer una solicitud converitr numero a palabras
 * @param {string|number} monto - Cnatidad a convertir
 * @param {boolean} seeError - (Opcional - false) Mostrar alerta cuando regresa un error
 */
export async function numberSpelling(monto, seeError) {
  const params = {
    action: "numbertotext",
    number: monto,
  };
  let data = peticionEncript(params);
  try {
    const res = await axios.post(
      process.env.REACT_APP_API + "app/facades/tools/toolsF.php",
      data
    );
    if (res.status === 200 && res.data.status === "success") {
      return res.data.number;
    } else {
      if (seeError !== false) {
        toast(
          <Notification
            type={"consultar_error"}
            backMessage={res.data.message}
            withIcon
          />,
          { closeButton: false }
        );
      }
    }
  } catch (err) {
    toast(<Notification type={"consultar_servidor_error"} withIcon />, {
      closeButton: false,
    });
  }
  return "";
}


/**
 * Obener id de empelado del usario
 * @param {string|number} id - Id usuario
 * @param {string} cols (opcional) - Columnas extras a obtener
 * 
 * @returns {Object | null} Null si no encuentra el empleado
 */
export async function findEmployee(id, cols) {
  const params = {
    action: "multiselect",
    rows: `idStaff ` + (cols ? cols : ""),
    table: "users A join staff B USING(idStaff)",
    conditions: `idUser = ${id}`
  }
  const res = await doRequest("receiver/receiver.php", params, false, false);
  if(res.length > 0) return res[0];
  return null;
}

const mess = [
  "EXITO",
  "EXITO ",
  "Se ejecuto correctamente la consulta"
]

function cleanMessage(bm){
  if(mess.includes(bm)) return "Registro guardado correctamente";
  return bm;
}


/**
 * Hacer una solicitud a un sistema externo
 * @param {string} route - Ruta endpoint
 * @param {"GET"|"POST"|"PUT"|"DELETE"} method - Tipo de peticion
 * @param {object} params - Parametros
 * @param {boolean} seeOk - Mostrar alerta cuando regresa un success
 * @param {string} errorMsg - (Opcional) Mensaje de error
 * @param {Object} config - (Opcional) Configuración de la solicitud
 */
export async function outerRequest(route, method, params, seeOk, errorMsg, config) {
  try {
    const res = await typePeticion(route, method, params, config);
    if (res.status >= 200 && res.status <= 399) {
      if (seeOk) {
        toast(
          <Notification
            type={"elimina_exito"}
            backMessage={res.data.message}
            withIcon
          />,
          { closeButton: false }
        );
      }
      return res.data;
    }else{
      throw new Error(res.data);
    }
  } catch (err) {
    console.log(err)
    toast(<Notification type={"elimina_error"} backMessage={errorMsg || err.message} withIcon />, {
      closeButton: false,
    });
  }
  return "";
}


async function typePeticion(route, method, params, config){
  switch(method.toUpperCase()){
    case "GET": return axios.get(route, config);
    case "PUT": return axios.put(route, params, config);
    case "DELETE": return axios.delete(route, config);
    default: return axios.post(route, params, config);
  }
}

/**
 * Obtener un Archivo
 * @param {string} url - Url del archivo
 * @param {'blob' | 'arraybuffer' | 'document' | 'json' | 'text' | 'stream'} type - formato del archivo
 */
export async function getFileFromUrl(url, type){
  try{
    let res = await axios.get(url, { responseType: type });
    if(!res) throw new Error("No fue posible obtener el archivo");
    return res.data | null;
  }catch(err){
    toast(
      <Notification type={"elimina_exito"} backMessage={err.message | err} withIcon />, 
      { closeButton: false }
    );
  }
}
import React from "react";
import { useState } from "react";
import TableComponent from "../../../components/TableComponent/TableComponentFiltro";

export const Estados = () => {
  const cabeceras = ["Id", "Estado", "Clave SAT", "País", "Editar", "Eliminar"];
  const [filter] = useState([true, true, true, true]);
  const [parametros] = useState({
    action: "datatable",
    table: "states A INNER JOIN countries B USING (keyCountry)",
    rows: "keyState,nameState,claveSAT,nameCountry,keyCountry",
    conditions: "A.enabled = 1 AND B.enabled = 1",
    page: 0,
    records: 5,
    search: "",
    order: "",
  });

  return (
    <div>
      <TableComponent
        titulo="Estados"
        cabecerasTabla={cabeceras}
        filtro={filter}
        rutaCrear="/template/estados/crear"
        rutaEditar="/template/estados/editar"
        parametros={parametros}
      />
    </div>
  );
};
export default Estados;

import Widget from "../../../components/Widget/Widget";
import { useRef, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup"
import { Button, Col, Collapse, Row } from "reactstrap";
import { FilesInput } from "../../../components/GenericInputsFormik";
import { outerRequest } from "../../../helpers/requests";
import FullFormLoader from "../../../components/Loader/FullFormLoader";
import { newFile, processExcel } from "./processExcel";
import Datatable from "../../../components/Datatable/Datatable";
import { fMoney } from "../../../libs/tools/format";

const defaultForm = {
    origin: "",
    system: ""
};

const fileTypes = ["application/vnd.ms-excel", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"];

const FormSchema = Yup.object().shape({
    origin: testFile("documento expedido por la organización"),
    system: testFile("reporte de Nómina General Desglosado")
});

function testFile(type){
    return Yup.mixed()
    .required(`Cargue el ${type}`)
    .test("file-type", "El documento debe ser de tipo excel", (value) => {
        return value && fileTypes.includes(value.type);
    })
    .test({
      message: `El documento debe pesar menos de 20MB`,
      test: (value) => value?.size < 20000000,
    })
}

export default function ConciliacionNominas(){
    const [loading, setLoading] = useState({msg: "", value: false});
    const [show, setShow] = useState(false);
    const [data, setData] = useState([]);
    const [refresh, setRefresh] = useState(false);
    const refInpFileO = useRef(null);
    const refInpFileS = useRef(null);

    const {
        handleSubmit,
        values,
        handleBlur,
        errors,
        touched,
        setFieldValue,
        handleReset,
        setValues,
    } = useFormik({
        initialValues: defaultForm,
        validationSchema: FormSchema,
        onSubmit: () => compare(),
        onReset: ()=>{
            refInpFileO.current.value = ""; 
            refInpFileS.current.value = "";
            setShow(false);
            setData([]);
        },
        enableReinitialize: true
    });

    function onChange({target}){
        setFieldValue(target.name, target.files[0] || "");
    }

    async function compare(){
        setLoading({msg: "Procesando archivos...", value: true});
        const files = await processFiles();

        const params = new FormData();
        params.append("files", files[0]);
        params.append("files", files[1]);

        setLoading({msg: "Comparando archivos...", value: true});
        const res = await outerRequest("http://zeesign.net:8081/comparar", "POST", params, false, "No fue posible comparar los archivos");
        if(res){
            setData(res.hallazgos);
            setShow(true);
            setRefresh(true);
        }
        setLoading({msg: "", value: false});
    }

    async function processFiles(){
        const [rangeA, start] = await processExcel(values.system, "sistema", "Sheet1", 6);
        const nSistemas = await newFile(rangeA, "sistema", "Sheet1");

        const [rangeB, rm] = await processExcel(values.origin, "origen", "PO", start - Math.floor((start/4))*3);
        const nOrigen = await newFile(rangeB, "origen", "PO");
        return [nSistemas, nOrigen]
    }

    const columns = [
        {data: "nombre"},
        {data: "RFC"},
        {
            data: null, orderValue: "sistema", render: (row)=>{
                return(
                    <div>
                        {
                            row.diferencias ? 
                            row.diferencias.map((el, ix)=>(
                                <div key={row.RFC+"-"+ix} className="d-flex justify-content-between border-bottom">
                                    <p><strong>Clave:</strong> {el.clave}</p>
                                    <p><strong>Origen:</strong> {fMoney(el.origen)}</p>
                                    <p><strong>Sistema:</strong> {fMoney(el.sistema)}</p>
                                </div>
                            )) : 
                            row.error || "---"
                        }
                    </div>
                )
            }
        }
    ]

    return(
        <Widget className="widget-p-md">
            <h3 className="text-secondary">Conciliación de Nóminas</h3>
            <form onSubmit={handleSubmit} onReset={handleReset}>
                <Row>
                    <Col xs={12} lg={6}>
                        <FilesInput
                            label="Origen"
                            inputName="origin"
                            onChangeMethod={onChange}
                            onBlurMethod={handleBlur}
                            fileAccept="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                            isRequired
                            touched={touched.origin}
                            errors={errors.origin}
                            basicReq={false}
                            refInput={refInpFileO}
                        />
                    </Col>
                    <Col xs={12} lg={6}>
                        <FilesInput
                            label="Sistema"
                            inputName="system"
                            onChangeMethod={onChange}
                            onBlurMethod={handleBlur}
                            fileAccept="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                            isRequired
                            touched={touched.system}
                            errors={errors.system}
                            basicReq={false}
                            refInput={refInpFileS}
                        />
                    </Col>
                </Row>
                <div className=" d-flex flex-md-row flex-column-reverse justify-content-md-around">
                    <Button color="danger" type="reset" className="mt-3">
                        Limpiar
                    </Button>
                    <Button color="success" type="submit" className="mt-2 mt-md-3">
                        Comparar
                    </Button>
                </div>
            </form>
            <Collapse isOpen={show} className="mt-3">
                <Datatable
                    headers={["Nombre", "RFC", "Diferencias"]}
                    columns={columns}
                    data={data}
                    control="front"
                    stateRefresh={[refresh, setRefresh]}
                    searching={true}
                    className="px-0 mt-4"
                />
            </Collapse>
            <FullFormLoader show={loading.value} message={loading.msg} />
        </Widget>
    )
}
import {
  Document,
  Page,
  StyleSheet,
  Text,
  View,
  Font,
  Image
} from "@react-pdf/renderer";
import { peticionEncript } from "../../../../../../helpers/peticionesEncripted";
import { styles } from "../../../../../../components/Pdf/pdfGeneral/EstiloPdf";
import axios from "axios";
import { toast } from "react-toastify";
import Notification from "../../../../../../components/Notification/Notification";
import { peticionesReceiver } from "../../../../../../helpers/peticionesReceiver";

import HeaderReport from "../../../../../../components/Pdf/Header";

const routes = ["app/facades/reports/reportsF.php", "receiver/receiver.php"];

const API = peticionesReceiver();

Font.register({
  family: "Open Sans",
  fonts: [
    {
      src: "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-700.ttf",
      fontWeight: 700,
    },
  ],
});
let acumD = "";

const getStaff = async (data) => {
  let staff = [];
  const params = {
    action: "getStaffData",
    idStaff: data[0].idStaff,
  };
  await API.peticionEndPoint(params, "app/facades/reports/reportsF.php")
    .then((res) => {
      if (res.status === 200) {
        staff = res.data.data[0];
      } else {
        toast(
          <Notification
            type={"consultar_error"}
            backMessage={res.data.message}
            withIcon
          />
        );
      }
    })
    .catch((err) => {
      toast(<Notification type={"consultar_servidor_error"} withIcon />);
    });
  return staff;
};

const formatText = (text) => {
  let fg = new Date(text);
  return `${fg.getDay()}/${fg.getMonth()}/${fg.getFullYear()}`;
};

const consulta = async (params, route) => {
  const finalData = peticionEncript(params);
  let info = [];
  await axios
    .post(process.env.REACT_APP_API + routes[route], finalData)
    .then((res) => {
      if (res.status === 200 && res.data.code === "200") {
        info = res.data.data;
      }
      // else {
      //   if (show) {
      //     toast(
      //       <Notification
      //         type={"consultar_error"}
      //         backMessage={res.data.message}
      //         withIcon
      //       />,
      //       { closeButton: false }
      //     );
      //   }
      // }
    })
    .catch((err) => {
      toast(<Notification type={"consultar_servidor_error"} withIcon />, {
        closeButton: false,
      });
    });
  return info;
};

const formatter = new Intl.NumberFormat("es-MX", {
  style: "currency",
  currency: "MXN",
});

const s = StyleSheet.create({
  headerStyle: { height: "80px", backgroundColor: "#ABABAB" },
  titleStyle: {
    /*       fontFamily: "Open Sans", */
    fontWeight: "bold",
    border: "1px solid black",
    textAlign: "center",
    fontSize: "10px",
    marginBottom: "5px",
  },
  mtable: { margin: "10px" },
  row: { flexDirection: "row" },
  w15: { width: "15%" },
  w20: { width: "20%" },
  w30: { width: "30%" },
  w45: { width: "45%" },
  w50: { width: "50%" },
  w60: { width: "60%" },
  w65: { width: "65%" },
  w70: { width: "70%" },
  wAuto: { width: "auto" },
  tbold: { /* fontFamily: "Open Sans" ,*/ fontWeight: "bold" },
  tend: { textAlign: "right", paddingRight: "3px" },
  tstart: { textAlign: "left", paddingRight: "3px" },
  tcenter: { textAlign: "center" },
  border: { border: "1px solid black" },
  borderB: { borderBottom: "1px solid black" },
  borderE: { borderRight: "1px solid black" },
  fs8: { fontSize: "8px" },
  fs9: { fontSize: "9px" },
  fs10: { fontSize: "10px" },
  textBold: {
    fontFamily: "Helvetica-Bold",
    /* fontWeight: 900, */
    /* fontFamily: 'Courier-Bold' */
    /* color: 'red' */
  },
});
/* const getStaff = async (data) => {
    const params = { action: "getStaffData", idStaff: data[0].idStaff };
    const info = await consulta(params, 0);
    return info[0];
  }; */

const getLeyend = async (data) => {
  const params = {
    action: "select",
    table: "advertisements",
    condition: { period: data[0].period, year: data[0].year },
  };

  const info = await consulta(params, 1);
  const msg = info.length > 0 ? info[0].descripcion : "";
  return msg;
};

const clearLeyend = (leyend) => {
  return leyend.replaceAll("<P>", "").replaceAll("</P>", "");
};

const TableConcepto = ({ title, conceptos }) => {
  return (
    <>
      <View style={s.borderB}>
        <Text style={[s.tcenter, s.fs10, { paddingVertical: "1px" }]}>
          {title}
        </Text>
      </View>
      <View style={[s.row, s.borderB, { fontSize: "8px" }]}>
        <View style={[s.w15, s.tcenter]}>
          <Text>CLAVE</Text>
        </View>
        <View style={s.w65}>
          <Text>CONCEPTO</Text>
        </View>
        <View style={[s.w20, s.tcenter]}>
          <Text>IMPORTE</Text>
        </View>
      </View>
      <View style={[s.tbold, { fontSize: "8px", minHeight: "300px" }]}>
        
          <View key={conceptos[0].idPayslip} style={s.row}>
            <View style={[s.w15, s.tcenter, s.textBold]}>
              <Text>{conceptos[0].under}</Text>
            </View>
            <View style={[s.w65, s.textBold]}>
              <Text>{conceptos[0].nameUnder}</Text>
            </View>
            <View style={[s.w20, s.tend, s.textBold]}>
              <Text>{formatter.format(conceptos[0].amount)}</Text>
            </View>
          </View>
        
      </View>
    </>
  );
};
const TableConceptoDeduct = ({ title }) => {
  
  return (
    <>
      <View style={s.borderB}>
        <Text style={[s.tcenter, s.fs10, { paddingVertical: "1px" }]}>
          {title}
        </Text>
      </View>
      <View style={[s.row, s.borderB, { fontSize: "8px" }]}>
        <View style={[s.w15, s.tcenter]}>
          <Text>CLAVE</Text>
        </View>
        <View style={s.w65}>
          <Text>CONCEPTO</Text>
        </View>
        <View style={[s.w20, s.tcenter]}>
          <Text>IMPORTE</Text>
        </View>
      </View>
      <View style={[s.tbold, { fontSize: "8px", minHeight: "300px" }]}>
      </View>
    </>
  );
};

const insertQr = (urlQr) => {
  return (
      <View
            style={[
              styles.tableRow,
              { /* marginLeft: "auto", *//*  margin: "auto"  */},
            ]}
            wrap={false}
          >
            <View style={[{ width: "40%", height: "200", borderWidth: "2", flexDirection: "column", justifyContent: 'space-around', margin:"auto" }]}>
              <Image
                src={urlQr}
                style={{ /* width: "100%", height: "100%", */ padding: "5" }}
              />

              <Text style={{fontSize:"10",textAlign: "center", padding: "5", marginBottom: "5"}}>
              Válida este documento escaneando el siguiente código QR
              </Text>
            </View>
          </View>
  )
}

export async function generatePdfPensiones(data, model, title, fileNo) {
  const staff = await getStaff(data);
  const leyend = await getLeyend(data);
  return {
    report: (
      <>
        <Document>
          <Page style={styles.body}>
            <HeaderReport/>
            <View style={s.mtable}>
              <View style={s.titleStyle}>
                <Text style={s.textBold}>{title}</Text>
              </View>

              <View style={[s.row, s.fs10]}>
                <View style={[s.w30, s.tstart]}>
                  <Text>Nombre:</Text>
                </View>
                <View style={[s.w60, s.fs9]}>
                  <Text style={s.textBold}>{`${data[0].nombre}`}</Text>
                </View>
                <View style={[s.w30, s.tstart]}>
                  <Text>Clave de empleado:</Text>
                </View>
                <View style={[s.w60, s.fs9]}>
                  <Text style={s.textBold}>{staff.staffNumber}</Text>
                </View>
              </View>

              <View style={[s.row, s.fs10]}>
                <View style={[s.w30, s.tstart]}>
                  <Text>Clave ISSEMyM:</Text>
                </View>
                <View style={[s.w60, s.fs10]}>
                  <Text style={s.textBold}>{staff.socialSecurityKey}</Text>
                </View>
                <View style={[s.w30, s.tstart]}>
                  <Text>Adscripcion:</Text>
                </View>
                <View style={[s.w60, s.fs10]}>
                  <Text style={s.textBold}>{staff.nameArea}</Text>
                </View>
              </View>
              <View style={[s.row, s.fs10]}>
                <View style={[s.w30, s.tstart]}>
                  <Text>Fecha de Pago:</Text>
                </View>
                <View style={[s.w60, s.fs10]}>
                  {/* ----------------------------------------------- */}
                  <Text style={s.textBold}>
                    {formatText(staff.startJobDate)}
                  </Text>
                </View>
                <View style={[s.w30, s.tstart]}>
                  <Text>Período de Pago:</Text>
                </View>
                <View style={[s.w60, s.fs10]}>
                  <Text style={s.textBold}>{model.period}</Text>
                </View>
              </View>
              <View style={[s.row, s.fs10]}>
                <View style={[s.w30, s.tstart]}>
                  <Text>Puesto:</Text>
                </View>
                <View style={[s.w60, s.fs10]}>
                  <Text style={[s.textBold]}>{data[0].nameUnder}</Text>
                </View>
                <View style={[s.w30, s.tstart]}>
                  <Text>Total neto:</Text>
                </View>
                <View style={[s.w60, s.fs10]}>
                <Text style={[s.textBold]}>{formatter.format(data[0].amount)}</Text>
                </View>
              </View>

              <View style={[s.row, s.border, { marginTop: "15px" }]}>
                <View style={[s.w50, s.borderE]}>
                  <TableConcepto
                    title={"PERCEPCIONES"}
                    conceptos={data}
                  />
                </View>
                <View style={s.w50}>
                  <TableConceptoDeduct
                    title={"DEDUCCIONES"}
                  />
                </View>
              </View>

              <View style={[s.row, s.border, s.fs9, { marginTop: "3px" }]}>
                <View style={[s.w50, s.row, s.borderE]}>
                  <View style={s.w50}>
                    <Text style={{ paddingLeft: "3px" }}>
                      Total de percepciones
                    </Text>
                  </View>
                  <View style={[s.w50, s.tend, s.textBold, s.fs8]}>
                    <Text>{formatter.format(data[0].amount)}</Text>
                  </View>
                </View>
                <View style={[s.w50, s.row]}>
                  <View style={s.w50}>
                    <Text style={{ paddingLeft: "3px" }}>
                      Total de deducciones
                    </Text>
                  </View>
                  <View style={[s.w50, s.tend, s.textBold, s.fs8]}>
                    <Text>{formatter.format(acumD)}</Text>
                  </View>
                </View>
              </View>

              <View style={[s.border, s.fs10, { marginTop: "3px" }]}>
                <View style={[s.row, { padding: "8px" }]}>
                  <Text>
                  EL ABONO REALIZADO EN LA CUENTA{data[0].account} EL DÍA:
                  </Text>
                </View>
                <View style={{ padding: "8px" }}>
                  <Text>
                  CONSTITUYE EL RECIBO DE PAGO CORRESPONDIENTE.{"        "}
                    <Text style={s.textBold}>**************</Text>
                  </Text>
                </View>
              </View>
              <View
                style={[
                  s.border,
                  s.fs10,
                  { marginTop: "3px", minHeight: "100px", padding: "8px" },
                ]}
              >
                <Text>{clearLeyend(leyend)}</Text>
              </View>

              <View style={[s.fs10, { marginTop: "3px" }]}>
                <Text>COMPROBANTE PARA EL SERVIDOR PÚBLICO</Text>
              </View>
            </View>
            {model.pngUrl && insertQr(model.pngUrl)}
          </Page>
        </Document>
      </>
    ),
  };
}
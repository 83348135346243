
import React from "react";
import Widget from "../../../../components/Widget/Widget";
import FormPais from "../FormPais";
import { createCountry } from "../functions";

export const CrearPais = (props) => {

  return (
    <Widget className="widget-p-md">
        <h3>Crear país</h3>
        <FormPais crear={createCountry}/>
    </Widget>
  )
}

export default CrearPais;
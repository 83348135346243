import React from "react";
import { Document, Page, Text, View } from "@react-pdf/renderer";
import { styles } from "../../../../components/Pdf/pdfGeneral/EstiloPdf";
import HeaderReport from "../../../../components/Pdf/Header";

const formatter = new Intl.NumberFormat("es-MX", {
  style: "currency",
  currency: "MXN",
});

function getSheetData(data, filter) {
  let keys = [];
  let totalPer = 0;
  let totalDed = 0;
  filter.headers.forEach((h) => {
    filter.content.every((arr) => {
      let flg = true;
      arr.every((ob) => {
        if (ob.header === h) {
          keys.push([ob.value, ob.format]);
          flg = false;
          return false;
        }
        return true;
      });
      return flg;
    });
  });
  // let pageData = [];
  let dataPer = [];
  let dataDed = [];
  let reg = [];
  data.forEach((d) => {
    reg = keys.map((k) => {
      if (k[0] === "fullKey" && d.keyTypeUnder === "2") {
        return d.accountKey;
      }
      let tx = d[k[0]];
      switch (k[1]) {
        case "moneda":
          tx = formatter.format(tx);
          break;
        default:
      }
      return tx;
    });
    if (d.keyTypeUnder === "1") {
      dataPer.push(reg);
      totalPer += +d.ammount;
    } else if (d.keyTypeUnder === "2") {
      dataDed.push(reg);
      totalDed += +d.ammount;
    }
  });
  dataPer.sort((a,b)=> {return +a[0] - +b[0]});
  dataDed.sort((a,b)=> {return +a[0] - +b[0]});
  dataPer.push(["", "", "", "TOTAL:", formatter.format(totalPer)]);
  dataDed.push(["", "", "", "TOTAL:", formatter.format(totalDed)]);

  return [dataPer, dataDed];
}

function calcHeadStyle(id, width) {
  let colHeaderStyle = {
    width: `${width}%`,
    height: 20,
    borderStyle: "solid",
    borderRightWidth: 1,
    borderBottomWidth: 1,
    borderTopWidth: 1,
    backgroundColor: "#646464",
    color: "white",
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center",
    fontSize: 10,
  };
  if (id === 0) {
    colHeaderStyle.borderLeftWidth = 1;
  }
  switch (id){
    case 0:
      colHeaderStyle.width = "10%";
    break;
    case 1:
    case 3:
      colHeaderStyle.width = "15%";
    break;
    case 2:
      colHeaderStyle.width = "40%";
    break;
    case 4:
      colHeaderStyle.width = "20%";
    break;
    default:
  }
  return colHeaderStyle;
}

function calcDataStyle(id) {
  const colDataStyle = {
    width: "100%",
    height: "auto",
    borderStyle: "solid",
    borderRightWidth: 1,
    borderBottomWidth: 1,
    fontSize: 10,
  };
  if (id === 0) {
    colDataStyle.borderLeftWidth = 1;
  }

  switch (id){
    case 0:
      colDataStyle.width = "10%";
    break;
    case 1:
    case 3:
      colDataStyle.width = "15%";
    break;
    case 2:
      colDataStyle.width = "40%";
    break;
    case 4:
      colDataStyle.width = "20%";
    break;
    default:
  }
  return colDataStyle;
}

const insertTitles = (model, title) => {
  return (
    <>
      <HeaderReport/>
      <View>
        <Text style={styles.title}>{title}</Text>
      </View>
      <View>
        <Text style={styles.subTitle}>
          {model.filters !== undefined
            ? model.filters.onlyMessage === true
              ? model.filters.message
              : ""
            : ""}
        </Text>
      </View>
    </>
  );
};
export async function generatePdfGlobalPercepciones(
  data,
  model,
  title,
  fileNo
) {
  const info = getSheetData(data, model);
  const width = (100 / model.headers.length).toFixed(2);
  return {
    report: (
      <Document>
        <Page style={styles.body}>
          {insertTitles(model, title)}

          <View style={{ marginVertical: "5px" }}>
            <View>
              <Text
                style={{
                  width: "100%",
                  fontSize: "14px",
                  marginBottom: "2px",
                  textAlign: "center",
                }}
              >
                Percepciones
              </Text>
            </View>
            <View
              style={{
                display: "table",
                marginHorizontal: "10px",
                marginVertical: "5px",
              }}
            >
              {/* Cabeceras */}
              <View style={{ flexDirection: "row" }} wrap>
                {model.headers.map((el, id) => (
                  <View style={calcHeadStyle(id, width)} key={"h0"+id}>
                    <Text>{el}</Text>
                  </View>
                ))}
              </View>

              {info[0].map((row, id) => (
                <View style={{ flexDirection: "row" }} key={"p"+id}>
                  {row.map((col, idC) => (
                    <View style={calcDataStyle(idC)} key={"pr"+id+idC}>
                      {id === info[0].length-1 ? (
                        <Text
                          style={{
                            flexDirection: "row",
                            fontFamily: "Helvetica-Bold",
                            textAlign: [0, 1].includes(idC)
                              ? "center"
                              : [3, 4].includes(idC) && "right",
                          }}
                        >
                          {col}
                        </Text>
                      ) : (
                        <Text
                          style={{
                            flexDirection: "row",
                            textAlign: [0, 1].includes(idC)
                              ? "center"
                              : [3, 4].includes(idC) && "right",
                          }}
                        >
                          {col}
                        </Text>
                      )}
                    </View>
                  ))}
                </View>
              ))}
            </View>
          </View>

          <View style={{ marginVertical: "5px" }}>
            <View>
              <Text
                style={{
                  width: "100%",
                  fontSize: "14px",
                  marginBottom: "2px",
                  textAlign: "center",
                }}
              >
                Deducciones
              </Text>
            </View>
            <View
              style={{
                display: "table",
                marginHorizontal: "10px",
                marginVertical: "5px",
              }}
            >
              {/* Cabeceras */}
              <View style={{ flexDirection: "row" }} wrap>
                {model.headers.map((el, id) => (
                  <View style={calcHeadStyle(id, width)} key={"h1"+id}>
                    <Text>{el}</Text>
                  </View>
                ))}
              </View>

              {info[1].map((row, id) => (
                <View style={{ flexDirection: "row" }} key={"d"+id}>
                  {row.map((col, idC) => (
                    <View style={calcDataStyle(idC)} key={"dr"+id+idC}>
                      {id === info[1].length-1 ? (
                        <Text
                          style={{
                            flexDirection: "row",
                            fontFamily: "Helvetica-Bold",
                            textAlign: [0, 1].includes(idC)
                              ? "center"
                              : [3, 4].includes(idC) && "right",
                          }}
                        >
                          {col}
                        </Text>
                      ) : (
                        <Text
                          style={{
                            flexDirection: "row",
                            textAlign: [0, 1].includes(idC)
                              ? "center"
                              : [3, 4].includes(idC) && "right",
                          }}
                        >
                          {col}
                        </Text>
                      )}
                    </View>
                  ))}
                </View>
              ))}
            </View>
          </View>

          <View fixed style={styles.spaceFooter}></View>
          <View fixed style={styles.footer}>
            <Text style={styles.textFooter}>{fileNo}</Text>
            <Text
              style={styles.textFooter}
              render={({ pageNumber, totalPages }) =>
                `PAGINA : ${pageNumber} / ${totalPages}`
              }
            />
          </View>
        </Page>
      </Document>
    ),
  };
}

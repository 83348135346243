import { useEffect, useState } from "react";
import Datatable from "../../../components/Datatable/Datatable";
import { doRequestSaveRes } from "../../../helpers/requests";
import { Button, Col, Row } from "reactstrap";
import classNames from "classnames";
import sb from "../../adquisiciones/recepcion-cotizaciones/recepCot.module.scss";
import ModalViewFile from "../../../components/Modal/ModalViewFile";
import { ButtonInput } from "../../../components/GenericInputsFormik/ButtonInput";
import ModalTableVer from "../../../components/Modal/ModalTableVer";
import { toast } from "react-toastify";
import Notification from "../../../components/Notification/Notification";
import ModalFirma from "./ModalFirma";
import ModalSigneds from "./ModalSigneds";
import { SelectTypeHeadSingle } from "../../../components/GenericInputsFormik";
import { peticionesReceiver } from "../../../helpers/peticionesReceiver";

const titles = ["Regiones", "Areas"];

const headersModal = [
  ["Id", "Región", "Seleccionar"],
  ["Id", "Unidad de trabajo", "Seleccionar"],
];
const tabParams = [
  {
    table: "regions",
    rows: "keyRegion, nameRegion",
  },
  {
    table: "areas",
    rows: "idArea, name",
  },
];

export default function TableCheck({
  headers,
  columns,
  params,
  columnDefs,
  permissions,
  setLoading,
}) {
  const [selected, setSelected] = useState([]);
  const [region, setRegion] = useState({ id: "", name: "" });
  const [area, setArea] = useState({ id: "", name: "" });
  const [fileType, setFileType] = useState({ id: "", name: "" });
  const [refresh, setRefresh] = useState(false);
  const [all, setAll] = useState(false);
  const [fileTypes, setFileTypes] = useState([]);


  const API = peticionesReceiver()

  const [linkFile, setLinkFile] = useState(""); ///Link de archivo
  const [modalFile, setModalFile] = useState(false); ///Visualziar archivos

  const [modalTable, setModalTable] = useState(false);
  const [modalTableTitle, setModalTableTitle] = useState("");
  const [modalTableCabeceras, setModalTableCabeceras] = useState([]);
  const [modalTableParams, setModalTableParams] = useState("");
  const [table, setTable] = useState("");

  const [modalSign, setModalSign] = useState(false);

  const [modalSigned, setModalSigned] = useState(false);
  const [filesSigneds, setFilesSigneds] = useState({
    ok: 0,
    error: 0,
    list: [],
  });


  


  useEffect(() => {
    getFileTypes()
    
  }, []);

  const getFileTypes = async () => {
    params = {
        action: "multiselect",
        table: "filesignsetup A INNER JOIN filetypes B on  ( A.keyFileType = B.keyFileType)",
        rows: "B.keyFileType, B.nameFyleType",
        conditions: `A.enabled = 1 and B.enabled = 1`
    }
    
    
    await API.peticion(params)
    .then((res) => {
      if (res.status === 200 && res.data.code === "200") {
        setFileTypes(res.data.data)
      } else {
        toast(<Notification type={"consultar_error"} withIcon />);
      }
    })
    .catch((err) => {
      toast(<Notification type={"consultar_servidor_error"} withIcon />);
    });
  

  };

  async function getData(ordCol, itemsPerPage, currentPage, search) {
    
    const res = await doRequestSaveRes(
      "receiver/receiver.php",
      getParams(ordCol, itemsPerPage, currentPage, search),
      false,
      false
    );
    if (res.code === "200") return res;
    else return null;
  }

  function getParams(ordCol, itemsPerPage, currentPage, search) {
    const paramsq = {
      ...params,
      order: ordCol,
      records: itemsPerPage.toString(),
      page: (itemsPerPage * currentPage).toString(),
      search,
      conditions: getConditions(params.conditions),
    };
    return paramsq;
  }

  function getConditions(c) {
    let conditions = c;
    if (region.id !== "") conditions += ` AND K.keyRegion = ${region.id}`;
    if (area.id !== "") conditions += ` AND C.idArea = ${area.id}`;
    /* if (fileType.id !== "")  */conditions += ` AND A.keyFileType = ${fileType.id}`;
    return conditions;
  }

  function selectPDF(row) {
    const selectedCpy = [...selected];
    const ix = selected.findIndex((el) => el.reference === row.reference);
    if (ix === -1) {
      selectedCpy.push(row);
    } else {
      selectedCpy.splice(ix, 1);
    }
    setSelected(selectedCpy);
  }

  const defColumns = [
    {
      data: null,
      render: function (row) {
        return (
          <div className="form-check checkbox checkbox-dark-gray d-flex justify-content-center">
            <input
              type="checkbox"
              className="styled"
              checked={
                all || selected.findIndex((el) => el.src === row.src) !== -1
              }
              onChange={({ target }) => selectPDF(row)}
            />
            <label />
          </div>
        );
      },
    },
    ...columns,
    {
      data: null,
      render: function (row) {
        return (
          <div
            className={classNames(["text-center pl-2 mx-auto", sb.colAction])}
          >
            <button
              className={classNames([
                "btn btn-secondary text-light text-center px-2 my-3",
                sb.myBtn,
              ])}
              onClick={() => {
                setLinkFile(row.src);
              }}
            >
              <i
                className={classNames(["fa fa-file-pdf-o  my-1 mr-1", sb.x2])}
              />
              <span>Ver</span>
            </button>
          </div>
        );
      },
    },
  ];

  const defheaders = [
    <HeaderCheck all={all} setAll={setAll} setSelected={setSelected} />,
    ...headers,
    "Ver Archivo",
  ];

  useEffect(() => {
    if (linkFile !== "") {
      setModalFile(true);
    }
  }, [linkFile]);
  

  useEffect(() => {
    if (!modalFile) {
      setLinkFile("");
    }
  }, [modalFile]);

  function selectTable(n) {
    setModalTableParams({
      action: "datatable",
      table: tabParams[n].table,
      rows: tabParams[n].rows,
      conditions: "enabled = 1",
      page: 0,
      records: 5,
      search: "",
      order: "",
    });
    setModalTableTitle(titles[n]);
    setModalTableCabeceras(headersModal[n]);
    setTable(n);
    setModalTable(true);
  }

  function selectRow(r) {
    setModalTable(false);
    if (table === 0) setRegion({ id: r[0], name: r[1] });
    else if (table === 1) setArea({ id: r[0], name: r[1] });
    setRefresh(true);
  }

  async function signFumps() {
    if (!all && selected.length < 1) {
      toast(
        <Notification
          type={"warning"}
          backMessage="Seleccione un archivo para firmar"
          withIcon
        />,
        { closeButton: false }
      );
      return;
    }
    setModalSign(true);
  }

  return (
    <div>
      <Row className="d-flex justify-content-center">
        <Col xs={12} md={6}>
          <SelectTypeHeadSingle
            label="Tipo de archivo"
            isRequired={true}
            inputName="fileType"
            optionsArray={fileTypes}
            defaultOption="Seleccione un tipo de archivo"
            onChangeMethod={(e) => {
              setFileType({id: e.target.value, name: e.target.value})
              setRefresh(true)
            }}
            /* onBlur={handleBlur} */
            isDisabled={false}
            value={fileType.id}
            /* touched={touched.keyStat}
            errors={errors.keyStat} */
            optionValue="keyFileType"
            optionName="nameFyleType"
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={6}>
          <ButtonInput
            label="Región"
            inputName="region"
            value={region.name}
            isRequired
            setModalTable={setModalTable}
            clearFun={() => {
              setRegion({ id: "", name: "" });
              setRefresh(true);
            }}
            onButtonClicik={() => selectTable(0)}
          />
        </Col>
        <Col xs={12} md={6}>
          <ButtonInput
            label="Unidad de trabajo"
            inputName="area"
            value={area.name}
            isRequired
            setModalTable={setModalTable}
            clearFun={() => {
              setArea({ id: "", name: "" });
              setRefresh(true);
            }}
            onButtonClicik={() => selectTable(1)}
          />
        </Col>
      </Row>

      {fileType.id !== "" && (
        <>
        <div className="text-center my-3">
        {permissions.UPD && (
          <Button color="success" onClick={() => signFumps()}>
            Firmar
          </Button>
        )}
      </div>

      <Datatable
          headers={defheaders}
          columns={defColumns}
          order={{ col: 9, opt: "asc" }}
          columnDefs={columnDefs}
          petition={getData}
          control="back"
          stateRefresh={[refresh, setRefresh]}
          searching={true}
          className="mt-4 px-0"
        />
        </>

      )}

      <ModalTableVer
        modal={modalTable}
        setModal={setModalTable}
        title={modalTableTitle}
        cabeceras={modalTableCabeceras}
        filtro={[true, true, true]}
        parametros={modalTableParams}
        selectRegistro={(row) => selectRow(row)}
      />

      <ModalViewFile
        modal={modalFile}
        setModal={setModalFile}
        backdrop={true}
        keyboard={true}
        btnClose={true}
        fileLink={linkFile}
      />

      <ModalFirma
        modal={modalSign}
        setModal={setModalSign}
        selected={selected}
        setselected={setSelected}
        all={all}
        setAll={setAll}
        paramsAll={params}
        getConditions={getConditions}
        setLoading={setLoading}
        setRefresh={setRefresh}
        setFilesSigneds={setFilesSigneds}
        setModalSigned={setModalSigned}
      />

      <ModalSigneds
        modal={modalSigned}
        setModal={setModalSigned}
        headers={headers}
        columns={columns}
        columnDefs={columnDefs}
        filesSigneds={filesSigneds}
      />
    </div>
  );
}

function HeaderCheck({ all, setAll, setSelected }) {
  const selectAll = () => {
    if (!all) setSelected([]);
    setAll(!all);
  };
  return (
    <div className="form-check checkbox checkbox-primary d-flex justify-content-center">
      <input
        id="all"
        type="checkbox"
        className="styled"
        checked={all}
        onChange={() => selectAll()}
      />
      <label />
    </div>
  );
}

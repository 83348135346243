/**
 * Permisos utilizados por cada modulo
 * @exports Array<Object> actionsModules - Arreglo de modulos con acciones desactivadas
 * @exports Array<Object> dependientPermissions - Arreglo de modulos con permisos que dependen de otro
 */
export const actionsModules = [
    // ADMIN. USUARIOS
    {
        idModule: 5,
        name: "PERFILES MODULOS",
        permissions: {
            CON: true,
            INS: false,
            UPD: true,
            DEL: false,
        },
    },
    {
        idModule: 42,
        name: "PERMISOS USUARIOS",
        permissions: {
            CON: true,
            INS: true,
            UPD: false,
            DEL: true,
        },
    },
    // ADQUISICIONES
    {
        idModule: 177,
        name: "AUTORIZACION COTIZACIONES",
        permissions: {
            CON: true,
            INS: false,
            UPD: true,
            DEL: false,
        },
    },
    {
        idModule: 171,
        name: "RECEPCION COTIZACIONES",
        permissions: {
            CON: true,
            INS: false,
            UPD: true,
            DEL: false,
        },
    },
    {
        idModule: 283,
        name: "EXISTENCIAS ALMACÉN",
        permissions: {
            CON: true,
            INS: false,
            UPD: true,
            DEL: false,
        },
    },
    {
        idModule: 290,
        name: "RECEPCION DE SERVICIOS",
        permissions: {
            CON: true,
            INS: false,
            UPD: true,
            DEL: false,
        },
    },
    // COMPLEMENTOS DE NOMINA
    {
        idModule: 142,
        name: "AJUSTE DE PRISMA",
        permissions: {
            CON: true,
            INS: true,
            UPD: false,
            DEL: true,
        },
    },
    {
        idModule: 80,
        name: "CONFIGURACION DE CALCULO",
        permissions: {
            CON: true,
            INS: true,
            UPD: false,
            DEL: false,
        },
    },
    {
        idModule: 303,
        name: "CONSULTA DE PAGOS ELECTRÓNICOS",
        permissions: {
            CON: true,
            INS: false,
            UPD: false,
            DEL: false,
        },
    },
    {
        idModule: 109,
        name: "PROVEEDORES DE NOMINA",
        permissions: {
            CON: true,
            INS: true,
            UPD: true,
            DEL: false,
        },
    },
    {
        idModule: 155,
        name: "TARJETA DE PRESENTACIÓN",
        permissions: {
            CON: true,
            INS: false,
            UPD: false,
            DEL: false,
        },
    },
    {
        idModule: 282,
        name: "CONCILIACIÓN ISSEMYM",
        permissions: {
            CON: true,
            INS: true,
            UPD: false,
            DEL: false,
        },
    },
    {
        idModule: 287,
        name: "RECEPCIÓN DE TRASPASOS",
        permissions: {
            CON: true,
            INS: false,
            UPD: true,
            DEL: false,
        },
    },
    {
        idModule: 306,
        name: "CONCILIACIÓN DE NÓMINAS",
        permissions: {
            CON: true,
            INS: false,
            UPD: false,
            DEL: false,
        },
    },
    {
        idModule: 236,
        name: "MOVIMIENTOS ISSEMYM",
        permissions: {
            CON: true,
            INS: false,
            UPD: false,
            DEL: false,
        },
    },
    {
        idModule: 83,
        name: "REGLAS HORARIOS",
        permissions: {
            CON: true,
            INS: true,
            UPD: true,
            DEL: false,
        },
    },
    // COMPLEMENTOS DE PERSONAL
    {
        idModule: 154,
        name: "CONSULTA DE DATOS",
        permissions: {
            CON: true,
            INS: false,
            UPD: false,
            DEL: false,
        },
    },
    {
        idModule: 100,
        name: "ESTATUS EMPLEADOS",
        permissions: {
            CON: true,
            INS: false,
            UPD: true,
            DEL: false,
        },
    },
    {
        idModule: 314,
        name: "PERMUTAR EMPELADOS",
        permissions: {
            CON: true,
            INS: true,
            UPD: false,
            DEL: false,
        },
    },
    // NOMINA
    {
        idModule: 36,
        name: "ARCHIVOS DE DISPERSIÓN",
        permissions: {
            CON: true,
            INS: true,
            UPD: false,
            DEL: true,
        },
    },
    {
        idModule: 156,
        name: "Incremento salarial",
        permissions: {
            CON: true,
            INS: true,
            UPD: false,
            DEL: true,
        },
    },
    {
        idModule: 32,
        name: "CALCULO",
        permissions: {
            CON: true,
            INS: true,
            UPD: false,
            DEL: false,
        },
    },
    {
        idModule: 67,
        name: "CARGA LAYOUTS",
        permissions: {
            CON: true,
            INS: true,
            UPD: false,
            DEL: false,
        },
    },
    {
        idModule: 101,
        name: "COMPROBANTES DE PAGO",
        permissions: {
            CON: true,
            INS: false,
            UPD: false,
            DEL: false,
        },
    },
    {
        idModule: 34,
        name: "REPORTES NOMINA",
        permissions: {
            CON: true,
            INS: false,
            UPD: false,
            DEL: false,
        },
    },
    {
        idModule: 235,
        name: "CALCULADORA PARA LAYOUT",
        permissions: {
            CON: true,
            INS: false,
            UPD: false,
            DEL: false,
        },
    },
    {
        idModule: 160,
        name: "CALCULAR FOREMEX",
        permissions: {
            CON: true,
            INS: false,
            UPD: false,
            DEL: false,
        },
    },
    {
        idModule: 159,
        name: "CONSULTA POR EMPLEADOS",
        permissions: {
            CON: true,
            INS: false,
            UPD: false,
            DEL: false,
        },
    },
    {
        idModule: 161,
        name: "GUARDAR FOREMEX",
        permissions: {
            CON: true,
            INS: true,
            UPD: false,
            DEL: false,
        },
    },
    {
        idModule: 152,
        name: "SOLICITUD DE PAGO",
        permissions: {
            CON: true,
            INS: true,
            UPD: false,
            DEL: false,
        },
    },
    {
        idModule: 137, /// Nomina
        name: "PAGOS EN TRASFERENCIAS DE EMPLEADOS",
        permissions: {
            CON: true,
            INS: false,
            UPD: false,
            DEL: false,
        },
    },
    {
        idModule: 139, /// Nomina
        name: "PAGOS EN TRASFERENCIAS DE PENSIONES",
        permissions: {
            CON: true,
            INS: false,
            UPD: false,
            DEL: false,
        },
    },
    {
        idModule: 149, /// Nomina
        name: "PAGO DE PROVEEDORES DE NÓMINA",
        permissions: {
            CON: true,
            INS: false,
            UPD: false,
            DEL: false,
        },
    },
    {
        idModule: 144, /// Nomina
        name: "GENERACIÓN DE CHEQUES",
        permissions: {
            CON: true,
            INS: false,
            UPD: false,
            DEL: false,
        },
    },
    {
        idModule: 209,
        name: "FIRMA DE ARCHIVOS",
        permissions: {
            CON: true,
            INS: false,
            UPD: true,
            DEL: false,
        },
    },
    {
        idModule: 32,
        name: "CALCULO",
        permissions: {
            CON: true,
            INS: true,
            UPD: false,
            DEL: false,
        },
    },
    {
        idModule: 210,
        name: "LAYOUT PARA TIMBRAR",
        permissions: {
            CON: true,
            INS: true,
            UPD: false,
            DEL: true,
        },
    },
    {
        idModule: 82,
        name: "REPORTES DE PERSONAL",
        permissions: {
            CON: true,
            INS: false,
            UPD: false,
            DEL: false,
        },
    },
    {
        idModule: 310,
        name: "CARGA DE CONSTANCIAS",
        permissions: {
            CON: true,
            INS: false,
            UPD: true,
            DEL: false,
        },
    },
    {
        idModule: 308,
        name: "REGISTRO DE FINIQUITOS",
        permissions: {
            CON: true,
            INS: true,
            UPD: true,
            DEL: false,
        },
    },
    // FINANZAS
    {
        idModule: 128,
        name: "Consulta de polizas",
        permissions: {
            CON: true,
            INS: false,
            UPD: false,
            DEL: false,
        },
    },
    {
        idModule: 103,
        name: "REPORTES FINANZAS",
        permissions: {
            CON: true,
            INS: true,
            UPD: true,
            DEL: true,
        },
    },
    {
        idModule: 151,
        name: "SOLICITUD DE PAGO",
        permissions: {
            CON: true,
            INS: false,
            UPD: true,
            DEL: false,
        },
    },
    {
        idModule: 140, /// Finanzas
        name: "GENERACIÓN DE CHEQUES",
        permissions: {
            CON: true,
            INS: true,
            UPD: false,
            DEL: false,
        },
    },
    // PERSONAL
    {
        idModule: 123,
        name: "CONTRATOS DE HONORARIOS",
        permissions: {
            CON: true,
            INS: true,
            UPD: true,
            DEL: false,
        },
    },
    {
        idModule: 104,
        name: "Gafetes",
        permissions: {
            CON: true,
            INS: true,
            UPD: true,
            DEL: false,
        },
    },
    {
        idModule: 122,
        name: "OFICIOS  SERVICIOS SOCIAL",
        permissions: {
            CON: true,
            INS: true,
            UPD: true,
            DEL: false,
        },
    },
    {
        idModule: 82,
        name: "REPORTES DE PERSONAL",
        permissions: {
            CON: true,
            INS: false,
            UPD: false,
            DEL: false,
        },
    },
    {
        idModule: 145, /// Finanzas
        name: "PAGO DE PROVEEDORES DE NÓMINA",
        permissions: {
            CON: true,
            INS: true,
            UPD: true,
            DEL: false,
        },
    },
    {
        idModule: 265,
        name: "AUTORIZACIÓN DE INCIDENCIAS",
        permissions: {
            CON: true,
            INS: false,
            UPD: true,
            DEL: false,
        },
    },
        // PERSONAL - EXPEDIENTE PERSONAL
    {
        idModule: 215,
        name: "EP-DATOS GENERALES",
        permissions: {
            CON: true,
            INS: true,
            UPD: true,
            DEL: false,
        },
    },
    {
        idModule: 216,
        name: "EP-ASIGNACION DE PLAZAS",
        permissions: {
            CON: true,
            INS: true,
            UPD: true,
            DEL: false,
        },
    },
    {
        idModule: 217,
        name: "EP-DOMICILIOS",
        permissions: {
            CON: true,
            INS: true,
            UPD: false,
            DEL: false
        },
    },
    {
        idModule: 218,
        name: "EP-CONTACTOS",
        permissions: {
            CON: true,
            INS: true,
            UPD: false,
            DEL: false,
        },
    },
    {
        idModule: 220,
        name: "EP-CUENTAS BANCAIRAS",
        permissions: {
            CON: true,
            INS: true,
            UPD: false,
            DEL: false,
        },
    },
    {
        idModule: 221,
        name: "EP-SEGURO",
        permissions: {
            CON: true,
            INS: true,
            UPD: true,
            DEL: false,
        },
    },
    {
        idModule: 224,
        name: "EP-DOCUMENTOS",
        permissions: {
            CON: true,
            INS: true,
            UPD: false,
            DEL: true,
        },
    },
    {
        idModule: 225,
        name: "EP-FORMATOS",
        permissions: {
            CON: true,
            INS: false,
            UPD: false,
            DEL: false,
        },
    },
    // PLANEACION
    {
        idModule: 111,
        name: "CONSULTA DE AVANCES",
        permissions: {
            CON: true,
            INS: false,
            UPD: false,
            DEL: false,
        },
    },
    {
        idModule: 141,
        name: "HABILITAR EDICION",
        permissions: {
            CON: true,
            INS: false,
            UPD: true,
            DEL: false,
        },
    },
    {
        idModule: 107,
        name: "VALIDACION DE PROYECTOS",
        permissions: {
            CON: true,
            INS: false,
            UPD: true,
            DEL: false,
        },
    },
    // PRESUPUESTO
    {
        idModule: 130,
        name: "CONSULTA DE TRASPASOS",
        permissions: {
            CON: true,
            INS: false,
            UPD: false,
            DEL: false,
        },
    },
    {
        idModule: 124,
        name: "ADECUACIONES PRESUPUESTALES",
        permissions: {
            CON: true,
            INS: true,
            UPD: true,
            DEL: false,
        },
    },
    {
        idModule: 253,
        name: "DICTAMEN PRESUPUESTAL",
        permissions: {
            CON: true,
            INS: false,
            UPD: true,
            DEL: false,
        },
    },
    // TESORERIA
    {
        idModule: 94,
        name: "CUENTAS BANCARIAS",
        permissions: {
            CON: true,
            INS: true,
            UPD: true,
            DEL: false,
        },
    },
    // PERCEPCIONES EXTRAORDINARIAS
    {
        idModule: 302,
        name: "CALCULO PERCEPCIONES EXTRAORDINARIAS",
        permissions: {
            CON: true,
            INS: true,
            UPD: false,
            DEL: false,
        },
    },
    {
        idModule: 301,
        name: "PAGOS PERCEPCIONES EXTRAORDINARIAS",
        permissions: {
            CON: true,
            INS: false,
            UPD: true,
            DEL: true,
        },
    },
    
];

/*
 * Modulos en los que el permiso de consulta es obligatorio
 * para realizar cualquiera de las otras acciones
 */
export const dependientPermissions = {
    /* ADMIN. USUARIOS */
    m5: { name: "PERFILES MODULOS" },

    /* ALMACENES */
    m283: { name: "EXISTENCIAS ALMACÉN" },
    m287: { name: "RECEPCIÓN DE TRASPASOS" },
    m290: { name: "RECEPCIÓN DE SERVICIOS" },

    /* ADQUISICIONES */
    m171: { name: "RECEPCION COTIZACIONES" },
    m177: { name: "AUTORIZACION COTIZACIONES" },

    /* COMPLEMENTOS DE NOMINA */
    m209: { name: "FIRMA DE ARCHIVOS" },
    m83: { name: "REGLAS HORARIOS" },

    /* FINANZAS */
    m129: { name: "PAGOS EN TRASFERENCIAS DE EMPLEADOS" },
    m129: { name: "PAGOS EN TRASFERENCIAS DE EMPLEADOS" },
    m138: { name: "PAGOS EN TRASFERENCIAS DE PENSIONES" },
    m145: { name: "PAGO DE PROVEEDORES DE NÓMINA" },
    m140: { name: "GENERACIÓN DE CHEQUES" },

    /* NOMINA */
    m32: { name: "CALCULO", inverse: ["INS"] },
    m67: { name: "CARGA LAYOUTS", inverse: ["INS"] },
        // NOMINA - EXPEDIENTE DE NOMINA
        m229: { name: "EN - ESTANCIAS" },
        m226: { name: "EN - LICENICAS" },
        m228: { name: "EN - MULTAS" },
        m237: { name: "EN - PRESTAMOS" },
        m231: { name: "EN- INASISTENCIAS" },
        m227: { name: "EN- INCAPACIDADES" },
        m232: { name: "EN- PRESTACIONES GENERALES" },
        m230: { name: "EN-PENSIONES" },
        m233: { name: "EN-SEGUROS" },
    m310: { name: "CARGA DE CONSTANCIAS",},


    /* PRESUPUESTO */
    m253: { name: "DICTAMEN PRESUPUESTAL" },

    /* PERSONAL */
    m265: { name: "AUTORIZACIÓN DE INCIDENCIAS" },
    m100: {name: "ESTATUS EMPLEADOS"},
        // PERSONAL - EXPEDIENTE PERSONAL
        m215: { name: "EP-DATOS GENERALES" },
        m216: { name: "EP-ASIGNACION DE PLAZAS" },
        m217: { name: "EP-DOMICILIOS" },
        m218: { name: "EP-CONTACTOS" },
        m219: { name: "EP-FAMILIARES" },
        m220: { name: "EP-CUENTAS BANCAIRAS" },
        m221: { name: "EP-SEGURO" },
        m222: { name: "EP-ESCOLARIDADES" },
        m223: { name: "EP-EXPERIENCIA LABORAL" },
        m224: { name: "EP-DOCUMENTOS" },
        m225: { name: "EP-FORMATOS" },
        m234: { name: "EP- SEGUROS ESP" },
    
    // PERCEPCIONES EXTRAORDINARIAS
    m302: { name: "CALCULO PERCEPCIONES EXTRAORDINARIAS", inverse: ["INS"] },
    m301: { name: "PAGOS PERCEPCIONES EXTRAORDINARIAS" },
};

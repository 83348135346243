
import React from "react";

import Widget from "../../../../components/Widget/Widget";
import FormLeyenda from "../FormLeyenda";
import { updateLegend,getLegend } from "../functions";
import { useHistory } from "react-router-dom";

export const EditarLeyendas = () => {
  const history = useHistory();
  
  const getData = localStorage.getItem('dataContent')
  const data = getData ? getData.split('|-|') : history.goBack()

  return (
    <Widget className="widget-p-md">
        <h3>Editar Leyenda</h3>
        <FormLeyenda 
          data={data}
          obtener = {getLegend}
          actualizar={updateLegend} 
        />
    </Widget>
  )
}

export default EditarLeyendas;
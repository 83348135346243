import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Button, Collapse, Row, Col } from "reactstrap";
import { peticionesReceiver } from "../../../../helpers/peticionesReceiver";

import { toast } from "react-toastify";
import Notification from "../../../../components/Notification/Notification";
import ModalConfirmation from "../../../../components/Modal/ModalConfirmation";
import { TextInput } from "../../../../components/GenericInputsFormik/";




const FormTemas = (props) => {
  const { collapse, setCollapse, setRefreshP, data, setData, permissions } =
    props;
  const API = peticionesReceiver();
  const [modalConfirmation, setModalConfirmation] = useState(false);
  const [editando, setEditando] = useState(false);

  useEffect(() => {
    if (data !== "") getForEdit(data);
  }, [data]);

  const FormSchema = Yup.object().shape({
    nameTopic: Yup.string().required("Ingrese el nombre del tema"),
  });

  const {
    handleSubmit,
    values,
    errors,
    setFieldValue,
    touched,
    handleBlur,
    resetForm,
  } = useFormik({
    initialValues: {
      nameTopic: "",
    },
    onSubmit: () => {
      setModalConfirmation(true);
    },
    validationSchema: FormSchema,
    enableReinitialize: true,
  });

  const onChange = (e) => {
    const { name, value } = e.target;
    setFieldValue([name], value);
  };

  const getForEdit = async (row) => {
    if (!collapse) {
      setFieldValue("nameTopic", row.nameTopic);
      setFieldValue("keyTopic", row.keyTopic);
      setEditando(true);
      setCollapse(true);
    }
  };

  const resetFormuario = () => {
    setEditando(false);
    setCollapse(false);
    setFieldValue("nameTopic", "");
    setFieldValue("keyTopic", "");
    resetForm();
    setData("");
    // setFieldValue("keyTypePurchase", "");
    // setFieldValue("nameTypePurchase", "");
    // setFieldValue("minPayment", "");
    // setFieldValue("maxPayment", "");
  };

  const saveTopic = async () => {
    const data =
      !editando === true
        ? {
            action: "insert",
            table: "topics",
            rows: {
              nameTopic: values.nameTopic,
            },
            validate: [["nameTopic"]],
          }
        : {
            action: "update",
            table: "topics",
            rows: {
            nameTopic: values.nameTopic,
            },
            condition: {
              keyTopic: values.keyTopic,
            },
            validate: [["nameTopic"]],
          };
    const res = await API.peticion(data);
    if (res.status === 200 && res.data.status === "success") {
      toast(
        <Notification
          type={"agrega_exito"}
          backMessage={res.data.message}
          withIcon
        />
      );
    //   if (editando) {
    //     setRefreshP(true);
    //     setData(res.data.data[0]);
    //   } else {
    //     setRefreshP(true);
    //     setData(res.data.data[0]);
    //   }
       setRefreshP(true);
       resetFormuario();
    } else {
      toast(
        <Notification
          type={"agrega_error"}
          backMessage={res.data.message}
          withIcon
        />
      );
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <Row className="justify-content-around">
          <Col xs={12} lg={6}>
            <TextInput
              label="Nombre del tema "
              inputType="text"
              inputName="nameTopic"
              onChangeMethod={onChange}
              onBlurMethod={handleBlur}
              value={values.nameTopic}
              touched={touched.nameTopic}
              errors={errors.nameTopic}
            />
          </Col>
        </Row>

        <div className="d-flex justify-content-around mt-2">
            <div>
              <Button
                color="danger"
                onClick={() => {
                  resetFormuario();
                }}
              >
                Cancelar
              </Button>
            </div>
          

          {editando
            ? permissions.UPD && (
                <div>
                  <Button type="submit" color="success">
                    {editando ? "Editar" : "Agregar"}
                  </Button>
                </div>
              )
            : permissions.INS && (
                <div>
                  <Button type="submit" color="success">
                    {editando ? "Editar" : "Agregar"}
                  </Button>
                </div>
              )}

          
        </div>

        <ModalConfirmation
          modalTitle={editando ? "Editar" : "Crear"}
          modal={modalConfirmation}
          setModal={setModalConfirmation}
          crear={saveTopic}
          isEdit={false}
          values={values}
        >
          <div className="text-center">
            Esta seguro de continuar con el registro
          </div>
        </ModalConfirmation>
      </form>
      
    </div>
  );
};

export default FormTemas;

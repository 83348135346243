import { useFormik } from "formik";
import Datatable from "../../../components/Datatable/Datatable";
import { SelectTypeHeadSingle } from "../../../components/GenericInputsFormik";
import Widget from "../../../components/Widget/Widget";
import { PERIOD } from "../../../helpers/regex";
import { YearInput } from "../../../components/GenericInputsFormik/YearInput";

import * as Yup from "yup"
import { Button, Col, Row, Spinner } from "reactstrap";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Notification from "../../../components/Notification/Notification";
import { peticionesReceiver } from "../../../helpers/peticionesReceiver";
import { GetPermissions } from "../../../libs/permissions/getPermissions";
import { saveGeneralReport } from "../../../libs/reports/general/excelGeneral";
import FileSaver from "file-saver";


export default function MovimientosIssemym() {
    const API = peticionesReceiver();

    const [refresh, setRefresh] = useState(false)
    const [permisos, setPermisos] = useState("")
    const [loading, setLoading] = useState(true);
    const [show, setShow] = useState(false);
    const [isGood, setIsGood] = useState(false)

    GetPermissions(setPermisos, setLoading);


    const defaultForm = {
        period: "",
        year: ""
    }

    const columns = [
        { data: "staffNumber" },
        { data: "curp" },
        { data: "rfc" },
        { data: "socialSecurityKey" },
        { data: "pName" },
        { data: "mName" },
        { data: "name" },
        { data: "bornDate" },
        { data: "claveSAT" },
        { data: "pais" },
        { data: "siglasGender" },
        { data: "estadoCivil" },
        { data: "startJobDate" },
        { data: "street" },
        { data: "extNumber" },
        { data: "intNumber" },
        { data: "localidad" },
        { data: "poblacion" },
        { data: "zipCode" },
        { data: "entidad" },
        { data: "pais" },
        { data: "ladaCel" },
        { data: "cel" },
        { data: "ladaOficina" },
        { data: "telOficina" },
        { data: "email" },
        { data: "regimenLaboral" },
        { data: "nameJob" },
        { data: "level" },
        { data: "rango" },
        { data: "sindicalizado" },
        { data: "diasCotizados" },
        { data: "sindicato" },
    ];

    const headers = [
        "CVE EMP",
        "CURP",
        "RFC",
        "CLAVE ISSEMYM",
        "PATERNO",
        "MATERNO",
        "NOMBRE",
        "FECHA NACIMIENTO",
        "ENTIDAD NACIMIENTO",
        "NACIONALIDAD",
        "SEXO",
        "ESTADO CIVIL",
        "FECHA DE INGRESO",
        "DOMICILIO",
        "# EXTERIOR",
        "# INTERNO",
        "LOCALIDAD",
        "POBLACION",
        "CP",
        "ENTIDAD FEDERATIVA",
        "PAIS",
        "CLAVE",
        "TELEFONO",
        "LADA OFICINA",
        "TELEFONO OFICINA",
        "CORREO ELECTRONICO",
        "REGIMEN LABORAL",
        "CATEGORIA",
        "NIVEL",
        "RANGO",
        "SINDICALIZADO",
        "DIAS COTIZADOS",
        "SINDICATO"
    ];

    const columnDefs = [
    ]

    const FormSchema = Yup.object().shape({
        period: Yup.string().required("Seleccione un periodo"),
        year: Yup.string().required("Seleccione un año").nullable("Seleccione un año")
    })

    function refrescar() {
        if (!show) {
            setShow(true);
        } else {
            setRefresh(true)
        }
    }

    const { handleSubmit, values, handleBlur, errors, touched, setFieldValue } =
        useFormik({
            initialValues: defaultForm,
            onSubmit: () => {
                refrescar()
            },
            validationSchema: FormSchema,
        });

    const onChange = (e) => {
        const { name, value } = e.target
        setFieldValue([name], value);
    }

    const getParams = (order, records, page, search) => {
        const params = {
            action: "getMovimientosIssemym",
            order,
            records: records.toString(),
            page: (page * records).toString(),
            search,
            period: values.period,
            year: (new Date(values.year)).getFullYear()
        }
        return params
    }

    useEffect(() => {
        setShow(false)
        setIsGood(false)
    }, [values.period, values.year])

    async function getData(order, records, page, search) {
        try {
            const res = await API.peticionEndPoint(getParams(order, records, page, search), "app/facades/reports/reportsF.php");
            if (res.status === 200 && res.data.code === "200") {
                setIsGood(true)
                return res.data;
            } else {
                toast(<Notification type={"consultar_error"} backMessage={res.data.message} />);
                return null;
            }
        } catch (err) {
            toast(<Notification type={"consultar_servidor_error"} withIcon />);
            return null
        }
    }
    
    async function genExcel() {
        try {
            const res = await API.peticionEndPoint({
                action: "getMovimientosIssemym",
                period: values.period,
                year: (new Date(values.year)).getFullYear()
            }, "app/facades/reports/reportsF.php");
            if (res.status === 200 && res.data.code === "200") {
                const title = "Movimientos ISSEMYM";
                const data = res.data.data
                const config = {
                    subTitle: `Periodo: ${values.period} Año: ${(new Date(values.year)).getFullYear()}`,
                    headers,
                    sum: [],
                    sizes: [20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20],
                    content: headers.map((header, index) => { return { header, value: columns[index].data } })
                }
                saveGeneralReport(data, title, null, config);
            } else {
                toast(<Notification type={"consultar_error"} backMessage={res.data.message} />);
                
            }
        } catch (err) {
            toast(<Notification type={"consultar_servidor_error"} withIcon />);
        }
    }

    async function genExcelDetalle() {
        try {
            const res = await API.peticionEndPoint({
                action: "getMovimientosIssemym",
                period: values.period,
                year: (new Date(values.year)).getFullYear()
            }, "app/facades/reports/reportsF.php");
            if (res.status === 200 && res.data.code === "200") {
                const title = "Detalle - Movimientos ISSEMYM";
                const data = []
                res.data.data.forEach(val => {
                    val.detalleConceptos.forEach(det => {
                        const value = { ...det, ...val };
                        delete value.detalleConceptos
                        data.push(value);
                    })
                })
                const newHeaders = [ ...headers, "NOMBRE CONCEPTO", "CONCEPTO", "IMPORTE"];
                const newColumns = [ ...columns, {data: "nameUnder"}, {data: "under"}, {data: "amount"}];
                const config = {
                    subTitle: `Periodo: ${values.period} Año: ${(new Date(values.year)).getFullYear()}`,
                    headers: newHeaders,
                    sum: [],
                    sizes: [20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20],
                    content: newHeaders.map((header, index) => { return { header, value: newColumns[index].data } })
                }
                saveGeneralReport(data, title, null, config);
            } else {
                toast(<Notification type={"consultar_error"} backMessage={res.data.message} />);
                
            }
        } catch (err) {
            toast(<Notification type={"consultar_servidor_error"} withIcon />);
        }
    }

    if (loading) {
        <div className="d-flex align-items-center justify-content-center" style={{ height: "200px" }}>
            <Spinner
                color="primary"
                style={{
                    height: '3rem',
                    width: '3rem'
                }}
                type="grow"
            >
                Loading...
            </Spinner>
        </div>
    }
    if (permisos.CON) {
        return (
            <>
                <Widget>
                    <form onSubmit={handleSubmit}>
                        <Row className="px-3 pt-3">
                            <Col xs={12} md={6}>
                                <SelectTypeHeadSingle
                                    label="Periodo"
                                    isRequired={true}
                                    inputName="period"
                                    optionsArray={PERIOD}
                                    defaultOption="Seleccione un periodo"
                                    onChangeMethod={onChange}
                                    value={values.period}
                                    errors={errors.period}
                                    touched={touched.period}
                                    optionValue="value"
                                    optionName="label"
                                />
                            </Col>
                            <Col xs={12} md={6}>
                                <YearInput
                                    label="AÑO"
                                    inputName="year"
                                    onChangeMethod={onChange}
                                    onBlurMethod={handleBlur}
                                    value={values.year}
                                    touched={touched.year}
                                    errors={errors.year}
                                />
                            </Col>
                        </Row>
                        <div className="text-center mb-3">
                            <Button color="info" type="submit">Consultar</Button>
                        </div>
                    </form>
                    {isGood &&
                        <div className="text-right">
                            <Button className="mx-1" onClick={genExcel}>Excel</Button>
                            <Button className="mx-1" onClick={genExcelDetalle}>Excel detalle</Button>
                            <Button
                                className="mx-1"
                                onClick={() => FileSaver.saveAs(`https://pruebas.sapptesting.click/sapp/back/files/issemym/movimientos${values.period}${(new Date(values.year)).getFullYear()}.txt`, `movimientos${values.period}${(new Date(values.year)).getFullYear()}.txt`)}
                            >
                                Layout
                            </Button>
                        </div>}
                    {permisos.CON ? (show && <Datatable
                        columns={columns}
                        headers={headers}
                        petition={getData}
                        stateRefresh={[refresh, setRefresh]}
                        columnDefs={columnDefs}
                        control="back"
                    />)
                        : <div className='text-center mt-3 mb-1'>No tiene permitido ver los registros de esta seccion</div>}

                </Widget>
            </>
        )
    } else {
        return (
            <>
                <div className="text-center p-4">
                    No tienes permitido ver los registros de esta seccion
                </div>
            </>
        )
    }
}
import { peticionesReceiver } from "../../../helpers/peticionesReceiver";
import Notification from "../../../components/Notification/Notification";
import { toast } from "react-toastify";
const API = peticionesReceiver();

export const createRegion = async (paisN) => {
    if (paisN) {
      const params = {
        action: "insert",
        table: "regions",
        rows: paisN,
        validate: [["nameRegion"]]
      };
      API.peticion(params).then((res) => { 
          if (res.status === 200 && res.data.code === "200") {
            toast(
              <Notification
                type={"agrega_exito"}
                backMessage={res.data.message}
                withIcon
              />
            );
            window.location.href = "#/template/regiones"
          } else {
            toast(
              <Notification
                type={"agrega_error"}
                backMessage={res.data.message}
                withIcon
              />
            );
          }
        })
        .catch((err) => {
          toast(<Notification type={"consultar_servidor_error"} withIcon />);
        });
    }
  };

  export const updateRegion = async (regionU) => {
    if (regionU) {
      const params = {
        action: "update",
        table: "regions",
        rows: regionU,
        condition: {
          keyRegion: regionU.keyRegion,
        },
        validate: [["nameRegion"]]
      };
      API.peticion(params).then((res) => { 
          if (res.status === 200 && res.data.code === "200") {
            toast(
              <Notification
                type={"modifica_exito"}
                backMessage={res.data.message}
                withIcon
              />
            );
            window.location.href = "#/template/regiones"
          } else {
            toast(
              <Notification
                type={"modifica_error"}
                backMessage={res.data.message}
                withIcon
              />
            );
          }
        })
        .catch((err) => {
          toast(<Notification type={"consultar_servidor_error"} withIcon />);
        });
    }
  };

import React, { useEffect, useState } from 'react'
/* import Gantt from '../../../componentes/gantt/Gantt' */
import Gantt2 from '../../../componentes/gantt/Gantt2'
import { peticionesReceiver } from "../../../../../helpers/peticionesReceiver";
import { toast } from "react-toastify";
import Notification from "../../../../../components/Notification/Notification";
import { getRandomColor } from '../../../componentes/gantt/Gantt2';





const ExpGantt = (props) => {
    
    const API = peticionesReceiver();
    const { proyect } = props
    const [data, setData] = useState("")


    useEffect(() => {
        getActivities()
    }, [])




    const getActivities = async () => {
        const params = {
            action: "multiselect",
            table: 'activities',
            rows: "idActivity id,nameActivity name,startDate start,endDate end",
            /* conditions: `enabled = 1` */
            conditions: `enabled = 1 and idProyect =${proyect.id}`
        };
        await API.peticion(params)
            .then((res) => {
                
                if (res.status === 200 && res.data.code === "200") {
                    const activities = res.data.data.map(element => ({
                        id: element.id  ,
                        name: element.name  ,
                        start: element.start,
                        end:  element.end,
                        color: getRandomColor()
                    }))
                    setData(activities.length > 0 ? activities : "")                    
                    
                } else {
                    toast(<Notification type={"consultar_error"} backMessage={res.data.message} withIcon />);
                }
            })
            .catch((err) => {
                toast(<Notification type={"consultar_servidor_error"} withIcon />);
            });
    };

    return (
        <div>            
            <span className="headline-2 text-center">{`Actividades de ${proyect.name}`}</span>
            {data !== "" && <Gantt2 data={data} />}
            {/* {data !== "" && <Gantt data={data} titulo={"Hola mundo"} />} */}
        </div>
    )
}

export default ExpGantt
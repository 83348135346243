import { doRequest } from "../../helpers/requests";

/** 
 * Funcion para obtene permisos de los hijos de un modulo
 * @param {number} iModule id del modulo
 * @param {number|string} permission id del permiso del perfil para el usaurio
 */
export default async function getChildPermissions(idMod, permission) {
    const params = {
        action: "getModuleChilds",
        idModule: idMod,
        idPermission: permission,
    };

    const res = await doRequest("menu/menu.php", params, false);
    if(res.length > 0) {
        const prmss = {};
        res.forEach(el=>{
            const name = el.nameModule.replaceAll(" ", "").split("-")[1];
            prmss[name] = {
                module: el.nameModule,
                CON: el.CON === "1",
                INS: el.INS === "1",
                UPD: el.UPD === "1",
                DEL: el.DEL === "1",
            }
        });
        return prmss;
    }
    return {}
}

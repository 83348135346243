/**
 * @param params setters from Format
 */
export function setConfigR50(params) {
    const { setHeaders, setPdfConfig, setExcelConfig, setHtmlConfig, setData, filter } = params;
    const headers = [
        "TOTAL",
        "TOTAL IMPORTE"
    ]
    setHeaders(headers)
    const tempData = {
        action: "reporteClasificacionAdministrativa",
        year: (new Date(filter.year)).getFullYear(),
    }
    setData(tempData);
    const content = [
        { header: "TOTAL", value: "value" },
        { header: "TOTAL IMPORTE", value: "label" },
    ];
    setHtmlConfig({
        headers,
        content: [content]
    })
    setPdfConfig({
        descr: tempData,
        headers,
        content: [content]
    });
    setExcelConfig({
        headers,
        content: [...content]
    });
}
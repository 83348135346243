import { useState, useEffect } from "react";
import Datatable from "../../../components/Datatable/Datatable";
import Widget from "../../../components/Widget/Widget";
import { doRequestSaveRes } from "../../../helpers/requests";
import { fDate } from "../../../libs/tools/format";
import Form from "./Form";
import { Button } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { GetPermissions } from "../../../libs/permissions/getPermissions";
import { faEye } from "@fortawesome/free-solid-svg-icons";

function getParams(ordCol, itemsPerPage, currentPage, search) {
    const params = {
        action: "datatable",
        table: "transferjobstaff A INNER JOIN jobstaff B ON A.idJobStaffONew = B.idJobStaff INNER JOIN jobstaff C ON A.idJobStaffTNew = C.idJobStaff INNER JOIN staff DB ON DB.idStaff = B.idStaff INNER JOIN staff EC ON EC.idStaff = C.idStaff INNER JOIN jobs FB ON FB.keyJob = B.keyJob INNER JOIN jobs GC ON GC.keyJob = C.keyJob INNER JOIN areas HB ON HB.idArea = B.idArea INNER JOIN areas IC ON IC.idArea = C.idArea",
        rows: "A.idTransferJobstaff, A.authorizationDate, CONCAT_WS(' ', DB.name, DB.pName, DB.mName) AS nameA, CONCAT_WS(' ', EC.name, EC.pName, EC.mName) AS nameB, FB.nameJob AS jobA, GC.nameJob AS jobB, HB.name as areaA, IC.name as areaB",
        conditions: `A.enabled = 1`,
        order: ordCol,
        records: itemsPerPage,
        page: itemsPerPage * currentPage,
        search,
    };
    return params;
}

export default function PermutacionEmpleados() {
    const [permissions, setPermissions] = useState({ LOD: true });
    GetPermissions(setPermissions);

    const [selected, setSelected] = useState("");
    const [refresh, setRefresh] = useState(false);
    const [open, setOpen] = useState(false);

    async function getData(ordCol, itemsPerPage, currentPage, search) {
        const res = await doRequestSaveRes(
            "receiver/receiver.php",
            getParams(ordCol, itemsPerPage, currentPage, search),
            false,
            false
        );
        if (res.code === "200") return res;
        else return null;
    }

    const columns = [
        { data: null, render: (d)=> <InfoMove data={d} field="A"/>, orderValue: "nameA" },
        { data: null, render: (d)=> <InfoMove data={d} field="B"/>, orderValue: "nameB" },
        { data: "authorizationDate", format: fDate },
        { data: null, render: (row)=> <FontAwesomeIcon icon={faEye} className="text-info cursor-pointer" onClick={()=>openToEdit(row)} /> },
    ];

    const columnDefs = [{ className: "text-center", targets: [2, 3] },{ orderable: false, targets: [3] }];

    function openToEdit(row){ setSelected(row); }

    useEffect(()=>{
        if(selected) setOpen(true);
    }, [selected]);

    useEffect(()=>{
        if(!open){
            if(selected) setSelected(undefined);
        }
    }, [open]);

    return (
        <Widget className="widget-p-md">
            <div className="d-flex flex-column flex-md-row justify-content-md-between mb-2">
                <h4 className="">Permutación Empleados {open && (selected? "- Editar" : "- Registrar")}</h4>
                {!open && permissions.INS && <Button onClick={()=>setOpen(true)} className="my-1 my-md-0">Agregar</Button>}
            </div>

            {open ? (
                <Form selected={selected} setOpen={setOpen} />
            ) : (
                permissions.CON && <Datatable
                    headers={["Empleado A", "Empleado B", "Fecha Autorización", "Ver"]}
                    columns={columns}
                    columnDefs={columnDefs}
                    petition={getData}
                    control="back"
                    stateRefresh={[refresh, setRefresh]}
                    searching={true}
                    order={{ col: 2, opt: "desc" }}
                />
            )}
        </Widget>
    );
}

function InfoMove({data, field}){
    return(
        <div className="w-auto" style={{width: "auto"}}>
            <p><strong>Empleado: </strong>{data["name"+field]}</p>
            <p><strong>Puesto Nuevo: </strong>{data["job"+field]}</p>
            <p><strong>Unidad de trabajo Nueva: </strong>{data["area"+field]}</p>
        </div>
    )
}
import { peticionesReceiver } from "../../../helpers/peticionesReceiver";
import Notification from "../../../components/Notification/Notification";
import { toast } from "react-toastify";
const API = peticionesReceiver();

export const agregar = async (banco) => {
    const data = {
      action: "insert",
      table: "banks",
      rows: banco,
      validate: [
        ["nameBank"],
        ["codeBank"],
    ]
    };    
    API.peticion(data).then((res) => {
        if (res.status === 200 && res.data.code === "200") {
          console.log("Insertado",res)
          toast(<Notification type={"agrega_exito"} backMessage={res.data.message} withIcon />);
          window.location.href = "#/template/bancos";
        } else {
          console.log("Insertado",res)
          toast(<Notification type={"agrega_error"} backMessage={res.data.message} withIcon />);
        }
      })
      .catch((err) => {
        toast(<Notification type={"consultar_servidor_error"} withIcon />);
      });
  };

export const editar = async (banco) => {
    const data = {
      action: "update",
      table: "banks",
      rows: banco,
      condition: {
        keyBank: banco.keyBank,
      },
      validate: [
        ["nameBank"],
        ["codeBank"],
      ]
    };
    API.peticion(data).then((res) => {
        if (res.status === 200 && res.data.code === "200") {
          toast(<Notification type={"modifica_exito"} backMessage={res.data.message} withIcon />);
          window.location.href = "#/template/bancos";
        } else {
          toast(<Notification type={"modifica_error"} backMessage={res.data.message} withIcon />);
        }
      })
      .catch((err) => {
        toast(<Notification type={"consultar_servidor_error"} withIcon />);
      });
  };
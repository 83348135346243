import { useState } from "react";
import { useHistory } from "react-router-dom";
import TableComponent from "../../../components/TableComponent/TableComponentFiltro";

export const Areas = () => {
  const cabeceras = [
    "Id",//0
    "Unidad de Trabajo",//1
    "Clave",//2
    "Tipo",//3
    "Edificio",//4
    "Almacen", //n5
    "A. Padre",//5 n6
    "Correo",//6 n7
    "Editar",//
    "Eliminar",//
  ];
  const [filter] = useState([true, true, true, true, true, true, true, true,false,false]);
  const [parametros] = useState({
    action: "datatable",
    table: "areas A LEFT JOIN areas B on (A.pArea = B.idArea and B.enabled = 1) INNER JOIN buildings C on (A.idBuilding= C.idBuilding)  INNER JOIN types_areas D on (A.keyTypeArea = D.keyTypeArea)",
    rows: "A.idArea,A.name,A.areaKey,D.nameTypeArea,C.nameBuilding,IF(A.storage = 1, 'SI', 'NO') as storage,B.name padrearea,A.email as correo,A.keyTypeArea as idTipoArea,C.idBuilding as idEdificio,A.pArea as areapadre",//0-10
    conditions:"A.enabled = 1",
    page:0,
    records:5,
    search:"",
    order:""
  });

  return (
    <div>
        <TableComponent
          titulo="Unidades de trabajo"
          cabecerasTabla={cabeceras}
          filtro={filter}
          rutaCrear="/template/areas/crear"
          rutaEditar="/template/areas/editar"
          parametros={parametros}
        />
    </div>
  );
};
export default Areas;

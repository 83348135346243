import { Button, Col, Row } from "reactstrap";
import { TextInput } from "../../../../components/GenericInputsFormik";
import Datatable from "../../../../components/Datatable/Datatable";
import { useEffect, useState, useRef } from "react";
import { peticionesReceiver } from "../../../../helpers/peticionesReceiver";
import { toast } from "react-toastify";
import Notification from "../../../../components/Notification/Notification";
import Proveedor from "./proveedor";
import CantidadProveedor from "./cantidad";

export default function Adjudicacion({ idQuote }) {
  const API = peticionesReceiver();
  const [arrayRecepcionCompras, setArrayRecepcionCompras] = useState([]);
  const [refresh, setRefresh] = useState(false);

  const [generalData, setGeneralData] = useState({
    numberRequest: "",
    yearRequest: "",
    modified: "",
    dateAproved: "",
  });

  async function getAdjudicacion() {
    try {
      const res = await API.peticion({
        action: "multiselect",
        rows: "numberRequest,yearRequest,modified,dateApproved",
        table: "purchaserequests",
        conditions: `idQuote=${idQuote}`,
      });
      if (res.status === 200 && res.data.code === "200") {
        setGeneralData(res.data.data[0]);
      } else {
        toast(
          <Notification
            type={"consultar_error"}
            backMessage={res.data.message}
          />
        );
      }
    } catch (err) {
      toast(<Notification type={"consultar_servidor_error"} withIcon />);
    }
  }

  useEffect(() => {
    getAdjudicacion();
  }, []);

  const headers = [
    "Partida",
    "Descripción",
    "Modelo",
    "Marca",
    "Peridiocidad",
    "Cantidad",
    "Proveedor",
    "Pago",
    "Guardar",
  ];

  const columns = [
    { data: "index" },
    { data: "descripcion" },
    { data: "model" },
    { data: "brand" },
    { data: "nameServiceTime" },
    { data: "amount" },
    {
      data: null,
      render: function render(row) {
        return (
          <Proveedor
            idDetail={row.idDetail}
            indexRecepcion={getArrayRecepcion(row.idDetail)}
            arrayRecepcionCompras={arrayRecepcionCompras}
            setArrayRecepcionCompras={setArrayRecepcionCompras}
          />
        );
      },
    },
    {
      data: null,
      render: function render(row) {
        return (
          <CantidadProveedor
            idDetail={row.idDetail}
            indexRecepcion={getArrayRecepcion(row.idDetail)}
            arrayRecepcionCompras={arrayRecepcionCompras}
            setArrayRecepcionCompras={setArrayRecepcionCompras}
          />
        );
      },
    },
    {
      data: null,
      render: function render(row) {
        return (
          <div className="text-center">
            <Button
              onClick={() => {
                sendInformation(row);
              }}
              color={row.idWinner !== "" ? "warning" : "success"}
            >
              {row.idWinner !== "" ? "Editar " : "Guardar"}
            </Button>
          </div>
        );
      },
    },
  ];

  const getArrayRecepcion = (idDetail) => {
    return arrayRecepcionCompras.findIndex((obj) => obj.idDetail === idDetail);
  };

  const sendInformation = (row) => {
    if (validateInformation(row)) {
      const data =
        row.idWinner === ""
          ? {
              action: "insert",
              table: "purchasewinners",
              rows: {
                idPurchaseRequest: +localStorage.getItem("solvProc"),
                idProviderQuote: row.provedor,
                daysPayment: row.diasPaga,
              },
              validate: [["idProviderQuote", "purchasewinners"]],
            }
          : {
              action: "update",
              table: "purchasewinners",
              rows: {
                idPurchaseRequest: +localStorage.getItem("solvProc"),
                idProviderQuote: row.provedor,
                daysPayment: row.diasPaga,
              },
              condition: {
                idWinner: row.idWinner,
              },
              validate: [["idProviderQuote", "purchasewinners"]],
            };

      API.peticion(data)
        .then((res) => {
          if (res.status === 200 && res.data.code === "200") {
            toast(
              <Notification
                type={"agrega_exito"}
                backMessage={res.data.message}
                withIcon
              />
            );
            setRefresh(true);
          } else {
            toast(
              <Notification
                type={"agrega_error"}
                backMessage={res.data.message}
                withIcon
              />
            );
          }
        })
        .catch((err) => {
          toast(<Notification type={"consultar_servidor_error"} withIcon />);
        });
    }
  };

  const validateInformation = (row) => {
    if (
      row.provedor === "" ||
      row.provedor === null ||
      row.provedor === undefined
    ) {
      toast(
        <Notification
          type={"error"}
          backMessage={"Favor de llenar el provedor"}
          withIcon
        />
      );
      return false;
    } else if (
      row.diasPaga === "" ||
      row.diasPaga === null ||
      row.diasPaga === undefined
    ) {
      toast(
        <Notification
          type={"consultar_servidor_error"}
          backMessage={"Favor de llenar el numero de dias"}
          withIcon
        />
      );
      return false;
    }

    return true;
  };

  const columnDefs = [{ targets: [0, 5], className: "text-center" }];

  const getDataForArray = async (list) => {
    let params = {
      action: "multiselect",
      table: `purchasewinners A INNER JOIN providerquotes B using(idProviderQuote)`,
      rows: "A.idWinner,A.idPurchaseRequest,A.idProviderQuote,A.daysPayment,B.idDetail",
      conditions: `A.enabled=1 and A.idPurchaseRequest = ${localStorage.getItem(
        "solvProc"
      )}`,
    };

    const res = await API.peticion(params);
    if (res.status === 200 && res.data.code === "200") {
      list.forEach((obj) => {
        let founded = res.data.data.find(
          (winner) => winner.idDetail === obj.idDetail
        );
        if (founded !== undefined) {
          obj.provedor = founded.idProviderQuote;
          obj.diasPaga = founded.daysPayment;
          obj.idWinner = founded.idWinner;
        } else {
          obj.provedor = "";
          obj.diasPaga = "";
          obj.idWinner = "";
        }
      });
    } else {
      toast(
        <Notification type={"consultar_error"} backMessage={res.data.message} />
      );
    }
  };

  async function getData() {
    let params = {
      action: "multiselect",
      /* table:
      `providerquotes pq inner join quotesdetail qt on(pq.idDetail=qt.idDetail AND pq.winner=1) left join services sv on (qt.idService=sv.idService) left join furnitures f on (f.idFurniture=qt.idFurniture) left join messureunits mu on (f.keyUnit=mu.keyUnit) left join servicetimes sti on (sv.keyServiceTime=sti.keyServiceTime) LEFT JOIN purchasewinners pwn on (pq.idProviderQuote = pwn.idProviderQuote)`,
      rows: "qt.idDetail,qt.amount,if(isnull(sv.nameService),f.nameFurniture,sv.nameService) as descripcion,f.brand,f.model,sti.nameServiceTime,IFNULL(pwn.idProviderQuote,'') as provedor, IFNULL(pwn.daysPayment,'') as diasPaga,idWinner,pq.idProviderQuote as IDPROVIDERQOUTE, pwn.idProviderQuote as IDPROVIDEREWINNERS  ", */
      table: `providerquotes pq inner join quotesdetail qt on(pq.idDetail=qt.idDetail AND pq.winner=1) left join services sv on (qt.idService=sv.idService) left join furnitures f on (f.idFurniture=qt.idFurniture) left join messureunits mu on (f.keyUnit=mu.keyUnit) left join servicetimes sti on (sv.keyServiceTime=sti.keyServiceTime) `,
      rows: "qt.idDetail,qt.amount,if(isnull(sv.nameService),f.nameFurniture,sv.nameService) as descripcion,f.brand,f.model,sti.nameServiceTime",
      conditions: `qt.idQuote=${idQuote} AND qt.enabled=1`,
    };
    try {
      const res = await API.peticion(params);
      if (res.status === 200 && res.data.code === "200") {
        res.data.data.forEach((data, index) => {
          data.index = index + 1;
        });
        let tmpList = res.data.data.slice(0, res.data.data.length);
        setArrayRecepcionCompras(tmpList);
        
        await getDataForArray(tmpList);

        
        return res.data;
      } else {
        toast(
          <Notification
            type={"consultar_error"}
            backMessage={res.data.message}
          />
        );
        return [];
      }
    } catch (err) {
      toast(<Notification type={"consultar_servidor_error"} withIcon />);
      return [];
    }
  }

  return (
    <>
      <h4>Adjudicación del proveedor</h4>
      <Row>
        <Col sm={4} md={6}>
          <TextInput
            inputName={"numberRequest"}
            inputType="text"
            label={"Numero de compra"}
            value={generalData.numberRequest}
            isDisabled
          />
        </Col>
        <Col sm={4} md={6}>
          <TextInput
            inputName={"yearRequest"}
            inputType="text"
            label={"Año de compra"}
            value={generalData.yearRequest}
            isDisabled
          />
        </Col>
      </Row>
      <Row>
        <Col sm={4} md={6}>
          <TextInput
            inputName={"modified"}
            inputType="text"
            label={"Fecha de elaboración"}
            value={generalData.modified}
            isDisabled
          />
        </Col>
        <Col sm={4} md={6}>
          <TextInput
            inputName={"dateAproved"}
            inputType="text"
            label={"Fecha de autorizacion"}
            value={generalData.dateAproved}
            isDisabled
          />
        </Col>
      </Row>
      <p>Adjudicacion a proveedores (*)</p>
      <Datatable
        headers={headers}
        columns={columns}
        columnDefs={columnDefs}
        searching={false}
        info={false}
        paging={false}
        petition={getData}
        control="back"
        className="p-0 my-2 overflow-none"
        stateRefresh={[refresh, setRefresh]}
      />
    </>
  );
}

import { Document, Page, Text, View } from "@react-pdf/renderer";
import { styles } from "./EstiloPdf";
import HeaderReport from "../Header";
import { fDate, fPercent } from "../../../libs/tools/format";

const formatter = new Intl.NumberFormat("es-MX", {
  style: "currency",
  currency: "MXN",
});
const dateformatter = (fecha) => {
  let date = new Date(fecha);
  return `${date.getDate()}/${date.getMonth()}/${date.getFullYear()}`;
};

const formatField = (emp, conf) => {
  let ff = emp[conf.value];
  switch (conf.format) {
    case "moneda":
      ff = formatter.format(ff);
      break;
    case "fecha":
      if (isNaN(ff)) {
        ff = fDate(ff);
      }
      break;
    case "porcentaje":
      if (isNaN(ff)) {
        ff = fPercent(ff);
      }
      break;
    default:
      if(conf.format){
        ff = conf.format(emp, conf.value);
      }
      break;
  }
  return ff;
};

export function generatePdfGeneral(data, model, title, fileNo, cont) {
  const ori = model.orientation === "h" ? "landscape" : "portrait";

  const id = model.id ? "id" : "idFormat";

  const contregistro = isNaN(cont) ? 0 : cont;
  return {
    contador: contregistro + data.length,
    report: (
      <>
        <Document>
          <Page style={styles.body} orientation={ori}>
            <HeaderReport />
            <View>
              <Text style={styles.title}>{title}</Text>
            </View>
            <View>
              <Text style={styles.subTitle}>
                {
                  model.filters !== undefined ? 
                    model.filters.onlyMessage === true ? model.filters.message : ""
                    : ""
                }
              </Text>
            </View>
            <View style={styles.table}>
              {/* Cabeceras */}
              <View style={styles.tableRow} fixed>
                <View style={[styles.tableColHeaderNoL, {width: "10%"}]} fixed>
                  <Text style>{model.headers[0]}</Text>
                </View>
                <View style={[styles.tableColHeaderNo, {width: "15%"}]} fixed>
                  <Text>{model.headers[1]}</Text>
                </View>
                <View style={styles.tableColHeaderReport} fixed>
                  <Text>{model.headers[2]}</Text>
                </View>
              </View>
              {data.length > 0 &&
                data.map((employee, index) => (
                  <View style={styles.tableRow} wrap={false} key={"rd"+index}>
                    <View style={[styles.tableColContentNo, {width: "10%"}]}>
                      <Text style={styles.cellOneColumn}>
                        {contregistro + index + 1}
                      </Text>
                    </View>
                    <View style={[styles.tableColContentNoBI, {width: "15%"}]}>
                      <Text style={styles.cell}>
                        {/*  {employee[model.id]} */}
                        {formatField(employee, {
                          value: model[id].id,
                          format: model[id].format,
                        })}
                      </Text>
                    </View>

                    <View style={styles.tableColContentReport}>
                      {model.content.map((mod, ix1) => (
                        <View style={styles.displayContent} key={`rm${index}${ix1}`}>
                          {mod.map((att, ix2) => (
                            <Text
                              style={
                                att.columns === 3
                                  ? styles.cellThreeColumn
                                  : att.columns === 2
                                  ? styles.cellTwoColumn
                                  : styles.cellOneColumn
                              }
                              key={`rmm${index}${ix1}${ix2}`}
                            >
                              <Text style={styles.textBold}>
                                {typeof att.header === "function" ? att.header(employee) : att.header}:
                              </Text>
                              {formatField(employee, att)}
                            </Text>
                          ))}
                        </View>
                      ))}
                    </View>
                  </View>
                ))}
            </View>

            <View fixed style={styles.spaceFooter}></View>
            <View fixed style={styles.footer}>
              <Text style={styles.textFooter}>{fileNo}</Text>
              <Text
                style={styles.textFooter}
                render={({ pageNumber, totalPages }) =>
                  `PAGINA : ${pageNumber} / ${totalPages}`
                }
              />
            </View>
          </Page>
        </Document>
      </>
    ),
  };
}

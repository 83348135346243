import { Row, Col, Button } from "reactstrap";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as Yup from "yup";

import { SelectTypeHeadSingle } from "../../../components/GenericInputsFormik/SelectTypeHeadSingle";
import { NumberInput } from "../../../components/GenericInputsFormik/NumberInput";
import ModalConfirmation from "../../../components/Modal/ModalConfirmation";
import { peticionesReceiver } from "../../../helpers/peticionesReceiver";
import Notification from "../../../components/Notification/Notification";
import Loader from "../../../components/Loader/Loader"
import Widget from "../../../components/Widget/Widget";

export default function AgregarIncrementoSalarial() {
    const [modal, setModal] = useState(false);
    const [loading, setLoading] = useState(true);
    const [levels, setLevels] = useState([]);
    const [endLevels, setEndLevels] = useState([]);
    const API = peticionesReceiver();

    const onChange = (e) => {
        const { name, value } = e.target
        if (name === "startLavel") {
            setFieldValue(["endLavel"], "");
            setEndLevels(levels.filter(val => Number(val.level) >= Number(value)));
        }
        setFieldValue([name], value);
    }

    const defaultForm = {
        porcetageBase: "",
        porcentageReward: "",
        porcentageLarder: "",
        porcentageClearance: "",
        porcentageFortalecimiento: "",
        year: (new Date()).getFullYear().toString(),
        startLavel: "",
        endLavel: ""
    }

    //FORM
    const FormSchema = Yup.object().shape({
        startLavel: Yup.number()
            .required("Ingrese un valor inicial")
            .integer("El valor solo puede ser entero"),
        endLavel: Yup.number()
            .required("Ingrese un valor final")
            .integer("El valor solo puede ser entero"),
        porcetageBase: Yup.number()
            .required("Ingrese un porcentaje base")
            .min(0, "El valor como minimo es 0")
            .max(100, "El valor no puede ser mayor a 100"),
        porcentageReward: Yup.number()
            .required("Ingrese un porcentaje de gratificación")
            .min(0, "El valor como minimo es 0")
            .max(100, "El valor no puede ser mayor a 100"),
        porcentageLarder: Yup.number()
            .required("Ingrese un porcentaje de despensa")
            .min(0, "El valor como minimo es 0")
            .max(100, "El valor no puede ser mayor a 100"),
        porcentageClearance: Yup.number()
            .required("Ingrese un porcentaje de compensación")
            .min(0, "El valor como minimo es 0")
            .max(100, "El valor no puede ser mayor a 100"),
        porcentageFortalecimiento: Yup.number()
            .required("Ingrese un porcentaje de fortalecimiento")
            .min(0, "El valor como minimo es 0")
            .max(100, "El valor no puede ser mayor a 100"),
        year: Yup.number()
    })

    const { handleSubmit, values, handleBlur, errors, touched, setFieldValue, resetForm } =
        useFormik({
            initialValues: defaultForm,
            onSubmit: () => setModal(true),
            validationSchema: FormSchema,
        });

    async function OnCreate(rows) {
        const API = peticionesReceiver();
        const params = {
            action: "saveSalariesIncrement",
            rows
        }
        try {
            const res = await API.peticionEndPoint(params, "app/facades/jobs/jobsF.php")
            if (res.status === 200 && res.data.status === "success") {
                toast(<Notification type={"agrega_exito"} backMessage={res.data.message} withIcon />);
                window.location.href = "#/template/incrementosalarial"
            } else {
                toast(<Notification type={"agrega_error"} backMessage={res.data.message} withIcon />);
            }
        } catch (err) {
            toast(<Notification type={"agrega_error"} withIcon />);
        }
    }

    async function getLevels() {
        try {
            const params = {
                action: "multiselect",
                table: "salaries",
                rows: "distinct level",
                order: "level asc",
                conditions: "enabled=1"
            }
            const res = await API.peticion(params);
            if (res.status === 200 && res.data.code === "200") {
                setLevels(res.data.data)
                setLoading(false)
            } else {
                toast(<Notification type={"consultar_error"} backMessage={res.data.message} />);
            }
        } catch (err) {
            toast(<Notification type={"consultar_servidor_error"} withIcon />);
        }
    }

    useEffect(() => {
        getLevels();
    }, [])


    if (loading) {
        return (
            <>
                <Loader />
            </>
        )
    }
    return (
        <>
            <Widget className="widget-p-md">
                <h3>Agregar nuevo incremento</h3>
                <form onSubmit={handleSubmit}>
                    <Row>
                        <Col xs={12} md={6}>
                            <SelectTypeHeadSingle
                                label="Nivel inicial"
                                inputName="startLavel"
                                onChangeMethod={onChange}
                                onBlurMethod={handleBlur}
                                value={values.startLavel}
                                isRequired
                                optionsArray={levels}
                                optionValue="level"
                                optionName="level"
                                errors={errors.startLavel}
                                touched={touched.startLavel}
                            />
                        </Col>
                        <Col xs={12} md={6}>
                            <SelectTypeHeadSingle
                                label="Nivel final"
                                inputName="endLavel"
                                onChangeMethod={onChange}
                                onBlurMethod={handleBlur}
                                value={values.endLavel}
                                isRequired
                                optionsArray={endLevels}
                                optionValue="level"
                                optionName="level"
                                errors={errors.endLavel}
                                touched={touched.endLavel}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} md={4}>
                            <NumberInput
                                label="% Aumento Sueldo Base"
                                inputName="porcetageBase"
                                onChangeMethod={onChange}
                                onBlurMethod={handleBlur}
                                value={values.porcetageBase}
                                isRequired
                                errors={errors.porcetageBase}
                                touched={touched.porcetageBase}
                                decimals
                                suffix="%"
                                isMin={0}
                                isMax={100}
                            />
                        </Col>
                        <Col xs={12} md={4}>
                            <NumberInput
                                label="% Aumento Gratificacion"
                                inputName="porcentageReward"
                                onChangeMethod={onChange}
                                onBlurMethod={handleBlur}
                                value={values.porcentageReward}
                                isRequired
                                errors={errors.porcentageReward}
                                touched={touched.porcentageReward}
                                decimals
                                suffix="%"
                                isMin={0}
                                isMax={100}
                            />
                        </Col>
                        <Col xs={12} md={4}>
                            <NumberInput
                                label="% Aumento Despensa"
                                inputName="porcentageLarder"
                                onChangeMethod={onChange}
                                onBlurMethod={handleBlur}
                                value={values.porcentageLarder}
                                isRequired
                                errors={errors.porcentageLarder}
                                touched={touched.porcentageLarder}
                                decimals
                                suffix="%"
                                isMin={0}
                                isMax={100}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} md={6}>
                            <NumberInput
                                label="% Aumento Compensación"
                                inputName="porcentageClearance"
                                onChangeMethod={onChange}
                                onBlurMethod={handleBlur}
                                value={values.porcentageClearance}
                                isRequired
                                errors={errors.porcentageClearance}
                                touched={touched.porcentageClearance}
                                decimals
                                suffix="%"
                                isMin={0}
                                isMax={100}
                            />
                        </Col>
                        <Col xs={12} md={6}>
                            <NumberInput
                                label="% Aumento Fortalecimiento"
                                inputName="porcentageFortalecimiento"
                                onChangeMethod={onChange}
                                onBlurMethod={handleBlur}
                                value={values.porcentageFortalecimiento}
                                isRequired
                                errors={errors.porcentageFortalecimiento}
                                touched={touched.porcentageFortalecimiento}
                                decimals
                                suffix="%"
                                isMin={0}
                                isMax={100}
                            />
                        </Col>
                    </Row>
                    <div className="text-center">
                        <Button color="danger" className="mx-1" onClick={() => window.location.href = "#/template/incrementosalarial"}>Cancelar</Button>
                        <Button color="add" className="mx-1" type="submit">Agregar</Button>
                    </div>
                </form>
                <ModalConfirmation
                    modalTitle={"Agregar nuevo registro"}
                    modal={modal}
                    crear={(rows) => OnCreate(rows)}
                    setModal={setModal}
                    values={values}
                >
                    <div className="d-flex justify-content-center">
                        <h6>¿Desea continuar con el registro?</h6>
                    </div>
                </ModalConfirmation>
            </Widget>
        </>
    )
}
import { peticionesReceiver } from "../../../helpers/peticionesReceiver";
import Notification from "../../../components/Notification/Notification";
import { toast } from "react-toastify";
const API = peticionesReceiver();

export const createImpuesto = async (taxN) => {
  if (taxN) {
    const params = {
      action: "insert",
      table: "taxes",
      rows: taxN,
      order: "nameTax asc",
      validate: [["nameTax"]],
    };
    API.peticion(params)
      .then((res) => {
        if (res.status === 200 && res.data.code === "200") {
          toast(<Notification type={"agrega_exito"} withIcon />);
          window.location.href = "#/template/impuestos";
        } else {
          toast(
            <Notification
              type={"agrega_error"}
              backMessage={res.data.message}
              withIcon
            />
          );
        }
      })
      .catch((err) => {
        toast(<Notification type={"consultar_servidor_error"} withIcon />);
      });
  }
};

export const updateImpuesto = async (taxU) => {
  if (taxU) {
    const params = {
      action: "update",
      table: "taxes",
      rows: taxU,
      condition: {
        keyTax: taxU.keyTax,
      },
      validate: [["nameTax"]],
    };
    API.peticion(params)
      .then((res) => {
        if (res.status === 200 && res.data.code === "200") {
          toast(<Notification type={"modifica_exito"} withIcon />);
          window.location.href = "#/template/impuestos";
        } else {
          toast(
            <Notification
              type={"modifica_error"}
              backMessage={res.data.message}
              withIcon
            />
          );
        }
      })
      .catch((err) => {
        toast(<Notification type={"consultar_servidor_error"} withIcon />);
      });
  }
};

import { Modal, ModalHeader, ModalBody, Button, Row, Col } from "reactstrap"
import { useEffect, useState } from "react"
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as Yup from "yup";

import ModalConfirmation from "../../../../components/Modal/ModalConfirmation";
import Notification from "../../../../components/Notification/Notification";
import { peticionesReceiver } from "../../../../helpers/peticionesReceiver";
import InputWithModal from "../../../../components/GenericInputsFormik/InputWithModal";
import s from "./cell.module.css"
import Loader from "../../../../components/Loader/Loader";

export default function AsignarResguardo({ modal, setModal, idInventory }) {
    const API = peticionesReceiver();
    const [inventory, setInventory] = useState(null)
    const [modalConfirmation, setModalConfirmation] = useState(false)

    const cabecerasMTStaff = [
        "#",
        "Nombre",
        "Seleccionar",
    ];
    const filtroMTStaff = [true, true];
    const paramsMTStaff = {
        action: "datatable",
        table: "staffview",
        rows: "idStaff,concat(name,' ',pName,' ',mName) as nameStaff",
        conditions: "keyEmployType=1",
        page: 0,
        records: 5,
        search: "",
        order: "",
    };


    async function getInventory(id) {
        const params = {
            action: "getInventoryData",
            idInventory: id
        }
        const res = await API.peticionEndPoint(params, "app/facades/inventory/inventoryF.php")
        if (res.status === 200 && res.data.code === "200") {
            const data = res.data.data[0];
            setInventory(data);
        } else {
            toast(<Notification type={"consultar_error"} withIcon />);
            setModal(false)
        }
    }

    useEffect(() => {
        if (modal) {
            getInventory(idInventory)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [idInventory, modal])


    const defaultForm = {
        idStaff: "",
        nameStaff: ""
    }

    const FormSchema = Yup.object().shape({
        idStaff: Yup.number(),
        nameStaff: Yup.string().required("Seleccione un empleado valido"),
    });

    const { handleSubmit, values, handleBlur, errors, touched, setFieldValue, resetForm } =
        useFormik({
            initialValues: defaultForm,
            onSubmit: (values) => {
                setModalConfirmation(true)
            },
            validationSchema: FormSchema,
            enableReinitialize: true,
        });

    const onChange = (e) => {
        setFieldValue(e.target.name, e.target.value);
    };

    const clearStaff = () => {
        setFieldValue(["idStaff"], "")
        setFieldValue(["nameStaff"], "")
    }

    const selectStaff = (el) => {
        setFieldValue(["idStaff"], el[0])
        setFieldValue(["nameStaff"], el[1])
    }

    const OnEdit = async (rows) => {
        const API = peticionesReceiver();
        const params = {
            action: "saveAssignment",
            idInventory,
            idStaff: rows.idStaff
        }
        try {
            const res = await API.peticionEndPoint(params, "app/facades/inventory/inventoryF.php")
            if (res.status === 200 && res.data.code === "200") {
                toast(<Notification type={"modifica_exito"} backMessage={res.data.message} withIcon />);
                setModal(false)
                resetForm()
            } else {
                toast(<Notification type={"modifica_error"} backMessage={res.data.message} withIcon />);
            }
        } catch (err) {
            toast(<Notification type={"modifica_error"} withIcon />);
        }
    }

    return (
        <>
            <Modal isOpen={modal} toggle={() => setModal(false)} fullscreen="lg" size="xl" backdrop="static" >
                <ModalHeader toggle={() => setModal(false)}>Asignar resguardo</ModalHeader>
                <ModalBody className="px-5">
                    {inventory != null ? (
                        <>
                            <h3 className="text-center mb-3 border-bottom">Datos del bien</h3>
                            <div className="text-center h5">
                                {inventory.nameFurniture} MARCA: {inventory.brand} MODELO: {inventory.model}
                            </div>
                            <Row>
                                <Col xs={12} md={6} lg={3} className="p-0">
                                    <div className="border-bottom border-primary text-center p-2">
                                        Numero de inventario
                                    </div>
                                    <div className={`text-center p-2 ${s.cellCustom}`}>
                                        {inventory.inventorykey}
                                    </div>
                                </Col>
                                <Col xs={12} md={6} lg={3} className="p-0">
                                    <div className="border-bottom border-primary text-center p-2">
                                        Numero de serie
                                    </div>
                                    <div className={`text-center p-2 ${s.cellCustom}`}>
                                        {inventory.serialkey}
                                    </div>
                                </Col>
                                <Col xs={12} md={6} lg={3} className="p-0">
                                    <div className="border-bottom border-primary text-center p-2">
                                        Grupo
                                    </div>
                                    <div className={`text-center p-2 ${s.cellCustom}`}>
                                        {inventory.nameGroup}
                                    </div>
                                </Col>
                                <Col xs={12} md={6} lg={3} className="p-0">
                                    <div className="border-bottom border-primary text-center p-2">
                                        Subgrupo
                                    </div>
                                    <div className={`text-center p-2 ${s.cellCustom}`}>
                                        {inventory.nameSubGroup}
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12} md={6} lg={3} className="p-0">
                                    <div className="border-bottom border-primary text-center p-2">
                                        Clase
                                    </div>
                                    <div className={`text-center p-2 ${s.cellCustom}`}>
                                        {inventory.nameClass}
                                    </div>
                                </Col>
                                <Col xs={12} md={6} lg={3} className="p-0">
                                    <div className="border-bottom border-primary text-center p-2">
                                        Partida presupuestal
                                    </div>
                                    <div className={`text-center p-2 ${s.cellCustom}`}>
                                        {inventory.setup}
                                    </div>
                                </Col>
                                <Col xs={12} md={6} lg={3} className="p-0">
                                    <div className="border-bottom border-primary text-center p-2">
                                        Fecha de compra
                                    </div>
                                    <div className={`text-center p-2 ${s.cellCustom}`}>
                                        {inventory.addDate}
                                    </div>
                                </Col>
                                <Col xs={12} md={6} lg={3} className="p-0">
                                    <div className="border-bottom border-primary text-center p-2">
                                        Precio de compra
                                    </div>
                                    <div className={`text-center p-2 ${s.cellCustom}`}>
                                        {inventory.actualPrice}
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12} md={6} lg={4} className="p-0">
                                    <div className="border-bottom border-primary text-center p-2">
                                        Estado
                                    </div>
                                    <div className={`text-center p-2 ${s.cellCustom}`}>
                                        {inventory.nameStat}
                                    </div>
                                </Col>
                                <Col xs={12} md={6} lg={8} className="p-0">
                                    <div className="border-bottom border-primary text-center p-2">
                                        En resguardo de
                                    </div>
                                    <div className={`text-center p-2 ${s.cellCustom}`}>
                                        {inventory.staffAsiggnment}
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12} className="p-0">
                                    <div className="border-bottom border-primary text-center p-2">
                                        Adscripción
                                    </div>
                                    <div className={`text-center p-2 ${s.cellCustom}`}>
                                        {inventory.nameArea}
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12} className="p-0">
                                    <div className="border-bottom border-primary text-center p-2">
                                        Motivo de actualización del costo y/o numero de serie
                                    </div>
                                    <div className={`text-center p-2 ${s.cellCustom}`}>
                                        {inventory.notes !== "" ? inventory.notes : "SIN CAMBIOS"}
                                    </div>
                                </Col>
                            </Row>
                            <form onSubmit={handleSubmit}>
                                <Row>
                                    <Col xs={12}>
                                        <InputWithModal
                                            cabeceras={cabecerasMTStaff}
                                            error={errors.nameStaff}
                                            filtro={filtroMTStaff}
                                            handleBlur={handleBlur}
                                            onChange={onChange}
                                            actionSelect={selectStaff}
                                            onClear={clearStaff}
                                            parametros={paramsMTStaff}
                                            touched={touched.nameStaff}
                                            value={values.nameStaff}
                                            label="Nuevo resguardo"
                                            name={"nameStaff"}
                                            title={"Seleccionar empleado"}
                                        />
                                    </Col>
                                </Row>

                                <div className="text-center mt-2">
                                    <Button color="danger" className="mx-1" onClick={() => setModal(false)}>Cerrar</Button>
                                    <Button color="add" className="mx-1" >Actualizar resguardo</Button>
                                </div>
                            </form>
                        </>
                    ) : <Loader />}
                </ModalBody>
            </Modal>
            <ModalConfirmation
                modalTitle={"Editar"}
                modal={modalConfirmation}
                editar={(rows) => OnEdit(rows)}
                setModal={setModalConfirmation}
                isEdit={true}
                values={values}
            >
                <div className="d-flex justify-content-center">
                    <h6>¿Desea continuar con el registro?</h6>
                </div>
            </ModalConfirmation>
        </>
    )
}
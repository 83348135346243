import { Formik, Form, Field, ErrorMessage, useFormikContext } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { useHistory } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { Col, Row, Button } from "reactstrap";
import { toast } from "react-toastify";
import Notification from "../../../components/Notification/Notification";
import Loader from "../../../components/Loader/Loader";
import ModalConfirmation from "../../../components/Modal/ModalConfirmation";
import { peticionEncript } from "../../../helpers/peticionesEncripted";
import { SelectTypeHeadSingle } from "../../../components/GenericInputsFormik/SelectTypeHeadSingle";


const defaultForm = {
  keyDistrict: "",
  keyRegion: "",
  nameDistrict: "",
};

const FormDistrito = ({ crear, data, editar }) => {
  const history = useHistory();
  const [regions, setRegions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [modal, setModal] = useState(false);
  const [form, setForm] = useState(defaultForm);

  const FormSchema = Yup.object().shape({
    nameDistrict: Yup.string()
      .min(4, "Nombre debe contener al menos 4 letras")
      .max(200, "El nombre ingresado es muy largo")
      .required("Ingrese un nombre de distrito"),
    keyRegion: Yup.string().required("Seleccione una región"),
  });

  const getRegions = async () => {
    const params = {
      action: "select",
      table: "regions",
      order: "nameRegion ASC",
    };
    const finalData = peticionEncript(params)
    await axios
      .post(`${process.env.REACT_APP_API}receiver/receiver.php`, finalData)
      .then((res) => {
        if (res.status === 200 && res.data.code === "200") {
          setRegions(res.data.data);
          setLoading(false);
        } else {
          toast(<Notification type={"consultar_error"} withIcon />);
          setLoading(false);
        }
      })
      .catch((err) => {
        toast(<Notification type={"consultar_servidor_error"} withIcon />);
        setLoading(false);
      });
  };

  useEffect(() => {
    getRegions();
  }, []);

  const submitFunction = (fields) => {
    setModal(true);
  };

  const cancelOp = () => {
    history.push("/template/distritos");
  };

  const findId = (name) => {
    let id = "";
    for (const el of regions) {
      if (el[1] === name) {
        id = el[0];
        break;
      }
    }
    return id;
  };
 
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <Formik
          initialValues={form}
          validationSchema={FormSchema}
          onSubmit={(fields, { resetForm }) => {
            submitFunction(fields);
          }}
          onReset={(fields, { resetForm }) => {
            cancelOp();
          }}
        >
          {({ errors, status, touched, values, setFieldValue, handleBlur }) => (
            <Form className="mt-4">
              <ModalConfirmation
                modalTitle={data ? "Editar" : "Crear"}
                modal={modal}
                setModal={setModal}
                crear={crear}
                editar={editar}
                isEdit={data ? true : false}
                values={values}
              >
                {data ? (
                  <div className="d-flex justify-content-center">
                    <h6>¿Está seguro de editar el registro?</h6>
                  </div>
                ) : (
                  <div className="d-flex justify-content-center">
                    <h6>¿Desea continuar con el registro?</h6>
                  </div>
                )}
              </ModalConfirmation>
              <Row>
                <Col xs="12" md="6">
                  <div className="form-group">
                    <label htmlFor="nameDistrict">
                      Nombre<span className="text-danger">*</span>
                    </label>
                    <Field
                      name="nameDistrict"
                      type="text"
                      className={
                        "form-control" +
                        (errors.nameDistrict && touched.nameDistrict
                          ? " is-invalid"
                          : "")
                      }
                    />
                    <ErrorMessage
                      name="nameDistrict"
                      component="div"
                      className="invalid-feedback"
                    />
                  </div>
                </Col>
                {/* <Col xs="12" md="6">
                  <div className="form-group">
                    <label htmlFor="mName">
                      Región<span className="text-danger">*</span>
                    </label>
                    <Field
                      name="keyRegion"
                      as="select"
                      className={
                        "form-control" +
                        (errors.keyRegion && touched.keyRegion
                          ? " is-invalid"
                          : "")
                      }
                    >
                      <option defaultValue={true} disabled key="0" value="">
                        Seleccione una región
                      </option>
                      {regions.map((e) => (
                        <option key={e[0]} value={e[0]}>
                          {e[1]}
                        </option>
                      ))}
                    </Field>

                    <ErrorMessage
                      name="keyRegion"
                      component="div"
                      className="invalid-feedback"
                    />
                  </div>
                </Col> */}
                <Col xs="12" md="6">
                  <SelectTypeHeadSingle
                    label="Región"
                    inputName="keyRegion"
                    onChangeMethod={ (e) => setFieldValue([e.target.name],e.target.value)}
                    onBlurMethod={handleBlur}
                    value={values.keyRegion}
                    isRequired={true}
                    touched={touched.keyRegion}
                    errors={errors.keyRegion}
                    optionsArray={regions}
                    optionValue="keyRegion"
                    optionName="nameRegion"
                    defaultOption="Seleccione una región"
                  />
                </Col>
              </Row>
              
            <div className=" d-flex flex-sm-row flex-column-reverse justify-content-sm-around mt-1">
              <Button color="danger" type="reset" className="mt-3">
                Cancelar
              </Button>
              <Button color="success" type="submit" className="mt-2 mt-sm-3">
                Guardar
              </Button>
            </div>
              <FormikData info={data} />
            </Form>
          )}
        </Formik>
      )}
    </>
  );
};

const FormikData = ({ info }) => {
  const { setFieldValue } = useFormikContext();
  useEffect(() => {
    if (info) {
      setFieldValue("keyDistrict", info[0]);
      setFieldValue("nameDistrict", info[1]);
      setFieldValue("keyRegion", info[2]);
    }
  }, [info]);
  return null;
};

export default FormDistrito;

import React from "react";

import Widget from "../../../../components/Widget/Widget";
import FormEstado from "../FormEstado";
import { createState } from "../functions";

export const CrearEstado = (props) => {
  return (
    <Widget className="widget-p-md">
        <h3>Crear estado</h3>
        <FormEstado crear={createState}/>
    </Widget>
  )
}

export default CrearEstado;
/**
 * @param params setters from Format
 */
export function setConfigR25(params) {
    const { setHeaders, setPdfConfig, setExcelConfig, setHtmlConfig, setData, filter } = params;
    const headers = [
        "Id",
        "Mes",
        "Año",
        "Total empleados",
        "Suma deducciones",
        "Editar",
    ]
    setHeaders(headers)
    const tempData = {
        action: "datatable",
        table: "isrmonthlyreport mr",
        rows: "mr.idIsrmonthlyreport,mr.month,mr.year,(SELECT count(*) from isrstaffreports where idIsrmonthlyreport=mr.idIsrmonthlyreport) as totalEmp,(mr.amountSP+mr.amountCR) as total",
        conditions: `mr.month = ${filter.month} AND mr.year = ${(new Date(filter.year)).getFullYear()} AND mr.enabled = 1`,
    }
    setData(tempData);
    const content = [
        { header: "Mes", value: "month", format: false },
        { header: "Año", value: "year", format: false },
        { header: "Total empleados", value: "totalEmp", format: false },
        { header: "Suma deducciones", value: "total", format: true },
    ];
    setHtmlConfig({
        headers,
        id: { id: "idIsrmonthlyreport" },
        content: [content]
    });
    setExcelConfig({
        headers: ["No.", "Concepto", "Monto", "Empleado", "RFC"],
        content: [
            { header: "No.", value: "id", format: false },
            { header: "Concepto", value: "nameIsrunder", format: false },
            { header: "Monto", value: "amount", format: true },
            { header: "Empleado", value: "nombre", format: false },
            { header: "RFC", value: "rfc", format: false },
        ]
    });
    setPdfConfig({
        headers: ["No.", "Concepto", "Monto", "Empleado", "RFC"],
        sizes: [5, 20, 10, 45, 20],
        content: [
            { header: "No.", value: "id", format: false },
            { header: "Concepto", value: "nameIsrunder", format: false },
            { header: "Monto", value: "amount", format: true },
            { header: "Empleado", value: "nombre", format: false },
            { header: "RFC", value: "rfc", format: false },
        ],
        orientation: "l"
    })
}

export function configRep25(values) {
    return {
        action: "multiselect",
        table: "isrmonthlyreport mr join isrstaffreports isr using (idIsrmonthlyreport) join staff stf using (idStaff) join isrunders isru using(keyIsrunder)",
        rows: "mr.month,mr.year,mr.amountSP,mr.amountCR,mr.amountCS,mr.amountCRep,mr.amountSC,mr.amountGA,mr.amountRT,isr.amount,isru.nameIsrunder,concat(stf.staffNumber,' - ', stf.name, ' ', stf.pName, ' ', stf.mName) as nombre,stf.rfc",
        conditions: `mr.month = ${values.month} AND mr.year = ${(new Date(values.year)).getFullYear()} AND mr.enabled = 1`,
    }
}
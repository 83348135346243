import Datatable from "../../../components/Datatable/Datatable";
import { fDate } from "../../../libs/tools/format";
import { doRequest, doRequestSaveRes } from "../../../helpers/requests";
import { useState } from "react";
import { Button, Col, Collapse, Row } from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import FullFormLoader from "../../../components/Loader/FullFormLoader";
import ModalConfirmation from "../../../components/Modal/ModalConfirmation";
import ButtonInputM from "../../../components/GenericInputsFormik/ButtonInputModal";
import { TextInput } from "../../../components/GenericInputsFormik";
import format from "date-fns/format";
import { toast } from "react-toastify";
import Notification from "../../../components/Notification/Notification";
import ModalDelete from "../../../components/Modal/ModalDelete";

const headers = ["Adscripción devuelve", "Empleado realiza", "Almacén", "Fecha devolución", "Estado", "Editar", "Eliminar", "Detalle devolución"];
const columnDefs = [
    {
        targets: [3, 4, 5, 6, 7],
        className: "text-center",
    },
    {
        orderable: false,
        targets: [5, 6, 7]
    }
];

const modalTablaAreaParams = {
    title: "Adscripciones",
    headers: ["Adscripción"],
    columns: [
        {data: "nameArea"},
    ],
    params: {
        table: "areas",
        rows: "idArea, name AS nameArea",
        conditions: `enabled = 1`,
    },
}

const defaultForm = {
    idReturn: "",
    idAreaReturn: "",
    nameArea: "",
    idStaffReturn: "",
    nameStaff: "",
    dateReturn: ""
}

const FormSchema = Yup.object().shape({
    nameArea: Yup.string().required("Seleccione una adscripción"),
    nameStaff: Yup.string().required("Seleccione un empleado"),
    dateReturn: Yup.string().required("Seleccione una fecha"),
});

const cYear = new Date().getFullYear();

export default function Consulta({permissions, setInfo, idArea, info}){
    const [refresh, setRefresh] = useState(false);
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [modal, setModal] = useState(false);

    const {
        handleSubmit,
        handleReset,
        handleBlur,
        values,
        errors,
        touched,
        setFieldValue,
        setValues
    } = useFormik({
        initialValues: defaultForm,
        validationSchema: FormSchema,
        enableReinitialize: true,
        onSubmit: () => setModal(true),
        onReset: () => setOpen(false),
    });
        /* Table */
        const columns = [
            {data: "nAreaD", render: (d)=><div style={{minWidth: "300px"}}>{d}</div>},
            {data: "fullName", render: (d)=><div style={{minWidth: "200px"}}>{d}</div>},
            {data: "nAreaR", render: (d)=><div style={{minWidth: "300px"}}>{d}</div>},
            {data: "dateReturn", format: fDate},
            {data: "nameStat"},
            {
                data: null, 
                render: (row)=> permissions.UPD && +row.keyStat === 134 && <i className="fa fa-pencil text-warning cursor-pointer" onClick={()=>openToEdit(row)}/>
            },
            {
                data: null, 
                render: (row) => (
                    permissions.DEL && +row.keyStat === 134 &&
                    <ModalDelete
                        modalTitle="Eliminar"
                        table=""
                        nameColumn={[""]}
                        elimina={fnDelete}
                        id={row.idReturn}
                    >
                        <h6>
                            Está seguro de eliminar esta devolución?
                        </h6>
                        No podrá revertir esta acción
                    </ModalDelete>
                ),
            },
            {
                data: null, 
                render: (row)=> <i className="fa fa-eye text-info cursor-pointer" onClick={()=>{setInfo({id: row.idReturn, stat: row.keyStat})}}/>
            }
        ]

        async function getData(ordCol, itemsPerPage, currentPage, search, multi) {
            const res = await doRequestSaveRes(
                "receiver/receiver.php",
                getParams(ordCol, itemsPerPage, currentPage, search, multi),
                false,
                false
            );
            if (res.code === "200") return res;
            return null;
        }

        function getParams(ordCol, itemsPerPage, currentPage, search) {
            const params = {
                action: "datatable",
                table: "warehousereturns A INNER JOIN areas B ON B.idArea = A.idAreaReturn INNER JOIN areas C ON C.idArea = A.idAreaReceive INNER JOIN status D USING(keyStat) INNER JOIN staff E ON A.idStaffReturn = E.idStaff",
                rows: "B.name as nAreaD, CONCAT_WS(' ', E.name, E.pName, E.mName) AS fullName, C.name as nAreaR, A.dateReturn, D.nameStat, A.idReturn, A.idAreaReturn, A.idStaffReturn, A.keyStat",
                conditions: `A.idAreaReceive = ${idArea} AND A.enabled = 1`,
                order: ordCol,
                records: itemsPerPage.toString(),
                page: (itemsPerPage * currentPage).toString(),
                search
            };
            return params;
        }

        /* Form */
        async function save() {
            setLoading(true);
            const params = {
                action: "insert",
                table: "warehousereturns",
                rows: {
                    keyStat: 134, 
                    idAreaReturn: values.idAreaReturn,
                    idAreaReceive: idArea,
                    dateReturn: values.dateReturn,
                    idStaffReturn: values.idStaffReturn
                },
                validate: [],
            };
            if(values.idReturn){
                params.action = "update";
                params.condition = { idReturn: values.idReturn };
            }
            const res = await doRequest("receiver/receiver.php", params, true);
            setLoading(false);
            if (res.length > 0){
                setInfo({id: res[0].idReturn, stat: res[0].keyStat});
            }
        }

        function openToEdit(row){
            if(!open){
                setValues({
                    idReturn: row.idReturn,
                    idAreaReturn: row.idAreaReturn,
                    nameArea: row.nAreaD,
                    idStaffReturn: row.idStaffReturn,
                    nameStaff: row.fullName,
                    dateReturn:row.dateReturn.split(" ")[0]
                }, true);
                setOpen(true);
            }else{
                toast(
                    <Notification
                      type={"warning"}
                      backMessage="Guarde los cambios antes de continuar"
                      withIcon
                    />,
                    { closeButton: false }
                  );
            }
        }

        async function fnDelete(id) {
            setLoading(true);
            const params = {
                action: "delete",
                table: "warehousereturns",
                condition: {
                    idReturn: id,
                },
                force: 1
            };
            const res = await doRequest("receiver/receiver.php", params, true);
            if (res.length > 0) {
                setRefresh(true);
                if(+values.idReturn === +id) handleReset();
            }
            setLoading(false);
        }

        /* InputModal */
        const modalTablaStaffParams = {
            title: "Empelados",
            headers: ["No. Empleado", "Nombre", "Puesto"],
            columns: [
                {data: "staffNumber"},
                {data: "fullName"},
                {data: "nameJobType"}
            ],
            columnDefs: [{targets: [0, 2], className: "text-center"}],
            params: {
                table: "staff A INNER JOIN employtypes B ON A.keyEmployType = B.keyEmployType INNER JOIN jobstaff C ON C.idStaff = A.idStaff INNER JOIN jobtypes D ON D.keyJobType = A.keyEmployType",
                rows: "A.idStaff, A.staffNumber, CONCAT(A.name,' ',A.pName,' ',A.mName) as fullName, D.nameJobType",
                conditions: `A.enabled = 1 AND A.keyStat = 1 AND C.valid = 1 AND C.enabled = 1 AND C.idArea = ${values.idAreaReturn}`
            },
        }

        function selectRow(row){
            if(row.idArea){
                setFieldValue("idAreaReturn", row.idArea);
                setFieldValue("nameArea", row.nameArea);
            }else{
                setFieldValue("idStaffReturn", row.idStaff);
                setFieldValue("nameStaff", row.fullName);
            }
        }

        function clearInput(field){
            if(field === "nameArea"){
                setFieldValue("idAreaReturn", "");
                setFieldValue("nameArea", "");
            }
            setFieldValue("idStaffReturn", "");
            setFieldValue("nameStaff", "");
        
        }

    return(
        <div>
            {   
                permissions.CON &&
                <Datatable
                    headers={headers}
                    columns={columns}
                    columnDefs={columnDefs}
                    petition={getData}
                    control="back"
                    stateRefresh={[refresh, setRefresh]}
                    searching={true}
                    className="px-0 mt-4"
                />
            }
            {
                permissions.INS &&
                <div className=" d-flex flex-md-row flex-column justify-content-md-end">
                    <Button color="info" onClick={() => setOpen(true)}>
                        Agregar
                    </Button>
                </div>
            }

            <Collapse isOpen={open} className="mt-3 pt-3 border-top">
                <h5 className="mb-2">{values.idReturn? "Editar " : "Crear "}devolución</h5>
                <form onSubmit={handleSubmit} onReset={handleReset}>
                    <Row>
                        <Col xs={12} md={6}>
                            <ButtonInputM
                                label="Adscripción de devolución"
                                inputName="nameArea"
                                value={values.nameArea}
                                touched={touched.nameArea}
                                errors={errors.nameArea}
                                isRequired={true}
                                modalParams={modalTablaAreaParams}
                                selectRow={selectRow}
                                clearFun={clearInput}
                            />
                        </Col>
                        <Col xs={12} md={6}>
                            <ButtonInputM
                                label="Empleado"
                                inputName="nameStaff"
                                value={values.nameStaff}
                                touched={touched.nameStaff}
                                errors={errors.nameStaff}
                                isRequired={true}
                                modalParams={modalTablaStaffParams}
                                selectRow={selectRow}
                                clearFun={clearInput}
                                isDisabled={!values.idAreaReturn}
                            />
                        </Col>
                        <Col xs={12} md={6}>
                            <TextInput
                                label="Fecha devolución"
                                inputName="dateReturn"
                                inputType="date"
                                onChangeMethod={({target})=>setFieldValue("dateReturn", target.value)}
                                onBlurMethod={handleBlur}
                                value={values.dateReturn}
                                errors={errors.dateReturn}
                                touched={touched.dateReturn}
                                isMin={format(new Date(cYear-1, 0, 1), "yyyy-MM-dd")}
                            />
                        </Col>
                    </Row>


                    <div className=" d-flex flex-md-row flex-column-reverse justify-content-md-around">
                        <Button color="danger" type="reset" className="mt-3">
                            Cancelar
                        </Button>
                        <Button color="success" type="submit" className="mt-2 mt-md-3">
                            Guardar
                        </Button>
                    </div>
                </form>
            </Collapse>

            <ModalConfirmation
                modalTitle="Crear requisición"
                modal={modal}
                setModal={setModal}
                crear={save}
            >
                <div className="d-flex justify-content-center">
                    <h6>¿Está seguro de guardar la revolución?</h6>
                </div>
            </ModalConfirmation>

            <FullFormLoader show={loading} />
        </div>
    )
}
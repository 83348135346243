import React, { useState, useEffect } from "react";
import TableComponent from "../../../components/TableComponent/TableComponentFiltro";
const Usuarios = () => {
  const [cabeceras] = useState([
    "Id",
    "Nombre",
    "Usuario",
    "Telefono",
    "Correo",
    "Editar",
    "Eliminar",
  ]);
  const filtro = [true, true, true, true, true, false]
  const [parametros] = useState({
    action: "datatable",
    table: "users", 
    rows: "idUser,CONCAT_WS(' ',name,pname,mName) as fullName,user,phone,email,password,name,pname,mName,idStaff,staffNumber,actualArea",
    conditions:"enabled = 1",
    page:0,
    records:5,
    search:"",
    order:""
  });
  return (
    <div>
        <TableComponent
          titulo="Usuarios"
          cabecerasTabla={cabeceras}
          filtro={filtro}
          rutaCrear="/template/usuarios/crear"
          rutaEditar="/template/usuarios/editar"
          parametros={parametros}
          forceDelete={1}
          beforeDelete={"users"}
        ></TableComponent>
    </div>
  );
};

export default Usuarios;

import React, { useState, useEffect } from "react";
import { Button, Collapse, Label, FormGroup } from "reactstrap";
import { Formik, Form, Field } from "formik";
import { toast } from "react-toastify";

import Notification from "../../../components/Notification/Notification";
import { peticionesReceiver } from "../../../helpers/peticionesReceiver";
import TableComponentVer from "../../../components/TableComponent/TableComponentVer";
import { SelectTypeHeadSingle } from "../../../components/GenericInputsFormik/index";
import FullFormLoader from "../../../components/Loader/FullFormLoader";
import { doRequest } from "../../../helpers/requests";
import { getStaffPic } from "../../../libs/tools/images";

const formDefault = {
  staffNumber: "",
  nameStaff: "",
  staffType: "1",
};

const ExpedienteNominaBuscar = ({ setStaff }) => {
  const [loading, setLoading] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [tipos, setTipos] = useState([]);
  const [parametros, setParametros] = useState({
    action: "datatable",
    table:
      "staff A INNER JOIN employtypes B ON B.keyEmployType = A.keyEmployType INNER JOIN status C ON A.keyStat = C.keyStat LEFT JOIN jobstaff D ON A.idStaff = D.idStaff LEFT JOIN areas E ON D.idArea = E.idArea LEFT JOIN jobs F ON D.keyJob = F.keyJob LEFT JOIN areas G ON D.idAreaComission = G.idArea",
    rows: "A.idStaff, A.staffNumber, CONCAT(A.name, ' ', A.pName, ' ', A.mName) AS fullName, B.nameEmployType, C.nameStat, E.name AS nameNominal, F.nameJob, G.name AS nameFisica, D.idJobStaff",
    conditions: "",
    page: 0,
    records: 5,
    search: "",
    order: "",
  });
  const API = peticionesReceiver();
  const cabeceras = ["Id", "Núm. Empleado", "Nombre", "Tipo", "Ver"];
  const filtro = [true, true, true, true];

  const getTypes = async () => {
    const params = {
      action: "select",
      table: "employtypes",
      condition: {
        enabled: 1,
      },
      order: "nameEmployType ASC",
    };
    API.peticion(params)
      .then((res) => {
        if (res.status === 200 && res.data.code === "200") {
          let data = [];
          res.data.data.forEach((el) => {
            data.push({
              keyEmployType: el.keyEmployType,
              nameEmployType: el.nameEmployType,
            });
          });
          setTipos(data);
        } else {
          toast(
            <Notification
              type={"consultar_error"}
              backMessage={res.data.message}
              withIcon
            />
          );
        }
      })
      .catch((err) => {
        toast(<Notification type={"consultar_servidor_error"} withIcon />);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    getTypes();
  }, []);

  const submitFunction = (fields) => {
    if (fields.staffNumber) {
      setLoading(true);
      if (fields.staffType) {
        const par = { ...parametros };
        par.conditions = `A.staffNumber = ${fields.staffNumber} AND A.keyEmployType = ${fields.staffType} AND A.enabled = 1 AND IF(ISNULL(D.valid),true, D.valid != 0 AND D.enabled = 1)`;
        API.peticion(par)
          .then((res) => {
            if (res.status === 200 && res.data.code === "200") {
              if (res.data.data && res.data.data.length !== 0) {
                selectEmployee(Object.values(res.data.data[0]));
              } else {
                toast(
                  <Notification
                    type={"consultar_error"}
                    backMessage={"No se encontraron empleados"}
                    withIcon
                  />,
                  { closeButton: false }
                );
              }
            }
          })
          .catch((err) => {
            toast(<Notification type={"consultar_servidor_error"} withIcon />);
          })
          .finally(()=> setLoading(false));
      } else {
        toast(
          <Notification
            type={"agrega_error"}
            backMessage={"Seleccione un tipo de empleado"}
            withIcon
          />,
          { closeButton: false }
        );
      }
    } else if (fields.nameStaff) {
      // let clName = fields.nameStaff.trim().split(" ").map(n => `'%${n}%'`);
      let clName = `'%${fields.nameStaff.trim()}%'`;
      // let likeCondition = [`concat(A.name,' ',A.pName,' ',A.mName) like '%${fields.nameStaff.trim()}%'`];
      // ["A.name", "A.pName", "A.mName"].forEach((w)=>{
      //   let field = [];
      //   names.forEach((n)=> field.push(`${w} LIKE ${n}`));
      //   likeCondition.push(`(${field.join(" OR ")})`);
      // });
      const conds = [
        `CONCAT_WS(' ',A.name,A.pName,A.mName) LIKE ${clName}`,
        `CONCAT_WS(' ',A.name,A.mName,A.pName) LIKE ${clName}`,
        `CONCAT_WS(' ',A.pName,A.mName,A.name) LIKE ${clName}`,
        `CONCAT_WS(' ',A.pName,A.name,A.mName) LIKE ${clName}`,
        `CONCAT_WS(' ',A.mName,A.pName,A.name) LIKE ${clName}`,
        `CONCAT_WS(' ',A.mName,A.name,A.pName) LIKE ${clName}`,
      ]
      setParametros((prevState) => ({
        ...prevState,
        // V1 WITH ARRAY
        // conditions: `A.enabled = 1 AND IF(D.valid IS NULL,true,IF(D.valid != 0, true, false)) AND (concat(A.name,' ',A.pName,' ',A.mName) like '%${fields.nameStaff}%' OR A.name IN (${names}) OR A.pName IN (${names}) OR A.mName IN (${names})) AND D.enabled = 1`,
        // V2 WITH EVERY LIKE
        // conditions: `A.enabled = 1 AND IF(ISNULL(D.valid),true, D.valid != 0 AND D.enabled = 1) AND (${likeCondition.join(" OR ")})`,
        // V3 COMBINATION LIKE
        conditions: `A.enabled = 1 AND IF(ISNULL(D.valid),true, D.valid != 0 AND D.enabled = 1) AND (${conds.join(" OR ")})`,
      }));
      setIsOpen(true);
    } else {
      toast(
        <Notification
          type={"agrega_error"}
          backMessage={"Ingrese un nombre o un número de empleado"}
          withIcon
        />,
        { closeButton: false }
      );
    }
  };

  const selectEmployee = async (el) => {
    const query = {
      action: "multiselect",
      table: "staff A LEFT JOIN bookcase BC ON (BC.enabled = 1 AND BC.keyFileType = 1 AND BC.reference = A.idStaff) LEFT JOIN files F ON (F.enabled = 1 AND F.idBook = BC.idBook)",
      rows: "A.keyGenre, F.src",
      conditions: `A.enabled = 1 AND A.idStaff = ${el[0]}`, 
      page: 0,
      records: 5,
      search: "",
      order: "",
    };
    const res = await doRequest("receiver/receiver.php", query, false, false);
    let picture = "";
    if(res.length > 0){
      picture = getStaffPic(res[0].keyGenre, res[0].src)
    }
    setStaff({
      idStaff: el[0],
      staffNumber: el[1],
      fullName: el[2],
      employType: el[3],
      nameStat: el[4],
      nameArea: el[5],
      nameJob: el[6],
      picture,
      nameBuilding: el[7],
    });
  };

  return (
    <div>
      <div className="row">
            <div className="col-sm-6">
              <h4 className="text-center text-sm-left">Buscar empleado</h4>
            </div>
      </div>
      <Formik
            initialValues={formDefault}
            onSubmit={(fields) => {
              submitFunction(fields);
            }}
          >
            {({ errors, touched, handleBlur, setFieldValue, values }) => (
              <Form className="mt-3 row justify-content-center">
                <div className="col-12 col-md-7">
                  <FormGroup>
                    <Label for="nameStaff">Nombre de empleado</Label>
                    <Field
                      name="nameStaff"
                      type="text"
                      className="form-control"
                    />
                  </FormGroup>
                  <p className="dividing-line-form my-5">
                    &#8195;&Oacute;&#8195;
                  </p>
                  <FormGroup className="mt-4">
                    <Label for="staffNumber">Número de empleado</Label>
                    <Field
                      name="staffNumber"
                      type="text"
                      className="form-control"
                    />
                  </FormGroup>
                  <FormGroup>
                    <SelectTypeHeadSingle
                      label="Tipo de empleado"
                      isRequired={false}
                      inputName="staffType"
                      optionsArray={tipos}
                      defaultOption="Seleccione un tipo de empleado"
                      onChangeMethod={(event) =>
                        setFieldValue([event.target.name], event.target.value)
                      }
                      onBlur={handleBlur}
                      isDisabled={false}
                      value={values.staffType}
                      touched={touched.staffType}
                      errors={errors.staffType}
                      optionValue="keyEmployType"
                      optionName="nameEmployType"
                    />
                  </FormGroup>
                  <div className="d-flex justify-content-center mt-5">
                    <Button color="info" type="submit">
                      Buscar
                    </Button>
                  </div>
                </div>
              </Form>
            )}
      </Formik>
      <Collapse isOpen={isOpen}>
            <TableComponentVer
              titulo={"Empleados"}
              cabecerasTabla={cabeceras}
              filtro={filtro}
              ver={selectEmployee}
              parametros={parametros}
              usePermissions={false}
            />
      </Collapse>
        <FullFormLoader show={loading} />
    </div>
  );
};
export default ExpedienteNominaBuscar;

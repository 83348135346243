import { StyleSheet, View, Text } from "@react-pdf/renderer";

const s = StyleSheet.create({
  h: { height: "90px" },
  mxauto: { marginHorizontal: "auto" },
  max: { width: "70%" },
  mb5: { marginBottom: 5 },
  mt50: { marginTop: 50 },
  textBold: { fontFamily: "Helvetica-Bold" },
  spaceFooter: {
    bottom: 10,
    left: 0,
    right: 0,
    width: "auto",
    height: 30,
    marginHorizontal: 10,
  },
  footer: {
    position: "absolute",
    bottom: 10,
    left: 0,
    right: 0,
    width: "auto",
    height: 30,
    marginHorizontal: 10,
    borderTopWidth: 1,
    flexDirection: "row",
    alignContent: "space-around",
    alignItems: "center",
  },
  textFooter: {
    width: "50%",
    textAlign: "center",
    fontSize: 8,
  },
});

export default function FooterReport({fileNo}) {
  // const origin = window.location.origin;
  return (
    <>
      <View fixed style={s.spaceFooter}></View>
      <View fixed style={s.footer}>
        <Text style={s.textFooter}>{fileNo}</Text>
        <Text
          style={s.textFooter}
          render={({ pageNumber, totalPages }) =>
            `PAGINA : ${pageNumber} / ${totalPages}`
          }
        />
      </View>
    </>
  );
}

import React, { useState } from "react";
import TableComponent from "../../../components/TableComponent/TableComponentFiltro";

export const ObjetosGasto = () => {
  const cabeceras = [
    "Id", //0
    "Clave",
    "Capitulo",
    "Concepto",
    "Partida Generica",
    "Denominación",
    "Tipo de gasto",
    "Editar",
    "Eliminar",
  ];
  const [parametros] = useState({
    action: "datatable",
    table: "expense_objects e inner join expenses_items ei on e.keyItem = ei.keyItem inner join expenses_concepts ec on ei.keyConcept = ec.keyConcept inner join expenses_chapters c on ec.keyChapter = c.keyChapter INNER JOIN expense_types et on e.keyExpenseType = et.keyExpenseType",
    rows: "idExpenseObject,fullKey,nameChapter,nameConcept,nameItem,nameObject,nameExpenseType,e.keyItem,e.keyExpenseType,ec.keyConcept,c.keyChapter,objectKey", ///sin espacios y comas
    conditions: "e.enabled = 1",
    page: 0,
    records: 5,
    search: "",
    order: "",
  });

  const [filter] = useState([true, true, true, true, true, true, true, false, false, false, false, false])

  return (
    <div>
      <TableComponent
        titulo="Objetos de Gasto"
        cabecerasTabla={cabeceras}
        filtro={filter}
        rutaCrear="/template/objetos-gasto/crear"
        rutaEditar="/template/objetos-gasto/editar"
        parametros={parametros}
      ></TableComponent>
    </div>
  );
};
export default ObjetosGasto;

/* eslint-disable react-hooks/exhaustive-deps */
import * as Yup from "yup";
import { toast } from "react-toastify";
import { Col, Row, Button } from "reactstrap";
import { useHistory } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage, useFormikContext } from "formik";

import { getSalariesTypes } from "./functions";
import { peticionesReceiver } from "../../../helpers/peticionesReceiver";

import Loader from "../../../components/Loader/Loader";
import Notification from "../../../components/Notification/Notification";
import ModalConfirmation from "../../../components/Modal/ModalConfirmation";
import { SelectTypeHeadSingle } from "../../../components/GenericInputsFormik";
import { NumberInput } from "../../../components/GenericInputsFormik/NumberInput";

const FormSalarios = ({ crear, data, editar }) => {
  const defaultForm = {
    idSalary: 0,
    level: "",
    subLevel: "",
    rango: "",
    base: "",
    gross: "",
    net: "",
    reward: "",
    larder: "",
    clearance: "",
    fortalecimiento: "",
    travels: "",
    taxes: "",
    ssecurity: "",
    keyStat: "",
    keySalaryType: 0,
  };

  const history = useHistory();
  const API = peticionesReceiver();

  const form = defaultForm;
  const [modal, setModal] = useState(false);
  const [allStatus, setAllStatus] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [salariesTypes, setSalariesTypes] = useState([]);

  useEffect(() => {
    getStatus();
    getTypes();
  }, []);

  const getTypes = async () => {
    const response = await getSalariesTypes();
    setSalariesTypes(response);
  };

  const getStatus = async () => {
    const params = {
      action: "select",
      table: "status",
      condition: {
        enabled: 1,
        keyTypeStat: 11,
      },
      order: "nameStat ASC",
    };
    const res = await API.peticion(params);
    if (res.status === 200 && res.data.code === "200") {
      const data = res.data.data.map((el) => {
        return { label: el.nameStat, value: el.keyStat };
      });
      setAllStatus(data);
      setIsLoading(false);
    } else {
      toast(<Notification type={"consultar_error"} withIcon />);
    }
  };

  const FormSchema = Yup.object().shape({
    level: Yup.string()
      .min(1, "El nivel debe ser minimo de 1")
      .max(999, "El nivel debe ser maximo de 999")
      .required("Favor de ingresar un nivel"),

    rango: Yup.string()
      .min(1, "El rango debe contener al menos 1 letras")
      .max(200, "El rango ingresado es muy largo")
      .required("Favor de ingresar un rango"),

    reward: Yup.string()
      /* .min(0, "La gratificacion debe ser mayor a los $0")
      .max(9999999999, "La gratificacion debe ser maximo de $9999999999") */
      .required("Favor de ingresar una gratificacion"),

    larder: Yup.string()
      /* .min(0, "El valor de la despensa debe ser mayor a los $0")
      .max(9999999999, "El valor de la despensa debe ser maximo de $9999999999") */
      .required("Favor de ingresar el valor de la despensa"),

    clearance: Yup.string()
      /* .min(0, "La compensación debe ser mayor a los $0")
      .max(9999999999, "La compensación debe ser maximo de $9999999999") */
      .required("Favor de ingresar una compensación"),

    fortalecimiento: Yup.string()
      /* .min(0, "El fortalecimiento debe ser mayor a los $0")
      .max(9999999999, "El fortalecimiento debe ser maximo de $9999999999") */
      .required("Favor de ingresar el valor del fortalecimiento"),

    travels: Yup.string()
      /* .min(0, "Los viaticos debe ser mayor a los $0")
      .max(9999999999, "Los viaticos debe ser maximo de $9999999999") */
      .required("Favor de ingresar el valor de los viaticos"),

    taxes: Yup.string()
      /* .min(0, "Los impuestos debe ser mayor a los $0")
      .max(9999999999, "Los impuestos debe ser maximo de $9999999999") */
      .required("Favor de ingresar el valor de los impuestos"),

    ssecurity: Yup.string()
      /* .min(0, "Las cuotas de salud debe ser mayor a los $0")
      .max(9999999999, "Las cuotas de salud debe ser maximo de $9999999999") */
      .required("Favor de ingresar el valor de las cuotas de salud"),

    keyStat: Yup.string().required(
      "Favor de seleccionar el estado del salario"
    ),

    keySalaryType: Yup.string()
      /* .moreThan(0, "Favor de seleccionar un tipo de estructura") */
      .required("Favor de seleccionar un tipo de estructura"),
    base: Yup.string()
      /* .moreThan(0, "Favor de seleccionar un tipo de estructura") */
      .required("Favor de seleccionar un sueldo base"),
  });

  const handleSubmit = (values) => {
    return {
      ...values,
      gross:
        Number(values.base) +
        Number(values.reward) +
        Number(values.larder) +
        Number(values.clearance) +
        Number(values.fortalecimiento),
      net:
        Number(values.base) +
        Number(values.reward) +
        Number(values.larder) +
        Number(values.clearance) +
        Number(values.fortalecimiento) -
        Number(values.taxes) -
        Number(values.ssecurity),
    };
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <Formik
      initialValues={form}
      validationSchema={() => FormSchema}
      onSubmit={() => setModal(true)}
      onReset={() => history.push("/template/salarios")}
    >
      {({ errors, touched, values, setFieldValue, handleBlur }) => (
        <Form className="mt-4">
          <ModalConfirmation
            modalTitle={data ? "Editar" : "Crear"}
            modal={modal}
            setModal={setModal}
            crear={crear}
            editar={editar}
            isEdit={data ? true : false}
            values={handleSubmit(values)}
          >
            {data ? (
              <div className="d-flex justify-content-center">
                <h6>¿Está seguro de editar el registro?</h6>
              </div>
            ) : (
              <div className="d-flex justify-content-center">
                <h6>¿Desea continuar con el registro?</h6>
              </div>
            )}
          </ModalConfirmation>

          <Row>
            <Col xs="12" md="6">
              <NumberInput
                label="Nivel"
                inputName="level"
                onChangeMethod={(event) =>
                  event.target.value === "" || isNaN(event.target.value)
                    ? setFieldValue("level", 0)
                    : /* REX_NUMBER_LEVEL.test(event.target.value) && */
                      setFieldValue("level", Number(event.target.value))
                }
                onBlurMethod={handleBlur}
                value={values.level}
                isRequired
                touched={touched.level}
                errors={errors.level}
                disableSep
              />
              {/* <div className="form-group">
                <label htmlFor="level">
                  {" "}
                  Nivel<span className="text-danger">*</span>{" "}
                </label>
                <Field
                  name="level"
                  type="number"
                  className={
                    "form-control" +
                    (errors.level && touched.level ? " is-invalid" : "")
                  }
                  min={1}
                  max={999}
                  onChange={(event) =>
                    event.target.value === ""
                      ? setFieldValue("level", event.target.value)
                      : REX_NUMBER_LEVEL.test(event.target.value) &&
                        setFieldValue("level", Number(event.target.value))
                  }
                />
                <ErrorMessage
                  name="level"
                  component="div"
                  className="invalid-feedback"
                />
              </div> */}
            </Col>

            <Col xs="12" md="6">
              <div className="form-group">
                <label htmlFor="subLevel"> Subnivel </label>
                <Field
                  name="subLevel"
                  type="text"
                  className={
                    "form-control" +
                    (errors.subLevel && touched.subLevel ? " is-invalid" : "")
                  }
                  onChange={(event) =>
                    setFieldValue("subLevel", event.target.value)
                  }
                  maxLength={10}
                />
                <ErrorMessage
                  name="subLevel"
                  component="div"
                  className="invalid-feedback"
                />
              </div>
            </Col>
          </Row>

          <Row>
            <Col xs="12" md="6">
              <div className="form-group">
                <label htmlFor="rango">
                  {" "}
                  Rango<span className="text-danger">*</span>{" "}
                </label>
                <Field
                  name="rango"
                  type="text"
                  className={
                    "form-control" +
                    (errors.rango && touched.rango ? " is-invalid" : "")
                  }
                  maxLength={10}
                />
                <ErrorMessage
                  name="rango"
                  component="div"
                  className="invalid-feedback"
                />
              </div>
            </Col>

            <Col xs="12" md="6">
              <NumberInput
                label="Sueldo Base"
                inputName="base"
                onChangeMethod={(event) =>
                  /* event.target.value === ""
                    ? setFieldValue("base", event.target.value)
                    : REGEX_MONEY.test(event.target.value) && */
                  setFieldValue(
                    "base",
                    isNaN(event.target.value) ? "" : event.target.value
                  )
                }
                onBlurMethod={handleBlur}
                value={values.base}
                isRequired
                decimals={true}
                touched={touched.base}
                errors={errors.base}
                prefix={"$"}
              />
            </Col>
          </Row>

          <Row>
            <Col xs="12" md="6">
              <NumberInput
                label="Sueldo Bruto"
                inputName="gross"
                onChangeMethod={(event) =>
                  /* event.target.value === ""
                    ? setFieldValue("gross", event.target.value)
                    : REGEX_MONEY.test(event.target.value) && */
                  setFieldValue(
                    "gross",
                    isNaN(event.target.value) ? "" : event.target.value
                  )
                }
                onBlurMethod={handleBlur}
                value={
                  Number(values.base) +
                  Number(values.reward) +
                  Number(values.larder) +
                  Number(values.clearance) +
                  Number(values.fortalecimiento)
                }
                isRequired
                touched={touched.gross}
                errors={errors.gross}
                prefix={"$"}
                isDisabled
              />
            </Col>

            <Col xs="12" md="6">
              <NumberInput
                label="Sueldo Neto"
                inputName="net"
                onChangeMethod={(event) =>
                  /* event.target.value === ""
                    ? setFieldValue("net", event.target.value)
                    : REGEX_MONEY.test(event.target.value) && */
                  setFieldValue(
                    "net",
                    isNaN(event.target.value) ? "" : event.target.value
                  )
                }
                onBlurMethod={handleBlur}
                value={
                  Number(values.base) +
                  Number(values.reward) +
                  Number(values.larder) +
                  Number(values.clearance) +
                  Number(values.fortalecimiento) -
                  (Number(values.taxes) + Number(values.ssecurity))
                }
                isRequired
                touched={touched.net}
                errors={errors.net}
                prefix={"$"}
                isDisabled
              />
            </Col>
          </Row>

          <Row>
            <Col xs="12" md="6">
              <NumberInput
                label="Gratificacion"
                inputName="reward"
                decimals={true}
                onChangeMethod={(event) =>
                  /* event.target.value === ""
                    ? setFieldValue("reward", event.target.value)
                    : REGEX_MONEY.test(event.target.value) && */
                  setFieldValue(
                    "reward",
                    isNaN(event.target.value) ? "" : event.target.value
                  )
                }
                onBlurMethod={handleBlur}
                value={values.reward}
                isRequired
                touched={touched.reward}
                errors={errors.reward}
                prefix={"$"}
              />
              {/* <div className="form-group">
                <label htmlFor="reward">
                  Gratificacion<span className="text-danger">*</span>
                </label>
                <Field
                  name="reward"
                  type="number"
                  className={
                    "form-control" +
                    (errors.reward && touched.reward ? " is-invalid" : "")
                  }
                  onChange={(event) =>
                    event.target.value === ""
                      ? setFieldValue("reward", event.target.value)
                      : REGEX_MONEY.test(event.target.value) &&
                        setFieldValue("reward", Number(event.target.value))
                  }
                  // onChange = {event => REGEX_MONEY.test(event.target.value) && (setFieldValue('reward',Number(event.target.value)))}
                />
                <ErrorMessage
                  name="reward"
                  component="div"
                  className="invalid-feedback"
                />
              </div> */}
            </Col>

            <Col xs="12" md="6">
              <NumberInput
                label="Despensa"
                inputName="larder"
                decimals={true}
                onChangeMethod={(event) =>
                  /* event.target.value === ""
                    ? setFieldValue("larder", event.target.value)
                    : REGEX_MONEY.test(event.target.value) && */
                  setFieldValue(
                    "larder",
                    isNaN(event.target.value) ? "" : event.target.value
                  )
                }
                onBlurMethod={handleBlur}
                value={values.larder}
                isRequired
                touched={touched.larder}
                errors={errors.larder}
                prefix={"$"}
              />
              {/* <div className="form-group">
                <label htmlFor="larder">
                  Despensa<span className="text-danger">*</span>
                </label>
                <Field
                  name="larder"
                  type="number"
                  className={
                    "form-control" +
                    (errors.larder && touched.larder ? " is-invalid" : "")
                  }
                  onChange={(event) =>
                    event.target.value === ""
                      ? setFieldValue("larder", event.target.value)
                      : REGEX_MONEY.test(event.target.value) &&
                        setFieldValue("larder", Number(event.target.value))
                  }
                  // onChange = {event => REGEX_MONEY.test(event.target.value) && (setFieldValue('larder',Number(event.target.value)))}
                />
                <ErrorMessage
                  name="larder"
                  component="div"
                  className="invalid-feedback"
                />
              </div> */}
            </Col>
          </Row>

          <Row>
            <Col xs="12" md="6">
              <NumberInput
                label="Compensación"
                inputName="clearance"
                decimals={true}
                onChangeMethod={(event) =>
                  /* event.target.value === ""
                    ? setFieldValue("clearance", event.target.value)
                    : REGEX_MONEY.test(event.target.value) && */
                  setFieldValue(
                    "clearance",
                    isNaN(event.target.value) ? "" : event.target.value
                  )
                }
                onBlurMethod={handleBlur}
                value={values.clearance}
                isRequired
                touched={touched.clearance}
                errors={errors.clearance}
                prefix={"$"}
              />
              {/* <div className="form-group">
                <label htmlFor="clearance">
                  Compensación<span className="text-danger">*</span>
                </label>
                <Field
                  name="clearance"
                  type="number"
                  className={
                    "form-control" +
                    (errors.clearance && touched.clearance ? " is-invalid" : "")
                  }
                  onChange={(event) =>
                    event.target.value === ""
                      ? setFieldValue("clearance", event.target.value)
                      : REGEX_MONEY.test(event.target.value) &&
                        setFieldValue("clearance", Number(event.target.value))
                  }
                  // onChange = {event => REGEX_MONEY.test(event.target.value) && (setFieldValue('clearance',Number(event.target.value)))}
                />
                <ErrorMessage
                  name="clearance"
                  component="div"
                  className="invalid-feedback"
                />
              </div> */}
            </Col>

            <Col xs="12" md="6">
              <NumberInput
                label="Fortalecimiento"
                inputName="fortalecimiento"
                decimals={true}
                onChangeMethod={(event) =>
                  /* event.target.value === ""
                    ? setFieldValue("fortalecimiento", event.target.value)
                    : REGEX_MONEY.test(event.target.value) && */
                  setFieldValue(
                    "fortalecimiento",
                    isNaN(event.target.value) ? "" : event.target.value
                  )
                }
                onBlurMethod={handleBlur}
                value={values.fortalecimiento}
                isRequired
                touched={touched.fortalecimiento}
                errors={errors.fortalecimiento}
                prefix={"$"}
              />
              {/* <div className="form-group">
                <label htmlFor="fortalecimiento">
                  Fortalecimiento<span className="text-danger">*</span>
                </label>
                <Field
                  name="fortalecimiento"
                  type="number"
                  className={
                    "form-control" +
                    (errors.fortalecimiento && touched.fortalecimiento
                      ? " is-invalid"
                      : "")
                  }
                  onChange={(event) =>
                    event.target.value === ""
                      ? setFieldValue("fortalecimiento", event.target.value)
                      : REGEX_MONEY.test(event.target.value) &&
                        setFieldValue(
                          "fortalecimiento",
                          Number(event.target.value)
                        )
                  }
                  // onChange = {event => REGEX_MONEY.test(event.target.value) && (setFieldValue('clearance',Number(event.target.value)))}
                />
                <ErrorMessage
                  name="clearance"
                  component="div"
                  className="invalid-feedback"
                />
              </div> */}
            </Col>
          </Row>

          <Row>
            <Col xs="12" md="6">
              <NumberInput
                label="Viaticos"
                inputName="travels"
                decimals={true}
                onChangeMethod={(event) =>
                  /* event.target.value === ""
                    ? setFieldValue("travels", event.target.value)
                    : REGEX_MONEY.test(event.target.value) && */
                  setFieldValue(
                    "travels",
                    isNaN(event.target.value) ? "" : event.target.value
                  )
                }
                onBlurMethod={handleBlur}
                value={values.travels}
                isRequired
                touched={touched.travels}
                errors={errors.travels}
                prefix={"$"}
              />
              {/* <div className="form-group">
                <label htmlFor="travels">
                  Viaticos<span className="text-danger">*</span>
                </label>
                <Field
                  name="travels"
                  type="number"
                  className={
                    "form-control" +
                    (errors.travels && touched.travels ? " is-invalid" : "")
                  }
                  onChange={(event) =>
                    event.target.value === ""
                      ? setFieldValue("travels", event.target.value)
                      : REGEX_MONEY.test(event.target.value) &&
                        setFieldValue("travels", Number(event.target.value))
                  }
                />
                <ErrorMessage
                  name="travels"
                  component="div"
                  className="invalid-feedback"
                />
              </div> */}
            </Col>
            <Col xs="12" md="6">
              <NumberInput
                label="Impuestos"
                inputName="taxes"
                decimals={true}
                onChangeMethod={(event) =>
                  /* event.target.value === ""
                    ? setFieldValue("taxes", event.target.value)
                    : REGEX_MONEY.test(event.target.value) && */
                  setFieldValue(
                    "taxes",
                    isNaN(event.target.value) ? "" : event.target.value
                  )
                }
                onBlurMethod={handleBlur}
                value={values.taxes}
                isRequired
                touched={touched.taxes}
                errors={errors.taxes}
                prefix={"$"}
              />
              {/* <div className="form-group">
                <label htmlFor="taxes">
                  Impuestos<span className="text-danger">*</span>
                </label>
                <Field
                  name="taxes"
                  type="number"
                  className={
                    "form-control" +
                    (errors.taxes && touched.taxes ? " is-invalid" : "")
                  }
                  onChange={(event) =>
                    event.target.value === ""
                      ? setFieldValue("taxes", event.target.value)
                      : REGEX_MONEY.test(event.target.value) &&
                        setFieldValue("taxes", Number(event.target.value))
                  }
                  // onChange = {event => REGEX_MONEY.test(event.target.value) && (setFieldValue('taxes',Number(event.target.value)))}
                />
                <ErrorMessage
                  name="taxes"
                  component="div"
                  className="invalid-feedback"
                />
              </div> */}
            </Col>
          </Row>

          <Row>
            <Col xs="12" md="6">
              <NumberInput
                label="Cuotas de Salud"
                inputName="ssecurity"
                decimals={true}
                onChangeMethod={(event) =>
                  /* event.target.value === ""
                    ? setFieldValue("ssecurity", event.target.value)
                    : REGEX_MONEY.test(event.target.value) && */
                  setFieldValue(
                    "ssecurity",
                    isNaN(event.target.value) ? "" : event.target.value
                  )
                }
                onBlurMethod={handleBlur}
                value={values.ssecurity}
                isRequired
                touched={touched.ssecurity}
                errors={errors.ssecurity}
                prefix={"$"}
              />
              {/* <div className="form-group">
                <label htmlFor="ssecurity">
                  Cuotas de Salud<span className="text-danger">*</span>
                </label>
                <Field
                  name="ssecurity"
                  type="number"
                  className={
                    "form-control" +
                    (errors.ssecurity && touched.ssecurity ? " is-invalid" : "")
                  }
                  onChange={(event) =>
                    event.target.value === ""
                      ? setFieldValue("ssecurity", event.target.value)
                      : REGEX_MONEY.test(event.target.value) &&
                        setFieldValue("ssecurity", Number(event.target.value))
                  }
                />
                <ErrorMessage
                  name="ssecurity"
                  component="div"
                  className="invalid-feedback"
                />
              </div> */}
            </Col>

            <Col xs="12" md="6">
              <div className="form-group">
                <SelectTypeHeadSingle
                  label="Estado"
                  isRequired={true}
                  inputName="keyStat"
                  optionsArray={allStatus}
                  defaultOption="Seleccione un estado de salario"
                  onChangeMethod={(event) => {
                    const { name, value } = event.target;
                    setFieldValue(name, value);
                  }}
                  onBlur={handleBlur}
                  isDisabled={false}
                  value={values.keyStat}
                  touched={touched.keyStat}
                  errors={errors.keyStat}
                  optionValue="value"
                  optionName="label"
                />
              </div>
            </Col>

            <Col xs="12" md="6">
              <SelectTypeHeadSingle
                label="Tipo de estructura"
                isRequired={true}
                inputName="keySalaryType"
                optionsArray={salariesTypes}
                defaultOption="Seleccione un tipo de estructura"
                onChangeMethod={(event) => {
                  const { name, value } = event.target;
                  setFieldValue(name, value);
                }}
                onBlur={handleBlur}
                isDisabled={false}
                value={values.keySalaryType}
                touched={touched.keySalaryType}
                errors={errors.keySalaryType}
                optionValue="keySalaryType"
                optionName="nameSalaryType"
              />
            </Col>
          </Row>

          <div className="d-flex justify-content-between mt-5">
            <Button color="danger" type="reset">
              {" "}
              Cancelar{" "}
            </Button>
            <Button color="success" type="submit">
              {" "}
              Guardar{" "}
            </Button>
          </div>

          <FormikData
            info={data}
            status={allStatus}
            salarieType={salariesTypes}
          />
        </Form>
      )}
    </Formik>
  );
};

const FormikData = ({ info, status, salarieType }) => {
  const { setFieldValue } = useFormikContext();
  useEffect(() => {
    if (info !== undefined) {
      if (info.length > 0) {
        handleSelects(status, "keyStat", {
          firstCondition: "label",
          secondCondition: info[2],
        });
        const valueLevel = info[1].split(" - ");
        setFieldValue("idSalary", info[0]);
        setFieldValue("level", valueLevel[0]);
        setFieldValue("rango", valueLevel[1]);
        setFieldValue("base", info[3]);
        setFieldValue("gross", info[4]);
        setFieldValue("net", info[5]);
        setFieldValue("reward", info[6]);
        setFieldValue("larder", info[7]);
        setFieldValue("clearance", info[8]);
        setFieldValue("fortalecimiento", info[9]);
        setFieldValue("travels", info[10]);
        setFieldValue("taxes", info[11]);
        setFieldValue("ssecurity", info[12]);
        setFieldValue("subLevel", info[14]);
        setFieldValue("keySalaryType", info[15]);
      }
    }
  }, [info]);

  useEffect(() => {
    if (salarieType.length > 0 && info) {
      handleSelects(salarieType, "keySalaryType", {
        firstCondition: "nameSalaryType",
        secondCondition: info[13],
      });
    }
  }, [salarieType]);

  const handleSelects = (array, value, { firstCondition, secondCondition }) => {
    const result = array.filter(
      (it) => it[firstCondition] === secondCondition
    )[0];
    setFieldValue(
      value,
      value === "keyStat" ? result?.value||"" : result?.keySalaryType||""
    );
  };
  return null;
};

export default FormSalarios;

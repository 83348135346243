import * as Yup from "yup"
import { useEffect, useState } from "react";
import { useFormik } from "formik";
import { Button, Col, Modal, ModalBody, ModalHeader, Row, Table } from "reactstrap";
import { toast } from "react-toastify";


import Widget from "../../../../../components/Widget/Widget";
import { SelectTypeHeadSingle } from "../../../../../components/GenericInputsFormik";
import { peticionesReceiver } from "../../../../../helpers/peticionesReceiver";
import Notification from "../../../../../components/Notification/Notification";
import ModalConfirmation from "../../../../../components/Modal/ModalConfirmation";
import InputWithModal from "../../../../../components/GenericInputsFormik/InputWithModal";
import Form from "../../../../patrimonio/bienes-muebles/Form";
import { NumberInput } from "../../../../../components/GenericInputsFormik/NumberInput";
import Datatable from "../../../../../components/Datatable/Datatable";
import EditarCantidad from "./EditarCantidad";
import ModalComponent from "../../../../../components/Modal/ModalDelete";
import AltaServicios from "../Servicios/AltaServicios";
import AnexosTecnicos from "./AnexosTecnicos";
import AsignarSolicitante from "./AsignarSolicitante";
import ModalReports from "../../../../../components/Modal/ModalReports";
import exportSolicitudAdquisicion from "../../pdf/solicitudAdquisicion";
import { pdf } from "@react-pdf/renderer";
import { AddFile } from "../../../../patrimonio/bienes-inmuebles/functions";

import { GetPermisos } from "../../../../../libs/permissions/hook";
import FullFormLoader from "../../../../../components/Loader/FullFormLoader";
import Loader from "../../../../../components/Loader/Loader";

export default function EditarSolicitud() {
    const [permisos, setPermisos] = useState("")
    GetPermisos(setPermisos);

    const API = peticionesReceiver();
    const [tab, setTab] = useState(0);

    const [modal, setModal] = useState(false)
    const [modalAdd, setModalAdd] = useState(false)
    const [modalBien, setModalBien] = useState(false)
    const [modalService, setModalService] = useState(false)
    const [modalReports, setModalReports] = useState(false)

    const handleOpenModalBien = () => setModalBien(true);
    const handleCloseModalBien = () => setModalBien(false);
    const handleOpenModalService = () => setModalService(true);
    const handleCloseModalService = () => setModalService(false);

    const [loader, setLoader] = useState(false)

    const [report, setReport] = useState(null)

    const [quote, setQuote] = useState(null)

    const [idCot, setIdCot] = useState("")

    useEffect(() => {
        if (permisos !== "" && permisos.INS === "0") {
            returnSol()
        }
    }, [permisos])

    const types = [
        { value: "0", label: "Bienes" },
        { value: "1", label: "Servicios" },
    ]

    const cabecerasFurniture = [
        "#",
        "Bien mueble",
    ];

    const cabecerasServicios = [
        "#",
        "Servicio"
    ];

    const filtroFurniture = [true, true];

    const filtroServicio = [true, true];

    const paramsFurniture = {
        action: "datatable",
        table: "furnitures",
        rows: "idFurniture,concat(nameFurniture,', MODELO: ',model,', MARCA: ',Brand) as furniture",
        conditions: "enabled = 1",
        page: 0,
        records: 5,
        search: "",
        order: "",
    };

    const paramsServicio = {
        action: "datatable",
        table: "services s inner join servicetimes st using(keyServiceTime)",
        rows: "s.idService,concat(s.nameService,' - PERIODO: ',st.nameServiceTime) as service",
        conditions: "s.enabled = 1",
        page: 0,
        records: 5,
        search: "",
        order: "",
    };

    const actionSelectBien = (el) => {
        setFieldValue(["idFurniture"], el[0]);
        setFieldValue(["furniture"], `${el[1]}`);
    }

    const actionSelectService = (el) => {
        setFieldValue(["idService"], el[0]);
        setFieldValue(["service"], `${el[1]}`);
    }

    /* Limpiar el mueble */
    function clearBien() {
        setFieldValue(["idFurniture"], "");
        setFieldValue(["furniture"], "");
    }

    /* Limpiar el servicio */
    function clearServicio() {
        setFieldValue(["idService"], "");
        setFieldValue(["service"], "");
    }

    const returnSol = () => {
        localStorage.removeItem("cotedit")
        window.location.href = "#/template/cotizacion"
    }

    const defaultForm = {
        idQuote: "",
        typeObject: "",
        idFurniture: "",
        furniture: "",
        idService: "",
        service: "",
        amount: ""
    }

    const FormSchema = Yup.object().shape({
        typeObject: Yup.string().required("Ingrese un tipo de opción valido"),
        furniture: Yup.string()
            .when("typeObject", {
                is: "0",
                then: Yup.string().required("Seleccione un bien valido")
            }),
        service: Yup.string()
            .when("typeObject", {
                is: "1",
                then: Yup.string().required("Seleccione un servicio valido")
            }),
        amount: Yup.number().moreThan(0, "Mayor a 0").required("Ingrese una cantidad valida")
    })

    const onChange = (e) => {
        const { name, value } = e.target
        setFieldValue([name], value);
    }

    const { handleSubmit, values, handleBlur, errors, touched, setFieldValue, resetForm } =
        useFormik({
            initialValues: defaultForm,
            onSubmit: () => setModalAdd(true),
            validationSchema: FormSchema,
        });

    useEffect(() => {
        const idCot = localStorage.getItem("cotedit")
        if (idCot === null) {
            returnSol()
        } else {
            setIdCot(idCot)
            setFieldValue("idQuote", idCot);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    function getParams(values) {
        const rows = getSol(values);
        if (rows !== null) {
            return {
                table: "quotesdetail",
                action: "insert",
                rows,
                validate: rows.idFurniture !== undefined ? [["idQuote", "idFurniture"]] : [["idQuote", "idService"]]
            }
        }
        return null;
    }

    const getSol = (rows) => {
        if (rows.typeObject === "0") {
            return { idQuote: rows.idQuote, idFurniture: rows.idFurniture, amount: rows.amount }
        } else if (rows.typeObject === "1") {
            return { idQuote: rows.idQuote, idService: rows.idService, amount: rows.amount }
        } else {
            return null;
        }
    }

    const OnAppend = async (rows) => {
        const API = peticionesReceiver();
        try {
            const params = getParams(rows);
            if (params !== null) {
                const res = await API.peticion(params)
                if (res.status === 200 && res.data.code === "200") {
                    toast(<Notification type={"agrega_exito"} backMessage={res.data.message} withIcon />);
                    setRefreshTableElmt(true)
                    resetForm()
                    setFieldValue("idQuote", idCot)
                } else {
                    toast(<Notification type={"agrega_error"} backMessage={res.data.message} withIcon />);
                }
            } else {
                resetForm()
                setFieldValue("idQuote", idCot)
                toast(<Notification type={"agrega_error"} withIcon />);
            }
        } catch (err) {
            console.log(err)
            toast(<Notification type={"agrega_error"} withIcon />);
        }
    }

    const OnSend = async (row) => {
        if (row !== "") {
            if (report[0].numberQuote === "") {
                const params = {
                    action: "saveQuote",
                    idQuote: row
                }
                try {
                    const res = await API.peticionEndPoint(params, "app/facades/quotes/quotesF.php");
                    if (res.status === 200 && res.data.code === "200") {
                        toast(<Notification type={"modifica_exito"} backMessage={res.data.message} />);
                        const report = await getQuote();
                        if (report !== null) {
                            UpploadFile(report)
                        }
                    } else {
                        toast(<Notification type={"modifica_error"} backMessage={res.data.message} />);
                    }
                } catch (err) {
                    toast(<Notification type={"modifica_error"} withIcon />);
                }
            } else {
                const params = {
                    action: "update",
                    table: "quotes",
                    rows: { keyStat: "102" },
                    condition: { idQuote: row },
                }
                try {
                    const res = await API.peticion(params);
                    if (res.status === 200 && res.data.code === "200") {
                        toast(<Notification type={"modifica_exito"} backMessage={res.data.message} />);
                        const report = await getQuote();
                        if (report !== null) {
                            UpploadFile(report)
                        }
                    } else {
                        toast(<Notification type={"modifica_error"} backMessage={res.data.message} />);
                    }
                } catch (err) {
                    toast(<Notification type={"modifica_error"} withIcon />);
                }
            }
        }
    }

    const UpploadFile = async (report) => {
        try {
            setLoader(true)
            const blob = await pdf(exportSolicitudAdquisicion(report).report).toBlob();
            const file = new File([blob], "Diseno_Solicitud.pdf")
            const data = {
                reference: idCot,
                keyFileType: "42",
                nameFile: "",
                file
            }
            AddFile(data, () => { setLoader(false); returnSol() }, "saveFileReplace");
        } catch (err) {
            console.log(err);
        }
    }

    const componentSend = () => {
        return (
            <div className="text-center mb-3">
                <Button color="warning" onClick={() => setModal(true)}>Enviar solicitud</Button>
            </div>
        )
    }

    //TABLES

    const [refreshTableElmt, setRefreshTableElmt] = useState(false)

    const headersTableElmts = [
        "Estatus",
        "Tipo",
        "Cantidad",
        "Unidad de medición",
        "Costo",
        "Costo total",
        "Nombre del articulo",
        "Descripción del servicio",
        "Periodo",
        "Observaciones",
        "Acciones",
    ]

    const columnsTableElmts = [
        { data: "" },
        { data: "tipo" },
        {
            data: null,
            render: function (row) {
                return (
                    <EditarCantidad
                        amount={row.amount}
                        idDetail={row.idDetail}
                        setRefresh={setRefreshTableElmt}
                    />
                )
            }
        },
        { data: "nameUnit" },
        {
            data: "unitCost",
            render: function (row) {
                return row ? row : "0.00$"
            }
        },
        {
            data: "total",
            render: function (row) {
                return row ? row : "0.00$"
            }
        },
        { data: "nameFurniture" },
        { data: "nameService" },
        { data: "nameServiceTime" },
        { data: "notes" },
        {
            data: "idDetail",
            render: function (row) {
                return (
                    <ModalComponent
                        modalTitle="Eliminar"
                        id={row}
                        table={["quotesdetail"]}
                        nameColumn={["idDetail"]}
                        elimina={() => OnDelete(row)}
                    >
                        <h6>
                            ¿Seguro de eliminar el registro?
                        </h6>
                        No podrá recuperar la información después de ser eliminada
                    </ModalComponent>
                )
            }
        },
    ]

    async function OnDelete(row) {
        const API = peticionesReceiver();
        const params = {
            action: "delete",
            table: "quotesdetail",
            condition: {
                idDetail: row
            },
        };
        try {
            const res = await API.peticion(params)
            if (res.status === 200 && res.data.code === "200") {
                toast(<Notification type={"elimina_exito"} backMessage={res.data.message} withIcon />);
                setRefreshTableElmt(true)
            } else {
                toast(<Notification type={"elimina_error"} backMessage={res.data.message} withIcon />);
            }
        } catch (err) {
            toast(<Notification type={"elimina_error"} withIcon />);
        }
    }

    const columnDefsTableElmt = [
        { targets: [1, 2], className: "text-center" },
        { targets: [4, 5], className: "text-right" },
        { targets: [0, 9, 10], orderable: false }
    ]

    const getParamsTableElmt = (order, records, page, search) => {
        const params = {
            action: "datatable",
            table: "quotesdetail qt left join services sv on (qt.idService=sv.idService) left join furnitures f on (f.idFurniture=qt.idFurniture) left join messureunits mu on (f.keyUnit=mu.keyUnit) left join servicetimes sti on (sv.keyServiceTime=sti.keyServiceTime)",
            rows: "qt.idDetail,if(isnull(sv.nameService),'BIENES','SERVICIOS') as tipo,sv.nameService,f.nameFurniture,qt.amount,mu.nameUnit,qt.unitCost,qt.taxies,qt.total,qt.notes,sti.nameServiceTime",
            conditions: `qt.enabled=1 AND qt.idQuote=${idCot}`,
            order,
            records: records.toString(),
            page: (page * records).toString(),
            search
        }
        return params
    }

    async function getDataTableElmts(order, records, page, search) {
        try {
            const res = await API.peticion(getParamsTableElmt(order, records, page, search));
            if (res.status === 200 && res.data.code === "200") {
                return res.data;
            } else {
                // toast(<Notification type={"consultar_error"} backMessage={res.data.message} />);
                return null;
            }
        } catch (err) {
            toast(<Notification type={"consultar_servidor_error"} withIcon />);
            return null
        }
    }

    const getWithoutSB = (boolean) => {
        if (boolean) {
            const params = {
                action: "multiselect",
                table: "quotes q inner join status stt on (stt.keyStat=q.keyStat) left join staff stf on (q.idStaffRequire = stf.idStaff)",
                rows: "q.object,stt.nameStat,CONCAT(stf.name,' ',stf.pName,' ',stf.mName) as fullname,q.notes as qnotes",
                conditions: `q.enabled=1 AND q.idQuote=${idCot}`
            }
            return params
        } else {
            const params = {
                action: "multiselect",
                table: "quotes q inner join quotesdetail qt on (q.idQuote=qt.idQuote) left join services sv on (qt.idService=sv.idService) left join furnitures f on (f.idFurniture=qt.idFurniture) left join messureunits mu on (f.keyUnit=mu.keyUnit) left join servicetimes sti on (sv.keyServiceTime=sti.keyServiceTime) inner join status stt on (stt.keyStat=q.keyStat) left join staff stf on (stf.idStaff = q.idStaffRequire)",
                rows: "q.idQuote,q.idStaffRequire,q.numberQuote,q.yearQuote,q.dateSend,q.object,if(isnull(sv.nameService),f.nameFurniture,sv.nameService) as descripcion,if(isnull(mu.nameUnit),sti.nameServiceTime,mu.nameUnit) as unit,qt.amount,qt.unitCost,qt.taxies,qt.total,qt.notes,stt.nameStat,CONCAT(stf.name,' ',stf.pName,' ',stf.mName) as fullname,q.notes as qnotes",
                conditions: `q.enabled=1 AND q.idQuote=${idCot} AND qt.enabled=1`
            }
            return params
        }
    }

    const getQuote = async (wt = false) => {
        try {
            const res = await API.peticion(getWithoutSB(wt));
            if (res.status === 200 && res.data.code === "200") {
                const report = res.data.data;
                if (!wt) {
                    if (report.length > 0) {
                        if (report[0].idStaffRequire !== "") {
                            return report;
                        } else {
                            toast(<Notification type={"consultar_error"} backMessage={"No se ha seleccionado el solicitante"} />);
                            return null;
                        }
                    } else {
                        toast(<Notification type={"consultar_error"} backMessage={"No se tienen registrados bienes o servicios"} />);
                        return null
                    }
                } else {
                    return report;   
                }
            } else {
                toast(<Notification type={"consultar_error"} backMessage={res.data.message} />);
            }
        } catch (err) {
            toast(<Notification type={"consultar_servidor_error"} withIcon />);
        }
    }

    async function generarSolicitud() {
        const quote = await getQuote()
        if (quote !== null) {
            setReport(quote)
            setModalReports(true)
        }
    }

    async function setData() {
        const quote = await getQuote(true);
        setQuote(quote[0]);
    }

    useEffect(() => {
        if (idCot !== null && idCot !== "") {
            setData()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [idCot])

    return (
        <>
            <Widget>
                <h3 className="p-3">Editar solicitud de cotizacion</h3>
                {quote ? (
                    <Table hover>
                        <thead>
                            <tr>
                                <th>Estatus</th>
                                <th>Justificación para la adquisición del bien o contratación del servicio</th>
                                <th>Observaciones</th>
                                <th>Solicitante</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{quote.nameStat}</td>
                                <td>{quote.object}</td>
                                <td>{quote.qnotes}</td>
                                <td>{quote.fullname}</td>
                            </tr>
                        </tbody>
                    </Table>
                ) : <div className="my-2"><Loader /></div>}

                <div className="accordion" id="accordionDatosInmueble">
                    <div className="card">
                        <div className={`card-header bg-secondary p-0 ${tab === 0 ? "rounded-top" : "rounded"}`} id="datosGen">
                            <h2 className="mb-0">
                                <button className={`btn btn-link btn-block text-left text-white ${tab === 0 ? "collapsed" : ""}`} style={{ fontSize: "12pt" }} type="button" onClick={() => tab === 0 ? setTab(-1) : setTab(0)}>
                                    Registro de bienes y/o servicios
                                </button>
                            </h2>
                        </div>
                        <div id="collapseDatosG" className={`collapse ${tab === 0 ? "show" : ""}`} aria-labelledby="datosGen" data-parent="#accordionDatosInmueble">
                            <div className="card-body">
                                <form onSubmit={handleSubmit} className="px-3">
                                    <Row>
                                        <Col>
                                            <SelectTypeHeadSingle
                                                label="Tipo de requerimiento a cotizar"
                                                inputName="typeObject"
                                                onChangeMethod={onChange}
                                                onBlurMethod={handleBlur}
                                                value={values.typeObject}
                                                isRequired
                                                touched={touched.typeObject}
                                                errors={errors.typeObject}
                                                optionsArray={types}
                                                optionValue={"value"}
                                                optionName={"label"}
                                            />
                                        </Col>
                                    </Row>
                                    {values.typeObject === "0" ? (
                                        <Row className="align-items-end">
                                            <Col xs={12} md={8}>
                                                <InputWithModal
                                                    cabeceras={cabecerasFurniture}
                                                    error={errors.furniture}
                                                    filtro={filtroFurniture}
                                                    handleBlur={handleBlur}
                                                    onChange={onChange}
                                                    actionSelect={actionSelectBien}
                                                    onClear={clearBien}
                                                    parametros={paramsFurniture}
                                                    touched={touched.furniture}
                                                    value={values.furniture}
                                                    label="Bienes"
                                                    name={"furniture"}
                                                    title={"Agregar bien"}
                                                />
                                            </Col>
                                            <Col xs={12} md={4}>
                                                <Button className="mb-3" color="add" onClick={handleOpenModalBien}>
                                                    Dar de alta nuevo bien
                                                </Button>
                                            </Col>
                                        </Row>
                                    ) : values.typeObject === "1" ? (
                                        <Row className="align-items-end">
                                            <Col xs={12} md={8}>
                                                <InputWithModal
                                                    cabeceras={cabecerasServicios}
                                                    error={errors.service}
                                                    filtro={filtroServicio}
                                                    handleBlur={handleBlur}
                                                    onChange={onChange}
                                                    actionSelect={actionSelectService}
                                                    onClear={clearServicio}
                                                    parametros={paramsServicio}
                                                    touched={touched.service}
                                                    value={values.service}
                                                    label="Servicios"
                                                    name={"service"}
                                                    title={"Agregar Servicio"}
                                                />
                                            </Col>
                                            <Col xs={12} md={4}>
                                                <Button className="mb-3" color="add" onClick={handleOpenModalService}>
                                                    Dar de alta nuevo servicio
                                                </Button>
                                            </Col>
                                        </Row>
                                    ) : null}
                                    <Row>
                                        <Col>
                                            <NumberInput
                                                value={values.amount}
                                                errors={errors.amount}
                                                inputName="amount"
                                                isRequired
                                                label="Cantidad a solicitar"
                                                onBlurMethod={handleBlur}
                                                onChangeMethod={onChange}
                                                touched={touched.amount}
                                            />
                                        </Col>
                                    </Row>
                                    <div className="text-center">
                                        <Button color="add" type="submit">Agregar bien o servicio</Button>
                                    </div>
                                </form>
                                <h3 className="m-3">Resumen de solicitud</h3>
                                {idCot !== "" && (
                                    <Datatable
                                        headers={headersTableElmts}
                                        columns={columnsTableElmts}
                                        control="back"
                                        petition={getDataTableElmts}
                                        order={{ col: 1, opt: "asc" }}
                                        columnDefs={columnDefsTableElmt}
                                        stateRefresh={[refreshTableElmt, setRefreshTableElmt]}
                                    />
                                )}

                            </div>
                        </div>
                        <div className="card">
                            <div className={`card-header bg-secondary p-0 ${tab === 1 ? "rounded-top" : "rounded"}`} id="caract">
                                <h2 className="mb-0">
                                    <button className={`btn btn-link btn-block text-left text-white ${tab === 1 ? "collapsed" : ""}`} style={{ fontSize: "12pt" }} type="button" onClick={() => tab === 1 ? setTab(-1) : setTab(1)}>
                                        Anexos tecnicos
                                    </button>
                                </h2>
                            </div>
                            <div id="collapseCaract" className={`collapse ${tab === 1 ? "show" : ""}`} aria-labelledby="caract" data-parent="#accordionDatosInmueble">
                                <div className="card-body">
                                    {idCot !== "" && (
                                        <AnexosTecnicos idCot={idCot} />
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="card">
                            <div className={`card-header bg-secondary p-0 ${tab === 2 ? "rounded-top" : "rounded"}`} id="asig">
                                <h2 className="mb-0">
                                    <button className={`btn btn-link btn-block text-left text-white ${tab === 2 ? "collapsed" : ""}`} style={{ fontSize: "12pt" }} type="button" onClick={() => tab === 2 ? setTab(-1) : setTab(2)}>
                                        Asignar solicitante
                                    </button>
                                </h2>
                            </div>
                            <div id="collapseAsig" className={`collapse ${tab === 2 ? "show" : ""}`} aria-labelledby="asig" data-parent="#accordionAsig">
                                <div className="card-body">
                                    {idCot !== "" && (
                                        <AsignarSolicitante idQuote={idCot} />
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="text-center p-2">
                    <Button color="danger mx-1" onClick={returnSol}>Regresar</Button>
                    <Button color="add mx-1" onClick={generarSolicitud}>Generar solicitud</Button>
                </div>
            </Widget>
            <ModalConfirmation
                modalTitle={"Añadir bienes y/o servicios"}
                modal={modalAdd}
                crear={(rows) => OnAppend(rows)}
                setModal={setModalAdd}
                values={values}
            >
                <div className="d-flex justify-content-center">
                    <h6>¿Desea continuar con el registro de bienes y/o servicios?</h6>
                </div>
            </ModalConfirmation>
            <ModalConfirmation
                modalTitle={"Crear solicitud"}
                modal={modal}
                crear={(rows) => OnSend(rows)}
                setModal={setModal}
                values={idCot}
            >
                <div className="d-flex justify-content-center">
                    <h6>¿Desea continuar con el envio?</h6>
                </div>
            </ModalConfirmation>
            <Modal isOpen={modalBien} toggle={handleCloseModalBien} fullscreen="lg" size="xl" backdrop="static" >
                <ModalHeader toggle={handleCloseModalBien}>Agregar nuevo bien mueble</ModalHeader>
                <ModalBody>
                    <Form OnSuccess={handleCloseModalBien} OnCancel={handleCloseModalBien} />
                </ModalBody>
            </Modal>
            <Modal isOpen={modalService} toggle={handleCloseModalService} fullscreen="lg" size="xl" backdrop="static" >
                <ModalHeader toggle={handleCloseModalService}>Agregar nuevo servicio</ModalHeader>
                <ModalBody>
                    <AltaServicios OnSuccess={handleCloseModalService} OnCancel={handleCloseModalService} />
                </ModalBody>
            </Modal>
            {report !== null && (
                <ModalReports
                    modal={modalReports}
                    setModal={setModalReports}
                    title={"Solicitud de adquisición"}
                    backdrop={"static"}
                    keyboard={false}
                    report={report}
                    generatePdfMethod={{
                        method: exportSolicitudAdquisicion,
                        type: "general",
                    }}
                    records={1000}
                    component={componentSend}
                />
            )}
            <FullFormLoader show={loader} message="Espere un momento" />

        </>
    )
}
import React, { useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Button, Row, Col } from "reactstrap";
import ModalConfirmation from "../../../components/Modal/ModalConfirmation";

import { TextInput } from "../../../components/GenericInputsFormik/index";

import { useEffect } from "react";
import { peticionesReceiver } from "../../../helpers/peticionesReceiver";
import { toast } from "react-toastify";
import Notification from "../../../components/Notification/Notification";
import Loader from "../../../components/Loader/Loader";

const FormInstituciones = ({
  permissions,
  setRefreshP,
  institution,
  setPerception,
  setCollapse,
}) => {
  const [modalConfirmation, setModalConfirmation] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const API = peticionesReceiver();

  const FormSchema = Yup.object().shape({
    nameInstitution: Yup.string().required(
      "Ingrese el nombre de la institución"
    ),
  });

  const {
    handleSubmit,
    values,
    errors,
    setFieldValue,
    touched,
    handleBlur,
  } = useFormik({
    initialValues: {
      idPerceptionExtra: "",
      namePerceptionExtra: "",
      idUnder: "",
      daysPay: "",
      startDate: "",
      endDate: "",
    },
    onSubmit: () => {
      setModalConfirmation(true);
    },
    validationSchema: FormSchema,
    enableReinitialize: true,
  });

  const onChange = (e) => {
    const { name, value } = e.target;
    setFieldValue(name, value);
  };

  const saveInstitution = async () => {
    const data =
      !isEdit === true
        ? {
            action: "insert",
            table: "institutions",
            rows: {
              nameInstitution: values.nameInstitution,
            },
          }
        : {
            action: "update",
            table: "institutions",
            rows: {
              nameInstitution: values.nameInstitution,
            },
            condition: {
              keyInstitution: values.keyInstitution,
            },
          };
    const res = await API.peticion(data);
    if (res.status === 200 && res.data.status === "success") {
      toast(
        <Notification
          type={"agrega_exito"}
          backMessage={res.data.message}
          withIcon
        />
      );
      setCollapse("home");
      setPerception(res.data.data[0]);
      setFieldValue("idPerceptionExtra", res.data.data[0].idPerceptionExtra);
      setRefreshP(true);
    } else {
      toast(
        <Notification
          type={"agrega_error"}
          backMessage={res.data.message}
          withIcon
        />
      );
    }
  };

  useEffect(() => {
    if (institution && institution !== "") {
      setDataForEdit();
    }
  }, []);

  const setDataForEdit = () => {
    setIsEdit(true);
    setFieldValue("keyInstitution", institution.keyInstitution);
    setFieldValue("nameInstitution", institution.nameInstitution);
  };

  return (
    <div>
      {true ? (
        <>
          <form onSubmit={handleSubmit}>
            <div className="text-center">
              <h2>Nueva Institución</h2>
            </div>

            <Row className="d-flex justify-content-around ">
              <Col xs={12} lg={5}>
                <TextInput
                  label="Nombre de la institución"
                  inputType="text"
                  inputName="nameInstitution"
                  isRequired
                  onChangeMethod={onChange}
                  onBlurMethod={handleBlur}
                  value={values.nameInstitution}
                  touched={touched.nameInstitution}
                  errors={errors.nameInstitution}
                />
              </Col>
            </Row>

            <div className="d-flex justify-content-around">
              {isEdit
                ? permissions.UPD && (
                    <div>
                      <Button type="submit" color="success">
                        Editar
                      </Button>
                    </div>
                  )
                : permissions.INS && (
                    <div>
                      <Button type="submit" color="success">
                        Agregar
                      </Button>
                    </div>
                  )}
            </div>
          </form>

          <ModalConfirmation
            modalTitle={isEdit ? "Editar" : "Crear"}
            modal={modalConfirmation}
            setModal={setModalConfirmation}
            crear={saveInstitution}
            editar={saveInstitution}
            isEdit={false}
            values={values}
          >
            <div className="text-center">
              Esta seguro de continuar con el registro
            </div>
          </ModalConfirmation>
        </>
      ) : (
        <div>
          <Loader />
        </div>
      )}
    </div>
  );
};

export default FormInstituciones;

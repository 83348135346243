import { peticionesReceiver } from "../../../helpers/peticionesReceiver";
import Notification from "../../../components/Notification/Notification";
import { toast } from "react-toastify";
const API = peticionesReceiver();

export const createDistrict = async (distN) => {
  if (distN) {
    const params = {
      action: "insert",
      table: "districts",
      rows: distN,
      validate: [["keyRegion", "nameDistrict"]],
    };
    API.peticion(params)
      .then((res) => {
        if (res.status === 200 && res.data.code === "200") {
          toast(
            <Notification
              type={"agrega_exito"}
              backMessage={res.data.message}
              withIcon
            />
          );
          window.location.href = "#/template/distritos";
        } else {
          toast(
            <Notification
              type={"agrega_error"}
              backMessage={res.data.message}
              withIcon
            />
          );
        }
      })
      .catch((err) =>
        toast(<Notification type={"consultar_servidor_error"} withIcon />)
      );
  }
};

export const updateDistrict = async (distU) => {
  if (distU) {
    const params = {
      action: "update",
      table: "districts",
      rows: distU,
      condition: {
        keyDistrict: distU.keyDistrict,
      },
      validate: [["keyRegion", "nameDistrict"]],
    };
    API.peticion(params)
      .then((res) => {
        if (res.status === 200 && res.data.code === "200") {
          toast(
            <Notification
              type={"modifica_exito"}
              backMessage={res.data.message}
              withIcon
            />
          );
          window.location.href = "#/template/distritos";
        } else {
          toast(
            <Notification
              type={"modifica_error"}
              backMessage={res.data.message}
              withIcon
            />
          );
        }
      })
      .catch((err) =>
        toast(<Notification type={"consultar_servidor_error"} withIcon />)
      );
  }
};

import React from "react";
import { Input} from "reactstrap";

export const SearchInput = (props) => {
  const {
    onChangeMethod,
    value,
    isPlaceholder,
  } = props;
  return (
    <>
      <div className="form-group">
        <div className = "d-flex">
            <label htmlFor="search">Buscar</label>
        </div>
        <Input
            value={value}
            name="search"
            onChange={onChangeMethod}
            className="form-control"
            placeholder={isPlaceholder ? isPlaceholder : "Buscar..."}
        />
      </div>
    </>
  );
};

import { peticionesReceiver } from "../../../helpers/peticionesReceiver";
import Notification from "../../../components/Notification/Notification";
import { toast } from "react-toastify";
const API = peticionesReceiver();

export const createPrestamo = async (leaveN) => {
    if (leaveN) {
      const params = {
        action: "insert",
        table: "types_loan",
        rows: leaveN,
        order: "nameTypeLoan asc",
        validate: [
          ["nameTypeLoan"]
      ]
      };
      API.peticion(params).then((res) => { 
          if (res.status === 200 && res.data.code === "200") {
            toast(<Notification type={"agrega_exito"} withIcon />);
            window.location.href = "#/template/prestamos-nomina"
          }else{
            toast(<Notification type={"agrega_error"} backMessage={res.data.message} withIcon />);
          }
        })
        .catch((err) =>{
        toast(<Notification type={"consultar_servidor_error"} withIcon />);
      });
    }
  };

 export const updatePrestamo = async (leaveU) => {
    if (leaveU) {
      const params = {
        action: "update",
        table: "types_loan",
        rows: leaveU,
        condition: {
          keyTypeLoan: leaveU.keyTypeLoan,
        },
        validate: [["nameTypeLoan"]]
      };
      API.peticion(params).then((res) => { 
          if (res.status === 200 && res.data.code === "200") {
            toast(<Notification type={"modifica_exito"} withIcon />);
            window.location.href = "#/template/prestamos-nomina"
          }else{
            toast(<Notification type={"modifica_error"} backMessage={res.data.message} withIcon />);
          }
        })
        .catch((err) =>{
        toast(<Notification type={"consultar_servidor_error"} withIcon />);
      });
    }
  };
import { useEffect, useState } from "react";
import { Pagination, PaginationItem, PaginationLink, Table } from "reactstrap";
import { sizes } from "./layouts";

export default function DesplegableTable(props) {
  /* Tabla */
  const [pageSize, setPageSize] = useState(5); // Tamaño de las paginas
  const [pagesCount, setPagesCount] = useState(0); // Cantidad total de paginas
  const [currentPage, setCurrentPage] = useState(0); // Pagina actual
  const [seccionActual, setSeccionActual] = useState(0); // Seccion actual
  const [rows, setRows] = useState(0); // Cnatidad de filas

  const formatter = new Intl.NumberFormat("es-MX", {
    style: "currency",
    currency: "MXN",
  });

  const monedas = ["TOTALIMPORT","AMOUNTBIWEEKLY","TOTALDISCOUNT"];
  const { columns, selectedF, dataView, datal } = props;
  const style = { borderBottomWidth: "3px", borderBottomStyle: "solid" }

  useEffect(() => {
    setRows([...Array(datal - 1)].fill(true));
    setPagination(5);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //TABLE FUNCTIONS
  const settableContentPage = (e, index) => {
    e.preventDefault();
    if (index + 1 > (seccionActual + 1) * 5) {
      setSeccionActual(seccionActual + 1);
    } else if (seccionActual !== 0 && index + 1 <= seccionActual * 5) {
      setSeccionActual(seccionActual - 1);
    }
    setCurrentPage(index);
  };

  function setPagination(size) {
    setPagesCount(Math.ceil((datal - 1) / size));
    setPageSize(size);
    setCurrentPage(0);
    setSeccionActual(0);
  }

  function showRow(id) {
    let arr = rows.slice();
    arr[id] = !arr[id];
    setRows(arr);
  }

  const handleOnChangeSelect = (e) => {
    setPagination(e.target.value);
  };

  const formatCell = (d, c) => {
    if(monedas.includes(c) && !isNaN(+d)){
        return formatter.format(d);
    }
    return d;
  }

  return (
    <>
      <Table
        className="table-borderless" //table-hover
        responsive
      >
        <thead className="border-default" style={style}>
          <tr >
            <th className="text-center">No. Fila</th>
            {columns[selectedF].csv.slice(0, columns[selectedF].split).map((el, id) => (
              <th
                key={id}
                className="text-center align-middle"
                style={sizes.includes(el) ? { minWidth: "300px" } : {}}
              >
                {el}
              </th>
            ))}
            {/* <th className="text-center align-middle">Inasistencias</th> */}
            <th className="text-center align-middle">{columns[selectedF].colDes}</th>
          </tr>
        </thead>
        <>
          {dataView
            .slice(currentPage * pageSize, (currentPage + 1) * pageSize)
            .map((el, i) => (
              <tbody key={"R" + i} className="border-bottom border-default">
                <tr style={el.row}>
                  {/* {columns[selectedF].bd.map((col) => ( */}
                  <td className="text-center">
                    {el.idR}
                  </td>
                  {columns[selectedF].bd.slice(0, columns[selectedF].split).map((col) => (
                    <td key={col} className={"align-middle " + [el.cols[col]]}>
                      {formatCell(el[col], col)}
                    </td>
                  ))}
                  {/* <th className="text-center align-middle">{el.sons.length}</th> */}
                  <td
                    className="text-center align-middle"
                    style={{ minWidth: "60px" }}
                  >
                    <i
                      className={rows[5 * currentPage + i] ? "fa fa-angle-double-down" : "fa fa-angle-double-up"}
                      style={{ cursor: "pointer" }}
                      onClick={() => showRow(5 * currentPage + i)}
                    />
                  </td>
                </tr>
                {el.sons && (
                  <tr hidden={rows[5 * currentPage + i]} style={{ backgroundColor: "#F5F5F5" }}>
                    <td colSpan={columns[selectedF].split + 2} className="m-0 p-0">
                      <table style={{ borderCollapse: "collapse"}} className="w-25 ml-auto">
                        <thead className="border-bottom border-secandary">
                          <tr className="text-center">
                              <th className="text-center">#</th>
                            {
                              columns[selectedF].sonsH.map((h, ix)=>(
                                <th className="text-center" key={"khs"+ix}>{h}</th>
                              ))
                            }
                          </tr>
                        </thead>
                        <tbody>
                          {el.sons.map((s, ids) => (
                            <tr key={"S" + ids} className="border-bottom text-center" >
                              <td className="text-center">{ids + 1}</td>
                              {
                                columns[selectedF].sonsK.map((k, ix)=>(
                                  <td className={s[k+"S"]} key={"rscls"+ix}>
                                    {s[k]}
                                  </td>
                                ))
                              }
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </td>
                  </tr>
                )}
              </tbody>
            ))}
        </>
      </Table>
      <div className="row mx-2">
        <div className="col-12 col-lg-6 d-flex justify-content-around mt-3">
          <div>
            Mostrando{" "}
            <span className="text-primary">
              {pageSize * (currentPage + 1) <= dataView.length
                ? pageSize * currentPage + 1
                : dataView.length}{" "}
            </span>{" "}
            a{" "}
            <span className="text-primary">
              {pageSize * (currentPage + 1) <= dataView.length
                ? pageSize * (currentPage + 1)
                : dataView.length}
            </span>{" "}
            de {dataView.length} registros
          </div>
          <select
            className="form-control form-control-sm"
            style={{ width: "78px" }}
            value={pageSize}
            onChange={handleOnChangeSelect}
          >
            <option value={5} key={5}>
              5
            </option>
            <option value={10} key={10}>
              10
            </option>
            <option value={15} key={15}>
              15
            </option>
            <option value={20} key={20}>
              20
            </option>
            <option value={100} key={100}>
              100
            </option>
          </select>
        </div>
        <div className="col-12 col-lg-6 mt-3 d-flex justify-content-center justify-content-lg-end">
          <Pagination>
            <PaginationItem disabled={currentPage <= 0} className="mx-1">
              <PaginationLink
                onClick={(e) => settableContentPage(e, currentPage - 1)}
                previous
                href={currentPage}
              />
            </PaginationItem>

            {[...Array(5)].map(
              (page, i) =>
                5 * seccionActual + i < pagesCount && (
                  <PaginationItem
                    key={i}
                    disabled={5 * seccionActual + i === currentPage}
                    className="mx-1"
                  >
                    <PaginationLink
                      onClick={(e) =>
                        settableContentPage(e, 5 * seccionActual + i)
                      }
                      href={5 * seccionActual + (i + 1)}
                      className={
                        5 * seccionActual + i === currentPage
                          ? "bg-primary rounded-lg text-light"
                          : ""
                      }
                    >
                      {5 * seccionActual + (i + 1)}
                    </PaginationLink>
                  </PaginationItem>
                )
            )}

            <PaginationItem
              disabled={currentPage >= pagesCount - 1}
              className="mx-1"
            >
              <PaginationLink
                onClick={(e) => settableContentPage(e, currentPage + 1)}
                next
                href={currentPage + 2}
              />
            </PaginationItem>
          </Pagination>
        </div>
      </div>
    </>
  );
}

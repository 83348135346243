/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState,useEffect } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { Col, Row, Button } from "reactstrap";

import { peticionesReceiver } from "../../../helpers/peticionesReceiver";

import Loader from "../../../components/Loader/Loader";
import Notification from "../../../components/Notification/Notification";
import { SelectWithSearchInput,CurrencyInput,FilesInput } from "../../../components/GenericInputsFormik/index";
import ModalConfirmation from "../../../components/Modal/ModalConfirmation";

export const TrapasoCuentas = ({ idBankAccount,subirArchivo,actualizarBalance,crear, canInsert }) => {
  const today = new Date()
  const day = today.getDate()
  const defaultForm = {
    idMove: "",
    startBalance: "",
    confirmAmount: "",
    endBalance: '',
    amount: '',
    description: 'Transferencia Bancaria',
    file: '',
    dateMove: `${today.getFullYear()}-${today.getMonth() + 1}-${day < 10 ? `0${day}` : day}`,
    rootAccount: Number(idBankAccount),
    receivingAccount: '',
    rootAccountStartBalance: '',
    receivingAccountStartBalance: ''
  };

  const [modal, setModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true)
  const [invalidFile, setInvalidFile] = useState(false);
  const [filesType, setFilesType] = useState([])
  const [receivingAccountStartBalance, setReceivingAccountStartBalance] = useState(0)
  const [rootAccountStartBalance, setRootAccountStartBalance] = useState(0)

  const [allAccounts, setAllAccounts] = useState([])
  const [accountTransfer, setAccountTransfer] = useState([])
  const [selectRecevingAccount, setSelectRecevingAccount] = useState(true)
  const API = peticionesReceiver();

  useEffect(() => {
    getAllData()
  }, [])

  useEffect(() => {
    if(allAccounts.length > 0 && accountTransfer.length === 0){
      const newAccounts = allAccounts.filter(it => Number(it.idBankAccount) !== Number(values.rootAccount))
      const indexIdBank = allAccounts.findIndex(it => Number(it.idBankAccount) === Number(values.rootAccount))
      setFieldValue('rootAccount', allAccounts[indexIdBank].idBankAccount);
      setFieldValue('rootAccountStartBalance', allAccounts[indexIdBank].avaible);
      setRootAccountStartBalance(allAccounts[indexIdBank].avaible)

      setAccountTransfer(newAccounts)
      setIsLoading(false)
    }

  }, [allAccounts.length > 0 && accountTransfer.length === 0])

  const getAllData = async() => {
    await Promise.all([
      getData("bankaccounts",setAllAccounts,"account",true),
      getData("filetypes",setFilesType,"nameFyleType"),
    ])
  }

  const getData = async(table,functionUpdated,column,accounts = false) => {
    const params = {
      action: 'select',
      table: table,
      condition: { enabled: 1 },
      order: `${column} ASC`,
    };

    const paramsAccounts = {
      action: "multiselect",
      table:"bankaccounts AS BNA INNER JOIN banks AS BNK ON BNA.keyBank = BNK.keyBank",
      rows: "BNA.idBankAccount,BNA.keyBank,BNA.account,BNA.avaible,BNK.nameBank",
      conditions:`BNA.enabled = 1`,
    };
    if(table === 'filetypes') params.condition = {idModule: 35, nameFyleType: 'TRASPASO ENTRE CUENTAS'}

    const res = await API.peticion(accounts ? paramsAccounts : params)
    if(res.status === 200 && res.data.code === "200"){
      functionUpdated(res.data.data)
      return true
    }
    else {
      toast(<Notification type={"consultar_error"} withIcon />);
      return false
    }
  };

  const handleFiles = event => {
    const { files } = event.target

    if(files[0].size > 5000000){
      setInvalidFile(true);
    }
    else {
      setFieldValue('file', files[0]);
    }
  }

  const OnChangeSelect = (options, name) => {
    setFieldValue([name], options.value);

    if(name === 'receivingAccount'){
      const getAccount = allAccounts.find(it => it.idBankAccount === options.value)
      setSelectRecevingAccount(false)
      setFieldValue('receivingAccountStartBalance',getAccount.avaible)
      setReceivingAccountStartBalance(getAccount.avaible)
      setFieldValue('amount','')
      setFieldValue('confirmAmount','')

    }
  };

  const onChangeCurrent = event => {
    const { name,value } = event.target

    if(name === 'amount'){
      const amount = value === undefined ? 0 : value
      setFieldValue('amount',value)

      if(amount === 0){
        const getRootAccount = allAccounts.find(it => it.idBankAccount === Number(values.rootAccount))
        const getReceivingAccount = allAccounts.find(it => it.idBankAccount === Number(values.receivingAccount))

        setFieldValue('rootAccountStartBalance',getRootAccount.avaible)
        setFieldValue('receivingAccountStartBalance',getReceivingAccount.avaible)
      }
      else if( Number(value) <= Number(values.rootAccountStartBalance) ){

        const rootAccountTotal = Number(rootAccountStartBalance) - Number(amount)
        const receivingAccountTotal = Number(receivingAccountStartBalance) + Number(amount)

        setFieldValue('rootAccountStartBalance',rootAccountTotal)
        setFieldValue('receivingAccountStartBalance',receivingAccountTotal)
      }
      else {
        setFieldValue('receivingAccountStartBalance',Number(values.rootAccountStartBalance))
      }
     
    }
    else {
      setFieldValue([name],value)
    }
  }

  const clear = (reset) => {
    reset();
  };

  const saveInfo = async(reset) => {
    setIsLoading(true)

    const data ={
      idBankAccountOrigin: values.rootAccount,
      idBankAccountDest: values.receivingAccount,
      amount: Number(values.amount)
    }

    try{
      
      const result = await crear(data)

      if(result.success){
        const formData = new FormData();
        formData.append("action", "saveFile")
        formData.append("keyFileType", filesType[0].keyFileType);
        formData.append('binary',values.file)
        formData.append('reference',result.references.referenceOrigin)

        const formData2 = new FormData();
        formData2.append("action", "saveFile")
        formData2.append("keyFileType", filesType[0].keyFileType);
        formData2.append('binary',values.file)
        formData2.append('reference',result.references.referenceDestination)

        const promises = await Promise.all(
          [
            subirArchivo(formData),
            subirArchivo(formData2),
          ]
        )
        const response = promises.every(it => it.success ? true : false)
        if(response) {
          toast(<Notification type={"modifica_exito"} withIcon />);
          clear(reset)
          actualizarBalance(values.rootAccountStartBalance)
          setIsLoading(false)
        }
        else {
          toast(<Notification type={"modifica_error"} backMessage={promises.message} withIcon />);
          setIsLoading(false)
        }

      }
      else {
        toast(<Notification type={"modifica_error"} backMessage={result.message} withIcon />);
        setIsLoading(false)
      }
    }
    catch(error){
      toast(<Notification type={"consultar_servidor_error"} withIcon />);
      setIsLoading(false)
    }
  };

  const FormSchema = Yup.object().shape({
    receivingAccount: Yup.number()
      .moreThan(0,"Favor de seleccionar una cuenta destino")
      .required("Favor de seleccionar una cuenta destino"),
    amount: Yup.number()
      .moreThan(0,"Favor de ingresar un monto del movimiento")
      .required("Favor de ingresar un monto del movimiento"),
    confirmAmount: Yup.number()
      .oneOf([Yup.ref("amount"), null], "Favor de confirmar el monto del movimiento")
      .moreThan(0,"Favor de confirmar el monto del movimiento")
      .required("Favor de confirmar el monto del movimiento"),
    description: Yup.string()
      .required("Favor de ingresar alguna observacion"),
    file: Yup.string()
      .required("Favor de subir un comprobante")
  });

  const { handleSubmit, values, handleBlur, errors, touched, setFieldValue, resetForm, handleReset } = useFormik({
    initialValues: defaultForm,
    validationSchema: FormSchema,
    onSubmit: () => setModal(true),
    enableReinitialize: true,
  });

  return (
    <>
    {
      isLoading ? <Loader />
      :
      <div className="mt-4">
        <form onSubmit={handleSubmit} onReset={handleReset} className="mt-4">
          <ModalConfirmation
            modalTitle={"Editar"}
            modal={modal}
            setModal={setModal}
            editar={() => saveInfo(resetForm)}
            isEdit={true}
            values={values}
          >
            <div className="d-flex justify-content-center">
              <h6>¿Está seguro de guardar el registro?</h6>
            </div>
          </ModalConfirmation>

          <Row>
            <Col xs={12} md={6}>
              <SelectWithSearchInput
                label="Cuenta Origen"
                isRequired
                inputOptions={allAccounts}
                inputName="rootAccount"
                onBlurMethod={handleBlur}
                touched={touched.rootAccount}
                errors={errors.rootAccount}
                optionValue="idBankAccount"
                inputValue={values.rootAccount}
                optionName={["nameBank","account"]}
                optionSubLabel = {[false,'Cta.']}
                isDisabled = {true}
              />
            </Col>

            <Col xs={12} md={6}>
              <SelectWithSearchInput
                label="Cuenta Destino"
                isRequired
                inputOptions={accountTransfer}
                inputName="receivingAccount"
                onChangeMethod={(options) => OnChangeSelect(options, "receivingAccount")}
                onBlurMethod={handleBlur}
                touched={touched.receivingAccount}
                errors={errors.receivingAccount}
                optionValue="idBankAccount"
                inputValue={values.receivingAccount}
                optionName={["nameBank","account"]}
                optionSubLabel = {[false,'Cta']}
                isDisabled={!canInsert}
              />
            </Col>

            <Col xs={12} md={6}>
              <CurrencyInput
                label="Saldo actual de cuenta origen"
                inputName="startBalance"
                value={values.rootAccountStartBalance}
                isDisabled = {true}
              />
            </Col>

            <Col xs={12} md={6}>
              <CurrencyInput
                label="Saldo actual de cuenta destino"
                inputName="startBalance"
                value={values.receivingAccountStartBalance}
                isDisabled = {true}
              />
            </Col>

            <Col xs={12} md={6}>
              <CurrencyInput
                label="Monto a transferir"
                inputName="amount"
                onChangeMethod={(value, name ) => onChangeCurrent({ target: { name: name, value: value } }) }
                onBlurMethod={handleBlur}
                value={values.amount}
                isRequired={true}
                touched={touched.amount}
                errors={errors.amount}
                isMax = {values.rootAccountStartBalance.length}
                isDisabled = {!canInsert && selectRecevingAccount}
              />
            </Col>

            <Col xs={12} md={6}>
              <CurrencyInput
                label="Confirmar monto"
                inputName="confirmAmount"
                onChangeMethod={(value, name ) => onChangeCurrent({ target: { name: name, value: value } }) }
                onBlurMethod={handleBlur}
                value={values.confirmAmount}
                isRequired={true}
                touched={touched.confirmAmount}
                errors={errors.confirmAmount}
                isMax = {values.rootAccountStartBalance.length}
                isDisabled = {!canInsert && selectRecevingAccount}
              />
            </Col>

            <Col xs={12} lg={6}>
              <FilesInput
                label="Comprobante PDF"
                inputName="file"
                fileAccept = ".pdf"
                onChangeMethod={handleFiles}
                onBlurMethod={handleBlur}
                value={values.file}
                isRequired={true}
                touched={touched.file}
                errors={invalidFile}
                isDisabled={!canInsert}
              />
            </Col>

          </Row>

          {canInsert && <Row className="mt-5">
            <div className="col-sm-6 order-2 order-sm-1 text-center text-sm-left mb-4">
              <Button color="danger" type="reset" onClick={() => clear(resetForm)} >
                Cancelar
              </Button>
            </div>

            <div className="col-sm-6 order-1 order-sm-2 text-center text-sm-right mb-4">
              <Button color="success" type="submit"> Guardar </Button>
            </div>
          </Row>}
        </form>
      </div>
    }
    </>
  );
};
export default TrapasoCuentas;

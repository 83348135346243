import { Document, Page, StyleSheet, Text, View } from "@react-pdf/renderer";

const s = StyleSheet.create({
  Text: { paddingVertical: "4px" },
  body: {
    paddingVertical: "50px",
    paddingHorizontal: "40px",
  },

  header: {
    // fontSize: "12px",
    backgroundColor: "#BEBEBE",
    textAlign: "center",
  },
  mCell: { padding: "2px" },

  marginB1: { marginBottom: "1px" },
  textP: { paddingVertical: "4px", paddingHorizontal: "2px" },

  vCenter: { alignItems: "center", justifyContent: "center" },
  vCenterL: { alignItems: "left", justifyContent: "center" },
  vCenterR: { alignItems: "right", justifyContent: "center" },
  vCenterBtw: { alignItems: "stretch", justifyContent: "center" },

  jcEnd: { display: "flex", justifyContent: "flex-end" },

  alignE: { alignContent: "flex-end" },

  row: { flexDirection: "row" },
  flexWrap: { display: "flex", flexWrap: "wrap", flexDirection: "row" },

  w8: { width: "8%" },
  w10: { width: "10%" },
  w11: { width: "11%" },
  w13: { width: "13%" },
  w14: { width: "14%" },
  w15: { width: "15%" },
  w16: { width: "16%" },
  w20: { width: "20%" },
  w25: { width: "25%" },
  w26: { width: "26%" },
  w29: { width: "29%" },
  w30: { width: "30%" },
  w39: { width: "39%" },
  w40: { width: "40%" },
  w44: { width: "44%" },
  w45: { width: "45%" },
  w50: { width: "50%" },
  w55: { width: "55%" },
  w58: { width: "58%" },
  w60: { width: "60%" },
  w65: { width: "65%" },
  w70: { width: "70%" },
  w80: { width: "80%" },
  w86: { width: "86%" },
  w87: { width: "87%" },
  w90: { width: "90%" },
  w100: { width: "100%" },
  wAuto: { width: "auto" },

  border: { border: "1px solid #5D6D7E" },
  borderT: { borderBottom: "1px solid #5D6D7E" },
  borderB: { borderBottom: "1px solid #5D6D7E" },
  borderE: { borderRight: "1px solid #5D6D7E" },
  borderS: { borderLeft: "1px solid #5D6D7E" },

  bg: { backgroundColor: "#BEBEBE" },
  bg2: { backgroundColor: "#BEBEBE" },

  fs8: { fontSize: "8px" },
  fs9: { fontSize: "9px" },
  fs10: { fontSize: "10px" },
  fs11: { fontSize: "11px" },
  fs12: { fontSize: "12px" },

  tend: { textAlign: "right", paddingRight: "2px" },
  tstart: { textAlign: "left" },
  tcenter: { textAlign: "center" },
  tBold: { fontFamily: "Helvetica-Bold" },

  mT15: { marginTop: "15px" },
  mT10: { marginTop: "10px" },
  mT60: { marginTop: "60px" },
});

const date = new Date();
const lDate = date.toLocaleString("es-MX", { dateStyle: "long" });

export default async function chequePdf(data) {
  return {
    report: (
      <>
        <Document>
          <Page style={[s.body, s.fs11]}>
            <View style={[s.tend, s.fs10]}>
              <Text>{lDate}</Text>
            </View>
            <View style={[s.mT60]}>
              <View style={[s.row]}>
                <View style={[s.w80, s.tcenter]}>
                  <Text>{data[0].nombre}</Text>
                </View>
                <View style={[s.w20, s.tcenter]}>
                  <Text>{data[0].cantidad}</Text>
                </View>
              </View>
              <View style={[s.row, s.mT15]}>
                <View style={[s.w80, s.tcenter]}>
                  <Text style={{textTransform: "uppercase"}}>({data[0].letra})</Text>
                </View>
                <View style={[s.w20, s.tcenter]}>
                </View>
              </View>
            </View>
            <View style={{marginTop: "130px",}}>
              <Text>{data[0].msg}</Text>
            </View>
          </Page>
        </Document>
      </>
    ),
  };
}

import React from "react";

import Widget from "../../../../components/Widget/Widget";
import FormRegiones from "../FormRegiones";
import { updateRegion } from "../functions";

export const EditarRegion = (props) => {
  return (
    <Widget className="widget-p-md">
        <h3>Editar región</h3>
        <FormRegiones data={localStorage.getItem('dataContent').split('|-|')} editar={updateRegion}/>
    </Widget>
  )
}

export default EditarRegion;
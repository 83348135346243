import { useEffect, useState } from "react";
import { Row, Col, Button } from "reactstrap";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as Yup from "yup";

import { CurrencyInput, SelectTypeHeadSingle } from "../../../../components/GenericInputsFormik";
import { peticionesReceiver } from "../../../../helpers/peticionesReceiver";
import Notification from "../../../../components/Notification/Notification";
import Widget from "../../../../components/Widget/Widget";

import ModalConfirmation from "../../../../components/Modal/ModalConfirmation";

import Datatable from "../../../../components/Datatable/Datatable"

import ModalComponent from "../../../../components/Modal/ModalDelete";

import { getDataArray } from "./functions";
import EditarCapitulo from "./EditarCapitulo";
import Loader from "../../../../components/Loader/Loader";
import { GetPermisos } from "../../../../libs/permissions/hook";

const formatter = new Intl.NumberFormat("es-MX", {
    style: "currency",
    currency: "MXN",
  });

export default function FormAsignacionPresupuestoAreas({ data }) {
    const API = peticionesReceiver();
    const [modal, setModal] = useState(false)
    const [modalEdit, setModalEdit] = useState(false)
    const [areas, setAreas] = useState([])
    const [loadAreas, setLoadAreas] = useState(true)
    const [chapters, setChapters] = useState([])
    const [loadChapters, setLoadChapters] = useState(true)
    const [loading, setLoading] = useState(false)
    const [disableAdd, setDisableAdd] = useState(false)
    const [disableArea, setDisableArea] = useState(false)
    const [refresh, setRefresh] = useState(false)
    const [idBudgetAreaChapter, setIdBudgetAreaChapter] = useState(null)

    const [dataT, setDataT] = useState([])

    const [permissionsForm, setPermissionsForm] = useState("");

    GetPermisos(setPermissionsForm);

    const headers = ["Capitulo", "Monto", "Editar", "Eliminar"];

    const columns = [
        {
            data: null,
            render: function (row) {
                return `${row.keyChapter} - ${row.nameChapter}`
            },
            orderValue: "keyChapter"
        },
        {
            data: "amount",
            render: function (row) {
                return formatter.format(row);
            }
        },
        {
            data: "idBudgetAreaChapter",
            render: function (row) {
                return (
                    <>
                        {permissionsForm.UPD === "1" ? (
                            <i className="fa fa-pencil text-warning" style={{ cursor: "pointer" }} onClick={() => { setIdBudgetAreaChapter(row); setModalEdit(true) }} />
                        ) : ""}
                    </>
                )
            }
        },
        {
            data: "idBudgetAreaChapter",
            render: function (row) {
                return (
                    <>
                        {permissionsForm.DEL === "1" ? (
                            <ModalComponent
                                modalTitle="Eliminar"
                                id={row.idFurniture}
                                table={["furnitures"]}
                                nameColumn={["idFurniture"]}
                                elimina={() => OnDelete(row)}
                            >
                                <h6>
                                    ¿Seguro de eliminar el registro "{row.nameFurniture}"?
                                </h6>
                                No podrá recuperar la información después de ser eliminada
                            </ModalComponent>
                        ) : ""}
                    </>
                )
            }
        }
    ]

    const OnDelete = async (id) => {
        const params = {
            action: "delete",
            table: "budgetareachapters",
            condition: { idBudgetAreaChapter: id },
        }
        try {
            const res = await API.peticion(params)
            if (res.status === 200 && res.data.code === "200") {
                setLoadChapters(true);
                getDataArray("getChapters", setChapters, setLoadChapters)
                setRefresh(true)
                toast(<Notification type={"elimina_exito"} withIcon />);
            } else {
                toast(<Notification type={"elimina_error"} withIcon />);
            }
        } catch (err) {
            toast(<Notification type={"elimina_error"} withIcon />);
        }
    }

    const columnDefs = [
        { targets: [2, 3], className: "text-center", orderable: false },
        { targets: [1], className: "text-right" }
    ]

    const defaultForm = {
        idAnnualBudget: data.idAnnualBudget,
        idArea: "",
        keyChapter: "",
        amount: ""
    }

    useEffect(() => {
        getDataArray("getChapters", setChapters, setLoadChapters)
        getDataArray("getBudgetAreas", setAreas, setLoadAreas)
    }, [])

    useEffect(() => {
        setLoading(loadChapters && loadAreas);
    }, [loadChapters, loadAreas])

    const getArea = async (idBudgetArea, idAnualBudget) => {
        const params = {
            action: "multiselect",
            table: "budgetareas",
            rows: "idArea",
            conditions: `idAnualBudget=${idAnualBudget} AND idBudgetArea=${idBudgetArea} AND enabled = 1`
        };
        try {
            const res = await API.peticion(params)
            if (res.status === 200 && res.data.code === "200") {
                const data = res.data.data[0];
                if (areas.findIndex(area => area.idArea === data.idArea) !== -1) {
                    setFieldValue(["idArea"], data.idArea)
                    setDisableAdd(false)
                } else {
                    setDisableAdd(true)
                }
                setDisableArea(true)
            } else {
                toast(<Notification type={"consultar_error"} withIcon />);
            }
        } catch (err) {
            toast(<Notification type={"consultar_error"} withIcon />);
        }
    }

    useEffect(() => {
        if (data.idBudgetArea !== undefined && !loading && areas.length > 0) {
            getArea(data.idBudgetArea, data.idAnnualBudget)
        }
    }, [loading, areas])

    const onChange = (e) => {
        const { name, value } = e.target
        setFieldValue([name], value)
    }

    const FormSchema = Yup.object().shape({
        idAnnualBudget: Yup.number(),
        idArea: Yup.number()
            .required("Seleccione un area valido"),
        keyChapter: Yup.number()
            .required("Seleccione un capitulo valido"),
        amount: Yup.number()
            .required("Ingrese un monto valido"),
    })

    const { handleSubmit, values, handleBlur, errors, touched, setFieldValue, resetForm } =
        useFormik({
            initialValues: defaultForm,
            onSubmit: () => setModal(true),
            validationSchema: FormSchema,
        });

    const OnCreate = async (rows) => {
        const params = {
            action: "saveAreaBudget",
            idArea: rows.idArea,
            idAnnualBudget: rows.idAnnualBudget,
            keyChapter: rows.keyChapter,
            amount: rows.amount
        };
        try {
            const res = await API.peticionEndPoint(params, "app/facades/budgets/budgetsF.php")
            if (res.status === 200 && res.data.code === "200") {
                const ress = res.data.data[0];
                if (data.idBudgetArea === undefined) {
                    const save = { idBudgetArea: ress.idBudgetArea, idAnnualBudget: rows.idAnnualBudget }
                    localStorage.setItem("preuniejearea", JSON.stringify(save));
                    window.location.href = "#/template/presupuestounidades/areas/editar"
                } else {
                    const valuesSave = JSON.parse(JSON.stringify(values));
                    resetForm()
                    setFieldValue(["idAnnualBudget"], valuesSave.idAnnualBudget)
                    setFieldValue(["idArea"], values.idArea)
                    setRefresh(true)
                }
                toast(<Notification type={"agrega_exito"} withIcon />);
            } else {
                toast(<Notification type={"agrega_error"} withIcon />);
            }
        } catch (err) {
            toast(<Notification type={"agrega_error"} withIcon />);
        }
    }

    function getParams(order, records, page, search) {
        return {
            action: "getAreaChapters",
            //page: (page * records).toString(),
            //records: records.toString(),
            //search,
            //order,
            idArea: values.idArea
        }
    }

    useEffect(() => {
        if (dataT.length > 0) {
            setChapters(chapters.filter(chapter => dataT.findIndex(row => row.keyChapter === chapter.chapterKey) === -1))
        }
        
    }, [dataT])

    async function getData(order, records, page, search) {
        try {
            const res = await API.peticionEndPoint(getParams(order, records, page, search), "app/facades/budgets/budgetsF.php")
            if (res.status === 200 && res.data.code === "200") {
                const data = res.data;
                data.data.forEach(row => row.amount = Number(row.amount.substring(1).replace(/,/g, "")));
                setDataT(data.data)
                return data;
            } else {
                toast(<Notification type={"consultar_error"} backMessage={res.data.message} />);
                return null;
            }
        } catch (err) {
            console.log(err)
            toast(<Notification type={"consultar_servidor_error"} withIcon />);
            return null
        }
    }


    if (loading) {
        return (<Loader />)
    }
    return (
        <>
            <Widget className={"p-3"}>
                <h3>Asignación de presupuesto</h3>
                <form onSubmit={handleSubmit}>
                    <Row>
                        <Col xs={12} md={4}>
                            <SelectTypeHeadSingle
                                label="Unidad ejecutora"
                                inputName="idArea"
                                onChangeMethod={onChange}
                                onBlurMethod={handleBlur}
                                value={values.idArea}
                                isRequired
                                touched={touched.idArea}
                                errors={errors.idArea}
                                optionsArray={areas}
                                optionValue="idArea"
                                optionName="name"
                                isDisabled={disableArea}
                            />
                        </Col>
                        <Col xs={12} md={4}>
                            <SelectTypeHeadSingle
                                label="Capitulo"
                                inputName="keyChapter"
                                onChangeMethod={onChange}
                                onBlurMethod={handleBlur}
                                value={values.keyChapter}
                                isRequired
                                touched={touched.keyChapter}
                                errors={errors.keyChapter}
                                optionsArray={chapters}
                                optionValue="keyChapter"
                                optionName="name"
                            />
                        </Col>
                        <Col xs={12} md={4}>
                            <CurrencyInput
                                label={"Monto"}
                                errors={errors.amount}
                                inputName="amount"
                                isRequired
                                onBlurMethod={handleBlur}
                                onChangeMethod={(value, name, e) => onChange({ target: { name: name, value: value } })}
                                touched={touched.amount}
                                value={values.amount}
                            />
                        </Col>
                    </Row>
                    <div className="text-center">
                        <Button className="add" type="submit" disabled={disableAdd}>Agregar</Button>
                    </div>
                </form>
                {values.idArea !== "" &&
                    (<>
                        <Datatable
                            className="mt-3"
                            control="front"
                            headers={headers}
                            columns={columns}
                            columnDefs={columnDefs}
                            petition={getData}
                            stateRefresh={[refresh, setRefresh]}
                        />
                    </>)
                }
                <Button color="danger" className="mt-2" onClick={() => window.location.href = "#/template/presupuestounidades/areas"}>Regresar</Button>
                {idBudgetAreaChapter !== null &&
                    (
                        <>
                            {permissionsForm.CON === "1" ? (
                                <EditarCapitulo
                                    id={idBudgetAreaChapter}
                                    modalEdit={modalEdit}
                                    setModalEdit={setModalEdit}
                                    setRefresh={setRefresh}
                                />
                            ) : (<div className="text-center p-4">No tienes permitido ver los registros de esta seccion</div>)}
                        </>
                    )}

                <ModalConfirmation
                    modalTitle={"Asignar presupuesto"}
                    modal={modal}
                    crear={(rows) => OnCreate(rows)}
                    setModal={setModal}
                    values={values}
                >
                    <div className="d-flex justify-content-center">
                        <h6>¿Desea continuar con el registro?</h6>
                    </div>
                </ModalConfirmation>
            </Widget>
        </>
    )
}
import { useFormik } from "formik";
import { Alert, Badge, Button, Col, Collapse, Row } from "reactstrap";
import * as Yup from "yup";
import { SelectTypeHeadSingle, TextInput } from "../../../../components/GenericInputsFormik";
import { YearInput } from "../../../../components/GenericInputsFormik/YearInput";
import { NumberInput } from "../../../../components/GenericInputsFormik/NumberInput";
import Datatable from "../../../../components/Datatable/Datatable";
import { useEffect, useState } from "react";
import { doRequest } from "../../../../helpers/requests";
import { PERIOD } from "../../../../libs/tools/format";
import { toast } from "react-toastify";
import Notification from "../../../../components/Notification/Notification";

const cYear = new Date().getFullYear();

const defaulForm = {
    idIncapacityApplication: "",
    keyTypeApplicationSalary: "",
    noDays: "",
    enabled: 1,
    periods: [],
}

const columnDefs = [
    { className: "text-center", targets: [1, 2, 3, 4, 5] },
    { orderable: false, targets: [5] }
];

// function periodDays(period, fYear){
//     const year = new Date(fYear).getFullYear();
//     if(!period) return 0;
//     if(period % 2 !== 0) return 15;
//     const month = ((period / 2) + (period % 2));
//     return getDaysInMonth(new Date(year, month, 0)) - 15;
// }

export default function FormaDescuentoForm({ tDescuentos, tStatus, ttdays, discounts, dates, idStaff, setDiscounts, reset, idIncapacity }) {
    const [refresh, setRefresh] = useState(false);
    const [errores, setErrores] = useState("");
    const [data, setData] = useState([]);
    const [flag, setFlag] = useState(false);
    const [required, setRequired] = useState(false);
    const [showButtonsDesc, setShowButtonsDesc] = useState(true);
    const [clnDescuentos, setClnDescuentos] = useState([]);
    const [isEdit, setIsEdit] = useState(-1);
    const [canDelete, setCanDelete] = useState(true);

    useEffect(() => {
        if(!reset){
            handleReset();
            setData([]);
        }
    }, [reset]);

    useEffect(() => {
        if (discounts.length > 0) {
            const md = discounts.map((d) => {
                return {
                    ...d,
                    name: tDescuentos.find((df) => +df.keyTypeApplicationSalary === +d.keyTypeApplicationSalary)?.nameTypeApplicationSalary || "",
                    canDelete: ![ ...d.periods || [] ].some((p)=> +p.noPaysMade > 0)
                }
            }).filter((d) => +d.enabled > 0);
            setData(md);
        } else setData([]);
    }, [discounts]);

    useEffect(() => {
        if (flag) setRefresh(true);
        else setFlag(true);
        compareDays();
        cleanDescuentos();
    }, [data]);

    function cleanDescuentos(id) {
        const usados = discounts.map((d) => (id && id === +d.keyTypeApplicationSalary )|| +d.enabled === 0 ? "" : +d.keyTypeApplicationSalary);      
        setClnDescuentos(tDescuentos.filter((d) => !usados.includes(+d.keyTypeApplicationSalary)));
    }

    /* TABLE */
    const columns = [
        { data: "name" },
        { data: "noDays" },
        {
            data: null, render: (row) => (
                row.keyTypeApplicationSalary != 1 &&
                <i className="fa fa-eye text-info cursor-pointer" onClick={() => { openDiscount(row)}} />
            )
        },
        {
            data: null, render: (row) => (
                row.canDelete && 
                <i className="fa fa-trash text-danger cursor-pointer" onClick={() => quitDescuento(row)} />
            )
        },
    ]

    function quitDescuento(row) {
        const cpyRow = { ...row };
        delete cpyRow.name;
        delete cpyRow.canDelete;
        const ix = discounts.findIndex((d) => JSON.stringify(d) === JSON.stringify(cpyRow));
        if (ix > -1) {
            let cpyDiscounts = [...discounts];
            if (row.idIncapacityApplication) {
                let ewe = cpyDiscounts[ix];
                ewe.enabled = 0;
            } else {
                cpyDiscounts.splice(ix, 1);
            }
            setDiscounts(cpyDiscounts);
            handleReset();
        }
    }

    function openDiscount(row){
        if(values.idIncapacityApplication || values.periods.length > 0){
            toast(
                <Notification type="warning" backMessage="Guarde el descuento antes de continuar" />,
                { closeButton: false }
            );
            return;
        }
        const cpyRow = { ...row };
        setCanDelete(cpyRow.canDelete);
        delete cpyRow.canDelete;
        delete cpyRow.name;
        setValues({ ...cpyRow, keyTypeApplicationSalary: +row.keyTypeApplicationSalary }, true);
        cleanDescuentos(+row.keyTypeApplicationSalary);
        setRequired(+row.keyTypeApplicationSalary != 1);
        const ix = discounts.findIndex((d) => JSON.stringify(d) === JSON.stringify(cpyRow) );
        setIsEdit(ix);
    }
    /* END TABLE */

    /* FORM */
    const FormSchema = Yup.object().shape({
        keyTypeApplicationSalary: Yup.string()
            .required("Seleccione un tipo de descuento")
            .test({
                name: "uniqueDiscount", 
                message: "El tipo de descuento ya existe",
                test: (value, cx) => discountExist(+value)
            }),
        noDays: Yup.number()
            .required("Ingrese el número de dias")
            .min(1, "La número debe ser mayor a 0")
            .test({
                name: "maxIncapacityDays",
                message: () => `Limite de días de la incapacidad excedido (Max: ${ttdays})`,
                test: (value) => validMaxIncapacityDays(+value)
            })
            .test({
                name: "periodDays",
                message: `El acumulado de días excede los de la incapacidad (Max: ${ttdays})`,
                test: (value, cx) => validAcumDays(value)
            }),
        periods: Yup.array().when("keyTypeApplicationSalary", {
            is: (v) => +v !== 1,
            then: Yup.array()
                    .min(1, "Agregue al menos un periodo de aplicación al descuento")
                    .test({
                        name: "validSameQuantity",
                        params: { noDays: Yup.ref("noDays") },
                        message: 'El acumulado de días de los periodos de aplicación es diferente al del tipo de descuento (${noDays} días)',
                        test: (value, cx) => validSameQuantity(value, +cx.parent.noDays)
                    })
        })
    });

    function discountExist(nd) {
        return !discounts.find((d) => +d.keyTypeApplicationSalary === nd) || isEdit > -1;
    }

    function validMaxIncapacityDays(days) {
        return days <= ttdays;
    }

    function validAcumDays(days) {
        const sum = data.reduce((acc, d, ix) => acc + ((+d.enabled != 0 && isEdit != ix) ? +d.noDays : 0), 0);
        return sum + +days <= ttdays;
    }

    function validSameQuantity(p, tt){
        if(!idIncapacity) return true; /// Primera area
        const sum = p.reduce((acc, d) => acc + (+d.enabled != 0 ? +d.noDays : 0), 0);
        return sum === tt;
    }

    const {
        handleSubmit,
        values,
        handleBlur,
        errors,
        touched,
        setFieldValue,
        handleReset,
        setValues
    } = useFormik({
        initialValues: defaulForm,
        validationSchema: FormSchema,
        onSubmit: () => addDescuento(),
        onReset: () => {
            setIsEdit(-1); 
            setCanDelete(true);
        },
        enableReinitialize: true,
    });

    function onChange({ target }) {
        if (target.name === "keyTypeApplicationSalary") {
            if (+target.value === 1) {
                setRequired(false);
                setFieldValue("periods", []);
            } else {
                setRequired(true);
            }
        }
        setFieldValue(target.name, target.value);
    }

    function addDescuento() {
        const cpy = [...discounts];
        if(isEdit > -1){
            cpy.splice(isEdit, 1, {...values});
        }else{
            cpy.push({ ...values });
        }
        setDiscounts(cpy);
        handleReset();
    }
    /* END FORM */


    /* VALIDATIONS */
    function compareDays() {
        const sum = data.reduce((acc, d) => acc + +d.noDays, 0);
        if (sum > ttdays) {
            setErrores("Valide que la suma de los días coincida con los días del rango de fechas seleccionado");
        } else {
            setErrores("");
        }
    }
    /* END VALIDATIONS */

    return (
        <div>
            <div className="mt-3">
                <h5>Formas de descuento</h5>
            </div>
            <hr />

            <Alert isOpen={!!errores} className="border border-danger text-danger font-weight-bold mx-1" style={{ backgroundColor: "#FF4B2340" }}>
                {errores}
            </Alert>
            <Datatable
                headers={["Tipo Descuento", "No. Días", "Ver", "Eliminar"]}
                columns={columns}
                columnDefs={columnDefs}
                data={data}
                control="front"
                stateRefresh={[refresh, setRefresh]}
                order={{ col: 0, opt: "asc" }}
                searching={false}
                paging={false}
                className="px-0"
            />

            <div className="mt-3">
                <Row>
                    <Col xs={12} md={6}>
                        <SelectTypeHeadSingle
                            label="Tipo de descuento"
                            inputName="keyTypeApplicationSalary"
                            optionsArray={clnDescuentos}
                            defaultOption="Seleccione un descuento"
                            onChangeMethod={onChange}
                            onBlurMethod={handleBlur}
                            value={values.keyTypeApplicationSalary}
                            touched={touched.keyTypeApplicationSalary}
                            errors={errors.keyTypeApplicationSalary}
                            optionValue="keyTypeApplicationSalary"
                            optionName="nameTypeApplicationSalary"
                            isRequired
                        />
                    </Col>
                    <Col xs={12} md={6}>
                        {/* (values.period ? ` (${periodDays(+values.period, values.year)})`: "") */}
                        <NumberInput
                            label="No. Días"
                            inputName="noDays"
                            onChangeMethod={onChange}
                            onBlurMethod={handleBlur}
                            value={values.noDays}
                            errors={errors.noDays}
                            touched={touched.noDays}
                            isMin={1}
                            maxLength={2}
                            isRequired
                        />
                    </Col>

                </Row>
                <Collapse isOpen={!!values.keyTypeApplicationSalary && values.keyTypeApplicationSalary !== 1 && !!values.noDays}>
                    <div>
                        <PeriodsForm 
                            periods={values.periods} 
                            tStatus={tStatus} 
                            required={required} 
                            dates={dates} 
                            idStaff={idStaff} 
                            application={values.keyTypeApplicationSalary}
                            errorPeriods={touched.periods ? errors.periods : ""}
                            maxDays={+values.noDays}
                            sfv={setFieldValue}
                            canDelete={canDelete}
                            idIncapacity={idIncapacity}
                            setShowButtonsDesc={setShowButtonsDesc}
                        />
                    </div>
                </Collapse>

                {
                    showButtonsDesc &&
                    <div className={"d-flex flex-md-row flex-column-reverse mb-3 " + (isEdit > -1 ? "justify-content-around":" justify-content-center")}>
                        {
                            isEdit > -1 &&
                            <Button color="primary" type="button" form="miniForm" className="my-1" onClick={() => {handleReset(); cleanDescuentos()}}>
                                Cerrar Descuento
                            </Button>
                        }
                            <Button color="success" type="button" form="miniForm" className="my-1" onClick={() => handleSubmit()}>
                                {isEdit > -1 ? "Actualizar " : "Agregar "} Descuento
                            </Button>
                    </div>
                }
            </div>
            <hr />
        </div>
    )
}

const columnDefs2 = [
    { className: "text-center", targets: [0, 1, 2, 3, 4, 5, 6, 9, 10, 11] },
    { className: "text-right", targets: [7, 8] },
    { orderable: false, targets: [10, 11] }
];

const defaulForm2 = {
    idIncapacityApplicationPeriod: "",
    keyStat: "",
    startYear: new Date(cYear, 0, 1, 0, 0, 0, 0),
    startPeriod: "",
    endYear: "",
    endPeriod: "",
    noDays: "",
    amountTotal: "",
    amountBiweekly: "",
    noPays: "",
    noPaysMade: 0,
    enabled: 1,
}

function PeriodsForm({ periods, tStatus, required, idStaff, dates, application, errorPeriods, maxDays, sfv, canDelete, idIncapacity, setShowButtonsDesc }) {
    const [refresh2, setRefresh2] = useState(false);
    const [cleanData, setCleanData] = useState([]);
    const [flag, setFlag] = useState(false);
    const [openForm, setOpenForm] = useState(false);
    const [isEdit, setIsEdit] = useState(-1);

    useEffect(() => {
        if(!application){
        handleReset();
        setCleanData([]);
        }
    }, [application]);

    useEffect(() => {
        setCleanData(periods.map((p) => {
            return { ...p, name: tStatus.find((s) => +s.KeyStat === +p.keyStat )?.nameStat || "" }
        }).filter((p) => +p.enabled > 0));
    }, [periods]);

    useEffect(() => {
        if(flag) setRefresh2(true);
        else setFlag(true);
    }, [cleanData]);

    /* FORM */
    const FormSchemaCreate = Yup.object().shape({
        amountTotal: Yup.number().required("Ingrese el monto total").min(1, "Monto debe ser mayor a 0"),
    });
    const FormSchemaEdit = Yup.object().shape({
        keyStat: Yup.string().required("Seleccione un estatus"),
        startYear: Yup.date().required("Seleccione un año de inicio"),
        endYear: Yup.date().required("Seleccione un año").min(Yup.ref("startYear"), "El año debe ser mayor/igual al del inicio"),
        startPeriod: Yup.string().required("Seleccione un periodo de inicio"),
        endPeriod: Yup.string().required("Seleccione un periodo de término"),
        noPays: Yup.number().required("Ingrese el no. de parcialidades").min(1, "Parcialidades deben ser mayor a 0"),
        noDays: Yup.number()
            .required("Ingrese el no. de días")
            .test({
                name: "maxDiscountDays",
                message: () => `Limite de días del descuento excedido (Max: ${maxDays})`,
                test: (value) => +value <= maxDays
            })
            .test({
                name: "maxDiscountAcumDays",
                message: () => `El acumulado de días excede los del tipo de descuento (Max: ${maxDays})`,
                test: (value) => maxDiscountAcumDays(+value)
            }),
        amountTotal: Yup.number().required("Ingrese el monto total").min(1, "Monto debe ser mayor a 0"),
        amountBiweekly: Yup.number().required("Ingrese el monto quincenal").min(1, "Monto debe ser mayoir a 0")
    });

    function maxDiscountAcumDays(val){
        const sum = cleanData.reduce((acc, d, ix) => acc + (+d.enabled != 0 && ix !== isEdit ? +d.noDays : 0), 0);
        return sum + val <= maxDays
    }

    const {
        handleSubmit,
        values,
        handleBlur,
        errors,
        touched,
        setFieldValue,
        handleReset,
        setValues
    } = useFormik({
        initialValues: defaulForm2,
        validationSchema: idIncapacity ? FormSchemaEdit : FormSchemaCreate,
        onSubmit: (values) => addPeriod(),
        onReset: () => { 
            if(isEdit !== -1) setIsEdit(-1);
            setShowButtonsDesc(true);
            setOpenForm(false)
         },
        enableReinitialize: true,
    });

    function onChange({ target }) {
        setFieldValue(target.name, target.value);
    }

    function addPeriod(){
        const cpy = [ ...periods ];
        if(isEdit > -1){
            console.log("ESTOY EDINTADOOO EL PERIDO")
            cpy.splice(isEdit, 1, {
                ...values, 
                startYear: values.startYear.getFullYear()
            });
        }else{
            console.log("CREANDO EL PERIDO")
            cpy.push({ 
                ...values,
                startYear: values.startYear.getFullYear()
            });
        }
        sfv("periods", cpy);
        handleReset();
    }
    /* END FORM */


    /* TABLE */
    const columns = [
        { data: "startYear" },
        { data: "startPeriod" },
        { data: "endYear" },
        { data: "endPeriod" },
        { data: "noDays" },
        { data: "name", render: (d) => <Badge color="secondary">{d}</Badge> },
        { data: "noPays" },
        { data: "amountTotal", format: "currency" },
        { data: "amountBiweekly", format: "currency" },
        {data: "noPaysMade" },
        {
            data: null, render: (row) => (
                canDelete &&
                <i className="fa fa-pencil text-warning cursor-pointer" onClick={() => editPeriod(row)} />
            )
        },
        {
            data: null, render: (row) => (
                canDelete &&
                <i className="fa fa-trash text-danger cursor-pointer" onClick={() => quitPeriod(row)} />
            )
        },
    ]

    function editPeriod(row){
        if(values.idIncapacityApplicationPeriod){
            toast(
                <Notification type="warning" backMessage="Guarde el periodo antes de continuar" />,
                { closeButton: false }
            );
            return;
        }
        setValues({ 
            ...row, 
            startYear: new Date(+row.startYear, 0, 1, 0, 0, 0, 0),
        }, true);
        const ix = cleanData.findIndex((d) => JSON.stringify(d) === JSON.stringify(row) );
        setIsEdit(ix);
        setShowButtonsDesc(false);
        setOpenForm(true)
    }

    function quitPeriod(row) {
        const cpyRow = { ...row };
        if (Object.keys(cpyRow).includes("name")) {
            delete cpyRow.name;
        }
        const ix = periods.findIndex((d) => JSON.stringify(d) === JSON.stringify(cpyRow));
        if (ix > -1) {
            let cpyPeriods = [...periods];
            if (row.idIncapacityApplicationPeriod) {
                let ewe = cpyPeriods[ix];
                ewe.enabled = 0;
            } else {
                cpyPeriods.splice(ix, 1);
            }
            sfv("periods", cpyPeriods);
        }
    }
    /* END TABLE */

    /* Consulta el monto total */
    useEffect(()=>{
        if(values.noDays) getAmout();
    }, [values.noDays]);    

    /* Calcula año y periodo final */
    useEffect(()=>{
        if(values.startYear && values.startPeriod && values.noPays && +values.noPays > 0) calEnds();
        else{
            setFieldValue("endYear", "");
            setFieldValue("endPeriod", "");
        }
    }, [values.startYear, values.startPeriod, values.noPays]);

    /* Calcular monto quincenal */
    useEffect(()=>{
        if(values.amountTotal && values.noPays && +values.noPays > 0){
            setFieldValue("amountBiweekly", (+values.amountTotal / +values.noPays));
        }else{
            setFieldValue("amountBiweekly", "");
        }
    }, [values.amountTotal, values.noPays]); 

    async function getAmout() {
        const params = {
            "action": "calcularDescuento",
            "rows": {
                "idStaff": idStaff,
                "startDate": dates.sd,
                "endDate": dates.ed,
                "noDays": values.noDays,
                "application": application
            }
        }
        const res = await doRequest("app/facades/employees/benefitsF.php", params, false);
        if(res.toString()) setFieldValue("amountTotal", res);
    }

    function calEnds(){
        const pFC = +values.startPeriod + +values.noPays - 1;
        const excede = pFC > 24;
        const ultimo = pFC % 24 === 0;
        const anios = excede ? Math.floor(pFC / 24) - ultimo : 0;
        const periodos = excede ? (ultimo ? 24 : pFC % 24) : pFC;

        setFieldValue("endYear", values.startYear.getFullYear() + anios );
        setFieldValue("endPeriod", periodos);
    }

    function openFormPeriodos(){
        setOpenForm(true);
        setShowButtonsDesc(false);
    }

    return (
        <div className="my-4 bg-light-gray border pt-2 pb-3 px-2">
            <h6>Periodo de aplicación</h6>

            <Alert isOpen={!!errorPeriods} className="border border-danger text-danger font-weight-bold " style={{ backgroundColor: "#FF4B2340" }}>
                {errorPeriods}
            </Alert>

            <Datatable
                headers={["Año Inicio", "Per. Inicio", "Año Término", "Per. Término", "No. Días", "Estatus", "Parcialidades", "Cant. total", "Cant.\nQuincenal", "Pagos\nAplicados", "Editar", "Eliminar"]}
                    columns={columns}
                    columnDefs={columnDefs2}
                    data={cleanData}
                    control="front"
                    stateRefresh={[refresh2, setRefresh2]}
                    order={{ col: 0, opt: "asc" }}
                    searching={false}
                paging={false}
            />

            <div className="text-right my-2">
                <Button color="info" onClick={()=> openFormPeriodos()} disabled={openForm}>Agregar</Button>
            </div>

            <Collapse isOpen={required && openForm} onExited={() => handleReset()}>
                <div className="mt-2">
                    { idIncapacity && 
                        <Row className="d-flex justify-content-around">
                            <Col xs={12} md={6}>
                                <SelectTypeHeadSingle
                                    label="Estatus"
                                    inputName="keyStat"
                                    optionsArray={tStatus}
                                    defaultOption="Seleccione un descuento"
                                    onChangeMethod={onChange}
                                    onBlurMethod={handleBlur}
                                    value={values.keyStat}
                                    touched={touched.keyStat}
                                    errors={errors.keyStat}
                                    optionValue="keyStat"
                                    optionName="nameStat"
                                    isRequired
                                />
                            </Col>
                            <Col xs={12} md={3}>
                                <NumberInput
                                    label="No. Días"
                                    inputName="noDays"
                                    onChangeMethod={onChange}
                                    onBlurMethod={handleBlur}
                                    value={values.noDays}
                                    errors={errors.noDays}
                                    touched={touched.noDays}
                                    isMin={1}
                                    maxLength={2}
                                    isRequired
                                />
                            </Col>
                            <Col xs={12} md={3}>
                                <NumberInput
                                    label="No. Parcialidades"
                                    inputName="noPays"
                                    onChangeMethod={onChange}
                                    onBlurMethod={handleBlur}
                                    value={values.noPays}
                                    errors={errors.noPays}
                                    touched={touched.noPays}
                                    isMin={1}
                                    maxLength={3}
                                    isRequired
                                />
                            </Col>
                        </Row> 
                    }
                    { idIncapacity && 
                    <Row className="d-flex justify-content-around">
                        <Col xs={12} md={3}>
                            <YearInput
                                label="Año de inicio"
                                inputName="startYear"
                                onChangeMethod={onChange}
                                onBlurMethod={handleBlur}
                                value={values.startYear}
                                errors={errors.startYear}
                                touched={touched.startYear}
                                isMin={cYear - 1}
                                isMax={2099}
                                isRequired
                            />
                        </Col>
                        <Col xs={12} md={3}>
                            <SelectTypeHeadSingle
                                label="Periodo de Inicio"
                                inputName="startPeriod"
                                optionsArray={PERIOD}
                                defaultOption="Seleccione un periodo"
                                onChangeMethod={onChange}
                                onBlurMethod={handleBlur}
                                value={values.startPeriod}
                                touched={touched.startPeriod}
                                errors={errors.startPeriod}
                                optionValue="value"
                                optionName="label"
                                isRequired
                            />
                        </Col>
                        <Col xs={12} md={3}>
                            <TextInput
                                label="Año de término"
                                inputName="endYear"
                                value={values.endYear}
                                errors={errors.endYear}
                                touched={touched.endYear}
                                isDisabled
                                isRequired
                            />
                        </Col>
                        <Col xs={12} md={3}>
                            <TextInput
                                label="Periodo de término"
                                inputName="endPeriod"
                                value={values.endPeriod}
                                errors={errors.endPeriod}
                                touched={touched.endPeriod}
                                isDisabled
                                isRequired
                            />
                        </Col>
                    </Row>
                    }
                    <Row className="d-flex justify-content-around">
                        <Col xs={12} md={5}>
                            <NumberInput
                                label="Monto Total"
                                inputName="amountTotal"
                                onChangeMethod={onChange}
                                onBlurMethod={handleBlur}
                                value={values.amountTotal}
                                errors={errors.amountTotal}
                                touched={touched.amountTotal}
                                isMin={1}
                                prefix="$"
                                decimals
                                isRequired
                            />
                        </Col>
                        { idIncapacity && 
                            <>
                                <Col xs={12} md={5}>
                                    <NumberInput
                                        label="Monto Quincenal"
                                        inputName="amountBiweekly"
                                        onChangeMethod={onChange}
                                        onBlurMethod={handleBlur}
                                        value={values.amountBiweekly}
                                        errors={errors.amountBiweekly}
                                        touched={touched.amountBiweekly}
                                        isMin={1}
                                        prefix="$"
                                        decimals
                                        isRequired
                                        isDisabled
                                    />
                                </Col>
                                <Col xs={12} md={2}>
                                    <NumberInput
                                        label="Pagos aplicados"
                                        inputName="noPaysMade"
                                        value={values.noPaysMade}
                                        isMin={0}
                                        isDisabled
                                    />
                                </Col>
                            </>
                        }
                    </Row>
                    <div className="d-flex flex-row justify-content-md-end justify-content-center">
                        <div className="text-center">
                            <Button type="button" color="danger" onClick={handleReset}>
                                Cancelar
                            </Button> 
                            <Button color="secondary" type="button" className="m-1" onClick={handleSubmit} >
                                {values.idIncapacityApplicationPeriod ? "Editar": "Guardar"} periodo
                            </Button>
                        </div>
                    </div>
                </div>
            </Collapse>
        </div>
    )
}
import React, { useState } from "react";
import TableComponent from "../../../components/TableComponent/TableComponentFiltro";

export const Salarios = () => {
  const cabeceras = [
    "Dias Naturales",
    "Dias Promedio",
    "UMA",
    "Salario Minimo",
    "Monto Maximo SS",
    "Monto Minimo SS",
    "CSS",
    "CSSR",
    "COSCI",
    "AOSCI",
    "Servicio de Salud",
    "FSSR",
    "Gastos de Administración",
    "Prima Basica",
    "Prima de Siniestralidad",
    "Vigente",
  ];
  const [parametros] = useState(
    /* {
    action: "datatable",
    table: `valuesworkouts`,
    rows: `	naturalDays,averageDays,CONCAT('$',uma) AS uma,
    CONCAT('$',smi) AS smi,CONCAT('$',maxSS) AS maxSS,
    CONCAT('$',minSS) AS minSS,quotaSS,quotaSSR,quotaOSCI,
    supportOSCI,supportSS,supportFSSR,supportGA,supportPB,supportPS,IF(enabled=1,'Si','No') AS vigente`, ///sin espacios y comas
    conditions: "enabled = 1 OR enabled = 0",
    page: 0,
    records: 5,
    search: "",
    order: "",
  } */
    {
      action: "datatable",
      table: "valuesworkouts",
      rows: "naturalDays,averageDays,CONCAT('$',format(uma,2)) AS uma,CONCAT('$',format(smi,2)) AS smi,CONCAT('$',format(maxSS,2)) AS maxSS,CONCAT('$',format(minSS,2)) AS minSS,quotaSS,quotaSSR,quotaOSCI,supportOSCI,supportSS,supportFSSR,supportGA,supportPB,supportPS,IF(enabled=1,'Si','No') AS vigente",
      conditions: "enabled = 1 OR enabled = 0",
      page: 0,
      records: 5,
      search: "22",
      order: "",
    }
  );

  return (
    <div>
      <TableComponent
        titulo="Configuracion de cálculo"
        cabecerasTabla={cabeceras}
        filtro={null}
        rutaCrear="/template/config-calculo-nomina/crear"
        rutaEditar={null}
        parametros={parametros}
        disabledDelete={true}
      />
    </div>
  );
};
export default Salarios;

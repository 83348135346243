import React from "react";

import { Button, Row, Col } from "reactstrap";
import { SelectTypeHeadSingle } from "../../../components/GenericInputsFormik/index";
import { PERIOD } from "../../../helpers/regex";
import { YearInput } from "../../../components/GenericInputsFormik/YearInput";

const FiltroCalendar = ({ onChange, values }) => {
  return (
    <div>
      <form>
        <Row className="d-flex justify-content-around align-items-center mb-5">
          <Col xs={12} lg={3}>
            <SelectTypeHeadSingle
              label="Periodo"
              isRequired={true}
              optionsArray={PERIOD}
              inputName="period"
              onChangeMethod={onChange}
              optionValue="value"
              optionName="label"
              value={values.period}
              defaultOption="Seleccione un periodo"
            />
          </Col>
          <Col xs={12} lg={3}>
            <YearInput
              label="Año"
              inputName="year"
              onChangeMethod={onChange}
              value={values.year}
              isRequired
              isMin={new Date("2020")}
              isMax={new Date("2500")}
            />
          </Col>
          <Col xs={12} lg={3}>
            <Button type="submit" color="success">
              Buscar
            </Button>
          </Col>
        </Row>
      </form>
    </div>
  );
};

export default FiltroCalendar;

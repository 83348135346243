import { peticionesReceiver } from "../../../helpers/peticionesReceiver";
const API = peticionesReceiver();

export const createLegend = async (advertisements) => {
  if (advertisements) {
    const params = {
      action: "insert",
      table: "advertisements",
      rows: advertisements,
      order: "idAdvertisement asc",
      validate: [
        ["keyTypeAdverstisement","descripcion","period","year"]
      ]
    };
    try {
      const res = await API.peticion(params)
      if (res.status === 200 && res.data.code === "200") {
        return {success: true}
      }
      else{
        return {success: false, message: res.data.message}
      }
    } catch (error) {
      return {success: false, message: error}
    }
  }
};

export const getLegend = async (idAdvertisement) => {
  const paramsJobs = {
    action: "select",
    table: 'advertisements',
    condition: {
      enabled: 1,
      idAdvertisement: idAdvertisement
    }
  }
  try {
    const result = await API.peticion(paramsJobs)
    if (result.status === 200 && result.data.code === "200") {
      const info = result.data.data[0]
      return {success: true, info}
    }
    else{
      return {success: false, message: result.data.message}
    }
  } catch (error) {
    return {success: false, message: error}
  }
}

export const updateLegend = async (advertisements) => {
  if (advertisements) {
    const params = {
      action: "update",
      table: "advertisements",
      rows: advertisements,
      condition: {
        idAdvertisement: advertisements.idAdvertisement,
      },
      validate: [
        ["keyTypeAdverstisement","descripcion","period","year"]
      ]
    };
    try {
      const res = await API.peticion(params)
      if (res.status === 200 && res.data.code === "200") {
        return {success: true}
      }
      else{
        return {success: false, message: res.data.message}
      }
    } catch (error) {
      return {success: false, message: error}
    }
  }
};
import { faUpload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useRef } from "react";
import { Button } from "reactstrap";

export default function ButtonFile({
    label,
    error,
    onChange,
    color,
    value,
    name,
    disabled
}) {
    const refInput = useRef(null);

    return (
        <div className="mb-3">
            <Button
                color={color}
                type="button"
                onClick={() => refInput.current && refInput.current.click()}
                className="me-3"
                disabled={disabled}
            >
                <FontAwesomeIcon icon={faUpload} className="mr-2"/>
                <span>{label}</span>
            </Button>
            <p>{value !== "" && value.name !== "File" ? value.name : ""}</p>
            <p className={error ? "text-danger" : ""}>{error}</p>
            <input
                onChange={onChange}
                style={{ display: "none" }}
                type="file"
                ref={refInput}
                name={name}
                id={name}
                accept="application/pdf"
            />
        </div>
    )
}
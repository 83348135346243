import React, { useState } from "react";
import TableComponent from "../../../components/TableComponent/TableComponentFiltro";

export const HorarioLaboral = () => {
  const cabeceras = [
    "Id",
    "Nombre del horario",
    "Tipo de horario",
    "Editar",
    "Eliminar",
  ];
  const [parametros] = useState({
    action: "datatable",
    table: `jobschedule AS JBS
    INNER JOIN types_schedule AS TSD
    ON TSD.keyTypeSchedule = JBS.keyTypeSchedule`,
    rows: "JBS.idJobSchedule, JBS.nameJobSchedule, TSD.nameTypeSchedule", ///sin espacios y comas
    conditions: "JBS.enabled = 1",
    page: 0,
    records: 5,
    search: "",
    order: "",
  });

  return (
    <div>
      <TableComponent
        titulo="Horarios laborales"
        cabecerasTabla={cabeceras}
        filtro={null}
        rutaCrear="/template/horarios-nomina/crear"
        rutaEditar="/template/horarios-nomina/editar"
        parametros={parametros}
      />
    </div>
  );
};
export default HorarioLaboral;

import { useEffect, useState } from "react";
import TableComponentCustom from "../../../components/TableComponent/TableComponentCustom";
import Widget from "../../../components/Widget/Widget";
import { useSelector } from "react-redux";
import { peticionesReceiver } from "../../../helpers/peticionesReceiver";
import ExpedienteAvances from "./ExpedienteAvances";
import HeaderComponent from "../componentes/others/HeaderComponent";

const defaultProyect = {
  id: 0,
  name: "",
  objetive: "",
  keyStat: "",
  canEdit: true,
};

const RegistroProyectos = () => {
  const API = peticionesReceiver();
  const { profileType } = useSelector((state) => state.userInfo);
  const idArea = profileType.selectProfile.idArea;
  const [proyect, setProyect] = useState({ ...defaultProyect });
  


  const parametros = {
    action: "datatable",
    table: "proyects A INNER JOIN status B ON A.keyStat = B.keyStat",
    rows: "A.idProyect, A.yearProyect, A.startDate, A.endDate ,A.nameProyect, A.objetive, B.nameStat, B.keyStat",
    conditions: `A.enabled = 1 AND A.idArea = ${idArea} AND A.keyStat = 68`,
    page: 0,
    records: 5,
    search: "",
    order: "",
  };


  const openAdvance = (values) => {
    setProyect({
      id: values[0],
      name: values[4],
      objetive: values[5],
      keyStat: values[7],
    });
  };

  return (
    <Widget className="widget-p-md">
      {proyect.id === 0 ? (
        <TableComponentCustom
          titulo={"Registro de avances"}
          cabecerasTabla={[
            "Id",
            "Año",
            "Fecha de inicio",
            "Fecha de termino",
            "Descripcion del proyecto",
            "Objetivo",
            "Estatus",
            "Acciones",
          ]}
          filtro={[true, true, true, true, true, true, true]}
          parametros={parametros}
          addWatch={true}
          actionWatch={openAdvance}
        />
      ) : (
        <>

          <div >
            <h1 className="text-secondary text-center">Expediente de actividades </h1>
          </div>

          <HeaderComponent proyect={proyect}/>

          <ExpedienteAvances proyect={proyect} setProyect={setProyect}/>
        </>
      )}
    </Widget>
  );
};

export default RegistroProyectos;

export default function INELogo({ className = "" }) {
    return (
        <svg version="1.1" viewBox="0 0 175.62 119.45" xmlns="http://www.w3.org/2000/svg" className={className}>
            <g transform="matrix(1 0 0 .99955 -1.1074 -.0026967)">
                <path d="m192.56 118.29h37.023v-91.607h-37.023v91.607" fill="#231f20" />
                <path d="m348.24 79.844-67.876-53.161h-37.021v91.607h37.021v-52.211l67.876 52.211h37.021v-91.607h-37.021v53.161" fill="#231f20" />
                <path d="m519.59 43.769v-17.087h-120.09v91.607h120.09v-17.087h-83.064v-20.175h83.064v-17.087h-83.064v-20.172h83.064" fill="#231f20" />
                <path d="m28.083 64.324s26.364 34.743 61.311 55.18c0 0 58.041-52.931 67.033-54.157 0 0-47.364-31.011-62.697-65.344 0 0-40.628 26.643-65.647 64.321" fill="#d4098d" />
                <path d="m6.1198 132.68v26.959h-6.1198v-26.959h6.1198" fill="#231f20" />
                <path d="m12.32 146.31c0-2.44-0.08072-4.52-0.15989-6.2387h5.2797l0.2796 2.6787h0.1204c0.79947-1.24 2.7995-3.1187 6.0401-3.1187 3.9989 0 6.9989 2.6387 6.9989 8.3987v11.6h-6.0797v-10.84c0-2.52-0.88013-4.24-3.0797-4.24-1.6791 0-2.6791 1.16-3.0801 2.28-0.15893 0.36-0.24013 0.96-0.24013 1.52v11.28h-6.0792v-13.32" fill="#231f20" />
                <path d="m36.72 154.31c1.1204 0.68133 3.4401 1.4813 5.2396 1.4813 1.8401 0 2.6005-0.64 2.6005-1.64s-0.60053-1.48-2.8801-2.24c-4.0391-1.36-5.5991-3.56-5.56-5.88 0-3.64 3.1199-6.3987 7.96-6.3987 2.2796 0 4.3197 0.51866 5.5208 1.1187l-1.0812 4.2c-0.87973-0.48-2.56-1.12-4.2391-1.12-1.4808 0-2.3209 0.6-2.3209 1.6 0 0.92 0.7604 1.4 3.1604 2.24 3.7204 1.28 5.2797 3.16 5.3204 6.04 0 3.64-2.8803 6.3187-8.4808 6.3187-2.5599 0-4.8396-0.55867-6.3187-1.3587l1.0791-4.3613" fill="#231f20" />
                <path d="m61.919 134.47v5.6013h4.3593v4.4787h-4.3593v7.08c0 2.36 0.55987 3.44 2.4 3.44 0.7604 0 1.3604-0.08 1.8004-0.16l0.03907 4.6c-0.79947 0.32-2.2395 0.51867-3.9599 0.51867-1.9593 0-3.6-0.67867-4.5593-1.6787-1.1203-1.16-1.6803-3.0413-1.6803-5.8v-8h-2.6v-4.4787h2.6v-4.2413l5.96-1.36" fill="#231f20" />
                <path d="m70.799 159.63v-19.559h6.0797v19.559h-6.0797" fill="#231f20" />
                <path d="m89.558 134.47v5.6013h4.3599v4.4787h-4.3599v7.08c0 2.36 0.5604 3.44 2.4 3.44 0.7604 0 1.3609-0.08 1.7995-0.16l0.04 4.6c-0.79947 0.32-2.2395 0.51867-3.9593 0.51867-1.9593 0-3.6005-0.67867-4.5599-1.6787-1.1199-1.16-1.6797-3.0413-1.6797-5.8v-8h-2.6005v-4.4787h2.6005v-4.2413l5.9595-1.36" fill="#231f20" />
                <path d="m116.88 153.35c0 2.56 0.0813 4.64 0.1604 6.28h-5.2796l-0.28027-2.7613h-0.11973c-0.7604 1.2013-2.6 3.2013-6.1199 3.2013-3.9599 0-6.8801-2.4813-6.8801-8.52v-11.479h6.1197v10.519c0 2.84 0.9208 4.56 3.0407 4.56 1.6797 0 2.6391-1.16 3.0401-2.1213 0.1588-0.35867 0.19947-0.83867 0.19947-1.3187v-11.639h6.1192v13.279" fill="#231f20" />
                <path d="m129.56 134.47v5.6013h4.36v4.4787h-4.36v7.08c0 2.36 0.55987 3.44 2.4 3.44 0.7604 0 1.3604-0.08 1.8-0.16l0.04 4.6c-0.8 0.32-2.24 0.51867-3.9604 0.51867-1.9593 0-3.6-0.67867-4.5599-1.6787-1.1192-1.16-1.6797-3.0413-1.6797-5.8v-8h-2.6v-4.4787h2.6v-4.2413l5.96-1.36" fill="#231f20" />
                <path d="m143.4 149.83c0 3.36 1.4 5.88 3.9987 5.88 2.36 0 3.88-2.36 3.88-5.88 0-2.92-1.12-5.88-3.88-5.88-2.92 0-3.9987 3-3.9987 5.88zm14.159-0.2c0 7.16-5.08 10.44-10.319 10.44-5.72 0-10.12-3.7613-10.12-10.08 0-6.32 4.1587-10.359 10.439-10.359 6 0 10 4.1187 10 9.9987" fill="#231f20" />
                <path d="m171.6 159.63v-26.959h7.12l5.6 9.8787c1.6013 2.84 3.2 6.1987 4.4 9.24h0.12c-0.4-3.56-0.52-7.2-0.52-11.24v-7.8787h5.6013v26.959h-6.4013l-5.76-10.4c-1.6-2.88-3.3587-6.36-4.6787-9.52l-0.12 0.04c0.16 3.56 0.23867 7.36 0.23867 11.76v8.12h-5.6" fill="#231f20" />
                <path d="m211.16 150.39c-3.2-0.04-5.68 0.72-5.68 3.08 0 1.5587 1.04 2.32 2.4 2.32 1.52 0 2.76-1 3.16-2.24 0.08-0.32 0.12-0.68 0.12-1.04zm0.72 9.24-0.36-1.96h-0.12c-1.28 1.56-3.28 2.4-5.6 2.4-3.96 0-6.32-2.88-6.32-6 0-5.08 4.56-7.52 11.48-7.48v-0.28c0-1.04-0.56-2.52-3.56-2.52-2 0-4.12 0.68-5.4 1.48l-1.12-3.9187c1.36-0.76134 4.0413-1.72 7.6-1.72 6.52 0 8.6 3.8387 8.6 8.4387v6.7987c0 1.88 0.08 3.6813 0.28 4.7613h-5.48" fill="#231f20" />
                <path d="m238.32 159.15c-1.08 0.48133-3.12 0.88-5.44 0.88-6.32 0-10.359-3.8387-10.359-9.9987 0-5.72 3.9187-10.399 11.199-10.399 1.6 0 3.36 0.28 4.64 0.75866l-0.96 4.5213c-0.72-0.32134-1.8-0.60134-3.4-0.60134-3.1987 0-5.28 2.28-5.24 5.48 0 3.6 2.4 5.48 5.36 5.48 1.44 0 2.56-0.24133 3.48-0.6l0.72 4.4787" fill="#231f20" />
                <path d="m243.44 159.63v-19.559h6.08v19.559h-6.08" fill="#231f20" />
                <path d="m261.32 149.83c0 3.36 1.4 5.88 3.9987 5.88 2.3613 0 3.88-2.36 3.88-5.88 0-2.92-1.1187-5.88-3.88-5.88-2.9187 0-3.9987 3-3.9987 5.88zm14.159-0.2c0 7.16-5.0787 10.44-10.319 10.44-5.72 0-10.12-3.7613-10.12-10.08 0-6.32 4.1587-10.359 10.439-10.359 6 0 10 4.1187 10 9.9987" fill="#231f20" />
                <path d="m281 146.31c0-2.44-0.08-4.52-0.16-6.2387h5.28l0.28 2.6787h0.12c0.79867-1.24 2.7987-3.1187 6.04-3.1187 3.9987 0 6.9987 2.6387 6.9987 8.3987v11.6h-6.08v-10.84c0-2.52-0.88-4.24-3.0787-4.24-1.68 0-2.68 1.16-3.0813 2.28-0.15867 0.36-0.23867 0.96-0.23867 1.52v11.28h-6.08v-13.32" fill="#231f20" />
                <path d="m316.59 150.39c-3.2-0.04-5.6787 0.72-5.6787 3.08 0 1.5587 1.0387 2.32 2.3987 2.32 1.52 0 2.76-1 3.16-2.24 0.08-0.32 0.12-0.68 0.12-1.04zm0.72 9.24-0.36-1.96h-0.12c-1.28 1.56-3.28 2.4-5.6 2.4-3.96 0-6.3187-2.88-6.3187-6 0-5.08 4.5587-7.52 11.479-7.48v-0.28c0-1.04-0.56-2.52-3.56-2.52-2 0-4.12 0.68-5.4 1.48l-1.12-3.9187c1.36-0.76134 4.0413-1.72 7.6013-1.72 6.5187 0 8.5987 3.8387 8.5987 8.4387v6.7987c0 1.88 0.08 3.6813 0.28 4.7613h-5.48" fill="#231f20" />
                <path d="m329.08 131.24h6.0787v28.399h-6.0787v-28.399" fill="#231f20" />
                <path d="m367.92 148.23h-9.9213v6.4h11.081v5h-17.201v-26.959h16.64v4.9987h-10.52v5.6h9.9213v4.96" fill="#231f20" />
                <path d="m375.15 131.24h6.08v28.399h-6.08v-28.399" fill="#231f20" />
                <path d="m399.95 147.63c0-1.48-0.64134-3.96-3.44-3.96-2.56 0-3.6 2.32-3.7613 3.96zm-7.16 4.16c0.2 2.5187 2.6787 3.72 5.5187 3.72 2.08 0 3.7613-0.28 5.4-0.8l0.80134 4.12c-2 0.8-4.44 1.1987-7.0813 1.1987-6.6387 0-10.439-3.8387-10.439-9.9587 0-4.96 3.08-10.439 9.8787-10.439 6.3213 0 8.72 4.9187 8.72 9.7587 0 1.04-0.12 1.96-0.2 2.4h-12.599" fill="#231f20" />
                <path d="m425.87 159.15c-1.08 0.48133-3.12 0.88-5.44 0.88-6.32 0-10.359-3.8387-10.359-9.9987 0-5.72 3.9187-10.399 11.199-10.399 1.6 0 3.36 0.28 4.64 0.75866l-0.96 4.5213c-0.72-0.32134-1.8-0.60134-3.4-0.60134-3.2 0-5.28 2.28-5.24 5.48 0 3.6 2.4 5.48 5.36 5.48 1.44 0 2.56-0.24133 3.48-0.6l0.72 4.4787" fill="#231f20" />
                <path d="m438.63 134.47v5.6013h4.3587v4.4787h-4.3587v7.08c0 2.36 0.56 3.44 2.4 3.44 0.76133 0 1.36-0.08 1.8-0.16l0.0387 4.6c-0.79866 0.32-2.2387 0.51867-3.9573 0.51867-1.9613 0-3.6027-0.67867-4.56-1.6787-1.12-1.16-1.68-3.0413-1.68-5.8v-8h-2.6013v-4.4787h2.6013v-4.2413l5.9587-1.36" fill="#231f20" />
                <path d="m453.43 149.83c0 3.36 1.4013 5.88 4 5.88 2.36 0 3.88-2.36 3.88-5.88 0-2.92-1.1187-5.88-3.88-5.88-2.9187 0-4 3-4 5.88zm14.159-0.2c0 7.16-5.0773 10.44-10.317 10.44-5.7213 0-10.12-3.7613-10.12-10.08 0-6.32 4.1587-10.359 10.437-10.359 6 0 10 4.1187 10 9.9987" fill="#231f20" />
                <path d="m473.31 146.51c0-2.88-0.08-4.76-0.15867-6.4387h5.24l0.2 3.5987h0.15867c1-2.84 3.4013-4.0387 5.2787-4.0387 0.56266 0 0.84133 0 1.2813 0.0787v5.72c-0.44-0.08-0.96-0.16-1.64-0.16-2.24 0-3.76 1.2-4.1587 3.08-0.0813 0.39867-0.12 0.88-0.12 1.36v9.92h-6.0813v-13.12" fill="#231f20" />
                <path d="m500.43 150.39c-3.2-0.04-5.68 0.72-5.68 3.08 0 1.5587 1.04 2.32 2.4013 2.32 1.5187 0 2.7587-1 3.1587-2.24 0.0813-0.32 0.12-0.68 0.12-1.04zm0.72133 9.24-0.36133-1.96h-0.12c-1.2787 1.56-3.2787 2.4-5.5987 2.4-3.9613 0-6.3213-2.88-6.3213-6 0-5.08 4.56-7.52 11.48-7.48v-0.28c0-1.04-0.56-2.52-3.56-2.52-2 0-4.12 0.68-5.3987 1.48l-1.12-3.9187c1.36-0.76134 4.04-1.72 7.5987-1.72 6.5213 0 8.6 3.8387 8.6 8.4387v6.7987c0 1.88 0.08 3.6813 0.28133 4.7613h-5.48" fill="#231f20" />
                <path d="m513.12 131.24h6.0787v28.399h-6.0787v-28.399" fill="#231f20" />
                <path d="m76.879 138.47h-6.0797v-3.9973h6.0797v3.9973" fill="#231f20" />
                <path d="m249.52 138.47h-6.08v-3.9973h6.08v3.9973" fill="#231f20" />
                <path d="m24.494 87.631-23.387 9.0664h56.85l0.05078 0.05469 57.104-0.05469h61.619l-23.387-9.0664h-27.613c-1.0213 0.85733-2.0444 1.7218-3.0664 2.5898h30.68l5.7207 2.8691h-140.29l5.7188-2.8691h27.15c-0.9068-0.87333-1.7879-1.7365-2.6504-2.5898z" fill="#231f20" />
            </g>
        </svg>


    )
}
import React, { useState } from "react";
import TableComponentFiltro from "../../../components/TableComponent/TableComponentFiltro";

export const Impuestos = () => {
  const cabeceras = [
    "Id", //0
    "Nombre", //1
    "Editar",
    "Eliminar",
  ];
  const [parametros] = useState({
    action: "datatable",
    table: "taxes",
    rows: "keyTax,nameTax", ///sin espacios y comas
    conditions: "enabled = 1",
    page: 0,
    records: 5,
    search: "",
    order: "",
  });

  return (
    <div>
      <TableComponentFiltro
        titulo="Tipos de impuestos"
        cabecerasTabla={cabeceras}
        filtro={null}
        rutaCrear="/template/impuestos/crear"
        rutaEditar="/template/impuestos/editar"
        parametros={parametros}
      />
    </div>
  );
};
export default Impuestos;

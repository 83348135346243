import { useEffect, useState } from "react";
import Datatable from "../../../components/Datatable/Datatable";
import Widget from "../../../components/Widget/Widget";
import {
  doRequest,
  doRequestSaveRes,
  findEmployee,
  numberSpelling,
  uploadFile,
} from "../../../helpers/requests";
import { Button } from "reactstrap";
import ModalConfirmation from "../../../components/Modal/ModalConfirmation";
import FullScreenLoader from "../../../components/Loader/FullScreenLoader";
import { pdf } from "@react-pdf/renderer";
import solicitudCotizacion from "./pdf/solicitud";
import { toast } from "react-toastify";
import Notification from "../../../components/Notification/Notification";
import { GetPermissions } from "../../../libs/permissions/getPermissions";
import sb from "../recepcion-cotizaciones/recepCot.module.scss";
import classNames from "classnames";
import { fDate } from "../../../libs/tools/format";
import { useSelector } from "react-redux";
import ModalViewFile from "../../../components/Modal/ModalViewFile";

const headers = [
  "FOLIO",
  "OBJETO",
  "SOLICITANTE",
  "ENCARGADO",
  "INGRESO DE LA SOLICITUD DE COTIZACIÓN",
  "FECHA EN QUE SE ATENDIO LA SOLICITUD",
  "OBSERVACIONES",
  "ACCIONES",
];

const columnDefs = [
  {
    orderable: true,
    targets: [0, 4, 5],
    className: "text-center",
  },
  {
    orderable: true,
    targets: [1, 6],
    className: "text-justify",
  },
];

export default function AutorizacionCotizaciones() {
  const [permissions, setPermissions] = useState({});
  GetPermissions(setPermissions);

  const [refresh, setRefresh] = useState(false);
  const [modalConfirm, setModalConfirm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [msgLoader, setMsgLoader] = useState("");
  const [selectedQuote, setSelectedQuote] = useState("");
  const [modalReports, setModalReports] = useState(false); // QUITAR
  const [inforep, setinforep] = useState(""); // QUITAR
  const [linkFile, setLinkFile] = useState(""); ///Link de archivo
  const [modalFile, setModalFile] = useState(false); ///Visualziar archivos
  const { profileType, id } = useSelector((state) => state.userInfo);
  const [idStaff, setIdStaff] = useState(null);
  const idArea = profileType.selectProfile.idArea;

  const columns = [
    { data: "folio" },
    { data: "object" },
    { data: "fullNameSol" },
    { data: "fullNameEnc" },
    { data: "created", format: fDate },
    { data: "dateSend", format: fDate },
    { data: "notes" },
    {
      data: null,
      render: function (row) {
        return (
          <div className="d-flex flex-row">
            <div className={classNames(["text-center pl-3", sb.colAction])}>
              <button
                className={classNames([
                  "btn text-light d-flex flex-row px-2 my-3",
                  sb.myBtn,
                  sb.info,
                ])}
                onClick={() => setLinkFile(row.solicitud)}
              >
                <i className="fa fa-eye my-1 mr-1" />
                <span>Solicitud</span>
              </button>

              {
                row.dictamen &&
                  <button
                  className={classNames([
                    "btn text-light d-flex flex-row px-2 my-3",
                    sb.myBtn,
                    sb.warning,
                  ])}
                  onClick={() => setLinkFile(row.dictamen)}
                >
                  <i className="fa fa-gavel my-1 mr-1" />
                  <span>Dictamen</span>
                </button>
              }

              {permissions.UPD &&
                row.keyStat === "105" && ( // "105"
                  <button
                    className={classNames([
                      "btn text-light d-flex flex-row px-2 my-3",
                      sb.myBtn,
                      sb.success,
                    ])}
                    onClick={() => selectQuote(row)}
                  >
                    <i className="fa fa-check-circle-o my-1 mr-1" />
                    <span>Aprobar</span>
                  </button>
                )}
            </div>
          </div>
        );
      },
    },
  ];

  useEffect(()=>{
    getStaff();
}, []);

async function getStaff(){
  const emp = await findEmployee(id);
  if(emp){
      setIdStaff(emp.idStaff);
  }
}

  useEffect(() => {
    if (linkFile !== "") {
      setModalFile(true);
    }
  }, [linkFile]);

  useEffect(() => {
    if (!modalFile) {
      setLinkFile("");
    }
  }, [modalFile]);

  async function getData(ordCol, itemsPerPage, currentPage, search) {
    const res = await doRequestSaveRes(
      "receiver/receiver.php",
      getParams(ordCol, itemsPerPage, currentPage, search),
      false,
      false
    );
    if (res.code === "200") return res;
    else return null;
  }

  function getParams(ordCol, itemsPerPage, currentPage, search) {
    const params = {
      action: "datatable",
      table:
        "quotes A LEFT JOIN staff B ON A.idStaffRequire = B.idStaff LEFT JOIN staff C ON A.idStaffRevision = C.idStaff LEFT JOIN bookcase D ON A.idQuote = D.reference AND D.keyFileType = 42 LEFT JOIN files E ON D.idBook = E.idBook AND E.enabled = 1 LEFT JOIN bookcase F ON A.idQuote = F.reference AND F.keyFileType = 45 LEFT JOIN files G ON F.idBook = G.idBook AND G.enabled = 1",
      rows: "CONCAT(A.numberQuote,'/',A.yearQuote) AS folio, A.object, CONCAT(B.name,' ',B.pName,' ',B.mName) AS fullNameSol, CONCAT(C.name,' ',C.pName,' ',C.mName) AS fullNameEnc,A.created, A.dateSend, A.notes, A.idQuote,A.keyStat, E.src AS solicitud, G.src AS dictamen",
      conditions: `A.enabled=1 AND A.keystat IN (105, 106) AND A.idArea = ${idArea}`, //
      order: ordCol,
      records: itemsPerPage.toString(),
      page: (itemsPerPage * currentPage).toString(),
      search,
    };
    return params;
  }

  function selectQuote(el) {
    setSelectedQuote(el);
    setModalConfirm(true);
  }

  async function approveQuote() {
    const params = {
      action: "update",
      table: "quotes",
      rows: { keyStat: 106, idStaffAproves: idStaff },
      condition: { idQuote: selectedQuote.idQuote },
    };
    setLoading(true);
    setMsgLoader("Aprobando solicitud...");
    const res = await doRequest("receiver/receiver.php", params, false);
    if (res.length > 0) {
      setMsgLoader("Generando reporte...");
      let blobPdf = await generateReport();
      if (blobPdf !== "" && typeof blobPdf === "object") {
        const uploaded = await uploadFile(
          selectedQuote.idQuote,
          "saveFileReplace",
          45,
          blobPdf,
          false
        );
        if (uploaded) {
          setRefresh(true);
          toast(
            <Notification
              type="agrega_exito"
              backMessage="Solicitud Aprobada"
              withIcon
            />,
            { closeButton: false }
          );
        }
      }
    }
    setLoading(false);
  }

  const generateReport = async () => {
    let params = {
      action: "multiselect",
      table:
        "quotes A LEFT JOIN quotesdetail B USING(idQuote) LEFT JOIN furnitures C USING (idFurniture) LEFT JOIN services D USING (idService) LEFT JOIN messureunits E USING (keyUnit)",
      rows: "ISNULL(B.idFurniture) AS isService,	IF(ISNULL(B.idFurniture), D.nameService, C.nameFurniture) AS descripcion, E.nameUnit, B.amount, B.total",
      conditions: `A.idQuote = ${selectedQuote.idQuote} AND A.enabled = 1 AND B.enabled = 1`,
    };
    setMsgLoader("Obteniendo información...");
    const productos = await doRequest("receiver/receiver.php", params, false);
    if (productos.length > 0) {
      params = {
        action: "multiselect",
        table:
          "quotes A LEFT JOIN quotesdetail B USING(idQuote) LEFT JOIN staff F ON A.idStaffRequire = F.idStaff LEFT JOIN staff G ON A.idStaffRevision = G.idStaff LEFT JOIN staff H ON A.idStaffAproves = H.idStaff ",
        rows: "CONCAT(A.numberQuote,'/',A.yearQuote) AS folio, SUM(B.total) AS total, A.object, CONCAT(F.name,' ',F.pName,' ',F.mName) AS fullNameSol,  CONCAT(G.name,' ',G.pName,' ',G.mName) AS fullNameEnc, CONCAT(H.name,' ',H.pName,' ',H.mName) AS fullNameAut",
        conditions: `A.idQuote = ${selectedQuote.idQuote} AND A.enabled = 1 AND B.enabled = 1`,
      };
      const cotizacion = await doRequest(
        "receiver/receiver.php",
        params,
        false
      );
      if (cotizacion.length > 0) {
        setMsgLoader("Generando documento...");
        const decimales = cotizacion[0].total.substring(
          cotizacion[0].total.indexOf(".") + 1
        );
        const numTt = parseInt(cotizacion[0].total);
        const numbertext = await numberSpelling(numTt, false);
        if (numbertext !== "") {
          const intText = numbertext.split(" punto")[0];
          const infoRepo = {
            productos: productos,
            cotizacion: cotizacion[0],
            numbertext: `${intText} ${decimales}/100 M.N`,
          };
          try {
            const blobPdf = await pdf(solicitudCotizacion(infoRepo)).toBlob();
            return blobPdf;
          } catch (e) {
            toast(
              <Notification
                type={"modifica_error"}
                backMessage="Ocurrio un problema al generar el reporte"
                withIcon
              />,
              { closeButton: false }
            );
          }
        }
      }
    }
    return "";
  };

  return (
    <Widget className="widget-p-md">
      <div className="mb-4 mx-3">
        <h3>Autorización de Cotizaciones</h3>
      </div>

      {permissions.CON ? (
        <Datatable
          headers={headers}
          columns={columns}
          columnDefs={columnDefs}
          petition={getData}
          control="back"
          stateRefresh={[refresh, setRefresh]}
          searching={true}
          order={{col: 4, opt: "desc"}}
        />
      ) : (
        <div className="text-center p-4">
          No tienes permitido ver los registros de esta seccion
        </div>
      )}

      <ModalViewFile
        modal={modalFile}
        setModal={setModalFile}
        backdrop={true}
        keyboard={true}
        btnClose={true}
        fileLink={linkFile}
      />

      <ModalConfirmation
        modal={modalConfirm}
        setModal={setModalConfirm}
        editar={() => approveQuote()}
        isEdit={true}
        buttonOkMsg="Aceptar"
      >
        <div className="d-flex justify-content-center">
          <div className="text-center">
            <p style={{ fontSize: "20px" }} className="mb-2">
              ¿Desea aprobar la solicitud con el folio{" "}
              <span className="font-weight-bold">
                {selectedQuote && selectedQuote.folio}
              </span>
              ?
            </p>
          </div>
        </div>
      </ModalConfirmation>

      <FullScreenLoader show={loading} message={msgLoader} />
    </Widget>
  );
}

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { Col, Row, Button } from "reactstrap";

import Loader from "../../../components/Loader/Loader";
import { peticionesReceiver } from "../../../helpers/peticionesReceiver";
import Notification from "../../../components/Notification/Notification";
import ModalConfirmation from "../../../components/Modal/ModalConfirmation";
import { TextInput,SelectTypeHeadSingle } from "../../../components/GenericInputsFormik";
import { REGEX_NUMBER } from "../../../helpers/regex";

const FormRubros = ({ crear, data, editar }) => {

  const defaultForm = {
    idIncome: '',
    keyIncomeSubType: 0,
    idFinancialSources: 0,
    nameIncome: '',
    incomeKey: '',
    keyIncomeType: 0
  };

  const history = useHistory();
  const API = peticionesReceiver();

  const [modal, setModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true)
  const [incomesTypes, setIncomesTypes] = useState([])
  const [financialSources, setFinancialSources] = useState([])
  const [incomesSubTypes, setIncomesSubTypes] = useState([])
  const [allIncomesSubTypes, setAllIncomesSubTypes] = useState([])
  const [availableTypes, setAvalibleTypes] = useState(true)

  useEffect(() => {
    if(data) {
      setFieldValue('idIncome', data[0]);
      setFieldValue('incomeKey', data[1]);
      setFieldValue('nameIncome', data[5]);
    }
    getAllData()
  }, [])

  useEffect(() => {
    if(data && incomesTypes.length > 0 ) {
      const findType = incomesTypes.find(it => it.nameIncomeType === data[2])
      setFieldValue('keyIncomeType', findType.keyIncomeType);
    }
  }, [data && incomesTypes.length > 0])

  useEffect(() => {
    if(data && financialSources.length > 0 ) {
      const findFinancial = financialSources.find(it => it.nameFinancialSource === data[4])
      setFieldValue('idFinancialSources', findFinancial.idFinancialSource);

    }
  }, [data && financialSources.length > 0])

  useEffect(() => {
    if(data && allIncomesSubTypes.length > 0 ) {
      const findSubType = allIncomesSubTypes.find(it => it.nameIncombeSubType === data[3])
      setFieldValue("keyIncomeSubType", findSubType.keyIncomeSubType);
      setAvalibleTypes(false)
    }
  }, [data && allIncomesSubTypes.length > 0])


  const getAllData = async() => {
    const result = await Promise.all([
      getData("income_types",setIncomesTypes,"nameIncomeType"),
      getData("financialsources",setFinancialSources,"nameFinancialSource"),
      getData("income_subtypes",setAllIncomesSubTypes,"nameIncombeSubType"),
    ])
    if (result.some(it => it === true)) {
      setIsLoading(false)
    }
  }
  
  const getData = async(table,functionUpdated,column) => {
    const params = {
      action: "select",
      table: table,
      condition: { enabled: 1 },
      order: `${column} ASC`,
    };
    const res = await API.peticion(params)
    if(res.status === 200 && res.data.code === "200"){
      if(table === 'income_subtypes') setIncomesSubTypes(res.data.data)
      functionUpdated(res.data.data)
      return true
    }
    else {
      toast(<Notification type={"consultar_error"} withIcon />);
      return false
    }
  };

  const FormSchema = Yup.object().shape({
    keyIncomeSubType: Yup.number()
      .moreThan(0,"Favor de seleccionar un tipo de rubro")
      .required("Favor de seleccionar un tipo de rubro"),
    nameIncome: Yup.string()
      .required("Favor de escribir una descripción"),
    idFinancialSources: Yup.number()
      .moreThan(0,"Favor de seleccionar una Fuente de financiamiento")
      .required("Favor de seleccionar un tipo de rubro"),
    keyIncomeType: Yup.number()
      .moreThan(0,"Favor de seleccionar un rubro")
      .required("Favor de seleccionar un rubro"),
    incomeKey: Yup.string()
      .required("Favor de escribir una calve de ingreso"),
  });

  const goBack = (reset) =>{
    reset()
    history.goBack()
  }

  const OnChangeSelect = (event) => {
    if(event.target.name === 'keyIncomeType'){
      const filter = allIncomesSubTypes.filter(it => it.keyIncomeType === event.target.value)
      setIncomesSubTypes(filter)
      setAvalibleTypes(false)
    }
    setFieldValue(event.target.name, event.target.value);
  };

  const onChange = event => {
    const { name,value } = event.target
    if(name === 'incomeKey'){
      if(REGEX_NUMBER.test(value)){
        setFieldValue([name], value);
      }
    }
    else{
      setFieldValue([name], value);
    }
  }

  const saveIncome = async() => {
    setIsLoading(true)
    const info = {
      ...values,
      incomeKey: Number(values.incomeKey)
    }
    delete info.keyIncomeType
    try{
      const response = data ? await editar(info) : await crear(info)

      if(response.success){
        toast(<Notification type={data ? "modifica_exito" :"agrega_exito"} withIcon />);
        localStorage.removeItem('dataContent')
        goBack(resetForm)
      }
      else {
        toast(<Notification type={data ? "modifica_error" : "agrega_error"} backMessage={response.message} withIcon />);
        setIsLoading(false)
      }
    }
    catch(error){
      toast(<Notification type={"consultar_servidor_error"} withIcon />);
      setIsLoading(false)
    }
  }

  const { handleSubmit, values, handleBlur, errors, touched, setFieldValue,resetForm } =
    useFormik({
      initialValues: defaultForm,
      onSubmit: () => setModal(true),
      validationSchema: FormSchema,
  });

  return (
    <>
      {
        isLoading ? <Loader/>
        :
        <form onSubmit={handleSubmit} className="mt-4" noValidate>
          <ModalConfirmation
            modalTitle={data ? "Editar" : "Crear"}
            modal={modal}
            setModal={setModal}
            crear={saveIncome}
            editar={saveIncome}
            isEdit={data ? true : false}
            values={values}
          >
            {data ? (
              <div className="d-flex justify-content-center">
                <h6>¿Está seguro de editar el registro?</h6>
              </div>
            ) : (
              <div className="d-flex justify-content-center">
                <h6>¿Desea continuar con el registro?</h6>
              </div>
            )}
          </ModalConfirmation>

          <Row className="mt-2">

            <Col xs={12} md={6}>
              <SelectTypeHeadSingle
                label="Rubro"
                isRequired
                optionsArray={incomesTypes}
                inputName="keyIncomeType"
                onChangeMethod={OnChangeSelect}
                onBlurMethod={handleBlur}
                touched={touched.keyIncomeType}
                errors={errors.keyIncomeType}
                optionValue="keyIncomeType"
                optionName="nameIncomeType"
                value={values.keyIncomeType}
                defaultOption="Seleccione un rubro"
              />
            </Col>

            <Col xs={12} md={6}>
              <SelectTypeHeadSingle
                label="Tipo de Rubro"
                isRequired
                optionsArray={incomesSubTypes}
                inputName="keyIncomeSubType"
                onChangeMethod={OnChangeSelect}
                onBlurMethod={handleBlur}
                touched={touched.keyIncomeSubType}
                errors={errors.keyIncomeSubType}
                optionValue="keyIncomeSubType"
                optionName="nameIncombeSubType"
                value={values.keyIncomeSubType}
                isDisabled = {availableTypes}
                defaultOption = {"Seleccione un tipo de rubro"}
              />
            </Col>

            <Col xs={12} md={6}>
              <TextInput
                label="Denominación"
                inputType="text"
                inputName="nameIncome"
                onChangeMethod={onChange}
                onBlurMethod={handleBlur}
                value={values.nameIncome}
                isRequired={true}
                touched={touched.nameIncome}
                errors={errors.nameIncome}
                isMax={50}
              />
            </Col>

            <Col xs={12} md={6}>
              <TextInput
                label="Clave Ingreso"
                inputType="text"
                inputName="incomeKey"
                onChangeMethod={onChange}
                onBlurMethod={handleBlur}
                value={values.incomeKey}
                isRequired={true}
                touched={touched.incomeKey}
                errors={errors.incomeKey}
                isMax={7}
              />
            </Col>

            <Col xs={12} md={6}>
              <SelectTypeHeadSingle
                label="Fuentes de financiamiento"
                isRequired
                optionsArray={financialSources}
                inputName="idFinancialSources"
                onChangeMethod={OnChangeSelect}
                onBlurMethod={handleBlur}
                touched={touched.idFinancialSources}
                errors={errors.idFinancialSources}
                optionValue="idFinancialSource"
                optionName="nameFinancialSource"
                value={values.idFinancialSources}
                defaultOption="Seleccione una fuente de financiamiento"
              />
            </Col>
            
          </Row>

          <div className="row mt-3">
            <div className="col-sm-6 order-2 order-sm-1 text-center text-sm-left mb-4">
              <Button color="danger" type="reset" onClick={() => goBack(resetForm)} > Cancelar </Button>
            </div>

            <div className="col-sm-6 order-1 order-sm-2 text-center text-sm-right mb-4">
              <Button color="success" type="submit"> Guardar </Button>
            </div>
            
          </div>

        </form>
      }
    </>
  );
};

export default FormRubros;
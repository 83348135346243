import { pdf } from "@react-pdf/renderer";
import { doRequest, uploadFile } from "../../../helpers/requests";
import fumpPdf from "../../../pages/personal/expediente-personal/pdf/fumpPdf";
import { toast } from "react-toastify";
import Notification from "../../../components/Notification/Notification";
import axios from "axios";
import { PDFDocument, StandardFonts, degrees, grayscale, rgb } from "pdf-lib";
import { saveAs } from "file-saver";
import QRCode from "qrcode.react";

/**
 * Generar reporte FUMP
 * @param {string | number} idJobStaff - Id de la plaza del empleado
 * @param {boolean} alerts - (Opcional) False para ocultar la alert ade error
**/
export async function getFump(idJobStaff, alerts) {
    // setFsLoaderMessage("Generando reporte de movimiento");
    const params = {
        action: "getDatosFUMP",
        rows: { idJobStaff: idJobStaff }
    }
    const data = await doRequest("app/facades/jobs/jobsF.php", params, false);
    if (data.length > 0) {
        let blobPdf = undefined;
        try {
            blobPdf = await pdf(fumpPdf(data[0], generatedQrImage())).toBlob();
            if (blobPdf != undefined && typeof blobPdf === "object") {
                const res = await uploadFile(idJobStaff, "saveFileReplace", 18, blobPdf, false, false);
                return res;
            }
        } catch (e) {
            if(alerts !== false) toast(
                <Notification
                    type={"modifica_error"}
                    backMessage="Ocurrio un problema al generar el reporte"
                    withIcon
                />,
                { closeButton: false }
            );
        }
    }
    return false;
};

export function generatedQrImage() {
  const canvas = document.getElementById("123456");
  const pngUrl = canvas.toDataURL("image/png").replace("image/png", "image/octet-stream");
  return pngUrl;
};

/**
 * Cancelar reporte FUMP
 * Consulta la ultima plaza del empleado y cancela su fump
 * @param {string | number} idStaff - Id del emepleado
**/
export async function cancelFUMP(idStaff){
    /*
     * 1. Consultar la ultima plaza activa y su fump
     * 2. obtener el pdf
     * 3. editar el pdf
     * 4. crear la nueva plaza?
     * 4.1 crear el nuevo fump
    */
    const params = {
        action: "multiselect",
        table: "jobstaff A INNER JOIN bookcase B ON B.reference = A.idJobStaff AND B.keyFileType = 18 INNER JOIN files C ON C.idBook = B.idBook",
        rows: "A.idJobStaff, B.idBook, C.idFile, C.src",
        conditions: `A.idStaff = ${idStaff} AND A.valid = 1 AND A.enabled = 1`
    }
    try {
        const data = await doRequest("receiver/receiver.php", params, false, false);
        if(data.length < 1) throw new Error("No se identificó la plaza");
        const infoFump = data[0];
        // Get FUMP
        let res = await axios.get(infoFump.src, { responseType: "arraybuffer" });
        if(!res) throw new Error("No fue posible obtener el archivo FUMP");
        const oldFUMP = await PDFDocument.load(res.data);

        // Add watermark
        const helveticaFont = await oldFUMP.embedFont(StandardFonts.Helvetica);
        const pages = oldFUMP.getPages();
        pages.forEach(page => {
            addWatermark(page, helveticaFont);
        });

        const pdfBytes = await oldFUMP.save();
        const bytes = new Uint8Array(pdfBytes);
        const blobFile = new Blob([bytes], { type: "application/pdf" });
        saveAs(blobFile, `marca de agua.pdf`);

        // const upload = await uploadFile(infoFump.idJobStaff, "saveFileReplace", 18, blobFile, false, false);
        // if(!upload) throw new Error("No fue posible actualizar el archivo FUMP");
        
    } catch (error) {
        console.log(error);
        toast(
            <Notification
            type={"consultar_error"}
            backMessage={ error }
            withIcon
            />,
            { closeButton: false }
        );
    }    
}

function addWatermark(page, helveticaFont){
    const { width, height } = page.getSize();
    const rows = height / 60;
    for (let i = 15; i < height; i+=60) {
        page.drawText('Sin efectos legales', {
            x: 0,
            y: i,
            size: 35,
            font: helveticaFont,
            color: rgb(0.24, 0.30, 1),
            opacity: 0.5
        });
        page.drawText('Sin efectos legales', {
            x: width / 2,
            y: i,
            size: 35,
            font: helveticaFont,
            color: rgb(0.24, 0.30, 1),
            opacity: 0.5
        });
    }
}

export function QRImageComponet({ route }){
    return(
        <div style={{ display: "none" }}>
          <QRCode
            id="123456"
            value={route || "https://fgjem.edomex.gob.mx/"}
            size={290}
            level="H"
            includeMargin={true}
          />
        </div>
    )
}
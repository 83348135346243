import React from "react";
import { useEffect, useState } from "react";
import { Col, Row, Button } from "reactstrap";
import { generatedPdfProjects } from "../pdf/PdfGastos";
import ModalReports from "../../../../components/Modal/ModalReports";

const ButtonPdfGastosComponent = (props) => {
  const { proyect } = props;

  const [modalReport, setModalReport] = useState(false);
  const [pdfConfig, setPdfConfig] = useState("");

  useEffect(() => {
    setPdfConfig({ project: proyect });
  }, []);

  return (
    <div>
      <Row className="d-flex justify-content-around align-items-center">
        <Button color="success" type="submit" className="mb-3" onClick={() => setModalReport(true)}>
          Generar Reporte
        </Button>
      </Row>

      <ModalReports
        modal={modalReport}
        setModal={setModalReport}
        title={"Cedula analitica de gasto y justificacion"}
        cabeceras={""}
        backdrop={"static"}
        keyboard={false}
        report={"rep"}
        pdfConfig={pdfConfig}
        generatePdfMethod={{
          method: generatedPdfProjects,
          type: "general",
        }}
        records={1000}
      />
    </div>
  );
};

export default ButtonPdfGastosComponent;

import s from "./TableErros.module.scss";
import differenceInDays from 'date-fns/differenceInDays';
import { isPositiveInt, isYear, notVoid } from "./validations";
import getDaysInMonth from 'date-fns/getDaysInMonth'

export const columns = [
  { // 1 - percepciones / dedudcciones
    csv: [
      "CVE EMPLEADO",
      "NOMBRE",
      "ID CONCEPTO",
      "PERIODO INCIAL",
      "ANIO INICIAL",
      "PERIODO FINAL",
      "ANIO FINAL",
      "IMPORTE",
      "PARCIALIDADES",
      "IMPORTE QUINCENAL",
      "FECHA ALTA",
      "NOTAS",
    ],
    bd: [
      "STAFFNUMBER",
      "STAFFNAME",
      "UNDER",
      "INITIALPERIOD",
      "INITIALYEAR",
      "FINALPERIOD",
      "FINALYEAR",
      "TOTALIMPORT",
      "PARTIALITIES",
      "AMOUNTBIWEEKLY",
      "CREATED",
      "NOTES",
    ],
    action: "loadExpenses",
  },
  { // 2 - prestamos
    csv: [
      "CVE EMP",
      "NOMBRE EMPLEADO",
      "CONCEPTO",
      "TIPO DE PRESTAMO",
      "INSTITUCION",
      "POLIZA",
      "OFICIO",
      "INICIO",
      "TERMINO",
      "TOTAL A DESCONTAR",
      "DESCUENTO QNAL",
      "PARCIALIDADES",
      "FECHA ALTA",
      "OBSERVACIONES",
    ],
    bd: [
      "STAFFNUMBER",
      "STAFFNAME",
      "UNDER",
      "KEYTYPELOAN",
      "INSTITUTION",
      "POLIZA",
      "OFICIO",
      "INICIO",
      "TERMINO",
      "TOTALDISCOUNT",
      "AMOUNTBIWEEKLY",
      "NOPAYS",
      "CREATED",
      "NOTES",
    ],
    action: "loadLoans",
    empty: ["KEYTYPELOAN"]
  },
  { //3 - seguros
    csv: [
      "CVE EMPLEADO",
      "NOMBRE EMPLEADO",
      "CONCEPTO",
      "ID TIPO SEGURO",
      "ID INSTITUCION",
      "POLIZA",
      "CONTRATO",
      "INICIO SEGURO",
      // "TERMINO SEGURO",
      // "IMPORTE A DESCONTAR",
      // "PARCIALIDADES",
      "IMPORTE QUINCENAL",
      "FECHA ALTA",
      "OBSERVACIONES",
    ],
    bd: [
      "STAFFNUMBER",
      "STAFFNAME",
      "UNDER",
      "KEYTYPEINSURANCE",
      "INSTITUTION",
      "POLIZA",
      "OFICIO",
      "INICIO",
      // "TERMINO",
      // "TOTALDISCOUNT",
      // "NOPAYS",
      "AMOUNTBIWEEKLY",
      "CREATED",
      "NOTES",
    ],
    action: "loadSecures",
    empty: ["KEYTYPEINSURANCE"]
  },
  { //4 - inasistencias
    csv: [
      "CVE EMPLEADO",
      "ID CONCEPTO",
      "PERIODO APLICA",
      "ANIO APLICA",
      "PERIODO INCIAL",
      "ANIO INICIAL",
      "PERIODO FINAL", ///6
      "ANIO FINAL", ///7
      "IMPORTE",
      "PARCIALIDADES",
      "IMPORTE QUINCENAL", //10
      "NOTAS",
      "NO DE DIAS",
      "ID TIPO INASISTENCIA",
      "FECHA Y/O TIEMPO",
    ],
    bd: [
      "STAFFNUMBER",
      "UNDER",
      "ABCENSEPERIOD",
      "ABCENSEYEAR",
      "INITIALPERIOD",
      "INITIALYEAR",
      "FINALPERIOD", ///6
      "FINALYEAR", ///7
      "TOTALIMPORT",
      "PARTIALITIES",
      "AMOUNTBIWEEKLY", //10
      "NOTES",
      "NODAYS",
      "I1", //13
      "T1",
    ],
    action: "loadAbcenses",
    split: 13,
    sonsH: ["Tipo", "Fecha"],
    sonsK: ["I", "T"],
    colDes: "Descuento/Día"
  },
  { //5 - estancias
    csv: [
      "CVE EMPLEADO",
      "NOMBRE EMPLEADO",
      "CONCEPTO",
      "NO HIJOS",
      "OFICIO",
      "INICIO ESTANCIA",
      "TERMINO ESTANCIA",
      "IMPORTE A DESCONTAR",
      "PARCIALIDADES",
      "IMPORTE QUINCENAL",
      "FECHA ALTA",
      "OBSERVACIONES",
    ],
    bd: [
      "STAFFNUMBER",
      "STAFFNAME",
      "UNDER",
      "NOCHILDREN",
      "POLIZA",
      "INICIO",
      "TERMINO",
      "TOTALDISCOUNT",
      "NOPAYS",
      "AMOUNTBIWEEKLY",
      "CREATED",
      "NOTES",
    ],
    action: "loadChildcareservice",
  },
  { // 6 - pensiones
    csv: [
      "CVE EMPLEADO",
      "NOMBRE EMPLEADO",
      "CONCEPTO",
      "OFICIO",
      "FECHA OFICIO",
      "INICIO PENSION",
      "TERMINO PERNSION",
      "NOMBRE BENEFICIADO",
      "PATERNO BENEFICIADO",
      "MATERNO BENEFICIADO",
      "TIPO DE PAGO",
      "TIPO DE CUENTA",
      "BANCO",
      "CUENTA/CLABE",
      "TIPO PENSION",
      "IMPORTE/PORCENTAJE",
      "FECHA ALTA",
      "OBSERVACIONES",
    ],
    bd: [
      "STAFFNUMBER",
      "STAFFNAME",
      "UNDER",
      "POLIZA",
      "DATEPOLIZA",
      "INICIO",
      "TERMINO",
      "NAME",
      "PNAME",
      "MNAME",
      "PAYMENTTYPE",
      "ACCOUNTTYPE",
      "BANK",
      "ACCOUNT",
      "TYPEPENSION",
      "AMOUNT",
      "CREATED",
      "NOTES",
    ],
    action: "loadPensions",
    empty: ["ACCOUNTTYPE", "BANK", "ACCOUNT"]
  },
  { // 7 - licencias
    csv: [
      "CVE EMPLEADO",
      "NOMBRE",
      "ID CONCEPTO",
      "TIPO DE LICENCIA",
      "OFICIO",
      "FECHA AUTORIZACION",
      "INICIO LICENCIA",
      "TERMINO LICENCIA",
      "PERIODO INCIAL",
      "ANIO INICIAL",
      "PERIODO FINAL",
      "ANIO FINAL",
      "IMPORTE",
      "PARCIALIDADES",
      "IMPORTE QUINCENAL",
      "FECHA ALTA",
      "NOTAS",
      "INICIO CON GOCE",
      "TERMINO CON GOCE",
      "INICIO MEDIO GOCE",
      "TERMINO MEDIO GOCE",
      "INICIO SIN GOCE",
      "TERMINO SIN GOCE",
    ],
    bd: [
      "STAFFNUMBER",
      "STAFFNAME",
      "UNDER",
      "TYPE",
      "POLICY",
      "POLICYDATE",
      "INICIO",
      "TERMINO",
      "INITIALPERIOD",
      "INITIALYEAR",
      "FINALPERIOD",
      "FINALYEAR",
      "TOTALIMPORT",
      "PARTIALITIES",
      "AMOUNTBIWEEKLY",
      "CREATED",
      "NOTES",
      "INICIOCON",
      "TERMINOCON",
      "INICIOMEDIO",
      "TERMINOMEDIO",
      "INICIOSIN",
      "TERMINOSIN",
    ],
    action: "loadLeaves",
    empty: ["POLICYDATE", "INICIOCON", "TERMINOCON", "INICIOMEDIO", "TERMINOMEDIO", "INICIOSIN", "TERMINOSIN"]
  },
  { // 8 - incapacidades
    csv: [
      "CVE EMPLEADO",
      "NOMBRE",
      "ID CONCEPTO",
      "TIPO DE INCAPACIDAD",
      "FOLIO INCAPACIDAD",
      "OFICIO",
      "UNIDAD MEDICA",
      "DOCTOR",
      "ESPECIALIDAD",
      "DIAGNOSTICO",
      "INICIO INCAPACIDAD",
      "TERMINO INCAPACIDAD",
      "FECHA ALTA", 
      "NOTAS",

      "ID TIPO APLICACIÓN", 
      "PERIODO", 
      "ANIO", 
      "NO DIAS",
      /*
        "PERIODO INCIAL",
        "ANIO INICIAL",
        "PERIODO FINAL",
        "ANIO FINAL",
        "IMPORTE",
        "PARCIALIDADES",
        "IMPORTE QUINCENAL",
        "FECHA ALTA",
        "NOTAS",
        "INICIO CON GOCE",
        "TERMINO CON GOCE",
        "INICIO MEDIO GOCE",
        "TERMINO MEDIO GOCE",
        "INICIO SIN GOCE",
        "TERMINO SIN GOCE",
      */
    ],
    bd: [
      "STAFFNUMBER",
      "STAFFNAME",
      "UNDER",
      "TYPE",
      "FOLIO",
      "POLICY", // 5
      "MEDICAL",
      "DOCTOR",
      "ESPECIALIST",
      "DIAGNOSTIC",
      "INICIO", // 10
      "TERMINO",
      "CREATED",
      "NOTES",

      "TA1",
      "P1",
      "A1",
      "ND1"
      /*
        "INITIALPERIOD",
        "INITIALYEAR",
        "FINALPERIOD",
        "FINALYEAR",
        "TOTALIMPORT",
        "PARTIALITIES",
        "AMOUNTBIWEEKLY",
        "CREATED",
        "NOTES",
        "INICIOCON",
        "TERMINOCON",
        "INICIOMEDIO",
        "TERMINOMEDIO",
        "INICIOSIN",
        "TERMINOSIN",
      */
    ],
    action: "loadIncapacitys",
    split: 14,
    sonsH: ["ID TIPO APLICACIÓN", "PERIODO", "ANIO", "NO DIAS"],
    sonsK: ["TA", "P", "A", "ND"],
    colDes: "Descuentos",
    empty: ["FOLIO","POLICY","MEDICAL","DOCTOR","ESPECIALIST","DIAGNOSTIC","CREATED"]
  },
  { // 9 - multas
    csv: [
      "CVE EMPLEADO",
      "NOMBRE",
      "ID CONCEPTO",
      "TIPO DE SANCION",
      "OFICIO",
      // "INICIO SANCION",
      "JUZGADO",
      "CAUSA",
      "FONDO AUXILIAR",
      // "TERMINO SANCION",
      "PERIODO INCIAL",
      "ANIO INICIAL",
      "PERIODO FINAL",
      "ANIO FINAL",
      "IMPORTE",
      "PARCIALIDADES",
      "IMPORTE QUINCENAL",
      "FECHA ALTA",
      "NOTAS",
      // "INICIO CON GOCE",
      // "TERMINO CON GOCE",
      // "INICIO MEDIO GOCE",
      // "TERMINO MEDIO GOCE",
      // "INICIO SIN GOCE",
      // "TERMINO SIN GOCE",
      "UMAS"
    ],
    bd: [
      "STAFFNUMBER",
      "STAFFNAME",
      "UNDER",
      "TYPE",
      "POLICY",
      // "INICIO",
      "JUDGE",
      "EXPNUMBER",
      "AUXILIARFUND",
      // "TERMINO",
      "INITIALPERIOD",
      "INITIALYEAR",
      "FINALPERIOD",
      "FINALYEAR",
      "TOTALIMPORT",
      "PARTIALITIES",
      "AMOUNTBIWEEKLY",
      "CREATED",
      "NOTES",
      // "INICIOCON",
      // "TERMINOCON",
      // "INICIOMEDIO",
      // "TERMINOMEDIO",
      // "INICIOSIN",
      // "TERMINOSIN",
      "UMAS"
    ],
    action: "loadPenaltys",
    empty: ["CREATED", "INICIOCON", "TERMINOCON", "INICIOMEDIO", "TERMINOMEDIO", "INICIOSIN", "TERMINOSIN"]
  },
];

export const namesKeys = {
  conceptos: ["unders", "idUnder", "nameUnderC", "conceptos"], ////
  prestamos: ["types_loan", "keyTypeLoan", "nameTypeLoan", "prestamos"], ////
  instituciones: [
    ////
    "institutions",
    "keyInstitution",
    "nameInstitution",
    "instituciones",
  ],
  seguros: [
    ////
    "types_insurance",
    "keyTypeInsurance",
    "nameTypeInsurance",
    "seguros",
    // {idModule: 33} // Condición extra
  ],
  inasistencias: [
    ////
    "types_abcenses",
    "keyTypeAbcense",
    "nameTypeAbcense",
    "inasistencias",
  ],
  pagos: ["paymenttypes", "keyPaymentType", "namePaymentType", "pagos"], ////
  cuentas: ["accounttypes", "keyAccountType", "nameAccountType", "cuentas"], ////
  bancos: ["banks", "keyBank", "nameBank", "bancos"], ////
  pensiones: [
    ////
    "types_pensions",
    "keyTypePension",
    "nameTypePension",
    "pensiones",
  ],
  licencias: ["types_leaves", "keyTypeLeave", "nameTypeLeave", "licencias"],
  incapacidades: [
    "types_incapacitys",
    "keyTypeIncapacity",
    "nameTypeIncapacity",
    "incapacidades",
  ],
  multas: ["types_penaltys", "keyTypePenalty", "namePenalty", "multas/sanciones"],
  descuentos: ["types_applicationsalary", "keyTypeApplicationSalary", "nameTypeApplicationSalary", "descuentos"]
};

export const sizes = [
  "NOMBRE",
  "NOMBRE EMPLEADO",
  "NOTAS",
  "OBSERVACIONES",
  "UNDER",
  "CONCEPTO",
  "ID CONCEPTO",
  "TIPO DE CUENTA",
  "TIPO DE CUENTA",
  "TIPO PENSION",
  "TIPO DE LICENCIA",
  "TIPO DE INCAPACIDAD",
  "TIPO DE PRESTAMO"
];

export function extractIncapacitiesCols(row, errores, tipos) {
  let rowCpy = { ...row };
  let hijos = [];
  const ttInca = (Object.values(rowCpy).length - 16) / 4;
  const ttDays = getTtDays(row.INICIO, row.TERMINO);
  const anios = {};
  let flg = 1;
  let canCalculate = 0;
  let lastVals = 0;
  for (let i = 0; i < ttInca; i++) {
    let auxRow = { TA: "", P: "", A: "", ND: "", TAS: "", PS: "", AS: "" , NDS: "" };
    canCalculate = 0;
    let tipo = rowCpy["TA"+flg];
    let keyA = "A"+flg;
    let keyP = "P"+flg;
    let valA = rowCpy[keyA];
    let valP = rowCpy[keyP];
    let keyND = "ND"+flg;
    let valND = rowCpy[keyND];
    // Valida tipo de incapacidad
    if (!notVoid(tipo)) {
        auxRow.TAS = s.trDangerBg;
        errores++;
    }else{
        let tipof = tipos.find((c) => +c.clave === +tipo);
        if (!tipof){
          auxRow.TA = "Descuento desconocido";
          auxRow.TAS = " text-danger font-weight-bold";
          errores++;
        } else{ 
          auxRow.TA = tipof.name;
          auxRow.TAS = " text-left";
        }
    }
    // Valid year
    if (!notVoid(valA)) {
      auxRow.AS = s.trDangerBg;
      canCalculate++;
    } else if(!isYear(rowCpy, keyA)){
      auxRow.AS = " text-danger font-weight-bold ";
      canCalculate++;
    }
    auxRow.A = valA;
    // Validar period
    if (!notVoid(valP)) {
      auxRow.PS = s.trDangerBg;
      canCalculate++;
    } else if(!isPositiveInt(rowCpy, keyP) && +valP < 25){
      auxRow.PS = " text-danger font-weight-bold ";
      canCalculate++;
    }
    auxRow.P = valP;
    // Validar dias
    if (!notVoid(valND)) {
      auxRow.NDS = s.trDangerBg;
      canCalculate++;
    } else if(!isPositiveInt(rowCpy, keyND)){
      auxRow.NDS = " text-danger font-weight-bold ";
      canCalculate++;
    }
    auxRow.ND = valND;
    const daysP = periodDays(+valP, +valA);
    // Validar Dias x periodo
    if(canCalculate < 1){ 
      if(+valND>daysP){
        auxRow.NDS = " text-danger font-weight-bold ";
        auxRow.PS = " text-danger font-weight-bold ";
        canCalculate++;
      }
    }
    // Validar tipo x periodo
    if(canCalculate < 1){
      if(!anios["A"+valA]) anios["A"+valA] = {}
      const cY = anios["A"+valA];
      if(!cY["P"+valP]){ 
        cY["P"+valP] = {
          tipos: [],
          maxD: daysP
        }
      }
      if(cY["P"+valP].tipos.includes(tipo)){
        auxRow.TAS = " text-danger text-left font-weight-bold " + s.trPrimaryBg;
        auxRow.AS = " text-danger font-weight-bold " + s.trPrimaryBg;
        auxRow.PS = " text-danger font-weight-bold " + s.trPrimaryBg;
        auxRow.NDS = " text-dangerfont-weight-bold " + s.trPrimaryBg;
        const org = hijos.find((h) => h.TA === auxRow.TA && h.A === valA && h.P === valP );
        if(org){
          org.TAS += ` ${s.trPrimaryBg}`;
          org.AS += ` ${s.trPrimaryBg}`;
          org.PS += ` ${s.trPrimaryBg}`;
          org.NDS += ` ${s.trPrimaryBg}`;
        }
        canCalculate++;
      } else cY["P"+valP].tipos.push(tipo);
    }
    errores += canCalculate;
    lastVals += canCalculate;
    hijos.push(auxRow);
    delete rowCpy[keyA];
    delete rowCpy[keyP];
    delete rowCpy["TA"+flg];
    delete rowCpy[keyND];
    flg++;
  }
  // Valida max dias x periodo
  if(lastVals < 1){
    Object.values(anios).forEach((a)=>{
      Object.keys(a).forEach((c)=>{
        const suma = hijos.reduce((acc, h)=> acc + (h.P === c[1] ? +h.ND : 0), 0);
        if(suma > a[c].maxD){
          hijos.forEach((h)=>{
            if(h.P === c[1]){
              h.NDS += ` text-danger font-weight-bold `;
            }
          });
          lastVals++;
        }
      });
    });
  }
  // Valida total dias
  if(lastVals < 1){
    const suma = hijos.reduce((acc, h)=> acc + +h.ND, 0);
    if(suma !== ttDays){
      hijos.forEach((h)=>{
        h.NDS += ` text-danger font-weight-bold `;
      });
      rowCpy.cols.INICIO += ` text-danger font-weight-bold `;
      rowCpy.cols.TERMINO += ` text-danger font-weight-bold `;
    }
  }
  errores += lastVals;
  rowCpy.sons = hijos;
  return [rowCpy, errores];
};

function getTtDays(i, t){
  const s = i.split("/").reverse();
  const e = t.split("/").reverse();
  const sD = new Date(s);
  const eD = new Date(e);
  return differenceInDays(eD, sD) + 1;
}

export function clearKeys(arr, selectedF){
  const colsBase = columns[selectedF].split;
  const baseCols = columns[selectedF].bd.length;
  const arrN = arr.map((el, ix) => {
    let nEl = { ...el };
    if (ix !== 0 && Object.values(el).length > colsBase) {
      const vals = Object.values(el);
      const keyss = Object.keys(el);
      const sonsk = columns[selectedF].sonsK;
      let flg = 2;
      for (let i = baseCols; i < keyss.length; i += (sonsk.length)) {
        const wew = flg;
        sonsk.forEach((k, ixsk)=>{
          nEl[`${k}${wew}`] = vals[i+ixsk];
          delete nEl[keyss[i+ixsk]];
        });
        flg++;
      }
    }
    return nEl;
  });
  return arrN;
}

function periodDays(period, year){
  if(!period) return 0;
  if(period % 2 !== 0) return 15;
  const month = ((period / 2) + (period % 2));
  return getDaysInMonth(new Date(year, month, 0)) - 15;
}

import React from "react";
import Widget from "../../../../components/Widget/Widget";
import FormMultas from "../FormMultas";
import { createPenalty } from "../functions";

export const CrearMulta = (props) => {

  return (
    <Widget className="widget-p-md">
        <h3>Crear tipo de sanción</h3>
        <FormMultas crear={createPenalty}/>
    </Widget>
  )
}

export default CrearMulta;
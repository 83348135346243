import React, { useState } from "react";
import TableComponentFiltro from "../../../components/TableComponent/TableComponentFiltro";

export const Comisiones = () => {
  const cabeceras = [
    "Id", //0
    "Tipo de movimiento bancario",
    "Tipo de impuesto",
    "Nombre de la comisión",
    "Porcentaje",
    "Editar",
    "Eliminar",
  ];
  const [parametros] = useState({
    action: "datatable",
    table: "kindbankmoves k INNER JOIN types_bankmoves b USING (keybankmove) INNER JOIN taxes t USING (keytax)",
    rows: "keykindmove, nameBankMove, nameTax, nameKindBankMove, tax, k.keyBankMove, k.keyTax", ///sin espacios y comas
    conditions: "k.enabled = 1",
    page: 0,
    records: 5,
    search: "",
    order: "",
  });

  const [filter] = useState([true, true, true, true, true, false, false]);

  return (
    <div>
      <TableComponentFiltro
        titulo="Tipos de Comisiones"
        cabecerasTabla={cabeceras}
        filtro={filter}
        rutaCrear="/template/comisiones/crear"
        rutaEditar="/template/comisiones/editar"
        parametros={parametros}
      />
    </div>
  );
};
export default Comisiones;

import { useEffect, useState } from "react";
import Datatable from "../../../components/Datatable/Datatable";
import { doRequest, doRequestSaveRes } from "../../../helpers/requests";
import { Button } from "reactstrap";
import ModalConfirmation from "../../../components/Modal/ModalConfirmation";
import FullFormLoader from "../../../components/Loader/FullFormLoader";
import { NumberInput } from "../../../components/GenericInputsFormik/NumberInput";
import { toast } from "react-toastify";
import Notification from "../../../components/Notification/Notification";
import ModalReports from "../../../components/Modal/ModalReports";
import pdfAcucseRecibo from "./acuseRecibo";

export default function Detalle({ permissions, info, idStaff, idArea }) {
    const [refresh, setRefresh] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {}, []);

    return (
        <div className="mt-2">
            <TableOne
                refresh={refresh}
                setRefresh={setRefresh}
                info={info}
                idArea={idArea}
                setLoading={setLoading}
            />

            <TableTwo
                refresh={refresh}
                setRefresh={setRefresh}
                setLoading={setLoading}
                info={info}
                idStaff={idStaff}
                idArea={idArea}
                permissions={permissions}
            />

            <FullFormLoader show={loading} />
        </div>
    );
}

function TableOne({ refresh, setRefresh, info, idArea, setLoading }) {
    const [modalReport, setModalReport] = useState(false);
    const [infoReport, setInfoReport] = useState({});
    
    const headers = ["Entrada", "Acuse"];
    const columnDefs = [
        {
            targets: [0, 1, 2],
            className: "text-center",
        },
    ];

    const columns = [
        { data: "entrada" },
        {
            data: "idIncome",
            render: () => (
                <i className="fa fa-file-pdf-o text-danger cursor-pointer" onClick={()=>showReport()}/>
            ),
        },
    ];

    async function getData(ordCol, itemsPerPage, currentPage, search) {
        const res = await doRequestSaveRes(
            "receiver/receiver.php",
            getParams(ordCol, itemsPerPage, currentPage, search),
            false,
            false
        );
        if (res.code === "200") {
            res.data = res.data.map((el, ix) => {
                return { ...el, ix: ix + 1 };
            });
            return res;
        }
        return null;
    }

    function getParams(ordCol, itemsPerPage, currentPage, search) {
        const params = {
            action: "datatable",
            table: "warehouseincomes A INNER JOIN warehouseincomereceptions B ON B.idIncome = A.idIncome INNER JOIN receptions C ON C.idReception = B.idReception INNER JOIN purchasewinners D ON D.idWinner = C.idWinner INNER JOIN providerquotes E ON E.idProviderQuote = D.idProviderQuote",
            rows: "CONCAT(A.numberIncome,'/',A.yearIncome) AS entrada, A.idIncome",
            conditions: `A.idPurchaseRequest = ${info.id} AND A.enabled = 1 AND B.enabled = 1 AND A.idAreaReceipt = ${idArea} AND E.idProvider = ${info.idProvider}`,
            order: ordCol,
            records: itemsPerPage.toString(),
            page: (itemsPerPage * currentPage).toString(),
            search,
            group: "A.idIncome"
        };
        return params;
    }

    async function showReport(){
        setLoading(true);
        const params = {
            action: "multiselect",
            table: "warehouseincomes A INNER JOIN warehouseincomereceptions B ON B.idIncome = A.idIncome INNER JOIN receptions C ON C.idReception = B.idReception INNER JOIN purchasewinners D ON D.idWinner = C.idWinner INNER JOIN providerquotes E ON E.idProviderQuote = D.idProviderQuote INNER JOIN areas F ON F.idArea = A.idAreaReceipt INNER JOIN staff G ON G.idStaff = A.idStaffReceive INNER JOIN providers H on H.idProvider= E.idProvider INNER JOIN quotesdetail I on I.idDetail = E.idDetail INNER JOIN services J on J.idService = I.idService",
            rows: "A.dateIncome, A.numberIncome, F.name AS nameArea, CONCAT_WS(' ',G.name,G.pName,G.mName) AS fullName, H.socialName, H.itin, H.telephone, H.email, J.nameService, C.quantity, B.incomeTotal, E.total",
            conditions: `A.idPurchaseRequest = ${info.id} AND A.enabled = 1 AND B.enabled = 1 AND A.idAreaReceipt = ${idArea} AND E.idProvider = ${info.idProvider}`,
            order: "nameService ASC",
            group: ""
        }
        const res = await doRequest("receiver/receiver.php", params, false);
        if(res.length > 0){
            setInfoReport({data: res, service: true});
            setLoading(false);
            setModalReport(true);
        }
    }

    return (
        <div className="mt-4">
            <div className="text-center bg-dark rounded-top">
                <h5 className="text-white py-1">Entradas registradas</h5>
            </div>
            <Datatable
                headers={headers}
                columns={columns}
                columnDefs={columnDefs}
                petition={getData}
                order={{ col: 0, opt: "asc" }}
                control="back"
                stateRefresh={[refresh, setRefresh]}
                searching={true}
                className="px-0 mt-4"
            />

        <ModalReports
            modal={modalReport}
            setModal={setModalReport}
            title={"Acuse de Recibo"}
            cabeceras={[]}
            report={infoReport}
            pdfConfig={{}}
            generatePdfMethod={{
              method: pdfAcucseRecibo,
              type: "general",
            }}
            records={1000}
          />
        </div>
    );
}

function TableTwo({ refresh, setRefresh, setLoading, info, idStaff, idArea, permissions }) {
    const [modal, setModal] = useState(false);
    const [selected, setSelected] = useState([]);
    const [showButton, setShowButton] = useState(false);

    const headers = [
        "Servicio",
        "Cantidad Solicitada",
        "Cantidad recibida",
        "Cantidad a recibir",
        "Seleccione",
    ];
    const columnDefs = [
        {
            targets: [1, 2],
            className: "text-right",
        },
        {
            orderable: false,
            targets: [3, 4],
        },
    ];

    const columns = [
        { data: "nameService" },
        { data: "amount" },
        { data: "quantity" },
        {
            data: null,
            render: (row) => {
                const ix = selected.findIndex(
                    (el) => el.idReception === row.idReception
                );
                const value = ix !== -1 ? selected[ix].value : "";
                const error =
                    ix !== -1 && selected[ix].selected && !selected[ix].value
                        ? "Ingrese una cantidad"
                        : "";
                return (
                    permissions.UPD && <div style={{ maxWidth: "180px" }} className="mx-auto">
                        <NumberInput
                            inputName={"c" + row.idReception}
                            onChangeMethod={({ target }) =>
                                onChange(row.idReception, target.value)
                            }
                            onBlurMethod={() => {}}
                            value={value}
                            touched={isChecked(row.idReception)}
                            errors={error}
                            isMin={1}
                        />
                    </div>
                );
            },
        },
        {
            data: null,
            render: (row) => (
                permissions.UPD && <div className="form-check checkbox checkbox-dark-gray d-flex justify-content-center">
                    <input
                        type="checkbox"
                        className="styled"
                        checked={isChecked(row.idReception)}
                        onChange={({ target }) => selectRow(row.idReception)}
                    />
                    <label />
                </div>
            ),
        },
    ];

    function isChecked(id) {
        const ix = selected.findIndex((el) => el.idReception === id);
        if (ix !== -1) return selected[ix].selected;
        return false;
    }

    function onChange(id, value) {
        const selectedCpy = [...selected];
        const ix = selected.findIndex((el) => el.idReception === id);
        if (ix === -1) {
            selectedCpy.push({
                idReception: id,
                selected: false,
                value: +value > 0 ? value : "",
                tocuhed: true,
                error: "",
            });
        } else {
            selectedCpy[ix].value = +value > 0 ? value : "";
        }
        setSelected(selectedCpy);
    }

    function selectRow(id) {
        const selectedCpy = [...selected];
        const ix = selected.findIndex((el) => el.idReception === id);
        if (ix === -1) {
            selectedCpy.push({ idReception: id, selected: true, value: "" });
        } else {
            selectedCpy[ix].selected = !selectedCpy[ix].selected;
        }
        setSelected(selectedCpy);
    }

    async function getData(ordCol, itemsPerPage, currentPage, search) {
        const res = await doRequestSaveRes(
            "receiver/receiver.php",
            getParams(ordCol, itemsPerPage, currentPage, search),
            false,
            false
        );
        if (res.code === "200") {
            if (+res.total > 0) setShowButton(true);
            else if (showButton) setShowButton(false);
            res.data = res.data.map((el, ix) => {
                return { ...el, ix: ix + 1 };
            });
            return res;
        }
        return null;
    }

    function getParams(ordCol, itemsPerPage, currentPage, search) {
        const params = {
            action: "datatable",
            table: "purchaserequests A INNER JOIN purchasewinners B on B.idPurchaseRequest = A.idPurchaseRequest INNER JOIN providerquotes C on C.idProviderQuote = B.idProviderQuote INNER JOIN quotesdetail D on D.idDetail = C.idDetail INNER JOIN services E on E.idService = D.idService INNER JOIN receptions F on F.idWinner = B.idWinner",
            rows: "E.nameService, D.amount, F.quantity, F.idReception",
            conditions: `A.enabled = 1 AND B.enabled = 1 AND  C.enabled = 1 AND  D.enabled = 1 AND F.enabled = 1 AND A.idArea = ${idArea} AND A.idPurchaseRequest = ${info.id} AND C.idProvider = ${info.idProvider} AND F.idReception NOT IN (SELECT A.idReception FROM warehouseincomereceptions A INNER JOIN warehouseincomes B USING(idIncome) WHERE B.idPurchaseRequest = ${info.id} AND B.idAreaReceipt = ${idArea} AND A.enabled = 1 AND B.enabled = 1)`,
            order: ordCol,
            records: itemsPerPage.toString(),
            page: (itemsPerPage * currentPage).toString(),
            search,
        };
        return params;
    }

    function showModal() {
        const sS = selected.filter((el) => el.selected);
        if (selected.length > 0 && sS.length > 0) {
            const sC = sS.filter((el) => el.value < 1);
            if (sC.length < 1) setModal(true);
            else {
                toast(
                    <Notification
                        type={"warning"}
                        backMessage="Llene toda la información necesaria"
                        withIcon
                    />,
                    { closeButton: false }
                );
            }
        } else {
            toast(
                <Notification
                    type={"warning"}
                    backMessage="Seleccione al menos un registro para guardar"
                    withIcon
                />,
                { closeButton: false }
            );
        }
    }

    async function confirm() {
        setLoading(true);
        const articles = selected.filter((el) => el.selected && +el.value > 0);
        const params = {
            action: "saveReception",
            idStaffReceive: idStaff,
            idAreaReceipt: idArea,
            idPurchaseRequest: info.id,
            service: "S",
            articles: articles.map((el) => {
                return { idReception: el.idReception, quantity: el.value };
            }),
        };
        const res = await doRequest(
            "app/facades/warehouse/warehouseF.php",
            params,
            true
        );
        if (res.length > 0) {
            setRefresh(true);
            setSelected([]);
        }
        setLoading(false);
    }

    return (
        <div className="mt-5">
            <div className="text-center bg-dark rounded-top">
                <h5 className="text-white py-1">Datos recepción</h5>
            </div>
            <Datatable
                headers={headers}
                columns={columns}
                columnDefs={columnDefs}
                petition={getData}
                order={{ col: 0, opt: "asc" }}
                control="back"
                stateRefresh={[refresh, setRefresh]}
                searching={true}
                className="px-0 mt-4"
            />
            {showButton && permissions.UPD && ( //
                <div className="mt-3 d-flex flex-column flex-md-row justify-content-center">
                    <Button color="info" onClick={() => showModal()}>
                        Guardar
                    </Button>
                </div>
            )}

            <ModalConfirmation
                modalTitle="Confirmar Recepción"
                modal={modal}
                crear={confirm}
                setModal={setModal}
            >
                <div className="text-center">
                    <h6>
                        ¿Está seguro de confirmar la recepción de los servicios?
                    </h6>
                    <p>No podrá deshacer esta acción</p>
                </div>
            </ModalConfirmation>
        </div>
    );
}

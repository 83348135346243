import { peticionesReceiver } from "../../../helpers/peticionesReceiver";
import Notification from "../../../components/Notification/Notification";
import { toast } from "react-toastify";
const API = peticionesReceiver();

export const agregar = async (sistema) => {
    
    const data = {
      action: "insert",
      table: "apparatus",
      rows: {
        nameSystem: sistema.nameSystem,
      },
      validate: [
        ["nameSystem"]
      ]
    };
    
    API.peticion(data).then((res) => { 
        if (res.status === 200 && res.data.code === "200") {
          toast(<Notification type={"agrega_exito"} withIcon />);
          window.location.href = "#/template/sistemas";
        } else {
          toast(
            <Notification
              type={"agrega_error"}
              backMessage={res.data.message}
              withIcon
            />
          );
          
        }
      })
      .catch((err) => {
        toast(<Notification type={"consultar_servidor_error"} withIcon />);
      });
  };

  export const editar = async (sistema) => {
    
    const data = {
      action: "update",
      table: "apparatus",
      rows: {
        nameSystem: sistema.nameSystem,
      },
      condition: {
        keySystem: sistema.keySystem,
      },
      validate: [
        ["nameSystem"]
      ]
    };
    
    API.peticion(data).then((res) => { 
        if (res.status === 200 && res.data.code === "200") {
          toast(
            <Notification
              type={"modifica_exito"}
              backMessage={res.data.message}
              withIcon
            />
          );
          window.location.href ="#/template/sistemas"  
        } else {
          toast(
            <Notification
              type={"modifica_error"}
              backMessage={res.data.message}
              withIcon
            />
          );
        }
      })
      .catch((err) => {
        toast(<Notification type={"consultar_servidor_error"} withIcon />);        
      });
  };
import axios from "axios";
import { peticionesReceiver } from "../../../helpers/peticionesReceiver";
import { peticionEncript } from "../../../helpers/peticionesEncripted";

const API = peticionesReceiver();

export const create = async ({ table,rows,column,validations }) => {
  if (rows) {
    const params = {
      action: "insert",
      table: table,
      rows: rows,
      order: `${column} asc`,
      validate: [
        validations
      ]
    };
    try {
      const res = await API.peticion(params)
      if (res.status === 200 && res.data.code === "200") {
        const data = res.data.data[0]
        return {success: true, reference: data}
      }
      else{
        return {success: false, message: res.data.message}
      }
    } catch (error) {
      return {success: false, message: error}
    }
  }
};

export const update = async ({ nameId,table,rows,validations }) => {
  if (rows) {
    const params = {
      action: "update",
      table: table,
      rows: rows,
      condition: {
        [nameId]: rows[nameId],
      },
      validate: [
        validations
      ]
    };
    if(validations.length === 0) delete params.validate
    try {
      const res = await API.peticion(params)
      if (res.status === 200 && res.data.code === "200") {
        return {success: true}
      }
      else{
        return {success: false, message: res.data.message}
      }
    } catch (error) {
      return {success: false, message: error}
    }
  }
};

export const getRow = async ({ id,nameId,table }) => {
  const params = {
    action: "select",
    table: table,
    condition: {
      enabled: 1,
      [nameId]: id
    }
  }
  try {
    const result = await API.peticion(params)
    if (result.status === 200 && result.data.code === "200") {
      const info = result.data.data[0]
      return {success: true, info}
    }
    else{
      return {success: false, message: result.data.message}
    }
  } catch (error) {
    return {success: false, message: error}
  }
}
 
export const createWithFile = async (data) => {
  try {
    const params = {
      method: "post",
      url: `${process.env.REACT_APP_API}app/facades/files/fileReceiver.php`,
      data: data,
      headers: { "Content-Type": "multipart/form-data" }
    };
    
    const result = await axios(params)

    if(result.status === 200 && result.data.code === "200"){
      return {success: true}
    }
    else {
      return {success: false, message: 'Favor de intentarlo nuevamente'}
    }
  }
  catch (error) {
    return {success: false, message: error}
  }
}

export const moneyTransfer = async (data) => {
  try {
    const params = {
      action: "moneyTransfer",
      rows:data
    };

    const finalData = peticionEncript(params)
    const result = await axios.post(`${process.env.REACT_APP_API}app/facades/accounts/accountsF.php`, finalData)

    if(result.status === 200 && result.data.code === "200"){
      const data = result.data.data[0]
      return {success: true, references: data}
    }
    else {
      return {success: false, message: 'Favor de intentarlo nuevamente'}
    }
  }
  catch (error) {
    return {success: false, message: error}
  }
}
import * as XlsxPopulate from "xlsx-populate";
import { saveAs } from "file-saver";
import { fDateLocal } from "../../../../libs/tools/format";

const formatter = new Intl.NumberFormat("es-MX", {
  style: "currency",
  currency: "MXN",
});

const dateformatter = (fecha) => {
  let date = new Date(fecha);
  return `${date.getDate()}/${date.getMonth()}/${date.getFullYear()}`;
};

export async function nominaDesglosadoExcel(
  report,
  headers,
  title,
  filter,
  params
) {
  report = Object.values(report)
  XlsxPopulate.fromBlankAsync().then(async (workbook) => {    

    const ds = {
      wrapText: true,
      horizontalAlignment: "center",
      verticalAlignment: "center",
      border: true,
    };

    const hs = {
      horizontalAlignment: "center",
      verticalAlignment: "center",
      border: true,
      bold: true,
    };

    const ts = {
      horizontalAlignment: "center",
      verticalAlignment: "center",
      bold: true,
    };

    const bolder = {
      bold: true
    }

    const sheet1 = workbook.sheet(0);


    const keys = Object.keys(report[0]);
    keys.splice(keys.indexOf("idStaff"),1 )
    

    const mid = Math.ceil(keys.length / 2);

    
    let colL = sheet1.column(mid - 1).columnName();
    let colR = sheet1.column(mid + 1).columnName();

    sheet1.range(`${colL}1:${colR}1`).merged(true).value("SAPP").style(ts);
    sheet1.range(`${colL}2:${colR}2`).merged(true).value(title).style(ts);
    
    sheet1.row(3).cell(1).value("Período de paga: ").style(ts);
    sheet1.row(3).cell(2).value(params.filters.period?.toString().padStart(2, '0')+ "/"+ params.filters.year);
    sheet1.row(4).cell(1).value("DEL: ").style({ ...bolder });
    sheet1.row(4).cell(2).value(`${fDateLocal(params.filters.dates.start)}  al  ${fDateLocal(params.filters.dates.end)}`).style({ horizontalAlignment: "center" });
    

    sheet1.row(6).cell(1).value([keys]);

    if (report.length > 4000) {
      report.forEach((staff, indexStaff) => {
        keys.forEach((keyObj, indexObj) => {
          if (indexStaff === report.length - 1) {
            sheet1
            .row(7 + indexStaff)
            .cell(1 + indexObj)
            .value(staff[keyObj] === "" || isNaN(Number(staff[keyObj])) ? staff[keyObj] : formatter.format(staff[keyObj]))
            .style([hs])
          } else {
            sheet1
            .row(7 + indexStaff)
            .cell(1 + indexObj)
            .value(staff[keyObj]);
          }
        });
      });
    } else {
      report.forEach((staff, indexStaff) => {
        keys.forEach((keyObj, indexObj) => {
          if (indexStaff === report.length - 1) {
            sheet1
            .row(7 + indexStaff)
            .cell(1 + indexObj)
            .value(staff[keyObj] === "" || isNaN(Number(staff[keyObj])) ? staff[keyObj] : formatter.format(staff[keyObj]))
            .style([hs])
          } else {
            sheet1
            .row(7 + indexStaff)
            .cell(1 + indexObj)
            .value(staff[keyObj])
            .style(ds);
          }
        });
      });
    }

    for (let i = 1; i <= keys.length; i++) {
      sheet1.column(i).width(30);
      sheet1.row(6).cell(i).style(hs);
    }
    return workbook.outputAsync().then((res) => {
      saveAs(res, `${title}.xlsx`);
    });
  });
}



import { Document, Page, StyleSheet, Text, View } from "@react-pdf/renderer";
import { pt } from "date-fns/locale";
import axios from "axios";
import { toast } from "react-toastify";
import Notification from "../../../../components/Notification/Notification";
import { peticionEncript } from "../../../../helpers/peticionesEncripted";
import HeaderReport from "../../../../components/Pdf/Header";
import { styles } from "../../../../components/Pdf/pdfGeneral/EstiloPdf";

const formatter = new Intl.NumberFormat("es-MX", {
  style: "currency",
  currency: "MXN",
});
const styleHeadForAll = {
  fontFamily: "Helvetica-Bold",
  paddingVertical: 3,
  borderStyle: "solid",
  borderWidth: 1,
  backgroundColor: "#646464",
  color: "white",
  textAlign: "center",
  justifyContent: "center",
  alignItems: "center",
  fontSize: 6,
  borderBottom: 0,
};

const styleCellForAll = {
  paddingVertical: 3,
  borderStyle: "solid",
  borderWidth: 1,
  borderTopWidth: 0,
  fontSize: 6,
  paddingHorizontal: 2,
};

/**
 * Estilos del pdf
 */
const s = StyleSheet.create({
  body: {
    padding: 10,
  },
  body: {
    paddingBottom: 35,
    paddingHorizontal: 20,
  },
  titleStyle: {
    fontWeight: "bold",
    textAlign: "center",
    fontSize: "10px",
    marginBottom: "5px",
    fontFamily: "Helvetica-Bold",
  },
  header: {
    backgroundColor: "#AEAAAA",
    color: "#FFFFFF",
    textAlign: "center",
    marginBottom: "1px",
  },
  vCenter: { height: "100%", paddingTop: "7px" },
  mPage: { margin: "10px" },
  mCell: { padding: "2px" },
  mB2: { marginBottom: "2px" },
  mB5: { marginBottom: "5px" },
  mB10: { marginBottom: "10px" },
  mB12: { marginBottom: "12px" },
  mT11: { marginTop: "11px" },
  row: { flexDirection: "row" },
  w10: { width: "10%" },
  w15: { width: "15%" },
  w20: { width: "20%" },
  w25: { width: "25%" },
  w30: { width: "30%" },
  w35: { width: "35%" },
  w40: { width: "40%" },
  w45: { width: "45%" },
  w50: { width: "50%" },
  w60: { width: "60%" },
  w65: { width: "65%" },
  w70: { width: "70%" },
  w80: { width: "80%" },
  w85: { width: "85%" },
  w90: { width: "90%" },
  w100: { width: "100%" },
  wAuto: { width: "auto" },
  border: { border: "1px solid black" },
  borderT: { borderTop: "1px solid black" },
  borderB: { borderBottom: "1px solid black" },
  borderE: { borderRight: "1px solid black" },
  borderBx2: { borderBottom: "2px solid black" },
  borderTx2: { borderTop: "2px solid black" },
  borderG: { border: "1px solid grey" },
  borderBG: { borderBottom: "1px solid grey" },
  fs8: { fontSize: "8px" },
  fs9: { fontSize: "9px" },
  fs10: { fontSize: "10px" },
  fs12: { fontSize: "12px" },
  fs13: { fontSize: "13px" },
  tend: { textAlign: "right", paddingRight: "3px" },
  tcenter: { textAlign: "center" },
  tBold: { fontFamily: "Helvetica-Bold" },
  spaceFooter: {
    bottom: "10",
    left: "0",
    right: "0",
    width: "100%",
    height: "50px",
  },
  footer: {
    position: "absolute",
    fontSize: "12",
    bottom: "10",
    left: "0",
    right: "0",
    width: "auto",
    height: "30px",
    marginHorizontal: "10px",
    borderTopWidth: "1px",
    flexDirection: "row",
    alignContent: "space-around",
    alignItems: "center",
  },
  textFooter: {
    width: "50%",
    textAlign: "center",
    fontSize: 8,
  },
  bd: { border: "1px solid red" },
  subTitle: {
    //SUBTITULOS
    width: "100%",
    textAlign: "center",
    marginBottom: 10,
    marginLeft: 10,
    fontSize: 12,
  },
  f6: {
    fontSize: 6,
  },
  f5: {
    fontSize: 5,
  },
  f8: {
    fontSize: 8,
  },
  f10: {
    fontSize: 10,
  },
  thIn: {
    ...styleHeadForAll,
  },
  th: {
    ...styleHeadForAll,
    borderLeftWidth: 0,
  },
  h130: {
    height: "130",
  },
  w5: {
    width: "5%",
  },
  w6: {
    width: "6%",
  },
  w7: {
    width: "7%",
  },
  w10: {
    width: "10%",
  },
  w15: {
    width: "15%",
  },
  w20: {
    width: "20%",
  },
  w18: {
    width: "18%",
  },
  w25: {
    width: "25%",
  },
  w30: {
    width: "30%",
  },
  w33: {
    width: "33%",
  },
  w34: {
    width: "34%",
  },
  w40: {
    width: "40%",
  },
  w45: {
    width: "45%",
  },
  w50: {
    width: "50%",
  },
  w60: {
    width: "60%",
  },
  w70: {
    width: "70%",
  },
  w80: {
    width: "80%",
  },
  w90: {
    width: "90%",
  },
  w100: {
    width: "100%",
  },
  m1: {
    margin: 3,
  },
  mt2: {
    marginTop: 5,
  },
  mt3: {
    marginTop: 10,
  },
  mx2: {
    marginHorizontal: 5,
  },
  mt4: {
    marginTop: 40,
  },
  pt1: {
    paddingTop: 10,
  },
  px2: {
    paddingHorizontal: 10,
  },
  px1: {
    paddingHorizontal: 5,
  },
  py6: {
    paddingVertical: 10,
  },
  py7: {
    paddingVertical: 16.5,
  },
  b1: {
    border: 1,
  },
  bt0: {
    borderTopWidth: 0,
  },
  bt1: {
    borderTopWidth: 1,
  },
  center: {
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
  },
  textCenter: {
    textAlign: "center",
  },
  textBold: {
    fontFamily: "Helvetica-Bold",
  },
  textRight: {
    textAlign: "right",
  },
  tdIn: {
    //Cell CC
    ...styleCellForAll,
  },
  td: {
    //Cell Concepto
    ...styleCellForAll,
    borderLeftWidth: 0,
  },
  td0: {
    //Cell Concepto
    ...styleCellForAll,
    borderLeftWidth: 0,
    borderRightWidthWidth: 0,
  },
  red: {
    backgroundColor: "red",
  },
  green: {
    backgroundColor: "green",
  },
  gray: {
    backgroundColor: "gray",
  },
  h100: {
    height: "100%",
  },
});

async function consulta(params) {
  const finalData = peticionEncript(params);
  let info = [];
  await axios
    .post(process.env.REACT_APP_API + "receiver/receiver.php", finalData)
    .then((res) => {
      if (res.status === 200 && res.data.code === "200") {
        info = res.data.data;
      }
    })
    .catch((err) => {
      toast(<Notification type={"consultar_servidor_error"} withIcon />, {
        closeButton: false,
      });
    });
  return info;
}

async function countEmployees() {
  const params = {
    action: "multiselect",
    table: "staff S INNER JOIN jobstaff J ON J.idStaff = S.idStaff",
    rows: "COUNT(S.idStaff) cant",
    conditions: "S.enabled = 1 AND J.valid = 1 AND J.enabled = 1",
    order: "",
  };
  const cant = await consulta(params);
  if (cant.length !== 0) return cant[0].cant;
  return 0;
}

async function countPensions() {
  const params = {
    action: "multiselect",
    table:
      "staff S INNER JOIN pensions P ON P.idStaff = S.idStaff INNER JOIN jobstaff J ON J.idStaff = S.idStaff ",
    rows: "COUNT(S.idStaff) cant",
    conditions:
      "S.enabled = 1 AND S.keyStat NOT IN (2, 7, 9, 10, 11, 12, 13) AND J.enabled = 1 AND J.valid = 1 AND P.enabled = 1",
    order: "",
  };
  const cant = await consulta(params);
  if (cant.length !== 0) return cant[0].cant;
  return 0;
}

const insertHeader = (income) => {
  const fecha = new Date(income.dateIncome);
  
  return (
    <View style={styles.table}>
      <View style={[styles.tableRow]}>
        <View style={s.w50}>
          <View style={styles.tableRow}>
            <View style={[s.thIn, s.w100]}>
              <Text>Fecha de Recepción</Text>
            </View>
          </View>
          <View style={styles.tableRow}>
            <View style={s.w100}>
              <View style={styles.tableRow}>
                <View style={[s.thIn, s.w33]}>
                  <Text>Dia</Text>
                </View>
                <View style={[s.th, s.w33]}>
                  <Text>Mes</Text>
                </View>
                <View style={[s.th, s.w34]}>
                  <Text>Año</Text>
                </View>
              </View>
              <View style={styles.tableRow}>
                <View style={[s.tdIn, s.w33, s.center]}>
                  <Text>
                    {fecha.getDay() < 10
                      ? "0" + fecha.getDay()
                      : fecha.getDay()}
                  </Text>
                </View>
                <View style={[s.td, s.w33, s.center]}>
                  <Text>
                    {fecha.getMonth() + 1 < 10
                      ? "0" + (fecha.getMonth() + 1)
                      : fecha.getMonth() + 1}
                  </Text>
                </View>
                <View style={[s.td, s.w34, s.center]}>
                  <Text>{fecha.getFullYear()}</Text>
                </View>
              </View>
            </View>
          </View>
        </View>

        <View style={s.w50}>
          <View style={styles.tableRow}>
            <View style={[s.th, s.w100, s.py7, { borderBottomWidth: "1" }]}>
              <Text>ACUSE DE RECEPCIÓN DE MERCANCIA</Text>
            </View>
          </View>
        </View>
        <View style={s.w20}>
          <View style={styles.tableRow}>
            <View style={[s.th, s.w100]}>
              <Text>Folio</Text>
            </View>
          </View>
          <View style={styles.tableRow}>
            <View style={[s.td, s.w100, s.py6, s.center]}>
              <Text>{income.numberIncome}</Text>
            </View>
          </View>
        </View>
      </View>

      <View style={[styles.tableRow]}>
        <View style={[s.tdIn, s.w30]}>
          <Text>Almacen</Text>
        </View>
        <View style={[s.td, s.w70]}>
          <Text>{income.name}</Text>
        </View>
      </View>
      <View style={styles.tableRow}>
        <View style={[s.tdIn, s.w30]}>
          <Text>Recibio</Text>
        </View>
        <View style={[s.td, s.w70]}>
          <Text>{income.empleado} </Text>
        </View>
      </View>

      <View style={[styles.tableRow,{ paddingTop: 7 }]}>
        <View style={[s.thIn, s.w100]}>
          <Text>Datos generales del provedor </Text>
        </View>
      </View>

      <View style={styles.tableRow}>
        <View style={[s.tdIn, s.w30]}>
          <Text>Denominación o Razón Social</Text>
        </View>
        <View style={[s.td, s.w70]}>
          <Text> {income.socialName}</Text>
        </View>
      </View>
      <View style={styles.tableRow}>
        <View style={[s.tdIn, s.w25]}>
          <Text>RFC:</Text>
        </View>
        <View style={[s.td, s.w25]}>
          <Text>{income.itin} </Text>
        </View>
        <View style={[s.td, s.w25]}>
          <Text>Telefono </Text>
        </View>
        <View style={[s.td, s.w25]}>
          <Text>{income.telephone} </Text>
        </View>
      </View>

      <View style={styles.tableRow}>
        <View style={[s.tdIn, s.w30]}>
          <Text>Correo:</Text>
        </View>
        <View style={[s.td, s.w70]}>
          <Text> {income.email}</Text>
        </View>
      </View>
    </View>
  );
};

const insertTableProducts = (data) => {
  return (
    <View style={styles.table}>
      <View style={styles.tableRow}>
        <View style={[s.thIn, s.w100]}>
          <Text>Artículos Entregados</Text>
        </View>
      </View>

      <View style={styles.tableRow}>
        <View style={[s.tdIn, s.w25, s.center]}>
          <Text>Articulo</Text>
        </View>
        <View style={[s.td, s.w25, s.center]}>
          <Text>Cantidad Programada </Text>
        </View>
        <View style={[s.td, s.w25, s.center]}>
          <Text>Cantidad recibida </Text>
        </View>
        <View style={[s.td, s.w25, s.center]}>
          <Text>Consto Unitario con IVA </Text>
        </View>
      </View>

      {data.map((obj) => (
        <View style={styles.tableRow}>
          <View style={[s.tdIn, s.w25, s.center]}>
            <Text>{obj.bien}</Text>
          </View>
          <View style={[s.td, s.w25, s.center]}>
            <Text>{obj.quantity} </Text>
          </View>
          <View style={[s.td, s.w25, s.center]}>
            <Text>{obj.incomeTotal} </Text>
          </View>
          <View style={[s.td, s.w25, s.center]}>
            <Text> {formatter.format((+obj.total + (+obj.total*1.16))) } </Text>
          </View>
        </View>
      ))}

      <View style={[styles.tableRow,{marginTop:50}]}>
        <View
          style={[
            s.w100,
            { alignContent: "flex-end", alignItems: "center",flexDirection:"row"},
          ]}
        >
          <View style={[s.w35,s.center, { borderTopWidth: 1, fontSize:8 }]}>
            <Text>Recibió</Text>
          </View>
          <View style={[s.w25]}>
          </View>

          <View style={[s.w35, { borderTopWidth: 1, fontSize:8 },s.center]}>
            <Text>Provedor</Text>
          </View>
        </View>
      </View>
    </View>
  );
};

export async function generatePdf(data, cfg, fileNo) {
  /* const headerData = getHeaderData() */
  

  return {
    report: (
      <Document>
        <Page style={s.body}>
          <HeaderReport />

          {insertHeader(data.generalData[0])}

          {insertTableProducts(data.warehouse)}

          <View fixed style={s.spaceFooter}></View>
          <View fixed style={s.footer}>
            <Text style={s.textFooter}>{fileNo}</Text>
            <Text
              style={s.textFooter}
              render={({ pageNumber, totalPages }) =>
                `PÁGINA : ${pageNumber} / ${totalPages}`
              }
            />
          </View>
        </Page>
      </Document>
    ),
  };
}

import { useEffect, useRef, useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
    FilesInput,
    SelectTypeHeadSingle,
} from "../../../components/GenericInputsFormik";
import { Button, Col, Collapse, Row } from "reactstrap";
import { PERIOD } from "../../nomina/calculo/regex";
import { YearInput } from "../../../components/GenericInputsFormik/YearInput";
import ModalConfirmation from "../../../components/Modal/ModalConfirmation";
import { doRequest, doRequestSaveRes } from "../../../helpers/requests";
import TableCon from "./TableCon";
import FullFormLoader from "../../../components/Loader/FullFormLoader";
import { toast } from "react-toastify";
import Notification from "../../../components/Notification/Notification";
import { validValue } from "../../../libs/tools/format";

const defaultForm = {
    period: "",
    year: new Date(),
    binary: "",
};

const FormSchema = Yup.object().shape({
    period: Yup.string().required("Seleccione un periodo"),
    year: Yup.string().required("Seleccione un año").nullable(),
    binary: Yup.mixed()
        .required("Agregue un archivo")
        .test("file-type", "El documento debe ser en formato CSV", (value) => {
            return value && value.type === "text/csv";
        }),
});

const cYear = new Date().getFullYear();

export default function Form({permissions}) {
    const [modal, setModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [filtro, setFiltro] = useState({period: 0, year: cYear});
    const refInpFile = useRef(null);

    const {
        handleSubmit,
        handleReset,
        handleBlur,
        values,
        errors,
        touched,
        setFieldValue,
        setTouched
    } = useFormik({
        initialValues: defaultForm,
        validationSchema: FormSchema,
        enableReinitialize: true,
        onSubmit: () => setModal(true),
        onReset: () => {
            refInpFile.current.value = "";
        },
    });

    const onChange = (e) => {
        if (e.target.name === "binary") {
            if (e.target.files.length === 0) setFieldValue("binary", "");
            else setFieldValue("binary", e.target.files[0]);
        } else {
            setFieldValue(e.target.name, e.target.value);
        }
    };

    async function upload(){
        setLoading(true);
        const year = values.year.getFullYear();
        const params = {
            action: "multiselect",
            table: "payslips",
            rows: "COUNT(period) AS pagos",
            conditions: `period = ${values.period} AND year = ${year}`,
            group: "period"
        }
        const did = await doRequest("receiver/receiver.php",params, false, false);
        if(did.length > 0 && +did[0].pagos > 0){
            const formData = new FormData();
            formData.append("action", "importPrisma");
            formData.append("period", values.period);
            formData.append("year", year);
            formData.append("binary", values.binary);
            const res = await doRequestSaveRes("app/facades/layouts/layoutsF.php", formData, true, true, false, {}, false);
            if(+res.code === 200){
                setFiltro({period: values.period, year, year});
                handleReset();
            }
        }else{
            toast(
                <Notification
                    type={"consultar_error"} 
                    backMessage={`No se ha realizado el cálculo para el periodo ${values.period} del año ${year}`}
                    withIcon
                />, 
                { closeButton: false }
            );
        }
        setLoading(false);
    }

    function consultar(){
        if(validValue(values.period) && validValue(values.year)){
            setFiltro({period: values.period, year: values.year.getFullYear()});
        }else{
            setTouched({period: true, year: true}, true);
        }
    }

    return (
        <div>
            <form onSubmit={handleSubmit} onReset={handleReset}>
                <Row>
                    <Col xs={12} md={6}>
                        <FilesInput
                            label="Adjuntar archivo de Prisma CSV"
                            inputName="binary"
                            onChangeMethod={onChange}
                            onBlurMethod={handleBlur}
                            fileAccept="text/csv"
                            isRequired
                            touched={touched.binary}
                            errors={errors.binary}
                            basicReq={false}
                            refInput={refInpFile}
                        />
                    </Col>
                    <Col xs={12} md={3}>
                        <SelectTypeHeadSingle
                            label="Periodo"
                            isRequired={true}
                            inputName="period"
                            optionsArray={PERIOD.slice(0, PERIOD.length - 2)}
                            defaultOption="Seleccione un periodo"
                            onChangeMethod={onChange}
                            onBlur={handleBlur}
                            value={values.period}
                            touched={touched.period}
                            errors={errors.period}
                            optionValue="value"
                            optionName="label"
                        />
                    </Col>
                    <Col xs={12} md={3}>
                        <YearInput
                            label="Año"
                            inputName="year"
                            onChangeMethod={onChange}
                            value={values.year}
                            isMin={cYear - 5}
                            isMax={cYear}
                            onBlurMethod={handleBlur}
                            touched={touched.year}
                            errors={errors.year}
                            isRequired
                        />
                    </Col>
                </Row>
                <div className=" d-flex flex-md-row flex-column justify-content-md-around">
                    {
                        permissions.INS &&
                        <Button color="success" type="submit" className="mt-2 mt-md-3">
                            Ejecutar proceso
                        </Button>
                    }
                    {
                        permissions.CON &&
                        <Button color="secondary" type="button" className="mt-3" onClick={()=>consultar()}>
                            Consultar
                        </Button>
                    }
                </div>
            </form>

            <hr className="mt-4" />

            {
                permissions.CON && 
                <Collapse isOpen={filtro.period !== 0}>
                    <Row className="">
                        <Col xs={12} md={6}>
                            <TableCon type={1} filtro={filtro}/>
                        </Col>
                        <Col xs={12} md={6}>
                            <TableCon type={2} filtro={filtro}/>
                        </Col>
                        <Col xs={12} md={6}>
                            <TableCon type={3} filtro={filtro}/>
                        </Col>
                        <Col xs={12} md={6}>
                            <TableCon type={4} filtro={filtro}/>
                        </Col>
                        <Col xs={12} md={6}>
                            <TableCon type={5} filtro={filtro}/>
                        </Col>
                    </Row>
                </Collapse>
            }
            
            <ModalConfirmation
                modalTitle="Realizar conciliación"
                modal={modal}
                setModal={setModal}
                crear={upload}
                values={values}
            >
                <div className="d-flex justify-content-center">
                    <h6>¿Está seguro de realizar la consolidación?</h6>
                </div>
            </ModalConfirmation>

            <FullFormLoader show={loading} message="Cargando datos, espere un momento"/>
        </div>
    );
}

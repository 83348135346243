import { Formik, Form, Field, ErrorMessage, useFormikContext } from "formik";
import * as Yup from "yup";
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Button } from "reactstrap";
import ModalConfirmation from "../../../components/Modal/ModalConfirmation";

const defaultForm = {
  keyTypeLoan: "",
  nameTypeLoan: "",
};

const FormPensiones = ({ crear, data, editar }) => {
  const history = useHistory();
  const [form, setForm] = useState(defaultForm);
  const [modal, setModal] = useState(false);

  const FormSchema = Yup.object().shape({
    nameTypeLoan: Yup.string()
      .min(3, "El nombre del prestamo debe contener al menos 3 letras")
      .required("Ingrese un nombre")
  });

  const submitFunction = (fields) => {
    setModal(true);
  };

  const cancelOp = () => {
      history.push("/template/prestamos-nomina");
    
  };

  return (
    <Formik
      initialValues={form}
      validationSchema={() => FormSchema}
      onSubmit={(fields, { resetForm }) => {
        submitFunction(fields);
      }}
      onReset={(fields, { resetForm }) => {
        cancelOp();
      }}
    >
      {({ errors, status, touched, values }) => (
        <Form className="mt-4">
          <ModalConfirmation
            modalTitle={data ? "Editar" : "Crear"}
            modal={modal}
            setModal={setModal}
            crear={crear}
            editar={editar}
            isEdit={data ? true : false}
            values={values}
          >
            {data ? (
              <div className="d-flex justify-content-center">
                <h6>¿Está seguro de editar el registro?</h6>
              </div>
            ) : (
              <div className="d-flex justify-content-center">
                <h6>¿Desea continuar con el registro?</h6>
              </div>
            )}
          </ModalConfirmation>

          <div className="form-group">
            <label htmlFor="nameTypeLoan">
              Nombre del prestamo<span className="text-danger">*</span>
            </label>
            <Field
              name="nameTypeLoan"
              type="text"
              className={
                "form-control" +
                (errors.nameTypeLoan && touched.nameTypeLoan ? " is-invalid" : "")
              }
            />
            <ErrorMessage
              name="nameTypeLoan"
              component="div"
              className="invalid-feedback"
            />
          </div>
          <div className="d-flex justify-content-between mt-5">
            <Button color="danger" type="reset">
              Cancelar
            </Button>
            <Button color="success" type="submit">
              Guardar
            </Button>
          </div>
          <FormikData info={data} />
        </Form>
      )}
    </Formik>
  );
};

const FormikData = ({ info }) => {
  const { setFieldValue } = useFormikContext();
  useEffect(() => {
    if (info) {
      setFieldValue("keyTypeLoan", info[0]);
      setFieldValue("nameTypeLoan", info[1]);
    }
  }, [info]);
  return null;
};
export default FormPensiones;

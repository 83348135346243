import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import * as Yup from "yup";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { Row, Col, Button, Collapse } from "reactstrap";

import Loader from "../../../components/Loader/Loader";
import { peticionEncript } from "../../../helpers/peticionesEncripted";
import Notification from "../../../components/Notification/Notification";
import { FilesInput, SelectTypeHeadSingle } from "../../../components/GenericInputsFormik/index";

import TableComponentFiles from "../../../components/TableComponent/TableComponentFiles";
import Camera from "../../../components/Modal/ModalCamera";
import { dataURLtoFile, formikImageFile2Image64 } from "../../../libs/tools/images";
import SignaturePad from 'react-signature-canvas'
import { peticionesReceiver } from "../../../helpers/peticionesReceiver";
import * as faceapi from 'face-api.js'
import FullFormLoader from "../../../components/Loader/FullFormLoader";

const PersonalDocumentos = ({ idStaff, updateData, permissions, staffValid, upload, setUpload }) => {
  const API = peticionesReceiver()
  const [fileTypes, setFileTypes] = useState("");
  const [loading, setLoading] = useState(true);
  const [loadingModels, setLoadingModels] = useState(true);
  const [isEmpty, setIsEmpty] = useState(false);
  const [staffFiles, setStaffFiles] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [fotografia, setFotografia] = useState("");
  const [loadedFromFile, setLoadedFromFile] = useState(false);
  const [loadingDescr, setLoadingDescr] = useState(false);
  const [firma, setFirma] = useState("");
  const [visible, setVisible] = useState(false)
  const previewRef = useRef(null)
  const refInpFile = useRef(null);
  let sigPad1 = useRef(null);
  const [staffData, setStaffData] = useState({
    reference: idStaff,
    keyFileType: "",
    binary: "",
  });

  const [parametros, setParametros] = useState({
    action: "getFilesbyModule",
    reference: idStaff,
    idModule: 25,
  });

  const FormSchema = Yup.object().shape({
    keyFileType: Yup.string().required("Seleccione un tipo de archivo a subir"),
    binary: Yup.mixed()
      .when("keyFileType", {
        is: (val) => [firma, fotografia].includes(val),
        then: Yup.mixed().test("file-type", "Ingrese un archivo tipo imagen", (value) => {
          const files = ["image/png", "image/gif", "image/jpeg"]
          return value && files.includes(value.type);
        })
      })
      .test("file-type", "Ingrese un archivo tipo JPG, PNG, GIF o PDF", (value) => {
        const files = ["image/png", "image/gif", "image/jpeg", "application/pdf"]
        return value && files.includes(value.type);
      })
      .test({
        message: `El documento debe pesar menos de 4MB`,
        test: (value) => value?.size < 4000000,
      })
  });

  useEffect(() => {
    if (staffValid !== null && upload !== null && !upload.fot && fotografia !== "") {
      const file = dataURLtoFile(staffValid.captura, "fotografia.jpeg");
      setFieldValue("keyFileType", fotografia)
      setFieldValue("binary", file)
      setFieldValue("descriptor", staffValid.descriptor)
      setFieldValue("captura", staffValid.captura)
      openForm()
    }
  }, [staffValid, upload, fotografia])

  useEffect(() => {
    getFileTypes();
    LoadModels()
  }, []);

  const removeSelectOptions = (allFiles, userFiles) => {
    userFiles.map((e) => {
      allFiles.filter((element, index) => {
        if (element.nameFyleType === e.nameFyleType) {
          allFiles.splice(index, 1);
        }
      });
    });
    setStaffFiles(userFiles);
    function SortArray(x, y) {
      if (x.nameFyleType < y.nameFyleType) {
        return -1;
      }
      if (x.nameFyleType > y.nameFyleType) {
        return 1;
      }
      return 0;
    }
    allFiles = allFiles.sort(SortArray);

    setFileTypes(allFiles);
    setLoading(false);
  };

  const getFilesUser = async (allFiles) => {
    const finalData = peticionEncript(parametros);
    await axios
      .post(
        `${process.env.REACT_APP_API}app/facades/files/fileReceiver.php`,
        finalData
      )
      .then((res) => {
        if (res.data.code === "200" && res.data.status === "success") {
          removeSelectOptions(allFiles, res.data.data);
        } else {
          function SortArray(x, y) {
            if (x.nameFyleType < y.nameFyleType) {
              return -1;
            }
            if (x.nameFyleType > y.nameFyleType) {
              return 1;
            }
            return 0;
          }
          allFiles = allFiles.sort(SortArray);
          setFileTypes(allFiles);
          setLoading(false);
        }
      })
      .catch((err) => {
        toast(<Notification type={"consultar_error"} withIcon />);
      });
  };

  const getFileTypes = async () => {
    const params = {
      action: "getFileTypsByModule",
      idModule: "25",
      order: "nameFyleType asc",
    };
    const finalData = peticionEncript(params);
    await axios
      .post(
        `${process.env.REACT_APP_API}app/facades/files/fileReceiver.php`,
        finalData
      )
      .then((res) => {
        const fotografia = res.data.data.find(row => row.nameFyleType === "FOTOGRAFIA");
        setFotografia(fotografia.keyFileType);
        const firma = res.data.data.find(row => row.nameFyleType === "FIRMA");
        setFirma(firma.keyFileType);

        getFilesUser(res.data.data);
      })
      .catch((err) => {
        toast(<Notification type={"consultar_error"} withIcon />);
      });
  };

  const onChange = (e) => {
    if (e.target.name === "binary") {
      if (values.keyFileType === firma || values.keyFileType === fotografia) {
        formikImageFile2Image64(e.target.files[0], setFieldValue, "captura", false);
      }
      if (values.keyFileType === fotografia) {
        setLoadingDescr(true)
        setLoadedFromFile(true)
      }
      setFieldValue([e.target.name], e.target.files[0]);
    } else {
      setFieldValue([e.target.name], e.target.value);
    }
  };

  async function sendDescriptor(values) {
    const params = {
      action: "update",
      table: "staff",
      rows: {
        descriptor: Object.values(values.descriptor).join("?")
      },
      condition: {
        idStaff: idStaff
      }
    }
    try {
      const res = await API.peticion(params);
      if (res.status === 200 && res.data.code === "200") {
        toast(<Notification type={"modifica_exito"} backMessage={res.data.message} withIcon />);
        return true;
      } else {
        toast(<Notification type={"modifica_error"} backMessage={res.data.message} withIcon />);
        return false;
      }
    } catch (err) {
      toast(<Notification type={"consultar_servidor_error"} withIcon />);
      return false;
    }
  }

  const sendData = async () => {
    if (!isEmpty) {
      setLoading(true);
      const sendedDescriptor = values.keyFileType === fotografia ? await sendDescriptor(values) : true;
      if (sendedDescriptor) {
        const formData = new FormData();
        formData.append("action", "saveFile");
        formData.append("reference", values.reference);
        formData.append("keyFileType", values.keyFileType);
        formData.append("binary", values.binary);
        setFieldValue(values.keyFileType, "");
        setFieldValue(values.binary, "");
        setStaffData((prevState) => ({
          ...prevState,
          keyFileType: "",
          binary: "",
        }));
        resetForm();
        const params = {
          method: "post",
          url: `${process.env.REACT_APP_API}app/facades/files/fileReceiver.php`,
          data: formData,
        };
        await axios(params)
          .then((res) => {
            toast(<Notification type={"agrega_exito"} withIcon />);
            updateData();
            setIsOpen(false);
            getFileTypes();
            setParametros({ ...parametros });
            if (staffValid !== null) {
              const uppload = JSON.parse(JSON.stringify(upload));
              uppload.fot = true;
              setUpload(uppload)
            }
          })
          .catch((err) => {
            toast(<Notification type={"agrega_error"} withIcon />);
          });
      } else {
        setLoading(false);
      }
    }
  };

  const openForm = () => {
    if (isOpen) setIsOpen(false);
    else setIsOpen(true);
  };

  const { handleSubmit, values, handleBlur, errors, touched, setFieldValue, resetForm } =
    useFormik({
      initialValues: staffData,
      onSubmit: (values) => {
        sendData();
      },
      validationSchema: FormSchema,
      enableReinitialize: true,
    });

  useEffect(() => {
    setIsEmpty(values.binary === "");
  }, [values.binary])

  function setImage(key, value) {
    if (key === "captura") {
      const file = dataURLtoFile(value, "fotografia.jpeg");
      setFieldValue("binary", file);
      setFieldValue(key, value);
    } else {
      setFieldValue(key, value);
    }
  }

  function onEndSignature() {
    const pad = sigPad1?.current;
    if (pad !== null && pad !== undefined) {
      const file = dataURLtoFile(pad.toDataURL(), "Firma.png", "image/png")
      setFieldValue("binary", file)
    }
  }

  function cleanSign() {
    setFieldValue("binary", "")
    sigPad1?.current?.clear();
  }

  async function LoadModels() {
    const loadFromOrigin = process.env.REACT_APP_FACE_API_ORIGIN === "true" ? true : false;
    const origin = window.location.origin;
    await Promise.all([
      faceapi.nets.ssdMobilenetv1.loadFromUri(!loadFromOrigin ? `https://cdn.jsdelivr.net/gh/cgarciagl/face-api.js/weights/` : `${origin}/weights/`),
      faceapi.nets.faceLandmark68Net.loadFromUri(!loadFromOrigin ? `https://cdn.jsdelivr.net/gh/cgarciagl/face-api.js/weights/` : `${origin}/weights/`),
      faceapi.nets.faceRecognitionNet.loadFromUri(!loadFromOrigin ? `https://cdn.jsdelivr.net/gh/cgarciagl/face-api.js/weights/` : `${origin}/weights/`),
      faceapi.nets.tinyFaceDetector.loadFromUri(!loadFromOrigin ? `https://cdn.jsdelivr.net/gh/cgarciagl/face-api.js/weights/` : `${origin}/weights/`)
    ])
    setLoadingModels(false)
  }


  const handleScreenshot = async (blob) => {
    if (loadedFromFile) {
      try {
        if (previewRef && previewRef.current) {

          const face = await faceapi.detectSingleFace(previewRef.current).withFaceLandmarks().withFaceDescriptor();
          if (face) {
            const displaySize = { width: previewRef.current.width, height: previewRef.current.height }
            const resizedDetections = faceapi.resizeResults(face, displaySize)
            setFieldValue("descriptor", resizedDetections.descriptor)
            setLoadingDescr(false)
          } else {
            setFieldValue("binary", "")
            setFieldValue("captura", "")
            refInpFile.current.value = ""
            setLoadingDescr(false);
            toast(<Notification type={"consultar_error"} backMessage={"No se a detectado una persona en la fotografia"} withIcon />);
          }
        }
      } catch (err) {
        setFieldValue("binary", "")
        setFieldValue("captura", "")
        refInpFile.current.value = ""
        setLoadingDescr(false);
        toast(<Notification type={"consultar_error"} backMessage={"Ocurrio un error al leer el archivo"} withIcon />);
      }
    }
  }

  return (
    <div>
      {loading || loadingModels ? (
        <Loader></Loader>
      ) : (
        <div>
          <TableComponentFiles
            params={parametros}
            resetFunction={getFileTypes}
            updateData={updateData}
            permissions={permissions}
          />
          {permissions.INS && <div className="d-grid d-flex justify-content-center justify-content-sm-end">
            <Button color="primary" onClick={() => openForm()}>
              {isOpen ? "Ocultar" : "Agregar"}
            </Button>
          </div>}
          <Collapse isOpen={isOpen}>
            <form onSubmit={handleSubmit}>
              <Row>
                <Col xs={12} lg={6}>
                  <SelectTypeHeadSingle
                    label="Seleccione un tipo de archivo a subir"
                    isRequired={true}
                    inputName="keyFileType"
                    optionsArray={fileTypes}
                    defaultOption="Seleccione un tipo de archivo a subir"
                    onChangeMethod={onChange}
                    onBlur={handleBlur}
                    isDisabled={false}
                    value={values.keyFileType}
                    touched={touched.keyFileType}
                    errors={errors.keyFileType}
                    optionValue="keyFileType"
                    optionName="nameFyleType"
                  />
                </Col>
                <Col xs={12} lg={6}>
                  <FilesInput
                    label="Ingrese un archivo"
                    inputName="binary"
                    fileAccept={values.keyFileType === fotografia || values.keyFileType === firma ? ".gif,.png,.jpeg" : ".pdf,.gif,.png,.jpeg"}
                    onChangeMethod={onChange}
                    onBlurMethod={handleBlur}
                    value={values.binary}
                    errors={errors.binary}
                    touched={touched.binary}
                    refInput={refInpFile}
                  />
                  {values.keyFileType === fotografia && (
                    <>
                      <div className="d-flex flex-column mx-auto" style={{ maxWidth: "50%" }}>
                        <p className="text-center mt-3 font-weight-bold">ó</p>
                        <Button onClick={() => setVisible(true)}>Tomar fotografia</Button>
                      </div>
                      <img className="border border-primary mt-3" ref={previewRef} src={values.captura} onLoad={() => handleScreenshot(values.captura)} alt="Sin fotografia" style={{ minHeight: "200px", maxWidth: "100%" }} />
                    </>
                  )}
                  {values.keyFileType === firma && (
                    <div className="d-flex flex-column mx-auto" style={{ maxWidth: "fit-content" }}>
                      <p className="text-center mt-3 font-weight-bold">ó</p>
                      <h6 className="text-center">Realize una firma autografa en el recuadro</h6>
                      <div className="text-center mb-3">
                        <Button variant="warning" onClick={cleanSign}>Limpiar</Button>
                      </div>
                      <div className="text-center border" style={{ width: "fit-content", height: "fit-content", backgroundColor: "#fff" }}>
                        <SignaturePad
                          canvasProps={{ width: 400, height: 200 }}
                          ref={sigPad1}
                          clearOnResize={false}
                          onEnd={onEndSignature}
                        />
                      </div>
                    </div>
                  )}
                </Col>
              </Row>
              <div className="d-flex justify-content-center mt-5">
                <button type="submit" className="btn btn-success">
                  Enviar
                </button>
              </div>
            </form>
          </Collapse>
        </div>
      )}
      <Camera
        setFieldValue={setImage}
        setVisible={setVisible}
        visible={visible}
      />
      <FullFormLoader message={"Cargando información de la imagen"} setShow={setLoadingDescr} show={loadingDescr} />
    </div>
  );
};
export default PersonalDocumentos;

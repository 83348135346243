import { useEffect, useState } from "react";
import { Row, Col, Button } from "reactstrap"
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as Yup from "yup";

import ModalConfirmation from "../../../components/Modal/ModalConfirmation";
import Notification from "../../../components/Notification/Notification";
import { SelectTypeHeadSingle, TextAreaInput, TextInput, FilesInput } from "../../../components/GenericInputsFormik"
import { peticionesReceiver } from "../../../helpers/peticionesReceiver";
import { OnCreate, OnEdit } from "./functions";
import Loader from "../../../components/Loader/Loader";
import { useHistory } from "react-router";

export default function Form({
    data,
    OnSuccess,
    OnCancel
}) {
    const history = useHistory()
    const defaultForm = {
        idFurniture: data ? data.idFurniture : 0,
        keyGroup: "",
        keySubGroup: "",
        keyClass: "",
        keyColor: "",
        keyMaterial: "",
        keyUnit: "",
        nameFurniture: "",
        model: "",
        brand: "",
        file: ""
    }

    const [firstSRC, setFirstSRC] = useState("")

    const API = peticionesReceiver();
    const [modal, setModal] = useState(false)

    //STATES
    const [keyGroup, setKeyGroup] = useState("");
    const [keySubGroup, setKeySubGroup] = useState("");

    //DATA
    const [loadingGroups, setLoadingGroups] = useState(true)
    const [groups, setGroups] = useState([]);
    const [loadingSubgroups, setLoadingSubgroups] = useState(false)
    const [subgroups, setSubgroups] = useState([]);
    const [loadingClasses, setLoadingClasses] = useState(false)
    const [classes, setClasses] = useState([]);
    const [loadingUnits, setLoadingUnits] = useState(true)
    const [units, setUnits] = useState([]);
    const [loadingMaterials, setLoadingMaterials] = useState(true)
    const [materials, setMaterials] = useState([]);
    const [loadingColors, setLoadingColors] = useState(true)
    const [colors, setColors] = useState([]);

    const [loading, setLoading] = useState(true)
    const [loadingData, setLoadingData] = useState(true);

    async function getDataGrupo() {
        const params = {
            action: "multiselect",
            table: "furniture_groups",
            rows: "keyGroup,nameGroup",
            conditions: `enabled = 1`,
        }
        const res = await API.peticion(params)
        if (res.status === 200 && res.data.code === "200") {
            const data = res.data.data;
            setGroups(data)
            setLoadingGroups(false)
        } else {
            setGroups([])
            toast(<Notification type={"consultar_error"} withIcon />);
        }
    }

    async function getDataSubGrupo(keyGroup, all) {
        setLoadingSubgroups(true)
        const params = {
            action: "multiselect",
            table: "furniture_subgroups",
            rows: "keySubGroup,nameSubGroup",
            conditions: !all ? `enabled = 1 AND keyGroup = ${keyGroup}` : `enabled = 1`,
        };
        const res = await API.peticion(params)
        if (res.status === 200 && res.data.code === "200") {
            const data = res.data.data;
            setSubgroups(data)
            setLoadingSubgroups(false)
        } else {
            setSubgroups([])
            toast(<Notification type={"consultar_error"} withIcon />);
        }
    }

    async function getDataClases(keySubGroup, all) {
        setLoadingClasses(true)
        const params = {
            action: "multiselect",
            table: "furniture_classes",
            rows: "keyClass,nameClass",
            conditions: !all ? `enabled = 1 AND keySubGroup = ${keySubGroup}` : `enabled = 1`
        }
        const res = await API.peticion(params)
        if (res.status === 200 && res.data.code === "200") {
            const data = res.data.data;
            setClasses(data)
            setLoadingClasses(false)
        } else {
            setClasses([])
            toast(<Notification type={"consultar_error"} withIcon />);
        }
    }

    async function getDataColors() {
        const params = {
            action: "multiselect",
            table: "colors",
            rows: "keyColor,nameColor",
            conditions: `enabled = 1`,
        };
        const res = await API.peticion(params)
        if (res.status === 200 && res.data.code === "200") {
            const data = res.data.data;
            setColors(data)
            setLoadingColors(false)
        } else {
            setColors([])
            toast(<Notification type={"consultar_error"} withIcon />);
        }
    }

    async function getDataMaterials() {
        const params = {
            action: "multiselect",
            table: "materials",
            rows: "keyMaterial,nameMaterial",
            conditions: `enabled = 1`,
        };
        const res = await API.peticion(params)
        if (res.status === 200 && res.data.code === "200") {
            const data = res.data.data;
            setMaterials(data)
            setLoadingMaterials(false)
        } else {
            setMaterials([])
            toast(<Notification type={"consultar_error"} withIcon />);
        }
    }

    async function getDataUnits() {
        const params = {
            action: "multiselect",
            table: `messureunits`,
            rows: "keyUnit,nameUnit",
            conditions: `enabled = 1`,
        };
        const res = await API.peticion(params)
        if (res.status === 200 && res.data.code === "200") {
            const data = res.data.data;
            setUnits(data)
            setLoadingUnits(false)
        } else {
            setUnits([])
            toast(<Notification type={"consultar_error"} withIcon />);
        }
    }

    const FormSchema = Yup.object().shape({
        idFurniture: Yup.number(),
        keyGroup: Yup.number()
            .required("Seleccione un grupo valido"),
        keySubGroup: Yup.number()
            .required("Seleccione un subgrupo valido"),
        keyClass: Yup.number()
            .required("Seleccione una clase valida"),
        keyColor: Yup.number()
            .required("Seleccione un color valido"),
        keyMaterial: Yup.number()
            .required("Seleccione un material valido"),
        keyUnit: Yup.number()
            .required("Seleccione una unidad de medida valid"),
        nameFurniture: Yup.string()
            .required("Ingrese una denominación valida"),
        model: Yup.string()
            .required("Ingrese un modelo valido"),
        brand: Yup.string()
            .required("Ingrese una marca valida"),
        file: Yup.mixed()
            .test("file-type", "El documento debe ser de tipo imagen", (value) => {
                const files = ["image/png", "image/gif", "image/jpeg", "image/jpg"]
                return value ? value && files.includes(value.type) : true;
            })
            .test({
                message: `El documento debe pesar menos de 4MB`,
                test: (value) => value ? value?.size < 4000000 : true
            }),
    })

    const { handleSubmit, values, handleBlur, errors, touched, setFieldValue } =
        useFormik({
            initialValues: defaultForm,
            onSubmit: () => setModal(true),
            validationSchema: FormSchema,
        });

    const onChange = (e) => {
        const { name, value } = e.target
        setFieldValue([name], value);
        switch (name) {
            case "keyGroup":
                setKeyGroup(value);
                break;
            case "keySubGroup":
                setKeySubGroup(value);
                break;
            default: break;
        }
    }

    const handleFiles = event => {
        const { files } = event.target
        setFieldValue('file', files[0]);
    }

    useEffect(() => {
        if (groups.length === 0) {
            getDataGrupo();
            getDataColors();
            getDataMaterials();
            getDataUnits();
        } else {
            if (keyGroup) {
                getDataSubGrupo(keyGroup, loadingData && data);
                if (keySubGroup) {
                    getDataClases(keySubGroup, loadingData && data)
                }
            } else {
                setKeySubGroup("")
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [groups, keyGroup, keySubGroup, data])

    async function getClassSubGroup(id) {
        const params = {
            action: "multiselect",
            table: "furniture_groups fg join furniture_subgroups fs on fg.keyGroup=fs.keyGroup join furniture_classes fc on fc.keySubGroup=fs.keySubGroup",
            rows: "fg.keyGroup,fs.keySubGroup",
            conditions: `fc.keyClass=${id}`,
        };
        const res = await API.peticion(params)
        if (res.status === 200 && res.data.code === "200") {
            const data = res.data.data[0];
            setFieldValue(["keyGroup"], data.keyGroup);
            setKeyGroup(data.keyGroup)
            setFieldValue(["keySubGroup"], data.keySubGroup);
            setKeySubGroup(data.keySubGroup)
            setFieldValue(["keyClass"], id);
            setLoadingData(false)
            setLoading(false)
        } else {
            toast(<Notification type={"consultar_error"} withIcon />);
        }
    }

    async function getFurniture(id) {
        const params = {
            action: "multiselect",
            table: "furnitures A LEFT JOIN bookcase BC ON (A.idFurniture = BC.reference AND BC.enabled=1 AND BC.keyFileType = 24) LEFT JOIN files G ON (BC.idBook = G.idBook AND G.enabled=1)",
            rows: "A.keyColor,A.keyMaterial,A.keyUnit,A.model,A.brand,A.nameFurniture,A.keyClass,G.src",
            conditions: `A.enabled = 1 AND A.idFurniture=${id}`,
        };
        const res = await API.peticion(params)
        if (res.status === 200 && res.data.code === "200") {
            const data = res.data.data[0];
            setFieldValue(["keyColor"], data.keyColor);
            setFieldValue(["keyMaterial"], data.keyMaterial);
            setFieldValue(["keyUnit"], data.keyUnit);
            setFieldValue(["model"], data.model);
            setFieldValue(["brand"], data.brand);
            setFieldValue(["nameFurniture"], data.nameFurniture);
            setFirstSRC(data.src);
            getClassSubGroup(data.keyClass);
        } else {
            setTimeout(() => {
                toast(<Notification type={"consultar_error"} withIcon />);
                history.push("/template/bienesmuebles")
            }, 1000)
        }
    }

    useEffect(() => {
        if (data) {
            if (!loadingGroups && !loadingSubgroups && !loadingClasses && !loadingColors && !loadingUnits && !loadingMaterials) {
                getFurniture(data.idFurniture)
            }
        } else {
            setLoading(loadingGroups && loadingSubgroups && loadingClasses && loadingColors && loadingUnits && loadingMaterials)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadingGroups, loadingSubgroups, loadingClasses, loadingColors, loadingUnits, loadingMaterials, loadingData])


    if (loading) {
        return (<Loader />)
    }
    return (
        <>
            <form onSubmit={handleSubmit}>
                <Row>
                    <Col xs={12} md={4}>
                        <SelectTypeHeadSingle
                            label="Grupo"
                            inputName="keyGroup"
                            onChangeMethod={onChange}
                            onBlurMethod={handleBlur}
                            value={values.keyGroup}
                            optionsArray={groups}
                            optionValue="keyGroup"
                            optionName="nameGroup"
                            errors={errors.keyGroup}
                            touched={touched.keyGroup}
                            isRequired
                        />
                    </Col>
                    <Col xs={12} md={4}>
                        <SelectTypeHeadSingle
                            label="Subgrupo"
                            inputName="keySubGroup"
                            onChangeMethod={onChange}
                            onBlurMethod={handleBlur}
                            value={values.keySubGroup}
                            optionsArray={subgroups}
                            optionValue="keySubGroup"
                            optionName="nameSubGroup"
                            errors={errors.keySubGroup}
                            touched={touched.keySubGroup}
                            isRequired
                        />
                    </Col>
                    <Col xs={12} md={4}>
                        <SelectTypeHeadSingle
                            label="Clase"
                            inputName="keyClass"
                            onChangeMethod={onChange}
                            onBlurMethod={handleBlur}
                            value={values.keyClass}
                            optionsArray={classes}
                            optionValue="keyClass"
                            optionName="nameClass"
                            errors={errors.keyClass}
                            touched={touched.keyClass}
                            isRequired
                        />
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md={6}>
                        <SelectTypeHeadSingle
                            label="Color"
                            inputName="keyColor"
                            onChangeMethod={onChange}
                            onBlurMethod={handleBlur}
                            value={values.keyColor}
                            optionsArray={colors}
                            optionValue="keyColor"
                            optionName="nameColor"
                            errors={errors.keyColor}
                            touched={touched.keyColor}
                            isRequired
                        />
                    </Col>
                    <Col xs={12} md={6}>
                        <SelectTypeHeadSingle
                            label="Material"
                            inputName="keyMaterial"
                            onChangeMethod={onChange}
                            onBlurMethod={handleBlur}
                            value={values.keyMaterial}
                            optionsArray={materials}
                            optionValue="keyMaterial"
                            optionName="nameMaterial"
                            errors={errors.keyMaterial}
                            touched={touched.keyMaterial}
                            isRequired
                        />
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md={4}>
                        <SelectTypeHeadSingle
                            label="Unidad de medida"
                            inputName="keyUnit"
                            onChangeMethod={onChange}
                            onBlurMethod={handleBlur}
                            value={values.keyUnit}
                            optionsArray={units}
                            optionValue="keyUnit"
                            optionName="nameUnit"
                            errors={errors.keyUnit}
                            touched={touched.keyUnit}
                            isRequired
                        />
                    </Col>
                    <Col xs={12} md={4}>
                        <TextInput
                            label="Modelo"
                            inputName="model"
                            inputType="text"
                            onChangeMethod={onChange}
                            onBlurMethod={handleBlur}
                            value={values.model}
                            isRequired
                            touched={touched.model}
                            errors={errors.model}
                        />
                    </Col>
                    <Col xs={12} md={4}>
                        <TextInput
                            label="Marca"
                            inputName="brand"
                            inputType="text"
                            onChangeMethod={onChange}
                            onBlurMethod={handleBlur}
                            value={values.brand}
                            isRequired
                            touched={touched.brand}
                            errors={errors.brand}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md={4}>
                        <TextAreaInput
                            label="Denominación"
                            inputName="nameFurniture"
                            inputRows={12}
                            onChangeMethod={onChange}
                            onBlurMethod={handleBlur}
                            value={values.nameFurniture}
                            isRequired
                            touched={touched.nameFurniture}
                            errors={errors.nameFurniture}
                        />
                    </Col>
                    <Col xs={12} md={8}>
                        {data && firstSRC ? (
                            <div className="text-center mb-3">
                                <h6>Imagen actual:</h6>
                                <img src={firstSRC} alt="Imagen actual" style={{ width: "50%" }} />
                            </div>
                        ) : null}
                        <FilesInput
                            label="Imagen del bien"
                            inputName="file"
                            fileAccept=".jpeg,.jpg,.gif,.png"
                            onChangeMethod={handleFiles}
                            onBlurMethod={handleBlur}
                            value={values.file}
                            errors={values.file}
                        />
                        <div className="text-center">
                            <Button color="danger" className="mx-1 my-1" onClick={OnCancel}>Cancelar</Button>
                            <Button color="success" className="mx-1 my-1" type="submit">Guardar registro</Button>
                        </div>
                    </Col>
                </Row>
            </form>
            <ModalConfirmation
                modalTitle={!data ? "Agregar" : "Editar"}
                modal={modal}
                crear={(rows) => OnCreate(rows, OnSuccess)}
                editar={(rows) => OnEdit(rows, firstSRC, OnSuccess)}
                setModal={setModal}
                isEdit={data ? true : false}
                values={values}
            >
                <div className="d-flex justify-content-center">
                    <h6>¿Desea continuar con el registro?</h6>
                </div>
            </ModalConfirmation>
        </>
    )
}
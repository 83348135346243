import { useEffect, useRef, useState } from "react";
import axios from "axios";
import ModalConfirmation from "../../../components/Modal/ModalConfirmation";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Col, Row, Button, Collapse } from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import ModalTableVer from "../../../components/Modal/ModalTableVer";
import s from "../../nomina/reportes/filtros/InputClear.module.scss";
import Notification from "../../../components/Notification/Notification";
import { toast } from "react-toastify";
import { peticionEncript } from "../../../helpers/peticionesEncripted";
import TableComponentVer from "../../../components/TableComponent/TableComponentVer";
import { CancelOutlined } from "@material-ui/icons";
import { TextAreaInput, TextInput } from "../../../components/GenericInputsFormik";


/* TABLE */
const cabecerasTabla = [
  "Año", // []
  "Fecha Inicio", //
  "Fecha Termino", //
  "Descripción del proyecto", // []
  "Objetivo", //
  "Estatus Finanzas", // []
  "Ver",
];
const filtroTabla = [false, true, true, true, true, true, true];

/* MODAL TABLE */
const filtroModalTabla = [true, true, true, true, true];
const cabecerasModalTabla = [
  "#",
  "Núm. Empleado",
  "Nombre",
  "Unidad de trabajo",
  "Puesto",
  "Seleccionar",
];

const defaultForm = {
  idProyect: "",
  yearProyect: new Date(), // step 1
  idStaffCharge: "", // step 1
  nameStaff: "",
  nameProyect: "", // step 1
  objetive: "", // step 1
  startDate: "", // step 1
  endDate: "", // step 1
  keyStat: "66",
};

const FormSchema = Yup.object().shape({
  yearProyect: Yup.string().required("Seleccione un año"),
  nameStaff: Yup.string().required("Seleccione un empleado"),
  nameProyect: Yup.string().required("Ingrese un nombre de proyecto"),
  objetive: Yup.string().required("Ingrese un objetivo de proyecto"),
  startDate: Yup.date().required("Seleccione una fecha de inicio"),
  endDate: Yup.date()
    .required("Seleccione una fecha de termino")
    .min(
      Yup.ref("startDate"),
      "La fecha debe ser posterior a la fecha de inicio"
    ),
});

const date = new Date().getFullYear();

const RegistrarP = ({ idArea, setProyect, permissions }) => {
  const [parametrosTabla, setParametrosTabla] = useState({
    action: "datatable",
    table: "proyects A INNER JOIN status B ON A.keyStat = B.keyStat",
    rows: "A.idProyect, A.yearProyect, A.startDate, A.endDate ,A.nameProyect, A.objetive, B.nameStat, A.keyStat, A.notes",
    conditions: `A.enabled = 1 AND A.idArea = ${idArea}`,
    page: 0,
    records: 5,
    search: "",
    order: "",
  });
  const [paramsModalTabla] = useState({
    action: "datatable",
    table:
      "staff STF INNER JOIN employtypes AS EMT ON STF.keyEmployType = EMT.keyEmployType INNER JOIN jobstaff AS JBS ON JBS.idStaff = STF.idStaff INNER JOIN areas AS AR ON AR.idArea = JBS.idArea INNER JOIN jobtypes AS JBT ON JBT.keyJobType = STF.keyEmployType",
    rows: "STF.idStaff,STF.staffNumber,CONCAT(STF.name,' ',STF.pName,' ',STF.mName) as fullName,AR.name,JBT.nameJobType",
    conditions: `STF.enabled = 1 AND JBS.valid = 1 AND STF.keyStat = 1 AND JBS.enabled = 1 AND JBS.idArea = ${idArea}`,
    page: 0,
    records: 5,
    search: "",
    order: "",
  });
  const [modalConfirm, setModalConfirm] = useState(false);
  const [modalTable, setModalTable] = useState(false);
  const [showForm, setShowForm] = useState(false); /// Hook para mostrar formulario
  const [titleForm, setTitleForm] = useState(false); /// Hook para cambiar titulo del formulario
  const [minDate, setMinDate] = useState(""); /// Fecha minima
  const [maxDate, setMaxDate] = useState(""); /// Fecha maxima
  const formSection = useRef(null);

  const {
    handleSubmit,
    values,
    handleBlur,
    errors,
    touched,
    setFieldValue,
    handleReset,
  } = useFormik({
    initialValues: defaultForm,
    validationSchema: FormSchema,
    onSubmit: (values) => {
      setModalConfirm(true);
    },
    enableReinitialize: true,
  });

  useEffect(()=>{
    getYearProyect();
  }, [values.yearProyect]);

  /* Funcion para guardar el id del proyecto seleccionado*/
  const openProyect = (el) => {
    setProyect({
      id: +el[0],
      dateS: el[2],
      dateE: el[3],
      name: el[4],
      objetive: el[5],
      notes:  el[8],
      keyStat:  +el[7],
      canEdit: +el[7] !== 67 && +el[7] !== 68
    });
  }

  const onChange = (e) => {
    if (e.target.name === "startDate" && e.target.value === "") {
      setFieldValue("endDate", "");
    }
    setFieldValue(e.target.name, e.target.value);
  };

  /* Funcion que se envia al modalTabla al seleccionar un empleado*/
  const selectEmpleado = (el) => {
    setFieldValue("idStaffCharge", el[0]);
    setFieldValue("nameStaff", `${el[1]} - ${el[2]}`);
    setModalTable(false);
  };

  /* Funcion para guardar/editar la información del proyecto */
  const editInfo = async () => {
    let rows = { ...values };
    delete rows.nameStaff;
    rows.yearProyect = new Date(values.yearProyect).getFullYear();
    rows.idArea = idArea;

    const params = {
      action: "",
      table: "proyects",
      rows: rows,
      validate: [["nameProyect", "yearProyect"]],
    };
    if(values.idProyect === "") params.action = "insert";
    else{
      params.action = "update";
      params.condition = { idProyect: values.idProyect };
    }

    const dataEncrypted = peticionEncript(params);
    await axios
      .post(process.env.REACT_APP_API + "receiver/receiver.php", dataEncrypted)
      .then((res) => {
        if (res.status === 200 && res.data.status === "success") {
          toast(
            <Notification
              type={"agrega_exito"}
              backMessage={res.data.message}
              withIcon
            />,
            { closeButton: false }
          );
          setShowForm(false); //maybe
          setParametrosTabla({ ...parametrosTabla });
        } else {
          toast(
            <Notification
              type={"agrega_error"}
              backMessage={res.data.message}
              withIcon
            />,
            { closeButton: false }
          );
        }
      })
      .catch((err) => {
        toast(<Notification type={"consultar_servidor_error"} withIcon />, {
          closeButton: false,
        });
      });
  };

  /* Función para scrolear a un punto de la aplicación */
  const scrollTo = () => {
    formSection.current.scrollIntoView({ behavior: "smooth" });
  };

  /* Función para limitar el timpo del proyecto */
  const getYearProyect = () => {
    const dateY = values.yearProyect.getFullYear();
    setMinDate(`${dateY}-01-01`);
    setMaxDate(`${dateY}-12-31`);
  }

  return (
    <div className="mt-3">
      <div className="mb-4">
        <h4>Proyectos</h4>
        <p className="text-muted">Lista de proyectos</p>
      </div>

      <TableComponentVer
        cabecerasTabla={cabecerasTabla}
        filtro={filtroTabla}
        ver={openProyect}
        parametros={parametrosTabla}
      />

      <div className="row mt-2" ref={formSection}>
        <div className="col-12 col-sm-6">
          <h5 className="text-center text-sm-left">{titleForm}</h5>
        </div>
        {permissions.INS && <div className="col-12 col-sm-6">
          <div className="text-center text-sm-right">
            <Button
              color="add"
              onClick={() => setShowForm(true)}
              disabled={showForm}
            >
              Agregar
            </Button>
          </div>
        </div>}
      </div>

      <Collapse isOpen={showForm} className="mt-2" onEntered={()=> scrollTo()}>
        <form onSubmit={handleSubmit} onReset={handleReset} className="mt-2">
          <ModalConfirmation
            modalTitle="Guardar proyecto"
            modal={modalConfirm}
            setModal={setModalConfirm}
            editar={() => {
              editInfo();
            }}
            isEdit={true}
            values={values}
          >
            <div className="d-flex justify-content-center">
              <h6>¿Está seguro de guardar el proyecto?</h6>
            </div>
          </ModalConfirmation>
          <Row>
            <Col xs={12} lg={6}>
              <div className="form-group">
                <label>
                  Año del proyecto<span className="text-danger"> * </span>
                </label>
                <DatePicker
                  minDate={new Date(`${date}`)}
                  selected={values.yearProyect}
                  onChange={(date) =>
                    onChange({ target: { name: "yearProyect", value: date } })
                  }
                  onBlur={handleBlur}
                  showYearPicker
                  dateFormat="yyyy"
                  yearItemNumber={6}
                  className={
                    "form-control" +
                    (touched.yearProyect && errors.yearProyect
                      ? " is-invalid"
                      : "")
                  }
                />
                {touched.yearProyect && errors.yearProyect && (
                  <span style={{ color: "red" }}>{errors.yearProyect}</span>
                )}
              </div>
            </Col>
            <Col xs={12} lg={6}>
              <div className="form-group position-relative">
                <label htmlFor="nameStaff">
                  Empleado a cargo <span className="text-danger">*</span>
                </label>
                <div className="input-group">
                  <input
                    name="nameStaff"
                    onChange={onChange}
                    onBlur={handleBlur}
                    className={
                      "form-control" +
                      (errors.nameStaff && touched.nameStaff
                        ? " is-invalid"
                        : "")
                    }
                    value={values.nameStaff}
                    disabled
                  />
                  <div className="input-group-append">
                    <button
                      className="btn btn-secondary"
                      style={{ zIndex: "auto" }}
                      type="button"
                      onClick={() => setModalTable(true)}
                    >
                      <i className="fa fa-search" />
                    </button>
                  </div>
                </div>
                {values.nameStaff !== "" && (
                  <CancelOutlined
                    className={s.btnClear}
                    onClick={() => {
                      setFieldValue("idStaffCharge", "");
                      setFieldValue("nameStaff", "");
                    }}
                  />
                )}
                {touched.nameStaff && errors.nameStaff && (
                  <span style={{ color: "red" }}>{errors.nameStaff}</span>
                )}
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={12} lg={6}>
              <TextInput
                label="Nombre del proyecto"
                inputType="text"
                inputName="nameProyect"
                onChangeMethod={onChange}
                onBlurMethod={handleBlur}
                value={values.nameProyect}
                touched={touched.nameProyect}
                errors={errors.nameProyect}
                isRequired
                isMax={250}
              />
            </Col>
            <Col xs={12} lg={6}>
              <TextAreaInput
                label="Objetivo del proyecto"
                inputName="objetive"
                inputRows={4}
                onChangeMethod={onChange}
                onBlurMethod={handleBlur}
                value={values.objetive}
                isRequired
                touched={touched.objetive}
                errors={errors.objetive}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12} lg={6}>
              <TextInput
                label="Fecha inicial"
                inputType="date"
                inputName="startDate"
                onChangeMethod={onChange}
                onBlurMethod={handleBlur}
                value={values.startDate}
                isRequired
                touched={touched.startDate}
                errors={errors.startDate}
                isMin={minDate}
                isMax={maxDate}
              />
            </Col>
            <Col xs={12} lg={6}>
              <TextInput
                label="Fecha final"
                inputType="date"
                inputName="endDate"
                onChangeMethod={onChange}
                onBlurMethod={handleBlur}
                value={values.endDate}
                isRequired
                touched={touched.endDate}
                errors={errors.endDate}
                isMin={values.startDate || maxDate}
                isMax={maxDate}
                isDisabled={values.startDate === ""}
              />
            </Col>
          </Row>

          <Row className="mt-5">
            <div className="col-sm-6 order-2 order-sm-1 text-center text-sm-left mb-4">
              <Button
                color="danger"
                type="reset"
                onClick={() => {setShowForm(false)}}
              >
                Cancelar
              </Button>
            </div>
            <div className="col-sm-6 order-1 order-sm-2 text-center text-sm-right mb-4">
              <Button color="success" type="submit">
                Guardar
              </Button>
            </div>
          </Row>
        </form>
      </Collapse>

      <ModalTableVer
        modal={modalTable}
        setModal={setModalTable}
        title="Empleados"
        cabeceras={cabecerasModalTabla}
        filtro={filtroModalTabla}
        parametros={paramsModalTabla}
        selectRegistro={selectEmpleado}
        backdrop={"static"}
        keyboard={false}
      />
    </div>
  );
};

export default RegistrarP;

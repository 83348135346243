import React, { useState, useEffect } from "react";
import axios from "axios";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Button, Collapse, Row, Col } from "reactstrap";
import { peticionesReceiver } from "../../../helpers/peticionesReceiver";
import ModalDelete from "../../../components/Modal/ModalDelete";
import Datatable from "../../../components/Datatable/Datatable";
import { GetPermissions } from "../../../libs/permissions/getPermissions";
import { toast } from "react-toastify";
import Notification from "../../../components/Notification/Notification";
import ModalConfirmation from "../../../components/Modal/ModalConfirmation";
import { SelectTypeHeadSingle } from "../../../components/GenericInputsFormik/index";
import { NumberInput } from "../../../components/GenericInputsFormik/NumberInput";
import { Loader } from "react-bootstrap-typeahead";

const CatalogoArchivosFirma = () => {
  const API = peticionesReceiver();
  const [collapse, setCollapse] = useState(false);
  const toggleCollapse = () => setCollapse(!collapse);
  const [modal, setModal] = useState(false);
  const [filesTypes, setFilesTypes] = useState([]);
  const [editando, setEditando] = useState(false);
  const [modalConfirmation, setModalConfirmation] = useState(false);

  const [loader, setLoader] = useState(true);

  const [loading, setLoading] = useState(true);
  const [permissions, setPermissions] = useState(true);
  GetPermissions(setPermissions, setLoading);

  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    getFileTypes();
  }, []);

  const getFileTypes = async () => {
    setLoader(false);
    const data = {
      action: "select",
      table: "filetypes",
      order: "nameFyleType ASC",
    };
    API.peticion(data)
      .then((res) => {
        if (res.status === 200 && res.data.status === "success") {
          setFilesTypes(res.data.data);
          setLoader(false);
        } else {
          toast(<Notification type={"consultar_error"} withIcon />);
        }
      })
      .catch((err) => {
        toast(<Notification type={"consultar_servidor_error"} withIcon />);
      });
  };

  const headers = [
    "#",
    "Documento",
    "Numero de firmantes",
    "Editar",
    "Eliminar",
  ];
  const columns = [
    { data: "index" },
    { data: "nameFyleType" },
    { data: "singers" },
    {
      data: null,
      render: function (row) {
        return permissions.UPD ? (
          <td
            onClick={() => getForEdit(row)}
            className="text-center d-flex justify-content-center"
          >
            <i className={" fa fa-pencil text-warning handCursor"} />
          </td>
        ) : (
          "Accion no permitida"
        );
      },
    },
    {
      data: null,
      render: function (row) {
        return permissions.DEL ? (
          <>
            <ModalDelete
              modalTitle="Eliminar"
              id={row.idSetup}
              table={["filesignsetup"]}
              nameColumn={["idSetup"]}
              elimina={() => deleteFileType(row)}
              setRefresh={setRefresh}
              type="icon"
            >
              <h6>¿Seguro de eliminar el registro?</h6>
              No podrá recuperar la información después de ser eliminada
            </ModalDelete>
          </>
        ) : (
          "Accion no permitida"
        );
      },
    },
  ];

  const deleteFileType = async (obj) => {
    const status = deleteFromZeeFirma(obj);
    if (status) {
      deleteFromZapp(obj);
    }
  };

  const deleteFromZeeFirma = async (obj) => {
    let band = false;
    const params = {
      query: {
        nameSystem: "SAPP",
        name: obj.nameFyleType,
        enabled: true,
      },
      update: {
        enabled: false,
      },
    };

    await axios({
      url: "https://www.zeefirma.com/api/fileType/put",
      data: params,
      method: "PUT",
      auth: {
        username: "SAPP",
        password: process.env.REACT_APP_ZEEFIRMA
      }
    })
      .then((res) => {
        if (res.status === 200 || res.status === "200") {
          toast(
            <Notification
              type={"elimina_exito"}
              backMessage={res.data.message}
              withIcon
            />
          );
          band = true;
        } else {
          toast(
            <Notification
              type={"elimina_error"}
              backMessage={res.data.message}
              withIcon
            />
          );
          band = false;
        }

        // <Notification
        //   type={"agrega_exito"}
        //   backMessage={res.data.message}
        //   withIcon
        // />;
      })
      .catch((err) => {

        toast(
          toast(<Notification type={"consultar_servidor_error"} withIcon />)
        );
      });
    // return respuesta;

    return band;
  };

  const deleteFromZapp = async (id) => {
    const params = {
      action: "delete",
      table: "filesignsetup",
      condition: { idSetup: id.idSetup },
    };

    await API.peticion(params)
      .then((res) => {
        if (res.data.status === "success" && res.data.code === "200") {
          toast(
            <Notification
              type={"elimina_exito"}
              backMessage={res.data.message}
              withIcon
            />
          );
          setRefresh(true);
        } else {
          toast(
            <Notification
              type={"elimina_error"}
              backMessage={res.data.message}
              withIcon
            />,
            { closeButton: false }
          );
        }
      })
      .catch((err) => {
        toast(<Notification type={"consultar_servidor_error"} withIcon />);
      });
  };

  const columnDefs = [
    {
      targets: [0, 1, 2, 3, 4],
      className: "text-center",
    },
    {
      orderable: false,
      targets: [0],
    },
  ];

  const getParamsTableElmt = (order, records, page, search) => {
    const params = {
      action: "datatable",
      table:
        "filesignsetup  A INNER JOIN filetypes B  on (A.keyFileType = B.keyFileType)",
      rows: "A.idSetup, A.keyFileType,B.nameFyleType, A.singers",
      conditions: `A.enabled = 1`,
      order,
      records: records.toString(),
      page: (page * records).toString(),
      search,
    };
    return params;
  };

  async function getData(order, records, page, search) {
    try {
      const res = await API.peticion(
        getParamsTableElmt(order, records, page, search)
      );
      if (res.status === 200 && res.data.code === "200") {
        const data = res.data;

        /* data.forEach((value, index) => (value.index = 1 + index)); */
        data.data.forEach((value, index) => (value.index = 1 + index));
        return data;
      } else {
        toast(
          <Notification
            type={"consultar_error"}
            backMessage={res.data.message}
          />
        );
        return null;
      }
    } catch (err) {
      toast(<Notification type={"consultar_servidor_error"} withIcon />);
      return null;
    }
  }

  const onChange = (e) => {
    const { name, value } = e.target;
    setFieldValue([name], value);
  };

  const getForEdit = async (row) => {
    if (!collapse) {
      setFieldValue("idSetup", row.idSetup);
      setFieldValue("keyFileType", row.keyFileType);
      setFieldValue("singers", row.singers);
      setEditando(true);
      setCollapse(true);
    } else {
      toast(
        <Notification
          type="warning"
          backMessage="Guarde sus cambios antes de continuar"
        />
      );
    }
  };

  const saveDocument = async () => {
    const status = await sendZeeFirmaPetition();

    if (status) {

      await sendSappPetition();
    }

    setRefresh(true);
    resetForm();
    setCollapse(false);
  };

  const sendZeeFirmaPetition = async () => {
    let band = false;
    const nameFile = filesTypes.find(
      (type) => +type.keyFileType === +values.keyFileType
    ).nameFyleType;
    const params =
      editando === true
        ? {
          query: {
            name: nameFile,
            enabled: true,
            nameSystem: "SAPP",
          },
          update: {
            name: nameFile,
            idTypeFileOrigin: values.keyFileType,
            noSigners: values.singers,
          },
        }
        : {
          name: nameFile,
          nameSystem: "SAPP",
          idTypeFileOrigin: values.keyFileType,
          noSigners: values.singers,
        };

    if (editando) {
      await axios({
        url: "https://www.zeefirma.com/api/fileType/put",
        data: params,
        method: "PUT",
        auth: {
          username: "SAPP",
          password: process.env.REACT_APP_ZEEFIRMA
        }
      })
        /* .post(route, finalData) */
        .then((res) => {
          if (res.status === 200 || res.status === "200") {
            toast(
              <Notification
                type={"agrega_exito"}
                backMessage={res.data.message}
                withIcon
              />
            );

            band = true;
          } else {
            toast(
              <Notification
                type={"agrega_exito"}
                backMessage={res.data.message}
                withIcon
              />
            );

            band = false;
          }

          /* if (res.data) return true; */
          // <Notification
          //   type={"agrega_exito"}
          //   backMessage={res.data.message}
          //   withIcon
          // />;
        })
        .catch((err) => {

          toast(<Notification type={"consultar_servidor_error"} withIcon />);
        });
      // return respuesta;
    } else {
      await axios({
        url: "https://www.zeefirma.com/api/fileType/create",
        data: params,
        method: "POST",
        auth: {
          username: "SAPP",
          password: process.env.REACT_APP_ZEEFIRMA
        }
      })
        /* .post(route, finalData) */
        .then((res) => {


          if (res.status === 200 || res.status === "200") {

            toast(
              <Notification
                type={"agrega_exito"}
                backMessage={res.message}
                withIcon
              />
            );
            band = true;
          } else {

            toast(
              <Notification
                type={"agrega_error"}
                backMessage={res.message}
                withIcon
              />
            );
          }
        })
        .catch((err) => {

          toast(<Notification type={"consultar_servidor_error"} withIcon />);
        });

    }
    setEditando(false)
    return band;
  };

  const sendSappPetition = async () => {
    const data =
      !editando === true
        ? {
          action: "insert",
          table: "filesignsetup",
          rows: {
            keyFileType: +values.keyFileType,
            singers: +values.singers,
          },
          validate: [["keyFileType", "singers"]],
        }
        : {
          action: "update",
          table: "filesignsetup",
          rows: values,
          condition: {
            idSetup: values.idSetup,
          },
          validate: [["keyFileType", "singers"]],
        };
    try {
      const res = await API.peticion(data);
      if (res.status === 200 && res.data.status === "success") {
        toast(
          <Notification
            type={"agrega_exito"}
            backMessage={res.data.message}
            withIcon
          />
        );
      } else {
        toast(
          <Notification
            type={"agrega_error"}
            backMessage={res.data.message}
            withIcon
          />
        );
      }
    } catch (err) {
      toast(<Notification type={"consultar_servidor_error"} withIcon />, {
        closeButton: false,
      });
    }
  };

  const FormSchema = Yup.object().shape({
    keyFileType: Yup.string().required("Seleccione el tipo de docuemnto"),
    singers: Yup.string().required("Ingrese el numero de firmantes"),
  });

  const {
    handleSubmit,
    handleBlur,
    values,
    errors,
    touched,
    setFieldValue,
    resetForm,
  } = useFormik({
    initialValues: {
      idSetup: "",
      keyFileType: "",
      singers: "",
    },
    onSubmit: () => {
      setModalConfirmation(true);
    },
    validationSchema: FormSchema,
    enableReinitialize: true,
  });

  const resetFormuario = () => {
    setEditando(false);
    setCollapse(false);
    resetForm();
  };

  return (
    <div>
      {loader ? (
        <Loader />
      ) : (
        <>
          {permissions.CON ? (
            <>
              <div className="text-center mb-5">
                <h1>Catálogo de documentos</h1>
              </div>

              <div>
                <Datatable
                  headers={headers}
                  columns={columns}
                  columnDefs={columnDefs}
                  petition={getData}
                  control="back"
                  stateRefresh={[refresh, setRefresh]}
                  searching={true}
                  order={{ col: 1, opt: "desc" }}
                />
              </div>

              <div className="d-flex justify-content-end mb-3">
                <Button color="success" onClick={() => toggleCollapse()}>
                  Agregar
                </Button>
              </div>

              <Collapse isOpen={collapse} className="mt-3">
                <form onSubmit={handleSubmit}>
                  <Row>
                    <Col xs={12} lg={6}>
                      <SelectTypeHeadSingle
                        label="Tipo de documento"
                        isRequired={true}
                        inputName="keyFileType"
                        optionsArray={filesTypes}
                        defaultOption="Seleccione un tipo de documento"
                        onChangeMethod={onChange}
                        value={values.keyFileType}
                        optionValue="keyFileType"
                        optionName="nameFyleType"
                      />
                    </Col>

                    <Col xs={12} lg={6}>
                      <NumberInput
                        label="Numero de firmas"
                        inputName="singers"
                        onChangeMethod={onChange}
                        onBlurMethod={handleBlur}
                        value={values.singers}
                        touched={touched.singers}
                        errors={errors.singers}
                        disableSep={true}
                      />
                    </Col>
                  </Row>

                  <div className="d-flex justify-content-around mt-2">
                    <div>
                      <Button
                        color="danger"
                        onClick={() => {
                          resetFormuario();
                        }}
                      >
                        Cancelar
                      </Button>
                    </div>
                    <div>
                      <Button type="submit" color="success">
                        Agregar
                      </Button>
                    </div>
                  </div>
                </form>
              </Collapse>

              <ModalConfirmation
                modalTitle={editando ? "Editar" : "Crear"}
                modal={modalConfirmation}
                setModal={setModalConfirmation}
                crear={saveDocument}
                isEdit={false}
                values={values}
              >
                <div className="text-center">
                  Esta seguro de continuar con el registro
                </div>
              </ModalConfirmation>
            </>
          ) : (
            "No tiene permisos para consultar  este modulo"
          )}
        </>
      )}
    </div>
  );
};

export default CatalogoArchivosFirma;

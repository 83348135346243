import { peticionesReceiver } from "../../../helpers/peticionesReceiver";
import Notification from "../../../components/Notification/Notification";
import { toast } from "react-toastify";
const API = peticionesReceiver();

function getParams(rows, generico) {
  //SI TIENE UN RFC GENERICO PUEDE REPETIRSE
  if (generico) {
    return {
      action: "insert",
      table: "providerspayroll",
      rows,
      validate: [
        ["businessName"]
      ]
    };
  } else { //SI NO TIENE RFC GENERICO NO PUEDE REPETIRSE
    return {
      action: "insert",
      table: "providerspayroll",
      rows,
      validate: [
        ["businessName", "rfc"]
      ]
    };
  }
}

export const createProveedor = async (leaveN) => {
    
  var leaveNTemp = leaveN
  delete leaveN.idAccountList
    delete leaveN.idProviderPayroll

    leaveNTemp.signatureAgreementDate = `${leaveN.signatureAgreementDate.getFullYear()}-${leaveN.signatureAgreementDate.getMonth()}-${leaveN.signatureAgreementDate.getDate()}`
    leaveNTemp.startAgreementDate = `${leaveN.startAgreementDate.getFullYear()}-${leaveN.startAgreementDate.getMonth()}-${leaveN.startAgreementDate.getDate()}`
    leaveNTemp.endAgreementDate = `${leaveN.endAgreementDate.getFullYear()}-${leaveN.endAgreementDate.getMonth()}-${leaveN.endAgreementDate.getDate()}`


    

    console.log(leaveNTemp)
    if (leaveNTemp) {
      const params = getParams(leaveNTemp, leaveNTemp.rfc === "XAXX010101000" || leaveNTemp.rfc === "XEXX010101000")
      API.peticion(params).then((res) => { 
          if (res.status === 200 && res.data.code === "200") {
            toast(<Notification type={"agrega_exito"} withIcon />);
            const dataContent = Object.values(res.data.data[0]);
            localStorage.setItem("dataContent", dataContent.join('|-|'));
            window.location.href = "#/template/proveedoresnomina/editar"
          }else{
            toast(<Notification type={"agrega_error"} backMessage={res.data.message} withIcon />);
          }
        })
        .catch((err) =>{
        toast(<Notification type={"consultar_servidor_error"} withIcon />);
      });
    }
  };

function getParamsUpdate(leaveU, generico) {
    delete leaveU.idAccountList
    if (generico) {
      return {
        action: "update",
        table: "providerspayroll",
        rows: leaveU,
        condition: {
          idProviderPayroll: leaveU.idProviderPayroll,
        },
        validate: [["businessName", "rfc"]]
      };
    } else{
      return {
        action: "update",
        table: "providerspayroll",
        rows: leaveU,
        condition: {
          idProviderPayroll: leaveU.idProviderPayroll,
        },
        validate: [["businessName", "rfc"]]
      };
    }
  }

 export const updateProveedor = async (leaveU) => {
    if (leaveU) {
      const params = getParamsUpdate(leaveU, leaveU.rfc === "XAXX010101000" || leaveU.rfc === "XEXX010101000")
      API.peticion(params).then((res) => { 
          if (res.status === 200 && res.data.code === "200") {
            toast(<Notification type={"modifica_exito"} withIcon />);
            const dataContent = Object.values(res.data.data[0]);
            localStorage.setItem("dataContent", dataContent.join('|-|'));
            window.location.href = "#/template/proveedoresnomina/editar"
          }else{
            toast(<Notification type={"modifica_error"} backMessage={res.data.message} withIcon />);
          }
        })
        .catch((err) =>{
        toast(<Notification type={"consultar_servidor_error"} withIcon />);
      });
    }
  };
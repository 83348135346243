import * as Yup from "yup"
import { useFormik } from "formik";

import { FilesInput } from "../../../../../components/GenericInputsFormik";
import { Button, Col, Row } from "reactstrap";
import { useRef, useState } from "react";
import ModalConfirmation from "../../../../../components/Modal/ModalConfirmation";
import { AddFile } from "../../../../patrimonio/bienes-inmuebles/functions";
import { DeleteArchivo } from "../../../../patrimonio/bienes-inmuebles/functions";
import TableComponentCustom from "../../../../../components/TableComponent/TableComponentCustom";
import { toast } from "react-toastify";
import Notification from "../../../../../components/Notification/Notification";
import ModalViewFile from "../../../../../components/Modal/ModalViewFile";
import { peticionesReceiver } from "../../../../../helpers/peticionesReceiver";

function getCabeceras(add) {
    if (add) {
        return ["No", "Documento", "Fecha de registro", "Eliminar", "Ver"]
    }
    return ["No", "Documento", "Fecha de registro", "Ver"]
}

export default function AnexosTecnicos({ idCot, add = true }) {
    const refInput = useRef()
    const [modal, setModal] = useState(false)
    const cabeceras = getCabeceras(add);
    const [params, setParams] = useState({
        action: "datatable",
        rows: "G.idFile,FT.nameFyleType,G.created", ///sin espacios y comas
        table: "quotes A INNER JOIN bookcase BC ON (BC.reference = A.idQuote) LEFT JOIN files G ON (BC.idBook = G.idBook) INNER JOIN filetypes FT ON FT.keyFileType=BC.keyFileType",
        conditions: `A.enabled = 1 AND BC.enabled = 1 AND G.enabled = 1 AND FT.enabled = 1 AND A.idQuote=${idCot} AND FT.keyFileType = 41`,
        page: 0,
        records: 5,
        search: "",
        orderBy: "A.idQuote desc"
    });
    const [linkFile, setlinkFile] = useState();
    const [modalFile, setModalFile] = useState(false);

    const defaultForm = {
        reference: idCot,
        keyFileType: "41",
        nameFile: "",
        file: ""
    }

    const FormSchema = Yup.object().shape({
        file: Yup.mixed()
            .test("file-type", "El documento debe ser de tipo pdf", (value) => {
                const files = ["application/pdf"]
                return value && files.includes(value.type);
            })
            .test({
                message: `El documento debe pesar menos de 4MB`,
                test: (value) => value?.size < 4000000,
            })
    })

    const { handleSubmit, values, handleBlur, errors, touched, setFieldValue, resetForm } =
        useFormik({
            initialValues: defaultForm,
            onSubmit: () => setModal(true),
            validationSchema: FormSchema,
        });

    const handleFiles = event => {
        const { files } = event.target
        setFieldValue('file', files[0]);
    }

    const getDocument = async (value) => {
        const params = {
            action: "multiselect",
            table: "files",
            rows: "src",
            conditions: `enabled = 1 AND idFile=${value[0]}`,
        };
        const API = peticionesReceiver()
        const res = await API.peticion(params)
        if (res.status === 200 && res.data.code === "200") {
            const data = res.data.data;
            if (data.length > 0) {
                setlinkFile(res.data.data[0].src)
                setModalFile(true)
            } else {
                toast(<Notification type={"consultar_error"} withIcon />);
            }
        } else {
            toast(<Notification type={"consultar_error"} withIcon />);
        }
    }

    const OnRefresh = () => {
        setParams({
            action: "datatable",
            rows: "G.idFile,FT.nameFyleType,G.created", ///sin espacios y comas
            table: "quotes A INNER JOIN bookcase BC ON (BC.reference = A.idQuote) LEFT JOIN files G ON (BC.idBook = G.idBook) INNER JOIN filetypes FT ON FT.keyFileType=BC.keyFileType",
            conditions: `A.enabled = 1 AND BC.enabled = 1 AND G.enabled = 1 AND FT.enabled = 1 AND A.idQuote=${idCot} AND FT.keyFileType = 41`,
            page: 0,
            records: 5,
            search: "",
            orderBy: "A.idQuote desc"
        })
        refInput.current.value = ""
        resetForm()
    }

    return (
        <>
            {add && (
                <form onSubmit={handleSubmit}>
                    <Row>
                        <Col xs={12} md={8}>
                            <FilesInput
                                label="Adjuntar anexo técnico administrativo"
                                inputName="file"
                                fileAccept=".pdf"
                                onChangeMethod={handleFiles}
                                onBlurMethod={handleBlur}
                                value={values.file}
                                errors={errors.file}
                                touched={touched.file}
                                refInput={refInput}
                        />
                        </Col>
                        <Col xs={12} md={4}>
                            <Button className="mt-xs-0 mt-md-4" type="submit">Agregar anexo</Button>
                        </Col>
                    </Row>
                </form>
            )}
            <TableComponentCustom
                titulo="Archivos registrados"
                cabecerasTabla={cabeceras}
                filtro={[true, true, true, true, true]}
                parametros={params}
                addWatch
                actionWatch={getDocument}
                addDelete={add}
                elimina={(row) => DeleteArchivo(row, OnRefresh)}
            />
            <ModalConfirmation
                modalTitle={"AGREGAR DOCUMENTO"}
                modal={modal}
                crear={(rows) => AddFile(rows, OnRefresh)}
                setModal={setModal}
                isEdit={false}
                values={values}
            >
                <div className="d-flex justify-content-center">
                    <h6>¿Desea continuar con el registro?</h6>
                </div>
            </ModalConfirmation>
            {linkFile ? (
                <ModalViewFile
                    modal={modalFile}
                    setModal={setModalFile}
                    backdrop={true}
                    keyboard={true}
                    btnClose={true}
                    fileLink={linkFile}
                />
            ) : null}
        </>
    )
}
import PercepcionesExtraordinarias from "../../../pages/percepciones-extraordinarias/configuracion-PE/PercepcionesExtraordinarias"
import CalculoPE from "../../../pages/percepciones-extraordinarias/calculo-PE/crear/CrearCalculo"
import PagosExtraordinarios from "../../../pages/percepciones-extraordinarias/pagos-PE/Pagos-Pe"

const rutasPercepcionesExtraordinarias = [

    { path: "/template/configuracion-percepciones-extraordinarias", component: PercepcionesExtraordinarias },
    { path: "/template/calculo-percepciones-extraordinarias", component: CalculoPE },
    { path: "/template/pagos-percepciones-extraordinarias", component: PagosExtraordinarios },
]

export default rutasPercepcionesExtraordinarias
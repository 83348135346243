import React, { useState, useEffect } from "react";
import * as Icons from "@material-ui/icons";
import {
  Col,
  Row,
  Table,
  Pagination,
  PaginationItem,
  PaginationLink,
  Button,
} from "reactstrap";
import Widget from "../../../components/Widget/Widget";
import s from "../../../pages/tables/Tables.module.scss";
import { useHistory } from "react-router-dom";
import ModalComponentEliminar from "../../../components/Modal/ModalDelete"

/**
 * @param {Object} props
 * @param {string} props.titulo - Titulo de la tabla
 * @param {Array<Object>} props.cabecerasTabla - Arreglo con las cabeceras de la tabla
 * @param {Array<boolean>} props.contenido - Arreglo con datos
 * @param {Array<boolean>} props.filtro - Arreglo con filtro para mostrar datos
 * @param {string} props.rutaVer - Ruta de redirección al dar click en ver
 * @param {string} props.tipo - Formato en que se mostrara la tabla ["show"]
 * @param {Function} props.elimina - Opcional: Funcion de activación que sucede al momento de dar click
 * @param {Function} props.getPermissions - Funcion para obtener los datos de contenido
 * @param {Object} props.permissionsAuth - Objeto con los permisos del ususario sobr eel modulo
*/
export const TableShowComponent = (props) => {
  const { titulo, cabecerasTabla, contenido, filtro, rutaVer, tipo, elimina, getPermissions, permissionsAuth } =
    props;
  const history = useHistory();
  const [firstTable] = useState(contenido);
  /* const [firstTableCurrentPage, setFirstTableCurrentPage] = useState(0);
  const pageSize = 5;
  const firstTablePagesCount = Math.ceil(firstTable.length / pageSize);
  const setFirstTablePage = (e, index) => {
    e.preventDefault();
    setFirstTableCurrentPage(index);
  }; */
  function handleSubmit(usuario) {
    if (tipo == "show") {
      history.push({
        pathname: rutaVer,
        state: { usuario },
      });
      localStorage.setItem("dataContent", usuario.toString());
    }
  }
  const mapea = (element, index) => {
    if (filtro[index]) {
      return <td className="text-center">{element}</td>;
    } else {
      return <></>;
    }
  };
  return (
    <div>
      <Row className="mb-4">
        <Col>
          <Widget>
            <div className={s.tableTitle}>
              <Col className="mb-12">
                <h2 className="text-center">{titulo} </h2>
              </Col>
              {/* <Button
                color="success"
                className="btn-rounded with-icon"
                onClick={() => {
                  handleSubmit();
                }}
              >
                Agregar
              </Button> */}
              <div className="d-flex"></div>
            </div>
            <div className="widget-table-overflow">
              <Table
                className={`table-striped table-borderless table-hover ${s.statesTable}`}
                responsive
              >
                <thead>
                  <tr>
                    <>
                      {cabecerasTabla.map((cabecera, index) => (
                        <th className="text-center" key={index}>
                          {
                            cabecera.toLowerCase() === "eliminar" ? 
                            permissionsAuth.DEL && "Eliminar" : 
                            cabecera
                          }
                        </th>
                      ))}
                    </>
                  </tr>
                </thead>
                <tbody>
                  {contenido.length != 0 ? (
                    contenido.map((registro) => (
                      <tr key={registro[0]}>
                        {registro.map((elemento, index) => {
                          if (filtro !== null) {
                            return mapea(elemento, index);
                          } else {
                            return <td className="text-center">{elemento}</td>;
                          }
                        })}
                        {tipo == "show" ? (
                          <td className="text-center">
                            {" "}
                            <Button
                              color="info"
                              onClick={() => handleSubmit(registro)}
                            >
                              Ver
                            </Button>
                          </td>
                        ) : permissionsAuth.DEL && (
                          <td className="text-center">
                            {/* {" "}
                            <Button
                              color="danger"
                              onClick={() => elimina(registro[0])}
                            >
                              Eliminar
                            </Button> */}
                            <ModalComponentEliminar
                            buttonLabel="Demo"
                            buttonColor="primary"
                            modalTitle="Eliminar"
                            registro="Martinez"
                            eliminar={elimina}
                            id={registro[0]}
                            table={["permissions"]}
                            nameColumn={["idPermission"]}
                            getData={getPermissions}
                            >Seguro de eliminar el registro con área {registro[2]} y perfil {registro[5]}
                          </ModalComponentEliminar>
                          </td>
                        )}
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td
                        colspan={cabecerasTabla.length}
                        className="text-center"
                      >
                        No se encontro ningun registro
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </div>
          </Widget>
        </Col>
      </Row>
    </div>
  );
};
export default TableShowComponent;

import * as XlsxPopulate from "xlsx-populate";
import { saveAs } from "file-saver";

/**
 * Formateador de numeros de concurrencias
 */
const formatter = new Intl.NumberFormat("es-MX", {
  style: "currency",
  currency: "MXN",
});

/**
 * Hoja de estilos del excel
 */
const styles = {
  title: {
    horizontalAlignment: 'center', bold: true
  },
  headerBorder: {
    bold: "true",
    border: true,
    fill: "BFBFBF",
  },
  header: {
    bold: "true",
    border: true,
    fill: "BFBFBF",
  },
  headerBoderBold: {
    bold: "true",
    border: true,
  },
  contentBorder: {
    border: true,
  },
};

/**
 * 
 * @param {Reporte entregado de la BD} report 
 * @param {Titulo (Nombre de la institución)} title 
 * @param {Parametros pasados a la BD para su consulta} data 
 * @param {configuración de headers} config 
 */
export async function saveReport30AsExcel(report, title, data, config) {

  const firstTable = report.filter(data => data.keyTypeUnder === "1");
  const secondTable = report.filter(data => data.keyTypeUnder === "2");

  //Inicio de excel
  XlsxPopulate.fromBlankAsync().then(async (workbook) => {
    const sheet1 = workbook.sheet(0);

    //Titulo
    sheet1
      .range("A1:D2")
      .merged(true)
      .value("SAPP")
      .style(styles.title);
    //Subtitulos
    sheet1
      .range("A3:D3")
      .merged(true)
      .value(title)
    sheet1
      .range("A4:D4")
      .merged(true)
      .value(`Periodo: ${data.period} Año: ${data.year}`)
    if (data.idStateFund) {
      sheet1
      .range("A5:D5")
      .merged(true)
      .value(data.stateFund)
    }
    //Cabeceras
    sheet1
      .cell("A6")
      .value(config.headers[0])
      .style(styles.headerBoderBold)
    
    sheet1
      .cell("B6")
      .value(config.headers[1])
      .style(styles.headerBoderBold)
    
    sheet1
      .cell("C6")
      .value(config.headers[2])
      .style(styles.headerBoderBold)
    
    sheet1
      .cell("D6")
      .value(config.headers[3])
      .style(styles.headerBoderBold)
    
    addData(firstTable, secondTable, config, sheet1)
    
    sheet1.column("A").width(20);
    sheet1.column("B").width(50);
    sheet1.column("C").width(30);
    sheet1.column("D").width(20);
    
    return workbook.outputAsync().then((res) => {
        saveAs(res, `${title}.xlsx`);
    });
  });
}

/**
 * 
 * @param {Tabla principal} fTable 
 * @param {Tabla secundaria} sTable 
 * @param {Configuración del excel} config 
 * @param {Hoja de excel} sheet1 
 */
function addData(fTable, sTable, config, sheet1) {
  const lengthFTable = fTable.length;
  const lengthSTable = sTable.length;
  if (lengthFTable + lengthSTable > 4000) { //EN CASO DE SOBREPASAR LOS 4000 registros
    //PRIMERA TABLA SIN ESTILOS
    let FTImporte = 0;
    fTable.forEach((value, index) => {
      sheet1
        .cell(`A${index + 7}`)
        .value(value[config.content[0].value])
      sheet1
        .cell(`B${index + 7}`)
        .value(value[config.content[1].value])
      sheet1
        .cell(`C${index + 7}`)
        .value(value[config.content[2].value])
      const importe = value[config.content[3].value];
      sheet1
        .cell(`D${index + 7}`)
        .value(importe)
      const NImp = importe.replace("$", "").replace(/,/g, "");
      FTImporte += Number(NImp);
    })
    sheet1
      .cell(`C${lengthFTable + 7}`)
      .value("TOTAL")
    sheet1
      .cell(`D${lengthFTable + 7}`)
      .value(formatter.format(FTImporte))
    //SEGUNDA TABLA
    let STImporte = 0;
    sTable.forEach((value, index) => {
      sheet1
        .cell(`A${index + lengthFTable + 10}`)
        .value(value[config.content[0].value])
      sheet1
        .cell(`B${index + lengthFTable + 10}`)
        .value(value[config.content[1].value])
      sheet1
        .cell(`C${index + lengthFTable + 10}`)
        .value(value[config.content[2].value])
      const importe = value[config.content[3].value];
      sheet1
        .cell(`D${index + lengthFTable + 10}`)
        .value(importe)
      const NImp = importe.replace("$", "").replace(/,/g, "");
      STImporte += Number(NImp);
    })
    sheet1
      .range(`A${lengthFTable + lengthSTable + 10}:B${lengthFTable + lengthSTable + 10}`)
      .merged(true)
    sheet1
      .cell(`C${lengthFTable + lengthSTable + 10}`)
      .value("TOTAL")
    sheet1
      .cell(`D${lengthFTable + lengthSTable + 10}`)
      .value(formatter.format(STImporte))
  } else { //SI NO SOBREPASA LOS 4000 registros
    //PRIMERA TABLA CON ESTILOS
    let FTImporte = 0;
    fTable.forEach((value, index) => {
      sheet1
        .cell(`A${index + 7}`)
        .value(value[config.content[0].value])
        .style(styles.contentBorder)
      sheet1
        .cell(`B${index + 7}`)
        .value(value[config.content[1].value])
        .style(styles.contentBorder)
      sheet1
        .cell(`C${index + 7}`)
        .value(value[config.content[2].value])
        .style(styles.contentBorder)
      const importe = value[config.content[3].value];
      sheet1
        .cell(`D${index + 7}`)
        .value(importe)
        .style(styles.contentBorder)
      const NImp = importe.replace("$", "").replace(/,/g, "");
      FTImporte += Number(NImp);
    })
    sheet1
      .range(`A${lengthFTable + 7}:B${lengthFTable + 7}`)
      .merged(true)
      .style(styles.contentBorder)
    sheet1
      .cell(`C${lengthFTable + 7}`)
      .value("TOTAL")
      .style(styles.contentBorder)
    sheet1
      .cell(`D${lengthFTable + 7}`)
      .value(formatter.format(FTImporte))
      .style(styles.contentBorder)
    //SEGUNDA TABLA
    let STImporte = 0;
    sTable.forEach((value, index) => {
      sheet1
        .cell(`A${index + lengthFTable + 10}`)
        .value(value[config.content[0].value])
        .style(styles.contentBorder)
      sheet1
        .cell(`B${index + lengthFTable + 10}`)
        .value(value[config.content[1].value])
        .style(styles.contentBorder)
      sheet1
        .cell(`C${index + lengthFTable + 10}`)
        .value(value[config.content[2].value])
        .style(styles.contentBorder)
      const importe = value[config.content[3].value];
      sheet1
        .cell(`D${index + lengthFTable + 10}`)
        .value(importe)
        .style(styles.contentBorder)
      const NImp = importe.replace("$", "").replace(/,/g, "");
      STImporte += Number(NImp);
    })
    sheet1
      .range(`A${lengthFTable + lengthSTable + 10}:B${lengthFTable + lengthSTable + 10}`)
      .merged(true)
      .style(styles.contentBorder)
    sheet1
      .cell(`C${lengthFTable + lengthSTable + 10}`)
      .value("TOTAL")
      .style(styles.contentBorder)
    sheet1
      .cell(`D${lengthFTable + lengthSTable + 10}`)
      .value(formatter.format(STImporte))
      .style(styles.contentBorder)
  }
}
import { useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import ModalConfirmation from "../../../../components/Modal/ModalConfirmation";
import FullFormLoader from "../../../../components/Loader/FullFormLoader";
import Widget from "../../../../components/Widget/Widget";
import { Button, Col, Row } from "reactstrap";
import { YearInput } from "../../../../components/GenericInputsFormik/YearInput";
import { ButtonInput } from "../../../../components/GenericInputsFormik/ButtonInput";
import ModalTableVer from "../../../../components/Modal/ModalTableVer";
import { doRequest } from "../../../../helpers/requests";
import { SelectTypeHeadSingle } from "../../../../components/GenericInputsFormik";
import { PERIOD } from "../../calculo/regex";
import { validValue } from "../../../../libs/tools/format";
import { excelForemex } from "./excelForemex";
import Notification from "../../../../components/Notification/Notification";
import { toast } from "react-toastify";
import { GetPermissions } from "../../../../libs/permissions/getPermissions";

/* MODAL TABLE */
const filtroModalTabla = [true, true, true, true, true];
const cabecerasModalTabla = [
  "#",
  "Núm. Empleado",
  "Nombre",
  "Unidad de trabajo",
  "Puesto",
  "Seleccionar",
];
const dh = new Date();

const defaultForm = {
  periodInitial: "",
  yearInitial: new Date(dh.getFullYear(), 0, 1),
  periodFinish: "",
  yearFinish: new Date(dh.getFullYear(), 0, 1),
  idStaff: "",
  nameStaff: "",
};

const FormSchema = Yup.object().shape(
  {
    periodInitial: Yup.number().required("Seleccione un periodo inicial"),
    yearInitial: Yup.date().required("Seleccione un año inicial").nullable(),
    periodFinish: Yup.number()
      .required("Seleccione un periodo final")
      .test(
        "final-period", /// Debe retornar falso para mostrar error
        "El periodo final debe ser mayor al inicial",
        (value, tc) =>
          tc.parent.yearInitial && tc.parent.yearFinish
            ? tc.parent.yearInitial.getFullYear() ===
              tc.parent.yearFinish.getFullYear()
              ? value >= tc.parent.periodInitial
              : tc.parent.yearInitial.getFullYear() <
                tc.parent.yearFinish.getFullYear()
            : true
      ),
    yearFinish: Yup.date()
      .required("Seleccione un año final")
      .min(Yup.ref("yearInitial"), "El año final debe ser mayor al inicial")
      .nullable(),
  }
  // ["year", "nameStaff"]
);
const anio = new Date().getFullYear();
const CalculoForemex = () => {
  GetPermissions();

  const [modalConfirm, setModalConfirm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingMsg, setLoadingMsg] = useState("");
  const [modalTable, setModalTable] = useState(false);
  const [paramsModalTabla] = useState({
    action: "datatable",
    table:
      "staff STF INNER JOIN employtypes AS EMT ON STF.keyEmployType = EMT.keyEmployType INNER JOIN jobstaff AS JBS ON JBS.idStaff = STF.idStaff INNER JOIN areas AS AR ON AR.idArea = JBS.idArea INNER JOIN jobtypes AS JBT ON JBT.keyJobType = STF.keyEmployType",
    rows: "STF.idStaff,STF.staffNumber,CONCAT(STF.name,' ',STF.pName,' ',STF.mName) as fullName,AR.name,JBT.nameJobType",
    conditions:
      "STF.enabled = 1 AND JBS.valid = 1 AND STF.keyStat = 1 AND JBS.enabled = 1",
    page: 0,
    records: 5,
    search: "",
    order: "",
  });

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleReset,
    handleSubmit,
    setFieldValue,
    setFieldTouched,
  } = useFormik({
    initialValues: defaultForm,
    validationSchema: FormSchema,
    onSubmit: () => setModalConfirm(true),
    onReset: () => {},
    enableReinitialize: true,
  });

  const onChange = (e) => {
    if (!validValue(e.target.value) && e.target.name === "periodInitial") {
      setFieldValue("periodFinish", "");
    }
    if (e.target.name === "yearInitial") {
      setFieldValue("yearFinish", null);
      setFieldValue("periodFinish", "");
      setFieldTouched("yearFinish", false);
      setFieldTouched("periodFinish", false);
    }
    setFieldValue(e.target.name, e.target.value);
  };

  const makeCalculo = async () => {
    let params = {
      action: "generateFileForemex",
      rows: {
        periodInitial: values.periodInitial,
        yearInitial: values.yearInitial.getFullYear(),
        periodFinish: values.periodFinish,
        yearFinish: values.yearFinish.getFullYear(),
      },
    };
    if (values.nameStaff !== "") params.rows.idStaff = values.idStaff;
    setLoadingMsg("Realizando calculo");
    setLoading(true);
    const data = await doRequest("app/facades/jobs/jobsF.php", params, false);
    console.log(data.data);
    if (data.data !== undefined && data.data !== null && data.data !== "") {
      if (data.data.length > 0) {
        const pA =
          values.periodInitial + "/" + values.yearInitial.getFullYear();
        const pB = values.periodFinish + "/" + values.yearFinish.getFullYear();
        const emp = values.nameStaff
          ? " - " + values.nameStaff.split(" - ")[1]
          : "";
        setLoadingMsg("Generando documento");
        let flg = false;
        try {
          await excelForemex(data.data, {
            period: pA + "-" + pB,
            emp: emp,
          });
          flg = true;
        } catch (error) {}

        toast(
          <Notification
            type={flg ? "agrega_exito" : "consultar_error"}
            backMessage={
              flg
                ? "Archivo de calculo generado"
                : "No fue posible generar el archivo"
            }
            withIcon
          />,
          { closeButton: false }
        );
      }
    }
    setLoading(false);
  };

  /* Funcion que se envia al modalTabla al seleccionar un empleado*/
  const selectEmpleado = (el) => {
    setFieldValue("idStaff", el[0]);
    setFieldValue("nameStaff", `${el[1]} - ${el[2]}`);
    setModalTable(false);
  };

  const filterPeriods = () => {
    let periodsClean = [...PERIOD];
    if (
      validValue(values.yearInitial) &&
      validValue(values.periodInitial) &&
      validValue(values.yearFinish)
    ) {
      if (
        values.yearInitial.getFullYear() === values.yearFinish.getFullYear()
      ) {
        periodsClean = periodsClean.slice(+values.periodInitial);
      }
    }
    return periodsClean;
  };

  const getRange = () => {
    const pA =
      values.periodInitial +
      "/" +
      (values.yearInitial ? values.yearInitial.getFullYear() : "");
    const pB =
      values.periodFinish +
      "/" +
      (values.yearFinish ? values.yearFinish.getFullYear() : "");
    return (
      <>
        <strong>{pA}</strong> al <strong>{pB}</strong>
        {values.nameStaff && (
          <>
            <br /> para el empleado <br /> <strong>{values.nameStaff}</strong>
          </>
        )}
      </>
    );
  };

  return (
    <Widget className="widget-p-md">
      <h3>Calcular FOREMEX</h3>
      <form onSubmit={handleSubmit} onReset={handleReset} className="mt-3">
        <Row>
          <Col xs={12} lg={6}>
            <YearInput
              label="Año Inicial"
              inputName="yearInitial"
              onChangeMethod={onChange}
              onBlurMethod={handleBlur}
              touched={touched.yearInitial}
              errors={errors.yearInitial}
              value={values.yearInitial}
              isMin={anio - 3}
              isRequired
            />
          </Col>
          <Col xs={12} lg={6}>
            <SelectTypeHeadSingle
              label="Periodo Inicial"
              inputName="periodInitial"
              optionsArray={PERIOD}
              defaultOption="Seleccione el periodo inicial"
              onChangeMethod={onChange}
              onBlurMethod={handleBlur}
              value={values.periodInitial}
              touched={touched.periodInitial}
              errors={errors.periodInitial}
              optionValue="value"
              optionName="label"
              isRequired
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12} lg={6}>
            <YearInput
              label="Año Final"
              inputName="yearFinish"
              onChangeMethod={onChange}
              onBlurMethod={handleBlur}
              touched={touched.yearFinish}
              errors={errors.yearFinish}
              value={values.yearFinish}
              isMin={values.yearInitial && values.yearInitial.getFullYear()}
              isDisabled={!values.yearInitial}
              isRequired
            />
          </Col>
          <Col xs={12} lg={6}>
            <SelectTypeHeadSingle
              label="Periodo Final"
              inputName="periodFinish"
              optionsArray={filterPeriods()}
              defaultOption="Seleccione el periodo final"
              onChangeMethod={onChange}
              onBlurMethod={handleBlur}
              value={values.periodFinish}
              touched={touched.periodFinish}
              errors={errors.periodFinish}
              optionValue="value"
              optionName="label"
              isDisabled={!values.yearInitial || !values.periodInitial}
              isRequired
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12} lg={6}>
            <ButtonInput
              label="Empleado"
              inputName="nameStaff"
              value={values.nameStaff}
              touched={touched.nameStaff}
              errors={errors.nameStaff}
              setModalTable={setModalTable}
              clearFun={() => {
                setFieldValue("idStaff", "");
                setFieldValue("nameStaff", "");
              }}
            />
          </Col>
          <Col xs={12} lg={6}>
            <div className="mt-4 pt-2 text-center text-lg-left">
              <Button color="primary" type="submit" onClick={() => {}}>
                Calcular
              </Button>
            </div>
          </Col>
        </Row>
      </form>

      <ModalConfirmation
        modalTitle="Generar Calculo"
        modal={modalConfirm}
        setModal={setModalConfirm}
        editar={() => makeCalculo()}
        isEdit={true}
        buttonOkMsg="Calcular"
      >
        <div className="d-flex justify-content-center text-center">
          <p style={{ fontSize: "20px" }} className="mb-2">
            ¿Esta seguro de generar el calculo del <br />
            perido {getRange()}?
          </p>
        </div>
      </ModalConfirmation>

      <ModalTableVer
        modal={modalTable}
        setModal={setModalTable}
        title="Empleados"
        cabeceras={cabecerasModalTabla}
        filtro={filtroModalTabla}
        parametros={paramsModalTabla}
        selectRegistro={selectEmpleado}
        backdrop
        keyboard
      />

      <FullFormLoader show={loading} message={loadingMsg} />
    </Widget>
  );
};
export default CalculoForemex;
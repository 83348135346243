import React, { useState, useEffect } from "react";
import Widget from "../../../../../components/Widget/Widget";
import TableProgressBar from "../../../componentes/table/TableProgressBar";
import ModalAvances from "../../../componentes/modal/ModalAvances"

const RegistroAvances = (props) => {
  const { proyect } = props
  const [parametros, setParametros] = useState({
    action: "getTotalActivities",
    rows: "idActivity,nameActivity,resposible,startDate,endDate,PROGRESS",
    idProyect: proyect.id,
    page: 0,
    records: 5,
    search: "",
    order: "",
  });




  const [modal, setModal] = useState(false)
  const [idRecord, setIdRecord] = useState("")


  const registerAdvance = (registro) => {
    setModal(true)
    setIdRecord(registro[0])
  }


  return (
    <Widget className="widget-p-md">
      <TableProgressBar
        titulo={"Registro de avances"}
        cabecerasTabla={[
          "Id",
          "Accion",
          "Encargado",
          "Fecha de inicio",
          "Fecha de termino",
          "Avance",
          "Registrar Avance",
        ]}
        filtro={[true, true, true, true, true, true, true, true]}
        parametros={parametros}
        routePeticion="app/facades/planning/planningF.php"
        specialColumns={[{ icon: " fa fa-eye text-info", method: registerAdvance, content: "Registrar Avance" }]}
      />



      <ModalAvances
        modal={modal}
        setModal={setModal}
        title={"Cambio de actividades"}
        backdrop={"static"}
        keyboard={true}
        idActivity={idRecord}
        modify={true}
        btnClose={true}
      />


    </Widget>
  );
};

export default RegistroAvances;

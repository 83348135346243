import { Modal, ModalHeader, ModalBody, Button, Row, Col } from "reactstrap"
import { useEffect, useState } from "react"
import { toast } from "react-toastify";
import { format } from "date-fns"
import { es } from "date-fns/locale"
import { peticionesReceiver } from "../../../../helpers/peticionesReceiver";
import Notification from "../../../../components/Notification/Notification";
import s from "./cell.module.css"

export const getFile = async (typeFile, ref, showNotif=false) => {
    const API = peticionesReceiver()
    const params = {
        action: "multiselect",
        table: "bookcase b join files f on (b.idBook = f.idBook) join invoices i on (b.reference = i.idInvoice) join warehouseincomes wh on (wh.idIncome = i.idIncome)",
        rows: "f.src",
        conditions: `b.enabled = 1 AND f.enabled AND b.keyFileType = ${typeFile} AND wh.idIncome=${ref}`,
    };
    const res = await API.peticion(params)
    if (res.status === 200 && res.data.code === "200") {
        const data = res.data.data;
        if (data.length > 0) {
            return res.data.data[0].src
        } else {
            if (showNotif) {
                toast(<Notification type={"consultar_error"} withIcon />);
            }
            return null
        }
    } else {
        if (showNotif) {
            toast(<Notification type={"consultar_error"} withIcon />);
        }
        return null
    }
}

export default function GetXML({ idIncome, dataXML, modal, setModal }) {
    const stateCFDI = "VIGENTE";
    const [data, setData] = useState({})


    const formatter = new Intl.NumberFormat("es-MX", {
        style: "currency",
        currency: "MXN",
    });

    const getRFC = (att) => {
        try {
            return att.getNamedItem("Rfc").value
        } catch (err) {
            return att.getNamedItem("rfc").value
        }
    }

    const getNombre = (att) => {
        try {
            return att.getNamedItem("Nombre").value
        } catch (err) {
            return att.getNamedItem("nombre").value
        }
    }

    const getTotal = (att) => {
        try {
            return att.getNamedItem("Total").value
        } catch (err) {
            return att.getNamedItem("total").value
        }
    }

    const getFecha = (att) => {
        try {
            return att.getNamedItem("Fecha").value
        } catch (err) {
            return att.getNamedItem("fecha").value
        }
    }

    const getPAC = (att) => {
        try {
            return att.getNamedItem("RfcProvCertif").value
        } catch (err) {
            return att.getNamedItem("noCertificadoSAT").value
        }
    }

    const getTComp = (att) => {
        try {
            return att.getNamedItem("TipoDeComprobante").value
        } catch (err) {
            return att.getNamedItem("tipoDeComprobante").value
        }
    }

    function setDataModal(data) {
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(data, 'application/xml');
        const value = xmlDoc.getElementsByTagName("cfdi:Comprobante")[0];
        const childrens = [...value.children];
        const emisor = childrens.filter(item => item.nodeName === "cfdi:Emisor")[0];
        const receptor = childrens.filter(item => item.nodeName === "cfdi:Receptor")[0];
        const complemento = childrens.filter(item => item.nodeName === "cfdi:Complemento")[0];
        const rfcEmisor = getRFC(emisor.attributes);
        const razonEmisor = getNombre(emisor.attributes);
        const rfcReceptor = getRFC(receptor.attributes);
        const razonReceptor = getNombre(receptor.attributes);
        const folioFiscal = complemento.firstChild.attributes.getNamedItem("UUID").value
        const fechaExp = getFecha(value.attributes);
        const fechaCertSAT = complemento.firstChild.attributes.getNamedItem("FechaTimbrado").value
        const PAC = getPAC(complemento.firstChild.attributes);
        const totalCFDI = formatter.format(Number(getTotal(value.attributes)))
        const efecto = getTComp(value.attributes)
        setData({
            rfcEmisor,
            razonEmisor,
            rfcReceptor,
            razonReceptor,
            folioFiscal,
            fechaExp: format(new Date(fechaExp), "PPpp", { locale: es }),
            fechaCertSAT: format(new Date(fechaCertSAT), "PPpp", { locale: es }),
            PAC,
            totalCFDI,
            efecto
        })
    }

    async function getXML(id, data, petition) {
        const fileType = 39;
        const src = await getFile(fileType, id);
        if (src) {
            fetch(src, {
                method: "GET",
                mode: 'cors',
                headers: {
                    'Content-Type': "application/x-www-form-urlencoded, multipart/form-data",
                },
            })
                .then(response => response.text())
                .then(data => {
                    setDataModal(data)
                })
                .catch(error => {
                    toast(<Notification type={"consultar_error"} withIcon />)
                    setModal(false);
                });
        } else if (data) {
            setDataModal(data)
        } else {
            toast(<Notification type={"consultar_error"} withIcon />)
            setModal(false);
        }
    }

    useEffect(() => {
        if (modal) {
            getXML(idIncome, dataXML);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [idIncome, modal])

    return (
        <>
            <Modal isOpen={modal} toggle={() => setModal(false)} fullscreen="lg" size="xl" backdrop="static" >
                <ModalHeader toggle={() => setModal(false)}>Visualización de factura</ModalHeader>
                <ModalBody>
                    <Row>
                        <Col xs={12} md={6} lg={3} className="p-0">
                            <div className="border-bottom border-primary text-center p-2">
                                RFC del emisor
                            </div>
                            <div className={`text-center p-2 ${s.cellCustom}`}>
                                {data.rfcEmisor}
                            </div>
                        </Col>
                        <Col xs={12} md={6} lg={3} className="p-0">
                            <div className="border-bottom border-primary text-center p-2">
                                Nombre o razón social del emisor
                            </div>
                            <div className={`text-center p-2 ${s.cellCustom}`}>
                                {data.razonEmisor}
                            </div>
                        </Col>
                        <Col xs={12} md={6} lg={3} className="p-0">
                            <div className="border-bottom border-primary text-center p-2">
                                RFC del receptor
                            </div>
                            <div className={`text-center p-2 ${s.cellCustom}`}>
                                {data.rfcReceptor}
                            </div>
                        </Col>
                        <Col xs={12} md={6} lg={3} className="p-0">
                            <div className="border-bottom border-primary text-center p-2">
                                Nombre o razón social del receptor
                            </div>
                            <div className={`text-center p-2 ${s.cellCustom}`}>
                                {data.razonReceptor}
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} md={6} lg={3} className="p-0">
                            <div className="border-bottom border-primary text-center p-2">
                                Folio fiscal
                            </div>
                            <div className={`text-center p-2 ${s.cellCustom}`}>
                                {data.folioFiscal}
                            </div>
                        </Col>
                        <Col xs={12} md={6} lg={3} className="p-0">
                            <div className="border-bottom border-primary text-center p-2">
                                Fecha de expedición
                            </div>
                            <div className={`text-center p-2 ${s.cellCustom}`}>
                                {data.fechaExp}
                            </div>
                        </Col>
                        <Col xs={12} md={6} lg={3} className="p-0">
                            <div className="border-bottom border-primary text-center p-2">
                                Fecha certificación SAT
                            </div>
                            <div className={`text-center p-2 ${s.cellCustom}`}>
                                {data.fechaCertSAT}
                            </div>
                        </Col>
                        <Col xs={12} md={6} lg={3} className="p-0">
                            <div className="border-bottom border-primary text-center p-2">
                                PAC que certifico
                            </div>
                            <div className={`text-center p-2 ${s.cellCustom}`}>
                                {data.PAC}
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} md={6} lg={4} className="p-0">
                            <div className="border-bottom border-primary text-center p-2">
                                Total del CFDI
                            </div>
                            <div className={`text-center p-2 ${s.cellCustom}`}>
                                {data.totalCFDI}
                            </div>
                        </Col>
                        <Col xs={12} md={6} lg={4} className="p-0">
                            <div className="border-bottom border-primary text-center p-2">
                                Efecto del comprobante
                            </div>
                            <div className={`text-center p-2 ${s.cellCustom}`}>
                                {data.efecto}
                            </div>
                        </Col>
                        <Col xs={12} md={6} lg={4} className="p-0">
                            <div className="border-bottom border-primary text-center p-2">
                                Estado CFDI
                            </div>
                            <div className={`text-center p-2 ${s.cellCustom}`}>
                                {stateCFDI}
                            </div>
                        </Col>
                    </Row>
                    <div className="text-center mt-2">
                        <Button color="danger" className="mx-1" onClick={() => setModal(false)}>Cerrar</Button>
                    </div>
                </ModalBody>
            </Modal>
        </>
    )
}
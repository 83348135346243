import {
    Document,
    Page,
    StyleSheet,
    Text,
    View,
} from "@react-pdf/renderer";
import HeaderReport from "../../../../components/Pdf/Header";
import { fDatewhitTime, fMoney } from "../../../../libs/tools/format";


const s = StyleSheet.create({
    body: {
        paddingVertical: "30px",
        paddingHorizontal: "20px",
    },

    title: {
        fontSize: "12px",
        textAlign: "center",
        fontFamily: "Helvetica-Bold",
    },

    header: {
        backgroundColor: "#730A29",
        textAlign: "center",
        color: "#FFFFFF",
    },

    mCell: { padding: "1px" },
    mxCell: { paddingHorizontal: "1px" },

    vCenter: { alignItems: "center", justifyContent: "center" },
    vCenterL: { alignItems: "left", justifyContent: "center" },
    vCenterR: { alignItems: "end", justifyContent: "center" },
    vCenterBtw: { alignItems: "stretch", justifyContent: "center" },

    dflexBtw: { display: "flex", justifyContent: "space-between" },
    dflexEnd: { display: "flex", justifyContent: "flex-end" },

    alignE: { alignContent: "flex-end" },

    row: { flexDirection: "row" },
    flexWrap: { display: "flex", flexWrap: "wrap", flexDirection: "row" },

    w5: { width: "5%" },
    w10: { width: "10%" },
    w15: { width: "15%" },
    w20: { width: "20%" },
    w25: { width: "25%" },
    w30: { width: "30%" },
    w32: { width: "32%" },
    w35: { width: "35%" },
    w40: { width: "40%" },
    w45: { width: "45%" },
    w50: { width: "50%" },
    w55: { width: "55%" },
    w60: { width: "60%" },
    w65: { width: "65%" },
    w70: { width: "70%" },
    w75: { width: "75%" },
    w80: { width: "80%" },
    w90: { width: "90%" },
    w100: { width: "100%" },
    wAuto: { width: "auto" },

    fborder: { border: "1px solid #000" },
    border: { border: "1px solid #000", borderBottom: "0px" },
    borderT: { borderBottom: "1px solid #000" },
    borderB: { borderBottom: "1px solid #000" },
    borderB2: { borderBottom: "2px solid #000" },
    borderE: { borderRight: "1px solid #000" },
    borderS: { borderLeft: "1px solid #000" },
    borderBS: { borderBottom: "4px solid #730A29" },
    borderHE: { borderRight: "1px solid #FFFFFF" },

    bg: { backgroundColor: "#BEBEBE" },
    bg2: { backgroundColor: "#BEBEBE" },
    bglg: { backgroundColor: "#adb5bd" },

    fs7: { fontSize: "7px" },
    fs8: { fontSize: "8px" },
    fs9: { fontSize: "9px" },
    fs10: { fontSize: "10px" },
    fs11: { fontSize: "11px" },
    fs12: { fontSize: "12px" },
    fs13: { fontSize: "13px" },

    tend: { textAlign: "right", paddingRight: "2px" },
    tstart: { textAlign: "left" },
    tcenter: { textAlign: "center" },
    tBold: { fontFamily: "Helvetica-Bold" },

    mT1: { marginTop: "1px" },
    mT2: { marginTop: "2px" },
    mT8: { marginTop: "8px" },
    mT10: { marginTop: "10px" },
    mT20: { marginTop: "20px" },
    mT40: { marginTop: "40px" },
    mx5: { marginHorizontal: "5px" },
    mx18: { marginHorizontal: "18px" },
    mx20: { marginHorizontal: "20px" },

    spaceFooter: {
        bottom: 10,
        left: 0,
        right: 0,
        width: "auto",
        height: 30,
        marginHorizontal: 10,
    },

    footer: {
        position: "absolute",
        bottom: 10,
        left: 0,
        right: 0,
        width: "auto",
        height: 30,
        marginHorizontal: 10,
        borderTopWidth: 1,
        flexDirection: "row",
        alignContent: "space-around",
        alignItems: "center",
    },
    textFooter: {
        width: "50%",
        textAlign: "center",
        fontSize: 8,
    },
});

export default async function pdfSolicitud(data, cfg, t, fileNo) {
    return {
        report: (
            <Document>
                <Page style={[s.body, s.fs11]}>
                    <HeaderReport />
                    <View style={[s.tcenter, s.tBold, s.mT8]}>
                        <Text>
                            SOLICITUD DE SUFICIENCIA PRESUPUESTAL{" "}
                            {data.solicitud.folio.split("/")[1]}
                        </Text>
                    </View>

                    <View style={[s.row, s.mT20]}>
                        <View style={[s.w50]}>
                            <View style={[s.row]}>
                                <View style={[s.w25, s.tBold]}>
                                    <Text>{"            "}</Text>
                                    <Text>FOLIO NO.:</Text>
                                </View>
                                <View style={[s.w50, s.borderB2, s.tcenter]}>
                                    <Text>{"            "}</Text>
                                    <Text>{data.solicitud.folio}</Text>
                                </View>
                            </View>
                        </View>
                        <View style={[s.w20]}></View>
                        <View style={[s.w40]}>
                            <View style={[s.row]}>
                                <View style={[s.w40, s.tBold]}>
                                    <Text>FECHA DE{"\n"}RECEPCIÓN:</Text>
                                </View>
                                <View style={[s.w60, s.borderB2, s.tcenter]}>
                                    <Text>{"            "}</Text>
                                    <Text>{fDatewhitTime(data.solicitud.fecha)}</Text>
                                </View>
                            </View>
                        </View>
                    </View>
                    <View style={[s.borderBSs, s.mT10]}></View>

                    <View style={[s.mT10]}>
                        <View style={[s.row]}>
                            <View style={[s.w80, s.fs8]}>
                                <View style={[s.row]} fixed={true}>
                                    <View style={[s.w20, s.mx5, s.vCenter, s.bglg]}>
                                        <View style={[s.tcenter]}>
                                            <Text>PROYECTO</Text>
                                        </View>
                                    </View>
                                    <View style={[s.w20, s.mx5, s.vCenter, s.bglg]}>
                                        <View style={[s.tcenter]}>
                                            <Text>PARTIDA</Text>
                                        </View>
                                    </View>
                                    <View style={[s.w20, s.mx5, s.vCenter, s.bglg]}>
                                        <View style={[s.tcenter]}>
                                            <Text>IMPORTE</Text>
                                        </View>
                                    </View>
                                    <View style={[s.w30, s.mx5, s.bglg]}>
                                        <View style={[s.tcenter]}>
                                            <Text>DESCRIPCIÓN DEL BIEN O{"\n"}SERVICIO</Text>
                                        </View>
                                    </View>
                                </View>
                                {data.proyects.map((el, ix) => (
                                    <View style={[s.row, s.fs8, s.mT2]} key={"rt" + ix}>
                                        <View style={[s.w20, s.borderB, s.mx5, s.vCenterL]}>
                                            <Text>{el.prPres.split(" - ")[0]}</Text>
                                        </View>
                                        <View style={[s.w20, s.borderB, s.mx5, s.vCenterL]}>
                                            <Text>{el.COG}</Text>
                                        </View>
                                        <View style={[s.w20, s.borderB, s.mx5, s.vCenterL, s.tend]}>
                                            <Text>{fMoney(el.acum)}</Text>
                                        </View>
                                        <View style={[s.w30, s.fborder, s.mx5, s.vCente, s.mxCell]}>
                                            <Text>{el.concept}</Text>
                                        </View>
                                    </View>
                                ))}
                                <View style={[s.row, s.fs8, s.mT2]}>
                                    <View style={[s.w20, s.mx5]}>
                                        <Text></Text>
                                    </View>
                                    <View style={[s.w20, s.mx5, s.vCenterL, s.tend, s.tBold]}>
                                        <Text>TOTAL</Text>
                                    </View>
                                    <View style={[s.w20, s.mx5, s.vCenterL, s.tend, s.tBold]}>
                                        <Text>{fMoney(data.total)}</Text>
                                    </View>
                                    <View style={[s.w30, s.mx5]}>
                                        <Text></Text>
                                    </View>
                                </View>
                            </View>
                            <View style={[s.w20]}>
                                <View style={[s.bglg, s.tcenter]}>
                                    <Text>CALENDARIZACIÓN</Text>
                                </View>
                                {data.meses.map((el) => (
                                    <View style={[s.row]} key={"r" + el.nameMonth}>
                                        <View style={[s.w10]}>
                                            <Text>{el.nameMonth.charAt(0)}</Text>
                                        </View>
                                        <View style={[s.w90, s.tend, s.borderB]}>
                                            <Text>{fMoney(el.acum)}</Text>
                                        </View>
                                    </View>
                                ))}
                            </View>
                        </View>
                    </View>

                    <View style={{ marginTop: "100px" }}>
                        <View style={[s.bglg]}>
                            <Text>JUSTIFICACIÓN DEL GASTO</Text>
                        </View>
                        <View style={[s.borderB]}>
                            <Text>{data.solicitud.justificacion}</Text>
                        </View>
                        <View style={[s.bglg]}>
                            <Text>DICTAMEN</Text>
                        </View>
                        <View style={[s.fborder, s.mT1, { minHeight: "50px" }]}>
                            <Text>{data.notas}</Text>
                        </View>
                        <View style={[s.fborder, s.mT1, { minHeight: "40px" }]}>
                            <Text>
                                NOTA: El dictamen es de carácter informativo sobre la disponibilidad de los recursos presupestales y no autoriza ni valida la adquisición de un bien o servicio
                            </Text>
                        </View>
                    </View>

                    <View style={[s.mT10, s.row]}>
                        <Persona tt="SOLICITA" name={data.proyects[0].solicita} />
                        <Persona tt="REVISÓ" name={data.proyects[0].revisa} />
                        <Persona tt="Vo. Bo." name={data.proyects[0].aprueba} c1="DIRECCIÓN DE FINANZAS" />
                    </View>

                    <View fixed style={s.spaceFooter}></View>
                    <View fixed style={s.footer}>
                        <Text style={s.textFooter}>{fileNo}</Text>
                        <Text
                            style={s.textFooter}
                            render={({ pageNumber, totalPages }) =>
                                `PAGINA : ${pageNumber} / ${totalPages}`
                            }
                        />
                    </View>
                </Page>
            </Document>
        ),
    };
}

function Persona({ tt, name, c1 }) {
    return (
        <View style={[s.w32, s.mx5, s.fs9]}>
            <View style={[s.fborder, s.bglg, s.mT1, s.mCell, s.tcenter]}>
                <Text>{tt}</Text>
            </View>
            <View style={[s.fborder, s.mT1, s.tcenter, { minHeight: "40px" }]}>
                <Text>{c1}</Text>
            </View>
            <View style={[s.fborder, s.mT1, { minHeight: "30px" }]}>

            </View>
            <View style={[s.fborder, s.mT1, s.vCenter, { minHeight: "30px" }]}>
                <Text>{name}</Text>
            </View>
        </View>
    )
}

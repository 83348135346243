import { Document, Page, Text, View } from "@react-pdf/renderer";
import { StyleSheet } from "@react-pdf/renderer";
import HeaderReport from "../../Pdf/Header";

const formatter = (value) => {
  const newValue =  value.replace(',','')
  return new Intl.NumberFormat("es-MX", {
    style: "currency",
    currency: "MXN",
  }).format(newValue)
}

const dateformatter = (date) => {
  const today = new Date(date)
  const month = today.getMonth() + 1
  const day = today.getDate()
  const formattMonth = month < 10 ? `0${month}` : month
  const formattDay = day < 10 ? `0${day}` : day
  return `${formattDay}/${formattMonth}/${today.getFullYear()}`
};

const colors = {
  backgroundHeaders: "#646464",
};

const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 35,
    paddingHorizontal: 10,
  },
  spaceFooter: {
    fontSize: 12,
    bottom: 10,
    left: 0,
    right: 0,
    width: "auto",
    height: 50,
    marginHorizontal: 10,
  },
  footer: {
    position: "absolute",
    fontSize: 12,
    bottom: 10,
    left: 0,
    right: 0,
    width: "auto",
    height: 30,
    marginHorizontal: 10,
    /*       borderColor:"#a9a9a9", */
    borderTopWidth: 1,
    flexDirection: "row",
    alignContent: "space-around",
    alignItems: "center",
  },
  title: {
    width: "100%",
    textAlign: "center",
    marginBottom: 10,
    /* color: '#a9a9a9', */
    fontSize: 18,
    fontWeight: '700'
  },
  subtitle: {
    width: "100%",
    textAlign: "center",
    marginBottom: 10,
    /* color: '#a9a9a9', */
    fontSize: 13,
  },
  textFooter: {
    width: "50%",
    /* color: '#a9a9a9', */
    textAlign: "center",
    fontSize: 8,
  },

  table: {
    display: "table",
    width: "auto",
    /*       borderStyle: "solid", 
      borderColor: "red",
      borderWidth: 1, 
      borderRightWidth: 0, 
      borderLeftWidth: 0 , */
    margin: 10,
  },
  //cabeceras
  tableColHeaderNo: {
    width: "27.5%",
    height: 20,
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    backgroundColor: colors.backgroundHeaders,
    color: "white",
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center",
    fontSize: 9,
  },
  tableColHeaderReport: {
    width: "50%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    backgroundColor: colors.backgroundHeaders,
    color: "white",
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center",
    fontSize: 9,
  },

  //contenido

  tableColContentReport: {
    width: "50%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  //filas
  tableRow: {
    flexDirection: "row",
  },

  //Filas de contenido, max 3
  displayContent: {
    paddingLeft: 9, //centra el el content
    margin: 9,
    /* marginTop: 9,
      marginBottom: 9, */
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "space-around",
  },

  //Tamaño de celda deacuerdo a tamaño del contenido
  cell80: {
    width: "90%",
    fontSize: 8,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    textAlign: 'center'
  },
});

const insertHeaders = () => {
  return (
    <View style={styles.tableRow} fixed>
      <View style={styles.tableColHeaderReport} fixed>
        <Text style>No. de movimiento</Text>
      </View>
      <View style={styles.tableColHeaderReport} fixed>
        <Text>Fecha de Registro</Text>
      </View>
      <View style={styles.tableColHeaderReport} fixed>
        <Text>Concepto</Text>
      </View>
      <View style={styles.tableColHeaderReport} fixed>
        <Text>Saldo Anterior</Text>
      </View>
      <View style={styles.tableColHeaderReport} fixed>
        <Text>Cargo</Text>
      </View>
      <View style={styles.tableColHeaderReport} fixed>
        <Text>Abono</Text>
      </View>
      <View style={styles.tableColHeaderReport} fixed>
        <Text>Saldo Nuevo</Text>
      </View>
    </View>
  );
};

export function generatePdfPresupuestoAnual(data, model, title, fileNo, cont) {
  //model.id
  console.log('model ',model);
  const contregistro = isNaN(cont) ? 0 : cont;
  return {
    contador: contregistro + data.length,
    report: (
      <>
        <Document>
          <Page style={styles.body}>
            <HeaderReport/>
            <View>
              <Text style={styles.title}>{title}</Text>
            </View>
            <View>
              <Text style={styles.subtitle}>
                {model.filters !== undefined
                  ? model.filters.onlyMessage === true
                    ? model.filters.message
                    : ""
                  : ""}
              </Text>
            </View>
            <View style={styles.table}>
              {/* Cabeceras */}

              {insertHeaders()}

              {data.length > 0 &&
                data.map((employee, index) => (
                  <View style={styles.tableRow} wrap={false}>

                    <View style={styles.tableColContentReport}>
                      <Text style={styles.cell80}>
                        {/* {contregistro + index + 1} */}
                        {employee.idMove}
                      </Text>
                    </View>

                    <View style={styles.tableColContentReport}>
                      <Text style={styles.cell80}>
                        {dateformatter(employee.Date)}
                      </Text>
                    </View>

                    <View style={styles.tableColContentReport}>
                      <Text style={styles.cell80}>
                        {employee.description}
                      </Text>
                    </View>

                    <View style={styles.tableColContentReport}>
                      <Text style={styles.cell80}>
                        {formatter(employee.LastBalance)}
                      </Text>
                    </View>

                    <View style={styles.tableColContentReport}>
                      <Text style={styles.cell80}>
                        {formatter(employee.Cargo)}
                      </Text>
                    </View>

                    <View style={styles.tableColContentReport}>
                      <Text style={styles.cell80}>
                        {formatter(employee.Abono)}
                      </Text>
                    </View>

                    <View style={styles.tableColContentReport}>
                      <Text style={styles.cell80}>
                        {formatter(employee.CurrentBalance)}
                      </Text>
                    </View>
                  </View>
                ))}
            </View>

            <View fixed style={styles.spaceFooter}></View>
            <View fixed style={styles.footer}>
              <Text style={styles.textFooter}>{fileNo}</Text>
              <Text
                style={styles.textFooter}
                render={({ pageNumber, totalPages }) =>
                  `PAGINA : ${pageNumber} / ${totalPages}`
                }
              />
            </View>
          </Page>
        </Document>
      </>
    ),
  };
}

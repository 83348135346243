import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import {
  Container,
  Row,
  Col,
  Button,
  FormGroup,
  FormText,
  Input,
} from "reactstrap";
import { useHistory } from "react-router-dom";

import Widget from "../../../components/Widget/Widget";

import { loginUser, receiveToken } from "../../../actions/auth";
import Notification from "../../../components/Notification/Notification";

const Login = (props) => {
  const origin = window.location.origin;
  const history = useHistory();

  const [state, setState] = useState({
    action: "verifyLogin",
    user: "",
    password: "",
  });

  const [seePass, setseePass] = useState(false);

  const doLogin = async (e) => {
    e.preventDefault();
    const result = await props.dispatch(loginUser(state));
    if (!result.success) {
      toast(
        <Notification type={"error"} backMessage={result.message} withIcon />
      );
    } else {
      history.push({ pathname: "/template", state: { menuPermission: true } });
    }
  };

  const changeCreds = (event) => {
    setState({ ...state, [event.target.name]: event.target.value });
  };

  useEffect(() => {
    const params = new URLSearchParams(props.location.search);
    const token = params.get("token");
    if (token) {
      props.dispatch(receiveToken(token));
    }
  });

  return (
    <div className="auth-page">
      <Container className="col-12">
        <Row className="d-flex align-items-center">
          <Col xs={12} lg={6} className="left-column">
            <Widget className="widget-auth widget-p-lg">
              <div className="d-flex align-items-center justify-content-between py-3">
                <p className="auth-header mb-0">Iniciar sesión</p>
              </div>
              <form onSubmit={(event) => doLogin(event)}>
                <FormGroup className="my-3">
                  <FormText>Usuario</FormText>
                  <Input
                    id="user"
                    className="input-transparent pl-3"
                    value={state.user}
                    onChange={(event) => changeCreds(event)}
                    type="text"
                    required
                    name="user"
                    placeholder="Usuario"
                  />
                </FormGroup>
                <FormGroup className="my-3 position-relative">
                  <div className="d-flex justify-content-between">
                    <FormText>Contraseña</FormText>
                  </div>
                  <Input
                    id="password"
                    className="input-transparent pl-3"
                    value={state.password}
                    onChange={(event) => changeCreds(event)}
                    type={seePass ? "text" : "password"}
                    required
                    name="password"
                    placeholder="Contraseña"
                  />
                  <i
                    className={"text-add " + (seePass? " eva eva-eye-off-outline" : " eva eva-eye-outline")}
                    style={{ padding: "0px", 
                      cursor: "pointer", 
                      position: "absolute", 
                      top: "37px", 
                      right: "15px",
                     }}
                    onClick={() => setseePass(!seePass)}
                  />
                </FormGroup>
                <div className="bg-widget d-flex justify-content-center">
                  <Button
                    className="rounded-pill my-3"
                    type="submit"
                    color="secondary-red"
                  >
                    Iniciar Sesión
                  </Button>
                </div>
              </form>
                <div className="text-center mt-3">
                  <Link to="/recuperar-cuenta">¿Olvidaste tu contraseña?</Link>
                </div>
            </Widget>
          </Col>
          <Col xs={0} lg={6} className="right-column">
            <div>
              <img
                className="w-100"
                src={`${origin}/images/logo.png`}
                alt="Error page"
              />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

Login.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    isFetching: state.auth.isFetching,
    isAuthenticated: state.auth.isAuthenticated,
    errorMessage: state.auth.errorMessage,
  };
}

export default withRouter(connect(mapStateToProps)(Login));

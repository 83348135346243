import React, { useState, useEffect } from "react";
import { peticionEncript } from "../../../helpers/peticionesEncripted";
import axios from "axios";
import { toast } from "react-toastify";
import Notification from "../../../components/Notification/Notification";
import { useDropzone } from "react-dropzone";
import { useMemo } from "react";
import { Alert, Button } from "reactstrap";
import ModalConfirmation from "../../../components/Modal/ModalConfirmation";
import FullFormLoader from "../../../components/Loader/FullFormLoader";
import ModalViewFile from "../../../components/Modal/ModalViewFile";

const baseStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  textAlign: "center",
  padding: "1.25rem",
  height: "150px",
  borderWidth: "2px",
  borderStyle: "dashed",
  borderColor: "#C7D0D9",
  borderRadius: "8px",
  position: "relative",
  cursor: "pointer",
};
const focusedStyle = {
  borderColor: "#2196f3",
};

async function doRequest(route, params, encrypt, isReg) {
  let info = [];
  const dataEncrypted = encrypt ? peticionEncript(params) : params;
  await axios
    .post(process.env.REACT_APP_API + route, dataEncrypted)
    .then((res) => {
      if (res.status === 200 && res.data.status === "success") {
        info = res.data.data;
        if (isReg) {
          toast(
            <Notification
              type={"agrega_exito"}
              backMessage={res.data.message}
              withIcon
            />,
            { closeButton: false }
          );
          info = res.data.code;
        }
      } else {
        toast(
          <Notification
            type={"consultar_error"}
            backMessage={res.data.message}
            withIcon
          />,
          { closeButton: false }
        );
      }
    })
    .catch((err) => {
      toast(<Notification type={"consultar_servidor_error"} withIcon />, {
        closeButton: false,
      });
    });
  return info;
}

const DocumentoPViewer = ({ proyectId, fileType, canEdit, permissions }) => {
  const [modalConfirm, setModalConfirm] = useState(false);
  const [modalFile, setModalFile] = useState(false);
  const [loader, setLoader] = useState(false);
  const [file, setFile] = useState({});

  const { acceptedFiles, getRootProps, getInputProps, isFocused } = useDropzone(
    {
      accept: {
        "aplication/pdf": [".pdf"],
      },
      multiple: false,
      maxFiles: 1,
    }
  );

  const style = useMemo(() => ({
    ...baseStyle,
    ...(isFocused ? focusedStyle : {}),
  }));

  //Detectar si se subio un archivo valido
  useEffect(() => {
    if (acceptedFiles.length !== 0) {
      setModalConfirm(true);
    }
  }, [acceptedFiles]);

  //Buscar el archivo
  useEffect(() => {
    getFile();
  }, []);

  const getFile = async () => {
    setLoader(true);
    const param = {
      action: "multiselect",
      table: "bookcase A INNER JOIN files B ON A.idBook = B.idBook",
      rows: "B.src",
      conditions: `A.reference = ${proyectId} AND A.keyFileType = ${fileType} AND B.enabled = 1`,
      order: "",
    };
    const data = await doRequest("receiver/receiver.php", param, true);
    if (data.length > 0) setFile(data[0]);
    setLoader(false);
  };

  const saveFile = async () => {
    const formData = new FormData();
    formData.append("action", "saveFileReplace");
    formData.append("reference", proyectId);
    formData.append("keyFileType", fileType);
    formData.append("binary", acceptedFiles[0]);
    const data = await doRequest(
      "app/facades/files/fileReceiver.php",
      formData,
      false,
      true
    );
    if (data === "200") await getFile();
  };

  const openFIle = () => {
    if (file.src !== undefined && file.src !== null && file.src !== "")
      setModalFile(true);
  };

  const checkFile = () => {
    const e = file.src === undefined || file.src === null || file.src === "";
    return file.src === undefined || file.src === null || file.src === ""
  }

  return (
    <div className="mt-4">
      <Alert
        color="dark"
        isOpen
        fade={false}
        className=""
        style={{
          borderRadius: "0.25rem",
          borderLeft: "3px solid #6b859e",
        }}
      >
        <h5 className="text-dark">Instrucciones</h5>
        <p className="text-dark">
          {
            canEdit? (
              "Suba el documento de acuerdo al tipo de documento seleccionado"
            ) : ("Seleccione un tipo de documento y presione el botón para visualizarlo")
          }
        </p>
      </Alert>

      { canEdit && ((checkFile() && permissions.INS) || (!checkFile() && permissions.UPD) ) && (
        <section className="container">
          <div {...getRootProps({ style })}>
            <input {...getInputProps()} />
            <p className="muted">
              Arrastre el archivo sobre el recuadro o haga click para
              seleccionar un archivo
            </p>
          </div>
        </section>
      )}

      <div className="text-center mt-4">
        <Button
          color="secondary"
          onClick={() => openFIle()}
          disabled={checkFile()}
        >
          <i className="fa fa-file-pdf-o mr-2" style={{ fontSize: "16px" }} />
          Ver
        </Button>
      </div>

      <ModalViewFile
        modal={modalFile}
        setModal={setModalFile}
        backdrop={true}
        keyboard={true}
        btnClose={true}
        fileLink={file.src}
      />

      <FullFormLoader show={loader} />

      <ModalConfirmation
        modalTitle="Guardar documento"
        modal={modalConfirm}
        setModal={setModalConfirm}
        editar={() => {
          saveFile();
        }}
        isEdit={true}
      >
        <div className="d-flex justify-content-center">
          <div className="text-center">
            <h6>¿Está seguro de guardar este archivo?</h6>
            <h6>Este archivo remplazará al existente</h6>
          </div>
        </div>
      </ModalConfirmation>
    </div>
  );
};
export default DocumentoPViewer;


import React from "react";
import Widget from "../../../../components/Widget/Widget";
import FormProveedores from "../FormProveedores";
import { createProveedor } from "../functions";

export default function CrearProveedor(props) {

  return (
    <Widget className="widget-p-md">
      <h3>Crear proveedor de nomina</h3>
      <FormProveedores crear={createProveedor}/>
    </Widget>
  )
}

import { useState } from "react";
import Datatable from "../../../../../components/Datatable/Datatable";
import { doRequestSaveRes } from "../../../../../helpers/requests";
import { fMoney } from "../../../../../libs/tools/format";
import { useEffect } from "react";
import ModalViewFile from "../../../../../components/Modal/ModalViewFile";
import classNames from "classnames";

const headers = ["Unidad Administrativa Revisora", "Estatus del Adeudo", "Motivo del Adeudo", "Interacción"];
    
const columnDefs = [
    {targets: [1], className: "text-center"}
]

export default function Documentacion({id, permissions}){
    const [refresh, setRefresh] = useState(false);
    const [file, setFile] = useState("");
    const [modal, setModal] = useState(false);

    const columns = [
        {data: "name", render:(d)=><div style={{minWidth: "200px"}}>{d}</div>},
        {
            data: null, 
            render:(row)=> (
                <div style={{width: "max-content"}} className={classNames(["d-flex flex-row", row.src && "cursor-pointer"])} onClick={()=>setFile(row.src)}>
                    <p>{row.nameStat}</p>
                    {
                        row.src && <i className="fa fa-file-pdf-o text-danger pl-2"/>
                    }
                </div>
            ),
            orderValue: "nameStat"
        },
        {data: "notes"},
        {
            data: null, 
            render:(row)=>(
                row.discount && <div className="d-flex flex-row">
                    <p className="mr-3 font-weight-bold">{+row.discount === 1 ? "Descuento" : "Pago"}:</p>
                    <p>{fMoney(row.amount)}</p>
                </div>
            ),
            orderValue: "amount"
        }
    ]

    async function getData(ordCol, itemsPerPage, currentPage, search, multi) {
        const res = await doRequestSaveRes(
            "receiver/receiver.php",
            getParams(ordCol, itemsPerPage, currentPage, search, multi),
            false,
            false
        );
        if (res.code === "200") return res;
        return null;
    }

    function getParams(ordCol, itemsPerPage, currentPage, search) {
        const params = {
            action: "datatable",
            table: "settlementsdocs A INNER JOIN settlementareas B USING(idSettlementArea) INNER JOIN areas C USING(idArea) INNER JOIN status D USING(keyStat) LEFT JOIN bookcase E ON E.reference = A.idDocument AND E.keyFileType = 62 LEFT JOIN files F USING(idBook)",
            rows: "C.name, D.nameStat, A.notes, A.amount, A.discount, F.src",
            conditions: `A.enabled = 1 AND B.enabled = 1 AND A.idSettlement = ${id}`,
            order: ordCol,
            records: itemsPerPage.toString(),
            page: (itemsPerPage * currentPage).toString(),
            search
        };
        return params;
    }

    useEffect(()=>{
        if(file) setModal(true);
    }, [file]);

    useEffect(()=>{
        if(!modal) setFile("");
    }, [modal]);

    return(
        <div>
            {permissions.CON ? (
                <Datatable
                    headers={headers}
                    columns={columns}
                    columnDefs={columnDefs}
                    petition={getData}
                    control="back"
                    stateRefresh={[refresh, setRefresh]}
                    className="px-0 mt-4"
                />
            ) : (
                <div>
                    <h5>No tiene los permisos necesarios para consultar esta información</h5>
                </div>
            )}
            <ModalViewFile
                modal={modal}
                setModal={setModal}
                backdrop={true}
                keyboard={true}
                btnClose={true}
                fileLink={file}
            />
        </div>
    )
}
import React, { useState} from "react";
import TableComponent from "../../../components/TableComponent/TableComponentFiltro";
export const Perfiles = () => {
  const [cabeceras] = useState([
    "Id",
    "Perfil",
    "Sistema",
    "Editar",
    "Eliminar",
  ]);
  const [filter] = useState([true, true, true, false]);
  const [parametros] = useState({
    action: "datatable",
    table: "profiles A INNER JOIN apparatus B USING (keySystem)", 
    rows: "A.keyProfile,A.nameProfile,B.nameSystem,A.keySystem",
    conditions: "A.enabled = 1 AND B.enabled = 1",
    page:0,
    records:5,
    search:"",
    order:""
  });
  return (
    <div>
        <TableComponent
          titulo="Perfiles"
          filtro={filter}
          cabecerasTabla={cabeceras}
          rutaCrear="/template/perfiles/crear"
          rutaEditar="/template/perfiles/editar"
          parametros={parametros}
        ></TableComponent>
    </div>
  );
};
export default Perfiles;

import React from "react";
import Widget from "../../../../components/Widget/Widget";
import FormRubros from "../FormRubros";
import { createIncomes } from "../functions";

export const CrearRubro = () => {

  return (
    <Widget className="widget-p-md">
        <h3>Crear Rubro de Ingreso</h3>
        <FormRubros crear={createIncomes}/>
    </Widget>
  )
}

export default CrearRubro;
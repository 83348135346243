import React from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from "reactstrap";

/**
 * 
 * @param {*} props 
 * @param {JSX.Element} children - children - Contenido del modal
 * @param {string} modalTitle - titulo del modal
 * @param {*} modal - Hook modal
 * @param {*} setModal - setModal - Set hook modal
 * @param {Function} crear - crear Funcion que se ejecuta al aceptar
 * @param {Function} editar - (Opcional) Funcion para editar
 * @param {boolean} isEdit - isEdit (Opcional) Si se está editando
 * @param {*} values - valores (Opcional)
 * @param {function} reset - reset (Opcional) 
 * @param {string} buttonOkMsg - bTexto del boton aceptar
 * @param {boolean} hiddeOk - hiddeOk (Opcional) Ocultar Botón aceptar
 * @param {boolean} hiddeCancel - hiddeCancel (Opcional) Ocultar Botón Cancelar
 * @param {*} size - (Opcional) tamño del modal
 * @param {boolean} centered - (Opcional) centrar verticalmente el modal
 * @returns 
 */
export default function ModalConfirmation(props) {
  const {
    children,
    modalTitle,
    modal,
    setModal,
    editar,
    crear,
    isEdit,
    values,
    reset,
    buttonOkMsg,
    hiddeOk,
    hiddeCancel,
    size,
    centered,
    cancel,
    cancelOverride = false,
    buttonCancelMsg,
    cancelColor
  } = props;

  const toggle = () => setModal(!modal);

  const CloseBtn = (
    <i className="eva eva-close cursor-pointer" onClick={toggle} />
  );
  const toDo = () => {
    setModal(!modal);
    if (isEdit) {
      editar(values, reset);
    } else {
      crear(values, reset);
    }
  };

  return (
    <>
      <Modal isOpen={modal} toggle={toggle} size={size || ""} centered={centered} keyboard={false}>
        <ModalHeader toggle={toggle} close={CloseBtn} className="headline-1">
          {modalTitle || ""}
        </ModalHeader>
        <ModalBody className="pb-2" >{children}</ModalBody>
        <ModalFooter className="pb-3">
          <div className="w-100 d-flex flex-md-row flex-column-reverse justify-content-md-around mx-5 mx-md-5 px-3">
            {!hiddeCancel && (
              <Button
                className="btn-outline-secondary mt-3"
                outline
                onClick={cancelOverride ? cancel : toggle}
                color={cancelColor || "danger"}
              >
                { buttonCancelMsg || "Cancelar" }
              </Button>
            )}
            {!hiddeOk && (
              <Button color="success" onClick={toDo} className="mt-md-3">
                {buttonOkMsg ? buttonOkMsg : "Guardar"}
              </Button>
            )}
          </div>
        </ModalFooter>
      </Modal>
    </>
  );
}

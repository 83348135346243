import { Row, Col, Button, Modal, ModalHeader, ModalBody, Table } from "reactstrap";
import { useState, useEffect, useRef } from "react";
import { toast } from "react-toastify";
import ReactQuill from "react-quill";
import { useFormik } from "formik";
import * as Yup from "yup";

import { SelectTypeHeadSingle, TextInput } from "../../../../components/GenericInputsFormik";
import ModalConfirmation from "../../../../components/Modal/ModalConfirmation";
import Notification from "../../../../components/Notification/Notification";
import { peticionesReceiver } from "../../../../helpers/peticionesReceiver";
import { toolbar } from './helpers/configTextEditor'

import "./helpers/_textEditor.scss";
import Loader from "../../../../components/Loader/Loader";

import Datatable from "../../../../components/Datatable/Datatable";
import Widget from "../../../../components/Widget/Widget";

import { valuesTable } from "../../../../libs/tools/fillFormats";

const headers = ["Palabra", "Descripción"]

const columns = [
    { data: "word" },
    { data: "desc" }
]

export default function FormFormato({ data }) {
    const API = peticionesReceiver();
    const [modal, setModal] = useState(false);
    const [modalWords, setModalWords] = useState(false);
    const [types, setTypes] = useState([])
    const [loading, setLoading] = useState(true);
    const quillRef = useRef(null)
    const [cursorPosition, setCursorPosition] = useState(0)

    const onClick = (row) => {
        const editor = quillRef.current.getEditor();
        editor.insertText(cursorPosition, row.word)
        setModalWords(false);
    };

    function setCursor() {
        try {
            const editor = quillRef.current.getEditor();
            const cursorPosition = editor.getSelection().index;
            setCursorPosition(cursorPosition);
        } catch (err) {

        }
    }

    const defaultForm = {
        idFormat: data ? data[0] : "",
        textFormat: "",
        nameFormat: "",
        keyTypeFormat: ""
    }

    const FormSchema = Yup.object().shape({
        idFormat: Yup.number(),
        textFormat: Yup.string()
            .required("Favor de ingresar el contenido del formato"),
        nameFormat: Yup.string()
            .required("Ingresar el nombre del formato"),
        keyTypeFormat: Yup.number()
            .required("Seleccionar un tipo de formato valido")
    });


    const { handleSubmit, values, handleBlur, errors, touched, setFieldValue, resetForm } =
        useFormik({
            initialValues: defaultForm,
            onSubmit: () => setModal(true),
            validationSchema: FormSchema,
        });

    const handleEditor = (editorState) => {
        setFieldValue('textFormat', editorState)
        setCursor()
    }

    const onChange = (e) => {
        setFieldValue(e.target.name, e.target.value);
    };

    async function getDataTypes() {
        const params = {
            action: "multiselect",
            table: "types_formats",
            rows: "keyTypeFormat,nameTypeFormat",
            conditions: `enabled = 1`,
        }
        const res = await API.peticion(params)
        if (res.status === 200 && res.data.code === "200") {
            const data = res.data.data;
            setTypes(data);
            setLoading(false)
        } else {
            toast(<Notification type={"consultar_error"} withIcon />);
        }
    }

    useEffect(() => {
        getDataTypes()
    }, [])

    async function getFormat(id) {
        const params = {
            action: "multiselect",
            table: "formats",
            rows: "idFormat,textFormat,nameFormat,keyTypeFormat",
            conditions: `idFormat = ${id}`
        }
        const res = await API.peticion(params)
        if (res.status === 200 && res.data.code === "200") {
            const data = res.data.data[0];
            setFieldValue(["idFormat"], data.idFormat);
            const textFormat = data.textFormat.replace(/<([A-Z]+)([^>]*)>/g, function (match, p1, p2) {
                return '<' + p1.toLowerCase() + (p2 ? p2.toLowerCase() : '') + '>';
            });
            setFieldValue(["textFormat"], textFormat);
            setFieldValue(["nameFormat"], data.nameFormat);
            setFieldValue(["keyTypeFormat"], data.keyTypeFormat);
            setCursorPosition(data.textFormat.length);
        } else {
            toast(<Notification type={"consultar_error"} withIcon />);
        }
    }

    useEffect(() => {
        if (data && !loading) {
            getFormat(data[0])
        }
    }, [data, loading])

    function getParams(rows, create) {
        const params = {
            table: "formats",
            action: create ? "insert" : "update",
            rows: {
                textFormat: rows.textFormat.replace(/&nbsp;/g, ""),
                nameFormat: rows.nameFormat,
                keyTypeFormat: rows.keyTypeFormat
            },
            validate: [["nameFormat", "keyTypeFormat"]]
        }
        if (!create) {
            params.condition = { idFormat: rows.idFormat }
        }
        return params
    }

    async function OnCreate(rows) {
        const API = peticionesReceiver();
        try {
            const res = await API.peticion(getParams(rows, true))
            if (res.status === 200 && res.data.code === "200") {
                toast(<Notification type={"agrega_exito"} backMessage={res.data.message} withIcon />);
                window.location.href = "#/template/plantillas-formatos"
            } else {
                toast(<Notification type={"agrega_error"} backMessage={res.data.message} withIcon />);
            }
        } catch (err) {
            toast(<Notification type={"agrega_error"} withIcon />);
        }
    }

    async function OnEdit(rows) {
        const API = peticionesReceiver();
        try {
            const res = await API.peticion(getParams(rows, false))
            if (res.status === 200 && res.data.code === "200") {
                toast(<Notification type={"modifica_exito"} backMessage={res.data.message} withIcon />);
                window.location.href = "#/template/plantillas-formatos"
            } else {
                toast(<Notification type={"modifica_error"} backMessage={res.data.message} withIcon />);
            }
        } catch (err) {
            console.log(err)
            toast(<Notification type={"modifica_error"} withIcon />);
        }
    }

    if (loading) {
        return (
            <>
                <Loader />
            </>
        )
    }
    return (
        <>
            <Widget className="widget-p-md">
                <form onSubmit={handleSubmit}>
                    <Row>
                        <Col xs={12} md={6}>
                            <TextInput
                                label="Nombre"
                                inputName="nameFormat"
                                inputType="text"
                                onChangeMethod={onChange}
                                handleBlur={handleBlur}
                                value={values.nameFormat}
                                isRequired
                                touched={touched.nameFormat}
                                errors={errors.nameFormat}
                            />
                        </Col>
                        <Col xs={12} md={6}>
                            <SelectTypeHeadSingle
                                label="Tipo de formato"
                                inputName="keyTypeFormat"
                                onChangeMethod={onChange}
                                onBlurMethod={handleBlur}
                                value={values.keyTypeFormat}
                                touched={touched.keyTypeFormat}
                                errors={errors.keyTypeFormat}
                                optionsArray={types}
                                optionValue="keyTypeFormat"
                                optionName="nameTypeFormat"
                            />
                        </Col>
                    </Row>
                    <div className="form-group" style={{ marginTop: 20 }}>
                        <h5>Contenido: </h5>
                        <div className="text-right mb-3">
                            <Button color="info" onClick={() => setModalWords(true)}>Palabras reservadas</Button>
                        </div>
                        <ReactQuill
                            theme="snow"
                            value={values.textFormat}
                            className={errors.textFormat && touched.textFormat ? "ql-error" : ''} // Add a dynamic class 
                            ref={quillRef}
                            onChange={handleEditor}
                            modules={toolbar}
                            onFocus={setCursor}
                        />
                        {errors.textFormat && touched.textFormat && (
                            <span style={{ color: "red" }}>{errors.textFormat}</span>
                        )}
                    </div>
                    <div className="text-center">
                        <Button color="danger" className="mx-1" onClick={() => window.location.href = "#/template/plantillas-formatos"}>Cancelar</Button>
                        <Button color="add" className="mx-1" type="submit">{data ? "Guardar cambios" : "Agregar"}</Button>
                    </div>
                </form>
                <ModalConfirmation
                    modalTitle={data ? "Editar formato" : "Agregar formato"}
                    modal={modal}
                    setModal={setModal}
                    editar={(rows) => OnEdit(rows)}
                    crear={(rows) => OnCreate(rows)}
                    isEdit={data ? true : false}
                    values={values}
                >
                    <div className="d-flex justify-content-center">
                        <h6>¿Desea continuar con el registro?</h6>
                    </div>
                </ModalConfirmation>
                <Modal isOpen={modalWords} toggle={() => setModalWords(false)} size="lg" backdrop="static" >
                    <ModalHeader toggle={() => setModalWords(false)}>Palabras reservadas</ModalHeader>
                    <ModalBody>
                        <Datatable
                            data={valuesTable}
                            columns={columns}
                            headers={headers}
                            eventsRow={{ onClick }}
                        />
                    </ModalBody>
                </Modal>
            </Widget>
        </>
    )
}
import { useState } from "react";
import Widget from "../../../../components/Widget/Widget";
import TableIncident from "./TableIncident";
import { GetPermissions } from "../../../../libs/permissions/getPermissions";


export default function AutorizarIncidencia() {
    const [permissions, setPermissions] = useState({ id: 0 });
    GetPermissions(setPermissions);

    const [refreshA, setRefreshA] = useState(false);
    const [refreshB, setRefreshB] = useState(false);

    return (
        <Widget className="widget-p-md">
            <div className="mt-4">
                <h4>Autorización de Incidencias</h4>
            </div>
            {
                permissions.CON ?
                <>
                    <div className="mt-4">
                        <h5>Registradas</h5>
                        <TableIncident type={0} refresh={refreshA} setRefresh={setRefreshA} setRefreshB={setRefreshB} permissions={permissions} />
                    </div>
                    <hr />
                    <div className="mt-5">
                        <h5>Autorizadas / Negadas</h5>
                        <TableIncident type={1} refresh={refreshB} setRefresh={setRefreshB} permissions={permissions} />
                    </div>
                </> :
                (<div className="d-flex justify-content-center my-5 py-5">
                    <h5>No tiene autorización para consultar esta información</h5>
                </div>)
            }
        </Widget>
    );
}

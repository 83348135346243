import Form from "../Form";
import { useHistory } from "react-router";

export default function EditarBienMueble() {
  const history = useHistory();
  const returnOp = () => {
    history.push("/template/bienesmuebles");
  };

  return (
    <>
      <Form
        data={JSON.parse(localStorage.getItem("furnitureData"))}
        OnSuccess={returnOp}
        OnCancel={returnOp}
      />
    </>
  );
}

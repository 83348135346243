import { saveAsExcel } from "../../../../components/excel/Excel";

export const title = "REPORTE DE PROVEEDORES DE NOMINA"
const cabeceras = [
    "NO",
    "RAZÓN SOCIAL",
    "RFC",
    "BANCO",
    "CUENTA",
    "CLABE"
]
export const config = {
    id: { id: "idProviderPayRoll" },
    headers: [
        "NO",
        "RAZÓN SOCIAL",
        "RFC",
        "BANCO",
        "CUENTA",
        "CLABE"
    ],
    content: [
        { header: "RAZÓN SOCIAL", value: "businessName"},
        { header: "RFC", value: "rfc"},
        { header: "BANCO", value: "nameBank"},
        { header: "CUENTA", value: "account"},
        { header: "CLABE", value: "switfCode"},
    ]
}

const configExcel = {
    id: { id: "idProviderPayRoll" },
    headers: [
        "NO",
        "RAZÓN SOCIAL",
        "RFC",
        "BANCO",
        "CUENTA",
        "CLABE"
    ],
    content: [[
        { header: "RAZÓN SOCIAL", value: "businessName"},
        { header: "RFC", value: "rfc"},
        { header: "BANCO", value: "nameBank"},
        { header: "CUENTA", value: "account"},
        { header: "CLABE", value: "switfCode"},
    ]]
}

const params = {
    action: "datatable",
    table: "providerspayroll A LEFT JOIN banks B USING (keyBank) LEFT JOIN status C USING (keyStat)",
    rows: "A.idProviderPayRoll,A.businessName,A.rfc,B.nameBank,A.account,A.switfCode,C.namestat", ///sin espacios y comas
    conditions: "A.enabled = 1 AND B.enabled = 1 AND C.enabled = 1",
}

export function exportAsExcel(report) {
    saveAsExcel(report, cabeceras, title, configExcel, params);
}


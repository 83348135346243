


import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import InicioDetallesCarga from "./init";
import DetalleProveedorCargaFacturas from "./detailProvider";

export default function DetallesCargaFactura() {
    const history = useHistory()
    
    const [id, setId] = useState("");
    const [idWinner, setIdWinner] = useState("");


    useEffect(() => {
        const id = localStorage.getItem("loadBill");
        if (id) {
            setId(id);
        } else {
            history.push("/template/carga-facturas")
        }
    }, [])

    if (idWinner) {
        return (
            <>
                <DetalleProveedorCargaFacturas
                    idWinner={idWinner}
                    setIdWinner={setIdWinner}
                />
            </>
        )
    } 
    return (
        <>
            <InicioDetallesCarga
                id={id}
                setIdWinner={setIdWinner}
            />
        </>
    )
}
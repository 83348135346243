import Datatable from "../../../../components/Datatable/Datatable";
import { doRequest, doRequestSaveRes } from "../../../../helpers/requests";
import ModalComponentEliminar from "../../../../components/Modal/ModalDelete";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  SelectTypeHeadSingle,
  TextAreaInput,
} from "../../../../components/GenericInputsFormik";
import { Alert, Button, Col, Collapse, CustomInput, Row } from "reactstrap";
import { useEffect, useState } from "react";
import { NumberInput } from "../../../../components/GenericInputsFormik/NumberInput";
import ModalConfirmation from "../../../../components/Modal/ModalConfirmation";
import FullFormLoader from "../../../../components/Loader/FullFormLoader";
import { toast } from "react-toastify";
import Notification from "../../../../components/Notification/Notification";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane } from "@fortawesome/free-regular-svg-icons";
import { faHouse } from "@fortawesome/free-solid-svg-icons";
import FormRegistroMultiple from "./FormRegistroMultiple";
import { fMoney } from "../../../../libs/tools/format";

const headers = [
  "PROYECTO PRESUPUESTAL",
  "PARTIDA",
  "DESCRIPCIÓN",
  "MULTIANUAL",
  "ENERO",
  "FEBRERO",
  "MARZO",
  "ABRIL",
  "MAYO",
  "JUNIO",
  "JULIO",
  "AGOSTO",
  "SEPTIEMBRE",
  "OCTUBRE",
  "NOVIEMBRE",
  "DICIEMBRE",
  "TOTAL",
];

const columnDefs = [
  {
    orderable: false,
    targets: [4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15],
    className: "text-right",
  },
  {
    orderable: true,
    targets: [16],
    className: "text-right",
  },
  {
    orderable: true,
    targets: [0, 1, 2],
    className: "text-justify",
  },
  {
    orderable: true,
    targets: [3],
    className: "text-center",
  },
];

const defaultForm = {
  idDetail: "",
  idStateFund: "",
  keyChapter: "",
  idExpenseObject: "",
  concept: "",
  m1: 0,
  m2: 0,
  m3: 0,
  m4: 0,
  m5: 0,
  m6: 0,
  m7: 0,
  m8: 0,
  m9: 0,
  m10: 0,
  m11: 0,
  m12: 0,
  total: 0,
  multianual: false,
};

const FormSchema = Yup.object().shape({
  idStateFund: Yup.string().required("Seleccione un proyecto presupuestal"),
  keyChapter: Yup.string().required("Seleccione un capítulo"),
  idExpenseObject: Yup.string().required("Seleccione una partida"),
  concept: Yup.string().required("Ingrese una descipción"),
  m1: Yup.number()
    .required("Ingrese una cantidad")
    .min(0, "Ingrese un valor positivo"),
  m2: Yup.number()
    .required("Ingrese una cantidad")
    .min(0, "Ingrese un valor positivo"),
  m3: Yup.number()
    .required("Ingrese una cantidad")
    .min(0, "Ingrese un valor positivo"),
  m4: Yup.number()
    .required("Ingrese una cantidad")
    .min(0, "Ingrese un valor positivo"),
  m5: Yup.number()
    .required("Ingrese una cantidad")
    .min(0, "Ingrese un valor positivo"),
  m6: Yup.number()
    .required("Ingrese una cantidad")
    .min(0, "Ingrese un valor positivo"),
  m7: Yup.number()
    .required("Ingrese una cantidad")
    .min(0, "Ingrese un valor positivo"),
  m8: Yup.number()
    .required("Ingrese una cantidad")
    .min(0, "Ingrese un valor positivo"),
  m9: Yup.number()
    .required("Ingrese una cantidad")
    .min(0, "Ingrese un valor positivo"),
  m10: Yup.number()
    .required("Ingrese una cantidad")
    .min(0, "Ingrese un valor positivo"),
  m11: Yup.number()
    .required("Ingrese una cantidad")
    .min(0, "Ingrese un valor positivo"),
  m12: Yup.number()
    .required("Ingrese una cantidad")
    .min(0, "Ingrese un valor positivo"),
  total: Yup.number()
    .required("Llene la lalendarización del gasto")
    .min(1, "Llene la calendarización del gasto"),
});

export default function Detalles({
  soliciutud,
  setSolicitud,
  proyectosP,
  capitulos,
  permissions,
  controlStatus,
  isAuthorizedArea
}) {
  const [refresh, setRefresh] = useState(false);
  const [collapse, setCollapse] = useState(false);
  const [partidas, setPartidas] = useState([]);
  const [modalConfirm, setModalConfirm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [modalSend, setModalSend] = useState(false);
  const [showSend, setShowSend] = useState(true);
  const [title, settitle] = useState("Agregar detalle");

  const {
    handleSubmit,
    values,
    handleBlur,
    errors,
    touched,
    setFieldValue,
    handleReset,
    setValues,
  } = useFormik({
    initialValues: defaultForm,
    validationSchema: FormSchema,
    onSubmit: () => setModalConfirm(true),
    onReset: () => {
      setCollapse(false);
      settitle("Agregar detalle");
    },
    enableReinitialize: true,
  });

  useEffect(() => {
    if (+soliciutud.keyStat !== 116) setShowSend(false);
  }, []);

  /* Form */
  const onChange = ({ target }) => {
    if (target.name === "multianual") {
      setFieldValue("multianual", !values.multianual);
    } else {
      if (target.name.includes("m")) {
        const total =
          values.total - (values[target.name] || 0) + (+target.value || 0);
        setFieldValue("total", total);
      }
      setFieldValue(target.name, target.value);
    }
  };

  useEffect(() => {
    if (values.idStateFund && values.keyChapter) {
      getPartidas();
    }
  }, [values.idStateFund, values.keyChapter]);

  async function getPartidas() {
    const params = {
      action: "multiselect",
      table:
        "expenses_chapters A INNER JOIN expenses_concepts B USING (keyChapter) INNER JOIN expenses_items C USING (keyConcept) INNER JOIN expense_objects D USING (keyItem)",
      rows: "D.idExpenseObject, CONCAT(A.chapterKey,B.conceptKey,C.itemKey,D.objectKey, ' - ', D.nameObject) as label",
      conditions: `A.enabled = 1 AND B.enabled = 1 AND C.enabled = 1 AND D.enabled = 1 AND A.keyChapter = ${values.keyChapter}`,
    };
    const data = await doRequest("receiver/receiver.php", params);
    if (data.length > 0) setPartidas(data);
  }

  async function saveInfo() {
    setLoading(true);
    const params = {
      action: "saveBudgetDetail",
      idExpenseObject: values.idExpenseObject,
      idStateFund: values.idStateFund,
      idBudgetRequest: soliciutud.idBudgetRequest,
      concept: values.concept,
      multianual: values.multianual ? 1 : 0,
      months: [...Array(12)].map((d, ix) => {
        return +values[`m${ix + 1}`];
      }),
    };

    if (values.idDetail !== "") {
      params.action = "updateBudgetDetail";
      params.idDetail = values.idDetail;
    }

    const res = await doRequest(
      "app/facades/budgets/budgetsF.php",
      params,
      true
    );
    if (res.length > 0) {
      handleReset();
      setRefresh(true);
    }
    setLoading(false);
  }

  const canFill = () => !(values.idStateFund && values.keyChapter);
  /* Form */

  /* Table */
  async function getData(ordCol, itemsPerPage, currentPage, search) {
    const res = await doRequestSaveRes(
      "receiver/receiver.php",
      getParams(ordCol, itemsPerPage, currentPage, search),
      false,
      false
    );
    if (res.code === "200") {
      res.data = res.data.map((el) => {
        const sm = el.amount_months.split(",");
        return {
          ...el,
          m1: selectMonths(sm, 1),
          m2: selectMonths(sm, 2),
          m3: selectMonths(sm, 3),
          m4: selectMonths(sm, 4),
          m5: selectMonths(sm, 5),
          m6: selectMonths(sm, 6),
          m7: selectMonths(sm, 7),
          m8: selectMonths(sm, 8),
          m9: selectMonths(sm, 9),
          m10: selectMonths(sm, 10),
          m11: selectMonths(sm, 11),
          m12: selectMonths(sm, 12),
        };
      });
      return res;
    } else return null;
  }

  function getParams(ordCol, itemsPerPage, currentPage, search) {
    const params = {
      action: "datatable",
      table:
        "budgetrequestdetail A INNER JOIN statefunds B USING(idStateFund) INNER JOIN expense_objects C ON C.idExpenseObject = A.idExpenseObject INNER JOIN expenses_items D ON D.keyItem = C.keyItem INNER JOIN expenses_concepts E ON E.keyConcept = D.keyConcept INNER JOIN expenses_chapters F ON F.keyChapter = E.keyChapter INNER JOIN budgetrequestschedule G USING (idDetail)",
      rows: "B.nameStateFund, CONCAT(F.chapterKey,E.conceptKey,D.itemKey,C.objectKey, ' - ', C.nameObject) as partida, B.idStateFund, C.idExpenseObject, A.concept, GROUP_CONCAT(CONCAT(G.keyMonth,'m',G.required) ORDER BY G.keyMonth ASC) AS amount_months, A.idDetail, SUM(G.required) AS total, A.idBudgetRequest, F.keyChapter, C.idExpenseObject, A.multianual",
      conditions: `A.enabled = 1 AND A.idBudgetRequest = ${soliciutud.idBudgetRequest} AND G.enabled = 1`,
      order: ordCol,
      records: itemsPerPage.toString(),
      page: (itemsPerPage * currentPage).toString(),
      search,
      group: "A.idDetail",
    };
    return params;
  }

  function selectMonths(arr, m) {
    const ewe = arr.find((el) => el.includes(m + "m"));
    if (!ewe) return 0;
    return +ewe.split("m")[1];
  }

  const columns = [
    {
      data: null,
      render: function (d) {
        return (
          <div style={{ minWidth: "200px" }}>
            {d.nameStateFund}
            <br />
            {showSend && (
              <>
                {permissions.UPD && (+controlStatus.enabled === 1 || isAuthorizedArea) &&  (
                  <i
                    className="fa fa-pencil text-warning cursor-pointer mx-2"
                    onClick={() => openToEdit(d)}
                  />
                )}
                {permissions.DEL && (+controlStatus.enabled === 1 || isAuthorizedArea) && (
                  <ModalComponentEliminar
                    modalTitle="Eliminar"
                    id={d.idDetail}
                    table="budgetrequestdetail"
                    nameColumn="idDetail"
                    elimina={deleteRow}
                  >
                    <h6>¿Seguro de eliminar el registro "{d.concept}"?</h6>
                    No podrá recuperar la información después de ser eliminada
                  </ModalComponentEliminar>
                )}
              </>
            )}
          </div>
        );
      },
      orderValue: "nameStateFund",
    },
    {
      data: "partida",
      render: function (d) {
        return <div style={{ minWidth: "150px" }}>{d}</div>;
      },
    },
    { data: "concept" },
    {
      data: "multianual",
      render: function (d) {
        return +d === 1 ? "SI" : "NO";
      },
    },
    {
      data: "m1",
      render: function (d) {
        return d ? fMoney(d) : "$0.00";
      },
    },
    {
      data: "m2",
      render: function (d) {
        return d ? fMoney(d) : "$0.00";
      },
    },
    {
      data: "m3",
      render: function (d) {
        return d ? fMoney(d) : "$0.00";
      },
    },
    {
      data: "m4",
      render: function (d) {
        return d ? fMoney(d) : "$0.00";
      },
    },
    {
      data: "m5",
      render: function (d) {
        return d ? fMoney(d) : "$0.00";
      },
    },
    {
      data: "m6",
      render: function (d) {
        return d ? fMoney(d) : "$0.00";
      },
    },
    {
      data: "m7",
      render: function (d) {
        return d ? fMoney(d) : "$0.00";
      },
    },
    {
      data: "m8",
      render: function (d) {
        return d ? fMoney(d) : "$0.00";
      },
    },
    {
      data: "m9",
      render: function (d) {
        return d ? fMoney(d) : "$0.00";
      },
    },
    {
      data: "m10",
      render: function (d) {
        return d ? fMoney(d) : "$0.00";
      },
    },
    {
      data: "m11",
      render: function (d) {
        return d ? fMoney(d) : "$0.00";
      },
    },
    {
      data: "m12",
      render: function (d) {
        return d ? fMoney(d) : "$0.00";
      },
    },
    { data: "total", format: "currency" },
  ];

  async function deleteRow(id) {
    const params = {
      action: "delete",
      table: "budgetrequestdetail",
      condition: { idDetail: id },
      force: 1,
    };
    const res = await doRequest("receiver/receiver.php", params, false);
    if (res.length > 0) {
      params.table = "budgetrequestschedule";
      const res2 = await doRequest("receiver/receiver.php", params, true);
      setRefresh(true);
      if (collapse) setCollapse(false);
    }
  }

  function openToEdit(d) {
    if (collapse) {
      toast(
        <Notification
          type={"warning"}
          backMessage="Guarde sus cambios antes de continuar"
          withIcon
        />,
        { closeButton: false }
      );
    } else {
      setValues({
        idDetail: d.idDetail,
        idStateFund: d.idStateFund,
        keyChapter: d.keyChapter,
        idExpenseObject: d.idExpenseObject,
        concept: d.concept,
        m1: +d.m1 || 0,
        m2: +d.m2 || 0,
        m3: +d.m3 || 0,
        m4: +d.m4 || 0,
        m5: +d.m5 || 0,
        m6: +d.m6 || 0,
        m7: +d.m7 || 0,
        m8: +d.m8 || 0,
        m9: +d.m9 || 0,
        m10: +d.m10 || 0,
        m11: +d.m11 || 0,
        m12: +d.m12 || 0,
        total: +d.total,
        multianual: +d.multianual === 1,
      });
      settitle("Editar detalle");
      setCollapse(true);
    }
  }
  /* Table */

  async function sendRequest() {
    setLoading(true);
    const params = {
      action: "sendBudgetRequest",
      idBudgetRequest: soliciutud.idBudgetRequest,
    };
    const res = await doRequest(
      "app/facades/budgets/budgetsF.php",
      params,
      true
    );
    if (res.length > 0) setShowSend(false);
    setLoading(false);
  }

  async function canSend() {
    if (collapse) {
      toast(
        <Notification
          type={"warning"}
          backMessage="Guarde sus cambios antes de continuar"
          withIcon
        />,
        { closeButton: false }
      );
    } else {
      const exist = await getData("", 5, 0, "");
      if (exist && exist.data.length < 1) {
        toast(
          <Notification
            type={"warning"}
            backMessage="Agregue al menos un detalle a la lista"
            withIcon
          />,
          { closeButton: false }
        );
      } else setModalSend(true);
    }
  }

  return (
    <div>
      <div className=" d-flex flex-md-row flex-column justify-content-md-between mb-md-2 mb-4">
        <h4>Detalles</h4>
        <Button
          onClick={() => setSolicitud({ idBudgetRequest: "" })}
          style={{ backgroundColor: "#cc0a44" }}
          className="px-3"
        >
          <FontAwesomeIcon icon={faHouse} className="mr-2" />
          Cerrar
        </Button>
      </div>

      {
        soliciutud.notes &&
        <Alert color="light" className="border border-dark text-dark mt-4 mb-4" fade={false}>
          <h5 className="alert-heading">Comentarios/Observaciones</h5>
          <p className="mb-0">
            {soliciutud.notes}
          </p>
        </Alert>
}

      {permissions.CON && (
        <Datatable
          headers={headers}
          columns={columns}
          columnDefs={columnDefs}
          petition={getData}
          control="back"
          stateRefresh={[refresh, setRefresh]}
          searching={true}
          className="px-0"
        />
      )}

      {permissions.INS && showSend && (+controlStatus.enabled === 1 || isAuthorizedArea) && (
        <div className="my-3 d-flex flex-md-row flex-column justify-content-md-end">
          <Button onClick={() => setCollapse(true)}>Agregar</Button>
        </div>
      )}

      <Collapse isOpen={collapse} className="pt-3 border-top">
        <h5>{title}</h5>

        {!values.idDetail && (
          <FormRegistroMultiple
            chapters={capitulos}
            idBudgetRequest={soliciutud.idBudgetRequest}
            setRefresh={setRefresh}
            mainReset={handleReset}
          />
        )}

        <form className="mt-2" onSubmit={handleSubmit} onReset={handleReset}>
          <Row>
            <Col xs={12} md={6}>
              <SelectTypeHeadSingle
                label="Proyecto presupuestal"
                inputName="idStateFund"
                onChangeMethod={onChange}
                onBlurMethod={handleBlur}
                value={values.idStateFund}
                optionsArray={proyectosP}
                multipleLabels={["stateFundKey", "nameStateFund"]}
                optionValue="idStateFund"
                optionName="name"
                errors={errors.idStateFund}
                touched={touched.idStateFund}
                isRequired
              />
            </Col>
            <Col xs={12} md={6}>
              <SelectTypeHeadSingle
                label="Capítulo"
                inputName="keyChapter"
                onChangeMethod={onChange}
                onBlurMethod={handleBlur}
                value={values.keyChapter}
                optionsArray={capitulos}
                optionValue="keyChapter"
                optionName="label"
                errors={errors.keyChapter}
                touched={touched.keyChapter}
                isRequired
              />
            </Col>
          </Row>

          <Collapse isOpen={!canFill()}>
            <Row>
              <Col xs={12} md={6}>
                <SelectTypeHeadSingle
                  label="Partida"
                  inputName="idExpenseObject"
                  onChangeMethod={onChange}
                  onBlurMethod={handleBlur}
                  value={values.idExpenseObject}
                  optionsArray={partidas}
                  optionValue="idExpenseObject"
                  optionName="label"
                  errors={errors.idExpenseObject}
                  touched={touched.idExpenseObject}
                  isRequired
                  isDisabled={canFill()}
                />
                <CustomInput
                  type="switch"
                  id="multianual"
                  name="multianual"
                  label="Multianual"
                  disabled={canFill()}
                  onChange={onChange}
                  checked={values.multianual}
                />
              </Col>
              <Col xs={12} md={6}>
                <TextAreaInput
                  label="Descripción de bien o servicio"
                  inputName="concept"
                  inputRows={6}
                  onChangeMethod={onChange}
                  onBlurMethod={handleBlur}
                  value={values.concept}
                  isRequired
                  touched={touched.concept}
                  errors={errors.concept}
                />
              </Col>
            </Row>

            <div className="mt-2">
              <div className="text-center">
                <h4>Calendarización del Gasto</h4>
              </div>

              <Row className="border border-primary border-right-0 mx-1 mt-1">
                <MesField
                  name="m1"
                  title="Enero"
                  error={errors.m1}
                  value={values.m1}
                  touched={touched.m1}
                  onChange={onChange}
                  handleBlur={handleBlur}
                />
                <MesField
                  name="m2"
                  title="Febrero"
                  error={errors.m2}
                  value={values.m2}
                  touched={touched.m2}
                  onChange={onChange}
                  handleBlur={handleBlur}
                />
                <MesField
                  name="m3"
                  title="Marzo"
                  error={errors.m3}
                  value={values.m3}
                  touched={touched.m3}
                  onChange={onChange}
                  handleBlur={handleBlur}
                />
                <MesField
                  name="m4"
                  title="Abril"
                  error={errors.m4}
                  value={values.m4}
                  touched={touched.m4}
                  onChange={onChange}
                  handleBlur={handleBlur}
                />
                <MesField
                  name="m5"
                  title="Mayo"
                  error={errors.m5}
                  value={values.m5}
                  touched={touched.m5}
                  onChange={onChange}
                  handleBlur={handleBlur}
                />
                <MesField
                  name="m6"
                  title="Junio"
                  error={errors.m6}
                  value={values.m6}
                  touched={touched.m6}
                  onChange={onChange}
                  handleBlur={handleBlur}
                />
                <MesField
                  name="m7"
                  title="Julio"
                  error={errors.m7}
                  value={values.m7}
                  touched={touched.m7}
                  onChange={onChange}
                  handleBlur={handleBlur}
                />
                <MesField
                  name="m8"
                  title="Agosto"
                  error={errors.m8}
                  value={values.m8}
                  touched={touched.m8}
                  onChange={onChange}
                  handleBlur={handleBlur}
                />
                <MesField
                  name="m9"
                  title="Septiembre"
                  error={errors.m9}
                  value={values.m9}
                  touched={touched.m9}
                  onChange={onChange}
                  handleBlur={handleBlur}
                />
                <MesField
                  name="m10"
                  title="Octubre"
                  error={errors.m10}
                  value={values.m10}
                  touched={touched.m10}
                  onChange={onChange}
                  handleBlur={handleBlur}
                />
                <MesField
                  name="m11"
                  title="Noviembre"
                  error={errors.m11}
                  value={values.m11}
                  touched={touched.m11}
                  onChange={onChange}
                  handleBlur={handleBlur}
                />
                <MesField
                  name="m12"
                  title="Diciembre"
                  error={errors.m12}
                  value={values.m12}
                  touched={touched.m12}
                  onChange={onChange}
                  handleBlur={handleBlur}
                />
              </Row>

              <div className="pt-2 d-flex flex-md-row flex-column justify-content-end">
                <h5 className="pt-3 pr-md-2">Total acumulado:</h5>
                <NumberInput
                  inputName="total"
                  onChangeMethod={() => {}}
                  value={values.total}
                  errors={errors.total}
                  touched={touched.total}
                  prefix="$"
                  isRequired
                  className="text-center"
                  // readOnly
                />
              </div>
            </div>
          </Collapse>

          <div className=" d-flex flex-md-row flex-column-reverse justify-content-md-around">
            <Button color="danger" type="reset" className="mt-3">
              Cancelar
            </Button>
            <Button color="success" type="submit" className="mt-2 mt-md-3">
              Guardar
            </Button>
          </div>

          <FullFormLoader show={loading} />
        </form>
      </Collapse>

      <ModalConfirmation
        modalTitle="Guardar detalle"
        modal={modalConfirm}
        setModal={setModalConfirm}
        crear={() => saveInfo()}
        values={values}
      >
        <div className="d-flex justify-content-center">
          <h6>¿Está seguro de guardar esta solicitud?</h6>
        </div>
      </ModalConfirmation>

      {showSend && +controlStatus.enabled === 1 && (
        <div className="mt-5 mb-3 text-center">
          <Button color="primary" className="" onClick={() => canSend()}>
            <FontAwesomeIcon icon={faPaperPlane} className="mr-2" />
            Enviar
          </Button>
        </div>
      )}

      <ModalConfirmation
        modalTitle="Guardar detalle"
        modal={modalSend}
        setModal={setModalSend}
        crear={() => sendRequest()}
        values={values}
      >
        <div className="d-flex justify-content-center">
          <h6>¿Está seguro de enviar esta solicitud de presupuesto?</h6>
        </div>
      </ModalConfirmation>
    </div>
  );
}

function MesField({
  name,
  title,
  error,
  value,
  touched,
  onChange,
  handleBlur,
}) {
  return (
    <Col xs={12} sm={4} md={3} className="p-0 border-right border-primary">
      <div className="">
        <div className="bg-primary text-center text-light p-1">{title}</div>
        <div className="px-2 pt-3">
          <NumberInput
            inputName={name}
            onChangeMethod={onChange}
            onBlurMethod={handleBlur}
            value={value}
            errors={error}
            touched={touched}
            prefix="$"
            isRequired
            className="text-center"
            decimals
          />
        </div>
      </div>
    </Col>
  );
}

import * as XlsxPopulate from 'xlsx-populate';
import { saveAs } from "file-saver";
import { peticionesReceiver } from "../../../../helpers/peticionesReceiver";
import { toast } from "react-toastify";
import Notification from "../../../../components/Notification/Notification";

const API = peticionesReceiver();


const formatter = new Intl.NumberFormat("es-MX", {
  style: "currency",
  currency: "MXN",
});


const styles =  {
    title: {
        bold: true
    },
    headerBorder:{
        bold: "true",
        border: true,
        fill: "BFBFBF"
    },
    contentBorder:{
        border: true
    }
}


export async function saveReport7AsExcel(report,headers,title,employee) {
   // peticion y al finalizar llama a generateExcel (Main)
    getStaffGenerateExcel(report,headers,title,employee)
  }

  const getStaffGenerateExcel = async(report,headers,title) => {
    let staff = [];
    const params = {
    action: "getStaffData",
    idStaff:report[0].idStaff
    }
    API.peticionEndPoint(params, "app/facades/reports/reportsF.php")
      .then((res) => {
        console.log("Consulta Staff", res)
        if (res.status === 200) {
          staff = res.data.data
          generateExcel(report,headers,title,res.data.data[0])
          
        } else {
          console.log("Consulta Staff ERROR", res)
          toast(
            <Notification
              type={"consultar_error"}
              backMessage={res.data.message}
              withIcon
              />
              );
            }
          })
          .catch((err) => {
        console.log("Consulta Staff CATCH")
        toast(<Notification type={"consultar_servidor_error"} withIcon />);
      });
      return staff;
  };


  const generateExcel = (report,headers,title,staff) => {

    //Inicio de excel
    XlsxPopulate.fromBlankAsync().then(async (workbook) => {
        const sheet1 = workbook.sheet(0);
        
        //Titulos
        sheet1.range("E1:H1").merged(true).value("SAPP").style(styles.title);
        sheet1.range("E2:H2").merged(true).value(title).style(styles.title);
        
     
        
        //Inserta info del usuario
        insertStaff(sheet1,staff);
        

        //inserta percepciones y deducciones
        const perceptionsDeductions = getPerceptionsDeductions(report)
        insertPerceptionsDeductions(sheet1,perceptionsDeductions)
        /* De alguna forma fallaba al pasar el array */
        
        //da un estilo general a las celdas
        sheet1.usedRange().style("horizontalAlignment","center"); 
         return workbook.outputAsync().then((res) => {
          saveAs(res, `${title}.xlsx`);
        }); 
      });
  }


  const insertStaff = (sheet1,staff) => {
    //transform object to array
    const arrStaf = transformStaff(staff)

    //combining cells
    sheet1.range("D6:I6").merged(true)
    sheet1.range("D7:F7").merged(true)
    sheet1.range("G7:I7").merged(true)
    sheet1.range("D8:F8").merged(true)
    sheet1.range("G8:I8").merged(true)
    sheet1.range("D9:E9").merged(true)
    sheet1.range("F9:G9").merged(true)
    sheet1.range("H9:I9").merged(true)
    sheet1.range("D10:E10").merged(true)
    sheet1.range("F10:G10").merged(true)
    sheet1.range("H10:I10").merged(true)
    sheet1.range("D11:F11").merged(true)
    sheet1.range("G11:I11").merged(true)
    sheet1.range("D12:E12").merged(true)
    sheet1.range("F12:G12").merged(true)
    sheet1.range("H12:I12").merged(true)
    sheet1.range("D13:I13").merged(true)

    //title
    const rangeInfoGeneral = sheet1.range("D6:I13")
    rangeInfoGeneral.value(arrStaf).style("border", true);
  }

  const insertPerceptionsDeductions = (sheet1,perceptionsDeductions) => {
    //InsertHeaders

    for (let i = 0; i < perceptionsDeductions.headers.length; i++) {
      //Header percepciones
      sheet1
        .row(15)
        .cell(2 + i)
        .value(perceptionsDeductions.headers[i])
        .style(styles.headerBorder);
      sheet1.column(2 + i).width(30);
      //Header deducciones
      sheet1
        .row(15)
        .cell(8 + i)
        .value(perceptionsDeductions.headers[i])
        .style(styles.headerBorder);
      sheet1.column(8 + i).width(30);
    }
    sheet1.column("F").width(30);
    sheet1.column("G").width(30);
    sheet1.range("D6:I6").style(styles.headerBorder);

    /* Agrega array Perceptions and deductions*/
    sheet1
      .cell("B16")
      .value(perceptionsDeductions.perceptions)
      .style(styles.contentBorder);
    sheet1
      .cell("H16")
      .value(perceptionsDeductions.deductions)
      .style(styles.contentBorder);
    addTotals(sheet1,perceptionsDeductions,16)
  }


  //Agrega las celdas de total
  const addTotals = (sheet1,perceptionsDeductions,initialcell) => {
    

    //Deducciones
    const totalCellDeductions = perceptionsDeductions.deductions.length + initialcell
    const finalRangeDeductions = `H${totalCellDeductions}:J${totalCellDeductions}`
    const range = sheet1.range(finalRangeDeductions);
    console.log("RangoFinal", finalRangeDeductions)
    sheet1.range(`H${totalCellDeductions}:J${totalCellDeductions}`).value(perceptionsDeductions.totalDeductions[0])
    .style({ border: true, bold: true });
    sheet1.range(`H${totalCellDeductions}:J${totalCellDeductions}`).merged(true);
    sheet1
      .cell(`K${totalCellDeductions}`)
      .value(perceptionsDeductions.totalDeductions[1])
      .style(styles.contentBorder);

    sheet1.range(`H${totalCellDeductions+1}:J${totalCellDeductions+1}`).value(perceptionsDeductions.total[0])
    .style({ border: true, bold: true });
    sheet1.range(`H${totalCellDeductions+1}:J${totalCellDeductions+1}`).merged(true);
    sheet1
      .cell(`K${totalCellDeductions+1}`)
      .value(perceptionsDeductions.totalDeductions[1]+perceptionsDeductions.totalPerceptions[1])
      .style(styles.contentBorder);

    //Perceociones
    const totalCellPerceptions = perceptionsDeductions.perceptions.length + initialcell
    const finalRangePerceptions = `B${totalCellPerceptions}:D${totalCellPerceptions}`
    const rangePerceptions = sheet1.range(finalRangePerceptions);
    rangePerceptions
      .value(perceptionsDeductions.totalDeductions[0])
      .style({ border: true, bold: true });
    rangePerceptions.merged(true);

    sheet1
      .cell(`E${totalCellPerceptions}`)
      .value(perceptionsDeductions.totalPerceptions[1])
      .style(styles.contentBorder);
  } 

  const getPerceptionsDeductions = (report) => {
    const type = {
        headers:['Concepto','Partida','Concepto','Importe'],
        perceptions:[],
        deductions:[],
        totalPerceptions: ["Total de percepciones"],
        totalDeductions: ["Total de deducciones"],
        total: ["Total de neto"],
    }
    let totalPerceptions = 0 ,totalDeductions = 0 
    report.map(obj => {
        if(obj.keyTypeUnder === 1 || obj.keyTypeUnder === "1" ){
            type.perceptions.push([obj.under,obj.year,obj.nameUnder,formatter.format(obj.amount)])
            totalPerceptions += obj.amount ? parseInt(obj.amount) : 0
        }else{
            type.deductions.push([obj.under,obj.year,obj.nameUnder,formatter.format(obj.amount)])
            totalDeductions += obj.amount ? parseInt(obj.amount) : 0
        }
    })
    type.totalPerceptions.push(formatter.format(totalPerceptions))
    type.totalDeductions.push( formatter.format(totalDeductions))
    return type;
  };
  const transformStaff =  (staff) => {
    
    /* const staff = getStaff(idStaff) */
    
    return [
        ["Datos general del empleado"],
        ["NO.EMPLEADO: "+staff.staffNumber,"","","NOMBRE: "+staff.name],
        ["ADSCRIPCION: "+staff.nameArea,"","","REGION: "+staff.nameBuilding],
        ["PUESTO LABORAL: "+staff.nameJob,"","NIVEL DE RANGO: "+staff.levelrange,"","TIPO DE NOMBRAMIENTO: "+staff.nameAssignment],
        ["FECHA DE INGRESO: "+ staff.startJobDate,"","CURP: "+staff.curp,"","RFC: "+staff.rfc],
        ["FECHA DE INGRESO AL ISSEMYM: "+staff.startJobDate,"","","CLAVE ISSEMYM: "+staff.cuenta],
        ["BANCO: "+staff.nameBank,"CUENTA: "+staff.nameJob,"CLABE: "+staff.nameJob],
        ["ESTATUS: "+staff.statusStaff],
    ]
  };

import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import Notification from "../../../../../components/Notification/Notification";
import { peticionesReceiver } from "../../../../../helpers/peticionesReceiver";
import ModalAddGasto from "../../../componentes/modal/ModalAddGasto";
import ModalViewGasto from "../../../componentes/modal/ModalViewGasto";
import { Row, Col } from "reactstrap";
import CollapseTableColor from "../../../componentes/others/CollapseTableColor";

const GastosProyecto = (props) => {
  const { proyect } = props;
  const API = peticionesReceiver();

  const [activities, setActivities] = useState("");

  const [modalViewGasto, setModalViewGasto] = useState(false);

  const [modalGasto, setModalGasto] = useState(false);
  const [progresSelected, setProgresSelected] = useState("");
  const [idActivity, setidActivity] = useState("");

  useEffect(() => {
    /* getActivities(); */
    getActivityExpenses();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getActivityExpenses = () => {
    const params = {
      action: "getActivityEvidencesApproved",
      idProyect: proyect.id,
    };
    API.peticionEndPoint(params, "app/facades/planning/planningF.php")
      .then((res) => {
        if (res.data.status === "success" && res.data.code === "200") {
          setActivities(res.data.data);
        } else {
          toast(
            <Notification
              type={"consultar_error"}
              backMessage={res.data.message}
              withIcon
            />
          );
        }
      })
      .catch((err) => {
        toast(<Notification type={"consultar_servidor_error"} withIcon />);
      });
  };

  const refreshMethod = () => {
    getActivityExpenses()
  };

  const addSpent = (values,idActivity) => {
    setProgresSelected(values);
    setidActivity(idActivity)
    setModalGasto(true);
  };
  const showFile = (values) => {
    setProgresSelected(values);
    setModalViewGasto(true);
  };

  return (
    <div>
      {activities !== "" &&
        activities.map((activity, index) => (
          <Row className="mt-3" key={activity.idActivity}>
            <Col xs={12} md={12} lg={12}>
              <CollapseTableColor
                activity={activity}
                proyect={proyect}
                data={activity}
                refreshMethod={refreshMethod}
                methodAddSpent={addSpent}
                methodViewFile={showFile}
              />
            </Col>
          </Row>
        ))}

      {modalGasto && progresSelected !== "" && (
        <ModalAddGasto
          modal={modalGasto}
          setModal={setModalGasto}
          title={"Agregar Gasto"}
          backdrop={"static"}
          keyboard={true}
          idActivity={idActivity}
          progress={progresSelected}
          refreshMethod={refreshMethod}
          btnClose={true}
        />
      )}
      {modalViewGasto && progresSelected !== "" && (
        <ModalViewGasto
          modal={modalViewGasto}
          setModal={setModalViewGasto}
          title={"Gastos"}
          backdrop={"static"}
          keyboard={true}
          idActivity={idActivity}
          progress={progresSelected}
          refreshMethod={refreshMethod}
          btnClose={true}
          
        />
      )}
    </div>
  );
};

export default GastosProyecto;

import { Formik, Form, Field, ErrorMessage, useFormikContext } from "formik";
import * as Yup from "yup";
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import { Col, Row, Button } from "reactstrap";
import { toast } from "react-toastify";
import Notification from "../../../components/Notification/Notification";
import Loader from "../../../components/Loader/Loader";
import ModalConfirmation from "../../../components/Modal/ModalConfirmation";
import { peticionesReceiver } from "../../../helpers/peticionesReceiver";
import { SelectTypeHeadSingle } from "../../../components/GenericInputsFormik/SelectTypeHeadSingle";

const defaultForm = {
  keyCounty: "",
  keyState: "",
  keyDistrict: "",
  nameCounty: "",
  economicKey: "",
};

const FormMunicipio = ({ crear, data, editar }) => {
  const API = peticionesReceiver();
  const history = useHistory();
  const [estados, setEstados] = useState([]);
  const [distritos, setDistritos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [modal, setModal] = useState(false);
  const [form] = useState(defaultForm);

  const FormSchema = Yup.object().shape({
    keyState: Yup.string().required("Seleccione un estado"),
    keyDistrict: Yup.string().required("Seleccione un distrito"),
    nameCounty: Yup.string()
      .min(3, "Nombre debe tener al menos 3 letras")
      .max(25, "El nombre ingresado es muy largo")
      .required("Ingrese un nombre"),
    economicKey: Yup.string()
      .min(3, "La clave debe tener al menos 3 letras")
      .max(25, "La calve ingresada es muy larga")
      .required("Ingrese una clave"),
  });

  const getStates = async () => {
    const params = {
      action: "select",
      table: "states",
      condition: {
        enabled: 1,
      },
      order: "nameState ASC",
    };
    API.peticion(params)
      .then((res) => {
        if (res.status === 200 && res.data.code === "200") {
          setEstados(res.data.data);
        } else {
          toast(<Notification type={"consultar_error"} withIcon />);
        }
        getDistricts();
      })
      .catch((err) => {
        toast(<Notification type={"consultar_servidor_error"} withIcon />);
        setLoading(false);
      });
  };

  const getDistricts = async () => {
    const params = {
      action: "select",
      table: "districts",
      condition: {
        enabled: 1,
      },
      order: "nameDistrict ASC",
    };

    API.peticion(params)
      .then((res) => {
        if (res.status === 200 && res.data.code === "200") {
          setDistritos(res.data.data);
        } else {
          toast(<Notification type={"consultar_error"} withIcon />);
        }
        setLoading(false);
      })
      .catch((err) => {
        toast(<Notification type={"consultar_servidor_error"} withIcon />);
        setLoading(false);
      });
  };

  const submitFunction = (fields) => {
    setModal(true);
  };

  const cancelOp = () => {
    history.push("/template/municipios");
  };

  useEffect(() => {
    getStates();
  }, []);

  const findId = (name, array) => {
    let id = "";
    for (const el of array) {
      if (el[1] === name) {
        id = el[0];
        break;
      }
    }
    return id;
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <Formik
          initialValues={form}
          validationSchema={FormSchema}
          onSubmit={(fields, { resetForm }) => {
            submitFunction(fields);
          }}
          onReset={(fields, { resetForm }) => {
            cancelOp();
          }}
        >
          {({ errors, status, touched, values, setFieldValue, handleBlur }) => (
            <Form className="mt-4">
              <ModalConfirmation
                modalTitle={data ? "Editar" : "Crear"}
                modal={modal}
                setModal={setModal}
                crear={crear}
                editar={editar}
                isEdit={data ? true : false}
                values={values}
              >
                {data ? (
                  <div className="d-flex justify-content-center">
                    <h6>¿Está seguro de editar el registro?</h6>
                  </div>
                ) : (
                  <div className="d-flex justify-content-center">
                    <h6>¿Desea continuar con el registro?</h6>
                  </div>
                )}
              </ModalConfirmation>
              <Row>
                <Col xs="12" md="6">
                  <div className="form-group">
                    <label htmlFor="nameCounty">
                      Nombre<span className="text-danger">*</span>
                    </label>
                    <Field
                      name="nameCounty"
                      type="text"
                      className={
                        "form-control" +
                        (errors.nameCounty && touched.nameCounty
                          ? " is-invalid"
                          : "")
                      }
                    />
                    <ErrorMessage
                      name="nameCounty"
                      component="div"
                      className="invalid-feedback"
                    />
                  </div>
                </Col>
                <Col xs="12" md="6">
                  <div className="form-group">
                    <label htmlFor="economicKey">
                      Clave INEGI<span className="text-danger">*</span>
                    </label>
                    <Field
                      name="economicKey"
                      type="text"
                      className={
                        "form-control" +
                        (errors.economicKey && touched.economicKey
                          ? " is-invalid"
                          : "")
                      }
                    />
                    <ErrorMessage
                      name="economicKey"
                      component="div"
                      className="invalid-feedback"
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col xs="12" md="6">
                  <SelectTypeHeadSingle
                    label="Estado"
                    inputName="keyState"
                    onChangeMethod={(e) =>
                      setFieldValue([e.target.name], e.target.value)
                    }
                    onBlurMethod={handleBlur}
                    value={values.keyState}
                    isRequired={true}
                    touched={touched.keyState}
                    errors={errors.keyState}
                    optionsArray={estados}
                    optionValue="keyState"
                    optionName="nameState"
                    defaultOption="Seleccione un estado"
                  />
                </Col>

                <Col xs="12" md="6">
                  <SelectTypeHeadSingle
                    label="Distrito"
                    inputName="keyDistrict"
                    onChangeMethod={(e) =>
                      setFieldValue([e.target.name], e.target.value)
                    }
                    onBlurMethod={handleBlur}
                    value={values.keyDistrict}
                    isRequired={true}
                    touched={touched.keyDistrict}
                    errors={errors.keyDistrict}
                    optionsArray={distritos}
                    optionValue="keyDistrict"
                    optionName="nameDistrict"
                    defaultOption="Seleccione un distrito"
                  />
                </Col>
              </Row>

              <div className=" d-flex flex-sm-row flex-column-reverse justify-content-sm-around mt-1">
                <Button color="danger" type="reset" className="mt-3">
                  Cancelar
                </Button>
                <Button color="success" type="submit" className="mt-2 mt-sm-3">
                  Guardar
                </Button>
              </div>
              <FormikData info={data} />
            </Form>
          )}
        </Formik>
      )}
    </>
  );
};

const FormikData = ({ info }) => {
  const { setFieldValue } = useFormikContext();
  useEffect(() => {
    if (info) {
      setFieldValue("keyCounty", info[0]);
      setFieldValue("nameCounty", info[1]);
      setFieldValue("economicKey", info[2]);
      setFieldValue("keyState", info[5]);
      setFieldValue("keyDistrict", info[6]);
    }
  }, [info]);
  return null;
};

export default FormMunicipio;

import { useSelector } from "react-redux";
import Widget from "../../../components/Widget/Widget";
import { peticionesReceiver } from "../../../helpers/peticionesReceiver";
import { toast } from "react-toastify";
import Notification from "../../../components/Notification/Notification";
import { useEffect, useState } from "react";

import Datatable from "../../../components/Datatable/Datatable";
import { GetPermissions } from "../../../libs/permissions/getPermissions";
import Loader from "../../../components/Loader/Loader";
import { Button, Spinner } from "reactstrap";
import ModalViewFile from "../../../components/Modal/ModalViewFile";

export default function EstudiosMercado() {
    const API = peticionesReceiver();
    const { id } = useSelector(state => state.userInfo);
    const [idStaff, setIdStaff] = useState(null);
    const [permisos, setPermisos] = useState("")
    const [loading, setLoading] = useState(false);
    const [loadingUser, setLoadingUser] = useState(false);
    const [modalFile, setModalFile] = useState(false);
    const [linkFile, setLinkFile] = useState("");
    GetPermissions(setPermisos, setLoading);

    useEffect(() => {
        getStaffAttent()
    }, [])

    async function getStaffAttent() {
        try {
            const res = await API.peticion({
                action: "multiselect",
                rows: "s.idStaff",
                table: "users u join staff s on(u.idStaff = s.idStaff)",
                conditions: `u.idUser = ${id}`
            });
            if (res.status === 200 && res.data.code === "200") {
                setIdStaff(res.data.data[0].idStaff);
                setLoadingUser(true);
            } else {
                toast(<Notification type={"consultar_error"} backMessage={res.data.message} />);
            }
        } catch (err) {
            toast(<Notification type={"consultar_servidor_error"} withIcon />);
        }
    }

    const getParams = (order, records, page, search) => {
        const params = {
            action: "datatable",
            table: "quotes A inner join status s on (A.keyStat = s.keyStat) left join staff st on (A.idStaffRequire = st.idStaff) LEFT JOIN bookcase D ON A.idQuote = D.reference AND D.keyFileType = 44 LEFT JOIN files E ON D.idBook = E.idBook AND E.enabled = 1",
            rows: "A.numberQuote,A.yearQuote,A.keyStat,s.nameStat,A.object,CONCAT(st.name,' ',st.pName,' ',st.mName) as nameStaff,A.dateSend,A.idQuote,E.src",
            conditions: `A.enabled=1 AND A.idStaffAttend=${idStaff}`,
            order,
            records: records.toString(),
            page: (page * records).toString(),
            search
        }
        return params
    }

    async function getData(order, records, page, search) {
        try {
            const res = await API.peticion(getParams(order, records, page, search));
            if (res.status === 200 && res.data.code === "200") {
                return res.data;
            } else {
                toast(<Notification type={"consultar_error"} backMessage={res.data.message} />);
                return null;
            }
        } catch (err) {
            toast(<Notification type={"consultar_servidor_error"} withIcon />);
            return null
        }
    }

    const headers = [
        "Folio",
        "Estatus",
        "Justificación para la adquisición del bien o contratación del servicio",
        "Solicitante",
        "Fecha de registro",
        "Acciones"
    ];

    const columns = [
        {
            data: null,
            render: function (row) {
                return row.numberQuote && row.yearQuote ? `${row.numberQuote}/${row.yearQuote}` : ""
            },
            orderValue: "numberQuote"
        },
        { data: "nameStat" },
        { data: "object" },
        { data: "nameStaff" },
        { data: "dateSend" },
        {
            data: null,
            render: function (row) {
                return (
                    <>
                        {permisos.UPD && row.src === "" && (
                            <Button color="success" size="sm" className="d-flex align-items-center py-1 px-2 text-nowrap mb-2 w-100" onClick={() => handleCotizar(row.idQuote)}>
                                <i className="fa fa-check-circle mr-2" />Cotizar
                            </Button>
                        )}
                        <Button color="warning" size="sm" className="d-flex align-items-center py-1 px-2 text-nowrap mb-2 w-100" onClick={() => getFile(row.idQuote)}>
                            <i className="fa fa-file-pdf-o mr-2" />Ver solicitud
                        </Button>
                        {row.src !== "" && (
                            <Button color="primary" size="sm" className="d-flex align-items-center py-1 px-2 text-nowrap w-100" onClick={() => setEstudioMercado(row.src)}>
                                <i className="fa fa-file-pdf-o mr-2" />Ver cuadro
                            </Button>
                        )}
                    </>
                )
            }
        }
    ]

    const handleCotizar = (row) => {
        localStorage.setItem("cotestudio", row);
        window.location.href = "#/template/estudiomercado/cotizar"
    }

    const getFile = async (row) => {
        const params = {
            action: "multiselect",
            table: "files f inner join bookcase b on (f.idBook=b.idBook) inner join filetypes ft on (ft.keyFileType=b.keyFileType)",
            rows: "src",
            conditions: `f.enabled = 1 AND b.reference = ${row} AND ft.keyFileType=42`,
        };
        const API = peticionesReceiver()
        try {
            const res = await API.peticion(params)
            if (res.status === 200 && res.data.code === "200") {
                const data = res.data.data;
                if (data.length > 0) {
                    setLinkFile(res.data.data[0].src)
                    setModalFile(true)
                } else {
                    toast(<Notification type={"consultar_error"} withIcon />);
                }
            } else {
                toast(<Notification type={"consultar_error"} backMessage={res.data.message} />);
            }
        } catch (err) {
            toast(<Notification type={"consultar_error"} withIcon />);
        }
    }

    function setEstudioMercado(src) {
        setLinkFile(src)
        setModalFile(true)
    }

    const columnDefs = [
        { targets: [5], className: "text-center", orderable: false }
    ]

    if (loading || loadingUser) {
        <div className="d-flex align-items-center justify-content-center" style={{ height: "200px" }}>
            <Spinner
                color="primary"
                style={{
                    height: '3rem',
                    width: '3rem'
                }}
                type="grow"
            >
                Loading...
            </Spinner>
        </div>
    }
    if (permisos.CON) {
        return (
            <>
                <Widget>
                    <h2 className="text-secondary p-3">Estudios de mercado</h2>
                    {loading ? (
                        <div style={{ height: "200px" }}>
                            <Loader className="mx-auto my-auto" />
                        </div>
                    ) : (
                        <>
                            {permisos.CON && idStaff !== null ? (
                                <Datatable
                                    headers={headers}
                                    columns={columns}
                                    columnDefs={columnDefs}
                                    petition={getData}
                                    control="back"
                                    order={{ col: 0, opt: "desc" }}
                                />
                            ) : (<div className="text-center p-4">No tienes permitido ver los registros de esta seccion</div>)}
                        </>
                    )}

                </Widget>
                {linkFile ? (
                    <ModalViewFile
                        modal={modalFile}
                        setModal={setModalFile}
                        backdrop={true}
                        keyboard={true}
                        btnClose={true}
                        fileLink={linkFile}
                    />
                ) : null}
            </>
        )
    } else {
        return (
            <>
                <div className="text-center p-4">
                    No tienes permitido ver los registros de esta seccion
                </div>
            </>
        )
    }
}
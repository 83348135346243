import * as XlsxPopulate from "xlsx-populate";
import { saveAs } from "file-saver";
import getLayout from "../../../../components/excel/layoutExcel";

const headers = [
  "NÚMERO EMPLEADO",
  "NOMBRE EMPLEADO",
  "ID PENSIÓN",
  "NOMBRE BENEFICIARIO",
  "CUENTA/CLABE",
  "ID BANCO",
  "BANCO",
  "IMPORTE",
];
const fmtMny = new Intl.NumberFormat("es-MX", {
  style: "currency",
  currency: "MXN",
});


const s = {
  tC: { horizontalAlignment: "center", verticalAlignment: "center" },
  tS: { horizontalAlignment: "left" },
  tE: { horizontalAlignment: "right", verticalAlignment: "center" },
  tt: {
    horizontalAlignment: "center",
    verticalAlignment: "bottom",
    wrapText: true,
    shrinkToFit: true,
    bold: true,
  },
  tN: {
    border: true,
    wrapText: true,
    shrinkToFit: true,
    verticalAlignment: "center",
  },
  tNC: {
    horizontalAlignment: "center",
    verticalAlignment: "center",
    wrapText: true,
    shrinkToFit: true,
    border: true,
  },
  tNE: {
    horizontalAlignment: "right",
    verticalAlignment: "center",
    wrapText: true,
    shrinkToFit: true,
    border: true,
  },
  header: {
    horizontalAlignment: "center",
    bold: true,
    verticalAlignment: "center",
    fill: "730A29",
    fontColor: "FFFFFF",
    wrapText: true,
    shrinkToFit: true,
    border: true,
  },
  tB: { bold: true },
  wt: { wrapText: true, verticalAlignment: "center" },
};

export async function pensionesBancos(data, config) {
  const excel = await getLayout();
  const cfg = config.cfg;
  if (excel)
    XlsxPopulate.fromDataAsync(excel).then(async (workbook) => {
      const hoja = workbook.sheet(0);
      let row = 4;
      hoja
        .range(`A${row}:H${row}`)
        .merged(true)
        .value("PENSIONES BANCOS")
        .style(s.tt);
      row++;
      if(cfg.bank !== ""){
        hoja
        .range(`A${row}:H${row}`)
        .merged(true)
        .value(cfg.bank)
        .style(s.tt);
        row++;
      }
      hoja
        .range(`A${row}:H${row}`)
        .merged(true)
        .value(`del Periodo ${cfg.period} del Año ${cfg.year}`)
        .style(s.tt);
      row += 2;

      for (let i = 0; i < headers.length; i++) {
        hoja.column(i + 1).width([2, 4].includes(i+1)? 40 : 25);
        hoja.row(row).cell(i+1).value(headers[i]).style(s.header);        
      }

      data.forEach((d, ix) => {
        row++;
        hoja.row(row).cell(1).value(d.staffNumber).style(s.tNC);
        hoja.row(row).cell(2).value(d.nombreEmpleado).style(s.tN);
        hoja.row(row).cell(3).value(d.idPension).style(s.tNC);
        hoja.row(row).cell(4).value(d.nombreBeneficiario).style(s.tN);
        hoja.row(row).cell(5).value(d.account).style(s.tNC);
        hoja.row(row).cell(6).value(d.keyBank).style(s.tNC);
        hoja.row(row).cell(7).value(d.nameBank).style(s.tN);
        hoja.row(row).cell(8).value(fmtMny.format(d.amount)).style(s.tNE);
      });

      

      return workbook.outputAsync().then((res) => {
        saveAs(
          res,
          `Pensiones Bancos ${cfg.period}-${cfg.year}.xlsx`
        );
      });
    });
}

import { useState } from "react";
import Datatable from "../../../../components/Datatable/Datatable";
import { doRequestSaveRes } from "../../../../helpers/requests";
import { getStaffPic } from "../../../../libs/tools/images";

const headers = ["No. Empleado", "Nombre Empleado", "Adscripción", "Puesto laboral", "Motivo Baja", "Estatus Finiquito", "Ver"];

const columnDefs = [
    {targets: [6], orderable: false},
    {targets: [0,4,5], className: "text-center"}
]

export default function Consulta({setFiniquito, idArea}){
    const [refresh, setRefresh] = useState(false);

    const columns = [
        {data: "staffNumber"},
        {data: "fullName"},
        {data: "nameArea"},
        {data: "nameJob"},
        {data: "statStaff"},
        {data: "statSettlement"},
        {data: null, render: (row)=><i className="fa fa-eye text-info cursor-pointer" onClick={()=>selectFiniquito(row)}/>}
    ]

    async function getData(ordCol, itemsPerPage, currentPage, search, multi) {
        const res = await doRequestSaveRes(
            "receiver/receiver.php",
            getParams(ordCol, itemsPerPage, currentPage, search, multi),
            false,
            false
        );
        if (res.code === "200") return res;
        return null;
    }

    function getParams(ordCol, itemsPerPage, currentPage, search) {
        const params = {
            action: "datatable",
            table: "settlements A INNER JOIN staffview B USING (idStaff) INNER JOIN status F ON F.keyStat = B.keyStat INNER JOIN status G ON G.keyStat = A.keyStat LEFT JOIN bookcase H ON H.reference = B.idStaff AND H.keyFileType = 1  LEFT JOIN files I ON I.idBook = H.idBook AND I.enabled = 1 INNER JOIN settlementsdocs J USING (idSettlement) INNER JOIN settlementareas K USING (idSettlementArea) INNER JOIN areas L ON L.idArea = K.idArea",
            rows: "B.staffNumber, CONCAT_WS(' ', B.name, B.pName, B.mName) AS fullName, B.nameArea, B.nameJob, F.nameStat AS statStaff, G.nameStat AS statSettlement, B.keyGenre, B.nameEmployType, A.idSettlement, I.src, A.idStaff, B.nameBuilding, B.idJobStaff, J.idDocument",
            conditions: `A.enabled = 1 AND A.keyStat = 155 AND J.enabled = 1 AND L.idArea = ${idArea}`,
            order: ordCol,
            records: itemsPerPage.toString(),
            page: (itemsPerPage * currentPage).toString(),
            search,
            group: "A.idSettlement",
        };
        return params;
    }

    function selectFiniquito(row) {
        setFiniquito({
            id: row.idSettlement,
            idStaff: row.idStaff,
            src: getStaffPic(row.keyGenre, row.src),
            fullName: row.fullName,
            employType: row.nameEmployType,
            staffNumber: row.staffNumber,
            nameArea: row.nameArea,
            nameJob: row.nameJob,
            nameStat: row.statStaff,
            nameBuilding: row.nameBuilding,
            idJobStaff: row.idJobStaff,
            idDocument: row.idDocument
        });
    }

    return(
        <div>
            <Datatable
                headers={headers}
                columns={columns}
                columnDefs={columnDefs}
                petition={getData}
                control="back"
                stateRefresh={[refresh, setRefresh]}
                className="px-0 mt-4"
            />
        </div>
    )
}
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Button, Alert } from "reactstrap";
import { Formik, Form, Field, ErrorMessage, useFormikContext } from "formik";
import * as Yup from "yup";
import ModalConfirmation from "../../../components/Modal/ModalConfirmation";
import { toast } from "react-toastify";
import Loader from "../../../components/Loader/Loader";
import Notification from "../../../components/Notification/Notification";
import { peticionesReceiver } from "../../../helpers/peticionesReceiver";

const defaultForm = {
  keyCareerType: "",
  nameCareerType: "",
  careerTitle: "",
  keySchoolarityType:"",
};

const FormCarreras = ({ crear, data, editar }) => {
  const API = peticionesReceiver();
  const history = useHistory();
  const [modal, setModal] = useState(false);
  const [form] = useState(defaultForm);
  const [schoolarityTypes, setSchoolarityTypes] = useState();
  const [loadingSchoolarityTypes, setLoadingSchoolarityTypes] = useState(true);
  const FormSchema = Yup.object().shape({
    nameCareerType: Yup.string()
      .min(3, "Nombre de la carrrera debe contener al menos 3 letras")
      .required("Ingrese el nombre de la carrera"),
    careerTitle: Yup.string()
      .min(2, "Nombre del titulo debe contener al menos 2 letras")
      .required("Ingrese el nombre del titulo"),
    keySchoolarityType: Yup.string()
      .required("Ingrese un nivel academico"),
  });

  const submitFunction = (fields) => {
    setModal(true);
  };

  const getSchoolarityTypes = async () => {
    const data = {
      action: "select",
      table: "schoolaritytypes",
      order: "nameSchoolarityType ASC"
    };
    API.peticion(data)
      .then((res) => {
        if (res.status === 200 && res.data.status === "success") {
          const datos = [];
          res.data.data.map((element) => {
            datos.push([
              element["keySchoolarityType"],
              element["nameSchoolarityType"],
            ]);
          });
          setSchoolarityTypes(datos);
          setLoadingSchoolarityTypes(false);
          console.log(res.data.data);
        } else {
          toast(
            <Alert color="danger">Error al consultar nivel de escolaridad</Alert>
          );
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getSchoolarityTypes()
  }, []);

  const cancelOp = () => {
    history.push("/template/carreras");
  };

  return (
    <div>
      {!loadingSchoolarityTypes ? (
        <Formik
          initialValues={form}
          validationSchema={FormSchema}
          onSubmit={(fields) => {
            submitFunction(fields);
          }}
          onReset={(fields, { resetForm }) => {
            cancelOp();
          }}
        >
          {({ errors, status, touched, values }) => (
            <Form className="mt-4">
              <ModalConfirmation
                modalTitle={data ? "Editar" : "Crear"}
                modal={modal}
                setModal={setModal}
                crear={crear}
                editar={editar}
                isEdit={data ? true : false}
                values={values}
              >
                {data ? (
                  <div className="d-flex justify-content-center">
                    <h6>¿Está seguro de editar el registro?</h6>
                  </div>
                ) : (
                  <div className="d-flex justify-content-center">
                    <h6>¿Desea continuar con el registro?</h6>
                  </div>
                )}
              </ModalConfirmation>

              <div className="form-group">
                <label htmlFor="keySchoolarityType">
                  Nivel academico
                </label>
                <Field name="keySchoolarityType">
                  {({ field }) => (
                    <select {...field} 
                    className={
                      "form-control" +
                      (errors.keySchoolarityType && touched.keySchoolarityType ? " is-invalid" : "")
                    }
                    >
                      <option key={0} value={""}>
                        {"Seleccione un nivel academico"}
                      </option>
                      {schoolarityTypes.map((e) => (
                        <option key={e[0]} value={e[0]}>
                          {e[1]}
                        </option>
                      ))}
                    </select>
                  )}
                </Field>
                {touched.keySchoolarityType && errors.keySchoolarityType && (
                  <span style={{ color: "red" }}>{errors.keySchoolarityType}</span>
                )}
              </div>

              <div className="form-group">
                <label htmlFor="nameCareerType">
                  Nombre de la carrera<span className="text-danger">*</span>
                </label>
                <Field
                  name="nameCareerType"
                  type="text"
                  className={
                    "form-control" +
                    (errors.nameCareerType && touched.nameCareerType
                      ? " is-invalid"
                      : "")
                  }
                />
                <ErrorMessage
                  name="nameCareerType"
                  component="div"
                  className="invalid-feedback"
                />
              </div>
              <div className="form-group">
                <label htmlFor="careerTitle">
                  Nombre del titulo<span className="text-danger">*</span>
                </label>
                <Field
                  name="careerTitle"
                  type="text"
                  className={
                    "form-control" +
                    (errors.careerTitle && touched.careerTitle
                      ? " is-invalid"
                      : "")
                  }
                />
                <ErrorMessage
                  name="careerTitle"
                  component="div"
                  className="invalid-feedback"
                />
              </div>

              <div className="d-flex justify-content-between mt-5">
                <Button color="danger" type="reset">
                  Cancelar
                </Button>
                <Button color="success" type="submit">
                  Guardar
                </Button>
              </div>
              <FormikData info={data} />
            </Form>
          )}
        </Formik>
      ) : (
        <Loader />
      )}
    </div>
  );
};

const FormikData = ({ info }) => {
  const { setFieldValue } = useFormikContext();
  useEffect(() => {
    console.log(info);
    if (info) {
      setFieldValue("keyCareerType", info[0]);
      setFieldValue("nameCareerType", info[1]);
      setFieldValue("careerTitle", info[2]);
      setFieldValue("keySchoolarityType", info[4]);
    }
  }, [info]);
  return null;
};

export default FormCarreras;

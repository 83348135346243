import React, { useEffect, useState } from "react";
import axios from "axios";
import * as Yup from "yup";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { Button, Col, Row } from "reactstrap";
import { useHistory } from "react-router-dom";

import { peticionEncript } from "../../../helpers/peticionesEncripted";
import { peticionesReceiver } from "../../../helpers/peticionesReceiver";

import Notification from "../../../components/Notification/Notification";
import Loader from "../../../components/Loader/Loader";
import ModalConfirmation from "../../../components/Modal/ModalConfirmation";
import { SelectTypeHeadSingle } from "../../../components/GenericInputsFormik";

const PresupuestosAgregarDependientes = () => {
  const API = peticionesReceiver();
  const history = useHistory();
  const [modal, setModal] = useState(false);
  const [formDatos, setFormDatos] = useState({
    idAnnualBudget:
      localStorage.getItem("idDetail") !== undefined
        ? localStorage.getItem("idDetail")
        : "",
    idFinancialSource: "",
    idExpenseObject: "",
    idStateFund: "",
  });

  const [searchFilters, setSearchFilters] = useState({
    financialSources: "",
    expenseObjects: "",
    stateFunds: "",
  });

  const [loading, setLoading] = useState(true);

  const getFinancialsSources = async () => {
    const params = {
      action: "select",
      table: "financialsources",
      condition: {
        enabled: 1,
      },
      order: "nameFinancialSource ASC",
    };
    API.peticion(params)
      .then((res) => {
        if (res.status === 200 && res.data.code === "200") {
          setSearchFilters((prevState) => ({
            ...prevState,
            financialSources: res.data.data,
          }));
        } else {
          toast(<Notification type={"consultar_error"} withIcon />);
        }
      })
      .catch((err) => {
        toast(<Notification type={"consultar_servidor_error"} withIcon />);
      });
  };

  const getStatesFunds = async () => {
    const params = {
      action: "select",
      table: "statefunds",
      condition: {
        enabled: 1,
      },
      order: "nameStateFund ASC",
    };
    API.peticion(params)
      .then((res) => {
        if (res.status === 200 && res.data.code === "200") {
          setSearchFilters((prevState) => ({
            ...prevState,
            stateFunds: res.data.data,
          }));
        } else {
          toast(<Notification type={"consultar_error"} withIcon />);
        }
      })
      .catch((err) => {
        toast(<Notification type={"consultar_servidor_error"} withIcon />);
      });
  };

  const getExpensesObjects = async () => {
    const params = {
      action: "select",
      table: "expense_objects",
      condition: {
        enabled: 1,
      },
      order: "nameObject ASC",
    };
    API.peticion(params)
      .then((res) => {
        if (res.status === 200 && res.data.code === "200") {
          setSearchFilters((prevState) => ({
            ...prevState,
            expenseObjects: res.data.data,
          }));
          if (formDatos.idAnnualBudget !== "") {
            setLoading(false);
          } else {
            history.push({
              pathname: "/template/presupuestos",
            });
          }
        } else {
          toast(<Notification type={"consultar_error"} withIcon />);
        }
      })
      .catch((err) => {
        toast(<Notification type={"consultar_servidor_error"} withIcon />);
      });
  };

  const OnChangeSelect = event => {
    const { name,value } = event.target
    setFormDatos((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    setFieldValue(name, value);
  };

  useEffect(() => {
    getFinancialsSources().finally(() => {
      getStatesFunds().finally(() => {
        getExpensesObjects();
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  const FormSchema = Yup.object().shape({
    idStateFund: Yup.string().required("Seleccione un proyecto presupuestal"),
    idFinancialSource: Yup.string().required("Seleccione una fuente de financiamiento"),
    idExpenseObject: Yup.string().required("Seleccione una partida")
  });

  const enviaDatos = () => {
    
    setModal(true);
  };

  const crear = async () => {
    const params = {
      action: "saveBudget",
      idAnnualBudget: formDatos.idAnnualBudget,
      idFinancialSource: formDatos.idFinancialSource,
      idExpenseObject: formDatos.idExpenseObject,
      idStateFund: formDatos.idStateFund,
    }; 
    const finalData = peticionEncript(params);
    await axios
      .post(
        `${process.env.REACT_APP_API}app/facades/budgets/budgetsF.php`,
        finalData
      )
      .then((res) => {
        if (res.status === 200 && res.data.code === "200") {
          localStorage.setItem("idDetailMonths", res.data.data[0].idBudget);
          history.push({
            pathname: "/template/presupuestos/presupuestos-dependientes/detalles",
        });
        } else {
          toast(
            <Notification
              type={"consultar_servidor_error"}
              backMessage={res.data.message}
              withIcon
            />
          );
        }
      })
      .catch((err) => {
        toast(<Notification type={"consultar_servidor_error"} withIcon />);
      });
  }

  const goBack = () =>{
    resetForm()
    history.push({
        pathname: "/template/presupuestos/presupuestos-dependientes",
    });
  }

  const { handleSubmit, values, handleBlur, errors, touched, setFieldValue, resetForm } =
    useFormik({
      initialValues: formDatos,
      onSubmit: () => {
        enviaDatos();
      },
      validationSchema: FormSchema,
      enableReinitialize: true,
    });

  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <form onSubmit={handleSubmit} className="mt-4" noValidate>
            <ModalConfirmation
              modalTitle={"Crear"}
              modal={modal}
              setModal={setModal}
              crear={crear}
              isEdit={false}
              values={values}
            >
              <div className="d-flex justify-content-center">
                <h6>¿Desea continuar con el registro?</h6>
              </div>
            </ModalConfirmation>
          <Row>
            <Col xs={12} md={6} lg={4}>
              <SelectTypeHeadSingle
                label="Proyecto Presupuestal"
                isRequired={true}
                inputName="idStateFund"
                optionsArray={searchFilters.stateFunds}
                defaultOption="Seleccione un proyecto presupuestal"
                onChangeMethod={OnChangeSelect}
                onBlur={handleBlur}
                isDisabled={false}
                value={values.idStateFund}
                touched={touched.idStateFund}
                errors={errors.idStateFund}
                multipleLabels={["stateFundKey","nameStateFund"]}
                optionValue="idStateFund"
                optionName="name"
              />
            </Col>
            <Col xs={12} md={6} lg={4}>
              <SelectTypeHeadSingle
                label="Fuente de financiamiento"
                isRequired={true}
                inputName="idFinancialSource"
                optionsArray={searchFilters.financialSources}
                defaultOption="Seleccione una fuente de financiamiento"
                onChangeMethod={OnChangeSelect}
                onBlur={handleBlur}
                isDisabled={false}
                value={values.idFinancialSource}
                touched={touched.idFinancialSource}
                errors={errors.idFinancialSource}
                multipleLabels={["financialSourceKey","nameFinancialSource"]}
                optionValue="idFinancialSource"
                optionName="name"
              />
            </Col>
            <Col xs={12} md={6} lg={4}>
              <SelectTypeHeadSingle
                label="Partida"
                isRequired={true}
                inputName="idExpenseObject"
                optionsArray={searchFilters.expenseObjects}
                defaultOption="Seleccione una partida"
                onChangeMethod={OnChangeSelect}
                onBlur={handleBlur}
                isDisabled={false}
                value={values.idExpenseObject}
                touched={touched.idExpenseObject}
                errors={errors.idExpenseObject}
                multipleLabels={["fullKey","nameObject"]}
                optionValue="idExpenseObject"
                optionName="name"
              />
            </Col>
          </Row>
          <div className="row mt-3">
            <div className="col-sm-6 order-2 order-sm-1 text-center text-sm-left mb-4">
              <Button color="danger" type="reset" onClick={() => goBack()} > Cancelar </Button>
            </div>

            <div className="col-sm-6 order-1 order-sm-2 text-center text-sm-right mb-4">
              <Button color="success" type="submit"> Guardar </Button>
            </div>
            
          </div>
        </form>
      )}
    </div>
  );
};
export default PresupuestosAgregarDependientes;

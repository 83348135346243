import {
  Document,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
import HeaderReport from "../../../../components/Pdf/Header";
import { fMoney } from "../../../../libs/tools/format";

const fmtD = new Intl.DateTimeFormat("es-MX", {
  day: "2-digit",
  month: "2-digit",
  year: "numeric",
  timeZone: "UTC",
});

const s = StyleSheet.create({
  body: {
    paddingVertical: "30px",
    paddingHorizontal: "20px",
  },

  title: {
    fontSize: "12px",
    textAlign: "center",
    fontFamily: "Helvetica-Bold",
  },

  header: {
    backgroundColor: "#730A29",
    textAlign: "center",
    color: "#FFFFFF",
  },

  mCell: { padding: "2px" },
  mxCell: { paddingHorizontal: "2px" },

  vCenter: { alignItems: "center", justifyContent: "center" },
  vCenterL: { alignItems: "left", justifyContent: "center" },
  vCenterR: { alignItems: "right", justifyContent: "center" },
  vCenterBtw: { alignItems: "stretch", justifyContent: "center" },

  dflexBtw: { display: "flex", justifyContent: "space-between" },
  dflexEnd: { display: "flex", justifyContent: "flex-end" },

  alignE: { alignContent: "flex-end" },

  row: { flexDirection: "row" },
  flexWrap: { display: "flex", flexWrap: "wrap", flexDirection: "row" },

  w5: { width: "5%" },
  w10: { width: "10%" },
  w15: { width: "15%" },
  w20: { width: "20%" },
  w25: { width: "25%" },
  w30: { width: "30%" },
  w33: { width: "33%" },
  w35: { width: "35%" },
  w40: { width: "40%" },
  w45: { width: "45%" },
  w50: { width: "50%" },
  w55: { width: "55%" },
  w60: { width: "60%" },
  w65: { width: "65%" },
  w70: { width: "70%" },
  w75: { width: "75%" },
  w80: { width: "80%" },
  w90: { width: "90%" },
  w100: { width: "100%" },
  wAuto: { width: "auto" },

  border: { border: "1px solid #000", borderBottom: "0px" },
  borderT: { borderBottom: "1px solid #000" },
  borderB: { borderBottom: "1px solid #000" },
  borderB2: { borderBottom: "2px solid #000" },
  borderE: { borderRight: "1px solid #000" },
  borderS: { borderLeft: "1px solid #000" },
  borderBS: { borderBottom: "4px solid #730A29" },
  borderHE: { borderRight: "1px solid #FFFFFF" },

  bg: { backgroundColor: "#BEBEBE" },
  bg2: { backgroundColor: "#BEBEBE" },

  fs7: { fontSize: "7px" },
  fs8: { fontSize: "8px" },
  fs9: { fontSize: "9px" },
  fs10: { fontSize: "10px" },
  fs11: { fontSize: "11px" },
  fs12: { fontSize: "12px" },
  fs13: { fontSize: "13px" },

  tend: { textAlign: "right", paddingRight: "2px" },
  tstart: { textAlign: "left" },
  tcenter: { textAlign: "center" },
  tJustify: { textAlign: "justify" },
  tBold: { fontFamily: "Helvetica-Bold" },

  mT5: { marginTop: "5px" },
  mT8: { marginTop: "8px" },
  mT10: { marginTop: "10px" },
  mT20: { marginTop: "20px" },
  mT40: { marginTop: "40px" },
  mx18: { marginHorizontal: "18px" },
  mx20: { marginHorizontal: "20px" },
  mL: { marginLeft: "auto" },

  spaceFooter: {
    bottom: 10,
    left: 0,
    right: 0,
    width: "auto",
    height: 30,
    marginHorizontal: 10,
  },

  footer: {
    position: "absolute",
    bottom: 10,
    left: 0,
    right: 0,
    width: "auto",
    height: 30,
    marginHorizontal: 10,
    borderTopWidth: 1,
    flexDirection: "row",
    alignContent: "center",
    alignItems: "center",
  },
  textFooter: {
    width: "100%",
    textAlign: "right",
    fontSize: 8,
  },
});

export default function solicitudCotizacion(info) {
  
  return (
    <Document>
      <Page style={[s.body, s.fs11]}>
        <HeaderReport />
        <View style={[s.title, s.tcenter, { marginTop: 7 }]}>
          <Text>SOLICITUD PARA ADQUISICIÓN DE BIENES Y CONTRATACIÓN DE</Text>
          <Text>SERVICIOS Y TRÁMITE DE LIMITE PRESUPUESTAL</Text>
        </View>

        <View style={[s.w25, s.mL, { marginTop: "4px" }]}>
          <View style={[s.border]}>
            <View style={[s.row, s.borderB]}>
              <View style={[s.w50, s.borderE, s.tBold]}>
                <Text style={s.mCell}>Folio:</Text>
              </View>
              <View style={[s.w50]}>
                <Text style={s.mCell}>{info.cotizacion.folio}</Text>
              </View>
            </View>
            <View style={[s.row, s.borderB]}>
              <View style={[s.w50, s.borderE, s.tBold]}>
                <Text style={s.mCell}>Fecha:</Text>
              </View>
              <View style={[s.w50]}>
                <Text style={s.mCell}>{fmtD.format(new Date())}</Text>
              </View>
            </View>
          </View>
        </View>

        <View>
          <View style={[s.tBold]}>
            <Text>DIRECCIÓN DE RECURSOS MATERIALES Y </Text>
            <Text>SERVICIOS PRESENTE</Text>
          </View>
          <View style={[s.tJustify, s.mT8]}>
            <Text>
              Por medio del presente se solicita llevar a cabo la adquisición
              del (os) bien (es) y/o contratación del (os) servicio (s), así
              como la cotización para el tramite del límite presupuestal, cuyas
              especificaciones y cantidades a continuación se refieren:
            </Text>
          </View>
        </View>

        <View style={[s.border, s.mT10, s.fs9, s.tcenter]} fixed>
          <View style={[s.row, s.borderB, s.tBold]} fixed>
            <View style={[s.w10, s.vCenter, s.borderE]}>
              <Text style={s.mCell}>No. de{"\n"}Partida</Text>
            </View>
            <View style={[s.w40, s.vCenter, s.borderE]}>
              <Text style={s.mCell}>Descripción del Bien o Servicio</Text>
            </View>
            <View style={[s.w10, s.vCenter, s.borderE]}>
              <Text style={s.mCell}>Unidad de Medida</Text>
            </View>
            <View style={[s.w10, s.vCenter, s.borderE]}>
              <Text style={s.mCell}>Cantidad Solicitada</Text>
            </View>
            <View style={[s.w30, s.vCenter, s.tJustify]}>
              <Text style={s.mCell}>
                Importe para Solicitud de Suficiencia Presupuestal (Para ser
                requisitado exclusivo por la Subdirección de Adquisiciones)
              </Text>
            </View>
          </View>
          {info.productos.length > 0 &&
            info.productos.map((p, ix) => (
              <View style={[s.row, s.borderB, s.fs10]} key={"rp" + ix}>
                <View style={[s.w10, s.vCenter, s.borderE]}>
                  <Text>{ix+1}</Text>
                </View>
                <View style={[s.w40, s.vCenterL, s.tstart, s.borderE]}>
                  <Text style={s.mCell}>{p.descripcion}</Text>
                </View>
                <View style={[s.w10, s.vCenter, s.borderE]}>
                  <Text>{p.nameUnit}</Text>
                </View>
                <View style={[s.w10, s.vCenter, s.borderE]}>
                  <Text>{p.amount}</Text>
                </View>
                <View style={[s.w30, s.vCenter]}>
                  <Text>{fMoney(p.total)}</Text>
                </View>
              </View>
            ))}
          <View style={[s.row, s.borderB, s.tBold]}>
            <View style={[s.w60, s.borderE, s.tcenter]}>
              <Text>Total</Text>
            </View>
            <View style={[s.w30]}>
              <Text>{fMoney(info.cotizacion.total)}</Text>
            </View>
          </View>
          <View style={[s.tcenter, s.borderB, s.tBold]}>
            <Text style={s.mCell}>Cantidad con letra: {info.numbertext}</Text>
          </View>
        </View>

        <View style={{ marginVertical: "10px" }}>
          <Text style={s.tJustify}>
            <Text style={s.tBold}>Nota: </Text>El importe correspondiente
            incluye el Impuesto al Valor Agregado
          </Text>
        </View>

        <View style={[s.border, s.borderB, s.mCell]} wrap={false}>
          <Text style={[s.tBold]}>
            Justificación para la adquisición del bien o contratación del
            servicio
          </Text>
          <Text style={[s.tJustify, s.mT8]}>{info.cotizacion.object}</Text>
        </View>

        <Text style={[s.tJustify, s.mT8, { marginBottom: 15 }]} wrap={false}>
          Se menciona que la solicitud de suficiencia presupuestal debidamente
          dictaminada, será remitida en cuanto sea autorizada por la Dirección
          de Finanzas; sabedor de que el procedimiento adquisitivo dará inicio
          hasta en tanto se cuente con la misma en la Dirección de Recursos
          Materiales y Servicios, de conformidad con lo establecido en los
          artículos 15 y 16 del Reglamento de la Ley de Contratación Pública del
          Estado de México y Municipios.
        </Text>

        <View
          style={[
            s.border,
            s.borderB,
            s.row,
            s.fs7,
            { height: "130px" },
            s.tcenter,
          ]}
          wrap={false}
        >
          <View style={[s.w33, s.borderE, s.mCell]}>
            <Text style={s.mT5}>{info.cotizacion.fullNameSol}</Text>
            <Text style={[s.mT5, s.tBold]}>UNIDAD DE PROMOCIÓN Y ASISTENCIA SOCIAL</Text>
          </View>
          <View style={[s.w33, s.borderE, s.mCell]}>
            <Text style={s.mT5}>{info.cotizacion.fullNameEnc}</Text>
            <Text style={[s.mT5, s.tBold]}>
              TITULAR DE LA SUBDIRECCIÓN DE DIRECCIÓN DE ADQUISICIONES
            </Text>
          </View>
          <View style={[s.w33, s.mCell]}>
            <Text style={s.mT5}>{info.cotizacion.fullNameAut}</Text>
            <Text style={[s.mT5, s.tBold]}>
              DIRECCIÓN DE RECURSOS MATERIALES Y{"\n"}SERVICIOS
            </Text>
          </View>
        </View>

        <View style={s.mT10}>
          <Text style={s.tJustify}>
            <Text style={s.tBold}>Nota: </Text>En caso de modificación y/o
            cancelación del presente documento, se deberá emitir un comunicado
            signado por el titular de la unidad administrativa requirente
          </Text>
        </View>

        <View fixed style={s.spaceFooter}></View>
        <View fixed style={s.footer}>
          <Text
            style={s.textFooter}
            render={({ pageNumber, totalPages }) =>
              `PAGINA : ${pageNumber} / ${totalPages}`
            }
          />
        </View>
      </Page>
    </Document>
  );
}

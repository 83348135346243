import { useEffect, useState } from "react";
import { doRequest, doRequestSaveRes } from "../../../helpers/requests";
import FullFormLoader from "../../../components/Loader/FullFormLoader";
import { Button, Col, Row, Table } from "reactstrap";
import { fDate, fDatewhitTime, fMoney } from "../../../libs/tools/format";
import ModalConfirmation from "../../../components/Modal/ModalConfirmation";
import { TextAreaInput } from "../../../components/GenericInputsFormik";
import Notification from "../../../components/Notification/Notification";
import ModalReports from "../../../components/Modal/ModalReports";
import pdfSolicitud from "../dictamen-presupuestal/reports/pdfSolicitud";
import Datatable from "../../../components/Datatable/Datatable";


const mNames = [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre"
]

const headers = [
    "Proyecto",
    "COG",
    "Denominación",
    "Descripción",
    "Importe"
];

const columns = [
    {data: "prPres", render: (d)=>{return <div  style={{maxWidth: "400px"}}>{d}</div>}},
    {data: "COG"},
    {data: "nameObject"},
    {data: "concept"},
    {data: "acumP", format: "currency"}
]

const columnDefs = [
    {
        orderable: true,
        targets: [1],
        className: "text-center",
    },
    {
        orderable: true,
        targets: [4],
        className: "text-right",
    },
];



export default function Aprobar({ solicitud, user, permissions }) {
    const [loading, setLoading] = useState(true);
    const [calend, setCalend] = useState([]);
    const [proyP, setProyP] = useState([]);
    const [total, setTotal] = useState(0);
    const [notes, setNotes] = useState("");
    const [validNotes, setValidNotes] = useState(false);
    const [showButtons, setShowButtons] = useState(true);
    const [modalDenegar, setModalDenegar] = useState(false);
    const [dataDenegar, setDataDenegar] = useState({ title: "", stat: "", case: "" });
    const [modalReport, setModalReport] = useState(false);
    const [refresh, setRefresh] = useState(false);

    useEffect(() => {
        getMonths();
        getProy();
        setShowButtons(+solicitud.estatus === 118);
        setNotes(solicitud.notas);
    }, []);

    async function getMonths() {
        setLoading(true);
        const params = {
            action: "multiselect",
            table:
                "budgetrequestdetail A INNER JOIN budgetrequestschedule B USING(idDetail) INNER JOIN months C USING (keyMonth)",
            rows: "C.nameMonth, SUM(IF(A.keyStat = 121, B.required, IF(A.keyStat = 122,B.approved, 0))) AS acum, C.keyMonth",
            conditions: `A.idBudgetRequest = ${solicitud.id} AND A.enabled = 1 AND B.enabled = 1`, //  AND A.keyStat != 123
            order: "C.keyMonth",
            group: "C.keyMonth",
        };
        const res = await doRequest("receiver/receiver.php", params, false, false);
        if (res.length > 0) {
            let tt = 0;
            const data = mNames.map((el, ix) => {
                const fnd = res.find((mb) => +mb.keyMonth === ix+1);
                const nMes = { nameMonth: el, acum: 0 };
                if (fnd) {
                    tt += +fnd.acum;
                    nMes.acum = +fnd.acum;
                }
                return nMes;
            });

            setCalend(data);
            setTotal(tt);
        }
        setLoading(false);
    }

    async function getProy() {
        const params = {
            action: "multiselect",
            table: "budgetrequestdetail A INNER JOIN statefunds B USING (idStateFund) INNER JOIN expense_objects C USING (idExpenseObject) INNER JOIN budgetrequestschedule G USING (idDetail) INNER JOIN status H USING (keyStat) INNER JOIN budgetrequests I USING (idBudgetRequest) LEFT JOIN staff SS ON SS.idStaff = I.idStaffRequest LEFT JOIN staff SR ON SR.idStaff = I.idStaffRevision LEFT JOIN staff SA ON SA.idStaff = I.idStaffAproves",
            rows: "A.idStateFund, CONCAT(B.stateFundKey,' - ',B.nameStateFund) AS prPres, C.fullKey as COG, GROUP_CONCAT(G.required) AS rMonth, C.nameObject, SUM(G.required) AS acum, H.nameStat, A.idDetail,A.keyStat,A.concept, CONCAT(SS.name,' ',SS.pName,' ',SS.mName) AS solicita, CONCAT(SR.name,' ',SR.pName,' ',SR.mName) AS revisa, CONCAT(SA.name,' ',SA.pName,' ',SA.mName) AS aprueba, A.validationDate,A.notes",
            conditions: `A.idBudgetRequest = ${solicitud.id} AND A.enabled = 1 AND G.enabled = 1`,
            order: "prPres",
            group: "C.idExpenseObject, B.idStateFund",
        };
        const res = await doRequest("receiver/receiver.php", params, false, false);
        if (res.length > 0) {
            setProyP(res);
        }
    }

    //Boton regrezar/aprobar
    function showModal(s) {
        if (s !== 119 && checkNotes()) return;
        else if(validNotes) setValidNotes(false);
        
        setDataDenegar({
            title: s === 117 ? "Devolver solicitud" : "Aprobar solicitud",
            stat: s,
            case: s === 117 ? "Devolver" : "Aprobar",
        });
        setModalDenegar(true);
    }

    async function updateSolicitud(s) {
        setLoading(true);
        const params = {
            action: "update",
            table: "budgetrequests",
            condition: { idBudgetRequest: solicitud.id },
            rows: { keyStat: s, notes: s== 117 ? notes : "", idStaffAproves: s== 117 ? "" : user }
        }
        let res = await doRequest("receiver/receiver.php", params, true);
        if (res.length > 0) setShowButtons(false);
        setLoading(false);
    }

    function checkNotes() {
        if (notes.length < 1) setValidNotes(true);
        return notes.length < 1
    }

    const onChange = (val) => {
        if(!permissions.UPD) return;
        setValidNotes(val < 1);
        setNotes(val);
    }

    async function getPDF(){
        
        setModalReport(true);
    }

    /* Tabla proyectos con descripcion */
    async function getData(ordCol, itemsPerPage, currentPage, search) {
        const res = await doRequestSaveRes(
            "receiver/receiver.php",
            getParams(ordCol, itemsPerPage, currentPage, search),
            false,
            false
        );
        if (res.code === "200") return res;
        else return null;
    }

    function getParams(ordCol, itemsPerPage, currentPage, search) {
        const params = {
            action: "datatable",
            table: "budgetrequestdetail A INNER JOIN statefunds B USING (idStateFund) INNER JOIN expense_objects C USING (idExpenseObject) INNER JOIN budgetrequestschedule D USING (idDetail)",
            rows: "CONCAT(B.stateFundKey,' - ',B.nameStateFund) AS prPres,C.fullKey AS COG,C.nameObject,A.concept,SUM(D.approved) AS acumP",
            conditions: `A.idBudgetRequest = ${solicitud.id} AND A.enabled = 1`,
            order: ordCol,
            records: itemsPerPage.toString(),
            page: (itemsPerPage * currentPage).toString(),
            search,
            group: "C.idExpenseObject, B.idStateFund",
        };
        return params;
    }

    return (
        <div className="mt-4">
            <div className="mb-3 d-flex flex-md-row flex-column">
                <Button color="primary" className="mr-md-3 mt-1" onClick={()=>getPDF(true)}>Previsualizar</Button>
            </div>

            <Row className="my-3 text-center g-3">
                <Col xs={12} md={6}>
                    <h5>Folio:</h5>
                    {solicitud.folio}
                </Col>
                <Col xs={12} md={6}>
                    <h5>Fecha de recepción:</h5>
                    {fDatewhitTime(solicitud.fecha)}
                </Col>
            </Row>

            <div className="mt-4">
                <Datatable
                    headers={headers}
                    columns={columns}
                    columnDefs={columnDefs}
                    petition={getData}
                    control="back"
                    stateRefresh={[refresh, setRefresh]}
                    searching={true}
                    className="px-0 my-5"
                />

                <h5 className="mb-3 text-center">Calendarización del gasto</h5>
                <Row className="border border-middle-gray border-right-0 mx-1 mt-1">
                    {calend.map((el, ix) => (
                        <Col key={"rcg" + ix} xs={12} sm={4} md={3} className="p-0 border-right border-middle-gray">
                            <div className="bg-primary text-center text-light p-1">{el.nameMonth}</div>
                            <div className="text-middle text-right p-2">
                                <p>{fMoney(el.acum)}</p>
                            </div>
                        </Col>
                    ))}
                    <Col xs={12} className="p-0 border-right border-middle-gray" >
                        <div className="bg-primary text-light text-right px-2 py-1">Total</div>
                        <div className="text-middle text-right p-2">
                            <p>{fMoney(total)}</p>
                        </div>
                    </Col>
                </Row>
                <div className="my-3">
                    <h5 className="mb-1 text-center">Justificación del gasto</h5>
                    <div className="bg-light-gray p-2 border rounded"> {/*  */}
                        {solicitud.justificacion}
                    </div>
                </div>

                <h5 className="mb-3 text-center">Dcitamen</h5>
                <Table responsive className="border border-middle-gray">
                    <thead className="bg-primary">
                        <tr className="text-center">
                            <th className="text-light p-2 border-right border-light">Proyecto Presupuestal</th>
                            <th className="text-light p-2 border-right border-light">COG</th>
                            <th className="text-light p-2 border-right border-light">Denominación</th>
                            <th className="text-light p-2 border-right border-light">Importe</th>
                            <th className="text-light p-2 border-right border-light">Dictamen</th>
                            <th className="text-light p-2 border-right border-light">Fecha Dictamen</th>
                            <th className="text-light p-2 border-right border-light">Observaciones</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            proyP.map((el, ix) => (
                                <tr key={"rpp" + ix}>
                                    <td className="border border-middle-gray">{el.prPres}</td>
                                    <td className="text-center border border-middle-gray">{el.COG}</td>
                                    <td className="border border-middle-gray">{el.nameObject}</td>
                                    <td className="text-right border border-middle-gray">{fMoney(el.acum)}</td>
                                    <td className="border border-middle-gray">{el.nameStat}</td>
                                    <td className="text-center border border-middle-gray">
                                        {fDate(el.validationDate)}
                                    </td>
                                    <td className="border border-middle-gray">{el.notes}</td>
                                </tr>
                            ))
                        }
                    </tbody>
                </Table>

                <div className="mt-4">
                    <TextAreaInput
                        label="Comentarios/Observaciones del dictamen"
                        inputName="notes"
                        isRequired
                        inputRows={6}
                        onChangeMethod={({ target }) => onChange(target.value)}
                        value={notes}
                        touched={validNotes}
                        errors="Agregue un comentario u observación"
                        isDisabled={!showButtons}
                    />
                    {
                        showButtons && permissions.UPD &&
                        <div className="d-flex flex-md-row flex-column justify-content-center">
                            <Button className="m-2" color="success" onClick={() => showModal(119)}>Aprobar Solicitud</Button>
                            <Button className="m-2" color="danger" onClick={() => showModal(117)}>Regresar a Revisión</Button>
                        </div>
                    }
                </div>
            </div>

            {/* Modal regresar / aprobar partida */}
            <ModalConfirmation
                modalTitle={dataDenegar.title}
                modal={modalDenegar}
                setModal={setModalDenegar}
                crear={() => updateSolicitud(dataDenegar.stat)}
                buttonOkMsg={dataDenegar.case}
                centered
            >
                <div className="text-center">
                    <h6>¿Está seguro de {dataDenegar.case} esta solicitud?</h6>
                </div>
            </ModalConfirmation>

            {/* Modal mostrar pdf */}
            <ModalReports
                modal={modalReport}
                setModal={setModalReport}
                title={"Solicitud de suficiencia presupuestal y dictamen " + ""}
                backdrop
                keyboard
                report={{meses: calend, proyects: proyP, solicitud: solicitud, total: total, notas: notes}}
                pdfConfig={{}}
                generatePdfMethod={{
                method: pdfSolicitud,
                type: "reporteAuxiliar",
                }}
            />

            <FullFormLoader show={loading && calend.length > 0 && proyP.length > 0} message="" />
        </div>
    );
}

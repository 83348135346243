import { peticionesReceiver } from "../../../helpers/peticionesReceiver";
import Notification from "../../../components/Notification/Notification";
import { toast } from "react-toastify";
const API = peticionesReceiver();

export const createObjetoGasto = async (objetoGastoN) => {
  if (objetoGastoN) {
    delete objetoGastoN.keyConcept;
    delete objetoGastoN.keyChapter;
    objetoGastoN.keyItem = objetoGastoN.keyItem.split(",")[0]
    const params = {
      action: "insert",
      table: "expense_objects",
      rows: objetoGastoN,
      validate: [["keyItem", "keyExpenseType", "nameObject", "fullKey"]],
    };
    API.peticion(params)
      .then((res) => {
        if (res.status === 200 && res.data.code === "200") {
          toast(<Notification type={"agrega_exito"} withIcon />);
          window.location.href = "#/template/objetos-gasto";
        } else {
          toast(
            <Notification
              type={"agrega_error"}
              backMessage={res.data.message}
              withIcon
            />
          );
        }
      })
      .catch((err) => {
        toast(<Notification type={"consultar_servidor_error"} withIcon />);
      });
  }
};

export const updateObjetoGasto = async (objetoGastoU) => {
  delete objetoGastoU.keyConcept;
  delete objetoGastoU.keyChapter;
  objetoGastoU.keyItem = objetoGastoU.keyItem.split(",")[0]
  if (objetoGastoU) {
    const params = {
      action: "update",
      table: "expense_objects",
      rows: objetoGastoU,
      condition: {
        idExpenseObject: objetoGastoU.idExpenseObject,
      },
      validate: [["keyItem", "keyExpenseType", "nameObject", "fullKey"]],
    };
    API.peticion(params)
      .then((res) => {
        if (res.status === 200 && res.data.code === "200") {
          toast(<Notification type={"modifica_exito"} withIcon />);
          window.location.href = "#/template/objetos-gasto";
        } else {
          toast(
            <Notification
              type={"modifica_error"}
              backMessage={res.data.message}
              withIcon
            />
          );
        }
      })
      .catch((err) => {
        toast(<Notification type={"consultar_servidor_error"} withIcon />);
      });
  }
};

import { Document, Page, Text, View } from "@react-pdf/renderer";
import { styles } from "../../../components/Pdf/pdfGeneral/EstiloPdf";
import HeaderReport from "../../../components/Pdf/Header";

const formatter = new Intl.NumberFormat("es-MX", {
  style: "currency",
  currency: "MXN",
});

function formatDate(date) {
  return date.split("-").reverse().toString().replaceAll(",", "/");
}

const insertHeaders = () => {
  return (
    <>
      <View style={styles.tableRow} fixed>
        <View style={styles.cellHeader50Borders}>
          <Text>Capitulo</Text>
        </View>
        <View style={styles.cellHeader50BordersRTB}>
          <Text>Monto</Text>
        </View>
      </View>
    </>
  );
};

const insertContent = (data) => {
  return (
    <>
      {data.map((obj) => (
        <View style={[styles.tableRow, { height: 50 }]} wrap={false}>
          <View style={[styles.cellContent50BordersLBR]}>
            <Text>{obj.nameChapter}</Text>
          </View>
          <View style={[styles.cellContent50BordersRB]}>
            <Text>{obj.amount}</Text>
          </View>
          {/* <View style={[styles.cellContent50BordersRB,{fontSize:6}]}>
            <Text>{formatter.format(obj.amount)}</Text>
          </View> */}
        </View>
      ))}
    </>
  );
};

const insertTitles = (model, title) => {
  return (
    <>
      <HeaderReport />
      <View>
        <Text style={styles.title}>{model.textYear}</Text>
      </View>
      <View>
        <Text style={styles.subTitle}>{model.area}</Text>
      </View>
    </>
  );
};

const insertFooter = (fileNo) => {
  return (
    <>
      <View fixed style={styles.spaceFooter}></View>
      <View fixed style={styles.footer}>
        <Text style={styles.textFooter}>{fileNo}</Text>
        <Text
          style={styles.textFooter}
          render={({ pageNumber, totalPages }) =>
            `PAGINA : ${pageNumber} / ${totalPages}`
          }
        />
      </View>
    </>
  );
};

function convertirPrecio(cadenaPrecio) {
  // Eliminar cualquier carácter no numérico excepto el punto decimal
  cadenaPrecio = cadenaPrecio.replace(/[^0-9.]/g, "");

  // Convertir la cadena a un número decimal
  var precio = parseFloat(cadenaPrecio);

  // Devolver el precio convertido
  return precio;
}

const insertTotals = (total) => {
  return (
    <>
      <View style={[styles.tableRow, { height: 50 }]} wrap={false}>
        <View style={[styles.cellContent50BordersLBR]}>
          <Text style={{fontFamily: "Helvetica-Bold"}}>Total:</Text>
        </View>
        <View style={[styles.cellContent50BordersRB]}>
          <Text style={{fontFamily: "Helvetica-Bold"}}>{formatter.format(total)}</Text>
        </View>
      </View>
    </>
  );
};

const generateReport = (data, model, title, fileNo, total) => {
  return (
    <Document>
      <Page style={styles.body}>
        <View style={styles.tableCell}>
          {insertTitles(model, title)}
          {insertHeaders(model)}
          {insertContent(data)}
          {insertTotals(total)}

          <View style={styles.tableRow}></View>
        </View>
        {insertFooter(fileNo)}
      </Page>
    </Document>
  );
};
export function generatePdfPresupuestoAutorizados(
  data,
  model,
  title,
  fileNo,
  acumNeto,
  acumAmount,
  final
) {

  let total = 0;
  data.map((chapter, index) => {
    total += convertirPrecio(chapter.amount);
  });



  const report = generateReport(
    data,
    model,
    title,
    fileNo,
    total,
    final,
  );
  return {
    report: report,
  };
}

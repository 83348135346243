import React, { useEffect, useState } from "react";
import { Modal, ModalHeader, ModalBody, Button } from "reactstrap";
import { Alert } from "reactstrap";
import { peticionesReceiver } from "../../../../helpers/peticionesReceiver";
import { toast } from "react-toastify";
import Notification from "../../../../components/Notification/Notification";
import ModalConfirmation from "../../../../components/Modal/ModalConfirmation";
import * as Yup from "yup";
import { useFormik } from "formik";

const ModalObservaciones = ({ modal, setModal, activity, btnClose, refreshMethod }) => {
  const [modalConfirmation, setModalConfirmation] = useState(false)

  const [activitySelected, setActiviySelected] = useState("");
  const API = peticionesReceiver();

  const defaultData = {
    notes: ""
  }

  useEffect(() => {
    if (modal) {
      getActivities();
    }
  }, [modal]);

  useEffect(() => {
    if (activitySelected.keyStat === 89 && activitySelected.notes !== "") {
      setFieldValue(["notes"], activitySelected.notes);
    }
  } , [activitySelected])

  const getActivities = () => {
    const params = {
      action: "select",
      table: "activitiesprogress",
      condition: {
        idProgress: activity.idProgress,
        enabled: 1,
      },
    };
    API.peticion(params)
      .then((res) => {
        if (res.status === 200 && res.data.code === "200") {
          setActiviySelected(res.data.data[0]);
        } else {
          toast(<Notification type={"consultar_error"} withIcon />);
        }
      })
      .catch((err) => {
        toast(<Notification type={"consultar_servidor_error"} withIcon />);
        /* setLoading(false); */
      });
  };

  const toggle = () => setModal(!modal);

  const CloseBtn = (
    <>
      {btnClose ? (
        <i className="eva eva-close cursor-pointer" onClick={toggle} />
      ) : (
        <></>
      )}
    </>
  );

  const FormSchema = Yup.object().shape({
    notes: Yup.string()
  });

  const {
    handleSubmit,
    values,
    handleBlur,
    errors,
    touched,
    setFieldValue,
    resetForm,
  } = useFormik({
    initialValues: defaultData,
    onSubmit: (values) => {
      setModalConfirmation(true)
    },
    validationSchema: FormSchema,
    enableReinitialize: true,
  });

  const updateNote = async (values) => {
    const params = {
      action: "update",
      table: "activitiesprogress",
      rows: values,
      condition: {
        idProgress: activity.idProgress
      },
    };
    const re = await API.peticion(params);
      if (
        re.status === "success" ||
        re.status === "200" ||
        re.status === 200
      ) {
        toast(
          <Notification
            type={"agrega_exito"}
            backMessage={re.data.message}
            withIcon
          />
        );
      } else {
        toast(
          <Notification
            type={"modifica_error"}
            backMessage={re.data.message}
            withIcon
          />
        );
      }
      if (refreshMethod) {
        refreshMethod() 
      }
      setModal(false)
  }

  const onChange = (e) => {
    setFieldValue(e.target.name, e.target.value);
  };
  
  return (
    <>
      <ModalConfirmation
        modalTitle={"Crear"}
        modal={modalConfirmation}
        setModal={setModalConfirmation}
        crear={updateNote}
        isEdit={false}
        values={values}
      >
        <div className="d-flex justify-content-center">
          <h6>¿Está seguro de guardar el avance?</h6>
        </div>
      </ModalConfirmation>
      <Modal
        isOpen={modal}
        toggle={toggle}
        style={{ minWidth: "70%", minHeight: "70%" }}
      >
        <ModalHeader className="headline-1" close={CloseBtn}>Observaciones</ModalHeader>
        <ModalBody>
          <Alert
            color="dark"
            isOpen
            fade={false}
            className=""
            style={{
              borderRadius: "0.25rem",
              borderLeft: "3px solid #6b859e",
            }}
          >
            <h5 className="text-dark text-center">Observaciones</h5>
              {activitySelected.notes === null ||
              activitySelected.notes === undefined ||
              activitySelected.notes === ""
                ? <p className="text-dark text-center">"No se encontraron observaciones"</p>
              :
              activitySelected.keyStat === 89 ?
              <>
                <form onSubmit={handleSubmit}>
                  <label htmlFor="notes" className="text-dark">
                      Observaciones
                  </label>
                  <textarea
                    name="notes"
                    id="notes"
                    onChange={onChange}
                    onBlur={handleBlur}
                    rows={5}
                    value={values.notes}
                    className="form-control"
                  />
                  <div className="text-right mt-2">
                    <Button type="submit" onSubmit={handleSubmit} color="success" className="mr-2">Actualizar observación</Button>
                  </div>
                </form>
              </> : <p className="text-dark text-center">{activitySelected.notes}</p>}
          </Alert>

                  {activitySelected.notes === null ||
              activitySelected.notes === undefined ||
              activitySelected.notes === "" ? 
              "" : <p className="text-dark text-center">Fecha de observacion {activitySelected.modified} </p>}
          
        </ModalBody>
        </Modal>
      </>
  );
};

export default ModalObservaciones;

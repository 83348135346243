import { Document, Page, Text, View } from "@react-pdf/renderer";
import { styles } from "../../../../components/Pdf/pdfGeneral/EstiloPdf";
import HeaderReport from "../../../../components/Pdf/Header";

const formatter = new Intl.NumberFormat("es-MX", {
  style: "currency",
  currency: "MXN",
})

let totalAmount = 0
let totalNet = 0
const typeHeaderCell = (tam, index) => {
  if (index === 0) {
    return tam === 100
      ? styles.cellHeader100Borders
      : tam === 75
      ? styles.cellHeader75Borders
      : tam === 50
      ? styles.cellHeader50Borders
      : tam === 25
      ? styles.cellHeader25Borders
      : tam === 11
      ? styles.cellHeader11Borders
      : ""
  } else {
    return tam === 100
      ? styles.cellContent100LBR
      : tam === 88.7
      ? styles.cellContent88_7BordersLBR
      : tam === 75
      ? styles.cellHeader75BordersRTB
      : tam === 50
      ? styles.cellHeader50BordersRTB
      : tam === 25
      ? styles.cellHeader25BordersRTB
      : tam === 11
      ? styles.cellHeader11BordersRTB
      : ""
  }
}

const insertHeaders = (model) => {
  return (
    <>
      <View style={styles.tableRow} fixed>
                {model.headers.map((obj, index) => (
          <View style={typeHeaderCell(model.columnsHeaders[index], index)}>
            <Text>{obj}</Text>
          </View>
        ))}
      </View>
      
    </>
  )
}

const getTotals = (net, amount,obj,index) => {
  totalNet += +net;
  totalAmount += +amount;
};

const insertContent = (data) => {
  totalAmount = 0 
  totalNet = 0

  
  return (
    <>
      {data.map((obj,index) => (
        <View style={styles.tableRow} wrap={false}>
          {getTotals(obj.net, obj.amount,obj,index+1)}
          <View style={styles.cellContent11BordersLBR}>
            <Text>{obj.staffNumber}</Text>
          </View>
          <View style={styles.cellContent11BordersRB}>
            <Text>{obj.staffName}</Text>
          </View>
          <View style={styles.cellContent11BordersRB}>
            <Text>{obj.nameArea}</Text>
          </View>
          <View style={styles.cellContent11BordersRB}>
            <Text>{obj.nameJob}</Text>
          </View>
          <View style={styles.cellContent11BordersRB}>
            <Text>{obj.levelRange}</Text>
          </View>
          <View style={styles.cellContent11BordersRB}>
            <Text>{obj.startDate}</Text>
          </View>
          <View style={styles.cellContent11BordersRB}>
            <Text>{obj.endDate}</Text>
          </View>
          <View style={styles.cellContent11BordersRB}>
            <Text>{formatter.format(obj.net)}</Text>
          </View>
          <View style={styles.cellContent11BordersRB}>
            <Text>{formatter.format(obj.amount)}</Text>
          </View>
        </View>
      ))}
    </>
  );
};

const insertTitles = (model, title) => {
  return (
    <>
      <HeaderReport/>
      <View>
        <Text style={styles.title}>{title}</Text>
      </View>
      <View>
        
        <Text style={styles.subTitle}>
          {model.filters !== undefined
            ? model.filters.onlyMessage === true
              ? model.filters.message
              : ""
            : ""}
        </Text>
      </View>
    </>
  );
};

const insertTotals = () => {
  return (
    <>
      <View style={styles.tableRow} wrap={false}>
        <View style={styles.cellContent77_77BordersLBR}>
          <Text>Total</Text>
        </View>
        <View style={styles.cellContent11BordersRB}>
          <Text>{formatter.format(totalNet)}</Text>
        </View>
        <View style={styles.cellContent11BordersRB}>
          <Text>{formatter.format(totalAmount)}</Text>
        </View>
      </View>
    </>
  )
};
const insertTotalFinal =  (acumNeto,acumAmount) => {
  return (
    <>
       <View style={styles.tableRow} wrap={false}>
        <View style={styles.cellContent77_77BordersLBR}>
          <Text>Total del reporte </Text>
        </View>
        <View style={styles.cellContent11BordersRB}>
          <Text>{formatter.format(totalNet + acumNeto)}</Text>
        </View>
        <View style={styles.cellContent11BordersRB}>
          <Text>{formatter.format(totalAmount + acumAmount)}</Text>
        </View>
      </View>
    </>
  )
}

const insertFooter = (fileNo) => {
  return (
    <>
      <View fixed style={styles.spaceFooter}></View>
      <View fixed style={styles.footer}>
        <Text style={styles.textFooter}>{fileNo}</Text>
        <Text
          style={styles.textFooter}
          render={({ pageNumber, totalPages }) =>
            `PAGINA : ${pageNumber} / ${totalPages}`
          }
        />
      </View>
    </>
  );
};


const retSums = (total, acum, msg) => {
  return total+acum
}


const generateReport = (data,model,title,fileNo,acumNeto,acumAmount,final) => {
  return (
    <Document>
        <Page style={styles.body}>
          <View style={styles.tableCell}>
            {insertTitles(model, title)}
            {insertHeaders(model)}
            {insertContent(data)}
            {insertTotals()}
            {final  && (insertTotalFinal(acumNeto,acumAmount))}
            <View style={styles.tableRow}></View>
          </View>
          {insertFooter(fileNo)}
        </Page>
      </Document>
  )

}
export function generatePdfConceptoNomina(data, model, title, fileNo,acumNeto,acumAmount,final) {
  const report = generateReport(data,model,title,fileNo,acumNeto,acumAmount,final)
  return {
    report:report,
    totalneto: retSums(totalNet, acumNeto, "neto") ,
    totalamount:retSums(totalAmount, acumAmount ,"amount"),
  };
}

import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Col, Row, Button } from "reactstrap";
import { toast } from "react-toastify";
import Notification from "../../../../../components/Notification/Notification";
import {
  SelectTypeHeadSingle,
  SelectWithSearchInput,
} from "../../../../../components/GenericInputsFormik/index";
import { YearInput } from "../../../../../components/GenericInputsFormik/YearInput";
import ModalTableVer from "../../../../../components/Modal/ModalTableVer";
import { CancelOutlined } from "@material-ui/icons";
import s from "./InputClear.module.scss";
import { peticionesReceiver } from "../../../../../helpers/peticionesReceiver";
import { PERIOD } from "../../../calculo/regex";

const format = [
  {
    id: 1,
    value: "Pdf",
  },
  {
    id: 2,
    value: "Hoja de calculo",
  },
  {
    id: 3,
    value: "Html",
  },
];

const Form = (props) => {
  const {
    setReport,
    setShowReportFormat,
    setvaluesReport,
    setParams,
    setTotalPerceptions,
    setTotalDeductions,
  } = props;

  const API = peticionesReceiver();

  const [defaultForm] = useState({
    idFormat: 3,
    idStaff: "",
    nameStaff: "",
    period: "",
    year: new Date(),
  });

  const FormSchema = Yup.object().shape({
    idFormat: Yup.string().required("Seleccione un formato"),
    nameStaff: Yup.string().required("Seleccione un empleado"),
    period: Yup.number()
      .max(24, "Periodo debe ser menor a 25")
      .min(1, "Periodo debe ser mayor a 0")
      .required("Ingrese un periodo"),
    year: Yup.string()
      // .max(3000, "Año invalido")
      // .min(2020, "Año debe ser posterior al 2019")
      .required("Seleccione un año"),
  });

  const [modalTabla, setModalTabla] = useState(false);
  const filtroModalTabla = [true, true, true, true, true];
  const cabecerasModalTabla = [
    "#",
    "Núm. Empleado",
    "Nombre",
    "Unidad de trabajo",
    "Puesto",
    "Seleccionar",
  ];
  const [paramsModalTabla] = useState({
    action: "datatable",
    table:
      "staff STF INNER JOIN employtypes AS EMT ON STF.keyEmployType = EMT.keyEmployType INNER JOIN jobstaff AS JBS ON JBS.idStaff = STF.idStaff INNER JOIN areas AS AR ON AR.idArea = JBS.idArea INNER JOIN jobtypes AS JBT ON JBT.keyJobType = STF.keyEmployType",
    rows: "STF.idStaff,STF.staffNumber,CONCAT(STF.name,' ',STF.pName,' ',STF.mName) as fullName,AR.name,JBT.nameJobType",
    conditions:
      "STF.enabled = 1 AND JBS.valid = 1 AND STF.keyStat NOT IN (2, 3) AND JBS.enabled = 1",
    page: 0,
    records: 5,
    search: "",
    order: "",
  });

  const selectEmpleado = (el) => {
    setFieldValue("idStaff", el[0]);
    setFieldValue("nameStaff", `${el[1]} - ${el[2]}`);
    setModalTabla(false);
  };

  function handleClear() {
    setFieldValue("idStaff", "");
    setFieldValue("nameStaff", "");
    /* setShowReportFormat("0"); */
  }

  const getReport = (values) => {
    setvaluesReport(values);

    const params = {
      action: "report7",
      search: "",
      order: "under ASC",
      idStaff: values.idStaff,
      period: values.period,
      year: values.year.getFullYear(),
      prenomina: 1
    };

    setParams(params);
    let route = "app/facades/reports/reportsF.php";

    API.peticionEndPoint(params, route)
      .then((res) => {
        if (res.status === 200 && res.data.code === "200") {
          setReport(res.data.data);
          let totales = { totalPer: 0, totalDeduc: 0 };
          res.data.data.forEach((obj) => {
            if (obj.keyTypeUnder === "1") {
              totales.totalPer += +obj.amount;
            } else {
              totales.totalDeduc += +obj.amount;
            }
            setTotalPerceptions(totales.totalPer);
            setTotalDeductions(totales.totalDeduc);
          });
        }else{
          toast(
            <Notification
              type={"consultar_error"}
              backMessage={res.data.message}
              withIcon
            />
          );

        }
      })
      .catch((err) => {
        console.log(err);
        toast(<Notification type={"consultar_servidor_error"} withIcon />, {
          closeButton: false,
        });
      });
  };

  const { handleSubmit, handleBlur, values, errors, touched, setFieldValue } =
    useFormik({
      initialValues: defaultForm,
      onSubmit: (values) => {
        getReport(values);
      },
      validationSchema: FormSchema,
      enableReinitialize: true,
    });

  const onChange = (e) => {
    setReport("");
    if (e.target.value === "" || e.target.value === undefined) {
      if (e.target.name === "initialDate") {
        setFieldValue("finalDate", "");
      }
      // if (e.target.name === "period") {
      //   setFieldValue("year", new Date());
      // }
    }
    if (e.target.value === undefined) {
      setFieldValue([e.target.name], "");
    } else {
      setFieldValue([e.target.name], e.target.value);
    }
    setFieldValue([e.target.name], e.target.value);
    /* setShowReportFormat("0"); */
  };

  return (
    <div>
      <form onSubmit={handleSubmit} className="mt-4" noValidate>
        <Row className="d-flex justify-content-center">
          <Col xs={12} md={12} lg={6}>
            <SelectWithSearchInput
              label="Seleccione un formato"
              inputOptions={format}
              inputName="idFormat"
              onChangeMethod={(options) => {
                setReport("");
                setShowReportFormat(options.value);
                setFieldValue("idFormat", options.value);
              }}
              onBlurMethod={handleBlur}
              touched={touched.idFormat}
              errors={errors.idFormat}
              optionValue="id"
              optionName="value"
              inputValue={values.idFormat}
              /* isDisabled={data ? true : false} */
              isRequired
            />
          </Col>
          <Col xs={12} md={12} lg={6}>
            <div className="form-group position-relative">
              <label htmlFor="nameStaff">
                Empleado <span className="text-danger">*</span>
              </label>
              <div className="input-group">
                <input
                  name="nameStaff"
                  onChange={onChange}
                  onBlur={handleBlur}
                  className={
                    "form-control" +
                    (errors.nameStaff && touched.nameStaff ? " is-invalid" : "")
                  }
                  value={values.nameStaff}
                  readOnly
                  onClick={() => setModalTabla(true)}
                />
                <div className="input-group-append">
                  <button
                    className="btn btn-secondary"
                    style={{ zIndex: "auto" }}
                    type="button"
                    onClick={() => setModalTabla(true)}
                  >
                    <i className="fa fa-search" />
                  </button>
                </div>
              </div>
              {values.nameStaff !== "" && (
                <CancelOutlined className={s.btnClear} onClick={handleClear} />
              )}
              {touched.nameStaff && errors.nameStaff && (
                <span style={{ color: "red" }}>{errors.nameStaff}</span>
              )}
            </div>
          </Col>
        </Row>

        <Row>
          <Col xs={12} lg={6}>
            <SelectTypeHeadSingle
              label="Periodo"
              isRequired={true}
              optionsArray={PERIOD}
              inputName="period"
              onChangeMethod={onChange}
              onBlurMethod={handleBlur}
              touched={touched.period}
              errors={errors.period}
              optionValue="value"
              optionName="label"
              value={values.period}
              defaultOption="Seleccione un periodo"
            />
          </Col>
          <Col xs={12} lg={6}>
            <YearInput
              label="Año"
              inputName="year"
              onChangeMethod={onChange}
              onBlurMethod={handleBlur}
              value={values.year}
              isRequired
              touched={touched.year}
              errors={errors.year}
              isMin={new Date("2020")}
              isMax={new Date("2500")}
            />
          </Col>
        </Row>

        <div className="text-center my-3">
          <Button color="success" type="submit">
            Generar
          </Button>
        </div>
      </form>

      <ModalTableVer
        modal={modalTabla}
        setModal={setModalTabla}
        title="Empleados"
        cabeceras={cabecerasModalTabla}
        filtro={filtroModalTabla}
        parametros={paramsModalTabla}
        selectRegistro={selectEmpleado}
        backdrop={"static"}
        keyboard={false}
        btnClose
      />
    </div>
  );
};

export default Form;
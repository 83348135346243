/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import axios from "axios";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Collapse } from "reactstrap";
import { toast } from "react-toastify";
import { Col, Row, Button } from "reactstrap";
import { REGEX_NUMBER } from "../../../helpers/regex";
import { peticionEncript } from "../../../helpers/peticionesEncripted";
import { peticionesReceiver } from "../../../helpers/peticionesReceiver";
import Loader from "../../../components/Loader/Loader";
import Widget from "../../../components/Widget/Widget";
import Notification from "../../../components/Notification/Notification";
import ModalConfirmation from "../../../components/Modal/ModalConfirmation";
import {
  getInstitutions,
  getKindToStatus,
  getTypesInsurance,
  getUnders,
} from "./consultasSelect";
import {
  TextInput,
  TextAreaInput,
  SelectTypeHeadSingle,
  FilesInput,
} from "../../../components/GenericInputsFormik/index";
import { useRef } from "react";
import { doRequest, uploadFile } from "../../../helpers/requests";

import TableComponentCustom from "../../../components/TableComponent/TableComponentCustom";
import ModalViewFile from "../../../components/Modal/ModalViewFile";
import ModalComponentEliminar from "../../../components/Modal/ModalDelete";
import { DatePickerInput } from "../../../components/GenericInputsFormik/DatePickerInput";
import { NumberInput } from "../../../components/GenericInputsFormik/NumberInput";
import { fDateInput } from "../../../libs/tools/format";

export const ExpedienteNominaSeguros = ({ idStaff, permissions, idModule }) => {
  const API = peticionesReceiver();
  const [modalFile, setModalFile] = useState(false);
  const [linkFile, setLinkFile] = useState("");
  const [editando, setEditando] = useState(false);
  const [collapse, setCollapse] = useState(false);
  const [modal, setModal] = useState(false);
  const [editHasFile, setEditHasFile] = useState(false);
  const [parametros, setParametros] = useState({
    action: "datatable",
    table: `staffinsurance  AS SI INNER JOIN unders AS UN ON UN.idUnder = SI.idUnder INNER JOIN institutions AS INS ON INS.keyInstitution = SI.keyInstitution INNER JOIN types_insurance AS TI ON TI.keyTypeInsurance = SI.keyTypeInsurance INNER JOIN status AS ST ON ST.keyStat = SI.keyStat LEFT JOIN bookcase X ON SI.idStaffInsurance = X.reference AND X.keyFileType = 50 LEFT JOIN files Y ON X.idBook = Y.idBook `,
    rows: `SI.idStaffInsurance,SI.policy,INS.nameInstitution,TI.nameTypeInsurance, CONCAT(UN.under, ' - ', UN.nameUnder) AS nameUnder,SI.startDate,SI.endDate,SI.noPays,SI.amountTotal,SI.amountBiweekly, SI.noPaysMade,ST.nameStat,Y.src`,
    conditions: `SI.enabled = 1 AND SI.idStaff=${idStaff} AND TI.idModule=${idModule} AND IF(ISNULL(X.enabled), TRUE, X.enabled = 1 AND Y.enabled = 1)`,
    page: 0,
    records: 5,
    search: "",
    order: "",
  });
  const [selectsData, setSelectsData] = useState({
    statusInsurance: [],
    institutionsInsurance: [],
    typesInsurance: [],
    unders: [],
  });
  const [loading, setLoading] = useState(true);
  const refInpFile = useRef(null);
  const refInpTipo = useRef(null);
  
  /* INITIAL */
  useEffect(() => {
    getKindToStatus(setSelectsData, true, "statusInsurance", "insurance");
    getInstitutions(setSelectsData, true, "institutionsInsurance");
    getTypesInsurance(setSelectsData, true, "typesInsurance", idModule);
    getUnders(setSelectsData, true, "unders", " = 3");
    setLoading(false);
  }, []);
  /* END INITIAL */

  const limpia = (reset) => {
    setEditando(false);
    setEditHasFile(false);
    setCollapse(false);
    reset();
  };

  const FormSchema = Yup.object().shape({
    // keyTypeInsurance: Yup.number().moreThan(0,"Favor de seleccionar un tipo de seguro"),
    policy: Yup.string()
      .min(2, "La poliza debe contener al menos 2 caracteres")
      .max(30, "La poliza ingresada es muy largo")
      .required("Ingrese una poliza"),
    idUnder: Yup.number().moreThan(0, "Favor de seleccionar un concepto"),
    keyInstitution: Yup.number().moreThan(
      0,
      "Favor de seleccionar una institucion"
    ),
    startDate: Yup.date()
      .typeError("Favor de ingresar una fecha valida")
      .required("Favor se seleccionar una fecha de inicio"),
    // endDate: Yup.date()
    //   .typeError("Favor de ingresar una fecha valida")
    //   .required("Favor se seleccionar una fecha de finalización"),
    // amountTotal: Yup.number()
    //   .moreThan(0, "Favor de ingresar un monto total")
    //   .max(9999999999, "El monto debe ser menor a $9999999999"),
    // noPays: Yup.number().required("Ingrese el número de parcialidades").moreThan(
    //   0,
    //   "Favor de seleccionar ingresar el numero de parcialidades"
    // ),
    // .max(24,"Número de parcialidades debe ser menor a 25"),
    keyStat: Yup.number()
      .moreThan(0, "Favor de seleccionar un estado")
      .required("Seleccione un estado"),
    comprobante: Yup.mixed().when("act", {
      is: (v) => {
        return editHasFile;
      },
      otherwise: Yup.mixed()
        .required("Agregue un comprobante")
        .test("file-type", "El documento debe ser en formato PDF", (value) => {
          return value && value.type === "application/pdf";
        })
        .test({
          message: `El documento debe pesar menos de 4MB`,
          test: (value) => value?.size < 4000000,
        }),
    }),
    amountBiweekly: Yup.number()
      .moreThan(0, "Favor de ingresar el monto quincenal")
      .max(9999999999, "El monto debe ser menor a $9999999999")
      .required("Ingrese el monto quincenal"),
  });

  const onChange = (e) => {
    const { name, value, type } = e.target;
    setFieldValue([name], value);
    if (type === "number") {
      if (REGEX_NUMBER.test(value)) {
        if (value === "") setFieldValue([name], 1);
      }
    }
    if (name === "amountTotal" || name === "noPays") {
      if(value){
        const calc = name === "amountTotal"? value / values.noPays : values.amountTotal / value;
        setFieldValue("amountBiweekly", calc.toFixed(2));
      }else setFieldValue("amountBiweekly", "");
    }
  };

  const onChangeFile = (e) => {
    var { files } = e.target;
    if (files.length === 0) setFieldValue("comprobante", "");
    else setFieldValue("comprobante", files[0]);
  };

  const sendFormInformation = async () => {
    setLoading(true);
    const params = {
      action: "saveInsurance",
      rows: {
        ...values,
        startDate: fDateInput(values.startDate),
      },
    };
    const finalData = peticionEncript(params);
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API}app/facades/employees/benefitsF.php`,
        finalData
      );
      if (res.status === 200 && res.data.status === "success") {
        if (values.comprobante) {
          await uploadFile(
            res.data.data[0].idStaffInsurance,
            "saveFileReplace",
            50,
            values.comprobante,
            false
          );
        }
        setParametros({ ...parametros });
        toast(<Notification type={"agrega_exito"} withIcon />);
        limpia(resetForm);
      } else {
        toast(
          <Notification
            type={"agrega_error"}
            backMessage={res.data.message}
            withIcon
          />
        );
      }
    } catch (err) {
      console.log(err);
      toast(<Notification type={"consultar_error"} withIcon />);
    }
    setLoading(false);
  };

  const getInsuranceById = async (id) => {
    const params = {
      action: "select",
      table: "staffinsurance",
      condition: {
        enabled: 1,
        idStaffInsurance: id,
      },
    };
    const res = await API.peticion(params);
    if (res.status === 200 && res.data.code === "200") {
      const data = res.data.data[0];
      let startDate = new Date(data.startDate);
      let endDate = new Date(data.endDate);
      startDate.setDate(startDate.getDate() + 1);
      endDate.setDate(endDate.getDate() + 1);
      setFieldValue("idStaffInsurance", data.idStaffInsurance);
      setFieldValue("idUnder", data.idUnder);
      setFieldValue("keyInstitution", data.keyInstitution);
      setFieldValue("keyTypeInsurance", data.keyTypeInsurance);
      setFieldValue("keyStat", data.keyStat);
      setFieldValue("policy", data.policy);
      setFieldValue("startDate", startDate);
      setFieldValue("endDate", endDate);
      setFieldValue("amountTotal", data.amountTotal);
      setFieldValue("amountBiweekly", data.amountBiweekly);
      setFieldValue("noPays", data.noPays);
      setFieldValue(
        "noPaysMade",
        data.noPaysMade === null ? 0 : data.noPaysMade
      );
      setFieldValue("notes", data.notes);
    } else {
      toast(<Notification type={"consultar_error"} withIcon />);
    }
    setLoading(false);
    setEditando(true);
    setCollapse(true);
  };


  /* FORM */
  const {
    handleSubmit,
    handleReset,
    values,
    handleBlur,
    errors,
    touched,
    setFieldValue,
    resetForm,
  } = useFormik({
    initialValues: { ...initialValues, idStaff: idStaff },
    onSubmit: (values) => {
      setModal(true);
    },
    validationSchema: FormSchema,
    enableReinitialize: true,
  });
  /* END FORM */

  /* TABLE */
  const PdfCol = (el) => {
    return (
      <div>
        <i className="fa fa-file-pdf-o cursor-pointer" onClick={() => showFile(el[12]) }/>
      </div>
    );
  };

  const editCol = (el) => {
    if (permissions.UPD)
      return (
        <div>
          <i
            className="fa fa-pencil text-warning cursor-pointer"
            onClick={() => updated(el)}
          />
        </div>
      );
  };

  const deleteCol = (el) => {
    if (permissions.DEL)
      return (
        <div>
          <ModalComponentEliminar
            modalTitle="Eliminar"
            id={el[0]}
            table="staffinsurance"
            nameColumn={["idStaffInsurance"]}
            limpia={limpia}
            elimina={elimianrRegistro}
          >
            <h6>¿Seguro de eliminar el registro "{el[1]}"?</h6>
            No podrá recuperar la información después de ser eliminada
          </ModalComponentEliminar>
        </div>
      );
  };

  function showFile(src) {
    if (src === "") {
      toast(
        <Notification
          type={"warning"}
          backMessage="Este registro no cuenta con una evidencia"
          withIcon
        />,
        { closeButton: false }
      );
    } else setLinkFile(src);
  }

  const updated = async (data) => {
    if (!collapse) {
      if (data[12]) setEditHasFile(true);
      setLoading(true);
      await getInsuranceById(data[0]);
    } else {
      toast(
        <Notification
          type="warning"
          backMessage="Guarde sus cambios antes de continuar"
        />
      );
    }
  };

  async function elimianrRegistro(id) {
    const params = {
      action: "delete",
      table: "staffinsurance",
      condition: { idStaffInsurance: id },
      force: 1,
    };
    setLoading(true);
    const data = await doRequest("receiver/receiver.php", params, true);
    if (data.length > 0) {
      limpia(resetForm);
      setParametros({ ...parametros });
    }
    setLoading(false);
  }
  /* END TABLE */


  useEffect(() => {
    if (linkFile !== "") {
      setModalFile(true);
    }
  }, [linkFile]);

  useEffect(() => {
    if (!modalFile) {
      setLinkFile("");
    }
  }, [modalFile]);

  return (
    <>
      <Widget className="widget-p-md">
        <TableComponentCustom
          titulo="Seguros"
          cabecerasTabla={cabeceras}
          filtro={filtro}
          parametros={parametros}
          specialColumns={[PdfCol, editCol, deleteCol]}
        />
        {permissions.INS && (
          <div className="d-grid d-flex justify-content-center justify-content-sm-end">
            <Button
              color="add"
              onClick={() => setCollapse(true)}
              style={{ marginBottom: "1rem" }}
              disabled={collapse}
            >
              Agregar
            </Button>
          </div>
        )}

        <Collapse isOpen={collapse}>
          <div>
            {loading ? (
              <Loader />
            ) : (
              <>
                <div>
                  <Col className="d-grid d-flex justify-content-sm-end justify-content-center">
                    <h6 className=" text-center">
                      Estado:{" "}
                      <span className="text-primary">
                        {editando ? "Editando" : "Guardando"}{" "}
                      </span>{" "}
                    </h6>
                  </Col>
                </div>
                <form
                  onSubmit={handleSubmit}
                  onReset={handleReset}
                  className="mt-4"
                  noValidate
                >
                  <ModalConfirmation
                    modalTitle={editando ? "Editar" : "Crear"}
                    modal={modal}
                    setModal={setModal}
                    crear={sendFormInformation}
                    editar={sendFormInformation}
                    isEdit={idStaff ? true : false}
                    values={values}
                  >
                    {editando ? (
                      <div className="d-flex justify-content-center">
                        <h6>¿Está seguro de editar el registro?</h6>
                      </div>
                    ) : (
                      <div className="d-flex justify-content-center">
                        <h6>¿Desea continuar con el registro?</h6>
                      </div>
                    )}
                  </ModalConfirmation>

                  <Row className="mt-2">
                    <Col xs={12} md={12} lg={4}>
                      <TextInput
                        label="Poliza"
                        inputType="text"
                        inputName="policy"
                        onChangeMethod={onChange}
                        onBlurMethod={handleBlur}
                        value={values.policy}
                        isRequired={true}
                        touched={touched.policy}
                        errors={errors.policy}
                        isMax={30}
                      />
                    </Col>

                    <Col xs={12} md={12} lg={4}>
                      <SelectTypeHeadSingle
                        label="Institución"
                        isRequired={true}
                        inputName="keyInstitution"
                        optionsArray={selectsData.institutionsInsurance}
                        defaultOption="Seleccione un tipo una institución"
                        onChangeMethod={onChange}
                        onBlurMethod={handleBlur}
                        isDisabled={false}
                        value={values.keyInstitution}
                        touched={touched.keyInstitution}
                        errors={errors.keyInstitution}
                        optionValue="keyInstitution"
                        optionName="nameInstitution"
                      />
                    </Col>

                    <Col xs={12} md={12} lg={4}>
                      <SelectTypeHeadSingle
                        label="Tipo de seguro"
                        inputName="keyTypeInsurance"
                        optionsArray={selectsData.typesInsurance}
                        defaultOption="Seleccione un tipo de seguro"
                        onChangeMethod={onChange}
                        onBlurMethod={handleBlur}
                        isDisabled={false}
                        value={values.keyTypeInsurance}
                        touched={touched.keyTypeInsurance}
                        errors={errors.keyTypeInsurance}
                        optionValue="keyTypeInsurance"
                        optionName="nameTypeInsurance"
                        refSelect={refInpTipo}
                        // isRequired={true}
                      />
                    </Col>

                    <Col xs={12} md={12} lg={4}>
                      <SelectTypeHeadSingle
                        label="Concepto"
                        isRequired={true}
                        inputName="idUnder"
                        optionsArray={selectsData.unders}
                        defaultOption="Seleccione un concepto"
                        onChangeMethod={onChange}
                        onBlurMethod={handleBlur}
                        isDisabled={false}
                        value={values.idUnder}
                        touched={touched.idUnder}
                        errors={errors.idUnder}
                        optionValue="idUnder"
                        optionName="name"
                      />
                    </Col>

                    <Col xs={12} md={12} lg={4} className="d-flex flex-column">
                      <DatePickerInput
                        label=" Fecha de inicio"
                        inputName="startDate"
                        onChangeMethod={onChange}
                        onBlurMethod={handleBlur}
                        value={values.startDate}
                        touched={touched.startDate}
                        errors={errors.startDate}
                        isRequired
                      />
                    </Col>

                    {/* <Col xs={12} md={12} lg={4} className="d-flex flex-column">
                      <DatePickerInput
                        label=" Fecha de término"
                        inputName="endDate"
                        onChangeMethod={onChange}
                        onBlurMethod={handleBlur}
                        value={values.endDate}
                        touched={touched.endDate}
                        errors={errors.endDate}
                        isMin={values.startDate}
                        isDisabled={!values.startDate}
                        isRequired
                      />
                    </Col> */}

                    {/* <Col xs={12} md={12} lg={4}>
                      <NumberInput
                        label="Número de Parcialidades"
                        inputName="noPays"
                        onChangeMethod={onChange}
                        onBlurMethod={handleBlur}
                        value={values.noPays}
                        errors={errors.noPays}
                        touched={touched.noPays}
                        isMin={1}
                        maxLength={3}
                        isRequired
                      />
                    </Col> */}

                    {/* <Col xs={12} md={12} lg={4}>
                      <NumberInput
                        label="Cantidad total"
                        inputName="amountTotal"
                        onChangeMethod={onChange}
                        onBlurMethod={handleBlur}
                        value={values.amountTotal}
                        errors={errors.amountTotal}
                        touched={touched.amountTotal}
                        isMin={1}
                        decimals
                        prefix="$"
                        isRequired
                      />
                    </Col> */}

                    <Col xs={12} md={12} lg={4}>
                      <NumberInput
                        label="Cantidad quincenal"
                        inputName="amountBiweekly"
                        onChangeMethod={onChange}
                        onBlurMethod={handleBlur}
                        value={values.amountBiweekly}
                        errors={errors.amountBiweekly}
                        touched={touched.amountBiweekly}
                        isMin={1}
                        decimals
                        prefix="$"
                        isRequired
                      />
                    </Col>

                    <Col xs={12} md={12} lg={4}>
                      <TextInput
                        label="Pagos Aplicados"
                        inputType="number"
                        inputName="noPaysMade"
                        value={values.noPaysMade}
                        isRequired={true}
                        touched={touched.noPaysMade}
                        errors={errors.noPaysMade}
                        isDisabled={true}
                      />
                    </Col>

                    <Col xs={12} md={12} lg={4}>
                      <SelectTypeHeadSingle
                        label="Estado"
                        isRequired={true}
                        inputName="keyStat"
                        optionsArray={selectsData.statusInsurance}
                        defaultOption="Seleccione un estado"
                        onChangeMethod={onChange}
                        onBlurMethod={handleBlur}
                        isDisabled={false}
                        value={values.keyStat}
                        touched={touched.keyStat}
                        errors={errors.keyStat}
                        optionValue="keyStat"
                        optionName="nameStat"
                      />
                    </Col>

                    <Col xs={12} md={12} lg={4}>
                      <FilesInput
                        label="Evidencia"
                        inputName="comprobante"
                        onChangeMethod={onChangeFile}
                        onBlurMethod={handleBlur}
                        fileAccept="application/pdf"
                        isRequired
                        touched={touched.comprobante}
                        errors={errors.comprobante}
                        basicReq={false}
                        refInput={refInpFile}
                      />
                    </Col>
                  </Row>

                  <div className="form-group">
                    <TextAreaInput
                      label="Información Adicional"
                      inputName="notes"
                      inputRows={10}
                      onChangeMethod={onChange}
                      onBlurMethod={handleBlur}
                      value={values.notes}
                      isRequired={false}
                      touched={touched.notes}
                      errors={errors.notes}
                    />
                  </div>

                  <div className="row mt-3">
                    <div className="col-sm-6 order-2 order-sm-1 text-center text-sm-left mb-4">
                      <Button
                        color="danger"
                        type="reset"
                        onClick={() => limpia(resetForm)}
                      >
                        Cancelar
                      </Button>
                    </div>
                    <div className="col-sm-6 order-1 order-sm-2 text-center text-sm-right mb-4">
                      <Button color="success" type="submit">
                        Guardar
                      </Button>
                    </div>
                  </div>
                </form>
              </>
            )}
          </div>
        </Collapse>
        <ModalViewFile
          modal={modalFile}
          setModal={setModalFile}
          backdrop={true}
          keyboard={true}
          btnClose={true}
          fileLink={linkFile}
        />
      </Widget>
    </>
  );
};

export default ExpedienteNominaSeguros;

const initialValues = {
  idStaffInsurance: "",
  idStaff: "",
  idUnder: 0,
  keyInstitution: 0,
  keyTypeInsurance: 0,
  keyStat: 0,
  policy: "",
  startDate: "",
  endDate: "",
  amountTotal: 0,
  amountBiweekly: "",
  noPays: 1,
  noPaysMade: "",
  notes: "",
  comprobante: "",
};

const cabeceras = [
  "Id",
  "Poliza",
  "Institución",
  "Tipo de seguro",
  "Concepto",
  "Fecha de inicio",
  null,// "Fecha de termino",
  null,// "Número de parcialidades",
  null,// "Cantidad Total",
  "Cantidad Quincenal",
  "Pagos Aplicados",
  "Estado",
  "Evidencia",
  "Editar",
  "Eliminar",
];
const filtro = [
  true,
  true,
  true,
  true,
  true,
  true,
  false,
  false,
  false,
  true,
  true,
  true,
  false,
  false,
  false,
];

import { Button, Modal, ModalBody, ModalHeader, Spinner } from "reactstrap";
import Datatable from "../../../../components/Datatable/Datatable";
import { useState } from "react";
import AnexosTecnicos from "../../solicitud-cotizacion/Forms/Editar/AnexosTecnicos";
import { toast } from "react-toastify";
import Notification from "../../../../components/Notification/Notification";
import { peticionesReceiver } from "../../../../helpers/peticionesReceiver";
import { GetPermissions } from "../../../../libs/permissions/getPermissions";

const formatter = new Intl.NumberFormat("es-MX", {
    style: "currency",
    currency: "MXN",
});

export default function BienesSolicitados({ idCot }) {
    const [modal, setModal] = useState(false);
    const [permisos, setPermisos] = useState("")
    const [loading, setLoading] = useState("")
    GetPermissions(setPermisos, setLoading);
    const API = peticionesReceiver()

    const columns = [
        { data: "tipo" },
        { data: "amount", format: "decim" },
        {
            data: "unitCost",
            render: function (row) {
                return row === "" ? "$0.00" : formatter.format(row)
            }
        },
        { data: "" },
        { data: "descripcion" },
        { data: "unit" }
    ]

    const headers = ["Tipo", "Cantidad", "Costo", "Denominación", "Descripción del servicio", "Unidad de medida"]


    const handleOpenModal = () => setModal(true);
    const handleCloseModal = () => setModal(false);

    const getParamsTableElmt = (order, records, page, search) => {
        const params = {
            action: "datatable",
            table: "quotesdetail qt left join services sv on (qt.idService=sv.idService) left join furnitures f on (f.idFurniture=qt.idFurniture) left join messureunits mu on (f.keyUnit=mu.keyUnit) left join servicetimes sti on (sv.keyServiceTime=sti.keyServiceTime)",
            rows: "if(isnull(sv.nameService),'BIENES','SERVICIOS') as tipo,if(isnull(sv.nameService),f.nameFurniture,sv.nameService) as descripcion,if(isnull(mu.nameUnit),sti.nameServiceTime,mu.nameUnit) as unit,qt.amount,qt.unitCost,qt.taxies,qt.total,qt.notes",
            conditions: `qt.idQuote=${idCot} AND qt.enabled=1`,
            order,
            records: records.toString(),
            page: (page * records).toString(),
            search
        }
        return params
    }

    async function getDataTableElmts(order, records, page, search) {
        try {
            const res = await API.peticion(getParamsTableElmt(order, records, page, search));
            if (res.status === 200 && res.data.code === "200") {
                return res.data;
            } else {
                toast(<Notification type={"consultar_error"} backMessage={res.data.message} />);
                return null;
            }
        } catch (err) {
            toast(<Notification type={"consultar_servidor_error"} withIcon />);
            return null
        }
    }

    if (loading) {
        <div className="d-flex align-items-center justify-content-center" style={{ height: "200px" }}>
            <Spinner
                color="primary"
                style={{
                    height: '3rem',
                    width: '3rem'
                }}
                type="grow"
            >
                Loading...
            </Spinner>
        </div>
    }
    if (permisos.CON) {
        return (
            <>
                <Button color="add" className="mb-3" onClick={handleOpenModal}>Ver anexos técnico administrativo</Button>
                {idCot !== "" && (
                    <Datatable
                        headers={headers}
                        columns={columns}
                        control="back"
                        petition={getDataTableElmts}
                    />
                )}
                <Modal isOpen={modal} toggle={handleCloseModal} fullscreen="lg" size="xl" backdrop="static" >
                    <ModalHeader className="pb-0" toggle={handleCloseModal} />
                    <ModalBody className="m-0 p-0">
                        <AnexosTecnicos idCot={idCot} add={false} />
                    </ModalBody>
                </Modal>
            </>
        )
    } else {
        return (
            <>
                <div className="text-center p-4">
                    No tienes permitido ver los registros de esta seccion
                </div>
            </>
        )
    }
}
import * as XlsxPopulate from "xlsx-populate";
import { saveAs } from "file-saver";

const formatter = new Intl.NumberFormat("es-MX", {
  style: "currency",
  currency: "MXN",
});

const dateformatter = (fecha) => {
  let date = new Date (fecha)
  return `${date.getDate()}/${date.getMonth()}/${date.getFullYear()}`
}

export async function generateExcelHistoricoPlazas(report, headers, title, filter, params) {

  XlsxPopulate.fromBlankAsync().then(async (workbook) => {
    //Estilos
    // const dataStyle = workbook.styleSheet().createStyle();
    // dataStyle.style('wrapText', true);
    // dataStyle.style('horizontalAlignment', 'center');
    // dataStyle.style('verticalAlignment', 'center');
    // dataStyle.style('border', true);

    const ds = {wrapText: true, horizontalAlignment: 'center', verticalAlignment: 'center', border: true}

    // const headerStyle = workbook.styleSheet().createStyle();
    // headerStyle.style('horizontalAlignment', 'center');
    // headerStyle.style('verticalAlignment', 'center');
    // headerStyle.style('border', true);
    // headerStyle.style('bold', true);

    const hs = {horizontalAlignment: 'center', verticalAlignment: 'center', border: true, bold: true}

    // const titleStyle = workbook.styleSheet().createStyle();
    // titleStyle.style('horizontalAlignment', 'center');
    // titleStyle.style('verticalAlignment', 'center');
    // titleStyle.style('bold', true);

    const ts = {horizontalAlignment: 'center', verticalAlignment: 'center', bold: true}

    const sheet1 = workbook.sheet(0);
    const sheetData = getSheetData(report, headers, filter);
    const mid = Math.ceil(headers.length / 2);

    // Unir tres columnas del centro
    let colL = sheet1.column(mid - 1).columnName();
    let colR = sheet1.column(mid + 1).columnName();

    sheet1
      .range(`${colL}1:${colR}1`)
      .merged(true)
      .value("SAPP")
      .style(ts);
    sheet1
      .range(`${colL}2:${colR}2`)
      .merged(true)
      .value(title)
      .style(ts);
    /* if (params && params != "") {
      sheet1
        .range(`${colL}3:${colR}3`)
        .merged(true)
        .value(params)
        .style(ts);
    } */


    sheet1.row(6).cell(1).value("NUMERO DE PLAZA").style(hs);
    sheet1.row(6).cell(2).value("PUESTO LABORAL").style(hs);
    sheet1.row(6).cell(3).value("NIVEL-RANGO").style(hs);
    sheet1.row(6).cell(4).value("TIPO DE PLAZA").style(hs);
    sheet1.row(6).cell(5).value("STATUS DE PLAZA").style(hs);
    sheet1.row(6).cell(6).value("VACANTE").style(hs);
    
    sheet1.row(7).cell(1).value(report[0].idJobPosition).style(ds);
    sheet1.row(7).cell(2).value(report[0].nameJob).style(ds);
    sheet1.row(7).cell(3).value(report[0].LevelRango).style(ds);
    sheet1.row(7).cell(4).value(report[0].namePositionType).style(ds);
    sheet1.row(7).cell(5).value(report[0].nameStatJobposition).style(ds);
    sheet1.row(7).cell(6).value(report[0].vacante).style(ds);
    

    if(report.length > 4000){
      sheet1.row(9).cell(1).value(sheetData);
    }else{
      sheet1.row(9).cell(1).value(sheetData).style(ds);
    }

    for (let i = 1; i <= headers.length; i++) {
        sheet1.column(i).width(30);
      if(title === "Reporte de Plantilla laboral"){
        if(i === 2){
          sheet1.column(i).width(50);
        }
        if(i === 1 || i === 3 || i === 8 || i === 9 || i === 10){
          sheet1.column(i).style({horizontalAlignment: "center"});
        }
      }
      sheet1.row(9).cell(i).style(hs);
    }

    // sheet1.usedRange().style("horizontalAlignment", "center");

    return workbook.outputAsync().then((res) => {
      saveAs(res, `${title}.xlsx`);
    });
  });
}



function getSheetData(data, header, filter) {
  let keys = [];
  header.forEach((h) => {
    filter.content.every((arr) => {
      let flg = true;
      arr.every((ob) => {
        if (ob.header === h) {
          keys.push([ob.value, ob.format]);
          flg = false;
          return false;
        }
        return true;
      });
      return flg;
    });
  });


  keys.unshift([filter.id.id, filter.id.format]);
  let pageData = data.map((d) => {
    let reg = keys.map((k) => {
      let tx = d[k[0]];
      switch (k[1]){
        case "moneda":
          tx = formatter.format(tx);
          break;
        case "fecha":
          if(isNaN(tx)){
            tx = dateformatter(tx) 
          }
           break;
        default:
          break;
      
      }
      return tx;
    });
    return reg;
  });
  pageData = pageData.reverse();
  pageData.unshift(header);
  return pageData;
}

import React, { useState, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage, useFormikContext } from "formik";
import * as Yup from "yup";
import { Row, Col, FormGroup, Label, Button, Collapse } from "reactstrap";
import { toast } from "react-toastify";
import Notification from "../../../components/Notification/Notification";
import ModalConfirmation from "../../../components/Modal/ModalConfirmation";
import { peticionesReceiver } from "../../../helpers/peticionesReceiver";
import TableComponentOnePage from "../../../components/TableComponent/TableComponentOnePage";
import { getParamsExperiencia } from "./functions";
import { YearInput } from "../../../components/GenericInputsFormik/YearInput";
import { fDateInput } from "../../../libs/tools/format";
import { NumberInput } from "../../../components/GenericInputsFormik/NumberInput";

const defaultForm = {
  idStaffWork: "",
  idStaff: "",
  employeer: "",
  position: "",
  startDate: "",
  endDate: "",
  public: false,
  notes: "",
};
const cYear = new Date().getFullYear();
const FormSchema = Yup.object().shape({
  employeer: Yup.string()
    .min(3, "Nombre de la institución muy corto")
    .max(200, "Nombre de la institución invalido")
    .required("Ingrese el nombre de la institución"),

  position: Yup.string()
    .min(3, "Nombre de puesto muy corto")
    .max(200, "Nombre de puesto invalido")
    .required("Ingrese el nombre del puesto"),

  startDate: Yup.number()
  .required("Seleccione una fecha de inicio")
  .max(cYear, "Año inválido"),
  endDate: Yup.number()
    .required("Seleccione una fecha de termino")
    .min(Yup.ref("startDate"), "Año de término debe ser mayor o igual a la de inicio")
    .max(cYear, "Año inválido"),
});

const PersonalExperiencia = ({ idStaff, permissions, staffValid, setUpload, upload, setActiveTab }) => {
  const API = peticionesReceiver();
  const [isOpen, setIsOpen] = useState(false);
  const [modal, setModal] = useState(false);
  const [title, setTitle] = useState("");
  const [info, setInfo] = useState(defaultForm);
  const [editando, setEditando] = useState(false);

  const [experiencia, setExperiencia] = useState(false);

  const cabeceras = [
    "Id",
    "Puesto",
    "Institución/Empresa",
    "A. Inicio",
    "A. Termino",
    "Sector",
    "Editar",
    "Eliminar",
  ];
  const filtro = [true, true, true, true, true, true];
  const [parametros, setParametros] = useState({
    action: "datatable",
    table: "staffworks", ///DATE_FORMAT(startDate,'%d/%m/%Y') as datForm
    rows: "idStaffWork,position,employeer,YEAR(startDate) AS startDate,YEAR(endDate) AS endDate,if(public='1','Publico','Privado') as isPublic,notes,idStaff",
    conditions: `enabled = 1 AND idStaff=${idStaff}`,
    page: 0,
    records: 5,
    search: "",
    order: "created desc",
  });

  useEffect(() => {
    if (staffValid !== null && upload !== null && !upload.exp) {
      setExperiencia(staffValid.IMSS.historialLaboral)
    }
  }, [staffValid])

  useEffect(() => {
    if (experiencia.length > 0) {
      openForm()
      const params = getParamsExperiencia(idStaff, experiencia[0])
      setInfo(params)
    } else {
      setInfo(defaultForm)
    }
  }, [experiencia])

  const editInfo = (values, reset) => {
    const rows = {
      idStaff: values.idStaff | idStaff,
      employeer: values.employeer,
      position: values.position,
      startDate: fDateInput(new Date(values.startDate, 0, 1)),
      endDate: fDateInput(new Date(values.endDate, 0, 1)),
      public: values.public,
      notes: values.notes,
    };
    const params = {
      action: "",
      table: "staffworks",
      rows: rows,
    };
    if (values.idStaffWork) {
      params.action = "update";
      params.condition = { idStaffWork: values.idStaffWork };
    } else {
      params.action = "insert";
    }
    API.peticion(params)
      .then((res) => {
        if (res.status === 200 && res.data.code === "200") {
          toast(
            <Notification
              type={"modifica_exito"}
              backMessage={res.data.message}
              withIcon
            />
          );
          setParametros({ ...parametros });
          setTitle("");
          setIsOpen(false);
          reset();
          if (staffValid !== null) {
            const expTemp = experiencia.slice(1)
            setExperiencia(expTemp)
            if (expTemp.length > 0) {
              toast(
                <Notification
                  type={"info"}
                  backMessage={`Quedan ${expTemp.length} registros por agregar`}
                  withIcon
                />
              );
            }
            if (expTemp.length === 0) {
              const uppload = JSON.parse(JSON.stringify(upload));
              uppload.exp = true;
              setUpload(uppload)
              setActiveTab(9)
            }
          }
        } else {
          toast(
            <Notification
              type={"modifica_error"}
              backMessage={res.data.message}
              withIcon
            />
          );
        }
      })
      .catch((err) => {
        toast(<Notification type={"consultar_servidor_error"} withIcon />);
      });
  };

  const openForm = () => {
    setTitle("Agregar empleo");
    setIsOpen(true);
  };

  const getForEdit = (el) => {
    if (title === "") {
      setInfo({
        idStaffWork: el[0],
        idStaff: el[7],
        employeer: el[2],
        position: el[1],
        startDate: el[3],
        endDate: el[4],
        public: el[5] === "Publico" ? true : false,
        notes: el[6],
      });
      setTitle("Editar empleo");
      setEditando(true);
      setIsOpen(true);
    } else {
      toast(
        <Notification
          type="warning"
          backMessage="Guarde sus cambios antes de continuar"
        />,
        { closeButton: false }
      );
    }
  };

  const limpia = (reset) => {
    setTitle("");
    reset()
    setEditando(false)
    setIsOpen(false);
  }

  return (
    <div>
      <Formik
        initialValues={defaultForm}
        validationSchema={FormSchema}
        onSubmit={() => {
          setModal(true);
        }}
        onReset={() => { }}
      >
        {({ errors, touched, values, resetForm, setFieldValue, handleBlur }) => (
          <div>
            <TableComponentOnePage
              titulo={"Experiencia laboral"}
              cabecerasTabla={cabeceras}
              filtro={filtro}
              editar={getForEdit}
              parametros={parametros}
              limpia={limpia}
              reset={resetForm}
              permissions={permissions}
            />

            {permissions.INS && <div className="d-grid d-flex justify-content-center justify-content-sm-end">
              <Button
                color="add"
                onClick={() => openForm()}
                disabled={title !== ""}
              >
                Agregar
              </Button>
            </div>}

            <div className="col-12 col-sm-6"></div>
            <Collapse isOpen={isOpen}>
              <Col className="d-grid d-flex justify-content-sm-end justify-content-center mt-3">
                <h6 className="text-center">
                  Estado:{" "}
                  <span className="text-primary">
                    {editando ? "Editando" : "Guardando"}
                  </span>
                </h6>
              </Col>
              <Form className="mt-4">
                <ModalConfirmation
                  modalTitle={title}
                  modal={modal}
                  setModal={setModal}
                  editar={editInfo}
                  isEdit={true}
                  values={values}
                  reset={resetForm}
                >
                  <div className="d-flex justify-content-center">
                    <h6>¿Está seguro de guardar el registro?</h6>
                  </div>
                </ModalConfirmation>
                <Row>
                  <Col xs={12} lg={6}>
                    <FormGroup>
                      <Label for="position">Puesto <span className="text-danger">*</span></Label>
                      <Field
                        type="text"
                        name="position"
                        className={
                          "form-control" +
                          (errors.position && touched.position
                            ? " is-invalid"
                            : "")
                        }
                      />
                      <ErrorMessage
                        name="position"
                        component="div"
                        className="invalid-feedback"
                      />
                    </FormGroup>
                  </Col>
                  <Col xs={12} lg={6}>
                    <FormGroup>
                      <Label for="employeer">Institución o empresa <span className="text-danger">*</span></Label>
                      <Field
                        type="text"
                        name="employeer"
                        className={
                          "form-control" +
                          (errors.employeer && touched.employeer
                            ? " is-invalid"
                            : "")
                        }
                      />
                      <ErrorMessage
                        name="employeer"
                        component="div"
                        className="invalid-feedback"
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} lg={6}>
                    {/* <YearInput
                      label="Año de Inicio"
                      inputName="startDate"
                      onChangeMethod={({target})=>setFieldValue("startDate", target.value)}
                      onBlurMethod={handleBlur}
                      value={values.startDate}
                      touched={touched.startDate}
                      errors={errors.startDate}
                      isRequired
                    /> */}
                    <NumberInput
                        label="Año de Inicio"
                        inputName="startDate"
                        onChangeMethod={({target})=>setFieldValue("startDate", target.value)}
                        onBlurMethod={handleBlur}
                        value={values.startDate}
                        touched={touched.startDate}
                        errors={errors.startDate}
                        maxLength={4}
                        isMax={cYear}
                        disableSep
                        isRequired
                    />
                  </Col>
                  <Col xs={12} lg={6}>
                    {/* <YearInput
                      label="Año de Termino"
                      inputName="endDate"
                      onChangeMethod={({target})=>setFieldValue("endDate", target.value)}
                      onBlurMethod={handleBlur}
                      value={values.endDate}
                      touched={touched.endDate}
                      errors={errors.endDate}
                      isRequired
                      isMin={values.startDate}
                      isDisabled={!values.startDate}
                    /> */}
                    <NumberInput
                      label="Año de Termino"
                      inputName="endDate"
                      onChangeMethod={({target})=>setFieldValue("endDate", target.value)}
                      onBlurMethod={handleBlur}
                      value={values.endDate}
                      touched={touched.endDate}
                      errors={errors.endDate}
                      maxLength={4}
                      isMax={cYear}
                      isMin={values.startDate}
                      isDisabled={!values.startDate}
                      disableSep
                      isRequired
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} lg={6}>
                    <FormGroup>
                      <Label for="public">Sector público</Label>
                      <Field name="public">
                        {({ field }) => (
                          <div className="form-check checkbox checkbox-primary d-flex justify-content-center">
                            <input
                              {...field}
                              id="chk"
                              type="checkbox"
                              className="styled"
                              checked={field.value}
                            />
                            <label htmlFor="chk" />
                          </div>
                        )}
                      </Field>
                    </FormGroup>
                  </Col>
                  <Col xs={12} lg={6}>
                    <FormGroup>
                      <Label for="notes">Notas </Label>
                      <Field
                        as="textarea"
                        name="notes"
                        className="form-control"
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <div className="row mt-3">
                  <div className="col-sm-6 order-2 order-sm-1 text-center text-sm-left mb-4">
                    <Button
                      color="danger"
                      type="reset"
                      onClick={() => limpia(resetForm)}
                    >
                      Cancelar
                    </Button>
                  </div>
                  <div className="col-sm-6 order-1 order-sm-2 text-center text-sm-right mb-4">
                    <Button color="success" type="submit">
                      Guardar
                    </Button>
                  </div>
                </div>
                <FormikData info={info} />
              </Form>
            </Collapse>
          </div>
        )}
      </Formik>
    </div>
  );
};

const FormikData = ({ info }) => {
  const { setFieldValue } = useFormikContext();
  useEffect(() => {
    if (info) {
      setFieldValue("idStaffWork", info.idStaffWork);
      setFieldValue("idStaff", info.idStaff);
      setFieldValue("employeer", info.employeer);
      setFieldValue("position", info.position);
      setFieldValue("startDate", info.startDate);
      setFieldValue("endDate", info.endDate);
      setFieldValue("public", info.public);
      setFieldValue("notes", info.notes);
    }
  }, [info]);
  return null;
};

export default PersonalExperiencia;

import { Row, Col, Button } from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import { SelectTypeHeadSingle, TextInput } from "../../../../components/GenericInputsFormik";
import { NumberInput } from "../../../../components/GenericInputsFormik/NumberInput";
import Datatable from "../../../../components/Datatable/Datatable";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Notification from "../../../../components/Notification/Notification";

export default function FormBeneficiarios({ data, setData, relaciones }) {
    const [refresh, setRefresh] = useState(false);
    const [lcData, setLcData] = useState([]);
    const [flag, setFlag] = useState(false);
    const [isEdit, setIsEdit] = useState(-1);
    const [edited, setEdited] = useState("");

    useEffect(() => {
        if (data.length > 0) {
            const sc = [];
            data.forEach(row => {
                if (+row.enabled === 1) {
                    sc.push({
                        ...row,
                        relacion: relaciones.find(r => +r.keyRelationshipType === +row.keyRelationshipType)?.nameRelationshipType || ""
                    });
                }
            })
            setLcData(sc);
        } else setLcData([]);
    }, [data]);

    useEffect(() => {
        if (flag) setRefresh(true);
        else setFlag(true);
    }, [lcData]);

    /* FORM */
    const FormSchema = Yup.object().shape({
        nameBenefit: Yup.string().required("Ingrese un nombre"),
        pNameBenefit: Yup.string().required("Ingrese el primer apellido"),
        mNameBenefit: Yup.string().required("Ingrese el segundo apellido"),
        porcentage: Yup.number().test({//.required("Ingrese un procentaje")
            name: "acum-percent", 
            message: "El acumulado de procentajes es mayor a 100%",
            test: (value, cx) => acumPercent(value ? +value : 0)
        }),
        // keyRelationshipType: Yup.string().required("Seleccione un parentesco"),
    });

    function acumPercent(val){
        const acum = data.reduce((acc, d, ix) => acc + ((+d.enabled !== 0 && (isEdit !== ix)) ? +d.porcentage : 0), 0);
        return val + acum <= 100;
    }

    const {
        handleSubmit,
        handleReset,
        values,
        handleBlur,
        errors,
        touched,
        setFieldValue,
        setValues,
    } = useFormik({
        initialValues: defaultForm,
        onSubmit: () => saveBeneficiary(),
        onReset: () => setIsEdit(-1),
        validationSchema: FormSchema,
        enableReinitialize: true,
    });

    function onChange({ target }) {
        setFieldValue(target.name, target.value);
    }

    function saveBeneficiary() {
        const cpyD = [...data]
        if (isEdit > -1) cpyD[isEdit] = { ...values };
        else cpyD.push(values);
        setData("beneficiary", cpyD);
        handleReset();
    }

    function editBeneficiary(row) {
        if (JSON.stringify(defaultForm) !== JSON.stringify(values)) {
            toast(
                <Notification type="warning" backMessage="Guarde el beneficiario antes de continuar" />,
                { closeButton: false }
            );
            return;
        }
        const ewe = { ...row }
        delete ewe.relacion;
        setValues({ ...ewe }, true);
        const ix = data.findIndex((d) => JSON.stringify(d) === JSON.stringify(ewe));
        setIsEdit(ix);
        setEdited(JSON.stringify(ewe));
    }

    function removeBeneficiary(row) {
        const ewe = { ...row };
        delete ewe.relacion;
        let cpy = [...data];
        if (row.idStaffInsuranceBeneficiary) {
            const fnd = cpy.find(d => +d.idStaffInsuranceBeneficiary === +row.idStaffInsuranceBeneficiary);
            if (cpy) fnd.enabled = 0;
        } else {
            cpy = data.filter(d => JSON.stringify(d) !== JSON.stringify(ewe));
        }
        if(isEdit > -1) {
            const ix = data.findIndex((d) => JSON.stringify(d) === JSON.stringify(ewe));
        }
        if(edited === JSON.stringify(ewe)) handleReset();
        setData("beneficiary", cpy);
    }
    /* END FORM */

    /* TABLE */
    const columns = [
        { data: null, render: (row) => `${row.nameBenefit} ${row.pNameBenefit} ${row.mNameBenefit}` },
        { data: "relacion" },
        { data: "porcentage", format: "percent" },
        {
            data: null,
            render: (row) => (
                <div>
                    <i className="fa fa-pencil text-warning cursor-pointer px-3" onClick={() => editBeneficiary(row)} />
                    <i className="fa fa-trash-o text-danger cursor-pointer px-3" onClick={() => removeBeneficiary(row)} />
                </div>
            )
        }
    ]
    /* END TABLE */

    return (
        <div className="mt-2 border border p-2 rounded mb-2 bg-light">
            <div>
                <h5 className="border-bottom pb-2">Beneficiarios</h5>
            </div>
            <Row>
                <Col xs={12} md={12} lg={4}>
                    <TextInput
                        label="Nombre"
                        inputType="text"
                        inputName="nameBenefit"
                        onChangeMethod={onChange}
                        onBlurMethod={handleBlur}
                        value={values.nameBenefit}
                        touched={touched.nameBenefit}
                        errors={errors.nameBenefit}
                        isRequired
                    />
                </Col>
                <Col xs={12} md={12} lg={4}>
                    <TextInput
                        label="Primer apellido"
                        inputType="text"
                        inputName="pNameBenefit"
                        onChangeMethod={onChange}
                        onBlurMethod={handleBlur}
                        value={values.pNameBenefit}
                        touched={touched.pNameBenefit}
                        errors={errors.pNameBenefit}
                        isRequired
                    />
                </Col>
                <Col xs={12} md={12} lg={4}>
                    <TextInput
                        label="Segundo apellido"
                        inputType="text"
                        inputName="mNameBenefit"
                        onChangeMethod={onChange}
                        onBlurMethod={handleBlur}
                        value={values.mNameBenefit}
                        touched={touched.mNameBenefit}
                        errors={errors.mNameBenefit}
                        isRequired
                    />
                </Col>
                <Col xs={12} md={12} lg={4}>
                    <SelectTypeHeadSingle
                        label="Relación"
                        inputName="keyRelationshipType"
                        optionsArray={relaciones}
                        defaultOption="Seleccione un concepto"
                        onChangeMethod={onChange}
                        onBlurMethod={handleBlur}
                        value={values.keyRelationshipType}
                        touched={touched.keyRelationshipType}
                        errors={errors.keyRelationshipType}
                        optionValue="keyRelationshipType"
                        optionName="nameRelationshipType"
                    />
                </Col>
                <Col xs={12} md={12} lg={4}>
                    <NumberInput
                        label="Porcentaje"
                        inputName="porcentage"
                        onChangeMethod={onChange}
                        onBlurMethod={handleBlur}
                        value={values.porcentage}
                        errors={errors.porcentage}
                        touched={touched.porcentage}
                        isMin={1}
                        isMax={100}
                        maxLength={5}
                        decimals
                        suffix="%"
                    />
                </Col>
            </Row>
            <div className=" d-flex flex-md-row flex-column-reverse justify-content-md-around mb-1">
                <Button color="danger" type="button" className="mt-3" onClick={() => handleReset()}>
                    Cancelar
                </Button>
                <Button color="success" type="button" className="mt-2 mt-md-3" onClick={() => handleSubmit()}>
                    Guardar
                </Button>
            </div>

            <Datatable
                headers={["Nombre", "Parentesco", "Porcentaje", "Acciones"]}
                columns={columns}
                columnDefs={columnDefs}
                data={lcData}
                control="front"
                stateRefresh={[refresh, setRefresh]}
                searching={false}
                paging={false}
                className="px-0 mb-2"
            />

        </div>
    );
}

const defaultForm = {
    idStaffInsuranceBeneficiary: "",
    nameBenefit: "",
    pNameBenefit: "",
    mNameBenefit: "",
    porcentage: "",
    noCertificate: "",
    keyRelationshipType: "",
    enabled: 1
};

const columnDefs = [
    { targets: [2], className: "text-right" },
    { targets: [1, 3], className: "text-center" }
]

import React, {useState} from 'react'
import TableComponent from "../../../components/TableComponent/TableComponentFiltro";

const Bancos = () => {
    const [cabeceras] = useState([
        "Id",              
        "Nombre del banco",
        "Clave del banco",
        "Editar",
        "Eliminar"  
      ]);
    const [filtro] = useState([true, true, true, true, true]);
    const [parametros] = useState({
      action: "datatable",
      table: "banks",
      rows: "keyBank,nameBank,codeBank",
      conditions: "enabled = 1",
      page: 0,
      records: 5,
      search: "",
      order: "",
    });
  return (
    <div>
        <TableComponent
            titulo="Bancos"
            cabecerasTabla={cabeceras}
            filtro={filtro}
            rutaCrear="/template/bancos/crear"
            rutaEditar="/template/bancos/editar"
            parametros={parametros}
        ></TableComponent>
  </div>
  )
}

export default Bancos
import React, { useState } from "react";
import { toast } from "react-toastify";
import Notification from "../../../components/Notification/Notification";
import { useHistory } from "react-router-dom";
import Loader from "../../../components/Loader/Loader";
import { Button, Col, Row } from "reactstrap";
import { useFormik } from "formik";
import axios from "axios";
import ModalConfirmation from "../../../components/Modal/ModalConfirmation";

const PresupuestosImportacionCSV = () => {
  const history = useHistory();
  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [invalidFile, setInvalidFile] = useState(false);
  const [layoutError, setLayoutError] = useState("");

  const formDatos = {
    binary: "",
  };

  const enviaDatos = () => {
    if (values.binary === "") {
      setLayoutError("Ingrese un archivo CSV");
    } else {
      setModal(true);
    }
  };

  const crear = async () => {
    if (!invalidFile) {
      setLoading(true);
      const formData = new FormData();
      formData.append("action", "importCSV");
      formData.append(
        "idAnnualBudget",
        Number(localStorage.getItem("idDetail"))
      ); 
      formData.append("binary", values.binary);
      const params = {
        method: "post",
        url: `${process.env.REACT_APP_API}app/facades/budgets/budgetsF.php`,
        data: formData,
      };
      await axios(params)
        .then((res) => {
          if (res.status === 200 && res.data.code === "200") {
            history.push({
              pathname: "/template/presupuestos/presupuestos-dependientes",
            });
          } else {
            toast(
              <Notification
                type={"consultar_error"}
                backMessage={"Su archivo no cumple con los requisitos"}
                withIcon
              />
            );
            setLayoutError("Error en el archivo CSV cargado");
          }
        })
        .catch((err) => {
          toast(<Notification type={"agrega_error"} withIcon />);
        });
    }
  };

  const goBack = () => {
    resetForm();
    history.push({
      pathname: "/template/presupuestos/presupuestos-dependientes",
    });
  };

  const onChange = (e) => {
    console.log(e.target.files[0].type)
    if (
      (e.target.files[0].type !== "text/csv" ||
        e.target.files[0].size > 5000000)
    ) {
      setInvalidFile(true);
    }
    setFieldValue([e.target.name], e.target.files[0]);
  };

  const { handleSubmit, values, setFieldValue, resetForm } = useFormik({
    initialValues: formDatos,
    onSubmit: () => {
      enviaDatos();
    },
    enableReinitialize: true,
  });

  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <form onSubmit={handleSubmit} className="mt-4" noValidate>
          <ModalConfirmation
            modalTitle={"Crear"}
            modal={modal}
            setModal={setModal}
            crear={crear}
            isEdit={false}
            values={values}
          >
            <div className="d-flex justify-content-center">
              <h6>¿Desea continuar con el registro?</h6>
            </div>
          </ModalConfirmation>
          <Row>
            <Col xs={12}>
              <label htmlFor="file">Ingrese un archivo</label>
              <input
                disabled={values.keyFileType === ""}
                type="file"
                name="binary"
                onChange={onChange}
                className={
                  "form-control" +
                  (invalidFile && invalidFile ? " is-invalid" : "")
                }
                required
              />
              {invalidFile && (
                <span style={{ color: "red" }}>
                  Ingrese un archivo tipo CSV y menor a 5MB
                </span>
              )}
              {layoutError !== "" && (
                <span style={{ color: "red" }}>{layoutError}</span>
              )}
            </Col>
          </Row>
          <div className="row mt-3">
            <div className="col-sm-6 order-2 order-sm-1 text-center text-sm-left mb-4">
              <Button color="danger" type="reset" onClick={() => goBack()}>
                {" "}
                Cancelar{" "}
              </Button>
            </div>

            <div className="col-sm-6 order-1 order-sm-2 text-center text-sm-right mb-4">
              <Button color="success" type="submit">
                {" "}
                Guardar{" "}
              </Button>
            </div>
          </div>
        </form>
      )}
    </div>
  );
};
export default PresupuestosImportacionCSV;

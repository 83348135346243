import { useEffect, useState } from "react";
import { Spinner, } from "reactstrap";
import { peticionesReceiver } from "../../../helpers/peticionesReceiver";
import axios from "axios";
import { toast } from "react-toastify";
import Notification from "../../../components/Notification/Notification";
import { format } from "date-fns"

export default function InfoAsistencia({ before, user }) {
    const API = peticionesReceiver()
    const [loading, setLoading] = useState(true)
    const [loadingPet, setLoadingPet] = useState(true)
    const [privateIP, setPrivate] = useState("")
    const [publicIP, setPublic] = useState("")
    const [latitude, setLatitude] = useState("")
    const [longitude, setLongitude] = useState("")

    async function getPublicIP() {
        
        try {
            const res = await API.peticion({ action: "getIp" });
            if (res.status === 200 && res.data.code === "200") {
                setPublic(res.data.data)
            } else {
                toast(<Notification type={"consultar_error"} backMessage={"Sin registros"} withIcon />);
            }
        } catch (err) {
            console.log(err);
            toast(<Notification type={"consultar_servidor_error"} withIcon />);
        }
    }

    function getLocation() {
        try {
            navigator.geolocation.getCurrentPosition(function (position) {
                setLatitude(position.coords.latitude)
                setLongitude(position.coords.longitude)
            });
        } catch (err) {
            toast(<Notification type={"consultar_error"} backMessage={"No se pudo obtener la posición actual"} withIcon />);
        }

    }

    useEffect(() => {
        getPublicIP()
        getLocation()
        try {
            window.RTCPeerConnection = window.RTCPeerConnection || window.mozRTCPeerConnection || window.webkitRTCPeerConnection;   //compatibility for firefox and chrome
            let pc = new RTCPeerConnection({ iceServers: [] }), noop = function () { };
            pc.createDataChannel("");    //create a bogus data channel
            pc.createOffer(pc.setLocalDescription.bind(pc), noop);    // create offer and set local description
            pc.onicecandidate = function (ice) {  //listen for candidate events
                if (!ice || !ice.candidate || !ice.candidate.candidate) return;
                const myIP = /([0-9]{1,3}(\.[0-9]{1,3}){3}|[a-f0-9]{1,4}(:[a-f0-9]{1,4}){7})/.exec(ice.candidate.candidate)[1];
                setPrivate(myIP)
                pc.onicecandidate = noop;
            };
        } catch (err) {
            toast(<Notification type={"consultar_error"} backMessage={"No se pudo obtener la dirección IP privada"} withIcon />);
        }
    }, [])

    useEffect(() => {
        setLoading(!(privateIP !== "" && publicIP !== "" && latitude !== "" && longitude !== ""))
    }, [privateIP, publicIP, latitude, longitude])

    async function registrarAsistencia() {
        setLoadingPet(true)
        const datos = {
            idStaff: user.idStaff,
            idJobStaff: user.idJobStaff,
            assistDate: format(new Date(), "yyyy-MM-dd HH:mm:ss"),
            latitud: latitude.toString(),
            longitud: longitude.toString(),
            localIP: privateIP,
            publicIP: publicIP
        }
        const data = {
            action: "insert",
            table: "assists",
            rows: datos,
            validate: [["idStaff", "assistDate"]],
        };
        try {
            const res = await API.peticion(data);
            if (res.status === 200 && res.data.code === "200") {
                setLoadingPet(false);
                setTimeout(() => {
                    before()
                }, 2000)
            } else {
                toast(<Notification
                    type={"agrega_error"}
                    backMessage={res.data.message}
                    withIcon
                />);
            }
        } catch (err) {
            toast(<Notification type={"consultar_servidor_error"} withIcon />);
        }

    }

    useEffect(() => {
        if (!loading) {
            registrarAsistencia()
        }
    }, [loading])

    return (
        <>
            <h6>HOLA, {user.name} {user.pName} {user.mName}</h6>
            {loading ? (
                <div className="d-flex flex-column align-items-center justify-content-center" style={{ minHeight: "300px" }}>
                    <Spinner animation="grow" variant="primary" />
                    <h3 className="mt-5">Obteniendo información de control...</h3>
                </div>
            ) : (
                <>
                    {loadingPet ? (
                        <div className="d-flex flex-column align-items-center justify-content-center" style={{ minHeight: "300px" }}>
                            <Spinner animation="grow" variant="primary" />
                            <h3 className="mt-5">Registrando asistencia...</h3>
                        </div>
                    ) : (
                        <div className="text-center my-5">
                            <i className="fa fa-check-circle text-success" style={{ fontSize: "80px" }} />
                            <h3>Tu registro de asistencia a sido éxitosa</h3>
                        </div>
                    )}
                </>
            )
            }
        </>
    )
}
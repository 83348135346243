import { peticionesReceiver } from "../../../helpers/peticionesReceiver";
const API = peticionesReceiver();

export const createSchedule = async (schedule) => {
  if (schedule) {
    const params = {
      action: "insert",
      table: "schedulerules",
      rows: schedule,
      order: "idJobSchedule asc",
      validate: [
        ["idJobSchedule","keyTypeScheduleRule"]
    ]
    };
    try {
      const res = await API.peticion(params)
      if (res.status === 200 && res.data.code === "200") {
        return {success: true}
      }
      else{
        return {success: false, message: res.data.message}
      }
    } catch (error) {
      return {success: false, message: error}
    }
  }
};

export const getSchedule = async (nameJobSchedule) => {
  const paramsJobs = {
    action: "select",
    table: 'jobschedule',
    condition: {
      enabled: 1,
      nameJobSchedule: nameJobSchedule
    }
  }
  try {
    const result = await API.peticion(paramsJobs)
    if (result.status === 200 && result.data.code === "200") {
      const idJobSchedule = result.data.data[0].idJobSchedule

      const paramsRules = {
        action: "select",
        table: 'schedulerules',
        condition: {
          enabled: 1,
          idJobSchedule: idJobSchedule
        }
      };
      const response = await API.peticion(paramsRules)
      if(response.status === 200 && response.data.code === "200"){ 
        const info = {
          idJobSchedule:idJobSchedule,
          array: response.data.data
        }
        return {success: true, info}
      }
      else {
        return {success: false, message: response.data.message}
      }
    }
    else{
      return {success: false, message: result.data.message}
    }
  } catch (error) {
    return {success: false, message: error}
  }
}

export const deleteSchedule = async (idScheduleRule) => {
  if (idScheduleRule) {
    const params = {
      action: "delete",
      table: "schedulerules",
      condition: {
        idScheduleRule: idScheduleRule,
      },
      force: '1',
    };
    try {
      const res = await API.peticion(params)
      if (res.data.status === "success" && res.data.code === "200") {
        return {success: true,message:res.data.message}
      }
      else{
        return {success: false, message: res.data.message}
      }
    } catch (error) {
      return {success: false, message: error}
    }
  }
};
import React, { useState, useEffect } from "react";
import { Button, Modal, ModalHeader, ModalBody } from "reactstrap";
import DownloadContainer from "../Pdf/DownloadContainer";
import { StyleSheet, PDFViewer, PDFDownloadLink } from "@react-pdf/renderer";
import ClockLoader from "react-spinners/ClockLoader";
import { isMobile } from "react-device-detect"

function ModalReports(props) {
  const {
    modal,
    setModal,
    backdrop, // true, false, "static"
    keyboard, // true, false
    // btnClose, // true, false
    title,
    report,
    pdfConfig,
    generatePdfMethod,
    records,
    // PdfComponent,
    component
  } = props;

  const [current, setCurrent] = useState(0);

  const stylesTable = StyleSheet.create({
    viewer: {
      width: "100%", //the pdf viewer will take up all of the width and height
      height: window.innerHeight,
    },
  });

  const [pdfData, setPdfData] = useState();

  const toggle = () => {
    setModal(!modal);
  };

  const [generateReports, setGenerateReports] = useState(false);
  const [subReports, setSubReports] = useState([]);
  const [pdfSubReportsGenerated, setPdfSubReportsGenerated] = useState([]);
  const [finished, setFinished] = useState(true);

  const onToggleFinished = () => {
    setFinished(!finished);
  };

  useEffect(() => {
    if (modal === true) {
      preparaPdf();
    }
  }, [modal]);

  const preparaPdf = async () => {
    let rep = splitReports();
    generatedDocumentforCompiler(rep);
  };

  /* Divide los documentos */
  const splitReports = () => {
    let rep = [];
    if (report.length / records > 1) {
      let inicio = 0;
      let fin = records;
      while (inicio < report.length) {
        rep.push(report.slice(inicio, fin));
        inicio = fin;
        fin += records;
      }

      setSubReports(rep); ///----------
    }
    return rep;
  };

  /* Genera configuracion que pasa al compilador */
  const generatedDocumentforCompiler = async (rep) => {
    let reportAnt = {};
    let noRegistro = 0;
    let repsGenerateds = [];
    switch (generatePdfMethod.type) {
      case "general":
        if (rep.length !== 0) {
          rep.map(async (r, index) => {
            reportAnt = generatePdfMethod.method(
              r,
              pdfConfig,
              title,
              `Archivo ${index + 1} de ${rep.length}`,
              noRegistro
            );
            noRegistro = reportAnt.contador;
            repsGenerateds.push(reportAnt);
          });
          setPdfSubReportsGenerated(repsGenerateds);
        } else {
          setPdfData(
            await generatePdfMethod.method(
              report,
              pdfConfig,
              title,
              `Archivo 1 de 1`,
              noRegistro
            )
          );
        }
        break;
      case "conceptoNomina":
        let totalNet = 0;
        let totalAmount = 0;
        if (rep.length !== 0) {
          rep.map(async (r, index) => {
            reportAnt = generatePdfMethod.method(
              r,
              pdfConfig,
              title,
              `Archivo ${index + 1} de ${rep.length}`,
              totalNet,
              totalAmount,
              index === rep.length - 1 ? true : false
            );
            totalNet = reportAnt.totalneto;
            totalAmount = reportAnt.totalamount;
            repsGenerateds.push(reportAnt);
          });
          setPdfSubReportsGenerated(repsGenerateds);
        } else {
          setPdfData(
            await generatePdfMethod.method(
              report,
              pdfConfig,
              title,
              `Archivo 1 de 1`
            )
          );
        }
        break;
      case "coutasAportacionesIssemym":
        let acumTotals = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        if (rep.length !== 0) {
          rep.map(async (r, index) => {
            reportAnt = generatePdfMethod.method(
              r,
              pdfConfig,
              title,
              `Archivo ${index + 1} de ${rep.length}`,
              index === rep.length - 1 ? true : false,
              acumTotals
            );
            acumTotals = reportAnt.acumTotals;
            repsGenerateds.push(reportAnt);
          });
          setPdfSubReportsGenerated(repsGenerateds);
        } else {

          setPdfData(
            await generatePdfMethod.method(
              report,
              pdfConfig,
              title,
              `Archivo 1 de 1`
            )
          );
        }
        break;

      default:
        if (rep.length !== 0) {
          rep.map(async (r, index) => {
            reportAnt = await generatePdfMethod.method(
              r,
              pdfConfig,
              title,
              `Archivo ${index + 1} de ${rep.length}`
            );
            repsGenerateds.push(reportAnt);
          });
          setPdfSubReportsGenerated(repsGenerateds);
        } else {
          setPdfData(
            await generatePdfMethod.method(
              report,
              pdfConfig,
              title,
              `Archivo 1 de 1`
            )
          );
          break;
        }
    }
  };
  useEffect(() => {
    if (modal === false) {
      setGenerateReports(false);
    }
  }, [modal]);

  return (
    <>
      <Modal
        isOpen={modal}
        backdrop={backdrop}
        keyboard={keyboard}
        toggle={toggle}
        scrollable={true}
        style={{ minWidth: "95%", maxHeight: "90%" }}
      >
        <ModalHeader className="pl-5"></ModalHeader>
        <ModalBody className="mx-2">
          <div
            className="text-secondary"
            style={{ fontSize: "2vw", textAlign: "center" }}
          >
            Reporte PDF
          </div>
          {generateReports !== true ? (
            <>
              <div
                className="text-secondary"
                style={{ fontSize: "2vw", textAlign: "center" }}
              >
                Se generaran{" "}
                {subReports.length === 0
                  ? `1 reporte`
                  : `${subReports.length} reportes`}
                , este proceso demorara unos minutos
              </div>
              <div
                className="text-secondary mb-3"
                style={{ fontSize: "2vw", textAlign: "center" }}
              >
                ¿Desea continuar con el proceso?
              </div>
            </>
          ) : subReports.length === 0 ? (
            <>
              {isMobile ? (
                <PDFDownloadLink document={pdfData.report}>
                  {({ blob, url, loading, error }) =>
                    loading ? (
                      <div className="text-center">Generando su documento...</div>
                    ) :
                      <>
                        <div className="text-center px-3 pt-3">
                          <i className="fa fa-download" style={{ fontSize: "80px" }} />
                        </div>
                        <div className="text-center px-3" style={{ fontSize: "20px" }} >
                          Descargar
                        </div>
                      </>
                  }
                </PDFDownloadLink>
              ) : (
                <PDFViewer style={stylesTable.viewer} document={pdfData}>
                  {pdfData.report}
                </PDFViewer>
              )}

            </>
          ) : (
            <>
              {pdfSubReportsGenerated.map((rep, index) => (
                <div key={index}>
                  {index === current && (
                    <>
                      {console.log("Entrando a generar reporte ", index + 1)}

                      <DownloadContainer
                        pdf={rep.report}
                        finished={onToggleFinished}
                        fileName={`${title}_${index + 1} de ${subReports.length
                          }`}
                        showFileName={`${title} ${index + 1} de ${subReports.length
                          }`}
                        current={current}
                        setCurrent={setCurrent}
                        index={index}
                      />
                    </>
                  )}
                </div>
              ))}

              {current < pdfSubReportsGenerated.length && (
                <div className="d-flex justify-content-center">
                  <ClockLoader size={60} color="#4D53E0" loading={true} />
                </div>
              )}
            </>
          )}
          <div className="text-center mb-3">
            {!generateReports && (
              <Button
                color="success"
                onClick={() => setGenerateReports(true)}
              >
                Generar Reporte
              </Button>
            )}
          </div>
          {component !== undefined && generateReports && component()}
          <div className="text-center mb-3">
            <Button color="danger" onClick={toggle}>
              Cerrar
            </Button>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
}
export default ModalReports;

import React, { useState, useEffect } from "react";
import Loader from "../Loader/Loader";
import axios from "axios";
import {
  Col,
  Row,
  Table,
  Pagination,
  PaginationItem,
  PaginationLink,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";

import s from "../../pages/tables/Tables.module.scss";
import sc from "./TableComponentFiltro.module.scss";
import { toast } from "react-toastify";

import { peticionEncript } from "../../helpers/peticionesEncripted";

import { saveAsExcel } from "../excel/Excel";
import ModalReports from "../Modal/ModalReports";
import Notification from "../Notification/Notification";
import { DateInput, SearchInput } from "../GenericInputsFormik";
import { generatePdfPresupuestoAnual } from "./Pdf/presupuestosAnualesPdf";
import { fMoney } from "../../libs/tools/format";

/**
 * @param {Object} props
 * @param {string} props.titulo - Titulo de la tabla
 * @param {Array<string>} props.cabecerasTabla - Arreglo con las cabeceras de la tabla
 * @param {Array<boolean>} props.filtro - Arreglo con filtro para mostrar datos
 * @param {Function} props.editar - Funcion para ejecutar en el boton editar
 * @param {Object} props.parametros - Objeto con los parametros para realizar la consulta
 * @param {string} props.nameStartDate - Nombre de columna (BD) de la fecha
 * @param {Object} props.account - Objeto con la infromación del registro principal
 * @param {Object} props.permissions - Objeto con los permisos del usuario sobre el modulo
 */
export const TableComponentDateFilters = (props) => {
  const {
    titulo,
    cabecerasTabla,
    filtro,
    editar,
    parametros, //sobre que tabla hacer el select
    nameStartDate,
    account,
    permissions,
  } = props;
  const today = new Date();

  const cabeceras = [
    "No. Movimiento",
    "Fecha de Registro",
    "Concepto",
    "Saldo Anterior",
    "Cargo",
    "Abono",
    "Saldo Nuevo",
  ];

  const [conditions] = useState(parametros.conditions);
  const [loading, setLoading] = useState(false);
  const [tableContent, setTableContent] = useState([]); // Información mostrada en la tabla
  const [pageSize, setPageSize] = useState(5); // Tamaño de las paginas
  const [totalRegistros, setTotalRegistros] = useState(0); // Total de registros en BD
  const [pagesCount, setPagesCount] = useState(0); // Cantidad total de paginas
  const [seccionActual, setSeccionActual] = useState(0); // Seccion actual
  const [sortCol, setSortCol] = useState({
    //Parametros para aplicar filtros a la busqueda
    direction: "",
    column: "",
    page: "",
    currentPage: 0,
    search: "",
    records: 5,
    startDate: today,
    endDate: today,
  });

  const [fileLink, setFileLink] = useState("");
  const [fileName, setFileName] = useState("");

  const [modal, setModal] = useState(false);
  const [files, setFiles] = useState([]);
  const [modalReports, setModalReports] = useState(false);
  const [report, setReport] = useState("");

  const formatDate = (date, formatDate = false) => {
    const today = new Date(date);
    const month = today.getMonth() + 1;
    const day = today.getDate();
    const formattMonth = month < 10 ? `0${month}` : month;
    const formattDay = day < 10 ? `0${day}` : day;
    return formatDate
      ? `${formattDay}/${formattMonth}/${today.getFullYear()}`
      : `${today.getFullYear()}-${formattMonth}-${formattDay}`;
  };

  const [pdfConfig, setPdfConfig] = useState({
    headers: [
      "No. Movimiento",
      "Fecha de Registro",
      "Concepto",
      "Saldo Anterior",
      "Cargo",
      "Abono",
      "Saldo Nuevo",
    ],
    id: { id: "idMove" },
    title: "Reporte de Movimientos Bancarios",
    filters: {
      onlyMessage: true,
      message: `Cuenta ${account.account} del ${formatDate(
        sortCol.startDate,
        true
      )} al ${formatDate(sortCol.endDate, true)}`,
    },
    orientation: "h",
    content: [
      [
        {
          header: "Fecha de Registro",
          value: "Date",
          columns: 1,
        },
      ],
      [
        {
          header: "Concepto",
          value: "description",
          columns: 1,
        },
      ],
      [
        {
          header: "Saldo Anterior",
          value: "LastBalance",
          columns: 1,
        },
      ],
      [
        { header: "Cargo", value: "Cargo", columns: 1 },
        { header: "Abono", value: "Abono", columns: 1 },
        { header: "Saldo Nuevo", value: "CurrentBalance", columns: 1 },
      ],
    ],
  });

  const settableContentPage = (e, index) => {
    if (index + 1 > (seccionActual + 1) * 5) {
      setSeccionActual(seccionActual + 1);
    } else if (seccionActual != 0 && index + 1 <= seccionActual * 5) {
      setSeccionActual(seccionActual - 1);
    }
    e.preventDefault();
    setSortCol((prevState) => ({
      ...prevState,
      page: pageSize * index,
      currentPage: index,
    }));
  };

  const mapea = (element, indexCol, indexRow) => {
    if (indexCol === 0) {
      return (
        <td className="text-center" key={"C" + indexCol + "R" + indexRow}>
          {5 * sortCol.currentPage + (indexRow + 1)}
        </td>
      );
    }
    if (filtro[indexCol]) {
      const reg = /\d{4}-\d{2}-\d{2}/g;
      let texto = element;
      if (reg.test(element)) {
        texto = element.split("-").reverse().toString().replaceAll(",", "/");
      }
      texto = texto.replaceAll(",", "");
      return (
        <td className="text-center" key={"C" + indexCol + "R" + indexRow}>
          {isNaN(+texto) ? texto : fMoney(texto)}
        </td>
      );
    } else {
      return null;
    }
  };

  const sortTable = (cabecera, indexHeader) => {
    if (
      !["Editar", "Eliminar", "Id", "Ver", "Seleccionar", "Modificar"].includes(
        cabecera
      )
    ) {
      if (sortCol.column !== indexHeader) {
        setSortCol((ps) => ({ ...ps, column: indexHeader, direction: "asc" }));
      } else {
        setSortCol((ps) => ({
          ...ps,
          direction:
            sortCol.direction === "asc"
              ? "desc"
              : sortCol.direction === "desc"
              ? ""
              : "asc",
        }));
      }
    }
  };

  const getData = async (orderOb) => {
    if (!permissions.CON) return false;
    //LA PRIMERA VEZ QUE ENTRAMOS VIENE UN ORDEROB = EMPTY Y EN EL PARAMS = ORDER = "", PAGE = 0 Y RECORDS = 5
    //CUANDO SELECCIONO EL HEADER VIENE ORDEROB = COLUMN = 1 Y DIRECTION = ASC Y EN EL PARAMS = ORDER = "ASC", PAGE = 0 Y RECORDS = 5
    //DESPUES DE ESO SELECCIONO EL PAGINADO Y MUESTRA ORDEROB = COLUMN = "", DIRECTION = "" Y PAGE = 5 Y EN EL PARAMS = ORDER = "", PAGE = 5 Y RECORDS = 5
    //SOLO HAY QUE VERIFICAR EL ESTADO DEL HOOK Y QUE NO CAMBIE LOS PARAMS
    parametros.conditions = conditions;
    let params = parametros;

    if (sortCol.column !== "" && sortCol.direction !== "") {
      //AQUI QUIERE DECIR QUE TRAE UN ORDENAMIENTO POR HEADER
      params.order = orderOb.column + " " + orderOb.direction;
    } else {
      params.order = "";
    }
    if (sortCol.page !== "") {
      //QUIERE DECIR QUE CAMBIAMOS DE PAGINA
      params.page = sortCol.page;
    } else {
      params.page = 0;
    }
    if (sortCol.search !== "") {
      params.search = sortCol.search;
    } else {
      params.search = "";
    }
    if (sortCol.startDate !== "") {
      params.conditions =
        params.conditions +
        ` AND ${nameStartDate} BETWEEN '${formatDate(
          sortCol.startDate
        )} 00:00:00'`;
    }
    if (sortCol.endDate !== "") {
      params.conditions =
        params.conditions + ` AND '${formatDate(sortCol.endDate)} 23:59:59'`;
    }

    if (orderOb) {
    } else {
      if (sortCol.column !== "" && sortCol.direction !== "") {
        //AQUI TRAE UN ORDENAMIENTO POR HEADER
        params.order = orderOb.column + " " + orderOb.direction;
      } else {
        params.order = "";
      }
      if (sortCol.page !== "") {
        //CAMBIAMOS DE PAGINA
        params.page = sortCol.page;
      } else {
        params.page = 0;
      }
      if (sortCol.search !== "") {
        params.search = sortCol.search;
      } else {
        params.search = "";
      }
      if (sortCol.startDate !== "") {
        params.conditions =
          params.conditions +
          ` AND ${nameStartDate} BETWEEN '${formatDate(
            sortCol.startDate
          )} 00:00:00'`;
      }
      if (sortCol.endDate !== "") {
        params.conditions =
          params.conditions + ` AND '${formatDate(sortCol.endDate)} 23:59:59'`;
      }
    }
    params.records = sortCol.records;
    setLoading(true);
    let data = [];
    const finalData = peticionEncript(params);
 
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API}receiver/receiver.php`,
        finalData
      );
      // console.log("res ", res);

      if (res.status === 200 && res.data.code === "200") {
        setTotalRegistros(res.data.totalRecords);
        setPagesCount(Math.ceil(res.data.totalRecords / pageSize));
        const files = res.data.data.map((it) => {
          return { idMove: it.idMove, name: it.nameFyleType, file: it.src };
        });

        setPdfConfig({
          ...pdfConfig,
          filters: {
            ...pdfConfig.filters,
            message: `Cuenta ${account.account} del ${formatDate(
              sortCol.startDate,
              true
            )} al ${formatDate(sortCol.endDate, true)}`,
          },
        });

        res.data.data.map((el) => {
          delete el.src;
          delete el.nameFyleType;
          data.push(Object.values(el));
        });

        setFiles(files);
        setReport(res.data.data);
        setTableContent(data);
        setLoading(false);
      } else {
        toast(
          <Notification
            type={"consultar_servidor_error"}
            backMessage={res.data.messag}
            withIcon
          />
        );
        setLoading(false);
      }
    } catch (error) {
      toast(<Notification type={"consultar_servidor_error"} withIcon />);
      setLoading(false);
    }
  };

  useEffect(() => {
    getData(sortCol);
  }, [sortCol, parametros]);

  const handleOnChange = (e) => {
    setSeccionActual(0);
    setSortCol((prevState) => ({
      ...prevState,
      search: e.target.value,
      currentPage: 0,
      page: "",
    }));
  };

  const handleOnChangeSelect = (e) => {
    setSeccionActual(0);
    setPageSize(e.target.value);
    setSortCol((prevState) => ({
      ...prevState,
      records: e.target.value,
      currentPage: 0,
      page: "",
    }));
  };

  const onChangeDate = (e) => {
    const { name, value } = e;

    setSortCol((prevState) => ({
      ...prevState,
      startDate: name === "startDate" ? value : sortCol.startDate,
      endDate: name === "endDate" ? value : sortCol.endDate,
    }));
  };

  const toggle = () => setModal(false);

  const CloseBtn = (
    <i className="eva eva-close cursor-pointer" onClick={toggle} />
  );

  const viewFile = (id) => {
    const getFile = files.find((it) => it.idMove === id);
    setFileLink(getFile.file);
    setFileName(getFile.name);
    setModal(true);
  };

  const crearExcel = () => {
    saveAsExcel(
      report,
      cabeceras,
      "Reporte de Movimientos Bancarios",
      pdfConfig,
      pdfConfig.filters.message
    );
  };
  const crearPdf = () => {
    setModalReports(true);
  };

  return (
    <div>
      <div className="mb-4">
        {permissions.CON && (
          <>
            <div className="d-flex flex-column flex-md-row justify-content-md-between">
              <h1 className="text-secondary text-center text-md-left">{titulo} </h1>
              <div className="d-flex justify-content-md-end flex-md-row flex-column ">
                <Button onClick={() => crearPdf()} className="mx-md-3 mb-3">
                  Exportar PDF
                </Button>
                <Button onClick={() => crearExcel()} className="mb-3">
                  Exportar Excel
                </Button>
              </div>
            </div>
            <Row>
              <Col xs={12} md={6}>
                <SearchInput
                  value={sortCol.search}
                  onChangeMethod={handleOnChange}
                />
              </Col>
              <Col xs={12} md={3}>
                <DateInput
                  label="Fecha inicial"
                  inputName="startDate"
                  onChangeMethod={(date) =>
                    onChangeDate({ name: "startDate", value: new Date(date) })
                  }
                  value={sortCol.startDate}
                />
              </Col>
              <Col xs={12} md={3}>
                <DateInput
                  label="Fecha Final"
                  inputName="endDate"
                  onChangeMethod={(date) =>
                    onChangeDate({ name: "endDate", value: new Date(date) })
                  }
                  value={sortCol.endDate}
                  isMin={sortCol.startDate}
                />
              </Col>
            </Row>
          </>
        )}

        <div className="widget-table-overflow mt-3">
          <Table
            className={`table-striped table-borderless table-hover ${s.statesTable}`}
            responsive
          >
            {permissions.CON && (
              <thead className="border-bottom border-secondary">
                <tr className={sc.userSelectNone}>
                  {cabecerasTabla.map((cabecera, indexHeaders) => (
                    <th
                      key={cabecera}
                      className="text-center"
                      onClick={() => sortTable(cabecera, indexHeaders + 1)}
                    >
                      {cabecera === "Id" ? "#" : cabecera}
                      <i
                        className={
                          "align-top eva" +
                          (sortCol.column === indexHeaders + 1
                            ? sortCol.direction === "asc"
                              ? " eva-arrow-ios-upward-outline"
                              : sortCol.direction === "desc"
                              ? " eva-arrow-ios-downward-outline"
                              : ""
                            : "")
                        }
                      ></i>
                    </th>
                  ))}
                </tr>
              </thead>
            )}
            <tbody>
              {!permissions.CON ? (
                <tr key={"nothing"}>
                  <td colSpan={cabecerasTabla.length} className="text-center">
                    No tiene permitido ver los registros de esta seccion
                  </td>
                </tr>
              ) : loading ? (
                <tr key={"loading"}>
                  <td colSpan={cabecerasTabla.length}>
                    <Loader />
                  </td>
                </tr>
              ) : tableContent.length === 0 ? (
                <tr key={"nothing"}>
                  <td colSpan={cabecerasTabla.length} className="text-center">
                    No se encontraron registros
                  </td>
                </tr>
              ) : (
                tableContent.map((registro, index) => (
                  <tr key={index}>
                    {registro.map((elemento, indexCol) => {
                      if (filtro !== null) {
                        return mapea(elemento, indexCol, index);
                      } else {
                        return (
                          <td
                            className="text-center"
                            key={"C" + indexCol + "R" + index}
                          >
                            {elemento}
                          </td>
                        );
                      }
                    })}

                    <td
                      className="text-center"
                      onClick={() => viewFile(registro[0])}
                    >
                      <i className="fa fa-eye text-info" />
                    </td>

                    {editar !== false && (
                      <td onClick={() => editar(registro)}>
                        <div className="d-flex justify-content-center">
                          <i
                            className={
                              sc.handCursor + " fa fa-pencil text-warning"
                            }
                          />
                        </div>
                      </td>
                    )}
                  </tr>
                ))
              )}
            </tbody>
          </Table>
          <div className="row">
            <div className="col-12 col-sm-6 d-flex justify-content-around mt-3">
              <div>
                Mostrando{" "}
                <span className="text-primary">
                  {sortCol.currentPage * pageSize +
                    (tableContent.length === 0 ? 0 : 1)}
                </span>{" "}
                a{" "}
                <span className="text-primary">
                  {pageSize * (sortCol.currentPage + 1) <= totalRegistros
                    ? pageSize * (sortCol.currentPage + 1)
                    : totalRegistros}{" "}
                </span>
                de {totalRegistros} registros
              </div>
              <select
                className="form-control form-control-sm"
                style={{ width: "78px" }}
                value={sortCol.records}
                onChange={handleOnChangeSelect}
              >
                <option value={5} key={5}>
                  5
                </option>
                <option value={10} key={10}>
                  10
                </option>
                <option value={15} key={15}>
                  15
                </option>
                <option value={20} key={20}>
                  20
                </option>
                <option value={100} key={100}>
                  100
                </option>
              </select>
            </div>
            <div className="col-12 col-sm-6 mt-3 d-flex justify-content-center justify-content-sm-end">
              <Pagination
                className="pagination-borderless d-flex"
                aria-label="Page navigation example"
              >
                <PaginationItem disabled={sortCol.currentPage <= 0}>
                  <PaginationLink
                    onClick={(e) =>
                      settableContentPage(e, sortCol.currentPage - 1)
                    }
                    previous
                    href="#top"
                  />
                </PaginationItem>
                {[...Array(5)].map(
                  (page, i) =>
                    5 * seccionActual + i < pagesCount && (
                      <PaginationItem
                        key={i}
                        disabled={5 * seccionActual + i === sortCol.currentPage}
                      >
                        <PaginationLink
                          onClick={(e) =>
                            settableContentPage(e, 5 * seccionActual + i)
                          }
                          href={5 * seccionActual + (i + 1)}
                          className={
                            5 * seccionActual + i === sortCol.currentPage
                              ? "text-primary text-bold"
                              : "text-secondary"
                          }
                        >
                          {5 * seccionActual + (i + 1)}
                        </PaginationLink>
                      </PaginationItem>
                    )
                )}
                <PaginationItem
                  disabled={sortCol.currentPage >= pagesCount - 1}
                >
                  <PaginationLink
                    onClick={(e) =>
                      settableContentPage(e, sortCol.currentPage + 1)
                    }
                    next
                    href="#top"
                  />
                </PaginationItem>
              </Pagination>
            </div>
          </div>
        </div>

        <div>
          <Modal isOpen={modal} toggle={toggle} style={{ maxWidth: "80%" }}>
            <ModalHeader
              toggle={toggle}
              close={CloseBtn}
              className="headline-1"
            >
              {fileName}
            </ModalHeader>
            <ModalBody>
              <div className="embed-responsive embed-responsive-21by9">
                <iframe
                  className="embed-responsive-item"
                  src={fileLink}
                  allowfullscreen
                />
              </div>
            </ModalBody>

            <ModalFooter>
              <div className="w-100 d-flex justify-content-around">
                <Button
                  className="btn-outline-secondary mr-3"
                  outline
                  onClick={toggle}
                >
                  Cerrar
                </Button>
              </div>
            </ModalFooter>
          </Modal>

          <ModalReports
            modal={modalReports}
            setModal={setModalReports}
            title={"Reporte de movimientos"}
            cabeceras={cabeceras}
            backdrop={"static"}
            keyboard={false}
            report={report}
            pdfConfig={pdfConfig}
            generatePdfMethod={{
              method: generatePdfPresupuestoAnual,
              type: "general",
            }}
            records={1000}
          />
        </div>
      </div>
    </div>
  );
};
export default TableComponentDateFilters;

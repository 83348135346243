import axios from "axios";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  Alert,
  Button,
  Col,
  Container,
  FormGroup,
  Row,
  Spinner,
} from "reactstrap";
import Notification from "../../../components/Notification/Notification";
import { peticionEncript } from "../../../helpers/peticionesEncripted";
import s from "../../../components/CustomAlert/CustomAlert.module.scss";
import successIcon from "../../../assets/notifications/successIcon.svg";
import dangerIcon from "../../../assets/notifications/successIcon.svg";
import classNames from "classnames";
import { TextAreaInput } from "../../../components/GenericInputsFormik";

async function doRequest(route, params, alerts) {
  let info = {};
  const dataEncrypted = peticionEncript(params);
  await axios
    .post(process.env.REACT_APP_API + route, dataEncrypted)
    .then((res) => {
      info = res.data;
      if (alerts && res.status === 200 && res.data.status === "success") {
        toast(
          <Notification
            type={"agrega_exito"}
            backMessage={res.data.message}
            withIcon
          />,
          { closeButton: false }
        );
        info = res.data.data;
      } else if (alerts) {
        toast(
          <Notification
            type={"agrega_error"}
            backMessage={res.data.message}
            withIcon
          />,
          { closeButton: false }
        );
      }
    })
    .catch((err) => {
      toast(<Notification type={"consultar_servidor_error"} withIcon />, {
        closeButton: false,
      });
    });
  return info;
}

const ValidarP = ({ proyect, setProyect }) => {
  const [valid, setValid] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(true);
  const [buttonOk, setButtonOk] = useState(true);

  const getValidation = async () => {
    const params = {
      action: "verifyProject",
      idProyect: proyect.id,
    };
    const data = await doRequest("app/facades/planning/planningF.php", params);
    setValid(+data.totalRows);
    setMessage(data.message);
    setLoading(false);
    setButtonOk(+data.totalRows !== 0);
  };

  useEffect(() => {
    getValidation();
  }, []);

  const updateProyect = async () => {
    const params = {
      action: "update",
      table: "proyects",
      rows: { keyStat: 67 },
      condition: { idProyect: proyect.id },
    };
    const data = await doRequest("receiver/receiver.php", params, true);
    if (data.length > 0) {
      setButtonOk(false);
      setProyect({
        id: 0,
        dateS: "",
        dateE: "",
        name: "",
        objetive: "",
        keyStat: "",
        canEdit: true,
        notes: "",
      });
    }
  };

  if (loading)
    return (
      <div style={{ minHeight: "300px" }} className="text-center">
        <Spinner color="primary" style={{ marginTop: "135px" }} />
      </div>
    );

  return (
    <Container className="mx-0 mt-2" style={{ maxWidth: "100%" }}>
      <div className="mb-4">
        <h4>Envio a Validación</h4>
      </div>

      <Row className="d-flex justify-content-center mt-4">
        <Col xs={12} md={7}>
          <Alert
            isOpen
            fade={false}
            color={valid > 0 ? "success" : "danger"}
            className={classNames(s.alertContainer, {
              backgroundColor: valid > 0 ? "#43BC1350" : "#FF4B2350",
            })}
          >
            <div className={s.alertIconContainer}>
              <img src={valid > 0 ? successIcon : dangerIcon} alt="..." />
            </div>
            <div className={s.messageContainer}>{message}</div>
          </Alert>

          {(+proyect.keyStat === 69 || proyect.notes !== "") && (
            <TextAreaInput
              label="Notas u observaciones"
              inputName="notes"
              inputRows={6}
              value={proyect.notes}
              isDisabled
            />
          )}
        </Col>
        {proyect.canEdit && buttonOk && (
          <Col xs={12} className="text-center p-5">
            <Button onClick={() => updateProyect()}>Enviar validación</Button>
          </Col>
        )}
      </Row>
    </Container>
  );
};

export default ValidarP;

import {
  Document,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
import HeaderReport from "../../../../components/Pdf/Header";

const s = StyleSheet.create({
  body: {
    paddingTop: 15,
    paddingBottom: 15,
    paddingHorizontal: 15,
  },
  title: { //TITULOS
    width: "100%",
    textAlign: 'center',
    fontFamily: 'Helvetica-Bold',
    fontSize: 12,
  }
})

export async function generarGANTT(data, model, title, fileNo) {

  const ori = model.orientation === "h" ? "landscape" : "portrait"

  const width = 550;
  const height = width / model.aspectRatio;

  return {
    report: (
      <>
        <Document>
          <Page style={s.body} orientation={ori}>
            <HeaderReport/>
            <View>
            <Text style={s.title}>{title}</Text>
            </View>
            <View>
              <Image src={model.imgData} style={{ width: `${width}`, height: `${height}`, padding: "10" }} />
            </View>
          </Page>
        </Document>
      </>
    ),
  };
}

import { useEffect, useRef, useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import ModalConfirmation from "../../../components/Modal/ModalConfirmation";
import { validarRFC } from "../../../libs/personalData/RFC";
import { Button, Col, Collapse, Row } from "reactstrap";
import {
  SelectTypeHeadSingle,
  TextInput,
} from "../../../components/GenericInputsFormik";
import { peticionEncript } from "../../../helpers/peticionesEncripted";
import axios from "axios";
import Notification from "../../../components/Notification/Notification";
import { toast } from "react-toastify";
import { doRequest } from "../../../helpers/requests";
import { NumberInput } from "../../../components/GenericInputsFormik/NumberInput";
import FullFormLoader from "../../../components/Loader/FullFormLoader";
import TableComponentCustom from "../../../components/TableComponent/TableComponentCustom";
import ModalDelete from "../../../components/Modal/ModalDelete";

const reg = /^\d*$/;

const cabeceras = [
  "Banco",
  "Número de cuenta",
  "CLABE",
  "Eliminar",
];
const filtro = [false, true, true, true];

const defaultForm = {
  idProviderAccount: "",
  keyBank: "",
  account: "",
  swiftCode: "",
};

const FormSchema = Yup.object().shape({
  keyBank: Yup.string().required("Seleccione un banco"),
  account: Yup.string()
    .required("Ingrese un número de cuenta")
    .min(10, "Ingrese un minimo de 10 digitos")
    .max(12, "Ingrese un maximo de  12 digitos"),
  swiftCode: Yup.string()
    .required("Ingrese la CLABE")
    .length(18, "Número de CLABE invalido"),
});

const CuentaB = ({ idProvider, banks, permissions }) => {
  const [modalConfirm, setModalConfirm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState("");
  const [form, setForm] = useState(false);
  const [parametros, setParametros] = useState({
    action: "datatable",
    table: "provideraccounts A JOIN banks B USING (keyBank)",
    rows: "A.idProviderAccount, B.nameBank, A.account, A.swiftCode, IF(A.enabled=0,'INACTIVO','ACTIVO') AS edo, A.enabled, A.idProviderAccount, A.keyBank",
    conditions: `A.idProvider = ${idProvider}`,
    page: 0,
    records: 5,
    search: "",
    order: "6 desc",
  });
  const refScroll = useRef(null);

  const {
    handleSubmit,
    values,
    handleBlur,
    errors,
    touched,
    setFieldValue,
    handleReset,
  } = useFormik({
    initialValues: defaultForm,
    validationSchema: FormSchema,
    onSubmit: (values) => {
      if (JSON.stringify(values) !== JSON.stringify(form)) {
        setModalConfirm(true);
      }
    },
    onReset: () => setTitle(""),
    enableReinitialize: true,
  });

  const onChange = (e) => {
    if (e.target.name === "swiftCode") {
      if (reg.test(e.target.value)) {
        setFieldValue("swiftCode", e.target.value);
      }
    } else {
      setFieldValue(e.target.name, e.target.value);
    }
  };

  const editInfo = async () => {
    let params = {};
    let rute = "";
    if (values.idProviderAccount === "") {
      params = {
        ...values,
        idProvider: idProvider,
        action: "saveProviderAccount",
      };
      rute = "app/facades/inventory/inventoryF.php";
    } else {
      params = {
        action: "update",
        table: "provideraccounts",
        rows: { ...values },
        condition: { idProviderAccount: values.idProviderAccount },
        validate: [["account"], ["swiftCode"]],
      };
      rute = "receiver/receiver.php";
    }
    setLoading(true);
    const data = await doRequest(rute, params, true);
    if (data.length > 0) {
      setParametros({ ...parametros });
      setForm(false);
    }
    setLoading(false);
  };

  const openForm = () => {
    setTitle("Agregar Cuenta Bancaria");
    setForm(true);
  };

  const scrollTo = () => {
    refScroll.current.scrollIntoView({ behavior: "smooth" });
  };

  const ActionCol = (el) => {
    if(permissions.DEL) 
      return (
        <div>
          {el[4] === "ACTIVO" && (
            // <i
            //   className={"fa fa-trash-o text-danger"}
            //   style={{ cursor: "pointer" }}
            //   onClick={() => deleteCuenta(el)}
            // />
            <div>
              <ModalDelete
                modalTitle="Eliminar"
                table="provideraccounts"
                nameColumn={["idProviderAccount"]}
                elimina={deleteCuenta}
                id={el[0]}
              >
                <h6>
                  ¿Seguro de eliminar la cuenta en el banco "{el[1]}"?
                </h6>
                No podrá restaurar la cuenta
              </ModalDelete>
            </div>
          )}
        </div>
      );
  };

  const deleteCuenta = async (id) => {
    const params = {
      action: "delete",
      table: "provideraccounts",
      condition: {idProviderAccount: id},
    };
    setLoading(true);
    const data = await doRequest("receiver/receiver.php", params, true);
    if(data.length > 0) setParametros({...parametros});
    setLoading(false);
  }

  return (
    <div className="mx-4">
      <TableComponentCustom
        cabecerasTabla={cabeceras}
        filtro={filtro}
        parametros={parametros}
        specialColumns={[ActionCol]}
        hiddeTitle
        defaultOrder="6 desc"
      />

      <Row className="mb-1">
        <Col xs={12} md={6} className="text-center text-md-left">
          {openForm && <h5>{title}</h5>}
        </Col>
        {permissions.INS && <Col xs={12} md={6} className="text-center text-md-right">
          <Button
            color="add"
            onClick={() => {
              setForm(true);
              setTitle("Agregar Cuenta");
            }}
          >
            Agregar
          </Button>
        </Col>}
      </Row>

      <Collapse
        isOpen={form}
        onExited={() => handleReset()}
        onEntered={() => scrollTo()}
        className=""
      >
        <form onSubmit={handleSubmit} onReset={handleReset} className="mt-2">
          <ModalConfirmation
            modalTitle="Guardar proveedor"
            modal={modalConfirm}
            setModal={setModalConfirm}
            editar={() => editInfo()}
            isEdit={true}
          >
            <div className="d-flex justify-content-center text-center">
              <h6>
                {values.idProviderAccount === ""
                  ? "¿Esta seguro de guardar esta cuenta?"
                  : "¿Está seguro de actualizar la información de esta cuenta?"}
              </h6>
            </div>
          </ModalConfirmation>
          <Row>
            <Col xs={12} lg={4}>
              <SelectTypeHeadSingle
                label="Banco"
                isRequired={true}
                inputName="keyBank"
                optionsArray={banks}
                defaultOption="Seleccione un banco"
                onChangeMethod={onChange}
                onBlurMethod={handleBlur}
                value={values.keyBank}
                touched={touched.keyBank}
                errors={errors.keyBank}
                optionValue="keyBank"
                optionName="nameBank"
              />
            </Col>
            <Col xs={12} lg={4}>
              <NumberInput
                label="Número de Cuenta"
                inputType="text"
                inputName="account"
                onChangeMethod={onChange}
                onBlurMethod={handleBlur}
                value={values.account}
                touched={touched.account}
                errors={errors.account}
                isRequired
                maxLength={10}
                disableSep
              />
            </Col>
            <Col xs={12} lg={4}>
              <TextInput
                label="CLABE"
                inputType="text"
                inputName="swiftCode"
                onChangeMethod={onChange}
                onBlurMethod={handleBlur}
                value={values.swiftCode}
                touched={touched.swiftCode}
                errors={errors.swiftCode}
                isRequired
                isMax={18}
              />
            </Col>
          </Row>

          <Row className="mt-5">
            <div
              className="col-sm-6 order-2 order-sm-1 text-center text-sm-left mb-4"
              ref={refScroll}
            >
              <Button
                color="danger"
                type="reset"
                onClick={() => {
                  setForm(false);
                }}
              >
                Cancelar
              </Button>
            </div>
            <div className="col-sm-6 order-1 order-sm-2 text-center text-sm-right mb-4">
              <Button color="success" type="submit">
                Guardar
              </Button>
            </div>
          </Row>
        </form>
      </Collapse>

      <FullFormLoader show={loading} />
    </div>
  );
};

export default CuentaB;

/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Modal, ModalHeader, ModalBody} from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useState, useEffect } from "react";
import { toast } from "react-toastify";

import { SelectTypeHeadSingle } from "../../../../components/GenericInputsFormik";
import { CurrencyInput } from "../../../../components/GenericInputsFormik";
import { getDataArray } from "./functions";
import ModalConfirmation from "../../../../components/Modal/ModalConfirmation";
import Notification from "../../../../components/Notification/Notification";
import { peticionesReceiver } from "../../../../helpers/peticionesReceiver";
import Loader from "../../../../components/Loader/Loader";

export default function EditarCapitulo({ id, modalEdit, setModalEdit, setRefresh }) {
    const API = peticionesReceiver();
    const [modal, setModal] = useState(false);
    const [chapters, setChapters] = useState([])
    const [loadChapters, setLoadChapters] = useState(true)
    const [loadData, setLoadData] = useState(true)
    const [loading, setLoading] = useState(true)

    const defaultForm = {
        idBudgetAreaChapter: "",
        keyChapter: "",
        idArea: "",
        amount: "",
        idAnnualBudget: ""
    }

    const onChange = (e) => {
        const { name, value } = e.target
        setFieldValue([name], value)
    }

    useEffect(() => {
        getDataArray("getChapters", setChapters, setLoadChapters)
    }, [])

    useEffect(() => {
        setLoading(loadData || loadChapters)
    }, [loadChapters, loadData])

    const getData = async (id) => {
        const params = {
            action: "multiselect",
            table: "budgetareachapters a join budgetareas b using(idBudgetArea)",
            rows: "a.keyChapter,a.amount,b.idArea,b.idAnualBudget",
            conditions: `a.idBudgetAreaChapter=${id} AND a.enabled = 1 AND b.enabled = 1`
        };
        try {
            const res = await API.peticion(params)
            if (res.status === 200 && res.data.code === "200") {
                const data = res.data.data[0];
                setFieldValue(["idBudgetAreaChapter"], id)
                setFieldValue(["keyChapter"], data.keyChapter)
                setFieldValue(["amount"], data.amount)
                setFieldValue(["idArea"], data.idArea)
                setFieldValue(["idAnnualBudget"], data.idAnualBudget)
                setLoadData(false)
            } else {
                setModalEdit(false)
                toast(<Notification type={"consultar_error"} withIcon />);
            }
        } catch (err) {
            setModalEdit(false)
            toast(<Notification type={"consultar_error"} withIcon />);
        }
    }


    useEffect(() => {
        if (id !== null && modalEdit) {
            getData(id)
        }
    }, [id, modalEdit])

    const FormSchema = Yup.object().shape({
        idBudgetAreaChapter: Yup.number(),
        idArea: Yup.number(),
        idAnnualBudget: Yup.number(),
        keyChapter: Yup.number()
            .required("Seleccione un capitulo valido"),
        amount: Yup.number()
            .required("Ingrese un monto valido"),
    })

    const { handleSubmit, values, handleBlur, errors, touched, setFieldValue, resetForm } =
        useFormik({
            initialValues: defaultForm,
            onSubmit: () => setModal(true),
            validationSchema: FormSchema,
        });

    const OnEdit = async (rows) => {
        const params = {
            action: "saveAreaBudget",
            idArea: rows.idArea,
            idAnnualBudget: rows.idAnnualBudget,
            idBudgetAreaChapter: rows.idBudgetAreaChapter,
            keyChapter: rows.keyChapter,
            amount: rows.amount
        };
        try {
            const res = await API.peticionEndPoint(params, "app/facades/budgets/budgetsF.php")
            if (res.status === 200 && res.data.code === "200") {
                toast(<Notification type={"modifica_exito"} withIcon />);
                setRefresh(true)
                setModalEdit(false)
            } else {
                toast(<Notification type={"modifica_error"} withIcon />);
            }
        } catch (err) {
            toast(<Notification type={"modifica_error"} withIcon />);
        }
    }

    return (
        <>
            <Modal isOpen={modalEdit} toggle={() => setModalEdit(false)} size="md" centered backdrop="static" >
                <ModalHeader toggle={() => setModalEdit(false)}>Editar capitulo</ModalHeader>
                <ModalBody>
                    {loading ? (
                        <Loader />
                    ) : (
                        <form onSubmit={handleSubmit}>
                            <SelectTypeHeadSingle
                                label="Capitulo"
                                inputName="keyChapter"
                                onChangeMethod={onChange}
                                onBlurMethod={handleBlur}
                                value={values.keyChapter}
                                isRequired
                                touched={touched.keyChapter}
                                errors={errors.keyChapter}
                                optionsArray={chapters}
                                optionValue="keyChapter"
                                optionName="name"
                            />
                            <CurrencyInput
                                label={"Monto"}
                                errors={errors.amount}
                                inputName="amount"
                                isRequired
                                onBlurMethod={handleBlur}
                                onChangeMethod={(value, name, e) => onChange({ target: { name: name, value: value } })}
                                touched={touched.amount}
                                value={values.amount}
                            />
                            <div className="text-right">
                                <Button color='danger' className="mx-2" onClick={() => setModalEdit(false)}>Cancelar</Button>
                                <Button type="submit" className="mx-2">Guardar</Button>
                            </div>
                        </form>
                    )}
                </ModalBody>
            </Modal>

            <ModalConfirmation
                modalTitle={"Editar presupuesto"}
                modal={modal}
                editar={(rows) => OnEdit(rows)}
                isEdit
                setModal={setModal}
                values={values}
            >
                <div className="d-flex justify-content-center">
                    <h6>¿Desea continuar con el registro?</h6>
                </div>
            </ModalConfirmation>
        </>
    )
}
import { useEffect, useState } from "react";
import { Row, Col, Button } from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { CancelOutlined } from "@material-ui/icons";

import { toast } from "react-toastify";
import Notification from "../../../../../components/Notification/Notification";
import { CurrencyInput } from "../../../../../components/GenericInputsFormik";
import { peticionesReceiver } from "../../../../../helpers/peticionesReceiver";
import ModalConfirmation from "../../../../../components/Modal/ModalConfirmation";
import Loader from "../../../../../components/Loader/Loader";
import { SelectTypeHeadSingle } from "../../../../../components/GenericInputsFormik";
import ModalTableVer from "../../../../../components/Modal/ModalTableVer";

import s from "../../../../complemento-nomina/ajustesPrisma/InputClear.module.scss"

export default function FormEmpleados({ data, setParams, disabled }) {
    const API = peticionesReceiver();
    const [loading, setLoading] = useState(true);

    const [modal, setModal] = useState(false)
    const [modalTabla, setModalTabla] = useState(false)
    const [conceptos, setConceptos] = useState([])
    const filtroModalTabla = [true, true, true, true];
    const cabecerasModalTabla = [
        "#",
        "Núm. Empleado",
        "Nombre",
        "RFC",
        "Seleccionar",
    ];
    const [paramsModalTabla] = useState({
        action: "datatable",
        table: "staff STF",
        rows: "STF.idStaff,STF.staffNumber,CONCAT(STF.name,' ',STF.pName,' ',STF.mName) as fullName,STF.rfc",
        conditions: "STF.enabled = 1",
        page: 0,
        records: 5,
        search: "",
        order: "",
    });

    async function getISRunders() {
        const params = {
            action: "multiselect",
            table: "isrunders",
            rows: "keyIsrunder,nameIsrunder",
            conditions: `enabled = 1`
        }
        const res = await API.peticion(params)
        if (res.status === 200 && res.data.code === "200") {
            const data = res.data.data;
            setConceptos(data)
            setLoading(false)
        } else {
            setConceptos([])
            toast(<Notification type={"consultar_error"} withIcon />);
        }
    }

    useEffect(() => {
        getISRunders();
    }, [])

    const selectEmpleado = (el) => {
        setFieldValue("idStaff", el[0]);
        setFieldValue("nameStaff", `${el[1]} - ${el[2]}`);
        setModalTabla(false);
    };

    const defaultForm = {
        idIsrmonthlyreport: data ? data.idIsrmonthlyreport : "",
        idStaff: "",
        nameStaff: "",
        keyIsrunder: "",
        amount: "",
    }

    const FormSchema = Yup.object().shape({
        idStaff: Yup.number(),
        nameStaff: Yup.string()
            .required("Seleccione un empleado valido"),
        keyIsrunder: Yup.number()
            .required("Ingrese un concepto valido"),
        amount: Yup.number()
            .required("Ingrese un monto valido")
            .min(0)
    })

    const { handleSubmit, values, handleBlur, errors, touched, setFieldValue, resetForm } =
        useFormik({
            initialValues: defaultForm,
            onSubmit: () => setModal(true),
            validationSchema: FormSchema,
        });

    const onChange = (e) => {
        const { name, value } = e.target
        setFieldValue([name], value);
    }

    const OnAdd = async (fields) => {
        const rows = JSON.parse(JSON.stringify(fields));
        delete rows.nameStaff
        const formData = {
            action: "insert",
            table: "isrstaffreports",
            rows,
            validate: [
                ["idIsrmonthlyreport", "keyIsrunder", "idStaff"],
            ]
        };
        try {
            const res = await API.peticion(formData);
            if (res.status === 200 && res.data.code === "200") {
                setParams({
                    action: "datatable",
                    table: "isrstaffreports rep inner join staff s using(idStaff) inner join isrunders isr using(keyIsrunder)",
                    rows: "rep.idIsrstaffreport,isr.nameIsrunder,rep.amount,concat(s.staffNumber,' - ', s.name, ' ', s.pName, ' ', s.mName),s.rfc",
                    conditions: `rep.enabled = 1 AND rep.idIsrmonthlyreport = ${data.idIsrmonthlyreport}`,
                    page: "0",
                    records: "5",
                    search: "",
                    order: ""
                })
                resetForm()
                toast(<Notification type={"agrega_exito"} backMessage={res.data.message} withIcon />);
            } else {
                toast(<Notification type={"agrega_error"} backMessage={res.data.message} withIcon />);
            }
        } catch (err) {
            console.log(err);
            toast(<Notification type={"agrega_error"} withIcon />);
        }
    }

    const handleClear = () => {
        setFieldValue("idStaff", "");
        setFieldValue("nameStaff", "");
    };

    if (loading) {
        return (<Loader />)
    }
    return (
        <>
            <form onSubmit={handleSubmit}>
                <h2>Empleados</h2>
                <Row>
                    <Col xs={12} md={6}>
                        <SelectTypeHeadSingle
                            label="Concepto"
                            inputName="keyIsrunder"
                            onChangeMethod={onChange}
                            onBlurMethod={handleBlur}
                            value={values.keyIsrunder}
                            optionsArray={conceptos}
                            optionValue="keyIsrunder"
                            optionName="nameIsrunder"
                            errors={errors.keyIsrunder}
                            touched={touched.keyIsrunder}
                            isRequired
                        />
                    </Col>
                    <Col xs={12} md={6}>
                        <CurrencyInput
                            inputName="amount"
                            onBlurMethod={handleBlur}
                            onChangeMethod={(value, name) => onChange({ target: { name, value } })}
                            errors={errors.amount}
                            touched={touched.amount}
                            label="Deducción"
                            value={values.amount}
                            isRequired
                        />
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} lg={12}>
                        <div className="form-group position-relative">
                            <label htmlFor="nameStaff">
                                Empleado <span className="text-danger">*</span>
                            </label>
                            <div className="input-group">
                                <input
                                    name="nameStaff"
                                    onChange={onChange}
                                    onBlur={handleBlur}
                                    className={
                                        "form-control" +
                                        (errors.nameStaff && touched.nameStaff
                                            ? " is-invalid"
                                            : "")
                                    }
                                    value={values.nameStaff}
                                    disabled
                                />
                                <div className="input-group-append">
                                    <button
                                        className="btn btn-secondary"
                                        style={{ zIndex: "auto" }}
                                        type="button"
                                        onClick={() => setModalTabla(true)}
                                    >
                                        <i className="fa fa-search" />
                                    </button>
                                </div>
                            </div>
                            {values.nameStaff !== "" && (
                                <CancelOutlined
                                    className={s.btnClear}
                                    onClick={handleClear}
                                />
                            )}
                            {touched.nameStaff && errors.nameStaff && (
                                <span style={{ color: "red" }}>{errors.nameStaff}</span>
                            )}
                        </div>
                    </Col>
                </Row>
                <div className="text-center mb-3">
                    <Button color="danger" className="mx-2" onClick={() => window.location.href = "#/template/reportes-finanzas"}>Regresar</Button>
                    <Button color="add" type="submit" disabled={disabled}>Agregar</Button>
                </div>
            </form>
            <ModalConfirmation
                modalTitle={"Agregar registro"}
                modal={modal}
                crear={(rows) => OnAdd(rows)}
                setModal={setModal}
                isEdit={false}
                values={values}
            >
                <div className="d-flex justify-content-center">
                    <h6>¿Desea agregar el registro?</h6>
                </div>
            </ModalConfirmation>
            <ModalTableVer
                modal={modalTabla}
                setModal={setModalTabla}
                title="Empleados"
                cabeceras={cabecerasModalTabla}
                filtro={filtroModalTabla}
                parametros={paramsModalTabla}
                selectRegistro={selectEmpleado}
                backdrop={"static"}
                keyboard={false}
            />
        </>
    )
}
import React, { useState } from "react";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import Notification from "../../../components/Notification/Notification";
import s from "./InputClear.module.scss";
import ModalTableVer from "../../../components/Modal/ModalTableVer";
import { Row, Col, Button, Collapse } from "reactstrap";
import * as Yup from "yup";
import { CancelOutlined, Refresh } from "@material-ui/icons";
import ModalConfirmation from "../../../components/Modal/ModalConfirmation";
import { peticionesReceiver } from "../../../helpers/peticionesReceiver";
import Widget from "../../../components/Widget/Widget";
import Datatable from "../../../components/Datatable/Datatable";
import ModalComponentEliminar from "../../../components/Modal/ModalDelete";
import { GetPermisos } from "../../../libs/permissions/hook";

const AsignacionRoles = () => {
  const [quouteAgents, setQuouteAgents] = useState({
    idStaff: "",
    nameStaff: "",
    pricing: "0",
    contracts: "0",
    reception: "0",
  });

  const API = peticionesReceiver();
  const [modalConfirmation, setModalConfirmation] = useState(false);
  const [collapse, setCollapse] = useState(false);
  const toggleCollapse = () => setCollapse(!collapse);

  const FormSchema = Yup.object().shape({
    /* idUnder: Yup.string().required("Seleccione un concepto de nomina"), */
    nameStaff: Yup.string().required("Seleccione un usuario"),
    pricing: Yup.string().required("Seleccione almenos algun rol"),
    contracts: Yup.string().required("Seleccione almenos algun rol"),
    reception: Yup.string().required("Seleccione almenos algun rol"),
  });

  function handleClearStaff() {
    setFieldValue("nameStaff", "");
    setFieldValue("idStaff", "");
  }

  const [permissionsForm, setPermissionsForm] = useState("");
  GetPermisos(setPermissionsForm);

  const headersTable = [
    "#", //0
    "EMPLEDADO",
    "ESTUDIO DE MERCADO",
    "CONTRATOS",
    "RECEPCION DE FACTURAS ",
    "EDITAR",
    "ELIMINAR",
  ];

  const columnsTable = [
    { data: "index" },
    { data: "fullName" },
    {
      data: null,
      render: function (quoteAgent) {
        return (
          <>
            <input
              type="checkbox"
              disabled={true}
              name={`receptionCont+${quoteAgent.idAgent}`}
              className="form-control"
              checked={quoteAgent.pricing === "1" ? true : false}
            />
          </>
        );
      },
    },
    {
      data: null,
      render: function (quoteAgent) {
        return (
          <>
            <input
              type="checkbox"
              disabled={true}
              name={`receptionPri+${quoteAgent.idAgent}`}
              className="form-control"
              checked={quoteAgent.contracts === "1" ? true : false}
            />
          </>
        );
      },
    },
    {
      data: null,
      render: function (quoteAgent) {
        return (
          <>
            <input
              type="checkbox"
              disabled={true}
              name={`receptionRep+${quoteAgent.idAgent}`}
              className="form-control"
              checked={quoteAgent.reception === "1" ? true : false}
            />
          </>
        );
      },
    },
    {
      data: null,
      render: function (quoteAgent) {
        return (
          <>
            {permissionsForm.UPD === "1" && (
              <i
                className={"fa fa-pencil text-warning"}
                style={{ cursor: "pointer" }}
                onClick={() => sendInformationToForm(quoteAgent)}
              />
            )}
          </>
        );
      },
    },
    {
      data: null,
      render: function (quoteAgent) {
        return (
          <>
            {permissionsForm.DEL === "1" && (
              <ModalComponentEliminar
                modalTitle="Eliminar"
                id={quoteAgent.idAgent}
                table={["quoteagents"]}
                nameColumn={["idAgent"]}
                elimina={() => deleteQuote(quoteAgent.idAgent)}
              >
                <h6>
                  ¿Seguro de eliminar el registro "{quoteAgent.fullName}
                  "?
                </h6>
                No podrá recuperar la información después de ser eliminada
              </ModalComponentEliminar>
            )}
          </>
        );
      },
    },

    /* { data: "reception" },
    { data: "reception" }, */
  ];

  const columnDefsBudget = [
    { targets: [0, 2, 3, 4, 5, 6], className: "text-center" },
    { targets: [5, 6], orderable: false },
  ];

  const deleteQuote = async (idAgent) => {
    const params = {
      action: "delete",
      table: "quoteagents",
      condition: {
        idAgent: idAgent,
      },
    };
    await API.peticion(params)
      .then((res) => {
        if (res.data.status === "success" && res.data.code === "200") {
          toast(
            <Notification
              type={"elimina_exito"}
              backMessage={res.data.message}
              withIcon
            />
          );

          setRefresh(true);
        } else {
          toast(
            <Notification
              type={"elimina_error"}
              backMessage={res.data.message}
              withIcon
            />,
            { closeButton: false }
          );
        }
      })
      .catch((err) => {
        toast(<Notification type={"consultar_servidor_error"} withIcon />);
      });
  };

  function getParamsTable(order, records, page, search) {
    return {
      action: "datatable",
      table: "quoteagents A INNER JOIN staff B on (A.idStaff = B.idStaff)",
      rows: "A.idAgent, concat(B.name,' ',B.pName,' ',B.mName) as fullName,A.pricing,A.contracts,A.reception,B.staffNumber,B.idStaff",
      conditions: "A.enabled = 1",
      page: (page * records).toString(),
      records: records.toString(),
      search,
      order,
    };
  }

  async function getDataTable(order, records, page, search) {
    try {
      const res = await API.peticion(
        getParamsTable(order, records, page, search)
      );
      if (res.status === 200 && res.data.code === "200") {
        const data = res.data;
        data.data.forEach((value, index) => (value.index = page + 1 + index));
        return data;
      } else {
        toast(
          <Notification
            type={"consultar_error"}
            backMessage={res.data.message}
          />
        );
        return null;
      }
    } catch (err) {
      toast(<Notification type={"consultar_servidor_error"} withIcon />);
      return null;
    }
  }
  const [refresh, setRefresh] = useState(false);

  const {
    handleSubmit,
    handleBlur,
    values,
    errors,
    touched,
    setFieldValue,
    resetForm,
  } = useFormik({
    initialValues: quouteAgents,
    onSubmit: () => {
      if (
        values.contracts !== "0" ||
        values.pricing !== "0" ||
        values.reception !== "0"
      ) {
        setModalConfirmation(true);
      }
    },
    validationSchema: FormSchema,
    enableReinitialize: true,
  });

  const [modalTabla, setModalTabla] = useState(false);
  const filtroModalTabla = [true, true, true, true, true];
  const cabecerasModalTabla = [
    "#",
    "Núm. Empleado",
    "Nombre",
    "Unidad de trabajo",
    "Puesto",
    "Seleccionar",
  ];
  const [paramsModalTabla] = useState({
    action: "datatable",
    table: "staffview",
    rows: "idStaff,staffNumber,concat(name,' ',pName,' ',mName) as fullName,nameArea,nameEmployType",
    conditions: "",
    page: 0,
    records: 5,
    search: "",
    order: "",
  });

  const selectEmpleado = (el) => {
    setFieldValue("idStaff", el[0]);
    setFieldValue("nameStaff", `${el[1]} - ${el[2]}`);
    setModalTabla(false);
  };

  const onChange = (e) => {
    if (e.target.name === "pricing") {
      setFieldValue("pricing", values.pricing === "1" ? "0" : "1");
    } else if (e.target.name === "contracts") {
      setFieldValue("contracts", values.contracts === "1" ? "0" : "1");
    } else if (e.target.name === "reception") {
      setFieldValue("reception", values.reception === "1" ? "0" : "1");
    } else {
      setFieldValue([e.target.name], e.target.value);
    }
  };

  const sendInformation = () => {
    let role = {
      idStaff: values.idStaff,
      pricing: values.pricing,
      contracts: values.contracts,
      reception: values.reception,
    };

    if (
      values.idAgent !== "" ||
      values.idAgent !== undefined ||
      values.idAgent !== null
    ) {
      role.idAgent = values.idAgent;
    }

    const data = {
      table: "quoteagents",
      rows: role,
    };

    if (
      values.idAgent === "" ||
      values.idAgent === undefined ||
      values.idAgent === null
    ) {
      data.action = "insert";
      data.validate = [["idStaff"]];
    } else {
      data.action = "update";
      data.condition = {
        idAgent: values.idAgent,
      };
    }

    API.peticion(data)
      .then((res) => {
        if (res.status === 200 && res.data.status === "success") {
          toast(
            <Notification
              type={"agrega_exito"}
              backMessage={res.data.message}
              withIcon
            />
          );
          limpia(resetForm);
          setRefresh(true);
          /* setParametros(consulta);
          setCollapse(false); */
        } else {
          toast(
            <Notification
              type={"agrega_error"}
              backMessage={res.data.message}
              withIcon
            />
          );
        }
      })
      .catch((err) => {
        toast(err);
        toast(<Notification type={"consultar_servidor_error"} withIcon />);
      });
  };

  const limpia = (resetForm) => {
    setCollapse(false);
    resetForm();
  };

  const sendInformationToForm = (quoteAgent) => {
    if (!collapse) {
      setFieldValue("idAgent", quoteAgent.idAgent);
      setFieldValue("idStaff", quoteAgent.idStaff);
      setFieldValue(
        "nameStaff",
        `${quoteAgent.staffNumber}-${quoteAgent.fullName}`
      );
      setFieldValue("pricing", quoteAgent.pricing);
      setFieldValue("contracts", quoteAgent.contracts);
      setFieldValue("reception", quoteAgent.reception);
      setCollapse(true);
    } else {
      toast(
        <Notification
          type="warning"
          backMessage="Guarde sus cambios antes de continuar"
        />
      );
    }
  };

  return (
    <Widget className="widget-p-md">
      <ModalConfirmation
        modalTitle={"Asignacion de roles"}
        modal={modalConfirmation}
        setModal={setModalConfirmation}
        crear={sendInformation}
        values={values}
      >
        <div className="d-flex justify-content-center">
          <h6>¿Desea continuar con el registro?</h6>
        </div>
      </ModalConfirmation>

      {permissionsForm.CON === "1" ? (
        <>
          <Datatable
            headers={headersTable}
            petition={getDataTable}
            columns={columnsTable}
            order={{ col: 1, opt: "asc" }}
            columnDefs={columnDefsBudget}
            control="back"
            key={"quoteagents"}
            stateRefresh={[refresh, setRefresh]}
          />
        </>
      ) : (
        <div className="text-center p-4">
          No tienes permitido ver los registros de esta seccion
        </div>
      )}

      {permissionsForm.INS && (
        <div className="d-grid d-flex justify-content-center justify-content-sm-end mt-3">
          <Button
            color="add"
            onClick={toggleCollapse}
            style={{ marginBottom: "1rem" }}
            disabled={collapse}
          >
            Agregar
          </Button>
        </div>
      )}

      <Collapse isOpen={collapse} className="mt-3">
        <form onSubmit={handleSubmit}>
          <Row>
            <Col xs={12} lg={12}>
              <div className="form-group position-relative">
                <label htmlFor="nameStaff">
                  Personal{/*  <span className="text-danger">*</span> */}
                </label>
                <div className="input-group">
                  <input
                    name="nameStaff"
                    onChange={onChange}
                    onBlur={handleBlur}
                    className={
                      "form-control" +
                      (errors.nameStaff && touched.nameStaff
                        ? " is-invalid"
                        : "")
                    }
                    value={values.nameStaff}
                    disabled
                  />
                  <div className="input-group-append">
                    <button
                      className="btn btn-secondary"
                      style={{ zIndex: "auto" }}
                      type="button"
                      onClick={() => setModalTabla(true)}
                      /* disabled={
                          [10, 11, 12, 13, 14, 15].includes(+values.idReport) &&
                          (values.period !== "" || values.initialDate !== "")
                        } */
                    >
                      <i className="fa fa-search" />
                    </button>
                  </div>
                </div>
                {values.nameStaff !== "" && (
                  <CancelOutlined
                    className={s.btnClear}
                    onClick={handleClearStaff}
                  />
                )}
                {touched.nameStaff && errors.nameStaff && (
                  <span style={{ color: "red" }}>{errors.nameStaff}</span>
                )}
              </div>
            </Col>
          </Row>
          <Row className="d-flex flex-row text-center justify-content-center align-items-center">
            <Col xs={4} lg={4}>
              <div className="form-group d-flex flex-column text-center justify-content-center">
                <label htmlFor="pricing" className="fs-5">
                  ESTUDIOS DE MERCADO
                </label>
                <input
                  type="checkbox"
                  name="pricing"
                  className="form-control"
                  onChange={onChange}
                  value={values.pricing}
                  checked={values.pricing === "1" ? true : false}
                />
              </div>
              {touched.pricing && errors.pricing && (
                <span style={{ color: "red" }}>{errors.pricing}</span>
              )}
            </Col>
            <Col xs={4} lg={4}>
              <div className="form-group d-flex flex-column text-center justify-content-center">
                <label htmlFor="contracts" className="fs-5">
                  CONTRATOS
                </label>
                <input
                  type="checkbox"
                  name="contracts"
                  className="form-control"
                  onChange={onChange}
                  value={values.contracts}
                  checked={values.contracts === "1" ? true : false}
                />
              </div>
              {touched.contracts && errors.contracts && (
                <span style={{ color: "red" }}>{errors.finished}</span>
              )}
            </Col>
            <Col xs={4} lg={4}>
              <div className="form-group d-flex flex-column text-center justify-content-center">
                <label htmlFor="reception" className="fs-5">
                  RECEPCION DE FACTURAS
                </label>
                <input
                  type="checkbox"
                  name="reception"
                  className="form-control"
                  onChange={onChange}
                  value={values.reception}
                  checked={values.reception === "1" ? true : false}
                />
              </div>
              {touched.reception && errors.reception && (
                <span style={{ color: "red" }}>{errors.reception}</span>
              )}
            </Col>
          </Row>

          <Row>
            <Col className="text-center">
              {values.contracts === "0" &&
                values.pricing === "0" &&
                values.reception === "0" && (
                  <span style={{ color: "red" }}>
                    Seleccione almenos un rol
                  </span>
                )}
            </Col>
          </Row>

          <div className="row mt-3">
            <div className="col-sm-6 order-2 order-sm-1 text-center text-sm-left mb-4">
              <Button
                color="danger"
                type="reset"
                onClick={() => limpia(resetForm)}
              >
                Cancelar
              </Button>
            </div>
            <div className="col-sm-6 order-1 order-sm-2 text-center text-sm-right mb-4">
              <Button color="success" type="submit">
                Guardar
              </Button>
            </div>
          </div>
        </form>
      </Collapse>

      <ModalTableVer
        modal={modalTabla}
        setModal={setModalTabla}
        title="Empleados"
        cabeceras={cabecerasModalTabla}
        filtro={filtroModalTabla}
        parametros={paramsModalTabla}
        selectRegistro={selectEmpleado}
        backdrop={"static"}
        keyboard={false}
        btnClose={true}
      />
    </Widget>
  );
};

export default AsignacionRoles;

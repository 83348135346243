import { useState } from "react";
import Datatable from "../../../components/Datatable/Datatable";
import Widget from "../../../components/Widget/Widget";
import { doRequestSaveRes } from "../../../helpers/requests";
import { Button } from "reactstrap";
import { useHistory } from "react-router";

const headers = ["Solicitud", "Tipo de compra", "Compra", "Adscripcion solicitante", "Entrada", "Proveedor", "Estatus", "Ver"]


export default function RevisionAdquisiciones() {
    const [refresh, setRefresh] = useState(false);
    const [compra, setCompra] = useState({ });
    const history = useHistory();
    
    const columns = [
        { data: "solicitud" },
        { data: "nameTypePurchase" },
        { data: "object" },
        { data: "name" },
        { data: "entrada" },
        { data: "socialName" },
        { data: "nameStat" },
        {
            data: null,
            render: function render(row) {
                return (
                    <i className="fa fa-eye text-info cursor-pointer" onClick={() => selectCompra(row)}/>
                )
            }
        }
    ]

    function selectCompra(row){
        // setCompra({
        //     id: id,
        // });
        localStorage.setItem("solvProc", row.idPurchaseRequest+"|"+row.idIncome+","+row.idProvider+","+row.idWinner+"|"+row.keyStat);
        history.push("/template/revision-adquisiciones/detalle")
    }

    async function getData(order, records, page, search) {
        const res = await doRequestSaveRes(
            "receiver/receiver.php",
            getParams(order, records, page, search),
            false,
            false
        );
        return res;
    }
    
    const getParams = (order, records, page, search) => {
        const params = {
            action: "datatable",
            table: "purchaserequests A INNER JOIN status B ON A.keyStat=B.keyStat INNER JOIN areas C ON C.idArea=A.idArea INNER JOIN types_purchases D ON D.keyTypePurchase=A.keyTypePurchase INNER JOIN quotes E ON A.idQuote=E.idQuote INNER JOIN purchasewinners F ON F.idPurchaseRequest = A.idPurchaseRequest INNER JOIN providerquotes G ON G.idProviderQuote = F.idProviderQuote INNER JOIN providers H ON H.idProvider = G.idProvider INNER JOIN receptions I ON I.idWinner = F.idWinner INNER JOIN warehouseincomereceptions J ON J.idReception = I.idReception INNER JOIN warehouseincomes K ON K.idIncome = J.idIncome",
            rows: "A.idPurchaseRequest, K.idIncome, CONCAT(A.numberRequest,'/',A.yearRequest) AS solicitud, D.nameTypePurchase, E.object, C.name, H.socialName, B.nameStat,CONCAT(K.numberIncome,'/',K.yearIncome) AS entrada,C.idArea, H.idProvider, F.idWinner, K.keyStat",
            conditions: `A.enabled = 1 AND I.enabled = 1 AND J.enabled = 1 AND K.enabled = 1`,
            group: "K.idIncome",
            order,
            records: records.toString(),
            page: (page * records).toString(),
            search
        }
        return params
    }

    return (
        <Widget className="widget-p-md">
            <h3 className="text-secondary">Revisión de adquisiciones</h3>
            {true ? ( //permisos.CON
                    <Datatable
                        headers={headers}
                        columns={columns}
                        petition={getData}
                        stateRefresh={[refresh, setRefresh]}
                        control="back"
                        order={{ col: 0, opt: "desc" }}
                    />
            ) : (<div className="text-center p-4">No tienes permitido ver los registros de esta seccion</div>)}
        </Widget>
    )
}
import { CancelOutlined } from "@material-ui/icons";
import { useState } from "react";

import ModalTableVer from "../Modal/ModalTableVer";
import s from "./InputClear.module.scss";

export default function InputWithModal({ onChange, actionSelect, handleBlur, error, touched, value, name, label, title, onClear, cabeceras, filtro, parametros, disabled= false }) {
    const [modalTabla, setModalTabla] = useState(false)

    const onSelect = (el) => {
        actionSelect(el)
        setModalTabla(false);
    }

    return (
        <>
            <div className="form-group position-relative">
                <label htmlFor={name}>
                    {label} <span className="text-danger">*</span>
                </label>
                <div className="input-group">
                    <input
                        name={name}
                        onChange={onChange}
                        onBlur={handleBlur}
                        className={
                            "form-control" +
                            (error && touched
                                ? " is-invalid"
                                : "")
                        }
                        onClick={() => setModalTabla(true)}
                        value={value}
                        readOnly
                    />
                    <div className="input-group-append">
                        <button
                            className="btn btn-secondary"
                            style={{ zIndex: "auto" }}
                            type="button"
                            onClick={() => setModalTabla(true)}
                            disabled={disabled}
                        >
                            <i className="fa fa-search" />
                        </button>
                    </div>
                </div>
                {value !== "" && !disabled && (
                    <CancelOutlined
                        className={s.btnClear}
                        onClick={onClear}
                    />
                )}
                {touched && error && (
                    <span style={{ color: "red" }}>{error}</span>
                )}
            </div>
            <ModalTableVer
                modal={modalTabla}
                setModal={setModalTabla}
                title={title}
                cabeceras={cabeceras}
                filtro={filtro}
                parametros={parametros}
                selectRegistro={onSelect}
                backdrop
                keyboard
                btnClear
            />
        </>
    )
}
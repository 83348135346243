import { Document, Page, Text, View } from "@react-pdf/renderer";
import { styles } from "../../../../components/Pdf/pdfGeneral/EstiloPdf";
import HeaderReport from "../../../../components/Pdf/Header";
import { fMoney, fPercent } from "../../../../libs/tools/format";

const typeHeaderCell = (tam, index) => {
  if (index === 0) {
    return tam === 100
      ? styles.cellHeader100Borders
      : tam === 75
      ? styles.cellHeader75Borders
      : tam === 50
      ? styles.cellHeader50Borders
      : tam === 25
      ? styles.cellHeader25Borders
      : tam === 11
      ? styles.cellHeader11Borders
      : "";
  } else {
    return tam === 100
      ? styles.cellContent100LBR
      : tam === 88.7
      ? styles.cellContent88_7BordersLBR
      : tam === 75
      ? styles.cellHeader75BordersRTB
      : tam === 50
      ? styles.cellHeader50BordersRTB
      : tam === 25
      ? styles.cellHeader25BordersRTB
      : tam === 11
      ? styles.cellHeader11BordersRTB
      : "";
  }
};

const insertHeaders = (model) => {
  return (
    <>
      <View style={styles.tableRow} fixed>
        {model.headers.map((obj, index) => (
          <View style={[typeHeaderCell(25, index),{fontSize:4}]} key={"rh"+index}>
            <Text>{obj}</Text>
          </View>
        ))}
      </View>
    </>
  );
};

const insertContent = (data) => {
  return (
    <>
      {data.map((obj, ix) => (
        <View style={[styles.tableRow, {height:50}]} wrap={false} key={"rd"+ix}>
          <View style={[styles.cellContent25BordersLBR, {fontSize:4}]}>
            <Text>{obj.numberPension}</Text>
          </View>
          <View style={[styles.cellContent25BordersRB,{fontSize:4}]}>
            <Text>{obj.staffNumber}</Text>
          </View>
          <View style={[styles.cellContent25BordersRB,{fontSize:4}]}>
            <Text>{obj.nombreCompleto}</Text>
          </View>
          <View style={[styles.cellContent25BordersRB,{fontSize:4}]}>
            <Text>{obj.nameJob}</Text>
          </View>
          <View style={[styles.cellContent25BordersRB,{fontSize:4}]}>
            <Text>{obj.rango}</Text>
          </View>
          <View style={[styles.cellContent25BordersRB,{fontSize:4}]}>
            <Text>{obj.beneficiario}</Text>
          </View>
          <View style={[styles.cellContent25BordersRB,{fontSize:4}]}>
            <Text>{obj.nameTypePension}</Text>
          </View>
          <View style={[styles.cellContent25BordersRB,{fontSize:4}]}>
            <Text>{obj.porcentage === "0" ? "CANTIDAD M/N" : "PORCENTAJE %"}</Text>
          </View>
          <View style={[styles.cellContent25BordersRB,{fontSize:4}]}>
            <Text>{obj.porcentage === "0" ? fMoney(obj.amount) : fPercent(obj.porcentage)}</Text>
          </View>
          <View style={[styles.cellContent25BordersRB,{fontSize:4}]}>
            <Text>{obj.namePaymentType}</Text>
          </View>
          <View style={[styles.cellContent25BordersRB,{fontSize:4}]}>
            <Text>{obj.nameBank}</Text>
          </View>
          <View style={[styles.cellContent25BordersRB,{fontSize:4}]}>
            <Text>{obj.account}</Text>
          </View>
          <View style={[styles.cellContent25BordersRB,{fontSize:4}]}>
            <Text>{obj.special === "0" ? "SI" : "NO"}</Text>
          </View>
        </View>

      ))}
    </>
  );
};

const insertTitles = (model, title) => {
  return (
    <>
      <HeaderReport/>
      <View>
        <Text style={styles.title}>{title}</Text>
      </View>
      <View>
        <Text style={styles.subTitle}>
          {model.filters !== undefined
            ? model.filters.onlyMessage === true
              ? model.filters.message
              : ""
            : ""}
        </Text>
      </View>
    </>
  );
};

const insertFooter = (fileNo) => {
  return (
    <>
      <View fixed style={styles.spaceFooter}></View>
      <View fixed style={styles.footer}>
        <Text style={styles.textFooter}>{fileNo}</Text>
        <Text
          style={styles.textFooter}
          render={({ pageNumber, totalPages }) =>
            `PAGINA : ${pageNumber} / ${totalPages}`
          }
        />
      </View>
    </>
  );
};

const generateReport = (data, model, title, fileNo) => {
  return (
    <Document>
      <Page style={styles.body}>
        <View style={styles.tableCell}>
          {insertTitles(model, title)}
          {insertHeaders(model)}
          {insertContent(data)}

          <View style={styles.tableRow}></View>
        </View>
        {insertFooter(fileNo)}
      </Page>
    </Document>
  );
};
export function generatePdfDetallePensiones(
  data,
  model,
  title,
  fileNo,
  acumNeto,
  acumAmount,
  final
) {
  const report = generateReport(
    data,
    model,
    title,
    fileNo,
    acumNeto,
    acumAmount,
    final
  );
  return {
    report: report,
  };
}

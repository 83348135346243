import { Button } from "reactstrap";
import { useState } from "react";
import TableComponentCustom from "../../../../../components/TableComponent/TableComponentCustom";
import ModalComponent from "../../../../../components/Modal/ModalDelete";
import CurrencyInput from "react-currency-input-field";
import ModalConfirmation from "../../../../../components/Modal/ModalConfirmation";
import { peticionesReceiver } from "../../../../../helpers/peticionesReceiver";
import { toast } from "react-toastify";
import Notification from "../../../../../components/Notification/Notification";

export default function TableEmpleados({ data, params, setParams, setDisabled }) {
    const API = peticionesReceiver();
    const cabeceras = ["Id", "Concepto", "Nombre", "RFC", "Deducciones", "Acciones"];

    const [deducc, setDeducc] = useState([])
    const [modal, setModal] = useState(false);

    const onDelete = async (fields) => {
        const API = peticionesReceiver();
        const params = {
            action: "delete",
            table: "isrstaffreports",
            condition: {
                idIsrstaffreport: fields[0]
            },
        };
        try {
            const res = await API.peticion(params)
            if (res.status === 200 && res.data.code === "200") {
                toast(<Notification type={"elimina_exito"} backMessage={res.data.message} withIcon />);
                setParams({
                    action: "datatable",
                    table: "isrstaffreports rep inner join staff s using(idStaff) inner join isrunders isr using(keyIsrunder)",
                    rows: "rep.idIsrstaffreport,isr.nameIsrunder,rep.amount,concat(s.staffNumber,' - ', s.name, ' ', s.pName, ' ', s.mName),s.rfc",
                    conditions: `rep.enabled = 1 AND rep.idIsrmonthlyreport = ${data.idIsrmonthlyreport}`,
                    page: "0",
                    records: "5",
                    search: "",
                    order: ""
                })
            } else {
                toast(<Notification type={"elimina_error"} backMessage={res.data.message} withIcon />);
            }
        } catch (err) {
            toast(<Notification type={"elimina_error"} withIcon />);
        }
    }

    const onEdit = async (values) => {
        await Promise.all(
            values.map(async deduc => {
                const formData = {
                    action: "update",
                    table: "isrstaffreports",
                    rows: deduc,
                    condition: {
                        idIsrstaffreport: deduc.idIsrstaffreport,
                    },
                };
                try {
                    const res = await API.peticion(formData)
                    if (res.status === 200 && res.data.code === "200") {
                        toast(<Notification type={"agrega_exito"} backMessage={res.data.message} withIcon />);
                    } else {
                        toast(<Notification type={"agrega_error"} backMessage={res.data.message} withIcon />);
                    }
                } catch (err) {
                    console.log(err);
                    toast(<Notification type={"agrega_error"} withIcon />);
                }

            })
        )
        setDeducc([]);
        setDisabled(false);
        setParams({
            action: "datatable",
            table: "isrstaffreports rep inner join staff s using(idStaff) inner join isrunders isr using(keyIsrunder)",
            rows: "rep.idIsrstaffreport,isr.nameIsrunder,rep.amount,concat(s.staffNumber,' - ', s.name, ' ', s.pName, ' ', s.mName),s.rfc",
            conditions: `rep.enabled = 1 AND rep.idIsrmonthlyreport = ${data.idIsrmonthlyreport}`,
            page: "0",
            records: "5",
            search: "",
            order: ""
        })
    }

    function getDedduc(idIsrstaffreport) {
        const item = deducc.find(ded => ded.idIsrstaffreport === idIsrstaffreport);
        return item
    }

    function appendToPending(idIsrstaffreport, amount) {
        const item = getDedduc(idIsrstaffreport)
        if (item) {
            item.amount = amount;
        } else {
            deducc.push({ idIsrstaffreport, amount });
            setDisabled(true)
        }
    }

    const deducciones = (row, key) => {
        const item = getDedduc(row[0]);
        let deducci = item ? item.amount : row[2];
        return (<>
            <CurrencyInput
                id={key}
                name={key}
                defaultValue={deducci}
                decimalsLimit={2}
                className={"form-control"}
                allowDecimals={true}
                allowNegativeValue={false}
                prefix="$"
                decimalSeparator="."
                groupSeparator=","
                onValueChange={(value, name) => appendToPending(row[0], value)}
            />
        </>)
    }

    const deleteRow = (row, key) => {
        return (<>
            <ModalComponent
                modalTitle="Eliminar"
                id={row[0]}
                table={["isrstaffreports"]}
                nameColumn={["idIsrstaffreport"]}
                elimina={() => onDelete(row)}
            >
                <h6>
                    ¿Seguro de eliminar el registro "{row.nameFurniture}"?
                </h6>
                No podrá recuperar la información después de ser eliminada
            </ModalComponent>
        </>)
    }


    return (
        <>
            <TableComponentCustom
                titulo="Registros actuales"
                cabecerasTabla={cabeceras}
                filtro={[true, true, false, true, true]}
                parametros={params}
                specialColumns={[deducciones, deleteRow]}
            />
            <div className="text-center">
                <Button color="danger" className="mx-2" onClick={() => window.location.href = "#/template/reportes-finanzas"}>Regresar</Button>
                <Button color="success" disabled={deducc.length === 0} onClick={() => setModal(true)}>Guardar</Button>
            </div>
            <ModalConfirmation
                modalTitle={"Guardar cambios"}
                modal={modal}
                crear={(rows) => onEdit(rows)}
                setModal={setModal}
                isEdit={false}
                values={deducc}
            >
                <div className="d-flex justify-content-center">
                    <h6>¿Desea guardar los cambios?</h6>
                </div>
            </ModalConfirmation>
        </>
    )
}
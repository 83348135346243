import { Document, Page, Text, View, StyleSheet } from "@react-pdf/renderer";
import { styles } from "../../../../components/Pdf/pdfGeneral/EstiloPdf";
import { sumValues } from "../../../../libs/tools/arrays";
import HeaderReport from "../../../../components/Pdf/Header";

/**
 * Estilo que se repite en todas los headers de la tabla
 */
const styleHeadForAll = {
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "#646464",
    color: 'white',
    textAlign: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 9
}

const styleCellForAll = {
    borderStyle: "solid",
    borderWidth: 1,
    borderTopWidth: 0,
    fontSize: 9
}

/**
 * Estilos del pdf
 */
const s = StyleSheet.create({
    subTitle: { //SUBTITULOS
        width: "100%",
        textAlign: 'center',
        marginBottom: 10,
        marginLeft: 10,
        fontSize: 14
    },
    f6: {
        fontSize: 6
    },
    f5: {
        fontSize: 5
    },
    f8: {
        fontSize: 8
    },
    f10: {
        fontSize: 10
    },
    thIn: {
        ...styleHeadForAll
    },
    th: {
        ...styleHeadForAll,
        borderLeftWidth: 0
    },
    w5: {
        width: "5%"
    },
    w7: {
        width: "7%"
    },
    w8: {
        width: "8%"
    },
    w10: {
        width: "10%"
    },
    w12: {
        width: "10%"
    },
    w20: {
        width: "20%"
    },
    w18: {
        width: "18%"
    },
    w22: {
        width: "22%"
    },
    w25: {
        width: "25%"
    },
    w30: {
        width: "30%"
    },
    w33: {
        width: "33%"
    },
    w34: {
        width: "34%"
    },
    w40: {
        width: "40%"
    },
    w50: {
        width: "50%"
    },
    w60: {
        width: "60%"
    },
    w70: {
        width: "70%"
    },
    w90: {
        width: "90%"
    },
    w100: {
        width: "100%"
    },
    mt2: {
        marginTop: 20
    },
    px2: {
        paddingHorizontal: 10
    },
    px1: {
        paddingHorizontal: 5
    },
    bt0: {
        borderTopWidth: 0
    },
    center: {
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
    },
    textBold: {
        fontFamily: 'Helvetica-Bold'
    },
    textRight: {
        justifyContent: 'center',
        textAlign: "right"
    },
    textLeft: {
        justifyContent: 'center',
        textAlign: "left"
    },
    tdIn: { //Cell CC
        ...styleCellForAll
    },
    td: { //Cell Concepto
        ...styleCellForAll,
        borderLeftWidth: 0
    },
    bt1: {
        borderTopWidth: 1
    },
    td0: { //Cell Concepto
        ...styleCellForAll,
        borderLeftWidth: 0,
        borderRightWidthWidth: 0
    },
    red: {
        backgroundColor: "red",
    },
    green: {
        backgroundColor: "green",
    },
    gray: {
        backgroundColor: "gray",
    }
})


const formatter = new Intl.NumberFormat("es-MX", {
    style: "currency",
    currency: "MXN",
});

function toDate(date) {
    const arr = date.split("-")
    if (arr[1].length === 1) {
        arr[1] = "0" + arr[1]
    }
    return `${arr[2]}/${arr[1]}/${arr[0]}`
}

export default function exportPrisma(data, model, title, fileNo) {
    const ori = model.orientation === "h" ? "landscape" : "portrait";

    const rep = data[0]

    const subTitle = model.subTitle

    function getWidth(width) {
        switch (width) {
            case 5:
                return s.w5
            case 7:
                return s.w7
            case 8:
                return s.w8
            case 10:
                return s.w10;
            case 12:
                return s.w12;
            case 20:
                return s.w20;
            case 22:
                return s.w22;
            case 30:
                return s.w30;
            case 40:
                return s.w40;
            case 50:
                return s.w50;
            case 60:
                return s.w60;
            case 70:
                return s.w70;
            case 80:
                return s.w80;
            case 90:
                return s.w90;
            default:
                return s.w100
        }
    }

    const cabecerasArrays = ["CLAVE DE PROYECTO", "TOTAL", "", "", "", "CLAVE DE PROYECTO", "TOTAL"]
    const claves = ["1412 - SERVICIOS DE SALUD", "1413 - FONDO SOLIDARIO DE REPARTO", "1414 - APORTACIONES OBLIGATORIAS SCI", "1415 - GASTOS DE ADMINISTRACIÓN", "1416 - RIESGOS DE TRABAJO"]
    const prismas = ["aporServSalud", "aporFondoSolRep", "aporSCIOblig", "aporGastosAdmon", "aporRT"]
    const access = ["accServSalud", "accFondoSolRep", "accSCIOblig", "accGastosAdmon", "accRT"]

    let acumudadoTP = 0
    let acumudadoTS = 0

    let total = Number(rep.cuotaServSalud) + Number(rep.cuotaServSalud) + Number(rep.cuotaFondSolRep) + Number(rep.cuotaSCIOblig) + Number(rep.cuotaSCIVol) + Number(rep.retInstitucionales) + Number(rep.retTerceros)

    return {
        report: (
            <>
                <Document>
                    <Page style={s.body} orientation={ori}>
                        <HeaderReport />
                        <View>
                            <Text style={s.subTitle}>{title}</Text>
                            <Text style={s.subTitle}>{subTitle}</Text>
                        </View>
                        <View style={styles.table}>
                            <View style={styles.tableRow} fixed>
                                {model.content.headersConceptos.map((header, index) => (
                                    <View style={[index === 0 ? s.thIn : s.th, getWidth(model.sizesCon[index])]}>
                                        <Text>{header}</Text>
                                    </View>
                                ))}
                            </View>
                            {model.content.aportaciones.map(value => (
                                <View style={styles.tableRow} fixed>
                                    <View style={[s.tdIn, getWidth(model.sizesCon[0])]}>
                                        <Text>{value.header}</Text>
                                    </View>
                                    {value.values.map((key, ind) => (
                                        <View style={[s.td, s.textBold, ind > 0 ? s.textRight : s.center, getWidth(model.sizesCon[ind + 1])]}>
                                            <Text>{key !== "" ? (ind > 0 && key !== "datePrisma" ? formatter.format(rep[key]) : toDate(rep[key])) : ""}</Text>
                                        </View>
                                    ))}
                                </View>
                            ))}
                            <View style={styles.tableRow} fixed>
                                <View style={[s.tdIn, s.textRight, s.textBold, getWidth(model.sizes[0] + model.sizes[1])]}>
                                    <Text>APORTACIONES</Text>
                                </View>
                                <View style={[s.td, s.textRight, s.textBold, getWidth(model.sizes[2])]}>
                                    <Text>{formatter.format(rep[model.content.sumaAportaciones[0]])}</Text>
                                </View>
                                <View style={[getWidth(model.sizes[3] + model.sizes[4])]}>
                                </View>
                                <View style={[s.tdIn, s.textRight, s.textBold, getWidth(model.sizes[5])]}>
                                    <Text>{formatter.format(rep[model.content.sumaAportaciones[1]])}</Text>
                                </View>
                                <View style={[s.td, s.textRight, s.textBold, getWidth(model.sizes[6])]}>
                                    <Text>{formatter.format(rep[model.content.sumaAportaciones[2]])}</Text>
                                </View>
                            </View>
                        </View>
                        <View style={[styles.table, styles.mt2]}>
                            {model.content.cuotas.map(value => (
                                <View style={styles.tableRow} fixed>
                                    <View style={[s.tdIn, s.mt1, getWidth(model.sizesCon[0])]}>
                                        <Text>{value.header}</Text>
                                    </View>
                                    {value.values.map((key, ind) => (
                                        <View style={[s.td, s.mt1, s.textBold, ind > 0 ? s.textRight : s.center, getWidth(model.sizesCon[ind + 1])]}>
                                            <Text>{key !== "" ? (ind > 0 && key !== "datePrisma" ? formatter.format(rep[key]) : toDate(rep[key])) : ""}</Text>
                                        </View>
                                    ))}
                                </View>
                            ))}
                            <View style={styles.tableRow} fixed>
                                <View style={[s.tdIn, s.textRight, s.textBold, getWidth(model.sizes[0] + model.sizes[1])]}>
                                    <Text>CUOTAS</Text>
                                </View>
                                <View style={[s.td, s.textRight, s.textBold, getWidth(model.sizes[2])]}>
                                    <Text>{formatter.format(rep[model.content.sumaCuotas[0]])}</Text>
                                </View>
                                <View style={[getWidth(model.sizes[3] + model.sizes[4])]}>
                                </View>
                                <View style={[s.tdIn, s.textRight, s.textBold, getWidth(model.sizes[5])]}>
                                    <Text>{formatter.format(rep[model.content.sumaCuotas[1]])}</Text>
                                </View>
                                <View style={[s.td, s.textRight, s.textBold, getWidth(model.sizes[6])]}>
                                    <Text>{formatter.format(rep[model.content.sumaCuotas[2]])}</Text>
                                </View>
                            </View>
                        </View>
                        <View style={[styles.table, styles.mt2]}>
                            <View style={styles.tableRow} fixed>
                                <View style={[s.tdIn, s.bt1, s.textRight, s.textBold, getWidth(model.sizes[0] + model.sizes[1])]}>
                                    <Text>DETERMINACIÓN</Text>
                                </View>
                                <View style={[s.td, s.bt1, s.textRight, s.textBold, getWidth(model.sizes[2])]}>
                                    <Text>{formatter.format(rep[model.content.sumaDeterminacion[0]])}</Text>
                                </View>
                                <View style={[getWidth(model.sizes[3] + model.sizes[4])]}>
                                </View>
                                <View style={[s.tdIn, s.bt1, s.textRight, s.textBold, getWidth(model.sizes[5])]}>
                                    <Text>{formatter.format(rep[model.content.sumaDeterminacion[1]])}</Text>
                                </View>
                                <View style={[s.td, s.bt1, s.textRight, s.textBold, getWidth(model.sizes[6])]}>
                                    <Text>{formatter.format(rep[model.content.sumaDeterminacion[2]])}</Text>
                                </View>
                            </View>
                        </View>
                    </Page>
                    {model.content.arrays.map((array, index) => (
                        <Page style={s.body} orientation={ori}>
                            <View style={styles.table}>
                                <View style={styles.tableRow}>
                                    <View style={[s.thIn, s.w100]}>
                                        <Text>{claves[index]}</Text>
                                    </View>
                                </View>
                                <View style={styles.tableRow}>
                                    <View style={[s.thIn, s.bt0, getWidth(model.sizes[0] + model.sizes[1])]}>
                                        <Text>SAPP</Text>
                                    </View>
                                    <View style={[getWidth(model.sizes[2] + model.sizes[3] + model.sizes[4])]}>
                                    </View>
                                    <View style={[s.thIn, s.bt0, getWidth(model.sizes[5] + model.sizes[6])]}>
                                        <Text>INTEGRACIÓN</Text>
                                    </View>
                                </View>
                                <View style={styles.tableRow}>
                                    {cabecerasArrays.map((header, index) => (
                                        <View style={[index === 0 ? s.thIn : (index === 5 ? s.thIn : index === 1 || index === 6 ? s.th : ""), getWidth(model.sizes[index], s.bt0)]}>
                                            <Text>{header}</Text>
                                        </View>
                                    ))}
                                </View>

                                {Object.entries(rep[array].data).map(([key, obj]) => (
                                    <View style={styles.tableRow}>
                                        <View style={[s.tdIn, getWidth(model.sizes[0]), s.center]}>
                                            <Text>{key}</Text>
                                        </View>
                                        <View style={[s.td, getWidth(model.sizes[1]), s.textRight]}>
                                            <Text>{formatter.format(obj.valorPrimero)}</Text>
                                        </View>
                                        <View style={[getWidth(model.sizes[2] + model.sizes[3] + model.sizes[4])]}>
                                        </View>
                                        <View style={[s.tdIn, getWidth(model.sizes[5]), s.center]}>
                                            <Text>{key}</Text>
                                        </View>
                                        <View style={[s.td, getWidth(model.sizes[6]), s.textRight]}>
                                            <Text>{formatter.format(obj.valorSegundo)}</Text>
                                        </View>
                                    </View>
                                ))}
                                {acumudadoTP += Number(rep[array].totalPrimero)}
                                {acumudadoTS += Number(rep[array].totalSegundo)}
                                <View style={styles.tableRow}>
                                    <View style={[getWidth(model.sizes[0] + model.sizes[1])]}></View>
                                    <View style={[s.tdIn, getWidth(model.sizes[2]), s.textRight, s.bt1]}>
                                        <Text>PRISMA</Text>
                                    </View>
                                    <View style={[s.td, getWidth(model.sizes[3]), s.textRight, s.bt1]}>
                                        <Text>DIFERENCIA</Text>
                                    </View>
                                    <View style={[getWidth(model.sizes[4] + model.sizes[5])]}>
                                    </View>
                                    <View style={[s.tdIn, getWidth(model.sizes[6]), s.textRight]}>
                                        <Text>{formatter.format(rep[array].totalSegundo)}</Text>
                                    </View>
                                    <View style={[s.tdIn, s.bt1, getWidth(model.sizes[7] + model.sizes[8]), s.textRight]}>
                                        <Text>ACCESORIOS</Text>
                                    </View>
                                    <View style={[s.td, s.bt1, getWidth(model.sizes[9]), s.textRight]}>
                                        <Text>TOTAL</Text>
                                    </View>
                                </View>
                                <View style={styles.tableRow}>
                                    <View style={[s.tdIn, getWidth(model.sizes[0]), s.textRight, s.bt1]}>
                                        <Text>TOTAL</Text>
                                    </View>
                                    <View style={[s.td, getWidth(model.sizes[1]), s.textRight, s.bt1]}>
                                        <Text>{formatter.format(rep[array].totalPrimero)}</Text>
                                    </View>
                                    <View style={[s.td, getWidth(model.sizes[2]), s.textRight]}>
                                        <Text>{formatter.format(rep[prismas[index]])}</Text>
                                    </View>
                                    <View style={[s.td, getWidth(model.sizes[3]), s.textRight]}>
                                        <Text>{formatter.format(rep[array].diferenciaPrisma)}</Text>
                                    </View>
                                    <View style={[getWidth(model.sizes[4])]}>
                                    </View>
                                    <View style={[s.tdIn, getWidth(model.sizes[5]), s.textRight, s.bt1]}>
                                        <Text>TOTAL</Text>
                                    </View>
                                    <View style={[s.td, getWidth(model.sizes[6]), s.textRight]}>
                                        <Text>{formatter.format(rep[array].totalSegundo)}</Text>
                                    </View>
                                    <View style={[s.td, getWidth(model.sizes[7] + model.sizes[8]), s.textRight]}>
                                        <Text>{formatter.format(rep[access[index]])}</Text>
                                    </View>
                                    <View style={[s.td, s.bt1, getWidth(model.sizes[9]), s.textRight]}>
                                        <Text>{formatter.format(Number(rep[array].totalSegundo) + Number(rep[access[index]]))}</Text>
                                    </View>
                                </View>
                            </View>
                            {index > 0 ? (
                                <View style={[styles.table, s.mt2]}>
                                    <View style={styles.tableRow}>
                                        <View style={[s.tdIn, getWidth(model.sizes[0]), s.textRight, s.bt1]}>
                                            <Text>ACUMULADO</Text>
                                        </View>
                                        <View style={[s.td, getWidth(model.sizes[1]), s.textRight, s.bt1]}>
                                            <Text>{formatter.format(acumudadoTP)}</Text>
                                        </View>
                                        <View style={getWidth(model.sizes[2] + model.sizes[3] + model.sizes[4] + model.sizes[5])}>
                                        </View>
                                        <View style={[s.tdIn, getWidth(model.sizes[6]), s.textRight, s.bt1]}>
                                            <Text>{formatter.format(acumudadoTS)}</Text>
                                        </View>
                                    </View>
                                </View>
                            ) : null}
                        </Page>
                    ))}
                    <Page style={s.body} orientation={ori}>
                        <View style={styles.table}>
                            <View style={styles.tableRow}>
                                <View style={[s.thIn, getWidth(model.sizes[0])]}>
                                    <Text>1346</Text>
                                </View>
                                <View style={[s.th, getWidth(model.sizes[1])]}>
                                    <Text>TOTAL</Text>
                                </View>
                            </View>
                            <View style={styles.tableRow}>
                                <View style={[s.tdIn, getWidth(model.sizes[0])]}>
                                    <Text>APORTACIÓN EXTRAORDINARIA</Text>
                                </View>
                                <View style={[s.td, getWidth(model.sizes[1])]}>
                                    <Text>{formatter.format(rep.aporExtraordinaria)}</Text>
                                </View>
                            </View>
                        </View>
                        <View style={[styles.table, s.mt2]}>
                            <View style={styles.tableRow}>
                                <View style={[s.tdIn, s.bt1, getWidth(model.sizes[0])]}>
                                    <Text>TOTAL</Text>
                                </View>
                                <View style={[s.td, s.bt1, getWidth(model.sizes[1])]}>
                                    <Text>{formatter.format(rep.aporExtraordinaria)}</Text>
                                </View>
                            </View>
                        </View>
                        <View style={[styles.table, s.mt2]}>
                            <View style={styles.tableRow}>
                                <View style={[s.thIn, getWidth(model.sizes[0])]}>
                                    <Text>PRISMA</Text>
                                </View>
                                <View style={[s.th, getWidth(model.sizes[1])]}>
                                    <Text>TOTAL</Text>
                                </View>
                                <View style={getWidth(model.sizes[2] + model.sizes[3])}>
                                </View>
                                <View style={[s.thIn, getWidth(model.sizes[4] + model.sizes[5])]}>
                                    <Text>SAPP</Text>
                                </View>
                                <View style={[s.th, getWidth(model.sizes[6])]}>
                                    <Text>TOTAL</Text>
                                </View>
                                <View style={getWidth(model.sizes[7] + model.sizes[8])}>
                                </View>
                                <View style={[s.thIn, getWidth(model.sizes[9])]}>
                                    <Text>DIFERENCIA</Text>
                                </View>
                            </View>
                            <View style={styles.tableRow}>
                                <View style={[s.thIn, s.bt0, s.textLeft, getWidth(model.sizes[0])]}>
                                    <Text>CUOTA POR SERVICIO DE SALUD</Text>
                                </View>
                                <View style={[s.td, s.textLeft, getWidth(model.sizes[1])]}>
                                    <Text>{formatter.format(rep.cuotaServSalud)}</Text>
                                </View>
                                <View style={getWidth(model.sizes[2] + model.sizes[3])}>
                                </View>
                                <View style={[s.thIn, s.bt0, s.textLeft, getWidth(model.sizes[4] + model.sizes[5])]}>
                                    <Text>CUOTA POR SERVICIO DE SALUD</Text>
                                </View>
                                <View style={[s.td, s.textLeft, getWidth(model.sizes[6])]}>
                                    <Text>{formatter.format(0)}</Text>
                                </View>
                                <View style={getWidth(model.sizes[7] + model.sizes[8])}>
                                </View>
                                <View style={[s.tdIn, s.textLeft, getWidth(model.sizes[9])]}>
                                    <Text>{formatter.format(0)}</Text>
                                </View>
                            </View>
                            <View style={styles.tableRow}>
                                <View style={[s.thIn, s.bt0, s.textLeft, getWidth(model.sizes[0])]}>
                                    <Text>CUOTA POR FONDO SOLIDARIO DE REPARTO</Text>
                                </View>
                                <View style={[s.td, s.textLeft, getWidth(model.sizes[1])]}>
                                    <Text>{formatter.format(rep.cuotaFondSolRep)}</Text>
                                </View>
                                <View style={getWidth(model.sizes[2] + model.sizes[3])}>
                                </View>
                                <View style={[s.thIn, s.bt0, s.textLeft, getWidth(model.sizes[4] + model.sizes[5])]}>
                                    <Text>CUOTA POR FONDO SOLIDARIO DE REPARTO</Text>
                                </View>
                                <View style={[s.td, s.textLeft, getWidth(model.sizes[6])]}>
                                    <Text>{formatter.format(0)}</Text>
                                </View>
                                <View style={getWidth(model.sizes[7] + model.sizes[8])}>
                                </View>
                                <View style={[s.tdIn, s.textLeft, getWidth(model.sizes[9])]}>
                                    <Text>{formatter.format(0)}</Text>
                                </View>
                            </View>
                            <View style={styles.tableRow}>
                                <View style={[s.thIn, s.bt0, s.textLeft, getWidth(model.sizes[0])]}>
                                    <Text>CUOTA SCI OBLIGATORIO</Text>
                                </View>
                                <View style={[s.td, s.textLeft, getWidth(model.sizes[1])]}>
                                    <Text>{formatter.format(rep.cuotaSCIOblig)}</Text>
                                </View>
                                <View style={getWidth(model.sizes[2] + model.sizes[3])}>
                                </View>
                                <View style={[s.thIn, s.bt0, s.textLeft, getWidth(model.sizes[4] + model.sizes[5])]}>
                                    <Text>CUOTA SCI OBLIGATORIO</Text>
                                </View>
                                <View style={[s.td, s.textLeft, getWidth(model.sizes[6])]}>
                                    <Text>{formatter.format(0)}</Text>
                                </View>
                                <View style={getWidth(model.sizes[7] + model.sizes[8])}>
                                </View>
                                <View style={[s.tdIn, s.textLeft, getWidth(model.sizes[9])]}>
                                    <Text>{formatter.format(0)}</Text>
                                </View>
                            </View>
                            <View style={styles.tableRow}>
                                <View style={[s.thIn, s.bt0, s.textLeft, getWidth(model.sizes[0])]}>
                                    <Text>CUOTA SCI VOLUNTARIO</Text>
                                </View>
                                <View style={[s.td, s.textLeft, getWidth(model.sizes[1])]}>
                                    <Text>{formatter.format(rep.cuotaSCIVol)}</Text>
                                </View>
                                <View style={getWidth(model.sizes[2] + model.sizes[3])}>
                                </View>
                                <View style={[s.thIn, s.bt0, s.textLeft, getWidth(model.sizes[4] + model.sizes[5])]}>
                                    <Text>CUOTA SCI VOLUNTARIO</Text>
                                </View>
                                <View style={[s.td, s.textLeft, getWidth(model.sizes[6])]}>
                                    <Text>{formatter.format(0)}</Text>
                                </View>
                                <View style={getWidth(model.sizes[7] + model.sizes[8])}>
                                </View>
                                <View style={[s.tdIn, s.textLeft, getWidth(model.sizes[9])]}>
                                    <Text>{formatter.format(0)}</Text>
                                </View>
                            </View>
                            <View style={styles.tableRow}>
                                <View style={[s.thIn, s.bt0, s.textLeft, getWidth(model.sizes[0])]}>
                                    <Text>
                                        RETENCIONES INSTITUCIONALES
                                    </Text>
                                    <Text>
                                        +
                                    </Text>
                                    <Text>
                                        RETENCIONES A TERCEROS
                                    </Text>
                                </View>
                                <View style={[s.td, s.textLeft, getWidth(model.sizes[1])]}>
                                    <Text>{formatter.format(Number(rep.retInstitucionales) + Number(rep.retTerceros))}</Text>
                                </View>
                                <View style={getWidth(model.sizes[2] + model.sizes[3])}>
                                </View>
                                <View style={[s.thIn, s.bt0, s.textLeft, getWidth(model.sizes[4] + model.sizes[5])]}>
                                    <Text>
                                        RETENCIONES INSTITUCIONALES
                                    </Text>
                                    <Text>
                                        +
                                    </Text>
                                    <Text>
                                        RETENCIONES A TERCEROS
                                    </Text>
                                </View>
                                <View style={[s.td, s.textLeft, getWidth(model.sizes[6])]}>
                                    <Text>{formatter.format(0)}</Text>
                                </View>
                                <View style={getWidth(model.sizes[7] + model.sizes[8])}>
                                </View>
                                <View style={[s.tdIn, s.textLeft, getWidth(model.sizes[9])]}>
                                    <Text>{formatter.format(0)}</Text>
                                </View>
                            </View>
                            <View style={styles.tableRow}>
                                <View style={[s.thIn, s.bt0, s.textLeft, getWidth(model.sizes[0])]}>
                                    <Text>
                                        TOTAL
                                    </Text>
                                </View>
                                <View style={[s.td, s.textLeft, getWidth(model.sizes[1])]}>
                                    <Text>{formatter.format(total)}</Text>
                                </View>
                                <View style={getWidth(model.sizes[2] + model.sizes[3])}>
                                </View>
                                <View style={[s.thIn, s.bt0, s.textLeft, getWidth(model.sizes[4] + model.sizes[5])]}>
                                    <Text>
                                        TOTAL
                                    </Text>
                                </View>
                                <View style={[s.td, s.textLeft, getWidth(model.sizes[6])]}>
                                    <Text>{formatter.format(0)}</Text>
                                </View>
                                <View style={getWidth(model.sizes[7] + model.sizes[8])}>
                                </View>
                                <View style={[s.tdIn, s.textLeft, getWidth(model.sizes[9])]}>
                                    <Text>{formatter.format(0)}</Text>
                                </View>
                            </View>
                        </View>
                        <View style={[styles.table, s.mt2]}>
                            <View style={styles.tableRow}>
                                <View style={[s.thIn, s.bt0, s.textRight, getWidth(model.sizes[0])]}>
                                    <Text>
                                        TOTAL A PAGAR
                                    </Text>
                                </View>
                                <View style={[s.td, s.textLeft, s.bt1, getWidth(model.sizes[1])]}>
                                    <Text>{formatter.format(total + acumudadoTP + Number(rep.aporExtraordinaria) + Number(rep.cuotaExtraordinaria))}</Text>
                                </View>
                                <View style={getWidth(model.sizes[2] + model.sizes[3])}>
                                </View>
                                <View style={[s.thIn, s.bt0, s.textRight, getWidth(model.sizes[4] + model.sizes[5])]}>
                                    <Text>
                                        TOTAL A PAGAR
                                    </Text>
                                </View>
                                <View style={[s.td, s.textLeft, s.bt1, getWidth(model.sizes[6])]}>
                                    <Text>{formatter.format(0)}</Text>
                                </View>
                                <View style={getWidth(model.sizes[7] + model.sizes[8])}>
                                </View>
                                <View style={[s.tdIn, s.textLeft, s.bt1, getWidth(model.sizes[9])]}>
                                    <Text>{formatter.format(0)}</Text>
                                </View>
                            </View>
                        </View>
                    </Page>
                </Document>
            </>
        )
    };
}
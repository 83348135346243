/* eslint-disable eqeqeq */
import React, { useEffect, useState } from 'react';
import { Col, Row, Button, Collapse } from "reactstrap";
import { SelectTypeHeadSingle, TextInput, CheckBoxInput, TextAreaInput } from '../../../../components/GenericInputsFormik';
import { NumberInput } from '../../../../components/GenericInputsFormik/NumberInput';
import { useFormik } from "formik";
import * as Yup from "yup";
import ModalConfirmation from '../../../../components/Modal/ModalConfirmation';
import Loader from '../../../../components/Loader/Loader';
import { toast } from "react-toastify";
import Notification from '../../../../components/Notification/Notification';
import { peticionesReceiver } from '../../../../helpers/peticionesReceiver';
import { createOficioPrestador, updateOficioPrestador } from './functions';
import { format } from "date-fns";

const validMoves = ["ALTA", "REINGRESO", "REASIGNACIÓN", "BAJA"];

export default function FormContrato({ title, setTitle, data, setData, employee, setEmployee, noEmp, setParametros, isOpen, setIsOpen, genReport, permissions }) {
    const API = peticionesReceiver();
    //LOADERS
    const [isLoading, setIsLoading] = useState(true); //CARGA TOTAL


    const [loadData, setLoadData] = useState(true); //INFORMACIÓN
    const [loadTypes, setLoadTypes] = useState(true) //TIPOS 
    const [loadAreasJob, setLoadAreasJob] = useState(true) //AREAS
    const [loadJobs, setLoadJobs] = useState(true) //JOBS

    //DATA SELECTS
    const [typeMovs, setTypeMovs] = useState([]);
    const [buildsAreas, setBuildsAreas] = useState([])
    const [jobs, setJobs] = useState([]);
    const [modal, setModal] = useState(false);

    const defaultForm = {
        idStaff: employee ? employee[0] : 0,
        idContractPractice: 0,
        numberContract: "",
        keyMove: 0,
        idArea: 0,
        idBuilding: 0,
        keyJob: 0,
        startDate: "",
        endDate: "",
        authorizationFolio: "",
        authorizationDate: "",
        approved: "1",
        evaluationDate: "",
        hours: "",
        notes: ""
    }

    useEffect(() => {
        if (employee) {
            setFieldValue("idStaff", employee[0]);    
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [employee])

    useEffect(() => {
        getMovTypes();
        getBuildsAreas();
        getJobs();
        if (data && !loadTypes && !loadAreasJob && !loadJobs) {
            getData(data[0]);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, loadTypes, loadAreasJob, loadJobs])

    useEffect(() => {
        if (data) {
            setIsLoading(loadTypes || loadAreasJob || loadJobs || loadData)
        } else {
            setIsLoading(loadTypes || loadAreasJob || loadJobs)
        }
    }, [loadTypes, loadAreasJob, loadJobs, loadData, data])

    async function getMovTypes() {
        const params = {
            action: "multiselect",
            table: 'types_move A',
            conditions: "A.enabled=1",
            rows: "A.keyMove,A.nameMove"
          };
          const res = await API.peticion(params)
        if (res.status === 200 && res.data.code === "200") {
            const data = res.data.data;
            setTypeMovs(data.filter(it => validMoves.includes(it.nameMove)))
            setLoadTypes(false)
        }else {
            toast(<Notification type={"consultar_error"} withIcon />);
        }
    }

    async function getBuildsAreas() {
        const params = {
            action: "multiselect",
            table: 'areas A inner join buildings B on A.idBuilding=B.idBuilding',
            conditions: "A.enabled=1 AND B.enabled=1",
            rows: "A.idArea,A.name,B.idBuilding,B.nameBuilding"
          };
          const res = await API.peticion(params)
        if (res.status === 200 && res.data.code === "200") {
            const data = res.data.data;
            setBuildsAreas(data);
            setLoadAreasJob(false)
        }else {
            toast(<Notification type={"consultar_error"} withIcon />);
        }
    }

    async function getJobs() {
        const params = {
            action: "multiselect",
            table: 'jobs J inner join jobtypes JT on J.keyJobType=JT.keyJobType',
            conditions: "J.enabled=1 AND J.keyJobType=5",
            rows: "J.keyJob,J.nameJob"
          };
          const res = await API.peticion(params)
        if (res.status === 200 && res.data.code === "200") {
            setJobs(res.data.data)
            setLoadJobs(false)
        }else {
            toast(<Notification type={"consultar_error"} withIcon />);
        }
    }

    async function getData(id) {
        const params = {
            action: "multiselect",
            table: 'contractPractice A',
            conditions: `A.idContractPractice=${id}`,
            rows: "*"
          };
          const res = await API.peticion(params)
        if (res.status === 200 && res.data.code === "200") {
            const data = res.data.data[0];
            setFieldValue("idStaff", data.idStaff);
            setFieldValue("idContractPractice", data.idContractPractice);
            setFieldValue("numberContract", data.numberContract);
            setFieldValue("keyMove", data.keyMove);
            setFieldValue("idArea", data.idArea);
            const build = buildsAreas.find(it => it.idArea == data.idArea);
            setFieldValue("idBuilding", build.idBuilding);
            setFieldValue("keyJob", data.keyJob);
            setFieldValue("startDate", data.startDate);
            setFieldValue("endDate", data.endDate);
            setFieldValue("authorizationFolio", data.authorizationFolio);
            setFieldValue("authorizationDate", data.authorizationDate);
            setFieldValue("approved", data.approved);
            setFieldValue("evaluationDate", data.evaluationDate);
            setFieldValue("hours", data.hours);
            setFieldValue("notes", data.notes);
            setLoadData(false)
            setIsOpen(true)
        }else {
            toast(<Notification type={"consultar_error"} withIcon />);
        }
    }

    const limpia = () => {
        resetForm();
        if (data) { //LIMPIAR RESTOS Y RESTAURAR LOADINGS
            setData(null);
            setLoadData(true)
            setIsLoading(true);
        }
        if (noEmp) { //SI NO SE SELECCIONO EL EMPLEADO Y SE EDITO DIRECTAMENTE
            setEmployee(null)
        }
        setIsOpen(false);
    }

    const onChange = (e) => {
        if(title === "Alta oficio" && !permissions.INS) return null;
        else if(title === "Editar oficio" && !permissions.UPD) return null;

        const { name, value } = e.target
        if (name === "startDate") {
            setFieldValue(["endDate"], "");
        }
        if (name === "idArea") {
            const build = buildsAreas.find(build => build.idArea === value);
            if (build) {
                setFieldValue([name], value);    
                setFieldValue("idBuilding", build.idBuilding);    
            }
        } else if (name === "approved") {
            setFieldValue(["approved"], "1");
        } else if (name === "disapproved") {
            setFieldValue(["approved"], "0");
        } else {
            setFieldValue([name], value);
        }
    }

    async function validateDate(date) {
        if (date) {
            const formattedDate = format(date, "yyyy/MM/dd")
            const params = {
                action: "multiselect",
                table: 'contractPractice CP INNER JOIN types_move TM on CP.keyMove=TM.keyMove INNER JOIN areas A on CP.idArea=A.idArea INNER JOIN jobs J on CP.keyJob=J.keyJob INNER JOIN staff SF ON CP.idStaff=SF.idStaff',
                conditions: !data ?
                    `'${formattedDate}'>=CP.startDate AND '${formattedDate}'<=CP.endDate AND CP.idStaff = ${values.idStaff}` :
                    `'${formattedDate}'>=CP.startDate AND '${formattedDate}'<=CP.endDate AND CP.idContractPractice != ${values.idContractPractice} AND CP.idStaff = ${values.idStaff}`,
                rows: "CP.idContractPractice,CP.startDate,CP.endDate"
            };
            const res = await API.peticion(params)
            if (res.status === 200 && res.data.code === "200") {
                const data = res.data.data;
                if (data.length > 0) {
                    return false;
                } else {
                    return true;
                }
            } else {
                toast(<Notification type={"consultar_error"} withIcon />);
                return true;
            }
        }
        return false;
    }

    Yup.addMethod(Yup.date, "dateRange", function (errorMessage) {
        return this.test('dateRange_valid', errorMessage, function (value) {
          const { path, createError } = this;
          return (
            // eslint-disable-next-line no-mixed-operators
            value && validateDate(value) || createError({ path, message: errorMessage })
          )
        })
    })

    const FormSchema = Yup.object().shape({
        numberContract: Yup.number(),
        authorizationFolio: Yup.number()
            .required("Ingrese un folio de pleno valido"),
        authorizationDate: Yup.date()
            .required("Ingrese una fecha de pleno valida"),
        keyMove: Yup.number()
            .required("Seleccione un movimiento valido"),
        idArea: Yup.number()
            .required("Seleccione una adscripción valido"),
        idBuilding: Yup.number()
            .required("Seleccione un edificio valido"),
        keyJob: Yup.number()
            .required("Seleccione un puesto laboral valido"),
        startDate: Yup.date()
            .required("Ingrese una fecha de inicio valida")
            .dateRange("Ingrese una fecha valida, coincide con el rango de fechas de otro registro"),
        endDate: Yup.date()
            .required("Ingrese una fecha final valida")
            .dateRange("Ingrese una fecha valida, coincide con el rango de fechas de otro registro"),
        hours: Yup.number()
            .required("Ingrese un numero de horas valido"),
        evaluationDate: Yup.date()
            .required("Ingrese una fecha valida"),
        notes: Yup.string()
    })

    const { handleSubmit, values, handleBlur, errors, touched, setFieldValue, resetForm } =
        useFormik({
            initialValues: defaultForm,
            onSubmit: () => setModal(true),
            validationSchema: FormSchema,
        });

    if (isLoading) {
        return (<Loader></Loader>)
    }
    return (
        <>
            {permissions.INS && <Row className='mt-2'>
                <Col>
                    <div className='text-center text-sm-right'>
                        <Button
                            color="add"
                            onClick={() => { setTitle("Alta oficio"); setIsOpen(!isOpen); }}
                            disabled={isOpen || !employee}
                        >
                            Agregar
                        </Button>
                    </div>
                </Col>
            </Row>}
            <Collapse isOpen={isOpen} className="mt-4 border-top">
                <form onSubmit={handleSubmit}>
                    <Row className='mt-2'>
                        <Col sm={12}>
                            <h5 className="text-center text-sm-left">{title}</h5>
                        </Col>
                    </Row>
                    {data ? (<Row>
                        <Col xs={12}>
                            <NumberInput
                                label="Folio contrato"
                                inputName="numberContract"
                                onChangeMethod={onChange}
                                onBlurMethod={handleBlur}
                                value={values.numberContract}
                                errors={errors.numberContract}
                                touched={touched.numberContract}
                                isDisabled
                                isRequired
                                disableSep
                            />
                        </Col>
                    </Row>): null}
                    <Row>
                        <Col xs={12} md={6}>
                            <NumberInput
                                label="Folio del pleno"
                                inputName="authorizationFolio"
                                onChangeMethod={onChange}
                                onBlurMethod={handleBlur}
                                value={values.authorizationFolio}
                                errors={errors.authorizationFolio}
                                touched={touched.authorizationFolio}
                                disabled
                                isRequired
                                disableSep
                            />
                        </Col>
                        <Col xs={12} md={6}>
                            <TextInput
                                label="Fecha del pleno"
                                inputName="authorizationDate"
                                inputType="date"
                                onChangeMethod={onChange}
                                onBlurMethod={handleBlur}
                                value={values.authorizationDate}
                                errors={errors.authorizationDate}
                                touched={touched.authorizationDate}
                                disabled
                                isRequired
                                disableSep
                            />
                        </Col>
                    </Row>
                    <Row className="mt-2">
                        <Col xs={12} md={6}>
                            <SelectTypeHeadSingle
                                label="Tipo de movimiento"
                                isRequired={true}
                                optionsArray={typeMovs}
                                inputName="keyMove"
                                onChangeMethod={onChange}
                                onBlurMethod={handleBlur}
                                touched={touched.keyMove}
                                errors={errors.keyMove}
                                optionValue="keyMove"
                                optionName="nameMove"
                                value={values.keyMove}
                            />
                        </Col>
                        <Col xs={12} md={6}>
                            <SelectTypeHeadSingle
                                label="Adscripción"
                                isRequired={true}
                                optionsArray={buildsAreas}
                                inputName="idArea"
                                onChangeMethod={onChange}
                                onBlurMethod={handleBlur}
                                touched={touched.idArea}
                                errors={errors.idArea}
                                optionValue="idArea"
                                optionName="name"
                                value={values.idArea}
                            />
                        </Col>
                    </Row>
                    <Row className="mt-2">
                        <Col xs={12} md={4}>
                            <SelectTypeHeadSingle
                                label="Edificio"
                                isRequired={true}
                                optionsArray={buildsAreas}
                                inputName="idBuilding"
                                onChangeMethod={onChange}
                                onBlurMethod={handleBlur}
                                touched={touched.idBuilding}
                                errors={errors.idBuilding}
                                optionValue="idBuilding"
                                optionName="nameBuilding"
                                value={values.idBuilding}
                                isDisabled
                            />
                        </Col>
                        <Col xs={12} md={8}>
                            <SelectTypeHeadSingle
                                label="Puesto laboral"
                                isRequired={true}
                                optionsArray={jobs}
                                inputName="keyJob"
                                onChangeMethod={onChange}
                                onBlurMethod={handleBlur}
                                touched={touched.keyJob}
                                errors={errors.keyJob}
                                optionValue="keyJob"
                                optionName="nameJob"
                                value={values.keyJob}
                            />
                        </Col>
                    </Row>
                    <Row className="mt-2">
                        <Col xs={12} md={6}>
                            <TextInput
                                label="Inicio nombramiento"
                                inputType="date"
                                inputName="startDate"
                                onChangeMethod={onChange}
                                onBlurMethod={handleBlur}
                                value={values.startDate}
                                errors={errors.startDate}
                                touched={touched.startDate}
                                isRequired
                            />
                        </Col>
                        <Col xs={12} md={6}>
                            <TextInput
                                label="Termino nombramiento"
                                inputType="date"
                                inputName="endDate"
                                onChangeMethod={onChange}
                                onBlurMethod={handleBlur}
                                value={values.endDate}
                                errors={errors.endDate}
                                touched={touched.endDate}
                                isMin={values.startDate}
                                isRequired
                            />
                        </Col>
                    </Row>
                    <Row className='mt-2'>
                        <Col xs={12} md={4}>
                            <NumberInput
                                label="Horas"
                                inputName="hours"
                                onChangeMethod={onChange}
                                onBlurMethod={handleBlur}
                                value={values.hours}
                                errors={errors.hours}
                                touched={touched.hours}
                                isRequired
                            />
                        </Col>
                        <Col xs={12} md={4}>
                            <TextInput
                                label="Fecha evaluación"
                                inputType="date"
                                inputName="evaluationDate"
                                onChangeMethod={onChange}
                                onBlurMethod={handleBlur}
                                value={values.evaluationDate}
                                errors={errors.evaluationDate}
                                touched={touched.evaluationDate}
                                isMin={values.endDate}
                                isRequired
                            />
                        </Col>
                        <Col xs={12} md={4}>
                            <div className='text-center'>
                                Resultado
                            </div>
                            <Row>
                                <Col>
                                    <CheckBoxInput
                                        label="Aprobado"
                                        inputName="approved"
                                        onChangeMethod={onChange}
                                        onBlurMethod={handleBlur}
                                        isRequired={false}
                                        value={values.approved === "1" ? true : false}
                                    />
                                </Col>
                                <Col>
                                    <CheckBoxInput
                                        label="Rechazado"
                                        inputName="disapproved"
                                        onChangeMethod={onChange}
                                        onBlurMethod={handleBlur}
                                        isRequired={false}
                                        value={values.approved !== "1" ? true : false}
                                    />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row className='mt-2'>
                        <Col xs={12}>
                            <TextAreaInput
                                label="Notas"
                                inputName="notes"
                                inputRows={10}
                                onChangeMethod={onChange}
                                onBlurMethod={handleBlur}
                                value={values.notes}
                                isRequired={false}
                                touched={touched.notes}
                                errors={errors.notes}
                            />
                        </Col>
                    </Row>
                    <Row className="mt-5">
                        <Col sm={6} className="order-2 order-sm-1 text-center text-sm-left mb-4">
                            <Button
                                color="danger"
                                type="reset"
                                onClick={() => limpia()}
                            >
                                Cancelar
                            </Button>
                        </Col>
                        { ((title === "Alta oficio" && permissions.INS) || (title === "Editar oficio" && permissions.UPD)) && 
                            <Col sm={6} className="order-1 order-sm-2 text-center text-sm-right mb-4">
                                <Button color="success" type="submit">
                                    Guardar
                                </Button>
                            </Col>
                        }
                    </Row>
                </form>
            </Collapse>
            {data ? (
                <ModalConfirmation
                    modalTitle={"Editar"}
                    modal={modal}
                    setModal={setModal}
                    editar={(rows) => updateOficioPrestador(rows, setParametros, employee[0], limpia, noEmp)}
                    isEdit={true}
                    values={values}
                >
                    <div className="d-flex justify-content-center">
                        <h6>¿Está seguro de editar el registro?</h6>
                    </div>
                </ModalConfirmation>
            ) : (
                <ModalConfirmation
                    modalTitle={"Agregar"}
                    modal={modal}
                    crear={(rows) => createOficioPrestador(rows, setParametros, employee[0], limpia, genReport)}
                    setModal={setModal}
                    isEdit={false}
                    values={values}
                >
                    <div className="d-flex justify-content-center">
                        <h6>¿Desea continuar con el registro?</h6>
                    </div>
                </ModalConfirmation>
            )}
        </>
    )
}
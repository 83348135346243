import * as Yup from "yup";
import { useFormik } from "formik";
import { Container, Row, Col, Button } from "reactstrap";
import { useEffect, useState } from "react";
import InputPP from "./inputs/ProyectoPresupuestal";
import { CurrencyInput, SelectTypeHeadSingle, TextInput } from "../../../components/GenericInputsFormik";
import getCapitulosOrigen from "./inputs/CapitulosOrigen";
import getPartidasOrigen from "./inputs/PartidasOrigen";
import ModalConfirmation from "../../../components/Modal/ModalConfirmation";
import { getMonthsDestination, getMonthsOrigin } from "./inputs/Months";
import { peticionesReceiver } from "../../../helpers/peticionesReceiver";
import { toast } from "react-toastify";
import Notification from "../../../components/Notification/Notification"

const Recalendarizacion = ({ annualBudget }) => {
  const [modal, setModal] = useState(false);
  const [reload, setReload] = useState(false);
  const [idStateFund, setIdStateFund] = useState(null);
  const [keyChapter, setKeyChapter] = useState(null);
  const [idExpenseObject, setIdExpenseObject] = useState(null);
  const [idMonthOr, setIdMonthOr] = useState(null);

  const [chapters, setChapters] = useState([]);
  const [expenseObject, setExpenseObject] = useState([]);
  const [monthsOr, setMonthsOr] = useState([]);
  const [monthsDTO, setMonthsDTO] = useState([]);
  const [monthsDTOFin, setMonthsDTOFin] = useState([]);

  const defaultForm = {
    idStateFund: "",
    keyChapter: "",
    idExpenseObject: "",
    monthOr: "",
    idBudgetMonth: "", 
    keyMonth: "",
    amount: "",
    description: ""
  }

  const onChange = (e) => {
    const { name, value } = e.target
    if (name === "idStateFund") {
      setIdStateFund(value);
    } else if (name === "keyChapter") {
      setKeyChapter(value)
    } else if (name === "idExpenseObject") {
      setIdExpenseObject(value);
    } else if (name === "monthOr") {
      const month = monthsOr.find(month => month.keyMonth == value);
      setFieldValue(["idBudgetMonth"], month.idBudgetMonth);
      setFieldValue(["keyMonth"], "");
      setIdMonthOr(value);
    }
    setFieldValue([name], value);
  }

  Yup.addMethod(Yup.string, "currencyMax", function (errorMessage) {
    return this.test('currency_valid', errorMessage, function (value) {
      const { path, createError } = this;
      return (
        value && getMax() >= Number(value) || createError({ path, message: errorMessage })
      )
    })
  })

  useEffect(() => {
    if (idStateFund || reload) {
      getCapitulosOrigen(annualBudget, idStateFund, setChapters);
      if (keyChapter || reload) {
        getPartidasOrigen(annualBudget, idStateFund, keyChapter, setExpenseObject);
      } else {
        setFieldValue("idExpenseObject", "");
        setFieldValue("monthOr", "");
        setFieldValue("keyMonth", "");
      }
      if (idExpenseObject || reload) {
        getMonthsOrigin(idStateFund, annualBudget, idExpenseObject, setMonthsOr);
        getMonthsDestination(idStateFund, annualBudget, idExpenseObject, setMonthsDTO);
      } else {
        setFieldValue("monthOr", "");
        setFieldValue("keyMonth", "");
      }
    } else {
      setFieldValue("idExpenseObject", "");
      setFieldValue("monthOr", "");
      setFieldValue("keyMonth", "");
    }
  }, [idStateFund, keyChapter, idExpenseObject, annualBudget, reload]);

  useEffect(() => {
    if (monthsDTO && monthsDTO.length > 0) {
      const actualMonth = Number(idMonthOr)
      const months = monthsDTO.filter(month => Number(month.keyMonth) > actualMonth)
      setMonthsDTOFin(months);  
    }
    
  }, [idMonthOr, monthsDTO])

  function getMaxAmount(value) {
    const amount = value.substring(value.indexOf("-") + 2).replace(/,/g, "").replace("$","");
    return Number(amount);
  }

  function getMax() {
    const month = monthsOr.find(month => month.keyMonth == idMonthOr);
    if (month) {
      return getMaxAmount(month.avaible);
    }
    return 0;
  }

  const FormSchema = Yup.object().shape({
    idStateFund: Yup.number().required("Seleccionar un fondo de estado"),
    keyChapter: Yup.number().required("Seleccionar un capitulo valido"),
    idExpenseObject: Yup.number().required("Seleccionar una partida de orgen valida"),
    monthOr: Yup.number().required("Seleccionar un mes de origen valido"),
    keyMonth: Yup.number().required("Seleccionar un mes de origen valido"),
    amount: Yup.string().required("Se requiere de la cantidad a transferir")
    .currencyMax("El monto ingresado supera al monto original"),
    description: Yup.string().required("Se requiere anexar el motivo de recalendarización")
  })

  const { handleSubmit, values, handleBlur, errors, touched, setFieldValue, resetForm } =
    useFormik({
      initialValues: defaultForm,
      onSubmit: () => setModal(true),
      validationSchema: FormSchema,
  });
  
  const editar = async (leaveU) => { 
    setReload(false);
    const API = peticionesReceiver();
    const params = { action: "saveReschedule" }
    params.description = leaveU.description
    params.idAnnualBudget = annualBudget.id
    const rows = [{
      idBudgetMonth: leaveU.idBudgetMonth,
      keyMonth: leaveU.keyMonth,
      amount: leaveU.amount
    }]
    params.rows = rows;
    await API.peticionEndPoint(params, "app/facades/budgets/budgetsF.php")
      .then((res) => {
        if (res.status === 200 && res.data.code === "200") {
          resetForm();
          setReload(true);
          toast(<Notification type={"modifica_exito"} backMessage={res.data.message} withIcon />);
        } else {
          toast(
            <Notification
              type={"consultar_error"}
              backMessage={res.data.message}
              withIcon
            />
          );
        }
      })
      .catch((err) => {
        toast(<Notification type={"consultar_servidor_error"} withIcon />);
      });
  }

  return (
    <div className="mt-2">
      <h4>Recalendarización</h4>
      <form onSubmit={handleSubmit}>
        <Container>
          <Row>
            <Col xs={12} md={6}>
              <InputPP
                errors={errors}
                touched={touched}
                annualBudget={annualBudget}
                onBlurMethod={handleBlur}
                onChangeMethod={onChange}
                values={values}
              />
              <SelectTypeHeadSingle
                label="Capitulo origen"
                inputName="keyChapter"
                onChangeMethod={onChange}
                onBlurMethod={handleBlur}
                value={values.keyChapter}
                isRequired
                isDisabled={values.idStateFund === ""}
                touched={touched.keyChapter}
                errors={errors.keyChapter}
                optionsArray={chapters}
                optionValue={"keyChapter"}
                optionName={"nameChapter"}
              />
              <SelectTypeHeadSingle
                label="Partidas origen"
                inputName="idExpenseObject"
                onChangeMethod={onChange}
                onBlurMethod={handleBlur}
                value={values.idExpenseObject}
                isRequired
                isDisabled={values.idStateFund === "" || values.keyChapter === ""}
                touched={touched.idExpenseObject}
                errors={errors.idExpenseObject}
                optionsArray={expenseObject}
                optionValue={"idExpenseObject"}
                optionName={"nameObject"}
              />
              <SelectTypeHeadSingle
                label="Mes origen"
                inputName="monthOr"
                onChangeMethod={onChange}
                onBlurMethod={handleBlur}
                value={values.monthOr}
                isRequired
                isDisabled={values.idStateFund === "" || values.keyChapter === "" || values.idExpenseObject === ""}
                touched={touched.monthOr}
                errors={errors.monthOr}
                optionsArray={monthsOr}
                optionValue={"keyMonth"}
                optionName={"avaible"}
              />
            </Col>
            <Col xs={12} md={6}>
              <SelectTypeHeadSingle
                label="Mes destino"
                inputName="keyMonth"
                onChangeMethod={onChange}
                onBlurMethod={handleBlur}
                value={values.keyMonth}
                isRequired
                isDisabled={values.idStateFund === "" || values.keyChapter === "" || values.idExpenseObject === "" || values.monthOr === ""}
                touched={touched.keyMonth}
                errors={errors.keyMonth}
                optionsArray={monthsDTOFin}
                optionValue={"keyMonth"}
                optionName={"avaible"}
              />
              <CurrencyInput
                label="Cantidad"
                inputName="amount"
                onChangeMethod={(value, name) => onChange({target:{name: name, value: value}})}
                onBlurMethod={handleBlur}
                value={values.amount}
                isRequired
                isPlaceholder={""}
                isDisabled={values.idStateFund === "" || values.keyChapter === "" || values.idExpenseObject === "" || values.monthOr === "" || values.keyMonth === ""}
                touched={touched.amount}
                errors={errors.amount}
                tooltip = {true}
                textTooltip = "Monto a transferir"
                isMax={getMax()}
              />
              <TextInput
                label="Motivo de recalendarización"
                inputName="description"
                inputType="text"
                onChangeMethod={onChange}
                onBlurMethod={handleBlur}
                value={values.description}
                isRequired
                isDisabled={values.idStateFund === "" || values.keyChapter === "" || values.idExpenseObject === "" || values.monthOr === "" || values.keyMonth === ""}
                touched={touched.description}
                errors={errors.description}
              />
            </Col>
          </Row>
        </Container>
        <div className="text-center">
          <Button color="add" type="submit">Solicitar traspaso</Button>
        </div>
      </form>
      <ModalConfirmation
        modalTitle="Reprogramar"
        modal={modal}
        setModal={setModal}
        editar={(rows) => editar(rows)}
        isEdit={true}
        values={values}
      >
        <div className="d-flex justify-content-center">
          <h6>¿Está seguro de reprogramar el registro?</h6>
        </div>
      </ModalConfirmation>
    </div>
  );
};
export default Recalendarizacion;

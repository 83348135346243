import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { SelectTypeHeadSingle } from "../../../../../components/GenericInputsFormik";
import { peticionesReceiver } from "../../../../../helpers/peticionesReceiver";
import Notification from "../../../../../components/Notification/Notification";
import { element } from "prop-types";


export default function SelectStateFunds({ handleBlur, touched, errors, values, setFieldValue }) {
    const [funds, setFunds] = useState([]);
    const API = peticionesReceiver();
    
    const data = {
        action: "multiselect",
        table:"statefunds as sf",
        rows: "sf.idStateFund,sf.stateFundKey,sf.nameStateFund",
        conditions:`sf.enabled = 1`,
    };    
    useEffect(() => {
        API.peticion(data).then((res) => {
            if (res.status === 200 && res.data.code === "200") {
                const datos = res.data.data.map(elmt => ({ label: `${elmt.stateFundKey} - ${elmt.nameStateFund}`, value: elmt.idStateFund }));
                setFunds(datos);
            } else {
                setFunds([])
                toast(<Notification type={"consultar_error"} withIcon />);
            }
        }).catch((err) => {
            toast(<Notification type={"consultar_servidor_error"} withIcon />);
        });
    }, [])

    const OnChangeSelect = event => {
        setFieldValue(event.target.name, event.target.value);
      };
    

    return (
        <SelectTypeHeadSingle
            label="Seleccione un fondo estatal"
            optionsArray={funds}
            inputName="idStateFund"
            onChangeMethod={OnChangeSelect}
            onBlurMethod={handleBlur}
            touched={touched.idStateFund}
            errors={errors.idStateFund}
            optionValue="value"
            optionName="label"
            value={values.idStateFund}
            isRequired
            defaultOption="Seleccione un fondo estatal"

        />
    )
}
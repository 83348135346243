import { Card, CardBody, CardTitle, Col, Row, Spinner } from "reactstrap";
import Widget from "../../../components/Widget/Widget";
import { useEffect, useState } from "react";
import LecturaQr from "./lectura";
import ReconocimientoFacial from "./facial";
import { peticionDecrypt } from "../../../helpers/peticionesEncripted";
import { peticionesReceiver } from "../../../helpers/peticionesReceiver";
import { toast } from "react-toastify";
import Notification from "../../../components/Notification/Notification";
import DetectarReconocimientoFacial from "./faceRecognition";
import InfoAsistencia from "./getLocation";
import Datatable from "../../../components/Datatable/Datatable"
import { GetPermissions } from "../../../libs/permissions/getPermissions";
import GetUserByList from "./getUser";
import { format } from "date-fns"
import { es } from "date-fns/locale"

export default function TomaAsistencia() {
    const origin = window.location.origin
    const API = peticionesReceiver()
    const [step, setStep] = useState(0);
    const [process, setProcess] = useState(0);
    const [qr, setQR] = useState("");
    const [user, setUser] = useState(null);
    const [src, setSRC] = useState("");
    const [permisos, setPermisos] = useState("")
    const [loading, setLoading] = useState("")
    GetPermissions(setPermisos, setLoading);

    const next = () => setStep(step + 1);

    const before = () => setStep(step - 1);

    const zero = () => setStep(0);

    function setProcessStep(value) {
        setProcess(value);
        next()
    }

    async function getStaff(idTag) {
        try {
            const res = await API.peticion({
                action: "multiselect",
                table: "nameTags nt inner join staff st on (nt.idStaff = st.idStaff) join jobstaff js on (js.idStaff=st.idStaff) and js.valid = 1",
                rows: "st.idStaff,nt.validDate,st.name,st.pName,st.mName,js.idJobStaff",
                conditions: `nt.idNameTags=${idTag} and nt.enabled=1`
            })
            if (res.status === 200 && res.data.code === "200") {
                const empleado = res.data.data[0];
                if (empleado.validDate) {
                    const [year, month, day] = empleado.validDate.split('-');
                    const transformedDate = new Date(year, month - 1, day);
                    const currentDate = new Date();
                    if (currentDate <= transformedDate) {
                        setUser(empleado)
                        getSRCImage(empleado.idStaff)
                    } else {
                        toast(<Notification type={"consultar_error"} backMessage={"La credencial no se encuentra vigente"} withIcon />);
                    }
                } else {
                    toast(<Notification type={"consultar_error"} backMessage={"No se tiene una fecha de vigencia"} withIcon />);
                }
            } else {
                toast(<Notification type={"consultar_error"} backMessage={"Sin registros"} withIcon />);
            }
        } catch (err) {
            toast(<Notification type={"consultar_servidor_error"} withIcon />);
        }
    }


    async function getSRCImage(idStaff) {
        try {
            const res = await API.peticion({
                action: "multiselect",
                table: "staff A LEFT JOIN bookcase BC ON (BC.enabled = 1 AND BC.keyFileType = 1 and BC.reference = A.idStaff) LEFT JOIN files FILES ON (FILES.enabled = 1 AND FILES.idBook = BC.idBook)",
                rows: "FILES.src",
                conditions: `A.enabled = 1 AND A.idStaff = ${idStaff}`
            })
            if (res.status === 200 && res.data.code === "200") {
                if (res.data.data[0].src && res.data.data[0].src !== "") {
                    setSRC(res.data.data[0].src)
                    next()
                } else {
                    toast(<Notification type={"consultar_error"} backMessage={"Sin foto para reconocimiento facial"} withIcon />);
                }
            } else {
                toast(<Notification type={"consultar_error"} backMessage={"Sin registros"} withIcon />);
            }
        } catch (err) {
            toast(<Notification type={"consultar_servidor_error"} withIcon />);
        }
    }

    useEffect(() => {
        if (qr && qr !== "") {
            try {
                const query = qr.substring(qr.indexOf("?token=") + 7);
                const decrypted = peticionDecrypt(decodeURIComponent(query))
                const idTag = decrypted.idNameTags;
                getStaff(idTag)
            } catch (err) {
                toast(<Notification type={"consultar_error"} backMessage={"No se pudo leer el codigo QR"} withIcon />);
            }
        }
    }, [qr])

    const headers = [
        "Dia",
        "Hora",
        "Nombre completo"
    ]

    const columns = [
        {
            data: "assistDate",
            render: function (row) {
                return (format(new Date(row), "P", {locale: es}))
            },
        },
        {
            data: "assistDate",
            render: function (row) {
                return (format(new Date(row), "HH:mm", {locale: es}))
            },
        },
        {
            data: null,
            render: function (row) {
                return `${row.name} ${row.pName} ${row.mName}`
            },
            orderValue: "CONCAT(s.name,' ',s.pName,' ',s.mName)"
        }
    ]

    const getParams = (order, records, page, search) => {
        const params = {
            action: "datatable",
            table: "assists a join staff s on (a.idStaff = s.idStaff)",
            rows: "a.assistDate,s.name,s.pName,s.mName",
            conditions: `a.enabled = 1`,
            order,
            records: records.toString(),
            page: (page * records).toString(),
            search
        }
        return params
    }

    async function getData(order, records, page, search) {
        try {
            const res = await API.peticion(getParams(order, records, page, search));
            if (res.status === 200 && res.data.code === "200") {
                return res.data;
            } else {
                toast(<Notification type={"consultar_error"} backMessage={res.data.message} />);
                return null;
            }
        } catch (err) {
            toast(<Notification type={"consultar_servidor_error"} withIcon />);
            return null
        }
    }

    if (loading) {
        return (
            <div className="d-flex flex-column align-items-center justify-content-center" style={{ minHeight: "300px" }}>
                <Spinner animation="grow" variant="primary" />
            </div>
        )
    }
    return (
        <>
            <Widget className="widget-p-md">
                <h2 className="text-secondary">Toma de asistencia</h2>
                {step === 0 ? (
                    <>
                        <p style={{ fontSize: "smaller" }}>El presente modulo tiene como intención la toma de asistencia del personal de la organización</p>
                        <p
                            className="font-weight-bold text-center mt-3"
                            style={{ fontSize: "small" }}>
                            Nota: Seleccione el proceso de toma de asistencia y presione el boton continuar,
                            <br />
                            las siguientes ventanas le ayudaran en el proceso de toma de asistencia
                        </p>
                        {permisos.INS && (
                            <Row className="mt-3">
                                <Col sm={12} md={6} onClick={() => setProcessStep(0)}>
                                    <Card className="shadow cursor-pointer">
                                        <CardTitle className="text-center font-weight-bold mt-3" tag={"h3"}>Asistencia por credencial</CardTitle>
                                        <CardBody className="text-center">
                                            <img src={`${origin}/images/QRScan.gif`} alt="qrscan" style={{ width: "30%" }} />
                                            <p className="font-weight-bold">Nota: Para la toma de asistencia debera portar en todo momento su credencial</p>
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col sm={12} md={6} onClick={() => setProcessStep(1)}>
                                    <Card className="shadow cursor-pointer">
                                        <CardTitle className="text-center font-weight-bold mt-3" tag={"h3"}>Asistencia por reconocimiento facial</CardTitle>
                                        <CardBody className="text-center">
                                            <img src={`${origin}/images/FaceRecognition.gif`} alt="qrscan" style={{ width: "30%" }} />
                                            <p className="font-weight-bold">Nota: En caso de no contar con su credencial y sus datos estan actualizados, seleccione esta opción</p>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        )}
                        <h4 className="text-primary mt-5">Registro de asistencias</h4>
                        {permisos.CON ? (
                            <Datatable
                                className="p-0 my-3"
                                columns={columns}
                                headers={headers}
                                control="back"
                                petition={getData}
                                order={{col:0, opt: "desc"}}
                            />
                        ) : (
                            <div className="text-center p-4">
                                No tienes permitido ver los registros de esta seccion
                            </div>
                        )}

                    </>
                ) : process === 0 ? (
                    <>
                        {step === 1 ? (
                            <LecturaQr
                                setData={setQR}
                                before={before}
                            />
                        ) : step === 2 ? (
                            <ReconocimientoFacial
                                user={user}
                                src={src}
                                before={before}
                                next={next}
                            />
                        ) : (
                            <InfoAsistencia
                                before={zero}
                                user={user}
                            />
                        )}
                    </>
                ) : (
                    <>
                        {step === 1 ? (
                            <GetUserByList
                                before={before}
                                next={next}
                                setUser={setUser}
                            />
                        ) : step === 2 ? (
                            <DetectarReconocimientoFacial
                                before={before}
                                user={user}
                                next={next}
                            />
                        ) : (
                            <InfoAsistencia
                                before={zero}
                                user={user}
                            />)}
                    </>
                )}
            </Widget >
        </>
    )
}
import React from "react";
import { Row } from 'reactstrap';

export const RadioInput = (props) => {
  const {
    label,
    inputName,
    onChangeMethod,
    onBlurMethod,
    value,
    isRequired,
    touched,
    errors,
    optionsArray,
    optionValue,
    optionName,
    isDisabled,
    isCenter
  } = props;

  return (
    <>
      <div className={isCenter ? "form-group text-center": "form-group"}>
        {label && <label htmlFor={inputName}>
          {label} {isRequired && <span className="text-danger">*</span>}
        </label>}
        <div className={"d-flex flex-row " + (isCenter && "justify-content-center")}>
        {optionsArray.length > 0 &&
          optionsArray.map((e, id) => {
            return (
              <div style={{ margin: '5px'}} key={id} >
                <input
                  type="radio"
                  id={inputName}
                  name={inputName}
                  value={e[optionValue]}
                  onChange={onChangeMethod}
                  onBlur={onBlurMethod}
                  checked={value === e[optionValue]}
                  disabled={isDisabled === true ? true : false}
                />
                <label className="ml-1">{e[optionName]}</label>
              </div>
            );
          })}
          </div>
        {isRequired && touched && errors && (
          <span style={{ color: "red" }}>{errors}</span>
        )}
      </div>
    </>
  );
};

import { toast } from "react-toastify";
import Datatable from "../../../../components/Datatable/Datatable";
import Notification from "../../../../components/Notification/Notification";

import { peticionesReceiver } from "../../../../helpers/peticionesReceiver";
import { Button, Spinner } from "reactstrap";
import { useState } from "react";
import RegistroProveedoresEstudio from "./RegistroProveedores";
import ModalComponent from "../../../../components/Modal/ModalDelete";
import ArchivoCotizacion from "./ArchivoCotizacion";
import { GetPermissions } from "../../../../libs/permissions/getPermissions";

export default function RegistroCotizaciones({ idCot }) {
    const API = peticionesReceiver()

    const [add, setAdd] = useState(false);
    const [refresh, setRefresh] = useState(false)
    const [permisos, setPermisos] = useState("")
    const [loading, setLoading] = useState("")
    GetPermissions(setPermisos, setLoading);

    const [idProviderQuote, setIdProviderQuote] = useState("");

    function editarCotizacion(row) {
        setIdProviderQuote(row)
        setAdd(true)
    }

    const columns = [
        { data: "provider" },
        {
            data: null,
            render: function (row) {
                return (
                    <div className="d-flex flex-wrap">
                        {permisos.UPD && (
                            <>
                                <Button style={{ backgroundColor: "green" }} size="sm" className="d-flex align-items-center text-nowrap py-1 px-2 mb-2 mr-3" onClick={() => editarCotizacion(row.idProviderQuote)}>
                                    <i className="fa fa-pencil mr-2" />Editar
                                </Button>
                                <ArchivoCotizacion idProviderQuote={row.idProviderQuote} />
                            </>
                        )}
                        {permisos.DEL && (
                            <ModalComponent
                                modalTitle="Eliminar"
                                id={row}
                                table={["providerquotes"]}
                                nameColumn={["quotes"]}
                                elimina={() => OnDelete(row)}
                                button={true}
                                specialIco={true}
                                fill={false}
                            >
                                <h6>
                                    ¿Seguro de eliminar el registro?
                                </h6>
                                No podrá recuperar la información después de ser eliminada
                            </ModalComponent>
                        )}
                    </div>
                )
            }
        }
    ]

    async function OnDelete(row) {
        const API = peticionesReceiver();
        const params = {
            action: "deleteProviderQuote",
            idQuote: row.idQuote,
            idProvider: row.idProvider
        };
        try {
            const res = await API.peticionEndPoint(params, "app/facades/reports/reportsF.php")
            if (res.status === 200 && res.data.code === "200") {
                toast(<Notification type={"elimina_exito"} backMessage={res.data.message} withIcon />);
                setRefresh(true)
            } else {
                toast(<Notification type={"elimina_error"} backMessage={res.data.message} withIcon />);
            }
        } catch (err) {
            toast(<Notification type={"elimina_error"} withIcon />);
        }
    }

    const headers = ["Proveedor", "Acciones"]

    const getParamsTableElmt = (order, records, page, search) => {
        const params = {
            action: "datatable",
            table: "quotesdetail qt left join providerquotes pq on (qt.idDetail = pq.idDetail) inner join providers pr on (pq.idProvider=pr.idProvider)",
            rows: "qt.idQuote,pr.idProvider,CONCAT(pr.itin,' - ',pr.socialName) as provider,MIN(pq.idProviderQuote) as idProviderQuote",
            conditions: `qt.idQuote=${idCot} AND pr.idProvider in (select DISTINCT(pq.idProvider) from quotesdetail qt left join providerquotes pq on (qt.idDetail = pq.idDetail) where qt.idQuote=${idCot} AND pq.enabled=1)`,
            group: "pr.itin, pr.socialName",
            order,
            records: records.toString(),
            page: (page * records).toString(),
            search
        }
        return params
    }

    async function getDataTableElmts(order, records, page, search) {
        try {
            const res = await API.peticion(getParamsTableElmt(order, records, page, search));
            if (res.status === 200 && res.data.code === "200") {
                return res.data;
            } else {
                toast(<Notification type={"consultar_error"} backMessage={res.data.message} />);
                return null;
            }
        } catch (err) {
            toast(<Notification type={"consultar_servidor_error"} withIcon />);
            return null
        }
    }

    if (loading) {
        <div className="d-flex align-items-center justify-content-center" style={{ height: "200px" }}>
            <Spinner
                color="primary"
                style={{
                    height: '3rem',
                    width: '3rem'
                }}
                type="grow"
            >
                Loading...
            </Spinner>
        </div>
    }
    if (permisos.CON) {
        return (
            <>
                {!add ? (
                    <>
                        {idCot !== "" && (
                            <>
                                <Button className="mx-3 mb-3" onClick={() => setAdd(true)}>Agregar cotizacion</Button>
                                <Datatable
                                    headers={headers}
                                    columns={columns}
                                    control="back"
                                    petition={getDataTableElmts}
                                    stateRefresh={[refresh, setRefresh]}
                                />
                            </>
                        )}
                    </>
                ) : (
                    <RegistroProveedoresEstudio
                        idCot={idCot}
                        idProviderQuote={idProviderQuote}
                        setIdProviderQuote={setIdProviderQuote}
                        setReturn={setAdd}
                    />
                )}
                {add && (
                    <div className="text-center mb-3">
                        <Button color="danger" onClick={() => setAdd(false)}>Regresar</Button>
                    </div>
                )}
            </>
        )
    } else {
        return (
            <>
                <div className="text-center p-4">
                    No tienes permitido ver los registros de esta seccion
                </div>
            </>
        )
    }
}
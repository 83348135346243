import * as Yup from "yup";
import { useFormik } from "formik";
import { Button, Col, Collapse, Row } from "reactstrap";
import React, { useState, useEffect } from "react";
import Widget from "../../../components/Widget/Widget";
import s from "../reportes/filtros/InputClear.module.scss";
import DatePicker from "react-datepicker";
import ModalTableVer from "../../../components/Modal/ModalTableVer";
import { CancelOutlined } from "@material-ui/icons";
import FormEditarPago from "./FormEditarPago";
import TableComponentOnePage from "../../../components/TableComponent/TableComponentOnePage";
import Notification from "../../../components/Notification/Notification";
import { toast } from "react-toastify";
import axios from "axios";
import { peticionEncript } from "../../../helpers/peticionesEncripted";
import { GetPermissions } from "../../../libs/permissions/getPermissions";
import { PERIOD } from "../../../helpers/regex";
import { SelectTypeHeadSingle } from "../../../components/GenericInputsFormik/index";

/* MODAL TABLA */
const filtroModalTabla = [true, true, true, true, true];
const cabecerasModalTabla = [
  "#",
  "Núm. Empleado",
  "Nombre",
  "Unidad de trabajo",
  "Puesto",
  "Seleccionar",
];
const paramsModalTabla = {
  action: "datatable",
  table:
    "staff STF INNER JOIN employtypes AS EMT ON STF.keyEmployType = EMT.keyEmployType INNER JOIN jobstaff AS JBS ON JBS.idStaff = STF.idStaff INNER JOIN areas AS AR ON AR.idArea = JBS.idArea INNER JOIN jobtypes AS JBT ON JBT.keyJobType = STF.keyEmployType",
  rows: "STF.idStaff,STF.staffNumber,CONCAT(STF.name,' ',STF.pName,' ',STF.mName) as fullName,AR.name,JBT.nameJobType",
  conditions:
    "STF.enabled = 1 AND JBS.valid = 1 AND STF.keyStat = 1 AND JBS.enabled = 1",
  page: 0,
  records: 5,
  search: "",
  order: "",
};
/* TABLA */
const filtroTP = [true, true, true, true, true];
const cabecerasTP = [ "Id", "Concepto RH", "Tipo Concepto RH", "Importe", "Estatus", "Editar", "Eliminar" ];
const parametrosTP = {
  action: "datatable",
  table:
    "payslips A INNER JOIN unders B ON A.idUnder = B.idUnder INNER JOIN type_unders C ON B.KeyTypeUnder = C.keyTypeUnder INNER JOIN status D ON A.keyStat = D.keyStat",
  rows: "A.idPayslip, concat(B.under,' - ',B.nameUnder) as underName, C.nameTypeUnder, A.amount, D.nameStat, A.idUnder, A.period, A.year, A.keyStat",
  conditions: `A.enabled = 1`,
  page: 0,
  records: 5,
  search: "",
  order: "",
};

/* CONSULTA BACK */
async function getBackInfo(params) {
  let info = [];
  const dataEncrypted = peticionEncript(params);
  await axios
    .post(process.env.REACT_APP_API + "receiver/receiver.php", dataEncrypted)
    .then((res) => {
      if (res.status === 200 && res.data.status === "success") {
        info = res.data.data;
      } else {
        toast(
          <Notification
            type={"consultar_error"}
            backMessage={res.data.message}
          />,
          { closeButton: false }
        );
      }
    })
    .catch((err) => {
      toast(<Notification type={"consultar_servidor_error"} withIcon />);
    });
  return info;
}

const getUnders = async (setUnders) => {
  const params = {
    action: "multiselect",
    table: "unders",
    rows: "idUnder,concat(under,' - ',nameUnder) as name",
    conditions: "enabled = 1",
    order: "cast(under as float) asc",
  };
  let data = await getBackInfo(params);
  setUnders(data);
};

const getStatus = async (setStatus) => {
  const params = {
    action: "select",
    table: "status",
    order: "nameStat ASC",
    condition: { keyTypeStat: 12 },
  };
  let data = await getBackInfo(params);
  setStatus(data);
};

const defaultForm = {
  idStaff: "",
  nameStaff: "",
  period: "",
  year: new Date(),
};

const FormSchema = Yup.object().shape({
  nameStaff: Yup.string().required("Seleccione un empleado"),
  period: Yup.number()
    .required("Ingrese un periodo")
    .min(1, "Periodo invalido")
    .max(24, "Periodo debe ser menor a 25"),
  year: Yup.string().required("Seleccione un año"),
});

const date = new Date().getFullYear();

export const EditarPago = () => {
  const [permissions, setPermissions] = useState({});
  GetPermissions(setPermissions);

  // const savedPermissions = localStorage.getItem("permissions");
  // const permissions = JSON.parse(savedPermissions);

  const [modalTabla, setModalTabla] = useState(false);
  const [collapseTable, setcollapseTable] = useState(false);
  const [showTable, setShowTable] = useState(false);
  const [collapseForm, setcollapseForm] = useState(false);
  const [showForm, setshowForm] = useState(false);
  const [titleForm, setTitleForm] = useState("");
  const [paramsTabla, setParamsTabla] = useState("");
  const [dataForEdit, setDataForEdit] = useState(null);
  const [unders, setUnders] = useState([]);
  const [status, setStatus] = useState([]);

  const {
    handleSubmit,
    values,
    handleBlur,
    errors,
    touched,
    setFieldValue,
  } = useFormik({
    initialValues: defaultForm,
    onSubmit: (values) => {
      showPays();
    },
    validationSchema: FormSchema,
    enableReinitialize: true,
  });

  useEffect(() => {
    getUnders(setUnders);
    getStatus(setStatus);
  }, []);

  const onChange = (e) => {
    setFieldValue(e.target.name, e.target.value);
    if (collapseTable) {
      setcollapseTable(false);
      setcollapseForm(false);
    }
  };

  /* Limpiar el empleado seleccionado */
  function clearStaff() {
    setFieldValue("idStaff", "");
    setFieldValue("nameStaff", "");
    if (collapseTable) {
      setcollapseTable(false);
      setcollapseForm(false);
    }
  }

  /* Funcion que se envia al modal al seleccionar un empleado */
  const selectEmpleado = (el) => {
    setFieldValue("idStaff", el[0]);
    setFieldValue("nameStaff", `${el[1]} - ${el[2]}`);
    setModalTabla(false);
  };

  /* Muestra tabla con pagos */
  const showPays = () => {
    setParamsTabla({
      ...parametrosTP,
      conditions: `A.enabled = 1 AND A.idStaff=${values.idStaff} AND A.period=${
        values.period
      } AND A.year=${new Date(values.year).getFullYear()}`,
    });
    setShowTable(true);
    setcollapseTable(true);
  };

  /* Muestra el formulario para agregar un pago*/
  const openForm = () => {
    setDataForEdit(null);
    setTitleForm("Agregar pago");
    setcollapseForm(true);
    setshowForm(true);
  };

  /* Muestra el formulario para editar un pago*/
  const getForEdit = (el) => {
    if (!showForm) {
      setTitleForm("Modificar pago");
      setDataForEdit({
        idPayslip: el[0],
        idStaff: el[9],
        idUnder: el[5],
        period: el[6],
        year: new Date().setFullYear(el[7]),
        amount: el[3],
        keyStat: el[8],
      });
      setcollapseForm(true);
      setshowForm(true);
    } else {
      toast(
        <Notification
          type="warning"
          backMessage="Guarde sus cambios antes de continuar"
        />,
        { closeButton: false }
      );
    }
  };

  return (
    <Widget className="widget-p-md">
      <div className="text-center text-md-left mb-3">
        <h3 className="text-secondary">Editar pagos</h3>
      </div>

      <form onSubmit={handleSubmit}>
        <Row>
          <Col xs={12}>
            <div className="form-group position-relative">
              <label htmlFor="nameStaff">
                Empleado <span className="text-danger">*</span>
              </label>
              <div className="input-group">
                <input
                  name="nameStaff"
                  onChange={onChange}
                  onBlur={handleBlur}
                  className={
                    "form-control" +
                    (errors.nameStaff && touched.nameStaff ? " is-invalid" : "")
                  }
                  value={values.nameStaff}
                  readOnly
                  onClick={() => setModalTabla(true)}
                />
                <div className="input-group-append">
                  <button
                    className="btn btn-secondary"
                    style={{ zIndex: "auto" }}
                    type="button"
                    onClick={() => setModalTabla(true)}
                  >
                    <i className="fa fa-search" />
                  </button>
                </div>
              </div>
              {values.nameStaff !== "" && (
                <CancelOutlined className={s.btnClear} onClick={clearStaff} />
              )}
              {touched.nameStaff && errors.nameStaff && (
                <span style={{ color: "red" }}>{errors.nameStaff}</span>
              )}
            </div>
          </Col>
          <Col xs={12} md={12} lg={6}>
            <SelectTypeHeadSingle
              label="Periodo"
              isRequired={true}
              optionsArray={PERIOD}
              inputName="period"
              onChangeMethod={onChange}
              onBlurMethod={handleBlur}
              touched={touched.period}
              errors={errors.period}
              optionValue="value"
              optionName="label"
              value={values.period}
              defaultOption="Seleccione un periodo"
            />
            {/* <div className="form-group">
              <label htmlFor="period">
                Periodo <span className="text-danger">*</span>
              </label>
              <CurrencyInput
                id="period"
                name="period"
                className={
                  "form-control" +
                  (errors.period && touched.period ? " is-invalid" : "")
                }
                allowDecimals={false}
                allowNegativeValue={false}
                onValueChange={(v, n) =>
                  onChange({ target: { name: n, value: v } })
                }
                onBlur={handleBlur}
                maxLength={2}
                value={values.period}
                min={1}
                max={24}
              />
              {touched.period && errors.period && (
                <span className="text-danger">{errors.period}</span>
              )}
            </div> */}
          </Col>
          <Col xs={12} md={12} lg={6}>
            <div className="form-group">
              <label htmlFor={values.year}>
                {" "}
                Año <span className="text-danger"> * </span>{" "}
              </label>
              <DatePicker
                minDate={new Date(`${date - 1}`)}
                selected={values.year}
                onChange={(date) =>
                  onChange({ target: { name: "year", value: date } })
                }
                showYearPicker
                dateFormat="yyyy"
                yearItemNumber={6}
                className={
                  "form-control" +
                  (errors.year && touched.year ? " is-invalid" : "")
                }
              />
              {touched.year && errors.year && (
                <span style={{ color: "red" }}>{errors.year}</span>
              )}
            </div>
          </Col>
        </Row>
        <div className="text-center my-4">
          <Button color="success" type="submit">
            Consultar pagos
          </Button>
        </div>
      </form>

      <Collapse
        isOpen={collapseTable}
        className="mt-4 pt-3"
        onExited={() => {
          setShowTable(false);
        }}
      >
        {showTable && (
          <>
            <TableComponentOnePage
              cabecerasTabla={cabecerasTP}
              filtro={filtroTP}
              editar={getForEdit}
              parametros={paramsTabla}
              limpia={() => {
                setcollapseForm(false);
              }}
              permissions={permissions}
            />
            <Row>
              <Col xs={12} sm={6}>
                <h5 className="text-center text-sm-left">{titleForm}</h5>
              </Col>
              {permissions.INS && (
                <Col xs={12} sm={6}>
                  <div className="text-center text-sm-right">
                    <Button
                      color="add"
                      onClick={() => openForm()}
                      disabled={showForm}
                    >
                      Agregar
                    </Button>
                  </div>
                </Col>
              )}
            </Row>
          </>
        )}
      </Collapse>

      {/* {showForm && <FormEditarPago idStaff={values.idStaff} setshowForm={setshowForm} />} */}
      <Collapse
        isOpen={collapseForm}
        onExited={() => {
          setshowForm(false);
          setTitleForm("");
        }}
      >
        {showForm && (
          <FormEditarPago
            idStaff={values.idStaff}
            setcollapseForm={setcollapseForm}
            data={dataForEdit}
            unders={unders}
            status={status}
            updateTable={showPays}
          />
        )}
      </Collapse>

      <ModalTableVer
        modal={modalTabla}
        setModal={setModalTabla}
        title="Empleados"
        cabeceras={cabecerasModalTabla}
        filtro={filtroModalTabla}
        parametros={paramsModalTabla}
        selectRegistro={selectEmpleado}
        backdrop
        keyboard
        btnClear
      />
    </Widget>
  );
};
export default EditarPago;

export default function DownloadIconDetail({ style, onClick, className }) {
    return (
        <svg width="50.172mm" height="48.859mm" version="1.0" viewBox="0 0 300 300" xmlns="http://www.w3.org/2000/svg" style={style} onClick={onClick} className={className}>
            <g transform="translate(0 300) scale(.05 -.05)">
                <path d="m1553 5958c-157-83-152-35-153-1517 0-1498-7-1435 169-1520 85-42 105-42 1180-37l1094 6 50 58 50 57-54 56c-29 31-73 55-96 54-24-1-518-1-1098 1l-1055 4v1318c0 1183 3 1319 32 1330 65 25 2059 13 2084-12 18-18 24-196 24-675v-651l220-102v744 745l-54 64c-102 121-83 119-1250 118-1030-1-1069-2-1143-41z" />
                <path d="m1844 5165 6-115 1450-10v120 120h-731-731l6-115z" />
                <path d="m1853 4607c-7-8-13-62-13-120v-107h730 730v120 120h-717c-394 0-722-6-730-13z" />
                <path d="m3467 4259c-484-94-559-765-109-965 108-48 262-54 378-15l86 28 569-567 94 95 95 94-561 563 34 131c98 371-214 708-586 636zm264-240c135-83 179-255 104-399-148-279-575-175-575 140 0 236 269 384 471 259z" />
                <path d="m2720 2270v-470h-160c-95 0-160-8-160-20 0-28 558-700 581-700 35 0 559 671 559 716 0 17-50 24-160 24h-160v460 460h-250-250v-470z" />
                <path d="m1680 632c0-425 0-430 48-486l49-56h1193 1193l49 56c48 56 48 61 48 486v430l-350-12-6-305-5-305h-929-929l-5 305-6 305-350 12v-430z" />
            </g>
        </svg>
    )
}

import React from "react";
import Widget from "../../../../components/Widget/Widget";
import FormFuentesF from "../FormFuentesF";
import { createFinancialSources } from "../functions";

export const CrearFuentesF = () => {

  return (
    <Widget className="widget-p-md">
        <h3>Crear fuente de financiamiento</h3>
        <FormFuentesF crear={createFinancialSources}/>
    </Widget>
  )
}

export default CrearFuentesF;
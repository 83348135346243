import React, { useState, useEffect } from "react";
import Loader from "../../components/Loader/Loader";
import axios from "axios";
import {
  Col,
  Row,
  Table
} from "reactstrap";
import s from "../../pages/tables/Tables.module.scss";
import sc from "./TableComponentFiltro.module.scss";
import { toast } from "react-toastify";
import Notification from "../../components/Notification/Notification";
import { peticionEncript } from "../../helpers/peticionesEncripted";
import { formatCell } from "./cellsFormat";
import FullFormLoader from "../Loader/FullFormLoader";

export const TableComponentReportWithoutActions = (props) => {
  const {
    titulo,
    cabecerasTabla,
    parametros, //sobre que tabla hacer el select
    filtro, // filtro para mapear la info
    reporte, // Reporte enviado
    route,
    specialColumns,
  } = props;
  const [sizes, setSizes] = useState([]); //Columnas con tamaño extendido
  const [loading, setLoading] = useState(true);
  const [tableContent, setTableContent] = useState([]); // Información mostrada en la tabla

  const mapea = (element, indexCol, indexRow) => {
    return formatCell(
      element,
      indexCol,
      indexRow,
      true,
      false,
      cabecerasTabla[indexCol]
    );
  };

  const getData = async () => {
    //LA PRIMERA VEZ QUE ENTRAMOS VIENE UN ORDEROB = EMPTY Y EN EL PARAMS = ORDER = "", PAGE = 0 Y RECORDS = 5
    //CUANDO SELECCIONO EL HEADER VIENE ORDEROB = COLUMN = 1 Y DIRECTION = ASC Y EN EL PARAMS = ORDER = "ASC", PAGE = 0 Y RECORDS = 5
    //DESPUES DE ESO SELECCIONO EL PAGINADO Y MUESTRA ORDEROB = COLUMN = "", DIRECTION = "" Y PAGE = 5 Y EN EL PARAMS = ORDER = "", PAGE = 5 Y RECORDS = 5
    //SOLO HAY QUE VERIFICAR EL ESTADO DEL HOOK Y QUE NO CAMBIE LOS PARAMS
    var params = parametros;
    setLoading(true); 
    const finalData = peticionEncript(params);
    await axios
      .post(
        `${process.env.REACT_APP_API}${!route ? 'app/facades/reports/reportsF.php' : route}`,
        finalData
      )
      .then((res) => {
        let data = [];
        console.log(res.status, res.data.code)
        if (res.status === 200 && res.data.code === "200") {

          if (res.data.data.length != 0) {
            data = clearData(res.data.data);
          }
        } else {
          toast(
            <Notification
              type={"consultar_error"}
              backMessage={res.data.message}
              withIcon
            />,
            { closeButton: false }
          );
        }
        setTableContent(data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err)
        toast(<Notification type={"consultar_servidor_error"} withIcon />, {
          closeButton: false,
        });
        setLoading(false);
      });
  };

  function clearData(data) {
    let keys = [];
    cabecerasTabla.forEach((h) => {
      filtro.content.every((arr) => {
        let flg = true;
        arr.every((ob) => {
          if (ob.header === h) {
            keys.push([ob.value, ob.format]);
            flg = false;
            return false;
          }
          return true;
        });
        return flg;
      });
    });
    /// Id´s de reportes especiales
    if (![43].includes(+reporte)) {
      keys.unshift([filtro.id.id, undefined]);
    }
    if (reporte === "43") {
      data = Object.values(data).map(value => value[0])
    }
    const keysBD = Object.keys(data[0]);
    const indexs = [];
    let sizes = [];
    let pageData = data.map((d, idx) => {
      let reg = keys.map((k, idxk) => {
        if (idx === 0) {
          /// guardamos el nombre de cada columna de la bd
          indexs.push(keysBD.indexOf(k[0]) + 1);
        }
        let campo = d[k[0]];

        if (campo.length >= 100) {
          if (!sizes.includes(idxk)) {
            sizes.push(idxk);
          }
        }

        return campo;
      });

      return reg;
    });
    setSizes(sizes);
    return pageData;
  }

  useEffect(() => {
    getData();
  }, [parametros]);


  return (
    <div>
      <Row className="mb-4">
        <Col>
          <div className={s.tableTitle}>
            <h1 className="text-secondary text-center">{titulo} </h1>
          </div>
          <div className="widget-table-overflow mt-3">
            <Table
              className={`table-striped table-borderless table-hover ${s.statesTable}`}
              responsive
            >
              <thead className="border-bottom border-secondary">
                <tr className={sc.userSelectNone}>
                  {cabecerasTabla.map((cabecera, indexHeaders) => (
                    <th
                      key={cabecera}
                      className="text-center"
                      style={
                        sizes.includes(indexHeaders)
                          ? { minWidth: "400px" }
                          : {}
                      }
                    >
                      {cabecera === "Id" ? "#" : cabecera}
                      <i
                        className={
                          "align-top eva"
                        }
                      ></i>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <tr key={"loading"}>
                    <td colSpan={cabecerasTabla.length}>
                      <Loader />
                    </td>
                  </tr>
                ) : tableContent.length === 0 ? (
                  <tr key={"nothing"}>
                    <td colSpan={cabecerasTabla.length} className="text-center">
                      No se encontraron registros
                    </td>
                  </tr>
                ) : (
                  tableContent.map((registro, index) => (
                    <tr key={index}>
                      {registro.map((elemento, indexCol) => {
                        return mapea(elemento, indexCol, index);
                      })}

                      {specialColumns && specialColumns.length > 0
                        ? specialColumns.map((column, index) => (
                          <td className="text-center" key={`espCol${index}`}>
                            <div className="d-flex flex-column justify-content-center align-items-center">
                              {column(registro, `spc${index}`)}
                            </div>
                          </td>
                        ))
                        : null}
                    </tr>
                  ))
                )}
              </tbody>
            </Table>
          </div>
        </Col>
      </Row>
      <FullFormLoader
        show={loading}
        message={"Consultando datos , espere un momento"}
      />
    </div>
  );
};
export default TableComponentReportWithoutActions;

import React, { useEffect, useState } from "react";
import Widget from "../../../components/Widget/Widget";
import Datatable from "../../../components/Datatable/Datatable";
import { GetPermissions } from "../../../libs/permissions/getPermissions";
import { toast } from "react-toastify";
import Notification from "../../../components/Notification/Notification";
import ModalDelete from "../../../components/Modal/ModalDelete";
import { peticionesReceiver } from "../../../helpers/peticionesReceiver";
import Loader from "../../../components/Loader/Loader";
import { Button, Row, Collapse, Col } from "reactstrap";
import FormPagosPE from "./FormPagosPE";

import { useSelector } from "react-redux";
import ButtonInputM from "../../../components/GenericInputsFormik/ButtonInputModal";
import { YearInput } from "../../../components/GenericInputsFormik/YearInput";
import { SelectTypeHeadSingle } from "../../../components/GenericInputsFormik";
import { PERIOD } from "../../../libs/tools/format";

const PagosPercepcionesExtraordinarias = () => {
  const [permissions, setPermissions] = useState(true);
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [collapse, setCollapse] = useState("home"); //detail, add, home
  // const [data, setata] = useState("");
  const { profileType, id } = useSelector((state) => state.userInfo);
  const [data, setData] = useState("");
  // const idAreaLogged = profileType.selectProfile.idArea;
  // const [modalTabla, setModalTabla] = useState(false);
  const [filtros, setFiltros] = useState({idStaff: "", nameStaff: "", period: "", year: ""});

  GetPermissions(setPermissions, setLoading);
  const API = peticionesReceiver();

  const headers = [
    "Num. Empelado",
    "Nombre Empleado",
    "Unidad de trabajo",
    "Puesto",
    "Nombre de percepción extraordinaria",
    "Concepto",
    "Periodo de aplicación",
    "Importe",
    "Con Movimientos",
    "Días Pagados",
    "Días Descontados",
    "Días Trabajados",
    "Aplicados",
    "Editar",
    "Eiminar",
  ];

  const columns = [
    { data: "staffNumber" },
    { data: "fullName" },
    { data: "name" },
    { data: "nameJob" },
    { data: "namePerceptionExtra" },
    { data: "nameUnder" },
    {
      data: null,
      render: function (row) {
        return permissions.UPD ? (
          <td className="text-center d-flex justify-content-center">
            {`${row.period} / ${row.year}`}
          </td>
        ) : (
          "Accion no permitida"
        );
      },
    },
    { data: "amount", format: "currency" },
    {
      data: null,
      render: function (row) {
        return permissions.DEL ? (
          <td className="text-center d-flex justify-content-center">
            {+row.changeJobStaff === 0 ? "No" : "Si"}
          </td>
        ) : (
          "Accion no permitida"
        );
      },
    },
    { data: "daysPaid" },
    { data: "daysDiscount" },
    { data: "daysWork" },
    { data: "applied" },
    {
      data: null,
      render: function (row) {
        return (
          <td className="text-center d-flex justify-content-center">
            <i
              className={" fa fa-pencil text-warning handCursor"}
              onClick={() => {
                changeView("edit", row);
              }}
            />
          </td>
        );
      },
    },
    {
      data: null,
      render: function (row) {
        return permissions.DEL ? (
          <>
            <ModalDelete
              modalTitle="Eliminar"
              id={row.idSetup}
              table={["topics"]}
              nameColumn={["keyTopic"]}
              elimina={() => deletePayExtra(row)}
              setRefresh={setRefresh}
              type="icon"
            >
              <h6>¿Seguro de eliminar el registro?</h6>
              No podrá recuperar la información después de ser eliminada
            </ModalDelete>
          </>
        ) : (
          "Accion no permitida"
        );
      },
    },
  ];

  const deletePayExtra = async (payExtra) => {
    const params = {
      action: "delete",
      table: "paysextras",
      condition: {
        idPayExtra: payExtra.idPayExtra,
      },
    };
    await API.peticion(params)
      .then((res) => {
        if (res.data.status === "success" && res.data.code === "200") {
          toast(
            <Notification
              type={"elimina_exito"}
              backMessage={res.data.message}
              withIcon
            />
          );
          setRefresh(true);
        } else {
          toast(
            <Notification
              type={"elimina_error"}
              backMessage={res.data.message}
              withIcon
            />,
            { closeButton: false }
          );
        }
      })
      .catch((err) => {
        toast(<Notification type={"consultar_servidor_error"} withIcon />);
      });
  };

  const columnDefs = [
    {
      targets: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14],
      className: "text-center",
    },
    {
      orderable: false,
      targets: [0],
    },
  ];

  const getParamsTableElmt = (order, records, page, search) => {
    const params = {
      action: "datatable",
      table:
        "paysextras A INNER JOIN staff B on (A.idStaff = B.idStaff) INNER JOIN jobstaff C on (A.idJobStaff = C.idJobStaff) INNER JOIN jobs D on (C.keyJob = D.keyJob ) INNER JOIN areas E on (E.idArea = C.idArea) INNER JOIN perceptionsextras F on (A.idPerceptionExtra = F.idPerceptionExtra) INNER JOIN unders G on (A.idUnder = G.idUnder)",
      rows: "A.idPayExtra,A.idPerceptionExtra,A.idStaff,A.period,A.year,A.amount,A.daysPaid,A.changeJobStaff,A.daysDiscount,A.applied,A.comments, concat(B.name,' ',B.pName,' ',B.mName,' ') as fullName,D.nameJob,E.name,F.namePerceptionExtra, G.nameUnder,IFNULL(daysWork,'0') as daysWork,B.staffNumber",
      conditions: "",
      order,
      records: records.toString(),
      page: (page * records).toString(),
      search,
    };
    let cond = `A.enabled = 1 and B.enabled =1 and C.enabled =1 and D.enabled = 1 and E.enabled = 1 and F.enabled =1 and G.enabled =1`;
    if(filtros.idStaff) cond += ` AND A.idStaff = ${filtros.idStaff}`;
    if(filtros.period) cond += ` AND A.period = ${filtros.period}`;
    if(filtros.year) cond += ` AND A.year = ${filtros.year.getFullYear()}`;
    params.conditions = cond;
    return params;
  };

  async function getData(order, records, page, search) {
    try {
      const res = await API.peticion(
        getParamsTableElmt(order, records, page, search)
      );
      if (res.status === 200 && res.data.code === "200") {
        return res.data;
      } else {
        return null;
      }
    } catch (err) {
      toast(<Notification type={"consultar_servidor_error"} withIcon />);
      return null;
    }
  }

  const changeView = (view, row) => {
    if (collapse !== "home") {
      setData("");
      setCollapse("home");
    } else {
      setData(row);
      setCollapse(view);
    }
  };

  function onChange({ target }){
    setFiltros({ ...filtros, [target.name]: target.value });
  }
    function selectRow(row){
      setFiltros({ ...filtros, idStaff: row.idStaff, nameStaff: `${row.staffNumber} - ${row.fullName}` });
  }

  function clearInput(){
      setFiltros({ ...filtros, idStaff: "", nameStaff: "" });
  }

  useEffect(() => {
    setRefresh(true);
  }, [filtros]);


  return (
    <Widget className="widget-p-md">
      {loading ? (
        <div className="text-center">
          <Loader />
        </div>
      ) : (
        <>
          {permissions.CON ? (
            <>
              {collapse === "home" && (
                <div className="text-center">
                  <h2>Pagos Realizados</h2>
                </div>
              )}

              {collapse !== "home" &&  (
                <Row className="mb-3">
                  <Col className="text-right mb-3">
                    <Button onClick={() => {   changeView("home"); }} color={"danger"}>
                      Regresar
                    </Button>
                  </Col>
                </Row>
              )}

              <Collapse isOpen={collapse === "home" ? true : false}>
                <div>
                  <Row>
                    <Col xs={12} lg={6}>
                      <ButtonInputM
                        label="Empleado"
                        inputName="nameStaff"
                        value={filtros.nameStaff}
                        modalParams={modalTablaStaffParams}
                        selectRow={selectRow}
                        clearFun={clearInput}
                      />
                    </Col>
                    <Col xs={12} lg={3}>
                      <SelectTypeHeadSingle
                        label="Periodo"
                        optionsArray={PERIOD}
                        inputName="period"
                        onChangeMethod={onChange}
                        optionValue="value"
                        optionName="label"
                        value={filtros.period}
                        defaultOption="Seleccione un periodo"
                      />
                    </Col>

                    <Col xs={12} lg={3}>
                      <YearInput
                        label="Año"
                        inputName="year"
                        onChangeMethod={onChange}
                        value={filtros.year}
                        isMin={new Date("2020")}
                        isMax={new Date()}
                      />
                    </Col>
                  </Row>

                  <Datatable
                    headers={headers}
                    columns={columns}
                    columnDefs={columnDefs}
                    petition={getData}
                    control="back"
                    stateRefresh={[refresh, setRefresh]}
                    searching={true}
                    order={{ col: 6, opt: "desc" }}
                    className="p-0"
                  />
                </div>
              </Collapse>

              <Collapse isOpen={collapse === "edit"} className="mt-3">
                {collapse === "edit" && (
                  <FormPagosPE
                    collapse={collapse}
                    setCollapse={setCollapse}
                    setRefreshP={setRefresh}
                    data={data}
                    setData={setData}
                    permissions={permissions}
                  />
                )}
              </Collapse>
            </>
          ) : (
            <div className="text-center">
              No tiene permisos de ver este modulo
            </div>
          )}
        </>
      )}
    </Widget>
  );
};

export default PagosPercepcionesExtraordinarias;

const modalTablaStaffParams = {
  title: "Empelados",
  headers: ["No. Empleado", "Nombre", "Puesto"],
  columns: [
      {data: "staffNumber"},
      {data: "fullName"},
      {data: "nameJobType"}
  ],
  columnDefs: [{targets: [0, 2], className: "text-center"}],
  params: {
      table: "staff A INNER JOIN employtypes B ON A.keyEmployType = B.keyEmployType INNER JOIN jobstaff C ON C.idStaff = A.idStaff INNER JOIN jobtypes D ON D.keyJobType = A.keyEmployType",
      rows: "A.idStaff, A.staffNumber, CONCAT(A.name,' ',A.pName,' ',A.mName) as fullName, D.nameJobType",
      conditions: `A.enabled = 1 AND A.keyStat = 1 AND C.valid = 1 AND C.enabled = 1`
  },
}

import { useEffect, useState } from "react";
import Datatable from "../../../components/Datatable/Datatable";
import { doRequestSaveRes } from "../../../helpers/requests";
import { fDatewhitTime, months } from "../../../libs/tools/format";
import { Col, Row } from "reactstrap";
import {
    SelectTypeHeadSingle,
    TextInput,
} from "../../../components/GenericInputsFormik";
import format from "date-fns/format";
import FullFormLoader from "../../../components/Loader/FullFormLoader";
import { ButtonInput } from "../../../components/GenericInputsFormik/ButtonInput";
import ModalTableVer from "../../../components/Modal/ModalTableVer";
import { YearInput } from "../../../components/GenericInputsFormik/YearInput";


const headers = [
    "ÁREA SOLICITANTE",
    "FOLIO",
    "MES",
    "AÑO",
    "JUSTIFICACIÓN",
    "FECHA REGISTRO",
    "FECHA RECEPCIÓN",
    "ESTATUS",
    "VER",
];

const columnDefs = [
    {
        orderable: false,
        targets: [8],
        className: "text-center",
    },
    {
        orderable: true,
        targets: [1, 2, 3, 5, 6, 7],
        className: "text-center",
    },
];

const modalTableParams = {
    action: "datatable",
    table: "areas",
    rows: "idArea, name",
    conditions: "enabled = 1",
    page: 0,
    records: 5,
    search: "",
    order: "",
}

const cDate = new Date();
const cYear = cDate.getFullYear();

export default function ConsultaInicial({ areas, status, setSolicitud }) {
    const [loading] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [modalTable, setModalTable] = useState(false);
    const [filters, setFilters] = useState({
        area: "",
        status: "",
        dI: "",
        dF: format(cDate, "yyyy-MM-dd"),
        nArea: "",
        nStatus: "",
        month: "",
        year: ""
    });

    const columns = [
        { data: "nameArea", render: (d) => { return <div style={{ minWidth: "200px" }}>{d}</div> } },
        { data: "folio" },
        { data: "nameMonth" },
        { data: "yearRequest" },
        { data: "justification", render: (d) => { return <div style={{ minWidth: "400px" }}>{d}</div> } },
        {
            data: "created",
            render: (d) => {
                return fDatewhitTime(d);
            },
        },
        {
            data: "dateSend",
            render: (d) => {
                return fDatewhitTime(d);
            },
        },
        { data: "nameStat" },
        {
            data: null,
            render: (d) => {
                return (
                    +d.keyStat > 117 && +d.keyStat < 120 && (
                        <i
                            className="fa fa-eye text-info cursor-pointer"
                            onClick={() => setSolicitud({ id: d.idBudgetRequest, folio: d.folio, fecha: d.dateSend, justificacion: d.justification, estatus: d.keyStat, notas: d.notes })}
                        />
                    )
                );
            },
        },
    ];

    useEffect(() => {
        setRefresh(true);
    }, [filters]);

    /* Table */
    async function getData(ordCol, itemsPerPage, currentPage, search) {
        const res = await doRequestSaveRes(
            "receiver/receiver.php",
            getParams(ordCol, itemsPerPage, currentPage, search),
            false,
            false
        );
        if (res.code === "200") return res;
        else return null;
    }

    function getParams(ordCol, itemsPerPage, currentPage, search) {
        const params = {
            action: "datatable",
            table: "budgetrequests A INNER JOIN areas B USING (idArea) INNER JOIN status D ON D.keyStat = A.keyStat LEFT JOIN months E ON E.keyMonth = MONTH(A.dateSend)",
            rows: "B.name AS nameArea,CONCAT(A.numberRequest,'/',A.yearRequest) AS folio,E.nameMonth,A.yearRequest,A.justification,A.created,A.dateSend,D.nameStat,A.keyStat,A.idBudgetRequest,A.notes",
            conditions: getConditions(),
            order: ordCol,
            records: itemsPerPage.toString(),
            page: (itemsPerPage * currentPage).toString(),
            search
        };
        return params;
    }

    function getConditions() {
        let cond = "A.enabled = 1"; // AND A.keyStat IN (117, 118)
        if (filters.area) cond += ` AND A.idArea = ${filters.area}`;
        if (filters.status) cond += ` AND A.keyStat = ${filters.status}`;
        if (filters.dI) cond += ` AND IF(ISNULL(A.dateSend), true, A.dateSend >= '${filters.dI} 00:00:00')`;
        if (filters.dF) cond += ` AND IF(ISNULL(A.dateSend), true, A.dateSend <= '${filters.dF} 23:59:59')`;
        if (filters.month) cond += ` AND E.keyMonth = ${filters.month}`;
        if (filters.year) cond += ` AND A.yearRequest = ${filters.year.getFullYear()}`;
        return cond;
    }

    const onChange = ({ target }) => {
        setFilters((prevState) => ({
            ...prevState,
            dI: target.name === "year" ? (cmpYear(filters.dI, target.value) ? filters.dI : "") : target.name === "month" ? (cmpMonth(filters.dI, target.value)? filters.dI : "") : filters.dI,
            dF: target.name === "dI" 
                ? (cmpDates(target.value) ? filters.dF : "")
                : (target.name === "year" ? (cmpYear(filters.dF, target.value) ? filters.dF : "") : target.name === "month" ? (cmpMonth(filters.dF, target.value)? filters.dF : "") : filters.dF),
            nArea: target.name === "area" ? target.nArea : filters.nArea,
            [target.name]: target.value,
        }));
    };

    function cmpDates(d1) {
        if (!d1) return true;
        return new Date(d1).getTime() <= new Date(filters.dF).getTime();
    }

    function cmpYear(df, y){
        if(y) return + new Date(df).getFullYear() === y.getFullYear()
        return true
    }

    function cmpMonth(f, m){
        if(m) return new Date(f).getMonth()+1 === m
        return false
    }

    function getMinLimit(field){
        let fecha = new Date(cYear - 5, 1, 1);
        if(filters.year) fecha = new Date(filters.year.getFullYear(), 0, 1);
        if(filters.month) fecha = new Date(fecha.getFullYear(), +filters.month - 1, 1);
        if(field === "fF"){
            if(filters.dI){
                const dd = filters.dI.split("-");
                fecha = new Date(+dd[0], +dd[1]-1,dd[2]);
            }
        }
        return format(fecha, "yyyy-MM-dd");
    }
    
    function getMaxLimit(){
        let fecha = cDate;
        // if(filters.year) fecha = new Date(fecha.getFullYear(), 11, 31);
        // if(filters.month) fecha = new Date(fecha.getFullYear(), +filters.month - 1, daysInMonth(+filters.month, fecha.getFullYear()));
        return format(fecha, "yyyy-MM-dd");
    }

    // function daysInMonth (month, year) {
    //     return new Date(year, month, 0).getDate();
    // }

    function selectRow(row) {
        onChange({ target: { name: "area", value: row[0], nArea: row[1] } });
        setModalTable(false);
    }


    return (
        <div className="mt-4">
            <Row>
                <Col xs={12} md={6}>
                    <ButtonInput
                        label="Áreas"
                        inputName="area"
                        value={filters.nArea}
                        setModalTable={setModalTable}
                        clearFun={() => onChange({ target: { name: "area", value: "", nArea: "" } })}
                    />
                </Col>
                <Col xs={12} md={6}>
                    <SelectTypeHeadSingle
                        label="Estatus"
                        inputName="status"
                        onChangeMethod={onChange}
                        onBlurMethod={() => { }}
                        value={filters.status}
                        optionsArray={status}
                        optionValue="keyStat"
                        optionName="nameStat"
                    />
                </Col>
                <Col xs={12} md={6}>
                    <YearInput
                        label="Año"
                        inputName="year"
                        onChangeMethod={onChange}
                        onBlurMethod={() => { }}
                        value={filters.year}
                        isMin={cYear-5}
                        isMax={cYear+1}
                    />
                </Col>
                <Col xs={12} md={6}>
                    <SelectTypeHeadSingle
                        label="Mes"
                        inputName="month"
                        onChangeMethod={onChange}
                        onBlurMethod={() => { }}
                        value={filters.month}
                        optionsArray={months}
                        optionValue="value"
                        optionName="label"
                    />
                </Col>
                <Col xs={12} md={6}>
                    <TextInput
                        label="Fecha Inicial"
                        inputName="dI"
                        inputType="date"
                        onChangeMethod={onChange}
                        onBlurMethod={() => { }}
                        value={filters.dI}
                        isMin={getMinLimit("fI")}
                        isMax={getMaxLimit()}
                    />
                </Col>
                <Col xs={12} md={6}>
                    <TextInput
                        label="Fecha final"
                        inputName="dF"
                        inputType="date"
                        onChangeMethod={onChange}
                        onBlurMethod={() => { }}
                        value={filters.dF}
                        isMin={getMinLimit("fF")}
                        isMax={getMaxLimit()}
                    />
                </Col>
            </Row>

            <Datatable
                headers={headers}
                columns={columns}
                columnDefs={columnDefs}
                petition={getData}
                control="back"
                order={{col: 5, opt: "desc"}}
                stateRefresh={[refresh, setRefresh]}
                searching={true}
                className="px-0 mt-4"
            />

            <ModalTableVer
                modal={modalTable}
                setModal={setModalTable}
                title="Unidades de trabajo"
                cabeceras={["#", "Unidad de trabajo"]}
                filtro={[false, true]}
                parametros={modalTableParams}
                selectRegistro={(row) => selectRow(row)}
            />

            <FullFormLoader show={loading} message="Generando reporte..." />
        </div>
    );
}

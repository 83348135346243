import { useState } from "react";
import Datatable from "../../../../components/Datatable/Datatable";
import { doRequest, doRequestSaveRes } from "../../../../helpers/requests";
import { Button, Collapse } from "reactstrap";
import ButtonInputM from "../../../../components/GenericInputsFormik/ButtonInputModal";
import FullFormLoader from "../../../../components/Loader/FullFormLoader";
import ModalConfirmation from "../../../../components/Modal/ModalConfirmation";
import { getStaffPic } from "../../../../libs/tools/images";

const headers = [
    "No. Empleado",
    "Nombre Empleado",
    "Adscripción",
    "Puesto laboral",
    "Motivo Baja",
    "Estatus Finiquito",
    "Ver",
];

const columnDefs = [
    { targets: [6], orderable: false },
    { targets: [0, 4, 5], className: "text-center" },
];

const modalTablaStaffParams = {
    title: "Empelados",
    headers: ["No. Empleado", "Nombre", "Puesto"],
    columns: [
        { data: "staffNumber" },
        { data: "fullName" },
        { data: "nameJobType" },
    ],
    columnDefs: [{ targets: [0, 2], className: "text-center" }],
    params: {
        table: "staff A INNER JOIN employtypes B USING(keyEmployType) INNER JOIN jobstaff C USING(idStaff)",
        rows: "A.idStaff, A.staffNumber, CONCAT(A.name,' ',A.pName,' ',A.mName) as fullName, C.idJobStaff, A.keyStat",
        conditions: `A.enabled = 1 AND C.enabled = 1 AND C.valid = 1 AND C.keyMove IN (2,3) AND C.keyMove IN (2, 3) AND C.idJobStaff NOT IN (SELECT idJobStaff FROM settlements WHERE enabled = 1)`,
    },
};

const defaultForm = { 
    idStaff: "", 
    name: "",
    idJobStaff: "",
}

export default function Consulta({ setFiniquito, permissions }) {
    const [loading, setLoading] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [form, setForm] = useState(false);
    const [staff, setStaff] = useState({ ...defaultForm });
    const [modal, setModal] = useState(false);

    const columns = [
        { data: "staffNumber" },
        { data: "fullName" },
        { data: "nameArea" },
        { data: "nameJob" },
        { data: "statStaff" },
        { data: "statSettlement" },
        {
            data: null,
            render: (row) => (
                <i
                    className="fa fa-eye text-info cursor-pointer"
                    onClick={() => selectFiniquito(row)}
                />
            ),
        },
    ];

    async function getData(ordCol, itemsPerPage, currentPage, search, multi) {
        const res = await doRequestSaveRes(
            "receiver/receiver.php",
            getParams(ordCol, itemsPerPage, currentPage, search, multi),
            false,
            false
        );
        if (res.code === "200") return res;
        return null;
    }

    function getParams(ordCol, itemsPerPage, currentPage, search) {
        const params = {
            action: "datatable",
            table: "settlements A INNER JOIN staffview B USING (idStaff) INNER JOIN status F ON F.keyStat = B.keyStat INNER JOIN status G ON G.keyStat = A.keyStat LEFT JOIN bookcase H ON H.reference = B.idStaff AND H.keyFileType = 1 LEFT JOIN files I ON I.idBook = H.idBook AND I.enabled = 1",
            rows: "B.staffNumber, CONCAT_WS(' ', B.name, B.pName, B.mName) AS fullName, B.nameArea, B.nameJob, F.nameStat AS statStaff, G.nameStat AS statSettlement, B.keyGenre, B.nameEmployType, A.idSettlement, I.src, A.idStaff, B.nameBuilding, B.idJobStaff, B.keyStat AS keyStatStaff, A.keyStat AS keyStatSettlement",
            conditions: `A.enabled = 1`,
            order: ordCol,
            records: itemsPerPage.toString(),
            page: (itemsPerPage * currentPage).toString(),
            search,
        };
        return params;
    }

    function selectFiniquito(row) {
        setFiniquito({
            id: row.idSettlement,
            idStaff: row.idStaff,
            src: getStaffPic(row.keyGenre, row.src),
            fullName: row.fullName,
            employType: row.nameEmployType,
            staffNumber: row.staffNumber,
            nameArea: row.nameArea,
            nameJob: row.nameJob,
            nameStat: row.statStaff,
            nameBuilding: row.nameBuilding,
            idJobStaff: row.idJobStaff,
            keyStatStaff: row.keyStatStaff,
            keyStatSettlement: row.keyStatSettlement
        });
    }

    function selectRowM(row) {
        setStaff({
            idStaff: row.idStaff,
            name: `${row.staffNumber} - ${row.fullName}`,
            idJobStaff: row.idJobStaff
        });
    }

    function handleSubmit(e) {
        e.preventDefault();
        if (staff.idStaff) setModal(true);
    }

    function handleReset() {
        setStaff({ id: "", name: "" });
        setForm(false);
    }

    async function newSettlement() {
        setLoading(true);
        const params = { action: "newSettlement", ...staff };
        delete params.name;
        const res = await doRequest(
            "app/facades/employees/employeesF.php",
            params,
            true
        );
        if (res.length > 0) {
            handleReset();
            setRefresh(true);
        }
        setLoading(false);
    }

    return (
        <div>
            {permissions.CON && (
                <Datatable
                    headers={headers}
                    columns={columns}
                    columnDefs={columnDefs}
                    petition={getData}
                    control="back"
                    stateRefresh={[refresh, setRefresh]}
                    className="px-0 mt-4"
                />
            )}

            {permissions.INS && (
                <div className=" d-flex flex-md-row flex-column justify-content-md-end my-2">
                    <Button color="info" onClick={() => setForm(true)}>
                        Agregar
                    </Button>
                </div>
            )}

            <Collapse isOpen={form}>
                <form onSubmit={handleSubmit} onReset={handleReset}>
                    <ButtonInputM
                        label="Empleados que causaron Baja"
                        inputName="nameStaff"
                        value={staff.name}
                        modalParams={modalTablaStaffParams}
                        selectRow={selectRowM}
                        clearFun={() => setStaff({ ...defaultForm })}
                    />

                    <div className=" d-flex flex-md-row flex-column-reverse justify-content-md-around">
                        <Button color="danger" type="reset" className="mt-3">
                            Cancelar
                        </Button>
                        <Button
                            color="success"
                            type="submit"
                            className="mt-2 mt-md-3"
                        >
                            Guardar
                        </Button>
                    </div>
                </form>
            </Collapse>

            <ModalConfirmation
                modalTitle="Crear finiquito"
                modal={modal}
                setModal={setModal}
                crear={newSettlement}
                centered
            >
                <div className="d-flex flex-column text-center">
                    <h6>
                        ¿Está seguro de registrar el finiquito para el empleado{" "}
                        {staff.name}?
                    </h6>
                    <p>No podrá deshacer esta acción</p>
                </div>
            </ModalConfirmation>

            <FullFormLoader show={loading} />
        </div>
    );
}

import { useEffect, useState } from "react";
import { Button, Col, Row } from "reactstrap";
import { peticionesReceiver } from "../../../../helpers/peticionesReceiver";
import Notification from "../../../../components/Notification/Notification";
import { toast } from "react-toastify";
import Datatable from "../../../../components/Datatable/Datatable";
import ModalFactura from "./modalFactura";


export default function DetalleProveedorCargaFacturas({ idWinner, setIdWinner }) {
    const [provider, setProvider] = useState(null);
    const [income, setIncome] = useState(null)
    const [modal, setModal] = useState(false)
    const [refresh, setRefresh] = useState(false)
    const API = peticionesReceiver();

    function paramsProvider() {
        const params = {
            action: "multiselect",
            table: "providers p inner join providerquotes pq on (p.idProvider=pq.idProvider) inner join purchasewinners pw on (pw.idProviderQuote=pq.idProviderQuote)",
            rows: "p.idProvider,p.socialName,p.itin",
            conditions: `pw.idWinner=${idWinner}`,
        }
        return params;
    }

    async function getProvider() {
        try {
            const res = await API.peticion(paramsProvider());
            if (res.status === 200 && res.data.code === "200") {
                setProvider(res.data.data[0])
            } else {
                toast(<Notification type={"consultar_error"} backMessage={res.data.message} />);
            }
        } catch (err) {
            console.log(err)
            toast(<Notification type={"consultar_servidor_error"} withIcon />);
        }
    }

    useEffect(() => {
        getProvider()
    }, [])

    function ret() {
        setIdWinner("")
    }

    const columns = [
        { data: "folio" },
        { data: "dateIncome" },
        { data: "description" },
        {
            data: null,
            render: function (row) {
                if (!row.idInvoice) {
                    return (
                        <Button size="xs" className="d-flex align-items-center mx-auto" onClick={() => showModal(row)}>
                            <i className="fa fa-file-text mx-2" />
                            <p>Adjuntar factura</p>
                        </Button>
                    )
                }
                return (
                    <>
                        <Button size="xs" className="d-flex align-items-center mx-auto" onClick={() => showModal(row)}>
                            <i className="fa fa-eye mx-2" />
                            <p>Ver factura</p>
                        </Button>
                    </>
                )
            }
        }
    ]

    function showModal(data) {
        setModal(true);
        setIncome(data)
    }

    const headers = [
        "Número de entrega",
        "Fecha de entrega",
        "Detalle de entrega",
        "Factura",
    ]

    const getParams = (order, records, page, search) => {
        const params = {
            action: "datatable",
            table: `purchasewinners pw
            inner join receptions r on (pw.idWinner=r.idWinner AND r.enabled = 1)
            inner join warehouseincomereceptions wir on (wir.idReception=r.idReception)
            inner join warehouseincomes wi on (wi.idIncome=wir.idIncome)
            inner join providerquotes pq on (pw.idProviderQuote=pq.idProviderQuote)
            inner join quotesdetail qd on (qd.idDetail = pq.idDetail)
            left join services s on (s.idService=qd.idService)
            left join furnitures f on (f.idFurniture=qd.idFurniture)
            left join invoices i on (i.idIncome=wi.idIncome)`,
            rows: "wi.idIncome,CONCAT(wi.numberIncome,'/',wi.yearIncome) as folio, wi.dateIncome,GROUP_CONCAT(CONCAT(wir.incomeTotal,' DE ',r.quantity,' ',ifnull(s.nameService,f.nameFurniture)) separator ', ') as description,wi.keyStat,i.idInvoice",
            conditions: `pw.idWinner=${idWinner}`,
            group: "CONCAT(wi.numberIncome,'/',wi.yearIncome)",
            order,
            records: records.toString(),
            page: (page * records).toString(),
            search
        }
        return params
    }

    async function getData(order, records, page, search) {
        try {
            const res = await API.peticion(getParams(order, records, page, search));
            if (res.status === 200 && res.data.code === "200") {
                res.data.data.forEach((row, index) => row.index = (index + 1) + (page * records))
                return res.data;
            } else {
                toast(<Notification type={"consultar_error"} backMessage={res.data.message} />);
                return null;
            }
        } catch (err) {
            toast(<Notification type={"consultar_servidor_error"} withIcon />);
            return null
        }
    }

    const columnDefs = [
        { targets: [0], className: "text-center" }
    ]

    return (
        <>
            {setIdWinner && <Button className="mb-4" color="danger" onClick={ret}>Regresar</Button>}
            <h4 className="mb-4">Detalles de la entrega</h4>
            {provider && (
                <>
                    <Row className="mb-3">
                        <Col sm={12} md={8}>
                            <p>Razón social:</p>
                            <h5 className="border-bottom">{provider.socialName}</h5>
                        </Col>
                        <Col sm={12} md={4}>
                            <p>RFC:</p>
                            <h5 className="border-bottom">{provider.itin}</h5>
                        </Col>
                    </Row>
                    <Datatable
                        className="px-0"
                        headers={headers}
                        columns={columns}
                        control="back"
                        petition={getData}
                        columnDefs={columnDefs}
                        stateRefresh={[refresh, setRefresh]}
                    />
                    {income && (
                        <ModalFactura
                            income={income}
                            provider={provider}
                            setIncome={setIncome}
                            modal={modal}
                            refresh={() => setRefresh(true)}
                            setModal={setModal}
                        />
                    )}
                </>
            )}

        </>
    )
}
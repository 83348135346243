import Widget from "../../../components/Widget/Widget";
import React, { useEffect, useRef, useState } from "react";
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Collapse,
  Row,
  Table,
  Tooltip,
} from "reactstrap";
import { PERIOD } from "../../nomina/calculo/regex";
import {
  SelectTypeHeadSingle,
  TextAreaInput,
} from "../../../components/GenericInputsFormik";
import { YearInput } from "../../../components/GenericInputsFormik/YearInput";
import ModalReports from "../../../components/Modal/ModalReports";
import { toast } from "react-toastify";
import Notification from "../../../components/Notification/Notification";
import axios from "axios";
import { peticionEncript } from "../../../helpers/peticionesEncripted";
import { pdfResumenGlobalNomina } from "../../nomina/reportes/pdf/pdfResumenGlobalNomina";
import FullFormLoader from "../../../components/Loader/FullFormLoader";
import st from "./PagosTrasferenciasEmpleados.module.scss";
import ModalConfirmation from "../../../components/Modal/ModalConfirmation";
import ModalViewFile from "../../../components/Modal/ModalViewFile";
import RechazoPagoEmpleado from "./RechazoPagoEmpleado";
import { useLocation } from "react-router-dom";
import { saveAs } from "file-saver";
import { PDFDocument } from "pdf-lib";
import classNames from "classnames";
import { GetPermissions } from "../../../libs/permissions/getPermissions";

const fmt = new Intl.NumberFormat("es-MX", {
  style: "currency",
  currency: "MXN",
});

const defaultFiltro = {
  period: "",
  year: new Date(),
};
const date = new Date().getFullYear();

async function doRequest(route, params, seeOk, encript, seeError, specialRes) {
  let info = [];
  const dataEncrypted = encript !== false ? peticionEncript(params) : params;
  await axios
    .post(process.env.REACT_APP_API + route, dataEncrypted)
    .then((res) => {
      if (res.status === 200 && res.data.status === "success") {
        info = specialRes ? res.data : res.data.data;
        if (seeOk) {
          toast(
            <Notification
              type={"elimina_exito"}
              backMessage={res.data.message}
              withIcon
            />,
            { closeButton: false }
          );
        }
      } else if (seeError !== false) {
        toast(
          <Notification
            type={"consultar_error"}
            backMessage={res.data.message}
            withIcon
          />,
          { closeButton: false }
        );
      } else info = res.data;
    })
    .catch((err) => {
      toast(<Notification type={"consultar_servidor_error"} withIcon />, {
        closeButton: false,
      });
    });
  return info;
}
const finanzas = "pagos-transferencias-empleados";
const pensiones = "pagos-transferencias-pensiones";

const PagosTrasferenciasEmpleados = () => {
  const [permissions, setPermissions] = useState({});
  GetPermissions(setPermissions);
  
  const [activeCollapse, setActiveCollapse] = useState(1);
  const [loadingTab1, setLoadingTab1] = useState(false);
  const [loadingTab2, setLoadingTab2] = useState(false);
  const [modalReports, setModalReports] = useState(false);
  const [modalSavePagos, setModalSavePagos] = useState(false); /// Modal para guardar todos los pagos
  const [configPDF, setConfigPDF] = useState({});
  const [filtro, setFiltro] = useState({ ...defaultFiltro }); /// Formulario apra seleccionar la solicitus de pago
  const [infoReport, setInfoReport] = useState({}); /// Informacion reporte
  const [infoSolicitud, setInfoSolicitud] = useState({}); /// Informacion de la solicitud de pago
  const [archivosDispersion, setArchivosDispersion] = useState([]); /// Informacion de los layouts
  const [cuentasBancarias, setCuentasBancarias] = useState([]); /// Informacion de selects
  const [pagosBancos, setPagosBancos] = useState([]); /// Banccos
  const [rechazos, setRechazos] = useState([]); /// Rechazos
  const [canSave, setCanSave] = useState(true); /// Para mostrar el botón de guardar pagos
  const [notesPagos, setNotesPagos] = useState(""); /// Observacion de los pagos
  const [alertOpen, setAlertOpen] = useState(false); /// Mostrar la alerta con multiples errores
  const [errorsArr, setErrorsArr] = useState([]); /// Arreglo con errores

  const location = useLocation();
  const route = location.pathname.split("/")[2];
  const alertRef = React.useRef(null);

  /* Cambiar */
  useEffect(() => {
    if (activeCollapse === 1) {
      if (loadingTab1) setLoadingTab1(false);
    } else {
      if (loadingTab2) setLoadingTab2(false);
    }
  }, [activeCollapse]);

  const onChange = (e) => {
    if (e.target.name === "year") {
      if (e.target.value !== null)
        setFiltro((prevState) => ({ ...prevState, year: e.target.value }));
      else setFiltro((prevState) => ({ ...prevState, year: new Date() }));
    } else if (e.target.name === "period") {
      setFiltro((prevState) => ({ ...prevState, period: e.target.value }));
    }
  };

  /*Hacer consuolta de reporte de nomina */
  const getReportData = async () => {
    const params = {
      action: "report20",
      search: "",
      order: "",
      period: filtro.period,
      year: filtro.year.getFullYear(),
    };
    setConfigPDF({
      period: `${filtro.period}${filtro.year.getFullYear()}`,
    });
    const data = await doRequest("app/facades/reports/reportsF.php", params);
    if (data !== "") {
      setInfoReport(data);
      setModalReports(true);
    }
  };

  /*--- EMPLEADOS ---*/
  /* Buscar solicitud de pago */
  const findSolicitudPago = async () => {
    setLoadingTab1(true);
    const params = {
      action: "multiselect",
      table:
        "payrollrequest A INNER JOIN payrollrequestnotes B ON B.idPayrollRequest = A.idPayrollRequest",
      rows: "A.idPayrollRequest AS id, GROUP_CONCAT(B.keyStat) AS estatuses",
      conditions: ` A.period = ${
        filtro.period
      } AND A.year = ${filtro.year.getFullYear()} AND A.enabled = 1`,
      group: "id",
    };
    const data = await doRequest("receiver/receiver.php", params);
    if (data.length > 0) {
      const statuses = data[0].estatuses.split(",");
      await getPagosDepositosEmpleados(data[0].id, statuses);
    } else setLoadingTab1(false);
  };

  /* Buscar informacion de la solicitud de pago */
  const getPagosDepositosEmpleados = async (id, estatuses) => {
    const params = {
      action:
        route === finanzas
          ? "searchPagosDepositosEmpleados"
          : route === pensiones
          ? "searchPagosDepositosPensiones"
          : "",
      rows: { idPayrollRequest: id ? id : infoSolicitud.id },
    };
    const data = await doRequest(
      "app/facades/payslips/payrollrequestF.php",
      params
    );
    if (data.length > 0) {
      const arcDis = data[0].archivosDispersion.map((el, id) => {
        const nameFile = el.url.split("/");
        return {
          COL1: el.nameTypePayrollclerk,
          COL2: el.nameLayoutClass,
          COL3: nameFile[nameFile.length - 1],
          COL4: el.url,
        };
      });
      setArchivosDispersion(arcDis);
      setCuentasBancarias(data[0].cuentasBancarias);
      let [rech, badCase] = await getRechazos(id);
      const depBan = data[0].pagosBancos.map((el, id) => {
        //Buscar si ya se pagó
        let cuentaDep = "";
        let canRevert = true;
        if (el.idBankAccount !== "") {
          const dato = data[0].cuentasBancarias.find(
            (cb) => cb.idBankAccount === el.idBankAccount
          );
          if (dato !== null) cuentaDep = dato.cuenta;

          //Buscar si tiene rechazos
          if (rech.length > 0) {
            const exist = rech.filter((r) => r.COL9 === el.idPayrollBank);
            if (exist.length > 0) canRevert = false;
          }
        } else if (!badCase) badCase = true;

        return {
          COL1: el.deposit,
          COL2: el.nameBank,
          COL3: el.amount,
          COL4: el.rutaComprobante,
          COL5: el.idPayrollBank,
          COL6: el.idBankAccount,
          COL7: cuentaDep,
          COL8: el.keyBank,
          canRevert: canRevert,
        };
      });
      setCanSave(!badCase);
      setPagosBancos(depBan);
      setInfoSolicitud({
        id: id ? id : infoSolicitud.id,
        depositos: data[0].depositos,
        importe: data[0].importe,
        keyStat: data[0].keyStat,
        estatuses: estatuses ? estatuses : infoSolicitud.estatuses,
      });
      setLoadingTab2(false);
      setActiveCollapse(2);
    } else {
      setActiveCollapse(1);
      setLoadingTab1(false);
    }
  };

  /* Buscar todos los rechazos de la solicitud de pago */
  const getRechazos = async (id) => {
    let badCase = false; /// si existe un rechazo sin atender
    const params = {
      action: "searchPayrollrequestreturns",
      rows: {
        idPayrollRequest: id ? id : infoSolicitud.id,
      },
    };
    let data = await doRequest(
      "app/facades/payslips/payrollrequestF.php",
      params,
      false,
      true
    );
    if (data.length > 0) {
      if (route === finanzas)
        data = data.filter((el) => [1, 3].includes(+el.keyTypePayrollBank));
      if (route === pensiones)
        data = data.filter((el) => 2 === +el.keyTypePayrollBank);

      data = data.map((el) => {
        if (!badCase && +el.keyStat === 85) badCase = true;
        return {
          COL1: el.origenDesc,
          COL2: el.desBancoAnt,
          COL3: el.numEmpleado,
          COL4: el.nombre,
          COL5: el.beforeReturn,
          COL6: el.amount,
          COL7: el.nameStat,
          COL8: el.idPayrollRequestReturn,
          COL9: el.idPayrollBank,
          COL10: el.afterReturn,
          COL11: el.keyStat,
          COL12: el.keyTypePayrollBank,
        };
      });
    }
    setRechazos(data);
    return [data, badCase];
  };

  /*--- PENSIONES ---*/

  /* Descargar el layout */
  const downloadLayout = (el) => {
    saveAs(el.COL4, el.COL3);
  };

  /* Juntar y descargar todos los comprobantes */
  const downloadAllComp = async () => {
    let comprobantes = pagosBancos.filter((b) => b.COL4 !== "");
    if (comprobantes.length === 0) {
      toast(
        <Notification
          type={"warning"}
          backMessage="No se han subido comprobantes de pago"
          withIcon
        />,
        { closeButton: false }
      );
      return;
    }
    setLoadingTab2(true);
    try {
      const pdfDoc = await PDFDocument.create();
      for (const f in comprobantes) {
        /// Obtener comprobantes
        let res = await axios.get(comprobantes[f].COL4, {
          responseType: "arraybuffer",
        });

        const doc = await PDFDocument.load(res.data);
        const [page] = await pdfDoc.copyPages(doc, [0]);
        pdfDoc.addPage(page);
      }

      const pdfBytes = await pdfDoc.save(); /// Genear pdf
      const bytes = new Uint8Array(pdfBytes);
      const blobFile = new Blob([bytes], { type: "application/pdf" });

      saveAs(
        /// Guardar pdf
        blobFile,
        `Comprobantes de pago ${filtro.period}_${filtro.year.getFullYear()}`
      );
    } catch (error) {
      console.error(`No se puedo obtener la infromación. ${error}`);
      toast(
        <Notification
          type={"consultar_error"}
          backMessage={`No fue posible descargar los comprobantes`}
          withIcon
        />,
        { closeButton: false }
      );
    }
    setLoadingTab2(false);
  };

  /* Guardar pagos de empelados o pensiones */
  const savePago = async () => {
    setLoadingTab2(true);
    const params = {
      action:
        route === finanzas
          ? "finishPagosNominaBancos"
          : route === pensiones
          ? "finishPagosNominaPensiones"
          : "",
      rows: { idPayrollRequest: infoSolicitud.id, notes: notesPagos },
    };
    const data = await doRequest(
      "app/facades/payslips/payrollrequestF.php",
      params,
      true,
      true,
      false,
      true
    );
    if (data.data.length > 0) findSolicitudPago();
    else {
      setErrorsArr(data.message);
      setAlertOpen(true);
      scrollTo();
      setLoadingTab2(false);
    }
  };

  /* Revisar historia de status de la solicitud */
  const checkStat = () => {
    if (infoSolicitud.keyStat === 75) return true;
    const stat = route === finanzas ? "76" : route === pensiones ? "77" : "";
    if (infoSolicitud.estatuses) return !infoSolicitud.estatuses.includes(stat);
    return true;
  };

  /* Función para scrolear a un punto de la aplicación */
  const scrollTo = () => {
    setTimeout(
      () => alertRef.current.scrollIntoView({ behavior: "smooth" }),
      200
    );
  };

  /* Función limpair la info al cerrar */
  const clearData = () => {
    setArchivosDispersion([]);
    setCuentasBancarias([]);
    setPagosBancos([]);
    setInfoSolicitud({});
    setErrorsArr([]);
    setAlertOpen(false);
    setNotesPagos("");
    setCanSave(true);
  };

  /* Para mostrar titluod e la columna crear/editar */
  const setHeader = () => {
    if(permissions.INS && permissions.UPD) return (<>Guardar/ <br/>Revertir</>);
    if(permissions.INS) return (<>Guardar</>);
    if(permissions.UPD) return (<>Revertir</>);
  }

  return (
    <Widget className="widget-p-md">
      <div className="mb-4 d-flex justify-content-between flex-column flex-md-row text-center text-md-left">
        {route === finanzas ? (
          <h4>Pagos en trasferencias de empleados</h4>
        ) : (
          route === pensiones && <h4>Pagos en trasferencias de pensiones</h4>
        )}

        {Object.keys(infoSolicitud).length > 0 && (
          <div className="mt-2 mt-md-0">
            <Button
              className="btn text-light"
              style={{ backgroundColor: "#ba262f", border: "none" }}
              onClick={() => setActiveCollapse(1)}
            >
              Cerrar
              <i className="fa fa-sign-out ml-2" style={{ fontSize: "16px" }} />
            </Button>
          </div>
        )}
      </div>

      {/* Form filtro */}
      <Collapse isOpen={activeCollapse === 1}>
        <Row>
          <Col xs={12} lg={4}>
            <SelectTypeHeadSingle
              label="Periodo"
              isRequired={true}
              inputName="period"
              optionsArray={PERIOD}
              defaultOption="Seleccione un periodo"
              onChangeMethod={onChange}
              value={filtro.period}
              optionValue="value"
              optionName="label"
            />
          </Col>
          <Col xs={12} lg={4}>
            <YearInput
              label="Año"
              inputName="year"
              onChangeMethod={onChange}
              value={filtro.year}
              isMin={date - 1}
              isRequired
            />
          </Col>
          <Col xs={12} lg={2}>
            <div className="pt-lg-4 mt-lg-2 text-center">
              <Button
                color="info"
                onClick={() => findSolicitudPago()}
                disabled={filtro.period === "" || filtro.year === ""}
              >
                Consultar
              </Button>
            </div>
          </Col>
        </Row>
        <FullFormLoader show={loadingTab1} />
      </Collapse>

      {/* Content */}
      <Collapse
        isOpen={activeCollapse === 2}
        onExited={clearData}
        className="border-top"
      >
        <div className="mt-4 pt-3">
          <div className="text-center text-lg-left mb-4">
            <Button
              color="primary"
              type="button"
              onClick={() => getReportData()}
            >
              Resumen de Nómina
            </Button>
          </div>

          <Card className="mb-4 border border-secondary">
            <CardHeader
              className="text-center bg-secondary text-white"
              tag="h6"
            >
              Importe a Pagar
            </CardHeader>
            <CardBody>
              {route === finanzas && (
                <div className="text-center mb-1" style={{ color: "#343a40" }} >
                  <h5 className="font-weight-bold">Servidores Publicos</h5>
                  <div className="row" style={{ fontSize: "18px" }} >
                    <div className="col-12 col-md-4 mt-2">Banco</div>
                    <div className="col-12 col-md-4 mt-2">{infoSolicitud.depositos}</div>
                    <div className="col-12 col-md-4 mt-2">
                      <span className=" border-bottom border-dark px-3">
                        {fmt.format(infoSolicitud.importe)}
                      </span>
                    </div>
                  </div>
                </div>
              )}

              {route === pensiones && (
                <div className="text-center mb-1" style={{ color: "#343a40" }} >
                  <h5 className="font-weight-bold text-center">Pensiones Alimenticias</h5>
                  <div className="row d-flex justify-content-center" style={{ fontSize: "18px" }} >
                    <div className="col-12 col-md-4 mt-2">{infoSolicitud.depositos}</div>
                    <div className="col-12 col-md-4 mt-2">
                      <span className=" border-bottom border-dark px-3">
                        {fmt.format(infoSolicitud.importe)}
                      </span>
                    </div>
                  </div>
                </div>
              )}
            </CardBody>
          </Card>

          <Card className="mb-4 border border-secondary">
            <CardHeader
              className="text-center bg-secondary text-white"
              tag="h6"
            >
              Layout Dispersión
            </CardHeader>
            <CardBody>
              <Table responsive className="table-striped table-borderless">
                <thead
                  className={classNames([
                    "text-center",
                    st.thBB,
                    "border-secondary",
                  ])}
                >
                  <tr className={st.tableHeader}>
                    <th className="py-2">NO.</th>
                    <th className="py-2">Tipo Dispersión</th>
                    <th className="py-2">Tipo Layout</th>
                    <th className="py-2">Archivo</th>
                  </tr>
                </thead>
                <tbody>
                  {archivosDispersion.length > 0 ? (
                    archivosDispersion.map((el, id) => (
                      <tr
                        key={"rl" + id}
                        className={classNames([st.rowHover, st.tableText])}
                      >
                        <td className="text-center">{id + 1}</td>
                        <td>{el.COL1}</td>
                        <td>{el.COL2}</td>
                        <td>
                          <a
                            className={st.hoverLink}
                            onClick={() => {
                              downloadLayout(el);
                            }}
                          >
                            {el.COL3}
                          </a>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr
                      key="nothing"
                      className={classNames([
                        st.rowHover,
                        st.tableText,
                        "text-center",
                      ])}
                    >
                      <td colSpan={4}>No existen layouts</td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </CardBody>
          </Card>
        </div>

        <div className="mt-5">
          <div className="text-center" ref={alertRef}>
            <h4>Servidores Publicos</h4>
          </div>

          <div className="mt-4 d-flex justify-content-end  flex-wrap">
            {/* <Button color="primary" className=" my-2">
              Descargar Comprobantes Banorte
            </Button> */}
            <Button
              color="secondary"
              className=" ml-2 my-2"
              onClick={() => downloadAllComp()}
            >
              Descargar Comprobantes
              <i className="fa fa-download pl-2" />
            </Button>
          </div>

          <div className="mt-3">
            <Alert
              isOpen={alertOpen}
              toggle={() => setAlertOpen(!alertOpen)}
              color="danger"
              style={{
                backgroundColor: "#f6435550",
                borderLeft: "4px solid #FF4B23",
                color: "#f64355",
              }}
            >
              {errorsArr.map((el, id) => (
                <li key={id}>{el}</li>
              ))}
            </Alert>
          </div>

          <Table
            responsive
            className="table-striped table-borderless mt-3 mb-2"
          >
            <thead
              className={classNames([
                "text-center",
                st.thBB,
                "border-secondary",
              ])}
            >
              <tr>
                <th className="px-2 py-2">Deposito</th>
                <th className="px-2 py-2">Banco</th>
                <th className="px-2 py-2">Importe</th>
                <th className="px-2 py-2">Comprobante</th>
                <th
                  className="text-center px-2 py-2"
                  style={{ minWidth: "260px" }}
                >
                  Cuenta
                </th>
                {(permissions.INS || permissions.UPD ) && <th className="text-center px-2 py-2">
                  {setHeader()}
                </th>}
                {permissions.UPD && <th className="text-center px-2 py-2">Rechazos</th>}
                <th className="text-center px-2 py-2">Ver Archivo</th>
              </tr>
            </thead>
            <tbody>
              {pagosBancos.length > 0 ? (
                pagosBancos.map((el, id) => (
                  <TableRow
                    key={"tr" + id}
                    el={el}
                    cuentas={cuentasBancarias}
                    getPagosDepositosEmpleados={getPagosDepositosEmpleados}
                    setLoading={setLoadingTab2}
                    route={route}
                    checkStat={checkStat}
                    permissions={permissions}
                  />
                ))
              ) : (
                <tr
                  key="nothing"
                  className={classNames([
                    st.rowHover,
                    st.tableText,
                    "text-center",
                  ])}
                >
                  <td colSpan={8}>No hay pagos por realizar</td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>

        <div className="mt-5 mb-3 pt-2">
          <div className="text-center">
            <h4>
              {route === finanzas
                ? "Pagos Rechazados"
                : route === pensiones
                ? "Pensiones Rechazadas"
                : ""}
            </h4>
          </div>

          <Table responsive className="table-striped table-borderless mt-2">
            <thead
              className={classNames([
                "text-center",
                st.thBB,
                "border-secondary",
              ])}
            >
              <tr>
                <th className="px-2 py-2">Origen</th>
                <th className="px-2 py-2">Banco</th>
                <th className="px-2 py-2">Num. Empleado</th>
                <th className="px-2 py-2">Nombre</th>
                <th className="px-2 py-2">Motivo</th>
                <th className="px-2 py-2">Importe</th>
                <th className="px-2 py-2">Estatus</th>
                <th className="px-2 py-2">Detalle</th>
                {permissions.DEL && <th className="px-2 py-2">Eliminar</th>}
              </tr>
            </thead>
            <tbody>
              {rechazos.length > 0 ? (
                rechazos.map((el, id) => (
                  <RowRechazo
                    el={el}
                    getPagosDepositosEmpleados={getPagosDepositosEmpleados}
                    key={"R" + el.COL8}
                    setLoading={setLoadingTab2}
                    permissions={permissions}
                  />
                ))
              ) : (
                <tr
                  key="nothing"
                  className={classNames([
                    st.rowHover,
                    st.tableText,
                    "text-center",
                  ])}
                >
                  <td colSpan={9}>No se han generado rechazos</td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>

        {checkStat() && permissions.INS && (
          <div className="text-center">
            <div className="mb-5 mx-0 mx-lg-5 mt-4">
              <TextAreaInput
                label="Observaciones"
                inputName="notesPagos"
                inputRows={6}
                onChangeMethod={(e) => {
                  setNotesPagos(e.target.value);
                }}
                value={notesPagos}
                isDisabled={!canSave}
              />
            </div>
            <Button
              color="success"
              onClick={() => setModalSavePagos(true)}
              disabled={!canSave}
            >
              Guardar Pagos
            </Button>
          </div>
        )}

        <FullFormLoader show={loadingTab2} />
      </Collapse>

      <ModalReports
        modal={modalReports}
        setModal={setModalReports}
        title={"RESUMEN GLOBAL DE NÓMINA"}
        backdrop
        keyboard
        report={infoReport}
        pdfConfig={configPDF}
        generatePdfMethod={{
          method: pdfResumenGlobalNomina,
          type: "resumenGlobalNomina",
        }}
      />

      <ModalConfirmation
        modal={modalSavePagos}
        setModal={setModalSavePagos}
        editar={() => savePago()}
        isEdit={true}
      >
        <div className="d-flex justify-content-center">
          <div className="text-center">
            <p style={{ fontSize: "22px" }} className="mb-2">
              ¿Está seguro de guardar los pagos de{" "}
              {route === finanzas ? "Empleados" : "Pensiones"} para el periodo{" "}
              <span style={{ fontWeight: "bold" }}>
                {filtro.period}/{filtro.year.getFullYear()}
              </span>
              ?
            </p>
            <p className="mt-3">Esta acción no se podrá deshacer</p>
          </div>
        </div>
      </ModalConfirmation>
    </Widget>
  );
};

export default PagosTrasferenciasEmpleados;

const TableRow = ({
  el,
  cuentas,
  getPagosDepositosEmpleados,
  setLoading,
  route,
  checkStat,
  permissions
}) => {
  const [modalConfirm, setModalConfirm] = useState(false);
  const [modalConfirmMode, setModalConfirmMode] = useState(0);
  const [modalFile, setModalFile] = useState(false);
  const [modalRechazo, setModalRechazo] = useState(false);
  const [linkFile, setlinkFile] = useState("");
  const [idBankAccount, setidBankAccount] = useState("");
  const [textAccount, settextAccount] = useState("");
  const [file, setfile] = useState("");
  const inputRef = useRef(null);
  const selectRef = useRef(null);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);

  const onChange = (e) => {
    if (e.target.name === "file") {
      if (e.target.files.length === 0) setfile("");
      else setfile(e.target.files[0]);
    } else {
      setidBankAccount(e.target.value);
    }
  };

  /* Guardar comprobante de pago de un baco */
  const saveInfo = async () => {
    setLoading(true);
    const rows = {
      idBankAccount: idBankAccount,
      idPayrollBank: el.COL5,
    };
    const formData = new FormData();
    formData.append("action", "insertCuentayComprobante");
    formData.append("rows", JSON.stringify(rows));
    formData.append("binary", file);

    const data = await doRequest(
      "app/facades/payslips/payrollrequestF.php",
      formData,
      true,
      false
    );
    if (data.length > 0) await getPagosDepositosEmpleados();
    else setLoading(false);
  };

  /* Para validar la info al guardar */
  const handleSubmit = () => {
    let msg = "";

    if (file === "" && idBankAccount === "")
      msg = "Cargue un comprobante y selecione una cuenta";
    else if (file === "") msg = "Cargue un comprobante";
    else if (idBankAccount === "") msg = "Selecione una cuenta";
    else if (file.type !== "application/pdf") msg = "Tipo de archivo invalido";
    if (msg === "") {
      setModalConfirmMode(1);
      settextAccount(selectRef.current.state.text);
      setModalConfirm(true);
    } else {
      toast(
        <Notification
          type={"consultar_error"}
          backMessage={`${msg} para el banco ${el.COL2}`}
          withIcon
        />,
        { closeButton: false }
      );
    }
  };

  /* Mostrar modal pdf */
  const openFile = () => {
    if (el.COL4 !== "") {
      setlinkFile(el.COL4);
      setModalFile(true);
    }
  };

  /* revertir el gaurdado del comprobante de pago */
  const revertirMovimiento = async () => {
    setLoading(true);
    const params = {
      action: "insertRevertirMovimiento",
      rows: { idPayrollBank: el.COL5 },
    };
    const data = await doRequest(
      "app/facades/payslips/payrollrequestF.php",
      params,
      true,
      true
    );
    if (data.length > 0) await getPagosDepositosEmpleados();
    else setLoading(false);
  };

  /* Mostrar modal de revertir pago */
  const handleDelete = () => {
    setModalConfirmMode(2);
    setModalConfirm(true);
  };

  return (
    <>
      <tr className={classNames(st.rowHover, st.tableText)}>
        <td className="px-2 text-center">{el.COL1}</td>
        <td className="px-2">{el.COL2}</td>
        <td className="px-2 text-right">{fmt.format(el.COL3)}</td>
        <td className="px-2 text-center">
          {el.COL4 === "" && (
            <>
              <Button
                outline
                color="secondary-red"
                className="px-3"
                type="button"
                onClick={() => inputRef.current.click()}
                disabled={!permissions.INS}
              >
                <i className="fa fa-file-pdf-o" />
              </Button>
              <input
                onChange={onChange}
                style={{ display: "none" }}
                type="file"
                ref={inputRef}
                name="file"
                id="file"
                accept="application/pdf"
              />
            </>
          )}
        </td>
        <td className={"px-2 text-center " + (el.COL4 === "" && "py-0 pt-3")}>
          {el.COL4 === "" ? (
            <div className="">
              <SelectTypeHeadSingle
                inputName={"idBankAccount"}
                optionsArray={cuentas}
                defaultOption="Seleccione una cuenta"
                onChangeMethod={onChange}
                value={idBankAccount}
                optionValue="idBankAccount"
                optionName="cuenta"
                isFixed
                refSelect={selectRef}
                isDisabled={!permissions.INS}
              />
            </div>
          ) : (
            <p>{el.COL7}</p>
          )}
        </td>
        <td className="px-2 text-center" id={"tt-" + el.COL8}>
          {checkStat() &&
            (permissions.INS && el.COL4 === "" ? (
              <Button
                outline
                color="success"
                className="px-3"
                type="button"
                onClick={() => handleSubmit()}
              >
                <i className="fa fa-floppy-o" />
              </Button>
            ) : ( permissions.UPD && el.COL4 !== "" &&
              <>
                <Button
                  outline
                  color={"danger"}
                  className="p-0"
                  disabled={!el.canRevert}
                  onClick={() => handleDelete()}
                >
                  <i
                    className="fa fa-reply-all"
                    style={{ padding: "9px 16px" }}
                  />
                </Button>
                {!el.canRevert && (
                  <Tooltip
                    placement="bottom"
                    isOpen={tooltipOpen}
                    target={"tt-" + el.COL8}
                    toggle={toggle}
                  >
                    Existen rechazos
                  </Tooltip>
                )}
              </>
            ))}
        </td>
        {permissions.UPD && <td className="px-2 text-center">
          {checkStat() && el.COL4 !== "" && (
            <Button
              outline
              color="warning"
              className="p-0"
              onClick={() => setModalRechazo(true)}
            >
              <i
                className="fa fa-thumbs-o-down"
                style={{ padding: "9px 17px" }}
              />
            </Button>
          )}
        </td>}
        <td className="px-2 text-center">
          {el.COL4 !== "" && (
            <Button
              outline
              color="info"
              className="px-3"
              onClick={() => openFile()}
            >
              <i className="fa fa-eye" />
            </Button>
          )}
        </td>
      </tr>

      <RechazoPagoEmpleado
        modal={modalRechazo}
        setModal={setModalRechazo}
        idPayrollBank={el.COL5}
        idBankAccount={el.COL6}
        getPagosDepositosEmpleados={getPagosDepositosEmpleados}
        route={route}
      />

      <ModalConfirmation
        modal={modalConfirm}
        setModal={setModalConfirm}
        editar={() =>
          modalConfirmMode === 1 ? saveInfo() : revertirMovimiento()
        }
        isEdit={true}
        buttonOkMsg="Continuar"
      >
        {modalConfirmMode === 1 ? (
          <div className="d-flex justify-content-center">
            <div className="text-center">
              <p style={{ fontSize: "22px" }} className="mb-2">
                ¿Está seguro de guardar este comprobante con la cuenta:{"\n"}
                <span style={{ fontWeight: "bold" }}>
                  {el.COL2}/{textAccount}
                </span>
                ?
              </p>
              <p className="mt-3">Esta acción no se podrá deshacer</p>
            </div>
          </div>
        ) : (
          <div className="d-flex justify-content-center">
            <div className="text-center">
              <p style={{ fontSize: "22px" }} className="mb-2">
                ¿Está seguro de revertir los pagos en el banco{" "}
                <span style={{ fontWeight: "bold" }}>{el.COL2}</span>?
              </p>
              <p className="mt-3">Esta acción no se podrá deshacer</p>
            </div>
          </div>
        )}
      </ModalConfirmation>

      <ModalViewFile
        modal={modalFile}
        setModal={setModalFile}
        backdrop={true}
        keyboard={true}
        btnClose={true}
        fileLink={linkFile}
      />
    </>
  );
};

const RowRechazo = ({ el, getPagosDepositosEmpleados, setLoading, permissions }) => {
  const [modalConfirm, setModalConfirm] = useState(false);

  /* Eliminar rechazo de la solicitud de pago */
  const deleteRechazo = async () => {
    setLoading(true);
    const params = {
      action: "deleteRechazo",
      rows: {
        idPayrollRequestReturn: el.COL8,
        idPayrollBank: el.COL9,
        amount: el.COL6,
        name: el.COL4,
      },
    };
    const data = await doRequest(
      "app/facades/payslips/payrollrequestF.php",
      params,
      true,
      true
    );
    if (data.length > 0) await getPagosDepositosEmpleados();
    else setLoading(false);
  };

  return (
    <>
      <tr className={classNames(st.rowHover, st.tableText)}>
        <td className="text-center">{el.COL1}</td>
        <td className="text-center">{el.COL2}</td>
        <td className="text-center">{el.COL3}</td>
        <td className="">{el.COL4}</td>
        <td className="">{el.COL5}</td>
        <td className="text-right">{fmt.format(el.COL6)}</td>
        <td className="text-center">{el.COL7}</td>
        <td className="">{el.COL10}</td>
        {permissions.DEL && <td className="text-right">
          {+el.COL11 === 85 && (
            <Button
              outline
              color="danger"
              className="px-3"
              onClick={() => setModalConfirm(true)}
            >
              <i className="fa fa-trash" />
            </Button>
          )}
        </td>}
      </tr>

      <ModalConfirmation
        modal={modalConfirm}
        setModal={setModalConfirm}
        editar={() => deleteRechazo()}
        isEdit={true}
        buttonOkMsg="Aceptar"
      >
        <div className="d-flex justify-content-center">
          <div className="text-center">
            <p style={{ fontSize: "20px" }} className="mb-2">
              ¿Desea eliminar el rechazo de pago del empleado{" "}
              <span style={{ fontWeight: "bold" }}>
                {el.COL3} - {el.COL4}
              </span>
              ?
            </p>
          </div>
        </div>
      </ModalConfirmation>
    </>
  );
};


import React from "react";
import Widget from "../../../../components/Widget/Widget";
import FormPlazas from "../FormPlazas";
import { createJobPosition } from "../functions";

export const CrearPlazaLaboral = (props) => {

  return (
    <Widget className="widget-p-md">
      <h3>Crear Plaza laboral</h3>
      <FormPlazas crear={createJobPosition}/>
    </Widget>
  )
}

export default CrearPlazaLaboral;
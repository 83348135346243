import React, { useState, useEffect } from "react";
import Widget from "../../../../../components/Widget/Widget";
import TableProgressBar from "../../../componentes/table/TableProgressBar";

const RegistroAvances = ({ proyect }) => {
  const parametros = {
    action: "getTotalActivities",
    rows: "idActivity,nameActivity,resposible,startDate,endDate,PROGRESS",
    idProyect: proyect.id,
    page: 0,
    records: 5,
    search: "",
    order: "",
  };


  return (
    <Widget>
      <TableProgressBar
        titulo={"Avances"}
        cabecerasTabla={[
          "Id",
          "Accion",
          "Encargado",
          "Fecha de inicio",
          "Fecha de termino",
          "Avance",
        ]}
        filtro={[true, true, true, true, true, true, true]}
        parametros={parametros}
        routePeticion="app/facades/planning/planningF.php"
      />

    </Widget>
  );
};

export default RegistroAvances;

import React, { useEffect } from "react";
import s from "../../../../assets/tables/Tables.module.scss";
import sc from "../../../../components/TableComponent/TableComponentFiltro.module.scss";
import { Table, Row, Col } from "reactstrap";
import { useState } from "react";
import Loader from "../../../../components/Loader/Loader";
import { toast } from "react-toastify";
import Notification from "../../../../components/Notification/Notification";
import { CustomInput } from "reactstrap";
import { peticionesReceiver } from "../../../../helpers/peticionesReceiver";
import FullFormLoader from "../../../../components/Loader/FullFormLoader";

const TablesSwitchs = ({ idPerceptionExtra, permissions }) => {
  const [loading, setLoading] = useState(true);
  const [fullLoading, setFullLoading] = useState(false);
  const [perception, setPerception] = useState("");
  const API = peticionesReceiver();

  useEffect(() => {
    
    getPerception();
  }, []);

  const getPerception = async () => {
    const data = {
      action: "multiselect",
      table: "perceptionsextras",
      rows: "idPerceptionExtra,namePerceptionExtra,base,reward,larder,clearance,fortalecimiento,absences,licenses,incapacitys,penaltys",
      conditions: `enabled = 1 and idPerceptionExtra = ${idPerceptionExtra}`,
    };
    await API.peticion(data)
      .then((res) => {
        if (res.status === 200 && res.data.code === "200") {
          setPerception(res.data.data[0]);
        } else {
          toast(<Notification type={"consultar_error"} withIcon />);
        }
      })
      .catch((err) => {
        toast(<Notification type={"consultar_servidor_error"} withIcon />);
      });
    setLoading(false);
  };

  const onChangeSwitch = async ({ target }) => {
    if (permissions.UPD) {
      setFullLoading(true);
      const { name, value, checked } = target;

      const params = {
        action: "update",
        table: "perceptionsextras",
        rows: {
          [name]: checked ? "1" : "0",
        },
        condition: {
          idPerceptionExtra: idPerceptionExtra,
        },
      };
      

      const res = await API.peticion(params);
      if (res.status === 200 && res.data.status === "success") {
        toast(
          <Notification
            type={"agrega_exito"}
            backMessage={res.data.message}
            withIcon
          />
        );
        
        setPerception(res.data.data[0]);
      } else {
        toast(
          <Notification
            type={"agrega_error"}
            backMessage={res.data.message}
            withIcon
          />
        );
      }
      setFullLoading(false);
    }
  };

  return (
    <div>
      {loading && perception !== "" ? (
        <Loader />
      ) : (
        <Row className="d-flex justify-content-around">
          <Col xs={12} lg={5}>
            <Table
              className={`table-striped table-borderless table-hover ${s.statesTable} mt-3`}
              responsive
              key={"table1"}
            >
              <thead className="border-bottom border-secondary">
                <tr className={sc.userSelectNone}>
                  <th colSpan={2} className="text-center">
                    Percepciones
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr key={"R1"}>
                  <td className="text-center">Sueldo Base</td>
                  <td className="text-center">
                    <CustomInput
                      type="switch"
                      id="base"
                      name="base"
                      key="base"
                      onChange={onChangeSwitch}
                      checked={+perception.base === 1}
                    />
                  </td>
                </tr>
                <tr key={"R2"}>
                  <td className="text-center">Gratificación</td>
                  <td className="text-center">
                    <CustomInput
                      type="switch"
                      id="reward"
                      name="reward"
                      key="reward"
                      onChange={onChangeSwitch}
                      checked={+perception.reward === 1}
                    />
                  </td>
                </tr>
                <tr key={"R3"}>
                  <td className="text-center">Despensa</td>
                  <td className="text-center">
                    <CustomInput
                      type="switch"
                      id="larder"
                      name="larder"
                      key="larder"
                      onChange={onChangeSwitch}
                      checked={+perception.larder === 1}
                    />
                  </td>
                </tr>
                <tr key={"R4"}>
                  <td className="text-center">Compensación</td>
                  <td className="text-center">
                    <CustomInput
                      type="switch"
                      id="clearance"
                      name="clearance"
                      key="clearance"
                      onChange={onChangeSwitch}
                      checked={+perception.clearance === 1}
                    />
                  </td>
                </tr>
                <tr key={"R5"}>
                  <td className="text-center">Fortalecimento</td>
                  <td className="text-center">
                    <CustomInput
                      type="switch"
                      id="fortalecimiento"
                      key="fortalecimiento"
                      name="fortalecimiento"
                      onChange={onChangeSwitch}
                      checked={+perception.fortalecimiento === 1}
                    />
                  </td>
                </tr>
              </tbody>
            </Table>
          </Col>

          <Col xs={12} lg={5}>
            <Table
              className={`table-striped table-borderless table-hover ${s.statesTable} mt-3`}
              responsive
              key={"table2"}
            >
              <thead className="border-bottom border-secondary">
                <tr className={sc.userSelectNone}>
                  <th colSpan={2} className="text-center">
                    Días de descuento
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr key={"R100"}>
                  <td className="text-center">Inasistencias</td>
                  <td className="d-flex justify-content-center align-items-center">
                    <CustomInput
                      type="switch"
                      id="absences"
                      name="absences"
                      key="bancenses"
                      onChange={onChangeSwitch}
                      checked={+perception.absences === 1}
                    />
                  </td>
                </tr>
                <tr key={"R102"}>
                  <td className="text-center">Licencias</td>
                  <td className="d-flex justify-content-center align-items-center">
                    <CustomInput
                      type="switch"
                      id="licenses"
                      name="licenses"
                      key="licences"
                      onChange={onChangeSwitch}
                      checked={+perception.licenses === 1}
                    />
                  </td>
                </tr>
                <tr key={"R103"}>
                  <td className="text-center">Incapacidades</td>
                  <td className="text-center">
                    <CustomInput
                      type="switch"
                      id="incapacitys"
                      name="incapacitys"
                      key="incapacitys"
                      onChange={onChangeSwitch}
                      checked={+perception.incapacitys === 1}
                    />
                  </td>
                </tr>
                <tr key={"R104"}>
                  <td className="text-center">Sanciones</td>
                  <td className="text-center">
                    <CustomInput
                      type="switch"
                      id="penaltys"
                      name="penaltys"
                      key="penaltys"
                      onChange={onChangeSwitch}
                      checked={+perception.penaltys === 1}
                    />
                  </td>
                </tr>
              </tbody>
            </Table>
          </Col>
        </Row>
      )}

      <FullFormLoader
        show={fullLoading}
        setShow={setFullLoading}
        message={"Enviando solicitud Espere un momento"}
      />
    </div>
  );
};

export default TablesSwitchs;

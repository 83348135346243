import React from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { isMobile } from "react-device-detect";
import FileSaver from "file-saver";

function ModalViewFile(props) {
  const {
    modal,
    setModal,
    title,
    backdrop, // true, false, "static"
    keyboard, // true, false
    btnClose, // true, false
    fileLink,
    type, // pdf , excel
    children,
  } = props;

  const toggle = () => {
    setModal(!modal);
  };

  const CloseBtn = (
    <>
      {btnClose ? (
        <i className="eva eva-close cursor-pointer" onClick={toggle} />
      ) : (
        <></>
      )}
    </>
  );

  const fileType = () => {
    // IMAGES
    if (type) {
      return type === "pdf" ? 2 : 1;
    } else {
      const images = /^[\w.-:/]+(jpg|png|jpeg|gif)$/g; // /^[\w.-:\/]+(jpg|png|jpeg|gif)$/g
      const pdf = /^[\w.-:/]+(pdf)$/g; // /^[\w.-:\/]+(pdf)$/g
      if (images.test(fileLink)) {
        return 1;
      } else if (pdf.test(fileLink)) {
        return 2;
      }
      return 2;
    }
  };

  return (
    <Modal
      isOpen={modal}
      backdrop={backdrop}
      keyboard={keyboard}
      toggle={toggle}
      style={{ minWidth: "95%" }}
    >
      <ModalHeader toggle={toggle} close={CloseBtn}>
        {title || ""}
      </ModalHeader>
      <ModalBody className="">
        {fileType() === 1 ? (
          <div className="text-center">
            <img
              src={fileLink}
              alt="archivo"
              className="img-fluid"
              style={{ maxWidth: "60%" }}
            />
          </div>
        ) : fileType() === 2 ? (
          <>
            {isMobile ? (
              <div
                onClick={() =>
                  FileSaver.saveAs(
                    fileLink,
                    fileLink.substring(fileLink.lastIndexOf("/") + 1)
                  )
                }
              >
                <div className="text-center px-3 pt-3">
                  <i className="fa fa-download" style={{ fontSize: "80px" }} />
                </div>
                <div className="text-center px-3" style={{ fontSize: "20px" }}>
                  Descargar
                </div>
              </div>
            ) : (
              <div className="embed-responsive embed-responsive-21by9">
                <iframe
                  className="embed-responsive-item"
                  src={fileLink}
                  allowFullScreen
                  title="pdf"
                />
              </div>
            )}
          </>
        ) : (
          <div className="text-center">
            <p>Formato no soportado</p>
          </div>
        )}

        {children}
      </ModalBody>
    </Modal>
  );
}

export default ModalViewFile;

import Widget from "../../../../components/Widget/Widget";
import FormCarreras from "../FormCarreras"
import { editar } from "../functions";

const EditarCarrera = (props) => {
    return (
    <div>
      <Widget className="widget-p-md">
        <h3>Editar Carrera</h3>
        <FormCarreras data={localStorage.getItem('dataContent').split('|-|')} editar={editar}/>
    </Widget>
    </div>
  )
}

export default EditarCarrera
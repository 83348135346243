import React from "react";

import Widget from "../../../../components/Widget/Widget";
import FormDistrito from "../FormDistrito";
import { createDistrict } from "../functions";

export const CrearDistrito = (props) => {
  return (
    <Widget className="widget-p-md">
        <h3>Crear Distrito</h3>
        <FormDistrito crear={createDistrict}/>
    </Widget>
  )
}

export default CrearDistrito;
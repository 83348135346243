import React from "react";
import {
  Col,
  Row
} from "reactstrap";
import Widget from "../../components/Widget/Widget.js";

export default function Dashboard() {

  return (
    <div>
      <Row>
        <Col className="pr-grid-col" xs={12} lg={8}>
          <Row className="gutter mb-4">
            <Col className="mb-4 mb-md-0" xs={12} md={8}>
              <Widget>
                <div className="d-flex justify-content-between widget-p-md">
                  <div className="headline-3 d-flex align-items-center">Bienvenido al Sistema de Administración<br/>de Personal y Presupuesto</div>
                </div>
              </Widget>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  )
}

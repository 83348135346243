
import React from "react";

import Widget from "../../../../components/Widget/Widget";
import FormPais from "../FormPais";
import { updateCountry } from "../functions";

export const EditarPais = (props) => {
  return (
    <Widget className="widget-p-md">
        <h3>Editar país</h3>
        <FormPais data={localStorage.getItem('dataContent').split('|-|')} editar={updateCountry}/>
    </Widget>
  )
}

export default EditarPais;
import { Button, Col, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import { useEffect, useState } from "react";
import { FilesInput } from "../../../../components/GenericInputsFormik";
import { useFormik } from "formik";
import * as Yup from "yup";
import GetXML, { getFile } from "./ViewXML";
import { isWithinInterval, subMonths } from "date-fns"
import ModalViewFile from "../../../../components/Modal/ModalViewFile";
import { toast } from "react-toastify";
import Notification from "../../../../components/Notification/Notification";
import axios from "axios";
import { peticionesReceiver } from "../../../../helpers/peticionesReceiver";

export default function ModalFactura({ modal, setModal, income, setIncome, refresh, provider }) {
    const API = peticionesReceiver()
    const [id, setId] = useState("")
    const [dataXML, setDataXML] = useState(null);
    const [srcPDF, setSRCPDF] = useState("")
    const [modalXML, setModalXML] = useState(false)
    const [modalPDF, setModalPDF] = useState(false)
    const [xmlValido, setXMLValido] = useState(true)
    const [keyStat, setKeyStat] = useState("")

    const toggle = () => {
        if (modal) {
            setIncome(null)
        }
        setModal(!modal)
    }

    useEffect(() => {
        if (income) {
            setKeyStat(income.keyStat)
            setId(income.idIncome)
        }
    }, [income])


    async function saveBill() {
        AddInvoice()
    }

    const getRFC = (att) => {
        try {
            return att.getNamedItem("Rfc").value
        } catch (err) {
            return att.getNamedItem("rfc").value
        }
    }

    const getTotal = (att) => {
        try {
            return att.getNamedItem("Total").value
        } catch (err) {
            return att.getNamedItem("total").value
        }
    }

    const getFolio = (att) => {
        try {
            return att.getNamedItem("Folio").value
        } catch (err) {
            return att.getNamedItem("folio").value
        }
    }

    const defaultForm = {
        idInvoice: income.idInvoice,
        itin: provider.itin,
        idProvider: provider.idProvider,
        xml: "",
        pdf: "",
        numberInvoice: "",
        taxNumber: "",
        dateInvoice: "",
        amount: ""
    }

    const FormSchema = Yup.object().shape({
        xml: Yup.mixed()
            .test("file-type", "El documento debe ser de tipo XML", (value) => {
                const files = ["text/xml"]
                return value && files.includes(value.type);
            })
            .test({
                message: `El documento debe pesar menos de 4MB`,
                test: (value) => value?.size < 4000000,
            })
            .test({
                message: `Ingrese una factura correspondiente al proveedor`,
                test: () => xmlValido
            }),
        pdf: Yup.mixed()
            .test("file-type", "El documento debe ser de tipo PDF", (value) => {
                const files = ["application/pdf"]
                return value && files.includes(value.type);
            })
            .test({
                message: `El documento debe pesar menos de 4MB`,
                test: (value) => value?.size < 4000000,
            }),
        numberInvoice: Yup.string(),
        taxNumber: Yup.string()
    })

    const { handleSubmit, values, handleBlur, errors, touched, setFieldValue, resetForm } =
        useFormik({
            initialValues: defaultForm,
            onSubmit: (values) => {
                saveBill()
            },
            validationSchema: FormSchema,
            enableReinitialize: true,
        });

    const handleFiles = (event) => {
        try {
            const { files, name } = event.target
            const file = files[0];
            if (name === "xml") {
                setFieldValue([name], file);
                const reader = new FileReader();
                reader.readAsText(file);
                reader.onload = () => {
                    try {
                        setDataXML(reader.result);
                        setModalXML(true)
                        const xmlDoc = new DOMParser().parseFromString(reader.result, "text/xml");
                        const value = xmlDoc.getElementsByTagName("cfdi:Comprobante")[0];
                        const total = getTotal(value.attributes)
                        const childrens = [...value.children]
                        const emisor = childrens.filter(item => item.nodeName === "cfdi:Emisor")[0];
                        const rfc = getRFC(emisor.attributes);
                        const complemento = childrens.filter(item => item.nodeName === "cfdi:Complemento")[0];
                        const fechaCertSAT = complemento.firstChild.attributes.getNamedItem("FechaTimbrado").value
                        const date = new Date()
                        setXMLValido(values.itin === rfc.toUpperCase() && isWithinInterval(new Date(fechaCertSAT), { start: subMonths(date, 1), end: date }))
                        if (values.itin === rfc.toUpperCase()) {
                            const complemento = childrens.filter(item => item.nodeName === "cfdi:Complemento")[0];
                            setFieldValue(["numberInvoice"], complemento.firstChild.attributes.getNamedItem("UUID").value);
                            setFieldValue(["taxNumber"], getFolio(value.attributes));
                            setFieldValue(["dateInvoice"], fechaCertSAT);
                            setFieldValue(["amount"], total);
                        }
                    } catch (err) {
                        setXMLValido(false)
                    }
                };
            } else {
                setFieldValue(name, file);
            }
        } catch (err) {
            setXMLValido(false)
        }
    }

    async function getFilePDF() {
        const src = await getFile(40, id, true);
        setSRCPDF(src)
        setModalPDF(true)
    }

    async function SubirArchivo(formData) {
        const params = {
            method: "post",
            url: `${process.env.REACT_APP_API}app/facades/files/fileReceiver.php`,
            data: formData,
        };
        try {
            const res = await axios(params);
            if (res.data.code === "200" && res.data.status === "success") {
                toast(<Notification type={"agrega_exito"} withIcon />);
                return { status: true, id: res.data.data }
            } else {
                toast(<Notification type={"agrega_error"} withIcon />);
                return { status: false };
            }
        } catch (err) {
            toast(<Notification type={"agrega_error"} withIcon />);
            return { status: false };
        }
    }

    async function AddPDF(rows, ref) {
        const formData = new FormData();
        formData.append("action", "saveFileReplace");
        formData.append("keyFileType", 40);
        formData.append('binary', rows.pdf);
        formData.append('reference', ref);
        const res = await SubirArchivo(formData);
        return res;
    }

    async function AddXML(rows, ref) {
        const formData = new FormData();
        formData.append("action", "saveFileReplace");
        formData.append("keyFileType", 39);
        formData.append('binary', rows.xml);
        formData.append('reference', ref);
        const res = await SubirArchivo(formData);
        return res;
    }

    function getParams() {
        const invoice = {
            idProvider: values.idProvider,
            idIncome: id,
            taxNumber: values.taxNumber,
            numberInvoice: values.numberInvoice,
            dateInvoice: values.dateInvoice,
            amount: values.amount
        }
        if (values.idInvoice) {
            const params = {
                action: "update",
                table: "invoices",
                rows: invoice,
                condition: {
                    idInvoice: values.idInvoice,
                },
                validate: [["idIncome"]]
            }
            return params
        }
        const params = {
            action: "insert",
            table: "invoices",
            rows: invoice,
            validate: [["idIncome"]]
        };
        return params
    }

    async function updateIncome() {
        try {
            const res = await API.peticion({
                action: "update",
                table: "warehouseincomes",
                rows: { keyStat: 141 },
                condition: {
                    idIncome: id
                }
            })
            if (res.status === 200 && res.data.code === "200") {
                toast(<Notification type={"modifica_exito"} backMessage={res.data.message} withIcon />);
                return true;
            } else {
                toast(<Notification type={"modifica_error"} backMessage={res.data.message} withIcon />);
                return false;
            }
        } catch (err) {
            toast(<Notification type={"consultar_servidor_error"} withIcon />);
            return false;
        }
    }

    async function AddInvoice() {

        try {
            const res = await API.peticion(getParams())
            if (res.status === 200 && res.data.code === "200") {
                if (values.idInvoice) {
                    toast(<Notification type={"modifica_exito"} backMessage={res.data.message} withIcon />);
                } else {
                    toast(<Notification type={"agrega_exito"} backMessage={res.data.message} withIcon />);
                }
                await AddPDF(values, res.data.data[0].idInvoice)
                await AddXML(values, res.data.data[0].idInvoice)
                await updateIncome();
            } else {
                if (values.idInvoice) {
                    toast(<Notification type={"agrega_error"} backMessage={res.data.message} withIcon />);
                } else {
                    toast(<Notification type={"modifica_error"} backMessage={res.data.message} withIcon />);
                }
            }
            refresh()
        } catch (err) {
            toast(<Notification type={"consultar_servidor_error"} withIcon />);
        }
        toggle()
    }

    return (
        <>
            <Modal
                isOpen={modal}
                backdrop={"static"}
                toggle={toggle}
                scrollable={true}
                style={{ minWidth: "95%", maxHeight: "90%" }}
            >
                <ModalHeader className="pl-5"></ModalHeader>
                <ModalBody className="mx-2">
                    <h2>Factura</h2>

                    <form onSubmit={handleSubmit}>
                        <Row>
                            <Col xs={12} md={6} className="d-flex">
                                {keyStat !== "143" && (
                                    <FilesInput
                                        label="Factura PDF"
                                        inputName="pdf"
                                        onChangeMethod={handleFiles}
                                        onBlurMethod={handleBlur}
                                        fileAccept=".pdf"
                                        isRequired
                                        touched={touched.pdf}
                                        errors={errors.pdf}
                                    />
                                )}
                                <Button style={{ height: "fit-content", margin: "auto" }} onClick={getFilePDF}>Visualizar PDF</Button>
                            </Col>
                            <Col xs={12} md={6} className="d-flex">
                                {keyStat !== "143" && (
                                    <FilesInput
                                        label="Factura XML"
                                        inputName="xml"
                                        onChangeMethod={handleFiles}
                                        onBlurMethod={handleBlur}
                                        fileAccept=".xml"
                                        isRequired
                                        touched={touched.xml}
                                        errors={errors.xml}
                                    />
                                )}
                                <Button style={{ height: "fit-content", margin: "auto" }} onClick={() => { setModalXML(true) }}>Visualizar XML</Button>
                            </Col>
                        </Row>
                        <div className="text-center mb-3">
                            {keyStat !== "143" ? (
                                <>
                                    <Button color="danger" className="mx-2" onClick={toggle}>
                                        Cancelar
                                    </Button>
                                    <Button color="success" className="mx-2" type="submit">
                                        Guardar
                                    </Button>
                                </>
                            ) : (
                                <Button color="danger" className="mx-2" onClick={toggle}>
                                    Cerrar
                                </Button>
                            )}

                        </div>
                    </form>
                </ModalBody>
            </Modal>
            <GetXML
                modal={modalXML}
                setModal={setModalXML}
                idIncome={id}
                dataXML={dataXML}
            />
            {srcPDF && (
                <ModalViewFile
                    modal={modalPDF}
                    setModal={setModalPDF}
                    backdrop={true}
                    keyboard={true}
                    btnClose={true}
                    fileLink={srcPDF}
                />
            )}

        </>
    )
}
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Button } from "reactstrap";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Widget from "../../../components/Widget/Widget";
import ModalConfirmation from "../../../components/Modal/ModalConfirmation";

const sistemaVacio = {
  keySystem: "",
  nameSystem: "",
};

export const FormSistema = ({ crear, data, editar }) => {
  const history = useHistory();
  const [modal, setModal] = useState(false);
  const [form, setForm] = useState(
    data
      ? {
          keySystem: data[0],
          nameSystem: data[1],
        }
      : sistemaVacio
  );

  const FormSchema = Yup.object().shape({
    nameSystem: Yup.string()
      .min(3, "Nombre debe contener almenos 3 caracteres")
      .max(100, "Nombre debe contener maximo 100 caracteres")
      .required("Ingrese un nombre"),
  });

  const submitFunction = (fields) => {
    setModal(true);
  };

  const cancelOp = () => {
    history.push("/template/sistemas");
  };

  return (
    <div>
      <Widget className="widget-p-md">
        <Formik
          initialValues={form}
          validationSchema={FormSchema}
          onSubmit={(fields) => {
            submitFunction(fields);
          }}
          onReset={(fields, { resetForm }) => {
            cancelOp();
          }}
        >
          {({ errors, status, touched, values }) => (
            <Form className="mt-2">
              <ModalConfirmation
                modalTitle={data ? "Editar" : "Crear"}
                modal={modal}
                setModal={setModal}
                crear={crear}
                editar={editar}
                isEdit={data ? true : false}
                values={values}
              >
                {data ? (
                  <div className="d-flex justify-content-center">
                    <h6>¿Está seguro de editar el registro?</h6>
                  </div>
                ) : (
                  <div className="d-flex justify-content-center">
                    <h6>¿Desea continuar con el registro?</h6>
                  </div>
                )}
              </ModalConfirmation>
              <div className="form-group">
                <label htmlFor="nameSystem">
                  Nombre sistema<span className="text-danger">*</span>
                </label>
                <Field
                  name="nameSystem"
                  type="text"
                  className={
                    "form-control" +
                    (errors.nameSystem && touched.nameSystem
                      ? " is-invalid"
                      : "")
                  }
                />
                <ErrorMessage
                  name="nameSystem"
                  component="div"
                  className="invalid-feedback"
                />
              </div>

              <div className="d-flex flex-md-row flex-column-reverse justify-content-md-around">
                <Button color="danger" type="reset" className="mt-3">
                  Cancelar
                </Button>
                <Button color="success" type="submit" className="mt-2 mt-md-3">
                  Guardar
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </Widget>
    </div>
  );
};
export default FormSistema;

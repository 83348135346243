import React, { useState } from "react";
import TableComponent from "../../../components/TableComponent/TableComponentFiltro";

export const Municipios = () => {
  const cabeceras = [
    "Id",
    "Nombre",
    "Clave INEGI",
    "Estado",
    "Distrito",
    "Editar",
    "Eliminar",
  ];
  const [filter] = useState([true, true, true, true, true, false, false]);
  const [parametros] = useState({
    action: "datatable",
    table:
      "counties A INNER JOIN states B USING (keyState) INNER JOIN districts C USING (keyDistrict)",
    rows: "keyCounty,nameCounty,economicKey,nameState,nameDistrict,keyState,keyDistrict",
    conditions: "A.enabled = 1 AND B.enabled = 1 AND C.enabled = 1",
    page: 0,
    records: 5,
    search: "",
    order: "",
  });

  return (
    <div>
      <TableComponent
        titulo="Municipios"
        cabecerasTabla={cabeceras}
        filtro={filter}
        rutaCrear="/template/municipios/crear"
        rutaEditar="/template/municipios/editar"
        parametros={parametros}
      />
    </div>
  );
};
export default Municipios;

import Widget from "../../../../components/Widget/Widget";
import FormCarreras from "../FormCarreras"
import {
  Col,    
} from "reactstrap";
import { agregar } from "../functions";

const CrearCarrera = (props) => {
  return (
    <div>
      <Widget className="widget-p-md">
      <Col className="mb-12"><h1 className=" text-center">Crear Carrera</h1></Col>
      <FormCarreras crear={agregar}/>
    </Widget>
    </div>
  )
}

export default CrearCarrera
import { Formik, Form, Field, ErrorMessage, useFormikContext } from "formik";
import * as Yup from "yup";
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Col, Row, Button } from "reactstrap";
import { toast } from "react-toastify";
import Notification from "../../../components/Notification/Notification";
import Loader from "../../../components/Loader/Loader";
import ModalConfirmation from "../../../components/Modal/ModalConfirmation";
import { peticionesReceiver } from "../../../helpers/peticionesReceiver";
import { SelectTypeHeadSingle } from "../../../components/GenericInputsFormik/SelectTypeHeadSingle";

const defaultForm = {
  keyState: "0",
  keyCountry: "",
  nameState: "",
  claveSAT: "",
};

const FormPais = ({ crear, data, editar }) => {
  const API = peticionesReceiver();
  const [paises, setPaises] = useState([]);
  const [form, setForm] = useState(defaultForm);
  const [loading, setLoading] = useState(true);
  const [modal, setModal] = useState(false);
  const history = useHistory();

  const FormSchema = Yup.object().shape({
    nameState: Yup.string()
      .min(3, "Nombre debe tener al menos 3 letras")
      .max(200, "Nombre demasiado largo")
      .required("Ingrese un nombre"),
    keyCountry: Yup.string().required("Seleccione un país"),
    claveSAT: Yup.string().required("Ingrese la clave del SAT").length(2, "La clave deve tener 2 caracteres"),
  });

  const getCountries = async () => {
    const params = {
      action: "select",
      table: "countries",
      condition: {
        enabled: 1,
      },
      order: "nameCountry ASC",
    };
    API.peticion(params)
      .then((res) => {
        if (res.status === 200 && res.data.code === "200") {
          /* let data = [];
          res.data.data.map((el) => {
            data.push([el.keyCountry, el.nameCountry, el.acronim, el.phoneKey]);
          }); */
          setPaises(res.data.data);
          setLoading(false);
        } else {
          toast(<Notification type={"consultar_error"} withIcon />);
          setLoading(false);
        }
      })
      .catch((err) => {
        toast(<Notification type={"consultar_servidor_error"} withIcon />);
        setLoading(false);
      });
  };

  useEffect(() => {
    getCountries();
  }, []);

  const submitFunction = (fields) => {
    setModal(true);
  };

  const cancelOp = () => {
    history.push("/template/estados");
  };

  const findId = (name) => {
    let id = "";
    for (const el of paises) {
      if (el[1] === name) {
        id = el[0];
        break;
      }
    }
    return id;
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <Formik
          initialValues={form}
          validationSchema={FormSchema}
          onSubmit={(fields, { resetForm }) => {
            submitFunction(fields);
          }}
          onReset={(fields, { resetForm }) => {
            cancelOp();
          }}
        >
          {({ errors, status, touched, values, handleBlur, setFieldValue }) => (
            <Form className="mt-4">
              <ModalConfirmation
                modalTitle={data ? "Editar" : "Crear"}
                modal={modal}
                setModal={setModal}
                crear={crear}
                editar={editar}
                isEdit={data ? true : false}
                values={values}
              >
                {data ? (
                  <div className="d-flex justify-content-center">
                    <h6>¿Está seguro de editar el registro?</h6>
                  </div>
                ) : (
                  <div className="d-flex justify-content-center">
                    <h6>¿Desea continuar con el registro?</h6>
                  </div>
                )}
              </ModalConfirmation>
              <Row>
                <Col xs="12" md="4">
                  <div className="form-group">
                    <label htmlFor="nameState">
                      Nombre<span className="text-danger">*</span>
                    </label>
                    <Field
                      name="nameState"
                      type="text"
                      className={
                        "form-control" +
                        (errors.nameState && touched.nameState
                          ? " is-invalid"
                          : "")
                      }
                    />
                    <ErrorMessage
                      name="nameState"
                      component="div"
                      className="invalid-feedback"
                    />
                  </div>
                </Col>
                <Col xs="12" md="4">
                  <SelectTypeHeadSingle
                    label="Pais"
                    inputName="keyCountry"
                    onChangeMethod={(e) =>
                      setFieldValue([e.target.name], e.target.value)
                    }
                    onBlurMethod={handleBlur}
                    value={values.keyCountry}
                    isRequired={true}
                    touched={touched.keyCountry}
                    errors={errors.keyCountry}
                    optionsArray={paises}
                    optionValue="keyCountry"
                    optionName="nameCountry"
                    defaultOption="Seleccione un pais"
                  />
                </Col>
                <Col xs="12" md="4">
                  <div className="form-group">
                    <label htmlFor="nameState">
                      Clave SAT<span className="text-danger">*</span>
                    </label>
                    <Field
                      name="claveSAT"
                      type="text"
                      className={
                        "form-control" +
                        (errors.claveSAT && touched.claveSAT
                          ? " is-invalid"
                          : "")
                      }
                    />
                    <ErrorMessage
                      name="claveSAT"
                      component="div"
                      className="invalid-feedback"
                    />
                  </div>
                </Col>
              </Row>
              <div className=" d-flex flex-sm-row flex-column-reverse justify-content-sm-around mt-1">
                <Button color="danger" type="reset" className="mt-3">
                  Cancelar
                </Button>
                <Button color="success" type="submit" className="mt-2 mt-sm-3">
                  Guardar
                </Button>
              </div>
              <FormikData info={data} values={values} />
            </Form>
          )}
        </Formik>
      )}
    </>
  );
};

const FormikData = ({ info, values, onChangeSelect }) => {
  const { setFieldValue } = useFormikContext();
  useEffect(() => {
    if (info) {
      setFieldValue("keyState", info[0]);
      setFieldValue("nameState", info[1]);
      setFieldValue("keyCountry", info[4]);
      setFieldValue("claveSAT", info[2]);
    }
  }, [info]);

  return null;
};

export default FormPais;

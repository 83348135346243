import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import htmlToPdfmake from "html-to-pdfmake";
pdfMake.vfs = pdfFonts.pdfMake.vfs;


export async function createPDF(data, setPDF) {
    const origin = window.location.origin;

    const gobEdomex = `${origin}/images/pdf/gobEDOMEX.png`

    const logoFGJ = `${origin}/images/logo.png`

    data.textFormat = data.textFormat.replace(/<([A-Z]+)([^>]*)>/g, function (match, p1, p2) {
        return '<' + p1.toLowerCase() + (p2 ? p2.toLowerCase() : '') + '>';
    }).replace(/<img\s+src=['"]\/\/:0['"]\s+height=['"]\d+['"]\s+width=['"]\d+['"]>/g, "");

    // data.textFormat = `<table><tr><td style="width:100px;border:none;"><img src=${gobEdomex} style="width:100px;height:100px;"/></td>` +
    //     `<td style=";border:none;width:440px;text-align: center; font-size: 14pt; font-weight: bold;">Sistema de administración<br/>de personal y presupuestos</td>` +
    //     `<td style=";border:none;width:100px;"><img src=${logoFGJ} style="width:100px;height:100px;"/></td></tr></table>` +
    //     data.textFormat
    
    const ret = htmlToPdfmake(data.textFormat, {
        imagesByReference: true,
        tableAutoSize: true,
        defaultStyles: {
            b: { bold: true },
            strong: { bold: true },
            u: { decoration: 'underline' },
            s: { decoration: 'lineThrough' },
            em: { italics: true },
            i: { italics: true },
            h1: { fontSize: 23, bold: true, marginBottom: 5 },
            h2: { fontSize: 17, bold: true, marginBottom: 5 },
            h3: { fontSize: 12, bold: true, marginBottom: 5 },
            h4: { fontSize: 10, bold: true, marginBottom: 5 },
            h5: { fontSize: 8, bold: true, marginBottom: 5 },
            h6: { fontSize: 6, bold: true, marginBottom: 5 },
            a: { color: 'blue', decoration: 'underline' },
            strike: { decoration: 'lineThrough' },
            p: { fontSize: 10, margin: [0, 1, 0, 1] },
            ul: { marginBottom: 5 },
            li: { marginLeft: 5 },
            table: { marginBottom: 5 },
            th: { bold: true, fillColor: '#EEEEEE' }
        }
    });

    pdfMake.createPdf({
        content: ret.content,
        images: ret.images,
        styles: {
            "ql-align-center": {
                alignment: "center"
            },
            "ql-align-justify": {
                alignment: "justify"
            },
            "ql-align-right": {
                alignment: "right"
            }
        }
    }).getDataUrl(function (outDoc) {
        setPDF(outDoc)
    })

}
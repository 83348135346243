/* eslint-disable react-hooks/exhaustive-deps */
import { Modal, ModalHeader, ModalBody, Button, Row, Col } from "reactstrap";
import { useEffect, useState } from "react";
import Tesseract from "tesseract.js";
import { useFormik } from "formik";
import { pdfjs } from "react-pdf";
import { PDFDocument } from "pdf-lib";
import * as Yup from "yup";

import { FilesInput, TextInput } from "../../../components/GenericInputsFormik";
import FullScreenLoader from "../../../components/Loader/FullScreenLoader";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

/**
 *
 * @param {Object} props
 * @param {Boolean} props.visible Muestra el modal
 * @param {Dispatch<Object>} props.setVisible Setter de la visualizacion
 * @param {Function} props.getData Pasa la información obtenida de tesseract
 * @param {Function} props.formVals Valores del form inicial de PersonalCuentas
 * @returns
 */
export default function ModalTesseract({
  visible,
  setVisible,
  getData,
  formVals,
}) {
  const toggle = () => setVisible(!visible);
  const [src, setSRC] = useState(null);
  const [loading, setLoading] = useState(false);

  const defaultForm = {
    nameFile: "",
    file: "",
    banco: "",
    noCuenta: "",
    clabe: "",
  };

  /**
   * Reinicia Form
   * Reinicia imagen
   */
  useEffect(() => {
    if (visible) {
      resetForm();
      setSRC(null);
    }
  }, [visible]);

  const FormSchema = Yup.object().shape({
    file: Yup.mixed()
      .test("file-type", "El documento debe ser de tipo pdf", (value) => {
        const files = ["application/pdf"];
        return value && files.includes(value.type);
      })
      .test({
        message: `El documento debe pesar menos de 4MB`,
        test: (value) => value?.size < 4000000,
      }),
    noCuenta: Yup.string().required("Ingrese un numero de cuenta valido"),
    clabe: Yup.string().required("Ingrese una CLABE valida"),
  });

  /**
   *
   * @param {Image} image Imagen a binarizar
   * @param {number} threshold Umbral de binarización
   */
  const threshold = (image, threshold = 166) => {
    let ctx = document.createElement("canvas").getContext("2d"); //Obtiene contexto
    ctx.canvas.style.display = "none"; //Cambia estilo para no visualizarse
    let w = (ctx.canvas.width = image.width), //Establece medidas al canvas
      h = (ctx.canvas.height = image.height);
    ctx.drawImage(image, 0, 0, w, h); //Pinta imagen en el canvas
    let d = ctx.getImageData(0, 0, w, h); //Obtiene data (Matriz) del canvas
    for (let i = 0; i < d.data.length; i += 4) {
      d.data[i] =
        d.data[i + 1] =
        d.data[i + 2] =
          d.data[i + 1] > threshold ? 255 : 0;
    }
    ctx.putImageData(d, 0, 0);
    document.body.appendChild(ctx.canvas);
    ctx.canvas.toBlob((blob) => {
      Tesseract.recognize(blob, "spa").then(({ data: { text } }) => {
        getInformacion(text);
        setLoading(false);
        document.body.removeChild(ctx.canvas);
      });
    });
  };

  /**
   * Funcion que obtiene el numero de cuenta del documento
   * @param {number} caso caso a aplicar para obtener numero de cuenta
   * @param {string} text texto recibido de tesseract
   */
  function getNoCuenta(caso, text) {
    let indexNoCuenta = -1;
    switch (caso) {
      case 0:
        indexNoCuenta = text.indexOf("No. de Cuenta ");
        if (indexNoCuenta !== -1) {
          indexNoCuenta += 14;
          const sub = text.substring(indexNoCuenta);
          const index = sub.indexOf(String.fromCharCode(10));
          return sub
            .substring(0, index)
            .replace(/ /g, "")
            .replace(/[^0-9]/g, "");
        } else {
          return "";
        }
      case 1:
        indexNoCuenta = text.indexOf("SUPER NOMINA ");
        if (indexNoCuenta !== -1) {
          indexNoCuenta += 13;
          const sub = text.substring(indexNoCuenta);
          const index = sub.indexOf(String.fromCharCode(32));
          return sub
            .substring(0, index)
            .replace(/ /g, "")
            .replace(/[^0-9]/g, "");
        } else {
          return "";
        }
      case 2:
        if (text.includes("NOMINA EJECUTIVA")) {
          const indexNoAc = text.indexOf("NÚMERO DE CUENTA CLABE");
          const indexNoCliente = text.indexOf("NÚMERO DE CLIENTE");
          if (indexNoCliente !== -1 && indexNoCliente !== -1) {
            let ssHSBC = text.substring(indexNoAc, indexNoCliente);
            ssHSBC = ssHSBC.substring(
              ssHSBC.indexOf(String.fromCharCode(10)) + 1
            );
            indexNoCuenta = ssHSBC.indexOf(String.fromCharCode(32));
            if (indexNoCuenta !== -1) {
              return ssHSBC
                .substring(0, indexNoCuenta)
                .replace(/ /g, "")
                .replace(/[^0-9]/g, "");
            }
          }
        } else {
          indexNoCuenta = text.search(/\b\d{4}\s\d{4}\s\d{4}\s\d{4}\b/g);
          if (indexNoCuenta !== -1) {
            return text
              .substring(indexNoCuenta, indexNoCuenta + 19)
              .replace(/ /g, "")
              .replace(/[^0-9]/g, "");
          }
        }
        return "";
      case 3:
        indexNoCuenta = text.indexOf("No. Cuenta: ");
        if (indexNoCuenta !== -1) {
          indexNoCuenta += 12;
          const sub = text.substring(indexNoCuenta);
          const index = sub.indexOf(String.fromCharCode(10));
          return sub
            .substring(0, index)
            .replace(/ /g, "")
            .replace(/[^0-9]/g, "");
        } else {
          return "";
        }
      case 4:
        indexNoCuenta = text.indexOf("CUENTA CONECTA BANBAJIO ");
        if (indexNoCuenta !== -1) {
          indexNoCuenta += 24;
          const sub = text.substring(indexNoCuenta);
          const index = sub.indexOf(String.fromCharCode(32));
          return sub
            .substring(0, index)
            .replace(/ /g, "")
            .replace(/[^0-9]/g, "");
        } else {
          return "";
        }
      case 5:
        indexNoCuenta = text.indexOf("Numero de cuenta de cheques");
        if (indexNoCuenta !== -1) {
          indexNoCuenta += 28;
          const sub = text.substring(indexNoCuenta);
          const index = sub.indexOf(String.fromCharCode(32));
          return sub
            .substring(0, index)
            .replace(/ /g, "")
            .replace(/[^0-9]/g, "");
        } else {
          indexNoCuenta = text.indexOf("MiCuenta ");
          if (indexNoCuenta !== -1) {
            indexNoCuenta += 9;
            const sub = text.substring(indexNoCuenta);
            const index = sub.indexOf(String.fromCharCode(32));
            return sub
              .substring(0, index)
              .replace(/ /g, "")
              .replace(/[^0-9]/g, "");
          }
        }
        return "";
      case 6:
        indexNoCuenta = text.indexOf("Numero de cuenta: ");
        if (indexNoCuenta !== -1) {
          indexNoCuenta += 18;
          const sub = text.substring(indexNoCuenta);
          const index = sub.indexOf(String.fromCharCode(10));
          return sub
            .substring(0, index)
            .replace(/ /g, "")
            .replace(/[^0-9]/g, "");
        } else {
          return "";
        }
      case 7:
        indexNoCuenta = text.indexOf("No. Cuenta..: ");
        if (indexNoCuenta !== -1) {
          indexNoCuenta += 14;
          const sub = text.substring(indexNoCuenta);
          const index = sub.indexOf(String.fromCharCode(32));
          return sub
            .substring(0, index)
            .replace(/ /g, "")
            .replace(/[^0-9]/g, "");
        } else {
          return "";
        }
      case 8:
        indexNoCuenta = text.indexOf("GAT nominal");
        if (indexNoCuenta !== -1) {
          let tempText = text.substring(indexNoCuenta);
          tempText = tempText.substring(0, tempText.indexOf("NA"));
          tempText = tempText.substring(
            tempText.indexOf(String.fromCharCode(10)) + 1
          );
          indexNoCuenta = 0;
          const sub = tempText.substring(indexNoCuenta);
          const index = sub.indexOf(String.fromCharCode(32));
          return sub
            .substring(0, index)
            .replace(/ /g, "")
            .replace(/[^0-9]/g, "");
        } else {
          return "";
        }
      case 9:
        let tempSco = text.substring(text.indexOf("Cuenta") + 7);
        indexNoCuenta = tempSco.indexOf("Cuenta");
        if (indexNoCuenta !== -1) {
          indexNoCuenta += 6;
          tempSco = tempSco.substring(indexNoCuenta);
          return tempSco
            .substring(0, tempSco.indexOf(String.fromCharCode(10)) + 1)
            .replace(/ /g, "")
            .replace(/[^0-9]/g, "");
        } else {
          return "";
        }
      default:
        return "";
    }
  }

  /**
   * Funcion que obtiene la clabe del documento
   * @param {number} caso caso a aplicar para obtener numero de cuenta
   * @param {string} text texto recibido de tesseract
   */
  function getClabe(caso, text) {
    let indexNoCuenta = -1;
    switch (caso) {
      case 0:
        indexNoCuenta = text.indexOf("CLABE ");
        if (indexNoCuenta !== -1) {
          indexNoCuenta += 6;
          const sub = text.substring(indexNoCuenta);
          const index = sub.indexOf(String.fromCharCode(10));
          return sub
            .substring(0, index)
            .replace(/ /g, "")
            .replace(/[^0-9]/g, "");
        } else {
          return "";
        }
      case 1:
        indexNoCuenta = text.indexOf("CLABE:");
        if (indexNoCuenta !== -1) {
          indexNoCuenta += 6;
          const sub = text.substring(indexNoCuenta);
          const index = sub.indexOf(String.fromCharCode(10));
          return sub
            .substring(0, index)
            .replace(/ /g, "")
            .replace(/[^0-9]/g, "");
        } else {
          return "";
        }
      case 2:
        if (text.includes("NOMINA EJECUTIVA")) {
          const indexNoAc = text.indexOf("NUMERO DE CUENTA CLABE");
          const indexNoCliente = text.indexOf("NUMERO DE CLIENTE");
          if (indexNoCliente !== -1 && indexNoCliente !== -1) {
            let ssHSBC = text.substring(indexNoAc, indexNoCliente);
            ssHSBC = ssHSBC.substring(
              ssHSBC.indexOf(String.fromCharCode(10)) + 1
            );
            indexNoCuenta = ssHSBC.indexOf(String.fromCharCode(32));
            if (indexNoCuenta !== -1) {
              indexNoCuenta++;
              const sub = ssHSBC.substring(indexNoCuenta);
              const index = sub.indexOf(String.fromCharCode(32));
              return sub
                .substring(0, index)
                .replace(/ /g, "")
                .replace(/[^0-9]/g, "");
            }
          }
        } else {
          const ssHSBC = text.substring(text.indexOf("NUMERO DE CUENTA CLABE"));
          indexNoCuenta = ssHSBC.indexOf(String.fromCharCode(10));
          if (indexNoCuenta !== -1) {
            indexNoCuenta++;
            const sub = ssHSBC.substring(indexNoCuenta);
            const index = sub.indexOf(String.fromCharCode(32));
            return sub
              .substring(0, index)
              .replace(/ /g, "")
              .replace(/[^0-9]/g, "");
          }
        }
        return "";
      case 3:
        indexNoCuenta = text.indexOf("CLABE: ");
        if (indexNoCuenta !== -1) {
          indexNoCuenta += 7;
          const sub = text.substring(indexNoCuenta);
          const index = sub.indexOf(String.fromCharCode(10));
          return sub
            .substring(0, index)
            .replace(/ /g, "")
            .replace(/[^0-9]/g, "");
        } else {
          return "";
        }
      case 4:
        indexNoCuenta = text.indexOf("CLABE INTERBANCARIA");
        if (indexNoCuenta !== -1) {
          indexNoCuenta += 22;
          const sub = text.substring(indexNoCuenta);
          const index = sub.indexOf(String.fromCharCode(32));
          return sub
            .substring(0, index)
            .replace(/ /g, "")
            .replace(/[^0-9]/g, "");
        } else {
          return "";
        }
      case 5:
        indexNoCuenta = text.indexOf("Interbancaria");
        if (indexNoCuenta !== -1) {
          indexNoCuenta += 14;
          const sub = text.substring(indexNoCuenta);
          const index = sub.indexOf(String.fromCharCode(10));
          return sub
            .substring(0, index)
            .replace(/ /g, "")
            .replace(/[^0-9]/g, "");
        } else {
          return "";
        }
      case 6:
        indexNoCuenta = text.indexOf("CLABE");
        if (indexNoCuenta !== -1) {
          indexNoCuenta += 6;
          const sub = text.substring(indexNoCuenta);
          const index = sub.indexOf(String.fromCharCode(10));
          return sub
            .substring(0, index)
            .replace(/ /g, "")
            .replace(/[^0-9]/g, "");
        } else {
          return "";
        }
      case 7:
        indexNoCuenta = text.indexOf("CLABE.......: ");
        if (indexNoCuenta !== -1) {
          indexNoCuenta += 14;
          const sub = text.substring(indexNoCuenta);
          const index = sub.indexOf(String.fromCharCode(32));
          return sub
            .substring(0, index)
            .replace(/ /g, "")
            .replace(/[^0-9]/g, "");
        } else {
          return "";
        }
      case 8:
        indexNoCuenta = text.indexOf("GAT nominal");
        if (indexNoCuenta !== -1) {
          let tempText = text.substring(indexNoCuenta);
          tempText = tempText.substring(0, tempText.indexOf("NA"));
          tempText = tempText.substring(
            tempText.indexOf(String.fromCharCode(10)) + 1
          );
          const resultado = tempText.match(/\b(\d{3} \d{3} \d{11} \d)\b/g)[0];
          return resultado.replace(/ /g, "");
        } else {
          return "";
        }
      case 9:
        indexNoCuenta = text.indexOf("TU NUMERO DE CLABE:");
        if (indexNoCuenta !== -1) {
          return text
            .substring(indexNoCuenta)
            .replace(/ /g, "")
            .replace(/[^0-9]/g, "");
        } else {
          return "";
        }
      default:
        return "";
    }
  }

  /**
   * Funcion que obtiene la información del estado de cuenta
   * @param {string} text texto recibido del tesseract
   */
  function getInformacion(text = "") {
    text = text.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    if (text.includes("GRUPO FINANCIERO BBVA")) {
      setFieldValue("banco", "BBVA BANCOMER");
      const noCuenta = getNoCuenta(0, text);
      const CLABE = getClabe(0, text);
      setFieldValue("noCuenta", noCuenta);
      setFieldValue("clabe", CLABE);
    } else if (text.includes("Grupo Financiero Santander")) {
      setFieldValue("banco", "SANTANDER");
      const noCuenta = getNoCuenta(1, text);
      const CLABE = getClabe(1, text);
      setFieldValue("noCuenta", noCuenta);
      setFieldValue("clabe", CLABE);
    } else if (text.includes("Grupo Financiero HSBC")) {
      setFieldValue("banco", "HSBC");
      const noCuenta = getNoCuenta(2, text);
      const CLABE = getClabe(2, text);
      setFieldValue("noCuenta", noCuenta);
      setFieldValue("clabe", CLABE);
    } else if (text.includes("Banco Azteca")) {
      setFieldValue("banco", "AZTECA");
      const noCuenta = getNoCuenta(3, text);
      const CLABE = getClabe(3, text);
      setFieldValue("noCuenta", noCuenta);
      setFieldValue("clabe", CLABE);
    } else if (text.includes("BANCO DEL BAJIO")) {
      setFieldValue("banco", "BANSEFI");
      const noCuenta = getNoCuenta(4, text);
      const CLABE = getClabe(4, text);
      setFieldValue("noCuenta", noCuenta);
      setFieldValue("clabe", CLABE);
    } else if (text.includes("BANAMEX") || text.includes("banamex")) {
      setFieldValue("banco", "BANAMEX");
      if (text.includes("MiCuenta")) {
        const noCuenta = getNoCuenta(5, text);
        const CLABE = getClabe(5, text);
        setFieldValue("noCuenta", noCuenta);
        setFieldValue("clabe", CLABE);
      }
    } else if (text.includes("BanCoppel")) {
      setFieldValue("banco", "BANCOPPEL");
      const noCuenta = getNoCuenta(6, text);
      const CLABE = getClabe(6, text);
      setFieldValue("noCuenta", noCuenta);
      setFieldValue("clabe", CLABE);
    } else if (text.includes("Banjercito")) {
      setFieldValue("banco", "BANJERCITO");
      const noCuenta = getNoCuenta(7, text);
      const CLABE = getClabe(7, text);
      setFieldValue("noCuenta", noCuenta);
      setFieldValue("clabe", CLABE);
    } else if (text.includes("Grupo Financiero Banorte")) {
      setFieldValue("banco", "BANORTE");
      const noCuenta = getNoCuenta(8, text);
      const CLABE = getClabe(8, text);
      setFieldValue("noCuenta", noCuenta);
      setFieldValue("clabe", CLABE);
    } else if (text.includes("Scotiabank")) {
      setFieldValue("banco", "SCOTIABANK");
      const noCuenta = getNoCuenta(9, text);
      const CLABE = getClabe(9, text);
      setFieldValue("noCuenta", noCuenta);
      setFieldValue("clabe", CLABE);
    } else {
      setFieldValue("noCuenta", "");
      setFieldValue("clabe", "");
    }
  }

  /**
   * Pasa la información a PersonalCuentas
   */
  const {
    handleSubmit,
    values,
    handleBlur,
    errors,
    touched,
    setFieldValue,
    resetForm,
  } = useFormik({
    initialValues: defaultForm,
    onSubmit: (rows) => {
      getData(rows, formVals);
    },
    validationSchema: FormSchema,
  });

  async function getFirstPageAsFile(file) {
    const pdfDoc = await PDFDocument.load(file);
    const newPdfDoc = await PDFDocument.create();
    const [firstPage] = await newPdfDoc.copyPages(pdfDoc, [0]);
    newPdfDoc.addPage(firstPage);
    const newPdfBytes = await newPdfDoc.save();
    const newPdfBlob = new Blob([newPdfBytes], { type: "application/pdf" });
    const fileOut = new File([newPdfBlob], "edo_cuenta.pdf", {
      type: "application/pdf",
    });
    setFieldValue("file", fileOut);
  }

  /**
   * Función que transforma de pdf a imagen y pasa el resultado a tesseract
   * @param {Object} event Evento de cambio del inputfile
   */
  const handleFiles = (event) => {
    const { files } = event.target;
    const filesIn = ["application/pdf"];
    setLoading(true);
    if (filesIn.includes(files[0].type)) {
      //Si pasa la comprobación del tipo de archivo
      const reader = new FileReader(); //Instanciar la lectura del archivo
      reader.onload = () => {
        //En cuanto cargue el archivo
        const typedarray = new Uint8Array(reader.result); //Pasar el blob a un arreglo de int8
        const loadingTask = pdfjs.getDocument(typedarray); //Leer el archivo

        loadingTask.promise.then((pdf) => {
          //Promesa de la lectura

          console.log("PDFGENERATED ", pdf);
          if (pdf.numPages > 0) {
            //Si tiene mas de 0 paginas
            getFirstPageAsFile(typedarray);
            pdf.getPage(1).then((page) => {
              //Obtener la primera hoja
              var scale = 2; //Establecer la escala del viewport
              var viewport = page.getViewport({ scale: scale });
              var outputScale = window.devicePixelRatio || 1; //Obtiene la escala de salida
              let canvas = document.createElement("canvas"); //Crea el canvas
              document.body.appendChild(canvas); //Agrega el canvas al body
              canvas.style.display = "none"; //cambia el estilo a novisible
              canvas.width = Math.floor(viewport.width * outputScale); //Establece las mediddas del canvas
              canvas.height = Math.floor(viewport.height * outputScale);
              canvas.style.width = Math.floor(viewport.width) + "px";
              canvas.style.height = Math.floor(viewport.height) + "px";
              let context = canvas.getContext("2d"); //Se obtiene el contexto

              var transform =
                outputScale !== 1 //Establece el transform (Escala de salida)
                  ? [outputScale, 0, 0, outputScale, 0, 0]
                  : null;

              var renderContext = {
                //Establece el contexto de renderizado
                canvasContext: context,
                transform: transform,
                viewport: viewport,
              };
              var task = page.render(renderContext); //Renderiza la pagina
              task.promise.then(() => {
                //Promesa del renderizado
                const img = new Image(); //Instancia una imagen
                img.onload = function () {
                  //En cuanto cargue la imagen
                  threshold(img); //Pasa a funcion de binarización
                };
                const data = canvas.toDataURL("image/png"); // obtiene la informacion del canvas en formato png
                img.src = data; //Establece el src en la imagen instanciada
                setSRC(data); //Establece el hook para la previsualización
                document.body.removeChild(canvas); //Elimina del body al canvas
              });
            });
          }
        });
      };
      reader.readAsArrayBuffer(files[0]); //Lee el archivo como buffer
    }
  };

  const onChange = (e) => {
    setFieldValue([e.target.name], e.target.value);
  };

  return (
    <>
      <Modal
        isOpen={visible}
        toggle={toggle}
        size="xl"
        style={{ minWidth: "80%" }}
        centered
      >
        <ModalHeader toggle={toggle}>Autorrellenar datos bancarios</ModalHeader>
        <ModalBody>
          <form onSubmit={handleSubmit}>
            <FilesInput
              label="Adjuntar pdf de estado de cuenta"
              inputName="file"
              fileAccept=".pdf"
              onChangeMethod={handleFiles}
              onBlurMethod={handleBlur}
              value={values.file}
              errors={errors.file}
              touched={touched.file}
            />

            {src !== null && (
              <Row>
                <Col xs={12} md={8}>
                  <img src={src} alt="files" style={{ width: "100%" }} />
                </Col>
                <Col xs={12} md={4}>
                  <TextInput
                    label="Banco"
                    value={values.banco}
                    touched={touched.banco}
                    errors={errors.banco}
                    inputName="banco"
                    isRequired
                    onBlurMethod={handleBlur}
                    onChangeMethod={onChange}
                  />
                  <TextInput
                    label="No. Cuenta"
                    value={values.noCuenta}
                    touched={touched.noCuenta}
                    errors={errors.noCuenta}
                    inputName="noCuenta"
                    isRequired
                    onBlurMethod={handleBlur}
                    onChangeMethod={onChange}
                  />
                  <TextInput
                    label="CLABE"
                    value={values.clabe}
                    touched={touched.clabe}
                    errors={errors.clabe}
                    inputName="clabe"
                    isRequired
                    onBlurMethod={handleBlur}
                    onChangeMethod={onChange}
                  />
                  <div className="text-center mt-3">
                    <Button type="submit">Rellenar</Button>
                  </div>
                </Col>
              </Row>
            )}
          </form>
          <div className="text-center mt-3">
            <Button color="danger" onClick={toggle}>
              Cerrar
            </Button>
          </div>
        </ModalBody>
      </Modal>
      <FullScreenLoader show={loading} message={"Obteniendo información..."} />
    </>
  );
}

import Widget from "../../../../components/Widget/Widget";
import FormModulos from "../FormModulos"
import { editar } from "../functions";

const EditarModulo = (props) => {
    return (
    <div>
      <Widget className="widget-p-md">
        <h3>Editar Módulo</h3>
        <FormModulos data={localStorage.getItem('dataContent').split('|-|')} editar={editar}/>
    </Widget>
    </div>
  )
}

export default EditarModulo
import GruposNominaPrincipal from "../../../pages/nomina/grupos-nomina/GruposNominaPrincipal";
// EXPEDIENTE NOMINA //
import ExpedienteNomina from "../../../pages/nomina/expediente-nomina/ExpedienteNomina";

// -- SALARIOS -- //
import Salarios from "../../../pages/nomina/salarios/Salarios";
import CrearSalario from "../../../pages/nomina/salarios/crear/CrearSalario";
import EditarSalario from "../../../pages/nomina/salarios/editar/EditarSalario";

// -- INCREMENTO DE SALARIOS -- //
import IncrementoSalarial from "../../../pages/nomina/incremento-salarial/Principal";
import AgregarIncrementoSalarial from "../../../pages/nomina/incremento-salarial/Crear";

// -- PUESTOS LABORALES  -- //
import PuestosLaborales from "../../../pages/nomina/puestos-laborales/PuestosLaborales";
import CrearPuestoLaboral from "../../../pages/nomina/puestos-laborales/crear/CrearPuestoLaboral";
import EditarPuestoLaboral from "../../../pages/nomina/puestos-laborales/editar/EditarPuestoLaboral";

// -- PLAZAS LABORALES  -- //
import PlazasLaborales from "../../../pages/nomina/plazas-laborales/PlazasLaborales";
import CrearPlazaLaboral from "../../../pages/nomina/plazas-laborales/crear/CrearPlazaLaboral";
import EditarPlazaLaboral from "../../../pages/nomina/plazas-laborales/editar/EditarPlazaLaboral";

// -- CARGA LAYOUTS -- //
import CargaLayouts from "../../../pages/nomina/carga-layouts/CargaLayouts";

// -- CALCULO  -- //
import Calculo from "../../../pages/nomina/calculo/crear/CrearCalculo";

// -- EDITAR PAGO  -- //
import EditarPago from "../../../pages/nomina/editar-pago/EditarPago";

// -- SOLICITUD DE PAGO  -- //
import SolicitudPago from "../../../pages/nomina/solicitud-pago/SolicitudPago";
import CrearSolicitudPago from "../../../pages/nomina/solicitud-pago/crear/CrearSolicitudPago";
import EditarSolicitudPago from "../../../pages/nomina/solicitud-pago/editar/EditarSolicitudPago";

// -- Calculo FOREMEX  -- //
import CalculoForemex from "../../../pages/nomina/foremex/calculo-foremex/CalculoForemex";

// -- Firma de Archivos  -- //
import FirmaArchivos from "../../../pages/nomina/firma-archivos/FirmaArchivos";

// -- Reportes Personal -- //
import ReportesNomina from "../../../pages/nomina/reportes/ReportesNomina";

// -- Archivos de Dispersion  -- //
import ArchivosDispersion from "../../../pages/nomina/archivos-dispersion/Dispersion";
import CrearArchivosDispersion from "../../../pages/nomina/archivos-dispersion/crear/CrearDispersion";

// -- Archivos de Dispersion  -- //
import ComprobantesPago from "../../../pages/nomina/comprobantes-pago/ComprobantesPago";

// -- Modificar pago timbrado  -- //
import ModificarPagoTimbrado from "../../../pages/nomina/timbrados/ModifcarPagoTimbrado";
// -- Layout para timbrar -- //
import LayoutParaTimbrar from "../../../pages/nomina/timbrados/layout-timbrar/Principal";

/* -- Registro de Proveedores -- */
import ConsultaEmpelados from "../../../pages/nomina/foremex/consulta-empleados/ConsultaEmpleados";
import GuardarForemex from "../../../pages/nomina/foremex/guardar-foremex/Principal";

import VerificarSaldosNomina from "../../../pages/nomina/verificarSaldos-nomina/VerificarSaldosNomina";

import CalendarioMovimientos from "../../../pages/nomina/calendario-movimientos/CalendarioMovimientos";

import Cct from "../../../pages/nomina/cct/Cct";

/* -- AREAS DICTAMINADORAS -- */
import AreasDictaminadoras from "../../../pages/nomina/finiquitos/areas-dictaminadoras/Index";
/* -- CARGA DE CONSTANCIAS -- */
import CargaConstancias from "../../../pages/nomina/finiquitos/carga-constancias/Index";
/* -- REGISTRO DE FINIQUITOS -- */
import RegistroFiniquito from "../../../pages/nomina/finiquitos/registro-finiquitos/Index";

const rutasNomina = [
  //Archivos de dispersion
  { path: "/template/dispersion", component: ArchivosDispersion },
  { path: "/template/dispersion/crear", component: CrearArchivosDispersion },
  //ASIGNACION DE BIENES
  {
    path: "/template/foremex-consulta-empleados",
    component: ConsultaEmpelados,
  },
  //Calculo
  { path: "/template/calculo", component: Calculo },
  //CALCULO FOREMEX
  { path: "/template/calculo-foremex", component: CalculoForemex },
  //Carga layouts
  { path: "/template/layouts", component: CargaLayouts },
  //Comprobantes de pago
  { path: "/template/comprobantes-pago", component: ComprobantesPago },
  { path: "/template/reportes-nomina", component: ReportesNomina },
  //Editar Pago
  { path: "/template/editarpago", component: EditarPago },
  //Expediente de Nomina
  { path: "/template/expediente-nomina", component: ExpedienteNomina },
  //Firma de Archivos
  { path: "/template/firma-archivos", component: FirmaArchivos },
  //GUARDAR FOREMEX
  { path: "/template/guardar-foremex", component: GuardarForemex },
  //Layout para timbrar
  { path: "/template/layout-para-timbrar", component: LayoutParaTimbrar },
  //Modificar Pagos Timbrados
  {
    path: "/template/modificacion-pagos-timbrados",
    component: ModificarPagoTimbrado,
  },
  //Nomina
  { path: "/template/gruposnomina", component: GruposNominaPrincipal },
  //Incremento Salarial
  { path: "/template/incrementosalarial", component: IncrementoSalarial },
  {
    path: "/template/incrementosalarial/agregar",
    component: AgregarIncrementoSalarial,
  },
  //Salarios
  { path: "/template/salarios", component: Salarios },
  { path: "/template/salarios/crear", component: CrearSalario },
  { path: "/template/salarios/editar", component: EditarSalario },
  //Puestos Laborales
  { path: "/template/puestos", component: PuestosLaborales },
  { path: "/template/puestos/crear", component: CrearPuestoLaboral },
  { path: "/template/puestos/editar", component: EditarPuestoLaboral },
  //Plazas Laborales
  { path: "/template/plazas-laborales", component: PlazasLaborales },
  { path: "/template/plazas-laborales/crear", component: CrearPlazaLaboral },
  { path: "/template/plazas-laborales/editar", component: EditarPlazaLaboral },
  //Solicitud de pago Nomina*/}
  { path: "/template/solicitudpago", component: SolicitudPago },
  { path: "/template/solicitudpago/crear", component: CrearSolicitudPago },
  //Solicitud de pago Finanzas*/}
  { path: "/template/solicitud-pago", component: SolicitudPago },
  { path: "/template/solicitud-pago/editar", component: EditarSolicitudPago },
  //Verificar saldos verificarSaldos
  { path: "/template/verificarSaldosNomina", component: VerificarSaldosNomina },

  //Verificar saldos verificarSaldos
  { path: "/template/cct", component: Cct },

  {
    path: "/template/calendario-movimientos",
    component: CalendarioMovimientos,
  },

  //AREAS DICTAMINADORAS
  { path: "/template/FINIQUITOSAREAS", component: AreasDictaminadoras },
  //CARGA DE CONSTANCIAS
  { path: "/template/FINIQUITOSCONSTANCIAS", component: CargaConstancias },
  //REGISTRO DE FINIQUITOS
  { path: "/template/REGISTRO", component: RegistroFiniquito },
];

export default rutasNomina;
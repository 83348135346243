import React, { useState } from "react";
import { Button, Collapse, Label, FormGroup } from "reactstrap";
import { Formik, Form, Field } from "formik";
import { toast } from "react-toastify";
import Notification from "../../../../components/Notification/Notification";
import { peticionesReceiver } from "../../../../helpers/peticionesReceiver";
import Loader from "../../../../components/Loader/Loader";
import { useEffect } from "react";
import TableComponentVer from "../../../../components/TableComponent/TableComponentVer";
import { SelectTypeHeadSingle } from "../../../../components/GenericInputsFormik/SelectTypeHeadSingle";
import Widget from "../../../../components/Widget/Widget";

import HistorialEmpleado from "./HistorialEmpleado";
import { GetPermissions } from "../../../../libs/permissions/getPermissions";

const formDefault = {
  staffNumber: "",
  nameStaff: "",
  staffType: "1",
};

export const ConsultaEmpleados = () => {
  GetPermissions();

  const [loading, setLoading] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [tipos, setTipos] = useState([]);
  const [employee, setEmployee] = useState("");

  const [parametros, setParametros] = useState({
    action: "datatable",
    table: "staffview",
    rows: "idStaff,staffNumber,concat(name,' ',pName,' ',mName) as fullName,nameEmployType,keyEmployType",
    conditions: "",
    page: 0,
    records: 5,
    search: "",
    order: "",
  });
  const API = peticionesReceiver();
  const cabeceras = ["Id", "Núm. Empelado", "Nombre", "Tipo", "Ver"];
  const filtro = [true, true, true, true];

  const getTypes = async () => {
    const params = {
      action: "select",
      table: "employtypes",
      condition: {
        enabled: 1,
      },
      order: "nameEmployType ASC",
    };
    API.peticion(params)
      .then((res) => {
        if (res.status === 200 && res.data.code === "200") {
          let data = [];
          res.data.data.map((el) => {
            data.push({
              keyEmployType: el.keyEmployType,
              nameEmployType: el.nameEmployType,
            });
          });
          setTipos(data);
        } else {
          toast(
            <Notification
              type={"consultar_error"}
              backMessage={res.data.message}
              withIcon
            />
          );
        }
        setLoading(false);
      })
      .catch((err) => {
        toast(<Notification type={"consultar_servidor_error"} withIcon />);
        setLoading(false);
      });
  };

  useEffect(() => {
    getTypes();
  }, []);

  const submitFunction = (fields) => {
    if (fields.staffNumber) {
      if (fields.staffType) {
        const par = parametros;
        par.conditions = `staffNumber = ${fields.staffNumber} AND keyEmployType = ${fields.staffType}`;
        API.peticion(par)
          .then((res) => {
            if (res.status === 200 && res.data.code === "200") {
              if (res.data.data && res.data.data.length !== 0) {
                selectEmployee([res.data.data[0].idStaff]);
              } else {
                toast(
                  <Notification
                    type={"consultar_error"}
                    backMessage={"No se encontraron empleados"}
                    withIcon
                  />,
                  { closeButton: false }
                );
              }
            }
          })
          .catch((err) => {
            toast(<Notification type={"consultar_servidor_error"} withIcon />);
          });
      } else {
        toast(
          <Notification
            type={"agrega_error"}
            backMessage={"Seleccione un tipo de empleado"}
            withIcon
          />,
          { closeButton: false }
        );
      }
    } else if (fields.nameStaff) {
      // setIsOpen(false);
      setParametros((prevState) => ({
        ...prevState,
        conditions: `concat(name,' ',pName,' ',mName) like '%${fields.nameStaff}%'`,
      }));
      setIsOpen(true);
    } else {
      toast(
        <Notification
          type={"agrega_error"}
          backMessage={"Ingrese un nombre o un número de empleado"}
          withIcon
        />,
        { closeButton: false }
      );
    }
  };

  const selectEmployee = async (el) => {
    /* setLoading(true) */
    setEmployee(el);
  };
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <Widget className="widget-p-md">
          {employee === "" ? (
            <>
              <div className="row">
                <div className="col-sm-6">
                  <h4 className="text-center text-sm-left">Buscar empleado</h4>
                </div>
              </div>
              <Formik
                initialValues={formDefault}
                onSubmit={(fields) => {
                  submitFunction(fields);
                }}
              >
                {({ errors, touched, handleBlur, values, setFieldValue }) => (
                  <Form className="mt-3 row justify-content-center">
                    <div className="col-12 col-md-7">
                      <FormGroup>
                        <Label for="nameStaff">Nombre de empleado</Label>
                        <Field
                          name="nameStaff"
                          type="text"
                          className="form-control"
                        />
                      </FormGroup>
                      <div className="border border-light">
                        <div
                          style={{ height: "17px" }}
                          className="w-100 my-4 border-bottom border-primary text-center"
                        >
                          <span
                            style={{
                              fontSize: "20px",
                              backgroundColor: "#ffff",
                            }}
                            className="px-3"
                          >
                            ó
                          </span>
                        </div>
                      </div>
                      <FormGroup className="mt-4">
                        <Label for="staffNumber">Número de empleado</Label>
                        <Field
                          name="staffNumber"
                          type="text"
                          className="form-control"
                        />
                      </FormGroup>
                      <FormGroup>
                        <SelectTypeHeadSingle
                          label="Tipo de empleado"
                          isRequired={false}
                          inputName="staffType"
                          optionsArray={tipos}
                          defaultOption="Seleccione un tipo de empleado"
                          onChangeMethod={(event) =>
                            setFieldValue(
                              [event.target.name],
                              event.target.value
                            )
                          }
                          onBlur={handleBlur}
                          isDisabled={false}
                          value={values.staffType}
                          touched={touched.staffType}
                          errors={errors.staffType}
                          optionValue="keyEmployType"
                          optionName="nameEmployType"
                        />
                      </FormGroup>
                      <div className="d-flex justify-content-center mt-5">
                        <Button color="primary" type="submit">
                          Buscar
                        </Button>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
              <Collapse isOpen={isOpen}>
                <TableComponentVer
                  titulo={"Empleados"}
                  cabecerasTabla={cabeceras}
                  filtro={filtro}
                  ver={selectEmployee}
                  parametros={parametros}
                />
              </Collapse>
            </>
          ) : (
            <>
              <HistorialEmpleado idEmployee = {employee} setLoading={setLoading} setEmployee={setEmployee} setIsOpen={setIsOpen}/>
            </>
          )}
        </Widget>
      )}
    </>
  );
};
export default ConsultaEmpleados;

import { peticionesReceiver } from "../../../helpers/peticionesReceiver";
import Notification from "../../../components/Notification/Notification";
import { toast } from "react-toastify";
const API = peticionesReceiver();
export const updateCity = async (cityU) => {
  if (cityU) {
    const params = {
      action: "update",
      table: "cities",
      rows: cityU,
      condition: {
        keyCity: cityU.keyCity,
      },
      validate: [
        ["keyCounty", "nameCity"],
        ["economicKey"],
      ],
    };
    API.peticion(params)
      .then((res) => {
        if (res.status === 200 && res.data.code === "200") {
          toast(
            <Notification
              type={"modifica_exito"}
              backMessage={res.data.message}
              withIcon
            />
          );
          window.location.href = "#/template/ciudades";
        } else {
          toast(
            <Notification type={"modifica_error"} backMessage={res.data.message} withIcon />
          );
        }
      })
      .catch((err) =>
        toast(<Notification type={"consultar_servidor_error"} withIcon />)
      );
  }
};

export const createCity = async (cityN) => {
    if (cityN) {
      const params = {
        action: "insert",
        table: "cities",
        rows: cityN,
        validate: [
          ["keyCounty", "nameCity"],
          ["economicKey"],
        ],
      };
      API.peticion(params).then((res) => { 
          if (res.status === 200 && res.data.code === "200") {
            toast(<Notification type={"agrega_exito"} backMessage={res.data.message} withIcon />);
            window.location.href = "#/template/ciudades";
          }else{
            toast(<Notification type={"agrega_error"} backMessage={res.data.message} withIcon />);
          }
        })
        .catch((err) => toast(<Notification type={"consultar_servidor_error"} withIcon />));
    }
  };
import React, { useState, useEffect } from "react";
import ModalComponentEliminar from "../Modal/ModalDelete";
import Loader from "../Loader/Loader";
import axios from "axios";
import {
  Col,
  Row,
  Table,
  Input,
  Pagination,
  PaginationItem,
  PaginationLink,
  Button,
} from "reactstrap";
import Widget from "../Widget/Widget.js";
import s from "../../pages/tables/Tables.module.scss";
import { useHistory } from "react-router-dom";
import sc from "./TableComponentFiltro.module.scss";
import { toast } from "react-toastify";
import Notification from "../Notification/Notification";
import { peticionEncript } from "../../helpers/peticionesEncripted";
import getActions from "../../helpers/getPermissionForms";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { formatCell } from "./cellsFormat";
import getRouters from "../Layout/function";

/**
 * @param {Object} props
 * @param {string} props.titulo - Titulo de la tabla 
 * @param {Array<string>} props.cabecerasTabla - Arreglo con las cabeceras de la tabla
 * @param {Array<boolean} props.filtro - Arreglo con uno o ceros sobre que columnas mostrar
 * @param {boolean} props.disabledDelete - Opcional: Boolean que habilita el boton de eliminar
 * @param {Array<string>} props.parametros - Objecto con los parametros para la petición al controlador
 * @param {string} props.routePeticion - Opcional: Ruta del controlador a consultar 
 * @param {string} props.rutaEditar - Opcional: Ruta de redirección al dar click en editar
 * @param {boolean} props.addEdit - Opcional: Booleano que muestra el boton de editar
 * @param {Function} props.actionEdit - Opcional: Funcion de activación que sucede al momento de dar click
 * @param {string} props.rutaCrear - Opcional: Ruta de redirección al dar click en crear
 * @param {boolean} props.addNew - Opcional: Booleano que muestra el boton de agregar
 * @param {Function} props.actionNew - Opcional: Funcion de activación que sucede al momento de dar click
 * @param {boolean} props.addDelete - Opcional: Booleano que muestra el boton de eliminar
 * @param {Function} props.elimina - Opcional: Funcion de activación que sucede al momento de dar click
 * @param {string} props.rutaWatch - Opcional: Ruta de redirección al dar click en ver
 * @param {boolean} props.addWatch - Opcional: Booleano que muestra el boton de ver
 * @param {Function} props.actionWatch - Opcional: Funcion de activación que sucede al momento de dar click
 * @param {Array[Function]} props.specialColums - Opcional: Arreglo de renders de funciones de componentes [render(value)]
 * @param {boolean} props.hiddeTitle - Opcional: Booleano que oculta el titulo de la tabla
 * @param {boolean} props.defaultOrder - Opcional: Ordenar registros
 * @returns {JSX.Element} - El elemento JSX del componente.
 */
export default function TableComponentCustom({
  titulo,
  cabecerasTabla,
  filtro,
  disabledDelete,
  parametros,
  routePeticion,
  rutaEditar,
  addEdit,
  actionEdit,
  addNew,
  rutaCrear,
  actionNew,
  addDelete,
  elimina,
  addWatch,
  actionWatch,
  rutaWatch,
  specialColumns,
  hiddeTitle,
  defaultOrder
}) {

  const colsBdNames = parametros.rows.split(","); // Columnas del back
  const nameTable = parametros.table ? parametros.table.split(" ") : "";
  const nameColumn = colsBdNames[0].split(".");
  const deleteRow = disabledDelete === true ? true : false;
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [tableContent, setTableContent] = useState([]); // Información mostrada en la tabla
  const [pageSize, setPageSize] = useState(5); // Tamaño de las paginas
  const [totalRegistros, setTotalRegistros] = useState(0); // Total de registros en BD
  const [pagesCount, setPagesCount] = useState(0); // Cantidad total de paginas
  const [seccionActual, setSeccionActual] = useState(0); // Seccion actual
  const [sortCol, setSortCol] = useState({
    //Parametros para aplicar filtros a la busqueda
    direction: "",
    column: "",
    page: "",
    currentPage: 0,
    search: "",
    records: 5,
  });
  const [pagination, setPagination] = useState([]);

  const [permissionsForm, setPermissionsForm] = useState("");

  const settableContentPage = (e, index) => {
    if (index + 1 > (seccionActual + 1) * 5) {
      setSeccionActual(seccionActual + 1);
    } else if (seccionActual != 0 && index + 1 <= seccionActual * 5) {
      setSeccionActual(seccionActual - 1);
    }
    e.preventDefault();
    setSortCol((prevState) => ({
      ...prevState,
      page: pageSize * index,
      currentPage: index,
    }));
  };

  function handleSubmit(dataContent, type, route) {

    if (route) {
      history.push({
        pathname: route,
      });
      localStorage.setItem("dataContent", dataContent.join("|-|"));

    } else {

      if (type === "watch") {
        history.push({
          pathname: rutaWatch,
        });
        localStorage.setItem("dataContent", dataContent.join("|-|"));
      } else {
        if (dataContent === undefined) {
          history.push({
            pathname: rutaCrear,
          });
        } else {
          history.push({
            pathname: rutaEditar,
          });
          localStorage.setItem("dataContent", dataContent.join("|-|"));
        }
      }

    }



  }

  const mapea = (element, indexCol, indexRow) => {
    if (filtro !== null) {
      return formatCell(element, indexCol, indexRow, filtro[indexCol], sortCol, cabecerasTabla[indexCol] + "-" + titulo);
    }
    return formatCell(element, indexCol, indexRow, true, sortCol, cabecerasTabla[indexCol] + "-" + titulo);
  };

  const sortTable = (cabecera, indexHeader) => {
    if (
      !["Editar", "Eliminar", "Id", "Ver", "Seleccionar", "Modificar"].includes(
        cabecera
      )
    ) {
      if (sortCol.column !== indexHeader) {
        setSortCol((ps) => ({ ...ps, column: indexHeader, direction: "asc" }));
      } else {
        setSortCol((ps) => ({
          ...ps,
          direction:
            sortCol.direction === "asc"
              ? "desc"
              : sortCol.direction === "desc"
                ? ""
                : "asc",
        }));
      }
    }
  };

  const getData = async (orderOb,isDelete) => {
    //LA PRIMERA VEZ QUE ENTRAMOS VIENE UN ORDEROB = EMPTY Y EN EL PARAMS = ORDER = "", PAGE = 0 Y RECORDS = 5
    //CUANDO SELECCIONO EL HEADER VIENE ORDEROB = COLUMN = 1 Y DIRECTION = ASC Y EN EL PARAMS = ORDER = "ASC", PAGE = 0 Y RECORDS = 5
    //DESPUES DE ESO SELECCIONO EL PAGINADO Y MUESTRA ORDEROB = COLUMN = "", DIRECTION = "" Y PAGE = 5 Y EN EL PARAMS = ORDER = "", PAGE = 5 Y RECORDS = 5
    //SOLO HAY QUE VERIFICAR EL ESTADO DEL HOOK Y QUE NO CAMBIE LOS PARAMS
    let params = {...parametros};
    if (sortCol.column !== "" && sortCol.direction !== "") {
      //AQUI QUIERE DECIR QUE TRAE UN ORDENAMIENTO POR HEADER
      params.order = orderOb.column + " " + orderOb.direction;
    } else {
      params.order = parametros.order;
    }
    if (sortCol.page !== "") {
      //QUIERE DECIR QUE CAMBIAMOS DE PAGINA
      params.page = sortCol.page;
    } else {
      params.page = 0;
    }
    if (sortCol.search !== "") {
      params.search = sortCol.search;
    } else {
      params.search = "";
    }
    if (orderOb) {
    } else {
      if (sortCol.column !== "" && sortCol.direction !== "") {
        //AQUI TRAE UN ORDENAMIENTO POR HEADER
        params.order = orderOb.column + " " + orderOb.direction;
      } else {
        params.order = parametros.order;
      }
      if (sortCol.page !== "") {
        //CAMBIAMOS DE PAGINA
        params.page = sortCol.page;
      } else {
        params.page = 0;
      }
      if (sortCol.search !== "") {
        params.search = sortCol.search;
      } else {
        params.search = "";
      }
    }

    if(isDelete && tableContent.length === 1){
      params = parametros
      setSortCol( {
        //Parametros para aplicar filtros a la busqueda
        direction: "",
        column: "",
        page: "",
        currentPage: 0,
        search: "",
        records: 5,
      })
    }  
    params.records = sortCol.records;
    if (defaultOrder && params.order === "") params.order = defaultOrder;
    setLoading(true);
    const finalData = peticionEncript(params); 
    let route = routePeticion ? routePeticion : "receiver/receiver.php";
    await axios
      .post(`${process.env.REACT_APP_API}${route}`, finalData)
      .then((res) => {
        let data = [];
        if (res.status === 200 && res.data.code === "200") {
          setTotalRegistros(res.data.totalRecords || 0);
          setPagesCount(Math.ceil(res.data.totalRecords / pageSize));
          res.data.data.map((el) => {
            data.push(Object.values(el));
          });
        }
        setTableContent(data);
        setLoading(false);
      })
      .catch((err) => {
        toast(<Notification type={"consultar_servidor_error"} withIcon />);
        setLoading(false);
      });
  };

  const loc = useLocation();
  const user = useSelector((state) => state.userInfo);
  const { id, profileType } = useSelector((state) => state.userInfo);
  const getActionsUser = async () => {
    const pathname = loc.pathname.split("/")[2];
    if (pathname !== "dashboard") {
      const route = await getRouters(user.permission, pathname);
      if (!route.permission) {
        history.push("/template");
      } else {
        getPermissionsUser();
      }
    }
  };

  const getPermissionsUser = async () => {
    const pathname = loc.pathname.split("/")[2];
    const result = await getActions(
      id,
      profileType.selectProfile.keyProfile,
      pathname
    );
    if(!result.success || (result.actions.CON !== "1" && result.actions.INS !== "1" && result.actions.UPD !== "1" && result.actions.DEL !== "1")) history.push("/template");
    setPermissionsForm(result.actions);
  };

  useEffect(() => {
    getData(sortCol);
  }, [sortCol, parametros]);

  useEffect(() => {
    getActionsUser();
  }, []);

  const handleOnChange = (e) => {
    setSeccionActual(0);
    setSortCol((prevState) => ({
      ...prevState,
      search: e.target.value,
      currentPage: 0,
      page: "",
    }));
  };

  const handleOnChangeSelect = (e) => {
    setSeccionActual(0);
    setPageSize(e.target.value);
    setSortCol((prevState) => ({
      ...prevState,
      records: e.target.value,
      currentPage: 0,
      page: "",
    }));
  };

  const namesWatch = ["ver", "seleccionar"];
  const isForWatch = (header) => {
    return namesWatch.includes(header);
  };

  const isEditCol = (cabecera) => {
    return ["editar", "modificar", "modificar estatus"].includes(cabecera.toLowerCase());
  }

  /*Paginacion @luixeduard */
  useEffect(() => {
    const pages = Math.ceil(totalRegistros / pageSize);
    const maxPages = Math.min(pages, 5);
    const startPage = Math.max(Math.min(pages - maxPages, sortCol.currentPage - Math.floor(maxPages / 2)), 0);
    const endPage = Math.min(startPage + maxPages, pages);
    const tempPages = Array.from({ length: endPage - startPage }, (_, i) => i + startPage);

    setPagination(tempPages);
}, [tableContent]);

  return (
      <div className="mb-4">
        {!hiddeTitle && <div className={s.tableTitle}>
              <h1 className="text-secondary text-center">{titulo} </h1>
        </div>}
        <div className="d-flex flex-wrap justify-content-between">
              {permissionsForm.CON === "1" ? (
                <Input
                  value={sortCol.search}
                  name="search"
                  onChange={handleOnChange}
                  className="col-12 col-sm-6 order-last order-sm-first"
                  placeholder="Buscar..."
                ></Input>
              ) : (
                <div className="col-12 col-sm-6 order-first order-sm-last mb-3 mt-sm-0 text-center text-sm-right"></div>
              )}
              {permissionsForm.INS === "1" && addNew && (
                <div className="col-12 col-sm-6 order-first order-sm-last mb-3 mt-sm-0 text-center text-sm-right">
                  <Button
                    color="add"
                    className="btn-rounded with-icon"
                    onClick={() => {
                      actionNew ? actionNew() : handleSubmit();
                    }}
                  >
                    Agregar
                  </Button>
                </div>
              )}
        </div>
        <div className="widget-table-overflow mt-3">
              <Table
                className={`table-striped table-borderless table-hover ${s.statesTable}`}
                responsive
              >
                {permissionsForm.CON === "1" && (
                  <thead className="border-bottom border-secondary">
                    <tr className={sc.userSelectNone}>
                      <>
                        {cabecerasTabla.map((cabecera, indexHeaders) => {
                          if(!cabecera) return null;
                          if (isEditCol(cabecera)) {
                            if (permissionsForm.UPD === "1") {
                              return (
                                <th key={cabecera} className="text-center">
                                  {cabecera}
                                </th>
                              );
                            }
                            return null
                          } else if (cabecera.toLowerCase() === "eliminar") {
                            if (permissionsForm.DEL === "1") {
                              return (
                                <th key={cabecera} className="text-center">
                                  {cabecera}
                                </th>
                              );
                            }
                            return null
                          } else if (isForWatch(cabecera.toLowerCase())) {
                            if (addWatch) {
                              return (
                                <th key={cabecera} className="text-center">
                                  {cabecera}
                                </th>
                              );
                            }
                            return null;
                          } else {
                            return (
                              <th
                                key={cabecera}
                                className="text-center"
                                onClick={() =>
                                  sortTable(cabecera, indexHeaders + 1)
                                }
                              >
                                {cabecera === "Id" ? "#" : cabecera}
                                <i
                                  className={
                                    "align-top eva" +
                                    (sortCol.column === indexHeaders + 1
                                      ? sortCol.direction === "asc"
                                        ? " eva-arrow-ios-upward-outline"
                                        : sortCol.direction === "desc"
                                          ? " eva-arrow-ios-downward-outline"
                                          : ""
                                      : "")
                                  }
                                ></i>
                              </th>
                            );
                          }
                        })}
                      </>
                    </tr>
                  </thead>
                )}
                <tbody>
                  {loading ? (
                    <tr key={"loading"}>
                      <td colSpan={cabecerasTabla.length}>
                        <Loader />
                      </td>
                    </tr>
                  ) : tableContent.length === 0 ? (
                    <tr key={"nothingSize"}>
                      <td
                        colSpan={cabecerasTabla.length}
                        className="text-center"
                      >
                        No se encontraron registros
                      </td>
                    </tr>
                  ) : permissionsForm.CON !== "1" ? (
                    <tr key={`nothingPerm${Math.random()}`}>
                      <td
                        colSpan={cabecerasTabla.length}
                        className="text-center"
                      >
                        No tienes permitido ver los registros de esta seccion
                      </td>
                    </tr>
                  ) : (
                    tableContent.map((registro, index) => (
                      <tr key={`tCont${index}`}>
                        {registro.map((elemento, indexCol) => {
                          return mapea(elemento, indexCol, index);
                        })}

                        {
                          // * Columna editar * //
                          rutaEditar !== null && (
                            <>
                              {permissionsForm.UPD === "1" && addEdit ? (
                                <td
                                  onClick={() =>
                                    actionEdit
                                      ? actionEdit(registro)
                                      : handleSubmit(registro, "editar")
                                  }
                                  className=" text-center"
                                >
                                  <i
                                    className={
                                      sc.handCursor +
                                      " fa fa-pencil text-warning"
                                    }
                                  />
                                </td>
                              ) : null}
                            </>
                          )
                        }

                        {
                          // * Columna eliminar * //
                          deleteRow === false && (
                            <>
                              {permissionsForm.DEL === "1" && addDelete ? (
                                <td className="text-center">
                                  <div>
                                    <ModalComponentEliminar
                                      modalTitle="Eliminar"
                                      id={registro[0]}
                                      table={nameTable}
                                      nameColumn={nameColumn}
                                      getData={getData}
                                      elimina={elimina}
                                      sortCol={sortCol}
                                    >
                                      <h6>
                                        ¿Seguro de eliminar el registro "
                                        {registro[1]}
                                        "?
                                      </h6>
                                      No podrá recuperar la información después
                                      de ser eliminada
                                    </ModalComponentEliminar>
                                  </div>
                                </td>
                              ) : null}
                            </>
                          )
                        }
                        {deleteRow === false ? (
                          <>
                            {addWatch ? (
                              <td className="text-center">
                                <i
                                  className={
                                    sc.handCursor + " fa fa-eye text-info"
                                  }
                                  onClick={() =>
                                    actionWatch
                                      ? actionWatch(registro)
                                      : handleSubmit(registro, "watch")
                                  }
                                />
                              </td>
                            ) : null}
                          </>
                        ) : null}

                        {specialColumns &&
                          specialColumns.length > 0 ?
                          specialColumns.map((column, index) => (
                            <td className="text-center" key={`espCol${index}`}>
                              <div className="d-flex flex-column justify-content-center align-items-center">
                                {column(registro, `spc${index}`)}
                              </div>
                            </td>
                          )) : null}
                      </tr>
                    ))
                  )}
                </tbody>
              </Table>
              {permissionsForm.CON === "1" ? (
                <div className="row">
                  <div className="col-12 col-sm-6 d-flex justify-content-around mt-3">
                    <div>
                      Mostrando{" "}
                      <span className="text-primary">
                        {sortCol.currentPage * pageSize +
                          (tableContent.length === 0 ? 0 : 1)}
                      </span>{" "}
                      a{" "}
                      <span className="text-primary">
                        {pageSize * (sortCol.currentPage + 1) <= totalRegistros
                          ? pageSize * (sortCol.currentPage + 1)
                          : totalRegistros}{" "}
                      </span>
                      de {totalRegistros} registros
                    </div>
                    <select
                      className="form-control form-control-sm"
                      style={{ width: "78px" }}
                      value={sortCol.records}
                      onChange={handleOnChangeSelect}
                    >
                      <option value={5} key={5}>
                        5
                      </option>
                      <option value={10} key={10}>
                        10
                      </option>
                      <option value={15} key={15}>
                        15
                      </option>
                      <option value={20} key={20}>
                        20
                      </option>
                      <option value={100} key={100}>
                        100
                      </option>
                    </select>
                  </div>
                  <div className="col-12 col-sm-6 d-flex justify-content-center justify-content-md-end">
                    <Pagination className="pagination-borderless d-flex mt-3">
                      <PaginationItem className="m-0">
                        <PaginationLink 
                          disabled={sortCol.currentPage <= 0}
                          onClick={(e) => settableContentPage(e, sortCol.currentPage - 1)}
                          previous 
                          className={sortCol.currentPage <= 0 ? "text-muted" : ""}
                        />
                      </PaginationItem>
                      {pagination.map((page, i) => (
                        <PaginationItem key={i} disabled={page === sortCol.currentPage} className="m-1" >
                            <PaginationLink
                                onClick={(e) => settableContentPage(e, page)}
                                className={page === sortCol.currentPage ? "text-bold text-white bg-primary":"text-secondary"}
                            >
                                {page + 1}
                            </PaginationLink>
                        </PaginationItem>
                      ))}
                      <PaginationItem className="m-0">
                        <PaginationLink 
                          disabled={sortCol.currentPage + 1 >= pagesCount}
                          onClick={(e) => settableContentPage(e, sortCol.currentPage + 1)}
                          next 
                          className={sortCol.currentPage + 1 >= pagesCount ? "text-muted" : ""}
                        />
                      </PaginationItem>
                    </Pagination>
                  </div>
                </div>
              ) : null}
        </div>
      </div>
  );
};

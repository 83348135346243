import React, { useEffect } from "react";
import Select from "react-select";
import makeAnimated from "react-select/animated";

const animatedComponents = makeAnimated();

export default function Multiselect({
  label,
  inputName,
  onBlurMethod,
  value,
  isRequired,
  touched,
  errors,
  optionsArray,
  isDisabled,
  multiselect,
  optionValue,
  optionName,
  multipleLabels,
  optionSubLabel,
  //Si obtiene el metodo setFieldValue lo ejecutara, en caso contrario ejecutara un metodo onChangeMethod
  setFieldValue,
  onChangeMethod
  //retorna un nuevo array [{},{},{}],
}) {
  return (
    <div className="form-group">
      <label htmlFor={inputName}>
        {label} {isRequired && <span className="text-danger">*</span>}
      </label>
      <Select
        
        closeMenuOnSelect={!multiselect}
        isMulti={multiselect}
        components={animatedComponents}
        defaultValue={[]}
        options={optionsArray}
        className={errors && touched ? " is-invalid" : ""}
        name={inputName}
        id={inputName}
        onChange={setFieldValue  ? (valueChanged) =>  setFieldValue(inputName,valueChanged) :  onChangeMethod}
        onBlur={onBlurMethod}
        value={value}
        disabled={isDisabled === true ? true : false}
        getOptionLabel={(option) => {
          return option[optionName];
        }}
        getOptionValue={(option) => {
          return option[optionValue];
        }}
      />
      {touched && errors && <span style={{ color: "red" }}>{errors}</span>}
    </div>
  );
}

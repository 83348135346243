import { peticionesReceiver } from "../../../helpers/peticionesReceiver";
import Notification from "../../../components/Notification/Notification";
import { toast } from "react-toastify";
const API = peticionesReceiver();

export const createJobs = async (jobs) => {
  if (jobs) {
    const params = {
      action: "insert",
      table: "jobs",
      rows: jobs,
      order: "keyJobType asc",
      validate: [
      ["keyJobType", "nameJob"]
    ]
    };
    API.peticion(params).then((res) => { 
        if (res.status === 200 && res.data.code === "200") {
          toast(<Notification type={"agrega_exito"} withIcon />);
          window.location.href = "#/template/puestos"
        }else{
          toast(<Notification type={"agrega_error"} backMessage={res.data.message} withIcon />);
        }
      })
      .catch((err) =>{
      toast(<Notification type={"consultar_servidor_error"} withIcon />);
    });
  }
};

export const updateJobs = async (jobs) => {
  if (jobs) {
    const params = {
      action: "update",
      table: "jobs",
      rows: jobs,
      condition: {
        keyJob: jobs.keyJob,
      },
      validate: [["nameJob","keyJobType"]]
    };
    API.peticion(params).then((res) => { 
        if (res.status === 200 && res.data.code === "200") {
          toast(<Notification type={"modifica_exito"} withIcon />);
          window.location.href = "#/template/puestos"
        }else{
          toast(<Notification type={"modifica_error"} backMessage={res.data.message} withIcon />);
        }
      })
      .catch((err) =>{
      toast(<Notification type={"consultar_servidor_error"} withIcon />);
    });
  }
};
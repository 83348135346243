import { Collapse } from "reactstrap";
import { useState } from "react";
import classnames from "classnames";

import lg from "./LinksGroup.module.scss";
import s from "../Sidebar.module.scss";

export default function ProfilesGroup(props) {
    const { profiles, selected, onclick, iconName } = props; 
    const [isOpen, setIsOpen] = useState(false)

    if (profiles.length === 1) {
        return (<li className={`${s.sidebarProfileContainer} ${s.sidebarSelectProfile} mr-4`}>
            <span className="text-wrap font-weight-bold">{profiles[0].profile}</span>
            <span className="text-wrap">{profiles[0].areaName}</span>
        </li>)
    }

    return (
        <>
            <li key={'headerProf'} className={lg.headerLink}>
                <a className={classnames("d-flex", { [lg.collapsed]: isOpen })}
                    href="#"
                    onClick={(e) => { e.preventDefault(); setIsOpen(!isOpen)}}
                >
                    <span className={lg.icon}>{iconName}</span>
                    <span className={`text-default ml-1 align-middle w-auto`}>PERFILES</span>
                    <b className={["fa fa-angle-right", lg.caret].join(" ")} />
                </a>
                <Collapse className={lg.panel} isOpen={isOpen}>
                    <ul>
                        {profiles.map((it, index) => (
                            <li key={`prof${index}`} onClick={() => onclick(index)} className={`${s.sidebarProfileContainer} ${selected === index && s.sidebarSelectProfile} p-2`}>
                                <span className="text-wrap font-weight-bold">{it.profile}</span>
                                <span className="text-wrap">{it.areaName}</span>
                            </li>
                        ))}
                    </ul>
                </Collapse>
            </li>
            <li key={'profileAct'} className={`${isOpen ? "d-none" : ""} ${s.sidebarProfileContainer} ${s.sidebarSelectProfile} mr-4`}>
                <span className="text-wrap font-weight-bold">{profiles[selected].profile}</span>
                <span className="text-wrap">{profiles[selected].areaName}</span>
            </li>
        </>
    )
}
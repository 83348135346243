import { Image, StyleSheet, View, Text } from "@react-pdf/renderer";
import { styles } from "../../components/Pdf/pdfGeneral/EstiloPdf";

const s = StyleSheet.create({
  h: { height: "90px" },
  mxauto: { marginHorizontal: "auto" },
  max: { width: "70%" },
  mb5: { marginBottom: 5 },
  mt50: { marginTop: 50 },
  textBold: { fontFamily: "Helvetica-Bold" },
});

/**
 * @param {Object} props - parametros de visualizacion
 * @param {boolean} props.showTT - false para ocultar el titulo (opcional)
 * @param {boolean} props.fixed - true para mostrar en todas las paginas (opcional)
 */
export default function HeaderReport({ showTT, fixed = false }) {
  const origin = window.location.origin;
  return (
    <>
      <View style={styles.tableRow} fixed={fixed}>
        <Image style={s.h} src={`${origin}/images/pdf/gobEDOMEX.png`} />
        <View style={[s.max, s.mxauto]}>
          {showTT !== false && (
            <>
              <Text style={[styles.title, s.mb5, s.mt50, s.textBold]}>
                Sistema de administración
              </Text>
              <Text style={[styles.title, s.textBold]}>
                de personal y presupuesto
              </Text>
            </>
          )}
        </View>
        <Image style={[s.h]} src={`${origin}/images/logo.png`} />
      </View>
    </>
  );
}

import { Modal, ModalBody, ModalHeader } from "reactstrap";
import Datatable from "../../../components/Datatable/Datatable";

export default function ModalSigneds({
  modal,
  setModal,
  headers,
  columns,
  columnDefs,
  filesSigneds,
}) {
  const toggle = () => setModal(!modal);

  const defheaders = ["Motivo", ...headers];

  const defColumns = [{ data: "response" }, ...columns];

  return (
    <Modal
      isOpen={modal}
      toggle={toggle}
      keyboard
      size="lg"
      centered
      scrollable
      style={{ minWidth: "90%" }}
    >
      <ModalHeader tag="h4" toggle={toggle}>
        Documentos firmados
      </ModalHeader>
      <ModalBody>
        <div className="mx-2">
          <div className="text-center mb-2">
            <img
              src="/images/li-edit-document.gif"
              alt="sign-accept"
              width="190"
              height="190"
            />
          </div>

          <div className="mx-4">
            <h5>
              <span className="text-success pr-1">{filesSigneds.ok}</span>{" "}
              firmados
            </h5>

            <h5>
              <span className="text-danger pr-1">{filesSigneds.error}</span>{" "}
              errores
            </h5>
          </div>

          {filesSigneds.list.length > 0 && (
            <div className="mt-4">
              <div className="text-center">
                <h4>Documentos con errores</h4>
              </div>

              <Datatable
                headers={defheaders}
                columns={defColumns}
                columnDefs={columnDefs}
                data={filesSigneds.list}
                control="front"
                searching={false}
              />
            </div>
          )}
        </div>
      </ModalBody>
    </Modal>
  );
}

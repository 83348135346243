import React, { useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Button, Collapse, Row, Col } from "reactstrap";
import ModalConfirmation from "../../../components/Modal/ModalConfirmation";
import { NumberInput } from "../../../components/GenericInputsFormik/NumberInput";
import { TextAreaInput } from "../../../components/GenericInputsFormik/index";

import { useEffect } from "react";
import { peticionesReceiver } from "../../../helpers/peticionesReceiver";
import { toast } from "react-toastify";
import Notification from "../../../components/Notification/Notification";
import Loader from "../../../components/Loader/Loader";

const FormPagosPE = ({
  permissions,
  setRefreshP,
  data,
  setCollapse,
}) => {
  const [modalConfirmation, setModalConfirmation] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [countLoadings, setCountLoadings] = useState(0);
  const [unders, setUnders] = useState([]);

  const API = peticionesReceiver();

  const FormSchema = Yup.object().shape({
    amount: Yup.string().required("Ingrese el nombre del monto"),
    daysPaid: Yup.string().required("Ingrese los dias pagados"),
    daysDiscount: Yup.string().required("Ingrese los dias de descuento"),
    comments: Yup.string().required("Ingrese algun comentario"),
    daysWork: Yup.string().required("Ingrese los dias trabajados"),
  });

  const getUnders = async () => {
    const data = {
      action: "multiselect",
      table: "unders",
      rows: "idUnder,concat(under,' - ',nameUnder) as name",
      conditions: "enabled = 1",
      order: "cast(under as float) asc",
    };
    await API.peticion(data)
      .then((res) => {
        if (res.status === 200 && res.data.code === "200") {
          setUnders(res.data.data);
        } else {
          toast(<Notification type={"consultar_error"} withIcon />);
        }
      })
      .catch((err) => {
        toast(<Notification type={"consultar_servidor_error"} withIcon />);
      });
    setCountLoadings(countLoadings + 1);
  };

  // function getDays(endDate) {
  //   const oneDay = 24 * 60 * 60 * 1000;
  //   const date = new Date();

  //   const timeDifference = Math.abs(
  //     new Date(values.startDate) - new Date(endDate)
  //   );
  //   setFieldValue("daysPay", Math.round(timeDifference / oneDay));
  // }

  const {
    handleSubmit,
    values,
    errors,
    setFieldValue,
    touched,
    handleBlur,
    resetForm,
  } = useFormik({
    initialValues: {
      idPayExtra: "",
      amount: "",
      daysPaid: "",
      daysDiscount: "",
      daysWork: "",
      comments: "",
    },
    onSubmit: () => {
      setModalConfirmation(true);
    },
    validationSchema: FormSchema,
    enableReinitialize: true,
  });

  const onChange = (e) => {
    const { name, value } = e.target;
    setFieldValue(name, value);
  };

  const editPayExtra = async () => {
    const data = {
      action: "update",
      table: "paysextras",
      rows: {
        idPayExtra: values.idPayExtra,
        amount: values.amount,
        daysPaid: values.daysPaid,
        daysDiscount: values.daysDiscount,
        comments: values.comments,
        daysWork: values.daysWork
      },
      condition: {
        idPayExtra: values.idPayExtra,
      },
    };
    const res = await API.peticion(data);
    if (res.status === 200 && res.data.status === "success") {
      toast(
        <Notification
          type={"agrega_exito"}
          backMessage={res.data.message}
          withIcon
        />
      );
      /* setCollapse("home"); */
      setRefreshP(true);
      setCollapse("home");
    } else {
      toast(
        <Notification
          type={"agrega_error"}
          backMessage={res.data.message}
          withIcon
        />
      );
    }
  };

  useEffect(() => {
    getUnders();

    if (data && data !== "") {
      setDataForEdit();
    }
  }, []);

  const setDataForEdit = () => {
    setIsEdit(true);

    setFieldValue("idPayExtra", data.idPayExtra);
    setFieldValue("amount", data.amount);
    setFieldValue("daysPaid", data.daysPaid);
    setFieldValue("daysDiscount", data.daysDiscount);
    setFieldValue("comments", data.comments);
    setFieldValue("daysWork", data.daysWork);
  };

  return (
    <div>
      {true ? (
        <>
          <form onSubmit={handleSubmit}>
            <div className="text-center">
              <h2>Edición de Pago Extraordinario</h2>
            </div>

            <Row className="text-dark text-center my-2" style={{fontSize: "20px"}}>
              <Col xs={12} md={6} className="py-2">
                <strong>Número empleado:</strong>
                <p className="fs"> {data?.staffNumber} </p>
              </Col>
              <Col xs={12} md={6} className="py-2">
                <strong>Nombre empleado:</strong>
                <p className="fs">{data?.fullName}</p>
              </Col>
            </Row>

            <Row className="d-flex justify-content-around ">
              <Col xs={12} lg={5}>
                <NumberInput
                  label="Monto"
                  inputName="amount"
                  isRequired
                  onChangeMethod={onChange}
                  onBlurMethod={handleBlur}
                  value={values.amount}
                  touched={touched.amount}
                  errors={errors.amount}
                  required
                  negatives={false}
                  disableSep={false}
                  prefix="$"
                  enabled
                />
              </Col>
            </Row>
            <Row className="d-flex justify-content-around ">
              <Col xs={12} lg={4}>
                <NumberInput
                  label="Numero de días a pagar"
                  inputName="daysPaid"
                  isRequired
                  onChangeMethod={onChange}
                  onBlurMethod={handleBlur}
                  value={values.daysPaid}
                  touched={touched.daysPaid}
                  errors={errors.daysPaid}
                  required
                  negatives={false}
                  disableSep={true}
                  enabled
                />
              </Col>
              <Col xs={12} lg={4}>
                <NumberInput
                  label="Numero de días trabajados"
                  inputName="daysWork"
                  isRequired
                  onChangeMethod={onChange}
                  onBlurMethod={handleBlur}
                  value={values.daysWork}
                  touched={touched.daysWork}
                  errors={errors.daysWork}
                  required
                  negatives={false}
                  disableSep={true}
                  enabled
                />
              </Col>
              <Col xs={12} lg={4}>
                <NumberInput
                  label="Numero de días a descontar"
                  inputName="daysDiscount"
                  isRequired
                  onChangeMethod={onChange}
                  onBlurMethod={handleBlur}
                  value={values.daysDiscount}
                  touched={touched.daysDiscount}
                  errors={errors.daysDiscount}
                  required
                  negatives={false}
                  disableSep={true}
                  enabled
                />
              </Col>
            </Row>

            <Row className="d-flex justify-content-around ">
              <Col xs={12} lg={8}>
                <TextAreaInput
                  label="Comentarios"
                  inputName="comments"
                  inputRows={4}
                  onChangeMethod={onChange}
                  onBlurMethod={handleBlur}
                  value={values.comments}
                  isRequired
                  touched={touched.comments}
                  errors={errors.comments}
                />
              </Col>
            </Row>

            <div className="d-flex justify-content-around">
              {isEdit
                ? permissions.UPD && (
                    <div>
                      <Button type="submit" color="success">
                        Editar
                      </Button>
                    </div>
                  )
                : permissions.INS && (
                    <div>
                      <Button type="submit" color="success">
                        Agregar
                      </Button>
                    </div>
                  )}
            </div>
          </form>

          <ModalConfirmation
            modalTitle={isEdit ? "Editar" : "Crear"}
            modal={modalConfirmation}
            setModal={setModalConfirmation}
            crear={editPayExtra}
            editar={editPayExtra}
            isEdit={false}
            values={values}
          >
            <div className="text-center">
              Esta seguro de continuar con el registro
            </div>
          </ModalConfirmation>
        </>
      ) : (
        <div>
          <Loader />
        </div>
      )}
    </div>
  );
};

export default FormPagosPE;

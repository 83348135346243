import { useEffect, useState } from "react";
import Datatable from "../../../components/Datatable/Datatable";
import { doRequestSaveRes } from "../../../helpers/requests";
import { fDate } from "../../../libs/tools/format";

const headersList = [
    ["CURP", "RFC", "PATERNO", "MATERNO", "NOMBRE", "SALARIO"],
    ["CURP", "RFC", "PATERNO", "MATERNO", "NOMBRE", "SAPP", "PRISMA", "DIFERENCIA"],
    ["CURP", "CURP PRISMA", "PATERNO", "MATERNO", "NOMBRE", "SAPP"]
];

const titles = [
    "ALTAS DETECTADAS", 
    "BAJAS DETECTADAS", 
    "PERSONAS CON SUELDO SUPERIOR EN SAPP", 
    "PERSONAS CON SUELDO INFERIOR EN SAPP", 
    "PERSONAS CON CURP DIFERENTE EN SAPP", 
]


export default function TableCon({type, filtro}){
    const [refresh, setRefresh] = useState(false);
    const sType = type === 5 ? 2 : (type > 2 ? 1 : 0);
    const headers = headersList[sType];

    useEffect(()=>{
        setRefresh(true);
    }, [filtro]);

    const columns = [
        { data: "curpSapp"},
        { data: "rfc" },
        { data: "pName" },
        { data: "mName" },
        { data: "name" },
        { data: "sappAmount", format: "currency" }
    ];

    if(type === 5){
        columns[1].data = "curpPrisma" 
    }else if(type > 2){
        columns.push({data: "prismaAmount", format: "currency"});
        columns.push({data: "diference", format: "currency"});
    }
    
    const columnDefs = [
        {
            orderable: true,
            targets: [5,6,7],
            className: "text-right",
        },
    ];

    /* Table */
    async function getData(ordCol, itemsPerPage, currentPage, search) {
        const res = await doRequestSaveRes(
            "receiver/receiver.php",
            getParams(ordCol, itemsPerPage, currentPage, search),
            false,
            false
        );
        if (res.code === "200") return res;
        return null;
    }

    function getParams(ordCol, itemsPerPage, currentPage, search) {
        const params = {
            action: "datatable",
            table: `prismamoves`,
            rows: "curpSapp,rfc,pName,mName,name,sappAmount,prismaAmount,diference,curpPrisma",
            conditions: `enabled = 1 AND keyTypeMove = ${type} AND period = ${filtro.period} AND year = ${filtro.year}`,
            order: ordCol,
            records: itemsPerPage.toString(),
            page: (itemsPerPage * currentPage).toString(),
            search
        };
        return params;
    }

    return (
        <div className="mt-md-5 mt-3">
            <div className="text-center bg-dark rounded-top">
                <h5 className="text-white py-1">{titles[type-1]}</h5>
            </div>
            <Datatable
                headers={headers}
                columns={columns}
                columnDefs={columnDefs}
                petition={getData}
                order={{ col: 0, opt: "asc" }}
                control="back"
                stateRefresh={[refresh, setRefresh]}
                searching={true}
                className="px-0 mt-4"
            />
        </div>
    )
}

import { useSelector } from "react-redux";
import Widget from "../../../../components/Widget/Widget";
import { useEffect, useState } from "react";
import { peticionesReceiver } from "../../../../helpers/peticionesReceiver";
import { toast } from "react-toastify";
import Notification from "../../../../components/Notification/Notification";
import { GetPermissions } from "../../../../libs/permissions/getPermissions";
import { Button, Col, Row, Spinner } from "reactstrap";
import Datatable from "../../../../components/Datatable/Datatable";
import ModalDelete from "../../../../components/Modal/ModalDelete";
import { useHistory } from "react-router-dom";
import { DateInput } from "../../../../components/GenericInputsFormik";
import { format } from "date-fns"
import InputWithModal from "../../../../components/GenericInputsFormik/InputWithModal";

export default function Incidencias() {
    const API = peticionesReceiver()
    const [permisos, setPermisos] = useState("")
    const [loading, setLoading] = useState("")
    const history = useHistory();
    const [refresh, setRefresh] = useState(false)
    const [init, setInit] = useState("");
    const [end, setEnd] = useState("");
    const [nameStaff, setNameStaff] = useState("");
    const [idStaff, setIdStaff] = useState("");
    const [nameArea, setNameArea] = useState("");
    const [idArea, setIdArea] = useState("");
    GetPermissions(setPermisos, setLoading);

    const cabecerasEmpleado = [
        "#",
        "Núm. Empleado",
        "Nombre",
        "Puesto",
        "Seleccionar",
    ];

    const cabecerasArea = [
        "#",
        "Num. area",
        "Nombre",
        "Seleccionar",
    ];

    const filtroEmpleado = [
        true, true, true, true, true
    ]

    const filtroArea = [
        true, true, true, true
    ]

    const paramsEmpleado = {
        action: "datatable",
        table: "staff STF INNER JOIN employtypes AS EMT ON STF.keyEmployType = EMT.keyEmployType INNER JOIN jobstaff AS JBS ON JBS.idStaff = STF.idStaff INNER JOIN areas AS AR ON AR.idArea = JBS.idArea INNER JOIN jobtypes AS JBT ON JBT.keyJobType = STF.keyEmployType",
        rows: "STF.idStaff,STF.staffNumber,CONCAT(STF.name,' ',STF.pName,' ',STF.mName) as fullName,JBT.nameJobType",
        conditions: `STF.enabled = 1 AND JBS.valid = 1 AND STF.keyStat = 1 AND JBS.enabled = 1`,
        page: 0,
        records: 5,
        search: "",
        order: "",
    }

    const paramsArea = {
        action: "datatable",
        table: "areas",
        rows: "idArea,areaKey,name",
        conditions: `enabled = 1`,
        page: 0,
        records: 5,
        search: "",
        order: "",
    }

    const columns = [
        { data: "fullname" },
        { data: "nameJob" },
        { data: "name" },
        { data: "dateIncident" },
        { data: "nameTypeIncident" },
        { data: "notes" },
        { data: "nameStat" },
        { data: "authorized" },
        { data: "dateAutorized" },
        {
            data: "idIncident",
            render: function (row) {
                return (
                    <>
                        {permisos.UPD && (
                            <i className="fa fa-pencil-square-o text-warning cursor-pointer" onClick={() => editar(row)} />
                        )}
                    </>
                )
            }
        },
        {
            data: null,
            render: function (row) {
                return (
                    <>
                        {permisos.DEL && row.revised === "0" && (
                            <ModalDelete
                                modalTitle="Eliminar"
                                table="types_incidents"
                                nameColumn={["keyTypeIncident"]}
                                elimina={deleteIncidente}
                                id={row.idIncident}
                            >
                                <h6>
                                    ¿Seguro de eliminar el registro?
                                </h6>
                                No podrá restaurar el contenido
                            </ModalDelete>
                        )}
                    </>
                )
            }
        },
    ]

    const editar = (value) => {
        localStorage.setItem("incEdit", value)
        history.push("/template/incidencias/editar")
    }

    const columnDefs = [
        { targets: [9, 10], className: "text-center", orderable: false }
    ]

    async function deleteIncidente(id) {
        const params = {
            action: "delete",
            table: "incidents",
            condition: { idIncident: id },
        };
        try {
            const res = await API.peticion(params);
            if (res.status === 200 && res.data.code === "200") {
                toast(<Notification type={"elimina_exito"} backMessage={res.data.message} withIcon />);
                setRefresh(true)
            } else {
                toast(<Notification type={"elimina_error"} backMessage={res.data.message} withIcon />);
            }
        } catch (err) {
            toast(<Notification type={"elimina_error"} withIcon />);
        }
    }

    const headers = [
        "Nombre",
        "Puesto",
        "Area",
        "Fecha",
        "Tipo de incidencia",
        "Justificación",
        "Estatus",
        "Autorizado por",
        "Fecha de autorizacion",
        "Editar",
        "Eliminar"
    ]

    function getConditions() {
        let params = `i.enabled = 1`
        if (init && end) {
            params += ` AND i.dateIncident >= '${format(new Date(init), "yyyy-MM-dd")}' AND i.dateIncident <= '${format(new Date(end), "yyyy-MM-dd")}'`
        }
        if (idStaff) {
            params += ` AND i.idStaff = ${idStaff}`
        }
        if (idArea) {
            params += ` AND i.idJobStaff IN (SELECT idJobStaff from jobstaff where valid=1 AND idArea=${idArea})`
        }
        return params
    }

    const getParamsTableElmt = (order, records, page, search) => {
        const params = {
            action: "datatable",
            table: "incidents i inner join types_incidents ti on (i.keyTypeIncident=ti.keyTypeIncident) inner join status s on (i.keyStat=s.keyStat) inner join staff st on (st.idStaff=i.idStaff) left join staff sta on (sta.idStaff=i.idStaffAutorized) inner join jobstaff js on (js.idJobStaff=i.idJobStaff) inner join jobs j on (j.keyJob = js.keyJob) inner join areas a on (a.idArea=js.idArea)",
            rows: "concat(st.name,' ',st.pName,' ',st.mName) as fullname,concat(sta.name,' ',sta.pName,' ',sta.mName) as authorized,i.dateAutorized,i.revised,a.name,j.nameJob,i.idIncident,ti.nameTypeIncident,i.keyStat,s.nameStat,i.dateIncident,i.notes",
            conditions: getConditions(),
            order,
            records: records.toString(),
            page: (page * records).toString(),
            search,
        };
        return params;
    };

    async function getData(order, records, page, search) {
        try {
            const res = await API.peticion(
                getParamsTableElmt(order, records, page, search)
            );
            if (res.status === 200 && res.data.code === "200") {
                return res.data;
            } else {
                toast(
                    <Notification
                        type={"consultar_error"}
                        backMessage={res.data.message}
                    />
                );
                return null;
            }
        } catch (err) {
            toast(<Notification type={"consultar_servidor_error"} withIcon />);
            return null;
        }
    }

    const agregar = () => history.push("/template/incidencias/agregar")

    useEffect(() => {
        if (init !== "" && end !== "") {
            setRefresh(true)
        } else if (init === "" && end === "") {
            setRefresh(true)
        }
    }, [init, end])

    const actionSelectEmpleado = (el) => {
        setIdStaff(el[0]);
        setNameStaff(`${el[1]} - ${el[2]}`);
        setRefresh(true)
    }

    const actionSelectArea = (el) => {
        setIdArea(el[0]);
        setNameArea(`${el[1]} - ${el[2]}`);
        setRefresh(true)
    }

    const clearEmpleado = () => {
        setIdStaff("");
        setNameStaff("");
        setRefresh(true)
    }

    const clearArea = () => {
        setIdArea("");
        setNameArea("");
        setRefresh(true)
    }

    if (loading) {
        return (
            <div className="d-flex flex-column align-items-center justify-content-center" style={{ minHeight: "300px" }}>
                <Spinner animation="grow" variant="primary" />
            </div>
        )
    }
    return (
        <Widget className="widget-p-md">
            <h3 className="text-secondary">Registro de incidencias</h3>
            {permisos.INS && (
                <>
                    <Row>
                        <Col sm={12} md={4}>
                            <DateInput
                                label="Fecha de inicio"
                                inputName={"init"}
                                onChangeMethod={(date) => setInit(date.length > 0 ? new Date(date[0]) : "")}
                                value={init}
                            />
                        </Col>
                        <Col sm={12} md={4}>
                            <DateInput
                                label="Fecha final"
                                inputName={"end"}
                                onChangeMethod={(date) => setEnd(date.length > 0 ? new Date(date[0]) : "")}
                                value={end}
                            />
                        </Col>
                        <Col sm={12} md={4}>
                            <div className="text-right">
                                <Button onClick={agregar}>Agregar</Button>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12} md={6}>
                            <InputWithModal
                                cabeceras={cabecerasEmpleado}
                                filtro={filtroEmpleado}
                                actionSelect={actionSelectEmpleado}
                                onClear={clearEmpleado}
                                parametros={paramsEmpleado}
                                value={nameStaff}
                                label="Empleado"
                                name={"idStaff"}
                                title={"Seleccionar empleado"}
                            />
                        </Col>
                        <Col sm={12} md={6}>
                            <InputWithModal
                                cabeceras={cabecerasArea}
                                filtro={filtroArea}
                                actionSelect={actionSelectArea}
                                onClear={clearArea}
                                parametros={paramsArea}
                                value={nameArea}
                                label="Unidad de trabajo"
                                name={"idArea"}
                                title={"Seleccionar Unidad de trabajo"}
                            />
                        </Col>
                    </Row>
                </>
            )}
            {permisos.CON ? (
                <Datatable
                    className="p-0 my-3"
                    columns={columns}
                    headers={headers}
                    control="back"
                    petition={getData}
                    stateRefresh={[refresh, setRefresh]}
                    columnDefs={columnDefs}
                />
            ) : (
                <div className="text-center p-4">
                    No tienes permitido ver los registros de esta seccion
                </div>
            )}
        </Widget>
    )
} 
// -- CONSULTA DATOS PERSONALES -- //
import ConsultaDatosPersonales from "../../../pages/complemento-personal/consulta-datos/Principal";
// -- Estatus empleados  -- //
import EstatusEmpleados from "../../../pages/complemento-personal/estatus-empleados/EstatusEmpleados";
// -- Formatos -- //
import FormatosPersonal from "../../../pages/complemento-personal/formatos/Principal";
import AgregarFormatosPersonal from "../../../pages/complemento-personal/formatos/Agregar";
import EditarFormatosPersonal from "../../../pages/complemento-personal/formatos/Editar";
import PermutacionEmpleados from "../../../pages/complemento-personal/permutacion/Main";

const rutasComplementoPersonal = [
    //Consulta datos personales
    { path: "/template/consultadatos", component: ConsultaDatosPersonales },
    //Estatus empleados
    { path: "/template/estatus-empleados", component: EstatusEmpleados },
    //Formatos empleados
    { path: "/template/plantillas-formatos", component: FormatosPersonal },
    { path: "/template/plantillas-formatos/agregar", component: AgregarFormatosPersonal },
    { path: "/template/plantillas-formatos/editar", component: EditarFormatosPersonal },
    //Estatus empleados
    { path: "/template/estatus-empleados", component: EstatusEmpleados },
    //Permutacion empelados
    { path: "/template/permuta-empleados", component: PermutacionEmpleados },
]

export default rutasComplementoPersonal
import { useState } from "react";
import TableComponentPersonalNoEdit from "../../../../../components/TableComponent/TableComponentPersonalNoEdit";
import { SelectTypeHeadSingle } from "../../../../../components/GenericInputsFormik";
import { doRequest } from "../../../../../helpers/requests";
import { useEffect } from "react";
import { Col, Row } from "reactstrap";


export default function Emision({finiquito, setFiniquito, permissions}){
    const [loading, setLoading] = useState(false);
    const [types, setTypes] = useState([]); 
    const [type, setType] = useState(""); 
    const [parametros] = useState({
        action: "datatable",
        table:
        "staffaccounts A LEFT JOIN accounttypes B on (A.keyAccountType = B.keyAccountType) LEFT JOIN paymenttypes C on (A.keyPaymentType = C.keyPaymentType)   INNER JOIN banks D on (A.keyBank = D.keyBank)",
        rows: "A.idStaffAccount,ifnull(A.account,'S/A') as cuenta,ifnull(A.switfCode,'S/A'),ifnull(B.nameAccountType,'S/A'),C.namePaymentType,D.nameBank,IF(A.enabled=1,'SI','NO') as enabled,A.idStaffAccount,A.keyPaymentType,A.keyBank,A.keyAccountType",
        conditions: `idStaff=${finiquito.idStaff}`,
        page: 0,
        records: 5,
        search: "",
        order: "A.created desc",
    });

    useEffect(()=>{
        getTypes();
    }, []);

    async function getTypes(){
        const params = {
            action: "select",
            table: "paymenttypes",
            order: "namePaymentType ASC",
        }
        const res = await doRequest("receiver/receiver.php", params, false);
        if(res.length > 0) setTypes(res);
    }

    async function emitirPago(){
        const params = {
            action: "update",
            table: "settlements",
            rows: {
                keyStat: 157
            },
            condition: { idSettlement: finiquito.id }
        }
        setLoading(true);
        const res = await doRequest("receiver/receiver.php", params, true);
        if(res.length > 0) setFiniquito({ ...finiquito, keyStatSettlement: 157 });
        setLoading(false);
    }

    useEffect(()=>{
        if(type) emitirPago();
    }, [type]);

    return(
        <div>
            {
                permissions.UPD && +finiquito.keyStatSettlement < 156 && 
                <Row>
                    <Col xs={12} md={6}>
                        <SelectTypeHeadSingle
                            label="Tipo de pago"
                            isRequired={true}
                            inputName="keyState"
                            optionsArray={types}
                            defaultOption="Seleccione un tipo de pago"
                            onChangeMethod={({target})=>setType(target.value)}
                            value={type}
                            optionValue="keyPaymentType"
                            optionName="namePaymentType"
                        />
                    </Col>
                </Row>
            }
            <br />
            {
                permissions.CON && 
                <TableComponentPersonalNoEdit
                    cabecerasTabla={["Id", "Cuenta", "Codigo", "Tipo de cuenta", "Tipo de pago", "Banco", "Vigente"]}
                    filtro={[true, true, true, true, true, true, true]}
                    parametros={parametros}
                />
            }
        </div>
    )
}
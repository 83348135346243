import React from "react";
import { useState } from "react";
import { Col, Row, Button } from "reactstrap";
import FullFormLoader from "../../../components/Loader/FullFormLoader";
import ModalReports from "../../../components/Modal/ModalReports";
import { peticionesReceiver } from "../../../helpers/peticionesReceiver";
import { toast } from "react-toastify";
import Notification from "../../../components/Notification/Notification";
import {generatePdfPresupuestoAutorizados} from "./PdfPresupuestoAutorizado" 


const Reportes = (props) => {
  const { idArea, nameArea, textYear } = props;

  const [modalReports, setModalReports] = useState(false);
  const [report, setReport] = useState("");
  const [loader, setLoader] = useState(false);
  const [pdfConfig, setPdfConfig] = useState("");
  const API = peticionesReceiver();

  

  const getReport = async () => {
    setPdfConfig({
      area: nameArea,
      textYear: textYear
    });
    setReport("");
    const params = {
        action: "getAreaChapters",
        idArea: idArea
      };
    let report = null;
    await API.peticionEndPoint(params,"app/facades/budgets/budgetsF.php")
      .then((res) => {
        if (
          res.status === 200 &&
          res.data.code === "200" &&
          res.data.message === "Se ejecuto correctamente la consulta"
        ) {
          setReport(res.data.data);
          report = res.data.data;
        } else {
          if(res.data.data.length < 1 ){
            toast( 
              <Notification
                type={"consultar_error"}
                backMessage={"No se encontraron datos"}
                withIcon
              />,
              { closeButton: false }
            );
              
          }else{
            toast( 
              <Notification
                type={"consultar_error"}
                backMessage={res.data.message}
                withIcon
              />,
              { closeButton: false }
            );
          }
          
        }
      })
      .finally(() => {
        setLoader(false);
      })
      .catch((err) => {
        toast(<Notification type={"consultar_servidor_error"} withIcon />, {
          closeButton: false,
        });
      });

    return report;
  };

  const generatePdf = async () => {
    const rep = await getReport();
    if (rep !== null && rep !== undefined && rep !== "") {
      setModalReports(true);
    }
  };

  
  return (
    <div>
      <Row className="mt-5 d-flex justify-content-around">
        <Col xs="12" lg="4" className="text-center mt-3 mb-3">
          <Button
            color="success"
            onClick={() => {
              generatePdf();
            }}
          >
            Generar Pdf
          </Button>
        </Col>
        
      </Row>

      <FullFormLoader
        show={loader}
        message="Consultando datos , espere un momento"
      />

      {modalReports && report !== "" && (
        <ModalReports
          modal={modalReports}
          setModal={setModalReports}
          backdrop={"static"}
          keyboard={false}
          report={report}
          pdfConfig={pdfConfig}
          generatePdfMethod={{
            method: generatePdfPresupuestoAutorizados,
            type: "general",
          }}
          records={1000}
        />
      )}
    </div>
  );
};

export default Reportes;

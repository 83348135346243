import React, { useState } from "react";
import TableComponentOnePage from "../../../components/TableComponent/TableComponentOnePage";

export const ConsultarCheques = ({ idBankAccount, permissions }) => {
  const cabeceras = ["#","Chequera","Cheque","Beneficiario","Status"]
  const filtro = [true,true,true,true,true]

  const [parametros] = useState({
    action: "datatable",
    table: `bankcheks AS BNC
    INNER JOIN staffaccounts AS STA
    ON STA.idStaffAccount = BNC.idStaffAccount
    INNER JOIN staff AS STF 
    ON STF.idStaff = STA.idStaff
    INNER JOIN checkbooks AS CHB
    ON CHB.idCheckBook = BNC.idCheckBook`,
    rows: `CHB.checkBookNumber,BNC.numberCheck,CONCAT(STF.name,' ',STF.pName,' ',STF.mName) AS Name`,
    conditions:`BNC.enabled = 1 AND CHB.idBankAccount = ${idBankAccount}`,
    page:0,
    records:5,
    search:"",
    order:""
  })

  return (
    <div className="mt-4">
      <TableComponentOnePage
        titulo="Cheques"
        cabecerasTabla={cabeceras}
        filtro={filtro}
        parametros={parametros}
        eliminar = {false}
        permissions={permissions}
      />
    </div>
  );
};
export default ConsultarCheques;

import React, { useState, useEffect } from "react";
import Notification from "../../../../../components/Notification/Notification";
import { toast } from "react-toastify";
import { peticionesReceiver } from "../../../../../helpers/peticionesReceiver";
import Widget from "../../../../../components/Widget/Widget";
import TableComponentCustom from "../../../../../components/TableComponent/TableComponentCustom";
import { Col, Row, Button } from "reactstrap";
import QRCode from "qrcode.react";
import { generatePdfPensiones } from "./pdf/PdfPensiones";
import ModalReports from "../../../../../components/Modal/ModalReports";

const API = peticionesReceiver();

const Pensiones = (props) => {
  const { data } = props;
  const [report, setReport] = useState("");
  const [modalReports, setModalReports] = useState(false);
  const [leyendMessage, setLegendMessage] = useState(false);
  const [pdfConfig, setPdfConfig] = useState({});

  const cabeceras = [
    "Numero de pension",
    "Nombre de beneficiario",
    "Periodo",
    "Año",
    "Tipo de pension",
    "Inicio",
    "Fin",
  ];

  const consultaGeneral = {
    action: "datatable",
    table:
      "payslips p inner join pensions pe on (pe.idPension = p.idGeneral) inner join types_pensions tp on (pe.keyTypePension = tp.keyTypePension) inner join unders un on (p.idUnder = un.idUnder)",
    rows: "pe.numberPension,concat(pe.name, ' ', pe.pName, ' ', pe.mName)as nombre,p.period,p.year,tp.nameTypePension,pe.startDate,pe.endDate,pe.account,p.amount,p.idUnder,p.amount,un.nameUnder,pe.idStaff,p.idPayslip,un.under",
    conditions: `period=${data.period} and year=${data.year} and keyTypePayslip=5 and p.keyStat=52 and p.idStaff=${data.idStaff}`,
    page: 0,
    records: 5,
    search: "",
    order: "under ASC",
  };
  const filtro = [
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
  ];
  const consultaPensionStaff = {
    action: "datatable",
    table:
      "payslips p inner join pensions pe on (pe.idPension = p.idGeneral) inner join types_pensions tp on (pe.keyTypePension = tp.keyTypePension) inner join unders un on (p.idUnder = un.idUnder)",
    rows: "pe.numberPension,concat(pe.name, ' ', pe.pName, ' ', pe.mName)as nombre,p.period,p.year,tp.nameTypePension,pe.startDate,pe.endDate,pe.account,p.amount,p.idUnder,p.amount,un.nameUnder,pe.idStaff,p.idPayslip,un.under",
    conditions: `period=${data.period} and year=${data.year} and keyTypePayslip=5 and p.keyStat=52 and p.idStaff=${data.idStaff} and pe.idPension=${data.idPension}`,
    page: 0,
    records: 5,
    search: "",
    order: "under ASC",
  };
  const consultaPension = {
    action: "datatable",
    table:
      "payslips p inner join pensions pe on (pe.idPension = p.idGeneral) inner join types_pensions tp on (pe.keyTypePension = tp.keyTypePension) inner join unders un on (p.idUnder = un.idUnder)",
    rows: "pe.numberPension,concat(pe.name, ' ', pe.pName, ' ', pe.mName)as nombre,p.period,p.year,tp.nameTypePension,pe.startDate,pe.endDate,pe.account,p.amount,p.idUnder,p.amount,un.nameUnder,pe.idStaff,p.idPayslip,un.under",
    conditions: `period=${data.period} and year=${data.year} and keyTypePayslip=5 and p.keyStat=52 and pe.numberPension=${data.idPension}`,
    page: 0,
    records: 5,
    search: "",
    order: "under ASC",
  };
  const parametros =
    data.idPension === ""
      ? consultaGeneral
      : data.idStaff === ""
      ? consultaPension
      : consultaPensionStaff;
  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const cleanParams = () => {
    let params = parametros;
    params.action = "multiselect";
    delete params.page;
    delete params.records;
    delete params.order;
    delete params.search;
    return params;
  };

  const getData = () => {
    const params2 = {
      action: "multiselect",
      table: "advertisements",
      rows: "*",
      conditions: `period=${data.period} and enabled = 1 and year=${data.year} and aplicationDate <= NOW()`,
    };

    API.peticion(params2)
      .then((res2) => {
        if (
          res2.status === 200 &&
          res2.data.code === "200" &&
          res2.data.data.length > 0
        ) {
          setLegendMessage(false);
        } else {
          setLegendMessage(true);
        }

        let params = cleanParams();
        API.peticion(params)
          .then((res) => {
            if (res.status === 200 && res.data.code === "200") {
              setReport(res.data.data);
              let totales = { totalPer: 0, totalDeduc: 0 };
              res.data.data.forEach((obj) => {
                if (obj.keyTypeUnder === "1") {
                  totales.totalPer += +obj.amount;
                } else {
                  totales.totalDeduc += +obj.amount;
                }
              });
            } else {
              toast(
                <Notification
                  type={"consultar_error"}
                  backMessage={res.data.message}
                  withIcon
                />,
                { closeButton: false }
              );
            }
          })
          .catch((err) => {
            toast(<Notification type={"consultar_servidor_error"} withIcon />, {
              closeButton: false,
            });
          });
      })
      .catch((err) => {
        toast(<Notification type={"consultar_servidor_error"} withIcon />, {
          closeButton: false,
        });
      });
  };

  const crearPdf = () => {
    generatedQrImagePdfConfig()
  };

  const generatedQrImagePdfConfig = () => {
    const canvas = document.getElementById("123456");
    const pngUrl = canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    setPdfConfig({
      period: `${data.period}/${data.year} `,
      pngUrl: pngUrl,
    });
    setModalReports(true);
  };

  return (
    <div>
      {leyendMessage && (
        <div className="text-center mb-5 ">
          <span className="headline-1" style={{ color: "red" }}>
            {`Aún no se ha liberado el comprobante correspondiente al periodo ${data.period} y año  ${data.year}`}{" "}
          </span>
        </div>
      )}

      <Row className="d-flex  justify-content-end">
        <Col
          xs={12}
          md={12}
          lg={6}
          className="d-flex flex-column justify-content-center"
        >
          <Col className="text-center">
            <Button
              color="success"
              className="text-center"
              onClick={() => crearPdf()}
              disabled={
                report !== "" && report.length === 1 && report !== undefined
                  ? false
                  : true
              }
            >
              Generar Pdf
            </Button>
          </Col>
        </Col>
      </Row>

      <Widget className="widget-p-md">
        <Row className="mb-4">
          <Col>
            <div className="widget-table-overflow mt-3">
              <TableComponentCustom
                titulo="Pensiones"
                cabecerasTabla={cabeceras}
                filtro={filtro}
                parametros={parametros}
                addNew={false}
                addDelete={false}
                addEdit={false}
              />
              {/* <Table
                className={`table-striped table-borderless table-hover ${s.statesTable}`}
                responsive
              >
                <thead className="border-bottom border-secondary">
                  <tr className={sc.userSelectNone}>
                    <th className="text-center">Clabe</th>
                    <th className="text-center">Concepto</th>
                    <th className="text-center">Percepciones</th>
                    <th className="text-center">Deducciones</th>
                  </tr>
                </thead>
                <tbody>
                  {loading ? (
                    <tr key={"loading"}>
                      <td colSpan={4}>
                        <Loader />
                      </td>
                    </tr>
                  ) : report === "" ? (
                    <tr key={"nothing"}>
                      <td colSpan={4} className="text-center">
                        No se encontraron registros
                      </td>
                    </tr>
                  ) : (
                    report.length !== "" &&
                    report.map((registro, index) => (
                      <tr key={"R" + index}>
                        <td className="text-center">{registro.idPayslip}</td>
                        <td className="text-center">{registro.nameUnder}</td>
                        <td className="text-center">
                          {registro.keyTypeUnder === "1"
                            ? formatter.format(registro.amount)
                            : 0}
                        </td>
                        <td className="text-center">
                          {registro.keyTypeUnder !== "1"
                            ? formatter.format(registro.amount)
                            : 0}
                        </td>
                      </tr>
                    ))
                  )}

                  <tr>
                    <td colSpan={2} className="text-center">
                      Total
                    </td>
                    <td className="text-center">
                      {formatter.format(totalPerceptions)}
                    </td>
                    <td className="text-center">
                      {formatter.format(totalDeductions)}
                    </td>
                  </tr>
                </tbody>
              </Table> */}
            </div>
          </Col>
        </Row>
        <ModalReports
          modal={modalReports}
          setModal={setModalReports}
          title={"REPORTE DE PENSIONES"}
          backdrop={"static"}
          keyboard={false}
          report={report}
          pdfConfig={pdfConfig}
          generatePdfMethod={{
            method: generatePdfPensiones,
            type: "pensiones",
          }}
          records={1000}
        />

        <div style={{ display: "none" }}>
          <QRCode
            id="123456"
            value="https://fgjem.edomex.gob.mx/"
            size={290}
            level={"H"}
            includeMargin={true}
          />
        </div>
      </Widget>
    </div>
  );
};
export default Pensiones;

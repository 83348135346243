import { useEffect, useState } from "react";
import { Col, Container, Row, Button } from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";

import { peticionesReceiver } from "../../../../helpers/peticionesReceiver";
import ModalConfirmation from "../../../../components/Modal/ModalConfirmation";
import Notification from "../../../../components/Notification/Notification";
import {
  SelectTypeHeadSingle,
  TextInput,
  CurrencyInput,
  TextAreaInput,
} from "../../../../components/GenericInputsFormik";
import { NumberInput } from "../../../../components/GenericInputsFormik/NumberInput";
import Loader from "../../../../components/Loader/Loader";
import { OnCreate, OnEdit } from "../functions";
import { useHistory } from "react-router";

export default function DatosInmueble({ data }) {
  const history = useHistory()
  const API = peticionesReceiver();

  const [tab, setTab] = useState(0);

  //States
  const [modal, setModal] = useState(false);
  const [keyGroup, setKeyGroup] = useState("");
  const [keySubGroup, setKeySubGroup] = useState("");
  const [keyClass, setKeyClass] = useState("");

  //Data
  const [loadingBuilds, setLoadingBuilds] = useState(true);
  const [builds, setBuilds] = useState([]);
  const [loadingGroups, setLoadingGroups] = useState(true);
  const [groups, setGroups] = useState([]);
  const [loadingSubgroups, setLoadingSubgroups] = useState(false);
  const [subgroups, setSubgroups] = useState([]);
  const [loadingClasses, setLoadingClasses] = useState(false);
  const [classes, setClasses] = useState([]);
  const [loadingSubclasses, setLoadingSubclasses] = useState(false);
  const [subclasses, setSubclasses] = useState([]);
  const [loadingTypeProperties, setLoadingTypeProperties] = useState(false);
  const [typeProperties, setTypeProperties] = useState([]);
  const [loadingStaff, setLoadingStaff] = useState(false);
  const [staff, setStaff] = useState([]);

  const [loading, setLoading] = useState(true);

  //EDITAR
  const [loadingData, setLoadingData] = useState(true);

  //FORM
  const defaultForm = {
    idProperty: data ? data.idProperty : "",
    idBuilding: "",
    keyGroup: "",
    keySubGroup: "",
    keyClass: "",
    keySubClass: "",
    keyTypeProperty: "",
    seller: "",
    registerNumber: "",
    surface: "",
    cost: "",
    titleDescription: "",
    levels: "0",
    baths: "0",
    atms: "0",
    elevators: "0",
    idStaffCharge: "",
    phone: "",
    email: "",
    notes: "",
  };

  //GET DATA
  async function getDataEdificios() {
    const params = {
      action: "multiselect",
      table: "buildings A INNER JOIN cities B ON A.keyCity = B.keyCity",
      rows: "A.idBuilding,A.nameBuilding",
      conditions: `A.enabled = 1 AND B.enabled = 1 AND A.idBuilding NOT IN (SELECT idProperty FROM properties)`,
    };
    const res = await API.peticion(params);
    if (res.status === 200 && res.data.code === "200") {
      const data = res.data.data;
      setBuilds(data);
      setLoadingBuilds(false);
    } else {
      setBuilds([]);
      toast(<Notification type={"consultar_error"} withIcon />);
    }
  }

  async function getDataGrupo() {
    const params = {
      action: "multiselect",
      table: "property_groups",
      rows: "keyGroup,nameGroup",
      conditions: `enabled = 1`,
    };
    const res = await API.peticion(params);
    if (res.status === 200 && res.data.code === "200") {
      const data = res.data.data;
      setGroups(data);
      setLoadingGroups(false);
    } else {
      setGroups([]);
      toast(<Notification type={"consultar_error"} withIcon />);
    }
  }

  async function getDataSubGrupo(keyGroup, all) {
    setLoadingSubgroups(true);
    const params = {
      action: "multiselect",
      table: "property_subgroups",
      rows: "keySubGroup,nameSubGroup",
      conditions: !all
        ? `enabled = 1 AND keyGroup = ${keyGroup}`
        : `enabled = 1`,
    };
    const res = await API.peticion(params);
    if (res.status === 200 && res.data.code === "200") {
      const data = res.data.data;
      setSubgroups(data);
      setLoadingSubgroups(false);
    } else {
      setSubgroups([]);
      toast(<Notification type={"consultar_error"} withIcon />);
    }
  }

  async function getDataClases(keySubGroup, all) {
    setLoadingClasses(true);
    const params = {
      action: "multiselect",
      table: "property_classes",
      rows: "keyClass,nameClass",
      conditions: !all
        ? `enabled = 1 AND keySubGroup = ${keySubGroup}`
        : `enabled = 1`,
    };
    const res = await API.peticion(params);
    if (res.status === 200 && res.data.code === "200") {
      const data = res.data.data;
      setClasses(data);
      setLoadingClasses(false);
    } else {
      setClasses([]);
      toast(<Notification type={"consultar_error"} withIcon />);
    }
  }

  async function getDataSubClases(keyClass, all) {
    setLoadingSubclasses(true);
    const params = {
      action: "multiselect",
      table: "property_subclasses",
      rows: "keySubClasses,nameSubClass",
      conditions: !all
        ? `enabled = 1 AND keyClass = ${keyClass}`
        : `enabled = 1`,
    };
    const res = await API.peticion(params);
    if (res.status === 200 && res.data.code === "200") {
      const data = res.data.data;
      setSubclasses(data);
      setLoadingSubclasses(false);
    } else {
      setSubclasses([]);
      toast(<Notification type={"consultar_error"} withIcon />);
    }
  }

  async function getTypesProperty() {
    const params = {
      action: "multiselect",
      table: "types_property",
      rows: "keyTypeProperty,nameTypeProperty",
      conditions: `enabled = 1`,
    };
    const res = await API.peticion(params);
    if (res.status === 200 && res.data.code === "200") {
      const data = res.data.data;
      setTypeProperties(data);
      setLoadingTypeProperties(false);
    } else {
      setTypeProperties([]);
      toast(<Notification type={"consultar_error"} withIcon />);
    }
  }

  async function getStaff() {
    const params = {
      action: "multiselect",
      table: "staff",
      rows: "idStaff,CONCAT(name,' ',mName,' ',pName) as name",
      conditions: `enabled = 1`,
    };
    const res = await API.peticion(params);
    if (res.status === 200 && res.data.code === "200") {
      const data = res.data.data;
      setStaff(data);
      setLoadingStaff(false);
    } else {
      setLoadingStaff([]);
      toast(<Notification type={"consultar_error"} withIcon />);
    }
  }

  useEffect(() => {
    if (groups.length === 0) {
      getDataEdificios();
      getDataGrupo();
      getTypesProperty();
      getStaff();
    } else {
      if (keyGroup) {
        getDataSubGrupo(keyGroup, loadingData && data);
        if (keySubGroup) {
          getDataClases(keySubGroup, loadingData && data);
          if (keyClass) {
            getDataSubClases(keyClass, loadingData && data);
          }
        } else {
          setKeyClass("");
          setKeySubGroup("");
        }
      } else {
        setKeySubGroup("");
        setKeyClass("");
        setKeySubGroup("");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groups, keyGroup, keySubGroup, keyClass]);

  async function getidClassGroup(id) {
    const params = {
      action: "multiselect",
      table:
        "property_groups pg join property_subgroups ps on pg.keyGroup=ps.keyGroup join property_classes pc on pc.keySubGroup=ps.keySubGroup join property_subclasses psc on psc.keyClass=pc.keyClass",
      rows: "pg.keyGroup,ps.keySubGroup,pc.keyClass",
      conditions: `psc.keySubClasses=${id}`,
    };
    const res = await API.peticion(params);
    if (res.status === 200 && res.data.code === "200") {
      const data = res.data.data[0];
      setFieldValue(["keyGroup"], data.keyGroup);
      setKeyGroup(data.keyGroup);
      setFieldValue(["keySubGroup"], data.keySubGroup);
      setKeySubGroup(data.keySubGroup);
      setFieldValue(["keyClass"], data.keyClass);
      setKeyClass(data.keyClass);
      setFieldValue(["keySubClass"], id);
      setLoadingData(false);
      setLoading(false);
    } else {
      toast(<Notification type={"consultar_error"} withIcon />);
    }
  }

  async function getData(id) {
    const params = {
      action: "multiselect",
      table: "properties",
      rows: "*",
      conditions: `enabled = 1 AND idProperty = ${id}`,
    };
    const res = await API.peticion(params);
    if (res.status === 200 && res.data.code === "200") {
      const data = res.data.data[0];
      setFieldValue(["keyTypeProperty"], data.keyTypeProperty);
      setFieldValue(["idBuilding"], data.idBuilding);
      setFieldValue(["seller"], data.seller);
      setFieldValue(["cost"], data.cost);
      setFieldValue(["registerNumber"], data.registerNumber);
      setFieldValue(["surface"], data.surface);
      setFieldValue(["titleDescription"], data.titleDescription);
      setFieldValue(["levels"], data.levels);
      setFieldValue(["elevators"], data.elevators);
      setFieldValue(["baths"], data.baths);
      setFieldValue(["atms"], data.atms);
      setFieldValue(["phone"], data.phone);
      setFieldValue(["idStaffCharge"], data.idStaffCharge);
      setFieldValue(["email"], data.email);
      setFieldValue(["notes"], data.notes);
      getidClassGroup(data.keySubClass);
    } else {
      setTimeout(() => {
        toast(<Notification type={"consultar_error"} withIcon />);
        history.push("/template/bienesinmuebles")
      }, 1000);
    }
  }

  useEffect(() => {
    if (data) {
      if (
        !loadingGroups &&
        !loadingSubgroups &&
        !loadingClasses &&
        !loadingSubclasses &&
        !loadingBuilds &&
        !loadingTypeProperties &&
        !loadingStaff
      ) {
        getData(data.idProperty);
      }
    } else {
      setLoading(
        loadingGroups &&
          loadingSubgroups &&
          loadingClasses &&
          loadingSubclasses &&
          loadingBuilds &&
          loadingTypeProperties &&
          loadingStaff
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    loadingGroups,
    loadingSubgroups,
    loadingClasses,
    loadingBuilds,
    loadingTypeProperties,
    loadingData,
    loadingStaff,
  ]);

  //FORM
  const FormSchema = Yup.object().shape({
    idProperty: Yup.number(),
    idBuilding: Yup.number().required("Seleccione un edificio"),
    keyGroup: Yup.number().required("Seleccione un grupo valido"),
    keySubGroup: Yup.number().required("Seleccione un subgrupo valido"),
    keyClass: Yup.number().required("Seleccione una clase valida"),
    keySubClass: Yup.number().required("Seleccione una clase valida"),
    keyTypeProperty: Yup.number().required(
      "Seleccione un tipo de propiedad valido"
    ),
    seller: Yup.string().required("Ingrese un vendedor o donador valido"),
    registerNumber: Yup.number().required("Ingrese una clave valida"),
    surface: Yup.number().required("Ingrese una superficie valida"),
    cost: Yup.number().required("Ingrese una costo"),
    titleDescription: Yup.string().required("Ingrese una escritura"),
    levels: Yup.number(),
    baths: Yup.number(),
    atms: Yup.number(),
    elevators: Yup.number(),
    idStaffCharge: Yup.number(),
    phone: Yup.number().max(
      9999999999,
      "El numero de telefono tiene como maximo 10 digitos"
    ),
    email: Yup.number(),
    notes: Yup.string(),
  });

  const { handleSubmit, values, handleBlur, errors, touched, setFieldValue } =
    useFormik({
      initialValues: defaultForm,
      onSubmit: () => setModal(true),
      validationSchema: FormSchema,
    });

  const onChange = (e) => {
    const { name, value } = e.target;
    setFieldValue([name], value);
    switch (name) {
      case "keyGroup":
        setKeyGroup(value);
        break;
      case "keySubGroup":
        setKeySubGroup(value);
        break;
      case "keyClass":
        setKeyClass(value);
        break;
      default:
        break;
    }
  };

  if (loading) {
    return (
      <Row className="align-items-center" style={{ minHeight: "200px" }}>
        <Col>
          <Loader />
        </Col>
      </Row>
    );
  }
  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className="accordion" id="accordionDatosInmueble">
          <div className="card">
            <div
              className={`card-header bg-secondary p-0 ${
                tab === 0 ? "rounded-top" : "rounded"
              }`}
              id="datosGen"
            >
              <h2 className="mb-0">
                <button
                  className={`btn btn-link btn-block text-left text-white ${
                    tab === 0 ? "collapsed" : ""
                  }`}
                  style={{ fontSize: "12pt" }}
                  type="button"
                  onClick={() => (tab === 0 ? setTab(-1) : setTab(0))}
                >
                  Datos generales
                </button>
              </h2>
            </div>
            <div
              id="collapseDatosG"
              className={`collapse ${tab === 0 ? "show" : ""}`}
              aria-labelledby="datosGen"
              data-parent="#accordionDatosInmueble"
            >
              <div className="card-body">
                <Container>
                  <Row className="justify-content-center">
                    <Col xs={12} md={6}>
                      <SelectTypeHeadSingle
                        label="Edificio"
                        inputName="idBuilding"
                        onChangeMethod={onChange}
                        onBlurMethod={handleBlur}
                        value={values.idBuilding}
                        optionsArray={builds}
                        optionValue="idBuilding"
                        optionName="nameBuilding"
                        errors={errors.idBuilding}
                        touched={touched.idBuilding}
                        isRequired
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} md={6}>
                      <SelectTypeHeadSingle
                        label="Grupo"
                        inputName="keyGroup"
                        onChangeMethod={onChange}
                        onBlurMethod={handleBlur}
                        value={values.keyGroup}
                        optionsArray={groups}
                        optionValue="keyGroup"
                        optionName="nameGroup"
                        errors={errors.keyGroup}
                        touched={touched.keyGroup}
                        isRequired
                      />
                    </Col>
                    <Col xs={12} md={6}>
                      <SelectTypeHeadSingle
                        label="Subgrupo"
                        inputName="keySubGroup"
                        onChangeMethod={onChange}
                        onBlurMethod={handleBlur}
                        value={values.keySubGroup}
                        optionsArray={subgroups}
                        optionValue="keySubGroup"
                        optionName="nameSubGroup"
                        errors={errors.keySubGroup}
                        touched={touched.keySubGroup}
                        isRequired
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} md={6}>
                      <SelectTypeHeadSingle
                        label="Clase"
                        inputName="keyClass"
                        onChangeMethod={onChange}
                        onBlurMethod={handleBlur}
                        value={values.keyClass}
                        optionsArray={classes}
                        optionValue="keyClass"
                        optionName="nameClass"
                        errors={errors.keyClass}
                        touched={touched.keyClass}
                        isRequired
                      />
                    </Col>
                    <Col xs={12} md={6}>
                      <SelectTypeHeadSingle
                        label="Subclase"
                        inputName="keySubClass"
                        onChangeMethod={onChange}
                        onBlurMethod={handleBlur}
                        value={values.keySubClass}
                        optionsArray={subclasses}
                        optionValue="keySubClasses"
                        optionName="nameSubClass"
                        errors={errors.keySubClass}
                        touched={touched.keySubClass}
                        isRequired
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} md={4}>
                      <SelectTypeHeadSingle
                        label="Tipo de propiedad"
                        inputName="keyTypeProperty"
                        onChangeMethod={onChange}
                        onBlurMethod={handleBlur}
                        value={values.keyTypeProperty}
                        optionsArray={typeProperties}
                        optionValue="keyTypeProperty"
                        optionName="nameTypeProperty"
                        errors={errors.keyTypeProperty}
                        touched={touched.keyTypeProperty}
                        isRequired
                      />
                    </Col>
                    <Col xs={12} md={4}>
                      <TextInput
                        label="Vendedor o donante"
                        inputType="text"
                        inputName="seller"
                        onChangeMethod={onChange}
                        onBlurMethod={handleBlur}
                        value={values.seller}
                        isRequired
                        touched={touched.seller}
                        errors={errors.seller}
                      />
                    </Col>
                    <Col xs={12} md={4}>
                      <NumberInput
                        label="Clave catastral"
                        inputName="registerNumber"
                        onChangeMethod={onChange}
                        onBlurMethod={handleBlur}
                        value={values.registerNumber}
                        errors={errors.registerNumber}
                        touched={touched.registerNumber}
                        isRequired
                        disableSep
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} md={4}>
                      <NumberInput
                        label="Superficie"
                        inputName="surface"
                        onChangeMethod={onChange}
                        onBlurMethod={handleBlur}
                        value={values.surface}
                        isRequired
                        touched={touched.surface}
                        errors={errors.surface}
                      />
                    </Col>
                    <Col xs={12} md={4}>
                      <CurrencyInput
                        label="Costo"
                        inputName="cost"
                        onChangeMethod={(value, name) =>
                          onChange({ target: { name: name, value: value } })
                        }
                        onBlurMethod={handleBlur}
                        value={values.cost}
                        isRequired
                        touched={touched.cost}
                        errors={errors.cost}
                      />
                    </Col>
                    <Col xs={12} md={4}>
                      <TextInput
                        label="Escritura"
                        inputType="text"
                        inputName="titleDescription"
                        onChangeMethod={onChange}
                        onBlurMethod={handleBlur}
                        value={values.titleDescription}
                        isRequired
                        touched={touched.titleDescription}
                        errors={errors.titleDescription}
                      />
                    </Col>
                  </Row>
                </Container>
              </div>
            </div>
          </div>
          <div className="card">
            <div
              className={`card-header bg-secondary p-0 ${
                tab === 1 ? "rounded-top" : "rounded"
              }`}
              id="caract"
            >
              <h2 className="mb-0">
                <button
                  className={`btn btn-link btn-block text-left text-white ${
                    tab === 1 ? "collapsed" : ""
                  }`}
                  style={{ fontSize: "12pt" }}
                  type="button"
                  onClick={() => (tab === 1 ? setTab(-1) : setTab(1))}
                >
                  Caracteristicas
                </button>
              </h2>
            </div>
            <div
              id="collapseCaract"
              className={`collapse ${tab === 1 ? "show" : ""}`}
              aria-labelledby="caract"
              data-parent="#accordionDatosInmueble"
            >
              <div className="card-body">
                <Row>
                  <Col xs={12} md={3}>
                    <NumberInput
                      label="Niveles"
                      inputName="levels"
                      onChangeMethod={onChange}
                      onBlurMethod={handleBlur}
                      value={values.levels}
                      touched={touched.levels}
                      errors={errors.levels}
                    />
                  </Col>
                  <Col xs={12} md={3}>
                    <NumberInput
                      label="Baños"
                      inputName="baths"
                      onChangeMethod={onChange}
                      onBlurMethod={handleBlur}
                      value={values.baths}
                      touched={touched.baths}
                      errors={errors.baths}
                    />
                  </Col>
                  <Col xs={12} md={3}>
                    <NumberInput
                      label="Cajeros"
                      inputName="atms"
                      onChangeMethod={onChange}
                      onBlurMethod={handleBlur}
                      value={values.atms}
                      touched={touched.atms}
                      errors={errors.atms}
                    />
                  </Col>
                  <Col xs={12} md={3}>
                    <NumberInput
                      label="Elevadores"
                      inputName="elevators"
                      onChangeMethod={onChange}
                      onBlurMethod={handleBlur}
                      value={values.elevators}
                      touched={touched.elevators}
                      errors={errors.elevators}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} md={4}>
                    <SelectTypeHeadSingle
                      label="Responsable"
                      inputName="idStaffCharge"
                      onChangeMethod={onChange}
                      onBlurMethod={handleBlur}
                      value={values.idStaffCharge}
                      optionsArray={staff}
                      optionValue="idStaff"
                      optionName="name"
                      errors={errors.idStaffCharge}
                      touched={touched.idStaffCharge}
                    />
                  </Col>
                  <Col xs={12} md={4}>
                  <NumberInput
                        label="Tel. responsable"
                        inputName="phone"
                        onChangeMethod={onChange}
                        onBlurMethod={handleBlur}
                        value={values.phone}
                        errors={errors.phone}
                        touched={touched.phone}
                        isRequired
                        disableSep
                      />
                    {/* <TextInput
                      label="Tel. responsable"
                      inputType="tel"
                      inputName="phone"
                      onChangeMethod={onChange}
                      onBlurMethod={handleBlur}
                      value={values.phone}
                      touched={touched.phone}
                      errors={errors.phone}
                      disableSep
                    /> */}
                  </Col>
                  <Col xs={12} md={4}>
                    <TextInput
                      label="Mejoras aplicadas en el año"
                      inputType="text"
                      inputName="email"
                      onChangeMethod={onChange}
                      onBlurMethod={handleBlur}
                      value={values.email}
                      touched={touched.email}
                      errors={errors.email}
                    />
                  </Col>
                </Row>
                <TextAreaInput
                  label="Observaciones"
                  inputType="text"
                  inputName="notes"
                  inputRows={12}
                  onChangeMethod={onChange}
                  onBlurMethod={handleBlur}
                  value={values.notes}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="text-center mt-2">
          <Button color="success" type="submit">
            Guardar
          </Button>
        </div>
      </form>
      <ModalConfirmation
        modalTitle={!data ? "Agregar" : "Editar"}
        modal={modal}
        crear={(rows) => OnCreate(rows, history)}
        editar={(rows) => OnEdit(rows)}
        setModal={setModal}
        isEdit={data ? true : false}
        values={values}
      >
        <div className="d-flex justify-content-center">
          <h6>¿Desea continuar con el registro?</h6>
        </div>
      </ModalConfirmation>
    </>
  );
}

const formatter = new Intl.NumberFormat("es-MX", {
  style: "currency",
  currency: "MXN",
});

export function formatCell(elm, idCol, idRow, filtro, sortCol, header, stCol) {
  if (!filtro) {
    return null;
  }
  if (sortCol) {
    if (idCol === 0 && stCol !== false) {
      /// Primera columna a numerador
      return (
        <td className="text-center" key={"C" + idCol}>
          {sortCol.records * sortCol.currentPage + (idRow + 1)}
        </td>
      );
    }
  }
  
  const data = transformCell(elm, header)

  return (
    <td
      className={classNamesCell(data, header, elm)}
      key={"C" + idCol}
      style={{ whiteSpace: "pre-line" }}
    >
      {data}
    </td>
  );
}

function transformCell(texto, header) {
  const ft = /^\d{4}[-\/](0[1-9]|1[0-2])[-\/](0[1-9]|1[0-9]|2[0-9]|3[0-1])(\s(0[0-9]|1[0-9]|2[0-3]):([0-5][0-9]):([0-5][0-9]))?$/g; /// Fecha con tiempo
  const f = /^\d{4}[-\/](0[1-9]|1[0-2])[-\/](0[1-9]|1[0-9]|2[0-9]|3[0-1])$/g; /// Fecha simple
  const m = /^-?\d+\.\d+$/;
  const dateTimeTest = ft.test(texto);
  const dateTest = f.test(texto);
  const currTest = m.test(texto)

  if (dateTimeTest) {
    switch (header) {
      case "Fecha de registro-Solicitudes de Pago":
      case "Fecha de registro-Registro de Movimientos de la Solicitud de Pago de Nómina":
        return fDatewhitTime(texto);
      default:
        return fDateTime(texto)
    }
  } else if (dateTest) {
    return texto.split("-").reverse().toString().replaceAll(",", "/");
  } else if (currTest) {
    switch (header) {
      case "Porcentaje":
        return texto + "%";
      case "Cantidad-Actividades":
        return texto
      default:
        return formatter.format(texto);
    }
  } else {
    switch (header) {
      case "TOTAL A PAGAR-SOLICITUD DE PAGO POR TRANSFERENCIA DE DEDUCCIONES VÍA NÓMINA A TERCEROS":
        return formatter.format(texto);
      default:
        return texto;
    }
  }
}

export function classNamesCell(elm, header, elmNoClean) {
  if (isNaN(elm)) {
    const regex = /^\$\d{1,3}(,\d{3})*(\.\d{1,2})?$/
    if (regex.test(elm)) {
      return "text-right"
    } else {
      if(cabecerasCenter(header, elmNoClean)) return "text-center"; 
      else if(cabecerasRight(header, elmNoClean)) return "text-right";
    }
  } else {
    return "text-center"
  }
}


const fDate = (d) => {
  return d.split("-").reverse().toString().replaceAll(",", "/");
};

export const fDateTime = (d) => {
  return fDate(d.slice(0, 10));
};

export const fDatewhitTime = (d) => {
  const time = d.split(" ")[1];
  return fDate(d.slice(0, 10)) + " " + time;
};


function cabecerasCenter(header, val){
  const ft = /^\d{4}[-\/](0[1-9]|1[0-2])[-\/](0[1-9]|1[0-9]|2[0-9]|3[0-1])(\s(0[0-9]|1[0-9]|2[0-3]):([0-5][0-9]):([0-5][0-9]))?$/g; /// Fecha con tiempo
  return (header && ["Timepo de atención", "Tiempo de solución", "PORCENTAJE-EMPLEADOS CON EL MINIMO VITAL"].includes(header)) || ft.test(val)
}

function cabecerasRight(header, elm){
  if(elm) return /[$%]{1}/.test(elm) || !isNaN(+elm);
  else if(header){
    return ["CANTIDAD-Reporte de pensiones"].includes(header)
  }
  return false;
}
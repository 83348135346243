import { useSelector } from "react-redux";
import Widget from "../../../components/Widget/Widget";
import Consulta from "./Consulta";
import { useState } from "react";
import Detalle from "./Detalle";
import { GetPermissions } from "../../../libs/permissions/getPermissions";

export default function DevolucionesMercancia() {
    const [permissions, setPermissions] = useState({
        LOD: true,
        CON: true,
        UPD: true,
        INS: true,
        DEL: true,
    });
    GetPermissions(setPermissions);

    const [info, setInfo] = useState({ id: "" });
    const { profileType } = useSelector((state) => state.userInfo);
    const idArea = profileType.selectProfile.idArea;
    
    return (
        <Widget className="widget-p-md">
            <h3>Devolución de Mercancía</h3>

            {info.id ? (
                <Detalle info={info} setInfo={setInfo} permissions={permissions}/>
            ) : (
                <Consulta permissions={permissions} idArea={idArea} setInfo={setInfo} info={info}/>
            )}
        </Widget>
    );
}

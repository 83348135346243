import React, { useState, useEffect } from "react";
import Loader from "../Loader/Loader";
import axios from "axios";
import {
  Col,
  Row,
  Table,
  Input,
  Pagination,
  PaginationItem,
  PaginationLink,
  Button,
} from "reactstrap";
import s from "../../pages/tables/Tables.module.scss";
import { useHistory } from "react-router-dom";
import sc from "./TableComponentFiltro.module.scss";
import { toast } from "react-toastify";
import Notification from "../Notification/Notification";
import { peticionEncript } from "../../helpers/peticionesEncripted";
import getActions from "../../helpers/getPermissionForms";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { formatCell } from "./cellsFormat";
import ModalComponentEliminar from "../../components/Modal/ModalDelete";
import getRouters from "../Layout/function";

export const TableComponentActions = (props) => {
  const {
    titulo,
    cabecerasTabla,
    filtro,
    parametros, //sobre que tabla hacer el select
    createFn,
    updateFn,
    deleteFn,
    watchFn,
    specialFn,
    specialTxt,
    specialIco,
    showTitulo,
  } = props;
  const colsBdNames = parametros.rows.split(","); // Columnas del back
  const nameTable = parametros.table.split(" ");
  const nameColumn = colsBdNames[0].split(".");
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [tableContent, setTableContent] = useState([]); // Información mostrada en la tabla
  const [pageSize, setPageSize] = useState(5); // Tamaño de las paginas
  const [totalRegistros, setTotalRegistros] = useState(0); // Total de registros en BD
  const [pagesCount, setPagesCount] = useState(0); // Cantidad total de paginas
  const [seccionActual, setSeccionActual] = useState(0); // Seccion actual
  const [sortCol, setSortCol] = useState({
    //Parametros para aplicar filtros a la busqueda
    direction: "",
    column: "",
    page: "",
    currentPage: 0,
    search: "",
    records: 5,
  });

  const [permissionsForm, setPermissionsForm] = useState("");

  const settableContentPage = (e, index) => {
    if (index + 1 > (seccionActual + 1) * 5) {
      setSeccionActual(seccionActual + 1);
    } else if (seccionActual != 0 && index + 1 <= seccionActual * 5) {
      setSeccionActual(seccionActual - 1);
    }
    e.preventDefault();
    setSortCol((prevState) => ({
      ...prevState,
      page: pageSize * index,
      currentPage: index,
    }));
  };

  const mapea = (element, indexCol, indexRow) => {
    // if (indexCol === 0) {
    //   return (
    //     <td className="text-center">
    //       {5 * sortCol.currentPage + (indexRow + 1)}
    //     </td>
    //   );
    // }
    // if (filtro[indexCol]) {
    //   const reg = /\d{4}-\d{2}-\d{2}/g;
    //   let texto = element;
    //   if (reg.test(element)) {
    //     texto = element.split("-").reverse().toString().replaceAll(",", "/");
    //   }
    //   return <td className="text-center">{texto}</td>;
    // } else {
    //   return <></>;
    // }
    
    if(filtro !== null){
      return formatCell(element, indexCol, indexRow, filtro[indexCol], sortCol, cabecerasTabla[indexCol]+"-"+titulo);
    }
    return formatCell(element, indexCol, indexRow, true, sortCol, cabecerasTabla[indexCol]+"-"+titulo);
  };

  const sortTable = (cabecera, indexHeader) => {
    if (
      ![
        "Editar",
        "Eliminar",
        "Id",
        "Ver",
        "Seleccionar",
        "Modificar",
        "FUMP",
      ].includes(cabecera)
    ) {
      if (sortCol.column !== indexHeader) {
        setSortCol((ps) => ({ ...ps, column: indexHeader, direction: "asc" }));
      } else {
        setSortCol((ps) => ({
          ...ps,
          direction:
            sortCol.direction === "asc"
              ? "desc"
              : sortCol.direction === "desc"
              ? ""
              : "asc",
        }));
      }
    }
  };

  const getData = async (orderOb,isDelete) => {
    //LA PRIMERA VEZ QUE ENTRAMOS VIENE UN ORDEROB = EMPTY Y EN EL PARAMS = ORDER = "", PAGE = 0 Y RECORDS = 5
    //CUANDO SELECCIONO EL HEADER VIENE ORDEROB = COLUMN = 1 Y DIRECTION = ASC Y EN EL PARAMS = ORDER = "ASC", PAGE = 0 Y RECORDS = 5
    //DESPUES DE ESO SELECCIONO EL PAGINADO Y MUESTRA ORDEROB = COLUMN = "", DIRECTION = "" Y PAGE = 5 Y EN EL PARAMS = ORDER = "", PAGE = 5 Y RECORDS = 5
    //SOLO HAY QUE VERIFICAR EL ESTADO DEL HOOK Y QUE NO CAMBIE LOS PARAMS
    var params = parametros;
    if (sortCol.column !== "" && sortCol.direction !== "") {
      //AQUI QUIERE DECIR QUE TRAE UN ORDENAMIENTO POR HEADER
      params.order = orderOb.column + " " + orderOb.direction;
    } else {
      params.order = "";
    }
    if (sortCol.page !== "") {
      //QUIERE DECIR QUE CAMBIAMOS DE PAGINA
      params.page = sortCol.page;
    } else {
      params.page = 0;
    }
    if (sortCol.search !== "") {
      params.search = sortCol.search;
    } else {
      params.search = "";
    }
    if (orderOb) {
    } else {
      if (sortCol.column !== "" && sortCol.direction !== "") {
        //AQUI TRAE UN ORDENAMIENTO POR HEADER
        params.order = orderOb.column + " " + orderOb.direction;
      } else {
        params.order = "";
      }
      if (sortCol.page !== "") {
        //CAMBIAMOS DE PAGINA
        params.page = sortCol.page;
      } else {
        params.page = 0;
      }
      if (sortCol.search !== "") {
        params.search = sortCol.search;
      } else {
        params.search = "";
      }
    }

    if(isDelete && tableContent.length === 1){
      params = parametros
      setSortCol( {
        //Parametros para aplicar filtros a la busqueda
        direction: "",
        column: "",
        page: "",
        currentPage: 0,
        search: "",
        records: 5,
      })
    }  
    params.records = sortCol.records;
    setLoading(true);
    const finalData = peticionEncript(params);
    await axios 
      .post(`${process.env.REACT_APP_API}receiver/receiver.php`, finalData)
      .then((res) => {
        let data = [];
        if (res.status === 200 && res.data.code === "200") {
          const ttrec = res.data.totalRecords ? res.data.totalRecords : 0;
          setTotalRegistros(ttrec);
          setPagesCount(Math.ceil(ttrec / pageSize));
          res.data.data.map((el) => {
            data.push(Object.values(el));
          });
        } else {
          setTotalRegistros(0);
          setPagesCount(0);
        }
        setTableContent(data);
        setLoading(false);
      })
      .catch((err) => {
        toast(<Notification type={"consultar_servidor_error"} withIcon />);
        setLoading(false);
      });
  };

  const loc = useLocation();
  const user = useSelector((state) => state.userInfo);
  const { id, profileType } = useSelector((state) => state.userInfo);
  const getActionsUser = async () => {
    const pathname = loc.pathname.split("/")[2];
    if (pathname !== "dashboard") {
      const route = await getRouters(user.permission, pathname);
      if (!route.permission) {
        history.push("/template");
      } else {
        getPermissionsUser();
      }
    }
  };

  const getPermissionsUser = async () => {
    const pathname = loc.pathname.split("/")[2];
    const result = await getActions(
      id,
      profileType.selectProfile.keyProfile,
      pathname
    );
    setPermissionsForm(result.actions);
  };

  useEffect(() => {
    getData(sortCol);
  }, [sortCol, parametros]);

  useEffect(() => {
    getActionsUser();
  }, []);

  const handleOnChange = (e) => {
    setSeccionActual(0);
    setSortCol((prevState) => ({
      ...prevState,
      search: e.target.value,
      currentPage: 0,
      page: "",
    }));
  };

  const handleOnChangeSelect = (e) => {
    setSeccionActual(0);
    setPageSize(e.target.value);
    setSortCol((prevState) => ({
      ...prevState,
      records: e.target.value,
      currentPage: 0,
      page: "",
    }));
  };

  return (
    <div>
      <Row className="mb-4">
        <Col>
          {showTitulo && (
            <div className={s.tableTitle}>
              <h1 className="text-secondary text-center">{titulo} </h1>
            </div>
          )}
          <div className="d-flex flex-wrap justify-content-between">
            {permissionsForm.CON === "1" && (
              <Input
                value={sortCol.search}
                name="search"
                onChange={handleOnChange}
                className="col-12 col-sm-6 order-last order-sm-first"
                placeholder="Buscar..."
              ></Input>
            )}
            {createFn && permissionsForm.INS === "1" && (
              <div className="col-12 col-sm-6 order-first order-sm-last mb-3 mt-sm-0 text-center text-sm-right">
                <Button
                  color="add"
                  className="btn-rounded with-icon"
                  onClick={() => createFn}
                >
                  Agregar
                </Button>
              </div>
            )}
          </div>
          <div className="widget-table-overflow mt-3">
            <Table
              className={`table-striped table-borderless table-hover ${s.statesTable}`}
              responsive
            >
              <thead className="border-bottom border-secondary">
                <tr className={sc.userSelectNone}>
                  {cabecerasTabla.map(
                    (cabecera, indexHeaders) =>
                      filtro[indexHeaders] && (
                        <th
                          key={"H" + indexHeaders}
                          className="text-center"
                          onClick={() => sortTable(cabecera, indexHeaders + 1)}
                        >
                          {cabecera === "Id" ? "#" : cabecera}
                          <i
                            className={
                              "align-top eva" +
                              (sortCol.column === indexHeaders + 1
                                ? sortCol.direction === "asc"
                                  ? " eva-arrow-ios-upward-outline"
                                  : sortCol.direction === "desc"
                                  ? " eva-arrow-ios-downward-outline"
                                  : ""
                                : "")
                            }
                          ></i>
                        </th>
                      )
                  )}
                  <th className="text-center">Acciones</th>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <tr key={"loading"}>
                    <td colSpan={cabecerasTabla.length + 1}>
                      <Loader />
                    </td>
                  </tr>
                ) : tableContent.length === 0 ? (
                  <tr key={"nothing"}>
                    <td
                      colSpan={cabecerasTabla.length + 1}
                      className="text-center"
                    >
                      No se encontraron registros
                    </td>
                  </tr>
                ) : (
                  tableContent.map((registro, index) => (
                    <tr key={"R" + index}>
                      {registro.map((elemento, indexCol) => {
                        return mapea(elemento, indexCol, index);
                      })}
                      {/* ACTIONS */}
                      <td style={{ minWidth: "150px" }}>
                        <div>
                          {watchFn && permissionsForm.CON === "1" && (
                            <div
                              style={{ cursor: "pointer" }}
                              onClick={() => watchFn(registro)}
                            >
                              <i
                                className="fa fa-eye text-info pr-1"
                                style={{ cursor: "pointer" }}
                              />
                              Consultar
                            </div>
                          )}
                          {updateFn && permissionsForm.UPD === "1" && (
                            <div
                              style={{ cursor: "pointer" }}
                              onClick={() => updateFn(registro)}
                            >
                              <i
                                className="fa fa-pencil text-warning pr-1"
                                style={{ cursor: "pointer" }}
                              />
                              Editar
                            </div>
                          )}
                          {deleteFn && permissionsForm.DEL === "1" && (
                            <ModalComponentEliminar
                              modalTitle="Eliminar"
                              id={registro[0]}
                              table={nameTable}
                              nameColumn={nameColumn}
                              elimina={deleteFn}
                              sortCol={sortCol}
                              specialIco
                            >
                              <h6>
                                ¿Seguro de eliminar el registro "{registro[1]}"?
                              </h6>
                              No podrá recuperar la información después de ser
                              eliminada
                            </ModalComponentEliminar>
                          )}
                          {specialFn && (
                            <div
                              style={{ cursor: "pointer" }}
                              onClick={() => specialFn(registro)}
                            >
                              <i
                                className={
                                  "text-primary pr-2 fa " +
                                  (specialIco ? specialIco : "fa-star-o")
                                }
                              />
                              {specialTxt}
                            </div>
                          )}
                        </div>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </Table>
            {permissionsForm.CON === "1" && (
              <div className="row">
                <div className="col-12 col-sm-6 d-flex justify-content-start mt-3">
                  <div className="mr-3">
                    Mostrando{" "}
                    <span className="text-primary">
                      {sortCol.currentPage * pageSize +
                        (tableContent.length === 0 ? 0 : 1)}
                    </span>{" "}
                    a{" "}
                    <span className="text-primary">
                      {pageSize * (sortCol.currentPage + 1) <= totalRegistros
                        ? pageSize * (sortCol.currentPage + 1)
                        : totalRegistros}{" "}
                    </span>
                    de {totalRegistros} registros
                  </div>
                  <select
                    className="form-control form-control-sm"
                    style={{ width: "78px" }}
                    value={sortCol.records}
                    onChange={handleOnChangeSelect}
                  >
                    <option value={5} key={5}>
                      5
                    </option>
                    <option value={10} key={10}>
                      10
                    </option>
                    <option value={15} key={15}>
                      15
                    </option>
                    <option value={20} key={20}>
                      20
                    </option>
                    <option value={100} key={100}>
                      100
                    </option>
                  </select>
                </div>
                <div className="col-12 col-sm-6 mt-3 d-flex justify-content-center justify-content-sm-end">
                  <Pagination
                    className="pagination-borderless d-flex"
                    aria-label="Page navigation example"
                  >
                    <PaginationItem disabled={sortCol.currentPage <= 0}>
                      <PaginationLink
                        onClick={(e) =>
                          settableContentPage(e, sortCol.currentPage - 1)
                        }
                        previous
                        href="#top"
                      />
                    </PaginationItem>
                    {[...Array(5)].map(
                      (page, i) =>
                        5 * seccionActual + i < pagesCount && (
                          <PaginationItem
                            key={i}
                            disabled={
                              5 * seccionActual + i === sortCol.currentPage
                            }
                          >
                            <PaginationLink
                              onClick={(e) =>
                                settableContentPage(e, 5 * seccionActual + i)
                              }
                              href={5 * seccionActual + (i + 1)}
                              className={
                                5 * seccionActual + i === sortCol.currentPage
                                  ? "text-primary text-bold"
                                  : "text-secondary"
                              }
                            >
                              {5 * seccionActual + (i + 1)}
                            </PaginationLink>
                          </PaginationItem>
                        )
                    )}
                    <PaginationItem
                      disabled={sortCol.currentPage >= pagesCount - 1}
                    >
                      <PaginationLink
                        onClick={(e) =>
                          settableContentPage(e, sortCol.currentPage + 1)
                        }
                        next
                        href="#top"
                      />
                    </PaginationItem>
                  </Pagination>
                </div>
              </div>
            )}
          </div>
        </Col>
      </Row>
    </div>
  );
};
export default TableComponentActions;

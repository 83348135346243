import { fDate, fMoney } from "./format";

const fDateLong = new Intl.DateTimeFormat("es-MX", {
  dateStyle: "full",
  timeZone: "UTC",
});

export const valuesTable = [
  {
    word: "«nombre_del_empleado»",
    desc: "Mostrará el nombre del empleado",
    key: "nombreCompleto",
  },
  {
    word: "«adscripcion»",
    desc: "Mostrará el nombre de adscripcion del empleado",
    key: "nameArea",
  },
  {
    word: "«puesto»",
    desc: "Mostrará el puesto laboral del empleado",
    key: "nameJob",
  },
  {
    word: "«calle»",
    desc: "Mostrará la calle que tiene registrada el empleado",
    key: "street",
  },
  {
    word: "«interior»",
    desc: "Mostrará el numero interior que tiene registrada el empleado",
    key: "intNumber",
  },
  {
    word: "«exterior»",
    desc: "Mostrará el numero exterior que tiene registrada el empleado",
    key: "extNumber",
  },
  {
    word: "«numEmpleado»",
    desc: "Mostrará el numero de empleado",
    key: "staffNumber",
  },
  {
    word: "«telefono»",
    desc: "Mostrará el numero de teléfono que tiene registrado el empleado",
    key: "telefonoPersonal",
  },
  {
    word: "«correo»",
    desc: "Mostrará el correo electrónico que tiene registrado el empleado",
    key: "emailPersonal",
  },
  // {
  //     word: "«movil»",
  //     desc: "Mostrará el numero de celular que tiene registrado el empleado",
  //     key: "telefonoPersonal"
  // },
  {
    word: "«anio»",
    desc: "Mostrará el año actual cuando se genere el documento",
    key: "anioActual",
  },
  {
    word: "«mes»",
    desc: "Mostrará el mes actual cuando se genere el documento",
    key: "mesActual",
  },
  {
    word: "«dia»",
    desc: "Mostrará el dia actual cuando se genere el documento",
    key: "diaActual",
  },
  {
    word: "«anioLetra»",
    desc: "Mostrará el año actual en texto cuando se genere el documento",
    format: "numberText",
    key: "anioActualLetra",
  },
  {
    word: "«mesLetra»",
    desc: "Mostrará el mes actual en texto cuando se genere el documento",
    format: "monthText",
    key: "mesActualLetra",
  },
  {
    word: "«diaLetra»",
    desc: "Mostrará el dia actual en texto cuando se genere el documento",
    format: "dayText",
    key: "diaActualLetra",
  },
  {
    word: "«entidad»",
    desc: "Mostrará la entidad federativa que tiene registrado el empleado",
    key: "nameState",
  },
  {
    word: "«municipio»",
    desc: "Mostrará el municipio que tiene registrado el empleado",
    key: "nameCounty",
  },
  {
    word: "«issemym»",
    desc: "Mostrará la clave de ISSEMYM del empleado",
    key: "socialSecurityKey",
  },
  {
    word: "«fechaIngresoIssemym»",
    desc: "Mostrará la fecha de ingreso al ISSEMYM del empleado",
    format: "date",
    key: "SecurityKeyStartDate",
  },
  {
    word: "«colonia»",
    desc: "Mostrará la colonia que tiene el empleado",
    key: "subStreet",
  },
  {
    word: "«fechaBaja»",
    desc: "Mostrará la fecha de baja del empleado",
    format: "date",
    key: "firedDate",
  },
  {
    word: "«diaBaja»",
    desc: "Mostrará el dia en texto de baja del empleado",
    format: "dayText",
    key: "diaBajaLetra",
  },
  {
    word: "«mesBaja»",
    desc: "Mostrará el mes en texto de baja del empleado",
    format: "monthText",
    key: "mesBajaLetra",
  },
  {
    word: "«anioBaja»",
    desc: "Mostrará el año en texto de baja del empleado",
    format: "dayText",
    key: "anioBajaLetra",
  },
  {
    word: "«fechaIngreso»",
    desc: "Mostrará la fecha de ingreso del empleado",
    format: "date",
    key: "startJobDate",
  },
  {
    word: "«diaIngreso»",
    desc: "Mostrará el dia en texto de ingreso del empleado",
    format: "dayText",
    key: "diaIngresoLetra",
  },
  {
    word: "«mesIngreso»",
    desc: "Mostrará el mes en texto de ingreso del empleado",
    format: "monthText",
    key: "mesIngresoLetra",
  },
  {
    word: "«anioIngreso»",
    desc: "Mostrará el año en texto de ingreso del empleado",
    format: "numberText",
    key: "anioIngresoLetra",
  },
  {
    word: "«fechaBajaGafete»",
    desc: "Mostrará la fecha de baja del gafete empleado",
    format: "date",
    key: "fechaBajaGafete",
  },
  {
    word: "«diaBajaGafete»",
    desc: "Mostrará el dia en texto de baja del gafete del empleado",
    format: "dayText",
    key: "diaBajaGafeteLetra",
  },
  {
    word: "«mesBajaGafete»",
    desc: "Mostrará el mes en texto de baja del gafete del empleado",
    format: "monthText",
    key: "mesBajaGafeteLetra",
  },
  {
    word: "«anioBajaGafete»",
    desc: "Mostrará el año en texto de baja del gafete del empleado",
    format: "numberText",
    key: "anioBajaGafeteLetra",
  },
  {
    word: "«tituloAcademico»",
    desc: "Mostrará el grado academico que tiene el empleado",
    key: "titulo",
  },
  {
    word: "«oficio»",
    desc: "Tendra que insertar manualmente el numero de oficio",
    key: "manual",
  },
  {
    word: "«acuerdo»",
    desc: "Tendra que insertar manualmente el numero de acuerdo",
    key: "manual",
  },
  {
    word: "«fechaAcuerdo»",
    desc: "Tendra que insertar manualmente con letra la fecha del acuerdo",
    key: "manual",
  },
  {
    word: "«adscrito/a»",
    desc: "Muestra la palabra adscrito o adscrita dependiendo del genero",
    format: "«adscrito/a",
    key: "nameGender",
  },
  {
    word: "«articuloJuzgado»",
    desc: "Muestra el articulo de adscripcion",
    key: "xxx",
    format: "none",
  },
  {
    word: "«articuloJuzgadoComision»",
    desc: "Muestra el articulo de adscripcion comisión",
    key: "xxx",
    format: "none",
  },
  {
    word: "«articuloJuzgadoAnterior»",
    desc: "Muestra el articulo de adscripcion anterior",
    key: "xxx",
    format: "none",
  },
  {
    word: "«articuloJuzgadoComisionAnterior»",
    desc: "Muestra el articulo de ascripcion comisión anterior",
    key: "xxx",
    format: "none",
  },
  {
    word: "«articuloJuzgadoEncabezado»",
    desc: "Muestra el articulo de la ascripcion en el encabezado",
    key: "xxx",
    format: "none",
  },
];

export function getValue(w, info) {
  const caso = valuesTable.find((e) => e.word.toLowerCase() == w.toLowerCase());
  if (!caso) return "";
  let dato = findValue(caso.key, info);
  switch (caso.format) {
    case "date":
      dato = fDate(dato);
      break;
    case "money":
      dato = fMoney(dato);
      break;
    case "manual":
      dato = "__________________";
      break;
    case "none":
      dato = "";
      break;
  }
  return dato;
}

function findValue(key, info) {
  if (Array.isArray(key))
    return key.reduce((acc, b) => acc + info[b] + " ", "");
  else return info[key];
}

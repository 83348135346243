import {
    Col,
    Row,
    Input,
    Table,
    Spinner,
    Container
} from "reactstrap";
import { format } from 'date-fns';
import { es } from 'date-fns/locale';
import { useState, useEffect } from "react";
import ErrorDatatable from "./ErrorDatatable";
import Paging from "./Paging";

const CurrencyFormatter = new Intl.NumberFormat("es-MX", {
    style: "currency",
    currency: "MXN",
});

const DecimSeparatorFormatter = new Intl.NumberFormat('en-US');

const formatters = {
    currency: (value) => CurrencyFormatter.format(value),
    date: (value) => format(new Date(value), "dd/MM/yyyy"),
    decim: (value) => DecimSeparatorFormatter.format(value),
    percent: (value) => `${DecimSeparatorFormatter.format(value)}%`,
    datetime: (value) => format(new Date(value), "PPPppp", { locale: es }),
};

/**
 * Datatable para todo, basado en Datatables.net
 * @param {Object} props
 * @param {string} props.className - Clases de estilo a aplicar al contenedor
 * @param {boolean} props.paging - Habilitar paginación
 * @param {Function} props.onChangePage - Función que se ejecuta al cambiar la pagina, se recibira page, ordering, pageLength, searchValue
 * @param {{ col: number, opt: 'asc' | 'desc' }} props.order - El orden de las columnas.
 * @param {boolean} props.ordering - Habilitar ordenamiento global
 * @param {Function} props.onOrderingChange - Función que se ejecuta al cambiar la ordenación, se recibira page, ordering, pageLength, searchValue
 * @param {boolean} props.info - Habilitar o no la información de la pagina (No de registros, etc)
 * @param {boolean} props.searching - Habilitar o no busqueda
 * @param {boolean} props.onSearching - Función que se ejecuta al momento del input, se recibira page, ordering, pageLength, searchValue
 * @param {number} props.pageLength - Longitud de la pagina actual -1 para todos por defecto es 5
 * @param {Function} props.onPageLengthChange - Función que se ejecuta al cambiar el tamaño de pagina, se recibira page, ordering, pageLength, searchValue
 * @param {Array<{v?: number, l?: string}>} props.lengthMenu - Longitudes por mostrar [{ v: 5, l: 5 }, { v: 10, l: 10 }, { v: 20, l: 20 }, { v: 50, l: 50 }, { v: 100, l: 100 }]
 * @param {Array<Object>} props.stateItems - Control de estado externo de los items, recibe: [state, setState]
 * @param {Function} props.petition - Metodo que devolvera la información, recibe ordCol, itemsPerPage, currentPage, search, ignorar si se manda data
 * @param {Array<Object>} props.data - Arreglo que contiene la información, ignorar si se manda petition
 * @param {Array<string} props.headers - Arreglo que contiene los nombres de las columnas
 * @param {Array<{data?: string | null, format?: 'currency' | 'date' | 'decim' | 'percent' | 'datetime' | function, render?: Function, orderValue?: string}>} props.columns - Arreglo de objetos que representan a las columnas y sus renderizadores, si se desea formatear mandar la opción deseada o la funcion a formatear, la funcion recibira v
 * @param {Array<{orderable?: boolean, className?: string, visible?: boolean, targets: Array<number> | number}>} props.columnDefs - El array de objetos que contiene las definiciones de columna.
 * @param {{onClick?: Function, onDoubleClick?: Function}} props.eventsRow - Conjunto de eventos a establecer en el renglon
 * @param {'back' | 'front'} props.control - Escoge el tipo de control de la tabla por back o frontend
 * @param {Array<Object>} props.stateRefresh - Control de actualización recibe: [refresh, setRefresh]
 * @returns {JSX.Element} - El elemento JSX del componente.
 * @author Luis Salas
 */
export default function Datatable({
    className = "",
    paging = true,
    onChangePage,
    order = { col: 0, opt: 'asc' },
    ordering = true,
    onOrderingChange,
    info = true,
    searching = true,
    onSearching,
    pageLength = 5,
    onPageLengthChange,
    lengthMenu = [{ v: 5, l: 5 }, { v: 10, l: 10 }, { v: 20, l: 20 }, { v: 50, l: 50 }, { v: 100, l: 100 }],
    stateItems,
    petition,
    data,
    headers,
    columns,
    columnDefs,
    eventsRow = {},
    control = 'front',
    stateRefresh
}) {

    //TABLE
    let [refresh, setRefresh] = [null, null];
    if (stateRefresh) {
        [refresh, setRefresh] = stateRefresh
    }
    const [initial, setInitial] = useState(true);
    const [loading, setLoading] = useState(true)
    let [items, setItems] = useState([]);
    if (stateItems) {
        [items, setItems] = stateItems
    }
    const [currentPageItems, setCurrentPageItems] = useState([])
    const [filteredPageItems, setFilteredPageItems] = useState(0)
    const [other, setOther] = useState(null);

    //SEARCHING
    const [search, setSearch] = useState("")

    //ORDERING
    const [ordCol, setOrdCol] = useState(order)

    //PAGING
    const [offset, setOffset] = useState(0)
    const [itemsPerPage, setItemsPerPage] = useState(pageLength)
    const [currentPage, setCurrentPage] = useState(0)



    async function getDataPetition(ordCol, itemsPerPage, currentPage, search) {
        if (!loading) {
            setLoading(true);    
        }
        const data = await petition(ordCol, itemsPerPage, currentPage, search);
        if (data !== null) {
            const tempOther = {}
            const keys = Object.keys(data);
            keys.forEach(key => { if (key !== "data") tempOther[key] = data[key] })
            setOther(tempOther);
            setItems(data.data);
        } else {
            setItems([]);
        }
        setLoading(false)
    }

    useEffect(() => {
        if (initial) {
            if (data) {
                setItems(data);
                setLoading(false)
            } else if (petition) {
                const col = columns[ordCol.col]
                if (col.orderValue !== null || col.data !== null) {
                    const orden = `${col.orderValue ? col.orderValue : col.data} ${ordCol.opt}`
                    getDataPetition(orden, itemsPerPage, currentPage, search)
                } else {
                    getDataPetition("", itemsPerPage, currentPage, search)
                }
            } else {
                setLoading(false)
            }
            setInitial(false);
        }
    }, [data, petition])

    useEffect(() => {
        if (petition && refresh !== null && refresh === true) {
            setLoading(true);
            if (setRefresh !== null) {
                setRefresh(false)
            }
            const col = columns[ordCol.col]
            if (col.orderValue !== null || col.data !== null) {
                const orden = `${col.orderValue ? col.orderValue : col.data} ${ordCol.opt}`
                getDataPetition(orden, itemsPerPage, currentPage, search)
            } else {
                getDataPetition("", itemsPerPage, currentPage, search)
            }
        } else if (data && refresh !== null && refresh === true) {
            setItems(data);
            if (setRefresh !== null) {
                setRefresh(false)
            }
            filterData()
        }
    }, [refresh])

    function sortData(data) {
        const sortOrder = (ordCol.opt === "asc") ? 1 : -1;
        const sortColumn = columns[ordCol.col].data || columns[ordCol.col].orderValue;
        return data.slice().sort((a, b) => sortOrder * ((a[sortColumn] < b[sortColumn]) ? -1 : 1));
    }

    function filterData() {
        const searchTerms = search.replace(/\s+/g, " ").toUpperCase().split(" ");
        let tempItems = items;
        if (search !== "") {
            tempItems = tempItems.filter(item => {
                return searchTerms.every(term => {
                    const regex = new RegExp(term, "gi");
                    return regex.test(JSON.stringify(item).toUpperCase())
                });
            });
        }
        const sortedData = ordering ? sortData(tempItems) : tempItems;
        setFilteredPageItems(sortedData.length)
        if (paging) {
            setCurrentPageItems(sortedData.slice(offset, offset + itemsPerPage));
        } else {
            setCurrentPageItems(sortedData);
        }
    }

    useEffect(() => {
        if (items !== undefined) {
            if (control === 'front') {
                filterData()
            } else if (control === 'back') {
                setCurrentPageItems(items);
            }
        } else {
            setCurrentPage(0)
            setItemsPerPage(lengthMenu[0].v)
            setCurrentPageItems([]);
        }
    }, [other, items, itemsPerPage, currentPage, offset, control, paging])

    function sortTable(col) {
        if (ordCol.opt === 'desc' && ordCol.col === col) {
            setOrdCol({ col, opt: 'asc' })
        } else {
            setOrdCol({ col, opt: 'desc' })
        }
    }

    useEffect(() => {
        if (control !== 'front' && other !== null) {
            if (onChangePage !== undefined) {
                onChangePage(ordCol, itemsPerPage, currentPage, search)
            } else {
                console.log("El evento no puede ser manejado")
            }
            const col = columns[ordCol.col]
            setLoading(true)
            if (col.orderValue !== null || col.data !== null) {
                const orden = `${col.orderValue ? col.orderValue : col.data} ${ordCol.opt}`
                getDataPetition(orden, itemsPerPage, currentPage, search)
            } else {
                getDataPetition("", itemsPerPage, currentPage, search)
            }
        }
    }, [currentPage])

    useEffect(() => {
        if (control !== 'front' && other !== null) {
            if (onPageLengthChange !== undefined) {
                onPageLengthChange(ordCol, itemsPerPage, currentPage, search)
            } else {
                console.log("El evento no puede ser manejado")
            }
            const col = columns[ordCol.col]
            setLoading(true)
            if (col.orderValue !== null || col.data !== null) {
                const orden = `${col.orderValue ? col.orderValue : col.data} ${ordCol.opt}`
                getDataPetition(orden, itemsPerPage, currentPage, search)
            } else {
                getDataPetition("", itemsPerPage, currentPage, search)
            }
        }
    }, [itemsPerPage])

    useEffect(() => {
        if (items !== undefined) {
            if (control === 'front') {
                setOffset(0);
                setCurrentPage(0);
                filterData()
            } else if (control === 'back' && other !== null) {
                if (onSearching !== undefined) {
                    onSearching(ordCol, itemsPerPage, currentPage, search)
                } else {
                    console.log("El evento no puede ser manejado")
                }
                const col = columns[ordCol.col]
                setLoading(true)
                if (col.orderValue !== null || col.data !== null) {
                    const orden = `${col.orderValue ? col.orderValue : col.data} ${ordCol.opt}`
                    getDataPetition(orden, itemsPerPage, currentPage, search)
                } else {
                    getDataPetition("", itemsPerPage, currentPage, search)
                }
            }
        }
    }, [search])

    function isSortable(column) {
        if (!ordering) {
            return false;
        }
        if (!columnDefs) {
            return true;
        }
        return !columnDefs.some(col => {
            if (col.targets) {
                if (Array.isArray(col.targets) && col.targets.includes(column)) {
                    return col.orderable === undefined ? false : !col.orderable;
                } else if (typeof col.targets === 'number' && col.targets === column) {
                    return col.orderable === undefined ? false : !col.orderable;
                }
            }
            return false;
        });
    }

    function isVisible(column) {
        if (!columnDefs) {
            return true;
        }
        return !columnDefs.some(col => {
            if (col.targets) {
                if (Array.isArray(col.targets) && col.targets.includes(column)) {
                    return col.visible === undefined ? false : !col.visible;
                } else if (typeof col.targets === 'number' && col.targets === column) {
                    return col.visible === undefined ? false : !col.visible;
                }
            }
            return false;
        });
    }

    function getClassNames(column) {
        if (!columnDefs) {
            return '';
        }
        const classes = [];
        for (const col of columnDefs) {
            const { className, targets } = col;
            if (targets) {
                if ((Array.isArray(targets) && targets.includes(column)) || targets === column) {
                    if (className) classes.push(className);
                }
            }
        }
        return classes.join(' ');
    }

    function getColSize() {
        if (columnDefs === undefined) {
            return headers.length;
        }
        const hiddenCols = new Set();
        columnDefs.forEach(col => {
            if (col.visible === false) {
                if (Array.isArray(col.targets)) {
                    col.targets.forEach(target => hiddenCols.add(target));
                } else if (typeof col.targets === 'number') {
                    hiddenCols.add(col.targets);
                }
            }
        });
        return headers.length - filterData.length;
    }

    function formatting(value, formato) {
        try {
            if (formato === undefined) {
                return value;
            } else if (typeof formato === "string" && formato in formatters) {
                return formatters[formato](value);
            } else if (typeof formato === "function") {
                return formato(value);
            } else {
                return value;
            }
        } catch (err) {
            console.log(err);
            return value;
        }
    }

    useEffect(() => {
        if (items !== undefined) {
            if (control === 'front') {
                setOffset(0);
                setCurrentPage(0);
                filterData()
            } else if (control === 'back' && other !== null) {
                if (onOrderingChange !== undefined) {
                    onOrderingChange(ordCol, itemsPerPage, currentPage, search)
                } else {
                    console.log("El evento no puede ser manejado")
                }
                const col = columns[ordCol.col]
                if (col.orderValue !== null || col.data !== null) {
                    const orden = `${col.orderValue ? col.orderValue : col.data} ${ordCol.opt}`
                    getDataPetition(orden, itemsPerPage, currentPage, search)
                } else {
                    getDataPetition("", itemsPerPage, currentPage, search)
                }
            }
        }
    }, [ordCol, control])

    function getItemsPagging() {
        if (!items?.length) {
            return 0;
        }
        const totalRecords = other?.totalRecords;
        if (control === 'back') {
            return totalRecords;
        }
        if (pageLength === -1 || items.length <= pageLength) {
            return items.length;
        }
        return search !== "" ? filteredPageItems : items.length;
    }

    function searchFunction(value) {
        setSearch(value);
        setCurrentPage(0)
    }

    if (headers.length !== columns.length) {
        return (<ErrorDatatable message={"Las longitudes de la tabla no son las mismas"} />)
    }
    return (
        <Container className={className} fluid>
            {searching &&
                <Row className="mb-3">
                    <Col xs={12} md={6}>
                        <Input
                            id="search"
                            name="search"
                            placeholder="Buscar"
                            type="text"
                            value={search}
                            onChange={(e) => searchFunction(e.target.value)}
                        />
                    </Col>
                </Row>
            }
            <Table striped hover responsive>
                <thead className="border-bottom border-secondary">
                    <tr>
                        {headers.filter((header, index) => isVisible(index)).map((header, index) => (
                            <th className="text-center" key={`theadTab${index}`} style={{ cursor: isSortable(index) ? "pointer" : "default" }} onClick={isSortable(index) ? () => sortTable(index) : undefined}>
                                {header}
                                {ordering && ordCol.col === index && (
                                    <i className={"align-top eva eva-arrow-ios-" + (ordCol.opt === "asc" ? "upward" : "downward") + "-outline"}></i>
                                )}
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {loading ? (
                        <tr>
                            <td colSpan={getColSize()}>
                                <div className="d-flex align-items-center justify-content-center" style={{ height: "200px" }}>
                                    <Spinner
                                        color="primary"
                                        style={{
                                            height: '3rem',
                                            width: '3rem'
                                        }}
                                        type="grow"
                                    >
                                        Loading...
                                    </Spinner>
                                </div>
                            </td>
                        </tr>
                    ) : (
                        <>
                            {currentPageItems.length === 0 && (
                                <tr>
                                    <td colSpan={getColSize()} className="bg-light font-weight-bold text-center">
                                        Sin información
                                    </td>
                                </tr>
                            )}
                            {currentPageItems.map((row, ind) => (
                                <tr key={`trCont${ind}`} style={{ cursor: eventsRow.onClick || eventsRow.onDoubleClick ? "pointer" : "default" }} onDoubleClick={() => eventsRow.onDoubleClick && eventsRow.onDoubleClick(row)} onClick={() => eventsRow.onClick && eventsRow.onClick(row)}>
                                    {columns.map((col, index) => {
                                        const isVisibleColumn = isVisible(index);
                                        return isVisibleColumn && (
                                            <td key={`tdContr${ind}c${index}`} className={getClassNames(index)}>
                                                {col.render === undefined ? (col.data !== null && col.data !== undefined ? formatting(row[col.data], col.format) : formatting(JSON.stringify(row), col.format)) : (col.data !== null && col.data !== undefined ? col.render(formatting(row[col.data], col.format)) : col.render(formatting(row, col.format)))}
                                            </td>
                                        );
                                    })}
                                </tr>
                            ))}
                        </>
                    )}
                </tbody>
            </Table>
            <Paging
                lengthMenu={lengthMenu}
                currentPage={currentPage}
                currentPageItems={currentPageItems}
                setCurrentPage={setCurrentPage}
                itemsPerPage={itemsPerPage}
                setItemsPerPage={setItemsPerPage}
                items={getItemsPagging()}
                paging={paging}
                info={info}
                offset={offset}
                setOffset={setOffset}
            />
        </Container >
    )
}
import { peticionesReceiver } from "../../../helpers/peticionesReceiver";
import Notification from "../../../components/Notification/Notification";
import { toast } from "react-toastify";
const API = peticionesReceiver();

export const createBilding = async (edificioN) => {
  if (edificioN) {
    const params = {
      action: "insert",
      table: "buildings",
      rows: edificioN,
      validate: [
        [
          "keyCity",
          "nameBuilding",
          "streetName",
          "subStreet",
          "intNumber",
          "extNumber",
          "zipCode",
          "latitude",
          "longitude",
        ],
        ["keyCity", "nameBuilding", "zipCode"],
      ],
    };
    API.peticion(params)
      .then((res) => {
        if (res.status === 200 && res.data.code === "200") {
          toast(
            <Notification
              type={"agrega_exito"}
              backMessage={res.data.message}
              withIcon
            />
          );
          window.location.href = "#/template/edificios";
        } else {
          toast(
            <Notification
              type={"agrega_error"}
              backMessage={res.data.message}
              withIcon
            />
          );
        }
      })
      .catch((err) => {
        toast(<Notification type={"consultar_servidor_error"} withIcon />);
      });
  }
};

export const updateBuilding = async (edificioU) => {
  if (edificioU) {
    const params = {
      action: "update",
      table: "buildings",
      rows: edificioU,
      condition: {
        idBuilding: edificioU.idBuilding,
      },
      validate: [
        [
          "keyCity",
          "nameBuilding",
          "streetName",
          "subStreet",
          "intNumber",
          "extNumber",
          "zipCode",
          "latitude",
          "longitude",
        ],
        ["keyCity", "nameBuilding", "zipCode"],
      ],
    };
    API.peticion(params)
      .then((res) => {
        if (res.status === 200 && res.data.code === "200") {
          toast(
            <Notification
              type={"modifica_exito"}
              backMessage={res.data.message}
              withIcon
            />
          );
          window.location.href = "#/template/edificios";
        } else {
          toast(
            <Notification
              type={"modifica_error"}
              backMessage={res.data.message}
              withIcon
            />
          );
        }
      })
      .catch((err) => {
        toast(<Notification type={"consultar_servidor_error"} withIcon />);
      });
  }
};

import React, { useState } from "react";
import TableComponentCustom from "../../../components/TableComponent/TableComponentCustom";
import Widget from "../../../components/Widget/Widget";

export default function MatrizInRecaudados() {
  const cabeceras = [
    "Id",
    "Rubro de ingresos",
    "Nombre CRI",
    "Cuenta Cargo",
    "Nombre Cargo",
    "Cuenta Abono",
    "Nombre abono",
  ];
  const [parametros] = useState(
    {
      action: "datatable",
      table:
        "matrix_incomecollected",
      rows: "idMatrix,CRI,criName,chargeAccount,chargeAccountName,payAccount,payAccountName",
      conditions: `enabled = 1`,
      page: 0,
      records: 5,
      search: "",
      order: "",
    }
  );
  
  return (
    <Widget>
      <TableComponentCustom
        titulo={"Ingresos recaudados"}
        cabecerasTabla={cabeceras}
        filtro={null}
        parametros={parametros}
      />
    </Widget>
  )
}
import { Formik, Form, Field, ErrorMessage, useFormikContext } from "formik";
import * as Yup from "yup";
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import Notification from "../../../components/Notification/Notification";
import Loader from "../../../components/Loader/Loader";
import { Col, Row, Button } from "reactstrap";
import axios from "axios";
import ModalConfirmation from "../../../components/Modal/ModalConfirmation";
import { peticionesReceiver } from "../../../helpers/peticionesReceiver";
import { peticionEncript } from "../../../helpers/peticionesEncripted";
import { SelectTypeHeadSingle } from "../../../components/GenericInputsFormik/SelectTypeHeadSingle";

const defaultForm = {
  idArea: "",
  keyTypeArea: "",
  idBuilding: "",
  name: "",
  areaKey: "",
  pArea: "",
  email: "",
  storage: false
};

const FormArea = ({ crear, data, editar }) => {
  const API = peticionesReceiver();
  const [tiposAreas, setTiposArea] = useState([]);
  const [edificios, setEdificios] = useState([]);
  const [loading, setLoading] = useState(true);
  const [form, setForm] = useState(data ? {} : defaultForm);
  const [modal, setModal] = useState(false);
  const [areas, setAreas] = useState([]);
  const history = useHistory();

  const FormSchema = Yup.object().shape({
    keyTypeArea: Yup.string().required("Seleccione un tipo de área"),
    idBuilding: Yup.string().required("Seleccione un edificio"),
    name: Yup.string()
      .min(3, "Nombre debe tener al menos 3 letras")
      .max(250, "Nombre demasiado largo")
      .required("Ingrese un nombre de área"),
    areaKey: Yup.string()
      .min(3, "Clave de área debe tener al menos 3 letras")
      .max(45, "Clave de área demasiado larga")
      .required("Ingrese una clave de área"),
    email: Yup.string()
      .email("Correo electrónico inválido")
      // .required("Ingrese un correo electrónico"),
  });

  const getTiposArea = async () => {
    const params = {
      action: "select",
      table: "types_areas",
      condition: {
        enabled: 1,
      },
      order: "nameTypeArea ASC",
    };
    API.peticion(params)
      .then((res) => {
        if (res.status === 200 && res.data.code === "200") {
          setTiposArea(res.data.data);
          getEdificios();
        } else {
          toast(<Notification type={"consultar_error"} withIcon />);
        }
      })
      .catch((err) =>
        toast(<Notification type={"consultar_servidor_error"} withIcon />)
      );
  };

  const getEdificios = async () => {
    const params = {
      action: "select",
      table: "buildings",
      order: "nameBuilding ASC",
    };
    API.peticion(params)
      .then((res) => {
        if (res.status === 200 && res.data.code === "200") {
          setEdificios(res.data.data);
          setLoading(false);
        } else {
          toast(<Notification type={"consultar_error"} withIcon />);
        }
      })
      .catch((err) =>
        toast(<Notification type={"consultar_servidor_error"} withIcon />)
      );
  };

  const getAreas = async () => {
    const params = {
      action: "select",
      table: "areas",
      order: "name ASC",
    };
    const finalData = peticionEncript(params);
    await axios
      .post(`${process.env.REACT_APP_API}receiver/receiver.php`, finalData)
      .then((res) => {
        if (res.status === 200 && res.data.code === "200") {
          setAreas(res.data.data);
          getEdificios();
        } else {
          toast(<Notification type={"consultar_error"} withIcon />);
        }
      })
      .catch((err) =>
        toast(<Notification type={"consultar_servidor_error"} withIcon />)
      );
  }; 

  useEffect(() => {
    getTiposArea();
    getAreas();
  }, []);

  const submitFunction = (fields) => {
    setModal(true);
  };

  const cancelOp = () => {
    history.push("/template/areas");
  };

  const findId = (name, array) => {
    let id = "";
    for (const el of array) {
      if (el[1] === name) {
        id = el[0];
        break;
      }
    }
    return id;
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <Formik
          initialValues={form}
          validationSchema={FormSchema}
          onSubmit={(fields, { resetForm }) => {
            submitFunction(fields);
          }}
          onReset={(fields, { resetForm }) => {
            cancelOp();
          }}
        >
          {({ errors, status, touched, values, handleBlur, setFieldValue }) => (
            <Form className="mt-4">
              <ModalConfirmation
                modalTitle={data ? "Editar" : "Crear"}
                modal={modal}
                setModal={setModal}
                crear={crear}
                editar={editar}
                isEdit={data ? true : false}
                values={values}
              >
                {data ? (
                  <div className="d-flex justify-content-center">
                    <h6>¿Está seguro de editar el registro?</h6>
                  </div>
                ) : (
                  <div className="d-flex justify-content-center">
                    <h6>¿Desea continuar con el registro?</h6>
                  </div>
                )}
              </ModalConfirmation>
              <Row>
                <Col xs="12" lg="6">
                  <div className="form-group">
                    <label htmlFor="name">
                      Nombre<span className="text-danger">*</span>
                    </label>
                    <Field
                      name="name"
                      type="text"
                      className={
                        "form-control" +
                        (errors.name && touched.name ? " is-invalid" : "")
                      }
                    />
                    <ErrorMessage
                      name="name"
                      component="div"
                      className="invalid-feedback"
                    />
                  </div>
                </Col>
                <Col xs="12" lg="6">
                  <div className="form-group">
                    <label htmlFor="areaKey">
                      Clave de área<span className="text-danger">*</span>
                    </label>
                    <Field
                      name="areaKey"
                      type="text"
                      className={
                        "form-control" +
                        (errors.areaKey && touched.areaKey ? " is-invalid" : "")
                      }
                    />
                    <ErrorMessage
                      name="areaKey"
                      component="div"
                      className="invalid-feedback"
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col xs="12" md="6">
                  <SelectTypeHeadSingle
                    label="Tipo de área"
                    inputName="keyTypeArea"
                    onChangeMethod={(e) =>
                      setFieldValue([e.target.name], e.target.value)
                    }
                    onBlurMethod={handleBlur}
                    value={values.keyTypeArea}
                    isRequired={true}
                    touched={touched.keyTypeArea}
                    errors={errors.keyTypeArea}
                    optionsArray={tiposAreas}
                    optionValue="keyTypeArea"
                    optionName="nameTypeArea"
                    defaultOption="Seleccione un área"
                  />
                </Col>

                <Col xs="12" md="6">
                  <SelectTypeHeadSingle
                    label="Edificio"
                    inputName="idBuilding"
                    onChangeMethod={(e) =>
                      setFieldValue([e.target.name], e.target.value)
                    }
                    onBlurMethod={handleBlur}
                    value={values.idBuilding}
                    isRequired={true}
                    touched={touched.idBuilding}
                    errors={errors.idBuilding}
                    optionsArray={edificios}
                    optionValue="idBuilding"
                    optionName="nameBuilding"
                    defaultOption="Seleccione un área"
                  />
                </Col>
              </Row>
              <Row>
                <Col xs="12" lg="6">
                  <div className="form-group">
                    <label htmlFor="email">
                      Correo
                    </label>
                    <Field
                      name="email"
                      type="text"
                      className={
                        "form-control" +
                        (errors.email && touched.email ? " is-invalid" : "")
                      }
                    />
                    <ErrorMessage
                      name="email"
                      component="div"
                      className="invalid-feedback"
                    />
                  </div>
                </Col>
                
                <Col xs="12" md="6">
                  <SelectTypeHeadSingle
                    label="Area Padre"
                    inputName="pArea"
                    onChangeMethod={(e) =>
                      setFieldValue([e.target.name], e.target.value)
                    }
                    onBlurMethod={handleBlur}
                    value={values.pArea}
                    touched={touched.pArea}
                    errors={errors.pArea}
                    optionsArray={areas}
                    optionValue="idArea"
                    optionName="name"
                    defaultOption="Seleccione un área"
                  />
                </Col>

                <Col xs="12" md="6">
                <Field name="storage">
                  {({ field }) => (
                    <div className="form-group mt-2">
                      <div class="form-check">
                        <input style={{
                          marginLeft: "",
                          width: "20px",
                          height: "20px",
                        }} className="form-check-input" type="checkbox" id="storage" {...field} checked={field.value} />
                        <label className="form-check-label pl-2 pt-1" htmlFor="storage">
                          Almacén
                        </label>
                      </div>
                    </div>
                  )}
                </Field>
                </Col>

              </Row>
              <div className=" d-flex flex-sm-row flex-column-reverse justify-content-sm-around mt-4">
                  <Button color="danger" type="reset" className="mt-3">
                    Cancelar
                  </Button>
                  <Button
                    color="success"
                    type="submit"
                    className="mt-2 mt-sm-3"
                  >
                    Guardar
                  </Button>
                </div>
              <FormikData info={data} />
            </Form>
          )}
        </Formik>
      )}
    </>
  );
};

const FormikData = ({ info }) => {
  const { setFieldValue } = useFormikContext();
  useEffect(() => {
    if (info) {
      setFieldValue("idArea", info[0]);
      setFieldValue("name", info[1]);
      setFieldValue("areaKey", info[2]);
      setFieldValue("pArea", +info[10]);
      setFieldValue("email", info[7]);
      setFieldValue("keyTypeArea", info[8]);
      setFieldValue("idBuilding", info[9]);
      setFieldValue("storage", info[5] === "SI");
    }
  }, [info]);
  return null;
};

export default FormArea;

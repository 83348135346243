// -- Licencias -- //
import Impuestos from "../../../pages/tesoreria/impuestos/Impuestos";
import CrearImpuesto from "../../../pages/tesoreria/impuestos/crear/CrearImpuesto";
import EditarImpuesto from "../../../pages/tesoreria/impuestos/editar/EditarImpuesto";

// -- Comisiones -- //
import Comisiones from "../../../pages/tesoreria/comisiones/Comisiones";
import CrearComision from "../../../pages/tesoreria/comisiones/crear/CrearComision";
import EditarComision from "../../../pages/tesoreria/comisiones/editar/EditarComision";

// -- Cuentas bancarias -- //
import CuentasBancarias from "../../../pages/tesoreria/cuentas-bancarias/index";

const rutasTesoreria = [
    //Impuestos
    {path:"/template/impuestos", component: Impuestos},
    {path:"/template/impuestos/crear", component: CrearImpuesto},
    {path:"/template/impuestos/editar", component: EditarImpuesto},
    //Impuestos
    {path:"/template/comisiones", component: Comisiones},
    {path:"/template/comisiones/crear", component: CrearComision},
    {path:"/template/comisiones/editar", component: EditarComision},
    
    //Cuentas Bancarias
    {path:"/template/cuentas-bancarias", component: CuentasBancarias},
]

export default rutasTesoreria;
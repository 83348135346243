import { peticionesReceiver } from "../../../helpers/peticionesReceiver";
import Notification from "../../../components/Notification/Notification";
import { toast } from "react-toastify";
const API = peticionesReceiver();

export const createSalary = async (salary) => {
    if (salary) {
      const params = {
        action: "insert",
        table: "salaries",
        rows: salary,
        validate: [
        ["level", "subLevel","range","keyStat"]
      ]
      };
      try {
        const res = await API.peticion(params)
        if (res.status === 200 && res.data.code === "200") {
          toast(<Notification type={"agrega_exito"} withIcon />);
          window.location.href = "#/template/salarios"
        }
        else{
          toast(<Notification type={"agrega_error"} backMessage={res.data.message} withIcon />);
        }
      } catch (error) {
        toast(<Notification type={"consultar_servidor_error"} withIcon />);
      }
    }
};

export const updated = async (salary) => {
  if (salary) {
    const params = {
      action: "update",
      table: "salaries",
      rows: salary,
      condition: {
        idSalary: salary.idSalary,
      },
      validate: [
        [ "level", "subLevel", "range", "base", "gross", "net",
        "reward", "larder", "clearance", "travels", "taxes", "ssecurity"
        ]
      ]
    };
    try {
      const res = await API.peticion(params)
      if (res.status === 200 && res.data.code === "200") {
        toast(<Notification type={"modifica_exito"} withIcon />);
        window.location.href = "#/template/salarios"
      }
      else{
        toast(<Notification type={"modifica_error"} backMessage={res.data.message} withIcon />);
      }
    } catch (error) {
      toast(<Notification type={"consultar_servidor_error"} withIcon />);
    }
  }
};

export const getSalariesTypes = async() => {
  const params = {
    action: "select",
    table: 'salariestypes',
    condition: {
      enabled: 1,
    },
    order: "nameSalaryType ASC",
  }

  const res = await API.peticion(params)
  if(res.status === 200 && res.data.code === "200"){
    return res.data.data
  }
  else {
    return []
  }
}

export const getSalarie = async(id) => {
  const params = {
    action: "select",
    table: 'salaries',
    condition: {
      idSalary: id,
    }
  }

  const res = await API.peticion(params)
  if(res.status === 200 && res.data.code === "200"){
    return res.data.data
  }
  else {
    return []
  }
}
import { Alert, Button } from "reactstrap";
import { doRequest } from "../../../helpers/requests";
import { useState } from "react";
import FullFormLoader from "../../../components/Loader/FullFormLoader";

export default function AprobarAdquisicion({ id, status, setStatus }) {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");

    async function send() {
        setLoading(true);
        const params = {
            action: "select",
            table: "warehouseincomes",
            condition: {idIncome: id}
        };
        const res = await doRequest("receiver/receiver.php", params, false, false);
        if(res.length > 0 && res[0].idProviderAccount){
            params.action = "update";
            params.rows = {keyStat: 142};
            params.validate = [];
            const res2 = await doRequest("receiver/receiver.php", params, true);
            if(res2.length > 0){
                setError("");
                setStatus("142");
            }
        }else{
            setError("No se ha seleccionado una cuenta bancaria para realizar el pago al proveedor");
        }
        setLoading(false);
    }
    /* End Table */

    return (
        <div>

            <Alert color="danger" isOpen={error.length > 0} className="m-4">
                <p>{error}</p>
            </Alert>

            <div className=" d-flex justify-content-center mt-5">
                {
                    !status ?
                        <Button onClick={()=>send()}>Aprobar Adquisición</Button>
                    :
                    <h6>Adquisición en proceso de pago</h6>
                }
            </div>

            <FullFormLoader show={loading} />
        </div>
    );
}

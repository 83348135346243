import * as XlsxPopulate from "xlsx-populate";
import { saveAs } from "file-saver";

/**
 * Formateador de numeros de concurrencias
 */
const formatter = new Intl.NumberFormat("es-MX", {
  style: "currency",
  currency: "MXN",
});

const formatInformation = (report) => {
  let tempInformation = {
    data: [],
    dataCapitulos: [],
    dataPartidas: [],
  };

  let prevNameChapter = "";
  let amontChapter = 0;
  let amountConcept = 0;
  report.data.map((concept) => {
    /* total chapter */
    let tempChapters = report.dataCapitulos.filter(
      (chapter) => chapter.keyChapter === concept.keyChapter
    );
    amontChapter = 0;
    tempChapters.map((chap) => {
      amontChapter += +chap.montoTotal;
    });

    /* Total Concept */
    let tempConcepts = report.dataPartidas.filter(
      (partida) => partida.conceptKey === concept.conceptKey
    );
    amountConcept = 0;
    tempConcepts.map((part) => {
      amountConcept += +part.montoTotal;
    });

    /* nameChapter */
    if (prevNameChapter !== concept.nameChapter) {
      prevNameChapter = concept.nameChapter;
      tempInformation.data.push({
        nameChapterPrint: concept.nameChapter,
        amountChapter: amontChapter,
        isChapter: true,
      });
      /* concept.nameChapterPrint = concept.nameChapter;
        concept.amountChapter = amontChapter; */
    }

    concept.amountPartida = amountConcept;

    tempInformation.data.push(concept);
  });

  return tempInformation;
};

/**
 * Hoja de estilos del excel
 */
const styles = {
  title: {
    horizontalAlignment: "center",
    bold: true,
  },
  bold: {
    bold: true,
  },
  center: {
    horizontalAlignment: "center",
  },
  headerBorder: {
    bold: "true",
    border: true,
    fill: "BFBFBF",
  },
  header: {
    bold: "true",
    border: true,
    fill: "BFBFBF",
  },
  headerBoderBold: {
    bold: "true",
    border: true,
  },
  contentBorder: {
    border: true,
  },
  contentBorderB: {
    border: true,
    bold: true,
  },
  contentBorderRigth: {
    horizontalAlignment: "right",
  },
  contentBorderRigthB: {
    horizontalAlignment: "right",
    bold: true,
  },
};

export async function saveReport49(report, title, config) {
  XlsxPopulate.fromBlankAsync().then(async (workbook) => {
    const sheet1 = workbook.sheet(0);
    //Titulo
    sheet1
      .range(`B1:C1`)
      .merged(true)
      .value(`FISCALIA GENERAL DEL ESTADO DE MÉXICO`)
      .style(styles.title);
    sheet1.range(`B2:C2`).merged(true).value("SAPP").style(styles.title);
    sheet1.range(`B3:C3`).merged(true).value(title).style(styles.center);
    /* sheet1.range(`B4:C4`).merged(true).value({ title }).style(styles.title); */

    sheet1.cell(`B6`).value("TOTAL").style([styles.center,styles.bold]);

    sheet1.cell(`C6`).value("IMPORTE").style([styles.center,styles.bold]);
    let index = 7;

    const information = formatInformation(report);


    if(information.length > 4000){
        information.data.map((obj) => {
            if (obj.isChapter) {
              sheet1.cell(`B${index}`).value(obj.nameChapterPrint);
              sheet1
                .cell(`C${index}`)
                .value(
                  obj.amountChapter === undefined || obj.amountChapter === null
                    ? formatter.format(0)
                    : formatter.format(obj.amountChapter)
                )
                
              index++;
            } else {
              sheet1.cell(`B${index}`).value(obj.nameConcept);
              sheet1
                .cell(`C${index}`)
                .value(
                  obj.amountPartida === undefined || obj.amountPartida === null
                    ? formatter.format(0)
                    : formatter.format(obj.amountPartida))
                
              index++;
            }
          });
      }else{
        information.data.map((obj) => {
            if (obj.isChapter) {
              sheet1.cell(`B${index}`).value(obj.nameChapterPrint).style(styles.bold);
              sheet1
                .cell(`C${index}`)
                .value(
                  obj.amountChapter === undefined || obj.amountChapter === null
                    ? formatter.format(0)
                    : formatter.format(obj.amountChapter)
                )
                .style(styles.contentBorderRigthB);
              index++;
            } else {
              sheet1.cell(`B${index}`).value(obj.nameConcept);
              sheet1
                .cell(`C${index}`)
                .value(
                  obj.amountPartida === undefined || obj.amountPartida === null
                    ? formatter.format(0)
                    : formatter.format(obj.amountPartida)
                )
                .style(styles.contentBorderRigth);
              index++;
            }
          });
      }





    

    sheet1.column("B").width(80);
    sheet1.column("C").width(30);

    //Subtitulos
    return workbook.outputAsync().then((res) => {
      saveAs(res, `${title}.xlsx`);
    });
  });
}

import React, { useState } from "react";
import { useEffect } from "react";
import { peticionesReceiver } from "../../../../helpers/peticionesReceiver";
import { toast } from "react-toastify";
import Notification from "../../../../components/Notification/Notification";

const InfoUsuario = (props) => {
  const { idEmployee, setStaff,loading, setLoading } = props;
  const [staffComponent, setStaffComponent] = useState("");
  const API = peticionesReceiver();

  useEffect(() => {
    getStaff();
  }, []);

  const getStaff = async () => {
    setLoading(true)
    const params = {
      action: "getStaffData",
      idStaff: idEmployee[0],
    };
    await API.peticionEndPoint(params, "app/facades/reports/reportsF.php")
      .then((res) => {
        if (res.status === 200 && res.data.code === "200") {
          setStaffComponent(res.data.data[0]);
          setStaff(res.data.data[0]);
        } else {
          toast(
            <Notification
              type={"consultar_error"}
              backMessage={res.data.message}
              withIcon
            />
          );
        }
      })
      .finally(() => {
        setLoading(false)
      })
      .catch((err) => {
        toast(<Notification type={"consultar_servidor_error"} withIcon />);
      });
  };

  return (
      <div className="d-flex flex-md-row flex-column">
        <div className="text-center">
          <img
            src={staffComponent.src}
            alt="archivo"
            className="img-fluid mb-3 mb-md-0"
            width={150}
            height="auto"
          />
        </div>
        <div className="d-flex flex-column text-left ml-md-3 mt-auto">
          <span>
            <span className="font-weight-bold headline-7">Numero de empleado:</span>{" "}
            {staffComponent.staffNumber}
          </span>
          <span>
            <span className="font-weight-bold headline-7">Nombre:</span>{" "}
            {staffComponent.name}
          </span>
          <span>
            <span className="font-weight-bold headline-7">Puesto laboral: </span>{" "}
            {staffComponent.nameJob}
          </span>
          <span>
            <span className="font-weight-bold headline-7">Adscripcion:</span>{" "}
            {staffComponent.nameArea}
          </span>
          <span>
            <span className="font-weight-bold headline-7">Status:</span>{" "}
            {staffComponent.nameStatStaff}
          </span>
        </div>
      </div>
  );
};

export default InfoUsuario;

import React, { useState, useEffect } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Col,
  Row,
  Collapse,
  Alert
} from "reactstrap";
import * as Yup from "yup";
import RegistroComponent from "../others/RegistroComponent";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import Notification from "../../../../components/Notification/Notification";
import { peticionesReceiver } from "../../../../helpers/peticionesReceiver";
import ModalConfirmation from "../../../../components/Modal/ModalConfirmation";

export default function ModalEvidencias({
  modal,
  setModal,
  title,
  backdrop,
  keyboard,
  idActivity,
  progress,
  refreshMethod,
  btnClose
}) {
  const API = peticionesReceiver();

  const [data, setData] = useState("");
  const Month = new Date().getMonth(); //Mes actual se utiliza para saber que registro se ingresa
  const [aprove, setAprove] = useState(true);

  const [modalConfirmation, setModalConfirmation] = useState(false);


  useEffect(() => {
    if (modal) {
      getData();
      getActivityProgram();
    } else {
      resetForm()
    }
  }, [modal]);

  const getActivityProgram = () => {
    const params = {
      action: "getActivityProgram",
      idActivity: idActivity,
    };

    API.peticionEndPoint(params, "app/facades/planning/planningF.php")
      .then((res) => {
        if (res.data.status === "success" && res.data.code === "200") {
          setData(res.data.data);
        } else {
          toast(
            <Notification
              type={"consultar_error"}
              backMessage={res.data.message}
              withIcon
            />
          );
        }
      })
      .catch((err) => {
        toast(<Notification type={"consultar_servidor_error"} withIcon />);
      });
  };

  const defaultData = progress ? {
    idProgress: progress.idProgress,
    progress: +progress.progress,
    notes: "",
  } :
    {
      progress: "",
      notes: "",
    };
  
  const description = progress ? progress.description : "";
  const nameStat = progress ? progress.nameStat : "";
  
  const prepareData = async (values) => {
    if (aprove) {
      const params = {
        action: "saveProgress",
        idProgress: values.idProgress,
      };
      const res = await API.peticionEndPoint(params, "app/facades/planning/planningF.php")
      if (res.data.status === "success" && res.data.code === "200") {
        <Notification
          type={"agrega_exito"}
          backMessage={"Evidencia aprobada con exito"}
          withIcon
        />
      } else {
        toast(
          <Notification
            type={"consultar_error"}
            backMessage={res.data.message}
            withIcon
          />
        );
      }
    } else {
      values.idActivity = idActivity
      values.keyMonth = data[Month].keyMonth
      values.keyStat = 89
      const params = {
        action: "update",
        table: "activitiesprogress",
        rows: values,
        condition: {
          idProgress: values.idProgress
        },
      };
      const re = await API.peticion(params);
      if (
        re.status === "success" ||
        re.status === "200" ||
        re.status === 200
      ) {
        toast(
          <Notification
            type={"agrega_exito"}
            backMessage={re.data.message}
            withIcon
          />
        );
      } else {
        toast(
          <Notification
            type={"modifica_error"}
            backMessage={re.data.message}
            withIcon
          />
        );
      }
    }
    if (refreshMethod) {
      refreshMethod() 
    }
    setModal(false)
  }

  const getData = async () => {
    const params = {
      action: "multiselect",
      table: "activitiesprogress",
      conditions: `idProgress = ${values.idProgress}`,
      rows: "notes"
    };
    const re = await API.peticion(params);
    if(re.status === 200 && re.data.code === "200"){
      const data = re.data.data[0];
      if (data.notes !== "") {
        setAprove(false)
        setFieldValue(["notes"], data.notes);  
      }
    } else {
      toast(
        <Notification
          type={"consultar_error"}
          backMessage={re.data.message}
          withIcon
        />
      );
      if (refreshMethod) {
        refreshMethod() 
      }
      setModal(false)
    }
  }

  const FormSchema = Yup.object().shape({
    notes: Yup.string()
  });

  const onChange = (e) => {
    if (e.target.name === "notes") {
      setAprove(e.target.value.length === 0)
    }
    setFieldValue(e.target.name, e.target.value);
  };

  const {
    handleSubmit,
    values,
    handleBlur,
    errors,
    touched,
    setFieldValue,
    resetForm,
  } = useFormik({
    initialValues: defaultData,
    onSubmit: (values) => {
      setModalConfirmation(true)
    },
    validationSchema: FormSchema,
    enableReinitialize: true,
  });

  const toggle = () => {
    setModal(!modal);
  };

  const CloseBtn = (
    <>
      {btnClose ? (
        <i className="eva eva-close cursor-pointer" onClick={toggle} />
      ) : (
        <></>
      )}
    </>
  );

  return (
    <>
      <ModalConfirmation
        modalTitle={"Crear"}
        modal={modalConfirmation}
        setModal={setModalConfirmation}
        crear={prepareData}
        isEdit={false}
        values={values}
      >
        <div className="d-flex justify-content-center">
          <h6>¿Está seguro de guardar el avance?</h6>
        </div>
      </ModalConfirmation>
      <Modal
        isOpen={modal}
        backdrop={backdrop}
        keyboard={keyboard}
        toggle={toggle}
        scrollable={true}
        style={{ minWidth: "95%", maxHeight: "90%" }}
      >
        <ModalHeader close={CloseBtn}></ModalHeader>
        <ModalBody className="mx-2">
          <h2 className="text-center mb-2 text-secondary">
            {title}
          </h2>
          <form onSubmit={handleSubmit} className="mt-4" noValidate>
            <Row xs={1} md={3} lg={6} className="d-flex justify-content-around align-items-center mb-3">
              {data !== "" &&
                data.map((element) => (
                  <div key={element.keyMonth} className="border">
                    <RegistroComponent
                      month={element}
                      label="Progreso"
                      inputName="progress"
                    />
                  </div>
                ))}
            </Row>

            <Row>
              <Col
                xs={12}
                md={12}
                lg={6} className="border border-success p-2" style={{borderWidth: '3px !important'}}>
                {data !== "" && (
                  <RegistroComponent
                    month={data[Month]}
                    label="Progreso"
                    inputName="progress"
                    withInput
                    value={values.progress}
                    onChange={onChange}
                    handleBlur={handleBlur}
                    errors={errors.progress}
                    touched={touched.progress}
                    isDisable={true}
                  />
                )}
              </Col>

              <Col xs={12} md={12} lg={6}>
                <label>
                  Descripcion de la meta
                </label>
                <h6 className="text-primary">{description}</h6>
                <label htmlFor="notes">
                    Observaciones
                </label>
                <textarea
                  name="notes"
                  id="notes"
                  onChange={onChange}
                  onBlur={handleBlur}
                  rows={5}
                  value={values.notes}
                  className="form-control"
                  disabled={nameStat !== "POR APROBAR"}
                />
              </Col>
            </Row>

            <div className="text-right mt-2">
              {nameStat === "POR APROBAR" ? (
                <>
                  <Button type="submit" onSubmit={handleSubmit} color={aprove ? "success" : "danger"} className="mr-2">{aprove ? 'Aprobar evidencia' : 'Señalar observaciones'}</Button>
                  <Button color="secondary" onClick={toggle}>Cancelar</Button>
                </>
              ): null}
            </div>
          </form>
        </ModalBody>
      </Modal>
    </>
  );
}

import { peticionesReceiver } from "../../../helpers/peticionesReceiver";
const API = peticionesReceiver();

export const createFinancialSources = async (finances) => {
  if (finances) {
    const params = {
      action: "insert",
      table: "financialsources",
      rows: finances,
      order: "idFinancialSource asc",
      validate: [
        ["nameFinancialSource","financialSourceKey"]
      ]
    };
    try {
      const res = await API.peticion(params)
      if (res.status === 200 && res.data.code === "200") {
        return {success: true}
      }
      else{
        return {success: false, message: res.data.message}
      }
    } catch (error) {
      return {success: false, message: error}
    }
  }
};

export const updateFinancialSources = async (finances) => {
  if (finances) {
    const params = {
      action: "update",
      table: "financialsources",
      rows: finances,
      condition: {
        idFinancialSource: finances.idFinancialSource,
      },
      validate: [
        ["nameFinancialSource","financialSourceKey"]
      ]
    };
    try {
      const res = await API.peticion(params)
      if (res.status === 200 && res.data.code === "200") {
        return {success: true}
      }
      else{
        return {success: false, message: res.data.message}
      }
    } catch (error) {
      return {success: false, message: error}
    }
  }
};
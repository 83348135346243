import { Col, Row, Table, Button, Input, Form, FormGroup, FormFeedback, Progress } from "reactstrap";
import { CancelOutlined } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import csvtojson from "csvtojson";

import ModalConfirmation from "../../../../components/Modal/ModalConfirmation";
import Notification from "../../../../components/Notification/Notification";
import { peticionesReceiver } from "../../../../helpers/peticionesReceiver";
import Datatable from "../../../../components/Datatable/Datatable";
import Widget from "../../../../components/Widget/Widget";
import Loader from "../../../../components/Loader/Loader";

import is from "./InputFileClear.module.scss";
import s from "./TableErros.module.scss";
import { GetPermissions } from "../../../../libs/permissions/getPermissions";

const columns = [
    {
        csv: [
            "CVE EMPLEADO",
            "ACUMULADO ANUAL",
            "TOTAL QUINCENAS",
            "BENEFICIO",
            "PI",
            "PERIODO",
            "ANIO",
        ],
        bd: [
            "STAFFNUMBER",
            "AMOUNTYEAR",
            "BEWEEKLYS",
            "MOUNT",
            "PI",
            "PERIOD",
            "YEAR"
        ],
        action: "saveFileForemex",
    }
];

const formatter = new Intl.NumberFormat("es-MX", {
    style: "currency",
    currency: "MXN",
});

export default function CargaLayouts() {
    GetPermissions();

    const [modal, setModal] = useState(false);
    const layouts = [{ nameLayouts: "FOREMEX", src: "https://pruebas.sapptesting.click/sapp/back/files/layouts/foremex.csv" }];
    const [validating, setValidating] = useState(0); // estado de validacion
    const [processing, setProcessing] = useState(0); // porcentaje de validacion
    const [data, setData] = useState([]); // Array de objetos del csv para enviar
    const [invalidFile, setInvalidFile] = useState(""); // Mensaje de error par input
    const [errors, setErrors] = useState([]); // Mensaje de error para input
    const [noErrors, setNoErrors] = useState(0)
    const [loading, setLoading] = useState(false)
 
    const ref = React.useRef();

    let fileReader;

    const handleFile = (e) => {
        if (e.target.files.length !== 0) {
            if (e.target.files[0].type !== "text/csv") {
                setInvalidFile("Formato de archivo invalido");
            } else {
                setInvalidFile("");
                fileReader = new FileReader();
                fileReader.onloadend = handleFileRead;
                fileReader.readAsText(e.target.files[0]);
            }
        } else {
            clearInfo();
        }
    };

    const handleFileRead = async (e) => {
        const jsonOb = await csvtojson({
            noheader: true,
            headers: columns[0].bd,
        }).fromString(fileReader.result)
        if (Object.keys(jsonOb[0]).length !== columns[0].csv.length) {
            setInvalidFile("Cantidad de columnas incorrecta")
        } else if (columns[0].csv.some(column => !Object.values(jsonOb[0]).includes(column))) {
            setInvalidFile("Nombres de columnas incorrectos")
        } else if (jsonOb.length <= 1) {
            setInvalidFile("Archivo vacio");
        } else {
            setData(jsonOb.slice(1));
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setModal(true);
    };

    function handleClear() {
        ref.current.value = "";
        clearInfo();
    }

    const clearInfo = () => {
        if (validating > 0) {
            setValidating(0);
        }
        setData([]);
        setInvalidFile("");
        setErrors([]);
        setNoErrors(0);
    };

    async function backRequest(params) {
        const API = peticionesReceiver();
        try {
            const res = await API.peticionEndPoint(params, "app/facades/jobs/jobsF.php");
            if (res.status === 200 && res.data.status === "success") {
                toast(<Notification type={"agrega_exito"} backMessage={res.data.message} withIcon />);
                setLoading(false);
                return res.data.status;
            } else {
                toast(<Notification type={"agrega_error"} backMessage={res.data.message} withIcon />);
                setLoading(false);
            }
        } catch (err) {
            toast(<Notification type={"agrega_error"} withIcon />);
            setLoading(false);
        }
        return ""
    }

    const enviaInfo = async () => {
        const params = {
            action: columns[0].action,
            rows: data,
        };
        setLoading(true);
        const info = await backRequest(params);
        if (info === "success") {
            setValidating(3);
        }
    }

    useEffect(() => {
        if (data.length > 0) {
            processFile();
        }
    }, [data])

    const processFile = () => {
        setValidating(1);
        const errors = [];
        const staffNumbers = new Map();

        data.forEach((obj, index) => {
            const errorObj = {
                STAFFNUMBER: obj.STAFFNUMBER,
                AMOUNTYEAR: obj.AMOUNTYEAR,
                BEWEEKLYS: obj.BEWEEKLYS,
                MOUNT: obj.MOUNT,
                PI: obj.PI,
                PERIOD: obj.PERIOD,
                YEAR: obj.YEAR,
                empty: [],
                error: [],
                repited: false
            };

            const staffNumber = obj.STAFFNUMBER;
            if (isNaN(staffNumber) || staffNumber.includes(".") || Number(staffNumber) < 0) {
                errorObj.error.push("STAFFNUMBER");
            }
            if (staffNumbers.has(staffNumber)) {
                staffNumbers.set(staffNumber, staffNumbers.get(staffNumber) + 1);
            } else {
                staffNumbers.set(staffNumber, 1);
            }
            if (isNaN(obj.AMOUNTYEAR) || obj.AMOUNTYEAR.includes("-")) {
                errorObj.error.push("AMOUNTYEAR");
            }
            if (isNaN(obj.BEWEEKLYS) || obj.BEWEEKLYS.includes("-")) {
                errorObj.error.push("BEWEEKLYS");
            }
            if (isNaN(obj.MOUNT) || obj.MOUNT.includes("-")) {
                errorObj.error.push("MOUNT");
            }
            if (isNaN(obj.PI) || obj.PI.includes("-")) {
                errorObj.error.push("PI");
            }
            if (isNaN(obj.PERIOD) || Number(obj.PERIOD) < 1 || Number(obj.PERIOD) > 24) {
                errorObj.error.push("PERIOD");
            }
            if (isNaN(obj.YEAR) || obj.YEAR.length !== 4) {
                errorObj.error.push("YEAR");
            }
            Object.entries(obj).forEach(([key, value]) => {
                if (value === "") {
                    errorObj.empty.push(key);
                }
            });
            errors.push(errorObj);
            if (errorObj.empty.length || errorObj.error.length || errorObj.repited) {
                setNoErrors(noErrors + 1)
            }
            setProcessing(Math.ceil((index * 100) / data.length));
        });
        setErrors(errors.map(error => { error.repited = staffNumbers.get(error.STAFFNUMBER) > 1; return error }).reverse());
        setValidating(errors.length > 0 ? 2 : 1);
        setProcessing(errors.length > 0 ? 99 : 100);
    }

    const headers = ["CVE EMPLEADO", "ACUMULADO ANUAL", "TOTAL QUINCENAS", "BENEFICIO", "PI", "PERIODO", "ANIO"]

    function getClassName(row, key) {
        let className = "p-2"
        if (row.error.includes(key)) {
            className += " text-danger";
        }
        if (row.repited) {
            className += ` ${s.trPrimaryBg}`;
        }
        if (row.empty.includes(key)) {
            className += ` ${s.trDangerBg}`;
        }
        return className
    }

    function format(row) {
        return !isNaN(Number(row)) ? formatter.format(row) : row
    }

    const cols = [
        {
            data: null,
            render(row) {
                const className = getClassName(row, "STAFFNUMBER");
                return <div className={className}>{row.STAFFNUMBER !== "" ? row.STAFFNUMBER : "-"}</div>
            }
        },
        {
            data: null,
            render(row) {
                const className = getClassName(row, "AMOUNTYEAR");
                return <div className={className}>{row.AMOUNTYEAR !== "" ? format(row.AMOUNTYEAR) : "-"}</div>
            }
        },
        {
            data: null,
            render(row) {
                const className = getClassName(row, "BEWEEKLYS");
                return <div className={className}>{row.BEWEEKLYS !== "" ? format(row.BEWEEKLYS) : "-"}</div>
            }
        },
        {
            data: null,
            render(row) {
                const className = getClassName(row, "MOUNT");
                return <div className={className}>{row.MOUNT !== "" ? format(row.MOUNT) : "-"}</div>
            }
        },
        {
            data: null,
            render(row) {
                const className = getClassName(row, "PI");
                return <div className={className}>{row.PI !== "" ? format(row.PI) : "-"}</div>
            }
        },
        {
            data: null,
            render(row) {
                const className = getClassName(row, "PERIOD");
                return <div className={className}>{row.PERIOD !== "" ? row.PERIOD : "-"}</div>
            }
        },
        {
            data: null,
            render(row) {
                const className = getClassName(row, "YEAR");
                return <div className={className}>{row.YEAR !== "" ? row.YEAR : "-"}</div>
            }
        }
    ]

    const columnDefs = [
        {
            targets: [0, 5, 6],
            className: "text-center p-0"
        },
        {
            targets: [1, 2, 3, 4],
            className: "text-right p-0"
        }
    ]

    return (
        <>
            <Widget className="widget-p-md">
                <div className="text-center text-md-left mb-3">
                    <h2 className="text-secondary">GUARDAR ARCHIVO FOREMEX</h2>
                </div>
                <Row className="m-0">
                    <Col xs={12} md={8} className="p-0 mx-auto">
                        <Table striped responsive className="border border-default">
                            <thead className="bg-default ">
                                <tr>
                                    <th className="w-75 text-light border-right border-light">
                                        Plantilla
                                    </th>
                                    <th className="w-25 text-light">Descargar</th>
                                </tr>
                            </thead>
                            <tbody className="">
                                {layouts.length === 0 && (
                                    (<tr>
                                        <td colSpan={2}>Sin informacion</td>
                                    </tr>)
                                )}
                                {layouts.map((el, i) => (
                                    <tr key={i}>
                                        <td className=" border-right border-default">
                                            {el.nameLayouts}
                                        </td>
                                        <td className="text-center">
                                            <a href={el.src}>
                                                <i
                                                    className="fa fa-file-excel-o"
                                                    style={{ color: "#086400", cursor: "pointer" }}
                                                ></i>
                                            </a>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </Col>
                </Row>
                <Form className="mt-5" onSubmit={handleSubmit}>
                    <ModalConfirmation
                        modalTitle="Cargar archivo"
                        modal={modal}
                        setModal={setModal}
                        crear={enviaInfo}
                    >
                        <div className="d-flex justify-content-center">
                            <h6>¿Está seguro de cargar estos registros?</h6>
                        </div>
                    </ModalConfirmation>
                    <FormGroup>
                        <Row className="mx-1">
                            <Col xs={12} md={7} className="mx-auto">
                                <h6>
                                    Cargar archivo:
                                </h6>
                                <FormGroup className="position-relative">
                                    <Input
                                        type="file"
                                        name="file"
                                        onChange={handleFile}
                                        accept=".csv"
                                        invalid={invalidFile !== ""}
                                        innerRef={ref}
                                        className="form-control"
                                    />
                                    {ref && ref.current && ref.current.value !== "" && (
                                        <CancelOutlined
                                            className={
                                                invalidFile !== "" ? is.btnClear : is.btnClearErr
                                            }
                                            onClick={handleClear}
                                        />
                                    )}
                                    <FormFeedback>{invalidFile}</FormFeedback>
                                </FormGroup>
                            </Col>
                        </Row>
                    </FormGroup>
                    <div className="mt-5">
                        {validating === 1 ? (
                            <div className="w-75 m-5 mx-auto text-center">
                                {processing < 100 ? "Validando..." : "Validado"}
                                <Progress
                                    color="primary"
                                    striped
                                    animated
                                    className="mx-5 my-2"
                                    value={processing}
                                >
                                    {processing}%
                                </Progress>
                            </div>
                        ) : validating === 2 ? (
                            <>
                                {noErrors > 0 && (
                                    <>
                                        <div className="text-center">
                                            <h4>Errores encontrados</h4>
                                        </div>
                                        <table className="table-borderless my-1">
                                            <tbody>
                                                <tr>
                                                    <td className={s.trDangerBg}></td>
                                                    <td className="pl-1">Campos faltantes</td>
                                                </tr>
                                                <tr>
                                                    <td className="text-danger text-center px-1">Texto</td>
                                                    <td className="pl-1">
                                                        Información incorrecta o desconocida
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className={s.trPrimaryBg}></td>
                                                    <td className="pl-1">Registro repetido</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <Datatable
                                            control="front"
                                            data={errors}
                                            headers={headers}
                                            columns={cols}
                                            columnDefs={columnDefs}
                                            ordering={false}
                                            searching={false}
                                        />
                                    </>
                                )}
                            </>
                        ) : (
                            validating === 3 && (
                                <div className="text-center my-5">
                                    <h6>Información cargada con exito.</h6>
                                </div>
                            )
                        )}
                    </div>
                    <div className="text-center mt-2">
                        <Button color="success" type="submit" disabled={invalidFile !== "" || data.length === 0 || noErrors !== 0 || validating === 3 || loading}>
                            Guardar
                        </Button>
                    </div>
                    {loading && (
                        <div className="p-4">
                            <Loader />
                        </div>
                    )}
                </Form>
            </Widget>
        </>
    );
}
import React, { useState, useEffect } from "react";
import Widget from "../../../components/Widget/Widget";
import Datatable from "../../../components/Datatable/Datatable";
import { GetPermissions } from "../../../libs/permissions/getPermissions";
import { toast } from "react-toastify";
import Notification from "../../../components/Notification/Notification";
import { peticionesReceiver } from "../../../helpers/peticionesReceiver";
import Loader from "../../../components/Loader/Loader";
import { Button, Row, Collapse, Col } from "reactstrap";
import FormTickets from "./components/Form/FormTickets";
import { useSelector } from "react-redux";
import TicketDetail from "./components/Detail/TicketDetail";
import { SelectTypeHeadSingle } from "../../../components/GenericInputsFormik/index";

const Tickets = () => {
  const [permissions, setPermissions] = useState(true);
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [collapse, setCollapse] = useState("home"); //detail, add, home
  const [agentLogged, setAgentLogged] = useState([]);
  const [ticketForEdit, setTicketForEdit] = useState("");
  const { profileType, id } = useSelector((state) => state.userInfo);
  const user = useSelector((state) => state.userInfo);
  const idAreaLogged = profileType.selectProfile.idArea;
  const [isAgent, setIsAgent] = useState(false);
  const [valueSelect, setValueSelect] = useState(false);

  GetPermissions(setPermissions, setLoading);
  const API = peticionesReceiver();

  useEffect(() => {
    getUser();
  }, []);

  const getUser = async () => {
    const data = {
      action: "multiselect",
      table:
        "topicagents A INNER JOIN users B on (A.idUser = B.idUser) INNER JOIN topics C on (A.keyTopic = C.keyTopic)",
      rows: "A.idTopicAgent,C.keyTopic, C.nameTopic",
      conditions: `A.enabled = 1 and B.idUser = ${user.id}`,
    };
    await API.peticion(data)
      .then((res) => {
        if (res.status === 200 && res.data.code === "200") {
          if (res.data.data.length > 0) {
            
            setAgentLogged(res.data.data);
          }
        } else {
          toast(<Notification type={"consultar_error"} withIcon />);
        }
      })
      .catch((err) => {
        toast(<Notification type={"consultar_servidor_error"} withIcon />);
      });
  };

  const headers = [
    "#",
    "Numero de ticket",
    "Estauts",
    "Titulo",
    "Reportado por",
    "Descripción",
    "Severidad",
    "Acciones",
  ];

  const columns = [
    { data: "index" },
    { data: "numTicket" },
    { data: "nameStat" },
    { data: "title" },
    { data: "fullName" },
    { data: "description" },
    { data: "nameSeverity" },
    {
      data: null,
      render: function (row) {
        return actionsTable(row);
      },
    },
  ];

  const actionsTable = (row) => {
    return (
      <>
        <td className=" d-flex flex-column justify-content-center align-items-center">
        { permissions.CON && (
          <Button
          className="text-center d-flex justify-content-center align-items-center mt-3"
          onClick={() => {
            changeView("detail", row);
          }}
        >
          <i className={" fa fa-eye text-white handCursor"} />
          Revisar Estatus
        </Button>
        )}
          
          {row.keyStat === "102" && !isAgent && permissions.UPD &&  (
            <Button
              color="warning"
              className="text-center d-flex justify-content-around align-items-center mt-3"
              onClick={() => {
                changeView("add", row);
              }}
            >
              <i className={" fa fa-pencil  handCursor"} />
              Editar Ticket
            </Button>
          )}
        </td>
      </>
    );
  };

  const columnDefs = [
    {
      targets: [0, 1, 2, 3, 4, 5, 6],
      className: "text-center",
    },
    {
      orderable: false,
      targets: [0],
    },
  ];

  const getParamsTableElmt = (order, records, page, search) => {
    const params = !isAgent
      ? {
          action: "datatable",
          table:
            "tickets A LEFT JOIN staff B on (A.idStaff= B.idStaff) LEFT JOIN status C on (C.keyStat = A.keyStat) LEFT JOIN types_severity D on (A.keySeverity = D.keySeverity) LEFT JOIN tickettrazability E on (E.idTicket = A.idTicket) LEFT JOIN topicagents F on (E.idTopicAgent= F.idTopicAgent) LEFT JOIN topics G on (F.keyTopic= G.keyTopic)",
          rows: "A.idTicket,A.title,A.numTicket,ifnull(A.nameReporter, concat(B.name,' ',B.pName,' ',B.mName)) as fullName ,A.description,A.keyTopic,A.keyStat,A.keySeverity,A.idStaff,A.nameReporter,A.phoneContact,A.emailContact,A.title,A.description,C.nameStat,A.keyStat,D.nameSeverity, G.nameTopic",
          conditions: `A.enabled = 1 and A.idArea = ${idAreaLogged}`,
          group: "A.idTicket",
          order,
          records: records.toString(),
          page: (page * records).toString(),
          search,
        }
      : {
          action: "datatable",
          table:
            "tickets A LEFT JOIN staff B on (A.idStaff= B.idStaff) LEFT JOIN status C on (C.keyStat = A.keyStat)  LEFT JOIN tickettrazability D on (A.idTicket = D.idTicket) LEFT JOIN topicagents E on (E.idTopicAgent= D.idTopicAgent) LEFT JOIN topics F on (E.keyTopic= F.keyTopic) LEFT JOIN types_severity G on (A.keySeverity= G.keySeverity)",
          rows: "A.idTicket,A.title,A.numTicket,ifnull(A.nameReporter, concat(B.name,' ',B.pName,' ',B.mName)) as fullName ,A.description,A.keyTopic,A.keyStat,A.keySeverity,A.idStaff,A.nameReporter,A.phoneContact,A.emailContact,A.title,A.description,C.nameStat,A.keyStat,F.nameTopic,G.nameSeverity",
          conditions: `A.enabled = 1 and ${valueSelect ? `E.idTopicAgent = ${valueSelect}` : `E.idUser = ${user.id}` } `,
          group: "A.idTicket",
          order,
          records: records.toString(),
          page: (page * records).toString(),
          search,
        };
        
    return params;
  };

  async function getData(order, records, page, search) {
    try {
      const res = await API.peticion(
        getParamsTableElmt(order, records, page, search)
      );
      if (res.status === 200 && res.data.code === "200") {
        const data = res.data;

        data.data.forEach((value, index) => (value.index = 1 + index));
        return data;
      } else {
        toast(
          <Notification
            type={"consultar_error"}
            backMessage={res.data.message}
          />
        );
        return null;
      }
    } catch (err) {
      toast(<Notification type={"consultar_servidor_error"} withIcon />);
      return null;
    }
  }

  const changeView = (view, row) => {
    if (collapse !== "home") {
      setTicketForEdit("");
      setCollapse("home");
    } else {
      setTicketForEdit(row);
      setCollapse(view);
    }
  };
  const showDetail = (row) => {
    if (collapse !== "home") {
      setTicketForEdit("");
      setCollapse("home");
    } else {
      setTicketForEdit(row);
      setCollapse("detail");
    }
  };

  const changeProfile = () => {
    setIsAgent(!isAgent);
    
    setRefresh(true);
  };

  const onChange = (e) => {
    setRefresh(true)
    setValueSelect(e.target.value);
  };

  return (
    <Widget className="widget-p-md">
      {loading ? (
        <div className="text-center">
          <Loader />
        </div>
      ) : (
        <>
          {permissions.CON || permissions.UPD ? (
            <>
              {collapse === "home" && (
                <div className="text-center">
                  <h2>Tickets</h2>
                </div>
              )}

              <Row className="mb-5">
                <Col className="text-right mb-3">
                  <Button
                    onClick={() => {
                      changeView("add");
                    }}
                    color={collapse === "home" ? "success" : "danger"}
                  >
                    {collapse === "home" ? "Agregar" : "Regresar"}
                  </Button>
                </Col>
              </Row>

              {agentLogged.length > 0 && collapse === "home" && (
                <>
                  <Row className="mb-5 d-flex justify-content-center align-items-center">
                    <Col md={12} lg={5} className="d-flex align-items-center">
                      <p className="mr-2">Perfil Seleccionado: </p>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          height: "fit-content",
                        }}
                      >
                        <div
                          className="toggle-container mt-0 p-0"
                          style={{ width: "120px" }}
                          onClick={() => {
                            changeProfile();
                          }}
                        >
                          <div
                            className={`toggle-btn ${isAgent ? "disable" : ""}`}
                            style={{ width: "auto" }}
                          >
                            {isAgent ? "Agente" : "Usuario"}
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Collapse  isOpen={isAgent}>
                    <Row className="d-flex justify-content-center">
                    <Col md={12} lg={5}>
                      {isAgent && (
                        <SelectTypeHeadSingle
                          label="Busqueda por perfil de agente"
                          isRequired={true}
                          inputName="idTopicAgent"
                          optionsArray={agentLogged}
                          defaultOption="Seleccione un tema"
                          onChangeMethod={onChange}
                          value={valueSelect}
                          optionValue="idTopicAgent"
                          optionName="nameTopic"
                        />
                      )}
                    </Col>
                    </Row>
                  </Collapse>
                </>
              )}

              <Collapse isOpen={collapse === "home" ? true : false}>
                <div>
                  <Datatable
                    headers={headers}
                    columns={columns}
                    columnDefs={columnDefs}
                    petition={getData}
                    control="back"
                    stateRefresh={[refresh, setRefresh]}
                    searching={true}
                    order={{ col: 1, opt: "desc" }}
                  />
                </div>
              </Collapse>

              <Collapse isOpen={collapse === "add" ? true : false}>
                {collapse === "add" && (
                  <FormTickets
                    collapse={collapse}
                    setCollapse={setCollapse}
                    setRefreshP={setRefresh}
                    ticketForEdit={ticketForEdit}
                    permissions={permissions}
                    idAreaLogged={idAreaLogged}
                  />
                )}
              </Collapse>
              <Collapse isOpen={collapse === "detail" ? true : false}>
                {collapse === "detail" && (
                  <TicketDetail
                    collapse={collapse}
                    setCollapse={setCollapse}
                    setRefreshP={setRefresh}
                    ticket={ticketForEdit}
                    setTicket={setTicketForEdit}
                    permissions={permissions}
                    idAreaLogged={idAreaLogged}
                    isAgent={isAgent}
                    agentLogged={agentLogged}
                  />
                )}
              </Collapse>
            </>
          ) : (
            <div className="text-center">
              No tiene permisos de ver este modulo
            </div>
          )}
        </>
      )}
    </Widget>
  );
};

export default Tickets;

import React, { useState } from "react";
import TableComponentCustom from "../../../components/TableComponent/TableComponentCustom";
import Widget from "../../../components/Widget/Widget";

const MatrizVengados = () => {
  const cabeceras = [
    "Id",
    "Objeto de gasto",
    "Denominacion",
    "Cuenta Cargo",
    "Descripción Cargo",
    "Cuenta Abono",
    "Descripción abono",
  ];
  const [parametros] = useState(
    {
      action: "datatable",
      table:
        "matrixexpense_accrued",
      rows: "idMatrix,expenseObject,expenseObjectName,charge,chargeName,pay,payName",
      conditions: `enabled = 1`,
      page: 0,
      records: 5,
      search: "",
      order: "",
    }
  );
  const filtro = [
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    false,
    false,
    false,
    false,
    false,
    false,
  ];

  return (
    <Widget>

      <TableComponentCustom
        titulo={"Egresos devengados"}
        cabecerasTabla={cabeceras}
        filtro={filtro}
        parametros={parametros}
      />
    </Widget>
  )
}

export default MatrizVengados
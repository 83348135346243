import { StylesProvider } from "@material-ui/core";
import {
  Canvas,
  Document,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
import { peticionesReceiver } from "../../../../helpers/peticionesReceiver";
import HeaderReport from "../../../../components/Pdf/Header";
const origin = window.location.origin;

const colors = {
  backgroundHeaders: "#646464",
};

const API = peticionesReceiver();

const styles = StyleSheet.create({
  contentBarCode: {
    width: "106.5mm",
    height: "28.5mm",
    borderWidth: 1,
    marginTop: 10,
    marginBottom: 20,
  },

  /* ----------------------------ESTILOS DE TEXTO ------------------------------ */
  centerTextRow: {
    flexDirection: "row",
    justifyContent: "center-center",
    alignItems: "center",
    textAlign: "center",
  },
  centerText: {
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
  },
  alingTextsColumn: {
    flexDirection: "column",
    justifyContent: "space-around",
    alignItems: "center",
  },
  alingTextsRow: {
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
  },
  titleText: {
    fontSize: 13,
    fontFamily: "Helvetica-Bold",
  },
  textTitleLabel: {
    fontSize: 10,
    /* fontFamisly: "Helvetica-Bold", */
  },  
  contentText: {
    fontSize: 6 ,
    /* fontFamily: "Helvetica-Bold", */
  },

  spaceFooter: {
    fontSize: 12,
    bottom: 10,
    left: 0,
    right: 0,
    width: "auto",
    height: 50,
    marginHorizontal: 10,
  },
  footer: {
    position: "absolute",
    fontSize: 12,
    bottom: 10,
    left: 0,
    right: 0,
    width: "auto",
    height: 30,
    marginHorizontal: 10,
    /*       borderColor:"#a9a9a9", */
    borderTopWidth: 1,
    flexDirection: "row",
    alignContent: "space-around",
    alignItems: "center",
  },
  title: {
    width: "100%",
    textAlign: "center",
    marginBottom: 10,
    /* color: '#a9a9a9', */
    fontSize: 16,
  },
  subTitle: {
    width: "100%",
    textAlign: "center",
    marginBottom: 10,
    /* color: '#a9a9a9', */
    fontSize: 14,
  },
  textFooter: {
    width: "50%",
    /* color: '#a9a9a9', */
    textAlign: "center",
    fontSize: 8,
  },
});



function generateBarCode(value) {
  let barCode = value.toString(); 
  while (barCode.length < 10) {
    barCode = "0" + barCode;
  }
  return barCode;
}

export async function generateBarsCode(data, model, title, fileNo) {
  
  return {
    report: (
      <>
        <Document>
          <Page style={styles.body}>
            <HeaderReport />
            <View>
              <Text style={styles.title}>{title}</Text>
            </View>
            <View style={styles.alingTextsColumn}>
              {data.map((label,index) => (
                
                <View style={styles.contentBarCode} wrap = {false}>
                  <View
                    style={[
                      styles.alingTextsRow,
                      { borderBottom: 1, height: "35px" },
                    ]}
                  >
                    <Image
                      style={{ height: "20px" }}
                      src={`${origin}/images/pdf/gobEDOMEX.png`}
                    />

                    <Text style={[styles.textTitleLabel]}>
                      Fiscalia General de Justicia
                    </Text>

                    <Image
                      style={{ height: "20px" }}
                      src={`${origin}/images/logo.png`}
                    />
                  </View>

                  {/* numbers */}
                  <View style={{ marginTop: 3 }}>
                    <View style={[styles.alingTextsRow, { marginBottom: 3 }]}>
                      <Text style={[styles.contentText,{width:"40%",margin:"auto", textAlign:"center"}]}>{label[4]}</Text>
                      <Text style={[styles.contentText,{width:"40%",margin:"auto", textAlign:"center"}]}>{label[6]}</Text>
                    </View>
                    <View style={[styles.alingTextsRow, { marginBottom: 3 }]}>
                      <Text style={[styles.contentText,{width:"40%",margin:"auto", textAlign:"center"}]}>{label[3]}</Text>
                      <Text style={[styles.contentText,{width:"40%",margin:"auto", textAlign:"center"}]}>{label[5]}</Text>
                    </View>
                  </View>

                  <View style={styles.alingTextsColumn}>
                    <Image
                      src={model.codebars[index]}
                      style={{
                        width: "46.5mm",
                        height: "14.5mm",
                      }}
                    />
                    <Text style={[styles.contentText,{marginBottom:3}]}>{generateBarCode(label[0])}</Text>
                  </View>
                </View>
            ))}
            </View>

            <View fixed style={styles.spaceFooter}></View>
            <View fixed style={styles.footer}>
              <Text style={styles.textFooter}>{fileNo}</Text>
              <Text
                style={styles.textFooter}
                render={({ pageNumber, totalPages }) =>
                  `PAGINA : ${pageNumber} / ${totalPages}`
                }
              />
            </View>
          </Page>
        </Document>
      </>
    ),
  };
}

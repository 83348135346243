import {
  Document,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
import HeaderReport from "../../../components/Pdf/Header";
import FooterReport from "../../../components/Pdf/Footer";
import { fMoney, validValue } from "../../../libs/tools/format";
import { getStaffPic } from "../../../libs/tools/images";

const fmtMny = new Intl.NumberFormat("es-MX", {
  style: "currency",
  currency: "MXN",
});

const fmtD = new Intl.DateTimeFormat("es-MX", {
  day: "2-digit",
  month: "long",
  year: "numeric",
  timeZone: "UTC",
});

const s = StyleSheet.create({
  body: {
    paddingVertical: "20px",
    paddingHorizontal: "20px",
  },

  title: {
    fontSize: "12px",
    textAlign: "center",
    fontFamily: "Helvetica-Bold",
  },

  header: {
    backgroundColor: "#adb5bd",
    textAlign: "center",
    color: "#FFFFFF",
    border: "1px solid #000",
    fontFamily: "Helvetica-Bold"
  },

  vCenter: { alignItems: "center", justifyContent: "center" },
  vCenterL: { alignItems: "left", justifyContent: "center" },
  vCenterR: { alignItems: "right", justifyContent: "center" },
  vCenterBtw: { alignItems: "stretch", justifyContent: "center" },

  dflexBtw: { display: "flex", justifyContent: "space-between" },
  dflexEnd: { display: "flex", justifyContent: "flex-end" },

  alignE: { alignContent: "flex-end" },

  row: { flexDirection: "row" },
  flexWrap: { display: "flex", flexWrap: "wrap", flexDirection: "row" },

  w5: { width: "5%" },
  w10: { width: "10%" },
  w15: { width: "15%" },
  w20: { width: "20%" },
  w25: { width: "25%" },
  w30: { width: "30%" },
  w35: { width: "35%" },
  w40: { width: "40%" },
  w45: { width: "45%" },
  w50: { width: "50%" },
  w55: { width: "55%" },
  w60: { width: "60%" },
  w65: { width: "65%" },
  w70: { width: "70%" },
  w75: { width: "75%" },
  w80: { width: "80%" },
  w85: { width: "85%" },
  w90: { width: "90%" },
  w100: { width: "100%" },
  wAuto: { width: "auto" },

  wFoto: { width: "10%", height: "auto", marginLeft: "auto" },

  border: { border: "1px solid #000" },
  borderRow: { border: "1px solid #000", borderTop: "0px" },
  borderT: { borderBottom: "1px solid #000" },
  borderB: { borderBottom: "1px solid #000" },
  borderB2: { borderBottom: "2px solid #000" },
  borderE: { borderRight: "1px solid #000" },
  borderS: { borderLeft: "1px solid #000" },
  borderBS: { borderBottom: "4px solid #730A29" },
  borderHE: { borderRight: "1px solid #FFFFFF" },

  bg: { backgroundColor: "#BEBEBE" },
  bg2: { backgroundColor: "#BEBEBE" },

  fs7: { fontSize: "7px" },
  fs8: { fontSize: "8px" },
  fs9: { fontSize: "9px" },
  fs10: { fontSize: "10px" },
  fs11: { fontSize: "11px" },
  fs12: { fontSize: "12px" },
  fs13: { fontSize: "13px" },

  tend: { textAlign: "right", paddingRight: "2px" },
  tstart: { textAlign: "left" },
  tcenter: { textAlign: "center" },
  tBold: { fontFamily: "Helvetica-Bold" },
  tSpc: {
    textDecoration: "underline",
    fontFamily: "Helvetica-Bold",
    color: "#730A29",
  },
  tred: { color: "#730A29" },

  mT3: { marginTop: "3px" },
  mT5: { marginTop: "5px" },
  mT8: { marginTop: "8px" },
  mT10: { marginTop: "10px" },
  mT15: { marginTop: "15px" },
  mE10: { marginRight: "10px" },
  mCell: { paddingHorizontal: "3px", paddingVertical: "2px" },
  pxCell: { paddingHorizontal: "2px" },
  mxBody: { marginHorizontal: "10px" },
  mxAuto: { marginHorizontal: "auto" },
});

export default async function pdfTarjetaPr(data, config, t, fileNo) {
  return {
    report: (
      <Document>
        <FirstPage d={data} fno={fileNo} />
      </Document>
    ),
  };
}

const FirstPage = ({ d, fno }) => {
  const fDateLetra = (d) => {
    if (!validValue(d)) return d;
    return fmtD.format(new Date(d)).replaceAll(" de ", "/");
  };
  return (
    <Page style={[s.body]} orientation="landscape">
      <HeaderReport showTT={false} />
      <View style={[s.borderBS, s.mT3]}></View>
      <View style={[s.mxBody]}>
        <Image style={[s.wFoto]} src={getStaffPic(d.keyGenre, d.foto)} />
        <Text style={[s.fs13, s.tSpc]}>{d.nombreCompleto}</Text>
        <Text style={[s.fs11, s.mT10, s.tBold]}>Antecedente:</Text>
        
        <View style={[s.mT5, s.fs11]}>
          <View style={[s.row, s.border]}>
            <View style={[s.w40, s.borderE, s.mCell]}>
              <Text>Fecha de Ingreso al FGJ</Text>
            </View>
            <View style={[s.w60, s.mCell]}>
              <Text>{d.fechaIngresoLetra}</Text>
            </View>
          </View>
          <View style={[s.row, s.borderRow]}>
            <View style={[s.w40, s.borderE, s.mCell]}>
              <Text>Escolaridad</Text>
            </View>
            <View style={[s.w60, s.mCell, s.tred]}>
              <Text>{d.desCarrera}</Text>
            </View>
          </View>
          <View style={[s.row, s.borderRow]}>
            <View style={[s.w40, s.borderE, s.mCell]}>
              <Text>Fecha de Nacimiento</Text>
            </View>
            <View style={[s.w60, s.mCell]}>
              <Text>{d.fechaNacimientoLetra}</Text>
            </View>
          </View>
          <View style={[s.row, s.borderRow]}>
            <View style={[s.w40, s.borderE, s.mCell]}>
              <Text>Edad</Text>
            </View>
            <View style={[s.w60, s.mCell]}>
              <Text>{d.edad}</Text>
            </View>
          </View>
          <View style={[s.row, s.borderRow]}>
            <View style={[s.w40, s.borderE, s.mCell]}>
              <Text>Unidad de Adscripción</Text>
            </View>
            <View style={[s.w60, s.mCell]}>
              <Text>{d.nameArea}</Text>
            </View>
          </View>
          <View style={[s.row, s.borderRow]}>
            <View style={[s.w40, s.borderE, s.mCell]}>
              <Text>Puesto / Nivel y Rango</Text>
            </View>
            <View style={[s.w60, s.tBold, s.mCell]}>
              <Text>{d.nameJob}</Text>
            </View>
          </View>
          <View style={[s.row, s.borderRow]}>
            <View style={[s.w40, s.borderE, s.mCell]}>
              <Text>Antiguedad laboral</Text>
            </View>
            <View style={[s.w60, s.mCell]}>
              <Text>{d.fechaIngresoantiguedad}</Text>
            </View>
          </View>
          <View style={[s.row, s.borderRow]}>
            <View style={[s.w40, s.borderE, s.mCell]}>
              <Text>Horario oficial de labores</Text>
            </View>
            <View style={[s.w60, s.mCell]}>
              <Text>{"NO EXISTE"}</Text>
            </View>
          </View>
          <View style={[s.row, s.borderRow]}>
            <View style={[s.w40, s.borderE, s.mCell]}>
              <Text>Domicilio Particular</Text>
            </View>
            <View style={[s.w60, s.mCell]}>
              <Text>{d.domicilio}</Text>
            </View>
          </View>
        </View>

        <View style={[s.w90, s.mxAuto, s.mT10, s.fs11]}>
          <View style={s.header} fixed>
            <Text>HISTORICO LABORAL</Text>
          </View>
          { d.historicoLaboral.length > 0 && 
            d.historicoLaboral.map((el, ix)=>(
              <View style={[s.borderRow]} key={"rhl"+ix} wrap={false}>
                <Text style={[s.mCell]}>
                  <Text style={[s.tBold]}>{el.nameMove}</Text> a {" "}
                  <Text style={[s.tBold]}>{el.nameJob}</Text> a partir de {" "}
                  <Text style={[s.tBold]}>{fDateLetra(el.startDate)}</Text> a la fecha {" "}
                  <Text style={[s.tBold]}>{fDateLetra(el.endDate)}</Text>, nivel salarial {" "}
                  <Text style={[s.tBold]}>{el.nivelRango}</Text> adscrito(a) a {" "}
                  <Text style={[s.tBold]}>{el.nameArea}</Text>
                </Text>
              </View>
            ))
          }
        </View>
        
        <Text style={[s.fs11, s.mT15, s.tBold]}>Situación:</Text>
        <View style={[s.fs11, s.mT5]}>
          <View style={[s.w40]}>
            <View style={[s.row, s.border]}>
              <View style={[s.w30, s.borderE]}>
              </View>
              <View style={[s.w70, s.tcenter]}>
                <Text>Percepciones Mensuales Actuales</Text>
                <Text style={s.tred}>{d.nameJob}</Text>
                </View>
            </View>
            <View style={[s.row, s.borderRow]}>
              <View style={[s.w30, s.borderE, s.mCell]}>
                <Text>Sueldo base</Text>
              </View>
              <View style={[s.w70, s.mCell, s.tend]}>
                <Text>{fMoney(d.base)}</Text>
                </View>
            </View>
            <View style={[s.row, s.borderRow]}>
              <View style={[s.w30, s.borderE, s.mCell]}>
                <Text>Gratificación</Text>
              </View>
              <View style={[s.w70, s.mCell, s.tend]}>
                <Text>{fMoney(d.reward)}</Text>
                </View>
            </View>
            <View style={[s.row, s.borderRow]}>
              <View style={[s.w30, s.borderE, s.mCell]}>
                <Text>Despensa</Text>
              </View>
              <View style={[s.w70, s.mCell, s.tend]}>
                <Text>{fMoney(d.larder)}</Text>
                </View>
            </View>
            <View style={[s.row, s.borderRow, s.tBold]}>
              <View style={[s.w30, s.borderE, s.mCell]}>
                <Text>Total bruto</Text>
              </View>
              <View style={[s.w70, s.mCell, s.tend]}>
                <Text>{fMoney(d.gross)}</Text>
                </View>
            </View>
            <View style={[s.row, s.borderRow, s.tBold]}>
              <View style={[s.w30, s.borderE, s.mCell]}>
                <Text>Total neto</Text>
              </View>
              <View style={[s.w70, s.mCell, s.tend]}>
                <Text>{fMoney(d.net)}</Text>
                </View>
            </View>
          </View>
        </View>

      </View>
      <FooterReport fileNo={fno} />
    </Page>
  );
};

import React, { useState } from "react";
import TableComponentCustom from "../../../components/TableComponent/TableComponentCustom";
import Widget from "../../../components/Widget/Widget";
import Loader from "../../../components/Loader/Loader";
import { toast } from "react-toastify";
import Notification from "../../../components/Notification/Notification";
import { Collapse, Button, Row, Col } from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import { NumberInput } from "../../../components/GenericInputsFormik/NumberInput";
import { YearInput } from "../../../components/GenericInputsFormik/YearInput";
import { CancelOutlined } from "@material-ui/icons";
import ModalTableVer from "../../../components/Modal/ModalTableVer";
import ModalConfirmation from "../../../components/Modal/ModalConfirmation";
import { peticionesReceiver } from "../../../helpers/peticionesReceiver";
import s from "./InputClear.module.scss";
import { GetPermissions } from "../../../libs/permissions/getPermissions";
import {
  SelectTypeHeadSingle,
} from "../../../components/GenericInputsFormik/index";
import { PERIOD } from "../../../helpers/regex";

const cabeceras = [
  "ID",
  "NUMERO DE EMPLEADO",
  "NOMBRE",
  "PERIODO",
  "AÑO",
  "SUELDO NETO SUJETO A COTIZACIÓN",
  "SUELDO RETROACTIVO",
  "PRISMA DE RIESGO NO CONTROLADO",
  "PRIMA DE SINIESTRALIDAD",
  "ELIMINAR",
];

const consulta = {
  action: "datatable",
  table: "adjustprisma A INNER JOIN staff B on (A.idStaff = B.idStaff)",
  rows: "A.idAjustPrisma,B.staffNumber,concat(B.name,' ',B.pName,' ',B.mName),A.period,A.year,A.snsc,A.sr,A.prnc,A.ps",
  conditions: `A.enabled = 1`,
  page: 0,
  records: 5,
  search: "",
  order: "",
};

const API = peticionesReceiver();

const AjustesPrisma = () => {
  const [permissions, setPermissions] = useState({});
  GetPermissions(setPermissions);

  const [loader, setLoader] = useState(false);
  const [collapse, setCollapse] = useState(false);
  const [boolSueldos, setBoolSueldos] = useState("");
  const [modalConfirmation, setModalConfirmation] = useState(false);
  

  /* table para seleccionar empelado */

  const [modalTabla, setModalTabla] = useState(false);
  const filtroModalTabla = [true, true, true, true, true];
  const cabecerasModalTabla = [
    "#",
    "Núm. Empleado",
    "Nombre",
    "Unidad de trabajo",
    "Puesto",
    "Seleccionar",
  ];
  const [paramsModalTabla] = useState({
    action: "datatable",
    table:
      "staff STF INNER JOIN employtypes AS EMT ON STF.keyEmployType = EMT.keyEmployType INNER JOIN jobstaff AS JBS ON JBS.idStaff = STF.idStaff INNER JOIN areas AS AR ON AR.idArea = JBS.idArea INNER JOIN jobtypes AS JBT ON JBT.keyJobType = STF.keyEmployType",
    rows: "STF.idStaff,STF.staffNumber,CONCAT(STF.name,' ',STF.pName,' ',STF.mName) as fullName,AR.name,JBT.nameJobType",
    conditions:
      "STF.enabled = 1 AND JBS.valid = 1 AND STF.keyStat = 1 AND JBS.enabled = 1",
    page: 0,
    records: 5,
    search: "",
    order: "",
  });
  const selectEmpleado = (el) => {
    setFieldValue("idStaff", el[0]);
    setFieldValue("nameStaff", `${el[1]} - ${el[2]}`);
    setModalTabla(false);
  };

  const toggle = () => {
    setCollapse(!collapse);
  };

  const [parametros, setParametros] = useState(consulta);

  const defaultData = {
    idStaff: "",
    nameStaff: "",
    period: "",
    year: "",
    snsc: 0,
    sr: 0,
    prnc: 0,
    ps: 0,
  };

  const FormSchema = Yup.object().shape({
    idStaff: Yup.string().required("Seleccione empleado"),
    nameStaff: Yup.string().required("Seleccione empleado"),
    period: Yup.number()
      .min(1, "Seleccione un periodo mayor a 0")
      .max(24, "Seleccione un periodo menor a 25")
      .required("Seleccione un peridodo"),
    year: Yup.string().required("Seleccione un año"),
  });

  const onChange = (e) => {
    if (
      e.target.name === "snsc" ||
      e.target.name === "sr" ||
      e.target.name === "prnc" ||
      e.target.name === "ps"
    ) {
      if (
        e.target.value === "" ||
        e.target.value === undefined ||
        e.target.value === null
      ) {
        setFieldValue([e.target.name], 0);
      } else {
        setFieldValue([e.target.name], e.target.value);
      }
    } else {
      setFieldValue([e.target.name], e.target.value);
    }
  };

  const {
    handleSubmit,
    values,
    handleBlur,
    errors,
    touched,
    setFieldValue,
    resetForm,
  } = useFormik({
    initialValues: defaultData,
    onSubmit: (values) => {
      const valida = validaSueldos();
      if (valida) {
        setModalConfirmation(true);
      }
    },
    validationSchema: FormSchema,
    enableReinitialize: true,
    onReset: () => {},
  });

  const validaSueldos = () => {
    if (values.prnc > 0 || values.sr > 0 || values.ps > 0 || values.snsc > 0) {
      setBoolSueldos(true);
      return true;
    } else {
      setBoolSueldos(false);
      return false;
    }
  };

  const handleClear = () => {
    setFieldValue("idStaff", "");
    setFieldValue("nameStaff", "");
  };

  const limpia = () => {
    setCollapse(false);
    resetForm();
  };

  const crearPrisma = () => {
    setLoader(true);
    const prisma = {
      idStaff: values.idStaff,
      period: values.period,
      year: new Date(values.year).getFullYear(),
      snsc: values.snsc,
      sr: values.sr,
      prnc: values.prnc,
      ps: values.ps,
    };

    const data = {
      action: "insert",
      table: "adjustprisma",
      rows: prisma,
      validate: [["idStaff", "period", "year"]],
    };
    API.peticion(data)
      .then((res) => {
        if (res.status === 200 && res.data.code === "200") {
          toast(
            <Notification
              type={"agrega_exito"}
              backMessage={res.data.message}
              withIcon
            />
          );
          setParametros({
            action: "datatable",
            table:
              "adjustprisma A INNER JOIN staff B on (A.idStaff = B.idStaff)",
            rows: "A.idAjustPrisma,B.staffNumber,concat(B.name,' ',B.pName,' ',B.mName),A.period,A.year,A.snsc,A.sr,A.prnc,A.ps",
            conditions: `A.enabled = 1`,
            page: 0,
            records: 5,
            search: "",
            order: "",
          });
          limpia();
        } else {
          toast(
            <Notification
              type={"agrega_error"}
              backMessage={res.data.message}
              withIcon
            />
          );
        }
      })
      .finally(() => {
        setLoader(false);
      })
      .catch((err) => {
        toast(<Notification type={"consultar_servidor_error"} withIcon />);
      });
  };

  return (
    <Widget className="widget-p-md">
      <ModalConfirmation
        modalTitle={"ajuste de prisma"}
        modal={modalConfirmation}
        setModal={setModalConfirmation}
        crear={crearPrisma}
        values={values}
        reset={resetForm}
      >
        <div className="d-flex justify-content-center">
          <h6>¿Desea continuar con el registro?</h6>
        </div>
      </ModalConfirmation>
      <TableComponentCustom
        titulo={"Ajuste de prisma"}
        addDelete={true}
        cabecerasTabla={cabeceras}
        filtro={null}
        parametros={parametros}
      />

      {permissions.INS && (
        <div className="d-grid d-flex justify-content-center justify-content-sm-end">
          <Button
            color="primary"
            onClick={toggle}
            style={{ marginBottom: "1rem" }}
            disabled={collapse}
          >
            Agregar
          </Button>
        </div>
      )}

      <Collapse isOpen={collapse}>
        {loader ? (
          <Loader />
        ) : (
          <form onSubmit={handleSubmit} className="mt-4" noValidate>
            <Row className="d-flex justify-content-around ">
              <Col xs={12} md={12} lg={8}>
                <Row>
                  <Col xs={12} lg={12}>
                    <div className="form-group position-relative">
                      <label htmlFor="nameStaff">
                        Empleado <span className="text-danger">*</span>
                      </label>
                      <div className="input-group">
                        <input
                          name="nameStaff"
                          onChange={onChange}
                          onBlur={handleBlur}
                          className={
                            "form-control" +
                            (errors.nameStaff && touched.nameStaff
                              ? " is-invalid"
                              : "")
                          }
                          value={values.nameStaff}
                          disabled
                        />
                        <div className="input-group-append">
                          <button
                            className="btn btn-secondary"
                            style={{ zIndex: "auto" }}
                            type="button"
                            onClick={() => setModalTabla(true)}
                            disabled={
                              [10, 11, 12, 13, 14, 15].includes(
                                +values.idReport
                              ) &&
                              (values.period !== "" ||
                                values.initialDate !== "")
                            }
                          >
                            <i className="fa fa-search" />
                          </button>
                        </div>
                      </div>
                      {values.nameStaff !== "" && (
                        <CancelOutlined
                          className={s.btnClear}
                          onClick={handleClear}
                        />
                      )}
                      {touched.nameStaff && errors.nameStaff && (
                        <span style={{ color: "red" }}>{errors.nameStaff}</span>
                      )}
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className="d-flex justify-content-around ">
              <Col xs={12} md={12} lg={5}>
                <SelectTypeHeadSingle
                  label="Periodo"
                  isRequired={true}
                  optionsArray={PERIOD}
                  inputName="period"
                  onChangeMethod={onChange}
                  onBlurMethod={handleBlur}
                  touched={touched.period}
                  errors={errors.period}
                  optionValue="value"
                  optionName="label"
                  value={values.period}
                  defaultOption="Seleccione un periodo de inicio"
                />
                {/* <NumberInput
                  label="Periodo"
                  inputName="period"
                  onChangeMethod={onChange}
                  onBlurMethod={handleBlur}
                  value={values.period}
                  errors={errors.period}
                  touched={touched.period}
                  isRequired
                  disableSep
                /> */}
              </Col>
              <Col xs={12} md={12} lg={5}>
                <YearInput
                  label="Año"
                  inputName="year"
                  onChangeMethod={onChange}
                  onBlurMethod={handleBlur}
                  value={values.year}
                  touched={touched.year}
                  errors={errors.year}
                  isRequired
                />
              </Col>
            </Row>
            <Row className="d-flex justify-content-around ">
              <Col xs={12} md={12} lg={5}>
                <NumberInput
                  label="Sueldo neto sujeto a cotizaion"
                  inputName="snsc"
                  onChangeMethod={onChange}
                  onBlurMethod={handleBlur}
                  value={values.snsc}
                  errors={errors.snsc}
                  touched={touched.snsc}
                  prefix="$"
                  isRequired
                />
              </Col>
              <Col xs={12} md={12} lg={5}>
                <NumberInput
                  label="Sueldo retroactivo"
                  inputName="sr"
                  onChangeMethod={onChange}
                  onBlurMethod={handleBlur}
                  value={values.sr}
                  errors={errors.sr}
                  touched={touched.sr}
                  prefix="$"
                  isRequired
                />
              </Col>
              <Col xs={12} md={12} lg={5}>
                <NumberInput
                  label="Prima de riesgo no controlado"
                  inputName="prnc"
                  onChangeMethod={onChange}
                  onBlurMethod={handleBlur}
                  value={values.prnc}
                  errors={errors.prnc}
                  touched={touched.prnc}
                  prefix="$"
                  isRequired
                />
              </Col>
              <Col xs={12} md={12} lg={5}>
                <NumberInput
                  label="Prima de siniestralidad"
                  inputName="ps"
                  onChangeMethod={onChange}
                  onBlurMethod={handleBlur}
                  value={values.ps}
                  errors={errors.ps}
                  touched={touched.ps}
                  prefix="$"
                  isRequired
                />
              </Col>
            </Row>

            <div className="text-center">
              {boolSueldos !== "" && !boolSueldos && (
                <span className="text-danger  headline-3">
                  Ingrese almenos un sueldo{" "}
                </span>
              )}
            </div>

            <div className="row mt-3">
              <div className="col-sm-6 order-2 order-sm-1 text-center text-sm-left mb-4">
                <Button color="danger" type="reset" onClick={() => limpia()}>
                  Cancelar
                </Button>
              </div>
              <div className="col-sm-6 order-1 order-sm-2 text-center text-sm-right mb-4">
                <Button color="success" type="submit">
                  Guardar
                </Button>
              </div>
            </div>
          </form>
        )}
      </Collapse>
      <ModalTableVer
        modal={modalTabla}
        setModal={setModalTabla}
        title="Empleados"
        cabeceras={cabecerasModalTabla}
        filtro={filtroModalTabla}
        parametros={paramsModalTabla}
        selectRegistro={selectEmpleado}
        backdrop={"static"}
        btnClose
        keyboard
      />
    </Widget>
  );
};

export default AjustesPrisma;
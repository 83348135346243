/* eslint-disable jsx-a11y/alt-text */
import { useEffect, useRef, useState } from "react";
import { Button, Col, Row, Spinner } from "reactstrap";
import CameraInterface from "../../../components/webcam/Interface";
import * as faceapi from 'face-api.js'
import { toast } from "react-toastify";
import Notification from "../../../components/Notification/Notification";

export default function ReconocimientoFacial({ user, src, before, next }) {
    const [visible, setVisible] = useState(true);
    const [descriptor, setDescriptor] = useState(null);
    const [originDescriptor, setOriginDescriptor] = useState(null);
    const previewRef = useRef(null)
    const [captura, setCaptura] = useState("");
    const [loading, setLoading] = useState(true);
    const [loadingModel, setLoadingModel] = useState(true);
    const handleClose = () => setVisible(false)
    const handleOpen = () => setVisible(true)
    const [imageSRC, setImageSRC] = useState("");

    async function LoadModels() {
        const loadFromOrigin = process.env.REACT_APP_FACE_API_ORIGIN==="true" ? true : false;
        const origin = window.location.origin;
        await Promise.all([
            faceapi.nets.ssdMobilenetv1.loadFromUri(!loadFromOrigin ? `https://cdn.jsdelivr.net/gh/cgarciagl/face-api.js/weights/` : `${origin}/weights/`),
            faceapi.nets.faceLandmark68Net.loadFromUri(!loadFromOrigin ? `https://cdn.jsdelivr.net/gh/cgarciagl/face-api.js/weights/` : `${origin}/weights/`),
            faceapi.nets.faceRecognitionNet.loadFromUri(!loadFromOrigin ? `https://cdn.jsdelivr.net/gh/cgarciagl/face-api.js/weights/` : `${origin}/weights/`),
            faceapi.nets.tinyFaceDetector.loadFromUri(!loadFromOrigin ? `https://cdn.jsdelivr.net/gh/cgarciagl/face-api.js/weights/` : `${origin}/weights/`)
        ])
        setLoadingModel(false)
    }

    useEffect(() => {
        LoadModels()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    async function getCurrentDescriptor() {
        try {
            if (previewRef && previewRef.current) {
                const face = await faceapi.detectSingleFace(previewRef.current).withFaceLandmarks().withFaceDescriptor();
                if (face) {
                    const displaySize = { width: previewRef.current.width, height: previewRef.current.height }
                    const resizedDetections = faceapi.resizeResults(face, displaySize)
                    setOriginDescriptor(resizedDetections.descriptor)
                }
            }
        } catch (err) {
            toast(<Notification type={"consultar_error"} backMessage={"No a podido cargarse la imagen del empleado"} withIcon />);
            before()
        }
    }

    useEffect(() => {
        if (descriptor && originDescriptor) {
            const threshold = 0.5
            const distance = faceapi.utils.round(
                faceapi.euclideanDistance(descriptor, originDescriptor)
            )
            if (distance > threshold) {
                toast(<Notification type={"consultar_error"} backMessage={"El empleado no se parece al de la foto registrada"} withIcon />);
            } else {
                next()
            }
        }
    }, [descriptor, originDescriptor])

    useEffect(() => {
        getImageBase64FromURL(src).then(base64 => {
            setImageSRC(base64)
        }).catch(err => {
            toast(<Notification type={"consultar_error"} backMessage={"No se pudo leer la imagen del empleado"} withIcon />);
            before()
        })
    }, [loadingModel])

    const setFieldValue = (key, value) => {
        if (key === "descriptor") {
            setDescriptor(value)
        } else if (key === "captura") {
            setCaptura(value)
        }
    }

    async function getImageBase64FromURL(url) {
        return fetch(url)
            .then(response => response.blob())
            .then(blob => {
                return new Promise((resolve, reject) => {
                    const reader = new FileReader();

                    reader.onloadend = () => {
                        resolve(reader.result);
                    };

                    reader.onerror = reject;

                    reader.readAsDataURL(blob);
                });
            });
    }

    return (
        <>
            <h6>Hola, {user.name} {user.pName} {user.mName}</h6>
            <p>A continuación procederemos a hacer un reconocimiento facial para verificar tu identidad</p>

            <Row className="mt-3">
                <Col sm={12} md={6}>
                    {loadingModel || imageSRC === "" ? (
                        <>
                            <div className="d-flex flex-column align-items-center justify-content-center" style={{ minHeight: "300px" }}>
                                <Spinner animation="grow" variant="primary" />
                                <h3 className="mt-5">Cargando, espere por favor....</h3>
                            </div>
                        </>
                    ) : (
                        <>
                            <h6 className="text-center mb-4">Identidad a validar:</h6>
                            <img ref={previewRef} src={imageSRC} alt="imagen" style={{ width: "100%" }} onLoad={getCurrentDescriptor} />
                        </>
                    )}

                </Col >

                <Col sm={12} md={6}>
                    {visible ? (
                        <>
                            <h6 className="text-center">Reconocimiento facial:</h6>
                            <CameraInterface
                                cameraOpened={visible}
                                setCameraOpened={setVisible}
                                setFieldValue={setFieldValue}
                                handleCloseModal={handleClose}
                                setLoadingScreenShot={setLoading}
                            />
                        </>
                    ) : (
                        <div className="d-flex flex-column justify-content-end">
                            <Button className="ml-auto" color="warning mb-3" onClick={handleOpen}>Volver a intentar</Button>
                            <img ref={previewRef} src={captura} />
                        </div>
                    )}
                </Col>
            </Row >
            <div className="text-center mt-5">
                <Button color='danger' onClick={before} disabled={!loading}>Regresar</Button>
            </div>
        </>
    )
}
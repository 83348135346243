import { toast } from "react-toastify";

import Datatable from "../../../../components/Datatable/Datatable";
import Notification from "../../../../components/Notification/Notification";
import { peticionesReceiver } from "../../../../helpers/peticionesReceiver";
import { Button, Spinner } from "reactstrap";
import { useHistory } from "react-router-dom";
import { useState } from "react";
import { GetPermissions } from "../../../../libs/permissions/getPermissions";

export default function InicioDetallesCarga({ id, setIdWinner }) {
    const API = peticionesReceiver();
    const history = useHistory()
    const [permissions, setPermissions] = useState({});
    const [loading, setLoading] = useState(true)
    GetPermissions(setPermissions, setLoading);
    const headers = [
        "#",
        "Razón social",
        "RFC",
        "Justificación",
        "Entregas"
    ]

    const columns = [
        { data: "index" },
        { data: "socialName" },
        { data: "itin" },
        { data: "justification" },
        {
            data: "idWinner",
            render: function Render(row) {
                return (
                    <>
                        {permissions.UPD && (
                            <i className="fa fa-pencil text-warning cursor-pointer" onClick={() => setIdWinner(row)} />
                        )}
                    </>

                )
            }
        },
    ];

    const getParams = (order, records, page, search) => {
        const params = {
            action: "datatable",
            table: "purchaserequests pr inner join status s on (s.keyStat = pr.keyStat) inner join budgetrequests br on (br.idBudgetRequest=pr.idBudgetRequest) inner join purchasewinners pw on (pw.idPurchaseRequest=pr.idPurchaseRequest) inner join providerquotes pq on (pq.idProviderQuote=pw.idProviderQuote) inner join providers p on (pq.idProvider=p.idProvider)",
            rows: "pw.idWinner,br.justification,p.socialName,p.itin",
            conditions: `pw.enabled = 1 AND pw.idPurchaseRequest=${id}`,
            order,
            records: records.toString(),
            page: (page * records).toString(),
            search
        }
        return params
    }

    async function getData(order, records, page, search) {
        try {
            const res = await API.peticion(getParams(order, records, page, search));
            if (res.status === 200 && res.data.code === "200") {
                res.data.data.forEach((row, index) => row.index = (index + 1) + (page * records))
                return res.data;
            } else {
                toast(<Notification type={"consultar_error"} backMessage={res.data.message} />);
                return null;
            }
        } catch (err) {
            toast(<Notification type={"consultar_servidor_error"} withIcon />);
            return null
        }
    }

    const columnDefs = [{ targets: [0], orderable: false }, { targets: [4], className: "text-center" }]

    function ret() {
        localStorage.removeItem("loadBill");
        history.push("/template/carga-facturas")
    }

    if (loading) {
        return (
            <div className="d-flex align-items-center justify-content-center" style={{ height: "200px" }}>
                <Spinner
                    color="primary"
                    style={{
                        height: '3rem',
                        width: '3rem'
                    }}
                    type="grow"
                >
                    Loading...
                </Spinner>
            </div>
        )
    }
    return (
        <>
            <Button color="danger" onClick={ret}>Regresar</Button>
            <h3 className="text-center mb-5">Proveedores</h3>
            {id && (
                <Datatable
                    columns={columns}
                    headers={headers}
                    petition={getData}
                    columnDefs={columnDefs}
                    control="back"
                    order={{ col: 1, opt: "asc" }}
                />
            )}
        </>
    )
}
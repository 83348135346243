import { Document, Page, StyleSheet, Text, View } from "@react-pdf/renderer";
import HeaderReport from "../../../../components/Pdf/Header";

const s = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 35,
    paddingHorizontal: 20,
  },
  titleStyle: {
    fontWeight: "bold",
    textAlign: "center",
    fontSize: "10px",
    marginBottom: "5px",
    fontFamily: "Helvetica-Bold",
  },
  vCenter: {height: "100%", paddingTop:"7px"},
  mPage: { margin: "10px" },
  mCell: { padding: "2px" },
  mButton: { marginBottom: "5px" },
  row: { flexDirection: "row" },
  w10: { width: "10%" },
  w11: { width: "11%" },
  w15: { width: "15%" },
  w20: { width: "20%" },
  w30: { width: "30%" },
  w39: { width: "39%" },
  w40: { width: "40%" },
  w50: { width: "50%" },
  w60: { width: "60%" },
  w65: { width: "65%" },
  w70: { width: "70%" },
  wAuto: { width: "auto" },
  border: { border: "2px solid black" },
  borderTx2: { borderTop: "2px solid black" },
  borderLR: { borderLeft: "2px solid black", borderRight: "2px solid black"},
  borderBx2: { borderBottom: "2px solid black" },
  borderB: { borderBottom: "1px solid black" },
  borderE: { borderRight: "1px solid black" },
  background: { backgroundColor: "#D6D1C4" },
  fs8: { fontSize: "8px" },
  fs9: { fontSize: "9px" },
  fs10: { fontSize: "10px" },
  tend: { textAlign: "right", paddingRight: "3px" },
  tcenter: { textAlign: "center" },
  tBold: { fontFamily: "Helvetica-Bold" },
  spaceFooter: {
    // fontSize: 12,
    bottom: "10",
    left: "0",
    right: "0",
    width: "100%",
    height: "50px",
    // marginHorizontal: 10,
  },
  footer: {
    position: "absolute",
    fontSize: "12",
    bottom: "10",
    left: "0",
    right: "0",
    width: "auto",
    height: "30px",
    marginHorizontal: "10px",
    borderTopWidth: "1px",
    flexDirection: "row",
    alignContent: "space-around",
    alignItems: "center",
  },
  textFooter: {
    width: "50%",
    textAlign: "center",
    fontSize: 8,
  },
});

export async function PdfNominaGneral(data, model, title, fileNo) {
  // console.log("Llegando data",data)
  // console.log("Llegando model ",model)
  return {
    report: (
      <>
        <Document>
          <Page style={s.body}>
            <HeaderReport/>
            <View style={s.mPage}>
              <View style={s.titleStyle}>
                <Text>{title}</Text>
                <Text>{model.period}</Text>
              </View>
            </View>
            <View style={[s.fs9]}>
              <View style={[s.row, s.borderB, s.borderTx2, s.borderLR, s.tBold]} fixed>
                <View style={[s.w11, s.borderE, s.mCell]}>
                  <Text>NÚMERO EMPLEADO</Text>
                </View>
                <View style={[s.w20, s.borderE, s.mCell, s.vCenter]}>
                  <Text>NOMBRE COMPLETO</Text>
                </View>
                <View style={[s.w30, s.borderE, s.mCell, s.vCenter]}>
                  <Text>ADSCRIPCIÓN</Text>
                </View>
                <View style={[s.w39, s.mCell, s.vCenter]}>
                  <Text>CONCEPTO RH</Text>
                </View>
              </View>
              {data.map((el, ix) => (
                <View style={[s.row, s.borderB, s.borderLR, s.fs8]} wrap={false} key={"R"+ix}>
                  <View style={[s.w11, s.borderE, s.mCell]}>
                    <Text>{el.staffNumber}</Text>
                  </View>
                  <View style={[s.w20, s.borderE, s.mCell]}>
                    <Text>{el.name}</Text>
                  </View>
                  <View style={[s.w30, s.borderE, s.mCell]}>
                    <Text>{el.nameArea}</Text>
                  </View>
                  <View style={[s.w39, s.mCell]}>
                    <Text>{el.payments}</Text>
                    {/* <Text>   </Text> */}
                  </View>
                </View>
              ))}
            </View>

            <View fixed style={s.spaceFooter}></View>
            <View fixed style={s.footer}>
              <Text style={s.textFooter}>{fileNo}</Text>
              <Text
                style={s.textFooter}
                render={({ pageNumber, totalPages }) =>
                  `PAGINA : ${pageNumber} / ${totalPages}`
                }
              />
            </View>
          </Page>
        </Document>
      </>
    ),
  };
}

import React, { useState } from "react";
import { Nav, NavItem, NavLink } from "reactstrap";
import Widget from "../../../components/Widget/Widget";
import * as Icons from "@material-ui/icons";

import s from "./nav.module.scss";

import Chequera from "./Chequera";
import CuentasBancariasForm from "./CuentasBancarias";
import ConsultarCheques from "./ConsultarCheques";
import ConsultarMovimientos from "./ConsultarMovimientos";
import AjustesBancarios from "./AjustesBancarios";
import TrapasoCuentas from "./TrapasoCuentas";
import {
  create,
  update,
  getRow,
  createWithFile,
  moneyTransfer,
} from "./functions";
import { GetPermissions } from "../../../libs/permissions/getPermissions";

const CuentasBancarias = () => {
  const [permissions, setPermissions] = useState({});
  GetPermissions(setPermissions);

  const defaultValuesBankAccount = {
    idBankAccount: "",
    bank: "",
    account: "",
    swiftCode: "",
    avaible: "",
  };

  const [activeTab, setActiveTab] = useState(1);
  const [bankAccount, setBankAccount] = useState(defaultValuesBankAccount);

  const resetBankAccount = () => {
    setBankAccount(defaultValuesBankAccount);
    toggleTab(1);
  };

  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  const updateAccount = (newData) => {
    setBankAccount((prevState) => ({
      ...prevState,
      bank: newData.bank,
      account: newData.account,
      swiftCode: newData.swiftCode,
      avaible: newData.avaible,
    }));
  };

  const updateBalance = (newBalance) => {
    setBankAccount((prevState) => ({
      ...prevState,
      avaible: newBalance,
    }));
  };

  const selectAccount = (data) => {
    setBankAccount((prevState) => ({
      ...prevState,
      idBankAccount: data.idBankAccount,
      bank: data.bank,
      account: data.account,
      swiftCode: data.swiftCode,
      avaible: data.avaible,
    }));
    toggleTab(2);
  };

  return (
    <>
      <div>
        <Widget className="widget-p-md">
          <div className="headline-1">Cuentas Bancarias</div>
          <Nav fill tabs style={{ overflowY: "hidden", overflowX: "auto" }}>
            <NavItem active={activeTab === 1}>
              <NavLink
                href="#"
                active={activeTab === 1}
                onClick={() => toggleTab(1)}
                disabled={bankAccount.idBankAccount === "" ? false : true}
              >
                <Icons.CreditCard className={s.navIco} />
                Cuentas Bancarias
              </NavLink>
            </NavItem>
            <NavItem active={activeTab === 2}>
              <NavLink
                href="#"
                active={activeTab === 2}
                onClick={() => toggleTab(2)}
                disabled={bankAccount.idBankAccount === "" ? true : false}
              >
                <Icons.AssignmentTurnedIn className={s.navIco} />
                Chequeras
              </NavLink>
            </NavItem>
            <NavItem active={activeTab === 3}>
              <NavLink
                href="#"
                active={activeTab === 3}
                onClick={() => toggleTab(3)}
                disabled={bankAccount.idBankAccount === "" ? true : false}
              >
                <i className={s.navIco + " fa fa-id-card-o"} />
                Consulta de cheques
              </NavLink>
            </NavItem>
            <NavItem active={activeTab === 4}>
              <NavLink
                href="#"
                active={activeTab === 4}
                onClick={() => toggleTab(4)}
                disabled={bankAccount.idBankAccount === "" ? true : false}
              >
                <Icons.Settings className={s.navIco} />
                Ajustes Bancarios
              </NavLink>
            </NavItem>
            <NavItem active={activeTab === 5}>
              <NavLink
                href="#"
                active={activeTab === 5}
                onClick={() => toggleTab(5)}
                disabled={bankAccount.idBankAccount === "" ? true : false}
              >
                <Icons.CompareArrows className={s.navIco} />
                Traspasos entre cuentas
              </NavLink>
            </NavItem>
            <NavItem active={activeTab === 6}>
              <NavLink
                href="#"
                active={activeTab === 6}
                onClick={() => toggleTab(6)}
                disabled={bankAccount.idBankAccount === "" ? true : false}
              >
                <Icons.Search className={s.navIco} />
                Consulta de movimientos
              </NavLink>
            </NavItem>
          </Nav>

          <div className="mt-4">
            {bankAccount.idBankAccount !== "" && activeTab !== 1 && (
              <>
                <div className="row justify-content-between">
                  <div className="col-md-7 col-xs-12">
                    <h6 className="text-sm-left">Banco: {bankAccount.bank}</h6>
                    <h6 className="text-sm-left">CTA: {bankAccount.account}</h6>
                    <h6 className="text-sm-left">
                      Clabe: {bankAccount.swiftCode}
                    </h6>
                    <h6 className="text-sm-left">
                      Saldo:{" "}
                      {new Intl.NumberFormat("en-US", {
                        style: "currency",
                        currency: "USD",
                      }).format(bankAccount.avaible)}
                    </h6>
                  </div>

                  <div className="col-md-5 col-xs-12">
                    <div className="text-right">
                      <button
                        className="btn text-light"
                        style={{ backgroundColor: "#cc0a44", border: "none" }}
                        onClick={resetBankAccount}
                      >
                        Cerrar expediente
                      </button>
                    </div>
                  </div>
                </div>
              </>
            )}
            {activeTab === 1 ? (
              <CuentasBancariasForm
                crear={create}
                editar={update}
                obtener={getRow}
                actualizarCuenta={updateAccount}
                seleccionar={selectAccount}
                permissions={permissions}
              />
            ) : activeTab === 2 ? (
              <Chequera
                idBankAccount={bankAccount.idBankAccount}
                crear={create}
                editar={update}
                permissions={permissions}
              />
            ) : activeTab === 3 ? (
              <ConsultarCheques
                idBankAccount={bankAccount.idBankAccount}
                permissions={permissions}
              />
            ) : activeTab === 4 ? (
              <AjustesBancarios
                idBankAccount={bankAccount.idBankAccount}
                crear={create}
                subirArchivo={createWithFile}
                editar={update}
                actualizarBalance={updateBalance}
                permissions={permissions}
              />
            ) : activeTab === 5 ? (
              <TrapasoCuentas
                idBankAccount={bankAccount.idBankAccount}
                crear={moneyTransfer}
                subirArchivo={createWithFile}
                actualizarBalance={updateBalance}
                canInsert={permissions.INS}
              />
            ) : (
              activeTab === 6 && (
                <ConsultarMovimientos
                  idBankAccount={bankAccount.idBankAccount}
                  account={bankAccount}
                  permissions={permissions}
                />
              )
            )}
          </div>
        </Widget>
      </div>
    </>
  );
};
export default CuentasBancarias;

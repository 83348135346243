import { peticionesReceiver } from "../../../helpers/peticionesReceiver";
import Notification from "../../../components/Notification/Notification";
import { toast } from "react-toastify";
const API = peticionesReceiver();


export const insertInfo = async (account) => {
  const finalData ={
    idAccountList: account.idAccountList,
    parentAccountList: account.parentAccountList === 0 || account.parentAccountList=== "0" ? "": account.parentAccountList.toString(),
    nameAccountList: account.nameAccountList,
    accountListKey: account.accountListKey,
    keyPlan: account === 0 ? "": account.keyPlan
  }; 

    const data = {
      action: "saveAccount",
      rows:finalData
    };
    API.peticionEndPoint(data,"app/facades/accounts/accountsF.php")
    .then((res) => { 
        if (res.status === 200 && res.data.code === "200") {
          toast(<Notification type={"agrega_exito"} backMessage={res.data.message} withIcon />);
          window.location.href ="#/template/cuentas-contables";
        } else {
          toast(<Notification type={"agrega_error"} backMessage={res.data.message} withIcon />);
        }
      })
      .catch((err) => {
        toast(<Notification type={"consultar_servidor_error"} withIcon />);
      });
  };

  
  


import { Document, Image, Page, StyleSheet, Text, View } from "@react-pdf/renderer";
import HeaderReport from "../../../../components/Pdf/Header";
import { getStaffPic } from "../../../../libs/tools/images";

const fmtMny = new Intl.NumberFormat("es-MX", {
  style: "currency",
  currency: "MXN",
});

const s = StyleSheet.create({
  body: {
    paddingVertical: "20px",
    paddingHorizontal: "20px",
  },

  title: {
    fontSize: "12px",
    textAlign: "center",
    fontFamily: "Helvetica-Bold",
  },

  header: {
    backgroundColor: "#730A29",
    textAlign: "center",
    color: "#FFFFFF",
    border: "1px solid #000",
  },

  vCenter: { alignItems: "center", justifyContent: "center" },
  vCenterL: { alignItems: "left", justifyContent: "center" },
  vCenterR: { alignItems: "right", justifyContent: "center" },
  vCenterBtw: { alignItems: "stretch", justifyContent: "center" },

  dflexBtw: { display: "flex", justifyContent: "space-between" },
  dflexEnd: { display: "flex", justifyContent: "flex-end" },

  alignE: { alignContent: "flex-end" },

  row: { flexDirection: "row" },
  flexWrap: { display: "flex", flexWrap: "wrap", flexDirection: "row" },

  w5: { width: "5%" },
  w10: { width: "10%" },
  w15: { width: "15%" },
  w20: { width: "20%" },
  w25: { width: "25%" },
  w30: { width: "30%" },
  w35: { width: "35%" },
  w40: { width: "40%" },
  w45: { width: "45%" },
  w50: { width: "50%" },
  w55: { width: "55%" },
  w60: { width: "60%" },
  w65: { width: "65%" },
  w70: { width: "70%" },
  w75: { width: "75%" },
  w80: { width: "80%" },
  w85: { width: "85%" },
  w90: { width: "90%" },
  w100: { width: "100%" },
  wAuto: { width: "auto" },

  wFoto: {width: "100%", height: "auto"},

  borderRow: { border: "1px solid #000", borderTop: "0px" },
  borderT: { borderBottom: "1px solid #000" },
  borderB: { borderBottom: "1px solid #000" },
  borderB2: { borderBottom: "2px solid #000" },
  borderE: { borderRight: "1px solid #000" },
  borderS: { borderLeft: "1px solid #000" },
  borderBS: { borderBottom: "4px solid #730A29" },
  borderHE: { borderRight: "1px solid #FFFFFF" },

  bg: { backgroundColor: "#BEBEBE" },
  bg2: { backgroundColor: "#BEBEBE" },

  fs7: { fontSize: "7px" },
  fs8: { fontSize: "8px" },
  fs9: { fontSize: "9px" },
  fs10: { fontSize: "10px" },
  fs11: { fontSize: "11px" },
  fs12: { fontSize: "12px" },
  fs13: { fontSize: "13px" },

  tend: { textAlign: "right", paddingRight: "2px" },
  tstart: { textAlign: "left" },
  tcenter: { textAlign: "center" },
  tBold: { fontFamily: "Helvetica-Bold" },

  mT8: { marginTop: "8" },
  mT10: { marginTop: "10px" },
  mT3: { marginTop: "3px" },
  mE10: { marginRight: "10px" },
  mCell: { padding: "2px" },
  mxCell: { paddingHorizontal: "2px" },

  spaceFooter: {
    bottom: 10,
    left: 0,
    right: 0,
    width: "auto",
    height: 30,
    marginHorizontal: 10,
  },

  footer: {
    position: "absolute",
    bottom: 10,
    left: 0,
    right: 0,
    width: "auto",
    height: 30,
    marginHorizontal: 10,
    borderTopWidth: 1,
    flexDirection: "row",
    alignContent: "space-around",
    alignItems: "center",
  },
  textFooter: {
    width: "50%",
    textAlign: "center",
    fontSize: 8,
  },
});

function clearOptions(opt) {
  const clrOpt = opt.filter((e) => e !== "");
  let tt = clrOpt.join(" - ");
  return tt;
}

const fMoney = (d) => {
  return fmtMny.format(d);
};

const fDate = (d) => {
  return d.split("-").reverse().toString().replaceAll(",", "/");
};

export default async function PdfEmpleadosUnidad(data, config, t, fileNo) {
  const cfg = config.cfg;
  let sz = "";
  if (cfg.photo) sz = s.w75;
  else sz = s.w85;
  return {
    report: (
      <Document>
        <Page style={[s.body]} orientation="landscape">
          <HeaderReport />
          <View style={[s.fs12]}>
            <View style={[s.tcenter, s.tBold]}>
              <Text>PLANTILLA DE EMPLEADOS</Text>
            </View>
            {cfg.options.length > 0 && (
              <View style={[s.tcenter]}>
                <Text>{clearOptions(cfg.options)}</Text>
              </View>
            )}
          </View>

          <View style={[s.mT8, s.fs11]} wrap>
            <View style={[s.row, s.header, s.borderB]} fixed>
              <View style={[s.w5, s.vCenter, s.borderHE]}>
                <Text style={s.mxCell}>No.</Text>
              </View>
              {cfg.photo && (
                <View style={[s.w10, s.vCenter, s.borderHE]}>
                  <Text style={s.mxCell}>Fotografía</Text>
                </View>
              )}
              <View style={[s.w10, s.vCenter, s.borderHE]}>
                <Text style={s.mxCell}>Número de Empleado</Text>
              </View>
              <View style={[sz, s.vCenter, s.tcenter]}>
                <Text style={s.mxCell}>Detalles</Text>
              </View>
            </View>
            {data.map((d, ix) => (
              <RowData d={d} ix={ix + 1} photo={cfg.photo} key={"rd" + ix} />
            ))}
          </View>

          <View fixed style={s.spaceFooter}></View>
          <View fixed style={s.footer}>
            <Text style={s.textFooter}>{fileNo}</Text>
            <Text
              style={s.textFooter}
              render={({ pageNumber, totalPages }) =>
                `PAGINA : ${pageNumber} / ${totalPages}`
              }
            />
          </View>
        </Page>
      </Document>
    ),
  };
}

function RowData({ d, ix, photo }) {
  let sz = "";
  if (photo) sz = s.w75;
  else sz = s.w85;
  return (
    <View style={[s.row, s.borderRow, s.fs10]} wrap={false}>
      <View style={[s.w5, s.vCenter, s.borderE]}>
        <Text style={s.mxCell}>{ix}</Text>
      </View>
      {photo && (
        <View style={[s.w10, s.vCenter, s.borderE]}>
          <Image style={[s.wFoto]} src={getStaffPic(d.nameGender, d.foto)} />
        </View>
      )}
      <View style={[s.w10, s.vCenter, s.borderE]}>
        <Text style={s.mxCell}>{d.staffNumber}</Text>
      </View>
      <View style={[sz, s.mCell]}>
        <View style={[s.row, s.flexWrap]}>
          <View style={[s.mxCell, s.mT3, s.mE10]}>
            <Text>
              <Text style={s.tBold}>NOMBRE: </Text>
              {d.nombre}
            </Text>
          </View>
          <View style={[s.mxCell, s.mT3, s.mE10]}>
            <Text>
              <Text style={s.tBold}>SEXO: </Text>
              {d.nameGender}
            </Text>
          </View>
          <View style={[s.mxCell, s.mT3, s.mE10]}>
            <Text>
              <Text style={s.tBold}>CURP: </Text>
              {d.curp}
            </Text>
          </View>
          <View style={[s.mxCell, s.mT3, s.mE10]}>
            <Text>
              <Text style={s.tBold}>RFC: </Text>
              {d.rfc}
            </Text>
          </View>
          <View style={[s.mxCell, s.mT3, s.mE10]}>
            <Text>
              <Text style={s.tBold}>CLAVE ISSEMYM: </Text>
              {d.socialSecurityKey}
            </Text>
          </View>
          <View style={[s.mxCell, s.mT3, s.mE10]}>
            <Text>
              <Text style={s.tBold}>DIRECCION: </Text>
              {`${d.street} #${d.extNumber} ${
                d.intNumber !== "" ? `int. ${d.intNumber}` : ""
              } ${d.subStreet}, ${d.nameCity} ${d.nameCounty} ${d.nameState}`}
            </Text>
          </View>
        </View>
        <View style={[s.row, s.flexWrap]}>
          <View style={[s.mxCell, s.mT3, s.mE10]}>
            <Text>
              <Text style={s.tBold}>ADSCRIPCIÓN: </Text> {d.nameArea}
            </Text>
          </View>
          <View style={[s.mxCell, s.mT3, s.mE10]}>
            <Text>
              <Text style={s.tBold}>PUESTO LABORAL: </Text> {d.nameJob}
            </Text>
          </View>
          <View style={[s.mxCell, s.mT3]}>
            <Text>
              <Text style={s.tBold}>RANGO: </Text> {d.rango}
            </Text>
          </View>
        </View>
        <View style={[s.row, s.flexWrap]}>
          <View style={[s.mxCell, s.mE10, s.mT3]}>
            <Text>
              <Text style={s.tBold}>SUELDO BASE: </Text>
              {fMoney(d.base)}
            </Text>
          </View>
          <View style={[s.mxCell, s.mE10, s.mT3]}>
            <Text>
              <Text style={s.tBold}>SUELDO BRUTO: </Text>
              {fMoney(d.gross)}
            </Text>
          </View>
          <View style={[s.mxCell, s.mE10, s.mT3]}>
            <Text>
              <Text style={s.tBold}>SUELDO NETO: </Text>
              {fMoney(d.net)}
            </Text>
          </View>
          <View style={[s.mxCell, s.mE10, s.mT3]}>
            <Text>
              <Text style={s.tBold}>GRATIFICACIÓN: </Text>
              {fMoney(d.reward)}
            </Text>
          </View>
          <View style={[s.mxCell, s.mE10, s.mT3]}>
            <Text>
              <Text style={s.tBold}>DESPENSA: </Text>
              {fMoney(d.larder)}
            </Text>
          </View>
          <View style={[s.mxCell, s.mE10, s.mT3]}>
            <Text>
              <Text style={s.tBold}>FORTALECIMIENTO: </Text>
              {fMoney(d.fortalecimiento)}
            </Text>
          </View>
          <View style={[s.mxCell, s.mE10, s.mT3]}>
            <Text>
              <Text style={s.tBold}>COMPENSACION: </Text>
              {fMoney(d.clearance)}
            </Text>
          </View>
          <View style={[s.mxCell, s.mE10, s.mT3]}>
            <Text>
              <Text style={s.tBold}>ISR: </Text>
              {fMoney(d.taxes)}
            </Text>
          </View>
          <View style={[s.mxCell, s.mT3]}>
            <Text>
              <Text style={s.tBold}>ISSEMYM: </Text>
              {fMoney(d.ssecurity)}
            </Text>
          </View>
        </View>
        <View style={[s.row, s.flexWrap]}>
          <View style={[s.mxCell, s.mT3, s.mE10]}>
            <Text>
              <Text style={s.tBold}>TIPO PAGO: </Text>
              {d.namePaymentType}
            </Text>
          </View>
          <View style={[s.mxCell, s.mT3, s.mE10]}>
            <Text>
              <Text style={s.tBold}>TIPO DE DEPOSITO: </Text>
              {d.nameAccountType}
            </Text>
          </View>
          <View style={[s.mxCell, s.mT3, s.mE10]}>
            <Text>
              <Text style={s.tBold}>BANCO: </Text>
              {d.nameBank}
            </Text>
          </View>
          <View style={[s.mxCell, s.mT3]}>
            <Text>
              <Text style={s.tBold}>CUENTA BANCARIA: </Text>
              {d.account}
            </Text>
          </View>
        </View>
        <View style={[s.row, s.flexWrap]}>
          <View style={[s.mxCell, s.mT3, s.mE10]}>
            <Text>
              <Text style={s.tBold}>TIPO NOMBRAMIENTO: </Text>
              {d.nameAssignment}
            </Text>
          </View>
          <View style={[s.mxCell, s.mT3, s.mE10]}>
            <Text>
              <Text style={s.tBold}>FECHA INGRESO: </Text>
              {fDate(d.startJobDate)}
            </Text>
          </View>
          <View style={[s.mxCell, s.mT3, s.mE10]}>
            <Text>
              <Text style={s.tBold}>SINDICALIZADO: </Text>
              {+d.unionized === 1 ? "SI" : "NO"}
            </Text>
          </View>
        </View>
        <View style={[s.row, s.flexWrap]}>
          <View style={[s.mxCell, s.mT3, s.mE10]}>
            <Text>
              <Text style={s.tBold}>FECHA INICIO NOMBRAMIENTO: </Text>
              {fDate(d.startDate)}
            </Text>
          </View>
          <View style={[s.mxCell, s.mT3, s.mE10]}>
            <Text>
              <Text style={s.tBold}>FECHA FIN NOMBRAMIENTO: </Text>
              {fDate(d.endDate)}
            </Text>
          </View>
        </View>
      </View>
    </View>
  );
} 

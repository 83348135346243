import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { Col, Row, Button, Collapse } from "reactstrap";

import { REGEX_NUMBER } from "../../../helpers/regex";

import Loader from "../../../components/Loader/Loader";
import { peticionesReceiver } from "../../../helpers/peticionesReceiver";
import Notification from "../../../components/Notification/Notification";
import ModalConfirmation from "../../../components/Modal/ModalConfirmation";
import TableComponentVer from "../../../components/TableComponent/TableComponentVer";
import { TextInput,CurrencyInput, SelectTypeHeadSingle } from "../../../components/GenericInputsFormik/index";

export const CuentasBancarias = ({ crear,editar,obtener,actualizarCuenta,seleccionar, permissions }) => {
  const defaultForm = {
    idBankAccount: "", 
    keyBank: 0,
    idAccountList: 0,
    account: "",
    swiftCode: "",
    numberCard: "",
    sucursal: "",
    titular: "",
    avaible: "",
  };

  const API = peticionesReceiver();
  const [isOpen, setIsOpen] = useState(false);
  const [title, setTitle] = useState("");
  const [modal, setModal] = useState(false);
  const [finalData, setFinalData] = useState(false);
  const [isLoading, setIsLoading] = useState(true)
  const [allBanks, setAllBanks] = useState([])
  const [allAccountList, setAllAccountList] = useState([])

  const cabeceras = [ "#", "Banco", "Cuenta", "Clabe", "Titular", "Editar","Seleccionar" ];
  const filtro = [ true, true, true, true, true ];
  const [parametros] = useState({
    action: "datatable",
    table:
      `bankaccounts AS BNA
      INNER JOIN banks AS BAN
      ON BNA.keyBank = BAN.keyBank`,
    rows: "BNA.idBankAccount,BAN.nameBank,BNA.account,BNA.swiftCode,BNA.titular",
    conditions: `BNA.enabled = 1`,
    page: 0,
    records: 5,
    search: "",
    order: "",
  });

  useEffect(() => {
    getAllData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getAllData = async() => {
    const result = await Promise.all([
      getData("banks",setAllBanks,"nameBank"),
      getData("account_list",setAllAccountList,"nameAccountList"),
    ])
    if (result.some(it => it === true)) {
      setIsLoading(false)
    }
  }
  
  const getData = async(table,functionUpdated,column) => {
    const params = {
      action: "select",
      table: table,
      condition: { enabled: 1 },
      order: `${column} ASC`,
    };
    const res = await API.peticion(params)
    if(res.status === 200 && res.data.code === "200"){
      functionUpdated(res.data.data)
      return true
    }
    else {
      toast(<Notification type={"consultar_error"} withIcon />);
      return false
    }
  };

  const onChange = event => {
    const { name,value } = event.target
    if(name === 'account' || name === 'numberCard'){
      if(REGEX_NUMBER.test(value)){
        setFieldValue([name], value);
      }
    }
    else{
      setFieldValue([name], value);
    }
  }

  const onChangeCurrent = event => {
    const { name,value } = event.target
    setFieldValue([name],value)
  }

  const openForm = (isEdit = false) => {
    setTitle(`${isEdit ? 'Editar' : 'Crear'} cuenta bancaria`);
    setIsOpen(true);
  };

  const clear = (reset) => {
    setTitle("");
    reset();
    setIsOpen(false);
  };

  const getForEdit = async (row) => {

    if (!isOpen) {
      const params = {
        id: row[0],
        nameId: 'idBankAccount',
        table: 'bankaccounts'
      }

      try{
        const response = await obtener(params)
        if(response.success){
          const data = response.info
          setTitle("Editar Cuenta Bancaria");
          setFieldValue("idBankAccount", row[0]);
          setFieldValue("keyBank", data.keyBank);
          setFieldValue("idAccountList", data.idAccountList);
          setFieldValue("account", row[2]);
          setFieldValue("swiftCode", row[3]);
          setFieldValue("numberCard", data.numberCard);
          setFieldValue("sucursal", data.sucursal);
          setFieldValue("titular", row[4]);
          setFieldValue("avaible", data.avaible);
          setFinalData(true)
          openForm(true)
        }
        else {
          toast(<Notification type={"consultar_servidor_error"} backMessage={response.message} withIcon />);
          setIsLoading(false)
        }
      }
      catch(error){
        toast(<Notification type={"consultar_servidor_error"} withIcon />);
        setIsLoading(false)
      }
    } 
    else {
      toast( <Notification type="warning" backMessage="Guarde sus cambios antes de continuar" />,
        { closeButton: false }
      );
    }
  };

  const saveInfo = async(reset) => {
    setIsLoading(true)
    
    const data = {
      nameId: 'idBankAccount',
      table: 'bankaccounts',
      rows: values,
      column: 'idBankAccount',
      validations: finalData ? ["keyBank","idAccountList","account","swiftCode","sucursal","titular","numberCard","avaible"] : ["keyBank","idAccountList","account","swiftCode","avaible"]
    }
    
    try{
      const response = finalData === true ? await editar(data) : await crear(data)
      if(response.success){
        
        toast(<Notification type={finalData ? "modifica_exito" :"agrega_exito"} withIcon />);
        clear(reset)
        setIsLoading(false)

        if(finalData){
          const bankTemp = allBanks.find(it => it.keyBank === values.keyBank)
          const newData = {
            idBankAccount: values.idAccountList,
            bank: bankTemp.nameBank,
            account: values.account,
            swiftCode: values.swiftCode,
            avaible: values.avaible
          }
          actualizarCuenta(newData)
        }

      }
      else {
        toast(<Notification type={finalData ? "modifica_error" : "agrega_error"} backMessage={response.message} withIcon />);
        setIsLoading(false)
      }
    }
    catch(error){
      toast(<Notification type={"consultar_servidor_error"} withIcon />);
      setIsLoading(false)
    }
  };

  const selectAccount = async(info) => {
    const params = {
      id: info[0],
      nameId: 'idBankAccount',
      table: 'bankaccounts'
    }

    try{
      const response = await obtener(params)
      if(response.success){
        const data = response.info
        const newData = {
          idBankAccount: info[0],
          bank: info[1],
          account: data.account,
          swiftCode: data.swiftCode,
          avaible: data.avaible
        }
        seleccionar(newData)

      }
      else {
        toast(<Notification type={"consultar_servidor_error"} backMessage={response.message} withIcon />);
      }
    }
    catch(error){
      toast(<Notification type={"consultar_servidor_error"} withIcon />);
    }
  }

  const FormSchema = Yup.object().shape({
    keyBank: Yup.number()
      .moreThan(0,"Favor de seleciconar un banco")
      .required("Favor de seleciconar un banco"),
    idAccountList: Yup.number()
      .moreThan(0,"Favor de seleciconar una cuenta contable")
      .required("Favor de seleciconar una cuenta contable"),
    account: Yup.string().required("Favor de ingresar el número de cuenta"),
    swiftCode: Yup.string().required("Favor de ingresar una clabe"),
    avaible: Yup.string().required("Favor de ingresar un saldo inicial"),
  });

  const { handleSubmit, values, handleBlur, errors, touched, setFieldValue, resetForm, handleReset } = useFormik({
    initialValues: defaultForm,
    validationSchema: FormSchema,
    onSubmit: () => setModal(true),
    enableReinitialize: true,
  });

  return (
    <>
    {
      isLoading ? <Loader />
      :
      <div className="mt-4">

        <TableComponentVer
          titulo="Cuentas"
          cabecerasTabla={cabeceras}
          filtro={filtro}
          editar={getForEdit}
          parametros={parametros}
          values={values}
          limpia={clear}
          reset={resetForm}
          ver = {selectAccount}
          usePermissions
          permissions={{...permissions, CON: true}}
        />

        <div className="row mt-2">
          <div className="col-12 col-sm-6"> <h5 className="text-center text-sm-left">{title}</h5> </div>

          {permissions.INS && <div className="col-12 col-sm-6">
            <div className="text-center text-sm-right">
              <Button color="add" onClick={() => {openForm(); setFinalData(false)}} disabled={isOpen}>
                Agregar
              </Button>
            </div>
          </div>}

        </div>
        <Collapse isOpen={isOpen} className="mt-4 border-top">

          <form onSubmit={handleSubmit} onReset={handleReset} className="mt-4">
            <ModalConfirmation
              modalTitle={finalData ? "Editar" : "Crear"}
              modal={modal}
              setModal={setModal}
              crear={() => saveInfo(resetForm)}
              editar={() => saveInfo(resetForm)}
              isEdit={finalData ? true : false}
              values={values}
            >
              <div className="d-flex justify-content-center">
                <h6>¿Está seguro de guardar el registro?</h6>
              </div>
            </ModalConfirmation>

              <Row>
                <Col xs={12} md={6}>
                  <SelectTypeHeadSingle
                    label="Banco"
                    isRequired={true}
                    optionsArray={allBanks}
                    inputName="keyBank"
                    onChangeMethod={onChange}
                    onBlurMethod={handleBlur}
                    touched={touched.keyBank}
                    errors={errors.keyBank}
                    optionValue="keyBank"
                    optionName="nameBank"
                    value={values.keyBank}
                    defaultOption="Seleccione un banco"
                  />
                </Col>

                <Col xs={12} md={6}>
                  <SelectTypeHeadSingle
                    label="Cuenta Contable"
                    isRequired
                    optionsArray={allAccountList}
                    inputName="idAccountList"
                    onChangeMethod={onChange}
                    onBlurMethod={handleBlur}
                    touched={touched.idAccountList}
                    errors={errors.idAccountList}
                    optionValue="idAccountList"
                    optionName="nameAccountList"
                    value={values.idAccountList}
                  />
                </Col>

                <Col xs={12} md={6}>
                  <TextInput
                    label="No. de Cuenta"
                    isRequired
                    inputType="text"
                    inputName="account"
                    onChangeMethod={onChange}
                    onBlurMethod={handleBlur}
                    value={values.account}
                    touched={touched.account}
                    errors={errors.account}
                    isMax = {20}
                  />
                </Col>

                <Col xs={12} md={6}>
                  <TextInput
                    label="Clabe"
                    isRequired
                    inputType="text"
                    inputName="swiftCode"
                    onChangeMethod={onChange}
                    onBlurMethod={handleBlur}
                    value={values.swiftCode}
                    touched={touched.swiftCode}
                    errors={errors.swiftCode}
                    isMax = {18}
                  />
                </Col>

                <Col xs={12} md={6}>
                    <TextInput
                      label="Titular"
                      inputType="text"
                      inputName="titular"
                      onChangeMethod={onChange}
                      onBlurMethod={handleBlur}
                      value={values.titular}
                      touched={touched.titular}
                      errors={errors.titular}
                    />
                </Col>

                <Col xs={12} md={6}>
                  <TextInput
                    label="Numero de Tarjeta"
                    inputType="text"
                    inputName="numberCard"
                    onChangeMethod={onChange}
                    onBlurMethod={handleBlur}
                    value={values.numberCard}
                    touched={touched.numberCard}
                    errors={errors.numberCard}
                    isMax = {16}
                  />
                </Col>

                <Col xs={12} md={6}>
                  <TextInput
                    label="Sucursal"
                    inputType="text"
                    inputName="sucursal"
                    onChangeMethod={onChange}
                    onBlurMethod={handleBlur}
                    value={values.sucursal}
                    touched={touched.sucursal}
                    errors={errors.sucursal}
                  />
                </Col>

                <Col xs={12} md={6}>
                  <CurrencyInput
                    label="Saldo inicial"
                    inputName="avaible"
                    onChangeMethod={(value, name ) => onChangeCurrent({ target: { name: name, value: value } }) }
                    onBlurMethod={handleBlur}
                    value={values.avaible}
                    isRequired={true}
                    touched={touched.avaible}
                    errors={errors.avaible}
                    isMax={10}
                  />
                </Col>

              </Row>
            <Row className="mt-5">

              <div className="col-sm-6 order-2 order-sm-1 text-center text-sm-left mb-4">
                <Button color="danger" type="reset" onClick={() => clear(resetForm)} >
                  Cancelar
                </Button>
              </div>

              <div className="col-sm-6 order-1 order-sm-2 text-center text-sm-right mb-4">
                <Button color="success" type="submit"> Guardar </Button>
              </div>

            </Row>
          </form>

        </Collapse>

      </div>
    }
    </>
  );
};
export default CuentasBancarias;

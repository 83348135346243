import React, { useState, useEffect } from "react";
import Loader from "../../components/Loader/Loader";
import { Table } from "reactstrap";
import s from "../../pages/tables/Tables.module.scss";
import sc from "./TableComponentFiltro.module.scss";
import { fDateLocal } from "../../libs/tools/format";

const TableComponentAbsences = (props) => {
  const {
    registros,
    setRegistros,
    salaryTypesBack,
    selectsData
  } = props;

  const formatDateTime = (date, type) => {
    if(type === 1) return fDateLocal(date);
    const today = new Date(date)
    const month = today.getMonth() + 1
    const day = today.getDate()
    const formattMonth = month < 10 ? `0${month}` : month
    const formattDay = day < 10 ? `0${day}` : day
    return `${formattDay}/${formattMonth}/${today.getFullYear()} ${today.getHours()}:${today.getMinutes()}:00`
  }

  const eliminaRegistro = (ix, id) => {
    let newArray = [...registros];
    if(id) newArray[ix].enabled = 0;
    else newArray = registros.filter((_, ixl) => ixl !== ix);
    setRegistros(newArray);
  };

  return (
    <div className="widget-table-overflow mt-3">
      <Table
        className={`table-striped table-borderless table-hover ${s.statesTable}`}
        responsive
      >
        <thead className="border-bottom border-secondary">
          <tr className={sc.userSelectNone}>
            <th className="text-center">Tipo de deducción</th>
            <th className="text-center">Fecha y hora de inicio</th>
            <th className="text-center">Eliminar</th>
          </tr>
        </thead>
        <tbody>
          {registros.map((e, index) => {
                if (e.enabled === 1) {
                  var nameApplication = "";
                  salaryTypesBack.forEach((s) => {
                    if (
                      s.keyTypeApplicationSalary ===
                      parseInt(e.descuentosAplicacionKeyTypeApplicationSalary)
                    ) {
                      nameApplication = s.nameTypeApplicationSalary;
                    }
                  });
                  if (nameApplication === "") {
                    selectsData.applicationSalaryTypes.map((d) => {
                      if (
                        d.keyTypeApplicationSalary ===
                        parseInt(e.descuentosAplicacionKeyTypeApplicationSalary)
                      ) {
                        nameApplication = d.nameTypeApplicationSalary;
                      }
                    });
                  }

                  return (
                    <tr key={"r"+index}>
                      <td className="text-center">{nameApplication}</td>
                      <td className="text-center">
                        {formatDateTime(e.descuentosAplicacionStartDate, e.descuentosAplicacionKeyTypeApplicationSalary)}
                      </td>
                      <td
                        className="text-center"
                        onClick={() => eliminaRegistro(index, e.idAbcenseDays)}
                      >
                        <i className="fa fa-trash text-danger cursor-pointer" />
                      </td>
                    </tr>
                  );
                }
          })}
        </tbody>
      </Table>
    </div>
  );
};

export default TableComponentAbsences;

import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Table
} from "reactstrap";
import * as Yup from "yup";

import { useFormik } from "formik";
import { toast } from "react-toastify";
import Notification from "../../../../components/Notification/Notification";
import { peticionesReceiver } from "../../../../helpers/peticionesReceiver";

import ModalViewFile from "../../../../components/Modal/ModalViewFile";


const formatter = new Intl.NumberFormat("es-MX", {
  style: "currency",
  currency: "MXN",
});

//styles de dropZone

function ModalViewGasto({
  title,
  modal,
  setModal,
  backdrop,
  keyboard,
  progress,
  refreshMethod,
  btnClose
}) {

  const API = peticionesReceiver();
  const [data, setData] = useState("");

  
  // const [modalConfirmation, setModalConfirmation] = useState(false);
  const [modalView, setModalView] = useState(false);
  const [urlDocument, setUrlDocument] = useState("");

  useEffect(() => {
    if (modal) {
      getExpenses();
    }
  }, [modal]);
  useEffect(() => {
    if (modalView === false) {
      setUrlDocument("")
    }
  }, [modalView]);

  const getExpenses = async () => {
    const params = {
      action: "multiselect",
      table:
        "activityexpenses A left join expense_objects B on (A.idExpenseObject = B.idExpenseObject)",
      rows: "A.idExpense,A.amount,B.fullKey,A.description",
      conditions: `A.enabled = 1 and A.idProgress = ${progress.idProgress}`,
    };
    await API.peticion(params)
      .then((res) => {
        if (res.status === 200 && res.data.code === "200") {
          setData(res.data.data);
        } else {
          toast(
            <Notification
              type={"consultar_error"}
              backMessage={res.data.message}
              withIcon
            />
          );
        }
      })
      .catch((err) => {
        toast(<Notification type={"consultar_servidor_error"} withIcon />);
      });
  };

  const getDocument = async (id) => {
    const params = {
      action: "multiselect",
      table: "bookcase A INNER JOIN files B ON A.idBook = B.idBook",
      rows: "B.src",
      conditions: `A.reference = ${id} AND A.keyFileType = ${17} AND B.enabled = 1`,
      order: "",
    };
    await API.peticion(params)
      .then((res) => {
        
        if (res.status === 200 && res.data.status === "success") {
          setUrlDocument(res.data.data[0])
        } else {
          toast(
            <Notification
              type={"consultar_error"}
              backMessage={res.data.message}
              withIcon
            />
          );
        }
      })
      .catch((err) => {
        toast(<Notification type={"consultar_servidor_error"} withIcon />);
      });
  };

  const defaultData = {
    idProgress: progress.idProgress,
    idExpenseObject: "",
    amount: "",
    description: "",
    notes: "",
  };

  //realiza las peticiones , use de switch
  const insertInfo = async (values, type) => {
    let respuesta = null;
    switch (type) {
      case "insertExpense":
        await API.peticion(values)
          .then((res) => {
            if (
              res.status === "success" ||
              res.status === "200" ||
              res.status === 200
            ) {
              toast(
                <Notification
                  type={"agrega_exito"}
                  backMessage={"Gasto agregado con exito"}
                  withIcon
                />
              );
              respuesta = res.data.data[0];
            } else {
              toast(
                <Notification
                  type={"consultar_error"}
                  backMessage={res.data.message}
                  withIcon
                />
              );
            }
          })
          .catch((err) => {
            toast(<Notification type={"consultar_servidor_error"} withIcon />, {
              closeButton: false,
            });
          });
        break;

      default:
        break;
    }
    return respuesta;
  };

  //Prepara la informacion para ser enviada
  const prepareData = async (values) => {
    const params = {
      action: "insert",
      table: "activityexpenses",
      rows: values,
    };

    await insertInfo(params, "insertExpense");


    refreshMethod()
  };

  const FormSchema = Yup.object().shape({
    idExpenseObject: Yup.string().required(
      "Seleccione una partida presupuestal"
    ),
    amount: Yup.number().required("Ingrese un monto"),
    description: Yup.string().required("Ingrese una descripcion"),
    notes: Yup.string().required("Ingrese notas del proyecto"),
  });

  const onChange = (e) => {
    setFieldValue(e.target.name, e.target.value);
  };

  const {
    handleSubmit,
    values,
    handleBlur,
    errors,
    touched,
    setFieldValue,
    resetForm,
  } = useFormik({
    initialValues: defaultData,
    onSubmit: (values) => {
      // setModalConfirmation(true);
    },
    validationSchema: FormSchema,
    enableReinitialize: true,
    onReset: () => {},
  });

  const toggle = () => {
    setModal(!modal);
  };
  const viewDocument = (values) => { 
    getDocument(values.idExpense)
    setModalView(true)
    
  };

  const CloseBtn = (
    <>
      {btnClose ? (
        <i className="eva eva-close cursor-pointer" onClick={toggle} />
      ) : (
        <></>
      )}
    </>
  );

  
      
  

  return (
    <>
      <Modal
        isOpen={modal}
        backdrop={backdrop}
        keyboard={keyboard}
        toggle={toggle}
        scrollable={true}
        style={{ minWidth: "95%", maxHeight: "90%" }}
      >
        <ModalHeader className="pl-5" close={CloseBtn}></ModalHeader>
        <ModalBody className="mx-2">
          <h2 className="text-center mb-2 text-secondary">
            {title}
          </h2>
          <form onSubmit={handleSubmit} className="mt-4" noValidate>
            <Table
              className={`table-striped table-borderless table-hover `}
              responsive
            >
              <thead className="border-bottom border-secondary">
                <tr>
                  <th className="text-center">Importe</th>
                  <th className="text-center">Partida</th>
                  <th className="text-center">Bien / Servicios</th>
                  <th className="text-center">Evidencia</th>
                </tr>
              </thead>
              <tbody>
                {data === null || data === undefined || data === "" ? (
                  <td colSpan={5} className="text-center">
                    {" "}
                    No se encontraron registros
                  </td>
                ) : (
                  <>
                    {data.map((element, index) => (
                      <tr key={element.idProgress}>
                        <td className="text-center">{formatter.format(element.amount)}</td>
                        <td className="text-center">{element.fullKey}</td>
                        <td className="text-center">{element.description}</td>
                          <td className="text-center">
                            <i
                              className={" fa fa-file-pdf-o text-danger"}
                              style = {{cursor:"pointer"}}
                              onClick={() => viewDocument(element)}
                            />
                          </td>
                      </tr>
                    ))}
                  </>
                )}
              </tbody>
            </Table>
          </form>
        </ModalBody>
      </Modal>

      {modalView && urlDocument !== "" && <ModalViewFile
        modal={modalView}
        setModal={setModalView}
        backdrop={true}
        keyboard={true}
        btnClose={true}
        fileLink={urlDocument.src}
      />}
    </>
  );
}
export default ModalViewGasto;

import React, { useState, useEffect } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Col,
  Row,
  Collapse,
  Alert,
} from "reactstrap";
import * as Yup from "yup";

import { useFormik } from "formik";
import { toast } from "react-toastify";
import Notification from "../../../../components/Notification/Notification";

import { peticionesReceiver } from "../../../../helpers/peticionesReceiver";
import { useDropzone } from "react-dropzone";
import { useMemo } from "react";
import ModalConfirmation from "../../../../components/Modal/ModalConfirmation";
import ModalViewFile from "../../../../components/Modal/ModalViewFile";
import axios from "axios";

import { SelectTypeHeadSingle } from "../../../../components/GenericInputsFormik/SelectTypeHeadSingle";
import { NumberInput } from "../../../../components/GenericInputsFormik/NumberInput";

//styles de dropZone
const baseStyle = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  textAlign: "center",
  padding: "1.25rem",
  height: "250px",
  borderWidth: "2px",
  borderStyle: "dashed",
  borderColor: "#C7D0D9",
  borderRadius: "8px",
  position: "relative",
  cursor: "pointer",
};
const focusedStyle = {
  borderColor: "#2196f3",
};

function ModalAddGasto(props) {
  const {
    modal,
    setModal,
    backdrop,
    keyboard,
    idActivity,
    progress,
    refreshMethod,
    btnClose
  } = props;

  const API = peticionesReceiver();

  const [collapse, setCollapse] = useState(false);
  const [modalConfirmation, setModalConfirmation] = useState(false);

  const [partida, setPartida] = useState("");
  const [documentCorrect, setDocumentCorrect] = useState(2); // 1 correct , 2 false , 3 ignore

  useEffect(() => {
    if (modal) {
      getPartidaPresupuestal();
    }
  }, [modal]);

  const { acceptedFiles, getRootProps, getInputProps, isFocused } = useDropzone(
    {
      acceptedFiles: {
        "aplication/pdf": [".pdf"],
      },
      multiple: false,
      maxFiles: 1,
    }
  );

  const style = useMemo(() => ({
    ...baseStyle,
    ...(isFocused ? focusedStyle : {}),
  }));

  const getPartidaPresupuestal = async () => {
    const params = {
      action: "multiselect",
      table:
        "activitiescost A inner join expense_objects B on (A.idExpenseObject = B.idExpenseObject)",
      rows: "A.idExpenseObject,B.nameObject ",
      conditions: `A.enabled = 1 and A.idActivity = ${idActivity}`,
      group: " A.idExpenseObject",
      /* conditions: `enabled = 1 and idProyect =${proyect.id}` */
    };
    await API.peticion(params)
      .then((res) => {
        if (res.status === 200 && res.data.code === "200") {
          setPartida(res.data.data);
        } else {
          toast(
            <Notification
              type={"consultar_error"}
              backMessage={res.data.message}
              withIcon
            />
          );
        }
      })
      .catch((err) => {
        toast(<Notification type={"consultar_servidor_error"} withIcon />);
      });
  };

  const defaultData = {
    idProgress: progress.idProgress,
    idExpenseObject: "",
    amount: "",
    description: "",
    notes: "",
  };

  useEffect(() => {
    //Validaiciones de pdf y tamaño

    if (acceptedFiles.length > 0) {
      if (acceptedFiles[0].type === "application/pdf") {
        if (acceptedFiles[0].size < 5000000) {
          setDocumentCorrect(1);
        } else {
          toast(
            <Notification
              type={"consultar_error"}
              backMessage={"Archivo demaciado grandre"}
              withIcon
            />
          );
          setDocumentCorrect(2);
        }
      } else {
        toast(
          <Notification
            type={"consultar_error"}
            backMessage={"Ingrese un documento en formato PDF"}
            withIcon
          />
        );
        setDocumentCorrect(2);
      }
    } else {
      setDocumentCorrect(2);
    }
  }, [acceptedFiles]);

  //realiza las peticiones , use de switch
  const insertInfo = async (values, type) => {
    let params = {};
    let respuesta = null;
    switch (type) {
      case "insertExpense":
        await API.peticion(values)
          .then((res) => {
            if (
              res.status === "success" ||
              res.status === "200" ||
              res.status === 200
            ) {
              toast(
                <Notification
                  type={"agrega_exito"}
                  backMessage={"Gasto agregado con exito"}
                  withIcon
                />
              );
              respuesta = res.data.data[0];
            } else {
              toast(
                <Notification
                  type={"consultar_error"}
                  backMessage={res.data.message}
                  withIcon
                />
              );
            }
          })
          .catch((err) => {
            toast(<Notification type={"consultar_servidor_error"} withIcon />, {
              closeButton: false,
            });
          });
        break;

      case "insertDocument":
        params = {
          method: "post",
          url: `${process.env.REACT_APP_API}app/facades/files/fileReceiver.php`,
          data: values,
        }; 
        await axios(params)
          .then((res) => {
            toast(
              <Notification
                type={"agrega_exito"}
                backMessage={"Documento insertado correctamente"}
                withIcon
              />
            );
            setModal(false);
            resetForm();
          })
          .catch((err) => {
            toast(<Notification type={"agrega_error"} withIcon />);
          });

        break;
      default:
        break;
    }
    return respuesta;
  };

  //Prepara la informacion para ser enviada
  const prepareData = async (values) => {
    const params = {
      action: "insert",
      table: "activityexpenses",
      rows: values,
    };

    const varProgress = await insertInfo(params, "insertExpense");

    const formData = new FormData();
    formData.append("action", "saveFileReplace");
    formData.append("reference", varProgress.idExpense);
    formData.append("keyFileType", 17);
    formData.append("binary", acceptedFiles[0]);

    if (varProgress !== null && documentCorrect !== 2) {
      insertInfo(formData, "insertDocument");
    }

    refreshMethod && refreshMethod();
    setModal(false);
  };

  const FormSchema = Yup.object().shape({
    idExpenseObject: Yup.string().required(
      "Seleccione una partida presupuestal"
    ),
    amount: Yup.number().required("Ingrese un monto"),
    description: Yup.string().required("Ingrese una descripcion"),
    notes: Yup.string().required("Ingrese notas del proyecto"),
  });

  const onChange = (e) => {
    setFieldValue(e.target.name, e.target.value);
  };

  const {
    handleSubmit,
    values,
    handleBlur,
    errors,
    touched,
    setFieldValue,
    resetForm,
  } = useFormik({
    initialValues: defaultData,
    onSubmit: (values) => {
      if (documentCorrect !== 2) {
        setModalConfirmation(true);
      }
    },
    validationSchema: FormSchema,
    enableReinitialize: true,
    onReset: () => {},
  });

  const toggle = () => {
    setModal(!modal);
  };
  const toggleCollapse = () => {
    setCollapse(!collapse);
  };

  const CloseBtn = (
    <>
      {btnClose ? (
        <i className="eva eva-close cursor-pointer" onClick={toggle} />
      ) : (
        <></>
      )}
    </>
  );

  return (
    <>
      <ModalConfirmation
        modalTitle={"Crear"}
        modal={modalConfirmation}
        setModal={setModalConfirmation}
        crear={prepareData}
        isEdit={false}
        values={values}
      >
        <div className="d-flex justify-content-center">
          <h6>¿Está seguro de registrar el gasto?</h6>
        </div>
      </ModalConfirmation>
      <Modal
        isOpen={modal}
        backdrop={backdrop}
        keyboard={keyboard}
        toggle={toggle}
        scrollable={true}
        style={{ minWidth: "95%", maxHeight: "90%" }}
      >
        <ModalHeader className="pl-5" close={CloseBtn}></ModalHeader>
        <ModalBody className="mx-2">
          <form onSubmit={handleSubmit} className="mt-4" noValidate>
            <p className="headline-1 text-center mb-2 text-secondary">
              {" "}
              Agregar Gasto{" "}
            </p>
            <Row className="d-flex  justify-content-center align-items-center text-center mt-3">
              <Col xs={12} md={5} lg={5}>
                <SelectTypeHeadSingle
                  label="Partida presupuestal"
                  isRequired={true}
                  inputName="idExpenseObject"
                  optionsArray={partida}
                  defaultOption="Seleccione una partida presupuestal"
                  onChangeMethod={onChange}
                  onBlur={handleBlur}
                  isDisabled={false}
                  value={values.idExpenseObject}
                  touched={touched.idExpenseObject}
                  errors={errors.idExpenseObject}
                  optionValue="idExpenseObject"
                  optionName="nameObject"
                />
              </Col>
              <Col xs={12} md={5} lg={5}>
                <NumberInput
                  label="Monto"
                  inputName="amount"
                  onChangeMethod={onChange}
                  onBlurMethod={handleBlur}
                  value={values.amount}
                  errors={errors.amount}
                  touched={touched.amount}
                  prefix = "$"
                  isRequired
                />
              </Col>
            </Row>
            <Row className="d-flex  justify-content-center align-items-center text-center mt-3">
              <Col xs={12} md={5} lg={5}>
                <label htmlFor="description">
                  Productos o servicios adquiridos
                  <span className="text-danger">*</span>
                </label>
                <textarea
                  name="description"
                  id="description"
                  onChange={onChange}
                  onBlur={handleBlur}
                  rows={4}
                  className={
                    "form-control" +
                    (errors.description && touched.description
                      ? " is-invalid"
                      : "")
                  }
                  value={values.description}
                />
                {touched.description && errors.description && (
                  <span className="text-danger">{errors.description}</span>
                )}
              </Col>
              <Col xs={12} md={5} lg={5}>
                <label htmlFor="notes">
                  Comentarios/Notas
                  <span className="text-danger">*</span>
                </label>
                <textarea
                  name="notes"
                  id="notes"
                  onChange={onChange}
                  onBlur={handleBlur}
                  rows={4}
                  className={
                    "form-control" +
                    (errors.notes && touched.notes ? " is-invalid" : "")
                  }
                  value={values.notes}
                />
                {touched.notes && errors.notes && (
                  <span className="text-danger">{errors.notes}</span>
                )}
              </Col>
            </Row>

            <Row className="d-flex  justify-content-center align-items-center text-center mt-3">
              <Col xs={12} md={6} lg={6}>
                <div>
                  <label htmlFor="file">Adjunte una evidencia</label>
                  <div {...getRootProps({ style })}>
                    <input {...getInputProps()} />

                    <div className="mt-3">
                      <p className="headline-2">Documentos Agregados</p>
                      {acceptedFiles.length > 0 && documentCorrect === 1 ? (
                        <span className="headline-3">
                          {acceptedFiles[0].path}
                        </span>
                      ) : (
                        <span className="headline-3">
                          Seleccione un archivo Pdf
                        </span>
                      )}
                    </div>
                  </div>

                  {documentCorrect === 2 ? (
                    <span className="text-danger headline-2">
                      Ingrese un documento valido
                    </span>
                  ) : (
                    <span className="text-secondary headline-2">
                      Documento valido 
                    </span>
                  )}
                </div>
              </Col>
            </Row>

            <Row className="d-flex  justify-content-center align-items-center text-center mt-3 mb-3">
              <Col
                className="d-flex justify-content-around mt-3"
                xs={12}
                md={6}
                lg={6}
              >
                <Button color="success" type="submit">
                  Guardar
                </Button>
              </Col>
            </Row>
          </form>
        </ModalBody>
      </Modal>
    </>
  );
}
export default ModalAddGasto;

import React, { useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Button, Collapse, Row, Col } from "reactstrap";
import ModalConfirmation from "../../../../components/Modal/ModalConfirmation";
import { NumberInput } from "../../../../components/GenericInputsFormik/NumberInput";
import {
  TextInput,
  SelectTypeHeadSingle,
} from "../../../../components/GenericInputsFormik/index";

import { useEffect } from "react";
import { peticionesReceiver } from "../../../../helpers/peticionesReceiver";
import { toast } from "react-toastify";
import Notification from "../../../../components/Notification/Notification";
import Loader from "../../../../components/Loader/Loader";
import TablesSwitchs from "./TablesSwitchs";

const FormPercepcionesExtraordinarias = ({
  permissions,
  setRefreshP,
  perception,
  setPerception,
}) => {
  const [modalConfirmation, setModalConfirmation] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [countLoadings, setCountLoadings] = useState(0);
  const [unders, setUnders] = useState([]);

  const API = peticionesReceiver();

  const FormSchema = Yup.object().shape({
    namePerceptionExtra: Yup.string().required(
      "Ingrese el nombre de la percepcion"
    ),
    idUnder: Yup.string().required("Seleccione un concepto"),
    daysPay: Yup.string().required("Ingrese los dias de pago"),
    startDate: Yup.string().required("Seleccione la fecha de inicio"),
    endDate: Yup.string().required("Seleccione la fecha de termino"),
  });

  const getUnders = async () => {
    const data = {
      action: "multiselect",
      table: "unders",
      rows: "idUnder,concat(under,' - ',nameUnder) as name",
      conditions: "enabled = 1",
      order: "cast(under as float) asc",
    };
    await API.peticion(data)
      .then((res) => {
        if (res.status === 200 && res.data.code === "200") {
          setUnders(res.data.data);
        } else {
          toast(<Notification type={"consultar_error"} withIcon />);
        }
      })
      .catch((err) => {
        toast(<Notification type={"consultar_servidor_error"} withIcon />);
      });
    setCountLoadings(countLoadings + 1);
  };

  function getDays(endDate) {
    const oneDay = 24 * 60 * 60 * 1000;
    const date = new Date();

    const timeDifference = Math.abs(
      new Date(values.startDate) - new Date(endDate)
    );
    setFieldValue("daysPay", Math.round(timeDifference / oneDay));
  }

  const {
    handleSubmit,
    values,
    errors,
    setFieldValue,
    touched,
    handleBlur,
    resetForm,
  } = useFormik({
    initialValues: {
      idPerceptionExtra: "",
      namePerceptionExtra: "",
      idUnder: "",
      daysPay: "",
      startDate: "",
      endDate: "",
    },
    onSubmit: () => {
      setModalConfirmation(true);
    },
    validationSchema: FormSchema,
    enableReinitialize: true,
  });

  const onChange = (e) => {
    const { name, value } = e.target;

    if (name === "startDate") {
      setFieldValue("endDate", "");
      setFieldValue("daysPay", "");
    } else {
      if (name === "endDate") {
        getDays(value);
      }
    }

    setFieldValue(name, value);
  };

  const savePerception = async () => {
    const data =
      !isEdit === true
        ? {
            action: "insert",
            table: "perceptionsextras",
            rows: {
              namePerceptionExtra: values.namePerceptionExtra,
              idUnder: values.idUnder,
              daysPay: values.daysPay,
              startDate: values.startDate,
              endDate: values.endDate,
            },
          }
        : {
            action: "update",
            table: "perceptionsextras",
            rows: {
              namePerceptionExtra: values.namePerceptionExtra,
              idUnder: values.idUnder,
              daysPay: values.daysPay,
              startDate: values.startDate,
              endDate: values.endDate,
            },
            condition: {
              idPerceptionExtra: values.idPerceptionExtra,
            },
          };
    const res = await API.peticion(data);
    if (res.status === 200 && res.data.status === "success") {
      toast(
        <Notification
          type={"agrega_exito"}
          backMessage={res.data.message}
          withIcon
        />
      );
      /* setCollapse("home"); */
      setPerception(res.data.data[0]);
      setFieldValue("idPerceptionExtra", res.data.data[0].idPerceptionExtra);
      setRefreshP(true);
    } else {
      toast(
        <Notification
          type={"agrega_error"}
          backMessage={res.data.message}
          withIcon
        />
      );
    }
  };

  useEffect(() => {
    getUnders();

    if (perception && perception !== "") {
      setDataForEdit();
    }
  }, []);

  const setDataForEdit = () => {
    setIsEdit(true);

    setFieldValue("idPerceptionExtra", perception.idPerceptionExtra);
    setFieldValue("namePerceptionExtra", perception.namePerceptionExtra);
    setFieldValue("idUnder", perception.idUnder);
    setFieldValue("daysPay", perception.daysPay);
    setFieldValue("startDate", perception.startDate);
    setFieldValue("endDate", perception.endDate);
  };

  return (
    <div>
      {true ? (
        <>
          <form onSubmit={handleSubmit}>
            <div className="text-center">
              <h2>Nueva Percepcion</h2>
            </div>

            <Row className="d-flex justify-content-around ">
              <Col xs={12} lg={5}>
                <TextInput
                  label="Nombre de la percepcion"
                  inputType="text"
                  inputName="namePerceptionExtra"
                  isRequired
                  onChangeMethod={onChange}
                  onBlurMethod={handleBlur}
                  value={values.namePerceptionExtra}
                  touched={touched.namePerceptionExtra}
                  errors={errors.namePerceptionExtra}
                />
              </Col>
              <Col xs={12} md={12} lg={5}>
                <SelectTypeHeadSingle
                  label="Seleccione un concepto"
                  isRequired={true}
                  inputName="idUnder"
                  optionsArray={unders}
                  defaultOption="Seleccione un concepto"
                  onChangeMethod={onChange}
                  value={values.idUnder}
                  optionValue="idUnder"
                  optionName="name"
                  onBlurMethod={handleBlur}
                  errors={errors.idUnder}
                  touched={touched.idUnder}
                />
              </Col>
            </Row>

            <Row className="d-flex justify-content-around">
              <Col xs="12" lg="5">
                <TextInput
                  label="Fecha de inicio"
                  inputType="date"
                  inputName="startDate"
                  onChangeMethod={onChange}
                  onBlurMethod={handleBlur}
                  value={values.startDate}
                  isRequired={true}
                  touched={touched.startDate}
                  errors={errors.startDate}
                />
              </Col>
              <Col xs="12" lg="5">
                <TextInput
                  label="Fecha de termino"
                  inputType="date"
                  inputName="endDate"
                  onChangeMethod={onChange}
                  onBlurMethod={handleBlur}
                  value={values.endDate}
                  isRequired={true}
                  touched={touched.endDate}
                  errors={errors.endDate}
                  isDisabled={values.startDate ? false : true}
                  isMin={values.startDate}
                />
              </Col>
            </Row>

            <Row className="d-flex justify-content-around ">
              <Col xs={12} lg={5}>
                <NumberInput
                  label="Numero de dias a pagar"
                  inputName="daysPay"
                  isRequired
                  onChangeMethod={onChange}
                  onBlurMethod={handleBlur}
                  value={values.daysPay}
                  touched={touched.daysPay}
                  errors={errors.daysPay}
                  required
                  disableSep={true}
                  enabled
                />
              </Col>
            </Row>

            <div className="d-flex justify-content-around">
              {isEdit
                ? permissions.UPD && (
                    <div>
                      <Button type="submit" color="success">
                        Editar
                      </Button>
                    </div>
                  )
                : permissions.INS && (
                    <div>
                      <Button type="submit" color="success">
                        Agregar
                      </Button>
                    </div>
                  )}
            </div>
          </form>

          {values.idPerceptionExtra &&
            values.idPerceptionExtra !== "" &&
            permissions.UPD && (
              <div className="mt-5">
                <TablesSwitchs
                  idPerceptionExtra={values.idPerceptionExtra}
                  permissions={permissions}
                />
              </div>
            )}

          <ModalConfirmation
            modalTitle={isEdit ? "Editar" : "Crear"}
            modal={modalConfirmation}
            setModal={setModalConfirmation}
            crear={savePerception}
            editar={savePerception}
            isEdit={false}
            values={values}
          >
            <div className="text-center">
              Esta seguro de continuar con el registro
            </div>
          </ModalConfirmation>
        </>
      ) : (
        <div>
          <Loader />
        </div>
      )}
    </div>
  );
};

export default FormPercepcionesExtraordinarias;


import React from "react";
import Widget from "../../../../components/Widget/Widget";
import FormConfiguracionCalculo from "../FormConfiguracionCalculo";
import { createConfiguration,getConfiguration } from "../functions";

export const CrearConfiguracionCalculo = () => {

  return (
    <Widget className="widget-p-md">
      <h3>Crear configuración de calculo</h3>
      <FormConfiguracionCalculo crear={createConfiguration} obtener={getConfiguration} />
    </Widget>
  )
}

export default CrearConfiguracionCalculo;
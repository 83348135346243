import { useHistory } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { Button } from "reactstrap";
import { Formik, Form, Field, ErrorMessage, useFormikContext } from "formik";
import * as Yup from "yup";
import ModalConfirmation from "../../../components/Modal/ModalConfirmation";
import ModalTableVer from "../../../components/Modal/ModalTableVer";
import s from "../../nomina/reportes/filtros/InputClear.module.scss";
import { TextInput } from "../../../components/GenericInputsFormik/TextInput";

const defaultForm = {
  idUser: "",
  name: "",
  pName: "",
  mName: "",
  user: "",
  phone: "",
  email: "",
  password: "",
  idStaff: "",
  staffNumber: "",
  actualArea: "",
  isStaff: false,
};

const FormUser = ({ crear, data, editar }) => {
  const history = useHistory();
  const [modal, setModal] = useState(false);
  const [modalTabla, setModalTabla] = useState(false);
  const [seePass, setseePass] = useState(false);

  const FormSchema = Yup.object().shape({
    name: Yup.string()
      .min(3, "Nombre debe contener al menos 3 letras")
      .required("Ingrese un nombre"),
    pName: Yup.string()
      .min(3, "Apellido paterno debe contener al menos 3 letras")
      .required("Ingrese un apellido paterno"),
    user: Yup.string()
      .min(3, "Ingrese un usuario valido")
      .required("Ingrese un usuario"),
    phone: Yup.string()
      .min(10, "Debe de contener 10 digitos")
      .max(10, "Debe de contener 10 digitos")
      .required("Ingrese un teléfono"),
    email: Yup.string()
      .email("Correo electrónico invalido")
      .required("Ingrese un correo electronico"),
    password: Yup.string()
      .min(6, "Debe tener una constraseña con mas de 6 caracteres ")
      .required("Ingrese una constraseña"),
  });

  const submitFunction = (fields) => {
    setModal(true);
  };

  const cancelOp = () => {
    history.push("/template/usuarios");
  };

  return (
    <>
      <Formik
        initialValues={defaultForm}
        validationSchema={FormSchema}
        onSubmit={(fields, actions) => {
          submitFunction(fields);
          actions.setSubmitting(true);
        }}
        onReset={(fields, { resetForm }) => {
          cancelOp();
        }}
      >
        {({ errors, status, touched, values }) => (
          <Form className="mt-4">
            <ModalConfirmation
              modalTitle={data ? "Editar" : "Crear"}
              modal={modal}
              setModal={setModal}
              crear={crear}
              editar={editar}
              isEdit={data ? true : false}
              values={values}
            >
              {data ? (
                <div className="d-flex justify-content-center">
                  <h6>¿Está seguro de editar el registro?</h6>
                </div>
              ) : (
                <div className="d-flex justify-content-center">
                  <h6>¿Desea continuar con el registro?</h6>
                </div>
              )}
            </ModalConfirmation>
            <Field name="isStaff">
              {({ field }) => (
                <div className="mb-1">
                  <label htmlFor="isStaff">
                    ¿Es empleado de la institución?
                  </label>
                  <input
                    style={{
                      marginLeft: "16px",
                      width: "20px",
                      height: "20px",
                      border: "1px solid red",
                    }}
                    id="isStaff"
                    type="checkbox"
                    {...field}
                    checked={field.value}
                  />
                </div>
              )}
            </Field>

            <div className="form-group">
              <label htmlFor="name">
                Nombre<span className="text-danger">*</span>
              </label>
              <Field
                name="name"
                type="text"
                className={
                  "form-control" +
                  (errors.name && touched.name ? " is-invalid" : "")
                }
                disabled={values.idStaff !== ""}
              />
              <ErrorMessage
                name="name"
                component="div"
                className="invalid-feedback"
              />
            </div>
            <div className="form-group">
              <label htmlFor="pName">
                Apellido paterno<span className="text-danger">*</span>
              </label>
              <Field
                name="pName"
                type="text"
                className={
                  "form-control" +
                  (errors.pName && touched.pName ? " is-invalid" : "")
                }
                disabled={values.idStaff !== ""}
              />
              <ErrorMessage
                name="pName"
                component="div"
                className="invalid-feedback"
              />
            </div>
            <div className="form-group">
              <label htmlFor="mName">Apellido materno</label>
              <Field
                name="mName"
                type="text"
                className={
                  "form-control" +
                  (errors.mName && touched.mName ? " is-invalid" : "")
                }
                disabled={values.idStaff !== ""}
              />
              <ErrorMessage
                name="mName"
                component="div"
                className="invalid-feedback"
              />
            </div>
            <div className="form-group">
              <label htmlFor="phone">
                Telefono<span className="text-danger">*</span>
              </label>
              <Field
                name="phone"
                type="number"
                className={
                  "form-control" +
                  (errors.phone && touched.phone ? " is-invalid" : "")
                }
              />
              <ErrorMessage
                name="phone"
                component="div"
                className="invalid-feedback"
              />
            </div>
            <div className="form-group">
              <label htmlFor="email">
                Correo elecrónico<span className="text-danger">*</span>
              </label>
              <Field
                name="email"
                type="text"
                className={
                  "form-control" +
                  (errors.email && touched.email ? " is-invalid" : "")
                }
              />
              <ErrorMessage
                name="email"
                component="div"
                className="invalid-feedback"
              />
            </div>
            <div className="form-group">
              <label htmlFor="user">
                Usuario<span className="text-danger">*</span>
              </label>
              <Field
                name="user"
                type="text"
                className={
                  "form-control" +
                  (errors.user && touched.user ? " is-invalid" : "")
                }
              />
              <ErrorMessage
                name="user"
                component="div"
                className="invalid-feedback"
              />
            </div>

            <Field name="password">
              {({
                field, // { name, value, onChange, onBlur }
                form, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                meta,
              }) => (
                <div className="form-group position-relative">
                    <label htmlFor="password">
                      Contraseña<span className="text-danger">*</span>
                    </label>
                    <input
                    id="password"
                      type={seePass ? "text" : "password"}
                      className={
                        "form-control" +
                        (meta.touched && meta.error ? " is-invalid" : "")
                      }
                      {...field}
                    />
                    {meta.touched && meta.error && (
                      <span
                        className="text-danger"
                        style={{ fontSize: "0.875em" }}
                      >
                        {meta.error}
                      </span>
                    )}
                  <i
                    className={(seePass? " eva eva-eye-off-outline" : " eva eva-eye-outline")}
                    style={{ padding: "0px", 
                      cursor: "pointer", 
                      position: "absolute", 
                      top: "43px", 
                      right: (meta.touched && meta.error) ? "35px" : "15px"
                     }}
                    onClick={() => setseePass(!seePass)}
                  />
                </div>
                // <TextInput
                // label="Contraseña"
                // inputName={field.name}
                // inputType="password"
                // onChangeMethod={field.onChange}
                // onBlurMethod={field.onBlur}
                // value={field.value}
                // isRequired={true}
                // touched={form.touched.password}
                // errors={form.errors.password}
                // isMin={5}
                // isMax={30}
                // />
              )}
            </Field>
            <div className=" d-flex flex-md-row flex-column-reverse justify-content-md-around">
              <Button color="danger" type="reset" className="mt-3">
                Cancelar
              </Button>
              <Button color="success" type="submit" className="mt-2 mt-md-3">
                Guardar
              </Button>
            </div>
            <FormikData
              info={data}
              modalTabla={modalTabla}
              setModalTabla={setModalTabla}
            />
          </Form>
        )}
      </Formik>
    </>
  );
};

const FormikData = ({ info, modalTabla, setModalTabla }) => {
  const cabecerasModal = [
    "Id",
    "Nombre",
    "Tipo",
    "Núm. Empleado",
    "Seleccionar",
  ];
  const [flag, setflag] = useState(false);
  const filtroModal = [true, true, true, true];
  const [parametrosModal] = useState({
    action: "datatable",
    table:
      "staff A INNER JOIN employtypes B ON A.keyEmployType = B.keyEmployType LEFT JOIN jobstaff C ON A.idStaff = C.idStaff LEFT JOIN users D ON A.idStaff = D.idStaff",
    rows: "A.idStaff,CONCAT(A.name,' ',A.pName,' ',A.mName) as fullName,B.nameEmployType,A.staffNumber,A.name,A.pName,A.mName,C.idArea",
    conditions: `A.enabled = 1 AND IF(C.valid IS NULL,true,IF(C.valid != 0, true, false)) AND IF(D.idStaff IS NULL,TRUE,IF(D.idStaff != C.idStaff,TRUE,IF(D.enabled = 0,TRUE,FALSE)))`,
    page: 0,
    records: 5,
    search: "",
    order: "",
  });
  const { setFieldValue, values } = useFormikContext();

  useEffect(() => {
    if (info) {
      if (info[9] !== "") {
        if (values.isStaff) {
          if (flag) {
            setModalTabla(true);
          } else {
            setflag(true);
          }
        } else {
          setFieldValue("name", "");
          setFieldValue("pName", "");
          setFieldValue("mName", "");
          setFieldValue("idStaff", "");
          setFieldValue("staffNumber", "");
          setFieldValue("actualArea", "");
        }
      } else {
        if (values.isStaff) {
          setModalTabla(true);
        } else {
          if (flag) {
            if (values.name !== info[6]) {
              setFieldValue("name", "");
              setFieldValue("pName", "");
              setFieldValue("mName", "");
              setFieldValue("idStaff", "");
              setFieldValue("staffNumber", "");
              setFieldValue("actualArea", "");
            }
          } else {
            setflag(true);
          }
        }
      }
    } else {
      if (values.isStaff) {
        setModalTabla(true);
      } else {
        setFieldValue("name", "");
        setFieldValue("pName", "");
        setFieldValue("mName", "");
        setFieldValue("idStaff", "");
        setFieldValue("staffNumber", "");
        setFieldValue("actualArea", "");
      }
    }

    // if(info){
    //   if(!info[9]){
    //     if(values.isStaff){
    //       setModalTabla(true);
    //     }
    //   }
    // }else{
    //   if(values.isStaff){
    //     setModalTabla(true);
    //   }else{
    //     setFieldValue("name", "");
    //   setFieldValue("pName", "");
    //   setFieldValue("mName", "");
    //   setFieldValue("idStaff", "");
    //   setFieldValue("staffNumber", "");
    //   setFieldValue("actualArea", "");
    //   }
    // }
  }, [values.isStaff]);

  useEffect(() => {
    if (!modalTabla) {
      if (values.idStaff === "") {
        setFieldValue("isStaff", false);
      }
    }
  }, [modalTabla]);

  useEffect(() => {
    if (info) {
      setFieldValue("idUser", info[0]);
      setFieldValue("name", info[6]);
      setFieldValue("pName", info[7]);
      setFieldValue("mName", info[8]);
      setFieldValue("user", info[2]);
      setFieldValue("phone", info[3]);
      setFieldValue("email", info[4]);
      setFieldValue("password", info[5]);

      setFieldValue("idStaff", info[9]);
      setFieldValue("staffNumber", info[10]);
      setFieldValue("actualArea", info[11]);
      setFieldValue("isStaff", info[9] !== "");
    }
  }, [info]);

  const selectStaff = (el) => {
    setFieldValue("idStaff", el[0]);
    setFieldValue("name", el[4]);
    setFieldValue("pName", el[5]);
    setFieldValue("mName", el[6]);
    setFieldValue("staffNumber", el[3]);
    setFieldValue("actualArea", el[7]);
    setModalTabla(false);
  };

  return (
    <ModalTableVer
      modal={modalTabla}
      setModal={setModalTabla}
      title="Empleados"
      cabeceras={cabecerasModal}
      filtro={filtroModal}
      parametros={parametrosModal}
      selectRegistro={selectStaff}
      btnClose
    />
  );
};

export default FormUser;

import axios from "axios";
import { peticionEncript } from "../helpers/peticionesEncripted";
import { peticionesReceiver } from "../helpers/peticionesReceiver";

import {
  AUTH_FAILURE,
  LOGIN_SUCCESS,         
  LOGOUT_SUCCESS,
  USER_ID,
  USER_TOKEN,
  PROFILE_TYPE,
  LOGOUT_USER,
  PERMISSION,
  USER_INFO
}
from '../types'
export const authError = (payload)  => ({
  type: AUTH_FAILURE,
  payload
})

export const logoutUser = () => {
  localStorage.removeItem("authenticated");
  localStorage.removeItem("user");
  axios.defaults.headers.common["Authorization"] = "";

  return (dispatch) => {
    dispatch(logout())
    dispatch({ type: LOGOUT_SUCCESS });
  }
}

export const receiveToken = (token, userName)  => {
  localStorage.setItem("authenticated", token)
  return (dispatch) => {
    dispatch(saveToken(token))
    dispatch({ type: LOGIN_SUCCESS });
  };
}

export const getUser = async(id) => {
  const params = {
    action: "datatable",
    table: "users",
    rows: "idStaff",
    conditions: `enabled = 1 AND idUser = ${id}`,
    page:0,
    records:5,
    search:"",
    order:""
  }
  const API = peticionesReceiver();
  const result = await API.peticion(params)
  return result.data.data[0].idStaff
}

export const getAvatar = (id,name) => {
  return async(dispatch) => {
    const userId = await getUser(id)
    const query = {
      action: "getUserImage",
      rows: { idStaff:userId }
    };

    try{
      const finalData = peticionEncript(query) 
      const result = await axios.post(`${process.env.REACT_APP_API}app/facades/profiles/profilesF.php`, finalData)

      if(result.data.code === '200'){
        dispatch(saveUserInformation({name: name,avatar: result.data.data[0].src}))
      }
      else {
        dispatch(authError(result.data.message)) 
        return {success: false, message: result.data.message}
      }
    }
    catch (error) {
      dispatch(authError("Something was wrong. Try again"))
      return {success: false, message: "Something was wrong. Try again"}
    }
  }
}

export const saveProfileType = (profileType) => ({
  type: PROFILE_TYPE,
  payload: profileType
})

export const saveToken = (token) => ({
  type: USER_TOKEN,
  payload: token
})

export const saveUserId = (user) => ({
  type: USER_ID,
  payload: user
})

export const logout = () => ({
  type: LOGOUT_USER
})

export const saveSelectPermission = (permission) => ({
  type: PERMISSION,
  payload: permission
})

export const saveUserInformation = (info) => ({
  type: USER_INFO,
  payload: info
})

export const loginUser = (creds) => {
  return async(dispatch) => {
    if (creds.user.length > 0 && creds.password.length > 0) {
      const finalData = peticionEncript(creds)
      const result = await axios.post(`${process.env.REACT_APP_API}login/login.php`, finalData)
      if(result.data.code === '200'){
        const token = result.data.token;
        const username = result.data.name

        dispatch(receiveToken(token,creds.user))
        dispatch(saveUserId(result.data.idUser))
        dispatch(getAvatar(result.data.idUser,username.charAt(0) + (username.slice(1)).toLowerCase()))
        return {success: true}
      }
      else {
        dispatch(authError(result.data.message)) 
        return {success: false, message: result.data.message}
      }
    } 
    else {
      dispatch(authError("Something was wrong. Try again"))
      return {success: false, message: "Something was wrong. Try again"}
    }
  }
}
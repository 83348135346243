import React, { useState, useEffect } from "react";
import Loader from "../../components/Loader/Loader";
import { Table } from "reactstrap";
import s from "../../pages/tables/Tables.module.scss";
import sc from "./TableComponentFiltro.module.scss";
import axios from "axios";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Notification from "../../components/Notification/Notification";
import { toast } from "react-toastify";
import { peticionEncript } from "../../helpers/peticionesEncripted";
import { fDatewhitTime } from "./cellsFormat";

/**
 * @param {Object} props
 * @param {Object} props.params - Objeto con los parametros para realizar la consulta
 * @param {Function} props.resetFunction - Opcional - Funcion para reiniciar el formulario 
 * @param {Function} props.updateData - Opcional - Funcion que realzia una actualizacion de algun dato
 * @param {Object} props.permissions - Objeto con los permisos del usuario sobre el modulo
 */
const TableComponentFiles = ({
  params,
  resetFunction,
  updateData,
  permissions,
}) => {
  const [fileLink, setFileLink] = useState("");
  const [fileName, setFileName] = useState("");
  const [data, setData] = useState("");
  const [modal, setModal] = useState(false);
  const [modalEliminar, setModalEliminar] = useState({
    modal: false,
    elemento: "",
  });
  const [loading, setLoading] = useState(true);

  const getData = async () => {
    setLoading(true); 
    const finalData = peticionEncript(params);
    await axios
      .post(
        `${process.env.REACT_APP_API}app/facades/files/fileReceiver.php`,
        finalData
      )
      .then((res) => {
        if (res.data.code === "200" && res.data.status === "success") {
          setData(res.data.data);
        } else {
          setData(res.data.message);
        }
      })
      .catch((err) => {
        toast(<Notification type={"consultar_error"} withIcon />);
      });
    setLoading(false);
  };

  const viewFile = (e) => {
    setFileName(e.nameFyleType);
    setFileLink(e.src);
    setModal(true);
  };

  const deleteFile = async (e) => {
    setLoading(true);
    const parametros = {
      action: "deleteFile",
      idFile: e.idFile,
    };
    const finalData = peticionEncript(parametros);
    await axios
      .post(
        `${process.env.REACT_APP_API}app/facades/files/fileReceiver.php`,
        finalData
      )
      .then((res) => {
        if (res.data.code === "200" && res.data.status === "success") {
          toast(<Notification type={"elimina_exito"} withIcon />);
          if (updateData) updateData();
        } else {
          toast(<Notification type={"elimina_error"} withIcon />);
        }
        setLoading(false);
      })
      .catch((err) => {
        toast(<Notification type={"consultar_error"} withIcon />);
      });
    setModalEliminar((prevState) => ({
      ...prevState,
      modal: false,
      elemento: "",
    }));
    getData();
    if (resetFunction) resetFunction();
  };

  useEffect(() => {
    getData();
  }, [params]);

  const toggle = () => setModal(false);

  const toggleElimnar = (action, e) => {
    if (action === "abrir") {
      setModalEliminar((prevState) => ({
        ...prevState,
        modal: true,
        elemento: e,
      }));
    } else {
      setModalEliminar((prevState) => ({
        ...prevState,
        modal: false,
        elemento: "",
      }));
    }
  };

  const closeEliminarBtn = (
    <i
      className="eva eva-close cursor-pointer"
      onClick={() => toggleElimnar("cerrar")}
    />
  );

  const CloseBtn = (
    <i className="eva eva-close cursor-pointer" onClick={toggle} />
  );

  const clearDate = (d) => {
    return fDatewhitTime(d);
  };

  return (
    <div className="widget-table-overflow mt-3">
      <div>
        <Modal isOpen={modal} toggle={toggle} style={{ maxWidth: "80%" }}>
          <ModalHeader toggle={toggle} close={CloseBtn} className="headline-1">
            {fileName}
          </ModalHeader>
          <ModalBody>
            {fileLink.includes(".jpg") ||
            fileLink.includes(".jpeg") ||
            fileLink.includes(".png") ||
            fileLink.includes(".gif") ? (
              <div className="text-center">
                <img
                  src={fileLink}
                  alt="archivo"
                  className="img-fluid"
                  style={{ maxWidth: "60%" }}
                />
              </div>
            ) : (
              <div className="embed-responsive embed-responsive-21by9">
                <iframe
                  className="embed-responsive-item"
                  src={fileLink}
                  allowfullscreen
                ></iframe>
              </div>
            )}
          </ModalBody>
          <ModalFooter>
            <div className="w-100 d-flex justify-content-around">
              <Button
                className="btn-outline-secondary mr-3"
                outline
                onClick={toggle}
              >
                Cerrar
              </Button>
            </div>
          </ModalFooter>
        </Modal>
      </div>
      <Table
        className={`table-striped table-borderless table-hover ${s.statesTable}`}
        responsive
      >
        <Modal isOpen={modalEliminar.modal} toggle={toggleElimnar}>
          <ModalHeader
            toggle={() => toggleElimnar("cerrar")}
            close={closeEliminarBtn}
            className="headline-1"
          >
            Eliminar
          </ModalHeader>
          <ModalBody>
            <div className="d-flex justify-content-center text-center">
              <div className="text-center">
                <p style={{ fontSize: "20px" }} className="mb-2">
                  ¿Esta seguro de eliminar el archivo <br />
                  <span className="font-weight-bold">
                    {modalEliminar.elemento.nameFyleType}
                  </span>
                  ?
                </p>
                <p className="mt-3">Esta acción no se podrá deshacer</p>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <div className="w-100 d-flex justify-content-around">
              <Button
                className="btn-outline-secondary mr-3"
                outline
                onClick={() => toggleElimnar("cerrar")}
              >
                Cerrar
              </Button>
              <Button
                className="btn-outline-danger mr-3"
                outline
                onClick={() => deleteFile(modalEliminar.elemento)}
              >
                Eliminar
              </Button>
            </div>
          </ModalFooter>
        </Modal>
        <thead className="border-bottom border-secondary">
          <tr className={sc.userSelectNone}>
            <th className="text-center">Tipo de Archivo</th>
            <th className="text-center">Fecha de subida</th>
            <th className="text-center">Ver</th>
            {permissions.DEL && <th className="text-center">Eliminar</th>}
          </tr>
        </thead>
        <tbody>
          {loading ? (
            <tr key={"loading"}>
              <td colSpan={4}>
                <Loader />
              </td>
            </tr>
          ) : (
            <>
              {data !== "No existen archivos" ? (
                data.map((e, ix) => (
                  <tr key={"D" + ix}>
                    <td className="text-center">{e.nameFyleType}</td>
                    <td className="text-center">{clearDate(e.created)}</td>
                    <td className="text-center" onClick={() => viewFile(e)}>
                      <i
                        className="fa fa-eye text-info"
                        style={{ cursor: "pointer" }}
                      />
                    </td>
                    {permissions.DEL && (
                      <td
                        className="text-center"
                        onClick={() => toggleElimnar("abrir", e)}
                      >
                        <i
                          className="fa fa-trash text-danger"
                          style={{ cursor: "pointer" }}
                        />
                      </td>
                    )}
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={permissions.DEL ? 4 : 3} className="text-center">
                    {data}
                  </td>
                </tr>
              )}
            </>
          )}
        </tbody>
      </Table>
    </div>
  );
};

export default TableComponentFiles;

import { Row, Col, FormGroup, Input, Label, Button } from "reactstrap";
import { useFormik } from "formik";
import { useState } from "react";
import * as Yup from "yup";
import { toast } from "react-toastify";

import ModalConfirmation from "../../../../components/Modal/ModalConfirmation";
import Notification from "../../../../components/Notification/Notification";
import { peticionesReceiver } from "../../../../helpers/peticionesReceiver";
import { TextInput } from "../../../../components/GenericInputsFormik";
import { validarRFC } from "../../../../libs/personalData/RFC";

export default function AddProvider({ close }) {
    const [fisica, setFisica] = useState(true);
    const [modal, setModal] = useState(false)

    const defaultForm = {
        isFisica: fisica,
        socialName: "",
        name: "",
        pName: "",
        mName: "",
        itin: "",
        telephone: ""
    }

    Yup.addMethod(Yup.string, "rfc", function (errorMessage) {
        return this.test('rfc_valid', errorMessage, function (value) {
            const { path, createError } = this;
            return (
                (value && validarRFC(value) && (fisica ? value.length === 13 : value.length === 12)) || createError({ path, message: errorMessage })
            )
        })
    })

    const FormSchema = Yup.object().shape({
        isFisica: Yup.boolean(),
        socialName: Yup.string()
            .when("isFisica", {
                is: !true,
                then: Yup.string().required("Ingrese una razón social")
            }),
        name: Yup.string()
            .when("isFisica", {
                is: true,
                then: Yup.string().required("Ingrese una nombre")
            }),
        pName: Yup.string()
            .when("isFisica", {
                is: true,
                then: Yup.string().required("Ingrese un apellido paterno")
            }),
        mName: Yup.string()
            .when("isFisica", {
                is: true,
                then: Yup.string().required("Ingrese un apellido materno")
            }),
        itin: Yup.string()
            .required("El campo RFC es obligatorio")
            .rfc("Ingrese un RFC valido"),
        telephone: Yup.string()
            .required("El campo RFC es obligatorio")
            .max(12, "Ingrese un numero de telefono valido")
            .min(8, "Ingrese un numero de telefono valido")
    })

    const { handleSubmit, values, handleBlur, errors, touched, setFieldValue } =
        useFormik({
            initialValues: defaultForm,
            onSubmit: () => {
                setModal(true)
            },
            validationSchema: FormSchema,
            enableReinitialize: true,
        });

    const onChange = (e) => {
        setFieldValue(e.target.name, e.target.value);
    };

    const crear = async (rows) => {
        const API = peticionesReceiver();
        const provider = { telephone: rows.telephone, itin: rows.itin }
        const validate = [["socialName"]]
        if (provider.itin !== "XEXX010101000" && provider.itin !== "XAXX010101000") {
            validate[0].push("itin")
        }
        if (rows.isFisica) {
            provider.socialName = `${rows.name} ${rows.pName} ${rows.mName}`;
            provider.keyTypeProvider = 1;
            validate[0].push("itin")
        } else {
            provider.socialName = rows.socialName
            provider.keyTypeProvider = 2;
        }
        const params = {
            action: "insert",
            table: "providers",
            rows: provider,
            validate
        };
        try {
            const res = await API.peticion(params)
            if (res.status === 200 && res.data.code === "200") {
                toast(<Notification type={"agrega_exito"} backMessage={res.data.message} withIcon />);
                close()
            } else {
                toast(<Notification type={"agrega_error"} backMessage={res.data.message} withIcon />);
            }
        } catch (err) {
            toast(<Notification type={"consultar_servidor_error"} withIcon />);
        };
    }

    return (<>
        <h6 className="text-center">
            <span>*</span>Tipo de persona
        </h6>
        <form onSubmit={handleSubmit} noValidate>
            <FormGroup className="mx-auto" style={{ width: "fit-content" }}>
                <FormGroup check style={{ width: "fit-content" }}>
                    <Input
                        onChange={() => { setFisica(true); values.isFisica = true }}
                        type="radio"
                        checked={fisica}
                    />
                    {' '}
                    <Label check>
                        Persona física
                    </Label>
                </FormGroup>
                <FormGroup check style={{ width: "fit-content" }}>
                    <Input
                        onChange={() => { setFisica(false); values.isFisica = false }}
                        type="radio"
                        checked={!fisica}
                    />
                    {' '}
                    <Label check>
                        Persona moral
                    </Label>
                </FormGroup>
            </FormGroup>
            {fisica ? (
                <>
                    <Row>
                        <Col xs={12} md={6}>
                            <TextInput
                                label="Nombre"
                                inputName="name"
                                inputType="text"
                                onChangeMethod={onChange}
                                onBlurMethod={handleBlur}
                                value={values.name}
                                isRequired
                                touched={touched.name}
                                errors={errors.name}
                            />
                        </Col>
                        <Col xs={12} md={6}>
                            <TextInput
                                label="Apellido paterno"
                                inputName="pName"
                                inputType="text"
                                onChangeMethod={onChange}
                                onBlurMethod={handleBlur}
                                value={values.pName}
                                isRequired
                                touched={touched.pName}
                                errors={errors.pName}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} md={6}>
                            <TextInput
                                label="Apellido materno"
                                inputName="mName"
                                inputType="text"
                                onChangeMethod={onChange}
                                onBlurMethod={handleBlur}
                                value={values.mName}
                                isRequired
                                touched={touched.mName}
                                errors={errors.mName}
                            />
                        </Col>
                        <Col xs={12} md={6}>
                            <TextInput
                                label="RFC"
                                inputName="itin"
                                inputType="text"
                                onChangeMethod={onChange}
                                onBlurMethod={handleBlur}
                                value={values.itin}
                                isRequired
                                touched={touched.itin}
                                errors={errors.itin}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} md={6} className="mx-auto">
                            <TextInput
                                label="Telefono"
                                inputName="telephone"
                                inputType="text"
                                onChangeMethod={onChange}
                                onBlurMethod={handleBlur}
                                value={values.telephone}
                                isRequired
                                touched={touched.telephone}
                                errors={errors.telephone}
                            />
                        </Col>
                    </Row>
                </>
            ) : (
                <>
                    <Row>
                        <Col xs={12} md={6}>
                            <TextInput
                                label="Razón social"
                                inputName="socialName"
                                inputType="text"
                                onChangeMethod={onChange}
                                onBlurMethod={handleBlur}
                                value={values.socialName}
                                isRequired
                                touched={touched.socialName}
                                errors={errors.socialName}
                            />
                        </Col>
                        <Col xs={12} md={6}>
                            <TextInput
                                label="RFC"
                                inputName="itin"
                                inputType="text"
                                onChangeMethod={onChange}
                                onBlurMethod={handleBlur}
                                value={values.itin}
                                isRequired
                                touched={touched.itin}
                                errors={errors.itin}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} md={6} className="mx-auto">
                            <TextInput
                                label="Telefono"
                                inputName="telephone"
                                inputType="text"
                                onChangeMethod={onChange}
                                onBlurMethod={handleBlur}
                                value={values.telephone}
                                isRequired
                                touched={touched.telephone}
                                errors={errors.telephone}
                            />
                        </Col>
                    </Row>
                </>
            )}
            <div className="text-center">
                <Button color="add" className="mx-md-2" type="submit">Guardar</Button>
                <Button color="danger" onClick={close} className="mx-md-2">Cerrar</Button>
            </div>
        </form>
        <ModalConfirmation
            modalTitle={"Crear proveedor"}
            modal={modal}
            setModal={setModal}
            crear={crear}
            values={values}
        >
            <div className="d-flex justify-content-center">
                <h6>¿Desea continuar con el registro?</h6>
            </div>
        </ModalConfirmation>
    </>)
}
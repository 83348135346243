import { useFormik } from "formik";
import * as Yup from "yup";
import React, { useState, useEffect } from "react";
import { Col, Row, Button, Collapse } from "reactstrap";
import axios from "axios";
import Loader from "../../../components/Loader/Loader";
import { validarRFC } from "../../../libs/personalData/RFC";
import { toast } from "react-toastify";
import { peticionesReceiver } from "../../../helpers/peticionesReceiver";
import Notification from "../../../components/Notification/Notification";
import ModalConfirmation from "../../../components/Modal/ModalConfirmation";
import { NumberInput } from "../../../components/GenericInputsFormik/NumberInput";
import { peticionPersonal } from "../../../helpers/peticionesPersonal";
import {
  getCiudades,
  getEstados,
  getMunicipios,
  getPaises,
} from "./functionsLocation";
import { peticionEncript } from "../../../helpers/peticionesEncripted";
import { SelectTypeHeadSingle } from "../../../components/GenericInputsFormik/SelectTypeHeadSingle";
import { getParamsDatosGenerales } from "./functions";
import { TextInput } from "../../../components/GenericInputsFormik";
import { getStaffPic } from "../../../libs/tools/images";

const PersonalDatosGenerales = ({
  idStaff,
  toggleTab,
  staff,
  setStaff,
  permissions,
  staffValid,
  setStaffValid,
  setActiveTab,
  setUpload,
  upload,
}) => {
  const API = peticionesReceiver();
  const [genders, setGenders] = useState([]);
  const [employTypes, setEmployTypes] = useState([]);
  const [bloods, setBloods] = useState([]);
  const [maritalStatus, setMaritalStatus] = useState([]);
  const [disabilities, setDisabilities] = useState([]);
  const [statusStaff, setStatus] = useState([]);
  const [selectsData, setSelectsData] = useState({
    paisesData: [],
    estadosData: [],
    municipiosData: [],
    ciudadesData: [],
  });
  const [loading, setLoading] = useState(true);
  const [finalData, setFinalData] = useState("");
  const [modal, setModal] = useState(false);
  const [actualDate, setActualDate] = useState("");
  const [staffData, setStaffData] = useState({
    idStaff: "",
    keyEmployType: "",
    keyGenre: "",
    keyMarital: "",
    keyBloodType: "",
    keyStat: "",
    keyDisability: "",
    keyCityBorn: "",
    staffNumber: "",
    name: "",
    pName: "",
    mName: "",
    capitalization: 1,
    unionized: 0,
    unionizedDate: "",
    startJobDate: "",
    bornDate: "",
    firedDate: "",
    alergic: 0,
    kindAlergy: "",
    rfc: "",
    curp: "",
    postalCodeTax: "",
    identificationNumber: "",
    jobMail: "",
    jobNumber: "",
    jobExtension: "",
    emergencyMail: "",
    emergencyNumber: "",
    emergencyName: "",
  });

  useEffect(() => {
    if (staffValid !== null && upload !== null && !upload.dGen) {
      const params = getParamsDatosGenerales(staffValid);
      setStaffData(params);
    }
  }, [staffValid, upload]);

  const getGender = async () => {
    const data = {
      action: "select",
      table: "genders",
      condition: {
        enabled: 1,
      },
      order: "nameGender ASC",
    };
    API.peticion(data)
      .then((res) => {
        if (res.status === 200 && res.data.code === "200") {
          setGenders(res.data.data);
        } else {
          toast(<Notification type={"consultar_error"} withIcon />);
        }
        /* setLoading(false); */
      })
      .catch((err) => {
        toast(<Notification type={"consultar_servidor_error"} withIcon />);
        /* setLoading(false); */
      });
  };

  const getMaritalTypes = async () => {
    const data = {
      action: "select",
      table: "marytaltypes",
      condition: {
        enabled: 1,
      },
      order: "nameTypeMarital ASC",
    };
    API.peticion(data)
      .then((res) => {
        if (res.status === 200 && res.data.code === "200") {
          setMaritalStatus(res.data.data);
        } else {
          toast(<Notification type={"consultar_error"} withIcon />);
        }
      })
      .catch((err) => {
        toast(<Notification type={"consultar_servidor_error"} withIcon />);
      });
  };

  const getDisability = async () => {
    const data = {
      action: "select",
      table: "disabilities",
      condition: {
        enabled: 1,
      },
      order: "nameDisability ASC",
    };
    API.peticion(data)
      .then((res) => {
        if (res.status === 200 && res.data.code === "200") {
          setDisabilities(res.data.data);
          if (idStaff === "") {
            setFieldValue("keyDisability", "34");
          }
        } else {
          toast(<Notification type={"consultar_error"} withIcon />);
        }
        /* setLoading(false); */
      })
      .catch((err) => {
        toast(<Notification type={"consultar_servidor_error"} withIcon />);
        /* setLoading(false); */
      });
  };

  const getEmployType = async () => {
    const data = {
      action: "select",
      table: "employtypes",
      condition: {
        enabled: 1,
      },
      order: "nameEmployType ASC",
    };
    API.peticion(data)
      .then((res) => {
        if (res.status === 200 && res.data.code === "200") {
          setEmployTypes(res.data.data);
        } else {
          toast(<Notification type={"consultar_error"} withIcon />);
        }
      })
      .catch((err) => {
        toast(<Notification type={"consultar_servidor_error"} withIcon />);
        /* setLoading(false); */
      });
  };

  const getStatus = async () => {
    const data = {
      action: "select",
      table: "status",
      condition: {
        enabled: 1,
        keyTypeStat: 1,
      },
      order: "nameStat ASC",
    };
    API.peticion(data)
      .then((res) => {
        if (res.status === 200 && res.data.code === "200") {
          setStatus(res.data.data);
          if (idStaff === "") {
            setFieldValue("keyStat", "1");
          }
        } else {
          toast(<Notification type={"consultar_error"} withIcon />);
        }
      })
      .catch((err) => {
        toast(<Notification type={"consultar_servidor_error"} withIcon />);
        /* setLoading(false); */
      });
  };

  const getBloodType = async () => {
    const data = {
      action: "select",
      table: "bloodtypes",
      condition: {
        enabled: 1,
      },
      order: "nameBloodType ASC",
    };
    API.peticion(data)
      .then((res) => {
        if (res.status === 200 && res.data.code === "200") {
          setBloods(res.data.data);
        } else {
          toast(<Notification type={"consultar_error"} withIcon />);
        }
      })
      .catch((err) => {
        toast(<Notification type={"consultar_servidor_error"} withIcon />);
        /* setLoading(false); */
      });
  };

  const getEmployData = async () => {
    const data = {
      action: "multiselect",
      table:
        "staff A left JOIN cities B ON A.keyCityBorn = B.keyCity left JOIN counties C ON B.keyCounty = C.keyCounty left JOIN states D ON C.keyState = D.keyState left JOIN countries E ON D.keyCountry = E.keyCountry",
      rows: "A.idStaff,A.keyEmployType,A.keyGenre,A.keyMarital,A.keyBloodType,A.keyStat,A.keyDisability,A.keyCityBorn,A.staffNumber,A.name,A.pName,A.mName,A.unionized,A.unionizedDate,A.startJobDate,A.bornDate,A.firedDate,A.alergic,A.kindAlergy,A.rfc,A.curp,A.postalCodeTax,A.identificationNumber,A.jobMail,A.jobNumber,A.jobExtension,A.capitalization,C.keyCounty,D.keyState,E.keyCountry",
      conditions: `idStaff = ${idStaff}`,
    };
    const finalData = peticionEncript(data);
    await axios
      .post(`${process.env.REACT_APP_API}receiver/receiver.php`, finalData)
      .then((res) => {
        setStaffData(res.data.data[0]);
        if (
          res.data.data[0].keyCountry !== "" &&
          res.data.data[0].keyCountry !== null
        ) {
          getEstados(res.data.data[0].keyCountry, setSelectsData).finally(
            () => {
              getMunicipios(res.data.data[0].keyState, setSelectsData).finally(
                () => {
                  getCiudades(
                    res.data.data[0].keyCounty,
                    setSelectsData
                  ).finally(() => {
                    // getEmergencyContact();
                  });
                }
              );
            }
          );
        }
      })
      .catch((err) => {
        toast(<Notification type={"consultar_servidor_error"} withIcon />);
      });
  };

  useEffect(() => {
    getGender();
    getMaritalTypes();
    getDisability();
    getEmployType();
    getBloodType();
    getStatus();
    // var valP = {};
    getPaises(setSelectsData).finally(() => {
      // valP=selectsData.paisesData.filter((el => el.keyCountry === "151"))
      if (idStaff) {
        getEmployData().finally(() => {
          setLoading(false);
        });
      } else {
        onChange({ target: { name: "keyCountry", value: "151" } });
        setLoading(false);
      }
    });
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = today.getFullYear() - 18;
    today = yyyy + "-" + mm + "-" + dd;
    setActualDate(today);
  }, []);

  Yup.addMethod(Yup.string, "rfc", function (errorMessage) {
    return this.test("rfc_valid", errorMessage, function (value) {
      const { path, createError } = this;
      return (
        (value && validarRFC(value)) ||
        createError({ path, message: errorMessage })
      );
    });
  });

  // async function getEmergencyContact() {
  //   const data = {
  //     action: "select",
  //     table: "staffcontacts",
  //     condition: {
  //       enabled: 1,
  //       idStaff: idStaff,
  //       keyContactType: 1,
  //     },
  //   };
  //   API.peticion(data)
  //     .then((res) => {
  //       if (res.status === 200 && res.data.code === "200") {
  //         setFieldValue("emergencyName", res.data.data[0].contactName);
  //         setFieldValue("emergencyNumber", res.data.data[0].phone);
  //         setFieldValue("emergencyExtension", res.data.data[0].phoneExtension);
  //         setFieldValue("emergencyMail", res.data.data[0].email);
  //       }
  //     })
  //     .catch((err) => {
  //       /* setLoading(false); */
  //     });
  // }

  const FormSchema = Yup.object().shape({
    name: Yup.string()
      .min(3, "El nombre debe contener al menos 3 caracteres")
      .max(200, "Nombre demasiado largo")
      .required("Ingrese un nombre"),
    pName: Yup.string()
      .min(3, "El apellido paterno debe contener al menos 3 caracteres")
      .max(200, "Apellido paterno demasiado largo")
      .required("Ingrese un apellido paterno"),
    rfc: Yup.string()
      .min(10, "El RFC debe contener al menos 10 caracteres")
      .max(13, "RFC demasiado largo")
      .required("Ingrese un RFC")
      .rfc("Ingrese un RFC valido"),
    curp: Yup.string()
      .min(18, "La CURP debe contener al menos 18 caracteres")
      .max(18, "CURP demasiado largo")
      .required("Ingrese una CURP"),
    postalCodeTax: Yup.string()
      .min(5, "El codigo postal debe contener al menos 5 caracteres")
      .max(6, "codigo postal demasiado largo")
      .required("Ingrese un codigo postal"),
    jobNumber: Yup.string()
      .min(10, "El número de teléfono debe contener al menos 10 digitos")
      .max(10, "El número de teléfono debe maximo 10 digitos"),
    jobExtension: Yup.string()
      .min(2, "Ingrese mas de dos digitos")
      .max(4, "Ingrese maximo 4 digitos"),
    identificationNumber: Yup.string()
      .min(3, "El número de identificacion debe contener al menos 3 caracteres")
      .max(20, "Número de identificacion demasiado largo")
      .required("Ingrese un número de identificacion"),
    /* jobMail: Yup.string().required("Ingrese un email"), */
    startJobDate: Yup.date().required("Ingrese una fecha de inicio de trabajo"),
    firedDate: Yup.date().min(
      Yup.ref("startJobDate"),
      "La fecha debe ser posterior a la fecha de inicio"
    ),
    bornDate: Yup.string().required("Ingrese una fecha de nacimiento"),
    keyEmployType: Yup.string().required("Ingrese una opción"),
    keyGenre: Yup.string().required("Ingrese una opción"),
    keyMarital: Yup.string().required("Ingrese una opción"),
    keyBloodType: Yup.string().required("Ingrese una opción"),
    keyStat: Yup.string().required("Ingrese una opción"),
    keyDisability: Yup.string().required("Ingrese una opción"),
    // keyCityBorn: Yup.string().required("Ingrese una opción"),
    // keyCountry: Yup.string().required("Ingrese una opción"),
    // keyState: Yup.string().required("Ingrese una opción"),
    // keyCounty: Yup.string().required("Ingrese una opción"),
    emergencyName: Yup.string().when("act", {
      is: (val) => {
        return idStaff !== null && idStaff !== undefined && idStaff !== ""
          ? true
          : false;
      },
      otherwise: Yup.string().required("Ingrese un nombre"),
    }),
    emergencyMail: Yup.string().when("act", {
      is: (val) => {
        return idStaff !== null && idStaff !== undefined && idStaff !== ""
          ? true
          : false;
      },
      otherwise: Yup.string()
        .required("Ingrese un email")
        .email("Ingrese un correo electronico valido"),
    }),
    emergencyNumber: Yup.string().when("act", {
      is: (val) => {
        return idStaff !== null && idStaff !== undefined && idStaff !== ""
          ? true
          : false;
      },
      otherwise: Yup.string()
        .required("Ingrese un número de emergencia")
        .min(10, "El número de teléfono debe contener al menos 10 digitos")
        .max(10, "El número de teléfono debe maximo 10 digitos"),
    }),
  });

  const onChange = (e) => {
    // if (e.target.name !== undefined && e.target.name !== "" && e.target.name !== null) {
    if (e.target.name === "keyCountry" && e.target.value) {
      setFieldValue("keyState", "");
      setFieldValue("keyCounty", "");
      setFieldValue("keyCityBorn", "");
      getEstados(e.target.value, setSelectsData);
    } else if (e.target.name === "keyState") {
      setFieldValue("keyCounty", "");
      setFieldValue("keyCityBorn", "");
      getMunicipios(e.target.value, setSelectsData);
    } else if (e.target.name === "keyCounty") {
      setFieldValue("keyCityBorn", "");
      getCiudades(e.target.value, setSelectsData);
    } else if (e.target.name === "curp") {
      calcularEdad(e.target.value);
    }

    if (e.target.name === "alergic") {
      setFieldValue("alergic", values.alergic === 0 ? 1 : 0);
      if (values.alergic === 1 || values.alergic === "1") {
        //Lo vamos a cambiar a 0 entonces devuelvo el descripcion a limpio
        setFieldValue("kindAlergy", "");
      }
    } else if (e.target.name === "unionized") {
      setFieldValue("unionized", values.unionized === 0 ? 1 : 0);
      if (values.unionized === 1 || values.unionized === "1") {
        //Lo vamos a cambiar a 0 entonces devuelvo el descripcion a limpio
        setFieldValue("unionizedDate", "");
      }
    } else if (e.target.name === "capitalization") {
      setFieldValue("capitalization", values.capitalization === 0 ? 1 : 0);
    } else {
      setFieldValue([e.target.name], e.target.value);
    }
    // }
  };

  const calcularEdad = (curplow) => {
    if (curplow.length === 18 && +values.keyCountry === 151) {
      const curp = curplow.toUpperCase();
      const reg =
        /[A-Z]{1}[AEIOU]{1}[A-Z]{2}[0-9]{2}(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1])[HM]{1}(AS|BC|BS|CC|CS|CH|CL|CM|DF|DG|GT|GR|HG|JC|MC|MN|MS|NT|NL|OC|PL|QT|QR|SP|SL|SR|TC|TS|TL|VZ|YN|ZS|NE)[B-DF-HJ-NP-TV-Z]{3}[0-9A-Z]{1}[0-9]{1}/g;
      if (reg.test(curp)) {
        const fecha2d = curp.substring(4, 10);
        const currentYear = new Date().getFullYear().toString().substring(2);
        let yearNac = fecha2d.slice(0, 2);
        yearNac =
          Number(yearNac) >= Number(currentYear)? "19" + yearNac: "20" + yearNac;
        const dateNac =
          yearNac + "-" + fecha2d.slice(2, 4) + "-" + fecha2d.slice(4);
        setFieldValue("bornDate", dateNac);
        let sexo = curp.charAt(10);
        sexo = sexo === "H" ? "1" : "2";
        setFieldValue("keyGenre", sexo);
        let estado = curp.substring(11, 13);
        estado = selectsData.estadosData.find((el) => el.shortening === estado);
        if (estado) {
          onChange({
            target: { name: "keyState", value: estado.keyState.toString() },
          });
        }
      }
    }
  };

  const enviaDatos = () => {
    setFinalData((prevState) => ({
      ...prevState,
      idStaff: values.idStaff,
      keyEmployType: values.keyEmployType,
      keyGenre: values.keyGenre,
      keyMarital: values.keyMarital,
      keyBloodType: values.keyBloodType,
      keyStat: values.keyStat,
      keyDisability: values.keyDisability,
      keyCityBorn: values.keyCityBorn,
      capitalization: values.capitalization,
      name: values.name,
      pName: values.pName,
      mName: values.mName,
      unionized: values.unionized,
      unionizedDate: values.unionizedDate,
      startJobDate: values.startJobDate,
      bornDate: values.bornDate,
      firedDate: values.firedDate,
      alergic: values.alergic,
      kindAlergy: values.kindAlergy,
      rfc: values.rfc,
      curp: values.curp,
      postalCodeTax: values.postalCodeTax,
      identificationNumber: values.identificationNumber,
      jobMail: values.jobMail,
      jobNumber: values.jobNumber,
      jobExtension: values.jobExtension,
    }));
    setModal(true);
  };

  const sendFormInformation = async () => {
    setLoading(true);
    let actionAPI = "";
    if (finalData.idStaff !== "") {
      //Vamos a editar
      actionAPI = "updateEmployeed";
    } else {
      actionAPI = "insertEmployeed";
    }
    const data = {
      action: actionAPI,
      rows: finalData,
    };
    const res = await peticionPersonal(data);
    if (res.status === 200 && res.data.data !== "") {
      toast(
        <Notification
          type={"agrega_exito"}
          backMessage={res.data.message}
          withIcon
        />,
        { closeButton: false }
      );

      if (!idStaff) await insertContact(res.data.data[0]);
      resetStaff(res.data.data[0], finalData.idStaff !== "");
      if (staffValid !== null) {
        const uppload = JSON.parse(JSON.stringify(upload));
        uppload.dGen = true;
        setUpload(uppload);
        setTimeout(() => {
          setActiveTab(3);
        }, 2000);
      }
    } else {
      toast(
        <Notification
          type={"consultar_error"}
          backMessage={res.data.message}
          withIcon
        />,
        { closeButton: false }
      );
    }
    setLoading(false);
  };

  async function insertContact(staff) {
    const data = {
      action: "saveStaffContacts",
      table: "staffcontacts",
      rows: {
        keyContactType: 1,
        idStaff: staff.idStaff,
        phone: values.emergencyNumber,
        phoneExtension: values.emergencyExtension,
        email: values.emergencyMail,
        contactName: values.emergencyName
      },
    };
    const res = await peticionPersonal(data);
    if (res.status === 200 && res.data.status === "success") {
      toast(
        <Notification
          type={"agrega_exito"}
          backMessage={res.data.message}
          withIcon
        />
      );
    } else {
      setFieldValue("emergencyName", "");
      setFieldValue("emergencyNumber", "");
      setFieldValue("emergencyExtension", "");
      setFieldValue("emergencyMail", "");
    }
  }

  const resetStaff = (info, isedit) => {
    if (isedit) {
      setStaff({
        ...staff,
        fullName: info.name + " " + info.pName + " " + info.mName,
        employType: info.nameEmployType,
      });
    } else {
      setStaff({
        idStaff: info.idStaff,
        staffNumber: info.staffNumber,
        fullName: info.name + " " + info.pName + " " + info.mName,
        employType: info.nameEmployType,
        nameStat: "",
        nameArea: "",
        nameJob: "",
        picture: getStaffPic(info.keyGenre)
      });
    }
    // getEmployData();
    //toggleTab(1);
  };

  const { handleSubmit, values, handleBlur, errors, touched, setFieldValue } =
    useFormik({
      initialValues: staffData,
      onSubmit: (values) => {
        enviaDatos();
      },
      validationSchema: FormSchema,
      enableReinitialize: true,
    });

  return (
    <div>
      {!loading ? (
        <form className="mt-4" onSubmit={handleSubmit}>
          <ModalConfirmation
            modalTitle={idStaff ? "Editar" : "Crear"}
            modal={modal}
            setModal={setModal}
            crear={sendFormInformation}
            editar={sendFormInformation}
            isEdit={idStaff ? true : false}
            values={finalData}
          >
            {idStaff ? (
              <div className="d-flex justify-content-center">
                <h6>¿Está seguro de editar el registro?</h6>
              </div>
            ) : (
              <div className="d-flex justify-content-center">
                <h6>¿Desea continuar con el registro?</h6>
              </div>
            )}
          </ModalConfirmation>
          <Row>
            <p className="mb-4 body-3 muted ml-2">Datos Generales</p>
          </Row>
          <Row className="mt-2">
            <Col xs={12} md={12} lg={4}>
              <div className="form-group">
                <label htmlFor="name">
                  Nombre<span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  onChange={onChange}
                  onBlur={handleBlur}
                  className={
                    "form-control" +
                    (errors.name && touched.name ? " is-invalid" : "")
                  }
                  value={values.name}
                />
                {touched.name && errors.name && (
                  <span style={{ color: "red" }}>{errors.name}</span>
                )}
              </div>
            </Col>
            <Col xs={12} md={12} lg={4}>
              <div className="form-group">
                <label htmlFor="pName">
                  Apellido paterno<span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  id="pName"
                  name="pName"
                  onChange={onChange}
                  onBlur={handleBlur}
                  className={
                    "form-control" +
                    (errors.pName && touched.pName ? " is-invalid" : "")
                  }
                  value={values.pName}
                />
                {touched.nameBuilding && errors.nameBuilding && (
                  <span style={{ color: "red" }}>{errors.nameBuilding}</span>
                )}
              </div>
            </Col>
            <Col xs={12} md={12} lg={4}>
              <div className="form-group">
                <label htmlFor="mName">Apellido Materno</label>
                <input
                  type="text"
                  id="mName"
                  name="mName"
                  onChange={onChange}
                  value={values.mName}
                  className={"form-control"}
                />
              </div>
            </Col>
          </Row>
          <Row className="mt-2">
            <Col xs={12} md={12} lg={4}>
              <div className="form-group">
                <label htmlFor="rfc">
                  RFC<span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  id="rfc"
                  name="rfc"
                  onChange={onChange}
                  onBlur={handleBlur}
                  value={values.rfc}
                  className={
                    "form-control" +
                    (errors.rfc && touched.rfc ? " is-invalid" : "")
                  }
                />
                {touched.rfc && errors.rfc && (
                  <span style={{ color: "red" }}>{errors.rfc}</span>
                )}
              </div>
            </Col>
            <Col xs={12} md={12} lg={4}>
              <div className="form-group">
                <label htmlFor="curp">
                  CURP<span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  id="curp"
                  name="curp"
                  onChange={onChange}
                  onBlur={handleBlur}
                  value={values.curp}
                  className={
                    "form-control" +
                    (errors.curp && touched.curp ? " is-invalid" : "")
                  }
                  style={{ textTransform: "uppercase" }}
                />
                {touched.curp && errors.curp && (
                  <span style={{ color: "red" }}>{errors.curp}</span>
                )}
              </div>
            </Col>
            <Col xs={12} md={12} lg={4}>
              <div className="form-group">
                <label htmlFor="bornDate">
                  Fecha de nacimiento<span className="text-danger">*</span>
                </label>
                <input
                  type="date"
                  id="bornDate"
                  name="bornDate"
                  onChange={onChange}
                  onBlur={handleBlur}
                  value={values.bornDate}
                  max={actualDate}
                  className={
                    "form-control" +
                    (errors.bornDate && touched.bornDate ? " is-invalid" : "")
                  }
                />
                {touched.bornDate && errors.bornDate && (
                  <span style={{ color: "red" }}>{errors.bornDate}</span>
                )}
              </div>
            </Col>
          </Row>
          <Row className="mt-2">
            <Col xs={12} md={12} lg={4}>
              <div className="form-group">
                <label htmlFor="identificationNumber">
                  Numero de identificación<span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  id="identificationNumber"
                  name="identificationNumber"
                  onChange={onChange}
                  onBlur={handleBlur}
                  value={values.identificationNumber}
                  className={
                    "form-control" +
                    (errors.identificationNumber && touched.identificationNumber
                      ? " is-invalid"
                      : "")
                  }
                />
                {touched.identificationNumber &&
                  errors.identificationNumber && (
                    <span style={{ color: "red" }}>
                      {errors.identificationNumber}
                    </span>
                  )}
              </div>
            </Col>
            <Col xs={12} md={12} lg={4}>
              {/* <NumberInput
                label="Código Postal"
                inputName="postalCodeTax"
                onChangeMethod={onChange}
                onBlurMethod={handleBlur}
                value={values.postalCodeTax}
                touched={touched.postalCodeTax}
                errors={errors.postalCodeTax}
                isRequired
                maxLength={5}
                disableSep
              /> */}
              <TextInput
                label="Código Postal"
                inputName="postalCodeTax"
                onChangeMethod={onChange}
                onBlurMethod={handleBlur}
                value={values.postalCodeTax}
                touched={touched.postalCodeTax}
                errors={errors.postalCodeTax}
                isRequired
              />
            </Col>
            <Col>
              <SelectTypeHeadSingle
                label="Género"
                isRequired={true}
                inputName="keyGenre"
                optionsArray={genders}
                defaultOption="Selecciona un género"
                onChangeMethod={onChange}
                onBlur={handleBlur}
                isDisabled={false}
                value={values.keyGenre}
                touched={touched.keyGenre}
                errors={errors.keyGenre}
                optionValue="keyGender"
                optionName="nameGender"
              />
            </Col>
          </Row>
          <Row className="mt-2">
            <Col xs="12" lg="4">
              <SelectTypeHeadSingle
                label="Tipo de Empleado"
                isRequired={true}
                inputName="keyEmployType"
                optionsArray={employTypes}
                defaultOption="Selecciona un tipo de Empleado"
                onChangeMethod={onChange}
                onBlur={handleBlur}
                isDisabled={false}
                value={values.keyEmployType}
                touched={touched.keyEmployType}
                errors={errors.keyEmployType}
                optionValue="keyEmployType"
                optionName="nameEmployType"
              />
            </Col>
            <Col xs="12" lg="4">
              <SelectTypeHeadSingle
                label="Estado civil"
                isRequired={true}
                inputName="keyMarital"
                optionsArray={maritalStatus}
                defaultOption="Selecciona un estado civil"
                onChangeMethod={onChange}
                onBlur={handleBlur}
                isDisabled={false}
                value={values.keyMarital}
                touched={touched.keyMarital}
                errors={errors.keyMarital}
                optionValue="keyTypeMarital"
                optionName="nameTypeMarital"
              />
            </Col>
            <Col xs="12" lg="4">
              <SelectTypeHeadSingle
                label="Tipo de sangre"
                isRequired={true}
                inputName="keyBloodType"
                optionsArray={bloods}
                defaultOption="Selecciona un tipo de sangre"
                onChangeMethod={onChange}
                onBlur={handleBlur}
                isDisabled={false}
                value={values.keyBloodType}
                touched={touched.keyBloodType}
                errors={errors.keyBloodType}
                optionValue="keyBloodType"
                optionName="nameBloodType"
              />
            </Col>
          </Row>
          <Row className="mt-2">
            <Col xs="12" lg="4">
              <SelectTypeHeadSingle
                label="Tipo de discapacidad"
                isRequired={true}
                inputName="keyDisability"
                optionsArray={disabilities}
                defaultOption="Selecciona un tipo de discapacidad"
                onChangeMethod={onChange}
                onBlur={handleBlur}
                isDisabled={false}
                value={values.keyDisability}
                touched={touched.keyDisability}
                errors={errors.keyDisability}
                optionValue="keyDisability"
                optionName="nameDisability"
              />
            </Col>
            <Col xs="12" lg="4">
              <SelectTypeHeadSingle
                label="Estatus del empleado"
                isRequired={true}
                inputName="keyStat"
                optionsArray={statusStaff}
                defaultOption="Selecciona un tipo de estatus"
                onChangeMethod={onChange}
                onBlur={handleBlur}
                isDisabled={true}
                value={values.keyStat}
                touched={touched.keyStat}
                errors={errors.keyStat}
                optionValue="keyStat"
                optionName="nameStat"
              />
            </Col>
            <Col xs="12" lg="4">
              <SelectTypeHeadSingle
                label="País de nacimiento"
                inputName="keyCountry"
                optionsArray={selectsData.paisesData}
                defaultOption="Selecciona un país de nacimiento"
                onChangeMethod={onChange}
                onBlur={handleBlur}
                isDisabled={false}
                value={values.keyCountry}
                touched={touched.keyCountry}
                errors={errors.keyCountry}
                optionValue="keyCountry"
                optionName="nameCountry"
              />
            </Col>
          </Row>
          <Row className="mt-2">
            <Col xs="12" lg="4">
              <SelectTypeHeadSingle
                label="Estado de nacimiento"
                inputName="keyState"
                optionsArray={selectsData.estadosData}
                defaultOption="Selecciona estado de nacimiento"
                onChangeMethod={onChange}
                onBlur={handleBlur}
                isDisabled={false}
                value={values.keyState}
                touched={touched.keyState}
                errors={errors.keyState}
                optionValue="keyState"
                optionName="nameState"
              />
            </Col>
            <Col xs="12" lg="4">
              <SelectTypeHeadSingle
                label="Municipio de nacimiento"
                inputName="keyCounty"
                optionsArray={selectsData.municipiosData}
                defaultOption="Selecciona municipio de nacimiento"
                onChangeMethod={onChange}
                onBlur={handleBlur}
                isDisabled={false}
                value={values.keyCounty}
                touched={touched.keyCounty}
                errors={errors.keyCounty}
                optionValue="keyCounty"
                optionName="nameCounty"
              />
            </Col>
            <Col xs="12" lg="4">
              <SelectTypeHeadSingle
                label="Ciudad de nacimiento"
                inputName="keyCityBorn"
                optionsArray={selectsData.ciudadesData}
                defaultOption="Selecciona estado de nacimiento"
                onChangeMethod={onChange}
                onBlur={handleBlur}
                isDisabled={false}
                value={values.keyCityBorn}
                touched={touched.keyCityBorn}
                errors={errors.keyCityBorn}
                optionValue="keyCity"
                optionName="nameCity"
              />
            </Col>
          </Row>
          <Row className="mt-2">
            <Col xs={12} md={12} lg={4}>
              <Row className="justify-content-center">
                <div className="form-group">
                  <label htmlFor="alergic">Alergias</label>
                  <input
                    type="checkbox"
                    id="alergic"
                    name="alergic"
                    className="form-control"
                    onChange={onChange}
                    value={values.alergic}
                    checked={
                      values.alergic === "1" || values.alergic === 1
                        ? true
                        : false
                    }
                  />
                </div>
              </Row>
            </Col>
            <Col xs={12} md={12} lg={8}>
              <div className="form-group">
                <label htmlFor="kindAlergy">Descripción de las alergias</label>
                <input
                  type="text"
                  id="kindAlergy"
                  name="kindAlergy"
                  className={"form-control"}
                  onChange={onChange}
                  value={values.kindAlergy}
                  disabled={
                    values.alergic === 0 || values.alergic === "0"
                      ? true
                      : false
                  }
                  required={
                    values.alergic === 1 || values.alergic === "1"
                      ? true
                      : false
                  }
                />
              </div>
            </Col>
          </Row>

          <Collapse isOpen={idStaff ? false : true}>
            <Row className="mt-4">
              <p className="mb-4 body-3 muted ml-2">Contacto de emergencia</p>
            </Row>

            <Row>
              <Col>
                <div className="form-group">
                  <label htmlFor="emergencyName">
                    Nombre de contacto de emergencia{" "}
                    <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    id="emergencyName"
                    name="emergencyName"
                    onChange={onChange}
                    onBlur={handleBlur}
                    value={values.emergencyName}
                    className={
                      "form-control" +
                      (errors.emergencyName && touched.emergencyName
                        ? " is-invalid"
                        : "")
                    }
                  />
                  {touched.emergencyName && errors.emergencyName && (
                    <span style={{ color: "red" }}>{errors.emergencyName}</span>
                  )}
                </div>
              </Col>
            </Row>

            <Row>
              <Col xs={12} md={12} lg={4}>
                <div className="form-group">
                  <label htmlFor="emergencyMail">
                    Correo de emergencia <span className="text-danger">*</span>
                  </label>
                  <input
                    type="email"
                    id="emergencyMail"
                    name="emergencyMail"
                    onChange={onChange}
                    onBlur={handleBlur}
                    value={values.emergencyMail}
                    className={
                      "form-control" +
                      (errors.emergencyMail && touched.emergencyMail
                        ? " is-invalid"
                        : "")
                    }
                  />

                  {touched.emergencyMail && errors.emergencyMail && (
                    <span style={{ color: "red" }}>{errors.emergencyMail}</span>
                  )}
                </div>
              </Col>
              <Col xs={12} md={12} lg={4}>
                <NumberInput
                  label="Teléfono"
                  inputName="emergencyNumber"
                  onChangeMethod={onChange}
                  onBlurMethod={handleBlur}
                  value={values.emergencyNumber}
                  touched={touched.emergencyNumber}
                  errors={errors.emergencyNumber}
                  isRequired
                  maxLength={10}
                  disableSep
                />
              </Col>
              <Col xs={12} md={12} lg={4}>
                <NumberInput
                  label="Extension de teléfono"
                  inputName="emergencyExtension"
                  onChangeMethod={onChange}
                  onBlurMethod={handleBlur}
                  value={values.emergencyExtension}
                  maxLength={5}
                  disableSep
                />
              </Col>
            </Row>
          </Collapse>

          <Row className="mt-4">
            <p className="mb-4 body-3 muted ml-2">Datos de empleado</p>
          </Row>
          <Row>
            <Col xs={12} md={12} lg={4}>
              <div className="form-group">
                <label htmlFor="jobMail">Correo institucional</label>
                <input
                  type="email"
                  id="jobMail"
                  name="jobMail"
                  onChange={onChange}
                  onBlur={handleBlur}
                  value={values.jobMail}
                  className={
                    "form-control" +
                    (errors.jobMail && touched.jobMail ? " is-invalid" : "")
                  }
                />

                {touched.jobMail && errors.jobMail && (
                  <span style={{ color: "red" }}>{errors.jobMail}</span>
                )}
              </div>
            </Col>
            <Col xs={12} md={12} lg={4}>
              <NumberInput
                label="Teléfono"
                inputName="jobNumber"
                onChangeMethod={onChange}
                onBlurMethod={handleBlur}
                value={values.jobNumber}
                touched={touched.jobNumber}
                errors={errors.jobNumber}
                maxLength={10}
                disableSep
              />
            </Col>
            <Col xs={12} md={12} lg={4}>
              <NumberInput
                label="Extension de teléfono"
                inputName="jobExtension"
                onChangeMethod={onChange}
                onBlurMethod={handleBlur}
                value={values.jobExtension}
                touched={touched.jobExtension}
                errors={errors.jobExtension}
                maxLength={5}
                disableSep
              />
            </Col>
          </Row>

          <Row className="mt-2 d-flex justify-content-around">
            <Col xs={12} md={12} lg={4}>
              <Row className="justify-content-center">
                <div className="form-group">
                  <label htmlFor="alergic">Sistema de Capitalización</label>
                  <input
                    type="checkbox"
                    id="capitalization"
                    name="capitalization"
                    onChange={onChange}
                    value={values.capitalization}
                    className={"form-control"}
                    checked={
                      values.capitalization === "1" ||
                      values.capitalization === 1
                        ? true
                        : false
                    }
                  />
                </div>
              </Row>
            </Col>
            <Col xs={12} md={12} lg={4}>
              <Row className="justify-content-center">
                <div className="form-group">
                  <label htmlFor="alergic">Empleado sindicalizado</label>
                  <input
                    type="checkbox"
                    id="unionized"
                    name="unionized"
                    onChange={onChange}
                    value={values.unionized}
                    className={"form-control"}
                    checked={
                      values.unionized === "1" || values.unionized === 1
                        ? true
                        : false
                    }
                  />
                </div>
              </Row>
            </Col>
            <Col xs={12} md={12} lg={4}>
              <div className="form-group">
                <label htmlFor="unionizedDate">Fecha de sindicalizacion</label>
                <input
                  type="date"
                  id="unionizedDate"
                  name="unionizedDate"
                  onChange={onChange}
                  value={values.unionizedDate}
                  className={"form-control"}
                  disabled={
                    values.unionized === 0 || values.unionized === "0"
                      ? true
                      : false
                  }
                  required={
                    values.unionized === 1 || values.unionized === "1"
                      ? true
                      : false
                  }
                />
              </div>
            </Col>
          </Row>
          <Row className="mt-2 d-flex justify-content-around">
            <Col xs={12} md={12} lg={4}>
              <div className="form-group">
                <label htmlFor="startJobDate">
                  Fecha de ingreso al servicio
                  <span className="text-danger">*</span>
                </label>
                <input
                  type="date"
                  id="startJobDate"
                  name="startJobDate"
                  onChange={onChange}
                  onBlur={handleBlur}
                  value={values.startJobDate}
                  className={
                    "form-control" +
                    (errors.startJobDate && touched.startJobDate
                      ? " is-invalid"
                      : "")
                  }
                />
                {touched.startJobDate && errors.startJobDate && (
                  <span style={{ color: "red" }}>{errors.startJobDate}</span>
                )}
              </div>
            </Col>
            <Col xs={12} md={12} lg={4}>
              <div className="form-group">
                <label htmlFor="firedDate">
                  Fecha de termino de contrato
                </label>
                <input
                  type="date"
                    id="firedDate"
                    name="firedDate"
                    onBlur={handleBlur}
                    value={values.firedDate}
                    onChange={onChange}
                    className={
                      "form-control" +
                      (errors.firedDate && touched.firedDate
                        ? " is-invalid"
                        : "")
                    }
                  min={values.startJobDate}
                />
                {touched.firedDate && errors.firedDate && (
                  <span style={{ color: "red" }}>{errors.firedDate}</span>
                )}
              </div>
            </Col>
          </Row>
          <div className="d-flex justify-content-between mt-5">
            {!idStaff && (
              <Button
                style={{ backgroundColor: "#cc0a44", border: "none" }}
                onClick={() => {
                  if (staffValid !== null) {
                    setStaffValid(null);
                  }
                  toggleTab(1);
                }}
              >
                Cancelar
              </Button>
            )}
            {(idStaff !== "" ? permissions.UPD : permissions.INS) && (
              <Button color="success" type="submit">
                Guardar
              </Button>
            )}
          </div>
        </form>
      ) : (
        <Loader />
      )}
    </div>
  );
};
export default PersonalDatosGenerales;
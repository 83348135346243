import React, { useState } from "react";
import TableComponentFiltro from "../../../components/TableComponent/TableComponentFiltro";

export const FuentesFinanciamiento = () => {
  const cabeceras = [
    "#",
    "Clave",
    "Rubro",
    "Tipo",
    "Fuente de financiamiento",
    "Denominacion",
    "Editar",
    "Eliminar",
  ];
  const [parametros] = useState({
    action: "datatable",
    table: `incomes AS INC
    INNER JOIN income_subtypes AS INS
    ON INS.keyIncomeSubType = INC.keyIncomeSubType
    INNER JOIN income_types AS INCT
    ON INCT.keyIncomeType = INS.keyIncomeType
    INNER JOIN financialsources AS FNS
    ON FNS.idFinancialSource = INC.idFinancialSources`,
    rows: "INC.idIncome,INC.incomeKey,INCT.nameIncomeType,INS.nameIncombeSubType,FNS.nameFinancialSource,INC.nameIncome", ///sin espacios y comas
    conditions: "INC.enabled = 1",
    page: 0,
    records: 5,
    search: "",
    order: "",
  });

  return (
    <div>
      <TableComponentFiltro
        titulo="Rubros de ingresos"
        cabecerasTabla={cabeceras}
        filtro={null}
        rutaCrear="/template/rubros/crear"
        rutaEditar="/template/rubros/editar"
        parametros={parametros}
      />
    </div>
  );
};
export default FuentesFinanciamiento;

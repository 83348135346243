import FormInmueble from "./Tabs"
import { toast } from "react-toastify";
import Notification from "../../../../components/Notification/Notification";
import { useHistory } from "react-router";

export default function EditarInmueble() {
  const history = useHistory();

  function getData() {
    if (localStorage.getItem("propertyData") != null) {
      return JSON.parse(localStorage.getItem("propertyData"));
    } else {
      setTimeout(() => {
        toast(<Notification type={"consultar_error"} withIcon />);
        history.push("/template/bienesinmuebles")
      }, 100)
    }
  }

  return (
    <>
      <FormInmueble data={getData()} />
    </>
  )
}
import { useEffect } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  SelectTypeHeadSingle,
  TextAreaInput,
} from "../../../../components/GenericInputsFormik";
import {
  Button,
  Col,
  CustomInput,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import { useState } from "react";
import { NumberInput } from "../../../../components/GenericInputsFormik/NumberInput";
import { toast } from "react-toastify";
import Notification from "../../../../components/Notification/Notification";
import { peticionesReceiver } from "../../../../helpers/peticionesReceiver";
import MesesSwitch from "./components/MesesSwitch";
import ModalConfirmation from "../../../../components/Modal/ModalConfirmation";

const defaultForm = {
  totalAmount: "",
  idExpenseObject: "",
  keyChapter: "",
  description: "",
  allStateFund: false,
  allMonths: false,
  month1: false,
  month2: false,
  month3: false,
  month4: false,
  month5: false,
  month6: false,
  month7: false,
  month8: false,
  month9: false,
  month10: false,
  month11: false,
  month12: false,
};

const FormSchema = Yup.object().shape({
  keyChapter: Yup.string().required("Seleccione un capítulo"),
  idExpenseObject: Yup.string().required("Seleccione una partida"),
  totalAmount: Yup.string().required("Ingrese una cantidad total"),
  description: Yup.string().required("Ingrese una descipción"),
});

export default function FormRegistroMultiple(props) {
  const { chapters, idBudgetRequest, setRefresh, mainReset } = props;
  const API = peticionesReceiver();
  const [detalle, setDetalle] = useState(true);
  const [stateFunds, setStateFunds] = useState([]);
  const [areasChapter, setAreasChapter] = useState([]);
  const [modal, setModal] = useState(false);
  const [modalConfirmation, setModalConfirmation] = useState(false);
  const [checksStateFunds, setChecksStateFunds] = useState([]);
  const [expensesObjects, setExpenseObjects] = useState([]);
  const [loaderStateFund, setLoaderStateFund] = useState(true);
  const [loaderChapters, setLoaderChapters] = useState(true);

  const toggle = () => {
    setModal(!modal);
  };

  useEffect(() => {
    getStateFund();
    getBudgetAreaChapter();
  }, []);

  async function getPartidas() {
    const params = {
      action: "multiselect",
      table:
        "expenses_chapters A INNER JOIN expenses_concepts B USING (keyChapter) INNER JOIN expenses_items C USING (keyConcept) INNER JOIN expense_objects D USING (keyItem)",
      rows: "D.idExpenseObject, CONCAT(A.chapterKey,B.conceptKey,C.itemKey,D.objectKey, ' - ', D.nameObject) as label",
      conditions: `A.enabled = 1 AND B.enabled = 1 AND C.enabled = 1 AND D.enabled = 1 AND A.keyChapter = ${values.keyChapter}`,
    };
    await API.peticion(params)
      .then((res) => {
        if (res.status === 200 && res.data.code === "200") {
          setExpenseObjects(res.data.data);
        } else {
          toast(
            <Notification
              type={"consultar_error"}
              backMessage={res.data.message}
              withIcon
            />
          );
        }
      })
      .catch((err) => {
        toast(<Notification type={"consultar_servidor_error"} withIcon />);
      });
  }

  const getStateFund = async () => {
    const params = {
      action: "multiselect",
      table: "statefunds",
      rows: "idStateFund,CONCAT(stateFundKey,' - ',nameStateFund ) as keyName",
      conditions: `enabled = 1 `,
      /* conditions: `enabled = 1 and idProyect =${proyect.id}` */
    };
    await API.peticion(params)
      .then((res) => {
        if (res.status === 200 && res.data.code === "200") {
          setLoaderStateFund(false);
          setChecksStateFunds(new Array(res.data.data.length).fill(false));
          setStateFunds(res.data.data);
        } else {
          toast(
            <Notification
              type={"consultar_error"}
              backMessage={res.data.message}
              withIcon
            />
          );
        }
      })
      .catch((err) => {
        toast(<Notification type={"consultar_servidor_error"} withIcon />);
      });
  };

  const getBudgetAreaChapter = async () => {
    const params = {
      action: "multiselect",
      table:
        "expenses_chapters A INNER JOIN budgetareachapters B on (A.keyChapter = B.keychapter) INNER JOIN budgetareas C on (B.idBudgetArea = C.idBudgetArea) INNER JOIN areas D on (C.idArea = D.idArea)",
      rows: "A.keyChapter,A.nameChapter",
      conditions: `A.enabled = 1 `,
    };
    await API.peticion(params)
      .then((res) => {
        if (res.status === 200 && res.data.code === "200") {
          setLoaderChapters(false);
          setAreasChapter(res.data.data);
        } else {
          toast(
            <Notification
              type={"consultar_error"}
              backMessage={res.data.message}
              withIcon
            />
          );
        }
      })
      .catch((err) => {
        toast(<Notification type={"consultar_servidor_error"} withIcon />);
      });
  };

  const {
    handleSubmit,
    values,
    handleBlur,
    errors,
    touched,
    setFieldValue,
    handleReset,
    resetForm,
  } = useFormik({
    initialValues: defaultForm,
    validationSchema: FormSchema,
    onSubmit: () => {
      if(validateSwitchs()){
        setModalConfirmation(true);
      }
    },
    onReset: () => {},
    enableReinitialize: true,
  });

  const clearAllForm = () => {
    resetForm();
    resetSwitchs();
  };

  const resetSwitchs = () => {
    setFieldValue("allMonths", false);
    setFieldValue("month1", false);
    setFieldValue("month2", false);
    setFieldValue("month3", false);
    setFieldValue("month4", false);
    setFieldValue("month5", false);
    setFieldValue("month6", false);
    setFieldValue("month7", false);
    setFieldValue("month8", false);
    setFieldValue("month9", false);
    setFieldValue("month10", false);
    setFieldValue("month11", false);
    setFieldValue("month12", false);
    onChangeAllSwitchStateFunds(false)
  };


  


  const onChange = ({ target }) => {
    if (target.name === "keyChapter") {
      setFieldValue("idExpenseObject", "");
    }
    setFieldValue(target.name, target.value);
  };
  const onChangeSwitchMonths = ({ target }) => {
    if (target.name === "allMonths") {
      setFieldValue("month1", !values[target.name]);
      setFieldValue("month2", !values[target.name]);
      setFieldValue("month3", !values[target.name]);
      setFieldValue("month4", !values[target.name]);
      setFieldValue("month5", !values[target.name]);
      setFieldValue("month6", !values[target.name]);
      setFieldValue("month7", !values[target.name]);
      setFieldValue("month8", !values[target.name]);
      setFieldValue("month9", !values[target.name]);
      setFieldValue("month10", !values[target.name]);
      setFieldValue("month11", !values[target.name]);
      setFieldValue("month12", !values[target.name]);
    }
    setFieldValue(target.name, !values[target.name]);
  };
  const onChangeSwitchStateFunds = (value, index) => {
    let tmpChecks = checksStateFunds.slice(0, checksStateFunds.length);
    tmpChecks[index] = value;
    setChecksStateFunds(tmpChecks);
  };
  const onChangeAllSwitchStateFunds = (value, index) => {
    setFieldValue("allStateFund", value);
    let tmpChecks = new Array(stateFunds.length).fill(value);
    setChecksStateFunds(tmpChecks);
  };

  const CloseBtn = (
    <i className="eva eva-close cursor-pointer" onClick={toggle} />
  );


  const validateSwitchs = () => {
    const months = getMonthstoSend()
    const stateFunds = getStateFundtoSend()
    if (months.length === 0) {
      toast(
        <Notification
          type={"consultar_error"}
          backMessage={"Seleccione almenos un mes"}
          withIcon
        />
      );
      return false
    } else if (stateFunds.length === 0) {
      toast(
        <Notification
          type={"consultar_error"}
          backMessage={"Seleccione almenos un proyecto"}
          withIcon
        />
        );
        return false
    }
    return true

  }

  const sendInformation = async () => {
    let params = {
      action: "saveMultiBudgetDetail",
      idBudgetRequest: idBudgetRequest,
      months: getMonthstoSend(),
      stateFunds: getStateFundtoSend(),
      idExpenseObject: values.idExpenseObject,
      amount: values.totalAmount,
      concept: values.description,
    };

      await API.peticionEndPoint(params, "app/facades/budgets/budgetsF.php")
        .then((res) => {
          if (res.status === 200 && res.data.code === "200") {
            toast(
              <Notification
                type={"agrega_exito"}
                backMessage={res.data.message}
                withIcon
              />
            );


            clearAllForm()
            setModal(false)
            setRefresh(true)
            mainReset(); /// Close main form
          } else {
            toast(
              <Notification
                type={"consultar_error"}
                backMessage={res.data.message}
                withIcon
              />
            );
          }
        })
        .catch((err) => {
          toast(<Notification type={"consultar_servidor_error"} withIcon />);
        });
    
  };

  const getMonthstoSend = () => {
    let months = [];
    for (let index = 0; index < 12; index++) {
      if (values[`month${index + 1}`] === true) months.push(index + 1);
    }
    return months;
  };
  const getStateFundtoSend = () => {
    let stateFundstoSend = [];
    stateFunds.forEach((stateFund, index) => {
      if (checksStateFunds[index]) {
        stateFundstoSend.push(stateFund.idStateFund);
      }
    });
    return stateFundstoSend;
  };

  useEffect(() => {
    if (values.keyChapter !== "") getPartidas();
  }, [values.keyChapter]);

  useEffect(() => {
    if(!modal){
      clearAllForm()
    }
  }, [modal]);

  return (
    <>
      <div className="my-3 d-flex flex-md-row flex-column justify-content-md-end">
        <Button onClick={() => setModal(true)}>
          Agregar por registro multiple
        </Button>
      </div>

      <Modal
        isOpen={modal}
        toggle={toggle}
        style={{ minWidth: "95%", maxHeight: "90%" }}
      >
        <ModalHeader
          toggle={toggle}
          close={CloseBtn}
          className="headline-1"
        ></ModalHeader>
        <form className="mt-2" onSubmit={handleSubmit} onReset={handleReset}>
          <ModalBody className="pb-2">
            <div className="text-center mb-3">
              <h4>Registro Multiple</h4>
            </div>
            <Row className="d-fex justify-content-center ">
              <Col xs={12} md={7} className="mb-5">
                <Row className="d-flex justify-content-center align-items-center flex-row mb-3">
                  <Col xs={10} md={10}>
                    <h5>Proyectos Presupuestales</h5>
                  </Col>

                  <CustomInput
                    type="switch"
                    id={"allStateFund"}
                    name={"allStateFund"}
                    onChange={(e) =>
                      onChangeAllSwitchStateFunds(e.target.checked)
                    }
                    checked={values.allStateFund}
                  />
                </Row>

                <div
                  style={{
                    height: "475px",
                    overflowY: "auto",
                    overflowX: "hidden",
                  }}
                >
                  {stateFunds.map((stateFund, index) => (
                    <Row className="d-flex justify-content-center align-items-center mt-3">
                      <Col xs={10} md={10}>
                        {stateFund.keyName}
                      </Col>
                      <CustomInput
                        type="switch"
                        id={stateFund.idStateFund + index}
                        name={stateFund.idStateFund + index}
                        onChange={(e) =>
                          onChangeSwitchStateFunds(e.target.checked, index)
                        }
                        checked={checksStateFunds[index]}
                      />
                    </Row>
                  ))}
                </div>
              </Col>
              <Col xs={12} md={5} className="mb-5">
                <Row className="d-flex flex-row justify-content-center mb-3">
                  <Col xs={4} md={4}>
                    <h5>Meses </h5>
                  </Col>

                  <CustomInput
                    type="switch"
                    id={"allMonths"}
                    name={"allMonths"}
                    onChange={onChangeSwitchMonths}
                    checked={values.allMonths}
                  />
                </Row>

                <MesesSwitch
                  values={values}
                  onChangeSwitchMonths={onChangeSwitchMonths}
                />
              </Col>
            </Row>

            <Row>
              <Col xs={12} md={6}>
                <SelectTypeHeadSingle
                  label="Capítulo"
                  inputName="keyChapter"
                  onChangeMethod={onChange}
                  onBlurMethod={handleBlur}
                  value={values.keyChapter}
                  optionsArray={chapters}
                  optionValue="keyChapter"
                  optionName="label"
                  errors={errors.keyChapter}
                  touched={touched.keyChapter}
                  isRequired
                />
              </Col>
              <Col xs={12} md={6}>
                <SelectTypeHeadSingle
                  label="Partida"
                  inputName="idExpenseObject"
                  onChangeMethod={onChange}
                  onBlurMethod={handleBlur}
                  value={values.idExpenseObject}
                  optionsArray={expensesObjects}
                  optionValue="idExpenseObject"
                  optionName="label"
                  errors={errors.idExpenseObject}
                  touched={touched.idExpenseObject}
                  isRequired
                  isDisabled={
                    expensesObjects !== null &&
                    expensesObjects !== undefined &&
                    expensesObjects.length > 0
                      ? false
                      : true
                  }
                />
              </Col>
            </Row>

            <Row>
              <Col xs={12} md={6}>
                <NumberInput
                  label="Monto Total"
                  inputName="totalAmount"
                  onChangeMethod={onChange}
                  onBlurMethod={handleBlur}
                  value={values.totalAmount}
                  errors={errors.totalAmount}
                  touched={touched.totalAmount}
                  prefix={"$"}
                  isRequired
                />
              </Col>
              <Col xs={12} md={6}>
                <TextAreaInput
                  label="Justificación para la solicitud y/o adquisición"
                  inputName="description"
                  inputRows={6}
                  onChangeMethod={onChange}
                  onBlurMethod={handleBlur}
                  value={values.description}
                  isRequired
                  touched={touched.description}
                  errors={errors.description}
                />
              </Col>
            </Row>
            <Row className="text-center">
              <Col xs={12} md={6}>
                <Button color="danger" className="mt-3" onClick={() => setModal(false)}>
                  Cancelar
                </Button>
              </Col>
              <Col xs={12} md={6}>
                <Button color="success" type="submit" className="mt-2 mt-md-3">
                  Guardar
                </Button>
              </Col>
            </Row>
          </ModalBody>

          <ModalConfirmation
            modalTitle={"Crear"}
            modal={modalConfirmation}
            setModal={setModalConfirmation}
            crear={sendInformation}
            isEdit={false}
            values={values}
          >
            <div className="d-flex justify-content-center">
              <h6>¿Desea continuar con el registro?</h6>
            </div>
          </ModalConfirmation>
        </form>
      </Modal>
    </>
  );
}

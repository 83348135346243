/**
 * @param params setters from Format
 */
export function setConfigR53(params) {
    const { setHeaders, setPdfConfig, setExcelConfig, setHtmlConfig, setData, filter } = params;
    const headers = [
        "PRIORIDADES DE GASTO",
    ]
    setHeaders(headers)
    const tempData = {
        action: "reportePrioridadesDeGasto",
        year: (new Date(filter.year)).getFullYear(),
    }
    setData(tempData);
    setHtmlConfig({
        headers,
    })
    setPdfConfig({
        descr: tempData,
        headers,
    });
    setExcelConfig({
        headers,
    });
}
import { useFormik } from "formik";
import * as Yup from "yup";
import ModalConfirmation from "../../../../components/Modal/ModalConfirmation";
import { Button, Col, Row } from "reactstrap";
import {
    SelectTypeHeadSingle,
    TextInput,
} from "../../../../components/GenericInputsFormik";
import { NumberInput } from "../../../../components/GenericInputsFormik/NumberInput";
import { doRequest, doRequestSaveRes } from "../../../../helpers/requests";
import Datatable from "../../../../components/Datatable/Datatable";
import { useEffect } from "react";
import { useState } from "react";
import format from "date-fns/format";
import { compareDates, fDate, validValue } from "../../../../libs/tools/format";
import FullFormLoader from "../../../../components/Loader/FullFormLoader";
import ModalComponentEliminar from "../../../../components/Modal/ModalDelete";
import { useRef } from "react";

const defaultForm = {
    idWinner: "",
    idArea: "",
    quantity: "",
    startDate: "",
    endDate: "",
};


const columnDefs = [
    {
        orderable: false,
        targets: [0, 7],
        className: "text-center",
    },
    {
        orderable: true,
        targets: [3, 4],
        className: "text-right",
    },
    {
        orderable: true,
        targets: [5, 6],
        className: "text-center",
    },
];

const cDate = new Date();
const cYear = cDate.getFullYear();

const defaultSelected = {idWinner: "", nameBS: "", total: 1, entregado: 0, tipo: 0};

export default function OrganizarRecepcion({ idPR, tab, idArea, revision }) {
    const [loading, setLoading] = useState(true);
    const [modal, setModal] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [areas, setAreas] = useState([]);
    const [bienes, setBienes] = useState([]);
    const [selected, setSelected] = useState(defaultSelected);
    const [isPush, setIsPush] = useState(false);

    const FormSchema = Yup.object().shape({
        idArea: Yup.string().required("Seleccione una área"),
        idWinner: Yup.string().required("Seleccione un bien o servicio"),
        quantity: Yup.number().when("idWinner", {
            is: (v) => v !== undefined,
            then: Yup.number()
                .required("Ingrese una cantidad")
                .min(1, "Debe recibirse al menos un bien o servicio")
                .max(+selected.total - +selected.entregado, "Esta cantidad excede el total solicitado")
        }),
        startDate: Yup.string().required("Seleccione una fecha de inicio"),
        endDate: Yup.string().when("startDate", {
            is: (v) => v !== undefined,
            then: Yup.string().required("Seleccione una fecha de termino"),
        }),
    });

    const {
        handleSubmit,
        values,
        handleBlur,
        errors,
        touched,
        setFieldValue,
        handleReset,
        setValues,
    } = useFormik({
        initialValues: defaultForm,
        validationSchema: FormSchema,
        onSubmit: () => setModal(true),
        onReset: ()=> { 
            setSelected(defaultSelected);
        },
        enableReinitialize: true,
    });

    useEffect(()=>{
        if(!revision) getRegion();
        else setLoading(false);
    }, []);
    
    useEffect(() => {
        if(!revision){
            if(tab === "6"){
                getBienes();
            } else{
                modifyAreas(false); 
                handleReset();
            }
        }
    }, [tab]);

    async function getRegion(){
        const params = {
            action: "multiselect",
            table: "areas A INNER JOIN buildings B USING (idBuilding) INNER JOIN cities C USING(keyCity) INNER JOIN counties D USING(keyCounty) INNER JOIN districts E USING(keyDistrict) INNER JOIN regions F USING(keyRegion)",
            rows: "F.keyRegion",
            conditions: `A.enabled = 1 AND A.idArea = ${idArea}`,
        }
        const res = await doRequest("receiver/receiver.php", params, false, true, true, "No se intetifico la región");
        if(res.length > 0) {
            getAreas(res[0].keyRegion, params);
        }
        else return "0";
    }

    async function getAreas(id, paramss) {

        const params = {
            ...paramss,
            rows: "A.*",
            conditions: `A.enabled = 1 AND F.keyRegion = ${id} AND A.storage = 1`,
            order: "A.name ASC",
        };
        const res = await doRequest("receiver/receiver.php", params, false);
        if (res.length > 0) setAreas(res);
        setLoading(false);
    }
    
    async function getBienes() {
        setLoading(true);
        const params = {
            action: "multiselect",
            table: "purchasewinners A INNER JOIN providerquotes B USING(idProviderQuote) INNER JOIN quotesdetail C USING(idDetail) LEFT JOIN furnitures D USING(idFurniture) LEFT JOIN services E USING(idService) LEFT JOIN receptions F USING(idWinner) LEFT JOIN quotes G USING(idQuote) LEFT JOIN areas H ON H.idArea = G.idArea",
            rows: "A.idWinner, CONCAT(IF(ISNULL(C.idService),D.nameFurniture, E.nameService)) AS nameBS,  C.amount AS total, SUM(IF(ISNULL(F.quantity) OR F.enabled = 0, 0, F.quantity)) AS entregado, IF(ISNULL(C.idService),1, 2) AS tipo, G.idArea AS areaSol, H.name AS areaSolName",
            order: "nameBS ASC",
            conditions: `A.enabled = 1 AND A.idPurchaseRequest = ${idPR}`,
            page: "0",
            group: "A.idWinner"
        };
        const res = await doRequest("receiver/receiver.php", params, false, true, true, "No se encontraron Bienes adjudicados");
        if (res.length > 0){
            let data = res.filter((el) => el.total === el.entregado );
            data = res.map((el) => {
                return {
                    ...el,
                    nameBS: el.nameBS + ` - Recibido: ${el.entregado} - Total: ${el.total}`,
                }
            });
            data = data.filter(el => el.total !== el.entregado)
            setBienes(data);
        }
        setLoading(false);
    }

    const onChange = ({ target }) => {
        if (target.name === "idWinner") {
            let flag = true;
            if(validValue(target.value)){
                const fnd = bienes.find((el) => +el.idWinner === +target.value);
                if(fnd) {
                    setSelected(fnd);
                    if(+fnd.tipo === 2){
                        const areafnd = areas.find((el) => +el.idArea === +fnd.areaSol);
                        if(!areafnd) modifyAreas(true, +fnd.areaSol, fnd.areaSolName);
                        setFieldValue("idArea", fnd.areaSol);
                    }else modifyAreas(false)
                }else flag = false;
            }else flag = false;

            if(!flag){
                modifyAreas(false);
                setSelected(defaultSelected);
            }
            setFieldValue("quantity", "");
        }
        if (target.name === "startDate") {
            if(!validValue(target.value) || !compareDates(target.value, values.endDate)){
                setFieldValue("endDate", "");
            }
        }
        setFieldValue(target.name, target.value);
    };

    function modifyAreas(t, d, d2){
        const auxAreas = [...areas];
        if(t){
            if(isPush) auxAreas.pop();
            auxAreas.push({idArea: d, name: d2});
            setIsPush(true);
        }else{
           if(isPush){
                auxAreas.pop();
                setIsPush(false);
           }
        }
        setAreas(auxAreas);
    }

    function getMinLimit(field) {
        let fecha = new Date(cYear - 5, 1, 1);
        if (field === "fF") {
            if (values.startDate) {
                const dd = values.startDate.split("-");
                fecha = new Date(+dd[0], +dd[1] - 1, dd[2]);
            }
        }
        return format(fecha, "yyyy-MM-dd");
    }

    async function saveReception() {
        setLoading(true);
        const params = {
            action: "insert",
            table: "receptions",
            rows: values,
            validate: [],
        };
        const res = await doRequest("receiver/receiver.php", params, true);
        if (res.length > 0){ 
            modifyAreas(false); 
            handleReset();
            setRefresh(true);
            await getBienes();
        }
        setLoading(false);
    }

    /* Table */
    const headers = [
        "#",
        "Adscripción",
        "Bien",
        "Cantidad total del bien",
        "Cantidad a entregar",
        "Fecha inicio de recepcion",
        "Fecha fin de recepcion",
        "Eliminar",
    ];

    const columns = [
        { data: "ix" },
        { data: "nameArea" },
        { data: "nameBien" },
        { data: "amount" },
        { data: "quantity" },
        { data: "startDate", format: fDate},
        { data: "endDate", format: fDate },
        {
            data: null,
            render: (row) => (
                <ModalComponentEliminar
                    modalTitle="Eliminar"
                    id={row.idReception}
                    table="budgetrequestdetail"
                    nameColumn="idDetail"
                    elimina={deleteRow}
                >
                    <h6>
                        ¿Seguro de eliminar la recepción de "{row.nameBien}"?
                    </h6>
                    No podrá recuperar la información después de ser eliminada
                </ModalComponentEliminar>
            ),
        },
    ];

    if(revision){
        headers.pop();
        columns.pop();
    }

    async function getData(ordCol, itemsPerPage, currentPage, search) {
        const res = await doRequestSaveRes(
            "receiver/receiver.php",
            getParams(ordCol, itemsPerPage, currentPage, search),
            false,
            false
        );
        if (res.code === "200") {
            res.data = res.data.map((el, ix) => {
                return { ...el, ix: ix + 1 };
            });
            return res;
        } else return null;
    }

    function getParams(ordCol, itemsPerPage, currentPage, search) {
        const params = {
            action: "datatable",
            table: "receptions A INNER JOIN areas B USING(idArea) INNER JOIN purchasewinners C USING (idWinner) INNER JOIN providerquotes D USING(idProviderQuote) INNER JOIN quotesdetail E USING(idDetail) LEFT JOIN furnitures F USING(idFurniture) LEFT JOIN services G USING(idService)",
            rows: "A.idWinner, B.name AS nameArea, IF(ISNULL(E.idService),F.nameFurniture, G.nameService) AS nameBien, E.amount, A.quantity, A.startDate, A.endDate, A.idReception",
            conditions: `A.enabled = 1 AND C.idPurchaseRequest = ${idPR}`,
            order: ordCol,
            records: itemsPerPage.toString(),
            page: (itemsPerPage * currentPage).toString(),
            search,
        };
        return params;
    }

    async function deleteRow(id) {
        setLoading(true);
        const params = {
            action: "delete",
            table: "receptions",
            condition: { idReception: id },
            //   force: 1,
        };
        const res = await doRequest("receiver/receiver.php", params, true);
        if (res.length > 0){ 
            setRefresh(true);
            await getBienes();
        }
        setLoading(false);
    }
    /* End Table */

    return (
        <div>
            {
                !revision &&
                <>
                    <div className="my-2">
                        <h4>Organizar recepción de bienes</h4>
                    </div>

                    <form onSubmit={handleSubmit} onReset={handleReset}>
                        <Row>
                            <Col xs={12} md={12}>
                                <SelectTypeHeadSingle
                                    label="Adscripción"
                                    errors={errors.idArea}
                                    value={values.idArea}
                                    touched={touched.idArea}
                                    optionsArray={areas}
                                    inputName="idArea"
                                    isRequired
                                    onBlurMethod={handleBlur}
                                    optionName="name"
                                    optionValue="idArea"
                                    onChangeMethod={onChange}
                                    isDisabled={+selected.tipo === 2}
                                />
                            </Col>
                            <Col xs={12} md={6}>
                                <SelectTypeHeadSingle
                                    label="Bienes/Servicios"
                                    errors={errors.idWinner}
                                    value={values.idWinner}
                                    touched={touched.idWinner}
                                    optionsArray={bienes}
                                    inputName="idWinner"
                                    isRequired
                                    onBlurMethod={handleBlur}
                                    optionValue="idWinner"
                                    optionName="nameBS"
                                    onChangeMethod={onChange}
                                />
                            </Col>
                            <Col xs={12} md={6}>
                                <NumberInput
                                    label="Cantidad"
                                    inputName="quantity"
                                    onChangeMethod={onChange}
                                    onBlurMethod={handleBlur}
                                    value={values.quantity}
                                    errors={errors.quantity}
                                    touched={touched.quantity}
                                    isRequired
                                    isMin={1}
                                    isDisabled={!values.idWinner}
                                />
                            </Col>
                            <Col xs={12} md={6}>
                                <TextInput
                                    label="Fecha Inicial"
                                    inputName="startDate"
                                    inputType="date"
                                    onChangeMethod={onChange}
                                    onBlurMethod={handleBlur}
                                    value={values.startDate}
                                    errors={errors.startDate}
                                    touched={touched.startDate}
                                    isMin={getMinLimit("fI")}
                                    isMax={format(cDate, "yyyy-MM-dd")}
                                />
                            </Col>
                            <Col xs={12} md={6}>
                                <TextInput
                                    label="Fecha final"
                                    inputName="endDate"
                                    inputType="date"
                                    onChangeMethod={onChange}
                                    onBlurMethod={handleBlur}
                                    value={values.endDate}
                                    errors={errors.endDate}
                                    touched={touched.endDate}
                                    isMin={getMinLimit("fF")}
                                    isDisabled={!values.startDate}
                                />
                            </Col>
                        </Row>

                        <div className="mt-2 mb-5 d-flex flex-md-row flex-column-reverse justify-content-md-around">
                            <Button color="danger" type="reset" className="mt-3">
                                Limpiar
                            </Button>
                            <Button
                                color="success"
                                type="submit"
                                className="mt-2 mt-md-3"
                            >
                                Guardar
                            </Button>
                        </div>
                    </form>
                </>
            }

            <Datatable
                headers={headers}
                columns={columns}
                columnDefs={columnDefs}
                petition={getData}
                stateRefresh={[refresh, setRefresh]}
                control="back"
                order={{ col: 2, opt: "asc" }}
            />

            <FullFormLoader show={loading} />

            <ModalConfirmation
                modalTitle="Guardar"
                modal={modal}
                crear={saveReception}
                setModal={setModal}
            >
                <div className="d-flex justify-content-center">
                    <h6>¿Está seguro de guardar la recepción?</h6>
                </div>
            </ModalConfirmation>
        </div>
    );
}

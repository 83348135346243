
import React from "react";
import Widget from "../../../../components/Widget/Widget";
import FormSeguros from "../FormSeguros";
import { createSeguro } from "../functions";

export const CrearSeguro = (props) => {

  return (
    <Widget className="widget-p-md">
        <h3>Crear tipo de seguro</h3>
        <FormSeguros crear={createSeguro}/>
    </Widget>
  )
}

export default CrearSeguro;
import { useEffect, useState } from "react";
import { Pagination, PaginationItem, PaginationLink, Table } from "reactstrap";
import { sizes } from "./layouts";

export default function SimpleTable(props) {
  /* Tabla */
  const [pageSize, setPageSize] = useState(5); // Tamaño de las paginas
  const [pagesCount, setPagesCount] = useState(0); // Cantidad total de paginas
  const [currentPage, setCurrentPage] = useState(0); // Pagina actual
  const [seccionActual, setSeccionActual] = useState(0); // Seccion actual

  const { columns, selectedF, dataView, datal } = props;
  const style = { borderBottomWidth: "3px", borderBottomStyle: "solid" }

  useEffect(()=>{
    setPagination(5);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);
  
  //TABLE FUNCTIONS
  const settableContentPage = (e, index) => {
    e.preventDefault();
    if (index + 1 > (seccionActual + 1) * 5) {
      setSeccionActual(seccionActual + 1);
    } else if (seccionActual !== 0 && index + 1 <= seccionActual * 5) {
      setSeccionActual(seccionActual - 1);
    }
    setCurrentPage(index);
  };

  function setPagination(size) {
    setPagesCount(Math.ceil((datal - 1) / size));
    setPageSize(size);
    setCurrentPage(0);
    setSeccionActual(0);
  }

  const handleOnChangeSelect = (e) => {
    setPagination(e.target.value);
  };
  return (
    <>
      <Table
        className="table-striped table-borderless " //table-hover
        responsive
      >
        <thead className="border-default" style={style}>
          <tr>
            <th className="text-center">No. Fila</th>
            {columns[selectedF].csv.map((el, id) => (
              <th
                key={id}
                className="text-center"
                style={sizes.includes(el) ? { minWidth: "350px" } : {}}
              >
                {el}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {dataView
            .slice(currentPage * pageSize, (currentPage + 1) * pageSize)
            .map((el, i) => (
              <tr key={"r"+i} style={el.row} className="border-bottom border-default">
                <td className="text-center">
                  {el.idR}
                </td>
                {columns[selectedF].bd.map((col) => (
                  <td key={"r"+i+"-"+col} className={[el.cols[col]]}>
                    {el[col]}
                  </td>
                ))}
              </tr>
            ))}
        </tbody>
      </Table>
      <div className="row mx-2">
        <div className="col-12 col-lg-6 d-flex justify-content-around mt-3">
          <div>
            Mostrando{" "}
            <span className="text-primary">
              {pageSize * (currentPage + 1) <= dataView.length
                ? pageSize * currentPage + 1
                : dataView.length}{" "}
            </span>{" "}
            a{" "}
            <span className="text-primary">
              {pageSize * (currentPage + 1) <= dataView.length
                ? pageSize * (currentPage + 1)
                : dataView.length}
            </span>{" "}
            de {dataView.length} registros
          </div>
          <select
            className="form-control form-control-sm"
            style={{ width: "78px" }}
            value={pageSize}
            onChange={handleOnChangeSelect}
          >
            <option value={5} key={5}>
              5
            </option>
            <option value={10} key={10}>
              10
            </option>
            <option value={15} key={15}>
              15
            </option>
            <option value={20} key={20}>
              20
            </option>
            <option value={100} key={100}>
              100
            </option>
          </select>
        </div>
        <div className="col-12 col-lg-6 mt-3 d-flex justify-content-center justify-content-lg-end">
          <Pagination>
            <PaginationItem disabled={currentPage <= 0} className="mx-1">
              <PaginationLink
                onClick={(e) => settableContentPage(e, currentPage - 1)}
                previous
                href={currentPage}
              />
            </PaginationItem>

            {[...Array(5)].map(
              (page, i) =>
                5 * seccionActual + i < pagesCount && (
                  <PaginationItem
                    key={i}
                    disabled={5 * seccionActual + i === currentPage}
                    className="mx-1"
                  >
                    <PaginationLink
                      onClick={(e) =>
                        settableContentPage(e, 5 * seccionActual + i)
                      }
                      href={5 * seccionActual + (i + 1)}
                      className={
                        5 * seccionActual + i === currentPage
                          ? "bg-primary rounded-lg text-light"
                          : ""
                      }
                    >
                      {5 * seccionActual + (i + 1)}
                    </PaginationLink>
                  </PaginationItem>
                )
            )}

            <PaginationItem
              disabled={currentPage >= pagesCount - 1}
              className="mx-1"
            >
              <PaginationLink
                onClick={(e) => settableContentPage(e, currentPage + 1)}
                next
                href={currentPage + 2}
              />
            </PaginationItem>
          </Pagination>
        </div>
      </div>
    </>
  );
}

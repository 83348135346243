
import React from "react";
import Widget from "../../../../components/Widget/Widget";
import FormHorarios from "../FormHorarios";
import { createSchedule } from "../functions";

export const CrearHorario = () => {

  return (
    <Widget className="widget-p-md">
        <h3>Crear Horario</h3>
        <FormHorarios crear={createSchedule}/>
    </Widget>
  )
}

export default CrearHorario;
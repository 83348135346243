import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { Col, Row, Button } from "reactstrap";
import { useHistory } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage, useFormikContext } from "formik";

import Loader from "../../../components/Loader/Loader";
import { peticionesReceiver } from "../../../helpers/peticionesReceiver";
import Notification from "../../../components/Notification/Notification";
import ModalConfirmation from "../../../components/Modal/ModalConfirmation";
import { SelectTypeHeadSingle } from "../../../components/GenericInputsFormik/";

import { getKindToStatus } from "./functions";

const FormPuestoLaboral = ({ crear, data, editar }) => {
  const defaultForm = {
    keyJob: "",
    keyJobType: "",
    nameJob: "",
  }
  const API = peticionesReceiver();

  const history = useHistory();
  const [form, setForm] = useState(defaultForm);
  const [modal, setModal] = useState(false);
  const [jobsTypes, setJobsTypes] = useState([])
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    getJobsTypes()
  }, [])

  const getJobsTypes = async () => {
    const params = {
      action: "select",
      table: "jobtypes",
      condition: {
        enabled: 1,
      },
      order: "nameJobType ASC",
    };
    try{
      const res = await API.peticion(params)
      if(res.status === 200 && res.data.code === "200"){
        setJobsTypes(res.data.data)
        setIsLoading(false)
      }
      else {
        toast(<Notification type={"consultar_error"} withIcon />);
      }
    }
    catch(err) {
      toast(<Notification type={"consultar_servidor_error"} withIcon />)
    }
  };
  
  const FormSchema = Yup.object().shape({
    keyJobType: Yup.string()
      .required("Favor de seleccionar un tipo de puesto"),
      nameJob: Yup.string()
      .min(4, "Nombre del puesto debe contener al menos 4 letras")
      .max(200, "El nombre ingresado es muy largo")
      .required("Favor de ingresar el nombre del puesto"),
  });

  if (isLoading) {
    return (
      <Loader/>
    );
  }

  return (
    <Formik
      initialValues={form}
      validationSchema={() => FormSchema}
      onSubmit={() => setModal(true)}
      onReset={() => history.push("/template/puestos")}
    >
      {({ errors, touched, values,setFieldValue,handleBlur }) => (
        <Form className="mt-4">
          <ModalConfirmation
            modalTitle={data ? "Editar" : "Crear"}
            modal={modal}
            setModal={setModal}
            crear={crear}
            editar={editar}
            isEdit={data ? true : false}
            values={values}
          >
            {data ? (
              <div className="d-flex justify-content-center">
                <h6>¿Está seguro de editar el registro?</h6>
              </div>
            ) : (
              <div className="d-flex justify-content-center">
                <h6>¿Desea continuar con el registro?</h6>
              </div>
            )}
          </ModalConfirmation>

          <Row>
            <Col xs="12" md="6">
              <SelectTypeHeadSingle
                label="Tipo de Puesto"
                isRequired={true}
                inputName="keyJobType"
                optionsArray={jobsTypes}
                defaultOption="Seleccione un tipo de puesto"
                onChangeMethod={(event) =>
                  {
                    const {name,value} = event.target
                    setFieldValue(name, value)
                  }
                }
                onBlur={handleBlur}
                isDisabled={false}
                value={values.keyJobType}
                touched={touched.keyJobType}
                errors={errors.keyJobType}
                optionValue="keyJobType"
                optionName="nameJobType"
              />
            </Col>

            <Col xs="12" md="6">
              <div className="form-group">
                <label htmlFor="nameJob">Puesto<span className="text-danger">*</span>
                </label>
                <Field
                  name="nameJob"
                  type="text"
                  className={
                    "form-control" +
                    (errors.nameJob && touched.nameJob ? " is-invalid" : "")
                  }
                />
                <ErrorMessage
                  name="nameJob"
                  component="div"
                  className="invalid-feedback"
                />
              </div>
            </Col>
          </Row>

          <div className="d-flex justify-content-between mt-5">
            <Button color="danger" type="reset"> Cancelar </Button>
            <Button color="success" type="submit"> Guardar </Button>
          </div>
          <FormikData info={data} jobType = {jobsTypes}/>
        </Form>
      )}
    </Formik>
  );
};

const FormikData = ({ info,jobType }) => {
  const { setFieldValue } = useFormikContext();

  useEffect(() => {
    if(info) {
      getJobType()
      setFieldValue("keyJob", info[0]);
      setFieldValue("nameJob", info[1]);
    }
  }, [info]);

  const getJobType = () => {
    const data = jobType.filter(it => it.nameJobType === info[2])[0]
    setFieldValue("keyJobType", data.keyJobType);
  }
  return null;
};
export default FormPuestoLaboral;

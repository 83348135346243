import st from "./nav.module.scss";
import React from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
} from "reactstrap";
import SATLogo from "../../../components/logos/SAT";
import { RENAPOLogo } from "../../../components/logos/RENAPO";
import IMSSLogo from "../../../components/logos/IMSS";
import INELogo from "../../../components/logos/INE";
import ISSSTELogo from "../../../components/logos/ISSSTE";
import classNames from "classnames";
import { BornString2Years } from "../../../libs/tools/format";

const fDate = (s) => {
  return s.replaceAll("-", "/");
};

export default function ModalInformation({ modal, setModal, info, setStaffValid, setActiveTab, setUpload }) {
  const toggle = () => setModal(!modal);

  const CloseBtn = (
    <i className="eva eva-close cursor-pointer text-light" onClick={toggle} />
  );


  async function createEmployee(){
    setStaffValid(info)
    setUpload({dGen: false, dom: false, exp: false, fot: false})
    setModal(false)
    setActiveTab(2)
  }

  return (
    <Modal isOpen={modal} toggle={toggle} size="lg" style={{minWidth: "80%"}}>
      <ModalHeader
        toggle={toggle}
        close={CloseBtn}
        className="headline-1 bg-primary d-flex justify-content-between"
      >
        <div className=" text-center text-light">
          <p>VALIDACIÓN DE DATOS PERSONALES</p>
          <p>FISCALÍA GENERAL DE JUSTICIA</p>
        </div>
      </ModalHeader>
      {info !== "" && (
        <ModalBody className="pb-2">
          <div className="border rounded p-2">
            <Row>
              <Col xs={12} md={6}>
                <strong>Nombre: </strong>
                <span>
                  {info.INE.nombres} {info.INE.primerApellido}{" "}
                  {info.INE.segundoApellido}
                </span>
              </Col>
              <Col xs={12} md={6}>
                <strong>CURP: </strong>
                <span>{info.INE.curp}</span>
              </Col>
            </Row>
          </div>

          <div className="accordion mt-2" style={{ fontSize: "14px" }}>
            <Card className="border border-bottom-0">
              <CardHeader className="text-center">
                <INELogo className={st.sizeLogo} />
                <strong>INSTITUTO NACIONAL ELECTORAL</strong>
              </CardHeader>
              <CardBody className="p-0">
                <div className="row mx-0 border-bottom border-top mb-2">
                  <div className="col-12 col-md-2 text-light bg-dark-gray font-weight-bold px-2 d-flex align-items-center">
                    Nombre
                  </div>
                  <div className="col-12 col-md-2 px-2 d-flex align-items-center justify-content-center">
                    {info.INE.nombres} {info.INE.primerApellido}{" "}
                    {info.INE.segundoApellido}
                  </div>
                  <div className="col-12 col-md-2 text-light bg-dark-gray font-weight-bold px-2 d-flex align-items-center">
                    CURP
                  </div>
                  <div className="col-12 col-md-2 px-2 d-flex align-items-center justify-content-center">
                    {info.INE.curp}
                  </div>
                  <div className="col-12 col-md-2 text-light bg-dark-gray font-weight-bold px-2 d-flex align-items-center">
                    Vigencia
                  </div>
                  <div className="col-12 col-md-2 px-2 d-flex align-items-center justify-content-center">
                    {info.INE.vigencia}
                  </div>
                </div>
                <div className="row mx-0 border-bottom border-top my-2">
                  <div className="col-12 col-md-2 text-light bg-dark-gray font-weight-bold px-2 d-flex align-items-center">
                    Clave de Elector
                  </div>
                  <div className="col-12 col-md-2 px-2 d-flex align-items-center justify-content-center">{info.INE.claveElector}</div>
                  <div className="col-12 col-md-2 text-light bg-dark-gray font-weight-bold px-2 d-flex align-items-center">
                    Estatus
                  </div>
                  <div className="col-12 col-md-2 px-2 d-flex align-items-center justify-content-center">AEE</div>
                  <div className="col-12 col-md-2 text-light bg-dark-gray font-weight-bold px-2 d-flex align-items-center">
                    Año de Registro
                  </div>
                  <div className="col-12 col-md-2 px-2 d-flex align-items-center justify-content-center">{info.INE.registro}</div>
                </div>
                <div className="row mx-0 border-bottom border-top my-2">
                  <div className="col-12 col-md-2 text-light bg-dark-gray font-weight-bold px-2 d-flex align-items-center">
                    Mensaje
                  </div>
                  <div className="col-12 col-md-8 px-2 d-flex align-items-center justify-content-center">AGG</div>
                </div>
                <div className="row mx-0 border-bottom border-top my-2">
                  <div className="col-12 col-md-2 text-light bg-dark-gray font-weight-bold px-2 d-flex align-items-center">
                    Calle
                  </div>
                  <div className="col-12 col-md-2 px-2 d-flex align-items-center">{info.INE.calle}</div>
                  <div className="col-12 col-md-2 text-light bg-dark-gray font-weight-bold px-2 d-flex align-items-center">
                    Colonia
                  </div>
                  <div className="col-12 col-md-2 px-2 d-flex align-items-center">{info.INE.colonia}</div>
                  <div className="col-12 col-md-2 text-light bg-dark-gray font-weight-bold px-2 d-flex align-items-center">
                    Ciudad
                  </div>
                  <div className="col-12 col-md-2 px-2 d-flex align-items-center justify-content-center">{info.INE.ciudad}</div>
                </div>
                <div className="row mx-0 border-bottom border-top mb-2">
                  <div className="col-12 col-md-2 text-light bg-dark-gray font-weight-bold px-2 d-flex align-items-center">
                    Similitud de Rostro Contra Registro Patronal
                  </div>
                  <div className="col-12 col-md-2 px-2 d-flex align-items-center justify-content-center">
                    {info.FACECOMPARISON.estatus !== "ERROR" ? `${+info.FACECOMPARISON.similitud.toFixed(2)}%` : info.FACECOMPARISON.mensaje}
                  </div>
                  <div className="col-12 col-md-2 text-light bg-dark-gray font-weight-bold px-2 d-flex align-items-center">
                    Edad
                  </div>
                  <div className="col-12 col-md-2 px-2 d-flex align-items-center justify-content-center">
                    {BornString2Years(info.SAT.datosIdentificacion.fechaNacimiento)} AÑOS
                  </div>
                  <div className="col-12 col-md-2 text-light bg-dark-gray font-weight-bold px-2 d-flex align-items-center">
                    Sexo
                  </div>
                  <div className="col-12 col-md-2 px-2 d-flex align-items-center justify-content-center">{info.INE.sexo}</div>
                </div>
              </CardBody>
            </Card>
            <Card className="border border-bottom-0">
              <CardHeader className="text-center">
                <SATLogo className={st.sizeLogo} />
                <strong>SERVICIO DE ADMINISTRACIÓN TRIBUTARIA</strong>
              </CardHeader>
              <CardBody className="p-0">
                <div className="row mx-0 border-bottom border-top mb-2">
                  <div className="col-12 col-md-2 text-light bg-dark-gray font-weight-bold px-2 d-flex align-items-center">
                    RFC
                  </div>
                  <div className="col-12 col-md-3 px-2 d-flex align-items-center justify-content-center">{info.SAT.rfc}</div>
                  <div className="col-12 col-md-2 text-light bg-dark-gray font-weight-bold px-2 d-flex align-items-center">
                    Nombre
                  </div>
                  <div className="col-12 col-md-5 px-2 d-flex align-items-center">
                    {info.SAT.datosIdentificacion.nombres}{" "}
                    {info.SAT.datosIdentificacion.apellidoPaterno}{" "}
                    {info.SAT.datosIdentificacion.apellidoMaterno}
                  </div>
                </div>
                <div className="row mx-0 border-bottom border-top my-2">
                  <div className="col-12 col-md-2 text-light bg-dark-gray font-weight-bold px-2 d-flex align-items-center">
                    Fecha de Nacimiento
                  </div>
                  <div className="col-12 col-md-2 px-2 d-flex align-items-center justify-content-center">
                    {fDate(info.SAT.datosIdentificacion.fechaNacimiento)}
                  </div>
                  <div className="col-12 col-md-2 text-light bg-dark-gray font-weight-bold px-2 d-flex align-items-center">
                    Fecha Inicio Operaciones
                  </div>
                  <div className="col-12 col-md-2 px-2 d-flex align-items-center justify-content-center">
                    {fDate(info.SAT.datosIdentificacion.fechaInicioOperaciones)}
                  </div>
                  <div className="col-12 col-md-2 text-light bg-dark-gray font-weight-bold px-2 d-flex align-items-center">
                    Situación
                  </div>
                  <div className="col-12 col-md-2 px-2 d-flex align-items-center justify-content-center">
                    {info.SAT.datosIdentificacion.situacionContribuyente}
                  </div>
                </div>
                <div className="row mx-0 border-bottom border-top my-2">
                  <div className="col-12 col-md-2 text-light bg-dark-gray font-weight-bold px-2 d-flex align-items-center">
                    Domicilio
                  </div>
                  <div className="col-12 col-md-5 px-2 d-flex align-items-center">
                    {info.SAT.datosUbicacion.nombreVialidad}{" "}
                    {info.SAT.datosUbicacion.numeroExterior}{" "}
                    {info.SAT.datosUbicacion.numeroInterior}
                    {", "}
                    {info.SAT.datosUbicacion.municipioDelegacion}{" "}
                    {info.SAT.datosUbicacion.entidadFederativa}
                    {", C.P. "}
                    {info.SAT.datosUbicacion.cp}
                  </div>
                  <div className="col-12 col-md-2 text-light bg-dark-gray font-weight-bold px-2 d-flex align-items-center">
                    Correo Electrónico
                  </div>
                  <div className="col-12 col-md-3 px-2 d-flex align-items-center justify-content-center">
                    {info.SAT.datosUbicacion.correoElectronico}
                  </div>
                </div>
                <div className="row mx-0 border-bottom border-top mt-2">
                <div className="col-12 text-light bg-dark-gray font-weight-bold px-2 d-flex align-items-center justify-content-center">
                    Régimen fiscal
                  </div>
                </div>
                {info.SAT.caracteristicasFiscales.map((d, ix) => (
                  <div className={classNames(["row mx-0 border-bottom", ix+1 === info.SAT.caracteristicasFiscales.length  && "mb-2"])} 
                    key={"rrf" + ix} 
                    style={{backgroundColor: "#2f86d320"}}
                  >
                    <div className="col-12 col-md-6 px-2">{d.regimen}</div>
                    <div className="col-12 col-md-3 text-light bg-dark-gray font-weight-bold px-2 d-flex align-items-center justify-content-center">
                      Fecha Alta
                    </div>
                    <div className="col-12 col-md-3 px-2 d-flex align-items-center justify-content-center">{fDate(d.fechaAlta)}</div>
                  </div>
                ))}
              </CardBody>
            </Card>
            <Card className="border border-bottom-0">
              <CardHeader className="text-center">
                <RENAPOLogo className={st.sizeLogo5} />
                <strong>REGISTRO NACIONAL DE POBLACION</strong>
              </CardHeader>
              <CardBody className="p-0">
                <div className="row mx-0 border-bottom border-top mb-2">
                  <div className="col-12 col-md-2 text-light bg-dark-gray font-weight-bold px-2 d-flex align-items-center">
                    CURP
                  </div>
                  <div className="col-12 col-md-4 px-2 d-flex align-items-center justify-content-center">
                    {info.RENAPO.curp}
                  </div>
                  <div className="col-12 col-md-2 text-light bg-dark-gray font-weight-bold px-2 d-flex align-items-center">
                    Nombre
                  </div>
                  <div className="col-12 col-md-4 px-2 d-flex align-items-center">{info.RENAPO.nombre} {info.RENAPO.apellidoPaterno}{" "}
                    {info.RENAPO.apellidoMaterno}</div>
                </div>
                <div className="row mx-0 border-bottom border-top my-2">
                  <div className="col-12 col-md-2 text-light bg-dark-gray font-weight-bold px-2 d-flex align-items-center">
                    Fecha de Nacimiento
                  </div>
                  <div className="col-12 col-md-2 px-2 d-flex align-items-center justify-content-center">
                    {info.RENAPO.fechaNacimiento}
                  </div>
                  <div className="col-12 col-md-2 text-light bg-dark-gray font-weight-bold px-2 d-flex align-items-center">
                    Año de Registro
                  </div>
                  <div className="col-12 col-md-2 px-2 d-flex align-items-center justify-content-center">
                    {info.RENAPO.datosDocProbatorio.anioReg}
                  </div>
                  <div className="col-12 col-md-2 text-light bg-dark-gray font-weight-bold px-2 d-flex align-items-center">
                    Municipio de Registro
                  </div>
                  <div className="col-12 col-md-2 px-2 d-flex align-items-center justify-content-center">
                    {info.RENAPO.datosDocProbatorio.municipioRegistro}
                  </div>
                </div>
                <div className="row mx-0 border-bottom border-top my-2">
                  <div className="col-12 col-md-2 text-light bg-dark-gray font-weight-bold px-2 d-flex align-items-center">
                    Datos de Nacimiento
                  </div>
                  <div className="col-12 col-md-5 px-2 d-flex align-items-center">
                    {info.RENAPO.datosDocProbatorio.municipioRegistro},{" "}
                    {info.RENAPO.estadoNacimiento}, {info.RENAPO.paisNacimiento}
                  </div>
                  <div className="col-12 col-md-2 text-light bg-dark-gray font-weight-bold px-2 d-flex align-items-center">
                    Número de Acta
                  </div>
                  <div className="col-12 col-md-3 px-2 d-flex align-items-center justify-content-center">
                    {info.RENAPO.datosDocProbatorio.numActa}
                  </div>
                </div>
                {/* <div className="row mx-0 border-bottom border-top my-2">
                  <div className="col-12 text-light bg-dark-gray text-center">
                    Régimen fiscal
                  </div>
                  <div className="col-12 col-md-6">BD</div>
                  <div className="col-12 col-md-3">BE</div>
                  <div className="col-12 col-md-3">BF</div>
                </div> */}
              </CardBody>
            </Card>
            <Card className="border border-bottom-0">
              <CardHeader className="text-center">
                <IMSSLogo className={st.sizeLogo} />
                <strong>INSTITUTO MEXICANO DEL SEGURO SOCIAL</strong>
              </CardHeader>
              <CardBody className="p-0">
                <div className="row mx-0 border-bottom border-top mb-2">
                  <div className="col-12 col-md-2 text-light bg-dark-gray font-weight-bold px-2 d-flex align-items-center">
                    Nombre
                  </div>
                  <div className="col-12 col-md-5">{info.IMSS.nombre}</div>
                  <div className="col-12 col-md-2 text-light bg-dark-gray font-weight-bold px-2 d-flex align-items-center">
                    Semanas Cotizadas
                  </div>
                  <div className="col-12 col-md-3">
                    {info.IMSS.semanasCotizadas.semanasCotizadas}
                  </div>
                </div>
                <div className="row mx-0 border-bottom border-top my-2">
                  <div className="col-12 col-md-2 text-light bg-dark-gray font-weight-bold px-2 d-flex align-items-center">
                    NSS
                  </div>
                  <div className="col-12 col-md-5">BI</div>
                </div>
                <div className="row mx-0 border-bottom border-top mt-2">
                <div className="col-12 text-light bg-dark-gray font-weight-bold px-2 d-flex align-items-center justify-content-center">
                    Registro Patronal
                  </div>
                </div>
                {info.IMSS.historialLaboral.map((d, ix) => (
                  <div className={classNames(["row mx-0 border-bottom", ix+1 === info.IMSS.historialLaboral.length  && "mb-2"])}
                  style={{backgroundColor: "#2f86d320"}} 
                  key={"rimf" + ix} 
                >
                    <div className="col-12 col-md-4 px-2">{d.nombrePatron}</div>
                    <div className="col-12 col-md-2 text-light bg-dark-gray font-weight-bold px-2 d-flex align-items-center">
                      Fecha Alta
                    </div>
                    <div className="col-12 col-md-2 px-2 d-flex align-items-center justify-content-center">{d.fechaAlta}</div>
                    <div className="col-12 col-md-2 text-light bg-dark-gray font-weight-bold px-2 d-flex align-items-center">
                      Fecha Baja
                    </div>
                    <div className="col-12 col-md-2 px-2 d-flex align-items-center justify-content-center">{d.fechaBaja}</div>
                  </div>
                ))}
              </CardBody>
            </Card>
            <Card className="border">
              <CardHeader className="text-center">
                <ISSSTELogo className={st.sizeLogo} />
                <strong>
                  INSTITUTO DE SEGURIDAD Y SERVICIOS SOCIALES DE LOS
                  TRABAJADORES DEL ESTADO
                </strong>
              </CardHeader>
              <CardBody className="p-0">
                <div className="row mx-0 border-bottom border-top mb-2">
                  <div className="col-12 col-md-2 text-light bg-dark-gray font-weight-bold px-2 d-flex align-items-center">
                    Nombre
                  </div>
                  <div className="col-12 col-md-5 px-2 d-flex align-items-center justify-content-center">
                    {info.ISSSTE.nombre} {info.ISSSTE.primerApellido}{" "}
                    {info.ISSSTE.segundoApellido}
                  </div>
                  <div className="col-12 col-md-2 text-light bg-dark-gray font-weight-bold px-2 d-flex align-items-center">
                    RFC
                  </div>
                  <div className="col-12 col-md-3 px-2 d-flex align-items-center justify-content-center">{info.ISSSTE.rfc}</div>
                </div>
                <div className="row mx-0 border-bottom border-top my-2">
                  <div className="col-12 col-md-2 text-light bg-dark-gray font-weight-bold px-2 d-flex align-items-center">
                    NSS
                  </div>
                  <div className="col-12 col-md-2 px-2 d-flex align-items-center justify-content-center">{info.ISSSTE.nss}</div>
                  <div className="col-12 col-md-2 text-light bg-dark-gray font-weight-bold px-2 d-flex align-items-center">
                    Tipo Derecho Habiente
                  </div>
                  <div className="col-12 col-md-2 px-2 d-flex align-items-center justify-content-center">
                    {info.ISSSTE.tipoDerechohabiente}
                  </div>
                  <div className="col-12 col-md-2 text-light bg-dark-gray font-weight-bold px-2 d-flex align-items-center">
                    Estatus
                  </div>
                  <div className="col-12 col-md-2 px-2 d-flex align-items-center justify-content-center">{info.ISSSTE.situacionAfiliatoria}</div>
                </div>
                <div className="row mx-0">
                  <div className="col-12 col-md-2 text-light bg-dark-gray font-weight-bold px-2 d-flex align-items-center">
                    Fecha Nacimiento
                  </div>
                  <div className="col-12 col-md-2 px-2 d-flex align-items-center justify-content-center">
                    {info.ISSSTE.fechaNacimiento}
                  </div>
                  <div className="col-12 col-md-2 text-light bg-dark-gray font-weight-bold px-2 d-flex align-items-center">
                    Fecha Alta
                  </div>
                  <div className="col-12 col-md-2 px-2 d-flex align-items-center justify-content-center">
                    {info.ISSSTE.fechaAltaPlazaActual}
                  </div>
                  <div className="col-12 col-md-2 text-light bg-dark-gray font-weight-bold px-2 d-flex align-items-center">
                    Fecha Ingreso Gob. Federal
                  </div>
                  <div className="col-12 col-md-2 px-2 d-flex align-items-center justify-content-center">
                    {info.ISSSTE.fechaIngresoGobiernoFederal}
                  </div>
                </div>
              </CardBody>
            </Card>
          </div>
        </ModalBody>
      )}
      <ModalFooter className="pb-3">
          <Button color="success" className=" mx-auto" onClick={() => createEmployee()}>
            Continuar al Registro
          </Button>
      </ModalFooter>
    </Modal>
  );
}

import Widget from "../../../../components/Widget/Widget";
import FormSolicitudPago from "../FormSolicitudPago";

const CrearSolicitudPago = (props) => {
  return (
    <div>
      <Widget className="widget-p-md">
        <h3>Crear Solicitud de Pago</h3>
        <FormSolicitudPago module="nomina" />
      </Widget>
    </div>
  );
};

export default CrearSolicitudPago;

import React, { useState, useEffect } from "react";
import Loader from "../../components/Loader/Loader";
import { Table } from "reactstrap";
import s from "../../pages/tables/Tables.module.scss";
import sc from "./TableComponentFiltro.module.scss";

const TableComponentDiscounts = (props) => {
  const {
    sendDiscount,
    registros,
    setRegistros,
    salaryTypesBack,
    selectsData,
    setSelectsData,
    setSalaryTypesBack,
    excludeDates,
    setExcludeDates,
    setShowSection,
  } = props;
  const [loading, setLoading] = useState(true);
  function padTo2Digits(num) {
    return num.toString().padStart(2, "0");
  }

  function formatDate(date) {
    return [
      padTo2Digits(date.getDate()),
      padTo2Digits(date.getMonth() + 1),
      date.getFullYear(),
    ].join("/");
  }

  const muestraRegistro = (index) => {
    let newArray = [...registros];
    newArray[index].enabled = 0;
    setRegistros(newArray);
    sendDiscount("table");
    //Vamos a ajustar los hooks, tenemos que buscar cual es el que tiene
    // extra el arreglo de backup y agregarlo en el hook del select y
    // eliminarlo del backup
    let arrayToSelect = [];
    const registroToDelete = salaryTypesBack.filter(
      (e) =>
        e.keyTypeApplicationSalary ===
        parseInt(registros[index].descuentosAplicacionKeyTypeApplicationSalary)
    );
    arrayToSelect.push(registroToDelete[0]);
    if (selectsData.applicationSalaryTypes.length > 0) {
      selectsData.applicationSalaryTypes.map((e) => {
        arrayToSelect.push(e);
      });
    }
    setSelectsData((prevState) => ({
      ...prevState,
      applicationSalaryTypes: arrayToSelect,
    }));
    const registroToBack = salaryTypesBack.filter(
      (e) =>
        e.keyTypeApplicationSalary !==
        parseInt(registros[index].descuentosAplicacionKeyTypeApplicationSalary)
    );
    setSalaryTypesBack(registroToBack);
    const excludeDateIntervals = excludeDates.filter(function (e) {
      return (
        formatDate(e.start) !==
        formatDate(registros[index].descuentosAplicacionStartDate)
      );
    });
    setExcludeDates(excludeDateIntervals);
    var flagShow = true;
    var enabledDiscounts = 0;
    if (registros.length > 0) {
      registros.map((e) => {
        if (
          e.enabled === 1 &&
          e.descuentosAplicacionKeyTypeApplicationSalary !== "1"
        ) {
          enabledDiscounts++;
        }
        if (
          e.descuentosAplicacionKeyTypeApplicationSalary === "1" &&
          e.enabled === 1
        ) {
          flagShow = false;
        }
      });
      if(setShowSection){
        if (enabledDiscounts === 0) {
          setShowSection(false);
        } else if (flagShow) {
          setShowSection(true);
        } else if (!flagShow && enabledDiscounts > 0) {
          setShowSection(true);
        } else if (!flagShow) {
          setShowSection(false);
        }
      }
    }
  };

  useEffect(() => {
    setLoading(false);
  }, [registros]);
  return (
    <div className="widget-table-overflow mt-3">
      <Table
        className={`table-striped table-borderless table-hover ${s.statesTable}`}
        responsive
      >
        <thead className="border-bottom border-secondary">
          <tr className={sc.userSelectNone}>
            <th className="text-center">Tipo de deducción</th>
            <th className="text-center">Fecha de inicio</th>
            <th className="text-center">Fecha final</th>
            <th className="text-center">No. Dias</th>
            <th className="text-center">Eliminar</th>
          </tr>
        </thead>
        <tbody>
          {loading ? (
            <tr key={"loading"}>
              <td colSpan={4}>
                <Loader />
              </td>
            </tr>
          ) : (
            <>
              {registros.map((e, index) => {
                if (e.enabled === 1) {
                  var nameApplication = "";
                  salaryTypesBack.map((s) => {
                    if (
                      s.keyTypeApplicationSalary ===
                      parseInt(e.descuentosAplicacionKeyTypeApplicationSalary)
                    ) {
                      nameApplication = s.nameTypeApplicationSalary;
                    }
                  });
                  if (nameApplication === "") {
                    selectsData.applicationSalaryTypes.map((d) => {
                      if (
                        d.keyTypeApplicationSalary ===
                        parseInt(e.descuentosAplicacionKeyTypeApplicationSalary)
                      ) {
                        nameApplication = d.nameTypeApplicationSalary;
                      }
                    });
                  }

                  return (
                    <tr key={"rtd"+index}>
                      <td className="text-center">{nameApplication}</td>
                      <td className="text-center">
                        {formatDate(e.descuentosAplicacionStartDate)}
                      </td>
                      <td className="text-center">
                        {formatDate(e.descuentosAplicacionEndDate)}
                      </td>
                      <td className="text-center">
                        {e.descuentosAplicacionNoDays}
                      </td>
                      <td
                        className="text-center"
                        onClick={() => muestraRegistro(index)}
                      >
                        <i className="fa fa-trash text-danger cursor-pointer" />
                      </td>
                    </tr>
                  );
                }
              })}
            </>
          )}
        </tbody>
      </Table>
    </div>
  );
};

export default TableComponentDiscounts;

import { Document, Page, Text, View, StyleSheet } from "@react-pdf/renderer";
import { styles } from "../../../../components/Pdf/pdfGeneral/EstiloPdf";
import HeaderReport from "../../../../components/Pdf/Header";

const formatter = new Intl.NumberFormat("es-MX", {
  style: "currency",
  currency: "MXN",
});

const dateformatter = (fecha) => {
  let date = new Date (fecha)
  return `${date.getDate()}/${date.getMonth()}/${date.getFullYear()}`
}

const formatField = (emp, conf) => {
  let ff = emp[conf.value];
  switch (conf.format) {
    case "moneda":
      ff = formatter.format(ff);
      break;
    case "fecha":
      if(isNaN(ff)){
        ff = dateformatter(ff) 
      }
    break;
    default:
      break;
  }
  return ff;
};

const s = StyleSheet.create({
  tableColHeaderNP: { 
    width: "25%",
    height: 20, 
    borderStyle: "solid", 
    borderWidth: 1, 
    backgroundColor: "#646464",
    color:'white',
    textAlign: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 9 ,
  }, 
  tableColHeaderReport: { 
    width: "75%", 
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    backgroundColor: "#646464",
    color:'white',
    textAlign: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 9 ,
  },
  tableColContentNP: { 
    width: "25%", 
    borderStyle: "solid", 
    borderWidth: 1, 
    borderTopWidth: 0 ,
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  }, 
  tableColContentReport: { 
    width: "75%", 
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0 ,
  },
  columnOne: { 
    width: "50%", 
    fontSize: 8 ,
    flexDirection: "row" ,
    justifyContent: 'center',
    alignItems: 'center',
  },
  cellThreeColumn: { 
    width: "96.3%", 
    fontSize: 8 ,
  },
  displayContent: { 
    paddingLeft: 9, //centra el el content
    marginVertical: 4,
    flexDirection: "row",
    justifyContent: 'space-around',
  }, 

})


export function generatePDFVacantes(data, model, title, fileNo, cont) {
  const ori = model.orientation === "h" ? "landscape" : "portrait";

  model.content[0].shift(); //SE ELIMINA EL PRIMER ATTR QUE SE PRESENTA EN EXCEL
  
  //model.id
  return {
    report: (
      <>
        <Document>
          
          <Page style={styles.body} orientation={ori}>
            <HeaderReport/>
            <View>
              <Text style={styles.title}>{title}</Text>
            </View>
            <View>
              <Text style={styles.subTitle}>{model.period}</Text>
            </View>
            <View style={styles.table}>
              {/* Cabeceras */}
              <View style={styles.tableRow} fixed>
                <View style={s.tableColHeaderNP} fixed>
                  <Text>{model.headers[0]}</Text>
                </View>
                <View style={s.tableColHeaderReport} fixed>
                  <Text>DESCRIPCIÓN</Text>
                </View>
              </View>
              {data.length > 0 &&
                data.map(item => (
                  <View style={styles.tableRow} wrap={false}>
                    <View style={s.tableColContentNP}>
                      <Text style={s.columnOne}>
                        {item["numberJobPositions"]}
                      </Text>
                    </View>

                    <View style={s.tableColContentReport}>
                      {model.content[0].map((att) => (
                        <View style={s.displayContent}>
                          <Text
                            style={s.cellThreeColumn }
                          >
                            <Text style={styles.textBold}>
                              {att.header}:{" "}
                            </Text>
                            {formatField(item, att)}
                          </Text>
                        </View>
                      ))}
                    </View>
                  </View>
                ))}
            </View>

            <View fixed style={styles.spaceFooter}></View>
            <View fixed style={styles.footer}>
              <Text style={styles.textFooter}>{fileNo}</Text>
              <Text
                style={styles.textFooter}
                render={({ pageNumber, totalPages }) =>
                  `PAGINA : ${pageNumber} / ${totalPages}`
                }
              />
            </View>
          </Page>
        </Document>
      </>
    ),
  };
}

import * as XlsxPopulate from "xlsx-populate";
import { saveAs } from "file-saver";
import { peticionesReceiver } from "../../../../helpers/peticionesReceiver";
import { toast } from "react-toastify";
import Notification from "../../../../components/Notification/Notification";

const API = peticionesReceiver();


const getStaff = async (data) => {
  let staff = [];
  const params = {
    action: "getStaffData",
    idStaff: data[0].idStaff,
  };
  await API.peticionEndPoint(params, "app/facades/reports/reportsF.php")
    .then((res) => {
      if (res.status === 200) {
        staff = res.data.data[0];
      } else {
        toast(
          <Notification
            type={"consultar_error"}
            backMessage={res.data.message}
            withIcon
          />
        );
      }
    })
    .catch((err) => {
      toast(<Notification type={"consultar_servidor_error"} withIcon />);
    });
  return staff;
};

const formatter = new Intl.NumberFormat("es-MX", {
  style: "currency",
  currency: "MXN",
});

const styles = {
  title: {
    bold: true,
  },
  headerBorder: {
    bold: "true",
    border: true,
    fill: "BFBFBF",
  },
  header: {
    bold: "true",
    border: true,
    fill: "BFBFBF",
  },
  headerBoderBold: {
    bold: "true",
    border: true,
  },
  contentBorder: {
    border: true,
  },
};

export async function saveReport8AsExcel(report, headers, title, employee) {
  // peticion y al finalizar llama a generateExcel (Main)
  let staff = await getStaff(report);
  generateExcel(report, headers, title,staff);
}

const generateExcel = (report, headers, title, staff) => {
  //Inicio de excel
  XlsxPopulate.fromBlankAsync().then(async (workbook) => {
    const sheet1 = workbook.sheet(0);

    //Titulos
    sheet1.range("B2:E2").merged(true).value("SAPP").style(styles.title);
    sheet1.range("B3:E3").merged(true).value(title).style(styles.title);

    sheet1
      .range("B4:C4")
      .merged(true)
      .value("Clave del servidor publico")
      .style(styles.header);
    sheet1
      .range("D4:E4")
      .merged(true)
      .value(report[0].nameStaff)
      .style(styles.title);
    sheet1.range("B5:C5").merged(true).value("Puesto").style(styles.header);
    sheet1
      .range("D5:E5")
      .merged(true)
      .value(staff.staffNumber)
      .style(styles.title);
    sheet1
      .range("B6:C6")
      .merged(true)
      .value("Adscripcion")
      .style(styles.header);
    sheet1
      .range("D6:E6")
      .merged(true)
      .value(staff.nameArea)
      .style(styles.title);

    sheet1.column("A").width(30);
    sheet1.column("B").width(30);
    sheet1.column("C").width(30);
    sheet1.column("D").width(30);
    sheet1.column("E").width(30);
    sheet1.column("F").width(30);
    sheet1.column("G").width(30);
    sheet1.column("H").width(30);

    const data = accumulated(report);
    sheet1.range("B7:E7").value(data.headers).style(styles.headerBorder);
    sheet1.cell("B8").value(data.accumulated).style(styles.contentBorder);

    const cellTotals = data.accumulated.length + 8; //celda inicial

    sheet1
      .range(`B${cellTotals}:C${cellTotals}`)
      .merged(true)
      .value(data.totals[0])
      .style(styles.headerBoderBold);
    sheet1
      .cell(`D${cellTotals}`)
      .value(data.totals[1])
      .style(styles.contentBorder);
    sheet1
      .cell(`E${cellTotals}`)
      .value(data.totals[2])
      .style(styles.contentBorder);
    sheet1
      .range(`B${cellTotals + 1}:D${cellTotals + 1}`)
      .merged(true)
      .value("Total Neto")
      .style(styles.headerBoderBold);
    sheet1
      .cell(`E${cellTotals + 1}`)
      .value(data.totals[3])
      .style(styles.contentBorder);

    //Inserta info del usuario
    /*       insertStaff(sheet1,staff); */

    //inserta percepciones y deducciones
    /* const perceptionsDeductions = getPerceptionsDeductions(report) */
    /* insertPerceptionsDeductions(sheet1,perceptionsDeductions) */
    /* De alguna forma fallaba al pasar el array */

    //da un estilo general a las celdas
    const rangoUsado = sheet1
      .usedRange()
      .style("horizontalAlignment", "center");
    return workbook.outputAsync().then((res) => {
      saveAs(res, `${title}.xlsx`);
    });
  });
};

//Agrega las celdas de total

const accumulated = (report) => {
  const type = {
    headers: [["Clave", "Concepto", "Percepciones", "Deducciones"]],
    accumulated: [],
    totals: ["Total"],
  };
  let totalPerceptions = 0,
    totalDeductions = 0;
  report.map((obj) => {
    type.accumulated.push([
      obj.keyRollUnder,
      obj.nameUnder,
      obj.keyTypeUnder === "1" ? formatter.format(obj.amount) : "$0.00",
      obj.keyTypeUnder === "2" ? formatter.format(obj.amount) : "$0.00",
    ]);
    totalPerceptions += obj.keyTypeUnder === "1" ? +obj.amount : 0;
    totalDeductions += obj.keyTypeUnder === "2" ? +obj.amount : 0;
  });
  type.totals.push(formatter.format(totalPerceptions));
  type.totals.push(formatter.format(totalDeductions));
  type.totals.push(formatter.format(totalPerceptions - totalDeductions));
  return type;
};

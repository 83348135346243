import { peticionesReceiver } from "../../../../helpers/peticionesReceiver";
import { toast } from "react-toastify";
import Notification from "../../../../components/Notification/Notification";

export default async function getPartidasOrigen(annualBudget, idStateFund, keyChapter, setPartidas) {
    if (annualBudget && idStateFund && keyChapter) {
        const API = peticionesReceiver();
        const params = {
            action: "getExpensesOrigins",
            idAnnualBudget: annualBudget.id,
            idStateFund,
            keyChapter
        };
        API.peticionEndPoint(params, "app/facades/budgets/budgetsF.php")
            .then((res) => {
                if (res.status === 200) {
                    setPartidas(res.data.data);
                } else {
                    toast(
                        <Notification
                            type={"consultar_error"}
                            backMessage={res.data.message}
                            withIcon
                        />
                    );
                }
            })
            .catch((err) => {
                toast(<Notification type={"consultar_servidor_error"} withIcon />);
            });
    }
}
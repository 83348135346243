import { useSelector } from "react-redux";
import Widget from "../../../components/Widget/Widget";
import { GetPermissions } from "../../../libs/permissions/getPermissions";
import Consulta from "./Consulta";
import Detalle from "./Detalle";
import { useState } from "react";
import { Button } from "reactstrap";

export default function RecepcionTraspasos(){
    const [permissions, setPermissions] = useState({ LOD: true });
    GetPermissions(setPermissions);

    const [info, setInfo] = useState({ id: "" });
    const { profileType } = useSelector((state) => state.userInfo);
    const idArea = profileType.selectProfile.idArea;

    return(
        <Widget className="widget-p-md">
        <div className="mt-2">
            <h3>Recepción de Traspasos</h3>
            {
                info.id &&
                <div className="d-flex flex-md-row flex-column justify-content-md-between">
                    <p className="mt-1">
                        <strong>Recepción: </strong>
                        {info.name}
                    </p>
                    <Button onClick={()=>setInfo({id: ""})} className="mt-1">Regresar</Button>
                </div>
            }

            {
                info.id ?
                <Detalle info={info} setInfo={setInfo} permissions={permissions} /> :
                <Consulta idArea={idArea} permissions={permissions} setInfo={setInfo}/>
            }
        </div>

        </Widget>
    )
}
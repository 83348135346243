/**
 * @param params setters from Format
 */
export function setConfigR51(params) {
    const { setHeaders, setPdfConfig, setExcelConfig, setHtmlConfig, setData, filter } = params;
    const headers = [
        "IMPORTE"
    ]
    setHeaders(headers)
    const tempData = {
        action: "reporteClasificadorFuncionalDelGasto",
        year: (new Date(filter.year)).getFullYear(),
    }
    setData(tempData);
    const content = [
        { header: "IMPORTE", value: "montoTotal" },
    ];
    setHtmlConfig({
        year: (new Date(filter.year)).getFullYear()
    })
    setPdfConfig({
        year: (new Date(filter.year)).getFullYear()
    });
    setExcelConfig({
        year: (new Date(filter.year)).getFullYear()
    });
}
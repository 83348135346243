import * as Yup from "yup";
import { Col, Row, Button } from "reactstrap";
import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { useSelector } from "react-redux";
import DatePicker from "react-datepicker";

import { PERIOD } from "./regex";
import Loader from "../../../components/Loader/Loader";
import ModalTableVer from "../../../components/Modal/ModalTableVer";
import ModalConfirmation from "../../../components/Modal/ModalConfirmation";
import {
  TextInput,
  SelectTypeHeadSingle,
} from "../../../components/GenericInputsFormik/index";
import { GetPermissions } from "../../../libs/permissions/getPermissions";
import { useHistory } from "react-router";
import { toast } from "react-toastify";
import Notification from "../../../components/Notification/Notification";
import { peticionesReceiver } from "../../../helpers/peticionesReceiver";

const FormCalculoExtraordinarios = ({ crear, data }) => {
  const [permissions, setPermissions] = useState(null);
  const [perceptions, setPercepctions] = useState([]);
  GetPermissions(setPermissions);
  const history = useHistory();

  const API = peticionesReceiver();

  const defaultForm = {
    period: 0,
    year: new Date(),
    idStaff: "",
    staffFullName: "",
    idPerceptionExtra:""
  };
  const filtroModal = [true, true, true, true, true];
  const { id } = useSelector((state) => state.userInfo);
  const date = new Date().getFullYear();

  const parametrosModal = {
    action: "datatable",
    table:
      "staff STF INNER JOIN employtypes AS EMT ON STF.keyEmployType = EMT.keyEmployType INNER JOIN jobstaff AS JBS ON JBS.idStaff = STF.idStaff INNER JOIN areas AS AR ON AR.idArea = JBS.idArea INNER JOIN jobtypes AS JBT ON JBT.keyJobType = STF.keyEmployType",
    rows: "STF.idStaff,STF.staffNumber,CONCAT(STF.name,' ',STF.pName,' ',STF.mName) as fullName,AR.name,JBT.nameJobType",
    conditions: `JBS.valid = 1 AND STF.enabled = 1 AND STF.keyStat  = 1 AND JBS.enabled = 1 AND NOT STF.idStaff = ${id}`,
    page: 0,
    records: 5,
    search: "",
    order: "",
  };

  const cabecerasModal = [
    "#",
    "Núm. Empleado",
    "Nombre",
    "Unidad de trabajo",
    "Puesto",
    "Seleccionar",
  ];

  const [form, setForm] = useState(defaultForm);
  const [modal, setModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [modalTabla, setModalTabla] = useState(false);

  const OnChangeSelect = (event) => {
    const { name, value } = event.target;
    setFieldValue(name, value);
  };

  const FormSchema = Yup.object().shape({
    period: Yup.number()
      .moreThan(0, "Favor de seleccionar un periodo")
      .required("Favor de seleccionar un periodo"),

    year: Yup.string()
      // .moreThan(0,"Favor de ingresar el Año de calculo")
      .min(4, "El Año debe estar constituido por 4 digitos")
      .required("Favor de ingresar el Año de calculo"),
    idPerceptionExtra:Yup.string()
    .required("Seleccione una percepcion"),
  });

  const selectStaff = (el) => {
    setFieldValue("idStaff", el[0]);
    setFieldValue("staffFullName", `${el[2]} - No. Empleado: ${el[1]}`);
    setModalTabla(false);
  };

  const onChangeDate = (e) => {
    setFieldValue(e.name, e.value);
  };

  const sendInfo = async () => {
    if (Object.keys(errors).length === 0) {
      setIsLoading(true);
      const result = await crear(values);
      if (result === false) {
        setIsLoading(false);
      } else {
        setIsLoading(false);
        resetForm();
      }
    }
  };

  const {
    handleSubmit,
    values,
    handleBlur,
    errors,
    touched,
    setFieldValue,
    resetForm,
  } = useFormik({
    initialValues: form,
    onSubmit: () => {
      setModal(true);
    },
    validationSchema: FormSchema,
  });

  // useEffect(() => {
  //   if (permissions !== null && !permissions.INS) history.push("/template");
  // }, [permissions]);
  useEffect(() => {
    getUnders();
  }, []);

  const getUnders = async () => {
    const data = {
      action: "multiselect",
      table: "perceptionsextras",
      rows: "idPerceptionExtra, namePerceptionExtra as name",
      conditions: "enabled = 1",
      order: "namePerceptionExtra asc",
    };
    await API.peticion(data)
      .then((res) => {
        if (res.status === 200 && res.data.code === "200") {
          setPercepctions(res.data.data);
        } else {
          toast(<Notification type={"consultar_error"} withIcon />);
        }
      })
      .catch((err) => {
        toast(<Notification type={"consultar_servidor_error"} withIcon />);
      });
  };

  const onChange = (e) => {
    const { name, value } = e.target;

    if (name === "startDate") {
      setFieldValue("endDate", "");
    }

    setFieldValue(name, value);
  };

  return (
    <div>
      {isLoading ? (
        <Loader />
      ) : (
        <form onSubmit={handleSubmit} className="mt-4" noValidate>
          <ModalConfirmation
            modalTitle={data ? "Editar" : "Crear"}
            modal={modal}
            setModal={setModal}
            crear={sendInfo}
            isEdit={false}
            values={values}
          >
            {data ? (
              <div className="d-flex justify-content-center">
                <h6>¿Está seguro de editar el registro?</h6>
              </div>
            ) : (
              <div className="d-flex justify-content-center">
                <h6>¿Desea continuar con el registro?</h6>
              </div>
            )}
          </ModalConfirmation>
          <div className="d-flex align-items-center">
            <p className="mr-2">Calculo: </p>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                height: "fit-content",
              }}
            ></div>
          </div>

          <Row className="mt-2">
            <Col>
              <SelectTypeHeadSingle
                label="Periodo"
                isRequired={true}
                inputName="period"
                optionsArray={PERIOD}
                defaultOption="Seleccione un periodo"
                onChangeMethod={OnChangeSelect}
                onBlur={handleBlur}
                isDisabled={false}
                value={values.period}
                touched={touched.period}
                errors={errors.period}
                optionValue="value"
                optionName="label"
              />
            </Col>

            <Col>
              <label htmlFor={values.year}>
                {" "}
                Año <span className="text-danger"> * </span>{" "}
              </label>
              <DatePicker
                minDate={new Date(`${date}`)}
                selected={values.year}
                onChange={(date) => onChangeDate({ name: "year", value: date })}
                showYearPicker
                dateFormat="yyyy"
                yearItemNumber={6}
                className={
                  "form-control" +
                  (errors.year && touched.year ? " is-invalid" : "")
                }
              />

              {touched.year && errors.year && (
                <span style={{ color: "red" }}>{errors.year}</span>
              )}
            </Col>
          </Row>

          <Row className="d-flex justify-content-center mt-3 ">
            <Col xs={12} md={8} lg={8}>
              <SelectTypeHeadSingle
                label="Seleccione una percepcion extraordinaria"
                isRequired={true}
                inputName="idPerceptionExtra"
                optionsArray={perceptions}
                defaultOption="Seleccione un concepto"
                onChangeMethod={onChange}
                value={values.idPerceptionExtra}
                optionValue="idPerceptionExtra"
                optionName="name"
                onBlurMethod={handleBlur}
                errors={errors.idPerceptionExtra}
                touched={touched.idPerceptionExtra}
              />
            </Col>
          </Row>

          <Row className="mt-2 align-items-center">
            {values.idStaff !== "" && (
              <Col>
                <TextInput
                  label="Empleado"
                  inputType="text"
                  inputName="staffFullName"
                  value={values.staffFullName}
                  isDisabled
                />
              </Col>
            )}

            <Col xs={12} md={12} lg={4}>
              <Button color="info" onClick={() => setModalTabla(true)}>
                Buscar Empleado
              </Button>
            </Col>
          </Row>

          <div className="row mt-3 justify-content-end">
            <div className="order-sm-1 text-center text-sm-right mb-4">
              <Button color="success" type="submit">
                Guardar
              </Button>
            </div>
          </div>

          <ModalTableVer
            modal={modalTabla}
            setModal={setModalTabla}
            title="Empleados"
            cabeceras={cabecerasModal}
            filtro={filtroModal}
            parametros={parametrosModal}
            selectRegistro={selectStaff}
            btnClose
          />
        </form>
      )}
    </div>
  );
};

export default FormCalculoExtraordinarios;

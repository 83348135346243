import * as XlsxPopulate from "xlsx-populate";
import { saveAs } from "file-saver";
import { number2Alphabet } from "../../../../libs/tools/strings";
import { sumValues } from "../../../../libs/tools/arrays";

/**
 * Formateador de numeros de concurrencias
 */
const formatter = new Intl.NumberFormat("es-MX", {
  style: "currency",
  currency: "MXN",
});

/**
 * Hoja de estilos del excel
 */
const styles = {
  title: {
    horizontalAlignment: 'center', bold: true
  },
  headerBorder: {
    bold: "true",
    border: true,
    fill: "BFBFBF",
  },
  header: {
    bold: "true",
    border: true,
    fill: "BFBFBF",
  },
  headerBoderBold: {
    bold: "true",
    border: true,
  },
  contentBorder: {
    border: true,
  },
  contentBorderB: {
    border: true,
    bold: true
  },
  contentBorderRigth: {
    border: true,
    horizontalAlignment: 'right'
  },
  contentBorderRigthB: {
    border: true,
    horizontalAlignment: 'right',
    bold: true
  },
};

/**
 * 
 * @param {Reporte entregado de la BD} report 
 * @param {Titulo (Nombre de la institución)} title 
 * @param {Parametros pasados a la BD para su consulta} data 
 * @param {configuración de headers} config 
 */
export async function saveReport43AsExcel(report, title, data, config) {


  //Inicio de excel
  XlsxPopulate.fromBlankAsync().then(async (workbook) => {
    const sheet1 = workbook.sheet(0);

    //Titulo
    sheet1
      .range("A1:F2")
      .merged(true)
      .value("SAPP")
      .style(styles.title);
    //Subtitulos
    sheet1
      .range("A3:F3")
      .merged(true)
      .value(title)
    sheet1
      .range("A4:F4")
      .merged(true)
      .value(`Quincena ${data.period} del Año ${data.year}`)
    
    //Cabeceras
    config.headers.forEach((header, index) => {
      sheet1
      .cell(`${number2Alphabet(index)}6`)
      .value(header)
      .style(styles.headerBoderBold)
    });
    
    const values = Object.values(report).map(value => value[0]);
    
    const totalSS = sumValues(values, "SS");
    const totalFSSR = sumValues(values, "FSSR");
    const totalAOSCI = sumValues(values, "AOSCI");
    const totalGA = sumValues(values, "GA");
    const totalRT = sumValues(values, "RT");
    const totales = ["TOTALES", totalSS, totalFSSR, totalAOSCI, totalGA, totalRT]
    
    //Data
    values.forEach((value, index) => {
      config.content.forEach((key, ind) => {
        sheet1
        .cell(`${number2Alphabet(ind)}${index + 7}`)
        .value(ind === 0 ? value[key.value] : formatter.format(value[key.value]))
        .style(ind === 0 ? styles.contentBorder : styles.contentBorderRigth)
      })
    })

    totales.forEach((value, ind) => {
      sheet1
      .cell(`${number2Alphabet(ind)}${values.length + 7}`)
      .value(ind === 0 ? value : formatter.format(value))
      .style(ind === 0 ? styles.contentBorderB : styles.contentBorderRigthB)
    })
    
    for (let index = 0; index < 6; index++) {
      if (index === 0) {
        sheet1.column(`${number2Alphabet(index)}`).width(20);  
      } else {
        sheet1.column(`${number2Alphabet(index)}`).width(30);
      }
    }
    
    return workbook.outputAsync().then((res) => {
        saveAs(res, `${title}.xlsx`);
    });
  });
}
import React, { useState } from "react";
import TableComponentCustom from "../../../components/TableComponent/TableComponentCustom";
import Widget from "../../../components/Widget/Widget";
import { Col, Button } from "reactstrap";
import Notification from "../../../components/Notification/Notification";
import { toast } from "react-toastify";
import { peticionesReceiver } from "../../../helpers/peticionesReceiver";
import Loader from "../../../components/Loader/Loader";
import {
  GetPermissions,
  checkSpecialColumn,
} from "../../../libs/permissions/getPermissions";

const HabilitatEdicion = () => {
  const [permissions, setPermissions] = useState({});
  GetPermissions(setPermissions);

  const API = peticionesReceiver();
  const [loader, setLoader] = useState(false);
  const cabeceras = [
    "Id",
    "UNIDAD RESPONSABLE",
    "NOMBRE DEL PROYECTO",
    "AÑO",
    "ESTATUS",
  ];
  const [parametros, setParametros] = useState({
    action: "datatable",
    table:
      "proyects A INNER JOIN areas B USING (idArea) INNER JOIN staff C on (C.idStaff = A.idStaffCharge)",
    rows: "A.idProyect,B.name,A.nameProyect,A.yearProyect,A.endDate,CONCAT(C.name,' ',C.pName,'',C.mName) as staffName, A.keyStat",
    conditions: `A.enabled = 1 and yearProyect = 2023 and A.keyStat = 68`,
    page: 0,
    records: 5,
    search: "",
    order: "",
  });
  const filtro = [true, true, true, true, false, false, false, false];

  const changeStatus = async (values) => {
    setLoader(true);
    const proyect = {
      idProyect: values[0],
      keyStat: values[1] === "66" ? 68 : 66,
    };
    const params = {
      action: "update",
      table: "proyects",
      rows: proyect,
      condition: {
        idProyect: values[0],
      },
    };

    await API.peticion(params)
      .then((res) => {
        if (
          res.status === "success" ||
          res.status === "200" ||
          res.status === 200
        ) {
          toast(
            <Notification
              type={"agrega_exito"}
              backMessage={res.data.message}
              withIcon
            />
          );
          setParametros({
            action: "datatable",
            table:
              "proyects A INNER JOIN areas B USING (idArea) INNER JOIN staff C on (C.idStaff = A.idStaffCharge)",
            rows: "A.idProyect,B.name,A.nameProyect,A.yearProyect,A.endDate,CONCAT(C.name,' ',C.pName,'',C.mName) as staffName, A.keyStat",
            conditions: `A.enabled = 1 and yearProyect = 2023 and A.keyStat = 68`,
            page: 0,
            records: 5,
            search: "",
            order: "",
          });
        } else {
          toast(
            <Notification
              type={"consultar_error"}
              backMessage={res.data.message}
              withIcon
            />
          );
        }
      })
      .catch((err) => {
        toast(<Notification type={"consultar_servidor_error"} withIcon />, {
          closeButton: false,
        });
      })
      .finally(() => {
        setLoader(false);
      });
  };

  const toogleButton = (value, key) => {
    /* return <i className=" fa fa-check-circle text-info"    key={key} onClick={() => changeStatus(value)}></i> */
    if (!permissions.UPD) return null;
    return (
      <Col xs={12} md={12} lg={8}>
        <Button color="success" key={key} onClick={() => changeStatus(value)}>
          Hablitar
        </Button>
      </Col>
    );
    /* return ( 
      <FormGroup
        style={{ cursor: "pointer" }}
        key={key}
        onClick={() => changeStatus(value)}
      >
        <input
          id="switchEnabled"
          type="checkbox"
          checked={value[6] === "66" ? true : false }
          label="Enable"
        />
        <label>Edición de proyecto</label>
      </FormGroup>
    ); */
  };

  return (
    <Widget className="widget-p-md">
      {loader ? (
        <Loader />
      ) : permissions.CON && (
        <TableComponentCustom
          titulo={"Habilitar edición de proyectos"}
          cabecerasTabla={checkSpecialColumn(
            cabeceras,
            [[4, "UPD"]],
            permissions
          )}
          filtro={filtro}
          parametros={parametros}
          specialColumns={[toogleButton]}
        />
      )}
    </Widget>
  );
};

export default HabilitatEdicion;


import React from "react";

import Widget from "../../../../components/Widget/Widget";
import FormFuentesF from "../FormFuentesF";
import { updateFinancialSources } from "../functions";
import { useHistory } from "react-router-dom";

export const EditarFuentesF = () => {
  const history = useHistory();

  const getData = localStorage.getItem('dataContent')
  const data = getData ? getData.split('|-|') : history.goBack()

  return (
    <Widget className="widget-p-md">
        <h3>Editar fuente de financiamiento</h3>
        <FormFuentesF data={data} editar={updateFinancialSources}/>
    </Widget>
  )
}

export default EditarFuentesF;
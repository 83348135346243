import { peticionesReceiver } from "../../../helpers/peticionesReceiver";
import Notification from "../../../components/Notification/Notification";
import { toast } from "react-toastify";
const API = peticionesReceiver();
export const agregar = async (usuario) => {
  const data = {
    action: "saveUser",
    rows: {...usuario},
  };
  delete data.rows.isStaff;
  API.peticionPerfilesMod(data)
    .then((res) => {
      if (res.status === 200 && res.data.status === "success") {
        toast(
          <Notification
            type={"agrega_exito"}
            backMessage={res.data.message}
            withIcon
          />
        );
        window.location.href = "#/template/usuarios";
      } else {
        toast(
          <Notification
            type={"agrega_error"}
            backMessage={res.data.message}
            withIcon
          />, {closeButton: false}
        );
      }
    })
    .catch((err) => {
      toast(<Notification type={"consultar_servidor_error"} withIcon />);
    });
};
// export const editar = async (usuario) => {
//   const data = {
//     action: "saveUser",
//     rows: usuario,
//   };
//   API.peticion(data).then((res) => {
//       if (res.status === 200 && res.data.code === "200") {
//         toast(<Notification type={"modifica_exito"} backMessage={res.data.message} withIcon />);
//         window.location.href ="#/template/usuarios"
//       }else{
//         toast(<Notification type={"modifica_error"} backMessage={res.data.message} withIcon />);
//       }
//     })
//     .catch((err) =>{
//     toast(<Notification type={"consultar_servidor_error"} withIcon />);
//   });
// };

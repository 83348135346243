import React, { useState } from "react";
import { Col, Row, Button } from "reactstrap";
import { TextInput } from "../../../../components/GenericInputsFormik/index";
import { SelectTypeHeadSingle } from "../../../../components/GenericInputsFormik/index";

const format = [
  {
    id: 1,
    value: "Pdf",
  },
  {
    id: 2,
    value: "Hoja de calculo",
  },
  {
    id: 3,
    value: "Html",
  },
];

const FormTrapasos = (props) => {
  const {
    report,
    onChange,
    handleBlur,
    values,
    touched,
    errors,
    setFieldValue,
    collapseReport,
  } = props;

  const clearDates = () => {
    setFieldValue("startDate", "");
    setFieldValue("endDate", "");
  };

  return (
    <>
      <Row className="d-flex justify-content-around ">
        <Col xs={12} md={12} lg={10} >
          <Row>
            <Col xs={12} lg={4}>
              <SelectTypeHeadSingle
                label="Tipo de formato"
                isRequired={true}
                optionsArray={format}
                inputName="idFormat"
                onChangeMethod={onChange}
                onBlurMethod={handleBlur}
                touched={touched.idFormat}
                errors={errors.idFormat}
                optionValue="id"
                optionName="value"
                value={values.idFormat}
                defaultOption="Seleccione un tipo de reporte"
              />
            </Col>
            <Col xs={12} lg={4}>
              <TextInput
                label="Fecha inicial"
                inputType="date"
                inputName="startDate"
                onChangeMethod={onChange}
                onBlurMethod={handleBlur}
                value={values.startDate}
                touched={touched.startDate}
                errors={errors.startDate}
              />
            </Col>
            <Col xs={12} lg={4}>
              <TextInput
                label="Fecha final"
                inputType="date"
                inputName="endDate"
                onChangeMethod={onChange}
                onBlurMethod={handleBlur}
                value={values.endDate}
                touched={touched.endDate}
                errors={errors.endDate}
                isMin={values.startDate}
                isDisabled={values.startDate === ""}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row >
        <Col xs={12} lg={12}>
          <div className="text-center my-3 d-flex justify-content-around">
            <Button color="success" type="submit">
              {collapseReport ? "Actualizar" : "Buscar"}
            </Button>
            <Button color="danger" onClick={() => clearDates()}>
              Limpiar
            </Button>
          </div>
        </Col>
      </Row>

    </>
  );
};

export default FormTrapasos;

import React from "react";

import Widget from "../../../../components/Widget/Widget";
import FormMunicipio from "../FormMunicipio";
import { updateCounty } from "../functions";

export const EditarMunicipio = (props) => {
  return (
    <Widget className="widget-p-md">
        <h3>Editar municipio</h3>
        <FormMunicipio data={localStorage.getItem('dataContent').split('|-|')} editar={updateCounty}/>
    </Widget>
  )
}

export default EditarMunicipio;
import Widget from "../../../../components/Widget/Widget";
import Datatable from "../../../../components/Datatable/Datatable";
import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import Notification from "../../../../components/Notification/Notification";
import { peticionesReceiver } from "../../../../helpers/peticionesReceiver";
import { Collapse, Button, Col, Row } from "reactstrap";
import { TextInput } from "../../../../components/GenericInputsFormik";
import { NumberInput } from "../../../../components/GenericInputsFormik/NumberInput";

export default function Recepciones({
  idArea,
  idStaff,
  permissions,
  reception,
  setCollapseP,
  setReception
}) {
  const [refresh, setRefresh] = useState(false);
  const [collapse, setCollapse] = useState(false);

  const [recepcions, setRecepcions] = useState([]);

  const API = peticionesReceiver();

  const headers = [
    "#",
    "Bien",
    "Cantidad Solicitada",
    "Cantidad Recibida",
    "Cantidad a recibir",
    "Pasillo",
    "Anaquel",
    "Posicion",
    "Seleccione",
  ];

  const columns = [
    { data: "index" },
    { data: "bien" },
    { data: "quantity" },
    {
      data: null,
      render: function (row) {
        return (
          row.totalReceptions
        );
      },
    },
    {
      data: null,
      render: function (row) {
        return (
          <>
            <NumberInput
              inputName="quantityToSend"
              value={
                recepcions[row.index - 1] !== undefined
                  ? recepcions[row.index - 1].quantityToSend
                  : ""
              }
              onChangeMethod={(e) => changeArrayValue(e, row)}
              disableSep
              negatives={false}
              isMin={1}
              decimals={false}
              isDisabled={
                +row.totalReceptions === +row.quantity}
            />
            {/* <TextInput
            inputType="text"
            inputName="quantityToSend"
            onChangeMethod={(e) => changeArrayValue(e, row)}
            value={
              recepcions[row.index - 1] !== undefined
                ? recepcions[row.index - 1].quantityToSend
                : ""
            }
          /> */}
          </>
        );
      },
    },
    {
      data: null,
      render: function (row) {
        return (
          <TextInput
            inputType="text"
            inputName="hall"
            onChangeMethod={(e) => changeArrayValue(e, row)}
            value={
              recepcions[row.index - 1] !== undefined
                ? recepcions[row.index - 1].hall
                : ""
            }
            isDisabled={+row.totalReceptions === +row.quantity}
          />
        );
      },
    },
    {
      data: null,
      render: function (row) {
        return (
          <TextInput
            inputType="text"
            inputName="shell"
            onChangeMethod={(e) => changeArrayValue(e, row)}
            value={
              recepcions[row.index - 1] !== undefined
                ? recepcions[row.index - 1].shell
                : ""
            }
            isDisabled={+row.totalReceptions === +row.quantity}
          />
        );
      },
    },
    {
      data: null,
      render: function (row) {
        return (
          <TextInput
            inputType="text"
            inputName="position"
            onChangeMethod={(e) => changeArrayValue(e, row)}
            value={
              recepcions[row.index - 1] !== undefined
                ? recepcions[row.index - 1].position
                : ""
            }
            isDisabled={+row.totalReceptions === +row.quantity}
          />
        );
      },
    },
    {
      data: null,
      render: function (row) {
        return (
          <input
            type="checkbox"
            name="reception"
            className="form-control"
            onChange={(e) => changeArrayValue(e, row)}
            value={
              recepcions[row.index - 1] !== undefined &&
              recepcions[row.index - 1].check !== ""
                ? recepcions[row.index - 1].check
                : false
            }
            checked={
              recepcions[row.index - 1] !== undefined &&
              recepcions[row.index - 1].check !== ""
                ? recepcions[row.index - 1].check
                : false
            }
            disabled={+row.totalReceptions === +row.quantity}
          />
        );
      },
    },
  ];

  

  const changeArrayValue = (e, row) => {
    const { name, value } = e.target;

    if (name === "reception") {
      let tmpList = recepcions.slice(0, recepcions.length);
      tmpList[+row.index - 1][name] = e.target.checked;
      setRecepcions(tmpList);
    } else {
      let tmpList = recepcions.slice(0, recepcions.length);
      tmpList[+row.index - 1][name] = value;
      setRecepcions(tmpList);
    }
  };

  const columnDefs = [
    {
      targets: [0, 1, 2, 3, 4, 5],
      className: "text-center",
    },
    {
      orderable: false,
      targets: [0],
    },
  ];

  const getParamsTableElmt = (order, records, page, search) => {
    const params = {
      action: "datatable",
      table:
        "receptions Z LEFT JOIN purchasewinners A on (Z.idWinner = A.idWinner) LEFT JOIN providerquotes B ON ( B.idProviderQuote = A.idProviderQuote)  LEFT JOIN quotesdetail C ON (B.idDetail = C.idDetail) LEFT JOIN furnitures D on (C.idFurniture = D.idFurniture)  LEFT JOIN warehouseincomereceptions E on (E.idReception = Z.idReception) LEFT JOIN warehousefifos F on (E.idIncomeReception = F.idIncomeReception) LEFT JOIN warehouseinventory G on (G.idInventory = F.idInventory)",
      rows: "Z.idReception, concat(D.nameFurniture,' ',D.model,' ',D.brand) as bien ,C.amount, Z.quantity,Z.idArea,C.idFurniture,E.idIncomeReception,ifnull(G.hall,'')  as hall, ifnull(G.shell,'')  as shell, ifnull(G.position,'')  as position,Z.idReception, (SELECT sum(incomeTotal) from warehouseincomereceptions  WHERE  idReception = Z.idReception  ) as  totalReceptions    ",
      conditions: ` Z.enabled = 1 and A.enabled = 1 and B.enabled = 1 AND C.enabled = 1 and D.enabled = 1 and  Z.idArea = ${idArea} and A.idPurchaseRequest = ${reception.idPurchaseRequest}  `,
      group: "Z.idReception",
      order,
      records: records.toString(),
      page: (page * records).toString(),
      search,
    };
    return params;
  };

  async function getData(order, records, page, search) {
    try {
      const res = await API.peticion(
        getParamsTableElmt(order, records, page, search)
      );
      if (res.status === 200 && res.data.code === "200") {
        const data = res.data;

        /* data.forEach((value, index) => (value.index = 1 + index)); */
        data.data.forEach((value, index) => (value.index = 1 + index));
        setRecepcions(data.data);
        return data;
      } else {
        toast(
          <Notification
            type={"consultar_error"}
            backMessage={res.data.message}
          />
        );
        return null;
      }
    } catch (err) {
      toast(<Notification type={"consultar_servidor_error"} withIcon />);
      return null;
    }
  }

  const sendReceptions = async () => {
    const receptions = clearReceptions();

    if (receptions.length > 0) {
      const params = {
        action: "saveReception",
        idAreaReceipt: idArea,
        idStaffReceive: idStaff,
        idPurchaseRequest: reception.idPurchaseRequest,
        service: "N",
        articles: receptions,
      };

      try {
        const res = await API.peticionEndPoint(
          params,
          "app/facades/warehouse/warehouseF.php"
        );
        if (res.status === 200 && res.data.code === "200") {
          toast(
            <Notification
              type={"agrega_exito"}
              backMessage={res.data.message}
            />
          );
          setRecepcions(res.data.data[0])
          setRefresh(true);


          setCollapseP("warehouse")

        } else {
          toast(
            <Notification
              type={"consultar_error"}
              backMessage={res.data.message}
            />
          );
        }
      } catch (err) {
        toast(<Notification type={"consultar_servidor_error"} withIcon />);
      }
    } else {
      /* toast(
        <Notification
          type={"elimina_error"}
          backMessage={`No ha seleccionado ninguna entrada`}
          withIcon
        />
      ); */
    }
  };

  const clearReceptions = () => {
    let recep = [];
    recepcions.forEach((rep) => {
      if (rep.reception) {
        if (
          rep.quantityToSend !== "" &&
          rep.quantityToSend !== null &&
          rep.quantityToSend !== undefined &&
          rep.quantityToSend !== 0 &&
          rep.quantityToSend !== "0" &&
          rep.hall !== "" &&
          rep.hall !== null &&
          rep.hall !== undefined &&
          rep.shell !== "" &&
          rep.shell !== null &&
          rep.shell !== undefined &&
          rep.position !== "" &&
          rep.position !== null &&
          rep.position !== undefined
        ) {
          recep.push({
            idReception: rep.idReception,
            quantity: rep.quantityToSend,
            hall: rep.hall,
            shell: rep.shell,
            position: rep.position,
          });
        } else {
          toast(
            <Notification
              type={"elimina_error"}
              backMessage={`Favor verificar que el registro ${rep.index} haya sido llenado`}
              withIcon
            />
          );
          return [];
        }
      }
    });
    return recep;
  };

  return (
    <div>
      <Collapse isOpen={!collapse}>
        <div className="mb-2 text-dark d-flex flex-md-row flex-column justify-content-md-between">
          <div className="d-flex flex-column  ">
            <span style={{ fontSize: "18px" }}>
              <strong>PROVEEDOR:</strong> {reception.socialName}
            </span>
            <span style={{ fontSize: "18px" }}>
              <strong>RFC:</strong> {reception.itin}
            </span>
            <span style={{ fontSize: "18px" }}>
              <strong>COMPRA:</strong> {reception.paymentDetail}
            </span>
          </div>
          {permissions.INS && (
            <Row>
              <Col>
                <div className="text-right mb-3">
                  <Button
                    onClick={() => {
                      sendReceptions();
                    }}
                  >
                    Guardar
                  </Button>
                </div>
              </Col>
            </Row>
          )}
        </div>

        <Datatable
          headers={headers}
          columns={columns}
          columnDefs={columnDefs}
          petition={getData}
          control="back"
          stateRefresh={[refresh, setRefresh]}
          searching={true}
          order={{ col: 1, opt: "desc" }}
        />
      </Collapse>
    </div>
  );
}

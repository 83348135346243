import { peticionesReceiver } from "./peticionesReceiver";
const API = peticionesReceiver();

const getActions = async(userId,keyProfile,fileRoute)  => {
    const params = {
        action: "select",
        table: "modules",
        condition: {
            enabled: 1,
            fileRoute: `/${fileRoute.toLowerCase()}`
        },
    }
    const result = await API.peticion(params)
    if(result.status === 200 && result.data.status === 'success') {
        const idModule = result.data.data[0].idModule

        const data = {
            action: "multiselect",
            table:"permissions AS PER INNER JOIN profilemodules PFM ON PFM.keyProfile = PER.keyProfile INNER JOIN modules AS MDS ON MDS.idModule = PFM.idModule",
            rows: "PFM.INS,PFM.DEL,PFM.CON,PFM.UPD",
            conditions:`PER.idUser = ${userId} AND PFM.idModule = ${idModule} AND PFM.enabled = 1 AND PFM.keyProfile = ${keyProfile}`,
        };

        const response = await API.peticion(data)
        if(response.status === 200 && response.data.status === 'success'){
            const { CON,DEL,INS,UPD } = response.data.data[0]
            if(CON === '0' && DEL === '0' && INS === '0' && UPD === '0'){
                window.location.href = "#/template";
            }
            else{
                return { success: true, actions: response.data.data[0] }
            }

        }
        else {
            window.location.href = "#/template";
            return { success: false, message: 'No se encontro acciones para este modulo' }
        }

    }
    else{
        window.location.href = "#/template";
        return { success: false, message: 'No se encontro el modulo solicitado' }
    } 
}

export default getActions
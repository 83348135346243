/* eslint-disable react-hooks/exhaustive-deps */
import React,{ useEffect,useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { Button, Col, Row } from "reactstrap";
import { useHistory } from "react-router-dom";

import { peticionesReceiver } from "../../../helpers/peticionesReceiver";
import { peticionEncript } from "../../../helpers/peticionesEncripted";

import { generatePdfPresupuestoAnual } from "./Pdf/presupuestosAnualesPdf";

import Loader from "../../../components/Loader/Loader";

import { saveAsExcelPresupuestos } from "./Excel/PresupuestosDepentientes";
import ModalReports from "../../../components/Modal/ModalReports";
import Notification from "../../../components/Notification/Notification";
import {SelectTypeHeadSingle } from "../../../components/GenericInputsFormik";
import TableComponentPresupuestosDetallados from "../../../components/TableComponent/TableComponentPresupuestosDetallado";


const PresupuestosDependientes = () => {
  const API = peticionesReceiver();
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [modalReports, setModalReports] = useState(false);
  const [searchFilters, setSearchFilters] = useState({
    statesFunds: "",
    expensesObjects: "",
    financialSources: "",
  });
  const [form, setForm] = useState({
    idStateFund: "",
    idExpenseObject: "",
    idFinancialSource: "",
  });

  const cabecerasTabla = [
    "No",
    "Proyecto presupuestales",
    "Fuente de financiamiento",
    "Partida",
    "Total",
    "Detalles"
  ];

  const cabeceras = [
    "Proyecto presupuestales",
    "Fuente de financiamiento",
    "Partida",
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Octubre",
    "Noviembre",
    "Diciembre",
    "Total",
  ];
  const [parametros, setParametros] = useState({
    action: "getBudgets",
    page: "0",
    records: "5",
    search: "",
    order: "2 desc",
    idAnnualBudget: Number(localStorage.getItem("idDetail")),
    conditions: {
      idStateFund: form.idStateFund,
      idExpenseObject: form.idExpenseObject,
      idFinancialSource: form.idFinancialSource,
    },
  });

  const parametrosExcel = {
    action: "getBudgets",
    /* page: "0",
    records: "10", */
    idAnnualBudget: Number(localStorage.getItem("idDetail")),
    conditions: {
      idStateFund: form.idStateFund,
      idExpenseObject: form.idExpenseObject,
      idFinancialSource: form.idFinancialSource,
    },
  }

  const [report, setReport] = useState("");

  const [filter] = useState([
    true,
    true,
    true,
    true,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    true,
  ]);

  const [pdfConfig, setPdfConfig] = useState();

  const getFinancialsSources = async () => {
    const params = {
      action: "select",
      table: "financialsources",
      condition: {
        enabled: 1,
      },
      order: "nameFinancialSource ASC",
    };
    API.peticion(params)
      .then((res) => {
        if (res.status === 200 && res.data.code === "200") {
          setSearchFilters((prevState) => ({
            ...prevState,
            financialSources: res.data.data,
          }));
        } else {
          toast(<Notification type={"consultar_error"} withIcon />);
        }
      })
      .catch((err) => {
        toast(<Notification type={"consultar_servidor_error"} withIcon />);
      });
  };

  const getStatesFunds = async () => {
    const params = {
      action: "select",
      table: "statefunds",
      condition: {
        enabled: 1,
      },
      order: "nameStateFund ASC",
    };
    API.peticion(params)
      .then((res) => {
        if (res.status === 200 && res.data.code === "200") {
          setSearchFilters((prevState) => ({
            ...prevState,
            statesFunds: res.data.data,
          }));
        } else {
          toast(<Notification type={"consultar_error"} withIcon />);
        }
      })
      .catch((err) => {
        toast(<Notification type={"consultar_servidor_error"} withIcon />);
      });
  };

  const getExpensesObjects = async () => {
    const params = {
      action: "select",
      table: "expense_objects",
      condition: {
        enabled: 1,
      },
      order: "nameObject ASC",
    };
    API.peticion(params)
      .then((res) => {
        if (res.status === 200 && res.data.code === "200") {
          setSearchFilters((prevState) => ({
            ...prevState,
            expensesObjects: res.data.data,
          }));
          setLoading(false);
        } else {
          toast(<Notification type={"consultar_error"} withIcon />);
        }
      })
      .catch((err) => {
        toast(<Notification type={"consultar_servidor_error"} withIcon />);
      });
  };

  useEffect(() => {
    if (localStorage.getItem("idDetail") === undefined) {
      history.push({
        pathname: "/template/presupuestos",
      });
    } else {
      getFinancialsSources().finally(() => {
        getStatesFunds().finally(() => {
          getExpensesObjects();
        });
      });
    }
  }, []);

  const editaForm = (name, value) => {
    console.log(name, value);
    setParametros((prevState) => ({
      ...prevState,
      conditions: {
        idStateFund: name === "idStateFund" ? value : form.idStateFund,
        idExpenseObject:
          name === "idExpenseObject" ? value : form.idExpenseObject,
        idFinancialSource:
          name === "idFinancialSource" ? value : form.idFinancialSource,
      },
    }));
  };

  const OnChangeSelect = event => {
    const { name,value } = event.target
    setForm((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    editaForm(name, value);
  };

  const regresar = () => {
    history.push({
      pathname: "/template/presupuestos",
    });
  };

  useEffect(() => {
    if (report === "") {
      getData();
    }
  }, [report]);
 
  const getData = async () => {
    const finalData = peticionEncript(parametrosExcel);
    await axios
      .post(
        `${process.env.REACT_APP_API}app/facades/budgets/budgetsF.php`,
        finalData
      )
      .then((res) => {
        if (res.status === 200 && res.data.code === "200") {
          setReport(res.data.data);
        }
      })
      .catch((err) => {
        toast(<Notification type={"consultar_servidor_error"} withIcon />);
        setLoading(false);
      });
    /* setPdfConfig({
      headers: [
        "No",
        "Proyecto presupuestales",
        "Fuente de financiamiento",
        "Partida",
        "Enero",
        "Febrero",
        "Marzo",
        "Abril",
        "Mayo",
        "Junio",
        "Julio",
        "Agosto",
        "Septiembre",
        "Octubre",
        "Octubre",
        "Noviembre",
        "Diciembre",
        "Total",
      ],
      id: { id: "idBudget" },
      title: "REPORTE DE PRESUPUESTO ANUAL",
      orientation: "h",
      content: [
        [
          {
            header: "Proyecto presupuestales",
            value: "nameStateFund",
            columns: 2,
          },
          {
            header: "Fuente de financiamiento",
            value: "nameFinancialSource",
            columns: 2,
          },
          {
            header: "Partida",
            value: "nameObject",
            columns: 2,
          },
          { header: "Enero", value: "m1", columns: 2 },
          { header: "Febrero", value: "m2", columns: 2 },
          { header: "Marzo", value: "m3", columns: 2 },
          { header: "Abril", value: "m4", columns: 2 },
          { header: "Mayo", value: "m5", columns: 2 },
          { header: "Junio", value: "m6", columns: 2 },
          { header: "Julio", value: "m7", columns: 2 },
          { header: "Agosto", value: "m8", columns: 2 },
          { header: "Septiembre", value: "m9", columns: 2 },
          { header: "Octubre", value: "m10", columns: 2 },
          { header: "Noviembre", value: "m11", columns: 2 },
          { header: "Diciembre", value: "m12", columns: 2 },
          { header: "Total", value: "total", columns: 2 },
        ],
      ],
    }); */

    setPdfConfig({
      headers: [
        "No",
        "Proyecto presupuestales",
        "Fuente de financiamiento",
        "Partida",
        "Enero",
        "Febrero",
        "Marzo",
        "Abril",
        "Mayo",
        "Junio",
        "Julio",
        "Agosto",
        "Septiembre",
        "Octubre",
        "Octubre",
        "Noviembre",
        "Diciembre",
        "Total",
      ],
      id: { id: "idBudget" },
      title: "REPORTE DE PRESUPUESTO ANUAL",
      orientation: "h",
      content: [
          [{
            header: "Proyecto presupuestales",
            value: "nameStateFund",
            columns: 3,
          }],
          [{
            header: "Fuente de financiamiento",
            value: "nameFinancialSource",
            columns: 3,
          }],
          [{
            header: "Partida",
            value: "nameObject",
            columns: 3,
          }],
          [{ header: "Enero", value: "m1", columns: 1, format: "moneda" },
          { header: "Febrero", value: "m2", columns: 1, format: "moneda" },
          { header: "Marzo", value: "m3", columns: 1, format: "moneda" }],
          [{ header: "Abril", value: "m4", columns: 1, format: "moneda" },
          { header: "Mayo", value: "m5", columns: 1, format: "moneda" },
          { header: "Junio", value: "m6", columns: 1, format: "moneda" }],
         [ { header: "Julio", value: "m7", columns: 1, format: "moneda" },
          { header: "Agosto", value: "m8", columns: 1, format: "moneda" },
          { header: "Septiembre", value: "m9", columns: 1, format: "moneda" }],
          [{ header: "Octubre", value: "m10", columns: 1, format: "moneda" },
          { header: "Noviembre", value: "m11", columns: 1, format: "moneda" },
          { header: "Diciembre", value: "m12", columns: 1, format: "moneda" }],
          [{ header: "Total", value: "total", columns: 1, format: "moneda" }],
      ],
    });
  
  
  };

  const crearExcel = () => {
    if(report !== null && report !== undefined && report !== "" ){
      saveAsExcelPresupuestos(report, cabeceras, "Presupuestos Anuales", pdfConfig, "");
    }else{
      toast(<Notification type={"consultar_error"} backMessage={"NO SE ENCONTRARON DATOS"} withIcon />);
    }
  };
  const crearPdf = () => {
    if(report !== null && report !== undefined && report !== "" ){
      setModalReports(true)
    }else{
      toast(<Notification type={"consultar_error"} backMessage={"NO SE ENCONTRARON DATOS"} withIcon />);
    }
  };

  const crearCSV = () => {
    history.push({
      pathname: "/template/presupuestos/presupuestos-dependientes/csv",
    });
  };

  const limpiar = () => {
    setForm({
      idStateFund: "",
      idExpenseObject: "",
      idFinancialSource: "",
    });
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div>
          <form className="mt-4" noValidate>
            <Row >
              <Col xs={12} lg={12} className="d-flex justify-content-around">
                <Button onClick={() => regresar()} className="danger">
                  Regresar
                </Button>
                <Button onClick={() => limpiar()} className="danger">
                  Limpiar
                </Button>
              </Col>
            </Row>
            <Row className="mt-5 text-center">
              <Col xs={12} md={6} lg={4}>
                <SelectTypeHeadSingle
                  label="Proyecto Presupuestal"
                  isRequired={false}
                  inputName="idStateFund"
                  optionsArray={searchFilters.statesFunds}
                  defaultOption="Seleccione un proyecto presupuestal"
                  onChangeMethod={OnChangeSelect}
                  isDisabled={false}
                  value={form.idStateFund}
                  multipleLabels={["stateFundKey","nameStateFund"]}
                  optionValue="idStateFund"
                  optionName = "name"
                />
              </Col>
              <Col xs={12} md={6} lg={4}>
                <SelectTypeHeadSingle
                  label="Fuente de financiamiento"
                  isRequired={false}
                  inputName="idFinancialSource"
                  optionsArray={searchFilters.financialSources}
                  defaultOption="Seleccione una fuente de financiamiento"
                  onChangeMethod={OnChangeSelect}
                  isDisabled={false}
                  value={form.idFinancialSource}
                  multipleLabels={["financialSourceKey","nameFinancialSource"]}
                  optionValue="idFinancialSource"
                  optionName = "name"
                />
              </Col>
              <Col xs={12} md={6} lg={4}>
                <SelectTypeHeadSingle
                  label="Partida"
                  isRequired={false}
                  inputName="idExpenseObject"
                  optionsArray={searchFilters.expensesObjects}
                  defaultOption="Seleccione una partida"
                  onChangeMethod={OnChangeSelect}
                  isDisabled={false}
                  value={form.idExpenseObject}
                  multipleLabels={["fullKey","nameObject"]}
                  optionValue="idExpenseObject"
                  optionName = "name"
                />
              </Col>
            </Row>
            <Row className="mt-2 text-center">
              <Col xs={4} lg={4}>
                <Button onClick={() => crearCSV()} className="danger">
                  Importar CSV
                </Button>
              </Col>
              <Col xs={4} lg={4}>
                <Button onClick={() => crearExcel()} className="danger">
                  Exportar a Excel
                </Button>
              </Col>
              <Col xs={4} lg={4}>
                <Button onClick={() => crearPdf()} className="danger">
                  Exportar a PDF
                </Button>
              </Col>
            </Row>
          </form>
          <div>
            <TableComponentPresupuestosDetallados
              titulo="Presupuestos Anuales"
              cabecerasTabla={cabecerasTabla}
              filtro={filter}
              nextRoute="/template/presupuestos/presupuestos-dependientes/detalles"
              parametros={parametros}
            ></TableComponentPresupuestosDetallados>
          </div>
          <ModalReports
                modal={modalReports}
                setModal={setModalReports}
                title={"Reporte de dependientes"}
                cabeceras={cabeceras}
                backdrop={"static"}
                keyboard={false}
                report={report}
                pdfConfig={pdfConfig}
                generatePdfMethod={
                  {
                    method:  generatePdfPresupuestoAnual,
                    type:"general"
                  }
                }
                records={1000}
              />
        </div>
      )}
    </>
  );
};
export default PresupuestosDependientes;

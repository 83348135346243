import { useFormik } from "formik";
import Datatable from "../../../../components/Datatable/Datatable";
import { SelectTypeHeadSingle } from "../../../../components/GenericInputsFormik";
import Widget from "../../../../components/Widget/Widget";
import { PERIOD } from "../../calculo/regex";
import { YearInput } from "../../../../components/GenericInputsFormik/YearInput";

import * as Yup from "yup";
import { Button, Col, Row, Spinner } from "reactstrap";
import { useState } from "react";
import AgregarLayout from "./Agregar";
import { toast } from "react-toastify";
import Notification from "../../../../components/Notification/Notification";
import { peticionesReceiver } from "../../../../helpers/peticionesReceiver";
import ModalComponent from "../../../../components/Modal/ModalDelete";
import ModalConfirmation from "../../../../components/Modal/ModalConfirmation";
import { GetPermissions } from "../../../../libs/permissions/getPermissions";

import FileSaver from "file-saver";
import DownloadIcon from "./DownloadIcon";
import DownloadIconDetail from "./DownloadIconDetail";
import ModalErrors from "./ModalErrors";

import { format } from "date-fns";
import { es } from "date-fns/locale";
import FullFormLoader from "../../../../components/Loader/FullFormLoader";

export default function LayoutParaTimbrar() {
  const API = peticionesReceiver();

  const [add, setAdd] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [permisos, setPermisos] = useState("");
  const [loading, setLoading] = useState(false);
  const [stampErros, setStamppErrors] = useState(null);
  const [modalErrors, setModalErrors] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [modalConfirmation, setModalConfirmation] = useState(false);
  const [rowSelected, setRowSelected] = useState(null);

  const handleCloseModal = () => {
    setStamppErrors(null);
    setModalErrors(false);
  };

  GetPermissions(setPermisos, setLoading);

  const defaultForm = {
    period: "",
    year: "",
  };

  const columns = [
    { data: "nameTypeStamp" },
    {
      data: null,
      render: function (row) {
        return `${row.period}/${row.year}`;
      },
      orderValue: "CONCAT(s.period,'/',s.year)",
    },
    {
      data: "url",
      render: function (row) {
        return (
          <DownloadIcon
            style={{ fill: "var(--success)", width: "40px", height: "40px" }}
            onClick={() =>
              FileSaver.saveAs(row, row.substring(row.lastIndexOf("/") + 1))
            }
            className="cursor-pointer"
          />
        );
      },
    },
    {
      data: "urlReview",
      render: function (row) {
        return (
          <DownloadIconDetail
            style={{ fill: "var(--secondary)", width: "40px", height: "40px" }}
            onClick={() =>
              FileSaver.saveAs(row, row.substring(row.lastIndexOf("/") + 1))
            }
            className="cursor-pointer"
          />
        );
      },
    },
    {
      data: "created",
      render: function (row) {
        return format(new Date(row), "P HH:mm", { locale: es });
      },
    },
    {
      data: null,
      render: function (row) {
        return (
          <div className="d-flex justify-content-center align-items-center">
            <Button
              className="m-3"
              color={"success"}
              onClick={() => {
                prepareInformation(row)
              }}
            >
              Enviar
            </Button>
            <Button
              className="m-3"
              color={"danger"}
              onClick={() => {
                openModalErrors(row);
              }}
            >
              Errores
            </Button>
          </div>
        );
      },
    },
    {
      data: "idStamp",
      render: function (row) {
        return (
          <>
            {permisos.DEL && (
              <ModalComponent
                modalTitle="Eliminar"
                id={row}
                table={["stamps"]}
                nameColumn={["idStamp"]}
                elimina={() => OnDelete(row)}
              >
                <h6>¿Seguro de eliminar el registro?</h6>
                No podrá recuperar la información después de ser eliminada
              </ModalComponent>
            )}
          </>
        );
      },
    },
  ];

  const prepareInformation = (row) => {
    setRowSelected(row)
    setModalConfirmation(true)
    

  }
  const headers = [
    "Tipo timbrado",
    "Periodo",
    "Layout",
    "Layout Detalle",
    "Fecha de Registro",
    "Acciones",
    "Eliminar",
  ];

  const columnDefs = [{ targets: [2, 3, 5,6], className: "text-center" }];

  const sendPetition = async () => {
    setIsLoading(true);
    if(rowSelected){
      let params = {};

    if (rowSelected.byStaff === "0") {
      params = {
        action: "sendStampStaffs",
        rows: {
          idStamp: rowSelected.idStamp,
        },
      };
    } else {
      params = {
        action: "updateStampStaffs",
        rows: {
          idStamp: rowSelected.idStamp,
        },
      };
    }

    await API.peticionEndPoint(params, "app/facades/payslips/paystampF.php")
      .then((res) => {
        if (res.status === 200 && res.data.code === "200") {
        } else {
          toast(
            <Notification
              type={"consultar_error"}
              backMessage={res.data.message}
              withIcon
            />
          );
        }
      })
      .catch((err) => {
        toast(<Notification type={"consultar_servidor_error"} withIcon />);
      });
      setRowSelected(null)
    }

    
    setIsLoading(false);
  };

  const openModalErrors = (row) => {
    setStamppErrors(row);
    setModalErrors(true);
  };

  const FormSchema = Yup.object().shape({
    period: Yup.string(),
    year: Yup.string().nullable(),
  });

  const { handleSubmit, values, handleBlur, errors, touched, setFieldValue } =
    useFormik({
      initialValues: defaultForm,
      onSubmit: () => {
        setRefresh(true);
      },
      validationSchema: FormSchema,
    });

  const onChange = (e) => {
    const { name, value } = e.target;
    setFieldValue([name], value);
  };

  const getParams = (order, records, page, search) => {
    const params = {
      action: "datatable",
      table:
        "stamps s inner join types_stamps ts on (s.keyTypeStamp = ts.keyTypeStamp)",
      rows: "s.idStamp,ts.nameTypeStamp,s.period,s.year,s.url,s.urlReview,s.created,s.byStaff",
      conditions: `s.enabled=1${
        values.period !== "" ? ` AND s.period= ${values.period}` : ""
      }${
        values.year !== "" && values.year !== null
          ? ` AND s.year= ${new Date(values.year).getFullYear()}`
          : ""
      }`,
      order,
      records: records.toString(),
      page: (page * records).toString(),
      search,
    };
    return params;
  };

  async function getData(order, records, page, search) {
    try {
      const res = await API.peticion(getParams(order, records, page, search));
      if (res.status === 200 && res.data.code === "200") {
        return res.data;
      } else {
        toast(
          <Notification
            type={"consultar_error"}
            backMessage={res.data.message}
          />
        );
        return null;
      }
    } catch (err) {
      toast(<Notification type={"consultar_servidor_error"} withIcon />);
      return null;
    }
  }

  async function OnDelete(row) {
    setIsLoading(true);
    const API = peticionesReceiver();
    const params = {
      action: "deleteStampStaffs",
      rows: {
        idStamp: row,
      },
    };
    try {
      const res = await API.peticionEndPoint(
        params,
        "app/facades/payslips/paystampF.php"
      );
      if (res.status === 200 && res.data.code === "200") {
        toast(
          <Notification
            type={"elimina_exito"}
            backMessage={res.data.message}
            withIcon
          />
        );
        setRefresh(true);
      } else {
        toast(
          <Notification
            type={"elimina_error"}
            backMessage={res.data.message}
            withIcon
          />
        );
      }
    } catch (err) {
      toast(<Notification type={"elimina_error"} withIcon />);
    }
    setIsLoading(false);
  }

  if (loading) {
    <div
      className="d-flex align-items-center justify-content-center"
      style={{ height: "200px" }}
    >
      <Spinner
        color="primary"
        style={{
          height: "3rem",
          width: "3rem",
        }}
        type="grow"
      >
        Loading...
      </Spinner>
    </div>;
  }
  if (add && permisos.INS) {
    return <AgregarLayout setAdd={setAdd} setRefreshMain={setRefresh} />;
  }
  if (permisos.CON) {
    return (
      <>
        <Widget>
          {permisos.INS && (
            <Button className="mx-3 mt-3" onClick={() => setAdd(true)}>
              Agregar nuevo layout
            </Button>
          )}
          <form onSubmit={handleSubmit}>
            <Row className="px-3 pt-3">
              <Col xs={12} md={6}>
                <SelectTypeHeadSingle
                  label="Periodo"
                  isRequired={true}
                  inputName="period"
                  optionsArray={PERIOD}
                  defaultOption="Seleccione un periodo"
                  onChangeMethod={onChange}
                  value={values.period}
                  errors={errors.period}
                  touched={touched.period}
                  optionValue="value"
                  optionName="label"
                />
              </Col>
              <Col xs={12} md={6}>
                <YearInput
                  label="AÑO"
                  inputName="year"
                  onChangeMethod={onChange}
                  onBlurMethod={handleBlur}
                  value={values.year}
                  touched={touched.year}
                  errors={errors.year}
                />
              </Col>
            </Row>
            <div className="text-center mb-3">
              <Button color="info" type="submit">
                Buscar
              </Button>
            </div>
          </form>

          {permisos.CON ? (
            <Datatable
              columns={columns}
              headers={headers}
              petition={getData}
              stateRefresh={[refresh, setRefresh]}
              columnDefs={columnDefs}
              control="back"
            />
          ) : (
            <div className="text-center mt-3 mb-1">
              No tiene permitido ver los registros de esta seccion
            </div>
          )}

          {stampErros && (
            <ModalErrors
              modalErrors={modalErrors}
              setModalErrors={setModalErrors}
              handleCloseModal={handleCloseModal}
              stampErros={stampErros}
            />
          )}

          <FullFormLoader
            show={isLoading}
            message={"Realizando consulta, esto puede demorar unos minutos"}
          />
          <ModalConfirmation
            modalTitle={"Enviar"}
            modal={modalConfirmation}
            setModal={setModalConfirmation}
            crear={sendPetition}
            isEdit={false}
            values={values}
          >
            <div className="d-flex justify-content-center">
              <h6>¿Está seguro de continuar con el envio</h6>
            </div>
          </ModalConfirmation>
        </Widget>
      </>
    );
  } else {
    return (
      <>
        <div className="text-center p-4">
          No tienes permitido ver los registros de esta seccion
        </div>
      </>
    );
  }
}

import React, { useState } from "react";
import TableComponentCustom from "../../../components/TableComponent/TableComponentCustom";
import Widget from "../../../components/Widget/Widget";

export default function MatrizInventarios() {
  const cabeceras = [
    "Id",
    "Objeto del gasto",
    "Cuenta compra",
    "Partida compra",
    "Cuenta depreciación",
    "Partida depreciación",
    "Cuenta depreciación acumulada",
    "Partida depreciación acumulada",
    "Cuenta donación",
    "Partida donación",
    "Cuenta venta",
    "Partida venta",
    "Años vida util",
    "Porcentaje depreciación"
  ];
  const [parametros] = useState(
    {
      action: "datatable",
      table:
        "matrix_inventory",
      rows: "idMatrix,COG,buyAccount,buyObject,depreciationAccount,depreciationObject,depreciationAcumAccount,depreciationObjAccount,donationAccount,donationObject,sellAccount,sellObject,useLife,depreciationAvg",
      conditions: `enabled = 1`,
      page: 0,
      records: 5,
      search: "",
      order: "",
    }
  );

  
  return (
    <Widget>
      <TableComponentCustom
        titulo={"Inventarios"}
        cabecerasTabla={cabeceras}
        filtro={null}
        parametros={parametros}
      />
    </Widget>
  )
}
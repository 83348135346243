
import React from "react";

import Widget from "../../../../components/Widget/Widget";
import FromPrestamos from "../FormPrestamos";
import { updatePrestamo } from "../functions";

export const EditarPension = (props) => {
  return (
    <Widget className="widget-p-md">
        <h3>Editar tipo de prestamo</h3>
        <FromPrestamos data={localStorage.getItem('dataContent').split('|-|')} editar={updatePrestamo}/>
    </Widget>
  )
}

export default EditarPension;
import { Document, Page, Text, View, StyleSheet } from "@react-pdf/renderer";
import { styles } from "../../../../components/Pdf/pdfGeneral/EstiloPdf";
import { sumValues } from "../../../../libs/tools/arrays";
import HeaderReport from "../../../../components/Pdf/Header";

import { es } from "date-fns/locale"
import { setMonth, format } from "date-fns"

/**
 * Estilo que se repite en todas los headers de la tabla
 */
const styleHeadForAll = {
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "#646464",
    color: 'white',
    textAlign: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 10
}

const styleCellForAll = {
    borderStyle: "solid",
    borderWidth: 1,
    borderTopWidth: 0,
    fontSize: 8
}

/**
 * Estilos del pdf
 */
const s = StyleSheet.create({
    body: {
        padding: 10
    },
    subTitle: { //SUBTITULOS
        width: "100%",
        textAlign: 'center',
        marginBottom: 10,
        marginLeft: 10,
        fontSize: 12
    },
    f6: {
        fontSize: 6
    },
    f5: {
        fontSize: 5
    },
    f8: {
        fontSize: 8
    },
    f10: {
        fontSize: 10
    },
    thIn: {
        height: 20,
        ...styleHeadForAll
    },
    th: {
        height: 20,
        ...styleHeadForAll,
        borderLeftWidth: 0
    },
    w5: {
        width: "5%"
    },
    w10: {
        width: "10%"
    },
    w20: {
        width: "20%"
    },
    w18: {
        width: "18%"
    },
    w25: {
        width: "25%"
    },
    w30: {
        width: "30%"
    },
    w33: {
        width: "33%"
    },
    w34: {
        width: "34%"
    },
    w40: {
        width: "40%"
    },
    w45: {
        width: "45%"
    },
    w50: {
        width: "50%"
    },
    w60: {
        width: "60%"
    },
    w70: {
        width: "70%"
    },
    w90: {
        width: "90%"
    },
    w100: {
        width: "100%"
    },
    mt2: {
        marginTop: 5
    },
    mt4: {
        marginTop: 40
    },
    pt1: {
        paddingTop: 10
    },
    px2: {
        paddingHorizontal: 10
    },
    px1: {
        paddingHorizontal: 5
    },
    bt0: {
        borderTopWidth: 0
    },
    bt1: {
        borderTopWidth: 1
    },
    center: {
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
    },
    textBold: {
        fontFamily: 'Helvetica-Bold'
    },
    textRight: {
        textAlign: "right"
    },
    tdIn: { //Cell CC
        ...styleCellForAll
    },
    td: { //Cell Concepto
        ...styleCellForAll,
        borderLeftWidth: 0
    },
    td0: { //Cell Concepto
        ...styleCellForAll,
        borderLeftWidth: 0,
        borderRightWidthWidth: 0
    },
    red: {
        backgroundColor: "red",
    },
    green: {
        backgroundColor: "green",
    },
    gray: {
        backgroundColor: "gray",
    }
})


const formatter = new Intl.NumberFormat("es-MX", {
    style: "currency",
    currency: "MXN",
});



export default function exportPDF3srem(data, model, title, fileNo) {
    const ori = model.orientation === "h" ? "landscape" : "portrait";

    function getWidth(width) {
        switch (width) {
            case 5:
                return s.w5;
            case 10:
                return s.w10;
            case 20:
                return s.w20;
            case 30:
                return s.w30;
            case 40:
                return s.w40;
            case 45:
                return s.w45;
            case 50:
                return s.w50;
            case 60:
                return s.w60;
            case 70:
                return s.w70;
            case 80:
                return s.w80;
            case 90:
                return s.w90;
            default:
                return s.w100
        }
    }


    const month = Number(data[0].month) - 1;
    const total = Number(data[0].amountCR) + Number(data[0].amountSP)

    const sumadeduc = sumValues(data, "amount") + Number(data[0].amountCS) + Number(data[0].amountCRep) + Number(data[0].amountGA) + Number(data[0].amountRT)

    const signEl = ``

    const revisoEl = ``

    return {
        report: (
            <>
                <Document>
                    <Page style={s.body} orientation={ori}>
                        <HeaderReport />
                        <View>
                            <Text style={s.subTitle}>{title}</Text>
                            <Text style={s.subTitle}>(IMPUESTO SOBRE NÓMINA)</Text>
                            <Text style={s.subTitle}>{`BASE PARA EL CALCULO DEL 3.0% CORRESPONDIENTE AL MES DE ${format(setMonth(new Date(), month), "LLLL", { locale: es }).toUpperCase()} 2022`}</Text>
                        </View>
                        <View style={styles.table}>
                            <View style={[styles.tableRow, s.mt2]}>
                                <Text style={[s.f8, s.textBold]}>SERVICIOS PERSONALES:</Text><Text style={s.f8}> {formatter.format(data[0].amountSP)}</Text>
                            </View>
                            <View style={[styles.tableRow, s.mt2]}>
                                <Text style={[s.f8, s.textBold]}>COMPLEMENTO DE RECONOCIMIENTOS:</Text><Text style={s.f8}> {formatter.format(data[0].amountCR)}</Text>
                            </View>
                            <View style={[styles.tableRow, s.mt2]}>
                                <Text style={[s.f8, s.textBold]}>TOTAL:</Text><Text style={s.f8}> {formatter.format(total)}</Text>
                            </View>
                            <View style={[styles.tableRow, s.mt2]}>
                                <Text style={[s.f8, s.textBold]}>CUOTA DE SALUD:</Text><Text style={s.f8}> {formatter.format(data[0].amountCS)}</Text>
                            </View>
                            <View style={[styles.tableRow, s.mt2]}>
                                <Text style={[s.f8, s.textBold]}>CUOTA DE REPARTO:</Text><Text style={s.f8}> {formatter.format(data[0].amountCRep)}</Text>
                            </View>
                            <View style={[styles.tableRow, s.mt2]}>
                                <Text style={[s.f8, s.textBold]}>SISTEMA DE CAPITALIZACIÓN:</Text><Text style={s.f8}> {formatter.format(data[0].amountSC)}</Text>
                            </View>
                            <View style={[styles.tableRow, s.mt2]}>
                                <Text style={[s.f8, s.textBold]}>GASTOS DE ADMÓN.:</Text><Text style={s.f8}> {formatter.format(data[0].amountGA)}</Text>
                            </View>
                            <View style={[styles.tableRow, s.mt2]}>
                                <Text style={[s.f8, s.textBold]}>RIESGO DE TRABAJO:</Text><Text style={s.f8}> {formatter.format(data[0].amountRT)}</Text>
                            </View>
                        </View>
                        <View style={styles.table}>
                            <View style={styles.tableRow} fixed>
                                {model.headers.map((header, index) => (
                                    <View style={[index === 0 ? s.thIn : s.th, getWidth(model.sizes[index])]}>
                                        <Text>{header}</Text>
                                    </View>
                                ))}
                            </View>
                            {data.map((row, ind) => (
                                <View style={styles.tableRow} fixed>
                                    {model.content.map((value, index) => (
                                        <View style={[index === 0 ? s.tdIn : s.td, getWidth(model.sizes[index])]}>
                                            <Text style={value.format ? [s.textRight, s.px1] : ""}>{value.value !== "id" ? (!value.format ? row[value.value] : formatter.format(row[value.value])) : (ind + 1)}</Text>
                                        </View>
                                    ))}
                                </View>
                            ))}
                        </View>
                        <View style={s.body}>
                            <View style={[styles.tableRow, s.mt2]}>
                                <Text style={[s.f8, s.textBold]}>SUMA DE DEDUCCIONES:</Text><Text style={s.f8}> {formatter.format(sumadeduc)}</Text>
                            </View>
                            <View style={[styles.tableRow, s.mt2]}>
                                <Text style={[s.f8, s.textBold]}>BASE IMPUESTO:</Text><Text style={s.f8}> {formatter.format(total - sumadeduc)}</Text>
                            </View>
                            <View style={[styles.tableRow, s.mt2]}>
                                <Text style={[s.f8, s.textBold]}>PORCENTAJE: </Text><Text style={s.f8}> 3%</Text>
                            </View>
                            <View style={[styles.tableRow, s.mt2]}>
                                <Text style={[s.f8, s.textBold]}>IMPUESTO DETERMINADO:</Text><Text style={s.f8}> {formatter.format((total - sumadeduc) * 0.03)}</Text>
                            </View>


                            <View style={[styles.table, s.mt4]}>
                                <View style={styles.tableRow}>
                                    <View style={[s.w50, s.center]}>
                                        <Text style={[s.center, s.f10, s.textBold]}>
                                            ELABORÓ
                                        </Text>
                                        <Text style={[s.center, s.textBold, s.f10, s.mt4, s.bt1, s.pt1]}>
                                            {signEl}
                                        </Text>
                                    </View>
                                    <View style={[s.w50, s.center, s.center]}>
                                        <Text style={[s.center, s.textBold, s.f10]}>
                                            REVISÓ
                                        </Text>
                                        <Text style={[s.center, s.textBold, s.f10, s.mt4, s.bt1, s.pt1]}>
                                            {revisoEl}
                                        </Text>
                                    </View>
                                </View>
                            </View>
                        </View>
                    </Page>
                </Document>
            </>)
    }
}
import { Document, Page, Text, View } from "@react-pdf/renderer";
import { styles } from "../../../../components/Pdf/pdfGeneral/EstiloPdf";
import { peticionesReceiver } from "../../../../helpers/peticionesReceiver";
import Notification from "../../../../components/Notification/Notification";
import HeaderReport from "../../../../components/Pdf/Header";
import { toast } from "react-toastify";
import { fMoney } from "../../../../libs/tools/format";

const API = peticionesReceiver();

const InsertTitles = ({ model, title }) => {
    return (
        <>
            <HeaderReport />
            <View>
                <Text style={styles.title}>{title}</Text>
            </View>
            <View>
                <Text style={styles.subTitle}>
                    {model.filters !== undefined
                        ? model.filters.onlyMessage === true
                            ? model.filters.message
                            : ""
                        : ""}
                </Text>
            </View>
        </>
    );
};

// const InsertStaffData = ({ staff }) => {
//     return (
//         <>
//             <View style={styles.table}>
//                 <View style={[styles.tableRow, { justifyContent: "center" }]}>
//                     <View
//                         style={[
//                             styles.cell33BorderSides,
//                             {
//                                 borderTopWidth: 1,
//                                 borderBottomWidth: 1,
//                             },
//                         ]}
//                     >
//                         <Text>
//                             <Text style={styles.textBold}>CLAVE: </Text>
//                             {staff.staffNumber}
//                         </Text>
//                     </View>
//                     <View
//                         style={[
//                             styles.cell33BorderRigth,
//                             {
//                                 borderTopWidth: 1,
//                                 borderBottomWidth: 1,
//                             },
//                         ]}
//                     >
//                         <Text>
//                             <Text style={styles.textBold}> NOMBRE: </Text>{" "}
//                             {staff.name}{" "}
//                         </Text>
//                     </View>
//                 </View>
//             </View>
//         </>
//     );
// };

const getStaff = async (data) => {
    let staff = [];
    const params = {
        action: "getStaffData",
        idStaff: data[0].idStaff,
    };
    await API.peticionEndPoint(params, "app/facades/reports/reportsF.php")
        .then((res) => {
            if (res.status === 200) {
                staff = res.data.data[0];
            } else {
                toast(
                    <Notification
                        type={"consultar_error"}
                        backMessage={res.data.message}
                        withIcon
                    />
                );
            }
        })
        .catch((err) => {
            toast(<Notification type={"consultar_servidor_error"} withIcon />);
        });
    return staff;
};

const sty = [styles.cellHeader25BordersRTB, { fontSize: 6 }];

export async function generatePdfDeduccionesAdicionales(
    data,
    model,
    title,
    fileNo
) {
    const staff = model.byStaff ? await getStaff(data) : {};
    return {
        report: (
            <Document>
                <Page style={styles.body} orientation="landscape">
                    <InsertTitles model={model} title={title} />

                    <View
                        style={{ width: "100%", display: "table", borderStyle: "solid", margin: "auto"}}
                    >
                        <View style={[styles.tableRow]} fixed>
                            <View style={[styles.cellHeader25Borders, { fontSize: 6 }]}>
                                <Text>{"CVE DESCUENTO"}</Text>
                            </View>
                            <View style={sty}>
                                <Text>{"CONCEPTO"}</Text>
                            </View>
                            <View style={sty}>
                                <Text>{"FOLIO"}</Text>
                            </View>
                            {!model.byStaff && (
                                <View style={sty}>
                                    <Text>EMPLEADO</Text>
                                </View>
                            )}
                            <View style={sty}>
                                <Text>{"FECHA DE INICIO"}</Text>
                            </View>
                            <View style={sty}>
                                <Text>{"FECHA FIN"}</Text>
                            </View>
                            <View style={sty}>
                                <Text>{"IMPORTE INICIAL"}</Text>
                            </View>
                            <View style={sty}>
                                <Text>{"DESCUENTO FIJO"}</Text>
                            </View>
                            <View style={sty}>
                                <Text>{"IMPORTE RETENIDO"}</Text>
                            </View>
                            <View style={sty}>
                                <Text>{"SALDO"}</Text>
                            </View>
                        </View>

                        {data.length > 0 &&
                            data.map((info, index) => (
                                    <View style={styles.tableRow} key={index} wrap={false}>
                                        <View
                                            style={[
                                                styles.cellContent25BordersLBR,
                                                { fontSize: 6, textAlign: "left" },
                                            ]}
                                        >
                                            <Text>{info.under} </Text>
                                        </View>
                                        <View
                                            style={[
                                                styles.cellContent25BordersRB,
                                                { fontSize: 6, textAlign: "left" },
                                            ]}
                                        >
                                            <Text>{info.nameUnder} </Text>
                                        </View>
                                        <View
                                            style={[
                                                styles.cellContent25BordersRB,
                                                { fontSize: 6, textAlign: "left" },
                                            ]}
                                        >
                                            <Text>{info.folio} </Text>
                                        </View>
                                        {!model.byStaff && (
                                            <View style={[ styles.cellContent25BordersRB, { fontSize: 6, textAlign: "left" }, ]}>
                                                <Text>
                                                    {info.staffNumber} - {info.nombre}
                                                </Text>
                                            </View>
                                        )}
                                        <View
                                            style={[
                                                styles.cellContent25BordersRB, { fontSize: 6, textAlign: "left" }]}
                                        >
                                            <Text>{info.fechaInicio} </Text>
                                        </View>
                                        <View
                                            style={[
                                                styles.cellContent25BordersRB,
                                                { fontSize: 6, textAlign: "left" },
                                            ]}
                                        >
                                            <Text>{info.fechaFin} </Text>
                                        </View>

                                        <View
                                            style={[
                                                styles.cellContent25BordersRB,
                                                { fontSize: 6, textAlign: "left" },
                                            ]}
                                        >
                                            <Text>
                                                {fMoney(info.importeInicial)}{" "}
                                            </Text>
                                        </View>
                                        <View
                                            style={[
                                                styles.cellContent25BordersRB,
                                                { fontSize: 6, textAlign: "left" },
                                            ]}
                                        >
                                            <Text>
                                                {fMoney(info.descuentoFijo)}{" "}
                                            </Text>
                                        </View>
                                        <View
                                            style={[
                                                styles.cellContent25BordersRB,
                                                { fontSize: 6, textAlign: "left" },
                                            ]}
                                        >
                                            <Text>
                                                {fMoney(info.importeRetenido)}{" "}
                                            </Text>
                                        </View>
                                        <View
                                            style={[
                                                styles.cellContent25BordersRB,
                                                { fontSize: 6, textAlign: "left" },
                                            ]}
                                        >
                                            <Text>{fMoney(info.saldo)} </Text>
                                        </View>
                                    </View>
                            ))
                        }
                    </View>

                    <View fixed style={styles.spaceFooter}></View>
                    <View fixed style={styles.footer}>
                        <Text style={styles.textFooter}>{fileNo}</Text>
                        <Text
                            style={styles.textFooter}
                            render={({ pageNumber, totalPages }) =>
                                `PAGINA : ${pageNumber} / ${totalPages}`
                            }
                        />
                    </View>
                </Page>
            </Document>
        ),
    };
}

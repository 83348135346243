import { SelectTypeHeadSingle } from "../../../../../components/GenericInputsFormik";

export const months = [
    { label: "Enero", value: 1 },
    { label: "Febrero", value: 2 },
    { label: "Marzo", value: 3 },
    { label: "Abril", value: 4 },
    { label: "Mayo", value: 5 },
    { label: "Junio", value: 6 },
    { label: "Julio", value: 7 },
    { label: "Agosto", value: 8 },
    { label: "Septiembre", value: 9 },
    { label: "Octubre", value: 10 },
    { label: "Noviembre", value: 11 },
    { label: "Diciembre", value: 12 },
]



export default function SelectMonths({ handleBlur, touched, errors, values, setFieldValue, isDisabled }) {

    const OnChangeSelect = event => {
        setFieldValue(event.target.name, event.target.value);
    };

    return (
        <SelectTypeHeadSingle
            label="Seleccione un mes"
            optionsArray={months}
            inputName="month"
            onChangeMethod={OnChangeSelect}
            onBlurMethod={handleBlur}
            touched={touched.month}
            errors={errors.month}
            optionValue="value"
            optionName="label"
            value={values.month}
            isRequired
            isDisabled={isDisabled}
            defaultOption="Seleccione un mes"
        />
    )
}
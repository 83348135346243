import React from "react";
import Widget from "../../../../components/Widget/Widget";
import FormArea from "../FormArea";
import { updateArea } from "../functions";

export const EditarArea = (props) => {
  return (
    <Widget className="widget-p-md">
        <h3>Editar Unidad de Trabajo</h3>
        <FormArea data={localStorage.getItem('dataContent').split('|-|')} editar={updateArea}/>
    </Widget>
  )
}

export default EditarArea;
import axios from "axios";
import { peticionEncript } from "./peticionesEncripted";
import { toast } from "react-toastify";
import Notification from "../components/Notification/Notification";

export const peticionesReceiver = () => {  
  let respuesta = ""
  const peticion = async(data) => {
    const finalData = peticionEncript(data)

    await axios
      .post(`${process.env.REACT_APP_API}receiver/receiver.php`, finalData)
      .then((res) => {
        respuesta = res
      })
      .catch((err) =>{
        console.log("ERROR: "+ err)
      });
      return respuesta
  } 
  const peticionPerfilesMod = async(data) => {
    const finalData = peticionEncript(data)
    await axios
      .post(`${process.env.REACT_APP_API}app/facades/profiles/profilesF.php`, finalData)
      .then((res) => {
        respuesta = res
      })
      .catch((err) =>{
        toast(
          <Notification
            type={"consultar_servidor_error"}
            backMessage={err.message}
            withIcon
          />
        );
      });
      return respuesta
  }  

  const peticionPensionesExpediente = async(data) => {
    const finalData = peticionEncript(data)
    await axios
      .post(`${process.env.REACT_APP_API}app/facades/employees/benefitsF.php`, finalData)
      /* .post(route, finalData) */
      .then((res) => {
        respuesta = res
      })
      .catch((err) =>{
        console.log("ERROR: "+ err)
      });
      return respuesta
  }

  const peticionEndPoint = async(data,route) => {
    const finalData = peticionEncript(data)
    await axios
      .post(`${process.env.REACT_APP_API}${route}`, finalData)
      /* .post(route, finalData) */
      .then((res) => {
        respuesta = res
      })
      .catch((err) =>{
        console.log("ERROR: "+ err)
      });
      return respuesta
  }  
  return {peticion,peticionPerfilesMod,peticionEndPoint, peticionPensionesExpediente}
}

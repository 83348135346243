import React from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import Datatable from "../../components/Datatable/Datatable";

function ModalTableVer(props) {
  const {
    modal,
    setModal,
    title,
    backdrop, // true, false, "static"
    keyboard, // true, false
    btnClose, // true, false
    style,
    //table
    headers,
    columns,
    control,
    peticion,
    refresh,
    setRefresh,
    columnDefs,
    data
  } = props;

  const toggle = () => {
    setModal(!modal);
  };

  const CloseBtn = (
    <>
      { btnClose?
        <i className="eva eva-close cursor-pointer" onClick={toggle} /> :
        <></>
      }
    </>
  );

  return (
    <>
      <Modal isOpen={modal} backdrop={backdrop} keyboard={keyboard} toggle={ toggle} size="xl" style={style}>
        <ModalHeader toggle={toggle} close={CloseBtn}>{title || ""}</ModalHeader>
        <ModalBody className="mx-2">
        <Datatable
                    headers={headers}
                    columns={columns}
                    control={control}
                    data={data}
                    petition={peticion}
                    stateRefresh={[refresh, setRefresh]}
                    columnDefs={columnDefs}
                />
        </ModalBody>
      </Modal>
    </>
  );
}

export default ModalTableVer;

import Datatable from "../../../../components/Datatable/Datatable";
import { doRequest, doRequestSaveRes, findEmployee } from "../../../../helpers/requests";
import { fDate } from "../../../../libs/tools/format";
import { Button, Col, Row } from "reactstrap";
import {
    SelectTypeHeadSingle,
    TextInput,
} from "../../../../components/GenericInputsFormik";
import format from "date-fns/format";
import { ButtonInput } from "../../../../components/GenericInputsFormik/ButtonInput";
import ModalTableVer from "../../../../components/Modal/ModalTableVer";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import ModalConfirmation from "../../../../components/Modal/ModalConfirmation";
import { toast } from "react-toastify";
import Notification from "../../../../components/Notification/Notification";
import FullFormLoader from "../../../../components/Loader/FullFormLoader";
import { useSelector } from "react-redux";

const types = ["127", "128, 129"];

const modalTableParams = {
    action: "datatable",
    table:
      "staff STF INNER JOIN employtypes AS EMT ON STF.keyEmployType = EMT.keyEmployType INNER JOIN jobstaff AS JBS ON JBS.idStaff = STF.idStaff INNER JOIN areas AS AR ON AR.idArea = JBS.idArea INNER JOIN jobtypes AS JBT ON JBT.keyJobType = STF.keyEmployType",
    rows: "STF.idStaff,STF.staffNumber,CONCAT(STF.name,' ',STF.pName,' ',STF.mName) as fullName,AR.name,JBT.nameJobType",
    conditions: "STF.enabled = 1 AND JBS.valid = 1 AND STF.keyStat = 1 AND JBS.enabled = 1",
    page: 0,
    records: 5,
    search: "",
    order: ""
}

const cDate = new Date();
const cYear = cDate.getFullYear();

export default function TableIncident({type, refresh, setRefresh, setRefreshB, permissions}){
    const [filters, setFilters] = useState({
        staff: "",
        nStaff: "",
        dI: "",
        dF: format(cDate, "yyyy-MM-dd"),
    });
    const [loading, setLoading] = useState(false);
    const [modalConfirm, setModalConfirm] = useState(false);
    const [modalTable, setModalTable] = useState(false);
    const [selected, setSelected] = useState([]);
    const [all, setAll] = useState(false);
    const [newStatus, setNewStatus] = useState("");
    const { id } = useSelector((state) => state.userInfo)
    const [idStaff, setIdStaff] = useState(null);
    const history = useHistory();
    const headers = [
        <HeaderCheck all={all} setAll={setAll} setSelected={setSelected} />,
        "EMPLEADO",
        "PUESTO",
        "Unidad de trabajo",
        "FECHA",
        "TIPO INCIDENCIA",
        "JUSTIFICACION",
        "ESTATUS",
        "EDITAR"
    ];

    const columns = [
        {
          data: "idIncident",
          render: function (d) {
            return (
              <div className="form-check checkbox checkbox-dark-gray d-flex justify-content-center">
                <input
                  type="checkbox"
                  className="styled"
                  checked={all || selected.includes(d)}
                  onChange={() => checkRow(d)}
                />
                <label />
              </div>
            );
          },
        },
        { data: "fullNameStaff"},
        { data: "nameJob" },
        { data: "nameArea" },
        { data: "dateIncident", render: (d) => <div>{fDate(d)}</div> },
        { data: "nameTypeIncident" },
        { data: "notes" },
        { data: "nameStat" },
        {
            data: null,
            render: (d) => {
                return (
                    permissions.UPD && +d.revised === 0 && <i className="fa fa-pencil text-warning cursor-pointer" onClick={()=>toEdit(d)}/>
                );
            },
        },
    ];
    
const columnDefs = [
    {
        orderable: true,
        targets: type === 0 ? [4, 5, 7] : [3, 4, 6, 7],
        className: "text-center",
    },
    {
        orderable: false,
        targets: type === 0 ? [0] : [7],
        className: "text-center",
    },
];

    if(type === 1){
        headers.shift();
        columns.shift();
    }else{
        headers.pop();
        columns.pop();
    }

    useEffect(()=>{
        getStaff();
    }, []);

    useEffect(() => {
        setRefresh(true);
    }, [filters]);

    async function getStaff(){
        setLoading(true);
        const emp = await findEmployee(id);
        if(emp){
            setIdStaff(emp.idStaff);
            setRefresh(true);
        }
        setLoading(false);
    }

    /* Table */
    async function getData(ordCol, itemsPerPage, currentPage, search, multi) {
        if(idStaff){
            const res = await doRequestSaveRes(
                "receiver/receiver.php",
                getParams(ordCol, itemsPerPage, currentPage, search, multi),
                false,
                false
            );
            if (res.code === "200") return res;
        }
        return null;
    }

    function getParams(ordCol, itemsPerPage, currentPage, search, multi) {
        const params = {
            action: multi ? "multiselect": "datatable",
            table: `incidents A INNER JOIN staff B USING(idStaff) INNER JOIN status C ON C.keyStat = A.keyStat INNER JOIN jobstaff D ON D.idStaff = A.idStaff AND D.valid = 1 INNER JOIN jobs E USING(keyJob) INNER JOIN areas F USING(idArea) INNER JOIN types_incidents G USING(keyTypeIncident) INNER JOIN incidentsgroups H ON H.idStaff = A.idStaff`,
            rows: "CONCAT(B.name,' ',B.pName,' ',B.mName) AS fullNameStaff, E.nameJob, F.name as nameArea, A.dateIncident, G.nameTypeIncident, A.notes, C.nameStat, A.idIncident, A.revised, A.keyTypeIncident, A.keyStat",
            conditions: getConditions(),
            order: ordCol,
            records: multi ? "" : itemsPerPage.toString(),
            page: multi ? "" : (itemsPerPage * currentPage).toString(),
            search
        };
        return params;
    }

    function getConditions() {
        let cond = `A.enabled = 1 AND D.enabled = 1 AND H.enabled = 1 AND A.keyStat IN (${types[type]}) AND H.idStaffAutorized = ${idStaff}`;
        if (filters.staff) cond += ` AND A.idStaff = ${filters.staff}`;
        if (filters.dI) cond += ` AND A.dateIncident >= '${filters.dI}'`;
        if (filters.dF) cond += ` AND A.dateIncident <= '${filters.dF}'`;
        return cond;
    }
    
    function checkRow(d){
        const aux = [...selected];
        const ix = aux.indexOf(d);
        if(ix === -1) aux.push(d);
        else aux.splice(ix, 1);
        setSelected(aux);
    }

    const onChange = ({ target }) => {
        setFilters((prevState) => ({
            ...prevState,
            dF: target.name === "dI" 
                ? (cmpDates(target.value) ? filters.dF : "") : filters.dF,
            nStaff: target.name === "staff" ? target.nStaff : filters.nStaff,
            [target.name]: target.value,
        }));
    };

    function cmpDates(d1) {
        if (!d1) return true;
        return new Date(d1).getTime() <= new Date(filters.dF).getTime();
    }

    function getMinLimit(field){
        let fecha = new Date(cYear - 5, 1, 1);
        if(field === "fF"){
            if(filters.dI){
                const dd = filters.dI.split("-");
                fecha = new Date(+dd[0], +dd[1]-1,dd[2]);
            }
        }
        return format(fecha, "yyyy-MM-dd");
    }

    function selectRow(row) {
        onChange({ target: { name: "staff", value: row[0], nStaff: row[2] } });
        setModalTable(false);
    }

    function toEdit(d){
        localStorage.setItem("incident", JSON.stringify({
            idIncident: d.idIncident,
            keyTypeIncident: d.keyTypeIncident,
            dateIncident: d.dateIncident,
            keyStat: d.keyStat,
        }));
        history.push({ pathname: "/template/autorizar-incidencias/editar" });
    }

    function showModal(type){
        if(selected.length < 1 && !all){
            toast(
                <Notification
                  type={"consultar_error"}
                  backMessage="Seleccione al menos un registro de la tabla"
                  withIcon
                />,
                { closeButton: false }
              );
              return;
        }
        setNewStatus(type);
        setModalConfirm(true);
    }

    async function updateIncidents(){
        setLoading(true);
        let rows = selected;
        if(all){
            const data = await getData("", "", "0", "",true);
            console.log(data);
            if(+data.code === 200) rows = data.data;
            else rows = [];
        }
        if(rows && rows.length > 0){
            const params = {
                action: "saveIncidentsstatus",
                rows: {incidents: rows.map(el => {return {idIncident: el, keyStat: newStatus}})},
            }
            console.log(params)
            const res = await doRequest("app/facades/employees/employeesF.php", params, true);
            console.log(res)
            if(res.length > 0){
                setRefresh(true);
                setRefreshB(true);
                setNewStatus("");
                setSelected([]);
                setAll(false);
            }
        }
        setLoading(false);
    }

    return (
        <div className="mt-3">
            <Row>
                <Col xs={12} md={6}>
                    <TextInput
                        label="Fecha Inicial"
                        inputName="dI"
                        inputType="date"
                        onChangeMethod={onChange}
                        onBlurMethod={() => { }}
                        value={filters.dI}
                        isMin={getMinLimit("fI")}
                        isMax={format(cDate, "yyyy-MM-dd")}
                    />
                </Col>
                <Col xs={12} md={6}>
                    <TextInput
                        label="Fecha final"
                        inputName="dF"
                        inputType="date"
                        onChangeMethod={onChange}
                        onBlurMethod={() => { }}
                        value={filters.dF}
                        isMin={getMinLimit("fF")}
                        isMax={format(cDate, "yyyy-MM-dd")}
                    />
                </Col>
                <Col xs={12} md={6}>
                    <ButtonInput
                        label="Empleado"
                        inputName="staff"
                        value={filters.nStaff}
                        setModalTable={setModalTable}
                        clearFun={() => onChange({ target: { name: "staff", value: "", nStaff: "" } })}
                    />
                </Col>
            </Row>

            <Datatable
                headers={headers}
                columns={columns}
                columnDefs={columnDefs}
                petition={getData}
                order={{ col: 1, opt: "asc" }}
                control="back"
                stateRefresh={[refresh, setRefresh]}
                searching={true}
                className="px-0 mt-4"
            />

            {
                permissions.UPD && type == 0 &&
                <div className="mt-3 mb-5 d-flex flex-md-row flex-column-reverse justify-content-md-around">
                <Button color="danger" type="button" className="mt-3" onClick={()=>showModal(129)}>
                    Denegar
                </Button>
                <Button color="success" type="button" className="mt-2 mt-md-3" onClick={()=>showModal(128)}>
                    Autorizar
                </Button>
                </div>
            }

            <ModalTableVer
                modal={modalTable}
                setModal={setModalTable}
                title="Empelados"
                cabeceras={["#","Núm. Empleado","Nombre","Unidad de trabajo","Puesto","Seleccionar"]}
                filtro={[true, true, true, true, true, true, true]}
                parametros={modalTableParams}
                selectRegistro={(row) => selectRow(row)}
            />
            
            <ModalConfirmation
                modalTitle={"Registrar"}
                modal={modalConfirm}
                crear={updateIncidents}
                setModal={setModalConfirm}
            >
                <div className="d-flex justify-content-center">
                    <h6>
                        ¿Está seguro de 
                        {newStatus === 128? " autorizar " : " rechazar "}
                        {all ? "todas las " : "estas "}
                        incidecias?
                        </h6>
                </div>
            </ModalConfirmation>

            <FullFormLoader show={loading} />
        </div>
    )
}

function HeaderCheck({ all, setAll, setSelected }) {
    const selectAll = () => {
      if (!all) setSelected([]);
      setAll(!all);
    };
    return (
      <div className="form-check checkbox checkbox-primary d-flex justify-content-center">
        <input
          id="all"
          type="checkbox"
          className="styled"
          checked={all}
          onChange={() => selectAll()}
        />
        <label />
      </div>
    );
  }
import { useState } from "react";
import Widget from "../../../components/Widget/Widget";
import { GetPermissions } from "../../../libs/permissions/getPermissions";
import Form from "./Form";

export default function ConciliacionIssemym(){
    const [permissions, setPermissions] = useState({ id: 0 });
    GetPermissions(setPermissions);

    return(
        <Widget className="widget-p-md">
            <div className="mt-4">
                <h4>Conciliación ISSEMYM</h4>
            </div>
            <Form permissions={permissions} />
        </Widget>
    )
}
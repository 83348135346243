import * as XlsxPopulate from "xlsx-populate";
import { saveAs } from "file-saver";
import getLayout from "../../../components/excel/layoutExcel";

const s = {
  tC: { horizontalAlignment: "center", verticalAlignment: "center" },
  tS: { horizontalAlignment: "left" },
  tE: { horizontalAlignment: "right", verticalAlignment: "center" },
  header: {
    horizontalAlignment: "center",
    bold: true,
    verticalAlignment: "center",
    fill: "730A29",
    fontColor: "FFFFFF",
    wrapText: true,
    shrinkToFit: true,
    border: true,
  },
  tt: {
    bold: true,
    horizontalAlignment: "center",
    verticalAlignment: "center",
    wrapText: true,
    shrinkToFit: true,
  },
  tB: { bold: true },
  tN: {
    border: true,
    wrapText: true,
    shrinkToFit: true,
    verticalAlignment: "center",
  },
  tNC: {
    horizontalAlignment: "center",
    verticalAlignment: "center",
    wrapText: true,
    shrinkToFit: true,
    border: true,
  },
  tNE: {
    horizontalAlignment: "right",
    verticalAlignment: "center",
    wrapText: true,
    shrinkToFit: true,
    border: true,
  },
  wt: { wrapText: true, verticalAlignment: "center" },
};
const headers = [["No.", "No. Cheque", "Beneficiario", "Concepto", "Importe"]];

export async function excelCheques(emp, che, pe) {
  const excel = await getLayout();
  if (excel)
    XlsxPopulate.fromDataAsync(excel).then(async (workbook) => {
      const hoja = workbook.sheet(0);

      let cRow = 4;

      for (let i = 1; i <= 5; i++) {
        hoja.column(i).width(i === 3 ? 40 : i === 4 ? 60 : 20);
      }

      cRow = drawTable(
        hoja,
        emp,
        "RELACIÓN DE CHEQUES DE NÓMINA PARA FIRMA",
        cRow,
        `PAGO DE SERVIDORES PÚBLICOS, PERIODO ${pe}`
      );
      cRow = drawTable(
        hoja,
        che,
        "RELACIÓN DE CHEQUES DE PENSIONES PARA FIRMA",
        cRow + 4,
        `PAGO DE PENSIONES, PERIODO ${pe}`
      );

      return workbook.outputAsync().then((res) => {
        saveAs(res, "RELACIÓN DE CHEQUES.xlsx");
      });
    });
}

function drawTable(hoja, banks, tt, r, c) {
  hoja.range(`A${r}:E${r}`).merged(true).value(tt).style(s.tt);
  for (let b = 0; b < banks.length; b++) {
    r++;
    hoja
      .row(r + 1)
      .cell(1)
      .value("BANCO:")
      .style(s.tB);
    hoja
      .row(r + 1)
      .cell(2)
      .value(banks[b].banco);
    hoja
      .row(r + 2)
      .cell(1)
      .value("NO. DE CUENTA:")
      .style(s.tB);
    hoja
      .row(r + 2)
      .cell(2)
      .value(banks[b].cuenta);
    hoja
      .row(r + 3)
      .cell(1)
      .value("CLABE:")
      .style(s.tB);
    hoja
      .row(r + 3)
      .cell(2)
      .value(banks[b].clave);
    r += 5;
    hoja.row(r).cell(1).value(headers).style(s.header);
    for (let i = 0; i < banks[b].cheques.length; i++) {
      const emp = banks[b].cheques[i];
      r++;
      hoja
        .row(r)
        .cell(1)
        .value(i + 1)
        .style(s.tNC);
      hoja.row(r).cell(2).value(emp.numberCheck).style(s.tNC);
      hoja.row(r).cell(3).value(emp.nombreEmpleado).style(s.tN);
      hoja.row(r).cell(4).value(c).style(s.tN);
      hoja
        .row(r)
        .cell(5)
        .value("$" + emp.monto)
        .style(s.tNE);
    }
    r++;
  }
  return r;
}

import axios from "axios";
import { peticionEncript } from "../../../helpers/peticionesEncripted";
import { peticionesReceiver } from "../../../helpers/peticionesReceiver";
const API = peticionesReceiver();
const defaultForm = {
  supportPS:'',
  supportPB: '',
  supportGA: '',
  supportFSSR: '',
  supportSS:'',
  supportOSCI: '',
  quotaOSCI: '',
  quotaSSR: '',
  quotaSS: '',
  minSS: '',
  maxSS: '',
  smi: '',
  uma: '',
  averageDays: '',
  naturalDays: ''
};

export const createConfiguration = async (configuration) => {
  if (configuration) {
    const params = {
      action: "saveConfigurationPayroll",
      rows: configuration,
      validate: [
        ["naturalDays","averageDays","uma","smi","maxSS","minSS","quotaSS","quotaSSR","quotaOSCI","supportOSCI","supportSS","supportFSSR","supportGA","supportPB","supportPS"]
      ]
    }; 
    const finalData = peticionEncript(params)
    try {
      const res = await axios.post(`${process.env.REACT_APP_API}app/facades/payslips/payrollF.php`, finalData)
      if (res.status === 200 && res.data.status === "success") {
        return {success: true}
      }
      else{
        return {success: false, message: res.data.message}
      }
    } catch (error) {
      return {success: false, message: error}
    }
  }
};

export const getConfiguration = async () => {
  const paramsJobs = {
    action: "select",
    table: 'valuesworkouts',
    condition: {
      enabled: 1
    }
  }
  try {
    const result = await API.peticion(paramsJobs)
    if (result.status === 200 && result.data.code === "200") {
      return {success: true, data: result.data.data}
    }
    else{
      return {success: false, message: result.data.message}
    }
  } catch (error) {
    return {success: false, message: error}
  }
}
import React, { useState, useEffect } from "react";
import { Spinner, Table } from "reactstrap";
import { toast } from "react-toastify";
import Notification from "../../../components/Notification/Notification";
import { peticionesReceiver } from "../../../helpers/peticionesReceiver";
import { actionsModules, dependientPermissions } from "./acctionsModules";
import FullScreenLoader from "../../../components/Loader/FullScreenLoader";

const okArr = [1, -1];
const scp = {
  width: "10%",
  minWidth: "10%",
  maxWidth: "10%",
  color: "#FFFFFF",
};


const AsigaAcciones = ({ perfilS, permissions }) => {
  const API = peticionesReceiver();
  const [perfil] = useState(perfilS);
  const [modulos, setModulos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [modal, setModal] = useState(false);

  useEffect(() => {
    getModulos();
  }, []);

  /*Funcion para consultar los modulos y permisos para el perfil seleccionado*/
  const getModulos = async () => {
    const data = {
      action: "searchPermissionsModules",
      rows: {
        keySystem: perfil.system,
        keyProfile: perfil.value,
      },
      order: "nameModule desc",
    };
    setLoading(true);
    await API.peticionPerfilesMod(data)
      .then((res) => {
        if (res.status === 200 && res.data.status === "success") {
          let datos = [];
          datos = datos.concat(haveChildrens(res.data.data, 1));
          setModulos(datos);
        } else
          toast(<Notification type={"consultar_error"} withIcon />, {
            closeButton: false,
          });
      })
      .catch((err) =>
        toast(<Notification type={"consultar_servidor_error"} withIcon />)
      );
    setLoading(false);
  };

  /** Funcion recursiva para organziar y guardar los modulos
   * @param {Array<>} arr - hijos del modulo
   */
  const haveChildrens = (arr) => {
    let datos = [];
    arr.forEach((md) => {
      const d = actionsModules.find((amd) => +amd.idModule === +md.idModule); // Busca si el modulo tiene permisos desactivamos
      let modulo = {
        idModule: md.idModule,
        keySystem: md.keySystem,
        nameModule: md.nameModule,
        idProfileModule: md.idProfileModule,
        // Asigna los permisos del modulo. -1 : desactivado, 0: no otorgado, 1 : otorgado
        CON: d ? (!d.permissions.CON ? -1 : +md.CON) : +md.CON,
        INS: d ? (!d.permissions.INS ? -1 : +md.INS) : +md.INS,
        UPD: d ? (!d.permissions.UPD ? -1 : +md.UPD) : +md.UPD,
        DEL: d ? (!d.permissions.DEL ? -1 : +md.DEL) : +md.DEL,
      };
      if (md.children) {
        modulo.childs = haveChildrens(md.children);
      }
      datos.push(modulo);
    });
    return datos;
  };

  /** OnChange de checkbox
   * @param {EventTarget} t - target input
   * @param {string} field - Nombre del permiso
   * @param {Object} el - Modulo
   * @param {Object} ix - Indice del modulo
   * @param {Array<string>} ip - Areglo dcon indices de ancetros del modulo
   */
  const changeCheck = (t, field, el, ix, ip) => {
    if (permissions.UPD) changePermiso(field, t.checked, el, ix, ip);
  };

  /** Actualizar los permisos del modulo
   * @param {string} field - Nombre del permiso
   * @param {boolean} val - nuevo estado del checkbox (EventTarget)
   * @param {Object} el - Modulo
   * @param {Object} ix - Indice del modulo
   * @param {Array<string>} ip - Areglo dcon indices de ancetros del modulo
   */
  const changePermiso = async (field, val, el, ix, ip) => {
    setModal(true);
    const rows = {
      idModule: el.idModule,
      keyProfile: perfil.value,
      idProfileModule: el.idProfileModule,
    };
    const depPerm = dependientPermissions["m" + el.idModule]; // Buscar si un permiso dependen del permiso de consulta (CON)
    const valNum = val ? 1 : 0;
    if (field === "ALL") {
      // para otorgar/remover todos los permisos
      rows.CON = el.CON === -1 ? 0 : valNum;
      rows.INS = el.INS === -1 ? 0 : valNum;
      rows.UPD = el.UPD === -1 ? 0 : valNum;
      rows.DEL = el.DEL === -1 ? 0 : valNum;
    } else if (depPerm) {
      // Cuando existe una dependencia de los permisos INS/UPD/DEL del permiso CON
      if (field === "CON" && !val) {
        // Si se remueve el permiso CON, remueve el resto de permisos dependientes
        rows.CON = 0;
        rows.INS = 0;
        rows.UPD = 0;
        rows.DEL = 0;
      } else if (field !== "CON") rows.CON = 1; // Si se otorga el permiso INS/UPD/DEL, tambien se debe asignar el permiso CON
      if(depPerm.inverse){ // Si el pérmiso CON depende de INS/UPD/DEL (Modulos de solo INS/UPD/DEL)
        if(depPerm.inverse.includes(field)) rows.CON = valNum;
        else if(field === "CON") depPerm.inverse.forEach((col)=>rows[col] = valNum);
      }
    }
    rows[field] = valNum;
    const data = { action: "saveProfileModule", rows: rows };
    await API.peticionPerfilesMod(data).then((res) => {
      if (res.status === 200 && res.data.status === "success") {
        toast(
          <Notification
            type={"modifica_exito"}
            backMessage={res.data.message}
            withIcon
          />
        );
        uploadValueOnView(field, ix, valNum, el, ip, depPerm, res.data.data[0].idProfileModule);
      } else {
        toast(
          <Notification
            type={"modifica_error"}
            backMessage={res.data.message}
            withIcon
          />
        );
      }
    });
    setModal(false);
  };

  /** Actualizar la vista con los nuevos permisos del modulo
   * @param {string} field - Nombre del permiso
   * @param {Object} ix - Indice del modulo
   * @param {number} valNum - nuevo estado del checkbox
   * @param {Object} el - Modulo
   * @param {Array<string>} ip - Areglo con indices de ancestros del modulo
   * @param {Object} depPerm - Modulo objeto - existe si algun permiso depenede de otro
   * @param {Object} idRes - Cuando se crea por primera vez el permiso, se debe guardar el id del permiso 
   */
  const uploadValueOnView = (field, ix, valNum, el, ip, depPerm, idRes) => {
    let cpModules = [...modulos];
    let fnd = cpModules;
    ip.forEach((p) => {
      // recorre el arreglo de indices de ancestros del modulo modificado,
      fnd = fnd[p].childs; // para encontrar el modulo a modificar en el arreglo de modulos
    });
    if (field === "ALL") {
      // para marcar/desmarcar todos los permisos
      fnd[ix].CON = el.CON === -1 ? -1 : valNum;
      fnd[ix].INS = el.INS === -1 ? -1 : valNum;
      fnd[ix].UPD = el.UPD === -1 ? -1 : valNum;
      fnd[ix].DEL = el.DEL === -1 ? -1 : valNum;
    } else if (depPerm) {
      // Cuando existe una dependencia de los permisos INS/UPD/DEL del permiso CON
      if (field === "CON" && valNum === 0) {
        // Si se desmarca el permiso CON, desmarca el resto de permisos dependientes
        fnd[ix].CON = el.CON === -1 ? -1 : 0;
        fnd[ix].INS = el.INS === -1 ? -1 : 0;
        fnd[ix].UPD = el.UPD === -1 ? -1 : 0;
        fnd[ix].DEL = el.DEL === -1 ? -1 : 0;
      } else if (field !== "CON") fnd[ix].CON = 1; // Si se otorga el permiso INS/UPD/DEL, tambien se debe marcar el permiso CON
      if(depPerm.inverse){ // Si el permiso CON depende de INS/UPD/DEL (Modulos de solo INS/UPD/DEL)
        if(depPerm.inverse.includes(field)) fnd[ix].CON = valNum;
        else if(field === "CON") depPerm.inverse.forEach((col)=>fnd[ix][col] = valNum);
      }
    }
    fnd[ix][field] = valNum;
    if(fnd[ix]["idProfileModule"] === "") fnd[ix]["idProfileModule"] = idRes;
    setModulos(cpModules);
  };

  return (
    <div>
      <h5>Permisos de perfil por módulo</h5>
      {loading ? (
        <div className="py-5 text-center">
          <Spinner />
        </div>
      ) : (
        <Table
          className={`table-striped border border-default mt-2`}
          responsive
        >
          <thead className="bg-default text-center">
            <tr>
              <th className="text-light text-left">Módulo</th>
              <th style={scp}>Todos</th>
              <th style={scp}>Consultar</th>
              <th style={scp}>Crear</th>
              <th style={scp}>Editar</th>
              <th style={scp}>Eliminar</th>
            </tr>
          </thead>
          <tbody>
            {modulos.map((m, ix) => (
              <RowModule
                mod={m}
                ix={ix}
                ip={[]}
                onChange={changeCheck}
                lvl={1}
                key={"rm" + m.idModule}
              />
            ))}
          </tbody>
        </Table>
      )}
      <FullScreenLoader
        show={modal}
        message="Editando permiso..."
        color="white"
      />
    </div>
  );
};
export default AsigaAcciones;

const RowModule = ({ mod, ix, ip, onChange, lvl }) => {
  const condition = mod.childs ? true : false;
  const ind = ((lvl - 1) % 3) + 1;
  const st = {
    color: condition && lvl === 1 ? "#314248" : "#252729",
    fontWeight: condition ? (lvl === 1 ? "700" : "600") : "500",
    marginLeft: 11 * lvl,
    listStyleType: ind === 1 ? "disc" : ind === 2 ? "square" : "circle",
  };

  const allChecked = (mod) => {
    return (
      okArr.includes(mod.CON) &&
      okArr.includes(mod.INS) &&
      okArr.includes(mod.UPD) &&
      okArr.includes(mod.DEL)
    );
  };

  return (
    <>
      <tr className={st.tableText}>
        <td>
          <li style={st}>{mod.nameModule}</li>
        </td>
        {!condition ? (
          <>
            <td>
              <div className="form-check checkbox checkbox-primary d-flex justify-content-center">
                <input
                  id={mod.nameModule + "ALL"}
                  type="checkbox"
                  className="styled"
                  checked={allChecked(mod)}
                  onChange={({ target }) =>
                    onChange(target, "ALL", mod, ix, ip)
                  }
                />
                <label />
              </div>
            </td>
            <td>
              <div className="form-check checkbox checkbox-primary d-flex justify-content-center">
                <input
                  id={mod.nameModule + "CON"}
                  type="checkbox"
                  className="styled"
                  checked={mod.CON === 1}
                  onChange={({ target }) =>
                    onChange(target, "CON", mod, ix, ip)
                  }
                  disabled={mod.CON === -1}
                />
                <label />
              </div>
            </td>
            <td>
              <div className="form-check checkbox checkbox-primary d-flex justify-content-center">
                <input
                  id={mod.nameModule + "INS"}
                  type="checkbox"
                  className="styled"
                  checked={mod.INS === 1}
                  onChange={({ target }) =>
                    onChange(target, "INS", mod, ix, ip)
                  }
                  disabled={mod.INS === -1}
                />
                <label />
              </div>
            </td>
            <td>
              <div className="form-check checkbox checkbox-primary d-flex justify-content-center">
                <input
                  id={mod.nameModule + "UPD"}
                  type="checkbox"
                  className="styled"
                  checked={mod.UPD === 1}
                  onChange={({ target }) =>
                    onChange(target, "UPD", mod, ix, ip)
                  }
                  disabled={mod.UPD === -1}
                />
                <label />
              </div>
            </td>
            <td>
              <div className="form-check checkbox checkbox-primary d-flex justify-content-center">
                <input
                  id={mod.nameModule + "DEL"}
                  type="checkbox"
                  className="styled"
                  checked={mod.DEL === 1}
                  onChange={({ target }) =>
                    onChange(target, "DEL", mod, ix, ip)
                  }
                  disabled={mod.DEL === -1}
                />
                <label />
              </div>
            </td>
          </>
        ) : (
          <>
            <td>
              <div className="form-check checkbox checkbox-primary d-flex justify-content-center">
                <input
                  id={mod.nameModule + "ALL"}
                  type="checkbox"
                  className="styled"
                  checked={allChecked(mod)}
                  onChange={({ target }) =>
                    onChange(target, "ALL", mod, ix, ip)
                  }
                />
                <label />
              </div>
            </td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </>
        )}
      </tr>
      {condition &&
        mod.childs.map((sm, ixs) => (
          <RowModule
            mod={sm}
            ix={ixs}
            ip={[...ip, ix]}
            onChange={onChange}
            lvl={lvl + 1}
            key={"rms" + sm.idModule}
          />
        ))}
    </>
  );
};

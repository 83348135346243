import * as Yup from "yup";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { Col, Row, Button } from "reactstrap";
import React, { useState, useEffect } from "react";
import { ErrorMessage, useFormikContext, useFormik } from "formik";

import Loader from "../../../components/Loader/Loader";
import { peticionesReceiver } from "../../../helpers/peticionesReceiver";
import Notification from "../../../components/Notification/Notification";
import ModalConfirmation from "../../../components/Modal/ModalConfirmation";
import { SelectTypeHeadSingle, TextAreaInput, TextInput } from "../../../components/GenericInputsFormik";

const FormPlazas = ({ crear, data, editar, specFunction }) => {

    const defaultForm = {
        idJobPosition: "",
        keyPositionType: 0,
        keyJob: 0,
        idSalary: 0,
        idArea: 0,
        idBuilding: 0,
        notes: "",
        keyStat: 0,
        idCCT: ""
    };

    const history = useHistory();
    const API = peticionesReceiver();

    const [modal, setModal] = useState(false);
    const [arrays] = useState({ positionType: [], jobs: [], salaries: [], areas: [], buildings: [], status: [], ccts: [] })
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        if (data) {
            getJobById(Number(data[0]));
        }
        getAllData();
    }, [])

    const getJobById = async (id) => {
        const params = {
            action: "select",
            table: 'jobpositions',
            condition: {
                enabled: 1,
                idJobPosition: id
            }
        };
        const res = await API.peticion(params)
        if (res.status === 200 && res.data.code === "200") {
            const data = res.data.data[0];
            setValues({
                idJobPosition: data.idJobPosition,
                keyPositionType: data.keyPositionType,
                keyJob: data.keyJob,
                idSalary: data.idSalary,
                idArea: data.idArea,
                idBuilding: data.idBuilding,
                notes: data.notes,
                keyStat: data.keyStat,
                idCCT: data.idCCT,
                numberJobPositions: data.numberJobPositions
            }, true);
        }
        else {
            toast(<Notification type={"consultar_error"} withIcon />);
        }
    }

    const getAllData = async () => {
        const result = await Promise.all([
            getData("positiontypes", ["namePositionType", "keyPositionType"], 'positionType', "namePositionType"),
            getData("jobs", ["nameJob", "keyJob"], 'jobs', "nameJob"),
            getData("salaries", ["idSalary", "level", "subLevel", "rango", "base", "net", "gross"], 'salaries', "level"),
            getData("areas", ["name", "idArea"], 'areas', "name"),
            getData("buildings", ["nameBuilding", "idBuilding"], 'buildings', "nameBuilding"),
            getData("status", ["nameStat", "keyStat"], 'status', "nameStat"),
            getData("cct", ["nameCCT", "idCCT "], 'ccts', "nameCCT"),
        ])
        if (result.some(it => it === true)) {
            // setForm({ ...form, keyStat: arrays.status[0].value })
            setIsLoading(false);
        }
    }

    const getData = async (table, values, nameValueArray, column) => {
        const params = {
            action: "select",
            table: table,
            condition: table === 'status' ? {
                enabled: 1,
                keyTypeStat: 2
            }
                :
                {
                    enabled: 1,
                },
            order: `${column} ASC`,
        };
        const res = await API.peticion(params)
        if (res.status === 200 && res.data.code === "200") {
            const data = res.data.data.map((el) => { return el })
            arrays[nameValueArray] = data
            return true
        }
        else {
            toast(<Notification type={"consultar_error"} withIcon />);
            return false
        }
    };

    const FormSchema = Yup.object().shape({
        keyPositionType: Yup.number()
            .moreThan(0, "Favor de seleccionar un tipo de plaza")
            .required("Favor de seleccionar un tipo de plaza"),
        idSalary: Yup.number()
            .moreThan(0, "Favor de seleccionar un salario"),
        idArea: Yup.number()
            .moreThan(0, "Favor de seleccionar una unidad de trabajo"),
        idBuilding: Yup.number()
            .moreThan(0, "Favor de seleccionar un edificio donde se desempeñara")
            .required("Favor de seleccionar un edificio donde se desempeñara"),
        keyStat: Yup.number()
            .required("Favor de seleccionar el estado del salario"),
        keyJob: Yup.number()
            .moreThan(0, "Favor de seleccionar un puesto laboral")
            .required("Favor de seleccionar un puesto laboral"),
        idCCT: Yup.number().required("Seleccione una clave"),
    });

    const {
        handleSubmit,
        values,
        handleBlur,
        errors,
        touched,
        setFieldValue,
        handleReset,
        setValues
    } = useFormik({
        initialValues: defaultForm,
        validationSchema: FormSchema,
        onSubmit: () => setModal(true),
        enableReinitialize: true,
    });

    function localReset(res){
        if(specFunction)specFunction(res);
        else history.push("/template/plazas-laborales");
        handleReset();
    }

    function onChange({ target }) {
        setFieldValue(target.name, target.value);
    }

    if (isLoading) {
        return (
            <Loader />
        );
    }

    return (
        <>
            <form className="mt-4" onSubmit={handleSubmit} onReset={localReset}>
                <Row>
                    <Col xs="12" md="6">
                        <div className="form-group">
                            <TextInput
                                label="Número de Plaza"
                                type="text"
                                value={values.numberJobPositions} 
                                isDisabled 
                            />
                        </div>
                    </Col>

                    <Col xs="12" md="6">
                        <SelectTypeHeadSingle
                            label="Tipo de Plaza"
                            isRequired={true}
                            inputName="keyPositionType"
                            optionsArray={arrays.positionType}
                            defaultOption="Seleccione un tipo de plaza"
                            onChangeMethod={onChange}
                            onBlurMethod={handleBlur}
                            isDisabled={false}
                            value={values.keyPositionType}
                            touched={touched.keyPositionType}
                            errors={errors.keyPositionType}
                            optionValue="keyPositionType"
                            optionName="namePositionType"
                        />
                    </Col>

                    <Col xs="12" md="6">
                        <SelectTypeHeadSingle
                            label="Puesto de Trabajo"
                            isRequired={true}
                            inputName="keyJob"
                            optionsArray={arrays.jobs}
                            defaultOption="Seleccione un puesto de trabajo"
                            onChangeMethod={onChange}
                            onBlurMethod={handleBlur}
                            isDisabled={false}
                            value={values.keyJob}
                            touched={touched.keyJob}
                            errors={errors.keyJob}
                            optionValue="keyJob"
                            optionName="nameJob"
                        />
                    </Col>

                    <Col xs="12" md="6">
                        <SelectTypeHeadSingle
                            label="Tabuladores de Sueldos y Salarios"
                            isRequired={true}
                            inputName="idSalary"
                            optionsArray={arrays.salaries}
                            defaultOption="Seleccione un tabulador de sueldos y salarios"
                            onChangeMethod={onChange}
                            onBlurMethod={handleBlur}
                            isDisabled={false}
                            value={values.idSalary}
                            touched={touched.idSalary}
                            errors={errors.idSalary}
                            multipleLabels={["level", "subLevel", "rango", "base", "net", "gross"]}
                            optionSubLabel={[false, false, false, 'Salario Base', 'Salario Neto', 'Salario Bruto']}
                            optionName="name"
                            optionValue="idSalary"
                        />
                    </Col>

                    <Col xs="12" md="6">
                        <SelectTypeHeadSingle
                            label="Unidad de Trabajo de la Plaza"
                            isRequired={true}
                            inputName="idArea"
                            optionsArray={arrays.areas}
                            defaultOption="Seleccione una unidad de trabajo de la plaza"
                            onChangeMethod={onChange}
                            onBlurMethod={handleBlur}
                            isDisabled={false}
                            value={values.idArea}
                            touched={touched.idArea}
                            errors={errors.idArea}
                            optionValue="idArea"
                            optionName="name"
                        />
                    </Col>

                    <Col xs="12" md="6">
                        <SelectTypeHeadSingle
                            label="Edificio de la plaza"
                            isRequired={true}
                            inputName="idBuilding"
                            optionsArray={arrays.buildings}
                            defaultOption="Seleccione un edificio de la plaza"
                            onChangeMethod={onChange}
                            onBlurMethod={handleBlur}
                            isDisabled={false}
                            value={values.idBuilding}
                            touched={touched.idBuilding}
                            errors={errors.idBuilding}
                            optionValue="idBuilding"
                            optionName="nameBuilding"
                        />
                    </Col>

                    <Col xs="12" md="6">
                        <SelectTypeHeadSingle
                            label="CCT"
                            inputName="idCCT"
                            optionsArray={arrays.ccts}
                            defaultOption="Seleccione una clave"
                            onChangeMethod={onChange}
                            onBlurMethod={handleBlur}
                            isDisabled={false}
                            value={values.idCCT}
                            touched={touched.idCCT}
                            errors={errors.idCCT}
                            optionValue="idCCT"
                            optionName="nameCCT"
                            isRequired
                        />
                    </Col>

                    <Col xs="12" md="6">
                        <SelectTypeHeadSingle
                            label="Estado"
                            isRequired={true}
                            inputName="keyStat"
                            optionsArray={arrays.status}
                            defaultOption="Seleccione un estado"
                            onChangeMethod={onChange}
                            onBlurMethod={handleBlur}
                            isDisabled={false}
                            value={values.keyStat}
                            touched={touched.keyStat}
                            errors={errors.keyStat}
                            optionValue="keyStat"
                            optionName="nameStat"
                        />
                    </Col>

                </Row>

                <TextAreaInput
                    label="Información Adicional"
                    inputName="notes"
                    inputRows={10}
                    onChangeMethod={onChange}
                    onBlurMethod={handleBlur}
                    value={values.notes}
                />

                <div className=" d-flex flex-md-row flex-column-reverse justify-content-md-around">
                    {!specFunction && <Button color="danger" type="reset" className="mt-3">
                        Cancelar
                    </Button>}
                    <Button color="success" type="submit" className="mt-2 mt-md-3">
                        Guardar
                    </Button>
                </div>
            </form>
            <ModalConfirmation
                modalTitle={data ? "Editar" : "Crear"}
                modal={modal}
                setModal={setModal}
                crear={crear}
                editar={editar}
                isEdit={data ? true : false}
                values={values}
                reset={localReset}
            >
                {data ? (
                <div className="d-flex justify-content-center">
                    <h6>¿Está seguro de editar el registro?</h6>
                </div>
                ) : (
                <div className="d-flex justify-content-center">
                    <h6>¿Desea continuar con el registro?</h6>
                </div>
                )}
            </ModalConfirmation>
        </>
    );
};

// const FormikData = ({ info, selects }) => {
//     const { setFieldValue } = useFormikContext();
//     useEffect(() => {
//         if (info) {
//             // setFieldValue("idJobPosition", Number(info[0]));
//             // // setFieldValue("numberJobPositions", Number(info[1]));
//             // getDataByID('keyJob','jobs',{firstCondition: 'nameJob', secondCondition: info[2]})
//             // getDataByID('keyPositionType','positionType',{firstCondition: 'namePositionType', secondCondition: info[6]})
//             // getDataByID('idArea','areas',{firstCondition: 'name', secondCondition: info[7]})
//             // getDataByID('idBuilding','buildings',{firstCondition: 'nameBuilding', secondCondition: info[8]})
//             // getDataByID('keyStat','status',{firstCondition: 'nameStat', secondCondition: info[9]})
//             // setFieldValue('idCCT', info[10])
//         }
//     }, []);

//     const getDataByID = (value, table, { firstCondition, secondCondition }) => {
//         if (secondCondition) {
//             const result = selects[table].filter(it => it[firstCondition] == secondCondition)[0]
//             setFieldValue(value, result[value]);
//         }
//     }
//     return null;
// };

export default FormPlazas;

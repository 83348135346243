import React from "react";

import Widget from "../../../../components/Widget/Widget";
import FormMunicipio from "../FormMunicipio";
import { createCounty } from "../functions";

export const CrearMunicipio = (props) => {
  return (
    <Widget className="widget-p-md">
        <h3>Crear municipio</h3>
        <FormMunicipio crear={createCounty}/>
    </Widget>
  )
}

export default CrearMunicipio;
import {
    StyleSheet
  } from "@react-pdf/renderer";

  const colors = {
    backgroundHeaders: "#646464"
  }
  
export const styles = StyleSheet.create({
  /* MEtodos para generar la tabla con varios campos dentro */
  body: {
    paddingTop: 35,
    paddingBottom: 35,
    paddingHorizontal: 10,
  },
  bodyTableGeneral: {
    paddingTop: 35,
    paddingBottom: 10,
    paddingHorizontal: 10,
  },
    spaceFooterTableGeneral:{
      fontSize: 12,
      bottom: 10,
      left: 0,
      right: 0,
      width: "auto",
      height: 50, 
      marginHorizontal: 10,
    },
    spaceFooter:{
      fontSize: 12,
      bottom: 10,
      left: 0,
      right: 0,
      width: "auto",
      height: 50, 
      marginHorizontal: 10,
    },
    footer:{
      position: 'absolute',
      fontSize: 12,
      bottom: 10,
      left: 0,
      right: 0,
      width: "auto",
      height: 30, 
      marginHorizontal: 10,
/*       borderColor:"#a9a9a9", */
      borderTopWidth: 1, 
      flexDirection: "row" ,
      alignContent: "space-around",
      alignItems: "center",
    },
    title:{
      width:"100%",
      textAlign: 'center',
      marginBottom: 10,
      /* color: '#a9a9a9', */
      fontSize: 16 ,
    },
    subTitle:{
      width:"100%",
      textAlign: 'center',
      marginBottom: 10,
      /* color: '#a9a9a9', */
      fontSize: 14 ,
      
    },
    textFooter:{
      width: "50%",
      /* color: '#a9a9a9', */
      textAlign:"center",
      fontSize: 8 ,
    },

    table: { 
      display: "table", 
      width: "auto", 
/*       borderStyle: "solid", 
      borderColor: "red",
      borderWidth: 1, 
      borderRightWidth: 0, 
      borderLeftWidth: 0 , */
      margin: 10
    }, 
    //cabeceras
    tableColHeaderNo: { 
      width: "12.5%",
      height: 20, 
      borderStyle: "solid", 
      borderWidth: 1, 
      borderLeftWidth: 0, 
      backgroundColor: colors.backgroundHeaders,
      color:'white',
      textAlign: 'center',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: 9 ,
    }, 
    tableColHeaderNoL: { 
      width: "12.5%",
      height: 20, 
      borderStyle: "solid", 
      borderWidth: 1, 
      backgroundColor: colors.backgroundHeaders,
      color:'white',
      textAlign: 'center',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: 9 ,
    }, 
    tableColHeaderReport: { 
      width: "75%", 
      borderStyle: "solid", 
      borderWidth: 1, 
      borderLeftWidth: 0, 
      backgroundColor: colors.backgroundHeaders,
      color:'white',
      textAlign: 'center',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: 9 ,
    }, 
    tableHeaderComplete: { 
      width: "100%", 
      borderStyle: "solid", 
      borderWidth: 1, 
      borderLeftWidth: 0, 
      borderTopWidth: 0 ,
      backgroundColor: colors.backgroundHeaders,
      color:'white',
      textAlign: 'center',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: 9 ,
    }, 
    
    //contenido
    tableColContentNoBI: { 
      width: "12.5%", 
      borderStyle: "solid", 
      borderWidth: 1, 
      borderLeftWidth: 0, 
      borderTopWidth: 0 ,
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
    }, 

    tableColContentNo: { 
      width: "12.5%", 
      borderStyle: "solid", 
      borderWidth: 1, 
      /* borderLeftWidth: 0,  */
      borderTopWidth: 0 ,
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
    }, 
    
    tableColContentReport: { 
      width: "75%", 
      borderStyle: "solid", 
      borderWidth: 1, 
      borderLeftWidth: 0, 
      borderTopWidth: 0 ,
      
    },
    //filas 
    tableRow: { 
      flexDirection: "row" 
    }, 
    tableColumn: { 
      flexDirection: "column" 
    }, 

    //Filas de contenido, max 3
    displayContent: { 
      paddingLeft: 9, //centra el el content
      margin: 9, 
      /* marginTop: 9,
      marginBottom: 9, */
      flexDirection: "row",
      justifyContent: 'space-around',
    }, 

    //Tamaño de celda deacuerdo a tamaño del contenido
    cellOneColumn: { 
      width: "30%", 
      fontSize: 8 ,
      flexDirection: "row" ,
      justifyContent: 'center',
      alignItems: 'center',
    },
    cell: {
      fontSize: 8 ,
    },
    cellTwoColumn: { 
      width: "63%", 
      fontSize: 8 ,
      flexDirection: "row" ,
      justifyContent: 'center',
      alignItems: 'center',
    },
    /* En caso de usar formato de dos columnas */
    cellTwoColumn2: { 
      width: "80%", 
      fontSize: 8 ,
      justifyContent: 'center',
      alignItems: 'center',
    },
    cellThreeColumn: { 
      width: "96.3%", 
      fontSize: 8 ,
    },
    textBold: { 
      fontFamily: 'Helvetica-Bold'
      /* fontWeight: 900, */
      /* fontFamily: 'Courier-Bold' */
      /* color: 'red' */
    },



    /* -----------------------PERCEPTIONS-----------------------------------  */
    
    tableRowContainer: { 
      width: "100%", 
      flexDirection: "row",
      borderStyle: "solid", 
      borderWidth: 1, 
      borderTopWidth: 0 ,
      borderLeftWidth: 0,  
      borderRightWidth: 1 ,
      textAlign: 'center',
    }, 
    cell50BorderSides: { 
      width: "45%",
      height: 25 ,
      fontSize: 8 ,
      borderRightWidth: 1 ,
      borderLeftWidth: 1 ,
      flexDirection: "row",
      justifyContent: 'space-around',
      alignItems: 'center',
      
    },
    cell50: { 
      width: "50%", 
      height: 25, 
      fontSize: 8 ,
      flexDirection: "row",
      justifyContent: 'space-around',
      alignItems: 'center',
    },
    cell33: { 
      width: "33.3%",
      height: 25,  
      fontSize: 8 ,
      flexDirection: "row",
      justifyContent: 'space-around',
      alignItems: 'center',
    },
    cell33BorderSides: { 
      width: "33.3%", 
      height: 25,  
      fontSize: 8 ,
      borderLeftWidth: 1 ,
      borderRightWidth: 1 ,
      flexDirection: "row",
      justifyContent: 'space-around',
      alignItems: 'center',
    },
    cell33BorderRigth: { 
      width: "33.3%", 
      height: 25,  
      fontSize: 8 ,
      borderRightWidth: 1 ,
      flexDirection: "row",
      justifyContent: 'space-around',
      alignItems: 'center',
    },
    cell100BorderLRB: { 
      width: "100%", 
      height: 25,  
      fontSize: 8 ,
     borderWidth: 1,
     borderTopWidth: 0,
      flexDirection: "row",
      justifyContent: 'space-around',
      alignItems: 'center',
    },
    cell100BorderRigth: { 
      width: "100%", 
      height: 25,  
      fontSize: 8 ,
      flexDirection: "row",
      justifyContent: 'space-around',
      alignItems: 'center',
    },
    cell25: { 
      width: "25%", 
      height: 25,  
      fontSize: 8 ,
      flexDirection: "row",
      justifyContent: 'space-around',
      alignItems: 'center',
    },
    cell25BorderRigth: { 
      width: "25%", 
      height: 25,  
      fontSize: 8 ,
      borderRightWidth: 1 ,
      flexDirection: "row",
      justifyContent: 'space-around',
      alignItems: 'center',
    },
    cell25BorderSides: { 
      width: "25%", 
      height: 25,  
      fontSize: 8 ,
      borderLeftWidth: 1 ,
      borderRightWidth: 1 ,
      flexDirection: "row",
      justifyContent: 'space-around',
      alignItems: 'center',
    },
    cell75: { 
      width: "75%", 
      height: 25,  
      fontSize: 8 ,
      flexDirection: "row",
      justifyContent: 'space-around',
      alignItems: 'center',
    },
    cell75BorderRigth: { 
      width: "75%", 
      height: 25,  
      fontSize: 8 ,
      borderRightWidth: 1 ,
      flexDirection: "row",
      justifyContent: 'space-around',
      alignItems: 'center',
    },
    cell75BorderSides: { 
      width: "75%", 
      height: 25,  
      fontSize: 8 ,
      borderLeftWidth: 1 ,
      borderRightWidth: 1 ,
      flexDirection: "row",
      justifyContent: 'space-around',
      alignItems: 'center',
    },
    text50: {
      width:"50%",
      textAlign: "center"
    },
    tableHeader25: { 
      width: "25%", 
      borderStyle: "solid", 
      borderWidth: 1, 
      borderLeftWidth: 0, 
      borderTopWidth: 0 ,
      backgroundColor: colors.backgroundHeaders,
      color:'white',
      flexDirection: "row",
      justifyContent: 'space-around',
      alignItems: 'center',
      fontSize: 9 ,
    }, 



//_----------------------------------------------------------------------------------------------------------------------------------------------------------------
/* Metodos para generar un tabla  */


    /* General Cells */

    /* Tables */

    table50: { 
      width:"50%",
      display: "table", 
      borderStyle: "solid", 
      margin: 10
    }, 

    tableCell: { 
      width:"auto",
      display: "table", 
      borderStyle: "solid", 
      margin: 10
    }, 
    

    tableRowContainer2: { 
      width: "100%", 
      flexDirection: "row",
      borderStyle: "solid", 
      textAlign: 'center',
    }, 


    /* Headers */

    cellHeader100Borders: { 
      width: "100%", 
      height: 25,  
      fontSize: 9 ,
      borderWidth: 1,
      backgroundColor: colors.backgroundHeaders,
      color:'white',
      flexDirection: "row",
      justifyContent: 'space-around',
      alignItems: 'center',
    },
    
    

    cellContent100LBR: { 
      width: "100%", 
      height: 25,  
      fontSize: 8 ,
      borderWidth: 1,
      borderTopWidth: 0,
      
      flexDirection: "row",
      justifyContent: 'space-around',
      alignItems: 'center',
    },

    cellContent88_7BordersLBR: { 
      width: "88.7%", 
      minHeight:25 ,
      fontSize: 8 ,
      borderWidth: 1, 
      borderTopWidth: 0, 
      flexDirection: "row",
      justifyContent: 'space-around',
      alignItems: 'center',
    },

    cellContent77_77BordersLBR: { 
      width: "77.77%", 
      fontSize: 6 ,
      borderWidth: 1, 
      borderTopWidth: 0, 
      minHeight:25 ,
      flexDirection: "row",
      justifyContent: 'space-around',
      alignItems: 'center',
    },
    cellContent77_77BordersRB: { 
      width: "77.77%", 
      minHeight:25 ,
      fontSize: 6 ,
      borderRightWidth: 1, 
      borderBottomWidth: 1,
      flexDirection: "row",
      justifyContent: 'space-around',
      alignItems: 'center',
    },


    cellHeader75Borders: { 
      width: "75%", 
      height: 25,  
      fontSize: 8 ,
      borderWidth: 1,
       backgroundColor: colors.backgroundHeaders,
      color:'white',
      flexDirection: "row",
      justifyContent: 'space-around',
      alignItems: 'center',
    },
    cellHeader75BordersRTB: { 
      width: "75%", 
      height: 25,  
      fontSize: 8 ,
      borderWidth: 1, 
      borderLeftWidth: 0, 
      backgroundColor: colors.backgroundHeaders,
      color:'white',
      flexDirection: "row",
      justifyContent: 'space-around',
      alignItems: 'center',
    },
    
    cellContent75BordersLBR: { 
      width: "75%", 
      minHeight:25 ,
      fontSize: 8 ,
      borderWidth: 1, 
      borderTopWidth: 0, 
      flexDirection: "row",
      justifyContent: 'space-around',
      alignItems: 'center',
    },
    cellContent75BordersRB: { 
      width: "75%", 
      minHeight:25 ,
      fontSize: 8 ,
      borderRightWidth: 1, 
      borderBottomWidth: 1,
/*       justifyContent: 'center', */      
      flexDirection: "row",
      justifyContent: 'space-around',
      alignItems: 'center',
    },
    cellContent75BordersLBR_6: { 
      width: "75%", 
      minHeight:25 ,
      fontSize: 6 ,
      borderWidth: 1, 
      borderTopWidth: 0, 
      
      flexDirection: "row",
      justifyContent: 'space-around',
      alignItems: 'center',
    },
    cellContent75BordersRB_6: { 
      width: "75%", 
      minHeight:25 ,
      fontSize: 6 ,
      borderRightWidth: 1, 
      borderBottomWidth: 1,
/*       justifyContent: 'center', */      
      flexDirection: "row",
      justifyContent: 'space-around',
      alignItems: 'center',
    },

    cellHeader50Borders: { 
      width: "50%", 
      height: 25,  
      fontSize: 8 ,
      borderWidth: 1,
       backgroundColor: colors.backgroundHeaders,
      color:'white',
      flexDirection: "row",
      justifyContent: 'space-around',
      alignItems: 'center',
    },
    cellHeader50BordersRTB: { 
      width: "50%", 
      height: 25,  
      fontSize: 8 ,
      borderWidth: 1, 
      borderLeftWidth: 0, 
      backgroundColor: colors.backgroundHeaders,
      color:'white',
      flexDirection: "row",
      justifyContent: 'space-around',
      alignItems: 'center',
    },
    cellContent50BordersLBR: { 
      width: "50%", 
      minHeight:25 ,
      fontSize: 8 ,
      borderWidth: 1, 
      borderTopWidth: 0, 
      
      flexDirection: "row",
      justifyContent: 'space-around',
      alignItems: 'center',
    },
    cellContent50BordersLB: { 
      width: "50%", 
      minHeight:25 ,
      fontSize: 8 ,
      borderWidth: 1, 
      borderTopWidth: 0, 
      borderRightWidth: 0, 
      flexDirection: "row",
      justifyContent: 'space-around',
      alignItems: 'center',
    },
    cellContent50BordersRB: { 
      width: "50%", 
      minHeight:25 ,
      fontSize: 8 ,
      borderRightWidth: 1, 
      borderBottomWidth: 1,
/*       justifyContent: 'center', */      
      flexDirection: "row",
      justifyContent: 'space-around',
      alignItems: 'center',
    },
    cellContent25BordersLBR: { 
      width: "25%", 
      fontSize: 8 ,
      borderWidth: 1, 
      borderTopWidth: 0, 
      minHeight:25 ,
      flexDirection: "row",
      justifyContent: 'space-around',
      alignItems: 'center',
    },
    cellContent25BordersRB: { 
      width: "25%", 
      minHeight:25 ,
      fontSize: 8 ,
      borderRightWidth: 1, 
      borderBottomWidth: 1,
      flexDirection: "row",
      justifyContent: 'space-around',
      alignItems: 'center',
    },

    cellHeader25Borders: { 
      width: "25%", 
      height: 25,  
      fontSize: 9 ,
      borderWidth: 1,
       backgroundColor: colors.backgroundHeaders,
      color:'white',
      flexDirection: "row",
      justifyContent: 'space-around',
      alignItems: 'center',
    },
    cellContent25BordersLBR_6: { 
      width: "25%", 
      fontSize:6 ,
      borderWidth: 1, 
      borderTopWidth: 0, 
      minHeight:25 ,
      flexDirection: "row",
      justifyContent: 'space-around',
      alignItems: 'center',
    },
    cellContent25BordersRB_6: { 
      width: "25%", 
      minHeight:25 ,
      fontSize:6 ,
      borderRightWidth: 1, 
      borderBottomWidth: 1,
      flexDirection: "row",
      justifyContent: 'space-around',
      alignItems: 'center',
    },

    cellHeader25BordersRTB: { 
      width: "25%", 
      height: 25,  
      fontSize: 9 ,
      borderWidth: 1, 
      borderLeftWidth: 0, 
      backgroundColor: colors.backgroundHeaders,
      color:'white',
      flexDirection: "row",
      justifyContent: 'space-around',
      alignItems: 'center',
    },


    cellContent20BordersLBR: { 
      width: "20%", 
      fontSize: 8 ,
      borderWidth: 1, 
      borderTopWidth: 0, 
      minHeight:25 ,
      flexDirection: "row",
      justifyContent: 'space-around',
      alignItems: 'center',
    },
    cellContent20BordersRB: { 
      width: "20%", 
      minHeight:25 ,
      fontSize: 8 ,
      borderRightWidth: 1, 
      borderBottomWidth: 1,
      flexDirection: "row",
      justifyContent: 'space-around',
      alignItems: 'center',
    },

    cellHeader20Borders: { 
      width: "20%", 
      height: 25,  
      fontSize: 9 ,
      borderWidth: 1,
       backgroundColor: colors.backgroundHeaders,
      color:'white',
      flexDirection: "row",
      justifyContent: 'space-around',
      alignItems: 'center',
    },
    cellHeader20BordersRTB: { 
      width: "20%", 
      height: 25,  
      fontSize: 9 ,
      borderWidth: 1, 
      borderLeftWidth: 0, 
      backgroundColor: colors.backgroundHeaders,
      color:'white',
      flexDirection: "row",
      justifyContent: 'space-around',
      alignItems: 'center',
    },
    cellContent16BordersLBR: { 
      width: "16%", 
      fontSize: 4 ,
      borderWidth: 1, 
      borderTopWidth: 0, 
      minHeight:25 ,
      flexDirection: "row",
      justifyContent: 'space-around',
      alignItems: 'center',
    },
    cellContent16BordersRB: { 
      width: "16%", 
      minHeight:25 ,
      fontSize: 4 ,
      borderRightWidth: 1, 
      borderBottomWidth: 1,
      flexDirection: "row",
      justifyContent: 'space-around',
      alignItems: 'center',
    },

    cellHeader16Borders: { 
      width: "16%", 
      height: 25,  
      fontSize: 6 ,
      borderWidth: 1,
       backgroundColor: colors.backgroundHeaders,
      color:'white',
      flexDirection: "row",
      justifyContent: 'space-around',
      alignItems: 'center',
    },
    cellHeader16BordersRTB: { 
      width: "16%", 
      height: 25,  
      fontSize: 6 ,
      borderWidth: 1, 
      borderLeftWidth: 0, 
      backgroundColor: colors.backgroundHeaders,
      color:'white',
      flexDirection: "row",
      justifyContent: 'space-around',
      alignItems: 'center',
    },
    
    cellHeader11Borders: { 
      width: "11.11%", 
      height: 25,  
      fontSize: 8 ,
      borderWidth: 1,
       backgroundColor: colors.backgroundHeaders,
      color:'white',
      flexDirection: "row",
      justifyContent: 'space-around',
      alignItems: 'center',
    },
    cellHeader11BordersRTB: { 
      width: "11.11%", 
      height: 25,  
      fontSize: 8 ,
      borderWidth: 1, 
      borderLeftWidth: 0, 
      backgroundColor: colors.backgroundHeaders,
      color:'white',
      flexDirection: "row",
      justifyContent: 'space-around',
      alignItems: 'center',
    },
    cellContent11BordersLBR: { 
      width: "11.11%", 
      fontSize: 6 ,
      borderWidth: 1, 
      borderTopWidth: 0, 
      minHeight:25 ,
      flexDirection: "row",
      justifyContent: 'space-around',
      alignItems: 'center',
    },
    cellContent11BordersRB: { 
      width: "11.11%", 
      minHeight:25 ,
      fontSize: 6 ,
      borderRightWidth: 1, 
      borderBottomWidth: 1,
      flexDirection: "row",
      justifyContent: 'space-around',
      alignItems: 'center',
    },










  });
import React from 'react'
import Widget from '../../../../components/Widget/Widget';
import FormCuentas from '../FormCuentas'
import { insertInfo } from "../functions";

const EditarCuenta = () => {
  return (
    <div>
      <div>
      <Widget className="widget-p-md">
        <h3>Editar Cuenta Contable</h3>
        <FormCuentas data={localStorage.getItem('dataContent').split('|-|')} insertInfo={insertInfo}/>
      </Widget>
    </div>
    </div>
  )
}

export default EditarCuenta
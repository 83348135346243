import { useEffect, useState } from "react";
import Datatable from "../../../components/Datatable/Datatable";
import { doRequest, doRequestSaveRes } from "../../../helpers/requests";
import { fDatewhitTime } from "../../../libs/tools/format";
import { Button, Col, Row } from "reactstrap";
import {
    SelectTypeHeadSingle,
    TextInput,
} from "../../../components/GenericInputsFormik";
import format from "date-fns/format";
import { saveAsExcel } from "../../../components/excel/Excel";
import FullFormLoader from "../../../components/Loader/FullFormLoader";
import { getConfig, paramsReport } from "./reports/configexcels";
import { general } from "./reports/exGeneral";
import { Detalle } from "./reports/exDetalle";
import { ButtonInput } from "../../../components/GenericInputsFormik/ButtonInput";
import ModalTableVer from "../../../components/Modal/ModalTableVer";
import { useRef } from "react";

const headers = [
    "ÁREA SOLICITANTE",
    "FOLIO",
    "JUSTIFICACIÓN",
    "SOLICITA",
    "FECHA RECEPCIÓN",
    "ESTATUS",
    "MONTO SOLICITADO",
    "COMENTARIOS/ OBSERVACIONES",
    "VER",
];

const columnDefs = [
    {
        orderable: false,
        targets: [7],
        className: "text-center",
    },
    {
        orderable: true,
        targets: [1, 4, 5],
        className: "text-center",
    },
    {
        orderable: true,
        targets: [6],
        className: "text-right",
    },
];

const modalTableParams = {
    action: "datatable",
    table: "areas",
    rows: "idArea, name",
    conditions: "enabled = 1",
    page: 0,
    records: 5,
    search: "",
    order: "",
  }

const cDate = new Date();
const cYear = cDate.getFullYear();

export default function ConsultaInicial({ areas, status, setSolicitud }) {
    const [loading, setLoading] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [modalTable, setModalTable] = useState(false);
    const [filters, setFilters] = useState({
        area: "",
        status: "",
        dI: "",
        dF: format(cDate, "yyyy-MM-dd"),
        nArea: "",
        nStatus: ""
    });
    const refStatus = useRef(null);

    const columns = [
        { data: "nameArea", render: (d)=>{return <div style={{minWidth: "200px"}}>{d}</div>} },
        { data: "folio" },
        { data: "justification", render: (d)=>{return <div style={{minWidth: "400px"}}>{d}</div>} },
        { data: "fullName", render: (d)=>{return <div style={{minWidth: "150px"}}>{d}</div>} },
        {
            data: "dateSend",
            render: (d) => {
                return fDatewhitTime(d);
            },
        },
        { data: "nameStat" },
        { data: "requestedAmount", format: "currency" },
        { data: "notes", render: (d)=>{return <div style={{minWidth: "200px"}}>{d}</div>} },
        {
            data: null,
            render: (d) => {
                return (
                    +d.keyStat > 116 && (
                        <i
                            className="fa fa-pencil-square-o text-info cursor-pointer"
                            onClick={() => setSolicitud({id: d.idBudgetRequest, folio: d.folio, fecha: d.dateSend, justificacion: d.justification, estatus: d.keyStat, notas: d.notes})}
                        />
                    )
                );
            },
        },
    ];

    useEffect(() => {
        setRefresh(true);
    }, [filters]);

    /* Table */
    async function getData(ordCol, itemsPerPage, currentPage, search) {
        const res = await doRequestSaveRes(
            "receiver/receiver.php",
            getParams(ordCol, itemsPerPage, currentPage, search),
            false,
            false
        );
        if (res.code === "200") return res;
        else return null;
    }

    function getParams(ordCol, itemsPerPage, currentPage, search) {
        const params = {
            action: "datatable",
            table:
                "budgetrequests A INNER JOIN areas B USING (idArea) INNER JOIN staff C ON C.idStaff = A.idStaffRequest INNER JOIN status D ON D.keyStat = A.keyStat",
            rows: "B.name AS nameArea, CONCAT(A.numberRequest,'/',A.yearRequest) AS folio, A.justification, CONCAT(C.name,' ',C.pName,' ', C.mName) as fullName, A.dateSend, D.nameStat, A.requestedAmount,A.idBudgetRequest, A.keyStat, A.notes",
            conditions: getConditions(),
            order: ordCol,
            records: itemsPerPage.toString(),
            page: (itemsPerPage * currentPage).toString(),
            search
        };
        return params;
    }

    function getConditions() {
        let cond = "A.enabled = 1"; //AND A.keyStat != 116 // AND A.keyStat IN (117, 118)
        if (filters.area) cond += ` AND A.idArea = ${filters.area}`;
        if (filters.status) cond += ` AND A.keyStat = ${filters.status}`;
        if (filters.dI) cond += ` AND IF(ISNULL(A.dateSend), true, A.dateSend >= '${filters.dI} 00:00:00')`;
        if (filters.dF) cond += ` AND IF(ISNULL(A.dateSend), true, A.dateSend <= '${filters.dF} 23:59:59')`;
        return cond;
    }

    const onChange = ({ target }) => {
        setFilters((prevState) => ({
            ...prevState,
            [target.name]: target.value,
            dF: target.name === "dI"
                    ? cmpDates(target.value)
                        ? filters.dF
                        : ""
                    : target.name === "dF"
                        ? target.value
                        : filters.dF,
            nArea: target.name === "area" 
                        ? target.nArea : filters.nArea
                
        }));
    };

    function cmpDates(d1) {
        if (!d1) return true;
        return new Date(d1).getTime() <= new Date(filters.dF).getTime();
    }

    async function genExcel(i){
        setLoading(true);
        const res = await doRequest("receiver/receiver.php", paramsReport(i, getConditions), false);
        if(res.length > 0){
            if(i) await general(res);
            else {
                const titles =  getConditionsExcel();
                await Detalle(res, titles);
            }
        }
        setLoading(false);
    };

    function getConditionsExcel() {
        let cond = [];
        if (filters.area) cond.push(`ÁREA: ${filters.nArea}`);
        if (filters.status) cond.push(`ESTATUS: ${refStatus.current.state.text}`);
        if (filters.dI) {
            const fI =new Date(filters.dI);
            cond.push(`MES: ${fI.toLocaleString('default', { month: 'long' }).toUpperCase()}`);
            cond.push(`AÑO: ${fI.toLocaleString('default', { year: "numeric" })}`);
        }
        if (filters.dF) {
            const ff =new Date(filters.dF);
            if(filters.dI){
                const fI =new Date(filters.dI);
                if(fI.getMonth() !== ff.getMonth()){
                    cond[cond.length-2] = cond[cond.length-2] + ` a ${ff.toLocaleString('default', { month: 'long' }).toUpperCase()}`;
                }
                if(fI.getFullYear() !== ff.getFullYear()){
                    cond[cond.length-1] = cond[cond.length-1] + ` a ${ff.toLocaleString('default', { year: "numeric" })}`;
                }
            }else{
                cond.push(`MES: ${ff.toLocaleString('default', { month: 'long' }).toUpperCase()}`);
                cond.push(`AÑO: ${ff.toLocaleString('default', { year: "numeric" })}`);
            }
        }
        
        return cond;
    }

    function selectRow(row){
        onChange({target: {name: "area", value: row[0], nArea: row[1]}});
        setModalTable(false);
    }

    return (
        <div className="mt-4">
            <Row>
                    <Col xs={12} md={6}>
                        {/* <SelectTypeHeadSingle
                            label="Áreas"
                            inputName="area"
                            onChangeMethod={onChange}
                            onBlurMethod={()=>{}}
                            value={filters.area}
                            optionsArray={areas}
                            optionValue="idArea"
                            optionName="name"
                        /> */}
                        <ButtonInput
                            label="Unidades de trabajo"
                            inputName="area"
                            value={filters.nArea}
                            setModalTable={setModalTable}
                            clearFun={() => onChange({target: {name: "area", value: "", nArea: ""}})}
                        />
                    </Col>
                    <Col xs={12} md={6}>
                        <SelectTypeHeadSingle
                            label="Estatus"
                            inputName="status"
                            onChangeMethod={onChange}
                            onBlurMethod={()=>{}}
                            value={filters.status}
                            optionsArray={status}
                            optionValue="keyStat"
                            optionName="nameStat"
                            refSelect={refStatus}
                        />
                    </Col>
                    <Col xs={12} md={6}>
                        <TextInput
                            label="Fecha Inicial"
                            inputName="dI"
                            inputType="date"
                            onChangeMethod={onChange}
                            onBlurMethod={()=>{}}
                            value={filters.dI}
                            isMin={new Date(cYear - 5, 1, 1)}
                            isMax={format(cDate, "yyyy-MM-dd")}
                        />
                    </Col>
                    <Col xs={12} md={6}>
                        <TextInput
                            label="Fecha final"
                            inputName="dF"
                            inputType="date"
                            onChangeMethod={onChange}
                            onBlurMethod={()=>{}}
                            value={filters.dF}
                            isMin={filters.dI || new Date(cYear - 5, 1, 1)}
                            isMax={format(cDate, "yyyy-MM-dd")}
                        />
                    </Col>
            </Row>
            
            <div className="mb-4 d-flex flex-md-row flex-column justify-content-end">
                <Button color="secondary" className="mx-md-3 mt-2" onClick={()=>genExcel(true)}>Genear Excel</Button>
                <Button color="primary" className="mt-3 mt-md-2" onClick={()=>genExcel(false)}>Genear Excel Detalle</Button>
            </div>

            <Datatable
                headers={headers}
                columns={columns}
                columnDefs={columnDefs}
                petition={getData}
                control="back"
                order={{col: 4, opt: "desc"}}
                stateRefresh={[refresh, setRefresh]}
                searching={true}
                className="px-0"
            />

            <ModalTableVer
                modal={modalTable}
                setModal={setModalTable}
                title="Unidades de trabajo"
                cabeceras={["#", "Unidad de trabajo"]}
                filtro={[false, true]}
                parametros={modalTableParams}
                selectRegistro={(row) => selectRow(row)}
            />

            <FullFormLoader show={loading} message="Generando reporte..." />
        </div>
    );
}

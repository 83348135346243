import * as Yup from "yup"
import { useEffect, useState } from "react";
import { useFormik } from "formik";
import { Button } from "reactstrap";
import { toast } from "react-toastify";


import Widget from "../../../../../components/Widget/Widget";
import { TextAreaInput } from "../../../../../components/GenericInputsFormik";
import { peticionesReceiver } from "../../../../../helpers/peticionesReceiver";
import Notification from "../../../../../components/Notification/Notification";
import ModalConfirmation from "../../../../../components/Modal/ModalConfirmation";

import { GetPermisos } from "../../../../../libs/permissions/hook";

export default function CrearSolicitud() {
    const [modal, setModal] = useState(false)
    const [permisos, setPermisos] = useState("")
    GetPermisos(setPermisos);

    useEffect(() => {
        if (permisos !== "" && permisos.INS === "0") {
            returnMain()
        }
    }, [permisos])

    const returnMain = () => {window.location.href = "#/template/cotizacion"}

    const defaultForm = {
        object: "",
        keyStat: "101",
    }

    const FormSchema = Yup.object().shape({
        object: Yup.string().required("Ingrese una justificación para la solicitud de adquisición de bienes, contratación de servicios y/o tramite de limite presupuestal"),
    })

    const onChange = (e) => {
        const { name, value } = e.target
        setFieldValue([name], value);
    }

    const { handleSubmit, values, handleBlur, errors, touched, setFieldValue } =
        useFormik({
            initialValues: defaultForm,
            onSubmit: () => setModal(true),
            validationSchema: FormSchema,
        });

    function getParams(rows) {
        return {
            table: "quotes",
            action: "insert",
            rows
        }
    }

    const OnCreate = async (rows) => {
        const API = peticionesReceiver();
        try {
            const res = await API.peticion(getParams(rows))
            if (res.status === 200 && res.data.code === "200") {
                toast(<Notification type={"agrega_exito"} backMessage={res.data.message} withIcon />);
                window.location.href = "#/template/cotizacion"
            } else {
                toast(<Notification type={"agrega_error"} backMessage={res.data.message} withIcon />);
            }
        } catch (err) {
            console.log(err)
            toast(<Notification type={"agrega_error"} withIcon />);
        }
    }

    return (
        <>
            <Widget>
                <h3 className="p-3">Crear solicitud de cotizacion</h3>
                <form onSubmit={handleSubmit} className="px-3 pb-4">
                    <TextAreaInput
                        label="Justificación para la solicitud para adquisición"
                        inputName="object"
                        inputRows={10}
                        onChangeMethod={onChange}
                        onBlurMethod={handleBlur}
                        value={values.object}
                        isRequired
                        touched={touched.object}
                        errors={errors.object}
                    />
                    <div className="d-flex justify-content-md-around flex-md-row flex-column-reverse">
                        <Button color="danger" onClick={returnMain} className="mt-2 mb-2">Regresar</Button>
                        <Button color="add" className="mt-2 mb-2">Crear solicitud</Button>
                    </div>
                </form>
            </Widget>

            <ModalConfirmation
                modalTitle={"Crear solicitud"}
                modal={modal}
                crear={(rows) => OnCreate(rows)}
                setModal={setModal}
                values={values}
            >
                <div className="d-flex justify-content-center">
                    <h6>¿Desea continuar con el registro?</h6>
                </div>
            </ModalConfirmation>
        </>
    )
}
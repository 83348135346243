import { peticionesReceiver } from "../../../helpers/peticionesReceiver";
import Notification from "../../../components/Notification/Notification";
import { toast } from "react-toastify";
const API = peticionesReceiver();

export const createArea = async (areaN) => {
  if (areaN) {
    const params = {
      action: "insert",
      table: "areas",
      rows: areaN,
      validate: [["areaKey"], ["idBuilding", "name"]],
    };
    API.peticion(params)
      .then((res) => {
        if (res.status === 200 && res.data.code === "200") {
          toast(
            <Notification
              type={"agrega_exito"}
              backMessage={res.data.message}
              withIcon
            />
          );
          window.location.href = "#/template/areas";
        } else {
          toast(
            <Notification
              type={"agrega_error"}
              backMessage={res.data.message}
              withIcon
            />
          );
        }
      })
      .catch((err) =>
        toast(<Notification type={"consultar_servidor_error"} withIcon />)
      );
  }
};

export const updateArea = async (areaU) => {
  if (areaU) {
    const params = {
      action: "update",
      table: "areas",
      rows: areaU,
      condition: {
        idArea: areaU.idArea,
      },
      validate: [["areaKey"], ["idBuilding", "name"]],
    };
    API.peticion(params)
      .then((res) => {
        if (res.status === 200 && res.data.code === "200") {
          toast(
            <Notification
              type={"modifica_exito"}
              backMessage={res.data.message}
              withIcon
            />
          );
          window.location.href = "#/template/areas";
        } else {
          toast(
            <Notification
              type={"modifica_error"}
              backMessage={res.data.message}
              withIcon
            />
          );
        }
      })
      .catch((err) =>
        toast(<Notification type={"consultar_servidor_error"} withIcon />)
      );
  }
};


import React from "react";
import Widget from "../../../../components/Widget/Widget";
import FormLicencias from "../FormLicencias";
import { createLeave } from "../functions";

export const CrearLicencia = (props) => {

  return (
    <Widget className="widget-p-md">
        <h3>Crear tipo de licencia</h3>
        <FormLicencias crear={createLeave}/>
    </Widget>
  )
}

export default CrearLicencia;
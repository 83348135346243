import { toast } from "react-toastify";

import Notification from "../../../../components/Notification/Notification";
import { peticionesReceiver } from "../../../../helpers/peticionesReceiver";

const API = peticionesReceiver();

export async function getDataArray(action, setData, setLoader) {
    const params = {
        action
    };
    try {
        const res = await API.peticionEndPoint(params, "app/facades/budgets/budgetsF.php")
        if (res.status === 200 && res.data.code === "200") {
            const data = res.data.data;
            if (action === "getChapters") {
                data.forEach(value => value.name = `${value.chapterKey} - ${value.nameChapter}`)
            }
            setData(data)
            setLoader(false)
        } else {
            setData([])
            toast(<Notification type={"consultar_error"} withIcon />);
        }
    } catch (err) {
        toast(<Notification type={"consultar_error"} withIcon />);
    }

}
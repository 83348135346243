import React, { useState, useEffect } from "react";
import { Col, Row, Button } from "reactstrap";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import { useHistory } from "react-router-dom";
import { setYear } from "date-fns";
import * as Yup from "yup";

import Notification from "../../../components/Notification/Notification";
import Widget from "../../../components/Widget/Widget";

import { SelectTypeHeadSingle } from "../../../components/GenericInputsFormik/index";
import { peticionesReceiver } from "../../../helpers/peticionesReceiver";

import FullFormLoader from "../../../components/Loader/FullFormLoader";

import { setConfigR30 } from "../../../libs/reports/finanzas/report30";
import { setConfigR32 } from "../../../libs/reports/finanzas/report32";
import Filtro23303243 from "./filtros/Filtro23303243";

import ModalReports from "../../../components/Modal/ModalReports";
import generatePDFcifras from "./pdf/pdfCifras";
import { saveReport30AsExcel } from "./excel/excelCifras";
import TableComponent from "../../../components/TableComponent/TableComponentReport";
import { saveReport32AsExcel } from "./excel/excelISR";
import generatePDFISR from "./pdf/psfISR";
import exportContributionsIssemym from "./pdf/pdfISSEMYM";
import { setConfigR43 } from "../../../libs/reports/finanzas/report43";
import { saveReport43AsExcel } from "./excel/excelISSEMYM";
import { TableComponentReportWithoutActions } from "../../../components/TableComponent/TableComponentReportWithoutActions";
import { setConfigR23 } from "../../../libs/reports/finanzas/report23";
import { setConfigR24 } from "../../../libs/reports/finanzas/report24";
import exportGeneral from "./pdf/pdfGeneral";
import { saveGeneralReport } from "../../../libs/reports/general/excelGeneral";


import pdfProveedoresN from "./pdf/pdfProveedoresN";
import { excelProveedoresN } from "./excel/excelProveedoresN";

import { setConfigR25 } from "../../../libs/reports/finanzas/report25";
import { saveReport3SRem } from "./excel/excel3srem";

import { configRep25 } from "../../../libs/reports/finanzas/report25";
import exportPDF3srem from "./pdf/pdf3srem";
import { configEnd27, configRep27, setConfigR27 } from "../../../libs/reports/finanzas/report27";
import { savePrisma } from "./excel/excelPrisma";

import exportPrisma from "./pdf/pdfPRISMA";
import { doRequest } from "../../../helpers/requests";
import { GetPermissions } from "../../../libs/permissions/getPermissions";

export const ReportesFinanzas = () => {
  GetPermissions();
  
  const [loader, setLoader] = useState(false);
  const API = peticionesReceiver();

  const history = useHistory();

  //Variables de modalPDF
  const [modalReports, setModalReports] = useState(false);

  //variables para cargar los selects
  const [typeReports, setTypeReports] = useState([]);

  const [defaultForm, setDefaultForm] = useState({
    idFormat: "",
    idReport: "",
    period: "",
    year: "",
    month: "",
    idStateFund: "",
    stateFund: "",
    detalle: "0",
  });

  /* Parametros para enviar a los endPoint */
  const [data, setData] = useState("");
  /* Variable donde se guarda la informacion del reporte */
  const [report, setReport] = useState();
  /**
   *  Variable para decidir tipo de formato
   */
  const [showReportFormat, setShowReportFormat] = useState("0");

  /**
   * Configuración del PDF
   */
  const [pdfConfig, setPdfConfig] = useState("");
  /**
   * Configuración del excel
   */
  const [excelConfig, setExcelConfig] = useState("");
  /**
   * Configuración del html
   */
  const [htmlConfig, setHtmlConfig] = useState("");
  /**
   * Cabeceras de los documentos
   */
  const [headers, setHeaders] = useState([]);

  /**
   * Titulo del documento
   */
  const [title, setTitle] = useState("");

  /* Variable para decidir tipo de formato para los subreportes del reporte 24 */
  const [typeReport24B, setTypeReport24B] = useState("");
  /* Variable donde se guarda la informacion del reporte 24B */
  const [infoRep24B, setInfoRep24B] = useState("");


  /**
   * Opciones disponibles para el select del formato
   */
  const format = [
    {
      id: 1,
      value: "PDF"
    },
    {
      id: 2,
      value: "Hoja de calculo"
    },
    {
      id: 3,
      value: "Html"
    }
  ];

  /**
   * Entra al momento de montar el componente de reportes
   */
  useEffect(() => {
    getTypeReports();
  }, []);

  /**
   * Consulta del reporte especificado
   * @param {*} params Objeto especificado del endpoint
   */
  const getReport = (params, route) => {
    API.peticionEndPoint(
      params,
      !route ? "app/facades/reports/reportsF.php" : route
    )
      .then((res) => {
        if (res.status === 200 && res.data.code === "200") {
          if (route === "receiver/receiver.php") {
            if (res.data.data.length === 0) {
              toast(
                <Notification
                  type={"consultar_error"}
                  backMessage={res.data.message}
                  withIcon
                />,
                { closeButton: false }
              );
            } else {
              setReport(res.data.data);
            }
          } else {
            setReport(res.data.data);
          }
        } else {
          toast(
            <Notification
              type={"consultar_error"}
              backMessage={res.data.message}
              withIcon
            />,
            { closeButton: false }
          );
        }
      })
      .finally(() => {
        setLoader(false);
      })
      .catch((err) => {
        console.log(err)
        toast(<Notification type={"consultar_servidor_error"} withIcon />, {
          closeButton: false,
        });
      });
  };

  /**
   * Consulta previa
   * @param {*} params Objeto especificado del endpoint
   */
  const getPrevio = async (params, route, next, nextRoute) => {
    try {
      const res = await API.peticionEndPoint(
        params,
        !route ? "app/facades/reports/reportsF.php" : route
      )
      if (res.status === 200 && res.data.code === "200") {
        if (route === "receiver/receiver.php") {
          if (res.data.data.length === 0) {
            toast(
              <Notification
                type={"consultar_error"}
                backMessage={res.data.message}
                withIcon
              />,
              { closeButton: false }
            );
          } else {
            getReport(next(res.data.data), nextRoute);
          }
        } else {
          getReport(next(res.data.data), nextRoute)
        }
      } else {
        toast(
          <Notification
            type={"consultar_error"}
            backMessage={res.data.message}
            withIcon
          />,
          { closeButton: false }
        );
      }
    } catch (err) {
      toast(<Notification type={"consultar_servidor_error"} withIcon />, {
        closeButton: false,
      });
    }
  };

  /**
   * Consulta de los tipos de reportes disponibles
   */
  const getTypeReports = () => {
    const params = {
      action: "getReports",
      rows: {
        idModule: "61",
      },
    };
    API.peticionEndPoint(params, "app/facades/reports/reportsF.php")
      .then((res) => {
        if (res.status === 200 && res.data.status === "success") {
          setTypeReports(res.data.data);
        } else {
          toast(
            <Notification
              type={"consultar_error"}
              backMessage={res.data.message}
              withIcon
            />,
            { closeButton: false }
          );
        }
      })
      .catch(() => {
        toast(<Notification type={"consultar_servidor_error"} withIcon />, {
          closeButton: false,
        });
      });
  };

  /**
   * Establecimiento del form
   */
  const FormSchema = Yup.object().shape({
    idFormat: Yup.string().required("Seleccione un formato"),
    idReport: Yup.string().required("Seleccione un tipo de reporte"),
    period: Yup.number()
      .max(24, "Periodo debe ser menor a 25")
      .min(1, "Periodo debe ser mayor a 0")
      .when("idReport", {
        is: (val) => ["23", "24", "30", "43"].includes(val),
        then: Yup.number().required("Ingrese un periodo"),
      })
      .test('SiEsPrisma',
        "En caso del formato PDF y Excel es necesario este valor",
        function (item) {
          const format = this.parent.idFormat?.toString();
          const report = this.parent.idReport?.toString();
          if ((format === "1" || format === "2") && report === "27") {
            return (this.parent.period !== null)
          } else {
            return true
          }
        }
      ),
    year: Yup.date()
      .max(setYear(new Date(), 3000), "Año invalido")
      .min(setYear(new Date(), 2020), "Año debe ser posterior al 2019")
      .when("idReport", {
        is: (val) => ["23", "24", "25", "30", "32", "43"].includes(val),
        then: Yup.date().required("Ingrese un año"),
      })
      .test('SiEsPrisma',
        "En caso del formato PDF y Excel es necesario este valor",
        function (item) {
          const format = this.parent.idFormat?.toString();
          const report = this.parent.idReport?.toString();
          if ((format === "1" || format === "2") && report === "27") {
            return (this.parent.year !== null)
          } else {
            return true
          }
        }
      ),
    month: Yup.number()
      .when("idReport", {
        is: (val) => ["25", "32"].includes(val),
        then: Yup.number().required("Ingrese un mes"),
      }),
    idStateFund: Yup.string()
      .when("idReport", {
        is: (val) => ["30"].includes(val),
        then: Yup.string().required("Seleccione un fondo de estado"),
      }),
    stateFund: Yup.string(),
    detalle: Yup.string().when(["idReport", "idFormat"], {
      is: (idReport, idFormat) =>
        ["24"].includes(idReport) && ["1", "2"].includes(idFormat),
      then: Yup.string().required("Seleccione el detalle"),
    }),
  });

  /**
   * Se envia inmediatamente despues del submit
   * @param {*} filter Valores del form
   */
  const getData = (filter) => {
    setReport(undefined);
    setTitle("");
    setShowReportFormat("0");

    //RECUPERAMOS Y HACEMOS QUE SEA CADENA
    const idReport = filter.idReport.toString();
    switch (idReport) {
      case "23":
        setTitle("REPORTE DE RETENCIONES INSTITUCIONALES");
        setConfigR23({
          setHeaders,
          setPdfConfig,
          setExcelConfig,
          setHtmlConfig,
          setData,
          filter,
        });
        break;
      case "24":
        setTitle(
          "SOLICITUD DE PAGO POR TRANSFERENCIA DE DEDUCCIONES VÍA NÓMINA A TERCEROS"
        );
        setConfigR24({
          setPdfConfig,
          setExcelConfig,
          setHtmlConfig,
          setData,
          setHeaders,
          filter,
        });
        break;
      case "25":
        setTitle("IMPUESTO SOBRE REMUNERACIONES AL PERSONAL");
        setConfigR25({
          setHeaders,
          setPdfConfig,
          setExcelConfig,
          setHtmlConfig,
          setData,
          filter
        })
        break;
      case "27":
        setTitle("PRISMA");
        setConfigR27({
          setHeaders,
          setPdfConfig,
          setExcelConfig,
          setHtmlConfig,
          setData,
          filter
        })
        break;
      case "30":
        setTitle("CIFRAS POR PROYECTO PRESUPUESTAL");
        setConfigR30({
          setHeaders,
          setPdfConfig,
          setExcelConfig,
          setHtmlConfig,
          setData,
          filter,
        });
        break;
      case "32":
        setTitle("INFORME DE IMPUESTO SOBRE LA RENTA MENSUAL");
        setConfigR32({
          setHeaders,
          setPdfConfig,
          setExcelConfig,
          setHtmlConfig,
          setData,
          filter,
        });
        break;
      case "43":
        setTitle("Tipo de Reporte: General");
        setConfigR43({
          setHeaders,
          setPdfConfig,
          setExcelConfig,
          setHtmlConfig,
          setData,
          filter,
        });
        break;
      default:
        break;
    }
  };

  /**
   * Formik const
   */
  const {
    handleSubmit,
    handleBlur,
    values,
    errors,
    touched,
    setFieldValue,
    resetForm,
  } = useFormik({
    initialValues: defaultForm,
    onSubmit: (values) => {
      getData(values);
    },
    validationSchema: FormSchema,
    enableReinitialize: true,
  });

  /**
   * RECUPERA INFORMACIÓN DE DATASTORAGE
   */
  useEffect(() => {
    const value = localStorage.getItem("reportesNomina");
    if (value !== null) {
      const formLSto = JSON.parse(value);
      setFieldValue(["idFormat"], formLSto.idFormat)
      setFieldValue(["idReport"], formLSto.idReport)
      setTimeout(function () {
        setFieldValue(["period"], formLSto.period)
        if (formLSto.year !== "") {
          setFieldValue(["year"], new Date(formLSto.year))
        }
        setFieldValue(["month"], formLSto.month)
        setFieldValue(["idStateFund"], formLSto.idStateFund)
        setFieldValue(["stateFund"], formLSto.stateFund)
        setFieldValue(["detalle"], formLSto.detalle)
      }, 300)
      localStorage.removeItem("reportesNomina");
    }
  }, [])

  function saveForm() {
    localStorage.setItem("reportesNomina", JSON.stringify(values))
  }

  /**
   * Hook que detecta el cambio en data
   */
  useEffect(() => {
    if (data !== "") {
      const idFormat = values.idFormat.toString();
      if (idFormat === "1" || idFormat === "2") {
        setLoader(true);
        const idReport = values.idReport;
        if (["25"].includes(idReport)) {
          getReport(configRep25(values), "receiver/receiver.php")
        } else if (["27"].includes(idReport)) {
          getPrevio(configRep27(values), "receiver/receiver.php", configEnd27, "app/facades/reports/reportsFiTeF.php")
        } else if (["23", "24", "43"].includes(idReport)) {
          getReport(data, "app/facades/reports/reportsFiTeF.php")
        } else {
          getReport(data);
        }
      }
      setShowReportFormat(idFormat);
      setModalReports(true);
    }
  }, [data]);

  /**
   * Hook que detecta el cambio en la variable report y en caso
   * de ser el tipo de formato 2 genera un
   */
  useEffect(() => {
    if (report !== undefined && showReportFormat === "2") {
      setShowReportFormat("0");
      const idReport = values.idReport;
      switch (idReport) {
        case "23":
          saveGeneralReport(report, title, data, excelConfig);
          break;
        case "24":
          excelProveedoresN(report, excelConfig);
          break;
        case "25":
          saveReport3SRem(report, title, excelConfig);
          break;
        case "27":
          savePrisma(report, title, data, excelConfig)
          break;
        case "30":
          saveReport30AsExcel(report, title, data, excelConfig);
          break;
        case "32":
          saveReport32AsExcel(report, title, data, excelConfig);
          break;
        case "43":
          saveReport43AsExcel(report, title, data, excelConfig);
          break;
        default:
          break;
      }
    }
  }, [report]);

  /**
   * Con el valor del form decide el metodo del PDF a Generar
   * @returns Metodo a ocupar para generar PDF
   */
  function getPDFMethod() {
    switch (values.idReport) {
      case "23":
        return {
          method: exportGeneral,
          type: "Retenciones",
        };
      case "24":
        return {
          method: pdfProveedoresN,
          type: "pagoProveedores",
        };
      case "25":
        return {
          method: exportPDF3srem,
          type: "3srem"
        }
      case "27":
        return {
          method: exportPrisma,
          type: "prisma"
        }
      case "30":
        return {
          method: generatePDFcifras,
          type: "CifrasPorProyecto",
        };
      case "32":
        return {
          method: generatePDFISR,
          type: "ISR_MENSUAL",
        };
      case "43":
        return {
          method: exportContributionsIssemym,
          type: "ContributionsISSEMYM",
        };
      default:
        return null;
    }
  }

  const getDetail = (row, key) => {
    return (
      <i
        className={"fa fa-eye text-info"}
        key={key}
        onClick={() => openDetail(row)}
        style={{ cursor: "pointer" }}
      ></i>
    );
  };

  function openDetail(data) {
    saveForm()
    localStorage.setItem(
      "detailsRow",
      JSON.stringify({ data, idReport: values.idReport, values, title })
    );
    history.push({ pathname: "/template/reportes-finanzas/detalles" });
  }

  const getSpecialColumns = (idReport) => {
    switch (idReport) {
      case "23":
        return [getDetail];
      case "24":
        return [ColumnRep24];
      case "25":
        return [edit3]
      case "27":
        return [edit27]
      default:
        return [];
    }
  };

  const edit3 = (row, key) => {
    return (
      <i
        className={"fa fa-pencil text-warning"}
        key={key}
        onClick={() => setEdit(row, "calc3rem", 0, "idIsrmonthlyreport", "#template/reportes-finanzas/remuneraciones/editar")}
        style={{ cursor: "pointer" }}
      ></i>
    );
  }

  const edit27 = (row, key) => {
    return (
      <i
        className={"fa fa-pencil text-warning"}
        key={key}
        onClick={() => setEdit(row, "prisma", 0, "idPrisma", "#template/reportes-finanzas/prisma/editar")}
        style={{ cursor: "pointer" }}
      ></i>
    );
  }

  function setEdit(data, loc, index, key, href) {
    saveForm()
    localStorage.setItem(loc, JSON.stringify({ [key]: data[index] }))
    window.location.href = href;
  }

  /**
   * Si el param de showReport se encuentra en 1 o 3 y cumple con las condiciones
   * @returns Funcion que muestra el reporte de pdf o html
   */
  function showPDForHTML() {
    if (showReportFormat === "1" && report !== undefined && pdfConfig !== "") {
      return (
        <ModalReports
          modal={modalReports}
          setModal={setModalReports}
          title={title}
          backdrop={"static"}
          keyboard={false}
          report={report}
          pdfConfig={pdfConfig}
          generatePdfMethod={getPDFMethod()}
          records={1000}
        />
      );
    } else if (
      showReportFormat === "3" &&
      data !== "" &&
      title !== "" &&
      htmlConfig !== ""
    ) {
      delete data.stateFund;
      const idReport = values.idReport;
      if (idReport === "43") {
        return (
          <TableComponentReportWithoutActions
            titulo={title}
            cabecerasTabla={getCabeceras()}
            parametros={data}
            filtro={htmlConfig}
            reporte={idReport}
            route="app/facades/reports/reportsFiTeF.php"
          />
        );
      } else {
        return (
          <TableComponent
            titulo={title}
            cabecerasTabla={getCabeceras()}
            parametros={data}
            filtro={htmlConfig}
            reporte={idReport}
            specialColumns={getSpecialColumns(idReport)}
            route={
              ["23", "24"].includes(idReport)
                ? "app/facades/reports/reportsFiTeF.php"
                : ["25", "27"].includes(idReport) ? "receiver/receiver.php" : null
            }
          />
        );
      }
    }
    return null;
  }

  function getCabeceras() {
    const idReport = values.idReport.toString();
    if (idReport === "30") {
      return ["TIPO CONCEPTO", ...headers];
    }
    return headers;
  }

  /**
   * Metodo que establece en el target el valor
   * @param {*} e evento de change
   */
  const onChange = (e) => {
    if (
      (e.target.value === "" || e.target.value === undefined) &&
      e.target.name === "period"
    ) {
      setFieldValue("year", "");
    }
    if (e.target.value === undefined) {
      setFieldValue([e.target.name], "");
    } else {
      setFieldValue([e.target.name], e.target.value);
    }
    setShowReportFormat("0");
    if (typeReport24B !== "") setTypeReport24B("");
  };

  const OnChangeSelect = (event) => {
    setFieldValue(event.target.name, event.target.value);
    if (typeReport24B !== "") setTypeReport24B("");
  };

  /* FUNCIONES REPORTE 24 */

  function ColumnRep24(row, key) {
    return (
      <div className="d-flex flex-row" key={key}>
        <i
          className={"fa fa-file-pdf-o px-2"}
          onClick={() => detailReport(row, 1)}
          style={{ cursor: "pointer", color: "#FF4B23" }}
        ></i>
        <i
          className={"fa fa-file-excel-o px-2"}
          onClick={() => detailReport(row, 2)}
          style={{ cursor: "pointer", color: "#2d6d13" }}
        ></i>
        <i
          className={"fa fa-table px-2"}
          onClick={() => detailReport(row, 3)}
          style={{ cursor: "pointer", color: "#2F33A7" }}
        ></i>
      </div>
    );
  }

  async function detailReport(row, type) {
    const params = {
      action: "getPagosProveedoresNomina",
      period: +values.period,
      year: (new Date(values.year)).getFullYear(),
      detalle: "1",
      idAccountList: row[0],
    }
    if (type === 3) {
      localStorage.setItem("pagoProveedor", JSON.stringify(params));
      saveForm();
      history.push({ pathname: "/template/reportes-finanzas/detalle-pago-proveedores" });
    } else {
      setLoader(true);
      const data = await doRequest(
        "app/facades/reports/reportsFiTeF.php",
        params,
        false
      );
      if (data.length > 0) {
        setInfoRep24B(data);
        setTypeReport24B(type);
        if (type === 2) {
          await excelProveedoresN(data, {
            period: values.period,
            year: (new Date(values.year)).getFullYear(),
            detalle: "1",
          });
        } else if (type === 1) {
          setModalReports(true);
        }
      }
      setLoader(false);
    }
  }

  function showReport24B() {
    if (typeReport24B !== 1) return null;
    return (
      <ModalReports
        modal={modalReports}
        setModal={setModalReports}
        title={title}
        backdrop={"static"}
        keyboard={false}
        report={infoRep24B}
        pdfConfig={{
          period: values.period,
          year: (new Date(values.year)).getFullYear(),
          detalle: "1",
        }}
        generatePdfMethod={getPDFMethod()}
        records={1000}
      />
    );
  }

  /* FIN FUNCIONES REPORTE 24 */

  return (
    <>
      <Widget className="widget-p-md">
        <form onSubmit={handleSubmit} className="mt-4" noValidate>
          <div className="headline-1 text-center mb-4">Reportes Finanzas</div>

          <Row className="d-flex justify-content-center">
            <Col xs={12} md={12} lg={5}>
              <SelectTypeHeadSingle
                label="Seleccione el reporte a generar"
                optionsArray={typeReports}
                inputName="idReport"
                onChangeMethod={OnChangeSelect}
                onBlurMethod={handleBlur}
                touched={touched.idReport}
                errors={errors.idReport}
                optionValue="idReport"
                optionName="nameReport"
                value={values.idReport}
                isRequired
                defaultOption="Seleccione un reporte"
              />
            </Col>
            <Col xs={12} md={12} lg={5}>
              <SelectTypeHeadSingle
                label="Seleccione un formato"
                optionsArray={format}
                inputName="idFormat"
                onChangeMethod={OnChangeSelect}
                onBlurMethod={handleBlur}
                touched={touched.idFormat}
                errors={errors.idFormat}
                optionValue="id"
                optionName="value"
                value={values.idFormat}
                isRequired
                defaultOption="Seleccione un formato"
              />
            </Col>
          </Row>
          <Filtro23303243
            handleBlur={handleBlur}
            values={values}
            touched={touched}
            errors={errors}
            report={values.idReport}
            setFieldValue={setFieldValue}
            onChange={onChange}
          />
          <div className="text-center my-3">
            <Button color="success" type="submit">
              Generar
            </Button>
          </div>
        </form>

        {values.idReport === "25" ? (
          <div className="text-right mt-3">
            <Button color="add" onClick={() => { saveForm(); window.location.href = "/#/template/reportes-finanzas/remuneraciones/agregar" }}>
              Registrar
            </Button>
          </div>
        ) : values.idReport === "27" ?
          <div className="text-right mt-3">
            <Button color="add" onClick={() => { saveForm(); window.location.href = "/#/template/reportes-finanzas/prisma/agregar" }}>
              Registrar
            </Button>
          </div>
          : null}
        <FullFormLoader show={loader} message="Consultando datos , espere un momento" />

        {showPDForHTML()}

        {showReport24B()}

      </Widget>
    </>
  );
};
export default ReportesFinanzas;

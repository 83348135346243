import Notification from "../../../../components/Notification/Notification";
import { toast } from "react-toastify";
import { peticionesReceiver } from "../../../../helpers/peticionesReceiver";
const API = peticionesReceiver();

export const createOficioPrestador = async (leaveN, setParametros, idEmployee, limpia, genReport) => {
  delete leaveN.idContractPractice
  delete leaveN.numberContract
  if (leaveN) {
    const params = {
      action: "saveContractPractice",
      table: "contractPractice",
      rows: leaveN,
    }
    API.peticionEndPoint(params, "app/facades/jobs/jobsF.php").then((res) => {
      if (res.status === 200 && res.data.status === "success") {
        toast(<Notification type={"agrega_exito"} backMessage={res.data.message} withIcon />);
        setParametros({
          action: "datatable",
          table: "contractPractice CP INNER JOIN types_move TM on CP.keyMove=TM.keyMove INNER JOIN areas A on CP.idArea=A.idArea INNER JOIN jobs J on CP.keyJob=J.keyJob INNER JOIN staff SF ON CP.idStaff=SF.idStaff",
          rows: "CP.idContractPractice,J.nameJob,A.name,TM.nameMove,CP.valid,CP.startDate,CP.endDate",
          conditions: `CP.enabled = 1 AND SF.idStaff = ${idEmployee}`,
          page: 0,
          records: 5,
          search: "",
          order: "",
        })
        limpia();
        const re = Object.values(res.data.data);
        genReport(re)
      } else {
        toast(<Notification type={"agrega_error"} backMessage={res.data.message} withIcon />);
      }
    }).catch((err) => {
      toast(<Notification type={"consultar_servidor_error"} withIcon />);
    });
  }
};

export const updateOficioPrestador = async (leaveU, setParametros, idEmployee, limpia, noEmp) => {
  if (leaveU) {
    const params = {
      action: "saveContractPractice",
      table: "contractPractice",
      rows: leaveU,
      condition: {
        idContractPractice: leaveU.idContractPractice,
      },
    }
    API.peticionEndPoint(params, "app/facades/jobs/jobsF.php").then((res) => {
      if (res.status === 200 && res.data.status === "success") {
        toast(<Notification type={"modifica_exito"} withIcon />);
        if (noEmp) {
          setParametros({
            action: "datatable",
            table: "contractPractice CP INNER JOIN types_move TM on CP.keyMove=TM.keyMove INNER JOIN areas A on CP.idArea=A.idArea INNER JOIN jobs J on CP.keyJob=J.keyJob INNER JOIN staff SF ON CP.idStaff=SF.idStaff",
            rows: "CP.idContractPractice,J.nameJob,A.name,TM.nameMove,CP.valid,CP.startDate,CP.endDate",
            conditions: `CP.enabled = 1`,
            page: 0,
            records: 5,
            search: "",
            order: "",
          });
        } else {
          setParametros({
            action: "datatable",
            table: "contractPractice CP INNER JOIN types_move TM on CP.keyMove=TM.keyMove INNER JOIN areas A on CP.idArea=A.idArea INNER JOIN jobs J on CP.keyJob=J.keyJob INNER JOIN staff SF ON CP.idStaff=SF.idStaff",
            rows: "CP.idContractPractice,J.nameJob,A.name,TM.nameMove,CP.valid,CP.startDate,CP.endDate",
            conditions: `CP.enabled = 1 AND SF.idStaff = ${idEmployee}`,
            page: 0,
            records: 5,
            search: "",
            order: "",
          });
        }
        limpia()
      } else {
        toast(<Notification type={"modifica_error"} backMessage={res.data.message} withIcon />);
      }
    }).catch((err) => {
      toast(<Notification type={"consultar_servidor_error"} withIcon />);
    });
  }
};
export function RENAPOLogo({className = ""}) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 648 228" className={className}>
            <g strokeWidth="2.0" fill="none" strokeLinecap="butt">
                <path stroke="#6d6765" vectorEffect="non-scaling-stroke" d="
  M 385.21 37.00
  Q 384.64 36.91 384.84 37.47
  Q 393.37 60.45 403.37 82.84
  Q 405.44 87.47 408.56 90.69
  Q 409.24 91.39 410.29 91.00"
                />
                <path stroke="#77716d" vectorEffect="non-scaling-stroke" d="
  M 387.54 83.25
  Q 388.33 80.97 386.54 79.50"
                />
                <path stroke="#77716d" vectorEffect="non-scaling-stroke" d="
  M 76.00 1.54
  Q 76.58 1.82 76.35 2.18
  C 76.22 2.38 75.90 2.50 75.52 2.50
  Q 67.04 2.51 58.57 2.50
  Q 58.52 2.50 56.97 2.73
  Q 55.83 2.90 54.96 2.00"
                />
                <path stroke="#77716d" vectorEffect="non-scaling-stroke" d="
  M 56.29 22.00
  Q 57.31 19.34 56.04 16.75"
                />
                <path stroke="#77716d" vectorEffect="non-scaling-stroke" d="
  M 45.25 5.96
  Q 45.06 4.60 43.54 4.75"
                />
                <path stroke="#77716d" vectorEffect="non-scaling-stroke" d="
  M 199.46 3.00
  Q 198.86 3.08 198.39 2.81
  Q 197.86 2.50 197.51 2.50
  Q 172.63 2.50 147.75 2.50
  Q 141.31 2.50 137.54 3.50"
                />
                <path stroke="#77716d" vectorEffect="non-scaling-stroke" d="
  M 199.46 6.00
  L 199.00 8.81
  Q 198.92 9.26 198.80 9.63
  Q 198.62 10.15 199.46 10.00"
                />
                <path stroke="#77716d" vectorEffect="non-scaling-stroke" d="
  M 191.04 12.50
  Q 190.65 9.87 188.00 9.96"
                />
                <path stroke="#77716d" vectorEffect="non-scaling-stroke" d="
  M 244.75 7.96
  Q 244.35 8.19 243.92 8.08
  Q 243.62 8.01 243.35 7.61
  Q 240.51 3.39 235.96 2.57
  Q 235.25 2.44 227.54 2.41
  Q 220.02 2.38 212.54 3.25"
                />
                <path stroke="#77716d" vectorEffect="non-scaling-stroke" d="
  M 290.75 1.54
  Q 291.20 2.60 293.25 2.50
  Q 300.62 2.15 319.00 2.79"
                />
                <path stroke="#6d6765" vectorEffect="non-scaling-stroke" d="
  M 306.46 15.25
  Q 305.98 15.21 305.64 15.45
  C 305.43 15.60 305.31 15.93 305.31 16.32
  L 305.25 95.25
  C 305.25 95.57 305.09 95.86 304.84 96.02
  Q 301.55 98.02 299.28 94.98
  Q 298.70 94.20 297.96 94.75"
                />
                <path stroke="#6d6765" vectorEffect="non-scaling-stroke" d="
  M 227.04 47.50
  Q 227.30 48.06 227.59 47.98
  C 227.79 47.92 227.94 47.61 227.93 47.23
  L 227.69 15.64
  C 227.68 15.42 227.54 15.24 227.37 15.24
  L 227.04 15.25"
                />
                <path stroke="#44996f" strokeOpacity="0.996" vectorEffect="non-scaling-stroke" d="
  M 302.79 125.25
  Q 302.88 125.96 302.29 126.25"
                />
                <path stroke="#257b53" strokeOpacity="0.996" vectorEffect="non-scaling-stroke" d="
  M 302.29 126.25
  Q 301.31 125.30 300.09 125.49
  Q 298.48 125.75 298.36 125.75
  Q 209.80 125.75 121.25 125.75
  Q 120.68 125.75 120.75 125.04"
                />
                <path stroke="#1f8450" vectorEffect="non-scaling-stroke" d="
  M 302.29 126.25
  L 302.06 131.76
  C 302.05 132.03 301.80 132.25 301.50 132.25
  Q 213.50 132.25 125.50 132.30
  Q 124.47 132.30 123.75 132.96"
                />
                <path stroke="#ce4440" vectorEffect="non-scaling-stroke" d="
  M 532.50 125.04
  Q 531.15 125.74 530.00 125.75
  Q 463.34 126.18 375.69 125.96
  Q 361.87 125.92 348.04 126.00"
                />
                <path stroke="#d85551" vectorEffect="non-scaling-stroke" d="
  M 348.04 126.00
  Q 348.52 125.08 347.75 124.96"
                />
                <path stroke="#c5433b" vectorEffect="non-scaling-stroke" d="
  M 348.04 126.00
  L 347.78 129.77
  Q 347.62 132.00 349.66 132.00
  Q 437.25 132.00 525.00 132.00
  Q 527.09 132.00 528.29 133.00"
                />
                <path stroke="#c5433b" vectorEffect="non-scaling-stroke" d="
  M 624.50 128.54
  Q 622.56 129.88 625.25 129.79"
                />
                <path stroke="#7c7571" strokeOpacity="0.996" vectorEffect="non-scaling-stroke" d="
  M 63.25 178.79
  Q 64.17 178.65 65.01 178.26
  Q 66.22 177.69 66.72 177.75
  Q 67.77 177.86 68.29 179.00"
                />
                <path stroke="#7e7672" strokeOpacity="0.996" vectorEffect="non-scaling-stroke" d="
  M 139.04 222.75
  Q 139.34 222.81 139.57 222.72
  C 139.79 222.64 139.93 222.33 139.92 221.96
  Q 139.81 218.47 139.92 214.98
  Q 139.94 214.28 139.72 211.04
  Q 139.59 209.03 140.06 207.05
  C 140.12 206.79 139.99 206.51 139.73 206.37
  L 139.04 206.00"
                />
                <path stroke="#6d6765" vectorEffect="non-scaling-stroke" d="
  M 520.71 87.50
  Q 520.56 85.19 518.25 85.29"
                />
                <path stroke="#6d6765" vectorEffect="non-scaling-stroke" d="
  M 70.96 10.25
  Q 70.07 9.64 70.08 10.56
  Q 70.34 29.37 70.18 48.95
  C 70.17 49.67 70.73 50.26 71.45 50.30
  Q 78.77 50.68 85.87 50.51
  Q 86.32 50.50 86.61 50.36
  Q 87.39 50.00 86.75 49.46"
                />
                <path stroke="#726b69" strokeOpacity="0.996" vectorEffect="non-scaling-stroke" d="
  M 534.71 220.00
  Q 537.04 223.39 535.88 227.33
  C 535.83 227.52 535.86 227.70 535.96 227.76
  Q 536.07 227.83 536.25 227.79"
                />
            </g>
            <path fill="#615958" d="
  M 547.56 0.00
  Q 554.69 0.00 561.81 0.00
  Q 569.19 1.10 576.03 3.78
  Q 583.17 6.57 588.50 11.26
  Q 603.00 24.05 604.05 44.75
  Q 604.32 50.21 603.73 55.59
  Q 603.14 61.04 601.36 66.10
  Q 592.05 92.57 563.31 97.56
  Q 540.03 101.61 520.71 87.50
  Q 520.56 85.19 518.25 85.29
  Q 508.93 75.80 506.03 62.74
  Q 504.20 54.52 504.61 46.24
  Q 505.02 37.93 508.02 30.21
  Q 517.45 5.90 544.89 0.61
  Q 544.94 0.60 546.12 0.51
  Q 546.90 0.46 547.56 0.00
  Z
  M 526.44 22.69
  Q 521.14 32.92 520.83 44.77
  Q 520.65 51.55 521.38 57.22
  Q 522.18 63.45 524.66 69.32
  Q 532.46 87.81 550.39 90.43
  Q 560.86 91.96 569.18 87.98
  Q 578.45 83.55 582.94 73.19
  Q 590.33 56.11 586.50 37.25
  Q 584.52 27.51 579.28 20.04
  Q 574.51 13.24 567.14 9.86
  Q 555.57 4.54 542.97 8.55
  Q 537.46 10.30 533.38 13.89
  Q 529.00 17.75 526.44 22.69
  Z"
            />
            <path fill="#615958" d="
  M 385.21 37.00
  Q 384.64 36.91 384.84 37.47
  Q 393.37 60.45 403.37 82.84
  Q 405.44 87.47 408.56 90.69
  Q 409.24 91.39 410.29 91.00
  L 414.09 92.53
  C 414.33 92.62 414.67 92.64 415.05 92.58
  Q 416.65 92.31 416.36 94.01
  Q 416.28 94.49 416.40 94.97
  Q 416.79 96.49 415.14 96.35
  Q 413.96 96.25 413.85 96.25
  Q 395.78 96.25 377.70 96.25
  Q 377.64 96.25 376.80 96.31
  Q 375.22 96.43 375.41 94.75
  Q 375.47 94.27 375.31 93.78
  Q 374.81 92.22 377.03 92.51
  Q 381.63 93.11 385.89 91.32
  Q 388.62 90.17 388.20 87.20
  Q 387.92 85.22 387.54 83.25
  Q 388.33 80.97 386.54 79.50
  L 380.69 64.62
  C 380.46 64.04 379.92 63.67 379.34 63.69
  L 350.19 64.41
  C 349.88 64.42 349.61 64.60 349.48 64.89
  Q 344.73 75.18 341.56 86.08
  Q 340.61 89.33 343.70 90.72
  Q 347.56 92.46 352.67 92.76
  C 352.97 92.78 353.22 92.99 353.29 93.29
  Q 353.99 96.49 351.93 96.32
  Q 351.14 96.25 351.07 96.25
  Q 335.53 96.25 320.00 96.23
  Q 318.76 96.23 318.90 95.00
  C 318.94 94.69 318.89 94.36 318.75 94.07
  Q 318.31 93.12 319.44 92.94
  Q 328.64 91.44 332.21 83.20
  Q 349.84 42.56 367.12 1.77
  C 367.25 1.45 367.46 1.20 367.73 1.05
  Q 370.46 -0.51 371.75 2.75
  Q 378.52 19.86 385.21 37.00
  Z
  M 375.94 57.28
  Q 376.44 57.28 376.94 57.35
  C 377.19 57.39 377.41 57.18 377.37 56.94
  Q 377.07 55.10 376.30 53.42
  Q 374.91 50.38 374.81 50.10
  Q 370.52 38.44 365.83 26.99
  Q 365.30 25.68 364.76 26.99
  L 352.46 56.64
  C 352.34 56.92 352.54 57.24 352.85 57.24
  Q 364.72 57.39 375.94 57.28
  Z"
            />
            <path fill="#8e8982" fillOpacity="0.996" d="
  M 76.00 1.54
  Q 76.58 1.82 76.35 2.18
  C 76.22 2.38 75.90 2.50 75.52 2.50
  Q 67.04 2.51 58.57 2.50
  Q 58.52 2.50 56.97 2.73
  Q 55.83 2.90 54.96 2.00
  Q 55.70 1.57 56.06 1.56
  Q 66.03 1.42 76.00 1.54
  Z"
            />
            <path fill="#615958" d="
  M 76.00 1.54
  Q 85.01 1.01 93.80 3.01
  Q 100.95 4.63 106.06 8.69
  Q 109.92 11.75 111.63 17.04
  Q 115.52 29.02 109.05 39.60
  Q 105.01 46.21 97.36 50.39
  C 96.88 50.65 96.71 51.24 96.96 51.72
  Q 105.49 67.80 114.64 81.07
  Q 117.64 85.42 121.67 88.83
  Q 125.50 92.08 131.34 92.86
  C 131.64 92.90 131.88 93.14 131.92 93.44
  Q 132.41 96.96 129.83 96.90
  Q 127.72 96.85 127.66 96.86
  Q 120.97 97.50 114.26 97.96
  Q 108.99 98.32 105.06 93.94
  Q 101.81 90.30 100.99 88.98
  Q 93.12 76.16 86.72 62.55
  Q 85.48 59.90 83.52 57.82
  Q 82.07 56.26 79.79 55.92
  Q 75.77 55.32 71.70 55.64
  C 71.41 55.66 71.18 55.90 71.17 56.19
  Q 70.86 67.84 71.00 79.50
  Q 71.03 81.80 71.19 83.35
  Q 71.46 86.04 71.72 87.15
  Q 72.19 89.27 73.73 90.72
  Q 75.03 91.96 77.66 92.25
  Q 79.83 92.48 82.01 92.68
  Q 82.12 92.69 83.48 92.65
  Q 85.55 92.58 84.84 95.68
  C 84.77 96.01 84.48 96.25 84.14 96.25
  L 44.30 96.19
  C 44.00 96.19 43.73 96.00 43.64 95.72
  Q 43.24 94.48 43.59 93.22
  C 43.67 92.95 43.94 92.76 44.26 92.76
  Q 48.34 92.82 52.21 91.49
  Q 54.20 90.80 54.87 89.07
  Q 54.95 88.85 55.60 85.81
  Q 56.21 82.96 56.03 80.00
  Q 55.94 78.47 56.18 76.98
  Q 56.50 74.96 56.51 73.98
  Q 56.65 47.99 56.29 22.00
  Q 57.31 19.34 56.04 16.75
  Q 56.08 15.67 55.50 12.50
  Q 54.76 8.43 52.00 6.75
  Q 50.91 6.09 45.25 5.96
  Q 45.06 4.60 43.54 4.75
  L 43.42 2.74
  C 43.40 2.46 43.66 2.22 43.99 2.21
  L 54.96 2.00
  Q 55.83 2.90 56.97 2.73
  Q 58.52 2.50 58.57 2.50
  Q 67.04 2.51 75.52 2.50
  C 75.90 2.50 76.22 2.38 76.35 2.18
  Q 76.58 1.82 76.00 1.54
  Z
  M 70.96 10.25
  Q 70.07 9.64 70.08 10.56
  Q 70.34 29.37 70.18 48.95
  C 70.17 49.67 70.73 50.26 71.45 50.30
  Q 78.77 50.68 85.87 50.51
  Q 86.32 50.50 86.61 50.36
  Q 87.39 50.00 86.75 49.46
  Q 90.67 48.27 92.44 45.94
  Q 99.35 36.82 97.35 24.52
  Q 94.54 7.24 76.75 8.50
  Q 74.61 8.65 70.96 10.25
  Z"
            />
            <path fill="#8e8982" fillOpacity="0.996" d="
  M 199.46 3.00
  Q 198.86 3.08 198.39 2.81
  Q 197.86 2.50 197.51 2.50
  Q 172.63 2.50 147.75 2.50
  Q 141.31 2.50 137.54 3.50
  Q 136.65 1.50 139.00 1.50
  Q 168.75 1.50 198.50 1.50
  C 198.90 1.50 199.23 1.62 199.38 1.83
  Q 199.71 2.28 199.46 3.00
  Z"
            />
            <path fill="#8e8982" fillOpacity="0.996" d="
  M 244.75 7.96
  Q 244.35 8.19 243.92 8.08
  Q 243.62 8.01 243.35 7.61
  Q 240.51 3.39 235.96 2.57
  Q 235.25 2.44 227.54 2.41
  Q 220.02 2.38 212.54 3.25
  Q 211.92 1.50 213.25 1.50
  Q 222.38 1.50 231.50 1.50
  Q 235.26 1.50 237.85 2.20
  Q 239.69 2.70 241.55 4.68
  Q 243.12 6.35 244.75 7.96
  Z"
            />
            <path fill="#615958" d="
  M 290.75 1.54
  Q 291.20 2.60 293.25 2.50
  Q 300.62 2.15 319.00 2.79
  Q 319.26 3.89 319.06 4.95
  C 319.00 5.23 318.74 5.43 318.44 5.44
  Q 313.76 5.48 309.52 7.36
  C 309.22 7.49 308.97 7.71 308.81 7.99
  Q 306.89 11.33 306.46 15.25
  Q 305.98 15.21 305.64 15.45
  C 305.43 15.60 305.31 15.93 305.31 16.32
  L 305.25 95.25
  C 305.25 95.57 305.09 95.86 304.84 96.02
  Q 301.55 98.02 299.28 94.98
  Q 298.70 94.20 297.96 94.75
  Q 289.81 83.97 281.19 73.56
  Q 259.38 47.18 236.68 21.55
  Q 235.50 20.22 235.50 22.00
  Q 235.50 45.87 235.50 69.75
  Q 235.50 71.62 235.81 74.74
  Q 236.07 77.24 236.07 79.76
  Q 236.06 83.16 236.28 84.74
  Q 236.81 88.50 237.69 89.56
  Q 239.71 92.00 242.67 92.26
  Q 245.54 92.51 245.80 92.55
  Q 247.26 92.75 249.09 92.75
  C 249.39 92.75 249.66 92.96 249.72 93.26
  Q 250.09 94.90 249.89 95.73
  C 249.82 96.00 249.53 96.19 249.18 96.19
  L 213.65 96.25
  C 213.31 96.25 213.04 95.99 213.04 95.66
  L 213.02 93.06
  C 213.02 92.80 213.27 92.60 213.56 92.63
  Q 215.38 92.85 217.18 92.58
  Q 217.39 92.55 220.36 92.21
  Q 224.55 91.73 225.67 87.23
  Q 226.61 83.47 226.71 79.56
  Q 227.11 63.53 227.04 47.50
  Q 227.30 48.06 227.59 47.98
  C 227.79 47.92 227.94 47.61 227.93 47.23
  L 227.69 15.64
  C 227.68 15.42 227.54 15.24 227.37 15.24
  L 227.04 15.25
  Q 226.74 11.13 223.94 8.65
  Q 222.38 7.27 219.35 6.38
  Q 216.26 5.47 214.25 5.50
  Q 211.88 5.53 212.54 3.25
  Q 220.02 2.38 227.54 2.41
  Q 235.25 2.44 235.96 2.57
  Q 240.51 3.39 243.35 7.61
  Q 243.62 8.01 243.92 8.08
  Q 244.35 8.19 244.75 7.96
  Q 270.77 37.13 295.60 67.32
  Q 296.74 68.70 297.25 68.78
  Q 298.30 68.94 297.70 65.11
  Q 297.50 63.81 297.50 62.48
  Q 297.50 46.23 297.50 29.18
  Q 297.50 24.44 297.26 20.34
  Q 296.84 13.36 296.50 11.50
  Q 295.90 8.23 292.98 6.74
  Q 291.69 6.08 288.90 5.83
  Q 286.79 5.63 284.69 5.37
  C 284.36 5.33 284.09 5.13 283.98 4.85
  Q 283.44 3.47 283.89 2.03
  C 283.98 1.74 284.25 1.54 284.56 1.54
  L 290.75 1.54
  Z"
            />
            <path fill="#8e8982" fillOpacity="0.996" d="
  M 290.75 1.54
  Q 304.53 1.47 318.31 1.50
  Q 318.87 1.50 319.11 1.74
  Q 319.53 2.16 319.00 2.79
  Q 300.62 2.15 293.25 2.50
  Q 291.20 2.60 290.75 1.54
  Z"
            />
            <path fill="#615958" d="
  M 432.47 7.38
  Q 429.48 6.25 426.30 6.04
  Q 426.22 6.04 424.23 6.23
  Q 421.87 6.46 422.72 2.79
  C 422.82 2.37 423.19 2.07 423.62 2.05
  Q 443.49 1.34 462.00 1.55
  Q 474.45 1.69 483.94 8.06
  Q 488.23 10.94 490.54 16.62
  Q 493.78 24.54 492.01 33.00
  Q 489.84 43.39 481.22 49.48
  Q 472.37 55.73 461.49 56.28
  Q 457.00 56.50 451.10 56.50
  C 450.53 56.50 450.06 56.96 450.05 57.53
  Q 449.68 71.52 450.50 83.75
  Q 450.76 87.69 452.17 89.80
  Q 453.75 92.19 456.47 92.33
  Q 459.37 92.49 459.52 92.51
  Q 461.46 92.79 463.43 92.81
  C 463.74 92.82 464.00 93.01 464.07 93.29
  Q 464.90 96.70 461.65 96.43
  Q 459.45 96.25 459.33 96.25
  Q 442.31 96.25 425.29 96.25
  Q 425.18 96.25 424.22 96.34
  Q 421.92 96.54 422.69 93.18
  C 422.75 92.92 422.99 92.73 423.26 92.75
  Q 424.43 92.83 425.57 92.66
  Q 426.86 92.47 427.84 92.41
  Q 434.97 91.98 435.00 84.50
  Q 435.00 83.10 435.18 82.14
  Q 435.50 80.31 435.50 79.83
  Q 435.50 50.41 435.50 21.00
  Q 435.50 19.46 435.35 18.43
  Q 435.10 16.64 435.09 15.90
  Q 435.05 11.74 433.17 8.00
  C 433.02 7.72 432.77 7.50 432.47 7.38
  Z
  M 477.48 27.63
  Q 477.29 21.95 474.31 16.94
  Q 468.87 7.75 457.75 8.45
  Q 454.12 8.68 450.66 9.78
  C 450.37 9.88 450.18 10.14 450.18 10.43
  L 450.17 49.81
  C 450.17 50.09 450.39 50.34 450.69 50.37
  Q 459.63 51.40 466.00 49.00
  Q 473.79 46.07 476.13 38.43
  Q 477.68 33.36 477.48 27.63
  Z"
            />
            <path fill="#615958" d="
  M 199.46 3.00
  L 199.46 6.00
  L 199.00 8.81
  Q 198.92 9.26 198.80 9.63
  Q 198.62 10.15 199.46 10.00
  Q 199.78 16.98 197.86 24.30
  C 197.78 24.60 197.53 24.83 197.22 24.88
  Q 193.86 25.45 193.90 23.08
  Q 193.93 21.49 193.92 21.42
  Q 193.39 16.68 191.04 12.50
  Q 190.65 9.87 188.00 9.96
  Q 182.08 7.88 175.93 8.57
  Q 174.41 8.74 170.54 9.06
  Q 168.06 9.27 165.66 10.03
  C 165.37 10.13 165.18 10.39 165.18 10.69
  L 165.17 44.57
  C 165.17 44.84 165.40 45.06 165.69 45.06
  Q 172.59 44.98 179.50 45.00
  Q 187.06 45.02 188.71 37.30
  C 188.78 36.98 188.99 36.73 189.27 36.63
  Q 192.82 35.30 192.50 39.25
  Q 191.90 46.65 191.45 62.69
  C 191.44 63.01 191.28 63.30 191.02 63.46
  Q 187.22 65.86 187.19 60.56
  Q 187.16 56.06 184.08 53.77
  Q 182.06 52.26 177.57 51.82
  Q 171.58 51.22 165.66 51.74
  C 165.29 51.77 165.00 52.08 165.00 52.45
  Q 165.00 63.00 165.10 73.00
  Q 165.18 80.57 166.25 85.50
  Q 166.91 88.53 170.24 89.32
  Q 179.35 91.48 188.09 89.04
  Q 191.70 88.04 194.04 85.58
  Q 198.30 81.11 199.71 74.80
  C 199.78 74.47 199.97 74.20 200.24 74.06
  Q 201.87 73.21 203.73 73.87
  C 203.98 73.96 204.09 74.25 203.98 74.51
  Q 203.38 75.89 203.38 77.40
  Q 203.39 80.03 203.38 80.09
  Q 202.65 87.76 200.77 95.17
  C 200.61 95.80 200.04 96.25 199.38 96.25
  Q 169.82 96.25 140.38 96.25
  Q 138.30 96.25 138.14 96.17
  Q 137.18 95.66 137.73 93.22
  C 137.80 92.93 138.06 92.72 138.36 92.73
  Q 143.81 92.85 147.75 90.68
  C 148.03 90.53 148.26 90.28 148.39 89.98
  Q 150.53 85.00 150.52 79.50
  Q 150.50 48.80 150.50 18.10
  Q 150.50 17.43 150.19 15.95
  Q 149.99 14.98 150.00 13.75
  Q 150.03 9.89 147.51 7.45
  Q 146.11 6.09 143.39 5.88
  Q 140.82 5.69 138.25 5.53
  C 137.93 5.51 137.66 5.32 137.58 5.04
  Q 137.37 4.30 137.54 3.50
  Q 141.31 2.50 147.75 2.50
  Q 172.63 2.50 197.51 2.50
  Q 197.86 2.50 198.39 2.81
  Q 198.86 3.08 199.46 3.00
  Z"
            />
            <path fill="#8e8982" fillOpacity="0.996" d="
  M 43.54 4.75
  Q 45.06 4.60 45.25 5.96
  Q 42.10 7.06 43.54 4.75
  Z"
            />
            <path fill="#8e8982" fillOpacity="0.996" d="
  M 199.46 6.00
  Q 200.60 8.00 199.46 10.00
  Q 198.62 10.15 198.80 9.63
  Q 198.92 9.26 199.00 8.81
  L 199.46 6.00
  Z"
            />
            <path fill="#8e8982" fillOpacity="0.996" d="
  M 191.04 12.50
  L 188.00 9.96
  Q 190.65 9.87 191.04 12.50
  Z"
            />
            <path fill="#7a7573" fillOpacity="0.996" d="
  M 70.96 10.25
  L 71.00 48.51
  C 71.00 49.10 71.49 49.59 72.08 49.58
  L 86.75 49.46
  Q 87.39 50.00 86.61 50.36
  Q 86.32 50.50 85.87 50.51
  Q 78.77 50.68 71.45 50.30
  C 70.73 50.26 70.17 49.67 70.18 48.95
  Q 70.34 29.37 70.08 10.56
  Q 70.07 9.64 70.96 10.25
  Z"
            />
            <path fill="#7a7573" fillOpacity="0.996" d="
  M 227.04 15.25
  L 227.37 15.24
  C 227.54 15.24 227.68 15.42 227.69 15.64
  L 227.93 47.23
  C 227.94 47.61 227.79 47.92 227.59 47.98
  Q 227.30 48.06 227.04 47.50
  L 227.04 15.25
  Z"
            />
            <path fill="#7a7573" fillOpacity="0.996" d="
  M 306.46 15.25
  L 306.50 95.89
  C 306.50 96.33 306.19 96.71 305.76 96.80
  Q 304.25 97.13 302.49 97.45
  Q 299.24 98.04 297.96 94.75
  Q 298.70 94.20 299.28 94.98
  Q 301.55 98.02 304.84 96.02
  C 305.09 95.86 305.25 95.57 305.25 95.25
  L 305.31 16.32
  C 305.31 15.93 305.43 15.60 305.64 15.45
  Q 305.98 15.21 306.46 15.25
  Z"
            />
            <path fill="#8e8982" fillOpacity="0.996" d="
  M 56.04 16.75
  Q 57.31 19.34 56.29 22.00
  L 56.04 16.75
  Z"
            />
            <path fill="#7a7573" fillOpacity="0.996" d="
  M 385.21 37.00
  Q 393.47 57.94 404.56 83.44
  Q 406.59 88.10 410.29 91.00
  Q 409.24 91.39 408.56 90.69
  Q 405.44 87.47 403.37 82.84
  Q 393.37 60.45 384.84 37.47
  Q 384.64 36.91 385.21 37.00
  Z"
            />
            <path fill="#8e8982" fillOpacity="0.996" d="
  M 386.54 79.50
  Q 388.33 80.97 387.54 83.25
  Q 386.56 81.46 386.54 79.50
  Z"
            />
            <path fill="#7a7573" fillOpacity="0.996" d="
  M 518.25 85.29
  Q 520.56 85.19 520.71 87.50
  L 518.25 85.29
  Z"
            />
            <path fill="#4b9172" fillOpacity="0.992" d="
  M 302.79 125.25
  Q 302.88 125.96 302.29 126.25
  Q 301.31 125.30 300.09 125.49
  Q 298.48 125.75 298.36 125.75
  Q 209.80 125.75 121.25 125.75
  Q 120.68 125.75 120.75 125.04
  Q 120.90 124.92 121.07 124.84
  Q 121.23 124.75 121.45 124.75
  Q 211.35 124.75 301.25 124.75
  Q 302.32 124.75 302.79 125.25
  Z"
            />
            <path fill="#e25757" fillOpacity="0.996" d="
  M 532.50 125.04
  Q 531.15 125.74 530.00 125.75
  Q 463.34 126.18 375.69 125.96
  Q 361.87 125.92 348.04 126.00
  Q 348.52 125.08 347.75 124.96
  Q 437.75 124.59 527.75 124.75
  Q 531.15 124.76 532.50 125.04
  Z"
            />
            <path fill="#006634" d="
  M 120.75 125.04
  Q 120.68 125.75 121.25 125.75
  Q 209.80 125.75 298.36 125.75
  Q 298.48 125.75 300.09 125.49
  Q 301.31 125.30 302.29 126.25
  L 302.06 131.76
  C 302.05 132.03 301.80 132.25 301.50 132.25
  Q 213.50 132.25 125.50 132.30
  Q 124.47 132.30 123.75 132.96
  Q 123.06 132.74 121.50 132.75
  Q 82.11 133.01 42.75 131.24
  Q 34.47 130.87 26.25 129.81
  Q 25.67 129.74 25.31 129.48
  Q 24.21 128.69 26.00 128.25
  Q 29.98 127.27 38.37 126.91
  Q 49.99 126.42 50.13 126.41
  Q 63.81 125.68 71.00 125.75
  Q 75.75 125.80 79.51 125.59
  Q 85.58 125.27 88.00 125.28
  Q 104.37 125.36 120.75 125.04
  Z"
            />
            <path fill="#cf544c" d="
  M 347.75 124.96
  Q 348.52 125.08 348.04 126.00
  L 347.78 129.77
  Q 347.62 132.00 349.66 132.00
  Q 437.25 132.00 525.00 132.00
  Q 527.09 132.00 528.29 133.00
  Q 528.24 133.24 528.00 133.24
  Q 439.00 133.25 350.00 133.03
  Q 348.17 133.02 347.45 134.32
  Q 347.26 134.66 347.23 134.27
  Q 346.90 129.72 346.97 125.75
  Q 346.98 124.99 347.75 124.96
  Z"
            />
            <path fill="#ba312a" d="
  M 532.50 125.04
  Q 549.75 125.39 567.00 125.26
  Q 567.84 125.26 570.48 125.57
  Q 571.93 125.74 574.00 125.75
  Q 596.15 125.89 618.25 127.43
  Q 621.45 127.66 624.50 128.54
  Q 622.56 129.88 625.25 129.79
  Q 620.52 130.51 615.75 130.78
  Q 573.04 133.21 534.00 132.75
  Q 530.31 132.71 528.29 133.00
  Q 527.09 132.00 525.00 132.00
  Q 437.25 132.00 349.66 132.00
  Q 347.62 132.00 347.78 129.77
  L 348.04 126.00
  Q 361.87 125.92 375.69 125.96
  Q 463.34 126.18 530.00 125.75
  Q 531.15 125.74 532.50 125.04
  Z"
            />
            <path fill="#3ea16c" d="
  M 302.79 125.25
  L 302.92 132.22
  C 302.93 132.79 302.44 133.25 301.83 133.25
  Q 214.00 133.25 127.50 133.25
  Q 124.86 133.25 123.75 132.96
  Q 124.47 132.30 125.50 132.30
  Q 213.50 132.25 301.50 132.25
  C 301.80 132.25 302.05 132.03 302.06 131.76
  L 302.29 126.25
  Q 302.88 125.96 302.79 125.25
  Z"
            />
            <path fill="#cf544c" d="
  M 624.50 128.54
  Q 626.48 128.36 625.25 129.79
  Q 622.56 129.88 624.50 128.54
  Z"
            />
            <path fill="#6d6262" fillOpacity="0.996" d="
  M 611.39 160.34
  Q 608.03 162.46 604.00 162.28
  C 603.67 162.26 603.42 162.02 603.47 161.76
  Q 603.53 161.45 603.81 161.31
  Q 607.19 159.63 609.27 158.33
  Q 610.28 157.69 611.00 157.72
  Q 612.51 157.78 611.97 159.59
  C 611.89 159.89 611.68 160.16 611.39 160.34
  Z"
            />
            <path fill="#6a615f" fillOpacity="0.996" d="
  M 63.25 178.79
  Q 64.17 178.65 65.01 178.26
  Q 66.22 177.69 66.72 177.75
  Q 67.77 177.86 68.29 179.00
  L 68.07 186.35
  C 68.06 186.91 67.74 187.40 67.28 187.56
  Q 59.95 190.15 53.41 188.16
  Q 49.35 186.93 47.40 183.65
  Q 44.73 179.19 46.04 173.51
  Q 48.00 164.93 56.75 164.00
  Q 61.93 163.45 67.46 164.93
  C 67.75 165.01 67.95 165.27 67.95 165.57
  L 68.01 171.25
  C 68.01 171.61 67.87 171.92 67.63 172.08
  Q 67.11 172.43 66.50 172.18
  C 66.25 172.08 66.07 171.78 66.06 171.43
  Q 65.92 168.17 63.44 167.06
  Q 56.44 163.95 52.51 168.92
  Q 50.05 172.03 49.93 176.19
  Q 49.81 180.54 52.56 183.94
  Q 53.72 185.36 55.87 186.19
  Q 57.72 186.89 59.71 186.75
  Q 66.29 186.28 63.25 178.79
  Z"
            />
            <path fill="#6a615f" fillOpacity="0.996" d="
  M 90.04 169.52
  Q 91.98 173.22 95.94 174.81
  Q 97.93 175.61 99.19 177.81
  Q 102.04 182.80 99.26 186.26
  Q 97.24 188.75 93.35 188.95
  Q 90.20 189.11 86.54 188.20
  C 86.16 188.10 85.90 187.74 85.94 187.35
  Q 86.13 185.42 85.60 184.04
  Q 85.26 183.18 85.34 182.76
  Q 85.52 181.80 86.61 182.22
  C 86.88 182.33 87.10 182.58 87.22 182.92
  Q 88.79 187.49 94.01 186.75
  Q 97.58 186.24 96.76 182.72
  Q 96.15 180.13 92.81 178.44
  Q 92.24 178.15 89.72 176.32
  Q 85.19 173.06 86.50 168.25
  Q 87.49 164.60 92.25 163.75
  Q 95.24 163.22 99.48 164.88
  C 99.76 164.99 99.94 165.25 99.94 165.55
  Q 99.95 168.95 99.31 170.81
  Q 98.83 172.21 98.16 171.21
  Q 97.96 170.90 97.90 170.35
  Q 97.50 166.94 94.50 166.03
  Q 92.90 165.55 91.64 166.46
  Q 90.54 167.26 89.99 168.58
  C 89.86 168.88 89.88 169.22 90.04 169.52
  Z"
            />
            <path fill="#685f5f" d="
  M 174.89 176.48
  C 174.89 183.57 169.15 189.31 162.06 189.31
  C 154.97 189.31 149.23 183.57 149.23 176.48
  C 149.23 169.39 154.97 163.65 162.06 163.65
  C 169.15 163.65 174.89 169.39 174.89 176.48
  Z
  M 162.7233 186.9615
  C 167.33 186.69 170.79 181.77 170.4453 175.9742
  C 170.10 170.18 166.09 165.70 161.4767 165.9785
  C 156.87 166.25 153.41 171.17 153.7547 176.9658
  C 154.10 182.76 158.11 187.24 162.7233 186.9615
  Z"
            />
            <path fill="#6a615f" fillOpacity="0.996" d="
  M 245.51 171.39
  Q 246.85 172.04 244.82 171.86
  C 244.53 171.84 244.28 172.03 244.23 172.31
  Q 243.38 177.81 244.19 180.06
  Q 246.86 187.53 254.19 186.94
  Q 257.86 186.64 259.38 182.58
  Q 259.61 181.98 259.94 181.66
  Q 261.30 180.35 260.91 182.99
  Q 260.75 184.13 260.20 186.58
  C 260.08 187.12 259.70 187.56 259.18 187.75
  Q 254.24 189.55 249.74 188.82
  Q 238.13 186.92 239.49 174.75
  Q 240.46 166.03 248.75 164.25
  Q 254.61 162.99 260.96 164.91
  C 261.25 165.00 261.46 165.26 261.48 165.56
  Q 261.72 168.69 261.00 171.76
  Q 260.37 174.41 259.65 171.78
  Q 258.18 166.48 253.24 166.11
  Q 247.42 165.68 245.25 170.73
  C 245.14 170.98 245.26 171.27 245.51 171.39
  Z"
            />
            <path fill="#685f5f" d="
  M 302.84 176.46
  C 302.84 183.55 297.10 189.29 290.01 189.29
  C 282.92 189.29 277.18 183.55 277.18 176.46
  C 277.18 169.37 282.92 163.63 290.01 163.63
  C 297.10 163.63 302.84 169.37 302.84 176.46
  Z
  M 290.8336 186.9330
  C 295.44 186.60 298.84 181.63 298.4286 175.8216
  C 298.01 170.02 293.94 165.58 289.3264 165.9070
  C 284.72 166.24 281.32 171.21 281.7314 177.0184
  C 282.15 182.82 286.22 187.26 290.8336 186.9330
  Z"
            />
            <path fill="#685f5f" d="
  M 487.2162 168.8448
  C 491.34 174.54 489.98 182.56 484.1745 186.7618
  C 478.37 190.96 470.33 189.75 466.2038 184.0552
  C 462.08 178.36 463.44 170.34 469.2455 166.1382
  C 475.05 161.94 483.09 163.15 487.2162 168.8448
  Z
  M 477.2627 187.0045
  C 481.88 186.75 485.37 181.82 485.0577 175.9768
  C 484.74 170.14 480.74 165.61 476.1173 165.8555
  C 471.50 166.11 468.01 171.04 468.3223 176.8832
  C 468.64 182.72 472.64 187.25 477.2627 187.0045
  Z"
            />
            <path fill="#6d6262" fillOpacity="0.996" d="
  M 558.69 168.44
  Q 560.84 165.32 565.77 164.36
  Q 572.05 163.15 578.21 164.92
  C 578.49 165.00 578.67 165.27 578.64 165.56
  Q 578.31 168.78 577.96 172.00
  Q 577.79 173.60 576.91 172.80
  C 576.70 172.61 576.55 172.31 576.49 171.95
  Q 575.69 167.10 571.70 166.15
  Q 568.24 165.34 565.11 167.43
  Q 562.06 169.48 561.06 173.51
  Q 560.09 177.43 561.78 181.09
  Q 564.78 187.62 571.53 186.61
  Q 573.37 186.34 574.59 185.12
  Q 575.84 183.86 576.62 182.23
  Q 576.89 181.68 577.18 181.54
  Q 577.48 181.40 577.78 181.52
  C 578.06 181.64 578.22 181.97 578.14 182.31
  L 577.10 186.97
  C 577.04 187.24 576.81 187.49 576.49 187.63
  Q 571.46 189.75 565.99 188.80
  Q 561.35 187.99 558.83 184.69
  Q 556.64 181.83 556.33 177.40
  Q 555.98 172.37 558.69 168.44
  Z"
            />
            <path fill="#6a615f" fillOpacity="0.996" d="
  M 619.88 176.45
  C 619.88 183.55 614.13 189.30 607.03 189.30
  C 599.93 189.30 594.18 183.55 594.18 176.45
  C 594.18 169.35 599.93 163.60 607.03 163.60
  C 614.13 163.60 619.88 169.35 619.88 176.45
  Z
  M 607.7697 186.9118
  C 612.39 186.57 615.78 181.61 615.3575 175.8163
  C 614.93 170.03 610.85 165.61 606.2303 165.9482
  C 601.61 166.29 598.22 171.25 598.6425 177.0437
  C 599.07 182.83 603.15 187.25 607.7697 186.9118
  Z"
            />
            <path fill="#6d6262" fillOpacity="0.996" d="
  M 220.53 186.89
  Q 221.19 187.13 222.44 187.31
  Q 223.71 187.50 223.08 188.13
  C 222.87 188.33 222.55 188.45 222.19 188.45
  L 214.54 188.48
  Q 213.01 188.49 214.26 187.60
  Q 217.15 185.54 218.03 183.04
  Q 218.55 181.53 218.57 181.48
  Q 222.27 172.75 226.06 164.06
  Q 226.61 162.81 227.19 164.31
  Q 231.15 174.59 235.56 184.69
  Q 236.42 186.68 238.31 187.77
  Q 238.73 188.01 238.25 188.09
  Q 233.92 188.86 229.49 188.64
  Q 225.94 188.45 231.06 186.26
  C 231.19 186.20 231.26 186.05 231.21 185.90
  L 229.67 181.49
  C 229.39 180.71 228.64 180.20 227.81 180.24
  L 222.35 180.50
  C 222.00 180.52 221.69 180.71 221.54 181.00
  Q 220.21 183.56 220.08 186.19
  C 220.06 186.51 220.25 186.80 220.53 186.89
  Z
  M 223.31 178.19
  L 227.21 178.58
  C 227.66 178.63 228.02 178.23 227.89 177.83
  L 225.97 171.95
  Q 225.76 171.29 225.48 171.93
  L 222.95 177.66
  C 222.85 177.90 223.03 178.17 223.31 178.19
  Z"
            />
            <path fill="#6a615f" fillOpacity="0.996" d="
  M 338.00 186.54
  L 339.86 187.48
  C 340.20 187.65 340.41 187.93 340.39 188.18
  Q 340.35 188.73 339.46 188.60
  Q 338.71 188.49 338.52 188.48
  Q 334.10 188.29 333.00 188.50
  Q 330.75 188.92 331.42 187.73
  Q 331.65 187.31 332.28 187.00
  Q 333.05 186.63 333.77 186.20
  C 334.04 186.04 334.26 185.78 334.40 185.47
  Q 338.81 175.86 342.76 166.04
  Q 344.43 161.91 345.92 166.53
  Q 349.15 176.53 353.96 185.86
  C 354.10 186.14 354.35 186.37 354.65 186.52
  Q 357.14 187.71 354.68 188.35
  Q 354.11 188.50 353.31 188.50
  Q 350.41 188.50 347.50 188.50
  Q 345.98 188.50 345.18 187.91
  Q 344.74 187.58 345.32 187.59
  Q 347.17 187.61 348.55 186.52
  C 348.81 186.32 348.91 185.98 348.80 185.70
  L 346.97 180.95
  C 346.86 180.66 346.57 180.47 346.24 180.48
  L 339.94 180.64
  C 339.58 180.65 339.28 180.84 339.18 181.12
  L 337.61 185.71
  C 337.51 186.02 337.67 186.37 338.00 186.54
  Z
  M 342.70 172.92
  L 340.69 177.72
  C 340.59 177.97 340.76 178.25 341.04 178.32
  Q 343.25 178.84 345.46 178.31
  C 345.74 178.25 345.90 177.96 345.80 177.71
  L 344.01 172.97
  Q 343.38 171.29 342.70 172.92
  Z"
            />
            <path fill="#685f5f" d="
  M 16.24 177.53
  Q 17.92 181.73 20.87 185.15
  Q 21.53 185.92 22.76 186.62
  Q 26.97 189.03 19.53 189.00
  C 18.68 188.99 17.88 188.60 17.36 187.93
  Q 14.90 184.78 13.38 180.74
  Q 12.45 178.28 9.70 178.25
  C 9.32 178.24 9.00 178.56 9.00 178.94
  Q 9.00 181.50 8.94 184.00
  Q 8.87 187.19 11.92 187.48
  Q 12.35 187.52 12.53 187.68
  Q 12.90 188.00 12.29 188.35
  Q 12.04 188.50 11.68 188.50
  Q 7.25 188.50 2.82 188.50
  Q 1.23 188.50 1.55 187.91
  Q 1.80 187.44 3.93 186.93
  C 4.56 186.78 5.01 186.22 5.00 185.58
  L 4.94 167.56
  C 4.94 167.21 4.82 166.89 4.62 166.69
  Q 3.61 165.65 1.99 165.90
  C 1.70 165.94 1.43 165.76 1.40 165.50
  Q 1.20 163.98 2.88 164.11
  Q 4.25 164.21 4.63 164.18
  Q 10.08 163.82 15.34 165.02
  Q 17.75 165.57 18.77 167.26
  Q 20.45 170.05 19.40 173.53
  Q 19.28 173.93 18.87 174.33
  L 16.40 176.71
  C 16.19 176.91 16.13 177.23 16.24 177.53
  Z
  M 15.52 172.27
  Q 16.33 166.42 9.70 166.50
  C 9.31 166.51 9.00 166.82 9.00 167.21
  L 9.00 175.50
  C 9.00 175.81 9.19 176.08 9.48 176.17
  Q 11.87 176.93 13.60 175.79
  Q 15.17 174.74 15.52 172.27
  Z"
            />
            <path fill="#6a615f" fillOpacity="0.996" d="
  M 33.04 178.06
  Q 32.95 180.66 32.88 183.25
  Q 32.78 187.19 36.75 187.00
  Q 40.13 186.84 41.41 183.05
  C 41.52 182.74 41.78 182.52 42.06 182.51
  Q 43.46 182.46 42.76 183.82
  C 42.60 184.11 42.55 184.43 42.60 184.75
  Q 42.88 186.65 41.77 188.06
  C 41.58 188.30 41.27 188.44 40.94 188.44
  Q 34.10 188.51 27.25 188.36
  Q 24.04 188.29 28.16 186.49
  C 28.67 186.27 29.00 185.77 29.00 185.22
  L 29.00 167.50
  C 29.00 167.14 28.88 166.82 28.68 166.62
  Q 27.93 165.88 26.81 165.90
  C 26.42 165.91 26.09 165.81 25.91 165.64
  Q 25.13 164.94 26.24 164.44
  Q 26.66 164.25 27.29 164.25
  Q 33.66 164.25 40.42 164.25
  C 41.12 164.25 41.68 164.84 41.65 165.56
  L 41.49 169.50
  C 41.48 169.87 41.37 170.19 41.18 170.40
  Q 39.96 171.80 39.93 169.67
  Q 39.91 168.97 39.65 168.52
  Q 37.74 165.21 33.54 166.64
  C 33.24 166.74 33.04 167.01 33.04 167.31
  L 33.05 174.70
  C 33.05 174.99 33.26 175.25 33.55 175.32
  Q 36.81 176.07 38.93 173.10
  Q 39.54 172.24 39.82 173.26
  Q 40.58 176.01 39.15 179.96
  Q 38.85 180.80 38.57 179.96
  Q 37.42 176.53 33.55 177.43
  C 33.26 177.50 33.05 177.76 33.04 178.06
  Z"
            />
            <path fill="#6a615f" fillOpacity="0.996" d="
  M 81.25 187.22
  Q 83.06 187.11 82.88 187.98
  C 82.82 188.24 82.53 188.44 82.18 188.45
  Q 78.09 188.55 74.00 188.52
  Q 70.99 188.50 75.10 186.23
  C 75.49 186.01 75.75 185.61 75.77 185.16
  Q 76.19 177.13 76.00 168.75
  Q 75.94 166.14 73.43 165.85
  C 73.09 165.81 72.82 165.62 72.72 165.36
  Q 72.17 163.98 73.99 164.14
  Q 75.09 164.23 75.52 164.22
  Q 79.02 164.12 82.50 164.45
  C 82.86 164.49 83.15 164.69 83.21 164.96
  Q 83.42 165.92 81.23 166.07
  C 80.54 166.12 80.00 166.69 80.00 167.38
  L 80.00 186.05
  C 80.00 186.73 80.57 187.26 81.25 187.22
  Z"
            />
            <path fill="#6a615f" fillOpacity="0.996" d="
  M 116.28 167.06
  L 116.00 184.00
  Q 115.94 187.57 118.06 187.19
  Q 119.13 187.00 119.52 187.71
  C 119.64 187.93 119.48 188.22 119.20 188.25
  Q 113.95 188.84 108.71 188.14
  Q 107.75 188.01 108.81 187.74
  Q 112.31 186.86 112.10 183.35
  Q 112.00 181.71 112.00 181.65
  Q 112.00 174.57 112.00 167.50
  C 112.00 167.19 111.81 166.92 111.53 166.81
  Q 106.05 164.75 104.90 170.33
  C 104.83 170.66 104.64 170.93 104.38 171.07
  Q 103.10 171.72 103.38 170.27
  Q 103.49 169.66 103.46 169.22
  Q 103.34 167.11 103.72 165.05
  C 103.77 164.76 104.02 164.55 104.32 164.53
  Q 112.70 164.05 121.10 164.23
  Q 121.18 164.24 122.40 164.16
  Q 124.29 164.05 124.08 166.00
  Q 124.03 166.48 124.18 166.96
  Q 124.55 168.08 124.50 170.00
  Q 124.46 171.84 123.46 171.24
  C 123.23 171.10 123.05 170.81 122.97 170.45
  Q 121.91 165.40 116.80 166.45
  C 116.50 166.51 116.29 166.77 116.28 167.06
  Z"
            />
            <path fill="#685f5f" d="
  M 129.26 166.29
  Q 128.46 165.86 127.55 166.05
  C 127.22 166.12 126.90 166.00 126.80 165.76
  Q 126.58 165.25 126.80 164.74
  C 126.91 164.48 127.20 164.31 127.55 164.29
  Q 134.67 163.99 138.75 164.50
  Q 144.88 165.27 144.55 170.79
  Q 144.45 172.59 143.55 174.18
  Q 142.61 175.84 141.21 176.97
  C 140.88 177.24 140.79 177.70 141.00 178.07
  Q 143.43 182.25 145.75 185.49
  Q 146.71 186.82 148.25 186.98
  C 148.62 187.02 148.93 187.15 149.11 187.34
  Q 150.24 188.53 148.00 188.68
  Q 147.00 188.75 146.02 188.89
  Q 143.28 189.27 141.81 187.65
  Q 141.62 187.43 139.84 184.21
  Q 138.43 181.65 138.00 180.50
  Q 137.12 178.12 134.68 178.36
  C 134.39 178.39 134.17 178.63 134.13 178.93
  Q 133.92 180.78 133.97 182.64
  Q 133.97 182.69 133.90 184.36
  Q 133.80 186.86 136.33 187.21
  Q 136.86 187.28 137.14 187.42
  Q 138.37 188.03 136.90 188.41
  Q 136.55 188.50 136.08 188.50
  Q 132.41 188.50 128.75 188.42
  Q 123.85 188.33 129.00 186.77
  C 129.57 186.59 129.96 186.08 129.95 185.52
  L 129.72 167.07
  C 129.71 166.74 129.54 166.44 129.26 166.29
  Z
  M 136.38 166.33
  Q 135.44 166.31 134.53 166.63
  C 134.24 166.73 134.05 167.00 134.05 167.32
  L 134.00 175.49
  C 133.99 175.84 134.21 176.12 134.48 176.14
  L 137.44 176.28
  C 137.69 176.29 138.01 176.13 138.31 175.84
  Q 143.17 171.08 138.06 167.00
  Q 137.26 166.36 136.38 166.33
  Z"
            />
            <path fill="#6a615f" fillOpacity="0.996" d="
  M 193.51 186.39
  L 195.85 187.29
  C 196.24 187.44 196.51 187.65 196.58 187.86
  Q 196.76 188.42 195.50 188.38
  Q 192.22 188.28 188.94 188.19
  Q 184.94 188.09 189.26 186.75
  C 190.00 186.52 190.50 185.84 190.50 185.07
  L 190.50 167.49
  C 190.50 166.71 189.89 166.07 189.11 166.04
  Q 186.41 165.94 186.75 164.93
  C 186.84 164.68 187.12 164.47 187.49 164.39
  Q 193.59 163.10 196.98 167.58
  Q 199.03 170.30 199.11 170.39
  Q 203.63 175.51 207.47 180.33
  Q 208.60 181.75 208.52 179.89
  L 207.98 167.25
  C 207.97 166.89 207.82 166.59 207.60 166.45
  Q 206.80 165.93 205.81 165.92
  C 205.42 165.91 205.09 165.81 204.91 165.64
  Q 204.13 164.94 205.24 164.44
  Q 205.66 164.25 206.29 164.24
  Q 209.74 164.21 213.19 164.30
  C 213.55 164.31 213.87 164.41 214.09 164.60
  Q 214.86 165.27 213.89 165.75
  Q 213.57 165.91 212.95 165.93
  Q 210.82 165.98 210.83 167.98
  Q 210.84 170.44 210.83 170.52
  Q 210.08 176.64 211.00 182.75
  Q 211.28 184.61 210.31 188.45
  C 210.23 188.77 209.98 189.00 209.69 189.02
  Q 208.45 189.10 207.69 187.81
  Q 205.96 184.87 203.73 182.28
  Q 198.62 176.36 193.58 170.39
  Q 193.23 169.97 193.22 170.49
  L 193.05 185.68
  C 193.04 186.00 193.23 186.28 193.51 186.39
  Z"
            />
            <path fill="#6a615f" fillOpacity="0.996" d="
  M 267.98 183.94
  Q 268.37 175.32 267.54 167.18
  C 267.47 166.48 266.87 165.96 266.16 165.99
  Q 264.28 166.09 264.63 164.78
  C 264.71 164.50 264.99 164.31 265.32 164.30
  Q 268.88 164.21 272.44 164.24
  Q 273.33 164.25 274.03 164.46
  Q 277.36 165.48 272.69 166.35
  C 272.40 166.40 272.18 166.65 272.17 166.94
  Q 271.88 174.94 271.99 182.96
  Q 271.99 183.01 271.90 184.54
  Q 271.73 187.29 274.73 187.64
  Q 275.77 187.76 275.35 188.22
  C 275.19 188.40 274.89 188.50 274.55 188.50
  Q 269.78 188.50 265.00 188.41
  Q 264.48 188.40 264.38 188.13
  C 264.31 187.93 264.55 187.69 264.90 187.63
  Q 267.83 187.17 267.98 183.94
  Z"
            />
            <path fill="#6a615f" fillOpacity="0.996" d="
  M 307.49 166.61
  L 305.81 166.21
  C 305.38 166.11 305.06 165.98 304.93 165.86
  Q 303.60 164.58 306.00 164.25
  Q 311.67 163.47 314.88 167.65
  Q 319.58 173.77 325.39 180.36
  Q 325.91 180.95 325.94 180.17
  Q 326.21 173.68 325.73 168.52
  Q 325.72 168.45 325.69 167.59
  Q 325.60 165.35 323.29 166.03
  C 322.96 166.12 322.63 166.07 322.42 165.88
  Q 321.41 164.99 322.75 164.50
  Q 325.89 163.35 331.46 164.69
  Q 331.96 164.81 331.59 165.17
  Q 330.59 166.13 329.17 166.56
  C 328.88 166.64 328.68 166.90 328.67 167.19
  Q 328.38 176.88 328.49 186.56
  Q 328.50 187.73 328.08 188.34
  Q 327.15 189.68 325.69 187.81
  Q 318.85 179.07 311.55 170.68
  Q 310.24 169.17 310.34 171.16
  Q 310.64 177.28 310.55 184.00
  Q 310.51 186.72 312.93 187.02
  C 313.32 187.07 313.64 187.19 313.84 187.36
  Q 315.03 188.34 313.00 188.50
  Q 311.56 188.61 305.13 188.45
  Q 304.76 188.44 304.52 188.30
  Q 303.98 187.99 304.51 187.63
  Q 304.70 187.51 305.02 187.48
  Q 306.45 187.34 307.55 186.51
  C 307.80 186.32 307.95 186.02 307.95 185.69
  L 307.94 167.30
  C 307.94 166.96 307.75 166.67 307.49 166.61
  Z"
            />
            <path fill="#6a615f" fillOpacity="0.996" d="
  M 359.62 166.01
  L 358.51 165.80
  C 358.15 165.74 357.87 165.56 357.75 165.32
  Q 357.13 164.04 358.82 164.16
  Q 359.99 164.24 360.19 164.24
  Q 363.69 164.21 367.19 164.30
  C 367.58 164.31 367.91 164.45 368.03 164.68
  Q 368.53 165.56 365.85 166.49
  C 365.34 166.66 365.00 167.14 365.00 167.68
  L 365.00 184.98
  C 365.00 185.66 365.44 186.25 366.08 186.45
  Q 371.40 188.10 373.25 183.50
  Q 373.70 182.37 374.44 182.13
  C 374.72 182.03 375.01 182.26 374.98 182.56
  L 374.36 188.00
  C 374.33 188.28 374.05 188.50 373.71 188.50
  L 358.92 188.50
  Q 357.03 188.50 358.63 187.49
  Q 361.23 185.86 361.17 183.50
  Q 361.00 176.62 361.00 167.99
  C 361.00 166.99 360.42 166.15 359.62 166.01
  Z"
            />
            <path fill="#685f5f" d="
  M 389.72 186.19
  L 389.97 167.70
  C 389.98 167.15 389.61 166.64 389.06 166.46
  Q 386.45 165.55 386.96 164.68
  C 387.09 164.46 387.41 164.31 387.80 164.29
  Q 395.37 163.99 400.75 164.50
  Q 411.49 165.52 412.14 174.97
  Q 412.43 179.31 410.28 182.65
  Q 407.89 186.34 403.63 187.74
  Q 401.30 188.50 395.79 188.50
  Q 391.85 188.50 387.86 188.50
  Q 386.19 188.50 387.31 187.26
  Q 387.83 186.69 389.19 186.68
  C 389.48 186.67 389.71 186.46 389.72 186.19
  Z
  M 394.18 167.65
  Q 393.88 174.40 393.98 181.35
  Q 393.99 181.41 393.90 183.15
  Q 393.73 186.60 397.50 186.50
  Q 401.10 186.41 403.72 184.61
  Q 406.21 182.90 407.17 179.60
  Q 408.50 174.99 406.30 170.77
  Q 404.89 168.08 402.00 167.11
  Q 398.78 166.03 395.24 166.50
  C 394.65 166.58 394.20 167.07 394.18 167.65
  Z"
            />
            <path fill="#6d6262" fillOpacity="0.996" d="
  M 422.04 175.33
  Q 424.86 176.03 427.09 173.78
  Q 428.82 172.05 428.53 174.48
  Q 428.25 176.88 428.21 179.00
  Q 428.17 181.13 427.04 180.16
  C 426.85 180.01 426.68 179.71 426.56 179.33
  Q 425.72 176.81 422.02 177.64
  C 421.72 177.71 421.51 177.97 421.51 178.27
  L 421.52 183.98
  Q 421.52 186.95 424.58 186.79
  Q 428.72 186.57 429.80 183.41
  C 429.92 183.06 430.10 182.78 430.33 182.62
  Q 431.52 181.78 431.18 183.55
  Q 431.08 184.05 431.10 184.61
  Q 431.15 186.46 430.22 188.01
  C 430.06 188.28 429.76 188.44 429.44 188.44
  Q 422.22 188.54 415.01 188.26
  Q 413.32 188.19 414.32 187.51
  C 414.53 187.36 414.86 187.28 415.25 187.28
  Q 417.46 187.27 417.47 184.75
  Q 417.50 176.25 417.50 167.75
  Q 417.50 165.95 415.50 165.97
  C 415.12 165.98 414.80 165.90 414.57 165.75
  Q 413.20 164.86 414.72 164.39
  Q 415.15 164.25 415.80 164.25
  Q 422.40 164.25 429.08 164.25
  C 429.59 164.25 430.00 164.66 430.00 165.17
  Q 430.00 167.50 430.00 169.76
  Q 430.00 170.30 429.86 170.72
  Q 429.17 172.72 428.56 169.94
  Q 427.86 166.67 426.44 166.56
  L 423.10 166.31
  C 422.27 166.25 421.57 166.82 421.56 167.55
  L 421.54 174.68
  C 421.54 174.99 421.75 175.25 422.04 175.33
  Z"
            />
            <path fill="#6a615f" fillOpacity="0.996" d="
  M 450.54 179.07
  Q 450.44 182.03 450.34 184.99
  Q 450.26 187.38 452.20 186.95
  C 452.55 186.87 452.89 186.90 453.14 187.02
  Q 455.41 188.17 452.24 188.32
  Q 448.37 188.50 444.50 188.42
  Q 441.95 188.37 443.58 187.26
  Q 443.87 187.06 444.51 186.93
  Q 445.44 186.74 446.11 186.06
  C 446.33 185.85 446.45 185.53 446.45 185.18
  L 446.44 167.98
  C 446.44 167.23 445.92 166.57 445.18 166.38
  Q 442.55 165.68 443.01 164.71
  C 443.13 164.47 443.44 164.31 443.82 164.29
  Q 452.12 163.91 455.44 164.56
  Q 460.12 165.47 460.93 169.29
  Q 461.61 172.49 459.48 175.34
  Q 456.65 179.12 451.06 178.62
  C 450.79 178.59 450.55 178.80 450.54 179.07
  Z
  M 456.20 173.65
  Q 456.99 172.41 457.08 170.94
  Q 457.32 166.78 451.98 166.25
  C 451.19 166.17 450.50 166.79 450.50 167.59
  L 450.50 176.17
  C 450.50 176.46 450.73 176.70 451.02 176.71
  Q 454.19 176.84 456.20 173.65
  Z"
            />
            <path fill="#685f5f" d="
  M 494.99 183.94
  Q 495.23 175.84 494.74 167.62
  C 494.72 167.16 494.40 166.76 493.96 166.62
  Q 491.62 165.88 491.17 165.04
  C 491.05 164.82 491.25 164.54 491.56 164.51
  Q 500.31 163.61 503.50 164.50
  Q 511.30 166.69 507.20 173.76
  C 507.04 174.02 506.80 174.24 506.49 174.38
  Q 504.26 175.39 506.82 175.86
  C 507.17 175.93 507.47 176.06 507.70 176.26
  Q 510.03 178.31 510.12 181.23
  Q 510.21 183.89 508.26 185.92
  Q 505.60 188.67 501.25 188.59
  Q 496.80 188.50 492.34 188.50
  Q 491.94 188.50 491.69 188.36
  Q 491.24 188.11 491.44 187.80
  C 491.54 187.64 491.82 187.51 492.18 187.45
  Q 494.90 187.01 494.99 183.94
  Z
  M 498.94 174.23
  C 498.93 174.52 499.18 174.75 499.47 174.74
  Q 504.35 174.62 504.05 170.26
  Q 503.93 168.42 502.93 167.38
  Q 501.68 166.08 499.68 166.35
  C 499.38 166.39 499.16 166.63 499.15 166.93
  L 498.94 174.23
  Z
  M 505.48 182.34
  Q 506.08 177.11 500.12 176.27
  C 499.53 176.19 498.99 176.64 499.00 177.23
  L 499.05 184.69
  C 499.05 185.01 499.19 185.32 499.43 185.52
  Q 501.80 187.60 503.79 185.87
  Q 505.21 184.63 505.48 182.34
  Z"
            />
            <path fill="#6a615f" fillOpacity="0.996" d="
  M 512.42 165.18
  C 512.46 164.66 512.89 164.26 513.40 164.26
  L 522.19 164.30
  C 522.53 164.30 522.85 164.41 523.09 164.62
  Q 524.26 165.65 522.18 165.80
  Q 519.24 166.00 519.42 168.80
  Q 519.50 170.15 519.50 170.19
  Q 519.50 176.65 519.50 184.21
  Q 519.50 186.25 521.47 186.77
  Q 526.44 188.08 528.15 183.07
  Q 528.36 182.47 528.62 182.13
  Q 529.86 180.53 529.78 183.24
  Q 529.76 183.92 529.64 184.59
  Q 529.54 185.18 529.64 185.91
  Q 530.00 188.44 527.00 188.46
  Q 520.38 188.50 513.77 188.50
  Q 512.43 188.50 512.49 187.99
  Q 512.55 187.49 514.70 186.99
  C 515.17 186.88 515.50 186.46 515.50 185.97
  L 515.50 167.00
  C 515.50 166.31 514.66 165.83 513.75 166.00
  Q 512.34 166.25 512.42 165.18
  Z"
            />
            <path fill="#6d6262" fillOpacity="0.996" d="
  M 548.14 185.98
  L 546.47 181.10
  C 546.34 180.73 545.95 180.48 545.48 180.47
  L 539.31 180.36
  C 539.00 180.35 538.73 180.52 538.62 180.77
  Q 537.89 182.49 537.12 184.19
  Q 535.77 187.19 538.89 187.25
  Q 539.29 187.26 539.58 187.41
  Q 541.18 188.21 538.99 188.31
  Q 535.00 188.50 531.58 188.50
  Q 528.94 188.50 531.30 187.32
  Q 534.15 185.89 535.29 182.90
  Q 538.55 174.34 542.27 165.75
  Q 543.58 162.74 544.76 165.80
  Q 547.74 173.48 551.45 182.48
  Q 551.48 182.56 551.87 183.66
  Q 552.72 186.03 555.01 187.25
  Q 557.25 188.45 554.73 188.45
  L 545.81 188.45
  C 545.43 188.45 545.13 188.25 545.12 187.99
  Q 545.11 187.30 547.23 187.18
  C 547.88 187.14 548.34 186.55 548.14 185.98
  Z
  M 542.44 172.16
  L 539.87 177.76
  C 539.76 178.00 539.94 178.29 540.24 178.35
  Q 542.60 178.81 544.95 178.30
  C 545.20 178.25 545.34 177.98 545.24 177.74
  L 542.93 172.20
  Q 542.69 171.61 542.44 172.16
  Z"
            />
            <path fill="#685f5f" d="
  M 589.24 166.81
  Q 588.09 174.54 588.87 182.31
  Q 588.88 182.46 588.84 184.68
  Q 588.80 186.97 591.21 187.39
  Q 591.66 187.47 591.90 187.61
  Q 592.75 188.10 591.61 188.41
  Q 591.31 188.50 590.89 188.50
  Q 586.67 188.54 582.46 188.45
  Q 581.39 188.43 581.42 187.96
  Q 581.45 187.50 583.24 187.11
  C 583.89 186.97 584.38 186.44 584.46 185.79
  Q 585.65 176.63 584.44 167.27
  C 584.36 166.69 583.93 166.23 583.37 166.11
  Q 579.75 165.34 581.57 164.51
  Q 582.13 164.25 582.98 164.24
  Q 586.95 164.21 590.92 164.30
  Q 591.52 164.31 591.87 164.60
  Q 592.79 165.35 590.95 165.80
  Q 590.36 165.95 589.77 166.15
  C 589.49 166.25 589.28 166.50 589.24 166.81
  Z"
            />
            <path fill="#6a615f" fillOpacity="0.996" d="
  M 648.00 164.56
  Q 648.00 165.06 648.00 165.56
  Q 647.63 165.93 646.04 166.86
  C 645.71 167.06 645.50 167.42 645.50 167.82
  L 645.46 187.00
  Q 645.45 191.12 642.76 187.91
  L 628.09 170.37
  Q 627.75 169.97 627.72 170.49
  Q 627.32 177.61 627.49 184.75
  Q 627.55 187.39 629.94 187.35
  C 630.32 187.34 630.65 187.42 630.87 187.56
  Q 631.95 188.26 629.99 188.34
  Q 626.41 188.48 622.83 188.50
  Q 621.23 188.50 621.55 187.91
  Q 621.80 187.44 623.93 186.93
  C 624.56 186.78 625.00 186.22 625.00 185.58
  L 625.00 167.74
  C 625.00 167.39 624.88 167.08 624.67 166.87
  Q 623.88 166.09 622.74 166.00
  C 622.37 165.97 622.06 165.86 621.87 165.69
  Q 620.42 164.37 623.25 164.20
  Q 625.62 164.06 627.95 164.47
  C 628.28 164.53 628.57 164.69 628.78 164.93
  L 642.07 180.20
  Q 643.19 181.49 643.16 179.78
  L 643.00 169.25
  Q 642.98 167.78 640.19 166.06
  Q 637.42 164.36 641.25 164.18
  Q 644.65 164.02 648.00 164.56
  Z"
            />
            <path fill="#8e8982" fillOpacity="0.996" d="
  M 68.29 179.00
  Q 67.77 177.86 66.72 177.75
  Q 66.22 177.69 65.01 178.26
  Q 64.17 178.65 63.25 178.79
  Q 62.32 178.34 61.31 178.39
  C 60.98 178.40 60.69 178.24 60.60 177.99
  Q 60.22 176.94 61.56 176.81
  Q 63.04 176.68 70.00 176.74
  C 70.36 176.75 70.67 176.89 70.83 177.12
  Q 71.29 177.76 70.76 178.21
  Q 70.50 178.42 69.66 178.56
  Q 68.95 178.67 68.29 179.00
  Z"
            />
            <path fill="#6d6262" fillOpacity="0.996" d="
  M 353.75 197.08
  C 354.44 196.70 355.25 196.83 355.61 197.38
  Q 357.02 199.53 354.86 200.21
  Q 351.99 201.12 349.01 201.55
  Q 347.35 201.79 347.62 200.78
  C 347.67 200.59 347.92 200.35 348.28 200.14
  L 353.75 197.08
  Z"
            />
            <path fill="#6a615f" fillOpacity="0.996" d="
  M 564.61 226.72
  Q 565.52 227.22 564.49 227.27
  L 554.30 227.68
  C 554.00 227.69 553.75 227.46 553.77 227.19
  Q 553.85 226.21 555.49 226.55
  C 556.19 226.69 556.84 226.13 556.80 225.42
  Q 556.46 219.05 552.24 219.37
  Q 550.50 219.50 548.72 219.50
  C 548.38 219.50 548.06 219.68 547.88 219.97
  Q 546.32 222.51 546.05 224.75
  C 546.01 225.08 546.11 225.41 546.33 225.64
  Q 547.16 226.50 548.44 226.56
  C 548.76 226.58 549.00 226.81 548.96 227.06
  Q 548.85 227.64 547.99 227.59
  Q 544.30 227.39 540.61 227.65
  Q 539.92 227.70 539.60 227.42
  Q 538.96 226.87 541.04 226.40
  C 541.71 226.25 542.26 225.80 542.52 225.20
  L 551.58 203.97
  Q 552.60 201.58 553.50 203.99
  Q 557.26 214.12 561.64 223.99
  Q 562.09 225.01 562.87 225.59
  Q 563.68 226.19 564.61 226.72
  Z
  M 550.95 211.61
  L 549.04 216.38
  C 548.82 216.92 549.24 217.50 549.83 217.50
  L 553.34 217.47
  C 553.96 217.47 554.35 216.76 554.07 216.13
  L 552.16 211.72
  Q 551.51 210.21 550.95 211.61
  Z"
            />
            <path fill="#6a615f" fillOpacity="0.996" d="
  M 268.44 228.00
  Q 264.81 228.00 261.19 228.00
  Q 250.24 224.88 251.79 213.20
  Q 252.67 206.56 258.84 204.13
  Q 264.04 202.08 269.55 203.26
  Q 271.12 203.59 272.48 203.72
  C 273.06 203.78 273.50 204.27 273.50 204.85
  L 273.50 210.51
  C 273.50 210.84 273.37 211.15 273.14 211.36
  Q 271.44 212.88 271.46 210.14
  Q 271.47 209.11 271.10 208.46
  Q 269.33 205.36 266.02 205.03
  Q 263.24 204.74 260.59 206.37
  Q 256.07 209.14 256.00 215.50
  Q 255.96 219.51 257.94 222.09
  Q 262.35 227.83 269.19 224.94
  Q 270.65 224.32 272.00 221.15
  C 272.13 220.84 272.40 220.64 272.69 220.65
  Q 273.79 220.68 273.28 221.87
  Q 273.08 222.36 273.08 222.81
  Q 273.11 227.31 268.44 228.00
  Z"
            />
            <path fill="#6a615f" fillOpacity="0.996" d="
  M 290.25 226.50
  Q 291.95 226.77 292.18 225.46
  Q 292.29 224.84 291.87 223.70
  Q 291.22 221.90 290.49 220.00
  C 290.38 219.70 290.10 219.51 289.79 219.51
  L 283.56 219.55
  C 283.24 219.55 282.95 219.74 282.84 220.02
  L 280.91 224.77
  C 280.79 225.06 280.87 225.40 281.10 225.61
  Q 281.98 226.39 283.25 226.42
  Q 284.26 226.45 283.98 227.03
  C 283.86 227.27 283.55 227.43 283.18 227.44
  Q 279.84 227.50 276.49 227.56
  Q 276.40 227.56 275.64 227.62
  Q 273.64 227.78 277.27 225.01
  Q 277.72 224.66 277.91 224.21
  L 286.70 203.67
  Q 287.53 201.71 288.33 203.65
  Q 292.52 213.99 296.81 224.69
  Q 297.25 225.78 298.95 226.21
  C 299.33 226.31 299.62 226.48 299.77 226.68
  Q 300.44 227.58 298.50 227.60
  Q 294.09 227.64 289.70 227.35
  C 289.36 227.32 289.10 227.06 289.19 226.82
  Q 289.36 226.36 290.25 226.50
  Z
  M 286.15 211.30
  L 284.10 216.15
  C 283.83 216.79 284.26 217.49 284.92 217.49
  L 288.94 217.46
  C 289.21 217.46 289.40 217.20 289.30 216.96
  L 286.84 211.25
  Q 286.50 210.46 286.15 211.30
  Z"
            />
            <path fill="#6a615f" fillOpacity="0.996" d="
  M 316.94 228.00
  Q 313.56 228.00 310.19 228.00
  Q 308.83 227.30 307.31 227.20
  C 306.96 227.18 306.65 227.08 306.41 226.90
  Q 297.29 220.25 301.75 209.50
  Q 303.41 205.49 308.06 203.81
  Q 312.33 202.27 318.28 203.27
  Q 318.65 203.33 320.73 203.36
  Q 322.76 203.39 322.63 205.56
  Q 322.54 207.25 322.54 207.44
  Q 322.55 209.43 321.97 211.33
  C 321.86 211.69 321.63 211.96 321.37 212.03
  Q 320.55 212.27 320.50 210.75
  Q 320.37 206.75 316.26 205.46
  Q 312.60 204.30 309.34 206.35
  Q 306.37 208.22 305.43 211.29
  Q 304.60 214.02 304.94 217.25
  Q 305.21 219.88 306.69 222.04
  Q 308.07 224.06 310.49 225.15
  Q 317.15 228.14 321.00 220.75
  Q 321.31 220.16 321.81 220.48
  C 322.05 220.63 322.19 220.95 322.18 221.32
  Q 322.07 224.15 320.90 226.74
  C 320.78 227.00 320.50 227.17 320.19 227.17
  Q 318.46 227.18 316.94 228.00
  Z"
            />
            <path fill="#685f5f" d="
  M 353.69 228.00
  Q 350.69 228.00 347.69 228.00
  Q 337.22 224.90 338.50 214.00
  Q 339.27 207.41 344.39 204.64
  Q 348.67 202.33 354.00 203.18
  Q 359.98 204.14 362.48 209.39
  Q 365.06 214.80 363.03 220.23
  Q 360.83 226.09 353.69 228.00
  Z
  M 351.7069 226.0614
  C 356.32 225.79 359.77 220.84 359.4253 215.0142
  C 359.08 209.19 355.06 204.68 350.4531 204.9586
  C 345.84 205.23 342.39 210.18 342.7347 216.0058
  C 343.08 221.83 347.10 226.34 351.7069 226.0614
  Z"
            />
            <path fill="#685f5f" d="
  M 476.69 228.00
  Q 473.56 228.00 470.44 228.00
  Q 469.94 227.54 468.22 227.44
  C 467.71 227.41 467.34 226.96 467.41 226.46
  Q 467.75 223.89 467.18 222.15
  Q 466.93 221.37 467.50 221.25
  C 467.77 221.19 468.10 221.33 468.36 221.61
  Q 468.94 222.25 469.34 223.02
  Q 469.89 224.09 470.25 224.45
  Q 472.07 226.31 474.60 226.09
  Q 476.70 225.91 477.81 224.40
  Q 479.27 222.41 477.19 220.06
  Q 476.28 219.04 472.94 216.43
  Q 469.79 213.97 468.93 212.69
  Q 466.97 209.78 468.46 206.81
  Q 470.03 203.68 473.87 203.05
  Q 477.44 202.47 480.99 204.10
  C 481.27 204.23 481.46 204.50 481.49 204.80
  Q 481.73 207.48 481.15 209.00
  Q 480.24 211.34 479.42 208.97
  Q 477.70 204.03 473.53 205.39
  C 473.29 205.47 473.04 205.69 472.83 206.01
  L 471.63 207.81
  C 471.45 208.08 471.41 208.40 471.53 208.65
  Q 473.02 211.64 476.04 213.24
  Q 478.63 214.61 480.40 216.76
  Q 482.31 219.09 482.10 221.82
  Q 481.75 226.38 476.69 228.00
  Z"
            />
            <path fill="#685f5f" d="
  M 501.44 228.00
  Q 498.31 228.00 495.19 228.00
  Q 488.75 226.44 486.66 221.22
  Q 484.88 216.76 486.40 211.36
  Q 488.93 202.33 500.25 203.00
  Q 505.33 203.30 508.21 206.89
  Q 510.61 209.88 511.03 214.60
  Q 511.91 224.42 501.44 228.00
  Z
  M 499.0973 226.0743
  C 503.70 225.75 507.11 220.77 506.6996 214.9468
  C 506.29 209.12 502.23 204.66 497.6227 204.9857
  C 493.02 205.31 489.61 210.29 490.0204 216.1132
  C 490.43 221.94 494.49 226.40 499.0973 226.0743
  Z"
            />
            <path fill="#6d6262" fillOpacity="0.996" d="
  M 74.31 214.55
  Q 77.71 215.22 79.45 212.90
  Q 80.47 211.53 80.50 213.24
  Q 80.54 215.96 80.46 218.68
  Q 80.44 219.24 80.16 219.60
  Q 79.28 220.71 79.00 218.75
  Q 78.50 215.18 74.66 216.51
  C 74.35 216.62 74.14 216.89 74.14 217.18
  L 74.13 224.57
  C 74.13 224.86 74.33 225.14 74.64 225.27
  Q 80.04 227.55 82.31 221.81
  Q 82.50 221.33 82.89 221.44
  C 83.20 221.53 83.40 221.84 83.37 222.19
  L 82.85 227.20
  C 82.82 227.47 82.53 227.67 82.19 227.65
  Q 75.15 227.21 68.12 227.70
  Q 67.39 227.76 66.90 227.58
  Q 65.31 226.99 68.43 226.15
  C 68.97 226.00 69.37 225.55 69.44 225.00
  Q 70.65 215.50 69.50 206.46
  C 69.42 205.85 69.00 205.34 68.41 205.14
  Q 65.81 204.27 66.40 203.66
  Q 66.75 203.30 67.82 203.34
  Q 74.75 203.57 81.68 203.56
  C 81.99 203.56 82.25 203.75 82.32 204.03
  Q 83.09 206.96 81.68 209.70
  Q 80.84 211.34 80.80 209.49
  Q 80.69 204.11 74.62 205.64
  C 74.25 205.73 73.99 206.08 73.98 206.48
  L 73.85 213.95
  C 73.84 214.24 74.04 214.50 74.31 214.55
  Z"
            />
            <path fill="#6a615f" fillOpacity="0.996" d="
  M 96.56 226.44
  Q 98.51 227.02 98.50 224.00
  L 98.44 206.31
  C 98.44 205.95 98.27 205.64 98.01 205.55
  L 96.00 204.82
  C 95.58 204.67 95.28 204.49 95.19 204.35
  Q 94.72 203.58 95.75 203.33
  Q 96.13 203.24 96.76 203.31
  Q 100.88 203.76 104.99 203.31
  C 105.36 203.27 105.69 203.40 105.81 203.63
  Q 106.28 204.54 103.91 205.24
  C 103.27 205.43 102.82 206.00 102.77 206.66
  Q 102.18 215.15 102.69 223.31
  Q 102.87 226.27 105.40 226.48
  Q 105.85 226.52 106.06 226.64
  Q 106.51 226.89 106.31 227.20
  C 106.19 227.39 105.90 227.51 105.56 227.50
  L 95.81 227.45
  C 95.48 227.45 95.21 227.25 95.15 226.96
  Q 94.94 225.95 96.56 226.44
  Z"
            />
            <path fill="#6d6262" fillOpacity="0.996" d="
  M 139.04 222.75
  Q 139.34 222.81 139.57 222.72
  C 139.79 222.64 139.93 222.33 139.92 221.96
  Q 139.81 218.47 139.92 214.98
  Q 139.94 214.28 139.72 211.04
  Q 139.59 209.03 140.06 207.05
  C 140.12 206.79 139.99 206.51 139.73 206.37
  L 139.04 206.00
  Q 138.19 204.86 136.75 204.75
  C 136.39 204.72 136.09 204.59 135.92 204.37
  Q 135.05 203.22 136.77 203.37
  Q 137.62 203.44 137.88 203.45
  Q 144.45 203.54 151.33 203.50
  C 151.66 203.49 151.94 203.76 151.96 204.09
  Q 152.13 207.75 151.32 209.54
  Q 150.81 210.66 150.47 209.48
  Q 149.43 205.94 147.34 205.49
  Q 146.18 205.24 144.64 205.51
  Q 142.64 205.85 143.25 208.50
  Q 143.88 211.24 143.25 212.50
  Q 142.39 214.22 143.72 214.47
  Q 144.00 214.52 145.28 214.62
  Q 147.43 214.78 148.76 212.99
  Q 150.00 211.31 150.00 213.40
  Q 150.00 215.67 150.00 218.49
  Q 150.00 219.07 149.79 219.45
  Q 149.22 220.53 148.68 219.42
  Q 148.53 219.11 148.50 218.55
  Q 148.40 216.49 146.51 216.39
  Q 144.64 216.29 144.00 216.46
  Q 142.57 216.84 143.22 219.71
  Q 143.67 221.73 143.32 223.78
  Q 142.89 226.29 146.50 226.06
  Q 150.75 225.78 151.88 221.73
  C 151.94 221.50 152.22 221.41 152.38 221.56
  Q 153.63 222.70 152.81 224.38
  Q 152.59 224.82 152.61 225.32
  Q 152.73 227.86 149.50 227.50
  Q 146.75 227.19 136.32 227.67
  C 135.98 227.68 135.69 227.51 135.60 227.24
  Q 135.31 226.38 136.56 226.44
  Q 139.87 226.60 139.04 222.75
  Z"
            />
            <path fill="#6d6262" fillOpacity="0.996" d="
  M 178.69 228.00
  Q 178.06 228.00 177.44 228.00
  Q 170.19 219.50 163.13 210.85
  Q 162.60 210.21 162.15 209.83
  Q 160.11 208.13 160.97 211.11
  Q 161.17 211.83 161.13 212.55
  Q 160.92 215.91 161.31 223.19
  Q 161.48 226.31 164.12 226.65
  C 164.44 226.69 164.67 226.91 164.61 227.11
  Q 164.56 227.28 164.40 227.40
  Q 164.28 227.50 164.07 227.50
  Q 159.94 227.54 155.82 227.45
  C 155.45 227.44 155.16 227.25 155.11 226.99
  Q 154.94 226.15 157.26 226.33
  C 157.93 226.38 158.50 225.85 158.50 225.18
  Q 158.50 216.13 158.50 208.85
  Q 158.50 208.76 158.57 207.66
  Q 158.72 205.37 156.50 205.05
  C 156.13 204.99 155.82 204.87 155.61 204.69
  Q 153.77 203.10 156.82 203.22
  Q 159.01 203.31 161.19 203.51
  C 161.51 203.54 161.82 203.68 162.05 203.91
  Q 169.53 211.14 175.40 219.16
  Q 176.43 220.58 176.48 218.83
  Q 176.68 212.37 176.25 206.50
  Q 176.11 204.64 173.98 204.91
  C 173.64 204.95 173.33 204.87 173.12 204.68
  Q 171.46 203.17 174.75 203.50
  Q 177.21 203.74 181.26 203.31
  C 181.59 203.27 181.89 203.42 182.00 203.67
  Q 182.45 204.74 180.49 205.13
  C 179.63 205.30 179.01 206.05 179.00 206.92
  Q 178.95 216.91 179.06 226.33
  Q 179.06 226.41 179.19 227.02
  Q 179.31 227.58 178.69 228.00
  Z"
            />
            <path fill="#6a615f" fillOpacity="0.996" d="
  M 207.81 226.19
  Q 210.50 226.74 210.50 223.50
  Q 210.50 216.50 210.69 209.51
  Q 210.75 207.64 210.20 206.27
  C 210.06 205.91 209.75 205.64 209.37 205.54
  Q 206.99 204.92 207.15 203.78
  C 207.18 203.53 207.46 203.35 207.80 203.35
  L 216.94 203.35
  C 217.33 203.35 217.66 203.49 217.74 203.69
  Q 218.09 204.52 215.76 205.36
  C 215.00 205.64 214.50 206.35 214.50 207.15
  L 214.50 224.25
  C 214.50 224.60 214.61 224.92 214.81 225.15
  Q 215.75 226.21 217.19 226.31
  C 217.54 226.33 217.81 226.54 217.80 226.79
  Q 217.79 227.46 216.50 227.50
  Q 212.09 227.63 207.70 227.35
  C 207.36 227.33 207.08 227.13 207.00 226.85
  Q 206.76 225.97 207.81 226.19
  Z"
            />
            <path fill="#685f5f" d="
  M 248.06 226.44
  Q 251.09 226.19 249.35 227.42
  C 249.15 227.56 248.83 227.64 248.44 227.64
  L 239.50 227.70
  C 239.06 227.70 238.71 227.61 238.59 227.46
  Q 238.39 227.23 238.53 226.94
  C 238.63 226.73 238.91 226.57 239.27 226.52
  Q 242.00 226.17 242.00 223.00
  Q 242.00 214.87 242.00 206.74
  C 242.00 206.39 241.89 206.07 241.70 205.85
  Q 240.96 205.03 239.82 204.94
  C 239.43 204.91 239.10 204.82 238.90 204.67
  Q 237.69 203.78 239.00 203.37
  Q 239.41 203.24 240.01 203.31
  Q 243.83 203.77 247.66 203.35
  Q 248.94 203.21 249.19 203.72
  Q 249.51 204.37 247.00 205.32
  C 246.40 205.54 246.00 206.12 246.00 206.76
  L 246.00 223.00
  Q 246.00 226.61 248.06 226.44
  Z"
            />
            <path fill="#685f5f" d="
  M 406.71 205.61
  Q 406.27 205.16 405.56 205.15
  Q 404.94 205.14 404.61 204.97
  Q 402.45 203.85 405.51 203.71
  Q 408.97 203.55 412.43 203.34
  Q 415.19 203.18 417.67 204.10
  Q 420.32 205.09 421.41 207.43
  Q 423.25 211.39 419.72 215.22
  Q 417.14 218.01 412.48 217.40
  C 411.81 217.31 411.20 217.80 411.13 218.47
  Q 410.75 222.19 411.73 225.21
  C 411.85 225.58 412.12 225.87 412.48 226.02
  Q 416.64 227.73 412.75 227.61
  Q 409.25 227.51 405.75 227.50
  Q 405.19 227.50 404.77 227.36
  Q 402.92 226.74 405.89 226.15
  C 406.53 226.02 406.99 225.43 406.99 224.74
  L 406.97 206.50
  C 406.97 206.10 406.87 205.77 406.71 205.61
  Z
  M 410.99 206.15
  L 411.24 215.19
  C 411.25 215.47 411.49 215.71 411.81 215.73
  Q 416.89 216.09 417.48 211.88
  Q 417.85 209.23 416.60 207.31
  Q 415.03 204.90 411.56 205.52
  C 411.23 205.58 410.98 205.85 410.99 206.15
  Z"
            />
            <path fill="#6d6262" fillOpacity="0.996" d="
  M 436.75 205.83
  Q 435.89 205.42 434.91 205.42
  Q 433.83 205.42 433.08 205.39
  Q 430.38 205.29 430.77 208.53
  Q 431.05 210.84 430.94 213.19
  C 430.92 213.62 431.80 214.19 433.00 214.50
  Q 434.91 215.00 435.91 213.78
  Q 436.67 212.83 436.70 212.81
  Q 438.10 211.62 438.09 214.25
  Q 438.08 218.09 437.94 218.94
  Q 437.64 220.80 436.90 219.69
  Q 436.75 219.45 436.56 218.89
  Q 435.39 215.39 431.47 216.59
  C 431.19 216.68 431.00 216.94 431.00 217.24
  Q 431.02 220.00 431.19 222.74
  Q 431.46 227.00 436.73 225.47
  C 437.58 225.22 438.22 224.51 438.39 223.64
  Q 438.48 223.18 439.20 222.26
  Q 439.59 221.76 440.10 221.62
  Q 441.53 221.21 440.86 222.96
  Q 440.50 223.91 440.50 224.93
  Q 440.51 228.08 437.50 227.50
  Q 435.30 227.07 424.94 227.71
  Q 424.14 227.76 423.64 227.45
  Q 422.30 226.64 425.85 226.44
  C 426.50 226.40 427.00 225.87 427.00 225.22
  Q 427.00 216.28 427.00 208.91
  Q 427.00 208.79 427.09 207.61
  Q 427.26 205.40 425.08 205.00
  Q 424.47 204.89 424.12 204.63
  Q 422.52 203.40 425.18 203.47
  Q 431.96 203.64 439.61 203.48
  C 439.90 203.47 440.14 203.71 440.10 203.98
  L 439.39 209.02
  C 439.34 209.36 439.13 209.64 438.84 209.78
  Q 438.24 210.06 438.06 209.19
  Q 437.70 207.45 437.38 206.53
  C 437.26 206.22 437.04 205.96 436.75 205.83
  Z"
            />
            <path fill="#6a615f" fillOpacity="0.996" d="
  M 534.71 220.00
  Q 537.04 223.39 535.88 227.33
  C 535.83 227.52 535.86 227.70 535.96 227.76
  Q 536.07 227.83 536.25 227.79
  Q 536.22 227.90 536.19 228.00
  Q 535.81 228.00 535.44 228.00
  Q 531.20 223.49 527.44 218.56
  Q 525.93 216.59 521.16 211.79
  Q 520.89 211.51 520.70 210.95
  Q 520.43 210.13 519.69 209.97
  C 519.43 209.92 519.18 210.14 519.16 210.44
  Q 518.89 215.74 518.98 221.06
  Q 518.98 221.13 518.90 222.93
  Q 518.73 226.28 522.25 226.49
  C 522.63 226.51 522.93 226.69 522.98 226.93
  Q 523.09 227.40 522.25 227.42
  Q 518.12 227.50 514.00 227.37
  Q 512.36 227.31 513.11 226.57
  C 513.30 226.38 513.63 226.27 514.00 226.28
  Q 516.51 226.29 516.51 223.50
  Q 516.50 215.12 516.50 206.82
  C 516.50 206.08 515.98 205.44 515.25 205.30
  Q 512.98 204.85 512.92 204.04
  C 512.90 203.79 513.19 203.57 513.56 203.56
  L 517.78 203.41
  C 518.79 203.38 519.74 203.75 520.39 204.42
  Q 526.85 211.19 531.29 216.52
  Q 531.32 216.56 532.41 218.22
  Q 533.23 219.47 534.71 220.00
  Z"
            />
            <path fill="#7a7573" fillOpacity="0.996" d="
  M 536.25 227.79
  Q 536.07 227.83 535.96 227.76
  C 535.86 227.70 535.83 227.52 535.88 227.33
  Q 537.04 223.39 534.71 220.00
  L 533.94 206.40
  C 533.90 205.72 533.41 205.17 532.78 205.09
  Q 530.78 204.85 530.91 203.79
  C 530.94 203.54 531.22 203.35 531.56 203.35
  L 539.86 203.41
  Q 541.39 203.42 540.13 204.25
  Q 538.65 205.22 537.52 205.30
  C 537.18 205.32 536.92 205.60 536.93 205.94
  Q 537.05 216.53 537.07 226.50
  Q 537.07 227.40 536.25 227.79
  Z"
            />
            <path fill="#6a615f" fillOpacity="0.996" d="
  M 111.39 225.23
  Q 111.92 223.79 112.00 220.75
  Q 112.18 213.99 111.71 206.59
  C 111.66 205.89 111.16 205.30 110.48 205.13
  Q 108.80 204.72 108.56 204.05
  C 108.47 203.81 108.72 203.56 109.05 203.56
  Q 120.19 203.38 122.50 203.50
  Q 130.96 203.94 133.30 210.62
  Q 135.30 216.36 132.05 221.65
  Q 127.88 228.42 118.00 227.50
  Q 115.49 227.27 109.32 227.66
  C 108.95 227.68 108.62 227.53 108.53 227.29
  Q 108.40 226.95 108.69 226.64
  Q 108.87 226.46 109.25 226.37
  Q 110.02 226.21 110.74 225.90
  C 111.05 225.77 111.28 225.52 111.39 225.23
  Z
  M 123.33 206.05
  Q 120.61 205.18 117.02 205.67
  C 116.44 205.75 116.00 206.25 116.00 206.84
  L 116.00 224.22
  C 116.00 224.82 116.45 225.32 117.05 225.39
  Q 121.15 225.86 124.17 224.45
  Q 127.19 223.04 128.71 219.51
  Q 130.88 214.48 127.96 209.85
  Q 126.13 206.96 123.33 206.05
  Z"
            />
            <path fill="#6d6262" fillOpacity="0.996" d="
  M 191.96 205.90
  Q 186.79 204.26 185.06 209.81
  Q 184.57 211.40 183.76 210.19
  C 183.65 210.03 183.61 209.69 183.64 209.28
  L 183.99 204.87
  C 184.05 204.10 184.59 203.50 185.23 203.50
  L 203.58 203.50
  C 203.93 203.50 204.22 203.73 204.27 204.04
  L 205.16 209.44
  C 205.21 209.74 205.07 210.06 204.79 210.24
  Q 203.67 210.98 203.35 209.64
  Q 203.05 208.42 203.04 208.38
  Q 201.61 204.70 197.01 205.54
  C 196.71 205.60 196.50 205.85 196.50 206.15
  Q 196.50 214.18 196.50 221.89
  Q 196.50 221.98 196.41 223.61
  Q 196.21 227.02 199.47 225.98
  C 199.76 225.89 200.06 226.02 200.14 226.27
  Q 200.45 227.28 198.99 227.34
  Q 195.06 227.49 191.13 227.50
  Q 190.63 227.50 189.92 227.73
  Q 188.50 228.18 188.90 226.78
  C 188.97 226.52 189.24 226.35 189.56 226.36
  Q 191.11 226.42 191.92 225.27
  Q 192.43 224.53 192.46 222.98
  Q 192.58 214.77 192.44 206.56
  C 192.44 206.26 192.25 205.99 191.96 205.90
  Z"
            />
            <path fill="#6d6262" fillOpacity="0.996" d="
  M 228.02 214.62
  Q 230.88 215.61 233.50 213.01
  Q 234.50 212.01 234.50 213.42
  L 234.50 218.51
  C 234.50 218.85 234.39 219.17 234.20 219.41
  Q 233.15 220.69 232.78 218.92
  Q 232.61 218.07 232.40 217.77
  Q 231.57 216.53 229.74 216.88
  Q 229.26 216.97 228.79 216.83
  Q 227.20 216.35 227.36 218.00
  Q 227.48 219.14 227.46 219.52
  Q 227.36 222.37 227.81 225.19
  C 227.90 225.76 228.36 226.19 228.93 226.26
  Q 231.08 226.51 231.33 226.99
  C 231.44 227.20 231.10 227.44 230.69 227.45
  Q 226.22 227.54 221.76 227.51
  Q 220.43 227.50 220.49 226.99
  Q 220.55 226.49 222.70 225.99
  C 223.17 225.88 223.50 225.46 223.50 224.97
  L 223.50 206.41
  C 223.50 205.69 222.94 205.09 222.22 205.05
  Q 220.04 204.91 220.52 203.96
  C 220.65 203.71 220.95 203.56 221.31 203.56
  L 235.48 203.50
  C 235.77 203.50 236.00 203.74 236.00 204.03
  Q 236.00 206.87 235.81 208.98
  Q 235.72 210.03 235.02 209.88
  C 234.76 209.83 234.56 209.55 234.52 209.19
  Q 233.95 204.32 228.05 205.85
  C 227.73 205.94 227.50 206.23 227.50 206.55
  L 227.55 213.94
  C 227.55 214.25 227.74 214.52 228.02 214.62
  Z"
            />
            <path fill="#685f5f" d="
  M 333.05 206.06
  L 333.17 225.05
  C 333.17 225.36 333.36 225.65 333.64 225.76
  Q 334.43 226.08 335.28 226.24
  Q 335.81 226.35 336.02 226.55
  Q 336.26 226.79 336.21 227.08
  C 336.16 227.32 335.88 227.51 335.53 227.52
  Q 328.37 227.75 325.69 227.31
  Q 324.99 227.20 325.29 226.82
  C 325.40 226.67 325.64 226.57 325.94 226.53
  Q 329.00 226.13 329.00 222.50
  Q 329.00 215.62 329.15 208.75
  Q 329.23 205.17 325.69 204.56
  Q 325.17 204.47 325.07 204.05
  C 325.00 203.80 325.24 203.56 325.55 203.55
  Q 330.34 203.46 335.13 203.50
  Q 335.55 203.50 335.82 203.66
  Q 336.63 204.15 335.48 204.60
  Q 334.50 204.98 333.51 205.38
  C 333.23 205.49 333.05 205.76 333.05 206.06
  Z"
            />
            <path fill="#6a615f" fillOpacity="0.996" d="
  M 389.19 228.00
  Q 388.56 228.00 387.94 228.00
  Q 380.23 218.87 372.33 209.41
  Q 371.51 208.42 371.54 209.68
  L 371.93 224.83
  C 371.95 225.47 372.43 226.02 373.10 226.18
  Q 375.04 226.65 375.27 227.22
  C 375.35 227.43 375.06 227.64 374.69 227.64
  L 366.31 227.65
  C 365.96 227.65 365.67 227.50 365.55 227.27
  Q 365.11 226.37 366.50 226.50
  Q 368.99 226.73 369.00 224.00
  L 369.08 206.73
  C 369.09 206.19 368.73 205.72 368.22 205.57
  Q 366.71 205.14 365.86 204.59
  Q 364.19 203.50 366.19 203.50
  L 372.00 203.50
  C 372.33 203.50 372.63 203.63 372.83 203.86
  L 386.44 219.62
  Q 386.82 220.06 386.82 219.50
  L 386.80 206.73
  C 386.80 206.29 386.52 205.87 386.07 205.66
  Q 381.70 203.57 385.00 203.53
  Q 387.87 203.50 390.75 203.45
  Q 393.74 203.39 390.24 205.91
  C 389.77 206.24 389.49 206.77 389.50 207.31
  L 389.68 227.07
  C 389.68 227.29 389.59 227.51 389.42 227.70
  Q 389.29 227.84 389.19 228.00
  Z"
            />
            <path fill="#6a615f" fillOpacity="0.996" d="
  M 465.19 228.00
  Q 462.31 228.00 459.44 228.00
  Q 457.76 225.62 453.94 218.56
  Q 452.88 216.60 450.97 217.78
  C 450.71 217.94 450.56 218.23 450.55 218.55
  Q 450.48 220.72 450.49 222.90
  Q 450.49 224.59 451.20 225.41
  Q 452.13 226.48 453.77 226.70
  C 454.18 226.76 454.50 226.95 454.52 227.16
  Q 454.54 227.41 454.17 227.59
  Q 453.97 227.69 453.69 227.67
  Q 448.85 227.38 444.00 227.51
  Q 442.99 227.53 443.27 226.97
  C 443.36 226.79 443.65 226.60 444.04 226.44
  L 446.00 225.67
  C 446.28 225.56 446.46 225.27 446.45 224.93
  L 446.31 206.79
  C 446.30 206.13 445.85 205.53 445.19 205.31
  Q 442.18 204.32 443.60 203.71
  Q 444.08 203.50 444.94 203.50
  Q 449.47 203.49 454.00 203.39
  Q 459.18 203.28 460.85 207.24
  Q 461.89 209.72 460.75 212.23
  Q 459.74 214.45 457.65 215.97
  C 457.42 216.14 457.35 216.44 457.48 216.68
  Q 459.62 220.73 462.41 224.37
  Q 463.12 225.30 465.56 226.44
  Q 467.44 227.31 465.19 228.00
  Z
  M 457.05 211.40
  Q 457.56 207.56 453.75 205.50
  Q 452.59 204.87 450.99 205.79
  C 450.72 205.95 450.55 206.25 450.55 206.57
  L 450.50 214.79
  C 450.50 215.09 450.72 215.34 451.02 215.39
  Q 453.65 215.79 455.23 214.74
  Q 456.73 213.75 457.05 211.40
  Z"
            />
            <path fill="#6d6262" fillOpacity="0.996" d="
  M 581.84 221.20
  Q 581.78 220.38 582.81 220.79
  C 583.08 220.90 583.28 221.17 583.32 221.49
  Q 583.70 224.38 583.00 227.23
  C 582.95 227.43 582.77 227.58 582.57 227.57
  Q 574.42 227.46 566.85 227.50
  Q 566.08 227.50 565.76 227.17
  Q 565.18 226.59 567.63 226.38
  C 568.28 226.32 568.80 225.82 568.88 225.18
  Q 570.12 215.27 569.06 206.24
  C 569.02 205.88 568.87 205.57 568.65 205.39
  Q 568.09 204.92 567.31 204.91
  C 566.93 204.90 566.61 204.80 566.42 204.64
  Q 565.05 203.48 567.50 203.49
  Q 571.63 203.50 575.75 203.50
  C 576.12 203.50 576.43 203.63 576.59 203.85
  Q 576.97 204.37 576.33 204.75
  Q 576.04 204.92 575.52 204.90
  Q 573.23 204.82 573.40 207.04
  Q 573.49 208.34 573.49 208.47
  Q 573.41 216.45 573.55 224.43
  C 573.56 224.75 573.74 225.03 574.02 225.16
  Q 579.56 227.71 581.77 222.15
  Q 581.89 221.84 581.84 221.20
  Z"
            />
            <path fill="#8e8982" fillOpacity="0.996" d="
  M 139.04 206.00
  L 139.73 206.37
  C 139.99 206.51 140.12 206.79 140.06 207.05
  Q 139.59 209.03 139.72 211.04
  Q 139.94 214.28 139.92 214.98
  Q 139.81 218.47 139.92 221.96
  C 139.93 222.33 139.79 222.64 139.57 222.72
  Q 139.34 222.81 139.04 222.75
  L 139.04 206.00
  Z"
            />
        </svg>
    )
}
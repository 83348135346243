import {
  Document,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
import axios from "axios";

import logoA from "../../../assets/images/EstadoMexicoLogo.png";
import HeaderReport from "../../../components/Pdf/Header";
import { peticionEncript } from "../../../helpers/peticionesEncripted";

const s = StyleSheet.create({
  Text: { paddingVertical: "4px" },
  body: {
    paddingVertical: 30,
    paddingHorizontal: 20,
  },

  header: {
    // fontSize: "12px",
    backgroundColor: "#BEBEBE",
    textAlign: "center",
  },
  mCell: { padding: "2px" },

  marginB1: { marginBottom: "1px" },
  textP: { paddingVertical: "4px", paddingHorizontal: "2px" },

  vCenter: { alignItems: "center", justifyContent: "center" },
  vCenterL: { alignItems: "left", justifyContent: "center" },
  vCenterR: { alignItems: "right", justifyContent: "center" },
  vCenterBtw: { alignItems: "stretch", justifyContent: "center" },

  jcBtw: {display: "flex", justifyContent: "space-between"},

  alignE: { alignContent: "flex-end" },

  row: { flexDirection: "row" },
  flexWrap: { display: "flex", flexWrap: "wrap", flexDirection: "row" },

  w8: { width: "8%" },
  w10: { width: "10%" },
  w11: { width: "11%" },
  w13: { width: "13%" },
  w14: { width: "14%" },
  w15: { width: "15%" },
  w16: { width: "16%" },
  w20: { width: "20%" },
  w25: { width: "25%" },
  w26: { width: "26%" },
  w29: { width: "29%" },
  w30: { width: "30%" },
  w39: { width: "39%" },
  w40: { width: "40%" },
  w44: { width: "44%" },
  w45: { width: "45%" },
  w50: { width: "50%" },
  w55: { width: "55%" },
  w58: { width: "58%" },
  w60: { width: "60%" },
  w65: { width: "65%" },
  w70: { width: "70%" },
  w80: { width: "80%" },
  w86: { width: "86%" },
  w87: { width: "87%" },
  w90: { width: "90%" },
  w100: { width: "100%" },
  wAuto: { width: "auto" },

  border: { border: "1px solid #5D6D7E" },
  borderT: { borderBottom: "1px solid #5D6D7E" },
  borderB: { borderBottom: "1px solid #5D6D7E" },
  borderE: { borderRight: "1px solid #5D6D7E" },
  borderS: { borderLeft: "1px solid #5D6D7E" },

  bg: { backgroundColor: "#BEBEBE" },
  bg2: { backgroundColor: "#BEBEBE" },

  fs8: { fontSize: "8px" },
  fs9: { fontSize: "9px" },
  fs10: { fontSize: "10px" },

  tend: { textAlign: "right", paddingRight: "2px" },
  tstart: { textAlign: "left" },
  tcenter: { textAlign: "center" },
  tBold: { fontFamily: "Helvetica-Bold" },

  mT15: { marginTop: "15px" },
  mT10: { marginTop: "10px" },
  mT20: { marginTop: "20px" },
});

const date = new Date();
const lDate = date.toLocaleString("es-MX", { dateStyle: "long" });


async function consulta(params) {
  const finalData = peticionEncript(params);
  let info = [];
  await axios
    .post(process.env.REACT_APP_API + "receiver/receiver.php", finalData)
    .then((res) => {
      if (res.status === 200 && res.data.code === "200") {
        info = res.data.data;
      }
    })
    .catch((err) => {
      // toast(<Notification type={"consultar_servidor_error"} withIcon />, {
      //   closeButton: false,
      // });
    });
  return info;
}

export default async function pdfCheques(emp, che, filtro) {
  const params = {
    action: "select",
    table: "advertisements",
    condition: {
      period: filtro.period,
      year: filtro.year,
      keyTypeAdverstisement: 1
    }
  }
  // let leyenda = await consulta(params);
  // if(leyenda.length > 0) leyenda = leyenda[0];
  // else leyenda = "";

  return (
    <Document>
      <RelacionPage
        bank={emp}
        tt="RELACIÓN DE CHEQUES DE NÓMINA PARA FIRMA"
        pe={`PAGO DE SERVIDORES PÚBLICOS, PERIODO ${filtro.period} DEL AÑO ${filtro.year.getFullYear()}`}
        // leyenda={leyenda}
      />
      <RelacionPage
        bank={che}
        tt="RELACIÓN DE CHEQUES DE PENSIONES ALIMENTICIAS PARA FIRMA"
        pe={`PAGO DE PENSIONES, PERIODO ${filtro.period} DEL AÑO ${filtro.year.getFullYear()}`}
        // leyenda={leyenda}
      />
    </Document>
  );
}

function RelacionPage({ bank, tt, pe, }) {//leyenda
  return (
    <Page style={[s.body, s.fs10]}>
      <HeaderReport />
      {/* <HeaderPage leyenda={leyenda}/> */}
      <View style={[s.mT20]}>
        <View style={[s.tBold, s.tcenter]}>
          <Text st>{tt}</Text>
        </View>
        {bank.map((el, id) => (
          <TableBnacos b={el} key={"b" + id} pe={pe} />
        ))}
      </View>
    </Page>
  );
}

function TableBnacos({ b, pe }) {
  return (
    <View style={s.mT15}>
      <View style={s.row}>
        <Text>
          <Text style={s.tBold}>BANCO: </Text>
          {b.banco}
        </Text>
      </View>
      <View style={s.row}>
        <Text>
          <Text style={s.tBold}>NO. DE CUENTA: </Text>
          {b.cuenta}
        </Text>
      </View>
      <View style={s.row}>
        <Text>
          <Text style={s.tBold}>CLABE: </Text>
          {b.clave}
        </Text>
      </View>

      <View style={[s.mT10, {marginBottom: "15px"}]}>
        <View style={[s.border]}>
          <View style={[s.row, s.header, s.borderB]}>
            <View style={[s.w10, s.borderE, s.vCenter]}>
              <Text>NO.</Text>
            </View>
            <View style={[s.w15, s.vCenter, s.borderE]}>
              <Text>NO. CHEQUE</Text>
            </View>
            <View style={[s.w30, s.vCenter, s.borderE]}>
              <Text>BENEFICIARIO</Text>
            </View>
            <View style={[s.w30, s.vCenter, s.borderE]}>
              <Text>CONCEPTO</Text>
            </View>
            <View style={[s.w15, s.vCenter]}>
              <Text>IMPORTE</Text>
            </View>
          </View>
          {b.cheques.map((el, i) => (
            <View style={[s.row, s.borderBottom]} key={"em" + i}>
              <View style={[s.w10, s.vCenter, s.tcenter, s.borderE]}>
                <Text>{i + 1}</Text>
              </View>
              <View style={[s.w15, s.vCenter, s.tcenter, s.borderE]}>
                <Text>{el.numberCheck}</Text>
              </View>
              <View style={[s.w30, s.vCenteL, s.borderE, s.tstart]}>
                <Text wrap>{el.nombreEmpleado}</Text>
              </View>
              <View style={[s.w30, s.vCenteL, s.borderE, s.tstart]}>
                <Text wrap>{pe}</Text>
              </View>
              <View style={[s.w15, s.vCenterR, s.tend]}>
                <Text>${el.monto}</Text>
              </View>
            </View>
          ))}
        </View>
      </View>
    </View>
  );
}

// function HeaderPage({leyenda}) {
//   return (
//     <View>
//       <View style={[s.row, s.jcBtw]}>
//           <Image src={logoA} style={{ width: "32px", height: "auto" }}></Image>
//           <Image src={logoA} style={{ width: "32px", height: "auto" }}></Image>
//       </View>
//       <View fixed style={[s.row]}>
//         <View style={s.w44}></View>
//         <View fixed style={[s.w66, s.tcenter]}>
//           <Text style={s.fs8}>{leyenda.descripcion}</Text>
//           <View style={s.row}>
//             <View style={s.w50}></View>
//             <View style={[s.w50, s.tstart]}>
//               <Text>Lugar, {lDate}</Text>
//               <Text>No de Control: {date.getFullYear()}</Text>
//             </View>
//           </View>
//         </View>
//       </View>
//     </View>
//   );
// }

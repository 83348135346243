import { Modal, ModalBody, ModalHeader, Button } from 'reactstrap';
import { useState } from "react";
import { toast } from "react-toastify";
import Notification from "../../../components/Notification/Notification";
import { peticionesReceiver } from "../../../helpers/peticionesReceiver";
import Datatable from "../../../components/Datatable/Datatable";
import { createPDF } from "../../../components/Pdf/PdfHtml";
import ModalComponent from '../../../components/Modal/ModalDelete';
import FullFormLoader from '../../../components/Loader/FullFormLoader';
import Widget from '../../../components/Widget/Widget';
import { GetPermissions } from '../../../libs/permissions/getPermissions';

export default function FormatosPersonal() {
    const [permissions, setPermissions] = useState({});
    GetPermissions(setPermissions);

    const [modal, setModal] = useState(false)
    const [pdf, setPDF] = useState(null)
    const [refresh, setRefresh] = useState(false)
    const [loadingPDF, setLoadingPDF] = useState(false)

    const headers = ["Tipo de formato", "Nombre del formato", "Ver", "PDF", "Eliminar"]
    const columns = [
        { data: "nameTypeFormat" },
        { data: "nameFormat" },
        {
            data: "idFormat",
            render: function (row) {
                return (
                    <>
                        {permissions.UPD && <i className="fa fa-eye text-info" style={{ cursor: "pointer" }} onClick={() => watch(row)} />}
                    </>
                )
            }
        },
        {
            data: "idFormat",
            render: function (row) {
                return (
                    <>
                        <i className="fa fa-file-pdf-o text-danger" style={{ cursor: "pointer" }} onClick={() => viewPDF(row)} />
                    </>
                )
            }
        },
        {
            data: null,
            render: function (row) {
                return (
                    <>
                        {permissions.DEL && 
                            <ModalComponent
                                modalTitle="Eliminar"
                                id={row.idFormat}
                                table={["formats"]}
                                nameColumn={["idFormat"]}
                                elimina={() => deleteFile(row.idFormat)}
                            >
                                <h6>
                                    ¿Seguro de eliminar el registro "{row.nameFormat}"?
                                </h6>
                                No podrá recuperar la información después de ser eliminada
                            </ModalComponent>
                        }
                    </>
                )
            }
        },
    ]

    const columnDefs = [
        { targets: [2, 3, 4], className: "text-center", orderable: false }
    ]

    function getParams(ordCol, itemsPerPage, currentPage, search) {
        const params = {
            action: "datatable",
            table: "formats f join types_formats tf on f.keyTypeFormat=tf.keyTypeFormat",
            rows: "f.idFormat,tf.nameTypeFormat,f.nameFormat",
            conditions: "f.enabled=1 AND tf.enabled=1",
            order: ordCol,
            records: itemsPerPage.toString(),
            page: (itemsPerPage * currentPage).toString(),
            search
        }
        return params
    }

    async function getData(ordCol, itemsPerPage, currentPage, search) {
        const API = peticionesReceiver();
        try {
            const res = await API.peticion(getParams(ordCol, itemsPerPage, currentPage, search))
            if (res.status === 200 && res.data.code === "200") {
                return res.data;
            } else {
                toast(<Notification type={"consultar_error"} backMessage={res.data.message} withIcon />);
                return null;
            }
        } catch (err) {
            toast(<Notification type={"consultar_error"} withIcon />);
            return null;
        }
    }

    function watch(row) {
        localStorage.setItem("formatPl", JSON.stringify([row]))
        window.location.href = "#/template/plantillas-formatos/editar"
    }

    async function deleteFile(id) {
        const API = peticionesReceiver();
        const params = {
            action: "delete",
            table: "formats",
            condition: {
                idFormat: id,
            },
        };
        try {
            const res = await API.peticion(params)
            if (res.status === 200 && res.data.code === "200") {
                setRefresh(true)
                toast(<Notification type={"elimina_exito"} backMessage={res.data.message} withIcon />);
            } else {
                toast(<Notification type={"elimina_error"} backMessage={res.data.message} withIcon />);
            }
        } catch (err) {
            toast(<Notification type={"elimina_error"} withIcon />);
        }
    }

    async function viewPDF(id) {
        const API = peticionesReceiver();
        const params = {
            action: "multiselect",
            rows: "nameFormat,textFormat",
            table: "formats",
            conditions: `idFormat=${id} AND enabled = 1`
        };
        try {
            const res = await API.peticion(params)
            if (res.status === 200 && res.data.code === "200") {
                setPDF(null)
                createPDF(res.data.data[0], setPDF)
                setLoadingPDF(true)
                setModal(true)
            } else {
                toast(<Notification type={"consultar_error"} withIcon />);
            }
        } catch (err) {
            toast(<Notification type={"consultar_error"} withIcon />);
            console.log(err)
        }
    }

    const onDoubleClick = async (row) => {
        const idFormat = row.idFormat;
        watch(idFormat)
    }

    return (
        <>
            <Widget className="widget-p-md">
                <h1>Formatos</h1>
                {permissions.INS && <div className='text-right'>
                    <Button color="add" onClick={() => window.location.href = "#/template/plantillas-formatos/agregar"}>Agregar</Button>
                </div>}
                {permissions.CON ? <Datatable
                    headers={headers}
                    columns={columns}
                    columnDefs={columnDefs}
                    petition={getData}
                    control="back"
                    stateRefresh={[refresh, setRefresh]}
                    eventsRow={{onDoubleClick}}
                /> : <div className='text-center mt-3 mb-1'>No tiene permitido ver los registros de esta seccion</div>}
                {pdf && (
                    <Modal isOpen={modal} toggle={() => setModal(false)} size="xl" backdrop="static" >
                        <ModalHeader toggle={() => setModal(false)}>Visualizar pdf</ModalHeader>
                        <ModalBody>
                            <iframe
                                title="PDF Viewer"
                                src={pdf}
                                width="100%"
                                height="500"
                                onLoad={() => {
                                    setLoadingPDF(false)
                                }}
                                id="pdf-iframe"
                            />
                            <div className='text-center'>
                                <Button color='danger' onClick={() => setModal(false)}>Cerrar</Button>
                            </div>
                        </ModalBody>
                    </Modal>
                )}
                <FullFormLoader
                    show={loadingPDF}
                    message="Consultando datos, espere un momento"
                />
            </Widget>
        </>
    )
}
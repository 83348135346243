import Datatable from "../../../../../../components/Datatable/Datatable"
import { peticionesReceiver } from "../../../../../../helpers/peticionesReceiver"
import { toast } from "react-toastify"
import Notification from "../../../../../../components/Notification/Notification"
import { useState } from "react"
import FullFormLoader from "../../../../../../components/Loader/FullFormLoader"
import { Table } from "reactstrap"


export default function Report54({ title, params, route, htmlConfig }) {

    const API = peticionesReceiver()

    const [loading, setLoading] = useState(true)

    const columns = [
        { data: "nameStateFund" },
    ]

    const headers = htmlConfig.headers

    const columnDefs = [
        {
            targets: [1],
            className: "text-right"
        }
    ]

    const getData = async () => {
        try {
            const res = await API.peticionEndPoint(params, route)
            if (res.status === 200 && res.data.code === "200") {
                setLoading(false)
                return res.data;
            } else {
                toast(<Notification type={"consultar_error"} backMessage={res?.data?.message} withIcon />);
                setLoading(false)
                return null;
            }
        } catch (err) {
            toast(<Notification type={"consultar_servidor_error"} withIcon />);
            setLoading(false)
            return null
        }
    }

    return (
        <>
            <h1 className="text-secondary text-center">Presupuesto de Egresos para el Ejercicio Fiscal</h1>
            <div className="px-3">
                <Table>
                    <thead>
                        <tr>
                            <th className="border-top border-bottom text-center">Fiscalia General del Estado de México</th>
                        </tr>
                    </thead>
                </Table>
            </div>
            <Datatable
                columns={columns}
                headers={headers}
                columnDefs={columnDefs}
                ordering={false}
                searching={false}
                paging={false}
                petition={getData}
                info={false}
            />
            <FullFormLoader show={loading} message="Consultando datos , espere un momento" />
        </>
    )
}
import React, { useState } from "react";
import {Row, Col, Button} from "reactstrap"

import { peticionesReceiver } from "../../../helpers/peticionesReceiver";
import TableComponentCustom from "../../../components/TableComponent/TableComponentCustom"
import { saveReport } from "./export/excel";

import { toast } from "react-toastify";
import Notification from "../../../components/Notification/Notification";
import ModalReports from "../../../components/Modal/ModalReports";
import generatePDF from "./export/pdf";

export default function Detalle({ parametros, poliza, setPoliza }) {
    const configuracion = ["account","level2","level3","level4", "level5","description","nameAccount","nameFinancialSource","charge","pay"]
    const cabecerasTabla = ["Cuenta", "SCTA", "SSCTA", "SSSCTA", "SSSSCTA", "Descripción", "Concepto", "Fuente de financiamiento", "Debe", "Haber"]
    const configPDF = {
        orientation: "h",
        content: [
            { header: "Cuenta", value: "account" },
            { header: "SCTA", value: "level2" },
            { header: "SSCTA", value: "level3" },
            { header: "SSSCTA", value: "level4" },
            { header: "SSSSCTA", value: "level5" },
            { header: "Descripción", value: "description" },
            { header: "Concepto", value: "nameAccount" },
            { header: "Financiamiento", value: "nameFinancialSource" },
            { header: "Debe", value: "charge" },
            { header: "Haber", value: "pay" },
        ]
    };
    const [report, setReport] = useState();
    const [modalReports, setModalReports] = useState(false);

    const getDetallesReporte = async () => {
        const API = peticionesReceiver();
        const params = {
            action: "multiselect",
            table: "policiesdetail PD INNER JOIN financialsources FS using(idFinancialSource)",
            rows: "PD.account,PD.level2,PD.level3,PD.level4,PD.level5,PD.nameAccount,PD.description,FS.nameFinancialSource,PD.charge,PD.pay",
            conditions: `PD.idPolicy = ${poliza.idPolicy}`,
        };
        const res = await API.peticion(params)
        if (res.status === 200 && res.data.code === "200") {
            const data = res.data.data;
            if (data && data.length > 0) {
                return data
            } else {
                toast(<Notification type={"consultar_error"} withIcon />);
                return null
            }
        }else {
            toast(<Notification type={"consultar_error"} withIcon />);
            return null
        }
    }

    const excel = async () => {
        const detalles = await getDetallesReporte();
        if (detalles) {
            saveReport({ poliza, detalles }, "Detalle de poliza", configuracion);    
        }
    }

    const pdf = async () => {
        const detalles = await getDetallesReporte();
        setReport({ poliza, detalles });
        setModalReports(true);
    }

    return (
        <>
            <Row className="mb-3">
                <Col>
                    <Button className="mr-2" color="success" onClick={() => { localStorage.removeItem("poliza");  setPoliza(null)}}>
                        Regresar
                    </Button>
                    <Button className="mr-2" color="secondary" onClick={pdf} >
                        Generar PDF
                    </Button>
                    <Button color="secondary" onClick={excel}>
                        Generar Excel
                    </Button>
                </Col>
            </Row>
            <Row className="mx-1">
                <Col xs={12} md={8} className="bg-primary text-light">
                    Datos generales:
                </Col>
                <Col xs={12} md={4} className="bg-primary text-light">
                    Detalle de poliza:
                </Col>
            </Row>
            <Row className="mx-1">
                <Col xs={12} md={8} className="border border-dark">
                    Ente público: SAPP
                </Col>
                <Col xs={12} md={4} className="border border-dark">
                    Poliza: {poliza.number}/{poliza.year} - {poliza.acronim} {poliza.nameKindPolicy}
                </Col>
            </Row>
            <Row className="mx-1">
                <Col xs={12} md={8} className="border border-dark">
                </Col>
                <Col xs={12} md={4} className="border border-dark">
                    Fecha: {poliza.created}
                </Col>
            </Row>
            <Row className="mx-1">
                <Col xs={12} md={8} className="border border-dark">
                    Descripción: {poliza.nameEvent}
                </Col>
                <Col xs={12} md={4} className="border border-dark">
                    Mes: {poliza.nameMonth}
                </Col>
            </Row>
            <Row className="mx-1">
                <Col xs={12} md={8} className="border border-dark">
                    Elaboró: {poliza.elaboro}
                </Col>
                <Col xs={12} md={4} className="border border-dark">
                    Evento del sistema: {poliza.idPolicy}
                </Col>
            </Row>
            <TableComponentCustom
                cabecerasTabla={cabecerasTabla}
                filtro={[true, true, true, true, true, true, true, true, true, true]}
                parametros={parametros}
                disabledDelete
            />
            {modalReports ? (
                <ModalReports
                    modal={modalReports}
                    setModal={setModalReports}
                    title={"Detalle de poliza"}
                    backdrop={"static"}
                    keyboard={false}
                    report={report}
                    pdfConfig={configPDF}
                    cabeceras={cabecerasTabla}
                    generatePdfMethod={{
                        method: generatePDF,
                        type: "PDFHonorarios",
                    }}
                    records={1000}
                />
            ) : null}
        </>
    )
}
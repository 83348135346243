import React, { useState, useEffect, useRef } from "react";

import * as Yup from "yup";
import { useFormik } from "formik";
import { Button, Row, Col, Collapse } from "reactstrap";
import { peticionesReceiver } from "../../../../helpers/peticionesReceiver";
import ModalDelete from "../../../../components/Modal/ModalDelete";
import Datatable from "../../../../components/Datatable/Datatable";
import { GetPermissions } from "../../../../libs/permissions/getPermissions";
import { toast } from "react-toastify";
import Notification from "../../../../components/Notification/Notification";
import ModalConfirmation from "../../../../components/Modal/ModalConfirmation";
import { FilesInput, SelectTypeHeadSingle } from "../../../../components/GenericInputsFormik/index";
import { Loader } from "react-bootstrap-typeahead";
import { TextInput } from "../../../../components/GenericInputsFormik/index";
import TableComponentFiles from "../../../../components/TableComponent/TableComponentFiles";
import { doRequest, uploadFile } from "../../../../helpers/requests";
import FullFormLoader from "../../../../components/Loader/FullFormLoader";
import ModalViewFile from "../../../../components/Modal/ModalViewFile";

const FormSchema = Yup.object().shape({
    keyTypePurchaseDoc: Yup.string().required("Seleccione un tipo de documento"),
    file: Yup.mixed()
        .required("Agregue un documento")
        .test("file-type", "El documento debe ser en formato PDF", (value) => {
            return value && value.type === "application/pdf";
        })
        .test({
            message: `El documento debe pesar menos de 4MB`,
            test: (value) => value?.size < 4000000,
        }),
});

export default function FilesTab({ idPurchaseRequest, revision }) {
    const API = peticionesReceiver()
    const [modalConfirm, setModalConfirm] = useState(false);
    const [loading, setLoading] = useState(false);
    const [permissions, setPermissions] = useState("");
    GetPermissions(setPermissions, setLoading)
    const [collapse, setCollapse] = useState(false);
    const [clearFiles, setClearFiles] = useState([]);
    const [refresh, setRefresh] = useState(false);
    const refScroll = useRef(null);
    const refInpFile = useRef(null);
    const [modalFile, setModalFile] = useState(false);
    const [linkFile, setLinkFile] = useState("")

    const defaultForm = {
        idPurchaseRequest,
        keyTypePurchaseDoc: "",
        file: "",
    };

    useEffect(() => {
        if(!revision) filterFiles();
    }, []);

    const {
        handleSubmit,
        values,
        handleBlur,
        errors,
        touched,
        setFieldValue,
        handleReset,
    } = useFormik({
        initialValues: defaultForm,
        validationSchema: FormSchema,
        onSubmit: (values) => setModalConfirm(true),
        onReset: (v) => { refInpFile.current.value = "" },
        enableReinitialize: true,
    });

    const onChange = (e) => {
        if (e.target.name === "file") {
            if (e.target.files.length === 0) setFieldValue(e.target.name, "");
            else setFieldValue(e.target.name, e.target.files[0]);
        } else {
            setFieldValue(e.target.name, e.target.value);
        }
    };

    const saveFile = async () => {
        setLoading(true);
        try {
            const res = await API.peticion({
                action: "insert",
                table: "purchasefiles",
                rows: {
                    keyTypePurchaseDoc: values.keyTypePurchaseDoc,
                    idPurchaseRequest: values.idPurchaseRequest
                },
                validate: [["keyTypePurchaseDoc", "idPurchaseRequest"]]
            })
            if (res.status === 200 && res.data.code === "200") {
                const data = res.data.data[0];
                toast(<Notification type={"agrega_exito"} backMessage={res.data.message} withIcon />);
                const res2 = await uploadFile(data.idFile, "saveFileReplace", 58, values.file, true);
                if (res2) {
                    setCollapse(false);
                    filterFiles();
                    setRefresh(true);
                }
            } else {
                toast(<Notification type={"agrega_error"} backMessage={res.data.message} withIcon />);
            }
        } catch (err) {
            console.log(err)
            toast(<Notification type={"consultar_servidor_error"} withIcon />);
        }
        setLoading(false);
    };

    const filterFiles = async () => {
        setLoading(true);
        const params = {
            action: "multiselect",
            table: "purchaserequests pr INNER JOIN types_purchases tp ON (pr.keyTypePurchase = tp.keyTypePurchase) INNER JOIN types_purchasedocs tpd on (tpd.keyTypePurchase = tp.keyTypePurchase) INNER JOIN purchasedocs pd on (pd.keyPurchaseDoc = tpd.keyPurchaseDoc)",
            rows: "tpd.keyTypePurchaseDoc,pd.namePurchaseDoc",
            conditions: `tpd.keyTypePurchaseDoc NOT IN (SELECT keyTypePurchaseDoc from purchasefiles where idPurchaseRequest = ${idPurchaseRequest} AND enabled = 1) AND pr.idPurchaseRequest=${idPurchaseRequest}`,
        }
        const data = await doRequest("receiver/receiver.php", params, false, true)
        setClearFiles(data);
        setLoading(false);
    };

    const scrollTo = () => {
        refScroll.current.scrollIntoView({ behavior: "smooth" });
    };
    const headers = [
        "Tipo de archivo",
        "Fecha de subida",
        "Ver",
        "Eliminar"
    ]

    const columns = [
        { data: "namePurchaseDoc" },
        { data: "created" },
        {
            data: "src",
            render: function (row) {
                return (
                    <i className="fa fa-eye text-info cursor-pointer" onClick={() => {
                        setLinkFile(row);
                        setModalFile(true)
                    }} />
                )
            }
        },
        {
            data: null,
            render: function (row) {
                return (
                    <ModalDelete
                        modalTitle="Eliminar"
                        table=""
                        nameColumn={[""]}
                        elimina={deleteModal}
                        id={row}
                    >
                        <h6>
                            ¿Seguro de eliminar el registro?
                        </h6>
                        No podrá revertir la acción
                    </ModalDelete>
                )
            }
        },
    ]

    async function deleteModal(data) {
        //console.log(data);
        const params = {
            action: "delete",
            table: "purchasefiles",
            condition: {
                idFile: data.pfile,
            }
        };
        try {
            const res = await API.peticion(params);
            if (res.status === 200 && res.data.code === "200") {
                const params2 = {
                    action: "delete",
                    table: "files",
                    condition: {
                        idFile: data.idFile,
                    }
                };
                const res2 = await API.peticion(params2);
                if (res2.status === 200 && res2.data.code === "200") {
                    toast(
                        <Notification
                            type={"elimina_exito"}
                            backMessage={res.data.message}
                            withIcon
                        />
                    );
                    setRefresh(true);
                } else {
                    toast(<Notification type={"elimina_exito"} backMessage={res.data.message} />);
                }
            } else {
                toast(<Notification type={"elimina_exito"} backMessage={res.data.message} />);
            }
        } catch (err) {
            toast(<Notification type={"consultar_servidor_error"} withIcon />);
        }
    }

    const getParams = (order, records, page, search) => {
        const params = {
            action: "datatable",
            table: "purchaserequests pr inner join types_purchases tp on(pr.keyTypePurchase = tp.keyTypePurchase) inner join types_purchasedocs tpd on (tpd.keyTypePurchase = tp.keyTypePurchase) inner join purchasedocs pd on (tpd.keyPurchaseDoc = pd.keyPurchaseDoc) inner join purchasefiles pf on (pf.keyTypePurchaseDoc = tpd.keyTypePurchaseDoc) inner join bookcase b on (b.reference = pf.idFile AND keyFileType = 58) inner join files f on (f.idBook = b.idBook)",
            rows: "pd.namePurchaseDoc,f.created,pf.idFile as pfile,f.idFile,f.src",
            conditions: `pf.enabled=1 AND f.enabled = 1 AND pr.idPurchaseRequest=${idPurchaseRequest}`,
            order,
            records: records.toString(),
            page: (page * records).toString(),
            search
        }
        return params
    }

    async function getData(order, records, page, search) {
        try {
            const res = await API.peticion(getParams(order, records, page, search));
            if (res.status === 200 && res.data.code === "200") {
                return res.data;
            } else {
                toast(<Notification type={"consultar_error"} backMessage={res.data.message} />);
                return null;
            }
        } catch (err) {
            toast(<Notification type={"consultar_servidor_error"} withIcon />);
            return null
        }
    }
    
    if(revision){
        headers.pop();
        columns.pop();
    }

    return (
        <>
            <div className="mx-4 my-3">
                <Datatable
                    columns={columns}
                    headers={headers}
                    petition={getData}
                    control="back"
                    stateRefresh={[refresh, setRefresh]}
                />
                {
                    !revision &&
                    <Row className="my-3">
                        <Col xs={12} md={6} className="text-center text-md-left">
                            {collapse && <h5>Agregar Archivo</h5>}
                        </Col>
                        {permissions.INS && <Col xs={12} md={6} className="text-center text-md-right">
                            <Button color="add" onClick={() => setCollapse(true)} disabled={clearFiles.length === 0}>
                                Agregar
                            </Button>
                        </Col>}
                    </Row>
                }

                <Collapse
                    isOpen={collapse}
                    onExited={() => handleReset()}
                    onEntered={() => scrollTo()}
                >
                    <form onSubmit={handleSubmit} onReset={handleReset} className="mt-2">
                        <ModalConfirmation
                            modalTitle="Guardar Documneto"
                            modal={modalConfirm}
                            setModal={setModalConfirm}
                            editar={() => saveFile()}
                            isEdit={true}
                        >
                            <div className="d-flex justify-content-center text-center">
                                <p style={{ fontSize: "20px" }} className="mb-2">
                                    ¿Esta seguro de guardar el documento <br />
                                </p>
                            </div>
                        </ModalConfirmation>
                        <Row>
                            <Col xs={12} lg={6}>
                                <SelectTypeHeadSingle
                                    label="Tipo de Documento"
                                    isRequired={true}
                                    inputName="keyTypePurchaseDoc"
                                    optionsArray={clearFiles}
                                    defaultOption="Seleccione un tipo de archivo"
                                    onChangeMethod={onChange}
                                    onBlur={handleBlur}
                                    value={values.keyTypePurchaseDoc}
                                    touched={touched.keyTypePurchaseDoc}
                                    errors={errors.keyTypePurchaseDoc}
                                    optionValue="keyTypePurchaseDoc"
                                    optionName="namePurchaseDoc"
                                />
                            </Col>
                            <Col xs={12} lg={6}>
                                <FilesInput
                                    label="Documento"
                                    inputName="file"
                                    onChangeMethod={onChange}
                                    onBlurMethod={handleBlur}
                                    fileAccept="application/pdf"
                                    isRequired
                                    touched={touched.file}
                                    errors={errors.file}
                                    basicReq={false}
                                    refInput={refInpFile}
                                />
                            </Col>
                        </Row>

                        <Row className="mt-5">
                            <div
                                className="col-sm-6 order-2 order-sm-1 text-center text-sm-left mb-4"
                                ref={refScroll}
                            >
                                <Button
                                    color="danger"
                                    type="reset"
                                    onClick={() => {
                                        setCollapse(false);
                                    }}
                                >
                                    Cancelar
                                </Button>
                            </div>
                            <div className="col-sm-6 order-1 order-sm-2 text-center text-sm-right mb-4">
                                <Button color="success" type="submit">
                                    Guardar
                                </Button>
                            </div>
                        </Row>
                    </form>
                </Collapse>
                <FullFormLoader show={loading} />
            </div>
            <ModalViewFile
                modal={modalFile}
                setModal={setModalFile}
                backdrop={true}
                keyboard={true}
                btnClose={true}
                fileLink={linkFile}
            />
        </>
    )
};

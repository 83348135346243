import { useState } from "react";
import TableCheck from "./TableCheck";
import { fDate } from "../../../libs/tools/format";
import FullFormLoader from "../../../components/Loader/FullFormLoader";

const headers = [
  "Número De Empleado",
  "Nombre",
  "Puesto",
  "Unidad de trabajo",
  "Folio",
  "Movimiento",
  "Región",
  "Fechas De Plaza",
  "Registro",
];

const columnDefs = [
  {
    orderable: true,
    targets: [ 1, 4, 5, 6, 7, 8, 9],
    className: "text-center",
  },
  { targets: [0], orderable: false, className: "text-left" },
];


export default function FirmaOtros({permissions}) {
  const [loading, setLoading] = useState(false);
  const params = {
    // action: "datatable",
    // table: "bookcase A INNER JOIN files B USING(idBook) INNER JOIN filetypes C USING (keyFileType) ",
    // rows: "C.nameFyleType, A.nameFile, B.created, B.src, A.reference, C.keyFileType, B.idFile",
    // // conditions: "A.enabled = 1 AND C.keyFileType != 1",
    // conditions: "A.enabled = 1 AND A.signature = 1 ",


    /* action: "datatable",
    table: "bookcase A INNER JOIN files B USING(idBook) INNER JOIN filetypes C USING (keyFileType) INNER JOIN jobstaff D  ON (D.idJobStaff = A.reference) ",
    rows: "C.nameFyleType, A.nameFile, B.created, B.src, A.reference, C.keyFileType, B.idFile,D.folio",
    // conditions: "A.enabled = 1 AND C.keyFileType != 1",
    conditions: "A.enabled = 1 AND B.signature = 1 ",
    page: 0,
    records: 5,
    search: "",
    order: "", */


    
      action: "datatable",
      table:
        "bookcase A LEFT JOIN files B USING(idBook) LEFT JOIN staff D on (A.reference = D.idStaff) LEFT JOIN jobstaff C using(idStaff) INNER JOIN jobs E USING(keyJob) LEFT JOIN areas F USING(idArea) INNER JOIN buildings G ON G.idBuilding = C.idBuilding LEFT JOIN cities H ON H.keyCity = G.keyCity LEFT JOIN counties I USING(keyCounty) LEFT JOIN districts J USING(keyDistrict) LEFT JOIN regions K USING(keyRegion) LEFT JOIN types_move L ON C.keyMove = L.keyMove",
      rows: "A.idBook,D.staffNumber, CONCAT_WS(' ',D.name, D.pName, D.mName) AS fullName, E.nameJob, F.name as areaPlaza, C.folio, K.nameRegion, L.nameMove, C.startDate, C.endDate, A.created, A.reference, A.keyFileType, B.src",
      conditions:
        /* "A.keyFileType = 18 AND A.enabled = 1 AND B.enabled = 1 AND C.enabled = 1 AND B.signature = 0", */
        "A.enabled = 1 AND B.enabled = 1 AND C.valid= 1  AND B.signature = 1",
      page: 0,
      records: 5,
      search: "",
      order: "",
    
  };

  const columns = [
    { data: "staffNumber" },
    { data: "fullName" },
    { data: "nameJob" },
    { data: "areaPlaza" },
    { data: "folio" },
    { data: "nameMove" },
    { data: "nameRegion" },
    {
      data: null,
      orderValue: "startDate",
      render: function (d) {
        return (
          <div>
            {fDate(d.startDate)} AL {fDate(d.endDate)}
          </div>
        );
      },
    },
    { data: "created", format: fDate },
  ];

  return (
    <div className="mx-4">
      <TableCheck
        headers={headers}
        columns={columns}
        params={params}
        paramsAll={params}
        columnDefs={columnDefs}
        permissions={permissions}
        setLoading={setLoading}
      />
      <FullFormLoader show={loading} message="Firmando documentos, espere un momento"/>
    </div>
  );
}


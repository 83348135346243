import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import { useHistory } from "react-router-dom";
import { Col, Row, Button } from "reactstrap";
import Loader from "../../../components/Loader/Loader";
import {
  TextInput,
  SelectTypeHeadSingle
} from "../../../components/GenericInputsFormik/index";
import * as Yup from "yup";
import { peticionesReceiver } from "../../../helpers/peticionesReceiver";
import { toast } from "react-toastify";
import Notification from "../../../components/Notification/Notification";
import ModalConfirmation from "../../../components/Modal/ModalConfirmation";

export const FormCuentas = ({data, insertInfo}) => {
  const API = peticionesReceiver();
  const history = useHistory();
  const [account,setAccount] = useState(
    data  ? 
    {
      idAccountList: data[0],
        parentAccountList: data[5] === "" ? 0: parseInt(data[5]),
        nameAccountList:data[3],
        accountListKey: data[4],
        keyPlan: data[6] === "" ? 0 : data[6]
    }
    :
    {
      idAccountList: "",
      parentAccountList: 0,
      nameAccountList: "",
      accountListKey: "",
      keyPlan:0
    }
  )

  const [modal, setModal] = useState(false);
  const [plans, setPlans] = useState([]);
  const [accounts, setAccounts] = useState([]);
  const [loader, setLoader] = useState(false);

  const getPlans = async () => {
    const params = {
      action: "getAccountPlans",
      rows:{}
    };
    API.peticionEndPoint(params,"app/facades/accounts/accountsF.php")
    .then((res) => {
        if (res.status === 200) {
          setPlans(res.data.data);
        } else {
          toast(
            <Notification
              type={"consultar_error"}
              backMessage={res.data.message}
              withIcon
            />
          );
        }
      })
      .catch((err) => {
        toast(<Notification type={"consultar_servidor_error"} withIcon />);
      });
  };
  const getAccounts = async () => {
    const params = {
      action: "select",
      table: "account_list",
      condition: {
        enabled: 1,
      },
      order: "nameAccountList ASC",
    };
    API.peticion(params)
      .then((res) => {
        if (res.status === 200 && res.data.code === "200") {
          setAccounts(res.data.data);
        } else {
          toast(
            <Notification
              type={"consultar_error"}
              backMessage={res.data.message}
              withIcon
            />
          );
        }
      })
      .catch((err) => {
        toast(<Notification type={"consultar_servidor_error"} withIcon />);
      });
  };

  useEffect(() => {
    getPlans()
    getAccounts()
    

    setLoader(false)
  },[])
  

  const FormSchema = Yup.object().shape({
    nameAccountList: Yup.string()
    .required("Ingrese un nombre"),
    accountListKey: Yup.string()
    .required("Ingrese una clave")
    .max(20,"solo puede tener como maximo 20 caracteres"),
    keyPlan:Yup.number()
    .min(1,"seleccione un plan"),
    /* parentAccountList: "", */
  });

  const onChange = (e) => { 
    setFieldValue([e.target.name], e.target.value);
  };
  


  const { handleSubmit,handleBlur ,values, errors, touched, setFieldValue, resetForm } =
    useFormik({
      initialValues: account,
      onSubmit: (values) => {
        
        setModal(true)
      },
      validationSchema: FormSchema,
      enableReinitialize: true,
    });

  return (
    <>  
    {loader ? (
      <Loader/>
    ):
    (
      <>
         <ModalConfirmation
          modalTitle={data ? "Editar" : "Crear"}
          /* modalTitle={"Crear"} */
          modal={modal}
          setModal={setModal}
          crear={insertInfo}
          editar={insertInfo}
          isEdit={data ? true : false}
          values={values}
          reset={resetForm}
        >
          {false ? (
            <div className="d-flex justify-content-center">
              <h6>¿Está seguro de editar el registro?</h6>
            </div>
          ) : (
            <div className="d-flex justify-content-center">
              <h6>¿Desea continuar con el registro?</h6>
            </div>
          )}
        </ModalConfirmation>
          <form onSubmit={handleSubmit} className="mt-4" noValidate>
          <Row className="d-flex justify-content-around">
            <Col xs={12} md={12} lg={5}>
              <SelectTypeHeadSingle
                label="Plan de cuenta"
                isRequired={true}
                inputName="keyPlan"
                optionsArray={plans}
                defaultOption="Seleccione un plan de cuenta"
                onChangeMethod={(event) =>
                  {
                    const {name,value} = event.target
                    setFieldValue(name, value)
                  }
                }
                onBlur={handleBlur}
                isDisabled={data ? true : false}
                value={values.keyPlan}
                touched={touched.keyPlan}
                errors={errors.keyPlan}
                optionValue="keyPlan"
                multipleLabels={["planKey","namePlan"]}
                optionName="name"
                /* optionName="namePlan" */
              />
            </Col>
            <Col xs={12} md={12} lg={5}>
              {/* <SelectWithSearchInput
                label="Seleccione la cuenta padre"
                inputOptions={accounts}
                inputName="parentAccountList"
                onChangeMethod={(options) =>
                  setFieldValue("parentAccountList", options.value)
                }
                onBlurMethod={handleBlur}
                touched={touched.parentAccountList}
                errors={errors.parentAccountList}
                optionValue="idAccountList"
                optionName={["fullKey", "nameAccountList"]}
                inputValue={values.parentAccountList}
                isDisabled={data ? true:false}
              /> */}
              <SelectTypeHeadSingle
                label="Cuenta padre"
                isRequired={false}
                inputName="parentAccountList"
                optionsArray={accounts}
                defaultOption="Seleccione la cuenta padre"
                onChangeMethod={(event) =>
                  {
                    const {name,value} = event.target
                    setFieldValue(name, value)
                  }
                }
                onBlur={handleBlur}
                isDisabled={data ? true : false}
                value={values.parentAccountList}
                touched={touched.parentAccountList}
                errors={errors.parentAccountList}
                multipleLabels={["fullKey","nameAccountList"]}
                optionValue="idAccountList"
                optionName="name"
              />
            </Col>
          </Row>
          
          
            <Row className="d-flex justify-content-around">
              <Col xs={12} md={12} lg={5}>
                <TextInput
                  label="Nombre de la cuenta"
                  inputType="text"
                  inputName="nameAccountList"
                  onChangeMethod={onChange}
                  onBlurMethod={handleBlur}
                  value={values.nameAccountList}
                  isRequired={true}
                  touched={touched.nameAccountList}
                  errors={errors.nameAccountList}
                  
                />
              </Col>
              <Col xs={12} md={12} lg={5}>
                <TextInput
                  label="Clave de la cuenta"
                  inputType="text"
                  inputName="accountListKey"
                  onChangeMethod={onChange}
                  onBlurMethod={handleBlur}
                  value={values.accountListKey}
                  isRequired={true}
                  touched={touched.accountListKey}
                  errors={errors.accountListKey}
                  isDisabled={data ? true:false}
                />
              </Col>
            </Row>
            
            <div className="row m-3">
              <div className="col-sm-6 order-2 order-sm-1 text-center text-sm-left mb-4">
                  <Button
                  color="danger"
                  type="reset"
                  onClick={() => history.push({ pathname: "/template/cuentas-contables" })}
                >
                  Cancelar
                </Button>

              </div>
              <div className="col-sm-6 order-1 order-sm-2 text-center text-sm-right mb-4">
                <Button color="success" type="submit">
                  Guardar
                </Button>
              </div>
            </div>
          </form>
      </>
    )}
     
    </>
  );
};
export default FormCuentas
import { Modal, ModalHeader, ModalFooter, ModalBody, Button } from "reactstrap"
import { toast } from "react-toastify"
import { useState } from "react"

import Notification from "../../../components/Notification/Notification"
import { peticionesReceiver } from "../../../helpers/peticionesReceiver"
import Datatable from "../../../components/Datatable/Datatable"
import Widget from "../../../components/Widget/Widget"





export default function IncrementoSalarial() {
    const API = peticionesReceiver();
    const [modal, setModal] = useState(false)
    const [revertir, setRevertir] = useState(null)
    const [refresh, setRefresh] = useState(false)

    const headers = ["Nivel inicial", "Nivel final", "Porcentaje sueldo base", "Porcentaje gratificación", "Porcentaje despensa", "Porcentaje compensación", "Porcentaje fortalecimiento", "Aplicado", "Revertir"]

    const columns = [
        {
            data: "startLavel",
            format: "percent"
        },
        {
            data: "endLavel",
            format: "percent"
        },
        {
            data: "porcetageBase",
            format: "percent"
        },
        {
            data: "porcentageReward",
            format: "percent"
        },
        {
            data: "porcentageLarder",
            format: "percent"
        },
        {
            data: "porcentageClearance",
            format: "percent"
        },
        {
            data: "porcentageFortalecimiento",
            format: "percent"
        },
        { data: "year" },
        {
            data: "idSalaryIncrease",
            render: function (row) {
                return (
                    <>
                        <i className="fa fa-undo text-success" style={{ cursor: "pointer" }} onClick={() => { setRevertir(row); setModal(true) }} />
                    </>
                )
            }
        }
    ]

    const columnDefs = [
        {
            targets: [0, 1, 2, 3, 4, 5, 6, 7, 8],
            className: "text-center"
        }
    ]

    const getParams = (order, records, page, search) => {
        const params = {
            action: "datatable",
            table: "salaryincrease",
            rows: "idSalaryIncrease,startLavel,endLavel,porcetageBase,porcentageReward,porcentageLarder,porcentageClearance,porcentageFortalecimiento,year",
            conditions: "enabled=1",
            order,
            records: records.toString(),
            page: (page * records).toString(),
            search
        }
        return params
    }

    async function getData(order, records, page, search) {
        try {
            const res = await API.peticion(getParams(order, records, page, search));
            if (res.status === 200 && res.data.code === "200") {
                return res.data;
            } else {
                toast(<Notification type={"consultar_error"} backMessage={res.data.message} />);
                return null;
            }
        } catch (err) {
            toast(<Notification type={"consultar_servidor_error"} withIcon />);
            return null
        }
    }

    async function deleteIncremento() {
        const params = {
            action: "deleteSalariesIncrement",
            rows: { idSalaryIncrease: revertir }
        }
        try {
            const res = await API.peticionEndPoint(params, "app/facades/jobs/jobsF.php")
            if (res.status === 200 && res.data.status === "success") {
                toast(<Notification type={"elimina_exito"} backMessage={res.data.message} withIcon />);
                setRefresh(true)
                setModal(false)
            } else {
                toast(<Notification type={"elimina_error"} backMessage={res.data.message} withIcon />);
                setModal(false)
            }
        } catch (err) {
            toast(<Notification type={"elimina_error"} withIcon />);
            setModal(false)
        }
    }

    return (
        <>
            <Widget className="widget-p-md">
                <h1 className="text-secondary">Incremento salarial</h1>
                <div className="text-right">
                    <Button onClick={() => window.location.href = "#/template/incrementosalarial/agregar"}>Agregar incremento</Button>
                </div>
                <Datatable
                    petition={getData}
                    headers={headers}
                    columns={columns}
                    columnDefs={columnDefs}
                    control="back"
                    stateRefresh={[refresh, setRefresh]}
                />
                <Modal
                    isOpen={modal}
                >
                    <ModalHeader toggle={() => setModal(false)} className="headline-1" />
                    <ModalBody className="text-center">Revertir operación</ModalBody>
                    <ModalFooter>
                        <div className="w-100 d-flex justify-content-around">
                            <Button
                                className="btn-outline-secondary mr-3"
                                outline
                                onClick={() => setModal(false)}
                            >
                                Cancelar
                            </Button>
                            <Button color="danger" onClick={() => deleteIncremento()}>
                                Eliminar
                            </Button>
                        </div>
                    </ModalFooter>
                </Modal>
            </Widget>
        </>
    )
}
import FormUser from "../FormUser"
import Widget from "../../../../components/Widget/Widget";

import { agregar } from "../functions";

const EditarUsuario = (props) => {
  return (
    <div>
    <Widget className="widget-p-md">
      <h3>Editar usuario</h3>
      <FormUser data={localStorage.getItem('dataContent').split('|-|')} editar={agregar}/>
    </Widget>
    </div>
  )
}

export default EditarUsuario
import React, { useState, useEffect, useRef } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Col, Row, Button, Collapse } from "reactstrap";
import {
  TextInput,
  CheckBoxInput,
  SelectTypeHeadSingle
} from "../../../components/GenericInputsFormik/index";
import { peticionEncript } from "../../../helpers/peticionesEncripted";
import Loader from "../../../components/Loader/Loader";
import Notification from "../../../components/Notification/Notification";
import { toast } from "react-toastify";
import ModalConfirmation from "../../../components/Modal/ModalConfirmation";
import TableComponentOnePage from "../../../components/TableComponent/TableComponentOnePage";
import axios from "axios";
import ModalTableVer from "../../../components/Modal/ModalTableVer";

const defaultForm = {
  idStaffParent: "",
  idStaff: "",
  keyRelationshipType: "",
  keyActivityType: "",
  nameP: "",
  pNameP: "",
  pNameM: "",
  bornDate: "",
  economicalDependency: false,
  isStaff: false,
  idStaffRelated: "",
  otherRelationship: ""
};
const fecha = new Date()

const FormSchema = Yup.object().shape({
  keyRelationshipType: Yup.string().required(
    "Seleccione un tipo de parentesco"
  ),
  keyActivityType: Yup.number().required(
    "Seleccione una actividad de ingresos"
  ),
  nameP: Yup.string()
    .required("Ingrese un nombre")
    .min(3, "Nombre debe contener al menos 3 letras")
    .max(100, "Nombre demasiado largo"),
  pNameP: Yup.string()
    .required("Ingrese un apellido paterno")
    .min(3, "El apellido debe tener alemenos 3 letras")
    .max(100, "Apellido demasiado largo"),
  pNameM: Yup.string()
    .required("Ingrese un apellido materno")
    .min(3, "El apellido debe tener alemenos 3 letras")
    .max(100, "Apellido demasiado largo"),
  bornDate: Yup.string().required("Seleccione una fecha de nacimiento"),
});

const PersonalFamiliares = ({ idStaff, permissions }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [modal, setModal] = useState(false);
  const [estado, setEstado] = useState(false);
  const [loading, setLoading] = useState(false);
  const [modalTabla, setModalTabla] = useState(false);
  const [actividades, setActividades] = useState([]);
  const [relaciones, setRelaciones] = useState([]);
  const [isChecked, setIsChecked] = useState(false);
  const [disableData, setDisableData] = useState(false);
  const [today, setToday] = useState(`${fecha.getFullYear()}-${fecha.getMonth()+1 < 10 ? "0": ""}${fecha.getMonth()+1}-${fecha.getDay() < 10 ? "0": ""}${fecha.getDay()}`);
  const [disableAct, setDisableAct] = useState(false); //Lo agregue debido a que los valores pueden ser deshabilitados o no existir POSIBLE BUG
  const refRelacion = useRef(null);
  
  // * TABLA * //
  const cabeceras = [
    "Id",
    "Nombre",
    "Parentesco",
    "F. Nacimiento",
    "Empleado en la institución",
    "Editar",
    "Eliminar",
  ];
  const filtro = [true, true, true, true, true];
  const [parametros, setParametros] = useState({
    action: "datatable",
    table:
      "staffparents A INNER JOIN relationshiptypes B ON A.keyRelationshipType = B.keyRelationshipType LEFT JOIN staff C ON A.idStaffRelated = C.idStaff",
    rows: "A.idStaffParent, CONCAT(nameP,' ',pNameP,' ',pNameM) as fullName, B.nameRelationshipType,A.bornDate,IF(A.isStaff = 1, 'SI','NO') as isStaff,A.keyRelationshipType,A.keyActivityType,A.nameP,A.pNameP,A.pNameM,A.economicalDependency,A.idStaffRelated,A.otherRelationship",
    conditions: `A.enabled = 1 AND A.idStaff = ${idStaff}`,
    page: 0,
    records: 5,
    search: "",
    order: "A.created desc",
  });
  // * TABLA MODAL * //
  const cabecerasModal = [
    "Id",
    "Nombre",
    "Tipo",
    "Núm. Empleado",
    "Seleccionar",
  ];
  const filtroModal = [true, true, true, true];
  const [parametrosModal] = useState({
    action: "datatable",
    table:
      "staff A INNER JOIN employtypes B ON A.keyEmployType = B.keyEmployType",
    rows: "A.idStaff,CONCAT(A.name,' ',A.pName,' ',A.mName) as fullName,B.nameEmployType,A.staffNumber,A.name,A.pName,A.mName,A.bornDate",
    conditions: `A.enabled = 1 AND NOT idStaff = ${idStaff}`,
    page: 0,
    records: 5,
    search: "",
    order: "",
  });

  const getActividades = async () => {
    const params = {
      action: "select",
      table: "activitytypes",
      order: "nameActivityType ASC",
    };
    let data = await getBackInfo(params);
    setActividades(data);
  };

  const getRelaciones = async () => {
    const params = {
      action: "select",
      table: "relationshiptypes",
      order: "nameRelationshipType ASC",
    };
    let data = await getBackInfo(params);
    setRelaciones(data);
    setLoading(false);
  };

  useEffect(() => {
    setLoading(true);
    getActividades().finally(() => {
      getRelaciones();
    });
  }, []);

  useEffect(() => {
    if (!modalTabla && values) {
      if (values.idStaffRelated === "") {
        setFieldValue("isStaff", false);
      }
    }
  }, [modalTabla]);

  useEffect(() => {
    if(!isChecked){
      setFieldValue("idStaffRelated", "");
      setFieldValue("nameP", "");
      setFieldValue("pNameP", "");
      setFieldValue("pNameM", "");
      setFieldValue("bornDate", "");
      setDisableData(false);
      setDisableAct(false);
    }
  }, [isChecked]);

  const {
    handleSubmit,
    values,
    handleBlur,
    errors,
    touched,
    resetForm,
    setFieldValue,
    handleReset,
  } = useFormik({
    initialValues: defaultForm,
    validationSchema: FormSchema,
    onSubmit: () => {
      setModal(true);
    },
    onReset: () => {
      setEstado(false);
      setIsOpen(false);
      setDisableData(false);
      setDisableAct(false);
      setIsChecked(false);
    },
    validationSchema: FormSchema,
    enableReinitialize: true,
  });

  const editInfo = async () => {
    let rows = values;
    rows.idStaff = idStaff;
    const params = {
      action: rows.idStaffParent === "" ? "insert" : "update",
      table: "staffparents",
      rows: rows,
      validate: [
        [
          "idStaff",
          "keyRelationshipType",
          "keyActivityType",
          "nameP",
          "pNameP",
          "pNameM",
          "bornDate",
          "economicalDependency",
          "otherRelationship"
        ],
      ],
    };
    if (rows.idStaffParent !== "")
      params.condition = { idStaffParent: rows.idStaffParent };
    getBackInfo(params, true);
  };

  const getForEdit = (el) => {
    if (!isOpen) {
      setFieldValue("idStaffParent", el[0]);
      setFieldValue("idStaff", idStaff);
      setFieldValue("keyRelationshipType", +el[5]);
      setFieldValue("keyActivityType", +el[6]);
      setFieldValue("nameP", el[7]);
      setFieldValue("pNameP", el[8]);
      setFieldValue("pNameM", el[9]);
      setFieldValue("bornDate", el[3]);
      setFieldValue("economicalDependency", el[10]);
      setFieldValue("isStaff", el[4] === "SI" ? true : false);
      setFieldValue("idStaffRelated", el[11]);
      setFieldValue("otherRelationship", el[12]);//aaaaaaaaaaaa
      if( el[4] === "SI"){
        setDisableData(true);
        setDisableAct(true);
        setIsChecked(true);
      }
      setIsOpen(true);
      setEstado(true);
    } else {
      toast(
        <Notification
          type="warning"
          backMessage="Guarde sus cambios antes de continuar"
        />,
        { closeButton: false }
      );
    }
  };

  async function getBackInfo(params, isRegistro) {
    let info = [];
    const finalData = peticionEncript(params)
    await axios
      .post(process.env.REACT_APP_API + "receiver/receiver.php", finalData)
      .then((res) => {
        if (res.status === 200 && res.data.status === "success") {
          info = res.data.data;
          if (isRegistro) {
            setParametros({ ...parametros });
            resetForm();
            toast(
              <Notification
                type={"modifica_exito"}
                backMessage={res.data.message}
                withIcon
              />,
              { closeButton: false }
            );
          }
        } else {
          toast(<Notification type={"consultar_error"} backMessage={res.data.message} withIcon />);
        }
      })
      .catch((err) => {
        toast(<Notification type={"consultar_servidor_error"} withIcon />);
      });
    return info;
  }

  const onChange = (e) => {
    if (
      e.target.name === "isStaff" ||
      e.target.name === "economicalDependency"
    ) {
      setFieldValue([e.target.name], !values[e.target.name]);
    } else {
      setFieldValue([e.target.name], e.target.value);
    }
    if (e.target.name === "isStaff") {
      if(!values[e.target.name]){
        setModalTabla(true);
      }else{
        setIsChecked(false);
      }
    }
  };

  const OnChangeSelect = event => {
    const  { name, value } = event.target;
    if(name === "keyRelationshipType"){
      setFieldValue("otherRelationship", "");
    }
    setFieldValue([name], value);
  };

  const selectStaff = (el) => {
    setFieldValue("idStaffRelated", el[0]);
    setFieldValue("nameP", el[4]);
    setFieldValue("pNameP", el[5]);
    setFieldValue("pNameM", el[6]);
    setFieldValue("bornDate", el[7]);
    if (actividades!==null && actividades.length > 0) { //SI LAS ACTIVIDADES EXISTEN Y SON MAYORES A CERO
      const actividad = actividades.find(act => act.nameActivityType === "TRABAJADOR" && act.enabled === 1);
      if (actividad != null) { //SI LA ACTIVIDAD EXISTE
        setFieldValue("keyActivityType", actividad.keyActivityType);
        setDisableAct(true);
      }
    }
    setIsChecked(true);
    setDisableData(true);
    setModalTabla(false);
  };

  return (
    <div className="mt-4">
      <TableComponentOnePage
        titulo={"Familiares"}
        cabecerasTabla={cabeceras}
        filtro={filtro}
        editar={getForEdit}
        parametros={parametros}
        limpia={resetForm}
        permissions={permissions}
      />
      <div className="row mt-2">
        <div className="col-12 col-sm-6">
          {/* <h5 className="text-center text-sm-left">{title}</h5> */}
        </div>
        {permissions.INS && <div className="col-12 col-sm-6">
          <div className="text-center text-sm-right">
            <Button
              color="primary"
              onClick={() => setIsOpen(true)}
              disabled={isOpen}
            >
              Agregar
            </Button>
          </div>
        </div>}
      </div>
      <Collapse isOpen={isOpen} className="mt-4">
        <Col className="d-grid d-flex justify-content-sm-end justify-content-center mt-3">
          <h6 className="text-center">
            Estado:{" "}
            <span className="text-primary">
              {estado ? "Editando" : "Guardando"}
            </span>
          </h6>
        </Col>
        {loading ? (
          <Loader />
        ) : (
          <form onSubmit={handleSubmit} onReset={handleReset} className="mt-4">
            <ModalConfirmation
              modalTitle="Guardar registro"
              modal={modal}
              setModal={setModal}
              editar={editInfo}
              isEdit={true}
              values={values}
            >
              <div className="d-flex justify-content-center">
                <h6>¿Está seguro de guardar el registro?</h6>
              </div>
            </ModalConfirmation>

            <Row className="mt-2">
              <Col xs="12" lg="6">
                <SelectTypeHeadSingle
                  label="Parentesco"
                  isRequired={true}
                  inputName="keyRelationshipType"
                  optionsArray={relaciones}
                  defaultOption="Parentesco"
                  onChangeMethod={OnChangeSelect}
                  onBlur={handleBlur}
                  isDisabled={false}
                  value={values.keyRelationshipType}
                  touched={touched.keyRelationshipType}
                  errors={errors.keyRelationshipType}
                  optionValue="keyRelationshipType"
                  optionName="nameRelationshipType"
                  refSelect={refRelacion}
                />
              </Col>
              {
                refRelacion && refRelacion.current?.state.text.includes("Otro") &&
                <Col xs="12" lg="6">
                  <TextInput
                  label="Otra relación"
                  inputType="text"
                  inputName="otherRelationship"
                  onChangeMethod={onChange}
                  onBlurMethod={handleBlur}
                  value={values.otherRelationship}
                  touched={touched.otherRelationship}
                  errors={errors.otherRelationship}
                />
                </Col>
              }
              <Col xs="12" lg="4">
                <CheckBoxInput
                  label="¿Es empleado de la institución?"
                  inputName="isStaff"
                  onChangeMethod={onChange}
                  onBlurMethod={handleBlur}
                  value={values.isStaff}
                  touched={touched.isStaff}
                  errors={errors.isStaff}
                />
              </Col>
            </Row>
            <Row className="mt-2">
              <Col xs="12" lg="4">
                <TextInput
                  label="Nombre(s)"
                  inputType="text"
                  inputName="nameP"
                  onChangeMethod={onChange}
                  onBlurMethod={handleBlur}
                  value={values.nameP}
                  touched={touched.nameP}
                  errors={errors.nameP}
                  isRequired
                  isDisabled={disableData}
                />
              </Col>
              <Col xs="12" lg="4">
                <TextInput
                  label="Apellido paterno"
                  inputType="text"
                  inputName="pNameP"
                  onChangeMethod={onChange}
                  onBlurMethod={handleBlur}
                  value={values.pNameP}
                  touched={touched.pNameP}
                  errors={errors.pNameP}
                  isRequired
                  isDisabled={disableData}
                />
              </Col>
              <Col xs="12" lg="4">
                <TextInput
                  label="Apellido materno"
                  inputType="text"
                  inputName="pNameM"
                  onChangeMethod={onChange}
                  onBlurMethod={handleBlur}
                  value={values.pNameM}
                  touched={touched.pNameM}
                  errors={errors.pNameM}
                  isRequired
                  isDisabled={disableData}
                />
              </Col>
            </Row>
            <Row className="mt-2">
              <Col xs="12" lg="4">
                <TextInput
                  label="Fecha de nacimiento"
                  inputType="date"
                  inputName="bornDate"
                  onChangeMethod={onChange}
                  onBlurMethod={handleBlur}
                  value={values.bornDate}
                  touched={touched.bornDate}
                  errors={errors.bornDate}
                  isRequired
                  isDisabled={disableData}
                  
                  isMax={today}
                />
              </Col>
              <Col xs="12" lg="4">
                <SelectTypeHeadSingle
                  label="Actividad de ingresos"
                  isRequired={true}
                  inputName="keyActivityType"
                  optionsArray={actividades}
                  defaultOption="Seleccionar una actividad de ingresos"
                  onChangeMethod={OnChangeSelect}
                  onBlur={handleBlur}
                  isDisabled={disableAct}
                  value={values.keyActivityType}
                  touched={touched.keyActivityType}
                  errors={errors.keyActivityType}
                  optionValue="keyActivityType"
                  optionName="nameActivityType"
                />
              </Col>
              <Col xs="12" lg="4">
                <CheckBoxInput
                  label="¿Depende economicamente?"
                  inputName="economicalDependency"
                  onChangeMethod={onChange}
                  onBlurMethod={handleBlur}
                  value={values.economicalDependency}
                  touched={touched.economicalDependency}
                  errors={errors.economicalDependency}
                />
              </Col>
            </Row>
            <Row className="mt-5">
              <div className="col-sm-6 order-2 order-sm-1 text-center text-sm-left mb-4">
                <Button color="danger" type="reset" onClick={() => resetForm()}>
                  Cancelar
                </Button>
              </div>
              <div className="col-sm-6 order-1 order-sm-2 text-center text-sm-right mb-4">
                <Button color="success" type="submit">
                  Guardar
                </Button>
              </div>
            </Row>
          </form>
        )}
      </Collapse>

      <ModalTableVer
        modal={modalTabla}
        setModal={setModalTabla}
        title="Empleados"
        cabeceras={cabecerasModal}
        filtro={filtroModal}
        parametros={parametrosModal}
        selectRegistro={selectStaff}
        btnClose
      />
    </div>
  );
};

export default PersonalFamiliares;

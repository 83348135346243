import React from 'react'
import Widget from '../../../../components/Widget/Widget';
import FormCuentas from '../FormCuentas'
import { insertInfo } from "../functions";

const CrearCuenta = () => {
  return (
    <div>
      <Widget className="widget-p-md">
        <h3>Crear Cuenta Contable</h3>
        <FormCuentas insertInfo={insertInfo} />
      </Widget>
    </div>
  );
}

export default CrearCuenta
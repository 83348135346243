import axios from "axios";
import { peticionEncript } from "../../helpers/peticionesEncripted";
import { allRoutes } from "./Layout";

function getRepuesta(menu, pathname) {
    if (menu.children) {
        const res = menu.children.some(hijo => {
            return getRepuesta(hijo, pathname);
        })
        return res
    } else {
        return menu.fileRoute.toLowerCase() === `/${pathname}`
    }
}

function filtrado(childrens, paths) {
    const filtered = [];
    childrens.forEach(children => {
        if (children.children && children.children.length > 0) {
            const filt = filtrado(children.children, paths)
            children.children = filt && filt.length > 0 ? filt : undefined;
            filtered.push(children)
        } else {
            if (paths.includes(`/TEMPLATE${children.fileRoute.toUpperCase()}`)) {
                filtered.push(children)
            }
        }
    })
    return filtered;
}

const getRouters = async (permission, pathname) => {
    const paths = allRoutes.map(route => route.path.toUpperCase());
    const params = {
        action: "getMenu2",
        idPermission: permission,
    };
    const finalData = peticionEncript(params)
    const result = await axios.post(`${process.env.REACT_APP_API}menu/menu.php`, finalData)

    if (result.status === 200 && result.data.status === 'success' && paths) {
        const data = filtrado(result.data.data, paths);
        const response = data.some(el => {
            if (el.children) {
                return getRepuesta(el, pathname);
            }
            return el.fileRoute.toLowerCase() === `/${pathname}`
        });
        return { permission: response }

    }
    else {
        return { permission: false }
    }
}
export default getRouters;
import React, {useState} from "react";
import TableComponent from "../../../components/TableComponent/TableComponentWithExport";
import { peticionEncript } from "../../../helpers/peticionesEncripted";
import axios from "axios";
import { toast } from "react-toastify";
import Notification from "../../../components/Notification/Notification";
import { exportAsExcel, title, config } from "./export/exportData";
import ModalReports from "../../../components/Modal/ModalReports";
import generatePDFProviders from "./pdf/pdfProveedores";

export default function ProveedoresNomina() {
    const [modalReports, setModalReports] = useState(false);
    const [report, setReport] = useState();

    const cabeceras = [
        "Id", //0
        "Razón social", //1
        "RFC",
        "Banco",
        "Cuenta",
        "CLABE",
        "Estatus",
        "Editar"
    ];
    const [parametros] = useState({
        action: "datatable",
        table: "providerspayroll A LEFT JOIN banks B USING (keyBank) LEFT JOIN status C USING (keyStat)",
        rows: "A.idProviderPayRoll,A.businessName,A.rfc,B.nameBank,A.account,A.switfCode,C.namestat", ///sin espacios y comas
        conditions: "A.enabled = 1 AND B.enabled = 1 AND C.enabled = 1",
        page: 0,
        records: 5,
        search: "",
        order: "",
    });
    
    const exportPDF = () => {
        getData("pdf");
        setModalReports(true);
    }

    const exportExcel = () => {
        getData("excel");
    }

    const exportOptions = [
        { label: "Exportar PDF", event: exportPDF },
        { label: "Exportar Excel", event: exportExcel }
    ]; 

    const getData = async (method) => {
        const finalData = peticionEncript(parametros);
        await axios
        .post(`${process.env.REACT_APP_API}receiver/receiver.php`, finalData)
        .then((res) => {
            if (res.status === 200 && res.data.code === "200") {
                const report = res.data.data
                if (method === "excel") {
                    const finalExport = report.map(row => {
                        const reporte = row;
                        if (reporte.account === "") {
                            reporte.account = "Sin Información";
                        }
                        if (reporte.switfCode === "") {
                            reporte.switfCode = "Sin Información";
                        }
                        return reporte
                    })
                    exportAsExcel(finalExport);
                } else if (method === "pdf") {
                    setReport(report);
                }
            }
        })
        .catch((err) => {
            toast(<Notification type={"consultar_servidor_error"} withIcon />);
        });
    }

    return (
        <>
            <TableComponent
                titulo="Proveedores de nomina"
                cabecerasTabla={cabeceras}
                filtro={null}
                rutaCrear="/template/proveedoresnomina/crear"
                rutaEditar="/template/proveedoresnomina/editar"
                parametros={parametros}
                exportOptions={exportOptions}
                disabledDelete
            ></TableComponent>
            {report !== undefined ? (
                <ModalReports
                    modal={modalReports}
                    setModal={setModalReports}
                    title={title}
                    cabeceras={cabeceras}
                    backdrop={"static"}
                    keyboard={false}
                    report={report}
                    pdfConfig={config}
                    generatePdfMethod={{
                        method: generatePDFProviders,
                        type: "PDFProveedores",
                    }}
                    records={1000}
                />
            ): null}
            
        </>
    )
}
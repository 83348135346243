import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import { Button, Row, Col } from "reactstrap";
import { peticionesReceiver } from "../../../../helpers/peticionesReceiver";
import * as Yup from "yup";
import ModalConfirmation from "../../../../components/Modal/ModalConfirmation";
import Datatable from "../../../../components/Datatable/Datatable";
import { toast } from "react-toastify";
import Notification from "../../../../components/Notification/Notification";
import ModalDelete from "../../../../components/Modal/ModalDelete";
import {
  SelectTypeHeadSingle,
  TextInput,
} from "../../../../components/GenericInputsFormik";
import { NumberInput } from "../../../../components/GenericInputsFormik/NumberInput";

const FormDetails = ({
  transfer,
  permissions,
  setTrasnferRequest,
  setRefreshPrinc,
  idAreaLogged,
}) => {
  const [modalConfirmation, setModalConfirmation] = useState(false);
  const [areas, setAreas] = useState(false);
  const API = peticionesReceiver();
  const [refresh, setRefresh] = useState(false);
  const [furnituresAddeds, setFurnituresAddeds] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [cantTotal, setCanTotal] = useState("");

  useEffect(() => {
    
    getInventory();
  }, []);

  const headers = [
    "#",
    "Bien",
    "Unidad de Medida",
    "Cantidad a traspasar",
    "Eliminar",
  ];

  const columns = [
    { data: "index" },
    { data: "bien" },
    { data: "nameUnit" },
    { data: "quantity" },
    {
      data: null,
      render: function (row) {
        return permissions.DEL && transfer.keyStat !== "131" ? (
          <>
            <ModalDelete
              table={["warehousetransfersdetail"]}
              nameColumn={["idDetail"]}
              elimina={() => deleteFurniture(row)}
              setRefresh={setRefresh}
              type="icon"
            >
              <h6>¿Seguro de eliminar el registro?</h6>
              No podrá recuperar la información después de ser eliminada
            </ModalDelete>
          </>
        ) : (
          "No Permitido"
        );
      },
    },
  ];

  const deleteFurniture = async (obj) => {
    const params = {
      action: "delete",
      table: "warehousetransfersdetail",
      condition: { idDetail: obj.idDetail },
      force: 1,
    };

    await API.peticion(params)
      .then((res) => {
        if (res.data.status === "success" && res.data.code === "200") {
          toast(
            <Notification
              type={"elimina_exito"}
              backMessage={res.data.message}
              withIcon
            />
          );
          resetForm();
          setErrorMessage("");
          setRefresh(true);
        } else {
          toast(
            <Notification
              type={"elimina_error"}
              backMessage={res.data.message}
              withIcon
            />,
            { closeButton: false }
          );
        }
      })
      .catch((err) => {
        toast(<Notification type={"consultar_servidor_error"} withIcon />);
      });
  };

  const columnDefs = [
    {
      targets: [0, 1, 2, 3, 4, 5],
      className: "text-center",
    },
    {
      orderable: false,
      targets: [0],
    },
  ];

  const getParamsTableElmt = (order, records, page, search) => {
    const params = {
      action: "datatable",
      table:
        "warehousetransfersdetail A LEFT JOIN warehouseinventory X on (A.idInventory = X.idInventory) LEFT JOIN furnitures B on (X.idFurniture = B.idFurniture) LEFT JOIN messureunits C ON (B.keyUnit = C.keyUnit)",
      rows: "A.idDetail,concat(B.nameFurniture,' ',B.model,' ',B.brand) as bien,A.quantity,C.nameUnit,A.idInventory",
      conditions: `A.enabled = 1 and A.idTransfer = ${transfer.idTransfer}`,
      order,
      records: records.toString(),
      page: (page * records).toString(),
      search,
    };
    return params;
  };

  async function getData(order, records, page, search) {
    try {
      const res = await API.peticion(
        getParamsTableElmt(order, records, page, search)
      );
      if (res.status === 200 && res.data.code === "200") {
        const data = res.data;

        /* data.forEach((value, index) => (value.index = 1 + index)); */
        data.data.forEach((value, index) => (value.index = 1 + index));
        setFurnituresAddeds(data.data);
        return data;
      } else {
        toast(
          <Notification
            type={"consultar_error"}
            backMessage={res.data.message}
          />
        );
        return null;
      }
    } catch (err) {
      toast(<Notification type={"consultar_servidor_error"} withIcon />);
      return null;
    }
  }

  async function getInventory() {
    const params = {
      action: "multiselect",
      table:
        "warehouseinventory A LEFT JOIN furnitures B on (A.idFurniture = B.idFurniture) LEFT JOIN messureunits C USING (keyUnit)",
      rows: "A.idInventory,concat(B.nameFurniture,' ',B.model,' ',B.brand) as bien, A.totalCount- A.reserved as cantidad,C.nameUnit",
      conditions: `A.enabled = 1 and B.enabled =1 and  A.totalCount- A.reserved > 0 and A.idArea = ${idAreaLogged}`,
    };
    try {
      const res = await API.peticion(params);
      if (res.status === 200 && res.data.code === "200") {
        setAreas(res.data.data);
      } else {
        toast(
          <Notification
            type={"consultar_error"}
            backMessage={"Sin registros"}
            withIcon
          />
        );
      }
    } catch (err) {
      toast(<Notification type={"consultar_servidor_error"} withIcon />);
    }
  }

  const FormSchema = Yup.object().shape({
    idInventory: Yup.number().required("Seleccione un articulo"),
    featuresToPass: Yup.number()
      .required("Seleccione una cantidad a traspasar")
      .min(1, "Seleccione una cantidad mayor a 0"),
  });

  const {
    handleSubmit,
    values,
    errors,
    setFieldValue,
    touched,
    handleBlur,
    resetForm,
  } = useFormik({
    initialValues: {
      idInventory: "",
      featuresToPass: "",
      unit: "",
    },
    onSubmit: () => {
      addFurniture();
    },
    validationSchema: FormSchema,
    enableReinitialize: true,
  });

  const onChange = (e) => {
    const { name, value } = e.target;
    if (name === "idInventory") {
      validTotalFurniture(value);
      setFieldValue([name], value.idInventory);
      setFieldValue("unit", value.nameUnit);
      setFieldValue("acount", value.cantidad);
      setFieldValue("featuresToPass", "");
    } else {
      validTotalPlusQuality(value);
      setFieldValue([name], value);
    }
  };

  const validTotalPlusQuality = (value) => {
    if (+value + +cantTotal > +values.acount) {
      setErrorMessage(
        "La cantidad ingresada y la agregada supera la de la base"
      );
    } else {
      setErrorMessage("");
    }
  };

  const validTotalFurniture = (value) => {
    let cantAdded = 0;
    furnituresAddeds.forEach((furniture) => {
      cantAdded +=
        +furniture.idInventory === +value.idInventory ? +furniture.quantity : 0;
    });
    if (+cantAdded >= +value.cantidad) {
      setErrorMessage("Ya has alcanzado el limite de este articulo");
    } else {
      setErrorMessage("");
    }
    setCanTotal(cantAdded);
  };

  const addFurniture = async () => {
    if (errorMessage === "") {
      const data = {
        action: "insert",
        table: "warehousetransfersdetail",
        rows: {
          idTransfer: transfer.idTransfer,
          idInventory: values.idInventory,
          quantity: values.featuresToPass,
        },
      };
      const res = await API.peticion(data);
      if (res.status === 200 && res.data.status === "success") {
        toast(
          <Notification
            type={"agrega_exito"}
            backMessage={res.data.message}
            withIcon
          />
        );
        resetForm();
        setRefresh(true);
      } else {
        toast(
          <Notification
            type={"agrega_error"}
            backMessage={res.data.message}
            withIcon
          />
        );
      }
    }
  };

  const sendRequest = async () => {
    const params = {
      action: "sendTransfer",
      idTransfer: transfer.idTransfer,
      idAreaOrigin: transfer.idAreaOrigin,
      idAreaDestiny: transfer.idAreaDestiny,
      description: transfer.description,
      keyStat: 130,
    };

    try {
      const res = await API.peticionEndPoint(
        params,
        "app/facades/warehouse/warehouseF.php"
      );
      if (res.status === 200 && res.data.code === "200") {
        toast(
          <Notification
            type={"agrega_exito"}
            backMessage={res.data.message}
            withIcon
          />
        );
        setTrasnferRequest(undefined)
        setRefreshPrinc(true)
      } else {
        toast(
          <Notification
            type={"consultar_error"}
            backMessage={res.data.message}
            withIcon
          />
        );
      }
    } catch (err) {
      toast(<Notification type={"consultar_servidor_error"} withIcon />);
    }
  };
  return (
    <div className="mt-3">
      <form onSubmit={handleSubmit}>
        <div className="text-center mb-5">
          <h1>Detalle de solicitud</h1>
        </div>

        {transfer.keyStat !== "131" && (
          <>
            <Row className="d-flex justify-content-around">
              <Col xs={12} md={5}>
                <SelectTypeHeadSingle
                  label="Articulos disponibles de almacen"
                  inputName="idInventory"
                  onChangeMethod={onChange}
                  onBlurMethod={handleBlur}
                  value={values.idInventory}
                  isRequired
                  touched={touched.idInventory}
                  errors={errors.idInventory}
                  optionsArray={areas}
                  optionValue={"idInventory"}
                  optionName={"bien"}
                  retObject={true}
                />
              </Col>

              <Col xs={12} md={5}>
                <TextInput
                  label="Unidad de medida"
                  inputType="text"
                  inputName="unit"
                  onChangeMethod={onChange}
                  onBlurMethod={handleBlur}
                  value={values.unit}
                  touched={touched.unit}
                  errors={errors.unit}
                  isRequired
                  isDisabled={true}
                />
              </Col>
            </Row>

            <Row className="d-flex  justify-content-around mb-5">
              <Col xs={12} md={5}>
                <NumberInput
                  label="Cantidad disponible"
                  inputName="acount"
                  onChangeMethod={onChange}
                  onBlurMethod={handleBlur}
                  value={values.acount}
                  touched={touched.acount}
                  errors={errors.acount}
                  isDisabled={true}
                />
              </Col>
              <Col xs={12} md={5}>
                <NumberInput
                  label="Cantidad a pasar"
                  inputName="featuresToPass"
                  onChangeMethod={onChange}
                  onBlurMethod={handleBlur}
                  value={values.featuresToPass}
                  isRequired
                  touched={touched.featuresToPass}
                  errors={errors.featuresToPass}
                  isDisabled={
                    errorMessage ===
                    "Ya has alcanzado el limite de este articulo"
                      ? true
                      : false
                  }
                />
              </Col>

              {/* {errorMessage !== "" && (errorMessage)} */}
            </Row>

            <Row className="d-flex  justify-content-center mb-5 text-center">
              <Col>
                <span style={{ color: "red" }}>{errorMessage}</span>
              </Col>
            </Row>

            {permissions.INS && (
              <Row className="mb-5">
                <Col className="text-center">
                  <Button
                    type="submit"
                    color="success"
                    disabled={
                      errorMessage !==
                      ""
                        ? true
                        : false
                    }
                  >
                    Agregar
                  </Button>
                </Col>
              </Row>
            )}
          </>
        )}

        <ModalConfirmation
          modalTitle={"Crear"}
          modal={modalConfirmation}
          setModal={setModalConfirmation}
          crear={sendRequest}
          isEdit={false}
          values={values}
        >
          <div className="text-center">
            Esta seguro de continuar con el registro
          </div>
        </ModalConfirmation>
      </form>

      <Datatable
        headers={headers}
        columns={columns}
        columnDefs={columnDefs}
        petition={getData}
        control="back"
        stateRefresh={[refresh, setRefresh]}
        searching={false}
        order={{ col: 1, opt: "desc" }}
        paging={false}
      />

      {transfer.keyStat !== "131" ? (
        <>
          {permissions.INS && (
            <div className="text-center mt-5">
              <Button
                type="submit"
                color="success"
                onClick={() => setModalConfirmation(true)}
              >
                Enviar
              </Button>
            </div>
          )}
        </>
      ) : (
        <Row className="d-flex  justify-content-center mb-5 text-center mt-5">
          <Col>
            <h3 style={{ color: "red" }}>La solicitud ya ha sido enviada</h3>
          </Col>
        </Row>
      )}
    </div>
  );
};

export default FormDetails;

import { Button, Col, Collapse, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import ModalDelete from "../../../components/Modal/ModalDelete";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useState } from "react";
import { toast } from "react-toastify";
import Notification from "../../../components/Notification/Notification";
import { doRequest, doRequestSaveRes } from "../../../helpers/requests";
import ModalConfirmation from "../../../components/Modal/ModalConfirmation";
import Datatable from "../../../components/Datatable/Datatable";
import ButtonInputM from "../../../components/GenericInputsFormik/ButtonInputModal";
import { NumberInput } from "../../../components/GenericInputsFormik/NumberInput";
import FullFormLoader from "../../../components/Loader/FullFormLoader";
import FormFurniture from "../../patrimonio/bienes-muebles/Form.jsx";
import { validValue } from "../../../libs/tools/format";

const headers = ["Bien", "Cantiddad", "Editar", "Eliminar"];
const columnDefs = [
    {
        targets: [1],
        className: "text-right",
    },
    {
        targets: [2, 3],
        className: "text-center",
    },
    {
        orderable: false,
        targets: [2, 3]
    }
];

const modalTablaParams = {
    title: "Artículos",
    headers: ["Artículo", "Modelo", "Marca"],
    columns: [
        {data: "nameFurniture"},
        {data: "model"},
        {data: "brand"},
    ],
    params: {
        table: "furnitures",
        rows: "nameFurniture, model, brand, idFurniture",
        conditions: `enabled = 1`,
    },
}

const defaultForm = {
    idDetail: "",
    idFurniture: "",
    nameFurniture: "",
    quantity: ""
}

const FormSchema = Yup.object().shape({
    nameFurniture: Yup.string().required("Seleccione un artículo"),
    quantity: Yup.number().required("Ingrese una cantidad").min(1, "Cantidad debe ser maoyir a 0"),
});

export default function Detalle({info, setInfo, permissions}) {
    const [refresh, setRefresh] = useState(false);
    const [loading, setLoading] = useState(false);
    const [modal, setModal] = useState(false);
    const [modalBien, setModalBien] = useState(false);
    const [showButton, setShowButton] = useState(permissions.UPD && +info.stat === 134);
    const [showForm, setShowForm] = useState(permissions.INS && +info.stat === 134);
    
    const columns = [
        {data: "nameFurniture"},
        {data: "quantity"},
        {
            data: null, 
            render: (row)=> permissions.UPD && showButton && <i className="fa fa-pencil text-warning cursor-pointer" onClick={()=>openToEdit(row)}/>
        },
        {
            data: null, 
            render: (row) => (
                permissions.DEL && showButton &&
                <ModalDelete
                    modalTitle="Eliminar"
                    table=""
                    nameColumn={[""]}
                    elimina={fnDelete}
                    id={row.idDetail}
                >
                    <h6>
                        Está seguro de eliminar {row.nameFurniture} de la devolución?
                    </h6>
                    No podrá revertir esta acción
                </ModalDelete>
            ),
        }
    ]

    const {
        handleSubmit,
        handleReset,
        handleBlur,
        values,
        errors,
        touched,
        setFieldValue,
        setValues
    } = useFormik({
        initialValues: defaultForm,
        validationSchema: FormSchema,
        enableReinitialize: true,
        onSubmit: () => setModal(true),
        onReset: () => {
            if(!permissions.INS) setShowForm(false);
        },
    });
        /* Table */
        async function getData(ordCol, itemsPerPage, currentPage, search, multi) {
            const res = await doRequestSaveRes(
                "receiver/receiver.php",
                getParams(ordCol, itemsPerPage, currentPage, search, multi),
                false,
                false
            );
            if (res.code === "200") return res;
            return null;
        }

        function getParams(ordCol, itemsPerPage, currentPage, search) {
            const params = {
                action: "datatable",
                table: "warehousereturnsdetail A INNER JOIN furnitures B USING(idFurniture)",
                rows: "A.idDetail, A.idFurniture, A.quantity, B.nameFurniture",
                conditions: `A.idReturn = ${info.id} AND A.enabled = 1`,
                order: ordCol,
                records: itemsPerPage.toString(),
                page: (itemsPerPage * currentPage).toString(),
                search
            };
            return params;
        }
    
        /* Form */
        async function save() {
            setLoading(true);
            const params = {
                action: "insert",
                table: "warehousereturnsdetail",
                rows: {
                    idReturn: info.id, 
                    idFurniture: values.idFurniture,
                    quantity: values.quantity
                },
                validate: [["idReturn", "idFurniture"]],
            };
            if(values.idDetail){
                params.action = "update";
                params.condition = { idDetail: values.idDetail };
            }
            const res = await doRequest("receiver/receiver.php", params, true);
            if (res.length > 0){
                setRefresh(true);
                handleReset();
            }
            setLoading(false);
        }

        function openToEdit(row){
            if(!validValue(values.idDetail) && !validValue(values.idFurniture) && !validValue(values.nameFurniture) && !validValue(values.quantity)){
                setValues({
                    idDetail: row.idDetail, 
                    idFurniture: row.idFurniture, 
                    nameFurniture: row.nameFurniture, 
                    quantity: row.quantity
                }, true);
                if(!showForm) setShowForm(true);
            }else{
                toast(
                    <Notification
                      type={"warning"}
                      backMessage="Guarde los cambios antes de continuar"
                      withIcon
                    />,
                    { closeButton: false }
                  );
            }
        }

        async function fnDelete(id) {
            setLoading(true);
            const params = {
                action: "delete",
                table: "warehousereturnsdetail",
                condition: {
                    idReturn: id,
                },
                force: 1
            };
            const res = await doRequest("receiver/receiver.php", params, true);
            if (res.length > 0) {
                setRefresh(true);
                if(+values.idReturn === +id) handleReset();
            }
            setLoading(false);
        }

        function selectRow(row){
            setFieldValue("idFurniture", row.idFurniture);
            setFieldValue("nameFurniture", row.nameFurniture);
        }

        function clearInput(field){
            setFieldValue("idFurniture", "");
            setFieldValue("nameFurniture", "");
        
        }

        /* Modal bien */
        function addFurniture(){ setModalBien(true) };
        function closeModalBien(){setModalBien(false);}
        function successModalBien(row){
            setFieldValue("idFurniture", row.idFurniture);
            setFieldValue("nameFurniture", row.nameFurniture);
            setModalBien(false);
        }

        async function send(){
            setLoading(true);
            const params = {
                action: "saveReturn",
                idReturn: info.id
            };
            const res = await doRequest("app/facades/warehouse/warehouseF.php", params, true);
            if (res.length > 0){
                setShowButton(false);
                setShowForm(false);
            }
            setLoading(false);
        }

    return (
        <div>
            <div className="mb-3 text-dark text-center text-md-right">
                <Button onClick={() => setInfo({ id: "" })}>Regresar</Button>
            </div>
                
            <Collapse isOpen={((permissions.INS && showForm) || showForm)}>
                <form onSubmit={handleSubmit} onReset={handleReset}>
                    <h5 className="mb-2">{values.idDetail? "Editar " : "Agregar "}artículo</h5>
                    <Row>
                        <Col xs={12} md={6}>
                            <ButtonInputM
                                label="Artículos disponibles"
                                inputName="nameFurniture"
                                value={values.nameFurniture}
                                touched={touched.nameFurniture}
                                errors={errors.nameFurniture}
                                isRequired={true}
                                modalParams={modalTablaParams}
                                selectRow={selectRow}
                                clearFun={clearInput}
                                addRecord={addFurniture}
                            />
                        </Col>
                        <Col xs={12} md={6}>
                            <NumberInput
                                label="Cantidad"
                                inputName="quantity"
                                onChangeMethod={({target})=> setFieldValue("quantity", target.value)}
                                onBlurMethod={handleBlur}
                                value={values.quantity}
                                isRequired
                                touched={touched.quantity}
                                errors={errors.quantity}
                            />
                        </Col>
                    </Row>
                    <div className=" d-flex flex-md-row flex-column-reverse justify-content-md-around">
                        <Button color="danger" type="reset" className="mt-3">
                            Cancelar
                        </Button>
                        <Button color="success" type="submit" className="mt-2 mt-md-3">
                            Guardar
                        </Button>
                    </div>
                </form>
            </Collapse>

            {   
                permissions.CON &&
                <Datatable
                    headers={headers}
                    columns={columns}
                    columnDefs={columnDefs}
                    petition={getData}
                    control="back"
                    stateRefresh={[refresh, setRefresh]}
                    searching={true}
                    className="px-0 mt-4"
                />
            }

            {
                showButton && permissions.UPD && 
                <div className=" d-flex flex-md-row flex-column justify-content-center">
                    <Button color="info" type="submit" className="mt-2 mt-md-3" onClick={()=>send()}>
                        Finalizar
                    </Button>
                </div>
            }

            <ModalConfirmation
                modalTitle="Crear requisición"
                modal={modal}
                setModal={setModal}
                crear={save}
            >
                <div className="d-flex justify-content-center">
                    <h6>¿Está seguro de {values.idDetail ? "editar el artículo": `agregar ${values.nameFurniture} a la devolución`}?</h6>
                </div>
            </ModalConfirmation>

            <Modal isOpen={modalBien} toggle={closeModalBien} fullscreen="lg" size="xl" backdrop="static" >
                <ModalHeader toggle={closeModalBien}>Agregar nuevo bien mueble</ModalHeader>
                <ModalBody>
                    <FormFurniture OnSuccess={successModalBien} OnCancel={closeModalBien} />
                </ModalBody>
            </Modal>

            <FullFormLoader show={loading} />
        </div>
    )
}

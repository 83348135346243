import React from "react";
import { Row, Col } from "reactstrap";

const CheckComponen = ({ text, values, onChangeCheck, keyObject }) => {
  return (
    <Row className="d-flex justify-content-beetwen">
      <Col className="text-right">
        <p>{text}</p>
      </Col>
      <Col className="text-left">
        <input
          id={keyObject}
          name={keyObject}
          type="checkbox"
          className="styled"
          value={values}
          checked={values}
          onChange={(e) => onChangeCheck(e.target.checked, keyObject)}
        />
      </Col>
    </Row>
  );
};

export default CheckComponen;

import * as Yup from "yup"
import { useFormik } from "formik";
import { Button, Col, Row } from "reactstrap";

import { SelectTypeHeadSingle, TextInput } from "../../../../../components/GenericInputsFormik";
import ModalConfirmation from "../../../../../components/Modal/ModalConfirmation";
import { useEffect, useState } from "react";
import { peticionesReceiver } from "../../../../../helpers/peticionesReceiver";
import { toast } from "react-toastify";
import Notification from "../../../../../components/Notification/Notification";
import Loader from "../../../../../components/Loader/Loader";


export default function AltaServicios({ OnSuccess, OnCancel }) {
    const API = peticionesReceiver()
    const [modal, setModal] = useState(false)

    const [clasificaciones, setClasificaciones] = useState([]);
    const [periodos, setPeriodos] = useState([]);

    const [loadingClass, setLoadingClass] = useState(true);
    const [loadingPer, setLoadingPer] = useState(true);
    const [loading, setLoading] = useState(true);

    const defaultForm = {
        nameService: "",
        idExpenseObject: "",
        keyServiceTime: ""
    }

    const FormSchema = Yup.object().shape({
        nameService: Yup.string().required("Ingresar un nombre de servicio"),
        idExpenseObject: Yup.string().required("Seleccionar una clasificacion valida"),
        keyServiceTime: Yup.string().required("Seleccionar un periodo valido"),
    })

    const { handleSubmit, values, handleBlur, errors, touched, setFieldValue, resetForm } =
        useFormik({
            initialValues: defaultForm,
            onSubmit: () => setModal(true),
            validationSchema: FormSchema,
        });

    const getDataClasificacion = async () => {
        const params = {
            action: "multiselect",
            rows: "idExpenseObject,concat(fullKey,' - ',nameObject) as nameObject",
            table: "expense_objects",
            conditions: "enabled = 1 and substr(fullkey,1,1) = 3"
        }
        try {
            const res = await API.peticion(params);
            if (res.status === 200 && res.data.code === "200") {
                setClasificaciones(res.data.data)
                setLoadingClass(false)
            } else {
                toast(<Notification type={"consultar_error"} backMessage={res.data.message} />);
            }
        } catch (err) {
            toast(<Notification type={"consultar_servidor_error"} withIcon />);
            return null
        }
    }

    const getDataPeriodos = async () => {
        const params = {
            action: "multiselect",
            rows: "keyServiceTime,nameServiceTime",
            table: "servicetimes",
            conditions: "enabled = 1"
        }
        try {
            const res = await API.peticion(params);
            if (res.status === 200 && res.data.code === "200") {
                setPeriodos(res.data.data)
                setLoadingPer(false)
            } else {
                toast(<Notification type={"consultar_error"} backMessage={res.data.message} />);
            }
        } catch (err) {
            toast(<Notification type={"consultar_servidor_error"} withIcon />);
            return null
        }
    }

    useEffect(() => {
        getDataClasificacion();
        getDataPeriodos()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        setLoading(loadingClass && loadingPer);
    }, [loadingClass, loadingPer])

    const onChange = (e) => {
        const { name, value } = e.target
        setFieldValue([name], value);
    }

    const getParams = (rows) => {
        return {
            table: "services",
            action: "insert",
            rows
        }
    }

    const onCreate = async (rows) => {
        try {
            const res = await API.peticion(getParams(rows))
            if (res.status === 200 && res.data.code === "200") {
                toast(<Notification type={"agrega_exito"} backMessage={res.data.message} withIcon />);
                resetForm()
                OnSuccess()
            } else {
                toast(<Notification type={"agrega_error"} backMessage={res.data.message} withIcon />);
            }
        } catch (err) {
            console.log(err)
            toast(<Notification type={"agrega_error"} withIcon />);
        }
    }

    if (loading) {
        return (
            <div className="text-center" style={{height: "200px"}}>
                <Loader />
            </div>
        )
    }
    return (
        <>
            <form onSubmit={handleSubmit}>
                <Row>
                    <Col>
                        <TextInput
                            label="Descripción del servicio"
                            inputName="nameService"
                            inputType="text"
                            onChangeMethod={onChange}
                            onBlurMethod={handleBlur}
                            value={values.nameService}
                            isRequired
                            errors={errors.nameService}
                            touched={touched.nameService}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md={6}>
                        <SelectTypeHeadSingle
                            label="Clasificación contable"
                            inputName="idExpenseObject"
                            onChangeMethod={onChange}
                            onBlurMethod={handleBlur}
                            value={values.idExpenseObject}
                            isRequired
                            touched={touched.idExpenseObject}
                            errors={errors.idExpenseObject}
                            optionsArray={clasificaciones}
                            optionValue="idExpenseObject"
                            optionName="nameObject"
                        />
                    </Col>
                    <Col xs={12} md={6}>
                        <SelectTypeHeadSingle
                            label="Periodo"
                            inputName="keyServiceTime"
                            onChangeMethod={onChange}
                            onBlurMethod={handleBlur}
                            value={values.keyServiceTime}
                            isRequired
                            touched={touched.keyServiceTime}
                            errors={errors.keyServiceTime}
                            optionsArray={periodos}
                            optionValue="keyServiceTime"
                            optionName="nameServiceTime"
                        />
                    </Col>
                </Row>
                <div className="text-center">
                    <Button className="mx-2" color="danger" onClick={OnCancel}>Cancelar</Button>
                    <Button className="mx-2" type="submit">Agregar</Button>
                </div>
            </form>
            <ModalConfirmation
                modalTitle={"Alta de servicio"}
                modal={modal}
                editar={(rows) => onCreate(rows)}
                isEdit={true}
                setModal={setModal}
                values={values}
            >
                <div className="d-flex justify-content-center">
                    <h6>¿Desea continuar con el registro?</h6>
                </div>
            </ModalConfirmation>
        </>
    )
}
import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import TableShowComponent from "./TableShowComponent";
import Widget from "../../../components/Widget/Widget";
import { Row, Col, Button } from "reactstrap";
import { toast } from "react-toastify";
import Notification from "../../../components/Notification/Notification";
import { peticionesReceiver } from "../../../helpers/peticionesReceiver";
import { GetPermissions } from "../../../libs/permissions/getPermissions";

const form = {
  busqueda: "",
};
const Permisos = () => {
  GetPermissions();
  const API = peticionesReceiver();
  const [content, setContent] = useState();
  const [valor, setValor] = useState(true);
  const [cabeceras] = useState(["Usuario", "Nombre", "Modificar"]);
  const [filtro] = useState([false, true, true]);
  const FormSchema = Yup.object().shape({
    busqueda: Yup.string().required("Ingrese un nombre"),
  });

  const getAnswer = (fields) => {
    const data = {
      action: "multiselect",
      table: "users",
      rows: "idUser,user,concat(name,' ',pName,' ',mName) as fullName",
      conditions: `enabled = 1 AND concat(name,' ',pName,' ',mName) like '%${fields.busqueda}%' OR user like '%${fields.busqueda}%'`,
      order: "name asc",
    };
    /* .post(`${process.env.REACT_APP_API}receiver/receiver.php`, data)
    .then((res) => { */
    API.peticion(data)
      .then((res) => {
        if (res.status === 200 && res.data.code === "200") {
          const datos = [];
          res.data.data.map((element) => {
            datos.push([
              element["idUser"],
              element["user"],
              element["fullName"],
            ]);
          });
          setContent(datos);
          setValor(false);
        } else {
          setContent([]);
          toast(<Notification type={"consultar_error"} withIcon />);
        }
      })
      .catch((err) => {
        toast(<Notification type={"consultar_servidor_error"} withIcon />);
      });
  };
  const submitFunction = (fields) => {
    getAnswer(fields);
  };

  return (
    <Widget className="widget-p-md">
      <h1 className="text-secondary text-center">Asignación de permisos</h1>
      <Formik
        initialValues={form}
        validationSchema={FormSchema}
        onSubmit={(fields) => {
          submitFunction(fields);
        }}
      >
        {({ errors, status, touched }) => (
          <Form>
            <Row className="d-flex align-items-end">
              <Col xs={12} md={8}>
                <div className="form-group mt-4">
                  <label htmlFor="busqueda">Ingrese nombre del usuario</label>
                  <Field
                    name="busqueda"
                    type="text"
                    className={
                      "form-control" +
                      (errors.busqueda && touched.busqueda ? " is-invalid" : "")
                    }
                  />
                  <ErrorMessage
                    name="busqueda"
                    component="div"
                    className="invalid-feedback"
                  />
                </div>
              </Col>
              <Col xs={12} md={4}>
                <div className="d-flex justify-content-center">
                  <Button color="success" type="submit" className="mb-3">
                    Buscar
                  </Button>
                </div>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
      {valor ? (
        <div>
          <h3 className="text-center mt-4 text-primary">
            {" "}
            Ingrese el nombre del usuario
          </h3>
        </div>
      ) : (
        <TableShowComponent
          titulo="Usuarios"
          cabecerasTabla={cabeceras}
          contenido={content}
          filtro={filtro}
          rutaVer="/template/usuarios-permisos/permisos"
          tipo="show"
        ></TableShowComponent>
      )}
    </Widget>
  );
};
export default Permisos;

import Widget from "../../../components/Widget/Widget";
import { Button, Col, Nav, NavItem, NavLink, Row } from "reactstrap";
import { useEffect, useState } from "react";
import { HouseOutlined, LocalShippingOutlined } from "@material-ui/icons";
import Proveedores from "./Proveedores";
import Informacion from "./Informacion";
import { doRequest } from "../../../helpers/requests";
import Domicilio from "./Domicilio";
import CuentaB from "./CuentaB";
import Documentos from "./Documentos";
import { GetPermissions } from "../../../libs/permissions/getPermissions";
import { useLocation } from "react-router-dom";

const st = {
  in: { fontSize: "19px", marginRight: "5px" },
  imr: { marginRight: "5px" },
  bC: { backgroundColor: "#cc0a44", border: "none", color: "#FFFFFF" },
  iB: { fontSize: "16px" },
};
const titles = [
  ["Proveedores", "Contratistas"],
  "Información",
  "Domicilio",
  "Datos Bancarios",
  "Documentos",
];
const defaultProvider = {
  id: 0
};

const RegistroProveedores = () => {
  const [permissions, setPermissions] = useState({});
  GetPermissions(setPermissions);

  const [activeTab, setActiveTab] = useState(1);
  const [provider, setProvider] = useState({ ...defaultProvider });
  const [typesProv, setTypesProv] = useState([]);
  const [banks, setBanks] = useState([]);
  const [files, setFiles] = useState([]);
  const location = useLocation();
  const contratist = location.pathname.includes("contratistas") ? 1 : 0;

  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  useEffect(() => {
    getTypePersonas();
    getBanks();
    getFiles();
  }, []);

  const getTypePersonas = async () => {
    const params = {
      action: "select",
      table: "types_providers",
    };
    const data = await doRequest("receiver/receiver.php", params);
    if (data.length > 0) setTypesProv(data);
  };

  const getBanks = async () => {
    const params = {
      action: "select",
      table: "banks",
    };
    const data = await doRequest("receiver/receiver.php", params);
    if (data.length > 0) setBanks(data);
  };

  const getFiles = async () => {
    const params = {
      action: "select",
      table: "filetypes",
      condition: { idModule: "147" },
    };
    const data = await doRequest("receiver/receiver.php", params);
    if (data.length > 0) setFiles(data);
  };

  useEffect(() => {
    if (provider.id !== 0){
      if(!provider.change) toggleTab(2);
    } else toggleTab(1);
  }, [provider]);

  return (
    <Widget className="widget-p-md">
      <Nav fill tabs style={{ overflowY: "hidden", overflowX: "auto" }}>
        <NavItem active={activeTab === 1}>
          <NavLink
            href="#"
            active={activeTab === 1}
            onClick={() => toggleTab(1)}
            disabled={provider.id > 0}
          >
            <LocalShippingOutlined style={st.imr} />
            {titles[0][contratist]}
          </NavLink>
        </NavItem>
        <NavItem active={activeTab === 2}>
          <NavLink
            href="#"
            active={activeTab === 2}
            onClick={() => toggleTab(2)}
            disabled={!provider.id > 0}
          >
            <i className={"fa fa-id-card-o"} style={st.in} />
            Información
          </NavLink>
        </NavItem>
        <NavItem active={activeTab === 3}>
          <NavLink
            href="#"
            active={activeTab === 3}
            onClick={() => toggleTab(3)}
            disabled={!provider.id > 0}
          >
            {/* <i className={"fa fa-tasks"}  /> */}
            <HouseOutlined style={st.imr} />
            Domicilio
          </NavLink>
        </NavItem>
        <NavItem active={activeTab === 4}>
          <NavLink
            href="#"
            active={activeTab === 4}
            onClick={() => toggleTab(4)}
            disabled={!provider.id > 0}
          >
            <i className={"fa fa-money"} style={st.in} />
            Datos Bancarios
          </NavLink>
        </NavItem>
        <NavItem active={activeTab === 5}>
          <NavLink
            href="#"
            active={activeTab === 5}
            onClick={() => toggleTab(5)}
            disabled={!provider.id > 0}
          >
            <i className={"fa fa-id-card-o"} style={st.in} />
            Documentos
          </NavLink>
        </NavItem>
      </Nav>
      <div className="mt-4">
        <Row className="mb-1 mx-2">
          <Col xs={12} md={6} className="text-center text-md-left">
            <h3>{activeTab - 1 > 0 ? titles[activeTab - 1] : titles[0][contratist]}</h3>
          </Col>
          {provider.id !== 0 && (
            <Col xs={12} md={6} className="text-center text-md-right">
              <Button
                className="btn px-2 py-2"
                style={st.bC}
                onClick={() => setProvider({ ...defaultProvider })}
              >
                Cerrar
                <i className="fa fa-sign-out ml-2" style={st.iB} />
              </Button>
            </Col>
          )}
        </Row>

        {activeTab === 1 ? (
          <Proveedores setActiveTab={setActiveTab} setProvider={setProvider} contratist={contratist}/>
        ) : activeTab === 2 ? (
          <Informacion
            setActiveTab={setActiveTab}
            provider={provider}
            setProvider={setProvider}
            typesProv={typesProv}
            permissions={permissions}
            contratist={contratist}
          />
        ) : activeTab === 3 ? (
          <Domicilio idProvider={provider.id} permissions={permissions} />
        ) : activeTab === 4 ? (
          <CuentaB idProvider={provider.id} banks={banks} permissions={permissions} />
        ) : (
          <Documentos idProvider={provider.id} files={files} permissions={permissions} />
        )}
      </div>
    </Widget>
  );
};

export default RegistroProveedores;

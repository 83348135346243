
import React from "react";

import Widget from "../../../../components/Widget/Widget";
import FormLicencias from "../FormLicencias";
import { updateLeave } from "../functions";

export const EditarLicencia = (props) => {
  return (
    <Widget className="widget-p-md">
        <h3>Editar tipo de licencia</h3>
        <FormLicencias data={localStorage.getItem('dataContent').split('|-|')} editar={updateLeave}/>
    </Widget>
  )
}

export default EditarLicencia;
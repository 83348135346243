import XlsxPopulate from "xlsx-populate";
import { saveAs } from "file-saver";
import { doRequest } from "../../../../helpers/requests";
import { fMoney } from "../../../../libs/tools/format";

export default async function deduccionesAdicionalesExcel(data, config){
    const s = {
        header: {horizontalAlignment: "center", fill: "AEAAAA", fontColor: "FFFFFF", border: true},
        tt: {horizontalAlignment: "center", bold: true, fontSize: "14", shrinkToFit: false},
        txc: { horizontalAlignment: "center", border: true },
        txe: { horizontalAlignment: "right", border: true }
      }
    const staff = config.byStaff ? await getStaff(data[0].idStaff) : {};

    XlsxPopulate.fromBlankAsync().then(async (workbook) => {
        const hoja = workbook.sheet(0);
        hoja.range("A2:J2").merged(true).value("PERCEPCIONES Y DEDUCCIONES ADICIONALES").style(s.tt);
        let row = 3;
        const lines = config.filters.message.split("\n");
        lines.forEach((l, ix)=>{
          hoja.range(`A${row+ix}:J${row+ix}`).merged(true).value(l).style(s.tt);
        });
        row += lines.length + 2;

        for (let i = 1; i <= 10; i++) {
            if(i === 2) hoja.column(i).width(40);
            else hoja.column(i).width(20);
          }
        const flg = +(!config.byStaff);
        hoja.row(row).cell(1).value("CVE DESCUENTO").style(s.header);
        hoja.row(row).cell(2).value("CONCEPTO").style(s.header);
        hoja.row(row).cell(3).value("FOLIO").style(s.header);
        if(!config.byStaff) hoja.row(row).cell(4).value("EMPLEADO").style(s.header);
        hoja.row(row).cell(4 + flg).value("FECHA DE INICIO").style(s.header);
        hoja.row(row).cell(5 + flg).value("FECHA FIN").style(s.header);
        hoja.row(row).cell(6 + flg).value("IMPORTE INICIAL").style(s.header);
        hoja.row(row).cell(7 + flg).value("DESCUENTO FIJO").style(s.header);
        hoja.row(row).cell(8 + flg).value("IMPORTE RETENIDO").style(s.header);
        hoja.row(row).cell(9 + flg).value("SALDO").style(s.header);
        
        row++;
        data.forEach((el, ix) => {
            hoja.row(row+ix).cell(1).value(el.under).style(s.txc);
            hoja.row(row+ix).cell(2).value(el.nameUnder).style(s.txc);
            hoja.row(row+ix).cell(3).value(el.folio).style(s.txc);
            if(!config.byStaff) hoja.row(row+ix).cell(4).value(`${el.staffNumber} - ${el.nombre}`).style(s.txc);
            hoja.row(row+ix).cell(4 + flg).value(el.fechaInicio).style(s.txc);
            hoja.row(row+ix).cell(5 + flg).value(el.fechaFin).style(s.txc);
            hoja.row(row+ix).cell(6 + flg).value(fMoney(el.importeInicial)).style(s.txe);
            hoja.row(row+ix).cell(7 + flg).value(fMoney(el.descuentoFijo)).style(s.txe);
            hoja.row(row+ix).cell(8 + flg).value(fMoney(el.importeRetenido)).style(s.txe);
            hoja.row(row+ix).cell(9 + flg).value(fMoney(el.saldo)).style(s.txe);
        });

        return workbook.outputAsync().then((res) => {
            saveAs(res, `Deducciones Adicionales.xlsx`);
          });
    });
}

async function getStaff(idStaff) {
    const params = {
      action: "getStaffData",
      idStaff: idStaff,
    };
    const res = await doRequest("app/facades/reports/reportsF.php", params, false, false);
    if(res.length > 0) return res[0];
    return {}
  };

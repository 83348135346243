import React, { useState } from "react";
import TableComponentPresupuestos from "../../../components/TableComponent/TableComponentPresupuestos";

const Presupuestos = () => {
    const cabeceras = [
        "Id", //0
        "Año",
        "Tipo",
        "Cantidad Total"
      ];
      const [parametros] = useState({
        action: "datatable",
        table: "annualbudget A inner join budget_types T on (A.keyBudgetType = T.keyBudgetType)",
        rows: "A.idAnualBudget, A.budgetYear, T.nameBudgetType, A.amount", ///sin espacios y comas
        conditions: "A.enabled = 1",
        page: 0,
        records: 5,
        search: "",
        order: "idAnualBudget asc",
      });
    
      const [filter] = useState([true, true, true, true])
    

  return (
    <div>
      <TableComponentPresupuestos
        titulo="Presupuestos Anuales"
        cabecerasTabla={cabeceras}
        filtro={filter}
        nextRoute='/template/presupuestos/presupuestos-dependientes'
        parametros={parametros}
        origen='Presupuestos'
        crear='/template/presupuestos/agregar'
      ></TableComponentPresupuestos>
    </div>
  );
};
export default Presupuestos;

import React, { useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Notification from "../Notification/Notification";
import { peticionesReceiver } from "../../helpers/peticionesReceiver";

import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

const ModalComponent = (props) => {
  const {
    className,
    children,
    backdrop,
    opened,
    sortCol,
    // modalTitle,
    id,
    table,
    nameColumn,
    getData,
    limpia,
    reset,
    beforeDelete,
    elimina,
    specialIco,
    forceDelete,
    button = false,
    fill = true
  } = props;

  const API = peticionesReceiver();
  const [modal, setModal] = useState(false);
  const toggle = () => {
    setModal(!modal);
  };

  const handleEliminar = async () => {
    const tabla = table[0];
    const columna = nameColumn.length > 1 ? nameColumn[1] : nameColumn[0];
    // console.log("Tabla:",tabla);
    // console.log("NameColumn:",columna);
    // console.log("Id:",id);
    // console.log('beforeDelete', beforeDelete);
    var flag = false;

    //Si recibe un elimina lo ejecuta , si no ejecuta el default de la tabla
    if (elimina) {
      // console.log(id);
      elimina(id, getData);
      toggle();
    } else {
      if (beforeDelete !== undefined) {
        //Borro lo que tenga que hacer o incluso solo correr la funcion.
        // await beforeDelete();
        // console.log("ELIMINANDO NORMAL");
        var condition = {};
        condition[columna] = id;
        var params = {};
        if (forceDelete === 1 && beforeDelete !== undefined) {
          params = {
            action: "delete",
            table: beforeDelete,
            condition: condition,
            force: 1,
          };
        } else {
          params = {
            action: "delete",
            table: beforeDelete,
            condition: condition,
          };
        }

        await API.peticion(params)
          .then((res) => {
            // console.log(res.data);
            if (res.data.status === "success" && res.data.code === "200") {
              toast(
                <Notification
                  type={"elimina_exito"}
                  backMessage={res.data.message}
                  withIcon
                />
              );
            } else {
              toast(
                <Notification
                  type={"elimina_error"}
                  backMessage={res.data.message}
                  withIcon
                />,
                { closeButton: false }
              );
            }
          })
          .catch((err) => {
            toast(<Notification type={"consultar_servidor_error"} withIcon />);
          });
        getData(sortCol);
      } else {

        if (!flag) {
          delateRecord(tabla, columna, limpia, reset);
        }
      }
      // console.log(flag);

      toggle();
    }
  };
  const handleCancelar = () => {
    toggle();
  };

  const CloseBtn = (
    <i className="eva eva-close cursor-pointer" onClick={toggle} />
  );

  const delateRecord = async (tabla, columna) => {
    if (id && table && nameColumn) {
      var condition = {};
      condition[columna] = id;
      const params = {
        action: "delete",
        table: tabla,
        condition: condition,
      };
      await API.peticion(params)
        .then((res) => {
          if (res.status === 200 && res.data.code === "200") {
            toast(<Notification type={"elimina_exito"} withIcon />, {
              closeButton: false,
            });
            if (limpia) {
              if (reset) {
                limpia(reset, id);
              } else {
                limpia(id);
              }
            }
            sortCol ? getData(sortCol,true) : getData()
            
          } else {
            toast(
              <Notification
                type={"elimina_error"}
                backMessage={res.data.message}
                withIcon
              />,
              { closeButton: false }
            );
          }
        })
        .catch((err) => {
          toast(<Notification type={"consultar_servidor_error"} withIcon />);
        });
    }
  };

  return (
    <>
      {!button ? (
        <>
          {specialIco ? (
            <div style={{ cursor: "pointer" }} onClick={toggle}>
              <i className="fa fa-trash-o text-danger pr-2" />
              Eliminar
            </div>
          ) : (
            <i
              className="fa fa-trash-o text-danger"
              style={{ cursor: "pointer" }}
              onClick={toggle}
            />
          )}
        </>
      ) : (
        <>
          {specialIco ? (
            <Button color="danger" size="sm" className={`d-flex align-items-center text-nowrap py-1 px-2 mb-2 ${fill? "w-100" : ""}`} onClick={toggle}>
              <i className="fa fa-trash-o pr-2" />
              Eliminar
            </Button>
          ) : (
            <Button color="danger" size="sm" className={`d-flex align-items-center text-nowrap py-1 px-2 mb-2 ${fill? "w-100" : ""}`} onClick={toggle}>
              <i
                className="fa fa-trash-o"
                onClick={toggle}
              />
            </Button>

          )}
        </>
      )}


      <Modal
        isOpen={modal || opened}
        backdrop={backdrop}
        toggle={toggle}
        className={className}
      >
        <ModalHeader toggle={toggle} close={CloseBtn} className="headline-1" />
        <ModalBody className="text-center">{children}</ModalBody>
        <ModalFooter>
        <div className="w-100 d-flex flex-md-row flex-column-reverse justify-content-md-around mx-5 mx-md-5 px-3">
            <Button
              className="btn-outline-secondary mt-3"
              outline
              onClick={() => handleCancelar()}
            >
              Cancelar
            </Button>
            <Button color="danger" onClick={() => handleEliminar()} className="mt-md-3">
              Eliminar
            </Button>
          </div>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default ModalComponent;
import { Document, Page, Text, View, StyleSheet } from "@react-pdf/renderer";
import HeaderReport from "../../../../components/Pdf/Header";
import { styles } from "../../../../components/Pdf/pdfGeneral/EstiloPdf";

/**
 * Estilo que se repite en todas los headers de la tabla
 */
const styleHeadForAll = {
  borderStyle: "solid", 
  borderWidth: 1,
  backgroundColor: "#646464",
  color: 'white',
  textAlign: 'center',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: 12
}

const styleCellForAll = {
  borderStyle: "solid", 
  borderWidth: 1, 
  borderTopWidth: 0,
  fontSize: 12
}

/**
 * Estilos del pdf
 */
const s = StyleSheet.create({
  subTitle:{ //SUBTITULOS
    width:"100%",
    textAlign: 'center',
    marginBottom: 10,
    marginLeft: 10,
    fontSize: 14
  },
  f6: {
    fontSize: 6
  },
  f5: {
    fontSize: 5
  },
  f8: {
    fontSize: 8
  },
  f10: {
    fontSize: 10
  },
  thIn: { 
    height: 20, 
    ...styleHeadForAll
  }, 
  th: {
    height: 20,
    ...styleHeadForAll,
    borderLeftWidth: 0
  },
  w10: {
    width: "10%"
  },
  w20: {
    width: "20%"
  },
  w25: {
    width: "25%"
  },
  w30: {
    width: "30%"
  },
  w33: {
    width: "33%"
  },
  w34: {
    width: "34%"
  },
  w40: {
    width: "40%"
  },
  w50: {
    width: "50%"
  },
  w60: {
    width: "60%"
  },
  w70: {
    width: "70%"
  },
  w90: {
    width: "90%"
  },
  w100: {
    width: "100%"
  },
  mt2: {
    marginTop: 40
  },
  px2: {
    paddingHorizontal: 20
  },
  bt0: {
    borderTopWidth: 0
  },
  center: {
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
  textBold: {
    fontFamily: 'Helvetica-Bold'
  },
  tdIn: { //Cell CC
    ...styleCellForAll
  }, 
  td: { //Cell Concepto
    ...styleCellForAll,
    borderLeftWidth: 0
  },
  red: {
    backgroundColor: "red",
  },
  green: {
    backgroundColor: "green",
  },
  gray: {
    backgroundColor: "gray",
  }
})

function getDataField(field, key) {
  if (field && field.data && field.data.length > 0) {
    const data = field.data[0];
    return data[key] ? data[key] : "";
  }
  return ""
}

function toNumber(value) {
  if (!isNaN(value)) {
    return Number(value)
  }
  return value;
}
  
function contentVariacionMeta(row) {
  return (
    <View style={styles.tableRow} fixed>
      <View style={[s.w40, styles.tableRow]} fixed>
        <View style={[s.w70, styles.tableRow]}>
          <View style={[s.w10, s.tdIn, s.f6, s.center]}>
            <Text>{row.idActivity}</Text>
          </View>
          <View style={[s.w90, s.td, s.f6, s.center]}>
            <Text>{row.nameActivity}</Text>
          </View>
        </View>
        <View style={[s.w30, styles.tableRow]}>
          <View style={[s.w50, s.td, s.f6, s.center]}>
            <Text>{getDataField(row.annual, "variationUnit")}</Text>
          </View>
          <View style={[s.w50, s.td, s.f6, s.center]}>
            <Text>{toNumber(row.annualAmmout)}</Text>
          </View>
        </View>
      </View>
      <View style={[s.w30, styles.tableRow]}>
        <View style={[s.w33, styles.tableRow]}>
          <View style={[s.w50, s.td, s.f6, s.center]}>
            <Text>{toNumber(getDataField(row.trimonth, "programed"))}</Text>
          </View>
          <View style={[s.w50, s.td, s.f6, s.center]}>
            <Text>{toNumber(getDataField(row.trimonth, "programedP"))}%</Text>
          </View>
        </View>
        <View style={[s.w33, styles.tableRow]}>
          <View style={[s.w50, s.td, s.f6, s.center]}>
            <Text>{toNumber(getDataField(row.trimonth, "reached"))}</Text>
          </View>
          <View style={[s.w50, s.td, s.f6, s.center]}>
            <Text>{toNumber(getDataField(row.trimonth, "reachedP"))}%</Text>
          </View>
        </View>
        <View style={[s.w34, styles.tableRow]}>
          <View style={[s.w50, s.td, s.f6, s.center]}>
          <Text>{toNumber(getDataField(row.trimonth, "variation"))}</Text>
          </View>
          <View style={[s.w50, s.td, s.f6, s.center]}>
          <Text>{toNumber(getDataField(row.trimonth, "variationP"))}%</Text>
          </View>
        </View>
      </View>
      <View style={[s.w30, styles.tableRow]}>
        <View style={[s.w33, styles.tableRow]}>
          <View style={[s.w50, s.td, s.f6, s.center]}>
            <Text>{toNumber(getDataField(row.annual, "programed"))}</Text>
          </View>
          <View style={[s.w50, s.td, s.f6, s.center]}>
          <Text>{toNumber(getDataField(row.annual, "programedP"))}%</Text>
          </View>
        </View>
        <View style={[s.w33, styles.tableRow]}>
          <View style={[s.w50, s.td, s.f6, s.center]}>
            <Text>{toNumber(getDataField(row.annual, "reached"))}</Text>
          </View>
          <View style={[s.w50, s.td, s.f6, s.center]}>
            <Text>{toNumber(getDataField(row.annual, "reachedP"))}%</Text>
          </View>
        </View>
        <View style={[s.w34, styles.tableRow]}>
          <View style={[s.w50, s.td, s.f6, s.center]}>
            <Text>{toNumber(getDataField(row.annual, "variation"))}</Text>
          </View>
          <View style={[s.w50, s.td, s.f6, s.center]}>
            <Text>{toNumber(getDataField(row.annual, "variationP"))}%</Text>
          </View>
        </View>
      </View>
    </View>
  )
}

function generateContentVariacion(content) {
  return content.map(elemnt => contentVariacionMeta(elemnt))
}

function contectPorcAvance(row) {
  return (
    <View style={styles.tableRow} fixed >
      <View style={[s.w25, styles.tableRow]} fixed>
        <View style={[s.w70, styles.tableRow]}>
          <View style={[s.w10, s.tdIn, s.f5]}>
            <Text>{row.idActivity}</Text>
          </View>
          <View style={[s.w70, s.td, s.f5]}>
            <Text>{row.nameActivity}</Text>
          </View>
          <View style={[s.w20, s.td, s.f5]}>
            <Text>{toNumber(row.activityPercent)}</Text>
          </View>
        </View>
        <View style={[s.w30, styles.tableRow]}>
          <View style={[s.w50, s.td, s.f5]}>
            <Text>{getDataField(row.annual, "variationUnit")}</Text>
          </View>
          <View style={[s.w50, s.td, s.f5]}>
            <Text>{toNumber(row.annualAmmout)}</Text>
          </View>
        </View>
      </View>
      <View style={[s.w50, styles.tableRow]}>
        <View style={[s.w25, styles.tableRow]}>
          <View style={[s.w25, s.td, s.f5]}>
            <Text>{toNumber(getDataField(row.first, "programed"))}</Text>
          </View>
          <View style={[s.w25, s.td, s.f5]}>
            <Text>{toNumber(getDataField(row.first, "reached"))}</Text>
          </View>
          <View style={[s.w25, s.td, s.f5]}>
          <Text>{toNumber(getDataField(row.first, "variation"))}</Text>
          </View>
          <View style={[s.w25, s.td, s.f5, toNumber(getDataField(row.first, "reached")) >= toNumber(getDataField(row.first, "programed")) ? s.green : s.gray]}>
          </View>
        </View>
        <View style={[s.w25, styles.tableRow]}>
          <View style={[s.w25, s.td, s.f5]}>
            <Text>{toNumber(getDataField(row.second, "programed"))}</Text>
          </View>
          <View style={[s.w25, s.td, s.f5]}>
            <Text>{toNumber(getDataField(row.second, "reached"))}</Text>
          </View>
          <View style={[s.w25, s.td, s.f5]}>
            <Text>{toNumber(getDataField(row.second, "variation"))}</Text>
          </View>
          <View style={[s.w25, s.td, s.f5, toNumber(getDataField(row.second, "reached")) >= toNumber(getDataField(row.second, "programed")) ? s.green : s.gray]}>
          </View>
        </View>
        <View style={[s.w25, styles.tableRow]}>
          <View style={[s.w25, s.td, s.f5]}>
            <Text>{toNumber(getDataField(row.third, "programed"))}</Text>
          </View>
          <View style={[s.w25, s.td, s.f5]}>
            <Text>{toNumber(getDataField(row.third, "reached"))}</Text>
          </View>
          <View style={[s.w25, s.td, s.f5]}>
            <Text>{toNumber(getDataField(row.third, "variation"))}</Text>
          </View>
          <View style={[s.w25, s.td, s.f5, toNumber(getDataField(row.third, "reached")) >= toNumber(getDataField(row.third, "programed")) ? s.green : s.gray]}>
          </View>
        </View>
        <View style={[s.w25, styles.tableRow]}>
          <View style={[s.w25, s.td, s.f5]}>
            <Text>{toNumber(getDataField(row.fourth, "programed"))}</Text>
          </View>
          <View style={[s.w25, s.td, s.f5]}>
            <Text>{toNumber(getDataField(row.fourth, "reached"))}</Text>
          </View>
          <View style={[s.w25, s.td, s.f5]}>
            <Text>{toNumber(getDataField(row.fourth, "variation"))}</Text>
          </View>
          <View style={[s.w25, s.td, s.f5, toNumber(getDataField(row.fourth, "reached")) >= toNumber(getDataField(row.fourth, "programed")) ? s.green : s.gray]}>
          </View>
        </View>
      </View>
      <View style={[s.w25, styles.tableRow]}>
        <View style={[s.w20, s.td, s.f5]}>
          <Text>{toNumber(getDataField(row.annual, "reached"))}</Text>
        </View>
        <View style={[s.w20, s.td, s.f5]}>
          <Text>{toNumber(getDataField(row.annual, "activityScore"))}%</Text>
        </View>
        <View style={[s.w20, s.td, s.f5]}>
          <Text>{toNumber(getDataField(row.annual, "totalScore"))}%</Text>
        </View>
        <View style={[s.w20, s.td, s.f5]}>
          <Text>{getDataField(row.annual, "variationUnit")}</Text>
        </View>
        <View style={[s.w20, s.td, s.f5, toNumber(getDataField(row.fourth, "reached")) >= toNumber(row.annualAmmout) ? s.green : s.gray]}>
        </View>
      </View>
    </View>
  )
}

function generatePorcAvance(content) {
  return content.map(elemnt => contectPorcAvance(elemnt))
}

export default function generatePDFAvance(data, model, title, fileNo) {
  const ori = "landscape"

  const actividades = data.activities ? data.activities.data : [];

  const anual = data.Anual ? data.Anual.data : [];

  return {
    report: (
      <>
        <Document>
          <Page style={styles.body} orientation={ori}>
            <HeaderReport/>
            <View>
              <Text style={s.subTitle}>{title}</Text>
            </View>
            <View style={styles.table}>
              <View style={styles.tableRow} fixed>
                <View style={s.w30}>
                  <View><Text style={s.f10}>Informe de actividades por proyecto y unidad ejecutora</Text></View>
                  <View><Text style={s.f10}>Ejercicio: {data.startDate.substring(0, data.startDate.indexOf("-"))}</Text></View>
                  <View><Text style={s.f10}>Fecha: {data.startDate}</Text></View>
                  <View><Text style={s.f10}>Pbr-11a </Text></View>
                  <View><Text style={s.f10}>{data.trimestre}</Text></View>
                </View>
                <View style={[s.w70, s.px2]}>
                  <View><Text style={s.f10}>Proyecto presupuestal: { data.nameStateFund}</Text></View>
                  <View><Text style={s.f10}>Proyecto: {data.nameProyect}, {data.objetive}</Text></View>
                  <View><Text style={s.f10}>Unidad ejecutora: { data.nameArea }</Text></View>
                </View>
              </View>
            </View>
            <View>
              <Text style={[s.subTitle, s.textBold]}>Informe de variación de metas</Text>
            </View>
            <View style={styles.table}>
              <View style={styles.tableRow} fixed>
                <View style={[s.thIn, s.w40]}><Text>Actividades del proyecto</Text></View>
                <View style={[s.th, s.w30]}><Text>Avance trimestral</Text></View>
                <View style={[s.th, s.w30]}><Text>Avance acumulado anual</Text></View>
              </View>
              <View style={styles.tableRow} fixed>
                <View style={[s.w40, styles.tableRow]}>
                  <View style={[s.w70, s.thIn, s.bt0]}></View>
                  <View style={[s.w30, s.th, s.f8, s.bt0]}><Text>Programación anual</Text></View>
                </View>
                <View style={[s.w30, styles.tableRow]}>
                  <View style={[s.w33, s.th, s.f8, s.bt0]}><Text>Programada</Text></View>
                  <View style={[s.w33, s.th, s.f8, s.bt0]}><Text>Alcanzada</Text></View>
                  <View style={[s.w34, s.th, s.f8, s.bt0]}><Text>Variación</Text></View>
                </View>
                <View style={[s.w30, styles.tableRow]}>
                  <View style={[s.w33, s.th, s.f8, s.bt0]}><Text>Programada</Text></View>
                  <View style={[s.w33, s.th, s.f8, s.bt0]}><Text>Alcanzada</Text></View>
                  <View style={[s.w34, s.th, s.f8, s.bt0]}><Text>Variación</Text></View>
                </View>
              </View>
              <View style={styles.tableRow} fixed>
                <View style={[s.w40, styles.tableRow]}>
                  <View style={[s.w70, styles.tableRow]}>
                    <View style={[s.w10, s.thIn, s.f6, s.bt0]}><Text>Id.</Text></View>
                    <View style={[s.w90, s.th, s.f6, s.bt0]}><Text>Nombre de actividad</Text></View>
                  </View>
                  <View style={[s.w30, styles.tableRow]}>
                    <View style={[s.w50, s.th, s.f6, s.bt0]}>
                      <Text>Unidad de</Text><Text>medida</Text>
                    </View>
                    <View style={[s.w50, s.th, s.f6, s.bt0]}>
                      <Text>Programada</Text>
                    </View>
                  </View>
                </View>
                <View style={[s.w30, styles.tableRow]}>
                  <View style={[s.w33, styles.tableRow]}>
                    <View style={[s.w50, s.th, s.f6, s.bt0]}>
                      <Text>Meta</Text>
                    </View>
                    <View style={[s.w50, s.th, s.f6, s.bt0]}>
                      <Text>%</Text>
                    </View>
                  </View>
                  <View style={[s.w33, styles.tableRow]}>
                    <View style={[s.w50, s.th, s.f6, s.bt0]}>
                      <Text>Meta</Text>
                    </View>
                    <View style={[s.w50, s.th, s.f6, s.bt0]}>
                      <Text>%</Text>
                    </View>
                  </View>
                  <View style={[s.w34, styles.tableRow]}>
                    <View style={[s.w50, s.th, s.f6, s.bt0]}>
                      <Text>Meta</Text>
                    </View>
                    <View style={[s.w50, s.th, s.f6, s.bt0]}>
                      <Text>%</Text>
                    </View>
                  </View>
                </View>
                <View style={[s.w30, styles.tableRow]}>
                  <View style={[s.w33, styles.tableRow]}>
                    <View style={[s.w50, s.th, s.f6, s.bt0]}>
                      <Text>Meta</Text>
                    </View>
                    <View style={[s.w50, s.th, s.f6, s.bt0]}>
                      <Text>%</Text>
                    </View>
                  </View>
                  <View style={[s.w33, styles.tableRow]}>
                    <View style={[s.w50, s.th, s.f6, s.bt0]}>
                      <Text>Meta</Text>
                    </View>
                    <View style={[s.w50, s.th, s.f6, s.bt0]}>
                      <Text>%</Text>
                    </View>
                  </View>
                  <View style={[s.w34, styles.tableRow]}>
                    <View style={[s.w50, s.th, s.f6, s.bt0]}>
                      <Text>Meta</Text>
                    </View>
                    <View style={[s.w50, s.th, s.f6, s.bt0]}>
                      <Text>%</Text>
                    </View>
                  </View>
                </View>
              </View>
              {generateContentVariacion(actividades ? actividades : [])}
            </View>
            <View>
              <Text style={[s.subTitle, s.textBold]}>Informe de porcentaje de avance de proyecto</Text>
            </View>
            <View style={styles.table}>
              <View style={styles.tableRow} fixed>
                <View style={[s.thIn, s.w25]}><Text>Actividades del proyecto</Text></View>
                <View style={[s.th, s.w50]}><Text>Avance trimestral</Text></View>
                <View style={[s.th, s.w25]}><Text>Acumulado anual</Text></View>
              </View>
              <View style={styles.tableRow} fixed>
                <View style={[s.w25, styles.tableRow]}>
                  <View style={[s.w70, s.thIn, s.bt0]}>
                  </View>
                  <View style={[s.w30, s.th, s.f8, s.bt0]}>
                    <Text>Programación anual</Text>
                  </View>
                </View>
                <View style={[s.w50, styles.tableRow]}>
                  <View style={[s.w25, s.th, s.f8, s.bt0]}>
                    <Text>Primer trimestre</Text>
                  </View>
                  <View style={[s.w25, s.th, s.f8, s.bt0]}>
                    <Text>Segundo trimestre</Text>
                  </View>
                  <View style={[s.w25, s.th, s.f8, s.bt0]}>
                    <Text>Tercer trimestre</Text>
                  </View>
                  <View style={[s.w25, s.th, s.f8, s.bt0]}>
                    <Text>Cuarto trimestre</Text>
                  </View>
                </View>
                <View style={[s.w25, styles.tableRow]}>
                  <View style={[s.w100, s.th, s.bt0]}>
                  </View>
                </View>
              </View>
              <View style={styles.tableRow} fixed>
                <View style={[s.w25, styles.tableRow]}>
                  <View style={[s.w70, styles.tableRow]}>
                    <View style={[s.w10, s.thIn, s.f5, s.bt0]}>
                      <Text>Id</Text>
                    </View>
                    <View style={[s.w70, s.th, s.f5, s.bt0]}>
                      <Text>Nombre de la actividad</Text>
                    </View>
                    <View style={[s.w20, s.th, s.f5, s.bt0]}>
                      <Text>Valor porcentual</Text>
                    </View>
                  </View>
                  <View style={[s.w30, styles.tableRow]}>
                    <View style={[s.w50, s.th, s.f5, s.bt0]}>
                      <Text>Unidad de medida</Text>
                    </View>
                    <View style={[s.w50, s.th, s.f5, s.bt0]}>
                      <Text>Cantidad programada</Text>
                    </View>
                  </View>
                </View>
                <View style={[s.w50, styles.tableRow]}>
                  <View style={[s.w25, styles.tableRow]}>
                    <View style={[s.w25, s.th, s.f5, s.bt0]}>
                      <Text>Programada</Text>
                    </View>
                    <View style={[s.w25, s.th, s.f5, s.bt0]}>
                      <Text>Alcanzada</Text>
                    </View>
                    <View style={[s.w25, s.th, s.f5, s.bt0]}>
                      <Text>Variación</Text>
                    </View>
                    <View style={[s.w25, s.th, s.f5, s.bt0]}>
                      <Text>Semaforo</Text>
                    </View>
                  </View>
                  <View style={[s.w25, styles.tableRow]}>
                    <View style={[s.w25, s.th, s.f5, s.bt0]}>
                      <Text>Programada</Text>
                    </View>
                    <View style={[s.w25, s.th, s.f5, s.bt0]}>
                      <Text>Alcanzada</Text>
                    </View>
                    <View style={[s.w25, s.th, s.f5, s.bt0]}>
                      <Text>Variación</Text>
                    </View>
                    <View style={[s.w25, s.th, s.f5, s.bt0]}>
                      <Text>Semaforo</Text>
                    </View>
                  </View>
                  <View style={[s.w25, styles.tableRow]}>
                    <View style={[s.w25, s.th, s.f5, s.bt0]}>
                      <Text>Programada</Text>
                    </View>
                    <View style={[s.w25, s.th, s.f5, s.bt0]}>
                      <Text>Alcanzada</Text>
                    </View>
                    <View style={[s.w25, s.th, s.f5, s.bt0]}>
                      <Text>Variación</Text>
                    </View>
                    <View style={[s.w25, s.th, s.f5, s.bt0]}>
                      <Text>Semaforo</Text>
                    </View>
                  </View>
                  <View style={[s.w25, styles.tableRow]}>
                    <View style={[s.w25, s.th, s.f5, s.bt0]}>
                      <Text>Programada</Text>
                    </View>
                    <View style={[s.w25, s.th, s.f5, s.bt0]}>
                      <Text>Alcanzada</Text>
                    </View>
                    <View style={[s.w25, s.th, s.f5, s.bt0]}>
                      <Text>Variación</Text>
                    </View>
                    <View style={[s.w25, s.th, s.f5, s.bt0]}>
                      <Text>Semaforo</Text>
                    </View>
                  </View>
                </View>
                <View style={[s.w25, styles.tableRow]}>
                  <View style={[s.w20, s.th, s.f5, s.bt0]}>
                    <Text>Cantidad Alcanzada</Text>
                  </View>
                  <View style={[s.w20, s.th, s.f5, s.bt0]}>
                    <Text>Avance actividad</Text>
                  </View>
                  <View style={[s.w20, s.th, s.f5, s.bt0]}>
                    <Text>Avance proyecto</Text>
                  </View>
                  <View style={[s.w20, s.th, s.f5, s.bt0]}>
                    <Text>Variación</Text>
                  </View>
                  <View style={[s.w20, s.th, s.f5, s.bt0]}>
                    <Text>Semaforo</Text>
                  </View>
                </View>
              </View>
              {generatePorcAvance(anual ? anual : [])}
            </View>
            <View fixed style={styles.spaceFooter}></View>
            <View fixed style={styles.footer}>
              <Text style={styles.textFooter}>{fileNo}</Text>
              <Text
                style={styles.textFooter}
                render={({ pageNumber, totalPages }) =>
                  `PAGINA : ${pageNumber} / ${totalPages}`
                }
              />
            </View>
          </Page>
        </Document>
      </>
    ),
  }
}

import { useEffect, useState } from "react";
import Widget from "../../../components/Widget/Widget";
import { GetPermissions } from "../../../libs/permissions/getPermissions";
import { Spinner } from "reactstrap";
import Datatable from "../../../components/Datatable/Datatable";
import { peticionesReceiver } from "../../../helpers/peticionesReceiver";
import { toast } from "react-toastify";
import Notification from "../../../components/Notification/Notification";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

export default function SolventacionProceso() {
    const API = peticionesReceiver()
    const [permisos, setPermisos] = useState("")
    const [loading, setLoading] = useState(true);
    const [refresh, setRefresh] = useState(false);
    const { id } = useSelector(state => state.userInfo);
    const [idStaff, setIdStaff] = useState("");
    const [loadingUser, setLoadingUser] = useState(false);
    const history = useHistory()

    GetPermissions(setPermisos, setLoading);

    useEffect(() => {
        getStaffAttent()
    }, [])

    async function getStaffAttent() {
        try {
            const res = await API.peticion({
                action: "multiselect",
                rows: "s.idStaff",
                table: "users u join staff s on(u.idStaff = s.idStaff)",
                conditions: `u.idUser = ${id}`
            });
            if (res.status === 200 && res.data.code === "200") {
                setIdStaff(res.data.data[0].idStaff);
                setLoadingUser(false);
            } else {
                toast(<Notification type={"consultar_error"} backMessage={res.data.message} />);
            }
        } catch (err) {
            toast(<Notification type={"consultar_servidor_error"} withIcon />);
        }
    }

    const headers = ["Solicitud", "Tipo de compra", "Observaciones", "Adscripcion solicitante", "Estatus", "Ver"]

    const columns = [
        {
            data: null,
            render: function render(row) {
                return `${row.numberPurchaseRequest}/${row.yearPurchaseRequest}`
            },
            orderValue: "CONCAT(numberPurchaseRequest,'/',yearPurchaseRequest)"
        },
        { data: "nameTypePurchase" },
        { data: "object" },
        { data: "name" },
        { data: "nameStat" },
        {
            data: "idPurchaseRequest",
            render: function render(row) {
                return (
                    <i className="fa fa-eye text-info cursor-pointer" onClick={() => ResolverSolicitud(row)}/>
                )
            }
        }
    ]

    const getParams = (order, records, page, search) => {
        const params = {
            action: "datatable",
            table: "purchaserequests pr inner join status s on (pr.keyStat=s.keyStat) inner join areas a on (a.idArea=pr.idArea) inner join types_purchases tp on (tp.keyTypePurchase=pr.keyTypePurchase) inner join quotes q on (pr.idQuote=q.idQuote)",
            rows: "pr.idPurchaseRequest,pr.numberRequest as numberPurchaseRequest, pr.yearRequest as yearPurchaseRequest,pr.dateSend,a.name,tp.nameTypePurchase,q.object,s.nameStat,pr.idStaffAtend",
            conditions: `pr.enabled = 1 AND pr.idStaffAtend = ${idStaff} AND pr.keyStat=126`,
            order,
            records: records.toString(),
            page: (page * records).toString(),
            search
        }
        return params
    }

    async function getData(order, records, page, search) {
        try {
            const res = await API.peticion(getParams(order, records, page, search));
            if (res.status === 200 && res.data.code === "200") {
                return res.data;
            } else {
                toast(<Notification type={"consultar_error"} backMessage={res.data.message} />);
                return null;
            }
        } catch (err) {
            toast(<Notification type={"consultar_servidor_error"} withIcon />);
            return null
        }
    }

    const ResolverSolicitud = (id) => {
        localStorage.setItem("solvProc", id);
        history.push("/template/solventacion-proceso/resolver-solicitud")
    }

    if (loading || loadingUser) {
        return (
            <div className="d-flex align-items-center justify-content-center" style={{ height: "200px" }}>
                <Spinner
                    color="primary"
                    style={{
                        height: '3rem',
                        width: '3rem'
                    }}
                    type="grow"
                >
                    Loading...
                </Spinner>
            </div>
        )
    }
    return (
        <>
            <Widget className="widget-p-md">
                <h3 className="text-secondary">Recepcion de compras</h3>
                {permisos.CON ? (
                    <Datatable
                        headers={headers}
                        columns={columns}
                        petition={getData}
                        stateRefresh={[refresh, setRefresh]}
                        control="back"
                        order={{col: 0, opt: "desc"}}
                    />
                ) : (<div className="text-center p-4">No tienes permitido ver los registros de esta seccion</div>)}
            </Widget>
        </>
    )
}
//AUTH
export const AUTH_FAILURE = "AUTH_FAILURE";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const USER_TOKEN = 'USER_TOKEN';
export const USER_ID = 'USER_ID';
export const PROFILE_TYPE = 'PROFILE_TYPE';
export const USER_INFO = 'USER_INFO';
export const LOGOUT_USER = 'LOGOUT_USER';
export const PERMISSION = 'PERMISSION';

import React, { useState, useEffect } from "react";
import { Col, Row, Button } from "reactstrap";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import { useHistory } from "react-router-dom";
import { setYear } from "date-fns";
import axios from "axios";
import * as Yup from "yup";

import Notification from "../../../../../components/Notification/Notification";
import Widget from "../../../../../components/Widget/Widget";

import { SelectTypeHeadSingle } from "../../../../../components/GenericInputsFormik";
import { peticionesReceiver } from "../../../../../helpers/peticionesReceiver";

import FullFormLoader from "../../../../../components/Loader/FullFormLoader";

import Filtro from "./filtros/Filtro";

import ModalReports from "../../../../../components/Modal/ModalReports";
import TableComponent from "../../../../../components/TableComponent/TableComponentReport";

import { peticionEncript } from "../../../../../helpers/peticionesEncripted";

import { doRequest } from "../../../../../helpers/requests";

//configuraciones
import { setConfigR48 } from "../../../../../libs/reports/conac/report48";
import { setConfigR49 } from "../../../../../libs/reports/conac/report49";

//pdf
import exportPDFReport48 from "./pdf/pdfReport48";
import exportPDFReport49 from "./pdf/pdfReport49";

//excel
import { saveReport48 } from "./excel/excelRep48";
import { saveReport49 } from "./excel/excelRep49";

//html
import Report48 from "./html/Report48";
import { setConfigR51 } from "../../../../../libs/reports/conac/report51";
import { excelRep51 } from "./excel/excelRep51";
import pdfReport51 from "./pdf/pdfReport51";
import Report51 from "./html/Report51";
import { setConfigR50 } from "../../../../../libs/reports/conac/report50";
import Report50 from "./html/Report50";
import { saveReport50 } from "./excel/excelRep50";
import exportPDFReport50 from "./pdf/pdfReport50";
import Report52 from "./html/Report52";
import { setConfigR52 } from "../../../../../libs/reports/conac/report52";
import exportPDFReport52 from "./pdf/pdfReport52";
import { saveReport52 } from "./excel/excelRep52";
import { setConfigR53 } from "../../../../../libs/reports/conac/report53";
import Report53 from "./html/Report53";
import exportPDFReport53 from "./pdf/pdfReport53";
import { saveReport53 } from "./excel/excelRep53";
import { setConfigR54 } from "../../../../../libs/reports/conac/report54";
import Report54 from "./html/Report54";
import { saveReport54 } from "./excel/excelRep54";
import exportPDFReport54 from "./pdf/pdfReport54";
import Report49 from "./html/Report49";

export default function ReportesConac() {
  const [loader, setLoader] = useState(false);
  const API = peticionesReceiver();

  const history = useHistory();

  //Variables de modalPDF
  const [modalReports, setModalReports] = useState(false);

  //variables para cargar los selects
  const [typeReports, setTypeReports] = useState([]);

  const [defaultForm, setDefaultForm] = useState({
    idFormat: "",
    idReport: "",
    period: "",
    year: "",
    month: "",
    idStateFund: "",
    stateFund: "",
    detalle: "0",
  });

  /* Parametros para enviar a los endPoint */
  const [data, setData] = useState("");
  /* Variable donde se guarda la informacion del reporte */
  const [report, setReport] = useState();
  /**
   *  Variable para decidir tipo de formato
   */
  const [showReportFormat, setShowReportFormat] = useState("0");

  /**
   * Configuración del PDF
   */
  const [pdfConfig, setPdfConfig] = useState("");
  /**
   * Configuración del excel
   */
  const [excelConfig, setExcelConfig] = useState("");
  /**
   * Configuración del html
   */
  const [htmlConfig, setHtmlConfig] = useState("");
  /**
   * Cabeceras de los documentos
   */
  const [headers, setHeaders] = useState([]);

  /**
   * Titulo del documento
   */
  const [title, setTitle] = useState("");

  /**
   * Opciones disponibles para el select del formato
   */
  const format = [
    {
      id: 1,
      value: "PDF",
    },
    {
      id: 2,
      value: "Hoja de calculo",
    },
    {
      id: 3,
      value: "Html",
    },
  ];

  /**
   * Entra al momento de montar el componente de reportes
   */
  useEffect(() => {
    getTypeReports();
  }, []);

  /**
   * Consulta del reporte especificado
   * @param {*} params Objeto especificado del endpoint
   */
  const getReport = (params, route) => {
    API.peticionEndPoint(params, route)
      .then((res) => {
        if (res.status === 200 && res.data.code === "200") {
          const idReport = values.idReport.toString();
          if (route === "receiver/receiver.php") {
            if (res.data.data.length === 0) {
              toast(
                <Notification
                  type={"consultar_error"}
                  backMessage={res.data.message}
                  withIcon
                />,
                { closeButton: false }
              );
            } else {
              if (idReport === "48") {
                setReport(res.data);
              } else {
                setReport(res.data.data);
              }
            }
          } else {
            if (idReport === "48" || idReport === "50" || idReport === "51" || idReport === "52" || idReport === "53") {
              setReport(res.data);
            } else if (idReport === "49") {
              setReport({
                data: res.data.data,
                dataCapitulos: res.data.dataCapitulos,
                dataPartidas: res.data.dataPartidas,
              });
            } else {
              setReport(res.data.data);
            }
          }
        } else {
          toast(
            <Notification
              type={"consultar_error"}
              backMessage={res.data.message}
              withIcon
            />,
            { closeButton: false }
          );
        }
      })
      .finally(() => {
        setLoader(false);
      })
      .catch((err) => {
        console.log(err);
        toast(<Notification type={"consultar_servidor_error"} withIcon />, {
          closeButton: false,
        });
      });
  };

  /**
   * Consulta de los tipos de reportes disponibles
   */
  const getTypeReports = () => {
    const params = {
      action: "getReports",
      rows: {
        idModule: "180",
      },
    };
    API.peticionEndPoint(params, "app/facades/reports/reportsF.php")
      .then((res) => {
        if (res.status === 200 && res.data.status === "success") {
          setTypeReports(res.data.data);
        } else {
          toast(
            <Notification
              type={"consultar_error"}
              backMessage={res.data.message}
              withIcon
            />,
            { closeButton: false }
          );
        }
      })
      .catch(() => {
        toast(<Notification type={"consultar_servidor_error"} withIcon />, {
          closeButton: false,
        });
      });
  };

  /**
   * Establecimiento del form
   */
  const FormSchema = Yup.object().shape({
    idFormat: Yup.string().required("Seleccione un formato"),
    idReport: Yup.string().required("Seleccione un tipo de reporte"),
    period: Yup.number()
      .max(24, "Periodo debe ser menor a 25")
      .min(1, "Periodo debe ser mayor a 0")
      .when("idReport", {
        is: (val) => [].includes(val),
        then: Yup.number().required("Ingrese un periodo"),
      }),
    year: Yup.date()
      .max(setYear(new Date(), 3000), "Año invalido")
      .min(setYear(new Date(), 2020), "Año debe ser posterior al 2019")
      .when("idReport", {
        is: (val) => ["48", "50", "52", "53", "54"].includes(val),
        then: Yup.date().required("Ingrese un año"),
      }),
    month: Yup.number().when("idReport", {
      is: (val) => [].includes(val),
      then: Yup.number().required("Ingrese un mes"),
    }),
    idStateFund: Yup.string().when("idReport", {
      is: (val) => [].includes(val),
      then: Yup.string().required("Seleccione un fondo de estado"),
    }),
    stateFund: Yup.string(),
    detalle: Yup.string().when(["idReport", "idFormat"], {
      is: (idReport, idFormat) =>
        [].includes(idReport) && ["1", "2"].includes(idFormat),
      then: Yup.string().required("Seleccione el detalle"),
    }),
  });

  /**
   * Se envia inmediatamente despues del submit
   * @param {*} filter Valores del form
   */
  const getData = (filter) => {
    setReport(undefined);
    setTitle("");
    setShowReportFormat("0");

    //RECUPERAMOS Y HACEMOS QUE SEA CADENA
    const idReport = filter.idReport.toString();
    switch (idReport) {
      case "48":
        setTitle(`Iniciativa de Ley de Ingresos para el Ejercicio Fiscal ${(new Date(filter.year)).getFullYear()}`);
        setConfigR48({
          setHeaders,
          setPdfConfig,
          setExcelConfig,
          setHtmlConfig,
          setData,
          filter,
        });
        break;
      case "51":
        setTitle(
          `Iniciativa de Ley de Ingresos para el Ejercicio Fiscal ${new Date(
            filter.year
          ).getFullYear()}`
        );
        setConfigR51({
          setHeaders,
          setPdfConfig,
          setExcelConfig,
          setHtmlConfig,
          setData,
          filter,
        });
        break;
      case "50":
        setTitle(`Presupuesto de Egresos para el Ejercicio Fiscal ${(new Date(filter.year)).getFullYear()}`);
        setConfigR50({
          setHeaders,
          setPdfConfig,
          setExcelConfig,
          setHtmlConfig,
          setData,
          filter,
        })
        break;
      case "52":
        setTitle(`Presupuesto de Egresos para el Ejercicio Fiscal ${(new Date(filter.year)).getFullYear()}`);
        setConfigR52({
          setHeaders,
          setPdfConfig,
          setExcelConfig,
          setHtmlConfig,
          setData,
          filter,
        })
        break;
      case "53":
        setTitle(`${(new Date(filter.year)).getFullYear()}`);
        setConfigR53({
          setHeaders,
          setPdfConfig,
          setExcelConfig,
          setHtmlConfig,
          setData,
          filter,
        })
        break;
      case "54":
        setTitle(`${(new Date(filter.year)).getFullYear()}`);
        setConfigR54({
          setHeaders,
          setPdfConfig,
          setExcelConfig,
          setHtmlConfig,
          setData,
          filter,
    })
        break;
      case "49":
        setTitle(
          `Formato del Proyecto del Presupuesto de Egresos Armonizado: Clasificador por Objeto del Gasto ${new Date(
            filter.year
          ).getFullYear()}`
        );
        setConfigR49({
          setHeaders,
          setPdfConfig,
          setExcelConfig,
          setHtmlConfig,
          setData,
          filter,
        });
        break;
      default:
        break;
    }
  };

  /**
   * Formik const
   */
  const {
    handleSubmit,
    handleBlur,
    values,
    errors,
    touched,
    setFieldValue,
    resetForm,
  } = useFormik({
    initialValues: defaultForm,
    onSubmit: (values) => {
      getData(values);
    },
    validationSchema: FormSchema,
    enableReinitialize: true,
  });

  /**
   * RECUPERA INFORMACIÓN DE DATASTORAGE
   */
  useEffect(() => {
    const value = localStorage.getItem("reportesconac");
    if (value !== null) {
      const formLSto = JSON.parse(value);
      setFieldValue(["idFormat"], formLSto.idFormat);
      setFieldValue(["idReport"], formLSto.idReport);
      setTimeout(function () {
        setFieldValue(["period"], formLSto.period);
        if (formLSto.year !== "") {
          setFieldValue(["year"], new Date(formLSto.year));
        }
        setFieldValue(["month"], formLSto.month);
        setFieldValue(["idStateFund"], formLSto.idStateFund);
        setFieldValue(["stateFund"], formLSto.stateFund);
        setFieldValue(["detalle"], formLSto.detalle);
      }, 300);
      localStorage.removeItem("reportesNomina");
    }
  }, []);

  /**
   * Hook que detecta el cambio en data
   */
  useEffect(() => {
    if (data !== "") {
      const idFormat = values.idFormat.toString();
      if (idFormat === "1" || idFormat === "2") {
        setLoader(true);
        getReport(data, "app/facades/reports/reportsBudgetsF.php");
      }
      setShowReportFormat(idFormat);
      setModalReports(true);
    }
  }, [data]);

  /**
   * Hook que detecta el cambio en la variable report
   */
  useEffect(() => {
    if (report !== undefined && showReportFormat === "2") {
      setShowReportFormat("0");
      const idReport = values.idReport;
      switch (idReport) {
        case "48":
          saveReport48(report, title, excelConfig);
          break;
        case "50":
          saveReport50(report, title, excelConfig);
          break;
        case "51":
          excelRep51(report, excelConfig);
          break;
        case "52":
          saveReport52(report, title, excelConfig);
          break;
        case "53":
          saveReport53(report, title, excelConfig);
          break;
        case "54":
          saveReport54(report, title, excelConfig);
          break;
        case "49":
          saveReport49(report, title, excelConfig);
          break;
        default:
          break;
      }
    }
  }, [report]);

  /**
   * Con el valor del form decide el metodo del PDF a Generar
   * @returns Metodo a ocupar para generar PDF
   */
  function getPDFMethod() {
    switch (values.idReport) {
      case "48":
        return {
          method: exportPDFReport48,
          type: "Iniciativa de ley",
        };
      case "50":
        return {
          method: exportPDFReport50,
          type: "Iniciativa de ley",
        };
      case "51":
        return {
          method: pdfReport51,
          type: "Clasificación Funcional del Gasto"
        };
      case "52":
        return {
          method: exportPDFReport52,
          type: "Iniciativa de ley",
        };
      case "53":
        return {
          method: exportPDFReport53,
          type: "Iniciativa de ley",
        };
      case "54":
        return {
          method: exportPDFReport54,
          type: "Iniciativa de ley",
        };
      case "49":
        return {
          method: exportPDFReport49,
          type: "clasificador_objetos_gasto",
        };
      default:
        return null;
    }
  }

  const getSpecialColumns = (idReport) => {
    switch (idReport) {
      default:
        return [];
    }
  };
  /**
   * Si el param de showReport se encuentra en 1 o 3 y cumple con las condiciones
   * @returns Funcion que muestra el reporte de pdf o html
   */
  function showPDForHTML() {
    if (showReportFormat === "1" && report !== undefined && pdfConfig !== "") {
      return (
        <ModalReports
          modal={modalReports}
          setModal={setModalReports}
          title={title}
          backdrop={"static"}
          keyboard={false}
          report={report}
          pdfConfig={pdfConfig}
          generatePdfMethod={getPDFMethod()}
          records={1000}
        />
      );
    } else if (
      showReportFormat === "3" &&
      data !== "" &&
      title !== "" &&
      htmlConfig !== ""
    ) {
      const idReport = values.idReport.toString();
      if (idReport === "48") {
        return (
          <Report48
            title={title}
            params={data}
            htmlConfig={htmlConfig}
            route={"app/facades/reports/reportsBudgetsF.php"}
          />
        );
      } else if (idReport === "49") {

        return (
          <Report49
            title={title}
            params={data}
          htmlConfig={htmlConfig}
          route={"app/facades/reports/reportsBudgetsF.php"}
        />)
      }else if (idReport === "50") {
        return (<Report50
          title={title}
          params={data}
          htmlConfig={htmlConfig}
          route={"app/facades/reports/reportsBudgetsF.php"}
        />)
      }else if (idReport === "51") {
        return (
          <Report51
            params={data}
            cfg={htmlConfig}
            loadingPage={loader}
            setLoadingPage={setLoader}
          />
        );
      }else if (idReport === "52") {
      return (<Report52
        title={title}
        params={data}
        htmlConfig={htmlConfig}
        route={"app/facades/reports/reportsBudgetsF.php"}
      />)
      }else if (idReport === "53") {
        return (<Report53
          title={title}
          params={data}
          htmlConfig={htmlConfig}
          route={"app/facades/reports/reportsBudgetsF.php"}
        />)
      }else if (idReport === "54") {
        return (<Report54
          title={title}
          params={data}
          htmlConfig={htmlConfig}
            route={"app/facades/reports/reportsBudgetsF.php"}
          />
        );

        
      }else{
        return (
          <TableComponent
            titulo={title}
            cabecerasTabla={getCabeceras()}
            parametros={data}
            filtro={htmlConfig}
            reporte={idReport}
            specialColumns={getSpecialColumns(idReport)}
            route={"app/facades/reports/reportsBudgetsF.php"}
          />
        );
      }
    }
    return null;
  }

  function getCabeceras() {
    //const idReport = values.idReport.toString();
    return headers;
  }

  /**
   * Metodo que establece en el target el valor
   * @param {*} e evento de change
   */
  const onChange = (e) => {
    if (
      (e.target.value === "" || e.target.value === undefined) &&
      e.target.name === "period"
    ) {
      setFieldValue("year", "");
    }
    if (e.target.value === undefined) {
      setFieldValue([e.target.name], "");
    } else {
      setFieldValue([e.target.name], e.target.value);
    }
    setShowReportFormat("0");
    if (report !== "") setReport(undefined);
    if (data !== "") setData("");
  };

  const OnChangeSelect = (event) => {
    setFieldValue(event.target.name, event.target.value);
    setPdfConfig("")
    setExcelConfig("")
    setHtmlConfig("")
    setData("")
  };

  return (
    <>
      <Widget className="widget-p-md">
        <form onSubmit={handleSubmit} className="mt-4" noValidate>
          <div className="headline-1 text-center mb-4">CONAC</div>

          <Row className="d-flex justify-content-center">
            <Col xs={12} md={12} lg={8}>
              <SelectTypeHeadSingle
                label="Seleccione el reporte a generar"
                optionsArray={typeReports}
                inputName="idReport"
                onChangeMethod={OnChangeSelect}
                onBlurMethod={handleBlur}
                touched={touched.idReport}
                errors={errors.idReport}
                optionValue="idReport"
                optionName="nameReport"
                value={values.idReport}
                isRequired
                defaultOption="Seleccione un reporte"
              />
            </Col>
            <Col xs={12} md={12} lg={4}>
              <SelectTypeHeadSingle
                label="Seleccione un formato"
                optionsArray={format}
                inputName="idFormat"
                onChangeMethod={OnChangeSelect}
                onBlurMethod={handleBlur}
                touched={touched.idFormat}
                errors={errors.idFormat}
                optionValue="id"
                optionName="value"
                value={values.idFormat}
                isRequired
                defaultOption="Seleccione un formato"
              />
            </Col>
          </Row>
          <Filtro
            handleBlur={handleBlur}
            values={values}
            touched={touched}
            errors={errors}
            report={values.idReport}
            setFieldValue={setFieldValue}
            onChange={onChange}
          />
          <div className="text-center my-3">
            <Button color="success" type="submit">
              Generar
            </Button>
          </div>
        </form>

        <FullFormLoader
          show={loader}
          message="Consultando datos , espere un momento"
        />

        {showPDForHTML()}
      </Widget>
    </>
  );
}

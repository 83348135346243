import Widget from "../../../components/Widget/Widget";
import { Button, Col, Nav, NavItem, NavLink, Row, Spinner } from "reactstrap";
import { useEffect, useState } from "react";
import PersonalBuscar from "./BuscarEmpleado";
import Datos from "./Datos";

const st = {
  in: { fontSize: "19px", marginRight: "5px" },
  imr: { marginRight: "5px" },
  bC: { backgroundColor: "#cc0a44", border: "none", color: "#FFFFFF" },
  iB: { fontSize: "16px" },
};
const defaultEmployee = { id: 0 };

const TarjetaPresentacion = () => {
  const [activeTab, setActiveTab] = useState(1);
  const [employee, setEmployee] = useState({ ...defaultEmployee });

  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  useEffect(() => {}, []);

  useEffect(() => {
    if (employee.id !== 0) toggleTab(2);
    else toggleTab(1);
  }, [employee]);

  return (
    <Widget className="widget-p-md">
      <h3>Tarjeta de Presentación</h3>
      <Nav fill tabs style={{ overflowY: "hidden", overflowX: "auto" }}>
        <NavItem active={activeTab === 1}>
          <NavLink
            href="#"
            active={activeTab === 1}
            onClick={() => toggleTab(1)}
            disabled={employee.id > 0}
          >
            <i className={"fa fa-user"} style={st.in} />
            Buscar Empleado
          </NavLink>
        </NavItem>
        <NavItem active={activeTab === 2}>
          <NavLink
            href="#"
            active={activeTab === 2}
            onClick={() => toggleTab(2)}
            disabled={!employee.id > 0}
          >
            <i className={"fa fa-id-card-o"} style={st.in} />
            Datos de Empleado
          </NavLink>
        </NavItem>
      </Nav>
      <div className="mt-4">
        {employee.id !== 0 && (
          <Row className="mb-1 mx-2 border-bottom pb-4 pb-md-2">
            <Col xs={12} md={1} className="text-center">
              {employee.foto ?
                <img
                src={employee.foto}
                className="img-fluid border border-gray mb-3"
                alt="employe-picture"
                width="250" height="200"
              /> : <Spinner/>
            }
            </Col>
            <Col xs={12} md={4}>
              <h6 className="text-sm-left">Nombre: {employee.nombre}</h6>
              <h6 className="text-sm-left">
                Tipo de empleado: {employee.tipo}
              </h6>
              <h6 className="text-sm-left">
                Numero de empleado: {employee.numero}
              </h6>
            </Col>
            <Col xs={12} md={5}>
              <h6 className="text-sm-left">
                Unidad de trabajo:{" "}
                {employee.area !== "" ? employee.area : "Por asignar"}
              </h6>
              <h6 className="text-sm-left">
                Cargo:{" "}
                {employee.puesto !== "" ? employee.puesto : "Por asignar"}
              </h6>
              <h6 className="text-sm-left">
                Estatus:{" "}
                {employee.estatus !== "" ? employee.estatus : "Por asignar"}
              </h6>
            </Col>
            <Col xs={12} md={2} className="text-right">
              <Button
                className="btn px-2 py-2"
                style={st.bC}
                onClick={() => setEmployee({ ...defaultEmployee })}
              >
                Cerrar
                <i className="fa fa-sign-out ml-2" style={st.iB} />
              </Button>
            </Col>
          </Row>
        )}

        {activeTab === 1 ? (
          <PersonalBuscar setEmployee={setEmployee} />
        ) : (
          <Datos employee={employee}  setEmployee={setEmployee}/>
        )}
      </div>
    </Widget>
  );
};

export default TarjetaPresentacion;

import { useEffect, useRef, useState } from "react";
import { Button, Col, Collapse, Container, Row } from "reactstrap";
import { doRequest } from "../../../helpers/requests";
import { fDate, fMoney, validValue } from "../../../libs/tools/format";
import FullFormLoader from "../../../components/Loader/FullFormLoader";
import { SelectTypeHeadSingle } from "../../../components/GenericInputsFormik";
import { useFormik } from "formik";
import * as Yup from "yup";
import pdfTarjetaPr from "./pdfTarjetaPr";
import ModalReports from "../../../components/Modal/ModalReports";

const types = [
  { name: "HTML", value: "1" },
  { name: "PDF", value: "2" },
];

const s = {
  border: "2px solid #dee2e6",
  borderLeft: "4px solid #730A29",
  borderRadius: "5px",
};

const fmtD = new Intl.DateTimeFormat("es-MX", {
  day: "2-digit",
  month: "long",
  year: "numeric",
  timeZone: "UTC",
});

const defaultForm = {
  format: "",
};

const FormSchema = Yup.object().shape({
  format: Yup.string().required("Seleccione un tipo de formato"),
});

export const Datos = ({ employee, setEmployee }) => {
  const [loading, setLoading] = useState(true);
  const [loadingImg, setLoadingImg] = useState(true);
  const [showHTML, setShowHTML] = useState(false);//HTML
  const [showPDF, setShowPDF] = useState(false);//PDF
  const [showReport, setShowReport] = useState(false);
  const [infoStaff, setInfoStaff] = useState({});
  const [infoReport, setInfoReport] = useState({});
  const refScroll = useRef(null);

  const {
    values,
    handleBlur,
    errors,
    touched,
    handleSubmit,
    handleReset,
    setFieldValue,
  } = useFormik({
    initialValues: defaultForm,
    validationSchema: FormSchema,
    onSubmit: (values) => setShowReport(true),
    onReset: () => setShowReport(false),
    enableReinitialize: true,
  });

  useEffect(() => {
    getInfoStaff().then(()=>{getInfoReport()});
  }, []);

  async function getInfoStaff() {
    const params = {
      action: "getStaffData",
      idStaff: employee.id,
    };
    const data = await doRequest(
      "app/facades/reports/reportsF.php",
      params,
      false
    );
    if (data.length > 0) {
      setInfoStaff(data[0]);
      setEmployee({ ...employee, foto: data[0].src });
    }
    setLoading(false);
  }

  async function getInfoReport() {
    const params = {
      action: "getTarjetaPresentacion",
      idStaff: employee.id,
    };
    const data = await doRequest(
      "app/facades/reports/reportsF.php",
      params,
      false
    );
    if (data.length > 0) {
      const clrData = {
        ...data[0],
        fechaIngresoantiguedad: clearAntiguedad(data[0].fechaIngresoantiguedad),
        domicilio: makeDomicilio(data[0]),
        foto: infoStaff.src
      };
      setInfoReport(clrData);
      setShowHTML(values.format);
    }
  }

  const onChange = (e) => {
    if(showReport) setShowReport(false);
    setFieldValue(e.target.name, e.target.value);
  }

  // const showReportFormat = () => {
    
  // }

  const fDateLetra = (d) => {
    if (!validValue(d)) return d;
    return fmtD.format(new Date(d)).replaceAll(" de ", "/");
  };

  const makeDomicilio = (d) => {
    let dom = `${d.street}`;
    dom += isNaN(+d.extNumber) ? ` ${d.extNumber}` : ` #${d.extNumber}`;
    dom += d.intNumber !== "" && ` int. ${d.intNumber}`;
    dom += ` ${d.subStreet} ${d.nameCity}`;
    dom += ` ${d.nameCounty} ${d.nameState}`;
    return dom;
  };

  const clearAntiguedad = (d) => {
    let antC = d.replace("&ntilde;", "ñ");
    antC = antC.replace("&iacute;", "í");
    return antC;
  };

  function scrollTo() {
    refScroll.current.scrollIntoView({ behavior: "smooth" });
  }

  return (
    <div className="my-4 mx-4">
      <div className="mt-4 mb-3 d-flex flex-row">
        <i
          className="fa fa-minus-circle mr-1"
          style={{ fontSize: "15px", marginTop: "4.5px" }}
        />
        <h5>Datos del Empleado</h5>
      </div>

      <Row>
        <Col xs={12} lg={3} className="text-center">
          <img
            src={infoStaff.src}
            className="img-thumbnail"
            alt="employe-picture"
            width="300"
            height="240"
            loading="lazy"
          />
        </Col>
        <Col xs={12} lg={9}>
          <Container className="border border-right-0 text-dark mt-3 mt-lg-0">
            <Row className="bg-primary">
              <Col
                xs={12}
                className="border-bottom border-right py-2 text-light"
              >
                <strong>
                  <p>
                    {infoStaff.staffNumber} - {infoStaff.name}
                  </p>
                </strong>
              </Col>
            </Row>
            <Row>
              <Col xs={12} lg={8} className="border-bottom border-right px-2">
                <strong>Adscripción:</strong>{" "}
                <p>{infoStaff.nameArea}</p>
              </Col>
              <Col xs={12} lg={4} className="border-bottom border-right px-2">
              <strong>Región:</strong>{" "}
                <p>{infoStaff.nameRegionArea}</p>
              </Col>
            </Row>
            <Row>
              <Col xs={12} lg={4} className="border-bottom border-right px-2">
                <strong>Puesto laboral: </strong>
                <p>{infoStaff.nameJob}</p>
              </Col>
              <Col xs={12} lg={4} className="border-bottom border-right px-2">
                <strong>Nivel-Rango: </strong>
                <p>{infoStaff.levelrange}</p>
              </Col>
              <Col xs={12} lg={4} className="border-bottom border-right px-2">
                <strong>Tipo nombramiento: </strong>
                <p>{infoStaff.nameAssignment}</p>
              </Col>
            </Row>
            <Row>
              <Col xs={12} lg={4} className="border-bottom border-right px-2">
                <strong>Fecha ingreso: </strong>
                <p>{fDate(infoStaff.startJobDate)}</p>
              </Col>
              <Col xs={12} lg={4} className="border-bottom border-right px-2">
                <strong>CURP: </strong>
                <p>{infoStaff.curp}</p>
              </Col>
              <Col xs={12} lg={4} className="border-bottom border-right px-2">
                <strong>RFC: </strong>
                <p>{infoStaff.rfc}</p>
              </Col>
            </Row>
            <Row>
              <Col xs={12} lg={6} className="border-bottom border-right px-2">
                <strong>Fecha ingreso Issemym: </strong>
                <p>{infoStaff.SecurityKeyStartDate}</p>
              </Col>
              <Col xs={12} lg={6} className="border-bottom border-right px-2">
                <strong>Clave Issemym: </strong>
                <p>{infoStaff.socialSecurityKey}</p>
              </Col>
            </Row>
            <Row>
              <Col xs={12} lg={4} className="border-bottom border-right px-2">
                <strong>Banco: </strong>
                <p>{infoStaff.nameBank}</p>
              </Col>
              <Col xs={12} lg={4} className="border-bottom border-right px-2">
                <strong>Cuenta: </strong>
                <p>{infoStaff.cuenta}</p>
              </Col>
              <Col xs={12} lg={4} className="border-bottom border-right px-2">
                <strong>Clave: </strong>
                <p>{infoStaff.clabe}</p>
              </Col>
            </Row>
            <Row>
              <Col xs={12} className="border-right px-2">
                <strong>Estatus: </strong>
                <p>{infoStaff.nameStatStaff}</p>
              </Col>
            </Row>
          </Container>
        </Col>
      </Row>

      <form className="my-4" onSubmit={handleSubmit} onReset={handleReset}>
        <Row>
          <Col xs={12} md={6}>
            <SelectTypeHeadSingle
              label="Tipo de formato"
              inputName="format"
              optionsArray={types}
              defaultOption="Seleccione un tipo de formato"
              onChangeMethod={onChange}
              onBlur={handleBlur}
              value={values.format}
              touched={touched.format}
              errors={errors.format}
              optionValue="value"
              optionName="name"
              isRequired
            />
          </Col>
          <Col xs={12} md={6} className="px-0 text-center text-md-left pt-md-4">
            <Button color="primary" type="submit" className="mx-2 mt-1">
              Consultar
            </Button>
            <Button type="reset" className="mx-2 mt-1">
              Limpiar
            </Button>
          </Col>
        </Row>
      </form>

      {+values.format === 1 && <Collapse
        isOpen={showReport}
        onEntered={() => scrollTo()}
        className="border-top"
      >
        <div className="mt-4 p-3 text-dark" style={s} ref={refScroll}>
          <p style={{ fontSize: "22px" }}>{infoReport.nombreCompleto}</p>
        </div>
        <div className="my-4 text-dark">
          <p style={{ fontSize: "19px" }}>Antecedente:</p>
        </div>
        <Row className="px-3">
          <Col xs={12} lg={6} className="px-0">
            <table className="table-bordered table-striped w-100 ">
              <tbody>
                <tr>
                  <td className="w-50 p-1 text-dark border-right">
                    Fecha de Ingreso al FGJ
                  </td>
                  <td className="w-50 p-1 text-dark">
                    {infoReport.fechaIngresoLetra}
                  </td>
                </tr>
                <tr>
                  <td className="w-50 p-1 text-dark border-right">
                    Escolaridad
                  </td>
                  <td className="w-50 p-1 text-dark">
                    {infoReport.desCarrera}
                  </td>
                </tr>
                <tr>
                  <td className="w-50 p-1 text-dark border-right">
                    Fecha de Nacimiento
                  </td>
                  <td className="w-50 p-1 text-dark">
                    {infoReport.fechaNacimientoLetra}
                  </td>
                </tr>
                <tr>
                  <td className="w-50 p-1 text-dark border-right">Edad</td>
                  <td className="w-50 p-1 text-dark">{infoReport.edad}</td>
                </tr>
                <tr>
                  <td className="w-50 p-1 text-dark border-right">
                    Unidad de Adscripción
                  </td>
                  <td className="w-50 p-1 text-dark">{infoReport.nameArea}</td>
                </tr>
                <tr>
                  <td className="w-50 p-1 text-dark border-right">
                    Puesto / Nivel y Rango
                  </td>
                  <td className="w-50 p-1 text-dark">{infoReport.nameJob}</td>
                </tr>
                <tr>
                  <td className="w-50 p-1 text-dark border-right">
                    Antiguedad laboral
                  </td>
                  <td className="w-50 p-1 text-dark">
                    {infoReport.fechaIngresoantiguedad}
                  </td>
                </tr>
                <tr>
                  <td className="w-50 p-1 text-dark border-right">
                    Horario oficial de labores
                  </td>
                  <td className="w-50 p-1 text-dark">{"NO EXISTE"}</td>
                </tr>
                <tr>
                  <td className="w-50 p-1 text-dark border-right">
                    Domicilio Particular
                  </td>
                  <td className="w-50 p-1 text-dark">{infoReport.domicilio}</td>
                </tr>
              </tbody>
            </table>
          </Col>
          <Col xs={12} lg={6} className="px-0 border text-dark">
            <div
              className="p-1 bg-middle-gray text-center"
              style={{ fontSize: "18px" }}
            >
              HISTORICO LABORAL
            </div>
            <ul
              className="text-justify list-group ml-4 pl-2 mr-3"
              style={{ listStyle: "initial" }}
            >
              {/*listStyleType: "square" */}
              {infoReport.historicoLaboral &&
                infoReport.historicoLaboral.map((el, ix) => (
                  <li key={"hl" + ix}>
                    <strong>{el.nameMove}</strong> a{" "}
                    <strong>{el.nameJob}</strong> a partir de{" "}
                    <strong>{fDateLetra(el.startDate)}</strong> a la fecha{" "}
                    <strong>{fDateLetra(el.endDate)}</strong>, nivel salarial{" "}
                    <strong>{el.nivelRango}</strong> adscrito(a) a{" "}
                    <strong>{el.nameArea}</strong>
                  </li>
                ))}
            </ul>
          </Col>
        </Row>
        <div className="mt-5 d-flex justify-content-center">
          <table className="table-bordered">
            <tbody>
              <tr>
                <td className="p-1" style={{ minWidth: "200px" }}></td>
                <td
                  className="text-center p-1 text-dark"
                  style={{ minWidth: "150px", maxWidth: "350px" }}
                >
                  <p>Percepciones Mensuales Actuales</p>
                  <p className="text-danger">{infoReport.nameJob}</p>
                </td>
              </tr>
              <tr className=" text-right">
                <td className="p-1 text-dark">Sueldo base</td>
                <td className="p-1 text-dark">{fMoney(infoReport.base)}</td>
              </tr>
              <tr className=" text-right">
                <td className="p-1 text-dark">Gratificación</td>
                <td className="p-1 text-dark">{fMoney(infoReport.reward)}</td>
              </tr>
              <tr className=" text-right">
                <td className="p-1 text-dark">Despensa</td>
                <td className="p-1 text-dark">{fMoney(infoReport.larder)}</td>
              </tr>
              <tr className=" font-weight-bold text-right">
                <td className="p-1 text-dark">Total bruto</td>
                <td className="p-1 text-dark">{fMoney(infoReport.gross)}</td>
              </tr>
              <tr className=" font-weight-bold text-right">
                <td className="p-1 text-dark">Total neto</td>
                <td className="p-1 text-dark">{fMoney(infoReport.net)}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </Collapse>}

      {+values.format === 2 && <ModalReports
        modal={showReport}
        setModal={setShowReport}
        title="Tarjeta de Presentación"
        backdrop={"static"}
        report={infoReport}
        pdfConfig={{}}
        generatePdfMethod={{
          method: pdfTarjetaPr,
          type: "cardIntr",
        }}
        keyboard
      />}

      <FullFormLoader show={loading} />
    </div>
  );
};
export default Datos;

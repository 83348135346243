const formatter = new Intl.NumberFormat("es-MX", {
  style: "currency",
  currency: "MXN",
});

export function formatCell(elm, idCol, idRow, filtro, sortCol, header) {
  let texto = elm;
  if (filtro) {
    if (sortCol) {
      if (idCol === 0) {
        /// Primera columna a numerador
        return (
          <td className="text-center" key={"C" + idCol}>
            {5 * sortCol.currentPage + (idRow + 1)}
          </td>
        );
      }
    }

    var f = /^\d{4}[-\/](0[1-9]|1[0-2])[-\/](0[1-9]|1[0-9]|2[0-9]|3[0-1])$/g; /// Fecha simple
    var ft =
      /^\d{4}[-\/](0[1-9]|1[0-2])[-\/](0[1-9]|1[0-9]|2[0-9]|3[0-1])(\s(0[0-9]|1[0-9]|2[0-3]):([0-5][0-9]):([0-5][0-9]))?$/g; /// Fecha con tiempo
    var m = /^\d+\.\d{2}$/g; /// Moneda
    if (ft.test(elm)) {
      texto = elm
        .split(" ")[0]
        .split("-")
        .reverse()
        .toString()
        .replaceAll(",", "/");
    } else if (f.test(elm)) {
      texto = elm.split("-").reverse().toString().replaceAll(",", "/");
    } else if (m.test(elm)) {
      if (header === "Porcentaje") {
        texto = texto + "%";
      } else {
        texto = formatter.format(elm);
      }
    }
    return (
      <td
        className="text-center"
        key={"C" + idCol}
        style={{ whiteSpace: "pre-line" }}
      >
        {texto}
      </td>
    );
  }

  return null;
}

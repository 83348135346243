import { useEffect, useState } from "react";
import { SelectTypeHeadSingle } from "../../../../components/GenericInputsFormik";
import { toast } from "react-toastify";
import Notification from "../../../../components/Notification/Notification";
import { peticionesReceiver } from "../../../../helpers/peticionesReceiver";

export default function Proveedor({ idDetail,indexRecepcion,  arrayRecepcionCompras, setArrayRecepcionCompras }) {
    const API = peticionesReceiver()
    const [prov, setProv] = useState([]);
    

    async function getAdjudicacion() {
        try {
            const res = await API.peticion({
                action: "multiselect",
                rows: "CONCAT(itin,' - ',socialName) as name,idProvider,winner,idProviderQuote",
                table: "providerquotes inner join providers using(idProvider)",
                conditions: `idDetail=${idDetail}`
            });
            if (res.status === 200 && res.data.code === "200") {
                setProv(res.data.data)
            } else {
                toast(<Notification type={"consultar_error"} backMessage={res.data.message} />);
            }
        } catch (err) {
            toast(<Notification type={"consultar_servidor_error"} withIcon />);
        }
    }

    useEffect(() => {
        getAdjudicacion()

    }, [])

    
    const onChange = (e) => {
        const { name, value } = e.target
        let tmpList = arrayRecepcionCompras.slice(0, arrayRecepcionCompras.length);
         tmpList[indexRecepcion].provedor =  value
         setArrayRecepcionCompras(tmpList)
    }

    return (
        <div>
            <SelectTypeHeadSingle
                optionsArray={prov}
                optionName={"name"}
                optionValue={"idProviderQuote"}
                inputName={`provider${idDetail}`}
                /* id={`provider${idDetail}`} */
                onChangeMethod={onChange}
                value={arrayRecepcionCompras[indexRecepcion].provedor}
                /* isRequired
                touched={touched[`provider${idDetail}`]}
                errors={errors[`provider${idDetail}`]} */
            />
        </div>
    )
}
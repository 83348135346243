
import React from "react";

import Widget from "../../../../components/Widget/Widget";
import FormSalario from "../FormSalario";
import { updated } from "../functions";

export const EditarSalario = (props) => {
  return (
    <Widget className="widget-p-md">
      <h3>Editar Salario</h3>
      <FormSalario data={localStorage.getItem('dataContent').split('|-|')} editar={updated}/>
    </Widget>
  )
}

export default EditarSalario;
import React, { useRef, useState } from "react";
import * as Yup from "yup";
import FullScreenLoader from "../../../components/Loader/FullScreenLoader";
import { useFormik } from "formik";
import { FilesInput, TextInput } from "../../../components/GenericInputsFormik";
import { Button, Col, Container, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import ModalInformation from "./ModalInformation";
import { formikImageFile2Image64 } from "../../../libs/tools/images";
import { validarRFC } from "../../../libs/personalData/RFC";

import { peticionesReceiver } from "../../../helpers/peticionesReceiver";
import { toast } from "react-toastify";
import Notification from "../../../components/Notification/Notification";
import {
  validateCurps,
  validateFace,
  validateNames,
  validateSituacion,
} from "./validaciones";
import Camera from "../../../components/Modal/ModalCamera";

const defaultForm = {
  front: "",
  frontimg64: "",
  back: "",
  backimg64: "",
  rfc: "",
  cif: "",
  captura: "",
  descriptor: ""
};

Yup.addMethod(Yup.string, "rfc", function (errorMessage) {
  return this.test("rfc_valid", errorMessage, function (value) {
    const { path, createError } = this;
    return (
      (value && validarRFC(value)) ||
      createError({ path, message: errorMessage })
    );
  });
});

const FormSchema = Yup.object().shape({
  front: Yup.mixed()
    .required("Agregue un documento")
    .test("file-type", "El documento debe ser en formato PDF", (value) => {
      return value && ["image/jpg", "image/jpeg"].includes(value.type);
    })
    .test({
      message: `El documento debe pesar menos de 4MB`,
      test: (value) => value?.size < 4000000,
    }),
  back: Yup.mixed()
    .required("Agregue un documento")
    .test("file-type", "El documento debe ser en formato PDF", (value) => {
      return value && ["image/jpg", "image/jpeg"].includes(value.type);
    })
    .test({
      message: `El documento debe pesar menos de 4MB`,
      test: (value) => value?.size < 4000000,
    }),
  rfc: Yup.string()
    .required("El campo es obligatorio")
    .rfc("Ingrese un RFC valido"),
  cif: Yup.string().required("El campo es obligatorio"),
  captura: Yup.string()
    .required("Se necesita capturar una fotografia del individuo")
    .nullable(),
});

export default function ValidarInformacion({
  setStaffValid,
  setActiveTab,
  setUpload,
}) {
  const [loading, setLoading] = useState(false);
  const [modaInfo, setModaInfo] = useState(false);
  const [info, setInfo] = useState("");
  const [visibleSelfie, setVisibleSelfie] = useState(false);
  const [message, setMessage] = useState("");
  const [modalError, setModalError] = useState(false);
  const [errorsData, setErrorsData] = useState([]);

  const imgFront = useRef();
  const imgRev = useRef();

  const {
    handleSubmit,
    values,
    handleBlur,
    errors,
    touched,
    setFieldValue,
    handleReset,
  } = useFormik({
    initialValues: defaultForm,
    validationSchema: FormSchema,
    onSubmit: (rows) => {
      getInfo(rows);
    },
    enableReinitialize: true,
  });

  const onChange = (e) => {
    setFieldValue(e.target.name, e.target.files[0]); /// base64
    formikImageFile2Image64(
      e.target.files[0],
      setFieldValue,
      e.target.name + "img64"
    );
  };

  const onChangeInput = (e) => {
    setFieldValue(e.target.name, e.target.value);
  };

  async function getInfo(rows) {
    setLoading(true);
    setMessage("Consultando información, por favor espere");
    const data = {
      ineFrente: rows.frontimg64,
      ineReverso: rows.backimg64,
      selfie: rows.captura.substring(rows.captura.indexOf(",") + 1),
      rfc: rows.rfc,
      cif: rows.cif,
    };
    try {
      const info = await getData(data);
      if (Object.values(info).length > 0) {
        let errores = [];
        // if (!validateSituacion(info.SAT.datosIdentificacion)) {
        //   errores.push("La persona no se ecuentra activa en el SAT");
        // }
        if (!validateFace(info.FACECOMPARISON)) {
          errores.push(
            "La persona en la fotografía no coincide con la de la identificación"
          );
        }
        // if (!validateNames(info)) {
        //   errores.push(
        //     "El nombre de la persona no coinciden en las distintas instituciones"
        //   );
        // }
        // if (!validateCurps(info)) {
        //   errores.push(
        //     "El CURP de la persona no coinciden en las distintas instituciones"
        //   );
        // }
        if (errores.length > 0) {
          setErrorsData(errores);
          setModalError(true);
        } else {
          info.descriptor = values.descriptor;
          info.captura = values.captura;
          setInfo(info);
          setModaInfo(true);
        }
      }
    } catch (err) {
      toast(
        <Notification type={"consultar_error"} withIcon backMessage={err} />
      );
    }
    setLoading(false);
  }

  async function getData(rows) {
    const API = peticionesReceiver();
    try {
      const params = {
        action: "validatePerson",
        ...rows,
      };
      const res = await API.peticionEndPoint(
        params,
        "app/facades/identity/identityF.php"
      );
      if (res.status === 200 && res.data.code === "200") {
        return res.data.data;
      } else {
        throw new Error(res.data.message);
      }
    } catch (err) {
      throw new Error(err);
    }
  }

  return (
    <div>
      <h3>Validar Documentación</h3>
      <p className="text-muted">
        Cargue fotografias de la identificación por ambos lados
      </p>
      <form onSubmit={handleSubmit} onReset={handleReset} className="mt-2">
        <Row>
          <Col xs={12} lg={6}>
            <FilesInput
              label="Frente"
              inputName="front"
              onChangeMethod={onChange}
              onBlurMethod={handleBlur}
              fileAccept="image/jpg, image/jpeg"
              isRequired
              touched={touched.front}
              errors={errors.front}
              basicReq={false}
              refInput={imgFront}
            />
          </Col>
          <Col xs={12} lg={6}>
            <FilesInput
              label="Reverso"
              inputName="back"
              onChangeMethod={onChange}
              onBlurMethod={handleBlur}
              fileAccept="image/jpg, image/jpeg"
              isRequired
              touched={touched.back}
              errors={errors.back}
              basicReq={false}
              refInput={imgRev}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={6}>
            <TextInput
              value={values.rfc}
              errors={errors.rfc}
              inputName={"rfc"}
              inputType={"text"}
              label={"RFC"}
              onBlurMethod={handleBlur}
              isRequired
              touched={touched.rfc}
              onChangeMethod={onChangeInput}
            />
          </Col>
          <Col xs={12} md={6}>
            <TextInput
              value={values.cif}
              errors={errors.cif}
              inputName={"cif"}
              inputType={"text"}
              label={"CIF"}
              onBlurMethod={handleBlur}
              isRequired
              touched={touched.cif}
              onChangeMethod={onChangeInput}
            />
          </Col>
        </Row>
        <Row className="d-flex justify-content-center">
          <Col xs={12} md={6}>
            {values.captura !== "" ? (
              <>
                <div className="text-center">
                  <img
                    src={values.captura}
                    className="mt-3 w-50"
                    alt="selfie"
                  />
                </div>
                <div className="text-right mt-2">
                  <Button
                    color="danger"
                    onClick={() => setFieldValue("captura", "")}
                  >
                    Eliminar fotografia
                  </Button>
                </div>
              </>
            ) : (
              <div
                className="w-100 bg-white border text-center align-middle mt-2"
                style={{ height: "300px", lineHeight: "300px" }}
              >
                Sin imagen
              </div>
            )}
            {errors.captura && (
              <span className="text-danger">{errors.captura}</span>
            )}
          </Col>
        </Row>
        <div className="text-center my-2">
          <Button color="warning" onClick={() => setVisibleSelfie(true)}>
            Capturar fotografia
          </Button>
        </div>
        <div className="text-center my-2">
          <Button type="submit">Consultar Información</Button>
        </div>
      </form>
      <ModalInformation
        modal={modaInfo}
        setModal={setModaInfo}
        info={info}
        setStaffValid={setStaffValid}
        setUpload={setUpload}
        setActiveTab={setActiveTab}
      />
      <Camera
        setFieldValue={setFieldValue}
        setVisible={setVisibleSelfie}
        visible={visibleSelfie}
      />
      <ModalError
        modal={modalError}
        setModal={setModalError}
        errors={errorsData}
      />
      <FullScreenLoader show={loading} message={message} />
    </div>
  );
}

function ModalError({ modal, setModal, errors }) {
  const toggle = () => setModal(!modal);
  const CloseBtn = (
    <i className="eva eva-close cursor-pointer text-light" onClick={toggle} />
  );
  return (
    <Modal isOpen={modal} toggle={toggle} size="lg" style={{ minWidth: "80%" }} centered>
      <ModalHeader
        toggle={toggle}
        close={CloseBtn}
        className="headline-1 bg-primary d-flex justify-content-between"
      >
        <div className=" text-center text-light">
          <p>VALIDACIÓN DE DATOS PERSONALES</p>
          <p>FISCALÍA GENERAL DE JUSTICIA</p>
        </div>
      </ModalHeader>
      <ModalBody>
        <Container className="my-2">
          <div className="">
            <ul className="list-group">
              {errors.length > 0 &&
                errors.map((el, ix) => (
                  <li className="list-group-item text-dark" key={"le" + ix}>
                    <h6>{el}</h6>
                  </li>
                ))}
            </ul>
          </div>
        </Container>
      </ModalBody>
    </Modal>
  );
}

import Widget from "../../../components/Widget/Widget";
import Datatable from "../../../components/Datatable/Datatable";
import { GetPermissions } from "../../../libs/permissions/getPermissions";
import { useState } from "react";
import { toast } from "react-toastify";
import Notification from "../../../components/Notification/Notification";
import ModalViewFile from "../../../components/Modal/ModalViewFile";
import { peticionesReceiver } from "../../../helpers/peticionesReceiver";
import { Collapse } from "reactstrap";
import Receptions from "./views/Receptions";
import { useSelector } from "react-redux";
import { Button, Row, Col } from "reactstrap";
import Entradas from "./views/Entradas";
import { Loader } from "react-bootstrap-typeahead";

export default function RecepcionMercancia() {
  const [permissions, setPermissions] = useState(true);
  const [refresh, setRefresh] = useState(false);
  const [loading, setLoading] = useState(true);

  const [reception, setReception] = useState(false);

  const [collapse, setCollapse] = useState("purchases"); //purchases, receptions, warehouse

  const { profileType, id } = useSelector((state) => state.userInfo);

  const idArea = profileType.selectProfile.idArea;
  const [modalFile, setModalFile] = useState(false);
  const [linkFile, setLinkFile] = useState("");

  GetPermissions(setPermissions, setLoading);
  const API = peticionesReceiver();

  const headers = [
    "#",
    "Folio",
    "Tipo de compra",
    "Provedor",
    "Compra",
    "Inicio de recepcion",
    "Fin de recepcion",
    "Documento de adquisición",
    "Ver recepciones",
    "Recibir articulos",
  ];

  const columns = [
    { data: "index" },
    { data: "numberRequest" },
    { data: "nameTypePurchase" },
    { data: "socialName" },
    { data: "paymentDetail" },
    { data: "startDate", format: "date" },
    { data: "endDate", format: "date" },
    {
      data: null,
      render: function (row) {
        return (
          <i
            className="fa fa-file-pdf-o text-danger cursor-pointer"
            onClick={() => {
              getFile(row.idPurchaseRequest);
            }}
          />
        );
      },
    },
    {
      data: null,
      render: function (row) {
        return (
          <div className="text-center">
            <i
              className={" fa fa-eye text-info cursor-pointer "}
              onClick={() => {
                showEntries(row);
              }}
            ></i>
          </div>
        );
      },
    },
    {
      data: null,
      render: function (row) {
        return (
          <div className="text-center">
            <i
              className={"fa fa-arrow-right text-info cursor-pointer "}
              onClick={() => {
                showReceptions(row);
              }}
            ></i>
          </div>
        );
      },
    },
  ];

  const showEntries = (row) => {
    if (collapse !== "purchases") {
      setReception("");
      setCollapse("purchases");
    } else {
      setReception(row);
      setCollapse("warehouse");
    }
  };

  const showReceptions = (row) => {
    if (collapse !== "purchases") {
      setReception("");
      setCollapse("purchases");
    } else {
      setReception(row);
      setCollapse("receptions");
    }
  };

  const columnDefs = [
    {
      targets: [0, 1, 2, 3, 4, 5, 6, 7, 8],
      className: "text-center",
    },
    {
      orderable: false,
      targets: [0],
    },
  ];

  const getParamsTableElmt = (order, records, page, search) => {
    const params = {
      action: "datatable",
      table:
        "receptions A LEFT JOIN purchasewinners B  ON (A.idWinner = B.idWinner) LEFT JOIN purchaserequests C on (B.idPurchaseRequest = C.idPurchaseRequest) INNER JOIN providerquotes D on (B.idProviderQuote = D.idProviderQuote) INNER JOIN providers E on (D.idProvider= E.idProvider) INNER JOIN types_purchases F ON (C.keyTypePurchase = F.keyTypePurchase) LEFT JOIN quotesdetail G ON (G.idDetail = D.idDetail) ",
      rows: "C.idPurchaseRequest,C.numberRequest,D.idProvider,E.socialName,F.nameTypePurchase,A.startDate,A.endDate,D.paymentDetail,E.itin",
      conditions: `A.enabled = 1 and B.enabled = 1 and C.enabled = 1 and  A.idArea = ${idArea} and G.idFurniture IS NOT NULL`,
      group: "C.idPurchaseRequest",
      order,
      records: records.toString(),
      page: (page * records).toString(),
      search,
    };
    return params;
  };

  async function getData(order, records, page, search) {
    try {
      const res = await API.peticion(
        getParamsTableElmt(order, records, page, search)
      );
      if (res.status === 200 && res.data.code === "200") {
        const data = res.data;

        data.data.forEach((value, index) => (value.index = 1 + index));

        return data;
      } else {
        toast(
          <Notification
            type={"consultar_error"}
            backMessage={res.data.message}
          />
        );
        return null;
      }
    } catch (err) {
      toast(<Notification type={"consultar_servidor_error"} withIcon />);
      return null;
    }
  }

  const getFile = async (row) => {
    const paramsFiles = {
      action: "multiselect",
      table:
        "purchaserequests pr inner join types_purchases tp on(pr.keyTypePurchase = tp.keyTypePurchase) inner join types_purchasedocs tpd on (tpd.keyTypePurchase = tp.keyTypePurchase) inner join purchasedocs pd on (tpd.keyPurchaseDoc = pd.keyPurchaseDoc) inner join purchasefiles pf on (pf.keyTypePurchaseDoc = tpd.keyTypePurchaseDoc) inner join bookcase b on (b.reference = pf.idFile AND keyFileType = 58) inner join files f on (f.idBook = b.idBook)",
      rows: "pd.namePurchaseDoc,f.created,pf.idFile as pfile,f.idFile,f.src",
      conditions: `pf.enabled=1 AND f.enabled = 1 AND pr.idPurchaseRequest=${row}`,
    };

    const API = peticionesReceiver();
    try {
      const res = await API.peticion(paramsFiles);
      if (res.status === 200 && res.data.code === "200") {
        const data = res.data.data;
        if (data.length > 0) {
          setLinkFile(res.data.data[0].src);
          setModalFile(true);
        } else {
          toast(<Notification type={"consultar_error"} withIcon />);
        }
      } else {
        toast(
          <Notification
            type={"consultar_error"}
            backMessage={res.data.message}
          />
        );
      }
    } catch (err) {
      toast(<Notification type={"consultar_error"} withIcon />);
    }
  };

  return (
    <Widget className="widget-p-md">
      {loading ? (
        <div className="text-center">
          <Loader />
        </div>
      ) : (
        <>
          <Row>
            <Col className="text-center">
              <h1> Recepción de mercancia</h1>
            </Col>
          </Row>

          {!Loader ? (
            <div className="text-center">
              <Loader />
            </div>
          ) : (
            <>
              {permissions.CON ? (
                <>
                  {collapse !== "purchases" && (
                    <Row className="mb-3">
                      <Col className="text-right mb-3">
                        <Button
                          onClick={() => {
                            showReceptions();
                          }}
                          color={
                            collapse === "purchases" ? "success" : "danger"
                          }
                        >
                          {collapse === "purchases"
                            ? "Recepciones"
                            : "Regresar"}
                        </Button>
                      </Col>
                    </Row>
                  )}

                  <Collapse isOpen={collapse === "purchases" ? true : false}>
                    <Datatable
                      headers={headers}
                      columns={columns}
                      columnDefs={columnDefs}
                      petition={getData}
                      control="back"
                      stateRefresh={[refresh, setRefresh]}
                      searching={true}
                      order={{ col: 1, opt: "desc" }}
                    />
                  </Collapse>

                  <Collapse isOpen={collapse === "receptions" ? true : false}>
                    {collapse === "receptions" &&
                      reception !== "" &&
                      reception !== null &&
                      reception !== undefined && (
                        <Receptions
                          permissions={permissions}
                          reception={reception}
                          idArea={idArea}
                          idStaff={id}
                          setCollapseP={setCollapse}
                          setReception={setReception}
                        />
                      )}
                  </Collapse>

                  <Collapse isOpen={collapse === "warehouse" ? true : false}>
                    {collapse === "warehouse" && reception !== "" && (
                      <Entradas reception={reception} idArea={idArea} />
                    )}
                  </Collapse>

                  {linkFile ? (
                    <ModalViewFile
                      modal={modalFile}
                      setModal={setModalFile}
                      backdrop={true}
                      keyboard={true}
                      btnClose={true}
                      fileLink={linkFile}
                    />
                  ) : null}
                </>
              ) : (
                "No tiene permiso de consultar este modulo"
              )}
            </>
          )}
        </>
      )}
    </Widget>
  );
}

import { peticionesReceiver } from "../../../helpers/peticionesReceiver";
const API = peticionesReceiver();

export const createIncomes = async (incomesRows) => {
  if (incomesRows) {
    const params = {
      action: "insert",
      table: "incomes",
      rows: incomesRows,
      order: "idIncome asc",
      validate: [
        ["incomeKey"],
        ["nameIncome"],
        ["nameIncome", "incomeKey"],
        ["keyIncomeSubType","idFinancialSources","nameIncome","incomeKey"]
      ]
    };
    try {
      const res = await API.peticion(params)
      if (res.status === 200 && res.data.code === "200") {
        return {success: true}
      }
      else{
        return {success: false, message: res.data.message}
      }
    } catch (error) {
      return {success: false, message: error}
    }
  }
};

export const updateIncomes = async (incomesRows) => {
  if (incomesRows) {
    const params = {
      action: "update",
      table: "incomes",
      rows: incomesRows,
      condition: {
        idIncome: incomesRows.idIncome,
      },
      validate: [
        ["incomeKey"],
        ["nameIncome"],
        ["nameIncome", "incomeKey"],
        ["keyIncomeSubType","idFinancialSources","nameIncome","incomeKey"]
      ]
    };
    try {
      const res = await API.peticion(params)
      if (res.status === 200 && res.data.code === "200") {
        return {success: true}
      }
      else{
        return {success: false, message: res.data.message}
      }
    } catch (error) {
      return {success: false, message: error}
    }
  }
};
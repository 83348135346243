import { Modal, ModalHeader, ModalBody, Button, Row, Col, Table } from "reactstrap";


export default function ModalDatosPersonales({ staff, modal, setModal }) {

    return (
        <>
            <Modal isOpen={modal} toggle={() => setModal(false)} fullscreen="lg" size="lg" backdrop="static" >
                <ModalHeader toggle={() => setModal(false)}></ModalHeader>
                <ModalBody>
                    <div className="text-center border-bottom border-primary">
                        <h4>Información</h4>
                    </div>
                    <div className="text-center d-block">
                        <span>Empleado</span>
                        <h6>{staff.staffNumber}</h6>
                        <img className="w-50" src={staff.foto} alt={"foto del empleado"} />
                        <h5 className="mb-2 w-50 mx-auto">{staff.name}</h5>
                        <h6 className="mb-2 w-50 mx-auto">{staff.nameJob}</h6>
                        <h6 className="mb-2 w-50 mx-auto">{staff.DesJuz}</h6>
                    </div>
                    <Row className="mx-3 mb-2">
                        <Col className="text-dark"><b>RFC:</b> {staff.rfc}</Col>
                        <Col className="text-dark"><b>CURP:</b> {staff.curp}</Col>
                        <Col className="text-dark"><b>Telefono:</b> {staff.phone}</Col>
                    </Row>
                    <Row className="mx-3 mb-2">
                        <Col xs={4} className="text-dark"><b>Fecha ingreso:</b> {staff.startJobDate}</Col>
                        <Col xs={8} className="text-dark"><b>Correo(s):</b> {staff.correo}</Col>
                    </Row>
                    <div className="text-center border-bottom border-primary mb-3">
                        <h4>Escolaridades</h4>
                    </div>
                    {staff.escolaridades.length === 0 ?
                        <Table className="mb-3">
                            <tbody>
                                <tr>
                                    <td colSpan={4}>Sin registros</td>
                                </tr>
                            </tbody>
                        </Table>
                        : <Table striped hover responsive className="mb-3">
                            <thead>
                                <tr>
                                    <th>NIVEL ACADEMICO</th>
                                    <th>DOCUMENTO RECIBIDO</th>
                                    <th>INSTITUCIÓN</th>
                                    <th>FECHA INICIO</th>
                                    <th>FECHA FIN</th>
                                </tr>
                            </thead>
                            <tbody>

                                {staff.escolaridades.map(item => (
                                    <tr>
                                        <td>{item.nameSchoolarityType}</td>
                                        <td>{item.nameDegreeType}</td>
                                        <td>{item.school}</td>
                                        <td>{item.startDate}</td>
                                        <td>{item.endDate}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    }
                    <div className="text-center border-bottom border-primary mb-3">
                        <h4>Experiencia laboral</h4>
                    </div>
                    {staff.experiencia.length === 0 ?
                        <Table className="mb-3">
                            <tbody>
                                <tr>
                                    <td colSpan={4}>Sin registros</td>
                                </tr>
                            </tbody>
                        </Table>
                        : <Table striped hover responsive className="mb-3">
                            <thead>
                                <tr>
                                    <th>EMPLEADOR</th>
                                    <th>POSICION</th>
                                    <th>FECHA INICIO</th>
                                    <th>FECHA FIN</th>
                                </tr>
                            </thead>
                            <tbody>
                                {staff.experiencia.map(item => (
                                    <tr>
                                        <td>{item.employeer}</td>
                                        <td>{item.position}</td>
                                        <td>{item.startDate}</td>
                                        <td>{item.endDate}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    }
                    <div className="text-center border-bottom border-primary mb-3">
                        <h4>Movimientos</h4>
                    </div>
                    {staff.movimientos.length === 0 ?
                        <Table className="mb-3">
                            <tbody>
                                <tr>
                                    <td colSpan={4}>Sin registros</td>
                                </tr>
                            </tbody>
                        </Table>
                        : <Table striped hover responsive className="mb-3">
                            <thead>
                                <tr>
                                    <th>ADSCRIPCIÓN</th>
                                    <th>PUESTO</th>
                                    <th>NIVEL</th>
                                    <th>RANGO</th>
                                    <th>FECHA INICIO</th>
                                    <th>FECHA FIN</th>
                                </tr>
                            </thead>
                            <tbody>
                                {staff.movimientos.map(item => (
                                    <tr>
                                        <td>{item.nameArea}</td>
                                        <td>{item.nameJob}</td>
                                        <td>{item.level}</td>
                                        <td>{item.rango}</td>
                                        <td>{item.fechaInicio}</td>
                                        <td>{item.fechafin}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    }

                    <div className="text-center mt-2">
                        <Button color="danger" className="mx-1" onClick={() => setModal(false)}>Cerrar</Button>
                    </div>
                </ModalBody>
            </Modal>
        </>
    )
}
import React, { useState } from "react";
import TableComponentFiltro from "../../../components/TableComponent/TableComponentFiltro";

export const PuestosLaborales = () => {
  const cabeceras = [
    "Id Puesto",
    "Puesto",
    "Tipo Puesto",
    "Editar",
    "Eliminar",
  ];
  const [parametros] = useState({
    action: "datatable",
    table: `jobs AS J
    INNER JOIN jobtypes AS JT
    ON JT.keyJobType = J.keyJobType`,
    rows: "J.keyJob,J.nameJob,JT.nameJobType", ///sin espacios y comas
    conditions: "J.enabled = 1",
    page: 0,
    records: 5,
    search: "",
    order: "JT.nameJobType DESC",
  });

  return (
    <div>
      <TableComponentFiltro
        titulo="Puestos"
        cabecerasTabla={cabeceras}
        filtro={null}
        rutaCrear="/template/puestos/crear"
        rutaEditar="/template/puestos/editar"
        parametros={parametros}
      />
    </div>
  );
};
export default PuestosLaborales;

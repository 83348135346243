import { Row, Col, Button } from "reactstrap";
import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import { setYear, format } from "date-fns";
import * as Yup from "yup";

import { CurrencyInput, DateInput } from "../../../../../components/GenericInputsFormik";
import { NumberInput } from "../../../../../components/GenericInputsFormik/NumberInput"
import { YearInput } from "../../../../../components/GenericInputsFormik/YearInput";
import ModalConfirmation from "../../../../../components/Modal/ModalConfirmation";
import Notification from "../../../../../components/Notification/Notification";
import { peticionesReceiver } from "../../../../../helpers/peticionesReceiver";
import Loader from "../../../../../components/Loader/Loader";


export default function FormPrisma({ data }) {
    const [modal, setModal] = useState(false)
    const API = peticionesReceiver();
    const [loading, setLoading] = useState(data ? true : false)

    const defaultForm = {
        idPrisma: "",
        period: "",
        year: "",
        datePrisma: "",
        aporPrimSin: "",
        recAporPrimSin: "",
        actAporPrimSin: "",
        aporPrimBas: "",
        actAporPrimBas: "",
        recAporPrimBas: "",
        aporGastosAdmon: "",
        actAporGastosAdmon: "",
        recAporGastosAdmon: "",
        aporFondoSolRep: "",
        aporExtraordinaria: "",
        actAporFondoSolRep: "",
        aporServSalud: "",
        recAporFondoSolRep: "",
        actAporServSalud: "",
        recAporServSalud: "",
        cuotaExtraordinaria: "",
        cuotaFondSolRep: "",
        actCuotaFondSolRep: "",
        recCuotaFondSolRep: "",
        cuotaServSalud: "",
        actCuotaServSalud: "",
        recCuotaServSalud: "",
        aporSCIOblig: "",
        actAporSCIOblig: "",
        recAporSCIOblig: "",
        cuotaSCIVol: "",
        cuotaSCIOblig: "",
        actCuotaSCIOblig: "",
        recCuotaSCIOblig: "",
        retInstitucionales: "",
        retTerceros: ""
    }

    async function getData(id) {
        const params = {
            action: "multiselect",
            table: "prisma",
            rows: "*",
            conditions: `enabled = 1 AND idPrisma = ${id}`
        }
        const res = await API.peticion(params)
        if (res.status === 200 && res.data.code === "200") {
            const data = res.data.data[0];
            setFieldValue(["idPrisma"], data.idPrisma);
            setFieldValue(["period"], data.period);
            setFieldValue(["year"], setYear(new Date(), data.year));
            setFieldValue(["datePrisma"], new Date(data.datePrisma));
            setFieldValue(["aporPrimSin"], data.aporPrimSin);
            setFieldValue(["recAporPrimSin"], data.recAporPrimSin);
            setFieldValue(["actAporPrimSin"], data.actAporPrimSin);
            setFieldValue(["aporPrimBas"], data.aporPrimBas);
            setFieldValue(["actAporPrimBas"], data.actAporPrimBas);
            setFieldValue(["recAporPrimBas"], data.recAporPrimBas);
            setFieldValue(["aporGastosAdmon"], data.aporGastosAdmon);
            setFieldValue(["actAporGastosAdmon"], data.actAporGastosAdmon);
            setFieldValue(["recAporGastosAdmon"], data.recAporGastosAdmon);
            setFieldValue(["aporFondoSolRep"], data.aporFondoSolRep);
            setFieldValue(["aporExtraordinaria"], data.aporExtraordinaria);
            setFieldValue(["actAporFondoSolRep"], data.actAporFondoSolRep);
            setFieldValue(["aporServSalud"], data.aporServSalud);
            setFieldValue(["recAporFondoSolRep"], data.recAporFondoSolRep);
            setFieldValue(["actAporServSalud"], data.actAporServSalud);
            setFieldValue(["recAporServSalud"], data.recAporServSalud);
            setFieldValue(["cuotaExtraordinaria"], data.cuotaExtraordinaria);
            setFieldValue(["cuotaFondSolRep"], data.cuotaFondSolRep);
            setFieldValue(["actCuotaFondSolRep"], data.actCuotaFondSolRep);
            setFieldValue(["recCuotaFondSolRep"], data.recCuotaFondSolRep);
            setFieldValue(["cuotaServSalud"], data.cuotaServSalud);
            setFieldValue(["actCuotaServSalud"], data.actCuotaServSalud);
            setFieldValue(["recCuotaServSalud"], data.recCuotaServSalud);
            setFieldValue(["aporSCIOblig"], data.aporSCIOblig);
            setFieldValue(["actAporSCIOblig"], data.actAporSCIOblig);
            setFieldValue(["recAporSCIOblig"], data.recAporSCIOblig);
            setFieldValue(["cuotaSCIVol"], data.cuotaSCIVol);
            setFieldValue(["cuotaSCIOblig"], data.cuotaSCIOblig);
            setFieldValue(["actCuotaSCIOblig"], data.actCuotaSCIOblig);
            setFieldValue(["recCuotaSCIOblig"], data.recCuotaSCIOblig);
            setFieldValue(["retInstitucionales"], data.retInstitucionales);
            setFieldValue(["retTerceros"], data.retTerceros);
            setLoading(false)
        } else {
            setTimeout(() => {
                toast(<Notification type={"consultar_error"} withIcon />);
                window.location.href = "#/template/reportes-finanzas/"
            }, 1000)
        }
    }

    useEffect(() => {
        if (data) {
            getData(data.idPrisma)
        }
    }, [])

    const FormSchema = Yup.object().shape({
        idPrisma: Yup.number(),
        period: Yup.number()
            .required("Ingrese un periodo")
            .max(24, "Periodo debe ser menor a 25")
            .min(1, "Periodo debe ser mayor a 0"),
        year: Yup.date()
            .required("Ingrese un año")
            .max(setYear(new Date(), 3000), "Año invalido")
            .min(setYear(new Date(), 2020), "Año debe ser posterior al 2019"),
        datePrisma: Yup.date()
            .required("Ingrese una fecha"),
        aporPrimSin: Yup.number()
            .required("El importe es requerido"),
        recAporPrimSin: Yup.number()
            .required("El importe es requerido"),
        actAporPrimSin: Yup.number()
            .required("El importe es requerido"),
        aporPrimBas: Yup.number()
            .required("El importe es requerido"),
        actAporPrimBas: Yup.number()
            .required("El importe es requerido"),
        recAporPrimBas: Yup.number()
            .required("El importe es requerido"),
        aporGastosAdmon: Yup.number()
            .required("El importe es requerido"),
        actAporGastosAdmon: Yup.number()
            .required("El importe es requerido"),
        recAporGastosAdmon: Yup.number()
            .required("El importe es requerido"),
        aporFondoSolRep: Yup.number()
            .required("El importe es requerido"),
        aporExtraordinaria: Yup.number()
            .required("El importe es requerido"),
        actAporFondoSolRep: Yup.number()
            .required("El importe es requerido"),
        aporServSalud: Yup.number()
            .required("El importe es requerido"),
        recAporFondoSolRep: Yup.number()
            .required("El importe es requerido"),
        actAporServSalud: Yup.number()
            .required("El importe es requerido"),
        recAporServSalud: Yup.number()
            .required("El importe es requerido"),
        cuotaExtraordinaria: Yup.number()
            .required("El importe es requerido"),
        cuotaFondSolRep: Yup.number()
            .required("El importe es requerido"),
        actCuotaFondSolRep: Yup.number()
            .required("El importe es requerido"),
        recCuotaFondSolRep: Yup.number()
            .required("El importe es requerido"),
        cuotaServSalud: Yup.number()
            .required("El importe es requerido"),
        actCuotaServSalud: Yup.number()
            .required("El importe es requerido"),
        recCuotaServSalud: Yup.number()
            .required("El importe es requerido"),
        aporSCIOblig: Yup.number()
            .required("El importe es requerido"),
        actAporSCIOblig: Yup.number()
            .required("El importe es requerido"),
        recAporSCIOblig: Yup.number()
            .required("El importe es requerido"),
        cuotaSCIVol: Yup.number()
            .required("El importe es requerido"),
        cuotaSCIOblig: Yup.number()
            .required("El importe es requerido"),
        actCuotaSCIOblig: Yup.number()
            .required("El importe es requerido"),
        recCuotaSCIOblig: Yup.number()
            .required("El importe es requerido"),
        retInstitucionales: Yup.number()
            .required("El importe es requerido"),
        retTerceros: Yup.number()
            .required("El importe es requerido")
    })

    const { handleSubmit, values, handleBlur, errors, touched, setFieldValue, resetForm } =
        useFormik({
            initialValues: defaultForm,
            onSubmit: () => setModal(true),
            validationSchema: FormSchema,
        });

    const onChange = (e) => {
        const { name, value } = e.target
        setFieldValue([name], value);
    }

    function getParamsInsertUpdate(rows, insert) {
        const parametros = {
            table: "prisma",
            validate: [["year", "period"]]
        }
        const datos = rows;
        datos.year = new Date(datos.year).getFullYear()
        const tempDate = datos.datePrisma.toString();
        datos.datePrisma = tempDate.substring(0, tempDate.indexOf("T"));
        if (insert) {
            delete datos.idPrisma
            parametros.action = "insert"
            parametros.rows = datos;
        } else {
            parametros.action = "update"
            parametros.condition = { idPrisma: datos.idPrisma };
            parametros.rows = datos;
        }
        return parametros;
    }

    const OnCreate = async (rows) => {
        const params = getParamsInsertUpdate(JSON.parse(JSON.stringify(rows)), true)
        try {
            const res = await API.peticion(params)
            if (res.status === 200 && res.data.code === "200") {
                toast(<Notification type={"agrega_exito"} backMessage={res.data.message} withIcon />);
                window.location.href = "#/template/reportes-finanzas"
            } else {
                toast(<Notification type={"agrega_error"} backMessage={res.data.message} withIcon />);
            }
        } catch (err) {
            toast(<Notification type={"agrega_error"} withIcon />);
        }
    }

    const OnEdit = async (rows) => {
        const params = getParamsInsertUpdate(JSON.parse(JSON.stringify(rows)), false)
        try {
            const res = await API.peticion(params)
            if (res.status === 200 && res.data.code === "200") {
                toast(<Notification type={"modifica_exito"} backMessage={res.data.message} withIcon />);
                window.location.href = "#/template/reportes-finanzas"
            } else {
                toast(<Notification type={"modifica_error"} backMessage={res.data.message} withIcon />);
            }
        } catch (err) {
            toast(<Notification type={"modifica_error"} withIcon />);
        }
    }

    if (loading) {
        return (
            <Loader />
        )
    }
    return (
        <>
            <h2>PRISMA</h2>
            <h5 className="text-center border-bottom border-primary mb-3">Generales</h5>
            <form onSubmit={handleSubmit}>
                <Row>
                    <Col xs={12} md={4}>
                        <NumberInput
                            label="Periodo"
                            inputName="period"
                            onChangeMethod={onChange}
                            onBlurMethod={handleBlur}
                            value={values.period}
                            errors={errors.period}
                            touched={touched.period}
                            isRequired
                            disableSep
                        />
                    </Col>
                    <Col xs={12} md={4}>
                        <YearInput
                            label="AÑO"
                            inputName="year"
                            onChangeMethod={onChange}
                            onBlurMethod={handleBlur}
                            value={values.year}
                            isRequired
                            touched={touched.year}
                            errors={errors.year}
                        />
                    </Col>
                    <Col xs={12} md={4}>
                        <DateInput
                            label="Fecha"
                            inputName="datePrisma"
                            onChangeMethod={(date) => onChange({ target: { name: "datePrisma", value: new Date(date) } })}
                            value={values.datePrisma}
                            isRequired
                            touched={touched.datePrisma}
                            errors={errors.datePrisma}
                        />
                    </Col>
                </Row>
                <h5 className="text-center border-bottom border-primary mb-3">Aportación Prima de Siniestralidad</h5>
                <Row>
                    <Col xs={12} md={4}>
                        <CurrencyInput
                            label="Importe original"
                            inputName="aporPrimSin"
                            onChangeMethod={(value, name) => onChange({ target: { name, value } })}
                            onBlurMethod={handleBlur}
                            value={values.aporPrimSin}
                            isRequired
                            touched={touched.aporPrimSin}
                            errors={errors.aporPrimSin}
                        />
                    </Col>
                    <Col xs={12} md={4}>
                        <CurrencyInput
                            label="Importe actualización"
                            inputName="actAporPrimSin"
                            onChangeMethod={(value, name) => onChange({ target: { name, value } })}
                            onBlurMethod={handleBlur}
                            value={values.actAporPrimSin}
                            isRequired
                            touched={touched.actAporPrimSin}
                            errors={errors.actAporPrimSin}
                        />
                    </Col>
                    <Col xs={12} md={4}>
                        <CurrencyInput
                            label="Importe recargos"
                            inputName="recAporPrimSin"
                            onChangeMethod={(value, name) => onChange({ target: { name, value } })}
                            onBlurMethod={handleBlur}
                            value={values.recAporPrimSin}
                            isRequired
                            touched={touched.recAporPrimSin}
                            errors={errors.recAporPrimSin}
                        />
                    </Col>
                </Row>
                <h5 className="text-center border-bottom border-primary mb-3">Aportación Prima Básica</h5>
                <Row>
                    <Col xs={12} md={4}>
                        <CurrencyInput
                            label="Importe original"
                            inputName="aporPrimBas"
                            onChangeMethod={(value, name) => onChange({ target: { name, value } })}
                            onBlurMethod={handleBlur}
                            value={values.aporPrimBas}
                            isRequired
                            touched={touched.aporPrimBas}
                            errors={errors.aporPrimBas}
                        />
                    </Col>
                    <Col xs={12} md={4}>
                        <CurrencyInput
                            label="Importe actualización"
                            inputName="actAporPrimBas"
                            onChangeMethod={(value, name) => onChange({ target: { name, value } })}
                            onBlurMethod={handleBlur}
                            value={values.actAporPrimBas}
                            isRequired
                            touched={touched.actAporPrimBas}
                            errors={errors.actAporPrimBas}
                        />
                    </Col>
                    <Col xs={12} md={4}>
                        <CurrencyInput
                            label="Importe recargos"
                            inputName="recAporPrimBas"
                            onChangeMethod={(value, name) => onChange({ target: { name, value } })}
                            onBlurMethod={handleBlur}
                            value={values.recAporPrimBas}
                            isRequired
                            touched={touched.recAporPrimBas}
                            errors={errors.recAporPrimBas}
                        />
                    </Col>
                </Row>
                <h5 className="text-center border-bottom border-primary mb-3">Aportación Gastos de Administración</h5>
                <Row>
                    <Col xs={12} md={4}>
                        <CurrencyInput
                            label="Importe original"
                            inputName="aporGastosAdmon"
                            onChangeMethod={(value, name) => onChange({ target: { name, value } })}
                            onBlurMethod={handleBlur}
                            value={values.aporGastosAdmon}
                            isRequired
                            touched={touched.aporGastosAdmon}
                            errors={errors.aporGastosAdmon}
                        />
                    </Col>
                    <Col xs={12} md={4}>
                        <CurrencyInput
                            label="Importe actualización"
                            inputName="actAporGastosAdmon"
                            onChangeMethod={(value, name) => onChange({ target: { name, value } })}
                            onBlurMethod={handleBlur}
                            value={values.actAporGastosAdmon}
                            isRequired
                            touched={touched.actAporGastosAdmon}
                            errors={errors.actAporGastosAdmon}
                        />
                    </Col>
                    <Col xs={12} md={4}>
                        <CurrencyInput
                            label="Importe recargos"
                            inputName="recAporGastosAdmon"
                            onChangeMethod={(value, name) => onChange({ target: { name, value } })}
                            onBlurMethod={handleBlur}
                            value={values.recAporGastosAdmon}
                            isRequired
                            touched={touched.recAporGastosAdmon}
                            errors={errors.recAporGastosAdmon}
                        />
                    </Col>
                </Row>
                <h5 className="text-center border-bottom border-primary mb-3">Aportación Extraordinaria</h5>
                <Row>
                    <Col xs={12} md={4}>
                        <CurrencyInput
                            label="Importe original"
                            inputName="aporExtraordinaria"
                            onChangeMethod={(value, name) => onChange({ target: { name, value } })}
                            onBlurMethod={handleBlur}
                            value={values.aporExtraordinaria}
                            isRequired
                            touched={touched.aporExtraordinaria}
                            errors={errors.aporExtraordinaria}
                        />
                    </Col>
                </Row>
                <h5 className="text-center border-bottom border-primary mb-3">Aportación Fondo Solidario de Reparto</h5>
                <Row>
                    <Col xs={12} md={4}>
                        <CurrencyInput
                            label="Importe original"
                            inputName="aporFondoSolRep"
                            onChangeMethod={(value, name) => onChange({ target: { name, value } })}
                            onBlurMethod={handleBlur}
                            value={values.aporFondoSolRep}
                            isRequired
                            touched={touched.aporFondoSolRep}
                            errors={errors.aporFondoSolRep}
                        />
                    </Col>
                    <Col xs={12} md={4}>
                        <CurrencyInput
                            label="Importe actualización"
                            inputName="actAporFondoSolRep"
                            onChangeMethod={(value, name) => onChange({ target: { name, value } })}
                            onBlurMethod={handleBlur}
                            value={values.actAporFondoSolRep}
                            isRequired
                            touched={touched.actAporFondoSolRep}
                            errors={errors.actAporFondoSolRep}
                        />
                    </Col>
                    <Col xs={12} md={4}>
                        <CurrencyInput
                            label="Importe recargos"
                            inputName="recAporFondoSolRep"
                            onChangeMethod={(value, name) => onChange({ target: { name, value } })}
                            onBlurMethod={handleBlur}
                            value={values.recAporFondoSolRep}
                            isRequired
                            touched={touched.recAporFondoSolRep}
                            errors={errors.recAporFondoSolRep}
                        />
                    </Col>
                </Row>
                <h5 className="text-center border-bottom border-primary mb-3">Aportación Servicios de Salud</h5>
                <Row>
                    <Col xs={12} md={4}>
                        <CurrencyInput
                            label="Importe original"
                            inputName="aporServSalud"
                            onChangeMethod={(value, name) => onChange({ target: { name, value } })}
                            onBlurMethod={handleBlur}
                            value={values.aporServSalud}
                            isRequired
                            touched={touched.aporServSalud}
                            errors={errors.aporServSalud}
                        />
                    </Col>
                    <Col xs={12} md={4}>
                        <CurrencyInput
                            label="Importe actualización"
                            inputName="actAporServSalud"
                            onChangeMethod={(value, name) => onChange({ target: { name, value } })}
                            onBlurMethod={handleBlur}
                            value={values.actAporServSalud}
                            isRequired
                            touched={touched.actAporServSalud}
                            errors={errors.actAporServSalud}
                        />
                    </Col>
                    <Col xs={12} md={4}>
                        <CurrencyInput
                            label="Importe recargos"
                            inputName="recAporServSalud"
                            onChangeMethod={(value, name) => onChange({ target: { name, value } })}
                            onBlurMethod={handleBlur}
                            value={values.recAporServSalud}
                            isRequired
                            touched={touched.recAporServSalud}
                            errors={errors.recAporServSalud}
                        />
                    </Col>
                </Row>
                <h5 className="text-center border-bottom border-primary mb-3">Cuota extraordinaria</h5>
                <Row>
                    <Col xs={12} md={4}>
                        <CurrencyInput
                            label="Cuota extraordinaria"
                            inputName="cuotaExtraordinaria"
                            onChangeMethod={(value, name) => onChange({ target: { name, value } })}
                            onBlurMethod={handleBlur}
                            value={values.cuotaExtraordinaria}
                            isRequired
                            touched={touched.cuotaExtraordinaria}
                            errors={errors.cuotaExtraordinaria}
                        />
                    </Col>
                </Row>
                <h5 className="text-center border-bottom border-primary mb-3">Cuota Fondo Solidario de reparto</h5>
                <Row>
                    <Col xs={12} md={4}>
                        <CurrencyInput
                            label="Importe original"
                            inputName="cuotaFondSolRep"
                            onChangeMethod={(value, name) => onChange({ target: { name, value } })}
                            onBlurMethod={handleBlur}
                            value={values.cuotaFondSolRep}
                            isRequired
                            touched={touched.cuotaFondSolRep}
                            errors={errors.cuotaFondSolRep}
                        />
                    </Col>
                    <Col xs={12} md={4}>
                        <CurrencyInput
                            label="Importe actualización"
                            inputName="actCuotaFondSolRep"
                            onChangeMethod={(value, name) => onChange({ target: { name, value } })}
                            onBlurMethod={handleBlur}
                            value={values.actCuotaFondSolRep}
                            isRequired
                            touched={touched.actCuotaFondSolRep}
                            errors={errors.actCuotaFondSolRep}
                        />
                    </Col>
                    <Col xs={12} md={4}>
                        <CurrencyInput
                            label="Importe recargos"
                            inputName="recCuotaFondSolRep"
                            onChangeMethod={(value, name) => onChange({ target: { name, value } })}
                            onBlurMethod={handleBlur}
                            value={values.recCuotaFondSolRep}
                            isRequired
                            touched={touched.recCuotaFondSolRep}
                            errors={errors.recCuotaFondSolRep}
                        />
                    </Col>
                </Row>
                <h5 className="text-center border-bottom border-primary mb-3">Cuota Servicio de Salud</h5>
                <Row>
                    <Col xs={12} md={4}>
                        <CurrencyInput
                            label="Importe original"
                            inputName="cuotaServSalud"
                            onChangeMethod={(value, name) => onChange({ target: { name, value } })}
                            onBlurMethod={handleBlur}
                            value={values.cuotaServSalud}
                            isRequired
                            touched={touched.cuotaServSalud}
                            errors={errors.cuotaServSalud}
                        />
                    </Col>
                    <Col xs={12} md={4}>
                        <CurrencyInput
                            label="Importe actualización"
                            inputName="actCuotaServSalud"
                            onChangeMethod={(value, name) => onChange({ target: { name, value } })}
                            onBlurMethod={handleBlur}
                            value={values.actCuotaServSalud}
                            isRequired
                            touched={touched.actCuotaServSalud}
                            errors={errors.actCuotaServSalud}
                        />
                    </Col>
                    <Col xs={12} md={4}>
                        <CurrencyInput
                            label="Importe recargos"
                            inputName="recCuotaServSalud"
                            onChangeMethod={(value, name) => onChange({ target: { name, value } })}
                            onBlurMethod={handleBlur}
                            value={values.recCuotaServSalud}
                            isRequired
                            touched={touched.recCuotaServSalud}
                            errors={errors.recCuotaServSalud}
                        />
                    </Col>
                </Row>
                <h5 className="text-center border-bottom border-primary mb-3">Aportación S.C.I Obligatorio</h5>
                <Row>
                    <Col xs={12} md={4}>
                        <CurrencyInput
                            label="Importe original"
                            inputName="aporSCIOblig"
                            onChangeMethod={(value, name) => onChange({ target: { name, value } })}
                            onBlurMethod={handleBlur}
                            value={values.aporSCIOblig}
                            isRequired
                            touched={touched.aporSCIOblig}
                            errors={errors.aporSCIOblig}
                        />
                    </Col>
                    <Col xs={12} md={4}>
                        <CurrencyInput
                            label="Importe actualización"
                            inputName="actAporSCIOblig"
                            onChangeMethod={(value, name) => onChange({ target: { name, value } })}
                            onBlurMethod={handleBlur}
                            value={values.actAporSCIOblig}
                            isRequired
                            touched={touched.actAporSCIOblig}
                            errors={errors.actAporSCIOblig}
                        />
                    </Col>
                    <Col xs={12} md={4}>
                        <CurrencyInput
                            label="Importe recargos"
                            inputName="recAporSCIOblig"
                            onChangeMethod={(value, name) => onChange({ target: { name, value } })}
                            onBlurMethod={handleBlur}
                            value={values.recAporSCIOblig}
                            isRequired
                            touched={touched.recAporSCIOblig}
                            errors={errors.recAporSCIOblig}
                        />
                    </Col>
                </Row>
                <h5 className="text-center border-bottom border-primary mb-3">Cuota S.C.I Voluntario</h5>
                <Row>
                    <Col xs={12} md={4}>
                        <CurrencyInput
                            label="Importe original"
                            inputName="cuotaSCIVol"
                            onChangeMethod={(value, name) => onChange({ target: { name, value } })}
                            onBlurMethod={handleBlur}
                            value={values.cuotaSCIVol}
                            isRequired
                            touched={touched.cuotaSCIVol}
                            errors={errors.cuotaSCIVol}
                        />
                    </Col>
                </Row>
                <h5 className="text-center border-bottom border-primary mb-3">Cuota S.C.I Obligatorio</h5>
                <Row>
                    <Col xs={12} md={4}>
                        <CurrencyInput
                            label="Importe original"
                            inputName="cuotaSCIOblig"
                            onChangeMethod={(value, name) => onChange({ target: { name, value } })}
                            onBlurMethod={handleBlur}
                            value={values.cuotaSCIOblig}
                            isRequired
                            touched={touched.cuotaSCIOblig}
                            errors={errors.cuotaSCIOblig}
                        />
                    </Col>
                    <Col xs={12} md={4}>
                        <CurrencyInput
                            label="Importe actualización"
                            inputName="actCuotaSCIOblig"
                            onChangeMethod={(value, name) => onChange({ target: { name, value } })}
                            onBlurMethod={handleBlur}
                            value={values.actCuotaSCIOblig}
                            isRequired
                            touched={touched.actCuotaSCIOblig}
                            errors={errors.actCuotaSCIOblig}
                        />
                    </Col>
                    <Col xs={12} md={4}>
                        <CurrencyInput
                            label="Importe recargos"
                            inputName="recCuotaSCIOblig"
                            onChangeMethod={(value, name) => onChange({ target: { name, value } })}
                            onBlurMethod={handleBlur}
                            value={values.recCuotaSCIOblig}
                            isRequired
                            touched={touched.recCuotaSCIOblig}
                            errors={errors.recCuotaSCIOblig}
                        />
                    </Col>
                </Row>
                <h5 className="text-center border-bottom border-primary mb-3">Retenciones</h5>
                <Row>
                    <Col xs={12} md={6}>
                        <CurrencyInput
                            label="Institucionales"
                            inputName="retInstitucionales"
                            onChangeMethod={(value, name) => onChange({ target: { name, value } })}
                            onBlurMethod={handleBlur}
                            value={values.retInstitucionales}
                            isRequired
                            touched={touched.retInstitucionales}
                            errors={errors.retInstitucionales}
                        />
                    </Col>
                    <Col xs={12} md={6}>
                        <CurrencyInput
                            label="A terceros"
                            inputName="retTerceros"
                            onChangeMethod={(value, name) => onChange({ target: { name, value } })}
                            onBlurMethod={handleBlur}
                            value={values.retTerceros}
                            isRequired
                            touched={touched.retTerceros}
                            errors={errors.retTerceros}
                        />
                    </Col>
                </Row>
                <div className="text-center">
                    <Button color="danger" className="mx-md-1" onClick={() => window.location.href = "#/template/reportes-finanzas"}>Cancelar</Button>
                    <Button color="add" type="submit" className="mx-md-1">Agregar</Button>
                </div>
            </form>
            <ModalConfirmation
                modalTitle={!data ? "Agregar" : "Editar"}
                modal={modal}
                crear={(rows) => OnCreate(rows)}
                editar={(rows) => OnEdit(rows)}
                setModal={setModal}
                isEdit={data ? true : false}
                values={values}
            >
                <div className="d-flex justify-content-center">
                    <h6>¿Desea continuar con el registro?</h6>
                </div>
            </ModalConfirmation>
        </>
    )
}
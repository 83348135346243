import React, { useState, useEffect } from "react";
/* import ModalComponentEliminar from "../../components/Modal/ModalDelete"; */
import Loader from "../../components/Loader/Loader";
import axios from "axios";
import {
  Table,
  Input,
  Pagination,
  PaginationItem,
  PaginationLink
} from "reactstrap";
import s from "../../pages/tables/Tables.module.scss";
import { useHistory } from "react-router-dom";
import sc from "./TableComponentFiltro.module.scss";
import { peticionEncript } from "../../helpers/peticionesEncripted";
import { formatCell } from "./cellsFormat";

/**
 * @param {Object} props
 * @param {string} props.titulo - Titulo de la tabla
 * @param {Array<string>} props.cabecerasTabla - Arreglo con las cabeceras de la tabla
 * @param {Array<boolean>} props.filtro - Arreglo con filtro para mostrar datos
 * @param {Function} props.editar - Opcional - Funcion para ejecutar en el boton editar
 * @param {Function} props.ver - Opcional - Funcion para ejecutar en el boton ver
 * @param {Object} props.parametros - Objeto con los parametros para realizar la consulta
 * @param {boolean} props.hideTitle - Opcional - Ocultar el titulo de la tabla
 * @param {boolean} props.usePermissions - Opcional - Para utilizar permisos o no en la tabla (false)
 * @param {Object} props.permissions - Objeto con los permisos del usuario sobre el modulo
 *
 * @param {boolean} props.special - Opcional - Variable par agregar una columna
 * @param {Function} props.specialFun - Opcional - Funcion para ejecutar en la columna especial
 * @param {string} props.specialIco - Opcional - cadena del icono de la columna especial
 * @param {boolean} props.canSee - Opcional - Mostrar/Ocultar columna ver
 */
export const TableComponentVer = (props) => {
  const {
    titulo,
    cabecerasTabla,
    filtro,
    editar,
    ver,
    parametros,
    hideTitle,
    usePermissions,
    permissions,
    special,
    specialFun,
    specialIco,
    canSee,
  } = props;
  const colsBdNames = parametros.rows.split(","); // Columnas del back
  const nameTable = parametros.table.split(" ");
  const nameColumn = colsBdNames[0].split(".");
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [tableContent, setTableContent] = useState([]); // Información mostrada en la tabla
  const [pageSize, setPageSize] = useState(5); // Tamaño de las paginas
  const [totalRegistros, setTotalRegistros] = useState(0); // Total de registros en BD
  const [pagesCount, setPagesCount] = useState(0); // Cantidad total de paginas
  const [seccionActual, setSeccionActual] = useState(0); // Seccion actual
  const [sortCol, setSortCol] = useState({
    //Parametros para aplicar filtros a la busqueda
    direction: "",
    column: "",
    page: "",
    currentPage: 0,
    search: "",
    records: 5,
  });
  const [pagination, setPagination] = useState([]);
  
  const settableContentPage = (e, index) => {
    if (index + 1 > (seccionActual + 1) * 5) {
      setSeccionActual(seccionActual + 1);
    } else if (seccionActual != 0 && index + 1 <= seccionActual * 5) {
      setSeccionActual(seccionActual - 1);
    }
    e.preventDefault();
    setSortCol((prevState) => ({
      ...prevState,
      page: pageSize * index,
      currentPage: index,
    }));
  };

  const mapea = (element, indexCol, indexRow) => {
    // if (indexCol === 0) {
    //   return (
    //     <td className="text-center">
    //       {5 * sortCol.currentPage + (indexRow + 1)}
    //     </td>
    //   );
    // }
    // if (filtro[indexCol]) {
    //   const reg = /\d{4}-\d{2}-\d{2}/g;
    //   let texto = element;
    //   if (reg.test(element)) {
    //     texto = element.split("-").reverse().toString().replaceAll(",", "/");
    //   }
    //   return <td className="text-center">{texto}</td>;
    // } else {
    //   return <></>;
    // }
    if (filtro !== null) {
      return formatCell(
        element,
        indexCol,
        indexRow,
        filtro[indexCol],
        sortCol,
        cabecerasTabla[indexCol] + "-" + titulo
      );
    }
    return formatCell(
      element,
      indexCol,
      indexRow,
      true,
      sortCol,
      cabecerasTabla[indexCol] + "-" + titulo
    );
  };

  const sortTable = (cabecera, indexHeader) => {
    if (
      ![
        "Editar",
        "Eliminar",
        "Id",
        "Ver",
        "Seleccionar",
        "Modificar",
        "FUMP",
      ].includes(cabecera)
    ) {
      if (sortCol.column !== indexHeader) {
        setSortCol((ps) => ({ ...ps, column: indexHeader, direction: "asc" }));
      } else {
        setSortCol((ps) => ({
          ...ps,
          direction:
            sortCol.direction === "asc"
              ? "desc"
              : sortCol.direction === "desc"
              ? ""
              : "asc",
        }));
      }
    }
  };

  const getData = async (orderOb) => {
    if(usePermissions && !permissions.CON) return false;
    //LA PRIMERA VEZ QUE ENTRAMOS VIENE UN ORDEROB = EMPTY Y EN EL PARAMS = ORDER = "", PAGE = 0 Y RECORDS = 5
    //CUANDO SELECCIONO EL HEADER VIENE ORDEROB = COLUMN = 1 Y DIRECTION = ASC Y EN EL PARAMS = ORDER = "ASC", PAGE = 0 Y RECORDS = 5
    //DESPUES DE ESO SELECCIONO EL PAGINADO Y MUESTRA ORDEROB = COLUMN = "", DIRECTION = "" Y PAGE = 5 Y EN EL PARAMS = ORDER = "", PAGE = 5 Y RECORDS = 5
    //SOLO HAY QUE VERIFICAR EL ESTADO DEL HOOK Y QUE NO CAMBIE LOS PARAMS
    var params = parametros;
    if (sortCol.column !== "" && sortCol.direction !== "") {
      //AQUI QUIERE DECIR QUE TRAE UN ORDENAMIENTO POR HEADER
      params.order = orderOb.column + " " + orderOb.direction;
    } else {
      params.order = parametros.order;
    }
    if (sortCol.page !== "") {
      //QUIERE DECIR QUE CAMBIAMOS DE PAGINA
      params.page = sortCol.page;
    } else {
      params.page = 0;
    }
    if (sortCol.search !== "") {
      params.search = sortCol.search;
    } else {
      params.search = "";
    }
    if (orderOb) {
    } else {
      if (sortCol.column !== "" && sortCol.direction !== "") {
        //AQUI TRAE UN ORDENAMIENTO POR HEADER
        params.order = orderOb.column + " " + orderOb.direction;
      } else {
        params.order = parametros.order;
      }
      if (sortCol.page !== "") {
        //CAMBIAMOS DE PAGINA
        params.page = sortCol.page;
      } else {
        params.page = 0;
      }
      if (sortCol.search !== "") {
        params.search = sortCol.search;
      } else {
        params.search = "";
      }
    }
    params.records = sortCol.records;
    setLoading(true); 
    const finalData = peticionEncript(params);
    await axios
      .post(`${process.env.REACT_APP_API}receiver/receiver.php`, finalData)
      .then((res) => {
        let data = [];
        if (res.status === 200 && res.data.code === "200") {
          const ttrec = res.data.totalRecords ? res.data.totalRecords : 0;
          setTotalRegistros(ttrec);
          setPagesCount(Math.ceil(ttrec / pageSize));
          res.data.data.map((el) => {
            data.push(Object.values(el));
          });
        } else {
          setTotalRegistros(0);
          setPagesCount(0);
        }
        setTableContent(data);
        setLoading(false);
      })
      .catch((err) => {
        /* toast(<Notification type={"consultar_servidor_error"} withIcon />); */
        setLoading(false);
      });
  };

  useEffect(() => {
    getData(sortCol);
  }, [sortCol, parametros]);

  const handleOnChange = (e) => {
    setSeccionActual(0);
    setSortCol((prevState) => ({
      ...prevState,
      search: e.target.value,
      currentPage: 0,
      page: "",
    }));
  };

  const handleOnChangeSelect = (e) => {
    setSeccionActual(0);
    setPageSize(e.target.value);
    setSortCol((prevState) => ({
      ...prevState,
      records: e.target.value,
      currentPage: 0,
      page: "",
    }));
  };

  const showHeander = (cab, id) => {
    if (cab.toLowerCase() === "editar" && !permissions.UPD) return null;
    if (cab.toLowerCase() === "eliminar" && !permissions.DEL) return null;
    return (
      <th
        key={"H" + cab}
        className="text-center"
        onClick={() => sortTable(cab, id + 1)}
      >
        {cab === "Id" ? "#" : cab}
        <i
          className={
            "align-top eva" +
            (sortCol.column === id + 1
              ? sortCol.direction === "asc"
                ? " eva-arrow-ios-upward-outline"
                : sortCol.direction === "desc"
                ? " eva-arrow-ios-downward-outline"
                : ""
              : "")
          }
        ></i>
      </th>
    );
  };
  

  /*Paginacion @luixeduard */
  useEffect(() => {
    const pages = Math.ceil(totalRegistros / pageSize);
    const maxPages = Math.min(pages, 5);
    const startPage = Math.max(Math.min(pages - maxPages, sortCol.currentPage - Math.floor(maxPages / 2)), 0);
    const endPage = Math.min(startPage + maxPages, pages);
    const tempPages = Array.from({ length: endPage - startPage }, (_, i) => i + startPage);

    setPagination(tempPages);
}, [tableContent]);

  return (
    <div className="mb-4">
      {titulo && !hideTitle && (
        <div className={s.tableTitle}>
          <h1 className="text-secondary text-center">{titulo} </h1>
        </div>
      )}
      {(!usePermissions || permissions.CON) && (
        <div>
          <div className="d-flex justify-content-between">
            <Input
              value={sortCol.search}
              name="search"
              onChange={handleOnChange}
              className="col-12 col-md-6"
              placeholder="Buscar..."
              autoFocus
            ></Input>
          </div>
          <div className="widget-table-overflow mt-3">
            <Table
              className={`table-striped table-borderless table-hover ${s.statesTable}`}
              responsive
            >
              {(!usePermissions || permissions.CON) && <thead className="border-bottom border-secondary">
                <tr className={sc.userSelectNone}>
                  {cabecerasTabla.map((cabecera, indexHeaders) =>
                    showHeander(cabecera, indexHeaders)
                  )}
                </tr>
              </thead>}
              <tbody>
                {loading ? (
                  <tr key={"loading"}>
                    <td colSpan={cabecerasTabla.length}>
                      <Loader />
                    </td>
                  </tr>
                ) : tableContent.length === 0 ? (
                  <tr key={"nothing"}>
                    <td colSpan={cabecerasTabla.length} className="text-center">
                      No se encontraron registros
                    </td>
                  </tr>
                ) : (!usePermissions || permissions.CON) ? (
                  tableContent.map((registro, index) => (
                    <tr key={"R" + index}>
                      {registro.map((elemento, indexCol) => {
                        return mapea(elemento, indexCol, index);
                      })}

                      {(!usePermissions || permissions.UPD) && editar && (
                        <td onClick={() => editar(registro)}>
                          <div className="d-flex justify-content-center">
                            <i
                              className={
                                sc.handCursor + " fa fa-pencil text-warning"
                              }
                            />
                          </div>
                        </td>
                      )}

                      {special && special.length > 0 && (
                        <td>
                          <div className="d-flex justify-content-center">
                            {specialFun && (
                              <i
                                onClick={() => specialFun(registro)}
                                className={
                                  sc.handCursor +
                                  " fa " +
                                  (specialIco && specialIco != ""
                                    ? specialIco
                                    : "fa-star-o")
                                }
                              />
                            )}
                            {/* {props.specialComp && props.specialComp} */}
                          </div>
                        </td>
                      )}

                      {canSee !== false && (
                        <td className="text-center">
                          <i
                            className={sc.handCursor + " fa fa-eye text-info"}
                            onClick={() => ver(registro)}
                          />
                        </td>
                      )}
                    </tr>
                  ))
                ) : (
                  <tr key={"nothing"}>
                    <td colSpan={cabecerasTabla.length} className="text-center">
                      No tiene permitido ver los registros de esta seccion
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
            <div className="row">
            <div className="col-12 col-sm-6 d-flex flex-row mt-3">
              <div className="mr-3">
                  Mostrando{" "}
                  <span className="font-weight-bold">
                    {sortCol.currentPage * pageSize +
                      (tableContent.length === 0 ? 0 : 1)}
                  </span>{" "}
                  a{" "}
                  <span className="font-weight-bold">
                    {pageSize * (sortCol.currentPage + 1) <= totalRegistros
                      ? pageSize * (sortCol.currentPage + 1)
                      : totalRegistros}{" "}
                  </span>
                  de <span className="font-weight-bold">{totalRegistros}</span> registros
                </div>
                <select
                  className="form-control form-control-sm"
                  style={{ width: "78px" }}
                  value={sortCol.records}
                  onChange={handleOnChangeSelect}
                >
                  <option value={5} key={5}>
                    5
                  </option>
                  <option value={10} key={10}>
                    10
                  </option>
                  <option value={15} key={15}>
                    15
                  </option>
                  <option value={20} key={20}>
                    20
                  </option>
                  <option value={100} key={100}>
                    100
                  </option>
                </select>
              </div>
              <div className="col-12 col-sm-6 d-flex justify-content-center justify-content-md-end">
                  <Pagination className="pagination-borderless d-flex mt-3">
                    <PaginationItem className="m-0">
                      <PaginationLink 
                        disabled={sortCol.currentPage <= 0}
                        onClick={(e) => settableContentPage(e, sortCol.currentPage - 1)}
                        previous 
                        className={sortCol.currentPage <= 0 ? "text-muted" : ""}
                      />
                    </PaginationItem>
                    {pagination.map((page, i) => (
                      <PaginationItem key={i} disabled={page === sortCol.currentPage} className="m-1" >
                          <PaginationLink
                              onClick={(e) => settableContentPage(e, page)}
                              className={page === sortCol.currentPage ? "text-bold text-white bg-primary":"text-secondary"}
                          >
                              {page + 1}
                          </PaginationLink>
                      </PaginationItem>
                    ))}
                    <PaginationItem className="m-0">
                      <PaginationLink 
                        disabled={sortCol.currentPage + 1 >= pagesCount}
                        onClick={(e) => settableContentPage(e, sortCol.currentPage + 1)}
                        next 
                        className={sortCol.currentPage + 1 >= pagesCount ? "text-muted" : ""}
                      />
                    </PaginationItem>
                  </Pagination>
                  </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default TableComponentVer;

import React, { useEffect } from "react";
import {
  Row,
  Col
} from "reactstrap";
import TableComponentVer from "./TableComponentFiltro";
import { toast } from "react-toastify";
import Notification from "../../../../../components/Notification/Notification";

function ModalTableChoose(props) {
  const {
    modal,
    setModal,
    title,
    titleTable,
    cabeceras,
    filtro,
    parametros,
    addSelectedsMethod,
    backdrop, // true, false, "static"
    keyboard, // true, false
    btnClose, // true, false
    style,
    hideTitleTable,
    staffList,
    setStaffList,
    setRefresh,
  } = props;

  /*   const [staffList, setStaffList] = useState([]); */

  const addStaff = (el) => {
    if (staffList.find((obj) => obj.idStaff === el[0]) === undefined) {
      let tmpList = staffList.slice(0, staffList.length);
      tmpList.push({ idStaff: el[0], staffNumber: el[1], nameStaff: el[2] });
      setStaffList(tmpList);
      setRefresh(true);
    } else {
      toast(
        <Notification
          type={"agrega_error"}
          backMessage={`Usuario: ${el[2]} omitido , ya se encuentra en la lista`}
          withIcon
        />
      );
    }
  };

  return (
    <>
      <Row  className="d-flex mt-5" >
        <Col /* xs={10} lg={10} */>
          <div>
            <TableComponentVer
              titulo={"Busqueda por empleado"}
              cabecerasTabla={cabeceras}
              filtro={filtro}
              ver={addStaff}
              parametros={parametros}
              canSee={props.canSee}
              hideTitle={hideTitleTable}
            />
          </div>
        </Col>
      </Row>
    </>
  );
}

export default ModalTableChoose;

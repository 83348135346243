import React, { useState,useEffect } from "react";
import axios from "axios";
import Loader from "../../../components/Loader/Loader";
import { toast } from 'react-toastify';
import Widget from "../../../components/Widget/Widget";
import { Col, Row, Button } from "reactstrap";
import Select from "react-select";
import AsigaAcciones from "./AsignaAcciones";
import Notification from "../../../components/Notification/Notification";
import {peticionesReceiver} from "../../../helpers/peticionesReceiver"
import { GetPermissions } from "../../../libs/permissions/getPermissions";

const defaultForm = {
  perfil: ""
}

const SeleccionaPerfil = () => {
  const [permissions, setPermissions] = useState({});
  GetPermissions(setPermissions);

  const API = peticionesReceiver()
  const [perfiles, setPerfiles] = useState();
  const [form, setForm] = useState(defaultForm);
  const [loading, setLoading] = useState(true);
  const [continuar, setContinuar] = useState(false);
 
  const getPerfiles = async () => {  
    const data = {
      action: "select",
      table: "profiles",
      order: "nameProfile asc"
    }
    API.peticion(data).then((res) => { 
        if(res.status === 200 && res.data.status === "success"){
          const datos = [];
          res.data.data.map(el => {
            datos.push({
              value: el.keyProfile,
              label: el.nameProfile,
              system: el.keySystem
            });
          })
          setPerfiles(datos);
        }else{
          toast(<Notification type={"consultar_error"} withIcon />);
        }
        setLoading(false);
      })
      .catch((err) =>{
        toast(<Notification type={"consultar_servidor_error"} withIcon />);
        setLoading(false);
      });
  };

  useEffect(() => {
    getPerfiles();
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    if(form.perfil){
      setContinuar(true);
    }
  }
  
  const handleOnChange = (opc) => {
    setForm({perfil: opc});
    if(form.perfil){
      setContinuar(false);
    }
  };

  return (
    <div>
      {loading ? (<Loader />): (
        <Widget className="widget-p-md">
          <h3 className="text-secondary">Perfiles - Modulos</h3>
          <form className="mt-4" onSubmit={(event) => {handleSubmit(event)}}>
              <Row>
                  <Col xs="12" lg="6">
                    <label htmlFor="perfil">Seleccione un perfil para continuar</label>
                    <Select
                      className={"basic-single is-invalid" + !form.profile ? ' is-invalid' : ''}
                      isClearable={true}
                      isSearchable={true}
                      name="perfil"
                      options={perfiles}
                      placeholder={"Seleccione un perfil"}
                      onChange={(choice) => handleOnChange(choice)}
                    />
                  </Col>
              </Row>
          <div className="d-flex justify-content-end mt-5">
              <Button color="success" type="submit" disabled={form.perfil? false : true}>Consultar</Button>
          </div>
          </form>
          <legend className="mt-4" />
          {!continuar? <></> : <AsigaAcciones perfilS={form.perfil} permissions={permissions} />}
        </Widget>
      )}
    </div>
  )
}
export default SeleccionaPerfil;
import { peticionesReceiver } from "../../../helpers/peticionesReceiver";
import Notification from "../../../components/Notification/Notification";
import { toast } from "react-toastify";
const API = peticionesReceiver();

export const getPaises = async (setSelectsData) => {
    const params = {
      action: "select",
      table: "countries",
      condition: {
        enabled: 1,
      },
      order: "nameCountry ASC",
    };
    await API.peticion(params)
    .then((res) => {
      if (res.status === 200 && res.data.code === "200") {
        setSelectsData((prevState) => ({
          ...prevState,
          paisesData: res.data.data,
          estadosData: [],
          municipiosData: [],
          ciudadesData: [],
        }));
        } else {
          toast(<Notification type={"consultar_error"} backMessage={res.data.message} withIcon />);
        }
      })
      .catch((err) => {
        toast(<Notification type={"consultar_servidor_error"} withIcon />);
      });
  };

  export const getEstados = async (idPais, setSelectsData) => {
    if(idPais !== null && idPais !== undefined && idPais !== "" ){
      const params = {
        action: "select",
        table: "states",
        condition: {
          enabled: 1,
          keyCountry: idPais,
        },
        order: "nameState ASC",
      };
      // console.log("Estados", idPais);
      await API.peticion(params)
        .then((res) => {
          if (res.status === 200 && res.data.code === "200") {
            setSelectsData((prevState) => ({
              ...prevState,
              estadosData: res.data.data,
              municipiosData: [],
              ciudadesData: [],
            }));
          } else {
            toast(<Notification type={"consultar_error"} backMessage={res.data.message} withIcon />);
          }
        })
        .catch((err) => {
          toast(<Notification type={"consultar_servidor_error"} withIcon />);
        });
    }
  };

  export const getMunicipios = async (idEstado, setSelectsData) => {
    if(idEstado !== null && idEstado !== undefined && idEstado !== "" ){
      const params = {
        action: "select",
        table: "counties",
        condition: {
          enabled: 1,
          keyState: idEstado,
        },
        order: "nameCounty ASC",
      };
      // console.log("Municipios", idEstado);
      await API.peticion(params)
        .then((res) => {
          if (res.status === 200 && res.data.code === "200") {
            setSelectsData((prevState) => ({
              ...prevState,
              municipiosData: res.data.data,
              ciudadesData: [],
            }));
          } else {
            toast(<Notification type={"consultar_error"} backMessage={res.data.message} withIcon />);
          }
        })
        .catch((err) => {
          toast(<Notification type={"consultar_servidor_error"} withIcon />);
        });
    }
  };

  export const getCiudades = async (idMunicipio, setSelectsData) => {
    if(idMunicipio !== null && idMunicipio !== undefined && idMunicipio !== "" ){
      const params = {
        action: "select",
        table: "cities",
        condition: {
          enabled: 1,
          keyCounty: idMunicipio,
        },
        order: "nameCity ASC",
      };
      // console.log("Ciudades", idMunicipio);
      await API.peticion(params)
        .then((res) => {
          if (res.status === 200 && res.data.code === "200") {
            setSelectsData((prevState) => ({
              ...prevState,
              ciudadesData: res.data.data,
            }));
          } else {
            toast(<Notification type={"consultar_error"} backMessage={res.data.message} withIcon />);
          }
        })
        .catch((err) => {
          toast(<Notification type={"consultar_servidor_error"} withIcon />);
        });
    }
  };
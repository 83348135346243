import { Button, Col, Container, Row } from "reactstrap";
import Widget from "../../../components/Widget/Widget";
import { TextInput } from "../../../components/GenericInputsFormik";
import { useState } from "react";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import FullFormLoader from "../../../components/Loader/FullFormLoader";
import { doRequest } from "../../../helpers/requests";
import { useEffect } from "react";
import CountdownTimer from "../../../components/CountdownTimer/CountdownTimer";

export default function RecoverPassword() {
    const [state, setState] = useState("send");

    

    function resend(){
        if(state === "resend"){
            setState("send");
        }
    }


    return (
        <div className="auth-page">
            <Container className="h-100">
                <Row className="align-items-center h-100">
                    <Col xs={12} lg={7} className="mx-auto">
                        <Widget className="widget-p-lg">
                            <h5>Recuperar cuenta</h5>
                            <hr />
                            <div className="text-center mb-2">
                                <img
                                    src={`${origin}/images/logo.png`}
                                    alt="recovery-pass"
                                    style={{ maxWidth: "12%" }}
                                />
                            </div>
                            { state === "send" ? (
                                <FormEmail state={state} setState={setState} />
                            ) : (
                                <div className="text-dark">
                                    <p className="mt-1">
                                        Se ha enviado un correo de recuperación
                                        de contraseña a su correo electrónico.
                                        Siga las instrucciones en él para
                                        restablecer su contraseña.
                                    </p>
                                    <p className="mt-3">
                                        ¿No recibió el correo electrónico? Presione 
                                        <span className="text-info cursor-pointer" onClick={()=>resend()}> aquí </span> 
                                        para reenviar el correo.
                                    </p>
                                    {state === "await" && <p>Espere { <CountdownTimer seconds={300} onFinish={()=>setState("resend")} />} seg. antes de reenviar.</p>}
                                    
                                </div>
                            )}
                            <div className="mt-3">
                                <Link to="/" style={{ fontSize: "14px" }}>
                                    Regresar
                                </Link>
                            </div>
                        </Widget>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

function FormEmail({ state, setState }) {
    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState("");
    const origin = window.location.origin;

    const emailSq = Yup.string().email();

    async function handleSubmit(e) {
        e.preventDefault();
        try {
            if (emailSq.validateSync(email)) {
                const params = { action: "" };
                // const res = doRequest("", params, false);
                setState("await");
            }
        } catch (error) {}
    }
    return (
        <>
            <p className="text-dark mb-3">
                Ingrese su correo electrónico asociado a su cuenta para cambiar
                su contraseña.
            </p>
            <form action="" onSubmit={handleSubmit}>
                <TextInput
                    inputType="email"
                    isPlaceholder="Correo electrónico"
                    onChangeMethod={({ target }) => setEmail(target.value)}
                />
                <div className=" d-flex flex-md-row flex-column justify-content-center">
                    <Button
                        color="success"
                        type="submit"
                        className="mt-2 mt-md-3"
                    >
                        Enviar
                    </Button>
                </div>
            </form>
            <FullFormLoader show={loading} />
        </>
    );
}

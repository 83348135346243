import React from "react";
import { peticionesReceiver } from "../../../helpers/peticionesReceiver";
import { toast } from "react-toastify";
import Notification from "../../../components/Notification/Notification";
import { forEach } from "lodash";
import { doRequest } from "../../../helpers/requests";

const API = peticionesReceiver();

export const getLeavesTypes = async (setHook, isFullHook, prevStateSet) => {
  const params = {
    action: "select",
    table: "types_leaves",
    condition: {
      enabled: 1,
    },
    order: "nameTypeLeave ASC",
  };
  await API.peticion(params)
    .then((res) => {
      if (res.status === 200 && res.data.code === "200") {
        if (isFullHook) {
          setHook((prevState) => ({
            ...prevState,
            [prevStateSet]: res.data.data,
          }));
        } else {
          setHook(res.data.data);
        }
      } else {
        toast(
          <Notification
            type={"consultar_error"}
            backMessage={res.data.message}
            withIcon
          />
        );
      }
    })
    .catch((err) => {
      toast(<Notification type={"consultar_servidor_error"} withIcon />);
    });
};

export const getStatus = async (
  setHook,
  isFullHook,
  prevStateSet,
  keyKindStat,
  setObjEmployHistory
) => {
  const params = {
    action: "select",
    table: "status",
    condition: {
      enabled: 1,
      keyTypeStat: keyKindStat,
    },
    order: "nameStat ASC",
  };

  await API.peticion(params)
    .then((res) => {
      if (res.status === 200 && res.data.code === "200") {
        if (setObjEmployHistory !== undefined && setObjEmployHistory !== null) {
          res.data.data.forEach((e) => {
            if (
              e.nameStat === "HISTORICO DEL EMPLEADO " ||
              e.nameStat === "HISTORICO DEL EMPLEADO"
            ) {
              setObjEmployHistory(e);
            }
          });
        }

        if (isFullHook) {
          setHook((prevState) => ({
            ...prevState,
            [prevStateSet]: res.data.data,
          }));
        } else {
          setHook(res.data.data);
        }
      } else {
        toast(
          <Notification
            type={"consultar_error"}
            backMessage={res.data.message}
            withIcon
          />
        );
        return [];
      }
    })
    .catch((err) => {
      toast(
        <Notification
          type={"consultar_servidor_error"}
          backMessage={"getStatus"}
          withIcon
        />
      );
      return [];
    });
};

export const getApplicationSalaries = async (
  setHook,
  isFullHook,
  prevStateSet
) => {
  const params = {
    action: "select",
    table: "types_applicationsalary",
    condition: {
      enabled: 1,
    },
    order: "nameTypeApplicationSalary ASC",
  };
  await API.peticion(params)
    .then((res) => {
      if (res.status === 200 && res.data.code === "200") {
        if (isFullHook) {
          setHook((prevState) => ({
            ...prevState,
            [prevStateSet]: res.data.data,
          }));
        } else {
          setHook(res.data.data);
        }
      } else {
        toast(
          <Notification
            type={"consultar_error"}
            backMessage={res.data.message}
            withIcon
          />
        );
        return [];
      }
    })
    .catch((err) => {
      toast(<Notification type={"consultar_servidor_error"} withIcon />);
      return [];
    });
};

export const getKindToStatus = async (
  setHook,
  isFullHook,
  prevStateSet,
  nameModule,
  setObjEmployHistory
) => {
  const params = {
    action: "select",
    rows: "keyKindStat",
    table: "kindstatus",
    condition: {
      enabled: 1,
      nameKindStar: nameModule,
    },
    order: "nameKindStar ASC",
  };

  await API.peticion(params)
    .then((res) => {
      if (res.status === 200 && res.data.code === "200") {
        getStatus(
          setHook,
          isFullHook,
          prevStateSet,
          res.data.data[0].keyKindStat,
          setObjEmployHistory
        );
      } else {
        toast(
          <Notification
            type={"consultar_error"}
            backMessage={res.data.message}
            withIcon
          />
        );
      }
    })
    .catch((err) => {
      toast(
        <Notification
          type={"consultar_servidor_error"}
          backMessage={"getKindToStatus"}
          withIcon
        />
      );
    });
};

export const getIncapacitiesType = async (
  setHook,
  isFullHook,
  prevStateSet
) => {
  const params = {
    action: "select",
    table: "types_incapacitys",
    condition: {
      enabled: 1,
    },
    order: "nameTypeIncapacity asc",
  };
  await API.peticion(params)
    .then((res) => {
      if (res.status === 200 && res.data.code === "200") {
        if (isFullHook) {
          setHook((prevState) => ({
            ...prevState,
            [prevStateSet]: res.data.data,
          }));
        } else {
          setHook(res.data.data);
        }
      } else {
        toast(
          <Notification
            type={"consultar_error"}
            backMessage={res.data.message}
            withIcon
          />
        );
      }
    })
    .catch((err) => {
      toast(<Notification type={"consultar_servidor_error"} withIcon />);
    });
};

export const getPenaltysType = async (setHook, isFullHook, prevStateSet) => {
  const params = {
    action: "select",
    table: "types_penaltys",
    condition: {
      enabled: 1,
    },
    order: "namePenalty asc",
  };
  await API.peticion(params)
    .then((res) => {
      if (res.status === 200 && res.data.code === "200") {
        if (isFullHook) {
          setHook((prevState) => ({
            ...prevState,
            [prevStateSet]: res.data.data,
          }));
        } else {
          setHook(res.data.data);
        }
      } else {
        toast(
          <Notification
            type={"consultar_error"}
            backMessage={res.data.message}
            withIcon
          />
        );
      }
    })
    .catch((err) => {
      toast(<Notification type={"consultar_servidor_error"} withIcon />);
    });
};

export const getAbcensesType = async (setHook, isFullHook, prevStateSet) => {
  const params = {
    action: "select",
    table: "types_abcenses",
    condition: {
      enabled: 1,
    },
    order: "nameTypeAbcense asc",
  };
  await API.peticion(params)
    .then((res) => {
      if (res.status === 200 && res.data.code === "200") {
        var newArreglo = [];
        res.data.data.forEach((e) => {
          newArreglo.push({
            created: e.created,
            enabled: e.enabled,
            keyTypeApplicationSalary: e.keyTypeAbcense,
            nameTypeApplicationSalary: e.nameTypeAbcense,
          });
        });
        if (isFullHook) {
          setHook((prevState) => ({
            ...prevState,
            [prevStateSet]: newArreglo,
          }));
        } else {
          setHook(res.data.data);
        }
      } else {
        toast(
          <Notification
            type={"consultar_error"}
            backMessage={res.data.message}
            withIcon
          />
        );
      }
    })
    .catch((err) => {
      toast(<Notification type={"consultar_servidor_error"} withIcon />);
    });
};

export const getInstitutions = async (setHook, isFullHook, prevStateSet) => {
  const params = {
    action: "select",
    table: "institutions",
    condition: {
      enabled: 1,
    },
    order: "nameInstitution ASC",
  };

  try {
    const res = await API.peticion(params);
    if (res.status === 200 && res.data.code === "200") {
      if (isFullHook) {
        setHook((prevState) => ({
          ...prevState,
          [prevStateSet]: res.data.data,
        }));
      } else {
        setHook(res.data.data);
      }
    } else {
      toast(
        <Notification
          type={"consultar_error"}
          backMessage={res.data.message}
          withIcon
        />
      );
    }
  } catch (error) {
    toast(<Notification type={"consultar_servidor_error"} withIcon />);
  }
};

export const getTypesInsurance = async (setHook, isFullHook, prevStateSet, idModule) => {
  const params = {
    action: "multiselect",
    rows: "*",
    table: "types_insurance",
    conditions: `enabled = 1${idModule!==undefined ? ` AND idModule=${idModule}`: ""}`,
    order: "nameTypeInsurance ASC",
  };

  try {
    const res = await API.peticion(params);
    if (res.status === 200 && res.data.code === "200") {
      if (isFullHook) {
        setHook((prevState) => ({
          ...prevState,
          [prevStateSet]: res.data.data,
        }));
      } else {
        setHook(res.data.data);
      }
    } else {
      toast(
        <Notification
          type={"consultar_error"}
          backMessage={res.data.message}
          withIcon
        />
      );
    }
  } catch (error) {
    toast(<Notification type={"consultar_servidor_error"} withIcon />);
  }
};

export const getUnders = async (
  setHook,
  isFullHook,
  prevStateSet,
  sectionToConcept
) => {
  var params = "";
  if (sectionToConcept !== null && sectionToConcept !== undefined) {
    params = {
      action: "multiselect",
      table: "unders",
      rows: "idUnder,concat(under,' - ',nameUnder) as name",
      conditions: `enabled = 1 and keyRollUnder ${sectionToConcept}`,
      order: "cast(under as float) asc",
    };
  } else {
    params = {
      action: "multiselect",
      table: "unders",
      rows: "idUnder,concat(under,' - ',nameUnder) as name",
      conditions: "enabled = 1",
      order: "cast(under as float) asc",
    };
  }

  try {
    const res = await API.peticion(params);
    if (res.status === 200 && res.data.code === "200") {
      if (isFullHook) {
        setHook((prevState) => ({
          ...prevState,
          [prevStateSet]: res.data.data,
        }));
      } else {
        setHook(res.data.data);
      }
    } else {
      toast(
        <Notification
          type={"consultar_error"}
          backMessage={res.data.message}
          withIcon
        />
      );
    }
  } catch (error) {
    toast(<Notification type={"consultar_servidor_error"} withIcon />);
  }
};

export async function getStatusPeriod(set){
  const params = {
    action: "select",
    table: "status",
    condition: {
      enabled: 1,
      keyTypeStat: 40,
    },
    order: "nameStat ASC",
  };
  const res = await doRequest("receiver/receiver.php", params, false);
  set((prev) => ({ ...prev, statusPeriod: res }));
}
import { SelectTypeHeadSingle } from "../../../components/GenericInputsFormik";
import Widget from "../../../components/Widget/Widget";
import * as Yup from "yup"
import { useFormik } from "formik";
import { peticionesReceiver } from "../../../helpers/peticionesReceiver";
import Notification from "../../../components/Notification/Notification";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import { Button, Col, Row, Spinner } from "reactstrap";
import ModalConfirmation from "../../../components/Modal/ModalConfirmation";
import { GetPermissions } from "../../../libs/permissions/getPermissions";
import Datatable from "../../../components/Datatable/Datatable";
import ModalComponent from "../../../components/Modal/ModalDelete";

export default function AreasAutorizadas() {
    const API = peticionesReceiver();
    const [modalConfirmation, setModalConfirmation] = useState(false);
    const [areas, setAreas] = useState({})
    const [permisos, setPermisos] = useState("")
    const [loading, setLoading] = useState("")
    const [loadingAreas, setLoadingAreas] = useState(true);
    const [refresh, setRefresh] = useState(false);
    GetPermissions(setPermisos, setLoading);

    const defaultForm = {
        idArea: "",
    }

    const FormSchema = Yup.object().shape({
        idArea: Yup.string().required("Seleccione una area valida"),
    })

    async function getAreas() {
        setLoadingAreas(true);
        const params = {
            action: "multiselect",
            table: "areas",
            conditions: "enabled=1 AND idArea NOT IN (SELECT idArea FROM budgetauthorizedareas WHERE enabled = 1)",
            rows: "idArea,name"
        }
        try {
            const res = await API.peticion(params);
            if (res.status === 200 && res.data.code === "200") {
                setAreas(res.data.data);
                setLoadingAreas(false)
            } else {
                toast(<Notification type={"consultar_error"} backMessage={"Sin registros"} withIcon />);
            }
        } catch (err) {
            toast(<Notification type={"consultar_servidor_error"} withIcon />);
        }
    }

    useEffect(() => {
        getAreas()
    }, [])

    const { handleSubmit, values, handleBlur, errors, touched, setFieldValue, resetForm } = useFormik({
        initialValues: defaultForm,
        onSubmit: (values) => {
            setModalConfirmation(true)
        },
        validationSchema: FormSchema,
        enableReinitialize: true,
    });


    function onChange(e) {
        const { name, value } = e.target
        setFieldValue([name], value);
    }

    async function addArea(values) {
        const params = {
            action: "insert",
            table: "budgetauthorizedareas",
            rows: values,
            validate: [["idArea"]]
        }
        try {
            const res = await API.peticion(params);
            if (res.status === 200 && res.data.code === "200") {
                getAreas()
                resetForm()
                setRefresh(true);
                toast(<Notification type={"agrega_exito"} backMessage={res.data.message} withIcon />);
            } else {
                toast(<Notification type={"agrega_error"} backMessage={res.data.message} withIcon />);
            }
        } catch (err) {
            toast(<Notification type={"consultar_servidor_error"} withIcon />);
        }
    }

    const headers = ["Cve. Area", "Area", "Fecha", "Eliminar"];

    const columns = [
        { data: "areaKey" },
        { data: "name" },
        { data: "created" },
        {
            data: "keyBudgetAuthorizedArea",
            render: function (row) {
                return (
                    <>
                        {permisos.DEL && (
                            <ModalComponent
                                modalTitle="Eliminar"
                                id={row}
                                table={["budgetauthorizedareas"]}
                                nameColumn={["keyBudgetAuthorizedArea"]}
                                elimina={() => OnDelete(row)}
                                button={true}
                                specialIco={true}
                                fill={false}
                            >
                                <h6>
                                    ¿Seguro de eliminar el registro?
                                </h6>
                                No podrá recuperar la información después de ser eliminada
                            </ModalComponent>
                        )}
                    </>
                )
            }
        }
    ]

    async function OnDelete(row) {
        const params = {
            action: "delete",
            table: "budgetauthorizedareas",
            condition: {
                keyBudgetAuthorizedArea: row
            },
        };
        try {
            const res = await API.peticion(params)
            if (res.status === 200 && res.data.code === "200") {
                toast(<Notification type={"elimina_exito"} backMessage={res.data.message} withIcon />);
                setRefresh(true)
            } else {
                toast(<Notification type={"elimina_error"} backMessage={res.data.message} withIcon />);
            }
        } catch (err) {
            toast(<Notification type={"elimina_error"} withIcon />);
        }
    }

    const getParams = (order, records, page, search) => {
        const params = {
            action: "datatable",
            table: "budgetauthorizedareas b join areas a on (b.idArea=a.idArea)",
            rows: "a.areaKey,a.name,b.created,b.keyBudgetAuthorizedArea",
            conditions: "b.enabled=1",
            order,
            records: records.toString(),
            page: (page * records).toString(),
            search
        }
        return params
    }

    async function getData(order, records, page, search) {
        try {
            const res = await API.peticion(getParams(order, records, page, search));
            if (res.status === 200 && res.data.code === "200") {
                return res.data;
            } else {
                // toast(<Notification type={"consultar_error"} backMessage={res.data.message} />);
                return null;
            }
        } catch (err) {
            toast(<Notification type={"consultar_servidor_error"} withIcon />);
            return null
        }
    }

    if (loading) {
        return (
            <div className="d-flex align-items-center justify-content-center" style={{ height: "200px" }}>
                <Spinner
                    color="primary"
                    style={{
                        height: '3rem',
                        width: '3rem'
                    }}
                    type="grow"
                >
                    Loading...
                </Spinner>
            </div>
        )
    }
    return (
        <>
            <Widget className="widget-p-md">
                <h3 className="text-secondary">Áreas autorizadas</h3>
                {loadingAreas ? (
                    <div className="d-flex align-items-center justify-content-center" style={{ height: "200px" }}>
                        <Spinner
                            color="primary"
                            style={{
                                height: '3rem',
                                width: '3rem'
                            }}
                            type="grow"
                        >
                            Loading...
                        </Spinner>
                    </div>
                ) : (
                    <>
                        {
                            permisos.INS && (
                                < form onSubmit={handleSubmit}>
                                    <Row>
                                        <Col sm={12} md={8}>
                                            <SelectTypeHeadSingle
                                                label="Unidad de trabajo"
                                                inputName="idArea"
                                                onChangeMethod={onChange}
                                                onBlurMethod={handleBlur}
                                                value={values.idArea}
                                                isRequired
                                                touched={touched.idArea}
                                                errors={errors.idArea}
                                                optionsArray={areas}
                                                optionValue={"idArea"}
                                                optionName={"name"}
                                            />
                                        </Col>
                                        <Col sm={12} md={4}>
                                            <Button className="mt-4" type="submit">Agregar</Button>
                                        </Col>
                                    </Row>
                                </form>
                            )
                        }
                    </>
                )}
                {permisos.CON ? (
                    <Datatable
                        columns={columns}
                        headers={headers}
                        control="back"
                        petition={getData}
                        stateRefresh={[refresh, setRefresh]}
                        className="px-0 mt-3"
                    />
                ) : (
                    <div className='text-center mt-3 mb-1'>No tiene permitido ver los registros de esta seccion</div>
                )}
                <ModalConfirmation
                    modalTitle={"Registrar área"}
                    modal={modalConfirmation}
                    crear={(rows) => addArea(rows)}
                    setModal={setModalConfirmation}
                    values={values}
                >
                    <div className="d-flex justify-content-center">
                        <h6>¿Desea continuar con el registro?</h6>
                    </div>
                </ModalConfirmation>
            </Widget >
        </>
    )
}
/**
 * @param params setters from Format
 */
export function setConfigR32(params) {
    const { setHeaders, setPdfConfig, setExcelConfig, setHtmlConfig, setData, filter } = params;
    const headers = [
        "CONCEPTO",
        "TOTAL EMPLEADOS",
        "TOTAL IMPORTE"
    ]
    setHeaders(headers)
    const tempData = {
        action: "report32",
        search: "",
        order: "",
        year: (new Date(filter.year)).getFullYear(),
        month: filter.month
    }
    setData(tempData);
    const content = [
        { header: "TOTAL EMPLEADOS", value: "TotalEmpleados" },
        { header: "TOTAL IMPORTE", value: "TotalImporte" },
    ];
    setHtmlConfig({
        headers,
        id: { id: "nameUnder" },
        content: [content]
    })
    setPdfConfig({
        descr: tempData,
        headers,
        content: [{ header: "CONCEPTO", value: "nameUnder" }, ...content]
    });
    setExcelConfig({
        headers,
        content: [{ header: "CONCEPTO", value: "nameUnder" }, ...content]
    });
}
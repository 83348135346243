import { useState } from "react";
import Widget from "../../../../components/Widget/Widget";
import Consulta from "./Consulta";
import Detalle from "./Detalle";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import FullFormLoader from "../../../../components/Loader/FullFormLoader";
import { doRequest, findEmployee } from "../../../../helpers/requests";
import { GetPermissions } from "../../../../libs/permissions/getPermissions";

export default function CargaConstancias() {
    const [permissions, setPermissions] = useState({});
    GetPermissions(setPermissions);
    
    const [loading, setLoading] = useState(true);
    const [canUse, setCanUse] = useState(false);
    const [finiquito, setFiniquito] = useState({});
    const { profileType, id } = useSelector((state) => state.userInfo);
    const idArea = profileType.selectProfile.idArea;

    useEffect(()=>{
        checkArea();
    }, []);

    async function checkArea(){
        const params = {
            action: "select", 
            table: "settlementareas",
            condition: { idArea: idArea}
        }
        const res = await doRequest("receiver/receiver.php", params, false, false);
        if(res.length > 0){
            const res2 = await findEmployee(id);
            if(res2){
                if(+res[0].idStaffAproves === +res2.idStaff){
                    setCanUse(true);
                }
            }
            
        }
        setLoading(false);
    }

    return (
        <Widget className="widget-p-md">
            <h4>Carga de Constancias</h4>

            {finiquito.id && (
                <div className="d-flex flex-md-row flex-column-reverse justify-content-md-between justify-content-center mb-4">
                    <div className="d-flex flex-md-row flex-column">
                        <div className="d-flex justify-content-center">
                            {finiquito.src && (
                                <img src={finiquito.src} className="img-thumbnail w-100 exp-photo" alt="employe"/>
                            )}
                        </div>
                        <div className="text-dark mt-2 mt-md-0 pl-0 pl-md-2">
                            <p>
                                <strong>No. Empleado: </strong>
                                {finiquito.staffNumber}
                            </p>
                            <p>
                                <strong>Nombre: </strong>
                                {finiquito.fullName}
                            </p>
                            <p>
                                <strong>Puesto Laboral: </strong>
                                {finiquito.nameJob || "POR ASIGNAR"}
                            </p>
                            <p>
                                <strong>Adscripción Nominal: </strong>
                                {finiquito.nameArea || "POR ASIGNAR"}
                            </p>
                            <p>
                                <strong>Adscripción Fisica: </strong>
                                {finiquito.nameBuilding || "POR ASIGNAR"}
                            </p>
                            <p>
                                <strong>Estatus: </strong>
                                {finiquito.nameStat}
                            </p>
                        </div>
                    </div>
                    <div className="my-2 my-md-0 text-center">
                        <button
                            className="btn text-light btn-block"
                            style={{
                                backgroundColor: "#cc0a44",
                                border: "none",
                            }}
                            onClick={() => setFiniquito({})}
                        >
                            Cerrar expediente
                        </button>
                    </div>
                </div>
            )}

            {
                canUse ?
                    finiquito.id ?
                        <Detalle finiquito={finiquito} idArea={idArea} permissions={permissions}/> :
                        <Consulta setFiniquito={setFiniquito} idArea={idArea}/>
                    :
                    <div className="text-center py-5 my-5">
                        <h5>No cuenta con las credenciales necesarias para usar este módulo</h5>
                    </div>
            }

            <FullFormLoader show={loading} />
        </Widget>
    )
}
import React from "react";
import Widget from "../../../../components/Widget/Widget";
import FormCalculo from "../FormCalculo";
import { createCalculo } from "../functions";

export const CrearCalculoExtraordinario = (props) => {

  return (
    <Widget className="widget-p-md">
      <h3>Calculo Percepciones Extraordinarias</h3>
      <FormCalculo crear={createCalculo}/>
    </Widget>
  )
}

export default CrearCalculoExtraordinario;
import React, { useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Button, Row, Col } from "reactstrap";
import { peticionesReceiver } from "../../../../helpers/peticionesReceiver";
import ModalDelete from "../../../../components/Modal/ModalDelete";
import Datatable from "../../../../components/Datatable/Datatable";

import { toast } from "react-toastify";
import Notification from "../../../../components/Notification/Notification";
import ModalConfirmation from "../../../../components/Modal/ModalConfirmation";

import { TextInput } from "../../../../components/GenericInputsFormik/";

const CatalogoDocumentos = (props) => {
  const { permissions } = props;
  const API = peticionesReceiver();

  const [modalConfirmation, setModalConfirmation] = useState(false);
  const [refresh, setRefresh] = useState(false);

  const headers = ["#", "Tipo de archivo", "Fecha de subida", "Eliminar"];
  const columns = [
    { data: "index" },
    { data: "namePurchaseDoc" },
    { data: "created" },
    {
      data: null,
      render: function (row) {
        return permissions.DEL ? (
          <>
            <ModalDelete
              table={["purchasedocs"]}
              nameColumn={["keyPurchaseDoc"]}
              elimina={() => deleteFileType(row)}
              setRefresh={setRefresh}
              type="icon"
            >
              <h6>¿Seguro de eliminar el registro?</h6>
              No podrá recuperar la información después de ser eliminada
            </ModalDelete>
          </>
        ) : (
          "Accion no permitida"
        );
      },
    },
  ];

  const deleteFileType = async (obj) => {
    const params = {
      action: "delete",
      table: "purchasedocs",
      condition: { keyPurchaseDoc: obj.keyPurchaseDoc },
    };
    await API.peticion(params)
      .then((res) => {
        if (res.data.status === "success" && res.data.code === "200") {
          toast(
            <Notification
              type={"elimina_exito"}
              backMessage={res.data.message}
              withIcon
            />
          );
          setRefresh(true);
        } else {
          toast(
            <Notification
              type={"elimina_error"}
              backMessage={res.data.message}
              withIcon
            />,
            { closeButton: false }
          );
        }
      })
      .catch((err) => {
        toast(<Notification type={"consultar_servidor_error"} withIcon />);
      });
  };

  const columnDefs = [
    {
      targets: [0, 1, 2, 3, 4],
      className: "text-center",
    },
    {
      orderable: false,
      targets: [0],
    },
  ];

  const getParamsTableElmt = (order, records, page, search) => {
    const params = {
      action: "datatable",
      table: "purchasedocs",
      rows: "keyPurchaseDoc,namePurchaseDoc,created",
      conditions: `enabled = 1`,
      order,
      records: records.toString(),
      page: (page * records).toString(),
      search,
    };
    return params;
  };

  async function getData(order, records, page, search) {
    try {
      const res = await API.peticion(
        getParamsTableElmt(order, records, page, search)
      );
      if (res.status === 200 && res.data.code === "200") {
        const data = res.data;
        data.data.forEach((value, index) => (value.index = 1 + index));
        return data;
      } else {
        toast(
          <Notification
            type={"consultar_error"}
            backMessage={res.data.message}
          />
        );
        return null;
      }
    } catch (err) {
      toast(<Notification type={"consultar_servidor_error"} withIcon />);
      return null;
    }
  }

  const onChange = (e) => {
    const { name, value } = e.target;
    setFieldValue([name], value);
  };

  const saveDocument = async () => {
    const data = {
      action: "insert",
      table: "purchasedocs",
      rows: {
        namePurchaseDoc: values.namePurchaseDoc,
      },
      validate: [["namePurchaseDoc"]],
    };

    const res = await API.peticion(data);
    if (res.status === 200 && res.data.status === "success") {
      toast(
        <Notification
          type={"agrega_exito"}
          backMessage={res.data.message}
          withIcon
        />
      );
      setRefresh(true);
      resetForm();
    } else {
      toast(
        <Notification
          type={"agrega_error"}
          backMessage={res.data.message}
          withIcon
        />
      );
    }
  };

  const FormSchema = Yup.object().shape({
    namePurchaseDoc: Yup.string().required(
      "Ingrese el nombre del tipo de documento"
    ),
  });

  const {
    handleSubmit,
    values,
    errors,
    setFieldValue,
    touched,
    handleBlur,
    resetForm,
  } = useFormik({
    initialValues: {
      namePurchaseDoc: "",
    },
    onSubmit: () => {
      setModalConfirmation(true);
    },
    validationSchema: FormSchema,
    enableReinitialize: true,
  });

  return (
    <div>
      {permissions.CON ? (
        <>
          <form onSubmit={handleSubmit}>
            <div className="text-center mb-5">
              <h1>Documentos para compras</h1>
            </div>

            <Row className="d-flex align-items-center justify-content-around mb-5">
              <Col xs={12} lg={5}>
                <TextInput
                  label="Tipo de documento"
                  inputType="text"
                  inputName="namePurchaseDoc"
                  onChangeMethod={onChange}
                  onBlurMethod={handleBlur}
                  value={values.namePurchaseDoc}
                  touched={touched.namePurchaseDoc}
                  errors={errors.namePurchaseDoc}
                />
              </Col>
              <Col xs={12} lg={5} className="text-center">
                <Button type="submit" color="success">
                  Agregar
                </Button>
              </Col>
            </Row>

            <div>
              <Datatable
                headers={headers}
                columns={columns}
                columnDefs={columnDefs}
                petition={getData}
                control="back"
                stateRefresh={[refresh, setRefresh]}
                searching={true}
                order={{ col: 1, opt: "desc" }}
              />
            </div>

            <ModalConfirmation
              modalTitle={"Crear"}
              modal={modalConfirmation}
              setModal={setModalConfirmation}
              crear={saveDocument}
              isEdit={false}
              values={values}
            >
              <div className="text-center">
                Esta seguro de continuar con el registro
              </div>
            </ModalConfirmation>
          </form>
        </>
      ) : (
        "No tiene permisos para consultar  este modulo"
      )}
    </div>
  );
};

export default CatalogoDocumentos;

import React, { useState } from "react";
import { Alert } from "reactstrap";

import infoIcon from "../../assets/notifications/infoIcon.svg";
import warningIcon from "../../assets/notifications/warningIcon.svg";
import successIcon from "../../assets/notifications/successIcon.svg";
import dangerIcon from "../../assets/notifications/successIcon.svg";
import s from "./Notification.module.scss";


const notifications = {
  info: {
    icon: infoIcon,
    message:
      "<span className='body-2'>Info:</span> This alert needs your attention, but it's not important.",
    color: "#00A5FF",
    transparentColor: "#00A5FF50",
  },
  warning: {
    icon: warningIcon,
    message:
      "<span className='body-2'>Warning:</span> Best check yourself, you're not looking too good.",
    color: "#FFA100",
    transparentColor: "#FFA10050",
  },
  success: {
    icon: successIcon,
    message:
      "<span className='body-2'>Success:</span> You successfully read this important alert message.",
    color: "#43BC13",
    transparentColor: "#43BC1350",
  },
  error: {
    icon: dangerIcon,
    message:
      "<span className='body-2'>Danger:</span> Danger: Change this and that and try again.",
    color: "#FF4B23",
    transparentColor: "#FF4B2350",
  },
  consultar_servidor_error: {
    icon: warningIcon,
    message: "Ocurrió un problema al conectarse al servidor.",
    color: "#FF4B23",
    transparentColor: "#FF4B2350",
  },
  consultar_error: {
    icon: warningIcon,
    message: "No es posible obtener la información.",
    color: "#FF4B23",
    transparentColor: "#FF4B2350",
  },
  agrega_exito: {
    icon: successIcon,
    message: "Registro agregado con éxito.",
    color: "#43BC13",
    transparentColor: "#43BC1350",
  },
  agrega_error: {
    icon: warningIcon,
    message: "Error al agregar registro.",
    color: "#FF4B23",
    transparentColor: "#FF4B2350",
  },
  modifica_exito: {
    icon: successIcon,
    message: "Registro modificado con éxito.",
    color: "#43BC13",
    transparentColor: "#43BC1350",
  },
  modifica_error: {
    icon: warningIcon,
    message: "Error al modificar registro.",
    color: "#FF4B23",
    transparentColor: "#FF4B2350",
  },
  elimina_error: {
    icon: warningIcon,
    message: "Error al eliminar registro.",
    color: "#FF4B23",
    transparentColor: "#FF4B2350",
  },
  elimina_exito: {
    icon: successIcon,
    message: "Registro eliminado con éxito.",
    color: "#43BC13",
    transparentColor: "#43BC1350",
  },
  elimina_cancelado: {
    icon: infoIcon,
    message: "Eliminación cancelada.",
    color: "#00A5FF",
    transparentColor: "#00A5FF50",
  },
  sin_datos: {
    icon: infoIcon,
    message: "No se encontraron registros.",
    color: "#00A5FF",
    transparentColor: "#00A5FF50",
  },
};

export default function Notification(props) {
  const [notificationOpen, setNotificationClose] = useState(true);

  const closeNotification = () => {
    setNotificationClose(!notificationOpen);
  };

  const notificationStyle = () => {
    return props.transparent
      ? {
          backgroundColor: notifications[props.type].transparentColor,
          color: notifications[props.type].color,
        }
      : { backgroundColor: notifications[props.type].color };
  };

  return (
    <>
      <Alert
        className={s.notificationContainer}
        style={notificationStyle()}
        isOpen={notificationOpen}
        toggle={() => closeNotification()}
      >
        <div className={s.notificationIconContainer}>
          {props.withIcon && (
            <img src={notifications[props.type].icon} alt="..." />
          )}
        </div>
        <div className={s.messageContainer}>
          <span
            dangerouslySetInnerHTML={{
              __html:
                props.backMessage !== null &&
                props.backMessage !== undefined &&
                props.backMessage !== ""
                  ? props.backMessage
                  : notifications[props.type].message,
            }}
          ></span>
        </div>
      </Alert>
    </>
  );
}

import React, { useState, useEffect } from "react";
import {
  Col,
  Row,
  Button,
  Collapse,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as Yup from "yup";
import { peticionesReceiver } from "../../../helpers/peticionesReceiver";
import Loader from "../../../components/Loader/Loader";
import Notification from "../../../components/Notification/Notification";
import ModalConfirmation from "../../../components/Modal/ModalConfirmation";
import TableComponent from "../../../components/TableComponent/TableComponentOnePage";
import { SelectTypeHeadSingle, TextInput } from "../../../components/GenericInputsFormik/index";
import FullScreenLoader from "../../../components/Loader/FullScreenLoader";
import { YearInput } from "../../../components/GenericInputsFormik/YearInput";
import { fDateInput } from "../../../libs/tools/format";
import { NumberInput } from "../../../components/GenericInputsFormik/NumberInput";

const cYear = new Date().getFullYear();
const PersonalEscolaridad = ({ idStaff, permissions }) => {
  const [modal, setModal] = useState(false);
  const API = peticionesReceiver();
  //datos
  const [schoolarityTypes, setSchoolarityTypes] = useState([]);
  const [loadingSchoolarityTypes, setLoadingSchoolarityTypes] = useState(true);
  const [careerTypes, setCarerTypes] = useState([]);
  // const [loadingCarerTypes, setLoadingCarerTypes] = useState(true);
  const [degreeTypes, setDegreeTypes] = useState([]);
  const [loadingDegreeTypes, setLoadingDegreeTypes] = useState(true);
  const [cities, setCities] = useState([]);
  const [counties, setCounties] = useState([]);
  const [states, setStates] = useState([]);
  const [countries, setCountries] = useState([]);
  const [editando, setEditando] = useState(false);

  //collapse
  const [collapse, setCollapse] = useState(false);
  const toggle = () => setCollapse(!collapse);
  const [finalData, setFinalData] = useState({});
  const [loadingCheck, setLoadingCheck] = useState(false);
  const [schoolarity] = useState({
    idStaffSchoolarity: "",
    idStaff: idStaff,
    keySchoolarityType: "",
    keyCity: "",
    keyDegreeType: "",
    keyCareerType: "",
    school: "",
    startDate: "",
    endDate: "",
    degreeDate: "",
    degreeNumber: "",
    finished: false,
    keyCountry: "",
    keyState: "",
    keyCounty: "",
  });
  const [cabeceras] = useState([
    "Id",
    "Escuela",
    "A.Inicio",
    "A.Termino",
    "T.Escuela",
    "Carrera",
    "Tipo de grado",
    "Ciudad",
    "Editar",
    "Eliminar",
  ]);
  const [filtro] = useState([
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    false,
    false,
    false,
  ]);

  const [modalCed, setModalCed] = useState(false);

  const consulta = {
    action: "datatable",
    table:
      "staffschoolarity A INNER JOIN schoolaritytypes B on (A.keySchoolarityType = B.keySchoolarityType) INNER JOIN careertypes C on(A.keyCareerType = C.keyCareerType) INNER JOIN degreetypes D on (A.keyDegreeType = D.keyDegreeType) LEFT JOIN cities E ON (A.keyCity = E.keyCity) LEFT JOIN counties F on (E.keyCounty = F.keyCounty) LEFT JOIN states G on (F.keyState = G.keyState) LEFT JOIN countries H on (G.keyCountry = H.keyCountry)",
    rows: "A.idStaffSchoolarity,A.school,YEAR(A.startDate) AS startDate,IF(ISNULL(A.endDate),'VIGENTE', YEAR(A.endDate)) as fechatermino,B.nameSchoolarityType,C.nameCareerType,D.nameDegreeType,E.nameCity,A.finished,A.degreeDate,A.degreeNumber,A.otherCareer,B.keySchoolarityType,C.keyCareerType,D.keyDegreeType,E.keyCity,F.keyCounty,G.keyState,H.keyCountry",
    conditions: `A.enabled = 1 AND idStaff=${idStaff}`,
    page: 0,
    records: 5,
    search: "",
    order: "A.created desc",
  };
  const [parametros, setParametros] = useState(consulta);


  useEffect(() => {
    getSchoolarityTypes();
    getDegreeTypes();
    getPaises();
  }, []);

  const getSchoolarityTypes = async () => {
    const data = {
      action: "select",
      table: "schoolaritytypes",
      order: "nameSchoolarityType ASC",
    };
    API.peticion(data)
      .then((res) => {
        if (res.status === 200 && res.data.status === "success") {
          setSchoolarityTypes(res.data.data);
          setLoadingSchoolarityTypes(false);
        } else {
          toast(
            <Notification
              type={"consultar_error"}
              backMessage={res.data.message}
              withIcon
            />
          );
        }
      })
      .catch((err) => {
        toast(<Notification type={"consultar_error"} withIcon />);
      });
  };

  const getCareerType = async (idScholarity) => {
    const data = {
      action: "select",
      table: "careertypes",
      order: "nameCareerType ASC",
      condition: {
        enabled: 1,
        keySchoolarityType: idScholarity,
      },
    };
    API.peticion(data)
      .then((res) => {
        if (res.status === 200 && res.data.status === "success") {
          setCarerTypes(res.data.data);
        } else {
          toast(
            <Notification
              type={"consultar_error"}
              backMessage={res.data.message}
              withIcon
            />
          );
        }
      })
      .catch((err) => {
        toast(<Notification type={"consultar_servidor_error"} withIcon />);
      });
  };

  const getDegreeTypes = async () => {
    const data = {
      action: "select",
      table: "degreetypes",
      order: "nameDegreeType ASC",
    };
    API.peticion(data)
      .then((res) => {
        if (res.status === 200 && res.data.status === "success") {
          setDegreeTypes(res.data.data);
          setCarerTypes([]);
          setLoadingDegreeTypes(false);
        } else {
          toast(
            <Notification
              type={"consultar_error"}
              backMessage={res.data.message}
              withIcon
            />
          );
        }
      })
      .catch((err) => {
        toast(<Notification type={"consultar_servidor_error"} withIcon />);
      });
  };

  const getPaises = async () => {
    const params = {
      action: "select",
      table: "countries",
      order: "nameCountry ASC",
      condition: {
        enabled: 1,
      },
    };
    await API.peticion(params)
      .then((res) => {
        if (res.status === 200 && res.data.status === "success") {
          const datos = [];
          res.data.data.map((element) => {
            datos.push([element["keyCountry"], element["nameCountry"]]);
          });
          setCountries(datos);
        } else {
          toast(
            <Notification
              type={"consultar_error"}
              backMessage={res.data.message}
              withIcon
            />
          );
        }
      })
      .catch((err) => {
        toast(<Notification type={"consultar_servidor_error"} withIcon />);
      });
  };

  const getEstados = async (idPais) => {
    const params = {
      action: "select",
      table: "states",
      order: "nameState ASC",
      condition: {
        enabled: 1,
        keyCountry: idPais,
      },
    };
    await API.peticion(params)
      .then((res) => {
        if (res.status === 200 && res.data.status === "success") {
          const datos = [];
          res.data.data.map((element) => {
            datos.push([element["keyState"], element["nameState"]]);
          });
          setStates(datos);
        } else {
          if (res.data.message === "Sin registros en la consulta") {
            toast(
              <Notification
                type={"consultar_error"}
                backMessage={"El pais no contiene estados"}
                withIcon
              />
            );
          } else {
            toast(
              <Notification
                type={"consultar_error"}
                backMessage={res.data.message}
                withIcon
              />
            );
          }
        }
        setCounties([]);
        setCities([]);
      })
      .catch((err) => {
        toast(<Notification type={"consultar_servidor_error"} withIcon />);
      });
  };

  const getMunicipios = async (idEstado) => {
    const params = {
      action: "select",
      table: "counties",
      order: "nameCounty ASC",
      condition: {
        enabled: 1,
        keyState: idEstado,
      },
    };
    await API.peticion(params)
      .then((res) => {
        if (res.status === 200 && res.data.status === "success") {
          const datos = [];
          res.data.data.map((element) => {
            datos.push([element["keyCounty"], element["nameCounty"]]);
          });
          setCounties(datos);
        } else {
          if (res.data.message === "Sin registros en la consulta") {
            toast(
              <Notification
                type={"consultar_error"}
                backMessage={"El estado no contiene municipios"}
                withIcon
              />
            );
          } else {
            toast(
              <Notification
                type={"consultar_error"}
                backMessage={res.data.message}
                withIcon
              />
            );
          }
        }
        setCities([]);
      })
      .catch((err) => {
        toast(<Notification type={"consultar_servidor_error"} withIcon />);
      });
  };

  const getCiudades = async (idMunicipio) => {
    const params = {
      action: "select",
      table: "cities",
      order: "nameCity ASC",
      condition: {
        enabled: 1,
        keyCounty: idMunicipio,
      },
    };
    await API.peticion(params)
      .then((res) => {
        if (res.status === 200 && res.data.status === "success") {
          const datos = [];
          res.data.data.map((element) => {
            datos.push([element["keyCity"], element["nameCity"]]);
          });
          setCities(datos);
        } else {
          if (res.data.message === "Sin registros en la consulta") {
            toast(
              <Notification
                type={"consultar_error"}
                backMessage={"El municipio no contiene ciudades"}
                withIcon
              />
            );
          } else {
            toast(
              <Notification
                type={"consultar_error"}
                backMessage={res.data.message}
                withIcon
              />
            );
          }
        }
      })
      .catch((err) => {
        toast(<Notification type={"consultar_servidor_error"} withIcon />);
      });
  };

  const limpia = (resetForm) => {
    setCarerTypes([]);
    setEditando(false);
    setCollapse(false);
    resetForm();
  };

  const agregar = async (schoolarity, reset) => {
    const data = {
      action: "insert",
      table: "staffschoolarity",
      rows: schoolarity,
      validate: [["school", "keyCareerType", "idStaff"]],
    };
    API.peticion(data)
      .then((res) => {
        if (res.status === 200 && res.data.status === "success") {
          toast(
            <Notification
              type={"agrega_exito"}
              backMessage={res.data.message}
              withIcon
            />
          );
          setParametros(consulta);
          limpia(reset);
          setCollapse(false);
        } else {
          toast(
            <Notification
              type={"agrega_error"}
              backMessage={res.data.message}
              withIcon
            />
          );
        }
      })
      .catch((err) => {
        toast(err);
        toast(<Notification type={"consultar_servidor_error"} withIcon />);
      });
  };

  const modoEdicion = async (schoolarity) => {
    if (!collapse) {
      setFieldValue("idStaffSchoolarity", schoolarity[0]);
      setFieldValue("school", schoolarity[1]);
      setFieldValue("startDate", schoolarity[2]);
      setFieldValue("endDate", schoolarity[3] === "VIGENTE" ? "" : schoolarity[3]);
      setFieldValue("finished", schoolarity[8] === "1" || schoolarity[9] === 1 ? true : false);
      setFieldValue("degreeDate", schoolarity[9] ? schoolarity[9].split("-")[0] : "");
      setFieldValue("degreeNumber", schoolarity[10]);
      setFieldValue("otherCareer", schoolarity[11]);
      setFieldValue("keySchoolarityType", schoolarity[12]);
      setFieldValue("keyCareerType", schoolarity[13]);
      setFieldValue("keyDegreeType", schoolarity[14]);
      setFieldValue("keyCountry", schoolarity[18]);
      setFieldValue("keyState", schoolarity[17]);
      setFieldValue("keyCounty", schoolarity[16]);
      setFieldValue("keyCity", schoolarity[15]);
      setEditando(true);
      setCollapse(true);
    } else {
      toast(
        <Notification
          type="warning"
          backMessage="Guarde sus cambios antes de continuar"
        />
      );
    }
  };
  const editar = async (schoolarity, reset) => {
    const data = {
      action: "update",
      table: "staffschoolarity",
      rows: schoolarity,
      condition: {
        idStaffSchoolarity: schoolarity.idStaffSchoolarity,
      },
      validate: [["school", "keyCareerType", "idStaff"]],
    };
    API.peticion(data)
      .then((res) => {
        if (res.status === 200 && res.data.status === "success") {
          toast(
            <Notification
              type={"modifica_exito"}
              backMessage={res.data.message}
              withIcon
            />
          );
          setParametros(consulta);
          limpia(reset);
          setCollapse(false);
        } else {
          toast(
            <Notification
              type={"modifica_error"}
              backMessage={res.data.message}
              withIcon
            />
          );
        }
      })
      .catch((err) => {
        toast(<Notification type={"consultar_servidor_error"} withIcon />);
      });
  };

  const enviaDatos = () => {
    setFinalData((prevState) => ({
      ...prevState,
      idStaffSchoolarity: values.idStaffSchoolarity,
      idStaff: idStaff,
      keySchoolarityType: values.keySchoolarityType,
      keyCity: values.keyCity,
      keyDegreeType: values.keyDegreeType,
      keyCareerType: values.keyCareerType == 0 ? "" : values.keyCareerType,
      school: values.school,
      startDate: fDateInput(new Date(values.startDate, 0, 1)),
      endDate: values.endDate ? fDateInput(new Date(values.endDate, 0, 1)) : "",
      degreeDate: values.degreeDate ? fDateInput(new Date(values.degreeDate, 0, 1)) : "",
      degreeNumber: values.degreeNumber,
      finished: values.finished === true ? 1 : 0,
      otherCareer: values.otherCareer,
    }));
    setModal(true);
  };
  
  const FormSchema = Yup.object().shape({
    keySchoolarityType: Yup.number()
      .required("Seleccione una escolaridad")
      .min(1, "Seleccione una escolaridad"),
    keyDegreeType: Yup.number()
      .required("Seleccione un documento")
      .min(1, "Seleccione un documento"),
    keyCareerType: Yup.number()
      .when("keySchoolarityType", {
        is: 0,
        then: Yup.number().required("seleccione una escolaridad"),
      })
      .required("Seleccione una carrera")
      .min(1, "Seleccione una carrera"),
    school: Yup.string().required("Ingrese un el nombre de la escuela"),
    startDate: Yup.number().max(cYear, "Año inválido"),
    //.required("Ingrese una fecha de ingreso"),
    finished: Yup.boolean(),
    endDate: Yup.number()
      .when("finished", {
        is: true,
        then: Yup.number()
          .required("Ingrese la fecha de termino")
          .min(Yup.ref("startDate"), `La fecha debe ser mayor o igual a la de inicio`)
          .max(cYear, "Año inválido"),
      }),
      degreeDate: Yup.number().when("finished", {
        is: true,
        then: Yup.number()
          .min(Yup.ref("endDate"), `La fecha debe ser mayor o igual a la de término`)
          .max(cYear, "Año inválido"),
      }),
    degreeNumber: Yup.string().when("finished", {
      is: true,
      then: Yup.string().min(7, "el numero debe contener al menos 7 caracteres")
    }),
  });

  const {
    handleSubmit,
    values,
    handleBlur,
    errors,
    touched,
    setFieldValue,
    resetForm,
  } = useFormik({
    initialValues: schoolarity,
    onSubmit: async (values) => {
      if (values.degreeNumber !== "") {
        const res = await checkCedula(values.degreeNumber);
        if (!res) {
          setModalCed(true);
        } else {
          enviaDatos();
        }
      } else {
        enviaDatos();
      }
    },
    validationSchema: FormSchema,
    enableReinitialize: true,
  });

  async function checkCedula(value) {
    const API = peticionesReceiver();
    setLoadingCheck(true);
    try {
      const params = {
        action: "validarCedula",
        cedula: value,
      };
      const res = await API.peticionEndPoint(
        params,
        "app/facades/identity/identityF.php"
      );
      if (res.status === 200 && res.data.data.estatus !== "ERROR") {
        setLoadingCheck(false);
        return true;
      } else {
        throw new Error(res.data.message);
      }
    } catch (err) {
      setLoadingCheck(false);
      return false;
    }
  }

  const onChange = (e) => {
    if(!!e.target.value) {
      if (e.target.name === "keyCountry") {
        setStates([]);
        setCounties([]);
        setCities([]);
        getEstados(e.target.value);
      } else if (e.target.name === "keyState") {
        setCounties([]);
        setCities([]);
        getMunicipios(e.target.value);
      } else if (e.target.name === "keyCounty") {
        setCities([]);
        getCiudades(e.target.value);
      } else if (e.target.name === "keySchoolarityType") {
        setCarerTypes([]);
        getCareerType(e.target.value);
      }
    }else if (e.target.name === "startDate") {
      setFieldValue("endDate", "");
      setFieldValue("degreeDate", "");
    }

    if (e.target.name === "finished") {
      setFieldValue("finished", values.finished === true ? false : true);
      if (values.finished === true) {
        setFieldValue("endDate", "");
        setFieldValue("degreeDate", "");
        setFieldValue("degreeNumber", "");
      }
    } else {
      setFieldValue(e.target.name, e.target.value);
    }
  };

  useEffect(() => {
    if (values.idStaffSchoolarity) {
      getCareerType(values.keySchoolarityType);
      getEstados(values.keyCountry).finally(() => {
        getMunicipios(values.keyState).finally(() => {
          getCiudades(values.keyCounty);
        });
      });
    }
  }, [editando]);

  useEffect(() => {
    if (values.idStaffSchoolarity) {
      getEstados(values.keyCountry).finally(() => {
        getMunicipios(values.keyState).finally(() => {
          getCiudades(values.keyCounty);
        });
      });
    }
  }, [editando]);

  return (
    <div>
      {!loadingSchoolarityTypes && !loadingDegreeTypes ? (
        <div>
          <TableComponent
            titulo="Escolaridad"
            cabecerasTabla={cabeceras}
            filtro={filtro}
            editar={modoEdicion}
            parametros={parametros}
            limpia={limpia}
            reset={resetForm}
            permissions={permissions}
          ></TableComponent>
          <ModalConfirmation
            modalTitle={editando ? "Editar" : "Crear"}
            modal={modal}
            setModal={setModal}
            crear={agregar}
            editar={editar}
            isEdit={editando}
            values={finalData}
            reset={resetForm}
          >
            {editando ? (
              <div className="d-flex justify-content-center">
                <h6>¿Está seguro de editar el registro?</h6>
              </div>
            ) : (
              <div className="d-flex justify-content-center">
                <h6>¿Desea continuar con el registro?</h6>
              </div>
            )}
          </ModalConfirmation>

          {permissions.INS && (
            <div className="d-grid d-flex justify-content-center justify-content-sm-end">
              <Button
                color="add"
                onClick={toggle}
                style={{ marginBottom: "1rem" }}
                disabled={collapse}
              >
                Agregar
              </Button>
            </div>
          )}

          <Collapse isOpen={collapse}>
            <div>
              <Col className="d-grid d-flex justify-content-sm-end justify-content-center">
                <h6 className=" text-center">
                  Estado:{" "}
                  <span className="text-primary">
                    {editando ? "Editando" : "Guardando"}{" "}
                  </span>{" "}
                </h6>
              </Col>
              <div className="headline-1 text-center">Escolaridad</div>
              <form onSubmit={handleSubmit}>
                <Row>
                  <Col xs={12}>
                    <p className="mb-4 body-3 muted ml-2">Datos Generales</p>
                  </Col>
                </Row>

                {/* Escolaridad */}
                <Row className="d-flex justify-content-around">
                  <Col xs={12} md={12} lg={5}>
                    <div className="form-group">
                      <SelectTypeHeadSingle
                        label="Tipo de escolaridad"
                        isRequired={true}
                        inputName="keySchoolarityType"
                        optionsArray={schoolarityTypes}
                        defaultOption="Seleccione una escolaridad"
                        onChangeMethod={onChange}
                        onBlur={handleBlur}
                        isDisabled={false}
                        value={values.keySchoolarityType}
                        touched={touched.keySchoolarityType}
                        errors={errors.keySchoolarityType}
                        optionValue="keySchoolarityType"
                        optionName="nameSchoolarityType"
                      />
                    </div>
                  </Col>

                  <Col xs={12} md={12} lg={5}>
                    <div className="form-group">
                      <SelectTypeHeadSingle
                        label="Carrera"
                        isRequired={true}
                        inputName="keyCareerType"
                        optionsArray={careerTypes}
                        defaultOption="Seleccione una carrera"
                        onChangeMethod={onChange}
                        onBlur={handleBlur}
                        isDisabled={values.keySchoolarityType ? false : true}
                        value={values.keyCareerType}
                        touched={touched.keyCareerType}
                        errors={errors.keyCareerType}
                        optionValue="keyCareerType"
                        optionName="nameCareerType"
                      />
                    </div>
                  </Col>
                </Row>

                <Row className="d-flex justify-content-around">
                  <Col xs={12} md={12} lg={5}>
                    <div className="form-group">
                      <SelectTypeHeadSingle
                        label="Documento obtenido"
                        isRequired={true}
                        inputName="keyDegreeType"
                        optionsArray={degreeTypes}
                        defaultOption="Seleccione un grado"
                        onChangeMethod={onChange}
                        onBlur={handleBlur}
                        isDisabled={false}
                        value={values.keyDegreeType}
                        touched={touched.keyDegreeType}
                        errors={errors.keyDegreeType}
                        optionValue="keyDegreeType"
                        optionName="nameDegreeType"
                      />
                    </div>
                  </Col>

                  <Col xs={12} md={12} lg={5}>
                    <TextInput
                      label="Escuela"
                      inputType="text"
                      inputName="school"
                      onChangeMethod={onChange}
                      onBlurMethod={handleBlur}
                      value={values.school}
                      touched={touched.school}
                      errors={errors.school}
                      isRequired
                    />
                  </Col>
                </Row>

                <Row>
                  <Col xs={12}>
                    <p className="mb-4 body-3 muted ml-2">Dirección</p>
                  </Col>
                </Row>

                <Row className="d-flex justify-content-around">
                  <Col xs={12} md={12} lg={5}>
                    <div className="form-group">
                      <SelectTypeHeadSingle
                        label="Pais de la institución/escuela"
                        inputName="keyCountry"
                        optionsArray={countries}
                        defaultOption="Seleccione un pais"
                        onChangeMethod={onChange}
                        onBlur={handleBlur}
                        isDisabled={false}
                        value={values.keyCountry}
                        touched={touched.keyCountry}
                        errors={errors.keyCountry}
                        optionValue="0"
                        optionName="1"
                      />
                    </div>
                  </Col>

                  <Col xs={12} md={12} lg={5}>
                    <div className="form-group">
                      <SelectTypeHeadSingle
                        label="Estado de la institución/escuela"
                        inputName="keyState"
                        optionsArray={states}
                        defaultOption="Seleccione un estado"
                        onChangeMethod={onChange}
                        onBlur={handleBlur}
                        isDisabled={states.length > 0 && values.keyCountry ? false : true}
                        value={values.keyState}
                        touched={touched.keyState}
                        errors={errors.keyState}
                        optionValue="0"
                        optionName="1"
                      />
                    </div>
                  </Col>
                </Row>

                {/* estados */}
                <Row className="d-flex justify-content-around">
                  <Col xs={12} md={12} lg={5}>
                    <div className="form-group">
                      <SelectTypeHeadSingle
                        label="Municipio de la institución/escuela"
                        inputName="keyCounty"
                        optionsArray={counties}
                        defaultOption="Seleccione un municipio"
                        onChangeMethod={onChange}
                        onBlur={handleBlur}
                        isDisabled={counties.length > 0 && values.keyState ? false : true}
                        value={values.keyCounty}
                        touched={touched.keyCounty}
                        errors={errors.keyCounty}
                        optionValue="0"
                        optionName="1"
                      />
                    </div>
                  </Col>

                  {/* ciudad */}
                  <Col xs={12} md={12} lg={5}>
                    <div className="form-group">
                      <SelectTypeHeadSingle
                        label="Colonia de la institución/escuela"
                        inputName="keyCity"
                        optionsArray={cities}
                        defaultOption="Seleccione una colonia"
                        onChangeMethod={onChange}
                        onBlur={handleBlur}
                        isDisabled={cities.length > 0 && values.keyCounty ? false : true}
                        value={values.keyCity}
                        touched={touched.keyCity}
                        errors={errors.keyCity}
                        optionValue="0"
                        optionName="1"
                      />
                    </div>
                  </Col>
                </Row>

                <Row className="d-flex justify-content-around">
                  <Col xs={12}>
                    <p className="mb-4 body-3 muted ml-2">Periodo</p>
                  </Col>
                </Row>

                <Row className="d-flex justify-content-around">
                  <Col xs={12} md={12} lg={5}>
                    {/* <YearInput
                      label="Año de Inicio"
                      inputName="startDate"
                      onChangeMethod={onChange}
                      onBlurMethod={handleBlur}
                      value={values.startDate}
                      touched={touched.startDate}
                      errors={errors.startDate}
                    /> */}
                    <NumberInput
                      label="Año de Inicio"
                      inputName="startDate"
                      onChangeMethod={onChange}
                      onBlurMethod={handleBlur}
                      value={values.startDate}
                      touched={touched.startDate}
                      errors={errors.startDate}
                      maxLength={4}
                      isMax={cYear}
                      disableSep
                    />
                  </Col>

                  {/* Finalizado */}
                  <Col xs={12} md={12} lg={5}>
                    <div className="form-group d-flex justify-content-center">
                      <label htmlFor="finished" className="fs-5">
                        Finalización de la escolaridad
                      </label>
                      <input
                        type="checkbox"
                        name="finished"
                        className="form-control"
                        onChange={onChange}
                        value={values.finished}
                        checked={values.finished}
                      />
                    </div>
                  </Col>
                </Row>
                {/* escuela */}

                {/* F inicio */}
                <Row className="d-flex justify-content-around">
                  {/* F.Termino */}
                  <Col xs={12} md={12} lg={5}>
                    {/* <YearInput
                      label="Año de Término"
                      inputName="endDate"
                      onChangeMethod={onChange}
                      onBlurMethod={handleBlur}
                      value={values.endDate}
                      touched={touched.endDate}
                      errors={errors.endDate}
                      isDisabled={!values.finished}
                      isMin={values.startDate}
                      isRequired={values.finished}
                    /> */}
                    <NumberInput
                      label="Año de Término"
                      inputName="endDate"
                      onChangeMethod={onChange}
                      onBlurMethod={handleBlur}
                      value={values.endDate}
                      touched={touched.endDate}
                      errors={errors.endDate}
                      maxLength={4}
                      isMax={cYear}
                      isMin={+values.startDate}
                      isDisabled={!values.finished}
                      disableSep
                      isRequired={values.finished}
                    />
                  </Col>
                </Row>

                <Row className="d-flex justify-content-around">
                  <Col xs={12} md={12} lg={5}>
                    {/* <YearInput
                      label="Año de titulación/conclusión"
                      inputName="degreeDate"
                      onChangeMethod={onChange}
                      onBlurMethod={handleBlur}
                      value={values.degreeDate}
                      touched={touched.degreeDate}
                      errors={errors.degreeDate}
                      isDisabled={!values.endDate}
                      isMin={values.endDate}
                    /> */}
                    <NumberInput
                      label="Año de titulación/conclusión"
                      inputName="degreeDate"
                      onChangeMethod={onChange}
                      onBlurMethod={handleBlur}
                      value={values.degreeDate}
                      touched={touched.degreeDate}
                      errors={errors.degreeDate}
                      maxLength={4}
                      isMax={cYear}
                      isMin={+values.endDate}
                      isDisabled={!values.endDate}
                      disableSep
                    />
                  </Col>
                  {/* Numero de grado */}
                  <Col xs={12} md={12} lg={5}>
                    <div className="form-group">
                      <label htmlFor="degreeNumber">
                        No. de cédula/documento
                      </label>
                      <input
                        name="degreeNumber"
                        type="number"
                        className={
                          "form-control" +
                          (errors.degreeNumber &&
                          touched.degreeNumber &&
                          values.degreeDate
                            ? " is-invalid"
                            : "")
                        }
                        onBlur={handleBlur}
                        onChange={onChange}
                        value={values.degreeNumber}
                        disabled={
                          !values.degreeDate || values.degreeDate === ""
                            ? true
                            : false
                        }
                      />
                      {touched.degreeNumber &&
                        errors.degreeNumber &&
                        values.degreeDate && (
                          <span style={{ color: "red" }}>
                            {errors.degreeNumber}
                          </span>
                        )}
                    </div>
                  </Col>
                </Row>

                <div className="row mt-3">
                  <div className="col-sm-6 order-2 order-sm-1 text-center text-sm-left mb-4">
                    <Button
                      color="danger"
                      type="reset"
                      onClick={() => limpia(resetForm)}
                    >
                      Cancelar
                    </Button>
                  </div>
                  <div className="col-sm-6 order-1 order-sm-2 text-center text-sm-right mb-4">
                    <Button color="success" type="submit">
                      Guardar
                    </Button>
                  </div>
                </div>
              </form>
            </div>
          </Collapse>
        </div>
      ) : (
        <Loader />
      )}
      <ValidateCedula
        visible={modalCed}
        handleCloseModal={() => setModalCed(false)}
      />
      <FullScreenLoader
        show={loadingCheck}
        message={"Validando cedula profesional..."}
      />
    </div>
  );
};
export default PersonalEscolaridad;

function ValidateCedula({ visible, handleCloseModal }) {
  return (
    <Modal
      isOpen={visible}
      toggle={handleCloseModal}
      fullscreen="lg"
      size="lg"
      backdrop="static"
    >
      <ModalHeader toggle={handleCloseModal}></ModalHeader>
      <ModalBody>
        <div className="text-center">
          <h4 className="text-danger mb-4">
            Cedula profesional no encontrada, verifique por favor
          </h4>
          <Button color="danger" onClick={()=>handleCloseModal()}>Cerrar</Button>
        </div>
      </ModalBody>
    </Modal>
  );
}

import { Button, Col, Collapse, Row } from "reactstrap";
import {
    FilesInput,
    RadioInput,
    SelectTypeHeadSingle,
    TextAreaInput,
} from "../../../../components/GenericInputsFormik";
import { NumberInput } from "../../../../components/GenericInputsFormik/NumberInput";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useRef, useState } from "react";
import ModalConfirmation from "../../../../components/Modal/ModalConfirmation";
import {
    doRequest,
    doRequestSaveRes,
    uploadFile,
} from "../../../../helpers/requests";
import { useEffect } from "react";
import Datatable from "../../../../components/Datatable/Datatable";
import FullFormLoader from "../../../../components/Loader/FullFormLoader";
import { fMoney } from "../../../../libs/tools/format";
import { toast } from "react-toastify";
import Notification from "../../../../components/Notification/Notification";
import ModalViewFile from "../../../../components/Modal/ModalViewFile";

const status = [
    { value: "160", name: "CON ADEUDO" },
    { value: "161", name: "SIN ADEUDO" },
];

const defaultForm = {
    idDocument: "",
    amount: 0,
    discount: "",
    notes: "",
    file: "",
    keyStatEmp: "",
};

const FormSchema = Yup.object().shape({
    keyStatEmp: Yup.string().required("Seleccione un estatus"),
    file: Yup.mixed()
        .required("Agregue un documento")
        .test("file-type", "El documento debe ser en formato PDF", (value) => {
            return value && value.type === "application/pdf";
        })
        .test({
            message: `El documento debe pesar menos de 10MB`,
            test: (value) => value?.size < 10000000,
        }),
    amount: Yup.number().when("keyStatEmp", {
        is: "160",
        then: Yup.number()
            .required("Ingrese un onto de adeudo")
            .min(1, "Monto inválido"),
    }),
    discount: Yup.string().when("keyStatEmp", {
        is: "160",
        then: Yup.string().required("Seleccione una vía de pago"),
    }),
    notes: Yup.string().when("keyStatEmp", {
        is: "160",
        then: Yup.string().required("Ingrese una anotación"),
    }),
});

export default function Detalle({ finiquito, idArea, permissions }) {
    const [loading, setLoading] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [modal, setModal] = useState(false);
    const [modalF, setModalF] = useState(false);
    const [file, setFile] = useState("");
    const [adeudo, setAdeudo] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const refInpFile = useRef(null);

    const {
        handleSubmit,
        handleReset,
        handleBlur,
        values,
        errors,
        touched,
        setFieldValue,
        setValues,
    } = useFormik({
        initialValues: defaultForm,
        validationSchema: FormSchema,
        enableReinitialize: true,
        onSubmit: () => setModal(true),
        onReset: () => {
            refInpFile.current.value = "";
            setIsOpen(false);
        },
    });

    useEffect(() => {
        setValues(
            {
                keyStat: finiquito.amount ? "160" : "161",
                amount: finiquito.amount,
                discount: finiquito.discount,
                notes: finiquito.notes,
            },
            true
        );
    }, []);

    function onChange({ target }) {
        if (+finiquito.keyStat === 163 || !permissions.UPD) return;
        if (target.name === "keyStatEmp") {
            setAdeudo(+target.value === 160);
            setValues({
                idDocument: values.idDocument,
                amount: "",
                discount: "",
                notes: "",
                file: values.file
            }, true);
        }
        if (target.name === "file") {
            setFieldValue("file", target.files[0] || "");
        } else setFieldValue(target.name, target.value);
    }

    async function save() {
        setLoading(true);
        const params = {
            action: "update",
            table: "settlementsdocs",
            rows: { ...values, keyStat: 163 },
            condition: { idDocument: values.idDocument },
        };
        delete params.rows.file;
        delete params.rows.keyStatEmp;
        const resF = await uploadFile(
            values.idDocument,
            "saveFileReplace",
            62,
            values.file,
            false
        );
        if (resF) {
            const res = await doRequest("receiver/receiver.php", params, true);
            if (res.length > 0) {
                setRefresh(true);
                handleReset();
            }
        }
        setLoading(false);
    }

    /* TABLE */
    const columns = [
        {
            data: null,
            render: (row) =>
                check(row) ? (
                    <Button onClick={() => openForm(row)}>
                        <i className="fa fa-upload cursor-pointer text-white mr-2" />
                        Subir
                    </Button>
                ) : (
                    row.src && <i className="fa fa-file-pdf-o text-danger pl-2" onClick={()=>setFile(row.src)}/>
                ),
        },
        { data: "nameStat" },
        { data: null, render: (row) => !check(row) && fMoney(row.amount), orderValue: "amount" },
        { data: null, render: (row) => !check(row) && (+row.discount === 1 ? "SI" : "NO"), orderValue: "discount" },
        { data: "notes" },
    ];

    function check(row){
        return +row.keyStat !== 163;
    }

    async function getData(ordCol, itemsPerPage, currentPage, search, multi) {
        const res = await doRequestSaveRes(
            "receiver/receiver.php",
            getParams(ordCol, itemsPerPage, currentPage, search, multi),
            false,
            false
        );
        if (res.code === "200") {
            res.data = res.data.map((el, ix) => {
                return { ...el, ix: ix + 1 };
            });
            return res;
        }
        return null;
    }

    function getParams(ordCol, itemsPerPage, currentPage, search) {
        const params = {
            action: "datatable",
            table: "settlementsdocs A INNER JOIN settlementareas B USING(idSettlementArea) INNER JOIN areas C USING(idArea) INNER JOIN status D USING (keyStat)",
            rows: "A.idDocument, C.name, D.nameStat, A.amount, A.discount, A.notes, A.keyStat",
            conditions: `A.idSettlement = ${finiquito.id} AND A.enabled = 1 AND C.idArea = ${idArea}`,
            order: ordCol,
            records: itemsPerPage.toString(),
            page: (itemsPerPage * currentPage).toString(),
            search,
        };
        return params;
    }

    function openForm(row){
        if(isOpen){
            toast(
                <Notification type="warning" backMessage="Guarde sus cambios antes de continuar" />,
                { closeButton: false }
            );
            return;
        }
        setValues({
            idDocument: row.idDocument,
            amount: row.amount,
            discount: row.discount,
            notes: row.notes,
            keyStatEmp: "",
            file: "",
        }, true);
        setIsOpen(true);
    }
    /* END TABLE */
    
    useEffect(()=>{
        if(file) setModalF(true);
    }, [file]);

    useEffect(()=>{
        if(!modalF) setFile("");
    }, [modalF]);

    return (
        <div className="mt-2">
            <Datatable
                headers={["Constancia","Estatus","Monto Adeudo","Pago Via Nomina","Notas"]}
                columns={columns}
                columnDefs={columnDefs}
                petition={getData}
                control="back"
                stateRefresh={[refresh, setRefresh]}
                className="px-0 mt-4"
                order={{col: 1, opt: "desc"}}
            />

            <Collapse isOpen={isOpen}>
                <form onSubmit={handleSubmit} onReset={handleReset}>
                    <Row>
                        <Col xs={12} md={6}>
                            <SelectTypeHeadSingle
                                label="Estatus de empleado"
                                isRequired={true}
                                inputName="keyStatEmp"
                                optionsArray={status}
                                defaultOption="Seleccione un estatus"
                                onChangeMethod={onChange}
                                onBlurMethod={handleBlur}
                                value={values.keyStatEmp}
                                touched={touched.keyStatEmp}
                                errors={errors.keyStatEmp}
                                optionValue="value"
                                optionName="name"
                                isDisabled={finiquito.keyStat === 163}
                            />
                        </Col>
                        <Col xs={12} md={6}>
                            <FilesInput
                                label="Constancia"
                                inputName="file"
                                onChangeMethod={onChange}
                                onBlurMethod={handleBlur}
                                fileAccept="application/pdf"
                                isRequired
                                touched={touched.file}
                                errors={errors.file}
                                basicReq={false}
                                isDisabled={finiquito.keyStat === 163}
                                refInput={refInpFile}
                            />
                        </Col>
                        <Col xs={12} md={6}>
                            <NumberInput
                                label="Monto Adeudo"
                                inputName="amount"
                                onChangeMethod={onChange}
                                onBlurMethod={handleBlur}
                                value={values.amount}
                                touched={touched.amount}
                                errors={errors.amount}
                                isRequired={adeudo}
                                prefix="$"
                                isDisabled={!adeudo}
                            />
                        </Col>
                        <Col xs={12} md={6}>
                            <RadioInput
                                label="Paga via Nómina"
                                inputName="discount"
                                onChangeMethod={onChange}
                                onBlurMethod={handleBlur}
                                value={values.discount}
                                isRequired={adeudo}
                                touched={touched.discount}
                                errors={errors.discount}
                                optionsArray={[
                                    { val: "1", name: "Si" },
                                    { val: "0", name: "No" },
                                ]}
                                optionValue="val"
                                optionName="name"
                                isDisabled={!adeudo}
                            />
                        </Col>
                        <Col xs={12} md={6}>
                            <TextAreaInput
                                label="Notas"
                                inputName="notes"
                                inputRows="6"
                                isRequired={adeudo}
                                onChangeMethod={onChange}
                                onBlurMethod={handleBlur}
                                value={values.notes}
                                isDisabled={!adeudo}
                                touched={touched.notes}
                                errors={errors.notes}
                            />
                        </Col>
                    </Row>
                    {+finiquito.keyStat !== 163 && (
                        <div className="text-center mt-3">
                            <Button type="submit">Enviar Constancia</Button>
                        </div>
                    )}
                </form>
            </Collapse>

            <ModalViewFile
                modal={modalF}
                setModal={setModalF}
                backdrop={true}
                keyboard={true}
                btnClose={true}
                fileLink={file}
            />

            <ModalConfirmation
                modalTitle="Guardar detalles"
                modal={modal}
                setModal={setModal}
                crear={save}
            >
                <div className="text-center">
                    <h6>¿Está seguro de enviar esta constancia?</h6>
                    <p>No podrá deshacer esta acción</p>
                </div>
            </ModalConfirmation>

            <FullFormLoader show={loading} />
        </div>
    );
}

const columnDefs = [
    { targets: [0], orderable: false },
    { targets: [0, 1, 3], className: "text-center" },
    { targets: [2], className: "text-right" },
];

import React, { useState, useEffect } from "react";
import { peticionEncript } from "../../../helpers/peticionesEncripted";
import * as Yup from "yup";
import { useFormik } from "formik";
import axios from "axios";
import { toast } from "react-toastify";
import Notification from "../../../components/Notification/Notification";
import { Button, Col, Collapse, Row, Spinner } from "reactstrap";
import ModalConfirmation from "../../../components/Modal/ModalConfirmation";
import ModalTableVer from "../../../components/Modal/ModalTableVer";
import { NumberInput } from "../../../components/GenericInputsFormik/NumberInput";
import { ButtonInput } from "../../../components/GenericInputsFormik/ButtonInput";
import {
  SelectTypeHeadSingle,
  TextAreaInput,
  TextInput,
} from "../../../components/GenericInputsFormik";
import TableComponentActions from "../../../components/TableComponent/TableComponentActions";
import FullFormLoader from "../../../components/Loader/FullFormLoader";

/* TABLE */
const cabecerasTabla = [
  "Id",
  "Actividad", // []
  "Empleado", //
  "Fecha Inicio", //
  "Fecha Termino", //
  "Ponderación", // []
  "Unidad de medida", //
  "Cantidad", // []
];
const filtroTabla = [false, true, true, true, true, true, true, true];

/* MODAL TABLE */
const filtroModalTabla = [true, true, true, true, true];
const cabecerasModalTabla = [
  "#",
  "Núm. Empleado",
  "Nombre",
  "Unidad de trabajo",
  "Puesto",
  "Seleccionar",
];

/* Formualrio */
const defaultForm = {
  idActivity: "",
  keyUnit: "",
  idStaffResonsible: "",
  nameStaff: "", /// NO
  nameActivity: "",
  startDate: "",
  endDate: "",
  nameResult: "",
  annualAmmout: 1,
  ///meses
  m1: 0,
  m2: 0,
  m3: 0,
  m4: 0,
  m5: 0,
  m6: 0,
  m7: 0,
  m8: 0,
  m9: 0,
  m10: 0,
  m11: 0,
  m12: 0,
};

const FormSchema = Yup.object().shape({
  keyUnit: Yup.string().required("Seleccione un unidad de medida"),
  nameStaff: Yup.string().required("Seleccione un empleado"),
  nameActivity: Yup.string().required("Ingrese un nombre pra la actividad"),
  startDate: Yup.date().required("Seleccione una fecha de inicio"),
  endDate: Yup.date()
    .required("Seleccione una fecha de termino")
    .min(
      Yup.ref("startDate"),
      "La fecha debe ser posterior a la fecha de inicio"
    ),
  nameResult: Yup.string().required("Ingrese un supuesto de proyecto"),
  annualAmmout: Yup.number()
    .required("Ingrese la cantidad de unidades")
    .min(1, "Cantidad de debe ser mayor a 0"),
  m1: Yup.number()
    .required("Ingrese una cantidad")
    .min(0, "Cantidad invalida")
    .max(Yup.ref("annualAmmout"), "Cantidad maxima excedida"),
  m2: Yup.number()
    .required("Ingrese una cantidad")
    .min(0, "Cantidad invalida")
    .max(Yup.ref("annualAmmout"), "Cantidad maxima excedida"),
  m3: Yup.number()
    .required("Ingrese una cantidad")
    .min(0, "Cantidad invalida")
    .max(Yup.ref("annualAmmout"), "Cantidad maxima excedida"),
  m4: Yup.number()
    .required("Ingrese una cantidad")
    .min(0, "Cantidad invalida")
    .max(Yup.ref("annualAmmout"), "Cantidad maxima excedida"),
  m5: Yup.number()
    .required("Ingrese una cantidad")
    .min(0, "Cantidad invalida")
    .max(Yup.ref("annualAmmout"), "Cantidad maxima excedida"),
  m6: Yup.number()
    .required("Ingrese una cantidad")
    .min(0, "Cantidad invalida")
    .max(Yup.ref("annualAmmout"), "Cantidad maxima excedida"),
  m7: Yup.number()
    .required("Ingrese una cantidad")
    .min(0, "Cantidad invalida")
    .max(Yup.ref("annualAmmout"), "Cantidad maxima excedida"),
  m8: Yup.number()
    .required("Ingrese una cantidad")
    .min(0, "Cantidad invalida")
    .max(Yup.ref("annualAmmout"), "Cantidad maxima excedida"),
  m9: Yup.number()
    .required("Ingrese una cantidad")
    .min(0, "Cantidad invalida")
    .max(Yup.ref("annualAmmout"), "Cantidad maxima excedida"),
  m10: Yup.number()
    .required("Ingrese una cantidad")
    .min(0, "Cantidad invalida")
    .max(Yup.ref("annualAmmout"), "Cantidad maxima excedida"),
  m11: Yup.number()
    .required("Ingrese una cantidad")
    .min(0, "Cantidad invalida")
    .max(Yup.ref("annualAmmout"), "Cantidad maxima excedida"),
  m12: Yup.number()
    .required("Ingrese una cantidad")
    .min(0, "Cantidad invalida")
    .max(Yup.ref("annualAmmout"), "Cantidad maxima excedida"),
});

async function doRequest(route, params, alert, exist) {
  let info = [];
  const dataEncrypted = peticionEncript(params);
  await axios
    .post(process.env.REACT_APP_API + route, dataEncrypted)
    .then((res) => {
      if (res.status === 200 && res.data.status === "success") {
        info = res.data.data;
        if (alert) {
          toast(
            <Notification
              type={"elimina_exito"}
              backMessage={res.data.message}
              withIcon
            />,
            {
              closeButton: false,
            }
          );
        }
      } else {
        if (res.data.code !== 0) {
          toast(
            <Notification
              type={"consultar_error"}
              backMessage={res.data.message}
              withIcon
            />,
            { closeButton: false }
          );
        }
      }
    })
    .catch((err) => {
      toast(<Notification type={"consultar_servidor_error"} withIcon />, {
        closeButton: false,
      });
    });
  return info;
}
const defaultBlocked = [true, true, true, true, true, true, true, true, true, true, true, true];
const ActividadesP = ({ idArea, proyectId, canEdit, datesLimit, permissions }) => {
  const [loading, setLoading] = useState(true);
  const [loadingForm, setLoadingForm] = useState(false);
  const [modalConfirm, setModalConfirm] = useState(false);
  const [modalTable, setModalTable] = useState(false);
  const [unidades, setUnidades] = useState([]);
  const [afterValues, setAfterValues] = useState([]); /// Hook para guardar los valores anteriores del mes
  const [showForm, setShowForm] = useState(false); /// Hook para mostrar formulario
  const [blocked, setBlocked ] = useState(defaultBlocked); /// Hook para bloquear meses
  const [paramsModalTabla] = useState({
    action: "datatable",
    table:
      "staff STF INNER JOIN employtypes AS EMT ON STF.keyEmployType = EMT.keyEmployType INNER JOIN jobstaff AS JBS ON JBS.idStaff = STF.idStaff INNER JOIN areas AS AR ON AR.idArea = JBS.idArea INNER JOIN jobtypes AS JBT ON JBT.keyJobType = STF.keyEmployType",
    rows: "STF.idStaff,STF.staffNumber,CONCAT(STF.name,' ',STF.pName,' ',STF.mName) as fullName,AR.name,JBT.nameJobType",
    conditions: `STF.enabled = 1 AND JBS.valid = 1 AND STF.keyStat = 1 AND JBS.enabled = 1 AND JBS.idArea = ${idArea}`,
    page: 0,
    records: 5,
    search: "",
    order: "",
  });
  const [parametrosTabla, setParametrosTabla] = useState({
    action: "datatable",
    table:
      "activities A INNER JOIN staff B ON A.idStaffResonsible = B.idStaff INNER JOIN messureunits C ON A.keyUnit = C.keyUnit INNER JOIN activitiesplan D ON A.idActivity = D.idActivity",
    rows: "A.idActivity, A.nameActivity, CONCAT(B.name,' ',B.pName,' ',B.mName) as fullName, A.startDate, A.endDate, A.idProyect AS ponderacion, C.nameUnit, A.annualAmmout, A.idStaffResonsible, B.staffNumber, A.keyUnit, A.nameResult, GROUP_CONCAT(D.goal) AS goals",
    conditions: `A.idProyect = ${proyectId} AND A.enabled = 1 AND D.enabled = 1`,
    page: 0,
    records: 5,
    search: "",
    order: "",
    group: "A.idActivity",
  });
  const [titleForm, setTitleForm] = useState(""); /// Hook para cambiar titulo del formulario
  const formSection = React.useRef(null);

  const {
    handleSubmit,
    values,
    handleBlur,
    errors,
    touched,
    setFieldValue,
    handleReset,
    setFieldError,
  } = useFormik({
    initialValues: defaultForm,
    validationSchema: FormSchema,
    onSubmit: (values) => {
      validateAmmout();
    },
    onReset: () => {
      setTitleForm("");
      setShowForm(false);
    },
    enableReinitialize: true,
  });

  /* Valida que la cantitdad total concuerde con la de los meses*/
  const validateAmmout = () => {
    const suma =
      +values.m1 +
      +values.m2 +
      +values.m3 +
      +values.m4 +
      +values.m5 +
      +values.m6 +
      +values.m7 +
      +values.m8 +
      +values.m9 +
      +values.m10 +
      +values.m11 +
      +values.m12;
    if (+suma !== +values.annualAmmout) {
      // toast(
      //   <Notification
      //     type={"consultar_error"}
      //     backMessage={res.data.message}
      //     withIcon
      //   />,
      //   { closeButton: false }
      // );
      setFieldError("annualAmmout", "Cantidad anual y mensual no coincide");
    } else setModalConfirm(true);
  };

  /* Consulta lo tipos de documentos*/
  const getUnidades = async () => {
    const param = {
      action: "select",
      table: "messureunits",
    };
    const data = await doRequest("receiver/receiver.php", param);
    if (data.length > 0) setUnidades(data);
    setLoading(false);
  };

  useEffect(() => {
    getUnidades();
  }, []);

  useEffect(()=>{
    if(values.endDate !== ""){
      findMoths();
    }else {
      setBlocked(defaultBlocked);
      setFieldValue("m1", 0);
      setFieldValue("m2", 0);
      setFieldValue("m3", 0);
      setFieldValue("m4", 0);
      setFieldValue("m5", 0);
      setFieldValue("m6", 0);
      setFieldValue("m7", 0);
      setFieldValue("m8", 0);
      setFieldValue("m9", 0);
      setFieldValue("m10", 0);
      setFieldValue("m11", 0);
      setFieldValue("m12", 0);
    }
  }, [values.endDate])

  const onChange = (e) => {
    if(e.target.name === "startDate" && e.target.value === ""){
      setFieldValue("endDate", "");
    }
    setFieldValue(e.target.name, e.target.value);
  };

  /* Funcion que se envia al modalTabla al seleccionar un empleado*/
  const selectEmpleado = (el) => {
    setFieldValue("idStaffResonsible", el[0]);
    setFieldValue("nameStaff", `${el[1]} - ${el[2]}`);
    setModalTable(false);
  };

  /* Funcion para guardar/editar la información de la actividad */
  const editInfo = async () => {
    setLoadingForm(true);
    let rows = {
      idActivity: values.idActivity,
      idProyect: proyectId,
      keyUnit: values.keyUnit,
      idStaffResonsible: values.idStaffResonsible,
      keyStat: "70",
      nameActivity: values.nameActivity,
      startDate: values.startDate,
      endDate: values.endDate,
      nameResult: values.nameResult,
      annualAmmout: values.annualAmmout,
    };
    const params = {
      action: "",
      table: "activities",
      rows: rows,
      validate: [["nameActivity"]],
    };
    if (values.idActivity === "") {
      params.action = "insert";
    } else {
      params.action = "update";
      params.condition = { idActivity: values.idActivity };
    }
    const data = await doRequest("receiver/receiver.php", params);
    if (data.length > 0) insertPlans(data[0].idActivity);
    else setLoadingForm(false);
  };

  /* Funcion para guardar/editar la información de cada plan de la actividad */
  const insertPlans = async (idAct) => {
    const params = {
      action: "saveActivityPlan",
      idActivity: idAct,
      m1: values.m1,
      m2: values.m2,
      m3: values.m3,
      m4: values.m4,
      m5: values.m5,
      m6: values.m6,
      m7: values.m7,
      m8: values.m8,
      m9: values.m9,
      m10: values.m10,
      m11: values.m11,
      m12: values.m12,
    };
    const data = await doRequest("app/facades/planning/planningF.php", params);
    if (data.length > 0) {
      toast(
        <Notification
          type={"agrega_exito"}
          backMessage="Actividad registrada"
          withIcon
        />,
        { closeButton: false }
      );
      handleReset();
      setParametrosTabla({ ...parametrosTabla });
    }
    setLoadingForm(false);
  };

  /* Abrir formulario para crear registro */
  const openForm = () => {
    setTitleForm("Agregar Actividad");
    setShowForm(true);
  };

  /* Abrir registro de la tabla para editarlo*/
  const getForEdit = async (el) => {
    if (!showForm) {
      setFieldValue("idActivity", el[0]);
      setFieldValue("nameActivity", el[1]);
      setFieldValue("nameResult", el[11]);
      setFieldValue("nameStaff", `${el[9]} - ${el[2]}`);
      setFieldValue("idStaffResonsible", el[8]);
      setFieldValue("startDate", el[3]);
      setFieldValue("endDate", el[4]);
      setFieldValue("keyUnit", el[10]);
      setFieldValue("annualAmmout", +el[7]);
      const g = el[12].split(",");
      setFieldValue("m1", +g[0]);
      setFieldValue("m2", +g[1]);
      setFieldValue("m3", +g[2]);
      setFieldValue("m4", +g[3]);
      setFieldValue("m5", +g[4]);
      setFieldValue("m6", +g[5]);
      setFieldValue("m7", +g[6]);
      setFieldValue("m8", +g[7]);
      setFieldValue("m9", +g[8]);
      setFieldValue("m10", +g[9]);
      setFieldValue("m11", +g[10]);
      setFieldValue("m12", +g[11]);
      setAfterValues(g);
      setTitleForm(canEdit ? "Agregar Actividad" : "Consultar actividad");
      setShowForm(true);
    } else {
      toast(
        <Notification
          type="warning"
          backMessage="Guarde sus cambios antes de continuar"
        />,
        { closeButton: false }
      );
      scrollTo();
    }
  };

  /* Elimina registro de la tabla */
  const deleteActivity = async (id) => {
    handleReset();
    let params = {
      action: "delete",
      table: "activitiesplan",
      condition: { idActivity: id },
    };
    let data = await doRequest("receiver/receiver.php", params, false);
    if (data.length > 0) {
      params.table = "activities";
      data = await doRequest("receiver/receiver.php", params, true);
      if (data.length > 0) setParametrosTabla({ ...parametrosTabla });
    }
  };

  /* Función para scrolear a un punto de la aplicación */
  const scrollTo = () => {
    formSection.current.scrollIntoView({ behavior: "smooth" });
  };

  const findMoths = () => {
    const monthS = +values.startDate.split("-")[1] - 1;
    const monthE = +values.endDate.split("-")[1] - 1;
    let copy = [...defaultBlocked];
    for(let i = monthS; i <= monthE; i++){
      copy[i] = false;
    }
    setBlocked(copy);
  }

  if (loading)
    return (
      <div style={{ minHeight: "300px" }} className="text-center">
        <Spinner color="primary" style={{ marginTop: "135px" }} />
      </div>
    );

  return (
    <div className="mt-2">
      <div className="mb-4">
        <h4>Actividades</h4>
      </div>

      <TableComponentActions
        cabecerasTabla={cabecerasTabla}
        filtro={filtroTabla}
        parametros={parametrosTabla}
        watchFn={canEdit ? false : getForEdit}
        updateFn={canEdit ? getForEdit : false}
        deleteFn={canEdit ? deleteActivity : false}
        titulo="Actividades"
        showTitle
      />

      <div className="row mt-2" ref={formSection}>
        <div className="col-12 col-sm-6">
          <h5 className="text-center text-sm-left">{titleForm}</h5>
        </div>
        <div className="col-12 col-sm-6">
          {canEdit && permissions.INS && (
            <div className="text-center text-sm-right">
              <Button
                color="add"
                onClick={() => openForm()}
                disabled={showForm}
              >
                Agregar
              </Button>
            </div>
          )}
        </div>
      </div>

      <Collapse isOpen={showForm} className="mt-2" onEntered={() => scrollTo()}>
        <form
          onSubmit={handleSubmit}
          onReset={handleReset}
          className="mt-2 position-relative"
        >
          <ModalConfirmation
            modalTitle="Guardar actividad"
            modal={modalConfirm}
            setModal={setModalConfirm}
            editar={() => {
              editInfo();
            }}
            isEdit={true}
            values={values}
          >
            <div className="d-flex justify-content-center">
              <h6>¿Está seguro de guardar esta actividad?</h6>
            </div>
          </ModalConfirmation>

          <Row>
            <Col xs={12} lg={6}>
              <TextInput
                label="Nombre de la actividad"
                inputType="text"
                inputName="nameActivity"
                onChangeMethod={onChange}
                onBlurMethod={handleBlur}
                value={values.nameActivity}
                touched={touched.nameActivity}
                errors={errors.nameActivity}
                isRequired
                isDisabled={!canEdit}
              />
            </Col>
          </Row>

          <Row>
            <Col xs={12} lg={12}>
              <TextAreaInput
                label="Supuesto de la actividad"
                inputName="nameResult"
                inputRows={4}
                inputType="text"
                onChangeMethod={onChange}
                onBlurMethod={handleBlur}
                value={values.nameResult}
                touched={touched.nameResult}
                errors={errors.nameResult}
                isRequired
                isDisabled={!canEdit}
              />
            </Col>
          </Row>

          <Row>
            <Col xs={12} lg={6}>
              <ButtonInput
                label="Empleado responsable"
                inputName="nameStaff"
                value={values.nameStaff}
                isRequired
                touched={touched.nameStaff}
                errors={errors.nameStaff}
                setModalTable={canEdit ? setModalTable : () => {}}
                clearFun={() => {
                  setFieldValue("idStaffResonsible", "");
                  setFieldValue("nameStaff", "");
                }}
                isDisabled={!canEdit}
                disabledClear={!canEdit}
              />
            </Col>

            <Col xs={12} lg={3}>
              <TextInput
                label="Fecha inicial"
                inputType="date"
                inputName="startDate"
                onChangeMethod={onChange}
                onBlurMethod={handleBlur}
                value={values.startDate}
                isRequired
                touched={touched.startDate}
                errors={errors.startDate}
                isMin={datesLimit[0]}
                isMax={datesLimit[1]}
                isDisabled={!canEdit}
              />
            </Col>

            <Col xs={12} lg={3}>
              <TextInput
                label="Fecha final"
                inputType="date"
                inputName="endDate"
                onChangeMethod={onChange}
                onBlurMethod={handleBlur}
                value={values.endDate}
                isRequired
                touched={touched.endDate}
                errors={errors.endDate}
                isMin={values.startDate? values.startDate : datesLimit[0]}
                isMax={datesLimit[1]}
                isDisabled={values.startDate === "" || !canEdit}
              />
            </Col>
          </Row>

          <Row>
            <Col xs={12} lg={6}>
              <SelectTypeHeadSingle
                label="Unidad de medida"
                isRequired={true}
                inputName="keyUnit"
                optionsArray={unidades}
                defaultOption="Seleccione un tipo de unidad"
                onChangeMethod={onChange}
                onBlur={handleBlur}
                value={values.keyUnit}
                touched={touched.keyUnit}
                errors={errors.keyUnit}
                optionValue="keyUnit"
                optionName="nameUnit"
                isDisabled={!canEdit}
              />
            </Col>
            <Col xs={12} lg={6}>
              <NumberInput
                label="Cantidad programada anual"
                inputName="annualAmmout"
                onChangeMethod={onChange}
                onBlurMethod={handleBlur}
                value={values.annualAmmout}
                isRequired
                touched={touched.annualAmmout}
                errors={errors.annualAmmout}
                isMin={1}
                decimals
                isDisabled={!canEdit}
              />
            </Col>
          </Row>

          <div className="mt-1 pb-2 border-bottom">
            <h5>Descripción del medio de varificación</h5>
            <p className="text-muted">Lista de asistencia</p>
          </div>

          <div className="mt-2">
            <p className="text-muted">
              Indique la cantidad mensual de actividades que desarrolla para
              cumplir la cantidad programada anual
            </p>
            <Row className="mt-2">
              <Col xs={12} xl={2}>
                <NumberInput
                  label="Enero"
                  inputName="m1"
                  onChangeMethod={onChange}
                  onBlurMethod={handleBlur}
                  value={values.m1}
                  isRequired
                  touched={touched.m1}
                  errors={errors.m1}
                  isMin={0}
                  isMax={values.annualAmmout}
                  decimals
                  isDisabled={!canEdit|blocked[0]}
                />
              </Col>
              <Col xs={12} xl={2}>
                <NumberInput
                  label="Febrero"
                  inputName="m2"
                  onChangeMethod={onChange}
                  onBlurMethod={handleBlur}
                  value={values.m2}
                  isRequired
                  touched={touched.m2}
                  errors={errors.m2}
                  isMin={0}
                  isMax={values.annualAmmout}
                  decimals
                  isDisabled={!canEdit|blocked[1]}
                />
              </Col>
              <Col xs={12} xl={2}>
                <NumberInput
                  label="Marzo"
                  inputName="m3"
                  onChangeMethod={onChange}
                  onBlurMethod={handleBlur}
                  value={values.m3}
                  isRequired
                  touched={touched.m3}
                  errors={errors.m3}
                  isMin={0}
                  isMax={values.annualAmmout}
                  decimals
                  isDisabled={!canEdit|blocked[2]}
                />
              </Col>
              <Col xs={12} xl={2}>
                <NumberInput
                  label="Abril"
                  inputName="m4"
                  onChangeMethod={onChange}
                  onBlurMethod={handleBlur}
                  value={values.m4}
                  isRequired
                  touched={touched.m4}
                  errors={errors.m4}
                  isMin={0}
                  isMax={values.annualAmmout}
                  decimals
                  isDisabled={!canEdit|blocked[3]}
                />
              </Col>
              <Col xs={12} xl={2}>
                <NumberInput
                  label="Mayo"
                  inputName="m5"
                  onChangeMethod={onChange}
                  onBlurMethod={handleBlur}
                  value={values.m5}
                  isRequired
                  touched={touched.m5}
                  errors={errors.m5}
                  isMin={0}
                  isMax={values.annualAmmout}
                  decimals
                  isDisabled={!canEdit|blocked[4]}
                />
              </Col>
              <Col xs={12} xl={2}>
                <NumberInput
                  label="Junio"
                  inputName="m6"
                  onChangeMethod={onChange}
                  onBlurMethod={handleBlur}
                  value={values.m6}
                  isRequired
                  touched={touched.m6}
                  errors={errors.m6}
                  isMin={0}
                  isMax={values.annualAmmout}
                  decimals
                  isDisabled={!canEdit|blocked[5]}
                />
              </Col>
              <Col xs={12} xl={2}>
                <NumberInput
                  label="Julio"
                  inputName="m7"
                  onChangeMethod={onChange}
                  onBlurMethod={handleBlur}
                  value={values.m7}
                  isRequired
                  touched={touched.m7}
                  errors={errors.m7}
                  isMin={0}
                  isMax={values.annualAmmout}
                  decimals
                  isDisabled={!canEdit|blocked[6]}
                />
              </Col>
              <Col xs={12} xl={2}>
                <NumberInput
                  label="Agosto"
                  inputName="m8"
                  onChangeMethod={onChange}
                  onBlurMethod={handleBlur}
                  value={values.m8}
                  isRequired
                  touched={touched.m8}
                  errors={errors.m8}
                  isMin={0}
                  isMax={values.annualAmmout}
                  decimals
                  isDisabled={!canEdit|blocked[7]}
                />
              </Col>
              <Col xs={12} xl={2}>
                <NumberInput
                  label="Septiembre"
                  inputName="m9"
                  onChangeMethod={onChange}
                  onBlurMethod={handleBlur}
                  value={values.m9}
                  isRequired
                  touched={touched.m9}
                  errors={errors.m9}
                  isMin={0}
                  isMax={values.annualAmmout}
                  decimals
                  isDisabled={!canEdit|blocked[8]}
                />
              </Col>
              <Col xs={12} xl={2}>
                <NumberInput
                  label="Octubre"
                  inputName="m10"
                  onChangeMethod={onChange}
                  onBlurMethod={handleBlur}
                  value={values.m10}
                  isRequired
                  touched={touched.m10}
                  errors={errors.m10}
                  isMin={0}
                  isMax={values.annualAmmout}
                  decimals
                  isDisabled={!canEdit|blocked[9]}
                />
              </Col>
              <Col xs={12} xl={2}>
                <NumberInput
                  label="Noviembre"
                  inputName="m11"
                  onChangeMethod={onChange}
                  onBlurMethod={handleBlur}
                  value={values.m11}
                  isRequired
                  touched={touched.m11}
                  errors={errors.m11}
                  isMin={0}
                  isMax={values.annualAmmout}
                  decimals
                  isDisabled={!canEdit|blocked[10]}
                />
              </Col>
              <Col xs={12} xl={2}>
                <NumberInput
                  label="Diciembre"
                  inputName="m12"
                  onChangeMethod={onChange}
                  onBlurMethod={handleBlur}
                  value={values.m12}
                  isRequired
                  touched={touched.m12}
                  errors={errors.m12}
                  isMin={0}
                  isMax={values.annualAmmout}
                  decimals
                  isDisabled={!canEdit|blocked[11]}
                />
              </Col>
            </Row>
          </div>

          <Row className="mt-2">
            <div className="col-sm-6 order-2 order-sm-1 text-center text-sm-left mb-4">
              <Button color="danger" type="reset">
                {canEdit ? "Cancelar" : "Cerrar"}
              </Button>
            </div>
            {canEdit && (
              <div className="col-sm-6 order-1 order-sm-2 text-center text-sm-right mb-4">
                <Button color="success" type="submit">
                  Guardar
                </Button>
              </div>
            )}
          </Row>

          <FullFormLoader show={loadingForm} />
        </form>
      </Collapse>

      <ModalTableVer
        modal={modalTable}
        setModal={setModalTable}
        title="Empleados"
        cabeceras={cabecerasModalTabla}
        filtro={filtroModalTabla}
        parametros={paramsModalTabla}
        selectRegistro={selectEmpleado}
        backdrop={"static"}
        keyboard={false}
      />
    </div>
  );
};
export default ActividadesP;

import React, { useState } from "react";
import ModalTableVer from "../../../../components/Modal/ModalTableVer";
import { Formik, Form, Field } from "formik";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Col, Row, Button, Collapse } from "reactstrap";
import { CancelOutlined } from "@material-ui/icons";
import s from "../InputClear.module.scss";
import { toast } from "react-toastify";
import Notification from "../../../../components/Notification/Notification";
import { peticionesReceiver } from "../../../../helpers/peticionesReceiver";
import ModalConfirmation from "../../../../components/Modal/ModalConfirmation";
import FullFormLoader from "../../../../components/Loader/FullFormLoader";

const defaultForm = {
  idInventory: "",
  serialKey: "",
  description: "",
};

const AsignarBien = (props) => {
  const { collapse, setCollapse, obj, setParams } = props;
  const [loader, setLoader] = useState(false);
  const API = peticionesReceiver();
  const [modalTablaBien, setModalTablaBien] = useState(false);
  const [modalConfirmation, setModalConfirmation] = useState(false);
  const [cabecerasModalTablaBien] = useState([
    "#",
    "Codigo de barras",
    "Numero de serie",
    "Descripcion",
  ]);
  const [paramsModalTablaBien] = useState({
    action: "datatable",
    table:
      "inventories A INNER JOIN furnitures B on (A.idFurniture = B.idFurniture)",
    rows: "A.idInventory,A.idInventory as barscode,A.idInventory,A.serialKey,CONCAT(B.nameFurniture,'\nMARCA: ',B.model,'\nModelo: ',B.brand) as description",
    conditions: "A.enabled = 1",
    page: 0,
    records: 5,
    search: "",
    order: "",
  });

  const FormSchema = Yup.object().shape({
    serialKey: Yup.string().required("Seleccione un bien"),
  });

  const onChange = (e) => {
    setFieldValue([e.target.name], e.target.value);
  };

  const insertBien = (values) => {
    setLoader(true);
    let params = "";
    if (obj.type === "staff") {
      params = {
        action: "saveAssignment",
        idInventory: values.idInventory,
        idStaff: obj.id,
      };
    } else {
      params = {
        action: "saveAssignmentArea",
        idInventory: values.idInventory,
        idArea: obj.id,
      };
    }

    API.peticionEndPoint(params, "app/facades/inventory/inventoryF.php")
      .then((res) => {
        if (res.status === 200 && res.data.code === "200") {
          
          toast(
            <Notification
              type={"agrega_exito"}
              backMessage={res.data.message}
              withIcon
            />
          );

          setParams(
            obj.type === "staff"
              ? {
                  action: "datatable",
                  table:
                    "assignment B INNER JOIN inventories A ON (A.idInventory = B.idInventory and B.enabled=1) INNER JOIN furnitures C USING (idFurniture) INNER JOIN status D using (keyStat) INNER JOIN furniture_classes E USING (keyClass) inner join furniture_subgroups F USING (keySubGroup) inner join furniture_groups G USING (keyGroup) INNER JOIN staff H ON (H.idStaff = B.idStaff) LEFT JOIN areas I ON (I.idArea = B.idArea)",
                  rows: "B.idAssignment,CONCAT(H.name,' ',H.pName,' ',H.mName) as nameStaff,inventorykey,serialkey,CONCAT(nameFurniture,' Marca: ',brand,' Modelo: ',model) as nameFurniture,CONCAT(nameGroup,'\n',nameSubGroup,'\n',nameClass) as setup,nameStat,actualPrice,I.name as nameArea",
                  conditions: `A.enabled =1 and B.enabled = 1 and H.idStaff = ${obj.id}`,
                  page: 0,
                  records: 5,
                  search: "",
                  order: "",
                }
              : {
                  action: "datatable",
                  table:
                    "assignment B INNER JOIN inventories A ON (A.idInventory = B.idInventory and B.enabled=1) INNER JOIN furnitures C USING (idFurniture) INNER JOIN status D using (keyStat) INNER JOIN furniture_classes E USING (keyClass) inner join furniture_subgroups F USING (keySubGroup) inner join furniture_groups G USING (keyGroup) INNER JOIN staff H ON (H.idStaff = B.idStaff) LEFT JOIN areas I ON (I.idArea = B.idArea)",
                  rows: "B.idAssignment, CONCAT(H.name,' ',H.pName,' ',H.mName) as nameStaff,inventorykey,serialkey,CONCAT(nameFurniture,' Marca: ',brand,' Modelo: ',model) as nameFurniture,CONCAT(nameGroup,'\n',nameSubGroup,'\n',nameClass) as setup,nameStat,actualPrice,I.name as nameArea",
                  conditions: `A.enabled = 1 and B.enabled = 1 and B.idArea= ${obj.id}`,
                  page: 0,
                  records: 5,
                  search: "",
                  order: "",
                }
          );

          cleanForm();
        } else {
          toast(
            <Notification
              type={"consultar_error"}
              backMessage={res.data.message}
              withIcon
            />
          );
        }
      })
      .finally(() => {
        setLoader(false);
      })
      .catch((err) => {
        toast(<Notification type={"consultar_servidor_error"} withIcon />, {
          closeButton: false,
        });
      });
  };

  const {
    handleSubmit,
    handleBlur,
    values,
    errors,
    touched,
    setFieldValue,
    resetForm,
  } = useFormik({
    initialValues: defaultForm,
    onSubmit: (values) => {
      setModalConfirmation(true);
    },
    validationSchema: FormSchema,
    enableReinitialize: true,
  });

  const selectBien = (el) => {
    setFieldValue("idInventory", el[0]);
    setFieldValue("serialKey", `${el[1]}`);
    setFieldValue("description", `${el[3]}`);
    setModalTablaBien(false);
  };
  function clearBien() {
    setFieldValue("idInventory", "");
    setFieldValue("serialKey", "");
    setFieldValue("description", "");
  }

  const cleanForm = () => {
    setCollapse(false);
    resetForm();
  };

  return (
    <div>
      <div className="d-grid d-flex justify-content-center justify-content-sm-end">
        <Button color="add" onClick={() => setCollapse(true)}>
          Agregar
        </Button>
      </div>
      <Collapse isOpen={collapse}>
        <form onSubmit={handleSubmit} className="mt-4" noValidate>
          <Row className="d-flex justify-content-around">
            <Col xs={12} lg={8}>
              <div className="form-group position-relative">
                <label htmlFor="serialKey">
                  Consulta de bienes <span className="text-danger">*</span>
                </label>
                <div className="input-group">
                  <input
                    name="serialKey"
                    onChange={onChange}
                    onBlur={handleBlur}
                    className={
                      "form-control" +
                      (errors.serialKey && touched.serialKey
                        ? " is-invalid"
                        : "")
                    }
                    value={values.serialKey}
                    disabled
                  />
                  <div className="input-group-append">
                    <button
                      className="btn btn-secondary"
                      style={{ zIndex: "auto" }}
                      type="button"
                      onClick={() => setModalTablaBien(true)}
                    >
                      <i className="fa fa-search" />
                    </button>
                  </div>
                </div>
                {values.serialKey !== "" && (
                  <CancelOutlined className={s.btnClear} onClick={clearBien} />
                )}
                {touched.serialKey && errors.serialKey && (
                  <span style={{ color: "red" }}>{errors.serialKey}</span>
                )}
              </div>
            </Col>
          </Row>
          <Row className="d-flex justify-content-around">
            <Col xs={12} lg={8}>
              <label htmlFor="description">
                Descripcion del bien
                <span className="text-danger">*</span>
              </label>
              <textarea
                name="description"
                id="description"
                onChange={onChange}
                onBlur={handleBlur}
                rows={3}
                disabled = {true}
                className={
                  "form-control" +
                  (errors.description && touched.description
                    ? " is-invalid"
                    : "")
                }
                value={values.description}
              />
              {touched && errors.description && (
                <span style={{ color: "red" }}>{errors.description}</span>
              )}
            </Col>
          </Row>

          <div className="row mt-5">
            <div className="col-sm-6 order-2 order-sm-1 text-center text-sm-left mb-4">
              <Button color="danger" type="reset" onClick={() => cleanForm()}>
                Cancelar
              </Button>
            </div>
            <div className="col-sm-6 order-1 order-sm-2 text-center text-sm-right mb-4">
              <Button color="success" type="submit">
                Guardar
              </Button>
            </div>
          </div>
        </form>
        <FullFormLoader show={loader} />
      </Collapse>

      <ModalTableVer
        modal={modalTablaBien}
        setModal={setModalTablaBien}
        title="Bienes"
        cabeceras={cabecerasModalTablaBien}
        filtro={[true, true, true, true, true,true]}
        parametros={paramsModalTablaBien}
        selectRegistro={selectBien}
        backdrop={"static"}
        keyboard={false}
      />

      <ModalConfirmation
        modalTitle={"Crear"}
        modal={modalConfirmation}
        setModal={setModalConfirmation}
        crear={insertBien}
        isEdit={false}
        values={values}
      >
        <div className="d-flex justify-content-center">
          <h6>¿Desea continuar con el registro?</h6>
        </div>
      </ModalConfirmation>
    </div>
  );
};

export default AsignarBien;

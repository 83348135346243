import React, { useState, useEffect } from "react";
import { Col, Row, Input } from "reactstrap";
import { CancelOutlined } from "@material-ui/icons";
import s from "./InputClear.module.scss";
import DatePicker from "react-datepicker";
import ModalTableVer from "../../../../components/Modal/ModalTableVer";
import {
  RadioInput,
  SelectTypeHeadSingle,
} from "../../../../components/GenericInputsFormik";
import Notification from "../../../../components/Notification/Notification";
import { toast } from "react-toastify";

const PERIOD = [
  { label: "1", value: 1 },
  { label: "2", value: 2 },
  { label: "3", value: 3 },
  { label: "4", value: 4 },
  { label: "5", value: 5 },
  { label: "6", value: 6 },
  { label: "7", value: 7 },
  { label: "8", value: 8 },
  { label: "9", value: 9 },
  { label: "10", value: 10 },
  { label: "11", value: 11 },
  { label: "12", value: 12 },
  { label: "13", value: 13 },
  { label: "14", value: 14 },
  { label: "15", value: 15 },
  { label: "16", value: 16 },
  { label: "17", value: 17 },
  { label: "18", value: 18 },
  { label: "19", value: 19 },
  { label: "20", value: 20 },
  { label: "21", value: 21 },
  { label: "22", value: 22 },
  { label: "23", value: 23 },
  { label: "24", value: 24 },
];

const ComponentesForm = (props) => {
  const {
    onChange,
    handleBlur,
    values,
    touched,
    errors,
    setFieldValue,
    resetForm,
    activeTab,
    setFinalData,
  } = props;

  //VariablesTableEmpelado
  const [modalTabla, setModalTabla] = useState(false);
  const filtroModalTabla = [true, true, true, true, true];
  const cabecerasModalTabla = [
    "#",
    "Núm. Empleado",
    "Nombre",
    "Unidad de trabajo",
    "Puesto",
    "Seleccionar",
  ];
  const [paramsModalTabla] = useState({
    action: "datatable",
    table:
      "staff STF INNER JOIN employtypes AS EMT ON STF.keyEmployType = EMT.keyEmployType INNER JOIN jobstaff AS JBS ON JBS.idStaff = STF.idStaff INNER JOIN areas AS AR ON AR.idArea = JBS.idArea INNER JOIN jobtypes AS JBT ON JBT.keyJobType = STF.keyEmployType",
    rows: "STF.idStaff,STF.staffNumber,CONCAT(STF.name,' ',STF.pName,' ',STF.mName) as fullName,AR.name,JBT.nameJobType",
    conditions:
      "JBS.valid = 1 AND STF.keyStat = 1 AND JBS.enabled = 1",
    page: 0,
    records: 5,
    search: "",
    order: "",
  });

  //----------------------VariablesTablePensiones-------------------------------
  const [modalTablaPension, setModalTablaPension] = useState(false);
  const filtroModalTablaPension = [true, true, true, true, true];
  const cabecerasModalTablaPension = [
    "#",
    "Nombre",
    "Apellido paterno",
    "Apellido Materno",
  ];

  function handleClear() {
    setFieldValue("idStaff", "");
    setFieldValue("nameStaff", "");
    handleClearPension();
  }
  function handleClearPension() {
    setFieldValue("idPension", "");
    setFieldValue("beneficiary", "");
    setFinalData("");
  }

  const onChangeDate = (event) => {
    setFieldValue(event.name, event.value);
    setFinalData("");
  };

  const selectEmpleado = (el) => {
    setFieldValue("idStaff", el[0]);
    setFieldValue("nameStaff", `${el[1]} - ${el[2]}`);
    handleClearPension();
    setModalTabla(false);
  };
  const selectEmpleadoPension = (el) => {
    setFieldValue("idPension", `${el[0]}`);
    setFieldValue("beneficiary", `${el[1]} ${el[2]} ${el[3]}`);
    setModalTablaPension(false);
    setFinalData("");
  };

  const validBeneficiary = () => {
    if (
      values.idStaff !== "" ||
      (values.period !== "" && values.year !== null)
    ) {
      setModalTablaPension(true);
    } else {
      toast(
        <Notification
          type={"consultar_error"}
          backMessage={"Seleccione un usuario o un periodo"}
          withIcon
        />,
        { closeButton: false }
      );
    }
  };

  return (
    <Row className="d-flex justify-content-around">
      {activeTab !== 1 && (
        <Col xs={12} md={12} lg={10}>
          {activeTab !== 5 && (
            <Row>
              <Col xs={12} lg={12}>
                <div className="form-group position-relative">
                  <label htmlFor="nameStaff">
                    Empleado{" "}
                    {activeTab === 4 && <span className="text-danger">*</span>}
                  </label>
                  <div className="input-group">
                    <input
                      name="nameStaff"
                      onChange={onChange}
                      onBlur={handleBlur}
                      className={
                        "form-control" +
                        (errors.nameStaff && touched.nameStaff
                          ? " is-invalid"
                          : "")
                      }
                      value={values.nameStaff}
                      onClick={() => setModalTabla(true)}
                      readOnly
                    />
                    <div className="input-group-append">
                      <button
                        className="btn btn-secondary"
                        style={{ zIndex: "auto" }}
                        type="button"
                        onClick={() => setModalTabla(true)}
                      >
                        <i className="fa fa-search" />
                      </button>
                    </div>
                  </div>
                  {values.nameStaff !== "" && (
                    <CancelOutlined
                      className={s.btnClear}
                      onClick={handleClear}
                    />
                  )}
                  {touched.nameStaff && errors.nameStaff && (
                    <span style={{ color: "red" }}>{errors.nameStaff}</span>
                  )}
                </div>
              </Col>
            </Row>
          )}

          <Row>
            <Col xs={12} md={12} lg={6}>
              {/* <SelectTypeHeadSingle
          label="Periodo"
          isRequired={true}
          inputName="period"
          optionsArray={PERIOD}
          defaultOption="Selecciona un estado"
          onChangeMethod={onChange}
          onBlur={handleBlur}
          isDisabled={false}
          value={values.period}
          touched={touched.period}
          errors={errors.period}
          optionValue="value"
          optionName="label"
        /> */}

              <SelectTypeHeadSingle
                label="Periodo"
                isRequired={true}
                inputName="period"
                optionsArray={PERIOD}
                defaultOption="Seleccione un periodo"
                onChangeMethod={onChange}
                onBlur={handleBlur}
                isDisabled={false}
                value={values.period}
                touched={touched.period}
                errors={errors.period}
                optionName="label"
                optionValue="value"
              />
            </Col>

            <Col xs={12} md={12} lg={6}>
              <label htmlFor={values.year}>
                {" "}
                Año <span className="text-danger"> * </span>{" "}
              </label>
              <DatePicker
                selected={values.year}
                onChange={(date) => onChangeDate({ name: "year", value: date })}
                showYearPicker
                dateFormat="yyyy"
                yearItemNumber={6}
                className={
                  "form-control" +
                  (errors.year && touched.year ? " is-invalid" : "")
                }
              />

              {touched.year && errors.year && (
                <span style={{ color: "red" }}>{errors.year}</span>
              )}
            </Col>
          </Row>
          {activeTab === 4 && (
            <Row>
              <Col xs={12} lg={12}>
                <div className="form-group position-relative">
                  <label htmlFor="beneficiary">
                    Beneficiario{" "}
                    {values.idStaff === "" && (
                      <span className="text-danger">*</span>
                    )}
                  </label>
                  <div className="input-group">
                    <input
                      name="beneficiary"
                      onChange={onChange}
                      onBlur={handleBlur}
                      className={
                        "form-control" +
                        (errors.beneficiary && touched.beneficiary
                          ? " is-invalid"
                          : "")
                      }
                      value={values.beneficiary}
                      onClick={() => validBeneficiary()}
                      readOnly
                    />
                    <div className="input-group-append">
                      <button
                        className="btn btn-secondary"
                        style={{ zIndex: "auto" }}
                        type="button"
                        onClick={() => validBeneficiary()}
                      >
                        <i className="fa fa-search" />
                      </button>
                    </div>
                  </div>
                  {values.beneficiary !== "" && (
                    <CancelOutlined
                      className={s.btnClear}
                      onClick={handleClearPension}
                    />
                  )}
                  {touched.beneficiary && errors.beneficiary && (
                    <span style={{ color: "red" }}>{errors.beneficiary}</span>
                  )}
                </div>
              </Col>
            </Row>
          )}

          {activeTab === 5 && (
            <Row className="d-flex justify-content-center">
              <Col xs={12} md={12} lg={8} className="justify-content-center">
                <RadioInput
                  label="Tipo de reporte"
                  inputName="typeCheck"
                  onChangeMethod={onChange}
                  onBlurMethod={handleBlur}
                  value={values.typeCheck}
                  isRequired={true}
                  touched={touched.typeCheck}
                  errors={errors.typeCheck}
                  optionsArray={[
                    { val: "1", name: "Empleados" },
                    { val: "0", name: "Pensionados" },
                  ]}
                  optionValue="val"
                  optionName="name"
                  isCenter
                />
              </Col>
            </Row>
          )}
        </Col>
      )}

      <ModalTableVer
        modal={modalTabla}
        setModal={setModalTabla}
        title="Empleados"
        cabeceras={cabecerasModalTabla}
        filtro={filtroModalTabla}
        parametros={paramsModalTabla}
        selectRegistro={selectEmpleado}
        backdrop={"static"}
        keyboard={false}
        btnClose={true}
        resetForm={resetForm}
      />
      <ModalTableVer
        modal={modalTablaPension}
        setModal={setModalTablaPension}
        title={
          values.idStaff !== ""
            ? `BENEFICIARIOS DE ${values.nameStaff} `
            : `BENEFICIARIOS DEL PERIODO ${values.period} DEL AÑO ${new Date(
                values.year
              ).getFullYear()}`
        }
        cabeceras={cabecerasModalTablaPension}
        filtro={filtroModalTablaPension}
        parametros={
          values.idStaff !== ""
            ? {
                action: "datatable",
                table: "pensions",
                rows: "idPension,name,pName,mName",
                conditions: `idStaff = ${values.idStaff}`,
                page: 0,
                records: 5,
                search: "",
                order: "",
              }
            : {
                action: "datatable",
                table:
                  "payslips p left join pensions pe on (pe.idPension = p.idGeneral)",
                rows: "pe.numberPension,pe.name,pe.pName,pe.mName",
                conditions: `period=${values.period} and year=${new Date(
                  values.year
                ).getFullYear()} and keyTypePayslip=5 and p.keyStat=52`,
                page: 0,
                records: 5,
                search: "",
                order: "",
              }
        }
        selectRegistro={selectEmpleadoPension}
        keyboard={false}
        resetForm={resetForm}
        btnClose={true}
      />
    </Row>
  );
};
export default ComponentesForm;

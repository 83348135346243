import * as Yup from "yup"
import { useState } from "react";
import { useFormik } from "formik";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import { toast } from "react-toastify";

import { TextAreaInput } from "../../../../../components/GenericInputsFormik";
import { peticionesReceiver } from "../../../../../helpers/peticionesReceiver";
import Notification from "../../../../../components/Notification/Notification";
import ModalConfirmation from "../../../../../components/Modal/ModalConfirmation";

export default function EditarJustificacionSolicitud({ id, object, setRefresh }) {
    const [modal, setModal] = useState(false);
    const [modalConf, setModalConf] = useState(false);

    const handleClose = () => { setModal(false) };
    const handleOpen = () => { setModal(true) };

    const defaultForm = {
        object,
    }

    const FormSchema = Yup.object().shape({
        object: Yup.string().required("Ingrese una justificación para la solicitud de adquisición de bienes, contratación de servicios y/o tramite de limite presupuestal"),
    })

    const { handleSubmit, values, handleBlur, errors, touched, setFieldValue, resetForm } =
        useFormik({
            initialValues: defaultForm,
            onSubmit: () => {
                setModalConf(true)
            },
            validationSchema: FormSchema,
        });

    const onChange = (e) => {
        const { name, value } = e.target
        setFieldValue([name], value);
    }

    function getParams(rows) {
        const params = {
            table: "quotes",
            action: "update",
            rows,
            condition: { idQuote: id }
        }
        return params
    }

    async function OnEdit(rows) {
        const API = peticionesReceiver();
        try {
            const res = await API.peticion(getParams(rows, false))
            if (res.status === 200 && res.data.code === "200") {
                toast(<Notification type={"modifica_exito"} backMessage={res.data.message} withIcon />);
                setRefresh(true)
                resetForm()
                handleClose()
            } else {
                toast(<Notification type={"modifica_error"} backMessage={res.data.message} withIcon />);
            }
        } catch (err) {
            console.log(err)
            toast(<Notification type={"modifica_error"} withIcon />);
        }
    }

    return (
        <>
            <Button color="warning" size="sm" className="d-flex align-items-center py-1 px-2 my-2" onClick={handleOpen}>
                <i className="fa fa-pencil mr-2" />Editar justificación
            </Button>
            <Modal isOpen={modal} fullscreen="lg" size="xl" backdrop="static" centered>
                <ModalHeader toggle={handleClose}>
                    Editar justificación
                </ModalHeader>
                <ModalBody>
                    <form onSubmit={handleSubmit} className="px-3">
                        <TextAreaInput
                            label="Justificación para la solicitud para adquisición"
                            inputName="object"
                            inputRows={10}
                            onChangeMethod={onChange}
                            onBlurMethod={handleBlur}
                            value={values.object}
                            isRequired
                            touched={touched.object}
                            errors={errors.object}
                        />
                        <div className="mt-2 d-flex justify-content-around flex-md-row flex-column">
                            <Button color="danger" className="mx-1 my-1" onClick={handleClose}>Cerrar</Button>
                            <Button color="success" className="mx-1 my-1" type="submit" >Guardar cambios</Button>
                        </div>
                    </form>
                </ModalBody>
            </Modal>
            <ModalConfirmation
                modalTitle={"Crear solicitud"}
                modal={modalConf}
                editar={(rows) => OnEdit(rows)}
                isEdit={true}
                setModal={setModalConf}
                values={values}
            >
                <div className="d-flex justify-content-center">
                    <h6>¿Desea guardar los cambios del registro?</h6>
                </div>
            </ModalConfirmation>
        </>
    )
} 
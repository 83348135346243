import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Button, Collapse, Row, Col } from "reactstrap";
import { peticionesReceiver } from "../../../../helpers/peticionesReceiver";

import { toast } from "react-toastify";
import Notification from "../../../../components/Notification/Notification";
import ModalConfirmation from "../../../../components/Modal/ModalConfirmation";
import { TextInput } from "../../../../components/GenericInputsFormik/";
import { NumberInput } from "../../../../components/GenericInputsFormik/NumberInput";

import TablaDocumentosTiposCompras from "./TablaDocumentosTiposCompras";

const FormTiposCompras = (props) => {
  const { collapse, setCollapse, setRefreshP, data, setData, permissions } =
    props;
  const API = peticionesReceiver();
  const [modalConfirmation, setModalConfirmation] = useState(false);
  const [editando, setEditando] = useState(false);

  useEffect(() => {
    if (data !== "") getForEdit(data);
  }, [data]);

  const FormSchema = Yup.object().shape({
    nameTypePurchase: Yup.string().required("Ingrese el tipo de la compra"),
    minPayment: Yup.number().required("Ingrese la monto minimo"),
    maxPayment: Yup.number()
      .required("Ingrese la monto maximo")
      .min(Yup.ref("minPayment"), "La monto  debe ser mayor al minimo"),
  });

  const {
    handleSubmit,
    values,
    errors,
    setFieldValue,
    touched,
    handleBlur,
    resetForm,
  } = useFormik({
    initialValues: {
      nameTypePurchase: "",
      minPayment: "",
      maxPayment: "",
    },
    onSubmit: () => {
      setModalConfirmation(true);
    },
    validationSchema: FormSchema,
    enableReinitialize: true,
  });

  const onChange = (e) => {
    const { name, value } = e.target;
    if (name === "minPayment") {
      setFieldValue("maxPayment", "");
    }
    setFieldValue([name], value);
  };

  const getForEdit = async (row) => {
    if (!collapse) {
      setFieldValue("keyTypePurchase", row.keyTypePurchase);
      setFieldValue("nameTypePurchase", row.nameTypePurchase);
      setFieldValue("minPayment", row.minPayment);
      setFieldValue("maxPayment", row.maxPayment);
      setEditando(true);
      setCollapse(true);
    }
  };

  const resetFormuario = () => {
    setEditando(false);
    setCollapse(false);
    setFieldValue("nameTypePurchase", "");
    resetForm();
    setData("");
    // setFieldValue("keyTypePurchase", "");
    // setFieldValue("nameTypePurchase", "");
    // setFieldValue("minPayment", "");
    // setFieldValue("maxPayment", "");
  };

  const savePurchase = async () => {
    const data =
      !editando === true
        ? {
            action: "insert",
            table: "types_purchases",
            rows: {
              nameTypePurchase: values.nameTypePurchase,
              minPayment: values.minPayment,
              maxPayment: values.maxPayment,
            },
            validate: [["nameTypePurchase", "minPayment", "maxPayment"]],
          }
        : {
            action: "update",
            table: "types_purchases",
            rows: {
              nameTypePurchase: values.nameTypePurchase,
              minPayment: values.minPayment,
              maxPayment: values.maxPayment,
            },
            condition: {
              keyTypePurchase: values.keyTypePurchase,
            },
            validate: [["nameTypePurchase", "minPayment", "maxPayment"]],
          };
    const res = await API.peticion(data);
    if (res.status === 200 && res.data.status === "success") {
      toast(
        <Notification
          type={"agrega_exito"}
          backMessage={res.data.message}
          withIcon
        />
      );
      if (editando) {
        setRefreshP(true);
        setData(res.data.data[0]);
      } else {
        setRefreshP(true);
        setData(res.data.data[0]);
      }
      // resetFormuario();
    } else {
      toast(
        <Notification
          type={"agrega_error"}
          backMessage={res.data.message}
          withIcon
        />
      );
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <Row className="justify-content-around">
          <Col xs={12} lg={6}>
            <TextInput
              label="Tipo de compra"
              inputType="text"
              inputName="nameTypePurchase"
              onChangeMethod={onChange}
              onBlurMethod={handleBlur}
              value={values.nameTypePurchase}
              touched={touched.nameTypePurchase}
              errors={errors.nameTypePurchase}
            />
          </Col>

          <Col xs={12} lg={6}>
            <NumberInput
              label="Monto Minimo"
              inputName="minPayment"
              onChangeMethod={onChange}
              onBlurMethod={handleBlur}
              value={values.minPayment}
              touched={touched.minPayment}
              errors={errors.minPayment}
              disableSep={false}
              prefix={"$"}
            />
          </Col>
          <Col xs={12} lg={6}>
            <NumberInput
              label="Monto Maximo"
              inputName="maxPayment"
              onChangeMethod={onChange}
              onBlurMethod={handleBlur}
              value={values.maxPayment}
              touched={touched.maxPayment}
              errors={errors.maxPayment}
              disableSep={false}
              prefix={"$"}
              isMin={values.minPayment}
              isDisabled={
                values.minPayment !== "" &&
                values.minPayment !== null &&
                values.minPayment !== undefined
                  ? false
                  : true
              }
              min
            />
          </Col>
        </Row>

        <div className="d-flex justify-content-around mt-2">
          {(data.keyTypePurchase === "" ||
            data.keyTypePurchase === null ||
            data.keyTypePurchase === undefined) && (
            <div>
              <Button
                color="danger"
                onClick={() => {
                  resetFormuario();
                }}
              >
                Cancelar
              </Button>
            </div>
          )}

          {editando
            ? permissions.UPD && (
                <div>
                  <Button type="submit" color="success">
                    {editando ? "Editar" : "Agregar"}
                  </Button>
                </div>
              )
            : permissions.INS && (
                <div>
                  <Button type="submit" color="success">
                    {editando ? "Editar" : "Agregar"}
                  </Button>
                </div>
              )}

          {/* {editando ? (
              permissions.UPD ? (
                
            ):(
              ""
            )} */}
        </div>

        <ModalConfirmation
          modalTitle={editando ? "Editar" : "Crear"}
          modal={modalConfirmation}
          setModal={setModalConfirmation}
          crear={savePurchase}
          isEdit={false}
          values={values}
        >
          <div className="text-center">
            Esta seguro de continuar con el registro
          </div>
        </ModalConfirmation>
      </form>
      {data.keyTypePurchase !== "" &&
        data.keyTypePurchase !== null &&
        data.keyTypePurchase !== undefined && (
          <TablaDocumentosTiposCompras
            data={data}
            resetFormuario={resetFormuario}
          />
        )}
    </div>
  );
};

export default FormTiposCompras;


import React from "react";
import Widget from "../../../../components/Widget/Widget";
import FormProveedores from "../FormProveedores";
import { updateProveedor } from "../functions";

export default function EditarProveedor(props) {

  return (
    <Widget className="widget-p-md">
      <h3>Editar proveedor de nomina</h3>
      <FormProveedores data={localStorage.getItem('dataContent').split('|-|')} editar={updateProveedor} />
    </Widget>
  )
}

import { useEffect } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  SelectTypeHeadSingle,
  TextAreaInput,
  TextInput,
} from "../../../../components/GenericInputsFormik";
import { Button, Col, Collapse, CustomInput, Row } from "reactstrap";
import { useState } from "react";
import { NumberInput } from "../../../../components/GenericInputsFormik/NumberInput";
import { toast } from "react-toastify";
import Notification from "../../../../components/Notification/Notification";
import { peticionesReceiver } from "../../../../helpers/peticionesReceiver";
import ModalTableVer from "../../../../components/Modal/ModalTableVer";
import { CancelOutlined } from "@material-ui/icons";
import s from "./InputClear.module.scss";
import ModalConfirmation from "../../../../components/Modal/ModalConfirmation";

const FormSchema = Yup.object().shape({
    nameStaff: Yup.string().required("Seleccione un empleado"),
    justification: Yup.string().required("Ingrese una justificación"),
});

export default function FormNuevaSolicitud(props) {
  const {setRefresh,idArea} = props
  const API = peticionesReceiver();
  const [collapse, setCollapse] = useState(false);
  const [modal, setModal] = useState(false);

  const [modalTabla, setModalTabla] = useState(false);
  const filtroModalTabla = [true, true, true, true, true];
  const cabecerasModalTabla = [
    "#",
    "Núm. Empleado",
    "Nombre",
    "Unidad de trabajo",
    "Puesto",
    "Seleccionar",
  ];
  const [paramsModalTabla] = useState({
    action: "datatable",
    table:
      "staff STF INNER JOIN employtypes AS EMT ON STF.keyEmployType = EMT.keyEmployType INNER JOIN jobstaff AS JBS ON JBS.idStaff = STF.idStaff INNER JOIN areas AS AR ON AR.idArea = JBS.idArea INNER JOIN jobtypes AS JBT ON JBT.keyJobType = STF.keyEmployType",
    rows: "STF.idStaff,STF.staffNumber,CONCAT(STF.name,' ',STF.pName,' ',STF.mName) as fullName,AR.name,JBT.nameJobType",
    conditions:
      "STF.enabled = 1 AND JBS.valid = 1 AND STF.keyStat = 1 AND JBS.enabled = 1",
    page: 0,
    records: 5,
    search: "",
    order: "",
  });

  const selectEmpleado = (el) => {
    setFieldValue("idStaff", el[0]);
    setFieldValue("nameStaff", `${el[1]} - ${el[2]}`);

    setModalTabla(false);
  };

  function handleClear() {
    setFieldValue("idStaff", "");
    setFieldValue("nameStaff", "");
  }

  const defaultForm = {
    justification: "",
    idStaff:"",
    nameStaff:"",
  };
  const {
    handleSubmit,
    values,
    handleBlur,
    errors,
    touched,
    setFieldValue,
    handleReset,
    resetForm,
  } = useFormik({
    initialValues: defaultForm,
    validationSchema: FormSchema,
    onSubmit: () => {
        setModal(true)
    },
    onReset: () => {
      setCollapse(false);
    },
    enableReinitialize: true,
  });

  const onChange = ({ target }) => {
    setFieldValue(target.name, target.value);
  };

  const saveRequest = () => {
    const data = {
        action: "insert",
        table: "budgetrequests",
        rows: {
            keyStat: "116",
            idArea: idArea,
            idStaffRequest : values.idStaff,
            justification: values.justification
        },
      };    
      API.peticion(data).then((res) => {
          if (res.status === 200 && res.data.code === "200") {
            setCollapse(false)
            setRefresh(true)
            resetForm()
            toast(<Notification type={"agrega_exito"} backMessage={res.data.message} withIcon />);
          } else {
            toast(<Notification type={"agrega_error"} backMessage={res.data.message} withIcon />);
          }
        })
        .catch((err) => {
          toast(<Notification type={"consultar_servidor_error"} withIcon />);
        });

  }

  return (
    <>
      <div className="my-3 d-flex flex-md-row flex-column justify-content-md-end">
        <Button onClick={() => setCollapse(true)}>Nueva Solicitud</Button>
      </div>

      <Collapse isOpen={collapse} className="pt-3 border-top">
        <div className="text-center">
        <h5>Registrar nueva solicitud</h5>

        </div>
        <form className="mt-2" onSubmit={handleSubmit} onReset={handleReset}>
          <Row>
          <Col xs={12} lg={6}>
              <div className="form-group position-relative">
                <label htmlFor="nameStaff">
                  Empleado <span className="text-danger">*</span>
                </label>
                <div className="input-group">
                  <input
                    name="nameStaff"
                    onChange={onChange}
                    onBlur={handleBlur}
                    className={
                      "form-control" +
                      (errors.nameStaff && touched.nameStaff
                        ? " is-invalid"
                        : "")
                    }
                    value={values.nameStaff}
                    disabled
                  />
                  <div className="input-group-append">
                    <button
                      className="btn btn-secondary"
                      style={{ zIndex: "auto" }}
                      type="button"
                      onClick={() => setModalTabla(true)}
                    >
                      <i className="fa fa-search" />
                    </button>
                  </div>
                </div>
                {values.nameStaff !== "" && (
                  <CancelOutlined
                    className={s.btnClear}
                    onClick={handleClear}
                />
                )}
                {touched.nameStaff && errors.nameStaff && (
                  <span style={{ color: "red" }}>{errors.nameStaff}</span>
                )}
              </div>
            </Col>
            <Col  xs={12} lg={6}>
              <TextAreaInput
                label="Justificación para la solicitud para adquisición"
                inputName="justification"
                inputRows={3}
                onChangeMethod={onChange}
                onBlurMethod={handleBlur}
                value={values.justification}
                isRequired
                touched={touched.justification}
                errors={errors.justification}
              />
            </Col>

          </Row>
          <div className=" d-flex flex-md-row flex-column-reverse justify-content-md-around">
            <Button color="danger" type="reset" className="mt-3">
              Cancelar
            </Button>
            <Button color="success" type="submit" className="mt-2 mt-md-3">
              Guardar
            </Button>
          </div>

          <ModalConfirmation
                modalTitle={"Crear"}
                modal={modal}
                setModal={setModal}
                crear={saveRequest}
                isEdit={false}
                values={values}
              >
                  <div className="d-flex justify-content-center">
                    <h6>¿Desea continuar con el registro?</h6>
                  </div>
              </ModalConfirmation>
        </form>

        <ModalTableVer
          modal={modalTabla}
          setModal={setModalTabla}
          title="Empleados"
          cabeceras={cabecerasModalTabla}
          filtro={filtroModalTabla}
          parametros={paramsModalTabla}
          selectRegistro={selectEmpleado}
          backdrop={"static"}
          keyboard={false}
          btnClose={true}
          resetForm={resetForm}
        />
      </Collapse>
    </>
  );
}

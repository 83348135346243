import React, { useState } from "react";
import FormIncidentsGroup from "../components/FormIncidentsGroup";
import { Button, Collapse, Alert } from "reactstrap";
import Widget from "../../../../../components/Widget/Widget";

const CrearGrupoIncidencia = () => {
  const [collapse, setCollapse] = useState(false);
  const toggleCollapse = () => setCollapse(!collapse);
  return (
    <Widget className={"widget-p-md"}>
      

      {/* <Collapse isOpen={collapse} className="mt-3"> */}
        <FormIncidentsGroup collapse={collapse} setCollapse={setCollapse} />
      {/* </Collapse> */}
    </Widget>
  );
};

export default CrearGrupoIncidencia;

import { CancelOutlined } from "@material-ui/icons";
import React from "react";
import s from "./InputClear.module.scss";

export const ButtonInput = (props) => {
  const {
    label,
    inputName,
    value,
    isRequired,
    touched,
    errors,
    isPlaceholder,
    setModalTable,
    clearFun,
    isDisabled,
    disabledClear,
    onButtonClicik
  } = props;
  return (
    <>
      <div className="form-group position-relative">
        <label htmlFor={inputName}>
          {label} {isRequired && <span className="text-danger">*</span>}
        </label>
        <div className="input-group">
          <input
            name={inputName}
            id={inputName}
            className={
              "form-control" +
              (errors && touched ? " is-invalid" : "")
            }
            value={value}
            placeholder={isPlaceholder? isPlaceholder : ""}
            disabled={isDisabled}
            readOnly
            onClick={() => {onButtonClicik? onButtonClicik() : setModalTable(true)}}
          />
          <div className="input-group-append">
            <button
              className="btn btn-secondary"
              style={{ zIndex: "auto" }}
              type="button"
              onClick={() => {onButtonClicik? onButtonClicik() : setModalTable(true)}}
              disabled={isDisabled? true : false}
            >
              <i className="fa fa-search" />
            </button>
          </div>
        </div>
        {(disabledClear? false: true) && value !== "" && (
          <CancelOutlined
            className={s.btnClear}
            onClick={() => clearFun()}
            style={{zIndex: 3}}
          />
        )}
        {touched && errors && (
          <span style={{ color: "red" }}>{errors}</span>
        )}
      </div>
    </>
  );
};

import { useRef, useState } from "react";
import Datatable from "../../../components/Datatable/Datatable";
import FullFormLoader from "../../../components/Loader/FullFormLoader";
import { useEffect } from "react";
import { doRequest, doRequestSaveRes, uploadFile } from "../../../helpers/requests";
import { Button, Col, Collapse, Row } from "reactstrap";
import ModalComponentEliminar from "../../../components/Modal/ModalDelete";
import * as Yup from "yup";
import { useFormik } from "formik";
import ModalViewFile from "../../../components/Modal/ModalViewFile";
import Notification from "../../../components/Notification/Notification";
import { toast } from "react-toastify";
import { FilesInput, RadioInput, SelectTypeHeadSingle, TextAreaInput, TextInput } from "../../../components/GenericInputsFormik/index";
import { NumberInput } from "../../../components/GenericInputsFormik/NumberInput";
import { PERIOD } from "../../../libs/tools/format";
import { YearInput } from "../../../components/GenericInputsFormik/YearInput";
import ModalConfirmation from "../../../components/Modal/ModalConfirmation";

export default function ENMultasRF({ idStaff, permissions }){
    const [loading, setLoading] = useState(true);
    const [isOpen, setIsOpen] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [modalConfirm, setModalConfirm] = useState(false);
    const [modalFile, setModalFile] = useState(false);
    const [linkFile, setLinkFile] = useState("");
    const [selects, setSelects] = useState({
        sanciones: [],
        conceptos: [],
        estatus: [],
     });
    const [isComplete, setIsComplete] = useState(false);
    const refInpFile = useRef(null);
    
    useEffect(()=>{
        getSanciones();
        getConceptos();
        getEstatus();
    }, []);

    async function getSanciones(){
        const params = {
            action: "select",
            table: "types_penaltys",
            condition: { enabled: 1 },
            order: "namePenalty ASC",
        }
        const res = await doRequest("receiver/receiver.php", params, false);
        if(res.length > 0){
            setSelects((ps)=> ( { ...ps, sanciones: res } ));
        }
    }

    async function getConceptos(){
        const params = {
            action: "multiselect",
            table: "unders",
            rows: "idUnder, CONCAT(under,' - ',nameUnder) AS nameUnder",
            conditions: `enabled = 1 AND keyRollUnder IN (5, 6)`,
            order: "under ASC",
          }
        const res = await doRequest("receiver/receiver.php", params, false);
        if(res.length > 0){
            setSelects((ps)=> ( { ...ps, conceptos: res } ));
        }
    }

    async function getEstatus(){
        const params = {
            action: "select",
            table: "status",
            condition: { enabled: 1, keyTypeStat: 6 },
            order: "nameStat ASC",
        }
        const res = await doRequest("receiver/receiver.php", params, false);
        if(res.length > 0){
            setSelects((ps)=> ( { ...ps, estatus: res } ));
        }
        setLoading(false);
    }

    /* ********** FORM ********** */
    const {
        handleSubmit,
        values,
        handleBlur,
        errors,
        touched,
        setFieldValue,
        handleReset,
        setValues
    } = useFormik({
        initialValues: { ...defaultForm, idStaff: idStaff },
        validationSchema: formSchema,
        onSubmit: () => setModalConfirm(true),
        onReset: () => { 
            setIsOpen(false);
            refInpFile.current.value = "";
            setIsComplete(false);
        },
        enableReinitialize: true,
    });

    function onChange({ target }) {
        var value = target.value;
        if(target.name === "comprobante"){
            value = target.files[0] || "";
        }
        setFieldValue(target.name, value);
    }

    async function saveInfo(){
        setLoading(true);
        const params = {
            action: "savePenaltys",
            rows: { ...values, startYear: values.startYear.getFullYear() },
        }
        const res = await doRequest("app/facades/employees/benefitsF.php", params, true);
        if(res.length > 0){
            if(values.comprobante){
                await uploadFile( res[0].idPenalty, "saveFileReplace", 48, values.comprobante, false );
            }
            handleReset();
            setRefresh(true);
        }
        setLoading(false);
    }
    /* ********** END FORM ********** */

    /* ********** TABLE ********** */
    const columns = [
        { data: "ix" },
        { data: "policy" },
        { data: "namePenalty" },
        { data: "nameUnder" },
        { data: "amountTotal", format: "currency"},
        { data: "amountBiweekly", format: "currency"},
        { data: null, render: (row) => <p>{row.startPeriod}/{row.startYear}</p>, orderValue: "CONCAT(startYear, startPeriod)" },
        { data: null, render: (row) => <p>{row.endPeriod}/{row.endYear}</p>, orderValue: "CONCAT(endYear, endPeriod)" },
        { data: "noPays"},
        { data: "noPaysMade"},
        { data: "nameStat"},
        { data: "src", render: (d) => d && <i className="fa fa-file-pdf-o cursor-pointer" onClick={() => showFile(d) } />},
        { data: null, render: (d) => <i className="fa fa-pencil text-warning cursor-pointer" onClick={() => openToEdit(d)} />},
        { data: "idPenalty", render: (d) => (
            permissions.DEL &&
            <ModalComponentEliminar
                modalTitle="Eliminar"
                id={d}
                table="penaltys"
                nameColumn={["idPenalty"]}
                elimina={elimianrRegistro}
            >
                <h6>¿Seguro de eliminar la multa/sanción?</h6>
                No podrá deshacer este cambio
            </ModalComponentEliminar>
        )},
    ]

    async function elimianrRegistro(id) {
        const params = {
            action: "delete",
            table: "penaltys",
            condition: { idPenalty: id },
            force: 1,
        };
        setLoading(true);
        const data = await doRequest("receiver/receiver.php", params, true);
        if (data.length > 0) {
            handleReset();
            setRefresh(true);
        }
        setLoading(false);
    }

    function openToEdit(row){
        if(isOpen){
            toast(
                <Notification type="warning" backMessage="Guarde sus cambios antes de continuar" />,
                { closeButton: false }
            );
            return;
        }
        setValues({
            idPenalty: row.idPenalty,
            idStaff: row.idStaff,
            policy: row.policy,
            keyTypePenalty: +row.keyTypePenalty,
            idUnder: +row.idUnder,
            keyStat: +row.keyStat,
            noPays: row.noPays,
            amountTotal: row.amountTotal,
            amountBiweekly: +row.amountBiweekly,
            startPeriod: row.startPeriod,
            startYear: new Date(+row.startYear, 0, 1, 0, 0, 0, 0),
            endPeriod: row.endPeriod,
            endYear: new Date(+row.endYear, 0, 1, 0, 0, 0, 0),
            noPaysMade: +row.noPaysMade,
            notes: row.notes,
            comprobante: row.comprobante,
            idStaff: idStaff,
            auxiliarFund: row.auxiliarFund || "0",
            judge: row.judge,
            expNumber: row.expNumber,
            umas: row.umas
        }, true);
        setIsComplete(row.noPaysMade === +row.noPays && +row.noPays !== 0);
        setIsOpen(true);
    }

    async function getData(ordCol, itemsPerPage, currentPage, search) {
        const res = await doRequestSaveRes(
          "receiver/receiver.php",
          getParams(ordCol, itemsPerPage, currentPage, search),
          false,
          false
        );
        if (res.code === "200"){
            res.data.forEach((e, ix) => e.ix = currentPage + 1 + ix );
            return res;
        } else return null;
    }
    
    function getParams(ordCol, itemsPerPage, currentPage, search) {
        const params = {
            action: "datatable",
            table:
                "penaltys A INNER JOIN status B USING(keyStat) INNER JOIN types_penaltys C USING(keyTypePenalty) LEFT JOIN bookcase D ON A.idPenalty = D.reference AND D.keyFileType = 48 LEFT JOIN files E USING(idBook) INNER JOIN unders G USING(idUnder)",
            rows: "A.idPenalty, A.policy, A.startPeriod, A.endPeriod, A.startYear, A.endYear, A.noPays, A.noPaysMade, B.nameStat, C.namePenalty, A.amountTotal, A.amountBiweekly, E.src, G.nameUnder, A.notes, A.keyTypePenalty, A.idUnder, A.keyStat,A.auxiliarFund,A.judge,A.expNumber,A.umas",//
            conditions: `A.enabled = 1 AND A.idStaff = ${idStaff}`,
            page: (itemsPerPage * currentPage).toString(),
            records: itemsPerPage.toString(),
            search,
            order: ordCol
        };
      return params;
    }

      function showFile(src) {
        if (src === "") {
            toast(
                <Notification
                    type={"warning"}
                    backMessage="Este registro no cuenta con una evidencia"
                    withIcon
                />,
                { closeButton: false }
            );
        } else setLinkFile(src);
    }

    /* ********** END TABLE ********** */

    /* Abrir modal */
    useEffect(() => {
        if (linkFile !== "") setModalFile(true);
    }, [linkFile]);

    /* Cerrar modal */
    useEffect(() => {
        if (!modalFile) setLinkFile("");
    }, [modalFile]);

    /* Calcula año y periodo final */
    useEffect(()=>{
        if(values.startYear && values.startPeriod && values.noPays && +values.noPays > 0) calEnds();
        else{
            setFieldValue("endYear", "");
            setFieldValue("endPeriod", "");
        }
    }, [values.startYear, values.startPeriod, values.noPays]);

    /* Calcular monto quincenal */
    useEffect(()=>{
        if(values.amountTotal && values.noPays && +values.noPays > 0){
            setFieldValue("amountBiweekly", (+values.amountTotal / +values.noPays));
        }else{
            setFieldValue("amountBiweekly", "");
        }
    }, [values.amountTotal, values.noPays]); 

    function calEnds(){
        const pFC = +values.startPeriod + +values.noPays - 1;
        const excede = pFC > 24;
        const ultimo = pFC % 24 === 0;
        const anios = excede ? Math.floor(pFC / 24) - ultimo : 0;
        const periodos = excede ? (ultimo ? 24 : pFC % 24) : pFC;

        setFieldValue("endYear", values.startYear.getFullYear() + anios );
        setFieldValue("endPeriod", periodos);
    }

    return(
        <div className="mt-5">
            <h1 className="text-secondary">Multas</h1>

            {
                permissions.CON && 
                <Datatable
                    headers={["#", "Oficio", "Tipo de Sanción", "Concepto", "Monto Total", "Monto quincenal", "Periodo inicio", "Periodo termino", "Parcialidades", "Pagos aplicados", "Estatus", "Evidencia", "Editar", "Eliminar"]}
                    columns={columns}
                    columnDefs={columnDefs}
                    petition={getData}
                    control="back"
                    stateRefresh={[refresh, setRefresh]}
                    order={{ col: 6, opt: "DESC" }}
                    className="px-0 mt-4"
                />
            }

            <div className="d-flex flex-column-reverse flex-md-row justify-content-md-between text-center mt-4 mb-2">
                <div>
                    {
                        isOpen && <h4 className="my-3 my-md-2">{`${values.idIncapacity ? "Editar" : "Agregar"} Multa`}</h4>
                    }
                </div>
                {permissions.INS && (
                    <Button color="add" onClick={() => setIsOpen(true)} disabled={isOpen} >
                        Agregar
                    </Button>
                )}
            </div>
            
            <Collapse isOpen={isOpen}>
                <form onSubmit={handleSubmit} onReset={handleReset}>
                    <Row className="mt-2">
                        <Col xs={12} md={12} lg={4}>
                            <TextInput
                                label="Oficio"
                                inputType="text"
                                inputName="policy"
                                onChangeMethod={onChange}
                                onBlurMethod={handleBlur}
                                value={values.policy}
                                isRequired={true}
                                touched={touched.policy}
                                errors={errors.policy}
                            />
                        </Col>
                        <Col xs={12} md={12} lg={4}>
                            <SelectTypeHeadSingle
                                label="Seleccione un tipo de sancion/multa"
                                inputName="keyTypePenalty"
                                optionsArray={selects.sanciones}
                                defaultOption="Seleccione un tipo de sancion/multa"
                                onChangeMethod={onChange}
                                onBlur={handleBlur}
                                value={values.keyTypePenalty}
                                touched={touched.keyTypePenalty}
                                errors={errors.keyTypePenalty}
                                optionValue="keyTypePenalty"
                                optionName="namePenalty"
                                isRequired
                            />
                        </Col>
                        <Col xs={12} md={12} lg={4}>
                            <SelectTypeHeadSingle
                                label="Concepto"
                                inputName="idUnder"
                                optionsArray={selects.conceptos}
                                defaultOption="Seleccione un concepto"
                                onChangeMethod={onChange}
                                onBlur={handleBlur}
                                isDisabled={false}
                                value={values.idUnder}
                                touched={touched.idUnder}
                                errors={errors.idUnder}
                                optionValue="idUnder"
                                optionName="nameUnder"
                                isRequired
                            />
                        </Col>
                        <Col xs={12} md={12} lg={4}>
                            <SelectTypeHeadSingle
                                label="Seleccione un estatus"
                                inputName="keyStat"
                                optionsArray={selects.estatus}
                                defaultOption="Seleccione un estatus"
                                onChangeMethod={onChange}
                                onBlur={handleBlur}
                                value={values.keyStat}
                                touched={touched.keyStat}
                                errors={errors.keyStat}
                                optionValue="keyStat"
                                optionName="nameStat"
                                isRequired
                            />
                        </Col>
                        <Col xs={12} md={12} lg={4}>
                            <FilesInput
                                label="Evidencia"
                                inputName="comprobante"
                                onChangeMethod={onChange}
                                onBlurMethod={handleBlur}
                                fileAccept="application/pdf"
                                touched={touched.comprobante}
                                errors={errors.comprobante}
                                basicReq={false}
                                refInput={refInpFile}
                            />
                        </Col>

                        <Col xs={12} md={12} lg={4}>
                            <RadioInput
                                label="Fondo Auxiliar"
                                inputName="auxiliarFund"
                                onChangeMethod={onChange}
                                value={values.auxiliarFund}
                                optionsArray={opt}
                                optionValue="val"
                                optionName="name"
                                touched={touched.auxiliarFund}
                                errors={errors.auxiliarFund}
                                isRequired
                                isCenter
                            />
                        </Col>
                        <Col xs={12} md={12} lg={4}>
                            <TextInput
                                label="Juzgado"
                                inputType="text"
                                inputName="judge"
                                onChangeMethod={onChange}
                                onBlurMethod={handleBlur}
                                value={values.judge}
                                touched={touched.judge}
                                errors={errors.judge}
                                isRequired
                            />
                        </Col>
                        <Col xs={12} md={12} lg={4}>
                            <TextInput
                                label="Núero de causa"
                                inputType="text"
                                inputName="expNumber"
                                onChangeMethod={onChange}
                                onBlurMethod={handleBlur}
                                value={values.expNumber}
                                touched={touched.expNumber}
                                errors={errors.expNumber}
                                isRequired
                            />
                        </Col>
                    </Row>

                    <Row className="d-flex justify-content-around">
                        <Col xs={12} md={5}>
                            <NumberInput
                                label="Monto total"
                                inputName="amountTotal"
                                onChangeMethod={onChange}
                                onBlurMethod={handleBlur}
                                value={values.amountTotal}
                                errors={errors.amountTotal}
                                touched={touched.amountTotal}
                                isMin={1}
                                prefix="$"
                                decimals
                                isRequired={!!values.idPenalty}
                                isDisabled={isComplete}
                            />
                        </Col>
                        <Col xs={12} md={5}>
                            <NumberInput
                                label="No. Parcialidades"
                                inputName="noPays"
                                onChangeMethod={onChange}
                                onBlurMethod={handleBlur}
                                value={values.noPays}
                                errors={errors.noPays}
                                touched={touched.noPays}
                                isMin={1}
                                isMax={999}
                                maxLength={3}
                                isRequired={!!values.idPenalty}
                                isDisabled={isComplete}
                            />
                        </Col>
                    </Row>
                    <Row className="d-flex justify-content-around">
                        <Col xs={12} md={5}>
                            <YearInput
                                label="Año de inicio"
                                inputName="startYear"
                                onChangeMethod={onChange}
                                onBlurMethod={handleBlur}
                                value={values.startYear}
                                errors={errors.startYear}
                                touched={touched.startYear}
                                isMin={cYear - 1}
                                isMax={2099}
                                isRequired={!!values.idPenalty}
                                isDisabled={isComplete}
                            />
                        </Col>
                        <Col xs={12} md={5}>
                            <SelectTypeHeadSingle
                                label="Periodo de Inicio"
                                inputName="startPeriod"
                                optionsArray={PERIOD}
                                defaultOption="Seleccione un periodo"
                                onChangeMethod={onChange}
                                onBlurMethod={handleBlur}
                                value={values.startPeriod}
                                touched={touched.startPeriod}
                                errors={errors.startPeriod}
                                optionValue="value"
                                optionName="label"
                                isRequired={!!values.idPenalty}
                                isDisabled={isComplete}
                            />
                        </Col>
                    </Row>
                    <Row className="d-flex justify-content-around">
                        <Col xs={12} md={5}>
                            <TextInput
                                label="Año de término"
                                inputName="endYear"
                                value={values.endYear}
                                errors={errors.endYear}
                                touched={touched.endYear}
                                isDisabled
                                isRequired={!!values.idPenalty}
                            />
                        </Col>
                        <Col xs={12} md={5}>
                            <TextInput
                                label="Periodo de término"
                                inputName="endPeriod"
                                value={values.endPeriod}
                                errors={errors.endPeriod}
                                touched={touched.endPeriod}
                                isDisabled
                                isRequired={!!values.idPenalty}
                            />
                        </Col>
                    </Row>
                    <Row className="d-flex justify-content-around">
                        <Col xs={12} md={5}>
                            <NumberInput
                                label="Monto Quincenal"
                                inputName="amountBiweekly"
                                onChangeMethod={onChange}
                                onBlurMethod={handleBlur}
                                value={values.amountBiweekly}
                                errors={errors.amountBiweekly}
                                touched={touched.amountBiweekly}
                                isMin={1}
                                prefix="$"
                                decimals
                                isDisabled
                                isRequired={!!values.idPenalty}
                            />
                        </Col>
                        <Col xs={12} md={5}>
                            <NumberInput
                                label="Pagos aplicados"
                                inputName="noPaysMade"
                                value={values.noPaysMade}
                                isMin={0}
                                isDisabled
                            />
                        </Col>
                        <Col xs={12} md={5}>
                            <NumberInput
                                label="Umas"
                                inputName="umas"
                                value={values.umas}
                                onChangeMethod={onChange}
                                isMin={0}
                                disableSep
                                isDisabled={isComplete}
                            />
                        </Col>
                    </Row>
                    <Row className="d-flex justify-content-around">
                        <Col xs={12} md={11}>
                            <TextAreaInput
                                label="Notas"
                                inputName="notes"
                                inputRows={4}
                                onChangeMethod={onChange}
                                onBlurMethod={handleBlur}
                                value={values.notes}
                                isDisabled={isComplete}
                            />
                        </Col>
                    </Row>
                    { (+values.noPaysMade > 0 || true) && <div>
                        <div className="text-center mt-4 mb-2"> 
                            <h5>Pagos aplicados</h5>
                        </div>

                        <Row className="d-flex justify-content-around">
                            <Col xs={12} lg={5}>
                            <NumberInput
                                label="Pago Aplicado"
                                inputName="total"
                                onChangeMethod={onChange}
                                onBlurMethod={handleBlur}
                                value={(+values.noPaysMade * +values.amountBiweekly)}
                                prefix="$"
                                decimals
                                readOnly
                            />
                            </Col>
                            <Col xs={12} lg={5}>
                            <NumberInput
                                label="Pago Restante"
                                inputName="total"
                                onChangeMethod={onChange}
                                onBlurMethod={handleBlur}
                                value={+values.amountTotal - (+values.noPaysMade * +values.amountBiweekly)}
                                prefix="$"
                                decimals
                                readOnly
                            />
                            </Col>
                        </Row>
                    </div>}

                    <div className=" d-flex flex-md-row flex-column-reverse justify-content-md-around">
                        <Button color="danger" type="reset" className="mt-3">
                            Cancelar
                        </Button>
                        <Button color="success" type="submit" className="mt-2 mt-md-3">
                            Guardar
                        </Button>
                    </div>
                </form>
            </Collapse>

            <ModalConfirmation
                modalTitle={values.idPenalty ? "Editar" : "Crear"}
                modal={modalConfirm}
                setModal={setModalConfirm}
                editar={saveInfo}
                isEdit
            >
                <div className="d-flex justify-content-center">
                    <h6>¿Está seguro de {values.idPenalty ? "editar" : "crear"} el registro?</h6>
                </div>
            </ModalConfirmation>

            <ModalViewFile
                modal={modalFile}
                setModal={setModalFile}
                backdrop={true}
                keyboard={true}
                btnClose={true}
                fileLink={linkFile}
            />

            <FullFormLoader show={loading} />
        </div>
    )
}

const cYear = new Date().getFullYear();

const defaultForm = {
    idPenalty: "",
    idStaff: "",
    policy: "",
    keyTypePenalty: "",
    idUnder: "",
    keyStat: "",
    noPays: "",
    amountTotal: "",
    amountBiweekly: "",
    startPeriod: "",
    startYear: new Date(cYear, 0, 1, 0, 0, 0, 0),
    endPeriod: "",
    endYear: "",
    noPaysMade: "",
    notes: "",
    comprobante: "",
    auxiliarFund: "",
    judge: "",
    expNumber: "",
    umas: ""
}

const formSchema = Yup.object().shape({
    policy: Yup.string().required("Ingrese el oficio"),
    keyTypePenalty: Yup.string().required("Seleccione una multa/sanción"),
    idUnder: Yup.string().required("Seleccione un concepto"),
    keyStat: Yup.string().required("Seleccione un estatus"),
    amountTotal: Yup.number().when("idPenalty", {
        is: (v) => !!v,
        then: Yup.number().required("Ingrese una cantidad").min(1, "El monto debe ser mayor a 0")
    }),
    noPays: Yup.number().when("idPenalty", {
        is: (v) => !!v,
        then: Yup.number().required("Ingrese el número de parcialidades")
        .min(1, "Parcialidades debe ser mayor a 0")
        .integer("Debe de ser un número entero")
    }),
    startYear: Yup.date().when("idPenalty", {
        is: (v) => !!v,
        then: Yup.date().required("Seleccione un año de inicio").nullable()
    }),
    startPeriod: Yup.string().when("idPenalty", {
        is: (v) => !!v,
        then: Yup.string().required("Seleccione un periodo de inicio")
    }),
    endYear: Yup.date().when("idPenalty", {
        is: (v) => !!v,
        then: Yup.date().required("Seleccione un año").min(Yup.ref("startYear"), "El año debe ser mayor/igual al del inicio").nullable()
    }),
    endPeriod: Yup.string().when("idPenalty", {
        is: (v) => !!v,
        then: Yup.string().required("Seleccione un periodo de término")
    }),
    amountBiweekly: Yup.number().when("idPenalty", {
        is: (v) => !!v,
        then: Yup.number().required("Ingrese el monto quincenal").min(1, "Monto debe ser mayoir a 0")
    }),
    auxiliarFund: Yup.boolean().required("Seleccione una opción"),
    judge: Yup.string().required("Ingrese un juzgado"),
    expNumber: Yup.string().required("Ingrese un número de causa"),
});

const columnDefs = [
    { className: "text-center", targets: [0, 1, 2, 3, 6 ,7, 10, 11, 12, 13] },
    { className: "text-right", targets: [4, 5, 8, 9] },
    { orderable: false, targets: [11, 12, 13] }
];

const opt = [
    { name: "No", val: "0" },
    { name: "Si", val: "1" },
  ];


  
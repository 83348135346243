import { useEffect, useState } from "react";
import Widget from "../../../components/Widget/Widget";
import { GetPermissions } from "../../../libs/permissions/getPermissions";
import { ButtonInput } from "../../../components/GenericInputsFormik/ButtonInput";
import { Button, Card, CardBody, Col, Row } from "reactstrap";
import { SelectTypeHeadSingle } from "../../../components/GenericInputsFormik";
import { doRequest } from "../../../helpers/requests";
import FullFormLoader from "../../../components/Loader/FullFormLoader";
import Cards from "../../../components/CardsComponent/Cards";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

export default function ExistenciasAlmacen() {
    const [permissions, setPermissions] = useState({ LOD: true });
    GetPermissions(setPermissions);

    const [groups, setGroups] = useState([]);
    const [subgroups, setSubgroups] = useState([]);
    const [classes, setClasses] = useState([]);
    const [materials, setMaterials] = useState([]);
    const [params, setParams] = useState({
        action: "datatable",
        table: "warehouseinventory A INNER JOIN furnitures B USING(idFurniture) LEFT JOIN bookcase C ON C.reference = B.idFurniture LEFT JOIN files D USING(idBook) INNER JOIN furniture_classes E USING(keyClass) INNER JOIN furniture_subgroups F USING(keySubGroup) INNER JOIN messureunits G USING(keyUnit)",
        rows: "A.idInventory,A.idFurniture,CONCAT_WS(' ', B.brand, B.model, B.nameFurniture) AS fullName,A.totalCount,A.reserved,D.src,A.hall,A.shell,A.position,G.nameUnit",
        conditions: "",
        page: 0,
        records: 5,
        search: "",
        order: "",
    });
    const [filters, setFilters] = useState({
        keyGroup: "",
        keySubGroup: "",
        keyClass: "",
        keyMaterial: "",
    });
    const [refresh, setRefresh] = useState(true);

    const { profileType } = useSelector((state) => state.userInfo);
    const idArea = profileType.selectProfile.idArea;
    const history = useHistory();

    useEffect(() => {
        getData("furniture_groups", "nameGroup");
        getData("furniture_subgroups", "nameSubGroup");
        getData("furniture_classes", "nameClass");
        getData("materials", "nameMaterial");
    }, []);
    
    async function getData(table, name) {
        const params = {
            action: "select",
            table: table,
            condition: { enabled: 1 },
            order: name + " ASC",
        };
        const res = await doRequest(
            "receiver/receiver.php",
            params,
            false,
            true
        );
        if (res.length > 0) {
            switch (table) {
                case "furniture_groups":
                    setGroups(res);
                    break;
                case "furniture_subgroups":
                    setSubgroups(res);
                    break;
                case "furniture_classes":
                    setClasses(res);
                    break;
                case "materials":
                    setMaterials(res);
                    break;
            }
        }
    }

    useEffect(() => {
        const nParams = { ...params };
        nParams.conditions = getConditions();
        setParams(nParams);
        setRefresh(true);
    }, [filters]);

    function getConditions() {
        let cond = `idArea = ${idArea} AND A.enabled = 1 AND D.enabled = 1 AND C.keyFileType = 24`;//
        if (filters.keyGroup) cond += " AND F.keyGroup = " + filters.keyGroup;
        if (filters.keySubGroup)
            cond += " AND F.keySubGroup = " + filters.keySubGroup;
        if (filters.keyClass) cond += " AND b.keyClass = " + filters.keyClass;
        if (filters.keyMaterial)
            cond += " AND B.keyMaterial = " + filters.keyMaterial;
        return cond;
    }

    const onChange = ({ target }) => {
        const { name, value } = target;
        setFilters((ps) => ({
            ...ps,
            keySubGroup: ["keyGroup"].includes(name) ? "" : ps.keySubGroup,
            keyClass: ["keyGroup", "keySubGroup"].includes(name)
                ? ""
                : ps.keyClass,
            [name]: value,
        }));
    };

    function filterData(value, key) {
        let data = [];
        if (value === "keySubGroup") data = [...subgroups];
        else data = [...classes];

        return filters[key]
            ? data.filter((el) => +el[key] === +filters[key])
            : data;
    }

    function complete() {
        return (
            subgroups.length > 0 &&
            classes.length > 0 &&
            materials.length > 0 &&
            filters.length > 0
        );
    }

    
    const CardRender = (row) => {
        function toEdit() {
            localStorage.setItem("idInventory", JSON.stringify(row.idInventory));
            history.push({ pathname: "/template/existencias-almacen/detalle" });
        }
        return (
            <Card className="p-2" key={`card${row.idInventory}`}>
                <Row
                    className="no-gutters align-items-center"
                    style={{ height: "100%" }}
                >
                    <Col md={4} className="text-center">
                        <img src={row.src || "/images/no-image.png"} alt={row.fullName} className="img-thumbnail" loading="lazy" width={300} height={300} />
                    </Col>
                    <Col md={8} className=" h-100">
                        <CardBody className="p-2">
                            <h5 className="mb-1 text-dark">
                                {row.fullName}
                            </h5>
                            <div className="text-dark">
                                <p>Existencias: {row.totalCount}</p>
                                <p>Comprometido: {row.reserved}</p>
                            </div>
                            <div className="mt-4 text-center">
                                <Button color="secondary" onClick={()=>toEdit()}>
                                    <i className="fa fa-eye" style={{fontSize: "16px", paddingRight: "5px"}}/>
                                    Detalle
                                </Button>
                            </div>
                        </CardBody>
                    </Col>
                </Row>
            </Card>
        );
    };

    return (
        <Widget className="widget-p-md">
            <div className="mt-2">
                <h3>Existencias Almacén</h3>
            </div>
            {!permissions.LOD && (
                <>
                    <Row className="mb-3">
                        <Col xs={12} md={6}>
                            <SelectTypeHeadSingle
                                label="Grupo"
                                inputName="keyGroup"
                                onChangeMethod={onChange}
                                value={filters.keyGroup}
                                optionsArray={groups}
                                optionValue="keyGroup"
                                optionName="nameGroup"
                            />
                        </Col>
                        <Col xs={12} md={6}>
                            <SelectTypeHeadSingle
                                label="Subgrupo"
                                inputName="keySubGroup"
                                onChangeMethod={onChange}
                                value={filters.keySubGroup}
                                optionsArray={filterData(
                                    "keySubGroup",
                                    "keyGroup"
                                )}
                                optionValue="keySubGroup"
                                optionName="nameSubGroup"
                            />
                        </Col>
                        <Col xs={12} md={6}>
                            <SelectTypeHeadSingle
                                label="Clase"
                                inputName="keyClass"
                                onChangeMethod={onChange}
                                value={filters.keyClass}
                                optionsArray={filterData(
                                    "keyClass",
                                    "keySubGroup"
                                )}
                                optionValue="keyClass"
                                optionName="nameClass"
                            />
                        </Col>
                        <Col xs={12} md={6}>
                            <SelectTypeHeadSingle
                                label="Material"
                                inputName="keyMaterial"
                                onChangeMethod={onChange}
                                value={filters.keyMaterial}
                                optionsArray={materials}
                                optionValue="keyMaterial"
                                optionName="nameMaterial"
                            />
                        </Col>
                    </Row>
                    <Cards
                        params={params}
                        endpoint="receiver/receiver.php"
                        card={CardRender}
                        refresh={refresh}
                        setRefresh={setRefresh}
                    />
                </>
            )}
            <FullFormLoader show={complete()} />
        </Widget>
    );
}


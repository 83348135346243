// -- MODULOS -- //
import Modulos from "../../../pages/admin-usuarios/modulos/Modulos";
import CrearModulo from "../../../pages/admin-usuarios/modulos/crear/CrearModulo";
import EditarModulo from "../../../pages/admin-usuarios/modulos/editar/EditarModulo";

// -- PERFILES -- //
import Perfiles from "../../../pages/admin-usuarios/perfil/Perfiles";
import CrearPerfil from "../../../pages/admin-usuarios/perfil/alta/CrearPerfil";
import EditarPerfil from "../../../pages/admin-usuarios/perfil/modificacion/EditarPerfil";

// -- PERMISOS -- //
import Permisos from "../../../pages/admin-usuarios/permisos/Permisos"
import AsignaPermisos from "../../../pages/admin-usuarios/permisos/AsignaPermisos"

// -- SISTEMAS -- //
import Sistemas from "../../../pages/admin-usuarios/sistema/sistemas/Sistemas";
import CrearSistema from "../../../pages/admin-usuarios/sistema/crear/CrearSistema";
import EditarSistema from "../../../pages/admin-usuarios/sistema/editar/EditarSistema";

// -- MODULOPerfil -- //
import SeleccionaPerfil from "../../../pages/admin-usuarios/moduloPerfil/SeleccionaPerfil";

// -- USUARIOS -- //
import Usuarios from "../../../pages/admin-usuarios/usuario/Usuarios";
import CrearUsuario from "../../../pages/admin-usuarios/usuario/crear/CrearUsuario";
import EditarUsuario from "../../../pages/admin-usuarios/usuario/editar/EditarUsuario";

const rutasAdminUsuarios = [
    { path:"/template/sistemas", component: Sistemas },
    { path:"/template/sistemas/crear", component: CrearSistema },
    { path:"/template/sistemas/editar", component: EditarSistema },
    { path:"/template/perfiles", component: Perfiles },
    { path:"/template/perfiles/crear", component: CrearPerfil },
    { path:"/template/perfiles/editar", component: EditarPerfil },
    { path:"/template/modulos", component: Modulos },
    { path:"/template/modulos/crear", component: CrearModulo },
    { path: "/template/modulos/editar", component: EditarModulo },
    //Rutas de usuario
    { path:"/template/usuarios", component: Usuarios },
    { path:"/template/usuarios/crear", component: CrearUsuario },
    { path: "/template/usuarios/editar", component: EditarUsuario },
    //Ruta perfil-modulo
    { path: "/template/perfiles-modulos", component: SeleccionaPerfil },
    //Permisos
    { path:"/template/usuarios-permisos", component: Permisos },
    { path:"/template/usuarios-permisos/permisos", component: AsignaPermisos }
]

export default rutasAdminUsuarios
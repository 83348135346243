'use strict';
export const peticionEncript = (data) => {
  // console.log(JSON.stringify(data));
  const crypto = require('crypto');
  const ENC_KEY = process.env.REACT_APP_ENCRYPTION_KEY;
  const IV = process.env.REACT_APP_ENCRYPTION_IV; 
  var encrypt = ((val) => {
    let cipher = crypto.createCipheriv('aes-256-cbc', ENC_KEY, IV);
    let encrypted = cipher.update(val, 'utf8', 'base64');
    encrypted += cipher.final('base64');
    return encrypted;
  });

  data = encrypt(JSON.stringify(data));
  return data;
};

export const peticionDecrypt = (data) => {
  // console.log(JSON.stringify(data));
  const crypto = require('crypto');
  const ENC_KEY = process.env.REACT_APP_ENCRYPTION_KEY;
  const IV = process.env.REACT_APP_ENCRYPTION_IV; 
  var decrypt = (encrypted) => {
    let decipher = crypto.createDecipheriv('aes-256-cbc', ENC_KEY, IV);
    let decrypted = decipher.update(encrypted, 'base64', 'utf8');
    decrypted += decipher.final('utf8');
    return decrypted;
  };
  data = JSON.parse(decrypt(data));
  
  return data;
};

import React from "react";
import Widget from "../../../../components/Widget/Widget";
import FormImpuestos from "../FormImpuestos";
import { createImpuesto } from "../functions";

export const CrearImpuesto = (props) => {

  return (
    <Widget className="widget-p-md">
        <h3>Crear tipo de impuesto</h3>
        <FormImpuestos crear={createImpuesto}/>
    </Widget>
  )
}

export default CrearImpuesto;
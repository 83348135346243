import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Button } from "reactstrap";
import { Formik, Form, Field, ErrorMessage, useFormikContext } from "formik";
import * as Yup from "yup";
import ModalConfirmation from "../../../components/Modal/ModalConfirmation";

const defaultForm = {
  keyBank: "",
  nameBank: "",
  codeBank: "",
};

const FormBancos = ({ crear, data, editar }) => {
  const history = useHistory();
  const [modal, setModal] = useState(false);

  const [form] = useState( defaultForm);
  const number = /\d{4}/;


  const FormSchema = Yup.object().shape({
    nameBank: Yup.string()

      .min(3, "Nombre debe contener al menos 3 letras")
      .required("Ingrese el nombre del banco"),
      /* codeBank: Yup.number("Ingrese un numero").integer("Ingrese un numero entero")
      .min(1000, "el codigo contener menos de 4 digitos")
      .max(9999, "el codigo contener menos de 4 digitos")
      .required("Ingrese el codigo de banco"), */
      codeBank: Yup.string("Ingrese un numero").matches(number,"el codigo debe contener 4 digitos")
/*       .min(1000, "el codigo contener menos de 4 digitos") */
      .min(4, "el codigo debe contener 4 digitos")
      .max(4, "el codigo debe contener 4 digitos")
      .required("Ingrese el codigo de banco"),
  });

  const submitFunction = (fields) => {
    setModal(true);
  };

  const cancelOp = () => {
    history.push("/template/bancos");
  };

  return (
    <div>
        <Formik
        initialValues={form}
        validationSchema={FormSchema}
        onSubmit={(fields) => {
          submitFunction(fields);
        }}
        onReset={(fields, { resetForm }) => {
          cancelOp();
        }}
      >
        {({ errors, status, touched, values }) => (
              <Form className="mt-4">
                <ModalConfirmation
                  modalTitle={data? "Editar" : "Crear"}
                  modal={modal}
                  setModal={setModal}
                  crear={crear}
                  editar={editar}
                  isEdit={data ? true : false}
                  values={values}
                >
                  {
                    data? 
                    (<div className="d-flex justify-content-center"><h6>¿Está seguro de editar el registro?</h6></div>) : 
                    (<div className="d-flex justify-content-center"><h6>¿Desea continuar con el registro?</h6></div>)
                  }
                </ModalConfirmation>
            <div className="form-group">
              <label htmlFor="nameBank">Nombre del banco<span className="text-danger">*</span></label>
              <Field
                name="nameBank"
                type="text"
                placaceholder="Nombre del banco"
                className={
                  "form-control" +
                  (errors.nameBank && touched.nameBank
                    ? " is-invalid"
                    : "")
                }
              />
              <ErrorMessage
                name="nameBank"
                component="div"
                className="invalid-feedback"
              />
            </div>
            <div className="form-group">
              <label htmlFor="codeBank">Clave del banco<span className="text-danger">*</span></label>
              <Field
                name="codeBank"
                type="text"
                placaceholder="Clave del banco"
                className={
                  "form-control" +
                  (errors.codeBank && touched.codeBank
                    ? " is-invalid"
                    : "")
                }
              />
              <ErrorMessage
                name="codeBank"
                component="div"
                className="invalid-feedback"
              />
            </div>
            <div className="d-flex justify-content-between mt-5">
              <Button color="danger" type="reset">
                Cancelar
              </Button>
              <Button color="success" type="submit">
                Guardar
              </Button>
            </div>
            <FormikData info={data}/>
          </Form>
        )}
      </Formik>
      
    </div>
  );
};

const FormikData = ({info}) => {
  const { setFieldValue } = useFormikContext();
  useEffect(() => {
      console.log(info);
      if(info){
          setFieldValue("keyBank",info[0]);
          setFieldValue("nameBank",info[1]);
          setFieldValue("codeBank",info[2]);
      }
  }, [info]);
  return null;
};
export default FormBancos;

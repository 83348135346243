import React from "react";

import Widget from "../../../../components/Widget/Widget";
import FormRegiones from "../FormRegiones";
import { createRegion } from "../functions";

export const CrearRegion = (props) => {
  return (
    <Widget className="widget-p-md">
        <h3>Crear región</h3>
        <FormRegiones crear={createRegion}/>
    </Widget>
  )
}

export default CrearRegion;
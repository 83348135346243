import { peticionesReceiver } from "../../../helpers/peticionesReceiver"; 
import Notification from "../../../components/Notification/Notification";
import { toast } from "react-toastify";
const API = peticionesReceiver();

export const editar = async (discapacidad) => {
    const data = {
      action: "update",
      table: "disabilities",
      rows: discapacidad,
      validate: [
        ["nameDisability"],
      ],
      condition: {
        keyDisability: discapacidad.keyDisability,
      },
    };
    API.peticion(data)
      .then((res) => {
        console.log("Recibiendo respuesta desde la vista", res);
        if (res.status === 200 && res.data.code === "200") {
          toast(
            <Notification
              type={"agrega_exito"}
              backMessage={res.data.message}
              withIcon
            />
          );
          window.location.href = '#/template/discapacidades';
        } else {
          toast(
            <Notification
              type={"agrega_error"}
              backMessage={res.data.message}
              withIcon
            />
          );
        }
      })
      .catch((err) =>
        toast(<Notification type={"consultar_servidor_error"} withIcon />)
      );
  };
  export const agregar = (discapacidad) => {
    const data = {
      action: "insert",
      table: "disabilities",
      rows: discapacidad,
      validate: [
        ["nameDisability"],
    ]
    };
    API.peticion(data)
      .then((res) => {
        console.log("Recibiendo respuesta desde la vista", res);
        if (res.status === 200 && res.data.code === "200") {
          toast(
            <Notification
              type={"agrega_exito"}
              backMessage={res.data.message}
              withIcon
            />
          );
          window.location.href = "#/template/discapacidades" 
        } else {
          toast(
            <Notification
              type={"agrega_error"}
              backMessage={res.data.message}
              withIcon
            />
          );
        }
      })
      .catch((err) =>
        toast(<Notification type={"consultar_servidor_error"} withIcon />)
      );
  };
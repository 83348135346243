import React, { useState } from "react";
import TableComponent from "../../../components/TableComponent/TableComponentFiltro";

export const Licencias = () => {
  const cabeceras = [
    "Id", //0
    "Nombre", //1
    "Editar",
    "Eliminar",
  ];
  const [parametros] = useState({
    action: "datatable",
    table: "types_leaves",
    rows: "keyTypeLeave,nameTypeLeave", ///sin espacios y comas
    conditions: "enabled = 1",
    page: 0,
    records: 5,
    search: "",
    order: "",
  });

  return (
    <div>
      <TableComponent
        titulo="Tipos de Licencias"
        cabecerasTabla={cabeceras}
        filtro={null}
        rutaCrear="/template/licencias-nomina/crear"
        rutaEditar="/template/licencias-nomina/editar"
        parametros={parametros}
      ></TableComponent>
    </div>
  );
};
export default Licencias;

import * as Yup from "yup";
import { useFormik } from "formik";
import { Button, ModalBody, ModalFooter } from "reactstrap";
import { FilesInput, TextInput } from "../../../components/GenericInputsFormik";
import { toast } from "react-toastify";
import Notification from "../../../components/Notification/Notification";
import { doRequest } from "../../../helpers/requests";
import { useEffect } from "react";

const defaultform = {
    key: "",
    crt: "",
    pass: "",
};
const FormSchema = Yup.object().shape({
    key: Yup.mixed()
        .required("Cargue la llave privada")
        .test("file-type", "El certificado sele", (value) => {
            return value && value.name.search(".key") !== -1;
        })
        .test({
            message: `El documento debe pesar menos de 4MB`,
            test: (value) => value?.size < 4000000,
        }),
    crt: Yup.mixed()
        .required("Agregue el certificado")
        .test("file-type", "El certificado seleccionado es invalido", (value) => {
            return value && value.name.search(".crt") !== -1;
        })
        .test({
            message: `El documento debe pesar menos de 4MB`,
            test: (value) => value?.size < 4000000,
        }),
    pass: Yup.string().required("Ingrese la contraseña de clave privada"),
});

export default function FormCertificados({
    modal,
    setModal,
    selected,
    setselected,
    all,
    setAll,
    paramsAll,
    getConditions,
    setRefresh,
    setLoading,
    setFilesSigneds,
    setModalSigned
}) {

    const {
        handleSubmit,
        handleReset,
        handleBlur,
        values,
        errors,
        touched,
        setFieldValue,
    } = useFormik({
        initialValues: defaultform,
        onSubmit: () => {
            signFiles();
        },
        validationSchema: FormSchema,
        enableReinitialize: true,
    });

    useEffect(() => {
        if (!modal) handleReset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [modal]);

    async function signFiles() {
        setLoading(true);
        let rutes = [];
        if (all) {
            const params = {
                ...paramsAll,
                conditions: getConditions(paramsAll.conditions),
                records: 9999,
            };
            rutes = await doRequest("receiver/receiver.php", params, false, false);
            if (rutes.length < 1) {
                toast(
                    <Notification
                        type={"consultar_error"}
                        backMessage="No fue posible obtener todos los documentos para firmar"
                        withIcon
                    />,
                    { closeButton: false }
                );
                return;
            }
        } else if (selected.length > 0) rutes = [...selected];

        const formData = new FormData();
        formData.append("action", "requestSign");
        formData.append("cer", values.crt);
        formData.append("key", values.key);
        formData.append("pass", values.pass);
        formData.append("files", JSON.stringify(rutes));

        const res = await doRequest(
            "app/facades/sign/signF.php",
            formData,
            false,
            true,
            false
        );
        setLoading(false);
        console.log(res);
        let filesOk = 0;
        let filesError = 0;
        let listaerrors = [];
        if (res.length > 0) {
            res.forEach((r) => {
                if (r.code !== 200) {
                    filesError++;
                    listaerrors.push(r);
                } else filesOk++;
            });
            toggle();
            setselected([]);
            setAll(false);
            setRefresh(true);
            setFilesSigneds({
                ok: filesOk,
                error: filesError,
                list: listaerrors,
            });
            setModalSigned(true);
        }

    }

    const toggle = () => setModal(!modal);

    const onChangeFile = ({ target }) => {
        const { name, files } = target;
        if (files.length > 0) setFieldValue(name, files[0]);
        else setFieldValue(name, "");
    };

    const onChange = ({ target }) => {
        setFieldValue(target.name, target.value);
    };

    return (
        <form onSubmit={handleSubmit} onReset={handleReset} className="border-top">
            <ModalBody>
                <FilesInput
                    label="Certificado (.crt)"
                    inputName="crt"
                    onChangeMethod={onChangeFile}
                    onBlurMethod={handleBlur}
                    fileAccept=".crt"
                    isRequired
                    touched={touched.crt}
                    errors={errors.crt}
                    basicReq={false}
                />
                <FilesInput
                    label="Clave privada (.key)"
                    inputName="key"
                    onChangeMethod={onChangeFile}
                    onBlurMethod={handleBlur}
                    fileAccept=".key"
                    isRequired
                    touched={touched.key}
                    errors={errors.key}
                    basicReq={false}
                />
                <TextInput
                    label="Contraseña de clave privada"
                    inputType="password"
                    inputName="pass"
                    onChangeMethod={onChange}
                    onBlurMethod={handleBlur}
                    value={values.pass}
                    touched={touched.pass}
                    errors={errors.pass}
                    isRequired
                />
            </ModalBody>
            <ModalFooter>
                <div className="w-100 d-flex justify-content-md-end flex-md-row flex-column-reverse ">
                    <Button
                        color="primary"
                        onClick={toggle}
                        className="my-2 my-md-0 mx-0 mr-md-3"
                        type="reset"
                    >
                        Cancelar
                    </Button>{" "}
                    <Button color="secondary" className="my-2 my-md-0" type="submit">
                        Firmar documentos
                    </Button>
                </div>
            </ModalFooter>
        </form>
    )
}
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, Spinner } from "reactstrap";
import Datatable from "../../../components/Datatable/Datatable";
import Widget from "../../../components/Widget/Widget";
import { toast } from "react-toastify";
import Notification from "../../../components/Notification/Notification";
import { peticionesReceiver } from "../../../helpers/peticionesReceiver";
import { useState } from "react";
import InputWithModal from "../../../components/GenericInputsFormik/InputWithModal";
import * as Yup from "yup"
import { useFormik } from "formik";
import ModalConfirmation from "../../../components/Modal/ModalConfirmation";
import { GetPermissions } from "../../../libs/permissions/getPermissions";

export default function RecepcionCompras() {
    const API = peticionesReceiver()
    const [refresh, setRefresh] = useState(false);
    const [modalSelect, setModalSelect] = useState(false);
    const [modalConfirmation, setModalConfirmation] = useState(false);
    const [idPurchaseRequest, setIdPurchaseRequest] = useState("");
    const [update, setUpdate] = useState(false);
    const [permisos, setPermisos] = useState("")
    const [loading, setLoading] = useState(true);

    GetPermissions(setPermisos, setLoading);

    const headers = ["Solicitud", "Fecha solicitud", "Adscripcion solicitante", "Tipo de compra", "Sintesis", "Suficiencia presupuestal", "Estatus", "Asignada"]

    const defaultForm = {
        idStaffAtend: "",
        nameStaff: "",
    }

    const filtro = [true, true, true];
    const cabeceras = [
        "#",
        "No. Empleado",
        "Nombre"
    ];

    const params = {
        action: "datatable",
        table: "quoteagents A INNER JOIN staff B on (A.idStaff = B.idStaff)",
        rows: "B.idStaff,B.staffNumber, concat(B.name,' ',B.pName,' ',B.mName) as fullName",
        conditions: "A.enabled = 1 AND A.contracts = 1",
        page: 0,
        records: 5,
        search: "",
        order: "",
    };

    const FormSchema = Yup.object().shape({
        nameStaff: Yup.string().required("Seleccione un empleado valido")
    })

    const { handleSubmit, values, handleBlur, errors, touched, setFieldValue, resetForm } = useFormik({
        initialValues: defaultForm,
        onSubmit: (values) => {
            setModalConfirmation(true)
        },
        validationSchema: FormSchema,
        enableReinitialize: true,
    });

    const actionSelect = (el) => {
        setFieldValue(["idStaffAtend"], el[0]);
        setFieldValue(["nameStaff"], `${el[1]} - ${el[2]}`);
    }

    const clear = (el) => {
        resetForm()
    }

    const columns = [
        {
            data: null,
            render: function render(row) {
                return `${row.numberPurchaseRequest}/${row.yearPurchaseRequest}`
            },
            orderValue: "CONCAT(numberPurchaseRequest,'/',yearPurchaseRequest)"
        },
        { data: "dateSend" },
        { data: "name" },
        { data: "nameTypePurchase" },
        { data: "object" },
        {
            data: null,
            render: function render(row) {
                return `${row.numberRequest}/${row.yearRequest}`
            },
            orderValue: "CONCAT(br.numberRequest,'/',br.yearRequest)"
        },
        { data: "nameStat" },
        {
            data: null,
            render: function render(row) {
                if (row.idStaffAtend) {
                    if (permisos.UPD) {
                        return (
                            <div className="d-flex flex-column">
                                <p>{row.nameA} {row.pNameA} {row.mNameA}</p>
                                <Button size="sm" onClick={() => showModalSelect(row.idPurchaseRequest, true)}>Reasignar</Button>
                            </div>
                        )
                    }
                } else {
                    if (permisos.INS) {
                        return <Button size="sm" onClick={() => showModalSelect(row.idPurchaseRequest, false)}>Asignar</Button>
                    }
                }
                return <></>
            },
            orderValue: "CONCAT(nameA,' ',pNameA,' ',mNameA)"
        }
    ]

    function showModalSelect(id, update) {
        setIdPurchaseRequest(id);
        setUpdate(update)
        setModalSelect(true)
    }

    const getParams = (order, records, page, search) => {
        const params = {
            action: "datatable",
            table: "purchaserequests pr inner join status s on (pr.keyStat=s.keyStat) inner join areas a on (a.idArea=pr.idArea) inner join types_purchases tp on (tp.keyTypePurchase=pr.keyTypePurchase) inner join quotes q on (pr.idQuote=q.idQuote) inner join budgetrequests br on (pr.idBudgetRequest=br.idBudgetRequest) left join staff st on (st.idStaff=pr.idStaffAtend)",
            rows: "pr.idPurchaseRequest,pr.numberRequest as numberPurchaseRequest, pr.yearRequest as yearPurchaseRequest,pr.dateSend,a.name,tp.nameTypePurchase,q.object,br.numberRequest,br.yearRequest,s.nameStat,pr.idStaffAtend,st.name as nameA,st.pName as pNameA,st.mName as mNameA",
            conditions: `pr.enabled = 1`,
            order,
            records: records.toString(),
            page: (page * records).toString(),
            search
        }
        return params
    }

    async function getData(order, records, page, search) {
        try {
            const res = await API.peticion(getParams(order, records, page, search));
            if (res.status === 200 && res.data.code === "200") {
                return res.data;
            } else {
                toast(<Notification type={"consultar_error"} backMessage={res.data.message} />);
                return null;
            }
        } catch (err) {
            toast(<Notification type={"consultar_servidor_error"} withIcon />);
            return null
        }
    }

    const toggle = () => setModalSelect(!modalSelect)

    async function asignarEmpleado() {
        try {
            const res = await API.peticion({
                action: "update",
                table: "purchaserequests",
                rows: {
                    idStaffAtend: values.idStaffAtend,
                    assigned: 1,
                    keyStat: 126
                },
                condition: { idPurchaseRequest }
            })
            if (res.status === 200 && res.data.code === "200") {
                toast(<Notification type={"modifica_exito"} backMessage={res.data.message} withIcon />);
                setRefresh(true)
                resetForm()
                toggle()
            } else {
                toast(<Notification type={"modifica_error"} backMessage={res.data.message} withIcon />);
            }
        } catch (err) {
            console.log(err)
            toast(<Notification type={"consultar_servidor_error"} withIcon />);
        }
    }

    async function reasignarEmpleado() {
        try {
            const res = await API.peticion({
                action: "update",
                table: "purchaserequests",
                rows: {
                    idStaffAtend: values.idStaffAtend,
                    assigned: 1,
                    keyStat: 126
                },
                condition: { idPurchaseRequest }
            })
            if (res.status === 200 && res.data.code === "200") {
                toast(<Notification type={"modifica_exito"} backMessage={res.data.message} withIcon />);
                setRefresh(true)
                resetForm()
                toggle()
            } else {
                toast(<Notification type={"modifica_error"} backMessage={res.data.message} withIcon />);
            }
        } catch (err) {
            console.log(err)
            toast(<Notification type={"consultar_servidor_error"} withIcon />);
        }
    }

    if (loading) {
        return (
            <div className="d-flex align-items-center justify-content-center" style={{ height: "200px" }}>
                <Spinner
                    color="primary"
                    style={{
                        height: '3rem',
                        width: '3rem'
                    }}
                    type="grow"
                >
                    Loading...
                </Spinner>
            </div>
        )
    }
    return (
        <>
            <Widget className="widget-p-md">
                <h3 className="text-secondary">Recepcion de compras</h3>
                {permisos.CON ? (
                    <Datatable
                        headers={headers}
                        columns={columns}
                        petition={getData}
                        stateRefresh={[refresh, setRefresh]}
                        control="back"
                        order={{col: 0, opt: "desc"}}
                    />
                ) : (<div className="text-center p-4">No tienes permitido ver los registros de esta seccion</div>)}
            </Widget>
            <Modal isOpen={modalSelect} toggle={toggle} size="lg" centered>
                <ModalHeader toggle={toggle} className="headline-1">
                    {update ? "Reasignar" : "Asignar"}
                </ModalHeader>
                <form onSubmit={handleSubmit}>
                    <ModalBody className="pb-2" >
                        <InputWithModal
                            cabeceras={cabeceras}
                            error={errors.nameStaff}
                            filtro={filtro}
                            handleBlur={handleBlur}
                            actionSelect={actionSelect}
                            onClear={clear}
                            parametros={params}
                            touched={touched.nameStaff}
                            value={values.nameStaff}
                            label="Seleccione el empleado que atendera la solicitud"
                            name={"nameStaff"}
                            title={"Seleccionar empleado"}
                        />
                    </ModalBody>
                    <ModalFooter className="pb-3">
                        <Button color="danger" className="mx-2" onClick={toggle}>Cancelar</Button>
                        <Button className="mx-2" type="submit">Asignar</Button>
                    </ModalFooter>
                </form>
            </Modal>
            <ModalConfirmation
                modalTitle={"Registrar cotización"}
                modal={modalConfirmation}
                crear={asignarEmpleado}
                editar={reasignarEmpleado}
                isEdit={!update}
                setModal={setModalConfirmation}
                values={values}
            >
                <div className="d-flex justify-content-center">
                    <h6>¿Desea continuar con la asignacion del empleado?</h6>
                </div>
            </ModalConfirmation>
        </>
    )
}
import * as Yup from "yup";
import { useFormik } from "formik";
import { Button, Col, Row } from "reactstrap";
import ModalConfirmation from "../../../components/Modal/ModalConfirmation";
import { TextInput } from "../../../components/GenericInputsFormik";
import ButtonInputM from "../../../components/GenericInputsFormik/ButtonInputModal";
import { useState } from "react";
import { doRequest } from "../../../helpers/requests";
import { useEffect } from "react";
import QRCode from "qrcode.react";
import { getFump } from "../../../libs/reports/fump/createFump";
import FullScreenLoader from "../../../components/Loader/FullScreenLoader";
import { toast } from "react-toastify";
import Notification from "../../../components/Notification/Notification";

const defaultForm = {
    employeeA: "",
    employeeB: "",
    nameStaffA: "",
    nameStaffB: "",
    dateStart: ""
}

const formSchema = Yup.object().shape({
    nameStaffA: Yup.string().required("Seleccione el primer empleado"),
    nameStaffB: Yup.string().required("Seleccione el segundo empleado"),
    dateStart: Yup.string().required("Seleccione una fecha de incio"),
});


export default function Form({selected, setOpen}){
    const [loading, setLoading] = useState(false);
    const [msgLoading, setMsgLoading] = useState("");
    const [modal, setModal] = useState(false);
    const [modalOk, setModalOk] = useState(false);
    const [salaryA, setSalaryA] = useState(-1);

    const {
        handleSubmit,
        handleReset,
        handleBlur,
        setFieldValue,
        setValues,
        values,
        errors,
        touched,
    } = useFormik({
        initialValues: defaultForm,
        validationSchema: formSchema,
        onSubmit: (values) => setModal(true),
        onReset: () => setOpen(false),
    });

    function onChange({ target }){
        setFieldValue(target.name, target.value); 
    }

    useEffect(()=>{
        if(selected){
            setValues({
                employeeA: "",
                employeeB: "",
                nameStaffA: selected.nameA,
                nameStaffB: selected.nameB,
                dateStart: selected.authorizationDate,
            }, true);
        }
    }, []);


    /* InputModal */
    const modalTablaStaffParams = {
        title: "Empleado",
        headers: ["No. Empleado", "Nombre", "Puesto"],
        columns: [
            {data: "staffNumber"},
            {data: "fullName"},
            {data: "nameJobType"}
        ],
        columnDefs: [{targets: [0, 2], className: "text-center"}],
        params: {
            table: "staff A INNER JOIN employtypes B ON A.keyEmployType = B.keyEmployType INNER JOIN jobstaff C ON C.idStaff = A.idStaff INNER JOIN jobtypes D ON D.keyJobType = A.keyEmployType",
            rows: "A.idStaff, A.staffNumber, CONCAT(A.name,' ',A.pName,' ',A.mName) as fullName, D.nameJobType, C.idSalary",
            conditions: `A.enabled = 1 AND A.keyStat = 1 AND C.valid = 1 AND C.enabled = 1`
        },
    }

    const params2 = {
        ...modalTablaStaffParams,
        params: {
            table: "staff A INNER JOIN employtypes B ON A.keyEmployType = B.keyEmployType INNER JOIN jobstaff C ON C.idStaff = A.idStaff INNER JOIN jobtypes D ON D.keyJobType = A.keyEmployType",
            rows: "A.idStaff, A.staffNumber, CONCAT(A.name,' ',A.pName,' ',A.mName) as fullName, D.nameJobType",
            conditions: `A.enabled = 1 AND A.keyStat = 1 AND C.valid = 1 AND C.enabled = 1 AND A.idStaff != ${values.employeeA} AND C.idSalary = ${salaryA}`
        },
    }
    
    function selectRow(row, fields){
        setFieldValue(fields[0], row.fullName);
        setFieldValue(fields[1], row.idStaff);
        if(fields[0] === "nameStaffA" && values.employeeB){
            if(row.idStaff === values.employeeB) clearInput("employeeB", ["", "nameStaffB"]);
        }
        setSalaryA(row.idSalary);
    }

    function clearInput(field, fields){
        setFieldValue(field, "");
        setFieldValue(fields[1], "");
    }

    async function getStaffSalaries(){
        const params = {
            action: "searchStaffbySalaries",
            rows: { 
                idStaffO: values.employeeA,
                idStaffT: values.employeeB
            }
        }
        setMsgLoading("Realizando movimiento de empleados...");
        setLoading(true);
        const res = await doRequest("app/facades/jobs/jobsF.php", params, false);
        if(typeof res === "object") await save();
        setLoading(false);
    }


    async function save(){
        const params = {
            action: "savePermuta",
            rows: {
                idStaffO: values.employeeA,
                idStaffT: values.employeeB,
                authorizationDate: values.dateStart
            }
        }
        const res = await doRequest("app/facades/jobs/jobsF.php", params, false);
        if(res.status === "success"){
            setMsgLoading("Generando reportes...");
            const fumps = await Promise.all([
                getFump(res.data[0].idJobStaffONew),
                getFump(res.data[0].idJobStaffTNew)
            ]);
            let msg = "";
            if(fumps[0] && fumps[1]) msg = "Reportes Fump generados correctamente";
            else msg = "No fue posible generar todos los reportes Fump";
            toast(
                <Notification type={fumps[0] && fumps[1] ? "success": "warning"} backMessage={msg} withIcon />,
                { closeButton: false, style:{zIndex: 9999} }
            );
            setModalOk(true);
        }
    }

    return(
        <form onSubmit={handleSubmit} onReset={handleReset}>
            <Row>
                <Col xs={12} md={6}>
                    <ButtonInputM
                        label="Primer Empleado"
                        inputName="nameStaffA"
                        value={values.nameStaffA}
                        touched={touched.nameStaffA}
                        errors={errors.nameStaffA}
                        isRequired={true}
                        modalParams={modalTablaStaffParams}
                        selectRow={selectRow}
                        clearFun={clearInput}
                        fields={["nameStaffA", "employeeA"]}
                        isDisabled={selected}
                    />
                </Col>
                <Col xs={12} md={6}>
                    <ButtonInputM
                        label="Segundo Empleado"
                        inputName="nameStaffB"
                        value={values.nameStaffB}
                        touched={touched.nameStaffB}
                        errors={errors.nameStaffB}
                        isRequired={true}
                        modalParams={params2}
                        selectRow={selectRow}
                        clearFun={clearInput}
                        fields={["nameStaffB", "employeeB"]}
                        isDisabled={selected||!values.nameStaffA}
                    />
                </Col>
                <Col xs={12} md={6}>
                    <TextInput
                        label="Fecha de Autorización"
                        inputType="date"
                        inputName="dateStart"
                        onChangeMethod={onChange}
                        onBlurMethod={handleBlur}
                        value={values.dateStart}
                        touched={touched.dateStart}
                        errors={errors.dateStart}
                        isRequired
                        isDisabled={selected}
                    />
                </Col>
            </Row>

            {
                !selected ?
                <div className="d-grid gap-2 d-md-flex justify-content-md-around mt-2">
                    <Button color="danger" type="reset">
                        Cancelar
                    </Button>
                    <Button color="success" type="submit">
                        Guardar
                    </Button>
                </div> :
                <div className="text-center mt-2">
                    <Button color="danger" type="reset">
                        Regresar
                    </Button>
                </div>
            }

            <ModalConfirmation
                modalTitle="Cambiar plazas"
                modal={modal}
                crear={getStaffSalaries}
                setModal={setModal}
                centered
            >
                <div className="text-center">
                    <h6>¿Desea guardar el intercambio de plazas?</h6>
                    <p>No podrá deshacer este movimiento</p>
                </div>
            </ModalConfirmation>

            <ModalConfirmation
                modal={modalOk}
                crear={()=>handleReset()}
                setModal={setModalOk}
                hiddeCancel
                buttonOkMsg="Aceptar"
                centered
            >
                <div className="text-center mx-1">
                    <h4>Movimiento realizado correctamente</h4>
                    <h6 className="text-muted">Verificar los movimientos en asignación de plaza de cada empleado</h6>
                </div>
            </ModalConfirmation>

            <FullScreenLoader show={loading} message={msgLoading} />
            
            <div style={{ display: "none" }}>
                <QRCode
                id="123456"
                value="https://fgjem.edomex.gob.mx/"
                size={290}
                level={"H"}
                includeMargin={true}
                />
            </div>
        </form>
    )
}
import { Row, Col, Button } from "reactstrap"
import { toast } from "react-toastify";
import { useState } from "react";

import Datatable from "../../../../components/Datatable/Datatable";
import Widget from "../../../../components/Widget/Widget";
import { peticionesReceiver } from "../../../../helpers/peticionesReceiver";
import Notification from "../../../../components/Notification/Notification";
import { GetPermisos } from "../../../../libs/permissions/hook";

export default function AgregarAreasPresupuestoUnidadesEjecutoras() {
    const idAnnualBudget = localStorage.getItem("preunieje");

    if (idAnnualBudget === null) {
        toast(<Notification type={"consultar_error"} backMessage={"Ocurrio un error al recuperar la información"} />);
    }

    const API = peticionesReceiver()

    const [permissionsForm, setPermissionsForm] = useState("");

    GetPermisos(setPermissionsForm);

    const headers = [
        "Unidad ejecutora", //0
        "Importe",
        "Editar",
        "PDF"
    ];

    const columns = [
        { data: "name" },
        { data: "amount" },
        {
            data: "idBudgetArea",
            render: function (row) {
                return (
                    <>
                        {permissionsForm.UPD === "1" ? (
                            <i className="fa fa-pencil text-warning" style={{ cursor: "pointer" }} onClick={() => changeToEdit(row)} />
                        ) : ""}
                    </>
                )
            }
        },
        {
            data: "idBudgetArea",
            render: function (row) {
                return (
                    <>
                        <i className="fa fa-file-pdf-o text-danger" style={{ cursor: "pointer" }} />
                    </>
                )
            }
        },
    ]

    function changeToEdit(idBudgetArea) {
        localStorage.setItem("preuniejearea", JSON.stringify({ idBudgetArea, idAnnualBudget }));
        window.location.href = "#/template/presupuestounidades/areas/editar"
    }

    function changeToAdd() {
        localStorage.setItem("preuniejearea", JSON.stringify({ idAnnualBudget }));
        window.location.href = "#/template/presupuestounidades/areas/agregar"
    }

    const columnDefs = [
        { targets: [2, 3], className: "text-center", orderable: false },
        { targets: [1], className: "text-right" }
    ]

    function getParams(order, records, page, search) {
        return {
            action: "getAreaBudgets",
            page,
            records,
            search,
            order,
            idAnualBudget: idAnnualBudget
        }
    }

    async function getData(order, records, page, search) {
        try {
            const res = await API.peticionEndPoint(getParams(order, records, page, search), "app/facades/budgets/budgetsF.php")
            if (res.status === 200 && res.data.code === "200") {
                const data = res.data;
                data.data.forEach((value, index) => value.index = page + 1 + index);
                return data;
            } else {
                toast(<Notification type={"consultar_error"} backMessage={res.data.message} />);
                return null;
            }
        } catch (err) {
            toast(<Notification type={"consultar_servidor_error"} withIcon />);
            return null
        }
    }

    return (
        <>
            <Widget className={"p-3"}>
                <Row>
                    <Col xs={12} md={6}>
                        <h1 className="text-secondary mb-3">Areas</h1>
                    </Col>
                    <Col xs={12} md={6} className="text-right">
                        {permissionsForm.INS === "1" && (
                            <Button color="add" onClick={changeToAdd}>Agregar area</Button>
                        )}
                    </Col>
                </Row>
                {permissionsForm.CON === "1" ? (
                    <Datatable
                        headers={headers}
                        petition={getData}
                        columns={columns}
                        columnDefs={columnDefs}
                        control='back'
                        key={"table"}
                    />
                ) : (<div className="text-center p-4">No tienes permitido ver los registros de esta seccion</div>)}
                <Button color="danger" onClick={() => window.location.href = "#/template/presupuestounidades"}>Regresar</Button>
            </Widget>
        </>
    )
}

import React from "react";

import Widget from "../../../../components/Widget/Widget";
import FormImpuestos from "../FormImpuestos";
import { updateImpuesto } from "../functions";

export const EditarImpuesto = (props) => {
  return (
    <Widget className="widget-p-md">
        <h3>Editar tipo de impuesto</h3>
        <FormImpuestos data={localStorage.getItem('dataContent').split('|-|')} editar={updateImpuesto}/>
    </Widget>
  )
}

export default EditarImpuesto;
/**
 * @param params setters from Format
 */
export function setConfigR24(params) {
  const {
    setPdfConfig,
    setExcelConfig,
    setHtmlConfig,
    setData,
    setHeaders,
    filter,
  } = params;

  const paramQ = {
    action: "getPagosProveedoresNomina",
    period: +filter.period,
    year: (new Date(filter.year)).getFullYear(),
    detalle: filter.detalle === "0" ? "" : "1",
    // idAccountList: idAL,
  };
  if (filter.idFormat === "2") paramQ.detalle = "1";
  if (filter.idFormat === "3" || paramQ.detalle === "") delete paramQ.detalle;
  setData(paramQ);

  setPdfConfig({
    period: filter.period,
    year: (new Date(filter.year)).getFullYear(),
    detalle: filter.detalle === "0" ? "" : "1",
  });

  const headers = [
    "Id",
    "NOMBRE DE LA CUENTA",
    "NÚMERO DE LA CUENTA",
    "TOTAL A PAGAR",
    "BENEFICIARIO",
    "CLABE BANCARIA",
    "BANCO DESTINO",
    "Ver detalle",
  ];
  const content = [
    { header: "NOMBRE DE LA CUENTA", value: "nameAccountList" },
    { header: "NÚMERO DE LA CUENTA", value: "lcPropio" },
    { header: "TOTAL A PAGAR", value: "deducciones" },
    { header: "BENEFICIARIO", value: "rfc" },
    { header: "CLABE BANCARIA", value: "cuenta" },
    { header: "BANCO DESTINO", value: "nameBank" },
  ];

  setHeaders(headers);

  setHtmlConfig({
    headers,
    id: { id: "idAccountList" },
    content: [content],
  });

  setExcelConfig({
    period: filter.period,
    year: (new Date(filter.year)).getFullYear(),
    detalle: filter.detalle === "0" ? "" : "1",
  });
}


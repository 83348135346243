const styles = {
    container: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        backgroundColor: '#ebebeb',
        fontFamily: 'Arial, sans-serif',
    },
    innerContainer: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        padding: '40px',
        backgroundColor: '#ffffff',
        borderRadius: '6px',
        boxShadow: '0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)',
    },
    logo: {
        width: '100px',
        height: '100px',
        marginBottom: '20px',
    },
    textContainer: {
        textAlign: 'center',
    },
    title: {
        fontSize: '28px',
        fontWeight: 'bold',
        margin: 0,
        color: '#333333',
    },
    subtitle: {
        fontSize: '18px',
        margin: '10px 0 0',
        color: '#333333',
    },
    message: {
        fontSize: '14px',
        margin: '10px 0 0',
        color: '#333333',
    },
};

export default function ErrorDatatable({ message }) {
    return (
        <div style={styles.container}>
            <div style={styles.innerContainer}>
                <div style={styles.textContainer}>
                    <h1 style={styles.title}>Lo sentimos.</h1>
                    <p style={styles.subtitle}>Se produjo un error inesperado.</p>
                    {message && <p style={styles.message}>{message}</p>}
                </div>
            </div>
        </div>
    );
}
import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import Notification from "../../../../../components/Notification/Notification";
import { peticionesReceiver } from "../../../../../helpers/peticionesReceiver";
import CollapseTableColor from "../../../componentes/others/CollapseTableColor";
import HeaderEvidenciaAvances from "../../../componentes/others/HeaderEvidenciaAvances";
import ModalObservaciones from "../../../componentes/modal/ModalObservacionesUpdate";
import ModalViewFile from "../../../../../components/Modal/ModalViewFile";
import ModalEvidencias from "../../../componentes/modal/ModalEvidencias";
import { Row, Col } from "reactstrap";

const EvidenciaActividades = (props) => {
  const { proyect } = props;

  const API = peticionesReceiver();
  const [activityEvidence, setActivityEvidence] = useState("");
  const [processSelected, setProcessSelected] = useState("");
  const [urlDocument, setUrlDocument] = useState("");
  const [idActivity, setidActivity] = useState("");

  const [modalObservaciones, setModalObservaciones] = useState(false);
  const [modalView, setModalView] = useState(false);
  const [modalAvances, setModalAvances] = useState(false);


  useEffect(() => {
    getActivityEvidences();
  }, []);

  const getActivityEvidences = () => {
    const params = {
      action: "getActivityEvidences",
      idProyect: proyect.id,
    };
    API.peticionEndPoint(params, "app/facades/planning/planningF.php")
      .then((res) => {
        if (res.data.status === "success" && res.data.code === "200") {
          setActivityEvidence(res.data.data);
        } else {
          toast(
            <Notification
              type={"consultar_error"}
              backMessage={res.data.message}
              withIcon
            />
          );
        }
      })
      .catch((err) => {
        toast(<Notification type={"consultar_servidor_error"} withIcon />);
      });
  };

  const getDocuments = async (idProgress) => {
    const params = {
      action: "multiselect",
      table: "bookcase A INNER JOIN files B ON A.idBook = B.idBook",
      rows: "B.src",
      conditions: `A.reference = ${idProgress} AND A.keyFileType = ${16} AND B.enabled = 1`,
      order: "",
    };
    await API.peticion(params)
      .then((res) => {

        if (res.status === 200 && res.data.status === "success") {
          setUrlDocument(res.data.data[0])
        } else {
          toast(
            <Notification
              type={"consultar_error"}
              backMessage={res.data.message}
              withIcon
            />
          );
        }
      })
      .catch((err) => {
      });
  };


  const refreshMethod = () => {
    getActivityEvidences()
  }

  const showDocument = async (progress) => {
    await getDocuments(progress.idProgress)
    setModalView(true)
  }

  const editProcess = (process,idActivity) => {
    setProcessSelected(process)
    setidActivity(idActivity)
    setModalAvances(true)
  }

  const showObservaciones = (process) => {
    setProcessSelected(process)
    setModalObservaciones(true)
  }

  return (
    <div>

      {activityEvidence !== "" ?
        activityEvidence.map((activity, index) => (

          <Row className="mt-3" key={activity.idActivity}>
            <Col
              xs={12}
              md={12}
              lg={12}
            >
              <CollapseTableColor 
                activity={activity} 
                proyect={proyect} 
                data={activity}
                refreshMethod ={refreshMethod}
                methodViewFile={showDocument}
                methodObservaciones={showObservaciones}
                editProcessMethod={editProcess}
                isCollapsed={index === 0}
              />
            </Col>
          </Row>
        )) : null}

      {modalObservaciones &&
        <ModalObservaciones
          modal={modalObservaciones}
          setModal={setModalObservaciones}
          activity={processSelected}
          btnClose={true}
          refreshMethod={refreshMethod}
        />}


      {modalView &&
        <ModalViewFile
          modal={modalView}
          setModal={setModalView}
          backdrop={true}
          keyboard={true}
          btnClose={true}
          fileLink={urlDocument.src}
        />}

      {modalAvances && processSelected !== "" &&

        <ModalEvidencias
          modal={modalAvances}
          setModal={setModalAvances}
          title={"Dictaminación de avances"}
          backdrop={"static"}
          keyboard={true}
          idActivity={idActivity}
          progress={processSelected}
          refreshMethod={refreshMethod}
          btnClose={true}
        />

      }
    </div>


  );
};

export default EvidenciaActividades;

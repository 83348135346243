import React, { useState, useEffect } from "react";
import axios from "axios";
import * as Yup from "yup";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import CurrencyInput from "react-currency-input-field";
import { Col, Row, Button, Collapse } from "reactstrap";

import { peticionEncript } from "../../../helpers/peticionesEncripted";
import { peticionesReceiver } from "../../../helpers/peticionesReceiver";

import Loader from "../../../components/Loader/Loader";
import Notification from "../../../components/Notification/Notification";

import ModalConfirmation from "../../../components/Modal/ModalConfirmation";
import {
  TextInput,
  SelectTypeHeadSingle,
  FilesInput,
} from "../../../components/GenericInputsFormik/index";
import { doRequest, uploadFile } from "../../../helpers/requests";

import TableComponentCustom from "../../../components/TableComponent/TableComponentCustom";
import ModalViewFile from "../../../components/Modal/ModalViewFile";
import ModalComponentEliminar from "../../../components/Modal/ModalDelete";

const API = peticionesReceiver();

const defaultForm = {
  idChildcareService: "",
  idStaff: "",
  idUnder: "",
  keyStat: "",
  noChildren: "",
  policy: "",
  startDate: "",
  endDate: "",
  amountTotal: "",
  noPays: "",
  amountBiweekly: "",
  noPaysMade: 0,
  notes: "",
  comprobante: "",
};

async function selectGet(params) {
  let info = [];
  await API.peticion(params)
    .then((res) => {
      if (res.status === 200 && res.data.code === "200") {
        info = res.data.data;
      } else {
        toast(<Notification type={"consultar_error"} withIcon />);
      }
    })
    .catch((err) => {
      toast(<Notification type={"consultar_servidor_error"} withIcon />);
    });
  return info;
}

export const ExpedienteNominaGuarderias = ({ idStaff, permissions }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState("");
  const [modal, setModal] = useState(false);
  // const [maxPays, setMaxPays] = useState(1);
  const [conceptos, setConceptos] = useState([]);
  const [estatus, setEstatus] = useState([]);
  const formSection = React.useRef(null);
  const refInpFile = React.useRef(null);
  const [modalFile, setModalFile] = useState(false); ///Visualziar archivos
  const [linkFile, setLinkFile] = useState(""); ///Link de archivo
  const [editHasFile, setEditHasFile] = useState(false);

  const cabeceras = [
    "Id",
    "Hijos", //  [childcareservice]
    "Vigente", // keyStat - [status]
    "Fehca de inicio", // [childcareservice]
    "Fecha de termino", // [childcareservice]
    "Monto total", // [childcareservice]
    "Pagos realizados", // [childcareservice]
    "Total de pagos", // [childcareservice]
    "Notas", // [childcareservice]
    "Evidencia",
    "Editar", // []
    "Eliminar", // []
  ];

  const filtro = [true, true, true, true, true, true, true, true, true];
  const [parametros, setParametros] = useState({
    action: "datatable",
    table:
      "childcareservice A INNER JOIN status B ON A.keyStat = B.keyStat LEFT JOIN bookcase X ON A.idChildcareService = X.reference AND X.keyFileType = 52 LEFT JOIN files Y ON X.idBook = Y.idBook ",
    rows: "A.idChildcareService,A.noChildren,B.nameStat,A.startDate,A.endDate,A.amountTotal,A.noPaysMade,A.noPays,A.notes,B.keyStat,A.idUnder,A.policy,A.amountBiweekly,Y.src",
    conditions: `A.enabled = 1 AND B.enabled = 1 AND idStaff=${idStaff}`,
    page: 0,
    records: 5,
    search: "",
    order: "",
  });

  const FormSchema = Yup.object().shape({
    policy: Yup.string().required("Ingrese el oficio"),
    idUnder: Yup.string().required("Seleccione un concepto"),
    keyStat: Yup.string().required("Seleccione un estatus"),
    noChildren: Yup.number()
      .required("Ingrese la cantidad de hijos")
      .min(1, "Cantidad invalida")
      .integer("Ingrese una cantidad entera"),
    startDate: Yup.date().required("Seleccione una fecha"),
    endDate: Yup.date()
      .required("Seleccione una fecha")
      .min(
        Yup.ref("startDate"),
        ({ min }) => "La fecha debe ser mayor a la fecha de inicio"
      ),
    amountTotal: Yup.number()
      .required("Ingrese la cantidad total")
      .min(1, "Cantidad invalida"),
    noPays: Yup.number()
      .integer("Ingrese una cantidad entera")
      .min(1, "Cantidad invalida")
      .required("Ingrese el número de pagos"),
    comprobante: Yup.mixed().when("act", {
      is: (v) => {
        return editHasFile;
      },
      otherwise: Yup.mixed()
        .required("Agregue un comprobante")
        .test("file-type", "El documento debe ser en formato PDF", (value) => {
          return value && value.type === "application/pdf";
        })
        .test({
          message: `El documento debe pesar menos de 4MB`,
          test: (value) => value?.size < 4000000,
        }),
    }),
  });

  const getConceptos = async () => {
    const params = {
      action: "multiselect",
      table: "unders",
      rows: "idUnder,concat(under,' - ',nameUnder) as name",
      conditions: "enabled = 1 and keyRollUnder = 10",
      order: "cast(under as float) asc",
    };
    let data = await selectGet(params);
    setConceptos(data);
  };

  const getEstatus = async () => {
    const params = {
      action: "select",
      table: "status",
      order: "nameStat ASC",
      condition: { keyTypeStat: 10 },
    };
    let data = await selectGet(params);
    setEstatus(data);
  };

  const getForEdit = (el) => {
    if (!isOpen) {
      if (13) setEditHasFile(true);
      setTitle("Editando guardería");
      setFieldValue("idChildcareService", el[0]);
      setFieldValue("idStaff", idStaff);
      setFieldValue("idUnder", el[10]);
      setFieldValue("keyStat", el[9]);
      setFieldValue("noChildren", el[1]);
      setFieldValue("policy", el[11]);
      setFieldValue("startDate", el[3]);
      setFieldValue("endDate", el[4]);
      setFieldValue("amountTotal", el[5]);
      setFieldValue("noPays", el[7]);
      setFieldValue("amountBiweekly", el[12]);
      setFieldValue("noPaysMade", el[6]);
      setFieldValue("notes", el[8]);
      setIsOpen(true);
    } else {
      toast(
        <Notification
          type="warning"
          backMessage="Guarde sus cambios antes de continuar"
        />,
        { closeButton: false }
      );
      scrollTo();
    }
  };

  const editInfo = async (data) => {
    let rows = { ...values };
    delete rows.comprobante;
    rows["idStaff"] = idStaff;
    const params = {
      action: "saveChildcareservice",
      rows: rows,
    };
    const finalData = peticionEncript(params);
    await axios
      .post(
        `${process.env.REACT_APP_API}app/facades/employees/benefitsF.php`,
        finalData
      )
      .then(async (res) => {
        if (res.status === 200 && res.data.status === "success") {
          if (values.comprobante) {
            await uploadFile(
              res.data.data[0].idChildcareService,
              "saveFileReplace",
              52,
              values.comprobante,
              false
            );
          }
          toast(
            <Notification
              type={"modifica_exito"}
              backMessage={res.data.message}
              withIcon
            />
          );
          setIsOpen(false);
          setParametros({ ...parametros });
          resetForm();
        } else {
          toast(
            <Notification
              type={"modifica_error"}
              backMessage={res.data.message}
              withIcon
            />,
            { closeButton: false }
          );
        }
      })
      .catch((err) => {
        toast(<Notification type={"consultar_servidor_error"} withIcon />, {
          closeButton: false,
        });
      });
  };

  useEffect(() => {
    getConceptos();
    getEstatus();
  }, []);

  const {
    handleSubmit,
    values,
    handleBlur,
    errors,
    touched,
    resetForm,
    setFieldValue,
    handleReset,
  } = useFormik({
    initialValues: defaultForm,
    validationSchema: FormSchema,
    onSubmit: (values) => {
      setModal(true);
    },
    onReset: () => {
      setTitle("");
      if (refInpFile.current !== null) refInpFile.current.value = "";
    },
    enableReinitialize: true,
  });

  const openForm = () => {
    setTitle("Agregando guarderia");
    setIsOpen(true);
  };

  const onChange = (e) => {
    setFieldValue([e.target.name], e.target.value);

    calcQuincena(e);
    if (e.target.name === "startDate") {
      if (e.target.value === "") {
        setFieldValue("endDate", "");
      }
    }
  };

  const OnChangeSelect = (event) => {
    const { name, value } = event.target;
    setFieldValue(name, value);
  };

  const onChangeFile = (e) => {
    var { files } = e.target;
    if (files.length === 0) {
      setFieldValue("comprobante", "");
    } else {
      setFieldValue("comprobante", files[0]);
    }
  };

  const calcQuincena = (e) => {
    if (e.target.name === "noPays" && values.amountTotal !== "") {
      if ((e.target.value !== "") & (e.target.value !== undefined)) {
        setFieldValue(
          "amountBiweekly",
          (+values.amountTotal / +e.target.value).toFixed(2)
        );
        // setMaxPays(e.target.value);
      } else {
        setFieldValue("amountBiweekly", "");
      }
    } else if (e.target.name === "amountTotal" && values.noPays !== "") {
      if ((e.target.value !== "") & (e.target.value !== undefined)) {
        setFieldValue(
          "amountBiweekly",
          (+e.target.value / +values.noPays).toFixed(2)
        );
      } else {
        setFieldValue("amountBiweekly", "");
      }
    }
  };

  const limpia = (reset) => {
    setTitle("");
    reset();
    setIsOpen(false);
  };

  const scrollTo = () => {
    formSection.current.scrollIntoView({ behavior: "smooth" });
  };

  const clearForm = () => {
    setIsOpen(false);
    setEditHasFile(false);
  };

  // -------------------- COLUMNAS TABLA ---------------- //
  const PdfCol = (el) => {
    return (
      <div>
        <i
          className="fa fa-file-pdf-o cursor-pointer"
          onClick={() => {
            showFile(el[13]);
          }}
        />
      </div>
    );
  };
  const editCol = (el) => {
    if (permissions.UPD)
      return (
        <div>
          <i
            className="fa fa-pencil text-warning cursor-pointer"
            onClick={() => getForEdit(el)}
          />
        </div>
      );
  };
  const deleteCol = (el) => {
    if (permissions.DEL)
      return (
        <div>
          <ModalComponentEliminar
            modalTitle="Eliminar"
            id={el[0]}
            table="childcareservice"
            nameColumn={["idChildcareService"]}
            limpia={limpia}
            elimina={elimianrRegistro}
          >
            <h6>¿Seguro de eliminar el registro "{el[1]}"?</h6>
            No podrá recuperar la información después de ser eliminada
          </ModalComponentEliminar>
        </div>
      );
  };

  async function elimianrRegistro(id) {
    const params = {
      action: "delete",
      table: "childcareservice",
      condition: { idChildcareService: id },
      force: 1,
    };
    setLoading(true);
    const data = await doRequest("receiver/receiver.php", params, true);
    if (data.length > 0) {
      limpia(resetForm);
      setParametros({ ...parametros });
    }
    setLoading(false);
  }

  useEffect(() => {
    if (linkFile !== "") {
      setModalFile(true);
    }
  }, [linkFile]);

  useEffect(() => {
    if (!modalFile) {
      setLinkFile("");
    }
  }, [modalFile]);

  function showFile(src) {
    if (src === "") {
      toast(
        <Notification
          type={"warning"}
          backMessage="Este registro no cuenta con una evidencia"
          withIcon
        />,
        { closeButton: false }
      );
    } else setLinkFile(src);
  }

  return (
    <div className="mt-4">
      <TableComponentCustom
        titulo="Guarderias"
        cabecerasTabla={cabeceras}
        filtro={filtro}
        parametros={parametros}
        specialColumns={[PdfCol, editCol, deleteCol]}
      />

      <div className="row mt-2">
        <div className="col-12 col-sm-6">
          <h5 className="text-center text-sm-left">{title}</h5>
        </div>
        {permissions.INS && (
          <div className="col-12 col-sm-6">
            <div className="text-center text-sm-right">
              <Button color="add" onClick={() => openForm()} disabled={isOpen}>
                Agregar
              </Button>
            </div>
          </div>
        )}
      </div>
      <Collapse isOpen={isOpen} className="mt-4 border-top">
        {loading ? (
          <Loader />
        ) : (
          <form onSubmit={handleSubmit} onReset={handleReset} className="mt-4">
            <ModalConfirmation
              modalTitle="Guardar registro"
              modal={modal}
              setModal={setModal}
              editar={editInfo}
              isEdit={true}
              values={values}
            >
              <div className="d-flex justify-content-center">
                <h6>¿Está seguro de guardar el registro?</h6>
              </div>
            </ModalConfirmation>
            <Row>
              <Col xs="12" lg="3">
                <TextInput
                  label="Oficio"
                  inputType="text"
                  inputName="policy"
                  onChangeMethod={onChange}
                  onBlurMethod={handleBlur}
                  value={values.policy}
                  isRequired={true}
                  touched={touched.policy}
                  errors={errors.policy}
                />
              </Col>
              <Col xs="12" lg="5">
                <SelectTypeHeadSingle
                  label="Concepto"
                  isRequired={true}
                  inputName="idUnder"
                  optionsArray={conceptos}
                  defaultOption="Seleccione un concepto"
                  onChangeMethod={OnChangeSelect}
                  onBlur={handleBlur}
                  isDisabled={false}
                  value={values.idUnder}
                  touched={touched.idUnder}
                  errors={errors.idUnder}
                  optionValue="idUnder"
                  optionName="name"
                />
              </Col>
              <Col xs="12" lg="4">
                <SelectTypeHeadSingle
                  label="Estados"
                  isRequired={true}
                  inputName="keyStat"
                  optionsArray={estatus}
                  defaultOption="Seleccione un estados"
                  onChangeMethod={OnChangeSelect}
                  onBlur={handleBlur}
                  isDisabled={false}
                  value={values.keyStat}
                  touched={touched.keyStat}
                  errors={errors.keyStat}
                  optionValue="keyStat"
                  optionName="nameStat"
                />
              </Col>
            </Row>
            <Row className="mt-2">
              <Col xs="12" lg="4">
                <div className="form-group">
                  <label htmlFor="noChildren">
                    Cantidad de hijos <span className="text-danger">*</span>
                  </label>
                  <CurrencyInput
                    id="noChildren"
                    name="noChildren"
                    className={
                      "form-control" +
                      (errors.noChildren && touched.noChildren
                        ? " is-invalid"
                        : "")
                    }
                    allowDecimals={false}
                    allowNegativeValue={false}
                    onValueChange={(v, n) =>
                      onChange({ target: { name: n, value: v } })
                    }
                    // onChange={onChange}
                    onBlur={handleBlur}
                    maxLength={2}
                    value={values.noChildren}
                    // step={1}
                    min={1}
                  />
                  {touched.noChildren && errors.noChildren && (
                    <span className="text-danger">{errors.noChildren}</span>
                  )}
                </div>
              </Col>
              <Col xs="12" lg="4">
                <TextInput
                  label="Fecha de inicio"
                  inputType="date"
                  inputName="startDate"
                  onChangeMethod={onChange}
                  onBlurMethod={handleBlur}
                  value={values.startDate}
                  isRequired={true}
                  touched={touched.startDate}
                  errors={errors.startDate}
                />
              </Col>
              <Col xs="12" lg="4">
                <TextInput
                  label="Fecha de termino"
                  inputType="date"
                  inputName="endDate"
                  onChangeMethod={onChange}
                  onBlurMethod={handleBlur}
                  value={values.endDate}
                  isRequired={true}
                  touched={touched.endDate}
                  errors={errors.endDate}
                  isDisabled={values.startDate ? false : true}
                  isMin={values.startDate}
                />
              </Col>
            </Row>
            <Row className="mt-2">
              <Col xs="12" lg="4">
                <div className="form-group">
                  <label htmlFor="amountTotal">
                    Cantidad total <span className="text-danger">*</span>
                  </label>
                  <CurrencyInput
                    id="amountTotal"
                    name="amountTotal"
                    className={
                      "form-control" +
                      (errors.amountTotal && touched.amountTotal
                        ? " is-invalid"
                        : "")
                    }
                    allowDecimals={true}
                    allowNegativeValue={false}
                    onValueChange={(value, name) =>
                      onChange({ target: { name: name, value: value } })
                    }
                    value={values.amountTotal}
                    onBlur={handleBlur}
                    min={1}
                    prefix="$"
                  />
                  {touched.amountTotal && errors.amountTotal && (
                    <span className="text-danger">{errors.amountTotal}</span>
                  )}
                </div>
              </Col>
              <Col xs="12" lg="4">
                <div className="form-group">
                  <label htmlFor="noChildren">
                    Número de parcialidades{" "}
                    <span className="text-danger">*</span>
                  </label>
                  <CurrencyInput
                    id="noPays"
                    name="noPays"
                    className={
                      "form-control" +
                      (errors.noPays && touched.noPays ? " is-invalid" : "")
                    }
                    allowDecimals={false}
                    allowNegativeValue={false}
                    onValueChange={(v, n) =>
                      onChange({ target: { name: n, value: v } })
                    }
                    onBlur={handleBlur}
                    maxLength={3}
                    value={values.noPays}
                    min={1}
                  />
                  {touched.noPays && errors.noPays && (
                    <span className="text-danger">{errors.noPays}</span>
                  )}
                </div>
              </Col>
              <Col xs="12" lg="4">
                <div className="form-group">
                  <label htmlFor="amountBiweekly">
                    Cantidad quincenal <span className="text-danger">*</span>
                  </label>
                  <CurrencyInput
                    id="amountBiweekly"
                    name="amountBiweekly"
                    className={
                      "form-control" +
                      (errors.amountBiweekly && touched.amountBiweekly
                        ? " is-invalid"
                        : "")
                    }
                    allowDecimals={true}
                    allowNegativeValue={false}
                    onValueChange={(value, name) =>
                      onChange({ target: { name: name, value: value } })
                    }
                    onBlur={handleBlur}
                    min={1}
                    prefix="$"
                    value={values.amountBiweekly}
                    disabled
                  />
                  {touched.amountTotal && errors.amountTotal && (
                    <span className="text-danger">{errors.amountTotal}</span>
                  )}
                </div>
              </Col>
            </Row>
            <Row className="mt-2">
              <Col xs="12" lg="2">
                <TextInput
                  label="Pagos realizados"
                  inputType="number"
                  inputName="noPaysMade"
                  onChangeMethod={onChange}
                  onBlurMethod={handleBlur}
                  value={values.noPaysMade}
                  touched={touched.noPaysMade}
                  errors={errors.noPaysMade}
                  isDisabled
                />
              </Col>
              <Col xs={12} md={12} lg={4}>
                <FilesInput
                  label="Evidencia"
                  inputName="comprobante"
                  onChangeMethod={onChangeFile}
                  onBlurMethod={handleBlur}
                  fileAccept="application/pdf"
                  isRequired
                  touched={touched.comprobante}
                  errors={errors.comprobante}
                  basicReq={false}
                  refInput={refInpFile}
                />
              </Col>
              <Col xs="12" lg="6">
                <div className="form-group">
                  <label htmlFor="notes">Notas</label>
                  <textarea
                    name="notes"
                    className="form-control"
                    onChange={onChange}
                    onBlur={handleBlur}
                    value={values.notes}
                  />
                </div>
              </Col>
            </Row>
            <Row className="mt-5">
              <div
                className="col-sm-6 order-2 order-sm-1 text-center text-sm-left mb-4"
                ref={formSection}
              >
                <Button color="danger" type="reset" onClick={() => clearForm()}>
                  Cancelar
                </Button>
              </div>
              <div className="col-sm-6 order-1 order-sm-2 text-center text-sm-right mb-4">
                <Button color="success" type="submit">
                  Guardar
                </Button>
              </div>
            </Row>
          </form>
        )}
      </Collapse>
      <ModalViewFile
        modal={modalFile}
        setModal={setModalFile}
        backdrop={true}
        keyboard={true}
        btnClose={true}
        fileLink={linkFile}
      />
    </div>
  );
};
export default ExpedienteNominaGuarderias;

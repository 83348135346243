import React, { useState, useEffect } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Col,
  Row,
  Collapse,
  Alert
} from "reactstrap";
import * as Yup from "yup";
import RegistroComponent from "../others/RegistroComponent";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import Notification from "../../../../components/Notification/Notification";

import { peticionesReceiver } from "../../../../helpers/peticionesReceiver";
import { useDropzone } from "react-dropzone";
import { useMemo } from "react";
import ModalConfirmation from "../../../../components/Modal/ModalConfirmation";
import ModalViewFile from "../../../../components/Modal/ModalViewFile";
import axios from "axios";

//styles de dropZone
const baseStyle = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  textAlign: "center",
  padding: "1.25rem",
  height: "250px",
  borderWidth: "2px",
  borderStyle: "dashed",
  borderColor: "#C7D0D9",
  borderRadius: "8px",
  position: "relative",
  cursor: "pointer",
};
const focusedStyle = {
  borderColor: "#2196f3",
};

function ModalAvances(props) {
  const {
    modal,
    setModal,
    backdrop,
    keyboard,
    idActivity,
    progress,
    refreshMethod,
    btnClose,
    modify
  } = props;

  const API = peticionesReceiver();

  const [data, setData] = useState("");
  const Month = new Date().getMonth(); //Mes actual se utiliza para saber que registro se ingresa

  const [collapse, setCollapse] = useState(false);
  const [modalConfirmation, setModalConfirmation] = useState(false);
  const [documentCorrect, setDocumentCorrect] = useState(2); // 1 correct , 2 false , 3 ignore
  const [urlDocument, setUrlDocument] = useState("")

  const [modalView, setModalView] = useState(false);

  const { acceptedFiles, getRootProps, getInputProps, isFocused } = useDropzone(
    {
      acceptedFiles: {
        "aplication/pdf": [".pdf"],
      },
      multiple: false,
      maxFiles: 1,
    }
  );

  const style = useMemo(() => ({
    ...baseStyle,
    ...(isFocused ? focusedStyle : {}),
  }));

  useEffect(() => {
    if (modal) {

      getActivityProgram();

      if (progress !== null && progress !== undefined) {
        getDocument()
      }

    } else {
      resetForm()
      setDocumentCorrect(false)
    }
  }, [modal]);

  

  const getActivityProgram = () => {
    const params = {
      action: "getActivityProgram",
      idActivity: idActivity,
    };

    API.peticionEndPoint(params, "app/facades/planning/planningF.php")
      .then((res) => {
        if (res.data.status === "success" && res.data.code === "200") {
          setData(res.data.data);
        } else {
          toast(
            <Notification
              type={"consultar_error"}
              backMessage={res.data.message}
              withIcon
            />
          );
        }
      })
      .catch((err) => {
        toast(<Notification type={"consultar_servidor_error"} withIcon />);
      });
  };


  const getDocument = async () => {
    const params = {
      action: "multiselect",
      table: "bookcase A INNER JOIN files B ON A.idBook = B.idBook",
      rows: "B.src",
      conditions: `A.reference = ${progress.idProgress} AND A.keyFileType = ${16} AND B.enabled = 1`,
      order: "",
    };
    await API.peticion(params)
      .then((res) => {
        if (res.status === 200 && res.data.status === "success") {

          setUrlDocument(res.data.data[0])
          setDocumentCorrect(3)
        } else {
          toast(
            <Notification
              type={"consultar_error"}
              backMessage={res.data.message}
              withIcon
            />
          );
        }
      })
      .catch((err) => {
        toast(<Notification type={"consultar_servidor_error"} withIcon />);
      });
  };

  useEffect(() => {
    //Validaiciones de pdf y tamaño

    if (acceptedFiles.length > 0) {
      if (acceptedFiles[0].type === "application/pdf") {
        if (acceptedFiles[0].size < 5000000) {
          setDocumentCorrect(1);
        } else {
          toast(
            <Notification
              type={"consultar_error"}
              backMessage={"Archivo demaciado grandre"}
              withIcon
            />
          );
          setDocumentCorrect(2);
        }
      } else {
        toast(
          <Notification
            type={"consultar_error"}
            backMessage={"Ingrese un documento en formato PDF"}
            withIcon
          />
        );
        setDocumentCorrect(2);
      }
    } else {
      setDocumentCorrect(2)
    }

  }, [acceptedFiles]);



  const defaultData = progress ? {
    idProgress: progress.idProgress,
    idActivity: progress.idActivity,
    keyMonth: progress.keyMonth,
    description: progress.description,
    keyStat: progress.keyStat,
    progress: +progress.progress,
    notes: progress.notes,
  } :
    {
      idActivity: "",
      keyMonth: "",
      description: "",
      keyStat: "",
      progress: "",
      notes: "",
    };

  //realiza las peticiones , use de switch
  const insertInfo = async (values, type) => {
    let respuesta = null;
    let params = "";
    switch (type) {
      case "insertProgress":

        params = {
          action: "insert",
          table: "activitiesprogress",
          rows: values,
          /* validate: [
            [""]
          ] */
        };

        await API.peticion(params)
          .then((res) => {

            if (
              res.status === "success" ||
              res.status === "200" ||
              res.status === 200
            ) {

              toast(
                <Notification
                  type={"agrega_exito"}
                  backMessage={"Progreso agregado con exito"}
                  withIcon
                />
              );
              respuesta = res.data.data[0];
            } else {
              toast(
                <Notification
                  type={"consultar_error"}
                  backMessage={res.data.message}
                  withIcon
                />
              );
            }
          })
          .catch((err) => {
            toast(<Notification type={"consultar_servidor_error"} withIcon />, {
              closeButton: false,
            });
          });
        break;
      case "editProgress":

        params = {
          action: "update",
          table: "activitiesprogress",
          rows: values,
          condition: {
            idProgress: values.idProgress
          },
        };

        await API.peticion(params)
          .then((res) => {

            if (
              res.status === "success" ||
              res.status === "200" ||
              res.status === 200
            ) {

              toast(
                <Notification
                  type={"agrega_exito"}
                  backMessage={res.data.message}
                  withIcon
                />
              );
              respuesta = res.data.data[0];
            } else {
              toast(
                <Notification
                  type={"consultar_error"}
                  backMessage={res.data.message}
                  withIcon
                />
              );
            }
          })
          .catch((err) => {
            toast(<Notification type={"consultar_servidor_error"} withIcon />, {
              closeButton: false,
            });
          });
        break;

      case "insertDocument":

        params = {
          method: "post",
          url: `${process.env.REACT_APP_API}app/facades/files/fileReceiver.php`,
          data: values,
        }; 
        await axios(params)
          .then((res) => {
            toast(
              <Notification
                type={"agrega_exito"}
                backMessage={"Documento insertado correctamente"}
                withIcon
              />
            );
            setModal(false);
            resetForm();
          })
          .catch((err) => {
            toast(<Notification type={"agrega_error"} withIcon />);
          });

        break;
      default:
        break;
    }
    return respuesta;
  };

  //Prepara la informacion para ser enviada
  const prepareData = async (values) => {
    const params = {
      idProgress: values.idProgress,
      idActivity: idActivity,
      keyMonth: data[Month].keyMonth,
      keyStat: 87,
      description: values.description,
      progress: values.progress,
      notes: "",
    };


    const varProgress = await insertInfo(params, values.idProgress ? "editProgress" : "insertProgress");

    const formData = new FormData();
    formData.append("action", "saveFileReplace");
    formData.append("reference", varProgress.idProgress);
    formData.append("keyFileType", 16);
    formData.append("binary", acceptedFiles[0]);

    if (varProgress !== null && documentCorrect !== 3) {
      insertInfo(formData, "insertDocument");
    }

    refreshMethod && refreshMethod()
    setModal(false)


  };

  const FormSchema = Yup.object().shape({
    progress: Yup.number()
      /* .max(data === "" ? 0 : +(data[Month].goal - +data[Month].progress) , "Ingrese una cantidad menor a la total") */
      .required("Ingrese el avance realizado"),
    description: Yup.string().required("Introduzca una descripcion"),
  });

  const onChange = (e) => {
    setFieldValue(e.target.name, e.target.value);
  };

  const {
    handleSubmit,
    values,
    handleBlur,
    errors,
    touched,
    setFieldValue,
    resetForm,
  } = useFormik({
    initialValues: defaultData,
    onSubmit: (values) => {
      if (documentCorrect !== 2) {
        setModalConfirmation(true);
      }
    },
    validationSchema: FormSchema,
    enableReinitialize: true,
    onReset: () => { },
  });

  const toggle = () => {
    setModal(!modal);
  };
  const toggleCollapse = () => {
    setCollapse(!collapse);
  };

  const CloseBtn = (
    <>
      {btnClose ? (
        <i className="eva eva-close cursor-pointer" onClick={toggle} />
      ) : (
        <></>
      )}
    </>
  );

  return (
    <>
      <ModalConfirmation
        modalTitle={"Crear"}
        modal={modalConfirmation}
        setModal={setModalConfirmation}
        crear={prepareData}
        isEdit={false}
        values={values}
      >
        <div className="d-flex justify-content-center">
          <h6>¿Está seguro de guardar el avance?</h6>
        </div>
      </ModalConfirmation>
      <Modal
        isOpen={modal}
        backdrop={backdrop}
        keyboard={keyboard}
        toggle={toggle}
        scrollable={true}
        style={{ minWidth: "95%", maxHeight: "90%" }}
      >
        <ModalHeader className="pl-5" close={CloseBtn}></ModalHeader>
        <ModalBody className="mx-2">
          <form onSubmit={handleSubmit} className="mt-4" noValidate>
            <p className="headline-1 text-center mb-2 text-secondary">
              {" "}
              Registro de avances{" "}
            </p>

            <Row>
              <Col className="text-center">
                <Button
                  color="primary"
                  className="m-3"
                  onClick={() => toggleCollapse()}
                >
                  Mostrar Avance
                </Button>
              </Col>
            </Row>

            <Collapse isOpen={collapse} className="mb-3">
              <Row
                xs={1}
                md={3}
                lg={6}
                className="d-flex justify-content-around align-items-center"
              >
                {data !== "" &&
                  data.map((element) => (
                    <div key={element.keyMonth} className="border">
                      <RegistroComponent
                        month={element}
                        label="Progreso"
                        inputName="progress"

                      />
                    </div>
                  ))}
              </Row>
            </Collapse>

            <Row>
              <Col
                xs={12}
                md={12}
                lg={6}>
                {data !== "" && (
                  <RegistroComponent

                    month={data[Month]}
                    label="Progreso"
                    inputName="progress"
                    withInput
                    value={values.progress}
                    onChange={onChange}
                    handleBlur={handleBlur}
                    errors={errors.progress}
                    touched={touched.progress}
                    isDisable={false}
                  />
                )}
              </Col>

              <Col
                xs={12}
                md={12}
                lg={6}
                className=""
              >

                <label htmlFor="description">
                  Descripcion de la meta
                  <span className="text-danger">*</span>
                </label>
                <textarea
                  name="description"
                  id="description"
                  onChange={onChange}
                  onBlur={handleBlur}
                  rows={5}
                  className={
                    "form-control" + (errors.description && touched.description ? " is-invalid" : "")
                  }
                  value={values.description}
                />
                {touched && errors.description && (
                  <span style={{ color: "red" }}>{errors.description}</span>
                )}
              </Col>
            </Row>


            <Row className="mt-3">
              <Col className="d-flex flex-column">
                <p className="headline-2 text-center">
                  Descripción de la meta:
                </p>
                <p className="headline-2 text-center ">
                  Solicitud de adquisición y contratación
                </p>
              </Col>
            </Row>
            <Row className="d-flex  justify-content-center align-items-center text-center mt-3">

              <Col
                xs={12}
                md={12}
                lg={6}
                className=""
              >

                {modify &&
                  <Alert
                    color="dark"
                    isOpen
                    fade={false}
                    className=""
                  >
                    <h5 className="text-dark">Instrucciones</h5>
                    <p className="text-dark" >
                      Arrastre el archivo sobre el recuadro o haga click para
                      seleccionar un archivo
                    </p>
                  </Alert>
                }
                {urlDocument !== "" &&



                  <Alert
                    color="dark"
                    isOpen
                    fade={false}
                    className=""
                  >
                    <h5 className="text-dark">Documento Actual</h5>
                    <p className="text-dark">
                      {
                        <Button color="primary" onClick={() => setModalView(true)}>
                          Ver documento actual
                        </Button>
                      }
                    </p>
                  </Alert>

                }
              </Col>



              {modify &&

                <Col
                  xs={12}
                  md={12}
                  lg={6}
                  className=""
                >
                  <div>

                    <label htmlFor="file">Adjunte una evidencia</label>
                    <div {...getRootProps({ style })}>
                      <input {...getInputProps()} />

                      <div className="mt-3">
                        <p className="headline-2" >Documentos Agregados</p>
                        {acceptedFiles.length > 0 && documentCorrect === 1 ? (
                          <span className="headline-3">{acceptedFiles[0].path}</span>
                        )
                          : (
                            <span className="headline-3">Seleccione un archivo Pdf</span>
                          )}
                      </div>
                    </div>

                    {documentCorrect === 2 ? (
                      <span className="text-danger headline-2">
                        Ingrese un documento valido
                      </span>
                    ) : documentCorrect === 1 ? (
                      <span className="text-secondary headline-2">
                        Documento valido {urlDocument !== "" && "(El documento anterior sera remplazado)"}
                      </span>
                    ) :
                      (
                        <span className="text-primary headline-2">
                          Se omitira la insercion del documento
                        </span>
                      )}
                  </div>
                </Col>

              }


            </Row>

            <Row className="d-flex flex-column justify-content-around align-items-center mt-3 mb-3">


            </Row>
            <Row>
              <Col
                className="d-flex justify-content-around mt-3"
                xs={12}
                md={6}
                lg={6}
              >

                {modify && <Button color="primary" onClick={() => setDocumentCorrect(3)}>
                  Borrar Documento
                </Button>}



              </Col>
              <Col
                className="d-flex justify-content-around mt-3"
                xs={12}
                md={6}
                lg={6}
              >

                {modify && <Button color="success" type="submit">
                  Guardar
                </Button>}




              </Col>
            </Row>

            <div className="row mt-3">

            </div>
          </form>
        </ModalBody>
      </Modal>

      {modalView && <ModalViewFile
        modal={modalView}
        setModal={setModalView}
        backdrop={true}
        keyboard={true}
        btnClose={true}
        fileLink={urlDocument.src}
      />}
    </>
  );
}
export default ModalAvances;

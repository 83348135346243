import { Row, Col, Button, Modal, ModalHeader, ModalBody, Table, Pagination, PaginationItem, PaginationLink } from "reactstrap"
import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as Yup from "yup";


import { CurrencyInput, FilesInput, SelectTypeHeadSingle, TextInput } from "../../../../components/GenericInputsFormik";
import sc from "../../../../components/TableComponent/TableComponentFiltro.module.scss"
import { NumberInput } from "../../../../components/GenericInputsFormik/NumberInput";
import Notification from "../../../../components/Notification/Notification";
import { peticionesReceiver } from "../../../../helpers/peticionesReceiver";
import ModalConfirmation from "../../../../components/Modal/ModalConfirmation";

import InputWithModal from "../../../../components/GenericInputsFormik/InputWithModal";
import s from "../../../tables/Tables.module.scss";
import Form from "../../bienes-muebles/Form";
import AddProvider from "./Providers";
import { CreateInventory } from "./functions";
import { useHistory } from "react-router";

const formatter = new Intl.NumberFormat("es-MX", {
    style: "currency",
    currency: "MXN",
});

export default function InfoBien() {
    const API = peticionesReceiver();
    const history = useHistory()

    const [multi, setMulti] = useState(false);
    const [bienes, setBienes] = useState([])
    const [reasons, setReasons] = useState([])

    const [modal, setModal] = useState(false);
    const [modalProvider, setModalProvider] = useState(false);
    const [status, setStatus] = useState([])
    let idBien = 0;

    //MODAL TABLE
    const filtroFurniture = [true, true];
    const filtroProvider = [true, true, true];
    const cabecerasFurniture = [
        "#",
        "Bien mueble",
    ];
    const cabecerasProvider = [
        "#",
        "RFC",
        "Razon social"
    ];
    const paramsFurniture = {
        action: "datatable",
        table: "furnitures",
        rows: "idFurniture,concat(nameFurniture,', MODELO: ',model,', MARCA: ',Brand) as furniture",
        conditions: "enabled = 1",
        page: 0,
        records: 5,
        search: "",
        order: "",
    };
    const paramsProvider = {
        action: "datatable",
        table: "providers",
        rows: "idProvider,itin,socialName",
        conditions: "enabled = 1",
        page: 0,
        records: 5,
        search: "",
        order: "",
    };

    async function getDataStatus() {
        const params = {
            action: "multiselect",
            table: "status",
            rows: "keyStat,nameStat",
            conditions: `enabled = 1 AND keyTypeStat=25`,
        }
        const res = await API.peticion(params)
        if (res.status === 200 && res.data.code === "200") {
            const data = res.data.data;
            setStatus(data);
        } else {
            toast(<Notification type={"consultar_error"} withIcon />);
        }
    }

    async function getDataRazones() {
        const params = {
            action: "multiselect",
            table: "reasonadd",
            rows: "keyReason,nameReason",
            conditions: `enabled = 1`,
        }
        const res = await API.peticion(params)
        if (res.status === 200 && res.data.code === "200") {
            const data = res.data.data;
            setReasons(data);
        } else {
            toast(<Notification type={"consultar_error"} withIcon />);
        }
    }

    useEffect(() => {
        getDataStatus();
        getDataRazones();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const onSuccess = () => {
        setModal(false)
    }

    const defaultForm = {
        idFurniture: "",
        furniture: "",
        keyStat: "",
        actualPrice: "",
        noBienes: "",
        serialkey: "",
    }

    const [xmlValido, setXMLValido] = useState(true)

    const defaultFormFact = {
        keyReason: "",
        idProvider: "",
        itin: "",
        socialName: "",
        xml: "",
        pdf: "",
        numberInvoice: "",
        taxNumber: ""
    }

    function addMueble() {
        const idFurniture = values.idFurniture;
        const furniture = values.furniture;
        const keyStat = values.keyStat;
        const actualPrice = values.actualPrice;
        if (values.noBienes === "") {
            const serialKey = values.serialkey;
            bienes.push({ id: idBien, idFurniture, furniture, keyStat, actualPrice, serialKey });
            idBien++;
        } else {
            const noBienes = Number(values.noBienes);
            for (let index = 0; index < noBienes; index++) {
                bienes.push({ id: idBien, idFurniture, furniture, keyStat, actualPrice, serialKey: "SIN SERIE" });
                idBien++;
            }
        }
        setAddedItems(true);
        resetForm();
    }

    const FormSchema = Yup.object().shape({
        idFurniture: Yup.number(),
        furniture: Yup.string().required("Seleccione un bien valido"),
        keyStat: Yup.number().required("Seleccione un estado valido"),
        actualPrice: Yup.number().required("Ingrese un monto valido"),
        noBienes: Yup.number(),
        serialkey: Yup.string().test(
            "isRequired",
            "El campo es obligatorio",
            function (item) {
                return this.parent.noBienes === "" ? true : this.parent.serialKey !== ""
            }
        )
    });

    const FormSchemaFact = Yup.object().shape({
        keyReason: Yup.number().required("Seleccione una razón valida"),
        idProvider: Yup.number(),
        socialName: Yup.string().required("Seleccione un proveedor valido"),
        xml: Yup.mixed()
            .test("file-type", "El documento debe ser de tipo XML", (value) => {
                const files = ["text/xml"]
                return value && files.includes(value.type);
            })
            .test({
                message: `El documento debe pesar menos de 4MB`,
                test: (value) => value?.size < 4000000,
            })
            .test({
                message: `Ingrese una factura correspondiente al proveedor`,
                test: () => xmlValido
            }),
        pdf: Yup.mixed()
            .test("file-type", "El documento debe ser de tipo PDF", (value) => {
                const files = ["application/pdf"]
                return value && files.includes(value.type);
            })
            .test({
                message: `El documento debe pesar menos de 4MB`,
                test: (value) => value?.size < 4000000,
            }),
        numberInvoice: Yup.string(),
        taxNumber: Yup.string()
    })

    const actionSelectBien = (el) => {
        setFieldValue(["idFurniture"], el[0]);
        setFieldValue(["furniture"], `${el[1]}`);
    }

    const onChange = (e) => {
        setFieldValue(e.target.name, e.target.value);
    };

    const { handleSubmit, values, handleBlur, errors, touched, setFieldValue, resetForm } =
        useFormik({
            initialValues: defaultForm,
            onSubmit: (values) => {
                addMueble();
            },
            validationSchema: FormSchema,
            enableReinitialize: true,
        });

    const onChangeFact = (e) => {
        setFieldValueFact(e.target.name, e.target.value);
    };

    const [modalConfirmation, setModalConfirmation] = useState(false)

    const formik =
        useFormik({
            initialValues: defaultFormFact,
            onSubmit: (values) => {
                setModalConfirmation(true)
            },
            validationSchema: FormSchemaFact,
            enableReinitialize: true,
        });

    const handleSubmitFact = formik.handleSubmit, valuesFact = formik.values, handleBlurFact = formik.handleBlur, errorsFact = formik.errors, touchedFact = formik.touched, setFieldValueFact = formik.setFieldValue, resetFormFact = formik.resetForm

    /* Limpiar el mueble */
    function clearBien() {
        resetForm();
    }

    /* Limpiar el mueble */
    function clearProvider() {
        resetFormFact();
        document.getElementById("pdf").value = ''
        document.getElementById("xml").value = ''
        setXMLValido(true);
    }

    function clearAll() {
        clearProvider()
        setBienes([])
        idBien = 0;
        clearBien()
    }

    const actionSelectProvider = (el) => {
        setFieldValueFact(["idProvider"], el[0]);
        setFieldValueFact(["itin"], `${el[1]}`);
        setFieldValueFact(["socialName"], `${el[1]} - ${el[2]}`);
    }

    function onDelete(bien) {
        const tempBienes = bienes.filter(item => item.id !== bien.id);
        setBienes(tempBienes)
    }

    const [currentPage, setCurrentPage] = useState(0);
    const [itemPerPage, setItemsPerPage] = useState(5);
    const [offset, setOffset] = useState(0);
    const [currentPageItems, setCurrentPageItems] = useState([]);
    const [addedItems, setAddedItems] = useState(false);
    const [pagination, setPagination] = useState([])

    useEffect(() => {
        setCurrentPageItems(bienes.slice(offset, offset + itemPerPage));
        setOffset(currentPage * itemPerPage)
        const tempPages = Array.from(Array(Math.ceil(bienes.length / itemPerPage)).keys())
        setPagination(tempPages.slice(currentPage > 5 ? currentPage : 0, 5));
    }, [currentPage, itemPerPage, offset, bienes, addedItems])

    useEffect(() => {
        if (addedItems) {
            setCurrentPageItems(bienes.slice(offset, offset + itemPerPage));
            setAddedItems(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [addedItems])

    function RenderRow({ bien, index }) {
        const [serial, setSerial] = useState()

        useEffect(() => {
            setSerial(bien.serialKey);
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [])

        function setSerie(e, bien) {
            bien.serialKey = e.target.value
            setSerial(e.target.value)
        }

        return (
            <tr key={"C" + index}>
                <td
                    className="text-center"
                    style={{ whiteSpace: "pre-line" }}
                >
                    {bien.furniture}
                </td>
                <td
                    className="text-center"
                    style={{ whiteSpace: "pre-line" }}
                >
                    <input
                        id={`SK${index}`}
                        value={serial}
                        className={"form-control"}
                        autoComplete="off"
                        onChange={(e) => setSerie(e, bien)}
                    />
                </td>
                <td
                    className="text-center"
                    style={{ whiteSpace: "pre-line" }}
                >
                    {formatter.format(bien.actualPrice)}
                </td>
                <td
                    className="text-center"
                    style={{ whiteSpace: "pre-line" }}
                >
                    {status.find(stat => stat.keyStat === bien.keyStat)?.nameStat}
                </td>
                <td
                    className="text-center"
                    style={{ whiteSpace: "pre-line" }}
                >
                    <i className="fa fa-trash text-danger" style={{ cursor: "pointer" }} onClick={() => onDelete(bien)} />
                </td>
            </tr>
        )
    }

    const getRFC = (att) => {
        try {
            return att.getNamedItem("Rfc").value
        } catch (err) {
            return att.getNamedItem("rfc").value
        }
    }

    const getFolio = (att) => {
        try {
            return att.getNamedItem("Folio").value
        } catch (err) {
            return att.getNamedItem("folio").value
        }
    }

    const handleFiles = (event) => {
        try {
            const { files, name } = event.target
            const file = files[0];
            if (name === "xml") {
                setFieldValueFact([name], file);
                const reader = new FileReader();
                reader.readAsText(file);
                reader.onload = () => {
                    try {
                        const xmlDoc = new DOMParser().parseFromString(reader.result, "text/xml");
                        const value = xmlDoc.getElementsByTagName("cfdi:Comprobante")[0];
                        const childrens = [...value.children]
                        const emisor = childrens.filter(item => item.nodeName === "cfdi:Emisor")[0];
                        const rfc = getRFC(emisor.attributes);
                        setXMLValido(valuesFact.itin === rfc.toUpperCase())
                        if (valuesFact.itin === rfc.toUpperCase()) {
                            const complemento = childrens.filter(item => item.nodeName === "cfdi:Complemento")[0];
                            setFieldValueFact(["numberInvoice"], complemento.firstChild.attributes.getNamedItem("UUID").value);
                            setFieldValueFact(["taxNumber"], getFolio(value.attributes));
                        }
                    } catch (err) {
                        setXMLValido(false)
                    }
                };
            } else {
                setFieldValueFact(name, file);
            }
        } catch (err) {
            setXMLValido(false)
        }
    }

    return (
        <>
            <div className="border-bottom border-primary text-center mb-3">
                <h2>Información del bien</h2>
            </div>
            <form onSubmit={handleSubmit} noValidate>
                <Row className="align-items-end">
                    <Col xs={12} md={8}>
                        <InputWithModal
                            cabeceras={cabecerasFurniture}
                            error={errors.furniture}
                            filtro={filtroFurniture}
                            handleBlur={handleBlur}
                            onChange={onChange}
                            actionSelect={actionSelectBien}
                            onClear={clearBien}
                            parametros={paramsFurniture}
                            touched={touched.furniture}
                            value={values.furniture}
                            label="Bien"
                            name={"furniture"}
                            title={"Agregar bien"}
                        />
                    </Col>
                    <Col xs={12} md={4}>
                        <Button className="mb-3" color="add" onClick={() => setModal(true)}>
                            Agregar Bien
                        </Button>
                    </Col>
                </Row>
                {values.idFurniture !== "" ?
                    <>
                        <div className="text-center my-3">
                            <h3>Datos del bien</h3>
                        </div>

                        <Row className="align-items-baseline">
                            <Col xs={12} md={4}>
                                <SelectTypeHeadSingle
                                    label="Estado del bien"
                                    inputName="keyStat"
                                    onChangeMethod={onChange}
                                    value={values.keyStat}
                                    optionsArray={status}
                                    optionValue="keyStat"
                                    optionName="nameStat"
                                    isRequired
                                />
                            </Col>
                            <Col xs={12} md={4}>
                                <CurrencyInput
                                    label="Costo del bien"
                                    inputName="actualPrice"
                                    onChangeMethod={(value, name) => onChange({ target: { name: name, value: value } })}
                                    onBlurMethod={handleBlur}
                                    value={values.actualPrice}
                                    isRequired
                                    touched={touched.actualPrice}
                                    errors={errors.actualPrice}
                                />
                            </Col>
                            <Col xs={12} md={4}>
                                <label>
                                    Registro de multiples bienes<span className="text-danger">*</span>
                                </label>
                                <div className="form-group form-check">
                                    <input type="checkbox" className="form-check-input" id="multReg" checked={multi} onClick={() => setMulti(!multi)} style={{ width: "38px", height: "38px" }} />
                                    <label className="form-check-label" for="multReg" style={{ marginTop: "10px", marginLeft: "25px", fontSize: "12pt" }}>Registrar más de un bien</label>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            {!multi ? (
                                <Col xs={12} md={6} className="mx-auto">
                                    <TextInput
                                        label="Numero de serie"
                                        inputName="serialkey"
                                        onChangeMethod={onChange}
                                        onBlurMethod={handleBlur}
                                        value={values.serialkey}
                                        isRequired
                                        touched={touched.serialkey}
                                        errors={errors.serialkey}
                                    />
                                </Col>
                            ) : (
                                <Col xs={12} md={6} className="mx-auto">
                                    <NumberInput
                                        label="Ingrese el numero de bienes a registrar"
                                        inputName="noBienes"
                                        onChangeMethod={onChange}
                                        onBlurMethod={handleBlur}
                                        value={values.noBienes}
                                        isRequired
                                        touched={touched.noBienes}
                                        errors={errors.noBienes}
                                    />
                                </Col>
                            )}
                        </Row>
                        <div className="text-center mb-3">
                            <Button color="add" type="submit">Añadir</Button>
                        </div>
                    </>
                    : null}
                <div className="text-center my-3">
                    <h3>Resumen</h3>
                </div>
                <Table
                    className={`table-striped table-borderless table-hover ${s.statesTable}`}
                    responsive
                >
                    <thead className="border-bottom border-secondary">
                        <tr className={sc.userSelectNone}>
                            <th className="text-center">
                                Bien
                            </th>
                            <th className="text-center">
                                Serie
                            </th>
                            <th className="text-center">
                                Costo
                            </th>
                            <th className="text-center">
                                Estado
                            </th>
                            <th className="text-center">
                                Eliminar
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {currentPageItems.length === 0 ? (
                            <tr key={"nothingSize"}>
                                <td
                                    colSpan={5}
                                    className="text-center"
                                >
                                    Sin registros
                                </td>
                            </tr>
                        ) : (
                            <>
                                {currentPageItems.map((bien, index) => (
                                    <RenderRow bien={bien} index={index} />
                                ))}
                            </>
                        )}
                    </tbody>
                </Table>
                <div className="row mt-3">
                    <div className="col-12 col-sm-6 d-flex justify-content-around mt-3">
                        <div>
                            Mostrando{" "}
                            <span className="text-primary">
                                {currentPageItems.length}
                            </span>{" "}
                            a{" "}
                            <span className="text-primary">
                                {itemPerPage * (currentPage + 1) <= bienes.length
                                    ? itemPerPage * (currentPage + 1)
                                    : bienes.length}{" "}
                            </span>
                            de {bienes.length} registros
                        </div>
                        <select
                            className="form-control form-control-sm"
                            style={{ width: "78px" }}
                            value={itemPerPage}
                            onChange={(e) => setItemsPerPage(e.currentTarget.value)}
                        >
                            <option value={5} key={"op5pg"}>
                                5
                            </option>
                            <option value={10} key={"op10pg"}>
                                10
                            </option>
                            <option value={25} key={"op25pg"}>
                                25
                            </option>
                            <option value={50} key={"op50pg"}>
                                50
                            </option>
                            <option value={100} key={"op100pg"}>
                                100
                            </option>
                        </select>
                    </div>
                    <div className="col-12 col-sm-6 d-flex justify-content-center justify-content-sm-end">
                        <Pagination
                            className="pagination-borderless d-flex mt-3 "
                            aria-label="Page navigation example"
                        >
                            <PaginationItem disabled={currentPage <= 0}>
                                <PaginationLink
                                    onClick={() =>
                                        setCurrentPage(currentPage - 1)
                                    }

                                    previous
                                />
                            </PaginationItem>
                            {pagination.map((page, i) => (
                                <PaginationItem
                                    key={i}
                                    disabled={
                                        page === currentPage
                                    }
                                >
                                    <PaginationLink
                                        onClick={() =>
                                            setCurrentPage(page)
                                        }
                                        className={
                                            page === currentPage
                                                ? "text-primary text-bold"
                                                : "text-secondary"
                                        }
                                    >
                                        {page + 1}
                                    </PaginationLink>
                                </PaginationItem>
                            ))}
                            <PaginationItem
                                disabled={currentPage >= Math.ceil(bienes.length / itemPerPage)}
                            >
                                <PaginationLink
                                    onClick={() =>
                                        setCurrentPage(currentPage + 1)
                                    }
                                    next
                                />
                            </PaginationItem>
                        </Pagination>
                    </div>
                </div>
            </form>
            {bienes.length > 0 ? (
                <form onSubmit={handleSubmitFact}>
                    <div className="text-center my-3 border-bottom border-primary">
                        <h3>Facturación</h3>
                    </div>
                    <Row className="align-items-end">
                        <Col xs={12} md={5}>
                            <SelectTypeHeadSingle
                                label="Motivo alta"
                                inputName="keyReason"
                                onChangeMethod={onChangeFact}
                                value={valuesFact.keyReason}
                                optionsArray={reasons}
                                optionValue="keyReason"
                                optionName="nameReason"
                                isRequired
                            />
                        </Col>
                        <Col xs={12} md={5}>
                            <InputWithModal
                                cabeceras={cabecerasProvider}
                                error={errorsFact.socialName}
                                filtro={filtroProvider}
                                handleBlur={handleBlurFact}
                                onChange={onChangeFact}
                                actionSelect={actionSelectProvider}
                                onClear={clearProvider}
                                parametros={paramsProvider}
                                touched={touchedFact.socialName}
                                value={valuesFact.socialName}
                                label="Proveedor"
                                name={"socialName"}
                                title={"Seleccionar proveedor"}
                            />
                        </Col>
                        <Col xs={12} md={2}>
                            <Button className="mb-3" color="add" onClick={() => setModalProvider(true)}>
                                Nuevo proveedor
                            </Button>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} md={6}>
                            <FilesInput
                                label="Factura PDF"
                                inputName="pdf"
                                onChangeMethod={handleFiles}
                                onBlurMethod={handleBlurFact}
                                fileAccept=".pdf"
                                isRequired
                                touched={touchedFact.pdf}
                                errors={errorsFact.pdf}
                                isDisabled={!valuesFact.idProvider && valuesFact.idProvider === ""}
                            />
                        </Col>
                        <Col xs={12} md={6}>
                            <FilesInput
                                label="Factura XML"
                                inputName="xml"
                                onChangeMethod={handleFiles}
                                onBlurMethod={handleBlurFact}
                                fileAccept=".xml"
                                isRequired
                                touched={touchedFact.xml}
                                errors={errorsFact.xml}
                                isDisabled={!valuesFact.idProvider && valuesFact.idProvider === ""}
                            />
                        </Col>
                    </Row>
                    <div className="text-center mt-3">
                        <Button color="add" className="mx-md-2" type="submit">Guardar</Button>
                    </div>
                </form>
            ) : null}
            <div className="text-center mt-3">
                <Button color="danger" className="mx-md-2" onClick={() => history.push("/template/inventarios")}>Cancelar</Button>
            </div>
            <Modal isOpen={modal} toggle={() => setModal(false)} fullscreen="lg" size="xl" backdrop="static" >
                <ModalHeader toggle={() => setModal(false)}>Agregar nuevo bien mueble</ModalHeader>
                <ModalBody>
                    <Form OnSuccess={onSuccess} OnCancel={onSuccess} />
                </ModalBody>
            </Modal>
            <Modal isOpen={modalProvider} toggle={() => setModalProvider(false)} fullscreen="lg" size="xl" backdrop="static" >
                <ModalHeader toggle={() => setModalProvider(false)}>Agregar nuevo proveedor</ModalHeader>
                <ModalBody>
                    <AddProvider close={() => setModalProvider(false)} />
                </ModalBody>
            </Modal>
            <ModalConfirmation
                modalTitle={"Agregar inventario"}
                modal={modalConfirmation}
                crear={() => CreateInventory(valuesFact, bienes, clearAll)}
                setModal={setModalConfirmation}
                values={valuesFact}
            >
                <div className="d-flex justify-content-center">
                    <h6>¿Desea continuar con el registro?</h6>
                </div>
            </ModalConfirmation>
        </>
    )
}
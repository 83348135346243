import { Document, Page, Text, View, Image } from "@react-pdf/renderer";
import { styles } from "../../../../../components/Pdf/pdfGeneral/EstiloPdf";
import HeaderReport from "../../../../../components/Pdf/Header";

const formatter = new Intl.NumberFormat("es-MX", {
  style: "currency",
  currency: "MXN",
});

function formatDate(date) {
  return date.split("-").reverse().toString().replaceAll(",", "/");
}

const typeHeaderCell = (tam, index) => {
  if (index === 0) {
    return tam === 100
      ? styles.cellHeader100Borders
      : tam === 75
      ? styles.cellHeader75Borders
      : tam === 50
      ? styles.cellHeader50Borders
      : tam === 25
      ? styles.cellHeader25Borders
      : tam === 11
      ? styles.cellHeader11Borders
      : "";
  } else {
    return tam === 100
      ? styles.cellContent100LBR
      : tam === 88.7
      ? styles.cellContent88_7BordersLBR
      : tam === 75
      ? styles.cellHeader75BordersRTB
      : tam === 50
      ? styles.cellHeader50BordersRTB
      : tam === 25
      ? styles.cellHeader25BordersRTB
      : tam === 11
      ? styles.cellHeader11BordersRTB
      : "";
  }
};

const insertHeaders = (model) => {
  return (
    <>
      <View style={styles.tableRow} fixed>
        {model.headers.map((obj, index) => (
          <View style={[typeHeaderCell(25, index), { fontSize: 8 }]}>
            <Text>{obj}</Text>
          </View>
        ))}
      </View>
    </>
  );
};

const insertContent = (data) => {
  return (
    <>
      {data.map((obj) => (
        <View style={[styles.tableRow, { height: 50 }]} wrap={false}>
          <View style={[styles.cellContent25BordersLBR, { fontSize: 8 }]}>
            <Text>{obj.year}</Text>
          </View>
          <View style={[styles.cellContent25BordersRB, { fontSize: 8 }]}>
            <Text>{obj.amountYear}</Text>
          </View>
          <View style={[styles.cellContent25BordersRB, { fontSize: 8 }]}>
            <Text>{obj.beweeklys}</Text>
          </View>
          <View style={[styles.cellContent25BordersRB, { fontSize: 8 }]}>
            <Text>{formatter.format(obj.mount)}</Text>
          </View>
        </View>
      ))}
    </>
  );
};

const insertTitles = (model, title) => {
  return (
    <>
      <HeaderReport />
      <View>
        <Text style={styles.title}>{title}</Text>
      </View>
      <View>
        <Text style={styles.subTitle}>
          {model.filters !== undefined
            ? model.filters.onlyMessage === true
              ? model.filters.message
              : ""
            : ""}
        </Text>
      </View>
    </>
  );
};

const insertFooter = (fileNo) => {
  return (
    <>
      <View fixed style={styles.spaceFooter}></View>
      <View fixed style={styles.footer}>
        <Text style={styles.textFooter}>{fileNo}</Text>
        <Text
          style={styles.textFooter}
          render={({ pageNumber, totalPages }) =>
            `PAGINA : ${pageNumber} / ${totalPages}`
          }
        />
      </View>
    </>
  );
};

const insertStaffInfo = (model) => {
  
  return (
    <>
      <View style={[styles.tableRow, { height: 100,marginBottom: 10 }]} wrap={false}>
        <View
          style={[
            styles.cellContent25BordersLBR,
            { fontSize: 6, height: 100, borderTop: 1, width: "50%" },
          ]}
        >
          <Image
            source={{ uri: model.staffInfo.src }}
            /* style={{
              minWidth: "100%",
              width: "100%",
              maxWidth: "100%",
              height:"100%"
            }} */
          />
        </View>
        <View
          style={[
            /* styles.cellContent25BordersRB, */
            { borderBottom:1,borderRight:1,fontSize: 7, borderTop: 1, height: 100, width: "100%",textAlign:"left", display:"flex", flexDirection: "row", justifyContent:"space-around" },
          ]}
        >

          <View style={{display:"flex", flexDirection: "column"}}>
          <Text style={{ marginTop:15}}><Text style={styles.textBold}>NUMERO DE EMPLEADO:</Text>{model.staffInfo.staffNumber}</Text>
          <Text style={{ marginTop:15}}><Text style={styles.textBold}>NOMBRE:</Text>{model.staffInfo.name}</Text>
          <Text style={{ marginTop:15}}><Text style={styles.textBold}>PUESTO:</Text>{model.staffInfo.nameJob}</Text>
            

          </View>
          <View style={{display:"flex", flexDirection: "column"}}>
          <Text style={{ marginTop:15}}><Text style={styles.textBold}>AREA:</Text>{model.staffInfo.nameArea}</Text>
          <Text style={{ marginTop:15}}><Text style={styles.textBold}>ESTATUS:</Text>{model.staffInfo.nameStatStaff}</Text>
            
          </View>

        </View>
        {/* <View style={[styles.cellContent25BordersRB,{fontSize:6,borderTop:1}]}>
          <Text>{model.staffInfo.name}</Text>
          </View>
          <View style={[styles.cellContent25BordersRB,{fontSize:6,borderTop:1}]}>
          <Text>{model.staffInfo.nameJob}</Text>
          </View>
          <View style={[styles.cellContent25BordersRB,{fontSize:6,borderTop:1}]}>
          <Text>{model.staffInfo.nameArea}</Text>
            <Text>{model.staffInfo.status}</Text>
          </View> */}
      </View>
    </>
  );
};

const generateReport = (data, model, title, fileNo) => {
  return (
    <Document>
      <Page style={styles.body}>
        <View style={styles.tableCell}>
          {insertTitles(model, title)}

          {insertStaffInfo(model)}

          {insertHeaders(model)}
          {insertContent(data)}
          <View style={styles.tableRow}></View>
        </View>
        {insertFooter(fileNo)}
      </Page>
    </Document>
  );
};
export function generatePdfHistorialEmpleado(
  data,
  model,
  title,
  fileNo,
  acumNeto,
  acumAmount,
  final
) {
  const report = generateReport(
    data,
    model,
    title,
    fileNo,
    acumNeto,
    acumAmount,
    final
  );
  return {
    report: report,
  };
}

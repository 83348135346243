import { useState } from "react";
import { Col, Row, Table } from "reactstrap";
import FullFormLoader from "../../../../components/Loader/FullFormLoader";
import { useEffect } from "react";
import { doRequest } from "../../../../helpers/requests";
import { fMoney } from "../../../../libs/tools/format";

export default function ComprobantePagoRepHTML({data, cfg}){
    const [loading, setLoading] = useState(true);
    const [staff, setStaff] = useState({});
    const [percepciones, setPercepciones] = useState({});
    const [deducciones, setDeducciones] = useState({});
    
    useEffect(() => {
        if(data){
            getJobStaffInfo();
            setPercepciones(filterData(data, 1));
            setDeducciones(filterData(data, 2));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    async function getJobStaffInfo(){
        const params = {
        action: "getStaffData",
        idStaff: data[0].idStaff,
        };
        const res = await doRequest("app/facades/reports/reportsF.php", params, false);
        if(res.length > 0){
            console.log(res[0])
            setStaff(res[0]);
        }
        setLoading(false); 
    }
  
    const filterData = (data, type) => {
        let dataF = data.filter((el) => +el.keyTypeUnder === type);
        let acum = dataF.reduce((acc, b) => acc + +b.amount, 0);
        return {tt: acum, data: dataF};
    };

    if (loading) {
        return <FullFormLoader show={loading} message={"Consultando datos , espere un momento"}/>
    }

    return(
        <div>
            <h2 className="text-secondary text-sm-center text-md-left">
                Comprobante de Pago
            </h2>

            <div>
                <Row className="mb-2">
                    <Col xs={12} md={4}>
                        <div>
                            <h6 className="mb-0">Nombre:</h6>
                            <p>{staff.name}</p>
                        </div>
                    </Col>
                    <Col xs={12} md={4}>
                        <div >
                            <h6 className="mb-0">Clave SP: </h6>
                            <p>{staff.staffNumber}</p>
                        </div>
                    </Col>
                    <Col xs={12} md={4}>
                        <div >
                            <h6 className="mb-0">{staff.nameBank} Deposito:</h6>
                            <p>{staff.cuenta}</p>
                        </div>
                    </Col>
                </Row>
                <Row className="mb-2">
                    <Col xs={12} md={4}>
                        <div >
                            <h6 className="mb-0">RFC:</h6>
                            <p>{staff.rfc}</p>
                        </div>
                    </Col>
                    <Col xs={12} md={4}>
                        <div >
                            <h6 className="mb-0">CURP: </h6>
                            <p>{staff.curp}</p>
                        </div>
                    </Col>
                    <Col xs={12} md={4}>
                        <div >
                            <h6 className="mb-0">ISSEMYM:</h6>
                            <p>{staff.socialSecurityKey}</p>
                        </div>
                    </Col>
                </Row>
                <Row className="mb-2">
                    <Col xs={12} md={4}>
                        <div >
                            <h6 className="mb-0">Centro de trabajo:</h6>
                            <p>{staff.nameBuilding}</p>
                        </div>
                    </Col>
                    <Col xs={12} md={4}>
                        <div >
                            <h6 className="mb-0">Lugar de Pago: </h6>
                            <p>{staff.nameRegionArea}</p>
                        </div>
                    </Col>
                    <Col xs={12} md={4}>
                        <div >
                            <h6 className="mb-0">Plaza:</h6>
                            <p>{staff.nameJob}</p>
                        </div>
                    </Col>
                </Row>
                <Row className="mb-2">
                    <Col xs={12} md={4}>
                        <div >
                            <h6 className="mb-0">Categoría:</h6>
                            <p>{staff.levelrange}</p>
                        </div>
                    </Col>
                    <Col xs={12} md={4}>
                        <div >
                            <h6 className="mb-0">Adscripción: </h6>
                            <p>{staff.nameArea}</p>
                        </div>
                    </Col>
                </Row>
                <Row className="mb-2">
                    <Col xs={12} md={4}>
                        <div >
                            <h6 className="mb-0">Total Percepción:</h6>
                            <p>{fMoney(percepciones.tt)}</p>
                        </div>
                    </Col>
                    <Col xs={12} md={4}>
                        <div >
                            <h6 className="mb-0">Total Deducción: </h6>
                            <p>{fMoney(deducciones.tt)}</p>
                        </div>
                    </Col>
                    <Col xs={12} md={4}>
                        <div >
                            <h6 className="mb-0">Neto:</h6>
                            <p>{fMoney(percepciones.tt - deducciones.tt)}</p>
                        </div>
                    </Col>
                </Row>
            </div>
            <hr />
            <div>
                <Row>
                    <Col xs={12} md={6}>
                        <TabConcepto info={percepciones} title="PERCEPCIONES" />
                    </Col>
                    <Col xs={12} md={6}>
                        <TabConcepto info={deducciones} title="DEDUCCIONES" />
                    </Col>
                </Row>
            </div>
        </div>
    )
}

function TabConcepto({ info, title }){
    return (
      <>
        <div className="text-center mt-2 border-bottom border-secondary"><h6>{title}</h6></div>
        <Table borderless responsive>
          <thead className="border-bottom border-2 border-secondary">
            <tr className="text-center">
              <th className="w-auto py-0">Clave</th>
              <th className="w-auto py-0">Concepto</th>
              <th className="w-auto py-0">Importe</th>
            </tr>
          </thead>
          <tbody>
            {info.data.map((d, i) => (
              <tr key={"R" + i}>
                <td className="text-center">{d.under}</td>
                <td>{d.nameUnder}</td>
                <td className="text-right">{fMoney(d.amount)}</td>
              </tr>
            ))}
            <tr className="border-top border-dark" style={{borderWidth: "2px"}}>
              <td className="text-center">TOTAL</td>
              <td colSpan={2} className="text-right">{fMoney(info.tt)}</td>
            </tr>
          </tbody>
        </Table>
      </>
    );
  };
import React, {useState} from "react";
import ModalTableVer from "../../../../components/Modal/ModalTableVer"
import { Row, Col, Container } from "reactstrap";
import { CancelOutlined } from "@material-ui/icons";

import s from "../../../nomina/reportes/filtros/InputClear.module.scss";

export default function InputEmployee({paramsModalTable, selectEmployee, employee, setEmployee, title, setParametros, parametros, setData, setIsOpenForm}) {
    const [modalTable, setModalTable] = useState(false);
    const filtroModalTabla = [true, true, true, true, true];
    const cabecerasModalTabla = [
        "#",
        "Núm. Empleado",
        "Nombre",
        "Unidad de trabajo",
        "Puesto",
        "Seleccionar",
    ];

    function onSelect(value) {
        setData(null)
        selectEmployee(value)
        setModalTable(false);
        setIsOpenForm(false);
    }

    function clearEmployee() {
        setEmployee(null)
        setParametros(parametros)
        setData(null)
        setIsOpenForm(false);
    }

    return (
        <>
            <Container>
                <Row>
                    <Col xs={12}>
                        <div className="form-group position-relative">
                            <label htmlFor="nameStaff">
                                { title }
                            </label>
                            <div className="input-group">
                                <input
                                    className="form-control"
                                    value={employee ? employee[2] : ""}
                                    disabled
                                />
                                <div className="input-group-append">
                                    <button
                                        className="btn btn-secondary"
                                        style={{ zIndex: "auto" }}
                                        type="button"
                                        onClick={() => setModalTable(true)}
                                    >
                                        <i className="fa fa-search" />
                                    </button>
                                </div>
                            </div>
                            {employee ? (
                                <CancelOutlined
                                className={s.btnClear}
                                onClick={() => clearEmployee()}
                                />
                            ): null}
                        </div>
                    </Col>
                </Row>
            </Container>
            <ModalTableVer
                modal={modalTable}
                setModal={setModalTable}
                title="Empleados"
                cabeceras={cabecerasModalTabla}
                filtro={filtroModalTabla}
                parametros={paramsModalTable}
                selectRegistro={onSelect}
                keyboard={false}
                btnClose
            />
        </>
    )
}
import React, { useState } from "react";
import {
  Tooltip,
} from 'reactstrap';
import style from "./Tooltip.module.scss";

export default function CustomTooltip(props) {

  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);
  return (
    <main className='mr-3 mb-3'>
      <span id={props.idTooltip} className = {style.buttonInfo}>
        <i className={`eva eva-info ${style.icon}`}/>
      </span>
      <Tooltip
        placement={props.placement || 'top'}
        isOpen={tooltipOpen}
        target={props.idTooltip}
        toggle={toggle}
      >
        {props.text}
      </Tooltip>
    </main>
  )
}

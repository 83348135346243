import React, { useState, useEffect } from "react";
import { StyleSheet } from "@react-pdf/renderer";
import { Col, Row, Button } from "reactstrap";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as Yup from "yup";

import Notification from "../../../components/Notification/Notification";
import Widget from "../../../components/Widget/Widget";

import { SelectTypeHeadSingle } from "../../../components/GenericInputsFormik/index";
import { peticionesReceiver } from "../../../helpers/peticionesReceiver";


import ModalReports from "../../../components/Modal/ModalReports";
import TableComponent from "../../../components/TableComponent/TableComponentReport";
import { setConfigR23Details } from "../../../libs/reports/finanzas/report23";
import exportGeneral from "./pdf/pdfGeneral";
import { saveGeneralReport } from "../../../libs/reports/general/excelGeneral";

export default function DetallesReporte (){
  const API = peticionesReceiver();
  const config = JSON.parse(localStorage.getItem("detailsRow"));
  const titleRep = config.title || "Error";

  //Variables de modalTabla
  const [modalReports, setModalReports] = useState(false);

  const [defaultForm, setDefaultForm] = useState({
    idFormat: "",
  });

  /* Parametros para enviar a los endPoint */
  const [data, setData] = useState("");
  /* Variable donde se guarda la informacion del reporte */
  const [report, setReport] = useState();
  /**
   *  Variable para decidir tipo de formato
   */
  const [showReportFormat, setShowReportFormat] = useState("0");

  /**
   * Configuración del PDF
   */
  const [pdfConfig, setPdfConfig] = useState("");
  /**
   * Configuración del excel
   */
  const [excelConfig, setExcelConfig] = useState("");
  /**
   * Configuración del html
   */
  const [htmlConfig, setHtmlConfig] = useState("");
  /**
   * Cabeceras de los documentos
   */
  const [headers, setHeaders] = useState([]);

  /**
   * Titulo del documento
   */
  const [title, setTitle] = useState("");

  /**
   * Opciones disponibles para el select del formato
   */
  const format = [
    {
      id: 1,
      value: "PDF"},
    {
      id: 2,
      value: "Hoja de calculo"},
    {
      id: 3,
      value: "Html" }
  ];

  /**
   * Consulta del reporte especificado
   * @param {*} params Objeto especificado del endpoint
   */
  const getReport = (params, route) => {
    API.peticionEndPoint(params, !route ? "app/facades/reports/reportsF.php" : route)
      .then((res) => {
        if (res.status === 200 && res.data.code === "200") {
          setReport(res.data.data);
        } else {
          toast(
            <Notification
              type={"consultar_error"}
              backMessage={res.data.message}
              withIcon
            />,
            { closeButton: false }
          );
        }
      })
      .catch((err) => {
        toast(<Notification type={"consultar_servidor_error"} withIcon />, {
          closeButton: false,
        });
      });
  };

  /**
   * Establecimiento del form
   */
  const FormSchema = Yup.object().shape({
    idFormat: Yup.string().required("Seleccione un formato"),
  });

  /**
   * Se envia inmediatamente despues del submit
   * @param {*} filter Valores del form
   */
  const getData = () => {
    setReport(undefined);
    setTitle("");
    setShowReportFormat("0");
    //RECUPERAMOS Y HACEMOS QUE SEA CADENA
    const idReport = config.idReport;
    switch (idReport) {
      case "23":
        config.values.idUnder = config.data[0]
        setTitle("REPORTE DE RETENCIONES INSTITUCIONALES");
        setConfigR23Details({
          setHeaders,
          setPdfConfig,
          setExcelConfig,
          setHtmlConfig,
          setData,
          filter: config.values
        })
        break;
      default:
        break;
    }
  };

  /**
   * Formik const
   */
  const {
    handleSubmit,
    handleBlur,
    values,
    errors,
    touched,
    setFieldValue,
    resetForm,
  } = useFormik({
    initialValues: defaultForm,
    onSubmit: (values) => {
      getData();
    },
    validationSchema: FormSchema,
    enableReinitialize: true,
  });


  /**
   * Hook que detecta el cambio en data
   */
  useEffect(() => {
    if (data !== "") {
      const idFormat = values.idFormat.toString();
      if (idFormat === "1" || idFormat === "2") {
        const idReport = config.idReport;
        if (["23"].includes(idReport)) {
          getReport(data, "app/facades/reports/reportsFiTeF.php")
        }
      }
      setShowReportFormat(idFormat);
      setModalReports(true);
    }
  }, [data]);
  

  /**
   * Hook que detecta el cambio en la variable report y en caso
   * de ser el tipo de formato 2 genera un 
   */
  useEffect(() => {
    if (report !== undefined && showReportFormat === "2") {
      setShowReportFormat("0");
      const idReport = config.idReport;
      switch (idReport) {
        case "23":
          saveGeneralReport(report, title, data, excelConfig);
          break;
        default:
          break;
      }
    }
  }, [report]);

  /**
   * Con el valor del form decide el metodo del PDF a Generar
   * @returns Metodo a ocupar para generar PDF
   */
    function getPDFMethod() {
        switch(config.idReport){
          case "23": {
            return {
              method: exportGeneral,
              type: "Retenciones"
            }
          }
          default:
            return null 
        }
    }

    /**
     * Si el param de showReport se encuentra en 1 o 3 y cumple con las condiciones
     * @returns Funcion que muestra el reporte de pdf o html
     */
    function showPDForHTML() {
        if (showReportFormat === "1" && report !== undefined && pdfConfig !== "") {
            return (
                <ModalReports
                modal={modalReports}
                setModal={setModalReports}
                title={title}
                backdrop={"static"}
                keyboard={false}
                report={report}
                pdfConfig={pdfConfig}
                generatePdfMethod={getPDFMethod()}
                records={1000}
                />
            )
        } else if (showReportFormat === "3" && data !== "" && title !== "" && htmlConfig !== "") {
            return (
                <TableComponent
                    titulo={title}
                    cabecerasTabla={getCabeceras()}
                    parametros={data}
                    filtro={htmlConfig}
                    reporte={"detail"}
                    route={["23"].includes(config.idReport) ? "app/facades/reports/reportsFiTeF.php" : null}
                />
            )
        } 
        return null
    }

    function getCabeceras() {
        return headers
    }

    const OnChangeSelect = event => {
        setFieldValue(event.target.name, event.target.value)
    };

  return (
    <>
      <Widget className="widget-p-md">
        <form onSubmit={handleSubmit} className="mt-4" noValidate>
          <div className="headline-1 text-center mb-4">Detalles del reporte</div>
          <div className="headline-1 text-center mb-4">{titleRep}</div>

          <Row className="d-flex justify-content-center">
            <Col xs={12} md={12} lg={5}>
              <SelectTypeHeadSingle
                label="Seleccione un formato"
                optionsArray={format}
                inputName="idFormat"
                onChangeMethod={OnChangeSelect}
                onBlurMethod={handleBlur}
                touched={touched.idFormat}
                errors={errors.idFormat}
                optionValue="id"
                optionName="value"
                value={values.idFormat}
                isRequired
                defaultOption="Seleccione un formato"
              />
            </Col>
          </Row>
            
          <div className="text-center my-3">
            <Button color="danger" className="mx-1" onClick={() => window.location.href = "#/template/reportes-finanzas/"}>
              Cancelar
            </Button>
            <Button color="success" className="mx-1" type="submit">
              Generar
            </Button>
          </div>
        </form>
            
        {showPDForHTML()}

      </Widget>
    </>
  )
}
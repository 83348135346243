import React, { useState } from "react";
import TableComponentFilesDateFilters from "../../../components/TableComponent/TableComponentFilesDateFilters";

export const ConsultarMovimientos = ({ idBankAccount,account, permissions }) => {
  const cabeceras = ["#","Fecha Registro","Concepto","Saldo Anterior","Cargo","Abono","Saldo Nuevo","Comprobante"]
  const filtro = [true,true,true,true,true,true,true,true]

  const [parametros] = useState({
    action: "datatable",
    table: 'bankaccountmoves a inner join bookcase b ON (a.idMove = b.reference and keyFileType in (10,11)) inner join files c on (c.idBook = b.idBook) inner join filetypes d on (d.keyFileType = b.keyFileType)',
    rows: 'idMove,DATE(dateMove) AS Date,description,FORMAT(startBalance,2) AS LastBalance,FORMAT(IF(startBalance > endBalance,ammount,0),2) AS Cargo,FORMAT(IF(startBalance < endBalance,ammount,0),2) AS Abono,FORMAT(endBalance,2) as CurrentBalance,src,nameFyleType',
    conditions:`a.enabled = 1 AND a.idBankAccount = ${idBankAccount}`,
    page:0,
    records:5,
    search:"",
    order:""
  })

  return (
    <div className="mt-4">
      <TableComponentFilesDateFilters
        titulo="Movimientos"
        cabecerasTabla={cabeceras}
        filtro={filtro}
        parametros={parametros}
        eliminar = {false}
        editar = {false}
        nameStartDate = 'dateMove'
        account = {account}
        permissions={permissions}
      />
    </div>
  );
};
export default ConsultarMovimientos;

import * as XlsxPopulate from "xlsx-populate";
import { saveAs } from "file-saver";
import axios from "axios";
import { toast } from "react-toastify";
import Notification from "../../../../components/Notification/Notification";
import { peticionEncript } from "../../../../helpers/peticionesEncripted";

const formatter = new Intl.NumberFormat("es-MX", {
  style: "currency",
  currency: "MXN",
});

const s = {
  tC: {horizontalAlignment: "center"},
  tS: {horizontalAlignment: "left"},
  tE: {horizontalAlignment: "right"},
  header: {horizontalAlignment: "center", fill: "AEAAAA", fontColor: "FFFFFF"},
  bT: {topBorder: true},
  bB: {bottomBorder: true},
  bBb: {bottomBorder: "medium"},
  ttl: {bottomBorder: "medium", fontSize: "14"},
  ttc: {bottomBorder: "medium", fontSize: "14", horizontalAlignment: "right"}
}

export async function resumenGlobalNomina(data, cfg) {
  const cntEmpl = await countEmployees();
  const cntPens = await countPensions();
  makeReport(data, cfg, cntEmpl,cntPens, cntPens);
}

async function countEmployees(){
  const params = {
    "action": "multiselect",
    "table": "staff S INNER JOIN jobstaff J ON J.idStaff = S.idStaff",
    "rows": "COUNT(S.idStaff) cant",
    "conditions": "S.enabled = 1 AND J.valid = 1 AND J.enabled = 1",
    "order": ""
  }
  const cant = await consulta(params);
  if(cant.length !== 0) return cant[0].cant;
  return 0;
}

async function countPensions(){
  const params = {
    "action": "multiselect",
    "table": "staff S INNER JOIN pensions P ON P.idStaff = S.idStaff INNER JOIN jobstaff J ON J.idStaff = S.idStaff ",
    "rows": "COUNT(S.idStaff) cant",
    "conditions": "S.enabled = 1 AND S.keyStat NOT IN (2, 7, 9, 10, 11, 12, 13) AND J.enabled = 1 AND J.valid = 1 AND P.enabled = 1",
    "order": ""
  }
  const cant = await consulta(params);
  if(cant.length !== 0) return cant[0].cant;
  return 0;
}

function makeReport(data, cfg, cntEmpl, cntPens){
  let maxR = data.depositos.totalRows > data.pensionesDeposito.totalRows ?
    +data.depositos.totalRows + 1 : +data.pensionesDeposito.totalRows + 1;
  const ban = {
    dep: +data.depositos.totalDepositos,
    dtp: +data.depositos.totalPersonas,
    che: +data.cheques.totalCheques,
    cche: +data.cheques.totalRows
  }
  const pen = {
    dep: +data.pensionesDeposito.totalPensiones,
    ptd: +data.pensionesDeposito.totalPersonas,
    che: +data.pensionesCheque.totalCheques,
    cche: +data.pensionesCheque.totalRows
  }

  XlsxPopulate.fromBlankAsync().then(async (workbook) => {
    const hoja = workbook.sheet(0);

    for (let i = 1; i < 12; i++) {
      if(i === 1 || i === 6 || i === 11) hoja.column(i).width(5.7);
      else hoja.column(i).width(11.43);
    }
    hoja.range("H2:K2").merged(true).value("RESUMEN GLOBAL DE NÓMINA").style(s.tC);
    hoja.range("H3:I3").merged(true).value("QUINCENA:").style(s.tC);
    hoja.cell("J3").value(cfg.period).style(s.tC);
    hoja.range("G4:I4").merged(true).value("SERVIDORES PÚBLICOS:").style(s.tE);
    hoja.range("J4:K4").merged(true).value(cntEmpl).style(s.tE);
    hoja.range("G6:I6").merged(true).value("PENSIONES ALIMENTICIAS:").style(s.tE);
    hoja.range("J6:K6").merged(true).value(cntPens).style(s.tE);


    hoja.range("A8:K8").merged(true).value("IMPORTE A PAGAR").style(s.header);
    hoja.range("C9:E9").merged(true).value("SERVIDORES PUBLICOS").style(s.tE);
    hoja.range("H9:J9").merged(true).value("PENSIONES ALIMENTICIAS").style(s.tE);
    hoja.cell("B10").value("BANCO:");
    hoja.range("D10:E10").merged(true).value(money(ban.dep)).style(s.tE);
    hoja.range("I10:J10").merged(true).value(money(pen.dep)).style(s.tE);
    hoja.cell("B11").value("CHEQUE:");
    hoja.cell("C11").value(ban.cche).style(s.tE);
    hoja.range("D11:E11").merged(true).value(money(ban.che)).style(s.tE);
    hoja.cell("H11").value(pen.cche).style(s.tC);
    hoja.range("I11:J11").merged(true).value(money(pen.che)).style(s.tE);;
    hoja.row(12).height(10);
    hoja.range("C12:E12").merged(true).style(s.bBb);
    hoja.range("H12:J12").merged(true).style(s.bBb);
    hoja.row(13).height(21.5);
    hoja.range("C13:E13").merged(true).value(money(ban.dep + ban.che)).style(s.tE);
    hoja.range("H13:J13").merged(true).value(money(pen.dep + pen.che)).style(s.tE);
    hoja.range("G15:H15").merged(true).value("TOTAL NETO:").style(s.ttl);
    hoja.range("I15:J15").merged(true).value(money((ban.dep + ban.che) + (pen.dep + pen.che))).style(s.ttc);

    hoja.range("A17:K17").merged(true).value("DESGLOCE DE BANCOS").style(s.header);
    hoja.range("A18:E18").merged(true).value("SERVIDORES PUBLICOS").style(s.tC);
    hoja.range("G18:K18").merged(true).value("PENSIONES ALIMENTARIAS").style(s.tC);
    tabDepositos(hoja, 19, "B", "E", "C", "D", data.depositos);
    tabDepositos(hoja, 19, "G", "J", "H", "I", data.pensionesDeposito);
    maxR += 19;
    hoja.range(`A${maxR}:K${maxR}`).style(s.bT);
    hoja.cell(`B${maxR}`).value(data.depositos.totalPersonas).style(s.tC);
    hoja.range(`D${maxR}:E${maxR}`).merged(true)
      .value(money(data.depositos.totalDepositos)).style(s.tE);
    hoja.cell(`G${maxR}`).value(data.pensionesDeposito.totalPersonas).style(s.tC);
    hoja.range(`I${maxR}:J${maxR}`).merged(true)
      .value(money(pen.dep)).style(s.tE);

    maxR += 3;
    hoja.range(`B${maxR}:E${maxR}`).merged(true).value("TOTAL DE DEPOSITOS EN BANCOS");
    hoja.range(`G${maxR}:K${maxR}`).merged(true).value(money(ban.dep + pen.dep)).style(s.ttc);
    hoja.range(`A${maxR}:K${maxR}`).style(s.bB);
      
    return workbook.outputAsync().then((res) => {
      saveAs(res, "resumenGlobalNomina.xlsx");
    });
  });
}

function tabDepositos(hoja, row, lA, lB, rgA, rgB, d) {
  hoja.cell(`${lA}${row}`).value("Deposito").style(s.tC);
  hoja.range(`${rgA}${row}:${rgB}${row}`).merged(true).value("Banco").style(s.tC);
  hoja.cell(`${lB}${row}`).value("Importe").style(s.tC);

  d.data.forEach((e, i)=>{
    hoja.cell(`${lA}${row+i+1}`).value(e.totalStaff).style(s.tC);
    hoja.range(`${rgA}${row+i+1}:${rgB}${row+i+1}`).merged(true).value(e.nameBank);
    hoja.cell(`${lB}${row+i+1}`).value(money(e.total)).style(s.tE);
  });
}

function money(d){
  if(isNaN(+d)) return "$0.00"
  return formatter.format(d);
}

async function consulta(params) {
  const finalData = peticionEncript(params);
  let info = [];
  await axios
    .post(process.env.REACT_APP_API + "receiver/receiver.php", finalData)
    .then((res) => {
      if (res.status === 200 && res.data.code === "200") {
        info = res.data.data;
      }
    })
    .catch((err) => {
      toast(<Notification type={"consultar_servidor_error"} withIcon />, {
        closeButton: false,
      });
    });
  return info;
}

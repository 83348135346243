import React, { useEffect } from "react";
import { Col, CustomInput, Row } from "reactstrap";

export default function MesesSelect(props) {
  const { values, onChangeSwitchMonths } = props;
  return (
    <div className="mb-3">
      <Row className="d-flex justify-content-center mb-3">
        <Col xs={4} md={4}>
          Enero
        </Col>
        <CustomInput
          type="switch"
          id="month1"
          name="month1"
          onChange={onChangeSwitchMonths}
          checked={values.month1}
        />
      </Row>
      <Row className="d-flex justify-content-center mb-3">
        <Col xs={4} md={4}>
          Febrero
        </Col>
        <CustomInput
          type="switch"
          id="month2"
          name="month2"
          onChange={onChangeSwitchMonths}
          checked={values.month2}
        />
      </Row>
      <Row className="d-flex justify-content-center mb-3">
        <Col xs={4} md={4}>
          Marzo
        </Col>
        <CustomInput
          type="switch"
          id="month3"
          name="month3"
          onChange={onChangeSwitchMonths}
          checked={values.month3}
        />
      </Row>
      <Row className="d-flex justify-content-center mb-3">
        <Col xs={4} md={4}>
          Abril
        </Col>
        <CustomInput
          type="switch"
          id="month4m6"
          name="month4"
          onChange={onChangeSwitchMonths}
          checked={values.month4}
        />
      </Row>
      <Row className="d-flex justify-content-center mb-3">
        <Col xs={4} md={4}>
          Mayo
        </Col>
        <CustomInput
          type="switch"
          id="month5"
          name="month5"
          onChange={onChangeSwitchMonths}
          checked={values.month5}
        />
      </Row>
      <Row className="d-flex justify-content-center mb-3">
        <Col xs={4} md={4}>
          Junio
        </Col>
        <CustomInput
          type="switch"
          id="month6"
          name="month6"
          onChange={onChangeSwitchMonths}
          checked={values.month6}
        />
      </Row>
      <Row className="d-flex justify-content-center mb-3">
        <Col xs={4} md={4}>
          Julio
        </Col>
        <CustomInput
          type="switch"
          id="month7"
          name="month7"
          onChange={onChangeSwitchMonths}
          checked={values.month7}
        />
      </Row>
      <Row className="d-flex justify-content-center mb-3">
        <Col xs={4} md={4}>
          Agosto
        </Col>
        <CustomInput
          type="switch"
          id="month8"
          name="month8"
          onChange={onChangeSwitchMonths}
          checked={values.month8}
        />
      </Row>
      <Row className="d-flex justify-content-center mb-3">
        <Col xs={4} md={4}>
          Septiembre
        </Col>
        <CustomInput
          type="switch"
          id="month9"
          name="month9"
          onChange={onChangeSwitchMonths}
          checked={values.month9}
        />
      </Row>
      <Row className="d-flex justify-content-center mb-3">
        <Col xs={4} md={4}>
          Octubre
        </Col>
        <CustomInput
          type="switch"
          id="month10"
          name="month10"
          onChange={onChangeSwitchMonths}
          checked={values.month10}
        />
      </Row>
      <Row className="d-flex justify-content-center mb-3">
        <Col xs={4} md={4}>
          Noviembre
        </Col>
        <CustomInput
          type="switch"
          id="month11"
          name="month11"
          onChange={onChangeSwitchMonths}
          checked={values.month11}
        />
      </Row>
      <Row className="d-flex justify-content-center">
        <Col xs={4} md={4}>
          Diciembre
        </Col>
        <CustomInput
          type="switch"
          id="month12"
          name="month12"
          onChange={onChangeSwitchMonths}
          checked={values.month12}
        />
      </Row>
    </div>
  );
}

import { useEffect, useState } from "react";
import { Col, Row, Button, Spinner } from "reactstrap";
import TableComponentVer from "../../../components/TableComponent/TableComponentVer";
import {
  SelectTypeHeadSingle,
} from "../../../components/GenericInputsFormik";
import { YearInput } from "../../../components/GenericInputsFormik/YearInput";
import { peticionEncript } from "../../../helpers/peticionesEncripted";
import axios from "axios";
import { toast } from "react-toastify";
import Notification from "../../../components/Notification/Notification";

/* TABLE */
const cabecerasTabla = [
  "Año", // []
  "Fecha Inicio", //
  "Fecha Termino", //
  "Descripción del proyecto", // []
  "Objetivo", //
  "Estatus Finanzas", // []
  "Ver",
];
const filtroTabla = [false, true, true, true, true, true, true];


const date = new Date().getFullYear();

async function doRequest(route, params, alert) {
  let info = [];
  const dataEncrypted = peticionEncript(params);
  await axios
    .post(process.env.REACT_APP_API + route, dataEncrypted)
    .then((res) => {
      if (res.status === 200 && res.data.status === "success") {
        info = res.data.data;
        if (alert) {
          toast(
            <Notification
              type={"elimina_exito"}
              backMessage={res.data.message}
              withIcon
            />,
            {
              closeButton: false,
            }
          );
        }
      } else {
        toast(
          <Notification
            type={"consultar_error"}
            backMessage={res.data.message}
            withIcon
          />,
          { closeButton: false }
        );
      }
    })
    .catch((err) => {
      toast(<Notification type={"consultar_servidor_error"} withIcon />, {
        closeButton: false,
      });
    });
  return info;
}

const ConsultarProyectos = ({ idArea, setProyect }) => {
  const [loading, setLoading] = useState(true);
  const [year, setYear] = useState(new Date());
  const [area, setArea] = useState("");
  const [areas, setAreas] = useState([]);
  const [parametrosTabla, setParametrosTabla] = useState({
    action: "datatable",
    table: "proyects A INNER JOIN status B ON A.keyStat = B.keyStat",
    rows: "A.idProyect, A.yearProyect, A.startDate, A.endDate ,A.nameProyect, A.objetive, B.nameStat, A.keyStat, A.notes",
    conditions: `A.enabled = 1 AND A.keyStat = 67 AND A.yearProyect = ${date}`,
    page: 0,
    records: 5,
    search: "",
    order: "",
  });

  useEffect(() => {
    getAreas();
  }, []);

  /* Consulta areas*/
  const getAreas = async () => {
    const param = {
      action: "select",
      table: "areas",
    };
    const data = await doRequest("receiver/receiver.php", param);
    if (data.length > 0) setAreas(data);
    setLoading(false);
  };

  /* Funcion para guardar el id del proyecto seleccionado*/
  const openProyect = (el) => {
    setProyect({
      id: +el[0],
      dateS: el[2],
      dateE: el[3],
      name: el[4],
      objetive: el[5],
      notes:  el[8],
      keyStat:  +el[7],
      canEdit: +el[7] !== 67 && +el[7] !== 68
    });
  };

  const onChange = (e) => {
    if (e.target.name === "year") {
      if (e.target.value !== null) {
        setYear(e.target.value);
      } else {
        setYear(new Date());
      }
    } else if (e.target.name === "area") {
      setArea(e.target.value);
    }
  };

  useEffect(() => {
    let params = { ...parametrosTabla };
    if (area !== "" && year !== null) {
      params.conditions = `A.enabled = 1 AND A.keyStat = 67 AND A.yearProyect = ${year.getFullYear()} AND A.idArea = ${area}`;
    }else if(year !== null){
      params.conditions = `A.enabled = 1 AND A.keyStat = 67 AND A.yearProyect = ${year.getFullYear()}`;
    }else if(area !== null){
      params.conditions = `A.enabled = 1 AND A.keyStat = 67 AND A.idArea = ${area}`;
    }
    setParametrosTabla(params);
  }, [area, year]);

  if (loading)
    return (
      <div style={{ minHeight: "300px" }} className="text-center">
        <Spinner color="primary" style={{ marginTop: "135px" }} />
      </div>
    );

  return (
    <div className="mt-3">
      <div className="mb-4">
        <h4>Proyectos</h4>
        <p className="text-muted">Lista de proyectos</p>
      </div>

        <div  className="mb-5 border p-3 rounded">
          <p className="border-bottom mb-3 font-weight-bold">Filtro</p>
        <Row>
          <Col xs={12} lg={4}>
            <YearInput
              label="Año"
              inputName="year"
              onChangeMethod={onChange}
              value={year}
              isMin={date - 2}
              style={{}}
            />
          </Col>
          <Col xs={12} lg={8}>
            <SelectTypeHeadSingle
              label="Área administrativa"
              inputName="area"
              onChangeMethod={onChange}
              value={area}
              optionsArray={areas}
              optionValue="idArea"
              optionName="name"
              defaultOption="Seleccione una Área administrativa"
            />
          </Col>
        </Row>
        </div>

      <TableComponentVer
        cabecerasTabla={cabecerasTabla}
        filtro={filtroTabla}
        ver={openProyect}
        parametros={parametrosTabla}
      />
    </div>
  );
};

export default ConsultarProyectos;

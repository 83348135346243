import React, { useState, useEffect } from "react";
import { Table } from "reactstrap";
import sc from "../../../components/TableComponent/TableComponentFiltro.module.scss";
import ModalEdit from "./ModalEdit";
import ModalComponentEliminar from "../../../components/Modal/ModalDelete";
import { peticionesReceiver } from "../../../helpers/peticionesReceiver";
import Notification from "../../../components/Notification/Notification";
import { toast } from "react-toastify";
import Loader from "../../../components/Loader/Loader";
import Form from "./Form";

import { GetPermisos } from "../../../libs/permissions/hook";


const API = peticionesReceiver();
const VerificarSaldosNomina = () => {


  const [permissionsForm, setPermissionsForm] = useState("");

    GetPermisos(setPermissionsForm);





  const [report, setReport] = useState("");

  const [modal, setModal] = useState(false);
  const [objForEdit, setObjForEdit] = useState("");

  const loading = false;

  
  const [valuesReport, setvaluesReport] = useState("");
  const [params, setParams] = useState("");

  const [totalPerceptions, setTotalPerceptions] = useState(0);
  const [totalDeductions, setTotalDeductions] = useState(0);

  const formatter = new Intl.NumberFormat("es-MX", {
    style: "currency",
    currency: "MXN",
  });

  const handleEdit = (obj) => {
    setObjForEdit(obj);
    setModal(true);
    
  };

  const deleteCuenta = async (id) => {
    const params = {
      action: "delete",
      table: "payslips",
      condition: { idPayslip: id },
      force:1
    };
    API.peticion(params)
      .then((res) => {
        if (
          res.status === "success" ||
          res.status === "200" ||
          res.status === 200
        ) {
          toast(
            <Notification
              type={"agrega_exito"}
              backMessage={res.data.message}
              withIcon
            />
          );

          setvaluesReport({
            idStaff: valuesReport.idStaff,
            nameStafff: valuesReport.nameStafff,
            period: valuesReport.period,
            year: valuesReport.year,
          })
        } else {
          toast(
            <Notification
              type={"consultar_error"}
              backMessage={res.data.message}
              withIcon
            />
          );
        }
      })
      .catch((err) => {
        toast(<Notification type={"consultar_servidor_error"} withIcon />, {
          closeButton: false,
        });
      });
  };

  return (
    <div>

      {permissionsForm.CON ? (
        <>
        <Form
        setReport={setReport}
        valuesReport={valuesReport}
        setvaluesReport={setvaluesReport}
        setParams={setParams}
        setTotalPerceptions={setTotalPerceptions}
        setTotalDeductions={setTotalDeductions}
      />

      {report !== "" && report !== null && report !== undefined && (
        <Table
        className={`table-striped table-borderless table-hover `}
        responsive
      >
        <thead className="border-bottom border-secondary">
          <tr className={sc.userSelectNone}>
            <th className="text-center">Clave</th>
            <th className="text-center">Concepto</th>
            <th className="text-center">Percepciones</th>
            <th className="text-center">Deducciones</th>
            {permissionsForm.UPD && (<th className="text-center">Editar</th>)}
            {permissionsForm.DEL && (<th className="text-center">Eliminar</th>)}
            
            
          </tr>
        </thead>
        <tbody>
          {loading ? (
            <tr key={"loading"}>
              <td colSpan={6}>
                <Loader />
              </td>
            </tr>
          ) : report === "" ? (
            <tr key={"nothing"}>
              <td colSpan={6} className="text-center">
                No se encontraron registros
              </td>
            </tr>
          ) : (
            report.length !== "" &&
            report.map((registro, index) => (
              <tr key={"R" + index}>
                <td className="text-center">{registro.under}</td>
                <td className="text-left">{registro.nameUnder}</td>
                <td className={"text-right"}>
                  {registro.keyTypeUnder === "1"
                    ? formatter.format(registro.amount)
                    : "-"}
                </td>
                <td className={"text-right"}>
                  {registro.keyTypeUnder !== "1"
                    ? formatter.format(registro.amount)
                    : "-"}
                </td>

                {permissionsForm.UPD && (
                  <td
                  onClick={() => handleEdit(registro, "editar")}
                  className=" text-center"
                >
                  <i className={sc.handCursor + " fa fa-pencil text-warning"} />
                </td>
                )}


                {permissionsForm.DEL && (<td className="text-center">
                  <ModalComponentEliminar
                    modalTitle="Eliminar"
                    table={["payslips"]}
                    nameColumn={"idPayslip"}
                    elimina={deleteCuenta}
                    id={registro.idPayslip}
                  >
                    <h6>¿Seguro de eliminar ?</h6>
                    No podrá restaurar el registro
                  </ModalComponentEliminar>
                </td>)}

                

                
              </tr>
            ))
          )}
          {report !== "" && (
            <tr>
              <td colSpan={2} className="text-center">
                Total
              </td>
              <td className="text-right">
                {formatter.format(totalPerceptions)}
              </td>
              <td className="text-right">
                {formatter.format(totalDeductions)}
              </td>
              <td></td>
              <td></td>
            </tr>
          )}
          {report !== "" && (
            <tr>
              <td colSpan={2} className="text-center">
                Total neto
              </td>
              <td colSpan={2} className="text-right">
                {formatter.format(totalPerceptions - totalDeductions)}
              </td>
              <td></td>
              <td></td>
            </tr>
          )}
        </tbody>
      </Table>
      ) }
      <ModalEdit
        modal={modal}
        setModal={setModal}
        setReport={setReport}
        objForEdit={objForEdit}
        valuesReport={valuesReport}
        setvaluesReport={setvaluesReport}
      />
        </>

      ):(
        <div className="text-center p-4">
        No tienes permitido ver los registros de esta seccion
        </div>
      )}
      



      
    </div>
  );
};

export default VerificarSaldosNomina;


import React from "react";

import Widget from "../../../../components/Widget/Widget";
import FormPlazas from "../FormPlazas";
import { updateJobPosition } from "../functions";

export const EditarPlazaLaboral = (props) => {
  return (
    <Widget className="widget-p-md">
      <h3>Editar Plaza laboral</h3>
      <FormPlazas data={localStorage.getItem('dataContent').split('|-|')} editar={updateJobPosition}/>
    </Widget>
  )
}

export default EditarPlazaLaboral;
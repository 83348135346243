import React from "react";
import CustomTooltip from "../../components/CustomTooltip/CustomTooltip";
import { useState } from "react";

/**
 *
 * @param {Object} props
 * @param {string} props.label Etiqueta del input
 * @param {string} props.inputName Nombre e id del input
 * @param {Function} props.onChangeMethod Metodo que ocurre al cambiar el valor
 * @param {Function} props.onBlurMethod Metodo que ocurre al quitar el focus del input
 * @param {string} props.value Valor del metodo
 * @param {string} props.label Etiqueta del input
 * @param {boolean} props.isRequired Etiqueta del input
 * @param {string} props.touched Valor de visita del input
 * @param {string} props.errors Error encontrado en el input
 * @param {boolean} props.isDisabled Valor de deshabilitado del input
 * @param {string} props.isMin Valor minimo del input
 * @param {string} props.isMax Valor maximo del input
 * @param {string} props.isPlaceholder Texto de placeholder del input
 * @param {boolean} props.tooltip Valor de deshabilitado del texto de ayuda
 * @param {string} props.textTooltip Texto de ayuda
 * @returns
 */
export const TextInput = ({
  label,
  inputName,
  inputType,
  onChangeMethod,
  onBlurMethod,
  value,
  isRequired,
  touched,
  errors,
  isDisabled,
  isMin,
  isMax,
  isPlaceholder,
  tooltip,
  textTooltip,
  className
}) => {
  const [seePass, setseePass] = useState(false);

  const getTypeInput = () => {
    if (inputType !== "password") return inputType;
    return seePass ? "text" : "password";
  };

  return (
    <>
      <div className="form-group position-relative">
        <div className="d-flex">
          {label && <label htmlFor={inputName}>
            {label} {isRequired && <span className="text-danger">*</span>}
          </label>}
          {tooltip && (
            <CustomTooltip
              className="mr-3 mb-3"
              text={textTooltip}
              idTooltip={`tooltip-${inputName}`}
            />
          )}
        </div>
        <input
          name={inputName}
          type={getTypeInput()}
          id={inputName}
          onChange={onChangeMethod}
          onBlur={onBlurMethod}
          className={"form-control "+ (className || " ") + (errors && touched ? " is-invalid" : "")}
          value={value}
          // isrequired={isRequired ? "true" : "false"}
          disabled={isDisabled ? true : false}
          min={isMin !== undefined ? isMin : ""}
          max={isMax !== undefined ? isMax : ""}
          maxLength={isMax !== undefined ? isMax : ""}
          placeholder={isPlaceholder !== undefined ? isPlaceholder : ""}
        />
        {touched && errors && <span style={{ color: "red" }}>{errors}</span>}

        {/* Only with type = password */}
        {inputType === "password" && (
          <i
            className={
              "text-mutted " +
              (seePass ? " eva eva-eye-off-outline" : " eva eva-eye-outline")
            }
            style={{
              padding: "0px",
              cursor: "pointer",
              position: "absolute",
              top: "41px",
              right: touched && errors ? "35px" : "15px",
            }}
            onClick={() => setseePass(!seePass)}
          />
        )}
      </div>
    </>
  );
};

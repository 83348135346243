
import React from "react";

import Widget from "../../../../components/Widget/Widget";
import FormPensiones from "../FormPensiones";
import { updatePension } from "../functions";

export const EditarPension = (props) => {
  return (
    <Widget className="widget-p-md">
        <h3>Editar tipo de pensión</h3>
        <FormPensiones data={localStorage.getItem('dataContent').split('|-|')} editar={updatePension}/>
    </Widget>
  )
}

export default EditarPension;
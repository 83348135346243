// -- BIENES MUEBLES -- //
import BienesMuebles from "../../../pages/patrimonio/bienes-muebles/Principal";
import AgregarBienMueble from "../../../pages/patrimonio/bienes-muebles/Agregar/Agregar";
import EditarBienMueble from "../../../pages/patrimonio/bienes-muebles/Editar/Editar";
// -- BIENES INMUEBLES -- //
import BienesInmuebles from "../../../pages/patrimonio/bienes-inmuebles/Principal";
import AgregarInmueble from "../../../pages/patrimonio/bienes-inmuebles/acciones/Create";
import EditarInmueble from "../../../pages/patrimonio/bienes-inmuebles/acciones/Update";
// -- INVENTARIOS -- //
import PrincipalInventarios from "../../../pages/patrimonio/inventarios/Principal";
import AgregarInventario from "../../../pages/patrimonio/inventarios/Agregar/Agregar";
// --------- ADQUISICIONES ------------ //
/* -- Asignacion de vienes -- */
import AsignacionBienes from "../../../pages/patrimonio/asignacionBienes/AsignacionBienes";

/* -- Consulta de traspasos -- */
import Rotulacion from "../../../pages/patrimonio/rotulacion/Rotulacion";

const rutasPatrimonio = [
    //Bienes muebles
    { path: "/template/bienesmuebles", component: BienesMuebles },
    { path: "/template/bienesmuebles/crear", component: AgregarBienMueble },
    { path: "/template/bienesmuebles/editar", component: EditarBienMueble },
    //Bienes inmuebles
    { path: "/template/bienesinmuebles", component: BienesInmuebles },
    { path: "/template/bienesinmuebles/crear", component: AgregarInmueble },
    { path: "/template/bienesinmuebles/editar", component: EditarInmueble },
    //ASIGNACION DE BIENES
    { path: "/template/asignacion", component: AsignacionBienes },
    //Rotuacion
    { path: "/template/rotulacion", component: Rotulacion },
    //INVENTARIOS
    { path: "/template/inventarios", component: PrincipalInventarios },
    { path: "/template/inventarios/agregar", component: AgregarInventario },
]

export default rutasPatrimonio
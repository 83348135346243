import * as XlsxPopulate from "xlsx-populate";
import { saveAs } from "file-saver";
import { fDateTime, fMoney, fPercent } from "../../../../../libs/tools/format";

const s = {
  tC: {horizontalAlignment: "center", border: true},
  tS: {horizontalAlignment: "left", border: true},
  tE: {horizontalAlignment: "right", border: true},
  header: {horizontalAlignment: "center", fill: "AEAAAA", fontColor: "0000", bold: true, border: true, wrapText: true, verticalAlignment: "center" },
  bT: {topBorder: true},
  bB: {bottomBorder: true},
  bBb: {bottomBorder: "medium"},
  tt: { horizontalAlignment: "center", bold: true },
  ttS: {bold: true, border: true },
}
const headers = [
  "#",
  "Nivel",
  "Rango",
  "S. Base",
  "S. Bruto",
  "S. Neto",
  "Gratificación",
  "Despensa",
  "Viáticos",
  "Compensación",
  "Fortalecimiento",
  "Impuestos",
  "Issemym"
]

export async function SalariosDetalleExcel(data, cfg) {
  XlsxPopulate.fromBlankAsync().then(async (workbook) => {
    const hoja = workbook.sheet(0);
    hoja.range(`A2:I2`).merged(true).value("SAPP").style(s.tt);
    hoja.range(`A3:I3`).merged(true).value("Tabuladores Histórico - Detalle Salarios").style(s.tt);

    hoja.range(`A5:B5`).merged(true).value("Incremento Salarial").style(s.ttS);
    hoja.row(5).cell(3).value(cfg.idSalaryIncrease).style(s.tC);

    hoja.range(`A6:B6`).merged(true).value("Fecha Apliación").style(s.ttS);
    hoja.row(6).cell(3).value(fDateTime(cfg.fechaApliacion)).style(s.tC);

    headers.forEach((h, ix)=>{
      if(ix+1 < 4)hoja.column(ix+1).width(12);
      else hoja.column(ix+1).width(20);
      hoja.row(8).cell(ix+1).value(h).style(s.header);
    });

    data.forEach((d, ix)=>{
      hoja.row(9+ix).cell(1).value(ix+1).style(s.tC);
      hoja.row(9+ix).cell(2).value(d.level).style(s.tC);
      hoja.row(9+ix).cell(3).value(d.rango).style(s.tC);
      hoja.row(9+ix).cell(4).value(fMoney(d.sueldoBase)).style(s.tE);
      hoja.row(9+ix).cell(5).value(fMoney(d.sueldoBruto)).style(s.tE);
      hoja.row(9+ix).cell(6).value(fMoney(d.sueldoNeto)).style(s.tE);
      hoja.row(9+ix).cell(7).value(fMoney(d.gratificacion)).style(s.tE);
      hoja.row(9+ix).cell(8).value(fMoney(d.despensa)).style(s.tE);
      hoja.row(9+ix).cell(9).value(fMoney(d.viaticos)).style(s.tE);
      hoja.row(9+ix).cell(10).value(fMoney(d.compensacion)).style(s.tE);
      hoja.row(9+ix).cell(11).value(fMoney(d.fortalecimiento)).style(s.tE);
      hoja.row(9+ix).cell(12).value(fMoney(d.impuestos)).style(s.tE);
      hoja.row(9+ix).cell(13).value(fMoney(d.issemym)).style(s.tE);
    });
    
      
    return workbook.outputAsync().then((res) => {
      saveAs(res, `TabuladoresHistorico - Detalle Salarios ${cfg.idSalaryIncrease} ${fDateTime(cfg.fechaApliacion).replace("/","_")}.xlsx`);
    });
  });
}

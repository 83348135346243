import Widget from "../../../components/Widget/Widget";
import { useSelector } from "react-redux";
import { Button, Nav, NavItem, NavLink } from "reactstrap";
import { useEffect, useState } from "react";
import ConsultarProyectos from "./ConsultarProyectos";
import DocumentacionP from "../registro-proyectos/DocumentacionP";
import ActividadesP from "../registro-proyectos/ActividadesP";
import CostosP from "../registro-proyectos/CostosP";
import AprobarProyecto from "./AprobarProyecto";
import { GetPermissions } from "../../../libs/permissions/getPermissions";


const s = { fontSize: "18px", marginRight: "5px" };
const defaultProyect = {
  id: 0,
  name: "",
  objetive: "",
  keyStat: "",
  canEdit: true,
  notes: ""
}

const ValidacionProyectos = () => {
  const [permissions, setPermissions] = useState({});
  GetPermissions(setPermissions);

  const { profileType } = useSelector((state) => state.userInfo);
  const idArea = profileType.selectProfile.idArea;
  
  const [activeTab, setActiveTab] = useState(1);
  const [proyect, setProyect] = useState({...defaultProyect});

  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  useEffect(() => {
    if (proyect.id !== 0) toggleTab(2);
    else  toggleTab(1);
  }, [proyect]);

  return (
    <Widget className="widget-p-md">
      <Nav fill tabs style={{ overflowY: "hidden", overflowX: "auto" }}>
        <NavItem active={activeTab === 1}>
          <NavLink
            href="#"
            active={activeTab === 1}
            onClick={() => toggleTab(1)}
            disabled={proyect.id > 0}
          >
            <i className={"fa fa-cogs"} style={s} />
            Proyectos
          </NavLink>
        </NavItem>
        <NavItem active={activeTab === 2}>
          <NavLink
            href="#"
            active={activeTab === 2}
            onClick={() => toggleTab(2)}
            disabled={!proyect.id > 0}
          >
            <i className={"fa fa-file-text-o"} style={s} />
            Documentación
          </NavLink>
        </NavItem>
        <NavItem active={activeTab === 3}>
          <NavLink
            href="#"
            active={activeTab === 3}
            onClick={() => toggleTab(3)}
            disabled={!proyect.id > 0}
          >
            <i className={"fa fa-tasks"} style={s} />
            Actividades
          </NavLink>
        </NavItem>
        <NavItem active={activeTab === 4}>
          <NavLink
            href="#"
            active={activeTab === 4}
            onClick={() => toggleTab(4)}
            disabled={!proyect.id > 0}
          >
            <i className={"fa fa-money"} style={s} />
            Costo del proyecto
          </NavLink>
        </NavItem>
        <NavItem active={activeTab === 5}>
          <NavLink
            href="#"
            active={activeTab === 5}
            onClick={() => toggleTab(5)}
            disabled={!proyect.id > 0}
          >
            <i className={"fa fa-check-square-o"} style={s} />
            Aprobación del Proyecto
          </NavLink>
        </NavItem>
      </Nav>
      <div className="mt-2">
        {proyect.id !== 0 && (
          <div className="d-flex justify-content-end mb-2">
            <Button
              className="btn text-light px-2 py-1"
              style={{ backgroundColor: "#cc0a44", border: "none" }}
              onClick={() => setProyect({...defaultProyect})}
            >
              Cerrar
              <i className="fa fa-sign-out ml-2" style={{fontSize: "16px"}} />
            </Button>
          </div>
        )}

        {activeTab === 1 ? (
          <ConsultarProyectos setProyect={setProyect}/>
        ) : activeTab === 2 ? (
          <DocumentacionP proyectId={proyect.id} canEdit={false}/>
        ) : activeTab === 3 ? (
          <ActividadesP idArea={idArea} proyectId={proyect.id} canEdit={false} datesLimit={[proyect.dateS, proyect.dateE]}/>
        ) : activeTab === 4 ? (
          <CostosP proyect={proyect}/>
        ) : (
          <AprobarProyecto proyect={proyect} setProyect={setProyect} permissions={permissions}/>
        )}
      </div>
    </Widget>
  );
};

export default ValidacionProyectos;

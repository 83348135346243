import { useState } from "react";
import Datatable from "../../../components/Datatable/Datatable";
import { doRequestSaveRes } from "../../../helpers/requests";

const headers = ["Estatus", "Folio", "Origen", "Destino", "Descripción", "Detalle"];
const columnDefs = [
    {
        targets: [0, 1, 5],
        className: "text-center",
    },
    {
        orderable: false,
        targets: [5],
    }
];

const sAreas = {minWidth: "200px", maxWidth: "350px"}; 

export default function Consulta({permissions, idArea, setInfo}){
    const [refresh, setRefresh] = useState(false);
    
    const columns = [
        {data: "nameStat"},
        {data: "folio"},
        {data: "nAreaO", render:(d)=><div style={sAreas}>{d}</div>},
        {data: "nAreaD", render:(d)=><div style={sAreas}>{d}</div>},
        {data: "description", render:(d)=><div style={sAreas}>{d}</div>},
        {
            data: null, 
            render: (row)=> <i className="fa fa-eye text-info cursor-pointer" onClick={()=>{setInfo({id: row.idTransfer, name: row.description, stat: row.keyStat})}}/>
        }
    ]

    async function getData(ordCol, itemsPerPage, currentPage, search, multi) {
        const res = await doRequestSaveRes(
            "receiver/receiver.php",
            getParams(ordCol, itemsPerPage, currentPage, search, multi),
            false,
            false
        );
        if (res.code === "200") return res;
        return null;
    }

    function getParams(ordCol, itemsPerPage, currentPage, search) {
        const params = {
            action: "datatable",
            table: "warehousetransfers A INNER JOIN status B USING(keyStat) INNER JOIN areas C ON C.idArea = A.idAreaOrigin INNER JOIN areas D ON D.idArea = A.idAreaDestiny",
            rows: "B.nameStat, CONCAT(A.numberTransfer,'/',A.yearTransfer) AS folio, C.name AS nAreaO, D.name AS nAreaD, A.description, A.idTransfer, A.keyStat",
            conditions: `A.idAreaDestiny = ${idArea} AND A.enabled = 1`,
            order: ordCol,
            records: itemsPerPage.toString(),
            page: (itemsPerPage * currentPage).toString(),
            search
        };
        return params;
    }

    return(
        <div className="mt-2">
            {
                permissions.CON &&
                <Datatable
                    headers={headers}
                    columns={columns}
                    columnDefs={columnDefs}
                    petition={getData}
                    order={{ col: 1, opt: "asc" }}
                    control="back"
                    stateRefresh={[refresh, setRefresh]}
                    searching={true}
                    className="px-0 mt-4"
                />
            }
        </div>
    )
}
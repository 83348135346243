import { Formik, Form, Field, ErrorMessage, useFormikContext } from "formik";
import * as Yup from "yup";
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Button } from "reactstrap";
import ModalConfirmation from "../../../components/Modal/ModalConfirmation";

const defaultForm = {
  keyRegion: "",
  nameRegion: "",
};

const FormRegiones = ({ crear, data, editar }) => {
  const history = useHistory();
  const [form] = useState(defaultForm);
  const [modal, setModal] = useState(false);

  const FormSchema = Yup.object().shape({
    nameRegion: Yup.string()
      .min(4, "Nombre debe contener almenos 4 letras")
      .max(200, "El nombre ingresado es muy largo")
      .required("Ingrese un nombre de región"),
  });

  const submitFunction = (fields) => {
    setModal(true);
  };

  const cancelOp = () => {
    history.push("/template/regiones");
  };

  return (
    <Formik
      initialValues={form}
      validationSchema={FormSchema}
      onSubmit={(fields, { resetForm }) => {
        submitFunction(fields);
      }}
      onReset={(fields, { resetForm }) => {
        cancelOp();
      }}
    >
      {({ errors, status, touched, values }) => (
        <Form className="mt-4">
          <ModalConfirmation
            modalTitle={data ? "Editar" : "Crear"}
            modal={modal}
            setModal={setModal}
            crear={crear}
            editar={editar}
            isEdit={data ? true : false}
            values={values}
          >
            {data ? (
              <div className="d-flex justify-content-center">
                <h6>¿Está seguro de editar el registro?</h6>
              </div>
            ) : (
              <div className="d-flex justify-content-center">
                <h6>¿Desea continuar con el registro?</h6>
              </div>
            )}
          </ModalConfirmation>
          <div className="form-group">
            <label htmlFor="nameRegion">
              Nombre<span className="text-danger">*</span>
            </label>
            <Field
              name="nameRegion"
              type="text"
              className={
                "form-control" +
                (errors.nameRegion && touched.nameRegion ? " is-invalid" : "")
              }
            />
            <ErrorMessage
              name="nameRegion"
              component="div"
              className="invalid-feedback"
            />
          </div>
          <div className=" d-flex flex-sm-row flex-column-reverse justify-content-sm-around mt-1">
            <Button color="danger" type="reset" className="mt-3">
              Cancelar
            </Button>
            <Button color="success" type="submit" className="mt-2 mt-sm-3">
              Guardar
            </Button>
          </div>
          <FormikData info={data} />
        </Form>
      )}
    </Formik>
  );
};

const FormikData = ({ info }) => {
  const { setFieldValue } = useFormikContext();
  useEffect(() => {
    if (info) {
      setFieldValue("keyRegion", info[0]);
      setFieldValue("nameRegion", info[1]);
    }
  }, [info]);
  return null;
};

export default FormRegiones;

import React,{useState} from "react";

import TableComponent from "../../../components/TableComponent/TableComponentCustom";

export default function MatrizEgresosPagados() {
    const cabeceras = [
        "Id", //0
        "Objeto de gasto", //1
        "Denominación",
        "Cuenta de cargo",
        "Descripción del cargo",
        "Cuenta de abono",
        "Descripción del abono"
    ];
    const [parametros] = useState({
        action: "datatable",
        table: "matrixexpense_paid",
        rows: "idMatrix,expenseObject,expenseObjectName,charge,chargeName,pay,payName", ///sin espacios y comas
        conditions: "enabled = 1",
        page: 0,
        records: 5,
        search: "",
        order: "",
    });

    return (<>
        <TableComponent
            titulo="Matriz de egresos pagados"
            cabecerasTabla={cabeceras}
            filtro={null}
            parametros={parametros}
        ></TableComponent>
    </>)
}
import { Document, Page, Text, View } from "@react-pdf/renderer";
import { styles } from "../../../../components/Pdf/pdfGeneral/EstiloPdf";
import HeaderReport from "../../../../components/Pdf/Header";

function formatDate(date) {
  return date.split("-").reverse().toString().replaceAll(",", "/");
}

const typeHeaderCell = (tam, index) => {
  if (index === 0) {
    return tam === 100
      ? styles.cellHeader100Borders
      : tam === 75
      ? styles.cellHeader75Borders
      : tam === 50
      ? styles.cellHeader50Borders
      : tam === 25
      ? styles.cellHeader25Borders
      : tam === 11
      ? styles.cellHeader11Borders
      : "";
  } else {
    return tam === 100
      ? styles.cellContent100LBR
      : tam === 88.7
      ? styles.cellContent88_7BordersLBR
      : tam === 75
      ? styles.cellHeader75BordersRTB
      : tam === 50
      ? styles.cellHeader50BordersRTB
      : tam === 25
      ? styles.cellHeader25BordersRTB
      : tam === 11
      ? styles.cellHeader11BordersRTB
      : "";
  }
};

const insertHeaders = (model) => {
  return (
    <>
      <View style={styles.tableRow} fixed>
        {model.headers.map((obj, index) => (
          <View style={[typeHeaderCell(25, index),{fontSize:5}]}>
            <Text>{obj}</Text>
          </View>
        ))}
      </View>
    </>
  );
};

const insertContent = (data) => {
  return (
    <>
      {data.map((obj, index) => (
        <View style={styles.tableRow} wrap={false}>
          <View style={[styles.cellContent25BordersLBR,{fontSize:5}]}>
            <Text>{obj.staffNumber}</Text>
          </View>
          <View style={[styles.cellContent25BordersRB,{fontSize:5}]}>
            <Text>{obj.nombreCompleto}</Text>
          </View>
          <View style={[styles.cellContent25BordersRB,{fontSize:5}]}>
            <Text>{obj.areaName}</Text>
          </View>
          <View style={[styles.cellContent25BordersRB,{fontSize:5}]}>
            <Text>{obj.nameMove}</Text>
          </View>
          <View style={[styles.cellContent25BordersRB,{fontSize:5}]}>
            <Text>{formatDate(obj.fechaConclusion)}</Text>
          </View>
          <View style={[styles.cellContent25BordersRB,{fontSize:5}]}>
            <Text>{obj.nameJob}</Text>
          </View>
          <View style={[styles.cellContent25BordersRB,{fontSize:5}]}>
            <Text>{obj.rango}</Text>
          </View>
          <View style={[styles.cellContent25BordersRB,{fontSize:5}]}>
            <Text>{formatDate(obj.startDate)}</Text>
          </View>
        </View>
      ))}
    </>
  );
};

const insertTitles = (model, title) => {
  return (
    <>
      <HeaderReport/>
      <View>
        <Text style={styles.title}>{title}</Text>
      </View>
      <View>
        <Text style={styles.subTitle}>
          {model.filters !== undefined
            ? model.filters.onlyMessage === true
              ? model.filters.message
              : ""
            : ""}
        </Text>
      </View>
    </>
  );
};

const insertFooter = (fileNo) => {
  return (
    <>
      <View fixed style={styles.spaceFooter}></View>
      <View fixed style={styles.footer}>
        <Text style={styles.textFooter}>{fileNo}</Text>
        <Text
          style={styles.textFooter}
          render={({ pageNumber, totalPages }) =>
            `PAGINA : ${pageNumber} / ${totalPages}`
          }
        />
      </View>
    </>
  );
};

const generateReport = (data, model, title, fileNo) => {
  return (
    <Document>
      <Page style={styles.body}>
        <View style={styles.tableCell}>
          {insertTitles(model, title)}
          {insertHeaders(model)}
          {insertContent(data)}

          <View style={styles.tableRow}></View>
        </View>
        {insertFooter(fileNo)}
      </Page>
    </Document>
  );
};
export function generatePdfVencimientoPlazas(
  data,
  model,
  title,
  fileNo,
  acumNeto,
  acumAmount,
  final
) {
  const report = generateReport(
    data,
    model,
    title,
    fileNo,
    acumNeto,
    acumAmount,
    final
  );
  return {
    report: report,
  };
}

import React, { useEffect, useState } from 'react'
import HeaderEvidenciaAvances from './HeaderEvidenciaAvances';
import TableColors from '../table/TableEvidenceColors';


import {
    Col,
    Row,
    Collapse,
} from "reactstrap";


const CollapseTableColor = (props) => {
    const { 
        activity, 
        data, 
        refreshMethod, 
        methodViewFile, 
        methodAddSpent,
        methodObservaciones,
        editProcessMethod,
        isCollapsed
     } = props
    const [collapse, setCollapse] = useState(isCollapsed)
    const toggle = (collapse) => {
        setCollapse(!collapse)
    }
    useEffect(() => {
        
    }, [])

    return (
        <div className={"border"}>
            <Row>
                <Col>
                    <div className='bg-secondary text-white px-2 font-weight-bold' style={{height:"100"}} onClick={() => toggle(collapse)}>{activity.nameActivity} </div>
                </Col>
            </Row>

            <Collapse isOpen={collapse} >
                <div className="mx-2 mt-2">

                    <HeaderEvidenciaAvances activityEvidence={data} />
                    <TableColors
                        data={data.evidence.data}
                        idActivity={activity.idActivity}
                        refreshMethod={refreshMethod}
                        methodViewFile={methodViewFile}
                        methodObservaciones={methodObservaciones}
                        editProcessMethod={editProcessMethod}
                        methodAddSpent={methodAddSpent}
                        
                    />
                </div>
            </Collapse>
        </div>
    )
}
export default CollapseTableColor
// -- CUENTAS CONTABLES  -- //
import CuentasContables from '../../../pages/finanzas/cuentas-contables/CuentasContables'
import CrearCuentaContable from '../../../pages/finanzas/cuentas-contables/crear/CrearCuenta'
import EditarCuentaContable from '../../../pages/finanzas/cuentas-contables/editar/EditarCuenta'

// -- ADECUACIONES PRESUPUESTALES -- //
import AdecuacionesPresupuestales from "../../../pages/finanzas/adecuaciones-presupuestales/AdecuacionesPresupuestales";

// -- CONSULTA DE POLIZAS -- //
import ConsultaDePolizas from "../../../pages/finanzas/consulta-polizas/Principal";


// -- Pagos Tranferencias Empleados / Penaiones -- //
import PagosTrasferenciasEmpleados from "../../../pages/finanzas/pagos-transferencias-empleados/PagosTrasferenciasEmpleados";
import WatchPagosTrasferenciasEmpleados from "../../../pages/finanzas/pagos-transferencias-empleados/WatchPagosTrasferenciasEmpleados";

// -- Generacion de Cheques Empleados / Penaiones -- //
import GeneracionCheques from "../../../pages/finanzas/generacion-cheques/GeneracionCheques";

// -- Pagos de Proveedores de Nomina -- //
import PagosProveedoresNomina from "../../../pages/finanzas/pagos-proveedores-nomina/PagosProveedoresNomina";
import ReportesFinanzas from "../../../pages/finanzas/reportes/ReportesFinanzas";
import DetallesReporteFinanzas from "../../../pages/finanzas/reportes/Detalles";
import DetalleProveedorN from "../../../pages/finanzas/reportes/DetalleProveedorN";
import AddRemunaciones from "../../../pages/finanzas/reportes/forms/remunaciones/Add";
import EditRemunaciones from "../../../pages/finanzas/reportes/forms/remunaciones/Edit";
import AddPrisma from "../../../pages/finanzas/reportes/forms/prisma/Add";
import EditPrisma from "../../../pages/finanzas/reportes/forms/prisma/Edit";

import ReportesConac from "../../../pages/finanzas/reportes-presupuestales/anteproyecto/conac/Conac";

// -- Suficiencia Presupuestal -- //
import SuficienciaPresupuestal from '../../../pages/finanzas/suficiencia-presupuestal/SuficienciaPresupuestal';

import ControlSuficienciaPresupuestal from '../../../pages/finanzas/control-suficiencia-presupuestal/ControlSuficiencia';

//-- Areas autorizadas --//
import AreasAutorizadas from '../../../pages/finanzas/areas-autorizadas';

const rutasFinanzas = [
    //Cuentas Contables
    { path: "/template/cuentas-contables", component: CuentasContables },
    { path: "/template/cuentas-contables/crear", component: CrearCuentaContable },
    { path: "/template/cuentas-contables/editar", component: EditarCuentaContable },
    //Pago de Proveedores de Nomina Finanzas*/}
    { path: "/template/pago-proveedores-nomina", component: PagosProveedoresNomina },
    //Pago de Proveedores de Nomina Nomina*/}
    { path: "/template/pagoproveedoresnomina", component: PagosProveedoresNomina },
    //Adecuaciones Presupuestales
    { path: "/template/adecuaciones", component: AdecuacionesPresupuestales },
    //Consulta de polizas
    { path: "/template/consultapolizas", component: ConsultaDePolizas },
    //Pagos en trasferencias de empleados Finanzas*/}
    { path: "/template/pagos-transferencias-empleados", component: PagosTrasferenciasEmpleados },
    //Pagos en trasferencias de empleados Nomina*/}
    { path: "/template/pagostransferenciasempleados", component: WatchPagosTrasferenciasEmpleados },

    //Pagos en trasferencias de Pensiones Finanzas*/}
    { path: "/template/pagos-transferencias-pensiones", component: PagosTrasferenciasEmpleados },
    //Pagos en trasferencias de empleados Nomina*/}
    { path: "/template/pagostransferenciaspensiones", component: WatchPagosTrasferenciasEmpleados },
    //Generacion de Cheques Finanzas*/}
    { path: "/template/generacion-cheques", component: GeneracionCheques },
    //Generacion de Cheques Nomina*/}
    { path: "/template/generacioncheques", component: GeneracionCheques },

    //Pago de Proveedores de Nomina Finanzas*/}
    { path: "/template/pago-proveedores-nomina", component: PagosProveedoresNomina },
    //Pago de Proveedores de Nomina Nomina*/}
    { path: "/template/pagoproveedoresnomina", component: PagosProveedoresNomina },
    //Reportes Finanzas
    { path: "/template/reportes-finanzas", component: ReportesFinanzas },
    { path: "/template/reportes-finanzas/detalles", component: DetallesReporteFinanzas },
    { path: "/template/reportes-finanzas/detalle-pago-proveedores", component: DetalleProveedorN },
    { path: "/template/reportes-finanzas/remuneraciones/agregar", component: AddRemunaciones },
    { path: "/template/reportes-finanzas/remuneraciones/editar", component: EditRemunaciones },
    { path: "/template/reportes-finanzas/prisma/agregar", component: AddPrisma },
    { path: "/template/reportes-finanzas/prisma/editar", component: EditPrisma },
    //Reportes Presupuestales*/}
    { path: "/template/reportes-conac", component: ReportesConac },
    //Reportes Presupuestales*/}
    { path: "/template/suficiencia-presupuestal", component: SuficienciaPresupuestal },
    { path: "/template/control-suficiencia-presupuestal", component: ControlSuficienciaPresupuestal },
    //Areas autorizadas
    { path: "/template/areas-autorizadas", component: AreasAutorizadas }
]

export default rutasFinanzas
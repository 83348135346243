import Widget from "../../../components/Widget/Widget";
import { useEffect, useRef, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Collapse,
  Row,
  Table,
  UncontrolledTooltip,
} from "reactstrap";
import { PERIOD } from "../../nomina/calculo/regex";
import { SelectTypeHeadSingle } from "../../../components/GenericInputsFormik";
import { YearInput } from "../../../components/GenericInputsFormik/YearInput";
import ModalReports from "../../../components/Modal/ModalReports";
import { toast } from "react-toastify";
import Notification from "../../../components/Notification/Notification";
import axios from "axios";
import { peticionEncript } from "../../../helpers/peticionesEncripted";
import { pdfResumenGlobalNomina } from "../../nomina/reportes/pdf/pdfResumenGlobalNomina";
import FullFormLoader from "../../../components/Loader/FullFormLoader";
import st from "./PagosTrasferenciasEmpleados.module.scss";
import ModalViewFile from "../../../components/Modal/ModalViewFile";

import { useLocation } from "react-router-dom";
import { saveAs } from "file-saver";
import { PDFDocument } from "pdf-lib";
import classNames from "classnames";
import ResolverRechazo from "./ResolverRechazo";

const fmt = new Intl.NumberFormat("es-MX", {
  style: "currency",
  currency: "MXN",
});

const defaultFiltro = {
  period: "",
  year: new Date(),
};
const date = new Date().getFullYear();

async function doRequest(route, params, seeOk, encript) {
  let info = [];
  const dataEncrypted = encript !== false ? peticionEncript(params) : params;
  await axios
    .post(process.env.REACT_APP_API + route, dataEncrypted)
    .then((res) => {
      if (res.status === 200 && res.data.status === "success") {
        info = res.data.data;
        if (seeOk) {
          toast(
            <Notification
              type={"elimina_exito"}
              backMessage={res.data.message}
              withIcon
            />,
            { closeButton: false }
          );
        }
      } else {
        toast(
          <Notification
            type={"consultar_error"}
            backMessage={res.data.message}
            withIcon
          />,
          { closeButton: false }
        );
      }
    })
    .catch((err) => {
      toast(<Notification type={"consultar_servidor_error"} withIcon />, {
        closeButton: false,
      });
    });
  return info;
}
const empleados = "pagostransferenciasempleados";
const pensiones = "pagostransferenciaspensiones";

const WatchPagosTrasferenciasEmpleados = () => {
  const [activeCollapse, setActiveCollapse] = useState(1);
  const [loadingTab1, setLoadingTab1] = useState(false);
  const [loadingTab2, setLoadingTab2] = useState(false);
  const [modalReports, setModalReports] = useState(false);
  const [configPDF, setConfigPDF] = useState({});
  const [filtro, setFiltro] = useState({ ...defaultFiltro }); /// Formulario apra seleccionar la solicitus de pago
  const [infoReport, setInfoReport] = useState({}); /// Informacion reporte
  const [infoSolicitud, setInfoSolicitud] = useState({}); /// Informacion de la solicitud de pago
  const [archivosDispersion, setArchivosDispersion] = useState([]); /// Informacion de los layouts
  const [pagosBancos, setPagosBancos] = useState([]); /// Banccos
  const [rechazos, setRechazos] = useState([]); /// Rechazos
  const [soluciones, setSoluciones] = useState([]); /// Arreglo de soluciones para los Rechazos
  const [bancos, setBancos] = useState([]); /// Arreglo de bancos para los Rechazos
  const [tiposCuentas, setTiposCuentas] = useState([]); /// Arreglo de tipos de cuentas para los Rechazos
  const [onlyRechazos, setOnlyRechazos] = useState(false); /// Para mostrar unicamente los rechazos
  const location = useLocation();
  const route = location.pathname.split("/")[2];

  /* Cambiar */
  useEffect(() => {
    if (activeCollapse === 1) {
      if (loadingTab1) setLoadingTab1(false);
    } else {
      if (loadingTab2) setLoadingTab2(false);
    }
  }, [activeCollapse]);

  const onChange = (e) => {
    if (e.target.name === "year") {
      if (e.target.value !== null)
        setFiltro((prevState) => ({ ...prevState, year: e.target.value }));
      else setFiltro((prevState) => ({ ...prevState, year: new Date() }));
    } else if (e.target.name === "period") {
      setFiltro((prevState) => ({ ...prevState, period: e.target.value }));
    }
  };

  /*Hacer consuolta de reporte de nomina */
  const getReportData = async () => {
    const params = {
      action: "report20",
      search: "",
      order: "",
      period: filtro.period,
      year: new Date(filtro.year).getFullYear(),
    };
    setConfigPDF({
      period: `${filtro.period}${new Date(filtro.year).getFullYear()}`,
    });
    const data = await doRequest("app/facades/reports/reportsF.php", params);
    if (data !== "") {
      setInfoReport(data);
      setModalReports(true);
    }
  };

  /* Buscar solicitud de pago */
  const findSolicitudPago = async () => {
    setLoadingTab1(true);
    const params = {
      action: "multiselect",
      table:
        "payrollrequest A INNER JOIN payrollrequestnotes B ON B.idPayrollRequest = A.idPayrollRequest",
      rows: "A.idPayrollRequest AS id, GROUP_CONCAT(B.keyStat) AS estatuses",
      conditions: ` A.period = ${
        filtro.period
      } AND A.year = ${filtro.year.getFullYear()} AND A.enabled = 1`,
      group: "id",
    };
    const data = await doRequest("receiver/receiver.php", params);
    if (data.length > 0) {
      const statuses = data[0].estatuses.split(",");
      if (checkStat(statuses)) {
        await getPagosDepositosEmpleados(data[0].id, statuses);
        setActiveCollapse(2);
      } else {
        const rechazos = await getRechazos(data[0].id);
        if (rechazos.length > 0) {
          setOnlyRechazos(true);
          setActiveCollapse(2);
        } else {
          toast(
            <Notification
              type={"warning"}
              backMessage={
                route === empleados
                  ? "No se ha concluido el proceso de pagos a empelados de este periodo"
                  : route === pensiones
                  ? "No se ha concluido el pagos de pensiones de este periodo"
                  : "Módulo desconocido"
              }
              withIcon
            />,
            { closeButton: false }
          );
        }
      }
    }
    setLoadingTab1(false);
  };

  /* Buscar informacion de la solicitud de pago */
  const getPagosDepositosEmpleados = async (id, estatuses) => {
    const params = {
      action:
        route === empleados
          ? "searchPagosDepositosEmpleados"
          : route === pensiones
          ? "searchPagosDepositosPensiones"
          : "",
      rows: { idPayrollRequest: id ? id : infoSolicitud.id },
    };
    const data = await doRequest(
      "app/facades/payslips/payrollrequestF.php",
      params
    );
    if (data.length > 0) {
      const arcDis = data[0].archivosDispersion.map((el, id) => {
        const nameFile = el.url.split("/");
        return {
          COL1: el.nameTypePayrollclerk,
          COL2: el.nameLayoutClass,
          COL3: nameFile[nameFile.length - 1],
          COL4: el.url,
        };
      });
      setArchivosDispersion(arcDis);
      const rech = await getRechazos(id);
      const depBan = data[0].pagosBancos.map((el, id) => {
        //Buscar si ya se pagó
        let cuentaDep = "";
        let cantRechazos = "NO";
        if (el.idBankAccount !== "") {
          const dato = data[0].cuentasBancarias.find(
            (cb) => cb.idBankAccount === el.idBankAccount
          );
          if (dato !== null) cuentaDep = dato.cuenta;

          //Buscar si tiene rechazos
          if (rech.length > 0) {
            const exist = rech.filter((r) => r.COL9 === el.idPayrollBank);
            if (exist.length > 0) cantRechazos = exist.length;
          }
        }

        return {
          COL1: el.deposit,
          COL2: el.nameBank,
          COL3: el.amount,
          COL4: el.rutaComprobante,
          COL5: el.idPayrollBank,
          COL6: el.idBankAccount,
          COL7: cuentaDep,
          COL8: el.keyBank,
          cantRechazos: cantRechazos,
        };
      });
      setPagosBancos(depBan);
    }
    setInfoSolicitud({
      id: id ? id : infoSolicitud.id,
      depositos: data[0].depositos,
      importe: data[0].importe,
      keyStat: data[0].keyStat,
      estatuses: estatuses ? estatuses : infoSolicitud.estatuses,
    });
    setLoadingTab2(false);
  };

  /* Buscar todos los rechazos de la solicitud de pago */
  const getRechazos = async (id) => {
    const params = {
      action: "searchPayrollrequestreturns",
      rows: {
        idPayrollRequest: id ? id : infoSolicitud.id,
      },
    };
    let data = await doRequest(
      "app/facades/payslips/payrollrequestF.php",
      params,
      false,
      true
    );
    if (data.length > 0) {
      if (id) {
        getSoluciones();
        getBancos();
        getTiposCuentas();
      }
      if (route === empleados)
        data = data.filter((el) => [1, 3].includes(+el.keyTypePayrollBank));
      if (route === pensiones)
        data = data.filter((el) => 2 === +el.keyTypePayrollBank);
      data = data.map((el) => {
        return {
          COL1: el.origenDesc,
          COL2: el.desBancoAnt,
          COL3: el.numEmpleado,
          COL4: el.nombre,
          COL5: el.beforeReturn,
          COL6: el.amount,
          COL7: el.nameStat,
          COL8: el.idPayrollRequestReturn,
          COL9: el.idPayrollBank,
          COL10: el.afterReturn,
          COL11: el.keyStat,
          COL12: id,
        };
      });
      setRechazos(data);
    }
    return data;
  };

  /* Buscar solicuines a los rechazos de la solicitud de pago */
  const getSoluciones = async () => {
    const params = {
      action: "select",
      table: "types_payrollrequestreturns",
    };
    const data = await doRequest("receiver/receiver.php", params, false, true);
    if (data.length > 0) {
      setSoluciones(data);
    }
  };

  /* Buscar Bancos para los rechazos de la solicitud de pago */
  const getBancos = async () => {
    const params = {
      action: "select",
      table: "banks",
    };
    const data = await doRequest("receiver/receiver.php", params, false, true);
    if (data.length > 0) {
      setBancos(data);
    }
  };

  /* Buscar Tipos de Cuentas para los rechazos de la solicitud de pago */
  const getTiposCuentas = async () => {
    const params = {
      action: "select",
      table: "accounttypes",
    };
    const data = await doRequest("receiver/receiver.php", params, false, true);
    if (data.length > 0) {
      setTiposCuentas(data);
    }
  };

  /* Descargar el layout */
  const downloadLayout = (el) => {
    saveAs(el.COL4, el.COL3);
  };

  /* Juntar y descargar todos los comprobantes */
  const downloadAllComp = async () => {
    let comprobantes = pagosBancos.filter((b) => b.COL4 !== "");
    if (comprobantes.length === 0) {
      toast(
        <Notification
          type={"warning"}
          backMessage="No se han subido comprobantes de pago"
          withIcon
        />,
        { closeButton: false }
      );
      return;
    }
    setLoadingTab2(true);
    try {
      const pdfDoc = await PDFDocument.create();
      for (const f in comprobantes) {
        /// Obtener comprobantes
        let res = await axios.get(comprobantes[f].COL4, {
          responseType: "arraybuffer",
        });

        const doc = await PDFDocument.load(res.data);
        const [page] = await pdfDoc.copyPages(doc, [0]);
        pdfDoc.addPage(page);
      }

      const pdfBytes = await pdfDoc.save(); /// Genear pdf
      const bytes = new Uint8Array(pdfBytes);
      const blobFile = new Blob([bytes], { type: "application/pdf" });

      saveAs(
        /// Guardar pdf
        blobFile,
        `Comprobantes de pago ${filtro.period}_${filtro.year.getFullYear()}`
      );
    } catch (error) {
      console.error(`No se puedo obtener la infromación. ${error}`);
      toast(
        <Notification
          type={"consultar_error"}
          backMessage={`No fue posible descargar los comprobantes`}
          withIcon
        />,
        { closeButton: false }
      );
    }
    setLoadingTab2(false);
  };

  /* Revisar historia de status de la solicitud */
  const checkStat = (statuses) => {
    if (infoSolicitud.keyStat === 75) return false;
    const stat = route === empleados ? "76" : route === pensiones ? "77" : "";
    return statuses.includes(stat);
  };

  /* Función limpair la info al cerrar */
  const clearData = () => {
    setArchivosDispersion([]);
    setPagosBancos([]);
    setInfoSolicitud({});
    setOnlyRechazos(false);
  };

  return (
    <Widget className="widget-p-md">
      <div className="mb-4 d-flex justify-content-between flex-column flex-md-row text-center text-md-left">
        {route === empleados ? (
          <h4>Pagos en trasferencias de empleados</h4>
        ) : (
          route === pensiones && <h4>Pagos en trasferencias de pensiones</h4>
        )}

        {(Object.keys(infoSolicitud).length > 0 || onlyRechazos) && (
          <div className="mt-2 mt-md-0">
            <Button
              className="btn text-light"
              style={{ backgroundColor: "#ba262f", border: "none" }}
              onClick={() => setActiveCollapse(1)}
            >
              Cerrar
              <i className="fa fa-sign-out ml-2" style={{ fontSize: "16px" }} />
            </Button>
          </div>
        )}
      </div>

      {/* Form filtro */}
      <Collapse isOpen={activeCollapse === 1}>
        <Row>
          <Col xs={12} lg={4}>
            <SelectTypeHeadSingle
              label="Periodo"
              isRequired={true}
              inputName="period"
              optionsArray={PERIOD}
              defaultOption="Seleccione un periodo"
              onChangeMethod={onChange}
              value={filtro.period}
              optionValue="value"
              optionName="label"
            />
          </Col>
          <Col xs={12} lg={4}>
            <YearInput
              label="Año"
              inputName="year"
              onChangeMethod={onChange}
              value={filtro.year}
              isMin={date - 1}
              isRequired
            />
          </Col>
          <Col xs={12} lg={2}>
            <div className="pt-lg-4 mt-lg-2 text-center">
              <Button
                color="secondary"
                onClick={() => findSolicitudPago()}
                disabled={filtro.period === "" || filtro.year === ""}
              >
                Consultar
              </Button>
            </div>
          </Col>
        </Row>
        <FullFormLoader show={loadingTab1} />
      </Collapse>

      {/* Content */}
      <Collapse
        isOpen={activeCollapse === 2}
        onExited={clearData}
        className="border-top"
      >
        {!onlyRechazos && (
          <>
            <div className="mt-4 pt-3">
              <div className="text-center text-lg-left mb-4">
                <Button
                  color="primary"
                  type="button"
                  onClick={() => getReportData()}
                >
                  Resumen de Nómina
                </Button>
              </div>

              <Card className="mb-4 border border-dark-gray">
                <CardHeader
                  className="text-center bg-secondary text-white"
                  tag="h6"
                >
                  Importe a Pagar
                </CardHeader>
                <CardBody>
                  {route === empleados && (
                    <div
                      className="text-center mb-1"
                      style={{ color: "#343a40" }}
                    >
                      <h5 className="font-weight-bold">Servidores Publicos</h5>
                      <div className="row" style={{ fontSize: "18px" }}>
                        <div className="col-12 col-md-4 mt-2">Banco</div>
                        <div className="col-12 col-md-4 mt-2">
                          {infoSolicitud.depositos}
                        </div>
                        <div className="col-12 col-md-4 mt-2">
                          <span className=" border-bottom border-dark px-3">
                            {fmt.format(infoSolicitud.importe)}
                          </span>
                        </div>
                      </div>
                    </div>
                  )}

                  {route === pensiones && (
                    <div
                      className="text-center mb-1"
                      style={{ color: "#343a40" }}
                    >
                      <h5 className="font-weight-bold text-center">
                        Pensiones Alimenticias
                      </h5>
                      <div
                        className="row d-flex justify-content-center"
                        style={{ fontSize: "18px" }}
                      >
                        <div className="col-12 col-md-4 mt-2">
                          {infoSolicitud.depositos}
                        </div>
                        <div className="col-12 col-md-4 mt-2">
                          <span className=" border-bottom border-dark px-3">
                            {fmt.format(infoSolicitud.importe)}
                          </span>
                        </div>
                      </div>
                    </div>
                  )}
                </CardBody>
              </Card>

              <Card className="mb-4 border border-dark-gray">
                <CardHeader
                  className="text-center bg-secondary text-white"
                  tag="h6"
                >
                  Layout Dispersión
                </CardHeader>
                <CardBody>
                  <Table responsive className="table-striped table-borderless">
                    <thead
                      className={classNames([
                        "text-center",
                        st.thBB,
                        "border-secondary",
                      ])}
                    >
                      <tr className={st.tableHeader}>
                        <th className="py-2">NO.</th>
                        <th className="py-2">Tipo Dispersión</th>
                        <th className="py-2">Tipo Layout</th>
                        <th className="py-2">Archivo</th>
                      </tr>
                    </thead>
                    <tbody>
                      {archivosDispersion.map((el, id) => (
                        <tr
                          key={"rl" + id}
                          className={classNames([st.rowHover, st.tableText])}
                        >
                          <td className="text-center">{id + 1}</td>
                          <td>{el.COL1}</td>
                          <td>{el.COL2}</td>
                          <td>
                            <a
                              className={st.hoverLink}
                              onClick={() => {
                                downloadLayout(el);
                              }}
                            >
                              {el.COL3}
                            </a>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </CardBody>
              </Card>
            </div>

            <div className="mt-5">
              <div className="text-center">
                <h4>Servidores Publicos</h4>
              </div>

              <div className="mt-4 d-flex justify-content-end  flex-wrap">
                {/* <Button color="primary" className=" my-2">
                Descargar Comprobantes Banorte
              </Button> */}
                <Button
                  className=" ml-2 my-2"
                  onClick={() => downloadAllComp()}
                >
                  Descargar Comprobantes
                  <i className="fa fa-download pl-2" />
                </Button>
              </div>

              <Table
                responsive
                className="table-striped table-borderless mt-3 mb-2"
              >
                <thead
                  className={classNames([
                    "text-center",
                    st.thBB,
                    "border-secondary",
                  ])}
                >
                  <tr>
                    <th className="px-2 py-2">Deposito</th>
                    <th className="px-2 py-2">Banco</th>
                    <th className="px-2 py-2">Importe</th>
                    <th
                      className="text-center px-2 py-2"
                      style={{ minWidth: "260px" }}
                    >
                      Cuenta
                    </th>
                    <th className="text-center px-2 py-2">Rechazos</th>
                    <th className="text-center px-2 py-2">Ver Archivo</th>
                  </tr>
                </thead>
                <tbody>
                  {pagosBancos.length > 0 ? (
                    pagosBancos.map((el, id) => (
                      <TableRow key={"tr" + id} el={el} route={route} />
                    ))
                  ) : (
                    <tr
                      key="nothing"
                      className={classNames([
                        st.rowHover,
                        st.tableText,
                        "text-center",
                      ])}
                    >
                      <td colSpan={6}>No hay pagos por realizar</td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </div>
          </>
        )}

        {/* Rechazos */}
        <div className="mt-5 pt-2">
          <div className="text-center">
            <h4>
              {route === empleados
                ? "Pagos Rechazados"
                : route === pensiones
                ? "Pensiones Rechazadas"
                : ""}
            </h4>
          </div>

          <Table responsive className="table-striped table-borderless mt-2">
            <thead
              className={classNames([
                "text-center",
                st.thBB,
                "border-secondary",
              ])}
            >
              <tr>
                <th className="px-2 py-2">Origen</th>
                <th className="px-2 py-2">Banco</th>
                <th className="px-2 py-2">Num. Empleado</th>
                <th className="px-2 py-2">Nombre</th>
                <th className="px-2 py-2">Motivo</th>
                <th className="px-2 py-2">Importe</th>
                <th className="px-2 py-2" style={{maxWidth: "150px", width: "150px"}}>Estatus</th>
                <th className="px-2 py-2">Detalle</th>
                {onlyRechazos && <th>Acción</th>}
              </tr>
            </thead>
            <tbody>
              {rechazos.length > 0 ? (
                rechazos.map((el, id) => (
                  <RowRechazo
                    el={el}
                    key={"R" + el.COL8}
                    getRechazos={getRechazos}
                    soluciones={soluciones}
                    bancos={bancos}
                    tiposCuentas={tiposCuentas}
                    onlyRechazos={onlyRechazos}
                  />
                ))
              ) : (
                <tr
                  key="nothing"
                  className={classNames([
                    st.rowHover,
                    st.tableText,
                    "text-center",
                  ])}
                >
                  <td colSpan={8}>No existen rechazos</td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>

        <FullFormLoader show={loadingTab2} />
      </Collapse>

      {/* Form rechazos */}
      {/* <Collapse
        isOpen={activeCollapse === 3}
        onExited={()=> {}}
        className="border-top"
      >
      a
      </Collapse> */}

      <ModalReports
        modal={modalReports}
        setModal={setModalReports}
        title={"RESUMEN GLOBAL DE NÓMINA"}
        backdrop
        keyboard
        report={infoReport}
        pdfConfig={configPDF}
        generatePdfMethod={{
          method: pdfResumenGlobalNomina,
          type: "resumenGlobalNomina",
        }}
      />
    </Widget>
  );
};

export default WatchPagosTrasferenciasEmpleados;

const TableRow = ({ el, route }) => {
  const [modalFile, setModalFile] = useState(false);
  const [linkFile, setlinkFile] = useState("");

  const openFile = () => {
    if (el.COL4 !== "") {
      setlinkFile(el.COL4);
      setModalFile(true);
    }
  };

  return (
    <>
      <tr className={classNames(st.rowHover, st.tableText)}>
        <td className="px-2 text-center">{el.COL1}</td>
        <td className="px-2">{el.COL2}</td>
        <td className="px-2 text-right">{fmt.format(el.COL3)}</td>
        <td className="px-2 text-center">
          <p>{el.COL7}</p>
        </td>
        <td className="px-2 text-center">
          <p>{el.cantRechazos}</p>
        </td>
        <td className="px-2 text-center">
          {el.COL4 !== "" && (
            <Button
              outline
              color="info"
              className="px-3"
              onClick={() => openFile()}
            >
              <i className="fa fa-eye" />
            </Button>
          )}
        </td>
      </tr>

      <ModalViewFile
        modal={modalFile}
        setModal={setModalFile}
        backdrop={true}
        keyboard={true}
        btnClose={true}
        fileLink={linkFile}
      />
    </>
  );
};

const RowRechazo = ({
  el,
  getRechazos,
  soluciones,
  bancos,
  tiposCuentas,
  onlyRechazos,
}) => {
  const [modalRechazo, setModalRechazo] = useState(false);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);

  /* Resolver rechazo de la solicitud de pago */
  const resolveRechazo = async () => {
    const params = {
      action: "deleteRechazo",
      rows: {
        idPayrollRequestReturn: el.COL8,
        idPayrollBank: el.COL9,
        amount: el.COL6,
        name: el.COL4,
      },
    };
    const data = await doRequest(
      "app/facades/payslips/payrollrequestF.php",
      params,
      true,
      true
    );
  };

  return (
    <>
      <tr className={classNames(st.rowHover, st.tableText)}>
        <td className="text-center">{el.COL1}</td>
        <td className="text-center">{el.COL2}</td>
        <td className="text-center">{el.COL3}</td>
        <td className="">{el.COL4}</td>
        <td className="">{el.COL5}</td>
        <td className="text-right">{fmt.format(el.COL6)}</td>
        <td className="text-center">
          <div className="d-flex justify-content-center" id={"tt-" + el.COL8}>
            {+el.COL11 !== 85 ? (
              <i
                className="fa fa-check-circle-o"
                style={{ fontSize: "40px", color: "#43BC13" }}
              />
            ) : (
              <i
                className="fa fa-times-circle-o"
                style={{ fontSize: "40px", color: "#ff3347" }}
              />
            )}
            <p className="pl-2">{el.COL7}</p>
          </div>
          <UncontrolledTooltip
            placement="top" target={"tt-" + el.COL8} boundariesElement="window"
          >
            {+el.COL11 !== 85 ? "Resuelto" : "Sin resolver"}
          </UncontrolledTooltip>
        </td>
        <td className="">{el.COL10}</td>
        {onlyRechazos && (
          <td className="text-center">
            {el.COL10 === "" && (
              <Button
                outline
                color="primary"
                className="p-0"
                onClick={() => setModalRechazo(true)}
              >
                <i className="fa fa-plus py-2 px-3" />
              </Button>
            )}
          </td>
        )}
      </tr>

      <ResolverRechazo
        modal={modalRechazo}
        setModal={setModalRechazo}
        idPayrollRequestReturn={el.COL8}
        idPayrollRequest={el.COL12}
        getRechazos={() => getRechazos(el.COL12)}
        empleado={`${el.COL3} - ${el.COL4}`}
        soluciones={soluciones}
        bancos={bancos}
        tiposCuentas={tiposCuentas}
      />
    </>
  );
};

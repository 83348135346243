import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect, useSelector } from "react-redux";
import { Switch, Route, withRouter, Redirect } from "react-router";
import { useHistory } from "react-router-dom";

import getRoute from './function'
import getActions from "../../helpers/getPermissionForms";

import Header from "../Header/Header";
import Sidebar from "../Sidebar/Sidebar";

import Dashboard from "../../pages/dashboard/Dashboard";

import s from "./Layout.module.scss";



import rutasAdminUsuarios from "./admin-usuarios/rutas";
import rutasAdquisiciones from "./adquisiciones/rutas";
import rutasComplementoFinanzas from "./complemento-finanzas/rutas";
import rutasComplementoNomina from "./complemento-nomina/rutas";
import rutasComplementoPersonal from "./complemento-personal/rutas";
import rutasEstructuraInstitucional from "./estructura-institucional/rutas";
import rutasFinanzas from "./finanzas/rutas";
import rutasNomina from "./nomina/rutas";
import rutasPatrimonio from "./patrimonio/rutas";
import rutasPersonal from "./personal/rutas";
import rutasPlaneacion from "./planeacion/rutas";
import rutasPresupuesto from "./presupuesto/rutas";
import rutasTesoreria from "./tesoreria/rutas";
import rutasAlmacenes from "./almacenes/rutas"
import rutasMesa from "./mesa-ayuda/rutas";
import rutasPercepcionesExtaordinarias from "./percepciones-extraordinarias/rutas";
import rutasObra from "./obra/rutas";


const allRoutes = [
  ...rutasAdminUsuarios,
  ...rutasAdquisiciones,
  ...rutasComplementoFinanzas,
  ...rutasComplementoNomina,
  ...rutasComplementoPersonal,
  ...rutasEstructuraInstitucional,
  ...rutasFinanzas,
  ...rutasNomina,
  ...rutasPatrimonio,
  ...rutasPersonal,
  ...rutasPlaneacion,
  ...rutasPresupuesto,
  ...rutasTesoreria,
  ...rutasAlmacenes,
  ...rutasMesa,
  ...rutasPercepcionesExtaordinarias,
  ...rutasObra
];

const Layout = (props) => {
  const history = useHistory();
  const user = useSelector(state => state.userInfo)
  const [permission, setPermission] = useState(true)

  useEffect(() => {
    changeUrl()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.location])

  const changeUrl = async () => {
    if (props.location.state === undefined) {
      const pathname = props.location.pathname.split('/')[2]
      const fullPath = props.location.pathname.split('/')
      let originalPath = '';
      for (let i = 0; i < fullPath.length; i++) {
        if (fullPath[i].toLowerCase() !== 'crear' && fullPath[i].toLowerCase() !== 'editar' && fullPath[i] !== '') {
          originalPath = originalPath + '/' + fullPath[i]
        }
      }
      if (pathname !== "dashboard") {

        const route = await getRoute(user.permission, pathname)
        setPermission(route.permission)
        const result = await getActions(
          user.id,
          user.profileType.selectProfile.keyProfile,
          pathname
        );
        route.permission === false && history.push("/template");
        const createFlag = fullPath.some((e) => e === 'crear');
        const editFlag = fullPath.some((e) => e === 'editar');
        if (createFlag && result.actions.INS !== '1') {
          history.push(originalPath);
        }
        if (editFlag && result.actions.UPD !== '1') {
          history.push(originalPath);
        }
      }

    }
  }

  return (
    <div className={s.root}>
      <div className={s.wrap}>
        <Header />
        <Sidebar />
        <main className={`${s.content} ${props.sidebarOpened ? s.contentLoc : ""}`}>
          {
            permission === true ?
              <Switch>
                <Route path="/template" exact render={() => <Redirect to="template/dashboard" />} />
                <Route path="/template/dashboard" exact component={Dashboard} />
                {allRoutes.map((ruta, ix) => (
                  <Route path={ruta.path} exact component={ruta.component} key={`mr${ix}`} />
                ))}
                {/* Error */}
                <Route path='*' exact render={() => <Redirect to="/error" />} />
              </Switch >
              :
              <Switch>
                <Route path='*' exact render={() => <Redirect to="/error" />} />
              </Switch>
          }
        </main >
      </div >
    </div >
  );
}

Layout.propTypes = {
  sidebarOpened: PropTypes.bool,
  dispatch: PropTypes.func.isRequired,
}

function mapStateToProps(store) {
  return {
    sidebarOpened: store.navigation.sidebarOpened,
    currentUser: store.auth.currentUser,
  };
}

export default withRouter(connect(mapStateToProps)(Layout));

export { allRoutes }

import { useState } from "react";
import { toast } from "react-toastify";

import Datatable from "../../../../components/Datatable/Datatable";
import { GetPermisos } from "../../../../libs/permissions/hook";
import { peticionesReceiver } from "../../../../helpers/peticionesReceiver";

import Notification from "../../../../components/Notification/Notification";
import FullFormLoader from "../../../../components/Loader/FullFormLoader";
import { fMoney, fPercent } from "../../../../libs/tools/format";

export default function Report47() {
    const API = peticionesReceiver();

    const [permissionsForm, setPermissionsForm] = useState("");
    const [loading, setLoading] = useState(true)

    GetPermisos(setPermissionsForm);

    const headers = [
        "Id",
        "NUMERO DE PENSION",
        "NUMERO DE EMPLEADO",
        "NOMBRE EMPLEADO",
        "PUESTO LABORAL",
        "NIVEL-RANGO",
        "NOMBRE BENEFICIARIO",
        "TIPO DE PENSION",
        "TIPO DE IMPORTE",
        "IMPORTE",
        "TIPO DE PAGO",
        "BANCO",
        "CUENTA",
        "ESPECIAL",
    ]

    const columns = [
        {
            data: "idPension"
        },
        {
            data: "numberPension"
        },
        {
            data: "staffNumber"
        },
        {
            data: "nombreCompleto"
        },
        {
            data: "nameJob"
        },
        {
            data: "rango"
        },
        {
            data: "beneficiario"
        },
        {
            data: "nameTypePension"
        },
        {
            data: "porcentage",
            render: function (row) {
                return row === "0" ? "CANTIDAD M/N" : "PORCENTAJE %"
            }
        },
        {
            data: null,
            render: (row) =>{return +row.porcentage > 0 ? fPercent(row.amount) : fMoney(row.porcentage)},
            orderValue: "amount"
        },
        {
            data: "namePaymentType"
        },
        {
            data: "nameBank",
            render: function (row) {
                return row !== "" ? row : "SIN DEFINIR"
            }
        },
        {
            data: "account",
            render: function (row) {
                return row !== "" ? row : "SIN DEFINIR"
            }
        },
        {
            data: "special",
            render: function (row) {
                return row === "0" ? "NO" : "SI"
            }
        },
    ]

    const columnDefs = [
        { className: "text-center", targets: [0, 1, 2, 5] },
        { className: "text-right", targets: [9] }
    ]

    function getParams(order, page, search, records) {
        const params = {
            action: "report45",
            page: (page * records).toString(),
            records: records.toString(),
            search,
            order
        }
        return params
    }
    async function getData(order, records, page, search) {
        const route = "app/facades/reports/reportsF.php"
        setLoading(true)
        try {
            const res = await API.peticionEndPoint(getParams(order, page, search, records), route)
            if (res.status === 200 && res.data.code === "200") {
                setLoading(false)
                return res.data;
            } else {
                toast(<Notification type={"consultar_error"} backMessage={res.data.message} />);
                setLoading(false)
                return null;
            }
        } catch (err) {
            toast(<Notification type={"consultar_servidor_error"} withIcon />);
            setLoading(false)
            return null
        }
    }

    return (
        <>
            {permissionsForm.CON === "1" && (
                <Datatable
                    className="mt-3"
                    headers={headers}
                    petition={getData}
                    columns={columns}
                    columnDefs={columnDefs}
                    control='back'
                    key={"table"}
                />
            )}
            <FullFormLoader
                show={loading}
                message={"Consultando datos , espere un momento"}
            />
        </>
    )
}
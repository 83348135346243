import React, { useEffect, useState } from "react";
import {
    Col,
    Row,
    Table,
    Button,
    Input,
    Form,
    FormGroup,
    FormFeedback,
    Progress,
} from "reactstrap";
import Widget from "../../../components/Widget/Widget";
import { peticionEncript } from "../../../helpers/peticionesEncripted";
import Notification from "../../../components/Notification/Notification";
import ModalConfirmation from "../../../components/Modal/ModalConfirmation";
import { toast } from "react-toastify";
import axios from "axios";
import csvtojson from "csvtojson";
import { CancelOutlined } from "@material-ui/icons";
import {
    calcAmountQ,
    calcPeriods,
    calcFinalPeriod,
    calculateGoces,
    compareColumns,
    countColumns,
    countLines,
    isAccount,
    isClabe,
    isDate,
    isDateTime,
    isDuplicated,
    isPositive,
    isPositiveInt,
    isYear,
    limitColumns,
    maxValue,
    notVoid,
    objComplete,
    periodLow,
    periodLowEqual,
    svInitialPeriodM,
    validName,
    validYears,
    validateDates,
    validPeriod,
    itsSameYear,
    emptyRow,
} from "./validations";
import s from "./TableErros.module.scss";
import is from "./InputFileClear.module.scss";
import SimpleTable from "./SimpleTable";
import DesplegableTable from "./DesplegableTable";
import { GetPermissions } from "../../../libs/permissions/getPermissions";
import { validValue } from "../../../libs/tools/format";
import ModalDataTableVerF from "../../../components/Modal/ModalDataTableFront";
import { saveAs } from "file-saver";
import FullFormLoader from "../../../components/Loader/FullFormLoader";
import {
    clearKeys,
    columns,
    extractIncapacitiesCols,
    namesKeys,
} from "./layouts";

const fmtMny = new Intl.NumberFormat("es-MX", {
    style: "currency",
    currency: "MXN",
    minimumFractionDigits: 2,
    maximumFractionDigits: 5
});

export function lcfMoney(d) {
    if (!validValue(d) || isNaN(+d)) return d;
    return fmtMny.format(d);
}

async function backRequest(params, idx, insert) {
    let info = [];
    const finalData = peticionEncript(params);
    await axios
        .post(process.env.REACT_APP_API + routes[idx], finalData)
        .then((res) => {
            if (res.status === 200 && res.data.status === "success") {
                info = res.data.data;
                if (insert) {
                    info = res.data.status;
                    toast(
                        <Notification
                            type={"agrega_exito"}
                            backMessage={
                                res.data.message || "Registros agregados correctamente"
                            }
                            withIcon
                        />,
                        { closeButton: false }
                    );
                }
            } else {
                toast(
                    <Notification
                        type={"consultar_error"}
                        backMessage={res.data.message}
                        withIcon
                    />,
                    { closeButton: false }
                );
            }
        })
        .catch((err) => {
            toast(<Notification type={"consultar_servidor_error"} withIcon />, {
                closeButton: false,
            });
            if (insert) info = "conexion_error"
        });
    return info;
}

const routes = ["app/facades/layouts/layoutsF.php", "receiver/receiver.php"];

const columnDefs = [
    {
        orderable: true,
        targets: [0],
        className: "text-center",
    },
];

export default function CargaLayouts() {
    /* Permisos */
    GetPermissions();
    /* Estado vista */
    const [loading, setLoading] = useState(true);
    const [loadingTab, setLoadingTab] = useState(false);
    const [modal, setModal] = useState(false);
    /* Catalogos */
    const [layouts, setLayouts] = useState([]);
    const [conceptos, setconceptos] = useState([]);
    const [prestamos, setprestamos] = useState([]);
    const [instituciones, setinstituciones] = useState([]);
    const [seguros, setseguros] = useState([]);
    const [inasistencias, setinasistencias] = useState([]);
    const [pagos, setpagos] = useState([]);
    const [cuentas, setcuentas] = useState([]);
    const [bancos, setbancos] = useState([]);
    const [pensiones, setpensiones] = useState([]);
    const [licencias, setlicencias] = useState([]);
    const [incapacidades, setincapacidades] = useState([]);
    const [multas, setmultas] = useState([]);
    const [tiposDescuentos, seTiposDescuentos] = useState([]);

    /* Modal catalogos */
    const [modalCatalogo, setModalCatalogo] = useState(false);
    const [headersModal, setHeadersModal] = useState([]);
    const [columnasModal, setColumnasModal] = useState([]);
    const [titlemodal, settitlemodal] = useState("");
    const [selectedData, setSelectedData] = useState("");

    /* Validacion */
    const [validating, setValidating] = useState(0); // estado de validacion
    const [processing, setProcessing] = useState(0); // procentaje de validacion
    const [selectedF, setSelectedF] = useState(-1); // Archivo seleccionado / -1
    const [selectedName, setSelectedName] = useState(""); // Nombre Archivo seleccionado 
    const [data, setData] = useState(""); // Array de objetos del csv para enviar
    const [dataView, setDataView] = useState(""); // Array de objetos para mostrar
    const [invalidFile, setInvalidFile] = useState(""); // Mensaje de error par input
    const [errors, setErrors] = useState(-1); // Mensaje de error par input

    const ref = React.useRef();
    let fileReader;

    async function getCatalogo(keys) {
        let params = {};
        if (keys[0] === "unders") {
            params = {
                action: "multiselect",
                table: "unders A INNER JOIN rolloutunders B USING(keyRollUnder)",
                rows: "A.idUnder,CONCAT(A.under,' - ',A.nameUnder) AS nameUnderC, B.keyRollUnder, B.nameRollUnder",
                conditions:
                    "A.enabled = 1 AND B.keyRollUnder IN(1, 2, 3, 9, 10, 4, 7, 8, 6, 5, 13)",
            };
        } else {
            params = { action: "select", table: keys[0] };
            if (keys[4]) {// Condición extra
                params.condition = keys[4];
            }
        }
        const res = await backRequest(params, 1);
        const resclean = res.map((el) => {
            switch (keys[0]) {
                case "unders":
                    return {
                        clave: el[keys[1]],
                        name: el[keys[2]],
                        typeClave: el.keyRollUnder,
                        typeName: el.nameRollUnder,
                    }
                case "types_insurance":
                    return { clave: el[keys[1]], name: el[keys[2]], module: +el.idModule === 25 ? "EXP. PERSONAL" : "EXP. NOMINA" };
                default:
                    return { clave: el[keys[1]], name: el[keys[2]] }
            }
        });
        switchCatalogo(keys[3], function (catalogo, setcatalogo) {
            setcatalogo(resclean);
        });
        return resclean;
    }

    const getLayouts = async () => {
        const params = { 
            action: "multiselect",
            table: "layouts",
            rows: "*",
            conditions: "enabled = 1 AND nameLayouts != 'INCAPACIDADES'"
        };
        const res = await backRequest(params, 1);
        setLayouts(res);
        setLoading(false);
    };

    useEffect(() => {
        getLayouts();
    }, []);

    const handleFile = (e) => {
        try{
            if (e.target.files.length !== 0) {
                clearInfo();
                const mimeType = e.target.files[0].type;
                if ( mimeType.includes("csv") || ["text/csv", "application/csv", "text/plain", "application/vnd.ms-excel"].includes(mimeType)) {
                    setInvalidFile("");
                    fileReader = new FileReader();
                    fileReader.onloadend = handleFileRead;
                    fileReader.readAsText(e.target.files[0]);
                } else {
                    setInvalidFile("Formato de archivo invalido");
                }
            } else {
                clearInfo();
            }
        }catch(e){
            toast(<Notification type="error" backMessage="El documento cargado no es un archivo csv valido" withIcon />, { closeButton: false });
        }
    };

    const handleFileRead = (e) => {
        let incremental = false;
        csvtojson({
            noheader: true,
            headers: columns[selectedF - 1].bd,
        })
        .fromString(fileReader.result)
        .then((jsonOb) => {
                incremental = [4, 8].includes(+selectedF);
                if (!countColumns(jsonOb, columns[selectedF - 1].csv, incremental)) {
                    setInvalidFile("Cantidad de columnas incorrecta");
                } else if (
                    !compareColumns(jsonOb, columns[selectedF - 1].csv, incremental)
                ) {
                    setInvalidFile("Nombres de columnas incorrectos");
                } else if (countLines(jsonOb)) {
                    setInvalidFile("Archivo vacio");
                } else {
                    switch (selectedF) {
                        case 4:
                            ///Cmabiar keys de columnas de inasistencias
                            if (limitColumns(jsonOb, 43))
                                setInvalidFile("Un registro tiene mas de 15 inasistencias");
                            else setData(clearKeys(jsonOb, selectedF - 1));
                            break;
                        case 8:
                            setData(clearKeys(jsonOb, selectedF - 1));
                            break;
                        default:
                            setData(jsonOb); //Guarada la info con las mismas keys
                    }
                }
        });
    };

    // const clearKeysInas = (arr) => {
    //   const arrN = arr.map((el, ix) => {
    //     let nEl = { ...el };
    //     if (ix !== 0 && Object.values(el).length > 15) {
    //       const vals = Object.values(el);
    //       const keyss = Object.keys(el);
    //       let flg = 2;
    //       for (let i = 15; i < keyss.length; i += 2) {
    //         nEl[`I${flg}`] = vals[i];
    //         nEl[`T${flg}`] = vals[i + 1];
    //         delete nEl[keyss[i]];
    //         delete nEl[keyss[i + 1]];
    //         flg++;
    //       }
    //     }
    //     return nEl;
    //   });
    //   return arrN;
    // };

    const processFile = () => {
        setLoading(true);
        setValidating(1);
        if ([1, 4, 7, 8, 9].includes(+selectedF)) {
            validatePercepciones();
        } else if ([2, 3, 5, 6].includes(+selectedF)) {
            validatePrestamos();
        }
        setLoading(false);
    };

    const validatePercepciones = () => {
        let errores = 0;
        let total = data.length - 1;
        let idDuplic = -1;
        let auxData = [];
        let auxDataErr = [];
        const incremental = [4, 8].includes(+selectedF); // Para validaciónes con columnas variables
        let canCalculate = 0;
        let flagErrors = errores;
        data.forEach((obj, index) => {
            let cpObj = { ...obj };
            if (index !== 0) {
                idDuplic = isDuplicated(cpObj, data.slice(1, index));
                if (idDuplic !== -1) {
                    let objFnd = auxData.find((el) => el.idR === idDuplic + 2);
                    if (!objFnd) {
                        objFnd = auxDataErr.find((el) => el.idR === idDuplic + 2);
                        errores++;
                    }
                    if (objFnd.row.backgroundColor === undefined) {
                        objFnd.row = { backgroundColor: "#4d53e035" };
                    }
                    cpObj = { ...objFnd };
                } else {
                    cpObj.row = {};
                    cpObj["cols"] = {};
                    canCalculate = 0;
                    if (objComplete(cpObj,columns[selectedF - 1].csv.length + 2,incremental ? selectedF : null)  && !emptyRow(obj)) {
                        const nameKeys = incremental
                            ? columns[selectedF - 1].bd.slice(0, [selectedF - 1].split)
                            : columns[selectedF - 1].bd;
                        nameKeys.forEach((col) => {
                            if (notVoid(cpObj[col])) {
                                cpObj.cols[col] = "";
                                switch (col) {
                                    case "UNDER":
                                        const ex = conceptos.find((c) => +c.clave === +cpObj[col]);
                                        if (ex !== undefined) cpObj[col] = ex.name;
                                        else {
                                            cpObj[col] = "Concepto desconocido: " + cpObj[col];
                                            cpObj["cols"][col] = " text-danger font-weight-bold ";
                                            errores++;
                                        }
                                        break;
                                    case "TYPE":
                                        const [tl, texto] = getTypeCatalogo(+cpObj[col]);
                                        if (tl !== undefined)
                                            cpObj[col] = tl.clave + " - " + tl.name;
                                        else {
                                            cpObj[col] = `${texto} desconocida: ${cpObj[col]}`;
                                            cpObj["cols"][col] = " text-danger font-weight-bold ";
                                            errores++;
                                        }
                                        break;
                                    case "ABCENSEPERIOD":
                                    case "INITIALPERIOD":
                                    case "FINALPERIOD":
                                    case "PARTIALITIES":
                                    case "NODAYS":
                                    case "UMAS":
                                        if (!isPositiveInt(cpObj, col)) {
                                            cpObj["cols"][col] = " text-danger font-weight-bold ";
                                            canCalculate++;
                                        }
                                        cpObj["cols"][col] += " text-right";
                                        break;
                                    case "TOTALIMPORT":
                                    case "AMOUNTBIWEEKLY":
                                        if (!isPositive(cpObj, col)) {
                                            cpObj["cols"][col] = " text-danger font-weight-bold ";
                                            canCalculate++;
                                        } else {
                                            cpObj["cols"][col] += " text-right";
                                            cpObj[col] = cpObj[col].replaceAll(",","");
                                            obj[col] = obj[col].replaceAll(",","");
                                        }
                                        break;
                                    case "ABCENSEYEAR":
                                    case "INITIALYEAR":
                                    case "FINALYEAR":
                                        if (!isYear(cpObj, col)) {
                                            cpObj["cols"][col] = " text-danger font-weight-bold ";
                                            canCalculate++;
                                        }
                                        cpObj["cols"][col] += " text-center";
                                        break;
                                    case "CREATED":
                                    case "POLICYDATE": //7
                                    case "INICIO": //7
                                    case "TERMINO": //7
                                        if (!isDate(cpObj, col)) {
                                            cpObj["cols"][col] = " text-danger font-weight-bold ";
                                            errores++;
                                        }
                                        cpObj["cols"][col] += " text-center";
                                        break;
                                    case "DOCTOR":
                                        // if (!validName(cpObj, col)) {
                                        //     cpObj["cols"][col] = " text-danger font-weight-bold ";
                                        //     errores;
                                        // }
                                        break;
                                    case "AUXILIARFUND":
                                        if(!["SI", "NO"].includes(cpObj[col])){
                                            cpObj["cols"][col] = " text-danger font-weight-bold";
                                            errores++;
                                        }
                                        break;
                                    default:
                                }
                            } else {
                                // 7 - licencias | 8 - incapacidades | 9 - multas
                                if (
                                    [7, 8, 9].includes(selectedF) &&
                                    !columns[selectedF - 1].empty.includes(col)
                                ) {
                                    errores++;
                                    cpObj["cols"][col] = s.trDangerBg;
                                }
                            }
                        });
                        let next = true; // Solo para licencias. Si están mal las fechas, no hará lo de abajo
                        if (canCalculate === 0) {
                            if (+selectedF === 1 && !maxValue(+obj.INITIALPERIOD, 24)) {
                                // SOLO ESPECIAL EN EL 1 ->
                                errores = errores + svInitialPeriodM(cpObj);
                            } else {
                                if (incremental && +selectedF === 4) {
                                    if (
                                        !periodLowEqual(cpObj, "ABCENSEPERIOD", "INITIALPERIOD")
                                    ) {
                                        cpObj["cols"]["ABCENSEPERIOD"] =
                                            " text-danger font-weight-bold ";
                                        cpObj["cols"]["INITIALPERIOD"] =
                                            " text-danger font-weight-bold ";
                                        errores++;
                                    }
                                    if (validYears(cpObj, "ABCENSEYEAR", "INITIALYEAR") === 0) {
                                        cpObj["cols"]["ABCENSEYEAR"] =
                                            " text-danger font-weight-bold ";
                                        cpObj["cols"]["INITIALYEAR"] =
                                            " text-danger font-weight-bold ";
                                        errores++;
                                    }
                                }
                                if ([7, 8].includes(+selectedF)) {
                                    if (!validateDates(cpObj, "INICIO", "TERMINO")) {
                                        cpObj["cols"]["INICIO"] = " text-danger font-weight-bold ";
                                        cpObj["cols"]["TERMINO"] = " text-danger font-weight-bold ";
                                        errores++;
                                        next = false;
                                    }
                                }
                                if (next && ![8].includes(+selectedF)) {
                                    const typeCalc = validYears(
                                        cpObj,
                                        "INITIALYEAR",
                                        "FINALYEAR"
                                    );
                                    if (typeCalc !== 0) {
                                        let calcPerido = true;
                                        if (typeCalc === 1) {
                                            calcPerido = periodLow(
                                                cpObj,
                                                "INITIALPERIOD",
                                                "FINALPERIOD"
                                            );
                                        }
                                        if (calcPerido) {
                                            if ([7].includes(selectedF)) { // 8, 9
                                                if (!itsSameYear(cpObj, "INICIO", "INITIALYEAR")) {
                                                    cpObj["cols"]["INITIALYEAR"] =
                                                        " text-danger font-weight-bold ";
                                                    next = false;
                                                    errores++;
                                                } else if (
                                                    !validPeriod(cpObj, "INICIO", "INITIALPERIOD")
                                                ) {
                                                    cpObj["cols"]["INITIALPERIOD"] =
                                                        " text-danger font-weight-bold ";
                                                    next = false;
                                                    errores++;
                                                }
                                            }
                                            if (next) {
                                                const clcper = calcFinalPeriod(cpObj, typeCalc);
                                                if (clcper === 1) {
                                                    if (
                                                        !calcAmountQ(
                                                            cpObj,
                                                            "TOTALIMPORT",
                                                            "PARTIALITIES",
                                                            "AMOUNTBIWEEKLY"
                                                        )
                                                    ) {
                                                        cpObj["cols"]["AMOUNTBIWEEKLY"] =
                                                            " text-danger font-weight-bold ";
                                                        errores++;
                                                    } else if ([7].includes(selectedF)) { // 8, 9
                                                        [cpObj, errores] = calculateGoces(cpObj, errores);
                                                    }
                                                } else {
                                                    if (clcper === 2)
                                                        cpObj["cols"]["FINALPERIOD"] =
                                                            " text-danger font-weight-bold ";
                                                    if (clcper === 3) {
                                                        cpObj["cols"]["FINALPERIOD"] =
                                                            " text-danger font-weight-bold ";
                                                        cpObj["cols"]["FINALYEAR"] =
                                                            " text-danger font-weight-bold ";
                                                    }
                                                    errores++;
                                                }
                                            }
                                        } else {
                                            cpObj["cols"]["INITIALPERIOD"] =
                                                " text-danger font-weight-bold ";
                                            cpObj["cols"]["FINALPERIOD"] =
                                                " text-danger font-weight-bold ";
                                            errores++;
                                        }
                                    } else {
                                        cpObj["cols"]["INITIALYEAR"] =
                                            " text-danger font-weight-bold ";
                                        cpObj["cols"]["FINALYEAR"] =
                                            " text-danger font-weight-bold ";
                                        errores++;
                                    }
                                }
                            }
                        } else {
                            errores++;
                        }
                        if (incremental) {
                            switch (selectedF) {
                                case 4:
                                    [cpObj, errores] = extractAbcensesCols(cpObj, errores);
                                    break;
                                case 8:
                                    if (next) {
                                        [cpObj, errores] = extractIncapacitiesCols(
                                            cpObj,
                                            errores,
                                            tiposDescuentos
                                        );
                                    }
                                    break;
                                default:
                            }
                        }
                    } else {
                        cpObj.row = { backgroundColor: "#FF4B2335" };
                        errores++;
                    }
                }
                setProcessing(Math.ceil((index * 100) / total));
                cpObj.TOTALIMPORT = lcfMoney(cpObj.TOTALIMPORT);
                cpObj.AMOUNTBIWEEKLY = lcfMoney(cpObj.AMOUNTBIWEEKLY);
                //Ordenar regisros con errores al inicio
                cpObj.idR = index + 1;
                if (flagErrors !== errores) auxDataErr.push(cpObj);
                else auxData.push(cpObj);
                flagErrors = errores;
            }
        });
        setDataView(auxDataErr.concat(auxData));
        setErrors(errores);
        setValidating(2);
    };

    function getTypeCatalogo(id) {
        let catalogoc = [];
        let texto = "";
        if (selectedF === 7) {
            catalogoc = licencias;
            texto = "Licencia";
        } else if (selectedF === 8) {
            catalogoc = incapacidades;
            texto = "Incapacidad";
        }
        if (selectedF === 9) {
            catalogoc = multas;
            texto = "Multa";
        }
        const ex = catalogoc.find((c) => +c.clave === +id);
        return [ex, texto];
    }

    const extractAbcensesCols = (rw, errores) => {
        let ex = undefined; // Para buscar el registro
        let rwE = { ...rw };
        let inas = [];
        const vals = Object.values(rwE);
        const keys = Object.keys(rwE);
        let next = true;
        for (let i = 13; i < vals.length - 2; i += 2) {
            let aux = { I: "", T: "", IS: "", TS: "" };
            //Valida id inasistencia
            if (notVoid(vals[i])) {
                ex = inasistencias.find((c) => +c.clave === +vals[i]);
                if (ex !== undefined) aux.I = ex.name;
                else {
                    aux.I = "Ausencia desconocida";
                    aux.IS = " text-danger font-weight-bold ";
                    errores++;
                }
                next = true;
            } else {
                aux.IS = s.trDangerBg;
                errores++;
                next = false;
            }
            //valida fecha deacuerdo a tipo
            if(next){
                if (notVoid(vals[i + 1])){
                    if(+vals[i] === 1){ /// dd/mm/YYYY
                        if (!isDate(rwE, keys[i + 1])) {
                            aux.TS = " text-danger font-weight-bold ";
                            errores++;
                        } 
                    }else { // dd/mm/YYYY HH:mm:ss
                        if (!isDateTime(rwE, keys[i + 1])) {
                            aux.TS = " text-danger font-weight-bold ";
                            errores++;
                        } 
                    }
                } else {
                    aux.TS = s.trDangerBg;
                    errores++;
                }
            }
            //valida fecha inasistencia
            aux.T = vals[i + 1];
            
            inas.push(aux);
            delete rwE[keys[i]];
            delete rwE[keys[i + 1]];
        }
        rwE.sons = inas;
        if (inas.length !== rwE.NODAYS) {
            if (rwE.cols.NODAYS === undefined) {
                rwE.cols.NODAYS = " text-danger font-weight-bold ";
            }
        }
        if(+rwE.NODAYS !== rwE.sons.length){
            rwE.cols.NODAYS += " text-danger "
            errores++;
        }
        return [rwE, errores];
    };

    const validatePrestamos = () => {
        let errores = 0;
        let total = data.length - 1;
        let idDuplic = -1;
        let auxData = [];
        let auxDataErr = [];
        let flagErrors = errores;
        data.forEach((obj, index) => {
            let cpObj = { ...obj };
            if (index !== 0) {
                idDuplic = isDuplicated(cpObj, data.slice(1, index));
                if (idDuplic !== -1) {
                    let objFnd = auxData.find((el) => el.idR === idDuplic + 2);
                    if (!objFnd) {
                        objFnd = auxDataErr.find((el) => el.idR === idDuplic + 2);
                        errores++;
                    }
                    if (objFnd.row.backgroundColor === undefined) {
                        objFnd.row = { backgroundColor: "#4d53e035" };
                    }
                    cpObj = { ...objFnd };
                } else {
                    cpObj.row = {};
                    cpObj["cols"] = {};
                    let canCalculate = 0;
                    if (objComplete(cpObj, columns[selectedF - 1].csv.length + 2) && !emptyRow(obj)) {
                        columns[selectedF - 1].bd.forEach((col) => {
                            if (notVoid(cpObj[col])) {
                                cpObj.cols[col] = "";
                                switch (col) {
                                    case "UNDER":
                                        const ex = conceptos.find((c) => +c.clave === +cpObj[col]);
                                        if (ex !== undefined) cpObj[col] = ex.name;
                                        else {
                                            cpObj[col] = "Concepto desconocido: " + cpObj[col];
                                            cpObj["cols"][col] = " text-danger font-weight-bold ";
                                            errores++;
                                        }
                                        break;
                                    case "KEYTYPELOAN":
                                        const pr = prestamos.find((c) => +c.clave === +cpObj[col]);
                                        if (pr !== undefined) cpObj[col] = pr.name;
                                        else {
                                            cpObj[col] = "Prestamo desconocido: " + cpObj[col];
                                            cpObj["cols"][col] = " text-danger font-weight-bold ";
                                            errores++;
                                        }
                                        break;
                                    case "KEYTYPEINSURANCE":
                                        const isu = seguros.find((c) => +c.clave === +cpObj[col]);
                                        if (isu !== undefined) cpObj[col] = isu.name;
                                        else {
                                            cpObj[col] = "Prestamo desconocido: " + cpObj[col];
                                            cpObj["cols"][col] = " text-danger font-weight-bold ";
                                            errores++;
                                        }
                                        break;
                                    case "INSTITUTION":
                                        const ins = instituciones.find(
                                            (c) => +c.clave === +cpObj[col]
                                        );
                                        if (ins !== undefined) cpObj[col] = ins.name;
                                        else {
                                            cpObj[col] = "Institución desconocida: " + cpObj[col];
                                            cpObj["cols"][col] = " text-danger font-weight-bold ";
                                            errores++;
                                        }
                                        break;
                                    case "PAYMENTTYPE":
                                        const bc = pagos.find((c) => +c.clave === +cpObj[col]);
                                        if (bc !== undefined) cpObj[col] = bc.name;
                                        else {
                                            cpObj[col] = "Tipo de pago desconocido: " + cpObj[col];
                                            cpObj["cols"][col] = " text-danger font-weight-bold ";
                                            errores++;
                                        }
                                        break;
                                    case "ACCOUNTTYPE":
                                        if (+obj["PAYMENTTYPE"] === 1) {
                                            const ct = cuentas.find((c) => +c.clave === +cpObj[col]);
                                            if (ct !== undefined) cpObj[col] = ct.name;
                                            else {
                                                cpObj[col] =
                                                    "Tipo de cuenta desconocida: " + cpObj[col];
                                                cpObj["cols"][col] = " text-danger font-weight-bold ";
                                                errores++;
                                            }
                                        } else {
                                            cpObj[col] = "Tipo de cuenta no requerido";
                                            cpObj["cols"][col] = " text-danger font-weight-bold ";
                                            errores++;
                                        }
                                        break;
                                    case "BANK":
                                        if (+obj["PAYMENTTYPE"] === 1) {
                                            const bk = bancos.find((c) => +c.clave === +cpObj[col]);
                                            if (bk !== undefined) cpObj[col] = bk.name;
                                            else {
                                                cpObj[col] = "Banco desconocido: " + cpObj[col];
                                                cpObj["cols"][col] = " text-danger font-weight-bold ";
                                                errores++;
                                            }
                                        } else {
                                            cpObj[col] = "BANCO no requerido";
                                            cpObj["cols"][col] = " text-danger font-weight-bold ";
                                            errores++;
                                        }
                                        break;
                                    case "TYPEPENSION":
                                        const tp = pensiones.find((c) => +c.clave === +cpObj[col]);
                                        if (tp !== undefined) cpObj[col] = tp.name;
                                        else {
                                            cpObj[col] = "Tipo de pensión desconocida: " + cpObj[col];
                                            cpObj["cols"][col] = " text-danger font-weight-bold ";
                                            errores++;
                                        }
                                        break;
                                    case "AMOUNTBIWEEKLY":
                                    case "TOTALDISCOUNT":
                                        if (!isPositive(cpObj, col)) {
                                            cpObj["cols"][col] = " text-danger font-weight-bold ";
                                            canCalculate++;
                                        } else {
                                            cpObj["cols"][col] += " text-right";
                                            cpObj[col] = cpObj[col].replaceAll(",","");
                                            obj[col] = obj[col].replaceAll(",","");
                                        }
                                        break;
                                    case "NOPAYS":
                                    case "NOCHILDREN":
                                        if (!isPositiveInt(cpObj, col)) {
                                            cpObj["cols"][col] = " text-danger font-weight-bold ";
                                            canCalculate++;
                                        }
                                        cpObj["cols"][col] += " text-right";
                                        break;
                                    case "CREATED":
                                    case "INICIO":
                                    case "TERMINO":
                                    case "DATEPOLIZA":
                                        if (!isDate(cpObj, col)) {
                                            cpObj["cols"][col] = " text-danger font-weight-bold ";
                                            if (col === "INICIO" || col === "TERMINO") canCalculate++;
                                            errores++;
                                        }
                                        cpObj["cols"][col] += " text-center";
                                        break;
                                    // case "STAFFNAME": /// DESACTIVADO PARA PRUEBAS
                                    case "NAME":
                                    case "PNAME":
                                    case "MNAME":
                                        if (!validName(cpObj, col)) {
                                            cpObj["cols"][col] = " text-danger font-weight-bold ";
                                        }
                                        break;
                                    case "ACCOUNT":
                                        if (+obj["PAYMENTTYPE"] === 1) {
                                            if (+obj["ACCOUNTTYPE"] === 1) {
                                                if (!isClabe(cpObj, col)) {
                                                    cpObj["cols"][col] = " text-danger font-weight-bold ";
                                                    errores++;
                                                }
                                            } else if (+obj["ACCOUNTTYPE"] === 2) {
                                                if (!isAccount(cpObj, col)) {
                                                    cpObj["cols"][col] = " text-danger font-weight-bold ";
                                                    errores++;
                                                }
                                            }
                                        } else {
                                            cpObj[col] = "CUENTA/CLABE no requerida";
                                            cpObj["cols"][col] = " text-danger font-weight-bold ";
                                            errores++;
                                        }
                                        break;
                                    case "AMOUNT": //PENSIONES
                                        if (!isPositive(cpObj, col)) {
                                            cpObj["cols"][col] = " text-danger font-weight-bold ";
                                            canCalculate++;
                                        } else {
                                            cpObj["cols"][col] += " text-right";
                                            if (+obj["TYPEPENSION"] === 1) {
                                                cpObj[col] = lcfMoney(cpObj[col]);
                                                cpObj[col] = cpObj[col].replaceAll(",","");
                                                obj[col] = obj[col].replaceAll(",","");
                                            } else {
                                                cpObj[col] =
                                                    Number.parseFloat(cpObj[col]).toFixed(2) + "%";
                                                if (+obj[col] > 100) {
                                                    cpObj[col] = "Porcentaje invalido: " + obj[col] + "%";
                                                    cpObj["cols"][col] = " text-danger font-weight-bold ";
                                                    errores++;
                                                }
                                            }
                                        }
                                        break;
                                    case "POLIZA": 
                                        default: cpObj.cols[col] = cpObj.cols[col].toString();
                                }
                            } else {
                                // 2 - prestamos | 3 - seguros | 6 - pensiones
                                if (
                                    [2, 3, 6].includes(selectedF) &&
                                    !columns[selectedF - 1].empty.includes(col)
                                ) {
                                    if (cpObj) {
                                        if (col !== "NOTES") {

                                            cpObj["cols"][col] = s.trDangerBg;
                                            errores++;
                                        }
                                    }
                                }
                            }
                        });
                        if (canCalculate === 0) {
                            if (selectedF === 6) {
                                if (!validateDates(cpObj, "INICIO", "TERMINO")) {
                                    cpObj["cols"]["INICIO"] = " text-danger font-weight-bold ";
                                    cpObj["cols"]["TERMINO"] = " text-danger font-weight-bold ";
                                    errores++;
                                }
                            } else if(selectedF !== 3 ){
                                if (calcPeriods(cpObj, "INICIO", "TERMINO", "NOPAYS")) {
                                    if (!calcAmountQ( cpObj, "TOTALDISCOUNT", "NOPAYS", "AMOUNTBIWEEKLY")) {
                                        cpObj["cols"]["AMOUNTBIWEEKLY"] = " text-danger font-weight-bold ";
                                        errores++;
                                    }
                                } else {
                                    cpObj["cols"]["INICIO"] = " text-danger font-weight-bold ";
                                    cpObj["cols"]["TERMINO"] = " text-danger font-weight-bold ";
                                    errores++;
                                }
                            }
                        } else {
                            errores++;
                        }
                    } else {
                        cpObj.row = { backgroundColor: "#FF4B2335" };
                        errores++;
                    }
                }
                setProcessing((index * 100) / total);
                if (cpObj.TOTALDISCOUNT) cpObj.TOTALDISCOUNT = lcfMoney(cpObj.TOTALDISCOUNT);
                if (cpObj.AMOUNTBIWEEKLY) cpObj.AMOUNTBIWEEKLY = lcfMoney(cpObj.AMOUNTBIWEEKLY);
                //Ordenar regisros con errores al inicio
                cpObj.idR = index + 1;
                if (flagErrors !== errores) auxDataErr.push(cpObj);
                else auxData.push(cpObj);
                flagErrors = errores;
            }
        });
        setDataView(auxDataErr.concat(auxData));
        setErrors(errores);
        setValidating(2);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setModal(true);
    };

    const enviaInfo = async () => {
        const rowsData = data.slice(1);
        if(selectedF === 9){
            rowsData.forEach((d) => {
                d.AUXILIARFUND = +(d.AUXILIARFUND === "SI");
            });
        }
        const params = {
            action: columns[selectedF - 1].action,
            rows: rowsData,
        };
        setLoadingTab(true);
        const info = await backRequest(params, 0, true);
        setLoadingTab(false);
        if (info === "success") {
            setValidating(3);
        } else if(info === "conexion_error"){
            setValidating(500);
        }
    };

    function handleClear() {
        ref.current.value = "";
        clearInfo();
    }

    const clearInfo = () => {
        if (validating > 0) {
            setValidating(0);
            setProcessing(0);
        }
        setData("");
        setDataView("");
        setInvalidFile("");
        setErrors(-1);
    };

    async function downloadLayout(el) {
        const nlc = el.nameLayouts.toLowerCase();
        saveAs(el.src, `${nlc}.csv`);
    }

    async function selectLaoyut(el) {
        if (+el.idLayout === selectedF) return;
        const nlc = el.nameLayouts.toLowerCase();
        handleClear();
        setSelectedF(+el.idLayout);
        setSelectedName(el.nameLayouts);
        setLoading(true);
        getCatalogo(namesKeys["conceptos"]);
        await findCatalogo(nlc);

        switch (nlc) {
            case "pensiones":
                getCatalogo(namesKeys["bancos"]);
                if (pagos.length < 1) {
                    getCatalogo(namesKeys["pagos"]);
                    getCatalogo(namesKeys["cuentas"]);
                }
                break;
            case "seguros":
            case "prestamos":
                getCatalogo(namesKeys["instituciones"]);
                break;
            case "incapacidades":
                if (incapacidades.length < 1) getCatalogo(namesKeys["descuentos"]);
                break;
            default:
        }

        setLoading(false);
    }

    function switchCatalogo(name, fun) {
        switch (name) {
            case "conceptos":
                fun(conceptos, setconceptos);
                break;
            case "prestamos":
                fun(prestamos, setprestamos);
                break;
            case "instituciones":
                fun(instituciones, setinstituciones);
                break;
            case "seguros":
                fun(seguros, setseguros);
                break;
            case "inasistencias":
                fun(inasistencias, setinasistencias);
                break;
            case "pagos":
                fun(pagos, setpagos);
                break;
            case "cuentas":
                fun(cuentas, setcuentas);
                break;
            case "bancos":
                fun(bancos, setbancos);
                break;
            case "pensiones":
                fun(pensiones, setpensiones);
                break;
            case "licencias":
                fun(licencias, setlicencias);
                break;
            case "incapacidades":
                fun(incapacidades, setincapacidades);
                break;
            case "multas/sanciones":
                fun(multas, setmultas);
                break;
            case "descuentos":
                fun(tiposDescuentos, seTiposDescuentos);
                break;
            default:
                fun(false, null);
        }
    }

    async function findCatalogo(nl) {
        let cpyCatalogo = [];
        const namekey = nl === "multas/sanciones" ? "multas" : nl;
        if (namesKeys[namekey]) cpyCatalogo = await getCatalogo(namesKeys[namekey]);
        return cpyCatalogo;
    }

    /* Componente row catalogo*/
    async function showModalCat(name) {
        setLoading(true);
        const cat = await findCatalogo(name.toLowerCase());
        setSelectedData(cat);
        var titulos = [], columnas = [];
        switch (name.toLowerCase()) {
            case "conceptos":
                titulos = ["Clave", "Tipo", "Nombre"];
                columnas = [
                    { data: "clave" },
                    { data: "typeName" },
                    { data: "name" },
                ];
                break;
            case "seguros":
                titulos = ["Módulo", "Clave", "Nombre"];
                columnas = [
                    { data: "module" },
                    { data: "clave" },
                    { data: "name" }
                ];
                break;
            default:
                titulos = ["Clave", "Nombre"];
                columnas = [{ data: "clave" }, { data: "name" }];
        }
        setHeadersModal(titulos);
        setColumnasModal(columnas);

        setLoading(false);
        settitlemodal(name);
        setModalCatalogo(true);
    }

    async function downloadCatalogo(name) {
        setLoading(true);
        const cat = await findCatalogo(name.toLowerCase());
        var titulos = [], columnas = [];
        switch (name.toLowerCase()) {
            case "conceptos":
                titulos = ["Clave", "Tipo", "Nombre"];
                columnas = ["clave", "typeName", "name"];
                break;
            case "seguros":
                titulos = ["Módulo", "Clave", "Nombre"];
                columnas = ["module", "clave", "name"];
                break;
            default:
                titulos = ["Clave", "Nombre"];
                columnas = ["clave", "name"];
        }

        let filet = titulos.join(",") + "\n";
        cat.forEach((d) => {
            columnas.forEach((c) => {
                filet += `${d[c]},`;
            });
            filet += "\n";
        });
        const blov = new Blob([new Uint8Array([0xef, 0xbb, 0xbf]), filet], {
            type: "data:text/csv;charset=utf-8",
        });
        saveAs(blov, `catalogo-${name.toLowerCase()}.csv`);
        setLoading(false);
    }

    function RowCatalogo({ name }) {
        const fullname = ["BANCOS", "INSTITUCIONES"].includes(name)
            ? name
            : `TIPOS DE ${name}`;
        return (
            <tr className="text-center">
                <td className="border-right border-default text-left">{fullname}</td>
                <td className="border-right border-default">
                    <i
                        className="fa fa-file-excel-o cursor-pointer"
                        style={{ color: "#086400" }}
                        onClick={() => downloadCatalogo(name)}
                    />
                </td>
                <td>
                    <i
                        className="fa fa-eye text-info cursor-pointer"
                        onClick={() => showModalCat(name)}
                    />
                </td>
            </tr>
        );
    }

    return (
        <>
            <Widget className="widget-p-md">
                <div className="text-center text-md-left mb-3">
                    <h2 className="text-secondary">Carga de Layouts</h2>
                </div>
                <Row className="m-0">
                    <Col xs={12} md={6}>
                        <Table striped responsive className="border border-default">
                            <thead className="bg-default">
                                <tr>
                                    <th className="w-75 text-light border-right border-light">
                                        Plantilla
                                    </th>
                                    <th className="w-25 text-light">Descargar</th>
                                </tr>
                            </thead>
                            <tbody className="">
                                {layouts.length > 0 ? (
                                    layouts.map((el, i) => (
                                        <tr key={"lr" + i} className={+selectedF === +el.idLayout ? s.rowSelected : ""} >
                                            <td
                                                className={ "border-right border-default cursor-pointer " + s.rowLayout }
                                                onClick={() => selectLaoyut(el)}
                                            >
                                                {el.nameLayouts}
                                            </td>
                                            <td className="text-center">
                                                <i
                                                    className="fa fa-file-excel-o cursor-pointer"
                                                    style={{ color: "#086400" }}
                                                    onClick={() => downloadLayout(el)}
                                                />
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr></tr>
                                )}
                            </tbody>
                        </Table>
                    </Col>
                    <Col xs={12} md={6}>
                        <Table
                            striped
                            responsive
                            className="border border-default mt-3 mt-md-0"
                        >
                            <thead className="bg-default">
                                <tr>
                                    <th className="text-light border-right border-white">
                                        Catálogo
                                    </th>
                                    <th className="text-light border-right border-white">
                                        Descargar
                                    </th>
                                    <th className="text-light text-center">Ver</th>
                                </tr>
                            </thead>
                            <tbody>
                                <RowCatalogo name="BANCOS" />
                                <RowCatalogo name="INSTITUCIONES" />
                                <RowCatalogo name="CONCEPTOS" />
                                <RowCatalogo name="CUENTAS" />
                                <RowCatalogo name="DESCUENTOS" />
                                <RowCatalogo name="INASISTENCIAS" />
                                {/* <RowCatalogo name="INCAPACIDADES" /> */}
                                <RowCatalogo name="LICENCIAS" />
                                <RowCatalogo name="MULTAS/SANCIONES" />
                                <RowCatalogo name="PAGOS" />
                                <RowCatalogo name="PENSIONES" />
                                <RowCatalogo name="PRESTAMOS" />
                                <RowCatalogo name="SEGUROS" />
                            </tbody>
                        </Table>
                    </Col>
                </Row>
                <Form className="mt-5" onSubmit={handleSubmit}>
                    <ModalConfirmation
                        modalTitle="Cargar layout"
                        modal={modal}
                        setModal={setModal}
                        crear={enviaInfo}
                    >
                        <div className="d-flex justify-content-center">
                            <h6>¿Está seguro de cargar estos registros?</h6>
                        </div>
                    </ModalConfirmation>
                    <h6 className="mx-3">
                        Cargar archivo:{" "}
                        <span className="text-primary">
                            {selectedF >= 1 ? selectedName : ""}
                        </span>
                    </h6>
                    <FormGroup>
                        <Row className="mx-1">
                            <Col xs={12} md={7}>
                                <FormGroup className="position-relative">
                                    <Input
                                        type="file"
                                        name="file"
                                        onChange={handleFile}
                                        accept=".csv"
                                        invalid={invalidFile !== ""}
                                        innerRef={ref}
                                        disabled={selectedF < 1}
                                        className="form-control"
                                        onClick={() => handleClear()}
                                    />
                                    {ref && ref.current && ref.current.value !== "" && (
                                        <CancelOutlined
                                            className={
                                                invalidFile !== "" ? is.btnClear : is.btnClearErr
                                            }
                                            onClick={handleClear}
                                        />
                                    )}
                                    <FormFeedback>{invalidFile}</FormFeedback>
                                </FormGroup>
                            </Col>
                            <Col xs={12} md={5} className="text-center">
                                <Button
                                    color="default"
                                    type="button"
                                    onClick={() => {
                                        processFile();
                                    }}
                                    disabled={invalidFile !== "" || data === ""}
                                >
                                    Validar
                                </Button>
                            </Col>
                        </Row>
                    </FormGroup>
                    <div className=" mt-5">
                        {validating === 1 ? (
                            <div className="w-75 m-5 mx-auto text-center">
                                <Progress
                                    color="primary"
                                    striped
                                    animated
                                    className="m-5"
                                    value={processing}
                                >
                                    {processing}%
                                </Progress>
                            </div>
                        ) : validating === 2 ? (
                            <>
                                {errors > 0 && (
                                    <table className="table-borderless my-1">
                                        <tbody>
                                            <tr>
                                                <td className={s.trDangerBg}></td>
                                                <td className="pl-1">Campos faltantes</td>
                                            </tr>
                                            <tr>
                                                <td className=" text-danger font-weight-bold  text-center px-1">
                                                    Texto
                                                </td>
                                                <td className="pl-1">
                                                    Información incorrecta o desconocida
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className={s.trPrimaryBg}></td>
                                                <td className="pl-1">Registro repetido</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                )}
                                {
                                    [4, 8].includes(selectedF) ? (
                                        <DesplegableTable
                                            columns={columns}
                                            selectedF={selectedF - 1}
                                            dataView={dataView}
                                            datal={data.length}
                                        />
                                    ) : (
                                        <SimpleTable
                                            columns={columns}
                                            selectedF={selectedF - 1}
                                            dataView={dataView}
                                            datal={data.length}
                                        />
                                    )}
                            </>
                        ) : validating === 3 ? (
                            <div className="text-center my-5">
                                <h6>Información cargada con exito.</h6>
                            </div>
                        ) : validating === 500 && (
                            <div className="text-center my-5">
                                <h6>Ocurrió un problema de conexión con el sevidor</h6>
                                <p>Verifique que la información se haya cargado correctamente</p>
                            </div>
                        )}
                    </div>
                    {invalidFile === "" &&
                        data !== "" &&
                        errors === 0 &&
                        validating < 3 && (
                            <div className="text-center mt-2">
                                <Button color="success" type="submit">
                                    Guardar
                                </Button>
                            </div>
                        )}
                </Form>
            </Widget>

            <ModalDataTableVerF
                modal={modalCatalogo}
                setModal={setModalCatalogo}
                title={"Catáligo " + titlemodal}
                keyboard
                btnClose
                headers={headersModal}
                columns={columnasModal}
                columnDefs={columnDefs}
                data={selectedData}
            />

            <FullFormLoader show={loading || loadingTab} />
        </>
    );
}

import * as XlsxPopulate from "xlsx-populate";
import { saveAs } from "file-saver";
import { number2Alphabet } from "../../tools/strings";
import { sumValues } from "../../tools/arrays";

/**
 * Formateador de numeros de concurrencias
 */
const formatter = new Intl.NumberFormat("es-MX", {
  style: "currency",
  currency: "MXN",
});

/**
 * Hoja de estilos del excel
 */
const styles = {
  title: {
    horizontalAlignment: 'center', bold: true
  },
  headerBorder: {
    bold: "true",
    border: true,
    fill: "BFBFBF",
  },
  header: {
    bold: "true",
    border: true,
    fill: "BFBFBF",
  },
  headerBoderBold: {
    bold: "true",
    border: true,
  },
  contentBorder: {
    border: true,
  },
  contentBorderB: {
    border: true,
    bold: true
  },
  contentBorderRigth: {
    border: true,
    horizontalAlignment: 'right'
  },
  contentBorderRigthB: {
    border: true,
    horizontalAlignment: 'right',
    bold: true
  },
};

/**
 * 
 * @param {Reporte entregado de la BD} report 
 * @param {Titulo (Nombre de la institución)} title 
 * @param {Parametros pasados a la BD para su consulta} data 
 * @param {configuración de headers} config 
 */
export async function saveGeneralReport(report, title, data, config) {


  //Inicio de excel
  XlsxPopulate.fromBlankAsync().then(async (workbook) => {
    const sheet1 = workbook.sheet(0);

    const hl = config.headers.length;

    //Titulo
    sheet1
      .range(`A1:${number2Alphabet(hl-1)}2`)
      .merged(true)
      .value("SAPP")
      .style(styles.title);
    //Subtitulos
    sheet1
      .range(`A3:${number2Alphabet(hl-1)}3`)
      .merged(true)
      .value(title)
    sheet1
      .range(`A4:${number2Alphabet(hl-1)}4`)
      .merged(true)
      .value(config.subTitle)
    
    
    //Cabeceras
    config.headers.forEach((header, index) => {
      sheet1
      .cell(`${number2Alphabet(index)}6`)
      .value(header)
      .style(styles.headerBoderBold)
    });

    const totales = config.sum.map(key => { return { value: sumValues(report, key), format: true } });
    if (totales.length > 0) {
      totales.unshift({value: "TOTAL", format: false});

      while (totales.length < hl) {
        totales.unshift({value: "", format: false});
      }
    }
    
    
    //Data
    report.forEach((value, index) => {
      config.content.forEach((key, ind) => {
        sheet1
        .cell(`${number2Alphabet(ind)}${index + 7}`)
        .value(!key.format ? value[key.value] : formatter.format(value[key.value]))
        .style(!key.format ? styles.contentBorder : styles.contentBorderRigth)
      })
    })

    totales.forEach((value, ind) => {
      sheet1
      .cell(`${number2Alphabet(ind)}${report.length + 7}`)
      .value(!value.format ? value.value : formatter.format(value.value))
      .style(!value.format ? styles.contentBorderB : styles.contentBorderRigthB)
    })
    
    config.sizes.forEach((size, index) => {
      sheet1.column(`${number2Alphabet(index)}`).width(size);  
    })
    
    return workbook.outputAsync().then((res) => {
        saveAs(res, `${title}.xlsx`);
    });
  });
}
import { useState } from "react";
import Widget from "../../../components/Widget/Widget";
import { useFormik } from "formik";
import * as Yup from "yup";
import { doRequest } from "../../../helpers/requests";
import { useHistory } from "react-router-dom";
import { Button, Col, Row } from "reactstrap";
import { SelectTypeHeadSingle, TextInput } from "../../../components/GenericInputsFormik";
import { useEffect } from "react";
import FullFormLoader from "../../../components/Loader/FullFormLoader";
import ModalConfirmation from "../../../components/Modal/ModalConfirmation";

const defaultForm = {
    keySeverity: "",
    nameSeverity: "",
    maxAttentionTime: "",
    maxSolutionTime: ""
};

const FormSchema = Yup.object().shape({
    nameSeverity: Yup.string()
        .required("Ingrese una descripción para la severidad")
        .min(3, "Ingrese al menos 3 caracteres")
        .max(200, "Longitud maxima de 200 caracteres excedida"),
    maxAttentionTime: Yup.string().required("Ingrese el tiempo maximo de atención"),
    maxSolutionTime: Yup.string().required("Ingrese el tiempo maximo de solución")
});

const hours = [
    {val: "01:00:00"},
    {val: "02:00:00"},
    {val: "03:00:00"},
    {val: "04:00:00"},
    {val: "05:00:00"},
    {val: "06:00:00"},
    {val: "07:00:00"},
    {val: "08:00:00"},
    {val: "09:00:00"},
    {val: "10:00:00"},
    {val: "11:00:00"},
    {val: "12:00:00"},
    {val: "13:00:00"},
    {val: "14:00:00"},
    {val: "15:00:00"},
    {val: "16:00:00"},
    {val: "17:00:00"},
    {val: "18:00:00"},
    {val: "19:00:00"},
    {val: "20:00:00"},
    {val: "21:00:00"},
    {val: "22:00:00"},
    {val: "23:00:00"},
]

export default function FormSeveridad() {
    const [loading, setLoading] = useState(true);
    const [modal, setModal] = useState(false);
    const [title, setTitle] = useState("Crear");
    const history = useHistory();

    const {
        handleSubmit,
        values,
        handleBlur,
        errors,
        touched,
        setFieldValue,
        handleReset,
        setValues,
    } = useFormik({
        initialValues: defaultForm,
        validationSchema: FormSchema,
        onSubmit: () => setModal(true),
        onReset: () => { localStorage.removeItem('dataContent'); history.goBack(); },
        enableReinitialize: true,
    });
    
    useEffect(()=>{
        const data = localStorage.getItem('dataContent');
        if(data){
            const ec = data.split('|-|');
            setValues({
                keySeverity: ec[0],
                nameSeverity: ec[1],
                maxAttentionTime: ec[2],
                maxSolutionTime: ec[3]
            }, true);
        }
        setLoading(false);
    }, []);

    function onChange({target}){setFieldValue(target.name, target.value)}

    async function save() {
        setLoading(true);
        const params = {
            action: "insert",
            table: "types_severity",
            rows: {
                nameSeverity: values.nameSeverity,
                maxAttentionTime: values.maxAttentionTime,
                maxSolutionTime: values.maxSolutionTime
            },
            validate: [["nameSeverity"]]
        };
        if(values.keySeverity){
            params.action = "update";
            params.condition = {keySeverity: values.keySeverity}
        }
        const res = await doRequest("receiver/receiver.php", params, true);
        setLoading(false);
        if (res.length > 0) handleReset();
    }

    return (
        <Widget className="widget-p-md">
            <div className="mb-3">
                <h3>{title} severidad</h3>
            </div>

            <form onSubmit={handleSubmit} onReset={handleReset}>
                <Row>
                    <Col xs={12} md={4}>
                        <TextInput
                            label="Descripción"
                            inputType="text"
                            inputName="nameSeverity"
                            onChangeMethod={onChange}
                            onBlurMethod={handleBlur}
                            value={values.nameSeverity}
                            touched={touched.nameSeverity}
                            errors={errors.nameSeverity}
                            isRequired
                        />
                    </Col>
                    <Col xs={12} md={4}>
                        <SelectTypeHeadSingle
                            label="Tiempo de atención"
                            inputName="maxAttentionTime"
                            onChangeMethod={ onChange }
                            onBlurMethod={handleBlur}
                            value={values.maxAttentionTime}
                            isRequired={true}
                            touched={touched.maxAttentionTime}
                            errors={errors.maxAttentionTime}
                            optionsArray={hours}
                            optionValue="val"
                            optionName="val"
                            defaultOption="Seleccione el tiempo de atención"
                        />
                    </Col>
                    <Col xs={12} md={4}>
                    <SelectTypeHeadSingle
                            label="Tiempo de solución"
                            inputName="maxSolutionTime"
                            onChangeMethod={ onChange }
                            onBlurMethod={handleBlur}
                            value={values.maxSolutionTime}
                            isRequired={true}
                            touched={touched.maxSolutionTime}
                            errors={errors.maxSolutionTime}
                            optionsArray={hours}
                            optionValue="val"
                            optionName="val"
                            defaultOption="Seleccione el tiempo de solución"
                        />
                    </Col>
                </Row>
                
                <div className=" d-flex flex-md-row flex-column-reverse justify-content-md-around">
                    <Button color="danger" type="reset" className="mt-3">
                        Cancelar
                    </Button>
                    <Button color="success" type="submit" className="mt-2 mt-md-3">
                        Guardar
                    </Button>
                </div>
            </form>

            <ModalConfirmation
                modalTitle={"Guardar tipo de severidad"}
                modal={modal}
                crear={save}
                setModal={setModal}
                values={values}
            >
                <div className="d-flex justify-content-center">
                    <h6>¿Desea guardar el registro?</h6>
                </div>
            </ModalConfirmation>

            <FullFormLoader show={loading}/>
        </Widget>
    );
}

import * as Yup from "yup";
import { useFormik } from "formik";
import { Button, ModalBody, ModalFooter } from "reactstrap";
import { TextInput } from "../../../components/GenericInputsFormik";
import { toast } from "react-toastify";
import Notification from "../../../components/Notification/Notification";
import { doRequest } from "../../../helpers/requests";
import { useEffect, useState } from "react";
import Camera from "../../../components/Modal/ModalCamera";

const defaultform = {
    email: "",
    pass: "",
    descriptor: "",
    captura: ""
};
const FormSchema = Yup.object().shape({
    pass: Yup.string().required("Ingrese la contraseña de clave privada"),
    email: Yup.string().required("Ingrese su correo"),
});

export default function FormFacial({
    modal,
    setModal,
    selected,
    setselected,
    all,
    setAll,
    paramsAll,
    getConditions,
    setRefresh,
    setLoading,
    setFilesSigneds,
    setModalSigned
}) {

    const [show, setShow] = useState(false);

    const {
        handleSubmit,
        handleReset,
        handleBlur,
        values,
        errors,
        touched,
        setFieldValue,
    } = useFormik({
        initialValues: defaultform,
        onSubmit: () => {
            signFiles();
        },
        validationSchema: FormSchema,
        enableReinitialize: true,
    });

    useEffect(() => {
        if (!modal) handleReset();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [modal]);

    async function signFiles() {
        setLoading(true);
        let rutes = [];
        if (all) {
            const params = {
                ...paramsAll,
                conditions: getConditions(paramsAll.conditions),
                records: 9999,
            };
            rutes = await doRequest("receiver/receiver.php", params, false, false);
            if (rutes.length < 1) {
                toast(
                    <Notification
                        type={"consultar_error"}
                        backMessage="No fue posible obtener todos los documentos para firmar"
                        withIcon
                    />,
                    { closeButton: false }
                );
                return;
            }
        } else if (selected.length > 0) rutes = [...selected];
        const formData = new FormData();
        formData.append("action", "requestSignbyFace");
        formData.append("email", values.email);
        formData.append("descriptor", JSON.stringify(values.descriptor));
        formData.append("pass", values.pass);
        formData.append("files", JSON.stringify(rutes));

        const res = await doRequest(
            "app/facades/sign/signF.php",
            formData,
            false,
            true,
            false
        );
        setLoading(false);
        console.log(res);
        let filesOk = 0;
        let filesError = 0;
        let listaerrors = [];
        if (res.length > 0) {
            res.forEach((r) => {
                if (r.code !== 200) {
                    filesError++;
                    listaerrors.push(r);
                } else filesOk++;
            });
            toggle();
            setselected([]);
            setAll(false);
            setRefresh(true);
            setFilesSigneds({
                ok: filesOk,
                error: filesError,
                list: listaerrors,
            });
            setModalSigned(true);
        }

    }

    const toggle = () => setModal(!modal);

    const onChange = ({ target }) => {
        setFieldValue(target.name, target.value);
    };

    return (
        <form onSubmit={handleSubmit} onReset={handleReset} className="border">
            <ModalBody>
                <div className="d-flex justify-content-center">
                    {values.captura ? (
                        <img src={values.captura} alt="fotografia" width={200} height={200} style={{ objectFit: "contain" }} className="mx-auto" />
                    ) : (
                        <i className="fa fa-user-circle" aria-hidden="true" style={{fontSize: "140px"}}/>
                    )}
                    
                </div>
                <TextInput
                    label="Correo"
                    inputType="email"
                    inputName="email"
                    onChangeMethod={onChange}
                    onBlurMethod={handleBlur}
                    value={values.email}
                    touched={touched.email}
                    errors={errors.email}
                    isRequired
                />
                <TextInput
                    label="Contraseña"
                    inputType="password"
                    inputName="pass"
                    onChangeMethod={onChange}
                    onBlurMethod={handleBlur}
                    value={values.pass}
                    touched={touched.pass}
                    errors={errors.pass}
                    isRequired
                />
            </ModalBody>
            <ModalFooter>
                <div className="w-100 d-flex justify-content-md-end flex-md-row flex-column-reverse ">
                    <Button
                        color="primary"
                        onClick={toggle}
                        className="my-2 my-md-0 mx-0 mr-md-3"
                        type="reset"
                    >
                        Cancelar
                    </Button>{" "}
                    {!values.descriptor ? (
                        <Button color="secondary" className="my-2 my-md-0" onClick={() => setShow(!show)}>{show ? "Cerrar camara" : "Tomar fotografia"}</Button>
                    ) : (
                        <>
                            <Button color='danger' className="my-2 my-md-0 mx-0 mr-md-3" onClick={() => setShow(!show)}>{show ? "Cerrar camara" : "Volver a tomar fotografia"}</Button>
                            <Button color="secondary" className="my-2 my-md-0" type="submit">Firmar documentos</Button>
                        </>
                    )}
                </div>
            </ModalFooter>
            <Camera
                setFieldValue={setFieldValue}
                setVisible={setShow}
                visible={show}
            />
        </form>
    )
}
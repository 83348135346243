import React, { useState, useEffect } from "react";
import TableComponentCustom from "../../../../components/TableComponent/TableComponentCustom";
import { Col, Row, Button } from "reactstrap";
import Reportes from "./reportes/Reportes";
import Loader from "../../../../components/Loader/Loader";

import InfoUsuario from "./InfoUsuario";

const HistorialEmpleado = (props) => {
  const { idEmployee, setEmployee, setIsOpen } = props;
  const [staff, setStaff] = useState("");
  const [loading, setLoading] = useState(false);

  const cabeceras = [
    "Id",
    "Año",
    "Sueldo",
    "Antiguedad en quincenas",
    "Monto de beneficio",
  ];
  const [parametros] = useState({
    action: "datatable",
    table: "foremex",
    rows: "idForemex,year, amountYear, beweeklys, mount",
    conditions: `enabled = 1 and idStaff=${idEmployee[0]}`,
    page: 0,
    records: 5,
    search: "",
    order: "",
  });
  const filtro = [true, true, true, true, true];

  return (
    <div>
      <Row>
        <Col xs={12} md={12} lg={12}>
          <InfoUsuario
            idEmployee={idEmployee}
            setStaff={setStaff}
            laoding={loading}
            setLoading={setLoading}
          />
        </Col>
      </Row>

      <Row>
        <Col className="text-center text-md-right">
          <Button
            color="primary"
            className="mt-3"
            onClick={() => {
              setIsOpen(false)
              setEmployee("")
              
            }}
          >
            Cerrar expediente
          </Button>
        </Col>
      </Row>

      {loading ? (
        <Loader />
      ) : (
        <>
          <Reportes staff={staff} />
          <TableComponentCustom
            titulo={"FOREMEX"}
            cabecerasTabla={cabeceras}
            filtro={filtro}
            parametros={parametros}
          />
        </>
      )}
    </div>
  );
};

export default HistorialEmpleado;

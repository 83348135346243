import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { Col, Button, Alert, Collapse } from "reactstrap";
import { Formik, Form, Field, ErrorMessage, useFormikContext } from "formik";
import { NumberInput } from "../../../components/GenericInputsFormik/NumberInput";

import Loader from "../../../components/Loader/Loader";
import Notification from "../../../components/Notification/Notification";

import { peticionPersonal } from "../../../helpers/peticionesPersonal";
import { peticionesReceiver } from "../../../helpers/peticionesReceiver";

import ModalConfirmation from "../../../components/Modal/ModalConfirmation";
import { SelectTypeHeadSingle } from "../../../components/GenericInputsFormik/index";
import TableComponentPersonalNoEdit from "../../../components/TableComponent/TableComponentPersonalNoEdit";


const PersonalContactos = (props) => {
  const { idStaff, fullName, permissions } = props;
  
const defaultForm= {
  idStafContact: "",
  idStaff: idStaff,
  keyContactType: "",
  contactName: "",
  phone: "",
  email: "",
  phoneExtension: "",
}
  const [modal, setModal] = useState(false);
  const API = peticionesReceiver();
  const [contactsTypes, setContactsTypes] = useState([]);
  const [loadingContactsTypes, setLoadingContactsTypes] = useState(true);
  const [idPersonal, setIdPersonal] = useState(0);
  const [editando, setEditando] = useState(false);
  const [cambia, setCambia] = useState(false);
  const [contact, setContact] = useState(defaultForm);
  const [collapse, setCollapse] = useState(false);
  const toggle = () => setCollapse(!collapse);
  const [cabeceras] = useState([
    "Id",
    "Nombre",
    "Tipo",
    "Telefono",
    "Correo",
    "Ext.Telefono",
    "Vigente",
  ]);
  const [filtro] = useState([
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    false,
    false,
  ]);
  const consulta = {
    action: "datatable",
    table:
      "staffcontacts A LEFT JOIN contacttypes B on (A.keyContactType = B.keyContactType )",
    rows: "A.idStafContact,A.contactName,B.nameContactType,A.phone,ifnull(A.email,'NO AGREGADO'),ifnull(A.phoneExtension,'S/E') as extension,IF(A.enabled=1,'SI','NO') as enabled,A.keyContactType,A.idStaff",
    conditions: `idStaff=${idStaff}`,
    page: 0,
    records: 5,
    search: "",
    order: "A.created desc",
  };
  const [parametros, setParametros] = useState(consulta);

  const getContactTypes = async () => {
    const data = {
      action: "select",
      table: "contacttypes",
      order: "nameContactType ASC",
    };
    API.peticion(data)
      .then((res) => {
        if (res.status === 200 && res.data.status === "success") {
          const datos = res.data.data.map((element) => {
            if (element["nameContactType"].toString() === "Personal") {
              setIdPersonal(element["keyContactType"].toString());
            }
            return {value: element["keyContactType"], name: element["nameContactType"]};
          });
          setContactsTypes(datos);
          setLoadingContactsTypes(false);
        } else {
          toast(
            <Alert color="danger">Error al consultar tipos de contactos</Alert>
          );
        }
      })
      .catch((err) => {
        toast(<Notification type={"consultar_servidor_error"} withIcon />);
      });
  };

  const FormSchema = Yup.object().shape({
    keyContactType: Yup.number()
      .required("Seleccione un tipo de contacto")
      .min(1, "Seleccione un tipo de contacto"),
    contactName: Yup.string().required("Ingrese un nombre de contacto"),
    phone: Yup.string()
      .required("Ingrese un numero telefonico")
      .min(10, "Debe de contener 10 digitos")
      .max(10, "Debe de contener 10 digitos"),
    email: Yup.string().email("Correo electrónico invalido"),

    phoneExtension: Yup.string()
      .min(2, "Ingrese mas de dos digitos")
      .max(5, "Ingrese maximo 5 digitos"),
    /* .required("Ingrese un correo electronico") */
    // email: Yup.string().when("keyContactType", {
    //   is: parseInt(idPersonal),
    //   then: Yup.string().required("Ingrese un correo electronico"),
    // }).email("Correo electrónico invalido"),
  });

  useEffect(() => {
    getContactTypes();
  }, []);

  const limpia = (reset) => {
    setContact(defaultForm);
    setEditando(false);
    setCollapse(false);
    reset();
  };

  const agregar = async (contacto, reset) => {
    const data = {
      action: "saveStaffContacts",
      table: "staffcontacts",
      rows: contacto,
    };
    console.log(contacto);
    const res = await peticionPersonal(data);
    if (res.status === 200 && res.data.status === "success") {
      toast(
        <Notification
          type={"agrega_exito"}
          backMessage={res.data.message}
          withIcon
        />
      );
      setParametros(consulta);
      limpia(reset);
    } else {
      toast(
        <Notification
          type={"agrega_error"}
          backMessage={res.data.message}
          withIcon
        />
      );
    }
  };

  const modoEdicion = async (contacto) => {
    setCambia(false);
    if (!collapse) {
      setContact({
        idStaff: idStaff,
        keyContactType: contacto[6],
        contactName: contacto[1],
        phone: contacto[3],
        email: contacto[4],
        phoneExtension: contacto[5] !== "S/E" && contacto[5],
        idStafContact: contacto[0],
      });

      setEditando(true);
      setCollapse(true);
    } else {
      toast(
        <Notification
          type="warning"
          backMessage="Guarde sus cambios antes de continuar"
        />
      );
    }

    setCambia(false);
  };
  const editar = async (contacto, reset) => {
    const data = {
      action: "update",
      table: "staffcontacts",
      rows: contacto,
      condition: {
        idStafContact: contacto.idStafContact,
      },
      validate: [
        [
          "idStaff",
          "email",
          "phone",
          "contactName",
          "phoneExtension",
          "keyContactType",
        ],
      ],
    };
    API.peticion(data)
      .then((res) => {
        if (res.status === 200 && res.data.status === "success") {
          toast(
            <Notification
              type={"modifica_exito"}
              backMessage={res.data.message}
              withIcon
            />
          );
          setParametros(consulta);
          limpia(reset);
          setCollapse(false);
        } else {
          toast(
            <Notification
              type={"modifica_error"}
              backMessage={res.data.message}
              withIcon
            />
          );
        }
      })
      .catch((err) => {
        toast(<Notification type={"consultar_servidor_error"} withIcon />);
      });
  };

  return (
    <div>
      {!loadingContactsTypes ? (
        <Formik
          initialValues={defaultForm}
          validationSchema={FormSchema}
          onSubmit={(fields) => {
            /* submitFunction(fields,resetForm); */
            setModal(true);
          }}
          onReset={(fields, { resetForm }) => {}}
          render={({
            errors,
            touched,
            values,
            resetForm,
            handleBlur,
            setFieldValue,
          }) => (
            <div>
              <TableComponentPersonalNoEdit
                titulo="Medios de contacto"
                cabecerasTabla={cabeceras}
                filtro={filtro}
                editar={modoEdicion}
                parametros={parametros}
                limpia={limpia}
                reset={resetForm}
              />

              <Form>
                <ModalConfirmation
                  modalTitle={editando ? "Editar" : "Crear"}
                  modal={modal}
                  setModal={setModal}
                  crear={agregar}
                  editar={editar}
                  isEdit={editando}
                  values={values}
                  reset={resetForm}
                >
                  {editando ? (
                    <div className="d-flex justify-content-center">
                      <h6>¿Está seguro de editar el registro?</h6>
                    </div>
                  ) : (
                    <div className="d-flex justify-content-center">
                      <h6>¿Desea continuar con el registro?</h6>
                    </div>
                  )}
                </ModalConfirmation>

                {permissions.INS && <div className="d-grid d-flex justify-content-center justify-content-sm-end">
                  <Button
                    color="add"
                    onClick={() => {
                      toggle();
                      setContact(defaultForm);
                    }}
                    style={{ marginBottom: "1rem" }}
                    disabled={collapse}
                  >
                    Agregar
                  </Button>
                </div>}

                <Collapse
                  isOpen={collapse}
                >
                  <div>
                    <Col className="d-grid d-flex justify-content-sm-end justify-content-center">
                      <h6 className=" text-center">
                        Estado:{" "}
                        <span className="text-primary">
                          {editando ? "Editando" : "Guardando"}{" "}
                        </span>{" "}
                      </h6>
                    </Col>
                    <div className="headline-1 text-center">
                      Medio de contacto
                    </div>
                    <SelectTypeHeadSingle
                      label="Tipo de contacto"
                      isRequired={true}
                      inputName="keyContactType"
                      optionsArray={contactsTypes}
                      defaultOption="Seleccionar un tipo de contacto"
                      onChangeMethod={(event) =>
                        setFieldValue([event.target.name], event.target.value)
                      }
                      onBlur={handleBlur}
                      isDisabled={false}
                      value={values.keyContactType}
                      touched={touched.keyContactType}
                      errors={errors.keyContactType}
                      optionValue="value"
                      optionName="name"
                    />
                    <label htmlFor="contactName">
                      Nombre de contacto<span className="text-danger">*</span>{" "}
                    </label>
                    <Field
                      name="contactName"
                      type="text"
                      disabled={
                        values.keyContactType.toString() ===
                        idPersonal.toString()
                          ? true
                          : false
                      }
                      className={
                        "form-control" +
                        (errors.contactName && touched.contactName
                          ? " is-invalid"
                          : "")
                      }
                    />
                    <ErrorMessage
                      name="contactName"
                      component="div"
                      className="invalid-feedback"
                    />

                    <NumberInput
                      label="Telefono"
                      inputName="phone"
                      onChangeMethod={({target}) => setFieldValue(target.name, target.value)
                      }
                      onBlurMethod={handleBlur}
                      value={values.phone}
                      touched={touched.phone}
                      errors={errors.phone}
                      maxLength={10}
                      disableSep
                      isRequired
                    />

                    {/* <div className="form-group">
                      <label htmlFor="phone">
                        Telefono <span className="text-danger">*</span>
                      </label>
                      <Field
                        name="phone"
                        type="number"
                        className={
                          "form-control" +
                          (errors.phone && touched.phone ? " is-invalid" : "")
                        }
                      />
                      <ErrorMessage
                        name="phone"
                        component="div"
                        className="invalid-feedback"
                      />
                    </div> */}

                    <NumberInput
                      label="Extensión de teléfono"
                      inputName="phoneExtension"
                      onChangeMethod={(event) =>
                        setFieldValue("phoneExtension", event.target.value)
                      }
                      onBlurMethod={handleBlur}
                      value={values.phoneExtension}
                      touched={touched.phoneExtension}
                      errors={errors.phoneExtension}
                      maxLength={5}
                      disableSep
                    />
                    {/*                     <div className="form-group">
                      <label htmlFor="phoneExtension">
                        Extension de telefono
                      </label>
                      <Field
                        name="phoneExtension"
                        type="number"
                        className={
                          "form-control" +
                          (errors.phoneExtension && touched.phoneExtension
                            ? " is-invalid"
                            : "")
                        }
                      />
                      <ErrorMessage
                        name="phoneExtension"
                        component="div"
                        className="invalid-feedback"
                      />
                    </div> */}

                    <div className="form-group">
                      <label htmlFor="email">Correo</label>
                      <Field
                        name="email"
                        type="text"
                        className={
                          "form-control" +
                          (errors.email && touched.email ? " is-invalid" : "")
                        }
                      />
                      <ErrorMessage
                        name="email"
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>
                    {/* <div className="d-flex flex-sm-row gap-sm-3 flex-column flex-sm-column justify-content-between justify-content-sm-left"> */}
                    <div className="row mt-3">
                      <div className="col-sm-6 order-2 order-sm-1 text-center text-sm-left mb-4">
                        <Button
                          color="danger"
                          type="reset"
                          onClick={() => limpia(resetForm)}
                        >
                          Cancelar
                        </Button>
                      </div>
                      <div className="col-sm-6 order-1 order-sm-2 text-center text-sm-right mb-4">
                        <Button color="success" type="submit">
                          Guardar
                        </Button>
                      </div>
                    </div>
                  </div>
                </Collapse>
                <FormikContact
                  info={values}
                  idPersonal={idPersonal}
                  fullName={fullName}
                  contactType={values.keyContactType}
                  contact={contact}
                  idStaff={idStaff}
                  cambia={cambia}
                  setCambia={setCambia}
                />
              </Form>
            </div>
          )}
        />
      ) : (
        <Loader />
      )}
    </div>
  );
};

const FormikContact = ({
  info,
  idPersonal,
  fullName,
  contact,
  idStaff,
  cambia,
  setCambia,
}) => {
  const { setFieldValue } = useFormikContext();
  //modo-editar
  useEffect(() => {
    if (contact) {
      setFieldValue("idStaff", idStaff);
      setFieldValue("keyContactType", contact.keyContactType);
      setFieldValue("contactName", contact.contactName);
      setFieldValue("phone", contact.phone);
      setFieldValue("phoneExtension", contact.phoneExtension);
      setFieldValue("email", contact.email);
      setFieldValue("idStafContact", contact.idStafContact);
    }
  }, [contact]);

  //cambio- full-Name
  useEffect(() => {
    if (cambia) {
      if (info.keyContactType.toString() === idPersonal.toString()) {
        setFieldValue("contactName", fullName);
      } else {
        setFieldValue("contactName", "");
      }
    }
    setCambia(true);
  }, [info.keyContactType]);
  return null;
};
export default PersonalContactos;

import {
  Document,
  Page,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
import HeaderReport from "../../../../../../components/Pdf/Header";
import { fMoney } from "../../../../../../libs/tools/format";

const s = StyleSheet.create({
  body: {
    paddingVertical: "30px",
    paddingHorizontal: "20px",
  },

  title: {
    fontSize: "12px",
    textAlign: "center",
    fontFamily: "Helvetica-Bold",
  },

  header: {
    backgroundColor: "#730A29",
    textAlign: "center",
    color: "#FFFFFF",
  },

  mCell: { padding: "2px" },
  mxCell: { paddingHorizontal: "2px" },

  vCenter: { alignItems: "center", justifyContent: "center" },
  vCenterL: { alignItems: "left", justifyContent: "center" },
  vCenterR: { alignItems: "right", justifyContent: "center" },
  vCenterBtw: { alignItems: "stretch", justifyContent: "center" },

  dflexBtw: { display: "flex", justifyContent: "space-between" },
  dflexEnd: { display: "flex", justifyContent: "flex-end" },

  alignE: { alignContent: "flex-end" },

  row: { flexDirection: "row" },
  flexWrap: { display: "flex", flexWrap: "wrap", flexDirection: "row" },

  w5: { width: "5%" },
  w10: { width: "10%" },
  w15: { width: "15%" },
  w20: { width: "20%" },
  w25: { width: "25%" },
  w30: { width: "30%" },
  w35: { width: "35%" },
  w40: { width: "40%" },
  w45: { width: "45%" },
  w50: { width: "50%" },
  w55: { width: "55%" },
  w60: { width: "60%" },
  w65: { width: "65%" },
  w70: { width: "70%" },
  w75: { width: "75%" },
  w80: { width: "80%" },
  w90: { width: "90%" },
  w100: { width: "100%" },
  wAuto: { width: "auto" },

  border: { border: "1px solid #000", borderBottom: "0px" },
  borderT: { borderBottom: "1px solid #000" },
  borderB: { borderBottom: "1px solid #000" },
  borderB2: { borderBottom: "2px solid #000" },
  borderE: { borderRight: "1px solid #000" },
  borderS: { borderLeft: "1px solid #000" },
  borderBS: { borderBottom: "4px solid #730A29" },
  borderHE: { borderRight: "1px solid #FFFFFF" },

  bg: { backgroundColor: "#BEBEBE" },
  bg2: { backgroundColor: "#BEBEBE" },

  fs7: { fontSize: "7px" },
  fs8: { fontSize: "8px" },
  fs9: { fontSize: "9px" },
  fs10: { fontSize: "10px" },
  fs11: { fontSize: "11px" },
  fs12: { fontSize: "12px" },
  fs13: { fontSize: "13px" },

  tend: { textAlign: "right", paddingRight: "2px" },
  tstart: { textAlign: "left" },
  tcenter: { textAlign: "center" },
  tBold: { fontFamily: "Helvetica-Bold" },

  mT8: { marginTop: "8" },
  mT10: { marginTop: "10px" },
  mT20: { marginTop: "20px" },
  mT40: { marginTop: "40px" },
  mx18: { marginHorizontal: "18px" },
  mx20: { marginHorizontal: "20px" },

  spaceFooter: {
    bottom: 10,
    left: 0,
    right: 0,
    width: "auto",
    height: 30,
    marginHorizontal: 10,
  },

  footer: {
    position: "absolute",
    bottom: 10,
    left: 0,
    right: 0,
    width: "auto",
    height: 30,
    marginHorizontal: 10,
    borderTopWidth: 1,
    flexDirection: "row",
    alignContent: "space-around",
    alignItems: "center",
  },
  textFooter: {
    width: "50%",
    textAlign: "center",
    fontSize: 8,
  },
});

export default async function pdfReport51(data, cfg, t, fileNo) {
  return {
    report: (
      <Document>
        <Page style={[s.body, s.fs11]}>
          <HeaderReport />
          <View style={[s.title, s.mT8]}>
            <Text>
              INICIATIVA DE LEY DE INGRESOS PARA EL EJERCICIO FISCAL {cfg.year}
            </Text>
            <Text>CLASIFICADOR FUNCIONAL DEL GASTO</Text>
          </View>

          <View style={[s.mT20, s.fs10, s.border]}>
            <View style={[s.header]}>
              <View style={[s.mCell, s.borderB]}>
                <Text>Fiscalia General de Justicia del Estado de México</Text>
              </View>
              <View style={[s.mCell, s.borderB]}>
                <Text>
                  Presupuesto de Egresos para el Ejercicio Fiscal {cfg.year}
                </Text>
              </View>
              <View style={[s.mCell, s.borderB]}>
                <Text>Clasificador Funcional del Gasto</Text>
              </View>
              <View style={[s.row, s.borderB]}>
                <View style={[s.w70, s.borderE]}>
                  <Text style={s.mCell}>Total</Text>
                </View>
                <View style={[s.w30]}>
                  <Text style={s.mCell}>Importe</Text>
                </View>
              </View>
            </View>

            <View style={[s.row, s.borderB]}>
              <View style={[s.w70, s.borderE]}>
                <Text style={s.mCell}>Gobierno</Text>
              </View>
              <View style={[s.w30, s.tend]}>
                <Text style={s.mCell}>{fMoney(data.montoTotal)}</Text>
              </View>
            </View>
            <View style={[s.row, s.borderB]}>
              <View style={[s.w70, s.borderE]}>
                <Text style={s.mCell}>Desarrollo Social</Text>
              </View>
              <View style={[s.w30]}>
                <Text style={s.mCell}></Text>
              </View>
            </View>
            <View style={[s.row, s.borderB]}>
              <View style={[s.w70, s.borderE]}>
                <Text style={s.mCell}>Desarrollo Económico</Text>
              </View>
              <View style={[s.w30]}>
                <Text style={s.mCell}></Text>
              </View>
            </View>
            <View style={[s.row, s.borderB]}>
              <View style={[s.w70, s.borderE]}>
                <Text style={s.mCell}>
                  Otras no clasificadas en funciones anteriores
                </Text>
              </View>
              <View style={[s.w30]}>
                <Text style={s.mCell}></Text>
              </View>
            </View>
          </View>
        </Page>
      </Document>
    ),
  };
}

import { useEffect, useState } from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { SelectTypeHeadSingle } from "../../../../components/GenericInputsFormik";
import * as Yup from "yup";
import { useFormik } from "formik";
import { doRequest } from "../../../../helpers/requests";
import FullFormLoader from "../../../../components/Loader/FullFormLoader";

const defaultform = {
  staff: "",
};
const FormSchema = Yup.object().shape({
  staff: Yup.string().required("Seleccione un empleado"),
});

export default function AsignaPerosnal({
  idQuote,
  personal,
  modal,
  setModal,
  setRefresh,
}) {
  const [loading, setLoading] = useState(false);

  const {
    handleSubmit,
    handleReset,
    handleBlur,
    values,
    errors,
    touched,
    setFieldValue,
  } = useFormik({
    initialValues: defaultform,
    onSubmit: () => {
      addStaff();
    },
    validationSchema: FormSchema,
    enableReinitialize: true,
  });

  const onChange = (e) => {
    setFieldValue([e.target.name], e.target.value);
  };

  const toggle = () => setModal(!modal);

  useEffect(() => {}, []);

  const addStaff = async () => {
    const params = {
      action: "update",
      table: "quotes",
      rows: { idStaffAttend: values.staff },
      condition: { idQuote: idQuote },
    };
    setLoading(true);
    const res = await doRequest("receiver/receiver.php", params, true);
    if (res.length > 0) {
      setRefresh(true);
      setModal(false);
      handleReset();
    }
    setLoading(false);
  };

  return (
    <Modal
      isOpen={modal}
      toggle={toggle}
      backdrop={false}
      keyboard
      size="lg"
      centered
    >
      <ModalHeader toggle={toggle} className="bg-primary">
        <p className="text-white">Asignar personal para atender la solicitud</p>
      </ModalHeader>
      <ModalBody>
        <form>
          <SelectTypeHeadSingle
            label="Seleccione al empleado que atenderá esta solicitud:"
            isRequired={true}
            inputName="staff"
            optionsArray={personal}
            defaultOption=""
            onChangeMethod={onChange}
            onBlurMethod={handleBlur}
            value={values.staff}
            touched={touched.staff}
            errors={errors.staff}
            optionValue="idStaff"
            optionName="fullName"
          />
        </form>
      </ModalBody>
      <ModalFooter>
        <div className="w-100 d-flex justify-content-md-end flex-md-row flex-column-reverse ">
          <Button color="primary" onClick={toggle} className="my-2 my-md-0 mx-0 mr-md-3">
            Cerrar
          </Button>{" "}
          <Button color="secondary" onClick={() => handleSubmit()} className="my-2 my-md-0">
            Guardar Asignación
          </Button>
        </div>
      </ModalFooter>
      <FullFormLoader show={loading} />
    </Modal>
  );
}

import React, { useEffect, useState } from "react";
import Datatable from "../../../../components/Datatable/Datatable";
import { toast } from "react-toastify";
import Notification from "../../../../components/Notification/Notification";
import { peticionesReceiver } from "../../../../helpers/peticionesReceiver";
import { TextAreaInput } from "../../../../components/GenericInputsFormik";
import { Button } from "reactstrap";
import ModalReports from "../../../../components/Modal/ModalReports";
import { generatePdfOrdenPago } from "../pdf/pdfOrdenPago";
import { PDFDocument } from "pdf-lib";
import { pdf } from "@react-pdf/renderer";
import { uploadFile } from "../../../../helpers/requests";
import ModalViewFile from "../../../../components/Modal/ModalViewFile";

const TableWarehouse = ({
  order,
  modeAdd,
  refresh,
  setRefresh,
  updateTables,
  setCollapse,
  permissions,
}) => {
  const API = peticionesReceiver();
  const months = [
    "ENERO",
    "FEBRERO",
    "MARZO",
    "ABRIL",
    "MAYO",
    "JUNIO",
    "JULIO",
    "AGOSTO",
    "SEPTIEMBRE",
    "OCTUBRE",
    "NOVIEMBRE",
    "DICIEMBRE",
  ];
  useEffect(() => {}, []);

  const [warehouse, setWarehouse] = useState([]);
  const [modalReports, setModalReports] = useState(false);
  const [modalFile, setModalFile] = useState(false);
  const [linkFile, setLinkFile] = useState("");

  const headers = [
    "#",
    "FOLIO",
    "MES",
    "PROVEEDOR",
    "OBJETO DE COMPRA",
    "MONTO",
    "FACTURA",
    "OBERVACIONES",
    "ACCIONES",
  ];

  const columns = [
    { data: "index" },
    {
      data: null,
      render: function (row) {
        return `${row.numberIncome}/${row.yearIncome} `;
      },
    },
    {
      data: null,
      render: function (row) {
        return getMonth(row.created);
      },
    },
    { data: "socialName" },
    { data: "description" },
    { data: "amount", format: "currency" },
    {
      data: null,
      render: function (row) {
        return (
          <i
            className="fa fa-file-pdf-o text-danger cursor-pointer"
            onClick={() => {
              getFile(row);
            }}
          />
        );
      },
    },
    {
      data: null,
      render: function (row) {
        if (modeAdd) {
          return (
            <TextAreaInput
              inputName="notes"
              inputRows={6}
              onChangeMethod={(e) => changeArrayValue(e, row)}
              value={
                warehouse[row.index - 1] !== undefined
                  ? warehouse[row.index - 1].notes
                  : ""
              }
            />
          );
        } else {
          return row.notes;
        }
      },
    },
    {
      data: null,
      render: function (row) {
        if (+order.keyStat !== 149 && +order.keyStat !== 154) {
          if (permissions.UPD) {
            if (modeAdd) {
              return (
                <Button onClick={() => addWarehouseIncome(row)} color="success">
                  Agregar
                </Button>
              );
            } else {
              return (
                <Button
                  onClick={() => deleteWarehouseIncome(row)}
                  color="danger"
                >
                  Eliminar
                </Button>
              );
            }
          }
        }
      },
    },
  ];

  const getMonth = (dateCreated) => {
    const date = new Date(dateCreated);
    return months[date.getMonth()];
  };

  const columnDefs = [
    {
      targets: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
      className: "text-center",
    },
    {
      orderable: false,
      targets: [0],
    },
  ];

  const getParamsTableElmt = (orderParam, records, page, search) => {
    const params = modeAdd
      ? {
          action: "datatable",
          table:
            "warehouseincomes A INNER JOIN purchaserequests B on (A.idPurchaseRequest = B.idPurchaseRequest) INNER JOIN  budgetrequests C on (B.idBudgetRequest = C.idBudgetRequest) LEFT JOIN invoices D on (A.idIncome = D.idIncome) LEFT JOIN providers E on (D.idProvider = E.idProvider)",
          rows: "A.idIncome, A.yearIncome, A.numberIncome,E.socialName,C.justification,D.amount,A.created,A.notes,A.idOrder,D.idInvoice",
          conditions: `A.enabled = 1 and A.keyStat = 142`,
          group: "",
          orderParam,
          records: records.toString(),
          page: (page * records).toString(),
          search,
        }
      : {
          action: "datatable",
          table:
            "warehouseincomes A INNER JOIN purchaserequests B on (A.idPurchaseRequest = B.idPurchaseRequest) INNER JOIN  budgetrequests C on (B.idBudgetRequest = C.idBudgetRequest) LEFT JOIN invoices D on (A.idIncome = D.idIncome) LEFT JOIN providers E on (D.idProvider = E.idProvider) LEFT JOIN budgetrequestdetail F on (C.idBudgetRequest = F.idBudgetRequest) LEFT JOIN  expense_objects G on (G.idExpenseObject = F.idExpenseObject)",
          rows: "A.idIncome, A.yearIncome, A.numberIncome,E.socialName,C.justification,D.amount,A.created,A.notes,A.idOrder,D.taxNumber,G.fullkey,D.idInvoice",
          conditions: `A.enabled = 1 and A.keyStat = 148 and A.idOrder = ${order.idOrder}`,
          orderParam,
          group: "A.idIncome ",
          records: records.toString(),
          page: (page * records).toString(),
          search,
        };
    return params;
  };

  async function getData(orderParam, records, page, search) {
    try {
      const res = await API.peticion(
        getParamsTableElmt(orderParam, records, page, search)
      );
      if (res.status === 200 && res.data.code === "200") {
        const data = res.data;
        /* data.forEach((value, index) => (value.index = 1 + index)); */
        data.data.forEach((value, index) => (value.index = 1 + index));
        setWarehouse(data.data);

        return data;
      } else {
        toast(
          <Notification
            type={"consultar_error"}
            backMessage={res.data.message}
          />
        );
        return null;
      }
    } catch (err) {
      toast(<Notification type={"consultar_servidor_error"} withIcon />);
      return null;
    }
  }

  const changeArrayValue = (e, row) => {
    const { name, value } = e.target;

    // if (name === "reception") {
    //     let tmpList = warehouse.slice(0, warehouse.length);
    //     tmpList[+row.index - 1][name] = e.target.checked;
    //     warehouse(tmpList);
    //   } else {

    let tmpList = warehouse.slice(0, warehouse.length);
    tmpList[+row.index - 1][name] = value;

    setWarehouse(tmpList);
    //   }
  };

  const addWarehouseIncome = async (row) => {
    if (warehouse[row.index - 1].notes) {
      const params = {
        action: "update",
        table: "warehouseincomes",
        rows: {
          keyStat: 148,
          idOrder: order.idOrder,
          notes: row.notes,
        },
        condition: { idIncome: row.idIncome },
      };

      try {
        const res = await API.peticion(params);
        if (res.status === 200 && res.data.code === "200") {
          toast(
            <Notification
              type={"modifica_exito"}
              backMessage={res.data.message}
              withIcon
            />
          );
          updateTables();
        } else {
          toast(
            <Notification
              type={"modifica_error"}
              backMessage={res.data.message}
              withIcon
            />
          );
        }
      } catch (err) {
        toast(<Notification type={"consultar_servidor_error"} withIcon />);
      }
    } else {
      toast(
        <Notification
          type={"consultar_error"}
          backMessage={"INGRESE LAS OBSERVACIONES ANTES DE ENVIAR EL REGISTRO"}
        />
      );
    }
  };

  const deleteWarehouseIncome = async (row) => {
    const params = {
      action: "update",
      table: "warehouseincomes",
      rows: {
        keyStat: 142,
        idOrder: null,
        notes: "",
      },
      condition: { idIncome: row.idIncome },
    };

    try {
      const res = await API.peticion(params);
      if (res.status === 200 && res.data.code === "200") {
        toast(
          <Notification
            type={"modifica_exito"}
            backMessage={res.data.message}
            withIcon
          />
        );
        updateTables();
      } else {
        toast(
          <Notification
            type={"modifica_error"}
            backMessage={res.data.message}
            withIcon
          />
        );
      }
    } catch (err) {
      toast(<Notification type={"consultar_servidor_error"} withIcon />);
    }
  };

  const showDocument = () => {
    if (warehouse.length > 0) {
      setModalReports(true);
    } else {
      toast(
        <Notification
          type={"consultar_error"}
          backMessage={"NO EXISTEN COMPRAS"}
        />
      );
    }
  };

  const finishOrder = async () => {
    const blob = await pdf(
      (
        await generatePdfOrdenPago({ warehouse: warehouse, order: order }, 0)
      ).report
    ).toBlob();

    if (blob !== "" && typeof blob === "object") {
      const uploaded = await uploadFile(
        order.idOrder,
        "saveFileReplace",
        61,
        blob,
        false
      );
      if (uploaded) {
        changesStatus();
      }
    }
  };

  const changesStatus = async () => {
    const params = {
      action: "update",
      table: "payorders",
      rows: {
        keyStat: 149,
      },
      condition: { idOrder: order.idOrder },
    };

    try {
      const res = await API.peticion(params);
      if (res.status === 200 && res.data.code === "200") {
        toast(
          <Notification
            type={"modifica_exito"}
            backMessage={res.data.message}
            withIcon
          />
        );
        setCollapse(false);
      } else {
        toast(
          <Notification
            type={"modifica_error"}
            backMessage={res.data.message}
            withIcon
          />
        );
      }
    } catch (err) {
      toast(<Notification type={"consultar_servidor_error"} withIcon />);
    }
  };

  const cancelOrder = async () => {
    if (changePurchasesToDefault()) {
      changeStatustoCancel();
    }
  };

  const changeStatustoCancel = async () => {
    const params = {
      action: "update",
      table: "payorders",
      rows: {
        keyStat: 154,
      },
      condition: { idOrder: order.idOrder },
    };

    try {
      const res = await API.peticion(params);
      if (res.status === 200 && res.data.code === "200") {
        toast(
          <Notification
            type={"modifica_exito"}
            backMessage={res.data.message}
            withIcon
          />
        );
        setCollapse(false);
      } else {
        toast(
          <Notification
            type={"modifica_error"}
            backMessage={res.data.message}
            withIcon
          />
        );
      }
    } catch (err) {
      toast(<Notification type={"consultar_servidor_error"} withIcon />);
    }
  };

  const changePurchasesToDefault = async () => {
    const params = {
      action: "update",
      table: "warehouseincomes",
      rows: {
        keyStat: 142,
        idOrder: "",
        notes: "",
      },
      condition: { idOrder: order.idOrder },
    };

    try {
      const res = await API.peticion(params);
      if (res.status === 200 && res.data.code === "200") {
        toast(
          <Notification
            type={"modifica_exito"}
            backMessage={res.data.message}
            withIcon
          />
        );
        return true;
      } else {
        if (res.data.message !== "No existe el registro a actualizar") {
          toast(
            <Notification
              type={"modifica_error"}
              backMessage={res.data.message}
              withIcon
            />
          );
        }
        return false;
      }
    } catch (err) {
      toast(<Notification type={"consultar_servidor_error"} withIcon />);
      return false;
    }
  };

  const getFile = async (row) => {
    const paramsFiles = {
      action: "multiselect",
      table:
        "bookcase b join files f on (b.idBook = f.idBook) join invoices i on (b.reference = i.idInvoice) join warehouseincomes wh on (wh.idIncome = i.idIncome)",
      rows: "f.src",
      conditions: `b.enabled = 1 AND f.enabled AND b.keyFileType = ${40} AND i.idInvoice=${
        row.idInvoice
      }`,
    };

    const API = peticionesReceiver();
    try {
      const res = await API.peticion(paramsFiles);
      if (res.status === 200 && res.data.code === "200") {
        const data = res.data.data;
        if (data.length > 0) {
          setLinkFile(res.data.data[0].src);
          setModalFile(true);
        } else {
          toast(<Notification type={"consultar_error"} withIcon />);
        }
      } else {
        toast(
          <Notification
            type={"consultar_error"}
            backMessage={res.data.message}
          />
        );
      }
    } catch (err) {
      toast(<Notification type={"consultar_error"} withIcon />);
    }
  };

  return (
    <div>
      {order && order.idOrder && (
        <>
          <Datatable
            headers={headers}
            columns={columns}
            columnDefs={columnDefs}
            petition={getData}
            control="back"
            stateRefresh={[refresh, setRefresh]}
            searching={true}
            order={{ col: 1, opt: "desc" }}
          />

          {!modeAdd &&
            +order.keyStat !== 149 &&
            +order.keyStat !== 154 &&
            permissions.INS &&
            permissions.UPD && (
              <>
                <div className="d-flex justify-content-around flex-wrap ">
                  <Button
                    className="mt-3"
                    color="warning"
                    onClick={() => {
                      showDocument();
                    }}
                  >
                    PREVISUALIZAR DOCUMENTO
                  </Button>
                </div>

                <div className="d-flex justify-content-around flex-wrap ">
                  <Button
                    className="mt-3"
                    color="danger"
                    onClick={() => {
                      cancelOrder();
                    }}
                  >
                    CANCELAR ORDEN
                  </Button>
                  <Button
                    className="mt-3"
                    color="success"
                    onClick={() => {
                      finishOrder();
                    }}
                  >
                    FINALIZAR ORDEN
                  </Button>
                </div>
              </>
            )}
        </>
      )}
      <ModalReports
        modal={modalReports}
        setModal={setModalReports}
        title={"Reporte de movimientos"}
        backdrop
        keyboard
        report={{ warehouse: warehouse, order: order }}
        pdfConfig={{}}
        generatePdfMethod={{
          method: generatePdfOrdenPago,
          type: "generatePdfOrdenPago",
        }}
      />

      <ModalViewFile
        modal={modalFile}
        setModal={setModalFile}
        backdrop={true}
        keyboard={true}
        btnClose={true}
        fileLink={linkFile}
      />
    </div>
  );
};

export default TableWarehouse;

import { useState } from "react";
import ConsultaInicial from "./Consulta";
import Widget from "../../../components/Widget/Widget";
import { useEffect } from "react";
import { doRequest, findEmployee } from "../../../helpers/requests";
import Revision from "./Revision";
import { Button, Container } from "reactstrap";
import { useSelector } from "react-redux";
import { GetPermissions } from "../../../libs/permissions/getPermissions";

export default function DictamenPresupuestal() {
    const [permissions, setPermissions] = useState({ id: 0 });
    GetPermissions(setPermissions);

    const [solicitud, setSolicitud] = useState({ id: "" });
    const [areas, setAreas] = useState([]);
    const [status, setStatus] = useState([]);
    const { id } = useSelector((state) => state.userInfo);
    const [idStaff, setIdStaff] = useState(null);

    useEffect(() => {
        getCapitulos();
        getStatus();
        getStaff();
    }, []);

    async function getStaff(){
        const emp = await findEmployee(id);
        if(emp){
            setIdStaff(emp.idStaff);
        }
    }

    async function getCapitulos() {
        const params = {
            action: "select",
            table: "areas",
            condition: { enabled: 1 },
        };
        const data = await doRequest("receiver/receiver.php", params);
        if (data.length > 0) setAreas(data);
    }

    async function getStatus() {
        const params = {
            action: "multiselect",
            table: "status",
            rows: "keyStat, nameStat",
            conditions: "enabled = 1 AND keyTypeStat = 27", //  AND keyStat > 116
            order: "nameStat",
        };
        const data = await doRequest("receiver/receiver.php", params);
        if (data.length > 0) setStatus(data);
    }

    return (
        <Widget className="widget-p-md">
            {permissions.CON ? (
                <>
                    <div className="my-3 d-flex flex-md-row flex-column justify-content-md-between">
                        <h3>Dictamen presupuestal</h3>
                        {solicitud.id && <Button onClick={() => setSolicitud({ id: "" })}>Volver</Button>}
                    </div>

                    {solicitud.id ? (
                        <Revision solicitud={solicitud} user={idStaff} permissions={permissions}/>
                    ) : (
                        <ConsultaInicial areas={areas} status={status} setSolicitud={setSolicitud} />
                    )}
                </>

            ) : (
                <div className="my-3">
                    <h3>Dictamen presupuestal</h3>
                    <div className="text-center my-5 py-5">
                        <h5>No tiene permiso para acceder a este módulo</h5>
                    </div>
                </div>
            )}
        </Widget>
    );
}

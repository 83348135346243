import { useEffect, useState } from "react";
import Datatable from "../../../../../components/Datatable/Datatable";
import { doRequest, doRequestSaveRes } from "../../../../../helpers/requests";
import ModalReports from "../../../../../components/Modal/ModalReports";
import SalariosDetallePDF from "../../pdf/tabuladores-historico/SalariosDetallepdf";
import FullFormLoader from "../../../../../components/Loader/FullFormLoader";
import { fDateTime } from "../../../../../libs/tools/format";
import { SalariosDetalleExcel } from "../../excel/tabuladores-historico/SalariosDetalleExcel";

const headers = [
  "ID",
  "NIVEL",
  "RANGO",
  "S. BASE",
  "S. BRUTO",
  "S. NETO",
  "GRATIFICACIÓN",
  "DESPENSA",
  "VIÁTICOS",
  "COMPENSACIÓN",
  "FORTALECIMIENTO",
  "IMPUESTOS",
  "ISSEMYM"
]

const columnDefs = [
  { orderable: false, targets: [13] },
  { className: "text-center", targets: [0, 1, 2] },
  { className: "text-right", targets: [3, 4, 5, 6, 7, 8, 9, 10, 11, 12] },
];
const colors = ["#BC2929","#3A8631"];

export default function SalariosDetalle({selected}){
    const [loading, setLoading] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [modalReport, setModalReport] = useState(false);
    const [data, setData] = useState([]);
    
    useEffect(()=>{
        if(selected) setRefresh(true);
        else setData([]);
    }, [selected]);

    async function getData(ordCol, itemsPerPage, currentPage, search) {
      if(!selected) return null;
        const res = await doRequestSaveRes(
          "app/facades/reports/reportsF.php",
          getParams(ordCol, itemsPerPage, currentPage, search),
          false
        );
        if (res.code === "200") return res;
        else return null;
      }
  
    function getParams(ordCol, itemsPerPage, currentPage, search) {
      const params = {
        action: "getHistoricoSalariosDetalle",
        idSalaryIncrease: selected.idSalaryIncrease,
        order: ordCol.toString(),
        records: itemsPerPage.toString(),
        page: (itemsPerPage * currentPage).toString(),
        search,
      };
      return params;
    }

    const columns = [
      { data: "idSalary" },
      { data: "level" },
      { data: "rango" },
      { data: "sueldoBase", format: "currency" },
      { data: "sueldoBruto", format: "currency" },
      { data: "sueldoNeto", format: "currency" },
      { data: "gratificacion", format: "currency" },
      { data: "despensa", format: "currency" },
      { data: "viaticos", format: "currency" },
      { data: "compensacion", format: "currency" },
      { data: "fortalecimiento", format: "currency" },
      { data: "impuestos", format: "currency" },
      { data: "issemym", format: "currency" },
    ]

    async function generateReport(type){
      setLoading(true);
      let res = data;
      if(res.length < 1){
        res = await doRequest(
          "app/facades/reports/reportsF.php",
          { action: "getHistoricoSalariosDetalle", idSalaryIncrease: selected.idSalaryIncrease },
          false
        );
      }
      if(res.length > 0){
        setData(res);
        if(type === 1) setModalReport(true);
        else {
          await SalariosDetalleExcel(res, selected);
        }
      }
      setLoading(false);
    }

    return(
        <div>
          <div className="my-2 d-flex flex-md-row flex-column justify-content-md-between">
            <div className="mb-2 mt-1">
                <p><strong>Incremento Salarial: </strong>{selected?.idSalaryIncrease||""}</p>
                <p><strong>Fecha de Aplicación: </strong>{fDateTime(selected?.fechaApliacion||"")}</p>
            </div>
            <div className="d-flex flex-md-row flex-column justify-content-md-between my-1">
              <button className="btn btn-outline m-1"  style={{backgroundColor: colors[0], color: "#fff"}} onClick={()=>generateReport(1)}>
                <i className="fa fa-file-pdf-o cursor-pointer mr-2"/>
                PDF
              </button>
              <button className="btn btn-outline m-1" style={{backgroundColor: colors[1], color: "#fff"}} onClick={()=>generateReport(2)}>
                <i className="fa fa-file-excel-o cursor-pointer mr-2" />
                Excel
              </button>
            </div>
          </div>
            <Datatable
                headers={headers}
                columns={columns}
                columnDefs={columnDefs}
                petition={getData}
                control="back"
                stateRefresh={[refresh, setRefresh]}
                className="px-0"
            />
            <ModalReports
              modal={modalReport}
              setModal={setModalReport}
              title="TABULADOR HISTORICO - DETALLES SALARIOS"
              backdrop={"static"}
              keyboard
              report={data}
              pdfConfig={selected}
              generatePdfMethod={{
                method: SalariosDetallePDF,
                type: "SalariosDetallePdf",
              }}
              records={1000}
            />
            <FullFormLoader show={loading} />
        </div>
    )
}
import { Row, Col } from "reactstrap";
import { useHistory } from "react-router-dom";
import { useEffect, useState } from "react";

import TableComponentCustom from "../../../components/TableComponent/TableComponentCustom";
import ModalViewFile from "../../../components/Modal/ModalViewFile";
import InputWithModal from "../../../components/GenericInputsFormik/InputWithModal";
import { getFile } from "./Forms/functions";
import EditarBien from "./Forms/EditarBien";
import GetXML from "./Modals/ViewXML";
import AsignarResguardo from "./Forms/Asignar";

export default function PrincipalInventarios() {

    const history = useHistory();
    //Tabla inventories
    const [params, setParams] = useState({
        action: "getInventories",
        rows: "serialKey,inventoryKey,nameFurniture",
        page: "0",
        records: "5",
        search: ""
    })

    //Modal empleado
    const [employee, setEmployee] = useState(null);
    const cabecerasMTEmp = [
        "#",
        "Núm. Empleado",
        "Nombre",
        "Unidad de trabajo",
        "Puesto",
        "Seleccionar",
    ];
    const filtroMTEmp = [true, true, true, true, true];
    const paramsMTEmp = {
        action: "datatable",
        table: "staff STF INNER JOIN employtypes AS EMT ON STF.keyEmployType = EMT.keyEmployType INNER JOIN jobstaff AS JBS ON JBS.idStaff = STF.idStaff INNER JOIN areas AS AR ON AR.idArea = JBS.idArea INNER JOIN jobtypes AS JBT ON JBT.keyJobType = STF.keyEmployType",
        rows: "STF.idStaff,STF.staffNumber,CONCAT(STF.name,' ',STF.pName,' ',STF.mName) as fullName,AR.name,JBT.nameJobType",
        conditions: "STF.enabled = 1 AND JBS.valid = 1 AND STF.keyStat = 1 AND JBS.enabled = 1",
        page: 0,
        records: 5,
        search: "",
        order: "",
    };
    const selectEmpleado = (el) => {
        const employee = { idStaff: el[0], nameStaff: `${el[1]} - ${el[2]}` }
        setEmployee(employee);
    };
    const clearEmployee = () => {
        setEmployee(null);
    }

    //Modal area
    const [area, setArea] = useState(null);
    const cabecerasMTArea = [
        "#",
        "Nombre",
        "Edificio",
        "Dirección",
        "Seleccionar",
    ];
    const filtroMTArea = [true, true, true, true];
    const paramsMTArea = {
        action: "datatable",
        table: "areas A join buildings B using(idBuilding)",
        rows: "idArea,name,nameBuilding,concat(streetName,' NO.',ifnull(intNumber, 'S/N'),' EXT.',ifnull(extNumber, 'S/N'),', ',substreet,' CP:',zipCode)",
        conditions: "A.enabled = 1 AND B.enabled = 1",
        page: 0,
        records: 5,
        search: "",
        order: "",
    };
    const selectArea = (el) => {
        const area = { idArea: el[0], name: `${el[1]}` }
        setArea(area);
    };
    const clearArea = () => {
        setArea(null);
    }

    //Modal bien
    const [bien, setBien] = useState(null);
    const cabecerasMTBien = [
        "#",
        "Nombre",
        "Seleccionar",
    ];
    const filtroMTBien = [true, true];
    const paramsMTBien = {
        action: "datatable",
        table: "furnitures",
        rows: "idFurniture,nameFurniture",
        conditions: "enabled = 1",
        page: 0,
        records: 5,
        search: "",
        order: "",
    };
    const selectBien = (el) => {
        const bien = { idFurniture: el[0], nameFurniture: `${el[1]}` }
        setBien(bien);
    };
    const clearBien = () => {
        setBien(null);
    }

    useEffect(() => {
        const params = {
            action: "getInventories",
            rows: "serialKey,inventoryKey,nameFurniture",
            page: "0",
            records: "5",
            search: ""
        }
        if (employee) {
            params.idStaff = employee.idStaff;
        }
        if (area) {
            params.idArea = area.idArea
        }
        if (bien) {
            params.idFurniture = bien.idFurniture;
        }
        setParams(params)
    }, [employee, area, bien])

    const [linkFile, setLinkFile] = useState(null)
    const [modalFile, setModalFile] = useState(null)


    async function getPDF(row) {
        const idInventory = row[0];
        const fileType = 40;
        const src = await getFile(fileType, idInventory);
        if (src) {
            setModalFile(true);
            setLinkFile(src);
        }
    }

    async function getXML(row) {
        setIdInventory(row[0])
        setModalXML(true)
    }

    const getFacturas = (row, key) => {
        return (
            <div className="text-center">
                <i className="fa fa-file-pdf-o mx-1 text-danger" style={{ cursor: "pointer" }} onClick={() => getPDF(row)} />
                <i className="fa fa-file-text mx-1 text-info" style={{ cursor: "pointer" }} onClick={() => getXML(row)} />
            </div>
        )
    }

    const [modalEdit, setModalEdit] = useState(false)
    const [idInventory, setIdInventory] = useState(null);

    const editInventory = (row) => {
        setIdInventory(row[0])
        setModalEdit(true)
    }

    const onUpdate = () => {
        setParams({
            action: "getInventories",
            rows: "serialKey,inventoryKey,nameFurniture",
            page: "0",
            records: "5",
            search: ""
        })
    }

    const [modalXML, setModalXML] = useState(false)

    const [modalAsig, setModalAsig] = useState(false)

    const assignInventory = (row) => {
        setIdInventory(row[0])
        setModalAsig(true)
    }

    return (
        <>
            <h6>Filtros:</h6>
            <Row>
                <Col xs={12} md={4}>
                    <InputWithModal
                        cabeceras={cabecerasMTEmp}
                        filtro={filtroMTEmp}
                        actionSelect={selectEmpleado}
                        onClear={clearEmployee}
                        parametros={paramsMTEmp}
                        value={employee ? employee.nameStaff : ""}
                        label="Empleado"
                        name={"nameStaff"}
                        title={"Seleccionar empleado"}
                    />
                </Col>
                <Col xs={12} md={4}>
                    <InputWithModal
                        cabeceras={cabecerasMTArea}
                        filtro={filtroMTArea}
                        actionSelect={selectArea}
                        onClear={clearArea}
                        parametros={paramsMTArea}
                        value={area ? area.name : ""}
                        label="Area"
                        name={"name"}
                        title={"Seleccionar area"}
                    />
                </Col>
                <Col xs={12} md={4}>
                    <InputWithModal
                        cabeceras={cabecerasMTBien}
                        filtro={filtroMTBien}
                        actionSelect={selectBien}
                        onClear={clearBien}
                        parametros={paramsMTBien}
                        value={bien ? bien.nameFurniture : ""}
                        label="Bien mueble"
                        name={"nameFurniture"}
                        title={"Seleccionar bien mueble"}
                    />
                </Col>
            </Row>
            <TableComponentCustom
                titulo="Inventario actual"
                cabecerasTabla={["Número de serie", "Número de inventario", "Color / Material", "Denominación", "Descripción (Marca / Modelo)", "Estado bien", "Editar", "Ver resguardo", "Factura"]}
                filtro={[false, true, true, true, true, true, true]}
                parametros={params}
                addEdit
                actionEdit={editInventory}
                addWatch
                actionWatch={assignInventory}
                addNew
                actionNew={() => history.push("/template/inventarios/agregar")}
                routePeticion={"app/facades/inventory/inventoryF.php"}
                specialColumns={[getFacturas]}
            />
            <ModalViewFile
                modal={modalFile}
                setModal={setModalFile}
                backdrop={true}
                keyboard={true}
                btnClose={true}
                fileLink={linkFile}
            />
            {idInventory !== null ? (
                <>
                    <EditarBien
                        idInventory={idInventory}
                        setIdInventory={setIdInventory}
                        modal={modalEdit}
                        setModal={setModalEdit}
                        onRefresh={onUpdate}
                    />
                    <GetXML
                        idInventory={idInventory}
                        modal={modalXML}
                        setModal={setModalXML}
                    />
                    <AsignarResguardo
                        idInventory={idInventory}
                        modal={modalAsig}
                        setModal={setModalAsig}
                    />
                </>
            ) : null}

        </>
    )
}
import { Document, Page, Text, View } from "@react-pdf/renderer";
/* import { styles } from "../../../../components/Pdf/pdfGeneral/EstiloPdf"; */
import { StyleSheet } from "@react-pdf/renderer";
import HeaderReport from "../../../../components/Pdf/Header";

const formatter = new Intl.NumberFormat("es-MX", {
  style: "currency",
  currency: "MXN",
});



const colors = {
  backgroundHeaders: "#730A29",
};

const styles = StyleSheet.create({
  tableColContentReport: {
    width: "50%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableColContentFirts: {
    width: "50%",
    borderStyle: "solid",
    borderWidth: 1,
    borderTopWidth: 0,
  },
  textBold: {
    fontFamily: "Helvetica-Bold",
  },
  cellThreeColumn: {
    width: "30%",
    fontSize: 4,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  cellTwoColumn: {
    width: "63%",
    fontSize: 4,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  cellOneColumn: {
    width: "100%",
    fontSize: 4,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  displayContent: {
    paddingLeft: 9, //centra el el content
    paddingRight: 9, //centra el el content
    marginTop: 3,
    marginBottom: 3,
    /* marginTop: 9,
    marginBottom: 9, */
    flexDirection: "row",
    justifyContent: "space-around",
  },
  body: {
    paddingTop: 35,
    paddingBottom: 35,
    paddingHorizontal: 10,
  },
  tableCell: {
    width: "auto",
    display: "table",
    borderStyle: "solid",
    margin: 10,
  },
  title: {
    width: "100%",
    textAlign: "center",
    marginBottom: 10,
    /* color: '#a9a9a9', */
    fontSize: 18,
  },
  subTitle: {
    width: "100%",
    textAlign: "center",
    marginBottom: 10,
    /* color: '#a9a9a9', */
    fontSize: 14,
  },
  textFooter: {
    width: "50%",
    /* color: '#a9a9a9', */
    textAlign: "center",
    fontSize: 8,
  },
  tableRow: {
    flexDirection: "row",
  },
  tableColumn: {
    flexDirection: "column",
  },
  cellHeader100Borders: {
    width: "100%",
    height: 15,
    fontSize: 5,
    borderWidth: 1,
    backgroundColor: colors.backgroundHeaders,
    color: "white",
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
  },
  cellHeader50Borders: {
    width: "50%",
    height: 15,
    fontSize: 5,
    borderWidth: 1,
    borderTopWidth: 0,
    /* backgroundColor: colors.backgroundHeaders, */
    /* color: "white", */
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
  },
  cellHeader50BordersRTB: {
    width: "50%",
    height: 15,
    fontSize: 5,
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    /* backgroundColor: colors.backgroundHeaders, */
    /* color: "white", */
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
  },
  cellHeader33Borders: {
    width: "33.333%",
    height: 25,
    fontSize: 4,
    borderWidth: 1,
    backgroundColor: colors.backgroundHeaders,
    color: "white",
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
  },
  cellHeader33BordersRTB: {
    width: "33.333%",
    height: 25,
    fontSize: 4,
    borderWidth: 1,
    borderLeftWidth: 0,
    backgroundColor: colors.backgroundHeaders,
    color: "white",
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
  },
  cellContent33Borders: {
    width: "33.333%",
    height: 25,
    fontSize: 4,
    borderWidth: 1,
    borderTopWidth: 0,
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
  },
  cellContent33BordersRTB: {
    width: "33.333%",
    height: 25,
    fontSize: 4,
    borderWidth: 1,
    borderTopWidth: 0,
    borderLeftWidth: 0,
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
  },
  cellHeader12Borders: {
    width: "12.5%",
    height: 25,
    fontSize: 4,
    borderWidth: 1,
    backgroundColor: colors.backgroundHeaders,
    color: "white",
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
  },
  cellHeader12BordersRTB: {
    width: "12.5%",
    height: 25,
    fontSize: 4,
    borderWidth: 1,
    borderLeftWidth: 0,
    backgroundColor: colors.backgroundHeaders,
    color: "white",
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
  },
  cellContent12BordersLBR: {
    width: "12.5%",
    fontSize: 4,
    borderWidth: 1,
    borderTopWidth: 0,
    minHeight: 25,
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
  },
  cellContent12BordersRB: {
    width: "12.5%",
    minHeight: 25,
    fontSize: 4,
    borderRightWidth: 1,
    borderBottomWidth: 1,
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
  },
  cell12BorderLRB: {
    width: "12.5%",
    height: 25,
    fontSize: 8,
    borderWidth: 1,
    borderTopWidth: 0,
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
  },
  cell12BorderRigth: {
    width: "12.5%",
    height: 25,
    fontSize: 8,
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
  },
  cell25: {
    width: "25%",
    height: 25,
    fontSize: 8,
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
  },
  cell25BorderRigth: {
    width: "25%",
    height: 25,
    fontSize: 8,
    borderRightWidth: 1,
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
  },
  cell25BorderSides: {
    width: "25%",
    height: 25,
    fontSize: 8,
    borderLeftWidth: 1,
    borderRightWidth: 1,
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
  },
  cell75: {
    width: "75%",
    height: 25,
    fontSize: 8,
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
  },
  cell75BorderRigth: {
    width: "75%",
    height: 25,
    fontSize: 8,
    borderRightWidth: 1,
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
  },
  cell75BorderSides: {
    width: "75%",
    height: 25,
    fontSize: 8,
    borderLeftWidth: 1,
    borderRightWidth: 1,
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
  },
  text50: {
    width: "50%",
    textAlign: "center",
  },
  tableHeader25: {
    width: "25%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    backgroundColor: colors.backgroundHeaders,
    color: "white",
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
    fontSize: 9,
  },
  spaceFooterTableGeneral: {
    fontSize: 12,
    bottom: 10,
    left: 0,
    right: 0,
    width: "auto",
    height: 50,
    marginHorizontal: 10,
  },
  spaceFooter: {
    fontSize: 12,
    bottom: 10,
    left: 0,
    right: 0,
    width: "auto",
    height: 50,
    marginHorizontal: 10,
  },
  footer: {
    position: "absolute",
    fontSize: 12,
    bottom: 10,
    left: 0,
    right: 0,
    width: "auto",
    height: 30,
    marginHorizontal: 10,
    /*       borderColor:"#a9a9a9", */
    borderTopWidth: 1,
    flexDirection: "row",
    alignContent: "space-around",
    alignItems: "center",
  },
});

/* const typeHeaderCell = (tam, index) => {
        if (index === 0) {
          return tam === 100
            ? styles.cellHeader100Borders
            : tam === 75
            ? styles.cellHeader75Borders
            : tam === 50
            ? styles.cellHeader50Borders
            : tam === 25
            ? styles.cellHeader25Borders
            : tam === 11
            ? styles.cellHeader11Borders
            : ""
        } else {
          return tam === 100
            ? styles.cellContent100LBR
            : tam === 88.7
            ? styles.cellContent88_7BordersLBR
            : tam === 75
            ? styles.cellHeader75BordersRTB
            : tam === 50
            ? styles.cellHeader50BordersRTB
            : tam === 25
            ? styles.cellHeader25BordersRTB
            : tam === 11
            ? styles.cellHeader11BordersRTB
            : ""
        }
      }

 */

/* const insertHeaders = (model) => {
  return (
    <>
      <View style={styles.tableRow} fixed>
                {model.headers.map((obj, index) => (
          <View style={typeHeaderCell(model.columnsHeaders[index], index)}>
            <Text>{obj}</Text>
          </View>
        ))}
      </View>
    </>
  )
} */



// const insertHeaders = (model) => {
//   return (
//     <>
//       <View style={styles.tableRow} >
//         <View style={styles.cellHeader33Borders}>
//           <Text style={{ textAlign: "center" }}>INFORMACION DEL EMPLEADO</Text>
//         </View>
//         <View style={styles.cellHeader33BordersRTB}>
//           <Text style={{ textAlign: "center" }}>DETALLES DEL EMPLEADO</Text>
//         </View>
//         <View style={styles.cellHeader33BordersRTB}>
//           <Text style={{ textAlign: "center" }}>CONCEPTOS</Text>
//         </View>
//       </View>
//     </>
//   );
// };
const insertTable1 = (model) => {
  return (
    <>
      <View style={[styles.tableRow]} >
        <View style={styles.cellHeader12Borders}>
          <Text style={{ textAlign: "center" }}>TIPO DE REGISTRO</Text>
        </View>
        <View style={styles.cellHeader12BordersRTB}>
          <Text style={{ textAlign: "center" }}>
            IDENTIFICADOR DE LA OPERACION
          </Text>
        </View>
        <View style={styles.cellHeader12BordersRTB}>
          <Text style={{ textAlign: "center" }}>TIPO ENTIDAD DE ORIGEN</Text>
        </View>
        <View style={styles.cellHeader12BordersRTB}>
          <Text style={{ textAlign: "center" }}>
            CLAVE ENTIDAD DE ORIGEN (ICP)
          </Text>
        </View>
        <View style={styles.cellHeader12BordersRTB}>
          <Text style={{ textAlign: "center" }}>FECHA DE TRANSMISION</Text>
        </View>
        <View style={styles.cellHeader12BordersRTB}>
          <Text style={{ textAlign: "center" }}>
            NOMBRE DE INSTITUCION PUBLICA
          </Text>
        </View>
        <View style={styles.cellHeader12BordersRTB}>
          <Text CLAS>RFC PATRONAL</Text>
        </View>
        <View style={styles.cellHeader12BordersRTB}>
          <Text style={{ textAlign: "center" }}>
            CLAVE DE INSTITUCION PUBLICA
          </Text>
        </View>
      </View>
      <View style={[styles.tableRow, { marginBottom: 15 }]} >
        <View style={styles.cellContent12BordersLBR}>
          <Text style={{ textAlign: "center" }}>1</Text>
        </View>
        <View style={styles.cellContent12BordersRB}>
          <Text style={{ textAlign: "center" }}>1</Text>
        </View>
        <View style={styles.cellContent12BordersRB}>
          <Text style={{ textAlign: "center" }}>3</Text>
        </View>
        <View style={styles.cellContent12BordersRB}>
          <Text style={{ textAlign: "center" }}>1</Text>
        </View>
        <View style={styles.cellContent12BordersRB}>
          <Text style={{ textAlign: "center" }}>20230228</Text>
        </View>
        <View style={styles.cellContent12BordersRB}>
          <Text style={{ textAlign: "center" }}>PJE950909L97</Text>
        </View>
        <View style={styles.cellContent12BordersRB}>
          <Text style={{ textAlign: "center" }}>
            PODER JUDICIAL DEL ESTADO DE MEXICO
          </Text>
        </View>
        <View style={styles.cellContent12BordersRB}>
          <Text style={{ textAlign: "center" }}>13000</Text>
        </View>
      </View>
    </>
  );
};

// const insertContent = (data) => {
//   return (
//     <>
//       <View style={styles.tableRow}>
//         <View style={styles.tableColContentFirts}>
//           <View style={styles.displayContent}>
//             <Text style={[styles.cellOneColumn, { textAlign: "center" }]}>
//               <Text style={styles.textBold}>Nombre de empleado:</Text>
//               {`${data[0].name} ${data[0].pName} ${data[0].mName}`}
//             </Text>
//           </View>
//           <View style={styles.displayContent}>
//             <Text style={styles.cellTwoColumn}>
//               <Text style={styles.textBold}>Numero de empleado:</Text>
//               {data[0].staffNumber}
//             </Text>
//             <Text style={styles.cellTwoColumn}>
//               <Text style={styles.textBold}>Tipo de registro:</Text>
//               {data[0].TipoRegistro}
//             </Text>
//           </View>
//           <View style={styles.displayContent}>
//             <Text style={styles.cellTwoColumn}>
//               <Text style={styles.textBold}>Curp del servidor publico:</Text>
//               {data[0].curp}
//             </Text>
//             <Text style={styles.cellTwoColumn}>
//               <Text style={styles.textBold}>Rfc del servidor publico:</Text>
//               {data[0].rfc}
//             </Text>
//           </View>
//           <View style={styles.displayContent}>
//             <Text style={styles.cellTwoColumn}>
//               <Text style={styles.textBold}>Clave Issemym:</Text>
//               {data[0].claveIssemym}
//             </Text>
//             <Text style={styles.cellTwoColumn}>
//               <Text style={styles.textBold}>Rfc del servidor publico:</Text>
//               {data[0].rfc}
//             </Text>
//           </View>
//         </View>

//         <View style={styles.tableColContentReport}>
//           <View style={styles.displayContent}>
//             <Text style={styles.cellTwoColumn}>
//               <Text style={styles.textBold}>Regimen Laboral:</Text>
//               {data[0].RegimenLaboral}
//             </Text>
//             <Text style={styles.cellTwoColumn}>
//               <Text style={styles.textBold}>Clave de entidad receptora</Text>
//               {data[0].cveEntidad}
//             </Text>
//           </View>
//           <View style={styles.displayContent}>
//             <Text style={styles.cellTwoColumn}>
//               <Text style={styles.textBold}>
//                 Fecha de Ingreso a la institucion publica:
//               </Text>
//               {data[0].startJobDate}
//             </Text>
//             <Text style={styles.cellTwoColumn}>
//               <Text style={styles.textBold}>
//                 Fecha Inicial de Contratacion:
//               </Text>
//               {data[0].iniContra}
//             </Text>
//           </View>
//           <View style={styles.displayContent}>
//             <Text style={styles.cellTwoColumn}>
//               <Text style={styles.textBold}>Fecha Final de Contratacion:</Text>
//               {data[0].finContra}
//             </Text>
//             <Text style={styles.cellTwoColumn}>
//               <Text style={styles.textBold}>Indicador del SCI:</Text>
//               {data[0].sci}
//             </Text>
//           </View>
//           <View style={styles.displayContent}>
//             <Text style={styles.cellTwoColumn}>
//               <Text style={styles.textBold}>Indicador de suplencia:</Text>
//               {data[0].suplencia}
//             </Text>
//             <Text style={styles.cellTwoColumn}>
//               <Text style={styles.textBold}>Indicador del SCI:</Text>
//               {data[0].sci}
//             </Text>
//           </View>
//           <View style={styles.displayContent}>
//             <Text style={styles.cellTwoColumn}>
//               <Text style={styles.textBold}>
//                 Dias cotizados en la quincena:
//               </Text>
//               {data[0].DCQ}
//             </Text>
//             <Text style={styles.cellTwoColumn}>
//               <Text style={styles.textBold}>Quincena:</Text>
//               {data[0].Quincena}
//             </Text>
//           </View>
//         </View>

//         <View style={styles.tableColContentReport}>
//           <View style={styles.displayContent}>
//             <Text style={styles.cellThreeColumn}>
//               <Text style={styles.textBold}>
//                 Sueldo Neto sujeto de Cotizacion:
//               </Text>
//               {data[0].sueldoNetoSujetoCotizacion}
//             </Text>
//             <Text style={styles.cellThreeColumn}>
//               <Text style={styles.textBold}>SCI Cuotas Obligatorias:</Text>
//               {data[0].SCI}
//             </Text>
//             <Text style={styles.cellThreeColumn}>
//               <Text style={styles.textBold}>SCI Cuotas Voluntarias:</Text>
//               {data[0].sciVoluntario}
//             </Text>
//           </View>

//           <View style={styles.displayContent}>
//             <Text style={styles.cellThreeColumn}>
//               <Text style={styles.textBold}>
//                 SCI Aportaciones Obligatorias:
//               </Text>
//               {data[0].AOSCI}
//             </Text>
//             <Text style={styles.cellThreeColumn}>
//               <Text style={styles.textBold}>SCI Aportaciones Voluntarias:</Text>
//               {data[0].AVSCI}
//             </Text>
//             <Text style={styles.cellThreeColumn}>
//               <Text style={styles.textBold}>Servicios de Salud:</Text>
//               {data[0].CSS}
//             </Text>
//           </View>
//           <View style={styles.displayContent}>
//             <Text style={styles.cellThreeColumn}>
//               <Text style={styles.textBold}>
//                 Fondo del Sistema Solidario de Reparto:
//               </Text>
//               {data[0].CSSR}
//             </Text>
//             <Text style={styles.cellThreeColumn}>
//               <Text style={styles.textBold}>Retenciones Institucionales:</Text>
//               {data[0].RI}
//             </Text>
//             <Text style={styles.cellThreeColumn}>
//               <Text style={styles.textBold}>Retenciones de Terceros:</Text>
//               {data[0].RT}
//             </Text>
//           </View>
//           <View style={styles.displayContent}>
//             <Text style={styles.cellThreeColumn}>
//               <Text style={styles.textBold}>Servicios de Salud:</Text>
//               {data[0].SS}
//             </Text>
//             <Text style={styles.cellThreeColumn}>
//               <Text style={styles.textBold}>
//                 Fondo del Sistema Solidario de Reparto:
//               </Text>
//               {data[0].FSSR}
//             </Text>
//             <Text style={styles.cellThreeColumn}>
//               <Text style={styles.textBold}>Aportaciones Extraordinarias:</Text>
//               {data[0].AE}
//             </Text>
//           </View>

//           <View style={styles.displayContent}>
//             <Text style={styles.cellThreeColumn}>
//               <Text style={styles.textBold}>Cuotas Extraordinarias:</Text>
//               {data[0].CE}
//             </Text>
//             <Text style={styles.cellThreeColumn}>
//               <Text style={styles.textBold}>Gastos de Administracion:</Text>
//               {data[0].GA}
//             </Text>
//             <Text style={styles.cellThreeColumn}>
//               <Text style={styles.textBold}>Prima Basica</Text>
//               {data[0].PB}
//             </Text>
//           </View>
//           <View style={styles.displayContent}>
//             <Text style={styles.cellThreeColumn}>
//               <Text style={styles.textBold}>Prima de Siniestralidad:</Text>
//               {data[0].PS}
//             </Text>
//             <Text style={styles.cellThreeColumn}>
//               <Text style={styles.textBold}>
//                 Prima de Riesgo no controlado:
//               </Text>
//               {data[0].PRNC}
//             </Text>
//           </View>
//         </View>
//       </View>

//       {/* <View style={styles.tableRow} fixed>
//         <View style={styles.cellHeader12Borders}>
//           <Text style={{ textAlign: "center" }}>TIPO DE REGISTRO</Text>
//         </View>
//         <View style={styles.cellHeader12BordersRTB}>
//           <Text style={{ textAlign: "center" }}>
//             IDENTIFICADOR DE LA OPERACION
//           </Text>
//         </View>
//       </View> */}
//     </>
//   );
// };

const insertTitles = (model, title) => {
  return (
    <>
      <View>
        <Text style={styles.title}>{title}</Text>
      </View>
      <View>
        <Text style={styles.subTitle}>
          {model.filters !== undefined
            ? model.filters.onlyMessage === true
              ? model.filters.message
              : ""
            : ""}
        </Text>
      </View>
    </>
  );
};

const insertTotals = (totals,title) => {
  console.log("Titulo desde metodo",title)
  console.log("Totals desde metodo",totals)
  return (
    <>
      <View style={[styles.tableRow]} >
        <View style={styles.cellHeader100Borders}>
          <Text style={{ textAlign: "center" }}>{title}</Text>
        </View>
      </View>
      <View style={[styles.tableRow]} >
        <View style={[styles.cellContent33Borders,styles.tableColumn]}>
          <Text style={{ textAlign: "center" }}>Sueldo neto sujeto de cotizacion</Text>
          <Text style={{ textAlign: "center" }}>{formatter.format(totals[0])}</Text>
        </View>
        <View style={[styles.cellContent33BordersRTB,styles.tableColumn]}>
          <Text style={{ textAlign: "center" }}>SCI Cuotas Obligatorias:</Text>
          <Text style={{ textAlign: "center" }}>{formatter.format(totals[1])}</Text>
        </View>
        <View style={[styles.cellContent33BordersRTB,styles.tableColumn]}>
          <Text style={{ textAlign: "center" }}>SCI Cuotas Voluntarias:</Text>
          <Text style={{ textAlign: "center" }}>{formatter.format(totals[2])}</Text>
        </View>
        <View style={[styles.cellContent33BordersRTB,styles.tableColumn]}>
          <Text style={{ textAlign: "center" }}>SCI Aportaciones Obligatorias:</Text>
          <Text style={{ textAlign: "center" }}>{formatter.format(totals[3])}</Text>
        </View>
        <View style={[styles.cellContent33BordersRTB,styles.tableColumn]}>
          <Text style={{ textAlign: "center" }}>SCI Aportaciones Voluntarias:</Text>
          <Text style={{ textAlign: "center" }}>{formatter.format(totals[4])}</Text>
        </View>
        <View style={[styles.cellContent33BordersRTB,styles.tableColumn]}>
          <Text style={{ textAlign: "center" }}>Servicios de Salud:</Text>
          <Text style={{ textAlign: "center" }}>{formatter.format(totals[5])}</Text>
        </View>
        <View style={[styles.cellContent33BordersRTB,styles.tableColumn]}>
          <Text style={{ textAlign: "center" }}>Fondo del Sistema Solidario de Reparto:</Text>
          <Text style={{ textAlign: "center" }}>{formatter.format(totals[6])}</Text>
        </View>
        <View style={[styles.cellContent33BordersRTB,styles.tableColumn]}>
          <Text style={{ textAlign: "center" }}>Retenciones Institucionales:</Text>
          <Text style={{ textAlign: "center" }}>{formatter.format(totals[7])}</Text>
        </View>
        <View style={[styles.cellContent33BordersRTB,styles.tableColumn]}>
          <Text style={{ textAlign: "center" }}>Retenciones de Terceros:</Text>
          <Text style={{ textAlign: "center" }}>{formatter.format(totals[8])}</Text>
        </View>
      </View>
      <View style={[styles.tableRow,{marginBottom: 10}]} >
        <View style={[styles.cellContent33Borders,styles.tableColumn]}>
          <Text style={{ textAlign: "center" }}>Servicios de Salud:</Text>
          <Text style={{ textAlign: "center" }}>{formatter.format(totals[9])}</Text>
        </View>
        <View style={[styles.cellContent33BordersRTB,styles.tableColumn]}>
          <Text style={{ textAlign: "center" }}>Fondo del Sistema Solidario de Reparto:</Text>
          <Text style={{ textAlign: "center" }}>{formatter.format(totals[10])}</Text>
        </View>
        <View style={[styles.cellContent33BordersRTB,styles.tableColumn]}>
          <Text style={{ textAlign: "center" }}>Aportaciones Extraordinarias:</Text>
          <Text style={{ textAlign: "center" }}>{formatter.format(totals[11])}</Text>
        </View>
        <View style={[styles.cellContent33BordersRTB,styles.tableColumn]}>
          <Text style={{ textAlign: "center" }}>Cuotas Extraordinarias:</Text>
          <Text style={{ textAlign: "center" }}>{formatter.format(totals[12])}</Text>
        </View>
        <View style={[styles.cellContent33BordersRTB,styles.tableColumn]}>
          <Text style={{ textAlign: "center" }}>Gastos de Administracion:</Text>
          <Text style={{ textAlign: "center" }}>{formatter.format(totals[13])}</Text>
        </View>
        <View style={[styles.cellContent33BordersRTB,styles.tableColumn]}>
          <Text style={{ textAlign: "center" }}>Prima Basica:</Text>
          <Text style={{ textAlign: "center" }}>{formatter.format(totals[14])}</Text>
        </View>
        <View style={[styles.cellContent33BordersRTB,styles.tableColumn]}>
          <Text style={{ textAlign: "center" }}>Prima de servicio:</Text>
          <Text style={{ textAlign: "center" }}>{formatter.format(totals[15])}</Text>
        </View>
        <View style={[styles.cellContent33BordersRTB,styles.tableColumn]}>
          <Text style={{ textAlign: "center" }}>Prima de Siniestralidad:</Text>
          <Text style={{ textAlign: "center" }}>{formatter.format(totals[16])}</Text>
        </View>
        <View style={[styles.cellContent33BordersRTB,styles.tableColumn]}>
          <Text style={{ textAlign: "center" }}>Prima de Riesgo no controlado:</Text>
          <Text style={{ textAlign: "center" }}>{formatter.format(totals[17])}</Text>
        </View>
      </View>
    </>
  );
};
// const insertTotalFinal = (acumNeto, acumAmount) => {
//   return (
//     {/* <>
//       <View style={styles.tableRow} wrap={false}>
//         <View style={styles.cellContent77_77BordersLBR}>
//           <Text>Total del reporte </Text>
//         </View>
//         <View style={styles.cellContent11BordersRB}>
//           <Text>{formatter.format(totalNet + acumNeto)}</Text>
//         </View>
//         <View style={styles.cellContent11BordersRB}>
//           <Text>{formatter.format(totalAmount + acumAmount)}</Text>
//         </View>
//       </View>
//     </> */}
//   );
// };

const insertFooter = (fileNo) => {
  return (
    <>
      <View fixed style={styles.spaceFooter}></View>
      <View fixed style={styles.footer}>
        <Text style={styles.textFooter}>{fileNo}</Text>
        <Text
          style={styles.textFooter}
          render={({ pageNumber, totalPages }) =>
            `PAGINA : ${pageNumber} / ${totalPages}`
          }
        />
      </View>
    </>
  );
};

const insertInfoStaff = (staff) => {
  return (
    <>
      <View style={[styles.tableRow]}>
        <View style={styles.cellHeader33Borders}>
          <Text style={{ textAlign: "center" }}>Numero de empleado</Text>
        </View>
        <View style={styles.cellHeader33BordersRTB}>
          <Text style={{ textAlign: "center" }}>nombre del empleado</Text>
        </View>
        <View style={styles.cellHeader33BordersRTB}>
          <Text style={{ textAlign: "center" }}>Tipo de registro</Text>
        </View>
        <View style={styles.cellHeader33BordersRTB}>
          <Text style={{ textAlign: "center" }}>Curp del servidor publico</Text>
        </View>
        <View style={styles.cellHeader33BordersRTB}>
          <Text style={{ textAlign: "center" }}>Rfc del servidor publico</Text>
        </View>
        <View style={styles.cellHeader33BordersRTB}>
          <Text style={{ textAlign: "center" }}>Clave Issemym</Text>
        </View>
      </View>

      <View style={[styles.tableRow]}>
        <View style={styles.cellContent33Borders}>
          <Text style={{ textAlign: "center" }}> {staff.staffNumber}</Text>
        </View>
        <View style={styles.cellContent33BordersRTB}>
          <Text
            style={{ textAlign: "center" }}
          >{`${staff.name} ${staff.pName} ${staff.mName}`}</Text>
        </View>
        <View style={styles.cellContent33BordersRTB}>
          <Text style={{ textAlign: "center" }}>{staff.TipoRegistro}</Text>
        </View>
        <View style={styles.cellContent33BordersRTB}>
          <Text style={{ textAlign: "center" }}>{staff.curp}</Text>
        </View>
        <View style={styles.cellContent33BordersRTB}>
          <Text style={{ textAlign: "center" }}>{staff.rfc}</Text>
        </View>
        <View style={styles.cellContent33BordersRTB}>
          <Text style={{ textAlign: "center" }}>{staff.claveIssemym}</Text>
        </View>
      </View>
    </>
  );
};

const insertCaracteristicas = (staff) => {
  return (
    <>
      <View style={styles.tableColContentFirts}>
        <View style={styles.displayContent}>
          <Text style={styles.cellThreeColumn}>
            <Text style={styles.textBold}>Regimen Laboral:</Text>
            {staff.RegimenLaboral}
          </Text>
          <Text style={styles.cellThreeColumn}>
            <Text style={styles.textBold}>Clave de entidad receptora:</Text>
            {staff.cveEntidad}
          </Text>
          <Text style={styles.cellThreeColumn}>
            <Text style={styles.textBold}>
              Fecha de Ingreso a la institucion publica:
            </Text>
            {staff.startJobDate}
          </Text>
        </View>
        <View style={styles.displayContent}>
          <Text style={styles.cellThreeColumn}>
            <Text style={styles.textBold}>Fecha Inicial de Contratacion:</Text>
            {staff.iniContra}
          </Text>
          <Text style={styles.cellThreeColumn}>
            <Text style={styles.textBold}>Fecha Final de Contratacion:</Text>
            {staff.finContra}
          </Text>
          <Text style={styles.cellThreeColumn}>
            <Text style={styles.textBold}>Indicador del SCI:</Text>
            {staff.sci}
          </Text>
        </View>
        <View style={styles.displayContent}>
          <Text style={styles.cellThreeColumn}>
            <Text style={styles.textBold}>Indicador de suplencia:</Text>
            {staff.suplencia}
          </Text>
          <Text style={styles.cellThreeColumn}>
            <Text style={styles.textBold}>Indicador del SCI:</Text>
            {staff.sci}
          </Text>
          <Text style={styles.cellThreeColumn}>
            <Text style={styles.textBold}>Dias cotizados en la quincena:</Text>
            {staff.DCQ}
          </Text>
        </View>
        <View style={styles.displayContent}>
          <Text style={styles.cellThreeColumn}>
            <Text style={styles.textBold}>Quincena:</Text>
            {staff.Quincena}
          </Text>
        </View>
      </View>
    </>
  );
};

const insertConceptos = (staff) => {
  return (
    <View style={styles.tableColContentReport}>
      <View style={styles.displayContent}>
        <Text style={styles.cellThreeColumn}>
          <Text style={styles.textBold}>Sueldo Neto sujeto de Cotizacion:</Text>
          {formatter.format(staff.sueldoNetoSujetoCotizacion)}
        </Text>
        <Text style={styles.cellThreeColumn}>
          <Text style={styles.textBold}>SCI Cuotas Obligatorias:</Text>
          {formatter.format(staff.SCI)}
        </Text>
        <Text style={styles.cellThreeColumn}>
          <Text style={styles.textBold}>SCI Cuotas Voluntarias:</Text>
          {formatter.format(staff.sciVoluntario)}
        </Text>
      </View>

      <View style={styles.displayContent}>
        <Text style={styles.cellThreeColumn}>
          <Text style={styles.textBold}>SCI Aportaciones Obligatorias:</Text>
          {formatter.format(staff.AOSCI)}
        </Text>
        <Text style={styles.cellThreeColumn}>
          <Text style={styles.textBold}>SCI Aportaciones Voluntarias:</Text>
          {formatter.format(staff.AVSCI)}
        </Text>
        <Text style={styles.cellThreeColumn}>
          <Text style={styles.textBold}>Servicios de Salud:</Text>
          {formatter.format(staff.CSS)}
        </Text>
      </View>
      <View style={styles.displayContent}>
        <Text style={styles.cellThreeColumn}>
          <Text style={styles.textBold}>
            Fondo del Sistema Solidario de Reparto:
          </Text>
          {formatter.format(staff.CSSR)}
        </Text>
        <Text style={styles.cellThreeColumn}>
          <Text style={styles.textBold}>Retenciones Institucionales:</Text>
          {formatter.format(staff.RI)}
        </Text>
        <Text style={styles.cellThreeColumn}>
          <Text style={styles.textBold}>Retenciones de Terceros:</Text>
          {formatter.format(staff.RT)}
        </Text>
      </View>
      <View style={styles.displayContent}>
        <Text style={styles.cellThreeColumn}>
          <Text style={styles.textBold}>Servicios de Salud:</Text>
          {formatter.format(staff.SS)}
        </Text>
        <Text style={styles.cellThreeColumn}>
          <Text style={styles.textBold}>
            Fondo del Sistema Solidario de Reparto:
          </Text>
          {formatter.format(staff.FSSR)}
        </Text>
        <Text style={styles.cellThreeColumn}>
          <Text style={styles.textBold}>Aportaciones Extraordinarias:</Text>
          {formatter.format(staff.AE)}
        </Text>
      </View>

      <View style={styles.displayContent}>
        <Text style={styles.cellThreeColumn}>
          <Text style={styles.textBold}>Cuotas Extraordinarias:</Text>
          {formatter.format(staff.CE)}
        </Text>
        <Text style={styles.cellThreeColumn}>
          <Text style={styles.textBold}>Gastos de Administracion:</Text>
          {formatter.format(staff.GA)}
        </Text>
        <Text style={styles.cellThreeColumn}>
          <Text style={styles.textBold}>Prima Basica</Text>
          {formatter.format(staff.PB)}
        </Text>
      </View>
      <View style={styles.displayContent}>
        <Text style={styles.cellThreeColumn}>
          <Text style={styles.textBold}>Prima de Siniestralidad:</Text>
          {formatter.format(staff.PS)}
        </Text>
        <Text style={styles.cellThreeColumn}>
          <Text style={styles.textBold}>Prima de Riesgo no controlado:</Text>
          {formatter.format(staff.PRNC)}
        </Text>
      </View>
    </View>
  );
};

const insertStaff = (data) => {
  return (
    <>
      {data.map((staff, index) => (
        <View wrap = {false}>
          {insertInfoStaff(staff)}
          <View style={[styles.tableRow]}>
            <View style={styles.cellHeader50Borders}>
              <Text style={{ textAlign: "center" }}>Caracteristicas</Text>
            </View>
            <View style={styles.cellHeader50BordersRTB}>
              <Text style={{ textAlign: "center" }}>Conceptors</Text>
            </View>
          </View>
          ;
          <View style={[styles.tableRow]}>
            {insertCaracteristicas(staff)}

            {insertConceptos(staff)}
          </View>
          ;
        </View>
      ))}
    </>
  );
};

const retSums = (total, totalAcum) => {
  let sumTotals = total
  total.map((total,index) =>(
    sumTotals[index] += totalAcum[index]
  ))
  return sumTotals
};

const generateReport = (
  data,
  model,
  title,
  fileNo,
  final,
  totals,
  acumTotals,
) => {
  console.log("Totals", totals)
  console.log("AcumTotals", acumTotals)
  return (
    <Document>
      <Page style={styles.body}>
        <HeaderReport/>
        <View style={styles.tableCell}>
          {insertTitles(model, title)}
          {insertTable1()}
          {insertTotals(totals,"Totales")}
          {final && insertTotals(retSums(totals,acumTotals),"Totales por reporte")}
          {insertStaff(data)}

          
          <View style={styles.tableRow}></View>
        </View>
        {insertFooter(fileNo)}
      </Page>
    </Document>
  );
};

const generateTotals = (data, filter) => {
  let totals = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
  data.map((staff) => {
    let staffInfo = [];
    staffInfo.push(
      filter.content[0].map((obj, indexObj) => {
        if (indexObj > 15 && indexObj < 34) {
          totals[indexObj - 16] += +staff[obj.value];
        }
        return staff[obj.value];
      })
    );
  });
  return totals;
};
export function generatePdfCoutasAportacionesIssemym(
  data,
  model,
  title,
  fileNo,
  final,
  acumTotals,
) {
  
  const totals = generateTotals(data, model);
  if(acumTotals === undefined){
    acumTotals =  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
  }
  const report = generateReport(
    data,
    model,
    title,
    fileNo,
    final,
    totals,
    acumTotals
  );
  return {
    report: report,
    acumTotals: retSums(totals, acumTotals),
  };
}

import React, { useState } from "react";
import TableComponent from "../../../components/TableComponent/TableComponentScheduleRules";

export const ReglasHorarios = () => {
  const cabeceras = [
    "Descripción del horario",
    "Editar",
    // "Eliminar",
  ];

  const [parametros] = useState({
    action: "datatable",
    table: `schedulerules AS SDR
    INNER JOIN jobschedule AS JBS
    ON JBS.idJobSchedule = SDR.idJobSchedule`,
    rows: "JBS.nameJobSchedule", ///sin espacios y comas
    conditions: "SDR.enabled = 1",
    page: 0,
    records: 5,
    search: "",
    order: "",
  });

  return (
    <div>
      <TableComponent
        titulo="Reglas de horarios"
        cabecerasTabla={cabeceras}
        filtro={null}
        rutaCrear="/template/reglas-horarios/crear"
        rutaEditar="/template/reglas-horarios/editar"
        parametros={parametros}
      />
    </div>
  );
};
export default ReglasHorarios;
import React, { useState } from "react";
import TableComponent from "../../../components/TableComponent/TableComponentFiltro";

export const Seguros = () => {
  const cabeceras = [
    "Id", //0
    "Nombre", //1
    "Destino",
    "Editar",
    "Eliminar",
  ];
  const [parametros] = useState({
    action: "datatable",
    table: "types_insurance ty inner join modules m on (ty.idModule=m.idModule)",
    rows: "ty.keyTypeInsurance,ty.nameTypeInsurance,m.nameModule", ///sin espacios y comas
    conditions: "ty.enabled = 1",
    page: 0,
    records: 5,
    search: "",
    order: "",
  });

  return (
    <div>
      <TableComponent
        titulo="Tipos de Seguros"
        cabecerasTabla={cabeceras}
        filtro={null}
        rutaCrear="/template/seguros-nomina/crear"
        rutaEditar="/template/seguros-nomina/editar"
        parametros={parametros}
      ></TableComponent>
    </div>
  );
};
export default Seguros;

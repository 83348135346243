import s from "./TableErros.module.scss";
import {differenceInMonths} from 'date-fns';

/**
 * @param {Array<Object>} data Arreglo de datos
 * @param {Array<string>} columns Arrelgo de headers
 * @param {boolean} spec Opcional. Para layouts con columnas variables
 * @returns bolean
 */
export function countColumns(data, columns, spec) {
    let res = false;
    if (spec) {
        // if (Object.keys(data[0]).length < columns.length) return false;
        res = data.some(d => Object.keys(d).length < columns.length);
    } else {
        // if (Object.keys(data[0]).length !== columns.length) return false;
        res = data.some(d => Object.keys(d).length !== columns.length);
    }
    return !res;
}

/**
 * @param {Object} data Objeto del registro a anlizar
 * @param {number} max Cantidad maxima de columnas
 * @returns bolean
 */
export function limitColumns(data, max) {
    let maxCol = 0;
    data.forEach((e) => {
        if (Object.values(e).length > maxCol) maxCol = Object.values(e).length;
    });
    if (maxCol > max) return true;
    return false;
}

/**
 * Revisa que las cabeceras en el csv esten escritas correctamente
 * @param {Object[]} data Arreglo de objetos
 * @param {Object[]} columns Arreglo de columnas
 * @param {boolean} spec - Opcional. Para layouts con columnas incrementales
 * @returns boolean
 */
export function compareColumns(data, columns, spec) {
    const arrCols = Object.values(data[0]);
    const minCols = spec ? arrCols.slice(0, columns.length) : arrCols;
    const colsA = JSON.stringify(minCols);
    const colsB = JSON.stringify(columns);
    if (colsA !== colsB) return false;
    // Revisar el nomrbe de las columnas extra sea correcto
    // if(spec){
    //   const sz = columns.length;
    //   return arrCols.slice(sz).every((e) => e === columns[sz-1] || e === columns[sz-2]);
    // }

    return true;
}

//data: arreglo de objetos
export function countLines(data) {
    if (data.length > 1) return false;
    return true;
}


/**
 * Validar que una fila tenga todos sus datos correctos
 * @param {Object} obj Objeto con los valores
 * @param {Number} columnsQ Cnatidad de columnas que debe tener la fila
 * @param {Number | null} spec (Opcional) - Numero de reporte, cuando el reporte permite agregar una cantidad variable de columnas 
 * @returns bolean
 */
export function objComplete(obj, columnsQ, spec) {
    if (spec) {
        const res = spec === 4 ? 1 : 0;
        if (Object.keys(obj).length % 2 !== res) return false;
        if (Object.keys(obj).length >= columnsQ) return true;
    }
    if (Object.keys(obj).length === columnsQ) return true;
    return false;
}

/**
 * Validar si una fila está completamente vacia
 * @param {Object} obj Objeto con los valores
 */
export function emptyRow(obj){
    const data = Object.values(obj);
    return data.length === data.filter(d => !d).length
}

/**
 * Revisa que el valor recibido exista
 * @param {*} value varaible
 * @returns bolean
 */
export function notVoid(value) {
    if (
        value !== undefined &&
        value !== null &&
        value.toString() !== "" &&
        value.toString().trim() !== ""
    ) {
        return true;
    }
    return false;
}

/**
 * Valida si es un numero positivo
 * @param {*} obj Objeto del registro a anlizar
 * @param {*} key Nombre del atributo
 * @returns boolean
 */
export function isPositive(obj, key) {
    const lc = (obj[key].match(/,/g) || []).length;
    if (lc < 2) {
        const num = Number(obj[key].replaceAll(",", ""));
        if (!isNaN(num) && num > 0) {
            return true;
        }
    }
    return false;
}

/**
 * Revisa que la varaible pueda considerarse un nombre
 * @param {Object} data Objeto del registro a anlizar
 * @param {string} key Nombre del atributo
 */
export function validName(obj, col) {
    var regex = /[0-9!@#$%^&*)(+=_-]+/g;
    return obj[col].search(regex) === -1;
}

/**
 * Revisa que la varaible pueda considerarse un numero positivo
 * @param {Object} data Objeto del registro a anlizar
 * @param {string} key Nombre del atributo
 */
export function isPositiveInt(obj, key) {
    if (
        notVoid(obj[key]) &&
        !isNaN(+obj[key]) &&
        +obj[key] > 0 &&
        +obj[key] % 1 === 0
    ) {
        return true;
    }
    return false;
}

/**
 *Validar que los años de inicio y fin concuerden
 * @param {Object} obj Objeto con los valores
 * @param {string} yA Nombre del campo año de inicio
 * @param {string} yB Nombre del campo año de termino
 * @return {number} 0 si yA > yB
 * @return {number} 1 si yA === yB
 * @return {number} 2 si yA < yB
 */
export function validYears(obj, yA, yB) {
    if (+obj[yA] === +obj[yB]) {
        return 1;
    } else if (+obj[yA] < +obj[yB]) {
        return 2;
    }
    return 0;
}

//obj: objeto, year: nombre de columna (cadena)
export function isYear(obj, year) {
    if (isPositiveInt(obj, year)) {
        if (+obj[year] >= 2000 && +obj[year] < 3000) {
            return true;
        }
    }
    return false;
}

/**
 * Revisa si la fecha tien el formato dd/mm/YYYY
 * @param {*} obj Objeto con la informacion
 * @param {*} date Nombre del campo fecha
 * @returns boolean
 */
export function isDate(obj, date) {
    const reg = /^([0-2]?[0-9]|3[0-1])(\/)(0[1-9]|1[0-2])(\/)(\d{4})$/g; // date format: dd/mm/YYYY
    if (reg.test(obj[date])) {
        return true;
    }
    return false;
}

/**
 * Revisa si la fecha tiene el formato dd/mm/YYYY HH:mm:ss
 * @param {*} obj Objeto con la informacion
 * @param {*} date Nombre del campo fecha
 * @returns boolean
 */
export function isDateTime(obj, date) {
    const reg =
        /^([0-2][0-9]|3[0-1])(\/)(0[1-9]|1[0-2])(\/)\d{4}(\s(0[0-9]|1[0-9]|2[0-3]):([0-5][0-9]):([0-5][0-9]))$/g; /// dd/mm/YYYY [HH:mm:ss]
    if (reg.test(obj[date])) {
        return true;
    }
    return false;
}

/**
 *Validar que el peridio inicial sea mayor o igual al final
 * @param {Object} obj Objeto con la informacion
 * @param {*} pI Nombre del campo periodo inicial
 * @param {*} pF Nombre del campo periodo final
 * @returns bolean
 */
export function periodLow(obj, pI, pF) {
    if (maxValue(obj[pI], 24)) {
        if (maxValue(obj[pF], 24)) {
            if (+obj[pI] <= +obj[pF]) {
                return true;
            }
        }
    }
    return false;
}

//obj: objeto, pI: periodo inicial, pF: periodo final
export function periodLowEqual(obj, pI, pF) {
    if (maxValue(obj[pI], 24)) {
        if (maxValue(obj[pF], 24)) {
            if (+obj[pI] <= +obj[pF]) {
                return true;
            }
        }
    }
    return false;
}

/**
 * Calcular el perido final
 * @param {*} obj Objeto con la informacion
 * @param {1 | 2} type 1 - mismo año , 2 - difernete año
 * @returns 1 - periodo correcto | 2 - periodo incorrecto | 3 - año incorrecto
 */
export function calcFinalPeriod(obj, type) {
    /// empeiza a acontar a partir del periodo de inicio
    const pC = +obj.INITIALPERIOD + +obj.PARTIALITIES - 1;
    if (type === 1) {
        return pC === +obj.FINALPERIOD ? 1 : 2;
    } else if (type === 2) {
        const diffYears = +obj.FINALYEAR - +obj.INITIALYEAR;
        const pNY = pC - 24 * diffYears;
        if (pNY < 1 || pNY > 25) return 3;
        return pNY === +obj.FINALPERIOD ? 1 : 2;
    }
    return 2;
}

/**
 * Calcular monto por periodo
 * @param {Object} obj Objeto con la informacion
 * @param {string} total Nombre del campo importe total
 * @param {string} parc Nombre del campo parcialidades
 * @param {*} importQ Nombre del campo importe quincenal
 * @returns bolean
 */
export function calcAmountQ(obj, total, parc, importQ) {
    const iQC = (cleanNumber(obj[total]) / cleanNumber(obj[parc])).toFixed(2);
    const iQ = cleanNumber(obj[importQ]).toFixed(2);
    if (iQ >= iQC - 1 && iQ <= +iQC + 1) {
        /// precicion parcialidad
        return true;
    }
    return false;
}

function cleanNumber(str) {
    return Number(str.replaceAll(",", ""));
}

/**
 * Calcular periodos entre las fechas
 * @param {Object} obj Objeto con la informacion
 * @param {string} fechaI Nombre del campo fecha de inicio
 * @param {string} fechaF Nombre del campo fecha de termino
 * @param {number|string} nopays Nombre del campo cantidad de pagos a relaizar
 * @returns bolean
 */
export function calcPeriods(obj, fechaI, fechaF, nopays) {
    const dI = textToDate(obj[fechaI]);
    const dF = textToDate(obj[fechaF]);
    /// diferencia de meses sin los meses de inicio y fin
    const difMesR = differenceInMonths(dF, dI) - 1; /// -1 para no incluir el mes de inicio
    /// periodos del mes inicial
    const pI = dI.getDate() > 15 ? 1 : 2;
    /// periodos del mes final
    const pF = dF.getDate() > 15 ? 2 : 1;
    /// suma de periodos de cada decha y los meses
    const totalPeriodos = (difMesR * 2) + pI + pF;
    return totalPeriodos === +obj[nopays]; /// -1 a totalPeriodos
}

export function maxValue(field, value) {
    return field <= value;
}

/**
 * Validar concurrencia de las fechas
 * @param {Object} obj Objeto con los valores
 * @param {string} fechaA Nombre del atributo fecha de inicio
 * @param {string} fechaB Nombre del atributo fecha de termino
 * @returns bolean
 */
export function validateDates(obj, fechaA, fechaB) {
    let fecA = obj[fechaA].split("/").reverse().toString().replaceAll(",", "/");
    let fecB = obj[fechaB].split("/").reverse().toString().replaceAll(",", "/");

    let dateA = new Date(fecA);
    let dateB = new Date(fecB);

    return dateA.getTime() <= dateB.getTime();
}

export function svInitialPeriodM(obj) {
    let errors = 0;
    if (obj.FINALPERIOD !== obj.INITIALPERIOD) {
        // ° , 2
        obj.cols.FINALPERIOD = "text-danger";
        errors++;
    }
    if (obj.FINALYEAR !== obj.INITIALYEAR) {
        obj.cols.FINALYEAR = "text-danger";
        errors++;
    }
    if (obj.PARTIALITIES !== "1") {
        obj.cols.PARTIALITIES = "text-danger";
        errors++;
    }
    if (obj.AMOUNTBIWEEKLY !== obj.TOTALIMPORT) {
        obj.cols.AMOUNTBIWEEKLY = "text-danger";
        errors++;
    }
    return errors;
}

export function isDuplicated(obj, data) {
    let strObj = JSON.stringify(obj);
    let idDuplicated = data.findIndex((el) => strObj === JSON.stringify(el));
    return idDuplicated;
}

/**
 * Comprobar que el numero de cuenta sea valido
 * @param {Object} obj Objeto con los valores
 * @param {string} field Nombre del atributo numero de cuenta
 * @returns bolean
 */
export function isAccount(obj, field) {
    if (isPositiveInt(obj, field)) {
        return rangeLength(obj[field], 10, 16);
    }
    return false;
}

/**
 * Comprobar que la CLABE sea valido
 * @param {Object} obj Objeto con los valores
 * @param {string} field Nombre del atributo CLABE
 * @returns bolean
 */
export function isClabe(obj, field) {
    if (isPositiveInt(obj, field)) {
        return rangeLength(obj[field], 18, 18);
    }
    return false;
}

/**
 * Comprobar la logitud minima y maxima de un valor
 * @param {Object} obj Objeto con los valores
 * @param {number} min logitud minima
 * @param {number} max logitud maxima
 * @returns bolean
 */
export function rangeLength(val, min, max) {
    return val.toString().length >= min && val.toString().length <= max;
}

/**
 * Comprobar que el año sea el actual
 * @param {Object} obj Objeto con los valores
 * @param {string} year Nombre del campo año
 * @retuns boolean
 */
export function sameYear(obj, year) {
    const d = Date();
    d.setHours(0, 0, 0, 0);

    return d.getFullYear() === +obj[year];
}

/*LAYOUT LICENCIAS*/

/**
 * Validar tiempo de los goces
 * @param {Object} obj Objeto con los valores
 * @param {number} errors Cantidad de errores
 * @returns {[Object, number]} [obj, errors]
 */
export function calculateGoces(obj, errors) {
    let vacios = 0;
    const ttDias = dateDiffInDays(obj, "INICIO", "TERMINO");
    let acumDays = 0;
    const keys = [
        "INICIOCON",
        "TERMINOCON",
        "INICIOMEDIO",
        "TERMINOMEDIO",
        "INICIOSIN",
        "TERMINOSIN",
    ];
    let err = 0;
    let empty = 0;
    for (let i = 0; i < keys.length; i += 2) {
        err = 0;
        empty = 0;
        if (!notVoid(obj[keys[i]])) empty++;
        else if (!isDate(obj, keys[i])) {
            obj.cols[keys[i]] = "text-danger";
            err++;
        }

        if (!notVoid(obj[keys[i + 1]])) {
            if (empty === 0) obj.cols[keys[i + 1]] = s.trDangerBg;
            empty++;
        } else {
            if (empty === 1) obj.cols[keys[i]] = s.trDangerBg;
            if (!isDate(obj, keys[i + 1])) {
                obj.cols[keys[i]] = "text-danger";
                err++;
            }
        }

        if (empty === 0 && err === 0) {
            if (validateDates(obj, keys[i], keys[i + 1])) {
                acumDays += dateDiffInDays(obj, keys[i], keys[i + 1]);
            } else if (acumDays === 0) {
                obj.cols[keys[i]] = "text-danger";
                obj.cols[keys[i + 1]] = "text-danger";
                errors++;
            }
        }

        vacios += empty;
    }

    if (vacios === 6) {
        obj = setClases(obj, ` ${s.trDangerBg}`);
        errors++;
    } else if (acumDays !== ttDias) {
        obj = setClases(obj, " text-danger");
        errors++;
    }

    return [obj, errors];
}

function dateDiffInDays(ob, fi, ft) {
    const _MS_PER_DAY = 1000 * 60 * 60 * 24;
    const a = new Date(ob[fi].split("/").reverse());
    const b = new Date(ob[ft].split("/").reverse());

    const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate() - 1);
    const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

    return Math.floor((utc2 - utc1) / _MS_PER_DAY);
}

function setClases(obj, clase) {
    obj.cols.INICIOCON += clase;
    obj.cols.TERMINOCON += clase;
    obj.cols.INICIOMEDIO += clase;
    obj.cols.TERMINOMEDIO += clase;
    obj.cols.INICIOSIN += clase;
    obj.cols.TERMINOSIN += clase;
    return obj;
}

/**
 * Validar que el peridodo de inicio sea el mismo que la fecha de inicio
 * @param {Object} obj Objeto con los valores
 * @param {*} date Nombre del campo fecha de inicio
 * @param {*} period Nombre del campo periodo de inicio
 */
export function validPeriod(obj, date, period) {
    const d = textToDate(obj[date]);
    const p = getPeriod(d);
    return +obj[period] === p;
}

/**
 * Validar que el año de inicio sea el mismo que la fecha de inicio
 * @param {*} obj Objeto con los valores
 * @param {*} date Nombre del campo fecha de inicio
 * @param {*} year Nombre del campo año de inicio
 */
export function itsSameYear(obj, date, year) {
    const d = textToDate(obj[date]);
    return +obj[year] === d.getFullYear();
}

function textToDate(val) {
    const d = new Date(val.split("/").reverse());
    d.setHours(0, 0, 0, 0);
    return d;
}

function getPeriod(d) {
    const m = d.getMonth() + 1;
    return d.getDate() > 15 ? m * 2 : m * 2 - 1;
}

/**
 * Genera la alerta emergente apra mostrar el error
 * @param {string} field Nombre del campo con el error
 * @param {number} error Tipo de error de acuardo al campo
 *
 * @returns {JSX.Element} Componete alerta
 */
// function setError(field, error){
//   let tt = "";
//   let msg = "";
//   switch(field){
//     case "STAFFNAME":
//       tt = "Nombre invalido";
//       msg = "El nombre ingresado es inválido.";
//     break;
//     case "UNDER":
//     tt = "Concepto desconocido";
//     msg = "El concepto ingresado no existe.";
//     break;
//     case "INITIALPERIOD":
//       tt = "Periodo inicial invalido";
//       msg = errInitialPeriod(error);
//     break;
//     case 4:
//     tt = "aaaaaaaa";
//     msg = "aaaaaaaa";
//     break;
//     case 5:
//     tt = "aaaaaaaa";
//     msg = "aaaaaaaa";
//     break;
//     case 6:
//     tt = "aaaaaaaa";
//     msg = "aaaaaaaa";
//     break;
//     case 7:
//     tt = "aaaaaaaa";
//     msg = "aaaaaaaa";
//     break;
//     case 8:
//     tt = "aaaaaaaa";
//     break;
//     case 9:
//     tt = "aaaaaaaa";
//     msg = "aaaaaaaa";
//     break;
//     case 10:
//     tt = "aaaaaaaa";
//     msg = "aaaaaaaa";
//     break;
//     case 11:
//     tt = "aaaaaaaa";
//     msg = "aaaaaaaa";
//     break;
//     case 12:
//     tt = "aaaaaaaa";
//     msg = "aaaaaaaa";
//     break;
//     case 13:
//     tt = "aaaaaaaa";
//     msg = "aaaaaaaa";
//     break;

//   }
// }

/**
 * Mensajes de error para el campo INITIALPERIOD
 * @param {number} err Tipo de error
 * @returns {string} Mensaje de error
 */
// function errInitialPeriod(err){
//   switch(err){
//     /* Validacion basica */
//     case 1: return "El periodo debe ser un número entero positivo." // No es un entero positivo
//     /* Validaciones especiales de percepciones y deducciones */
//     case 2: return "El periodo inicial y el periodo final son diferentes. Para periodos extemporáneos el periodo inicial y final deben ser el mismo."
//     case 3: return "";
//   }
// }

import React, { useState } from "react";
import ModalTableVer from "../../../../components/Modal/ModalTableVer";
import { useFormik } from "formik";
import { Col, Row, Button } from "reactstrap";
import { CancelOutlined } from "@material-ui/icons";
import s from "../InputClear.module.scss";

const defaultForm = {
  idStaff: "",
  nameStaff: "",
  idArea: "",
  nameArea: "",
};

const ConsultaBienes = (props) => {
  const [boolConsulta, setBoolConsulta] = useState("");
  const { setObj, setCollapse } = props;
  const [modalTablaStaff, setModalTablaStaff] = useState(false);
  const cabecerasModalTablaStaff = ["#", "Empleado"];
  const [paramsModalTablaStaff] = useState({
    action: "datatable",
    table: "staffview",
    rows: "idstaff,CONCAT(staffNumber,'-',name,'-',pName,'-',mName) as fullname",
    conditions: "",
    page: 0,
    records: 5,
    search: "",
    order: "",
  });

  const [modalTablaArea, setModalTablaArea] = useState(false);
  const cabecerasModalTablaArea = ["#", "Adscripcion"];
  const [paramsModalTablaArea] = useState({
    action: "datatable",
    table: "areas",
    rows: "idArea,name",
    conditions: "",
    page: 0,
    records: 5,
    search: "",
    order: "",
  });

  const onChange = (e) => {
    setFieldValue([e.target.name], e.target.value);
  };

  const validaCampos = () => {
    if (values.idStaff !== "" || values.idArea !== "") {
      setBoolConsulta(true);
      return true;
    } else {
      setBoolConsulta(false);
      return false;
    }
  };

  const { handleSubmit, handleBlur, values, errors, touched, setFieldValue } =
    useFormik({
      initialValues: defaultForm,
      onSubmit: (values) => {
        if (validaCampos()) {
          if (values.idStaff !== "") {
            setObj({
              type: "staff",
              id: values.idStaff,
            });
          } else {
            setObj({
              type: "area",
              id: values.idArea,
            });
          }
        }
      },
      /* validationSchema: FormSchema, */
      enableReinitialize: true,
    });

  const selectStaff = (el) => {
    setFieldValue("idStaff", el[0]);
    setFieldValue("nameStaff", `${el[1]}`);
    setModalTablaStaff(false);
    clearArea();
  };
  const selectArea = (el) => {
    setFieldValue("idArea", el[0]);
    setFieldValue("nameArea", `${el[1]}`);
    setModalTablaArea(false);
    clearStaff();
  };
  function clearStaff() {
    setFieldValue("idStaff", "");
    setFieldValue("nameStaff", "");
    setObj("");
    setCollapse(false);
  }
  function clearArea() {
    setFieldValue("idArea", "");
    setFieldValue("nameArea", "");
    setObj("");
    setCollapse(false);
  }
  return (
    <div>
      <form onSubmit={handleSubmit} className="mt-4" noValidate>
        {/* <Row>
          <Col xs={12} lg={12} className="text-center">
            <span className="text-center text-succes headline-1">
              Consulta de bienes
            </span>
          </Col>
        </Row> */}
        <div>
          <Row className="d-flex justify-content-around">
            <Col xs={12} lg={10}>
              <div className="form-group position-relative">
                <label htmlFor="nameStaff">
                  Empleado <span className="text-danger">*</span>
                </label>
                <div className="input-group">
                  <input
                    name="nameStaff"
                    onChange={onChange}
                    onBlur={handleBlur}
                    className={
                      "form-control" +
                      (errors.nameStaff && touched.nameStaff
                        ? " is-invalid"
                        : "")
                    }
                    value={values.nameStaff}
                    disabled
                  />
                  <div className="input-group-append">
                    <button
                      className="btn btn-secondary"
                      style={{ zIndex: "auto" }}
                      type="button"
                      onClick={() => setModalTablaStaff(true)}
                    >
                      <i className="fa fa-search" />
                    </button>
                  </div>
                </div>
                {values.nameStaff !== "" && (
                  <CancelOutlined className={s.btnClear} onClick={clearStaff} />
                )}
                {touched.nameStaff && errors.nameStaff && (
                  <span style={{ color: "red" }}>{errors.nameStaff}</span>
                )}
              </div>
            </Col>
          </Row>
          <Row className="d-flex justify-content-around">
            <Col xs={12} lg={10}>
              <p className="dividing-line-form my-5">&#8195;&Oacute;&#8195;</p>
            </Col>
          </Row>
          <Row className="d-flex justify-content-around">
            <Col xs={12} lg={10}>
              <div className="form-group position-relative">
                <label htmlFor="nameArea">
                  Adscripcion <span className="text-danger">*</span>
                </label>
                <div className="input-group">
                  <input
                    name="nameArea"
                    onChange={onChange}
                    onBlur={handleBlur}
                    className={
                      "form-control" +
                      (errors.nameArea && touched.nameArea ? " is-invalid" : "")
                    }
                    value={values.nameArea}
                    disabled
                  />
                  <div className="input-group-append">
                    <button
                      className="btn btn-secondary"
                      style={{ zIndex: "auto" }}
                      type="button"
                      onClick={() => setModalTablaArea(true)}
                    >
                      <i className="fa fa-search" />
                    </button>
                  </div>
                </div>
                {values.nameArea !== "" && (
                  <CancelOutlined className={s.btnClear} onClick={clearArea} />
                )}
                {touched.nameArea && errors.nameArea && (
                  <span style={{ color: "red" }}>{errors.nameArea}</span>
                )}
              </div>
            </Col>
          </Row>
        </div>
        <Row className="mt-3 mb-3">
          <Col className="text-center">
            {boolConsulta !== "" && !boolConsulta && (
              <span className="headline-2" style={{ color: "red" }}>
                Seleccione almenos un campo
              </span>
            )}
          </Col>
        </Row>

        <Row>
          <Col className="text-center">
            <Button color="primary" type="submit">
              Buscar
            </Button>
          </Col>
        </Row>
      </form>

      <ModalTableVer
        modal={modalTablaStaff}
        setModal={setModalTablaStaff}
        title="Empleados"
        cabeceras={cabecerasModalTablaStaff}
        filtro={[true, true]}
        parametros={paramsModalTablaStaff}
        selectRegistro={selectStaff}
        backdrop
        keyboard
        btnClose
      />
      <ModalTableVer
        modal={modalTablaArea}
        setModal={setModalTablaArea}
        title="Adscripcion"
        cabeceras={cabecerasModalTablaArea}
        filtro={[true, true]}
        parametros={paramsModalTablaArea}
        selectRegistro={selectArea}
        backdrop
        keyboard
        btnClose
      />
    </div>
  );
};

export default ConsultaBienes;
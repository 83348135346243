
import React from "react";
import Widget from "../../../../components/Widget/Widget";
import FormPuestoTrabajo from "../FormPuestoLaboral";
import { createJobs } from "../functions";

export const CrearPuestoLaboral = (props) => {

  return (
    <Widget className="widget-p-md">
        <h3>Crear Puesto laboral</h3>
        <FormPuestoTrabajo crear={createJobs}/>
    </Widget>
  )
}

export default CrearPuestoLaboral;
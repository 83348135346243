import * as XlsxPopulate from "xlsx-populate";
import { saveAs } from "file-saver";

/**
 * Formateador de numeros de concurrencias
 */
const formatter = new Intl.NumberFormat("es-MX", {
    style: "currency",
    currency: "MXN",
});

/**
 * Hoja de estilos del excel
 */
const styles = {
    title: {
        horizontalAlignment: 'center', bold: true
    },
    bold: {
        bold: true
    },
    center: {
        horizontalAlignment: 'center'
    },
    headerBorder: {
        bold: "true",
        border: true,
        fill: "BFBFBF",
    },
    header: {
        bold: "true",
        border: true,
        fill: "BFBFBF",
    },
    headerBoderBold: {
        bold: "true",
        border: true,
    },
    contentBorder: {
        border: true,
    },
    contentBorderB: {
        border: true,
        bold: true
    },
    contentBorderRigth: {
        horizontalAlignment: 'right'
    },
    contentBorderRigthB: {
        horizontalAlignment: 'right',
        bold: true
    },
};

export async function saveReport48(report, title, config) {

    const conceptos = [];

    const data = [...report.data, ...report.dataCero]

    data.forEach(row => {
        const index = conceptos.findIndex(con => con.key === row.keyIncomeType);
        if (index !== -1) {
            conceptos[index].values.push(row);
        } else {
            conceptos.push({ key: row.keyIncomeType, name: row.nameIncomeType, values: [row] })
        }
    })

    XlsxPopulate.fromBlankAsync().then(async (workbook) => {
        const sheet1 = workbook.sheet(0);
        //Titulo
        sheet1
            .cell(`A1`)
            .value(`FISCALIA GENERAL DEL ESTADO DE MÉXICO`)
            .style(styles.title);
        sheet1
            .cell(`A2`)
            .value("SAPP")
            .style(styles.title);
        sheet1
            .cell(`A3`)
            .value(title)
            .style(styles.center);
        sheet1
            .range(`B2:B3`)
            .merged(true)
            .value("Ingreso estimado")
            .style(styles.title);

        sheet1
            .cell(`A4`)
            .value("TOTAL")
            .style(styles.center);

        sheet1
            .cell(`B4`)
            .value("TOTAL CRI")
            .style(styles.center);


        let index = 5;

        conceptos.forEach(concepto => {
            sheet1
                .cell(`A${index}`)
                .value(concepto.name)
                .style(styles.bold);
            sheet1
                .cell(`B${index}`)
                .value(concepto?.key.toString() === "9" ? formatter.format(report.montoTotal) : "$0.00")
                .style(styles.contentBorderRigthB);
            index++;
            concepto.values.forEach(value => {
                sheet1
                    .cell(`A${index}`)
                    .value(value.nameIncombeSubType)
                sheet1
                    .cell(`B${index}`)
                    .value(value.nameIncombeSubType === "Transferencias Internas y Asignaciones al Sector Público" ? formatter.format(report.montoTotal) : "$0.00")
                    .style(styles.contentBorderRigth)
                index++;
            })
        })

        sheet1.column("A").width(50);
        sheet1.column("B").width(20);


        //Subtitulos
        return workbook.outputAsync().then((res) => {
            saveAs(res, `${title}.xlsx`);
        });
    })
}
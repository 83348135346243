import React from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import Datatable from "../../components/Datatable/Datatable";

export default function ModalDataTableVerF(props) {
  const {
    modal,
    setModal,
    title,
    backdrop, // true, false, "static"
    keyboard, // true, false
    btnClose, // true, false
    style,
    headers,
    columns,
    data,
    columnDefs
  } = props;

  const toggle = () => {
    setModal(!modal);
  };

  const CloseBtn = (
    <>
      {btnClose ? (
        <i className="eva eva-close cursor-pointer" onClick={toggle} />
      ) : (
        <></>
      )}
    </>
  );



  return (
    <>
      <Modal
        isOpen={modal}
        backdrop={backdrop}
        keyboard={keyboard}
        toggle={toggle}
        size="xl"
        style={style}
      >
        <ModalHeader toggle={toggle} close={CloseBtn}>
          {title || ""}
        </ModalHeader>
        <ModalBody className="mx-2">
          <Datatable
            headers={headers}
            columns={columns}
            columnDefs={columnDefs}
            data={data}
            control="front"
          />
        </ModalBody>
      </Modal>
    </>
  );
}

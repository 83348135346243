import { useState } from "react";
import { useEffect } from "react";
import { Card, CardBody, CardHeader, Col, Row, Table } from "reactstrap";
import FullFormLoader from "../../../components/Loader/FullFormLoader";
import { doRequest } from "../../../helpers/requests";

export default function ProveedorRev({data, status}){
    const [loadingV, setLoadingV] = useState(true);
    const [loading, setLoading] = useState(false);
    const [accounts, setAccounts] = useState("");
    const [accSel, setAccSel] = useState("");
    const info = data.split(",");
    useEffect(()=>{
        getProvider();
    }, []);

    async function getProvider(){
        const params = {
            action: "multiselect",
            table: `providers A INNER JOIN provideraccounts B USING(idProvider) INNER JOIN banks C USING(keyBank) INNER JOIN warehouseincomes D ON D.idIncome = ${info[0]}`,
            rows: "A.socialName, A.itin, A.email, A.webPage, A.telephone, C.nameBank, B.account, B.swiftCode, B.idProviderAccount, D.idProviderAccount AS acpag",
            conditions: `A.enabled = 1 AND B.enabled = 1 AND A.idProvider = ${info[1]}`
        }
        const res = await doRequest("receiver/receiver.php", params, false, true);
        if(res.length > 0){
            setAccounts(res);
            const fnd = res.find((el) => el.acpag);
            setAccSel(fnd ? fnd.acpag : "");
        }
        setLoadingV(false);
    }

    async function selectAccount(row, ix){
        if(status) return;
        const nv = accSel === row.idProviderAccount ? "" : row.idProviderAccount;
        const params = {
            action: "update",
            table: "warehouseincomes",
            rows: { idProviderAccount: nv },
            condition: { idIncome: info[0]},
            validate: []
        }
        setLoading(true);
        const res = await doRequest("receiver/receiver.php", params, true);
        if(res.length > 0){
            setAccSel(nv);
        }
        setLoading(false);
    }

    if(loadingV){
        return(
            <div className="position-relative" style={{height: "100px"}}>
                <FullFormLoader show={true}/>
            </div>
        )
    }

    return(
        <div className="position-relative">
            {
                accounts.length > 0 ?
                <Card>
                <CardHeader className="text-light bg-primary" tag="h6">
                    Proveedor: {accounts[0].socialName}
                </CardHeader>
                <CardBody>
                    <Row>
                        <Col xs={12} md={8}>
                            <strong>Razón Social: </strong> {accounts[0].socialName}
                        </Col>
                        <Col xs={12} md={4}>
                            <strong>RFC: </strong> {accounts[0].itin}
                        </Col>
                        <Col xs={12} md={4}>
                            <strong>Correo: </strong> {accounts[0].email}
                        </Col>
                        <Col xs={12} md={5}>
                            <strong>página Web: </strong> {accounts[0].webPage}
                        </Col>
                        <Col xs={12} md={3}>
                            <strong>Teléfono: </strong> {accounts[0].telephone}
                        </Col>
                    </Row>
                    <Table  striped hover responsive className="mt-2">
                    <thead className="border-bottom border-secondary text-center">
                        <tr>
                            <th>No.</th>
                            <th>Banco</th>
                            <th>Cuenta</th>
                            <th>Clabe</th>
                            <th>Pagar</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            accounts.map((el, ix)=>(
                                <tr key={"rc"+ix}>
                                    <td className="text-center">{ix+1}</td>
                                    <td>{el.nameBank}</td>
                                    <td className="text-center">{el.account}</td>
                                    <td className="text-center">{el.swiftCode}</td>
                                    <td>
                                        <div className="form-check checkbox checkbox-dark-gray d-flex justify-content-center">
                                            <input
                                                type="checkbox"
                                                className="styled"
                                                checked={el.idProviderAccount === accSel}
                                                onChange={({ target }) => selectAccount(el, ix)}
                                            />
                                            <label />
                                        </div>
                                    </td>
                                </tr>
                            ))
                        }
                    </tbody>
                    </Table>
                </CardBody>
                </Card> :
                <div className="text-center p-5">
                    <h6>Este proveedor no tiene cuentas activas registradas</h6>
                </div>
            }
            <FullFormLoader sohw={loading} />
        </div>
    )
}
import React, { useState, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage, useFormikContext } from "formik";
import * as Yup from "yup";
import { Row, Col, FormGroup, Label, Button } from "reactstrap";
import { toast } from "react-toastify";
import Notification from "../../../components/Notification/Notification";
import Loader from "../../../components/Loader/Loader";
import ModalConfirmation from "../../../components/Modal/ModalConfirmation";
import { peticionesReceiver } from "../../../helpers/peticionesReceiver";

const defaultForm = {
  idStaffSSec: "",
  idStaff: "",
  socialSecurityKey: "",
  startDate: "",
  endDate: "",
};

const FormSchema = Yup.object().shape({
  socialSecurityKey: Yup.string()
    .min(6, "Número de seguro muy corto")
    .required("Ingrese un número de seguro"),
  startDate: Yup.date().required("Seleccione una fecha de inicio"),
  // endDate: Yup.date()
  //   .required("Seleccione una fecha de termino")
  //   .when("startDate", {
  //     is: (val) => val !== undefined && val !== "",
  //     then: Yup.date().min(
  //       Yup.ref("startDate"),
  //       "La fecha debe ser posterior a la fecha de inicio"
  //     ),
  //   }),
});

const PersonalSeguro = ({ idStaff, permissions }) => {
  const API = peticionesReceiver();
  const [loading, setLoading] = useState(true);
  const [modal, setModal] = useState(false);
  const [info, setInfo] = useState(defaultForm);

  useEffect(() => {
    getInfo();
  }, []);

  const getInfo = async () => {
    setLoading(true);
    if (idStaff) {
      const params = {
        action: "select",
        table: "staffsocialsecurity",
        condition: {
          enabled: 1,
          idStaff: idStaff,
        },
      };
      API.peticion(params)
        .then((res) => {
          if (res.status === 200 && res.data.code === "200") {
            if (res.data.data.length !== 0) {
              setInfo({
                idStaffSSec: res.data.data[0].idStaffSSec,
                idStaff: res.data.data[0].idStaff,
                socialSecurityKey: res.data.data[0].socialSecurityKey,
                startDate: res.data.data[0].startDate,
                endDate: res.data.data[0].endDate || "",
              });
            }
          } else {
            toast(
              <Notification
                type={"consultar_error"}
                backMessage={res.data.message}
                withIcon
              />
            );
          }
          setLoading(false);
        })
        .catch((err) => {
          toast(<Notification type={"consultar_servidor_error"} withIcon />);
          setLoading(false);
        });
    }
  };

  const editInfo = (values) => {
    const rows = {
      idStaff: values.idStaff || idStaff,
      socialSecurityKey: values.socialSecurityKey,
      startDate: values.startDate,
      endDate: values.endDate,
    };
    const params = {
      action: "",
      table: "staffsocialsecurity",
      rows: rows,
      validate: [["socialSecurityKey"]],
    };
    if (values.idStaff) {
      params.action = "update";
      params.condition = { idStaffSSec: values.idStaffSSec };
    } else {
      params.action = "insert";
    }
    API.peticion(params)
      .then((res) => {
        if (res.status === 200 && res.data.code === "200") {
          toast(
            <Notification
              type={"modifica_exito"}
              backMessage={res.data.message}
              withIcon
            />
          );
          getInfo();
        } else {
          toast(
            <Notification
              type={"modifica_error"}
              backMessage={res.data.message}
              withIcon
            />
          );
        }
      })
      .catch((err) => {
        toast(<Notification type={"consultar_servidor_error"} withIcon />);
      });
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <Formik
          initialValues={info}
          validationSchema={FormSchema}
          onSubmit={() => {
            setModal(true);
          }}
        >
          {({ errors, touched, values }) => (
            <Form className="mt-4">
              <ModalConfirmation
                modalTitle={"Editar"}
                modal={modal}
                setModal={setModal}
                editar={editInfo}
                isEdit={true}
                values={values}
              >
                <div className="d-flex justify-content-center">
                  <h6>¿Está seguro de editar la información?</h6>
                </div>
              </ModalConfirmation>
              <h5 className="mb-3">ISSEMYM</h5>
              <Row>
                <Col xs={12} lg={4}>
                  <FormGroup>
                    <Label for="socialSecurityKey">
                      Clave ISSEMYM <span className="text-danger">*</span>
                    </Label>
                    <Field
                      type="text"
                      name="socialSecurityKey"
                      className={
                        "form-control" +
                        (errors.socialSecurityKey && touched.socialSecurityKey
                          ? " is-invalid"
                          : "")
                      }
                    />
                    <ErrorMessage
                      name="socialSecurityKey"
                      component="div"
                      className="invalid-feedback"
                    />
                  </FormGroup>
                </Col>
                <Col xs={12} lg={4}>
                  <FormGroup>
                    <Label for="startDate">Fecha de inicio <span className="text-danger">*</span></Label>
                    <Field
                      type="date"
                      name="startDate"
                      className={
                        "form-control" +
                        (errors.startDate && touched.startDate
                          ? " is-invalid"
                          : "")
                      }
                    />
                    <ErrorMessage
                      name="startDate"
                      component="div"
                      className="invalid-feedback"
                    />
                  </FormGroup>
                </Col>
                <Col xs={12} lg={4}>
                  <FormGroup>
                    <Label for="endDate">Fecha de termino</Label>
                    <Field
                      type="date"
                      name="endDate"
                      className={
                        "form-control"
                      }
                      min={values.startDate}
                    />
                  </FormGroup>
                </Col>
              </Row>
              { (values.idStaff ? permissions.UPD : permissions.INS) && <div className="row mt-3">
                <div className="col-sm-6 order-2 order-sm-1 text-center text-sm-left mb-4">
                  <Button color="danger" type="reset">
                    Cancelar
                  </Button>
                </div>
                <div className="col-sm-6 order-1 order-sm-2 text-center text-sm-right mb-4">
                  <Button color="success" type="submit">
                    Guardar
                  </Button>
                </div>
              </div>}
              <FormikData info={info} />
            </Form>
          )}
        </Formik>
      )}
    </>
  );
};

const FormikData = ({ info }) => {
  const { setFieldValue } = useFormikContext();
  useEffect(() => {
    if (info) {
      setFieldValue("idStaffSSec", info.idStaffSSec);
      setFieldValue("idStaff", info.idStaff);
      setFieldValue("socialSecurityKey", info.socialSecurityKey);
      setFieldValue("startDate", info.startDate);
      setFieldValue("endDate", info.endDate);
    }
  }, [info]);
  return null;
};

export default PersonalSeguro;


import React from "react";

import Widget from "../../../../components/Widget/Widget";
import FormObjetos from "../FormObjetos";
import { updateObjetoGasto } from "../functions";

export const EditarObjetoGasto = (props) => {
  return (
    <Widget className="widget-p-md">
        <h3>Editar objeto de gasto</h3>
        <FormObjetos data={localStorage.getItem('dataContent').split('|-|')} editar={updateObjetoGasto}/>
    </Widget>
  )
}

export default EditarObjetoGasto;
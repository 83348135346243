import { peticionesReceiver } from "../../../helpers/peticionesReceiver";
import Notification from "../../../components/Notification/Notification";
import { toast } from "react-toastify";
const API = peticionesReceiver();

export const createComision = async (comisionN) => {
  if (comisionN) {
    const params = {
      action: "insert",
      table: "kindbankmoves",
      rows: comisionN,
      order: "nameKindBankMove asc",
      validate: [["nameKindBankMove"]],
    };
    API.peticion(params)
      .then((res) => {
        if (res.status === 200 && res.data.code === "200") {
          toast(<Notification type={"agrega_exito"} withIcon />);
          window.location.href = "#/template/comisiones";
        } else {
          toast(
            <Notification
              type={"agrega_error"}
              backMessage={res.data.message}
              withIcon
            />
          );
        }
      })
      .catch((err) => {
        toast(<Notification type={"consultar_servidor_error"} withIcon />);
      });
  }
};

export const updateComision = async (comisionU) => {
  if (comisionU) {
    const params = {
      action: "update",
      table: "kindbankmoves",
      rows: comisionU,
      condition: {
        keyKindMove: comisionU.keyKindMove,
      },
      validate: [["nameKindBankMove"]],
    };
    API.peticion(params)
      .then((res) => {
        if (res.status === 200 && res.data.code === "200") {
          toast(<Notification type={"modifica_exito"} withIcon />);
          window.location.href = "#/template/comisiones";
        } else {
          toast(
            <Notification
              type={"modifica_error"}
              backMessage={res.data.message}
              withIcon
            />
          );
        }
      })
      .catch((err) => {
        toast(<Notification type={"consultar_servidor_error"} withIcon />);
      });
  }
};
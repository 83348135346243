
// import React, { useState, useEffect } from "react";
// import axios from "axios";
// import * as Yup from "yup";
// import { useFormik } from "formik";
// import parse from "date-fns/parse";
// import { toast } from "react-toastify";
// import DatePicker, { registerLocale } from "react-datepicker";
// import CurrencyInput from "react-currency-input-field";
// import { Col, Row, Button, Collapse, CustomInput } from "reactstrap";
// import "react-datepicker/dist/react-datepicker.css";

import ENMultasRF from "./ENMultasRF";

// import { peticionEncript } from "../../../helpers/peticionesEncripted";

// import Loader from "../../../components/Loader/Loader";
// import Widget from "../../../components/Widget/Widget";
// import Notification from "../../../components/Notification/Notification";
// import ModalConfirmation from "../../../components/Modal/ModalConfirmation";
// import TableComponentDiscounts from "../../../components/TableComponent/TableComponentDiscounts";

// import es from "date-fns/locale/es";
// import {
//   TextInput,
//   SelectTypeHeadSingle,
//   SelectInput,
//   FilesInput,
// } from "../../../components/GenericInputsFormik/index";

// import {
//   getApplicationSalaries,
//   getKindToStatus,
//   getPenaltysType,
//   getUnders,
// } from "./consultasSelect";

// import { PERIOD } from "../../../helpers/regex";
// import { YearInput } from "../../../components/GenericInputsFormik/YearInput";
// import { useRef } from "react";

// import { doRequest, uploadFile } from "../../../helpers/requests";
// import TableComponentCustom from "../../../components/TableComponent/TableComponentCustom";
// import ModalViewFile from "../../../components/Modal/ModalViewFile";
// import ModalComponentEliminar from "../../../components/Modal/ModalDelete";

// registerLocale("es", es);

// const actualDate = new Date();

export const ExpedienteNominaMultas = ({ idStaff, permissions }) => {
  return <ENMultasRF idStaff={idStaff} permissions={permissions} />
//   // || ------------------- INICIO DE LOS HOOKS ---------------- || //
//   const refInpFile = useRef(null);
//   const [modalFile, setModalFile] = useState(false); ///Visualziar archivos
//   const [linkFile, setLinkFile] = useState(""); ///Link de archivo

//   // || ------------------- HOOKSREFACTOR ---------------- || //

//   const [objEmployHistory, setObjEmployHistory] = useState([]); //
//   const [actualPeriodDate, setActualPeriodDate] = useState([]); //{actualPeriod: date, actualYear: date, firstDayPeriod:date  }

//   // ------------------- SELECTS PARA EL FORMULARIO ---------------- //

//   const [selectsData, setSelectsData] = useState({
//     penaltyTypes: "",
//     statusTypes: "",
//     applicationSalaryTypes: "",
//     underTypes: "",
//   });

//   const [salaryTypesBack, setSalaryTypesBack] = useState([]);

//   const [forPeriod, setForPeriod] = useState(false);
//   const [editHasFile, setEditHasFile] = useState(false);

//   // -------------------  INFORMACION PARA EL  BACK ---------------- //

//   const [penaltyData, setpenaltyData] = useState({
//     idPenalty: "",
//     keyTypePenalty: "",
//     keyStat: "",
//     idUnder: "",
//     policy: "",
//     startDate: "",
//     endDate: "",
//     noDays: 0,
//     application: "",
//     amountTotal: "",
//     amountBiweekly: "",
//     noPays: "",
//     noPaysMade: "",
//     notes: "",
//     startPeriod: "",
//     startYear: actualDate,
//     endPeriod: "",
//     endYear: "",
//     comprobante: "",
//   });

//   const [finalData, setFinalData] = useState("");

//   // ------------------- PARA EL DESCUENTO Y LA APLICACIÓN Y FECHA ---------------- //

//   const [discounts, setDiscounts] = useState([]);

//   const [descuentosAplicacion, setDescuentosAplicacion] = useState({
//     idPenaltysDays: "",
//     descuentosAplicacionKeyTypeApplicationSalary: "",
//     descuentosAplicacionStartDate: "",
//     descuentosAplicacionEndDate: "",
//     descuentosAplicacionNoDays: "",
//     enabled: 1,
//   });

//   const [errorsDiscount, setErrorsDiscount] = useState(false);

//   const [errorApplications, setErrorApplications] = useState(false);

//   const [excludeDates, setExcludeDates] = useState([]);

//   const [noActiveJob, setNoActiveJob] = useState(false);

//   // ------------------- PARA LA TABLA ONE PAGE Y FORMULARIO ------------------- //

//   const [editando, setEditando] = useState(false);

//   const [parametros, setParametros] = useState({
//     action: "datatable",
//     table:
//       "penaltys l inner join penaltysdays ld on l.idPenalty = ld.idPenalty inner join types_applicationsalary aps on ld.keyTypeApplicationSalary = aps.keyTypeApplicationSalary inner join status s on l.keyStat = s.keyStat inner join types_penaltys k on l.keyTypePenalty = k.keyTypePenalty LEFT JOIN bookcase X ON l.idPenalty = X.reference AND X.keyFileType = 48 LEFT JOIN files Y ON X.idBook = Y.idBook ",
//     rows: "l.idPenalty, l.policy, l.startDate, l.endDate, l.noDays, k.namePenalty, s.nameStat, group_concat(nameTypeApplicationSalary separator ' - ') formasAplicacion, Y.src",
//     conditions: `l.enabled=1 and ld.enabled=1 and l.idStaff=${idStaff}`,
//     page: 0,
//     records: 5,
//     search: "",
//     order: "",
//     group: "l.idPenalty",
//   });

//   const [cabeceras] = useState([
//     "Id",
//     "Oficio",
//     "Fecha de inicio",
//     "Fecha de termino",
//     "No. Dias",
//     "Tipo de sanción",
//     "Estatus",
//     "Formas de aplicacion",
//     "Evidencia",
//     "Editar",
//     "Eliminar",
//   ]);

//   const [collapse, setCollapse] = useState(false);

//   const [filtro] = useState([true, true, true, true, true, true, true, true]);

//   // ------------------- GENERALES ---------------- //

//   const [modal, setModal] = useState(false);

//   const [loading, setLoading] = useState(true);

//   const [showSection, setShowSection] = useState(false);

//   // || ------------------- FIN DE LO HOOKS ---------------- || //

//   // -------------------------------------------------------------------- //

//   // || ------------------- INICIO DEL SCHEMA ---------------- || //

//   const FormSchema = Yup.object().shape({
//     keyTypePenalty: Yup.string().required("Seleccione un tipo de licencia"),
//     keyStat: Yup.string().required("Seleccione un estatus"),
//     idUnder: Yup.string().required(
//       "El impuesto de la multa/sancion es requerido"
//     ),
//     policy: Yup.string().required("Ingrese una politica"),
//     startDate: Yup.date()
//       .transform(function (value, originalValue) {
//         if (this.isType(value)) {
//           return value;
//         }
//         const result = parse(originalValue, "dd.MM.yyyy", new Date());
//         return result;
//       })
//       .typeError("Ingrese una fecha valida")
//       .required("La fecha de inicio es requerida")
//       .min("1990-01-01", "Date is too early"),
//     endDate: Yup.date()
//       .transform(function (value, originalValue) {
//         if (this.isType(value)) {
//           return value;
//         }
//         const result = parse(originalValue, "dd.MM.yyyy", new Date());
//         return result;
//       })
//       .typeError("Ingrese una fecha valida")
//       .required("La fecha final es requerida")
//       .min(
//         penaltyData.startDate !== "" ? penaltyData.startDate : "1990-01-01",
//         "La fecha de finalización debe ser mayor o igual a la de inicio"
//       ),
//     comprobante: Yup.mixed().when("act", {
//       is: (v) => {
//         return editHasFile;
//       },
//       otherwise: Yup.mixed()
//         .required("Agregue un comprobante")
//         .test("file-type", "El documento debe ser en formato PDF", (value) => {
//           return value && value.type === "application/pdf";
//         })
//         .test({
//           message: `El documento debe pesar menos de 4MB`,
//           test: (value) => value?.size < 4000000,
//         }),
//     }),
//   });

//   // || ------------------- FIN DEL SCHEMA ---------------- || //

//   // -------------------------------------------------------------------- //

//   // || ------------------- INICIO DE LAS FUNCIONES ---------------- || //

//   // ------------------- FUNCIONES PARA LA TABLA Y MOSTRAR FORMULARIO ----------------

//   const toggle = () => setCollapse(!collapse);

//   const modoEdicion = async (e) => {
//     if (!collapse) {
//       if (e[8]) setEditHasFile(true);
//       //LLENAMOS EL HOOK CON LOS DATOS UNICAMENTE EL INCAPACITY, ADICIONALMENTE HAY QUE HACER EL LLAMADO A LAS FUNCIONES PARA TRAER APPLICATION
//       setLoading(true);
//       await getApplicationByidPenalty(e);
//     } else {
//       toast(
//         <Notification
//           type="warning"
//           backMessage="Guarde sus cambios antes de continuar"
//         />
//       );
//     }
//   };

//   const limpia = (reset) => {
//     //LIMPIO MI HOOK
//     setDiscounts([]);
//     setEditando(false);
//     setCollapse(false);
//     reset();
//   };

//   const getApplicationByidPenalty = async (e) => {
//     const data = {
//       action: "searchPenaltysById",
//       rows: {
//         idPenalty: e[0],
//       },
//     };
//     const finalData = peticionEncript(data);
//     await axios
//       .post(
//         `${process.env.REACT_APP_API}app/facades/employees/benefitsF.php`,
//         finalData
//       )
//       .then((res) => {
//         var startDate = new Date(res.data.data[0].startDate);
//         var endDate = new Date(res.data.data[0].endDate);
//         startDate.setDate(startDate.getDate() + 1);
//         startDate.setHours(0);
//         startDate.setMinutes(0);
//         startDate.setSeconds(0);
//         endDate.setDate(endDate.getDate() + 1);
//         endDate.setHours(0);
//         endDate.setMinutes(0);
//         endDate.setSeconds(0);
//         setpenaltyData((prevState) => ({
//           ...prevState,
//           idPenalty: res.data.data[0].idPenalty,
//           idUnder: res.data.data[0].idUnder,
//           policy: res.data.data[0].policy,
//           startDate: startDate,
//           endDate: endDate,
//           noDays: res.data.data[0].noDays,
//           amountTotal: res.data.data[0].amountTotal,
//           startYear: new Date(res.data.data[0].startYear, 0, 1),
//           startPeriod: res.data.data[0].startPeriod,
//           endYear: res.data.data[0].endYear,
//           endPeriod: res.data.data[0].endPeriod,
//           amountBiweekly: res.data.data[0].amountBiweekly,
//           noPays: res.data.data[0].noPays,
//           noPaysMade: res.data.data[0].noPaysMade,
//           notes: res.data.data[0].notes,
//           keyTypePenalty: res.data.data[0].keyTypePenalty,
//           keyStat: res.data.data[0].keyStat,
//           application: res.data.data[0].application,
//           forPeriod: +res.data.data[0].forPeriod === 1 ? true : false,
//         }));
//         setForPeriod(+res.data.data[0].forPeriod === 1 ? true : false);

//         res.data.data[0].application.forEach((e) => {
//           addDiscountByPenaltys(e);
//         });

//         changeTypeDiscount(res.data.data[0].application);
//       })
//       .catch((err) => {
//         toast(<Notification type={"consultar_error"} withIcon />);
//       });
//     setLoading(false);
//     setEditando(true);
//     setCollapse(true);
//   };

//   const changeTypeDiscount = (discounts) => {
//     const finalArray = getArrayFinal(discounts);

//     setSelectsData((prevState) => ({
//       ...prevState,
//       applicationSalaryTypes: finalArray,
//     }));
//   };

//   const getArrayFinal = (discounts) => {
//     let finalArray = selectsData.applicationSalaryTypes.slice();

//     discounts.forEach((discount) => {
//       finalArray = finalArray.filter(
//         (element) =>
//           +element.keyTypeApplicationSalary !==
//           +discount.keyTypeApplicationSalary
//       );
//     });
//     return finalArray;
//   };

//   const addDiscountByPenaltys = (e) => {
//     var arrayToBack = selectsData.applicationSalaryTypes.filter(
//       (element) =>
//         element.keyTypeApplicationSalary ===
//         parseInt(e.keyTypeApplicationSalary)
//     );
//     setSalaryTypesBack((salaryTypesBack) => [
//       ...salaryTypesBack,
//       arrayToBack[0],
//     ]);
//     var startDate = new Date(e.startDate);
//     var endDate = new Date(e.endDate);
//     startDate.setDate(startDate.getDate() + 1);
//     startDate.setHours(0);
//     startDate.setMinutes(0);
//     startDate.setSeconds(0);
//     endDate.setDate(endDate.getDate() + 1);
//     endDate.setHours(0);
//     endDate.setMinutes(0);
//     endDate.setSeconds(0);
//     var finalObject = {
//       idPenaltysDays: e.idPenaltysDays,
//       descuentosAplicacionEndDate: endDate,
//       descuentosAplicacionKeyTypeApplicationSalary: e.keyTypeApplicationSalary,
//       descuentosAplicacionNoDays: parseInt(e.noDays),
//       descuentosAplicacionStartDate: startDate,
//       enabled: parseInt(e.enabled),
//     };
//     setDiscounts((oldArray) => [...oldArray, finalObject]);
//     getDatesToExcludeById(finalObject);
//   };

//   const getDatesToExcludeById = (descuentosAplicacion) => {
//     var dateRange = {
//       start: descuentosAplicacion.descuentosAplicacionStartDate,
//       end: descuentosAplicacion.descuentosAplicacionEndDate,
//     };
//     setExcludeDates((oldArray) => [...oldArray, dateRange]);
//   };

//   // ------------------- FUNCIONES PARA TRANSFORMAR FECHAS  Y NUMEROS---------------- //

//   function padTo2Digits(num) {
//     return num.toString().padStart(2, "0");
//   }

//   function formatDate(date) {
//     return [
//       date.getFullYear(),
//       padTo2Digits(date.getMonth() + 1),
//       padTo2Digits(date.getDate()),
//     ].join("-");
//   }
//   function formatDateShow(date) {
//     return [
//       padTo2Digits(date.getDate()),
//       padTo2Digits(date.getMonth() + 1),
//       date.getFullYear(),
//     ].join("/");
//   }

//   function onlyNumbers(str) {
//     return /^[0-9]+$/.test(str);
//   }

//   const getDatesToExclude = (descuentosAplicacion) => {
//     if (discounts.length > 0) {
//       discounts.forEach((e) => {
//         if (e.enabled === 1 || e.enabled === "1") {
//           var dateRange = {
//             start: e.descuentosAplicacionStartDate,
//             end: e.descuentosAplicacionEndDate,
//           };
//           setExcludeDates((oldArray) => [...oldArray, dateRange]);
//         }
//       });
//     }
//     var dateRange = {
//       start: descuentosAplicacion.descuentosAplicacionStartDate,
//       end: descuentosAplicacion.descuentosAplicacionEndDate,
//     };
//     setExcludeDates((oldArray) => [...oldArray, dateRange]);
//   };

//   // ------------------- FUNCIONES PARA LOS DESCUENTOS -------------------------------- //

//   const addDiscount = () => {
//     setErrorsDiscount(false);
//     if (
//       descuentosAplicacion.descuentosAplicacionKeyTypeApplicationSalary !==
//         "" &&
//       descuentosAplicacion.descuentosAplicacionStartDate !== "" &&
//       descuentosAplicacion.descuentosAplicacionEndDate !== "" &&
//       descuentosAplicacion.descuentosAplicacionNoDays !== ""
//     ) {
//       let finalArray = "";
//       if (
//         descuentosAplicacion.descuentosAplicacionKeyTypeApplicationSalary ===
//         "1"
//       ) {
//         finalArray = selectsData.applicationSalaryTypes.filter(
//           (element) => element.keyTypeApplicationSalary !== 1
//         );
//       }
//       if (
//         descuentosAplicacion.descuentosAplicacionKeyTypeApplicationSalary ===
//         "2"
//       ) {
//         finalArray = selectsData.applicationSalaryTypes.filter(
//           (element) => element.keyTypeApplicationSalary !== 2
//         );
//       }
//       if (
//         descuentosAplicacion.descuentosAplicacionKeyTypeApplicationSalary ===
//         "3"
//       ) {
//         finalArray = selectsData.applicationSalaryTypes.filter(
//           (element) => element.keyTypeApplicationSalary !== 3
//         );
//       }
//       var arrayToBack = selectsData.applicationSalaryTypes.filter(
//         (element) =>
//           element.keyTypeApplicationSalary ===
//           parseInt(
//             descuentosAplicacion.descuentosAplicacionKeyTypeApplicationSalary
//           )
//       );
//       setSalaryTypesBack((salaryTypesBack) => [
//         ...salaryTypesBack,
//         arrayToBack[0],
//       ]);
//       setSelectsData((prevState) => ({
//         ...prevState,
//         applicationSalaryTypes: finalArray,
//       }));
//       var flagShow = true;
//       var enabledDiscounts = 0;
//       if (discounts.length > 0) {
//         discounts.forEach((e) => {
//           if (
//             e.enabled === 1 &&
//             e.descuentosAplicacionKeyTypeApplicationSalary !== "1"
//           ) {
//             enabledDiscounts++;
//           }
//           if (
//             e.descuentosAplicacionKeyTypeApplicationSalary === "1" &&
//             e.enabled === 1
//           ) {
//             flagShow = false;
//           }
//         });
//       }
//       if (
//         (descuentosAplicacion.enabled === "1" ||
//           descuentosAplicacion.enabled === 1) &&
//         descuentosAplicacion.descuentosAplicacionKeyTypeApplicationSalary ===
//           "1"
//       ) {
//         flagShow = false;
//       } else {
//         flagShow = true;
//       }
//       if (flagShow) {
//         setShowSection(true);
//       } else if (!flagShow && enabledDiscounts > 0) {
//         setShowSection(true);
//       } else if (!flagShow) {
//         setShowSection(false);
//       }

//       setDiscounts((oldArray) => [...oldArray, descuentosAplicacion]);
//       getDatesToExclude(descuentosAplicacion);
//       setDescuentosAplicacion((prevState) => ({
//         ...prevState,
//         descuentosAplicacionKeyTypeApplicationSalary: "",
//         descuentosAplicacionStartDate: "",
//         descuentosAplicacionEndDate: "",
//         descuentosAplicacionNoDays: "",
//       }));
//       sendDiscount(descuentosAplicacion);
//     } else {
//       setErrorsDiscount(true);
//     }
//   };

//   const sendDiscount = async (descuento) => {
//     var finalApplication = [];
//     if (discounts.length > 0) {
//       discounts.forEach((e) => {
//         var startDate = formatDate(e.descuentosAplicacionStartDate);
//         var endDate = formatDate(e.descuentosAplicacionEndDate);
//         var data = {
//           idPenaltysDays: e.idPenaltysDays !== "" ? e.idPenaltysDays : "",
//           keyTypeApplicationSalary:
//             e.descuentosAplicacionKeyTypeApplicationSalary,
//           noDays: e.descuentosAplicacionNoDays,
//           startDate: startDate,
//           endDate: endDate,
//           enabled: e.enabled,
//         };
//         finalApplication.push(data);
//       });
//     }
//     if (descuento !== "table") {
//       var startDate = formatDate(descuento.descuentosAplicacionStartDate);
//       var endDate = formatDate(descuento.descuentosAplicacionEndDate);
//       var data = {
//         idPenaltysDays: "",
//         keyTypeApplicationSalary:
//           descuento.descuentosAplicacionKeyTypeApplicationSalary,
//         noDays: descuento.descuentosAplicacionNoDays,
//         startDate: startDate,
//         endDate: endDate,
//         enabled: descuento.enabled,
//       };
//       finalApplication.push(data);
//     }
//     var suma = 0;
//     var registros = 0;
//     finalApplication.forEach((e) => {
//       if (e.enabled === 1 || e.enabled === "1") {
//         suma = suma + e.noDays;
//         registros++;
//       }
//     });
//     if (suma !== parseInt(penaltyData.noDays) && registros === 3) {
//       setErrorsDiscount(true);
//     }
//     var params = {
//       action: "calcularDescuento",
//       rows: {
//         idStaff: idStaff,
//         startDate: formatDate(values.startDate),
//         endDate: formatDate(values.endDate),
//         noDays: values.noDays,
//         application: finalApplication,
//       },
//     };
//     const finalData = peticionEncript(params);
//     await axios
//       .post(
//         `${process.env.REACT_APP_API}app/facades/employees/benefitsF.php`,
//         finalData
//       )
//       .then((res) => {
//         if (res.data.status === "success") {
//           if (res.data.data === 0) {
//             setpenaltyData((prevState) => ({
//               ...prevState,
//               amountTotal: "",
//               application: finalApplication,
//               amountBiweekly: "",
//               noPays: "",
//               noPaysMade: "",
//               startPeriod: "",
//               startYear: "",
//               endPeriod: "",
//               endYear: "",
//             }));
//             setFieldValue("amountTotal", "");
//             setFieldValue("amountBiweekly", "");
//             setFieldValue("noPays", "");
//             setFieldValue("noPaysMade", "");
//             setFieldValue("startPeriod", "");
//             setFieldValue("startYear", "");
//             setFieldValue("endPeriod", "");
//             setFieldValue("endYear", "");
//             setNoActiveJob(false);
//           } else {
//             configDiscountsParams(res, finalApplication);
//           }
//         } else {
//           if (res.data.data === "") {
//             toast(
//               <Notification
//                 type={"warning"}
//                 backMessage={
//                   "No existen plazas activas con entre las fechas seleccionadas, intente con otras fechas"
//                 }
//                 withIcon
//               />
//             );
//             setShowSection(false);
//             setNoActiveJob(true);
//           }
//         }
//       })
//       .catch((err) => {
//         console.log("ERROR: " + err);
//       });
//   };

//   const configDiscountsParams = (res, finalApplication) => {
//     var startPeriod =
//       values.keyStat === objEmployHistory.keyStat ? 1 : getStartPeriod();

//     setpenaltyData((prevState) => ({
//       ...prevState,
//       amountTotal: +res.data.data < 0 ? 0.0 : res.data.data,
//       noPays: 1,
//       startPeriod: startPeriod,
//       startYear: actualDate,
//       application: finalApplication,
//     }));
//     setFieldValue("amountTotal", +res.data.data < 0 ? 0.0 : res.data.data);
//     setFieldValue("noPays", 1);
//     /* calculaFecha(); */
//     setFieldValue("startPeriod", startPeriod);
//     setFieldValue("startYear", actualDate);
//     var objeto = {
//       amountTotal: +res.data.data < 0 ? 0.0 : res.data.data,
//       noPays: 1,
//       startPeriod: startPeriod,
//       startYear: actualDate,
//       application: finalApplication,
//     };
//     setNoActiveJob(false);
//     values.keyStat === objEmployHistory.keyStat
//       ? fillNominaApplicationHistory(objeto, "fromResponse")
//       : fillNominaApplicationActualPeriod(objeto, "fromResponse");
//   };

//   const validaDescuentosDias = () => {
//     var finalApplication = [];
//     if (discounts.length > 0) {
//       discounts.forEach((e) => {
//         var startDate = formatDate(e.descuentosAplicacionStartDate);
//         var endDate = formatDate(e.descuentosAplicacionEndDate);
//         var data = {
//           idPenaltysDays: "",
//           keyTypeApplicationSalary:
//             e.descuentosAplicacionKeyTypeApplicationSalary,
//           noDays: e.descuentosAplicacionNoDays,
//           startDate: startDate,
//           endDate: endDate,
//           enabled: e.enabled,
//         };
//         finalApplication.push(data);
//       });
//     }
//     var suma = 0;
//     finalApplication.forEach((e) => {
//       if (e.enabled === 1 || e.enabled === "1") {
//         suma = suma + e.noDays;
//       }
//     });
//     if (suma !== parseInt(penaltyData.noDays)) {
//       setErrorsDiscount(true);
//       return true;
//     } else {
//       return false;
//     }
//   };

//   const getStartPeriod = () => {
//     var inicio = new Date(values.startDate.getFullYear(), 0, 1);
//     var actual = new Date(values.startDate.getTime());
//     var dias = Math.ceil((actual - inicio + 1) / 86400000);
//     return Math.floor(dias / 15) + 1;
//   };

//   // ------------------- FUNCIONES PARA LAS APLICACIONES ------------------- //

//   const fillNominaApplicationActualPeriod = (valor, name) => {
//     /* debugger */
//     var periodoFinal = "";
//     var anioFinal = "";
//     var auxPeriodo = "";
//     if (!editando) {
//       if (name === "startYear") {
//         periodoFinal =
//           parseInt(values.startPeriod) + parseInt(values.noPays) - 1;
//         anioFinal = parseInt(valor.getFullYear());
//         auxPeriodo = periodoFinal / 24;
//         auxPeriodo = Math.floor(auxPeriodo);
//         if (periodoFinal % 24 > 0) {
//           periodoFinal = periodoFinal - 24 * auxPeriodo;
//           anioFinal = parseInt(valor.getFullYear()) + auxPeriodo;
//         } else {
//           periodoFinal = 24;
//           anioFinal = parseInt(valor.getFullYear()) + auxPeriodo - 1;
//         }
//         setpenaltyData((prevState) => ({
//           ...prevState,
//           endPeriod: periodoFinal,
//           endYear: anioFinal,
//           amountBiweekly:
//             parseInt(values.amountTotal) / parseInt(values.noPays),
//         }));
//         setFieldValue("endPeriod", periodoFinal);
//         setFieldValue("endYear", anioFinal);
//         setFieldValue(
//           "amountBiweekly",
//           parseInt(values.amountTotal) / parseInt(values.noPays)
//         );
//       } else if (name === "startPeriod") {
//         periodoFinal = parseInt(valor) + parseInt(values.noPays) - 1;
//         anioFinal = parseInt(values.startYear.getFullYear());
//         auxPeriodo = periodoFinal / 24;
//         auxPeriodo = Math.floor(auxPeriodo);
//         if (periodoFinal % 24 > 0) {
//           //
//           periodoFinal = periodoFinal - 24 * auxPeriodo;
//           //
//           anioFinal = parseInt(values.startYear.getFullYear()) + auxPeriodo;
//         } else {
//           periodoFinal = 24;
//           anioFinal = parseInt(values.startYear.getFullYear()) + auxPeriodo - 1;
//         }
//         setpenaltyData((prevState) => ({
//           ...prevState,
//           endPeriod: periodoFinal,
//           endYear: anioFinal,
//           amountBiweekly:
//             parseInt(values.amountTotal) / parseInt(values.noPays),
//         }));
//         setFieldValue("endPeriod", periodoFinal);
//         setFieldValue("endYear", anioFinal);
//         setFieldValue(
//           "amountBiweekly",
//           parseInt(values.amountTotal) / parseInt(values.noPays)
//         );
//       } else if (name === "noPays") {
//         periodoFinal = parseInt(values.startPeriod) + parseInt(valor) - 1;
//         anioFinal = parseInt(values.startYear.getFullYear());
//         auxPeriodo = periodoFinal / 24;
//         auxPeriodo = Math.floor(auxPeriodo);
//         if (periodoFinal % 24 > 0) {
//           //
//           periodoFinal = periodoFinal - 24 * auxPeriodo;
//           //
//           anioFinal = parseInt(values.startYear.getFullYear()) + auxPeriodo;
//         } else {
//           periodoFinal = 24;
//           anioFinal = parseInt(values.startYear.getFullYear()) + auxPeriodo - 1;
//         }
//         setpenaltyData((prevState) => ({
//           ...prevState,
//           endPeriod: periodoFinal,
//           endYear: anioFinal,
//           amountBiweekly: isNaN(parseInt(values.amountTotal) / parseInt(valor))
//             ? 0
//             : parseInt(values.amountTotal) / parseInt(valor),
//         }));
//         setFieldValue("endPeriod", periodoFinal);
//         setFieldValue("endYear", anioFinal);
//         setFieldValue(
//           "amountBiweekly",
//           isNaN(parseInt(values.amountTotal) / parseInt(valor))
//             ? 0
//             : parseInt(values.amountTotal) / parseInt(valor)
//         );
//       } else if (name === "amountTotal") {
//         periodoFinal =
//           parseInt(values.startPeriod) + parseInt(values.noPays) - 1;
//         anioFinal = parseInt(values.startYear.getFullYear());
//         auxPeriodo = periodoFinal / 24;
//         auxPeriodo = Math.floor(auxPeriodo);
//         if (periodoFinal % 24 > 0) {
//           //
//           periodoFinal = periodoFinal - 24 * auxPeriodo;
//           //
//           anioFinal = parseInt(values.startYear.getFullYear()) + auxPeriodo;
//         } else {
//           periodoFinal = 24;
//           anioFinal = parseInt(values.startYear.getFullYear()) + auxPeriodo - 1;
//         }
//         setpenaltyData((prevState) => ({
//           ...prevState,
//           endPeriod: periodoFinal,
//           endYear: anioFinal,
//           amountBiweekly: parseInt(valor) / parseInt(values.noPays),
//         }));
//         setFieldValue("endPeriod", periodoFinal);
//         setFieldValue("endYear", anioFinal);
//         setFieldValue(
//           "amountBiweekly",
//           parseInt(valor) / parseInt(values.noPays)
//         );
//       } else if (name === "fromResponse") {
//         periodoFinal =
//           parseInt(valor.startPeriod) + parseInt(valor.noPays) - 1;

//         anioFinal = parseInt(valor.startYear.getFullYear());
//         auxPeriodo = periodoFinal / 24;
//         auxPeriodo = Math.floor(auxPeriodo);
//         if (periodoFinal % 24 > 0) {
//           //
//           periodoFinal = periodoFinal - 24 * auxPeriodo;
//           //

//           anioFinal = parseInt(valor.startYear.getFullYear()) + auxPeriodo;
//         } else {
//           periodoFinal = 24;

//           anioFinal = parseInt(valor.startYear.getFullYear()) + auxPeriodo - 1;
//         }
//         setpenaltyData((prevState) => ({
//           ...prevState,
//           endPeriod: periodoFinal,
//           endYear: anioFinal,
//           amountBiweekly: parseInt(valor.amountTotal) / parseInt(valor.noPays),
//         }));
//         setFieldValue("endPeriod", periodoFinal);
//         setFieldValue("endYear", anioFinal);
//         setFieldValue(
//           "amountBiweekly",
//           parseInt(valor.amountTotal) / parseInt(valor.noPays)
//         );
//       }
//     }
//   };

//   const fillNominaApplicationHistory = (valor, name) => {
//     var periodoFinal = "";
//     var anioFinal = "";
//     var auxPeriodo = "";
//     if (!editando) {
//       if (name === "startYear") {
//         periodoFinal =
//           parseInt(values.startPeriod) + parseInt(values.noPays) - 1;
//         anioFinal = parseInt(valor.getFullYear());
//         auxPeriodo = periodoFinal / 24;
//         auxPeriodo = Math.floor(auxPeriodo);
//         if (periodoFinal % 24 > 0) {
//           periodoFinal = periodoFinal - 24 * auxPeriodo;
//           anioFinal = parseInt(valor.getFullYear()) + auxPeriodo;
//         } else {
//           periodoFinal = 24;
//           anioFinal = parseInt(valor.getFullYear()) + auxPeriodo - 1;
//         }
//         setpenaltyData((prevState) => ({
//           ...prevState,
//           endPeriod: periodoFinal,
//           endYear: anioFinal,
//           amountBiweekly: isNaN(parseInt(values.amountTotal) / parseInt(valor))
//             ? 0
//             : parseInt(values.amountTotal) / parseInt(valor),
//         }));
//         setFieldValue("endPeriod", periodoFinal);
//         setFieldValue("endYear", anioFinal);
//         setFieldValue(
//           "amountBiweekly",
//           isNaN(parseInt(values.amountTotal) / parseInt(valor))
//             ? 0
//             : parseInt(values.amountTotal) / parseInt(valor)
//         );
//       } else if (name === "startPeriod") {
//         periodoFinal = parseInt(valor) + parseInt(values.noPays) - 1;
//         anioFinal = parseInt(values.startYear.getFullYear());
//         auxPeriodo = periodoFinal / 24;
//         auxPeriodo = Math.floor(auxPeriodo);
//         if (periodoFinal % 24 > 0) {
//           //
//           periodoFinal = periodoFinal - 24 * auxPeriodo;
//           //
//           anioFinal = parseInt(values.startYear.getFullYear()) + auxPeriodo;
//         } else {
//           periodoFinal = 24;
//           anioFinal = parseInt(values.startYear.getFullYear()) + auxPeriodo - 1;
//         }
//         setpenaltyData((prevState) => ({
//           ...prevState,
//           endPeriod: periodoFinal,
//           endYear: anioFinal,
//           amountBiweekly: isNaN(parseInt(values.amountTotal) / parseInt(valor))
//             ? 0
//             : parseInt(values.amountTotal) / parseInt(valor),
//         }));
//         setFieldValue("endPeriod", periodoFinal);
//         setFieldValue("endYear", anioFinal);
//         setFieldValue(
//           "amountBiweekly",
//           isNaN(parseInt(values.amountTotal) / parseInt(valor))
//             ? 0
//             : parseInt(values.amountTotal) / parseInt(valor)
//         );
//       } else if (name === "noPays") {
//         periodoFinal = parseInt(values.startPeriod) + parseInt(valor) - 1;
//         anioFinal = parseInt(values.startYear.getFullYear());
//         auxPeriodo = periodoFinal / 24;
//         auxPeriodo = Math.floor(auxPeriodo);
//         if (periodoFinal % 24 > 0) {
//           //
//           periodoFinal = periodoFinal - 24 * auxPeriodo;
//           //
//           anioFinal = parseInt(values.startYear.getFullYear()) + auxPeriodo;
//         } else {
//           periodoFinal = 24;
//           anioFinal = parseInt(values.startYear.getFullYear()) + auxPeriodo - 1;
//         }
//         setpenaltyData((prevState) => ({
//           ...prevState,
//           endPeriod: periodoFinal,
//           endYear: anioFinal,
//           amountBiweekly: isNaN(parseInt(values.amountTotal) / parseInt(valor))
//             ? 0
//             : parseInt(values.amountTotal) / parseInt(valor),
//         }));
//         setFieldValue("endPeriod", periodoFinal);
//         setFieldValue("endYear", anioFinal);
//         setFieldValue(
//           "amountBiweekly",
//           isNaN(parseInt(values.amountTotal) / parseInt(valor))
//             ? 0
//             : parseInt(values.amountTotal) / parseInt(valor)
//         );
//       } else if (name === "amountTotal") {
//         periodoFinal =
//           parseInt(values.startPeriod) + parseInt(values.noPays) - 1;
//         anioFinal = parseInt(values.startYear.getFullYear());
//         auxPeriodo = periodoFinal / 24;
//         auxPeriodo = Math.floor(auxPeriodo);
//         if (periodoFinal % 24 > 0) {
//           //
//           periodoFinal = periodoFinal - 24 * auxPeriodo;
//           //
//           anioFinal = parseInt(values.startYear.getFullYear()) + auxPeriodo;
//         } else {
//           periodoFinal = 24;
//           anioFinal = parseInt(values.startYear.getFullYear()) + auxPeriodo - 1;
//         }
//         setpenaltyData((prevState) => ({
//           ...prevState,
//           endPeriod: periodoFinal,
//           endYear: anioFinal,
//           amountBiweekly: parseInt(valor) / parseInt(values.noPays),
//         }));
//         setFieldValue("endPeriod", periodoFinal);
//         setFieldValue("endYear", anioFinal);
//         setFieldValue(
//           "amountBiweekly",
//           parseInt(valor) / parseInt(values.noPays)
//         );
//       } else if (name === "fromResponse") {
//         periodoFinal =
//           parseInt(valor.startPeriod) + parseInt(valor.noPays) - 1;

//         anioFinal = parseInt(valor.startYear.getFullYear());
//         auxPeriodo = periodoFinal / 24;
//         auxPeriodo = Math.floor(auxPeriodo);
//         if (periodoFinal % 24 > 0) {
//           //
//           periodoFinal = periodoFinal - 24 * auxPeriodo;
//           //

//           anioFinal = parseInt(valor.startYear.getFullYear()) + auxPeriodo;
//         } else {
//           periodoFinal = 24;

//           anioFinal = parseInt(valor.startYear.getFullYear()) + auxPeriodo - 1;
//         }
//         setpenaltyData((prevState) => ({
//           ...prevState,
//           endPeriod: periodoFinal,
//           endYear: anioFinal,
//           amountBiweekly: parseInt(valor.amountTotal) / parseInt(valor.noPays),
//         }));
//         setFieldValue("endPeriod", periodoFinal);
//         setFieldValue("endYear", anioFinal);
//         setFieldValue(
//           "amountBiweekly",
//           parseInt(valor.amountTotal) / parseInt(valor.noPays)
//         );
//       }
//     }
//   };

//   const validaAplicacionCampos = () => {
//     if (penaltyData.amountTotal !== "") {
//       if (values.idPenalty !== "") {
//         // QUIERE DECIR QUE ESTA EDITANDO
//         if (
//           values.amountTotal === "0.00" ||
//           values.amountTotal === "0" ||
//           values.amountTotal === 0 ||
//           values.amountTotal === 0.0
//         ) {
//           // QUIERE DECIR QUE LO DEJO IGUAL
//           return false;
//         }
//       } else {
//         //QUIERE DECIR QUE TIENE QUE MOSTRAR ESTE PEDO
//         if (
//           values.noPays === "" ||
//           values.amountBiweekly === "" ||
//           values.startPeriod === "" ||
//           values.startYear === "" ||
//           values.endPeriod === "" ||
//           values.endYear === ""
//         ) {
//           return true;
//         } else {
//           return false;
//         }
//       }
//     } else {
//       /* setpenaltyData((prevState) => ({
//         ...prevState,
//         amountTotal: 0,
//       }));
//       setFieldValue("amountTotal", 0); */
//       return "Agrega 0";
//     }
//   };

//   // ------------------- FUNCIONES PARA EL CAMBIO DE INPUTS ------------------- //

//   const onChangeDate = (e) => {
//     if (e.name === "startDate" && editando) {
//       cleanChangeDates();
//     }
//     if (e.name === "startDate" || e.name === "endDate") {
//       if (values.startDate !== "" && e.name === "endDate") {
//         let noday1 = new Date(e.value);
//         let noday2 = new Date(values.startDate);
//         let difference = Math.abs(noday2 - noday1);
//         difference = Math.floor(difference / (1000 * 3600 * 24) + 1);
//         setpenaltyData((prevState) => ({
//           ...prevState,
//           noDays: difference,
//         }));
//         setFieldValue("noDays", difference);
//       } else if (e.name === "startDate" && values.endDate !== "") {
//         let noday1 = new Date(e.value);
//         let noday2 = new Date(values.endDate);
//         if (noday2 - noday1 < 0) {
//           setFieldValue("endDate", "");
//           setFieldValue("noDays", "");
//           setDiscounts([]);
//           setShowSection(false);
//           setNoActiveJob(false);
//         } else {
//           let difference = Math.abs(noday2 - noday1);
//           difference = Math.floor(difference / (1000 * 3600 * 24) + 1);
//           setpenaltyData((prevState) => ({
//             ...prevState,
//             noDays: difference,
//           }));
//           setFieldValue("noDays", difference);
//         }
//       }
//       setFieldValue(e.name, e.value);
//       setpenaltyData((prevState) => ({
//         ...prevState,
//         [e.name]: e.value,
//       }));
//     } else {
//       if (
//         (descuentosAplicacion.descuentosAplicacionStartDate !== "" &&
//           e.name === "descuentosAplicacionEndDate") ||
//         (descuentosAplicacion.descuentosAplicacionEndDate !== "" &&
//           e.name === "descuentosAplicacionStartDate")
//       ) {
//         var day1 = new Date(e.value);
//         var day2 = new Date(descuentosAplicacion.descuentosAplicacionStartDate);
//         var difference = Math.abs(day2 - day1);
//         difference = Math.floor(difference / (1000 * 3600 * 24) + 1);
//         setDescuentosAplicacion((prevState) => ({
//           ...prevState,
//           [e.name]: e.value,
//           descuentosAplicacionNoDays: difference,
//         }));
//       } else {
//         setDescuentosAplicacion((prevState) => ({
//           ...prevState,
//           [e.name]: e.value,
//         }));
//       }
//     }
//   };

//   const onChange = (e) => {
//     var { name, value } = e.target;
//     if (e.name === "startYear") {
//       name = e.target.name;
//       value = new Date(e.target.value, 0, 1).getFullYear();
//     }

//     if (name === "descuentosAplicacionKeyTypeApplicationSalary") {
//       setDescuentosAplicacion((prevState) => ({
//         ...prevState,
//         [name]: value,
//       }));
//       setFieldValue(name, value);
//     } else if (name === "amountTotal") {
//       changeAmountTotal(name, value);
//     } else if (name === "noPays") {
//       changeNoPays(name, value);
//     } else if (name === "startPeriod") {
//       changeStartPeriod(name, value);
//     } else if (name === "startYear") {
//       changeStartYear(name, value);
//     } else if (name === "keyStat") {
//       changeKeyStat(name, value);
//     } else {
//       setFieldValue([name], value);
//       setpenaltyData((prevState) => ({
//         ...prevState,
//         [name]: value,
//       }));
//     }
//   };

//   const onChangeFile = (e) => {
//     var { files } = e.target;
//     if (files.length === 0) {
//       setFieldValue("comprobante", "");
//       setpenaltyData((prevState) => ({
//         ...prevState,
//         comprobante: "",
//       }));
//     } else {
//       setFieldValue("comprobante", files[0]);
//       setpenaltyData((prevState) => ({
//         ...prevState,
//         comprobante: files[0],
//       }));
//     }
//   };

//   const getAuxiliarPeriod = () => {
//     var auxStartPeriod = getStartPeriod();

//     if (values.keyStat === objEmployHistory.keyStat) {
//       auxStartPeriod = 1;
//     }

//     return auxStartPeriod;
//   };

//   const setOnChangeError = (msg) => {
//     var period = getAuxiliarPeriod();

//     setFieldValue((prevState) => ({
//       ...prevState,
//       noPays: "",
//       amountBiweekly: "",
//       startPeriod: period,
//       startYear: actualDate,
//       endYear: "",
//       endPeriod: "",
//     }));

//     setFieldValue("noPays", "");
//     setFieldValue("amountBiweekly", "");
//     setFieldValue("startPeriod", period);
//     setFieldValue("startYear", actualDate);
//     setFieldValue("endYear", "");
//     setFieldValue("endPeriod", "");
//     setErrorApplications(msg ? msg : "La cantidad total debe ser mayor a 0");
//   };

//   const changeAmountTotal = (name, value) => {
//     var auxStartPeriod = getAuxiliarPeriod();

//     setFieldValue([name], value);
//     setpenaltyData((prevState) => ({
//       ...prevState,
//       [name]: value,
//     }));

//     if (!editando) {
//       if (parseInt(value) >= 0) {
//         setpenaltyData((prevState) => ({
//           ...prevState,
//           [name]: value,
//           noPays: 1,
//           startPeriod: auxStartPeriod,
//           startYear: actualDate,
//         }));
//         setFieldValue("noPays", 1);
//         setFieldValue("startPeriod", auxStartPeriod);
//         setFieldValue("startYear", actualDate);
//         /* fillNominaApplication(value, name); */
//         values.keyStat === objEmployHistory.keyStat
//           ? fillNominaApplicationHistory(value, name)
//           : fillNominaApplicationActualPeriod(value, name);
//         if (errorApplications === "La cantidad total debe ser mayor a 0") {
//           setErrorApplications(false);
//         }
//       } else {
//         setOnChangeError();
//       }
//     }
//   };

//   const changeNoPays = (name, value) => {
//     var auxStartPeriod = getAuxiliarPeriod();

//     if (parseInt(value) > 0 && onlyNumbers(value)) {
//       setpenaltyData((prevState) => ({
//         ...prevState,
//         [name]: value,
//       }));
//       /* debugger */
//       if (!editando) {
//         if (
//           parseInt(value) > 0 &&
//           onlyNumbers(value) &&
//           values.amountTotal > 0
//         ) {
//           setFieldValue(
//             "amountBiweekly",
//             isNaN(values.amountTotal / value) ? 0 : values.amountTotal / value
//           );
//           setpenaltyData((prevState) => ({
//             ...prevState,
//             amountBiweekly: values.amountTotal / value,
//             startPeriod: auxStartPeriod,
//             startYear: actualDate,
//             endPeriod: auxStartPeriod + +value,
//           }));

//           setFieldValue("startPeriod", auxStartPeriod);
//           setFieldValue("startYear", actualDate);

//           setFieldValue("endPeriod", auxStartPeriod + +value);
//         } else {
//           /* setFieldValue("amountBiweekly", "");
//           setpenaltyData((prevState) => ({
//             ...prevState,
//             amountBiweekly: "",
//           })); */
//         }
//         if (
//           values.startPeriod !== "" &&
//           values.startYear !== "" &&
//           values.startYear !== null &&
//           values.startYear !== undefined &&
//           values.amountTotal !== ""
//         ) {
//           values.keyStat === objEmployHistory.keyStat
//             ? fillNominaApplicationHistory(value, name)
//             : fillNominaApplicationActualPeriod(value, name);
//         }
//       }

//       if (
//         errorApplications ===
//           "El numero de pagos debe ser entero y mayor a 0" ||
//         errorApplications === "La cantidad total debe ser mayor a 0"
//       ) {
//         setErrorApplications(false);
//       }
//     } else {
//       !editando
//         ? setOnChangeError("El numero de pagos debe ser entero y mayor a 0")
//         : setErrorApplications("La cantidad total debe ser mayor a 0");
//     }

//     setFieldValue([name], value);
//   };

//   const changeStartPeriod = (name, value) => {
//     if (parseInt(value) > 0 && onlyNumbers(value) && parseInt(value) < 25) {
//       setpenaltyData((prevState) => ({
//         ...prevState,
//         [name]: value,
//       }));
//       if (
//         errorApplications ===
//         "El periodo de inicio debe ser un entero mayor a 0 y menor a 24"
//       ) {
//         setErrorApplications(false);
//       }
//       if (values.startYear !== "" && values.amountTotal !== "") {
//         values.keyStat === objEmployHistory.keyStat
//           ? fillNominaApplicationHistory(value, name)
//           : fillNominaApplicationActualPeriod(value, name);
//       }
//     } else {
//       setFieldValue((prevState) => ({
//         ...prevState,
//         startYear: "",
//         endYear: "",
//       }));
//       setFieldValue("startYear", "");
//       setFieldValue("endYear", "");
//       setErrorApplications(
//         "El periodo de inicio debe ser un entero mayor a 0 y menor a 24"
//       );
//     }
//     setFieldValue([name], value);
//   };

//   const changeStartYear = (name, value) => {
//     if (parseInt(value) > 0 && onlyNumbers(value)) {
//       setpenaltyData((prevState) => ({
//         ...prevState,
//         [name]: value,
//       }));
//       if (parseInt(value) > 2019) {
//         values.keyStat === objEmployHistory.keyStat
//           ? fillNominaApplicationHistory(value, name)
//           : fillNominaApplicationActualPeriod(value, name);
//         if (
//           errorApplications ===
//           "El año de inicio debe ser mayor a 2019 y con el formato YYYY"
//         ) {
//           setErrorApplications(false);
//         }
//       } else {
//         setpenaltyData((prevState) => ({
//           ...prevState,
//           endPeriod: "",
//           endYear: "",
//           amountBiweekly: "",
//         }));
//         setFieldValue("endPeriod", "");
//         setFieldValue("endYear", "");
//         setFieldValue("amountBiweekly", "");
//         setErrorApplications(
//           "El año de inicio debe ser mayor a 2019 y con el formato YYYY"
//         );
//       }
//     }
//     setFieldValue([name], value);
//   };

//   const changeKeyStat = (name, value) => {
//     /* console.log("onChangeStat"); */

//     cleanChangeSelect();

//     setpenaltyData((prevState) => ({
//       ...prevState,
//       startDate: "",
//       endDate: "",
//       keyStat: value,
//       noDays: 0,
//     }));
//     setFieldValue("startDate", "");
//     setFieldValue("endDate", "");
//     setFieldValue("noDays", 0);
//     setFieldValue([name], value);
//   };

//   // ------------------- FUNCIONES PARA EL ENVIO DE DATOS ------------------- //

//   const enviaDatos = () => {
//     var flag = validaDescuentosDias();
//     if (!flag) {
//       //QUIERE DECIR QUE CUMPLIO CON EL NUMERO DE DIAS
//       flag = validaAplicacionCampos();
//       if (flag !== true) {
//         if (flag === "Agrega 0") {
//           setFinalData((prevState) => ({
//             ...prevState,
//             idStaff: idStaff,
//             idPenalty: penaltyData.idPenalty,
//             idUnder: values.idUnder,
//             keyTypePenalty: values.keyTypePenalty,
//             keyStat: values.keyStat,
//             policy: values.policy,
//             startDate: formatDate(values.startDate),
//             endDate: formatDate(values.endDate),
//             noDays: values.noDays,
//             application: penaltyData.application,
//             notes: values.notes,
//             forPeriod: forPeriod ? 1 : 0,

//             amountTotal: 0,
//             amountBiweekly: penaltyData.amountBiweekly,
//             noPays: values.noPays,
//             noPaysMade: values.noPaysMade,
//             startPeriod: values.startPeriod,
//             startYear: values.startYear ? values.startYear.getFullYear() : "",
//             endPeriod: values.endPeriod,
//             endYear: values.endYear,
//           }));

//           /* setFinalData((prevState) => ({
//               ...prevState,
//               idStaff: idStaff,
//               idPenalty: penaltyData.idPenalty,
//               idUnder: values.idUnder,
//               keyTypePenalty: values.keyTypePenalty,
//               keyStat: values.keyStat,
//               policy: values.policy,
//               startDate: formatDate(values.startDate),
//               endDate: formatDate(values.endDate),
//               noDays: values.noDays,
//               application: penaltyData.application,
//               notes: values.notes,
//               forPeriod: values.forPeriod ? 1 : 0,

//                             amountTotal: 0,
//               amountBiweekly: penaltyData.amountBiweekly,
//               noPays: values.noPays,
//               noPaysMade: values.noPaysMade,
//               startPeriod: values.startPeriod,
//               startYear: values.startYear ? values.startYear.getFullYear() : "",
//               endPeriod: values.endPeriod,
//               endYear: values.endYear,
//             })); */
//         } else {
//           if (forPeriod) {
//             setFinalData((prevState) => ({
//               ...prevState,
//               idStaff: idStaff,
//               idPenalty: penaltyData.idPenalty,
//               keyTypePenalty: values.keyTypePenalty,
//               keyStat: values.keyStat,
//               idUnder: values.idUnder,
//               policy: values.policy,
//               startDate: formatDate(values.startDate),
//               endDate: formatDate(values.endDate),
//               noDays: values.noDays,
//               application: penaltyData.application,
//               notes: values.notes,
//               forPeriod: forPeriod ? 1 : 0,

//               amountTotal: values.amountTotal,
//               amountBiweekly: penaltyData.amountBiweekly,
//               noPays: values.noPays,
//               noPaysMade: values.noPaysMade,
//               startPeriod: values.startPeriod,
//               startYear: values.startYear ? values.startYear.getFullYear() : "",
//               endPeriod: values.endPeriod,
//               endYear: values.endYear,
//             }));
//           } else {
//             setFinalData((prevState) => ({
//               ...prevState,
//               idStaff: idStaff,
//               idPenalty: penaltyData.idPenalty,
//               keyTypePenalty: values.keyTypePenalty,
//               keyStat: values.keyStat,
//               idUnder: values.idUnder,
//               policy: values.policy,
//               startDate: formatDate(values.startDate),
//               endDate: formatDate(values.endDate),
//               noDays: values.noDays,
//               application: penaltyData.application,
//               notes: values.notes,
//               forPeriod: forPeriod ? 1 : 0,

//               /* amountTotal: values.amountTotal,
//               amountBiweekly: penaltyData.amountBiweekly,
//               noPays: values.noPays,
//               noPaysMade: values.noPaysMade,
//               startPeriod: values.startPeriod,
//               startYear: values.startYear ? values.startYear.getFullYear() : "",
//               endPeriod: values.endPeriod,
//               endYear: values.endYear, */
//             }));
//           }
//         }

//         setModal(true);
//       } else {
//         setErrorApplications("Llene todos los campos marcados con (*)");
//       }
//     } else {
//       setErrorsDiscount(true);
//     }
//   };

//   const sendFormInformation = async () => {
//     setLoading(true);
//     const data = {
//       action: "savePenaltys",
//       rows: finalData,
//     };
//     const finalDataEncrypted = peticionEncript(data);
//     await axios
//       .post(
//         `${process.env.REACT_APP_API}app/facades/employees/benefitsF.php`,
//         finalDataEncrypted
//       )
//       .then(async (res) => {
//         if (res.status === 200 && res.data.status === "success") {
//           if (values.comprobante) {
//             await uploadFile(
//               res.data.data[0].idPenalty,
//               "saveFileReplace",
//               48,
//               values.comprobante,
//               false
//             );
//           }
//           toast(<Notification type={"agrega_exito"} withIcon />);
//           setParametros({ ...parametros });

//           cleanForm();
//         } else {
//           toast(
//             <Notification
//               type={"modifica_error"}
//               backMessage={res.data.message}
//               withIcon
//             />
//           );
//         }
//       })
//       .catch((err) => {
//         toast(<Notification type={"consultar_error"} withIcon />);
//       });
//   };

//   const cleanForm = () => {
//     setShowSection(false);
//     setNoActiveJob(false);
//     setEditHasFile(false);
//     setDiscounts([]);
//     setExcludeDates([]);
//     setDescuentosAplicacion((prevState) => ({
//       ...prevState,
//       idPenaltysDays: "",
//       descuentosAplicacionKeyTypeApplicationSalary: "",
//       descuentosAplicacionStartDate: "",
//       descuentosAplicacionEndDate: "",
//       descuentosAplicacionNoDays: "",
//       enabled: 1,
//     }));
//     setSelectsData((prevState) => ({
//       ...prevState,
//       applicationSalaryTypes: "",
//     }));
//     setSalaryTypesBack([]);
//     setpenaltyData((prevState) => ({
//       ...prevState,
//       idPenalty: "",
//       keyTypePenalty: "",
//       keyStat: "",
//       idUnder: "",
//       policy: "",
//       startDate: "",
//       endDate: "",
//       noDays: 0,
//       application: "",
//       amountTotal: "",
//       amountBiweekly: "",
//       noPays: "",
//       noPaysMade: "",
//       notes: "",
//       startPeriod: "",
//       startYear: "",
//       endPeriod: "",
//       endYear: "",
//       comprobante: "",
//     }));
//     setFieldValue("idPenalty", "");
//     setFieldValue("keyTypePenalty", "");
//     setFieldValue("keyStat", "");
//     setFieldValue("policy", "");
//     setFieldValue("idUnder", "");
//     setFieldValue("clinicalDiagnosis", "");
//     setFieldValue("clinicalSpecialty", "");
//     setFieldValue("medicalUnit", "");
//     setFieldValue("doctor", "");
//     setFieldValue("endDate", "");
//     setFieldValue("noDays", 0);
//     setFieldValue("application", "");
//     setFieldValue("amountTotal", "");
//     setFieldValue("amountBiweekly", "");
//     setFieldValue("noPays", "");
//     setFieldValue("noPaysMade", "");
//     setFieldValue("startPeriod", "");
//     setFieldValue("startYear", "");
//     setFieldValue("endPeriod", "");
//     setFieldValue("endYear", "");
//     setEditando(false);
//     setCollapse(false);
//     refInpFile.current.value = "";
//     setFieldValue("comprobante", "");
//   };

//   const cleanChangeDates = () => {
//     setShowSection(false);
//     setNoActiveJob(false);
//     setDiscounts([]);
//     setExcludeDates([]);
//     setDescuentosAplicacion((prevState) => ({
//       ...prevState,
//       idLeaveDay: "",
//       descuentosAplicacionKeyTypeApplicationSalary: "",
//       descuentosAplicacionStartDate: "",
//       descuentosAplicacionEndDate: "",
//       descuentosAplicacionNoDays: "",
//       enabled: 1,
//     }));
//     setSelectsData((prevState) => ({
//       ...prevState,
//       applicationSalaryTypes: "",
//     }));
//     setSalaryTypesBack([]);
//     setpenaltyData((prevState) => ({
//       ...prevState,
//       /* idLeave: "",
//       keyTypeLeave: "",
//       keyStat: 0,
//       policy: "",
//       startDate: "", */
//       permissionDate: "",
//       endDate: "",
//       noDays: 0,
//       application: "",
//       amountTotal: "",
//       amountBiweekly: "",
//       noPays: "",
//       noPaysMade: "",
//       notes: "",
//       startPeriod: "",
//       startYear: "",
//       endPeriod: "",
//       endYear: "",
//     }));
//     /* setFieldValue("idLeave", ""); */
//     /* setFieldValue("keyTypeLeave", "");
//     setFieldValue("keyStat", "");
//     setFieldValue("policy", ""); */
//     setFieldValue("permissionDate", "");
//     setFieldValue("endDate", "");
//     setFieldValue("noDays", 0);
//     setFieldValue("application", "");
//     setFieldValue("amountTotal", "");
//     setFieldValue("amountBiweekly", "");
//     setFieldValue("noPays", "");
//     setFieldValue("noPaysMade", "");
//     setFieldValue("startPeriod", "");
//     setFieldValue("startYear", "");
//     setFieldValue("endPeriod", "");
//     setFieldValue("endYear", "");
//     setEditando(false);
//     /* setCollapse(false); */
//   };

//   const cleanChangeSelect = () => {
//     setShowSection(false);
//     setNoActiveJob(false);
//     setDiscounts([]);
//     setExcludeDates([]);
//     setDescuentosAplicacion((prevState) => ({
//       ...prevState,
//       idLeaveDay: "",
//       descuentosAplicacionKeyTypeApplicationSalary: "",
//       descuentosAplicacionStartDate: "",
//       descuentosAplicacionEndDate: "",
//       descuentosAplicacionNoDays: "",
//       enabled: 1,
//     }));
//     /* setSelectsData((prevState) => ({
//       ...prevState,
//       applicationSalaryTypes: "",
//     })); */
//     setSalaryTypesBack([]);
//     setpenaltyData((prevState) => ({
//       ...prevState,
//       /* idLeave: "",
//       keyTypeLeave: "",
//       keyStat: 0, */
//       /* policy: "", */
//       permissionDate: "",
//       startDate: "",
//       endDate: "",
//       noDays: 0,
//       application: "",
//       amountTotal: "",
//       amountBiweekly: "",
//       noPays: "",
//       noPaysMade: "",
//       notes: "",
//       startPeriod: "",
//       startYear: "",
//       endPeriod: "",
//       endYear: "",
//     }));
//     /* setFieldValue("idLeave", "");
//     setFieldValue("keyTypeLeave", "");
//     setFieldValue("keyStat", ""); */
//     setFieldValue("policy", "");
//     setFieldValue("permissionDate", "");
//     setFieldValue("endDate", "");
//     setFieldValue("noDays", 0);
//     setFieldValue("application", "");
//     setFieldValue("amountTotal", "");
//     setFieldValue("amountBiweekly", "");
//     setFieldValue("noPays", "");
//     setFieldValue("noPaysMade", "");
//     setFieldValue("startPeriod", "");
//     setFieldValue("startYear", "");
//     setFieldValue("endPeriod", "");
//     setFieldValue("endYear", "");
//     /* setEditando(false);
//     setCollapse(false); */
//   };

//   // || ------------------- FIN DE LAS FUNCIONES ---------------- || //

//   // -------------------------------------------------------------------- //

//   // || ------------------- INICIO DE LOS USEEFFECT ---------------- || //

//   // ------------------- LLENADO DE LOS SELECTS ------------------- //

//   useEffect(() => {
//     if (
//       selectsData.applicationSalaryTypes.length === 0 ||
//       selectsData.applicationSalaryTypes === ""
//     ) {
//       getPenaltysType(setSelectsData, true, "penaltyTypes");
//       getUnders(setSelectsData, true, "underTypes", "in (5,6)");
//     }
//   }, [selectsData.applicationSalaryTypes]);

//   useEffect(() => {
//     getKindToStatus(
//       setSelectsData,
//       true,
//       "statusTypes",
//       "PENALTYS",
//       setObjEmployHistory
//     );
//     getActualPeriodDate();
//   }, []);

//   const getActualPeriodDate = () => {
//     var primerDiaDeLaQuincena, ultimoDiaQuincena;

//     var diaDelMes = actualDate.getDate();
//     if (diaDelMes <= 15) {
//       primerDiaDeLaQuincena = new Date(
//         actualDate.getFullYear(),
//         actualDate.getMonth(),
//         1
//       );
//       ultimoDiaQuincena = new Date(
//         actualDate.getFullYear(),
//         actualDate.getMonth(),
//         15
//       );
//     } else {
//       primerDiaDeLaQuincena = new Date(
//         actualDate.getFullYear(),
//         actualDate.getMonth(),
//         16
//       );
//       ultimoDiaQuincena = new Date(
//         actualDate.getFullYear(),
//         actualDate.getMonth(),
//         0
//       );
//     }

//     var inicio = new Date(actualDate.getFullYear(), 0, 1);
//     var actual = new Date(actualDate.getTime());
//     var dias = Math.ceil((actual - inicio + 1) / 86400000);
//     var quincena = Math.floor(dias / 15);

//     setActualPeriodDate({
//       actualPeriod: quincena + 1,
//       actualYear: actualDate,
//       firstDayPeriod: primerDiaDeLaQuincena - 1,
//       endDayPeriod: ultimoDiaQuincena - 1,
//     });
//   };

//   useEffect(() => {
//     if (
//       selectsData.applicationSalaryTypes.length > 0 &&
//       selectsData.penaltyTypes.length > 0 &&
//       selectsData.statusTypes.length > 0 &&
//       loading
//     ) {
//       setLoading(false);
//     }
//   }, [loading, selectsData]);

//   useEffect(() => {
//     if (penaltyData.idPenalty !== "") {
//       if (
//         penaltyData.application.length >= 0 &&
//         penaltyData.application !== null &&
//         penaltyData.application !== "" &&
//         penaltyData.application !== undefined
//       ) {
//         penaltyData.application.forEach((e) => {
//           if (e.keyTypeApplicationSalary !== "1") {
//             setShowSection(true);
//           }
//         });
//       }
//     }
//   });

//   // -------------------- LLENADO DEL HOOK DE OPCIONES PARA SELECCIONAR TIPOS DE DESCUENTOS ---------------- //

//   useEffect(() => {
//     if (
//       discounts.length === 0 &&
//       selectsData.applicationSalaryTypes.length === 0 &&
//       salaryTypesBack.length === 0
//     ) {
//       getApplicationSalaries(setSelectsData, true, "applicationSalaryTypes");
//     } else {
//       var flag = false;
//       if (selectsData.applicationSalaryTypes.length > 0) {
//         for (let i = 0; i < selectsData.applicationSalaryTypes.length; i++) {
//           if (selectsData.applicationSalaryTypes[i] === undefined) {
//             selectsData.applicationSalaryTypes.splice(i, 1);
//           }
//         }
//       }
//       if (salaryTypesBack.length > 0) {
//         for (let i = 0; i < salaryTypesBack.length; i++) {
//           if (salaryTypesBack[i] === undefined) {
//             salaryTypesBack.splice(i, 1);
//           }
//         }
//       }

//       if (selectsData.applicationSalaryTypes.length > 0) {
//         salaryTypesBack.forEach((e) => {
//           selectsData.applicationSalaryTypes.forEach((s) => {
//             if (e !== undefined) {
//               if (e.keyTypeApplicationSalary === s.keyTypeApplicationSalary) {
//                 flag = true;
//               }
//             }
//           });
//         });
//       }
//       if (
//         flag &&
//         salaryTypesBack.length + selectsData.applicationSalaryTypes.length > 3
//       ) {
//         //Quiere decir que coincidio con un registro entonces, que lo actualice
//         discounts.forEach((e) => {
//           let finalArray = "";
//           if (e.descuentosAplicacionKeyTypeApplicationSalary === "1") {
//             finalArray = selectsData.applicationSalaryTypes.filter(
//               (element) => element.keyTypeApplicationSalary !== 1
//             );
//           }
//           if (e.descuentosAplicacionKeyTypeApplicationSalary === "2") {
//             finalArray = selectsData.applicationSalaryTypes.filter(
//               (element) => element.keyTypeApplicationSalary !== 2
//             );
//           }
//           if (e.descuentosAplicacionKeyTypeApplicationSalary === "3") {
//             finalArray = selectsData.applicationSalaryTypes.filter(
//               (element) => element.keyTypeApplicationSalary !== 3
//             );
//           }
//           setSelectsData((prevState) => ({
//             ...prevState,
//             applicationSalaryTypes: finalArray,
//           }));
//         });
//       }
//     }
//     // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, [selectsData.applicationSalaryTypes, salaryTypesBack]);

//   // || ------------------- FIN DE LOS USEEFFECT ---------------- || //

//   // -------------------------------------------------------------------- //

//   const {
//     handleSubmit,
//     handleReset,
//     values,
//     handleBlur,
//     errors,
//     touched,
//     setFieldValue,
//     resetForm,
//   } = useFormik({
//     initialValues: penaltyData,
//     onSubmit: (values) => {
//       enviaDatos();
//     },
//     validationSchema: FormSchema,
//     enableReinitialize: true,
//   });
//   // -------------------- COLUMNAS TABLA ---------------- //
//   const PdfCol = (el) => {
//     return (
//       <div>
//         <i
//           className="fa fa-file-pdf-o cursor-pointer"
//           onClick={() => {
//             showFile(el[8]);
//           }} //console.log(el);
//         />
//       </div>
//     );
//   };
//   const editCol = (el) => {
//     if (permissions.UPD)
//       return (
//         <div>
//           <i
//             className="fa fa-pencil text-warning cursor-pointer"
//             onClick={() => modoEdicion(el)}
//           />
//         </div>
//       );
//   };
//   const deleteCol = (el) => {
//     if (permissions.DEL)
//       return (
//         <div>
//           <ModalComponentEliminar
//             modalTitle="Eliminar"
//             id={el[0]}
//             table="penaltys"
//             nameColumn={["idPenalty"]}
//             limpia={limpia}
//             elimina={elimianrRegistro}
//           >
//             <h6>¿Seguro de eliminar el registro "{el[1]}"?</h6>
//             No podrá recuperar la información después de ser eliminada
//           </ModalComponentEliminar>
//         </div>
//       );
//   };

//   async function elimianrRegistro(id) {
//     const params = {
//       action: "delete",
//       table: "penaltys",
//       condition: { idPenalty: id },
//       force: 1,
//     };
//     setLoading(true);
//     const data = await doRequest("receiver/receiver.php", params, true);
//     if (data.length > 0) {
//       cleanForm();
//       setParametros({ ...parametros });
//     }
//     setLoading(false);
//   }

//   useEffect(() => {
//     if (linkFile !== "") {
//       setModalFile(true);
//     }
//   }, [linkFile]);

//   useEffect(() => {
//     if (!modalFile) {
//       setLinkFile("");
//     }
//   }, [modalFile]);

//   function showFile(src) {
//     if (src === "") {
//       toast(
//         <Notification
//           type={"warning"}
//           backMessage="Este registro no cuenta con una evidencia"
//           withIcon
//         />,
//         { closeButton: false }
//       );
//     } else setLinkFile(src);
//   }

//   const onChangeSwitch = (e) => {
//     console.log("SWITCH CAMBIADO");
//     setFieldValue(e.target.name, !values[e.target.name]);
//   };

//   return (
//     <>
//       <div>
//         {loading ? (
//           <Loader />
//         ) : (
//           <Widget className="widget-p-md">
//             <TableComponentCustom
//               titulo="Sanciones/Multas"
//               cabecerasTabla={cabeceras}
//               filtro={filtro}
//               parametros={parametros}
//               specialColumns={[PdfCol, editCol, deleteCol]}
//             />
//             {permissions.INS && (
//               <div className="d-grid d-flex justify-content-center justify-content-sm-end">
//                 <Button
//                   color="add"
//                   onClick={toggle}
//                   style={{ marginBottom: "1rem" }}
//                   disabled={collapse}
//                 >
//                   Agregar
//                 </Button>
//               </div>
//             )}
//             <Collapse isOpen={collapse}>
//               <div>
//                 <Col className="d-grid d-flex justify-content-sm-end justify-content-center">
//                   <h6 className=" text-center">
//                     Estado:{" "}
//                     <span className="text-primary">
//                       {editando ? "Editando" : "Guardando"}{" "}
//                     </span>{" "}
//                   </h6>
//                 </Col>
//               </div>
//               <>
//                 <form
//                   onSubmit={handleSubmit}
//                   onReset={handleReset}
//                   className="mt-4"
//                   noValidate
//                 >
//                   <ModalConfirmation
//                     modalTitle={editando ? "Editar" : "Crear"}
//                     modal={modal}
//                     setModal={setModal}
//                     crear={sendFormInformation}
//                     editar={sendFormInformation}
//                     isEdit={idStaff ? true : false}
//                     values={finalData}
//                   >
//                     {editando ? (
//                       <div className="d-flex justify-content-center">
//                         <h6>¿Está seguro de editar el registro?</h6>
//                       </div>
//                     ) : (
//                       <div className="d-flex justify-content-center">
//                         <h6>¿Desea continuar con el registro?</h6>
//                       </div>
//                     )}
//                   </ModalConfirmation>
//                   <Row className="mt-2">
//                     <Col xs={12} md={12} lg={4}>
//                       <TextInput
//                         label="Oficio"
//                         inputType="text"
//                         inputName="policy"
//                         onChangeMethod={onChange}
//                         onBlurMethod={handleBlur}
//                         value={values.policy}
//                         isRequired={true}
//                         touched={touched.policy}
//                         errors={errors.policy}
//                       />
//                     </Col>
//                     <Col xs={12} md={12} lg={4}>
//                       <SelectTypeHeadSingle
//                         label="Seleccione un tipo de sancion/multa"
//                         isRequired={true}
//                         inputName="keyTypePenalty"
//                         optionsArray={selectsData.penaltyTypes}
//                         defaultOption="Seleccione un tipo de sancion/multa"
//                         onChangeMethod={onChange}
//                         onBlur={handleBlur}
//                         isDisabled={false}
//                         value={values.keyTypePenalty}
//                         touched={touched.keyTypePenalty}
//                         errors={errors.keyTypePenalty}
//                         optionValue="keyTypePenalty"
//                         optionName="namePenalty"
//                       />
//                     </Col>
//                     <Col xs={12} md={12} lg={4}>
//                       <SelectTypeHeadSingle
//                         label="Seleccione un estatus"
//                         isRequired={true}
//                         inputName="keyStat"
//                         optionsArray={selectsData.statusTypes}
//                         defaultOption="Seleccione un estatus"
//                         onChangeMethod={onChange}
//                         onBlur={handleBlur}
//                         isDisabled={false}
//                         value={values.keyStat}
//                         touched={touched.keyStat}
//                         errors={errors.keyStat}
//                         optionValue="keyStat"
//                         optionName="nameStat"
//                       />
//                     </Col>
//                   </Row>
//                   <Row className="mt-2">
//                     <Col xs={12} md={12} lg={4}>
//                       <div className="form-group">
//                         <label htmlFor={values.startDate}>
//                           Fecha de inicio<span className="text-danger">*</span>
//                         </label>
//                         <DatePicker
//                           locale="es"
//                           onChange={(date) =>
//                             onChangeDate({ name: "startDate", value: date })
//                           }
//                           /*  maxDate={values.endDate !== "" && values.endDate} */
//                           maxDate={
//                             values.keyStat === objEmployHistory.keyStat &&
//                             actualPeriodDate.firstDayPeriod
//                           }
//                           // minDate={
//                           //   values.keyStat !== objEmployHistory.keyStat &&
//                           //   actualPeriodDate.firstDayPeriod
//                           // }
//                           value={
//                             values.startDate !== ""
//                               ? formatDateShow(values.startDate)
//                               : "dd/mm/yyyy"
//                           }
//                           className={
//                             "form-control" +
//                             (errors.startDate && touched.startDate
//                               ? " is-invalid"
//                               : "")
//                           }
//                           /* disabled={values.keyStat === objEmployHistory.keyStat ? false: true} */
//                           disabled={
//                             values.keyStat === "" ||
//                             values.keyStat === undefined ||
//                             values.keyStat === null ||
//                             values.keyStat === 0
//                               ? true
//                               : false
//                           }
//                         />
//                         {touched.startDate && errors.startDate && (
//                           <span style={{ color: "red" }}>
//                             {errors.startDate}
//                           </span>
//                         )}
//                       </div>
//                     </Col>
//                     <Col xs={12} md={12} lg={4}>
//                       <div className="form-group">
//                         <label htmlFor={values.endDate}>
//                           Fecha de termino<span className="text-danger">*</span>
//                         </label>
//                         <DatePicker
//                           locale="es"
//                           minDate={values.startDate}
//                           /* maxDate={  values.keyStat === objEmployHistory.keyStat ? actualPeriodDate.endDayPeriod : new Date()} */
//                           onChange={(date) =>
//                             onChangeDate({ name: "endDate", value: date })
//                           }
//                           value={
//                             values.endDate !== ""
//                               ? formatDateShow(values.endDate)
//                               : "dd/mm/yyyy"
//                           }
//                           className={
//                             "form-control" +
//                             (errors.endDate && touched.endDate
//                               ? " is-invalid"
//                               : "")
//                           }
//                           disabled={values.startDate === "" ? true : false}
//                         />
//                         {touched.endDate && errors.endDate && (
//                           <span style={{ color: "red" }}>{errors.endDate}</span>
//                         )}
//                       </div>
//                     </Col>
//                     <Col xs={12} md={12} lg={4}>
//                       <TextInput
//                         label="No. días"
//                         inputType="number"
//                         inputName="noDays"
//                         onChangeMethod={onChange}
//                         onBlurMethod={handleBlur}
//                         value={values.noDays}
//                         isRequired={false}
//                         isDisabled={true}
//                       />
//                     </Col>
//                     <Col xs={12} md={12} lg={4}>
//                       <SelectTypeHeadSingle
//                         label="Concepto"
//                         isRequired={true}
//                         inputName="idUnder"
//                         optionsArray={selectsData.underTypes}
//                         defaultOption="Seleccione un concepto"
//                         onChangeMethod={onChange}
//                         onBlur={handleBlur}
//                         isDisabled={false}
//                         value={values.idUnder}
//                         touched={touched.idUnder}
//                         errors={errors.idUnder}
//                         optionValue="idUnder"
//                         optionName="name"
//                       />
//                     </Col>
//                     <Col xs={12} md={12} lg={4}>
//                       <FilesInput
//                         label="Evidencia"
//                         inputName="comprobante"
//                         onChangeMethod={onChangeFile}
//                         onBlurMethod={handleBlur}
//                         fileAccept="application/pdf"
//                         isRequired
//                         touched={touched.comprobante}
//                         errors={errors.comprobante}
//                         basicReq={false}
//                         refInput={refInpFile}
//                       />
//                     </Col>
//                   </Row>
//                   {/* AQUI INICIAMOS CON EL PROCESO QUE SE VA A COMPARTIR CON LOS OTROS 2 MODULOS */}
//                   {values.startDate !== "" && values.endDate !== "" && (
//                     <>
//                       {/* FORMAS DE DESCUENTO */}
//                       <Row className="mt-2">
//                         <Col>
//                           <h4>Formas de descuento</h4>
//                         </Col>
//                       </Row>
//                       <Row className="mt-2">
//                         <Col xs={12} md={12} lg={4}>
//                           <SelectInput
//                             label="Seleccione un tipo de descuento"
//                             inputName="descuentosAplicacionKeyTypeApplicationSalary"
//                             onChangeMethod={onChange}
//                             onBlurMethod={handleBlur}
//                             value={
//                               descuentosAplicacion.descuentosAplicacionKeyTypeApplicationSalary
//                             }
//                             isRequired={false}
//                             optionsArray={selectsData.applicationSalaryTypes}
//                             optionValue="keyTypeApplicationSalary"
//                             optionName="nameTypeApplicationSalary"
//                             defaultOption="Seleccione un tipo de descuento"
//                             isDisabled={
//                               values.startDate === "" || values.endDate === ""
//                                 ? true
//                                 : false
//                             }
//                           />
//                         </Col>
//                         <Col xs={12} md={12} lg={4}>
//                           <div className="form-group">
//                             <label htmlFor={values.endDate}>
//                               Fecha de inicio
//                               <span className="text-danger">*</span>
//                             </label>
//                             <DatePicker
//                               locale="es"
//                               excludeDateIntervals={excludeDates}
//                               onChange={(date) =>
//                                 onChangeDate({
//                                   name: "descuentosAplicacionStartDate",
//                                   value: date,
//                                 })
//                               }
//                               value={
//                                 descuentosAplicacion.descuentosAplicacionStartDate !==
//                                 ""
//                                   ? formatDateShow(
//                                       descuentosAplicacion.descuentosAplicacionStartDate
//                                     )
//                                   : "dd/mm/yyyy"
//                               }
//                               disabled={
//                                 values.startDate === "" || values.endDate === ""
//                                   ? true
//                                   : false
//                               }
//                               className={"form-control"}
//                               minDate={values.startDate}
//                               maxDate={values.endDate}
//                             />
//                           </div>
//                         </Col>
//                         <Col xs={12} md={12} lg={4}>
//                           <div className="form-group">
//                             <label htmlFor={values.endDate}>
//                               Fecha de termino
//                               <span className="text-danger">*</span>
//                             </label>
//                             <DatePicker
//                               locale="es"
//                               excludeDateIntervals={excludeDates}
//                               onChange={(date) =>
//                                 onChangeDate({
//                                   name: "descuentosAplicacionEndDate",
//                                   value: date,
//                                 })
//                               }
//                               value={
//                                 descuentosAplicacion.descuentosAplicacionEndDate !==
//                                 ""
//                                   ? formatDateShow(
//                                       descuentosAplicacion.descuentosAplicacionEndDate
//                                     )
//                                   : "dd/mm/yyyy"
//                               }
//                               disabled={
//                                 descuentosAplicacion.descuentosAplicacionStartDate ===
//                                 ""
//                                   ? true
//                                   : false
//                               }
//                               className={"form-control"}
//                               minDate={
//                                 descuentosAplicacion.descuentosAplicacionStartDate
//                               }
//                               maxDate={values.endDate}
//                             />
//                           </div>
//                         </Col>
//                         <Col xs={12} md={12} lg={4}>
//                           <TextInput
//                             label="No. días"
//                             inputType="number"
//                             inputName="descuentosAplicacionNoDays"
//                             onChangeMethod={onChange}
//                             onBlurMethod={handleBlur}
//                             value={
//                               descuentosAplicacion.descuentosAplicacionNoDays
//                             }
//                             isRequired={false}
//                             isDisabled={true}
//                           />
//                         </Col>
//                         <Col xs={12} md={12} lg={4}>
//                           <div
//                             style={{
//                               display: "flex",
//                               justifyContent: "center",
//                               alignContent: "center",
//                             }}
//                           >
//                             <Button
//                               color="success"
//                               onClick={() => addDiscount()}
//                             >
//                               +
//                             </Button>
//                           </div>
//                         </Col>
//                         {errorsDiscount && (
//                           <Row>
//                             <Col xs={12}>
//                               <h6 style={{ color: "red" }}>
//                                 Error, ingrese todos los datos y valide que la
//                                 suma de sus dias de descuento sea igual a la de
//                                 solicitada arriba
//                               </h6>
//                             </Col>
//                           </Row>
//                         )}
//                         {discounts.length > 0 && (
//                           <Col xs={12} md={12} lg={12}>
//                             <TableComponentDiscounts
//                               registros={discounts}
//                               setRegistros={setDiscounts}
//                               salaryTypesBack={salaryTypesBack}
//                               selectsData={selectsData}
//                               setSelectsData={setSelectsData}
//                               setSalaryTypesBack={setSalaryTypesBack}
//                               excludeDates={excludeDates}
//                               setExcludeDates={setExcludeDates}
//                               sendDiscount={sendDiscount}
//                               setShowSection={setShowSection}
//                             />
//                           </Col>
//                         )}
//                       </Row>
//                       {/* APLICACION EN NOMINA */}

//                       {showSection > 0 && (
//                         <>
//                           <Row className="mt-2">
//                             <Col>
//                               <h4>Aplicación en nomina</h4>
//                             </Col>
//                           </Row>

//                           <Row className="mb-2 mt-2 text-center">
//                             <Col>
//                               <h4>Tipo de aplicación</h4>
//                             </Col>
//                           </Row>

//                           <Row classname="d-flex flex-row  justify-content-center ">
//                             <Col xs={5} md={5} lg={5} className="text-right">
//                               <p>Por nomina </p>
//                             </Col>
//                             <Col xs={2} md={2} lg={2}>
//                               <CustomInput
//                                 type="switch"
//                                 id="forPeriod"
//                                 name="forPeriod "
//                                 className="mx-auto text-center"
//                                 /* onChange={(e) => onChangeSwitch(e)} */
//                                 onChange={() => {
//                                   setForPeriod(!forPeriod);
//                                 }}
//                                 checked={forPeriod}
//                               />
//                             </Col>
//                             <Col xs={5} md={5} lg={5} className="text-left">
//                               <p>Por periodo </p>
//                             </Col>
//                           </Row>

//                           {forPeriod && (
//                             <>
//                               <Row className="mt-2">
//                                 {/* <Col xs={12} md={12} lg={4}>
//                               <TextInput
//                                 label="Cantidad total"
//                                 inputType="number"
//                                 inputName="amountTotal"
//                                 onChangeMethod={onChange}
//                                 onBlurMethod={handleBlur}
//                                 value={values.amountTotal}
//                                 isRequired={true}
//                                 touched={touched.amountTotal}
//                                 errors={errors.amountTotal}
//                               />
//                             </Col> */}
//                                 <Col xs={12} md={12} lg={4}>
//                                   <div className="form-group">
//                                     <label htmlFor="amountTotal">
//                                       Cantidad total{" "}
//                                       <span className="text-danger">*</span>
//                                     </label>
//                                     <CurrencyInput
//                                       id="amountTotal"
//                                       name="amountTotal"
//                                       className={
//                                         "form-control" +
//                                         (errors.amountTotal &&
//                                         touched.amountTotal
//                                           ? " is-invalid"
//                                           : "")
//                                       }
//                                       allowDecimals={true}
//                                       allowNegativeValue={false}
//                                       onValueChange={(value, name, e) =>
//                                         onChange({
//                                           target: { name: name, value: value },
//                                         })
//                                       }
//                                       value={values.amountTotal}
//                                       onBlur={handleBlur}
//                                       min={1}
//                                       prefix="$"
//                                       decimalSeparator="."
//                                       groupSeparator=","
//                                     />
//                                     {touched.amountTotal &&
//                                       errors.amountTotal && (
//                                         <span className="text-danger">
//                                           {errors.amountTotal}
//                                         </span>
//                                       )}
//                                   </div>
//                                 </Col>

//                                 <Col xs={12} md={12} lg={4}>
//                                   <div className="form-group">
//                                     <label htmlFor="noChildren">
//                                       Número de Parcialidades{" "}
//                                       <span className="text-danger">*</span>
//                                     </label>
//                                     <CurrencyInput
//                                       id="noPays"
//                                       name="noPays"
//                                       className={
//                                         "form-control" +
//                                         (errors.noPays && touched.noPays
//                                           ? " is-invalid"
//                                           : "")
//                                       }
//                                       allowDecimals={false}
//                                       allowNegativeValue={false}
//                                       onValueChange={(v, n) =>
//                                         onChange({
//                                           target: { name: n, value: v },
//                                         })
//                                       }
//                                       // onChange={onChange}
//                                       onBlur={handleBlur}
//                                       maxLength={2}
//                                       value={values.noPays}
//                                       // step={1}
//                                       min={1}
//                                     />
//                                     {touched.noPays && errors.noPays && (
//                                       <span className="text-danger">
//                                         {errors.noPays}
//                                       </span>
//                                     )}
//                                   </div>
//                                 </Col>
//                                 <Col xs={12} md={12} lg={4}>
//                                   {/* <TextInput
//                                 label="Cantidad quincenal"
//                                 inputType="number"
//                                 inputName="amountBiweekly"
//                                 onChangeMethod={onChange}
//                                 onBlurMethod={handleBlur}
//                                 value={values.amountBiweekly}
//                                 isRequired={true}
//                                 touched={touched.amountBiweekly}
//                                 errors={errors.amountBiweekly}
//                                 isDisabled={true}
//                               /> */}
//                                   <div className="form-group">
//                                     <label htmlFor="amountBiweekly">
//                                       Cantidad Quincenal{" "}
//                                       <span className="text-danger">*</span>
//                                     </label>
//                                     <CurrencyInput
//                                       id="amountBiweekly"
//                                       name="amountBiweekly"
//                                       className={
//                                         "form-control" +
//                                         (errors.amountBiweekly &&
//                                         touched.amountBiweekly
//                                           ? " is-invalid"
//                                           : "")
//                                       }
//                                       allowDecimals={true}
//                                       allowNegativeValue={false}
//                                       onValueChange={(value, name, e) =>
//                                         onChange({
//                                           target: { name: name, value: value },
//                                         })
//                                       }
//                                       value={values.amountBiweekly}
//                                       onBlur={handleBlur}
//                                       min={1}
//                                       prefix="$"
//                                       decimalSeparator="."
//                                       groupSeparator=","
//                                       disabled={true}
//                                     />
//                                     {touched.amountBiweekly &&
//                                       errors.amountBiweekly && (
//                                         <span className="text-danger">
//                                           {errors.amountBiweekly}
//                                         </span>
//                                       )}
//                                   </div>
//                                 </Col>
//                               </Row>
//                               <Row className="mt-2">
//                                 <Col xs={12} md={12} lg={3}>
//                                   {/* <TextInput
//                                 label="Inicio de periodo"
//                                 inputType="number"
//                                 inputName="startPeriod"
//                                 onChangeMethod={onChange}
//                                 onBlurMethod={handleBlur}
//                                 value={values.startPeriod}
//                                 isRequired={true}
//                                 touched={touched.startPeriod}
//                                 errors={errors.startPeriod}
//                                 isDisabled={
//                                   values.amountBiweekly === "" ? true : false
//                                 }
//                               /> */}

//                                   <SelectTypeHeadSingle
//                                     label="Periodo de inicio"
//                                     isRequired={true}
//                                     optionsArray={PERIOD}
//                                     inputName="startPeriod"
//                                     onChangeMethod={onChange}
//                                     onBlurMethod={handleBlur}
//                                     touched={touched.startPeriod}
//                                     errors={errors.startPeriod}
//                                     optionValue="value"
//                                     optionName="label"
//                                     value={values.startPeriod}
//                                     defaultOption="Seleccione un periodo de inicio"
//                                     isDisabled={
//                                       values.keyStat ===
//                                       objEmployHistory.keyStat
//                                         ? false
//                                         : true
//                                     }
//                                   />

//                                   {/* <label htmlFor="startPeriod">
//                                 Inicio periodo{" "}
//                                 <span className="text-danger">*</span>
//                               </label> */}
//                                   {/* <CurrencyInput
//                                 id="startPeriod"
//                                 name="startPeriod"
//                                 className={
//                                   "form-control" +
//                                   (errors.startPeriod && touched.startPeriod
//                                     ? " is-invalid"
//                                     : "")
//                                 }
//                                 allowDecimals={false}
//                                 allowNegativeValue={false}
//                                 onValueChange={(v, n) =>
//                                   onChange({ target: { name: n, value: v } })
//                                 }
//                                 onBlur={handleBlur}
//                                 maxLength={3}
//                                 value={values.startPeriod}
//                                 min={1}
//                               />
//                               {touched.startPeriod && errors.startPeriod && (
//                                 <span className="text-danger">
//                                   {errors.startPeriod}
//                                 </span>
//                               )} */}
//                                 </Col>
//                                 <Col xs={12} md={12} lg={3}>
//                                   {/* <TextInput
//                                 label="Año de inicio (YYYY)"
//                                 inputType="number"
//                                 inputName="startYear"
//                                 onChangeMethod={onChange}
//                                 onBlurMethod={handleBlur}
//                                 value={values.startYear}
//                                 isRequired={true}
//                                 touched={touched.startYear}
//                                 errors={errors.startYear}
//                                 isDisabled={
//                                   values.startPeriod === "" ||
//                                   values.startPeriod > 24
//                                     ? true
//                                     : false
//                                 }
//                               /> */}
//                                   <YearInput
//                                     label="Año de inicio"
//                                     inputName="startYear"
//                                     onChangeMethod={onChange}
//                                     onBlurMethod={handleBlur}
//                                     value={values.startYear}
//                                     isRequired
//                                     touched={touched.startYear}
//                                     errors={errors.startYear}
//                                     isMin={new Date("2019")}
//                                     isMax={
//                                       values.keyStat ===
//                                       objEmployHistory.keyStat
//                                         ? actualDate
//                                         : new Date("2500")
//                                     }
//                                     isDisabled={
//                                       values.keyStat ===
//                                       objEmployHistory.keyStat
//                                         ? false
//                                         : true
//                                     }
//                                   />
//                                 </Col>
//                                 <Col xs={12} md={12} lg={3}>
//                                   <TextInput
//                                     label="Periodo de finalización"
//                                     inputType="number"
//                                     inputName="endPeriod"
//                                     onChangeMethod={onChange}
//                                     onBlurMethod={handleBlur}
//                                     value={values.endPeriod}
//                                     isRequired={true}
//                                     touched={touched.endPeriod}
//                                     errors={errors.endPeriod}
//                                     isDisabled={true}
//                                   />
//                                 </Col>
//                                 <Col xs={12} md={12} lg={3}>
//                                   <TextInput
//                                     label="Año de finalización"
//                                     inputType="number"
//                                     inputName="endYear"
//                                     onChangeMethod={onChange}
//                                     onBlurMethod={handleBlur}
//                                     value={values.endYear}
//                                     isRequired={true}
//                                     touched={touched.endYear}
//                                     errors={errors.endYear}
//                                     isDisabled={true}
//                                   />
//                                 </Col>
//                               </Row>
//                               <Row className="mt-2">
//                                 <Col xs={12} md={12} lg={4}>
//                                   <TextInput
//                                     label="Pagos Aplicados"
//                                     inputType="number"
//                                     inputName="noPaysMade"
//                                     onChangeMethod={onChange}
//                                     onBlurMethod={handleBlur}
//                                     value={values.noPaysMade}
//                                     isRequired={true}
//                                     touched={touched.noPaysMade}
//                                     errors={errors.noPaysMade}
//                                     isDisabled={true}
//                                   />
//                                 </Col>
//                               </Row>
//                               {errorApplications !== false && (
//                                 <Row>
//                                   <Col xs={12}>
//                                     <h6 style={{ color: "red" }}>
//                                       {errorApplications}
//                                     </h6>
//                                   </Col>
//                                 </Row>
//                               )}
//                             </>
//                           )}
//                         </>
//                       )}
//                       <Row className="mt-2">
//                         <Col xs={12} md={12} lg={12}>
//                           <TextInput
//                             label="Notas"
//                             inputType="text"
//                             inputName="notes"
//                             onChangeMethod={onChange}
//                             onBlurMethod={handleBlur}
//                             value={values.notes}
//                             isRequired={false}
//                           />
//                         </Col>
//                       </Row>
//                     </>
//                   )}

//                   <div className="row mt-3">
//                     <div className="col-sm-6 order-2 order-sm-1 text-center text-sm-left mb-4">
//                       <Button
//                         color="danger"
//                         type="reset"
//                         onClick={() => cleanForm()}
//                       >
//                         Cancelar
//                       </Button>
//                     </div>
//                     {!noActiveJob && (
//                       <div className="col-sm-6 order-1 order-sm-2 text-center text-sm-right mb-4">
//                         <Button color="success" type="submit">
//                           Guardar
//                         </Button>
//                       </div>
//                     )}
//                   </div>
//                 </form>
//               </>
//             </Collapse>
//             <ModalViewFile
//               modal={modalFile}
//               setModal={setModalFile}
//               backdrop={true}
//               keyboard={true}
//               btnClose={true}
//               fileLink={linkFile}
//             />
//           </Widget>
//         )}
//       </div>
//     </>
//   );
};
export default ExpedienteNominaMultas;

import axios from "axios";

export default async function getLayout() {
  let data = {};
  try {
    data = await axios.get("excel/layoutExcel.xlsx", {
      responseType: "blob",
    });
  } catch (error) {
    console.log(error);
  }
  return data.data;
}

import * as XlsxPopulate from "xlsx-populate";
import { saveAs } from "file-saver";
import axios from "axios";
import getLayout from "../../../../../../components/excel/layoutExcel";
import { fMoney } from "../../../../../../libs/tools/format";

const s = {
  tC: { horizontalAlignment: "center", verticalAlignment: "center" },
  tS: { horizontalAlignment: "left" },
  tE: { horizontalAlignment: "right", verticalAlignment: "center" },
  tt: {
    horizontalAlignment: "center",
    verticalAlignment: "bottom",
    wrapText: true,
    shrinkToFit: true,
    bold: true,
  },

  tN: {
    border: true,
    wrapText: true,
    shrinkToFit: true,
    verticalAlignment: "center",
  },
  tNC: {
    horizontalAlignment: "center",
    verticalAlignment: "center",
    wrapText: true,
    shrinkToFit: true,
    border: true,
  },
  tNE: {
    horizontalAlignment: "right",
    verticalAlignment: "center",
    wrapText: true,
    shrinkToFit: true,
    border: true,
  },

  header: {
    horizontalAlignment: "center",
    bold: true,
    verticalAlignment: "center",
    fill: "730A29",
    fontColor: "FFFFFF",
    wrapText: true,
    shrinkToFit: true,
    border: true,
  },
  tB: { bold: true },
  wt: { wrapText: true, verticalAlignment: "center" },
};

export async function excelRep51(data, cfg) {
  const excel = await getLayout();
  if (excel)
    XlsxPopulate.fromDataAsync(excel).then(async (workbook) => {
      const hoja = workbook.sheet(0);
      let row = 3;

      hoja.range(`A${row}:H${row}`).merged(true).value("Fiscalia General de Justicia del Estado de México").style(s.tt);
      hoja.range(`A${row+1}:H${row+1}`).merged(true).value(`Presupuesto de Egresos para el Ejercicio Fiscal ${cfg.year}`).style(s.tt);
      hoja.range(`A${row+2}:H${row+2}`).merged(true).value(`Clasificador Funcional del Gasto`).style(s.tt);
      row+=4;
      hoja.range(`A${row}:F${row}`).merged(true).value(`Total`).style(s.header);
      hoja.range(`G${row}:H${row}`).merged(true).value(`Importe`).style(s.header);
      row++;
      hoja.range(`A${row}:F${row}`).merged(true).value(`Gobierno`).style(s.tNC);
      hoja.range(`G${row}:H${row}`).merged(true).value(fMoney(data.montoTotal)).style(s.tNE);

      hoja.range(`A${row+1}:F${row+1}`).merged(true).value(`Desarrollo Social`).style(s.tN);
      hoja.range(`G${row+1}:H${row+1}`).merged(true).style(s.tNE);
      
      hoja.range(`A${row+2}:F${row+2}`).merged(true).value(`Desarrollo Económico`).style(s.tN);
      hoja.range(`G${row+2}:H${row+2}`).merged(true).style(s.tNE);
      
      hoja.range(`A${row+3}:F${row+3}`).merged(true).value(`Otras no clasificadas en funciones anteriores`).style(s.tN);
      hoja.range(`G${row+3}:H${row+3}`).merged(true).style(s.tNE);


      return workbook.outputAsync().then((res) => {
        saveAs(res, `Clasificación Funcional del Gasto ${cfg.year}.xlsx`);
      });
    });
}

import React, { useState, useEffect } from "react";
import ModalReports from "../../../../../components/Modal/ModalReports";
import { Table } from "reactstrap";
import sc from "../../../../../components/TableComponent/TableComponentFiltro.module.scss";
import QRCode from "qrcode.react";
import Loader from "../../../../../components/Loader/Loader";
import { generatePdfPreNomina } from "./PdfPreNomina";
import Form from "./Form";
import { saveAsExcel } from "../../../../../components/excel/Excel";

const cabeceras = [
  "NUM. EMPLEADO",
  "NOMBRE",
  "CONCEPTO",
  "DESCRIPCIÓN CONCEPTO",
  "TIPO CONCEPTO",
  "PERIODO",
  "AÑO",
  "ESTADO",
  "MONTO",
  "NO. CUENTA",
];

const PreNomina = () => {
  const [report, setReport] = useState("");
  const [showReportFormat, setShowReportFormat] = useState(3);
  const [modalReports, setModalReports] = useState(false);
  const [pdfConfig, setPdfConfig] = useState({});
  const [valuesReport, setvaluesReport] = useState("");
  const [params, setParams] = useState("");
  const loading = false;

  const [totalPerceptions, setTotalPerceptions] = useState(0);
  const [totalDeductions, setTotalDeductions] = useState(0);

  const formatter = new Intl.NumberFormat("es-MX", {
    style: "currency",
    currency: "MXN",
  });

  /* setTitle("Reporte de Percepciones y Deducciones");
      setCabeceras(cabeceraGeneral);
      setPdfConfig({
        headers: ["NO", "NO. EMPLEADO", "REPORTE GENERAL DEL AREA"],
        filters: {
          message: `DEL PERIODO ${
            values.period
          } DEL AÑO ${values.year.getFullYear()}`,
          onlyMessage: true,
        },
        id: { id: "staffNumber" },
        content: contentPdfGeneral,
      });
      setData({
        action: "report7",
        search: "",
        order: "",
        idStaff: reportConfig.idStaff,
        period: reportConfig.period,
        year: reportConfig.year.getFullYear(),
      }); */

  useEffect(() => {
    if (report !== "" && showReportFormat === 2) {
      

      saveAsExcel(
        report,
        cabeceras,
        "Reporte de Percepciones y Deducciones",
        {
          headers: ["NO", "NO. EMPLEADO", "REPORTE GENERAL DEL AREA"],
          filters: {
            message: `DEL PERIODO ${
              valuesReport.period
            } DEL AÑO ${valuesReport.year.getFullYear()}`,
            onlyMessage: true,
          },
          id: { id: "staffNumber" },
          content: [
            [
              { header: "NOMBRE", value: "nameStaff", columns: 1 },
              { header: "CONCEPTO", value: "under", columns: 1 },
              {
                header: "DESCRIPCIÓN CONCEPTO",
                value: "nameUnder",
                columns: 1,
              },
              {
                header: "TIPO CONCEPTO",
                value: "nameTypeUnder",
                columns: 1,
              },
              { header: "PERIODO", value: "period", columns: 1 },
              { header: "AÑO", value: "year", columns: 1 },
              {
                header: "MONTO",
                value: "amount",
                columns: 1,
                format: "moneda",
              },
              { header: "ESTADO", value: "nameStat", columns: 1 },
              { header: "NO. CUENTA", value: "account", columns: 1 },
            ],
          ],
        },
        `Periodo `
      );
      // saveAsExcel(report, cabeceras, title, pdfConfig, descTipo);
      // generatePdfPreNomina(
      //   report,
      //   cabeceras,
      //   "Reporte de Percepciones y Deducciones"
      // );
    } else if (report !== "" && showReportFormat === 1) {
      generatedQrImagePdfConfig();
      setModalReports(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [report]);

  const generatedQrImagePdfConfig = () => {
    const canvas = document.getElementById("123456");
    const pngUrl = canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");

    setPdfConfig({
      headers: ["NO", "NÚMERO DE EMPLEADO", "REPORTE GENERAL DEL ÁREA"],
      filters: {
        message: `DEL PERIODO ${
          valuesReport.period
        } DEL AÑO ${valuesReport.year.getFullYear()}`,
        onlyMessage: true,
      },
      id: { id: "staffNumber" },
      content: [
        [
          { header: "NOMBRE", value: "nameStaff", columns: 1 },
          { header: "CONCEPTO", value: "under", columns: 1 },
          {
            header: "DESCRIPCIÓN CONCEPTO",
            value: "nameUnder",
            columns: 1,
          },
          {
            header: "TIPO CONCEPTO",
            value: "nameTypeUnder",
            columns: 1,
          },
          { header: "PERIODO", value: "period", columns: 1 },
          { header: "AÑO", value: "year", columns: 1 },
          {
            header: "MONTO",
            value: "amount",
            columns: 1,
            format: "moneda",
          },
          { header: "ESTADO", value: "nameStat", columns: 1 },
          { header: "NO. CUENTA", value: "account", columns: 1 },
        ],
      ],
      pngUrl: pngUrl,
    });
  };
  return (
    <div>
      <Form
        setReport={setReport}
        setShowReportFormat={setShowReportFormat}
        setvaluesReport={setvaluesReport}
        setParams={setParams}
        setTotalPerceptions={setTotalPerceptions}
        setTotalDeductions={setTotalDeductions}
      />
      <>
        {report !== "" && showReportFormat === 1 ? (
          <ModalReports
            modal={modalReports}
            setModal={setModalReports}
            title={"Reporte de Percepciones y Deducciones"}
            backdrop={"static"}
            keyboard={false}
            report={report}
            pdfConfig={pdfConfig}
            generatePdfMethod={{
              method: generatePdfPreNomina,
              type: "percepcionesDeducciones",
            }}
            records={1000}
          />
        ) : (
          params !== "" &&
          showReportFormat === 3 &&
          report !== "" && (
            <>
              <Table
                className={`table-striped table-borderless table-hover `}
                responsive
              >
                <thead className="border-bottom border-secondary">
                  <tr className={sc.userSelectNone}>
                    <th className="text-center">Clave</th>
                    <th className="text-center">Concepto</th>
                    <th className="text-center">Percepciones</th>
                    <th className="text-center">Deducciones</th>
                  </tr>
                </thead>
                <tbody>
                  {loading ? (
                    <tr key={"loading"}>
                      <td colSpan={4}>
                        <Loader />
                      </td>
                    </tr>
                  ) : report === "" ? (
                    <tr key={"nothing"}>
                      <td colSpan={4} className="text-center">
                        No se encontraron registros
                      </td>
                    </tr>
                  ) : (
                    report.length !== "" &&
                    report.map((registro, index) => (
                      <tr key={"R" + index}>
                        <td className="text-center">{registro.under}</td>
                        <td className="text-left">{registro.nameUnder}</td>
                        <td className={"text-right"}>
                          {registro.keyTypeUnder === "1"
                            ? formatter.format(registro.amount)
                            : "-"}
                        </td>
                        <td className={"text-right"}>
                          {registro.keyTypeUnder !== "1"
                            ? formatter.format(registro.amount)
                            : "-"}
                        </td>
                      </tr>
                    ))
                  )}
                  {report !== "" && (
                    <tr>
                      <td colSpan={2} className="text-center">
                        Total
                      </td>
                      <td className="text-right">
                        {formatter.format(totalPerceptions)}
                      </td>
                      <td className="text-right">
                        {formatter.format(totalDeductions)}
                      </td>
                    </tr>
                  )}
                  {report !== "" && (
                    <tr>
                      <td colSpan={2} className="text-center">
                        Total neto
                      </td>
                      <td colSpan={2} className="text-right">
                        {formatter.format(totalPerceptions - totalDeductions)}
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </>
          )
        )}
      </>

      <div style={{ display: "none" }}>
        <QRCode
          id="123456"
          value="https://fgjem.edomex.gob.mx/"
          size={290}
          level={"H"}
          includeMargin={true}
        />
      </div>
    </div>
  );
};

export default PreNomina;

import React from "react";

export const TextAreaInput = (props) => {
  const {
    label,
    inputName,
    inputRows,
    onChangeMethod,
    onBlurMethod,
    value,
    isRequired,
    touched,
    errors,
    isDisabled,
  } = props;
  return (
    <>
      <div className="form-group">
        <label htmlFor={inputName}>
          {label} {isRequired && <span className="text-danger">*</span>}
        </label>
        <textarea
          name={inputName}
          id={inputName}
          onChange={onChangeMethod}
          onBlur={onBlurMethod}
          rows = {inputRows}
          className={
            "form-control" +
            (errors && touched ? " is-invalid" : "")
          }
          value={value}
          // isRequired={isRequired ? 'true' : 'false'}
          disabled={isDisabled}
        />
        {touched && errors && (
          <span style={{ color: "red" }}>{errors}</span>
        )}
      </div>
    </>
  );
};

import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import {
  Col,
  Row,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import FullFormLoader from "../../../components/Loader/FullFormLoader";
import {
  SelectWithSearchInput,
  TextInput,
} from "../../../components/GenericInputsFormik/index";
import * as Yup from "yup";
import { peticionesReceiver } from "../../../helpers/peticionesReceiver";
import { toast } from "react-toastify";
import Notification from "../../../components/Notification/Notification";

import TableComponent from "../../../components/TableComponent/TableComponentReport";
import Widget from "../../../components/Widget/Widget";
/* import PdfGeneral from "../../../components/Pdf/pdfGeneral/PdfGeneral"; */
import {generatePdfGeneral} from "../../../components/Pdf/pdfGeneral/PdfGeneral"
import ModalReports from "../../../components/Modal/ModalReports";

import { StyleSheet, PDFViewer } from "@react-pdf/renderer";
import { saveAsExcel } from "../../../components/excel/Excel";
import { GetPermissions } from "../../../libs/permissions/getPermissions";

/* Da tamaño al vizualizador pdf*/
const stylesTable = StyleSheet.create({
  viewer: {
    width: 800, //the pdf viewer will take up all of the width and height
    height: window.innerHeight,
  },
});

/*  Variables para los que utilizan la misma configuracion de las columnas en el despliegue del PDF, si hay cambios no hacerlo en todos */
const pdfConfigGeneral = {
  headers: ["No", "No.EMPLEADO", "REPORTE GENERAL DE EMPLEADO"],
  id: {id: "staffNumber"},
  content: [
    [
      { header: "NOMBRE", value: "name", columns: 1 },
      { header: "FECHA DE NACIMIENTO", value: "bornDate", columns: 1, format: "fecha" },
      { header: "RFC", value: "rfc", columns: 1 },
    ],
    [
      { header: "CURP", value: "curp", columns: 1 },
      { header: "TELEFONO DE TRABAJO", value: "jobNumber", columns: 1 },
      { header: "CORREO DE TRABAJO", value: "jobMail", columns: 1 },
    ],
    [
      { header: "GENERO", value: "nameGender", columns: 1 },
      { header: "ESTADO CIVIL", value: "nameTypeMarital", columns: 1 },
      { header: "DISCAPACIDAD", value: "nameDisability", columns: 1 },
    ],

    [{ header: "LUGAR DE NACIMIENTO", value: "nacidoEn", columns: 3 }],

    [{ header: "DOMICILIO", value: "domicilio", columns: 3 }],
    [
      { header: "TELEFONO PERSONAL", value: "telefonoPersonal", columns: 2 },
      { header: "CORREO PERSONAL", value: "emailPersonal", columns: 1 },
    ],
  ],
};

const cabecerasUsuario = [
  "No.EMPLEADO",
  "NOMBRE",
  "FECHA DE NACIMIENTO",
  "RFC",
  "CURP",
  "TELEFONO DE TRABAJO",
  "CORREO DE TRABAJO",
  "GENERO",
  "ESTADO CIVIL",
  "DISCAPACIDAD",
  "LUGAR DE NACIMIENTO",
  "DOMICILIO",
  "TELEFONO PERSONAL",
  "CORREO PERSONAL",
];

export const ReportesPersonal = (props) => {
  GetPermissions();

  const API = peticionesReceiver();
  const [loader,setLoader] = useState(false)

  const [typeReports, setTypeReports] = useState([]);
  const [areas, setAreas] = useState([]);
  const [defaultForm, setDefaultForm] = useState({
    idFormat: "",
    idReport: "",
    idArea: "",
    idMonth: "",
    initialDate: "",
    finalDate: "",
  });

  ///Varaible para tipo de reporte
  const [descTipo, setDescTipo] = useState("");

  /* Modal pdf */
  const [modal, setModal] = useState(false);

  /* Parametros para enviar a los endPoint */
  const [data, setData] = useState("");
  /* Variable donde se guarda la informacion del reporte */
  const [report, setReport] = useState();
  const [title, setTitle] = useState("");

  /* Variable para habilitar  */
  const [showReportFormat, setShowReportFormat] = useState("0");
  //pruebaParaTabla

  /* Configutacion de pdf */
  const [pdfConfig, setPdfConfig] = useState("");

  /* Cabeceras que se enviaran  */
  const [cabeceras, setCabeceras] = useState(cabecerasUsuario);
  const [modalReports, setModalReports] = useState(false);

  /* Arrays estaticos para selects Formato y Meses */
  //formato y meses
  const format = [
    {
      id: 1,
      value: "Pdf",
    },
    {
      id: 2,
      value: "Hoja de calculo",
    },
    {
      id: 3,
      value: "Html",
    },
  ];
  const months = [
    { id: 1, Month: "Enero" },
    { id: 2, Month: "Febrero" },
    { id: 3, Month: "Marzo" },
    { id: 4, Month: "Abril" },
    { id: 5, Month: "Mayo" },
    { id: 6, Month: "Junio" },
    { id: 7, Month: "Julio" },
    { id: 8, Month: "Agosto" },
    { id: 9, Month: "Septiembre" },
    { id: 10, Month: "Octubre" },
    { id: 11, Month: "Noviembre" },
    { id: 12, Month: "Diciembre" },
  ];

  /* Trae los reportes  */
  const toggle = () => setModal(!modal);

  /* use Effect inicial */
  useEffect(() => {
    getTypeReports();
    /* getReport() */
  }, []);

  const getAreas = () => {
    const data = {
      action: "select",
      table: "areas",
      order: "name ASC",
    };
    API.peticion(data)
      .then((res) => {
        if (res.status === 200 && res.data.status === "success") {
          setAreas(res.data.data);
        } else {
          toast(<Notification type={"consultar_error"} withIcon />, {
            closeButton: false,
          });
        }
      })
      .catch((err) => {
        toast(<Notification type={"consultar_error"} withIcon />, {
          closeButton: false,
        });
      });
  };

  /* Consulta para traer los datos para llenar el pdf o excel,  */
  const getReport = (params) => {
    setLoader(true)
    API.peticionEndPoint(params, "app/facades/reports/reportsF.php")
      .then((res) => {
        if (res.status === 200 && res.data.code === "200") {
          setReport(res.data.data);
        } else {
          toast(
            <Notification
              type={"consultar_error"}
              backMessage={res.data.message}
              withIcon
            />,
            { closeButton: false }
          );
        }
      })
      .finally(() => {
        setLoader(false)
      })
      .catch((err) => {
        toast(<Notification type={"consultar_servidor_error"} withIcon />, {
          closeButton: false,
        });
      });
  };
  /* Info para llenar select de los tipos de reportes */
  const getTypeReports = () => {
    const params = {
      action: "getReports",
      rows: {
        idModule: "25",
      },
    };
    API.peticionEndPoint(params, "app/facades/reports/reportsF.php")
      .then((res) => {
        if (res.status === 200) {
          setTypeReports(res.data.data);
        } else {
          toast(
            <Notification
              type={"consultar_error"}
              backMessage={res.data.message}
              withIcon
            />,
            { closeButton: false }
          );
        }
      })
      .catch((err) => {
        toast(<Notification type={"consultar_servidor_error"} withIcon />, {
          closeButton: false,
        });
      });
  };

  const FormSchema = Yup.object().shape({
    idFormat: Yup.string().required("Seleccione un formato"),
    idReport: Yup.string().required("Seleccione un tipo de reporte"),
    idArea: Yup.string().when("idReport", {
      is: "2",
      then: Yup.string().required("Ingrese un area"),
    }),
    idMonth: Yup.string().when("idReport", {
      is: "3",
      then: Yup.string().required("Ingrese un mes"),
    }),
    initialDate: Yup.date().when("idReport", {
      is: "18",
      then: Yup.date().required("Seleccione una fecha de inicio"),
    }),
    finalDate: Yup.date().when("idReport", {
      is: "18",
      then: Yup.date()
        .required("Seleccione una fecha limite")
        .min(
          Yup.ref("initialDate"),
          "La fecha debe ser posterior a la fecha de inicio"
        ),
    }),
  });

  const onChange = (e) => {
    setFieldValue([e.target.name], e.target.value);
    setShowReportFormat("0")
  };
  //ReportConfig = values que envia el formik
  const getData = (reportConfig) => {
    /* setData("") */

    //Reinicia variables en caso de consultar mas de 1
    setReport(undefined);
    setTitle("");
    setShowReportFormat("0");
    setDescTipo("");

    /* Deacuerdo al tipo de reporte , prepara configuracion para cada uno 
        ++ Data  = Parametros que se enviara en la peticion
        ++ Titulo = Titulo que se mostrara en archivo
        ++ Cabeceras = Cabeceras a utilizar {Tabla o excel}
        ++ PdfConfig = Configuracion para enviar al pdf
    */

    /*
                Estructura de PdfConfig 

          
            headers:["NO","CLAVE AREA","REPORTE GENERAL DEL AREA"],    === Cabeceras de las tablas (Rojo) 
                                                                            
            id:"idArea",  ===   Id que retorna la peticion , (Se mapea aparte de los datos generales)

            La estructura para el content es un arreglo que contiene arreglos de objetos 
            Estos arreglos representan una Fila  en el pdf 

            
                      content se establece como queremos mapear nuestro pdf  cuenta con los siguientes campos
                           ++header = Es como aparecera nuestro campo escrito en negritas, ejemplo NOMBRE DEL AREA, NOMBRE DEL USUARIO
                           ++value = Es el valor al que corresponde nuestra cabecera ejemplo: name, nameArea,nameBuilding
                              estos campos en el pdf se veran asi -> NOMBRE: JORGE
                              
                           ++comulms: es el numero de columnas que recibira el pdf, puede recibi un maximo de 3 y puede abarcar solo una o las 3 columnas
                        
        contentt:[
        [ 
          {  header:"CURP",value:"curp",columns:2},
          {  header:"TEL.EMPLEO",value:"jobNumber",columns:1},
        ],
        [
          {  header:"GENERO",value:"nameGender",columns:1},
          {  header:"ESTADO CIVIL",value:"nameTypeMarital",columns:1},
          {  header:"DISCAPACIDAD",value:"nameDisability",columns:1}
        ],
        [
          {header:"LUGAR DE NACIMIENTO",value:"nacidoEn",columns:3}
        ], 

        ]

        El ejemplo anterior se veria de la siguiente forma

        CURP: KLASJDKLJKLASLK                               TEL.EMPLEO: 7178981289

        GENERO: MASCULINO        ESTADO CIVIL: CADASO       DISCAPACIDAD: NINGUNA

        LUGAR DE NACIMIENTO: CALLE MONTES DE OCA , COLONIA ORTIZ, ESTADO DE MEXICO

     */
    if (reportConfig.idReport === "1" || reportConfig.idReport === 1) {
      //Paso 1 titulo, cabeceras
      setTitle("Reporte de Plantilla laboral");
      setCabeceras(cabecerasUsuario);
      setPdfConfig(pdfConfigGeneral);
      //Paso 2 preparamos parametros para el endpoint
      setData({
        action: "report1",
        /* page: "0",//OPCIONALES, LOS RECIBE PARA DATATABLE, PARA EXCEL Y PDF SON OPCIONALES
        records: "500",//OPCIONALES, LOS RECIBE PARA DATATABLE, PARA EXCEL Y PDF SON OPCIONALES */
        search: "",
        order: "name desc",
      });
    } else if (reportConfig.idReport === "2" || reportConfig.idReport === 2) {
      /* const resultado = areas.find( area => area.id === reportConfig.idArea ); */
      setTitle("Reporte de organigrama");
      const are = areas.find(el => el.idArea === reportConfig.idArea);
      setDescTipo(are !== undefined? are.name : "");
      setCabeceras([
        "CLAVE DE LA UNIDAD DE TRABAJO",
        "NOMBRE",
        "EMAIL",
        "EDIFICIO",
        "DOMICILIO",
        "LATITUD",
        "LONGITUD",
      ]);
      setPdfConfig({
        headers: ["No", "CLAVE UNIDAD DE TRABAJO", "REPORTE GENERAL DEL UNIDAD DE TRABAJO"],
        id: {id: "idArea"},
        title: "REPORTE DE ORGANIGRAMA",
        filtro: "FILTRO FALSO DE MOMENTO",
        orientation: "h",
        content: [
          [
            { header: "NOMBRE", value: "name", columns: 2 },
            { header: "LONGITUD", value: "longitude", columns: 1 },
          ],
          [
            { header: "EDIFICIO", value: "nameBuilding", columns: 2 },
            { header: "LATITUD", value: "latitude", columns: 1 },
          ],
          [{ header: "EMAIL", value: "email", columns: 3 }],
          [{ header: "DOMICILIO", value: "domicilio", columns: 3 }],
        ],
      });
      setData({
        action: "report2",
        search: "",
        order: "name desc",
        idArea: reportConfig.idArea,
      });
    } else if (reportConfig.idReport === "3" || reportConfig.idReport === 3) {
      setTitle("Reporte de cumpleaños");
      const mes = months.find(el=>el.id === reportConfig.idMonth);
      setDescTipo(mes !== undefined? mes.Month : "");
      setCabeceras(cabecerasUsuario);
      setPdfConfig(pdfConfigGeneral);
      setData({
        action: "report3",
        search: "",
        order: "name desc",
        month: reportConfig.idMonth,
      });
    } else if (reportConfig.idReport === "4" || reportConfig.idReport === 4) {
      setTitle("Reporte de plantilla de honorarios");
      setCabeceras(cabecerasUsuario);
      setPdfConfig(pdfConfigGeneral);
      setData({
        action: "report4", //numero de reporte en la BD
        search: "",
        order: "name desc", //OPCIONAL
      });
    } else if (reportConfig.idReport === "5" || reportConfig.idReport === 5) {
      setTitle("Reporte de plantilla de servicio social");
      setCabeceras(cabecerasUsuario);
      setPdfConfig(pdfConfigGeneral);
      setData({
        action: "report5", //numero de reporte en la BD
        /* page: "0",//OPCIONALES, LOS RECIBE PARA DATATABLE, PARA EXCEL Y PDF SON OPCIONALES
                records: "10",//OPCIONALES, LOS RECIBE PARA DATATABLE, PARA EXCEL Y PDF SON OPCIONALES */
        search: "",
        order: "name desc", //OPCIONAL
      });
    } else if (+reportConfig.idReport === 18) {
      setTitle("Reporte de movimientos de personal");
      setCabeceras([
        "FECHA",
        "NO. EMPLEADO",
        "NOMBRE",
        "ADSCRIPCION",
        "PUESTO",
        "NIVEL RANGO",
        "MOVIMIENTO",
        "ID PLAZA ACTUAL",
        "INICIO PLAZA",
        "TERMINO PLAZA",
        "SUELDO NETO",
        "ID PLAZA ANTERIOR",
        "ADSCRIPCION ANTERIOR",
        "PUESTO ANTERIOR",
        "NIVEL RANGO ANTERIOR",
        "INICIO PLAZA ANTERIOR",
        "TERMINO PLAZA ANTERIOR",
        "SUELDO NETO ANTERIOR",
      ]);
      setPdfConfig({
        headers: ["No", "FECHA", "REPORTE GENERAL DE EMPLEADO"],
        id: {id:"fecha",format: "fecha"},
        /* idFormat: {id:"fecha", format: "fecha"}, */
        orientation: "h", 
        content: [
          [
            { header: "NOMBRE", value: "nameStaff", columns: 2 },
            { header: "NO. EMPLEADO", value: "staffNumber", columns: 1 },
          ],
          [
            { header: "ID PLAZA ACTUAL", value: "actualID", columns: 2 },
            { header: "MOVIMIENTO", value: "nameMove", columns: 1 },
          ],
          [
            { header: "ADSCRIPCION", value: "nameArea", columns: 2 },
            { header: "NIVEL RANGO", value: "levelrange", columns: 1 },
          ],
          [
            { header: "PUESTO", value: "nameJob", columns: 2 },
            { header: "SUELDO NETO", value: "net", columns: 1, format: "moneda",
          },
          ],
          [
            { header: "INICIO PLAZA", value: "startDate", columns: 1, format: "fecha" },
            { header: "TERMINO PLAZA", value: "endDate", columns: 1,format: "fecha" },
          ],
          [
            {
              header: "ADSCRIPCION ANTERIOR",
              value: "areaAnterior",
              columns: 2,
            },
            {
              header: "NIVEL RANGO ANTERIOR",
              value: "levelrangeAnterior",
              columns: 1,
            },
          ],
          [
            { header: "PUESTO ANTERIOR", value: "puestoAnterior", columns: 2 },
            {
              header: "SUELDO NETO ANTERIOR",
              value: "sueldoAnterior",
              columns: 1,
              format: "moneda"
            },
          ],
          [
            {
              header: "INICIO PLAZA ANTERIOR",
              value: "startAnterior",
              columns: 1,
              format: "fecha"
            },
            {
              header: "TERMINO PLAZA ANTERIOR",
              value: "endDateAnterior",
              columns: 1,
              format: "fecha"
            },
          ],
          [{ header: "ID PLAZA ANTERIOR", value: "beforeID", columns: 2 }],
        ],
      });
      setData({
        action: "report18",
        search: "",
        order: "",
        initialDate: formatDate(reportConfig.initialDate),
        finalDate: formatDate(reportConfig.finalDate),
      });
      setDescTipo(
        `Del ${formatDate(reportConfig.initialDate)} al ${formatDate(
          reportConfig.finalDate
        )}`
      );
    } else if (+reportConfig.idReport === 19) {
      setTitle("Empleados con Discapacidad");
      setCabeceras([
        "NO EMPLEADO",
        "NOMBRE",
        "ADSCRIPCION",
        "PUESTO",
        "NIVEL-RANGO",
        "TIPO DISCAPACIDAD",
      ]);
      setPdfConfig({
        headers: ["No", "NUM EMPLEADO", "REPORTE GENERAL DE EMPLEADO"],
        id: {id: "staffNumber"},
        content: [
          [
            { header: "NOMBRE", value: "staffName", columns: 2 },
            {
              header: "TIPO DISCAPACIDAD",
              value: "nameDisability",
              columns: 1,
            },
          ],
          [{ header: "ADSCRIPCION", value: "nameArea", columns: 3 }],
          [
            { header: "PUESTO", value: "nameJob", columns: 2 },
            { header: "NIVEL-RANGO", value: "levelRange", columns: 1 },
          ],
        ],
      });
      setData({
        action: "report19",
        search: "",
        order: "",
      });
    }
  };

  function formatDate(date) {
    return date.split("-").reverse().toString().replaceAll(",", "/");
  }

  const {
    handleSubmit,
    handleBlur,
    values,
    errors,
    touched,
    setFieldValue,
    resetForm,
  } = useFormik({
    initialValues: defaultForm,
    onSubmit: (values) => {
      getData(values);
    },
    validationSchema: FormSchema,
    enableReinitialize: true,
  });

  // Limpiar campos especiales cuando cambie el reporte seleccionado
  useEffect(() => {
    if (values.idReport !== "") {
      setFieldValue("idArea", "");
      setFieldValue("idMonth", "");
      setFieldValue("initialDate", "");
      setFieldValue("finalDate", "");
    }
  }, [values.idReport]);

  /* llena el select de areas si se selecciona plantilla */
  useEffect(() => {
    if (values.idReport === "2" || values.idReport === 2) {
      getAreas();
    }
  }, [values.idReport]);

  useEffect(() => {
    if (data !== "") {
      /* Si no es html trae los datos para pdf o excel  */
      if (values.idFormat.toString() !== "3") {
        //AQUI DIFERENTE DE 3 POR QUE LA OPCION 3 ES EL DATATABLE

        // DATA es como se forma la peticion para el getReport
        getReport(data);
        setModal(true);
        setModalReports(true);
      }

      /* show report format  es la que permite en la vista si se ve html , pdf o excel */
      setShowReportFormat(values.idFormat.toString());
    }
  }, [data]);

  /* Se usa para llamar al excel */
  useEffect(() => {
    if (report !== undefined && showReportFormat === "2") {
      setShowReportFormat("0");
      saveAsExcel(report, cabeceras, title, pdfConfig, descTipo);
    }
  }, [report]);

  const CloseBtn = (
    <i className="eva eva-close cursor-pointer" onClick={toggle} />
  );

  return (
    <>
      <Widget className="widget-p-md">
        <form onSubmit={handleSubmit} className="mt-4" noValidate>
          <div className="headline-1 text-center mb-4">Reportes personal</div>

          <Row className="d-flex justify-content-center">
            <Col xs={12} md={12} lg={5}>
              <SelectWithSearchInput
                label="Seleccione el reporte a generar"
                inputOptions={typeReports}
                inputName="idReport"
                onChangeMethod={(options) =>
                  {
                    setFieldValue("idReport", options.value)
                    setFieldValue("idArea", "")
                    setFieldValue("idMonth", "")
                    setFieldValue("initialDate", "")
                    setFieldValue("finalDate", "")
                    setShowReportFormat("0")
                  }
                }
                onBlurMethod={handleBlur}
                touched={touched.idReport}
                errors={errors.idReport}
                optionValue="idReport"
                optionName="nameReport"
                inputValue={values.idReport}
                isRequired
              />
            </Col>
            <Col xs={12} md={12} lg={5}>
              <SelectWithSearchInput
                label="Seleccione un formato"
                inputOptions={format}
                inputName="idFormat"
                onChangeMethod={(options) =>
                  {
                    setFieldValue("idFormat", options.value)
                    setShowReportFormat("0")
                  }
                  
                }
                onBlurMethod={handleBlur}
                touched={touched.idFormat}
                errors={errors.idFormat}
                optionValue="id"
                optionName="value"
                inputValue={values.idFormat}
                /* isDisabled={data ? true : false} */
                isRequired
              />
            </Col>
          </Row>
          {values.idReport === "2" ? (
            <Row className="d-flex justify-content-around">
              <Col xs={12} md={12} lg={10}>
                <SelectWithSearchInput
                  label="Selecicone un area"
                  inputName="idArea"
                  inputOptions={areas}
                  onChangeMethod={(options) =>
                    {
                      setFieldValue("idArea", options.value)
                      setShowReportFormat("0")
                    }
                  }
                  onBlurMethod={handleBlur}
                  isRequired
                  touched={touched.idArea}
                  errors={errors.idArea}
                  optionValue="idArea"
                  optionName="name"
                  inputValue={values.idArea}
                />
              </Col>
            </Row>
          ) : values.idReport === "3" ? (
            <Row className="d-flex justify-content-around">
              <Col xs={12} md={12} lg={10}>
                <SelectWithSearchInput
                  label="Seleccione un mes"
                  inputOptions={months}
                  inputName="idMonth"
                  onChangeMethod={(options) =>
                    {
                      setFieldValue("idMonth", options.value)
                      setShowReportFormat("0")
                    }
                    
                  }
                  onBlurMethod={handleBlur}
                  touched={touched.idMonth}
                  errors={errors.idMonth}
                  optionValue="id"
                  optionName="Month"
                  inputValue={values.idMonth}
                  isRequired
                />
              </Col>
            </Row>
          ) : (
            values.idReport === "18" && (
              <Row className="d-flex justify-content-center">
                <Col xs={12} lg={5}>
                  <TextInput
                    label="Fecha inicial"
                    inputType="date"
                    inputName="initialDate"
                    onChangeMethod={onChange}
                    onBlurMethod={handleBlur}
                    value={values.initialDate}
                    isRequired
                    touched={touched.initialDate}
                    errors={errors.initialDate}
                  />
                </Col>
                <Col xs={12} lg={5}>
                  <TextInput
                    label="Fecha final"
                    inputType="date"
                    inputName="finalDate"
                    onChangeMethod={onChange}
                    onBlurMethod={handleBlur}
                    value={values.finalDate}
                    isRequired
                    touched={touched.finalDate}
                    errors={errors.finalDate}
                    isMin={values.initialDate}
                    isDisabled={values.initialDate === ""}
                  />
                </Col>
              </Row>
            )
          )}

          <div className="text-center my-3">
            <Button color="success" type="submit">
              Generar
            </Button>
          </div>
        </form>
        <FullFormLoader show={loader} message="Consultando datos , espere un momento"/>

        {/* Muestra la tabla  en caso de que formato sea 3 */}
        {showReportFormat === "3" && title !== "" ? (
          <TableComponent
            titulo={title}
            cabecerasTabla={cabeceras}
            parametros={data}
            filtro={pdfConfig}
            reporte={values.idReport}
          />
        ) : /* Muestra pdf en caso de que el tipo sea  1 */
        showReportFormat === "1" && report !== undefined ? ( 
          <ModalReports
          modal={modalReports}
          setModal={setModalReports}
          title={title}
          backdrop={"static"}
          keyboard={false}
          report={report}
          pdfConfig={pdfConfig}
          generatePdfMethod={
            {
              method:  generatePdfGeneral,
              type:"general"
            }
          }
          records={1000}
        />
        ) : (
          ""
        )}
      </Widget>
    </>
  );
};
export default ReportesPersonal;

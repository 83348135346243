import React, { useEffect, useState } from "react";
import { Row, Col, Button } from "reactstrap";
import ModalReports from "../../../../components/Modal/ModalReports";
import { peticionesReceiver } from "../../../../helpers/peticionesReceiver";
import { toast } from "react-toastify";
import Notification from "../../../../components/Notification/Notification";
import FullFormLoader from "../../../../components/Loader/FullFormLoader";
import { generatePdfBienesPersona } from "./PdfBienesPersona";
import { saveAsExcel } from "../../../../components/excel/Excel";

const ReportesBienes = (props) => {
  const { obj } = props;
  const API = peticionesReceiver();
  const [modalReports, setModalReports] = useState(false);
  const [report, setReport] = useState("");
  const [loader, setLoader] = useState(false);
  const [pdfConfig, setPdfConfig] = useState({
    /* "A.idInventory,CONCAT(H.name,' ',H.pName,' ',H.mName) as nameStaff,inventorykey,serialkey,
      CONCAT(nameFurniture,' Marca: ',brand,' Modelo: ',model) as nameFurniture,CONCAT(nameGroup,'\n',nameSubGroup,'\n',nameClass) as setup,
      nameStat,actualPrice,I.name as nameArea", */
    id: { id: "nameStaff" },
    /* filters: {
        message: `Del periodo: ${reportConfig.period}/${reportConfig.year}`,
        onlyMessage: true,
      }, */
    headers: [
      "EMPLEADO",
      "NUMERO DE INVENTARIO",
      "NUMERO DE SERIE",
      "DENOMINACION",
      "ADSCRIPCION DE RESGUARDO",
      "ESTATUS",
      "PRECIO",
      "AREA",
    ],
    content: [
      [
        { header: "NUMERO DE INVENTARIO", value: "inventorykey" },
        { header: "NUMERO DE SERIE", value: "serialkey" },
        { header: "DENOMINACION", value: "setup" },
        { header: "ADSCRIPCION DE RESGUARDO", value: "nameFurniture" },
        { header: "ESTATUS", value: "nameStat" },
        { header: "PRECIO", value: "actualPrice", format: "moneda"},
        { header: "AREA", value: "nameArea" },
      ],
    ],
  });

  const getReport = async() => {
    setReport("")
    const params =
      obj.type === "staff"
        ? {
          action: "datatable",
          table:
            "assignment B INNER JOIN inventories A ON (A.idInventory = B.idInventory and B.enabled=1) INNER JOIN furnitures C USING (idFurniture) INNER JOIN status D using (keyStat) INNER JOIN furniture_classes E USING (keyClass) inner join furniture_subgroups F USING (keySubGroup) inner join furniture_groups G USING (keyGroup) INNER JOIN staff H ON (H.idStaff = B.idStaff) LEFT JOIN areas I ON (I.idArea = B.idArea)",
          rows: "B.idAssignment,CONCAT(H.name,' ',H.pName,' ',H.mName) as nameStaff,inventorykey,serialkey,CONCAT(nameFurniture,' Marca: ',brand,' Modelo: ',model) as nameFurniture,CONCAT(nameGroup,'\n',nameSubGroup,'\n',nameClass) as setup,nameStat,actualPrice,I.name as nameArea",
          conditions: `A.enabled =1 and B.enabled = 1 and H.idStaff = ${obj.id}`,
          page: 0,
          records: 5,
          search: "",
          order: "",
        }
      : {
          action: "datatable",
          table:
            "assignment B INNER JOIN inventories A ON (A.idInventory = B.idInventory and B.enabled=1) INNER JOIN furnitures C USING (idFurniture) INNER JOIN status D using (keyStat) INNER JOIN furniture_classes E USING (keyClass) inner join furniture_subgroups F USING (keySubGroup) inner join furniture_groups G USING (keyGroup) INNER JOIN staff H ON (H.idStaff = B.idStaff) LEFT JOIN areas I ON (I.idArea = B.idArea)",
          rows: "B.idAssignment, CONCAT(H.name,' ',H.pName,' ',H.mName) as nameStaff,inventorykey,serialkey,CONCAT(nameFurniture,' Marca: ',brand,' Modelo: ',model) as nameFurniture,CONCAT(nameGroup,'\n',nameSubGroup,'\n',nameClass) as setup,nameStat,actualPrice,I.name as nameArea",
          conditions: `A.enabled = 1 and B.enabled = 1 and B.idArea= ${obj.id}`,
          page: 0,
          records: 5,
          search: "",
          order: "",
        }
  
    let report = null;
    await API.peticion(params)
      .then((res) => {
        console.log("RESPUESTA",res)
        if (res.status === 200 && res.data.code === "200" && res.data.message === "Se ejecuto correctamente la consulta")  {
          setReport(res.data.data);
          report = res.data.data
        } else {
          toast(
            <Notification
              type={"consultar_error"}
              backMessage={res.data.message}
              withIcon
            />,
            { closeButton: false }
          );
        }
      })
      .finally(() => {
        setLoader(false);
      })
      .catch((err) => {
        toast(<Notification type={"consultar_servidor_error"} withIcon />, {
          closeButton: false,
        });
      });


      return report
  };

  const generatePdf = async() => {
    const rep = await getReport();
    if(rep !== null && rep !== undefined && rep !== ""){
      setModalReports(true);
    }
  };

  /* useEffect(() => {
    if(report !== "" && !modalReports){
      
    }
  },[report]) */

  /* useEffect(() => {
    if(report !== "" && !modalReports){
      saveAsExcel(
        report,
        pdfConfig.headers,
        "REPORTES DE BIENES POR PERSONA",
        pdfConfig,
        ""
      );
    }
  },[report]) */
  const generateExcel = async() => {
    const rep = await getReport();
    if(rep !== null && rep !== undefined && rep !== ""){
      saveAsExcel(
        rep,
        pdfConfig.headers,
        "REPORTES DE BIENES POR PERSONA",
        pdfConfig,
        ""
      );
    }
    
  };

  return (
    <div>
      <Row className="mt-5 d-flex justify-content-around">
        <Col xs="12" lg="4" className="text-center mt-3 mb-3">
          <Button
            color="success"
            onClick={() => {
              generatePdf();
            }}
          >
            Generar Pdf
          </Button>
        </Col>
        <Col xs="12" lg="4" className="text-center mt-3 mb-3">
          <Button
            color="success"
            onClick={() => {
              generateExcel()
            }}
          >
            Generar Excel
          </Button>
        </Col>
      </Row>
      <FullFormLoader
        show={loader}
        message="Consultando datos , espere un momento"
      />

      {modalReports && report !== "" && (
        <ModalReports
          modal={modalReports}
          setModal={setModalReports}
          title={"BIENES POR PERSONA"}
          backdrop={"static"}
          keyboard={false}
          report={report}
          pdfConfig={pdfConfig}
          generatePdfMethod={{
            method: generatePdfBienesPersona,
            type: "general",
          }}
          records={1000}
        />
      )}
    </div>
  );
};

export default ReportesBienes;

import { Button, Col, Row, Spinner } from "reactstrap";
import { SelectTypeHeadSingle } from "../../../../components/GenericInputsFormik";
import { PERIOD } from "../../calculo/regex";
import { useFormik } from "formik";
import * as Yup from "yup"
import { YearInput } from "../../../../components/GenericInputsFormik/YearInput";
import Widget from "../../../../components/Widget/Widget";
import InputWithModal from "../../../../components/GenericInputsFormik/InputWithModal";
import ModalConfirmation from "../../../../components/Modal/ModalConfirmation";
import { useEffect, useState } from "react";
import { peticionesReceiver } from "../../../../helpers/peticionesReceiver";
import { toast } from "react-toastify";
import Notification from "../../../../components/Notification/Notification";
import Datatable from "../../../../components/Datatable/Datatable";
import ModificarPagoTimbrado from "../ModifcarPagoTimbrado"
import FullFormLoader from "../../../../components/Loader/FullFormLoader";

export default function AgregarLayout({ setAdd, setRefreshMain }) {
    const API = peticionesReceiver()

    const [modal, setModal] = useState(false)

    const [empleados] = useState([])
    const [actEmpleados, setActEmpleados] = useState([])
    const [refresh, setRefresh] = useState(false);

    const [modificar, setModificar] = useState(false);

    const [tempData, setTempData] = useState(null);

    const [loader, setLoader] = useState(false);

    const cabeceras = [
        "#",
        "Núm. Empleado",
        "Nombre",
        "Puesto",
        "Seleccionar",
    ];
    const filtro = [
        true, true, true, true, true
    ]



    const defaultForm = {
        keyTypeStamp: "",
        period: "",
        year: "",
        idStaff: "",
        staffNumber: "",
        nameStaff: ""
    }

    const [typeStamps, setTypeStamp] = useState([])
    const [loadingTypeStamps, setLoadingTypeStamp] = useState(true)

    const FormSchema = Yup.object().shape({
        keyTypeStamp: Yup.string().required("Seleccione un tipo de layout de timbrado"),
        period: Yup.string().required("Ingrese un periodo valido"),
        year: Yup.string().required("Ingrese un año valido").nullable(),
        idStaff: Yup.string()
    })

    const { handleSubmit, values, handleBlur, errors, touched, setFieldValue, resetForm } =
        useFormik({
            initialValues: defaultForm,
            onSubmit: () => {
                setModal(true)
            },
            validationSchema: FormSchema,
        });

    const params = {
        action: "datatable",
        table: "staff STF INNER JOIN employtypes AS EMT ON STF.keyEmployType = EMT.keyEmployType INNER JOIN jobstaff AS JBS ON JBS.idStaff = STF.idStaff INNER JOIN areas AS AR ON AR.idArea = JBS.idArea INNER JOIN jobtypes AS JBT ON JBT.keyJobType = STF.keyEmployType INNER JOIN payslipsstamps pys on (STF.idStaff=pys.idStaff)",
        rows: "STF.idStaff,STF.staffNumber,CONCAT(STF.name,' ',STF.pName,' ',STF.mName) as fullName,JBT.nameJobType",
        conditions: `STF.enabled = 1 AND JBS.valid = 1 AND JBS.enabled = 1 AND pys.year=${new Date(values.year).getFullYear()} and pys.period=${values.period}`,
        group: "STF.idStaff",
        page: 0,
        records: 5,
        search: "",
        order: "",
    }

    const onChange = (e) => {
        const { name, value } = e.target
        if (name === "keyTypeStamp") {
            resetForm()
        }
        setFieldValue([name], value);
    }

    const actionSelect = (el) => {
        setFieldValue(["idStaff"], el[0]);
        setFieldValue(["staffNumber"], el[1]);
        setFieldValue(["nameStaff"], el[2]);
    }

    const clear = () => {
        setFieldValue(["idStaff"], "");
        setFieldValue(["staffNumber"], "");
        setFieldValue(["nameStaff"], "");
    }


    function getParams(rows) {
        if (rows.keyTypeStamp === "1" || rows.keyTypeStamp === "3") {
            return {
                action: "saveStampStaffs",
                rows: {
                    period: rows.period,
                    year: (new Date(rows.year)).getFullYear(),
                    keyTypeStamp: rows.keyTypeStamp,
                    nameTypeStamp: typeStamps.find(ts => ts.keyTypeStamp === rows.keyTypeStamp).nameTypeStamp
                }
            }
        } else {
            return {
                action: "saveStampStaffs",
                rows: {
                    period: rows.period,
                    year: (new Date(rows.year)).getFullYear(),
                    keyTypeStamp: rows.keyTypeStamp,
                    employes: actEmpleados.map(emp => { return { idStaff: emp.idStaff } }),
                    nameTypeStamp: typeStamps.find(ts => ts.keyTypeStamp === rows.keyTypeStamp).nameTypeStamp
                }
            }
        }
    }

    async function genLayout(rows) {
        setLoader(true);
        try {
            const res = await API.peticionEndPoint(getParams(rows), "app/facades/payslips/paystampF.php")
            if (res.status === 200 && res.data.code === "200") {
                toast(<Notification type={"agrega_exito"} backMessage={res.data.message} withIcon />);
                setRefreshMain(true)
                setAdd(false)
            } else {
                toast(<Notification type={"agrega_error"} backMessage={res.data.message} withIcon />);
            }
        } catch (err) {
            console.log(err)
            toast(<Notification type={"agrega_error"} withIcon />);
        }
        setLoader(false);
    }

    function modificarPagos(idStaff, nameStaff, staffNumber) {
        setTempData({ ...values, idStaff, nameStaff, staffNumber })
        setModificar(true)
    }

    const columns = [
        { data: "staffNumber" },
        { data: "nameStaff" },
        {
            data: null,
            render: function (row) {
                return (<i className="fa fa-pencil-square-o text-warning cursor-pointer" onClick={() => modificarPagos(row.idStaff, row.nameStaff, row.staffNumber)} />)
            }
        },
        {
            data: "idStaff",
            render: function (row) {
                return (<i className="fa fa-trash text-danger cursor-pointer" onClick={() => removeEmployee(row)} />)
            }
        }
    ];

    const columnDefs = [
        { targets: [0, 2, 3], className: "text-center" }
    ]

    const headers = [
        "No. Empleado",
        "Nombre",
        "Editar pago",
        "Eliminar"
    ];

    function addEmployee() {
        const findI = empleados.findIndex(emp => emp.idStaff === values.idStaff);
        if (findI === -1) {
            empleados.push({ idStaff: values.idStaff, staffNumber: values.staffNumber, nameStaff: values.nameStaff })
        }
        const keyTypeStamp = values.keyTypeStamp;
        const period = values.period;
        const year = values.year;
        resetForm()
        setFieldValue("keyTypeStamp", keyTypeStamp)
        setFieldValue("period", period)
        setFieldValue("year", year)
        setRefresh(true)
    }

    function removeEmployee(row) {
        const index = empleados.findIndex(aux => aux.idStaff === row)
        if (index !== -1) {
            setActEmpleados(empleados.splice(index, 1))
            setRefresh(true)
        }
    }

    const getTypeStamp = async () => {
        try {
            const res = await API.peticion({
                action: "multiselect",
                table: "types_stamps",
                rows: "keyTypeStamp,nameTypeStamp",
                conditions: `enabled=1`
            })
            if (res.status === 200 && res.data.code === "200") {
                setTypeStamp(res.data.data);
                setLoadingTypeStamp(false)
            } else {
                toast(<Notification type={"consultar_error"} backMessage={res.data.message} withIcon />);
            }
        } catch (err) {
            console.log(err)
            toast(<Notification type={"consultar_error"} withIcon />);
        }
    }

    useEffect(() => {
        getTypeStamp()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    if (loadingTypeStamps) {
        <div className="d-flex align-items-center justify-content-center" style={{ height: "200px" }}>
            <Spinner
                color="primary"
                style={{
                    height: '3rem',
                    width: '3rem'
                }}
                type="grow"
            >
                Loading...
            </Spinner>
        </div>
    }
    return (
        <>
            {!modificar ? (
                <>
                    <Widget>
                        <Button className="m-3" color="danger" onClick={() => setAdd(false)}>Regresar</Button>
                        <form onSubmit={handleSubmit}>
                            <Row className="mx-3">
                                <Col xs={12} md={4}>
                                    <SelectTypeHeadSingle
                                        label="Tipo de layout de timbrado"
                                        isRequired={true}
                                        inputName="keyTypeStamp"
                                        optionsArray={typeStamps}
                                        defaultOption="Seleccione un tipo"
                                        onChangeMethod={onChange}
                                        value={values.keyTypeStamp}
                                        errors={errors.keyTypeStamp}
                                        touched={touched.keyTypeStamp}
                                        optionValue="keyTypeStamp"
                                        optionName="nameTypeStamp"
                                    />
                                </Col>
                                <Col xs={12} md={4}>
                                    <SelectTypeHeadSingle
                                        label="Periodo"
                                        isRequired={true}
                                        inputName="period"
                                        optionsArray={PERIOD}
                                        defaultOption="Seleccione un periodo"
                                        onChangeMethod={onChange}
                                        value={values.period}
                                        errors={errors.period}
                                        touched={touched.period}
                                        isDisabled={actEmpleados.length>0}
                                        optionValue="value"
                                        optionName="label"
                                    />
                                </Col>
                                <Col xs={12} md={4}>
                                    <YearInput
                                        label="AÑO"
                                        inputName="year"
                                        onChangeMethod={onChange}
                                        onBlurMethod={handleBlur}
                                        value={values.year}
                                        touched={touched.year}
                                        errors={errors.year}
                                        isDisabled={actEmpleados.length>0}
                                        isRequired
                                    />
                                </Col>
                            </Row>
                            {values.keyTypeStamp === "2" && (
                                <Row className="mx-3">
                                    <Col>
                                        <InputWithModal
                                            cabeceras={cabeceras}
                                            error={errors.idStaff}
                                            filtro={filtro}
                                            handleBlur={handleBlur}
                                            onChange={onChange}
                                            actionSelect={actionSelect}
                                            onClear={clear}
                                            parametros={params}
                                            touched={touched.idStaff}
                                            value={values.nameStaff}
                                            label="Empleado"
                                            name={"idStaff"}
                                            title={"Seleccionar empleado"}
                                            disabled={values.period==="" || values.year === "" || values.year === null}
                                        />
                                    </Col>
                                </Row>
                            )}
                            {values.keyTypeStamp === "2" && (
                                <>
                                    <div className="text-center mb-3" key={"addTimb"}>
                                        <Button type="button" onClick={addEmployee}>
                                            <i className="fa fa-plus" />
                                        </Button>
                                    </div>
                                    <Datatable
                                        columns={columns}
                                        columnDefs={columnDefs}
                                        headers={headers}
                                        paging={false}
                                        searching={false}
                                        info={false}
                                        ordering={false}
                                        key={"empTimb"}
                                        data={empleados}
                                        stateItems={[actEmpleados, setActEmpleados]}
                                        stateRefresh={[refresh, setRefresh]}
                                    />
                                </>
                            )}
                            <div className="text-center pb-3">
                                <Button type="submit">Generar layout</Button>
                            </div>
                        </form>
                        <FullFormLoader show={loader}/>
                    </Widget>
                    <ModalConfirmation
                        modalTitle={"Generar layout"}
                        modal={modal}
                        crear={(rows) => genLayout(rows)}
                        setModal={setModal}
                        values={values}
                    >
                        <div className="d-flex justify-content-center">
                            <h6>¿Desea continuar con el registro?</h6>
                        </div>
                    </ModalConfirmation>
                </>
            ) : (
                <ModificarPagoTimbrado tempData={tempData} setModificar={setModificar} />
            )}
        </>
    )
}
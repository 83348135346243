import { Formik, Form, Field, ErrorMessage, useFormikContext } from "formik";
import * as Yup from "yup";
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Col, Row, Button, Alert } from "reactstrap";
import axios from "axios";
import { GoogleMap, useJsApiLoader, Marker } from "@react-google-maps/api";
import { toast } from "react-toastify";
import Notification from "../../../components/Notification/Notification";
import Loader from "../../../components/Loader/Loader";
import Geocode from "react-geocode";
import ModalConfirmation from "../../../components/Modal/ModalConfirmation";
import { peticionesReceiver } from "../../../helpers/peticionesReceiver";
import { SelectTypeHeadSingle } from "../../../components/GenericInputsFormik/SelectTypeHeadSingle";

const containerStyle = {
  width: "700px",
  height: "400px",
};

const centerMx = {
  lat: 20.0,
  lng: -100.0,
};

const defaultForm = {
  keyCity: "0",
  keyCounty: "",
  nameCity: "",
  economicKey: "",
  latitude: "",
  longitude: "",
};
const gmap = process.env.REACT_APP_GMAP;

const FormCiudad = ({ crear, data, editar }) => {
  const API = peticionesReceiver();
  const [municipios, setMunicipios] = useState([]);
  const [form, setForm] = useState(defaultForm);
  const [loading, setLoading] = useState(true);
  const history = useHistory();
  const [marker, setMarker] = useState();
  const [center, setCenter] = useState(centerMx);
  const [modal, setModal] = useState(false);

  const FormSchema = Yup.object().shape({
    keyCounty: Yup.string().required("Seleccione un municipio"),
    nameCity: Yup.string()
      .min(3, "El nombre debe contener al menos 3 caracteres")
      .max(200, "Nombre demasiado largo")
      .required("Ingrese un nombre"),
    economicKey: Yup.string()
      .min(3, "La clave debe tener al menos 3 caracteres")
      .max(200, "La clave es demasiado larga")
      .required("Ingrese una clave"),
    latitude: Yup.string().required("Busque o marque un punto en el mapa"),
    longitude: Yup.string().required("Seleccione un punto en el mapa"),
  });

  const getCounties = async () => {
    const params = {
      action: "select",
      table: "counties",
      condition: {
        enabled: 1,
      },
      order: "nameCounty ASC",
    };
    API.peticion(params)
      .then((res) => {
        if (res.status === 200 && res.data.code === "200") {
          let data = [];
          res.data.data.map((el) => {
            data.push({ keyCounty: el.keyCounty, nameCounty: el.nameCounty });
          });
          setMunicipios(data);
          setLoading(false);
        } else {
          toast(<Notification type={"consultar_error"} withIcon />);
        }
      })
      .catch((err) =>
        toast(<Notification type={"consultar_servidor_error"} withIcon />)
      );
  };

  useEffect(() => {
    getCounties();
    Geocode.setApiKey(gmap);
    Geocode.setLocationType("ROOFTOP");
    Geocode.setRegion("mx");
  }, []);

  const submitFunction = (fields) => {
    setModal(true);
  };

  const cancelOp = () => {
    history.push("/template/ciudades");
  };

  const onClick = (event) => {
    setMarker(event.latLng.toJSON());
  };

  const searchLocation = async (values) => {
    if (values.nameCity && values.keyCounty) {
      const address =
        values.nameCity + " " + findName(Number(values.keyCounty));
      Geocode.fromAddress(address).then(
        (response) => {
          if (response.results.length !== 0) {
            const location = response.results[0].geometry.location;
            setMarker(location);
            setCenter(location);
          } else {
            toast(<Alert color="danger">No se encontraron ubicaciones</Alert>);
          }
        },
        (error) => {
          toast(<Alert color="danger">No se encontraron ubicaciones</Alert>);
        }
      );
    } else {
      toast(
        <Alert color="danger">
          Ingrese el nombre de la localidad y un municipio
        </Alert>
      );
    }
  };

  function findName(id) {
    let name = "";
    for (const el of municipios) {
      if (el.keyCounty === id) {
        name = el.nameCounty;
        break;
      }
    }
    return name;
  }

  const renderMap = () => {
    return (
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        zoom={15}
        onClick={onClick}
      >
        <Marker position={marker} />
      </GoogleMap>
    );
  };

  const { isLoaded, loadError } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: gmap,
  });

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <Formik
            initialValues={form}
            validationSchema={FormSchema}
            onSubmit={(fields, { resetForm }) => {
              submitFunction(fields);
            }}
            onReset={(fields, { resetForm }) => {
              cancelOp();
            }}
          >
            {({
              errors,
              status,
              touched,
              values,
              setFieldValue,
              handleBlur,
            }) => (
              <Form className="mt-4">
                <ModalConfirmation
                  modalTitle={data ? "Editar" : "Crear"}
                  modal={modal}
                  setModal={setModal}
                  crear={crear}
                  editar={editar}
                  isEdit={data ? true : false}
                  values={values}
                >
                  {data ? (
                    <div className="d-flex justify-content-center">
                      <h6>¿Está seguro de editar el registro?</h6>
                    </div>
                  ) : (
                    <div className="d-flex justify-content-center">
                      <h6>¿Desea continuar con el registro?</h6>
                    </div>
                  )}
                </ModalConfirmation>
                <Row>
                  <Col xs="12" md="4">
                    <div className="form-group">
                      <label htmlFor="nameCity">
                        Nombre<span className="text-danger">*</span>
                      </label>
                      <Field
                        name="nameCity"
                        type="text"
                        className={
                          "form-control" +
                          (errors.nameCity && touched.nameCity
                            ? " is-invalid"
                            : "")
                        }
                      />
                      <ErrorMessage
                        name="nameCity"
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>
                  </Col>
                  <Col xs="12" md="4">
                    <div className="form-group">
                      <label htmlFor="economicKey">
                        Clave INEGI<span className="text-danger">*</span>
                      </label>
                      <Field
                        name="economicKey"
                        type="text"
                        className={
                          "form-control" +
                          (errors.economicKey && touched.economicKey
                            ? " is-invalid"
                            : "")
                        }
                      />
                      <ErrorMessage
                        name="economicKey"
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>
                  </Col>
                  <Col xs="12" md="4">
                    <SelectTypeHeadSingle
                      label="Municipio"
                      inputName="keyCounty"
                      onChangeMethod={(e) =>
                        setFieldValue([e.target.name], e.target.value)
                      }
                      onBlurMethod={handleBlur}
                      value={values.keyCounty}
                      isRequired={true}
                      touched={touched.keyCounty}
                      errors={errors.keyCounty}
                      optionsArray={municipios}
                      optionValue="keyCounty"
                      optionName="nameCounty"
                      defaultOption="Seleccione un municipio"
                    />
                  </Col>
                </Row>

                <Row>
                  <Col xs="12" md="6">
                    <div className="form-group">
                      {/* <label htmlFor="latitude">Latitud</label> */}
                      <Field
                        name="latitude"
                        disabled
                        type="hidden"
                        className={
                          "form-control" +
                          (errors.latitude && touched.latitude
                            ? " is-invalid"
                            : "")
                        }
                      />
                    </div>
                  </Col>
                  <Col xs="12" md="6">
                    <div className="form-group">
                      {/* <label htmlFor="longitude">Longitud</label> */}
                      <Field
                        name="longitude"
                        disabled
                        type="hidden"
                        className={
                          "form-control" +
                          (errors.longitude && touched.longitude
                            ? " is-invalid"
                            : "")
                        }
                      />
                    </div>
                  </Col>
                </Row>

                <div className="d-flex justify-content-center">
                  <Button
                    color="info"
                    onClick={() => searchLocation(values)}
                    className="position-relative"
                  >
                    <i
                      className="eva eva-pin-outline"
                      style={{ fontSize: "20px", verticalAlign: "top" }}
                    />
                    Buscar ubicación
                  </Button>
                </div>
                <div className="mt-4 text-center">
                  <p
                    className={
                      "label " +
                      (errors.longitude && touched.longitude
                        ? "text-danger"
                        : "")
                    }
                  >
                    Identifique o marque un punto en el mapa haciendo clic sobre
                    el.
                  </p>
                </div>
                <div className="d-flex justify-content-center mt-1">
                  {loadError ? (
                    <div>Ocurrio un error al cargar el mapa</div>
                  ) : isLoaded ? (
                    renderMap()
                  ) : (
                    <Loader />
                  )}
                </div>

                <div className=" d-flex flex-sm-row flex-column-reverse justify-content-sm-around mt-5">
                  <Button color="danger" type="reset" className="mt-3">
                    Cancelar
                  </Button>
                  <Button
                    color="success"
                    type="submit"
                    className="mt-2 mt-sm-3"
                  >
                    Guardar
                  </Button>
                </div>
                <FormikMap marker={marker} />
                <FormikData
                  info={data}
                  setMarker={setMarker}
                  setCenter={setCenter}
                />
              </Form>
            )}
          </Formik>
        </>
      )}
    </>
  );
};

const FormikData = ({ info, setMarker, setCenter }) => {
  const { setFieldValue } = useFormikContext();
  useEffect(() => {
    if (info) {
      setFieldValue("keyCity", info[0]);
      setFieldValue("keyCounty", info[6]);
      setFieldValue("nameCity", info[1]);
      setFieldValue("economicKey", info[2]);
      setFieldValue("latitude", info[4]);
      setFieldValue("longitude", info[5]);
      if (info[4] && info[5]) {
        setMarker({ lat: Number(info[5]), lng: Number(info[6]) });
        setCenter({ lat: Number(info[5]), lng: Number(info[6]) });
      }
    }
  }, [info]);
  return null;
};

const FormikMap = ({ marker }) => {
  const { setFieldValue } = useFormikContext();
  useEffect(() => {
    if (marker) {
      setFieldValue("latitude", marker.lat);
      setFieldValue("longitude", marker.lng);
    }
  }, [marker]);
  return null;
};

export default FormCiudad;

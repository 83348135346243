import { useEffect, useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import ModalConfirmation from "../../../components/Modal/ModalConfirmation";
import { validarRFC } from "../../../libs/personalData/RFC";
import { Button, Col, Row } from "reactstrap";
import {
  SelectTypeHeadSingle,
  TextInput,
} from "../../../components/GenericInputsFormik";
import { peticionEncript } from "../../../helpers/peticionesEncripted";
import axios from "axios";
import Notification from "../../../components/Notification/Notification";
import { toast } from "react-toastify";
import { doRequest } from "../../../helpers/requests";
import { NumberInput } from "../../../components/GenericInputsFormik/NumberInput";
import FullFormLoader from "../../../components/Loader/FullFormLoader";

const defaultForm = {
  idProvider: "",
  keyTypeProvider: "",
  socialName: "",
  itin: "",
  telephone: "",
  email: "",
  webPage: "",
};

Yup.addMethod(Yup.string, "rfc", function (errorMessage) {
  return this.test("rfc_valid", errorMessage, function (value) {
    const { path, createError } = this;
    return (
      (value && validarRFC(value)) ||
      createError({ path, message: errorMessage })
    );
  });
});

const FormSchema = Yup.object().shape({
  keyTypeProvider: Yup.string().required("Seleccione un tipo de persona"),
  socialName: Yup.string()
    .required("Ingrese una razón social")
    .max(200, "Cantidad de caracteres debe ser menoir a 200"),
  itin: Yup.string().required("Ingrese un RFC").rfc("Ingrese un RFC valido"),
  telephone: Yup.string()
    .required("Ingrese un número de teléfono")
    .length(10, "Número de teléfono invalido"),
  email: Yup.string()
    .required("Ingrese un correo electrónico")
    .email("Correo electrónico invalido"),
  webPage: Yup.string().matches(
    /(https?:\/\/(?:[wW]{3}\.|(?![wW]{3}))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|[wW]{3}\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:[wW]{3}\.|(?![wW]{3}))[a-zA-Z0-9]+\.[^\s]{2,}|[wW]{3}\.[a-zA-Z0-9]+\.[^\s]{2,})/,
    "Url invalida"
  ),
});

const Informacion = ({
  setActiveTab,
  provider,
  setProvider,
  typesProv,
  permissions,
  contratist
}) => {
  const [modalConfirm, setModalConfirm] = useState(false);
  const [form, setForm] = useState(defaultForm);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (provider.id !== 0) getProvider();
    else setForm(defaultForm);
  }, []);

  const {
    handleSubmit,
    values,
    handleBlur,
    errors,
    touched,
    setFieldValue,
    handleReset,
  } = useFormik({
    initialValues: form,
    validationSchema: FormSchema,
    onSubmit: (values) => {
      if (JSON.stringify(values) !== JSON.stringify(form)) {
        setModalConfirm(true);
      }
    },
    enableReinitialize: true,
  });

  const getProvider = async () => {
    const params = {
      action: "select",
      table: "providers",
      condition: { idProvider: provider.id },
    };
    setLoading(true);
    const data = await doRequest("receiver/receiver.php", params);
    if (data.length > 0) {
      setForm({
        idProvider: data[0].idProvider || "",
        socialName: data[0].socialName || "",
        itin: data[0].itin || "",
        telephone: data[0].telephone || "",
        email: data[0].email || "",
        keyTypeProvider: data[0].keyTypeProvider || "",
        webPage: data[0].webPage || "",
      });
    } else {
      setProvider({ id: 0 });
      setActiveTab(1);
    }
    setLoading(false);
  };

  const onChange = (e) => {
    if (permissions.INS || (permissions.UPD && provider.id !== 0))
      setFieldValue(e.target.name, e.target.value);
  };

  const editInfo = async () => {
    const params = {
      action: "insert",
      table: "providers",
      rows: { ...values, contratist: contratist },
      validate: [
        ["socialName"],
        ["itin"],
        ["socialName", "itin", "telephone", "email"],
      ],
    };
    if (values.idProvider !== "") {
      params.condition = { idProvider: values.idProvider };
      params.action = "update";
    }
    setLoading(true);
    const data = await doRequest("receiver/receiver.php", params, true);
    if (data.length > 0) {
      const p = data[0];
      setForm({
        idProvider: p.idProvider,
        keyTypeProvider: p.keyTypeProvider,
        socialName: p.socialName,
        itin: p.itin,
        telephone: p.telephone,
        email: p.email,
        webPage: p.webPage,
      });
      if (values.idProvider === "") {
        setActiveTab(3);
        setProvider({id: p.idProvider, change: true});
      }
    }
    setLoading(false);
  };

  const exitForm = () => {
    if (values.idProvider === "") setActiveTab(1);
    else {
    }
  };

  return (
    <div className="mx-4">
      <form onSubmit={handleSubmit} onReset={handleReset} className="mt-2">
        <ModalConfirmation
          modalTitle="Guardar proveedor"
          modal={modalConfirm}
          setModal={setModalConfirm}
          editar={() => editInfo()}
          isEdit={true}
        >
          <div className="d-flex justify-content-center text-center">
            <h6>
              {values.idProvider === ""
                ? "¿Esta seguro de guardar este proveedor?"
                : "¿Está seguro de actualizar la información de este proveedor?"}
            </h6>
          </div>
        </ModalConfirmation>
        <Row>
          <Col xs={12} lg={4}>
            <TextInput
              label="Razón Social"
              inputType="text"
              inputName="socialName"
              onChangeMethod={onChange}
              onBlurMethod={handleBlur}
              value={values.socialName}
              touched={touched.socialName}
              errors={errors.socialName}
              isRequired
            />
          </Col>
          <Col xs={12} lg={4}>
            <TextInput
              label="RFC"
              inputType="text"
              inputName="itin"
              onChangeMethod={onChange}
              onBlurMethod={handleBlur}
              value={values.itin}
              touched={touched.itin}
              errors={errors.itin}
              isRequired
            />
          </Col>
          <Col xs={12} lg={4}>
            <NumberInput
              label="Teléfono"
              inputType="text"
              inputName="telephone"
              onChangeMethod={onChange}
              onBlurMethod={handleBlur}
              value={values.telephone}
              touched={touched.telephone}
              errors={errors.telephone}
              isRequired
              maxLength={10}
              disableSep
            />
          </Col>
        </Row>

        <Row>
          <Col xs={12} lg={4}>
            <TextInput
              label="Correo"
              inputType="text"
              inputName="email"
              onChangeMethod={onChange}
              onBlurMethod={handleBlur}
              value={values.email}
              touched={touched.email}
              errors={errors.email}
              isRequired
            />
          </Col>
          <Col xs={12} lg={4}>
            <TextInput
              label="Página Web"
              inputType="text"
              inputName="webPage"
              onChangeMethod={onChange}
              onBlurMethod={handleBlur}
              value={values.webPage}
              touched={touched.webPage}
              errors={errors.webPage}
            />
          </Col>
          <Col xs={12} lg={4}>
            <SelectTypeHeadSingle
              label="Tipo de Persona"
              isRequired={true}
              inputName="keyTypeProvider"
              optionsArray={typesProv}
              defaultOption="Seleccione un tipo de persona"
              onChangeMethod={onChange}
              onBlurMethod={handleBlur}
              value={values.keyTypeProvider}
              touched={touched.keyTypeProvider}
              errors={errors.keyTypeProvider}
              optionValue="keyTypeProvider"
              optionName="nameTypeProvider"
            />
          </Col>
        </Row>

        {(permissions.INS || (permissions.UPD && provider.id !== 0)) && (
          <Row className="mt-5">
            <div className="col-sm-6 order-2 order-sm-1 text-center text-sm-left mb-4">
              <Button color="danger" type="reset" onClick={() => exitForm()}>
                Cancelar
              </Button>
            </div>
            <div className="col-sm-6 order-1 order-sm-2 text-center text-sm-right mb-4">
              <Button color="success" type="submit">
                Guardar
              </Button>
            </div>
          </Row>
        )}
      </form>
      <FullFormLoader show={loading} />
    </div>
  );
};

export default Informacion;

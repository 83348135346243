import Widget from "../../../../components/Widget/Widget";
import FormModulos from "../FormModulos"
import {
  Col,    
} from "reactstrap";
import { agregar } from "../functions";

const CrearModulo = (props) => {
  return (
    <div>
      <Widget className="widget-p-md">
      <Col className="mb-12"><h1 className="text-center">Crear Módulo</h1></Col>
      <FormModulos crear={agregar}/>
    </Widget>
    </div>
  )
}

export default CrearModulo
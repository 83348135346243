import { Document, Page, Text, View, StyleSheet } from "@react-pdf/renderer";
import { styles } from "../../../../components/Pdf/pdfGeneral/EstiloPdf";
import HeaderReport from "../../../../components/Pdf/Header";

/**
 * Estilo que se repite en todas los headers de la tabla
 */
const styleHeadForAll = {
  borderStyle: "solid", 
  borderWidth: 1,
  backgroundColor: "#646464",
  color: 'white',
  textAlign: 'center',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: 9
}

const styleCellForAll = {
  borderStyle: "solid", 
  borderWidth: 1, 
  borderTopWidth: 0 ,
  fontSize: 9
}

/**
 * Estilos del pdf
 */
const s = StyleSheet.create({
  subTitle:{ //SUBTITULOS
    width: "100%",
    textAlign: 'center',
    marginBottom: 10,
    fontSize: 14
  },
  tableColHeader10: { //HEADER NO
    width: "10%",
    height: 20, 
    ...styleHeadForAll
  }, 
  tableColHeader18: { //HEADER RAZON SOCIAL
    width: "18%",  
    ...styleHeadForAll,
    borderLeftWidth: 0,
  }, 
  tableColContentReport: { //CONTENIDO
    width: "100%"
  },
  tableColContent10: { //Cell NO
    width: "10%", 
    textAlign: 'center',
    ...styleCellForAll
  },
  tableColContent18: { //Cell Razon social
    width: "18%", 
    ...styleCellForAll,
    borderLeftWidth: 0
  },
  displayContent: { 
    paddingLeft: 9, //centra el content
    marginVertical: 4,
    flexDirection: "row",
    justifyContent: 'space-around',
  }
})


export default function generatePDFProviders(data, model, title, fileNo) {
  const ori = model.orientation === "h" ? "landscape" : "portrait";

  function getContentTable(table) {
    if (table.length > 0) {
      return table.map((value, index) => (
        <View style={styles.tableRow} wrap={false}>
          <View style={s.tableColContent10}>
            <Text style={s.cellColumn}>
              {value[model.id.id]}
            </Text>
          </View>
          <View style={s.tableColContent18}>
            <Text style={s.cellColumn}>
              {value[model.content[0].value]}
            </Text>
          </View>
          <View style={s.tableColContent18}>
            <Text style={s.cellColumn}>
              {value[model.content[1].value]}
            </Text>
          </View>
          <View style={s.tableColContent18}>
            <Text style={s.cellColumn}>
              {value[model.content[2].value]}
            </Text>
          </View>
          <View style={s.tableColContent18}>
            <Text style={s.cellColumn}>
              {value[model.content[3].value]}
            </Text>
          </View>
          <View style={s.tableColContent18}>
            <Text style={s.cellColumn}>
              {value[model.content[4].value]}
            </Text>
          </View>
        </View>
      ))
    }
    return null
  }
  
  return {
    report: (
      <>
        <Document>
          
          <Page style={styles.body} orientation={ori}>
            <HeaderReport/>
            <View>
              <Text style={s.subTitle}>{title}</Text>
            </View>
            <View style={styles.table}>
              {/* Cabeceras */}
              <View style={styles.tableRow} fixed>
                <View style={s.tableColHeader10} fixed>
                  <Text>{model.headers[0]}</Text>
                </View>
                <View style={s.tableColHeader18} fixed>
                  <Text>{model.headers[1]}</Text>
                </View>
                <View style={s.tableColHeader18} fixed>
                  <Text>{model.headers[2]}</Text>
                </View>
                <View style={s.tableColHeader18} fixed>
                  <Text>{model.headers[3]}</Text>
                </View>
                <View style={s.tableColHeader18} fixed>
                  <Text>{model.headers[4]}</Text>
                </View>
                <View style={s.tableColHeader18} fixed>
                  <Text>{model.headers[5]}</Text>
                </View>
              </View>
              <View style={s.tableColContentReport}>
                {getContentTable(data)}
              </View>
            </View>

            <View fixed style={styles.spaceFooter}></View>
            <View fixed style={styles.footer}>
              <Text style={styles.textFooter}>{fileNo}</Text>
              <Text
                style={styles.textFooter}
                render={({ pageNumber, totalPages }) =>
                  `PAGINA : ${pageNumber} / ${totalPages}`
                }
              />
            </View>
          </Page>
        </Document>
      </>
    ),
  };
}

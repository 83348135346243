import { Modal, Button, ModalBody, ModalHeader } from 'reactstrap';

import { useState } from "react"
import CameraInterface from '../webcam/Interface';

export default function Camera({ setVisible, visible, setFieldValue }) {
    const [loadingScreenShot, setLoadingScreenShot] = useState(false);
    const handleCloseModal = () => setVisible(false)

    return (
        <>
            <Modal isOpen={visible} size="lg" backdrop="static" toggle={loadingScreenShot ? handleCloseModal : undefined}>
                <ModalHeader className='fs-3 fw-bold' toggle={loadingScreenShot ? handleCloseModal : undefined}>Tomar fotografia</ModalHeader>
                <ModalBody className="d-flex justify-content-center">
                    <CameraInterface
                        cameraOpened={visible}
                        setCameraOpened={setVisible}
                        setFieldValue={setFieldValue}
                        handleCloseModal={handleCloseModal}
                        setLoadingScreenShot={setLoadingScreenShot}
                    />
                </ModalBody>
                <div className="text-center my-3">
                    <Button color='danger' className='mr-2' disabled={!loadingScreenShot} onClick={() => { setVisible(false) }}>
                        Cancelar
                    </Button>
                </div>
            </Modal>
        </>
    )
}
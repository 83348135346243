import { toast } from "react-toastify";
import { peticionesReceiver } from "../../../../helpers/peticionesReceiver";
import Notification from "../../../../components/Notification/Notification";
import { useEffect, useState } from "react";
import { Button, Spinner, Table } from "reactstrap";
import ModalReports from "../../../../components/Modal/ModalReports";
import exportCuadro from "../pdf/GenerarCuadro";
import { pdf } from "@react-pdf/renderer";
import { AddFile } from "../../../patrimonio/bienes-inmuebles/functions";
import FullFormLoader from "../../../../components/Loader/FullFormLoader";
import { GetPermissions } from "../../../../libs/permissions/getPermissions";

const formatter = new Intl.NumberFormat("es-MX", {
    style: "currency",
    currency: "MXN",
});

export default function EstudiosMercado({ idCot }) {

    const [partidas, setPartidas] = useState([])
    const [provQuotes, setProvQuotes] = useState([])
    const [modalReports, setModalReports] = useState(false)
    const [permisos, setPermisos] = useState("")
    const [loading, setLoading] = useState("")
    GetPermissions(setPermisos, setLoading);

    const [loader, setLoader] = useState(false)

    const [report, setReport] = useState(null)

    const API = peticionesReceiver();

    async function getPartidas(ret = false) {
        try {
            const res = await API.peticion({
                action: "multiselect",
                table: "quotesdetail qt left join services sv on (qt.idService=sv.idService) left join furnitures f on (f.idFurniture=qt.idFurniture) left join messureunits mu on (f.keyUnit=mu.keyUnit) left join servicetimes sti on (sv.keyServiceTime=sti.keyServiceTime)",
                rows: "qt.idDetail,qt.amount,if(isnull(sv.nameService),f.nameFurniture,sv.nameService) as descripcion,f.brand,if(isnull(mu.nameUnit),sti.nameServiceTime,mu.nameUnit) as unit",
                conditions: `qt.idQuote=${idCot} AND qt.enabled=1`
            })
            if (res.status === 200 && res.data.code === "200") {
                if (ret) {
                    return res.data.data
                }
                setPartidas(res.data.data)
            } else {
                toast(<Notification type={"consultar_error"} backMessage={res.data.message} withIcon />);
                if (ret) {
                    return null
                }
            }
        } catch (err) {
            console.log(err)
            toast(<Notification type={"consultar_error"} withIcon />);
            if (ret) {
                return null
            }
        }
    }

    async function getProviderQuotes(ret = false) {
        try {
            const res = await API.peticion({
                action: "multiselect",
                table: "providerquotes pq inner join quotesdetail qd using(idDetail) inner join providers p using(idProvider) join areas a on (pq.idAreaReception = a.idArea) inner join messureunits mu on (pq.keyUnit=mu.keyUnit)",
                rows: "pq.taxes,pq.unitCost,pq.total,p.socialName,p.itin,p.idProvider,pq.idDetail,pq.validity,pq.daysReception,pq.paymentDetail,a.name as nameArea,mu.nameUnit,pq.idProviderQuote",
                conditions: `qd.idQuote=${idCot} AND qd.enabled=1 AND pq.enabled=1`
            })
            if (res.status === 200 && res.data.code === "200") {
                if (ret) {
                    return groupByProviders(res.data.data, ret)
                }
                groupByProviders(res.data.data, ret)
            } else {
                toast(<Notification type={"consultar_error"} backMessage={res.data.message} withIcon />);
                if (ret) {
                    return null
                }
            }
        } catch (err) {
            console.log(err)
            toast(<Notification type={"consultar_error"} withIcon />);
            if (ret) {
                return null
            }
        }
    }

    function groupByProviders(data, ret = false) {
        const providers = [];
        data.forEach(row => {
            const index = providers.findIndex(prov => row.idProvider === prov.provider);
            if (index !== -1) {
                providers[index].data.push(row);
            } else {
                providers.push({ provider: row.idProvider, socialName: row.socialName, itin: row.itin, data: [row] });
            }
        })
        if (ret) {
            return providers
        }
        setProvQuotes(providers)
    }

    useEffect(() => {
        getPartidas();
        getProviderQuotes();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    function getPrecioPartidaProveedor(idDetail, idProvider) {
        const indexPartida = partidas.findIndex(partida => partida.idDetail === idDetail);
        const indexProv = provQuotes.findIndex(proveedor => proveedor.provider === idProvider);
        if (indexProv !== -1) {
            const indexProvQuote = provQuotes[indexProv].data.findIndex(data => data.idDetail === idDetail);
            if (indexProvQuote !== -1) {
                return (
                    <>
                        <td key={"m" + idDetail + "-" + idProvider}>{provQuotes[indexProv].data[indexProvQuote].brand}</td>
                        <td key={"uc" + idDetail + "-" + idProvider}>{formatter.format(provQuotes[indexProv].data[indexProvQuote].total)}</td>
                        <td key={"tot" + idDetail + "-" + idProvider}>{formatter.format(Number(provQuotes[indexProv].data[indexProvQuote].total) * Number(partidas[indexPartida].amount))}</td>
                    </>
                );
            }
        }
        return (
            <>
                <td key={"m" + idDetail + "-" + idProvider}></td>
                <td key={"uc" + idDetail + "-" + idProvider}></td>
                <td key={"tot" + idDetail + "-" + idProvider}></td>
            </>
        )
    }

    function getPromedio(idDetail) {
        let value = 0;
        const indexPartida = partidas.findIndex(partida => partida.idDetail === idDetail);
        const values = [];
        let sum = 0
        provQuotes.forEach(proveedor => {
            const index = proveedor.data.findIndex(data => data.idDetail === idDetail);
            if (index !== -1) {
                values.push(proveedor.data[index].total);
                sum++;
            }
        })
        values.forEach(val => {
            value += Number(val);
        })
        if (sum === 0 || value === 0) {
            return (
                <>
                    <td></td>
                    <td></td>
                </>
            )
        }
        return (
            <>
                <td>{formatter.format(value / sum)}</td>
                <td>{formatter.format(value * Number(partidas[indexPartida].amount) / sum)}</td>
            </>
        )
    }

    function getPlazo(idDetail, idProvider) {
        const indexProv = provQuotes.findIndex(proveedor => proveedor.provider === idProvider);
        if (indexProv !== -1) {
            const indexProvQuote = provQuotes[indexProv].data.findIndex(data => data.idDetail === idDetail);
            if (indexProvQuote !== -1) {
                const row = provQuotes[indexProv].data[indexProvQuote]
                return `${row.daysReception} ${row.nameUnit}`
            }
        }
        return ""
    }

    function getArea(idDetail, idProvider) {
        const indexProv = provQuotes.findIndex(proveedor => proveedor.provider === idProvider);
        if (indexProv !== -1) {
            const indexProvQuote = provQuotes[indexProv].data.findIndex(data => data.idDetail === idDetail);
            if (indexProvQuote !== -1) {
                const row = provQuotes[indexProv].data[indexProvQuote]
                return row.nameArea
            }
        }
        return ""
    }

    function getValidity(idDetail, idProvider) {
        const indexProv = provQuotes.findIndex(proveedor => proveedor.provider === idProvider);
        if (indexProv !== -1) {
            const indexProvQuote = provQuotes[indexProv].data.findIndex(data => data.idDetail === idDetail);
            if (indexProvQuote !== -1) {
                const row = provQuotes[indexProv].data[indexProvQuote]
                return row.validity
            }
        }
        return ""
    }

    function getPaymentDetail(idDetail, idProvider) {
        const indexProv = provQuotes.findIndex(proveedor => proveedor.provider === idProvider);
        if (indexProv !== -1) {
            const indexProvQuote = provQuotes[indexProv].data.findIndex(data => data.idDetail === idDetail);
            if (indexProvQuote !== -1) {
                const row = provQuotes[indexProv].data[indexProvQuote]
                return row.paymentDetail
            }
        }
        return ""
    }

    async function getReport() {
        const partidas = await getPartidas(true);
        const providerQuotes = await getProviderQuotes(true)
        const test = partidas.map(part => part.idDetail);
        providerQuotes.forEach(pq => {
            pq.data.forEach(data => {
                const index = test.findIndex(t => t === data.idDetail)
                if (index !== -1) {
                    test.splice(index, 1);
                }
            })
        })
        if (test.length > 0) {
            toast(<Notification type={"consultar_error"} withIcon backMessage={"Aun no se registran todas las partidas"} />);
        } else {
            setReport({ partidas, providerQuotes })
            setModalReports(true)
        }
    }

    const componentSend = () => {
        return (
            <div className="text-center mb-3">
                <Button color="warning" onClick={() => getReferences(report)}>Fijar precios</Button>
            </div>
        )
    }

    async function getReferences(report) {
        setLoader(true)
        const partidas = report.partidas;
        const winners = []
        partidas.forEach((partida, index) => {
            partida.cot.forEach((prov, ind) => {
                if (ind === 0) {
                    winners.push(prov);
                } else {
                    if (Number(prov.total) < Number(winners[index].total)) {
                        winners[index] = prov;
                    } else if (Number(prov.total) === Number(winners[index].total) && getTiempoEntrega(prov.daysReception, prov.nameUnit) < getTiempoEntrega(winners[index].daysReception, winners[index].nameUnit)) {
                        winners[index] = prov
                    }
                }
            })
        })
        const final = winners.map((winner, index) => ({ ...winner, amount: partidas[index].amount }))
        try {
            await Promise.all(final.map(partida => fijarPrecios(partida)));
            await Promise.all(final.map(partida => fijarGanadores(partida)));
            uploadFile(report)
        } catch (err) {
            toast(<Notification type={"modifica_error"} withIcon backMessage={err} />);
        }
    }

    async function fijarPrecios(partida) {
        const params = {
            action: "update",
            table: "quotesdetail",
            rows: {
                unitCost: partida.unitCost,
                taxies: partida.taxes,
                total: (Number(partida.total) * Number(partida.amount)),
            },
            condition: { idDetail: partida.idDetail }
        };
        try {
            const res = await API.peticion(params)
            if (res.status === 200 && res.data.code === "200") {
                return res.status;
            } else {
                throw new Error(res.data.message);
            }
        } catch (err) {
            throw new Error(err);
        }
    }

    async function fijarGanadores(partida) {
        const params = {
            action: "update",
            table: "providerquotes",
            rows: {
                winner: "1",
            },
            condition: { idProviderQuote: partida.idProviderQuote }
        };
        try {
            const res = await API.peticion(params)
            if (res.status === 200 && res.data.code === "200") {
                return res.status;
            } else {
                throw new Error(res.data.message);
            }
        } catch (err) {
            throw new Error(err);
        }
    }

    async function uploadFile(report) {
        const blob = await pdf(exportCuadro(report, false).report).toBlob();
        const file = new File([blob], "Estudio_Mercado.pdf")
        const data = {
            reference: idCot,
            keyFileType: "44",
            nameFile: "",
            file
        }
        AddFile(data, () => { setLoader(false); returnTo(); }, "saveFileReplace");
    }

    function getTiempoEntrega(daysReception, nameUnit) {
        switch (nameUnit) {
            case "MES":
                return (Number(daysReception) * 30)
            case "AÑO":
                return (Number(daysReception) * 365)
            default:
                return (Number(daysReception))
        }
    }

    function returnTo() {
        localStorage.removeItem("cotestudio");
        window.location.href = "#/template/estudiomercado"
    }

    if (loading) {
        <div className="d-flex align-items-center justify-content-center" style={{ height: "200px" }}>
            <Spinner
                color="primary"
                style={{
                    height: '3rem',
                    width: '3rem'
                }}
                type="grow"
            >
                Loading...
            </Spinner>
        </div>
    }
    if (permisos.CON) {
        return (
            <>
                <Table bordered className="border-primary" responsive>
                    <thead>
                        <tr>
                            <th rowSpan={4} className="text-center border-primary">
                                Partida
                            </th>
                            <th rowSpan={4} className="text-center border-primary">
                                Cantidad
                            </th>
                            <th rowSpan={4} className="text-center border-primary">
                                Unidad de medida
                            </th>
                            <th rowSpan={4} className="text-center border-primary">
                                Descripción del bien o servicio
                            </th>
                            <th colSpan={provQuotes.length * 3} className="text-center border-primary">
                                Proveedores y/o prestadores de servicios
                            </th>
                            <th colSpan={2} rowSpan={3} className="text-center border-primary">
                                Precio promedio de referencia
                            </th>
                        </tr>
                        <tr>
                            {provQuotes.map((prov, index) => (
                                <th colSpan={3} className="text-center border-primary" key={"socialName" + index}>
                                    {prov.socialName}
                                </th>
                            ))}
                        </tr>
                        <tr>
                            {provQuotes.map((prov, index) => (
                                <th colSpan={3} className="text-center border-primary" key={"itin" + index}>
                                    {prov.itin}
                                </th>
                            ))}
                        </tr>
                        <tr>
                            {provQuotes.map((value, index) => (
                                <>
                                    <th className="text-center border-primary" key={"marca" + index}>
                                        Marca
                                    </th>
                                    <th className="text-center border-primary" key={"precio" + index}>
                                        Precio
                                    </th>
                                    <th className="text-center border-primary" key={"sub" + index}>
                                        Subtotal
                                    </th>
                                </>
                            ))}
                            <th className="text-center border-primary">Unitario</th>
                            <th className="text-center border-primary">Subtotal</th>
                        </tr>
                    </thead>
                    <tbody>
                        {partidas.map((partida, index) => (
                            <tr>
                                <td key={"p" + index}>{index + 1}</td>
                                <td key={"pam" + index}>{partida.amount}</td>
                                <td key={"pun" + index}>{partida.unit}</td>
                                <td key={"pdesc" + index}>{partida.descripcion}</td>
                                {provQuotes.map((prov, ind) => (
                                    <>
                                        {getPrecioPartidaProveedor(partida.idDetail, prov.provider)}
                                    </>
                                ))}
                                {getPromedio(partida.idDetail)}
                            </tr>
                        ))}
                    </tbody>
                </Table>
                <Table bordered className="border-primary" responsive>
                    {partidas.map((partida) => (
                        <>
                            <thead>
                                <tr>
                                    <th className="text-center border-primary" colSpan={provQuotes.length + 1}>{partida.descripcion}</th>
                                </tr>
                                <tr>
                                    <th className="text-center border-primary" colSpan={provQuotes.length + 1}>Condiciones comerciales</th>
                                </tr>
                                <tr>
                                    <th className="border-primary"></th>
                                    {provQuotes.map((prov) => (
                                        <th className="text-center border-primary">{prov.socialName}</th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Plazo de entrega</td>
                                    {provQuotes.map((prov) => (
                                        <td>{getPlazo(partida.idDetail, prov.provider)}</td>
                                    ))}
                                </tr>
                                <tr>
                                    <td>Lugar de entrega</td>
                                    {provQuotes.map((prov) => (
                                        <td>{getArea(partida.idDetail, prov.provider)}</td>
                                    ))}
                                </tr>
                                <tr>
                                    <td>Vigencia de precios</td>
                                    {provQuotes.map((prov) => (
                                        <td>{getValidity(partida.idDetail, prov.provider)}</td>
                                    ))}
                                </tr>
                                <tr>
                                    <td>Condiciones de pago</td>
                                    {provQuotes.map((prov) => (
                                        <td>{getPaymentDetail(partida.idDetail, prov.provider)}</td>
                                    ))}
                                </tr>
                            </tbody>
                        </>
                    ))}
                </Table>
                <div className="text-center mt-3">
                    <Button color="add" onClick={getReport}>Generar cuadro comparativo en PDF</Button>
                </div>
                {report !== null && (
                    <ModalReports
                        modal={modalReports}
                        setModal={setModalReports}
                        title={"Solicitud de adquisición"}
                        backdrop={"static"}
                        keyboard={false}
                        report={report}
                        generatePdfMethod={{
                            method: exportCuadro,
                            type: "general",
                        }}
                        records={1000}
                        component={componentSend}
                    />
                )}
                <FullFormLoader show={loader} message="Consultando datos, espere un momento" />
            </>
        )
    } else {
        return (
            <>
                <div className="text-center p-4">
                    No tienes permitido ver los registros de esta seccion
                </div>
            </>
        )
    }
}

import React from "react";

import Widget from "../../../../components/Widget/Widget";
import FormRubros from "../FormRubros";
import { updateIncomes } from "../functions";
import { useHistory } from "react-router-dom";

export const EditarRubros = () => {
  const history = useHistory();

  const getData = localStorage.getItem('dataContent')
  const data = getData ? getData.split('|-|') : history.goBack()

  return (
    <Widget className="widget-p-md">
        <h3>Editar Rubro de Ingreso</h3>
        <FormRubros data={data} editar = {updateIncomes} />
    </Widget>
  )
}

export default EditarRubros;
import { Document, Page, Text, View, StyleSheet, Image } from "@react-pdf/renderer";
import HeaderReport from "../../../../components/Pdf/Header";
import { styles } from "../../../../components/Pdf/pdfGeneral/EstiloPdf";

/**
 * Estilo que se repite en todas los headers de la tabla
 */
const styleHeadForAll = {
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "#646464",
    color: 'white',
    textAlign: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 12
}

const styleCellForAll = {
    borderStyle: "solid",
    borderWidth: 1,
    borderTopWidth: 0,
    fontSize: 12
}

/**
 * Estilos del pdf
 */
const s = StyleSheet.create({
    subTitle: { //SUBTITULOS
        width: "100%",
        textAlign: 'center',
        marginBottom: 10,
        marginLeft: 10,
        fontSize: 14
    },
    f6: {
        fontSize: 6
    },
    f5: {
        fontSize: 5
    },
    f8: {
        fontSize: 8
    },
    f10: {
        fontSize: 10
    },
    thIn: {
        height: 20,
        ...styleHeadForAll
    },
    th: {
        height: 20,
        ...styleHeadForAll,
        borderLeftWidth: 0
    },
    w10: {
        width: "10%"
    },
    w20: {
        width: "20%"
    },
    w25: {
        width: "25%"
    },
    w30: {
        width: "30%"
    },
    w33: {
        width: "33%"
    },
    w34: {
        width: "34%"
    },
    w40: {
        width: "40%"
    },
    w50: {
        width: "50%"
    },
    w60: {
        width: "60%"
    },
    w70: {
        width: "70%"
    },
    w90: {
        width: "90%"
    },
    w100: {
        width: "100%"
    },
    mt2: {
        marginTop: 40
    },
    px2: {
        paddingHorizontal: 20
    },
    p2: {
        padding: 20
    },
    bt0: {
        borderTopWidth: 0
    },
    center: {
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
    },
    textBold: {
        fontFamily: 'Helvetica-Bold'
    },
    tdIn: { //Cell CC
        ...styleCellForAll
    },
    td: { //Cell Concepto
        ...styleCellForAll,
        borderLeftWidth: 0
    },
    red: {
        backgroundColor: "red",
    },
    green: {
        backgroundColor: "green",
    },
    gray: {
        backgroundColor: "gray",
    }
})


const formatter = new Intl.NumberFormat("es-MX", {
    style: "currency",
    currency: "MXN",
});

const nf = new Intl.NumberFormat('en-US');

export default function exportFicha(data, model, title, fileNo) {
    const ori = model.orientation === "h" ? "landscape" : "portrait";

    const images = data.map(build => { return { src: build.src, nameFile: build.nameFile } }).filter(image => image.src !== "");

    const inmueble = data[0];

    return {
        report: (
            <>
                <Document>
                    <Page style={s.body} orientation={ori}>
                        <HeaderReport />
                        <View>
                            <Text style={s.subTitle}>{title}</Text>
                        </View>
                        <View style={styles.table}>
                            <View style={styles.tableRow}>
                                <View style={[s.thIn, s.w100]}><Text>INFORME DEL BIEN INMUEBLE</Text></View>
                            </View>
                            <View style={styles.tableRow}>
                                <View style={[s.w100, s.tdIn, s.f10]}>
                                    <Text style={s.textBold}>EDICIO: </Text><Text>{inmueble?.nameBuilding}</Text>
                                </View>
                            </View>
                            <View style={styles.tableRow}>
                                <View style={[s.w100, s.tdIn, s.f10]}>
                                    <Text style={s.textBold}>DOMICILIO: </Text><Text>{inmueble?.streetName} NO. {inmueble?.extNumber} INT. {inmueble?.intNumber}, {inmueble?.subStreet}, CP: {inmueble?.zipCode}</Text>
                                </View>
                            </View>
                            <View style={styles.tableRow}>
                                <View style={[s.w50, s.tdIn, s.f10]}>
                                    <Text style={s.textBold}>GRUPO: </Text><Text>{inmueble?.nameGroup}</Text>
                                </View>
                                <View style={[s.w50, s.td, s.f10]}>
                                    <Text style={s.textBold}>SUBGRUPO: </Text><Text>{inmueble?.nameSubGroup}</Text>
                                </View>
                            </View>
                            <View style={styles.tableRow}>
                                <View style={[s.w50, s.tdIn, s.f10]}>
                                    <Text style={s.textBold}>CLASE: </Text><Text>{inmueble?.nameClass}</Text>
                                </View>
                                <View style={[s.w50, s.td, s.f10]}>
                                    <Text style={s.textBold}>SUBCLASE: </Text><Text>{inmueble?.nameSubClass}</Text>
                                </View>
                            </View>
                            <View style={styles.tableRow}>
                                <View style={[s.w50, s.tdIn, s.f10]}>
                                    <Text style={s.textBold}>TIPO PROPIEDAD: </Text><Text>{inmueble?.nameTypeProperty}</Text>
                                </View>
                                <View style={[s.w50, s.td, s.f10]}>
                                    <Text style={s.textBold}>VENDEDOR/DONANTE: </Text><Text>{inmueble?.seller}</Text>
                                </View>
                            </View>
                            <View style={styles.tableRow}>
                                <View style={[s.w50, s.tdIn, s.f10]}>
                                    <Text style={s.textBold}>CLAVE CATASTRAL: </Text><Text>{inmueble?.registerNumber}</Text>
                                </View>
                                <View style={[s.w50, s.td, s.f10]}>
                                    <Text style={s.textBold}>VALOR ACTUAL: </Text><Text>{formatter.format(inmueble?.cost)}</Text>
                                </View>
                            </View>
                        </View>
                        <View style={styles.table}>
                            <View style={styles.tableRow} >
                                <View style={[s.thIn, s.w100]}><Text>CARACTERISTICAS</Text></View>
                            </View>
                            <View style={styles.tableRow}>
                                <View style={[s.w50, s.tdIn, s.f10]}>
                                    <Text style={s.textBold}>SUPERFICIE: </Text><Text>{nf.format(inmueble?.surface)} MTS</Text>
                                </View>
                                <View style={[s.w50, s.td, s.f10]}>
                                    <Text style={s.textBold}>NIVELES: </Text><Text>{nf.format(inmueble?.levels)}</Text>
                                </View>
                            </View>
                            <View style={styles.tableRow}>
                                <View style={[s.w50, s.tdIn, s.f10]}>
                                    <Text style={s.textBold}>BAÑOS: </Text><Text>{nf.format(inmueble?.baths)}</Text>
                                </View>
                                <View style={[s.w50, s.td, s.f10]}>
                                    <Text style={s.textBold}>CAJEROS: </Text><Text>{nf.format(inmueble?.atms)}</Text>
                                </View>
                            </View>
                            <View style={styles.tableRow}>
                                <View style={[s.w50, s.tdIn, s.f10]}>
                                    <Text style={s.textBold}>ELEVADORES: </Text><Text>{nf.format(inmueble?.elevators)}</Text>
                                </View>
                                <View style={[s.w50, s.td, s.f10]}>
                                    <Text style={s.textBold}>MEJORAS APLICADAS EN EL AÑO: </Text><Text>{nf.format(inmueble?.email)}</Text>
                                </View>
                            </View>
                            <View style={styles.tableRow}>
                                <View style={[s.w50, s.tdIn, s.f10]}>
                                    <Text style={s.textBold}>ENCARGADO: </Text><Text>{inmueble?.name ? inmueble?.name : "SIN DESIGNACIÓN"}</Text>
                                </View>
                                <View style={[s.w50, s.td, s.f10]}>
                                    <Text style={s.textBold}>TELEFONO: </Text><Text>{inmueble?.phone}</Text>
                                </View>
                            </View>
                        </View>
                    </Page>

                    {images.map((image, index) => (
                        <Page style={[s.body, s.p2]} orientation={ori}>
                            <View style={s.center}>
                                <Text>{image.nameFile} </Text>
                            </View>
                            <Image style={{ height: "100%", padding: "20" }} src={image.src} />
                        </Page>
                    ))}

                </Document>
            </>
        ),
    };
}
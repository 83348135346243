import * as Yup from "yup"

import { toast } from "react-toastify";
import Notification from "../../../../../components/Notification/Notification";

import { useEffect, useState } from "react";
import { NumberInput } from "../../../../../components/GenericInputsFormik/NumberInput";
import { useFormik } from "formik";
import { Button, Col, Row } from "reactstrap";
import ModalConfirmation from "../../../../../components/Modal/ModalConfirmation";

import { peticionesReceiver } from "../../../../../helpers/peticionesReceiver";
import InputWithModal from "../../../../../components/GenericInputsFormik/InputWithModal";
import { useSelector } from "react-redux";
import Loader from "../../../../../components/Loader/Loader";

export default function AsignarSolicitante({ idQuote, refreshData }) {
    const API = peticionesReceiver();
    const [loading, setLoading] = useState(true);
    const [modal, setModal] = useState(false);
    const { profileType } = useSelector((state) => state.userInfo);
    const idArea = profileType.selectProfile.idArea;
    const cabeceras = [
        "#",
        "Núm. Empleado",
        "Nombre",
        "Puesto",
        "Seleccionar",
    ];
    const filtro = [
        true, true, true, true, true
    ]

    const params = {
        action: "datatable",
        table: "staff STF INNER JOIN employtypes AS EMT ON STF.keyEmployType = EMT.keyEmployType INNER JOIN jobstaff AS JBS ON JBS.idStaff = STF.idStaff INNER JOIN areas AS AR ON AR.idArea = JBS.idArea INNER JOIN jobtypes AS JBT ON JBT.keyJobType = STF.keyEmployType",
        rows: "STF.idStaff,STF.staffNumber,CONCAT(STF.name,' ',STF.pName,' ',STF.mName) as fullName,JBT.nameJobType",
        conditions: `STF.enabled = 1 AND JBS.valid = 1 AND STF.keyStat = 1 AND JBS.enabled = 1 AND AR.idArea=${idArea}`,
        page: 0,
        records: 5,
        search: "",
        order: "",
    }

    const defaultForm = {
        idQuote,
        idStaffRequire: "",
        nameStaff: ""
    }

    const FormSchema = Yup.object().shape({
        idStaffRequire: Yup.number().required("Seleccionar un empleado dentro del area")
    })

    const { handleSubmit, values, handleBlur, errors, touched, setFieldValue, resetForm } =
        useFormik({
            initialValues: defaultForm,
            onSubmit: () => setModal(true),
            validationSchema: FormSchema,
        });

    const onChange = (e) => {
        const { name, value } = e.target
        setFieldValue([name], value);
    }

    const OnEdit = async (rows) => {
        const params = {
            action: "update",
            table: "quotes",
            rows: {
                idQuote: rows.idQuote,
                idStaffRequire: rows.idStaffRequire,
                idArea: idArea
            },
            condition: {
                idQuote
            }
        }
        try {
            const res = await API.peticion(params)
            if (res.status === 200 && res.data.code === "200") {
                toast(<Notification type={"modifica_exito"} backMessage={res.data.message} withIcon />);
            } else {
                toast(<Notification type={"modifica_error"} backMessage={res.data.message} withIcon />);
            }
        } catch (err) {
            console.log(err)
            toast(<Notification type={"modifica_error"} withIcon />);
        }
    }

    const actionSelect = (el) => {
        setFieldValue(["idStaffRequire"], el[0]);
        if (el[0] !== "") {
            setFieldValue(["nameStaff"], `${el[1]} - ${el[2]}`);
        } else {
            setFieldValue(["nameStaff"], "");
        }
    }

    const clear = () => {
        setFieldValue(["idStaffRequire"], "");
        setFieldValue(["nameStaff"], "");
    }

    const getQuote = async () => {
        try {
            const res = await API.peticion({
                action: "multiselect",
                table: "quotes q left join staff STF on (q.idStaffRequire=STF.idStaff)",
                rows: "q.idStaffRequire,STF.staffNumber,CONCAT(STF.name,' ',STF.pName,' ',STF.mName) as nameStaff",
                conditions: `q.idQuote=${idQuote}`
            })
            if (res.status === 200 && res.data.code === "200") {
                actionSelect(Object.values(res.data.data[0]));
                setLoading(false)
            } else {
                toast(<Notification type={"consultar_error"} backMessage={res.data.message} withIcon />);
            }
        } catch (err) {
            console.log(err)
            toast(<Notification type={"consultar_error"} withIcon />);
        }
    }

    useEffect(() => {
        getQuote()
    }, [])

    if (loading) {
        return (
            <div className="text-center" style={{height: "200px"}}>
                <Loader />
            </div>
        )
    }
    return (
        <>
            <form onSubmit={handleSubmit}>
                <Row>
                    <Col>
                        <InputWithModal
                            cabeceras={cabeceras}
                            error={errors.idStaffRequire}
                            filtro={filtro}
                            handleBlur={handleBlur}
                            onChange={onChange}
                            actionSelect={actionSelect}
                            onClear={clear}
                            parametros={params}
                            touched={touched.idStaffRequire}
                            value={values.nameStaff}
                            label="Empleado"
                            name={"idStaffRequire"}
                            title={"Seleccionar empleado"}
                        />
                    </Col>
                </Row>
                <div className="text-center">
                    <Button type="submit" color="add">Asignar solicitante</Button>
                </div>
            </form>
            <ModalConfirmation
                modalTitle={"Editar cantidad"}
                modal={modal}
                editar={(rows) => OnEdit(rows)}
                isEdit={true}
                setModal={setModal}
                values={values}
            >
                <div className="d-flex justify-content-center">
                    <h6>¿Desea continuar con el cambio?</h6>
                </div>
            </ModalConfirmation>
        </>
    )
}
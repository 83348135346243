import { useState } from "react";
import { Row, Col } from "reactstrap";
import { toast } from "react-toastify";

import Datatable from "../../../components/Datatable/Datatable";
import Widget from "../../../components/Widget/Widget";
import { peticionesReceiver } from "../../../helpers/peticionesReceiver";
import Notification from "../../../components/Notification/Notification";

import { GetPermisos } from "../../../libs/permissions/hook";

export default function PresupuestoUnidadesEjecutoras() {
    const API = peticionesReceiver()
    const [permissionsForm, setPermissionsForm] = useState("");

    GetPermisos(setPermissionsForm);

    const headers = [
        "#", //0
        "Año",
        "Tipo",
        "Cantidad Total",
        ""
    ];

    const columns = [
        { data: "index" },
        { data: "budgetYear" },
        { data: "budgetType" },
        { data: "amount" },
        {
            data: "idAnualBudget",
            render: function (row) {
                return (
                    <>
                        <i className="fa fa-eye text-info" style={{ cursor: "pointer" }} onClick={() => viewPresupuesto(row)} />
                    </>
                )
            }
        },
    ]

    function viewPresupuesto(idAnualBudget) {
        localStorage.setItem("preunieje", idAnualBudget);
        window.location.href = "#/template/presupuestounidades/areas"
    }

    const columnDefs = [
        { targets: [0, 1, 4], className: "text-center" },
        { targets: [3], className: "text-right" },
        { targets: [0, 4], orderable: false }
    ]

    function getParams(order, records, page, search) {
        return {
            action: "getCurrentBudget",
            page,
            records,
            search,
            order
        }
    }

    async function getData(order, records, page, search) {
        try {
            const res = await API.peticionEndPoint(getParams(order, records, page, search), "app/facades/budgets/budgetsF.php")
            if (res.status === 200 && res.data.code === "200") {
                const data = res.data;
                data.data.forEach((value, index) => value.index = page + 1 + index);
                return data;
            } else {
                toast(<Notification type={"consultar_error"} backMessage={res.data.message} />);
                return null;
            }
        } catch (err) {
            toast(<Notification type={"consultar_servidor_error"} withIcon />);
            return null
        }
    }

    return (
        <>
            <Widget className={"p-3"}>
                <Row>
                    <Col xs={12} md={6}>
                        <h1 className="text-secondary mb-3">Ejercicio fiscal</h1>
                    </Col>
                </Row>
                {permissionsForm.CON === "1" ? (
                    <Datatable
                        headers={headers}
                        petition={getData}
                        columns={columns}
                        order={{ col: 1, opt: 'asc' }}
                        columnDefs={columnDefs}
                        control='back'
                        key={"table"}
                    />
                ) : (<div className="text-center p-4">No tienes permitido ver los registros de esta seccion</div>)}

            </Widget>
        </>
    )
}
import React, { useState, useEffect } from "react"
import { SelectTypeHeadSingle } from "../../../../components/GenericInputsFormik"
import { peticionesReceiver } from "../../../../helpers/peticionesReceiver";
import Notification from "../../../../components/Notification/Notification";
import { toast } from "react-toastify";

export default function InputPP({ onChangeMethod, onBlurMethod, values, errors, touched, annualBudget }) {
  const [options, setOptions] = useState([]);
  const API = peticionesReceiver();
  const params = {
    action: "getStateFundsOrigin",
    rows: { idAnnualBudget: annualBudget.id }
  };

  useEffect(() => {
    if (values.idStateFund === "") {
      getProyectosPresupuestales()  
    }
  }, [annualBudget, values]);

  const getProyectosPresupuestales = async () => {
    API.peticionEndPoint(params, "app/facades/budgets/budgetsF.php")
      .then((res) => {
        if (res.status === 200) {
          setOptions(res.data.data);
        } else {
          toast(
            <Notification
              type={"consultar_error"}
              backMessage={res.data.message}
              withIcon
            />
          );
        }
      })
      .catch((err) => {
        toast(<Notification type={"consultar_servidor_error"} withIcon />);
      });
  }
    
    
  return (
    <SelectTypeHeadSingle
      label="Proyectos presupuestales"
      inputName="idStateFund"
      onChangeMethod={onChangeMethod}
      onBlurMethod={onBlurMethod}
      value={values.idStateFund}
      isRequired
      touched={touched.idStateFund}
      errors={errors.idStateFund}
      optionsArray={options}
      optionValue={"idStateFund"}
      optionName={"nameStateFund"}
    />
  );
}
import React from "react";
import { Row, Col } from "reactstrap";
import CheckComponen from "./CheckComponen";

const ChecksInfoGeneral = ({
  data,
  checksGeneralInfo,
  setCheckGeneralInfo,
}) => {
  const onChangeCheck = (e, text) => {
    if (text === "selectAll") {
      let checks = checksGeneralInfo;
      for (let clave in checks) {
        checks[clave] = e;
      }

      setCheckGeneralInfo(checks);
    } else {
      setCheckGeneralInfo({ ...checksGeneralInfo, [text]: e });
    }
  };

  return (
    <div>
      <Row className="mb-3">
        <Col className="text-center">
          <h3>Seleccione los elementos que no quiere que se muestren</h3>
        </Col>
      </Row>
      <Row>
        <Col>
          <CheckComponen
            text={"Nombre"}
            values={data.nombre}
            keyObject={"nombre"}
            onChangeCheck={onChangeCheck}
          />

          <CheckComponen
            text={"Curp"}
            values={data.curp}
            keyObject={"curp"}
            onChangeCheck={onChangeCheck}
          />
          <CheckComponen
            text={"Rfc"}
            values={data.rfc}
            keyObject={"rfc"}
            onChangeCheck={onChangeCheck}
          />
          <CheckComponen
            text={"Clave Sp"}
            values={data.claveSP}
            keyObject={"claveSP"}
            onChangeCheck={onChangeCheck}
          />
          <CheckComponen
            text={"Codigo"}
            values={data.codigo}
            keyObject={"codigo"}
            onChangeCheck={onChangeCheck}
          />
          <CheckComponen
            text={"Cct"}
            values={data.cct}
            keyObject={"cct"}
            onChangeCheck={onChangeCheck}
          />
          <CheckComponen
            text={"Unidad administrativa"}
            values={data.unidadAdministrativa}
            keyObject={"unidadAdministrativa"}
            onChangeCheck={onChangeCheck}
          />
          <CheckComponen
            text={"Plaza"}
            values={data.plaza}
            keyObject={"plaza"}
            onChangeCheck={onChangeCheck}
          />
        </Col>

        <Col>
          <CheckComponen
            text={"Pago"}
            values={data.pago}
            keyObject={"pago"}
            onChangeCheck={onChangeCheck}
          />
          <CheckComponen
            text={"Fecha de Pago"}
            values={data.fechaPago}
            keyObject={"fechaPago"}
            onChangeCheck={onChangeCheck}
          />
          <CheckComponen
            text={"Código puesto"}
            values={data.codigoPuesto}
            keyObject={"codigoPuesto"}
            onChangeCheck={onChangeCheck}
          />
          <CheckComponen
            text={"Período de Pago"}
            values={data.periodoPago}
            keyObject={"periodoPago"}
            onChangeCheck={onChangeCheck}
          />
          <CheckComponen
            text={"Puesto"}
            values={data.puesto}
            keyObject={"puesto"}
            onChangeCheck={onChangeCheck}
          />
          <CheckComponen
            text={"Total Neto"}
            values={data.totalNeto}
            keyObject={"totalNeto"}
            onChangeCheck={onChangeCheck}
          />
          <CheckComponen
            text={"Clave ISSEMyM"}
            values={data.claveIssemym}
            keyObject={"claveIssemym"}
            onChangeCheck={onChangeCheck}
          />
          <CheckComponen
            text={"Dependencia"}
            values={data.dependencia}
            keyObject={"dependencia"}
            onChangeCheck={onChangeCheck}
          />
        </Col>
      </Row>
    </div>
  );
};

export default ChecksInfoGeneral;

import React, { useState} from "react";
import TableComponent from "../../../components/TableComponent/TableComponentFiltro";
import { toast } from "react-toastify";
import { peticionesReceiver } from "../../../helpers/peticionesReceiver";
import Notification from "../../../components/Notification/Notification";

const CuentasContables = () => {
    const API = peticionesReceiver();
    const [cabeceras] = useState([
      "Id",
      "Cuenta",
      "Nombre",
      "Descripcion",
      "Editar",
      "Eliminar",
    ]);
      const [filter] = useState([true, true, true,true, false]);
      const select = 
      {
        action: "datatable",
        table: "account_list A INNER JOIN account_plans B USING (keyPlan)",
        rows: "idAccountList,A.fullKey,namePlan,nameAccountList,accountListKey,parentAccountList,keyPlan",
        conditions:  "A.enabled = 1 AND B.enabled = 1",
        page: 0,
        records: 5,
        search: "",
        order: ""
    }
      const [parametros,setParametros] = useState(select);

    const elimina = async (id,getData) => {
      const data = 
      {
        action: "deleteAccount",
        rows:{
            "idAccountList": id,
        }
      }
      API.peticionEndPoint(data,"app/facades/accounts/accountsF.php")
      .then((res) => { 
          if (res.status === 200 || res.status === "200") {
            toast(<Notification type={"agrega_exito"} backMessage={res.data.message} withIcon />);
            /* setParametros(select) */
            getData()
            
          } else {
            toast(<Notification type={"agrega_error"} backMessage={res.data.message} withIcon />);
          }
        })
        .catch((err) => {
          toast(<Notification type={"consultar_servidor_error"} withIcon />);
        });
    }


  return (
    <div>
      <TableComponent
        titulo="Cuentas Contables"
        filtro={filter}
        cabecerasTabla={cabeceras}
        rutaCrear="/template/cuentas-contables/crear"
        rutaEditar="/template/cuentas-contables/editar"
        parametros={parametros}
        elimina={elimina}
      />
    </div>
  );
}

export default CuentasContables
import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import { Button, Row, Col } from "reactstrap";
import { peticionesReceiver } from "../../../../helpers/peticionesReceiver";
import * as Yup from "yup";
import ModalConfirmation from "../../../../components/Modal/ModalConfirmation";
import { CancelOutlined } from "@material-ui/icons";
import ModalTableVer from "../../../../components/Modal/ModalTableVer";
import s from "./InputClear.module.scss";
import Loader from "../../../../components/Loader/Loader";

import { toast } from "react-toastify";
import Notification from "../../../../components/Notification/Notification";
import {
  SelectTypeHeadSingle,
  TextAreaInput,
} from "../../../../components/GenericInputsFormik";

const FormSolicitudes = ({
  collapse,
  setRefresh,
  setTrasnferRequest,
  setCollapse,
  idAreaLogged,
}) => {
  const [modalConfirmation, setModalConfirmation] = useState(false);
  const [areas, setAreas] = useState([]);
  const [stores, setStores] = useState([]);
  const API = peticionesReceiver();

  const [modalTabla, setModalTabla] = useState(false);
  const [loader, setLoader] = useState(true);
  const filtroModalTabla = [true, true, true, true, true];
  const cabecerasModalTabla = [
    "#",
    "Núm. Empleado",
    "Nombre",
    "Unidad de trabajo",
    "Puesto",
    "Seleccionar",
  ];
  const [paramsModalTabla] = useState({
    action: "datatable",
    table:
      "staff STF INNER JOIN employtypes AS EMT ON STF.keyEmployType = EMT.keyEmployType INNER JOIN jobstaff AS JBS ON JBS.idStaff = STF.idStaff INNER JOIN areas AS AR ON AR.idArea = JBS.idArea INNER JOIN jobtypes AS JBT ON JBT.keyJobType = STF.keyEmployType",
    rows: "STF.idStaff,STF.staffNumber,CONCAT(STF.name,' ',STF.pName,' ',STF.mName) as fullName,AR.name,JBT.nameJobType",
    conditions:
      `STF.enabled = 1 AND JBS.valid = 1 AND STF.keyStat = 1 AND JBS.enabled = 1 and JBS.idArea = ${idAreaLogged}`,
    page: 0,
    records: 5,
    search: "",
    order: "",
  });

  useEffect(() => {
    getAreas();
    getRegion();
    /* getStore(); */
  }, []);
  useEffect(() => {
    if (!collapse) {
      resetForm();
    }
  }, [collapse]);

  async function getAreas() {
    const params = {
      action: "multiselect",
      table: "areas",
      rows: "idArea,name",
      conditions: "enabled = 1 and enabled =1 ",
    };
    try {
      const res = await API.peticion(params);
      if (res.status === 200 && res.data.code === "200") {
        setAreas(res.data.data);
      } else {
        toast(
          <Notification
            type={"consultar_error"}
            backMessage={"Sin registros"}
            withIcon
          />
        );
      }
    } catch (err) {
      toast(<Notification type={"consultar_servidor_error"} withIcon />);
    }
  }
  async function getRegion() {
    
    const params = {
      action: "multiselect",
      table: "areas A INNER JOIN buildings B ON (A.idBuilding = B.idBuilding) INNER JOIN cities C ON (B.keyCity= C.keyCity)  INNER JOIN counties D ON (C.keyCounty= D.keyCounty)  INNER JOIN districts E ON (D.keyDistrict= E.keyDistrict) INNER JOIN regions F ON (E.keyRegion = F.keyRegion)",
      rows: "A.idArea,A.name,F.keyRegion",
      conditions: `A.enabled = 1  AND A.idArea = ${idAreaLogged}`,
    };
    try {
      const res = await API.peticion(params);
      if (res.status === 200 && res.data.code === "200") {


        getStore(res.data.data[0].keyRegion)




        setStores(res.data.data);
      } else {
        toast(
          <Notification
            type={"consultar_error"}
            backMessage={"Sin registros"}
            withIcon
          />
        );
        setLoader(false)
      }
    } catch (err) {
      toast(<Notification type={"consultar_servidor_error"} withIcon />);
    }
  }
  async function getStore(keyRegion) {
    const params = {
      action: "multiselect",
      table: "areas A INNER JOIN buildings B ON (A.idBuilding = B.idBuilding) INNER JOIN cities C ON (B.keyCity= C.keyCity)  INNER JOIN counties D ON (C.keyCounty= D.keyCounty)  INNER JOIN districts E ON (D.keyDistrict= E.keyDistrict)  INNER JOIN regions F ON (E.keyRegion = F.keyRegion)",
      rows: "A.idArea,A.name",
      conditions: `A.enabled = 1 and A.storage = 1 AND F.keyRegion= ${keyRegion}`,
    };
    try {
      const res = await API.peticion(params);
      if (res.status === 200 && res.data.code === "200") {
        setStores(res.data.data);
        setLoader(false)
        
      } else {
        toast(
          <Notification
            type={"consultar_error"}
            backMessage={"Sin registros"}
            withIcon
          />
        );
      }
      setLoader(false)
    } catch (err) {
      toast(<Notification type={"consultar_servidor_error"} withIcon />);
      setLoader(false)
    }
  }


  const selectEmpleado = (el) => {
    setFieldValue("idStaff", el[0]);
    setFieldValue("nameStaff", `${el[1]} - ${el[2]}`);
    setModalTabla(false);
  };




  const FormSchema = Yup.object().shape({
    nameStaff: Yup.string().required("Seleccione un Unidad de trabajo de destino"),    
    idStore: Yup.string().required("Seleccione un almacen"),    
  });

  const {
    handleSubmit,
    values,
    errors,
    setFieldValue,
    touched,
    handleBlur,
    resetForm,
  } = useFormik({
    initialValues: {
      idArea: "",
      nameStaff:"",
      idStaff:"",
      idStore:"",
    },
    onSubmit: () => {
      setModalConfirmation(true);
    },
    validationSchema: FormSchema,
    enableReinitialize: true,
  });

  const onChange = (e) => {
    const { name, value } = e.target;
    setFieldValue([name], value);
  };

  const sendRequest = async (values) => {
    const data = {
      action: "createExitRequire",
      idAreaRequire: idAreaLogged,
      idStaffRequire: values.idStaff,
      idArea: values.idStore
    };
    const res = await API.peticionEndPoint(
      data,
      "app/facades/warehouse/warehouseF.php"
    );
    if (res.status === 200 && res.data.status === "success") {
      toast(
        <Notification
          type={"agrega_exito"}
          backMessage={res.data.message}
          withIcon
        />
      );
      resetForm();
      setRefresh(true);
      
      setTrasnferRequest(res.data.data[0]);
      setCollapse(false);
    } else {
      toast(
        <Notification
          type={"agrega_error"}
          backMessage={res.data.message}
          withIcon
        />
      );
    }
  };

  function handleClear() {
    setFieldValue("idStaff", "");
    setFieldValue("nameStaff", "");
  }

  return (
    <div className="mt-3">

      {loader ? (
       <div className="text-center">
         <Loader/>
        </div>

      ):
      (
        <>
        <form onSubmit={handleSubmit}>
        <div className="text-center mb-5">
          <h1>Nueva Solicitud</h1>
        </div>
        <Row className="d-flex justify-content-around">
          <Col xs={12} lg={5}>
            <SelectTypeHeadSingle
              label="Adscripción Solicitante "
              inputName="idArea"
              onChangeMethod={onChange}
              onBlurMethod={handleBlur}
              value={idAreaLogged}
              isRequired
              touched={touched.idArea}
              errors={errors.idArea}
              optionsArray={areas}
              optionValue={"idArea"}
              optionName={"name"}              
              isDisabled={true}
            />
          </Col>
          <Col xs={12} lg={5}>
                <div className="form-group position-relative">
                  <label htmlFor="nameStaff">
                    Empleado Solicitante{" "}
                    <span className="text-danger">*</span>
                  </label>
                  <div className="input-group">
                    <input
                      name="nameStaff"
                      onChange={onChange}
                      onBlur={handleBlur}
                      className={
                        "form-control" +
                        (errors.nameStaff && touched.nameStaff
                          ? " is-invalid"
                          : "")
                      }
                      value={values.nameStaff}
                      disabled
                    />
                    <div className="input-group-append">
                      <button
                        className="btn btn-secondary"
                        style={{ zIndex: "auto" }}
                        type="button"
                        onClick={() => setModalTabla(true)}
                      >
                        <i className="fa fa-search" />
                      </button>
                    </div>
                  </div>
                  {values.nameStaff !== "" && (
                    <CancelOutlined
                      className={s.btnClear}
                      onClick={handleClear}
                    />
                  )}
                  {touched.nameStaff && errors.nameStaff && (
                    <span style={{ color: "red" }}>{errors.nameStaff}</span>
                  )}
                </div>
              </Col>
          </Row>

          <Row className="d-flex justify-content-around">
          <Col xs={12} lg={5}>
            <SelectTypeHeadSingle
              label="Almacen de destino "
              inputName="idStore"
              onChangeMethod={onChange}
              onBlurMethod={handleBlur}
              value={values.idStore}
              isRequired
              touched={touched.idStore}
              errors={errors.idStore}
              optionsArray={stores}
              optionValue={"idArea"}
              optionName={"name"}              
            />
          </Col>

          </Row>

        <Row className="d-flex align-items-center justify-content-around mb-5">
        
          <Col xs={12} lg={5} className="text-center">
            <Button
              type="submit"
              color="success"
              disabled={+idAreaLogged === +values.idAreaDestiny}
            >
              Agregar
            </Button>
          </Col>
        </Row>

        <ModalConfirmation
          modalTitle={"Crear"}
          modal={modalConfirmation}
          setModal={setModalConfirmation}
          crear={sendRequest}
          isEdit={false}
          values={values}
        >
          <div className="text-center">
            Esta seguro de continuar con el registro
          </div>
        </ModalConfirmation>
      </form>

      <ModalTableVer
        modal={modalTabla}
        setModal={setModalTabla}
        title="Empleados"
        cabeceras={cabecerasModalTabla}
        filtro={filtroModalTabla}
        parametros={paramsModalTabla}
        selectRegistro={selectEmpleado}
        backdrop={"static"}
        keyboard={false}
        btnClose={true}
        resetForm={resetForm}
      />
        </>
      )}
      
    </div>
  );
};

export default FormSolicitudes;
import {
  USER_TOKEN,
  USER_ID,
  USER_INFO,
  PROFILE_TYPE,
  LOGOUT_USER,
  PERMISSION
} from "../types"

export const initialState = {
  isFetching: false,
  errorMessage: '',
  token: '',
  id: '',
  profileType: {nameType: '', data: [], selectProfile: {}},
  permission: '',
  info: {name: '', avatar: ''}
}

export default function user(state = initialState, {type, payload}) {
  switch (type) {
    case USER_TOKEN:
      return {...state,token: payload}

    case USER_ID:
      return {...state,id: payload}

    case PROFILE_TYPE:
      return {...state,profileType: payload}

    case PERMISSION:
      return {...state, permission: payload}

    case USER_INFO:
      return {...state, info: payload}

    case LOGOUT_USER:
      return initialState;
        
    default:
      return state;
  }
}
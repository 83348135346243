import { Button } from "reactstrap";
import Articulos from "./Articulos";
import AreasR from "./Areas";

export default function Detalle({info, setInfo, periods, permissions}){
    return(
        <div>
            <div className="mb-2 text-dark d-flex flex-md-row flex-column justify-content-md-between">
                <p style={{fontSize: "22px"}}><strong>Requisición: </strong>{info.name}</p>
                <Button onClick={()=>setInfo({ id: "" })}>Regresar</Button>
            </div>
            <Articulos id={info.id} periods={periods} permissions={permissions} />
            <hr className="mt-5 mb-5"/>
            <AreasR id={info.id} permissions={permissions} />
        </div>
    )
}
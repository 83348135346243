import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { Formik, Form, useFormikContext } from "formik";
import { Col, Button, Alert, Collapse } from "reactstrap";

import { peticionesReceiver } from "../../../helpers/peticionesReceiver";

import Loader from "../../../components/Loader/Loader";
import Widget from "../../../components/Widget/Widget";
import Notification from "../../../components/Notification/Notification";
import ModalConfirmation from "../../../components/Modal/ModalConfirmation";
import { SelectTypeHeadSingle } from "../../../components/GenericInputsFormik";
import TableComponentOnePage from "../../../components/TableComponent/TableComponentOnePage";

const GruposNomina = ({ permissions }) => {
  const [modal, setModal] = useState(false);
  const API = peticionesReceiver();
  const [underGroupsTypes, setUnderGroupsTypes] = useState();
  const [loadingGroupsTypes, setLoadingGroupsTypes] = useState(true);
  const [unders, setUnders] = useState();
  const [loadingUnders, setLoadingUnders] = useState(true);
  const [editando, setEditando] = useState(false);
  const [filterKeyTypeUnderGroup, setFilterKeyTypeUnderGroup] = useState("");
  const [group, setGroup] = useState({
    idUnderGroup: "",
    idUnder: "",
    keyTypeUnderGroup: "",
  });
  const [collapse, setCollapse] = useState(false);
  const [status, setStatus] = useState("Closed");
  const onEntering = () => setStatus("Opening...");
  const onEntered = () => setStatus("Opened");
  const onExiting = () => setStatus("Closing...");
  const onExited = () => setStatus("Closed");
  const toggle = () => setCollapse(!collapse);
  const [cabeceras] = useState([
    "Id",
    "Tipo de Grupo de Nomina",
    "Concepto",
    "Nombre Concepto",
    "Editar",
    "Eliminar",
  ]);
  const [filtro] = useState([true, true, true, true, false, false]);
  const [parametros, setParametros] = useState({
    action: "datatable",
    table:
      "undersgroups A INNER JOIN types_undersgroups B on (A.keyTypeUnderGroup = B.keyTypeUnderGroup) INNER JOIN unders C on (A.idUnder = C.idUnder)",
    rows: "A.idUnderGroup,B.nameTypeUnderGroup,C.under, C.nameUnder,A.idUnder,A.keyTypeUnderGroup",
    // conditions: `A.enabled = 1 ` += filterKeyTypeUnderGroup !== "" ? `and A.keyTypeUnderGroup = ${filterKeyTypeUnderGroup}` : "",
    conditions: `A.enabled = 1 ${
      filterKeyTypeUnderGroup !== ""
        ? `and A.keyTypeUnderGroup = ${filterKeyTypeUnderGroup}`
        : ""
    }`,
    page: 0,
    records: 5,
    search: "",
    order: "",
  });

  const getUndersGroup = async () => {
    const data = {
      action: "select",
      table: "types_undersgroups",
      order: "nameTypeUnderGroup ASC",
    };
    API.peticion(data)
      .then((res) => {
        if (res.status === 200 && res.data.status === "success") {
          setUnderGroupsTypes(res.data.data);
          setLoadingGroupsTypes(false);
        } else {
          toast(
            <Alert color="danger">Error al consultar tipos de contactos</Alert>
          );
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  /* const getUnders = async () => {
        const data = {
            action: "select",
            table: "unders",
            order: "nameUnder ASC",
        }
        API.peticion(data).then((res) => {
            if(res.status === 200 && res.data.status === "success"){
                const datos = [];
                res.data.data.map(element => {
                datos.push([element['idUnder'],element['nameUnder']]);
                })
                setUnders(datos);    
                setLoadingUnders(false); 
            }else{
                toast(<Alert color="danger">Error al consultar tipos de contactos</Alert>)
            }
          })
          .catch((err) => {
            console.log(err);
          });
    }; */
  const getUnders = async () => {
    const params = {
      action: "multiselect",
      table: "unders",
      rows: "idUnder,concat(under,' - ',nameUnder) as name",
      conditions: "enabled = 1",
      order: "cast(under as float) asc",
    };
    API.peticion(params)
      .then((res) => {
        if (res.status === 200 && res.data.code === "200") {
          setUnders(res.data.data);
          setLoadingUnders(false);
        } else {
          toast(
            <Notification
              type={"consultar_error"}
              backMessage={res.data.message}
              withIcon
            />
          );
        }
      })
      .catch((err) => {
        toast(<Notification type={"consultar_servidor_error"} withIcon />);
      });
  };

  const FormSchema = Yup.object().shape({
    idUnder: Yup.number()
      .required("Seleccione una escolaridad")
      .min(1, "Seleccione una opcion"),
    keyTypeUnderGroup: Yup.number()
      .required("Seleccione una escolaridad")
      .min(1, "Seleccione una opcion"),
  });

  useEffect(() => {
    getUndersGroup();
    getUnders();
  }, []);
  useEffect(() => {
    if (collapse) {
      getUndersGroup();
      getUnders();
    }
  }, [collapse]);

  const limpia = (reset) => {
    setGroup({
      idUnderGroup: "",
      idUnder: "",
      keyTypeUnderGroup: "",
    });
    setEditando(false);
    setCollapse(false);
    reset();
  };
  const limpiaGuarda = (reset, grupo) => {
    setGroup({
      idUnderGroup: "",
      keyTypeUnderGroup: grupo.keyTypeUnderGroup,
      idUnder: "",
    });
    /* setEditando(false); */
    /* setCollapse(false); */
    /* let setFieldVal =  reset[1]
    setFieldVal("idUnder","") */
  };

  const agregar = async (grupo, reset) => {
    const data = {
      action: "insert",
      table: "undersgroups",
      rows: grupo,
      validate: [["idUnder", "keyTypeUnderGroup"]],
    };
    API.peticion(data)
      .then((res) => {
        if (res.status === 200 && res.data.status === "success") {
          toast(
            <Notification
              type={"agrega_exito"}
              backMessage={res.data.message}
              withIcon
            />
          );
          setParametros({
            action: "datatable",
            table:
              "undersgroups A INNER JOIN types_undersgroups B on (A.keyTypeUnderGroup = B.keyTypeUnderGroup) INNER JOIN unders C on (A.idUnder = C.idUnder)",
            rows: "A.idUnderGroup,B.nameTypeUnderGroup,C.under, C.nameUnder,A.idUnder,A.keyTypeUnderGroup",
            // conditions: `A.enabled = 1 ` += filterKeyTypeUnderGroup !== "" ? `and A.keyTypeUnderGroup = ${filterKeyTypeUnderGroup}` : "",
            conditions: `A.enabled = 1 ${
              filterKeyTypeUnderGroup !== ""
                ? `and A.keyTypeUnderGroup = ${filterKeyTypeUnderGroup}`
                : ""
            }`,
            page: 0,
            records: 5,
            search: "",
            order: "",
          });
          limpiaGuarda(reset, grupo);
        } else {
          toast(
            <Notification
              type={"agrega_error"}
              backMessage={res.data.message}
              withIcon
            />
          );
        }
      })
      .catch((err) => {
        toast(err);
        console.log(err);
      });
  };

  const modoEdicion = async (grupo) => {
    if (!collapse) {
      setGroup({
        idUnderGroup: grupo[0],
        idUnder: grupo[4],
        keyTypeUnderGroup: grupo[5],
      });

      setEditando(true);
      setCollapse(true);
    } else {
      toast(
        <Notification
          type="warning"
          backMessage="Guarde sus cambios antes de continuar"
        />
      );
    }
  };
  const editar = async (grupo, reset) => {
    const data = {
      action: "update",
      table: "undersgroups",
      rows: grupo,
      condition: {
        idUnderGroup: grupo.idUnderGroup,
      },
      validate: [["idUnder", "keyTypeUnderGroup"]],
    };
    API.peticion(data)
      .then((res) => {
        if (res.status === 200 && res.data.status === "success") {
          toast(
            <Notification
              type={"modifica_exito"}
              backMessage={res.data.message}
              withIcon
            />
          );
          setParametros({
            action: "datatable",
            table:
              "undersgroups A INNER JOIN types_undersgroups B on (A.keyTypeUnderGroup = B.keyTypeUnderGroup) INNER JOIN unders C on (A.idUnder = C.idUnder)",
            rows: "A.idUnderGroup,B.nameTypeUnderGroup,C.under, C.nameUnder,A.idUnder,A.keyTypeUnderGroup",
            // conditions: `A.enabled = 1 ` += filterKeyTypeUnderGroup !== "" ? `and A.keyTypeUnderGroup = ${filterKeyTypeUnderGroup}` : "",
            conditions: `A.enabled = 1 ${
              filterKeyTypeUnderGroup !== ""
                ? `and A.keyTypeUnderGroup = ${filterKeyTypeUnderGroup}`
                : ""
            }`,
            page: 0,
            records: 5,
            search: "",
            order: "",
          });
          limpia(reset());
          setCollapse(false);
        } else {
          toast(
            <Notification
              type={"modifica_error"}
              backMessage={res.data.message}
              withIcon
            />
          );
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const changeFilter = (event) => {
    const { name, value } = event.target;
    
    setFilterKeyTypeUnderGroup(value);
    
  };

  useEffect(() => {
    setParametros({
      action: "datatable",
      table:
        "undersgroups A INNER JOIN types_undersgroups B on (A.keyTypeUnderGroup = B.keyTypeUnderGroup) INNER JOIN unders C on (A.idUnder = C.idUnder)",
      rows: "A.idUnderGroup,B.nameTypeUnderGroup,C.under, C.nameUnder,A.idUnder,A.keyTypeUnderGroup",
      // conditions: `A.enabled = 1 ` += filterKeyTypeUnderGroup !== "" ? `and A.keyTypeUnderGroup = ${filterKeyTypeUnderGroup}` : "",
      conditions: `A.enabled = 1 ${
        filterKeyTypeUnderGroup !== ""
          ? `and A.keyTypeUnderGroup = ${filterKeyTypeUnderGroup}`
          : ""
      }`,
      page: 0,
      records: 5,
      search: "",
      order: "",
    });

  },[filterKeyTypeUnderGroup])
  return (
    <div>
      {!loadingGroupsTypes && !loadingUnders ? (
        <Formik
          initialValues={group}
          validationSchema={FormSchema}
          onSubmit={(fields) => {
            /* submitFunction(fields,resetForm); */
            setModal(true);
          }}
          onReset={(fields, { resetForm }) => {}}
          render={({
            errors,
            status,
            touched,
            values,
            resetForm,
            setFieldValue,
            handleBlur,
          }) => (
            <Widget className="widget-p-md">
              <div className="headline-1 text-center mb-3">GRUPOS DE NOMINA</div>
              <div className="headline-2 text-center">Filtro por grupo</div>
              <SelectTypeHeadSingle
                label="Tipo de grupo"
                isRequired={true}
                inputName="filterKeyTypeUnderGroup"
                optionsArray={underGroupsTypes}
                defaultOption="Seleccione un tipo de grupo"
                onChangeMethod={(event) => {
                  changeFilter(event);
                }}
                isDisabled={false}
                value={filterKeyTypeUnderGroup}
                optionValue="keyTypeUnderGroup"
                optionName="nameTypeUnderGroup"
              />

              {permissions.CON && (
                <TableComponentOnePage
                  titulo=""
                  cabecerasTabla={cabeceras}
                  filtro={filtro}
                  editar={modoEdicion}
                  parametros={parametros}
                  limpia={limpia}
                  reset={resetForm}
                  permissions={permissions}
                />
              )}

              <Form>
                <ModalConfirmation
                  modalTitle={editando ? "Editar" : "Crear"}
                  modal={modal}
                  setModal={setModal}
                  crear={agregar}
                  editar={editar}
                  isEdit={editando}
                  values={values}
                  reset={resetForm}
                >
                  {editando ? (
                    <div className="d-flex justify-content-center">
                      <h6>¿Está seguro de editar el registro?</h6>
                    </div>
                  ) : (
                    <div className="d-flex justify-content-center">
                      <h6>¿Desea continuar con el registro?</h6>
                    </div>
                  )}
                </ModalConfirmation>

                {permissions.INS && (
                  <div className="d-grid d-flex justify-content-center justify-content-sm-end">
                    <Button
                      color="add"
                      onClick={toggle}
                      style={{ marginBottom: "1rem" }}
                      disabled={collapse}
                    >
                      Agregar
                    </Button>
                  </div>
                )}

                <Collapse
                  isOpen={collapse}
                  onEntering={onEntering}
                  onEntered={onEntered}
                  onExiting={onExiting}
                  onExited={onExited}
                >
                  <div>
                    <Col className="d-grid d-flex justify-content-sm-end justify-content-center">
                      <h6 className=" text-center">
                        Estado:{" "}
                        <span className="text-primary">
                          {editando ? "Editando" : "Guardando"}{" "}
                        </span>{" "}
                      </h6>
                    </Col>
                    <div className="headline-1 text-center">
                      Grupo de nomina
                    </div>
                    <SelectTypeHeadSingle
                      label="Tipo de grupo"
                      isRequired={true}
                      inputName="keyTypeUnderGroup"
                      optionsArray={underGroupsTypes}
                      defaultOption="Seleccione un tipo de grupo"
                      onChangeMethod={(event) => {
                        const { name, value } = event.target;
                        setFieldValue(name, value);
                      }}
                      onBlur={handleBlur}
                      isDisabled={false}
                      value={values.keyTypeUnderGroup}
                      touched={touched.keyTypeUnderGroup}
                      errors={errors.keyTypeUnderGroup}
                      optionValue="keyTypeUnderGroup"
                      optionName="nameTypeUnderGroup"
                    />

                    <SelectTypeHeadSingle
                      label="Concepto"
                      isRequired={true}
                      inputName="idUnder"
                      optionsArray={unders}
                      defaultOption="Seleccione un concepto"
                      onChangeMethod={(event) => {
                        const { name, value } = event.target;
                        setFieldValue(name, value);
                        
                      }}
                      onBlur={handleBlur}
                      isDisabled={false}
                      value={values.idUnder}
                      touched={touched.idUnder}
                      errors={errors.idUnder}
                      optionValue="idUnder"
                      optionName="name"
                    />
                  </div>
                  <div className="row mt-3">
                    <div className="col-sm-6 order-2 order-sm-1 text-center text-sm-left mb-4">
                      <Button
                        color="danger"
                        type="reset"
                        onClick={() => limpia(resetForm)}
                      >
                        Cancelar
                      </Button>
                    </div>
                    <div className="col-sm-6 order-1 order-sm-2 text-center text-sm-right mb-4">
                      <Button color="success" type="submit">
                        Guardar
                      </Button>
                    </div>
                  </div>
                </Collapse>
                <FormikContact info={group} />
              </Form>
            </Widget>
          )}
        />
      ) : (
        <Loader />
      )}
    </div>
  );
};
const FormikContact = ({ info }) => {
  const { setFieldValue } = useFormikContext();
  useEffect(() => {
    if (info) {
      
      setFieldValue("idUnderGroup", info.idUnderGroup);
      setFieldValue("idUnder", info.idUnder);
      setFieldValue("keyTypeUnderGroup", Number(info.keyTypeUnderGroup));
    }
  }, [info]);
  return null;
};
export default GruposNomina;

import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import * as Yup from "yup";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { Col, Row, Button, Collapse } from "reactstrap";
import {
    TextInput,
    RadioInput,
    SelectTypeHeadSingle,
} from "../../../components/GenericInputsFormik/index";
import QRCode from "qrcode.react";
import ModalTableVer from "../../../components/Modal/ModalTableVer";
import { peticionEncript } from "../../../helpers/peticionesEncripted";
import Notification from "../../../components/Notification/Notification";
import ModalConfirmation from "../../../components/Modal/ModalConfirmation";
import TableComponentVer from "../../../components/TableComponent/TableComponentVer";
import fumpPdf from "../../personal/expediente-personal/pdf/fumpPdf";
import FullScreenLoader from "../../../components/Loader/FullScreenLoader";
import { pdf } from "@react-pdf/renderer";
import ModalViewFile from "../../../components/Modal/ModalViewFile";
import { NumberInput } from "../../../components/GenericInputsFormik/NumberInput";
import FullFormLoader from "../../../components/Loader/FullFormLoader";
import { peticionesReceiver } from "../../../helpers/peticionesReceiver";
import { fDateInput, validValue } from "../../../libs/tools/format";
import { cancelFUMP } from "../../../libs/reports/fump/createFump";
import ModalEditarPlaza from "./extra-forms/ModalEditarPlaza";

const defaultForm = {
    idJobStaff: "", //jobstaff
    idStaff: "",
    numberJobPositions: "", //jobstaff, jobpositions -> idJobpPosition
    nameJob: "", //jobs -> keyJob <-> jobpositions
    idJobPosition: "",
    tabulador: "", //salaries -> level - sublevel - range -> idSalary
    areaPlaza: "", //?
    keyMove: "", //jobstaff, types_move (select)
    keyAssignment: "", //jobstaff, types_assigment (select)
    folio: "", //jobstaff
    idArea: "", //jobstaff, areas (select)
    startDate: "", //jobstaff
    endDate: "", //jobstaff
    idAreaComission: "", //jobstaff, areas (idArea) (select)
    startComissionDate: "", //jobstaff
    endComissionDate: "", //jobstaff
    keyJobRole: "", //jobstaff ?
    startRoleDate: "", //jobstaff
    endRoleDate: "", //jobstaff
    idBuilding: "", //buildings
    adjustPay: "0", //jobstaff boolean
    completePay: "0", //jobstaff boolean
    notes: "", //jobstaff
    lapseFUMP: "", //jobstaff
    vigente: 1, //valid
    keyStatJobStaff: "",
    firedDate: "",
    keyStat: "",
    startJobDate: "",
    idJobSchedule: "",
    minVital: "",
};

async function getBackInfo(route, params) {
    let info = [];
    const dataEncrypted = peticionEncript(params);
    await axios
        .post(process.env.REACT_APP_API + route, dataEncrypted)
        .then((res) => {
            if (res.status === 200 && res.data.status === "success") {
                info = res.data.data;
            } else {
                toast(
                    <Notification
                        type={"consultar_error"}
                        backMessage={res.data.message}
                        withIcon
                    />,
                    { closeButton: false }
                );
            }
        })
        .catch((err) => {
            toast(<Notification type={"consultar_servidor_error"} withIcon />, {
                closeButton: false,
            });
        });
    return info;
}

const FormSchema = Yup.object().shape({
    keyMove: Yup.string().required("Seleccione una opción"),
    keyAssignment: Yup.string().required("Seleccione una opción"),
    // folio: Yup.string().required("Ingrese un folio").max(20, "Folio invalido"),
    idArea: Yup.string().required("Seleccione una opción"),
    // idBuilding: Yup.string().required("Seleccione un edificio"),
    startDate: Yup.date().required("Seleccione una fecha"),
    endDate: Yup.date()
        .required("Seleccione una fecha")
        .min(
            Yup.ref("startDate"),
            ({ min }) => "La fecha debe ser posterior a la fecha de inicio"
        ),
    startComissionDate: Yup.date().when("idAreaComission", {
        is: (val) => val !== undefined && val !== "",
        then: Yup.date()
            .required("Seleccione una fecha")
            .min(Yup.ref("startDate"), "Fecha fuera del rango de trabajo")
            .max(Yup.ref("endDate"), "Fecha fuera del rango de trabajo"),
    }),
    // endComissionDate: Yup.date().when("idAreaComission", {
    //     is: (val) => val !== undefined && val !== "",
    //     then: Yup.date()
    //         .required("Seleccione una fecha")
    //         .min(
    //             Yup.ref("startComissionDate"),
    //             "La fecha debe ser posterior a la fecha de inicio"
    //         )
    //         .max(Yup.ref("endDate"), "Fecha fuera del rango de trabajo"),
    // }),
    // startRoleDate: Yup.date().when("keyJobRole", {
    //     is: (val) => val !== undefined && val !== "",
    //     then: Yup.date()
    //         .required("Seleccione una fecha")
    //         .min(Yup.ref("startDate"), "Fecha fuera del rango de trabajo")
    //         .max(Yup.ref("endDate"), "Fecha fuera del rango de trabajo"),
    // }),
    // endRoleDate: Yup.date().when("keyJobRole", {
    //     is: (val) => val !== undefined && val !== "",
    //     then: Yup.date()
    //         .required("Seleccione una fecha")
    //         .min(
    //             Yup.ref("startRoleDate"),
    //             "La fecha debe ser posterior a la fecha de inicio"
    //         )
    //         .max(Yup.ref("endDate"), "Fecha fuera del rango de trabajo"),
    // }),
    firedDate: Yup.date().when("keyMove", {
        is: (v)=> [2, 3].includes(+v),
        then: Yup.date().required("Seleccione una fecha"),
    }),
    keyStat: Yup.string().when("keyMove", {
        is: (v)=> [2, 3, 11].includes(+v),
        then: Yup.string().required("Seleccione una motivo"),
    }),
    idJobSchedule: Yup.string().required("Seleccione un horario"),
    startJobDate: Yup.date().when("keyMove", {
        is: "5",
        then: Yup.date().required("Seleccione una fecha"),
    }),
    minVital: Yup.number().when("keyMove", {
        is: "18",
        then: Yup.number()
            .required("Ingrese un porcentaje")
            .min(30, "El valor mínimo permitido es 30.0")
            .max(100, "El valor mínimo permitido es 100.0"),
    }),
    // keyStatJobStaff: Yup.string().required("Seleccione un estatus para la plaza"),
});

const specialMoves = [1, 5, 6, 7, 17, 20, 22];
const receiver = "receiver/receiver.php";
const special = "app/facades/jobs/jobsF.php";

export const ExpedienteNominaPlazas = ({ idStaff, updateData, permissions }) => {
    const API = peticionesReceiver();
    const [isOpen, setIsOpen] = useState(false);
    const [showButtons, setShowButtons] = useState(false);
    const [loading, setLoading] = useState(true);
    const [loadingMsg, setLoadingMsg] = useState("");
    const [fsLoading, setFsLoading] = useState(false);
    const [fsLoaderMessage, setFsLoaderMessage] = useState("");
    const [title, setTitle] = useState("");
    const [modal, setModal] = useState(false);
    const [modalTabla, setModalTabla] = useState(false);
    const [modalFile, setModalFile] = useState(false);
    const [linkFile, setlinkFile] = useState("");
    const [tiposMovimientos, setTiposMovimientos] = useState([]);
    const [tiposAsignaciones, setTiposAsignaciones] = useState([]);
    const [horarios, setHorarios] = useState([]);
    const [areas, setAreas] = useState([]);
    const [statusStaff, setStatusStaff] = useState([]);
    const [jobsRoles, setJobsRoles] = useState([]);
    const [edificios, setEdificios] = useState([]);
    const [finalData, setFinalData] = useState("");
    const [finalDataPlaza, setfinalDataPlaza] = useState();
    const [disbledForm, setDisbledForm] = useState(false);
    const [infoPlaza, setInfoPlaza] = useState(false);
    const [numPlaza, setNumPlaza] = useState(false);
    const [disbledReBa, setDisbledReBa] = useState(false); //reingreso/baja
    const [disableMinVit, setDisabledMinVit] = useState(false); // minimo vilta
    const [staffInfo, setStaffinfo] = useState(null);
    const [idSchedule9To6, setIdSchedule9To6] = useState();
    // const [disableDates, setDisableDates] = useState(false);
    const [modalRenovar, setModalRenovar] = useState(false);
    const [existeAlta, setExisteAlta] = useState(false); // Al ver una alta
    // const [statusPlaza, setstatusPlaza] = useState([]);
    // const [idstatusActiva, setidStatusActiva] = useState("");
    const [modalEditarPlaza, setModalEditarPlaza] = useState(false);
    const refScroll = useRef(null);
    const selectRef = useRef(null);

    const cabeceras = [
        "Id",
        "Num. Plaza", //numberJobPositions [jobpositions]
        "Adscripción Nominal", //name [areas]
        "Puesto", //nameJob [jobs]
        "Movimiento", //nameMove [types_move]
        "Nivel Rango", //"level - sublevel" [salaries]
        "Vigente", //valid
        "Folio acuerdo", //folio
        "Fecha Inicio", //startDate
        "Fecha Termino", //endDate
        "Fecha Baja", //firedDate
        "Observaciones", //notes
        "Fecha Registro",
        "FUMP",
        "Ver",
    ];

    const filtro = [ true, true, true, true, true, true, true, true, true, true, true, true, false, true ];

    const [parametros, setParametros] = useState({
        action: "datatable",
        table:
        `jobstaff A INNER JOIN    jobpositions B ON A.idJobPosition = B.idJobPosition INNER JOIN    areas C ON A.idArea = C.idArea INNER JOIN    jobs D ON A.keyJob = D.keyJob INNER JOIN    types_move E ON A.keyMove = E.keyMove INNER JOIN    staff H USING (idStaff) LEFT JOIN    bookcase F ON A.idJobStaff = F.reference AND F.keyFileType = 18 AND F.enabled = 1 LEFT JOIN    files G ON F.idBook = G.idBook AND G.enabled = 1 LEFT JOIN    staffincomedate I ON I.idJobStaff = A.idJobStaff AND I.enabled = 1 LEFT JOIN    clouseresjobstaff J ON J.idJobStaffCierre = A.idJobStaff AND J.enabled = 1`,
        rows: "A.idJobstaff,B.numberJobPositions,C.name as areaPlaza,D.nameJob,E.nameMove,CONCAT(A.level,'',if(A.subLevel is not null and A.subLevel != '',CONCAT('-',A.subLevel),''),'-',A.rango) AS lvlsb,IF(A.valid=1,'SI','NO') as valid,A.folio,A.startDate, A.endDate,if(A.keyMove = 2,ifnull(I.fireDate,H.firedDate),if(A.keyMove=3,ifnull(J.firedDate,''),'')) firedDate,A.notes,G.src,A.created",
        conditions: `A.enabled = 1 AND A.idStaff=${idStaff}`,
        page: 0,
        records: 5,
        search: "",
        order: "A.valid DESC, A.created DESC, A.startDate DESC",
    });

    // *TABLA MODAL* //
    const cabecerasModal = [
        "Id",
        "Num. Plaza",
        "Categoría",
        "Tipo",
        "Nivel",
        "Adscripción Nominal",
        "Observaciones",
        "Seleccionar",
    ];
    const filtroModal = [true, true, true, true, true, true, true];
    const [parametrosModal] = useState({
        action: "datatable",
        table: "jobspositionsvacant A LEFT JOIN jobstaff B ON B.idJobStaff = A.idJobStaff LEFT JOIN areas C ON C.idArea = A.idAreaJobPosition",
        rows: "A.idJobPosition, A.numberJobPositions, A.nameJob, A.namePositionType, CONCAT_WS('-',A.level,IF(!ISNULL(A.subLevel) AND A.subLevel != '',A.subLevel,NULL),A.rango) AS lvlsb, COALESCE(C.name,'SIN INFORMACION') AS nameArea, A.notes, A.idAreaJobPosition AS idAreaNominal, B.idJobSchedule AS idHorario",
        conditions: "",
        page: 0,
        records: 5,
        search: "",
        order: "",
    });

    //*************** CONSULTAS INICIALES **************** */
    useEffect(() => {
        getTiposMovimientos();
        getAreas();
        getTiposAsignaciones();
        getJobsRoles();
        getStatusStaff();
        getEdificios();
        getHorarios();
        // getStatusJobStaff();
        getStaffInfo();
    }, []);

    const getTiposMovimientos = async () => {
        const param = {
            action: "datatable",
            table: "jobstaff",
            rows: "*",
            conditions: `enabled = 1 AND idStaff=${idStaff} `,
            page: 0,
            records: 5,
            search: "",
            order: "",
        };
        let cantidad = await getBackInfo(receiver, param);

        const params = {
            action: "select",
            table: "types_move",
            condition: {
                enabled: 1,
                keyModule: 313,
            },
            order: "nameMove ASC",
        };
        let data = await getBackInfo(receiver, params);
        if (cantidad.length != 0) {
            setTiposMovimientos(data);
        } else {
            setTiposMovimientos([data[0]]);
        }
    };

    const getTiposAsignaciones = async () => {
        const params = {
            action: "select",
            table: "types_assignment",
            order: "nameAssignment ASC",
        };
        let data = await getBackInfo(receiver, params);
        setTiposAsignaciones(data);
    };

    const getAreas = async () => {
        const params = { action: "select", table: "areas", order: "name ASC" };
        let data = await getBackInfo(receiver, params);
        setAreas(data);
    };

    const getJobsRoles = async () => {
        const params = { action: "select", table: "jobs", order: "nameJob ASC" };
        let data = await getBackInfo(receiver, params);
        setJobsRoles(data);
    };

    const getEdificios = async () => {
        const params = {
            action: "select",
            table: "buildings",
            order: "nameBuilding ASC",
        };
        let data = await getBackInfo(receiver, params);
        setEdificios(data);
    };

    const getStatusStaff = async () => {
        const params = {
            action: "select",
            table: "status",
            condition: {
                keyTypeStat: 1,
            },
            order: "nameStat ASC",
        };
        let data = await getBackInfo(receiver, params);
        const cleandata = data.filter(ca => !["CESE", "CONCLUSIÓN", "INACTIVO", "LICENCIA"].includes(ca.nameStat));
        // data = data.filter((el) => +el.keyStat !== 1);
        setStatusStaff(cleandata);
    };

    // const getStatusJobStaff = async () => {
    //   const params = {
    //     action: "select",
    //     table: "status",
    //     condition: {
    //       keyTypeStat: 39,
    //     },
    //     order: "nameStat ASC",
    //   };
    //   let data = await getBackInfo(receiver, params);
    //   let idActiva = data.find((status) => status.nameStat === "VIGENTE");
    //   setFieldValue("keyStatJobStaff", idActiva ? idActiva.keyStat : "");
    //   setidStatusActiva(idActiva ? idActiva.keyStat : "");
    //   setstatusPlaza(data);
    // };

    const getHorarios = async () => {
        const params = {
            action: "multiselect",
            table:
                "jobschedule j INNER JOIN types_schedule ts ON j.keyTypeSchedule = ts.keyTypeSchedule",
            rows: "idJobSchedule,j.keyTypeSchedule,nameJobSchedule, nameTypeSchedule",
            conditions: "j.enabled=1",
        };
        let data = await getBackInfo(receiver, params);
        data.forEach((horario) => {
            if (horario.nameJobSchedule === "09:00 - 18:00") {
                setIdSchedule9To6(horario.idJobSchedule);
            }
        });

        setHorarios(data);
    };

    async function getStaffInfo() {
        const params = {
            action: "datatable",
            table: "staffview",
            rows: "*",
            conditions: `idStaff = ${idStaff}`,
            page: 0,
            records: 5,
            search: "",
            order: "",
        };

        const res = await getBackInfo(receiver, params);
        if(res.length > 0){
            setStaffinfo(res[0]);
        }
        setLoading(false);
    }
    //*************** END CONSULTAS INICIALES **************** */

    //*************** FORM **************** */
    const {
        handleSubmit,
        values,
        handleBlur,
        errors,
        touched,
        resetForm,
        setFieldValue,
        handleReset,
        setFieldTouched,
    } = useFormik({
        initialValues: defaultForm,
        validationSchema: FormSchema,
        onSubmit: (values) => enviaDatos(),
        onReset: () => {
            setTitle("");
            setDisbledForm(false);
            setDisbledReBa(false);
            setDisabledMinVit(false);
        },
        enableReinitialize: true,
    });

    const enviaDatos = async () => {
        setFinalData((prevState) => ({
            ...prevState,
            idJobStaff: values.idJobStaff,
            idStaff: values.idStaff | idStaff,
            idJobPosition:
                values.idJobPosition && values.idJobPosition !== ""
                    ? values.idJobPosition
                    : finalDataPlaza.idJobPosition
                        ? finalDataPlaza.idJobPosition
                        : "",
            idArea: values.idArea,
            idBuilding: values.idBuilding,
            keyMove: values.keyMove,
            keyAssignment: values.keyAssignment,
            folio: values.folio,
            notes: values.notes,
            startDate: values.startDate,
            endDate: values.endDate,
            adjustPay: values.adjustPay,
            completePay: values.completePay,
            idAreaComission: values.idAreaComission,
            startComissionDate: values.startComissionDate,
            endComissionDate: values.endComissionDate,
            keyJobRole: values.keyJobRole,
            startRoleDate: values.startRoleDate,
            endRoleDate: values.endRoleDate,
            idJobSchedule: values.idJobSchedule,
            lapseFUMP: values.lapseFUMP,
            valid: values.vigente,
            keyStatJobStaff: 164,
            keyStatStaff: values.keyStat, ///baja
            firedDate: values.firedDate, ///baja
            startJobDate: values.startJobDate, ///reingreso
            minVital: values.minVital, // minimo vital
        }));
        setLoading(true);
        setLoadingMsg("Generando reporte de movimiento...");
        // const infoLicencia = await getInfoLicencia();
        let backInformation = await getInfoPreFump({
            idJobPosition: values.idJobPosition,
        });
        if(backInformation.length > 0){
            try {
                const blobPdf = await pdf(
                    fumpPdf({...backInformation[0] }, generatedQrImage(), true)
                ).toBlob();
                const blobUrl = URL.createObjectURL(blobPdf);
                setShowButtons(true);
                watchFump(blobUrl);
            } catch (e) {
                toast(<Notification type={"Ocurrió un problema al previsualizar el reporte"} withIcon />);
            }
        }
        setLoadingMsg("");
        setLoading(false);
    };

    const openForm = () => {
        if(!staffInfo || !staffInfo.idArea){
            toast(
                <Notification
                    type="info"
                    backMessage="El empleado no ha sido asignado a una plaza anteriormente. Para realizar una ALTA de plaza al empleado, diríjase al módulo Expediente de Personal."
                    withIcon
                />,
                {
                    closeButton: false,
                }
            );
            return;
        }
        setTitle("Asignando plaza");
        setFieldValue("idArea", staffInfo.idArea ? staffInfo.idArea : "");
        setFieldValue("idJobSchedule", idSchedule9To6 ? idSchedule9To6 : "");
        setIsOpen(true);
    };

    const onChange = ({ target }) => {
        const { name, value } = target;
        setFieldValue(name, value);
        isNewJobPosition(target);
        if (value === 0 || value === "") {
            switch (name) {
                case "startDate":
                    if (+values.keyAssignment !== 2 && values.endDate !== "2099-12-31") setFieldValue("endDate", "");
                    break;
                case "startComissionDate":
                    setFieldValue("endComissionDate", "");
                    break;
                case "startRoleDate":
                    setFieldValue("endRoleDate", "");
                    break;
                case "idAreaComission":
                    setFieldValue("startComissionDate", "");
                    setFieldTouched("startComissionDate", false, false);
                    setFieldValue("endComissionDate", "");
                    setFieldTouched("endComissionDate", false, false);
                    break;
                case "keyJobRole":
                    setFieldValue("startRoleDate", "");
                    setFieldTouched("startRoleDate", false, false);
                    setFieldValue("endRoleDate", "");
                    setFieldTouched("endRoleDate", false, false);
                    break;
                case "keyMove":
                    const tt = title;
                    handleReset();
                    openForm();
                    break;
                default:
            }
        } else {
            switch(name){
                case "keyAssignment":
                    if ([2, 5].includes(+value)) {
                        const dst = fDateInput(new Date());
                        setFieldValue("startDate", dst);
                        setFieldValue("endDate", "2099-12-31"); /// en el campo, desactivar si es indefinido
                    }
                    break;
                // case "firedDate":
                //     if(+values.keyMove === 3){
                //         const frd = new Date(`${value} 00:00:00`);
                //         const frf = fDateInput(frd, -1);
                //         setFieldValue("endDate", frf);
                //     }
                // break;
                default:
            }
        }
    };

    async function isNewJobPosition({ name, value }) {
        if (name === "keyMove" && value) {
            setFieldValue("firedDate", "");
            setFieldValue("keyStat", "");
            if (specialMoves.includes(value)) {
                const titulo = title;
                resetForm();
                setModalTabla(true);
                setTitle(titulo);
                setFieldValue("keyMove", value);
            } else {
                switch (+value) {
                    case 4:
                    case 10:
                        setModalRenovar(true);
                        break;
                    default:
                        const exist = await getPlazaActiva(value);
                        if (exist) {
                            switch (+value) {
                                case 18:
                                    setDisabledMinVit(true);
                                    break;
                                case 11:
                                    setFieldValue("keyStat", 1);
                                case 2:
                                case 15:
                                    const ewe = tiposAsignaciones.find((d) => d.nameAssignment === (+value === 11 ? "EVENTUAL" : "PERMANENTE"));
                                    if (ewe) {
                                        setFieldValue("keyAssignment", ewe.keyAssignment);
                                    }
                                    break;
                                default:
                            }
                        } else {
                            setFieldValue("keyMove", "");
                        }
                }
            }
            setDisbledForm([2, 3].includes(+value));
        }
    }

    const getPlazaActiva = async (type) => {
        const param = {
            action: "datatable",
            table:
                "jobstaff A INNER JOIN jobpositions B ON A.idJobPosition = B.idJobPosition INNER JOIN jobs C ON A.keyJob = C.keyJob INNER JOIN areas D ON A.idArea = D.idArea",
            rows: "A.idJobStaff,A.idJobPosition,A.keyAssignment,A.folio,A.idArea,A.startDate,A.endDate,A.idAreaComission,A.startComissionDate,A.endComissionDate,A.keyJobRole,A.startRoleDate,A.endRoleDate,A.idBuilding,A.adjustPay,A.completePay,A.notes,A.lapseFUMP,A.idJobSchedule,A.valid,B.numberJobPositions,A.keyMove,A.minVital,C.nameJob, CONCAT(A.level,'',IF(ISNULL(A.subLevel) OR A.subLevel = '','',CONCAT('-',A.subLevel)),'-',A.rango) AS tabulador, D.name AS areaPlaza",
            conditions: `A.enabled = 1 AND A.idStaff = ${idStaff} AND A.valid = 1`,
            page: 0,
            records: 5,
            search: "",
            order: "",
        };
        setLoading(true);
        setLoadingMsg("Consultando plaza activa...");
        let registro = await getBackInfo(receiver, param);
        setLoadingMsg("");
        setLoading(false);
        if (registro.length > 0) {
            setfinalDataPlaza(registro[0]);
            setFieldValue("numberJobPositions", registro[0].numberJobPositions);
            setFieldValue("idJobPosition", registro[0].idJobPosition);
            setFieldValue("keyAssignment", [5, 6].includes(+registro[0].keyAssignment) ? registro[0].keyAssignment : 6);
            setFieldValue("folio", registro[0].folio);
            setFieldValue("idArea", registro[0].idArea);
            setFieldValue("startDate", registro[0].startDate);
            setFieldValue("endDate", registro[0].endDate);
            setFieldValue("idAreaComission", registro[0].idAreaComission);
            setFieldValue("startComissionDate", registro[0].startComissionDate);
            setFieldValue("endComissionDate", registro[0].endComissionDate);
            setFieldValue("keyJobRole", registro[0].keyJobRole);
            setFieldValue("startRoleDate", registro[0].startRoleDate);
            setFieldValue("endRoleDate", registro[0].endRoleDate);
            setFieldValue("idBuilding", registro[0].idBuilding);
            setFieldValue("adjustPay", registro[0].adjustPay);
            setFieldValue("completePay", registro[0].completePay);
            // setFieldValue("notes", registro[0].notes);
            setFieldValue("idJobSchedule", registro[0].idJobSchedule || idSchedule9To6);
            setFieldValue("lapseFUMP", registro[0].lapseFUMP);
            // setFieldValue("vigente", registro[0].valid);
            //setFieldTouched("minVital", registro[0].minVital);
            setFieldValue("nameJob", registro[0].nameJob);
            setFieldValue("tabulador", registro[0].tabulador);
            setFieldValue("areaPlaza", registro[0].areaPlaza);
            
            return true;
        } else {
            toast(
                <Notification
                    type="warning"
                    backMessage="El empleado no cuenta con una plaza activa"
                    withIcon
                />,
                {
                    closeButton: false,
                }
            );
        }
        return false;
    };

    const editInfo = async () => {
        setFsLoaderMessage("Asignando plaza");
        setFsLoading(true); // Bloquear la pantalla
        let info = { ...finalData };
        delete info.keyStat;
        if ([2, 3, 11].includes(+finalData.keyMove)) {
            info.keyStatStaff = values.keyStat;
        }
        if (![2, 3].includes(+finalData.keyMove)) {
            delete info.firedDate;
        }
        if (+finalData.keyMove !== 5) {
            delete info.startJobDate;
        }
        if (+finalData.keyMove !== 18) {
            info.minVital = 0;
        } else {
            info.idJobStaff = 0;
        }
        const params = {
            action: "saveJobStaff",
            table: "jobstaff",
            rows: info,
            validate: [],
        };
        const dataEncrypted = peticionEncript(params);
        try {
            const res = await axios.post(
                `${process.env.REACT_APP_API}app/facades/jobs/jobsF.php`,
                dataEncrypted
            );
            if (res.status === 200 && res.data.status === "success") {
                const success = await getFump(res.data.data);
                setParametros({ ...parametros });
                updateData(res.data.data.idStaff, res.data.data.idAreaComission);
                setIsOpen(false);
                setModalFile(false);
                resetForm();
                if (success) {
                    toast(
                        <Notification
                            type="modifica_exito"
                            backMessage="Plaza asignada"
                            withIcon
                        />,
                        {
                            closeButton: false,
                        }
                    );
                } else {
                    toast(
                        <Notification
                            type="modifica_error"
                            backMessage="Plaza asignada sin el reporte de movimineto"
                            withIcon
                        />,
                        {
                            closeButton: false,
                        }
                    );
                }
            } else throw new Error(res.data.message);
        } catch (error) {
            toast(
                <Notification
                    type={"modifica_error"}
                    backMessage={error.message}
                    withIcon
                />,
                {
                    closeButton: false,
                }
            );
        }

        setFsLoading(false);
    };

    const selectPlaza = (el) => {
        setFieldValue("idJobPosition", el[0]);
        setFieldValue("numberJobPositions", `${el[1]}`);
        setFieldValue("nameJob", el[2]);
        setFieldValue("tabulador", el[4]);
        setFieldValue("areaPlaza", el[5]);
        setFieldValue("idArea", el[7]);
        setFieldValue("idJobSchedule", el[8] ? el[8] : idSchedule9To6);
        setModalTabla(false);
    };

    const limpia = (reset) => {
        setTitle("");
        reset();
        setIsOpen(false);
        // setDisableDates(false);
        setModalFile(false);
    };

    //*************** END FORM **************** */

    //*************** FUMP **************** */
    async function getInfoPreFump() {
        const params = {
            action: "getDatosFUMP",
            rows: {
                idStaff: idStaff,
                idJobPosition: values.idJobPosition,
                idArea: values.idArea,
                keyMove: values.keyMove,
                keyAssignment: values.keyAssignment,
                folio: values.folio,
                startDate: values.startDate,
                endDate: values.endDate,
                keyJobRole: values.keyJobRole,
                startRoleDate: values.startRoleDate,
                endRoleDate: values.endRoleDate,
                keyStatStaff: values.keyStat, ///baja
                firedDate: values.firedDate, ///baja
                motivoBaja:  [2, 3].includes(+values.keyMove) ? selectRef.current.state.text : "", // baja
                startJobDate: values.startJobDate, ///reingreso
                minVital: values.minVital, // minimo vital
                lapseFUMP: values.lapseFUMP,
                fechaCaptura: values.startDate,
                idJobSchedule: values.idJobSchedule,
            },
        };
        
        return await getBackInfo("app/facades/jobs/jobsF.php", params);
    }

    const getFump = async (staff) => {
        setFsLoaderMessage("Generando reporte de movimiento");
        const params = {
            action: "getDatosFUMP",
            rows: {
                idJobStaff: staff.idJobStaff,
                idStaff: idStaff,
            },
        };
        // const infoLicencia = await getInfoLicencia();
        const data = await getBackInfo("app/facades/jobs/jobsF.php", params);
        if (data.length > 0) {
            try {
                let blobPdf = await pdf(fumpPdf({...data[0] }, generatedQrImage())).toBlob();
                if (blobPdf != undefined && typeof blobPdf === "object") {
                    await saveFUMP(blobPdf, staff.idJobStaff);
                    return blobPdf;
                }
            } catch (e) {
                console.error(e);
            }
        }
        return false;
    };

    // async function getInfoLicencia(){
    //     const params = {
    //         action: "datatable",
    //         table: "leaves A LEFT JOIN leavesdays B USING(idLeave) LEFT JOIN types_applicationsalary C USING(keyTypeApplicationSalary) LEFT JOIN types_leaves D USING(keyTypeLeave)",
    //         rows: " A.idLeave, A.startDate, A.endDate, GROUP_CONCAT(C.nameTypeApplicationSalary separator ', ') AS tiposSalario, D.nameTypeLeave",
    //         conditions: `idStaff = ${idStaff} AND A.enabled = 1`,
    //         page: 0,
    //         records: 1,
    //         search: "",
    //         order: "A.created DESC",
    //         group: "A.idLeave",
    //     }
    //     if(+values.keyMove === 11){
    //         const res = await getBackInfo("receiver/receiver.php", params);
    //         if(res.length > 0) return res[0];
    //     }
    //     return null;
    // }

    const saveFUMP = async (blob, idJobStaff) => {
        const file = new File([blob], "testBlob.pdf");
        const formData = new FormData();
        formData.append("action", "saveFileReplace");
        formData.append("reference", idJobStaff);
        formData.append("keyFileType", "18");
        formData.append("binary", file);

        const res = await axios.post(
            process.env.REACT_APP_API + "app/facades/files/fileReceiver.php",
            formData
        );
        if (res.status === 200 && res.data.status === "success") return true;
        else return false;
    };

    async function openOrCreateFump(row){
        setLoadingMsg("Consultando reporte");
        const link = row[12];
        if(row[12]){
            setLoading(true);
            const existingFump = await axios.get(link, { responseType: "arraybuffer" })
            .catch(()=>{});
            setLoading(false);
            if(existingFump){
                watchFump(link); 
                return;
            }
        }
        try {
            setFsLoading(true);
            const newFumpBlob = await getFump({ idJobStaff:  row[0] });
            const blobUrl = URL.createObjectURL(newFumpBlob);
            row[12] = blobUrl;
            watchFump(blobUrl);
        } catch (error) {
            toast(<Notification type="error" backMessage="El reporte no existe y no es posible generarlo" withIcon />);
        }
        setFsLoading(false);
    }

    function watchFump(link) {
        setlinkFile(link);
        setModalFile(true);
    };

    const generatedQrImage = () => {
        const canvas = document.getElementById("123456");
        const pngUrl = canvas
            .toDataURL("image/png")
            .replace("image/png", "image/octet-stream");
        return pngUrl;
    };

    async function cancelarFUMP() {
        await cancelFUMP(idStaff);
    }
    //************** END FUMP ***************** */

    /* Detectr cuando se debe mostrar la info de la palza */
    useEffect(() => {
        if (
            validValue(values.keyMove) &&
            [1, 4, 5, 6, 7, 10, 12, 14, 16, 20].includes(+values.keyMove)
        ) {
            setInfoPlaza(true);
        } else setInfoPlaza(false);
    }, [values.keyMove]);

    /* Detectar cuando no se selecciona una plaza */
    useEffect(() => {
        if (!modalTabla) {
            if (!values.numberJobPositions) {
                setInfoPlaza(false);
                if (
                    values.keyMove &&
                    [1, 5, 6, 7, 12, 14, 16, 20].includes(+values.keyMove)
                ) {
                    setFieldValue("keyMove", "");
                }
            }
        }
    }, [modalTabla]);

    /* Detectr cuando se debe mostrar solo el numero de plaza */
    useEffect(() => {
        if (validValue(values.numberJobPositions)) {
            setNumPlaza(true);
        } else setNumPlaza(false);
    }, [values.numberJobPositions]);

    /* Quitar ALTA del combo si existe */
    useEffect(() => {
        if (!isOpen & existeAlta){
            tiposMovimientos.pop();
            setExisteAlta(false);
        }
    }, [isOpen]);

    /* Ocultar el botón de guardar en el modal de fump */
    useEffect(() => {
        if (!modalFile && showButtons) setShowButtons(false);
    }, [modalFile]);
        
    /* ************* TABLE ************* */
    const getForEdit = async (el) => {
        if (!isOpen) {
            setLoadingMsg("Consultando plaza...");
            setLoading(true);
            const params = {
                action: "searchJobStaffByid",
                rows: { idJobStaff: el[0] },
            };
            const data = await getBackInfo(special, params);
            if (data.length != 0) {
                if (+data[0].valid !== 1) {
                    // setDisableDates(true);
                }
                setTitle("Modificando plaza");
                setFieldValue("numberJobPositions", el[1]);
                setFieldValue("idJobStaff", data[0].idJobStaff);
                setFieldValue("idJobPosition", data[0].idJobPosition);
                setFieldValue("keyAssignment", [5, 6].includes(+data[0].keyAssignment) ? data[0].keyAssignment : 6);
                setFieldValue("folio", data[0].folio);
                setFieldValue("idArea", data[0].idArea);
                setFieldValue("startDate", data[0].startDate);
                setFieldValue("endDate", data[0].endDate);
                setFieldValue("idAreaComission", data[0].idAreaComission);
                setFieldValue("startComissionDate", data[0].startComissionDate);
                setFieldValue("endComissionDate", data[0].endComissionDate);
                setFieldValue("keyJobRole", data[0].keyJobRole);
                setFieldValue("startRoleDate", data[0].startRoleDate);
                setFieldValue("endRoleDate", data[0].endRoleDate);
                setFieldValue("idBuilding", data[0].idBuilding);
                setFieldValue("adjustPay", data[0].adjustPay);
                setFieldValue("completePay", data[0].completePay);
                setFieldValue("notes", data[0].notes);
                setFieldValue("lapseFUMP", data[0].lapseFUMP);
                setFieldValue("idJobSchedule", data[0].idJobSchedule);
                setFieldValue("keyMove", data[0].keyMove);
                if ([2, 3].includes(+data[0].keyMove)) {
                    // setDisbledForm(true);
                    setFieldValue("keyStat", data[0].keyStat);
                    setFieldValue("firedDate", data[0].firedDate);
                    // setDisbledReBa(true);
                }
                if (+data[0].keyMove === 5) {
                    setFieldValue("startJobDate", data[0].startJobDate);
                    // setDisbledReBa(true);
                }
                if (+data[0].keyMove === 18) {
                    setFieldValue("minVital", data[0].minVital);
                    setDisabledMinVit(true);
                }
                /* info plaza */
                setFieldValue("nameJob", el[3]);
                setFieldValue("tabulador", el[5]);
                setFieldValue("areaPlaza", el[2]);
                
                setFieldValue("vigente", +data[0].valid);

                setIsOpen(true);
                if (+data[0].keyMove === 1) {
                    tiposMovimientos.push({keyMove: 1, nameMove: "ALTA"});
                    setExisteAlta(true);
                }

            } else {
                toast(
                    <Notification
                        type="warning"
                        backMessage="No se encontró el movimineto"
                        withIcon
                    />,
                    {
                        closeButton: false,
                    }
                );
            }
        } else {
            toast(
                <Notification
                    type="warning"
                    backMessage="Guarde sus cambios antes de continuar"
                />,
                { closeButton: false }
            );
        }
        setLoading(false);
        setLoadingMsg("");
    };
    /* ************* END TABLE ************* */

    const scrollTo = () => {
        refScroll.current.scrollIntoView({ behavior: "smooth" });
    };

    /* ************* CAMBIO DE RANGO ************* */
    async function shouldUpdatePlaza(){
        await getPlazaActiva();
        if(+values.keyMove === 10){
            setModalEditarPlaza(true);
        }
    }

    function updatePlazainfo(res){
        getPlazaActiva();
        setModalEditarPlaza(false);
    }
    /* ************* END CAMBIO DE RANGO  ************* */

    function getDateChanged(sd, days){
        const splt = sd.split("-");
        return new Date( splt[0], +splt[1] - 1, +splt[2] + (days||0));
    }

    return (
        <div className="mt-4">
            {/* <Button color="warning" onClick={()=>cancelarFUMP()}>Marcar pdf</Button> */}
            <TableComponentVer
                titulo={"Plazas"}
                cabecerasTabla={cabeceras}
                filtro={filtro}
                ver={getForEdit}
                parametros={parametros}
                limpia={limpia}
                reset={resetForm}
                special="FUMP"
                specialIco={"fa-file-pdf-o"}
                specialFun={(d) => {openOrCreateFump(d)}}
                permissions={permissions}
            />
            <div className="row mt-2">
                <div className="col-12 col-sm-6">
                    <h5 className="text-center text-sm-left">{title}</h5>
                </div>
                {permissions.INS && (
                    <div className="col-12 col-sm-6">
                        <div className="text-center text-sm-right">
                            <Button color="add" onClick={() => openForm()} disabled={isOpen}>
                                Agregar
                            </Button>
                        </div>
                    </div>
                )}
            </div>
            <Collapse
                isOpen={isOpen}
                className="mt-4 border-top"
                onEntered={() => scrollTo()}
            >
                <div className="position-relative">
                    <form onSubmit={handleSubmit} onReset={handleReset} className="mt-4" ref={refScroll} >
                        <ModalConfirmation
                            modalTitle="Guardar registro"
                            modal={modal}
                            setModal={setModal}
                            editar={editInfo}
                            isEdit={true}
                            values={finalData}
                        >
                            <div className="d-flex justify-content-center">
                                {[2, 3, 5].includes(+values.keyMove)? (
                                    <div className="text-center">
                                        <h6>¿Está seguro de guardar el registro?</h6>
                                        <h6>No podrán realizarse cambios a esta acción</h6>
                                    </div>
                                ) : (
                                    <h6>¿Está seguro de guardar el registro?</h6>
                                )}
                            </div>
                        </ModalConfirmation>

                        {infoPlaza ? (
                            <Row>
                                <Col xs="12" lg="2">
                                    <TextInput
                                        label="Número de plaza"
                                        inputType="text"
                                        inputName="numberJobPositions"
                                        onChangeMethod={onChange}
                                        onBlurMethod={handleBlur}
                                        value={values.numberJobPositions}
                                        touched={touched.numberJobPositions}
                                        errors={errors.numberJobPositions}
                                        isDisabled={true}
                                    />
                                </Col>
                                <Col xs="12" lg="4">
                                    <TextInput
                                        label="Puesto"
                                        inputType="text"
                                        inputName="nameJob"
                                        onChangeMethod={onChange}
                                        onBlurMethod={handleBlur}
                                        value={values.nameJob}
                                        touched={touched.nameJob}
                                        errors={errors.nameJob}
                                        isDisabled={true}
                                    />
                                </Col>
                                <Col xs="12" lg="2">
                                    <TextInput
                                        label="Nivel y Rango"
                                        inputType="text"
                                        inputName="tabulador"
                                        onChangeMethod={onChange}
                                        onBlurMethod={handleBlur}
                                        value={values.tabulador}
                                        touched={touched.tabulador}
                                        errors={errors.tabulador}
                                        isDisabled={true}
                                    />
                                </Col>
                                <Col xs="12" lg="4">
                                    <TextInput
                                        label="Adscripción nominal de la plaza"
                                        inputType="text"
                                        inputName="areaPlaza"
                                        onChangeMethod={onChange}
                                        onBlurMethod={handleBlur}
                                        value={values.areaPlaza}
                                        touched={touched.areaPlaza}
                                        errors={errors.areaPlaza}
                                        isDisabled={true}
                                    />
                                </Col>
                            </Row>
                        ) : (
                            numPlaza && (
                                <Row>
                                    <Col xs="12" lg="3">
                                        <TextInput
                                            label="Número de plaza"
                                            inputType="text"
                                            inputName="numberJobPositions"
                                            onChangeMethod={onChange}
                                            onBlurMethod={handleBlur}
                                            value={values.numberJobPositions}
                                            touched={touched.numberJobPositions}
                                            errors={errors.numberJobPositions}
                                            isDisabled={true}
                                        />
                                    </Col>
                                </Row>
                            )
                        )}
                        <Row className="mt-3">
                            <Col xs="12" lg="5">
                                <SelectTypeHeadSingle
                                    label="Tipo de movimiento"
                                    isRequired={true}
                                    inputName="keyMove"
                                    optionsArray={tiposMovimientos}
                                    defaultOption="Seleccione un tipo de movimiento"
                                    onChangeMethod={onChange}
                                    onBlur={handleBlur}
                                    value={values.keyMove}
                                    touched={touched.keyMove}
                                    errors={errors.keyMove}
                                    optionValue="keyMove"
                                    optionName="nameMove"
                                    isDisabled={values.idJobStaff}
                                />
                            </Col>
                            <Col xs="12" lg="4">
                                <SelectTypeHeadSingle
                                    label="Tipo de asignación"
                                    isRequired={true}
                                    inputName="keyAssignment"
                                    optionsArray={tiposAsignaciones}
                                    defaultOption="Seleccione un tipo de asignación"
                                    onChangeMethod={onChange}
                                    onBlur={handleBlur}
                                    // isDisabled={disbledForm && ![2, 3].includes(+values.keyMove)}
                                    value={values.keyAssignment}
                                    touched={touched.keyAssignment}
                                    errors={errors.keyAssignment}
                                    optionValue="keyAssignment"
                                    optionName="nameAssignment"
                                />
                            </Col>
                            <Col xs="12" lg="3">
                                <TextInput
                                    label="Folio de movimiento"
                                    inputType="text"
                                    inputName="folio"
                                    onChangeMethod={onChange}
                                    onBlurMethod={handleBlur}
                                    value={values.folio}
                                    touched={touched.folio}
                                    errors={errors.folio}
                                    isDisabled
                                />
                            </Col>
                        </Row>
                        <Row className="mt-2">
                            <Col xs="12" lg="6">
                                <SelectTypeHeadSingle
                                    label="Adscripción nominal"
                                    isRequired={true}
                                    inputName="idArea"
                                    optionsArray={areas}
                                    defaultOption="Seleccione la adscripción nominal"
                                    onChangeMethod={onChange}
                                    onBlur={handleBlur}
                                    isDisabled={disbledForm || disableMinVit}
                                    value={values.idArea}
                                    touched={touched.idArea}
                                    errors={errors.idArea}
                                    optionValue="idArea"
                                    optionName="name"
                                />
                            </Col>
                            <Col xs="12" lg="3">
                                <TextInput
                                    label="Fecha de inicio"
                                    inputType="date"
                                    inputName="startDate"
                                    onChangeMethod={onChange}
                                    onBlurMethod={handleBlur}
                                    value={values.startDate}
                                    isRequired={true}
                                    touched={touched.startDate}
                                    errors={errors.startDate}
                                    // isDisabled={disbledForm}//disableDates || 
                                />
                            </Col>
                            <Col xs="12" lg="3">
                                <TextInput
                                    label="Fecha de termino"
                                    inputType="date"
                                    inputName="endDate"
                                    onChangeMethod={onChange}
                                    onBlurMethod={handleBlur}
                                    value={values.endDate}
                                    isRequired={true}
                                    touched={touched.endDate}
                                    errors={errors.endDate}
                                    isDisabled={
                                        !values.startDate //||
                                        // disableDates ||
                                        // [2, 5].includes(+values.keyAssignment) ||
                                        // disbledForm
                                    }
                                    isMin={values.startDate}
                                />
                            </Col>
                        </Row>
                        <Row className="mt-2">
                            <Col xs="12" lg="6">
                                <SelectTypeHeadSingle
                                    label="Adscripción física"
                                    inputName="idAreaComission"
                                    optionsArray={areas}
                                    defaultOption="Seleccione una adscripción"
                                    onChangeMethod={onChange}
                                    onBlur={handleBlur}
                                    isDisabled={disbledForm}
                                    value={values.idAreaComission}
                                    touched={touched.idAreaComission}
                                    errors={errors.idAreaComission}
                                    optionValue="idArea"
                                    optionName="name"
                                />
                            </Col>
                            <Col xs="12" lg="2">
                                <TextInput
                                    label="Fecha de inicio"
                                    inputType="date"
                                    inputName="startComissionDate"
                                    onChangeMethod={onChange}
                                    onBlurMethod={handleBlur}
                                    value={values.startComissionDate}
                                    touched={touched.startComissionDate}
                                    errors={errors.startComissionDate}
                                    isDisabled={
                                        !values.idAreaComission || disbledForm //  disableDates ||
                                    }
                                    isMin={values.startDate}
                                    isMax={values.endDate}
                                    isRequired={!!values.idAreaComission}
                                />
                            </Col><Col xs="12" lg="2">
                                <RadioInput
                                    label="¿Pagar retroctivo?"
                                    inputName="adjustPay"
                                    onChangeMethod={onChange}
                                    onBlurMethod={handleBlur}
                                    value={values.adjustPay}
                                    optionsArray={[
                                        { val: "1", name: "Si" },
                                        { val: "0", name: "No" },
                                    ]}
                                    optionValue="val"
                                    optionName="name"
                                    isDisabled={disbledForm}
                                    isCenter
                                />
                            </Col>
                            <Col xs="12" lg="2">
                                <RadioInput
                                    label="¿Hacer pago completo?"
                                    inputName="completePay"
                                    onChangeMethod={onChange}
                                    onBlurMethod={handleBlur}
                                    value={values.completePay}
                                    optionsArray={[
                                        { val: "1", name: "Si" },
                                        { val: "0", name: "No" },
                                    ]}
                                    optionValue="val"
                                    optionName="name"
                                    isDisabled={disbledForm}
                                    isCenter
                                />
                            </Col>
                            {/* <Col xs="12" lg="3">
                                <TextInput
                                    label="Fecha de termino"
                                    inputType="date"
                                    inputName="endComissionDate"
                                    onChangeMethod={onChange}
                                    onBlurMethod={handleBlur}
                                    value={values.endComissionDate}
                                    touched={touched.endComissionDate}
                                    errors={errors.endComissionDate}
                                    isDisabled={
                                        !values.startComissionDate || disableDates || disbledForm
                                    }
                                    isMin={
                                        values.startComissionDate !== ""
                                            ? values.startComissionDate
                                            : values.startDate
                                    }
                                    isMax={values.endDate}
                                />
                            </Col> */}
                        </Row>
                        <Row className="mt-2">
                            {/* <Col xs="12" lg="3">
                                <TextInput
                                    label="Fecha de inicio"
                                    inputType="date"
                                    inputName="startRoleDate"
                                    onChangeMethod={onChange}
                                    onBlurMethod={handleBlur}
                                    value={values.startRoleDate}
                                    touched={touched.startRoleDate}
                                    errors={errors.startRoleDate}
                                    isDisabled={!values.keyJobRole || disableDates || disbledForm}
                                    isMin={values.startDate}
                                    isMax={values.endDate}
                                />
                            </Col>
                            <Col xs="12" lg="3">
                                <TextInput
                                    label="Fecha de termino"
                                    inputType="date"
                                    inputName="endRoleDate"
                                    onChangeMethod={onChange}
                                    onBlurMethod={handleBlur}
                                    value={values.endRoleDate}
                                    touched={touched.endRoleDate}
                                    errors={errors.endRoleDate}
                                    isDisabled={
                                        !values.startRoleDate || disableDates || disbledForm
                                    }
                                    isMin={
                                        values.startRoleDate
                                            ? values.startRoleDate
                                            : values.startDate
                                    }
                                    isMax={values.endDate}
                                />
                            </Col> */}
                        </Row>
                        <Row className="mt-2">
                            <Col xs="12" lg="6">
                                <SelectTypeHeadSingle
                                    label="Categoría"
                                    inputName="keyJobRole"
                                    optionsArray={jobsRoles}
                                    defaultOption="Seleccione una categoría"
                                    onChangeMethod={onChange}
                                    onBlur={handleBlur}
                                    isDisabled={disbledForm}
                                    value={values.keyJobRole}
                                    touched={touched.keyJobRole}
                                    errors={errors.keyJobRole}
                                    optionValue="keyJob"
                                    optionName="nameJob"
                                />
                            </Col>
                            <Col xs="12" lg="6">
                                <SelectTypeHeadSingle
                                    label="Edificio de trabajo"
                                    inputName="idBuilding"
                                    optionsArray={edificios}
                                    defaultOption="Seleccione un edificio de trabajo"
                                    onChangeMethod={onChange}
                                    onBlur={handleBlur}
                                    isDisabled={disbledForm}
                                    value={values.idBuilding}
                                    touched={touched.idBuilding}
                                    errors={errors.idBuilding}
                                    optionValue="idBuilding"
                                    optionName="nameBuilding"
                                />
                            </Col>
                        </Row>
                        <Row className="mt-2">
                            <Col xs="12" lg="5">
                                <div className="form-group">
                                    <label htmlFor="notes">Notas</label>
                                    <textarea
                                        name="notes"
                                        className="form-control"
                                        onChange={onChange}
                                        onBlur={handleBlur}
                                        value={values.notes}
                                        disabled={disbledForm}
                                        rows={6}
                                    />
                                </div>
                            </Col>
                            <Col xs="12" lg="7">
                                <Row>
                                    <Col xs="12" lg="7">
                                        <SelectTypeHeadSingle
                                            label="Horario de trabajo"
                                            isRequired={true}
                                            inputName="idJobSchedule"
                                            optionsArray={horarios}
                                            defaultOption="Seleccione un horario"
                                            onChangeMethod={onChange}
                                            onBlur={handleBlur}
                                            isDisabled={disbledForm}
                                            value={values.idJobSchedule}
                                            touched={touched.idJobSchedule}
                                            errors={errors.idJobSchedule}
                                            optionValue="idJobSchedule"
                                            optionName="nameJobSchedule"
                                        />
                                    </Col>
                                </Row>
                                <Row className="mt-2">
                                    {[2, 3].includes(+values.keyMove) ? (
                                        <>
                                            <Col xs="12" lg="6">
                                                <SelectTypeHeadSingle
                                                    label="Motivo de la baja"
                                                    isRequired={true}
                                                    inputName="keyStat"
                                                    optionsArray={statusStaff.filter(s => +s.keyStat !== 1)}
                                                    defaultOption="Seleccione un status"
                                                    onChangeMethod={onChange}
                                                    onBlur={handleBlur}
                                                    isDisabled={disbledReBa}
                                                    value={values.keyStat}
                                                    touched={touched.keyStat}
                                                    errors={errors.keyStat}
                                                    optionValue="keyStat"
                                                    optionName="nameStat"
                                                    refSelect={selectRef}
                                                />
                                            </Col>
                                            <Col xs="12" lg="6">
                                                <TextInput
                                                    label="Fecha de baja"
                                                    inputType="date"
                                                    inputName="firedDate"
                                                    onChangeMethod={onChange}
                                                    onBlurMethod={handleBlur}
                                                    value={values.firedDate}
                                                    touched={touched.firedDate}
                                                    errors={errors.firedDate}
                                                    isRequired
                                                    isDisabled={disbledReBa}
                                                    isMin={
                                                        values.startDate &&
                                                        values.startDate && fDateInput(getDateChanged(values.startDate, -(+(+values.keyMove !== 2 ))), 1)
                                                    }
                                                />
                                            </Col>
                                        </>
                                    ) : +values.keyMove === 5 ? (
                                        <Col xs="12" lg="3">
                                            <TextInput
                                                label="Fecha de reingreso"
                                                inputType="date"
                                                inputName="startJobDate"
                                                onChangeMethod={onChange}
                                                onBlurMethod={handleBlur}
                                                value={values.startJobDate}
                                                touched={touched.startJobDate}
                                                errors={errors.startJobDate}
                                                isRequired
                                                isDisabled={disbledReBa}
                                            />
                                        </Col>
                                    ) : +values.keyMove === 18 ? (
                                        <NumberInput
                                            label="Porcentaje del mínimo vital"
                                            inputName="minVital"
                                            onChangeMethod={onChange}
                                            onBlurMethod={handleBlur}
                                            value={values.minVital}
                                            touched={touched.minVital}
                                            errors={errors.minVital}
                                            isMin={30}
                                            isMax={100}
                                            decimals
                                            suffix="%"
                                            isRequired
                                        />
                                    ) : +values.keyMove === 11 ? (
                                        <>
                                            <Col xs="12" lg="6">
                                                <SelectTypeHeadSingle
                                                    label="Estatus empleado"
                                                    isRequired={true}
                                                    inputName="keyStat"
                                                    optionsArray={statusStaff.filter(s => [1, 3].includes(+s.keyStat))}
                                                    defaultOption="Seleccione un status"
                                                    onChangeMethod={onChange}
                                                    onBlur={handleBlur}
                                                    isDisabled={disbledReBa}
                                                    value={values.keyStat}
                                                    touched={touched.keyStat}
                                                    errors={errors.keyStat}
                                                    optionValue="keyStat"
                                                    optionName="nameStat"
                                                    refSelect={selectRef}
                                                />
                                            </Col>
                                        </>
                                    ) : null}
                                </Row>
                            </Col>
                        </Row>

                        <Row className="mt-5">
                            <div className="col-sm-6 order-2 order-sm-1 text-center text-sm-left mb-4">
                                <Button
                                    color="danger"
                                    type="reset"
                                    onClick={() => limpia(resetForm)}
                                >
                                    Cancelar
                                </Button>
                            </div>
                            <div className="col-sm-6 order-1 order-sm-2 text-center text-sm-right mb-4">
                                {(title === "Modificando plaza" ? 
                                    (![2].includes(+values.keyMove) && permissions.UPD) : permissions.INS) && (
                                        <Button color="success" type="submit">
                                            Guardar
                                        </Button>
                                    )}
                            </div>
                        </Row>
                    </form>
                </div>
            </Collapse>
            <ModalTableVer
                modal={modalTabla}
                setModal={setModalTabla}
                title="Plazas disponibles"
                cabeceras={cabecerasModal}
                filtro={filtroModal}
                parametros={parametrosModal}
                selectRegistro={selectPlaza}
                backdrop={true}
                keyboard={true}
                btnClose={true}
            />
            <FullFormLoader show={loading} message={loadingMsg} />
            <FullScreenLoader show={fsLoading} setShow={setFsLoading} message={fsLoaderMessage} />
            <ModalViewFile
                modal={modalFile}
                setModal={setModalFile}
                backdrop={true}
                keyboard={true}
                btnClose={true}
                fileLink={linkFile}
                type="pdf"
            >
                {showButtons && <Row className="mt-5 d-flex justify-content-center">
                    <div className="col-sm-6 order-2 order-sm-1 text-center text-sm-left mb-4">
                        <Button color="danger" onClick={() => setModalFile(false)}>
                            Cancelar
                        </Button>
                    </div>
                    <div className="col-sm-6 order-1 order-sm-2 text-center text-sm-right mb-4">
                        {(title === "Modificando plaza" ? +values.keyMove !== 2 && permissions.UPD : permissions.INS) && (
                            <Button
                                color="success"
                                onClick={() => {
                                    setModal(true);
                                }}
                            >
                                Guardar
                            </Button>
                        )}
                    </div>
                </Row>}
            </ModalViewFile>

            <div style={{ display: "none" }}>
                <QRCode
                    id="123456"
                    value="https://fgjem.edomex.gob.mx/"
                    size={290}
                    level={"H"}
                    includeMargin={true}
                />
            </div>

            <ModalConfirmation
                modalTitle="Cambio de plaza"
                modal={modalRenovar}
                crear={() => shouldUpdatePlaza() }
                setModal={setModalRenovar}
                centered
                cancelOverride
                cancel={() => { setModalTabla(true); setModalRenovar(false); }}
                buttonCancelMsg="Cambiar"
                buttonOkMsg="Conservar"
                cancelColor="info"
            >
                <div className="d-flex justify-content-center">
                    <h6>¿Conservar la misma plaza?</h6>
                </div>
            </ModalConfirmation>

            <ModalEditarPlaza 
                modal={modalEditarPlaza} 
                setModal={setModalEditarPlaza} 
                infoPlaza={{number: values.numberJobPositions, id: values.idJobPosition}}
                atFinish={updatePlazainfo}
            />
        </div>
    );
};
export default ExpedienteNominaPlazas;

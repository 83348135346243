import React from "react";
import Widget from "../../../../components/Widget/Widget";
import FormArea from "../FormArea";
import { createArea } from "../functions";

export const CrearArea = (props) => {
  return (
    <Widget className="widget-p-md">
        <h3>Crear Unidad de Trabajo</h3>
        <FormArea crear={createArea}/>
    </Widget>
  )
}

export default CrearArea;
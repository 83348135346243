import { useFormik } from "formik";
import * as Yup from "yup";
import React, { useEffect, useState } from "react";
import ModalConfirmation from "../../../components/Modal/ModalConfirmation";
import axios from "axios";
import Notification from "../../../components/Notification/Notification";
import { toast } from "react-toastify";
import {
  Button,
  Col,
  Row,
  Spinner,
} from "reactstrap";
import {
  FilesInput,
  SelectTypeHeadSingle,
  TextAreaInput,
} from "../../../components/GenericInputsFormik";
import { YearInput } from "../../../components/GenericInputsFormik/YearInput";
import { peticionEncript } from "../../../helpers/peticionesEncripted";
import { useHistory } from "react-router-dom";
import { PERIOD } from "../calculo/regex";
import FullFormLoader from "../../../components/Loader/FullFormLoader";
// import st from "../../finanzas/pagos-transferencias-empleados/PagosTrasferenciasEmpleados.module.scss";
// import { useLocation } from "react-router-dom";

/* Formualrio */
const defaultForm = {
  idPayrollRequest: "",
  keyStat: "",
  period: "",
  year: new Date(),
  file: "",
  notes: "",
};

const FormSchema = Yup.object().shape({
  keyStat: Yup.string().required("Seleccione un estatus"),
  period: Yup.number()
    .required("Seleccione un periodo")
    .min(1, "Periodo invalido")
    .max(24, "Periodo debe ser menor a 25"),
  year: Yup.string().required("Seleccione un año").nullable(),
  file: Yup.mixed()
    .required("Agregue un documento")
    .test("file-type", "El documento debe ser de tipo PDF", (value) => {
      return value && value.type === "application/pdf";
    })
    .test({
      message: `El documento debe pesar menos de 4MB`,
      test: (value) => value?.size < 4000000,
    }),
});

const date = new Date().getFullYear();

async function doRequest(route, params, seeOk, encrypt) {
  let info = [];
  let data = params;
  if (encrypt !== false) data = peticionEncript(params);
  await axios
    .post(process.env.REACT_APP_API + route, data)
    .then((res) => {
      if (res.status === 200 && res.data.status === "success") {
        info = res.data.data;
        if (seeOk) {
          toast(
            <Notification
              type={"elimina_exito"}
              backMessage={res.data.message}
              withIcon
            />,
            { closeButton: false }
          );
        }
      } else {
        toast(
          <Notification
            type={"consultar_error"}
            backMessage={res.data.message}
            withIcon
          />,
          { closeButton: false }
        );
      }
    })
    .catch((err) => {
      toast(<Notification type={"consultar_servidor_error"} withIcon />, {
        closeButton: false,
      });
    });
  return info;
}

// const finanzas = "pagos-transferencias-empleados";
// const pensiones = "pagos-transferencias-pensiones";

export const FormSolicitudPago = ({ module, data }) => {
  const [modalConfirm, setModalConfirm] = useState(false);
  const [loading, setLoading] = useState(true); // Cargar y ocultar contenido
  const [loadingForm, setLoadingForm] = useState(false); // Cargar solo el formulario
  const [estatus, setEstatus] = useState([]);
  // const [rechazos, setRechazos] = useState([]);
  const [disableFields, setDisableFields] = useState(false);

  const history = useHistory();
  // const location = useLocation();
  // const route = location.pathname.split("/")[2];

  const {
    handleSubmit,
    handleReset,
    handleBlur,
    values,
    errors,
    touched,
    setFieldValue,
  } = useFormik({
    initialValues: {
      ...defaultForm,
      keyStat: module === "nomina" ? "75" : "",
    },
    validationSchema: FormSchema,
    onSubmit: () => setModalConfirm(true),
    onReset: () => {},
    enableReinitialize: true,
  });

  useEffect(() => {
    if (data) {
      getInfoSolicitud();
      // getRechazos();
      setDisableFields(true);
    }
    getEstatus();
  }, []);

  const getEstatus = async () => {
    const params = {
      action: "select",
      table: "status",
      condition: { keyTypeStat: 18 },
    };
    const data = await doRequest("receiver/receiver.php", params);
    if (data.length > 0) setEstatus(data);
    setLoading(false);
  };

  const getInfoSolicitud = async () => {
    const params = {
      action: "multiselect",
      table:
        "payrollrequest A INNER JOIN payrollrequestnotes B ON A.idPayrollRequest = B.idPayrollRequest",
      rows: "B.keyStat, A.period, A.year, B.notes, B.created",
      conditions: `A.idPayrollRequest = ${data[0]} AND A.enabled = 1 AND B.enabled = 1 `,
      page: 0,
      records: 1,
      order: "5 DESC",
    };
    let dt = await doRequest("receiver/receiver.php", params, false, true);
    if (dt.length > 0) {
      dt = dt[0];
      setFieldValue("idPayrollRequest", data[0]);
      setFieldValue("keyStat", dt.keyStat);
      setFieldValue("period", dt.period);
      setFieldValue("year", new Date(dt.year));
      setFieldValue("notes", dt.notes);
    }
  };

  const onChange = (e) => {
    if (e.target.name === "file") {
      if (e.target.files.length === 0) setFieldValue(e.target.name, "");
      else setFieldValue(e.target.name, e.target.files[0]);
    } else {
      setFieldValue(e.target.name, e.target.value);
    }
  };

  const saveInfo = async () => {
    setLoadingForm(true);
    const rows = {};
    let action = "";
    if (data) {
      action = "updatePayrollrequest";
      rows.idPayrollRequest = values.idPayrollRequest;
    } else {
      action = "insertPayrollrequest";
      rows.period = values.period;
      rows.year = new Date(values.year).getFullYear();
    }
    rows.notes = values.notes;

    const formData = new FormData();
    formData.append("action", action);
    formData.append("rows", JSON.stringify(rows));
    formData.append("binary", values.file);
    const res = await doRequest(
      "app/facades/payslips/payrollrequestF.php",
      formData,
      true,
      false
    );
    setLoadingForm(false);
    if (res.length > 0) goBack();
  };

  const goBack = () => {
    history.goBack();
  };

  if (loading)
    return (
      <div style={{ minHeight: "300px" }} className="text-center">
        <Spinner color="primary" style={{ marginTop: "135px" }} />
      </div>
    );

  return (
    <div className="mt-4">
      <form onSubmit={handleSubmit} onReset={handleReset} className="mt-2">
        <ModalConfirmation
          modalTitle="Guardar Solicitud de Pago"
          modal={modalConfirm}
          setModal={setModalConfirm}
          editar={() => saveInfo()}
          isEdit={true}
          values={values}
        >
          <div className="d-flex justify-content-center">
            <h6>¿Está seguro de guardar la solicitud de pago?</h6>
          </div>
        </ModalConfirmation>

        <Row>
          <Col xs={12} lg={6}>
            <SelectTypeHeadSingle
              label="Estatus de solicitud"
              isRequired
              inputName="keyStat"
              optionsArray={estatus}
              defaultOption="Seleccione un estatus"
              onChangeMethod={onChange}
              onBlur={handleBlur}
              value={values.keyStat}
              touched={touched.keyStat}
              errors={errors.keyStat}
              optionValue="keyStat"
              optionName="nameStat"
              isDisabled
            />
          </Col>
          <Col xs={12} lg={3}>
            {/* <NumberInput
              label="Período"
              inputName="period"
              onChangeMethod={onChange}
              onBlurMethod={handleBlur}
              value={values.period}
              isRequired
              touched={touched.period}
              errors={errors.period}
              isMin={1}
              isMax={24}
              maxLength={2}
            /> */}
            <SelectTypeHeadSingle
              label="Periodo"
              isRequired={true}
              inputName="period"
              optionsArray={PERIOD}
              defaultOption="Seleccione un periodo"
              onChangeMethod={onChange}
              onBlurMethod={handleBlur}
              value={values.period}
              touched={touched.period}
              errors={errors.period}
              optionValue="value"
              optionName="label"
              isDisabled={disableFields}
            />
          </Col>
          <Col xs={12} lg={3}>
            <YearInput
              label="Año"
              inputName="year"
              onChangeMethod={onChange}
              onBlurMethod={handleBlur}
              value={values.year}
              isMin={date}
              touched={touched.year}
              errors={errors.year}
              isRequired
              isDisabled={disableFields}
            />
          </Col>
          <Col xs={12} lg={6}>
            <FilesInput
              label="Documentación anexa"
              inputName="file"
              onChangeMethod={onChange}
              onBlurMethod={handleBlur}
              fileAccept="application/pdf"
              isRequired
              touched={touched.file}
              errors={errors.file}
              basicReq={false}
            />
          </Col>
          <Col xs={12} lg={6}>
            <TextAreaInput
              label="Observaciones"
              inputName="notes"
              inputRows={6}
              onChangeMethod={onChange}
              value={values.notes}
            />
          </Col>
        </Row>

        <Row className="mt-5">
          <div className="col-sm-6 order-2 order-sm-1 text-center text-sm-left mb-4">
            <Button color="danger" type="reset" onClick={() => goBack()}>
              Cancelar
            </Button>
          </div>
          <div className="col-sm-6 order-1 order-sm-2 text-center text-sm-right mb-4">
            <Button color="success" type="submit">
              Guardar
            </Button>
          </div>
        </Row>

        <FullFormLoader show={loadingForm} />
      </form>

      {/* {rechazos.length > 0 && (
        <div className="mt-5 pt-2">
          <div className="text-center">
            <h4>Pagos Rechazados</h4>
          </div>

          <Table responsive className="table-striped table-borderless mt-2">
            <thead className="border-bottom border-secondary text-center">
              <tr>
                <th>Origen</th>
                <th>Banco</th>
                <th>Num. Empleado</th>
                <th>Nombre</th>
                <th>Motivo</th>
                <th>Importe</th>
                <th>Estatus</th>
                <th>Detalle</th>
                <th>Acción</th>
              </tr>
            </thead>
            <tbody>
              {rechazos.map((el, id) => (
                <RowRechazo
                  el={el}
                  getRechazos={getRechazos}
                  key={"R" + el.COL8}
                  soluciones={soluciones}
                  bancos={bancos}
                  tiposCuentas={tiposCuentas}
                />
              ))}
            </tbody>
          </Table>
        </div>
      )} */}
    </div>
  );
};
export default FormSolicitudPago;

// const fmt = new Intl.NumberFormat("es-MX", {
//   style: "currency",
//   currency: "MXN",
// });

// const RowRechazo = ({ el, getRechazos, soluciones, bancos, tiposCuentas }) => {
//   const [modalRechazo, setModalRechazo] = useState(false);
//   const [tooltipOpen, setTooltipOpen] = useState(false);
//   const toggle = () => setTooltipOpen(!tooltipOpen);

//   /* Resolver rechazo de la solicitud de pago */
//   const resolveRechazo = async () => {
//     const params = {
//       action: "deleteRechazo",
//       rows: {
//         idPayrollRequestReturn: el.COL8,
//         idPayrollBank: el.COL9,
//         amount: el.COL6,
//         name: el.COL4,
//       },
//     };
//     const data = await doRequest(
//       "app/facades/payslips/payrollrequestF.php",
//       params,
//       true,
//       true
//     );
//   };

//   return (
//     <>
//       <tr className={st.rowHover}>
//         <td className="text-center">{el.COL1}</td>
//         <td className="text-center">{el.COL2}</td>
//         <td className="text-center">{el.COL3}</td>
//         <td className="">{el.COL4}</td>
//         <td className="">{el.COL5}</td>
//         <td className="text-right">{fmt.format(el.COL6)}</td>
//         <td className="text-center" id={"tt-" + el.COL8}>
//           <div>
//             {+el.COL12 !== 85 ? (
//               <i
//                 className="fa fa-check-circle-o"
//                 style={{ fontSize: "40px", color: "#43BC13" }}
//               />
//             ) : (
//               <i
//                 className="fa fa-times-circle-o"
//                 style={{ fontSize: "40px", color: "#FF5668" }}
//               />
//             )}
//           </div>
//           <Tooltip
//             placement="bottom"
//             isOpen={tooltipOpen}
//             target={"tt-" + el.COL8}
//             toggle={toggle}
//           >
//             {+el.COL12 !== 85 ? "Resuelto" : "Sin resolver"}
//           </Tooltip>
//         </td>
//         <td className="">{el.COL10}</td>
//         <td className="text-center">
//           {el.COL10 === "" && (
//             <Button
//               outline
//               color="primary"
//               className="p-0"
//               onClick={() => setModalRechazo(true)}
//             >
//               <i className="fa fa-plus py-2 px-3" />
//             </Button>
//           )}
//         </td>
//       </tr>

//       <ResolverRechazo
//         modal={modalRechazo}
//         setModal={setModalRechazo}
//         idPayrollRequestReturn={el.COL8}
//         idPayrollRequest={el.COL11}
//         getRechazos={getRechazos}
//         empleado={`${el.COL3} - ${el.COL4}`}
//         soluciones={soluciones}
//         bancos={bancos}
//         tiposCuentas={tiposCuentas}
//       />
//     </>
//   );
// };

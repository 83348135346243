import * as XlsxPopulate from "xlsx-populate";
import { saveAs } from "file-saver";

const formatter = new Intl.NumberFormat("es-MX", {
  style: "currency",
  currency: "MXN",
});

export async function saveReport17AsExcel(report, headers, title, period, filter) {
  XlsxPopulate.fromBlankAsync().then(async (workbook) => {
    //Estilos
    // const headerStyle = workbook.styleSheet().createStyle();
    // headerStyle.style('horizontalAlignment', 'center');
    // headerStyle.style('verticalAlignment', 'center');
    // headerStyle.style('border', true);
    // headerStyle.style('bold', true);

    const hs = {horizontalAlignment: "center", verticalAlignment: "center", border: true, bold: true}
    
    // const titleStyle = workbook.styleSheet().createStyle();
    // titleStyle.style('horizontalAlignment', 'center');
    // titleStyle.style('verticalAlignment', 'center');
    // titleStyle.style('bold', true);

    const ts = {horizontalAlignment: "center", verticalAlignment: "center", bold: true}
    
    // const hal = workbook.styleSheet().createStyle();
    // hal.style('horizontalAlignment', 'center');
    // hal.style('verticalAlignment', 'center');

    const hal = {horizontalAlignment: "center", verticalAlignment: "center"}

    // const eal = workbook.styleSheet().createStyle();
    // eal.style('horizontalAlignment', 'right');
    // eal.style('verticalAlignment', 'center');

    const eal = {horizontalAlignment: "right", verticalAlignment: "center"}

    const sheet1 = workbook.sheet(0);
    const dataArr = getSheetData(report, headers, filter);
    const sheetData = dataArr[0];
    const mid = Math.ceil(headers.length / 2);
    sheet1
      .row(1)
      .cell(mid)
      .value("SAPP")
      .style(ts);
    sheet1
      .row(2)
      .cell(mid)
      .value(title)
      .style(ts);
    sheet1
      .row(3)
      .cell(mid)
      .value("Periodo "+period)
      .style(ts);

    for (let i = 1; i <= headers.length; i++) {
      if(i === 1 || i === 2) sheet1.column(i).style(hal);
      else if(i === 4 || i === 5) sheet1.column(i).style(eal);
      sheet1.row(6).cell(i).style(hs);
      sheet1.row(8+dataArr[0].length).cell(i).style(hs);
      if(i === 3) sheet1.column(i).width(45);
      else sheet1.column(i).width(20);
    }
    sheet1.range(`A5:E5`).merged(true).value("PERCEPCIONES").style(hs);
    sheet1.row(6).cell(1).value(dataArr[0]).style({"border": true});
    sheet1.range(`A${5+dataArr[0].length}:E${5+dataArr[0].length}`).style({"border": true, bold: true});

    
    sheet1.range(`A${7+dataArr[0].length}:E${7+dataArr[0].length}`).merged(true).value("DEDUCCIONES").style(hs);
    sheet1.row(8+dataArr[0].length).cell(1).value(dataArr[1]).style({"border": true});
    sheet1.range(`A${7+dataArr[0].length+dataArr[1].length}:E${7+dataArr[0].length+dataArr[1].length}`).style({"border": true, bold: true});


    return workbook.outputAsync().then((res) => {
      saveAs(res, `${title}.xlsx`);
    });
  });
}
function getSheetData(data, header, filter) {
  let keys = [];
  let totalPer = 0;
  let totalDed = 0;
  header.forEach((h) => {
    filter.content.every((arr) => {
      let flg = true;
      arr.every((ob) => {
        if (ob.header === h) {
          keys.push([ob.value, ob.format]);
          flg = false;
          return false;
        }
        return true;
      });
      return flg;
    });
  });
  let dataPer = [];
  let dataDed = [];
  let reg = [];
  data.forEach((d) => {
     reg = keys.map((k) => {
      if (k[0] === "fullKey" && d.keyTypeUnder === "2") {
        return d.accountKey;
      }
      let tx = d[k[0]];
      switch (k[1]){
        case "moneda":
          tx = formatter.format(tx);
        break;
      }
      return tx;
    });

    if (d.keyTypeUnder === "1") {
      dataPer.push(reg);
      totalPer += +d.ammount;
    } else if (d.keyTypeUnder === "2") {
      dataDed.push(reg);
      totalDed += +d.ammount;
    }
  });

  dataPer.unshift(header);
  dataPer.sort((a,b)=> {return +a[0] - +b[0]});
  dataPer.push(["", "", "", "TOTAL:", formatter.format(totalPer)]);

  dataDed.unshift(header);
  dataDed.sort((a,b)=> {return +a[0] - +b[0]});
  dataDed.push(["", "", "", "TOTAL:", formatter.format(totalDed)]);
  return [dataPer, dataDed];
}
//Recepcion de mercancia
import RecepcionMercancia from "../../../pages/almacenes/recepcion/RecepcionMercancia";
/* -- Requisiciones de papeleria -- */
import RequisicionesPapeleria from "../../../pages/almacenes/requisiciones-papeleria/Inicio";
// -- Existencias Almacén
import ExistenciasAlmacen from "../../../pages/almacenes/existencias-almacen/Index";
import DetalleExistenciasAlmacen from "../../../pages/almacenes/existencias-almacen/Detalle";
// -- Recepcion de Traspasos
import RecepcionTraspasos from "../../../pages/almacenes/recepcion-traspasos/Inicio";
// -- Devolución de Mercnacia
import DevolucionesMercancia from "../../../pages/almacenes/devoluciones-mercancia/Inicio";

// -- Existencias Almacén
import SalidasAlmacen from "../../../pages/almacenes/salidas-almacen/SalidasAlmacen";

// -- Recepcion de Traspasos
import SolicitudArticulos from "../../../pages/almacenes/solicitud-articulos/SolicitudArticulos";

//Traspasos de articulos
import TraspasosAlmacen from "../../../pages/almacenes/traspasos-articulos/TraspasosArticulos";


const rutasAlmacenes = [
    { path: "/template/recepcion", component: RecepcionMercancia },
    //Existencias Almacén
    { path: "/template/existencias-almacen", component: ExistenciasAlmacen },
    //Existencias Almacén - Detalle
    { path: "/template/existencias-almacen/detalle", component: DetalleExistenciasAlmacen },
    //Traspasos de articucos 
    { path: "/template/traspaso-articulos", component: TraspasosAlmacen },
    //Salidas de almacen 
    { path: "/template/salidas-almacen", component: SalidasAlmacen },
    //Recepcion de Traspasos
    { path: "/template/recepcion-traspasos", component: RecepcionTraspasos },
    //Devolución de Mercnacia
    { path: "/template/devoluciones", component: DevolucionesMercancia },
    //Solicitud Articulos
    { path: "/template/solicitud-articulos", component: SolicitudArticulos },
    //Requisiciones de papeleria
    { path: "/template/requisiciones", component: RequisicionesPapeleria },
    
]

export default rutasAlmacenes;



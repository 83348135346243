import React, { useState, useEffect } from "react";
import { Col, Row } from "reactstrap";
import {
  RadioInput,
  TextInput,
} from "../../../../components/GenericInputsFormik/index";
import { peticionesReceiver } from "../../../../helpers/peticionesReceiver";
import { toast } from "react-toastify";
import Notification from "../../../../components/Notification/Notification";
import { SelectWithSearchInput } from "../../../../components/GenericInputsFormik/index";
import { YearInput } from "../../../../components/GenericInputsFormik/YearInput";
import { ButtonInput } from "../../../../components/GenericInputsFormik/ButtonInput";
import { SelectTypeHeadSingle } from "../../../../components/GenericInputsFormik/SelectTypeHeadSingle";
import { PERIOD } from "../../../../helpers/regex";
import Multiselect from "../../../../components/GenericInputsFormik/MultiSelect";

const headersArr = {
  A: ["Id", "Unidad de trabajo", "Clave", "Seleccionar"],
  B: ["Id", "Puesto", "Tipo", "Seleccionar"],
  C: ["Id", "Tipo", "Concepto", "Seleccionar"],
};
const paramsArr = {
  A: {
    action: "datatable",
    table: "areas A",
    rows: "A.idArea, A.name, A.areaKey",
    conditions: "A.enabled = 1",
    page: 0,
    records: 5,
    search: "",
    order: "",
  },
  B: {
    action: "datatable",
    table: "jobs A JOIN jobtypes B USING (keyJobType)",
    rows: "A.keyJob, A.nameJob, B.nameJobType",
    conditions: "A.enabled = 1",
    page: 0,
    records: 5,
    search: "",
    order: "",
  },
  C: {
    action: "datatable",
    table: "unders A JOIN type_unders B USING (keyTypeUnder)",
    rows: "A.idUnder as idUnder, B.nameTypeUnder, CONCAT_WS(' - ',A.under,A.nameUnder) as nameUnder",
    conditions: "A.enabled = 1",
    page: 0,
    records: 5,
    search: "",
    order: "",
  },
};
const ttArr = {
  A: "Unidades de Trabajo",
  B: "Puestos",
  C: "Conceptos"
};

const inpDates = [10, 11, 12, 13, 14, 15, 36, 63, 66];
const inpPeriod = [7, 9, 10, 11, 12, 13, 14, 15, 17, 20, 21, 22, 39, 40, 42, 44, 45, 59, 60, 61, 63, 66];
const endPeriod = [];
const selectDeductions = [];
const inpOYear = [8];
const inpStaff = [7, 9, 10, 11, 12, 13, 14, 15,63, 66];
const inpUnders = [21];
const inpJobPositions = [41];
const inpBanks = [44, 45];
const inpClave = [63, 66];

export const opt = [
  { name: "No", val: "0" },
  { name: "Si", val: "1" },
];

const Filtro1015 = (props) => {
  const API = peticionesReceiver();
  const [unders, setUnders] = useState([]);
  const {
    report,
    onChange,
    handleBlur,
    values,
    touched,
    errors,
    setFieldValue,
    setModalTabla,
    setModalTablaJobs,
    setModalTablaBanks,
    resetForm,
    setShowReportFormat,
    setModalTabGeneral,
    setModalTabGParams,
    setModalTabGCabeceras,
    setFieldToShow,
    setModalTabGTitle,
  } = props;

  function handleClearFields(input) {
    switch(input){
        case "nameStaff":
            setFieldValue("idStaff", "");
            setFieldValue("nameStaff", "");
            break;
        case "jobPostition":
            setFieldValue("numberJobPositions", "");
            setFieldValue("jobPostition", "");
            break;
        case "bank":
            setFieldValue("keyBank", "");
            setFieldValue("bank", "");
            break;
        case "nameUnder":
            setFieldValue("idUnder", "");
            setFieldValue("nameUnder", "");
            break;
        default:
    }
    setShowReportFormat("0");
  }

  useEffect(() => {
    if (values.idReport !== "") {
      const report = values.idReport.toString();
      const tipo = values.idFormat.toString();
      resetForm();
      setFieldValue("idReport", report);
      setFieldValue("idFormat", tipo);
    }
  }, [values.idReport]);

  useEffect(() => {
    if (values.idReport === "21") {
      getUnders();
    } else if (values.idReport === "63") {
      getUndersDeductions();
    }
  }, [values.idReport]);

  const getUndersDeductions = async () => {
    const params = {
      action: "multiselect",
      table: "unders",
      rows: "idUnder,concat(under,' - ',nameUnder) as name",
      conditions: "enabled = 1 and keyTypeUnder = 2",
      order: "cast(under as float) asc",
    };
    API.peticion(params)
      .then((res) => {
        if (res.status === 200 && res.data.code === "200") {
          setUnders(res.data.data);
        } else {
          toast(
            <Notification
              type={"consultar_error"}
              backMessage={res.data.message}
              withIcon
            />
          );
        }
      })
      .catch((err) => {
        toast(<Notification type={"consultar_servidor_error"} withIcon />);
      });
  };

  const getUnders = () => {
    const params = {
      action: "getUnders",
    };
    API.peticionEndPoint(params, "app/facades/reports/reportsF.php")
      .then((res) => {
        if (res.status === 200) {
          setUnders(res.data.data);
        } else {
          toast(
            <Notification
              type={"consultar_error"}
              backMessage={res.data.message}
              withIcon
            />
          );
        }
      })
      .catch((err) => {
        toast(<Notification type={"consultar_servidor_error"} withIcon />);
      });
  };

  const setParams = (ty, field) => {
    let paramss = paramsArr[ty];
    if(+values.idReport === 66) paramss.conditions += " AND A.keyRollUnder IN (5, 6)";
    setModalTabGParams(paramss);
    setModalTabGCabeceras(headersArr[ty]);
    setFieldToShow([field, field.replace(/^(id|key)/, "name")]);
    setModalTabGTitle(ttArr[ty]);
    setModalTabGeneral(true);
  };

  return (
    <Row className="d-flex justify-content-around">
      <Col xs={12} md={12} lg={10}>
        {inpStaff.includes(+report) && (
          <Row>
            <Col xs={12} lg={12}>
              <ButtonInput
                label="Empleado"
                inputName="nameStaff"
                value={values.nameStaff}
                touched={touched.nameStaff}
                errors={errors.nameStaff}
                clearFun={()=>handleClearFields("nameStaff")}
                onButtonClicik={() => setModalTabla(true)}
                isDisabled={[15].includes(+values.idReport) && (!!values.period || !!values.initialDate)}
                isRequired={
                  ![10, 11, 12, 13, 14, 15, 63, 66].includes(+values.idReport) || 
                  ((!values.period && !values.initialDate && +values.idReport !== 63)) ||
                  [63, 66].includes(+values.idReport) && !values.idUnder && !values.period && !values.initialDate
                }
              />
            </Col>
          </Row>
        )}
        {inpClave.includes(+report) && (
            <Row>
                <Col xs={12} lg={12}>
                    <ButtonInput
                        label="Concepto"
                        inputName="nameUnder"
                        value={values.nameUnder}
                        touched={touched.nameUnder}
                        errors={errors.nameUnder}
                        setModalTable={setModalTabGeneral}
                        clearFun={()=>handleClearFields("nameUnder")}
                        onButtonClicik={() => setParams("C", "idUnder")}
                        isRequired={[63, 66].includes(+values.idReport) && !values.nameStaff && !values.period && !values.initialDate}
                    />
                </Col>
            </Row>
        )}
        {inpPeriod.includes(+report) && (
          <Row className="d-flex justify-content-center align-items-center">
            <Col xs={12} lg={6}>
              <SelectTypeHeadSingle
                label="Periodo"
                optionsArray={PERIOD}
                inputName="period"
                onChangeMethod={onChange}
                onBlurMethod={handleBlur}
                touched={touched.period}
                errors={errors.period}
                optionValue="value"
                optionName="label"
                value={values.period}
                defaultOption="Seleccione un periodo"
                isDisabled={[15].includes(+values.idReport) && (!!values.nameStaff || !!values.initialDate)}
                isRequired={
                  ![10, 11, 12, 13, 14, 15, 63, 66].includes(+values.idReport) || 
                  (!values.nameStaff && !values.initialDate && +values.idReport !== 63) ||                  
                  [63, 66].includes(+values.idReport) && !values.nameStaff && !values.idUnder && !values.initialDate
                }
              />
            </Col>

            {endPeriod.includes(+report) && (
              <Col xs={12} lg={6}>
                <SelectTypeHeadSingle
                  label="Periodo final"
                  isRequired={true}
                  optionsArray={PERIOD}
                  inputName="endPeriod"
                  onChangeMethod={onChange}
                  onBlurMethod={handleBlur}
                  touched={touched.endPeriod}
                  errors={errors.endPeriod}
                  optionValue="value"
                  optionName="label"
                  value={values.endPeriod}
                  defaultOption="Seleccione un periodo"
                />
              </Col>
            )}

            <Col xs={12} lg={6}>
              <YearInput
                label="Año"
                inputName="year"
                onChangeMethod={onChange}
                onBlurMethod={handleBlur}
                value={values.year}
                touched={touched.year}
                errors={errors.year}
                isMin={new Date("2020")}
                isMax={new Date("2500")}
                isDisabled={!values.period}
                isRequired={
                  ![10, 11, 12, 13, 14, 15, 63, 66].includes(+values.idReport) || 
                  (!values.nameStaff && !values.initialDate && +values.idReport !== 63) ||                  
                  [63, 66].includes(+values.idReport) && !values.nameStaff && !values.idUnder && !values.initialDate ||
                  !!values.period
                }
              />
            </Col>
          </Row>
        )}
        {inpDates.includes(+report) && (
          <Row>
            <Col xs={12} lg={6}>
              <TextInput
                label="Fecha inicial"
                inputType="date"
                inputName="initialDate"
                onChangeMethod={onChange}
                onBlurMethod={handleBlur}
                value={values.initialDate}
                touched={touched.initialDate}
                errors={errors.initialDate}
                isDisabled={[15].includes(+values.idReport) && (!!values.period || !!values.nameStaff)}
                isRequired={
                  +values.idReport === 36 || 
                  (!values.nameStaff && !values.period && +values.idReport !== 63) ||                  
                  +values.idReport === 63 && !values.nameStaff && !values.idUnder && !values.period
                }
              />
            </Col>

            <Col xs={12} lg={6}>
              <TextInput
                label="Fecha final"
                inputType="date"
                inputName="finalDate"
                onChangeMethod={onChange}
                onBlurMethod={handleBlur}
                value={values.finalDate}
                touched={touched.finalDate}
                errors={errors.finalDate}
                isMin={values.initialDate}
                isDisabled={!values.initialDate}
                isRequired={
                  +values.idReport === 36 || 
                  (!values.nameStaff && !values.period && +values.idReport !== 63) ||                  
                  +values.idReport === 63 && !values.nameStaff && !values.idUnder && !values.period ||
                  !!values.initialDate
                }
              />
            </Col>
          </Row>
        )}
        {inpOYear.includes(+report) && (
          <Row>
            <Col xs={12} lg={9}>
            <ButtonInput
                label="Empleado"
                inputName="nameStaff"
                value={values.nameStaff}
                touched={touched.nameStaff}
                errors={errors.nameStaff}
                clearFun={()=>handleClearFields("nameStaff")}
                onButtonClicik={() => setModalTabla(true)}
                isRequired
              />
            </Col>
            <Col xs={12} lg={3}>
              <YearInput
                label="Año"
                inputName="year"
                onChangeMethod={onChange}
                onBlurMethod={handleBlur}
                value={values.year}
                isRequired
                touched={touched.year}
                errors={errors.year}
                isMin={new Date("2020")}
                isMax={new Date("2500")}
              />
            </Col>
          </Row>
        )}
        {inpUnders.includes(+report) && (
          <SelectWithSearchInput
            label="Seleccione el reporte un formato de nomina"
            inputOptions={unders}
            inputName="idUnder"
            onChangeMethod={(options) => {
              setFieldValue("idUnder", options.value);
              setFieldValue("under", options.label);
              setShowReportFormat("0");
            }}
            onBlurMethod={handleBlur}
            touched={touched.idUnder}
            errors={errors.idUnder}
            optionValue="idUnder"
            optionName="nameUnder"
            inputValue={values.idUnder}
            isRequired
          />
        )}
        {inpJobPositions.includes(+report) && (
          <Row>
            <Col xs={12} lg={12}>
              <ButtonInput
                label="Puesto"
                inputName="jobPostition"
                value={values.jobPostition}
                touched={touched.jobPostition}
                errors={errors.jobPostition}
                clearFun={()=>handleClearFields("jobPostition")}
                onButtonClicik={() => setModalTablaJobs(true)}
                isRequired
              />
            </Col>
          </Row>
        )}
        {inpBanks.includes(+report) && (
          <Row>
            <Col xs={12} lg={12}>
              <ButtonInput
                label="Bancos"
                inputName="bank"
                value={values.bank}
                touched={touched.bank}
                errors={errors.bank}
                clearFun={()=>handleClearFields("bank")}
                onButtonClicik={() => setModalTablaBanks(true)}
              />
            </Col>
          </Row>
        )}
        {+report === 46 && (
          <Row>
            <Col xs={12} lg={6}>
              <ButtonInput
                label="Adscripción"
                inputName="nameArea"
                value={values.nameArea}
                isRequired
                touched={touched.nameArea}
                errors={errors.nameArea}
                setModalTable={setModalTabGeneral}
                clearFun={() => {
                  setFieldValue("nameArea", "");
                  setFieldValue("idArea", "");
                }}
                isDisabled={false}
                onButtonClicik={() => {
                  setParams("A", "idArea");
                }}
              />
            </Col>
            <Col xs={12} lg={6}>
              <ButtonInput
                label="Adscripción de Comisión"
                inputName="nameAreaComission"
                value={values.nameAreaComission}
                isRequired
                touched={touched.nameAreaComission}
                errors={errors.nameAreaComission}
                setModalTable={setModalTabGeneral}
                clearFun={() => {
                  setFieldValue("nameAreaComission", "");
                  setFieldValue("idAreaComission", "");
                }}
                isDisabled={false}
                onButtonClicik={() => {
                  setParams("A", "idAreaComission");
                }}
              />
            </Col>
            <Col xs={12} lg={6}>
              <ButtonInput
                label="Puesto"
                inputName="nameJob"
                value={values.nameJob}
                isRequired
                touched={touched.nameJob}
                errors={errors.nameJob}
                setModalTable={setModalTabGeneral}
                clearFun={() => {
                  setFieldValue("nameJob", "");
                  setFieldValue("keyJob", "");
                }}
                isDisabled={false}
                onButtonClicik={() => {
                  setParams("B", "keyJob");
                }}
              />
            </Col>
            <Col xs={12} lg={6}>
              <ButtonInput
                label="Puesto de Comisión"
                inputName="nameJobRole"
                value={values.nameJobRole}
                isRequired
                touched={touched.nameJobRole}
                errors={errors.nameJobRole}
                setModalTable={setModalTabGeneral}
                clearFun={() => {
                  setFieldValue("nameJobRole", "");
                  setFieldValue("keyJobRole", "");
                }}
                isDisabled={false}
                onButtonClicik={() => {
                  setParams("B", "keyJobRole");
                }}
              />
            </Col>
            {+values.idReport === 46 && +values.idFormat !== 3 && (
              <Col xs={12}>
                <RadioInput
                  label="Fotografía"
                  inputName="photo"
                  onChangeMethod={onChange}
                  onBlurMethod={handleBlur}
                  value={values.photo}
                  optionsArray={opt}
                  optionValue="val"
                  optionName="name"
                  touched={touched.photo}
                  errors={errors.photo}
                  isCenter
                />
              </Col>
            )}
          </Row>
        )}
      </Col>
      {selectDeductions.includes(+report) && (
        <Row>
          <Col xs={12} lg={12}>
            <Multiselect
              label="Seleccione los filtros de formato"
              optionsArray={unders}
              inputName="unders"
              multiselect={true}
              onBlurMethod={handleBlur}
              touched={touched.unders}
              errors={errors.unders}
              optionValue="idUnder"
              optionName="name"
              inputValue={values.unders}
              isRequired
              setFieldValue={setFieldValue}
            />
          </Col>
        </Row>
      )}
    </Row>
  );
};

export default Filtro1015;

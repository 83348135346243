import { useFormik } from "formik";
import * as Yup from "yup";
import React, { useEffect, useState } from "react";
import { Modal, ModalHeader, ModalBody, Row, Col, Button } from "reactstrap";
import {
  FilesInput,
  SelectTypeHeadSingle,
  TextAreaInput,
} from "../../../components/GenericInputsFormik";
import { ButtonInput } from "../../../components/GenericInputsFormik/ButtonInput";
import ModalConfirmation from "../../../components/Modal/ModalConfirmation";
import ModalTableVer from "../../../components/Modal/ModalTableVer";
import axios from "axios";
import Notification from "../../../components/Notification/Notification";
import { toast } from "react-toastify";
import { peticionEncript } from "../../../helpers/peticionesEncripted";
import FullFormLoader from "../../../components/Loader/FullFormLoader";

/* MODAL TABLE */
// const filtroModalTabla = [true, true, true, true, true];
// const cabecerasModalTabla = [
//   "#",
//   "Núm. Empleado",
//   "Nombre",
//   "Unidad de trabajo",
//   "Puesto",
//   "Seleccionar",
// ];

/* Formualrio */
const defaultForm = {
  idPayrollBank: "", // YA
  idStaff: "",
  idPension: "",
  // nameStaff: "",
  beforeReturn: "", // YA (es el motivo del rechazo)
  idBankAccount: "", // YA
  file: "",
};

async function doRequest(route, params, seeOk, encript) {
  let info = [];
  const dataEncrypted = encript !== false ? peticionEncript(params) : params;
  await axios
    .post(process.env.REACT_APP_API + route, dataEncrypted)
    .then((res) => {
      if (res.status === 200 && res.data.status === "success") {
        info = res.data.data;
        if (seeOk) {
          toast(
            <Notification
              type={"elimina_exito"}
              backMessage={res.data.message}
              withIcon
            />,
            { closeButton: false }
          );
        }
      } else {
        toast(
          <Notification
            type={"consultar_error"}
            backMessage={res.data.message}
            withIcon
          />,
          { closeButton: false }
        );
      }
    })
    .catch((err) => {
      toast(<Notification type={"consultar_servidor_error"} withIcon />, {
        closeButton: false,
      });
    });
  return info;
}

const finanzas = "pagos-transferencias-empleados";
const pensiones = "pagos-transferencias-pensiones";

function RechazoPagoEmpleado(props) {
  const {
    modal,
    setModal,
    idPayrollBank,
    idBankAccount,
    getPagosDepositosEmpleados,
    route,
  } = props;
  const [modalConfirm, setModalConfirm] = useState(false);
  const [loadingForm, setLoadingForm] = useState(false);
  // const [modalTable, setModalTable] = useState(false);
  // const [paramsModalTabla] = useState({
  //   action: "datatable",
  //   table:
  //     "staff STF INNER JOIN employtypes AS EMT ON STF.keyEmployType = EMT.keyEmployType INNER JOIN jobstaff AS JBS ON JBS.idStaff = STF.idStaff INNER JOIN areas AS AR ON AR.idArea = JBS.idArea INNER JOIN jobtypes AS JBT ON JBT.keyJobType = STF.keyEmployType",
  //   rows: "STF.idStaff,STF.staffNumber,CONCAT(STF.name,' ',STF.pName,' ',STF.mName) as fullName,AR.name,JBT.nameJobType",
  //   conditions: `STF.enabled = 1 AND JBS.valid = 1 AND STF.keyStat = 1 AND JBS.enabled = 1`,
  //   page: 0,
  //   records: 5,
  //   search: "",
  //   order: "",
  // });
  const [staffBanks, setStaffBanks] = useState([]);

  const FormSchema = Yup.object().shape({
    idStaff: Yup.string().test("is-employee", "Seleccione un empleado", (v) => {
      return route !== finanzas || v !== undefined;
    }),
    idPension: Yup.string().test(
      "is-pension",
      "Seleccione un empleado",
      (v) => {
        return route !== pensiones || v !== undefined;
      }
    ),
    beforeReturn: Yup.string().required("Ingrese un motivo"),
    file: Yup.mixed()
      .required("Agregue un documento")
      .test("file-type", "El documento debe ser de tipo PDF", (value) => {
        return value && value.type === "application/pdf";
      })
      .test({
        message: `El documento debe pesar menos de 4MB`,
        test: (value) => value?.size < 4000000,
      }),
  });

  const {
    handleSubmit,
    handleReset,
    handleBlur,
    values,
    errors,
    touched,
    setFieldValue,
  } = useFormik({
    initialValues: {
      ...defaultForm,
      keyStat: module === "nomina" ? "75" : "",
    },
    validationSchema: FormSchema,
    onSubmit: () => setModalConfirm(true),
    onReset: () => {},
    enableReinitialize: true,
  });

  useEffect(() => {
    if (modal) {
      getStaffBanks();
    } else {
      handleReset();
    }
  }, [modal]);

  const getStaffBanks = async () => {
    const params = {
      action: "",
      action:
        route === finanzas
          ? "searchStaffBanks"
          : route === pensiones
          ? "searchPensionBanks"
          : "",
      rows: { idPayrollBank: idPayrollBank },
    };
    const data = await doRequest(
      "app/facades/payslips/payrollrequestF.php",
      params,
      false,
      true
    );
    if (data.length > 0) {
      setStaffBanks(data);
    }
  };

  const onChange = (e) => {
    if (e.target.name === "file") {
      if (e.target.files.length === 0) setFieldValue(e.target.name, "");
      else setFieldValue(e.target.name, e.target.files[0]);
    } else setFieldValue(e.target.name, e.target.value);
  };

  const saveInfo = async () => {
    setLoadingForm(true);
    const rows = {
      idPayrollBank: idPayrollBank,
      //idStaff: values.idStaff,///
      beforeReturn: values.beforeReturn,
      idBankAccount: idBankAccount,
    };
    if (route === finanzas) rows.idStaff = values.idStaff;
    if (route === pensiones) rows.idPension = values.idPension;

    const formData = new FormData();
    formData.append("action", "insertRechazo");
    formData.append("rows", JSON.stringify(rows));
    formData.append("binary", values.file);
    const data = await doRequest(
      "app/facades/payslips/payrollrequestF.php",
      formData,
      true,
      false
    );
    if (data.length > 0) {
      getPagosDepositosEmpleados();
      setModal(false);
    }
    setLoadingForm(false);
  };

  /* Funcion que se envia al modalTabla al seleccionar un empleado*/
  // const selectEmpleado = (el) => {
  //   setFieldValue("idStaff", el[0]);
  //   setFieldValue("nameStaff", `${el[1]} - ${el[2]}`);
  //   // setModalTable(false);
  // };

  const toggle = () => {
    setModal(!modal);
  };

  const CloseBtn = (
    <i className="eva eva-close cursor-pointer" onClick={toggle} />
  );

  return (
    <Modal
      isOpen={modal}
      backdrop={true}
      keyboard
      toggle={toggle}
      style={{ minWidth: "95%" }}
    >
      <ModalHeader toggle={toggle} close={CloseBtn}>
        {/* Rechazo de pago por Empleado */}
      </ModalHeader>
      <ModalBody className="px-5 pt-0 mt-0">
        <h4 className="text-default">
          {route === finanzas
            ? "Rechazo de pago por Empleado"
            : route === pensiones
            ? "Rechazo de pago de Pensión"
            : ""}
        </h4>
        <form onSubmit={handleSubmit} onReset={handleReset} className="m-5">
          <ModalConfirmation
            modalTitle="Rechazar Pago"
            modal={modalConfirm}
            setModal={setModalConfirm}
            editar={() => saveInfo()}
            isEdit={true}
            values={values}
          >
            <div className="d-flex justify-content-center">
              <h6>¿Está seguro de guardar el rechazo este pago?</h6>
            </div>
          </ModalConfirmation>
          <Row>
            <Col xs={12} lg={7}>
              {/* <ButtonInput
                label="Empleado"
                inputName="nameStaff"
                value={values.nameStaff}
                isRequired
                touched={touched.nameStaff}
                errors={errors.nameStaff}
                setModalTable={setModalTable}
                clearFun={() => {
                  setFieldValue("idStaff", "");
                  setFieldValue("nameStaff", "");
                }}
              /> */}
              {route === finanzas ? (
                <SelectTypeHeadSingle
                  label="Empleado(a)"
                  isRequired={true}
                  inputName="idStaff"
                  optionsArray={staffBanks}
                  defaultOption="Seleccione un empleado"
                  onChangeMethod={onChange}
                  onBlurMethod={handleBlur}
                  isDisabled={false}
                  value={values.idStaff}
                  touched={touched.idStaff}
                  errors={errors.idStaff}
                  multipleLabels={["staffNumber", "nombre"]}
                  optionName="name"
                  optionValue="idStaff"
                />
              ) : (
                route === pensiones && (
                  <SelectTypeHeadSingle
                    label="Pensión"
                    isRequired={true}
                    inputName="idPension"
                    optionsArray={staffBanks}
                    defaultOption="Seleccione una pensión"
                    onChangeMethod={onChange}
                    onBlurMethod={handleBlur}
                    isDisabled={false}
                    value={values.idPension}
                    touched={touched.idPension}
                    errors={errors.idPension}
                    optionName="nombre"
                    optionValue="idPension"
                  />
                )
              )}
            </Col>
            <Col xs={12} lg={5}>
              <FilesInput
                label="Adjuntar Comprobante (PDF)"
                inputName="file"
                onChangeMethod={onChange}
                onBlurMethod={handleBlur}
                fileAccept="application/pdf"
                isRequired
                touched={touched.file}
                errors={errors.file}
                basicReq={false}
              />
            </Col>
            <Col xs={12}>
              <TextAreaInput
                label="Observaciones"
                inputName="beforeReturn"
                inputRows={6}
                onChangeMethod={onChange}
                onBlurMethod={handleBlur}
                value={values.beforeReturn}
                isRequired
                touched={touched.beforeReturn}
                errors={errors.beforeReturn}
              />
            </Col>
          </Row>
          <div className="text-center text-md-right my-4">
            <Button color="success" type="submit">
              Aceptar
            </Button>
          </div>
        </form>
        <FullFormLoader show={loadingForm} />

        {/* <ModalTableVer
          modal={modalTable}
          setModal={setModalTable}
          title="Empleados"
          cabeceras={cabecerasModalTabla}
          filtro={filtroModalTabla}
          parametros={paramsModalTabla}
          selectRegistro={selectEmpleado}
          backdrop
          keyboard
        /> */}
      </ModalBody>
    </Modal>
  );
}

export default RechazoPagoEmpleado;

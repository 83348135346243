// -- Matriz Egresos Pagados -- //
import MatrizEgresosPagados from "../../../pages/complemento-finanzas/matrices/egresosPagados";

// -- Matriz Egresos Devengados --//
import MatrizVengados from "../../../pages/complemento-finanzas/matrices/egresosDevengadas";
// -- Matriz Ingresos Devengados --//
import MatrizInVengados from "../../../pages/complemento-finanzas/matrices/ingresosDevengados";
// -- Matriz Ingresos Recaudados --//
import MatrizInRecaudados from "../../../pages/complemento-finanzas/matrices/ingresosRecaudados";
// -- Matriz Inventarios --//
import MatrizInventarios from "../../../pages/complemento-finanzas/matrices/inventarios";


const rutasComplementoFinanzas = [
    { path: "/template/matrizpagados", component: MatrizEgresosPagados },
    { path: "/template/matrizdevengados", component: MatrizVengados },
    { path: "/template/matrizingresosdevengados", component: MatrizInVengados },
    { path: "/template/matrizingresosrecaudados", component: MatrizInRecaudados },
    { path: "/template/matrizdebienes", component: MatrizInventarios },
]

export default rutasComplementoFinanzas;
import { peticionesReceiver } from "../../../helpers/peticionesReceiver";
import Notification from "../../../components/Notification/Notification";
import { toast } from "react-toastify";
const API = peticionesReceiver();

export const agregar = async (modulo) => {
    const data = {
      action: "insert",
      table: "modules",
      rows: modulo,
      validate: [
        ["fileRoute"],
        ["pModule", "nameModule", "level"]
      ]
    };    
    API.peticion(data).then((res) => {
        if (res.status === 200 && res.data.code === "200") {
          toast(<Notification type={"agrega_exito"} backMessage={res.data.message} withIcon />);
          window.location.href = "#/template/modulos";
        } else {
          toast(<Notification type={"agrega_error"} backMessage={res.data.message} withIcon />);
        }
      })
      .catch((err) => {
        toast(<Notification type={"consultar_servidor_error"} withIcon />);
      });
  };

export const editar = async (modulo) => {
    const data = {
      action: "update",
      table: "modules",
      rows: modulo,
      condition: {
        idModule: modulo.idModule,
      },
      validate: [
        ["fileRoute"],
        ["pModule", "nameModule", "level"]
      ]
    };
    
    API.peticion(data).then((res) => {
        if (res.status === 200 && res.data.code === "200") {
          toast(<Notification type={"modifica_exito"} backMessage={res.data.message} withIcon />);
          window.location.href = "#/template/modulos";
        } else {
          toast(<Notification type={"modifica_error"} backMessage={res.data.message} withIcon />);
        }
      })
      .catch((err) => {
        toast(<Notification type={"consultar_servidor_error"} withIcon />);
      });
  };

  const eliminar = async (id) => {
    if (id) {
      const data = {
        action: "delete",
        table: "modules",
        condition: {
          idModule: id,
        },
      };
      API.peticion(data).then((res) => {
          if (res.status === 200 && res.data.code === "200") {
            toast(<Notification type={"elimina_exito"} backMessage={res.data.message} withIcon />);
          } else {
            toast(<Notification type={"elimina_error"} backMessage={res.data.message} withIcon />);
          }
        })
        .catch((err) => {
          toast(<Notification type={"consultar_servidor_error"} withIcon />);
        });
    }
  };

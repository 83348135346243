import Datatable from "../../../../../../components/Datatable/Datatable"
import { peticionesReceiver } from "../../../../../../helpers/peticionesReceiver"
import { toast } from "react-toastify"
import Notification from "../../../../../../components/Notification/Notification"
import { useState } from "react"
import FullFormLoader from "../../../../../../components/Loader/FullFormLoader"

/**
 * Formateador de numeros de concurrencias
 */
const formatter = new Intl.NumberFormat("es-MX", {
    style: "currency",
    currency: "MXN",
});

export default function Report48({ title, params, route, htmlConfig }) {

    const API = peticionesReceiver()

    const [montoTotal, setMontoTotal] = useState("0")
    const [loading, setLoading] = useState(true)

    const columns = [
        { data: "nameIncombeSubType" },
        {
            data: null,
            render: function (row) {
                return row.nameIncombeSubType === "Transferencias Internas y Asignaciones al Sector Público" ? formatter.format(montoTotal) : "$0.00"
            }
        },
    ]

    const headers = htmlConfig.headers

    const columnDefs = [
        {
            targets: [1],
            className: "text-right"
        }
    ]

    const getData = async () => {
        try {
            const res = await API.peticionEndPoint(params, route)
            if (res.status === 200 && res.data.code === "200") {
                const data = [...res.data.data, ...res.data.dataCero]
                setMontoTotal(res.data.montoTotal)
                setLoading(false)
                return { data };
            } else {
                toast(<Notification type={"consultar_error"} backMessage={res.data.message} withIcon />);
                setLoading(false)
                return null;
            }
        } catch (err) {
            toast(<Notification type={"consultar_servidor_error"} withIcon />);
            setLoading(false)
            return null
        }
    }

    return (
        <>
            <h1 className="text-secondary text-center">{title} </h1>
            <Datatable
                columns={columns}
                headers={headers}
                columnDefs={columnDefs}
                ordering={false}
                searching={false}
                paging={false}
                petition={getData}
                info={false}
            />
            <FullFormLoader show={loading} message="Consultando datos , espere un momento" />
        </>
    )
}
import { useEffect, useState } from "react";
import Datatable from "../../../components/Datatable/Datatable";
import Widget from "../../../components/Widget/Widget";
import { doRequest, doRequestSaveRes } from "../../../helpers/requests";
import { Badge, Button } from "reactstrap";
import FullScreenLoader from "../../../components/Loader/FullScreenLoader";
import { GetPermissions } from "../../../libs/permissions/getPermissions";
import sb from "./recepCot.module.scss";
import classNames from "classnames";
import ModalConfirmation from "../../../components/Modal/ModalConfirmation";
import AsignaPerosnal from "./forms/AsignarPersonal";
import { fDate } from "../../../libs/tools/format";
import ModalViewFile from "../../../components/Modal/ModalViewFile";
import CancelarSolicitud from "./forms/CancelarSolicitud";
import { useHistory } from "react-router-dom";

const headers = [
  "FOLIO",
  "ESTATUS",
  "OBJETO",
  "SOLICITANTE",
  "ENCARGADO",
  "AUTORIZÓ",
  "INGRESO DE LA SOLICITUD DE COTIZACIÓN",
  "ASIGNADA A",
  "FECHA EN QUE SE ATENDIO LA SOLICITUD",
  "OBSERVACIONES",
  "ACCIONES",
];

const columnDefs = [
  {
    orderable: true,
    targets: [0, 1, 4, 6, 8],
    className: "text-center",
  },
  {
    orderable: true,
    targets: [2, 6, 9],
    className: "text-justify",
  },
];

function getStyle(d) {
  switch (+d) {
    case 102:
      return "secondary";
    case 103:
      return "info";
    case 104:
      return "danger";
    case 105:
      return "warning";
    case 106:
      return "success";
  }
}

export default function RecepcionCotizaciones() {
  const [permissions, setPermissions] = useState({});
  GetPermissions(setPermissions);

  const [refresh, setRefresh] = useState(false);
  const [loading, setLoading] = useState(false);
  const [msgLoader, setMsgLoader] = useState("");
  const [modaleAsig, setModaleAsig] = useState(false); //Modala asignar staff
  const [idQuote, setIdQuote] = useState("");
  const [personal, setPersonal] = useState([]);
  const [linkFile, setLinkFile] = useState(""); ///Link de archivo
  const [modalFile, setModalFile] = useState(false); ///Visualziar archivos
  const [modalRechazo, setModalRechazo] = useState(false); //Modala rechazo

  const history = useHistory();

  const columns = [
    { data: "folio" },
    {
      data: null,
      render: function (d) {
        return <Badge color={getStyle(d.keyStat)}>{d.nameStat}</Badge>;
      },
    },
    {
      data: "object",
      render: function (d) {
        return <div style={{ minWidth: "250px" }}>{d}</div>;
      },
    },
    { data: "fullNameSol" },
    {
      data: "fullNameEnc",
      render: function (d) {
        return <div>{d !== "" ? d : "Sin Atender"}</div>;
      },
    },
    {
      data: "fullNameAut",
      render: function (d) {
        return <div>{d !== "" ? d : "Sin Aprobar"}</div>;
      },
    },
    { data: "created", format: "date" },
    {
      data: null,
      render: function (row) {
        return permissions.UPD && row.fullNameAsi === "" ? (
          <div className="text-center">
            {
              <button
                className={classNames([
                  "btn text-light d-flex flex-row px-2",
                  sb.myBtn,
                  sb.success,
                ])}
                onClick={() => {
                  setIdQuote(row.idQuote);
                  setModaleAsig(true);
                }}
              >
                <span>Asignar</span>
                <i className="fa fa-user-plus my-1 ml-1" />
              </button>
            }
          </div>
        ) : (
          row.fullNameAsi
        );
      },
    },
    {
      data: "dateSend",
      format: fDate,
    },
    { data: "notes" },
    {
      data: null,
      render: function (row) {
        return (
          <div className={classNames(["text-center pl-2", sb.colAction])}>
            <button
              className={classNames([
                "btn text-light d-flex flex-row px-2 my-3",
                sb.myBtn,
                sb.info,
              ])}
              onClick={() => {
                setLinkFile(row.src);
              }}
            >
              <i className="fa fa-eye my-1 mr-1" />
              <span>Solicitud</span>
            </button>

            {permissions.UPD && +row.keyStat < 104 && (
              <>
                <button
                  className={classNames([
                    "btn text-light d-flex flex-row px-2 my-3",
                    sb.myBtn,
                    sb.success,
                  ])}
                  onClick={() => {
                    openDetail(row);
                  }}
                >
                  <i className="fa fa-pencil-square-o my-1 mr-1" />
                  <span>Atender Solicitud</span>
                </button>

                <button
                  className={classNames([
                    "btn text-light d-flex flex-row px-2 my-3",
                    sb.myBtn,
                    sb.danger,
                  ])}
                  onClick={() => {
                    setIdQuote(row.idQuote);
                    setModalRechazo(true);
                  }}
                >
                  <i className="fa fa-ban my-1 mr-1" />
                  <span>Cancelar solicitud</span>
                </button>
              </>
            )}
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    getPersonal();
  }, []);

  useEffect(() => {
    if (linkFile !== "") {
      setModalFile(true);
    }
  }, [linkFile]);

  useEffect(() => {
    if (!modalFile) {
      setLinkFile("");
    }
  }, [modalFile]);

  async function getData(ordCol, itemsPerPage, currentPage, search) {
    const res = await doRequestSaveRes(
      "receiver/receiver.php",
      getParams(ordCol, itemsPerPage, currentPage, search),
      false,
      false
    );
    if (res.code === "200") return res;
    else return null;
  }

  function getParams(ordCol, itemsPerPage, currentPage, search) {
    const params = {
      action: "datatable",
      table:
        "quotes A LEFT JOIN staff B ON A.idStaffRequire = B.idStaff LEFT JOIN staff C ON A.idStaffRevision = C.idStaff LEFT JOIN status D ON A.keyStat = D.keyStat LEFT JOIN staff E ON A.idStaffAproves = E.idStaff LEFT JOIN bookcase F ON A.idQuote = F.reference AND F.keyFileType = 42 LEFT JOIN files G ON F.idBook = G.idBook LEFT JOIN staff H ON A.idStaffAttend = H.idStaff",
      rows: "CONCAT(A.numberQuote,'/',A.yearQuote) AS folio, D.nameStat, A.object, CONCAT(B.name,' ',B.pName,' ',B.mName) AS fullNameSol, CONCAT(C.name,' ',C.pName,' ',C.mName) AS fullNameEnc, CONCAT(E.name,' ',E.pName,' ',E.mName) AS fullNameAut, CONCAT(H.name,' ',H.pName,' ',H.mName) AS fullNameAsi, A.created, A.dateSend, A.notes, A.idQuote, A.keyStat, G.src ",
      conditions: "A.enabled=1 AND A.keyStat > 101 AND G.enabled = 1",
      order: ordCol,
      records: itemsPerPage.toString(),
      page: (itemsPerPage * currentPage).toString(),
      search,
    };
    return params;
  }

  async function getPersonal() {
    const params = {
      action: "multiselect",
      rows: "CONCAT(B.staffNumber,' - ',B.name,' ',B.pName,' ',B.mName) AS fullName, B.idStaff",
      table: "quoteagents A LEFT JOIN staff B USING(idStaff)",
      conditions: `A.enabled = 1 AND B.enabled= 1`,
    };
    const res = await doRequest("receiver/receiver.php", params, false);
    if (res.length > 0) setPersonal(res);
  }

  function openDetail(row) {
    localStorage.setItem("quote", row.idQuote.toString());
    history.push({ pathname: "/template/recepcioncotizacion/detalles" });
  }

  return (
    <Widget className="widget-p-md">
      <div className="mb-4 mx-3">
        <h2>Recepción de Cotizaciones</h2>
      </div>

      {permissions.CON ? (
        <Datatable
          headers={headers}
          columns={columns}
          columnDefs={columnDefs}
          petition={getData}
          control="back"
          stateRefresh={[refresh, setRefresh]}
          searching={true}
          order={{col: 6, opt: "desc"}}
        />
      ) : (
        <div className="text-center p-4">
          No tienes permitido ver los registros de esta seccion
        </div>
      )}
      <AsignaPerosnal
        idQuote={idQuote}
        personal={personal}
        modal={modaleAsig}
        setModal={setModaleAsig}
        setRefresh={setRefresh}
      />

      <CancelarSolicitud
        idQuote={idQuote}
        modal={modalRechazo}
        setModal={setModalRechazo}
        setRefresh={setRefresh}
      />

      <ModalViewFile
        modal={modalFile}
        setModal={setModalFile}
        backdrop={true}
        keyboard={true}
        btnClose={true}
        fileLink={linkFile}
      />
      <FullScreenLoader show={loading} message={msgLoader} />
    </Widget>
  );
}

import Datatable from "../../../components/Datatable/Datatable";
import { peticionesReceiver } from "../../../helpers/peticionesReceiver";
import { toast } from "react-toastify";
import Notification from "../../../components/Notification/Notification";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useState } from "react";
import { GetPermissions } from "../../../libs/permissions/getPermissions";
import { Spinner } from "reactstrap";

export default function CargaFacturas() {
    const API = peticionesReceiver();
    const { profileType } = useSelector((state) => state.userInfo);
    const idArea = profileType.selectProfile.idArea;
    const history = useHistory()
    const [permissions, setPermissions] = useState({});
    const [loading, setLoading] = useState(true)
    GetPermissions(setPermissions, setLoading);

    const headers = [
        "Folio",
        "Estatus",
        "Justificación",
        "Monto suficiencia",
        "Detalle"
    ];

    function viewDetails(id) {
        localStorage.setItem("loadBill", id);
        history.push("/template/carga-facturas/detalles")
    }

    const columns = [
        { data: "folio" },
        { data: "nameStat" },
        { data: "justification" },
        {
            data: "requestedAmount",
            format: "currency"
        },
        {
            data: "idPurchaseRequest",
            render: function Render(row) {
                return <i className="fa fa-eye text-info cursor-pointer" onClick={() => viewDetails(row)} />
            }
        },
    ]

    const getParams = (order, records, page, search) => {
        const params = {
            action: "datatable",
            table: "warehouseincomes wh join purchaserequests pr on (wh.idPurchaseRequest=pr.idPurchaseRequest) inner join status s on (s.keyStat = pr.keyStat) inner join budgetrequests br on (br.idBudgetRequest=pr.idBudgetRequest)",
            rows: "pr.idPurchaseRequest,CONCAT(pr.numberRequest,'/',pr.yearRequest) as folio,s.nameStat,br.justification,br.requestedAmount",
            conditions: `pr.idArea=${idArea} AND wh.enabled = 1`,
            group:"pr.idPurchaseRequest",
            order,
            records: records.toString(),
            page: (page * records).toString(),
            search
        }
        return params
    }

    async function getData(order, records, page, search) {
        try {
            const res = await API.peticion(getParams(order, records, page, search));
            if (res.status === 200 && res.data.code === "200") {
                return res.data;
            } else {
                toast(<Notification type={"consultar_error"} backMessage={res.data.message} />);
                return null;
            }
        } catch (err) {
            toast(<Notification type={"consultar_servidor_error"} withIcon />);
            return null
        }
    }

    if (loading) {
        return (
            <div className="d-flex align-items-center justify-content-center" style={{ height: "200px" }}>
                <Spinner
                    color="primary"
                    style={{
                        height: '3rem',
                        width: '3rem'
                    }}
                    type="grow"
                >
                    Loading...
                </Spinner>
            </div>
        )
    }
    return (
        <>
            <h1 className="text-secondary">Carga de facturas</h1>
            <h4 className="mb-4">Adquisicion de bienes y servicios</h4>
            {permissions.CON ? (
                <Datatable
                    headers={headers}
                    columns={columns}
                    petition={getData}
                    control="back"
                />
            ) : (
                <div className="text-center p-4">
                    No tienes permitido ver los registros de esta seccion
                </div>
            )}

        </>
    )
}
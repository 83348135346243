import * as XlsxPopulate from "xlsx-populate";
import { saveAs } from "file-saver";

export async function generateExcelAportacionesIssemym(
  report,
  headers,
  title,
  filter,
  params
) {
  XlsxPopulate.fromBlankAsync().then(async (workbook) => {
    
    const ds = {
      wrapText: true,
      horizontalAlignment: "center",
      verticalAlignment: "center",
      border: true,
    };
    const hs = {
      horizontalAlignment: "center",
      verticalAlignment: "center",
      border: true,
      bold: true,  
    };

    const ts = {
      horizontalAlignment: "center",
      verticalAlignment: "center",
      bold: true,
    };

    const sheet1 = workbook.sheet(0);
    const sheetData = getSheetData(report, headers, filter, params);
    const mid = Math.ceil(headers.length / 2);

    // Unir tres columnas del centro
    let colL = sheet1.column(mid - 1).columnName();
    let colR = sheet1.column(mid + 1).columnName();

    sheet1.range(`${colL}1:${colR}1`).merged(true).value("SAPP").style(ts);
    sheet1.range(`${colL}2:${colR}2`).merged(true).value(title).style(ts);
    if (params && params !== "") {
      sheet1.range(`${colL}3:${colR}3`).merged(true).value(params).style(ts);
    }

    sheet1.row(6).cell(1).value("TIPO DE REGISTRO").style(hs);
    sheet1.row(6).cell(2).value("IDENTIFICACION DE LA OPERACION").style(hs);
    sheet1.row(6).cell(3).value("TIPO DE ENTIDAD DE ORIGEN").style(hs);
    sheet1.row(6).cell(4).value("CLAVE DE ENTIDAD DE ORIGEN").style(hs);
    sheet1.row(6).cell(5).value("FECHA DE TRANSMISION").style(hs);
    sheet1.row(6).cell(6).value("RFC PATRONAL").style(hs);
    sheet1.row(6).cell(7).value("NOMBRE DE LA INSTITUCION PUBLICA").style(hs);
    sheet1.row(6).cell(8).value("CLAVE DE LA INSTITUCION PUBLICA").style(hs);
    
    sheet1.row(7).cell(1).value("1").style(ds);
    sheet1.row(7).cell(2).value("1").style(ds);
    sheet1.row(7).cell(3).value("3").style(ds);
    sheet1.row(7).cell(4).value("1").style(ds);
    sheet1.row(7).cell(5).value("20230228").style(ds);
    sheet1.row(7).cell(6).value("PJE950909L97").style(ds);
    sheet1.row(7).cell(7).value("PODER JUDICIAL DEL ESTADO DE MEXICO").style(ds);
    sheet1.row(7).cell(8).value("CLAVE DE INSTITUCION PUBLICA").style(ds);



    const totals = [[0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,0]];

    filter.content[0].forEach((obj, indexObj) => {
      sheet1.row(9).cell(1+indexObj).value(obj.header)
    })


    if (report.length > 4000) {
      
      report.forEach((staff,indexReport) => {
        filter.content[0].forEach((obj, indexObj) => {
          if (indexObj > 15 && indexObj < 34) {
            totals[0][indexObj - 16] += +staff[obj.value];
          }
          sheet1.row(10+indexReport).cell(1+indexObj).value(staff[obj.value])
        })
    });
      

    }else{

      report.forEach((staff,indexReport) => {
        filter.content[0].forEach((obj, indexObj) => {
          if (indexObj > 15 && indexObj < 34) {
            totals[0][indexObj - 16] += +staff[obj.value];
          }
          sheet1.row(10+indexReport).cell(1+indexObj).value(staff[obj.value]).style(ds);
        })
    });
    
    }


    sheet1
        .row(10 + report.length)
        .cell(17)
        .value(sheetData.totals)
        .style(ds);

    

    
    
    
    

    
    




    /* if (report.length > 4000) {
      sheet1.row(9).cell(1).value(sheetData.headers);
      sheet1.row(10).cell(1).value(sheetData.data)
      .row(10 + sheetData.data.length)
        .cell(17)
        .value(sheetData.totals);
    } else {
      sheet1.row(9).cell(1).value(sheetData.headers)
      sheet1.row(10).cell(1).value(sheetData.data).style(ds);
      sheet1
        .row(10 + sheetData.data.length)
        .cell(17)
        .value(sheetData.totals)
        .style(ds);
    } */
    

    for (let i = 1; i <= headers.length; i++) {
      sheet1.column(i).width(40);
      sheet1.row(9).cell(i).style(hs);
    }

    

    return workbook.outputAsync().then((res) => {
      saveAs(res, `${title}.xlsx`);
    });
  });
}

function getSheetData(data, header, filter, params) {
  let report = {
    headers: [header],
    data: [],
    totals: [[0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,0]],
  };
  data.forEach((staff) => {
    let staffInfo = [];
    staffInfo.push(
      filter.content[0].map((obj, indexObj) => {
        if (indexObj > 15 && indexObj < 34) {
          report.totals[0][indexObj - 16] += +staff[obj.value];
        }
        return staff[obj.value];
      })
    );
    report.data.push([staffInfo]);
  });

  console.log("REPORT", report);
  return report;
}

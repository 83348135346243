
import React from "react";
import Widget from "../../../../components/Widget/Widget";
import FormLeyenda from "../FormLeyenda"
import { createLegend } from "../functions";

export const CrearLeyenda = () => {

  return (
    <Widget className="widget-p-md">
        <h3>Crear Leyenda</h3>
        <FormLeyenda crear={createLegend} />
    </Widget>
  )
}

export default CrearLeyenda;
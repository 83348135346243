import React, { useState, useEffect } from "react";

import * as Yup from "yup";
import { useFormik } from "formik";
import { Button, Row, Col } from "reactstrap";
import { peticionesReceiver } from "../../../../helpers/peticionesReceiver";
import ModalDelete from "../../../../components/Modal/ModalDelete";
import Datatable from "../../../../components/Datatable/Datatable";
import { GetPermissions } from "../../../../libs/permissions/getPermissions";
import { toast } from "react-toastify";
import Notification from "../../../../components/Notification/Notification";
import ModalConfirmation from "../../../../components/Modal/ModalConfirmation";
import { SelectTypeHeadSingle } from "../../../../components/GenericInputsFormik/index";
import { Loader } from "react-bootstrap-typeahead";
import { TextInput } from "../../../../components/GenericInputsFormik/";


const TablaDocumentosTiposCompras = (props) => {
  const { data, resetFormuario } = props;
  const API = peticionesReceiver();

  const [filesTypes, setFilesTypes] = useState([]);
  const [filesTypestoShow, setFilesTypestoShow] = useState([]);

  const [modalConfirmation, setModalConfirmation] = useState(false);
  const [loader, setLoader] = useState(true);
  const [loading, setLoading] = useState(true);
  const [permissions, setPermissions] = useState(true);
  GetPermissions(setPermissions, setLoading);

  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    getFileTypes();
  }, []);

  const getFileTypes = async () => {
    setLoader(false);
    const data = {
      action: "select",
      table: "purchasedocs",
      order: "namePurchaseDoc ASC",
    };
    await API.peticion(data)
      .then((res) => {
        if (res.status === 200 && res.data.status === "success") {
          setFilesTypes(res.data.data);
          setLoader(false);
        } else {
          toast(<Notification type={"consultar_error"} withIcon />);
        }
      })
      .catch((err) => {
        toast(<Notification type={"consultar_servidor_error"} withIcon />);
      });
  };

  useEffect(() => {
    filterDocumentsArray();
  }, [filesTypes]);

  const headers = [
    "#",
    "Tipo de archivo",
    "Descripcion",
    "Fecha de subida",
    "Eliminar",
  ];
  const columns = [
    { data: "index" },
    { data: "namePurchaseDoc" },
    { data: "description" },
    { data: "created" },
    {
      data: null,
      render: function (row) {
        return permissions.DEL ? (
          <>
            <ModalDelete
              table={["types_purchasedocs"]}
              nameColumn={["keyTypePurchaseDoc"]}
              elimina={() => deleteFileType(row)}
              setRefresh={setRefresh}
              type="icon"
            >
              <h6>¿Seguro de eliminar el registro?</h6>
              No podrá recuperar la información después de ser eliminada
            </ModalDelete>
          </>
        ) : (
          "Accion no permitida"
        );
      },
    },
  ];

  const deleteFileType = async (obj) => {
    const params = {
      action: "delete",
      table: "types_purchasedocs",
      condition: { keyTypePurchaseDoc: obj.keyTypePurchaseDoc },
    };
    await API.peticion(params)
      .then((res) => {
        if (res.data.status === "success" && res.data.code === "200") {
          toast(
            <Notification
              type={"elimina_exito"}
              backMessage={res.data.message}
              withIcon
            />
          );
          filterDocumentsArray()
          setRefresh(true);
        } else {
          toast(
            <Notification
              type={"elimina_error"}
              backMessage={res.data.message}
              withIcon
            />,
            { closeButton: false }
          );
        }
      })
      .catch((err) => {
        toast(<Notification type={"consultar_servidor_error"} withIcon />);
      });
  };

  const columnDefs = [
    {
      targets: [0, 1, 2, 3, 4],
      className: "text-center",
    },
    {
      orderable: false,
      targets: [0],
    },
  ];

  const getParamsTableElmt = (order, records, page, search) => {
    const params = {
      action: "datatable",
      table:
        "types_purchasedocs A INNER JOIN purchasedocs B ON (A.keyPurchaseDoc = B.keyPurchaseDoc)",
      rows: "A.keyTypePurchaseDoc,A.keyTypePurchase,B.namePurchaseDoc,A.created,A.description",
      conditions: `A.enabled = 1 and B.enabled = 1 and A.keyTypePurchase = ${data.keyTypePurchase}`,
      order,
      records: records.toString(),
      page: (page * records).toString(),
      search,
    };
    return params;
  };

  async function getData(order, records, page, search) {
    try {
      const res = await API.peticion(
        getParamsTableElmt(order, records, page, search)
      );
      if (res.status === 200 && res.data.code === "200") {
        const data = res.data;
        data.data.forEach((value, index) => (value.index = 1 + index));
        return data;
      } else {
        toast(
          <Notification
            type={"consultar_error"}
            backMessage={res.data.message}
          />
        );
        return null;
      }
    } catch (err) {
      toast(<Notification type={"consultar_servidor_error"} withIcon />);
      return null;
    }
  }

  const onChange = (e) => {
    const { name, value } = e.target;
    setFieldValue([name], value);
  };

  const saveDocument = async () => {
    const params = {
      action: "insert",
      table: "types_purchasedocs",
      rows: {
        keyPurchaseDoc: values.keyPurchaseDoc,
        keyTypePurchase: data.keyTypePurchase,
        description: values.description,
      },
      /* validate: [["keyTypePurchase", "description"]], */
    };

    const res = await API.peticion(params);
    if (res.status === 200 && res.data.status === "success") {
      toast(
        <Notification
          type={"agrega_exito"}
          backMessage={res.data.message}
          withIcon
        />
      );
      filterDocumentsArray()
      setRefresh(true);
      resetForm();
    } else {
      toast(
        <Notification
          type={"agrega_error"}
          backMessage={res.data.message}
          withIcon
        />
      );
    }
  };

  const FormSchema = Yup.object().shape({
    keyPurchaseDoc: Yup.string().required("Seleccione un tipo de documento"),
    description: Yup.string().required("Ingrese una descripción"),
  });

  const {
    handleSubmit,
    values,
    errors,
    setFieldValue,
    touched,
    handleBlur,
    resetForm,
  } = useFormik({
    initialValues: {
      keyPurchaseDoc: "",
      description: "",
    },
    onSubmit: () => {
      setModalConfirmation(true);
    },
    validationSchema: FormSchema,
    enableReinitialize: true,
  });

  const filterDocumentsArray = async () => {
    const arrFiles = filesTypes;

    const documentsList = await getDocumentList();

    

    if (
      arrFiles !== null ||
      (arrFiles !== undefined && documentsList !== null) ||
      documentsList !== undefined
    ) {



      var resultado = arrFiles.filter(function(objeto1) {
        return !documentsList.some(function(objeto2) {
          return +objeto1.keyPurchaseDoc === +objeto2.keyPurchaseDoc;
        });
      });

      


      setFilesTypestoShow(resultado)
    }
  };

  const getDocumentList = async () => {
    let lista = [];
    const params = {
      action: "multiselect",
      table: "types_purchasedocs",
      rows: "*",
      conditions: `enabled = 1 and keyTypePurchase = ${data.keyTypePurchase}`,
    };

    await API.peticion(params)
      .then((res) => {
        if (res.status === 200 && res.data.status === "success") {
          lista = res.data.data;
        }
      })
      .catch((err) => {
        
        toast(<Notification type={"consultar_servidor_error"} withIcon />);
      })
      .finally(() => {});

    return lista;
  };

  return (
    <div>
      {loader ? (
        <Loader />
      ) : (
        <>
          {permissions.CON ? (
            <>
              <form onSubmit={handleSubmit} className="mt-3">
                <div className="text-center mb-5">
                  <h1>Documentos requeridos</h1>
                </div>

                <div className="mb-5">
                  <Datatable
                    headers={headers}
                    columns={columns}
                    columnDefs={columnDefs}
                    petition={getData}
                    control="back"
                    stateRefresh={[refresh, setRefresh]}
                    searching={true}
                    order={{ col: 1, opt: "desc" }}
                  />
                </div>

                <Row className="d-flex align-items-center justify-content-around mb-5">
                  <Col xs={12} lg={5}>
                    <SelectTypeHeadSingle
                      label="Tipo de documento"
                      isRequired={true}
                      inputName="keyPurchaseDoc"
                      optionsArray={filesTypestoShow}
                      defaultOption="Seleccione un tipo de documento"
                      onChangeMethod={onChange}
                      value={values.keyPurchaseDoc}
                      optionValue="keyPurchaseDoc"
                      optionName="namePurchaseDoc"
                      onBlurMethod={handleBlur}
                      errors={errors.keyPurchaseDoc}
                    />
                  </Col>

                  <Col xs={12} lg={5}>
                    <TextInput
                      label="Descripción"
                      inputType="text"
                      inputName="description"
                      onChangeMethod={onChange}
                      onBlurMethod={handleBlur}
                      value={values.description}
                      touched={touched.description}
                      errors={errors.description}
                    />
                  </Col>
                  {/* <Col xs={12} lg={5} className="text-center">
                    <Button type="submit" color="success">
                      Agregar
                    </Button>
                  </Col> */}
                </Row>

                <div className="d-flex justify-content-around mt-2">
                  <div>
                    <Button
                      color="danger"
                      onClick={() => {
                        resetFormuario();
                      }}
                    >
                      Cancelar
                    </Button>
                  </div>

                  {permissions.UPD && (
                    <div>
                      <Button type="submit" color="success">
                        Agregar
                      </Button>
                    </div>
                  )}
                </div>

                <ModalConfirmation
                  modalTitle={"Crear"}
                  modal={modalConfirmation}
                  setModal={setModalConfirmation}
                  crear={saveDocument}
                  isEdit={false}
                  values={values}
                >
                  <div className="text-center">
                    Esta seguro de continuar con el registro
                  </div>
                </ModalConfirmation>
              </form>
            </>
          ) : (
            "No tiene permisos para consultar  este modulo"
          )}
        </>
      )}
    </div>
  );
};

export default TablaDocumentosTiposCompras;

import * as Yup from "yup";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { Col, Row, Button } from "reactstrap";
import React, { useState, useEffect } from "react";
import { useFormik } from "formik";

import Loader from "../../../components/Loader/Loader";
import Notification from "../../../components/Notification/Notification";
import ModalConfirmation from "../../../components/Modal/ModalConfirmation";
import {
  TextInput,
  CurrencyInput,
} from "../../../components/GenericInputsFormik";
import { REGEX_NUMBER_WITH_DECIMAL } from "../../../helpers/regex";
import { NumberInput } from "../../../components/GenericInputsFormik/NumberInput";

import "react-datepicker/dist/react-datepicker.css";

const FormConfiguracionCalculo = ({ crear, obtener }) => {
  const defaultForm = {
    supportPS: "",
    supportPB: "",
    supportGA: "",
    supportFSSR: "",
    supportSS: "",
    supportOSCI: "",
    quotaOSCI: "",
    quotaSSR: "",
    quotaSS: "",
    minSS: "",
    maxSS: "",
    smi: "",
    uma: "",
    averageDays: "",
    naturalDays: "",
  };

  const history = useHistory();
  const [modal, setModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);
    getConfiguration();
  }, []);

  const getConfiguration = async () => {
    const response = await obtener();
    if (response.success) {
      setIsLoading(false);
      if (response.data.length > 0) {
        const data = response.data[0];
        setFieldValue("naturalDays", data.naturalDays);
        setFieldValue("averageDays", data.averageDays);
        setFieldValue("uma", data.uma);
        setFieldValue("smi", data.smi);
        setFieldValue("maxSS", data.maxSS);
        setFieldValue("minSS", data.minSS);
        setFieldValue("quotaSS", data.quotaSS);
        setFieldValue("quotaSSR", data.quotaSSR);
        setFieldValue("quotaOSCI", data.quotaOSCI);
        setFieldValue("supportOSCI", data.supportOSCI);
        setFieldValue("supportSS", data.supportSS);
        setFieldValue("supportFSSR", data.supportFSSR);
        setFieldValue("supportGA", data.supportGA);
        setFieldValue("supportPB", data.supportPB);
        setFieldValue("supportPS", data.supportPS);
      }
    } else {
      toast(
        <Notification
          type={"consultar_error"}
          backMessage={response.message}
          withIcon
        />
      );
    }
  };

  const FormSchema = Yup.object().shape({
    averageDays: Yup.number().required("Favor de ingresar los días promedio"),
    uma: Yup.string().required(
      "Favor de ingresar la unidad de Medida y Actualización"
    ),
    naturalDays: Yup.number()
      .max(Yup.ref("averageDays"), "No debe de ingresar mas dias al promedio")
      .required("Favor de ingresar los días naturales"),
    smi: Yup.string().required("Favor de ingresar el salario mínimo"),
    maxSS: Yup.string().required(
      "Favor de ingresar el monto máximo seguro social"
    ),
    minSS: Yup.string().required(
      "Favor de ingresar el monto mínimo seguro social"
    ),
    quotaSS: Yup.string().required(
      "Favor de ingresar la cuota servicio de salud"
    ),
    quotaSSR: Yup.string().required(
      "Favor de ingresar la cuota del sistema solidario de raprto"
    ),
    quotaOSCI: Yup.string().required(
      "Favor de ingresar la cuotas obligatorias del sistema de capitalización individual"
    ),
    supportOSCI: Yup.string().required(
      "Favor de ingresar la aportaciones obligatorias del sistema de capitalización individual"
    ),
    supportSS: Yup.string().required("Favor de ingresar el servicio de salud"),
    supportFSSR: Yup.string().required(
      "Favor de ingresar el fondo del sistema solidario de reparto"
    ),
    supportGA: Yup.string().required(
      "Favor de ingresar el gastos de administración"
    ),
    supportPB: Yup.string().required("Favor de ingresar la prima Básica"),
    supportPS: Yup.string().required(
      "Favor de ingresar la prima de siniestralidad"
    ),
  });

  const {
    handleSubmit,
    values,
    handleBlur,
    errors,
    touched,
    setFieldValue,
    resetForm,
  } = useFormik({
    initialValues: defaultForm,
    onSubmit: () => setModal(true),
    validationSchema: FormSchema,
  });

  const goBack = (reset) => {
    reset();
    history.goBack();
  };

  const saveConfigurate = async () => {
    setIsLoading(true);
    const result = await crear(values);
    if (result.success) {
      toast(<Notification type={"agrega_exito"} withIcon />);
      setIsLoading(false);
      goBack(resetForm);
    } else {
      setIsLoading(false);
    }
  };

  const onChangeNumbers = (event) => {
    const { name, value } = event.target;
    if (REGEX_NUMBER_WITH_DECIMAL.test(value)) {
      setFieldValue([name], value);
    }
  };

  const onChange = (event) => {
    const { name, value } = event.target;
    setFieldValue([name], value);
  };

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <form onSubmit={handleSubmit} className="mt-4" noValidate>
          <ModalConfirmation
            modalTitle="Crear"
            modal={modal}
            setModal={setModal}
            crear={saveConfigurate}
            isEdit={false}
            values={values}
          >
            <div className="d-flex justify-content-center">
              <h6>¿Desea continuar con el registro?</h6>
            </div>
          </ModalConfirmation>

          <Row className="mt-2">
            <Col xs={12} md={12} lg={4}>
              {/* <TextInput
                label="Dias Naturales"
                inputType="text"
                inputName="naturalDays"
                onChangeMethod={onChangeNumbers}
                onBlurMethod={handleBlur}
                value={values.naturalDays}
                isRequired={true}
                touched={touched.naturalDays}
                errors={errors.naturalDays}
                isMax={values.averageDays}
              /> */}

              <NumberInput
                label="Dias Naturales"
                inputName="naturalDays"
                onChangeMethod={onChange}
                onBlurMethod={handleBlur}
                value={values.naturalDays}
                touched={touched.naturalDays}
                errors={errors.naturalDays}
                isMax={+values.averageDays}
                isRequired
                maxLength={5}
                disableSep
              />
            </Col>

            <Col xs={12} md={12} lg={4}>
              {/* <TextInput
                label="Dias Promedio"
                inputType="text"
                inputName="averageDays"
                onChangeMethod={onChangeNumbers}
                onBlurMethod={handleBlur}
                value={values.averageDays}
                isRequired={true}
                touched={touched.averageDays}
                errors={errors.averageDays}
                isMax={30}
              /> */}
              <NumberInput
                label="Dias Promedio"
                inputName="averageDays"
                onChangeMethod={onChange}
                onBlurMethod={handleBlur}
                value={values.averageDays}
                touched={touched.averageDays}
                errors={errors.averageDays}
                isRequired
                maxLength={5}
                disableSep
              />
            </Col>

            <Col xs={12} md={12} lg={4}>
              <CurrencyInput
                label="UMA"
                inputName="uma"
                onChangeMethod={(value, name, e) =>
                  onChange({ target: { name: name, value: value } })
                }
                onBlurMethod={handleBlur}
                value={values.uma}
                isRequired={true}
                touched={touched.uma}
                errors={errors.uma}
                tooltip={true}
                textTooltip="Unidad de Medida y Actualización"
                isMax={10}
              />
            </Col>

            <Col xs={12} md={12} lg={4}>
              <CurrencyInput
                label="Salario Minimo"
                inputName="smi"
                onChangeMethod={(value, name, e) =>
                  onChange({ target: { name: name, value: value } })
                }
                onBlurMethod={handleBlur}
                value={values.smi}
                isRequired={true}
                touched={touched.smi}
                errors={errors.smi}
                isMax={10}
              />
            </Col>

            <Col xs={12} md={12} lg={4}>
              <CurrencyInput
                label="Monto Maximo SS"
                inputName="maxSS"
                onChangeMethod={(value, name, e) =>
                  onChange({ target: { name: name, value: value } })
                }
                onBlurMethod={handleBlur}
                value={values.maxSS}
                isRequired={true}
                touched={touched.maxSS}
                errors={errors.maxSS}
                tooltip={true}
                textTooltip="Monto Maximo Seguro Social"
                isMax={10}
              />
            </Col>

            <Col xs={12} md={12} lg={4}>
              <CurrencyInput
                label="Monto Minimo SS"
                inputName="minSS"
                onChangeMethod={(value, name, e) =>
                  onChange({ target: { name: name, value: value } })
                }
                onBlurMethod={handleBlur}
                value={values.minSS}
                isRequired={true}
                touched={touched.minSS}
                errors={errors.minSS}
                tooltip={true}
                textTooltip="Monto Minimo Seguro Social"
                isMax={10}
              />
            </Col>

            <Col xs={12} md={12} lg={4}>
              <TextInput
                label="CSS"
                inputType="text"
                inputName="quotaSS"
                onChangeMethod={onChangeNumbers}
                onBlurMethod={handleBlur}
                value={values.quotaSS}
                isRequired={true}
                touched={touched.quotaSS}
                errors={errors.quotaSS}
                tooltip={true}
                textTooltip="Cuota Servicio de Salud"
                isMax={10}
              />
            </Col>

            <Col xs={12} md={12} lg={4}>
              <TextInput
                label="CSSR"
                inputType="text"
                inputName="quotaSSR"
                onChangeMethod={onChangeNumbers}
                onBlurMethod={handleBlur}
                value={values.quotaSSR}
                isRequired={true}
                touched={touched.quotaSSR}
                errors={errors.quotaSSR}
                tooltip={true}
                textTooltip="Cuota del Sistema Solidario de Raprto"
                isMax={10}
              />
            </Col>

            <Col xs={12} md={12} lg={4}>
              <TextInput
                label="COSCI"
                inputType="text"
                inputName="quotaOSCI"
                onChangeMethod={onChangeNumbers}
                onBlurMethod={handleBlur}
                value={values.quotaOSCI}
                isRequired={true}
                touched={touched.quotaOSCI}
                errors={errors.quotaOSCI}
                tooltip={true}
                textTooltip="Cuotas Obligatorias del Sistema de Capitalización Individual"
                isMax={10}
              />
            </Col>

            <Col xs={12} md={12} lg={4}>
              <TextInput
                label="AOSCI"
                inputType="text"
                inputName="supportOSCI"
                onChangeMethod={onChangeNumbers}
                onBlurMethod={handleBlur}
                value={values.supportOSCI}
                isRequired={true}
                touched={touched.supportOSCI}
                errors={errors.supportOSCI}
                tooltip={true}
                textTooltip="Aportaciones Obligatorias del Sistema de Capitalización Individual"
                isMax={10}
              />
            </Col>

            <Col xs={12} md={12} lg={4}>
              <TextInput
                label="SS"
                inputType="text"
                inputName="supportSS"
                onChangeMethod={onChangeNumbers}
                onBlurMethod={handleBlur}
                value={values.supportSS}
                isRequired={true}
                touched={touched.supportSS}
                errors={errors.supportSS}
                tooltip={true}
                textTooltip="Servicio de Salud"
                isMax={10}
              />
            </Col>

            <Col xs={12} md={12} lg={4}>
              <TextInput
                label="FSSR"
                inputType="text"
                inputName="supportFSSR"
                onChangeMethod={onChangeNumbers}
                onBlurMethod={handleBlur}
                value={values.supportFSSR}
                isRequired={true}
                touched={touched.supportFSSR}
                errors={errors.supportFSSR}
                tooltip={true}
                textTooltip="Fondo del Sistema Solidario de Reparto"
                isMax={10}
              />
            </Col>

            <Col xs={12} md={12} lg={4}>
              <TextInput
                label="Gastos de Administración"
                inputType="text"
                inputName="supportGA"
                onChangeMethod={onChangeNumbers}
                onBlurMethod={handleBlur}
                value={values.supportGA}
                isRequired={true}
                touched={touched.supportGA}
                errors={errors.supportGA}
                isMax={10}
              />
            </Col>

            <Col xs={12} md={12} lg={4}>
              <TextInput
                label="Prima Basica"
                inputType="text"
                inputName="supportPB"
                onChangeMethod={onChangeNumbers}
                onBlurMethod={handleBlur}
                value={values.supportPB}
                isRequired={true}
                touched={touched.supportPB}
                errors={errors.supportPB}
                isMax={10}
              />
            </Col>

            <Col xs={12} md={12} lg={4}>
              <TextInput
                label="Prima de Siniestralidad"
                inputType="text"
                inputName="supportPS"
                onChangeMethod={onChangeNumbers}
                onBlurMethod={handleBlur}
                value={values.supportPS}
                isRequired={true}
                touched={touched.supportPS}
                errors={errors.supportPS}
                isMax={10}
              />
            </Col>
          </Row>

          <div className="row mt-3">
            <div className="col-sm-6 order-2 order-sm-1 text-center text-sm-left mb-4">
              <Button
                color="danger"
                type="reset"
                onClick={() => goBack(resetForm)}
              >
                {" "}
                Cancelar{" "}
              </Button>
            </div>

            <div className="col-sm-6 order-1 order-sm-2 text-center text-sm-right mb-4">
              <Button color="success" type="submit">
                {" "}
                Guardar{" "}
              </Button>
            </div>
          </div>
        </form>
      )}
    </>
  );
};

export default FormConfiguracionCalculo;

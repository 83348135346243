import * as XlsxPopulate from "xlsx-populate";
import { saveAs } from "file-saver";

const totals = [0]

const formatter = new Intl.NumberFormat("es-MX", {
  style: "currency",
  currency: "MXN",
});

export async function saveAsExcelTotal(report, headers, title, filter) {
  console.log("Entrando a Excel total");
  XlsxPopulate.fromBlankAsync().then(async (workbook) => {
    const sheet1 = workbook.sheet(0);
    const sheetData = getSheetData(report, headers, filter);
    
    sheet1
      .row(1)
      .cell(Math.ceil(headers.length / 2))
      .value("SAPP")
      .style("bold", true);
    sheet1
      .row(2)
      .cell(Math.ceil(headers.length / 2))
      .value(title)
      .style("bold", true);
    if (filter.filters.message && filter.filters.message !== "") {
      sheet1
        .row(3)
        .cell(Math.ceil(headers.length / 2))
        .value(filter.filters.message)
        .style("bold", true);
    }
    if (filter.filters.message2 && filter.filters.message2 !== "") {
      sheet1
        .row(4)
        .cell(Math.ceil(headers.length / 2))
        .value(filter.filters.message2)
        .style("bold", true);
    }
    for (let i = 1; i <= headers.length; i++) {
      sheet1.row(6).cell(i).style("bold", true);
      /* sheet1.row(6).cell(i).style("horizontalAlignment","center") */
      sheet1.column(i).width(30);
    }
    /* sheet1.range("A5:" + endColumn + "1").style("fill", "BFBFBF");  */
    sheet1.row(6).cell(1).value(sheetData).style("border", true);
    sheet1.range(`A${6+sheetData.length}:H${6+sheetData.length}`).merged(true).style("border", true)
    sheet1.row(6+sheetData.length).cell(1).value("Total").style({
      bold: "true",
      border: true,
  },);
    sheet1.row(6+sheetData.length).cell(9).value(formatter.format(totals[0])).style({
      bold: "true",
      border: true,
  },);
    

    /* sheet1.usedRange() */
    sheet1.usedRange().style("horizontalAlignment", "center");

    return workbook.outputAsync().then((res) => {
      saveAs(res, `${title}.xlsx`);
    });
  });
}
function getSheetData(data, header, filter) {
  let keys = [];
  header.forEach((h)=>{
    filter.content.every((arr)=>{
      let flg = true;
      arr.every((ob)=>{
        if(ob.header === h){
          keys.push([ob.value, ob.format]);
          flg = false;
          return false;
        }
        return true;
      });
      return flg;
    });
  });
  keys.unshift([filter.id.id, undefined]);
  //totales
  for(let i=0;  i < filter.totals; i++){
    totals.push(0)
  }

  let pageData = data.map((d)=>{
    let reg = keys.map((k)=> {
      if(k[0] === "amount"){
        totals[0] += +d[k[0]]
      }
      let tx = d[k[0]];
      switch (k[1]){
        case "moneda":
          tx = formatter.format(tx);
        break;
      }
      return tx;
    })
    return reg;



  });

  pageData.unshift(header);
  return pageData;
}

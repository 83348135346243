import { useEffect, useState } from "react"
import { Button, Collapse } from "reactstrap";
import Datatable from "../../../../../components/Datatable/Datatable";
import { fPercent } from "../../../../../libs/tools/format";
import { doRequestSaveRes } from "../../../../../helpers/requests";
import SalariosDetalle from "./SalariosDetalle";

const headers = [
    "INCREMENTO SALARIAL",
    "AÑO APLICACIÓN",
    "FECHA APLICACIÓN",
    "PORCENTAJE BASE ",
    "PORCENTAJE RECOMPENSA",
    "PORCENTAJE DESPENSA",
    "PORCENTAJE LIQUIDACIÓN",
    "PORCENTAJE FORTALECIMIENTO",
    "AFECTADOS",
    "DETALLE"
]

const columnDefs = [
  { orderable: false, targets: [10] },
  { className: "text-center", targets: [0, 1, 2, 3, 4, 5, 6, 7, 9] },
  { className: "text-right", targets: [8] },

];

export default function TabuladoresHistorico(){
    const [report, setReport] = useState(1);
    const [refresh, setRefresh] = useState(false);
    const [selected, setSelected] = useState(null);
    
    useEffect(()=>{
        if(selected) setReport(2);
        else  setReport(1);
    }, [selected]);

    async function getData(ordCol, itemsPerPage, currentPage, search) {
      const res = await doRequestSaveRes(
        "app/facades/reports/reportsF.php",
        getParams(ordCol, itemsPerPage, currentPage, search),
        false
      );
      if (res.code === "200") return res;
      else return null;
    }
  
    function getParams(ordCol, itemsPerPage, currentPage, search) {
      const params = {
        action: "getHistoricoSalarios",
        order: ordCol,
        records: itemsPerPage,
        page: itemsPerPage * currentPage,
        search,
      };
      return params;
    }

    async function openDetail(row){
        setSelected(row);
    }

    const columns = [
        { data: "idSalaryIncrease" },
        { data: "anioAplicacion" },
        { data: "fechaApliacion", format: "date" },
        { data: "porcetageBase", format: fPercent },
        { data: "porcentageReward", format: fPercent },
        { data: "porcentageLarder", format: fPercent },
        { data: "porcentageClearance", format: fPercent },
        { data: "porcentageFortalecimiento", format: fPercent },
        { data: "cantAfectados" },
        { 
            data: null,
            render: (row) => (
                <i className="fa fa-eye text-info cursor-pointer" onClick={()=>openDetail(row)} />
            )
        },
    ]

    return(
        <div>
            <div>
                {
                    report === 1 ?
                    <h4>Tabuladores Histórico</h4> :
                    <div className="d-flex flex-md-row flex-column justify-content-md-between ">
                        <div>
                            <h4>Tabuladores Histórico - Detalle Salarios</h4>
                        </div>
                        <div>
                            <Button color="danger" onClick={()=>setSelected(null)}>Cerrar</Button>
                        </div>
                    </div>
                }
            </div>
            <Collapse isOpen={report === 1}>
                <div>
                    <Datatable
                        headers={headers}
                        columns={columns}
                        columnDefs={columnDefs}
                        petition={getData}
                        control="back"
                        stateRefresh={[refresh, setRefresh]}
                        className="px-0"
                    />
                </div>
            </Collapse>
            <Collapse isOpen={report === 2}>
                <div>
                    <SalariosDetalle selected={selected} />
                </div>
            </Collapse>
        </div>
    )
}
import React, { useState, useEffect } from "react";
/* import ModalComponentEliminar from "../../components/Modal/ModalDelete"; */
import Loader from "../../components/Loader/Loader";
import axios from "axios";
import {
  Col,
  Row,
  Table,
  Input,
  Pagination,
  PaginationItem,
  PaginationLink,
  Button,
  limpia,
  reset,
} from "reactstrap";
import s from "../../pages/tables/Tables.module.scss";
import { useHistory } from "react-router-dom";
import sc from "./TableComponentFiltro.module.scss";
import { toast } from "react-toastify";
import Notification from "../../components/Notification/Notification";
import { peticionEncript } from "../../helpers/peticionesEncripted";

export const TableComponentOnePage = (props) => {
  const {
    titulo,
    cabecerasTabla,
    filtro,
    editar,
    parametros, //sobre que tabla hacer el select
    limpia,
    reset,
    beforeDelete,
  } = props;
  const colsBdNames = parametros.rows.split(","); // Columnas del back
  const nameTable = parametros.table.split(" ");
  const nameColumn = colsBdNames[0].split(".");
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [tableContent, setTableContent] = useState([]); // Información mostrada en la tabla
  const [pageSize, setPageSize] = useState(5); // Tamaño de las paginas
  const [totalRegistros, setTotalRegistros] = useState(0); // Total de registros en BD
  const [pagesCount, setPagesCount] = useState(0); // Cantidad total de paginas
  const [seccionActual, setSeccionActual] = useState(0); // Seccion actual
  const [sortCol, setSortCol] = useState({
    //Parametros para aplicar filtros a la busqueda
    direction: "",
    column: "",
    page: "",
    currentPage: 0,
    search: "",
    records: 5,
  });
  const settableContentPage = (e, index) => {
    if (index + 1 > (seccionActual + 1) * 5) {
      setSeccionActual(seccionActual + 1);
    } else if (seccionActual != 0 && index + 1 <= seccionActual * 5) {
      setSeccionActual(seccionActual - 1);
    }
    e.preventDefault();
    setSortCol((prevState) => ({
      ...prevState,
      page: pageSize * index,
      currentPage: index,
    }));
  };

  const mapea = (element, indexCol, indexRow) => {
    if (indexCol === 0) {
      return (
        <td className="text-center"  key={"C"+indexCol}>
          {5 * sortCol.currentPage + (indexRow + 1)}
        </td>
      );
    }
    if (filtro[indexCol]) {
      const reg = /\d{4}-\d{2}-\d{2}/g;
      let texto = element;
      if (reg.test(element)) {
        texto = element.split("-").reverse().toString().replaceAll(",", "/");
      }
      return <td className="text-center" key={"C"+indexCol}>{texto}</td>;
    } else {
      return null;
    }
  };

  const sortTable = (cabecera, indexHeader) => {
    if (!["Editar","Eliminar","Id","Ver","Seleccionar","Modificar"].includes(cabecera)) {
      if (sortCol.column !== indexHeader) {
        setSortCol((ps) => ({ ...ps, column: indexHeader, direction: "asc" }));
      } else {
        setSortCol((ps) => ({
          ...ps,
          direction:
            sortCol.direction === "asc"
              ? "desc"
              : sortCol.direction === "desc"
              ? ""
              : "asc",
        }));
      }
    }
  };

  const getData = async (orderOb) => {
    //LA PRIMERA VEZ QUE ENTRAMOS VIENE UN ORDEROB = EMPTY Y EN EL PARAMS = ORDER = "", PAGE = 0 Y RECORDS = 5
    //CUANDO SELECCIONO EL HEADER VIENE ORDEROB = COLUMN = 1 Y DIRECTION = ASC Y EN EL PARAMS = ORDER = "ASC", PAGE = 0 Y RECORDS = 5
    //DESPUES DE ESO SELECCIONO EL PAGINADO Y MUESTRA ORDEROB = COLUMN = "", DIRECTION = "" Y PAGE = 5 Y EN EL PARAMS = ORDER = "", PAGE = 5 Y RECORDS = 5
    //SOLO HAY QUE VERIFICAR EL ESTADO DEL HOOK Y QUE NO CAMBIE LOS PARAMS
    var params = ''
    if (parametros.orderBy !== undefined && parametros.orderBy !== null) {
      params = {
          action : parametros.action,
          table: parametros.table,
          rows: parametros.rows,
          conditions: parametros.conditions,
          page: parametros.page,
          records: parametros.records,
          search: parametros.search,
          order: parametros.orderBy,
      }
    } else {
      params = parametros;
    }
    if (sortCol.column !== "" && sortCol.direction !== "") {
      //AQUI QUIERE DECIR QUE TRAE UN ORDENAMIENTO POR HEADER
      params.order = orderOb.column + " " + orderOb.direction;
    } else {
      params.order = parametros.order;
    }
    if (sortCol.page !== "") {
      //QUIERE DECIR QUE CAMBIAMOS DE PAGINA
      params.page = sortCol.page;
    } else {
      params.page = 0;
    }
    if (sortCol.search !== "") {
      params.search = sortCol.search;
    } else {
      params.search = "";
    }
    if (orderOb) {
    } else {
      if (sortCol.column !== "" && sortCol.direction !== "") {
        //AQUI TRAE UN ORDENAMIENTO POR HEADER
        params.order = orderOb.column + " " + orderOb.direction;
      } else {
        params.order = parametros.order;
      }
      if (sortCol.page !== "") {
        //CAMBIAMOS DE PAGINA
        params.page = sortCol.page;
      } else {
        params.page = 0;
      }
      if (sortCol.search !== "") {
        params.search = sortCol.search;
      } else {
        params.search = "";
      }
    }
    params.records = sortCol.records;
    setLoading(true); 
    const finalData = peticionEncript(params)
    await axios
      .post(`${process.env.REACT_APP_API}receiver/receiver.php`, finalData)
      .then((res) => {
        let data = [];
        if (res.status === 200 && res.data.code === "200") {
          setTotalRegistros(res.data.totalRecords);
          setPagesCount(Math.ceil(res.data.totalRecords / pageSize));
          res.data.data.map((el) => {
            data.push(Object.values(el));
          });
        }
        setTableContent(data);
        setLoading(false);
      })
      .catch((err) => {
        toast(<Notification type={"consultar_servidor_error"} withIcon />);
        setLoading(false);
      });
  };

  useEffect(() => {
    getData(sortCol);
  }, [sortCol,parametros]);

  const handleOnChange = (e) => {
    setSeccionActual(0);
    setSortCol((prevState) => ({
      ...prevState,
      search: e.target.value,
      currentPage: 0,
      page: "",
    }));
  };

  const handleOnChangeSelect = (e) => {
    setSeccionActual(0);
    setPageSize(e.target.value);
    setSortCol((prevState) => ({
      ...prevState,
      records: e.target.value,
      currentPage: 0,
      page: "",
    }));
  };

  return (
    <div>
      <Row className="mb-4">
        <Col>
          {titulo && <div className={s.tableTitle}>
            <h1 className="text-secondary text-center">{titulo} </h1>
          </div>}
          <div className="d-flex justify-content-between">
            <Input
              value={sortCol.search}
              name="search"
              onChange={handleOnChange}
              className="col-12 col-md-6"
              placeholder="Buscar..."
            />
            {/* <Button
                color="success"
                className="btn-rounded with-icon"
                onClick={() => {
                  handleSubmit();
                }}
              >
                Agregar
              </Button> */}
          </div>
          <div className="widget-table-overflow mt-3">
            <Table
              className={`table-striped table-borderless table-hover ${s.statesTable}`}
              responsive
            >
              <thead className="border-bottom border-secondary">
                <tr className={sc.userSelectNone}>
                    {cabecerasTabla.map((cabecera, indexHeaders) => (
                      <th
                        key={"th"+indexHeaders}
                        className="text-center"
                        onClick={() => sortTable(cabecera, indexHeaders + 1)}
                      >
                        {cabecera === "Id" ? "#" : cabecera}
                        <i
                          className={
                            "align-top eva" +
                            (sortCol.column === indexHeaders + 1
                              ? sortCol.direction === "asc"
                                ? " eva-arrow-ios-upward-outline"
                                : sortCol.direction === "desc"
                                ? " eva-arrow-ios-downward-outline"
                                : ""
                              : "")
                          }
                        ></i>
                      </th>
                    ))}
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <tr key={"loading"}>
                    <td colSpan={cabecerasTabla.length}>
                      <Loader />
                    </td>
                  </tr>
                ) : tableContent.length === 0 ? (
                  <tr key={"nothing"}>
                    <td colSpan={cabecerasTabla.length} className="text-center">
                      No se encontraron registros
                    </td>
                  </tr>
                ) : (
                  tableContent.map((registro, index) => (
                    <tr key={"R"+index}>
                      {registro.map((elemento, indexCol) => {
                          if (filtro !== null) {
                            return mapea(elemento, indexCol, index);
                        } else {
                          return <td className="text-center" key={"C"+indexCol}>{elemento}</td>;
                        }
                      })}
                    </tr>
                  ))
                )}
              </tbody>
            </Table>
            <div className="row">
              <div className="col-12 col-sm-6 d-flex justify-content-around mt-3">
                <div>
                  Mostrando{" "}
                  <span className="text-primary">
                    {sortCol.currentPage * pageSize +
                      (tableContent.length === 0 ? 0 : 1)}
                  </span>{" "}
                  a{" "}
                  <span className="text-primary">
                    {pageSize * (sortCol.currentPage + 1) <= totalRegistros
                      ? pageSize * (sortCol.currentPage + 1)
                      : totalRegistros}{" "}
                  </span>
                  de {totalRegistros} registros
                </div>
                <select
                  className="form-control form-control-sm"
                  style={{ width: "78px" }}
                  value={sortCol.records}
                  onChange={handleOnChangeSelect}
                >
                  <option value={5} key={5}>
                    5
                  </option>
                  <option value={10} key={10}>
                    10
                  </option>
                  <option value={15} key={15}>
                    15
                  </option>
                  <option value={20} key={20}>
                    20
                  </option>
                  <option value={100} key={100}>
                    100
                  </option>
                </select>
              </div>
              <div className="col-12 col-sm-6 mt-3 d-flex justify-content-center justify-content-sm-end">
                <Pagination
                  className="pagination-borderless d-flex"
                  aria-label="Page navigation example"
                >
                  <PaginationItem disabled={sortCol.currentPage <= 0}>
                    <PaginationLink
                      onClick={(e) =>
                        settableContentPage(e, sortCol.currentPage - 1)
                      }
                      previous
                      href="#top"
                    />
                  </PaginationItem>
                  {[...Array(5)].map(
                    (page, i) =>
                      5 * seccionActual + i < pagesCount && (
                        <PaginationItem
                          key={"pg"+i}
                          disabled={
                            5 * seccionActual + i === sortCol.currentPage
                          }
                        >
                          <PaginationLink
                            onClick={(e) =>
                              settableContentPage(e, 5 * seccionActual + i)
                            }
                            href={5 * seccionActual + (i + 1)}
                            className={
                              5 * seccionActual + i === sortCol.currentPage
                                ? "text-primary text-bold"
                                : "text-secondary"
                            }
                          >
                            {5 * seccionActual + (i + 1)}
                          </PaginationLink>
                        </PaginationItem>
                      )
                  )}
                  <PaginationItem
                    disabled={sortCol.currentPage >= pagesCount - 1}
                  >
                    <PaginationLink
                      onClick={(e) =>
                        settableContentPage(e, sortCol.currentPage + 1)
                      }
                      next
                      href="#top"
                    />
                  </PaginationItem>
                </Pagination>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};
export default TableComponentOnePage;

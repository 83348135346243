import React from "react";

import Widget from "../../../../components/Widget/Widget";
import FormEdificio from "../FormEdificio";
import { createBilding } from "../functions";

export const CrearEdificio = (props) => {
  return (
    <Widget className="widget-p-md">
        <h3>Crear edificio</h3>
        <FormEdificio crear={createBilding}/>
    </Widget>
  )
}

export default CrearEdificio;
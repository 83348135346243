import { useEffect, useRef, useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import ModalConfirmation from "../../../components/Modal/ModalConfirmation";
import { Button, Col, Collapse, Row } from "reactstrap";
import {
  FilesInput,
  SelectTypeHeadSingle,
  TextInput,
} from "../../../components/GenericInputsFormik";

import { NumberInput } from "../../../components/GenericInputsFormik/NumberInput";
import FullFormLoader from "../../../components/Loader/FullFormLoader";
import TableComponentFiles from "../../../components/TableComponent/TableComponentFiles";
import { doRequest, uploadFile } from "../../../helpers/requests";

const defaultForm = {
  keyFileType: "",
  file: "",
};

const FormSchema = Yup.object().shape({
  keyFileType: Yup.string().required("Seleccione un tipo de documento"),
  file: Yup.mixed()
    .required("Agregue un documento")
    .test("file-type", "El documento debe ser en formato PDF", (value) => {
      return value && value.type === "application/pdf";
    })
    .test({
      message: `El documento debe pesar menos de 4MB`,
      test: (value) => value?.size < 4000000,
    }),
});

const Documentos = ({ idProvider, files, permissions }) => {
  const [modalConfirm, setModalConfirm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [collapse, setCollapse] = useState(false);
  const [clearFiles, setClearFiles] = useState([]);
  const [parametros, setParametros] = useState({
    action: "getFilesbyModule",
    reference: idProvider,
    idModule: 147,
  });
  const refScroll = useRef(null);
  const refInpFile = useRef(null);

  useEffect(() => {
    filterFiles();
  }, []);

  const {
    handleSubmit,
    values,
    handleBlur,
    errors,
    touched,
    setFieldValue,
    handleReset,
  } = useFormik({
    initialValues: defaultForm,
    validationSchema: FormSchema,
    onSubmit: (values) => setModalConfirm(true),
    onReset: (v)=>{refInpFile.current.value = ""},
    enableReinitialize: true,
  });

  const onChange = (e) => {
    if (e.target.name === "file") {
      if (e.target.files.length === 0) setFieldValue(e.target.name, "");
      else setFieldValue(e.target.name, e.target.files[0]);
    } else {
      setFieldValue(e.target.name, e.target.value);
    }
  };

  const saveFile = async () => {
    setLoading(true);
    const res = await uploadFile(idProvider, "saveFile", values.keyFileType, values.file, true);
    if (res) {
      setParametros({ ...parametros });
      setCollapse(false);
      filterFiles();
    }
    setLoading(false);
  };

  const filterFiles = async () => {
    setLoading(true);
    const arch = await doRequest(
      "app/facades/files/fileReceiver.php",
      parametros,
      false,
      false
    );
    const used = arch.map((el) => {
      return +el.keyFileType;
    });
    const filtred = files.filter((el) => !used.includes(el.keyFileType));
    setClearFiles(filtred);
    setLoading(false);
  };

  const getFileTypeName = () => {
    const file = clearFiles.find((el) => el.keyFileType === values.keyFileType);
    if (file !== undefined) return file.nameFyleType;
    return "";
  };

  const scrollTo = () => {
    refScroll.current.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div className="mx-4">
      <TableComponentFiles
        params={parametros}
        updateData={filterFiles}
        permissions={permissions}
      />

      <Row className="mb-1">
        <Col xs={12} md={6} className="text-center text-md-left">
          {collapse && <h5>Agregar Archivo</h5>}
        </Col>
        {permissions.INS && <Col xs={12} md={6} className="text-center text-md-right">
          <Button color="add" onClick={() => setCollapse(true)} disabled={clearFiles.length === 0}>
            Agregar
          </Button>
        </Col>}
      </Row>

      <Collapse
        isOpen={collapse}
        onExited={() => handleReset()}
        onEntered={() => scrollTo()}
      >
        <form onSubmit={handleSubmit} onReset={handleReset} className="mt-2">
          <ModalConfirmation
            modalTitle="Guardar Documneto"
            modal={modalConfirm}
            setModal={setModalConfirm}
            editar={() => saveFile()}
            isEdit={true}
          >
            <div className="d-flex justify-content-center text-center">
              <p style={{ fontSize: "20px" }} className="mb-2">
                ¿Esta seguro de guardar el documento <br />
                <span className="font-weight-bold">{getFileTypeName()}</span>?
              </p>
            </div>
          </ModalConfirmation>
          <Row>
            <Col xs={12} lg={6}>
              <SelectTypeHeadSingle
                label="Tipo de Documento"
                isRequired={true}
                inputName="keyFileType"
                optionsArray={clearFiles}
                defaultOption="Seleccione un tipo de archivo"
                onChangeMethod={onChange}
                onBlurMethod={handleBlur}
                value={values.keyFileType}
                touched={touched.keyFileType}
                errors={errors.keyFileType}
                optionValue="keyFileType"
                optionName="nameFyleType"
              />
            </Col>
            <Col xs={12} lg={6}>
              <FilesInput
                label="Documento"
                inputName="file"
                onChangeMethod={onChange}
                onBlurMethod={handleBlur}
                fileAccept="application/pdf"
                isRequired
                touched={touched.file}
                errors={errors.file}
                basicReq={false}
                refInput={refInpFile}
              />
            </Col>
          </Row>

          <Row className="mt-5">
            <div
              className="col-sm-6 order-2 order-sm-1 text-center text-sm-left mb-4"
              ref={refScroll}
            >
              <Button
                color="danger"
                type="reset"
                onClick={() => {
                  setCollapse(false);
                }}
              >
                Cancelar
              </Button>
            </div>
            <div className="col-sm-6 order-1 order-sm-2 text-center text-sm-right mb-4">
              <Button color="success" type="submit">
                Guardar
              </Button>
            </div>
          </Row>
        </form>
      </Collapse>
      <FullFormLoader show={loading} />
    </div>
  );
};

export default Documentos;

import React, { useState, useEffect } from "react";
import "react-toastify/dist/ReactToastify.css";
import TableComponent from "../../../components/TableComponent/TableComponentFiltro";

export const Discapacidades = () => {
  const [cabeceras] = useState(["Id", "Discapacidad", "Editar", "Eliminar"]);
  const [filtro] = useState([true, true]);
  const [parametros] = useState({
    action: "datatable",
    table: "disabilities",
    rows: "keyDisability,nameDisability",
    conditions: "enabled = 1",
    page:0,
    records:5,
    search:"",
    order:""
  });
  useEffect(() => {
  }, []);

  return (
    <div>
        <TableComponent
          titulo="Discapacidades"
          cabecerasTabla={cabeceras}
          filtro={filtro}
          rutaCrear="/template/discapacidades/crear"
          rutaEditar="/template/discapacidades/editar"
          /* agregar={agregar}
          editar={editar}
          eliminar={eliminar}| */
          parametros={parametros}
        ></TableComponent>
    </div>
  );
};
export default Discapacidades;


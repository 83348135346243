import { useEffect } from "react";
import { useLocation } from "react-router";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { toast } from "react-toastify";
import axios from "axios";

import { peticionesReceiver } from "../../../helpers/peticionesReceiver";
import { peticionEncript } from "../../../helpers/peticionesEncripted";

import Notification from "../../../components/Notification/Notification";
import getActions from "../../../helpers/getPermissionForms";
import getRouters from "../../../components/Layout/function";

export function GetPermisos(setPermissionsForm) {
    // PERMISOS

    const loc = useLocation();
    const user = useSelector((state) => state.userInfo);
    const { id, profileType } = useSelector((state) => state.userInfo);

    //PERMISOS
    const history = useHistory();
    const getActionsUser = async () => {
        const pathname = loc.pathname.split("/")[2];
        if (pathname !== "dashboard") {
            const route = await getRouters(user.permission, pathname);
            if (!route.permission) {
                history.push("/template");
            } else {
                getPermissionsUser();
            }
        }
    };

    const getPermissionsUser = async () => {
        const pathname = loc.pathname.split("/")[2];
        const result = await getActions(
            id,
            profileType.selectProfile.keyProfile,
            pathname
        );
        setPermissionsForm(result.actions);
    };

    useEffect(() => {
        getActionsUser();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
}

function getParamsInsertUpdate(rows, insert) {
    const parametros = {
        table: "properties"
    }
    const datos = {
        idProperty: rows.idProperty,
        keySubClass: rows.keySubClass,
        seller: rows.seller,
        keyTypeProperty: rows.keyTypeProperty,
        idBuilding: rows.idBuilding,
        cost: rows.cost,
        registerNumber: rows.registerNumber,
        surface: rows.surface,
        titleDescription: rows.titleDescription,
        levels: rows.levels ? rows.levels : 0,
        elevators: rows.elevators ? rows.elevators : 0,
        baths: rows.baths ? rows.baths : 0,
        atms: rows.atms ? rows.atms : 0,
        phone: rows.phone ? rows.phone : "",
        idStaffCharge: rows.idStaffCharge,
        email: rows.email ? rows.email : 0,
        notes: rows.notes
    }
    if (insert) {
        delete datos.idProperty
        parametros.action = "insert"
        parametros.rows = datos;
    } else {
        parametros.action = "update"
        parametros.condition = { idProperty: datos.idProperty };
        parametros.rows = datos;
    }
    return parametros;
}


export async function OnCreate(rows, history) {
    const API = peticionesReceiver();
    const params = getParamsInsertUpdate(rows, true);
    try {
        const res = await API.peticion(params)
        if (res.status === 200 && res.data.code === "200") {
            const data = res.data.data[0];
            localStorage.setItem("propertyData", JSON.stringify(data));
            history.push("/template/bienesinmuebles/editar")
            toast(<Notification type={"agrega_exito"} backMessage={res.data.message} withIcon />);
        } else {
            toast(<Notification type={"agrega_error"} backMessage={res.data.message} withIcon />);
        }
    } catch (err) {
        toast(<Notification type={"agrega_error"} withIcon />);
    }
}

async function subirArchivo(formData, onRefresh) {
    const params = {
        method: "post",
        url: `${process.env.REACT_APP_API}app/facades/files/fileReceiver.php`,
        data: formData,
    };
    try {
        await axios(params)
        onRefresh()
        toast(<Notification type={"agrega_exito"} withIcon />);
    } catch (err) {
        toast(<Notification type={"agrega_error"} withIcon />);
    }
}

export async function DeleteArchivo(id, onRefresh) {
    const parametros = {
        action: "deleteFile",
        idFile: id
    };
    const finalData = peticionEncript(parametros)
    try {
        const res = await axios.post(`${process.env.REACT_APP_API}app/facades/files/fileReceiver.php`, finalData)
        if (res.data.code === "200" && res.data.status === "success") {
            toast(<Notification type={"elimina_exito"} withIcon />);
            onRefresh();
        } else {
            toast(<Notification type={"elimina_error"} withIcon />);
        }
    } catch (err) {
        toast(<Notification type={"consultar_error"} withIcon />);
    }
}

export async function AddImage(rows, onRefresh) {
    const formData = new FormData();
    formData.append("action", "saveFile");
    formData.append("keyFileType", 25);
    formData.append('binary', rows.file);
    formData.append('reference', rows.reference);
    formData.append('nameFile', rows.nameFile);
    await subirArchivo(formData, onRefresh)
}

export async function AddFile(rows, onRefresh, method = "saveFile") {
    const formData = new FormData();
    formData.append("action", method);
    formData.append("keyFileType", rows.keyFileType);
    formData.append('binary', rows.file);
    formData.append('reference', rows.reference);
    formData.append('nameFile', rows.nameFile);
    await subirArchivo(formData, onRefresh)
}

export async function OnEdit(rows) {
    const API = peticionesReceiver();
    const params = getParamsInsertUpdate(rows, false);
    try {
        const res = await API.peticion(params)
        if (res.status === 200 && res.data.code === "200") {
            const data = res.data.data[0];
            localStorage.setItem("propertyData", JSON.stringify(data));
            toast(<Notification type={"modifica_exito"} backMessage={res.data.message} withIcon />);
        } else {
            toast(<Notification type={"modifica_error"} backMessage={res.data.message} withIcon />);
        }
    } catch (err) {
        toast(<Notification type={"modifica_error"} withIcon />);
    }
}

export async function getDataReport(data) {
    const API = peticionesReceiver();
    const params = {
        action: "multiselect",
        table: "property_groups pg INNER JOIN property_subgroups ps ON (pg.keyGroup=ps.keyGroup) INNER JOIN property_classes pc ON (pc.keySubGroup=ps.keySubGroup) INNER JOIN property_subclasses psc ON (psc.keyClass=pc.keyClass) INNER JOIN properties prop ON (prop.keySubClass = psc.keySubClasses) INNER JOIN buildings build ON (build.idBuilding = prop.idBuilding) LEFT JOIN staff stf ON (stf.idStaff=prop.idStaffCharge) INNER JOIN types_property tprop ON (tprop.keyTypeProperty = prop.keyTypeProperty) RIGHT JOIN bookcase BC ON (BC.reference = prop.idProperty) LEFT JOIN files G ON (BC.idBook = G.idBook AND G.enabled=1) INNER JOIN filetypes FT ON (FT.keyFileType=BC.keyFileType AND FT.keyFileType = 25)",
        rows: "build.nameBuilding,build.streetName,build.subStreet,build.intNumber,build.extNumber,build.zipCode,pg.nameGroup,ps.nameSubGroup,pc.nameClass,psc.nameSubClass,tprop.nameTypeProperty,prop.seller,prop.registerNumber,prop.cost,prop.surface,prop.levels,prop.baths,prop.baths,prop.atms,prop.elevators,prop.phone,prop.email,CONCAT(stf.name, ' ', stf.mName, ' ', stf.pName) as name,G.src,BC.nameFile",
        conditions: `prop.idProperty = ${data.idProperty} AND prop.enabled = 1`,
    }
    const res = await API.peticion(params)
    if (res.status === 200 && res.data.code === "200") {
        const data = res.data.data;
        return data
    } else {
        toast(<Notification type={"consultar_error"} withIcon />);
        return null
    }
}
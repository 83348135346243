//  -- EXPEDIENTE PERSONAL -- //
import Personal from "../../../pages/personal/expediente-personal/Personal";

// -- CARRERAS -- //
import Carreras from "../../../pages/personal/carreras/Carreras";
import CrearCarreras from "../../../pages/personal/carreras/crear/CrearCarrera";
import EditarCarreras from "../../../pages/personal/carreras/editar/EditarCarrera";

// -- BANCOS -- //
import Bancos from "../../../pages/personal/bancos/Bancos";
import CrearBanco from "../../../pages/personal/bancos/crear/CrearBanco";
import EditarBanco from "../../../pages/personal/bancos/editar/EditarBanco";


// -- CONTRATO HONORARIOS -- //
import ContratoHonorarios from "../../../pages/personal/contrato-honorarios/Honorarios";

// -- DISCAPACIDADES -- //
import Discapacidades from "../../../pages/personal/discapacidad/Discapacidades";
import CrearDiscapacidad from "../../../pages/personal/discapacidad/crear/CrearDiscapacidad";
import EditarDiscapacidad from "../../../pages/personal/discapacidad/editar/EditarDiscapacidad";

// -- Reportes Personal -- //
import ReportesPersonal from "../../../pages/personal/reportes/ReportesPersonal";

// -- Estatus empleados  -- //
import GafetesBuscarEmpleado from "../../../pages/personal/gafetes/BuscarEmpleado";
import Gafetes from "../../../pages/personal/gafetes/Gafetes";
import CrearGafete from "../../../pages/personal/gafetes/CrearGafete";
import OficiosPrestadorServicios from "../../../pages/personal/prestador-servicios/ServSocial";

// Toma de asistencia
import TomaAsistencia from "../../../pages/personal/toma-asistencia";

// Catalogo de incidencias
import CatalogoIncidencias from "../../../pages/personal/control-personal/catalogo-incidencias";
import AgregarACatalogoIncidencias from "../../../pages/personal/control-personal/catalogo-incidencias/form/agregar";
import EditarCatalogoIncidencias from "../../../pages/personal/control-personal/catalogo-incidencias/form/editar";

//Registro de incidencias
import RegistroIncidencias from "../../../pages/personal/control-personal/registro-incidencias";
import AgregarRegistroIncidencias from "../../../pages/personal/control-personal/registro-incidencias/agregar";

// -- Autorizacion de Incidencia  -- //
import AutorizarIncidencia from "../../../pages/personal/control-personal/autorizacion-incidecias";
import EditarAutorizarIncidencia from "../../../pages/personal/control-personal/autorizacion-incidecias/Editar";

//Incidencias
import Incidencias from "../../../pages/personal/control-personal/incidencias";
import AgregarIncidencia from "../../../pages/personal/control-personal/incidencias/form/agregar";
import EditarIncidencia from "../../../pages/personal/control-personal/incidencias/form/editar";

import GruposIncidencias from "../../../pages/personal/control-personal/grupos-incidencias/GruposIncidencias";
import CrearGruposIncidencias from "../../../pages/personal/control-personal/grupos-incidencias/views/CrearGrupoIncidencia";

const rutasPersonal = [
    //Staff
    { path: "/template/personal", component: Personal },
    //Carreras
    { path: "/template/carreras", component: Carreras },
    { path: "/template/carreras/crear", component: CrearCarreras },
    { path: "/template/carreras/editar", component: EditarCarreras },
    //Bancos
    { path: "/template/bancos", component: Bancos },
    { path: "/template/bancos/crear", component: CrearBanco },
    { path: "/template/bancos/editar", component: EditarBanco },
    //Contratos de honorarios
    { path: "/template/contratohonorarios", component: ContratoHonorarios },
    //Oficios de servicio social
    { path: "/template/contratos", component: OficiosPrestadorServicios },
    //Discapacidades
    { path: "/template/discapacidades", component: Discapacidades },
    { path: "/template/discapacidades/crear", component: CrearDiscapacidad },
    { path: "/template/discapacidades/editar", component: EditarDiscapacidad },
    //Reportes Personal
    { path: "/template/reportes-personal", component: ReportesPersonal },
    //Gafetes
    { path: "/template/gafetes", component: GafetesBuscarEmpleado },
    { path: "/template/gafetes/historial", component: Gafetes },
    { path: "/template/gafetes/crear", component: CrearGafete },
    //Toma asistencia
    { path: "/template/toma-asistencia", component: TomaAsistencia },
    //Catalogo de incidencias
    { path: "/template/catalogo-incidencias", component: CatalogoIncidencias },
    { path: "/template/catalogo-incidencias/agregar", component: AgregarACatalogoIncidencias },
    { path: "/template/catalogo-incidencias/editar", component: EditarCatalogoIncidencias },
    //Registro de incidencias
    { path: "/template/registro-incidencias", component: RegistroIncidencias },
    { path: "/template/registro-incidencias/agregar", component: AgregarRegistroIncidencias },
    //Autorizacion de Incidencia
    { path: "/template/autorizar-incidencias", component: AutorizarIncidencia },
    { path: "/template/autorizar-incidencias/editar", component: EditarAutorizarIncidencia },
    //Incidencias
    { path: "/template/incidencias", component: Incidencias },
    { path: "/template/incidencias/agregar", component: AgregarIncidencia },
    { path: "/template/incidencias/editar", component: EditarIncidencia },
    { path: "/template/grupos-incidencias", component: GruposIncidencias },
    { path: "/template/grupos-incidencias/crear", component: CrearGruposIncidencias },

]

export default rutasPersonal;
// -- Proyectos presupuestales  -- //
import ProyectosPresupuestales from '../../../pages/presupuesto/proyectos-presupuestales/ProyectosPresupuestales'

// -- Objetos de gastos  -- //
import ObjetosGastos from '../../../pages/presupuesto/objetos-gasto/ObjetosGastos'
import CrearObjetoGasto from '../../../pages/presupuesto/objetos-gasto/crear/CrearObjetoGasto'
import EditarObjetoGasto from '../../../pages/presupuesto/objetos-gasto/editar/EditarObjetoGasto'

// -- FUENTES DE FINANCIAMIENTO  -- //
import FuentesFinanciamiento from '../../../pages/presupuesto/fuente-financiamiento/FuentesFinanciamiento'
import CrearFuentesF from '../../../pages/presupuesto/fuente-financiamiento/crear/CrearFuentesF'
import EditarFuentesF from '../../../pages/presupuesto/fuente-financiamiento/editar/EditarFuentesF'

// -- FUENTES DE FINANCIAMIENTO  -- //
import RubrosIngresos from '../../../pages/presupuesto/rubro-ingresos/RubrosIngresos'
import CrearRubros from '../../../pages/presupuesto/rubro-ingresos/crear/CrearRubros'
import EditarRubros from '../../../pages/presupuesto/rubro-ingresos/editar/EditarRubros'

// -- PRESUPUESTOS ANUALES -- //
import Presupuestos from '../../../pages/presupuesto/presupuestos-anuales/Presupuestos'
import PresupuestosAgregar from '../../../pages/presupuesto/presupuestos-anuales/PresupuestosAgregar'
import PresupuestosDependientes from '../../../pages/presupuesto/presupuestos-anuales/PresupuestosDependientes'
import PresupuestosDependientesDetalles from "../../../pages/presupuesto/presupuestos-anuales/PresupuestosDependientesDetalles";
import PresupuestosAgregarDependientes from "../../../pages/presupuesto/presupuestos-anuales/PresupuestosAgregarDependientes";
import PresupuestosImportacionCSV from "../../../pages/presupuesto/presupuestos-anuales/PresupuestosImportacionCSV";

// -- PRESUPUESTOS UNIDADES EJECUTORAS -- //
import PresupuestoUnidadesEjecutoras from "../../../pages/presupuesto/presupuesto-unidades-ejecutoras/Principal";
import AreaPresupuestoUnidadesEjecutoras from "../../../pages/presupuesto/presupuesto-unidades-ejecutoras/Areas/Areas";
import AgregarAreaPresupuesto from "../../../pages/presupuesto/presupuesto-unidades-ejecutoras/Areas/Agregar";
import EditarAreaPresupuesto from "../../../pages/presupuesto/presupuesto-unidades-ejecutoras/Areas/Editar";

/* -- Consulta de traspasos -- */
import ConsultaTrapasos from "../../../pages/presupuesto/consulta-traspasos/ConsultaTrapasos";
/* -- Presupuesto Autorizado -- */
import PresupuestoAutorizado from "../../../pages/presupuesto/presupuesto-autorizado/PresupuestoAutorizado";

/* -- Dictamen Presupuestal -- */
import DictamenPresupuestal from '../../../pages/presupuesto/dictamen-presupuestal'

/* -- Aprobacion Presupuestal -- */
import AprobacionPresupuestal from '../../../pages/presupuesto/aprobacion-presupuestal'

const rutasPresupuesto = [
    //Proyectos Presupuestales
    {path:"/template/proyectos-presupuestales", component:ProyectosPresupuestales},
    //Objetos de Gato
    {path:"/template/objetos-gasto", component:ObjetosGastos},
    {path:"/template/objetos-gasto/crear", component:CrearObjetoGasto},
    {path:"/template/objetos-gasto/editar", component:EditarObjetoGasto},
    //Fuentes de Financiamiento 
    {path:"/template/fuentes", component:FuentesFinanciamiento},
    {path:"/template/fuentes/crear", component:CrearFuentesF},
    {path:"/template/fuentes/editar", component:EditarFuentesF},
    //Rubros de Ingreso 
    {path:"/template/rubros", component:RubrosIngresos},
    {path:"/template/rubros/crear", component:CrearRubros},
    {path:"/template/rubros/editar", component:EditarRubros},
    //Presupuestos Anauales
    {path:"/template/presupuestos", component:Presupuestos},
    {path:"/template/presupuestos/agregar", component:PresupuestosAgregar},
    {path:"/template/presupuestos/presupuestos-dependientes", component:PresupuestosDependientes},
    {path:"/template/presupuestos/presupuestos-dependientes/detalles", component:PresupuestosDependientesDetalles},
    {path:"/template/presupuestos/presupuestos-dependientes/agregar", component:PresupuestosAgregarDependientes},
    {path:"/template/presupuestos/presupuestos-dependientes/csv", component:PresupuestosImportacionCSV},
    //Presupuesto Unidades Ejecutoras
    {path:"/template/presupuestounidades", component:PresupuestoUnidadesEjecutoras},
    {path:"/template/presupuestounidades/areas", component:AreaPresupuestoUnidadesEjecutoras},
    {path:"/template/presupuestounidades/areas/agregar", component:AgregarAreaPresupuesto},
    {path:"/template/presupuestounidades/areas/editar", component:EditarAreaPresupuesto},
    //PATRIMONIO
    {path:"/template/consulta-traspasos", component:ConsultaTrapasos},
    //PRESUPUESTO AUTORZADO
    {path:"/template/presupuesto-autorizado", component:PresupuestoAutorizado},
    //Dictamen Presupuestal
    {path:"/template/dictamen", component:DictamenPresupuestal},
    //Aprobacion Presupuestal
    {path:"/template/aprobacionp", component:AprobacionPresupuestal},
]

export default rutasPresupuesto;
import React,{useEffect, useState} from "react"
import { Container } from "reactstrap"
import InputEmployee from "../contrato-honorarios/Inputs/InputEmployee"
import TableComponent from "../../../components/TableComponent/TableComponentVer";
import Form from "./Form/Form";
import Widget from "../../../components/Widget/Widget";
import ModalReports from "../../../components/Modal/ModalReports";
import exportPDF, { cabecerasPDF, configPDF } from "./PDF/exportPDF";
import { peticionesReceiver } from "../../../helpers/peticionesReceiver";
import { toast } from "react-toastify";
import Notification from "../../../components/Notification/Notification";
import { GetPermissions } from "../../../libs/permissions/getPermissions";

export default function OficiosPrestadorServicios() {
  const [permissions, setPermissions] = useState({});
  GetPermissions(setPermissions);

  const API = peticionesReceiver();
  const [data, setData] = useState();
  const [title, setTitle] = useState("Alta oficio");
  const [isOpenForm, setIsOpenForm] = useState(false);
  const [employee, setEmployee] = useState(null);
  const [employeeRep, setEmployeeRep] = useState();
  const [report, setReport] = useState();
  const [modalReports, setModalReports] = useState(false);
  const [paramsModalTable] = useState({
    action: "datatable",
    table:
      "staff STF INNER JOIN employtypes AS EMT ON STF.keyEmployType = EMT.keyEmployType INNER JOIN jobstaff AS JBS ON JBS.idStaff = STF.idStaff INNER JOIN areas AS AR ON AR.idArea = JBS.idArea INNER JOIN jobtypes AS JBT ON JBT.keyJobType = STF.keyEmployType",
    rows: "STF.idStaff,STF.staffNumber,CONCAT(STF.name,' ',STF.pName,' ',STF.mName) as fullName,AR.name,JBT.nameJobType",
    conditions: `STF.enabled = 1 AND JBS.valid = 1 AND STF.keyStat = 1 AND JBS.enabled = 1 AND EMT.keyEmployType=3`,
    page: 0,
    records: 5,
    search: "",
    order: "",
  });

  const cabeceras = [
    "FOLIO",
    "ADSCRIPCION",
    "PUESTO",
    "MOVIMIENTO",
    "VIGENTE",
    "FECHA INICIO",
    "FECHA FIN",
    "OFICIO",
    "VER"
  ]

  const [parametros, setParametros] = useState({
    action: "datatable",
    table: "contractPractice CP INNER JOIN types_move TM on CP.keyMove=TM.keyMove INNER JOIN areas A on CP.idArea=A.idArea INNER JOIN jobs J on CP.keyJob=J.keyJob INNER JOIN staff SF ON CP.idStaff=SF.idStaff",
    rows: "CP.idcontractPractice,A.name,J.nameJob,TM.nameMove,CP.valid,CP.startDate,CP.endDate",
    conditions: `CP.enabled = 1`,
    page: 0,
    records: 5,
    search: "",
    order: "",
  });

  const onSelectEmployee = (value) => {
    setEmployee(value);
    const idEmploye = value[0];
    setParametros({
      action: "datatable",
      table: "contractPractice CP INNER JOIN types_move TM on CP.keyMove=TM.keyMove INNER JOIN areas A on CP.idArea=A.idArea INNER JOIN jobs J on CP.keyJob=J.keyJob INNER JOIN staff SF ON CP.idStaff=SF.idStaff",
      rows: "CP.idcontractPractice,A.name,J.nameJob,TM.nameMove,CP.valid,CP.startDate,CP.endDate",
      conditions: `CP.enabled = 1 AND SF.idStaff = ${idEmploye}`,
      page: 0,
      records: 5,
      search: "",
      order: "",
    })
  }

  const getForEdit = async (el) => {
    setTitle("Editar oficio")
    if(!employee) {
      const params = {
        action: "multiselect",
        table: 'contractPractice CP',
        conditions: `CP.idcontractPractice=${el[0]}`,
        rows: "CP.idStaff"
      };
      const res = await API.peticion(params)
      if (res.status === 200 && res.data.code === "200") {
        const data = res.data.data[0];
        setEmployeeRep(Object.values(data));
        setIsOpenForm(true);
        setData(el);
      }else {
        toast(<Notification type={"consultar_error"} withIcon />);
      }
    } else {
      setData(el);
    }    
  }

  const genReport = async (el) => {
    const params = {
      action: "getDatosServicioSocial",
      rows: {idContractPractice: el[0]}
    }
    const res = await API.peticionEndPoint(params, "app/facades/jobs/jobsF.php")
    if (res.status === 200) {
      const data = res.data.data[0];
      setReport(data)
      setModalReports(true)
    }
  }

  useEffect(() => {
    if (employee){
      setEmployeeRep(null)
    }
  }, [employee])

  useEffect(() => {
    if (employeeRep){
      setEmployee(null)
    }
  }, [employeeRep])

  return (
    <Widget className="widget-p-md">
      <InputEmployee
        employee={employee}
        setEmployee={setEmployee}
        paramsModalTable={paramsModalTable}
        selectEmployee={onSelectEmployee}
        setParametros={setParametros}
        setIsOpenForm={setIsOpenForm}
        setData={setData}
        title={"Seleccionar empleado"}
        parametros={{
          action: "datatable",
          table: "contractPractice CP INNER JOIN types_move TM on CP.keyMove=TM.keyMove INNER JOIN areas A on CP.idArea=A.idArea INNER JOIN jobs J on CP.keyJob=J.keyJob INNER JOIN staff SF ON CP.idStaff=SF.idStaff",
          rows: "CP.idcontractPractice,A.name,J.nameJob,TM.nameMove,CP.valid,CP.startDate,CP.endDate",
          conditions: `CP.enabled = 1`,
          page: 0,
          records: 5,
          search: "",
          order: "",
        }}
      />
      <Container>
        <TableComponent
          titulo="Historico de oficios"
          cabecerasTabla={cabeceras}
          filtro={null}
          parametros={parametros}
          ver={getForEdit}
          disabledDelete
          special="OFICIO"
          specialIco={"fa-file-pdf-o"}
          specialFun={(d) => genReport(d)}
        />
      </Container>
      <Container>
          <Form
            title={title}
            setTitle={setTitle}
            employee={employee ? employee : employeeRep}
            setEmployee={employee ? setEmployee : setEmployeeRep}
            noEmp={employee ? false : true}
            data={data}
            setData={setData}
            setParametros={setParametros}
            isOpen={isOpenForm}
            setIsOpen={setIsOpenForm}
            genReport={genReport}
            permissions={permissions}
          />
      </Container>
      {report !== undefined ? (
        <ModalReports
            modal={modalReports}
            setModal={setModalReports}
            title={"Oficio de prestación de servicios"}
            cabeceras={cabecerasPDF}
            backdrop={"static"}
            keyboard={false}
            report={report}
            pdfConfig={configPDF}
            generatePdfMethod={{
                method: exportPDF,
                type: "PDFPrestacionServicios",
            }}
            records={1000}
        />
      ): null}
    </Widget>
  )
}
import React from "react";
import { logoutUser } from "./actions/auth";
import { Redirect, Route } from "react-router";
import hasToken from "./services/authService";
import { useHistory } from "react-router-dom";
import Timer from "./helpers/Timer";

export const AdminRoute = ({ currentUser, dispatch, component, ...rest }) => {
  const history = useHistory();
  if (!currentUser || currentUser.role !== 'admin' || !hasToken()) {
    return (<Redirect to="/template" />)
  } else if (currentUser && currentUser.role === 'admin') {
    return (
      <Timer onFinished={() => { dispatch(logoutUser()); history.push("/login"); }}>
        <Route {...rest} render={props => (React.createElement(component, props))} />
      </Timer>
    );
  }
};

export const UserRoute = ({ dispatch, component, ...rest }) => {
  const history = useHistory();
  if (!hasToken()) {
    dispatch(logoutUser());
    return (<Redirect to="/login" />)
  } else {
    return (
      <Timer onFinished={() => { dispatch(logoutUser()); history.push("/login"); }}>
        <Route {...rest} render={props => (React.createElement(component, props))} />
      </Timer>
    );
  }
};

export const AuthRoute = ({ dispatch, component, ...rest }) => {
  const { from } = rest.location.state || { from: { pathname: '/template' } };
  if (hasToken()) {
    return (
      <Redirect to={from} />
    );
  } else {
    return (
      <Route {...rest} render={props => (React.createElement(component, props))} />
    )
  }
}

import React, { useEffect } from "react";
import { Row, Col } from "reactstrap";
import TableComponentVer from "./TableComponentFiltro";

import { peticionesReceiver } from "../../../../../helpers/peticionesReceiver";
import { toast } from "react-toastify";
import Notification from "../../../../../components/Notification/Notification";

function ModalTableChooseAreas(props) {
  const {
    cabeceras,
    filtro,
    parametros,
    hideTitleTable,
    staffList,
    setStaffList,
    setRefresh,
  } = props;

  const API = peticionesReceiver();

  const getStaffByArea = async (area) => {
    const params = {
      action: "multiselect",
      table:
        "staff STF INNER JOIN employtypes AS EMT ON STF.keyEmployType = EMT.keyEmployType INNER JOIN jobstaff AS JBS ON JBS.idStaff = STF.idStaff INNER JOIN areas AS AR ON AR.idArea = JBS.idArea INNER JOIN jobtypes AS JBT ON JBT.keyJobType = STF.keyEmployType",
      rows: "STF.idStaff,STF.staffNumber,CONCAT(STF.name,' ',STF.pName,' ',STF.mName) as fullName,AR.name,JBT.nameJobType",
      conditions: `STF.enabled = 1 AND JBS.valid = 1 AND STF.keyStat = 1 AND JBS.enabled = 1 and AR.idArea =${area[0]} `,
      search: "",
      order: "",
    };

    const res = await API.peticion(params);
    if (res.status === 200 && res.data.code === "200") {
      let tmpList = staffList.slice(
        0,
        staffList.length !== undefined ? staffList.length : 0
      );
      let cont = 0;
      res.data.data.forEach((staff) => {
        if (
          staffList.find((obj) => obj.idStaff === staff.idStaff) === undefined
        ) {
          tmpList.push({
            idStaff: staff.idStaff,
            staffNumber: staff.staffNumber,
            nameStaff: staff.fullName,
          });
        } else {
          cont += 1;
        }
      });
      if (cont > 0) {
        toast(
          <Notification
            type={"consultar_error"}
            backMessage={"Empleados duplicados omitidos"}
          />
        );
      }
      setStaffList(tmpList);
      setRefresh(true);
    } else {
      toast(
        <Notification type={"consultar_error"} backMessage={res.data.message} />
      );
    }
  };

  return (
    <>
      <Row className="d-flex justify-content-center mt-5">
        <Col /* xs={10} lg={10} */>
          <div>
            <TableComponentVer
              titulo={"Busqueda por area"}
              cabecerasTabla={cabeceras}
              filtro={filtro}
              ver={getStaffByArea}
              parametros={parametros}
              canSee={props.canSee}
              hideTitle={hideTitleTable}
            />
          </div>
        </Col>
      </Row>
    </>
  );
}

export default ModalTableChooseAreas;

import Widget from "../../../../components/Widget/Widget";
import FormSolicitudPago from "../FormSolicitudPago";

const EditarSolicitudPago = (props) => {
  return (
    <div>
      <Widget className="widget-p-md">
        <h3>Editar Solicitud de Pago</h3>
        <FormSolicitudPago data={localStorage.getItem('dataContent').split('|-|')}/>
    </Widget>
    </div>
  )
}

export default EditarSolicitudPago;
import { useEffect, useState } from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import {
  SelectTypeHeadSingle,
  TextAreaInput,
} from "../../../../components/GenericInputsFormik";
import * as Yup from "yup";
import { useFormik } from "formik";
import { doRequest } from "../../../../helpers/requests";
import FullFormLoader from "../../../../components/Loader/FullFormLoader";

const defaultform = {
  notes: "",
};
const FormSchema = Yup.object().shape({
  notes: Yup.string().required("Ingrese su observación"),
});

export default function CancelarSolicitud({
  idQuote,
  modal,
  setModal,
  setShowButtons
}) {
  const [loading, setLoading] = useState(false);

  const {
    handleSubmit,
    handleReset,
    handleBlur,
    values,
    errors,
    touched,
    setFieldValue,
  } = useFormik({
    initialValues: defaultform,
    onSubmit: () => {
      rechazarSolicitud();
    },
    validationSchema: FormSchema,
    enableReinitialize: true,
  });

  const onChange = (e) => {
    setFieldValue([e.target.name], e.target.value);
  };

  const toggle = () => setModal(!modal);

  useEffect(() => {}, []);

  const rechazarSolicitud = async () => {
    const params = {
      action: "update",
      table: "quotes",
      rows: { notes: values.notes, keyStat: 104 },
      condition: { idQuote: idQuote },
    };
    setLoading(true);
    const res = await doRequest("receiver/receiver.php", params, true);
    if (res.length > 0) {
      if(setShowButtons){
        setShowButtons(false);
      }
      setModal(false);
      handleReset();
    }
    setLoading(false);
  };

  return (
    <Modal
      isOpen={modal}
      toggle={toggle}
      backdrop={false}
      keyboard
      size="lg"
      centered
    >
    <form onSubmit={handleSubmit} onReset={handleReset}>
      <ModalHeader toggle={toggle} className="bg-primary">
        <p className="text-white">Señalar observaciones</p>
      </ModalHeader>
      <ModalBody>
          <TextAreaInput
            label="Observación"
            inputName="notes"
            inputRows={6}
            onChangeMethod={onChange}
            onBlurMethod={handleBlur}
            value={values.notes}
            isRequired
            touched={touched.notes}
            errors={errors.notes}
          />
      </ModalBody>
      <ModalFooter>
        <div className="w-100 d-flex justify-content-md-end flex-md-row flex-column-reverse">
          <Button color="primary" type="reset" onClick={toggle} className="my-2 my-md-0 mx-0 mr-md-3">
            Cerrar
          </Button>
          <Button color="secondary" type="submit" className="my-2 my-md-0">
            Guardar Observación
          </Button>
        </div>
      </ModalFooter>
      <FullFormLoader show={loading} />
        </form>
    </Modal>
  );
}

/**
 * Función que separa en arreglo dado un tamaño y fuente disponible
 * @param {Cadena a partir} str 
 * @param {Fuente en formato: 'size Family' tamaño en PT} font 
 * @param {Tamaño maximo en px PT = 0.75px} maxWidth 
 * @returns arreglo de cadenas
 */
export const splitString = (str, font, maxWidth) => {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    context.font = font;
  
    const words = str.split(' ');
    let line = '';
    const lines = [];
  
    for (let i = 0; i < words.length; i++) {
      const testLine = line + words[i] + ' ';
      const testWidth = context.measureText(testLine).width;
  
      if (testWidth > maxWidth && i > 0) {
        lines.push(line.trim());
        line = words[i] + ' ';
      } else {
        line = testLine;
      }
    }
  
    lines.push(line.trim());
    return lines;
};
  

export function number2Alphabet(numero) {
  let resultado = '';
  numero++;
  if (numero <= 0) {
    return resultado;
  }
  while (numero > 0) {
    const indice = (numero - 1) % 26;
    resultado = (indice + 10).toString(36).toUpperCase() + resultado;
    numero = Math.floor((numero - 1) / 26);
  }
  return resultado;
}
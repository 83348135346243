import { Document, Page, Text, View, StyleSheet } from "@react-pdf/renderer";
import { styles } from "../../../../components/Pdf/pdfGeneral/EstiloPdf";
import { numberToMonth } from "../excel/excelISR";
import HeaderReport from "../../../../components/Pdf/Header";

const formatter = new Intl.NumberFormat("es-MX", {
  style: "currency",
  currency: "MXN",
});

const formatterNums = new Intl.NumberFormat('es-MX');

/**
 * Estilo que se repite en todas los headers de la tabla
 */
const styleHeadForAll = {
  borderStyle: "solid", 
  borderWidth: 1,
  backgroundColor: "#646464",
  color: 'white',
  textAlign: 'center',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: 9
}

const styleCellForAll = {
  borderStyle: "solid", 
  borderWidth: 1, 
  borderTopWidth: 0 ,
}

/**
 * Estilos del pdf
 */
const s = StyleSheet.create({
  subTitle:{ //SUBTITULOS
    width: "100%",
    textAlign: 'center',
    marginBottom: 10,
    fontSize: 14
  },
  tableColHeaderCon: { //HEADER CONCEPTO
    width: "50%",
    height: 20, 
    ... styleHeadForAll
  }, 
  tableColHeaderTE: { //HEADER TOTAL EMPLEADOS
    width: "25%",  
    borderLeftWidth: 0,
    ... styleHeadForAll
  }, 
  tableColHeaderIm: { //HEADER TOTAL IMPORTE
    width: "25%", 
    borderLeftWidth: 0,
    ... styleHeadForAll
  }, 
  tableColContentReport: { //CONTENIDO
    width: "100%"
  },
  tableColContentCon: { //Cell concepto
    width: "50%", 
    borderLeftWidth: 0,
    ...styleCellForAll
  },
  tableColContentTE: { //Cell total empleado
    width: "25%", 
    textAlign: 'right',
    borderLeftWidth: 0, 
    ...styleCellForAll
  },
  tableColContentIm: { //Cell total importe
    width: "25%", 
    textAlign: 'right',
    borderLeftWidth: 0, 
    ...styleCellForAll
  },
  cellColumn: { 
    width: "100%", 
    fontSize: 8 ,
    flexDirection: "row" ,
    justifyContent: 'center',
    alignItems: 'center',
  },
  displayContent: { 
    paddingLeft: 9, //centra el content
    marginVertical: 4,
    flexDirection: "row",
    justifyContent: 'space-around',
  }
})


export default function generatePDFISR(data, model, title, fileNo) {
  const ori = model.orientation === "h" ? "landscape" : "portrait";

  function getContentTable(table) {
    if (table.length > 0) {
      const total = table.reduce((ac, value) => {
        const NImp = value.TotalImporte.replace("$", "").replace(/,/g, "");
        return ac + Number(NImp);
      }, 0);
      const content = table.map(value => (
        <View style={styles.tableRow} wrap={false}>
          <View style={s.tableColContentCon}>
            <Text style={s.cellColumn}>
              {value[model.content[0].value]}
            </Text>
          </View>
          <View style={s.tableColContentTE}>
            <Text style={s.cellColumn}>
              {formatterNums.format(value[model.content[1].value])}
            </Text>
          </View>
          <View style={s.tableColContentIm}>
            <Text style={s.cellColumn}>
              {value[model.content[2].value]}
            </Text>
          </View>
        </View>
      ))
      return (
        <>
        {content}
        <View style={styles.tableRow} wrap={false}>
          <View style={s.tableColContentCon}>
            <Text style={s.cellColumn}>
            </Text>
          </View>
          <View style={s.tableColContentTE}>
            <Text style={s.cellColumn}>
              TOTAL
            </Text>
          </View>
          <View style={s.tableColContentIm}>
            <Text style={s.cellColumn}>
              {formatter.format(total)}
            </Text>
          </View>
        </View>
        </>
      )
    }
    return null
  }
  
  return {
    report: (
      <>
        <Document>
          
          <Page style={styles.body} orientation={ori}>
            <HeaderReport/>
            <View>
              <Text style={s.subTitle}>{title}</Text>
              <Text style={s.subTitle}>{`DE: ${numberToMonth(model.descr.month)} DE ${model.descr.year}`}</Text>
            </View>
            <View style={styles.table}>
              {/* Cabeceras */}
              <View style={styles.tableRow} fixed>
                <View style={s.tableColHeaderCon} fixed>
                  <Text>{model.headers[0]}</Text>
                </View>
                <View style={s.tableColHeaderTE} fixed>
                  <Text>{model.headers[1]}</Text>
                </View>
                <View style={s.tableColHeaderIm} fixed>
                  <Text>{model.headers[2]}</Text>
                </View>
              </View>
              <View style={s.tableColContentReport}>
                {getContentTable(data)}
              </View>
            </View>

            <View fixed style={styles.spaceFooter}></View>
            <View fixed style={styles.footer}>
              <Text style={styles.textFooter}>{fileNo}</Text>
              <Text
                style={styles.textFooter}
                render={({ pageNumber, totalPages }) =>
                  `PAGINA : ${pageNumber} / ${totalPages}`
                }
              />
            </View>
          </Page>
        </Document>
      </>
    ),
  };
}

import { CancelOutlined } from "@material-ui/icons";
import React, { useState } from "react";
import s from "./InputClear.module.scss";
import ModalDatatable from "../Modal/ModalDataTable";
import { doRequestSaveRes } from "../../helpers/requests";

/**
 * Button Input con modal
 * @param {*} props 
 * @param {string} label - (Opcional) Etiqueta del input
 * @param {string} inputName - Nombre del campo
 * @param {*} value - valor del campo
 * @param {boolean} isRequired - (Opcional) Marcar elc ampo como obligatorio
 * @param {boolean} touched - (Opcional) Estado del campo. clickeado o no
 * @param {string | null} errors - (Opcional) mensaje de error
 * @param {string} isPlaceholder - (Opcional) Etiqueta del input
 * @param {Function} selectRow - Selecciona un registro de la tabla
 * @param {Function} clearFun - Funcion para limpiar el campo
 * @param {true} isDisabled - (Opcional) Desactivar el campo
 * @param {true} disabledClear - (Opcional) Desactivar el boton de limpiar
 * @param {Function} onButtonClicik - (Opcional) Funcion para ejecutar antes de mostrar el modal
 * @param {true | false | "static"} backdrop - (Opcional)
 * @param {boolean} keyboard - (Opcional)
 * @param {boolean} btnClose - (Opcional)
 * @param {Function} addRecord - (Opcional) Función apra crear un nuevo registro
 * @param {"front" | "back" } control - (Opcional) Modo de tabla (default back)
 * @param {boolean} data - (Opcional) Registros a mostrar
 * @param {Function} fields - (Opcional) Arreglo con nombres de campos a modificar
 * @param {{title: string, headers: Array<string>, columns: Array<Object>, params: {table: string, rows: string, conditions: string, group: string}, columnDefs: Array<Object>}} modalParams - Paramentros para utilizar la tabla
 * @param {string} modalParams.title - Titulo del módal
 * @param {Array<string>} modalParams.headers - Cabeceras de la tabla. *No es necesario incluir la columna de selección
 * @param {Array<Object>} modalParams.columns - Columansde la tabla
 * @param {{table: string, rows: string, conditions: string, group: string}} modalParams.params - Paramentros de la consulta
 * @param {Array<Object>} modalParams.columnDefs - Definición de columnas
 * @param {Array<Object>} modalParams.processData - (Opcional) Función para procesar la informacion de la respuesta antes de enviarla a la tabla
 */
export default function ButtonInputM({
  label,
  inputName,
  value,
  isRequired,
  touched,
  errors,
  isPlaceholder,
  selectRow,
  clearFun,
  isDisabled,
  disabledClear,
  onButtonClicik,
  modalParams,
  addRecord,
  control,
  data,
  fields
}){
  const [modalTable, setModalTable] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const headers = [...modalParams.headers, "Seleccionar"];
  const columns = [
    ...modalParams.columns, 
    {data: null, render: (row)=><i className="fa fa-eye text-info cursor-pointer" onClick={()=>{selectRow(row, fields); setModalTable(false)}}/>}
  ];
  const columnDefs = [...modalParams.columnDefs||[], {className: "text-center", orderable: false, targets: [headers.length-1]}]

  async function getData(ordCol, itemsPerPage, currentPage, search) {
    const res = await doRequestSaveRes(
        "receiver/receiver.php",
        getParams(ordCol, itemsPerPage, currentPage, search),
        false,
        false
    );
    if (res.code === "200"){
      if(modalParams.processData) res.data = modalParams.processData(res.data);
      return res;
    }else return null;
}

function getParams(ordCol, itemsPerPage, currentPage, search) {
    const params = {
        action: "datatable",
        table: modalParams.params.table,
        rows: modalParams.params.rows,
        conditions: modalParams.params.conditions,
        order: ordCol,
        records: itemsPerPage.toString(),
        page: (itemsPerPage * currentPage).toString(),
        search,
        group: modalParams.params.group || "",
    };
    return params;
}

  function onClick(){
    if(!isDisabled){
      if(onButtonClicik) onButtonClicik();
      setModalTable(true);
    }
  }

  return (
    <>
      <div className="form-group position-relative">
        {
          <div className="d-flex justify-content-between">
            <label htmlFor={inputName}>
              {label} {isRequired && <span className="text-danger">*</span>}
            </label>
            {
              addRecord && <p className={s.linkAdd} onClick={()=>addRecord()}>Agregar Registro</p>
            }
          </div>
        }
        <div className="input-group">
          <input
            name={inputName}
            id={inputName}
            className={
              "form-control" +
              (errors && touched ? " is-invalid" : "")
            }
            value={value}
            placeholder={isPlaceholder? isPlaceholder : ""}
            // disabled
            readOnly
            onClick={() => onClick()}
          />
          <div className="input-group-append">
            <button
              className="btn btn-secondary"
              style={{ zIndex: "auto" }}
              type="button"
              onClick={() => onClick()}
              disabled={isDisabled? true : false}
            >
              <i className="fa fa-search" />
            </button>
          </div>
        </div>
        {!disabledClear && value !== "" && (
          <CancelOutlined
            className={s.btnClear}
            onClick={() => clearFun(inputName, fields)}
            style={{zIndex: 3}}
          />
        )}
        {touched && errors && (
          <span style={{ color: "red" }}>{errors}</span>
        )}
      </div>

      <ModalDatatable
        modal={modalTable}
        setModal={setModalTable}
        title={modalParams.title}
        backdrop={modalParams.backdrop || true}
        keyboard={modalParams.keyboard || true}
        btnClose={modalParams.btnClose || true}
        headers={headers}
        columns={columns}
        control={control||"back"}
        peticion={getData}
        data={data}
        refresh={refresh}
        setRfresh={setRefresh}
        columnDefs={columnDefs}
      />
    </>
  );
};

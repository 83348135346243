/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import { Collapse, Col, Row, Button } from "reactstrap";
import { SelectTypeHeadSingle } from "../../../components/GenericInputsFormik/index";
import * as Yup from "yup";
import { peticionesReceiver } from "../../../helpers/peticionesReceiver";
import { toast } from "react-toastify";
import Notification from "../../../components/Notification/Notification";
import { getKindToStatus } from "./consultasSelect";
import ModalConfirmation from "../../../components/Modal/ModalConfirmation";
import TableComponentOnePage from "../../../components/TableComponent/TableComponentOnePage";
import CurrencyInput from "react-currency-input-field";
import { YearInput } from "../../../components/GenericInputsFormik/YearInput";
import { PERIOD } from "../../../helpers/regex";
import { NumberInput } from "../../../components/GenericInputsFormik/NumberInput";
import { actualPeriod } from "../../../libs/tools/format";
import PdfPrestacionesExtraordinarias from "./reports/PdfPrestacionesExtraordinarias";
import ModalReports from "../../../components/Modal/ModalReports";
import { doRequest } from "../../../helpers/requests";
import FullFormLoader from "../../../components/Loader/FullFormLoader";
import excelPrestacionesExtraordinarias from "./reports/excelPrestacionesExtraordinarias";

export const ExpedienteNominaPrestacionesGenerales = ({
    idStaff,
    permissions,
    staffData
}) => {
    const API = peticionesReceiver();
    const [unders, setUnders] = useState([]);
    const [status, setStatus] = useState([]);
    const [collapse, setCollapse] = useState(false);
    const [modal, setModal] = useState(false);
    const [filtroUnder, setFiltroUnder] = useState("");
    const select = {
        action: "datatable",
        table:
            "expensewithholding A INNER JOIN unders B on ( A.idUnder = B.idUnder) INNER JOIN status C on (A.keyStat = C.keyStat)",
        rows: "A.idExpenseWithholding,B.under,B.nameUnder,A.amountTotal,A.amountBiweekly,concat(A.startPeriod,'/',A.startYear) as inicio,concat(A.endPeriod,'/',A.endYear)as fin,A.noPays,A.noPaysMade,C.nameStat,A.startPeriod,A.startYear,A.endPeriod,A.endYear,A.notes,A.idUnder,A.keyStat",
        conditions: `A.enabled = 1 AND idStaff=${idStaff}`,
        page: 0,
        records: 5,
        search: "",
        order: "",
    };
    const [parametros, setParametros] = useState(select);
    const [paramsReport, setParamsReport] = useState("");
    const [modalReports, setModalReports] = useState(false);
    const [dataReport, setDataReport] = useState([]);
    const [loading, setLoading] = useState(false);

    /* INITIAL */
    useEffect(() => {
        getUnders();
        getKindToStatus(setStatus, false, null, "EXPENSE WITH HOLDING");
    }, []);

    async function getUnders() {
        const params = {
            action: "multiselect",
            table: "unders",
            rows: "idUnder,concat(under,' - ',nameUnder) as name",
            conditions: "enabled = 1",
            order: "name",
        };
        await API.peticion(params)
            .then((res) => {
                if (res.status === 200 && res.data.code === "200") {
                    setUnders(res.data.data);
                } else {
                    toast(
                        <Notification
                            type={"consultar_error"}
                            backMessage={res.data.message}
                            withIcon
                        />
                    );
                }
            })
            .catch((err) => {
                toast(<Notification type={"consultar_servidor_error"} withIcon />);
            });
    };
    /* END INITIAL */

    /* FORM */
    const {
        handleSubmit,
        handleReset,
        handleBlur,
        values,
        errors,
        touched,
        setFieldValue,
        resetForm
    } = useFormik({
        initialValues: { ...defaultForm, idStaff: idStaff },
        onSubmit: () => setModal(true),
        onReset: () => setCollapse(false),
        validationSchema: FormSchema,
        enableReinitialize: true,
    });

    const onChange = (e) => {
        if (e.target.name === "startYear" && !e.target.value) {
            setFieldValue([e.target.name], currDate);
        } else {
            setFieldValue(e.target.name, e.target.value);
        }
    };

    const modoEdicion = (prestamo) => {
        if (collapse) {
            toast(<Notification type="warning" backMessage="Guarde sus cambios antes de continuar" />, { closeButton: false });
            return;
        }
        setFieldValue("idExpenseWithholding", prestamo[0]);
        setFieldValue("startPeriod", prestamo[10]);
        setFieldValue("startYear", new Date(prestamo[11], 0, 1));
        setFieldValue("endPeriod", prestamo[12]);
        setFieldValue("endYear", new Date(prestamo[13]));
        setFieldValue("amountTotal", prestamo[3]);
        setFieldValue("amountBiweekly", prestamo[4]);
        setFieldValue("noPays", prestamo[7]);
        setFieldValue("noPaysMade", prestamo[8]);
        setFieldValue("notes", prestamo[14]);
        setFieldValue("keyStat", prestamo[16]);
        setFieldValue("idUnder", prestamo[15]);
        setCollapse(true);
    };

    const agregar = async () => {
        setLoading(true);
        var temPrestamo = { ...values };
        temPrestamo.startYear = values.startYear.getFullYear();
        temPrestamo.endYear = values.endYear.getFullYear();
        const data = {
            action: "insert",
            table: "expensewithholding",
            rows: temPrestamo,
            validate: [["idStaff", "idUnder", "keyStat", "startPeriod", "startYear"]],
        };
        await API.peticion(data)
            .then((res) => {
                if (res.status === 200 && res.data.status === "success") {
                    toast(
                        <Notification type={"agrega_exito"} backMessage={res.data.message} withIcon/>,
                        { closeButton: false }
                    );
                    setFiltroUnder("");
                    handleReset();
                    setParametros(select);
                } else {
                    toast(
                        <Notification  type={"agrega_error"}  backMessage={res.data.message}  withIcon/>,
                        { closeButton: false }
                    );
                }
            })
            .catch((err) => {
                toast(<Notification  type={"agrega_error"}  backMessage={err}  withIcon/>,
                { closeButton: false });
            });
        setLoading(false);
    };

    const editar = async () => {
        setLoading(true);
        var temPrestamo = { ...values };
        temPrestamo.startYear = values.startYear.getFullYear();
        temPrestamo.endYear = values.endYear.getFullYear();
        const data = {
            action: "update",
            table: "expensewithholding",
            rows: temPrestamo,
            condition: {
                idExpenseWithholding: values.idExpenseWithholding,
            },
            validate: [["idStaff", "idUnder", "keyStat", "startPeriod", "startYear"]],
        };
        await API.peticion(data)
            .then((res) => {
                if (res.status === 200 && res.data.status === "success") {
                    toast(
                        <Notification type={"modifica_exito"} backMessage={res.data.message} withIcon/>,
                        { closeButton: false }
                    );
                    setFiltroUnder("");
                    handleReset();
                    setParametros(select);
                } else {
                    toast(
                        <Notification type={"modifica_error"} backMessage={res.data.message} withIcon/>,
                        { closeButton: false }
                    );
                }
            })
            .catch((err) => { 
                toast(
                    <Notification type={"modifica_error"} backMessage={err} withIcon/>,
                    { closeButton: false }
                );
            });
        setLoading(false)
    };

    function limpiar(id) {
        if (+values.idExpenseWithholding === +id) {
            handleReset();
            setFiltroUnder("");
        }
    }
    /* END FORM */

    /* OBSERVER */
    // Calcular el periodo de termino
    useEffect(() => {
        if (
            values.startYear !== null &&
            values.startPeriod !== "" &&
            values.startYear !== "" &&
            values.noPays &&
            values.amountTotal &&
            values.startPeriod <= 24 &&
            values.amountTotal > 0 &&
            values.noPays > 0
        ) {
            calculaPrestamo();
        } else {
            setFieldValue("endPeriod", "");
            setFieldValue("endYear", "");
            setFieldValue("amountBiweekly", "");
        }
    }, [values.startPeriod, values.startYear, values.amountTotal, values.noPays]);

    // Acualziar el filtro de la tabla
    useEffect(() => {
        setParametros(((ps) => ({
            ...ps,
            conditions: `A.enabled = 1 AND idStaff=${idStaff}` + (filtroUnder ? ` AND A.idUnder = ${filtroUnder}` : "")
        })));
    }, [filtroUnder]);

    const calculaPrestamo = (e) => {
        var periodoFinal = +values.startPeriod + +values.noPays - 1;
        var anioFinal =
            typeof values.startYear === "number"
                ? values.startYear
                : values.startYear.getFullYear();
        var auxPeriodo = periodoFinal / 24;
        auxPeriodo = Math.floor(auxPeriodo);

        if (periodoFinal % 24 > 0) {
            periodoFinal = periodoFinal - 24 * auxPeriodo;
            //
            anioFinal =
                typeof values.startYear === "number"
                    ? values.startYear + auxPeriodo
                    : values.startYear.getFullYear() + auxPeriodo;
        } else {
            periodoFinal = 24;
            anioFinal =
                typeof values.startYear === "number"
                    ? values.startYear + auxPeriodo - 1
                    : values.startYear.getFullYear() + auxPeriodo - 1;
        }
        setFieldValue("endPeriod", periodoFinal);
        setFieldValue("endYear", new Date(anioFinal, 0, 1));
        setFieldValue("amountBiweekly", (+values.amountTotal / +values.noPays).toFixed(2));
    };

    async function createReport(isPDF) {
        let res = [...dataReport];
        setLoading(true);
        if (paramsReport.conditions !== parametros.conditions || res.length < 1) {
            const params = {
                action: "multiselect",
                table: parametros.table,
                rows: parametros.rows,
                conditions: parametros.conditions,
            }
            res = await doRequest("receiver/receiver.php", params, false);
            setParamsReport(JSON.stringify(paramsReport));
        }
        try {
            if (res.length > 0) {
                setDataReport(res);
                if (isPDF) setModalReports(true);
                else await excelPrestacionesExtraordinarias(res, {staff: staffData});
            } else {
                toast(
                    <Notification type="warning" backMessage="No se encontraron registros" withIcon />,
                    { closeButton: false }
                );
            }
        } catch (error) {
            toast(
                <Notification type="danger" backMessage="Ocurrio un error al generar el reporte" withIcon />,
                { closeButton: false }
            );
            console.error(error);
        }
        setLoading(false);
    }

    return (
        <div className="mt-4">
            <div className="text-center text-md-left mt-5">
                <h1 className="text-secondary">Prestaciones Extraordinarias</h1>
            </div>
            <Row>
                <Col xs={12} md={6}>
                    <SelectTypeHeadSingle
                        label="Filtro Concepto"
                        inputName="filtroConcepto"
                        value={filtroUnder}
                        optionsArray={unders}
                        onChangeMethod={({ target }) => setFiltroUnder(target.value)}
                        optionValue="idUnder"
                        optionName="name"
                    />
                </Col>
                <Col xs={12} md={6}>
                    <div className="d-flex flex-md-row flex-column mb-2 justify-content-center justify-content-md-end">
                        <Button color="danger" className="m-1" onClick={() => createReport(true)}>Generar PDF</Button>
                        <Button color="success" className="m-1" onClick={() => createReport(false)}>Generar Excel</Button>
                    </div>
                </Col>
            </Row>
            <TableComponentOnePage
                cabecerasTabla={cabeceras}
                filtro={filtro}
                editar={modoEdicion}
                parametros={parametros}
                limpia={(id) => limpiar(id)}
                permissions={permissions}
                orderers={ordeder}
            />
            <ModalConfirmation
                modalTitle={values.idExpenseWithholding ? "Editar" : "Crear"}
                modal={modal}
                setModal={setModal}
                crear={agregar}
                editar={editar}
                isEdit={values.idExpenseWithholding}
                values={values}
            >
                {values.idExpenseWithholding ? (
                    <div className="d-flex justify-content-center">
                        <h6>¿Está seguro de editar el registro?</h6>
                    </div>
                ) : (
                    <div className="d-flex justify-content-center">
                        <h6>¿Desea continuar con el registro?</h6>
                    </div>
                )}
            </ModalConfirmation>

            {permissions.INS && (
                <div className="d-grid d-flex justify-content-center justify-content-sm-end">
                    <Button
                        color="add"
                        onClick={() => setCollapse(true)}
                        style={{ marginBottom: "1rem" }}
                        disabled={collapse}
                    >
                        Agregar
                    </Button>
                </div>
            )}

            <Collapse isOpen={collapse}>
                <div className="d-grid d-flex justify-content-sm-end justify-content-center">
                    <h6 className=" text-center">
                        Estado:{" "}
                        <span className="text-primary">
                            {values.idExpenseWithholding ? "Editando" : "Guardando"}{" "}
                        </span>{" "}
                    </h6>
                </div>

                <div className="text-center">
                    <h4>Prestación Extraordinaria</h4>
                </div>

                <form onSubmit={handleSubmit} onReset={handleReset} className="mt-4">
                    <Row className="d-flex justify-content-around">
                        <Col xs={12} md={12} lg={5}>
                            <SelectTypeHeadSingle
                                label="Concepto"
                                isRequired={true}
                                inputName="idUnder"
                                optionsArray={unders}
                                defaultOption="Selecciona un concepto"
                                onChangeMethod={onChange}
                                onBlur={handleBlur}
                                isDisabled={false}
                                value={values.idUnder}
                                touched={touched.idUnder}
                                errors={errors.idUnder}
                                optionValue="idUnder"
                                optionName="name"
                            />
                        </Col>

                        <Col xs={12} md={12} lg={5}>
                            <SelectTypeHeadSingle
                                label="Estado"
                                isRequired={true}
                                inputName="keyStat"
                                optionsArray={status}
                                defaultOption="Selecciona un estado"
                                onChangeMethod={onChange}
                                onBlur={handleBlur}
                                isDisabled={false}
                                value={values.keyStat}
                                touched={touched.keyStat}
                                errors={errors.keyStat}
                                optionValue="keyStat"
                                optionName="nameStat"
                            />
                        </Col>
                    </Row>

                    <Row className="d-flex justify-content-around">
                        <Col xs={12} md={12} lg={5}>
                            <div className="form-group">
                                <label htmlFor="amountTotal">
                                    Cantidad total <span className="text-danger">*</span>
                                </label>
                                <CurrencyInput
                                    id="amountTotal"
                                    name="amountTotal"
                                    className={
                                        "form-control" +
                                        (errors.amountTotal && touched.amountTotal
                                            ? " is-invalid"
                                            : "")
                                    }
                                    allowDecimals={true}
                                    allowNegativeValue={false}
                                    onValueChange={(value, name) =>
                                        onChange({ target: { name: name, value: value } })
                                    }
                                    value={values.amountTotal}
                                    onBlur={handleBlur}
                                    min={1}
                                    prefix="$"
                                    groupSeparator=","
                                    decimalSeparator="."
                                />
                                {touched.amountTotal && errors.amountTotal && (
                                    <span className="text-danger">{errors.amountTotal}</span>
                                )}
                            </div>
                        </Col>
                        <Col xs={12} md={12} lg={5}>
                            <div className="form-group">
                                <label htmlFor="noPays">
                                    Número de parcialidades <span className="text-danger">*</span>
                                </label>
                                <CurrencyInput
                                    id="noPays"
                                    name="noPays"
                                    className={
                                        "form-control" +
                                        (errors.noPays && touched.noPays ? " is-invalid" : "")
                                    }
                                    allowDecimals={false}
                                    allowNegativeValue={false}
                                    onValueChange={(v, n) =>
                                        onChange({ target: { name: n, value: v } })
                                    }
                                    onBlur={handleBlur}
                                    maxLength={3}
                                    value={values.noPays}
                                    min={1}
                                />
                                {touched.noPays && errors.noPays && (
                                    <span className="text-danger">{errors.noPays}</span>
                                )}
                            </div>
                        </Col>
                    </Row>
                    <Row className="d-flex justify-content-around">
                        <Col xs={12} md={12} lg={5}>
                            <SelectTypeHeadSingle
                                label="Periodo de inicio"
                                isRequired={true}
                                optionsArray={PERIOD}
                                inputName="startPeriod"
                                onChangeMethod={onChange}
                                onBlurMethod={handleBlur}
                                touched={touched.startPeriod}
                                errors={errors.startPeriod}
                                optionValue="value"
                                optionName="label"
                                value={values.startPeriod}
                                defaultOption="Seleccione un periodo de inicio"
                            />
                        </Col>
                        <Col xs={12} md={12} lg={5}>
                            <YearInput
                                label="Año Inicio"
                                inputName="startYear"
                                onChangeMethod={onChange}
                                onBlurMethod={handleBlur}
                                value={values.startYear}
                                isRequired
                                touched={touched.startYear}
                                errors={errors.startYear}
                                isMin={new Date("2019")}
                                isMax={new Date("2500")}
                            />
                        </Col>
                    </Row>

                    <Row className="d-flex justify-content-around">
                        <Col xs={12} md={12} lg={5}>
                            <div className="form-group">
                                <label htmlFor="amountBiweekly">
                                    Cantidad quincenal <span className="text-danger">*</span>
                                </label>
                                <CurrencyInput
                                    id="amountBiweekly"
                                    name="amountBiweekly"
                                    className={
                                        "form-control" +
                                        (errors.amountBiweekly && touched.amountBiweekly
                                            ? " is-invalid"
                                            : "")
                                    }
                                    allowDecimals={true}
                                    allowNegativeValue={false}
                                    onValueChange={(value, name) =>
                                        onChange({ target: { name: name, value: value } })
                                    }
                                    value={values.amountBiweekly}
                                    onBlur={handleBlur}
                                    min={1}
                                    prefix="$"
                                    groupSeparator=","
                                    decimalSeparator="."
                                    disabled={true}
                                />
                                {touched.amountBiweekly && errors.amountBiweekly && (
                                    <span className="text-danger">{errors.amountBiweekly}</span>
                                )}
                            </div>
                        </Col>
                        <Col xs={12} md={12} lg={5}>
                            <div className="form-group">
                                <label htmlFor="endPeriod">
                                    Fin periodo <span className="text-danger">*</span>
                                </label>
                                <CurrencyInput
                                    id="endPeriod"
                                    name="endPeriod"
                                    className={
                                        "form-control" +
                                        (errors.endPeriod && touched.endPeriod ? " is-invalid" : "")
                                    }
                                    allowDecimals={false}
                                    allowNegativeValue={false}
                                    onValueChange={(v, n) =>
                                        onChange({ target: { name: n, value: v } })
                                    }
                                    onBlur={handleBlur}
                                    maxLength={3}
                                    value={values.endPeriod}
                                    min={1}
                                    disabled={true}
                                />
                                {touched.endPeriod && errors.endPeriod && (
                                    <span className="text-danger">{errors.endPeriod}</span>
                                )}
                            </div>
                        </Col>
                        <Col xs={12} md={12} lg={5}>
                            <YearInput
                                label="Año Termino"
                                inputName="endYear"
                                onChangeMethod={onChange}
                                onBlurMethod={handleBlur}
                                value={values.endYear}
                                isRequired
                                touched={touched.endYear}
                                errors={errors.endYear}
                                isMin={new Date("2019")}
                                isMax={new Date("2500")}
                                isDisabled
                            />

                        </Col>
                        <Col xs={12} md={12} lg={5}>
                            <div className="form-group">
                                <label htmlFor="noPaysMade">
                                    Pagos aplicados <span className="text-danger">*</span>
                                </label>
                                <CurrencyInput
                                    id="noPaysMade"
                                    name="noPaysMade"
                                    className={
                                        "form-control" +
                                        (errors.noPaysMade && touched.noPaysMade
                                            ? " is-invalid"
                                            : "")
                                    }
                                    allowDecimals={false}
                                    allowNegativeValue={false}
                                    onValueChange={(v, n) =>
                                        onChange({ target: { name: n, value: v } })
                                    }
                                    onBlur={handleBlur}
                                    maxLength={3}
                                    value={values.noPaysMade}
                                    min={1}
                                    disabled={true}
                                />
                                {touched.noPaysMade && errors.noPaysMade && (
                                    <span className="text-danger">{errors.noPaysMade}</span>
                                )}
                            </div>
                        </Col>
                        <Col xs={12} md={12} lg={10}>
                            <label htmlFor="notes">Notas</label>
                            <textarea
                                name="notes"
                                onChange={onChange}
                                onBlur={handleBlur}
                                value={values.notes}
                                className={
                                    "form-control" +
                                    (errors.notes && touched.notes ? " is-invalid" : "")
                                }
                            />
                        </Col>
                    </Row>

                    {(+values.noPaysMade > 0 || true) && <div>
                        <div className="text-center mt-4 mb-2">
                            <h5>Pagos aplicados</h5>
                        </div>

                        <Row className="d-flex justify-content-around">
                            <Col xs={12} lg={5}>
                                <NumberInput
                                    label="Pago Aplicado"
                                    inputName="total"
                                    onChangeMethod={onChange}
                                    onBlurMethod={handleBlur}
                                    value={(+values.noPaysMade * +values.amountBiweekly)}
                                    prefix="$"
                                    decimals
                                    readOnly
                                />
                            </Col>
                            <Col xs={12} lg={5}>
                                <NumberInput
                                    label="Pago Restante"
                                    inputName="total"
                                    onChangeMethod={onChange}
                                    onBlurMethod={handleBlur}
                                    value={+values.amountTotal - (+values.noPaysMade * +values.amountBiweekly)}
                                    prefix="$"
                                    decimals
                                    readOnly
                                />
                            </Col>
                        </Row>
                    </div>}

                    <div className="row mt-4">
                        <div className="col-sm-6 order-2 order-sm-1 text-center text-sm-left mb-4">
                            <Button color="danger" type="reset" >
                                Cancelar
                            </Button>
                        </div>
                        <div className="col-sm-6 order-1 order-sm-2 text-center text-sm-right mb-4">
                            <Button color="success" type="submit">
                                Guardar
                            </Button>
                        </div>
                    </div>
                </form>
            </Collapse>

            <ModalReports
                modal={modalReports}
                setModal={setModalReports}
                title={"Reporte de Prestaciones Extraordinarias"}
                cabeceras={cabeceras}
                backdrop="static"
                keyboard={false}
                report={dataReport}
                pdfConfig={{staff: staffData}}
                generatePdfMethod={{
                    method: PdfPrestacionesExtraordinarias,
                    type: "Prestaciones Extraordinarias",
                }}
                records={1000}
            />

            <FullFormLoader show={loading} />
        </div>
    );
};
export default ExpedienteNominaPrestacionesGenerales;


const currDate = new Date();
const currPeriod = actualPeriod(currDate);

const defaultForm = {
    idStaff: "",
    idExpenseWithholding: "",
    idUnder: "",
    keyStat: "",
    startPeriod: currPeriod,
    startYear: currDate,
    endPeriod: "",
    endYear: "",
    amountTotal: "",
    amountBiweekly: "",
    noPays: "",
    noPaysMade: 0,
    notes: ""
}

const FormSchema = Yup.object().shape({
    idUnder: Yup.string().required("Seleccione una nomina"),
    keyStat: Yup.string().required("Seleccione un estado"),
    startPeriod: Yup.number()
        .required("Ingrese un inicio de periodo")
        .integer("Debe de ser un número entero")
        .min(1, "ingrese un número mayor a 1")
        .max(24, "ingrese un número menor a 25"),
    startYear: Yup.date().required("Ingrese un inicio de año"),
    amountTotal: Yup.number()
        .min(1, "ingrese un número positivo")
        .required("Ingrese una cantidad"),
    noPays: Yup.number()
        .min(1, "ingrese un número mayor a 1")
        .integer("Debe de ser un número entero")
        .required("Ingrese un pago"),
});


const cabeceras = [
    "Id",
    "Clave",
    "Concepto",
    "Monto total",
    "Monto Quincenal",
    "Periodo de inicio",
    "Periodo termino",
    "Pagos",
    "Pagos aplicados",
    "Status",
    "Editar",
    "Eliminar",
];

const filtro = [
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    false,
    false,
    false,
    false,
    false,
];

const ordeder = [
    "",
    false,
    null,
    undefined,
    false,
    "CONCAT(A.startYear, A.startPeriod)",
    "CONCAT(A.endYear, A.endPeriod)",
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
];


import { Document, Page, Text, View, StyleSheet } from "@react-pdf/renderer";
import { styles } from "../../../../../../components/Pdf/pdfGeneral/EstiloPdf";

import HeaderReport from "../../../../../../components/Pdf/Header";
import { data } from "browserslist";

/**
 * Estilo que se repite en todas los headers de la tabla
 */
const styleHeadForAll = {
  fontFamily: "Helvetica-Bold",
  paddingVertical: 3,
  borderStyle: "solid",
  borderWidth: 1,
  backgroundColor: "#646464",
  color: "white",
  textAlign: "center",
  justifyContent: "center",
  alignItems: "center",
  fontSize: 9,
  borderBottom: 0,
};

const styleCellForAll = {
  paddingVertical: 3,
  borderStyle: "solid",
  borderWidth: 1,
  borderTopWidth: 0,
  fontSize: 8,
};

/**
 * Estilos del pdf
 */
const s = StyleSheet.create({
  body: {
    padding: 10,
  },
  subTitle: {
    //SUBTITULOS
    width: "100%",
    textAlign: "center",
    marginBottom: 10,
    marginLeft: 10,
    fontSize: 12,
  },
  f6: {
    fontSize: 6,
  },
  f5: {
    fontSize: 5,
  },
  f8: {
    fontSize: 8,
  },
  f10: {
    fontSize: 10,
  },
  thIn: {
    ...styleHeadForAll,
  },
  th: {
    ...styleHeadForAll,
    borderLeftWidth: 0,
  },
  w5: {
    width: "5%",
  },
  w10: {
    width: "10%",
  },
  w20: {
    width: "20%",
  },
  w18: {
    width: "18%",
  },
  w25: {
    width: "25%",
  },
  w30: {
    width: "30%",
  },
  w33: {
    width: "33%",
  },
  w34: {
    width: "34%",
  },
  w40: {
    width: "40%",
  },
  w45: {
    width: "45%",
  },
  w50: {
    width: "50%",
  },
  w60: {
    width: "60%",
  },
  w70: {
    width: "70%",
  },
  w90: {
    width: "90%",
  },
  w100: {
    width: "100%",
  },
  mt2: {
    marginTop: 5,
  },
  mt4: {
    marginTop: 40,
  },
  pt1: {
    paddingTop: 10,
  },
  px2: {
    paddingHorizontal: 10,
  },
  px1: {
    paddingHorizontal: 5,
  },
  bt0: {
    borderTopWidth: 0,
  },
  bt1: {
    borderTopWidth: 1,
  },
  center: {
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
  },
  textBold: {
    fontFamily: "Helvetica-Bold",
  },
  textRight: {
    textAlign: "right",
  },
  tdIn: {
    //Cell CC
    ...styleCellForAll,
  },
  td: {
    //Cell Concepto
    ...styleCellForAll,
    borderLeftWidth: 0,
  },
  td0: {
    //Cell Concepto
    ...styleCellForAll,
    borderLeftWidth: 0,
    borderRightWidthWidth: 0,
  },
  red: {
    backgroundColor: "red",
  },
  green: {
    backgroundColor: "green",
  },
  gray: {
    backgroundColor: "gray",
  },
  spaceFooter: {
    fontSize: 12,
    bottom: 10,
    left: 0,
    right: 0,
    width: "auto",
    height: 50,
    marginHorizontal: 10,
  },
  footer: {
    position: "absolute",
    fontSize: 12,
    bottom: 10,
    left: 0,
    right: 0,
    width: "auto",
    height: 30,
    marginHorizontal: 10,
    /*       borderColor:"#a9a9a9", */
    borderTopWidth: 1,
    flexDirection: "row",
    alignContent: "space-around",
    alignItems: "center",
  },
  textFooter: {
    width: "50%",
    /* color: '#a9a9a9', */
    textAlign: "center",
    fontSize: 8,
  },
});

const formatter = new Intl.NumberFormat("es-MX", {
  style: "currency",
  currency: "MXN",
});

const formatInformation = (report) => {
  let tempInformation = {
    data: [],
    dataCapitulos: [],
    dataPartidas: [],
  };

  let prevNameChapter = "";
  let amontChapter = 0;
  let amountConcept = 0;
  report.data.map((concept) => {
    /* total chapter */
    if (prevNameChapter !== concept.nameChapter) {
      //extraigo todos los capitulos que coincidan con keyChapter
      let tempChapters = report.dataCapitulos.filter(
        (chapter) => chapter.keyChapter === concept.keyChapter
      );

      //sumatoria de capitulos en el array extraido anteriormente
      amontChapter = 0;
      tempChapters.map((chap) => {
        amontChapter += +chap.montoTotal;
      });
      prevNameChapter = concept.nameChapter;
      tempInformation.data.push({
        nameChapterPrint: concept.nameChapter,
        amountChapter: amontChapter,
        isChapter: true,
      });
    }

    //extraigo todos los conceptos comparando con keyConcept
    let tempConcepts = report.dataPartidas.filter(
      (partida) => partida.conceptKey === concept.conceptKey
    );

    //sumatoria de conceptos en el array extraido anteriormente
    amountConcept = 0;
    tempConcepts.map((part) => {
      amountConcept += +part.montoTotal;
    });

    /* nameChapter */
    /* si el nameChapter cambia , inserta el capitulo con total capitulos  */

    //inserta el total partida del
    concept.amountPartida = amountConcept;
    tempInformation.data.push(concept);
  });

  return tempInformation;
};

export default function exportPDFReport49(report, model, title, fileNo) {
  const ori = model.orientation === "h" ? "landscape" : "portrait";
  const conceptos = [];

  /* const data = [...report.data, ...report.dataCero] */

  /* data.forEach(row => {
        const index = conceptos.findIndex(con => con.key === row.keyChapter);
        if (index !== -1) {
            conceptos[index].values.push(row);
        } else {
            conceptos.push({ key: row.keyIncomeType, name: row.nameIncomeType, values: [row] })
        }
    }) */

  const information = formatInformation(report);
  return {
    report: (
      <>
        <Document>
          <Page style={s.body} orientation={ori}>
            <HeaderReport fixed={true} />
            <View fixed>
              <Text style={s.subTitle}>{title}</Text>
            </View>
            <View style={styles.table}>
              <View style={styles.tableRow}>
                <View style={[s.thIn, s.w100]}>
                  <Text>Fiscalia General de Justicia del Estado de México</Text>
                </View>
              </View>
              <View style={styles.tableRow}>
                <View style={[s.thIn, s.w100]}>
                  <Text>{title}</Text>
                </View>
              </View>
              <View style={styles.tableRow} fixed>
                <View style={[s.thIn, s.w60]}>
                  <Text>Total</Text>
                </View>
                <View style={[s.th, s.w40]}>
                  <Text>Importe</Text>
                </View>
              </View>
              {information.data.map((obj,index) => (
                <View wrap={false} key={index}>
                  {obj.isChapter ? (
                    <View style={styles.tableRow}>
                      <View style={[s.tdIn, s.w60, s.textBold, s.center]}>
                        <Text>{obj.nameChapterPrint}</Text>
                      </View>
                      <View style={[s.td, s.w40, s.textBold, s.center]}>
                        <Text>
                          {" "}
                          {obj.amountChapter === undefined ||
                          obj.amountChapter === null
                            ? formatter.format(0)
                            : formatter.format(obj.amountChapter)}
                        </Text>
                      </View>
                    </View>
                  ) : (
                    <>
                      <View style={styles.tableRow}>
                        <View style={[s.tdIn, s.w60]}>
                          <Text>{obj.nameConcept}</Text>
                        </View>
                        <View style={[s.td, s.w40, s.center]}>
                          <Text>
                            {" "}
                            {obj.amountPartida === undefined ||
                            obj.amountPartida === null
                              ? formatter.format(0)
                              : formatter.format(obj.amountPartida)}
                          </Text>
                        </View>
                      </View>
                    </>
                  )}
                </View>
              ))}
            </View>

            <View fixed style={styles.spaceFooter}></View>
            <View fixed style={styles.footer}>
              <Text style={styles.textFooter}>{fileNo}</Text>
              <Text
                style={styles.textFooter}
                render={({ pageNumber, totalPages }) =>
                  `PAGINA : ${pageNumber} / ${totalPages}`
                }
              />
            </View>
          </Page>
        </Document>
      </>
    ),
  };
}

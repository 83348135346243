import { usePDF } from "@react-pdf/renderer";
import React from "react";
import { saveAs } from "file-saver";

const DownloadContainer = (props) => {
  const { pdf, showFileName, current, setCurrent, index } =
    props;

  const [instance] = usePDF({ document: pdf });
  if (
    instance.loading ||
    (!instance.loading && (instance.url === undefined || instance.url === null))
  ) {
    return (
      <>
        <div className="mt-3 text-center">
          <h6>Generando documento {index + 1}...</h6>
        </div>
        
      </>
    );
  }

  if (instance.error) return <div>Algo salio mal</div>;

  return (
    <div>
      {saveAs(instance.blob, showFileName)}
      {setCurrent(current + 1)}
    </div>
  );
};

export default DownloadContainer;

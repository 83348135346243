import React, { useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Button, Collapse, Row, Col } from "reactstrap";
import ModalConfirmation from "../../../components/Modal/ModalConfirmation";
import { TextAreaInput } from "../../../components/GenericInputsFormik/index";
import { useEffect } from "react";
import { peticionesReceiver } from "../../../helpers/peticionesReceiver";
import { toast } from "react-toastify";
import Notification from "../../../components/Notification/Notification";
import Loader from "../../../components/Loader/Loader";
import { SelectTypeHeadSingle } from "../../../components/GenericInputsFormik/index";
import { PERIOD } from "../../../helpers/regex";
import { YearInput } from "../../../components/GenericInputsFormik/YearInput";
import { TextInput } from "../../../components/GenericInputsFormik/index";

const FormCalendar = ({
  permissions,
  setRefreshP,
  calendar,
  setCollapse,
}) => {
  const [modalConfirmation, setModalConfirmation] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [loader, setLoader] = useState(true);
  const API = peticionesReceiver();

  const FormSchema = Yup.object().shape({
    period: Yup.number()
      .max(24, "Periodo debe ser menor a 25")
      .min(1, "Periodo debe ser mayor a 0")
      .required("Seleccione un periodo"),
    year: Yup.string().required("Seleccione un año"),
    startDate: Yup.date().required("Seleccione una fecha de inicio"),
    endDate: Yup.date()
      .required("Seleccione una fecha de termino")
      .min(
        Yup.ref("startDate"),
        ({ min }) => "La fecha de temrion debe ser mayor a la fecha de inicio"
      ),
  });

  const {
    handleSubmit,
    values,
    errors,
    setFieldValue,
    touched,
    handleBlur,
    resetForm,
  } = useFormik({
    initialValues: {
      period: "",
      year: "",
      startDate: "",
      endDate: "",
    },
    onSubmit: () => {
      setModalConfirmation(true);
    },
    validationSchema: FormSchema,
    enableReinitialize: true,
  });

  const onChange = (e) => {
    const { name, value } = e.target;
    setFieldValue(name, value);
  };

  const sendInformation = async () => {
    const data = {
      action: isEdit ? "update" : "insert",
      table: "paymentcalendar",
      rows: {
        idCalendar: values.idCalendar,
        period: values.period,
        year: values.year.getFullYear(),
        startDate: values.startDate,
        endDate: values.endDate,
      },
    };

    if (isEdit)
      data.condition = {
        idCalendar: values.idCalendar,
      };
    const res = await API.peticion(data);
    if (res.status === 200 && res.data.status === "success") {
      toast(
        <Notification
          type={"agrega_exito"}
          backMessage={res.data.message}
          withIcon
        />
      );
      /* setCollapse("home"); */
      setRefreshP(true);
      setCollapse("home");
    } else {
      toast(
        <Notification
          type={"agrega_error"}
          backMessage={res.data.message}
          withIcon
        />
      );
    }
  };

  useEffect(() => {
    if (calendar) {
      setDataForEdit();
    }
    setLoader(false);
  }, []);

  const setDataForEdit = () => {
    setIsEdit(true);
    setFieldValue("period", calendar.period);
    setFieldValue("year", new Date(+calendar.year, 0, 1));
    setFieldValue("startDate", calendar.startDate);
    setFieldValue("endDate", calendar.endDate);
    setFieldValue("idCalendar", calendar.idCalendar);
  };

  return (
    <div>
      {!loader ? (
        <>
          <form onSubmit={handleSubmit}>
            <div className="text-center">
              <h2>Movimientos de calendario</h2>
            </div>

            <Row className="d-flex justify-content-around ">
              <Col xs={12} lg={5}>
                <SelectTypeHeadSingle
                  label="Periodo"
                  isRequired={true}
                  optionsArray={PERIOD}
                  inputName="period"
                  onChangeMethod={onChange}
                  onBlurMethod={handleBlur}
                  touched={touched.period}
                  errors={errors.period}
                  optionValue="value"
                  optionName="label"
                  value={values.period}
                  defaultOption="Seleccione un periodo"
                />
              </Col>
              <Col xs={12} lg={5}>
                <YearInput
                  label="Año"
                  inputName="year"
                  onChangeMethod={onChange}
                  onBlurMethod={handleBlur}
                  value={values.year}
                  isRequired
                  touched={touched.year}
                  errors={errors.year}
                  isMin={new Date("2020")}
                  isMax={new Date("2500")}
                />
              </Col>
            </Row>

            <Row className="d-flex justify-content-around">
              <Col xs={12} md={12} lg={5}>
                <TextInput
                  label="Fecha de inicio"
                  inputType="date"
                  inputName="startDate"
                  onChangeMethod={onChange}
                  onBlurMethod={handleBlur}
                  value={values.startDate}
                  isRequired={true}
                  touched={touched.startDate}
                  errors={errors.startDate}
                />
              </Col>
              <Col xs={12} md={12} lg={5}>
                <TextInput
                  label="Fecha de termino"
                  inputType="date"
                  inputName="endDate"
                  onChangeMethod={onChange}
                  onBlurMethod={handleBlur}
                  value={values.endDate}
                  isRequired={true}
                  touched={touched.endDate}
                  errors={errors.endDate}
                  isMin={values.startDate}
                />
              </Col>
            </Row>

            <div className="d-flex justify-content-around">
              {isEdit
                ? permissions.UPD && (
                    <div>
                      <Button type="submit" color="success">
                        Editar
                      </Button>
                    </div>
                  )
                : permissions.INS && (
                    <div>
                      <Button type="submit" color="success">
                        Agregar
                      </Button>
                    </div>
                  )}
            </div>
          </form>

          <ModalConfirmation
            modalTitle={isEdit ? "Editar" : "Crear"}
            modal={modalConfirmation}
            setModal={setModalConfirmation}
            crear={sendInformation}
            editar={sendInformation}
            isEdit={false}
            values={values}
          >
            <div className="text-center">
              Esta seguro de continuar con el registro
            </div>
          </ModalConfirmation>
        </>
      ) : (
        <div>
          <Loader />
        </div>
      )}
    </div>
  );
};

export default FormCalendar;

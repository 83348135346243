import React, { useState, useEffect } from "react";
import { Col, Button, Alert } from "reactstrap";
import Widget from "../../../components/Widget/Widget";
import { toast } from "react-toastify";
import Loader from "../../../components/Loader/Loader";
import { useFormik } from "formik";
import * as Yup from "yup";
import TableComponentPersonalNoEdit from "../../../components/TableComponent/TableComponentPersonalNoEdit";
import Notification from "../../../components/Notification/Notification";
import { Collapse, Row } from "reactstrap";
import { peticionesReceiver } from "../../../helpers/peticionesReceiver";
import ModalConfirmation from "../../../components/Modal/ModalConfirmation";
import { peticionPersonal } from "../../../helpers/peticionesPersonal";
import axios from "axios";
import {
  SelectTypeHeadSingle,
  TextInput,
} from "../../../components/GenericInputsFormik/index";
import ModalTesseract from "./ModalTesseract";

const PersonalCuentas = ({ idStaff, permissions }) => {
  const [modal, setModal] = useState(false);
  const API = peticionesReceiver();
  const [accountTypes, setAccountType] = useState([]);
  const [loadingAccountTypes, setLoadingAccountTypes] = useState(true);
  const [paymentTypes, setPaymentTypes] = useState([]);
  const [loadingPaymentTypes, setLoadingPaymentTypes] = useState(true);
  const [banks, setBanks] = useState([]);
  const [loadingBanks, setLoadingBanks] = useState(true);
  const [editando, setEditando] = useState(false);

  const [idCheque, setIdCheque] = useState(0);
  const [idCuenta, setIdCuenta] = useState("");
  const [idBank, setIdBank] = useState("");

  const [modalCuenta, setModalCuenta] = useState(false);

  const [collapse, setCollapse] = useState(false);
  const toggle = () => setCollapse(!collapse);

  const [fileUp, setFileUp] = useState(null);

  const select = {
    action: "datatable",
    table:
      "staffaccounts A LEFT JOIN accounttypes B on (A.keyAccountType = B.keyAccountType) LEFT JOIN paymenttypes C on (A.keyPaymentType = C.keyPaymentType)   INNER JOIN banks D on (A.keyBank = D.keyBank)",
    rows: "A.idStaffAccount,ifnull(A.account,'S/A') as cuenta,ifnull(A.switfCode,'S/A'),ifnull(B.nameAccountType,'S/A'),C.namePaymentType,D.nameBank,IF(A.enabled=1,'SI','NO') as enabled,A.idStaffAccount,A.keyPaymentType,A.keyBank,A.keyAccountType",
    conditions: `idStaff=${idStaff}`,
    page: 0,
    records: 5,
    search: "",
    order: "A.created desc",
  };

  const [parametros, setParametros] = useState(select);

  const [account, setAccount] = useState({
    idStaffAccount: "",
    idStaff: idStaff,
    keyAccountType: "",
    keyPaymentType: "",
    keyBank: "",
    account: "",
    switfCode: "",
    file: "",
  });

  const [cabeceras] = useState([
    "Id",
    "Cuenta",
    "Codigo",
    "Tipo de cuenta",
    "Tipo de pago",
    "Banco",
    "Vigente",
  ]);
  const [filtro] = useState([
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    false,
    false,
  ]);

  const limpia = (reset) => {
    setAccount({
      idStaffAccount: "",
      idStaff: idStaff,
      keyAccountType: "",
      keyPaymentType: "",
      keyBank: "",
      account: "",
      switfCode: "",
    });
    setEditando(false);
    setCollapse(false);
    setFileUp(null);
  };

  const getAccountTypes = async () => {
    const data = {
      action: "select",
      table: "accounttypes",
      order: "nameAccountType ASC",
    };
    API.peticion(data)
      .then((res) => {
        if (res.status === 200 && res.data.status === "success") {
          const datos = res.data.data.map((element) => {
            if (element["nameAccountType"].toString() === "CLABE") {
              setIdCuenta(parseInt(element["keyAccountType"]));
            }
            return {
              value: element["keyAccountType"],
              name: element["nameAccountType"],
            };
          });
          setAccountType(datos);
          setLoadingAccountTypes(false);
        } else {
          toast(
            <Alert color="danger">Error al consultar tipos de contactos</Alert>
          );
        }
      })
      .catch((err) => {
        toast(<Notification type={"consultar_servidor_error"} withIcon />);
      });
  };

  const getBanks = async () => {
    const data = {
      action: "select",
      table: "banks",
      order: "nameBank ASC",
    };
    API.peticion(data)
      .then((res) => {
        if (res.status === 200 && res.data.status === "success") {
          const datos = res.data.data.map((element) => {
            if (element.nameBank === "BBVA BANCOMER") {
              setIdBank(element.keyBank);
            }
            return { value: element["keyBank"], name: element["nameBank"] };
          });
          setBanks(datos);
          setLoadingBanks(false);
        } else {
          toast(
            <Alert color="danger">Error al consultar tipos de contactos</Alert>
          );
        }
      })
      .catch((err) => {
        toast(<Notification type={"consultar_servidor_error"} withIcon />);
      });
  };

  const getPaymentTypes = async () => {
    const data = {
      action: "select",
      table: "paymenttypes",
      order: "namePaymentType ASC",
    };
    API.peticion(data)
      .then((res) => {
        if (res.status === 200 && res.data.status === "success") {
          const datos = res.data.data.map((element) => {
            if (element["namePaymentType"].toString() === "Cheque") {
              setIdCheque(Number(element["keyPaymentType"]));
            }
            return {
              value: element["keyPaymentType"],
              name: element["namePaymentType"],
            };
          });
          setPaymentTypes(datos);
          setLoadingPaymentTypes(false);
        } else {
          toast(
            <Alert color="danger">Error al consultar tipos de contactos</Alert>
          );
        }
      })
      .catch((err) => {
        toast(<Notification type={"consultar_servidor_error"} withIcon />);
      });
  };

  useEffect(() => {
    getAccountTypes();
    getPaymentTypes();
    getBanks();
  }, []);

  const submitFunction = (fields) => {
    setModal(true);
  };

  const FormSchema = Yup.object().shape({
    keyPaymentType: Yup.string().required("Seleccione un tipo de pago"),

    keyAccountType: Yup.string().when("keyPaymentType", {
      is: idCheque.toString(),
      otherwise: Yup.string().required("Ingrese una cuenta"),
    }),
    keyBank: Yup.string().required("Seleccione un banco"),

    account: Yup.string()
      .when("keyPaymentType", {
        is: idCheque.toString(),
        otherwise: Yup.string().when("keyAccountType", {
          is: idCuenta.toString(),
          otherwise: Yup.string().required("Ingrese una cuenta"),
        }),
      })
      .matches(/^\d+$/, "Ingrese solamente numeros")
      .min(10, "Minimo son 10 digitos")
      .max(16, "Ingrese maximo 16 digitos"),

    switfCode: Yup.string().when("keyPaymentType", {
      is: idCheque.toString(),
      otherwise: Yup.string()
        .when("keyAccountType", {
          is: idCuenta.toString(),
          then: Yup.string().required("Ingrese una cuenta"),
        })
        .matches(/^\d+$/, "Ingrese solamente numeros")
        .min(18, "La clave debe de contener 18 digitos")
        .max(18, "La clave debe de contener 18 digitos"),
    }),
    // file: Yup.mixed().when("keyPaymentType", {
    //   is: idCheque.toString(),
    //   otherwise: Yup.string()
    //     .test("file-type", "El documento debe ser de tipo pdf", () => {
    //       const files = ["application/pdf"]
    //       return fileUp && files.includes(fileUp?.type);
    //     })
    //     .test({
    //       message: `El documento debe pesar menos de 4MB`,
    //       test: () => fileUp?.size < 4000000,
    //     })
    // })
  });

  /**
   * Formik const
   */
  const {
    handleSubmit,
    handleBlur,
    values,
    errors,
    touched,
    setFieldValue,
    resetForm,
  } = useFormik({
    initialValues: account,
    onSubmit: (values) => {
      submitFunction(values);
    },
    validationSchema: FormSchema,
    enableReinitialize: true,
  });

  const modoEdicion = async (account) => {
    if (!collapse) {
      setAccount({
        idStaffAccount: account[0],
        idStaff: idStaff,
        keyAccountType: account[8],
        keyPaymentType: account[6],
        keyBank: account[7],
        account: account[1] === "S/A" ? "" : account[1],
        switfCode: account[2] === "S/A" ? "" : account[2],
      });
      setEditando(true);
      setCollapse(true);
    } else {
      toast(
        <Notification
          type="warning"
          backMessage="Guarde sus cambios antes de continuar"
        />
      );
    }
  };

  const agregar = async (account, reset) => {
    if (account.keyAccountType === 1) {
      const params = {
        action: "verifyAccount",
        clabe: account.switfCode,
      };
      const res = await API.peticionEndPoint(
        params,
        "app/facades/accounts/accountsF.php"
      );
      if (res.status !== 200 || res.data.code !== "200") {
        toast(
          <Notification
            type={"agrega_error"}
            backMessage={res.data.message}
            withIcon
          />
        );
        return;
      }
    }
    if (account.keyAccountType === 1) {
      account.account = "";
    } else {
      account.switfCode = "";
    }
    const data = {
      action: "saveStaffAccounts",
      table: "staffaccounts",
      rows: account,
    };
    const res = await peticionPersonal(data);
    if (res.status === 200 && res.data.status === "success") {
      toast(
        <Notification
          type={"agrega_exito"}
          backMessage={res.data.message}
          withIcon
        />
      );
      if (fileUp !== null) {
        await uploadFile(account.idStaff, fileUp);
      }
      setParametros(select);
      limpia();
      reset();
      setCollapse(false);
    } else {
      toast(
        <Notification
          type={"agrega_error"}
          backMessage={res.data.message}
          withIcon
        />
      );
    }
  };

  const editar = async (account, reset) => {
    if (account.keyAccountType === 1) {
      const params = {
        action: "verifyAccount",
        clabe: account.switfCode,
      };
      const res = await API.peticionEndPoint(
        params,
        "app/facades/accounts/accountsF.php"
      );
      if (res.status !== 200 || res.data.code !== "200") {
        toast(
          <Notification
            type={"agrega_error"}
            backMessage={res.data.message}
            withIcon
          />
        );
        return;
      }
    }
    if (account.keyAccountType === 1) {
      account.account = "";
    } else {
      account.switfCode = "";
    }
    const data = {
      action: "saveStaffAccounts",
      table: "staffaccounts",
      rows: account,
      condition: {
        idStaffAccount: account.idStaffAccount,
      },
    };
    const res = await peticionPersonal(data);
    if (res.status === 200 && res.data.status === "success") {
      toast(
        <Notification
          type={"modifica_exito"}
          backMessage={res.data.message}
          withIcon
        />
      );
      if (fileUp !== null) {
        await uploadFile(account.idStaff, fileUp);
      }
      setParametros(select);
      limpia();
      reset();
      setCollapse(false);
    } else {
      toast(
        <Notification
          type={"modifica_error"}
          backMessage={res.data.message}
          withIcon
        />
      );
    }
  };

  const uploadFile = async (reference, file) => {
    const formData = new FormData();
    formData.append("action", "saveFileReplace");
    formData.append("reference", reference);
    formData.append("keyFileType", 54);
    formData.append("binary", file);
    const params = {
      method: "post",
      url: "https://pruebas.sapptesting.click/sapp/back/app/facades/files/fileReceiver.php",
      data: formData,
    };
    try {
      const res = await axios(params);
      if (res.data.code === "200" && res.data.status === "success") {
        toast(
          <Notification
            type={"agrega_exito"}
            backMessage={"Estado de cuenta cargado con exito"}
            withIcon
          />
        );
        return true;
      } else {
        toast(<Notification type={"agrega_error"} withIcon />);
        return false;
      }
    } catch (err) {
      toast(<Notification type={"agrega_error"} withIcon />);
      return false;
    }
  };

  const onChange = (e) => {
    const { name, value } = e.target;
    setFieldValue([name], value);
  };

  function getData(data, formVals) {
    if (data.banco !== "" && data.noCuenta !== "" && data.clabe !== "") {
      const rows = {
        ...formVals,
      };
      const bank = banks.find((element) => element.name === data.banco);
      if (bank) {
        rows.keyBank = bank.value;
      }
      if (Number(rows.keyBank) !== 2) {
        rows.keyAccountType = 1;
      }
      rows.switfCode = data.clabe;
      rows.account = data.noCuenta;
      setFileUp(data.file);
      setAccount(rows);
      setModalCuenta(false);
    } else {
      toast(
        <Notification type="warning" backMessage="Error al obtener los datos" />
      );
    }
  }

  useEffect(() => {
    if (values.keyPaymentType === idCheque) {
      const vals = { ...values };
      resetForm();
      setFieldValue("keyPaymentType", vals.keyPaymentType);
      const find = accountTypes.find((val) => val.value !== idCuenta);
      if (find) {
        setFieldValue("keyAccountType", find.value);
      }
    } else {
      const vals = { ...values };
      resetForm();
      setFieldValue("keyPaymentType", vals.keyPaymentType);
      setFieldValue("keyAccountType", idCuenta);
    }
  }, [values.keyPaymentType]);

  useEffect(() => {
    const vals = { ...values };
    resetForm();
    setFieldValue("keyPaymentType", vals.keyPaymentType);
    setFieldValue("keyBank", vals.keyBank);
    if (vals.keyPaymentType === idCheque || Number(vals.keyBank) === idBank) {
      const find = accountTypes.find((val) => val.value !== idCuenta);
      if (find) {
        setFieldValue("keyAccountType", find.value);
      }
    } else {
      setFieldValue("keyAccountType", idCuenta);
    }
  }, [values.keyBank]);

  useEffect(() => {
    const vals = { ...values };
    resetForm();
    setFieldValue("keyPaymentType", vals.keyPaymentType);
    setFieldValue("keyBank", vals.keyBank);
    setFieldValue("keyAccountType", vals.keyAccountType);
  }, [values.keyAccountType]);

  return (
    <>
      {!loadingAccountTypes && !loadingPaymentTypes && !loadingBanks ? (
        <Widget className="widget-p-md">
          <TableComponentPersonalNoEdit
            titulo="Cuentas"
            cabecerasTabla={cabeceras}
            filtro={filtro}
            editar={modoEdicion}
            parametros={parametros}
            limpia={limpia}
            reset={resetForm}
          />
          <form onSubmit={handleSubmit}>
            <ModalConfirmation
              modalTitle={editando ? "Editar" : "Crear"}
              modal={modal}
              setModal={setModal}
              crear={agregar}
              editar={editar}
              isEdit={editando}
              values={values}
              reset={resetForm}
            >
              {editando ? (
                <div className="d-flex justify-content-center">
                  <h6>¿Está seguro de editar el registro?</h6>
                </div>
              ) : (
                <div className="d-flex justify-content-center">
                  <h6>¿Desea continuar con el registro?</h6>
                </div>
              )}
            </ModalConfirmation>

            {permissions.INS && (
              <div className="d-grid d-flex justify-content-center justify-content-sm-end">
                <Button
                  color="add"
                  onClick={toggle}
                  style={{ marginBottom: "1rem" }}
                  disabled={collapse}
                >
                  Agregar
                </Button>
              </div>
            )}

            <Collapse isOpen={collapse}>
              <div>
                <div className="headline-1 text-center">Cuenta</div>
                <Col className="d-grid d-flex justify-content-sm-end justify-content-center">
                  <h6 className=" text-center">
                    Estado:{" "}
                    <span className="text-primary">
                      {editando ? "Editando" : "Guardando"}{" "}
                    </span>{" "}
                  </h6>
                </Col>

                <Row className="d-flex justify-content-around">
                  <Col xs={12} md={12} lg={5}>
                    <SelectTypeHeadSingle
                      label="Tipo de pago"
                      isRequired={true}
                      inputName="keyPaymentType"
                      optionsArray={paymentTypes}
                      defaultOption="Tipo de pago"
                      onChangeMethod={(event) =>
                        setFieldValue([event.target.name], event.target.value)
                      }
                      onBlur={handleBlur}
                      isDisabled={false}
                      value={values.keyPaymentType}
                      touched={touched.keyPaymentType}
                      errors={errors.keyPaymentType}
                      optionValue="value"
                      optionName="name"
                    />
                  </Col>
                </Row>
                <Row className="d-flex justify-content-center">
                  {values.keyPaymentType !== idCheque && (
                    <Col xs={12} md={12} lg={5}>
                      <div className="form-group">
                        <Button
                          type="button"
                          onClick={() => setModalCuenta(true)}
                          onBlur={handleBlur}
                          className="mt-4 btn-block"
                        >
                          Cargar de estado de cuenta
                        </Button>
                        {touched.file && errors.file && (
                          <span style={{ color: "red" }}>{errors.file}</span>
                        )}
                      </div>
                    </Col>
                  )}
                </Row>

                <Row className="d-flex justify-content-around mt-3">
                  <Col xs={12} md={12} lg={5}>
                    <SelectTypeHeadSingle
                      label="Banco"
                      isRequired={true}
                      inputName="keyBank"
                      optionsArray={banks}
                      defaultOption="Seleccione un banco"
                      onChangeMethod={onChange}
                      onBlur={handleBlur}
                      isDisabled={false}
                      value={values.keyBank}
                      touched={touched.keyBank}
                      errors={errors.keyBank}
                      optionValue="value"
                      optionName="name"
                    />
                  </Col>
                  <Col xs={12} md={12} lg={5}>
                    <SelectTypeHeadSingle
                      label="Tipo de cuenta"
                      isRequired={true}
                      inputName="keyAccountType"
                      optionsArray={accountTypes}
                      defaultOption="Tipo de cuenta"
                      onChangeMethod={(event) =>
                        setFieldValue([event.target.name], event.target.value)
                      }
                      onBlur={handleBlur}
                      isDisabled={
                        Number(values.keyBank) !== "" ||
                        Number(idCheque) === values.keyPaymentType
                      }
                      value={values.keyAccountType}
                      touched={touched.keyAccountType}
                      errors={errors.keyAccountType}
                      optionValue="value"
                      optionName="name"
                    />
                  </Col>
                </Row>

                <Row className="d-flex justify-content-around mt-3">
                  <Col xs={12} md={12} lg={5}>
                    <TextInput
                      inputName="account"
                      errors={errors.account}
                      isDisabled={Number(idCuenta) === values.keyAccountType}
                      isRequired={Number(idCuenta) !== values.keyAccountType}
                      onBlurMethod={handleBlur}
                      value={values.account}
                      label="Cuenta"
                      touched={touched.account}
                      onChangeMethod={onChange}
                    />
                  </Col>

                  <Col xs={12} md={12} lg={5}>
                    <TextInput
                      inputName="switfCode"
                      errors={errors.switfCode}
                      isDisabled={
                        Number(idCheque) === values.keyPaymentType ||
                        Number(idCuenta) !== values.keyAccountType
                      }
                      isRequired={
                        Number(idCheque) !== values.keyPaymentType ||
                        Number(idCuenta) === values.keyAccountType
                      }
                      onBlurMethod={handleBlur}
                      value={values.switfCode}
                      label="CLABE interbancaria"
                      touched={touched.switfCode}
                      onChangeMethod={onChange}
                    />
                  </Col>
                </Row>

                <div className="row mt-3">
                  <div className="col-sm-6 order-2 order-sm-1 text-center text-sm-left mb-4">
                    <Button
                      color="danger"
                      type="reset"
                      onClick={() => limpia(resetForm)}
                    >
                      Cancelar
                    </Button>
                  </div>
                  <div className="col-sm-6 order-1 order-sm-2 text-center text-sm-right mb-4">
                    <Button color="success" type="submit">
                      Guardar
                    </Button>
                  </div>
                </div>
              </div>
            </Collapse>
            <ModalTesseract
              setVisible={setModalCuenta}
              visible={modalCuenta}
              getData={getData}
              formVals={values}
            />
          </form>
        </Widget>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default PersonalCuentas;

import axios from "axios";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import Notification from "../../../components/Notification/Notification";
import Loader from "../../../components/Loader/Loader";
import React, { useState, useEffect } from "react";
import TableShowComponent from "../permisos/TableShowComponent";
import { Row, Col, Table, Button } from "reactstrap";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import Widget from "../../../components/Widget/Widget";
import { peticionesReceiver } from "../../../helpers/peticionesReceiver";
import ModalConfirmation from "../../../components/Modal/ModalConfirmation";
import { GetPermissions } from "../../../libs/permissions/getPermissions";
import { SelectTypeHeadSingle } from "../../../components/GenericInputsFormik";

const AsignaPermisos = () => {
  const [permissions, setPermissions] = useState({});
  GetPermissions(setPermissions);

  const API = peticionesReceiver();
  const history = useHistory();
  const [usuario] = localStorage.getItem("dataContent").split(",");
  const [areas, setAreas] = useState();
  const [loadingAreas, setLoadingAreas] = useState(true);
  const [profiles, setProfiles] = useState();
  const [loadingProfile, setLoadingProfile] = useState(true);
  const [content, setContent] = useState();
  const [loadingPermissions, setLoadingPermissions] = useState(true);
  const [modal, setModal] = useState(false);

  const getAreas = async () => {
    const data = {
      action: "select",
      table: "areas",
      order: "name ASC",
    };
    API.peticion(data)
      .then((res) => {
        if (res.status === 200 && res.data.code === "200") {
          setAreas(res.data.data);
        } else {
          toast(<Notification type={"consultar_error"} withIcon />);
        }
        setLoadingAreas(false);
      })
      .catch((err) => {
        toast(<Notification type={"consultar_servidor_error"} withIcon />);
        setLoadingAreas(false);
      });
  };

  const getProfile = async () => {
    const data = {
      action: "select",
      table: "profiles",
      order: "nameProfile ASC",
    };
    API.peticion(data)
      .then((res) => {
        if (res.status === 200 && res.data.code === "200") {
          setProfiles(res.data.data);
        } else {
          toast(<Notification type={"consultar_error"} withIcon />);
        }
        setLoadingProfile(false);
      })
      .catch((err) => {
        toast(<Notification type={"consultar_servidor_error"} withIcon />);
        setLoadingProfile(false);
      });
  };

  const getPermissions = async () => {
    const data = {
      action: "multiselect",
      table:
        "areas A INNER JOIN permissions B USING (idArea) INNER JOIN profiles C USING (keyProfile) ",
      rows: "B.idPermission,A.idArea,A.name,B.idUser,C.keyProfile,C.nameProfile",
      conditions:
        "A.enabled = 1 AND B.enabled = 1 AND C.enabled = 1 AND B.idUser =" +
        usuario,
      order: "A.name asc",
    };
    await axios;
    API.peticion(data)
      .then((res) => {
        if (res.status === 200 && res.data.code === "200") {
          const datos = [];
          res.data.data.map((element) => {
            datos.push([
              element["idPermission"],
              element["idArea"],
              element["name"],
              element["idUser"],
              element["keyProfile"],
              element["nameProfile"],
            ]);
          });
          setContent(datos);
        } else {
          toast(<Notification type={"consultar_error"} withIcon />);
          setContent([]);
        }
        setLoadingPermissions(false);
      })
      .catch((err) => {
        toast(<Notification type={"consultar_servidor_error"} withIcon />);
        setLoadingPermissions(false);
      });
  };

  const addPermisson = async (datos, reset) => {
    const data = {
      action: "insert",
      table: "permissions",
      rows: datos,
      validate: [["keyProfile", "idUser", "idArea"]],
    };
    API.peticion(data)
      .then((res) => {
        if (res.status === 200 && res.data.code === "200") {
          toast(
            <Notification
              type={"agrega_exito"}
              backMessage={res.data.message}
              withIcon
            />
          );
          getPermissions();
          reset();
        } else {
          toast(
            <Notification
              type={"agrega_error"}
              backMessage={res.data.message}
              withIcon
            />
          );
        }
      })
      .catch((err) => {
        toast(<Notification type={"consultar_servidor_error"} withIcon />);
      });
  };

  useEffect(() => {
    getAreas();
    getProfile();
    getPermissions();
  }, []);

  const submitFunction = (fields) => {
    setModal(true);
  };

  const FormSchema = Yup.object().shape({
    idArea: Yup.string().required("Seleccione una Unidad de trabajo"),
    keyProfile: Yup.string().required("seleccione un perfil"),
  });

  const [form] = useState({
    idUser: usuario,
    idArea: "",
    keyProfile: "",
  });


  return (
    <Widget className="widget-p-md">
      {!loadingAreas && !loadingProfile && !loadingPermissions ? (
        <Formik
          initialValues={form}
          validationSchema={FormSchema}
          onSubmit={(fields) => {
            submitFunction(fields);
          }}
        >
          {({ errors, touched, values, handleBlur, handleReset, setFieldValue }) => (
            <div>
              {permissions.INS &&
                <Form className="mt-4">
                  <ModalConfirmation
                    modalTitle={"Asignar permiso"}
                    modal={modal}
                    setModal={setModal}
                    crear={addPermisson}
                    values={values}
                    reset={handleReset}
                  >
                    <div className="d-flex justify-content-center">
                      <h6>¿Está seguro de asignar este permiso?</h6>
                    </div>
                  </ModalConfirmation>
                  <Row className="d-flex align-items-center justify-content-between">
                    <Col xs={12} md={5}>
                      <SelectTypeHeadSingle
                        label="Unidad de trabajo"
                        inputName="idArea"
                        onChangeMethod={(e) => setFieldValue("idArea", e.target.value) }
                        onBlurMethod={handleBlur}
                        value={values.idArea}
                        isRequired={true}
                        touched={touched.idArea}
                        errors={errors.idArea}
                        optionsArray={areas}
                        optionValue="idArea"
                        optionName="name"
                        defaultOption="Seleccione una Unidad de trabajo"
                      />
                    </Col>
                    <Col xs={12} md={5}>
                        <SelectTypeHeadSingle
                          label="Perfil"
                          inputName="keyProfile"
                          onChangeMethod={(e) => setFieldValue("keyProfile", e.target.value) }
                          onBlurMethod={handleBlur}
                          value={values.keyProfile}
                          isRequired={true}
                          touched={touched.keyProfile}
                          errors={errors.keyProfile}
                          optionsArray={profiles}
                          optionValue="keyProfile"
                          optionName="nameProfile"
                          defaultOption="Seleccione un perfil"
                        />
                    </Col>
                    <Col xs={12} md={2} className="mt-4 text-center">
                      <Button color="add" type="submit">
                        Agregar
                      </Button>
                    </Col>
                  </Row>
                </Form>
              }
              {content === undefined || content === null ? (
                <h1 className="mt-5 text-center">
                  No hay permisos registrados
                </h1>
              ) : (
                <TableShowComponent
                  titulo="Permisos"
                  cabecerasTabla={["Unidad de trabajo", "Perfil", "Eliminar"]}
                  contenido={content}
                  filtro={[false, false, true, false, false, true]}
                  rutaVer="/template/usuarios-permisos/permisos"
                  getPermissions={getPermissions}
                  permissionsAuth = {permissions}
                ></TableShowComponent>
              )}
              <div className="d-flex justify-content-start mt-5">
                <Button
                  color="danger"
                  type="button"
                  onClick={() => history.push("/template/usuarios-permisos")}
                >
                  Regresar
                </Button>
              </div>
            </div>
          )}
        </Formik>
      ) : (
        <div className="my-5 py-5">
          <Loader />
        </div>
      )}
    </Widget>
  );
};
export default AsignaPermisos;

import React, { useState, useEffect } from "react";
import { Button, Col, Collapse, Row } from "reactstrap";
import { peticionEncript } from "../../../helpers/peticionesEncripted";
import axios from "axios";
import { toast } from "react-toastify";
import Notification from "../../../components/Notification/Notification";
import { SelectTypeHeadSingle } from "../../../components/GenericInputsFormik";
import * as Yup from "yup";
import { useFormik } from "formik";
import { NumberInput } from "../../../components/GenericInputsFormik/NumberInput";
import FullFormLoader from "../../../components/Loader/FullFormLoader";
import ModalConfirmation from "../../../components/Modal/ModalConfirmation";

/* Formualrio */
const defaultForm = {
  idActivity: "",
  idExpenseObject: "",
  ///meses
  m1: 0,
  m2: 0,
  m3: 0,
  m4: 0,
  m5: 0,
  m6: 0,
  m7: 0,
  m8: 0,
  m9: 0,
  m10: 0,
  m11: 0,
  m12: 0,
};

const FormSchema = Yup.object().shape({
  idExpenseObject: Yup.string().required("Seleccione una partida presupuestal"),
  m1: Yup.number().required("Ingrese una cantidad").min(0, "Cantidad invalida"),
  m2: Yup.number().required("Ingrese una cantidad").min(0, "Cantidad invalida"),
  m3: Yup.number().required("Ingrese una cantidad").min(0, "Cantidad invalida"),
  m4: Yup.number().required("Ingrese una cantidad").min(0, "Cantidad invalida"),
  m5: Yup.number().required("Ingrese una cantidad").min(0, "Cantidad invalida"),
  m6: Yup.number().required("Ingrese una cantidad").min(0, "Cantidad invalida"),
  m7: Yup.number().required("Ingrese una cantidad").min(0, "Cantidad invalida"),
  m8: Yup.number().required("Ingrese una cantidad").min(0, "Cantidad invalida"),
  m9: Yup.number().required("Ingrese una cantidad").min(0, "Cantidad invalida"),
  m10: Yup.number()
    .required("Ingrese una cantidad")
    .min(0, "Cantidad invalida"),
  m11: Yup.number()
    .required("Ingrese una cantidad")
    .min(0, "Cantidad invalida"),
  m12: Yup.number()
    .required("Ingrese una cantidad")
    .min(0, "Cantidad invalida"),
});

async function doRequest(route, params) {
  let info = [];
  const dataEncrypted = peticionEncript(params);
  await axios
    .post(process.env.REACT_APP_API + route, dataEncrypted)
    .then((res) => {
      if (res.status === 200 && res.data.status === "success") {
        info = res.data;
        toast(
          <Notification
            type={"elimina_exito"}
            backMessage={
              res.data.message === "EXITO "
                ? "Costo registrado"
                : res.data.message
            }
            withIcon
          />,
          {
            closeButton: false,
          }
        );
      } else {
        toast(
          <Notification
            type={"consultar_error"}
            backMessage={res.data.message}
            withIcon
          />,
          { closeButton: false }
        );
      }
    })
    .catch((err) => {
      toast(<Notification type={"consultar_servidor_error"} withIcon />, {
        closeButton: false,
      });
    });
  return info;
}

const defaultBlocked = [true, true, true, true, true, true, true, true, true, true, true, true];
const AsignarCosto = ({
  activityId,
  partidas,
  usedPartidas,
  setShowForm,
  openAct,
  showForm,
  idMap,
  data,
  canEdit,
  mesesOK
}) => {
  const [title, setTitle] = useState("Agregar costo");
  const [loadingForm, setLoadingForm] = useState(false);
  const [modalConfirm, setModalConfirm] = useState(false);
  const [partidasClean, setPartidasClean] = useState([]);
  const [partidaDis, setPartidaDis] = useState(false);
  const [afterValues, setAfterValues] = useState([]); /// Hook para guardar los valores anteriores del mes
  const [blocked, setBlocked ] = useState(defaultBlocked); /// Hook para bloquear meses
  const formSection = React.useRef(null);
  const refSelect = React.useRef(null);

  const {
    handleSubmit,
    values,
    handleBlur,
    errors,
    touched,
    setFieldValue,
    handleReset,
  } = useFormik({
    initialValues: { ...defaultForm, idActivity: activityId },
    validationSchema: FormSchema,
    onSubmit: (values) => setModalConfirm(true),
    onReset: () => {
      setShowForm("");
      setTitle("Agregar costo");
      setPartidaDis(false);
      refSelect.current.clear();
    },
    enableReinitialize: true,
  });

  useEffect(() => {
    if (data !== "") {
      setTitle(canEdit ? "Editar costo" : "Consultar costo");
      setFieldValue("m1", +data.m1);
      setFieldValue("m2", +data.m2);
      setFieldValue("m3", +data.m3);
      setFieldValue("m4", +data.m4);
      setFieldValue("m5", +data.m5);
      setFieldValue("m6", +data.m6);
      setFieldValue("m7", +data.m7);
      setFieldValue("m8", +data.m8);
      setFieldValue("m9", +data.m9);
      setFieldValue("m10", +data.m10);
      setFieldValue("m11", +data.m11);
      setFieldValue("m12", +data.m12);
      setFieldValue("idExpenseObject", data.idExpenseObject);
      setAfterValues([
        +data.m1,
        +data.m2,
        +data.m3,
        +data.m7,
        +data.m5,
        +data.m6,
        +data.m7,
        +data.m8,
        +data.m9,
        +data.m10,
        +data.m11,
        +data.m12,
        data.idExpenseObject,
      ]);
      setPartidaDis(true);
    }
  }, [data]);

  useEffect(()=>{
    if(showForm !== "" && data === ""){
      const parts = partidas.filter(el => !usedPartidas.includes(+el.idExpenseObject));
      setPartidasClean(parts);
    }else{
      setPartidasClean(partidas);
    }
  },[showForm]);

  useEffect(()=>{
    if(mesesOK){
      findMoths();
    }
  }, []);

  /* Funcion para guardar/editar la información de un costo */
  const editInfo = async () => {
    setLoadingForm(true);
    const params = {
      ...values,
      action: "saveActivityCost",
    };
    const data = await doRequest("app/facades/planning/planningF.php", params);
    if (data.status === "success") handleReset();
    setLoadingForm(false);
  };

  const onChange = (e) => {
    setFieldValue(e.target.name, e.target.value);
  };

  /* Función para scrolear a un punto de la aplicación */
  const scrollTo = () => {
    formSection.current.scrollIntoView({ behavior: "smooth" });
  };

  
  const findMoths = () => {
    const meses = mesesOK.split(",");
    let copy = [...defaultBlocked];
    for(let i = 0; i < copy.length; i++){
      if(meses[i] !== "0.00"){
        copy[i] = false;
      }
    }
    setBlocked(copy);
  }

  return (
    <div style={{ backgroundColor: "#6b859e08" }}>
      <Collapse
        isOpen={openAct === idMap && showForm === idMap}
        onEntered={() => scrollTo()}
      >
        <form
          className="px-4 pt-4"
          onSubmit={handleSubmit}
          onReset={handleReset}
        >
          <h5>{title}</h5>
          <ModalConfirmation
            modalTitle="Guardar costo"
            modal={modalConfirm}
            setModal={setModalConfirm}
            editar={() => editInfo()}
            isEdit={true}
            values={values}
          >
            <div className="d-flex justify-content-center">
              <h6>¿Está seguro de guardar esta información?</h6>
            </div>
          </ModalConfirmation>

          <Row className="d-flex justify-content-center">
            <Col xs={12} lg={6}>
              <SelectTypeHeadSingle
                label="Partida Presupuestal"
                isRequired={true}
                inputName="idExpenseObject"
                optionsArray={partidasClean}
                defaultOption="Seleccione una partida presupuestal"
                onChangeMethod={onChange}
                onBlur={handleBlur}
                value={values.idExpenseObject}
                touched={touched.idExpenseObject}
                errors={errors.idExpenseObject}
                optionValue="idExpenseObject"
                optionName="nameObjectFull"
                isDisabled={partidaDis || !canEdit}
                refSelect={refSelect}
              />
            </Col>
          </Row>
          <Row className="mt-2">
            <Col xs={12} xl={4}>
              <NumberInput
                label="Enero"
                inputName="m1"
                onChangeMethod={onChange}
                onBlurMethod={handleBlur}
                value={values.m1}
                isRequired
                touched={touched.m1}
                errors={errors.m1}
                isMin={0}
                decimals
                prefix="$"
                isDisabled={!canEdit|blocked[0]}
              />
            </Col>
            <Col xs={12} xl={4}>
              <NumberInput
                label="Febrero"
                inputName="m2"
                onChangeMethod={onChange}
                onBlurMethod={handleBlur}
                value={values.m2}
                isRequired
                touched={touched.m2}
                errors={errors.m2}
                isMin={0}
                decimals
                prefix="$"
                isDisabled={!canEdit|blocked[1]}
              />
            </Col>
            <Col xs={12} xl={4}>
              <NumberInput
                label="Marzo"
                inputName="m3"
                onChangeMethod={onChange}
                onBlurMethod={handleBlur}
                value={values.m3}
                isRequired
                touched={touched.m3}
                errors={errors.m3}
                isMin={0}
                decimals
                prefix="$"
                isDisabled={!canEdit|blocked[2]}
              />
            </Col>
            <Col xs={12} xl={4}>
              <NumberInput
                label="Abril"
                inputName="m4"
                onChangeMethod={onChange}
                onBlurMethod={handleBlur}
                value={values.m4}
                isRequired
                touched={touched.m4}
                errors={errors.m4}
                isMin={0}
                decimals
                prefix="$"
                isDisabled={!canEdit|blocked[3]}
              />
            </Col>
            <Col xs={12} xl={4}>
              <NumberInput
                label="Mayo"
                inputName="m5"
                onChangeMethod={onChange}
                onBlurMethod={handleBlur}
                value={values.m5}
                isRequired
                touched={touched.m5}
                errors={errors.m5}
                isMin={0}
                decimals
                prefix="$"
                isDisabled={!canEdit|blocked[4]}
              />
            </Col>
            <Col xs={12} xl={4}>
              <NumberInput
                label="Junio"
                inputName="m6"
                onChangeMethod={onChange}
                onBlurMethod={handleBlur}
                value={values.m6}
                isRequired
                touched={touched.m6}
                errors={errors.m6}
                isMin={0}
                decimals
                prefix="$"
                isDisabled={!canEdit|blocked[5]}
              />
            </Col>
            <Col xs={12} xl={4}>
              <NumberInput
                label="Julio"
                inputName="m7"
                onChangeMethod={onChange}
                onBlurMethod={handleBlur}
                value={values.m7}
                isRequired
                touched={touched.m7}
                errors={errors.m7}
                isMin={0}
                decimals
                prefix="$"
                isDisabled={!canEdit|blocked[6]}
              />
            </Col>
            <Col xs={12} xl={4}>
              <NumberInput
                label="Agosto"
                inputName="m8"
                onChangeMethod={onChange}
                onBlurMethod={handleBlur}
                value={values.m8}
                isRequired
                touched={touched.m8}
                errors={errors.m8}
                isMin={0}
                decimals
                prefix="$"
                isDisabled={!canEdit|blocked[7]}
              />
            </Col>
            <Col xs={12} xl={4}>
              <NumberInput
                label="Septiembre"
                inputName="m9"
                onChangeMethod={onChange}
                onBlurMethod={handleBlur}
                value={values.m9}
                isRequired
                touched={touched.m9}
                errors={errors.m9}
                isMin={0}
                decimals
                prefix="$"
                isDisabled={!canEdit|blocked[8]}
              />
            </Col>
            <Col xs={12} xl={4}>
              <NumberInput
                label="Octubre"
                inputName="m10"
                onChangeMethod={onChange}
                onBlurMethod={handleBlur}
                value={values.m10}
                isRequired
                touched={touched.m10}
                errors={errors.m10}
                isMin={0}
                decimals
                prefix="$"
                isDisabled={!canEdit|blocked[9]}
              />
            </Col>
            <Col xs={12} xl={4}>
              <NumberInput
                label="Noviembre"
                inputName="m11"
                onChangeMethod={onChange}
                onBlurMethod={handleBlur}
                value={values.m11}
                isRequired
                touched={touched.m11}
                errors={errors.m11}
                isMin={0}
                decimals
                prefix="$"
                isDisabled={!canEdit|blocked[10]}
              />
            </Col>
            <Col xs={12} xl={4}>
              <NumberInput
                label="Diciembre"
                inputName="m12"
                onChangeMethod={onChange}
                onBlurMethod={handleBlur}
                value={values.m12}
                isRequired
                touched={touched.m12}
                errors={errors.m12}
                isMin={0}
                decimals
                prefix="$"
                isDisabled={!canEdit|blocked[11]}
              />
            </Col>
          </Row>

          <div className="row mt-2" ref={formSection}>
            <div className="col-sm-6 order-2 order-sm-1 text-center text-sm-left mb-4">
              <Button color="danger" type="reset">
                {canEdit ? "Cancelar" : "Cerrar"}
              </Button>
            </div>
            {canEdit && (
              <div className="col-sm-6 order-1 order-sm-2 text-center text-sm-right mb-4">
                <Button color="success" type="submit">
                  Guardar
                </Button>
              </div>
            )}
          </div>

          <FullFormLoader show={loadingForm} />
        </form>
      </Collapse>
    </div>
  );
};
export default AsignarCosto;

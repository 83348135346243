import { useEffect, useState } from "react";
import { Row, Col, Button } from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";

import { toast } from "react-toastify";
import Notification from "../../../../../components/Notification/Notification";
import { NumberInput } from "../../../../../components/GenericInputsFormik/NumberInput";
import SelectMonths from "../../filtros/Inputs/SelectMonths"
import { CurrencyInput } from "../../../../../components/GenericInputsFormik";
import { peticionesReceiver } from "../../../../../helpers/peticionesReceiver";
import ModalConfirmation from "../../../../../components/Modal/ModalConfirmation";
import Loader from "../../../../../components/Loader/Loader";
import FormEmpleados from "./FormEmpleados";
import TableEmpleados from "./TableEmpleados";

export default function FormRemunaciones({ data }) {

    const API = peticionesReceiver();

    const [modal, setModal] = useState(false)
    const [loading, setLoading] = useState(data ? true : false);
    const [disabled, setDisabled] = useState(false);
    const [params, setParams] = useState();

    const defaultForm = {
        idIsrmonthlyreport: data ? data.idIsrmonthlyreport : "",
        month: "",
        year: "",
        amountSP: "0",
        amountCR: "0",
        amountCS: "0",
        amountCRep: "0",
        amountSC: "0",
        amountGA: "0",
        amountRT: "0",
        total: "0"
    }

    async function getData(id) {
        const params = {
            action: "multiselect",
            table: "isrmonthlyreport",
            rows: "*",
            conditions: `enabled = 1 AND idIsrmonthlyreport = ${id}`
        }
        const res = await API.peticion(params)
        if (res.status === 200 && res.data.code === "200") {
            const data = res.data.data[0];
            setFieldValue(["month"], data.month);
            setFieldValue(["year"], data.year);
            setFieldValue(["amountSP"], data.amountSP);
            setFieldValue(["amountCR"], data.amountCR);
            setFieldValue(["amountCS"], data.amountCS);
            setFieldValue(["amountCRep"], data.amountCRep);
            setFieldValue(["amountSC"], data.amountSC);
            setFieldValue(["amountGA"], data.amountGA);
            setFieldValue(["amountRT"], data.amountRT);
            setFieldValue(["total"], Number(data.amountSP) + Number(data.amountCR));
            setLoading(false)
        } else {
            setTimeout(() => {
                toast(<Notification type={"consultar_error"} withIcon />);
                window.location.href = "#/template/reportes-finanzas/"
            }, 1000)
        }
    }

    useEffect(() => {
        if (data) {
            setParams({
                action: "datatable",
                table: "isrstaffreports rep inner join staff s using(idStaff) inner join isrunders isr using(keyIsrunder)",
                rows: "rep.idIsrstaffreport,isr.nameIsrunder,rep.amount,concat(s.staffNumber,' - ', s.name, ' ', s.pName, ' ', s.mName),s.rfc",
                conditions: `rep.enabled = 1 AND rep.idIsrmonthlyreport = ${data.idIsrmonthlyreport}`,
                page: "0",
                records: "5",
                search: "",
                order: ""
            })
            getData(data.idIsrmonthlyreport)
        }
    }, [])

    const FormSchema = Yup.object().shape({
        month: Yup.number()
            .required("Ingrese un mes valido"),
        year: Yup.number()
            .required("Ingrese un año valido"),
        amountSP: Yup.number()
            .required("Ingrese un monto de servicios personales"),
        amountCR: Yup.number()
            .required("Ingrese un monto de complemento de reconocimientos"),
        total: Yup.number()
            .min(0, "El total de deducciones debe ser mayor a cero"),
        amountCS: Yup.number()
            .required("Ingrese una cuota de salud"),
        amountCRep: Yup.number()
            .required("Ingrese una cuota de reparto"),
        amountSC: Yup.number()
            .required("Ingrese un monto de sistema de capitalizacion"),
        amountGA: Yup.number()
            .required("Ingrese un monto de gastos de administración"),
        amountRT: Yup.number()
            .required("Ingrese un monto de riesgos de trabajo")
    })

    const { handleSubmit, values, handleBlur, errors, touched, setFieldValue, resetForm } =
        useFormik({
            initialValues: defaultForm,
            onSubmit: () => setModal(true),
            validationSchema: FormSchema,
        });

    function sumDeduc() {
        const total = Number(values.amountSP) + Number(values.amountCR)
        setFieldValue(["total"], total);
    }

    const onChange = (e) => {
        const { name, value } = e.target
        setFieldValue([name], value);
        if (name === "amountSP" || name === "amountCR") {
            sumDeduc()
        }
    }

    const OnCreate = async (fields) => {
        const rows = JSON.parse(JSON.stringify(fields));
        delete rows.idIsrmonthlyreport;
        delete rows.total;
        const data = {
            action: "insert",
            table: "isrmonthlyreport",
            rows,
            validate: [
                ["month", "year"],
            ]
        };
        try {
            const res = await API.peticion(data);
            if (res.status === 200 && res.data.code === "200") {
                toast(<Notification type={"agrega_exito"} backMessage={res.data.message} withIcon />);
                const data = res.data.data[0];
                localStorage.setItem("calc3rem", JSON.stringify({ idIsrmonthlyreport: data.idIsrmonthlyreport }))
                window.location.href = "#template/reportes-finanzas/remuneraciones/editar";
            } else {
                toast(<Notification type={"agrega_error"} backMessage={res.data.message} withIcon />);
            }
        } catch (err) {
            console.log(err);
            toast(<Notification type={"agrega_error"} withIcon />);
        }
    }

    const OnEdit = async (fields) => {
        const rows = JSON.parse(JSON.stringify(fields));
        delete rows.total;
        const data = {
            action: "update",
            table: "isrmonthlyreport",
            rows,
            condition: {
                idIsrmonthlyreport: fields.idIsrmonthlyreport,
            },
            validate: [
                ["month", "year"],
            ]
        };
        try {
            const res = await API.peticion(data);
            if (res.status === 200 && res.data.code === "200") {
                toast(<Notification type={"modifica_exito"} backMessage={res.data.message} withIcon />);
            } else {
                toast(<Notification type={"modifica_error"} backMessage={res.data.message} withIcon />);
            }
        } catch (err) {
            console.log(err);
            toast(<Notification type={"modifica_error"} withIcon />);
        }
    }

    if (loading) {
        return (
            <Row className="align-items-center" style={{ minHeight: "200px" }}>
                <Col>
                    <Loader />
                </Col>
            </Row>
        )
    }
    return (
        <>
            <h2>Cálculo</h2>
            <form onSubmit={handleSubmit}>
                <Row className="w-75 mx-auto">
                    <Col xs={12} md={6}>
                        <SelectMonths
                            handleBlur={handleBlur}
                            touched={touched}
                            errors={errors}
                            values={values}
                            setFieldValue={setFieldValue}
                            isDisabled={data ? true : false}
                        />
                    </Col>
                    <Col xs={12} md={6}>
                        <NumberInput
                            inputName="year"
                            onBlurMethod={handleBlur}
                            onChangeMethod={onChange}
                            errors={errors.year}
                            touched={touched.year}
                            label="Año"
                            value={values.year}
                            disableSep
                            isRequired
                            isDisabled={data ? true : false}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md={4}>
                        <CurrencyInput
                            inputName="amountSP"
                            onBlurMethod={handleBlur}
                            onChangeMethod={(value, name) => onChange({ target: { name, value } })}
                            errors={errors.amountSP}
                            touched={touched.amountSP}
                            label="Servicios personales"
                            value={values.amountSP}
                            isRequired
                        />
                    </Col>
                    <Col xs={12} md={4}>
                        <CurrencyInput
                            inputName="amountCR"
                            onBlurMethod={handleBlur}
                            onChangeMethod={(value, name) => onChange({ target: { name, value } })}
                            errors={errors.amountCR}
                            touched={touched.amountCR}
                            label="Complemento de reconocimientos"
                            value={values.amountCR}
                            isRequired
                        />
                    </Col>
                    <Col xs={12} md={4}>
                        <CurrencyInput
                            inputName="total"
                            onBlurMethod={handleBlur}
                            onChangeMethod={(value, name) => onChange({ target: { name, value } })}
                            errors={errors.total}
                            touched={touched.total}
                            label="Total"
                            value={values.total}
                            isDisabled
                        />
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md={4}>
                        <CurrencyInput
                            inputName="amountCS"
                            onBlurMethod={handleBlur}
                            onChangeMethod={(value, name) => onChange({ target: { name, value } })}
                            errors={errors.amountCS}
                            touched={touched.amountCS}
                            label="Cuota de salud"
                            value={values.amountCS}
                            isRequired
                        />
                    </Col>
                    <Col xs={12} md={4}>
                        <CurrencyInput
                            inputName="amountCRep"
                            onBlurMethod={handleBlur}
                            onChangeMethod={(value, name) => onChange({ target: { name, value } })}
                            errors={errors.amountCRep}
                            touched={touched.amountCRep}
                            label="Cuota de reparto"
                            value={values.amountCRep}
                            isRequired
                        />
                    </Col>
                    <Col xs={12} md={4}>
                        <CurrencyInput
                            inputName="amountSC"
                            onBlurMethod={handleBlur}
                            onChangeMethod={(value, name) => onChange({ target: { name, value } })}
                            errors={errors.amountSC}
                            touched={touched.amountSC}
                            label="Sistema de capitalización"
                            value={values.amountSC}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md={4}>
                        <CurrencyInput
                            inputName="amountGA"
                            onBlurMethod={handleBlur}
                            onChangeMethod={(value, name) => onChange({ target: { name, value } })}
                            errors={errors.amountGA}
                            touched={touched.amountGA}
                            label="Gastos de administración"
                            value={values.amountGA}
                            isRequired
                        />
                    </Col>
                    <Col xs={12} md={4}>
                        <CurrencyInput
                            inputName="amountRT"
                            onBlurMethod={handleBlur}
                            onChangeMethod={(value, name) => onChange({ target: { name, value } })}
                            errors={errors.amountRT}
                            touched={touched.amountRT}
                            label="Riesgos de trabajo"
                            value={values.amountRT}
                            isRequired
                        />
                    </Col>
                </Row>
                <div className="text-center">
                    <Button color="danger" className="mx-2" onClick={() => window.location.href = "#/template/reportes-finanzas"}>Regresar</Button>
                    <Button color="add" className="mx-2" type="submit">{!data ? "Agregar" : "Guardar cambios"}</Button>
                </div>
            </form>
            {data ?
                <>
                    <FormEmpleados data={data} setParams={setParams} disabled={disabled} />
                    <TableEmpleados data={data} params={params} setParams={setParams} setDisabled={setDisabled} />
                </>
                : null}
            <ModalConfirmation
                modalTitle={!data ? "Agregar" : "Editar"}
                modal={modal}
                crear={(rows) => OnCreate(rows)}
                editar={(rows) => OnEdit(rows)}
                setModal={setModal}
                isEdit={data ? true : false}
                values={values}
            >
                <div className="d-flex justify-content-center">
                    <h6>¿Desea continuar con el registro?</h6>
                </div>
            </ModalConfirmation>
        </>
    )
}
import React, { useState, useEffect } from "react";
import { Col, Button } from "reactstrap";
import { toast } from "react-toastify";
import { Formik, Form, Field, ErrorMessage, useFormikContext } from "formik";
import * as Yup from "yup";
import Notification from "../../../components/Notification/Notification";
import { Collapse } from "reactstrap";
import { peticionesReceiver } from "../../../helpers/peticionesReceiver";
import ModalConfirmation from "../../../components/Modal/ModalConfirmation";
import TableComponentOnePage from "../../../components/TableComponent/TableComponentOnePage";

const TiposGruposConceptos = ({ permissions }) => {
  const [modal, setModal] = useState(false);
  const API = peticionesReceiver();
  const [editando, setEditando] = useState(false);
  const [concept, setConcept] = useState({
    keyTypeUnderGroup: "",
    nameTypeUnderGroup: "",
  });
  const [collapse, setCollapse] = useState(false);
  const toggle = () => setCollapse(!collapse);
  const [cabeceras] = useState([
    "Id",
    "Nombre del concepto",
    "Editar",
    "Eliminar",
  ]);
  const [filtro] = useState([true, true]);
  const [parametros, setParametros] = useState({
    action: "datatable",
    table: "types_undersgroups",
    rows: "keyTypeUnderGroup,nameTypeUnderGroup",
    conditions: "enabled = 1 ",
    page: 0,
    records: 5,
    search: "",
    order: "",
  });

  const FormSchema = Yup.object().shape({
    nameTypeUnderGroup: Yup.string().required("Ingrese el nombre del concepto"),
  });

  const limpia = (reset) => {
    setConcept({
      keyTypeUnderGroup: "",
      nameTypeUnderGroup: "",
    });
    setEditando(false);
    setCollapse(false);
    reset();
  };

  const agregar = async (concepto, reset) => {
    const data = {
      action: "insert",
      table: "types_undersgroups",
      rows: concepto,
      validate: [["nameTypeUnderGroup"]],
    };
    API.peticion(data)
      .then((res) => {
        if (res.status === 200 && res.data.status === "success") {
          toast(
            <Notification
              type={"agrega_exito"}
              backMessage={res.data.message}
              withIcon
            />
          );
          setParametros({
            action: "datatable",
            table: "types_undersgroups",
            rows: "keyTypeUnderGroup,nameTypeUnderGroup",
            conditions: "enabled = 1 ",
            page: 0,
            records: 5,
            search: "",
            order: "",
          });
          limpia(reset);
        } else {
          toast(
            <Notification
              type={"agrega_error"}
              backMessage={res.data.message}
              withIcon
            />
          );
        }
      })
      .catch((err) => {
        toast(err);
        console.log(err);
      });
  };

  const modoEdicion = async (concepto) => {
    if (!collapse) {
      setConcept({
        keyTypeUnderGroup: concepto[0],
        nameTypeUnderGroup: concepto[1],
      });
      setEditando(true);
      setCollapse(true);
    } else {
      toast(
        <Notification
          type="warning"
          backMessage="Guarde sus cambios antes de continuar"
        />
      );
    }
  };
  const editar = async (concepto, reset) => {
    const data = {
      action: "update",
      table: "types_undersgroups",
      rows: concepto,
      condition: {
        keyTypeUnderGroup: concepto.keyTypeUnderGroup,
      },
      validate: [["nameTypeUnderGroup"]],
    };
    API.peticion(data)
      .then((res) => {
        if (res.status === 200 && res.data.status === "success") {
          toast(
            <Notification
              type={"modifica_exito"}
              backMessage={res.data.message}
              withIcon
            />
          );
          setParametros({
            action: "datatable",
            table: "types_undersgroups",
            rows: "keyTypeUnderGroup,nameTypeUnderGroup",
            conditions: "enabled = 1 ",
            page: 0,
            records: 5,
            search: "",
            order: "",
          });
          reset();
          setCollapse(false);
        } else {
          toast(
            <Notification
              type={"modifica_error"}
              backMessage={res.data.message}
              withIcon
            />
          );
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <div>
      <Formik
        initialValues={concept}
        validationSchema={FormSchema}
        onSubmit={(fields, resetForm) => {
          /* submitFunction(fields,resetForm); */
          setModal(true);
        }}
        onReset={(fields, { resetForm }) => {}}
        render={({ errors, status, touched, values, resetForm }) => (
          <div className="">
            {permissions.CON && (
              <TableComponentOnePage
                titulo="Tipos de concepto de nomina"
                cabecerasTabla={cabeceras}
                filtro={filtro}
                editar={modoEdicion}
                parametros={parametros}
                values={values}
                limpia={limpia}
                reset={resetForm}
                permissions={permissions}
              />
            )}

            <Form>
              <ModalConfirmation
                modalTitle={editando ? "Editar" : "Crear"}
                modal={modal}
                setModal={setModal}
                crear={agregar}
                editar={editar}
                isEdit={editando}
                values={values}
                reset={resetForm}
              >
                {editando ? (
                  <div className="d-flex justify-content-center">
                    <h6>¿Está seguro de editar el registro?</h6>
                  </div>
                ) : (
                  <div className="d-flex justify-content-center">
                    <h6>¿Desea continuar con el registro?</h6>
                  </div>
                )}
              </ModalConfirmation>
              {permissions.INS && (
                <div className="d-grid d-flex justify-content-center justify-content-sm-end">
                  <Button
                    color="add"
                    onClick={toggle}
                    style={{ marginBottom: "1rem" }}
                    disabled={collapse}
                  >
                    Agregar
                  </Button>
                </div>
              )}

              <Collapse
                isOpen={collapse}
              >
                <div>
                  <Col className="d-grid d-flex justify-content-sm-end justify-content-center">
                    <h6 className=" text-center">
                      Estado:{" "}
                      <span className="text-primary">
                        {editando ? "Editando" : "Guardando"}{" "}
                      </span>{" "}
                    </h6>
                  </Col>
                  <div className="headline-1 text-center">Tipo de concepto</div>
                  <label htmlFor="nameTypeUnderGroup">
                    Nombre del concepto<span className="text-danger">*</span>{" "}
                  </label>
                  <Field
                    name="nameTypeUnderGroup"
                    type="text"
                    className={
                      "form-control" +
                      (errors.nameTypeUnderGroup && touched.nameTypeUnderGroup
                        ? " is-invalid"
                        : "")
                    }
                  />
                  <ErrorMessage
                    name="nameTypeUnderGroup"
                    component="div"
                    className="invalid-feedback"
                  />
                  <div className="d-grid d-flex flex-column flex-sm-row justify-content-between mt-5">
                    <Button
                      color="danger"
                      type="reset"
                      onClick={() => limpia(resetForm)}
                      className="mt-3"
                    >
                      Cancelar
                    </Button>
                    <Button color="success" type="submit" className="mt-3">
                      {editando ? "Editar" : "Guardar"}
                    </Button>
                  </div>
                </div>
              </Collapse>
              <FormikContact info={concept} />
            </Form>
          </div>
        )}
      />
    </div>
  );
};
const FormikContact = ({ info }) => {
  const { setFieldValue } = useFormikContext();
  useEffect(() => {
    if (info) {
      setFieldValue("keyTypeUnderGroup", info.keyTypeUnderGroup);
      setFieldValue("nameTypeUnderGroup", info.nameTypeUnderGroup);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [info]);
  return null;
};
export default TiposGruposConceptos;

import * as XlsxPopulate from "xlsx-populate";
import { saveAs } from "file-saver";
import { fPercent, fDate } from "../../libs/tools/format";

const formatter = new Intl.NumberFormat("es-MX", {
  style: "currency",
  currency: "MXN",
});

// const dateformatter = (fecha) => {
//   let date = new Date (fecha)
//   return `${date.getDate()}/${date.getMonth()}/${date.getFullYear()}`
// }

/**
 * Generar reporte excel general
 * @param {Array<Object>} report Registros para generar el reporte
 * @param {Array<string>} headers Cabeceras de las  columnas
 * @param {string} title Titulo de reporte
 * @param {{headers: Array<string>, id: {id: string}, content: Array<Array<{ header: string, value: *, columns: number, format?: string | Function }>>}} filter filtro apra generar el excel
 * @param {string} params 
 */
export async function saveAsExcel(report, headers, title, filter, params) {
  XlsxPopulate.fromBlankAsync().then(async (workbook) => {
    //Estilos
    // const dataStyle = workbook.styleSheet().createStyle();
    // dataStyle.style('wrapText', true);
    // dataStyle.style('horizontalAlignment', 'center');
    // dataStyle.style('verticalAlignment', 'center');
    // dataStyle.style('border', true);

    const ds = {wrapText: true, horizontalAlignment: 'center', verticalAlignment: 'center', border: true}

    // const headerStyle = workbook.styleSheet().createStyle();
    // headerStyle.style('horizontalAlignment', 'center');
    // headerStyle.style('verticalAlignment', 'center');
    // headerStyle.style('border', true);
    // headerStyle.style('bold', true);

    const hs = {horizontalAlignment: 'center', verticalAlignment: 'center', border: true, bold: true}

    // const titleStyle = workbook.styleSheet().createStyle();
    // titleStyle.style('horizontalAlignment', 'center');
    // titleStyle.style('verticalAlignment', 'center');
    // titleStyle.style('bold', true);

    const ts = {horizontalAlignment: 'center', verticalAlignment: 'center', bold: true}

    const sheet1 = workbook.sheet(0);
    
    const sheetData = getSheetData(report, headers, filter);
    const mid = Math.ceil(headers.length / 2);

    // Unir tres columnas del centro
    let colL = sheet1.column(mid - 1).columnName();
    let colR = sheet1.column(mid + 1).columnName();

    sheet1
      .range(`${colL}1:${colR}1`)
      .merged(true)
      .value("SAPP")
      .style(ts);
    sheet1
      .range(`${colL}2:${colR}2`)
      .merged(true)
      .value(title)
      .style(ts);
    if (params && params != "") {
      sheet1
        .range(`${colL}3:${colR}3`)
        .merged(true)
        .value(params)
        .style(ts);
    }

    if(report.length > 4000){
      sheet1.row(6).cell(1).value(sheetData);
    }else{
      sheet1.row(6).cell(1).value(sheetData).style(ds);
    }

    for (let i = 1; i <= headers.length; i++) {
        sheet1.column(i).width(30);
      if(title === "Reporte de Plantilla laboral"){
        if(i === 2){
          sheet1.column(i).width(50);
        }
        if(i === 1 || i === 3 || i === 8 || i === 9 || i === 10){
          sheet1.column(i).style({horizontalAlignment: "center"});
        }
      }
      sheet1.row(6).cell(i).style(hs);
    }

    // sheet1.usedRange().style("horizontalAlignment", "center");

    return workbook.outputAsync().then((res) => {
      saveAs(res, `${title}.xlsx`);
    });
  });
}



function getSheetData(data, header, filter) {
  let keys = [];

  
  header.forEach((h) => {
    filter.content.every((arr) => {
      let flg = true;
      arr.every((ob) => {
        if (ob.header === h) {
          keys.push([ob.value, ob.format]);
          flg = false;
          return false;
        }
        return true;
      });
      return flg;
    });
  });


  //funcion para omitir agregar el id (agregando el parametro show dentro de la definicion de id)
  //keys.unshift agrega el id

  if(filter.id.show !== undefined && filter.id.show !== null ){
    if(filter.id.show){
      keys.unshift([filter.id.id, filter.id.format]);
    }
    //eliminando cabecera id
    header.shift()
  }else{
    
    keys.unshift([filter.id.id, filter.id.format]);
  }
  let pageData = data.map((d) => {
    let reg = keys.map((k) => {
      let tx = d[k[0]];
      switch (k[1]){
        case "moneda":
          tx = formatter.format(tx);
          break;
        case "fecha":
          if(isNaN(tx)){
            tx = fDate(tx) 
          }
           break;
        case "porcentaje":
          if (isNaN(tx)) {
            tx = fPercent(tx);
          }
          break;
        default:
          if(k[1]){
            tx = k[1](d, k[0]);
          }
          break;
      }
      return tx;
    });
    return reg;
  });
  pageData = pageData.reverse();
  pageData.unshift(header);
  return pageData;
}

import { useEffect, useState } from "react";
import {
  Button,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import { doRequest } from "../../../../helpers/requests";
import FullFormLoader from "../../../../components/Loader/FullFormLoader";
import { NumberInput } from "../../../../components/GenericInputsFormik/NumberInput";
import { fMoney } from "../../../../libs/tools/format";

const defaultform = {
  amount: "",
};
const FormSchema = Yup.object().shape({
  amount: Yup.number()
    .required("Ingrese una cantidad")
    .min(1, "La cantidad debe ser mayor a 0"),
});

export default function DetalleBien({ info, modal, setModal, setRefresh, showButtons }) {
  const [subtotal, setSubtotal] = useState("");
  const [precioImp, setPrecioImp] = useState("");
  const [loading, setLoading] = useState(false);

  const {
    handleSubmit,
    handleReset,
    handleBlur,
    values,
    errors,
    touched,
    setFieldValue,
  } = useFormik({
    initialValues: defaultform,
    onSubmit: () => {
      changeAmount();
    },
    onReset: () => {
      setSubtotal("");
      setPrecioImp("");
    },
    validationSchema: FormSchema,
    enableReinitialize: true,
  });

  const onChange = (e) => {
    setFieldValue([e.target.name], e.target.value);
    if(!isNaN(+e.target.value)){
      setSubtotal(+e.target.value * precioImp);
    }else setSubtotal("");
  };

  const toggle = () => setModal(!modal);

  useEffect(() => {
    if (info !== "") {
      setFieldValue("amount", info.amount);
      setSubtotal(info.total);
      setPrecioImp(+info.unitCost + +info.unitCost * (+info.taxies / 100));
    }
  }, [info]);

  const changeAmount = async () => {
    const params = {
      action: "update",
      table: "quotesdetail",
      rows: { amount: values.amount, total: subtotal },
      condition: { idDetail: info.idDetail },
    };
    setLoading(true);
    const res = await doRequest("receiver/receiver.php", params, true);
    if (res.length > 0) {
      if(setRefresh) setRefresh(true);
      setModal(false);
      handleReset();
    }
    setLoading(false);
  };

  return (
    <Modal
      isOpen={modal}
      toggle={toggle}
      backdrop={false}
      keyboard
      size="lg"
      centered
    >
      <form onSubmit={handleSubmit} onReset={handleReset}>
        <ModalHeader toggle={toggle} className="bg-primary">
          <p className="text-white">Datos generales</p>
        </ModalHeader>
        <ModalBody className="text-dark">
          <Row style={{ fontSize: 20 }} className="mx-0">
            <Col xs={12} md={6} className="text-left text-md-right">
              <p style={{ fontWeight: "600" }}>
                Descricón del Bien o Servicio:
              </p>
            </Col>
            <Col xs={12} md={6} className="mb-1">
              <u>{info.descripcion}</u>
            </Col>
            <Col xs={12} md={6} className="text-left text-md-right">
              <p style={{ fontWeight: "600" }}>Periodo:</p>
            </Col>
            <Col xs={12} md={6} className="mb-1">
              {info.nameServiceTime}
            </Col>
            <Col xs={12} md={6} className="text-left text-md-right">
              <p style={{ fontWeight: "600" }}>Marca:</p>
            </Col>
            <Col xs={12} md={6} className="mb-1">
              {info.brand}
            </Col>
            <Col xs={12} md={6} className="text-left text-md-right">
              <p style={{ fontWeight: "600" }}>Modelo:</p>
            </Col>
            <Col xs={12} md={6} className="mb-1">
              {info.model}
            </Col>
            <Col xs={12} md={6} className="text-left text-md-right">
              <p style={{ fontWeight: "600" }}>Cantidad requerida:</p>
            </Col>
            <Col xs={12} md={6} className="mb-1">
              {/* {info.amount} */}
              {showButtons ? <NumberInput
                inputName="amount"
                onChangeMethod={onChange}
                onBlurMethod={handleBlur}
                value={values.amount}
                touched={touched.amount}
                errors={errors.amount}
                isMin={1}
              />: values.amount}
            </Col>
          </Row>

          <hr />
          <div className="text-center">
            <p style={{ fontWeight: "600" }}>Compras anteriores</p>
            <p>No se cuentan compras anteriores para este bien o servicio</p>
          </div>

          <Row className="text-center mt-5" style={{ fontSize: 16 }}>
            <Col xs={12} md={6}>
              <strong>Costo unitario</strong>
              <div className="w-75 mx-auto text-center border-bottom">
                <p>{fMoney(info.unitCost)}</p>
              </div>
            </Col>
            <Col xs={12} md={6}>
              <strong>Costo total</strong>
              <div className="w-75 mx-auto text-center border-bottom">
                <p>{fMoney(subtotal)}</p>
              </div>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <div className="w-100 d-flex justify-content-md-end flex-md-row flex-column-reverse ">
            <Button
              type="reset"
              color="primary"
              onClick={toggle}
              className="my-2 my-md-0 mx-0 mr-md-3"
            >
              Cerrar
            </Button>{" "}
            {showButtons && <Button color="secondary" type="submit" className="my-2 my-md-0">
              Aceptar
            </Button>}
          </div>
        </ModalFooter>
        <FullFormLoader show={loading} />
      </form>
    </Modal>
  );
}

import React, { useState } from "react";
import TableComponentFiltro from "../../../components/TableComponent/TableComponentFiltro";

export const PlazasLaborales = () => {

  const cabeceras = [
    "Id",
    "Numero de plaza",
    "Puesto de Trabajo",
    "Nivel",
    "Subnivel",
    "Rango",
    "Tipo de plaza",
    "Unidad de Trabajo",
    "Edificio de la Plaza",
    "Estado",
    "Editar",
    "Eliminar",
  ];

  const [parametros] = useState({
    action: "datatable",
    table: `jobpositions AS JP
    INNER JOIN positiontypes AS PT
    ON PT.keyPositionType = JP.keyPositionType
    INNER JOIN jobs AS JB
    ON JB.keyJob = JP.keyJob
    INNER JOIN areas AS AR
    ON AR.idArea = JP.idArea
    INNER JOIN buildings AS BD
    ON BD.idBuilding = JP.idBuilding
    INNER JOIN salaries AS SL
    ON SL.idSalary = JP.idSalary
    INNER JOIN status AS ST
    ON ST.keyStat = JP.keyStat`,
    rows: "JP.idJobPosition,JP.numberJobPositions,JB.nameJob,SL.level,SL.subLevel,SL.rango,PT.namePositionType,AR.name,BD.nameBuilding,ST.nameStat, JP.idCCT", ///sin espacios y comas
    conditions: "JP.enabled = 1",
    page: 0,
    records: 5,
    search: "",
    order: "JP.numberJobPositions DESC",
  });

  return (
    <div>
      <TableComponentFiltro
        titulo="Plazas"
        cabecerasTabla={cabeceras}
        filtro={[true, true, true, true, true, true, true, true, true, true]}
        rutaCrear="/template/plazas-laborales/crear"
        rutaEditar="/template/plazas-laborales/editar"
        parametros={parametros}
      />
    </div>
  );
};
export default PlazasLaborales;

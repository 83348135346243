import { StyleSheet, PDFViewer, PDFDownloadLink } from "@react-pdf/renderer";
import { useEffect, useState } from "react";
import { isMobile } from "react-device-detect"
import { Spinner } from "reactstrap";

const stylesTable = StyleSheet.create({
    viewer: {
        width: "100%", //the pdf viewer will take up all of the width and height
        height: window.innerHeight,
    },
});

export default function ReportViewer({ pdfConfig, generatePdfMethod, report, title }) {

    const [pdfData, setPdfData] = useState(null);


    useEffect(() => {
        generatedDocumentforCompiler();
    }, []);

    /* Genera configuracion que pasa al compilador */
    const generatedDocumentforCompiler = async () => {
        const data = await generatePdfMethod.method(
            report,
            pdfConfig,
            title,
            `Archivo 1 de 1`
        )
        setPdfData(
            data   
        );
    };

    return (
        <>
            {pdfData !== null ? (
                <>
                    {isMobile ? (
                        <PDFDownloadLink document={pdfData.report}>
                            {({ blob, url, loading, error }) =>
                                loading ? (
                                    <div className="text-center">Generando su documento...</div>
                                ) :
                                    <>
                                        <div className="text-center px-3 pt-3">
                                            <i className="fa fa-download" style={{ fontSize: "80px" }} />
                                        </div>
                                        <div className="text-center px-3" style={{ fontSize: "20px" }} >
                                            Descargar
                                        </div>
                                    </>
                            }
                        </PDFDownloadLink>
                    ) : (
                        <PDFViewer style={stylesTable.viewer} document={pdfData}>
                            {pdfData.report}
                        </PDFViewer>
                    )}
                </>
            ) : (
                <div className="d-flex align-items-center justify-content-center" style={{ height: "200px" }}>
                    <Spinner
                        color="primary"
                        style={{
                            height: '3rem',
                            width: '3rem'
                        }}
                        type="grow"
                    >
                        Loading...
                    </Spinner>
                </div>
            )}
        </>
    )
}
import React from 'react'
import {
    Col,
    Row,
    Progress,
  } from "reactstrap";

const HeaderEvidenciaAvances = (props) => {
    const {activityEvidence} = props
  return (
    <div>
        <Row className="mb-5">
        <Col
          xs={12}
          md={12}
          lg={12}
          className="d-flex flex-column justify-content-around align-items-around text-center"
        >
          <div>
            <span className="headline-3">NOMBRE DEL ENCARGADO: </span>
            <span className="">{activityEvidence.nameResponsible}</span>
          </div>

          <div className="d-flex justify-content-around mt-3">
            <span>
              <span className="headline-3">FECHA DE INICIO: </span>
              <span className="">{activityEvidence.startDate}</span>
            </span>

            <span>
              <span className="headline-3">FECHA DE TERMINO: </span>
              <span className="">{activityEvidence.endDate}</span>
            </span>
          </div>
        </Col>
      </Row>

      <Row className="mb-3">
        <Col
          xs={12}
          md={12}
          lg={12}
          className="d-flex justify-content-around text-center"
        >
          <div>
            <span className="headline-2">Ponderación en acción</span>
            <Progress color="success" value={activityEvidence.progress} />
            {`${activityEvidence.progress}%`}
          </div>

          <div>
            <span className="headline-2">Avance en acción</span>
            <Progress color="success" value={activityEvidence.record} />
            {`${activityEvidence.record}%`}
          </div>
        </Col>
      </Row>

    </div>
  )
}

export default HeaderEvidenciaAvances
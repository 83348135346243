import React, { useState,useEffect } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { Col, Row, Button, Collapse } from "reactstrap";

import { peticionesReceiver } from "../../../helpers/peticionesReceiver";

import Loader from "../../../components/Loader/Loader";
import Notification from "../../../components/Notification/Notification";
import { TextAreaInput,CurrencyInput,FilesInput, SelectTypeHeadSingle } from "../../../components/GenericInputsFormik/index";
import ModalConfirmation from "../../../components/Modal/ModalConfirmation";
import TableComponentOnePage from "../../../components/TableComponent/TableComponentOnePage";

export const AjustesBancarios = ({ idBankAccount,crear,subirArchivo,actualizarBalance,editar, permissions }) => {
  const today = new Date()
  const day = today.getDate()

  const defaultForm = {
    idMove: "",
    idBankAccount: idBankAccount,
    keyBankMove: 0,
    keyKindMove: 0,
    startBalance: "",
    confirmAmount: "",
    tax: "",
    endBalance: '',
    amount: '',
    description: '',
    file: '',
    dateMove: `${today.getFullYear()}-${today.getMonth() + 1}-${day < 10 ? `0${day}` : day}`,
  };

  const [isOpen, setIsOpen] = useState(false);
  const [title, setTitle] = useState("");
  const [modal, setModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false)
  const [bankMoves, setBancMoves] = useState([])
  const [kindBankMoves, setKindBankMoves] = useState([])
  const [allKindBankMoves, setAllKindBankMoves] = useState([])
  const [subtractAmount, setSubtractAmount] = useState(false)
  const [invalidFile, setInvalidFile] = useState(false);
  const [filesType, setFilesType] = useState([])
  const [availableAmount, setAvailableAmount] = useState(true)

  const cabeceras = ["#","Banco","Cuenta","Alias","Clabe","Saldo","Ajuste"]
  const filtro = [true,true,true,true,true,true,false]
  const API = peticionesReceiver();

  const [parametros] = useState({
    action: "datatable",
    table: `bankaccounts AS BNA
    INNER JOIN banks AS BAN
    ON BNA.keyBank = BAN.keyBank`,
    rows: `BNA.idBankAccount,BAN.nameBank,BNA.account,BNA.titular,BNA.swiftCode,BNA.avaible`,
    conditions:`BNA.enabled = 1 AND BNA.idBankAccount = ${idBankAccount}`,
    page:0,
    records:5,
    search:"",
    order:""
  })

  useEffect(() => {
    getAllData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getAllData = async() => {
    const result = await Promise.all([
      getData("kindbankmoves",setKindBankMoves,"nameKindBankMove"),
      getData("types_bankmoves",setBancMoves,"nameBankMove"),
      getData("filetypes",setFilesType,"nameFyleType"),
    ])
    if (result.some(it => it === true)) {
      setIsLoading(false)
    }
  }

  const getData = async(table,functionUpdated,column) => {
    const params = {
      action: "select",
      table: table,
      condition: { enabled: 1 },
      order: `${column} ASC`,
    };
    if(table === 'filetypes') params.condition = {idModule: 35, nameFyleType: 'AJUSTE BANCARIO'}
    const res = await API.peticion(params)
    if(res.status === 200 && res.data.code === "200"){
      if(table === 'kindbankmoves') setAllKindBankMoves(res.data.data)
      functionUpdated(res.data.data)
      return true
    }
    else {
      toast(<Notification type={"consultar_error"} withIcon />);
      return false
    }
  };

  const onChange = event => {
    const { name,value } = event.target
    setFieldValue([name], value);
  }

  const handleFiles = event => {
    const { files } = event.target

    if(files[0].size > 5000000){
      setInvalidFile(true);
    }
    else {
      setFieldValue('file', files[0]);
    }
  }

  const OnChangeSelect = (event) => {
    const { value, name } = event.target;
    setFieldValue([name], value);

    if (name === 'keyBankMove') {
      const move = bankMoves.find(it => it.keyBankMove === value)
      if (move) {
        const getKindBankMoves = allKindBankMoves.filter(it => it.keyBankMove === value)
        setSubtractAmount(move.nameBankMove === 'CARGO' ? true : false)
        setKindBankMoves(getKindBankMoves) 
      } else {
        setKindBankMoves([])
      }
      setFieldValue('keyKindMove', 0);
      setFieldValue('tax', 0);
      setAvailableAmount(true)
    }else if(name === 'keyKindMove'){
      const getTax = kindBankMoves.find(it => it.keyKindMove === value)
      if (getTax) {
        const newBalance = Number(values.startBalance) - Number(getTax.tax)
        setAvailableAmount(false)
        setFieldValue('tax', getTax.tax);
        setFieldValue('endBalance', newBalance);
      } else {
        setAvailableAmount(true)
        setFieldValue('tax', 0);
        setFieldValue('endBalance', values.startBalance);
      }
    }
  };

  const onChangeCurrent = event => {
    const { name,value } = event.target

    if(name === 'amount'){
      const amount = value === undefined ? 0 : value

      if(amount === 0){
        setFieldValue('amount',value)
      }
      else if(Number(value) <= Number(values.startBalance)){
        const subTotal = Number(values.startBalance) - Number(values.tax)
        const total = subtractAmount ? subTotal - Number(amount) : subTotal + Number(amount)
        setFieldValue('endBalance', total);
        setFieldValue('amount',value)
      }
      else {
        const subTotal = Number(values.startBalance) - Number(values.tax)
        setFieldValue('endBalance', subTotal);
        setFieldValue('amount',subTotal)
      }
     
    }
    else {
      setFieldValue([name],value)
    }
  }

  const openForm = (isEdit = false) => {
    setTitle(`${isEdit ? 'Editar' : 'Crear'} chequera`);
    setIsOpen(true);
  };

  const clear = (reset) => {
    setTitle("");
    reset();
    setIsOpen(false);
  };

  const getForEdit = async (row) => {
    if (!isOpen) {
      setFieldValue("startBalance", Number(row[5]));
      setFieldValue("endBalance", Number(row[5]));
      openForm(true)
    } 
    else {
      toast( <Notification type="warning" backMessage="Guarde sus cambios antes de continuar" />,
        { closeButton: false }
      );
    }
  };

  const saveInfo = async(reset) => {
    setIsLoading(true)

    const data ={
      idMove: values.idMove,
      idBankAccount: values.idBankAccount,
      keyBankMove: values.keyBankMove,
      startBalance: values.startBalance,
      ammount: values.amount,
      endBalance: values.endBalance,
      description: values.description,
      dateMove: values.dateMove
    }

    const params = {
      nameId: 'idMove',
      table: 'bankaccountmoves',
      rows: data,
      column: 'idMove',
      validations: ["idBankAccount","keyBankMove","ammount","dateMove"]
    }

    const paramsEdit = {
      nameId: 'idBankAccount',
      table: 'bankaccounts',
      rows: {idBankAccount: data.idBankAccount,avaible: data.endBalance},
      validations: []
    }
  
    try{
      const promises = await Promise.all([crear(params),editar(paramsEdit)])
      const response = promises.every(it => it.success)
      if(response){
        const formData = new FormData();
        formData.append("action", "saveFile")
        formData.append("keyFileType", filesType[0].keyFileType);
        formData.append('binary',values.file)
        formData.append('reference',promises[0].reference.idMove)

        const result = await subirArchivo(formData)
        if(result.success) {
          toast(<Notification type={"modifica_exito"} withIcon />);
          clear(reset)
          actualizarBalance(data.endBalance)
          setIsLoading(false)
        }
        else {
          toast(<Notification type={"modifica_error"} backMessage={response.message} withIcon />);
          setIsLoading(false)
        }
      
      }
      else {
        toast(<Notification type={"modifica_error"} backMessage={response.message} withIcon />);
        setIsLoading(false)
      }
    }
    catch (error) {
      console.log(error);
      toast(<Notification type={"consultar_servidor_error"} withIcon />);
      setIsLoading(false)
    }
  };

  const FormSchema = Yup.object().shape({
    keyBankMove: Yup.number()
      .moreThan(0,"Favor de seleccionar el tipo de movimiento")
      .required("Favor de seleccionar el tipo de movimiento"),
    keyKindMove: Yup.number()
      .moreThan(0,"Favor de seleccionar el origen del movimiento")
      .required("Favor de seleccionar el origen del movimiento"),
    amount: Yup.number()
      .moreThan(0,"Favor de ingresar un monto del movimiento")
      .required("Favor de ingresar un monto del movimiento"),
    confirmAmount: Yup.number()
      .oneOf([Yup.ref("amount"), null], "Favor de confirmar el monto del movimiento")
      .moreThan(0,"Favor de confirmar el monto del movimiento")
      .required("Favor de confirmar el monto del movimiento"),
    description: Yup.string()
      .required("Favor de ingresar alguna observacion"),
    file: Yup.string()
      .required("Favor de subir un comprobante")
  });

  const { handleSubmit, values, handleBlur, errors, touched, setFieldValue, resetForm, handleReset } = useFormik({
    initialValues: defaultForm,
    validationSchema: FormSchema,
    onSubmit: () => setModal(true),
    enableReinitialize: true,
  });

  return (
    <>
    {
      isLoading ? <Loader />
      :
      <div className="mt-4">
        <TableComponentOnePage
          titulo="Ajustes de Bancarios"
          cabecerasTabla={cabeceras}
          filtro={filtro}
          editar={getForEdit}
          parametros={parametros}
          values={values}
          limpia={clear}
          reset={resetForm}
          eliminar = {false}
          permissions={permissions}
        />

        <div className="row mt-2">
          <div className="col-12 col-sm-6"> 
            <h5 className="text-center text-sm-left">{title}</h5> 
          </div>

        </div>
        <Collapse isOpen={isOpen} className="mt-4 border-top">

          <form onSubmit={handleSubmit} onReset={handleReset} className="mt-4">
            <ModalConfirmation
              modalTitle={"Editar"}
              modal={modal}
              setModal={setModal}
              editar={() => saveInfo(resetForm)}
              isEdit={true}
              values={values}
            >
              <div className="d-flex justify-content-center">
                <h6>¿Está seguro de guardar el registro?</h6>
              </div>
            </ModalConfirmation>

            <Row>
              <Col xs={12} md={6}>
                <CurrencyInput
                  label="Saldo actual"
                  inputName="startBalance"
                  value={values.startBalance}
                  isDisabled = {true}
                />
              </Col>

              <Col xs={12} md={6}>
                <SelectTypeHeadSingle
                  label="Tipo de movimiento"
                  isRequired={true}
                  optionsArray={bankMoves}
                  inputName="keyBankMove"
                  onChangeMethod={(event) => OnChangeSelect(event)}
                  onBlurMethod={handleBlur}
                  touched={touched.keyBankMove}
                  errors={errors.keyBankMove}
                  optionValue="keyBankMove"
                  optionName="nameBankMove"
                  value={values.keyBankMove}
                />
              </Col>

              <Col xs={12} md={6}>
              <SelectTypeHeadSingle
                  label="Origen de movimientos"
                  isRequired={true}
                  optionsArray={kindBankMoves}
                  inputName="keyKindMove"
                  onChangeMethod={(event) => OnChangeSelect(event)}
                  onBlurMethod={handleBlur}
                  touched={touched.keyKindMove}
                  errors={errors.keyKindMove}
                  optionValue="keyKindMove"
                  optionName="nameKindBankMove"
                  value={values.keyKindMove}
                  isDisabled = {values.keyBankMove > 0 ? false : true}
                />
              </Col>

              <Col xs={12} md={6}>
                <CurrencyInput
                  label="Monto del movimiento"
                  inputName="amount"
                  onChangeMethod={(value, name ) => onChangeCurrent({ target: { name: name, value: value } }) }
                  onBlurMethod={handleBlur}
                  value={values.amount}
                  isRequired={true}
                  touched={touched.amount}
                  errors={errors.amount}
                  isMax = {subtractAmount ? values.startBalance.length : 30}
                  isDisabled = {availableAmount}
                />
              </Col>

              <Col xs={12} md={6}>
                <CurrencyInput
                  label="Confirmar monto"
                  inputName="confirmAmount"
                  onChangeMethod={(value, name ) => onChangeCurrent({ target: { name: name, value: value } }) }
                  onBlurMethod={handleBlur}
                  value={values.confirmAmount}
                  isRequired={true}
                  touched={touched.confirmAmount}
                  errors={errors.confirmAmount}
                  isDisabled = {availableAmount}
                />
              </Col>

              <Col xs={12} md={6}>
                <CurrencyInput
                  label="Importe de impuesto"
                  inputName="tax"
                  onChangeMethod={(value, name ) => onChangeCurrent({ target: { name: name, value: value } }) }
                  onBlurMethod={handleBlur}
                  value={values.tax}
                  isRequired={true}
                  touched={touched.tax}
                  errors={errors.tax}
                  isDisabled = {true}
                />
              </Col>

              <Col xs={12} md={6}>
                <CurrencyInput
                  label="Saldo Final"
                  inputName="endBalance"
                  value={values.endBalance}
                  isRequired={true}
                  isDisabled = {true}
                />
              </Col>

              <Col xs={12} lg={6}>
                <FilesInput
                  label="Comprobante PDF"
                  inputName="description"
                  fileAccept = ".pdf"
                  onChangeMethod={handleFiles}
                  onBlurMethod={handleBlur}
                  value={values.description}
                  isRequired={true}
                  touched={touched.description}
                  errors={invalidFile}
                />
              </Col>

            </Row>

            <div className="form-group">
              <TextAreaInput
                label="Información Adicional"
                inputName="description"
                inputRows={10}
                onChangeMethod={onChange}
                onBlurMethod={handleBlur}
                value={values.description}
                isRequired={false}
                touched={touched.description}
                errors={errors.description}
              />
            </div>

            <Row className="mt-5">

              <div className="col-sm-6 order-2 order-sm-1 text-center text-sm-left mb-4">
                <Button color="danger" type="reset" onClick={() => clear(resetForm)} >
                  Cancelar
                </Button>
              </div>

              <div className="col-sm-6 order-1 order-sm-2 text-center text-sm-right mb-4">
                <Button color="success" type="submit"> Guardar </Button>
              </div>

            </Row>
          </form>

        </Collapse>

      </div>
    }
    </>
  );
};
export default AjustesBancarios;

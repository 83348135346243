import React, { useState, useEffect } from "react";
import Notification from "../../../../../components/Notification/Notification";
import { toast } from "react-toastify";
import { peticionesReceiver } from "../../../../../helpers/peticionesReceiver";
import Widget from "../../../../../components/Widget/Widget";
import TableComponentCustom from "../../../../../components/TableComponent/TableComponentCustom";
import { Col, Row, Button } from "reactstrap";
import { generatePdfCheques } from "./pdf/PdfCheques";
import ModalReports from "../../../../../components/Modal/ModalReports";
import QRCode from "qrcode.react";

const API = peticionesReceiver();

const Cheques = (props) => {
  const { data } = props;
  const [report, setReport] = useState("");
  const [modalReports, setModalReports] = useState(false);
  const [leyendMessage, setLegendMessage] = useState(false);
  const [pdfConfig, setPdfConfig] = useState({});

  const cabeceras =
    data.typeCheck === "1"
      ? [
          "Id",
          "Numero de empleado",
          "Nombre de empleado",
          "Adscripcion",
          "Puesto",
          "Periodo",
          "Año",
          "Importe de pago",
        ]
      : [
          "Id",
          "Deudor Alimentario",
          "Adscripcion",
          "Puesto",
          "Periodo",
          "Año",
          "Acredor Alimentario",
          "Importe de pago",
        ];

  const [filtro] = useState(
    data.typeCheck === "1"
      ? [
          true,
          true,
          true,
          true,
          true,
          true,
          true,
          true,
          false,
          false,
          false,
          false,
        ]
      : [true, true, true, true, true, true, true, true, false, false]
  );
  const [parametros] = useState(
    data.typeCheck === "1"
      ? {
          action: "datatable",
          table: `payslips ps inner join staff s on ps.idStaff = s.idStaff inner join staffaccounts sa on sa.idStaffAccount = ps.idStaffAccount inner join jobstaff js on ps.idJobStaff = js.idJobStaff inner join areas a on js.idArea = a.idArea inner join jobs j on js.keyJob = j.keyJob inner join unders un on (ps.idUnder = un.idUnder)`,
          rows: "ps.idPayslip,s.staffNumber, concat_ws(' ',s.name, s.pName, s.mName) nombre, a.name, j.nameJob, period, year,ps.amount,ps.idUnder,ps.amount,un.nameUnder,ps.idStaff,ps.idPayslip,un.under",
          conditions: `period=${data.period} and year=${data.year} and keyPaymentType=2 group by ps.idStaff`,
          page: 0,
          records: 5,
          search: "",
          order: "under ASC",
        }
      : {
          action: "datatable",
          table:
            "payslips ps inner join pensions p on ps.idGeneral = p.idPension inner join staff s on ps.idStaff = s.idStaff inner join jobstaff js on ps.idJobStaff = js.idJobStaff inner join areas a on js.idArea = a.idArea inner join jobs j on js.keyJob = j.keyJob inner join unders un on (ps.idUnder = un.idUnder)",
          rows: "ps.idPayslip,concat_ws(' ',s.name, s.pName, s.mName) deudorAlimentario, a.name, j.nameJob, period, year,concat_ws(' ',p.name, p.pName, p.pName) acredorAlimentario, p.amount importePago, ps.amount,ps.idUnder,ps.amount,un.nameUnder,ps.idStaff,ps.idPayslip,un.under",
          conditions: `period=${data.period} and year=${data.year} and keyTypePayslip=5 and p.keyPaymentType=2`,
          page: 0,
          records: 5,
          search: "",
          order: "under ASC",
        }
  );
  useEffect(() => {
    getData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const cleanParams = () => {
    let params = parametros;
    params.action = "multiselect";
    delete params.page;
    delete params.records;
    delete params.order;
    delete params.search;
    return params;
  };

  const getData = () => {
    let params = cleanParams();

    const params2 = {
      action: "multiselect",
      table: "advertisements",
      rows: "*",
      conditions: `period=${data.period} and enabled = 1 and year=${data.year} and aplicationDate <= NOW()`,
    };

    API.peticion(params2)
      .then((res2) => {
        if (
          res2.status === 200 &&
          res2.data.code === "200" &&
          res2.data.data.length > 0
        ) {
          setLegendMessage(false);
        } else {
          setLegendMessage(true);
        }

        API.peticion(params)
          .then((res) => {
            if (res.status === 200 && res.data.code === "200") {
              setReport(res.data.data);
            } else {
              toast(
                <Notification
                  type={"consultar_error"}
                  backMessage={res.data.message}
                  withIcon
                />,
                { closeButton: false }
              );
            }
          })
          .catch((err) => {
            toast(<Notification type={"consultar_servidor_error"} withIcon />, {
              closeButton: false,
            });
          });
      })

      .catch((err) => {
        toast(<Notification type={"consultar_servidor_error"} withIcon />, {
          closeButton: false,
        });
      });
  };

  const crearPdf = () => {
    generatedQrImagePdfConfig()
  };

  const generatedQrImagePdfConfig = () => {
    const canvas = document.getElementById("123456");
    const pngUrl = canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    setPdfConfig({
      period: `${data.period}/${data.year} `,
      pngUrl: pngUrl,
    });
    setModalReports(true);
  };

  return (
    <div>
      {leyendMessage && (
        <div className="text-center mb-5 ">
          <span className="headline-1" style={{ color: "red" }}>
            {`Aún no se ha liberado el comprobante correspondiente al periodo ${data.period} y año  ${data.year}`}{" "}
          </span>
        </div>
      )}

      <Row className="d-flex  justify-content-around">
        <Col xs={12} md={12} lg={6}></Col>
        <Col
          xs={12}
          md={12}
          lg={6}
          className="d-flex flex-column justify-content-center"
        >
          <Col className="text-center">
            <Button
              color="success"
              className="text-center"
              onClick={() => crearPdf()}
              disabled={
                report !== "" && report.length !== 0 && report !== undefined
                  ? false
                  : true
              }
            >
              Generar Pdf
            </Button>
          </Col>
        </Col>
      </Row>

      <Widget className="widget-p-md">
        <Row className="mb-4">
          <Col>
            <div className="widget-table-overflow mt-3">
              <TableComponentCustom
                titulo={
                  data.typeCheck === "1"
                    ? "CHEQUES POR EMPLEADO"
                    : "CHEQUES POR PENSIONES"
                }
                cabecerasTabla={cabeceras}
                filtro={filtro}
                parametros={parametros}
                addNew={false}
                addDelete={false}
                addEdit={false}
              />
            </div>
          </Col>
        </Row>
        <ModalReports
          modal={modalReports}
          setModal={setModalReports}
          title={
            data.typeCheck === "1"
              ? "REPORTE DE CHEQUES POR EMPLEADO"
              : "REPORTE DE CHEQUES POR PENSIONES"
          }
          backdrop={"static"}
          keyboard={false}
          report={report}
          pdfConfig={pdfConfig}
          generatePdfMethod={{
            method: generatePdfCheques,
            type: "cheques",
          }}
          records={1000}
        />

        <div style={{ display: "none" }}>
          <QRCode
            id="123456"
            value="https://fgjem.edomex.gob.mx/"
            size={290}
            level={"H"}
            includeMargin={true}
          />
        </div>
      </Widget>
    </div>
  );
};
export default Cheques;

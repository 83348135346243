/* eslint-disable jsx-a11y/alt-text */
import Webcam from "react-webcam"
import WebModule from './Interface.module.css'
import { useEffect, useRef, useState } from "react"
import * as faceapi from 'face-api.js'
import { Spinner } from "reactstrap"


export default function CameraInterface({ cameraOpened, setCameraOpened, setFieldValue, handleCloseModal, setLoadingScreenShot }) {
    const [isFlashing, setIsFlashing] = useState(false)
    const [screenshot, setScreenshot] = useState("")
    const [outline, setOutline] = useState("");
    const [message, setMessage] = useState("");
    const webcamRef = useRef(null)
    const containerRef = useRef(null)
    const canvasRef = useRef(null)
    const previewRef = useRef(null)
    const [loading, setLoading] = useState(true);
    

    async function LoadModels() {
        const loadFromOrigin = process.env.REACT_APP_FACE_API_ORIGIN==="true" ? true : false;
        const origin = window.location.origin;
        await Promise.all([
            faceapi.nets.ssdMobilenetv1.loadFromUri(!loadFromOrigin ? `https://cdn.jsdelivr.net/gh/cgarciagl/face-api.js/weights/` : `${origin}/weights/`),
            faceapi.nets.faceLandmark68Net.loadFromUri(!loadFromOrigin ? `https://cdn.jsdelivr.net/gh/cgarciagl/face-api.js/weights/` : `${origin}/weights/`),
            faceapi.nets.faceRecognitionNet.loadFromUri(!loadFromOrigin ? `https://cdn.jsdelivr.net/gh/cgarciagl/face-api.js/weights/` : `${origin}/weights/`),
            faceapi.nets.tinyFaceDetector.loadFromUri(!loadFromOrigin ? `https://cdn.jsdelivr.net/gh/cgarciagl/face-api.js/weights/` : `${origin}/weights/`)
        ])
        setLoading(false)
    }

    useEffect(() => {
        LoadModels()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const takeScreenshot = () => {
        if (webcamRef && webcamRef.current) {
            const screenshot = webcamRef.current.getScreenshot()
            setScreenshot(screenshot ? screenshot : "");
            setIsFlashing(true)
        }
    }

    const handleScreenshot = async (blob) => {
        try {
            if (previewRef && previewRef.current) {
                const face = await faceapi.detectSingleFace(previewRef.current).withFaceLandmarks().withFaceDescriptor();
                if (face) {
                    const displaySize = { width: previewRef.current.width, height: previewRef.current.height }
                    const resizedDetections = faceapi.resizeResults(face, displaySize)
                    setFieldValue("descriptor", resizedDetections.descriptor)
                    setFieldValue("captura", screenshot)
                    setLoadingScreenShot(false)
                    handleCloseModal()
                }
            }
        } catch (err) {
            console.log(err);
        }
    }

    async function handleStreamVideo(e) {

        let counter = 0
        setInterval(async () => {
            if (counter <= 40) {
                if (webcamRef.current) {
                    const video = webcamRef.current.video
                    const faces = await faceapi.detectAllFaces(video, new faceapi.TinyFaceDetectorOptions())
                    if (faces && faces.length === 1 && faces[0].score > 0.5) {
                        counter++
                        setOutline('#00ff00')
                        setMessage(`Mantente asi por ${Math.round(4 - (counter / 10))} segundos.`)
                    } else if (faces && faces.length > 1) {
                        counter = 0
                        setOutline('#f00000')
                        setMessage('Se han detectado multiples participantes, por favor colocate en un lugar más alejado de la gente')
                    } else {
                        counter = 0
                        setOutline('#f00000')
                        setMessage('Por favor colocate sobre el ovalo.')
                    }
                } else {
                    counter = 0
                    setOutline('#f00000')
                    setMessage('Por favor colocate sobre el ovalo.')
                }
            } else {
                setLoadingScreenShot(true)
                takeScreenshot()
            }
        }, 100)
    }

    function handleCameraError() {
        setCameraOpened(false);
    }

    if (loading) {
        return (
            <div className="d-flex flex-column align-items-center justify-content-center" style={{ minHeight: "300px" }}>
                <Spinner animation="grow" variant="primary" />
                <h3 className="mt-5">Cargando camara, espere por favor...</h3>
            </div>
        )
    }
    return (
        <div className={`${WebModule.cameraContainer} mx-auto my-auto border border-info border-5`} ref={containerRef}>
            {cameraOpened && !screenshot &&
                <>
                    <Webcam
                        className={WebModule.cameraVideo}
                        id="webcam"
                        ref={webcamRef}
                        screenshotFormat='image/jpeg'
                        screenshotQuality={1}
                        width={570}
                        height={700}
                        mirrored={true}
                        videoConstraints={{ facingMode: 'user' }}
                        onUserMedia={(e) => handleStreamVideo(e)}
                        onUserMediaError={handleCameraError}
                    />
                    <canvas id={WebModule.cameraCanvas} ref={canvasRef}>Your browser does not support the HTML canvas tag.</canvas>
                    <div className={WebModule.cameraFaceOverlay} style={{ borderColor: outline }}>
                        <div className={WebModule.cameraFaceMessage}>{message}</div>
                    </div>
                </>
            }
            {
                screenshot !== "" && <>
                    <img ref={previewRef}
                        src={screenshot}
                        onLoad={() => handleScreenshot(screenshot)}
                        width={570}
                        height={700}
                        className={WebModule.cameraVideo} />
                    <div className={`${WebModule.cameraCanvas} bg-dark d-flex flex-column align-items-center justify-content-center`} style={{opacity: 0.75, position: "relative", minHeight: "700px"}}>
                        <Spinner animation="grow" variant="primary"/>
                        <h4 className="text-center mt-5 text-white" >Obteniendo datos biometricos, no cierre esta ventana</h4>
                    </div>
                </>
            }
            <div
                className={WebModule.cameraFlash}
                style={{
                    animation: isFlashing ? `${WebModule.flashAnimation} 750ms ease-out` : "",
                }}
                onAnimationEnd={() => setIsFlashing(false)}
            />
        </div>
    )
}
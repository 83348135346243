
import React from "react";
import Widget from "../../../../components/Widget/Widget";
import FormSalario from "../FormSalario";
import { createSalary } from "../functions";

export const CrearSalario = (props) => {

  return (
    <Widget className="widget-p-md">
      <h3>Crear Salario</h3>
      <FormSalario crear={createSalary}/>
    </Widget>
  )
}

export default CrearSalario;
import React, { useState, useEffect } from "react";
import {
  Alert,
  Col,
  Container,
  Row,
  Spinner,
  Collapse,
  Card,
  CardHeader,
  Table,
  Button,
} from "reactstrap";
import { peticionEncript } from "../../../helpers/peticionesEncripted";
import axios from "axios";
import { toast } from "react-toastify";
import Notification from "../../../components/Notification/Notification";
import AsignarCosto from "./AsignarCosto";
import ModalComponentEliminar from "../../../components/Modal/ModalDelete";
import ButtonPdfGastosComponent from "../componentes/others/ButtonPdfGastosComponent";

const fmt = new Intl.NumberFormat("es-MX", {
  style: "currency",
  currency: "MXN",
});

async function doRequest(route, params, seeError, seeOk) {
  let info = [];
  const dataEncrypted = peticionEncript(params);
  await axios
    .post(process.env.REACT_APP_API + route, dataEncrypted)
    .then((res) => {
      if (res.status === 200 && res.data.status === "success") {
        info = res.data.data;
        if (seeOk === 1) {
          toast(
            <Notification
              type={"elimina_exito"}
              backMessage={res.data.message}
              withIcon
            />,
            { closeButton: false }
          );
        }
      } else if (seeError !== 1) {
        toast(
          <Notification
            type={"consultar_error"}
            backMessage={res.data.message}
            withIcon
          />,
          { closeButton: false }
        );
      }
    })
    .catch((err) => {
      toast(<Notification type={"consultar_servidor_error"} withIcon />, {
        closeButton: false,
      });
    });
  return info;
}

const CostosP = ({ proyect, permissions }) => {
  const [loading, setLoading] = useState(true);
  const [actividades, setActividades] = useState([]);
  const [partidas, setPartidas] = useState([]);
  const [usedPartidas, setUsedPartidas] = useState([]);
  const [openAct, setOpenAct] = useState("");
  const [showForm, setShowForm] = useState("");
  const [dataToEedit, setDataToEedit] = useState("");

  /* Cmabia que actividad está abierta*/
  const toggle = (id) => {
    if (showForm === "") {
      if (openAct !== id) setOpenAct(id);
      else setOpenAct("");
    } else {
      toast(
        <Notification
          type="warning"
          backMessage="Guarde sus cambios antes de continuar"
        />,
        { closeButton: false }
      );
    }
  };

  /* Muestra el fromulario de cada actividad */
  const openForm = (id) => {
    if (showForm !== id) {
      setShowForm(id);
    } else {
      toast(
        <Notification
          type="warning"
          backMessage="Guarde sus cambios antes de continuar"
        />,
        { closeButton: false }
      );
    }
  };

  /* Consulta */
  const getActividades = async () => {
    const param = {
      action: "multiselect",
      table: "activities A LEFT JOIN activitiescost B USING (idActivity)",
      rows: "A.idActivity, A.nameActivity, SUM(B.amount) as total, (SELECT GROUP_CONCAT(goal) FROM activitiesplan WHERE idActivity = A.idActivity) AS mesesOK",
      conditions: `A.idProyect = ${proyect.id} AND A.enabled = 1 AND IF(B.enabled IS NULL, TRUE, B.enabled = 1)`,
      group: "A.idActivity",
    };
    const data = await doRequest("receiver/receiver.php", param);
    if (data.length > 0) {
      setActividades(data);
      // calcTotal(data);
      if (partidas.length === 0) getPartidas();
    }

    setLoading(false);
  };

  /* Consulta lo tipos de partidas*/
  const getPartidas = async () => {
    const param = {
      action: "multiselect",
      table: "expense_objects",
      rows: "idExpenseObject, concat(fullKey,' - ',nameObject) as nameObjectFull",
      conditions: "enabled = 1",
      order: "cast(fullKey as float) asc",
    };
    const data = await doRequest("receiver/receiver.php", param);
    if (data.length > 0) setPartidas(data);
    setLoading(false);
  };

  useEffect(() => {
    if (showForm === "") {
      getActividades();
    }
  }, [showForm]);

  /* Calcula el total de todas las partidas de una actividad*/
  const calcTotal = () => {
    if (actividades.length > 0)
      return actividades.reduce((acc, b) => acc + +b.total, 0);
    return 0;
  };

  if (loading)
    return (
      <div style={{ minHeight: "300px" }} className="text-center">
        <Spinner color="primary" style={{ marginTop: "135px" }} />
      </div>
    );

  if (actividades.length < 1)
    return (
      <div style={{ height: "250px" }}>
        <div className="text-center pt-5">No hay actividades registradas</div>
      </div>
    );

  return (
    <Container className="mx-0 mt-2" style={{ maxWidth: "100%" }}>
      <div className="mb-4">
        <h4>Asignación de costos</h4>
      </div>

      <Row>
        <Col xs={12} lg={7} className="mt-2">
          <div>
            <strong>Proyecto </strong>
            <p>{proyect.name}</p>
          </div>
          <strong>Objetivo </strong>
          <p>{proyect.objetive}</p>
        </Col>
        <Col xs={12} lg={5} className="mt-2">
          <Alert
            color="light"
            isOpen
            fade={false}
            className="text-dark p-2"
            style={{
              borderRadius: "0.25rem",
              border: "2px solid #4D53E0",
            }}
          >
            <div className="d-flex justify-content-between">
              <i className="fa fa-money py-2" style={{ fontSize: "55px" }} />
              <div className="text-right">
                <p style={{ fontSize: "30px" }}>{fmt.format(calcTotal())}</p>
                <p>Monto total</p>
              </div>
            </div>
          </Alert>
        </Col>
      </Row>

      <div className="mt-4">
        <p className="text-muted mb-2" style={{ fontSize: "22px" }}>
          Actividades del Proyecto
        </p>

        <div className="mb-5">
          <div className="accordion">
            {actividades.map((el, id) => (
              <Card className="pb-1" key={"cr" + id}>
                <CardHeader
                  onClick={() => toggle(id)}
                  style={{ cursor: "pointer" }}
                >
                  <div className="d-flex justify-content-between">
                    <p>{el.nameActivity}</p>
                    <p>{fmt.format(el.total)}</p>
                  </div>
                </CardHeader>
                <Collapse isOpen={openAct === id && showForm !== id}>
                  <div className="mx-2 mt-2">
                    {proyect.canEdit && permissions.INS && (
                      <Button color="add" onClick={() => openForm(id)}>
                        Agregar
                      </Button>
                    )}
                    <TableCostos
                      activityId={el.idActivity}
                      openAct={openAct}
                      idTab={id}
                      showForm={showForm}
                      setShowForm={setShowForm}
                      dataToEedit={dataToEedit}
                      setDataToEedit={setDataToEedit}
                      getActividades={getActividades}
                      canEdit={proyect.canEdit}
                      setUsedPartidas={setUsedPartidas}
                      permissions={permissions}
                    />
                  </div>
                </Collapse>

                <AsignarCosto
                  activityId={el.idActivity}
                  partidas={partidas}
                  usedPartidas={usedPartidas}
                  setShowForm={setShowForm}
                  openAct={openAct}
                  showForm={showForm}
                  idMap={id}
                  data={dataToEedit}
                  canEdit={proyect.canEdit}
                  mesesOK={el.mesesOK}
                />
              </Card>
            ))}
          </div>
        </div>
      </div>

      <ButtonPdfGastosComponent proyect={proyect} />
    </Container>
  );
};

const TableCostos = ({
  activityId,
  openAct,
  idTab,
  showForm,
  setShowForm,
  dataToEedit,
  setDataToEedit,
  getActividades,
  setUsedPartidas,
  canEdit,
  permissions,
}) => {
  const [pagos, setPagos] = useState([]);
  const [loading, setLoading] = useState(true);
  /* Consulta lo tipos de documentos*/

  const getCantidades = async () => {
    const param = {
      action: "multiselect",
      table:
        "activitiescost A INNER JOIN expense_objects B ON A.idExpenseObject = B.idExpenseObject",
      rows: "A.idPlan, B.nameObject, GROUP_CONCAT(A.amount ORDER BY A.keyMonth ASC) AS amount_months, SUM(A.amount) AS total, A.idExpenseObject, B.fullKey",
      conditions: `A.idActivity = ${activityId} AND A.enabled = 1`,
      group: "A.idActivity, A.idExpenseObject",
    };
    let data = await doRequest("receiver/receiver.php", param, 1);
    const usedPartidas = [];
    if (data.length > 0) {
      data.forEach((el) => {
        const meses = el.amount_months.split(",");
        el.m1 = +meses[0];
        el.m2 = +meses[1];
        el.m3 = +meses[2];
        el.m4 = +meses[3];
        el.m5 = +meses[4];
        el.m6 = +meses[5];
        el.m7 = +meses[6];
        el.m8 = +meses[7];
        el.m9 = +meses[8];
        el.m10 = +meses[9];
        el.m11 = +meses[10];
        el.m12 = +meses[11];
        usedPartidas.push(+el.idExpenseObject);
      });
      setPagos(data);
      setUsedPartidas(usedPartidas);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (openAct === idTab && showForm === "") {
      getCantidades();
      if (dataToEedit !== "") setDataToEedit("");
    }
  }, [openAct, showForm]);

  const getForEdit = (el) => {
    setDataToEedit(el);
    setShowForm(idTab);
  };

  const deleteCosto = async (el) => {
    let params = {
      action: "delete",
      table: "activitiescost",
      condition: {
        idActivity: activityId,
        idExpenseObject: el.idExpenseObject,
      },
    };
    const data = await doRequest("receiver/receiver.php", params, 2, 1);
    if (data.length > 0) {
      getCantidades();
      getActividades();
    }
  };

  return (
    <Table className="table-striped border mt-2" responsive>
      <thead style={{ backgroundColor: "#4D53E050" }}>
        <tr>
          <th className="text-center text-dark">Partida</th>
          <th className="text-center text-dark">Descripción</th>
          <th className="text-center text-dark">Ene</th>
          <th className="text-center text-dark">Feb</th>
          <th className="text-center text-dark">Mar</th>
          <th className="text-center text-dark">Abr</th>
          <th className="text-center text-dark">May</th>
          <th className="text-center text-dark">Jun</th>
          <th className="text-center text-dark">Jul</th>
          <th className="text-center text-dark">Ago</th>
          <th className="text-center text-dark">Sep</th>
          <th className="text-center text-dark">Oct</th>
          <th className="text-center text-dark">Nov</th>
          <th className="text-center text-dark">Dic</th>
          <th className="text-center text-dark">Total</th>
          <th className="text-center text-dark">Acción</th>
        </tr>
      </thead>
      <tbody>
        {pagos.length < 1 ? (
          <tr>
            <td colSpan={16} className="text-center">
              No hay registros
            </td>
          </tr>
        ) : (
          pagos.map((el, id) => (
            <tr
              className="pb-1 border-bottom border-right"
              key={"tp" + id + "-" + activityId}
            >
              <td className="p-1 text-center border-right">{el.fullKey}</td>
              <td
                className="p-1 border-right"
                style={{ width: "80px", maxWidth: "80px" }}
              >
                {el.nameObject}
              </td>
              <td className="text-right border-right p-1">
                <p>{fmt.format(el.m1)}</p>
                <p>...................</p>
              </td>
              <td className="text-right border-right p-1">
                <p>{fmt.format(el.m2)}</p>
                <p>...................</p>
              </td>
              <td className="text-right border-right p-1">
                <p>{fmt.format(el.m3)}</p>
                <p>...................</p>
              </td>
              <td className="text-right border-right p-1">
                <p>{fmt.format(el.m4)}</p>
                <p>...................</p>
              </td>
              <td className="text-right border-right p-1">
                <p>{fmt.format(el.m5)}</p>
                <p>...................</p>
              </td>
              <td className="text-right border-right p-1">
                <p>{fmt.format(el.m6)}</p>
                <p>...................</p>
              </td>
              <td className="text-right border-right p-1">
                <p>{fmt.format(el.m7)}</p>
                <p>...................</p>
              </td>
              <td className="text-right border-right p-1">
                <p>{fmt.format(el.m8)}</p>
                <p>...................</p>
              </td>
              <td className="text-right border-right p-1">
                <p>{fmt.format(el.m9)}</p>
                <p>...................</p>
              </td>
              <td className="text-right border-right p-1">
                <p>{fmt.format(el.m10)}</p>
                <p>...................</p>
              </td>
              <td className="text-right border-right p-1">
                <p>{fmt.format(el.m11)}</p>
                <p>...................</p>
              </td>
              <td className="text-right border-right p-1">
                <p>{fmt.format(el.m12)}</p>
                <p>...................</p>
              </td>
              <td className="text-right border-right p-1">
                <p>{fmt.format(el.total)}</p>
                <p>...................</p>
              </td>
              <td className="p-1" style={{ minWidth: "140px" }}>
                {canEdit ? (
                  <div>
                    {permissions.UPD && (
                      <div
                        style={{ cursor: "pointer" }}
                        onClick={() => getForEdit(el)}
                      >
                        <i
                          className="fa fa-pencil text-warning pr-1"
                          style={{ cursor: "pointer" }}
                        />
                        Editar
                      </div>
                    )}
                    {permissions.DEL && (
                      <ModalComponentEliminar
                        modalTitle="Eliminar"
                        id={el.idPlan}
                        table={["activitiescost"]}
                        nameColumn={["idPlan"]}
                        elimina={() => deleteCosto(el)}
                        specialIco
                      >
                        <h6>
                          ¿Seguro de eliminar el registro "{el.nameObject}"?
                        </h6>
                        No podrá recuperar la información después de ser
                        eliminada
                      </ModalComponentEliminar>
                    )}
                  </div>
                ) : (
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={() => getForEdit(el)}
                  >
                    <i
                      className="fa fa-eye text-info pr-1"
                      style={{ cursor: "pointer" }}
                    />
                    Consultar
                  </div>
                )}
              </td>
            </tr>
          ))
        )}
      </tbody>
    </Table>
  );
};
export default CostosP;

import * as XlsxPopulate from "xlsx-populate";
import { saveAs } from "file-saver";

/**
 * Formateador de numeros de concurrencias
 */
const formatter = new Intl.NumberFormat("es-MX", {
    style: "currency",
    currency: "MXN",
});

/**
 * Hoja de estilos del excel
 */
const styles = {
    title: {
        horizontalAlignment: 'center', bold: true
    },
    bold: {
        bold: true
    },
    nobold: {
        bold: false
    },
    center: {
        horizontalAlignment: 'center'
    },
    headerBorder: {
        bold: "true",
        border: true,
        fill: "BFBFBF",
    },
    header: {
        bold: "true",
        border: true,
        fill: "BFBFBF",
    },
    headerBoderBold: {
        bold: "true",
        border: true,
    },
    contentBorder: {
        border: true,
    },
    contentBorderB: {
        border: true,
        bold: true
    },
    contentBorderRigth: {
        horizontalAlignment: 'right'
    },
    contentBorderRigthB: {
        horizontalAlignment: 'right',
        bold: true
    },
};

export async function saveReport50(report, title, config) {

    const data = [
        { label: "Poder Ejecutivo", value: 0 },
        { label: "Poder Legislativo", value: 0 },
        { label: "Poder Judicial", value: report.montoTotal },
        { label: "Organos Autonomos", value: 0 },
        { label: "Otras Entidades Paraestatales y Organismos", value: 0 },
    ]

    XlsxPopulate.fromBlankAsync().then(async (workbook) => {
        const sheet1 = workbook.sheet(0);
        //Titulo
        sheet1
            .range(`A1:B1`)
            .value(`FISCALIA GENERAL DEL ESTADO DE MÉXICO`)
            .merged(true)
            .style(styles.title);
        sheet1
            .range(`A2:B2`)
            .merged(true)
            .value("SAPP")
            .style(styles.title);
        sheet1
            .range(`A3:B3`)
            .merged(true)
            .value(title)
            .style(styles.title);
        sheet1
            .range(`A4:B4`)
            .merged(true)
            .value("Clasificación Administrativa")
            .style(styles.title);

        sheet1
            .cell(`A5`)
            .value("TOTAL")
            .style(styles.center);

        sheet1
            .cell(`B5`)
            .value("Importe")
            .style(styles.center);


        let index = 6;

        data.forEach(row => {
            sheet1
                .cell(`A${index}`)
                .value(row.label)
                .style(row.value !== 0 ? styles.bold : styles.nobold);
            sheet1
                .cell(`B${index}`)
                .value(formatter.format(row.value))
                .style(row.value !== 0 ? styles.contentBorderRigthB : styles.contentBorderRigth);
            index++;
        })

        sheet1.column("A").width(50);
        sheet1.column("B").width(20);


        //Subtitulos
        return workbook.outputAsync().then((res) => {
            saveAs(res, `${title} - Clasificación Administrativa.xlsx`);
        });
    })
}
import * as Yup from "yup";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { Col, Row, Button } from "reactstrap";
import React, { useState, useEffect } from "react";
import { useFormik } from "formik";

import Loader from "../../../components/Loader/Loader";
import Notification from "../../../components/Notification/Notification";
import ModalConfirmation from "../../../components/Modal/ModalConfirmation";
import { TextInput } from "../../../components/GenericInputsFormik";
import { REGEX_NUMBER } from "../../../helpers/regex";

const FormHorarios = ({ crear, data, editar }) => {

  const defaultForm = {
    idFinancialSource: "",
    nameFinancialSource: "",
    financialSourceKey: "",
  };

  const history = useHistory();

  const [modal, setModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    console.log("Data llegando",data)
    if(data){
      setFieldValue("idFinancialSource",data[0])
      setFieldValue("nameFinancialSource",data[1])
      setFieldValue("financialSourceKey",Number(data[2]))
    }
    setIsLoading(false)
  }, [])


  const FormSchema = Yup.object().shape({
    nameFinancialSource: Yup.string()
      .min(4,"La fuente de financiamiento debe contener al menos 4 caracteres")
      .required("Favor de ingredsar una fuente de financiamiento"),
    financialSourceKey: Yup.string()
      .min(8, "La clave debe contener al menos 8 caracteres")
      .required("Favor de ingresar una clave"),
  });

  const onChange = (e) => {
    const { name, value } = e.target
    if(name === 'financialSourceKey'){
      if(REGEX_NUMBER.test(value)){
        setFieldValue([name], value);
      }
    }
    else{
      setFieldValue([name], value);
    }
  }

  const goBack = (reset) =>{
    reset()
    history.goBack()
  }

  const saveFinances = async() => {
    setIsLoading(true)
    try{
      const response = data ? await editar(values) : await crear(values)

      if(response.success){
        toast(<Notification type={data ? "modifica_exito" :"agrega_exito"} withIcon />);
        localStorage.removeItem('dataContent')
        goBack(resetForm)
      }
      else {
        toast(<Notification type={data ? "modifica_error" : "agrega_error"} backMessage={response.message} withIcon />);
      }
      setIsLoading(false)
    }
    catch(error){
      toast(<Notification type={"consultar_servidor_error"} withIcon />);
      setIsLoading(false)
    }
  }

  const { handleSubmit, values, handleBlur, errors, touched, setFieldValue,resetForm } =
    useFormik({
      initialValues: defaultForm,
      onSubmit: () => setModal(true),
      validationSchema: FormSchema,
  });

  return (
    <>
      {
        isLoading ? <Loader/>
        :
        <form onSubmit={handleSubmit} className="mt-4" noValidate>
          <ModalConfirmation
            modalTitle={data ? "Editar" : "Crear"}
            modal={modal}
            setModal={setModal}
            crear={saveFinances}
            editar={saveFinances}
            isEdit={data ? true : false}
            values={values}
          >
            {data ? (
              <div className="d-flex justify-content-center">
                <h6>¿Está seguro de editar el registro?</h6>
              </div>
            ) : (
              <div className="d-flex justify-content-center">
                <h6>¿Desea continuar con el registro?</h6>
              </div>
            )}
          </ModalConfirmation>

          <Row className="mt-2">
            <Col xs={12} md={6}>
              <TextInput
                label="Fuentes de financiamiento"
                inputType="text"
                inputName="nameFinancialSource"
                onChangeMethod={onChange}
                onBlurMethod={handleBlur}
                value={values.nameFinancialSource}
                isRequired={true}
                touched={touched.nameFinancialSource}
                errors={errors.nameFinancialSource}
                isMax={50}
              />
            </Col>

            <Col xs={12} md={6}>
              <TextInput
                label="Clave"
                inputType="text"
                inputName="financialSourceKey"
                onChangeMethod={onChange}
                onBlurMethod={handleBlur}
                value={values.financialSourceKey}
                isRequired={true}
                touched={touched.financialSourceKey}
                errors={errors.financialSourceKey}
                isMax={8}
              />
            </Col>
          </Row>

          <div className="row mt-3">
            <div className="col-sm-6 order-2 order-sm-1 text-center text-sm-left mb-4">
              <Button color="danger" type="reset" onClick={() => goBack(resetForm)} > Cancelar </Button>
            </div>

            <div className="col-sm-6 order-1 order-sm-2 text-center text-sm-right mb-4">
              <Button color="success" type="submit"> Guardar </Button>
            </div>
            
          </div>
        </form>
      }
    </>
  );
};

export default FormHorarios;


import React from "react";

import Widget from "../../../../components/Widget/Widget";
import FormPuestoTrabajo from "../FormPuestoLaboral";
import { updateJobs } from "../functions";

export const EditarPuestoLaboral = (props) => {
  return (
    <Widget className="widget-p-md">
        <h3>Editar Puesto laboral</h3>
        <FormPuestoTrabajo data={localStorage.getItem('dataContent').split('|-|')} editar={updateJobs}/>
    </Widget>
  )
}

export default EditarPuestoLaboral;
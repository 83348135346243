import React from "react";
import Select from 'react-select'

export const SelectWithSearchInput = (props) => {
  const {
    label,
    inputName,
    inputOptions,
    onChangeMethod,
    onBlurMethod,
    isRequired,
    touched,
    errors,
    isDisabled,
    optionValue,
    optionName,
    inputValue,
    optionSubLabel,
    concatWithoutSpaces,
  } = props;

  const getData = () => {
    if(Array.isArray(optionName)) {
      const result = inputOptions.map(it => {
        let label = ''
        optionName.forEach((element,index) => {
          if(optionSubLabel !== undefined) {
            const subtitle = optionSubLabel[index] === false ? null : optionSubLabel[index];
            if(concatWithoutSpaces === true) {
              label === '' ? label = `${subtitle !== null ? `${subtitle}: `: ''}${it[element]}` : 
              it[element] && (label = label +`-${subtitle !== null ? `${subtitle}: `: ''} `+ it[element])
            } else {
              label === '' ? label = `${subtitle !== null ? `${subtitle}: `: ''}${it[element]}` : 
              it[element] && (label = label +` - ${subtitle !== null ? `${subtitle}: `: ''} `+ it[element])
            }
          }
          else{
            label === '' ? label = `${it[element]}` : 
            it[element] && (label = label +' - '+ it[element])
          }
        });
        return {label: label, value: it[optionValue]}
      })
      return result
    }
    else {
      return inputOptions.map(it => { return {label: it[optionName],value: it[optionValue]} })
    }
  }

  const newValue = inputValue === 0 ? inputValue :
  getData()[getData().findIndex(it => it.value === inputValue)]
  
  return (
    <>
      <div className="form-group">
        <label htmlFor={inputName}> {label} {isRequired && <span className="text-danger">*</span>} </label>
        <Select
          styles={errors && touched ? colourStylesError : colourStyles}
          options={getData()}
          name={inputName}
          id={inputName}
          onChange={onChangeMethod}
          onBlur={onBlurMethod}
          value={newValue}
          isDisabled={isDisabled}
          placeholder = "Buscar"
          required = {isRequired ? true : false}
        />
        {touched && errors && <span style={{ color: "red" }}>{errors}</span>}
      </div>
    </>
  );
};

const colourStyles = {
  control: (styles,state) => ({ ...styles,
    backgroundColor: '#fff',
    height: 45,
    borderRadius: '.5rem',
    paddingLeft: '1rem',
    fontSize: '1rem',
    boxShadow: state.isFocused && 0,
  })
};

const colourStylesError = {
  control: (styles,state) => ({ ...styles,
    borderColor: '#FF4B23',
    backgroundColor: '#fff',
    height: 45,
    borderRadius: '.5rem',
    paddingLeft: '1rem',
    fontSize: '1rem',
    boxShadow: state.isFocused && 0,
  }),
}

import { Button } from "reactstrap";
import * as Yup from "yup"
import { useFormik } from "formik";
import { SelectTypeHeadSingle } from "../../../components/GenericInputsFormik";
import { useState } from "react";
import ModalConfirmation from "../../../components/Modal/ModalConfirmation";
import { peticionesReceiver } from "../../../helpers/peticionesReceiver";
import { toast } from "react-toastify";
import Notification from "../../../components/Notification/Notification";

const CurrencyFormatter = new Intl.NumberFormat("es-MX", {
    style: "currency",
    currency: "MXN",
});

export default function RowPartidas({ item, bienes, bienesOriginal, idPurchaseRequest, idBudgetRequest, idQuote, idArea, setRefresh, setIdPurchaseRequest, setAdded, getPartidas }) {
    const API = peticionesReceiver()

    const [modalConfirmation, setModalConfirmation] = useState(false);

    async function getPurchaseRequest() {
        const params = {
            action: "multiselect",
            table: "purchaserequests",
            rows: "idPurchaseRequest",
            conditions: `keyStat=124 AND idBudgetRequest=${idBudgetRequest} AND idQuote=${idQuote}`
        }
        try {
            const res = await API.peticion(params);
            if (res.status === 200 && res.data.code === "200") {
                if (res.data.data.length > 0) {
                    return res.data.data[0].idPurchaseRequest;
                } else {
                    toast(<Notification type={"agrega_error"} backMessage={"La suficiencia y cotizacion ya se encuentran ligadas"} />);
                }
                return null
            } else {
                toast(<Notification type={"consultar_error"} backMessage={"Ocurrio un error al recuperar la informacion"} />);
                return null
            }
        } catch (err) {
            console.log(err);
            toast(<Notification type={"consultar_servidor_error"} withIcon />);
            return null
        }
    }



    async function addPurchaseRequest() {
        const params = {
            action: "insert",
            table: "purchaserequests",
            rows: {
                idBudgetRequest,
                idQuote,
                idArea,
                keyStat: "124"
            },
            validate: [["idBudgetRequest"], ["idQuote"]]
        }
        try {
            const res = await API.peticion(params);
            if (res.status === 200 && res.data.code === "200") {
                if (res.data.data.length > 0) {
                    toast(<Notification type={"agrega_exito"} backMessage={res.data.message} withIcon />);
                    return res.data.data[0].idPurchaseRequest
                } else {
                    toast(<Notification type={"agrega_error"} backMessage={"Ocurrio un error al agregar la relacion"} />);
                    return null;
                }
            } else {
                const result = await getPurchaseRequest();
                return result
            }
        } catch (err) {
            console.log(err);
            toast(<Notification type={"consultar_servidor_error"} withIcon />);
            return null
        }
    }

    async function getParams(data) {
        const tempRows = { ...data }
        delete tempRows.avaible
        if (idPurchaseRequest !== "") {
            const params = {
                action: "insert",
                table: "purchaserequestdetail",
                rows: tempRows,
                validate: [["idPurchaseRequest", "idExpenseObject", "idDetail"], ["idDetail"]]
            }
            return params;
        } else {
            const idPurchaseRequest = await addPurchaseRequest();
            if (idPurchaseRequest) {
                setIdPurchaseRequest(idPurchaseRequest);
                const params = {
                    action: "insert",
                    table: "purchaserequestdetail",
                    rows: {
                        ...tempRows,
                        idPurchaseRequest
                    },
                    validate: [["idPurchaseRequest", "idExpenseObject", "idDetail"], ["idDetail"]]
                }
                return params
            } else {
                return null;
            }
        }
    }

    async function addObject(data) {
        if (Number(data.avaible) >= Number(data.amount)) {
            const params = await getParams(data);
            if (params) {
                try {
                    const res = await API.peticion(params);
                    if (res.status === 200 && res.data.code === "200") {
                        setRefresh(true)
                        setAdded(true)
                        toast(<Notification type={"agrega_exito"} backMessage={res.data.message} withIcon />);
                    } else {
                        toast(<Notification type={"agrega_error"} backMessage={res.data.message} />);
                    }
                } catch (err) {
                    toast(<Notification type={"consultar_servidor_error"} withIcon />);
                }
            }
        } else {
            toast(<Notification type={"agrega_error"} backMessage={"El monto cotizado supera el monto aprobado"} />);
        }
    }

    const defaultForm = {
        idExpenseObject: item.idExpenseObject,
        idPurchaseRequest,
        idDetail: "",
        amount: "",
        avaible: item.avaible
    }

    const FormSchema = Yup.object().shape({
        idDetail: Yup.string().required("Seleccione un bien valido"),
    })

    const { handleSubmit, values, handleBlur, errors, touched, setFieldValue, resetForm } = useFormik({
        initialValues: defaultForm,
        onSubmit: (values) => {
            setModalConfirmation(true)
        },
        validationSchema: FormSchema,
        enableReinitialize: true,
    });

    const onChange = (e) => {
        const { name, value } = e.target
        const finded = bienesOriginal.find(bien => bien.idDetail === value);
        if (finded) {
            const aprobado = item.approved ? Number(item.approved) : 0;
            const costo = finded.total ? Number(finded.total) : 0
            if (costo > aprobado) {
                toast(<Notification type={"consultar_error"} backMessage={"El costo supera el monto aprobado"} />);
            } else {
                setFieldValue([name], value);
                setFieldValue(["amount"], finded.total);
            }
        }
    }

    return (
        <>
            <tr>
                <td>
                    <div className="d-flex flex-row">
                        <div className="mr-3">{item.name}</div>
                        <div>
                            <b>Monto aprobado:<br />
                                {CurrencyFormatter.format(item.approved)}
                            </b><br />
                            <b>Monto disponible:<br />
                                {CurrencyFormatter.format(item.avaible)}
                            </b>
                        </div>
                    </div>
                </td>
                <td>
                    <form onSubmit={handleSubmit} className="d-flex flex-row">
                        <SelectTypeHeadSingle
                            label="Seleccionar"
                            inputName="idDetail"
                            onChangeMethod={onChange}
                            onBlurMethod={handleBlur}
                            value={values.idDetail}
                            isRequired
                            touched={touched.idDetail}
                            errors={errors.idDetail}
                            optionsArray={bienes}
                            optionValue="idDetail"
                            optionName="desc"
                            style={{ height: "100%" }}
                        />
                        <Button type="submit" size="sm" className="ml-3">Agregar</Button>
                    </form>
                </td>
            </tr>
            {values.idDetail !== "" && values.amount !== "" && values.idDetail !== "" && (
                <ModalConfirmation
                    modalTitle={"Agregar partida presupuestal"}
                    modal={modalConfirmation}
                    setModal={setModalConfirmation}
                    crear={addObject}
                    values={values}
                >
                    <div className="d-flex justify-content-center">
                        <h6>¿Desea continuar con el registro?</h6>
                    </div>
                </ModalConfirmation>
            )}

        </>
    )
}
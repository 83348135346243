/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Nav, NavItem, NavLink, Row } from "reactstrap";
import Widget from "../../../components/Widget/Widget";
import s from "../../personal/expediente-personal/nav.module.scss";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Button } from "reactstrap";
//Componentes
import PercepcionesDeducciones from "./comprobantes/percepcionesDeducciones/PercepcionesDeducciones";
import Testado from "./comprobantes/testado/Testado";
import Pensiones from "./comprobantes/pensiones/Pensiones";
import Cheques from "./comprobantes/cheques/Cheques";
import ComponentesForm from "./componentesForm/ComponentesForm";
import { useHistory } from "react-router";
import { GetPermissions } from "../../../libs/permissions/getPermissions";
import PreNomina from "./comprobantes/PreNomina/PreNomina";

const ComprobantesPago = () => {
  const [permissions, setPermissions] = useState(null);
  GetPermissions(setPermissions);
  const history = useHistory();

  const [validation, setValidation] = useState(false);
  //Variables para el nav

  //Data que se envia a los componentes
  const [finalData, setFinalData] = useState("");

  //Variables para manipular el cambio de pestañas
  const [activeTab, setActiveTab] = useState(1);
  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
      setFinalData("");
      setValidation(false);
    }
  };
  //valores iniciales para el formik
  const [defaultForm] = useState({
    idStaff: "",
    nameStaff: "",
    period: "",
    year: new Date(),
    idPension: "",
    beneficiary: "",
    typeCheck: "1",
  });

  //validaciones del formik
  const FormSchema = Yup.object().shape({
    /* idStaff: Yup.string().when("act", {
      is: (v) => {
        return activeTab === 5 || activeTab === 3 ? true : false;
      },
      otherwise: Yup.string().required("Ingrese una cuenta"),
    }), */

    year: Yup.string().required("Favor de seleccionar un año"),
    nameStaff: Yup.string().when("act", {
      is: (v) => {
        return activeTab === 5 /* || activeTab === 3 */ ? true : false;
      },
      otherwise: Yup.string().required("Seleccione un empleado"),
    }),

    period: Yup.number()
      .max(24, "Periodo debe ser menor a 25")
      .min(1, "Periodo debe ser mayor a 0")
      .required("Ingrese un periodo"),
    typeCheck: Yup.string().when("act", {
      is: (v) => {
        return activeTab === 1 ? true : false;
      },
      then: Yup.string().required("Seleccione un empleado"),
    }),
  });

  const onChange = (e) => {
    setFieldValue([e.target.name], e.target.value);

    setFinalData("");
  };

  const {
    handleSubmit,
    handleBlur,
    values,
    errors,
    touched,
    setFieldValue,
    resetForm,
  } = useFormik({
    initialValues: defaultForm,
    onSubmit: (values) => {
      if (activeTab === 4) {
        if (values.idStaff !== "" || values.idPension !== "") {
          setValidation(false);
          setFinalData({
            idStaff: values.idStaff,
            nameStaff: values.nameStaff,
            period: values.period,
            year: new Date(values.year).getFullYear(),
            beneficiary: values.beneficiary,
            idPension: values.idPension,
            typeCheck: values.typeCheck,
          });
        } else {
          setValidation(true);
        }
      } else {
        setFinalData({
          idStaff: values.idStaff,
          nameStaff: values.nameStaff,
          period: values.period,
          year: new Date(values.year).getFullYear(),
          beneficiary: values.beneficiary,
          idPension: values.idPension,
          typeCheck: values.typeCheck,
        });
      }
    },
    validationSchema: FormSchema,
    enableReinitialize: true,
  });

  //reiniciar form
  useEffect(() => {
    resetForm();
    setFinalData("");
  }, [activeTab]);

  useEffect(() => {
    if (permissions !== null && !permissions.CON) history.push("/template");
  }, [permissions]);

  return (
    <div>
      <Widget className="widget-p-md">
        <div className="headline-1">Comprobantes de pago</div>
        <Nav fill tabs style={{ overflowY: "hidden", overflowX: "auto" }}>
          <NavItem active={activeTab === 1}>
            <NavLink
              href="#"
              active={activeTab === 1}
              onClick={() => toggleTab(1)}
            >
              <i className={s.navIco + " fa fa-list-alt"} />
              Pre-Nomina
            </NavLink>
          </NavItem>
          <NavItem active={activeTab === 2}>
            <NavLink
              href="#"
              active={activeTab === 2}
              onClick={() => toggleTab(2)}
            >
              <i className={s.navIco + " fa fa-pie-chart"} />
              Comprobante de pago nominal
            </NavLink>
          </NavItem>
          <NavItem active={activeTab === 3}>
            <NavLink
              href="#"
              active={activeTab === 3}
              onClick={() => toggleTab(3)}
            >
              <i className={s.navIco + " fa fa-money"} />
              Comprobante testado
            </NavLink>
          </NavItem>
          <NavItem active={activeTab === 4}>
            <NavLink
              href="#"
              active={activeTab === 4}
              onClick={() => toggleTab(4)}
            >
              <i className={s.navIco + " fa fa-money"} />
              Comprobante pensiones
            </NavLink>
          </NavItem>
          <NavItem active={activeTab === 5}>
            <NavLink
              href="#"
              active={activeTab === 5}
              onClick={() => toggleTab(5)}
            >
              <i className={s.navIco + " fa fa-money"} />
              Comprobante cheques
            </NavLink>
          </NavItem>
        </Nav>
        <div className="mt-4">
          <form onSubmit={handleSubmit} className="mt-4" noValidate>
            {activeTab === 1 ? (
              <h1 className="text-secondary text-center">Pre-Nomina</h1>
            ) : activeTab === 2 ? (
              <h1 className="text-secondary text-center">
                Comprobante de pago nominal
              </h1>
            ) : activeTab === 3 ? (
              <h1 className="text-secondary text-center">
                Comprobante testado
              </h1>
            ) : activeTab === 4 ? (
              <h1 className="text-secondary text-center">
                Comprobante Pensiones
              </h1>
            ) : (
              activeTab === 5 && (
                <h1 className="text-secondary text-center">
                  Comprobante Cheques
                </h1>
              )
            )}

            <ComponentesForm
              onChange={onChange}
              handleBlur={handleBlur}
              values={values}
              touched={touched}
              errors={errors}
              setFieldValue={setFieldValue}
              activeTab={activeTab}
              setFinalData={setFinalData}
            />

            {validation && (
              <Row className="justify-content-center">
                <span style={{ color: "red" }} className="text-center">
                  Seleccione un usuario o un beneficiario
                </span>
              </Row>
            )}


            {activeTab !== 1 && (
              <div className="text-center my-3">
              <Button color="success" type="submit">
                Generar
              </Button>
            </div>

            )}
            
          </form>
          {activeTab === 1 ? (
            <>
              <PreNomina />
            </>
          ) : activeTab === 2 && finalData !== "" ? (
            <>
              <PercepcionesDeducciones data={finalData} />
            </>
          ) : activeTab === 3 && finalData !== "" ? (
            <>
              <Testado data={finalData} />
            </>
          ) : activeTab === 4 && finalData !== "" ? (
            <>
              <Pensiones data={finalData} />
            </>
          ) : (
            activeTab === 5 && finalData !== "" && 
            <Cheques data={finalData} />
          )}
        </div>
      </Widget>
    </div>
  );
};
export default ComprobantesPago;
import { useState } from "react";
import Widget from "../../../components/Widget/Widget";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import ConsultaInicial from "./views/ConsultaInicial";
import Detalles from "./views/Detalles";
import { doRequest } from "../../../helpers/requests";
import { Container } from "reactstrap";
import FullFormLoader from "../../../components/Loader/FullFormLoader";
import { GetPermissions } from "../../../libs/permissions/getPermissions";
import { peticionesReceiver } from "../../../helpers/peticionesReceiver";
import { toast } from "react-toastify";
import Notification from "../../../components/Notification/Notification";
import { Loader } from "react-bootstrap-typeahead";

const s = { fontSize: "18px", marginRight: "5px" };

const year = new Date().getFullYear();

export default function SuficienciaPresupuestal() {
  const API = peticionesReceiver();

  const [permissions, setPermissions] = useState({ id: 0 });

  GetPermissions(setPermissions);

  const [budgetrequests, setBudgetrequests] = useState({
    idBudgetRequest: "",
    idArea: "",
    numberRequest: "",
    yearRequest: "",
    justification: "",
    dateSend: "",
    notes: ""
  });
  const [canUse, setCanUse] = useState();
  const [loading, setLoading] = useState(true);
  const [loader, setLoader] = useState(true);

  const [proyectosP, setProyectosP] = useState([]);
  const [capitulos, setCapitulos] = useState([]);

  const { profileType } = useSelector((state) => state.userInfo);
  const idArea = profileType.selectProfile.idArea;
  const [controlStatus, setControlStatus] = useState({ enabled: false });
  const [isAuthorizedArea, setIsAuthorizedArea] = useState(false);

  useEffect(() => {
    getCurrentBudget();
    getControlStatus();
    getAuthorizedAreas();
  }, []);

  const getAuthorizedAreas = async () => {
    const paramsControl = {
      action: "multiselect",
      table: "budgetauthorizedareas",
      rows: "*",
      conditions: "enabled = 1",
    };
    await API.peticion(paramsControl)
      .then((res) => {
        if (res.status === 200 && res.data.code === "200") {
          const aut = res.data.data.find((area) => {
            return area.idArea.toString() === idArea.toString();
          });
          if (aut !== null && aut !== undefined) {
            setIsAuthorizedArea(true);
          }
        } else {
          toast(
            <Notification
              type={"consultar_error"}
              backMessage={res.data.message}
              withIcon
            />
          );
        }
      })
      .catch((err) => {
        toast(<Notification type={"consultar_servidor_error"} withIcon />);
      });
    setLoader(false);
  };

  const getControlStatus = async () => {
    const paramsControl = {
      action: "multiselect",
      table: "budgetcontrol",
      rows: "*",
      conditions: "",
    };
    await API.peticion(paramsControl)
      .then((res) => {
        if (res.status === 200 && res.data.code === "200") {
          setControlStatus(res.data.data[0]);
        } else {
          toast(
            <Notification
              type={"consultar_error"}
              backMessage={res.data.message}
              withIcon
            />
          );
        }
      })
      .catch((err) => {
        toast(<Notification type={"consultar_servidor_error"} withIcon />);
      });
    setLoader(false);
  };

  async function getCurrentBudget() {
    const params = {
      action: "multiselect",
      table: "budgetareas A INNER JOIN annualbudget B USING(idAnualBudget)",
      rows: "A.idBudgetArea, A.idAnualBudget, A.idArea, B.keyBudgetType,  B.budgetYear",
      conditions: `A.enabled = 1 AND  A.idArea = ${idArea} AND B.enabled = 1 AND B.budgetYear = ${year} AND B.keyBudgetType = 3`,
    };
    const res = await doRequest("receiver/receiver.php", params);
    if (res.length > 0) {
      setCanUse(res[0].idBudgetArea);
      getPryPresupuestal();
      getCapitulos(res[0].idBudgetArea);
    }
    setLoading(false);
  }

  async function getPryPresupuestal() {
    const params = { action: "select", table: "statefunds" };
    const data = await doRequest("receiver/receiver.php", params);
    if (data.length > 0) setProyectosP(data);
  }

  async function getCapitulos(idBA) {
    const params = {
      action: "multiselect",
      table:
        "expenses_chapters A INNER JOIN budgetareachapters B USING(keyChapter)",
      rows: "A.keyChapter, CONCAT(A.chapterKey,'000 - ',A.nameChapter) as label",
      conditions: `A.enabled = 1 AND B.enabled = 1 AND B.idBudgetArea = ${idBA}`,
    };
    const data = await doRequest("receiver/receiver.php", params);
    if (data.length > 0) {
      setCapitulos(data);
    }
  }

  return (
    <Widget className="widget-p-md">
      {permissions.CON ? (
        <>
          <h3>Suficiencia presupuestal</h3>
          {!loading ? (
            canUse ? (
              loader ? (
                <Loader />
              ) : budgetrequests.idBudgetRequest === "" ? (
                <ConsultaInicial
                  idArea={idArea}
                  setBudgetrequests={setBudgetrequests}
                  permissions={permissions}
                  controlStatus={controlStatus}
                  isAuthorizedArea={isAuthorizedArea}
                />
              ) : (
                <Detalles
                  soliciutud={budgetrequests}
                  setSolicitud={setBudgetrequests}
                  proyectosP={proyectosP}
                  capitulos={capitulos}
                  permissions={permissions}
                  controlStatus={controlStatus}
                  isAuthorizedArea={isAuthorizedArea}
                />
              )
            ) : (
              <div>
                <Container>
                  <div className="text-center my-5 py-5">
                    <h5>No se puede acceder a este módulo</h5>
                    <p>
                      No hay un presupuesto activo para esta área en el año
                      actual
                    </p>
                  </div>
                </Container>
              </div>
            )
          ) : (
            <FullFormLoader show={loading} />
          )}
        </>
      ) : (
        <Container>
          <div className="text-center my-5 py-5">
            <h5>No tiene permiso para acceder a este módulo</h5>
          </div>
        </Container>
      )}
    </Widget>
  );
}

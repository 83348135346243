import { useHistory } from "react-router"
import Form from "../Form"

export default function AgregarBienMueble() {
    const history = useHistory();
    const returnOp = () => {
        history.push("/template/bienesmuebles")
    }

    return (
        <>
            <Form OnSuccess={returnOp} OnCancel={returnOp} />
        </>
    )
}
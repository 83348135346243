import ModalConfirmation from "../../../../components/Modal/ModalConfirmation";
import { Alert, Button } from "reactstrap";
import { doRequest, doRequestSaveRes } from "../../../../helpers/requests";
import { useEffect, useState } from "react";
import FullFormLoader from "../../../../components/Loader/FullFormLoader";
import { toast } from "react-toastify";
import Notification from "../../../../components/Notification/Notification";

export default function FinalizarCompra({ idPR, goBack }) {
    const [loading, setLoading] = useState(false);
    const [modal, setModal] = useState(false);
    const [error, setError] = useState("");
    const [showButton, setShowButton] = useState(true);

    useEffect(()=>{
    }, []);

    async function send() {
        setLoading(true);
        const params = {
            action: "validatePurchase",
            idPurchaseRequest: idPR
        };
        const res = await doRequestSaveRes("app/facades/budgets/budgetsF.php", params, true, false, true);
        if (res.code === "200"){
            toast(
                <Notification
                  type={"elimina_exito"}
                  backMessage="Recepción de compra completada"
                  withIcon
                />,
                { closeButton: false }
              );
            setError("");
            goBack();
            // setShowButton(false);
        }else{
            setError(res.message);
        }
        setLoading(false);
    }
    /* End Table */

    return (
        <div>
            <div className="my-2">
                <h4>Finalizar compra</h4>
            </div>

            <Alert color="danger" isOpen={error.length > 0} className="m-4">
                <p>{error}</p>
            </Alert>

            <div className=" d-flex justify-content-center mt-4">
                {
                    showButton ?
                        <Button onClick={()=>send()}>Finalizar Compra</Button>
                    :
                    <h6>Compra finalizada</h6>
                }
            </div>

            <FullFormLoader show={loading} />

            {/* <ModalConfirmation
                modalTitle="Guardar"
                modal={modal}
                crear={() => send()}
                setModal={setModal}
            >
                <div className="d-flex justify-content-center">
                    <h6>¿Está seguro de finalizar la compra?</h6>
                </div>
            </ModalConfirmation> */}
        </div>
    );
}

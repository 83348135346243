import { useState } from "react";
import Widget from "../../../components/Widget/Widget";
import { useSelector } from "react-redux";
import { Button, Nav, NavItem, NavLink } from "reactstrap";

import EvidenciaActividades from "./vistas/evidenciaActividades/ExpEvidenciaActividades";
import ExpRegistroAvances from "./vistas/registroAvances/ExpRegistroAvances";
import ExpGantt from "./vistas/expGantt/ExpGantt";
import GastosProyecto from "./vistas/gastosProyecto/GastosProyecto";


const s = { fontSize: "18px", marginRight: "5px" };
const defaultProyect = {
  id: 0,
  name: "",
  objetive: "",
  keyStat: "",
  canEdit: true,
};

const ExpedienteAvances = (props) => {

  const { proyect, setProyect } = props

  const [activeTab, setActiveTab] = useState(1);


  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  return (
    <Widget className="widget-p-md">
      <Nav fill tabs style={{ overflowY: "hidden", overflowX: "auto" }}>
        <NavItem active={activeTab === 1}>
          <NavLink
            href="#"
            active={activeTab === 1}
            onClick={() => toggleTab(1)}
          >
            <i className={"fa fa-cogs"} style={s} />
            Consulta de avances
          </NavLink>
        </NavItem>
        <NavItem active={activeTab === 2}>
          <NavLink
            href="#"
            active={activeTab === 2}
            onClick={() => toggleTab(2)}
          >
            <i className={"fa fa-file-text-o"} style={s} />
            Evidencia de avances
          </NavLink>
        </NavItem>
        <NavItem active={activeTab === 3}>
          <NavLink
            href="#"
            active={activeTab === 3}
            onClick={() => toggleTab(3)}
          >
            <i className={"fa fa-tasks"} style={s} />
            Gantt
          </NavLink>
        </NavItem>
        <NavItem active={activeTab === 4}>
          <NavLink
            href="#"
            active={activeTab === 4}
            onClick={() => toggleTab(4)}
            disabled={!proyect.id > 0}
          >
            <i className={"fa fa-money"} style={s} />
            Costos del proyecto
          </NavLink>
        </NavItem>
      </Nav>
      <div className="mt-2">
        {proyect.id !== 0 && (
          <div className="d-flex justify-content-end mb-2">
            <Button
              className="btn text-light px-2 py-1"
              style={{ backgroundColor: "#cc0a44", border: "none" }}
              onClick={() => setProyect({ ...defaultProyect })}
            >
              Cerrar
              <i className="fa fa-sign-out ml-2" style={{ fontSize: "16px" }} />
            </Button>
          </div>
        )}



        {activeTab === 1 ? (
          <ExpRegistroAvances proyect={proyect} />
        ) : activeTab === 2 ? (
          <EvidenciaActividades proyect={proyect} />
        ) : activeTab === 3 ? (
          <ExpGantt proyect={proyect} />
        ) : activeTab === 4 ? (
          <GastosProyecto proyect={proyect} />) : null
        }
        
      </div>
    </Widget>
  );
};

export default ExpedienteAvances;

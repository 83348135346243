
import React from "react";
import Widget from "../../../../components/Widget/Widget";
import FormPensiones from "../FormPensiones";
import { createPension } from "../functions";

export const CrearIncapacidad = (props) => {

  return (
    <Widget className="widget-p-md">
        <h3>Crear tipo de pension</h3>
        <FormPensiones crear={createPension}/>
    </Widget>
  )
}

export default CrearIncapacidad;
import * as XlsxPopulate from "xlsx-populate";
import { saveAs } from "file-saver";
import getLayout from "../../../../components/excel/layoutExcel";
import { fDatewhitTime, fMoney } from "../../../../libs/tools/format";

const s = {
    tC: { horizontalAlignment: "center", verticalAlignment: "center" },
    tS: { horizontalAlignment: "left" },
    tE: { horizontalAlignment: "right", verticalAlignment: "center" },
    header: {
        horizontalAlignment: "center",
        bold: true,
        verticalAlignment: "center",
        fill: "730A29",
        fontColor: "FFFFFF",
        wrapText: true,
        shrinkToFit: true,
        border: true,
    },
    tt: {
        bold: true,
        verticalAlignment: "center",
        wrapText: true,
        shrinkToFit: true,
        fontSize: 13
    },
    tB: { bold: true },
    tN: {
        border: true,
        wrapText: true,
        shrinkToFit: true,
        verticalAlignment: "center",
    },
    tNC: {
        horizontalAlignment: "center",
        verticalAlignment: "center",
        wrapText: true,
        shrinkToFit: true,
        border: true,
    },
    tNE: {
        horizontalAlignment: "right",
        verticalAlignment: "center",
        wrapText: true,
        shrinkToFit: true,
        border: true,
    },
    wt: { wrapText: true, verticalAlignment: "center" },
};

export async function general(data) {
    const excel = await getLayout();
    const headers = ["", "FOLIO", "MES", "JUSTIFICACIÓN", "SOLICITA", "FECHA RECEPCIÓN", "ESTATUS", "MONTO SOLICITADO"];
    const sizes = [-1, 15, 15, 50, 35, 25, 25, 20];

    if (excel)
        XlsxPopulate.fromDataAsync(excel).then(async (workbook) => {
            const hoja = workbook.sheet(0);
            let cRow = 4;
            hoja.range(`D${cRow}:E${cRow}`).merged(true).value("Poder Judicial del Estado de México").style(s.tt);
            cRow++;
            hoja.range(`D${cRow}:E${cRow}`).merged(true).value("Reporte Suficiencias Presupuestales").style(s.tt);
            cRow+=2;
                        
            for (let i = 1; i < headers.length; i++) {
                hoja.column(i).width(sizes[i]);
                hoja.row(cRow).cell(i).value(headers[i]).style(s.header);
            }
            data.forEach(el => {
                cRow++;
                hoja.row(cRow).cell(1).value(el.folio).style(s.tNC);
                hoja.row(cRow).cell(2).value(el.nameMonth).style(s.tNC);
                hoja.row(cRow).cell(3).value(el.justification).style(s.tN);
                hoja.row(cRow).cell(4).value(el.fullName).style(s.tN);
                hoja.row(cRow).cell(5).value(fDatewhitTime(el.dateSend)).style(s.tNC);
                hoja.row(cRow).cell(6).value(el.nameStat).style(s.tNC);
                hoja.row(cRow).cell(7).value(fMoney(el.requestedAmount)).style(s.tNE);
            });

            return workbook.outputAsync().then((res) => {
                saveAs(res, `Reporte Suficiencias Presupuestales.xlsx`);
            });
        });
}


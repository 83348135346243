import React from "react";

import Widget from "../../../../components/Widget/Widget";
import FormCiudad from "../FormCiudad";
import { updateCity } from "../functions";

export const EditarCiudad = (props) => {
  return (
    <Widget className="widget-p-md">
        <h3>Editar localidad</h3>
        <FormCiudad data={localStorage.getItem('dataContent').split('|-|')} editar={updateCity}/>
    </Widget>
  )
}

export default EditarCiudad;
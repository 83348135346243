import { Button, Col, Row, Spinner } from "reactstrap";
import Widget from "../../../components/Widget/Widget";
import { useEffect, useState } from "react";
import BienesSolicitados from "./Collapse/BienesSolicitados";
import { toast } from "react-toastify";
import Notification from "../../../components/Notification/Notification";
import { peticionesReceiver } from "../../../helpers/peticionesReceiver";
import RegistroCotizaciones from "./Collapse/RegistroCotizaciones";
import EstudiosMercado from "./Collapse/EstudiosMercado";
import { GetPermissions } from "../../../libs/permissions/getPermissions";

export default function CotizarEstudioDeMercado() {
    const [permisos, setPermisos] = useState("")
    const [loading, setLoading] = useState("")
    GetPermissions(setPermisos, setLoading);
    const [tab, setTab] = useState(0)
    const [idCot, setIdCot] = useState("")
    const [cot, setCot] = useState({});
    const API = peticionesReceiver()


    useEffect(() => {
        const idCot = localStorage.getItem("cotestudio")
        if (idCot === null) {
            returnSol()
        } else {
            setIdCot(idCot)
        }
    }, [])

    useEffect(() => {
        if (idCot !== "") {
            getQuote()
        }
    }, [idCot])

    const returnSol = () => {
        localStorage.removeItem("cotestudio")
        window.location.href = "#/template/estudiomercado"
    }

    const getQuote = async () => {
        const params = {
            action: "multiselect",
            table: "quotes q inner join staff s on (q.idStaffRequire=s.idStaff) left join areas a on (a.idArea=q.idArea)",
            rows: "CONCAT(s.name,' ',s.mName,' ',s.pName) as fullName,a.name as area,q.object",
            conditions: `q.enabled=1 AND q.idQuote=${idCot}`
        }
        try {
            const res = await API.peticion(params);
            if (res.status === 200 && res.data.code === "200") {
                const data = res.data.data;
                setCot(data[0])
            } else {
                returnSol()
                toast(<Notification type={"consultar_error"} backMessage={res.data.message} />);
            }
        } catch (err) {
            returnSol()
            toast(<Notification type={"consultar_servidor_error"} withIcon />);
        }
    }
    if (loading) {
        <div className="d-flex align-items-center justify-content-center" style={{ height: "200px" }}>
            <Spinner
                color="primary"
                style={{
                    height: '3rem',
                    width: '3rem'
                }}
                type="grow"
            >
                Loading...
            </Spinner>
        </div>
    }
    if (permisos.CON) {
        return (
            <>
                <Widget className={"p-3"}>
                    <h3>Estudio de mercado</h3>
                    <Row>
                        <Col xs={12} md={6}>
                            <b>Solicitante: </b>{cot.fullName}
                        </Col>
                        <Col xs={12} md={6}>
                            <b>Adscripción: </b>{cot.area}
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <b>Fecha de recepción:</b>
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <Col>
                            <b>Objecto:</b> {cot.object}
                        </Col>
                    </Row>
                    <div className="accordion" id="accordionDatosInmueble">
                        <div className="card">
                            <div className={`card-header bg-secondary p-0 ${tab === 0 ? "rounded-top" : "rounded"}`} id="solic">
                                <h2 className="mb-0">
                                    <button className={`btn btn-link btn-block text-left text-white ${tab === 2 ? "collapsed" : ""}`} style={{ fontSize: "12pt" }} type="button" onClick={() => tab === 0 ? setTab(-1) : setTab(0)}>
                                        Bienes de solicitados
                                    </button>
                                </h2>
                            </div>
                            <div id="collapseAsig" className={`collapse ${tab === 0 ? "show" : ""}`} aria-labelledby="solic" data-parent="#accordionSolic">
                                <div className="card-body">
                                    {idCot !== "" && (
                                        <BienesSolicitados idCot={idCot} />
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="card">
                            <div className={`card-header bg-secondary p-0 ${tab === 1 ? "rounded-top" : "rounded"}`} id="prov">
                                <h2 className="mb-0">
                                    <button className={`btn btn-link btn-block text-left text-white ${tab === 2 ? "collapsed" : ""}`} style={{ fontSize: "12pt" }} type="button" onClick={() => tab === 1 ? setTab(-1) : setTab(1)}>
                                        Registro de cotizaciones
                                    </button>
                                </h2>
                            </div>
                            <div id="collapseAsig" className={`collapse ${tab === 1 ? "show" : ""}`} aria-labelledby="prov" data-parent="#accordionProv">
                                <div className="card-body">
                                    {idCot !== "" && (
                                        <RegistroCotizaciones idCot={idCot} />
                                    )}
                                </div>
                            </div>
                        </div>

                        <div className="card">
                            <div className={`card-header bg-secondary p-0 ${tab === 2 ? "rounded-top" : "rounded"}`} id="comp">
                                <h2 className="mb-0">
                                    <button className={`btn btn-link btn-block text-left text-white ${tab === 2 ? "collapsed" : ""}`} style={{ fontSize: "12pt" }} type="button" onClick={() => tab === 2 ? setTab(-1) : setTab(2)}>
                                        Cuadro comparativo
                                    </button>
                                </h2>
                            </div>
                            <div id="collapseAsig" className={`collapse ${tab === 2 ? "show" : ""}`} aria-labelledby="comp" data-parent="#accordionComp">
                                <div className="card-body">
                                    {idCot !== "" && (
                                        <EstudiosMercado idCot={idCot} />
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="text-center mt-2">
                        <Button color="danger" onClick={returnSol}>Regresar</Button>
                    </div>
                </Widget>
            </>
        )
    } else {
        return (
            <>
                <div className="text-center p-4">
                    No tienes permitido ver los registros de esta seccion
                </div>
                <div className="text-center mt-2">
                    <Button color="danger" onClick={returnSol}>Regresar</Button>
                </div>
            </>
        )
    }

}
import React from 'react'
import { useEffect, useState } from "react";
import { Col, Row, Button } from "reactstrap";
import { SelectTypeHeadSingle } from "../../../../components/GenericInputsFormik/SelectTypeHeadSingle";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import Notification from "../../../../components/Notification/Notification";
import * as Yup from "yup";
import { peticionesReceiver } from "../../../../helpers/peticionesReceiver";
import ModalReports from '../../../../components/Modal/ModalReports';
import generatePDFAvance from '../reportes/avanceProyecto';

  
  const trimesters = [
    { id: 1, value: "1" },
    { id: 2, value: "2" },
    { id: 3, value: "3" },
    { id: 4, value: "4" },
  ];

const HeaderComponent = (props) => {
  const { proyect } = props

  const [totalScore, setTotalScore] = useState("0");
  const [modalReports, setModalReports] = useState(false);
  const [pdfConfig, setPDFConfig] = useState({});
  const [report, setReport] = useState({});

  const API = peticionesReceiver();

  useEffect(() => {
    getTotalScore()
  }, [])


  const getTotalScore = () => {
    const params = {
      action: "getTotalScore",
      idProyect: proyect.id
    }
    
    API.peticionEndPoint(params, "app/facades/planning/planningF.php")
      .then((res) => {
        if (res.status === 200 && res.data.code === "200") {
          setTotalScore(res.data.data[0])
        } else {
          toast(
            <Notification
              type={"consultar_error"}
              backMessage={res.data.message}
              withIcon
            />
          );
        }
      })
      .catch((err) => {
        toast(<Notification type={"consultar_servidor_error"} withIcon />);
      });
  }

  const defaultForm = {
    trimester: "",
  };
  const FormSchema = Yup.object().shape({
    trimester: Yup.number().required("Seleccione un trimestre"),
  });
    
  const onChange = (e) => {
    setFieldValue(e.target.name, e.target.value);
  };
    
  const generarAvanceProyecto = async (values) => {
    const params = {
      action: "getActivitiesResume",
      idProyect: proyect.id,
      trimonth: values.trimester
    }
    const res = await API.peticionEndPoint(params, "app/facades/planning/planningF.php");
    if (res.status === 200 && res.data.code === "200") {
      setReport(res.data.data[0])
      setModalReports(true);
    } else {
      toast(<Notification type={"consultar_servidor_error"} withIcon />);
    }
  }

  const {
    handleSubmit,
    values,
    handleBlur,
    errors,
    touched,
    setFieldValue,
    resetForm,
  } = useFormik({
    initialValues: defaultForm,
    onSubmit: (values) => {
      generarAvanceProyecto(values)
    },
    validationSchema: FormSchema,
    enableReinitialize: true,
    onReset: () => { },
  });

    


  return (
    <>
      <ModalReports
        modal={modalReports}
        setModal={setModalReports}
        title=""
        cabeceras=""
        backdrop={"static"}
        keyboard={false}
        report={report}
        pdfConfig={pdfConfig}
        generatePdfMethod={
          {
            method:  generatePDFAvance,
            type:"generalPDFAvance"
          }
        }
        records={1000}
      />
      <form onSubmit={handleSubmit}>
        <Row className="d-flex justify-content-around align-items-end">
          <Col xs={12} md={12} lg={6} className="text-center">
            <SelectTypeHeadSingle
              label="Seleccione un trimestre"
              isRequired={true}
              inputName="trimester"
              optionsArray={trimesters}
              defaultOption="Seleccione un trimestre"
              onChangeMethod={onChange}
              onBlur={handleBlur}
              value={values.trimester}
              touched={touched.trimester}
              errors={errors.trimester}
              optionValue="id"
              optionName="value"
            />
          </Col>
          <Button color="success" type="submit" className="mb-3" style={{height: '45px'}}>
            Generar Reporte
          </Button>
          <Col xs={6} md={6} lg={3} className={"text-center border border-dark mb-3"/*  +totalScore.progress < 50 ? "bg-danger" : +totalScore.progress < 75 ? "bg-warning" : "bg.success" */}>

            Avance General del proyecto {totalScore.progress ? totalScore.progress : 0}%

          </Col>
        </Row>
      </form>
    </>
  )
}

export default HeaderComponent
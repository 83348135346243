import { Document, Page, Text, View } from "@react-pdf/renderer";
import HeaderReport from "../../../../components/Pdf/Header";
import { StyleSheet } from "@react-pdf/renderer";

const formatter = new Intl.NumberFormat("es-MX", {
  style: "currency",
  currency: "MXN",
});

const colors = {
  backgroundHeaders: "#646464",
};

const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 35,
    paddingHorizontal: 10,
  },
  bodyTableGeneral: {
    paddingTop: 35,
    paddingBottom: 10,
    paddingHorizontal: 10,
  },
  spaceFooterTableGeneral: {
    fontSize: 12,
    bottom: 10,
    left: 0,
    right: 0,
    width: "auto",
    height: 50,
    marginHorizontal: 10,
  },
  spaceFooter: {
    fontSize: 12,
    bottom: 10,
    left: 0,
    right: 0,
    width: "auto",
    height: 50,
    marginHorizontal: 10,
  },
  footer: {
    position: "absolute",
    fontSize: 12,
    bottom: 10,
    left: 0,
    right: 0,
    width: "auto",
    height: 30,
    marginHorizontal: 10,
    /*       borderColor:"#a9a9a9", */
    borderTopWidth: 1,
    flexDirection: "row",
    alignContent: "space-around",
    alignItems: "center",
  },
  title: {
    width: "100%",
    textAlign: "center",
    marginBottom: 10,
    /* color: '#a9a9a9', */
    fontSize: 14,
  },
  subTitle: {
    width: "100%",
    textAlign: "center",
    marginBottom: 10,
    /* color: '#a9a9a9', */
    fontSize: 14,
  },
  textFooter: {
    width: "50%",
    /* color: '#a9a9a9', */
    textAlign: "center",
    fontSize: 8,
  },

  table: {
    display: "table",
    width: "auto",
    /*       borderStyle: "solid", 
      borderColor: "red",
      borderWidth: 1, 
      borderRightWidth: 0, 
      borderLeftWidth: 0 , */
    margin: 10,
  },
  //cabeceras
  tableColHeaderNo: {
    width: "27.5%",
    height: 20,
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    backgroundColor: colors.backgroundHeaders,
    color: "white",
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center",
    fontSize: 9,
  },
  tableColHeaderNoL: {
    width: "50%",
    height: 20,
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: colors.backgroundHeaders,
    color: "white",
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center",
    fontSize: 9,
  },
  tableColHeaderReport: {
    width: "50%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    backgroundColor: colors.backgroundHeaders,
    color: "white",
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center",
    fontSize: 9,
  },
  tableHeaderComplete: {
    width: "100%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    backgroundColor: colors.backgroundHeaders,
    color: "white",
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center",
    fontSize: 9,
  },

  //contenido
  tableColContentNoBI: {
    width: "50%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
    textAlign: "center",
  },

  tableColContentNo: {
    width: "50%",
    borderStyle: "solid",
    borderWidth: 1,
    /* borderLeftWidth: 0,  */
    borderTopWidth: 0,
    flexDirection: "column",
    justifyContent: "space-around",
    alignItems: "center",
    textAlign: "center",
  },

  tableColContentReport: {
    width: "50%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  //filas
  tableRow: {
    flexDirection: "row",
  },

  //Filas de contenido, max 3
  displayContent: {
    paddingLeft: 9, //centra el el content
    margin: 9,
    /* marginTop: 9,
      marginBottom: 9, */
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "space-around",
  },
  displayContentColumn: {
    /* marginTop: 9,
      marginBottom: 9, */
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
  },

  //Tamaño de celda deacuerdo a tamaño del contenido
  cellOneColumn: {
    width: "30%",
    fontSize: 8,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  cell80: {
    width: "90%",
    fontSize: 8,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  cellTwoColumn: {
    width: "63%",
    fontSize: 8,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  /* En caso de usar formato de dos columnas */
  cellTwoColumn2: {
    width: "80%",
    fontSize: 8,
    justifyContent: "center",
    alignItems: "center",
  },
  cellThreeColumn: {
    width: "96.3%",
    fontSize: 8,
  },
  textBold: {
    fontFamily: "Helvetica-Bold",
    /* fontWeight: 900, */
    /* fontFamily: 'Courier-Bold' */
    /* color: 'red' */
  },
});

const insertHeaders = (model) => {
  return (
    <View style={styles.tableRow} fixed>
      <View style={styles.tableColHeaderNoL} fixed>
        <Text>Proyecto Presupuestal</Text>
      </View>
      <View style={styles.tableColHeaderReport} fixed>
        <Text>Fuente de financiamiento</Text>
      </View>
    </View>
  );
};

export function generatePdfPresupuestoAnual(data, model, title, fileNo, cont) {
  //model.id
  console.log("Report", data[0]);
  const contregistro = isNaN(cont) ? 0 : cont;
  return {
    contador: contregistro + data.length,
    report: (
      <>
        <Document>
          <Page style={styles.body}>
            <HeaderReport/>
            <View>
              <Text style={styles.title}>{title}</Text>
            </View>
            <View>
              <Text style={styles.subTitle}>
                {model.filters !== undefined
                  ? model.filters.onlyMessage === true
                    ? model.filters.message
                    : ""
                  : ""}
              </Text>
            </View>
            <View style={styles.table}>
              {/* Cabeceras */}

              {insertHeaders(model)}

              {data.length > 0 &&
                data.map((employee, index) => (
                  <View style={styles.tableRow} wrap={false}>
                    
                    <View style={styles.tableColContentNo}>
                      <Text style={styles.cell80}>
                        <Text style={styles.textBold}>
                          {"Número de presupuesto"}:{" "}
                        </Text>
                        {employee.idBudget}
                      </Text>

                      <Text style={styles.cell80}>
                        <Text style={styles.textBold}>
                          {"Proyecto Presupuestal"}:{" "}
                        </Text>
                        {employee.nameStateFund}
                      </Text>
                      <Text style={styles.cell80}>
                        <Text style={styles.textBold}>
                          {"Fuente de financiamiento"}:{" "}
                        </Text>
                        {employee.nameFinancialSource}
                      </Text>
                      <Text style={styles.cell80}>
                        <Text style={styles.textBold}>{"Partida"}: </Text>
                        {employee.nameObject}
                      </Text>
                    </View>
                    <View style={styles.tableColContentReport}>
                      <View style={styles.displayContent}>
                        <Text style={styles.cellOneColumn}>
                          <Text style={styles.textBold}>{"Enero"}: </Text>
                          {formatter.format(employee.m1)}
                        </Text>
                        <Text style={styles.cellOneColumn}>
                          <Text style={styles.textBold}>{"Febrero"}: </Text>
                          {formatter.format(employee.m2)}
                        </Text>
                      </View>
                      <View style={styles.displayContent}>
                        <Text style={styles.cellOneColumn}>
                          <Text style={styles.textBold}>{"Marzo"}: </Text>
                          {formatter.format(employee.m3)}
                        </Text>
                        <Text style={styles.cellOneColumn}>
                          <Text style={styles.textBold}>{"Abril"}: </Text>
                          {formatter.format(employee.m4)}
                        </Text>
                      </View>
                      <View style={styles.displayContent}>
                        <Text style={styles.cellOneColumn}>
                          <Text style={styles.textBold}>{"Mayo"}: </Text>
                          {formatter.format(employee.m5)}
                        </Text>
                        <Text style={styles.cellOneColumn}>
                          <Text style={styles.textBold}>{"Junio"}: </Text>
                          {formatter.format(employee.m6)}
                        </Text>
                      </View>
                      <View style={styles.displayContent}>
                        <Text style={styles.cellOneColumn}>
                          <Text style={styles.textBold}>{"Julio"}: </Text>
                          {formatter.format(employee.m7)}
                        </Text>
                        <Text style={styles.cellOneColumn}>
                          <Text style={styles.textBold}>{"Agosto"}: </Text>
                          {formatter.format(employee.m8)}
                        </Text>
                      </View>
                      <View style={styles.displayContent}>
                        <Text style={styles.cellOneColumn}>
                          <Text style={styles.textBold}>{"Septiembre"}: </Text>
                          {formatter.format(employee.m9)}
                        </Text>
                        <Text style={styles.cellOneColumn}>
                          <Text style={styles.textBold}>{"Octubre"}: </Text>
                          {formatter.format(employee.m10)}
                        </Text>
                      </View>
                      <View style={styles.displayContent}>
                        <Text style={styles.cellOneColumn}>
                          <Text style={styles.textBold}>{"Noviembre"}: </Text>
                          {formatter.format(employee.m11)}
                        </Text>
                        <Text style={styles.cellOneColumn}>
                          <Text style={styles.textBold}>{"Diciembre"}: </Text>
                          {formatter.format(employee.m12)}
                        </Text>
                      </View>
                      <View style={styles.displayContent}>
                        <Text style={styles.cellOneColumn}>
                          <Text style={styles.textBold}>{"Total"}: </Text>
                          {formatter.format(employee.total)}
                        </Text>
                      </View>
                    </View>
                  </View>
                ))}
            </View>

            <View fixed style={styles.spaceFooter}></View>
            <View fixed style={styles.footer}>
              <Text style={styles.textFooter}>{fileNo}</Text>
              <Text
                style={styles.textFooter}
                render={({ pageNumber, totalPages }) =>
                  `PAGINA : ${pageNumber} / ${totalPages}`
                }
              />
            </View>
          </Page>
        </Document>
      </>
    ),
  };
}

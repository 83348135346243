import moment from "moment";
import { useEffect, useState } from "react";

const defaultTime = {min: 0, seg: 0};

export default function CountdownTimer({seconds, onFinish}){
    const start = moment();
    const [time, setTime] = useState(seconds);
    
    useEffect(()=>{
        const interval = time > 0 && setInterval(()=>{
            setTime(time - 1);
        }, 1000);
        return ()=> { clearInterval(interval); }
    }, [time]);

    useEffect(()=>{
        if(time === 0 && onFinish) onFinish();
    }, [time]);


    return(
        <span className="text-muted">{time}</span>
    )
}

function getSeconds(start){
    const seconds = start.diff(moment(), "seconds") % 60;
    if(seconds < 0) return 0
    return seconds;
}

function getMinutes(start){
    const minutes = start.diff(moment(), "minutes") % 60;
    if(minutes < 0) return 0
    return minutes;
}
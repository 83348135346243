import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import { configureStore } from '@reduxjs/toolkit';
import storage from "redux-persist/lib/storage";
// import { combineReducers,createStore,applyMiddleware, compose  } from 'redux';
import { combineReducers } from 'redux';

import auth from "../reducers/auth";
import userInfo from "../reducers/user";
import navigation from "../reducers/navigation";
// import calendar from "../reducers/calendar";
import users from "../reducers/usersReducers";
// import rootReducer from "../reducers/index";

import { createHashHistory } from "history";
import { connectRouter } from "connected-react-router";

// import { routerMiddleware } from "connected-react-router";
// import ReduxThunk from "redux-thunk";
// import createRootReducer from '../reducers';

const history = createHashHistory();

export function getHistory(){
    return history;
}

const rootReducer = combineReducers({
    router: connectRouter(history),
    auth,
    navigation,
    // calendar,
    users,
    userInfo
});

const persistConfig = { key: "root", version: 1, storage };
const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
    reducer: persistedReducer,
    middleware: getDefaultMiddleware =>
      getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
        }
      }),
});

export const persistor = persistStore(store);
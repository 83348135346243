import React, { useState } from "react";
import { Button, Collapse } from "reactstrap";
import { peticionesReceiver } from "../../../../helpers/peticionesReceiver";
import ModalDelete from "../../../../components/Modal/ModalDelete";
import Datatable from "../../../../components/Datatable/Datatable";
import { toast } from "react-toastify";
import Notification from "../../../../components/Notification/Notification";
import { fMoney } from "../../../../libs/tools/format";

import { Loader } from "react-bootstrap-typeahead";
import FormTiposCompras from "../componentes/FormTiposCompras";

const TiposCompras = (props) => {
  const { permissions } = props;
  const API = peticionesReceiver();
  const [collapse, setCollapse] = useState(false);
  const toggleCollapse = () => setCollapse(!collapse);
  const [data, setData] = useState("");
  const [loading, setLoading] = useState(false);

  const [refresh, setRefresh] = useState(false);

  const headers = [
    "#",
    "Tipo de compra",
    "Monto Minimo",
    "Monto Maximo",
    "Editar",
    "Eliminar",
  ];

  const columns = [
    { data: "index" },
    { data: "nameTypePurchase" },
    {
      data: null,
      render: function (row) {
        return <td className="text-center ">{fMoney(row.minPayment)}</td>;
      },
    },
    {
      data: null,
      render: function (row) {
        return <td className="text-center ">{fMoney(row.maxPayment)}</td>;
      },
    },
    {
      data: null,
      render: function (row) {
        return (
          <td
            onClick={() => {
              changeDataForEdit(row);
            }}
            className="text-center d-flex justify-content-center"
          >
            <i className={" fa fa-pencil text-warning handCursor"} />
          </td>
        );
      },
    },
    {
      data: null,
      render: function (row) {
        return permissions.DEL ? (
          <>
            <ModalDelete
              modalTitle="Eliminar"
              id={row.idSetup}
              table={["filesignsetup"]}
              nameColumn={["idSetup"]}
              elimina={() => deletePurchase(row)}
              setRefresh={setRefresh}
              type="icon"
            >
              <h6>¿Seguro de eliminar el registro?</h6>
              No podrá recuperar la información después de ser eliminada
            </ModalDelete>
          </>
        ) : (
          "Accion no permitida"
        );
      },
    },
  ];

  const changeDataForEdit = (row) => {
    if (!collapse) {
      setData(row);
    } else {
      toast(
        <Notification
          type="warning"
          backMessage="Guarde sus cambios antes de continuar"
        />
      );
    }
  };

  const deletePurchase = async (obj) => {
    const params = {
      action: "delete",
      table: "types_purchases",
      condition: { keyTypePurchase: obj.keyTypePurchase },
      force: 1,
    };

    if (collapse) {
      toast(
        <Notification
          type="warning"
          backMessage="Guarde sus cambios antes de continuar"
        />
      );
    } else {
      await API.peticion(params)
        .then((res) => {
          if (res.data.status === "success" && res.data.code === "200") {
            toast(
              <Notification
                type={"elimina_exito"}
                backMessage={res.data.message}
                withIcon
              />
            );
            setRefresh(true);
          } else {
            toast(
              <Notification
                type={"elimina_error"}
                backMessage={res.data.message}
                withIcon
              />,
              { closeButton: false }
            );
          }
        })
        .catch((err) => {
          toast(<Notification type={"consultar_servidor_error"} withIcon />);
        });
    }
  };

  const columnDefs = [
    {
      targets: [0, 1, 2, 3, 4, 5],
      className: "text-center",
    },
    {
      orderable: false,
      targets: [0],
    },
  ];

  const getParamsTableElmt = (order, records, page, search) => {
    const params = {
      action: "datatable",
      table: "types_purchases",
      rows: "keyTypePurchase,nameTypePurchase,minPayment,maxPayment",
      conditions: `enabled = 1`,
      order,
      records: records.toString(),
      page: (page * records).toString(),
      search,
    };
    return params;
  };

  async function getData(order, records, page, search) {
    try {
      const res = await API.peticion(
        getParamsTableElmt(order, records, page, search)
      );
      if (res.status === 200 && res.data.code === "200") {
        const data = res.data;

        /* data.forEach((value, index) => (value.index = 1 + index)); */
        data.data.forEach((value, index) => (value.index = 1 + index));
        return data;
      } else {
        toast(
          <Notification
            type={"consultar_error"}
            backMessage={res.data.message}
          />
        );
        return null;
      }
    } catch (err) {
      toast(<Notification type={"consultar_servidor_error"} withIcon />);
      return null;
    }
  }

  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <>
          {permissions.CON ? (
            <>
              <div className="text-center mb-5">
                <h1>Tipo de compras</h1>
              </div>

              <div>
                <Datatable
                  headers={headers}
                  columns={columns}
                  columnDefs={columnDefs}
                  petition={getData}
                  control="back"
                  stateRefresh={[refresh, setRefresh]}
                  searching={true}
                  order={{ col: 1, opt: "desc" }}
                />
              </div>

              <div className="d-flex justify-content-end mb-3">
                <Button color="success" onClick={() => toggleCollapse()}>
                  Agregar
                </Button>
              </div>

              <Collapse isOpen={collapse} className="mt-3">
                <FormTiposCompras
                  collapse={collapse}
                  setCollapse={setCollapse}
                  setRefreshP={setRefresh}
                  data={data}
                  setData={setData}
                  permissions={permissions}
                />
              </Collapse>
            </>
          ) : (
            "No tiene permisos para consultar  este modulo"
          )}
        </>
      )}
    </div>
  );
};

export default TiposCompras;

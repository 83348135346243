import React, { useState, useEffect } from "react";
import Notification from "../../../../../components/Notification/Notification";
import { toast } from "react-toastify";
import { peticionesReceiver } from "../../../../../helpers/peticionesReceiver";
import Widget from "../../../../../components/Widget/Widget";
import { Col, Row, Table, Button } from "reactstrap";
import Loader from "../../../../../components/Loader/Loader";
import s from "../../../../../assets/tables/Tables.module.scss";
import sc from "../../../../../components/TableComponent/TableComponentFiltro.module.scss";
import { generatePdfTestado } from "./pdf/PdfTestado";
import ModalReports from "../../../../../components/Modal/ModalReports";
import QRCode from "qrcode.react";
import ChecksInfoGeneral from "./ChecksInfoGeneral";

const API = peticionesReceiver();

const Testado = (props) => {
  const formatter = new Intl.NumberFormat("es-MX", {
    style: "currency",
    currency: "MXN",
  });

  const { data } = props;
  const [staff, setStaff] = useState("");
  const [report, setReport] = useState("");
  const [reportFilter, setReportFilter] = useState("");
  const loading = false;
  const [totalPerceptions, setTotalPerceptions] = useState(0);
  const [totalDeductions, setTotalDeductions] = useState(0);
  const [modalReports, setModalReports] = useState(false);
  const [checks, setChecks] = useState([]);
  const [leyendMessage, setLegendMessage] = useState(false);
  const [pdfConfig, setPdfConfig] = useState({});

  useEffect(() => {
    getStaff();
    getReport();
  }, []);

  const [checksGeneralInfo, setCheckGeneralInfo] = useState({
    nombre: false,
    claveSP: false,
    curp: false,
    rfc: false,
    codigo: false,
    cct: false,
    unidadAdministrativa: false,
    plaza: false,
    pago: false,
    fechaPago: false,
    codigoPuesto: false,
    periodoPago: false,
    puesto: false,
    totalNeto: false,
    claveIssemym: false,
    dependencia: false,
  });
  

  const getStaff = (idStaff) => {
    let staff = [];
    const params = {
      action: "getStaffData",
      idStaff: data.idStaff,
    };
    API.peticionEndPoint(params, "app/facades/reports/reportsF.php")
      .then((res) => {
        if (res.status === 200) {
          setStaff(res.data.data[0]);
        } else {
          toast(
            <Notification
              type={"consultar_error"}
              backMessage={res.data.message}
              withIcon
            />
          );
        }
      })
      .catch((err) => {
        toast(<Notification type={"consultar_servidor_error"} withIcon />);
      });
    return staff;
  };

  const getReport = () => {
    const params = {
      action: "report7",
      search: "",
      order: "under ASC",
      idStaff: data.idStaff,
      period: data.period,
      year: data.year,
    };

    const params2 = {
      action: "multiselect",
      table: "advertisements",
      rows: "*",
      conditions: `period=${data.period} and enabled = 1 and year=${data.year} and aplicationDate <= NOW()`,
    };

    API.peticion(params2)
      .then((res2) => {
        if (
          res2.status === 200 &&
          res2.data.code === "200" &&
          res2.data.data.length > 0
        ) {
          setLegendMessage(false);
        } else {
          setLegendMessage(true);
        }

        API.peticionEndPoint(params, "app/facades/reports/reportsF.php")
          .then((res) => {
            if (res.status === 200 && res.data.code === "200") {
              setChecks(new Array(res.data.data.length).fill(false));
              setReport(res.data.data);
              let totales = { totalPer: 0, totalDeduc: 0 };
              res.data.data.forEach((obj) => {
                if (obj.keyTypeUnder === "1") {
                  totales.totalPer += +obj.amount;
                } else {
                  totales.totalDeduc += +obj.amount;
                }
                setTotalPerceptions(totales.totalPer);
                setTotalDeductions(totales.totalDeduc);
              });
            } else {
              toast(
                <Notification
                  type={"consultar_error"}
                  backMessage={res.data.message}
                  withIcon
                />,
                { closeButton: false }
              );
            }
          })
          .catch((err) => {
            toast(<Notification type={"consultar_servidor_error"} withIcon />, {
              closeButton: false,
            });
          });
      })

      .catch((err) => {
        toast(<Notification type={"consultar_servidor_error"} withIcon />);
      });
  };

  const crearPdf = () => {
    let repFilt = [];
    report.forEach((rep, index) => {
      repFilt.push((rep.show = checks[index]));
    });

    setReportFilter(report);

    if (repFilt.length > 0) {
      generatedQrImagePdfConfig();
    } else {
      toast(
        <Notification
          type={"error"}
          backMessage={"NO HA SELECCIONADO NINGUN CONCEPTO"}
          withIcon
        />,
        {
          closeButton: false,
        }
      );
    }
  };

  const changeCheck = (e, index, obj) => {
    let tmpChecks = checks.slice(0, checks.length);
    tmpChecks[index] = e;
    setChecks(tmpChecks);
  };

  const generatedQrImagePdfConfig = () => {
    const canvas = document.getElementById("123456");
    const pngUrl = canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    setPdfConfig({
      period: `${data.period}/${data.year} `,
      pngUrl: pngUrl,
      filters: checksGeneralInfo,
    });
    setModalReports(true);
  };

  return (
    <div>
      {leyendMessage && (
        <div className="text-center mb-5 ">
          <span className="headline-1" style={{ color: "red" }}>
            {`Aún no se ha liberado el comprobante correspondiente al periodo ${data.period} y año  ${data.year}`}{" "}
          </span>
        </div>
      )}
      <Row className="d-flex  justify-content-around mb-5">
        <Col
          xs={12}
          md={12}
          lg={6}
          className="d-flex flex-column justify-content-center"
        >
          <Row>
            <Col className="ml-5">
              Nombre: {staff && staff.name ? staff.name : ""}
            </Col>
          </Row>
          <Row>
            <Col className="ml-5">
              Puesto: {staff && staff.nameJob ? staff.nameJob : ""}
            </Col>
          </Row>
          <Row>
            <Col className="ml-5">
              Adscripcion: {staff && staff.nameArea ? staff.nameArea : ""}
            </Col>
          </Row>
          <Row>
            <Col className="ml-5">
              Periodo: {data.period}/{data.year}
            </Col>
          </Row>
        </Col>

        <Col
          xs={12}
          md={12}
          lg={6}
          className="d-flex flex-column justify-content-center"
        >
          <Col className="text-center">
            <Button
              color="success"
              className="text-center"
              onClick={() => crearPdf()}
              disabled={
                report !== "" && report.length !== 0 && report !== undefined
                  ? false
                  : true
              }
            >
              Generar Pdf
            </Button>
          </Col>
        </Col>
      </Row>

      <ChecksInfoGeneral
        data={checksGeneralInfo}
        checksGeneralInfo={checksGeneralInfo}
        setCheckGeneralInfo={setCheckGeneralInfo}
        
      />

      <Widget className="widget-p-md">
        <Row className="mb-4">
          <Col>
            <div className="widget-table-overflow mt-3">
              <Table
                className={`table-striped table-borderless table-hover ${s.statesTable}`}
                responsive
              >
                <thead className="border-bottom border-secondary">
                  <tr className={sc.userSelectNone}>
                    <th className="text-center"> </th>
                    <th className="text-center">Clave</th>
                    <th className="text-center">Concepto</th>
                    <th className="text-center">Percepciones</th>
                    <th className="text-center">Deducciones</th>
                  </tr>
                </thead>
                <tbody>
                  {loading ? (
                    <tr key={"loading"}>
                      <td colSpan={5}>
                        <Loader />
                      </td>
                    </tr>
                  ) : report === "" ? (
                    <tr key={"nothing"}>
                      <td colSpan={5} className="text-center">
                        No se encontraron registros
                      </td>
                    </tr>
                  ) : (
                    report.length !== "" &&
                    report.map((registro, index) => (
                      <tr key={"R" + index}>
                        <td className="text-center">
                          {
                            <div className="form-check checkbox checkbox-primary d-flex justify-content-center">
                              <input
                                id={registro.idPayslip + index}
                                name={registro.idPayslip + index}
                                type="checkbox"
                                className="styled"
                                value={checks[index]}
                                checked={checks[index]}
                                onChange={(e) =>
                                  changeCheck(e.target.checked, index, registro)
                                }
                              />
                              <label />
                            </div>
                          }
                        </td>
                        <td className="text-center">{registro.under}</td>
                        <td className="text-left">{registro.nameUnder}</td>
                        <td className="text-right">
                          {registro.keyTypeUnder === "1"
                            ? formatter.format(registro.amount)
                            : "-"}
                        </td>
                        <td className="text-right">
                          {registro.keyTypeUnder !== "1"
                            ? formatter.format(registro.amount)
                            : "-"}
                        </td>
                      </tr>
                    ))
                  )}
                  {report !== "" && (
                    <tr>
                      <td colSpan={3} className="text-center">
                        Total
                      </td>
                      <td className="text-right">
                        {formatter.format(totalPerceptions)}
                      </td>
                      <td className="text-right">
                        {formatter.format(totalDeductions)}
                      </td>
                    </tr>
                  )}
                  {report !== "" && (
                    <tr>
                      <td colSpan={3} className="text-center">
                        Total neto
                      </td>

                      <td colSpan={2} className="text-right">
                        {formatter.format(totalPerceptions - totalDeductions)}
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </div>
          </Col>
        </Row>
        <ModalReports
          modal={modalReports}
          setModal={setModalReports}
          title={"REPORTE TESTADO"}
          backdrop={"static"}
          keyboard={false}
          report={reportFilter}
          pdfConfig={pdfConfig}
          generatePdfMethod={{
            method: generatePdfTestado,
            type: "testado",
          }}
          records={1000}
        />

        <div style={{ display: "none" }}>
          <QRCode
            id="123456"
            value="https://fgjem.edomex.gob.mx/"
            size={290}
            level={"H"}
            includeMargin={true}
          />
        </div>
      </Widget>
    </div>
  );
};
export default Testado;

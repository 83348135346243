  import React, { useState } from "react";
import Loader from "../../../components/Loader/Loader";
import Widget from "../../../components/Widget/Widget";
import { GetPermissions } from "../../../libs/permissions/getPermissions";
import Datatable from "../../../components/Datatable/Datatable";
import { peticionesReceiver } from "../../../helpers/peticionesReceiver";
import { toast } from "react-toastify";
import Notification from "../../../components/Notification/Notification";
import { Button, Collapse } from "reactstrap";
import { useSelector } from "react-redux";
import ModalDelete from "../../../components/Modal/ModalDelete";
import FormSolicitudes from "./components/FormSolicitudes"
import FormDetails from "./components/FormDetails"

const SolicitudArticulos = () => {
  const [permissions, setPermissions] = useState(true);
  const [loading, setLoading] = useState(true);
  const API = peticionesReceiver();

  GetPermissions(setPermissions, setLoading);

  const [refresh, setRefresh] = useState(false);
  const [collapse, setCollapse] = useState(false);
  const [trasnferRequest, setTransferRequest] = useState(undefined); // object || undefined
  const { profileType } = useSelector((state) => state.userInfo);
  const idAreaLogged = profileType.selectProfile.idArea;
  

  const headers = [
    "#",
    "Salida",
    "Tipo de salida",
    "Adscripcion Solicitante",
    "Adscripcion que autoriza",
    "Estatus",
    "Fecha de Salida",
    "Ver Detalle",
    "Eliminar",
  ];
  

  const columns = [
    { data: "index" },
    { data: "numberExit" },
    { data: "nameTypeExit" },
    { data: "nameAreaOrigin" },
    { data: "nameAreaDestiny" },
    { data: "nameStat" },
    {
      data: null,
      render: function (row) {
        return (
          <td className="text-center">
            {row.dateApproved ? row.dateApproved : "No asignada" }
          </td>
        );
      },
    },
    {
      data: null,
      render: function (row) {
        return (
          <td
            onClick={() => {
              showDetail(row);
            }}
            className="text-center d-flex justify-content-center"
          >
            <i className={" fa fa-eye text-info handCursor"} />
          </td>
        );
      },
    },
    {
      data: null,
      render: function (row) {
        return permissions.DEL && row.keyStat === "136" ? (
          <div className="text-center">
            <ModalDelete
              table={["warehouseexits"]}
              nameColumn={["idExit"]}
              elimina={() => deleteFurniture(row)}
              setRefresh={setRefresh}
              type="icon"
            >
              <h6>¿Seguro de eliminar el registro?</h6>
              No podrá recuperar la información después de ser eliminada
            </ModalDelete>
          </div>
        ) : (
          <div className="text-center">
            No Permitido
          </div>
        );
      },
    },
    
  ];

  const deleteFurniture = async (obj) => {
    const params = {
      action: "delete",
      table: "warehouseexits",
      condition: { idExit: obj.idExit },
      force: 1,
    };

    await API.peticion(params)
      .then((res) => {
        if (res.data.status === "success" && res.data.code === "200") {
          toast(
            <Notification
              type={"elimina_exito"}
              backMessage={res.data.message}
              withIcon
            />
          )
          setRefresh(true);
        } else {
          toast(
            <Notification
              type={"elimina_error"}
              backMessage={res.data.message}
              withIcon
            />,
            { closeButton: false }
          );
        }
      })
      .catch((err) => {
        toast(<Notification type={"consultar_servidor_error"} withIcon />);
      });
  };

  const showDetail = (row) => {
   
    setTransferRequest(row);
  };

  const columnDefs = [
    {
      targets: [0, 1, 2, 3, 4, 5],
      className: "text-center",
    },
    {
      orderable: false,
      targets: [0],
    },
  ];

  const getParamsTableElmt = (order, records, page, search) => {
    const params = {
      action: "datatable",
      table:
        "warehouseexits A LEFT JOIN  types_warehouseexits B on (A.keyTypeExit = B.keyTypeExit) LEFT JOIN areas C on (A.idAreaRequire = C.idArea) LEFT JOIN areas D on (A.idAreaDestiny = D.idArea)  LEFT JOIN status E on (A.keyStat = E.keyStat)  ",
      rows: "A.idExit,A.numberExit,B.nameTypeExit,C.name as nameAreaOrigin, D.name as nameAreaDestiny,E.nameStat,A.dateApproved,E.keyStat,A.idArea ",
      conditions: `A.enabled = 1 and A.idAreaRequire = ${idAreaLogged}`,
      order,
      records: records.toString(),
      page: (page * records).toString(),
      search,
    };
    return params;
  };

  async function getData(order, records, page, search) {
    try {
      const res = await API.peticion(
        getParamsTableElmt(order, records, page, search)
      );
      if (res.status === 200 && res.data.code === "200") {
        const data = res.data;

        /* data.forEach((value, index) => (value.index = 1 + index)); */
        data.data.forEach((value, index) => (value.index = 1 + index));
        return data;
      } else {
        toast(
          <Notification
            type={"consultar_error"}
            backMessage={res.data.message}
          />
        );
        return null;
      }
    } catch (err) {
      toast(<Notification type={"consultar_servidor_error"} withIcon />);
      return null;
    }
  }

  return (
    <Widget className="widget-p-md">
      {loading ? (
        <div className="text-center">
          <Loader />
        </div>
      ) : (
        <>
          {permissions.CON ? (
            <>
              <Collapse isOpen={trasnferRequest !== undefined ? false : true}>
                <div className="text-center">
                  <h1> Solicitud de articulos </h1>
                </div>
                <Datatable
                  headers={headers}
                  columns={columns}
                  columnDefs={columnDefs}
                  petition={getData}
                  control="back"
                  stateRefresh={[refresh, setRefresh]}
                  searching={true}
                  order={{ col: 1, opt: "desc" }}
                />

                {permissions.INS && (
                  <div className="text-right">
                    <Button
                      color={collapse ? "danger" : "success"}
                      onClick={() => {
                        setCollapse(!collapse);
                      }}
                    >
                      {collapse ? "Cancelar" : "Agregar Solicitud"}
                    </Button>
                  </div>
                )}

                <Collapse isOpen={collapse}>
                  <FormSolicitudes
                    collapse={collapse}
                    permissions={permissions}
                    setTrasnferRequest={setTransferRequest}
                    setRefresh={setRefresh}
                    setCollapse={setCollapse}
                    idAreaLogged={idAreaLogged}
                  />
                </Collapse>
              </Collapse>

              <Collapse isOpen={trasnferRequest !== undefined ? true : false}>
                <div className="text-left">
                  <Button
                    color="danger"
                    onClick={() => {
                      showDetail(undefined);
                    }}
                  >
                    Regresar
                  </Button>
                </div>
                {trasnferRequest !== undefined && (
                   <FormDetails
                    collapse={collapse}
                    permissions={permissions}
                    trasnferRequest={trasnferRequest}
                    setTrasnferRequest={setTransferRequest}
                    idAreaLogged={idAreaLogged}
                    setRefreshPrinc={setRefresh}
                  /> 
                )}
              </Collapse>
            </>
          ) : (
            "No tiene permiso de consultar este modulo"
          )}
        </>
      )}
    </Widget>
  );
};

export default SolicitudArticulos;
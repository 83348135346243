import React, { useState, useEffect } from "react";
import Widget from "../../../components/Widget/Widget";
import TableComponentCustom from "../../../components/TableComponent/TableComponentCustom";
import { toast } from "react-toastify";
import Notification from "../../../components/Notification/Notification";
import { peticionesReceiver } from "../../../helpers/peticionesReceiver";
import Datatable from "../../../components/Datatable/Datatable";
import { useSelector } from "react-redux";
import { Button } from "reactstrap";
import Reportes from "./Reportes";
import { GetPermisos } from "../../../libs/permissions/hook";
/* import ModalReports from "../../../components/Modal/ModalReports";
import {generatePdfPresupuestoAutorizados} from "./PdfPresupuestoAutorizado" */

const PresupuestoAutorizado = () => {
  //variables de control
  const API = peticionesReceiver();
  const [anualBudget, setAnualBudget] = useState("");
  const user = useSelector((state) => state.userInfo);


  const [permissionsForm, setPermissionsForm] = useState("");

    GetPermisos(setPermissionsForm);

  //Primera Tabla
  const openSecondTable = (data) => {
    setAnualBudget(data);
  };
  const headersBudget = [
    "#", //0
    "Año",
    "Tipo",
    "Cantidad Total",
    "",
  ];

  const columnsBudget = [
    { data: "index" },
    { data: "budgetYear" },
    { data: "budgetType" },
    { data: "amount" },
    {
      data: null,
      render: function (row) {
        return (
          <>
            <i
              className="fa fa-eye text-info"
              style={{ cursor: "pointer" }}
              onClick={() => openSecondTable(row)}
            />
          </>
        );
      },
    },
  ];

  function getParamsBudget(order, records, page, search) {
    return {
      action: "getCurrentBudget",
      page: (page * records).toString(),
      records: records.toString(),
      search,
      order,
    };

  }

  async function getDataBudget(order, records, page, search) {
    try {
      const res = await API.peticionEndPoint(
        getParamsBudget(order, records, page, search),
        "app/facades/budgets/budgetsF.php"
      );
      if (res.status === 200 && res.data.code === "200") {
        const data = res.data;
        data.data.forEach((value, index) => (value.index = page + 1 + index));
        return data;
      } else {
        toast(
          <Notification
            type={"consultar_error"}
            backMessage={res.data.message}
          />
        );
        return null;
      }
    } catch (err) {
      toast(<Notification type={"consultar_servidor_error"} withIcon />);
      return null;
    }
  }

  /* ---------------------------Segunda tabla---------------------------------------- */

  const formatter = new Intl.NumberFormat("es-MX", {
    style: "currency",
    currency: "MXN",
  });

  //Second Area
  const headersAreaChap = [
    "Nombre de capitulo",
    "Cantidad Total",
  ];

  const columnsAreaChap = [
    { data: "nameChapter" },
    { data: "amount" },
  ];

  function getParamsAreaChap(order, records, page, search) {
    return {
        action: "getAreaChapters",
        page: (page * records).toString(),
        records: records.toString(),
        search,
        order,
        idArea: user.profileType.selectProfile.idArea,
    }
}

function parseToNumber(cadenaPrecio) {
    // Eliminar cualquier carácter no numérico excepto el punto decimal
    cadenaPrecio = cadenaPrecio.replace(/[^0-9.]/g, '');
    
    // Convertir la cadena a un número decimal
    var precio = parseFloat(cadenaPrecio);
    
    // Devolver el precio convertido
    return precio;
  }

async function getDataAreaChap(order, records, page, search) {
    try {
        const res = await API.peticionEndPoint(getParamsAreaChap(order, records, page, search), "app/facades/budgets/budgetsF.php")
        if (res.status === 200 && res.data.code === "200") {
            const data = res.data;
            let total = 0 
            data.data.map((chapter,index) => {
                console.log("sumando", parseToNumber(chapter.amount))
                total += parseToNumber(chapter.amount)
            })
            data.data.push({nameChapter: "Total" ,amount: formatter.format(total)})
            return data;
        } else {
            toast(<Notification type={"consultar_error"} backMessage={res.data.message} />);
            return null;
        }
    } catch (err) {
        toast(<Notification type={"consultar_servidor_error"} withIcon />);
        return null
    }
}



  /* function getParamsAreaChap(order, records, page, search) {
    return {
      action: "getAreaChapters",
      idArea: user.profileType.selectProfile.idArea,
      page,
      records,
      search,
      order: "",
    };
  } */

  /* async function getDataAreaChap(order, records, page, search) {
    try {
      const res = await API.peticionEndPoint(
        getParamsAreaChap(order, records, page, search),
        "app/facades/budgets/budgetsF.php"
      );
      if (res.status === 200 && res.data.code === "200") {
        const data = res.data;
        data.data.forEach((value, index) => (value.index = page + 1 + index));
        return data;
      } else {
        toast(
          <Notification
            type={"consultar_error"}
            backMessage={res.data.message}
          />
        );
        return null;
      }
    } catch (err) {
      toast(<Notification type={"consultar_servidor_error"} withIcon />);
      return null;
    }
  } */

  const columnDefsBudget = [
    { targets: [0, 1, 4], className: "text-center" },
    { targets: [3], orderable: false, className: "text-right" },
  ];
  const columnDefsAreaChap = [
    { targets: [1], className: "text-right" },
  ];

  /* const [paramsAreaChapters] = useState({
    action: "getAreaChapters",
    rows: "A.idAnualBudget, A.budgetYear, t.nameBudgetType, A.amount", ///sin espacios y comas
    page: 0,
    records: 5,
    search: "",
    
  }); */

  return (
    <Widget className={"widget-p-md"}>
      {anualBudget !== "" && (
        <div className="text-left">
          <Button
            color="primary"
            onClick={() => {
              setAnualBudget("");
            }}
          >
            {" "}
            Regresar
          </Button>
        </div>
      )}

      <p className="headline-1 text-center mt-5 mb-3">
        PRESUPUESTO AUTORIZADO {anualBudget.budgetYear}
      </p>
      {permissionsForm.CON === "1" ?
      (
        anualBudget === "" ? (
            <>
              <Datatable
                headers={headersBudget}
                petition={getDataBudget}
                columns={columnsBudget}
                order={{ col: 1, opt: "asc" }}
                columnDefs={columnDefsBudget}
                eventsRow={{onDoubleClick: openSecondTable}}
                control="back"
                key={"currentBudget"}
              />
            </>
          ) : (
            <>
              {/* <h1> {user.profileType.selectProfile.areaName} </h1> */}
    
              <div className="text-center flex flex-row mt-5 mb-5">
                <p className="headline-2 text-center mb-3 ">
                  {user.profileType.selectProfile.areaName}
                </p>
    
                <Reportes
                  idArea={user.profileType.selectProfile.idArea}
                  nameArea={user.profileType.selectProfile.areaName}
                  textYear={`PRESUPUESTO AUTORIZADO ${anualBudget.budgetYear}`}
                />
              </div>
    
              <Datatable
                headers={headersAreaChap}
                petition={getDataAreaChap}
                columns={columnsAreaChap}
                searching={false}
                /* order={{ col: 1, opt: "asc" }} */
                columnDefs={columnDefsAreaChap}
                control="back"
                key={"area"}
                paging={false}
                ordering={false}
                info={false}
              />
            </>
          )
      ):
      (
        (<div className="text-center p-4">No tienes permitido ver los registros de esta seccion</div>)
      )
      
    
    }
      
    </Widget>
  );
};

export default PresupuestoAutorizado;

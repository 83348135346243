import { Document, Page, Text, View, Image } from "@react-pdf/renderer";
import { styles } from "../../../../../components/Pdf/pdfGeneral/EstiloPdf";
import { peticionesReceiver } from "../../../../../helpers/peticionesReceiver";
import Notification from "../../../../../components/Notification/Notification";
import HeaderReport from "../../../../../components/Pdf/Header";
import { toast } from "react-toastify";

const formatter = new Intl.NumberFormat("es-MX", {
  style: "currency",
  currency: "MXN",
});

const API = peticionesReceiver();

let deductionsPerceptions = {};

const datatypeUnder = (data) => {
  const type = {
    headers: ["Concepto", "Concepto", "Importe"],
    perceptions: [],
    deductions: [],
    totalPerceptions: ["Total de percepciones"],
    totalDeductions: ["Total de deducciones"],
    total: ["Total de neto"],
  };
  let totalPerceptions = 0,
    totalDeductions = 0;
  data.forEach((obj) => {
    if (obj.keyTypeUnder === 1 || obj.keyTypeUnder === "1") {
      // type.perceptions.push([obj.under, obj.nameUnder, obj.amount]);

      let prev = type.perceptions.find((perceptions) => {
        return perceptions.under === obj.under;
      });

      if (prev) {
        const foundIndex = type.perceptions.indexOf(prev);
        type.perceptions[foundIndex].amount += +obj.amount;
      } else {
        type.perceptions.push({
          under: obj.under,
          nameUnder: obj.nameUnder,
          amount: +obj.amount,
        });
      }

      totalPerceptions += obj.amount ? +obj.amount : 0;
    } else {
      let prev = type.deductions.find((deduction) => {
        return deduction.under === obj.under;
      });

      if (prev) {
        const foundIndex = type.deductions.indexOf(prev);
        type.deductions[foundIndex].amount += +obj.amount;
      } else {
        type.deductions.push({
          under: obj.under,
          nameUnder: obj.nameUnder,
          amount: +obj.amount,
        });
      }

      totalDeductions += obj.amount ? +obj.amount : 0;
    }
  });
  type.totalPerceptions.push(totalPerceptions);
  type.totalDeductions.push(totalDeductions);

  deductionsPerceptions = type;

};

const insertTitles = (model, title) => {
  return (
    <>
      <HeaderReport />
      <View>
        <Text style={styles.title}>{title}</Text>
      </View>
      <View>
        <Text style={styles.subTitle}>
          {model.filters !== undefined
            ? model.filters.onlyMessage === true
              ? model.filters.message
              : ""
            : ""}
        </Text>
      </View>
    </>
  );
};

const insertStaff = (staff) => {
  return (
    <>
      <View style={styles.table}>
        <View style={styles.cellHeader100Borders}>
          <Text>{"Personal general"}</Text>
        </View>
        <View style={styles.tableRow}>
          <View style={styles.tableRowContainer}>
            <View style={styles.cell50BorderSides}>
              <Text>
                <Text style={styles.textBold}>NO. EMPLEADO: </Text>
                {staff.staffNumber}
              </Text>
            </View>
            <View style={styles.cell50}>
              <Text>
                <Text style={styles.textBold}> NOMBRE: </Text> {staff.name}{" "}
              </Text>
            </View>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={styles.tableRowContainer}>
            <View style={styles.cell50BorderSides}>
              <Text>
                <Text style={styles.textBold}> ADSCRIPCION: </Text>{" "}
                {staff.nameArea}{" "}
              </Text>
            </View>
            <View style={styles.cell50}>
              <Text>
                <Text style={styles.textBold}> REGION: </Text>{" "}
                {staff.nameBuilding}{" "}
              </Text>
            </View>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={styles.tableRowContainer}>
            <View style={styles.cell33BorderSides}>
              <Text>
                <Text style={styles.textBold}> PUESTO LABORAL: </Text>{" "}
                {staff.nameJob}{" "}
              </Text>
            </View>
            <View style={styles.cell33BorderRigth}>
              <Text>
                <Text style={styles.textBold}> NIVEL-RANGO: </Text>{" "}
                {staff.levelrange}{" "}
              </Text>
            </View>
            <View style={styles.cell33}>
              <Text>
                <Text style={styles.textBold}> TIPO NOMBRAMIENTO: </Text>{" "}
                {staff.nameAssignment}{" "}
              </Text>
            </View>
          </View>
        </View>

        <View style={styles.tableRow}>
          <View style={styles.tableRowContainer}>
            <View style={styles.cell33BorderSides}>
              <Text>
                <Text style={styles.textBold}> FECHA DE INGRESO: </Text>{" "}
                {staff.startJobDate}
              </Text>
            </View>
            <View style={styles.cell33BorderRigth}>
              <Text>
                <Text style={styles.textBold}> CURP: </Text> {staff.curp}{" "}
              </Text>
            </View>
            <View style={styles.cell33}>
              <Text>
                <Text style={styles.textBold}> RFC: </Text> {staff.rfc}{" "}
              </Text>
            </View>
          </View>
        </View>

        <View style={styles.tableRow}>
          <View style={styles.tableRowContainer}>
            <View style={styles.cell50BorderSides}>
              <Text>
                <Text style={styles.textBold}> FECHA DE INGRESO ISSEMYM: </Text>{" "}
                {staff.SecurityKeyStartDate}{" "}
              </Text>
            </View>
            <View style={styles.cell50}>
              <Text>
                <Text style={styles.textBold}> CLAVE ISSEMYM: </Text>{" "}
                {staff.socialSecurityKey}{" "}
              </Text>
            </View>
          </View>
        </View>

        <View style={styles.tableRow}>
          <View style={styles.tableRowContainer}>
            <View style={styles.cell33BorderSides}>
              <Text>
                <Text style={styles.textBold}> BANCO: </Text> {staff.nameBank}{" "}
              </Text>
            </View>
            <View style={styles.cell33BorderRigth}>
              <Text>
                <Text style={styles.textBold}> CUENTA: </Text> {staff.cuenta}{" "}
              </Text>
            </View>
            <View style={styles.cell33}>
              <Text>
                <Text style={styles.textBold}> CLABE: </Text> {staff.clabe}{" "}
              </Text>
            </View>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={styles.cell100BorderLRB}>
            <Text>
              <Text style={styles.textBold}> ESTATUS: </Text>
              {staff.statusStaff}
            </Text>
          </View>
        </View>
      </View>
    </>
  );
};

const insertPerceptions = () => {
  return (
    <>
      <View style={styles.table50}>
        <View style={styles.tableRow} fixed>
          <View style={styles.cellHeader25Borders}>
            <Text>{"Clave"}</Text>
          </View>
          <View style={styles.cellHeader50BordersRTB}>
            <Text>{"Concepto"}</Text>
          </View>
          <View style={styles.cellHeader25BordersRTB}>
            <Text>{"Importe"}</Text>
          </View>
        </View>

        {deductionsPerceptions.perceptions.length > 0 &&
          deductionsPerceptions.perceptions.map((info, index) => (
            <View style={styles.tableRow} wrap={false}>
              <View
                style={[
                  styles.cellContent25BordersLBR,
                  { fontSize: 6, textAlign: "left" },
                ]}
              >
                <Text>{info.under} </Text>
              </View>
              <View
                style={[
                  styles.cellContent50BordersRB,
                  { fontSize: 6, textAlign: "left" },
                ]}
              >
                <Text>{info.nameUnder} </Text>
              </View>
              <View
                style={[
                  styles.cellContent25BordersRB,
                  { fontSize: 6, textAlign: "left" },
                ]}
              >
                <Text>{formatter.format(info.amount)} </Text>
              </View>
            </View>
          ))}
        <View style={styles.tableRow}>
          <View style={styles.cellContent75BordersLBR}>
            <Text style={[styles.textBold, { fontSize: 6, textAlign: "left" }]}>
              {deductionsPerceptions.totalPerceptions[0]}{" "}
            </Text>
          </View>
          <View
            style={[
              styles.cellContent25BordersRB,
              { fontSize: 6, textAlign: "left" },
            ]}
          >
            <Text>
              {formatter.format(deductionsPerceptions.totalPerceptions[1])}{" "}
            </Text>
          </View>
        </View>
      </View>
    </>
  );
};

const insertDeductions = () => {
  return (
    <>
      <View style={styles.table50}>
        <View style={styles.tableRow} fixed>
          <View style={styles.cellHeader25Borders}>
            <Text>{"Clave"}</Text>
          </View>
          <View style={styles.cellHeader50BordersRTB}>
            <Text>{"Concepto"}</Text>
          </View>
          <View style={styles.cellHeader25BordersRTB}>
            <Text>{"Importe"}</Text>
          </View>
        </View>

        {deductionsPerceptions.deductions.length > 0 &&
          deductionsPerceptions.deductions.map((info, index) => (
            <View style={styles.tableRow} wrap={false}>
              <View
                style={[
                  styles.cellContent25BordersLBR,
                  { fontSize: 6, textAlign: "left" },
                ]}
              >
                <Text>{info.under} </Text>
              </View>
              <View
                style={[
                  styles.cellContent50BordersRB,
                  { fontSize: 6, textAlign: "left" },
                ]}
              >
                <Text>{info.nameUnder} </Text>
              </View>
              <View
                style={[
                  styles.cellContent25BordersRB,
                  { fontSize: 6, textAlign: "left" },
                ]}
              >
                <Text>{formatter.format(info.amount)} </Text>
              </View>
            </View>
          ))}
        <View style={styles.tableRow}>
          <View
            style={[
              styles.cellContent75BordersLBR,
              { fontSize: 6, textAlign: "left" },
            ]}
          >
            <Text style={styles.textBold}>
              {deductionsPerceptions.totalDeductions[0]}
            </Text>
          </View>
          <View
            style={[
              styles.cellContent25BordersRB,
              { fontSize: 6, textAlign: "left" },
            ]}
          >
            <Text>
              {formatter.format(deductionsPerceptions.totalDeductions[1])}
            </Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View
            style={[
              styles.cellContent75BordersLBR,
              { fontSize: 6, textAlign: "left" },
            ]}
          >
            <Text style={styles.textBold}>{deductionsPerceptions.total}</Text>
          </View>
          <View
            style={[
              styles.cellContent25BordersRB,
              { fontSize: 6, textAlign: "left" },
            ]}
          >
            <Text>
              {formatter.format(
                deductionsPerceptions.totalPerceptions[1] -
                  deductionsPerceptions.totalDeductions[1]
              )}
            </Text>
          </View>
        </View>
      </View>
    </>
  );
};

const getStaff = async (data) => {
  let staff = [];
  const params = {
    action: "getStaffData",
    idStaff: data[0].idStaff,
  };
  await API.peticionEndPoint(params, "app/facades/reports/reportsF.php")
    .then((res) => {
      if (res.status === 200) {
        staff = res.data.data[0];
      } else {
        toast(
          <Notification
            type={"consultar_error"}
            backMessage={res.data.message}
            withIcon
          />
        );
      }
    })
    .catch((err) => {
      toast(<Notification type={"consultar_servidor_error"} withIcon />);
    });
  return staff;
};

const insertQr = (urlQr) => {
  return (
    <View
      style={[
        styles.tableRow,
        { /* marginLeft: "auto", */ marginHorizontal: "auto" },
      ]}
      wrap={false}
    >
      <View
        style={[
          {
            width: "40%",
            height: "175",
            borderWidth: "2",
            flexDirection: "column",
            justifyContent: "space-around",
          },
        ]}
      >
        <Image
          src={urlQr}
          style={{ /* width: "100%", height: "100%", */ padding: "5" }}
        />

        <Text
          style={{
            fontSize: "11",
            textAlign: "center",
            padding: "5",
            marginBottom: "5",
          }}
        >
          Válida este documento escaneando el siguiente código QR
        </Text>
      </View>
    </View>
  );
};

export async function generatePdfPreNomina(data, model, title, fileNo) {
  datatypeUnder(data);
  const staff = await getStaff(data);
  return {
    report: (
      <>
        <Document>
          <Page style={styles.body}>
            {/* Titulos */}

            {insertTitles(model, title)}
            {/* Inserta usuario */}
            {insertStaff(staff)}

            {/* insertar percepciones */}

            <View style={styles.tableRow}>
              <Text style={styles.text50}>Percepciones</Text>
              <Text style={styles.text50}>Deducciones</Text>
            </View>
            <View style={styles.tableRow}>
              {insertPerceptions()}

              {insertDeductions()}
            </View>

            {insertQr(model.pngUrl)}

            <View fixed style={styles.spaceFooter}></View>
            <View fixed style={styles.footer}>
              <Text style={styles.textFooter}>{fileNo}</Text>
              <Text
                style={styles.textFooter}
                render={({ pageNumber, totalPages }) =>
                  `PAGINA : ${pageNumber} / ${totalPages}`
                }
              />
            </View>
          </Page>
        </Document>
      </>
    ),
  };
}

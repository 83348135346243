import React from "react";
import { Typeahead } from "react-bootstrap-typeahead"; // ES2015

export const SelectTypeHeadSingle = ({
  label,
  inputName,
  onChangeMethod,
  onBlurMethod,
  value,
  isRequired,
  touched,
  errors,
  optionsArray,
  optionValue,
  optionName,
  defaultOption,
  isDisabled,
  dropup,
  multipleLabels,
  optionSubLabel,
  refSelect,
  isFixed,
  style,
  retObject = false
}) => {

  const ToggleButton = ({ isOpen, onClick }) => (
        <i
          onClick={isDisabled ? () => { } : onClick}
          onMouseDown={(e) => {
            e.preventDefault();
          }} 
          className={isOpen ? "eva eva-chevron-up-outline" : "eva eva-chevron-down-outline"} 
        />
  );

  function filterBy(option, state) {
    if (state.selected.length) {
      return true;
    }
    return option[optionName]?.toLowerCase().indexOf(state.text.toLowerCase()) > -1;
  }

  function getSelected() {
    const newArray = getData();
    if (newArray.length > 0) {
      let sel = newArray.find((el => el[optionValue] == value));
      if (sel !== undefined) return [sel];
    }
    return [];
  }

  const getData = () => {
    if (Array.isArray(multipleLabels)) {
      const result = optionsArray.map(it => {
        let label = ''
        multipleLabels.forEach((element, index) => {

          if (optionSubLabel !== undefined) {
            const subtitle = optionSubLabel[index] === false ? null : optionSubLabel[index];

            label === '' ? label = `${subtitle !== null ? `${subtitle}: ` : ''}${it[element]}` :
              it[element] && (label = label + ` - ${subtitle !== null ? `${subtitle}: ` : ''}` + it[element])
          }
          else {
            label === '' ? label = `${it[element]}` :
              it[element] && (label = label + ' - ' + it[element])
          }
        });
        return { ...it, name: label }
      })
      return result
    }
    else {
      return optionsArray
    }
  }

  return (
    <div className="form-group">
      {label && <label htmlFor={inputName}>
        {label} {isRequired && <span className="text-danger">*</span>}
      </label>}
      <Typeahead
        id={inputName}
        ref={refSelect ? refSelect : undefined}
        inputProps={{ name: inputName }}
        options={getData()}
        labelKey={option => {
          return option[optionName]
        }
        }
        onChange={(e) => {
          if (e.length > 0) {
            if(retObject){
              onChangeMethod({ target: { name: inputName, value: e[0]} });

            }else{
              onChangeMethod({ target: { name: inputName, value: e[0][optionValue] } });

            }
          } else {
            onChangeMethod({ target: { name: inputName, value: "" } });
          }
        }}
        placeholder={defaultOption || "Seleccione un elemento"}
        onBlur={onBlurMethod}
        isInvalid={touched && errors ? true : false}
        disabled={isDisabled ? true : false}
        paginationText="Mostrar mas resultados"
        emptyLabel="No se encontraron coincidencias"
        filterBy={filterBy}
        dropup={dropup ? true : false}
        // renderMenu={(results, menuProps) => (
        //   <Menu {...menuProps}>
        //     {results.map((result, index) => (
        //       <MenuItem option={result[optionValue]} position={index} key={index}>
        //         {result[optionName]}
        //       </MenuItem>
        //     ))}
        //   </Menu>
        // )}
        // defaultInputValue={optionsArray.filter((el => +el[optionValue] === +value))[0][optionName]}
        selected={getSelected()}
        positionFixed={isFixed ? true : false}
        flip={true}
        style={style ? style : undefined}
         className="position-relative"
      >
        {({ isMenuShown, toggleMenu,  }) => (
          <div style={styleB}>
          { value && !isDisabled ?
            <i className="eva eva-close-outline cursor-pointer" onClick={()=>onChangeMethod({ target: { name: inputName, value: "" } })} /> : null
          }
            <ToggleButton isOpen={isMenuShown} onClick={(e) => toggleMenu()} />
          </div>
        )}
      </Typeahead>
      {touched && errors && <span style={{ color: "red" }}>{errors}</span>}
    </div>
  );
};
const styleB = {
  background: "transparent",
  border: "0",
  color: "#6B859E",
  position: "absolute",
  right: "5px",
  top: "10px",
  cursor: "default"
}
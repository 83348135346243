import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect, useSelector, useDispatch } from "react-redux";
import { withRouter, useHistory } from "react-router-dom";
import axios from "axios";
import { peticionEncript } from "../../helpers/peticionesEncripted";
import { saveProfileType, saveSelectPermission } from '../../actions/auth'
import { changeActiveSidebarItem } from "../../actions/navigation.js";
import LinksGroup from "./LinksGroup/LinksGroup";
import Loader from "../../components/Loader/Loader";
import SofiaLogo from "../Icons/SofiaLogo.js";

import s from "./Sidebar.module.scss";
import "eva-icons/style/eva-icons.css";
import ProfilesGroup from "./LinksGroup/ProfilesGroup";

import { closeSidebar, openSidebar } from "../../actions/navigation.js";

const Sidebar = (props) => {
  const dispatch = useDispatch();
  const history = useHistory()
  const { id, profileType } = useSelector(state => state.userInfo)

  const [burgerBtnToggled, setBurgerBtnToggled] = useState(false);
  const [menuByPermissions, setMenuByPermissions] = useState({});
  const [loadingMenu, setLoadingMenu] = useState(true);
  const [profileTypeAll, setProfileTypeAll] = useState([])
  const [selectProfileIndex, setSelectProfileIndex] = useState(0)

  useEffect(() => {
    if (Object.keys(profileType.selectProfile).length > 0) {
      const newIndex = profileType.data.findIndex(it => it.nameProfile === profileType.selectProfile.profile
        && it.name === profileType.selectProfile.areaName)
      setSelectProfileIndex(newIndex)
      getProfileType(id, newIndex)
    }
    else {
      getProfileType(id)
    }

  }, [])

  useEffect(() => {
    if (props.sidebarOpened) {
      setBurgerBtnToggled(true);
    } else {
      setTimeout(() => {
        setBurgerBtnToggled(false);
      }, 0);
    }
  }, [props.sidebarOpened]);

  function renderMenuWithSons(parent, index) {
    return (
      <LinksGroup
        onActiveSidebarItemChange={(activeItem) =>
          props.dispatch(changeActiveSidebarItem(activeItem))
        }
        activeItem={props.activeItem}
        header={parent.nameModule}
        isRoot
        link={"/template/" + parent.nameModule}
        index={`${index}-`}
        childrens={parent.children}
        key={`LG${index}`}
      />
    )
  }

  const getMenu = async (permission) => {
    const params = {
      action: "getMenu2",
      idPermission: permission,
    }; 
    dispatch(saveSelectPermission(permission))
    const finalData = peticionEncript(params)
    const result = await axios.post(`${process.env.REACT_APP_API}menu/menu.php`, finalData)
    if (+result.status === 200 && result.data.status === 'success') {
      setMenuByPermissions(result.data.data);
    }
    setLoadingMenu(false);

  };

  const getProfileType = async (id, indexPermission = 0) => {
    const params = {
      action: "multiselect",
      table: `permissions AS PER INNER JOIN profiles AS PRO ON PER.keyProfile = PRO.keyProfile INNER JOIN profilemodules AS PM ON PM.keyProfile = PRO.keyProfile INNER JOIN areas AS A ON A.idArea = PER.idArea`,
      rows: `DISTINCT(PER.keyProfile),PER.idPermission,PER.idUser,PER.idArea,PRO.keySystem, PRO.nameProfile,A.name`,
      conditions: `PER.idUser = ${id} AND PER.enabled = 1`,
    };
    const finalData = peticionEncript(params)
    const result = await axios.post(`${process.env.REACT_APP_API}receiver/receiver.php`, finalData)

    if (+result.data.code === 200 && result.data.data.length > 0) {
      const nameProfile = result.data.data.map(item => {
        return {
          profile: item.nameProfile,
          areaName: item.name,
          idPermission: item.idPermission,
          keyProfile: item.keyProfile,
          idArea: item.idArea
        }
      })
      const type = {
        nameType: nameProfile,
        data: result.data.data,
        selectProfile: {
          profile: nameProfile[indexPermission].profile,
          areaName: nameProfile[indexPermission].areaName,
          keyProfile: nameProfile[indexPermission].keyProfile,
          idArea: nameProfile[indexPermission].idArea
        }
      }

      setProfileTypeAll(type.nameType)
      getMenu(type.data[indexPermission].idPermission)
      dispatch(saveProfileType(
        { ...profileType, nameType: type.nameProfile, data: type.data, selectProfile: type.selectProfile }
      ))
    }
    else {
      setLoadingMenu(false);
      setProfileTypeAll(null)
    }
  }

  const selectedProfile = (index, array = profileTypeAll) => {
    setSelectProfileIndex(index)
    getMenu(array[index].idPermission)
    const profile = {
      profile: array[index].profile,
      areaName: array[index].areaName,
      keyProfile: array[index].keyProfile,
      idArea: array[index].idArea
    }
    dispatch(saveProfileType({ ...profileType, selectProfile: profile }))
    history.push("/template");
  }

  function hideButton() {
    dispatch(closeSidebar());
  }
  function showButton() {
    dispatch(openSidebar());
  }

  return (
    <>
      <div className={`${burgerBtnToggled ? s.sidebarArrowOpen : "d-none"} align-items-center justify-content-center border rounded-right rounded-lg`} style={{ width: "20px", height: "20px", background: "white", position: "absolute", left: "350px", top: "50px" }}  onClick={hideButton} title="Cerrar menu de opciones">
        <i className="fa fa-arrow-left" style={{ fontSize: "10pt" }}/>
      </div>
      <nav className={`${s.root} ${burgerBtnToggled ? `${s.sidebarOpen} shadow` : s.sidebarClose}`}>
        {burgerBtnToggled ? (
          <>
            <header className={s.logo}>
              <SofiaLogo onClick={hideButton} />
              <span className={s.title}>Sistema de Administración <br /> de Personal y Presupuesto</span>
            </header>
            {!loadingMenu ? (
              <ul className={s.nav}>
                {
                  profileTypeAll === null ?
                    <h5 className={[s.navTitle, s.groupTitle].join(" ")}>No hay perfiles asignados</h5>
                    :
                    profileTypeAll.length > 0 ?
                      <ProfilesGroup
                        profiles={profileTypeAll}
                        selected={selectProfileIndex}
                        onclick={selectedProfile}
                        iconName={<i className="eva eva-grid" />}
                      />
                      :
                      <Loader />
                }
                {menuByPermissions.length > 0 &&
                  <>
                    <h5 className={[s.navTitle, s.groupTitle].join(" ")}>Menu</h5>
                    {menuByPermissions.map((e, index) => {
                      return (renderMenuWithSons(e, index));
                    })}
                  </>
                }
              </ul>
            ) : (
              <Loader />
            )}
          </>
        ) : (
          <div className="d-flex align-items-center mb-2">
            <div className="rounded-right rounded-lg shadow font-weight-bold d-flex align-items-center px-2" onMouseEnter={showButton}>
              <SofiaLogo minWidth={false}/>
              Menu
            </div>
            <div className="d-flex align-items-center justify-content-center border rounded-right rounded-lg" style={{ width: "20px", height: "20px", background: "white" }} onClick={showButton} title="Abrir menu de opciones">
              <i className="fa fa-arrow-right" style={{ fontSize: "10pt" }} />
            </div>
          </div>
        )}
      </nav>
    </>
  );
};

Sidebar.propTypes = {
  sidebarOpened: PropTypes.bool,
  dispatch: PropTypes.func.isRequired,
  activeItem: PropTypes.string,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
};

function mapStateToProps(store) {
  return {
    sidebarOpened: store.navigation.sidebarOpened,
    activeItem: store.navigation.activeItem,
  };
}

export default withRouter(connect(mapStateToProps)(Sidebar));
// export default Sidebar;

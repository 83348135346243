import React from "react";
import { Spinner } from "reactstrap";
import s from "./FullScreenLoader.module.scss";
import classNames from "classnames";

const FullScreenLoader = ({ show, setShow, message, color }) => {
  return (
    <div className={classNames([s.loaderBack, (show && s.show), (color && s[color])])}>
      <div className={"text-center " + s.ico}>
        <Spinner color="info"/>
        <h5 className="text-info">{message}</h5>
      </div>
    </div>
  );
};

export default FullScreenLoader;

import { useEffect, useState } from "react";
import Widget from "../../../components/Widget/Widget";
import Consulta from "./Consulta";
import Detalle from "./Detalle";
import { doRequest } from "../../../helpers/requests";
import { GetPermissions } from "../../../libs/permissions/getPermissions";

export default function RequisicionesPapeleria() {
    const [permissions, setPermissions] = useState({ LOD: true });
    GetPermissions(setPermissions);

    const [req, setReq] = useState({ id: "" });
    const [periods, setPeriods] = useState([]);

    useEffect(() => {
        getPeriods();
    }, []);

    async function getPeriods() {
        const params = {
            action: "select",
            table: "types_delivery",
            condition: { enabled: 1 },
            order: "nameTypeDelivery ASC",
        };
        const res = await doRequest("receiver/receiver.php", params, false);
        if (res.length > 0) {
            setPeriods(res);
        }
    }

    return (
        <Widget className="widget-p-md">
            <div className="mb-3">
                <h3>Requisiciones de papelería</h3>
            </div>

            {req.id ? (
                <Detalle
                    info={req}
                    setInfo={setReq}
                    periods={periods}
                    permissions={permissions}
                />
            ) : (
                <Consulta setReq={setReq} permissions={permissions} />
            )}
        </Widget>
    );
}

import * as XlsxPopulate from "xlsx-populate";
import { saveAs } from "file-saver";
import { peticionEncript } from "../../../../helpers/peticionesEncripted";
import axios from "axios";
import { toast } from "react-toastify";
import Notification from "../../../../components/Notification/Notification";
import RichText from "xlsx-populate/lib/RichText";

export async function saveReport9AsExcel(report, title, period) {
  getData(report, title, period);
}

async function getStaffInfo(data, tn,period) {
  const params = { action: "getStaffData", idStaff: data.idStaff };
  const info = await consulta(params, 0);
  const d = info.length != 0 ? info[0] : {};
  return [
    d.name/* , "*****" */,
    d.curp, d.rfc,
    /* "*****", */ d.socialSecurityKey,
    /* "*****", "*****", */
    d.nameArea, /* "*****", */
    /* "*****", "*****",
    "*****",*/ period[0], 
    d.nameJob, formatter.format(tn)
  ];
}

async function getLeyend(data) {
  const params = {
    action: "select",
    table: "advertisements",
    condition: { period: data.period, year: data.year },
  };
  const info = await consulta(params, 1);
  const msg = info.length > 0 ? info[0].descripcion : "";
  return msg;
}

const formatter = new Intl.NumberFormat("es-MX", {
  style: "currency",
  currency: "MXN",
});

async function getData(report, title, period) {
  const per = filterData(report, 1);
  const ded = filterData(report, 2);
  const staff = await getStaffInfo(report[0], per.tt-ded.tt,period);
  const leyend = await getLeyend(report[0]);
  generateExcel(title, period, staff, leyend, per, ded,period);
}

function filterData(data, type) {
  let dataF = data.filter((el) => el.keyTypeUnder == type);
  let acum = dataF.reduce((acc, b) => acc + +b.amount, 0);
  return { da: dataF, tt: acum };
}

function generateExcel(title, period, staff, leyend, per, ded,) {
  XlsxPopulate.fromBlankAsync().then(async (workbook) => {
    //** Estilos **//

    // const dataStyle = workbook.styleSheet().createStyle();
    // dataStyle.style("wrapText", true);
    // dataStyle.style("horizontalAlignment", "center");
    // dataStyle.style("verticalAlignment", "center");
    // dataStyle.style("border", true);

    const ds = {wrapText: true, horizontalAlignment: "center", verticalAlignment: "center", border: true}

    // const headerStyle = workbook.styleSheet().createStyle();
    // headerStyle.style("horizontalAlignment", "center");
    // headerStyle.style("border", true);
    // headerStyle.style("bold", true);
    const hs = {horizontalAlignment: "center", border: true, bold: true}

    // const boldStyle = workbook.styleSheet().createStyle();
    // boldStyle.style("bold", true);

    const bs = {bold: true}

    // const bbSt = workbook.styleSheet().createStyle();
    // bbSt.style("bottomBorder", true);

    const bbs = {bottomBorder: true}

    // const sbSt = workbook.styleSheet().createStyle();
    // sbSt.style("leftBorder", true);
    // sbSt.style("horizontalAlignment", "center");

    const sbs = {leftBorder: true, horizontalAlignment: "center"}

    // const ebSt = workbook.styleSheet().createStyle();
    // ebSt.style("rightBorder", true);
    // ebSt.style("horizontalAlignment", "right");

    const ebSt = {rightBorder: true, horizontalAlignment: "right"}

    // const hsSt = workbook.styleSheet().createStyle();
    // hsSt.style("horizontalAlignment", "center");
    // hsSt.style("leftBorder", true);
    // hsSt.style("bottomBorder", true);

    const hsSt = {horizontalAlignment: "center", leftBorder: true, bottomBorder: true}

    // const heSt = workbook.styleSheet().createStyle();
    // heSt.style("horizontalAlignment", "center");
    // heSt.style("rightBorder", true);
    // heSt.style("bottomBorder", true);

    const heSt = {horizontalAlignment: "center", rightBorder: true, bottomBorder: true}

    // const tbsSt = workbook.styleSheet().createStyle();
    // tbsSt.style("topBorder", true);
    // tbsSt.style("leftBorder", true);
    // tbsSt.style("bottomBorder", true);

    const tbsSt = {topBorder: true, leftBorder: true, bottomBorder: true}

    // const tbeSt = workbook.styleSheet().createStyle();
    // tbeSt.style("horizontalAlignment", "right");
    // tbeSt.style("bold", true);
    // tbeSt.style("topBorder", true);
    // tbeSt.style("rightBorder", true);
    // tbeSt.style("bottomBorder", true);

    const tbeSt = {horizontalAlignment: "right", bold: true, topBorder: true, rightBorder: true, bottomBorder: true }
    //** FIN Estilos **//

    const sheet1 = workbook.sheet(0);

    const ttStaff = [
      "Nombre",
      /* "Clave de SP", */
      "CURP",
      "RFC",
      /* "Dependencia", */
      "Clave ISSEMyM",
      /* "Código", */
      /* "CCT", */
      "Unidad Admiva",
      /* "L-Pago", */
      /* "Plaza",
      "Fecha de Pago", */
      /* "Código puesto", */
      "Período de Pago",
      "Puesto",
      "Total Neto",
    ];

    sheet1.range("B2:K2").merged(true).value(title).style(hs);

    var p = 0;
    for (let i = 4; i < 12; i++) {
      sheet1.row(i).cell(2).value(ttStaff[p]);
      sheet1.row(i).cell(8).value(ttStaff[p + 1]);
      sheet1.range(`C${i}:E${i}`).merged(true).value(staff[p]).style(bs);
      sheet1.range(`I${i}:K${i}`).merged(true).value(staff[p + 1]).style(bs);
      p += 2;
    }

    sheet1.range(`B13:F13`).merged(true).value("PERCEPCIONES").style(ds);
    sheet1.range(`G13:K13`).merged(true).value("DEDUCCIONES").style(ds);

    for (let i = 1; i <= 11; i++) {
      sheet1.column(i).width(15);
    }

    const x = [["C","E"], ["H","J"],["B","G"],["F","K"]];
    let cantMax = per.da.length <= ded.da.length? ded.da.length : per.da.length;
    const pd = [per, ded];
    for (let i = 0; i < 2; i++) {
      sheet1.row(14).cell(x[2][i]).value("CLAVE").style(hsSt);
      sheet1.range(`${x[i][0]}14:${x[i][1]}14`).merged(true).value("CONCEPTO").style(bbs);
      sheet1.row(14).cell(x[3][i]).value("IMPORTE").style(heSt);
      for (let j = 0; j < cantMax; j++) {
        let d = pd[i].da[j];
        if(d !== undefined) {
          sheet1.row(15+j).cell(x[2][i]).value(d.under).style(sbs);
          sheet1.range(`${x[i][0]}${15+j}:${x[i][1]}${15+j}`).merged(true).value(d.nameUnder);
          sheet1.row(15+j).cell(x[3][i]).value(formatter.format(d.amount)).style(ebSt);
        }else{
          sheet1.row(15+j).cell(x[2][i]).style(sbs);
          sheet1.range(`${x[i][0]}${15+j}:${x[i][1]}${15+j}`).merged(true);
          sheet1.row(15+j).cell(x[3][i]).style(ebSt);
        }
      };
    }

    let lastRow = 15 + cantMax;
    sheet1.range(`B${lastRow}:E${lastRow}`).merged(true).value("Total de percepciones").style(tbsSt);
    sheet1.row(lastRow).cell("F").value(formatter.format(per.tt)).style(tbeSt);
    sheet1.range(`G${lastRow}:J${lastRow}`).merged(true).value("Total de deducciones").style(tbsSt);
    sheet1.row(lastRow).cell("K").value(formatter.format(ded.tt)).style(tbeSt);

    const richText = new RichText();
    richText.add("     CONSTITUYE EL RECIBO DE PAGO CORRESPONDIENTE."); 
    /* richText.add("                RECIBO: "+"*****", {"bold": true}); */
    /* richText.add(`\n      SE REALIZÓ EL ABONO EN LA CUENTA No. ${staff.cuenta} EL DÍA:$`);  */
    /* richText.add(`  *****`, {"bold": true}); */
    sheet1.range(`B${lastRow+1}:K${lastRow+4}`).merged(true).value(richText).style({verticalAlignment: "center", border: true});
    const lyCln = leyend.replaceAll(/<.*?>/g, "");
    sheet1.range(`B${lastRow+5}:K${lastRow+8}`).merged(true).value(lyCln).style(ds);


    return workbook.outputAsync().then((res) => {
      saveAs(res, `${title}.xlsx`);
    });
  });
}

const routes = ["app/facades/reports/reportsF.php", "receiver/receiver.php"];
async function consulta(params, route) {
  const finalData = peticionEncript(params);
  let info = [];
  await axios
    .post(process.env.REACT_APP_API + routes[route], finalData)
    .then((res) => {
      if (res.status === 200 && res.data.code === "200") {
        info = res.data.data;
      }
    })
    .catch((err) => {
      toast(<Notification type={"consultar_servidor_error"} withIcon />, {
        closeButton: false,
      });
    });
  return info;
}

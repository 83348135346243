import * as XlsxPopulate from "xlsx-populate";
import { saveAs } from "file-saver";
import { number2Alphabet } from "../../../../libs/tools/strings";
import { sumValues } from "../../../../libs/tools/arrays";
import { es } from "date-fns/locale"
import { setMonth, format } from "date-fns"

/**
 * Formateador de numeros de concurrencias
 */
const formatter = new Intl.NumberFormat("es-MX", {
    style: "currency",
    currency: "MXN",
});

/**
 * Hoja de estilos del excel
 */
const styles = {
    title: {
        horizontalAlignment: 'center', bold: true
    },
    bold: {
        bold: true
    },
    center: {
        horizontalAlignment: 'center'
    },
    headerBorder: {
        bold: "true",
        border: true,
        fill: "BFBFBF",
    },
    header: {
        bold: "true",
        border: true,
        fill: "BFBFBF",
    },
    headerBoderBold: {
        bold: "true",
        border: true,
    },
    contentBorder: {
        border: true,
    },
    contentBorderB: {
        border: true,
        bold: true
    },
    contentBorderRigth: {
        border: true,
        horizontalAlignment: 'right'
    },
    contentBorderRigthB: {
        border: true,
        horizontalAlignment: 'right',
        bold: true
    },
};

export async function saveReport3SRem(report, title, config) {
    XlsxPopulate.fromBlankAsync().then(async (workbook) => {
        const sheet1 = workbook.sheet(0);
        //Titulo
        sheet1
            .range(`A1:E1`)
            .merged(true)
            .value(`FISCALIA GENERAL DEL ESTADO DE MÉXICO`)
            .style(styles.title);
        sheet1
            .range(`A2:E2`)
            .merged(true)
            .value("SAPP")
            .style(styles.title);
        sheet1
            .range(`A3:E3`)
            .merged(true)
            .value(title)
            .style(styles.center);
        sheet1
            .range(`A4:E4`)
            .merged(true)
            .value("(IMPUESTO SOBRE NÓMINA)")
            .style(styles.center);

        const month = Number(report[0].month) - 1;

        sheet1
            .range(`A5:E5`)
            .merged(true)
            .value(`BASE PARA EL CALCULO DEL 3.0% CORRESPONDIENTE AL MES DE ${format(setMonth(new Date(), month), "LLLL", { locale: es }).toUpperCase()} 2022`)

        sheet1
            .range(`A7:C7`)
            .merged(true)
            .value('SERVICIOS PERSONALES')
        sheet1
            .cell(`D7`)
            .value(formatter.format(report[0].amountSP))

        sheet1
            .range(`A8:C8`)
            .merged(true)
            .value('COMPLEMENTO DE RECONOCIMIENTOS')
        sheet1
            .cell(`D8`)
            .value(formatter.format(report[0].amountCR))

        sheet1
            .range(`A9:C9`)
            .merged(true)
            .value('TOTAL')
            .style(styles.bold)
        const total = Number(report[0].amountCR) + Number(report[0].amountSP)
        sheet1
            .cell(`D9`)
            .value(formatter.format(total))

        sheet1
            .range(`A11:C11`)
            .merged(true)
            .value('CUOTA DE SALUD')
        sheet1
            .cell(`D11`)
            .value(formatter.format(report[0].amountCS))

        sheet1
            .range(`A12:C12`)
            .merged(true)
            .value('CUOTA DE REPARTO')
        sheet1
            .cell(`D12`)
            .value(formatter.format(report[0].amountCRep))

        sheet1
            .range(`A13:C13`)
            .merged(true)
            .value('SISTEMA DE CAPITALIZACIÓN')
        sheet1
            .cell(`D13`)
            .value(formatter.format(report[0].amountSC))

        sheet1
            .range(`A14:C14`)
            .merged(true)
            .value('GASTOS DE ADMÓN.')
        sheet1
            .cell(`D14`)
            .value(formatter.format(report[0].amountGA))

        sheet1
            .range(`A15:C15`)
            .merged(true)
            .value('RIESGO DE TRABAJO')
        sheet1
            .cell(`D15`)
            .value(formatter.format(report[0].amountRT))

        //Cabeceras
        config.headers.forEach((header, index) => {
            sheet1
                .cell(`${number2Alphabet(index)}17`)
                .value(header)
                .style(styles.headerBoderBold);
        });

        report.forEach((value, index) => {
            config.content.forEach((key, ind) => {
                sheet1
                    .cell(`${number2Alphabet(ind)}${index + 18}`)
                    .value(key.value !== "id" ? (!key.format ? value[key.value] : formatter.format(value[key.value])) : index + 1)
                    .style(key.value !== "id" ? (!key.format ? styles.contentBorder : styles.contentBorderRigth) : [styles.center, styles.contentBorder])
            })
        })

        const sumadeduc = sumValues(report, "amount") + Number(report[0].amountCS) + Number(report[0].amountCRep) + Number(report[0].amountGA) + Number(report[0].amountRT)

        sheet1
            .range(`A${report.length + 18}:C${report.length + 18}`)
            .merged(true)
            .value('SUMA DE DEDUCCIONES')
        sheet1
            .cell(`D${report.length + 18}`)
            .value(formatter.format(sumadeduc))
        sheet1
            .range(`A${report.length + 19}:C${report.length + 19}`)
            .merged(true)
            .value('BASE IMPUESTO')
        sheet1
            .cell(`D${report.length + 19}`)
            .value(formatter.format(total - sumadeduc))
        sheet1
            .range(`A${report.length + 20}:C${report.length + 20}`)
            .merged(true)
            .value('PORCENTAJE')
        sheet1
            .cell(`D${report.length + 20}`)
            .value("3%")
        sheet1
            .range(`A${report.length + 21}:C${report.length + 21}`)
            .merged(true)
            .value('IMPUESTO DETERMINADO')
        sheet1
            .cell(`D${report.length + 21}`)
            .value(formatter.format((total - sumadeduc) * 0.03))
        sheet1
            .range(`A${report.length + 23}:C${report.length + 23}`)
            .merged(true)
            .value('ELABORÓ')
            .style([styles.center, styles.bold]);
        sheet1
            .range(`D${report.length + 23}:E${report.length + 23}`)
            .merged(true)
            .value('REVISÓ')
            .style([styles.center, styles.bold]);
        sheet1
            .range(`A${report.length + 27}:C${report.length + 27}`)
            .merged(true)
            .value('')
            .style([styles.center, styles.bold]);
        sheet1
            .range(`D${report.length + 27}:E${report.length + 27}`)
            .merged(true)
            .value('')
            .style([styles.center, styles.bold]);
        sheet1
            .range(`A${report.length + 28}:C${report.length + 28}`)
            .merged(true)
            .value('')
            .style([styles.center, styles.bold]);
        sheet1
            .range(`D${report.length + 28}:E${report.length + 28}`)
            .merged(true)
            .value('')
            .style([styles.center, styles.bold]);

        sheet1.row(1).height(70);
        sheet1.row(2).height(20);
        sheet1.column('A').width(20);
        sheet1.column('B').width(40);
        sheet1.column('C').width(25);
        sheet1.column('D').width(100);
        sheet1.column('E').width(30);


        //Subtitulos
        return workbook.outputAsync().then((res) => {
            saveAs(res, `${title}.xlsx`);
        });
    })
}
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import FormPlazas from "../../plazas-laborales/FormPlazas";
import { updateJobPosition } from "../../plazas-laborales/functions";
import { useState } from "react";

export default function ModalEditarPlaza({ modal, setModal, infoPlaza, atFinish }){
    const toggle = () => setModal(!modal);

    return(
        <Modal isOpen={modal} backdrop="static" toggle={toggle} style={{ minWidth: "95%" }}>
            <ModalHeader toggle={toggle}>
                Editar Plaza {infoPlaza.number}
            </ModalHeader>
            <ModalBody className="">
                { <FormPlazas data={[infoPlaza.id]} editar={updateJobPosition} specFunction={atFinish} /> }
            </ModalBody>
    </Modal>
    )
}
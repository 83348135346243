
import React from "react";

import Widget from "../../../../components/Widget/Widget";
import FormIncapacidades from "../FormIncapacidades";
import { updateIncapacity } from "../functions";

export const EditarIncapacidad = (props) => {
  return (
    <Widget className="widget-p-md">
        <h3>Editar tipo de incapacidad</h3>
        <FormIncapacidades data={localStorage.getItem('dataContent').split('|-|')} editar={updateIncapacity}/>
    </Widget>
  )
}

export default EditarIncapacidad;
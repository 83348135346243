import React, { useState, useEffect } from "react";
import { peticionesReceiver } from "../../../../../helpers/peticionesReceiver";
import { Button, Nav, NavItem, NavLink, Row, Col } from "reactstrap";
import Widget from "../../../../../components/Widget/Widget";
import s from "./nav.module.scss";
import { toast } from "react-toastify";
import Notification from "../../../../../components/Notification/Notification";
import ModalConfirmation from "../../../../../components/Modal/ModalConfirmation";
import Loader from "../../../../../components/Loader/Loader";
import DetailsView from "./DetailsView";
import ModalAsignArea from "./ModalAsignArea";

const TicketDetail = ({
  ticket,
  setCollapse,
  setRefreshP,
  isAgent,
  setTicket,
  agentLogged,
}) => {
  const API = peticionesReceiver();
  const [activeTab, setActiveTab] = useState(0);
  const [loading, setLoading] = useState(true);
  const [trazabilities, setTrazabilities] = useState([]);
  const [detailTrazability, setDetailTrazability] = useState(null);
  const [modalAsignArea, setModalAsignArea] = useState("false"); // false //asignArea //closeTicket
  const [modalConfirmation, setModalConfirmation] = useState(false);

  const toggleTab = (tab, trazabilitie) => {
    setDetailTrazability(trazabilitie);
    setActiveTab(tab);
  };

  useEffect(() => {
    getTrazability(true, 0);
  }, []);

  const getTrazability = async (changeLoader, valueTrasibility) => {
    const data = {
      action: "multiselect",
      table:
        "tickettrazability A LEFT JOIN topicagents B on (A.idTopicAgent = B.idTopicAgent)  LEFT JOIN users C on (B.idUser = C.idUser) LEFT JOIN topics D on (B.keyTopic = D.keyTopic)  INNER JOIN tickets E on (A.idTicket = E.idTicket) INNER JOIN status F on (E.keyStat = F.keyStat) INNER JOIN types_severity G on (E.keySeverity = G.keySeverity)  INNER JOIN areas H on (H.idArea = E.idArea) LEFT JOIN staff I on (I.idStaff = E.idStaff)",
      rows: "A.idTrazability,A.idTopicAgent,A.idTicket,A.notes,A.dateAssign,A.dateUpdated,concat(C.name,' ',C.pName,' ',C.mName) as fullNameAgent,D.nameTopic,E.numTicket,E.yearTicket,F.nameStat,E.title,E.description,E.keyStat,E.dateClosed,E.dateAssigned, G.nameSeverity,A.keyTopic,H.name as nameArea, ifnull(E.nameReporter, concat(I.name,' ',I.pName,' ',I.mName)) as fullName,A.dateUpdated",
      conditions: `A.enabled = 1 and A.idTicket = ${ticket.idTicket}`,
      order: "A.dateAssign ASC",
    };
    await API.peticion(data)

      .then(async (res) => {
        if (res.status === 200 && res.data.code === "200") {
          const data = await clearData(res);

          setTrazabilities(data);
        } else {
          toast(<Notification type={"consultar_error"} withIcon />);
        }
      })
      .catch((err) => {
        toast(<Notification type={"consultar_servidor_error"} withIcon />);
      });

    setLoading(false);
  };

  const clearData = async (res) => {
    let dateAttend = null;
    let notesClose = null;

    const data = res.data.data.filter((obj, index) => {
      if (obj.notes === "TICKET ATENDIDO") {
        dateAttend = obj.dateUpdated;
      }

      if (obj.notes.includes("12$/TICKET CERRADO12$/:")) {
        notesClose = getStringWithoutClose(obj.notes);
      }

      if (
        obj.notes !== "TICKET ATENDIDO" &&
        !obj.notes.includes("12$/TICKET CERRADO12$/:")
      ) {
        return obj;
      }
    });

    await data.forEach((value, index) => {
      value.index = 1 + index;
      value.dateAttended = dateAttend;
      value.notesClose = notesClose;
    });

    setDetailTrazability(data[data.length - 1]);

    return data;
  };

  const getStringWithoutClose = (closeString) => {
    const searchString = "12$/TICKET CERRADO12$/:";
    const startIndex = closeString.indexOf(searchString);
    const endIndex = startIndex + searchString.length;
    return closeString.substring(endIndex, closeString.length);
  };

  const closeTicket = async () => {
    const data = {
      action: "update",
      table: "tickets",
      rows: {
        keyStat: "146",
        dateClosed: new Date(),
      },
      condition: {
        idTicket: ticket.idTicket,
      },
    };

    await API.peticion(data)
      .then((res) => {
        if (res.status === 200 && res.data.code === "200") {
          setRefreshP(true);
          setCollapse("home");
          toast(
            <Notification
              type={"agrega_exito"}
              backMessage={res.data.message}
              withIcon
            />
          );
        } else {
          toast(<Notification type={"consultar_error"} withIcon />);
        }
      })
      .catch((err) => {
        toast(<Notification type={"consultar_servidor_error"} withIcon />);
      });
  };

  const refreshInformation = async () => {
    setLoading(true);
    setDetailTrazability(null);
    setTrazabilities(null);
    await getTrazability(false, activeTab + 1);
    toggleTab(trazabilities.length - 1, trazabilities[activeTab]);
    setRefreshP(true);
    setLoading(false);
  };

  const attendTicket = async () => {
    if (await insertTrazabilityAttend()) {
      await changeTicketAtetend();
    }
  };

  const changeTicketAtetend = async () => {
    const data = {
      action: "update",
      table: "tickets",
      rows: {
        keyStat: "144",
      },
      condition: {
        idTicket: ticket.idTicket,
      },
    };
    await API.peticion(data)
      .then((res) => {
        if (res.status === 200 && res.data.code === "200") {
          setTicket(res.data.data[0]);
          refreshInformation();
          // toast(
          //   <Notification
          //     type={"agrega_exito"}
          //     backMessage={res.data.message}
          //     withIcon
          //   />
          // );
        } else {
          toast(<Notification type={"consultar_error"} withIcon />);
        }
      })
      .catch((err) => {
        toast(<Notification type={"consultar_servidor_error"} withIcon />);
      });
  };

  const insertTrazabilityAttend = async () => {
    let isAllOki = false;
    const data = {
      action: "insert",
      table: "tickettrazability",
      rows: {
        idTicket: detailTrazability.idTicket,
        keyTopic: detailTrazability.keyTopic,
        idTopicAgent: detailTrazability.idTopicAgent,
        notes: "TICKET ATENDIDO",
        dateAssign: formatearFecha(new Date()),
        dateUpdated: formatearFecha(new Date()),
        enabled: 1,
      },
    };

    await API.peticion(data)
      .then((res) => {
        if (res.status === 200 && res.data.code === "200") {
          setTicket(res.data.data[0]);
          setRefreshP(true);
          isAllOki = true;
          toast(
            <Notification
              type={"agrega_exito"}
              backMessage={res.data.message}
              withIcon
            />
          );
        } else {
          toast(<Notification type={"consultar_error"} withIcon />);
        }
      })
      .catch((err) => {
        toast(<Notification type={"consultar_servidor_error"} withIcon />);
      });

    return isAllOki;
  };

  function formatearFecha(fecha) {
    const año = fecha.getFullYear();
    const mes = String(fecha.getMonth() + 1).padStart(2, "0"); // Los meses son base 0, por lo que se suma 1
    const dia = String(fecha.getDate()).padStart(2, "0");
    const horas = String(fecha.getHours()).padStart(2, "0");
    const minutos = String(fecha.getMinutes()).padStart(2, "0");
    const segundos = String(fecha.getSeconds()).padStart(2, "0");

    return `${año}/${mes}/${dia} ${horas}:${minutos}:${segundos}`;
  }

  function getDetailUpdated(fecha) {
    return trazabilities[activeTab];
  }

  const isAggentCorrect = () => {
    const exist = agentLogged.some(function (agent) {
      return agent.idTopicAgent === detailTrazability.idTopicAgent;
    });

    return exist;
  };

  return (
    <Widget className="widget-p-md">
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="text-center">
            <h1>Detalle de ticket</h1>
          </div>
          <Nav fill tabs style={{ overflowY: "hidden", overflowX: "auto" }}>
            {trazabilities.map((trazabilitie, index) => {
              return (
                <NavItem active={activeTab === index}>
                  <NavLink
                    href="#"
                    active={activeTab === index}
                    onClick={() => toggleTab(index, trazabilitie)}
                    disabled={false}
                    className={activeTab === index ? "text-primary" : ""}
                  >
                    {index + 1 < trazabilities.length ? (
                      <i className={s.navIco + " fa fa-check"} />
                    ) : +ticket.keyStat === 146 ? (
                      <i
                        className={s.navIco + " fa fa-check"}
                        style={{ color: "green" }}
                      />
                    ) : (
                      <i className={s.navIco + " fa fa-spinner text-info"} />
                    )}

                    {/* <i className={s.navIco + (index+1 < trazabilities.length) ? "fa fa-times text-danger" : +ticket.keyStat === 146 ?  "fa fa-check text-success" : "fa fa-spinner text-info"}  /> */}
                    {index + 1 + " - "}
                    {trazabilitie.nameTopic}
                  </NavLink>
                </NavItem>
              );
            })}
          </Nav>
        </>
      )}

      {detailTrazability && detailTrazability !== "" && !loading && (
        <>
          <DetailsView
            detailTrazability={detailTrazability}
            trazabilities={trazabilities}
            getDetailUpdated={getDetailUpdated}
            activeTab={activeTab}
          />

          <ModalAsignArea
            modal={modalAsignArea}
            setModal={setModalAsignArea}
            detailTrazability={detailTrazability}
            updateRoute={refreshInformation}
            formatearFecha={formatearFecha}
            setTicket={setTicket}
          />
        </>
      )}

      <ModalConfirmation
        modalTitle={"Cerrar"}
        modal={modalConfirmation}
        setModal={setModalConfirmation}
        crear={closeTicket}
        isEdit={false}
      >
        <div className="text-center">Esta seguro de cerrar el ticket</div>
      </ModalConfirmation>
      {trazabilities &&
        trazabilities.length > 0 &&
        detailTrazability &&
        detailTrazability.index &&
        isAgent &&
        detailTrazability.index === trazabilities.length &&
        +ticket.keyStat !== 146 &&
        isAggentCorrect() &&
        (+ticket.keyStat === 102 ? (
          <div className="d-flex justify-content-around">
            <Button
              onClick={() => {
                attendTicket();
              }}
              color="success"
            >
              Atender Ticket
            </Button>
          </div>
        ) : (
          <div className="d-flex justify-content-around">
            <div>
              <Button
                onClick={() => {
                  setModalAsignArea("asignArea");
                }}
                color="warning"
              >
                Asignar otra tarea
              </Button>
            </div>

            <div>
              <Button
                onClick={() => {
                  setModalAsignArea("closeTicket");
                }}
                color="success"
              >
                Marcar como resuelto
              </Button>
            </div>
          </div>
        ))}
    </Widget>
  );
};

export default TicketDetail;

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { NavLink, withRouter } from "react-router-dom";
import { Collapse } from "reactstrap";
import { useDispatch } from "react-redux";

import { closeSidebar } from "../../../actions/navigation.js";

import s from "./LinksGroup.module.scss";
import { allRoutes } from "../../Layout/Layout.jsx";

/**
 *
 * @param {
 * header: Nombre del link
 * link: template a apuntar
 * childrens: hijos del Grupo de links
 * iconName: icono que aparece a la izquierda (Opcional)
 * className: clase a aplicar en el list item (Opcional)
 * activeItem: item activo    index-
 * isRoot: Si se trata como links principales
 * index: Indice: (Raices principales)  index-index-
 * onActiveSidebarItemChange: Evento de activación
 * } params
 * @returns
 */
const LinksGroup = ({
  header,
  link,
  childrens,
  className,
  activeItem,
  isRoot,
  index,
  onActiveSidebarItemChange
}) => {
  const paths = allRoutes.map(route => route.path.toUpperCase());
  const dispatch = useDispatch();
  /**
   * Hook de estado de visualización del collapse
   */
  const [isOpen, setIsOpen] = useState(false);

  if (childrens && childrens.length > 0) {
    childrens = childrens.filter(children => children.CON === '1' || children.INS === '1' || children.UPD === '1' || children.DEL === '1')
    childrens = filtrado(childrens)
  }

  function filtrado(childrens) {
    const filtered = [];
    childrens.forEach(children => {
      if (children.children && children.children.length > 0) {
        const filt = filtrado(children.children)
        children.children = filt && filt.length > 0 ? filt : undefined;
        filtered.push(children)
      } else {
        if (paths.includes(`/TEMPLATE${children.fileRoute.toUpperCase()}`)) {
          filtered.push(children)
        }
      }
    })
    return filtered;
  }

  function getSubnivel(item) {
    let tempItem = item;
    if (tempItem.endsWith("-")) {
      tempItem = tempItem.substring(0, tempItem.length - 1);
    }
    const items = tempItem.split("-");
    items.pop();
    if (items.length > 0) {
      return items;
    } else {
      return null;
    }
  }

  /**
   * Funcion que manda a la funcion del sidebar para guardar en redux la variable
   * @param {evento de activación} e
   */
  const togglePanelCollapse = (e) => {
    if (activeItem && activeItem.startsWith(index)) {
      const lActItm = getLengthFromItem(activeItem);
      const lInd = getLengthFromItem(index);
      if (lActItm > lInd) {
        const subNivel = getSubnivel(index);
        if (subNivel) {
          onActiveSidebarItemChange(`${subNivel.join("-")}-`);
        } else {
          onActiveSidebarItemChange(""); //Funcion redux
        }
      } else {
        const subNivel = getSubnivel(activeItem);
        if (subNivel) {
          onActiveSidebarItemChange(`${subNivel.join("-")}-`);
        } else {
          onActiveSidebarItemChange(""); //Funcion redux
        }
      }
    } else {
      onActiveSidebarItemChange(index); //Funcion redux
    }
    e.preventDefault(); //Evitar que recargue pagina
  };

  /**
   * funcion para obtener la longitud real del id debido a que el id termina en - y surgen errores
   * @param {id del collapse} item
   * @returns longitud real de items de estado
   */
  function getLengthFromItem(item) {
    if (item) {
      if (item.endsWith("-")) {
        return item.substring(0, item.length - 1).split("-").length;
      }
      return item.split("-").length;
    }
    return "";
  }

  /**
   * Hook que detecta el cambio entre el indice y el item activo
   */
  useEffect(() => {
    if (activeItem) {
      const lActItm = getLengthFromItem(activeItem);
      const lInd = getLengthFromItem(index);
      setIsOpen(activeItem.startsWith(index) && lActItm >= lInd); //EJEMPLO: Index: 5- ActiveItem: 5-0-    B1: TRUE  B2: 2>=1: TRUE
    } else {
      setIsOpen(false);
    }
  }, [index, activeItem]); //EJEMPLO 2: Index: 5-0  ActiveItem: 5-1-0   B1: FALSE  B2: 3>=2: true

  /**
   * Funcion que retorna el estado final del arbol
   * @returns componente link
   */
  function getFinalPoint() {
    if (isRoot) {
      return (
        <li
          key={`subMEnd${index}`}
          className={`${s.headerLink} ${className ? className : ""}`}
        >
          <NavLink
            to={{ pathname: link, state: { menuPermission: true } }}
            activeClassName={s.headerLinkActive}
          >
            {header}
          </NavLink>
        </li>
      );
    }
    return (
      <li key={`finalEnd${index}`}>
        <NavLink
          to={{ pathname: link, state: { menuPermission: true } }}
          activeClassName={s.headerLinkActive}
          onClick={(e) => {
            dispatch(closeSidebar());
            if (link.includes("menu")) {
              e.preventDefault();
            }
          }}
        >
          <i className="fa fa-circle text-primary mr-2" />
          {header}
        </NavLink>
      </li>
    );
  }

  /**
   * Funcion que permite llamarse a si mismo si el nodo tiene mas hijos o no
   * @param {nodo actual} node
   * @param {indice del nodo} indexNode
   * @returns Llamado al mismo modulo
   */
  function recursividad(node, indexNode) {
    if (node.children && node.children.length > 0) {
      return (
        <LinksGroup
          onActiveSidebarItemChange={onActiveSidebarItemChange}
          header={node.nameModule}
          activeItem={activeItem}
          link={"/template/" + node.nameModule}
          index={`${index}${indexNode}`}
          childrens={node.children}
          isRoot
          key={`LG${index}${indexNode}`}
        />
      );
    }
    return (
      <LinksGroup
        onActiveSidebarItemChange={onActiveSidebarItemChange}
        header={node.nameModule}
        activeItem={activeItem}
        link={`/template${node.fileRoute.toLowerCase()}`}
        index={`${index}${indexNode}`}
        key={`LG${index}${indexNode}`}
      />
    );
  }

  /**
   * Funcion que establece el list del grupo
   * @returns Parte final del componente o subgrupo de elementos
   */
  function getSubMenu() {
    /**
     * Si el nodo tiene hijos crear collapse
     */
    if (childrens && childrens.length > 0) {
      return (
        <li
          key={`subM${index}`}
          className={`${className ? className : ""} ${isRoot ? s.headerLink : ""
            }`}
        >
          <a
            className={`d-flex ${isOpen ? s.collapsed : ""}`}
            onClick={(e) => togglePanelCollapse(e)}
            href="#top"
          >
            {header}
            <b className={["fa fa-angle-right", s.caret].join(" ")} />
          </a>
          <Collapse className={s.panel} isOpen={isOpen}>
            <ul>
              {childrens.map((child, index) => {
                return recursividad(child, index);
              })}
            </ul>
          </Collapse>
        </li>
      );
    }
    /**
     * Si no tiene hijos terminar ciclo
     */
    return <>{getFinalPoint()}</>;
  }

  /**
   * Si es un arbol el nodo actual(Collapse)
   */
  if (isRoot) {
    return <>{getSubMenu()}</>;
  }
  /**
   * Si no es nodo terminar abruptamente
   */
  return <>{getFinalPoint()}</>;
};

LinksGroup.propTypes = {
  header: PropTypes.node.isRequired,
  link: PropTypes.string.isRequired,
  childrens: PropTypes.array,
  iconName: PropTypes.object,
  className: PropTypes.string,
  activeItem: PropTypes.string,
  isRoot: PropTypes.bool,
  index: PropTypes.string,
  onActiveSidebarItemChange: PropTypes.func,
};

export default withRouter(LinksGroup);
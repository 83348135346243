import React from "react";

import Widget from "../../../../components/Widget/Widget";
import FormEstado from "../FormEstado";
import { updateState } from "../functions";

export const EditarEstado = (props) => {
  return (
    <Widget className="widget-p-md">
        <h3>Editar estado</h3>
        <FormEstado data={localStorage.getItem('dataContent').split('|-|')} editar={updateState}/>
    </Widget>
  )
}

export default EditarEstado;
import React from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import TableComponentVer from "../TableComponent/TableComponentVer";

function ModalTableVer(props) {
  const {
    modal,
    setModal,
    title,
    titleTable,
    cabeceras,
    filtro,
    parametros,
    selectRegistro,
    backdrop, // true, false, "static"
    keyboard, // true, false
    btnClose, // true, false
    style,
    hideTitleTable
  } = props;

  const toggle = () => {
    setModal(!modal);
  };

  const CloseBtn = (
    <>
      { btnClose?
        <i className="eva eva-close cursor-pointer" onClick={toggle} /> :
        <></>
      }
    </>
  );

  return (
    <>
      <Modal isOpen={modal} backdrop={backdrop} keyboard={keyboard} toggle={ toggle} size="xl" style={style}>
        <ModalHeader toggle={toggle} close={CloseBtn}>{title || ""}</ModalHeader>
        <ModalBody className="mx-2">
          <TableComponentVer
            titulo={titleTable}
            cabecerasTabla={cabeceras}
            filtro={filtro}
            ver={selectRegistro}
            parametros={parametros}
            canSee={props.canSee}
            hideTitle={hideTitleTable}
          />
        </ModalBody>
      </Modal>
    </>
  );
}

export default ModalTableVer;

import { peticionesReceiver } from "../../../helpers/peticionesReceiver";
import Notification from "../../../components/Notification/Notification";
import { toast } from "react-toastify";
const API = peticionesReceiver();

export const createState = async (estadoN) => {
    if (estadoN) {
      const params = {
        action: "insert",
        table: "states",
        rows: estadoN,
        validate: [["nameState", "keyCountry", "claveSAT"], ["claveSAT"]]
      };
      API.peticion(params).then((res) => { 
          if (res.status === 200 && res.data.code === "200") {
            toast(<Notification type={"agrega_exito"} backMessage={res.data.message} withIcon />);
            window.location.href ="#/template/estados"
          } else {
            toast(<Notification type={"agrega_error"} backMessage={res.data.message} withIcon />);
          }
        })
        .catch((err) => {
          toast(<Notification type={"consultar_servidor_error"} withIcon />);
        });
    }
  };
  export const updateState = async (estadoU) => {
    if (estadoU) {
      const params = {
        action: "update",
        table: "states",
        rows: estadoU,
        condition: {
          keyState: estadoU.keyState,
        },
        validate: [["nameState", "keyCountry", "claveSAT"], ["claveSAT"]]
      };
      API.peticion(params).then((res) => { 
          if (res.status === 200 && res.data.code === "200") {
            toast(<Notification type={"modifica_exito"} backMessage={res.data.message} withIcon />);
            window.location.href ="#/template/estados"
          } else {
            console.log(res);
            toast(<Notification type={"modifica_error"} backMessage={res.data.message} withIcon />);
          }
        })
        .catch((err) => {
          toast(<Notification type={"consultar_servidor_error"} withIcon />);
        });
    }
  };
import { Button, Col, Container, Row } from "reactstrap";
import Widget from "../../../components/Widget/Widget";
import { TextInput } from "../../../components/GenericInputsFormik";
import { useState } from "react";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import FullFormLoader from "../../../components/Loader/FullFormLoader";
import { doRequest } from "../../../helpers/requests";
import { useEffect } from "react";
import { useFormik } from "formik";

export default function ResetPassword() {
    const [state, setState] = useState("start");
    
    return (
        <div className="auth-page">
            <Container className="h-100">
                <Row className="align-items-center h-100">
                    <Col xs={12} lg={7} className="mx-auto">
                        <Widget className="widget-p-lg">
                            <h5>Cambio de contraseña</h5>
                            <hr />
                            <div className="text-center mb-2">
                                <img
                                    src={`${origin}/images/logo.png`}
                                    alt="recovery-pass"
                                    style={{ maxWidth: "12%" }}
                                />
                            </div>
                            { state === "start" ? (
                                <FormEmail state={state} setState={setState} />
                            ) : (
                                <div className="text-dark">
                                    <p className="mt-1">
                                        Contraseña actualizada correctamente. Inicie sesión en el sistema para confirmar el cambio.
                                    </p>
                                    <div className="mt-3 text-center">
                                        <Link to="/" style={{ fontSize: "14px" }} className="btn btn-primary">
                                            Iniciar sessión
                                        </Link>
                                    </div>
                                </div>
                            )}
                        </Widget>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

function FormEmail({ state, setState }) {
    const [loading, setLoading] = useState(false);

    const FormSchema = Yup.object().shape({
        pass: Yup.string()
          .min(6, "La constraseña debe tener mas de 6 caracteres")
          .required("Ingrese una constraseña"),
        repass: Yup.string().required("Repita la contraseña constraseña").when("pass", {
            is: (val) => val,
            then: () =>
              Yup.string()
                .required("Confirme su contraseña")
                .oneOf([Yup.ref("pass")], "La contraseña ingresada no coincide"),
          }),
      });

    const {
        handleSubmit,
        values,
        handleBlur,
        errors,
        touched,
        setFieldValue,
        setValues,
    } = useFormik({
        initialValues: {pass: "", repass: ""},
        validationSchema: FormSchema,
        onSubmit: () => changePassword(),
        enableReinitialize: true,
    });

    async function changePassword() {
        setLoading(true);
        // const res = doRequest("receiver", params, true);
        // if((await res).length > 0){
            setState("finish");
        // }
        setLoading(false);
    }

    function onChange({target}){
        setFieldValue(target.name, target.value);
    }

    return (
        <>
            <p className="text-dark mb-3">
                Ingrese su nueva contraseña en el primer campo y repitala en el segundo campo apra validarla.
            </p>
            <form action="" onSubmit={handleSubmit}>
                <TextInput
                    label="Nueva contraseña"
                    inputType="password"
                    inputName="pass"
                    onChangeMethod={onChange}
                    onBlurMethod={handleBlur}
                    value={values.pass}
                    errors={errors.pass}
                    touched={touched.pass}
                    isRequired
                />
                <TextInput
                    inputName="repass"
                    inputType="password"
                    label="Repetir contraseña"
                    onChangeMethod={onChange}
                    onBlurMethod={handleBlur}
                    value={values.repass}
                    errors={errors.repass}
                    touched={touched.repass}
                    isRequired
                />
                <div className=" d-flex flex-md-row flex-column justify-content-center">
                    <Button
                        color="success"
                        type="submit"
                        className="mt-2 mt-md-3"
                    >
                        Guardar
                    </Button>
                </div>
            </form>
            <FullFormLoader show={loading} />
        </>
    );
}

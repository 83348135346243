import React, { useState } from "react";
import ModalTableVer from "../../../components/Modal/ModalTableVer";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Col, Row, Button } from "reactstrap";
import { CancelOutlined } from "@material-ui/icons";
import s from "./InputClear.module.scss";

const defaultForm = {
  idStaff: "",
  nameStaff: "",
  idArea: "",
  nameArea: "",
  idFurniture: "",
  nameFurniture: "",
};

const ConsultaBien = (props) => {
  const { setParams } = props;

  

  //vars staff
  const [modalTablaStaff, setModalTablaStaff] = useState(false);
  const cabecerasModalTablaStaff = ["#", "Empleado"];
  const [paramsModalTablaStaff] = useState({
    action: "datatable",
    table: "staffview",
    rows: "idstaff,CONCAT(staffNumber,'-',name,'-',pName,'-',mName) as fullname",
    conditions: "",
    page: 0,
    records: 5,
    search: "",
    order: "",
  });

  //vars area
  const [modalTablaArea, setModalTablaArea] = useState(false);
  const cabecerasModalTablaArea = ["#", "Adscripcion"];
  const [paramsModalTablaArea] = useState({
    action: "datatable",
    table: "areas",
    rows: "idArea,name",
    conditions: "",
    page: 0,
    records: 5,
    search: "",
    order: "",
  });

  //vars furniture
  const [modalTablaFurniture, setModalTablaFurniture] = useState(false);
  const cabecerasModalTablaFurniture = ["#", "Descripcion"];
  const [paramsModalTablaFurniture] = useState({
    action: "datatable",
    table: "furnitures",
    rows: "idFurniture,CONCAT(nameFurniture,' ',model,' ',brand) as description",
    conditions: "enabled = 1",
    page: 0,
    records: 5,
    search: "",
    order: "",
  });

  const FormSchema = Yup.object().shape({
    nameStaff: Yup.string().required("Seleccione un empleado"),
    nameArea: Yup.string().required("Seleccione un area"),
    nameFurniture: Yup.string().required("Seleccione un bien"),
  });

  const {
    handleSubmit,
    handleBlur,
    values,
    errors,
    touched,
    setFieldValue,
    resetForm,
  } = useFormik({
    initialValues: defaultForm,
    onSubmit: (values) => {
      setParams(
        {
        action: "datatable",
        table:
          "inventories A INNER JOIN assignment B ON (A.idInventory = B.idInventory and B.enabled=1) INNER JOIN furnitures C USING (idFurniture) INNER JOIN status D using (keyStat) INNER JOIN furniture_classes E USING (keyClass) inner join furniture_subgroups F USING (keySubGroup) inner join furniture_groups G USING (keyGroup)",
        rows: "A.idInventory,CONCAT(nameGroup,'\n',nameSubGroup,'\n',nameClass) as setup, A.idInventory as keybars,serialKey,nameFurniture,brand,model,nameStat,actualPrice",
        conditions: `A.enabled =1 and B.idStaff = ${values.idStaff} and B.idArea = ${values.idArea} and A.idFurniture = ${values.idFurniture}`,
        /* conditions: `A.enabled =1 and B.idStaff = ${2} and B.idArea = ${46} and A.idFurniture = ${1}`, */
        page: 0,
        records: 5,
        search: "",
        order: "",
      }
      );
    },
    validationSchema: FormSchema,
    enableReinitialize: true,
  });

  const onChange = (e) => {
    setParams("")
    setFieldValue([e.target.name], e.target.value);
  };

  const selectStaff = (el) => {
    setFieldValue("idStaff", el[0]);
    setFieldValue("nameStaff", `${el[1]}`);
    setParams("")
    setModalTablaStaff(false);
  };

  function clearStaff() {
    setFieldValue("idStaff", "");
    setFieldValue("nameStaff", "");
  }

  const selectArea = (el) => {
    setFieldValue("idArea", el[0]);
    setFieldValue("nameArea", `${el[1]}`);
    setParams("")
    setModalTablaArea(false);
  };

  function clearArea() {
    setFieldValue("idArea", "");
    setFieldValue("nameArea", "");
  }
  const selectFurniture = (el) => {
    setFieldValue("idFurniture", el[0]);
    setFieldValue("nameFurniture", `${el[1]}`);
    setParams("")
    setModalTablaFurniture(false);
  };

  function clearFurniture() {
    setFieldValue("idFurniture", "");
    setFieldValue("nameFurniture", "");
  }

  return (
    <div>
      <form onSubmit={handleSubmit} className="mt-4" noValidate>
        <Row>
          <Col xs={12} lg={6}>
            <div className="form-group position-relative">
              <label htmlFor="nameStaff">
                Empleado <span className="text-danger">*</span>
              </label>
              <div className="input-group">
                <input
                  name="nameStaff"
                  onChange={onChange}
                  onBlur={handleBlur}
                  className={
                    "form-control" +
                    (errors.nameStaff && touched.nameStaff ? " is-invalid" : "")
                  }
                  value={values.nameStaff}
                  readOnly
                  onClick={() => setModalTablaStaff(true)}
                />
                <div className="input-group-append">
                  <button
                    className="btn btn-secondary"
                    style={{ zIndex: "auto" }}
                    type="button"
                    onClick={() => setModalTablaStaff(true)}
                  >
                    <i className="fa fa-search" />
                  </button>
                </div>
              </div>
              {values.nameStaff !== "" && (
                <CancelOutlined className={s.btnClear} onClick={clearStaff} />
              )}
              {touched.nameStaff && errors.nameStaff && (
                <span style={{ color: "red" }}>{errors.nameStaff}</span>
              )}
            </div>
          </Col>
          <Col xs={12} lg={6}>
            <div className="form-group position-relative">
              <label htmlFor="nameArea">
                Adscripcion <span className="text-danger">*</span>
              </label>
              <div className="input-group">
                <input
                  name="nameArea"
                  onChange={onChange}
                  onBlur={handleBlur}
                  className={
                    "form-control" +
                    (errors.nameArea && touched.nameArea ? " is-invalid" : "")
                  }
                  value={values.nameArea}
                  readOnly
                  onClick={() => setModalTablaArea(true)}
                />
                <div className="input-group-append">
                  <button
                    className="btn btn-secondary"
                    style={{ zIndex: "auto" }}
                    type="button"
                    onClick={() => setModalTablaArea(true)}
                  >
                    <i className="fa fa-search" />
                  </button>
                </div>
              </div>
              {values.nameArea !== "" && (
                <CancelOutlined className={s.btnClear} onClick={clearArea} />
              )}
              {touched.nameArea && errors.nameArea && (
                <span style={{ color: "red" }}>{errors.nameArea}</span>
              )}
            </div>
          </Col>
        </Row>
        <Row className="d-flex justify-content-around ">
          <Col xs={12} lg={6}>
            <div className="form-group position-relative">
              <label htmlFor="nameFurniture">
                Bien mueble <span className="text-danger">*</span>
              </label>
              <div className="input-group">
                <input
                  name="nameFurniture"
                  onChange={onChange}
                  onBlur={handleBlur}
                  className={
                    "form-control" +
                    (errors.nameFurniture && touched.nameFurniture
                      ? " is-invalid"
                      : "")
                  }
                  value={values.nameFurniture}
                  readOnly
                  onClick={() => setModalTablaFurniture(true)}
                />
                <div className="input-group-append">
                  <button
                    className="btn btn-secondary"
                    style={{ zIndex: "auto" }}
                    type="button"
                    onClick={() => setModalTablaFurniture(true)}
                  >
                    <i className="fa fa-search" />
                  </button>
                </div>
              </div>
              {values.nameFurniture !== "" && (
                <CancelOutlined
                  className={s.btnClear}
                  onClick={clearFurniture}
                />
              )}
              {touched.nameFurniture && errors.nameFurniture && (
                <span style={{ color: "red" }}>{errors.nameFurniture}</span>
              )}
            </div>
          </Col>
        </Row>

        <Row>
          <Col className="text-center">
            <Button color="primary" type="submit">
              Buscar
            </Button>
          </Col>
        </Row>
      </form>
      <ModalTableVer
        modal={modalTablaStaff}
        setModal={setModalTablaStaff}
        title="Empleados"
        cabeceras={cabecerasModalTablaStaff}
        filtro={[true, true]}
        parametros={paramsModalTablaStaff}
        selectRegistro={selectStaff}
        backdrop={"static"}
        keyboard={false}
      />
      <ModalTableVer
        modal={modalTablaArea}
        setModal={setModalTablaArea}
        title="Areas"
        cabeceras={cabecerasModalTablaArea}
        filtro={[true, true]}
        parametros={paramsModalTablaArea}
        selectRegistro={selectArea}
        backdrop={"static"}
        keyboard={false}
      />
      <ModalTableVer
        modal={modalTablaFurniture}
        setModal={setModalTablaFurniture}
        title="Bienes muebles"
        cabeceras={cabecerasModalTablaFurniture}
        filtro={[true, true]}
        parametros={paramsModalTablaFurniture}
        selectRegistro={selectFurniture}
        backdrop={"static"}
        keyboard={false}
      />
    </div>
  );
};

export default ConsultaBien;

import { Document, Page, Text, View, StyleSheet } from "@react-pdf/renderer";
import { styles } from "../../../../components/Pdf/pdfGeneral/EstiloPdf";

/**
 * Estilo que se repite en todas los headers de la tabla
 */
const styleHeadForAll = {
    fontFamily: 'Helvetica-Bold',
    paddingVertical: 3,
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "#646464",
    color: 'white',
    textAlign: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 6,
    borderBottom: 0
}

const styleCellForAll = {
    paddingVertical: 3,
    borderStyle: "solid",
    borderWidth: 1,
    borderTopWidth: 0,
    fontSize: 6,
    paddingHorizontal: 2
}

/**
 * Estilos del pdf
 */
const s = StyleSheet.create({
    body: {
        padding: 10
    },
    subTitle: { //SUBTITULOS
        width: "100%",
        textAlign: 'center',
        marginBottom: 10,
        marginLeft: 10,
        fontSize: 12
    },
    f6: {
        fontSize: 6
    },
    f5: {
        fontSize: 5
    },
    f8: {
        fontSize: 8
    },
    f10: {
        fontSize: 10
    },
    thIn: {
        ...styleHeadForAll
    },
    th: {
        ...styleHeadForAll,
        borderLeftWidth: 0
    },
    h130: {
        height: "130"
    },
    w5: {
        width: "5%"
    },
    w6: {
        width: "6%"
    },
    w7: {
        width: "7%"
    },
    w10: {
        width: "10%"
    },
    w15: {
        width: "15%"
    },
    w20: {
        width: "20%"
    },
    w18: {
        width: "18%"
    },
    w25: {
        width: "25%"
    },
    w30: {
        width: "30%"
    },
    w33: {
        width: "33%"
    },
    w34: {
        width: "34%"
    },
    w40: {
        width: "40%"
    },
    w45: {
        width: "45%"
    },
    w50: {
        width: "50%"
    },
    w60: {
        width: "60%"
    },
    w70: {
        width: "70%"
    },
    w80: {
        width: "80%"
    },
    w90: {
        width: "90%"
    },
    w100: {
        width: "100%"
    },
    m1: {
        margin: 3
    },
    mt2: {
        marginTop: 5
    },
    mt3: {
        marginTop: 10
    },
    mx2: {
        marginHorizontal: 5
    },
    mt4: {
        marginTop: 40
    },
    pt1: {
        paddingTop: 10
    },
    px2: {
        paddingHorizontal: 10
    },
    px1: {
        paddingHorizontal: 5
    },
    py6: {
        paddingVertical: 10
    },
    b1: {
        border: 1
    },
    bt0: {
        borderTopWidth: 0
    },
    bt1: {
        borderTopWidth: 1
    },
    center: {
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
    },
    textCenter: {
        textAlign: 'center',
    },
    textBold: {
        fontFamily: 'Helvetica-Bold'
    },
    textRight: {
        textAlign: "right"
    },
    tdIn: { //Cell CC
        ...styleCellForAll
    },
    td: { //Cell Concepto
        ...styleCellForAll,
        borderLeftWidth: 0
    },
    td0: { //Cell Concepto
        ...styleCellForAll,
        borderLeftWidth: 0,
        borderRightWidthWidth: 0
    },
    red: {
        backgroundColor: "red",
    },
    green: {
        backgroundColor: "green",
    },
    gray: {
        backgroundColor: "gray",
    },
    h100: {
        height: "100%"
    }
})

const formatter = new Intl.NumberFormat("es-MX", {
    style: "currency",
    currency: "MXN",
});

function getTiempoEntrega(daysReception, nameUnit) {
    switch (nameUnit) {
        case "MES":
            return (Number(daysReception) * 30)
        case "AÑO":
            return (Number(daysReception) * 365)
        default:
            return (Number(daysReception))
    }
}

export default function exportCuadro(report, inicial = true) {

    if (inicial) {
        report.partidas.forEach(partida => {
            report.providerQuotes.forEach(pq => {
                const index = pq.data.findIndex(data => data.idDetail === partida.idDetail);
                if (index !== -1) {
                    if (partida.cot !== undefined) {
                        partida.cot.push(pq.data[index])
                    } else {
                        partida.cot = [pq.data[index]];
                    }

                }
            })
        })
        const winners = []
        report.partidas.forEach((partida, index) => {
            partida.cot.forEach((prov, ind) => {
                if (ind === 0) {
                    winners.push(prov);
                } else {
                    if (Number(prov.total) < Number(winners[index].total)) {
                        winners[index] = prov;
                    } else if (Number(prov.total) === Number(winners[index].total) && getTiempoEntrega(prov.daysReception, prov.nameUnit) < getTiempoEntrega(winners[index].daysReception, winners[index].nameUnit)) {
                        winners[index] = prov
                    }
                }
            })
        })
        winners.forEach((winner, index) => {
            report.partidas[index].winner = winner.idProviderQuote
        })
        
    }


    function getPromSub(cot, amount) {
        let suma = 0;
        cot.forEach(val => {
            suma += (Number(amount) * Number(val.total))
        })
        return formatter.format(suma / cot.length);
    }

    function getUnit(cot, amount) {
        let suma = 0;
        cot.forEach(val => {
            suma += Number(val.unitCost)
        })
        return formatter.format(suma / cot.length);
    }

    function getTaxesSub(cot, amount, unit = true) {
        let suma = 0;
        cot.forEach(val => {
            if (unit) {
                suma += Number(val.taxes)
            } else {
                suma += (Number(amount) * Number(val.taxes))
            }
        })
        return formatter.format(suma / cot.length);
    }

    function getTotalSub(cot, amount, unit = true) {
        let suma = 0;
        cot.forEach(val => {
            if (unit) {
                suma += Number(val.total)
            } else {
                suma += (Number(amount) * Number(val.total))
            }
        })
        return formatter.format(suma / cot.length);
    }

    function getBackground(partida, prov) {
        if (partida.winner && partida.winner === prov.idProviderQuote) {
            return s.green
        }
        return undefined
    }

    return {
        report: (
            <>
                <Document>
                    {report.partidas.map((partida, indPart) => (
                        <>
                            {Array(Math.ceil(partida.cot.length / 3)).fill(0).map((value, index) => (
                                <Page style={s.body} orientation="landscape">
                                    <View>
                                        <Text style={[s.center, s.f10, s.textBold]}>CUADRO COMPARATIVO DE COTIZACIONES Y CONDICIONES COMERCIALES PARA ESTABLECER PRECIO DE REFERENCIA</Text>
                                    </View>
                                    <View style={styles.table}>
                                        <View style={styles.tableRow}>
                                            <View style={[s.thIn, s.w5]}>
                                                <Text>PARTIDA</Text>
                                            </View>
                                            <View style={[s.th, s.w5]}>
                                                <Text>CANTIDAD</Text>
                                            </View>
                                            <View style={[s.th, s.w5]}>
                                                <Text>UNIDAD DE</Text>
                                                <Text>MEDIDA</Text>
                                            </View>
                                            <View style={[s.th, s.w15]}>
                                                <Text>DESCRIPCION DEL BIEN Y/O SERVICIO</Text>
                                            </View>
                                            <View style={s.w50}>
                                                <View style={styles.tableRow}>
                                                    <View style={[s.th, s.w100]}>
                                                        <Text>PROVEEDORES Y/O PRESTADORES DE SERVICIOS</Text>
                                                    </View>
                                                </View>
                                                <View style={styles.tableRow}>
                                                    {partida.cot.slice(index * 3, index * 3 + 3).map(prov => (
                                                        <View style={s.w100}>
                                                            <View style={styles.tableRow}>
                                                                <View style={[s.th, s.w100]}>
                                                                    <Text>{prov.socialName}</Text>
                                                                </View>
                                                            </View>
                                                            <View style={styles.tableRow}>
                                                                <View style={[s.th, s.w100]}>
                                                                    <Text>{prov.itin}</Text>
                                                                </View>
                                                            </View>
                                                            <View style={styles.tableRow}>
                                                                <View style={[s.th, s.w33]}>
                                                                    <Text>MARCA</Text>
                                                                </View>
                                                                <View style={[s.th, s.w33]}>
                                                                    <Text>PRECIO</Text>
                                                                    <Text>UNITARIO</Text>
                                                                </View>
                                                                <View style={[s.th, s.w34]}>
                                                                    <Text>SUBTOTAL</Text>
                                                                </View>
                                                            </View>
                                                        </View>
                                                    ))}
                                                </View>
                                            </View>
                                            <View style={s.w20}>
                                                <View style={styles.tableRow}>
                                                    <View style={[s.th, s.w100, s.py6]}>
                                                        <Text>PRECIO</Text>
                                                        <Text>PROMEDIO</Text>
                                                        <Text>DE</Text>
                                                        <Text>REFERENCIA</Text>
                                                    </View>
                                                </View>
                                                <View style={styles.tableRow}>
                                                    <View style={[s.th, s.w50]}>
                                                        <Text>UNITARIO</Text>
                                                    </View>
                                                    <View style={[s.th, s.w50]}>
                                                        <Text>SUBTOTAL</Text>
                                                    </View>
                                                </View>
                                            </View>
                                        </View>
                                        <View style={styles.tableRow}>
                                            <View style={[s.tdIn, s.w5, s.textCenter]}>
                                                <Text>{indPart + 1}</Text>
                                            </View>
                                            <View style={[s.td, s.w5, s.textCenter]}>
                                                <Text>{partida.amount}</Text>
                                            </View>
                                            <View style={[s.td, s.w5]}>
                                                <Text>{partida.unit}</Text>
                                            </View>
                                            <View style={[s.td, s.w15]}>
                                                <Text>{partida.descripcion}</Text>
                                            </View>
                                            <View style={s.w50}>
                                                <View style={styles.tableRow}>
                                                    {partida.cot.slice(index * 3, index * 3 + 3).map(prov => (
                                                        <View style={s.w100}>
                                                            <View style={[styles.tableRow, getBackground(partida, prov)]}>
                                                                <View style={[s.td, s.w33]}>
                                                                    <Text>{partida.brand}</Text>
                                                                </View>
                                                                <View style={[s.td, s.w33, s.textRight]}>
                                                                    <Text>{formatter.format(prov.unitCost)}</Text>
                                                                </View>
                                                                <View style={[s.td, s.w34, s.textRight]}>
                                                                    <Text>{formatter.format(Number(partida.amount) * Number(prov.unitCost))}</Text>
                                                                </View>
                                                            </View>
                                                        </View>
                                                    ))}
                                                </View>
                                            </View>
                                            <View style={s.w20}>
                                                <View style={styles.tableRow}>
                                                    <View style={[s.td, s.w50]}>
                                                        <Text>{getUnit(partida.cot, partida.amount)}</Text>
                                                    </View>
                                                    <View style={[s.td, s.w50]}>
                                                        <Text>{getPromSub(partida.cot, partida.amount)}</Text>
                                                    </View>
                                                </View>
                                            </View>
                                        </View>
                                        <View style={styles.tableRow}>
                                            <View style={s.w15} />
                                            <View style={[s.tdIn, s.w15]}>
                                                <Text>SUBTOTAL</Text>
                                            </View>
                                            <View style={s.w50}>
                                                <View style={styles.tableRow}>
                                                    {partida.cot.slice(index * 3, index * 3 + 3).map(prov => (
                                                        <View style={[s.w100, s.td, s.textRight]}>
                                                            <Text>{formatter.format(Number(partida.amount) * Number(prov.unitCost))}</Text>
                                                        </View>
                                                    ))}
                                                </View>
                                            </View>
                                            <View style={s.w20}>
                                                <View style={styles.tableRow}>
                                                    <View style={[s.td, s.w50]}>
                                                        <Text>{getUnit(partida.cot, partida.amount)}</Text>
                                                    </View>
                                                    <View style={[s.td, s.w50]}>
                                                        <Text>{getPromSub(partida.cot, partida.amount)}</Text>
                                                    </View>
                                                </View>
                                            </View>
                                        </View>
                                        <View style={styles.tableRow}>
                                            <View style={s.w15} />
                                            <View style={[s.tdIn, s.w15]}>
                                                <Text>IMPUESTOS</Text>
                                            </View>
                                            <View style={s.w50}>
                                                <View style={styles.tableRow}>
                                                    {partida.cot.slice(index * 3, index * 3 + 3).map(prov => (
                                                        <View style={[s.w100, s.td, s.textRight]}>
                                                            <Text>{formatter.format(Number(partida.amount) * Number(prov.taxes))}</Text>
                                                        </View>
                                                    ))}
                                                </View>
                                            </View>
                                            <View style={s.w20}>
                                                <View style={styles.tableRow}>
                                                    <View style={[s.td, s.w50]}>
                                                        <Text>{getTaxesSub(partida.cot, partida.amount)}</Text>
                                                    </View>
                                                    <View style={[s.td, s.w50]}>
                                                        <Text>{getTaxesSub(partida.cot, partida.amount, false)}</Text>
                                                    </View>
                                                </View>
                                            </View>
                                        </View>
                                        <View style={styles.tableRow}>
                                            <View style={s.w15} />
                                            <View style={[s.tdIn, s.w15]}>
                                                <Text>TOTAL</Text>
                                            </View>
                                            <View style={s.w50}>
                                                <View style={styles.tableRow}>
                                                    {partida.cot.slice(index * 3, index * 3 + 3).map(prov => (
                                                        <View style={[s.w100, s.td, s.textRight]}>
                                                            <Text>{formatter.format(Number(partida.amount) * Number(prov.total))}</Text>
                                                        </View>
                                                    ))}
                                                </View>
                                            </View>
                                            <View style={s.w20}>
                                                <View style={styles.tableRow}>
                                                    <View style={[s.td, s.w50]}>
                                                        <Text>{getTotalSub(partida.cot, partida.amount)}</Text>
                                                    </View>
                                                    <View style={[s.td, s.w50]}>
                                                        <Text>{getTotalSub(partida.cot, partida.amount, false)}</Text>
                                                    </View>
                                                </View>
                                            </View>
                                        </View>
                                    </View>
                                    <View style={[styles.table, s.w60]}>
                                        <View style={styles.tableRow}>
                                            <View style={[s.thIn, s.w100]}>
                                                <Text>CONDICIONES COMERCIALES</Text>
                                            </View>
                                        </View>
                                        <View style={styles.tableRow}>
                                            <View style={[s.tdIn, s.w50]}>
                                                <Text>PLAZO DE ENTREGA</Text>
                                            </View>
                                            {partida.cot.slice(index * 3, index * 3 + 3).map(prov => (
                                                <View style={[s.w100, s.td, s.textRight]}>
                                                    <Text>{prov.daysReception} {prov.nameUnit}</Text>
                                                </View>
                                            ))}
                                        </View>
                                        <View style={styles.tableRow}>
                                            <View style={[s.tdIn, s.w50]}>
                                                <Text>LUGAR DE ENTREGA</Text>
                                            </View>
                                            {partida.cot.slice(index * 3, index * 3 + 3).map(prov => (
                                                <View style={[s.w100, s.td, s.textRight]}>
                                                    <Text>{prov.nameArea}</Text>
                                                </View>
                                            ))}
                                        </View>
                                        <View style={styles.tableRow}>
                                            <View style={[s.tdIn, s.w50]}>
                                                <Text>VIGENCIA DE LOS PRECIOS</Text>
                                            </View>
                                            {partida.cot.slice(index * 3, index * 3 + 3).map(prov => (
                                                <View style={[s.w100, s.td, s.textRight]}>
                                                    <Text>{prov.validity}</Text>
                                                </View>
                                            ))}
                                        </View>
                                        <View style={styles.tableRow}>
                                            <View style={[s.tdIn, s.w50]}>
                                                <Text>CONDICIONES DE PAGO</Text>
                                            </View>
                                            {partida.cot.slice(index * 3, index * 3 + 3).map(prov => (
                                                <View style={[s.w100, s.td, s.textRight]}>
                                                    <Text>{prov.paymentDetail}</Text>
                                                </View>
                                            ))}
                                        </View>
                                    </View>
                                </Page>
                            ))}
                        </>
                    ))}

                </Document>
            </>)
    }
}
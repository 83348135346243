import { Button, Col, Row } from "reactstrap";
import FullFormLoader from "../../../components/Loader/FullFormLoader";
import { useRef, useState } from "react";
import Widget from "../../../components/Widget/Widget";
import Datatable from "../../../components/Datatable/Datatable";
import { fDatewhitTime } from "../../../libs/tools/format";
import {
    doRequestSaveRes,
    doRequest,
    uploadFile,
} from "../../../helpers/requests";
import { useFormik } from "formik";
import * as Yup from "yup";
import { TextInput } from "../../../components/GenericInputsFormik";
import ModalConfirmation from "../../../components/Modal/ModalConfirmation";
import { useHistory } from "react-router-dom";
import { useEffect } from "react";

const headers = [
    "Costo Unitario",
    "Existencia",
    "Costo promedio",
    "No. Fcatura",
    "Fecha factura",
    "Proveedor",
];
const columns = [
    { data: "unitCost", format: "currency" },
    { data: "quantity" },
    { data: "averageCost", format: "currency" },
    { data: "idFifo" },
    { data: "created" },
    { data: "" },
];
const columnDefs = [
    { targets: [0, 2], className: "text-right" },
    { targets: [1], className: "text-right" },
    { targets: [3], className: "text-center" },
    { targets: [4], className: "text-center", format: fDatewhitTime },
];

const FormSchema = Yup.object().shape({
    hall: Yup.string().required("Ingrese un número de pasillo"),
    shell: Yup.string().required("Ingrese un número de anaquel"),
    position: Yup.string().required("Ingrese una posición"),
});

export default function DetalleExistenciasAlmacen() {
    const [loading, setLoading] = useState(true);
    const [refresh, setRefresh] = useState(true);
    const [modal, setModal] = useState(false);
    const [infoBien, setInfoBien] = useState({});
    const idInventory = localStorage.getItem("idInventory");
    const refInputCam = useRef(null);
    const history = useHistory();

    const {
        handleSubmit,
        values,
        handleBlur,
        errors,
        touched,
        setFieldValue,
        setValues,
    } = useFormik({
        initialValues: {hall:"", shell:"", position:""},
        validationSchema: FormSchema,
        onSubmit: () => setModal(true),
        enableReinitialize: true,
    });
    
    useEffect(()=>{
        if(!idInventory) history.push({ pathname: "/template/existencias-almacen" });
        else getInfo();
    }, []);

    
    async function getInfo(){
        const params = {
            action: "multiselect",
            table: "warehouseinventory A INNER JOIN furnitures B USING(idFurniture) LEFT JOIN bookcase C ON C.reference = B.idFurniture LEFT JOIN files D USING(idBook) INNER JOIN furniture_classes E USING(keyClass) INNER JOIN furniture_subgroups F USING(keySubGroup) INNER JOIN messureunits G USING(keyUnit)",
            rows: "A.idInventory,A.idFurniture,CONCAT_WS(' ', B.brand, B.model, B.nameFurniture) AS fullName,A.totalCount,A.reserved,D.src,A.hall,A.shell,A.position,G.nameUnit",
            conditions: `A.idInventory = ${idInventory} AND D.enabled = 1 AND C.keyFileType = 24`
        }
        const res = await doRequest("receiver/receiver.php", params, false, true);
        if(res.length > 0){
            const data = res[0];
            setInfoBien(data);
            setValues({
                hall: data.hall,
                shell: data.shell,
                position: data.position,
            }, true);
        }else history.push({ pathname: "/template/existencias-almacen" });
        setLoading(false);
    }

    const onChange = ({target}) => {
        if(target.name === "photo") saveImage(target.files);
        setFieldValue(target.name, target.value);
    }

    async function getData(ordCol, itemsPerPage, currentPage, search) {
        const res = await doRequestSaveRes(
            "receiver/receiver.php",
            getParams(ordCol, itemsPerPage, currentPage, search),
            false,
            false
        );
        if (res.code === "200") return res;
        else return null;
    }

    function getParams(ordCol, itemsPerPage, currentPage, search) {
        const params = {
            action: "datatable",
            table: "warehousefifos A LEFT JOIN warehouseincomereceptions B USING(idIncomeReception) LEFT JOIN receptions C USING (idReception) LEFT JOIN purchasewinners D USING (idWinner) LEFT JOIN providerquotes E USING (idProviderQuote) LEFT JOIN providers F USING (idProvider)",
            rows: "A.unitCost, A.quantity, A.averageCost, A.idFifo, A.created, F.socialName",
            conditions: `A.idInventory = ${infoBien.idInventory || "0"} AND A.enabled = 1`,
            order: ordCol,
            records: itemsPerPage.toString(),
            page: (itemsPerPage * currentPage).toString(),
            search,
        };
        return params;
    }

    async function saveImage(files){
        if (files.length !== 0) {
            setLoading(true);
            const res = await uploadFile(infoBien.idFurniture, "saveFileReplace", 24, files[0], true, true, files[0].type.split("/")[0]);
            if(res){
                setInfoBien({
                    ...infoBien,
                    src: URL.createObjectURL(files[0])
                });
            }
            setLoading(false);
        }
    }

    async function moveArticle(){
        setLoading(true);
        const params = {
            action: "update",
            table: "warehouseinventory",
            rows: {hall: values.hall, shell: values.shell, position: values.position},
            condition: { idInventory: infoBien.idInventory || "0" }
        }
        const res = await doRequest("receiver/receiver.php", params, true);
        setLoading(false);
    }

    return (
        <Widget className="widget-p-md">
            <div className="mt-2 d-flex flex-md-row flex-column justify-content-md-between">
                <h3>Existencias Almacén</h3>
                <Button onClick={()=>history.goBack()}>Regresar</Button>
            </div>
            <div className="mt-2">
                <Row>
                    <Col xs={12} md={3} className="d-flex align-items-start justify-content-center">
                        <div className="position-relative text-center">
                            <img
                                src={infoBien.src || "/images/no-image.png"}
                                className="img-thumbnail"
                                width={300}
                                height={300}
                                loading="lazy"
                            />
                            <div style={{position: "absolute", right: "13px", bottom:"8px"}}>
                                <i className="fa fa-upload text-default cursor-pointer" style={{fontSize: "30px"}} onClick={()=>refInputCam.current.click()}/>
                                <input type="file" name="photo" id="photo" accept="image/*" className="d-none" ref={refInputCam} onChange={onChange}/>
                            </div>
                        </div>
                    </Col>
                    <Col xs={12} md={9}>
                        <form onSubmit={handleSubmit} className="text-dark">
                            <blockquote className="blockquote mb-1" style={{fontSize: "25px"}}>
                                <p className="mb-0 text-dark">
                                    {infoBien.fullName}
                                </p>
                            </blockquote>
                            <p>
                                <strong className="pr-2">Existencia actual:</strong>
                                {infoBien.totalCount}
                            </p>
                            <p>
                                <strong className="pr-2">Comprometido:</strong>
                                {infoBien.reserved}
                            </p>
                            <Row className="mt-3">
                                <Col xs={12} md={2} className="mt-md-2">
                                    <strong>Pasillo: </strong>
                                </Col>
                                <Col xs={12} md={4}>
                                    <TextInput
                                        inputType="text"
                                        inputName="hall"
                                        onChangeMethod={onChange}
                                        onBlurMethod={handleBlur}
                                        value={values.hall}
                                        touched={touched.hall}
                                        errors={errors.hall}
                                        isRequired
                                        isPlaceholder="Pasillo"
                                        className="h-auto"
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12} md={2} className="mt-md-2">
                                    <strong>Anaquel: </strong>
                                </Col>
                                <Col xs={12} md={4}>
                                    <TextInput
                                        inputType="text"
                                        inputName="shell"
                                        onChangeMethod={onChange}
                                        onBlurMethod={handleBlur}
                                        value={values.shell}
                                        touched={touched.shell}
                                        errors={errors.shell}
                                        isRequired
                                        isPlaceholder="Anaquel"
                                        className="h-auto"
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12} md={2} className="mt-md-2">
                                    <strong>Posición: </strong>
                                </Col>
                                <Col xs={12} md={4}>
                                    <TextInput
                                        inputType="text"
                                        inputName="position"
                                        onChangeMethod={onChange}
                                        onBlurMethod={handleBlur}
                                        value={values.position}
                                        touched={touched.position}
                                        errors={errors.position}
                                        isRequired
                                        isPlaceholder="Posición"
                                        className="h-auto"
                                    />
                                </Col>
                            </Row>
                            <div className="d-flex flex-md-row flex-column">
                                <div xs={12}>
                                    <strong className="mr-3">
                                        Unidad de medida:
                                    </strong>
                                </div>
                                <div xs={12} md={9}>
                                    <p>{infoBien.nameUnit}</p>
                                </div>
                            </div>
                            <div className="mt-3 d-flex flex-md-row flex-column-reverse justify-content-md-around">
                                <Button
                                    color="success"
                                    type="submit"
                                    className="mt-2 mt-md-3"
                                >
                                    Actualizar
                                </Button>
                            </div>
                        </form>
                    </Col>
                </Row>
            </div>

            {
                infoBien.src && 
                <Datatable
                    headers={headers}
                    columns={columns}
                    columnDefs={columnDefs}
                    petition={getData}
                    control="back"
                    stateRefresh={[refresh, setRefresh]}
                    className="px-0 mt-4"
                    order={ {col: 4, opt: 'asc'} }
                />
            }
            <ModalConfirmation
                modalTitle="Cambiar posición"
                modal={modal}
                crear={moveArticle}
                setModal={setModal}
                values={values}
            >
                <div className="d-flex justify-content-center">
                    <h6>¿Está seguro de editar la posición del artículo?</h6>
                </div>
            </ModalConfirmation>

            <FullFormLoader show={loading} />
        </Widget>
    );
}

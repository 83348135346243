import { peticionesReceiver } from "../../../helpers/peticionesReceiver";
import Notification from "../../../components/Notification/Notification";
import { toast } from "react-toastify";
const API = peticionesReceiver();


export const agregarPerfil = async (perfil) => {
    const data = {
      action: "insert",
      table: "profiles",
      rows: perfil,
      validate: [
        ["nameProfile","keySystem"],
      ]
    };
    
    API.peticion(data).then((res) => { 
        if (res.status === 200 && res.data.code === "200") {
          toast(<Notification type={"agrega_exito"} backMessage={res.data.message} withIcon />);
          window.location.href ="#/template/perfiles";
        } else {
          console.log("xd");
          toast(<Notification type={"agrega_error"} backMessage={res.data.message} withIcon />);
        }
      })
      .catch((err) => {
        toast(<Notification type={"consultar_servidor_error"} withIcon />);
      });
  };

  export const editarPerfil = async (perfil) => {
    const data = {
      action: "update",
      table: "profiles",
      rows: perfil,
      condition: {
        keyProfile: perfil.keyProfile,
      },
      validate: [
        ["nameProfile","keySystem"],
      ]
    };
    API.peticion(data).then((res) => { 
        if (res.status === 200 && res.data.code === "200") {
          toast(<Notification type={"modifica_exito"} backMessage={res.data.message} withIcon />);
          window.location.href = "#/template/perfiles";
        } else {
          toast(<Notification type={"modifica_error"} backMessage={res.data.message} withIcon />);
        }
      })
      .catch((err) => {
        toast(<Notification type={"consultar_servidor_error"} withIcon />);
      });
  };
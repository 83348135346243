import * as XlsxPopulate from "xlsx-populate";
import { saveAs } from "file-saver";
import getLayout from "../../../../components/excel/layoutExcel";
import { fDate, fMoney } from "../../../../libs/tools/format";

const s = {
  tC: { horizontalAlignment: "center", verticalAlignment: "center" },
  tS: { horizontalAlignment: "left" },
  tE: { horizontalAlignment: "right", verticalAlignment: "center" },
  header: {
    horizontalAlignment: "center",
    bold: true,
    verticalAlignment: "center",
    fill: "730A29",
    fontColor: "FFFFFF",
    wrapText: true,
    shrinkToFit: true,
    border: true,
  },
  tt: {
    bold: true,
    horizontalAlignment: "center",
    verticalAlignment: "center",
    wrapText: true,
    shrinkToFit: true,
  },
  tB: { bold: true },
  tN: {
    border: true,
    wrapText: true,
    shrinkToFit: true,
    verticalAlignment: "center",
  },
  tNC: {
    horizontalAlignment: "center",
    verticalAlignment: "center",
    wrapText: true,
    shrinkToFit: true,
    border: true,
  },
  tNE: {
    horizontalAlignment: "right",
    verticalAlignment: "center",
    wrapText: true,
    shrinkToFit: true,
    border: true,
  },
  wt: { wrapText: true, verticalAlignment: "center" },
};
const headers = [
  "Movimiento",
  "Num. Empleado",
  "Unidad de trabajo",
  "RFC",
  "CURP",
  "Nombre Completo",
  "Fecha Ingreso",
  "Fecha Baja",
  "Nivel",
  "Percepciones",
  "Deducciones",
  "Periodos",
  "Historico Qns",
  "Historico Etyemp",
  "Seguro",
  "Activos a la Qna 2",
];
export async function excelForemex(data, cfg) {
  XlsxPopulate.fromBlankAsync().then(async (workbook) => {
    const hoja = workbook.sheet(0);
    let row = 1;

    for (let i = 1; i <= headers.length; i++) {
      hoja
        .column(i)
        .width(i === 3 ? 15 : i === 6 ? 50 : [4, 5].includes(i) ? 30 : 20);
      hoja
        .row(row)
        .cell(i)
        .value(headers[i - 1])
        .style(s.header);
    }

    data.forEach((d) => {
      row++;
      hoja.row(row).cell(1).value("").style(s.tN);
      hoja.row(row).cell(2).value(d.staffNumber).style(s.tNC);
      hoja.row(row).cell(3).value(d.idArea).style(s.tNC);
      hoja.row(row).cell(4).value(d.rfc).style(s.tN);
      hoja.row(row).cell(5).value(d.curp).style(s.tN);
      hoja.row(row).cell(6).value(d.nombre).style(s.tN);
      hoja.row(row).cell(7).value(fDate(d.startJobDate)).style(s.tNC);
      hoja.row(row).cell(8).value(fDate(d.bornDate)).style(s.tNC);
      hoja.row(row).cell(9).value(d.levelRango).style(s.tNC);
      hoja.row(row).cell(10).value(fMoney(d.percepcion)).style(s.tNE);
      hoja.row(row).cell(11).value(fMoney(d.deduccion)).style(s.tNE);
      hoja.row(row).cell(12).value(d.countPeriodo).style(s.tNE);
      hoja.row(row).cell(13).value(d.quincenas).style(s.tNE);
      hoja.row(row).cell(14).value(d.referenciaEmpleado).style(s.tNE);
      hoja.row(row).cell(15).value(d.seguroSeparacion).style(s.tNC);
      hoja.row(row).cell(16).value(d.activoQuincena).style(s.tN);
    });

    return workbook.outputAsync().then((res) => {
      saveAs(res, `CALCULO FOREMEX ${cfg.period}${cfg.emp}.xlsx`);
    });
  });
}

import * as Yup from "yup";
import { useFormik } from "formik";
import { Button, Col, Collapse, Row } from "reactstrap";
import React, { useState, useEffect } from "react";
import Widget from "../../../components/Widget/Widget";
import s from "../reportes/filtros/InputClear.module.scss";
import DatePicker from "react-datepicker";
import ModalTableVer from "../../../components/Modal/ModalTableVer";
import CurrencyInput from "react-currency-input-field";
import { CancelOutlined } from "@material-ui/icons";
import { SelectTypeHeadSingle } from "../../../components/GenericInputsFormik/SelectTypeHeadSingle";
import { TextInput } from "../../../components/GenericInputsFormik/TextInput";
import Notification from "../../../components/Notification/Notification";
import { peticionEncript } from "../../../helpers/peticionesEncripted";
import axios from "axios";
import { toast } from "react-toastify";
import ModalConfirmation from "../../../components/Modal/ModalConfirmation";

/* MODAL TABLA */
const filtroModalTabla = [true, true, true, true, true];

/* FORM BASE */
const defaultForm = {
  idPayslipstamp: "",
  idStaff: "",
  idUnder: "",
  period: "",
  year: new Date(),
  amount: "",
  /* keyStat: "", */
};

/* VALIDACIONES FORM */
const FormSchema = Yup.object().shape({
  idUnder: Yup.string().required("Seleccione un concepto"),
  period: Yup.number()
    .required("Ingrese un periodo")
    .min(1, "Periodo invalido")
    .max(24, "Periodo debe ser menor a 25"),
  year: Yup.string().required("Seleccione un año"),
  amount: Yup.number()
    .required("Ingrese una cantidad")
    .min(1, "Cantidad invalida"),
/*   keyStat: Yup.string().required("Seleccione el estado del pago"), */
});

const date = new Date().getFullYear();

export const FormEditarPago = (props) => {
  const [modalConfirm, setModalConfirm] = useState(false);

  const { idStaff, setcollapseForm, data, unders, status, updateTable } = props;

  const {
    handleSubmit,
    values,
    handleBlur,
    errors,
    touched,
    setFieldValue,
    resetForm,
  } = useFormik({
    initialValues: defaultForm,
    onSubmit: (values) => {setModalConfirm(true)},
    validationSchema: FormSchema,
    enableReinitialize: true,
    onReset: () => {},
  });

  useEffect(() => {
    if (data) {
      setFieldValue("idPayslipstamp", data.idPayslipstamp);
      setFieldValue("idStaff", data.idStaff);
      setFieldValue("idUnder", data.idUnder);
      setFieldValue("period", data.period);
      setFieldValue("year", data.year);
      setFieldValue("amount", data.amount);
      /* setFieldValue("keyStat", data.keyStat); */
    }
  }, []);

  const onChange = (e) => {
    setFieldValue(e.target.name, e.target.value);
  };

  /* Limpiar y cerrar el formulario */
  function limpia() {
    resetForm();
    setcollapseForm(false);
  }

  /* Guardar el registro creado/eliminado */
  async function editInfo() {
    let rows = {...values, idStaff: idStaff, year: new Date(values.year).getFullYear()};
    /* const params = {
      action: "savePayslip",
      rows: rows,
    }; */
    const params = {
      action: rows.idPayslipstamp ? "update" : "insert",
      table: "payslipsstamps",
      rows: rows,
      /* validate: [
        ["acronim", "nameCountry", "phoneKey"],
        ["acronim"],
        ["nameCountry"],
        ["phoneKey"]
    ] */
    };

    if(rows.idPayslipstamp  ){
      params.condition = {
        idPayslipstamp: rows.idPayslipstamp,
      }
    }

    const finalData = peticionEncript(params);
    await axios
      .post(
        `${process.env.REACT_APP_API}receiver/receiver.php`,
        finalData
      )
      .then((res) => {
        if (res.status === 200 && res.data.status === "success") {
          toast(
            <Notification
              type={"modifica_exito"}
              backMessage={res.data.message}
              withIcon
            />
          );
          setcollapseForm(false);
          updateTable();
        } else {
          toast(
            <Notification
              type={"modifica_error"}
              backMessage={res.data.message}
              withIcon
            />,
            { closeButton: false }
          );
        }
        resetForm();
      })
      .catch((err) => {
        toast(<Notification type={"consultar_servidor_error"} withIcon />, {
          closeButton: false,
        });
      });
  }


  return (
    <div className="mt-2">
      <ModalConfirmation
        modalTitle="Guardar registro"
        modal={modalConfirm}
        setModal={setModalConfirm}
        editar={editInfo}
        isEdit={true}
        values={values}
      >
      <div className="d-flex justify-content-center">
        <h6>¿Está seguro de guardar el registro?</h6>
      </div>
      </ModalConfirmation>
      <form onSubmit={handleSubmit}>
        <Row className="d-flex justify-content-around">
          <Col xs={12} md={12} lg={6}>
            <SelectTypeHeadSingle
              label="Concepto"
              isRequired={true}
              inputName="idUnder"
              optionsArray={unders}
              defaultOption="Seleccione un concepto"
              onChangeMethod={onChange}
              onBlur={handleBlur}
              isDisabled={false}
              value={values.idUnder}
              touched={touched.idUnder}
              errors={errors.idUnder}
              optionValue="idUnder"
              optionName="name"
            />
          </Col>
          {/* <Col xs={12} md={12} lg={6}>
            <SelectTypeHeadSingle
              label="Estatus"
              isRequired={true}
              inputName="keyStat"
              optionsArray={status}
              defaultOption="Seleccione un estatus"
              onChangeMethod={onChange}
              onBlur={handleBlur}
              isDisabled={false}
              value={values.keyStat}
              touched={touched.keyStat}
              errors={errors.keyStat}
              optionValue="keyStat"
              optionName="nameStat"
            />
          </Col> */}
        </Row>
        <Row>
          <Col xs={12} md={12} lg={4}>
            <div className="form-group">
              <label htmlFor="period">
                Periodo <span className="text-danger">*</span>
              </label>
              <CurrencyInput
                id="period"
                name="period"
                className={
                  "form-control" +
                  (errors.period && touched.period ? " is-invalid" : "")
                }
                allowDecimals={false}
                allowNegativeValue={false}
                onValueChange={(v, n) =>
                  onChange({ target: { name: n, value: v } })
                }
                onBlur={handleBlur}
                maxLength={2}
                value={values.period}
                min={1}
                max={24}
              />
              {touched.period && errors.period && (
                <span className="text-danger">{errors.period}</span>
              )}
            </div>
          </Col>
          <Col xs={12} md={12} lg={4}>
            <div className="form-group">
              <label htmlFor={values.year}>
                {" "}
                Año <span className="text-danger"> * </span>{" "}
              </label>
              <DatePicker
                minDate={new Date(`${date}`)}
                selected={values.year}
                onChange={(date) =>
                  onChange({ target: { name: "year", value: date } })
                }
                onBlur={handleBlur}
                showYearPicker
                dateFormat="yyyy"
                yearItemNumber={6}
                className={
                  "form-control" +
                  (touched.year && errors.year ? " is-invalid" : "")
                }
              />
              {touched.year && errors.year && (
                <span style={{ color: "red" }}>{errors.year}</span>
              )}
            </div>
          </Col>
          <Col xs={12} md={12} lg={4}>
            <div className="form-group">
              <label htmlFor="amount">
                Importe <span className="text-danger">*</span>
              </label>
              <CurrencyInput
                id="amount"
                name="amount"
                className={
                  "form-control" +
                  (errors.amount && touched.amount ? " is-invalid" : "")
                }
                allowNegativeValue={false}
                onValueChange={(v, n) =>
                  onChange({ target: { name: n, value: v } })
                }
                onBlur={handleBlur}
                value={values.amount}
                min={1}
                prefix="$"
                groupSeparator=","
                decimalSeparator="."
              />
              {touched.amount && errors.amount && (
                <span className="text-danger">{errors.amount}</span>
              )}
            </div>
          </Col>
        </Row>

        <div className="d-flex justify-content-between mt-5">
          <Button color="danger" type="reset" onClick={() => limpia()}>
            Cancelar
          </Button>
          <Button color="success" type="submit">
            Guardar
          </Button>
        </div>
      </form>
    </div>
  );
};
export default FormEditarPago;

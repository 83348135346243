import { useEffect, useState } from "react";
import Datatable from "../../../../../../components/Datatable/Datatable";
import { fMoney } from "../../../../../../libs/tools/format";
import { doRequestSaveRes } from "../../../../../../helpers/requests";

export default function Report51({ params, cfg, loadingPage, setLoadingPage }) {
  const [dataTab, setDataTab] = useState([]);
  const [show, setShow] = useState(false);

  const columns = [
    { data: "name" },
    {
      render: function (row) {
        return fMoney(row.amount);
      },
    },
  ];

  const headers = ["TOTAL", "IMPORTE"];

  const columnDefs = [
    {
      targets: [1],
      className: "text-left",
    },
    {
      targets: [1],
      className: "text-right",
    },
  ];

  useEffect(() => {
    getInfo();
  }, []);

  const getInfo = async () => {
    setLoadingPage(true);
    const res = await doRequestSaveRes(
      "app/facades/reports/reportsBudgetsF.php",
      params,
      false
    );
    if (res.code === "200") {
      setDataTab([
        { name: "Gobierno", amount: res.montoTotal },
        { name: "Desarrollo Social", amount: "" },
        { name: "Desarrollo Económico", amount: "" },
        { name: "Otras no clasificadas en funciones anteriores", amount: "" },
      ]);
      setShow(true);
    }
    setLoadingPage(false);
  };

  return (
    <div className="mt-4 pt-4 border-top">
      <h2 className="text-secondary text-center">
        Iniciativa De Ley De Ingresos Para El Ejercicio Fiscal {cfg.year}
      </h2>
      <h3 className="text-center">Clasificador Funcional Del Gasto</h3>

      <div className="text-dark mx-3">
        <p className="border rounded-sm my-1 p-2 bg-middle-gray ">Fiscalia General de Justicia del Estado de México</p>
        <p className="border rounded-sm my-1 p-2 bg-middle-gray">Presupuesto de Egresos para el Ejercicio Fiscal {cfg.year}</p>
        <p className="border rounded-sm my-1 p-2 bg-middle-gray">Clasificador Funcional del Gasto</p>
      </div>
      

      {show && (
        <Datatable
          columns={columns}
          headers={headers}
          columnDefs={columnDefs}
          ordering={false}
          searching={false}
          paging={false}
          data={dataTab}
          info={false}
        />
      )}
    </div>
  );
}

import { Document, Page, Text, View, StyleSheet } from "@react-pdf/renderer";
import { styles } from "../../../../components/Pdf/pdfGeneral/EstiloPdf";
import { sumValues } from "../../../../libs/tools/arrays";
import HeaderReport from "../../../../components/Pdf/Header";

    /**
     * Estilo que se repite en todas los headers de la tabla
     */
    const styleHeadForAll = {
        borderStyle: "solid",
        borderWidth: 1,
        backgroundColor: "#646464",
        color: 'white',
        textAlign: 'center',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: 10
    }
    
    const styleCellForAll = {
        borderStyle: "solid",
        borderWidth: 1,
        borderTopWidth: 0,
        fontSize: 11
    }
    
    /**
     * Estilos del pdf
     */
const s = StyleSheet.create({
    subTitle: { //SUBTITULOS
        width: "100%",
        textAlign: 'center',
        marginBottom: 10,
        marginLeft: 10,
        fontSize: 14
    },
    f6: {
        fontSize: 6
    },
    f5: {
        fontSize: 5
    },
    f8: {
        fontSize: 8
    },
    f10: {
        fontSize: 10
    },
    thIn: {
        height: 20,
        ...styleHeadForAll
    },
    th: {
        height: 20,
        ...styleHeadForAll,
        borderLeftWidth: 0
    },
    w10: {
        width: "10%"
    },
    w20: {
        width: "20%"
    },
    w18: {
        width: "18%"
    },
    w25: {
        width: "25%"
    },
    w30: {
        width: "30%"
    },
    w33: {
        width: "33%"
    },
    w34: {
        width: "34%"
    },
    w40: {
        width: "40%"
    },
    w50: {
        width: "50%"
    },
    w60: {
        width: "60%"
    },
    w70: {
        width: "70%"
    },
    w90: {
        width: "90%"
    },
    w100: {
        width: "100%"
    },
    mt2: {
        marginTop: 40
    },
    px2: {
        paddingHorizontal: 10
    },
    px1: {
        paddingHorizontal: 5
    },
    bt0: {
        borderTopWidth: 0
    },
    center: {
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
    },
    textBold: {
        fontFamily: 'Helvetica-Bold'
    },
    textRight: {
        textAlign: "right"
    },
    tdIn: { //Cell CC
        ...styleCellForAll
    },
    td: { //Cell Concepto
        ...styleCellForAll,
        borderLeftWidth: 0
    },
    td0: { //Cell Concepto
        ...styleCellForAll,
        borderLeftWidth: 0,
        borderRightWidthWidth: 0
    },
    red: {
        backgroundColor: "red",
    },
    green: {
        backgroundColor: "green",
    },
    gray: {
        backgroundColor: "gray",
    }
})


const formatter = new Intl.NumberFormat("es-MX", {
    style: "currency",
    currency: "MXN",
});



export default function exportGeneral(data, model, title, fileNo) {
    const ori = model.orientation === "h" ? "landscape" : "portrait";

    const sum = model.sum;
    const subTitle = model.subTitle

    const hl = model.headers.length;

    const totales = sum.map(key => { return { value: sumValues(data, key), format: true } });
    if (totales.length > 0) {
        totales.unshift({value: "TOTAL", format: false});
        while (totales.length < hl) {
            totales.unshift({value: "", format: false});
        }
    }

    function getWidth(width) {
        switch (width) {
            case 10:
                return s.w10;
            case 20:
                return s.w20;
            case 30:
                return s.w30;
            case 40:
                return s.w40;
            case 50:
                return s.w50;
            case 60:
                return s.w60;
            case 70:
                return s.w70;
            case 80:
                return s.w80;
            case 90:
                return s.w90;
            default:
                return s.w100
        }
    }
    
    return {
        report: (
            <>
                <Document>
                    <Page style={styles.body} orientation={ori}>
                        <HeaderReport/>
                        <View>
                            <Text style={s.subTitle}>{title}</Text>
                            <Text style={s.subTitle}>{subTitle}</Text>
                        </View>
                        <View style={styles.table}>
                            <View style={styles.tableRow}>
                                {model.headers.map((header, index) => (
                                    <View style={[index === 0 ? s.thIn : s.th, getWidth(model.sizes[index])]}>
                                        <Text>{header}</Text>
                                    </View>
                                ))}
                            </View>
                            {data.map(row => (
                                <View style={styles.tableRow}>
                                    {model.content.map((value, index) => (
                                        <View style={[index === 0 ? s.tdIn : s.td, getWidth(model.sizes[index])]}>
                                            <Text style={value.format ? [s.textRight, s.px1] : ""}>{!value.format ? row[value.value] : formatter.format(row[value.value])}</Text>
                                        </View>
                                    ))}
                                </View>
                            ))}
                            <View style={styles.tableRow} fixed>
                                {totales.map((value, index) => (
                                    <View style={[index === 0 ? s.tdIn : value!== "" ? s.td : s.td0, getWidth(model.sizes[index])]}>
                                        <Text style={[s.textRight, s.px1, s.textBold]}>{!value.format ? value.value : formatter.format(value.value)}</Text>
                                    </View>
                                ))}
                            </View>
                        </View>
                        
                    </Page>
                </Document>
            </>
        )
    };
}
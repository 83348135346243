import React, { useState, useEffect } from "react";
import ModalComponentEliminar from "../../components/Modal/ModalDelete";
import Loader from "../../components/Loader/Loader";
import axios from "axios";
import {
  Col,
  Row,
  Table,
  Input,
  Pagination,
  PaginationItem,
  PaginationLink,
} from "reactstrap";
import s from "../../pages/tables/Tables.module.scss";
import sc from "./TableComponentFiltro.module.scss";
import { toast } from "react-toastify";
import Notification from "../../components/Notification/Notification";
import { peticionEncript } from "../../helpers/peticionesEncripted";
import { formatCell } from "./cellsFormat";

/**
 * @param {Object} props
 * @param {string} props.titulo - Titulo de la tabla
 * @param {Array<string>} props.cabecerasTabla - Arreglo con las cabeceras de la tabla
 * @param {Array<boolean>} props.filtro - Arreglo con filtro para mostrar datos
 * @param {Function} props.editar - Opcional - Funcion para ejecutar en el boton editar
 * @param {Function} props.eliminar - Opcional - Funcion para ejecutar en el boton eliminar
 * @param {Object} props.parametros - Objeto con los parametros para realizar la consulta
 * @param {Function} props.limpia - Opcional - Funcion para limpiar el formulario
 * @param {Function} props.reset - Opcional - Funcion para reiniciar el formulario
 * @param {string} props.beforeDelete - Opcional - nombre de una tabla para eliminar un registro
 * @param {number} props.forceDelete - Opcional - 1 para forzar la eliminación de un registro
 * @param {Object} props.permissions - Objeto con los permisos del usuario sobre el modulo
 * @param {Object} props.setCount - Establece el numero de resultados de getData
 * @param {Object} props.enableContador - Habilita o deshabilita contador del primer index
 */
export const TableComponentOnePage = ({
  titulo,
  cabecerasTabla,
  filtro,
  editar,
  eliminar,
  parametros,
  limpia,
  reset,
  beforeDelete,
  forceDelete,
  permissions,
  setCount = null,
  enableContador = true,
  orderers
}) => {
  const colsBdNames = parametros.rows.split(","); // Columnas del back
  const nameTable = parametros.table.split(" ");
  const nameColumn = colsBdNames[0].split(".");
  const [loading, setLoading] = useState(false);
  const [tableContent, setTableContent] = useState([]); // Información mostrada en la tabla
  const [pageSize, setPageSize] = useState(5); // Tamaño de las paginas
  const [totalRegistros, setTotalRegistros] = useState(0); // Total de registros en BD
  const [pagesCount, setPagesCount] = useState(0); // Cantidad total de paginas
  const [sortCol, setSortCol] = useState({
    //Parametros para aplicar filtros a la busqueda
    direction: "",
    column: "",
    page: "",
    currentPage: 0,
    search: "",
    records: 5,
  });
  const [pagination, setPagination] = useState([]);

  const settableContentPage = (e, index) => {
    e.preventDefault();
    setSortCol((prevState) => ({
      ...prevState,
      page: pageSize * index,
      currentPage: index,
    }));
  };

  const mapea = (element, indexCol, indexRow) => {
    return formatCell(element, indexCol, indexRow, filtro[indexCol], sortCol, undefined, enableContador);
  };

  const sortTable = (cabecera, indexHeader) => {
    if (
      !["Editar", "Eliminar", "Id", "Ver", "Seleccionar", "Modificar"].includes(
        cabecera
      )
    ) {
      if (sortCol.column !== indexHeader) {
        setSortCol((ps) => ({ ...ps, column: indexHeader, direction: "asc" }));
      } else {
        setSortCol((ps) => ({
          ...ps,
          direction:
            sortCol.direction === "asc"
            ? "desc"
            : sortCol.direction === "desc"
            ? ""
            : "asc",
        }));
      }
    }
  };

  const getData = async (orderOb,isDelete) => {
    //LA PRIMERA VEZ QUE ENTRAMOS VIENE UN ORDEROB = EMPTY Y EN EL PARAMS = ORDER = "", PAGE = 0 Y RECORDS = 5
    //CUANDO SELECCIONO EL HEADER VIENE ORDEROB = COLUMN = 1 Y DIRECTION = ASC Y EN EL PARAMS = ORDER = "ASC", PAGE = 0 Y RECORDS = 5
    //DESPUES DE ESO SELECCIONO EL PAGINADO Y MUESTRA ORDEROB = COLUMN = "", DIRECTION = "" Y PAGE = 5 Y EN EL PARAMS = ORDER = "", PAGE = 5 Y RECORDS = 5
    //SOLO HAY QUE VERIFICAR EL ESTADO DEL HOOK Y QUE NO CAMBIE LOS PARAMS
    if (!permissions.CON) return false;
    var params = "";
    if (parametros.orderBy !== undefined && parametros.orderBy !== null) {
      params = {
        action: parametros.action,
        table: parametros.table,
        rows: parametros.rows,
        conditions: parametros.conditions,
        page: parametros.page,
        records: parametros.records,
        search: parametros.search,
        order: parametros.orderBy,
      };
    } else {
      params = parametros;
    }
    if (sortCol.column !== "" && sortCol.direction !== "") {
      //AQUI QUIERE DECIR QUE TRAE UN ORDENAMIENTO POR HEADER
      params.order = orderOb.column + " " + orderOb.direction;
    } else {
      params.order = parametros.order;
    }
    if (sortCol.page !== "") {
      //QUIERE DECIR QUE CAMBIAMOS DE PAGINA
      params.page = sortCol.page;
    } else {
      params.page = 0;
    }
    if (sortCol.search !== "") {
      params.search = sortCol.search;
    } else {
      params.search = "";
    }
    if (orderOb) {
    } else {
      if (sortCol.column !== "" && sortCol.direction !== "") {
        //AQUI TRAE UN ORDENAMIENTO POR HEADER
        params.order = orderOb.column + " " + orderOb.direction;
      } else {
        params.order = parametros.order;
      }
      if (sortCol.page !== "") {
        //CAMBIAMOS DE PAGINA
        params.page = sortCol.page;
      } else {
        params.page = 0;
      }
      if (sortCol.search !== "") {
        params.search = sortCol.search;
      } else {
        params.search = "";
      }
    }

    if(isDelete && tableContent.length === 1){
      params = parametros
      setSortCol( {
        //Parametros para aplicar filtros a la busqueda
        direction: "",
        column: "",
        page: "",
        currentPage: 0,
        search: "",
        records: 5,
      })
    }  
    params.records = sortCol.records;
    setLoading(true); 
    const finalData = peticionEncript(params);
    await axios
      .post(`${process.env.REACT_APP_API}receiver/receiver.php`, finalData)
      .then((res) => {
        let data = [];
        if (res.status === 200 && res.data.code === "200") {
          const ttrec = res.data.totalRecords ? +res.data.totalRecords : 0;
          setTotalRegistros(ttrec);
          setPagesCount(Math.ceil(ttrec / pageSize));
          res.data.data.forEach((el) => {
            data.push(Object.values(el));
          });
          if (setCount!==null) {
            setCount(data.length)
          }
        }
        setTableContent(data);
        setLoading(false);
      })
      .catch((err) => {
        toast(<Notification type={"consultar_servidor_error"} withIcon />, {
          closeButton: false,
        });
        setLoading(false);
      });
  };

  useEffect(() => {
    getData(sortCol);
  }, [sortCol, parametros]);

  const handleOnChange = (e) => {
    setSortCol((prevState) => ({
      ...prevState,
      search: e.target.value,
      currentPage: 0,
      page: "",
    }));
  };

  const handleOnChangeSelect = (e) => {
    setPageSize(e.target.value);
    setSortCol((prevState) => ({
      ...prevState,
      records: e.target.value,
      currentPage: 0,
      page: "",
    }));
  };

  const showHeander = (cab, id) => {
    if (["editar", "ajuste"].includes(cab.toLowerCase()) && !permissions.UPD)
      return null;
    if (cab.toLowerCase() === "eliminar" && !permissions.DEL) return null;
    const colOrd = (orderers && !!orderers[id]) ? orderers[id] : (+id + 1);
    return (
      <th key={"H" + cab} className="text-center" onClick={() => sortTable(cab, colOrd)}>
        {cab === "Id" ? "#" : cab}
        <i
          className={
            "align-top eva" +
            (sortCol.column === colOrd
              ? (sortCol.direction === "asc"
                ? " eva-arrow-ios-upward-outline"
                : (sortCol.direction === "desc"
                ? " eva-arrow-ios-downward-outline"
                : ""))
              : "")
          }
        ></i>
      </th>
    );
  };

  /*Paginacion @luixeduard */
  useEffect(() => {
    const pages = Math.ceil(totalRegistros / pageSize);
    const maxPages = Math.min(pages, 5);
    const startPage = Math.max(Math.min(pages - maxPages, sortCol.currentPage - Math.floor(maxPages / 2)), 0);
    const endPage = Math.min(startPage + maxPages, pages);
    const tempPages = Array.from({ length: endPage - startPage }, (_, i) => i + startPage);

    setPagination(tempPages);
}, [tableContent]);

  return (
    <div>
      <Row className="mb-4">
        <Col>
          {titulo && (
            <div className={s.tableTitle}>
              <h1 className="text-secondary text-center">{titulo} </h1>
            </div>
          )}
          {permissions.CON && (
            <div className="d-flex justify-content-between">
              <Input
                value={sortCol.search}
                name="search"
                onChange={handleOnChange}
                className="col-12 col-md-6"
                placeholder="Buscar..."
              />
            </div>
          )}
          <div className="widget-table-overflow mt-3">
            <Table className={`table-striped table-borderless table-hover ${s.statesTable}`} responsive>
              {permissions.CON && (
                <thead className="border-bottom border-secondary">
                  <tr className={sc.userSelectNone}>
                    {cabecerasTabla.map((cabecera, indexHeaders) =>
                      showHeander(cabecera, indexHeaders)
                    )}
                  </tr>
                </thead>
              )}
              <tbody>
                {!permissions.CON ? (
                  <tr key={"nothing"}>
                    <td colSpan={cabecerasTabla.length} className="text-center">
                      No tiene permitido ver los registros de esta seccion
                    </td>
                  </tr>
                ) : loading ? (
                  <tr key={"loading"}>
                    <td colSpan={cabecerasTabla.length}>
                      <Loader />
                    </td>
                  </tr>
                ) : tableContent.length === 0 ? (
                  <tr key={"nothing"}>
                    <td colSpan={cabecerasTabla.length} className="text-center">
                      No se encontraron registros
                    </td>
                  </tr>
                ) : (
                  tableContent.map((registro, index) => (
                    <tr key={"R" + index}>
                      {registro.map((elemento, indexCol) => {
                        if (filtro !== null) {
                          return mapea(elemento, indexCol, index);
                        } else {
                          return (
                            <td className="text-center" key={"C" + indexCol}>
                              {elemento}
                            </td>
                          );
                        }
                      })}

                      {permissions.UPD && editar !== false && (
                        <td>
                          <div className="d-flex justify-content-center">
                            <i
                              className={
                                sc.handCursor + " fa fa-pencil text-warning"
                              }
                              onClick={() => editar(registro)}
                              style={{ cursor: "pointer" }}
                            />
                          </div>
                        </td>
                      )}
                      {permissions.DEL && eliminar !== false && (
                        <td>
                          <div className="d-flex justify-content-center">
                            <ModalComponentEliminar
                              modalTitle="Eliminar"
                              id={registro[0]}
                              table={nameTable}
                              nameColumn={nameColumn}
                              getData={getData}
                              limpia={limpia}
                              elimina={eliminar}
                              reset={reset}
                              beforeDelete={
                                beforeDelete ? beforeDelete : undefined
                              }
                              forceDelete={forceDelete ? 1 : 0}
                              sortCol={sortCol}
                            >
                              <h6>
                                ¿Seguro de eliminar el registro "{registro[1]}"?
                              </h6>
                              No podrá recuperar la información después de ser
                              eliminada
                            </ModalComponentEliminar>
                          </div>
                        </td>
                      )}
                    </tr>
                  ))
                )}
              </tbody>
            </Table>
            {permissions.CON && (
              <div className="row">
                <div className="col-12 col-sm-6 d-flex flex-row mt-3">
                  <div className="mr-3">
                    Mostrando{" "}
                    <span className="font-weight-bold">
                      {sortCol.currentPage * pageSize +
                        (tableContent.length === 0 ? 0 : 1)}
                    </span>{" "}
                    a{" "}
                    <span className="font-weight-bold">
                      {pageSize * (sortCol.currentPage + 1) <= totalRegistros
                        ? pageSize * (sortCol.currentPage + 1)
                        : totalRegistros}{" "}
                    </span>
                    de <span className="font-weight-bold">{totalRegistros}</span> registros
                  </div>
                  <select
                    className="form-control form-control-sm"
                    style={{ width: "78px" }}
                    value={sortCol.records}
                    onChange={handleOnChangeSelect}
                  >
                    <option value={5} key={5}>
                      5
                    </option>
                    <option value={10} key={10}>
                      10
                    </option>
                    <option value={15} key={15}>
                      15
                    </option>
                    <option value={20} key={20}>
                      20
                    </option>
                    <option value={100} key={100}>
                      100
                    </option>
                  </select>
                </div>
                <div className="col-12 col-sm-6 d-flex justify-content-center justify-content-md-end">
                  <Pagination className="pagination-borderless d-flex mt-3">
                    <PaginationItem className="m-0">
                      <PaginationLink 
                        disabled={sortCol.currentPage <= 0}
                        onClick={(e) => settableContentPage(e, sortCol.currentPage - 1)}
                        previous 
                        className={sortCol.currentPage <= 0 ? "text-muted" : ""}
                      />
                    </PaginationItem>
                    {pagination.map((page, i) => (
                      <PaginationItem key={i} disabled={page === sortCol.currentPage} className="m-1" >
                          <PaginationLink
                              onClick={(e) => settableContentPage(e, page)}
                              className={page === sortCol.currentPage ? "text-bold text-white bg-primary":"text-secondary"}
                          >
                              {page + 1}
                          </PaginationLink>
                      </PaginationItem>
                    ))}
                    <PaginationItem className="m-0">
                      <PaginationLink 
                        disabled={sortCol.currentPage + 1 >= pagesCount}
                        onClick={(e) => settableContentPage(e, sortCol.currentPage + 1)}
                        next 
                        className={sortCol.currentPage + 1 >= pagesCount ? "text-muted" : ""}
                      />
                    </PaginationItem>
                  </Pagination>
                </div>
              </div>
            )}
          </div>
        </Col>
      </Row>
    </div>
  );
};
export default TableComponentOnePage;

/**
 * 
 * @param params setters from Format
 */
export function setConfigR30(params) {
    const { setHeaders, setPdfConfig, setExcelConfig, setHtmlConfig, setData, filter } = params;
    const headers = [
        "CLAVE CONCEPTO",
        "CONCEPTO",
        "OCURRENCIAS",
        "IMPORTE"
    ]
    setHeaders(headers)
    const tempData = {
        action: "report30",
        search: "",
        order: "",
        period: filter.period,
        year: (new Date(filter.year)).getFullYear()
    }
    tempData.idStateFund = filter.idStateFund;
    tempData.stateFund = filter.stateFund;
    setData(tempData);
    const content = [
        { header: "CLAVE CONCEPTO", value: "under" },
        { header: "CONCEPTO", value: "nameUnder" },
        { header: "OCURRENCIAS", value: "ocurrencias" },
        { header: "IMPORTE", value: "importe" },
    ];
    setHtmlConfig({
        headers: ["TIPO CONCEPTO", ...headers],
        id: { id: "keyTypeUnder" },
        content: [content]
    })
    setPdfConfig({
        descr: tempData,
        headers,
        content
    });
    setExcelConfig({
        headers,
        content
    });
}
import InputWithModal from "../../../components/GenericInputsFormik/InputWithModal";
import * as Yup from "yup"
import { useFormik } from "formik";
import { Button } from "reactstrap";
import { useState } from "react";
import { peticionesReceiver } from "../../../helpers/peticionesReceiver";
import { toast } from "react-toastify";
import Notification from "../../../components/Notification/Notification";
import FullFormLoader from "../../../components/Loader/FullFormLoader";

export default function GetUserByList({ next, before, setUser }) {
    const API = peticionesReceiver()

    const [loading, setLoading] = useState(false);

    const defaultForm = {
        idStaff: "",
        idJobStaff: "",
        name: "",
        pName: "",
        mName: "",
        value: "",
        descriptor: "",
        src: "",
    }
    const headers = ["#", "No. Empleado", "Nombre", "Apellido paterno", "Apellido materno"];

    const params = {
        action: "datatable",
        table: "staff s join jobstaff j on (s.idStaff = j.idStaff and j.valid = 1) LEFT JOIN bookcase BC ON (BC.enabled = 1 AND BC.keyFileType = 1 and BC.reference = s.idStaff) LEFT JOIN files FILES ON (FILES.enabled = 1 AND FILES.idBook = BC.idBook)",
        rows: "s.idStaff,s.staffNumber,j.idJobStaff,s.name,s.pName,s.mName,FILES.src",
        conditions: "s.enabled = 1 and s.descriptor IS NOT NULL",
        page: 0,
        records: 5,
        search: "",
        order: "",
    };

    const FormSchema = Yup.object().shape({
        value: Yup.string().required("Seleccione un empleado de la lista"),
    })

    const { handleSubmit, values, handleBlur, errors, touched, setFieldValue, resetForm } = useFormik({
        initialValues: defaultForm,
        onSubmit: (values) => {
            setUser({
                ...values
            })
            next();
        },
        validationSchema: FormSchema,
        enableReinitialize: true,
    });

    const actionSelect = (el) => {
        setFieldValue(["value"], `${el[1]} - ${el[3]} ${el[4]} ${el[5]}`);
        setFieldValue(["idStaff"], el[0]);
        setFieldValue(["idJobStaff"], el[2]);
        setFieldValue(["name"], el[3]);
        setFieldValue(["pName"], el[4]);
        setFieldValue(["mName"], el[5]);
        setFieldValue(["src"], el[6]);
        setLoading(true);
        getDescriptor(el[0])
    }

    async function getDescriptor(idStaff) {
        try {
            const params = {
                action: "multiselect",
                table: "staff",
                rows: "descriptor",
                conditions: `enabled = 1 and idStaff=${idStaff}`
            }
            const res = await API.peticion(params);
            if (res.status === 200 && res.data.code === "200") {
                const descriptor = res.data.data[0].descriptor.split("?");
                const mapped = descriptor.map(value => Number(value))
                setFieldValue(["descriptor"], new Float32Array(mapped));
                setLoading(false);
            } else {
                toast(<Notification type={"consultar_error"} backMessage={"Sin registros"} withIcon />);
                setLoading(false);
                setFieldValue(["descriptor"], "");
            }
        } catch (err) {
            toast(<Notification type={"consultar_servidor_error"} withIcon />);
            setFieldValue(["descriptor"], "");
            setLoading(false);
        }
    }

    const clear = (el) => {
        resetForm()
    }

    const filtroProvider = [true, true, false, true, true, true, false, false];

    return (
        <>
            <p>A continuación seleccione el empleado a registrar asistencia</p>
            <p className="font-weight-bold">Nota: Solo apareceran los usuarios que tengan su fotografia en el expediente</p>
            <form onSubmit={handleSubmit}>
                <InputWithModal
                    cabeceras={headers}
                    error={errors.value}
                    filtro={filtroProvider}
                    handleBlur={handleBlur}
                    actionSelect={actionSelect}
                    onClear={clear}
                    parametros={params}
                    touched={touched.value}
                    value={values.value}
                    label="Empleado"
                    name={"employee"}
                    title={"Seleccionar empleado"}
                />
                <div className="text-center">
                    <Button color="danger" className="mr-1 mr-md-3" onClick={before}>Regresar</Button>
                    <Button color="success" className="ml-1 ml-md-3" type="submit" disabled={values.descriptor === "" }>Continuar</Button>
                </div>
            </form>
            <FullFormLoader message={"Cargando información"} setShow={setLoading} show={loading} />
        </>
    )
}
import {
  Document,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
import HeaderReport from "../../../../components/Pdf/Header";

const fmt = new Intl.NumberFormat("es-MX", {
  maximumFractionDigits: 2,
  minimumFractionDigits: 2,
});
const fmtMny = new Intl.NumberFormat("es-MX", {
  style: "currency",
  currency: "MXN",
});

const s = StyleSheet.create({
  body: {
    paddingVertical: "30px",
    paddingHorizontal: "20px",
  },

  title: {
    fontSize: "12px",
    textAlign: "center",
    fontFamily: "Helvetica-Bold",
  },

  header: {
    backgroundColor: "#730A29",
    textAlign: "center",
    color: "#FFFFFF",
  },

  mCell: { padding: "2px" },
  mxCell: { paddingHorizontal: "2px" },

  vCenter: { alignItems: "center", justifyContent: "center" },
  vCenterL: { alignItems: "left", justifyContent: "center" },
  vCenterR: { alignItems: "right", justifyContent: "center" },
  vCenterBtw: { alignItems: "stretch", justifyContent: "center" },

  dflexBtw: { display: "flex", justifyContent: "space-between" },
  dflexEnd: { display: "flex", justifyContent: "flex-end" },

  alignE: { alignContent: "flex-end" },

  row: { flexDirection: "row" },
  flexWrap: { display: "flex", flexWrap: "wrap", flexDirection: "row" },

  w5: { width: "5%" },
  w10: { width: "10%" },
  w15: { width: "15%" },
  w20: { width: "20%" },
  w25: { width: "25%" },
  w30: { width: "30%" },
  w35: { width: "35%" },
  w40: { width: "40%" },
  w45: { width: "45%" },
  w50: { width: "50%" },
  w55: { width: "55%" },
  w60: { width: "60%" },
  w65: { width: "65%" },
  w70: { width: "70%" },
  w75: { width: "75%" },
  w80: { width: "80%" },
  w90: { width: "90%" },
  w100: { width: "100%" },
  wAuto: { width: "auto" },

  border: { border: "1px solid #000", borderBottom: "0px" },
  borderT: { borderBottom: "1px solid #000" },
  borderB: { borderBottom: "1px solid #000" },
  borderB2: { borderBottom: "2px solid #000" },
  borderE: { borderRight: "1px solid #000" },
  borderS: { borderLeft: "1px solid #000" },
  borderBS: { borderBottom: "4px solid #730A29" },
  borderHE: { borderRight: "1px solid #FFFFFF" },

  bg: { backgroundColor: "#BEBEBE" },
  bg2: { backgroundColor: "#BEBEBE" },

  fs7: { fontSize: "7px" },
  fs8: { fontSize: "8px" },
  fs9: { fontSize: "9px" },
  fs10: { fontSize: "10px" },
  fs11: { fontSize: "11px" },
  fs12: { fontSize: "12px" },
  fs13: { fontSize: "13px" },

  tend: { textAlign: "right", paddingRight: "2px" },
  tstart: { textAlign: "left" },
  tcenter: { textAlign: "center" },
  tBold: { fontFamily: "Helvetica-Bold" },

  mT8: { marginTop: "8" },
  mT10: { marginTop: "10px" },
  mT20: { marginTop: "20px" },
  mT40: { marginTop: "40px" },
  mx18: { marginHorizontal: "18px" },
  mx20: { marginHorizontal: "20px" },

  spaceFooter: {
    bottom: 10,
    left: 0,
    right: 0,
    width: "auto",
    height: 30,
    marginHorizontal: 10,
  },

  footer: {
    position: "absolute",
    bottom: 10,
    left: 0,
    right: 0,
    width: "auto",
    height: 30,
    marginHorizontal: 10,
    borderTopWidth: 1,
    flexDirection: "row",
    alignContent: "space-around",
    alignItems: "center",
  },
  textFooter: {
    width: "50%",
    textAlign: "center",
    fontSize: 8,
  },
});

export default async function pdfProveedoresN(data, cfg, t, fileNo) {
  return {
    report: (
      <Document>
        {data.map((el, id) => (
          <>
            <PrincipalPage d={el} cfg={cfg} fileNo={fileNo} key={"pgP" + id} />
            {cfg.detalle === "1" && (
              <Conceptos
                arrCons={el.conceptos}
                ttC={el.conceptosTotal}
                ttP={el.conceptosTotalPersonas}
                cfg={cfg}
                fileNo={fileNo}
                key={"dts" + id}
              />
            )}
          </>
        ))}
      </Document>
    ),
  };
}

function whatIs(algo){
  if(algo.length !== undefined) return algo;
  else return Object.values(algo);

}

function PrincipalPage({ d, cfg, fileNo }) {
  const dif = +d.deducciones - +d.importes;
  const stt = dif < 0 ? "#ca0404" : "#000";

  let arrDeducciones = whatIs(d.conceptosDed);
  let arrPercepciones = [];
  if(+d.importes > 0) arrPercepciones = whatIs(d.conceptosPer);

  return (
    <Page style={[s.body, s.fs11]}>
      <HeaderReport />
      <View style={[s.title, s.borderBS]}>
        <Text>{cfg.year}</Text>
        <Text>
          SOLICITUD DE PAGO POR TRANSFERENCIA DE DEDUCCIONES VÍA NÓMINA A
          TERCEROS
        </Text>
      </View>

      <View style={[s.mx18]}>
        <View style={[s.row, s.mT40]}>
          <View style={[s.w50]}>
            <Text>NOMBRE DE LA CUENTA</Text>
          </View>
          <View style={[s.w50]}>
            <Text>{d.nameAccountList}</Text>
          </View>
        </View>

        <View style={[s.row, s.mT40]}>
          <View style={[s.w50]}>
            <Text>NÚMERO DE LA CUENTA</Text>
          </View>
          <View style={[s.w50]}>
            <Text>{d.lcPropio}</Text>
          </View>
        </View>

        <View style={[s.row, s.mT40]}>
          <View style={[s.w50]}>
            <Text>PERIODO</Text>
          </View>
          <View style={[s.w50]}>
            <Text>{cfg.period}</Text>
          </View>
        </View>

        {((+d.importes > 0 && +d.deducciones > 0) ||
          arrDeducciones.length > 1) && (
          <View style={[s.row, s.mT40]}>
            <View style={[s.w50]}>
              <Text>INTEGRACIÓN</Text>
            </View>
            <View style={[s.w50]}>
              {+d.importes > 0 && +d.deducciones > 0 ? (
                <View>
                  {arrDeducciones.map((el, dx) => (
                    <ConceptosPD e={el} t={1} i={dx} key={"cd" + dx} />
                  ))}
                  {arrPercepciones.map((el, dx) => (
                    <ConceptosPD e={el} t={2} key={"cd" + dx} />
                  ))}
                </View>
              ) : (
                arrDeducciones.length > 1 &&
                arrDeducciones.map((el, dx) => (
                  <ConceptosPD e={el} t={1} i={dx} key={"cd" + dx} />
                ))
              )}
              <View style={s.row}>
                <View style={[s.w40, s.borderB2]}></View>
              </View>
            </View>
          </View>
        )}

        <View
          style={[s.row, +d.importes > 0 && +d.deducciones ? s.mT20 : s.mT40]}
        >
          <View style={[s.w50]}>
            <Text>TOTAL A PAGAR</Text>
          </View>
          <View style={[s.w50, s.tBold]}>
            <View style={[s.row, s.fs11]}>
              <View style={[s.w40, s.row, s.dflexBtw]}>
                <Text>$</Text>
                <Text style={[{ color: stt, paddingRight: "8px" }]}>
                  {+d.importes > 0 && +d.deducciones > 0
                    ? fmt.format(dif)
                    : fmt.format(d.deducciones)}
                </Text>
              </View>
              <View style={s.w60}></View>
            </View>
          </View>
        </View>

        <View style={[s.row, s.mT40]}>
          <View style={[s.w50]}>
            <Text>BENEFICIARIO</Text>
          </View>
          <View style={[s.w50]}>
            <Text>{d.rfc}</Text>
          </View>
        </View>

        <View style={[s.row, s.mT40]}>
          <View style={[s.w50]}>
            <Text>CLABE BANCARIA</Text>
          </View>
          <View style={[s.w50]}>
            <Text>{d.cuenta}</Text>
          </View>
        </View>

        <View style={[s.row, s.mT40]}>
          <View style={[s.w50]}>
            <Text>BANCO DESTINO</Text>
          </View>
          <View style={[s.w50]}>
            <Text>{d.nameBank}</Text>
          </View>
        </View>
      </View>

      <View fixed style={s.spaceFooter}></View>
      <View fixed style={s.footer}>
        <Text style={s.textFooter}>{fileNo}</Text>
        <Text
          style={s.textFooter}
          render={({ pageNumber, totalPages }) =>
            `PAGINA : ${pageNumber} / ${totalPages}`
          }
        />
      </View>
    </Page>
  );
}

function Conceptos({ arrCons, ttC, ttP, cfg, fileNo }) {
  return (
    <>
      {arrCons.map(
        (cd, ix) =>
          // <TablaConceptos
          //   c={el.conceptos[0]}
          //   cfg={cfg}
          //   ttp={+el.conceptosTotalPersonas[0] - el.conceptos[0].totalRows}
          //   ttc={+el.conceptosTotal[0]}
          //   fileNo={fileNo}
          //   key={"tc" + id}
          // />
          cd.status === "success" &&
          +cd.totalRows > 0 && (
            <Page style={[s.body, s.fs12]} key={"pgC" + ix}>
              <HeaderReport />
              <View style={[s.title, s.borderBS]}>
                <Text style={s.fs13}>{cfg.year}</Text>
                <Text>
                  SOLICITUD DE PAGO POR TRANSFERENCIA DE DEDUCCIONES VÍA NÓMINA
                  A TERCEROS
                </Text>
              </View>

              <View style={[s.tcenter, s.fs9, s.mT10]}>
                <Text>Concepto: {cd.data[0].nameUnder}</Text>
                <Text>
                  Periodo {cfg.period} de {cfg.year}
                </Text>
              </View>

              <TablaConceptos cd={cd.data} ttp={ttP[ix]} ttc={ttC[ix]} />

              <View fixed style={s.spaceFooter}></View>
              <View fixed style={s.footer}>
                <Text style={s.textFooter}>{fileNo}</Text>
                <Text
                  style={s.textFooter}
                  render={({ pageNumber, totalPages }) =>
                    `PAGINA : ${pageNumber} / ${totalPages}`
                  }
                />
              </View>
            </Page>
          )
      )}
    </>
  );
}

function TablaConceptos({ cd, ttp, ttc }) {
  return (
    <View style={[s.border, s.mT8, s.fs8]}>
      <View style={[s.row, s.header, s.borderB]} fixed>
        <View style={[s.w10, s.vCenter, s.borderHE]}>
          <Text style={s.mxCell}>NO.</Text>
        </View>
        <View style={[s.w20, s.vCenter, s.borderHE]}>
          <Text style={s.mxCell} wrap>
            NO. EMPLEADO
          </Text>
        </View>
        <View style={[s.w45, s.vCenter, s.borderHE]}>
          <Text style={s.mxCell}>NOMBRE COMPLETO</Text>
        </View>
        <View style={[s.w25, s.vCenter]}>
          <Text style={s.mxCell}>IMPORTE</Text>
        </View>
      </View>
      {cd.map((el, id) => (
        <View style={[s.row, s.borderB]} key={"tcr" + id}>
          <View style={[s.w10, s.tcenter, s.borderE]}>
            <Text style={s.mxCell}>{ttp + id + 1}</Text>
          </View>
          <View style={[s.w20, s.tcenter, s.borderE]}>
            <Text style={s.mxCell}>{el.staffNumber}</Text>
          </View>
          <View style={[s.w45, s.borderE]}>
            <Text style={s.mxCell}>{el.nombreCompleto}</Text>
          </View>
          <View style={[s.w25, s.tend]}>
            <Text style={s.mxCell}>{fmtMny.format(el.amount)}</Text>
          </View>
        </View>
      ))}
      <View style={[s.row, s.borderB]}>
        <View style={[s.w75, s.vCenter, s.borderE]}>
          <Text style={s.mxCell}>TOTAL</Text>
        </View>
        <View style={[s.w25, s.vCenter]}>
          <Text style={s.mxCell}>{fmtMny.format(ttc)}</Text>
        </View>
      </View>
    </View>
  );
}

function ConceptosPD({ e, t, i }) {
  return (
    <View style={[s.row, s.fs11]}>
      <View style={[s.w40, s.row, s.dflexBtw]}>
        <Text>$ {t === 1 ? (i === 0 ? "" : "+") : "-"}</Text>
        <Text style={{ paddingRight: "8px" }}>{fmt.format(e.importes)}</Text>
      </View>
      <View style={[s.w60, s.fs7, s.vCenterL]}>
        <Text>{e.nameUnder}</Text>
      </View>
    </View>
  );
}

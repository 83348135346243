import React, { useEffect, useState } from "react"
import { Row, Col } from "reactstrap"
import { AttachFile } from "@material-ui/icons";
import ModalViewFile from "../../../components/Modal/ModalViewFile";
import { SelectTypeHeadSingle } from "../../../components/GenericInputsFormik"
import { NumberInput } from "../../../components/GenericInputsFormik/NumberInput"
import { months } from "../reportes/filtros/Inputs/SelectMonths"
import { peticionesReceiver } from "../../../helpers/peticionesReceiver"
import { toast } from "react-toastify"
import Notification from "../../../components/Notification/Notification"
import Loader from "../../../components/Loader/Loader"
import TableComponentCustom from "../../../components/TableComponent/TableComponentCustom"


export default function Inicial({ setPoliza }) {
    const API = peticionesReceiver();
    const [linkFile, setlinkFile] = useState(null);
    const [modalFile, setModalFile] = useState(null);
    const [loading, setLoading] = useState(true);
    const [keyMonth, setKeyMonth] = useState(0);
    const [keyKindPolicy, setKeyKindPolicy] = useState(0);
    const [year, setYear] = useState();
    const [parameters, setParameters] = useState();
    const [keysKindPolicy, setKeysKindPolicy] = useState([]);

    const cabecerasTabla = ["Id", "Folio", "Mes/Año", "Tipo", "Evento", "Descripcion", "Fecha/Hora registro", "Ver", "Documento Fuente"]

    const onChange = (e) => {
        const { value, name } = e.target
        if (name === "keyKindPolicy") {
            setKeyKindPolicy(value ? value : 0);
        } else if (name === "keyMonth") {
            setKeyMonth(value ? value : 0);
        } else {
            setYear(value)
        }
    }

    async function getTiposPolizas() {
        const params = {
            action: "select",
            rows: "keyKindPolicy,nameKindPolicy",
            table: 'types_policies',
            conditions: "enabled=1"
        };
        const res = await API.peticion(params)
        if (res.status === 200 && res.data.code === "200") {
            setKeysKindPolicy(res.data.data)
            setLoading(false)
        } else {
            toast(<Notification type={"consultar_error"} withIcon />);
        }
    }

    useEffect(() => {
        if (!keysKindPolicy || keysKindPolicy.length === 0) {
            getTiposPolizas()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [keysKindPolicy])

    function getConditions(year) {
        let conditions = "A.enabled = 1"
        if (year && year !== "" && year.length > 3) {
            conditions += ` AND A.year = ${year}`;
        }
        if (keyMonth) {
            conditions += ` AND A.keyMonth = ${keyMonth}`
        }
        if (keyKindPolicy) {
            conditions += ` AND A.keyKindPolicy = ${keyKindPolicy}`
        }
        return conditions;
    }

    useEffect(() => {
        setParameters({
            action: "datatable",
            rows: "A.idPolicy,CONCAT(B.acronim,'-',A.number,'/',A.year),CONCAT(M.nameMonth,' ',A.year),CONCAT(B.acronim,'/',B.nameKindPolicy),C.nameEvent,D.description,A.created", ///sin espacios y comas
            table: "policies A INNER JOIN types_policies B ON A.keyKindPolicy=B.keyKindPolicy INNER JOIN account_events C on A.idEvent=C.idEvent INNER JOIN policiesdetail D on A.idPolicy=D.idPolicy INNER JOIN months M on A.keyMonth=M.keyMonth",
            conditions: getConditions(year),
            page: 0,
            records: 5,
            search: "",
            orderBy: "A.idPolicy desc"
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [keyMonth, keyKindPolicy, year])

    const getDocument = async (value) => {
        const params = {
            action: "multiselect",
            table:
                "policies A LEFT JOIN bookcase BC ON (BC.reference = A.idPolicy) LEFT JOIN files G ON (BC.idBook = G.idBook) INNER JOIN filetypes FT ON FT.keyFileType=BC.keyFileType",
            rows: "G.src",
            conditions: `A.enabled = 1 AND FT.keyFileType = 22  AND A.idPolicy = ${value[0]}`,
        };
        const res = await API.peticion(params)
        if (res.status === 200 && res.data.code === "200") {
            const data = res.data.data;
            if (data.length > 0) {
                setlinkFile(res.data.data[0].src)
                setModalFile(true)
            } else {
                toast(<Notification type={"consultar_error"} withIcon />);
            }
        } else {
            toast(<Notification type={"consultar_error"} withIcon />);
        }
    }

    const documentoFuente = (value, key) => {
        return <AttachFile style={{ cursor: "pointer" }} key={key} onClick={() => getDocument(value)} />
    }

    const onClickView = (row) => {
        localStorage.setItem("poliza", JSON.stringify(row))
        setPoliza(row);
    }

    if (loading) {
        return (<Loader></Loader>)
    }
    return (
        <>
            <Row>
                <Col xs={12} lg={4}>
                    <SelectTypeHeadSingle
                        label="Tipos de poliza"
                        inputName="keyKindPolicy"
                        onChangeMethod={onChange}
                        value={keyKindPolicy}
                        isRequired
                        optionsArray={keysKindPolicy}
                        optionValue="keyKindPolicy"
                        optionName="nameKindPolicy"
                    />
                </Col>
                <Col xs={12} lg={4}>
                    <SelectTypeHeadSingle
                        label="Mes"
                        inputName="keyMonth"
                        onChangeMethod={onChange}
                        value={keyMonth}
                        isRequired
                        optionsArray={months}
                        optionValue="value"
                        optionName="label"
                    />
                </Col>
                <Col xs={12} lg={4}>
                    <NumberInput
                        label="Año"
                        inputName="year"
                        onChangeMethod={onChange}
                        disableSep
                    />
                </Col>
            </Row>
            {!loading ?
                <TableComponentCustom
                    titulo="Consulta de polizas"
                    cabecerasTabla={cabecerasTabla}
                    filtro={[true, true, true, true, true, true, true]}
                    parametros={parameters}
                    addWatch={true}
                    actionWatch={onClickView}
                    specialColumns={[documentoFuente]}
                /> : <Loader />}
            {linkFile ? (
                <ModalViewFile
                    modal={modalFile}
                    setModal={setModalFile}
                    backdrop={true}
                    keyboard={true}
                    btnClose={true}
                    fileLink={linkFile}
                />
            ) : null}
        </>
    )
}
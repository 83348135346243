import React, { useEffect, useState } from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { Alert } from "reactstrap";
import { peticionesReceiver } from "../../../../helpers/peticionesReceiver";
import { toast } from "react-toastify";
import Notification from "../../../../components/Notification/Notification";

const ModalObservaciones = (props) => {
  const { modal, setModal, activity, btnClose } = props;

  const [activitySelected, setActiviySelected] = useState("");
  const API = peticionesReceiver();

  useEffect(() => {
    if (modal) {
      
      getActivities();
    }
  }, [modal]);

  const getActivities = () => {
    const params = {
      action: "select",
      table: "activitiesprogress",
      condition: {
        idProgress: activity.idProgress,
        enabled: 1,
      },
    };
    API.peticion(params)
      .then((res) => {
        if (res.status === 200 && res.data.code === "200") {
          setActiviySelected(res.data.data[0]);
        } else {
          toast(<Notification type={"consultar_error"} withIcon />);
        }
      })
      .catch((err) => {
        toast(<Notification type={"consultar_servidor_error"} withIcon />);
        /* setLoading(false); */
      });
  };

  const toggle = () => setModal(!modal);

  const CloseBtn = (
    <>
      {btnClose ? (
        <i className="eva eva-close cursor-pointer" onClick={toggle} />
      ) : (
        <></>
      )}
    </>
  );

  
  return (
    <Modal
      isOpen={modal}
      toggle={toggle}
      style={{ minWidth: "70%", minHeight: "70%" }}
    >
      <ModalHeader className="headline-1" close={CloseBtn}>Observaciones</ModalHeader>
      <ModalBody>
        <Alert
          color="dark"
          isOpen
          fade={false}
          className=""
          style={{
            borderRadius: "0.25rem",
            borderLeft: "3px solid #6b859e",
          }}
        >
          <h5 className="text-dark text-center">Observaciones</h5>
          <p className="text-dark text-center">
            
            {activitySelected.notes === null ||
            activitySelected.notes === undefined ||
            activitySelected.notes === ""
              ? "No se encontraron observaciones"
              : activitySelected.notes}
          </p>
        </Alert>

                {activitySelected.notes === null ||
            activitySelected.notes === undefined ||
            activitySelected.notes === "" ? 
             "" : <p className="text-dark text-center">Fecha de observacion {activitySelected.modified} </p>}
        
      </ModalBody>
    </Modal>
  );
};

export default ModalObservaciones;

import Widget from "../../../../components/Widget/Widget";
import FormBancos from "../FormBancos"
import {
    Col,    
  } from "reactstrap";
  import { agregar } from "../functions";

const CrearBanco = () => {
  return (
    <div>
      <Widget className="widget-p-md">
      <Col className="mb-12"><h1 className=" text-center">Crear Banco</h1></Col>
      <FormBancos crear={agregar}/>
    </Widget>
    </div>
  )
}

export default CrearBanco
import React from "react";
import CustomTooltip from "../../components/CustomTooltip/CustomTooltip";
import Currency from "react-currency-input-field";

export const CurrencyInput = ({
  label,
  inputName,
  onChangeMethod,
  onBlurMethod,
  value,
  isRequired,
  touched,
  errors,
  isDisabled,
  isMin,
  isMax,
  isPlaceholder,
  tooltip,
  textTooltip
}) => {
  return (
    <>
      <div className="form-group">
        <div className = "d-flex">
          <label htmlFor={inputName}>
            {label} {isRequired && <span className="text-danger">*</span>}
          </label>
          {
            tooltip &&
            <CustomTooltip
              className="mr-3 mb-3"
              text={textTooltip}
              idTooltip = {`tooltip-${inputName}`}
            />
          }
        </div>
        <Currency
            id={inputName}
            name={inputName}
            className={
                "form-control" +
                (errors && touched? " is-invalid" : "")
            }
            allowDecimals={true}
            allowNegativeValue={false}
            onValueChange={onChangeMethod}
            value={value}
            onBlur={onBlurMethod}
            min={1}
            prefix="$"
            decimalSeparator="."
            groupSeparator=","
            maxLength = {isMax || 30}
            placeholder={isPlaceholder ? isPlaceholder : undefined}
            disabled = {isDisabled || false}
        />
        {touched && errors && (
          <span style={{ color: "red" }}>{errors}</span>
        )}
      </div>
    </>
  );
};

import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Collapse, Row } from "reactstrap";
import Notification from "../../../components/Notification/Notification";
import { peticionesReceiver } from "../../../helpers/peticionesReceiver";
import TableComponentVer from "../../../components/TableComponent/TableComponentVer";
import { SelectTypeHeadSingle } from "../../../components/GenericInputsFormik/SelectTypeHeadSingle";
import { useFormik } from "formik";
import * as Yup from "yup";
import { doRequest } from "../../../helpers/requests";
import { TextInput } from "../../../components/GenericInputsFormik";
import { NumberInput } from "../../../components/GenericInputsFormik/NumberInput";
import { toast } from "react-toastify";

const defaultForm = {
  nameStaff: "",
  staffNumber: "",
  keyEmployType: "1",
};

const FormSchema = Yup.object().shape(
  {
    nameStaff: Yup.string().when("staffNumber", {
      is: undefined,
      then: Yup.string().required("Ingrese un nombre(s) y/o apellido(s)"),
    }),
    staffNumber: Yup.string().when("nameStaff", {
      is: undefined,
      then: Yup.string().required("Ingrese un número de empleado"),
    }),
    keyEmployType: Yup.string().when(
      ["nameStaff", "staffNumber"],
      (nameStaff, staffNumber, sc) => {
        return staffNumber !== undefined || nameStaff === undefined
          ? sc.required("Seleccione un tipo de empleado")
          : sc;
      }
    ),
  },
  ["nameStaff", "staffNumber"]
);

export const PersonalBuscar = ({ setEmployee }) => {
  const [loading, setLoading] = useState(true);
  const [collapse, setCollapse] = useState(false);
  const [typesStaff, setTypesStaff] = useState([]);
  const [parametros, setParametros] = useState({
    action: "datatable",
    table:
      "staff A INNER JOIN employtypes B ON B.keyEmployType = A.keyEmployType INNER JOIN status C ON A.keyStat = C.keyStat LEFT JOIN jobstaff D ON A.idStaff = D.idStaff LEFT JOIN areas E ON D.idArea = E.idArea LEFT JOIN jobs F ON D.keyJob = F.keyJob",
    rows: "A.idStaff,A.staffNumber,concat(A.name,' ',A.pName,' ',A.mName) as fullName, B.nameEmployType,C.nameStat,E.name as nameArea,F.nameJob",
    conditions: "",
    page: 0,
    records: 5,
    search: "",
    order: "",
  });
  const cabeceras = ["Id", "Núm. Empelado", "Nombre", "Tipo", "Ver"];
  const filtro = [true, true, true, true];
  const refScroll = useRef(null);

  const {
    values,
    handleBlur,
    errors,
    touched,
    handleSubmit,
    handleReset,
    setFieldValue,
  } = useFormik({
    initialValues: defaultForm,
    validationSchema: FormSchema,
    onSubmit: (values) => findEmployee(),
    enableReinitialize: true,
  });

  useEffect(() => {
    getTypesStaff();
  }, []);

  async function getTypesStaff() {
    const params = {
      action: "select",
      table: "employtypes",
      condition: { enabled: 1 },
      order: "nameEmployType ASC",
    };
    const data = await doRequest("receiver/receiver.php", params, false);
    if (data.length > 0) setTypesStaff(data);
    setLoading(false);
  }

  const onChange = (e) => setFieldValue(e.target.name, e.target.value);

  const findEmployee = () => {
    if (values.staffNumber !== "") findByNumber();
    else findByName();
  };

  function findByName() {
    setParametros({
      ...parametros,
      conditions: `A.enabled = 1 AND IF(D.valid IS NULL,true,IF(D.valid != 0, true, false)) AND concat(A.name,' ',A.pName,' ',A.mName) like '%${values.nameStaff}%'`,
    });
    if (!collapse) {
      setCollapse(true);
    }
    scrollTo();
  }

  async function findByNumber() {
    const params = {
      ...parametros,
      conditions: `A.enabled = 1 AND IF(D.valid IS NULL,true,IF(D.valid != 0, true, false)) AND A.staffNumber = ${values.staffNumber} AND A.keyEmployType = ${values.keyEmployType}`,
    };
    const data = await doRequest("receiver/receiver.php", params, false);
    if(data.length > 0){

      selectEmployee(Object.values(data[0]));
    }else{
      toast(<Notification type={"consultar_error"} backMessage={"EMPLEADO NO ENCONTRADO"} withIcon />);
    }
  }

  function scrollTo() {
    refScroll.current.scrollIntoView({ behavior: "smooth" });
  }

  function selectEmployee(el){
    setEmployee({
      id: el[0],
      nombre: el[2],
      tipo: el[3],
      numero: el[1],
      area: el[5],
      puesto: el[6],
      estatus: el[4],
      foto: ""
    });
  };

  return (
    <div className="mx-4">
      <div className="mb-3">
        <h4>Buscar Empleado</h4>
      </div>

      <form onSubmit={handleSubmit} onReset={handleReset} className="mt-2">
        <Row className="d-flex justify-content-center">
          <Col xs={12} md={7}>
            <TextInput
              label="Nombre del empleado"
              inputType="nameStaff"
              inputName="nameStaff"
              onChangeMethod={onChange}
              onBlurMethod={handleBlur}
              value={values.nameStaff}
              isRequired
              touched={touched.nameStaff}
              errors={errors.nameStaff}
            />
            <p className="dividing-line-form my-5">&#8195;&Oacute;&#8195;</p>
            <NumberInput
              label="Número de empleado"
              inputName="staffNumber"
              onChangeMethod={onChange}
              onBlurMethod={handleBlur}
              value={values.staffNumber}
              touched={touched.staffNumber}
              errors={errors.staffNumber}
              isMin={0}
              disableSep
              isRequired
            />
            <SelectTypeHeadSingle
              label="Partida Presupuestal"
              isRequired={true}
              inputName="keyEmployType"
              optionsArray={typesStaff}
              defaultOption="Seleccione un tipo de empleado"
              onChangeMethod={onChange}
              onBlur={handleBlur}
              value={values.keyEmployType}
              touched={touched.keyEmployType}
              errors={errors.keyEmployType}
              optionValue="keyEmployType"
              optionName="nameEmployType"
            />
          </Col>
        </Row>
        <div className="text-center mt-5">
          <Button color="primary" type="submit">
            Buscar
          </Button>
        </div>
      </form>

      <Collapse isOpen={collapse} className="">
        <TableComponentVer
          titulo={"Empleados"}
          cabecerasTabla={cabeceras}
          filtro={filtro}
          ver={selectEmployee}
          parametros={parametros}
        />
        <div ref={refScroll}></div>
      </Collapse>
    </div>
  );
};
export default PersonalBuscar;

/* eslint-disable jsx-a11y/alt-text */
import { Button, Col, Row } from "reactstrap";
import CameraInterface from "../../../components/webcam/Interface";
import { useFormik } from "formik";
import { useState } from "react";
import * as Yup from "yup";
import { TextInput } from "../../../components/GenericInputsFormik";
import * as faceapi from 'face-api.js'
import { toast } from "react-toastify";
import Notification from "../../../components/Notification/Notification";


export default function DetectarReconocimientoFacial({ before, next, user }) {
    const [visible, setVisible] = useState(true)
    const [loading, setLoading] = useState(false)
    const handleClose = () => setVisible(false)
    const handleOpen = () => {
        setVisible(true);
        setFieldValue("descriptor", "")
    }

    const defaultForm = {
        descriptor: "",
        captura: ""
    }

    const FormSchema = Yup.object().shape({
        descriptor: Yup.string().required("Es necesario capturar la fotografia del individuo"),
    })

    async function getIdentity() {
        const value = faceapi.euclideanDistance(user.descriptor, values.descriptor);
        if (value <= 0.5) {
            next();
        } else {
            toast(<Notification type={"consultar_error"} backMessage={"El empleado no se parece al de la foto registrada"} withIcon />);
        }
    }

    const { handleSubmit, values, handleBlur, errors, touched, setFieldValue, resetForm } =
        useFormik({
            initialValues: defaultForm,
            onSubmit: (values) => {
                getIdentity()
            },
            validationSchema: FormSchema,
        });

    return (
        <>
            <p>A continuación procederemos a hacer un reconocimiento facial para detectar su identidad</p>
            <form onSubmit={handleSubmit}>
                <Row className="mt-3 d-flex justify-content-center text-center">
                    <Col sm={12} md={6}>
                        <h6 className="text-center mb-4">Identidad a validar:</h6>
                        <img src={user.src} alt="imagen" style={{ width: "100%" }} />
                    </Col>
                    <Col sm={12} md={6}>
                        <div className="d-flex justify-content-center font-weight-bold">
                            <TextInput
                                errors={errors.descriptor}
                                inputName={"descriptor"}
                                inputType="hidden"
                                isRequired
                                label={"Reconocimiento facial"}
                                onBlurMethod={handleBlur}
                                touched={touched.descriptor}
                                value={values.descriptor}
                            />
                        </div>
                        {visible ? (
                            <div className="d-flex justify-content-center">
                                <CameraInterface
                                    cameraOpened={visible}
                                    setCameraOpened={setVisible}
                                    setFieldValue={setFieldValue}
                                    handleCloseModal={handleClose}
                                    setLoadingScreenShot={setLoading}
                                />
                            </div>
                        ) : (
                            <div className="d-flex flex-column justify-content-end">
                                <Button className="ml-auto" color="warning mb-3" onClick={handleOpen} disabled={!loading}>Volver a intentar</Button>
                                <img src={values.captura} />
                            </div>
                        )}
                    </Col>
                </Row>
                <div className="text-center mt-3">
                    <Button color='danger' className="mr-3" onClick={before}>Regresar</Button>
                    <Button color='success' className="ml-3" type="submit" disabled={!loading || values.descriptor === ""}>Continuar</Button>
                </div>
            </form>
        </>
    )
}
import * as XlsxPopulate from "xlsx-populate";
import { saveAs } from "file-saver";

/**
 * Hoja de estilos del excel
 */
const styles = {
  title: {
    horizontalAlignment: 'center',
    bold: true
  },
  headerBorder: {
    bold: "true",
    border: true,
    fill: "BFBFBF",
  },
  header: {
    bold: "true",
    border: true,
    fill: "BFBFBF",
  },
  headerBoderBold: {
    bold: "true",
    border: true,
  },
  contentBorder: {
    border: true,
  },
  contentBorderEnd: {
    border: true,
    horizontalAlignment: "right"
  }
};

const formatter = new Intl.NumberFormat("es-MX", {
  style: "currency",
  currency: "MXN",
});

/**
 * 
 * @param {Reporte entregado de la BD} report 
 * @param {Titulo (Nombre de la institución)} title 
 * @param {configuración de headers} config 
 */
export async function saveReport(report, title, config) {

  const { poliza, detalles } = report;
  
  function toCurrency(value) {
    if (value && !isNaN(value)) {
      return formatter.format(Number(value))
    } else if (isNaN(value)) {
      return value
    } else {
      return ""
    }
  }

  //Inicio de excel
  XlsxPopulate.fromBlankAsync().then(async (workbook) => {
    const sheet1 = workbook.sheet(0);

    //Titulo
    sheet1
        .range("A1:J1")
        .merged(true)
        .value("SAPP")
        .style(styles.title);
    //Subtitulos
    sheet1
        .range("A2:J2")
        .merged(true)
        .value(title)
        .style(styles.title);
    
    sheet1
      .range("A4:F4")
      .merged(true)
      .value(`DATOS GENERALES:`)
      .style(styles.headerBoderBold);
    sheet1
      .range("G4:J4")
      .merged(true)
      .value(`DETALLE DE POLIZA:`)
      .style(styles.headerBoderBold);
    sheet1
        .range("A5:F5")
        .merged(true)
        .value(`ENTE PUBLICO: SAPP`)
        .style(styles.headerBoderBold)
    sheet1
        .range("G5:J5")
        .merged(true)
        .value(`POLIZA: ${poliza.number}/${poliza.year} - ${poliza.acronim} ${poliza.nameKindPolicy}`)
        .style(styles.headerBoderBold)
    sheet1
        .range("A6:F6")
        .merged(true)
        .style(styles.headerBoderBold);
    sheet1
      .range("G6:J6")
      .merged(true)
      .value(`FECHA: ${poliza.created}`)
      .style(styles.headerBoderBold);
    sheet1
        .range("A7:F7")
        .merged(true)
        .value(`DESCRIPCIÓN: ${poliza.nameEvent}`)
        .style(styles.headerBoderBold)
    sheet1
        .range("G7:J7")
        .merged(true)
        .value(`MES: ${poliza.nameMonth}`)
        .style(styles.headerBoderBold)
    sheet1
        .range("A8:F8")
        .merged(true)
        .value(`ELABORO: ${poliza.elaboro}`)
        .style(styles.headerBoderBold)
    sheet1
        .range("G8:J8")
        .merged(true)
        .value(`EVENTO DEL SISTEMA: ${poliza.idPolicy}`)
        .style(styles.headerBoderBold)
      
        sheet1.cell(`A10`).style(styles.headerBoderBold).value("CUENTA")
        sheet1.cell(`B10`).style(styles.headerBoderBold).value("SCTA")
        sheet1.cell(`C10`).style(styles.headerBoderBold).value("SSCTA")
        sheet1.cell(`D10`).style(styles.headerBoderBold).value("SSSCTA")
        sheet1.cell(`E10`).style(styles.headerBoderBold).value("SSSSCTA")
        sheet1.cell(`F10`).style(styles.headerBoderBold).value("DESCRIPCIÓN")
        sheet1.cell(`G10`).style(styles.headerBoderBold).value("CONCEPTO")
        sheet1.cell(`H10`).style(styles.headerBoderBold).value("FUENTE DE FINANCIAMIENTO")
        sheet1.cell(`I10`).style(styles.headerBoderBold).value("DEBE")
        sheet1.cell(`J10`).style(styles.headerBoderBold).value("HABER")
    
    detalles.forEach((value, index) => {
        sheet1.cell(`A${index + 11}`).style(styles.contentBorder).value(value[config[0]])
        sheet1.cell(`B${index + 11}`).style(styles.contentBorder).value(value[config[1]])
        sheet1.cell(`C${index + 11}`).style(styles.contentBorder).value(value[config[2]])
        sheet1.cell(`D${index + 11}`).style(styles.contentBorder).value(value[config[3]])
        sheet1.cell(`E${index + 11}`).style(styles.contentBorder).value(value[config[4]])
        sheet1.cell(`F${index + 11}`).style(styles.contentBorder).value(value[config[5]])
        sheet1.cell(`G${index + 11}`).style(styles.contentBorder).value(value[config[6]])
        sheet1.cell(`H${index + 11}`).style(styles.contentBorder).value(value[config[7]])
        sheet1.cell(`I${index + 11}`).style(styles.contentBorder).value(toCurrency(value[config[8]]))
        sheet1.cell(`J${index + 11}`).style(styles.contentBorder).value(toCurrency(value[config[9]]))
    });
    
    sheet1.column("A").width(20);
    sheet1.column("B").width(20)
    sheet1.column("C").width(20)
    sheet1.column("D").width(20)
    sheet1.column("E").width(20)
    sheet1.column("F").width(20)
    sheet1.column("G").width(20)
    sheet1.column("H").width(20)
    sheet1.column("I").width(30)
    sheet1.column("J").width(30)
    
    return workbook.outputAsync().then((res) => {
        saveAs(res, `${title}.xlsx`);
    });
  });
}
import Datatable from "../../../../../../components/Datatable/Datatable";
import { peticionesReceiver } from "../../../../../../helpers/peticionesReceiver";
import { toast } from "react-toastify";
import Notification from "../../../../../../components/Notification/Notification";


/**
 * Formateador de numeros de concurrencias
 */
const formatter = new Intl.NumberFormat("es-MX", {
  style: "currency",
  currency: "MXN",
});

export default function Report48({ title, params, route }) {
  const API = peticionesReceiver();



  const columns = [
    {
      data: null,
      render: function (row) {
        return row.nameChapterPrint ? 
        (
            <div className="text-center">
                {row.nameChapterPrint}
            </div>
            ) : row.nameConcept;
      },
    },
    {
      data: null,
      render: function (row) {
        return row.amountChapter
          ? row.amountChapter === undefined || row.amountChapter === null
            ? formatter.format(0)
            : formatter.format(row.amountChapter)
          : row.amountPartida === undefined || row.amountPartida === null
          ? formatter.format(0)
          : formatter.format(row.amountPartida);
      },
    },
  ];

  const headers = ["TOTAL", "IMPORTE"];

  /* const columnDefs = [
        {
            targets: [1],
            className: "text-right"
        }
    ] */

  const getData = async () => {
    try {
      const res = await API.peticionEndPoint(params, route);
      if (res.status === 200 && res.data.code === "200") {
        return formatInformation(res.data);
      } else {
        toast(
          <Notification
            type={"consultar_error"}
            backMessage={res.data.message}
            withIcon
          />
        );
        return null;
      }
    } catch (err) {
      toast(<Notification type={"consultar_servidor_error"} withIcon />);
      return null;
    }
  };

  const formatInformation = (report) => {
    let tempInformation = {
      data: [],
      dataCapitulos: [],
      dataPartidas: [],
    };

    let prevNameChapter = "";
    let amontChapter = 0;
    let amountConcept = 0;
    report.data.map((concept) => {
      /* total chapter */
      let tempChapters = report.dataCapitulos.filter(
        (chapter) => chapter.keyChapter === concept.keyChapter
      );
      amontChapter = 0;
      tempChapters.map((chap) => {
        amontChapter += +chap.montoTotal;
      });

      /* Total Concept */
      let tempConcepts = report.dataPartidas.filter(
        (partida) => partida.conceptKey === concept.conceptKey
      );
      amountConcept = 0;
      tempConcepts.map((part) => {
        amountConcept += +part.montoTotal;
      });

      /* nameChapter */
      if (prevNameChapter !== concept.nameChapter) {
        prevNameChapter = concept.nameChapter;
        tempInformation.data.push({
          nameChapterPrint: concept.nameChapter,
          amountChapter: amontChapter,
          isChapter: true,
        });
        /* concept.nameChapterPrint = concept.nameChapter;
            concept.amountChapter = amontChapter; */
      }

      concept.amountPartida = amountConcept;

      tempInformation.data.push(concept);
    });

    return tempInformation;
  };

  return (
    <>
      <h1 className="text-secondary text-center">{title} </h1>
      <Datatable
        columns={columns}
        headers={headers}
        /* columnDefs={columnDefs} */
        ordering={false}
        searching={false}
        paging={false}
        petition={getData}
        info={false}
      />
    </>
  );
}

export function paramsReport(i, getConditions) {
    if (i) return {
        action: "multiselect",
        table:
            "budgetrequests A INNER JOIN areas B USING (idArea) INNER JOIN staff C ON C.idStaff = A.idStaffRequest INNER JOIN status D ON D.keyStat = A.keyStat INNER JOIN months E ON E.keyMonth = MONTH(A.dateSend)",
        rows: "CONCAT(A.numberRequest,'/',A.yearRequest) AS folio, E.nameMonth, A.justification, CONCAT(C.name,' ',C.pName,' ', C.mName) as fullName, A.dateSend, D.nameStat, A.requestedAmount",
        conditions: "A.enabled = 1 AND A.keyStat != 116",
        order: "folio",
        page: "0"
    }
    else return {
        action: "multiselect",
        table:
            "budgetrequests A INNER JOIN areas B USING (idArea) INNER JOIN budgetrequestdetail C USING (idBudgetRequest) INNER JOIN status D ON D.keyStat = A.keyStat INNER JOIN statefunds E USING (idStateFund) INNER JOIN expense_objects F USING (idExpenseObject) INNER JOIN budgetrequestschedule G USING (idDetail)",
        rows: "CONCAT(A.numberRequest,'/',A.yearRequest) AS folio,B.name AS nameArea,A.justification, A.dateSend,A.dateApproved,A.requestedAmount,D.nameStat,E.stateFundKey,E.nameStateFund,F.fullKey,F.nameObject,C.concept,C.remain,GROUP_CONCAT(G.required) AS meses,A.notes",
        conditions: getConditions() + " AND B.enabled = 1",
        order: "folio",
        page: "0",
        group: "C.idDetail",
    }
}

import React from "react";

/**
 * 
 * @param {Object} props
 * @param {string} props.label
 * @param {string} props.inputName
 * @param {Function} props.onChangeMethod
 * @param {Function} props.onBlurMethod
 * @param {string} props.fileAccept
 * @param {boolean} props.isRequired
 * @param {MutableRefObject} props.refInput
 * @param {string} props.errors
 * @param {MutableRefObject} props.refInput
 * @param {boolean} props.isDisabled
 * @param {boolean} props.basicReq
 * @param {boolean | "user" | "environment" | undefined} props.capture
 * @returns 
 */
export const FilesInput = ({
  label,
  inputName,
  onChangeMethod,
  onBlurMethod,
  fileAccept,
  isRequired,
  touched,
  refInput,
  errors,
  isDisabled,
  basicReq,
  capture
}) => {
  return (
    <>
      <div className="form-group">
        <label htmlFor={inputName}>
          {label} {isRequired && <span className="text-danger">*</span>}
        </label>
        <input
          disabled={isDisabled === true ? true : false}
          name={inputName}
          id={inputName}
          type="file"
          accept={fileAccept}
          onChange={onChangeMethod}
          onBlur={onBlurMethod ? onBlurMethod : undefined}
          className={
            "form-control" +
            (errors && touched ? " is-invalid" : "")
          }
          ref={refInput ? refInput : undefined}
          required={isRequired && basicReq !== false ? true : false}
          capture={capture}
        />
        {errors && touched && (
          <span style={{ color: "red" }}>{errors}</span>
        )}
      </div>
    </>
  );
};

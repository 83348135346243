import { Document, Page, Text, View } from "@react-pdf/renderer";

import HeaderReport from "../../../../components/Pdf/Header";
import { StyleSheet } from "@react-pdf/renderer";
import { fDateLocal } from "../../../../libs/tools/format";

const colors = {
  backgroundHeaders: "#646464",
};

export const styles = StyleSheet.create({
  /* MEtodos para generar la tabla con varios campos dentro */
  body: {
    paddingTop: 35,
    paddingBottom: 35,
    paddingHorizontal: 10,
  },
  tableColHeaderReport: { 
    width: "75%", 
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    backgroundColor: colors.backgroundHeaders,
    color:'white',
    textAlign: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 9 ,
  }, 
  tableColHeaderNoL: { 
    width: "12.5%",
    height: 20, 
    borderStyle: "solid", 
    borderWidth: 1, 
    backgroundColor: colors.backgroundHeaders,
    color:'white',
    textAlign: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 9 ,
  }, 
  bodyTableGeneral: {
    paddingTop: 35,
    paddingBottom: 10,
    paddingHorizontal: 10,
  },
  spaceFooterTableGeneral: {
    fontSize: 12,
    bottom: 10,
    left: 0,
    right: 0,
    width: "auto",
    height: 50,
    marginHorizontal: 10,
  },
  spaceFooter: {
    fontSize: 12,
    bottom: 10,
    left: 0,
    right: 0,
    width: "auto",
    height: 50,
    marginHorizontal: 10,
  },
  footer: {
    position: "absolute",
    fontSize: 12,
    bottom: 10,
    left: 0,
    right: 0,
    width: "auto",
    height: 30,
    marginHorizontal: 10,
    /*       borderColor:"#a9a9a9", */
    borderTopWidth: 1,
    flexDirection: "row",
    alignContent: "space-around",
    alignItems: "center",
  },
  title: {
    width: "100%",
    textAlign: "center",
    marginBottom: 10,
    /* color: '#a9a9a9', */
    fontSize: 16,
  },
  subTitle: {
    width: "100%",
    textAlign: "center",
    marginBottom: 10,
    /* color: '#a9a9a9', */
    fontSize: 14,
  },
  textFooter: {
    width: "50%",
    /* color: '#a9a9a9', */
    textAlign: "center",
    fontSize: 8,
  },

  table: {
    display: "table",
    width: "auto",
    /*       borderStyle: "solid", 
      borderColor: "red",
      borderWidth: 1, 
      borderRightWidth: 0, 
      borderLeftWidth: 0 , */
    margin: 10,
  },
  //cabeceras
  tableColHeaderNo: {
    width: "12.5%",
    height: 20,
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    backgroundColor: colors.backgroundHeaders,
    color: "white",
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center",
    fontSize: 9,
  },

  //contenido
  tableColContentNoBI: {
    width: "12.5%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
  },

  tableColContentNo: {
    width: "12.5%",
    borderStyle: "solid",
    borderWidth: 1,
    /* borderLeftWidth: 0,  */
    borderTopWidth: 0,
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
  },

  tableColContentReport: {
    width: "75%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  //filas
  tableRow: {
    flexDirection: "row",
  },
  tableColumn: {
    flexDirection: "column",
  },

  //Filas de contenido, max 3
  displayContent: {
    paddingLeft: 9,
    /* margin: 9,  */
    marginBottom: 5,
    /* marginTop: 9,
      marginBottom: 9, */
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-around",
  },

  //Tamaño de celda deacuerdo a tamaño del contenido
  cellOneColumn: {
    width: "100%",
    fontSize: 6,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    margin: 2.5,
  },
  cellTwoColumn: {
    width: "50%",
    fontSize: 6,
    marginTop: 2.5,
    marginBottom: 2.5,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },

  cellThreeColumn: {
    width: "96.3%",
    fontSize: 8,
  },
  textBold: {
    fontFamily: "Helvetica-Bold",
    /* fontWeight: 900, */
    /* fontFamily: 'Courier-Bold' */
    /* color: 'red' */
  },
});

// const formatter = new Intl.NumberFormat("es-MX", {
//   style: "currency",
//   currency: "MXN",
// });
// const dateformatter = (fecha) => {
//   let date = new Date(fecha);
//   return `${date.getDate()}/${date.getMonth()}/${date.getFullYear()}`;
// };

// const formatField = (emp, conf) => {
//   let ff = emp[conf.value];
//   switch (conf.format) {
//     case "moneda":
//       ff = formatter.format(ff);
//       break;
//     case "fecha":
//       if (isNaN(ff)) {
//         ff = dateformatter(ff);
//       }
//       break;
//     default:
//       break;
//   }
//   return ff;
// };

export function generatePdfNominaDesglosadoGen(
  data,
  model,
  title,
  fileNo,
  cont
) {
  data = Object.values(data)
  const keys = Object.keys(data[0]);
  keys.splice(keys.indexOf("idStaff"), 1);


  const nombre = keys.splice(keys.indexOf("nombre"), 1);
  const numeroEmpleado = keys.splice(keys.indexOf("NumEmpleado"), 1);


  const contregistro = isNaN(cont) ? 0 : cont;
  return {
    contador: contregistro + data.length,
    report: (
      <>
        <Document>
          <Page style={styles.body}>
            <HeaderReport />
            <View>
              <Text style={styles.title}>{title}</Text>
            </View>

            <View>
              <Text style={styles.subTitle}>
                Período de pago: {model.filters.period?.toString().padStart(2, '0')}/{model.filters.year}
              </Text>
              <Text style={styles.subTitle}>
                Del: {fDateLocal(model.filters.dates.start)} al {fDateLocal(model.filters.dates.end)}
              </Text>
            </View>
            
            <View style={styles.table}>
              {insertHeaders(model)}
              {insertContent1Map(data, keys,nombre,numeroEmpleado)}
            </View>
            <View fixed style={styles.spaceFooter}></View>
            <View fixed style={styles.footer}>
              <Text style={styles.textFooter}>{fileNo}</Text>
              <Text
                style={styles.textFooter}
                render={({ pageNumber, totalPages }) =>
                  `PAGINA : ${pageNumber} / ${totalPages}`
                }
              />
            </View>
          </Page>
        </Document>
      </>
    ),
  };
}

const insertHeaders = (model) => {
  return (
    <View style={styles.tableRow} fixed>
      <View style={styles.tableColHeaderNoL} fixed>
        <Text style>{model.headers[0]}</Text>
      </View>
      <View style={styles.tableColHeaderNo} fixed>
        <Text>{model.headers[1]}</Text>
      </View>
      <View style={styles.tableColHeaderReport} fixed>
        <Text>{model.headers[2]}</Text>
      </View>
    </View>
  );
};

const insertContent1Map = (data, keys, nombre,numEmpleado) => {
  return (
    data.length > 0 &&
    data.map((employee, index) => (
      <View style={styles.tableRow} wrap={false} key={"row"+index}>
        <View style={styles.tableColContentNo}>
          <Text style={styles.cellOneColumn}>{employee[nombre]}</Text>
        </View>
        <View style={styles.tableColContentNoBI}>
          <Text style={styles.cellOneColumn}>{employee[numEmpleado]}</Text>
        </View>
        <View style={styles.tableColContentReport}>
          <View style={styles.displayContent}>
            {keys.map((keyObj, indexObj) => (
              <Text style={styles.cellTwoColumn} key={"col"+indexObj}>
                <Text style={styles.textBold}>{keyObj}: </Text>
                {employee[keyObj]}
              </Text>
            ))}
          </View>
        </View>
      </View>
    ))
  );
};

import axios from "axios";
import { peticionEncript } from "./peticionesEncripted";

export const peticionPersonal = async (data) => {
  let respuesta = "";
  const finalData = peticionEncript(data)
  await axios
    .post(`${process.env.REACT_APP_API}app/facades/employees/employeesF.php`, finalData)
    .then((res) => {
      respuesta = res;
    })
    .catch((err) => {
      console.log("ERROR: " + err);
    });
  return respuesta;
}; 
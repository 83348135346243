import React, {useRef, useState} from "react";
import moment from 'moment';
import "moment/locale/es";
import TimeLine from "react-gantt-timeline";
import { Button } from "reactstrap";
import html2canvas from 'html2canvas'
import ModalReports from "../../../../components/Modal/ModalReports";
import { generarGANTT } from "./pdfGantt";

export function getRandomColor() {
  var letters = '0123456789ABCDEF';
  var color = '#';
  for (var i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}

const config = {
  header: {
    top: {
      style: {
        color: "var(--bg-primary-hover)",
        borderColor: "var(--bg-primary-hover)",
        backgroundColor: "#fbf9f9",
        borderWidth: "4px",
        fontSize: 20,
        fontWeight: "bold",
        padding: "9px",
        height: "40px",
      }
    },
    middle: {
      style: {
        backgroundColor: '#333333'
      }
    },
    bottom: {
      style: {
        background: '#333333',
        color: 'white',
        fontSize: 9
      }
    }
  },
  dataViewPort: {
    rows: {
      style: {
        backgroundColor: "#fbf9f9",
        borderBottom: 'solid 0.5px #cfcfcd'
      }
    },
    task: {
      showLabel: false,
      style: {
        position: 'absolute',
        borderRadius: 14,
        color: 'white',
        textAlign: 'center',
        backgroundColor: 'grey'
      }
    }
  }
};

export default function Gantt2({ data }) {
  moment.locales("es")
  const componentRef = useRef()
  const [pdfConfig, setPdfConfig] = useState();
  const [modalReports, setModalReports] = useState(false);

  const handleCapture = () => {
    componentRef.current.style.display = 'block';
    setTimeout(() => { 
      const { offsetWidth, offsetHeight } = componentRef.current;
      const aspectRatio = offsetWidth / offsetHeight;
      html2canvas(componentRef.current).then(canvas => {
        const imgData = canvas.toDataURL('image/png')
          .replace("image/png", "image/octet-stream");
        setPdfConfig({
          imgData,
          aspectRatio
        });
        setModalReports(true);
      });
      componentRef.current.style.display = 'none';
    }, 100);
      
};
  
  return (
    <>
      <div className="text-right mb-3">
        <Button color="success" onClick={handleCapture}>Imprimir</Button>
      </div>
      <div className="time-line-container">
        <TimeLine data={data} mode={'year'} config={config} nonEditableName={true}/>
      </div>
      <div className="time-line-container" ref={componentRef} style={{width: "900px", display: 'none'}}>
        <TimeLine data={data} mode={'year'} config={config} nonEditableName={true}/>
      </div>
      <ModalReports
          modal={modalReports}
          setModal={setModalReports}
          title={"Diagrama de Gantt"}
          cabeceras={""}
          backdrop={"static"}
          keyboard={false}
          report={"rep"}
          pdfConfig={pdfConfig}
          generatePdfMethod={{
            method: generarGANTT,
            type: "general",
          }}
          records={1000}
        />
    </>
  );
}
import Notification from "../../../components/Notification/Notification";
import { peticionEncript } from "../../../helpers/peticionesEncripted";
import { toast } from "react-toastify";

import axios from "axios";

export const createCalculo = async (data) => {
  if (data) {
    const params = {
      action: "ejecutaPercepcionesExtras",
      rows: {
        period: Number(data.period),
        year: Number(data.year.getFullYear()),
        idPerceptionExtra: Number(data.idPerceptionExtra),
        // idStaff: data.idStaff,
      },
    };
    if (data.idStaff) {
      params.rows.idStaff = data.idStaff;
    }
    const finalData = peticionEncript(params);
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API}app/facades/payslips/payslipF.php`,
        finalData
      );
      if (res.status === 200 && res.data.status === "success") {
        toast(
          <Notification
            type={"agrega_exito"}
            backMessage={res.data.message.duracion}
            withIcon
          />
        );
        return true;
      } else {
        toast(
          <Notification
            type={"agrega_error"}
            backMessage={res.data.message}
            withIcon
          />
        );
        return false;
      }
    } catch (error) {
      toast(<Notification type={"consultar_servidor_error"} withIcon />);
      return false;
    }
  }
};

import { Formik, Form, Field, ErrorMessage, useFormikContext } from "formik";
import * as Yup from "yup";
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Col, Row, Button } from "reactstrap";
import ModalConfirmation from "../../../components/Modal/ModalConfirmation";

const defaultForm = {
  keyCountry: "",
  acronim: "",
  nameCountry: "",
  phoneKey: "",
};

const FormPais = ({ crear, data, editar }) => {
  const history = useHistory();
  const [form, setForm] = useState(defaultForm);
  const [modal, setModal] = useState(false);

  const FormSchema = Yup.object().shape({
    acronim: Yup.string()
      .min(2, "Acrónimo debe contener al menos 2 letras")
      .max(4, "Acrónimo debe tener 4 letras o menos")
      .required("Ingrese un acrónimo para el país"),
    nameCountry: Yup.string()
      .min(4, "Nombre debe contener al menos 4 letras")
      .max(200, "El nombre ingresado es muy largo")
      .required("Ingrese un nombre de país"),
    phoneKey: Yup.string()
      .min(1, "Ingrese una clave telefónica")
      .max(4, "Clave telefónica muy larga")
      .required("Ingrese una clave telefónica"),
  });

  const submitFunction = (fields) => {
    setModal(true);
  };

  const cancelOp = () => {
    history.push("/template/paises");
  };

  return (
    <Formik
      initialValues={form}
      validationSchema={() => FormSchema}
      onSubmit={(fields, { resetForm }) => {
        submitFunction(fields);
      }}
      onReset={(fields, { resetForm }) => {
        cancelOp();
      }}
    >
      {({ errors, status, touched, values }) => (
        <Form className="mt-4">
          <ModalConfirmation
            modalTitle={data ? "Editar" : "Crear"}
            modal={modal}
            setModal={setModal}
            crear={crear}
            editar={editar}
            isEdit={data ? true : false}
            values={values}
          >
            {data ? (
              <div className="d-flex justify-content-center">
                <h6>¿Está seguro de editar el registro?</h6>
              </div>
            ) : (
              <div className="d-flex justify-content-center">
                <h6>¿Desea continuar con el registro?</h6>
              </div>
            )}
          </ModalConfirmation>

          <div className="form-group">
            <label htmlFor="nameCountry">
              Nombre<span className="text-danger">*</span>
            </label>
            <Field
              name="nameCountry"
              type="text"
              className={
                "form-control" +
                (errors.nameCountry && touched.nameCountry ? " is-invalid" : "")
              }
            />
            <ErrorMessage
              name="nameCountry"
              component="div"
              className="invalid-feedback"
            />
          </div>
          <Row>
            <Col xs="12" md="6">
              <div className="form-group">
                <label htmlFor="acronim">
                  Acrónimo<span className="text-danger">*</span>
                </label>
                <Field
                  name="acronim"
                  type="text"
                  className={
                    "form-control" +
                    (errors.acronim && touched.acronim ? " is-invalid" : "")
                  }
                />
                <ErrorMessage
                  name="acronim"
                  component="div"
                  className="invalid-feedback"
                />
              </div>
            </Col>
            <Col xs="12" md="6">
              <div className="form-group">
                <label htmlFor="mName">
                  Clave telefónica<span className="text-danger">*</span>
                </label>
                <Field
                  name="phoneKey"
                  type="number"
                  className={
                    "form-control" +
                    (errors.phoneKey && touched.phoneKey ? " is-invalid" : "")
                  }
                />
                <ErrorMessage
                  name="phoneKey"
                  component="div"
                  className="invalid-feedback"
                />
              </div>
            </Col>
          </Row>
          <div className=" d-flex flex-sm-row flex-column-reverse justify-content-sm-around mt-1">
            <Button color="danger" type="reset" className="mt-3">
              Cancelar
            </Button>
            <Button color="success" type="submit" className="mt-2 mt-sm-3">
              Guardar
            </Button>
          </div>
          <FormikData info={data} />
        </Form>
      )}
    </Formik>
  );
};

const FormikData = ({ info }) => {
  const { setFieldValue } = useFormikContext();
  useEffect(() => {
    if (info) {
      setFieldValue("keyCountry", info[0]);
      setFieldValue("nameCountry", info[1]);
      setFieldValue("acronim", info[2]);
      setFieldValue("phoneKey", info[3]);
    }
  }, [info]);
  return null;
};
export default FormPais;

/* -- Autorizacion de Cotizaciones -- */
import AutorizacionCotizaciones from "../../../pages/adquisiciones/autorizacion-cotizaciones/AutorizacionCotizaciones";

/* -- Recepcion Cotizaciones -- */
import RecepcionCotizaciones from "../../../pages/adquisiciones/recepcion-cotizaciones/RecepcionCotizaciones";
/* -- Detalle Recepcion Cotizaciones -- */
import DetalleSolicitud from "../../../pages/adquisiciones/recepcion-cotizaciones/DetalleSolicitud";

/* -- Registro de Proveedores -- */
import RegistroProveedores from "../../../pages/adquisiciones/registro-proveedores/RegistroProveedores";
/* -- Asignacion de roles -- */
import AsignacionRoles from "../../../pages/adquisiciones/asignacion-roles/AsignacionRoles";
/* -- Adquisiciones -- */
import SolicitudCotizacion from "../../../pages/adquisiciones/solicitud-cotizacion/Principal";
import CrearSolicitud from "../../../pages/adquisiciones/solicitud-cotizacion/Forms/Agregar/CrearSolicitud";
import EditarSolicitud from "../../../pages/adquisiciones/solicitud-cotizacion/Forms/Editar/EditarSolicitud";
/* -- Estudios de mercado -- */
import EstudiosMercado from "../../../pages/adquisiciones/estudios-mercado/Principal";
import CotizarEstudioDeMercado from "../../../pages/adquisiciones/estudios-mercado/Cotizar";
import PrincipalTiposCompras from "../../../pages/adquisiciones/tipos-compras/PrincipalTiposCompras";
// Recepcion de compras
import RecepcionCompras from "../../../pages/adquisiciones/recepcion-compras";// -- Solicitud de adquisiciones
// -- Solicitud de adquisiciones
import SolicitudAdquisicion from "../../../pages/adquisiciones/solicitud-adquisicion";
import AgregarSolicitudAdquisicion from "../../../pages/adquisiciones/solicitud-adquisicion/agregar";
// -- Solventacion de proceso
import SolventacionProceso from "../../../pages/adquisiciones/solventacion-proceso";
import ResolverSolicitud from "../../../pages/adquisiciones/solventacion-proceso/resolver-solicitud";
// -- Recepcion de Servicios
import RecepcionServicios from "../../../pages/adquisiciones/recepcion-servicios/Inicio";
//Carga de facturas
import CargaFacturas from "../../../pages/adquisiciones/carga-facturas";
import DetallesCargaFactura from "../../../pages/adquisiciones/carga-facturas/detalles";
//Revision de Adquisiciones
import RevisionAdquisiciones from "../../../pages/adquisiciones/revision-adquisiciones";

//Orden de pago 
import OrdenPago from "../../../pages/adquisiciones/orden-pago/OrdenPago";

const rutasAdquisiciones = [
    //Proveedores
    { path: "/template/proveedores", component: RegistroProveedores },
    //ASIGNACION ROLES 
    { path: "/template/asignacion-roles", component: AsignacionRoles },

    //AUTORIZACION DE COTIZACIONES 
    { path: "/template/autorizacotizacion", component: AutorizacionCotizaciones },

    //RECEPCION DE COTIZACIONES 
    { path: "/template/recepcioncotizacion", component: RecepcionCotizaciones },
    //DETALLE RECEPCION DE COTIZACIONES 
    { path: "/template/recepcioncotizacion/detalles", component: DetalleSolicitud },
    //SOLICITUD COTIZACION 
    { path: "/template/cotizacion", component: SolicitudCotizacion },
    //AGREGAR SOLICITUD COTIZACION
    { path: "/template/cotizacion/agregar", component: CrearSolicitud },
    //EDITAR SOLICITUD COTIZACION
    { path: "/template/cotizacion/editar", component: EditarSolicitud },
    //ESTUDIOS DE MERCADO
    { path: "/template/estudiomercado", component: EstudiosMercado },
    { path: "/template/estudiomercado/cotizar", component: CotizarEstudioDeMercado },
    //TIPOS COMPRAS
    { path: "/template/tiposcompras", component: PrincipalTiposCompras },
    //Solicitud adquisicion
    { path: "/template/solicitud-adquisicion", component: SolicitudAdquisicion },
    { path: "/template/solicitud-adquisicion/agregar", component: AgregarSolicitudAdquisicion },
    //RECEPCION DE COMPRAS
    { path: "/template/recepcioncompras", component: RecepcionCompras },
    //SOLVENTACIO DE PROCESO
    { path: "/template/solventacion-proceso", component: SolventacionProceso },
    { path: "/template/solventacion-proceso/resolver-solicitud", component: ResolverSolicitud },
    //Carga de facturas
    { path: "/template/carga-facturas", component: CargaFacturas },
    { path: "/template/carga-facturas/detalles", component: DetallesCargaFactura },
    //Recepcion Servicios
    { path: "/template/recepcionservicios", component: RecepcionServicios },
    //Revision de Adquisiciones
    { path: "/template/revision-adquisiciones", component: RevisionAdquisiciones },
    { path: "/template/revision-adquisiciones/detalle", component: ResolverSolicitud },
    //Orden de pago
    { path: "/template/orden-pago", component: OrdenPago },
]

export default rutasAdquisiciones
import * as Yup from "yup";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { Col, Row, Button } from "reactstrap";
import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import DatePicker from "react-datepicker";

import Loader from "../../../components/Loader/Loader";
import { peticionesReceiver } from "../../../helpers/peticionesReceiver";
import Notification from "../../../components/Notification/Notification";
import ModalConfirmation from "../../../components/Modal/ModalConfirmation";
import { SelectTypeHeadSingle, SelectWithSearchInput } from "../../../components/GenericInputsFormik";
import TableComponentDeleteRecord from '../../../components/TableComponent/TableComponentDeleteRecord'

import "react-datepicker/dist/react-datepicker.css";
import { GetPermissions } from "../../../libs/permissions/getPermissions";

const FormHorarios = ({ crear, data, eliminar, obtener }) => {
  const [permissions, setPermissions] = useState({});
  GetPermissions(setPermissions);

  const defaultForm = {
    idScheduleRule: "",
    idJobSchedule: 0,
    keyTypeScheduleRule: 0,
    startTime: new Date(),
    endTime:new Date(),
    currentRules: [],
  };

  const history = useHistory();
  const API = peticionesReceiver();
  const header = ['Reglas', 'Hora de inicio','Hora de fin', "Eliminar"]

  const [modal, setModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true)

  const [jobs, setJobs] = useState([])
  const [rulesTemp, setRulesTemp] = useState([])
  const [otherErrors, setOtherErrors] = useState({})
  const [typeSchedule, setTypeSchedule] = useState([])
  const [allTypeSchedule, setAllTypeSchedule] = useState([])

  useEffect(() => {
    if(data) {
      getRules(data[0])
    }
    else{
      getAllData()
    }
  }, [])

  useEffect(() => {
    setTypeSchedule(allTypeSchedule)
  }, [allTypeSchedule.length > 0 && typeSchedule.length === 0])

  useEffect(() => {
    if(data && typeSchedule.length > 0){
      let typeScheduleTemp = typeSchedule
      const newRulesTemp = rulesTemp.map( it => {
        typeScheduleTemp = typeScheduleTemp.filter(item =>  item.keyTypeScheduleRule !== it.nameTypeScheduleRule)
        const nameRule = allTypeSchedule.find(item => item.keyTypeScheduleRule == it.nameTypeScheduleRule)
        return {...it,nameTypeScheduleRule:nameRule.nameTypeScheduleRule}
      })
      setTypeSchedule(typeScheduleTemp)
      setRulesTemp(newRulesTemp)
    }
  }, [typeSchedule.length > 0 && data])

  const getRules = async(nameJobSchedule) => {
    const result = await obtener(nameJobSchedule)
    if(result.success){
      const data = result.info.array
      const newRules = data.map(it => {
        return {
          keyTypeScheduleRule: it.keyTypeScheduleRule,
          startTime: new Date(it.startTime),
          endTime: new Date(it.endTime),
          idScheduleRule: it.idScheduleRule
        }
      })

      const newRulesTemp = data.map(it => {
        const newStartTime = new Date(it.startTime).toTimeString().slice(0,8)
        const newEndTime = new Date(it.endTime).toTimeString().slice(0,8)

        return { nameTypeScheduleRule: it.keyTypeScheduleRule,startTime: newStartTime ,endTime: newEndTime }
      })
      
      setFieldValue("idJobSchedule", result.info.idJobSchedule);
      setFieldValue("currentRules", newRules);
      setRulesTemp(newRulesTemp)
      getAllData()
    }
    else {
      toast(<Notification type={"consultar_servidor_errorr"} backMessage={result.message} withIcon />);
    }
  }

  const getAllData = async() => {
    const result = await Promise.all([
      getData("jobschedule",setJobs,"nameJobSchedule"),
      getData("types_schedulerules",setAllTypeSchedule,"nameTypeScheduleRule"),
    ])
    if (result.some(it => it === true)) {
      setIsLoading(false)
    }
  }

  const getData = async(table,functionUpdated,column) => {
    const params = {
      action: "select",
      table: table,
      condition: { enabled: 1 },
      order: `${column} ASC`,
    };
    const res = await API.peticion(params)
    if(res.status === 200 && res.data.code === "200"){
      functionUpdated(res.data.data)
      return true
    }
    else {
      toast(<Notification type={"consultar_error"} withIcon />);
      return false
    }
  };

  const FormSchema = Yup.object().shape({
    idJobSchedule: Yup.number()
      .moreThan(0,"Favor de seleccionar una descripción  del horario")
      .required("Favor de seleccionar una descripción  del horario"),
    currentRules: Yup.array()
    .min(1, "Favor de ingresar mínimo una regla")
    .required("Favor de ingresar mínimo una regla"),
  });

  const OnChangeSelect = async(options,name) => {
    if(name === 'keyTypeScheduleRule'){
      delete otherErrors.keyTypeScheduleRule
      setOtherErrors(otherErrors)
    }
    if(name === 'idJobSchedule'){
      const result = await obtener(options.label)
      if(result.success){
        const data = result.info.array
        let typeScheduleTemp = typeSchedule
        data.forEach(it => {
          typeScheduleTemp = typeScheduleTemp.filter(item =>  item.keyTypeScheduleRule !== it.keyTypeScheduleRule)
        });
        setTypeSchedule(typeScheduleTemp)
      }
    }
    setFieldValue([name], options.value);
  }

  const goBack = (reset) =>{
    reset()
    history.goBack()
  }

  const formatTime = (time) => {
    const currentStartTime = new Date(time).toTimeString().slice(0,8)
    const setTime = currentStartTime.split(':')
    return new Date().setHours(setTime[0],setTime[1],setTime[2],0)
  }

  const validateSchedule = () => {
    const currentStartTime = formatTime(values.startTime)
    const currentEndTime = formatTime(values.endTime)

    if(currentStartTime === currentEndTime){
      return {sameTime: true ,message: 'La hora de inicio no debe ser igual a la hora de fin' }
    }
    else if(currentEndTime < currentStartTime){
      return {sameTime: true ,message: 'La hora de fin no debe ser menor a la hora de inicio'}
    }

    else if(values.currentRules.length > 0){

      const rulesEnabled = values.currentRules.filter(it => it.deleted === true ? false : it)
      const array = rulesEnabled.length > 0 ? rulesEnabled : values.currentRules

      const sameTime = array.some(it => {
        const startTimeTemp = formatTime(it.startTime)
        const endTimeTemp = formatTime(it.endTime)
        
        if(startTimeTemp === currentStartTime){
          return true
        }
        else if(endTimeTemp ===  currentEndTime){
          return true
        }
        else if(startTimeTemp === currentEndTime){
          return true
        }
        else if(endTimeTemp === currentStartTime){
          return true
        }
        else if(startTimeTemp < currentStartTime && currentStartTime < endTimeTemp){
          return true
        }
        else if(startTimeTemp < currentEndTime &&  currentEndTime < endTimeTemp) {
          return true
        }
        else {
          return false
        }
      })

      return {sameTime,message: sameTime ? 'Hay una regla con ese horario' : ''}
    }
    else {
      return {sameTime: false ,message:''}
    }
  }

  const clearValues = () => {
    setFieldValue('keyTypeScheduleRule',0)
    setFieldValue('startTime',new Date())
    setFieldValue('endTime',new Date())
  }

  const addRule = () => {
    const validate = validateSchedule()
    if(values.keyTypeScheduleRule === 0 ){
      setOtherErrors({...otherErrors,keyTypeScheduleRule: 'Favor de seleccionar una regla'})
    }
    else if(validate.sameTime) {
      setOtherErrors({...otherErrors,endTime: validate.message})
    }
    else{
      const newEndTime = new Date(values.endTime).toTimeString().slice(0,8)
      const newStartTime = new Date(values.startTime).toTimeString().slice(0,8)
      const filter = typeSchedule.filter(it => it.keyTypeScheduleRule !== values.keyTypeScheduleRule)
      const nameRule = allTypeSchedule.find(it => it.keyTypeScheduleRule == values.keyTypeScheduleRule)

      values.currentRules.push({ keyTypeScheduleRule: values.keyTypeScheduleRule,startTime: values.startTime,endTime: values.endTime, created: true})
      rulesTemp.push({ nameTypeScheduleRule: nameRule.nameTypeScheduleRule, startTime: newStartTime,endTime: newEndTime })

      setRulesTemp(rulesTemp)
      setTypeSchedule((prevState) => (filter));
      setFieldValue('currentRules',values.currentRules)

      clearValues()
    }
  }

  const onChangeDate = (event) => {
    const { name,value } = event
    setFieldValue(name, value);
    if(otherErrors.endTime) delete otherErrors.endTime
  };

  const onChange = (e) => {
    const { name, value } = e.target
    setFieldValue([name], value);
  }

  const handleDeleteRule = (index) => {
    const nameTypeScheduleRule = rulesTemp[index].nameTypeScheduleRule
    const typesTemp = allTypeSchedule.find(it => it.nameTypeScheduleRule === nameTypeScheduleRule)
    values.currentRules[index].deleted = true

    rulesTemp.splice(index,1)
    typeSchedule.push(typesTemp)

    setRulesTemp(rulesTemp)
    setTypeSchedule(typeSchedule)
    setFieldValue('currentRules',values.currentRules);
  }

  const saveRules = async() => {
    setIsLoading(true)
    
    const params = {
      idJobSchedule: values.idJobSchedule,
    };
    const promises = values.currentRules.map(async item => {
      if(item.deleted && item.created){
        return false
      }
      else if(item.deleted){
        return await eliminar(item.idScheduleRule)
      }
      else if(item.created){
        params.keyTypeScheduleRule = item.keyTypeScheduleRule
        params.startTime = `${item.startTime.getFullYear()}-${item.startTime.getMonth()+1}-${item.startTime.getDay()+1} ${item.startTime.toTimeString().slice(0,8)}`
        params.endTime = `${item.endTime.getFullYear()}-${item.endTime.getMonth()+1}-${item.endTime.getDay()+1} ${item.endTime.toTimeString().slice(0,8)}`
        return await crear(params)
      }
      else {
        return false
      }
    })

    try{
      const response = await Promise.all(promises)
      const resultwithOutFalse = response.filter(element => element !== false)
      const resultPromise = resultwithOutFalse.length === 0 ? null : resultwithOutFalse.some(it => it.success === true ? true : false)
      
      if(resultPromise === null){
        goBack(resetForm)
        localStorage.removeItem('dataContent')
      }
      else if(resultPromise){
        toast(<Notification type={data ? "modifica_exito" :"agrega_exito"} withIcon />);
        localStorage.removeItem('dataContent')
        goBack(resetForm)
      }
      else {
        const filter = response.filter(it => it.success === false)
        toast(<Notification type={data ? "modifica_error" : "agrega_error"} backMessage={filter[0].message} withIcon />);
      }
      setIsLoading(false)
    }
    catch(error){
      toast(<Notification type={"consultar_servidor_error"} withIcon />);
      setIsLoading(false)
    }
  }

  const { handleSubmit, values, handleBlur, errors, touched, setFieldValue,resetForm } =
    useFormik({
      initialValues: defaultForm,
      onSubmit: () => setModal(true),
      validationSchema: FormSchema,
  });

  return (
    <>
      {
        isLoading ? <Loader/>
        :
        <form onSubmit={handleSubmit} className="mt-4" noValidate>
          <ModalConfirmation
            modalTitle={data ? "Editar" : "Crear"}
            modal={modal}
            setModal={setModal}
            crear={saveRules}
            editar={saveRules}
            isEdit={data ? true : false}
            values={values}
          >
            {data ? (
              <div className="d-flex justify-content-center">
                <h6>¿Está seguro de editar el registro?</h6>
              </div>
            ) : (
              <div className="d-flex justify-content-center">
                <h6>¿Desea continuar con el registro?</h6>
              </div>
            )}
          </ModalConfirmation>

          <div className="form-group">
            <SelectTypeHeadSingle
              label="Descripción del horario"
              isRequired={true}
              optionsArray={jobs}
              inputName="idJobSchedule"
              onChangeMethod={onChange}
              onBlurMethod={handleBlur}
              touched={touched.idJobSchedule}
              errors={errors.idJobSchedule}
              optionValue="idJobSchedule"
              optionName="nameJobSchedule"
              value={values.idJobSchedule}
              defaultOption="Seleccione un horario"
              isDisabled = {data ? true : false}
            />
          </div>
          {touched.currentRules && errors.currentRules && <span style={{ color: "red" }}>{errors.currentRules}</span>}

          {
            permissions.INS && values.idJobSchedule > 0 &&
            <>
              <h4>Reglas</h4>
              <Row className="mt-2">
                <Col xs={12} md={12} lg={4}>
                  <SelectTypeHeadSingle
                    label="Reglas"
                    isRequired={true}
                    optionsArray={typeSchedule}
                    inputName="keyTypeScheduleRule"
                    onChangeMethod={onChange}
                    onBlurMethod={handleBlur}
                    touched={otherErrors.keyTypeScheduleRule ? true : false}
                    errors={otherErrors.keyTypeScheduleRule}
                    optionValue="keyTypeScheduleRule"
                    optionName="nameTypeScheduleRule"
                    value={values.keyTypeScheduleRule}
                    defaultValue="Seleccione una regla"  
                  />

                </Col>

                <Col xs={12} md={12} lg={4}>
                  <label htmlFor={values.startTime}> Hora de inicio <span className="text-danger"> * </span> </label>
                  <DatePicker
                    selected = {values.startTime}
                    onChange={(date) => onChangeDate({ name: "startTime", value: date }) }
                    showTimeInput
                    showTimeSelectOnly
                    timeIntervals={1}
                    timeCaption="Time"
                    dateFormat="h:mm aa"
                    className={ "form-control" + (errors.startTime && touched.startTime ? " is-invalid" : "") }
                  />

                  {touched.startTime && errors.startTime && (
                    <span style={{ color: "red" }}>{errors.startTime}</span>
                  )}
                </Col>

                <Col xs={12} md={12} lg={4}>
                  <label htmlFor={values.endTime}> Hora de fin <span className="text-danger"> * </span> </label>
                  <DatePicker
                    selected = {values.endTime}
                    onChange={(date) => onChangeDate({ name: "endTime", value: date }) }
                    showTimeInput
                    showTimeSelectOnly
                    timeIntervals={1}
                    timeCaption="Time"
                    dateFormat="h:mm aa"
                    className={ "form-control" + (otherErrors.endTime && (otherErrors.endTime ? true : false)? " is-invalid" : "") }
                  />

                  {(otherErrors.endTime ? true : false) && otherErrors.endTime && (
                    <span style={{ color: "red" }}>{otherErrors.endTime}</span>
                  )}
                </Col>
              </Row>

              <div className="col-sm-6 order-1 order-sm-2 text-center text-sm-right mb-4">
                <Button color="primary" onClick={() =>  addRule()}> + </Button>
              </div>
            </>
          }
          {
            values.currentRules.length > 0 &&
            <TableComponentDeleteRecord
              headers = {header}
              registros = {rulesTemp}
              handleDelete = {(index) => handleDeleteRule(index)}
              permissions = {permissions}
            />
          }

          <div className="row mt-3">
            <div className="col-sm-6 order-2 order-sm-1 text-center text-sm-left mb-4">
              <Button color="danger" type="reset" onClick={() => goBack(resetForm)} > Cancelar </Button>
            </div>

            <div className="col-sm-6 order-1 order-sm-2 text-center text-sm-right mb-4">
              <Button color="success" type="submit"> Guardar </Button>
            </div>
            
          </div>

        </form>
      }
    </>
  );
};

export default FormHorarios;
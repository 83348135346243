import * as XlsxPopulate from "xlsx-populate";
import { saveAs } from "file-saver";
import { number2Alphabet } from "../../../../libs/tools/strings";
import { sumValues } from "../../../../libs/tools/arrays";

/**
 * Formateador de numeros de concurrencias
 */
const formatter = new Intl.NumberFormat("es-MX", {
    style: "currency",
    currency: "MXN",
});

/**
 * Hoja de estilos del excel
 */
const styles = {
    bold: {
        bold: true
    },
    header: {
        fill: "BFBFBF"
    },
    center: {
        horizontalAlignment: "center"
    },
    right: {
        horizontalAlignment: "right"
    }
};

/**
 * 
 * @param {Reporte entregado de la BD} report 
 * @param {Titulo (Nombre de la institución)} title 
 * @param {Parametros pasados a la BD para su consulta} data 
 * @param {configuración de headers} config 
 */
export async function savePrisma(report, title, data, config) {

    const rep = report[0]

    //Inicio de excel
    XlsxPopulate.fromBlankAsync().then(async (workbook) => {
        const sheet1 = workbook.sheet(0);

        //const hl = config.headers.length;

        //Titulo
        sheet1
            .range(`A1:G2`)
            .merged(true)
            .value("FISCALIA GENERAL DE JUSTICIA")
            .style([styles.bold, styles.center]);
        sheet1
            .range(`A3:G3`)
            .merged(true)
            .value("SAPP")
            .style([styles.bold, styles.center]);
        sheet1
            .range(`A4:G4`)
            .merged(true)
            .value(config.subTitle)
            .style([styles.bold, styles.center]);

        function toDate(date) {
            const arr = date.split("-")
            if (arr[1].length === 1) {
                arr[1] = "0" + arr[1]
            }
            return `${arr[2]}/${arr[1]}/${arr[0]}`
        }

        config.content.headersConceptos.forEach((header, index) => {
            sheet1
                .cell(`${number2Alphabet(index)}5`)
                .value(header)
                .style([styles.bold, styles.center, styles.header])
        });
        config.content.aportaciones.forEach((value, index) => {
            sheet1
                .cell(`A${index + 6}`)
                .value(value.header)
                .style(styles.bold)
            value.values.forEach((key, ind) => {
                sheet1
                    .cell(`${number2Alphabet(ind + 1)}${index + 6}`)
                    .value(key !== "" ? (ind > 0 && key !== "datePrisma" ? formatter.format(rep[key]) : toDate(rep[key])) : "")
                    .style(ind > 0 ? [styles.bold, styles.right] : [styles.bold, styles.center])
            })
        })

        sheet1
            .range(`A17:B17`)
            .merged(true)
            .value("APORTACIONES")
            .style([styles.bold, styles.right])
        sheet1
            .cell(`C17`)
            .value(formatter.format(rep[config.content.sumaAportaciones[0]]))
            .style([styles.bold, styles.right])
        sheet1
            .cell(`F17`)
            .value(formatter.format(rep[config.content.sumaAportaciones[1]]))
            .style([styles.bold, styles.right])
        sheet1
            .cell(`G17`)
            .value(formatter.format(rep[config.content.sumaAportaciones[2]]))
            .style([styles.bold, styles.right])

        config.content.cuotas.forEach((value, index) => {
            sheet1
                .cell(`A${index + 21}`)
                .value(value.header)
                .style(styles.bold)
            value.values.forEach((key, ind) => {
                sheet1
                    .cell(`${number2Alphabet(ind + 1)}${index + 21}`)
                    .value(key !== "" ? (ind > 0 && key !== "datePrisma" ? formatter.format(rep[key]) : toDate(rep[key])) : "")
                    .style(ind > 0 ? [styles.bold, styles.right] : [styles.bold, styles.center])
            })
        })
        sheet1
            .range(`A33:B33`)
            .merged(true)
            .value("CUOTAS")
            .style([styles.bold, styles.right])
        sheet1
            .cell(`C33`)
            .value(formatter.format(rep[config.content.sumaCuotas[0]]))
            .style([styles.bold, styles.right])
        sheet1
            .cell(`F33`)
            .value(formatter.format(rep[config.content.sumaCuotas[1]]))
            .style([styles.bold, styles.right])
        sheet1
            .cell(`G33`)
            .value(formatter.format(rep[config.content.sumaCuotas[2]]))
            .style([styles.bold, styles.right])
        sheet1
            .range(`A36:B36`)
            .merged(true)
            .value("DETERMINACIÓN")
            .style([styles.bold, styles.right])
        sheet1
            .cell(`C36`)
            .value(formatter.format(rep[config.content.sumaDeterminacion[0]]))
            .style([styles.bold, styles.right])
        sheet1
            .cell(`F36`)
            .value(formatter.format(rep[config.content.sumaDeterminacion[1]]))
            .style([styles.bold, styles.right])
        sheet1
            .cell(`G36`)
            .value(formatter.format(rep[config.content.sumaDeterminacion[2]]))
            .style([styles.bold, styles.right])

        const cabecerasArrays = ["CLAVE DE PROYECTO", "TOTAL", "CLAVE DE PROYECTO", "TOTAL"]
        const claves = ["1412 - SERVICIOS DE SALUD", "1413 - FONDO SOLIDARIO DE REPARTO", "1414 - APORTACIONES OBLIGATORIAS SCI", "1415 - GASTOS DE ADMINISTRACIÓN", "1416 - RIESGOS DE TRABAJO"]
        const prismas = ["aporServSalud", "aporFondoSolRep", "aporSCIOblig", "aporGastosAdmon", "aporRT"]
        const access = ["accServSalud", "accFondoSolRep", "accSCIOblig", "accGastosAdmon", "accRT"]

        let rowInd = 38;
        let acumudadoTP = 0
        let acumudadoTS = 0
        config.content.arrays.forEach((array, index) => {
            sheet1
                .range(`A${rowInd}:G${rowInd}`)
                .merged(true)
                .value(claves[index])
                .style([styles.bold, styles.center, styles.header])
            rowInd++;
            sheet1
                .range(`A${rowInd}:B${rowInd}`)
                .merged(true)
                .value("SAPP")
                .style([styles.bold, styles.center, styles.header])
            sheet1
                .range(`F${rowInd}:G${rowInd}`)
                .merged(true)
                .value("INTEGRACIÓN")
                .style([styles.bold, styles.center, styles.header])
            rowInd++;
            cabecerasArrays.forEach((header, index) => {
                sheet1
                    .cell(`${number2Alphabet(index < 2 ? index : index + 3)}${rowInd}`)
                    .value(header)
                    .style([styles.bold, styles.center, styles.header])
            });
            rowInd++;
            const arra = rep[array];
            Object.entries(arra.data).forEach(([key, obj]) => {
                sheet1
                    .cell(`A${rowInd}`)
                    .value(key)
                    .style([styles.bold, styles.center])
                sheet1
                    .cell(`B${rowInd}`)
                    .value(formatter.format(obj.valorPrimero))
                    .style(styles.right)
                sheet1
                    .cell(`F${rowInd}`)
                    .value(key)
                    .style([styles.bold, styles.center])
                sheet1
                    .cell(`G${rowInd}`)
                    .value(formatter.format(obj.valorSegundo))
                    .style(styles.right)
                rowInd++
            })
            sheet1
                .cell(`A${rowInd + 1}`)
                .value("TOTAL")
                .style([styles.bold, styles.right])
            sheet1
                .cell(`B${rowInd + 1}`)
                .value(formatter.format(arra.totalPrimero))
                .style([styles.bold, styles.right])
            acumudadoTP += Number(arra.totalPrimero);
            sheet1
                .cell(`C${rowInd}`)
                .value("PRISMA")
                .style([styles.bold, styles.center])
            sheet1
                .cell(`C${rowInd + 1}`)
                .value(formatter.format(rep[prismas[index]]))
                .style([styles.bold, styles.right])
            sheet1
                .cell(`D${rowInd}`)
                .value("DIFERENCIA")
                .style([styles.bold, styles.center])
            sheet1
                .cell(`D${rowInd + 1}`)
                .value(formatter.format(arra.diferenciaPrisma))
                .style([styles.bold, styles.right])
            sheet1
                .cell(`F${rowInd + 1}`)
                .value("TOTAL")
                .style([styles.bold, styles.right])
            sheet1
                .cell(`G${rowInd + 1}`)
                .value(formatter.format(arra.totalSegundo))
                .style([styles.bold, styles.right])
            acumudadoTS += Number(arra.totalSegundo);
            sheet1
                .cell(`I${rowInd}`)
                .value("ACCESORIOS")
                .style([styles.bold, styles.right])
            sheet1
                .cell(`I${rowInd + 1}`)
                .value(formatter.format(rep[access[index]]))
                .style([styles.bold, styles.right])
            const total = Number(arra.totalSegundo) + Number(rep[access[index]])
            sheet1
                .cell(`J${rowInd}`)
                .value("TOTAL")
                .style([styles.bold, styles.right])
            sheet1
                .cell(`J${rowInd + 1}`)
                .value(formatter.format(total))
                .style([styles.bold, styles.right])
            if (index > 0) {
                sheet1
                    .cell(`A${rowInd + 3}`)
                    .value("ACUMULADO")
                    .style([styles.bold, styles.right])
                sheet1
                    .cell(`B${rowInd + 3}`)
                    .value(formatter.format(acumudadoTP))
                    .style([styles.bold, styles.right])
                sheet1
                    .cell(`G${rowInd + 3}`)
                    .value("ACUMULADO")
                    .style([styles.bold, styles.right])
                sheet1
                    .cell(`G${rowInd + 3}`)
                    .value(formatter.format(acumudadoTS))
                    .style([styles.bold, styles.right])
            }
            rowInd += 6
        })

        sheet1
            .cell(`A${rowInd}`)
            .value("1346")
            .style([styles.bold, styles.header, styles.center])
        sheet1
            .cell(`B${rowInd}`)
            .value("TOTAL")
            .style([styles.bold, styles.header, styles.center])
        rowInd++
        sheet1
            .cell(`A${rowInd}`)
            .value("APORTACIÓN EXTRAORDINARIA")
        sheet1
            .cell(`B${rowInd}`)
            .value(formatter.format(rep.aporExtraordinaria))
            .style(styles.right)
        rowInd += 2
        sheet1
            .cell(`A${rowInd}`)
            .value("TOTAL")
            .style(styles.bold)
        sheet1
            .cell(`B${rowInd}`)
            .value(formatter.format(rep.aporExtraordinaria))
            .style([styles.bold, styles.right])
        rowInd += 3
        sheet1
            .cell(`A${rowInd}`)
            .value("PRISMA")
            .style([styles.bold, styles.header, styles.center])
        sheet1
            .cell(`B${rowInd}`)
            .value("TOTAL")
            .style([styles.bold, styles.header, styles.center])
        sheet1
            .range(`E${rowInd}:F${rowInd}`)
            .merged(true)
            .value("SAPP")
            .style([styles.bold, styles.header, styles.center])
        sheet1
            .cell(`G${rowInd}`)
            .value("TOTAL")
            .style([styles.bold, styles.header, styles.center])
        sheet1
            .cell(`J${rowInd}`)
            .value("DIFERENCIA")
            .style([styles.bold, styles.header, styles.center])

        rowInd++
        let total = Number(rep.cuotaServSalud)
        sheet1
            .cell(`A${rowInd}`)
            .value("CUOTA POR SERVICIO DE SALUD")
            .style([styles.bold, styles.header])
        sheet1
            .cell(`B${rowInd}`)
            .value(formatter.format(rep.cuotaServSalud))
            .style([styles.bold, styles.right])

        sheet1
            .range(`E${rowInd}:F${rowInd}`)
            .merged(true)
            .value("CUOTA POR SERVICIO DE SALUD")
            .style([styles.bold, styles.header])
        sheet1
            .cell(`G${rowInd}`)
            .value(formatter.format(0))
            .style([styles.bold, styles.right])
        sheet1
            .cell(`J${rowInd}`)
            .value(formatter.format(0))
            .style([styles.bold, styles.right])
        total += Number(rep.cuotaServSalud)
        rowInd++
        sheet1
            .cell(`A${rowInd}`)
            .value("CUOTA POR FONDO SOLIDARIO DE REPARTO")
            .style([styles.bold, styles.header])
        sheet1
            .cell(`B${rowInd}`)
            .value(formatter.format(rep.cuotaFondSolRep))
            .style([styles.bold, styles.right])
        sheet1
            .range(`E${rowInd}:F${rowInd}`)
            .merged(true)
            .value("CUOTA POR FONDO SOLIDARIO DE REPARTO")
            .style([styles.bold, styles.header])
        sheet1
            .cell(`G${rowInd}`)
            .value(formatter.format(0))
            .style([styles.bold, styles.right])
        sheet1
            .cell(`J${rowInd}`)
            .value(formatter.format(0))
            .style([styles.bold, styles.right])
        total += Number(rep.cuotaFondSolRep)
        rowInd++
        sheet1
            .cell(`A${rowInd}`)
            .value("CUOTA SCI OBLIGATORIO")
            .style([styles.bold, styles.header])
        sheet1
            .cell(`B${rowInd}`)
            .value(formatter.format(rep.cuotaSCIOblig))
            .style([styles.bold, styles.right])
        sheet1
            .range(`E${rowInd}:F${rowInd}`)
            .merged(true)
            .value("CUOTA SCI OBLIGATORIO")
            .style([styles.bold, styles.header])
        sheet1
            .cell(`G${rowInd}`)
            .value(formatter.format(0))
            .style([styles.bold, styles.right])
        sheet1
            .cell(`J${rowInd}`)
            .value(formatter.format(0))
            .style([styles.bold, styles.right])
        total += Number(rep.cuotaSCIOblig)
        rowInd++
        sheet1
            .cell(`A${rowInd}`)
            .value("CUOTA SCI VOLUNTARIO")
            .style([styles.bold, styles.header])
        sheet1
            .cell(`B${rowInd}`)
            .value(formatter.format(rep.cuotaSCIVol))
            .style([styles.bold, styles.right])
        sheet1
            .range(`E${rowInd}:F${rowInd}`)
            .merged(true)
            .value("CUOTA SCI VOLUNTARIO")
            .style([styles.bold, styles.header])
        sheet1
            .cell(`G${rowInd}`)
            .value(formatter.format(0))
            .style([styles.bold, styles.right])
        sheet1
            .cell(`J${rowInd}`)
            .value(formatter.format(0))
            .style([styles.bold, styles.right])
        total += Number(rep.cuotaSCIVol)
        rowInd++
        sheet1
            .cell(`A${rowInd}`)
            .value(`RETENCIONES INSTITUCIONALES
            +
            RETENCIONES A TERCEROS`)
            .style([styles.bold, styles.header])
        sheet1
            .cell(`B${rowInd}`)
            .value(formatter.format(Number(rep.retInstitucionales) + Number(rep.retTerceros)))
            .style([styles.bold, styles.right])
        sheet1
            .range(`E${rowInd}:F${rowInd}`)
            .merged(true)
            .value(`RETENCIONES INSTITUCIONALES
            +
            RETENCIONES A TERCEROS`)
            .style([styles.bold, styles.header])
        sheet1
            .cell(`G${rowInd}`)
            .value(formatter.format(0))
            .style([styles.bold, styles.right])
        sheet1
            .cell(`J${rowInd}`)
            .value(formatter.format(0))
            .style([styles.bold, styles.right])
        sheet1.row(rowInd).height(40);
        total += Number(rep.retInstitucionales) + Number(rep.retTerceros)
        rowInd++
        sheet1
            .cell(`A${rowInd}`)
            .value("TOTAL")
            .style([styles.bold, styles.header])
        sheet1
            .cell(`B${rowInd}`)
            .value(formatter.format(total))
            .style([styles.bold, styles.right])
        sheet1
            .range(`E${rowInd}:F${rowInd}`)
            .merged(true)
            .value("TOTAL")
            .style([styles.bold, styles.header])
        sheet1
            .cell(`G${rowInd}`)
            .value(formatter.format(0))
            .style([styles.bold, styles.right])
        sheet1
            .cell(`J${rowInd}`)
            .value(formatter.format(0))
            .style([styles.bold, styles.right])
        rowInd += 2
        sheet1
            .cell(`A${rowInd}`)
            .value("CUOTA EXTRAORDINARIA")
            .style([styles.bold, styles.header])
        sheet1
            .cell(`B${rowInd}`)
            .value(formatter.format(rep.cuotaExtraordinaria))
            .style([styles.bold, styles.right])
        sheet1
            .range(`E${rowInd}:F${rowInd}`)
            .merged(true)
            .value("CUOTA EXTRAORDINARIA")
            .style([styles.bold, styles.header])
        sheet1
            .cell(`G${rowInd}`)
            .value(formatter.format(rep.cuotaExtraordinaria))
            .style([styles.bold, styles.right])
        sheet1
            .cell(`J${rowInd}`)
            .value(formatter.format(0))
            .style([styles.bold, styles.right])
        rowInd += 2
        const totalPagar = total + acumudadoTP + Number(rep.aporExtraordinaria) + Number(rep.cuotaExtraordinaria)

        sheet1
            .cell(`A${rowInd}`)
            .value("TOTAL A PAGAR")
            .style([styles.bold, styles.header, styles.right])
        sheet1
            .cell(`B${rowInd}`)
            .value(formatter.format(totalPagar))
            .style([styles.bold, styles.right])
        sheet1
            .range(`E${rowInd}:F${rowInd}`)
            .merged(true)
            .value("TOTAL A PAGAR")
            .style([styles.bold, styles.header])
        sheet1
            .cell(`G${rowInd}`)
            .value(formatter.format(0))
            .style([styles.bold, styles.right])
        sheet1
            .cell(`J${rowInd}`)
            .value(formatter.format(0))
            .style([styles.bold, styles.right])
        rowInd += 2

        sheet1.column("A").width(70);
        sheet1.column("B").width(20)
        sheet1.column("C").width(30)
        sheet1.column("D").width(30)
        sheet1.column("E").width(30)
        sheet1.column("F").width(30)
        sheet1.column("G").width(30)
        sheet1.column("H").width(10)
        sheet1.column("I").width(30)
        sheet1.column("J").width(30)


        return workbook.outputAsync().then((res) => {
            saveAs(res, `${title}.xlsx`);
        });
    });
}
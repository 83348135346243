import React, { useEffect } from "react";
import { Col, Row } from "reactstrap";
import CurrencyInput from "react-currency-input-field";
import SelectStateFunds from "./Inputs/SelectStateFunds";
import SelectMonths from "./Inputs/SelectMonths";
import RadioDetalle from "./Inputs/RadioDetalle";
import { YearInput } from "../../../../components/GenericInputsFormik/YearInput";

const inpPeriod = ["23", "24", "27", "30", "43"];
const inpYear = ["23", "24", "27", "25", "30", "32", "43"];
const inpMonth = ["25", "32"];
const inpStateFund = ["30"];
const inpDetalle = ["24"];

export default function Filtro23303243({
  report,
  handleBlur,
  values,
  touched,
  errors,
  setFieldValue,
  onChange,
}) {
  useEffect(() => {
    if (report !== "") {
      setFieldValue("period", "");
      setFieldValue("year", "");
      setFieldValue("month", "");
    }
  }, [report]);

  return (
    <Row className="d-flex justify-content-around">
      <Col xs={12} md={12} lg={10}>
        <Row>
          {inpMonth.includes(report) ? (
            <Col xs={12} lg={6}>
              <SelectMonths
                errors={errors}
                handleBlur={handleBlur}
                touched={touched}
                values={values}
                setFieldValue={setFieldValue}
              />
            </Col>
          ) : null}
          {inpPeriod.includes(report) ? (
            <Col xs={12} lg={6}>
              <div className="form-group">
                <label htmlFor="period">
                  Periodo {report !== "27" ? <span className="text-danger">*</span> : null}
                </label>
                <CurrencyInput
                  id="period"
                  name="period"
                  className={
                    "form-control" +
                    (errors.period && touched.period ? " is-invalid" : "")
                  }
                  allowDecimals={false}
                  allowNegativeValue={false}
                  onValueChange={(v, n) =>
                    onChange({ target: { name: n, value: v } })
                  }
                  onBlur={handleBlur}
                  maxLength={2}
                  value={values.period}
                  min={1}
                  max={24}
                />
                {touched.period && errors.period && (
                  <span className="text-danger">{errors.period}</span>
                )}
              </div>
            </Col>
          ) : null}
          {inpYear.includes(report) ? (
            <Col xs={12} lg={6}>
              <YearInput
                label="AÑO"
                inputName="year"
                onChangeMethod={onChange}
                onBlurMethod={handleBlur}
                value={values.year}
                isRequired={report !== "27"}
                touched={touched.year}
                errors={errors.year}
              />
            </Col>
          ) : null}
        </Row>
        {inpStateFund.includes(report) ? (
          <Row>
            <Col xs={12} lg={12}>
              <SelectStateFunds
                errors={errors}
                handleBlur={handleBlur}
                touched={touched}
                values={values}
                setFieldValue={setFieldValue}
              />
            </Col>
          </Row>
        ) : null}

        {inpDetalle.includes(report) && [1, 2].includes(+values.idFormat) && (
          <RadioDetalle
            handleBlur={handleBlur}
            touched={touched}
            errors={errors}
            values={values}
            setFieldValue={setFieldValue}
          />
        )}
      </Col>
    </Row>
  );
}

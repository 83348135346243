import React, { useEffect, useState } from "react";
import { Nav, NavItem, NavLink } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";

import Widget from "../../../components/Widget/Widget";
import s from "./nav.module.scss";
import PersonalBuscar from "./PersonalBuscar";
import PersonalContactos from "./PersonalContactos";
import PersonalDatosGenerales from "./PersonalDatosGenerales";
import PersonalCuentas from "./PersonalCuentas";
import PersonalDomicilio from "./PersonalDomicilio";
import PersonalSeguro from "./PersonalSeguro";
import PersonalExperiencia from "./PersonalExperiencia";
import PersonalEscolaridad from "./PersonalEscolaridad";
import PersonalDocumentos from "./PersonalDocumentos";
import PersonalFamiliares from "./PersonalFamiliares";
import { peticionesReceiver } from "../../../helpers/peticionesReceiver";
import { getUser, saveUserInformation } from "../../../actions/auth";
import { GetPermissions } from "../../../libs/permissions/getPermissions";
import PersonalFormatos from "./PersonalFormatos";
import classNames from "classnames";
import ValidarInformacion from "./ValidarInformacion";
import ExpedienteNominPlazas from "./ExpedienteNominaPlazas";
import { doRequest } from "../../../helpers/requests";
import FullFormLoader from "../../../components/Loader/FullFormLoader";
import PersonalSeguros from "./PersonalSeguros";
import { getStaffPic } from "../../../libs/tools/images";

const defaultStaff = {
  idStaff: "",
  staffNumber: "",
  fullName: "",
  employType: "",
  nameStat: "",
  nameArea: "",
  nameJob: "",
  picture: "",
  keyEmployType: "",
};

const tabNumbers = {
  DATOSGENERALES: 2,
  ASIGNACIONDEPLAZAS: 13,
  DOMICILIOS: 3,
  CONTACTOS: 4,
  FAMILIARES: 10,
  CUENTASBANCAIRAS: 5,
  SEGURO: 6,
  SEGUROSESP: 8,
  ESCOLARIDADES: 7,
  EXPERIENCIALABORAL: 8,
  DOCUMENTOS: 9,
  FORMATOS: 11,
}

export default function Personal() {
  const [permissions, setPermissions] = useState({});
  const [permissionsTabs, setPermissionsTabs] = useState({});
  GetPermissions(setPermissions, null, 25, setPermissionsTabs);

  const API = peticionesReceiver();
  const dispatch = useDispatch();
  const { info, id } = useSelector((state) => state.userInfo);

  const [activeTab, setActiveTab] = useState(1);
  const [staff, setStaff] = useState(defaultStaff);

  const [staffValid, setStaffValid] = useState(null);
  const [upload, setUpload] = useState(null);

  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  useEffect(() => {
    if (staff.idStaff !== "" && ![9, 13].includes(activeTab) ) {
      const ltab = findTab();
      toggleTab(ltab);
    }
  }, [staff]);

  function findTab(){
    const keysTabs = Object.keys(tabNumbers);
    const keysPerm = Object.keys(permissionsTabs);
    const fnd = keysTabs.find(k => keysPerm.includes(k));
    if(fnd) return tabNumbers[fnd];
    return 1;
  }

  const resetStaff = () => {
    setStaff({ ...defaultStaff });
    toggleTab(1);
    if (staffValid !== null) {
      setStaffValid(null);
    }
  };

  useEffect(() => {
    if (upload !== null && upload.dGen & upload.dom && upload.exp && upload.fot) {
      setUpload(null);
      setStaffValid(null);
    }
  }, [upload]);

  const updateData = async () => {
    const query = {
      action: "multiselect",
      table: "staff A LEFT JOIN bookcase BC ON (BC.enabled = 1 AND BC.keyFileType = 1 AND BC.reference = A.idStaff) LEFT JOIN files F ON (F.enabled = 1 AND F.idBook = BC.idBook)",
      rows: "A.keyGenre, F.src",
      conditions: `A.enabled = 1 AND A.idStaff = ${staff.idStaff}`, 
      page: 0,
      records: 5,
      search: "",
      order: "",
    };
    const res = await doRequest("receiver/receiver.php", query, false, false);
    let image = "";
    if(res.length > 0){
      image = getStaffPic(res[0].keyGenre, res[0].src);
      setStaff((prevState) => ({
        ...prevState,
        picture: image,
      }));
      const responseId = await getUser(id);
      if (responseId == staff.idStaff) {
        dispatch(
          saveUserInformation({
            name: info.name,
            avatar: image,
          })
        );
      }
    }
        
  };

  const updateJob = async (idStaff, areafisica) => {
    const params = {
      action: "datatable",
      table: "staffview A, status B, areas C",
      rows: "A.nameArea, A.nameJob, B.nameStat, C.name AS nameFisica",
      conditions: `A.idStaff = ${idStaff} AND B.keyStat = A.keyStat AND C.idArea = ${areafisica}`,
      page: 0,
      records: 5,
      search: "",
      order: "",
    };
    const res = await doRequest("receiver/receiver.php", params, false, false);
    if (res.length > 0) {
      setStaff((prevState) => ({
        ...prevState,
        nameArea: res[0].nameArea,
        building: res[0].nameFisica,
        nameJob: res[0].nameJob,
        nameStat: res[0].nameStat,
      }));
    }
  };

  function showNav(idm) {
    if (permissionsTabs[`${idm}`]) return permissionsTabs[`${idm}`].CON
    return Object.keys(permissionsTabs).length < 1;

  }

  return (
    <Widget className="widget-p-md">
      <div className="headline-1">Personal</div>
      <Nav fill tabs style={{ overflowY: "hidden", overflowX: "auto" }}>
        <NavItem active={activeTab === 1}>
          <NavLink
            href="#"
            active={activeTab === 1}
            onClick={() => {
              toggleTab(1);
              setStaffValid(null);
            }}
            disabled={staff.idStaff === "" ? false : true}
            className={activeTab === 1 ? "text-primary" : ""}
          >
            <i className={s.navIco + " fa fa-user"} />
            Buscar empleado
          </NavLink>
        </NavItem>
        {activeTab === 12 && (
          <NavItem active={activeTab === 12}>
            <NavLink
              href="#"
              active={activeTab === 12}
              onClick={() => toggleTab(12)}
              className={activeTab === 12 ? "text-primary" : ""}
            >
              <i className={s.navIco + " fa fa-list-alt"} />
              Validar información
            </NavLink>
          </NavItem>
        )}
        {showNav("DATOSGENERALES") && (
          <NavItem active={activeTab === 2}>
            <NavLink
              href="#"
              active={activeTab === 2}
              onClick={() => toggleTab(2)}
              disabled={staff.idStaff === "" ? !permissions.INS : false}
              className={activeTab === 2 ? "text-primary" : ""}
            >
              <i className={s.navIco + " fa fa-id-card-o"} />
              Datos generales
            </NavLink>
          </NavItem>
        )}
        {showNav("DOMICILIOS") && (
          <NavItem active={activeTab === 3}>
            <NavLink
              href="#"
              active={activeTab === 3}
              onClick={() => toggleTab(3)}
              disabled={staff.idStaff === "" ? true : false}
              className={activeTab === 3 ? "text-primary" : ""}
            >
              <i className={s.navIco + " fa fa-address-book-o"} />
              Domicilios
            </NavLink>
          </NavItem>
        )}
        {showNav("CONTACTOS") && (
          <NavItem active={activeTab === 4}>
            <NavLink
              href="#"
              active={activeTab === 4}
              onClick={() => toggleTab(4)}
              disabled={staff.idStaff === "" ? true : false}
              className={activeTab === 4 ? "text-primary" : ""}
            >
              <i className={s.navIco + " fa fa-phone"} />
              Contactos
            </NavLink>
          </NavItem>
        )}
        {showNav("FAMILIARES") && (
          <NavItem active={activeTab === 10}>
            <NavLink
              href="#"
              active={activeTab === 10}
              onClick={() => toggleTab(10)}
              disabled={staff.idStaff === "" ? true : false}
              className={activeTab === 10 ? "text-primary" : ""}
            >
              <i className={s.navIco + " fa fa-users"} />
              Familiares
            </NavLink>
          </NavItem>
        )}
        {showNav("CUENTASBANCAIRAS") && (
          <NavItem active={activeTab === 5}>
            <NavLink
              href="#"
              active={activeTab === 5}
              onClick={() => toggleTab(5)}
              disabled={staff.idStaff === "" ? true : false}
              className={activeTab === 5 ? "text-primary" : ""}
            >
              <i className={s.navIco + " fa fa-credit-card"} />
              Cuentas bancarias
            </NavLink>
          </NavItem>
        )}
        {showNav("ESCOLARIDADES") && (
          <NavItem active={activeTab === 7}>
            <NavLink
              href="#"
              active={activeTab === 7}
              onClick={() => toggleTab(7)}
              disabled={staff.idStaff === "" ? true : false}
              className={activeTab === 7 ? "text-primary" : ""}
            >
              <i className={s.navIco + " fa fa-graduation-cap"} />
              Escolaridad
            </NavLink>
          </NavItem>
        )}
        {showNav("EXPERIENCIALABORAL") && (
          <NavItem active={activeTab === 8}>
            <NavLink
              href="#"
              active={activeTab === 8}
              onClick={() => toggleTab(8)}
              disabled={staff.idStaff === "" ? true : false}
              className={activeTab === 8 ? "text-primary" : ""}
            >
              <i className={s.navIco + " fa fa-building-o"} />
              Experiencia
            </NavLink>
          </NavItem>
        )}
        {showNav("ASIGNACIONDEPLAZAS") && (
          <NavItem active={activeTab === 13}>
            <NavLink
              href="#"
              active={activeTab === 13}
              onClick={() => toggleTab(13)}
              disabled={staff.idStaff === "" ? true : false}
              className={activeTab === 13 ? "text-primary" : ""}
            >
              <i className={s.navIco + " fa fa-id-card-o"} />
              Asignación de plazas
            </NavLink>
          </NavItem>
        )}
        {showNav("SEGUROSESP") && (
          <NavItem active={activeTab === 8}>
            <NavLink
              href="#"
              active={activeTab === 14}
              onClick={() => toggleTab(14)}
              disabled={staff.idStaff === "" ? true : false}
              className={activeTab === 14 ? "text-primary" : ""}
            >
              <i className={s.navIco + " eva eva-shield-outline"} />
              Seguros
            </NavLink>
          </NavItem>
        )}
        {showNav("SEGURO") && (
          <NavItem active={activeTab === 6}>
            <NavLink
              href="#"
              active={activeTab === 6}
              onClick={() => toggleTab(6)}
              disabled={staff.idStaff === "" ? true : false}
              className={activeTab === 6 ? "text-primary" : ""}
            >
              <i className={s.navIco + " fa fa-hospital-o"} />
              ISSEMYM
            </NavLink>
          </NavItem>
        )}
        {showNav("DOCUMENTOS") && (
          <NavItem active={activeTab === 9}>
            <NavLink
              href="#"
              active={activeTab === 9}
              onClick={() => toggleTab(9)}
              disabled={staff.idStaff === "" ? true : false}
              className={activeTab === 9 ? "text-primary" : ""}
            >
              <i className={s.navIco + " fa fa-file-text-o"} />
              Documentos
            </NavLink>
          </NavItem>
        )}
        {["", "EMPLEADO"].includes(staff.employType) && showNav("FORMATOS") && (
          <NavItem active={activeTab === 11}>
            <NavLink
              href="#"
              active={activeTab === 11}
              onClick={() => toggleTab(11)}
              disabled={staff.idStaff === "" ? true : false}
              className={activeTab === 11 ? "text-primary" : ""}
            >
              <i className={classNames(s.navIco, "fa fa-file-pdf-o")} />
              Formatos
            </NavLink>
          </NavItem>
        )}
      </Nav>
      <div className="mt-4">
        {staff.idStaff !== "" && activeTab !== 1 && (
          <div className="d-flex flex-md-row flex-column-reverse justify-content-md-between justify-content-center">
            <div className="d-flex flex-md-row flex-column">
              <div className="d-flex justify-content-center">
                {staff.picture !== "" && (
                  <img src={staff.picture} className="img-thumbnail w-100 exp-photo" alt="employe" style={{minWidth: "150px"}}/>
                )}
              </div>
              <div className="text-dark mt-2 mt-md-0 pl-0 pl-md-2">
                <p><strong>No. Empleado: </strong>{ staff.staffNumber}</p>
                <p><strong>Nombre: </strong>{ staff.fullName}</p>
                <p><strong>Puesto Laboral: </strong>{ staff.nameJob || "POR ASIGNAR"}</p>
                <p><strong>Adscripción Nominal: </strong>{ staff.nameArea || "POR ASIGNAR"}</p>
                <p><strong>Adscripción Fisica: </strong>{ staff.building || "POR ASIGNAR"}</p>
                <p><strong>Estatus: </strong>{ staff.nameStat}</p>
              </div>
            </div>
            <div className="my-2 my-md-0 text-center">
              <button className="btn text-light btn-block" style={{ backgroundColor: "#cc0a44", border: "none" }} onClick={resetStaff} >
                Cerrar expediente
              </button>
            </div>
          </div>
        )}
        {activeTab === 1 ? (
          <PersonalBuscar
            setStaff={setStaff}
            toggleTab={toggleTab}
            permissions={permissions}
          />
        ) : activeTab === 2 ? (
          <PersonalDatosGenerales
            idStaff={staff.idStaff}
            toggleTab={toggleTab}
            staff={staff}
            setStaff={setStaff}
            permissions={permissionsTabs.DATOSGENERALES}
            staffValid={staffValid}
            setStaffValid={setStaffValid}
            setActiveTab={setActiveTab}
            setUpload={setUpload}
            upload={upload}
          />
        ) : activeTab === 3 ? (
          <PersonalDomicilio
            idStaff={staff.idStaff}
            permissions={permissionsTabs.DOMICILIOS}
            staffValid={staffValid}
            setStaffValid={setStaffValid}
            setActiveTab={setActiveTab}
            setUpload={setUpload}
            upload={upload}
          />
        ) : activeTab === 4 ? (
          <PersonalContactos
            idStaff={staff.idStaff}
            fullName={staff.fullName}
            permissions={permissionsTabs.CONTACTOS}
          />
        ) : activeTab === 10 ? (
          <PersonalFamiliares
            idStaff={staff.idStaff}
            permissions={permissionsTabs.FAMILIARES}
          />
        ) : activeTab === 5 ? (
          <PersonalCuentas idStaff={staff.idStaff} permissions={permissionsTabs.CUENTASBANCAIRAS} />
        ) : activeTab === 6 ? (
          <PersonalSeguro idStaff={staff.idStaff} permissions={permissionsTabs.SEGURO} />
        ) : activeTab === 7 ? (
          <PersonalEscolaridad
            idStaff={staff.idStaff}
            permissions={permissionsTabs.ESCOLARIDADES}
          />
        ) : activeTab === 8 ? (
          <PersonalExperiencia
            idStaff={staff.idStaff}
            staffValid={staffValid}
            setUpload={setUpload}
            upload={upload}
            setActiveTab={setActiveTab}
            permissions={permissionsTabs.EXPERIENCIALABORAL}
          />
        ) : activeTab === 9 ? (
          <PersonalDocumentos
            idStaff={staff.idStaff}
            updateData={updateData}
            staffValid={staffValid}
            upload={upload}
            setUpload={setUpload}
            permissions={permissionsTabs.DOCUMENTOS}
          />
        ) : activeTab === 11 ? (
          <PersonalFormatos idStaff={staff.idStaff} permissions={permissionsTabs.FORMATOS} />
        ) : activeTab === 12 ? (
          <ValidarInformacion
            setStaffValid={setStaffValid}
            setActiveTab={setActiveTab}
            setUpload={setUpload}
          />
        ) : activeTab === 13 ? (
          <ExpedienteNominPlazas
            idStaff={staff.idStaff}
            updateData={updateJob}
            permissions={permissionsTabs.ASIGNACIONDEPLAZAS}
          />
        ) : (
          <PersonalSeguros
            idStaff={staff.idStaff}
            permissions={permissionsTabs.SEGUROSESP}
            idModule={25}
          />
        )}
      </div>
      <FullFormLoader show={Object.keys(permissionsTabs).length < 1} />
    </Widget>
  );
}

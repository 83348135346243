
import React from "react";
import Widget from "../../../../components/Widget/Widget";
import FormPrestamos from "../FormPrestamos";
import { createPrestamo } from "../functions";

export const CrearPrestamo = (props) => {

  return (
    <Widget className="widget-p-md">
        <h3>Crear tipo de prestamo</h3>
        <FormPrestamos crear={createPrestamo}/>
    </Widget>
  )
}

export default CrearPrestamo;
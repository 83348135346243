import React from "react";
import DatePicker from "react-datepicker";

export const YearInput = (props) => {
  const {
    label,
    inputName,
    onChangeMethod,
    onBlurMethod,
    value,
    isRequired,
    touched,
    errors,
    isDisabled,
    isMin,
    isMax
  } = props;
  return (
    <div className="form-group">
      <label htmlFor={inputName}>
        {label} {isRequired && <span className="text-danger">*</span>}
      </label>
      <DatePicker
        name={inputName}
        id={inputName}
        minDate={isMin? new Date(`${isMin}`) : null}
        maxDate={isMax? new Date(`${isMax}`) : null}
        selected={value}
        onChange={(date) => onChangeMethod({ target: { name: inputName, value: date } })}
        onBlur={onBlurMethod}
        showYearPicker
        dateFormat="yyyy"
        yearItemNumber={6}
        className={
          " form-control " +
          (errors && touched? " is-invalid" : "")
        }
        disabled={isDisabled? true : undefined}
        isClearable={!!value && !isDisabled}
      />
      {touched && errors && (
        <span style={{ color: "red" }}>{errors}</span>
      )}
    </div>
  );
};

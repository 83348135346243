import Notification from "../../../components/Notification/Notification";
import { toast } from "react-toastify";
import axios from "axios";
import { peticionEncript } from "../../../helpers/peticionesEncripted";

export const createJobPosition = async (JobPosition) => {
  if(JobPosition){
    const params = {
      action: "saveJobPosition",
      rows: JobPosition,
      validate: [["keyPositionType", "keyJob", "idSalary","idArea","idBuilding","numberJobPositions","notes"]]
    }; 
    const finalData = peticionEncript(params)
    try{
      const res = await axios.post(`${process.env.REACT_APP_API}app/facades/jobs/jobsF.php`, finalData)
      if (res.status === 200 && res.data.status === "success") {
        toast(<Notification type={"agrega_exito"} withIcon />);
        window.location.href = "#/template/plazas-laborales"
      }
      else{
        toast(<Notification type={"agrega_error"} backMessage={res.data.message} withIcon />);
      }
    }
    catch(err) {
      toast(<Notification type={"consultar_servidor_error"} withIcon />);
    }
  }
};

 export const updateJobPosition = async (JobPosition, reset) => {
    if (JobPosition) {
      const params = {
        action: "saveJobPosition",
        rows: JobPosition,
        condition: {
          idJobPosition: JobPosition.idJobPosition,
        },
        validate: [["keyPositionType", "keyJob", "idSalary","idArea","idBuilding","numberJobPositions","notes"]]
      };
      const finalData = peticionEncript(params)
      try{
        const res = await axios.post(`${process.env.REACT_APP_API}app/facades/jobs/jobsF.php`, finalData)
        if (res.status === 200 && res.data.status === "success") {
          toast(<Notification type={"modifica_exito"} withIcon />);
          if(reset)reset(res.data.data.data[0]);
          else window.location.href = "#/template/plazas-laborales"
        }
        else{
          toast(<Notification type={"modifica_error"} backMessage={res.data.message} withIcon />);
        }
      }
      catch(err) {
        toast(<Notification type={"consultar_servidor_error"} withIcon />);
      }
    }
  };
import { useSelector } from "react-redux";
import Widget from "../../../components/Widget/Widget";
import { GetPermissions } from "../../../libs/permissions/getPermissions";
import { useEffect, useState } from "react";
import { Button } from "reactstrap";
import Consulta from "./Consulta";
import Detalle from "./Detalle";
import { findEmployee } from "../../../helpers/requests";

export default function RecepcionServicios(){
    const [permissions, setPermissions] = useState({ LOD: true });
    GetPermissions(setPermissions);

    const [info, setInfo] = useState({ id: "" });
    const { profileType, id } = useSelector((state) => state.userInfo);
    const idArea = profileType.selectProfile.idArea;
    const [idStaff, setIdStaff] = useState(null);

    useEffect(()=>{
        getStaff();
    }, []);
    
  async function getStaff(){
    const emp = await findEmployee(id);
    if(emp){
        setIdStaff(emp.idStaff);
    }
  }

    return(
        <Widget className="widget-p-md">
        <div className="mt-2">
            <h3>Recepción de Servicios</h3>
            {
                info.id &&
                <div>
                    <div className="d-flex flex-md-row flex-column justify-content-md-between">
                        <Button onClick={()=>setInfo({id: ""})} className="mt-1">Regresar</Button>
                    </div>
                    <div className="mt-3">
                        <p className="mt-1">
                            <strong>Proveedor: </strong> {info.provedor}
                        </p>
                        <p className="mt-1">
                            <strong>RFC: </strong> {info.rfc}
                        </p>
                        <p className="mt-1">
                            <strong>Compra: </strong> {info.compra}
                        </p>
                    </div>
                </div>
            }

            {
                info.id ?
                <Detalle permissions={permissions} info={info} idStaff={idStaff} idArea={idArea}/> :
                permissions.CON && <Consulta idArea={idArea} setInfo={setInfo}/>
            }
        </div>

        </Widget>
    )
}
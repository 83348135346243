import { useFormik } from "formik";
import * as Yup from "yup";
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Col, Row, Button, Alert } from "reactstrap";
import { GoogleMap, useJsApiLoader } from "@react-google-maps/api";
import { Marker } from "@react-google-maps/api";
import { toast } from "react-toastify";
import Notification from "../../../components/Notification/Notification";
import Loader from "../../../components/Loader/Loader";
import Geocode from "react-geocode";
import ModalConfirmation from "../../../components/Modal/ModalConfirmation";
import { peticionesReceiver } from "../../../helpers/peticionesReceiver";
import { SelectTypeHeadSingle } from "../../../components/GenericInputsFormik";

const containerStyle = {
  width: "700px",
  height: "400px",
};

const centerMx = {
  lat: 20.0,
  lng: -100.0,
};

const gmap = process.env.REACT_APP_GMAP;

const FormSchema = Yup.object().shape({
  keyCountry: Yup.string().required("Seleccione un país"),
  keyState: Yup.string().required("Seleccione una ciudad"),
  keyCounty: Yup.string().required("Seleccione un municipio"),
  keyCity: Yup.string().required("Seleccione una localidad"),
  nameBuilding: Yup.string()
    .min(2, "El nombre debe contener al menos 2 letras")
    .max(250, "El nombre ingresado es muy largo")
    .required("Ingrese un nombre"),
  streetName: Yup.string()
    .min(5, "La calle debe tener al menos 5 caracteres")
    .max(100, "El nombre de la calle es muy largo")
    .required("Ingrese una calle"),
  subStreet: Yup.string()
    .min(3, "El nombre de la colonia debe tener al menos e caracteres")
    .max(100, "El nombre de la colonia es muy largo")
    .required("Ingrese una colonia"),
  extNumber: Yup.string()
    .max(45, "Número exterior demasiado largo")
    .required("Ingrese un número exterior"),
  intNumber: Yup.string().max(45, "Número interior demasiado largo"),
  zipCode: Yup.string()
    .min(3, "Código postal inválido")
    .max(10, "Código postal inválido")
    .required("Ingrese un código postal"),
  latitude: Yup.number().required(
    "No se ha establecido una localización en el mapa"
  ),
  longitude: Yup.string().required("Seleccione una localización en el mapa"),
});

const FormEdificio = ({ crear, data, editar }) => {
  const API = peticionesReceiver();
  const history = useHistory();
  const [selectsData, setSelectsData] = useState({
    paisesData: [],
    estadosData: [],
    municipiosData: [],
    ciudadesData: [],
  });
  const [finalData, setFinalData] = useState("");
  const [form, setForm] = useState({
    idBuilding: "0",
    keyCountry: "",
    keyState: "",
    keyCounty: "",
    keyCity: "",
    nameBuilding: "",
    streetName: "",
    subStreet: "",
    intNumber: "",
    extNumber: "",
    zipCode: "",
    latitude: "",
    longitude: "",
  });
  const [marker, setMarker] = useState();
  const [center, setCenter] = useState(centerMx);
  const [loading, setLoading] = useState(true);
  const [modal, setModal] = useState(false);

  const getPaises = async () => {
    const params = {
      action: "select",
      table: "countries",
      order: "nameCountry ASC",
    };
    await API.peticion(params)
      .then((res) => {
        if (res.status === 200 && res.data.code === "200") {
          setSelectsData((prevState) => ({
            ...prevState,
            paisesData: res.data.data,
            estadosData: [],
            municipiosData: [],
            ciudadesData: [],
          }));
          if (data) {
            setForm((prevState) => ({
              ...prevState,
              keyCountry: data[13],
            }));
          }
        } else {
          toast(
            <Notification
              type={"consultar_error"}
              backMessage={res.data.message}
              withIcon
            />
          );
        }
      })
      .catch((err) => {
        toast(<Notification type={"consultar_servidor_error"} withIcon />);
      });
  };

  const getEstados = async (idPais) => {
    const params = {
      action: "select",
      table: "states",
      condition: {
        keyCountry: idPais,
      },
      order: "nameState ASC",
    };
    await API.peticion(params)
      .then((res) => {
        if (res.status === 200 && res.data.code === "200") {
          setSelectsData((prevState) => ({
            ...prevState,
            estadosData: res.data.data,
            municipiosData: [],
            ciudadesData: [],
          }));
          if (data) {
            setForm((prevState) => ({
              ...prevState,
              keyState: data[12],
            }));
          }
        } else {
          toast(
            <Notification
              type={"consultar_error"}
              backMessage={res.data.message}
              withIcon
            />
          );
        }
      })
      .catch((err) => {
        toast(<Notification type={"consultar_servidor_error"} withIcon />);
      });
  };

  const getMunicipios = async (idEstado) => {
    const params = {
      action: "select",
      table: "counties",
      condition: {
        enabled: 1,
        keyState: idEstado,
      },
      order: "nameCounty ASC",
    };
    await API.peticion(params)
      .then((res) => {
        if (res.status === 200 && res.data.code === "200") {
          setSelectsData((prevState) => ({
            ...prevState,
            municipiosData: res.data.data,
            ciudadesData: [],
          }));
          if (data) {
            setForm((prevState) => ({
              ...prevState,
              keyCounty: data[11],
            }));
          }
        } else {
          toast(
            <Notification
              type={"consultar_error"}
              backMessage={res.data.message}
              withIcon
            />
          );
        }
      })
      .catch((err) => {
        toast(<Notification type={"consultar_servidor_error"} withIcon />);
      });
  };

  const getCiudades = async (idMunicipio) => {
    const params = {
      action: "select",
      table: "cities",
      condition: {
        enabled: 1,
        keyCounty: idMunicipio,
      },
      order: "nameCity ASC",
    };
    await API.peticion(params)
      .then((res) => {
        if (res.status === 200 && res.data.code === "200") {
          setSelectsData((prevState) => ({
            ...prevState,
            ciudadesData: res.data.data,
          }));
          if (data) {
            setForm((prevState) => ({
              ...prevState,
              keyCity: data[10],
            }));
          }
          setLoading(false);
        } else {
          toast(
            <Notification
              type={"consultar_error"}
              backMessage={res.data.message}
              withIcon
            />
          );
        }
      })
      .catch((err) => {
        toast(<Notification type={"consultar_servidor_error"} withIcon />);
      });
  };

  useEffect(() => {
    if (data) {
      setForm((prevState) => ({
        ...prevState,
        idBuilding: data[0],
        nameBuilding: data[1],
        streetName: data[2],
        subStreet: data[3],
        intNumber: data[9],
        extNumber: data[4],
        zipCode: data[5],
        latitude: data[7],
        longitude: data[8],
      }));
      setMarker({ lat: +data[7], lng: +data[8] });
      setCenter({ lat: +data[7], lng: +data[8] });
    }
    getPaises();
    Geocode.setApiKey(gmap);
    Geocode.setLocationType("ROOFTOP");
    Geocode.setRegion("mx");
    setLoading(false);
  }, []);

  useEffect(() => {
    if (data && form.keyCountry) {
      getEstados(form.keyCountry);
    }
  }, [form.keyCountry]);

  useEffect(() => {
    if (data && form.keyState) {
      getMunicipios(form.keyState);
    }
  }, [form.keyState]);

  useEffect(() => {
    if (data && form.keyCounty) {
      getCiudades(form.keyCounty);
    }
  }, [form.keyCounty]);

  const searchLocation = async (values) => {
    if (
      values.keyState &&
      values.keyCounty &&
      values.keyCity &&
      values.subStreet &&
      values.streetName &&
      values.zipCode &&
      values.extNumber
    ) {
      const address =
        values.streetName +
        " " +
        values.extNumber +
        ", " +
        values.zipCode +
        ", " +
        values.subStreet +
        ", " +
        findName(values.keyCity, "ciudades") +
        ", " +
        findName(values.keyState, "estados");
      await Geocode.fromAddress(address).then(
        (response) => {
          if (response.results.length !== 0) {
            const location = response.results[0].geometry.location;
            setFieldValue("latitude", location.lat);
            setFieldValue("longitude", location.lng);
            setMarker(location);
            setCenter(location);
          } else {
            toast("No se encontraron resultados");
          }
        },
        (error) => {
          toast(<Alert color="danger">No se encontraron ubicaciones</Alert>);
        }
      );
    } else {
      toast(
        <Alert color="danger">
          Selecione un país, estado, municipio, localidad, colonia y calle
        </Alert>
      );
    }
  };

  const onClick = (event) => {
    setMarker(event.latLng.toJSON());
  };

  const findName = (id, hookPorBuscar) => {
    var registro = "";
    if (hookPorBuscar === "ciudades") {
      registro = selectsData.ciudadesData.find(
        (e) => e.keyCity === parseInt(id)
      );
      registro = registro.nameCity;
    } else {
      registro = selectsData.estadosData.find(
        (e) => e.keyState === parseInt(id)
      );
      registro = registro.nameState;
    }
    return registro;
  };

  const onChange = (e) => {
    if (e.target.name === "keyCountry") {
      getEstados(e.target.value);
    } else if (e.target.name === "keyState") {
      getMunicipios(e.target.value);
    } else if (e.target.name === "keyCounty") {
      getCiudades(e.target.value);
    }
    setFieldValue([e.target.name], e.target.value);
  };

  const enviaDatos = () => {
    setFinalData((prevState) => ({
      ...prevState,
      idBuilding: values.idBuilding,
      keyCity: values.keyCity,
      nameBuilding: values.nameBuilding,
      streetName: values.streetName,
      subStreet: values.subStreet,
      intNumber: values.intNumber,
      extNumber: values.extNumber,
      zipCode: values.zipCode,
      latitude: values.latitude,
      longitude: values.longitude,
    }));
    setModal(true);
  };

  const { handleSubmit, values, handleBlur, errors, touched, setFieldValue } =
    useFormik({
      initialValues: form,
      onSubmit: (values) => {
        enviaDatos();
      },
      validationSchema: FormSchema,
      enableReinitialize: true,
    });

  const renderMap = () => {
    return (
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        zoom={15}
        onClick={onClick}
      >
        <Marker position={marker} />
      </GoogleMap>
    );
  };

  const { isLoaded, loadError } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: gmap,
  });

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <form onSubmit={handleSubmit}>
          <ModalConfirmation
            modalTitle={data ? "Editar" : "Crear"}
            modal={modal}
            setModal={setModal}
            crear={crear}
            editar={editar}
            isEdit={data ? true : false}
            values={finalData}
          >
            {data ? (
              <div className="d-flex justify-content-center">
                <h6>¿Está seguro de editar el registro?</h6>
              </div>
            ) : (
              <div className="d-flex justify-content-center">
                <h6>¿Desea continuar con el registro?</h6>
              </div>
            )}
          </ModalConfirmation>
          <Row>
            <Col xs="12" lg="6">
              <SelectTypeHeadSingle
                label="Pais"
                inputName="keyCountry"
                onChangeMethod={onChange}
                onBlurMethod={handleBlur}
                value={values.keyCountry}
                isRequired={true}
                touched={touched.keyCountry}
                errors={errors.keyCountry}
                optionsArray={selectsData.paisesData}
                optionValue="keyCountry"
                optionName="nameCountry"
                defaultOption="Seleccione un pais"
              />

              {/* <div className="form-group">
                <label htmlFor="keyCountry">
                  País<span className="text-danger">*</span>
                </label>
                <select
                  name="keyCountry"
                  className={
                    "form-control" +
                    (errors.keyCountry && touched.keyCountry
                      ? " is-invalid"
                      : "")
                  }
                  onBlur={handleBlur}
                  onChange={onChange}
                  value={values.keyCountry}
                >
                  <option value="">Selecciona un pais</option>
                  {selectsData.paisesData.length > 0 &&
                    selectsData.paisesData.map((e, i) => {
                      return (
                        <option value={e.keyCountry} key={"po"+i}>{e.nameCountry}</option>
                      );
                    })}
                </select>
                {touched.keyCountry && errors.keyCountry && (
                  <span style={{ color: "red" }}>{errors.keyCountry}</span>
                )}
              </div> */}
            </Col>
            <Col xs="12" lg="6">
              <SelectTypeHeadSingle
                label="Estado"
                inputName="keyState"
                onChangeMethod={onChange}
                onBlurMethod={handleBlur}
                value={values.keyState}
                isRequired={true}
                touched={touched.keyState}
                errors={errors.keyState}
                optionsArray={selectsData.estadosData}
                optionValue="keyState"
                optionName="nameState"
                defaultOption="Seleccione un estado"
              />
              {/* <div className="form-group">
                <label htmlFor="keyCountry">
                  Estado<span className="text-danger">*</span>
                </label>
                <select
                  name="keyState"
                  className={
                    "form-control" +
                    (errors.keyState && touched.keyState ? " is-invalid" : "")
                  }
                  onBlur={handleBlur}
                  onChange={onChange}
                  value={values.keyState}
                >
                  <option value="">Selecciona un estado</option>
                  {selectsData.estadosData.length > 0 &&
                    selectsData.estadosData.map((e, i) => {
                      return (
                        <option value={e.keyState} key={"eo" + i}>
                          {e.nameState}
                        </option>
                      );
                    })}
                </select>
                {touched.keyState && errors.keyState && (
                  <span style={{ color: "red" }}>{errors.keyState}</span>
                )}
              </div> */}
            </Col>
          </Row>
          <Row>
            <Col xs="12" lg="6">
              <SelectTypeHeadSingle
                label="Municipio"
                inputName="keyCounty"
                onChangeMethod={onChange}
                onBlurMethod={handleBlur}
                value={values.keyCounty}
                isRequired={true}
                touched={touched.keyCounty}
                errors={errors.keyCounty}
                optionsArray={selectsData.municipiosData}
                optionValue="keyCounty"
                optionName="nameCounty"
                defaultOption="Seleccione un municipio"
              />
              {/* <div className="form-group">
                <label htmlFor="keyCountry">
                  Municipio<span className="text-danger">*</span>
                </label>
                <select
                  name="keyCounty"
                  className={
                    "form-control" +
                    (errors.keyCounty && touched.keyCounty ? " is-invalid" : "")
                  }
                  onBlur={handleBlur}
                  onChange={onChange}
                  value={values.keyCounty}
                >
                  <option value="">Selecciona un municipio</option>
                  {selectsData.municipiosData.length > 0 &&
                    selectsData.municipiosData.map((s, i) => {
                      return (
                        <option value={s.keyCounty} key={"mo" + i}>
                          {s.nameCounty}
                        </option>
                      );
                    })}
                </select>
                {touched.keyCounty && errors.keyCounty && (
                  <span style={{ color: "red" }}>{errors.keyCounty}</span>
                )}
              </div> */}
            </Col>
            <Col xs="12" lg="6">
              <SelectTypeHeadSingle
                label="Ciudad"
                inputName="keyCity"
                onChangeMethod={onChange}
                onBlurMethod={handleBlur}
                value={values.keyCity}
                isRequired={true}
                touched={touched.keyCity}
                errors={errors.keyCity}
                optionsArray={selectsData.ciudadesData}
                optionValue="keyCity"
                optionName="nameCity"
                defaultOption="Seleccione un ciudad"
              />
              {/* <div className="form-group">
                <label htmlFor="keyCountry">
                  Ciudad<span className="text-danger">*</span>
                </label>
                <select
                  name="keyCity"
                  className={
                    "form-control" +
                    (errors.keyCity && touched.keyCity ? " is-invalid" : "")
                  }
                  onBlur={handleBlur}
                  onChange={onChange}
                  value={values.keyCity}
                >
                  <option value="">Selecciona una ciudad</option>
                  {selectsData.ciudadesData.length > 0 &&
                    selectsData.ciudadesData.map((s, i) => {
                      return (
                        <option value={s.keyCity} key={"co" + i}>
                          {s.nameCity}
                        </option>
                      );
                    })}
                </select>
                {touched.keyCity && errors.keyCity && (
                  <span style={{ color: "red" }}>{errors.keyCity}</span>
                )}
              </div> */}
            </Col>
          </Row>
          <Row>
            <Col xs="12" lg="6">
              <div className="form-group">
                <label htmlFor="subStreet">
                  Colonia<span className="text-danger">*</span>
                </label>
                <input
                  name="subStreet"
                  type="text"
                  className={
                    "form-control" +
                    (errors.subStreet && touched.subStreet ? " is-invalid" : "")
                  }
                  onChange={onChange}
                  value={values.subStreet}
                />
                {touched.subStreet && errors.subStreet && (
                  <span style={{ color: "red" }}>{errors.subStreet}</span>
                )}
              </div>
            </Col>
            <Col xs="12" lg="6">
              <div className="form-group">
                <label htmlFor="streetName">
                  Calle<span className="text-danger">*</span>
                </label>
                <input
                  name="streetName"
                  type="text"
                  className={
                    "form-control" +
                    (errors.streetName && touched.streetName
                      ? " is-invalid"
                      : "")
                  }
                  onChange={onChange}
                  value={values.streetName}
                />
                {touched.streetName && errors.streetName && (
                  <span style={{ color: "red" }}>{errors.streetName}</span>
                )}
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs="12" lg="12">
              <div className="form-group">
                <label htmlFor="nameBuilding">
                  Nombre de edificio<span className="text-danger">*</span>
                </label>
                <input
                  name="nameBuilding"
                  type="text"
                  className={
                    "form-control" +
                    (errors.nameBuilding && touched.nameBuilding
                      ? " is-invalid"
                      : "")
                  }
                  onChange={onChange}
                  value={values.nameBuilding}
                />
                {touched.nameBuilding && errors.nameBuilding && (
                  <span style={{ color: "red" }}>{errors.nameBuilding}</span>
                )}
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs="12" lg="4">
              <div className="form-group">
                <label htmlFor="extNumber">
                  Número exterior<span className="text-danger">*</span>
                </label>
                <input
                  name="extNumber"
                  type="text"
                  className={
                    "form-control" +
                    (errors.extNumber && touched.extNumber ? " is-invalid" : "")
                  }
                  onChange={onChange}
                  value={values.extNumber}
                />
                {touched.extNumber && errors.extNumber && (
                  <span style={{ color: "red" }}>{errors.extNumber}</span>
                )}
              </div>
            </Col>
            <Col xs="12" lg="4">
              <div className="form-group">
                <label htmlFor="intNumber">Número interior</label>
                <input
                  name="intNumber"
                  type="text"
                  className={
                    "form-control" +
                    (errors.intNumber && touched.intNumber ? " is-invalid" : "")
                  }
                  onChange={onChange}
                  value={values.intNumber}
                />
                {touched.intNumber && errors.intNumber && (
                  <span style={{ color: "red" }}>{errors.intNumber}</span>
                )}
              </div>
            </Col>
            <Col xs="12" lg="4">
              <div className="form-group">
                <label htmlFor="zipCode">
                  Código postal<span className="text-danger">*</span>
                </label>
                <input
                  name="zipCode"
                  type="text"
                  className={
                    "form-control" +
                    (errors.zipCode && touched.zipCode ? " is-invalid" : "")
                  }
                  onChange={onChange}
                  value={values.zipCode}
                />
                {touched.zipCode && errors.zipCode && (
                  <span style={{ color: "red" }}>{errors.zipCode}</span>
                )}
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs="12" lg="6">
              <div className="form-group">
                <input
                  disabled
                  name="latitude"
                  type="hidden"
                  value={values.latitude}
                  className={
                    "form-control" +
                    (errors.latitude && touched.latitude ? " is-invalid" : "")
                  }
                  onChange={onChange}
                />
                {/* {touched.latitude && errors.latitude && (
                  <span style={{ color: "red" }}>{errors.latitude}</span>
                )} */}
              </div>
            </Col>
            <Col xs="12" lg="6">
              <div className="form-group">
                <input
                  disabled
                  name="longitude"
                  type="hidden"
                  value={values.longitude}
                />
                {/* {touched.longitude && errors.longitude && (
                  <span style={{ color: "red" }}>{errors.longitude}</span>
                )} */}
              </div>
            </Col>
          </Row>

          <div className="d-flex justify-content-center">
            <Button
              color="info"
              onClick={() => searchLocation(values)}
              className="position-relative"
            >
              <i
                className="eva eva-pin-outline"
                style={{ fontSize: "20px", verticalAlign: "top" }}
              />
              Identificar ubicación
            </Button>
          </div>

          <div className="mt-4 text-center">
            <p
              className={
                "label " +
                (errors.longitude && touched.longitude ? "text-danger" : "")
              }
            >
              Identifique o marque un punto en el mapa haciendo clic sobre el.
            </p>
          </div>

          <div className="d-flex justify-content-center mt-1">
            {loadError ? (
              <div>Ocurrio un error al cargar el mapa</div>
            ) : isLoaded ? (
              renderMap()
            ) : (
              <Loader />
            )}
          </div>
          <p className="label text-center mt-1">
            Para mayor precisión, seleccione País, Estado, Municipio, Localidad,
            Colonia, Calle, No. exterior y Código Postal
          </p>

          <div className=" d-flex flex-sm-row flex-column-reverse justify-content-sm-around mt-5">
            <Button
              color="danger"
              type="reset"
              className="mt-3"
              onClick={() => history.push({ pathname: "/template/edificios" })}
            >
              Cancelar
            </Button>
            <Button color="success" type="submit" className="mt-2 mt-sm-3">
              Guardar
            </Button>
          </div>
        </form>
      )}
    </>
  );
};

export default FormEdificio;

import { useEffect, useState } from 'react';
import { useIdleTimer } from 'react-idle-timer'

export default function Timer({ children, onFinished }) {
    const [state, setState] = useState('Active')
    const [count, setCount] = useState(0)
    const [remaining, setRemaining] = useState(300)

    const onIdle = () => {
        setState('Idle')
    }

    const onActive = () => {
        setState('Active')
    }

    const onAction = () => {
        setCount(count + 1)
    }

    const { getRemainingTime } = useIdleTimer({
        onIdle,
        onActive,
        onAction,
        crossTab: true,
        leaderElection: true,
        syncTimers: 200,
        timeout: 1000 * 60 * 60, //1000ms * 60seg * 5 min
        throttle: 500
    })

    useEffect(() => {
        const interval = setInterval(() => {
            setRemaining(Math.ceil(getRemainingTime() / 1000))
        }, 500)

        return () => {
            clearInterval(interval)
        }
    }, [])

    useEffect(() => {
        if (remaining === 0 && process.env.NODE_ENV === "production") {
            onFinished()
        }
        return;
    }, [remaining])

    return (
        <>
            {children}
        </>
    )
}
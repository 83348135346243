import * as Yup from "yup";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardSubtitle,
  CardTitle,
  Col,
  Container,
  Row,
  Spinner,
  Table,
} from "reactstrap";
import {
  SelectTypeHeadSingle,
  TextAreaInput,
} from "../../../components/GenericInputsFormik";
import ModalConfirmation from "../../../components/Modal/ModalConfirmation";
import Notification from "../../../components/Notification/Notification";
import { toast } from "react-toastify";
import axios from "axios";
import { peticionEncript } from "../../../helpers/peticionesEncripted";
import s from "../../../pages/tables/Tables.module.scss";
import { NumberInput } from "../../../components/GenericInputsFormik/NumberInput";
import FullFormLoader from "../../../components/Loader/FullFormLoader";

const defaultForm = {
  ///Origen
  idStateFund: "",
  idChapter: "",
  idExpenseObject: "",
  idBudgetMonth: "",
  //Destino
  idStateFundDest: "",
  idChapterDest: "",
  idExpenseObjectDest: "",
  idBudgetMonthDest: "",
  amount: 1,
};
const gCo = [1, 2, 3, 4];
const gCa = [5, 6, 7];
const FormSchema = Yup.object().shape({
  ///Origen
  idStateFund: Yup.string().required("Seleccione un Proyecto Presupuestal"),
  idChapter: Yup.string()
    .required("Seleccione un Capítulo de Origen")
    .test(
      "is-corriente-o", /// Debe retornar falso para mostrar error
      "No es posible hacer un traspaso a un Gasto de Capital",
      (value, testContext) =>
        testContext.parent.idChapterDest === undefined ||
        !gCo.includes(+value) ||
        !gCa.includes(+testContext.parent.idChapterDest)
    )
    .test(
      "is-capital-o", /// Debe retornar falso para mostrar error
      "No es posible hacer un traspaso a un Gasto Corriente",
      (value, testContext) =>
        testContext.parent.idChapterDest === undefined ||
        !gCa.includes(+value) ||
        !gCo.includes(+testContext.parent.idChapterDest)
    ),
  idExpenseObject: Yup.string().required("Seleccione una Partida de Origen"),
  idBudgetMonth: Yup.string().required("Seleccione un mes de Origen"),
  //Destino
  idStateFundDest: Yup.string().required("Seleccione un Proyecto Presupuestal"),
  idChapterDest: Yup.string()
    .required("Seleccione un Capítulo de Destino")
    .test(
      "is-cap-1000",
      (d) =>
        `No se puede transferir al capítulo ${d.value} - SERVICIOS PERSONALES`,
      (value, testContext) =>{
        return  value !== "1" || testContext.parent.idChapter === "1"
      }
    )
    .test(
      "is-capital-d",
      "No es posible recibir un traspaso de un Gasto Corriente",
      (value, testContext) =>
        testContext.parent.idChapter === undefined ||
        !gCa.includes(+value) ||
        !gCo.includes(+testContext.parent.idChapter)
    )
    .test(
      "is-corriente-d",
      "No es posible recibir un traspaso de un Gasto de Capital",
      (value, testContext) =>
        testContext.parent.idChapter === undefined ||
        !gCo.includes(+value) ||
        !gCa.includes(+testContext.parent.idChapter)
    ),
  idExpenseObjectDest: Yup.string().required(
    "Seleccione una Partida de Destino"
  ),
  idBudgetMonthDest: Yup.string().required("Seleccione un mes de Destino"),
  amount: Yup.number()
    .required("Ingrese un monto de traspaso")
    .min(1, "El monto debe ser mayor a $0.00"),
});

async function doRequest(route, params, seeOk) {
  let info = [];
  const dataEncrypted = peticionEncript(params);
  try {
    const res = await axios.post(
      process.env.REACT_APP_API + route,
      dataEncrypted
    );
    if (res.status === 200 && res.data.status === "success") {
      info = res.data.data;
      if (seeOk !== false) {
        toast(
          <Notification
            type={"agrega_exito"}
            backMessage={res.data.message}
            withIcon
          />,
          { closeButton: false }
        );
      }
    } else {
      toast(
        <Notification
          type={"agrega_error"}
          backMessage={res.data.message}
          withIcon
        />,
        { closeButton: false }
      );
    }
  } catch (err) {
    toast(<Notification type={"consultar_servidor_error"} withIcon />, {
      closeButton: false,
    });
  }
  return info;
}

const bugetsU = "app/facades/budgets/budgetsF.php";
const receiverU = "receiver/receiver.php";

const Traspasos = ({ annualBudget }) => {
  const [loading, setLoading] = useState(true);
  const [formLoader, setFormLoader] = useState(false);
  const [modalConfirm, setModalConfirm] = useState(false);
  const [proyectosO, setProyectosO] = useState([]);
  const [proyectosD, setProyectosD] = useState([]);
  const [capitulosO, setCapitulosO] = useState([]);
  const [capitulosD, setCapitulosD] = useState([]);
  const [partidaO, setPartidaO] = useState([]);
  const [partidaD, setPartidaD] = useState([]);
  const [mesO, setMesO] = useState([]);
  const [mesD, setMesD] = useState([]);
  const [traspasosShow, setTraspasosShow] = useState([]);
  const [traspasos, setTraspasos] = useState([]);
  const [traspasosError, setTraspasosError] = useState(false);
  const [description, setDescription] = useState("");
  const [descriptionTouch, setDescriptionTouch] = useState(false);
  const [descriptionError, setDescriptionError] = useState(false);
  const refs = [
    React.useRef(null),
    React.useRef(null),
    React.useRef(null),
    React.useRef(null),
    React.useRef(null),
    React.useRef(null),
    React.useRef(null),
    React.useRef(null),
  ];

  const {
    handleSubmit,
    values,
    handleBlur,
    errors,
    touched,
    setFieldValue,
    handleReset,
  } = useFormik({
    initialValues: defaultForm,
    validationSchema: FormSchema,
    onSubmit: (values) => addTraspaso(),
    onReset: () => {},
    enableReinitialize: true,
  });

  useEffect(() => {
    getProyecto(1);
    getProyecto(2).then(()=>{setLoading(false)});
  }, []);

  /* Funcion para buscar proyectos presupuestales */
  const getProyecto = async (q) => {
    const params = {
      action: q === 1 ? "getStateFundsOrigin" : "getStateFundsDestination",
      rows: { idAnnualBudget: annualBudget.id },
    };
    if (q === 2) {
      ////
      delete params.rows;
    }
    const data = await doRequest(bugetsU, params, false);
    if (data.length > 0) {
      if (q === 1) setProyectosO(data);
      else setProyectosD(data);
    }
  };

  /* Funcion para buscar capitulos */
  const getCapitulo = async (q, fund) => {
    if (fund !== "") {
      const params = {
        action: q === 1 ? "getChaptersOrigin" : "getChaptersDestination",
        idAnnualBudget: annualBudget.id,
        idStateFund: fund,
      };
      if (q === 2) {
        ////
        delete params.idAnnualBudget;
        delete params.idStateFund;
      }
      const data = await doRequest(bugetsU, params, false);
      if (data.length > 0) {
        if (q === 1) setCapitulosO(data);
        else setCapitulosD(data);
      }
    } else {
      if (q === 1) setCapitulosO([]);
      else setCapitulosD([]);
    }
    if (q === 1) onChange({ target: { name: "idChapter", value: "" } });
    else onChange({ target: { name: "idChapterDest", value: "" } });
  };

  /* Funcion para buscar partidas  */
  const getPartida = async (q, fund, cha) => {
    if (cha !== "") {
      const params = {
        action: q === 1 ? "getExpensesOrigins" : "getExpenseDestination",
        idAnnualBudget: annualBudget.id,
        idStateFund: fund,
        keyChapter: cha,
      };
      if (q === 2) {
        ////
        delete params.idAnnualBudget;
        delete params.idStateFund;
      }
      const data = await doRequest(bugetsU, params, false);
      if (data.length > 0) {
        if (q === 1) setPartidaO(data);
        else setPartidaD(data);
      }
    } else {
      if (q === 1) setPartidaO([]);
      else setPartidaD([]);
    }
    if (q === 1) onChange({ target: { name: "idExpenseObject", value: "" } });
    else onChange({ target: { name: "idExpenseObjectDest", value: "" } });
  };

  /* Funcion para buscar meses  */
  const getMes = async (q, fund, obj) => {
    if (obj !== "") {
      const params = {
        action: q === 1 ? "getMonthsOrigin" : "getMonthsDestination",
        idAnnualBudget: annualBudget.id,
        idStateFund: fund,
        idExpenseObject: obj,
      };
      const data = await doRequest(bugetsU, params, false);
      if (data.length > 0) {
        if (q === 1) setMesO(data);
        else setMesD(data);
      }
    } else {
      if (q === 1) setMesO([]);
      else setMesD([]);
    }
    if (q === 1) onChange({ target: { name: "idBudgetMonth", value: "" } });
    else onChange({ target: { name: "idBudgetMonthDest", value: "" } });
  };

  const onChange = (e) => {
    switch (e.target.name) {
      case "idStateFund":
        getCapitulo(1, e.target.value);
        break;
      case "idStateFundDest":
        getCapitulo(2, e.target.value);
        break;
      case "idChapter":
        getPartida(1, values.idStateFund, e.target.value);
        break;
      case "idChapterDest":
        getPartida(2, values.idStateFundDest, e.target.value);
        break;
      case "idExpenseObject":
        getMes(1, values.idStateFund, e.target.value);
        break;
      case "idExpenseObjectDest":
        getMes(2, values.idStateFundDest, e.target.value);
        break;
    }
    setFieldValue(e.target.name, e.target.value);
  };

  /* Funcion para agregar un traspaso a la tabla  */
  const addTraspaso = () => {
    traspasosShow.push([
      {
        idStateFund: refs[0].current.state.text,
        idChapter: refs[1].current.state.text,
        idExpenseObject: refs[2].current.state.text,
        idBudgetMonth: refs[3].current.state.text,
      },
      {
        idStateFundDest: refs[4].current.state.text,
        idChapterDest: refs[5].current.state.text,
        idExpenseObjectDest: refs[6].current.state.text,
        idBudgetMonthDest: refs[7].current.state.text,
      },
    ]);
    traspasos.push({
      idBudgetMonth: values.idBudgetMonth,
      idStateFundDest: values.idStateFundDest,
      idExpenseObjectDest: values.idExpenseObjectDest,
      keyMonth: values.idBudgetMonthDest,
      amount: values.amount,
    });
    setTraspasos(traspasos);
    setTraspasosShow(traspasosShow);
    if (traspasosError) setTraspasosError(false);
    handleReset();
  };

  /* Funcion para quitar un traspaso de la tabla  */
  const deleteTraspaso = (id) => {
    let trshC = [...traspasosShow];
    let trC = [...traspasos];
    trshC.splice(id, 1);
    trC.splice(id, 1);
    setTraspasosShow(trshC);
    setTraspasos(trC);
  };

  /* Funcion para gaurdar los traspasos*/
  const saveTraspaso = async () => {
    setFormLoader(true);
    const params = {
      action: "saveBudgetTransfer",
      description: description,
      idAnnualBudget: annualBudget.id,
      rows: traspasos,
    };
    console.log(traspasos);
    const data = await doRequest(
      "app/facades/budgets/budgetsF.php",
      params,
      true
    );
    if (data.length !== 0) {
      setTraspasosShow([]);
      setTraspasos([]);
      setTraspasosError(false);
      setDescription("");
      setDescriptionTouch(false);
      setDescriptionError(false);
    }
    setFormLoader(false);
  };

  /* Funcion para validar el motivo del traspaso*/
  const validDesc = (e) => {
    if (!descriptionTouch) setDescriptionTouch(true);
    if (description === "") {
      setDescriptionError("Ingrese un motivo del traspaso");
      return false;
    }
    setDescriptionError(false);
    return true;
  };

  /* Funcion para validar el motivo del traspaso*/
  const handleSubmitTraspasos = () => {
    if (traspasos.length === 0) {
      setTraspasosError(true);
      validDesc();
    } else if (validDesc()) {
      setModalConfirm(true);
    }
  };

  useEffect(() => {
    if (descriptionTouch) {
      validDesc();
    }
  }, [description]);

  if (loading)
    return (
      <div style={{ minHeight: "300px" }} className="text-center">
        <Spinner color="primary" style={{ marginTop: "135px" }} />
      </div>
    );

  return (
    <div className="mt-2">
      <h4 className="mb-2">Traspasos Internos/Externos</h4>

      <Container className="mx-0 mt-4" style={{ minWidth: "100%" }}>
        <form onSubmit={handleSubmit} onReset={handleReset}>
          <Row>
            <Col xs={12} lg={6} className="mb-4">
              <Card>
                <CardHeader className="pb-1 text-center">
                  <CardTitle tag="h5">Origen</CardTitle>
                </CardHeader>
                <CardBody>
                  <CardSubtitle
                    className="mb-3 text-muted"
                    tag="p"
                    style={{ fontSize: "18px" }}
                  >
                    Seleccione el origen de los recursos:
                  </CardSubtitle>

                  <SelectTypeHeadSingle
                    label="Proyecto Presupuestal"
                    isRequired
                    inputName="idStateFund"
                    optionsArray={proyectosO}
                    defaultOption="Seleccione un proyecto presupuestal"
                    onChangeMethod={onChange}
                    onBlurMethod={handleBlur}
                    value={values.idStateFund}
                    touched={touched.idStateFund}
                    errors={errors.idStateFund}
                    optionValue="idStateFund"
                    optionName="nameStateFund"
                    refSelect={refs[0]}
                  />

                  <SelectTypeHeadSingle
                    label="Capítulo Origen"
                    isRequired
                    inputName="idChapter"
                    optionsArray={capitulosO}
                    defaultOption="Seleccione un Cápitulo de Origen"
                    onChangeMethod={onChange}
                    onBlurMethod={handleBlur}
                    value={values.idChapter}
                    touched={touched.idChapter}
                    errors={errors.idChapter}
                    optionValue="keyChapter"
                    optionName="nameChapter"
                    refSelect={refs[1]}
                  />

                  <SelectTypeHeadSingle
                    label="Partida Origen"
                    isRequired
                    inputName="idExpenseObject"
                    optionsArray={partidaO}
                    defaultOption="Seleccione una Partida de Origen"
                    onChangeMethod={onChange}
                    onBlurMethod={handleBlur}
                    value={values.idExpenseObject}
                    touched={touched.idExpenseObject}
                    errors={errors.idExpenseObject}
                    optionValue="idExpenseObject"
                    optionName="nameObject"
                    refSelect={refs[2]}
                  />

                  <SelectTypeHeadSingle
                    label="Mes Origen"
                    isRequired
                    inputName="idBudgetMonth"
                    optionsArray={mesO}
                    defaultOption="Seleccione una Mes de Origen"
                    onChangeMethod={onChange}
                    onBlurMethod={handleBlur}
                    value={values.idBudgetMonth}
                    touched={touched.idBudgetMonth}
                    errors={errors.idBudgetMonth}
                    optionValue="idBudgetMonth"
                    optionName="avaible"
                    refSelect={refs[3]}
                  />
                </CardBody>
              </Card>
            </Col>

            <Col xs={12} lg={6} className="mb-4">
              <Card>
                <CardHeader className="pb-1 text-center">
                  <CardTitle tag="h5">Destino</CardTitle>
                </CardHeader>
                <CardBody>
                  <CardSubtitle
                    className="mb-3 text-muted"
                    tag="p"
                    style={{ fontSize: "18px" }}
                  >
                    Seleccione el destino de los recursos:
                  </CardSubtitle>

                  <SelectTypeHeadSingle
                    label="Proyecto Presupuestal"
                    isRequired
                    inputName="idStateFundDest"
                    optionsArray={proyectosD}
                    defaultOption="Seleccione un proyecto presupuestal"
                    onChangeMethod={onChange}
                    onBlurMethod={handleBlur}
                    value={values.idStateFundDest}
                    touched={touched.idStateFundDest}
                    errors={errors.idStateFundDest}
                    optionValue="idStateFund"
                    optionName="nameStateFund"
                    refSelect={refs[4]}
                  />

                  <SelectTypeHeadSingle
                    label="Capítulo Destino"
                    isRequired
                    inputName="idChapterDest"
                    optionsArray={capitulosD}
                    defaultOption="Seleccione un Cápitulo de Destino"
                    onChangeMethod={onChange}
                    onBlurMethod={handleBlur}
                    value={values.idChapterDest}
                    touched={touched.idChapterDest}
                    errors={errors.idChapterDest}
                    optionValue="keyChapter"
                    optionName="nameChapter"
                    refSelect={refs[5]}
                  />

                  <SelectTypeHeadSingle
                    label="Partida Destino"
                    isRequired
                    inputName="idExpenseObjectDest"
                    optionsArray={partidaD}
                    defaultOption="Seleccione una Partida de Destino"
                    onChangeMethod={onChange}
                    onBlurMethod={handleBlur}
                    value={values.idExpenseObjectDest}
                    touched={touched.idExpenseObjectDest}
                    errors={errors.idExpenseObjectDest}
                    optionValue="idExpenseObject"
                    optionName="nameObject"
                    refSelect={refs[6]}
                  />

                  <SelectTypeHeadSingle
                    label="Mes Destino"
                    isRequired
                    inputName="idBudgetMonthDest"
                    optionsArray={mesD}
                    defaultOption="Seleccione un Mes de Destino"
                    onChangeMethod={onChange}
                    onBlurMethod={handleBlur}
                    value={values.idBudgetMonthDest}
                    touched={touched.idBudgetMonthDest}
                    errors={errors.idBudgetMonthDest}
                    optionValue="keyMonth"
                    optionName="avaible"
                    refSelect={refs[7]}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row className="d-flex justify-content-center">
            <Col xs={12} lg={6}>
              <NumberInput
                label="Monto"
                inputName="amount"
                onChangeMethod={onChange}
                onBlurMethod={handleBlur}
                value={values.amount}
                isRequired
                touched={touched.amount}
                errors={errors.amount}
                isMin={1}
                decimals
                prefix="$"
              />
            </Col>
          </Row>

          <div className="row mt-2">
            <div className="col-12 col-md-6 order-2 order-sm-1 text-center my-3">
              <Button color="danger" type="reset">
                Limpiar
              </Button>
            </div>
            <div className="col-12 col-md-6 order-1 order-sm-2 text-center my-3">
              <Button color="success" type="submit">
                Guardar
              </Button>
            </div>
          </div>
        </form>
      </Container>

      <div className="mt-3">
        {traspasosError && (
          <p className="text-danger my-2">Agregue almenos un traspaso</p>
        )}

        <div
          className="border"
          style={{ overflowY: "auto", maxHeight: "300px" }}
        >
          <Table
            className={`table-striped border table-hover ${s.statesTable ? s.statesTable : ""}`}
            responsive
          >
            <thead
              className="border-bottom"
              style={{ backgroundColor: "var(--secondary)" }}
            >
              <tr className="text-center">
                <th
                  className="text-white border-right font-weight-bold"
                  style={{ width: "45%", position: "sticky", top: "0px" }}
                >
                  Origen
                </th>
                <th
                  className="text-white border-right font-weight-bold"
                  style={{ width: "45%", position: "sticky", top: "0px" }}
                >
                  Destino
                </th>
                <th
                  className="text-white font-weight-bold"
                  style={{ width: "10%", position: "sticky", top: "0px" }}
                >
                  Borrar
                </th>
              </tr>
            </thead>
            <tbody>
              {traspasosShow.length > 0 &&
                traspasosShow.map((el, id) => (
                  <tr key={"t" + id+el[0].idStateFund} className="border-bottom">
                    <td className="border-right p-1 align-top">
                      <div>
                        <p>
                          <strong>Proyecto:</strong> {el[0].idStateFund}
                        </p>
                        <p>
                          <strong>Capitulo:</strong> {el[0].idChapter}
                        </p>
                        <p>
                          <strong>Partida:</strong> {el[0].idExpenseObject}
                        </p>
                        <p>
                          <strong>Mes:</strong> {el[0].idBudgetMonth}
                        </p>
                      </div>
                    </td>
                    <td className="border-right p-1 align-top">
                      <div>
                        <p>
                          <strong>Proyecto:</strong> {el[1].idStateFundDest}
                        </p>
                        <p>
                          <strong>Capitulo:</strong> {el[1].idChapterDest}
                        </p>
                        <p>
                          {" "}
                          <strong>Partida:</strong> {el[1].idExpenseObjectDest}
                        </p>
                        <p>
                          <strong>Mes:</strong> {el[1].idBudgetMonthDest}
                        </p>
                      </div>
                    </td>
                    <td className="text-center p-1">
                      <i
                        className="fa fa-trash-o text-danger pr-2"
                        style={{ cursor: "pointer" }}
                        onClick={() => deleteTraspaso(id)}
                      />
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </div>

        <Row className="justify-content-center mt-5 mb-3">
          <Col xs={12} lg={8}>
            <TextAreaInput
              label="Motivo del traspaso"
              inputName="notas"
              isRequired
              inputRows={6}
              onChangeMethod={(e) => {
                setDescription(e.target.value);
              }}
              onBlurMethod={validDesc}
              touched={descriptionTouch}
              errors={descriptionError}
              value={description}
            />

            <div className="text-center mt-5">
              <Button
                color="add"
                type="button"
                onClick={() => handleSubmitTraspasos()}
              >
                Solicitar Traspaso
              </Button>
            </div>
          </Col>
        </Row>

        <ModalConfirmation
          modalTitle="Guardar actividad"
          modal={modalConfirm}
          setModal={setModalConfirm}
          editar={() => saveTraspaso()}
          isEdit={true}
          values={values}
        >
          <div className="d-flex justify-content-center">
            <h6>¿Está seguro de enviar la solicitud de traspaso?</h6>
          </div>
        </ModalConfirmation>

        <FullFormLoader show={formLoader} />
      </div>
    </div>
  );
};
export default Traspasos;

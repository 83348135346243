import FormUser from "../FormUser"
import Widget from "../../../../components/Widget/Widget";

import { agregar } from "../functions";

const CrearUsuario = (props) => {
  return (
    <div>
      <Widget className="widget-p-md">
        <h3>Crear usuario</h3>
        <FormUser crear={agregar}/>
    </Widget>
    </div>
  )
}

export default CrearUsuario
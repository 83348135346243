import * as Yup from "yup"

import { toast } from "react-toastify";
import Notification from "../../../../../components/Notification/Notification";

import { useState } from "react";
import { NumberInput } from "../../../../../components/GenericInputsFormik/NumberInput";
import { useFormik } from "formik";
import { Button } from "reactstrap";
import ModalConfirmation from "../../../../../components/Modal/ModalConfirmation";

import { peticionesReceiver } from "../../../../../helpers/peticionesReceiver";

export default function EditarCantidad({ amount, idDetail, setRefresh }) {
    const [modal, setModal] = useState(false)

    const defaultForm = {
        amount,
        idDetail
    }

    const FormSchema = Yup.object().shape({
        amount: Yup.number().moreThan(0, "Mayor a 0").required("Requerido")
    })

    const { handleSubmit, values, handleBlur, errors, touched, setFieldValue } =
        useFormik({
            initialValues: defaultForm,
            onSubmit: () => setModal(true),
            validationSchema: FormSchema,
        });

    const onChange = (e) => {
        const { name, value } = e.target
        setFieldValue([name], value);
    }

    const OnEdit = async (rows) => {
        const API = peticionesReceiver();
        const params = {
            action: "update",
            table: "quotesdetail",
            rows,
            condition: {
                idDetail: rows.idDetail,
            }
        }
        try {
            const res = await API.peticion(params)
            if (res.status === 200 && res.data.code === "200") {
                toast(<Notification type={"modifica_exito"} backMessage={res.data.message} withIcon />);
                setRefresh(true)
            } else {
                toast(<Notification type={"modifica_error"} backMessage={res.data.message} withIcon />);
            }
        } catch (err) {
            console.log(err)
            toast(<Notification type={"modifica_error"} withIcon />);
        }
    }

    return (
        <>
            <form onSubmit={handleSubmit}>
                <div className="d-flex">
                    <NumberInput
                        value={values.amount}
                        errors={errors.amount}
                        inputName="amount"
                        onBlurMethod={handleBlur}
                        onChangeMethod={onChange}
                        touched={touched.amount}
                    />
                    <Button className="m-0 p-0" color="success" type="submit" style={{ maxHeight: "45px" }} title="Guardar cantidad">
                        <i className="fa fa-check" />
                    </Button>
                </div>
            </form>
            <ModalConfirmation
                modalTitle={"Editar cantidad"}
                modal={modal}
                editar={(rows) => OnEdit(rows)}
                isEdit={true}
                setModal={setModal}
                values={values}
            >
                <div className="d-flex justify-content-center">
                    <h6>¿Desea continuar con el cambio?</h6>
                </div>
            </ModalConfirmation>
        </>
    )
}
// eslint-disable-next-line react-hooks/exhaustive-deps
import axios from "axios";
import classNames from "classnames";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Row,
  Table,
} from "reactstrap";
import Notification from "../../../components/Notification/Notification";
import Widget from "../../../components/Widget/Widget";
import { peticionEncript } from "../../../helpers/peticionesEncripted";
import st from "../pagos-transferencias-empleados/PagosTrasferenciasEmpleados.module.scss";
import { useHistory } from "react-router-dom";
import FullFormLoader from "../../../components/Loader/FullFormLoader";

const fmt = new Intl.NumberFormat("es-MX", {
  maximumFractionDigits: 2,
  minimumFractionDigits: 2,
});
const fmtMny = new Intl.NumberFormat("es-MX", {
  style: "currency",
  currency: "MXN",
});

async function doRequest(route, params, seeOk) {
  let info = [];
  const dataEncrypted = peticionEncript(params);
  await axios
    .post(process.env.REACT_APP_API + route, dataEncrypted)
    .then((res) => {
      if (res.status === 200 && res.data.status === "success") {
        info = res.data.data;
        if (seeOk) {
          toast(
            <Notification
              type={"elimina_exito"}
              backMessage={res.data.message}
              withIcon
            />,
            { closeButton: false }
          );
        }
      } else {
        toast(
          <Notification
            type={"consultar_error"}
            backMessage={res.data.message}
            withIcon
          />,
          { closeButton: false }
        );
      }
    })
    .catch((err) => {
      toast(<Notification type={"consultar_servidor_error"} withIcon />, {
        closeButton: false,
      });
    });
  return info;
}

export default function DetalleProveedorN() {
  const config = JSON.parse(localStorage.getItem("pagoProveedor"));
  const [pagos, setIPagos] = useState([]);
  const [loader, setLoader] = useState(true);
  const history = useHistory();

  useEffect(() => {
    if (config !== null && config !== "" && config !== undefined)
      getCipherInfo();
    else history.push({ pathname: "/template/reportes-finanzas" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function getCipherInfo() {
    const data = await doRequest(
      "app/facades/reports/reportsFiTeF.php",
      config
    );
    if (data.length > 0) {
      setIPagos(data);
    }
    setLoader(false);
  }

  const goBack = () => {
    history.goBack();
  };

  return (
    <Widget className="widget-p-md">
      <div className="headline-1 text-center mt-3 mb-4">
        Solicitud de Pago de Proveedores de Nomina
      </div>
      <div className="2-75">
        <div className="mb-4 text-center text-md-right">
          <Button
            className="btn text-light"
            style={{ backgroundColor: "#ba262f", border: "none" }}
            onClick={() => goBack()}
          >
            Regresar
          </Button>
        </div>
        {pagos.length > 0 &&
          pagos.map((el, ix) => (
            <Pagina info={el} id={"c" + ix} config={config} key={"c" + ix} />
          ))}
      </div>
      <FullFormLoader show={loader} />
    </Widget>
  );
}

function whatIs(algo) {
  if (algo.length !== undefined) return algo;
  else return Object.values(algo);
}

const Pagina = ({ info, id, config }) => {
  // const ttp = +info.conceptosTotalPersonas[0];
  // const dif = +info.deducciones - +info.importes;
  // const stt = dif < 0 ? "#ca0404" : "#000";
  let arrDeducciones = whatIs(info.conceptosDed);
  let arrPercepciones = [];
  if (+info.importes > 0) arrPercepciones = whatIs(info.conceptosPer);

  // console.log(info);
  return (
    <Card className="mb-4 border border-info">
      <CardHeader className="text-center bg-secondary text-white" tag="h6">
        <div className="d-flex justify-content-between">
          <p>{info.lcPropio} {info.nameAccountList}</p>
          <p>
            Periodo {config.period} de {config.year}
          </p>
        </div>
      </CardHeader>
      <CardBody>
        <div style={{ color: "#000" }}>
          <Row className="mb-2">
            <Col xs={12} lg={3} className="font-weight-bold">
              Nombre de la cuenta:
            </Col>
            <Col xs={12} lg={8}>
              {info.nameAccountList}
            </Col>
          </Row>
          <Row className="mb-2">
            <Col xs={12} lg={3} className="font-weight-bold">
              Número de la cuenta:
            </Col>
            <Col xs={12} lg={8}>
              {info.lcPropio}
            </Col>
          </Row>
          <Row className="mb-2">
            <Col xs={12} lg={3} className="font-weight-bold">
              Periodo:
            </Col>
            <Col xs={12} lg={8}>
              {config.period}
            </Col>
          </Row>
          {((+info.importes > 0 && +info.deducciones > 0) ||
            info.conceptosDed.length > 1) && (
            <Row className="mb-2">
              <Col xs={12} lg={3} className="font-weight-bold">
                Integración:
              </Col>
              <Col xs={12} lg={8}>
                {+info.importes > 0 && +info.deducciones > 0 ? (
                  <div>
                    {arrDeducciones.map((el, dx) => (
                      <ConceptosPD e={el} t={1} i={dx} key={"cd" + dx} />
                    ))}
                    {arrPercepciones.map((el, dx) => (
                      <ConceptosPD e={el} t={2} i={dx} key={"cp" + dx} />
                    ))}
                  </div>
                ) : (
                  info.conceptosDed.length > 1 &&
                  arrDeducciones.map((el, dx) => (
                    <ConceptosPD e={el} t={1} i={dx} key={"cd" + dx} />
                  ))
                )}
                <div className="d-flex flex-row">
                  <p
                    style={{
                      minWidth: "170px",
                      borderBottom: "2px solid black",
                    }}
                  />
                </div>
              </Col>
            </Row>
          )}
          <Row className="mb-2">
            <Col xs={12} lg={3} className="font-weight-bold">
              Total a pagar:
            </Col>
            <Col xs={12} lg={8}>
              <div className="d-flex flex-row">
                <p style={{ minWidth: "30px" }}>$</p>
                <p
                  style={{
                    minWidth: "140px",
                    textAlign: "right",
                    fontWeight: "bold",
                  }}
                >
                  {+info.importes > 0 && +info.deducciones > 0
                    ? fmt.format(+info.deducciones - +info.importes)
                    : fmt.format(info.deducciones)}
                </p>
              </div>
            </Col>
          </Row>
          <Row className="mb-2">
            <Col xs={12} lg={3} className="font-weight-bold">
              Beneficiario:
            </Col>
            <Col xs={12} lg={8}>
              {info.rfc}
            </Col>
          </Row>
          <Row className="mb-2">
            <Col xs={12} lg={3} className="font-weight-bold">
              Clabe Bancaria:
            </Col>
            <Col xs={12} lg={8}>
              {info.cuenta}
            </Col>
          </Row>
          <Row className="mb-2">
            <Col xs={12} lg={3} className="font-weight-bold">
              Banco Destino:
            </Col>
            <Col xs={12} lg={8}>
              {info.nameBank}
            </Col>
          </Row>
        </div>

        {info.conceptos.map(
          (c, ix) =>
            c.status === "success" &&
            c.totalRows > 0 && (
              <TableConcepto
                cct={c.data}
                ttp={info.conceptosTotalPersonas[ix]}
                ttc={info.conceptosTotal[ix]}
                key={id + "tc" + ix}
                cfg={config}
              />
            )
        )}
      </CardBody>
    </Card>
  );
};

function TableConcepto({ cct, ttp, ttc, cfg }) {
  return (
    <div className={classNames([st.tableLimit, "mt-5"])}>
      <div className="text-center" style={{ color: "#000" }}>
        <strong>Concepto:</strong> {cct[0].nameUnder}
        <p>Periodo {cfg.period} de {cfg.year}</p>
      </div>
      <Table
        responsive
        className={classNames(["table-striped table-borderless mt-2 mb-2"])}
      >
        <thead
          className={classNames([
            "text-center",
            st.thBB,
            "border-secondary bg-white",
          ])}
        >
          <tr>
            <th className="px-2 py-1">NO.</th>
            <th className="px-2 py-1">NO. EMPLEADO</th>
            <th className="px-2 py-1">NOMBRE COMPLETO</th>
            <th className="px-2 py-1">IMPORTE</th>
          </tr>
        </thead>
        <tbody>
          {cct.map((d, ix) => (
            <tr
              className={classNames(st.rowHover, st.tableText)}
              key={ix + "r" + ix}
            >
              <td className="text-center">{ttp + 1 + ix}</td>
              <td className="text-center">{d.staffNumber}</td>
              <td>{d.nombreCompleto}</td>
              <td className="text-right">{fmtMny.format(d.amount)}</td>
            </tr>
          ))}
          <tr className={classNames(st.tableText, st.trTT, "text-right font-weight-bold")}>
            <td colSpan={3}>Total: </td>
            <td>{fmtMny.format(ttc)}</td>
          </tr>
        </tbody>
      </Table>
    </div>
  );
}

function ConceptosPD({ e, t, i }) {
  return (
    <div className="d-flex flex-row flex-wrap-reverse">
      <p style={{ minWidth: "30px" }}>
        $ {t === 1 ? (i === 0 ? "" : "+") : "-"}
      </p>
      <p className="text-right mr-2" style={{ minWidth: "140px" }}>
        {fmt.format(e.importes)}
      </p>
      <p className="my-auto" style={{ fontSize: "10px" }}>
        {e.nameUnder}
      </p>
    </div>
    // <Row>
    //   <Col xs={1} className="border border-danger">$ {t === 1 ? (i === 0 ? "" : "+") : "-"}</Col>
    //   <Col xs={4} className="border border-info text-right">{fmt.format(e.importes)}</Col>
    //   <Col xs={7}>{e.nameUnder}</Col>
    // </Row>
  );
}

import React, { useState, useEffect } from "react";
import {
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  Spinner,
} from "reactstrap";
import { peticionEncript } from "../../../helpers/peticionesEncripted";
import axios from "axios";
import { toast } from "react-toastify";
import Notification from "../../../components/Notification/Notification";
import DocumentoPViewer from "./DocumentoPViewer";

const s = { fontSize: "18px", marginRight: "5px" };

async function doRequest(route, params) {
  let info = [];
  const dataEncrypted = peticionEncript(params);
  await axios
    .post(process.env.REACT_APP_API + route, dataEncrypted)
    .then((res) => {
      if (res.status === 200 && res.data.status === "success") {
        info = res.data.data;
      } else {
        toast(
          <Notification
            type={"consultar_error"}
            backMessage={res.data.message}
            withIcon
          />,
          { closeButton: false }
        );
      }
    })
    .catch((err) => {
      toast(<Notification type={"consultar_servidor_error"} withIcon />, {
        closeButton: false,
      });
    });
  return info;
}

const DocumentacionP = ({ proyectId, canEdit, permissions }) => {
  const [loading, setLoading] = useState(true);
  const [activeTab, setActiveTab] = useState(0);
  const [docTypes, setDocTypes] = useState([]);

  /* Cambiar de tab*/
  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  /* Consulta lo tipos de documentos*/
  const getDocTypes = async () => {
    const param = {
      action: "select",
      table: "filetypes",
      condition: { idModule: 105 },
    };
    const data = await doRequest("receiver/receiver.php", param);
    if (data.length > 0) {
      setDocTypes(data);
      setActiveTab(+data[0].keyFileType);
    }
    setLoading(false);
  };

  useEffect(() => {
    getDocTypes();
  }, []);

  if (loading)
    return (
      <div style={{ minHeight: "300px" }} className="text-center">
        <Spinner color="primary" style={{ marginTop: "135px" }} />
      </div>
    );

  return (
    <Container className="mx-0 mt-2" style={{ maxWidth: "100%" }}>
      <div className="mb-4">
        <h4>Documentación</h4>
      </div>

      <Row>
        <Col xs={12} lg={3} className="border-left border-right">
          <Nav
            fill
            pills
            vertical
            style={{
              overflowY: "hidden",
              overflowX: "auto",
            }}
            className="border-bottom-0 my-2"
          >
            {docTypes.length > 0 ? (
              docTypes.map((d, id) => (
                <NavItem active={activeTab === +d.keyFileType} key={"dc" + id}>
                  <NavLink
                    href="#"
                    active={activeTab === +d.keyFileType}
                    onClick={() => toggleTab(+d.keyFileType)}
                    className="rounded-0 border-bottom px-1 w-100"
                  >
                    {/* <i className={"fa fa-cogs"} style={s} /> */}
                    {d.nameFyleType}
                  </NavLink>
                </NavItem>
              ))
            ) : (
              <div className="text-center">No se encontraron documentos</div>
            )}
          </Nav>
        </Col>
        <Col xs={12} lg={9}>
          <div className="mt-2">
            {docTypes.length > 0 &&
              docTypes.map(
                (d, id) =>
                  activeTab === +d.keyFileType && (
                    <DocumentoPViewer
                      proyectId={proyectId}
                      fileType={d.keyFileType}
                      key={"dv" + id}
                      canEdit={canEdit}
                      permissions={permissions}
                    />
                  )
              )}
          </div>
        </Col>
      </Row>
    </Container>
  );
};
export default DocumentacionP;

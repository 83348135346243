import * as XlsxPopulate from "xlsx-populate";
import { saveAs } from "file-saver";
import { fDateTime, fPercent } from "../../../../../libs/tools/format";

const s = {
  tC: {horizontalAlignment: "center", border: true},
  tS: {horizontalAlignment: "left", border: true},
  tE: {horizontalAlignment: "right", border: true},
  header: {horizontalAlignment: "center", fill: "AEAAAA", fontColor: "0000", bold: true, border: true, wrapText: true, verticalAlignment: "center" },
  bT: {topBorder: true},
  bB: {bottomBorder: true},
  bBb: {bottomBorder: "medium"},
  tt: { horizontalAlignment: "center", bold: true },
}
const headers = [
  "Incremento Salarial",
  "Año Aplicación",
  "Fecha Aplicación",
  "Porcentaje Base ",
  "Porcentaje Recompensa", 
  "Porcentaje Despensa",
  "Porcentaje Liquidación",
  "Porcentaje Fortalecimiento",
  "Afectados"
]

export async function tabuladoresHistoricoExcel(data) {

  XlsxPopulate.fromBlankAsync().then(async (workbook) => {
    const hoja = workbook.sheet(0);
    hoja.range(`$A2:$I2`).merged(true).value("SAPP").style(s.tt);
    hoja.range(`$A3:$I3`).merged(true).value("Tabuladores Histórico").style(s.tt);

    headers.forEach((h, ix)=>{
      hoja.column(ix+1).width(20);
      hoja.row(6).cell(ix+1).value(h).style(s.header);
    });

    const row = 7;
    data.forEach((d, ix)=>{
      hoja.row(row+ix).cell(1).value(d.idSalaryIncrease).style(s.tC);
      hoja.row(row+ix).cell(2).value(d.anioAplicacion).style(s.tC);
      hoja.row(row+ix).cell(3).value(fDateTime(d.fechaApliacion)).style(s.tC);
      hoja.row(row+ix).cell(4).value(fPercent(d.porcetageBase)).style(s.tC);
      hoja.row(row+ix).cell(5).value(fPercent(d.porcentageReward)).style(s.tC);
      hoja.row(row+ix).cell(6).value(fPercent(d.porcentageLarder)).style(s.tC);
      hoja.row(row+ix).cell(7).value(fPercent(d.porcentageClearance)).style(s.tC);
      hoja.row(row+ix).cell(8).value(fPercent(d.porcentageFortalecimiento)).style(s.tC);
      hoja.row(row+ix).cell(9).value(d.cantAfectados).style(s.tE);
    });
    
      
    return workbook.outputAsync().then((res) => {
      saveAs(res, `TabuladoresHistorico.xlsx`);
    });
  });
}

import TableComponent from "../../../components/TableComponent/TableComponentFiltro";
export default function TiposSeveridad () {
    const parametros = {
        action: "datatable",
        table: "types_severity",
        rows: "keySeverity, nameSeverity, maxAttentionTime, maxSolutionTime",
        conditions: "enabled = 1",
        page: 0,
        records: 5,
        search: "",
        order: "",
    };

    return (
        <div>
            <TableComponent
                titulo="Tipos de severidad"
                cabecerasTabla={["Id", "Descripción", "Timepo de atención", "Tiempo de solución", "Editar", "Eliminar"]}
                filtro={[true, true, true, true, true, true]}
                rutaCrear="/template/tipos-severidad/crear"
                rutaEditar="/template/tipos-severidad/editar"
                parametros={parametros}
            />
        </div>
    );
};

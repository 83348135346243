import * as XlsxPopulate from "xlsx-populate";
import { saveAs } from "file-saver";

/**
 * Hoja de estilos del excel
 */
const styles = {
    title: {
        horizontalAlignment: 'center', bold: true
    },
    bold: {
        bold: true
    },
    nobold: {
        bold: false
    },
    center: {
        horizontalAlignment: 'center'
    },
    headerBorder: {
        bold: "true",
        border: true,
        fill: "BFBFBF",
    },
    header: {
        bold: "true",
        border: true,
        fill: "BFBFBF",
    },
    headerBoderBold: {
        bold: "true",
        border: true,
    },
    contentBorder: {
        border: true,
    },
    contentBorderB: {
        border: true,
        bold: true
    },
    contentBorderRigth: {
        horizontalAlignment: 'right'
    },
    contentBorderRigthB: {
        horizontalAlignment: 'right',
        bold: true
    },
};

export async function saveReport54(report, title, config) {

    XlsxPopulate.fromBlankAsync().then(async (workbook) => {
        const sheet1 = workbook.sheet(0);
        //Titulo
        sheet1
            .cell(`A1`)
            .value(`FISCALIA GENERAL DEL ESTADO DE MÉXICO`)
            .style(styles.title);
        sheet1
            .cell(`A2`)
            .value("SAPP")
            .style(styles.title);
        sheet1
            .cell(`A3`)
            .value(`Programas y Proyectos para el Ejercicio Fiscal ${title}`)
            .style(styles.title);


        let index = 4;

        report.forEach(row => {
            sheet1
                .cell(`A${index}`)
                .value(row.nameStateFund)
                .style(styles.center)
            index++;
        })

        sheet1.column("A").width(140);


        //Subtitulos
        return workbook.outputAsync().then((res) => {
            saveAs(res, `Programas y Proyectos ${title}`);
        });
    })
}
import { useEffect, useState } from "react";
import { Row, Col, Button } from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";

import ModalViewFile from "../../../../components/Modal/ModalViewFile";

import TableComponentCustom from "../../../../components/TableComponent/TableComponentCustom";
import { toast } from "react-toastify";
import Notification from "../../../../components/Notification/Notification";
import { peticionesReceiver } from "../../../../helpers/peticionesReceiver";
import { FilesInput, TextInput } from "../../../../components/GenericInputsFormik";
import { SelectTypeHeadSingle } from "../../../../components/GenericInputsFormik"
import Loader from "../../../../components/Loader/Loader";
import ModalConfirmation from "../../../../components/Modal/ModalConfirmation";
import { AddFile, DeleteArchivo } from "../functions";

export default function Documents({ data }) {
    const cabeceras = ["No", "Documento", "Descripción", "Fecha de registro", "Eliminar", "Ver"]

    const API = peticionesReceiver();

    const [modal, setModal] = useState(false);
    const [linkFile, setlinkFile] = useState();
    const [modalFile, setModalFile] = useState(false);

    const [documents, setDocuments] = useState([]);
    const [loading, setLoading] = useState(true)
    const [params, setParams] = useState();

    const defaultForm = {
        reference: data.idProperty,
        keyFileType: "",
        nameFile: "",
        file: ""
    }

    async function getDataFiles(id) {
        const params = {
            action: "multiselect",
            table: "filetypes",
            rows: "keyFileType,nameFyleType",
            conditions: `enabled = 1 AND idModule = 136 AND keyFileType NOT IN (SELECT FT.keyFileType FROM properties A INNER JOIN bookcase BC ON (BC.reference = A.idProperty) LEFT JOIN files G ON (BC.idBook = G.idBook) INNER JOIN filetypes FT ON FT.keyFileType=BC.keyFileType where A.idProperty=${id})`,
        }
        const res = await API.peticion(params)
        if (res.status === 200 && res.data.code === "200") {
            const data = res.data.data;
            setDocuments(data)
            setLoading(false)
        } else {
            setDocuments([])
            toast(<Notification type={"consultar_error"} withIcon />);
        }
    }

    useEffect(() => {
        if (data && loading) {
            setParams({
                action: "datatable",
                rows: "G.idFile,FT.nameFyleType,BC.nameFile,G.created", ///sin espacios y comas
                table: "properties A INNER JOIN bookcase BC ON (BC.reference = A.idProperty) LEFT JOIN files G ON (BC.idBook = G.idBook) INNER JOIN filetypes FT ON FT.keyFileType=BC.keyFileType",
                conditions: `A.enabled = 1 AND BC.enabled = 1 AND G.enabled = 1 AND FT.enabled = 1 AND A.idProperty=${data.idProperty} AND FT.idModule = 136`,
                page: 0,
                records: 5,
                search: "",
                orderBy: "A.idProperty desc"
            })
            getDataFiles(data.idProperty);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, loading])

    const FormSchema = Yup.object().shape({
        keyFileType: Yup.number().required("Seleccionar un tipo de archivo a subir"),
        nameFile: Yup.string().required("Ingrese una descripción"),
        file: Yup.mixed()
            .test("file-type", "El documento debe ser de tipo png, gif, jpeg o jpg", (value) => {
                const files = ["application/pdf"]
                return value && files.includes(value.type);
            })
            .test({
                message: `El documento debe pesar menos de 4MB`,
                test: (value) => value?.size < 4000000,
            })
    })

    const { handleSubmit, values, handleBlur, errors, touched, setFieldValue, resetForm } =
        useFormik({
            initialValues: defaultForm,
            onSubmit: () => setModal(true),
            validationSchema: FormSchema,
        });

    const handleFiles = event => {
        const { files } = event.target
        setFieldValue('file', files[0]);
    }

    const onChange = (e) => {
        const { name, value } = e.target
        setFieldValue([name], value);
    }

    const onRefresh = () => {
        setLoading(true)
    }

    const getDocument = async (value) => {
        const params = {
            action: "multiselect",
            table: "files",
            rows: "src",
            conditions: `enabled = 1 AND idFile=${value[0]}`,
        };
        const res = await API.peticion(params)
        if (res.status === 200 && res.data.code === "200") {
            const data = res.data.data;
            if (data.length > 0) {
                setlinkFile(res.data.data[0].src)
                setModalFile(true)
            } else {
                toast(<Notification type={"consultar_error"} withIcon />);
            }
        } else {
            toast(<Notification type={"consultar_error"} withIcon />);
        }
    }

    if (loading) {
        return (<Loader />)
    }
    return (
        <>
            <form onSubmit={handleSubmit}>
                <Row>
                    <Col xs={12} md={6}>
                        <SelectTypeHeadSingle
                            label="Documento a cargar"
                            inputName="keyFileType"
                            onChangeMethod={onChange}
                            onBlurMethod={handleBlur}
                            value={values.keyFileType}
                            optionsArray={documents}
                            optionValue="keyFileType"
                            optionName="nameFyleType"
                            errors={errors.keyFileType}
                            touched={touched.keyFileType}
                            isRequired
                        />
                    </Col>
                    <Col xs={12} md={6}>
                        <FilesInput
                            label="Adjuntar en formato pdf"
                            inputName="file"
                            fileAccept=".pdf"
                            onChangeMethod={handleFiles}
                            onBlurMethod={handleBlur}
                            value={values.file}
                            errors={values.file}
                        />
                    </Col>
                </Row>
                <Row className='align-items-end'>
                    <Col xs={12} md={8}>
                        <TextInput
                            label="Descripcion"
                            inputName="nameFile"
                            inputType="text"
                            onChangeMethod={onChange}
                            onBlurMethod={handleBlur}
                            value={values.nameFile}
                            isRequired
                            touched={touched.nameFile}
                            errors={errors.nameFile}
                        />
                    </Col>
                    <Col xs={12} md={4}>
                        <Button className='mb-3' color='success' type='submit'>Subir documento</Button>
                    </Col>
                </Row>
            </form>
            <TableComponentCustom
                titulo="Archivos registrados"
                cabecerasTabla={cabeceras}
                filtro={[true, true, true, true, true]}
                parametros={params}
                addWatch
                actionWatch={getDocument}
                addDelete
                elimina={(row) => DeleteArchivo(row, onRefresh)}

            />
            <ModalConfirmation
                modalTitle={data ? "Agregar documento" : "ERROR"}
                modal={modal}
                crear={(rows) => AddFile(rows, () => { onRefresh(); resetForm()})}
                setModal={setModal}
                isEdit={false}
                values={values}
            >
                <div className="d-flex justify-content-center">
                    <h6>¿Desea continuar con el registro?</h6>
                </div>
            </ModalConfirmation>
            {linkFile ? (
                <ModalViewFile
                    modal={modalFile}
                    setModal={setModalFile}
                    backdrop={true}
                    keyboard={true}
                    btnClose={true}
                    fileLink={linkFile}
                />
            ) : null}
        </>
    )
}
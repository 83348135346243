import React, { useState,useEffect } from "react";
import { Button, Col, Row } from "reactstrap";
import Datatable from "../../../../components/Datatable/Datatable";
import Notification from "../../../../components/Notification/Notification";
import { peticionesReceiver } from "../../../../helpers/peticionesReceiver";
import ModalReports from "../../../../components/Modal/ModalReports";
import { generatePdf } from "../pdf/PdfAcuseRecibido";

import { toast } from "react-toastify";

const Entradas = ({ reception, idArea }) => {
  const API = peticionesReceiver();

  const [warehouse, setWarehouse] = useState([]);
  const [generalData, setGeneralData] = useState([]);

  const headers = ["#", "Entrada",  "PDF"];
  const columns = [
    { data: "index" },
    { data: "entrada" },
    {
      data: null,
      render: function (row) {
        return (
          <>
            <i
              className="fa fa-file-pdf-o mr-2 cursor-pointer text-danger"
              onClick={() => getDataFile(row)}
            />
          </>
        );
      },
    },
  ];

  const getDataFile = async(row) => {
    
    const params = {
      action: "multiselect",
      table: "warehouseincomes A INNER JOIN warehouseincomereceptions B on (A.idIncome = B.idIncome) INNER JOIN receptions C on (B.idReception = C.idReception) LEFT JOIN purchasewinners D on (C.idWinner = D.idWinner) LEFT JOIN providerquotes E ON ( D.idProviderQuote = E.idProviderQuote) LEFT JOIN quotesdetail F ON (E.idDetail = F.idDetail) LEFT JOIN furnitures G on (F.idFurniture = G.idFurniture)", 
      rows: "A.idIncome,A.idPurchaserequest,B.idIncomeReception,C.idReception, concat(G.nameFurniture,' ',G.model,' ',G.brand) as bien, C.quantity, B.incomeTotal,E.total",
      conditions:`A.idIncome= ${row.idIncome} and A.enabled = 1`,
    };
    try {
      const res = await API.peticion(params);
      if (res.status === 200 && res.data.code === "200") {
        
        setWarehouse(res.data.data)

        getGeneralInfo(row)
      } else {
        toast(
          <Notification
            type={"consultar_error"}
            backMessage={"Sin registros"}
            withIcon
          />
        );
      }
    } catch (err) {
      toast(<Notification type={"consultar_servidor_error"} withIcon />);
    }
  };

  const getGeneralInfo = async (row) => {

    const params = {
      action: "datatable",
      table: `purchaserequests A LEFT JOIN purchasewinners B ON (A.idPurchaseRequest = B.idPurchaseRequest) LEFT JOIN receptions C on (B.idWinner = C.idWinner) LEFT JOIN warehouseincomereceptions D on (C.idReception = D.idReception ) LEFT JOIN warehouseincomes E on (E.idIncome = D.idIncome) LEFT JOIN areas F on (F.idArea = E.idAreaReceipt) LEFT JOIN staff G on (E.idStaffReceive = G.idStaff) LEFT JOIN providerquotes H on (H.idProviderQuote = B.idProviderQuote) LEFT JOIN quotesdetail I ON (I.idDetail = H.idDetail) LEFT JOIN furnitures J on (J.idFurniture = I.idFurniture) LEFT JOIN providers K on (K.idProvider = H.idProvider)`,
      rows: `A.idPurchaseRequest,B.idWinner, C.idReception, D.idIncomeReception, D.idIncomeReception, E.idIncome,E.numberIncome,C.endDate, F.name,concat(G.name,' ',G.pName,' ',G.mName) as empleado,concat(J.nameFurniture,' ',J.model,' ',J.brand) as articulo, C.quantity,D.incomeTotal,E.dateIncome,E.numberIncome,K.socialName,K.itin,K.email,K.telephone,H.total,D.incomeTotal`,
      conditions: `A.enabled=1 and B.enabled=1 and C.enabled=1 and D.enabled = 1 and D.enabled = 1 and A.idPurchaseRequest = ${reception.idPurchaseRequest}`,
      records: 1,
      page: 0
    };

    try {
      const res = await API.peticion(params);
      if (res.status === 200 && res.data.code === "200") {
        
        setGeneralData(res.data.data)
        setModalReport(true)
      } else {
        toast(
          <Notification
            type={"consultar_error"}
            backMessage={"Sin registros"}
            withIcon
          />
        );
      }
    } catch (err) {
      toast(<Notification type={"consultar_servidor_error"} withIcon />);
    }

  }
  

  const [modalReport, setModalReport] = useState(false);

  const [refresh, setRefresh] = useState(false);

  const columnDefs = [
    {
      targets: [0, 1, 2, 3, 4, 5],
      className: "text-center",
    },
    {
      orderable: false,
      targets: [0],
    },
  ];

  const getParamsTableElmt = (order, records, page, search) => {
    /* const params = {
      action: "datatable",
      table: `purchaserequests A INNER JOIN purchasewinners B ON (A.idPurchaseRequest = B.idPurchaseRequest) INNER JOIN receptions C on (B.idWinner = C.idWinner) INNER JOIN warehouseincomereceptions D on (C.idReception = D.idReception ) INNER JOIN warehouseincomes E on (E.idIncome = D.idIncome) INNER JOIN areas F on (F.idArea = E.idAreaReceipt) INNER JOIN staff G on (E.idStaffReceive = G.idStaff) LEFT JOIN providerquotes H on (H.idProviderQuote = B.idProviderQuote) INNER JOIN quotesdetail I ON (I.idDetail = H.idDetail) INNER JOIN furnitures J on (J.idFurniture = I.idFurniture) INNER JOIN providers K on (K.idProvider = H.idProvider)`,
      rows: `A.idPurchaseRequest,B.idWinner, C.idReception, D.idIncomeReception, D.idIncomeReception, E.idIncome,E.numberIncome,C.endDate, F.name,concat(G.name,' ',G.pName,' ',G.mName) as empleado,concat(J.nameFurniture,' ',J.model,' ',J.brand) as articulo, C.quantity,D.incomeTotal,E.dateIncome,E.numberIncome,K.socialName,K.itin,K.email,K.telephone,H.total,D.incomeTotal`,
      conditions: `A.enabled=1 and B.enabled=1 and C.enabled=1 and D.enabled = 1 and D.enabled = 1 and A.idPurchaseRequest = ${reception.idPurchaseRequest}`,
      order,
      records: records.toString(),
      page: (page * records).toString(),
      search,
    }; */
    const params = {
      action: "datatable",
      table: `warehouseincomes A`,
      rows: `A.idIncome,A.numberIncome, CONCAT(A.numberIncome,'/',A.yearIncome) AS entrada`,
      conditions: `A.idPurchaseRequest = ${reception.idPurchaseRequest} and A.enabled = 1 and A.idAreaReceipt= ${idArea} `,
      order,
      records: records.toString(),
      page: (page * records).toString(),
      search,
    };
    return params;
  };

  async function getData(order, records, page, search) {
    try {
      const res = await API.peticion(
        getParamsTableElmt(order, records, page, search)
      );
      if (res.status === 200 && res.data.code === "200") {
        const data = res.data;

        data.data.forEach((value, index) => (value.index = 1 + index));
        setWarehouse(res.data.data);

        return data;
      } else {
        toast(
          <Notification
            type={"consultar_error"}
            backMessage={res.data.message}
          />
        );
        return null;
      }
    } catch (err) {
      toast(<Notification type={"consultar_servidor_error"} withIcon />);
      return null;
    }
  }

  return (
    <div>
      <Datatable
        headers={headers}
        columns={columns}
        columnDefs={columnDefs}
        petition={getData}
        control="back"
        stateRefresh={[refresh, setRefresh]}
        searching={true}
        order={{ col: 1, opt: "desc" }}
      />

      <ModalReports
        modal={modalReport}
        setModal={setModalReport}
        title={"Acuse de recepción de Mercancia "}
        backdrop
        keyboard
        report={{generalData: generalData, warehouse: warehouse}}
        pdfConfig={{}}
        generatePdfMethod={{
          method: generatePdf,
          type: "acuseDeRecepcionDeMercancia",
        }}
      />
    </div>
  );
};

export default Entradas;

import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import { Collapse, Col, Row, Button } from "reactstrap";
import { TextInput, SelectTypeHeadSingle } from "../../../components/GenericInputsFormik/index";
import * as Yup from "yup";
import { peticionesReceiver } from "../../../helpers/peticionesReceiver";
import { toast } from "react-toastify";
import Notification from "../../../components/Notification/Notification";

import ModalConfirmation from "../../../components/Modal/ModalConfirmation";
import TableComponentOnePage from "../../../components/TableComponent/TableComponentOnePage";
import TableComponentEliminar from "../../../components/TableComponent/TableComponentEliminar";
import Widget from "../../../components/Widget/Widget";
import { GetPermissions } from "../../../libs/permissions/getPermissions";

export const ProyectosPresupuestales = (props) => {
  const [permissions, setPermissions] = useState({});
  GetPermissions(setPermissions);

  const API = peticionesReceiver();
  const [staffData] = useState({
    idStateFund: "",
    stateFundKey: "",
    nameStateFund: "",
    idArea: 0,
  });

  const [collapse, setCollapse] = useState(false);
  const [collapseArea, setCollapseArea] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [modal, setModal] = useState(false);

  //configuracion proyectos

  const [areas, setAreas] = useState([]);

  const select = {
    action: "datatable",
    table: "statefunds",
    rows: "idStateFund,stateFundKey,nameStateFund",
    conditions: "enabled = 1",
    page: 0,
    records: 5,
    search: "",
    order: "",
  };
  const [cabeceras] = useState([
    "Id",
    "Clave",
    "Proyecto Presupuestal",
    "Editar",
    "Eliminar",
  ]);
  const [filtro] = useState([true, true, true, true, true]);
  const [parametros, setParametros] = useState(select);

  const toggle = () => setCollapse(!collapse);

  const getAreas = async (vaciaValor) => {
    const params = {
      action: "multiselect",
      table: "areas",
      conditions:
        "idArea NOT IN (SELECT  idArea from statefundareas where enabled = 1) and enabled = 1",
      rows: "*",
      order: "name ASC",
    };
    API.peticion(params)
      .then((res) => {
        if (res.status === 200 && res.data.code === "200") {
          setAreas(res.data.data);
          if (vaciaValor) {
            setFieldValue("idArea", 0);
          }
        } else {
          toast(
            <Notification
              type={"consultar_error"}
              backMessage={res.data.message}
              withIcon
            />
          );
        }
      })
      .catch((err) => {
        toast(<Notification type={"consultar_servidor_error"} withIcon />);
      });
  };

  const FormSchema = Yup.object().shape({
    stateFundKey: Yup.string()
      .required("Ingrese una clave")
      .max(12, "La clave deve de contener minimo 12 caracteres")
      .min(12, "La clave deve de contener maximo 12 caracteres"),
    nameStateFund: Yup.string().required(
      "Ingrese el nombre del proyecto presupuestal"
    ),
  });

  const onChange = (e) => {
    setFieldValue([e.target.name], e.target.value);
  };
  const updateParamsArea = (id) => {
    setParametrosAreas({
      action: "datatable",
      table: "statefundareas A INNER JOIN areas B on (A.idArea = B.idArea)",
      rows: "A.idStateFundArea,B.name,B.areaKey",
      conditions: `A.enabled = 1 AND idStateFund = ${id}`,
      page: 0,
      records: 10,
      search: "",
      order: "",
    });
  };

  const {
    handleSubmit,
    handleBlur,
    values,
    errors,
    touched,
    setFieldValue,
    resetForm,
  } = useFormik({
    initialValues: staffData,
    onSubmit: (values) => {
      setModal(true);
    },
    validationSchema: FormSchema,
    enableReinitialize: true,
  });

  useEffect(() => {
    if (values.idStateFund !== "") {
      setCollapseArea(true);
    } else {
      setCollapseArea(false);
    }
  }, [values.idStateFund]);

  useEffect(() => {
    console.log("pERMISIONS HOOK", permissions);
  }, [permissions]);

  const modoEdicion = (proyecto, obj) => {
    getAreas();
    if (obj) {
      setFieldValue("idStateFund", proyecto.idStateFund);
      setFieldValue("stateFundKey", proyecto.stateFundKey);
      setFieldValue("nameStateFund", proyecto.nameStateFund);
      updateParamsArea(proyecto.idStateFund);
      setIsEditing(true);
    } else if (!collapse) {
      setFieldValue("idStateFund", proyecto[0]);
      setFieldValue("stateFundKey", proyecto[1]);
      setFieldValue("nameStateFund", proyecto[2]);
      updateParamsArea(proyecto[0]);
      setIsEditing(true);
      setCollapse(true);
    } else {
      toast(
        <Notification
          type="warning"
          backMessage="Guarde sus cambios antes de continuar"
        />
      );
    }
  };

  const limpia = () => {
    setIsEditing(false);
    setCollapse(false);
    resetForm();
  };

  const agregar = (proyecto, reset) => {
    const finalData = {
      stateFundKey: proyecto.stateFundKey,
      nameStateFund: proyecto.nameStateFund,
    };
    const data = {
      action: "insert",
      table: "statefunds",
      rows: finalData,
      validate: [["stateFundKey"], ["nameStateFund"]],
    };
    API.peticion(data)
      .then((res) => {
        if (res.status === 200 && res.data.status === "success") {
          toast(
            <Notification
              type={"agrega_exito"}
              backMessage={res.data.message}
              withIcon
            />
          );
          setParametros(select);
          modoEdicion(res.data.data[0], true);
        } else {
          toast(
            <Notification
              type={"agrega_error"}
              backMessage={res.data.message}
              withIcon
            />
          );
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const editar = (proyecto, reset) => {
    const finalData = {
      idStateFund: proyecto.idStateFund,
      stateFundKey: proyecto.stateFundKey,
      nameStateFund: proyecto.nameStateFund,
    };
    const data = {
      action: "update",
      table: "statefunds",
      rows: finalData,
      condition: {
        idStateFund: finalData.idStateFund,
      },
      /* validate: [
              ["idStaff", "idUnder","keyStat"]
             ] */
      validate: [["stateFundKey"], ["nameStateFund"]],
    };
    API.peticion(data)
      .then((res) => {
        if (res.status === 200 && res.data.status === "success") {
          toast(
            <Notification
              type={"modifica_exito"}
              backMessage={res.data.message}
              withIcon
            />
          );
          setParametros(select);
          /* reset()
            setCollapse(false) */
        } else {
          toast(
            <Notification
              type={"modifica_error"}
              backMessage={res.data.message}
              withIcon
            />
          );
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [cabecerasAreas] = useState(["Id", "Nombre", "Clave", "Eliminar"]);
  const [filtroAreas] = useState([true, true, true, true]);
  const [parametrosAreas, setParametrosAreas] = useState({
    action: "datatable",
    table: "statefundareas A INNER JOIN areas B on (A.idArea = B.idArea)",
    rows: "A.idStateFundArea,B.name,B.areaKey",
    conditions: `A.enabled = 1 AND idStateFund = ${values.stateFundKey}`,
    page: 0,
    records: 10,
    search: "",
    order: "",
  });

  const addArea = (id) => {
    if (id === 0 || id === "0") {
      toast(
        <Notification
          type={"agrega_error"}
          backMessage={"Seleccione una area "}
          withIcon
        />
      );
    } else {
      const final = {
        idStateFund: values.idStateFund,
        idArea: id,
      };
      const data = {
        action: "insert",
        table: "statefundareas",
        rows: final,
        validate: [["idStateFund", "idArea"]],
      };
      API.peticion(data)
        .then((res) => {
          if (res.status === 200 && res.data.status === "success") {
            toast(
              <Notification
                type={"agrega_exito"}
                backMessage={res.data.message}
                withIcon
              />
            );
            updateParamsArea(final.idStateFund);
            getAreas(true);
          } else {
            toast(
              <Notification
                type={"agrega_error"}
                backMessage={res.data.message}
                withIcon
              />
            );
          }
        })
        .catch((err) => {
          toast(err);
        });
    }
  };
  const actualizaElimina = () => {
    getAreas(true);
  };

  const onChangeSelect = (event) => {
    setFieldValue(event.target.name, event.target.value);
  };
  return (
    <>
      <Widget className="widget-p-md">
        {permissions.CON ? (
          <>
            <TableComponentOnePage
              titulo="Proyectos Presupuestales"
              cabecerasTabla={cabeceras}
              filtro={filtro}
              editar={modoEdicion}
              parametros={parametros}
              limpia={limpia}
              reset={resetForm}
              permissions={permissions}
            />

            <ModalConfirmation
              modalTitle={isEditing ? "Editar" : "Crear"}
              modal={modal}
              setModal={setModal}
              crear={agregar}
              editar={editar}
              isEdit={isEditing}
              values={values}
              reset={resetForm}
            >
              {isEditing ? (
                <div className="d-flex justify-content-center">
                  <h6>¿Está seguro de editar el registro?</h6>
                </div>
              ) : (
                <div className="d-flex justify-content-center">
                  <h6>¿Desea continuar con el registro?</h6>
                </div>
              )}
            </ModalConfirmation>

            {permissions.INS && (
              <div className="d-grid d-flex justify-content-center justify-content-sm-end mr-5">
                <Button
                  color="add"
                  onClick={toggle}
                  style={{ marginBottom: "1rem" }}
                  disabled={collapse}
                >
                  Agregar
                </Button>
              </div>
            )}

            <Collapse isOpen={collapse}>
              <Col className="d-grid d-flex justify-content-sm-end justify-content-center">
                <h6 className=" text-center">
                  Estado:{" "}
                  <span className="text-primary">
                    {isEditing ? "Editando" : "Guardando"}{" "}
                  </span>{" "}
                </h6>
              </Col>
              {/* Formulario */}
              <Row className="d-flex justify-content-center">
                <Col>
                  <h4 className="text-center">Proyecto presupuestal</h4>
                </Col>
              </Row>

              <form onSubmit={handleSubmit} className="mt-4" noValidate>
                <Row className="d-flex justify-content-around">
                  <Col xs={12} md={12} lg={5}>
                    <TextInput
                      label="Clave"
                      inputType="text"
                      inputName="stateFundKey"
                      onChangeMethod={onChange}
                      onBlurMethod={handleBlur}
                      value={values.stateFundKey}
                      isRequired={true}
                      touched={touched.stateFundKey}
                      errors={errors.stateFundKey}
                      isMin={0}
                    />
                  </Col>
                  <Col xs={12} md={12} lg={5}>
                    <TextInput
                      label="Nombre del proyecto presupuestal"
                      inputType="text"
                      inputName="nameStateFund"
                      onChangeMethod={onChange}
                      onBlurMethod={handleBlur}
                      value={values.nameStateFund}
                      isRequired={true}
                      touched={touched.nameStateFund}
                      errors={errors.nameStateFund}
                    />
                  </Col>
                </Row>
                <div className="row m-3">
                  <div className="col-sm-6 order-2 order-sm-1 text-center text-sm-left mb-4">
                    {!collapseArea ? (
                      <Button
                        color="danger"
                        type="reset"
                        onClick={() => limpia(resetForm)}
                      >
                        Cancelar
                      </Button>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="col-sm-6 order-1 order-sm-2 text-center text-sm-right mb-4">
                    <Button color="success" type="submit">
                      Guardar
                    </Button>
                  </div>
                </div>
              </form>
            </Collapse>

            <Collapse isOpen={collapseArea}>
              <Row className="d-flex justify-content-around">
                <Col xs={12} md={12} lg={8}>
                  <SelectTypeHeadSingle
                    label="Agregue un area"
                    optionsArray={areas}
                    inputName="idArea"
                    onChangeMethod={onChangeSelect}
                    onBlurMethod={handleBlur}
                    touched={touched.idArea}
                    errors={errors.idArea}
                    optionValue="idArea"
                    optionName="name"
                    value={values.idArea}
                  />
                </Col>
              </Row>

              <div className="col-sm-6 order-1 order-sm-2 text-center text-sm-right mb-4">
                <Button color="add" onClick={() => addArea(values.idArea)}>
                  Agregar
                </Button>
              </div>

              <TableComponentEliminar
                titulo="Areas"
                cabecerasTabla={cabecerasAreas}
                filtro={filtroAreas}
                parametros={parametrosAreas}
                limpia={actualizaElimina}
                reset={resetForm}
                permissions={permissions}
              />

              <div className="row m-3">
                <div className="col-sm-6 order-2 order-sm-1 text-center text-sm-left mb-4">
                  <Button
                    color="danger"
                    type="reset"
                    onClick={() => limpia(resetForm)}
                  >
                    Cancelar
                  </Button>
                </div>
              </div>
            </Collapse>
          </>
        ) : (
          <div className="text-center p-4">
            No tienes permitido ver los registros de esta seccion
          </div>
        )}
      </Widget>
    </>
  );
};
export default ProyectosPresupuestales;

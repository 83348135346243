import TableLink from "./TableLink/TableLink";
import { useState } from "react";
import { peticionesReceiver } from "../../../helpers/peticionesReceiver";
import { toast } from "react-toastify";
import Notification from "../../../components/Notification/Notification";

const paramsDatatable = { 
  action: "datatable",
  table: "payrollclerk A LEFT JOIN types_layaoutclass B on (A.keyLayoutClass = B.keyLayoutClass) LEFT JOIN types_payrollclerk C on (A.keyTypePayrollclerk = C.keyTypePayrollclerk)", 
  rows: "A.idPayrollclerk,C.nameTypePayrollclerk,B.nameLayoutClass,concat(A.period,'/',A.year) as fecha,A.url,A.created",
  conditions:"A.enabled = 1",
  page:0,
  records:5,
  search:"",
  order:""
};  

export const Dispersion = () => {
  const cabeceras = ["Id","Tipo de dispersion", "Tipo de layuot","Periodo/Año","Archivo", "Fecha de creacion","Editar","Eliminar"];
  const filtro = [true,true,true,true,true,true,true,true];
  const API = peticionesReceiver();
  const [parametros] = useState(paramsDatatable)

  const deletePayrollclerk = async (id,getData) => {
    
    const params = {
      action: "deleteDispersion",
      rows: {idPayrollclerk:id}
    };
    API.peticionEndPoint(params, "app/facades/payslips/payrollF.php")
      .then((res) => {
        if (res.data.status !== "success" && res.data.code !== "200") {
          toast(
            <Notification
              type={"elimina_error"}
              backMessage={res.data.message}
              withIcon
            />
          );
        }else{
          toast(
            <Notification
              type={"elimina_exito"}
              backMessage={res.data.message}
              withIcon
            />
          ); 
        }
        getData()
      })
      .catch((err) => {
        toast(<Notification type={"consultar_servidor_error"} withIcon />);
      });
  };
  
  return (
    <div>
        <TableLink
          titulo="Dispersión"
          cabecerasTabla={cabeceras}
          filtro={filtro}
          rutaCrear="/template/dispersion/crear"
          parametros={parametros}
          elimina= {deletePayrollclerk}
          addNew={true}
          addDelete={true}
          addEdit={false}
        />
    </div>
  );
};
export default Dispersion;

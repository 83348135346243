import * as Yup from "yup"
import { useFormik } from "formik";

import Widget from "../../../../components/Widget/Widget";
import ModalConfirmation from "../../../../components/Modal/ModalConfirmation";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { peticionesReceiver } from "../../../../helpers/peticionesReceiver";
import { toast } from "react-toastify";
import Notification from "../../../../components/Notification/Notification";
import { DateInput, SelectTypeHeadSingle, TextAreaInput } from "../../../../components/GenericInputsFormik";
import { Button, Spinner } from "reactstrap";
import { format } from "date-fns"
import { useHistory } from "react-router-dom";

export default function AgregarRegistroIncidencias() {
    const API = peticionesReceiver()
    const [modal, setModal] = useState(false)
    const { id } = useSelector(state => state.userInfo);
    const [loadingUser, setLoadingUser] = useState(false);
    const [types, setTypes] = useState([])
    const history = useHistory()

    const defaultForm = {
        idIncidentOrigin: "1",
        keyStat: "127",
        idStaff: "",
        idJobStaff: "",
        keyTypeIncident: "",
        dateIncident: "",
        notes: ""
    }

    const FormSchema = Yup.object().shape({
        keyTypeIncident: Yup.string().required("Seleccionar el tipo de incidente"),
        dateIncident: Yup.date().required("Ingresar la fecha del incidente"),
        notes: Yup.string().required("Ingresar la justificacion de la incidencia")
    })

    useEffect(() => {
        getStaffAttent()
        getTypes()
    }, [])

    async function getStaffAttent() {
        try {
            const res = await API.peticion({
                action: "multiselect",
                rows: "s.idStaff,js.idJobStaff",
                table: "users u join staff s on(u.idStaff = s.idStaff) inner join jobstaff js on (s.idStaff=js.idStaff AND js.valid=1)",
                conditions: `u.idUser = ${id}`
            });
            if (res.status === 200 && res.data.code === "200") {
                setFieldValue("idStaff", res.data.data[0].idStaff);
                setFieldValue("idJobStaff", res.data.data[0].idJobStaff);
                setLoadingUser(false);
            } else {
                setLoadingUser(false);
                toast(<Notification type={"consultar_error"} backMessage={res.data.message} />);
            }
        } catch (err) {
            setLoadingUser(false);
            toast(<Notification type={"consultar_servidor_error"} withIcon />);
        }
    }

    async function getTypes() {
        try {
            const res = await API.peticion({
                action: "multiselect",
                rows: "keyTypeIncident,nameTypeIncident",
                table: "types_incidents",
                conditions: `enabled=1`
            });
            if (res.status === 200 && res.data.code === "200") {
                setTypes(res.data.data)
                setLoadingUser(false);
            } else {
                setLoadingUser(false);
                toast(<Notification type={"consultar_error"} backMessage={res.data.message} />);
            }
        } catch (err) {
            setLoadingUser(false);
            toast(<Notification type={"consultar_servidor_error"} withIcon />);
        }
    }

    const { handleSubmit, values, handleBlur, errors, touched, setFieldValue, resetForm } =
        useFormik({
            initialValues: defaultForm,
            onSubmit: () => setModal(true),
            validationSchema: FormSchema,
        });

    async function addIncidencia(values) {
        const params = {
            action: "insert",
            rows: {
                ...values,
                dateIncident: format(new Date(values.dateIncident), "yyyy-MM-dd HH:mm:ss")
            },
            table: "incidents"
        }
        try {
            const res = await API.peticion(params);
            if (res.status === 200 && res.data.code === "200") {
                toast(<Notification type={"agrega_exito"} backMessage={res.data.message} withIcon />);
                regresar()
            } else {
                toast(<Notification type={"agrega_error"} backMessage={res.data.message} withIcon />);
            }
        } catch (err) {
            console.log(err)
            toast(<Notification type={"agrega_error"} withIcon />);
        }
    }

    const onChange = (e) => {
        const { name, value } = e.target
        setFieldValue([name], value);
    }

    const regresar = () => history.push("/template/registro-incidencias")

    if (loadingUser) {
        return (
            <div className="d-flex flex-column align-items-center justify-content-center" style={{ minHeight: "300px" }}>
                <Spinner animation="grow" variant="primary" />
            </div>
        )
    } else if (values.idStaff === "" || values.idJobStaff === "") {
        return (
            <div className="text-center p-4">
                El usuario necesita tener un empleado asignado y una plaza para acceder a este apartado
            </div>
        )
    }
    return (
        <Widget className="widget-p-md">
            <h3>Agregar incidencia</h3>
            <form onSubmit={handleSubmit}>
                <DateInput
                    label="Fecha"
                    inputName="dateIncident"
                    onChangeMethod={(date) => onChange({ target: { name: "dateIncident", value: new Date(date) } })}
                    value={values.dateIncident}
                    isRequired
                    touched={touched.dateIncident}
                    errors={errors.dateIncident}
                />
                <SelectTypeHeadSingle
                    label={"Tipo de incidencia"}
                    errors={errors.keyTypeIncident}
                    value={values.keyTypeIncident}
                    touched={touched.keyTypeIncident}
                    optionsArray={types}
                    inputName={"keyTypeIncident"}
                    isRequired
                    onBlurMethod={handleBlur}
                    optionName={"nameTypeIncident"}
                    optionValue={"keyTypeIncident"}
                    onChangeMethod={onChange}
                />
                <TextAreaInput
                    errors={errors.notes}
                    inputName="notes"
                    isRequired
                    label="Justificacion"
                    onBlurMethod={handleBlur}
                    onChangeMethod={onChange}
                    touched={touched.notes}
                    value={values.notes}
                    inputRows={6}
                />
                <div className="text-center">
                    <Button className="mx-2" color="danger" onClick={regresar}>Cancelar</Button>
                    <Button className="mx-2" type="submit">Agregar</Button>
                </div>
            </form>
            <ModalConfirmation
                modalTitle={"Registrar"}
                modal={modal}
                crear={addIncidencia}
                setModal={setModal}
                values={values}
            >
                <div className="d-flex justify-content-center">
                    <h6>¿Desea continuar con el registro?</h6>
                </div>
            </ModalConfirmation>
        </Widget>
    )
}
import { Formik, Form, Field, ErrorMessage, useFormikContext } from "formik";
import * as Yup from "yup";
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Button } from "reactstrap";
import ModalConfirmation from "../../../components/Modal/ModalConfirmation";
import { SelectTypeHeadSingle } from "../../../components/GenericInputsFormik/SelectTypeHeadSingle";

const defaultForm = {
  keyTypeInsurance: "",
  nameTypeInsurance: "",
  idModule: "",
};

const FormSeguros = ({ crear, data, editar }) => {
  const history = useHistory();
  const [form, setForm] = useState(defaultForm);
  const [modal, setModal] = useState(false);

  const modulos = [{ value: "25", label: "EXPEDIENTE DE PERSONAL" }, { value: "33", label: "EXPEDIENTE NOMINA" }]

  const FormSchema = Yup.object().shape({
    nameTypeInsurance: Yup.string()
      .min(3, "El nombre de la seguro debe contener al menos 3 letras")
      .required("Ingrese un nombre"),
    idModule: Yup.number()
      .required("Ingrese un tipo de modulo valido")
  });

  const submitFunction = (fields) => {
    setModal(true);
  };

  const cancelOp = () => {
    history.push("/template/seguros-nomina");

  };

  return (
    <Formik
      initialValues={form}
      validationSchema={() => FormSchema}
      onSubmit={(fields, { resetForm }) => {
        submitFunction(fields);
      }}
      onReset={(fields, { resetForm }) => {
        cancelOp();
      }}
    >
      {({ errors, status, touched, values, setFieldValue, handleBlur }) => (
        <Form className="mt-4">
          <ModalConfirmation
            modalTitle={data ? "Editar" : "Crear"}
            modal={modal}
            setModal={setModal}
            crear={crear}
            editar={editar}
            isEdit={data ? true : false}
            values={values}
          >
            {data ? (
              <div className="d-flex justify-content-center">
                <h6>¿Está seguro de editar el registro?</h6>
              </div>
            ) : (
              <div className="d-flex justify-content-center">
                <h6>¿Desea continuar con el registro?</h6>
              </div>
            )}
          </ModalConfirmation>

          <div className="form-group">
            <label htmlFor="nameTypeInsurance">
              Nombre del seguro<span className="text-danger">*</span>
            </label>
            <Field
              name="nameTypeInsurance"
              type="text"
              className={
                "form-control" +
                (errors.nameTypeInsurance && touched.nameTypeInsurance ? " is-invalid" : "")
              }
            />
            <ErrorMessage
              name="nameTypeInsurance"
              component="div"
              className="invalid-feedback"
            />
          </div>
          <SelectTypeHeadSingle
            label="Destino"
            inputName="idModule"
            onChangeMethod={(e) =>
              setFieldValue([e.target.name], e.target.value)
            }
            onBlurMethod={handleBlur}
            value={values.idModule}
            isRequired
            touched={touched.idModule}
            errors={errors.idModule}
            optionsArray={modulos}
            optionValue="value"
            optionName="label"
          />
          <div className="d-flex justify-content-between mt-5">
            <Button color="danger" type="reset">
              Cancelar
            </Button>
            <Button color="success" type="submit">
              Guardar
            </Button>
          </div>
          <FormikData info={data} modules={modulos} />
        </Form>
      )}
    </Formik>
  );
};

const FormikData = ({ info, modules }) => {
  const { setFieldValue } = useFormikContext();
  useEffect(() => {
    if (info) {
      setFieldValue("keyTypeInsurance", info[0]);
      setFieldValue("nameTypeInsurance", info[1]);
      setFieldValue("idModule", modules.find(modulo => modulo.label === info[2])?.value);
    }
  }, [info]);
  return null;
};
export default FormSeguros;

import { useEffect, useState } from "react";
import {
  Button,
  ButtonGroup,
  Modal,
  ModalHeader,
} from "reactstrap";
import FormCertificados from "./FormCert";
import FormFacial from "./FormFacial";

export default function ModalFirma({
  modal,
  setModal,
  selected,
  setselected,
  all,
  setAll,
  paramsAll,
  getConditions,
  setRefresh,
  setLoading,
  setFilesSigneds,
  setModalSigned
}) {
  const toggle = () => setModal(!modal);

  const [form, setForm] = useState(0);

  function setFormulario(option) {
    setForm(option);
  }

  return (
    <>
      <Modal
        isOpen={modal}
        toggle={toggle}
        backdrop={false}
        keyboard
        size="lg"
        centered
      >
        <ModalHeader toggle={toggle} className="bg-primary">
          <p className="text-white">Firmar documentos</p>
        </ModalHeader>
        <div>
          <ButtonGroup className="mx-2 mt-2">
            <Button outline active={form === 0} onClick={() => setFormulario(0)}>Firmado con e.firma</Button>
            <Button outline active={form === 1} onClick={() => setFormulario(1)}>Firmado con reconocimiento facial</Button>
          </ButtonGroup>
          {form === 0 ? (
            <FormCertificados
              modal={modal}
              setModal={setModal}
              selected={selected}
              setselected={setselected}
              all={all}
              setAll={setAll}
              paramsAll={paramsAll}
              getConditions={getConditions}
              setRefresh={setRefresh}
              setLoading={setLoading}
              setFilesSigneds={setFilesSigneds}
              setModalSigned={setModalSigned}
            />
          ) : (
            <FormFacial
              modal={modal}
              setModal={setModal}
              selected={selected}
              setselected={setselected}
              all={all}
              setAll={setAll}
              paramsAll={paramsAll}
              getConditions={getConditions}
              setRefresh={setRefresh}
              setLoading={setLoading}
              setFilesSigneds={setFilesSigneds}
              setModalSigned={setModalSigned}
            />
          )}
        </div>
      </Modal>
    </>
  );
}

import { Row, Col, Input } from "reactstrap"
import { useState, useEffect } from "react";
import { Pagination, PaginationItem, PaginationLink } from "reactstrap"

export default function Paggination({ filtContent, setFiltContent, pageSize, setPageSize, pagesCount, totalRegistros, content, seccionActual, setSeccionActual, setRefresh }) {

    const [pagination, setPagination] = useState([])
    const [offset, setOffset] = useState(0)

    useEffect(() => {
        const newOffset = seccionActual * pageSize;
        if (newOffset !== offset) {
            setOffset(newOffset);
        }

        const pages = Math.ceil(totalRegistros / pageSize);
        const maxPages = Math.min(pages, 5);
        const startPage = Math.max(Math.min(pages - maxPages, seccionActual - Math.floor(maxPages / 2)), 0);
        const endPage = Math.min(startPage + maxPages, pages);
        const tempPages = Array.from({ length: endPage - startPage }, (_, i) => i + startPage);
        setPagination(tempPages);
    }, [seccionActual, pageSize, offset, totalRegistros]);


    const handleOnChangeSelect = (e) => {
        setSeccionActual(0);
        setPageSize(e.target.value);
        setFiltContent((prevState) => ({
            ...prevState,
            records: e.target.value,
            currentPage: 0,
            page: 0,
        }));
        setRefresh(true);
    }

    const setContentPage = (e, index) => {
        setSeccionActual(index);
        e.preventDefault();
        setFiltContent((prevState) => ({
            ...prevState,
            page: pageSize * index,
            currentPage: index,
        }));
        setRefresh(true);
    };

    return (
        <Row className="mt-3">
            <Col sm={6} className="d-flex justify-content-start align-items-center mt-3">

                <div className="mr-3">
                    Mostrando <b>{filtContent.currentPage * seccionActual}</b> a <b>{pageSize * (seccionActual + 1) <= totalRegistros ? pageSize * (seccionActual + 1) : totalRegistros}</b> de <b>{totalRegistros}</b> registros
                </div>

                <Input
                    type="select"
                    bsSize="sm"
                    style={{ width: "78px" }}
                    value={filtContent.records}
                    onChange={handleOnChangeSelect}
                >
                    <option value={6} key={"op6pg"}>
                        6
                    </option>
                    <option value={12} key={"op12pg"}>
                        12
                    </option>
                    <option value={24} key={"op24pg"}>
                        24
                    </option>
                    <option value={48} key={"op48pg"}>
                        48
                    </option>
                    <option value={100} key={"op100pg"}>
                        100
                    </option>
                </Input>
            </Col>
            <Col sm={6} className="d-flex justify-content-center align-items-center justify-content-sm-end">

                <Pagination
                    className="pagination-borderless d-flex mt-3 "
                >
                    {pagination.length !== 0 ?
                        <PaginationItem disabled={seccionActual <= 0} className="m-0">
                            <PaginationLink
                                onClick={(e) =>
                                    setContentPage(e, filtContent.currentPage - 1)
                                }
                                previous
                            />
                        </PaginationItem>
                        : null}
                    {pagination.map((page, i) => (
                        <PaginationItem
                            key={i}
                            disabled={
                                page === seccionActual
                            }
                            className="m-1"
                        >
                            <PaginationLink
                                onClick={(e) =>
                                    setContentPage(e, page)
                                }
                                className={
                                    page === seccionActual
                                        ? "text-bold text-white bg-primary"
                                        : "text-secondary"
                                }
                            >
                                {page + 1}
                            </PaginationLink>
                        </PaginationItem>
                    ))}
                    {pagination.length !== 0 &&
                        <PaginationItem disabled={seccionActual >= Math.ceil(totalRegistros / pageSize)} className="m-0">
                            <PaginationLink
                                onClick={(e) =>
                                    setContentPage(e, filtContent.currentPage + 1)
                                }
                                next
                            />
                        </PaginationItem>
                    }
                </Pagination>
            </Col>
        </Row>
    )
}

import React from "react";
import Widget from "../../../../components/Widget/Widget";
import FormReglasHorarios from "../FormReglasHorarios";
import { createSchedule,getSchedule } from "../functions";

export const CrearReglasHorarios = () => {

  return (
    <Widget className="widget-p-md">
        <h3>Crear Reglas de Horario</h3>
        <FormReglasHorarios crear={createSchedule} obtener={getSchedule} />
    </Widget>
  )
}

export default CrearReglasHorarios;
export default function DownloadIcon({style, onClick, className}) {
    return (
        <svg width="50.172mm" height="48.859mm" version="1.1" viewBox="0 0 177.77 173.12" xmlns="http://www.w3.org/2000/svg" style={style} onClick={onClick} className={className}>
            <g transform="translate(2093.8 -556.98)">
                <g transform="matrix(.28768 0 0 .28768 -1494.5 279.53)">
                    <path d="m-1963.1 1427.2c-33.15-5.3983-56.446-16.371-77.508-36.506-27.965-26.734-42.489-60.351-42.489-98.338 0-38.165 15.466-73.347 43.731-99.485 12.686-11.731 28.663-21.316 47.766-28.655l5-1.921 0.6418-12.97c0.7328-14.811 1.8942-21.456 5.8496-33.47 15.435-46.88 52.258-80.089 101.01-91.093 8.9542-2.0212 13.076-2.362 28-2.3149 11.888 0.038 19.602 0.5405 24.055 1.5684l6.5549 1.5131 5.9451-7.1356c21.797-26.162 53.49-44.621 88.942-51.801 9.1223-1.8476 14.164-2.2184 29.503-2.1696 16.366 0.0521 19.922 0.37493 30.825 2.7985 41.758 9.2819 75.439 32.477 98.588 67.894 9.903 15.151 16.779 31.964 21.231 51.915 2.4606 11.026 2.7633 14.431 2.8723 32.306l0.1218 19.98 3.9682 2.5195c40.042 25.424 65.301 64.095 72.311 110.71 1.3917 9.2543 1.3917 32.362 0 41.617-7.5828 50.42-37.409 92.661-81.918 116.02-12.344 6.4767-24.296 10.855-39.5 14.47l-11 2.6154-181 0.1709c-99.55 0.094-182.12-0.012-183.5-0.2362zm371.5-41.655c53.01-14.901 87.48-59.299 87.49-112.69 0.01-48.678-30.994-92.038-77.524-108.42-8.0456-2.8331-7.8774-2.2408-4.1731-14.69 14.078-47.31-5.2044-99.618-46.985-127.46-2.8559-1.903-9.3809-5.465-14.5-7.9155-56.599-27.094-124.61-3.7038-152.82 52.554-1.7737 3.5377-3.3466 6.5698-3.4955 6.738s-3.3581-1.2537-7.1315-3.1598c-29.172-14.736-66.651-11.964-94.476 6.9872-36.837 25.089-52.028 73.412-35.923 114.27l2.0484 5.197-5.7552-0.632c-11.736-1.2887-32.673 3.1843-45.738 9.7716-32.736 16.505-53.517 50-53.517 86.26 0 36.655 20.492 69.66 53.578 86.291 8.9491 4.4985 19.072 7.7994 27.922 9.1043 3.3323 0.4914 84.92 0.8226 183.5 0.7448l177.5-0.1401z" />
                    <path d="m-1928.7 1565c-2.0806-0.646-5.5435-2.8832-7.6954-4.9716-5.9065-5.7325-6.2215-8.4613-5.8463-50.644 0.309-34.735 0.4257-36.694 2.4138-40.5 1.1489-2.2 3.9996-5.35 6.3347-7l4.2458-3 50.673-0.3075c27.87-0.1691 51.66 0.058 52.867 0.5041 1.2067 0.4464 9.5523 8.0098 18.546 16.808 12.984 12.702 17.679 16.642 22.798 19.132 7.9664 3.8757 21.916 5.4051 25.968 3.8319 17.099-4.2133 24.605-14.774 33.239-23.313l16.76-16.656 46.74-0.3093c25.707-0.1701 49.566-0.02 53.021 0.3329 7.72 0.7894 13.634 4.8042 16.928 11.492 2.1558 4.377 2.2097 5.3761 2.25 41.652 0.038 34.267-0.1059 37.505-1.844 41.5-2.4132 5.5467-6.4403 9.2856-12.115 11.248-4.0028 1.3842-22.014 1.5461-163 1.4658-118.33-0.067-159.46-0.388-162.28-1.2648zm251.31-27.335c4.8826-3.3951 5.9042-5.4589 5.9392-11.998 0.031-5.7687-0.2142-6.4641-3.401-9.651-6.5032-6.5032-16.011-5.4539-21.316 2.3526-3.9806 5.8574-2.3551 14.529 3.5497 18.936 3.4902 2.6051 11.719 2.7995 15.228 0.3597zm54.149-0.059c5.0043-3.3004 6.8234-6.6034 6.8234-12.39 0-4.4011-0.4699-5.8593-2.6433-8.2032-6.51-7.0208-13.984-7.0541-20.945-0.093-3.8443 3.8443-4.411 4.9619-4.4052 8.6881 0.012 7.8179 6.0567 13.763 13.993 13.763 2.623 0 5.6164-0.7363 7.1766-1.7653zm-151.18-69.999c-1.925-1.4972-25.212-24.442-51.75-50.988l-48.544-48.266c-8.418-8.6596-4.3976-18.956 6.0724-18.469l61.722-1 0.5-50c0.4699-46.993 0.6142-50.168 2.3985-52.801 3.7607-5.5487 4.773-5.6993 38.302-5.6952 28.733 0 31.014 0.1341 33.986 1.9465 5.9255 3.6131 5.7705 2.2251 6.3138 56.55l0.5 50 29.5 0.5c32.769 0.5554 33.641 0.3066 37.371 6.8709 2.2091 3.8885 1.0296 6.954-0.3933 10.688-1.0615 2.7922-96.404 98.604-101.19 101.69-4.1666 2.6858-10.584 2.2397-14.785-1.0278z" />
                    <path d="m-1877.5 1365.8 76.868 75.898-37.391-3.0914-57.706-58.294z" fill="none" fillRule="evenodd" />
                    <path d="m-1716.2 1490.5-4.1218-2.0609" fill="none" stroke="#000" strokeWidth="1px" />
                    <path d="m-1660 1369.8-76.868 75.898 37.391-3.0914 57.706-58.294z" fill="none" fillRule="evenodd" />
                </g>
            </g>
        </svg>
    )
}
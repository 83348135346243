import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Notification from "../../../components/Notification/Notification";
import { peticionesReceiver } from "../../../helpers/peticionesReceiver";
import { useHistory } from "react-router-dom";
import Loader from "../../../components/Loader/Loader";
import { SelectTypeHeadSingle } from "../../../components/GenericInputsFormik/index";
import { Button, Col, Row } from "reactstrap";
import { useFormik } from "formik";
import axios from "axios";
import { peticionEncript } from "../../../helpers/peticionesEncripted";
import ModalConfirmation from "../../../components/Modal/ModalConfirmation";
import * as Yup from "yup";
import DatePicker from "react-datepicker";

const PresupuestosAgregar = () => {
  const date = new Date().getFullYear();
  const API = peticionesReceiver();
  const history = useHistory();
  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [searchFilters, setSearchFilters] = useState({
    budgetTypes: ''
  });

  const formDatos = {
    year: '',
    keyBudgetType: ''
  };

  const FormSchema = Yup.object().shape({
    year: Yup.string()
      .min(4, "El Año debe estar constituido por 4 digitos")
      .required("Favor de ingresar el año"),
    keyBudgetType: Yup.string().required(
      "Seleccione un tipo de presupuesto"
    ),
  });

  const getBudgetTypes = async () => {
    const params = {
      action: "select",
      table: "budget_types",
      condition: {
        enabled: 1,
      },
      order: "nameBudgetType ASC",
    };
    API.peticion(params)
      .then((res) => {
        if (res.status === 200 && res.data.code === "200") {
          setSearchFilters((prevState) => ({
            ...prevState,
            budgetTypes: res.data.data,
          }));
          setLoading(false)
        } else {
          toast(<Notification type={"consultar_error"} withIcon />);
        }
      })
      .catch((err) => {
        toast(<Notification type={"consultar_servidor_error"} withIcon />);
      });
  };

  useEffect(() => {
    getBudgetTypes();
  }, [])

  const enviaDatos = () => {
    setModal(true);
  };

  const crear = async () => {
    const params = {
      action: "saveAnnualBudget",
      year: values.year.getFullYear(),
      keyBudgetType: values.keyBudgetType,
    };
    const finalData = peticionEncript(params);
    await axios
      .post(
        `${process.env.REACT_APP_API}app/facades/budgets/budgetsF.php`,
        finalData
      )
      .then((res) => {
        if (res.status === 200 && res.data.code === "200") {
          localStorage.setItem("idDetail", res.data.data[0].idAnualBudget);
          history.push({
            pathname:
              "/template/presupuestos/presupuestos-dependientes",
          });
        } else {
          toast(
            <Notification
              type={"consultar_servidor_error"}
              backMessage={res.data.message}
              withIcon
            />
          );
        }
      })
      .catch((err) => {
        toast(<Notification type={"consultar_servidor_error"} withIcon />);
      });
  };

  const goBack = () => {
    resetForm();
    history.push({
      pathname: "/template/presupuestos",
    });
  };

  const OnChangeSelect = event => {
    setFieldValue(event.target.name, event.target.value);
  };

  const onChangeDate = (e) => {
    setFieldValue(e.name, e.value);
  };

  const {
    handleSubmit,
    values,
    handleBlur,
    errors,
    touched,
    setFieldValue,
    resetForm,
  } = useFormik({
    initialValues: formDatos,
    onSubmit: () => {
      enviaDatos();
    },
    validationSchema: FormSchema,
    enableReinitialize: true,
  });

  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <form onSubmit={handleSubmit} className="mt-4" noValidate>
          <ModalConfirmation
            modalTitle={"Crear"}
            modal={modal}
            setModal={setModal}
            crear={crear}
            isEdit={false}
            values={values}
          >
            <div className="d-flex justify-content-center">
              <h6>¿Desea continuar con el registro?</h6>
            </div>
          </ModalConfirmation>
          <Row>
            <Col xs={12} md={6}>
              <label htmlFor={values.year}>
                {" "}
                Año <span className="text-danger"> * </span>{" "}
              </label>
              <DatePicker
                minDate={new Date(`${date}`)}
                selected={values.year}
                onChange={(date) => onChangeDate({ name: "year", value: date })}
                showYearPicker
                dateFormat="yyyy"
                yearItemNumber={6}
                className={
                  "form-control" +
                  (errors.year && touched.year ? " is-invalid" : "")
                }
              />
            </Col>
            <Col xs={12} md={6}>
              <SelectTypeHeadSingle
                label="Tipo de presupuesto"
                isRequired
                optionsArray={searchFilters.budgetTypes}
                inputName="keyBudgetType"
                onChangeMethod={OnChangeSelect}
                value={values.keyBudgetType}
                optionValue="keyBudgetType"
                optionName="nameBudgetType"
                onBlur={handleBlur}
                touched={touched.keyBudgetType}
                errors={errors.keyBudgetType}
                defaultOption="Seleccione un presupuesto"
                isDisabled={false}
              />
            </Col>
          </Row>
          <div className="row mt-3">
            <div className="col-sm-6 order-2 order-sm-1 text-center text-sm-left mb-4">
              <Button color="danger" type="reset" onClick={() => goBack()}>
                {" "}
                Cancelar{" "}
              </Button>
            </div>

            <div className="col-sm-6 order-1 order-sm-2 text-center text-sm-right mb-4">
              <Button color="success" type="submit">
                {" "}
                Guardar{" "}
              </Button>
            </div>
          </div>
        </form>
      )}
    </div>
  );
};
export default PresupuestosAgregar;

import * as XlsxPopulate from "xlsx-populate";
import { saveAs } from "file-saver";
import getLayout from "../../../../components/excel/layoutExcel";
import { getStaffPic } from "../../../../libs/tools/images";

const headers = [
  "FOTOGRÁFIA",
  "NUMERO EMPLEADO",
  "NOMBRE COMPLETO",
  "ADSCRIPCIÓN",
  "RANGO",
  "PUESTO LABORAL",
  "SEXO",
  "SUELDO BASE",
  "SUELDO BRUTO",
  "SUELDO NETO",
  "GRATIFICACION",
  "DESPENSA",
  "FORTALECIMIENTO",
  "COMPENSACION",
  "ISR",
  "ISSEMYM",
  "TIPO PAGO",
  "TIPO DE DEPOSITO",
  "BANCO",
  "CUENTA BANCARIA",
  "SINDICALIZADO",
  "FECHA INGRESO",
  "TIPO NOMBRAMIENTO",
  "FECHA INICIO NOMBRAMIENTO",
  "FECHA FIN NOMBRAMIENTO",
  "DIRECCION",
  "CURP",
  "RFC",
  "CLAVE ISSEMYM",
];

const fmtMny = new Intl.NumberFormat("es-MX", {
  style: "currency",
  currency: "MXN",
});

const s = {
  tC: { horizontalAlignment: "center", verticalAlignment: "center" },
  tS: { horizontalAlignment: "left" },
  tE: { horizontalAlignment: "right", verticalAlignment: "center" },
  tt: {
    horizontalAlignment: "center",
    verticalAlignment: "bottom",
    wrapText: true,
    shrinkToFit: true,
    bold: true,
  },
  tN: {
    border: true,
    wrapText: true,
    shrinkToFit: true,
    verticalAlignment: "center",
  },
  tNC: {
    horizontalAlignment: "center",
    verticalAlignment: "center",
    wrapText: true,
    shrinkToFit: true,
    border: true,
  },
  tNE: {
    horizontalAlignment: "right",
    verticalAlignment: "center",
    wrapText: true,
    shrinkToFit: true,
    border: true,
  },
  header: {
    horizontalAlignment: "center",
    bold: true,
    verticalAlignment: "center",
    fill: "730A29",
    fontColor: "FFFFFF",
    wrapText: true,
    shrinkToFit: true,
    border: true,
  },
  tB: { bold: true },
  wt: { wrapText: true, verticalAlignment: "center" },
};

export async function empleadosUnidad(data, config) {
  const excel = await getLayout();
  const cfg = config.cfg;
  const stc = data.length > 4000 ? s.tC : s.tNC;
  const sts = data.length > 4000 ? s.tS : s.tN;
  const ste = data.length > 4000 ? s.tE : s.tNE;
  const ftC = cfg.photo ? 0 : -1;
  if (excel)
    XlsxPopulate.fromDataAsync(excel).then(async (workbook) => {
      const hoja = workbook.sheet(0);
      let row = 4;
      hoja
        .range(`A${row}:H${row}`)
        .merged(true)
        .value("PLANTILLA DE EMPLEADOS")
        .style(s.tt);
      row++;
      hoja.range(`A${row}:H${row}`).merged(true).value(clearOptions(cfg.options)).style(s.tt);
      row++;

      row += 2;

      for (let i = 0; i < headers.length; i++) {
        hoja.column(i + 1).width([3+ftC, 4+ftC, 6+ftC, 26+ftC].includes(i + 1) ? 50 : [5+ftC, 6+ftC].includes(i + 1) ? 20 : 25);
        if(i !== 0 || cfg.photo) hoja.row(row).cell(i + 1 + ftC).value(headers[i]).style(s.header);
      }

      data.forEach((d, ix) => {
        row++;
        if(cfg.photo) hoja.row(row).cell(ftC + 1).value(getStaffPic(d.nameGender, d.foto)).style(sts);
        hoja.row(row).cell(ftC + 2).value(d.staffNumber).style(sts);
        hoja.row(row).cell(ftC + 3).value(d.nombre).style(sts);
        hoja.row(row).cell(ftC + 4).value(d.nameArea).style(sts);
        hoja.row(row).cell(ftC + 5).value(d.rango).style(sts);
        hoja.row(row).cell(ftC + 6).value(d.nameJob).style(sts);
        hoja.row(row).cell(ftC + 7).value(d.nameGender).style(sts);
        hoja.row(row).cell(ftC + 8).value(fMoney(d.base)).style(sts);
        hoja.row(row).cell(ftC + 9).value(fMoney(d.gross)).style(sts);
        hoja.row(row).cell(ftC + 10).value(fMoney(d.net)).style(sts);
        hoja.row(row).cell(ftC + 11).value(fMoney(d.reward)).style(sts);
        hoja.row(row).cell(ftC + 12).value(fMoney(d.larder)).style(sts);
        hoja.row(row).cell(ftC + 13).value(fMoney(d.fortalecimiento)).style(sts);
        hoja.row(row).cell(ftC + 14).value(fMoney(d.clearance)).style(sts);
        hoja.row(row).cell(ftC + 15).value(fMoney(d.taxes)).style(sts);
        hoja.row(row).cell(ftC + 16).value(fMoney(d.ssecurity)).style(sts);
        hoja.row(row).cell(ftC + 17).value(d.namePaymentType).style(sts);
        hoja.row(row).cell(ftC + 18).value(d.nameAccountType).style(sts);
        hoja.row(row).cell(ftC + 19).value(d.nameBank).style(sts);
        hoja.row(row).cell(ftC + 20).value(d.account).style(sts);
        hoja.row(row).cell(ftC + 21).value(+d.unionized === 1? "SI":"NO").style(sts);
        hoja.row(row).cell(ftC + 22).value(fDate(d.startJobDate)).style(sts);
        hoja.row(row).cell(ftC + 23).value(d.nameAssignment).style(sts);
        hoja.row(row).cell(ftC + 24).value(fDate(d.startDate)).style(sts);
        hoja.row(row).cell(ftC + 25).value(fDate(d.endDate)).style(sts);
        hoja.row(row).cell(ftC + 26).value(`${d.street} #${d.extNumber} ${d.intNumber !== "" && `int. ${d.intNumber}`} ${d.subStreet}, ${d.nameCity} ${d.nameCounty} ${d.nameState}`).style(sts);
        hoja.row(row).cell(ftC + 27).value(d.curp).style(sts);
        hoja.row(row).cell(ftC + 28).value(d.rfc).style(sts);
        hoja.row(row).cell(ftC + 29).value(d.socialSecurityKey).style(sts);
      });

      return workbook.outputAsync().then((res) => {
        saveAs(res, `Plantilla de Empeados por Unidad.xlsx`);
      });
    });
}

function clearOptions(opt){
  const clrOpt = opt.filter(e => e !== "");
  let tt = clrOpt.join(" - ");
  return tt;
}

function fMoney(d){
  if(+d !== NaN) return fmtMny.format(d);
  return fmtMny.format(0);
}

function fDate(d){
  return d.split("-").reverse().toString().replaceAll(",", "/");
}; 

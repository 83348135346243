import { toast } from "react-toastify";
import axios from "axios";

import Notification from "../../../../components/Notification/Notification";
import { peticionesReceiver } from "../../../../helpers/peticionesReceiver";


const API = peticionesReceiver();

async function AddInvoice(data) {
    const rows = { idProvider: data.idProvider, taxNumber: data.taxNumber, numberInvoice: data.numberInvoice };
    const params = {
        action: "insert",
        table: "invoices",
        rows,
        force: "1"
    };
    try {
        const res = await API.peticion(params)
        if (res.status === 200 && res.data.code === "200") {
            toast(<Notification type={"agrega_exito"} backMessage={res.data.message} withIcon />);
            return res.data.data[0]
        } else {
            toast(<Notification type={"agrega_error"} backMessage={res.data.message} withIcon />);
            return null
        }
    } catch (err) {
        toast(<Notification type={"consultar_servidor_error"} withIcon />);
        return null;
    };
}

async function SubirArchivo(formData) {
    const params = {
        method: "post",
        url: `${process.env.REACT_APP_API}app/facades/files/fileReceiver.php`,
        data: formData,
    };
    try {
        const res = await axios(params);
        if (res.data.code === "200" && res.data.status === "success") {
            toast(<Notification type={"agrega_exito"} withIcon />);
            return { status: true, id: res.data.data }
        } else {
            toast(<Notification type={"agrega_error"} withIcon />);
            return { status: false };
        }
    } catch (err) {
        toast(<Notification type={"agrega_error"} withIcon />);
        return { status: false };
    }
}

async function AddPDF(rows, invoice) {
    const formData = new FormData();
    formData.append("action", "saveFile");
    formData.append("keyFileType", 40);
    formData.append('binary', rows.pdf);
    formData.append('reference', invoice.idInvoice);
    const res = await SubirArchivo(formData);
    return res;
}

async function AddXML(rows, invoice) {
    const formData = new FormData();
    formData.append("action", "saveFile");
    formData.append("keyFileType", 39);
    formData.append('binary', rows.xml);
    formData.append('reference', invoice.idInvoice);
    const res = await SubirArchivo(formData);
    return res;
}

async function AddInventory(rows, idInvoice) {
    const params = {
        action: "saveInventory",
        idInvoice,
        rows,
    };
    try {
        const res = await API.peticionEndPoint(params, "app/facades/inventory/inventoryF.php")
        if (res.status === 200 && res.data.code === "200") {
            toast(<Notification type={"agrega_exito"} backMessage={res.data.message} withIcon />);
            return true;
        } else {
            toast(<Notification type={"agrega_error"} backMessage={res.data.message} withIcon />);
            return false;
        }
    } catch (err) {
        toast(<Notification type={"consultar_servidor_error"} withIcon />);
        return false;
    };
}

export const CreateInventory = async (data, bienes, reset) => {
    const invoice = await AddInvoice(data)
    if (invoice) {
        const res1 = await AddPDF(data, invoice);
        const res2 = await AddXML(data, invoice);
        if (res1.status && res2.status) {
            const rows = bienes.map(value => {
                const bien = { idFurniture: value.idFurniture, keyReasonUp: data.keyReason, keyStat: value.keyStat, serialKey: value.serialKey, purchasePrice: value.actualPrice }
                return bien
            })
            const res = await AddInventory(rows, invoice.idInvoice)
            if (res) {
                toast(<Notification type={"agrega_exito"} withIcon />);
                reset()
            } else {
                const params = {
                    action: "delete",
                    table: "invoices",
                    condition: {
                        idInvoice: invoice.idInvoice,
                    }
                }
                await API.peticion(params);
            }
        } else {
            const params = {
                action: "delete",
                table: "invoices",
                condition: {
                    idInvoice: invoice.idInvoice,
                }
            }
            await API.peticion(params);
        }
    }
}

export const getFile = async (typeFile, ref) => {
    const params = {
        action: "multiselect",
        table: "bookcase b join files f on (b.idBook = f.idBook) join invoices i on (b.reference = i.idInvoice) join inventories inv on (inv.idInvoice = i.idInvoice)",
        rows: "f.src",
        conditions: `b.enabled = 1 AND f.enabled AND b.keyFileType = ${typeFile} AND inv.idInventory=${ref}`,
    };
    const res = await API.peticion(params)
    if (res.status === 200 && res.data.code === "200") {
        const data = res.data.data;
        if (data.length > 0) {
            return res.data.data[0].src
        } else {
            toast(<Notification type={"consultar_error"} withIcon />);
            return null
        }
    } else {
        toast(<Notification type={"consultar_error"} withIcon />);
        return null
    }
}
/**
 * Funcion que suma un arreglo dado el objeto y la key a sumar
 * @param {*} array Arreglo de objetos
 * @param {*} key llave del objeto
 * @returns suma total
 */
export function sumValues(array, key) {
    const initialValue = 0;
    if (array) {
        return array.reduce((previousValue, currentValue) => previousValue + Number(currentValue[key]), initialValue);
    } else {
        return initialValue;
    }
}
import React, { useState, useEffect } from "react";
import { Button, Collapse } from "reactstrap";
import { peticionesReceiver } from "../../../helpers/peticionesReceiver";
import ModalDelete from "../../../components/Modal/ModalDelete";
import Datatable from "../../../components/Datatable/Datatable";
import { toast } from "react-toastify";
import Notification from "../../../components/Notification/Notification";
import { Loader } from "react-bootstrap-typeahead";
import { GetPermissions } from "../../../libs/permissions/getPermissions";
import Widget from "../../../components/Widget/Widget";
import { useSelector } from "react-redux";
import FormOrdenPago from "./FormOrdenPago";

const OrdenPago = () => {
  const [permissions, setPermissions] = useState(true);
  const [loading, setLoading] = useState(false);

  GetPermissions(setPermissions, setLoading);

  const API = peticionesReceiver();
  const [collapse, setCollapse] = useState(false);
  const toggleCollapse = () => setCollapse(!collapse);
  const [data, setData] = useState("");
  const { profileType } = useSelector((state) => state.userInfo);
  const idAreaLogged = profileType.selectProfile.idArea;

  const [refresh, setRefresh] = useState(false);

  const headers = [
    "#",
    "ORDEN",
    "OBSERVACIONES",
    "FECHA DE ORDEN",
    "ESTATUS",
    "ACCIONES",
  ];

  const columns = [
    { data: "index" },
    {
      data: null,
      render: function (row) {
        return `${row.numberOrder} / ${row.yearOrder}`;
      },
    },
    { data: "comments" },
    { data: "created", format: "date" },
    { data: "nameStat" },
    {
      data: null,
      render: function (row) {
        return (
          <td
            onClick={() => {
              changeDataForEdit(row);
            }}
            className="text-center d-flex justify-content-center"
          >
            <i className={" fa fa-eye text-info handCursor"} />
          </td>
        );
      },
    },
  ];

  const changeDataForEdit = (row) => {
    if (!collapse) {
      setData(row);
      setCollapse(true);
    } else {
      toast(
        <Notification
          type="warning"
          backMessage="Guarde sus cambios antes de continuar"
        />
      );
    }
  };

  useEffect(() => {
    if (collapse === false) {
      setData("");
    }
  }, [collapse]);

  const deleteAgentTopic = async (obj) => {
    const params = {
      action: "delete",
      table: "topicagents",
      force: "1",
      condition: { idUser: obj.idUser },
    };

    if (collapse) {
      toast(
        <Notification
          type="warning"
          backMessage="Guarde sus cambios antes de continuar"
        />
      );
    } else {
      await API.peticion(params)
        .then((res) => {
          if (res.data.status === "success" && res.data.code === "200") {
            toast(
              <Notification
                type={"elimina_exito"}
                backMessage={res.data.message}
                withIcon
              />
            );
            setRefresh(true);
          } else {
            toast(
              <Notification
                type={"elimina_error"}
                backMessage={res.data.message}
                withIcon
              />,
              { closeButton: false }
            );
          }
        })
        .catch((err) => {
          toast(<Notification type={"consultar_servidor_error"} withIcon />);
        });
    }
  };

  const columnDefs = [
    {
      targets: [0, 1, 2, 3, 4, 5],
      className: "text-center",
    },
    {
      orderable: false,
      targets: [0],
    },
  ];

  const getParamsTableElmt = (order, records, page, search) => {
    const params = {
      action: "datatable",
      table: "payorders A LEFT JOIN  status B on (A.keyStat = B.keyStat) INNER JOIN staff C on (A.idStaffApproves = C.idStaff) INNER JOIN jobstaff  E ON (E.idStaff = C.idStaff)  INNER JOIN areas F ON (E.idArea = F.idArea) INNER JOIN jobs G ON (G.keyJob= E.keyJob)",
      rows: "A.idOrder,B.nameStat,A.numberOrder,A.comments,A.dateSend,A.datePaid,A.created,B.nameStat,A.yearOrder,concat(C.name,' ',C.pName,'',C.mName) as fullName,C.idStaff,C.staffNumber,F.name as nameArea,G.nameJob,A.keyStat",
      conditions: `A.enabled = 1 and B.enabled = 1`,
      order,
      group: "A.idOrder",
      records: records.toString(),
      page: (page * records).toString(),
      search,
    };
    return params;
  };

  async function getData(order, records, page, search) {
    try {
      const res = await API.peticion(
        getParamsTableElmt(order, records, page, search)
      );
      if (res.status === 200 && res.data.code === "200") {
        const data = res.data;
        data.data.forEach((value, index) => (value.index = 1 + index));
        return data;
      } else {
        toast(
          <Notification
            type={"consultar_error"}
            backMessage={res.data.message}
          />
        );
        return null;
      }
    } catch (err) {
      toast(<Notification type={"consultar_servidor_error"} withIcon />);
      return null;
    }
  }

  return (
    <Widget className="widget-p-md">
      {loading ? (
        <Loader />
      ) : (
        <>
          {permissions.CON ? (
            +idAreaLogged === 6 ? (
              <>
              <div className="text-center mb-5">
                <h1>Ordenes de pago</h1>
              </div>

              <Collapse isOpen={!collapse} className="mt-3">
                {!collapse && (
                  <div>
                    <Datatable
                      headers={headers}
                      columns={columns}
                      columnDefs={columnDefs}
                      petition={getData}
                      control="back"
                      stateRefresh={[refresh, setRefresh]}
                      searching={true}
                      order={{ col: 1, opt: "desc" }}
                    />
                  </div>
                )}
              </Collapse>

              <div className="d-flex justify-content-end mb-3">
                <Button
                  color={collapse ? "danger" : "success"}
                  onClick={() => toggleCollapse()}
                >
                  {collapse ? "Regresar" : "Agregar"}
                </Button>
              </div>

              <Collapse isOpen={collapse} className="mt-3">
                {collapse && (
                  <FormOrdenPago
                    collapse={collapse}
                    setCollapse={setCollapse}
                    setRefreshP={setRefresh}
                    data={data}
                    setData={setData}
                    permissions={permissions}
                    idAreaLogged={idAreaLogged}
                  />
                )}
              </Collapse>
            </>

            ): (
              <div className="text-center">
             SOLO EL AREA DE ADQUISICIONES PUEDE ACCEDER A ESTE MODULO
            </div>

            )
            
          ) : (
            <div className="text-center">
              No tiene permisos para consultar este modulo
            </div>
          )}
        </>
      )}
    </Widget>
  );
};

export default OrdenPago;

import axios from "axios";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Col, Container, Row, Table } from "reactstrap";
import Notification from "../../../../components/Notification/Notification";
import { peticionEncript } from "../../../../helpers/peticionesEncripted";
import FullFormLoader from "../../../../components/Loader/FullFormLoader";

const formatter = new Intl.NumberFormat("es-MX", {
  style: "currency",
  currency: "MXN",
});

async function consulta(params) {
  const finalData = peticionEncript(params);
  let info = [];
  await axios
    .post(process.env.REACT_APP_API + "receiver/receiver.php", finalData)
    .then((res) => {
      if (res.status === 200 && res.data.code === "200") {
        info = res.data.data;
      } else {
        toast(
          <Notification
            type={"consultar_error"}
            backMessage={res.data.message}
            withIcon
          />,
          {
            closeButton: false,
          }
        );
      }
    })
    .catch((err) => {
      toast(<Notification type={"consultar_servidor_error"} withIcon />, {
        closeButton: false,
      });
    });
  return info;
}

const TabDeposito = ({ info, tt, dt }) => {
  return (
    <>
      <div className="text-center mt-2">{tt}</div>
      <Table borderless responsive>
        <thead>
          <tr className="text-center">
            <th className="w-25">Deposito</th>
            <th className="w-50">Banco</th>
            <th className="w-25">Importe</th>
          </tr>
        </thead>
        <tbody>
          {info.data.map((d, i) => (
            <tr key={"R" + i}>
              <td className="text-center">{d.totalStaff}</td>
              <td>{d.nameBank}</td>
              <td className="text-right">{formatter.format(d.total)}</td>
            </tr>
          ))}
          <tr className="border-top border-dark">
            <td className="text-center">{dt.ttp}</td>
            <td colSpan={2} className="text-right">{formatter.format(dt.dep)}</td>
          </tr>
        </tbody>
      </Table>
    </>
  );
};

const Reporte20Web = (props) => {
  const { data, cfg } = props;
  const [loading, setLoading] = useState(true);
  const [employees, setEmployees] = useState("0");
  const [pensions, setPensions] = useState("0");
  const [ban, setBan] = useState({});
  const [pen, setPen] = useState({});

  async function countEmployees() {
    const params = {
      action: "multiselect",
      table: "staff S INNER JOIN jobstaff J ON J.idStaff = S.idStaff",
      rows: "COUNT(S.idStaff) cant",
      conditions: "S.enabled = 1 AND J.valid = 1 AND J.enabled = 1",
      order: "",
    };
    const cant = await consulta(params);
    if (cant.length !== 0) setEmployees(cant[0].cant);
  }

  async function countPensions() {
    const params = {
      action: "multiselect",
      table:
        "staff S INNER JOIN pensions P ON P.idStaff = S.idStaff INNER JOIN jobstaff J ON J.idStaff = S.idStaff ",
      rows: "COUNT(S.idStaff) cant",
      conditions:
        "S.enabled = 1 AND S.keyStat NOT IN (2, 7, 9, 10, 11, 12, 13) AND J.enabled = 1 AND J.valid = 1 AND P.enabled = 1",
      order: "",
    };
    const cant = await consulta(params);
    if (cant.length !== 0) return setPensions(cant[0].cant);
  }

  useEffect(() => {
    if (data !== undefined) {
      setBan({
        dep: +data.depositos.totalDepositos,
        ttp: +data.depositos.totalPersonas,
        che: +data.cheques.totalCheques,
        cche: +data.cheques.totalRows
      });
      setPen({
        dep: +data.pensionesDeposito.totalPensiones,
        ttp: +data.pensionesDeposito.totalPersonas,
        che: +data.pensionesCheque.totalCheques,
        cche: +data.pensionesCheque.totalRows
      });
      countEmployees().then(() => {
        countPensions().then(() => {
          setLoading(false);
        });
      });
    }
  }, [data]);

  if (loading) {
    return (<FullFormLoader
      show={loading}
      message={"Consultando datos , espere un momento"}
    />)
  }

  return (
    <Container className="mt-4">
      <h2 className="text-secondary text-sm-center text-md-left">
        Resumen global de Nómina
      </h2>
      <Row>
        <Col xs={12} lg={6}></Col>
        <Col xs={12} lg={6}>
          <Table borderless size="sm" className="w-100" responsive>
            <tbody>
              <tr>
                <td>
                  <strong>QUINCENA: </strong>
                </td>
                <td className="text-right">
                  <p>{cfg.period}</p>
                </td>
              </tr>
              <tr>
                <td>
                  <strong>SERVIDORES PUBLICOS:</strong>
                </td>
                <td className="text-right">
                  <p>{employees}</p>
                </td>
              </tr>
              <tr>
                <td>
                  <strong>PENSIONES ALIMENTICIAS:</strong>
                </td>
                <td className="text-right">
                  <p>{pensions}</p>
                </td>
              </tr>
            </tbody>
          </Table>
        </Col>
      </Row>
      <div className="mt-4">
        <div className="bg-secondary text-light text-center">
          IPORTE A PAGAR
        </div>
        <div className="mt-1 border border-dark">
          <Row className="mx-3">
            <Col xs={12} lg={6} className="">
              <Table borderless size="sm">
                <tbody>
                  <tr>
                    <td colSpan={3} className="text-center">
                      SERVIDORES PUBLICOS
                    </td>
                  </tr>
                  <tr>
                    <td className="w-25 text-right">BANCO:</td>
                    <td className="w-25"></td>
                    <td className="w-50 border text-right">
                      {formatter.format(ban.dep)}
                    </td>
                  </tr>
                  <tr>
                    <td className="w-25 text-right">CHEQUE:</td>
                    <td className="w-25 text-center">{ban.cche}</td>
                    <td className="w-50 border text-right">
                      {formatter.format(ban.che)}
                    </td>
                  </tr>
                  <tr className="mt-4">
                    <td className="w-25"></td>
                    <td colSpan={2} className="w-75 text-right">
                      <div className="mt-1 border-top border-dark">
                        {formatter.format(ban.dep + ban.che)}
                      </div>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Col>
            <Col xs={12} lg={6} className="">
              <Table borderless size="sm">
                <tbody>
                  <tr>
                    <td colSpan={3} className="text-center">
                      PENSIONES ALIMENTARIAS
                    </td>
                  </tr>
                  <tr>
                    <td className="w-25 text-right"></td>
                    <td className="w-25"></td>
                    <td className="w-50 border text-right">
                      {formatter.format(pen.dep)}
                    </td>
                  </tr>
                  <tr>
                    <td className="w-25 text-right"></td>
                    <td className="w-25 text-center">{pen.cche}</td>
                    <td className="w-50 border text-right">
                      {formatter.format(pen.che)}
                    </td>
                  </tr>
                  <tr className="mt-4">
                    <td className="w-25"></td>
                    <td colSpan={2} className="w-75 mt-1 text-right">
                      <div className="mt-1 border-top border-dark">
                        {formatter.format(pen.dep + pen.che)}
                      </div>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Col>
          </Row>

          <Row className="mt-3 mb-2 justify-content-end mx-3">
            <Col
              xs={12}
              lg={6}
              className="d-flex flex-row"
              style={{ fontSize: "20px" }}
            >
              <div className="w-50" style={{ borderBottom: "4px solid black" }}>
                TOTAL NETO:
              </div>
              <div
                className="text-right w-50"
                style={{ borderBottom: "4px solid black" }}
              >
                {formatter.format(ban.dep + ban.che + pen.dep + pen.che)}
              </div>
            </Col>
          </Row>
        </div>
      </div>

      <div className="mt-4">
        <div className="bg-secondary text-light text-center">
          DESGLOCE DE BANCOS
        </div>
        <Row>
          <Col xs={12} md={6}>
            <TabDeposito tt={"SERVIDORES PUBLICOS"} info={data.depositos} dt={ban} />
          </Col>
          <Col xs={12} md={6}>
            <TabDeposito tt={"PENSIONES ALIMENTARIAS"} info={data.pensionesDeposito} dt={pen} />
          </Col>
        </Row>
        <Row className="mt-3 border-bottom border-dark">
          <Col xs={6}>
            TOTAL DE DEPOSITOS EN BANCOS
          </Col>
          <Col xs={6} className="text-right" style={{ fontSize: "20px" }}>
            {formatter.format(ban.dep + pen.dep)}
          </Col>
        </Row>
      </div>
    </Container>
  );
};
export default Reporte20Web;

import { useState } from "react";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import Documentacion from "./tabs/Documentacion";
import Calculo from "./tabs/Calculo";
import Emision from "./tabs/Emision";
import { doRequest } from "../../../../helpers/requests";
import { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import EmisionComprobante from "./tabs/EmisionComprobante";
import { faReceipt } from "@fortawesome/free-solid-svg-icons";

export default function Detalle({ finiquito, setFiniquito, permissions }) {
    const [tab, setTab] = useState(1);
    const [valid, setValid] = useState(false);

    useEffect(()=>{
        validate();
    }, []);

    async function validate(){
        const params = {
            action: "validateSettlement",
            idSettlement: finiquito.id
        }
        const res = await doRequest("app/facades/employees/employeesF.php", params, false);
        if(res.length > 0){
            setValid(true);
        }
    }

    return (
        <div>
            <Nav fill tabs className="border-0" style={{ overflowY: "hidden", overflowX: "auto" }}>
                <NavItem className="border-bottom">
                    <NavLink onClick={() => setTab(1)} className={tab === 1 ? "active border border-bottom-0" : ""} >
                        <i className="fa fa-file-text-o navIco" />
                        Documentación
                    </NavLink>
                </NavItem>
                <NavItem className="border-bottom">
                    <NavLink onClick={() => setTab(2)} className={tab === 2 ? "active border border-bottom-0" : ""} disabled={!valid}>
                        <i className="fa fa-calculator navIco" />
                        Cálculo
                    </NavLink>
                </NavItem>
                <NavItem className="border-bottom">
                    <NavLink onClick={() => setTab(3)} className={tab === 3 ? "active border border-bottom-0" : ""} disabled={!valid}>
                        <i className="fa fa-money navIco" />
                        Emisión a Pago
                    </NavLink>
                </NavItem>
                <NavItem className="border-bottom">
                    <NavLink onClick={() => setTab(4)} className={tab === 4 ? "active border border-bottom-0" : ""} disabled={!valid}>
                        <FontAwesomeIcon icon={faReceipt} className="navIco"/>
                        Emisión Comprobante de Pago
                    </NavLink>
                </NavItem>
            </Nav>

            <TabContent activeTab={tab} className="mt-4">
                <TabPane tabId={1}>
                    <Documentacion id={finiquito.id} permissions={permissions}/>
                </TabPane>
                <TabPane tabId={2}>
                    <Calculo finiquito={finiquito} permissions={permissions}/>
                </TabPane>
                <TabPane tabId={3}>
                    <Emision finiquito={finiquito} setFiniquito={setFiniquito} permissions={permissions}/>
                </TabPane>
                <TabPane tabId={4}>
                    <EmisionComprobante finiquito={finiquito} permissions={permissions}/>
                </TabPane>
            </TabContent>
        </div>
    )
}
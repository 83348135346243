
import React from "react";

import Widget from "../../../../components/Widget/Widget";
import FormComisiones from "../FormComisiones";
import { updateComision } from "../functions";

export const EditarComision = (props) => {
  return (
    <Widget className="widget-p-md">
        <h3>Editar comisión</h3>
        <FormComisiones data={localStorage.getItem('dataContent').split('|-|')} editar={updateComision}/>
    </Widget>
  )
}

export default EditarComision;
import React from "react";
import CurrencyInput from "react-currency-input-field";

export const NumberInput = (
  {
    label,
    inputName,
    onChangeMethod,
    onBlurMethod,
    value,
    isRequired,
    touched,
    errors,
    isDisabled,
    isMin, /// Valor minimo permitido 
    isMax, /// Valor maximo permitido 
    maxLength, /// Longintud maxima de la "cadena" 
    decimals, // Para permitir utilizar decimales
    negatives, // Para permitir utilizar valores negativos
    prefix, // Prefijo Ej: $ , ° , 
    suffix, // Sufijo Ej: % , MXN
    placeholder,
    disableSep,
    className,
    readOnly
  }
) => {


  return (
    <>
      <div className="form-group">
        {label && <label htmlFor={inputName}>
          {label} {isRequired && <span className="text-danger">*</span>}
        </label>}
        <CurrencyInput
          id={inputName}
          name={inputName}
          className={"form-control " + (errors && touched ? " is-invalid " : " ")+(className || "")}
          allowDecimals={decimals ? true : false}
          decimalsLimit={2}
          decimalScale={decimals ? +value % 1 === 0? 0 : 2 : undefined}
          allowNegativeValue={negatives ? true : false}
          onValueChange={(value, name) => onChangeMethod({target:{name: name, value: value||""}})}
          onBlur={onBlurMethod}
          value={value}
          min={isMin ? isMin : undefined}
          max={isMax ? isMax : undefined}
          maxLength={maxLength ? maxLength : undefined}
          prefix={prefix ? prefix : undefined}
          suffix={suffix ? suffix : undefined}
					groupSeparator=","
          decimalSeparator="."
          disabled={isDisabled ? true : false}
          disableGroupSeparators={disableSep ? true : false}
          placeholder={placeholder? placeholder : undefined}
          readOnly={readOnly}
        />
        {touched && errors && <span className="text-danger ">{errors}</span>}
      </div>
    </>
  );
};

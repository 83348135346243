import GenderImage from "../constants/gender-image";
import { validValue } from "./format";

/**
 * Function que convierte un archivo en image64
 * @param {File} file 
 * @param {Dispatch<SetStateAction<String>>} setImage 
 */
export function imageFile2Image64(file, setImage) {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
        setImage(reader.result)
    };
}

/**
 * Function que convierte un archivo en image64
 * @param {File} file 
 * @param {Dispatch<SetStateAction<String>>} setImage 
 */
export function formikImageFile2Image64(file, setFieldValue, name, cut = true) {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
        setFieldValue(name, cut ? reader.result.substring(reader.result.indexOf(",") + 1) : reader.result);
    };
}

/**
 * Funcion que convierte el contenido de una imagen 64 a archivo
 * @param {string} dataurl Contenido del archivo
 * @param {string} filename Nombre del archivo
 * @returns File
 */
export function dataURLtoFile(dataurl, filename, mimeType = "image/jpeg") {
    const imageData = dataurl.split(',')[1];
    const decodedImageData = Buffer.from(imageData, 'base64');
    const file = new File(
        [decodedImageData], filename, { type: mimeType });
    return file
}

/**
 * Funcion para seleccionar la imagen del empleado
 * @param {string | number} segs Genero del empleado
 * @param {string | undefined} url Url de fotografia del empleado
 * @returns { string } string
 */
export function getStaffPic(segs, url){
    // if (validValue(url)) {
    //     return url;
    // } else 
    if (segs === "FEMENINO" || +segs === 2){
        return process.env.REACT_APP_API + GenderImage.FEMALE;
    } else {
        return process.env.REACT_APP_API + GenderImage.MALE;
    }
}
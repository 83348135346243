import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { Button, Modal, ModalHeader, ModalBody } from "reactstrap";
import FullFormLoader from "../../../components/Loader/FullFormLoader";
import TableComponentCustom from "../../../components/TableComponent/TableComponentCustom";
import sc from "../../../components/TableComponent/TableComponentFiltro.module.scss";
import { doRequest } from "../../../helpers/requests";
import { createPDF } from "../../../components/Pdf/PdfHtml";
import { getValue } from "../../../libs/tools/fillFormats";
import ModalConfirmation from "../../../components/Modal/ModalConfirmation";
import { RadioInput } from "../../../components/GenericInputsFormik/RadioInput";

const cabeceras = ["Id", "TIPO DE FORMATO", "NOMBRE DEL FROMATO", "PDF"];
const filtro = [true, true, true];

const opt = [
  { name: "No", val: "0" },
  { name: "Si", val: "1" },
];

const PersonalFormatos = ({ idStaff, permissions }) => {
  const [loading, setLoading] = useState(true);
  const [loadingMsg, setLoadingMsg] = useState("");
  const [modalReport, setModalReport] = useState(false);
  const [modalSpec, setModalSpec] = useState(false);
  const [infoStaff, setInfoStaff] = useState("");
  const [uploadedDocs, setUploadedDocs] = useState([]); //documentos cargados
  const [typeSelect, setTypeSelect] = useState("");
  const [pdf, setPDF] = useState(null);
  const [parametros] = useState({
    action: "datatable",
    table: "formats A JOIN types_formats B USING (keyTypeFormat)",
    rows: "A.idFormat, B.nameTypeFormat, A.nameFormat",
    conditions: "A.enabled = 1",
    page: 0,
    records: 5,
    search: "",
    order: "",
  });

  useEffect(() => {
    getInfoStaff();
    getUploadedDocs();
  }, []);

  const SColumn = (el) => {
    return (
      <i
        className={classNames(
          "fa fa-file-pdf-o text-secondary-red",
          sc.handCursor
        )}
        onClick={() => wichReport(el[0])}
      />
    );
  };

  const getInfoStaff = async () => {
    const params = {
      action: "getDatosFormatos",
      rows: { idStaff: idStaff },
    };
    const data = await doRequest("app/facades/jobs/jobsF.php", params, false);
    if (data.length > 0) setInfoStaff(data[0]);
  };

  const getUploadedDocs = async () => {
    const params = {
      action: "multiselect",
      table: `types_receptiondocuments A LEFT OUTER JOIN receptiondocuments B ON A.keyTypeReceptiondocument = B.keyTypeReceptiondocument AND idStaff = ${idStaff}`,
      rows: "A.nameReceptiondocument, A.keyTypeReceptiondocument, B.idReceptiondocument, IF(ISNULL(B.idReceptiondocument),0, B.enabled) AS enabled",
      conditions: ``,
    };
    const data = await doRequest("receiver/receiver.php", params, false);
    if (data.length > 0) setUploadedDocs(data);
    setLoading(false);
  };

  function wichReport(id) {
    if ([18, 20].includes(+id)) {
      setTypeSelect(+id);
      setModalSpec(true);
    } else getFormat(id);
  }

  async function getFormat(id, options) {
    const params = {
      action: "select",
      table: "formats",
      condition: { idFormat: id },
    };
    if ([18, 20].includes(+id)) console.log(options);
    setLoadingMsg("Obteniendo información");
    setLoading(true);
    const data = await doRequest("receiver/receiver.php", params, false);
    if (data.length > 0) {
      let fmt = clearInfoStaff(data[0]);
      createPDF(fmt, setPDF);
      setModalReport(true);
    } else setLoading(false);
  }

  function clearInfoStaff(data) {
    let dis = data.textFormat;
    const remp = dis.match(/«\w+»/g); // Con « »
    // const remp = dis.match(/(?<=\«)\w+(?=»)/g); // Sin « »
    if (infoStaff !== "" && remp !== null) {
      remp.forEach((d) => {
        dis = dis.replace(d, getValue(d, infoStaff));
      });
    }
    return { ...data, textFormat: dis };
  }

  return (
    <div>
      <TableComponentCustom
        titulo="Formatos"
        cabecerasTabla={cabeceras}
        filtro={filtro}
        parametros={parametros}
        specialColumns={[SColumn]}
      />

      {pdf && (
        <Modal
          isOpen={modalReport}
          toggle={() => setModalReport(false)}
          keyboard
          scrollable={true}
          style={{ minWidth: "95%", maxHeight: "90%", height: "90%" }}
        >
          <ModalHeader toggle={() => setModalReport(false)}>
            Visualizar pdf
          </ModalHeader>
          <ModalBody>
            <iframe
              title="PDF Viewer"
              src={pdf}
              width="100%"
              height="94%"
              onLoad={() => {
                setLoading(false);
              }}
              id="pdf-iframe"
            />
            <div className="text-center">
              <Button color="danger" onClick={() => setModalReport(false)}>
                Cerrar
              </Button>
            </div>
          </ModalBody>
        </Modal>
      )}

      <ModalTipo
        type={typeSelect}
        modal={modalSpec}
        setmodal={setModalSpec}
        getFormat={getFormat}
        uploadedDocs={uploadedDocs}
        setUploadedDocs={setUploadedDocs}
        idStaff={idStaff}
        permissions={permissions}
      />

      <FullFormLoader show={loading} message={loadingMsg} />
    </div>
  );
};
export default PersonalFormatos;

const ModalTipo = ({
  type,
  modal,
  setmodal,
  getFormat,
  uploadedDocs,
  setUploadedDocs,
  idStaff,
  permissions,
}) => {
  const [pensionado, setpensionado] = useState("");
  const [localDocs, setLocalDocs] = useState([]);
  const [tt, settt] = useState("");
  const [loadingModal, setLoadingModal] = useState(false);

  useEffect(() => {
    if (modal) {
      switch (type) {
        case 18:
          settt("¿Es pensionada(o) del ISSEMYM?");
          break;
        case 20:
          setLocalDocs([...uploadedDocs]);
          settt("Recepción de Documentos");
          break;
      }
    }
  }, [modal]);

  const selectContent = () => {
    switch (type) {
      case 18:
        return pension();
      case 20:
        return recepDocs();
    }
  };

  //Form formato Pensiones
  const pension = () => {
    return (
      <RadioInput
        inputName="pensionado"
        onChangeMethod={({ target }) => {
          setpensionado(target.value);
          setmodal(false);
          getFormat(type, { pensionado: +target.value });
        }}
        value={pensionado}
        optionsArray={opt}
        optionValue="val"
        optionName="name"
        isCenter
      />
    );
  };

  //Form formato recepcion de documentos
  const recepDocs = () => {
    const onChange = (t, i) => {
      if (permissions.UPD) {
        let tb = [...localDocs];
        tb[i].enabled = t.checked ? 1 : 0;
        setLocalDocs(tb);
      }
    };

    return (
      <div className="mx-0 mx-md-2 mx-lg-5">
        <ul>
          {localDocs.map((el, ix) => (
            <li key={"lt" + ix} className="mb-2">
              <div className="form-check checkbox checkbox-secondary mr-0 mr-md-3 mr-lg-4">
                <input
                  id={ix + "chk"}
                  type="checkbox"
                  className="styled handCursor"
                  checked={+el.enabled === 1}
                  onChange={({ target }) => onChange(target, ix)}
                />
                <label
                  htmlFor={ix + "chk"}
                  className="userSelectNone pl-lg-5 pl-xs-1"
                >
                  {el.nameReceptiondocument}
                </label>
              </div>
            </li>
          ))}
        </ul>
        <FullFormLoader show={loadingModal} />
      </div>
    );
  };

  const submit = async () => {
    // const docs = uploadedDocs.map(el => {const ex = {...el}; delete ex.nameReceptiondocument; return ex});
    if(!permissions.UPD) getFormat(type, { documentos: "subidos" });
    const params = {
      action: "saveRecepecionDocumentos",
      rows: {
        idStaff: idStaff,
        documents: localDocs,
      },
    };
    setmodal(true);
    setLoadingModal(true);
    const res = await doRequest(
      "app/facades/jobs/jobsF.php",
      params,
      false,
      true
    );
    if (res.length > 0) {
      setUploadedDocs(localDocs);
      setmodal(false);
      getFormat(type, { documentos: "subidos" });
    }
    setLoadingModal(false);
  };

  return (
    <ModalConfirmation
      modalTitle={tt}
      modal={modal}
      setModal={setmodal}
      editar={submit}
      isEdit
      hiddeOk={type === 18}
      hiddeCancel={type === 20}
      buttonOkMsg="Aceptar"
      size={type === 20 ? "lg" : "md"}
    >
      {selectContent()}
    </ModalConfirmation>
  );
};

import Widget from "../../../../../components/Widget/Widget";
import * as Yup from "yup"
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import ModalConfirmation from "../../../../../components/Modal/ModalConfirmation";
import { TextInput } from "../../../../../components/GenericInputsFormik";
import { NumberInput } from "../../../../../components/GenericInputsFormik/NumberInput";
import { Button } from "reactstrap";
import { useHistory } from "react-router-dom";
import { peticionesReceiver } from "../../../../../helpers/peticionesReceiver";
import { toast } from "react-toastify";
import Notification from "../../../../../components/Notification/Notification";

export default function FormCatalogoIncidencias({ idIncidencia }) {
    const history = useHistory()
    const [modal, setModal] = useState(false)
    const API = peticionesReceiver();

    const defaultForm = {
        keyTypeIncident: idIncidencia || "",
        nameTypeIncident: "",
        limitDays: ""
    }

    const FormSchema = Yup.object().shape({
        nameTypeIncident: Yup.string().required("Se requiere el nombre de incidencia")
    })

    async function recoveryData(id) {
        const params = {
            action: "multiselect",
            table: "types_incidents",
            rows: "keyTypeIncident,nameTypeIncident,limitDays",
            conditions: `keyTypeIncident=${id}`
        }
        try {
            const res = await API.peticion(params);
            if (res) {
                setFieldValue("nameTypeIncident", res.data.data[0].nameTypeIncident)
                setFieldValue("limitDays", res.data.data[0].limitDays === "-1" ? "" : res.data.data[0].limitDays)
            } else {
                toast(
                    <Notification
                        type={"consultar_error"}
                        backMessage={res.data.message}
                    />
                );
                deleteAndReturn()
            }
        } catch (err) {
            toast(<Notification type={"consultar_servidor_error"} withIcon />);
            deleteAndReturn()
        }
    }

    useEffect(() => {
        if (idIncidencia !== undefined) {
            recoveryData(idIncidencia)
        }
    }, [])

    const { handleSubmit, values, handleBlur, errors, touched, setFieldValue, resetForm } =
        useFormik({
            initialValues: defaultForm,
            onSubmit: () => setModal(true),
            validationSchema: FormSchema,
        });

    async function addIncidencia(values) {
        const params = {
            action: "insert",
            table: "types_incidents",
            rows: {
                nameTypeIncident: values.nameTypeIncident,
                limitDays: values.limitDays === "" ? -1 : values.limitDays
            },
            validate: [["nameTypeIncident"]]
        }
        try {
            const res = await API.peticion(params);
            if (res.status === 200 && res.data.code === "200") {
                toast(<Notification type={"agrega_exito"} backMessage={res.data.message} withIcon />);
                regresar()
            } else {
                toast(<Notification type={"agrega_error"} backMessage={res.data.message} withIcon />);
            }
        } catch (err) {
            console.log(err)
            toast(<Notification type={"agrega_error"} withIcon />);
        }
    }

    async function editarIncidencia(values) {
        const data = {
            action: "update",
            table: "types_incidents",
            rows: {
                nameTypeIncident: values.nameTypeIncident,
                limitDays: values.limitDays === "" ? -1 : values.limitDays
            },
            condition: {
                keyTypeIncident: idIncidencia,
            },
            validate: [["nameTypeIncident"]]
        };
        try {
            const res = await API.peticion(data);
            if (res.status === 200 && res.data.code === "200") {
                toast(<Notification type={"modifica_exito"} backMessage={res.data.message} withIcon />);
                deleteAndReturn()
            } else {
                toast(<Notification type={"modifica_error"} backMessage={res.data.message} withIcon />);
            }
        } catch (err) {
            toast(<Notification type={"consultar_servidor_error"} withIcon />);
        }
    }

    const onChange = (e) => {
        const { name, value } = e.target
        setFieldValue([name], value);
    }

    const regresar = () => history.push("/template/catalogo-incidencias");
    const deleteAndReturn = () => {
        localStorage.removeItem("incCat")
        history.push("/template/catalogo-incidencias");
    }

    return (
        <Widget className="widget-p-md">
            <h3>{!idIncidencia ? "Agregar nueva incidencia" : "Editar incidencia"}</h3>
            <form onSubmit={handleSubmit}>
                <TextInput
                    errors={errors.nameTypeIncident}
                    inputName={"nameTypeIncident"}
                    inputType="text"
                    isRequired
                    label={"Nombre de la incidencia"}
                    onBlurMethod={handleBlur}
                    onChangeMethod={onChange}
                    touched={touched.nameTypeIncident}
                    value={values.nameTypeIncident}
                />
                <NumberInput
                    label="Límite de días permitidos"
                    inputName="limitDays"
                    onChangeMethod={onChange}
                    onBlurMethod={handleBlur}
                    value={values.limitDays}
                    touched={touched.limitDays}
                    errors={errors.limitDays}
                    maxLength={10}
                    disableSep
                    placeholder="N/A"
                />
                <div className="text-center">
                    <Button color="danger" className="mx-1" onClick={!idIncidencia ? regresar : deleteAndReturn}>Regresar</Button>
                    <Button className="mx-1" type="submit">{!idIncidencia ? "Agregar" : "Editar"}</Button>
                </div>
            </form>
            <ModalConfirmation
                modalTitle={!idIncidencia ? "Agregar incidencia a catalogo" : "Editar incidencia del catalogo"}
                modal={modal}
                crear={addIncidencia}
                editar={editarIncidencia}
                isEdit={idIncidencia !== undefined}
                setModal={setModal}
                values={values}
            >
                <div className="d-flex justify-content-center">
                    <h6>¿Desea continuar con el registro?</h6>
                </div>
            </ModalConfirmation>
        </Widget>
    )
}
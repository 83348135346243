import Widget from "../../../components/Widget/Widget";
import { Nav, NavItem, NavLink, Spinner } from "reactstrap";
import { useEffect, useState } from "react";
import Traspasos from "./Traspasos";
import Recalendarizacion from "./Recalendarizacion";
import Reprogramacion from "./Reprogramacion";
import { peticionEncript } from "../../../helpers/peticionesEncripted";
import { toast } from "react-toastify";
import Notification from "../../../components/Notification/Notification";
import axios from "axios";
import { GetPermissions } from "../../../libs/permissions/getPermissions";
import { useHistory } from "react-router";

const s = { fontSize: "18px", marginRight: "5px" };
const defaultBudget = {
  id: "",
};
const year = new Date().getFullYear();

const AdecuacionesPresupuestales = () => {
  const [permissions, setPermissions] = useState({});
  GetPermissions(setPermissions);
  const history = useHistory();

  const [loading, setLoading] = useState(true);
  const [activeTab, setActiveTab] = useState(0);
  const [annualBudget, setAnnualBudget] = useState({ ...defaultBudget });

  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  useEffect(() => {
    getAnnualBudget();
  }, []);

  const getAnnualBudget = async () => {
    const params = {
      action: "select",
      table: "annualbudget",
      condition: { budgetYear: year, keyBudgetType: 3 },
    }; 
    const finalData = peticionEncript(params);
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API}receiver/receiver.php`,
        finalData
      );
      if (res.status === 200 && res.data.status === "success") {
        // toast(<Notification type={"agrega_exito"} withIcon />);
        setAnnualBudget({
          id: res.data.data[0].idAnualBudget,
        });
        setActiveTab(1);
      } else {
        setActiveTab(4);
        toast(
          <Notification
            type={"agrega_error"}
            backMessage={res.data.message}
            withIcon
          />,
          { closeButton: false }
        );
      }
    } catch (err) {
      toast(<Notification type={"consultar_error"} withIcon />);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (permissions.CON !== undefined && !permissions.CON)
      history.push("/template");
  }, [permissions]);

  return (
    <Widget className="widget-p-md">
      <Nav fill tabs style={{ overflowY: "hidden", overflowX: "auto" }}>
        <NavItem active={activeTab === 1}>
          <NavLink
            href="#"
            active={activeTab === 1}
            onClick={() => toggleTab(1)}
            disabled={annualBudget.id === ""}
          >
            <i className={"fa fa-exchange"} style={s} />
            Traspasos Internos/Externos
          </NavLink>
        </NavItem>
        <NavItem active={activeTab === 2}>
          <NavLink
            href="#"
            active={activeTab === 2}
            onClick={() => toggleTab(2)}
            disabled={annualBudget.id === ""}
          >
            <i className={"fa fa-calendar"} style={s} />
            Recalendarización
          </NavLink>
        </NavItem>
        <NavItem active={activeTab === 3}>
          <NavLink
            href="#"
            active={activeTab === 3}
            onClick={() => toggleTab(3)}
            disabled={annualBudget.id === ""}
          >
            <i className={"fa fa-clock-o"} style={s} />
            Reprogramación
          </NavLink>
        </NavItem>
      </Nav>
      <div className="mt-4">
        {activeTab === 1 ? (
          permissions.INS ? (
            <Traspasos annualBudget={annualBudget} />
          ) : (
            <MsgPermission />
          )
        ) : activeTab === 2 ? (
          permissions.UPD ? (
            <Recalendarizacion annualBudget={annualBudget} />
          ) : (
            <MsgPermission />
          )
        ) : activeTab === 3 ? (
          permissions.UPD ? (
            <Reprogramacion annualBudget={annualBudget} />
          ) : (
            <MsgPermission />
          )
        ) : (
          <div className="m-5 p-5 text-center">
            <div className="m-5">
              {loading && !Object.keys(permissions).length > 0? (
                <Spinner color="primary" />
              ) : (
                <h5>No existe un presupuesto vigente</h5>
              )}
            </div>
          </div>
        )}
      </div>
    </Widget>
  );
};

export default AdecuacionesPresupuestales;

const MsgPermission = () => {
  return (
    <div className="mt-5 my-4">
      <blockquote className="blockquote text-center">
        <p>
          Usted no cuenta con los permisos para realziar esta acción.
        </p>
      </blockquote>
    </div>
  );
};

import React, { useState } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import {
  Navbar,
  Nav,
  NavItem,
  NavLink,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
} from "reactstrap";
import { logoutUser } from "../../actions/auth.js";
import { useHistory } from "react-router-dom";
import { connect,useSelector } from "react-redux";

import { closeSidebar, openSidebar } from "../../actions/navigation.js";
import MenuIcon from "../Icons/HeaderIcons/MenuIcon.js";

import logoutIcon from "../../assets/navbarMenus/pfofileIcons/logoutOutlined.svg";

import s from "./Header.module.scss";
import "animate.css";
import { v4 as uuidv4 } from "uuid";

const Header = (props) => {
  const history = useHistory();

  const { info } = useSelector(state => state.userInfo)
  const [notificationsOpen, setNotificationsOpen] = useState(false);

  const toggleNotifications = () => {
    setNotificationsOpen(!notificationsOpen)
  }

  const doLogout = () => {
    props.dispatch(logoutUser());
    history.push("/login");
  }

  const toggleSidebar = () => {
    if (props.sidebarOpened) {
      props.dispatch(closeSidebar())
    } else {
      props.dispatch(openSidebar());
    }
  }

  return (
    <Navbar className={`header-navbar d-print-none`}>
      <div>
        <NavLink
          onClick={() => toggleSidebar()}
          className={`d-md-none mr-3`}
          href="#"
        >
          <MenuIcon className={s.menuIcon} />
        </NavLink>
      </div>
      <Nav className="ml-auto">
        <Dropdown isOpen={notificationsOpen} toggle={() => toggleNotifications()} nav id="basic-nav-dropdown" className="ml-3">
          <DropdownToggle nav caret className="navbar-dropdown-toggle">
            <div className={`${s.avatar} rounded-circle float-left mr-2`}>
              {info.avatar !== "" && info.avatar && (
                <img src={info.avatar} alt="User" key={uuidv4()}/>  
              )}
            </div>
            <span className="small d-none d-sm-block ml-1 mr-2 body-1">{info.name}</span>
          </DropdownToggle>
          <DropdownMenu className="navbar-dropdown profile-dropdown" style={{ width: "194px" }}>
            <ul>
              <NavItem>
                <NavLink onClick={() => doLogout()} href="#">
                  <button className="btn btn-primary rounded-pill mx-auto logout-btn" type="submit">
                    <img src={logoutIcon} alt="Salir" />
                    <span className="ml-1">Salir</span>
                  </button>
                </NavLink>
              </NavItem>
            </ul>
          </DropdownMenu>
        </Dropdown>
      </Nav>
    </Navbar>
  )
}

Header.propTypes = {
  dispatch: PropTypes.func.isRequired,
  sidebarOpened: PropTypes.bool,
}

function mapStateToProps(store) {
  return {
    sidebarOpened: store.navigation.sidebarOpened,
  };
}

export default withRouter(connect(mapStateToProps)(Header));


import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import { Button, Row, Col } from "reactstrap";
import { peticionesReceiver } from "../../../../helpers/peticionesReceiver";
import * as Yup from "yup";
import ModalConfirmation from "../../../../components/Modal/ModalConfirmation";

import { toast } from "react-toastify";
import Notification from "../../../../components/Notification/Notification";
import {
  SelectTypeHeadSingle,
  TextAreaInput,
} from "../../../../components/GenericInputsFormik";

const FormSolicitudes = ({
  collapse,
  setRefresh,
  setTrasnferRequest,
  setCollapse,
  idAreaLogged,
}) => {
  const [modalConfirmation, setModalConfirmation] = useState(false);
  const [areas, setAreas] = useState(false);
  const API = peticionesReceiver();

  useEffect(() => {
    getAreas();
  }, []);
  useEffect(() => {
    if (!collapse) {
      resetForm();
    }
  }, [collapse]);

  async function getAreas() {
    const params = {
      action: "multiselect",
      table: "areas",
      rows: "idArea,name",
      conditions: "enabled = 1 ",
    };
    try {
      const res = await API.peticion(params);
      if (res.status === 200 && res.data.code === "200") {
        setAreas(res.data.data);
      } else {
        toast(
          <Notification
            type={"consultar_error"}
            backMessage={"Sin registros"}
            withIcon
          />
        );
      }
    } catch (err) {
      toast(<Notification type={"consultar_servidor_error"} withIcon />);
    }
  }

  const FormSchema = Yup.object().shape({
    /* idAreaOrigin: Yup.string().required("Seleccione un área de origen"), */
    idAreaDestiny: Yup.string().required("Seleccione un área de destino"),
    description: Yup.string().required("Ingrese una descripción"),
  });

  const {
    handleSubmit,
    values,
    errors,
    setFieldValue,
    touched,
    handleBlur,
    resetForm,
  } = useFormik({
    initialValues: {
      idAreaOrigin: "",
      idAreaDestiny: "",
      description: "",
    },
    onSubmit: () => {
      setModalConfirmation(true);
    },
    validationSchema: FormSchema,
    enableReinitialize: true,
  });

  const onChange = (e) => {
    const { name, value } = e.target;
    setFieldValue([name], value);
  };

  const sendRequest = async (values) => {
    const data = {
      action: "saveTransfer",
      idAreaOrigin: idAreaLogged,
      idAreaDestiny: values.idAreaDestiny,
      description: values.description,

      /* rows: {
        idAreaOrigin:values.idAreaOrigin,
        idAreaDestiny:values.idAreaDestiny,
        yearTransfer: new Date().getFullYear(),
        description:values.description,
        numberTransfer:10,
        keyStat:130
      } */
      /* validate: [["namePurchaseDoc"]], */
    };

    const res = await API.peticionEndPoint(
      data,
      "app/facades/warehouse/warehouseF.php"
    );
    if (res.status === 200 && res.data.status === "success") {
      toast(
        <Notification
          type={"agrega_exito"}
          backMessage={res.data.message}
          withIcon
        />
      );
      resetForm();
      setRefresh(true);
      
      setTrasnferRequest(res.data.data[0]);
      setCollapse(false);
    } else {
      toast(
        <Notification
          type={"agrega_error"}
          backMessage={res.data.message}
          withIcon
        />
      );
    }
  };

  return (
    <div className="mt-3">
      <form onSubmit={handleSubmit}>
        <div className="text-center mb-5">
          <h1>Nueva Solicitud</h1>
        </div>
        <Row className="d-flex justify-content-around">
          <Col xs={12} lg={5}>
            <SelectTypeHeadSingle
              label="Área de origen"
              inputName="idAreaOrigin"
              onChangeMethod={onChange}
              onBlurMethod={handleBlur}
              value={idAreaLogged}
              isRequired
              touched={touched.idAreaOrigin}
              errors={errors.idAreaOrigin}
              optionsArray={areas}
              optionValue={"idArea"}
              optionName={"name"}
              isDisabled={true}
            />
          </Col>
          <Col xs={12} lg={5}>
            <SelectTypeHeadSingle
              label="Área de destino"
              inputName="idAreaDestiny"
              onChangeMethod={onChange}
              onBlurMethod={handleBlur}
              value={values.idAreaDestiny}
              isRequired
              touched={touched.idAreaDestiny}
              errors={errors.idAreaDestiny}
              optionsArray={areas}
              optionValue={"idArea"}
              optionName={"name"}
            />
          </Col>
        </Row>

        {+idAreaLogged === +values.idAreaDestiny && (
          <Row className="d-flex  justify-content-center mb-5 text-center">
            <Col>
              <span style={{ color: "red" }}>
                No se puede realizar un traspaso a la misma area
              </span>
            </Col>
          </Row>
        )}

        <Row className="d-flex align-items-center justify-content-around mb-5">
          <Col xs={12} lg={5}>
            <TextAreaInput
              label="Descripción"
              inputName="description"
              inputRows={6}
              onChangeMethod={onChange}
              onBlurMethod={handleBlur}
              value={values.description}
              isRequired
              touched={touched.description}
              errors={errors.description}
            />
          </Col>
          <Col xs={12} lg={5} className="text-center">
            <Button
              type="submit"
              color="success"
              disabled={+idAreaLogged === +values.idAreaDestiny}
            >
              Agregar
            </Button>
          </Col>
        </Row>

        <ModalConfirmation
          modalTitle={"Crear"}
          modal={modalConfirmation}
          setModal={setModalConfirmation}
          crear={sendRequest}
          isEdit={false}
          values={values}
        >
          <div className="text-center">
            Esta seguro de continuar con el registro
          </div>
        </ModalConfirmation>
      </form>
    </div>
  );
};

export default FormSolicitudes;

import React, { useState } from "react";
import { Modal, ModalHeader } from "reactstrap";
import { toast } from "react-toastify";
import { peticionesReceiver } from "../../../../helpers/peticionesReceiver";
import Notification from "../../../../components/Notification/Notification";
import Datatable from "../../../../components/Datatable/Datatable";
import DownloadIcon from "./DownloadIcon";
import FileSaver from "file-saver";

const ModalErrors = ({
  modalErrors,
  handleCloseModal,
  stampErros,
  setModalErrors,
}) => {
  const [refresh, setRefresh] = useState(false);
  const API = peticionesReceiver();

  

  const columns = [
    { data: "index" },
    { data: "description" },
    { data: "sent" },
    { data: "erros" },
    {
      data:"urlError",
      render: function (row) {
        return (
          <DownloadIcon
            style={{ fill: "var(--success)", width: "40px", height: "40px" }}
            onClick={() =>
              FileSaver.saveAs(row, row.substring(row.lastIndexOf("/") + 1))
            }
            className="cursor-pointer"
          />
        );
      },
      orderValue: "CONCAT(s.period,'/',s.year)",
    },
  ];
  const headers = ["Numero", "Descripción", "Enviados","Errores", "Archivo"];

  const columnDefs = [{ targets: [0, 1, 2], className: "text-center" }];

  const getParams = (order, records, page, search) => {
    const params = {
      action: "datatable",
      table: "stampserrors",
      rows: "idStampError,description,urlError,sent,errors",
      conditions: `enabled=1`,
      order,
      records: records.toString(),
      page: (page * records).toString(),
      search,
    };
    return params;
  };

  async function getData(order, records, page, search) {
    try {
      const res = await API.peticion(getParams(order, records, page, search));
      if (res.status === 200 && res.data.code === "200") {
        const data = res.data;
        /* data.forEach((value, index) => (value.index = 1 + index)); */
        data.data.forEach((value, index) => (value.index = 1 + index));
        return data;
      } else {
        toast(
          <Notification
            type={"consultar_error"}
            backMessage={res.data.message}
          />
        );
        return null;
      }
    } catch (err) {
      toast(<Notification type={"consultar_servidor_error"} withIcon />);
      return null;
    }
  }
  return (
    <Modal
      isOpen={modalErrors}
      toggle={handleCloseModal}
      fullscreen="lg"
      size="lg"
      backdrop="static"
    >
      <ModalHeader toggle={handleCloseModal}>Errores</ModalHeader>
      <div className="text-center my-3">
        <Datatable
          columns={columns}
          headers={headers}
          petition={getData}
          stateRefresh={[refresh, setRefresh]}
          columnDefs={columnDefs}
          control="back"
          order={{ col: 1, opt: "desc" }}
        />
      </div>
    </Modal>
  );
};

export default ModalErrors;

import * as Yup from "yup";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { Col, Row, Button } from "reactstrap";
import React, { useState, useEffect } from "react";
import { useFormik } from "formik";

import Loader from "../../../components/Loader/Loader";
import { peticionesReceiver } from "../../../helpers/peticionesReceiver";
import Notification from "../../../components/Notification/Notification";
import ModalConfirmation from "../../../components/Modal/ModalConfirmation";
import { SelectTypeHeadSingle, TextInput } from "../../../components/GenericInputsFormik";

const FormHorarios = ({ crear, data, editar }) => {

  const defaultForm = {
    idJobSchedule: "",
    nameJobSchedule: "",
    keyTypeSchedule: 0,
  };

  const history = useHistory();
  const API = peticionesReceiver();

  const [modal, setModal] = useState(false);
  const [allTypesSchedule, setAllTypesSchedule] = useState([])
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    if(data) {
      getJobById(Number(data[0]))
    }
    else{
      getData()
    }
  }, [])

  const getJobById = async(id) => {
    const params = {
      action: "select",
      table: 'jobschedule',
      condition: {
        enabled: 1,
        idJobSchedule: id
      }
    };
    const res = await API.peticion(params)
    if(res.status === 200 && res.data.code === "200"){
      const info = res.data.data[0]
      setFieldValue("idJobSchedule", data[0]);
      setFieldValue("nameJobSchedule", data[1]);
      setFieldValue("keyTypeSchedule", info.keyTypeSchedule);

      getData()
    }
    else {
      toast(<Notification type={"consultar_error"} withIcon />);
    }
  }

  const getData = async() => {
    const params = {
      action: "select",
      table: 'types_schedule',
      condition: {
        enabled: 1
      },
      order: 'keyTypeSchedule ASC',
    };
    const res = await API.peticion(params)
    if(res.status === 200 && res.data.code === "200"){
      setAllTypesSchedule(res.data.data)
      setIsLoading(false)
    }
    else {
      toast(<Notification type={"consultar_error"} withIcon />);
      setIsLoading(false)
    }
  };

  const FormSchema = Yup.object().shape({
    keyTypeSchedule: Yup.number()
      .moreThan(0,"Favor de seleccionar un tipo de horario")
      .required("Favor de seleccionar un tipo de horario"),
    nameJobSchedule: Yup.string()
      .min(4, "El nombre del horario debe contener al menos 4 caracteres")
      .required("Favor de ingresar un nombre para el horario"),
  });

  const onChange = (e) => {
    const { name, value } = e.target
    setFieldValue([name], value);
  }

  const limpia = (reset) =>{
    reset()
    history.push('/template/horarios-nomina')
  }

  const { handleSubmit, values, handleBlur, errors, touched, setFieldValue,resetForm } =
    useFormik({
      initialValues: defaultForm,
      onSubmit: () => setModal(true),
      validationSchema: FormSchema,
  });

  return (
    <>
      {
        isLoading ? <Loader/>
        :
        <form onSubmit={handleSubmit} className="mt-4" noValidate>
          <ModalConfirmation
            modalTitle={data ? "Editar" : "Crear"}
            modal={modal}
            setModal={setModal}
            crear={crear}
            editar={editar}
            isEdit={data ? true : false}
            values={values}
          >
            {data ? (
              <div className="d-flex justify-content-center">
                <h6>¿Está seguro de editar el registro?</h6>
              </div>
            ) : (
              <div className="d-flex justify-content-center">
                <h6>¿Desea continuar con el registro?</h6>
              </div>
            )}
          </ModalConfirmation>

          <Row className="mt-2">
            <Col xs={12} md={6}>
              <TextInput
                label="Nombre de horario"
                inputType="text"
                inputName="nameJobSchedule"
                onChangeMethod={onChange}
                onBlurMethod={handleBlur}
                value={values.nameJobSchedule}
                isRequired={true}
                touched={touched.nameJobSchedule}
                errors={errors.nameJobSchedule}
                isMax={50}
              />
            </Col>

            <Col xs={12} md={6}>
              <SelectTypeHeadSingle
                label="Tipo de horario"
                isRequired={true}
                optionsArray={allTypesSchedule}
                inputName="keyTypeSchedule"
                onChangeMethod={onChange}
                onBlurMethod={handleBlur}
                touched={touched.keyTypeSchedule}
                errors={errors.keyTypeSchedule}
                optionValue="keyTypeSchedule"
                optionName="nameTypeSchedule"
                value={values.keyTypeSchedule}
                defaultOption="Seleccione un elemento"
              />
            </Col>
          </Row>

          <div className="row mt-3">
            <div className="col-sm-6 order-2 order-sm-1 text-center text-sm-left mb-4">
              <Button color="danger" type="reset" onClick={() => limpia(resetForm)} > Cancelar </Button>
            </div>

            <div className="col-sm-6 order-1 order-sm-2 text-center text-sm-right mb-4">
              <Button color="success" type="submit"> Guardar </Button>
            </div>
            
          </div>
        </form>
      }
    </>
  );
};

export default FormHorarios;

import * as Yup from "yup"
import { useFormik } from "formik";

import Widget from "../../../../../components/Widget/Widget";
import ModalConfirmation from "../../../../../components/Modal/ModalConfirmation";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { peticionesReceiver } from "../../../../../helpers/peticionesReceiver";
import { toast } from "react-toastify";
import Notification from "../../../../../components/Notification/Notification";
import { DateInput, SelectTypeHeadSingle, TextAreaInput } from "../../../../../components/GenericInputsFormik";
import { Button, Spinner } from "reactstrap";
import { format } from "date-fns"
import { useHistory } from "react-router-dom";
import InputWithModal from "../../../../../components/GenericInputsFormik/InputWithModal";

export default function FormIncidencia({ idIncidencia }) {
    const API = peticionesReceiver()
    const [modal, setModal] = useState(false)
    const { id } = useSelector(state => state.userInfo);
    const [loadingUser, setLoadingUser] = useState(false);
    const [types, setTypes] = useState([]);
    const status = [
        { value: "128", label: "Autorizada" },
        { value: "129", label: "Negada" },
    ]
    const history = useHistory()

    const cabecerasEmpleado = [
        "#",
        "Núm. Empleado",
        "Nombre",
        "Puesto",
        "Seleccionar",
    ];

    const filtroEmpleado = [
        true, true, true, true, false
    ]

    const paramsEmpleado = {
        action: "datatable",
        table: "staff STF INNER JOIN employtypes AS EMT ON STF.keyEmployType = EMT.keyEmployType INNER JOIN jobstaff AS JBS ON JBS.idStaff = STF.idStaff INNER JOIN areas AS AR ON AR.idArea = JBS.idArea INNER JOIN jobtypes AS JBT ON JBT.keyJobType = STF.keyEmployType",
        rows: "STF.idStaff,STF.staffNumber,CONCAT(STF.name,' ',STF.pName,' ',STF.mName) as fullName,JBT.nameJobType,JBS.idJobStaff",
        conditions: `STF.enabled = 1 AND JBS.valid = 1 AND STF.keyStat = 1 AND JBS.enabled = 1`,
        page: 0,
        records: 5,
        search: "",
        order: "",
    }

    const defaultForm = {
        idIncidentOrigin: "2",
        keyStat: "",
        idStaff: "",
        nameStaff: "",
        idJobStaff: "",
        idStaffAutorized: "",
        keyTypeIncident: "",
        dateIncident: "",
        notes: "",
        dateAutorized: "",
    }

    const FormSchema = Yup.object().shape({
        keyTypeIncident: Yup.string().required("Seleccionar el tipo de incidente"),
        nameStaff: Yup.string().required("Seleccionar un empleado de la lista"),
        dateIncident: Yup.date().required("Ingresar la fecha del incidente"),
        notes: Yup.string().required("Ingresar la justificacion de la incidencia"),
        dateAutorized: Yup.date().required("Ingresar la fecha del incidente"),
        keyStat: Yup.string().required("Seleccionar un tipo de estado"),
    })

    useEffect(() => {
        getStaffAttent()
        if (idIncidencia) {
            recoveryData()
        }
        getTypes()
    }, [])

    async function recoveryData() {
        try {
            const res = await API.peticion({
                action: "multiselect",
                rows: "i.idIncident,i.idStaff,i.idJobStaff,i.idIncidentOrigin,i.keyTypeIncident,i.keyStat,i.dateIncident,i.idStaffAutorized,i.dateAutorized,i.notes,s.staffNumber,CONCAT(s.name,' ',s.pName,' ',s.mName) as fullName",
                table: "incidents i inner join staff s on (i.idStaff = s.idStaff)",
                conditions: `i.idIncident = ${idIncidencia}`
            });
            if (res.status === 200 && res.data.code === "200") {
                if (res.data.data.length > 0) {
                    const data = res.data.data[0];
                    setFieldValue("idIncidentOrigin", data.idIncidentOrigin);
                    setFieldValue("keyStat", data.keyStat);
                    setFieldValue("idStaff", data.idStaff);
                    setFieldValue("nameStaff", data.fullName);
                    setFieldValue("idJobStaff", data.idJobStaff);
                    if (data.idStaffAutorized) {
                        setFieldValue("idStaffAutorized", data.idStaffAutorized);
                    }                    
                    setFieldValue("keyTypeIncident", data.keyTypeIncident);
                    setFieldValue("dateIncident", new Date(data.dateIncident));
                    setFieldValue("notes", data.notes);
                    setFieldValue("dateAutorized", new Date(data.dateAutorized));
                    setLoadingUser(false);
                } else {
                    toast(<Notification type={"consultar_error"} backMessage={"No se pudo recuperar el registro"} />);
                }
            } else {
                setLoadingUser(false);
                toast(<Notification type={"consultar_error"} backMessage={res.data.message} />);
            }
        } catch (err) {
            setLoadingUser(false);
            toast(<Notification type={"consultar_servidor_error"} withIcon />);
        }
    }

    async function getStaffAttent() {
        try {
            const res = await API.peticion({
                action: "multiselect",
                rows: "s.idStaff",
                table: "users u join staff s on(u.idStaff = s.idStaff)",
                conditions: `u.idUser = ${id}`
            });
            if (res.status === 200 && res.data.code === "200") {
                setFieldValue("idStaffAutorized", res.data.data[0].idStaff);
                setLoadingUser(false);
            } else {
                setLoadingUser(false);
                toast(<Notification type={"consultar_error"} backMessage={res.data.message} />);
            }
        } catch (err) {
            setLoadingUser(false);
            toast(<Notification type={"consultar_servidor_error"} withIcon />);
        }
    }

    async function getTypes() {
        try {
            const res = await API.peticion({
                action: "multiselect",
                rows: "keyTypeIncident,nameTypeIncident",
                table: "types_incidents",
                conditions: `enabled=1`
            });
            if (res.status === 200 && res.data.code === "200") {
                setTypes(res.data.data)
                setLoadingUser(false);
            } else {
                setLoadingUser(false);
                toast(<Notification type={"consultar_error"} backMessage={res.data.message} />);
            }
        } catch (err) {
            setLoadingUser(false);
            toast(<Notification type={"consultar_servidor_error"} withIcon />);
        }
    }

    const { handleSubmit, values, handleBlur, errors, touched, setFieldValue, resetForm } =
        useFormik({
            initialValues: defaultForm,
            onSubmit: () => setModal(true),
            validationSchema: FormSchema,
        });

    async function addIncidencia(values) {
        const rows = {
            ...values,
            dateIncident: format(new Date(values.dateIncident), "yyyy-MM-dd HH:mm:ss"),
            dateAutorized: format(new Date(values.dateAutorized), "yyyy-MM-dd HH:mm:ss")
        }
        delete rows.nameStaff
        const params = {
            action: "insert",
            rows,
            table: "incidents"
        }
        try {
            const res = await API.peticion(params);
            if (res.status === 200 && res.data.code === "200") {
                toast(<Notification type={"agrega_exito"} backMessage={res.data.message} withIcon />);
                regresar()
            } else {
                toast(<Notification type={"agrega_error"} backMessage={res.data.message} withIcon />);
            }
        } catch (err) {
            console.log(err)
            toast(<Notification type={"agrega_error"} withIcon />);
        }
    }

    async function editarIncidencia(values) {
        const rows = {
            ...values,
            dateIncident: format(new Date(values.dateIncident), "yyyy-MM-dd HH:mm:ss"),
            dateAutorized: format(new Date(values.dateAutorized), "yyyy-MM-dd HH:mm:ss")
        }
        delete rows.nameStaff
        const params = {
            action: "update",
            rows,
            table: "incidents",
            condition: {
                idIncident: idIncidencia
            }
        }
        try {
            const res = await API.peticion(params);
            if (res.status === 200 && res.data.code === "200") {
                toast(<Notification type={"modifica_exito"} backMessage={res.data.message} withIcon />);
                deleteAndReturn()
            } else {
                toast(<Notification type={"modifica_error"} backMessage={res.data.message} withIcon />);
            }
        } catch (err) {
            console.log(err)
            toast(<Notification type={"agrega_error"} withIcon />);
        }
    }

    const onChange = (e) => {
        const { name, value } = e.target
        setFieldValue([name], value);
    }

    const regresar = () => history.push("/template/incidencias")

    const deleteAndReturn = () => {
        localStorage.removeItem("incEdit")
        history.push("/template/incidencias");
    }

    const actionSelect = (el) => {
        setFieldValue(["idStaff"], el[0]);
        setFieldValue(["nameStaff"], `${el[1]} - ${el[2]}`);
        setFieldValue(["idJobStaff"], el[4]);
    }

    const clear = (el) => {
        setFieldValue(["idStaff"], "");
        setFieldValue(["nameStaff"], "");
        setFieldValue(["idJobStaff"], "");
    }

    if (loadingUser) {
        return (
            <div className="d-flex flex-column align-items-center justify-content-center" style={{ minHeight: "300px" }}>
                <Spinner animation="grow" variant="primary" />
            </div>
        )
    } else if (values.idStaffAutorized === "") {
        return (
            <div className="text-center p-4">
                El usuario necesita tener un empleado asignado y una plaza para acceder a este apartado
            </div>
        )
    }
    return (
        <Widget className="widget-p-md">
            <h3>{!idIncidencia ? "Agregar incidencia" : "Editar incidencia"}</h3>
            <form onSubmit={handleSubmit}>
                <InputWithModal
                    cabeceras={cabecerasEmpleado}
                    filtro={filtroEmpleado}
                    actionSelect={actionSelect}
                    onClear={clear}
                    parametros={paramsEmpleado}
                    value={values.nameStaff}
                    label="Empleado"
                    name={"nameStaff"}
                    title={"Seleccionar empleado"}
                    error={errors.nameStaff}
                    handleBlur={handleBlur}
                    touched={touched.nameStaff}
                    onChange={onChange}
                    disabled={!idIncidencia ? false : true}
                />
                <DateInput
                    label="Fecha"
                    inputName="dateIncident"
                    onChangeMethod={(date) => onChange({ target: { name: "dateIncident", value: new Date(date) } })}
                    value={values.dateIncident}
                    isRequired
                    touched={touched.dateIncident}
                    errors={errors.dateIncident}
                />
                <SelectTypeHeadSingle
                    label={"Tipo de incidencia"}
                    errors={errors.keyTypeIncident}
                    value={values.keyTypeIncident}
                    touched={touched.keyTypeIncident}
                    optionsArray={types}
                    inputName={"keyTypeIncident"}
                    isRequired
                    onBlurMethod={handleBlur}
                    optionName={"nameTypeIncident"}
                    optionValue={"keyTypeIncident"}
                    onChangeMethod={onChange}
                />
                <TextAreaInput
                    errors={errors.notes}
                    inputName="notes"
                    isRequired
                    label="Justificacion"
                    onBlurMethod={handleBlur}
                    onChangeMethod={onChange}
                    touched={touched.notes}
                    value={values.notes}
                    inputRows={6}
                />
                <DateInput
                    label="Fecha de autorizacion"
                    inputName="dateAutorized"
                    onChangeMethod={(date) => onChange({ target: { name: "dateAutorized", value: new Date(date) } })}
                    value={values.dateAutorized}
                    isRequired
                    touched={touched.dateAutorized}
                    errors={errors.dateAutorized}
                />
                <SelectTypeHeadSingle
                    label={"Estatus"}
                    errors={errors.keyStat}
                    value={values.keyStat}
                    touched={touched.keyStat}
                    optionsArray={status}
                    inputName={"keyStat"}
                    isRequired
                    onBlurMethod={handleBlur}
                    optionName={"label"}
                    optionValue={"value"}
                    onChangeMethod={onChange}
                />
                <div className="text-center">
                    <Button className="mx-2" color="danger" onClick={!idIncidencia ? regresar : deleteAndReturn}>Cancelar</Button>
                    <Button className="mx-2" type="submit">{!idIncidencia ? "Agregar" : "Guardar"}</Button>
                </div>
            </form>
            <ModalConfirmation
                modalTitle={!idIncidencia ? "Registrar incidencia" : "Editar incidencia"}
                modal={modal}
                crear={addIncidencia}
                editar={editarIncidencia}
                isEdit={!idIncidencia ? false : true}
                setModal={setModal}
                values={values}
            >
                <div className="d-flex justify-content-center">
                    <h6>¿Desea continuar con el registro?</h6>
                </div>
            </ModalConfirmation>
        </Widget>
    )
}
import React, { useState, useEffect } from "react";

import * as Yup from "yup";
import { useFormik } from "formik";
import { Button, Row, Col } from "reactstrap";
import { peticionesReceiver } from "../../../../helpers/peticionesReceiver";
import ModalDelete from "../../../../components/Modal/ModalDelete";
import Datatable from "../../../../components/Datatable/Datatable";

import { toast } from "react-toastify";
import { CancelOutlined } from "@material-ui/icons";
import Notification from "../../../../components/Notification/Notification";
import ModalTableVer from "../../../../components/Modal/ModalTableVer";
import ModalConfirmation from "../../../../components/Modal/ModalConfirmation";
import { SelectTypeHeadSingle } from "../../../../components/GenericInputsFormik/index";
import { Loader } from "react-bootstrap-typeahead";
import s from "./InputClear.module.scss";

const FormAgentes = (props) => {
  const { collapse, setCollapse, setRefreshP, data, setData, permissions } =
    props;

  const API = peticionesReceiver();
  const [topics, setTopics] = useState([]);
  const [filesTypestoShow, setFilesTypestoShow] = useState([]);
  const [isEditing, setIsEditing] = useState(false);

  const [modalConfirmation, setModalConfirmation] = useState(false);
  const [loader, setLoader] = useState(true);
  const [loading, setLoading] = useState(true);
  const [topicsUser, setTopicsUser] = useState([]);

  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    getTopics();
    if (data !== "") getForEdit(data);
  }, []);

  const getTopics = async () => {
    setLoader(false);
    const data = {
      action: "multiselect",
      table: "topics",
      rows: "keyTopic,nameTopic",
      conditions: `enabled = 1`,
    };

    await API.peticion(data)
      .then((res) => {
        if (res.status === 200 && res.data.status === "success") {
          setTopics(res.data.data);
          setLoader(false);
        } else {
          toast(<Notification type={"consultar_error"} withIcon />);
        }
      })
      .catch((err) => {
        toast(<Notification type={"consultar_servidor_error"} withIcon />);
      });
  };

  const headers = ["#", "Tema", "Eliminar"];
  const columns = [
    { data: "index" },
    { data: "nameTopic" },
    {
      data: null,
      render: function (row) {
        return permissions.DEL ? (
          <>
            <ModalDelete
              table={["topicsAgents"]}
              nameColumn={["idTopicAgent"]}
              elimina={() => deleteTopicAgent(row)}
              setRefresh={setRefresh}
              type="icon"
            >
              <h6>¿Seguro de eliminar el registro?</h6>
              No podrá recuperar la información después de ser eliminada
            </ModalDelete>
          </>
        ) : (
          "Accion no permitida"
        );
      },
    },
  ];

  const deleteTopicAgent = async (obj) => {
    const params = {
      action: "delete",
      table: "topicagents",
      condition: { idTopicAgent: obj.idTopicAgent },
    };
    await API.peticion(params)
      .then((res) => {
        if (res.data.status === "success" && res.data.code === "200") {
          toast(
            <Notification
              type={"elimina_exito"}
              backMessage={res.data.message}
              withIcon
            />
          );
          setRefresh(true);
        } else {
          toast(
            <Notification
              type={"elimina_error"}
              backMessage={res.data.message}
              withIcon
            />,
            { closeButton: false }
          );
        }
      })
      .catch((err) => {
        toast(<Notification type={"consultar_servidor_error"} withIcon />);
      });
  };

  const columnDefs = [
    {
      targets: [0, 1, 2, 3, 4],
      className: "text-center",
    },
    {
      orderable: false,
      targets: [0],
    },
  ];

  const getParamsTableElmt = (order, records, page, search) => {
    const params = {
      action: "datatable",
      table:
        "topicagents A INNER JOIN users B on (A.idUser = B.idUser) INNER JOIN topics C on (C.keyTopic = A.keyTopic)",
      rows: "A.idTopicAgent,A.idUser,concat(B.name,' ',B.pName,' ',B.mName) as fullName,nameTopic,A.keyTopic",
      conditions: `A.enabled = 1 and B.enabled = 1 and A.idUser = ${values.idUser} `,
      order,
      records: records.toString(),
      page: (page * records).toString(),
      search,
    };
    return params;
  };

  async function getData(order, records, page, search) {
    try {
      const res = await API.peticion(
        getParamsTableElmt(order, records, page, search)
      );
      if (res.status === 200 && res.data.code === "200") {
        const data = res.data;
        data.data.forEach((value, index) => (value.index = 1 + index));
        setTopicsUser(data.data);
        return data;
      } else {
        toast(
          <Notification
            type={"consultar_error"}
            backMessage={res.data.message}
          />
        );
        return null;
      }
    } catch (err) {
      toast(<Notification type={"consultar_servidor_error"} withIcon />);
      return null;
    }
  }

  const onChange = (e) => {
    const { name, value } = e.target;
    setFieldValue([name], value);
  };

  const saveAgente = async () => {
    const data = {
      action: "insert",
      table: "topicagents",
      rows: {
        idUser: values.idUser,
        keyTopic: values.keyTopic,
      },
      validate: [["idUser", "keyTopic"]],
    };
    const res = await API.peticion(data);
    if (res.status === 200 && res.data.status === "success") {
      toast(
        <Notification
          type={"agrega_exito"}
          backMessage={res.data.message}
          withIcon
        />
      );

      setRefresh(true);
      setRefreshP(true);
      setFieldValue("keyTopic", "");
    } else {
      toast(
        <Notification
          type={"agrega_error"}
          backMessage={res.data.message}
          withIcon
        />
      );
    }
  };

  const FormSchema = Yup.object().shape({
    keyTopic: Yup.string().required("Seleccione un tema"),
    nameUser: Yup.string().required("Seleccione un usuario"),
  });

  const {
    handleSubmit,
    values,
    errors,
    setFieldValue,
    touched,
    handleBlur,
    resetForm,
  } = useFormik({
    initialValues: {
      idUser: "",
      keyTopic: "",
    },
    onSubmit: () => {
      setModalConfirmation(true);
    },
    validationSchema: FormSchema,
    enableReinitialize: true,
  });

  const filterTopics = async () => {
    setFieldValue("keyTopic", "");
    const arrFiles = topics;
    /* const documentsList = await getTopicsList(); */
    const documentsList = topicsUser;

    if (
      arrFiles !== null ||
      (arrFiles !== undefined && documentsList !== null) ||
      documentsList !== undefined
    ) {
      var resultado = arrFiles.filter(function (objeto1) {
        return !documentsList.some(function (objeto2) {
          return +objeto1.keyTopic === +objeto2.keyTopic;
        });
      });
      setFilesTypestoShow(resultado);
    }
  };

  useEffect(() => {
    if (collapse === false) {
      handleClearStaff();
      resetForm();
    }
  }, [collapse]);

  const [modalTabla, setModalTabla] = useState(false);
  const filtroModalTabla = [true, true, true, true, true];
  const cabecerasModalTabla = ["#", "Nombre", "Seleccionar"];
  const [paramsModalTabla] = useState({
    action: "datatable",
    table: "users",
    rows: "idUser,concat(name,' ',pName,' ',mName) as fullName",
    conditions: "enabled = 1",
    page: 0,
    records: 5,
    search: "",
    order: "",
  });

  const selectUser = (el) => {
    setFieldValue("idUser", el[0]);
    setFieldValue("nameUser", `${el[1]}`);
    setModalTabla(false);
    setRefresh(true);
  };

  useEffect(() => {
    filterTopics();
  }, [topicsUser]);

  function handleClearStaff() {
    setFieldValue("idUser", "");
    setFieldValue("nameUser", "");
  }

  const getForEdit = async (user) => {
    if (collapse) {
      setFieldValue("idUser", user.idUser);
      setFieldValue("nameUser", user.fullName);
      setIsEditing(true);
      setCollapse(true);
    }
  };

  return (
    <div>
      {loader ? (
        <Loader />
      ) : (
        <>
          {permissions.CON ? (
            <>
              <form onSubmit={handleSubmit} className="mt-3">
                <div className="text-center mb-5">
                  <h1>Administración de Agentes</h1>
                </div>

                <Row>
                  <Col>
                    <div className="form-group position-relative">
                      <label htmlFor="nameUser">Personal</label>
                      <div className="input-group">
                        <input
                          name="nameUser"
                          onChange={onChange}
                          onBlur={handleBlur}
                          className={
                            "form-control" +
                            (errors.nameUser && touched.nameUser
                              ? " is-invalid"
                              : "")
                          }
                          value={values.nameUser}
                          disabled
                        />
                        <div className="input-group-append">
                          <button
                            className="btn btn-secondary"
                            style={{ zIndex: "auto" }}
                            type="button"
                            onClick={() => setModalTabla(true)}
                          >
                            <i className="fa fa-search" />
                          </button>
                        </div>
                      </div>
                      {values.nameUser !== "" && (
                        <CancelOutlined
                          className={s.btnClear}
                          onClick={handleClearStaff}
                        />
                      )}
                      {touched.nameUser && errors.nameUser && (
                        <span style={{ color: "red" }}>{errors.nameUser}</span>
                      )}
                    </div>
                  </Col>
                </Row>

                {values.idUser !== null &&
                  values.idUser !== undefined &&
                  values.idUser !== "" && (
                    <>
                      <Row className="d-flex align-items-center justify-content-around mb-5">
                        <Col xs={12} lg={5}>
                          <SelectTypeHeadSingle
                            label="Temas"
                            isRequired={true}
                            inputName="keyTopic"
                            optionsArray={filesTypestoShow}
                            defaultOption="Seleccione un Tema"
                            onChangeMethod={onChange}
                            value={values.keyTopic}
                            optionValue="keyTopic"
                            optionName="nameTopic"
                            onBlurMethod={handleBlur}
                            errors={errors.keyTopic}
                          />
                        </Col>

                        {permissions.INS && (
                          <Col xs={12} lg={5} className="text-center">
                            <Button type="submit" color="success">
                              Agregar
                            </Button>
                          </Col>
                        )}
                      </Row>

                      <div className="mt-5">
                        <Datatable
                          headers={headers}
                          columns={columns}
                          columnDefs={columnDefs}
                          petition={getData}
                          control="back"
                          stateRefresh={[refresh, setRefresh]}
                          searching={true}
                          order={{ col: 1, opt: "desc" }}
                        />
                      </div>
                    </>
                  )}

                <ModalConfirmation
                  modalTitle={"Crear"}
                  modal={modalConfirmation}
                  setModal={setModalConfirmation}
                  crear={saveAgente}
                  isEdit={false}
                  values={values}
                >
                  <div className="text-center">
                    Esta seguro de continuar con el registro
                  </div>
                </ModalConfirmation>
              </form>
              <ModalTableVer
                modal={modalTabla}
                setModal={setModalTabla}
                title="Usuarios"
                cabeceras={cabecerasModalTabla}
                filtro={filtroModalTabla}
                parametros={paramsModalTabla}
                selectRegistro={selectUser}
                backdrop={"static"}
                keyboard={false}
                btnClose={true}
              />
            </>
          ) : (
            "No tiene permisos para consultar  este modulo"
          )}
        </>
      )}
    </div>
  );
};

export default FormAgentes;

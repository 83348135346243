import { useEffect, useState } from "react";
import { doRequest, doRequestSaveRes } from "../../../helpers/requests";
import FullFormLoader from "../../../components/Loader/FullFormLoader";
import { Button, Col, Row, Table } from "reactstrap";
import { fDatewhitTime, fMoney } from "../../../libs/tools/format";
import ModalDictaminar from "./MdDictamen";
import ModalConfirmation from "../../../components/Modal/ModalConfirmation";
import { useHistory } from "react-router-dom";
import { TextAreaInput } from "../../../components/GenericInputsFormik";
import { toast } from "react-toastify";
import Notification from "../../../components/Notification/Notification";
import ModalReports from "../../../components/Modal/ModalReports";
import pdfSolicitud from "./reports/pdfSolicitud";

const cDate = new Date();
const cYear = cDate.getFullYear();

const defDictamen = {
    proyecto: "",
    partida: "",
    mSolicitado: 0,
    mDisponible: 0,
    aMes: [0,0,0,0,0,0,0,0,0,0,0,0],
    rMes: [0,0,0,0,0,0,0,0,0,0,0,0],
    case: "",
    idDetail: "",
    idIx: ""
}

const mNames = [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre"
]

export default function Revision({ solicitud, user, permissions }) {
    const [loading, setLoading] = useState(true);
    const [loadingMR, setLoadingMR] = useState(false);
    const [calend, setCalend] = useState([]);
    const [proyP, setProyP] = useState([]);
    const [total, setTotal] = useState(0);
    const [dictamenData, setDictamenData] = useState(defDictamen);
    const [modalDictamen, setModalDictamen] = useState(false);
    const [modalTraspaso, setModalTraspaso] = useState(false);
    const [modalRechazo, setModalRechazo] = useState(false);
    const [toDelete, setToDelete] = useState({});
    const [notes, setNotes] = useState("");
    const [validNotes, setValidNotes] = useState(false);
    const [showButtons, setShowButtons] = useState(true);
    const [modalDenegar, setModalDenegar] = useState(false);
    const [dataDenegar, setDataDenegar] = useState({ title: "", stat: "", case: "" });
    const [modalReport, setModalReport] = useState(false);
    const [noteRechazo, setNoteRechazo] = useState("");
    const [modalNotasRechazo, setModalNotasRechazo] = useState(false);
    const [validNotesRechazo, setValidNotesRechazo] = useState(false);

    const history = useHistory();

    useEffect(() => {
        getMonths();
        getProy();
        setShowButtons(+solicitud.estatus < 118);
        setNotes(solicitud.notas);
    }, []);

    async function getMonths() {
        setLoading(true);
        const params = {
            action: "multiselect",
            table:
                "budgetrequestdetail A INNER JOIN budgetrequestschedule B USING(idDetail) INNER JOIN months C USING (keyMonth)",
            rows: "C.nameMonth, SUM(IF(A.keyStat = 121, B.required, IF(A.keyStat = 122,B.approved, 0))) AS acum, C.keyMonth",
            conditions: `A.idBudgetRequest = ${solicitud.id} AND A.enabled = 1 AND B.enabled = 1`,// AND A.keyStat != 123
            order: "C.keyMonth",
            group: "C.keyMonth",
        };
        const res = await doRequest("receiver/receiver.php", params, false, false);
        if (res.length > 0) {
            let tt = 0;
            const data = mNames.map((el, ix) => {
                const fnd = res.find((mb) => +mb.keyMonth === ix+1);
                const nMes = { nameMonth: el, acum: 0 };
                if (fnd) {
                    tt += +fnd.acum;
                    nMes.acum = +fnd.acum;
                }
                return nMes;
            });

            setCalend(data);
            setTotal(tt);
        }
        setLoading(false);
    }

    async function getProy() {
        const params = {
            action: "multiselect",
            table: "budgetrequestdetail A INNER JOIN statefunds B USING (idStateFund) INNER JOIN expense_objects C USING (idExpenseObject) INNER JOIN budgetrequestschedule G USING (idDetail) INNER JOIN status H USING (keyStat) INNER JOIN budgetrequests I USING (idBudgetRequest) LEFT JOIN staff SS ON SS.idStaff = I.idStaffRequest LEFT JOIN staff SR ON SR.idStaff = I.idStaffRevision LEFT JOIN staff SA ON SA.idStaff = I.idStaffAproves",
            rows: "A.idStateFund, CONCAT(B.stateFundKey,' - ',B.nameStateFund) AS prPres, C.fullKey as COG, GROUP_CONCAT(G.required ORDER BY G.keyMonth ASC) AS rMonth, C.nameObject, SUM(G.required) AS acum, H.nameStat, A.idDetail,A.keyStat,A.concept, CONCAT(SS.name,' ',SS.pName,' ',SS.mName) AS solicita, CONCAT(SR.name,' ',SR.pName,' ',SR.mName) AS revisa, CONCAT(SA.name,' ',SA.pName,' ',SA.mName) AS aprueba",
            conditions: `A.idBudgetRequest = ${solicitud.id} AND A.enabled = 1 AND G.enabled = 1`,
            order: "prPres",
            group: "C.idExpenseObject, B.idStateFund",
        };
        const res = await doRequest("receiver/receiver.php", params, false, false);
        if (res.length > 0) {
            setProyP(res);
        }
    }

    //Boton dictaminar en tabla de proyectos
    async function dictaminar(d, ix) {
        setLoading(true);
        const params = { action: "analizeBudgetDetail", idDetail: d.idDetail }
        const res = await doRequestSaveRes("app/facades/budgets/budgetsF.php", params, false, false, true);
        if(+res.code === 202) setModalTraspaso(true);
        else {
            if(res.data.length < 1) return console.error("No se recibio la informacipon del proyecto presupuestal", 404);
            const mAviable = await doRequest("app/facades/budgets/budgetsF.php", { action: "getBudgetMonthDetail", idBudget: res.data[0].idBudget }, false);
            if(mAviable.length > 0){
                setDictamenData({
                    proyecto: d.prPres,
                    partida: `${d.COG} - ${d.nameObject}`,
                    mSolicitado: res.data[0].required,
                    mDisponible: res.data[0].avaible,
                    aMes: mAviable.slice(0,12),
                    rMes: d.rMonth.split(","),
                    case: +res.code,
                    idDetail: d.idDetail,
                    idIx: ix
                });
                setModalDictamen(true);
            }
        }

        setLoading(false);
    }

    //Boton rechazar en tabla de proyectos
    async function rechazar() {
        setLoadingMR(true);
        let params = {
            action: "update",
            table: "budgetrequestdetail",
            condition: { idDetail: toDelete.id },
            rows: { keyStat: 123, notes: noteRechazo }
        }
        let res = await doRequest("receiver/receiver.php", params, true);
        if (res.length > 0) {
            updProyect(toDelete.ix, "RECHAZADO", 123);
            await getMonths();
            setModalNotasRechazo(false);
        }
        setLoadingMR(false);
    }

    //Boton revisar
    async function emitir() {
        setLoading(true);
        const params = {
            action:"dictaminateBudgetRequest",
            idBudgetRequest: solicitud.id,
            idStaffRevision: user
        }
        const res = await doRequest("app/facades/budgets/budgetsF.php", params, true);
        if (res.length > 0) {
            setShowButtons(false);
        }
        setLoading(false);
    }

    //Boton denegar/regrezar
    function showModal(s) {
        if(s === 118){
            const exist = proyP.filter((el)=> +el.keyStat === 121);
            if(exist.length > 0){
                toast(
                    <Notification
                    type={"modifica_error"}
                    backMessage="Dictamine todos los proyectos antes de enviar la solicitud"
                    withIcon
                    />,
                    { closeButton: false }
                );
                return;

            }
            if(total === 0){
                toast(
                    <Notification
                    type={"modifica_error"}
                    backMessage="No hay proyectos aprobados"
                    withIcon
                    />,
                    { closeButton: false }
                );
                return;
            }
            setDataDenegar({
                title: "Enviar a aprobación",
                stat: s,
                case: "Enviar",
            });
        }else{
            if (checkNotes(notes, setValidNotes)) return;
            else if(validNotes) setValidNotes(false);
            setDataDenegar({
                title: s === 120 ? "Denegar solicitud" : "Regresar solicitud",
                stat: s,
                case: s === 120 ? "Denegar" : "Regresar",
            });
        }

        setModalDenegar(true);
    }

    async function denegarRegresar(s) {
        setLoading(true);
        const params = {
            action: "update",
            table: "budgetrequests",
            condition: { idBudgetRequest: solicitud.id },
            rows: { keyStat: s, notes: notes }
        }
        let res = await doRequest("receiver/receiver.php", params, true);
        if (res.length > 0) setShowButtons(false);
        setLoading(false);
    }

    function checkNotes(n, sv) {
        if (n.length < 1) sv(true);
        return n.length < 1
    }

    function updProyect(ix, name, key){
        const aux = [...proyP];
        aux[ix].nameStat = name;
        aux[ix].keyStat = key;
        setProyP(aux);
    }

    const onChange = (val) => {
        if(!permissions.UPD) return;
        setValidNotes(val < 1);
        setNotes(val);
    }

    async function getPDF(){
        setModalReport(true);
    }

    useEffect(()=>{
        if(!modalNotasRechazo){
            setNoteRechazo("");
            setValidNotesRechazo(false);
        }
    }, [modalNotasRechazo]);

    function validateNotesRechazo(){
        if(!checkNotes(noteRechazo, setValidNotesRechazo)) {
            setModalRechazo(true);
        }
        setModalNotasRechazo(true);
    }
    
    const onChangeRechazo = (val) => {
        setValidNotesRechazo(val < 1);
        setNoteRechazo(val);
    }

    return (
        <div className="mt-4">
            <div className="mb-3 d-flex flex-md-row flex-column">
                <Button color="primary" className="mr-md-3 mt-1" onClick={()=>getPDF(true)}>Previsualizar PDF</Button>
            </div>

            <Row className="my-3 text-center g-3">
                <Col xs={12} md={6}>
                    <h5>Folio:</h5>
                    {solicitud.folio}
                </Col>
                <Col xs={12} md={6}>
                    <h5>Fecha de recepción:</h5>
                    {fDatewhitTime(solicitud.fecha)}
                </Col>
            </Row>

            <div className="mt-4">
                <h5 className="mb-3 text-center">Calendarización del gasto</h5>
                <Row className="border border-middle-gray border-right-0 mx-1 mt-1">
                    {calend.map((el, ix) => (
                        <Col key={"rcg" + ix} xs={12} sm={4} md={3} className="p-0 border-right border-middle-gray">
                            <div className="bg-primary text-center text-light p-1">{el.nameMonth}</div>
                            <div className="text-middle text-right p-2">
                                <p>{fMoney(el.acum)}</p>
                            </div>
                        </Col>
                    ))}
                    <Col xs={12} className="p-0 border-right border-middle-gray" >
                        <div className="bg-primary text-light text-right px-2 py-1">Total</div>
                        <div className="text-middle text-right p-2">
                            <p>{fMoney(total)}</p>
                        </div>
                    </Col>
                </Row>
                <div className="my-3">
                    <h5 className="mb-1 text-center">Justificación del gasto</h5>
                    <div className="bg-light-gray p-2 border rounded"> {/*  */}
                        {solicitud.justificacion}
                    </div>
                </div>

                <h5 className="mb-3 text-center">Dcitamen</h5>
                <Table responsive className="border border-middle-gray">
                    <thead className="bg-primary">
                        <tr className="text-center">
                            <th className="text-light p-2 border-right border-light">Proyecto Presupuestal</th>
                            <th className="text-light p-2 border-right border-light">COG</th>
                            <th className="text-light p-2 border-right border-light">Denominación</th>
                            <th className="text-light p-2 border-right border-light">Importe</th>
                            <th className="text-light p-2 border-right border-light">Dictamen</th>
                            <th className="text-light p-2 border-right border-light">Dictaminar</th>
                            <th className="text-light p-2">Rechazar</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            proyP.map((el, ix) => (
                                <tr key={"rpp" + ix}>
                                    <td className="border border-middle-gray">{el.prPres}</td>
                                    <td className="text-center border border-middle-gray">{el.COG}</td>
                                    <td className="border border-middle-gray">{el.nameObject}</td>
                                    <td className="text-right border border-middle-gray">{fMoney(el.acum)}</td>
                                    <td className="border border-middle-gray">{el.nameStat}</td>
                                    <td className="text-center border border-middle-gray">
                                        {+el.keyStat === 121 && showButtons && permissions.UPD && <i className="fa fa-pencil text-warning cursor-pointer" onClick={() => dictaminar(el, ix)} />}
                                    </td>
                                    <td className="text-center">
                                        {+el.keyStat === 121 && showButtons && permissions.UPD && <i className="fa fa-times text-danger cursor-pointer" onClick={() => { setToDelete({ id: el.idDetail, ix: ix }); setValidNotesRechazo(false); setModalNotasRechazo(true) }} />}
                                    </td>
                                </tr>
                            ))
                        }
                    </tbody>
                </Table>

                <div className="mt-4">
                    <TextAreaInput
                        label="Comentarios/Observaciones del dictamen"
                        inputName="notes"
                        isRequired
                        inputRows={6}
                        onChangeMethod={({ target }) => onChange(target.value)}
                        value={notes}
                        touched={validNotes}
                        errors="Agregue un comentario u observación"
                        isDisabled={!showButtons}
                    />
                    {
                        showButtons && permissions.UPD &&
                        <div className="d-flex flex-md-row flex-column justify-content-center">
                            <Button className="m-2" color="secondary" onClick={() => showModal(118)}>Revisar Solicitud</Button>
                            <Button className="m-2" color="danger" onClick={() => showModal(120)}>Denegar Solicitud</Button>
                            <Button className="m-2" color="primary" onClick={() => showModal(116)}>Regresar a Revisión</Button>
                        </div>
                    }
                </div>
            </div>

            <ModalDictaminar modal={modalDictamen} setModal={setModalDictamen} data={dictamenData} getMonths={getMonths} updProyect={updProyect} />
            {/* Modal solicitar traspaso 202 */}
            <ModalConfirmation
                modalTitle="Presupuesto insuficiente"
                modal={modalTraspaso}
                setModal={setModalTraspaso}
                crear={() => history.push("/template/adecuaciones")}
                hiddeCancel
                buttonOkMsg="Solicitar traspaso"
                centered
            >
                <div className="d-flex justify-content-center">
                    <p>No existe la partida/proyecto en el presupuesto vigente, realizar traspaso a través del módulo Adecuaciones Presupuestales.</p>
                </div>
            </ModalConfirmation>
            {/* Modal rechazar partida */}
            <ModalConfirmation
                modalTitle="Rechazar partida"
                modal={modalRechazo}
                setModal={setModalRechazo}
                crear={() => rechazar()}
                buttonOkMsg="Rechazar"
                centered
            >
                <div className="text-center">
                    <h6>¿Está seguro de rechazar esta partida?</h6>
                    <p>No podrá dictaminarla despues de esta acción</p>
                </div>
            </ModalConfirmation>
            {/* Modal cancelar/regresar partida */}
            <ModalConfirmation
                modalTitle={dataDenegar.title}
                modal={modalDenegar}
                setModal={setModalDenegar}
                crear={() => denegarRegresar(dataDenegar.stat)}
                buttonOkMsg={dataDenegar.case}
                centered
            >
                <div className="text-center">
                    <h6>¿Está seguro de {dataDenegar.case} esta solicitud?</h6>
                    {
                        dataDenegar.case === "Denegar" &&
                        <p>No podrá editarse la solicitud una vez sea cancelada</p>
                    }
                </div>
            </ModalConfirmation>

            {/* Modal mostrar pdf */}
            <ModalReports
                modal={modalReport}
                setModal={setModalReport}
                title={"Solicitud de suficiencia presupuestal y dictamen " + ""}
                backdrop
                keyboard
                report={{meses: calend, proyects: proyP, solicitud: solicitud, total: total, notas: notes}}
                pdfConfig={{}}
                generatePdfMethod={{
                method: pdfSolicitud,
                type: "reporteAuxiliar",
                }}
            />

            {/* Modal agregar notas rechazo */}
            <ModalConfirmation
                modalTitle="Rechazar proyecto presupuestal"
                modal={modalNotasRechazo}
                setModal={setModalNotasRechazo}
                crear={() => validateNotesRechazo()}
                buttonOkMsg="Rechazar"
                size="lg"
                centered
            >
                <div className="">
                    <TextAreaInput
                        label="Comentarios/Observaciones"
                        inputName="notesRechazo"
                        isRequired
                        inputRows={6}
                        onChangeMethod={({ target }) => onChangeRechazo(target.value)}
                        value={noteRechazo}
                        touched={validNotesRechazo}
                        errors="Agregue un comentario u observación"
                        isDisabled={!showButtons}
                    />
                    <FullFormLoader show={loadingMR} message="" />
                </div>
            </ModalConfirmation>

            <FullFormLoader show={loading && calend.length > 0 && proyP.length > 0} message="" />
        </div>
    );
}

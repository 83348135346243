import React, { useState } from "react";
import { Col, Row, Table, Button } from "reactstrap";
import s from "../../tables/Tables.module.scss";
import sc from "../../../components/TableComponent/TableComponentFiltro.module.scss";
import ModalReports from "../../../components/Modal/ModalReports";
import { generateBarsCode } from "./pdf/PdfCodigoBarras";
import CodigoBarras from "./codigoBarras/CodigoBarras"


const TableAgregados = (props) => {
  const { bienes,setBienes } = props;
  const [modalReport, setModalReports] = useState(false);
  const [pdfConfig, setPdfConfig] = useState({});
  

  const generateFilesCodeBars = (values) => {

    
    let codebars = bienes.map((bien,index) => (
      generateCodeBar(bien)
    ))
    
      setPdfConfig({
      codebars: codebars,
    });
    
    setModalReports(true);
  };

  const generateCodeBar = (bien) => {
    const canvas = document.getElementById(bien[0]);
    return canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");

  }



  const deleteBien = (index) =>{
      let bienesTemp = bienes.slice(0, bienes.length)
      bienesTemp.splice(index,1)
      setBienes(bienesTemp)
      
    



  }

  return (
    <>
      <Row>
        <Col xs={12} lg={12} className="text-center">
          <span className="text-center text-succes headline-1">
            Bienes Agregados
          </span>
        </Col>
      </Row>

      <Table
        className={`table-striped table-borderless table-hover ${s.statesTable}`}
        responsive
      >
        <thead className="border-bottom border-secondary">
          <tr className={sc.userSelectNone}>
            <th className="text-center">No</th>
            {/* <th className="text-center">DENOMINACION</th> */}
            <th className="text-center">CODIGO DE BARRAS</th>
            <th className="text-center">NUMERO DE SERIE</th>
            <th className="text-center">NOMBRE</th>
            <th className="text-center">MARCA</th>
            <th className="text-center">MODELO</th>
            <th className="text-center">STATUS</th>
            <th className="text-center">ELIMINAR</th>
          </tr>
        </thead>
        <tbody>
          {bienes.map((bien, index) => (
            <tr key={"R" + index + 1}>
              <td className="text-center">{index + 1}</td>
              <td className="text-center">{bien[2]}</td>
              <td className="text-center">{bien[3]}</td>
              <td className="text-center">{bien[4]}</td>
              <td className="text-center">{bien[5]}</td>
              <td className="text-center">{bien[6]}</td>
              <td className="text-center">{bien[7]}</td>
              <td className="text-center">
                <i
                  className="fa fa-trash-o text-danger"
                  style={{ cursor: "pointer" }}
                  onClick={() => deleteBien(index)}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      <Row>
        <Col  className="text-center mt-3">
        <Button color="success" onClick={() => generateFilesCodeBars()}>
        Generar Reporte
      </Button>
        </Col>
      </Row>

      

      <ModalReports
        modal={modalReport}
        setModal={setModalReports}
        title={"Codigos de barras"}
        backdrop={"static"}
        keyboard={false}
        report={bienes}
        pdfConfig={pdfConfig}
        generatePdfMethod={{
          method: generateBarsCode,
          type: "codigoBarras",
        }}
        records={1000}
      />

      {/* <canvas
        ref={canvasRef}
        id="123"
        style={{ width: "86.5mm", height: "38.5mm" }}
      ></canvas> */}

      {bienes.length > 0 && bienes.map((bien,index) => (
        <div key={index}>
          <CodigoBarras value={bien[0]} />
        </div>
      )) }
      
      
    </>
  );
};

export default TableAgregados;

import * as XlsxPopulate from "xlsx-populate";
import { saveAs } from "file-saver";

/**
 * Cmabiar los valores de un archivo de excel
 * @param {Blob} file - Archivo a modificar
 * @param {string} sheet - Nombre de la primera pagina 
 * 
 * @returns Nuevo excel
 */
export async function processExcel(file, name, sheet, start){
    try {
        return XlsxPopulate.fromDataAsync(file).then(wb => {
            const stSheet = wb.sheet(0).name("old");
            let startL = -1;
            let rows = -1;
            if(name === "origen"){
                startL = findStartData(stSheet, start);
                rows = findLastData(stSheet, start) - 1;
            }else{
                startL = start;
                rows = getMaxRow(stSheet) - 1;
            }
            const cols = getMaxColumnn(stSheet);
            const nameCol = stSheet.column(cols).columnName();
            
            console.log(`A${startL}:${nameCol}${rows}`);

            let range = stSheet.range(`A${startL}:${nameCol}${rows}`).value();
            // stSheet.range(`A${startL}:${nameCol}${rows+1}`).value("");

            // const newSheet = wb.addSheet(sheet);
            // newSheet.cell("A1").value(range);
            // wb.deleteSheet("old");
            // newSheet.row(1).style("bold", true);

            return wb.outputAsync("blob").then((blob) => {
                // saveAs(blob, `${name}.xlsx`);
                // if(name === "origen"){
                //     return new File([blob], `${name}.xlsx`, {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"});
                // }else {
                //     return [new File([blob], `${name}.xlsx`, {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"}), rows];
                // }
                return [range,rows];
            });
        })
    } catch (error) {
        console.error("No fue posible procesar el archivo");
        return null;
    }
}
function getMaxRow(sheet) {
    const rows = Object.keys(sheet._rows);
    const size = rows.length;
    return Number(rows[size-1]);
}

function getMaxColumnn(sheet) {
    const cols = Object.keys(Object.keys(sheet.row(6)._cells));
    const size = cols.length;
    return Number(cols[size-1])+1;

}


function findStartData(sheet, rows){
    let i = 1;
    for (let i = 1; i < rows; i++) {
        if(sheet.row(i).cell(1).value() === "ID Empleado"){
            return i;
        }
        
    }
}

function findLastData(sheet, start){
    let i = start;
    while(sheet.row(i).cell(1).value()){
        i++;
    }
    return i;
}

export async function newFile(range, name, sheet){
    try {
        return XlsxPopulate.fromBlankAsync().then(wb => {
            const newSheet = wb.sheet(0).name(sheet);
            newSheet.cell("A1").value(range);

            return wb.outputAsync("blob").then((blob) => {
                return new File([blob], `${name}.xlsx`, {});
            })
        })
    } catch (error) {
        console.error("No fue posible procesar el archivo");
    }
}
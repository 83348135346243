import React from "react";

import Widget from "../../../../components/Widget/Widget";
import FormCiudad from "../FormCiudad";
import { createCity } from "../functions";

export const CrearCiudad = (props) => {
  return (
    <Widget className="widget-p-md">
        <h3>Crear localidad</h3>
        <FormCiudad crear={createCity}/>
    </Widget>
  )
}

export default CrearCiudad;
import { isMobile } from "react-device-detect"
import FileSaver from "file-saver";

export default function ViewerFile({fileLink}) {
    return (
        <>
            {isMobile ? (
                <div onClick={() => FileSaver.saveAs(fileLink, fileLink.substring(fileLink.lastIndexOf("/") + 1))}>
                    <div className="text-center px-3 pt-3">
                        <i className="fa fa-download" style={{ fontSize: "80px" }} />
                    </div>
                    <div className="text-center px-3" style={{ fontSize: "20px" }} >
                        Descargar
                    </div>
                </div>
            ) : (
                <div className="embed-responsive embed-responsive-21by9">
                    <iframe
                        className="embed-responsive-item"
                        src={fileLink}
                        allowFullScreen
                        title="pdf"
                    />
                </div>
            )}
        </>
    )
}
import React from "react";

import Widget from "../../../../components/Widget/Widget";
import FormPerfil from "../FormPerfil";
import { editarPerfil } from "../functions";

export const EditarPerfil = (props) => {
  return (
    <Widget className="widget-p-md">
        <h3>Editar perfil</h3>
        <FormPerfil data={localStorage.getItem('dataContent').split('|-|')} editar={editarPerfil}/>
    </Widget>
  )
}

export default EditarPerfil;
import { useState } from "react";
import Widget from "../../../../components/Widget/Widget";
import Detalle from "./Detalle";
import Consulta from "./Consulta";
import { GetPermissions } from "../../../../libs/permissions/getPermissions";

export default function RegistroFiniquito() {
    const [permissions, setPermissions] = useState({});
    GetPermissions(setPermissions);
    const [finiquito, setFiniquito] = useState({});

    return (
        <Widget className="widget-p-md">
            <h4>Registro de Finiquitos</h4>

            {finiquito.id && (
                <div className="d-flex flex-md-row flex-column-reverse justify-content-md-between justify-content-center mb-3">
                    <div className="d-flex flex-md-row flex-column">
                        <div className="d-flex justify-content-center">
                            {finiquito.src && (
                                <img src={finiquito.src} className="img-thumbnail w-100 exp-photo" alt="employe"/>
                            )}
                        </div>
                        <div className="text-dark mt-2 mt-md-0 pl-0 pl-md-2">
                            <p>
                                <strong>No. Empleado: </strong>
                                {finiquito.staffNumber}
                            </p>
                            <p>
                                <strong>Nombre: </strong>
                                {finiquito.fullName}
                            </p>
                            <p>
                                <strong>Puesto Laboral: </strong>
                                {finiquito.nameJob || "POR ASIGNAR"}
                            </p>
                            <p>
                                <strong>Adscripción Nominal: </strong>
                                {finiquito.nameArea || "POR ASIGNAR"}
                            </p>
                            <p>
                                <strong>Adscripción Fisica: </strong>
                                {finiquito.nameBuilding || "POR ASIGNAR"}
                            </p>
                            <p>
                                <strong>Estatus: </strong>
                                {finiquito.nameStat}
                            </p>
                        </div>
                    </div>
                    <div className="my-2 my-md-0 text-center">
                        <button
                            className="btn text-light btn-block"
                            style={{
                                backgroundColor: "#cc0a44",
                                border: "none",
                            }}
                            onClick={() => setFiniquito({})}
                        >
                            Cerrar expediente
                        </button>
                    </div>
                </div>
            )}

            {finiquito.id ? (
                <Detalle 
                    finiquito={finiquito}
                    setFiniquito={setFiniquito}
                    permissions={permissions}
                />
            ) : (
                <Consulta
                    setFiniquito={setFiniquito}
                    permissions={permissions}
                />
            )}
        </Widget>
    );
}

import Widget from "../../../../components/Widget/Widget";
import FormBancos from "../FormBancos"
import { editar } from "../functions";

const EditarBanco = () => {
  return (
    <div>
      <Widget className="widget-p-md">
        <h3>Editar bancos</h3>
        <FormBancos
          data={localStorage.getItem('dataContent').split('|-|')}
          editar={editar}
        />
      </Widget>
    </div>
  );
}
export default EditarBanco
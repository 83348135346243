
import React from "react";

import Widget from "../../../../components/Widget/Widget";
import FormHorarios from "../FormHorarios";
import { updateSchedule } from "../functions";

export const EditarHorario = () => {
  return (
    <Widget className="widget-p-md">
        <h3>Editar Horario</h3>
        <FormHorarios data={localStorage.getItem('dataContent').split('|-|')} editar={updateSchedule}/>
    </Widget>
  )
}

export default EditarHorario;
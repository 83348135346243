import React from "react";

export const SelectInput = (props) => {
  const {
    label,
    inputName,
    onChangeMethod,
    onBlurMethod,
    value,
    isRequired,
    touched,
    errors,
    optionsArray,
    optionValue,
    optionName,
    defaultOption,
    isDisabled
  } = props;

  return (
    <>
      <div className="form-group">
        <label htmlFor={inputName}>
          {label} {isRequired && <span className="text-danger">*</span>}
        </label>
        <select
          className={"form-control" + (errors && touched ? " is-invalid" : "")}
          name={inputName}
          id={inputName}
          onChange={onChangeMethod}
          onBlur={onBlurMethod}
          value={value}
          disabled={isDisabled === true ? true : false}
        >
          <option value=''>
            {defaultOption}
          </option>
          {optionsArray.length > 0 &&
            optionsArray.map((e) => {
              return (
                <option value={e[optionValue]} key={e[optionValue]}>
                  {e[optionName]}
                </option>
              );
            })}
        </select>
        {touched && errors && <span style={{ color: "red" }}>{errors}</span>}
      </div>
    </>
  );
};

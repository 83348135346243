import { DateInput, SelectTypeHeadSingle, TextInput } from "../../../components/GenericInputsFormik";
import Widget from "../../../components/Widget/Widget";
import * as Yup from "yup"
import { useFormik } from "formik";
import { Button, Col, Row } from "reactstrap";
import { NumberInput } from "../../../components/GenericInputsFormik/NumberInput";
import InputWithModal from "../../../components/GenericInputsFormik/InputWithModal";
import { useEffect, useState } from "react";
import { peticionesReceiver } from "../../../helpers/peticionesReceiver";
import { toast } from "react-toastify";
import Notification from "../../../components/Notification/Notification";
import FileSaver from "file-saver";
import { format } from "date-fns"
import axios from "axios";

export default function ConsultaPagosElectronicos() {
    const API = peticionesReceiver()

    const [banks, setBanks] = useState([])
    const [accounts, setAccounts] = useState([])

    const defaultForm = {
        idStaff: "",
        nameStaff: "",
        emisor: "",
        receptor: "",
        cuenta: "",
        clave: "",
        monto: "",
        fecha: ""
    }

    const FormSchema = Yup.object().shape({
        nameStaff: Yup.string().required("Seleccione un empleado"),
        emisor: Yup.string().required("Seleccione un banco emisor"),
        receptor: Yup.string().required("Seleccione un banco receptor"),
        cuenta: Yup.string().required("Seleccione una cuenta"),
        clave: Yup.string().required("Ingrese una clave rastreo").max(30, "Se supero la longitud maxima de la clave de rastreo"),
        monto: Yup.string().required("Ingrese el monto del pago"),
        fecha: Yup.string().required("Ingrese la fecha del pago")
    })

    const { handleSubmit, values, handleBlur, errors, touched, setFieldValue, resetForm } = useFormik({
        initialValues: defaultForm,
        onSubmit: (values) => {
            getCEP(values)
        },
        validationSchema: FormSchema,
        enableReinitialize: true,
    });

    function onChange(e) {
        const { name, value } = e.target
        setFieldValue([name], value);
    }

    const params = {
        action: "datatable",
        table: "staff STF INNER JOIN employtypes AS EMT ON STF.keyEmployType = EMT.keyEmployType INNER JOIN jobstaff AS JBS ON JBS.idStaff = STF.idStaff INNER JOIN areas AS AR ON AR.idArea = JBS.idArea INNER JOIN jobtypes AS JBT ON JBT.keyJobType = STF.keyEmployType",
        rows: "STF.idStaff,STF.staffNumber,CONCAT(STF.name,' ',STF.pName,' ',STF.mName) as fullName,AR.name,JBT.nameJobType",
        conditions: "STF.enabled = 1 AND JBS.valid = 1 AND STF.keyStat = 1 AND JBS.enabled = 1",
        page: 0,
        records: 5,
        search: "",
        order: "",
    };
    const actionSelect = (el) => {
        const employee = { idStaff: el[0], nameStaff: `${el[1]} - ${el[2]}` }
        setFieldValue(["idStaff"], employee.idStaff)
        setFieldValue(["nameStaff"], employee.nameStaff)
    };

    const clear = () => {
        setFieldValue(["idStaff"], "");
        setFieldValue(["nameStaff"], "");
    }

    const cabeceras = [
        "#",
        "Núm. Empleado",
        "Nombre",
        "Unidad de trabajo",
        "Puesto",
        "Seleccionar",
    ];

    const filtro = [true, true, true, true, true];

    async function getBanks() {
        const params = {
            action: "multiselect",
            table: "banks",
            rows: "cepKey,nameBank",
            conditions: "enabled = 1 AND cepKey IS NOT NULL"
        }
        try {
            const res = await API.peticion(params)
            if (res.status === 200 && res.data.code === "200") {
                setBanks(res.data.data)
            } else {
                toast(<Notification type={"consultar_error"} backMessage={res.data.message} withIcon />);
            }
        } catch (err) {
            toast(<Notification type={"consultar_servidor_error"} withIcon />);
        }
    }

    useEffect(() => {
        getBanks()
    }, [])

    async function getAccounts(id) {
        const params = {
            action: "multiselect",
            table: "staffaccounts",
            rows: "switfCode",
            conditions: `switfCode IS NOT NULL AND idStaff = ${id}`
        }
        try {
            const res = await API.peticion(params)
            if (res.status === 200 && res.data.code === "200") {
                setAccounts(res.data.data)
            } else {
                toast(<Notification type={"consultar_error"} backMessage={res.data.message} withIcon />);
            }
        } catch (err) {
            toast(<Notification type={"consultar_servidor_error"} withIcon />);
        }
    }

    async function getCEP(values) {
        const params = { ...values }
        const fecha = new Date(params.fecha)
        params.fecha = format(fecha, "yyyy-MM-dd")
        delete params.idStaff
        delete params.nameStaff
        params.action = "getCep"
        try {
            const res = await API.peticionEndPoint(params, "app/facades/files/fileReceiver.php")
            if (res.status === 200 && res.data.code === "200") {
                FileSaver.saveAs(res.data.data, res.data.data.substring(res.data.data.lastIndexOf("/") + 1))
            } else {
                toast(<Notification type={"consultar_error"} backMessage={res.data.message} withIcon />);
            }
        } catch (err) {
            toast(<Notification type={"consultar_servidor_error"} withIcon />);
        }
    }

    useEffect(() => {
        if (values.idStaff && values.idStaff !== "") {
            getAccounts(values.idStaff)
        }
    }, [values.idStaff])

    return (
        <>
            <Widget className="widget-p-md">
                <h3 className="text-secondary">Consulta de pago electrónicos</h3>
                <form onSubmit={handleSubmit}>
                    <Row>
                        <Col>
                            <InputWithModal
                                cabeceras={cabeceras}
                                error={errors.nameStaff}
                                filtro={filtro}
                                handleBlur={handleBlur}
                                actionSelect={actionSelect}
                                onClear={clear}
                                parametros={params}
                                touched={touched.nameStaff}
                                value={values.nameStaff}
                                label="Seleccione el empleado que atendera la solicitud"
                                name={"nameStaff"}
                                title={"Seleccionar empleado"}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12} md={4}>
                            <SelectTypeHeadSingle
                                label="Banco emisor"
                                inputName="emisor"
                                onChangeMethod={onChange}
                                onBlurMethod={handleBlur}
                                value={values.emisor}
                                isRequired
                                touched={touched.emisor}
                                errors={errors.emisor}
                                optionsArray={banks}
                                optionValue="cepKey"
                                optionName="nameBank"
                            />
                        </Col>
                        <Col sm={12} md={4}>
                            <SelectTypeHeadSingle
                                label="Banco receptor"
                                inputName="receptor"
                                onChangeMethod={onChange}
                                onBlurMethod={handleBlur}
                                value={values.receptor}
                                isRequired
                                touched={touched.receptor}
                                errors={errors.receptor}
                                optionsArray={banks}
                                optionValue="cepKey"
                                optionName="nameBank"
                            />
                        </Col>
                        <Col sm={12} md={4}>
                            <SelectTypeHeadSingle
                                label="Cuenta del empleado"
                                inputName="cuenta"
                                onChangeMethod={onChange}
                                onBlurMethod={handleBlur}
                                value={values.cuenta}
                                isRequired
                                touched={touched.cuenta}
                                errors={errors.cuenta}
                                optionsArray={accounts}
                                optionValue="switfCode"
                                optionName="switfCode"
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12} md={4}>
                            <TextInput
                                errors={errors.clave}
                                inputName="clave"
                                value={values.clave}
                                touched={touched.clave}
                                isRequired
                                label="Clave de rastreo"
                                onBlurMethod={handleBlur}
                                onChangeMethod={onChange}
                            />
                        </Col>
                        <Col sm={12} md={4}>
                            <NumberInput
                                errors={errors.monto}
                                inputName="monto"
                                value={values.monto}
                                touched={touched.monto}
                                decimals={true}
                                isRequired
                                label="Monto"
                                onBlurMethod={handleBlur}
                                onChangeMethod={onChange}
                                placeholder={"$0.00"}
                            />
                        </Col>
                        <Col sm={12} md={4}>
                            <DateInput
                                label="Fecha de emision"
                                inputName="fecha"
                                onChangeMethod={(date) => onChange({ target: { name: "fecha", value: new Date(date) } })}
                                value={values.fecha}
                                touched={touched.fecha}
                                errors={errors.fecha}
                            />
                        </Col>
                    </Row>
                    <div className="text-center">
                        <Button type="submit">Consultar</Button>
                    </div>
                </form>
                
            </Widget>
        </>
    )
}
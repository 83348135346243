/**
 * @param params setters from Format
 */
export function setConfigR43(params) {
    const { setHeaders, setPdfConfig, setExcelConfig, setHtmlConfig, setData, filter } = params;
    const headers = [
        "Clave Proyecto",
        "Servicios de Salud",
        "Fondo Solidario de Reparto",
        "Aportaciones Obligatorias SCI",
        "Gastos de Administración",
        "Riesgos de Trabajo",
    ]
    setHeaders(headers)
    const tempData = {
        action: "getAportacionesISSEMYM",
        period: filter.period,
        year: (new Date(filter.year)).getFullYear()
    }
    setData(tempData);
    const content = [
        { header: "Clave Proyecto", value: "claveProyecto" },
        { header: "Servicios de Salud", value: "SS" },
        { header: "Fondo Solidario de Reparto", value: "FSSR" },
        { header: "Aportaciones Obligatorias SCI", value: "AOSCI" },
        { header: "Gastos de Administración", value: "GA" },
        { header: "Riesgos de Trabajo", value: "RT" },
    ];
    setHtmlConfig({
        headers,
        id: { id: "claveProyecto" },
        content: [content]
    })
    setPdfConfig({
        descr: tempData,
        headers,
        content,
        orientation: "h"
    });
    setExcelConfig({
        headers,
        content
    });
}
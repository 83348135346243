import React, { useState, useEffect } from "react";

import { useHistory } from "react-router-dom";
import { Button, Row, Col } from "reactstrap";
import { Formik, Form, Field, ErrorMessage, useFormikContext } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import Notification from "../../../components/Notification/Notification";
import Loader from "../../../components/Loader/Loader";
import ModalConfirmation from "../../../components/Modal/ModalConfirmation";
import { peticionesReceiver } from "../../../helpers/peticionesReceiver";
import { SelectTypeHeadSingle } from "../../../components/GenericInputsFormik/SelectTypeHeadSingle";

const defaultForm = {
  idModule: "",
  nameModule: "",
  keySystem: "",
  fileRoute: "",
  pModule: "",
};

const FormModulos = ({ crear, data, editar }) => {
  const history = useHistory();
  const API = peticionesReceiver();
  const [sistemas, setSistemas] = useState([]);
  const [loading, setLoading] = useState(true);
  const [modulos, setModulos] = useState([]);
  const [modal, setModal] = useState(false);

  const getAnswer = async () => {
    const data = {
      action: "select",
      table: "apparatus",
      order: "nameSystem ASC",
    };
    API.peticion(data)
      .then((res) => {
        if (res.status === 200 && res.data.code === "200") {
          setSistemas(res.data.data);
        } else {
          toast(<Notification type={"consultar_error"} withIcon />);
        }
        setLoading(false);
      })
      .catch((err) => {
        toast(<Notification type={"consultar_servidor_error"} withIcon />);
        setLoading(false);
      });
  };
  const getModulos = async (idMod) => {
    if(!idMod) return ;
    const data = {
      action: "select",
      table: "modules",
      order: "nameModule ASC",
      condition: {keySystem: idMod}
    };
    API.peticion(data)
      .then((res) => {
        if (res.status === 200 && res.data.code === "200") {
          if(res.data.data.length > 0){
            setModulos(res.data.data);
          }else{
            toast(<Notification type={"consultar_error"} backMessage="No hay módulos registrados para este sistema" withIcon />);
          }
        } else {
          toast(<Notification type={"consultar_error"} withIcon />);
        }
      })
      .catch((err) => {
        toast(<Notification type={"consultar_servidor_error"} withIcon />);

      });
  };

  useEffect(() => {
    getAnswer();
  }, []);

  const [form] = useState(defaultForm);

  const FormSchema = Yup.object().shape({
    nameModule: Yup.string()
      .min(3, "Nombre debe contener al menos 3 letras")
      .required("Ingrese un nombre"),
    fileRoute: Yup.string()
      .min(3, "Nombre debe contener al menos 3 letras")
      .required("Ingrese una ruta"),
    keySystem: Yup.string().required("Elija un sistema"),
  });

  const submitFunction = (fields) => {
    setModal(true);
  };

  const cancelOp = () => {
    history.push("/template/modulos");
  };

  return (
    <div>
      {!loading ? (
        <Formik
          initialValues={form}
          validationSchema={FormSchema}
          onSubmit={(fields) => {
            submitFunction(fields);
          }}
          onReset={(fields, { resetForm }) => {
            cancelOp();
          }}
        >
          {({ errors, touched, values, handleBlur, setFieldValue }) => (
            <Form className="mt-4 mb-2">
              <ModalConfirmation
                modalTitle={data ? "Editar" : "Crear"}
                modal={modal}
                setModal={setModal}
                crear={crear}
                editar={editar}
                isEdit={data ? true : false}
                values={values}
              >
                {data ? (
                  <div className="d-flex justify-content-center">
                    <h6>¿Está seguro de editar el registro?</h6>
                  </div>
                ) : (
                  <div className="d-flex justify-content-center">
                    <h6>¿Desea continuar con el registro?</h6>
                  </div>
                )}
              </ModalConfirmation>

              <Row>
                <Col xs="12" md="6">
                  <div className="form-group">
                    <label htmlFor="nameModule">
                      Nombre del Módulo<span className="text-danger">*</span>
                    </label>
                    <Field
                      name="nameModule"
                      type="text"
                      placaceholder="Nombre del perfil"
                      className={
                        "form-control" +
                        (errors.nameModule && touched.nameModule
                          ? " is-invalid"
                          : "")
                      }
                    />
                    <ErrorMessage
                      name="nameModule"
                      component="div"
                      className="invalid-feedback"
                    />
                  </div>
                </Col>
                <Col xs="12" md="6">
                  <div className="form-group">
                    <label htmlFor="fileRoute">
                      Ruta de archivo<span className="text-danger">*</span>
                    </label>
                    <Field
                      name="fileRoute"
                      type="text"
                      placaceholder="Nombre del perfil"
                      className={
                        "form-control" +
                        (errors.fileRoute && touched.fileRoute
                          ? " is-invalid"
                          : "")
                      }
                    />
                    <ErrorMessage
                      name="fileRoute"
                      component="div"
                      className="invalid-feedback"
                    />
                  </div>
                </Col>
              </Row>

              <Row>
                <Col xs="12" md="6">
                  <SelectTypeHeadSingle
                    label="Sistema"
                    inputName="keySystem"
                    onChangeMethod={(e) =>{
                      setModulos([]);
                      setFieldValue("pModule", "");
                      setFieldValue([e.target.name], e.target.value);
                      getModulos(e.target.value);
                    }}
                    onBlurMethod={handleBlur}
                    value={values.keySystem}
                    isRequired={true}
                    touched={touched.keySystem}
                    errors={errors.keySystem}
                    optionsArray={sistemas}
                    optionValue="keySystem"
                    optionName="nameSystem"
                    defaultOption="Seleccione un sistema"
                  />
                </Col>

                <Col xs="12" md="6">
                  <SelectTypeHeadSingle
                    label="Módulo padre"
                    inputName="pModule"
                    onChangeMethod={(e) =>
                      setFieldValue([e.target.name], e.target.value)
                    }
                    onBlurMethod={handleBlur}
                    value={values.pModule}
                    touched={touched.pModule}
                    errors={errors.pModule}
                    optionsArray={modulos}
                    optionValue="idModule"
                    optionName="nameModule"
                    defaultOption="Seleccione un modulo padre"
                    isDisabled={!values.keySystem && modulos.length < 1}
                  />
                </Col>
              </Row>

              <div className=" d-flex flex-md-row flex-column-reverse justify-content-md-around">
                <Button color="danger" type="reset" className="mt-3">
                  Cancelar
                </Button>
                <Button color="success" type="submit" className="mt-2 mt-md-3">
                  Guardar
                </Button>
              </div>
              <FormikData info={data} getModulos={getModulos} />
            </Form>
          )}
        </Formik>
      ) : (
        <Loader />
      )}
    </div>
  );
};

const FormikData = ({ info, getModulos }) => {
  const { setFieldValue } = useFormikContext();
  useEffect(() => {
    if (info) {
      getModulos(info[6]);
      setFieldValue("idModule", info[0]);
      setFieldValue("nameModule", info[1]);
      setFieldValue("keySystem", info[6]);
      setFieldValue("fileRoute", info[2]);
      setFieldValue("pModule", info[7]);
      setFieldValue("level", info[4]);
    }
    
  }, [info]);
  return null;
};

export default FormModulos;

import React, {useState} from "react";
import Widget from "../../../../components/Widget/Widget";
import FormSistema from "../FormSistema"
import {agregar} from "../functions"
import {
  Col    
} from "reactstrap";

export const CrearSistema = (props) => {
  return (
    <div>
      <Widget className="widget-p-md">
        <Col className="mb-12"><h1 className="text-center">Crear sistema</h1></Col>
        <FormSistema crear={agregar}/>
    </Widget>
    </div>
  );
};

export default CrearSistema;

import { peticionesReceiver } from "../../../../helpers/peticionesReceiver";
import { toast } from "react-toastify";
import Notification from "../../../../components/Notification/Notification";

export default async function getCapitulosOrigen(annualBudget, idStateFund, setCapitulos) {
    
    if (annualBudget != null && idStateFund != null) {
        const API = peticionesReceiver();
        const params = {
            action: "getChaptersOrigin",
            idAnnualBudget: annualBudget.id,
            idStateFund
        };
        API.peticionEndPoint(params, "app/facades/budgets/budgetsF.php")
            .then((res) => {
                if (res.status === 200) {
                    setCapitulos(res.data.data);
                } else {
                    toast(
                        <Notification
                            type={"consultar_error"}
                            backMessage={res.data.message}
                            withIcon
                        />
                    );
                }
            })
            .catch((err) => {
                toast(<Notification type={"consultar_servidor_error"} withIcon />);
            });
    }
}
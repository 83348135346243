import React, { useState, useEffect, useRef } from "react";
import { Col, Row, Button, Collapse } from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { TextInput } from "../../../components/GenericInputsFormik/index";
import Widget from "../../../components/Widget/Widget";
import { peticionesReceiver } from "../../../helpers/peticionesReceiver";
import { SelectTypeHeadSingle } from "../../../components/GenericInputsFormik/SelectTypeHeadSingle";
import { toast } from "react-toastify";
import Notification from "../../../components/Notification/Notification";
import { data } from "browserslist";
import { useHistory } from "react-router-dom";
import ModalConfirmation from "../../../components/Modal/ModalConfirmation";
import Loader from "../../../components/Loader/Loader";
import Camera from "../../../components/Modal/ModalCamera";
import { dataURLtoFile, formikImageFile2Image64 } from "../../../libs/tools/images";
import SignaturePad from 'react-signature-canvas'
import * as faceapi from 'face-api.js'
import FullFormLoader from "../../../components/Loader/FullFormLoader";

const API = peticionesReceiver();

const CrearGafete = () => {
  const [templates, setTemplates] = useState("");
  const idStaff = localStorage.getItem("dataContent");
  const [photo, setPhoto] = useState(null);
  const [sign, setSign] = useState(null);
  const [dataStaff, setDataStaff] = useState("");
  const [modal, setModal] = useState(false);
  const [visible, setVisible] = useState(false)
  const [loader, setLoader] = useState(false);
  const [loadingModels, setLoadingModels] = useState(true);
  const [loadedFromFile, setLoadedFromFile] = useState(false);
  const [loadingDescr, setLoadingDescr] = useState(false);
  const [height, setHeight] = useState("350px")
  const previewRef = useRef(null)
  let sigPad1 = useRef(null);
  const refInpFile = useRef(null);
  const history = useHistory();

  useEffect(() => {
    getDocuments();
    getTemplates();
    getDataStaff();
    LoadModels()
  }, []);

  const getDocuments = () => {
    let params = {
      action: "getFilesbyModule",
      reference: idStaff,
      idModule: 25,
      conditions:
        "nameFyleType = 'FOTOGRAFIA' AND nameFyleType = 'FIRMA' and enabled = 1",
    };
    API.peticionEndPoint(params, "app/facades/files/fileReceiver.php")
      .then((res) => {
        if (res.status === 200 && res.data.status === "success") {
          res.data.data.map((doc) => {
            // se llena el setFieldValues, en caso de no cambiar no se hace la insercion de datos
            if (doc.nameFyleType === "FOTOGRAFIA") {
              setFieldValue("urlPhoto", doc.src);
              setPhoto(doc);
            } else if (doc.nameFyleType === "FIRMA") {
              /* setUrlSign(doc.src); */
              setFieldValue("urlSign", doc.src);
              setSign(doc);
            }
          });
          /* setDocuments(res.data.data); */
        } else {
          toast(
            <Notification
              type={"consultar_error"}
              backMessage={res.data.message}
              withIcon
            />
          );
        }
      })
      .catch((err) => {
        toast(<Notification type={"consultar_error"} withIcon />);
      });
  };

  const getDataStaff = () => {
    let data = {
      action: "multiselect",
      table:
        "staff STF INNER JOIN employtypes AS EMT ON (STF.keyEmployType = EMT.keyEmployType) INNER JOIN jobstaff AS JBS ON (JBS.idStaff = STF.idStaff) INNER JOIN areas AS AR ON (AR.idArea = JBS.idArea) INNER JOIN jobs JOB on (JBS.keyJob = JOB.keyJob) LEFT JOIN bloodtypes BLL on (STF.keyBloodType = BLL.keyBloodType)",
      rows: "STF.idStaff,STF.staffNumber,CONCAT(STF.name,' ',STF.pName,' ',STF.mName) as fullName,AR.name area,JBS.endDate,STF.kindAlergy,STF.curp,JOB.nameJob,BLL.nameBloodType,JBS.idJobStaff",
      conditions: `STF.enabled = 1 AND JBS.valid = 1 AND STF.keyStat = 1 AND JBS.enabled = 1 and STF.idStaff =${idStaff}`,
    };
    API.peticion(data)
      .then((res) => {
        if (res.status === 200 && res.data.status === "success") {
          setDataStaff(res.data.data[0]);
          setFieldValue("endDate", res.data.data[0].endDate);
        } else {
          toast(
            <Notification
              type={"consultar_error"}
              backMessage={res.data.message}
              withIcon
            />
          );
        }
      })
      .catch((err) => {
        toast(<Notification type={"consultar_error"} withIcon />);
      });
  };

  const getTemplates = async () => {
    const data = {
      action: "datatable",
      table: "templatesNameTags",
      rows: "idTemplateNameTags,nameTemplateNameTag",
      conditions: `enabled = 1`,
      page: 0,
      records: 5,
      search: "",
      order: "",
    };
    let stats = [];
    API.peticion(data)
      .then((res) => {
        if (res.status === 200 && res.data.status === "success") {
          stats = res.data.data;
          stats = stats.filter(
            (stat) => stat.nameStat !== "ACTIVO" && stat.nameStat !== "BAJA"
          );

          setTemplates(stats);
        } else {
          toast(
            <Notification
              type={"consultar_error"}
              backMessage={res.data.message}
              withIcon
            />
          );
        }
      })
      .catch((err) => {
        toast(<Notification type={"consultar_error"} withIcon />);
      });
  };
  const [defaultForm] = useState({
    idTemplateNameTags: "",
    urlPhoto: "",
    urlSign: "",
    keyStat: "",
    photo: "",
    sign: "",
    endDate: data.endDate,
  });

  const insertInfo = async (values, type) => {
    //deacuerdo al type hara cierta peticion 3= gafete, 1= foto 2 = firma
    let params = {};
    if (type === 3) {
      params = {
        action: "saveNameTags",
        rows: {
          idStaff: idStaff,
          idTemplateNameTag: values.idTemplateNameTags,
          validDate: values.endDate,
          idGeneral: dataStaff.idJobStaff,
        },
      };
      API.peticionEndPoint(params, "app/facades/employees/nameTagsF.php")
        .then((res) => {
          if (res.status === 200 && res.data.code === "200") {
            toast(
              <Notification
                type={"agrega_exito"}
                backMessage={res.data.message}
                withIcon
              />
            );
            history.push("/template/gafetes/historial");
          } else {
            toast(
              <Notification
                type={"consultar_error"}
                backMessage={res.data.message}
                withIcon
              />
            );
          }
        })
        .catch((err) => {
          toast(<Notification type={"consultar_servidor_error"} withIcon />, {
            closeButton: false,
          });
        });
    } else {
      const formData = new FormData();
      formData.append("action", "saveFileReplace");
      formData.append("reference", idStaff);
      formData.append("keyFileType", type);
      formData.append("binary", type === 1 ? values.photo : values.sign);
      params = {
        method: "post",
        url: `${process.env.REACT_APP_API}app/facades/files/fileReceiver.php`,
        data: formData,
      };
      await axios(params)
        .then((res) => {
          toast(
            <Notification
              type={"agrega_exito"}
              backMessage={
                type === 1
                  ? "Se ha actualizado la foto correctamente"
                  : "Se ha actualizado la firma correctamente"
              }
              withIcon
            />
          );

          /* updateData();
          setIsOpen(false);
          getFileTypes(); */
        })
        .catch((err) => {
          toast(<Notification type={"agrega_error"} withIcon />);
        });
    }
  };

  async function sendDescriptor(values) {
    const params = {
      action: "update",
      table: "staff",
      rows: {
        descriptor: Object.values(values.descriptor).join("?")
      },
      condition: {
        idStaff: idStaff
      }
    }
    try {
      const res = await API.peticion(params);
      if (res.status === 200 && res.data.code === "200") {
        toast(<Notification type={"modifica_exito"} backMessage={res.data.message} withIcon />);
        return true;
      } else {
        toast(<Notification type={"modifica_error"} backMessage={res.data.message} withIcon />);
        return false;
      }
    } catch (err) {
      toast(<Notification type={"consultar_servidor_error"} withIcon />);
      return false;
    }
  }

  const chooseAdds = async (values) => {
    //valida si url de foto es la misma, en caso de que no hace insercion de la nueva foto
    if (photo !== null && photo !== undefined) {
      if (values.urlPhoto !== photo.src) {
        const res = await sendDescriptor(values);
        if (res) {
          insertInfo(values, 1);
        }
      }
    } else {
      const res = await sendDescriptor(values);
      if (res) {
        insertInfo(values, 1);
      }
    }

    if (sign !== null && sign !== undefined) {
      if (values.urlSign !== sign.src) {
        insertInfo(values, 2);
      }
    } else {
      insertInfo(values, 2);
    }

    insertInfo(values, 3);
  };

  const correctFiles = (values) => {
    if (values.urlPhoto !== "" || photo !== null) {
      if (values.urlSign !== "" || sign !== null) {
        return true;
      } else {
        toast(
          <Notification
            type={"consultar_error"}
            backMessage={"Agregue una firma"}
            withIcon
          />
        );
      }
    } else {
      toast(
        <Notification
          type={"consultar_error"}
          backMessage={"Agregue una fotografia "}
          withIcon
        />
      );
    }
    return false;
  };

  const FormSchema = Yup.object().shape({
    idTemplateNameTags: Yup.string().required("Seleccione una plantilla"),
    endDate: Yup.string().required("Seleccione una fecha de termino"),
  });

  const {
    handleSubmit,
    values,
    handleBlur,
    errors,
    touched,
    setFieldValue,
    resetForm,
  } = useFormik({
    initialValues: defaultForm,
    onSubmit: (values) => {
      if (correctFiles(values)) {
        setModal(true);
      }
    },
    validationSchema: FormSchema,
    enableReinitialize: true,
  });

  const changeFile = (e, urlFile) => {
    //validaciones de imagen
    if (e.target.files[0] !== null && e.target.files[0] !== undefined) {
      //validaciones de formato
      if (
        e.target.files[0].type === "image/png" ||
        e.target.files[0].type === "image/jpeg" ||
        e.target.files[0].type === "image/gif" ||
        e.target.files[0].type === "image/jpg"
      ) {
        //validaciones de tamaño
        if (e.target.files[0].size < 5000000) {
          //creacion url y link a previzualizar
          if (e.target.name === "urlPhoto") {
            URL.revokeObjectURL(values.urlPhoto);
          } else {
            URL.revokeObjectURL(values.urlSign);
          }
          setFieldValue([e.target.name], e.target.files[0]);
          formikImageFile2Image64(e.target.files[0], setFieldValue, urlFile, false);
          if (e.target.name === "photo") {
            setLoadedFromFile(true);
            setLoadingDescr(true);
          }
        } else {
          toast(
            <Notification
              type={"consultar_error"}
              backMessage={"El archivo es demaiado grande"}
              withIcon
            />
          );
          setFieldValue([e.target.name], null);
        }
      } else {
        toast(
          <Notification
            type={"consultar_error"}
            backMessage={"Seleccione una imagen"}
            withIcon
          />
        );
        setFieldValue([e.target.name], null);
      }
    } else {
      setFieldValue([e.target.name], null);
    }
  };

  const onChange = (e) => {
    if (e.target.name === "photo") {
      changeFile(e, "urlPhoto");
    } else if (e.target.name === "sign") {
      changeFile(e, "urlSign");
    } else {
      setFieldValue([e.target.name], e.target.value);
    }
  };

  const resetDefaultValues = () => {
    setFieldValue("endDate", dataStaff.endDate);
    setFieldValue("idTemplateNameTags", "");
    setFieldValue("urlPhoto", photo.src);
    setFieldValue("urlSign", sign.src);
    setFieldValue("photo", "");
    setFieldValue("sign", "");
  };

  function setImage(key, value) {
    if (key === "captura") {
      setLoadedFromFile(false);
      const file = dataURLtoFile(value, "fotografia.jpeg");
      setFieldValue("photo", file);
      setFieldValue("urlPhoto", value);
    } else {
      setFieldValue(key, value);
    }
  }

  function onEndSignature() {
    const pad = sigPad1?.current;
    if (pad !== null && pad !== undefined) {
      const url = pad.toDataURL();
      const file = dataURLtoFile(url, "Firma.png", "image/png")
      setFieldValue("urlSign", url);
      setFieldValue("sign", file)
    }
  }

  function cleanSign() {
    setFieldValue("urlSign", "");
    setFieldValue("sign", "")
    sigPad1?.current?.clear();
  }

  async function LoadModels() {
    const loadFromOrigin = process.env.REACT_APP_FACE_API_ORIGIN === "true" ? true : false;
    const origin = window.location.origin;
    await Promise.all([
      faceapi.nets.ssdMobilenetv1.loadFromUri(!loadFromOrigin ? `https://cdn.jsdelivr.net/gh/cgarciagl/face-api.js/weights/` : `${origin}/weights/`),
      faceapi.nets.faceLandmark68Net.loadFromUri(!loadFromOrigin ? `https://cdn.jsdelivr.net/gh/cgarciagl/face-api.js/weights/` : `${origin}/weights/`),
      faceapi.nets.faceRecognitionNet.loadFromUri(!loadFromOrigin ? `https://cdn.jsdelivr.net/gh/cgarciagl/face-api.js/weights/` : `${origin}/weights/`),
      faceapi.nets.tinyFaceDetector.loadFromUri(!loadFromOrigin ? `https://cdn.jsdelivr.net/gh/cgarciagl/face-api.js/weights/` : `${origin}/weights/`)
    ])
    setLoadingModels(false)
  }

  const handleScreenshot = async (blob) => {
    if (previewRef && previewRef.current) {
      setHeight(`${previewRef.current.height}px`)
    }
    if (loadedFromFile) {
      try {
        if (previewRef && previewRef.current) {

          const face = await faceapi.detectSingleFace(previewRef.current).withFaceLandmarks().withFaceDescriptor();
          if (face) {
            const displaySize = { width: previewRef.current.width, height: previewRef.current.height }
            const resizedDetections = faceapi.resizeResults(face, displaySize)
            setFieldValue("descriptor", resizedDetections.descriptor)
            setLoadingDescr(false)
          } else {
            setFieldValue("photo", "")
            setFieldValue("urlPhoto", "")
            refInpFile.current.value = ""
            setLoadingDescr(false);
            toast(<Notification type={"consultar_error"} backMessage={"No se a detectado una persona en la fotografia"} withIcon />);
          }
        }
      } catch (err) {
        setFieldValue("photo", "")
        setFieldValue("urlPhoto", "")
        refInpFile.current.value = ""
        setLoadingDescr(false);
        toast(<Notification type={"consultar_error"} backMessage={"Ocurrio un error al leer el archivo"} withIcon />);
      }
    }
  }

  return (
    <Widget className="widget-p-md">
      <form onSubmit={handleSubmit} className="mt-4">
        <Row className="d-flex justify-content-around align-items-center">
          <div className="headline-1 text-center mb-3">Nuevo Gafete</div>
        </Row>

        <Row className="d-flex justify-content-around">
          <Col xs={12} lg={5}>
            <div
              className="headline-1 text-center d-flex justify-content-around align-items-center mb-3"
            >
              {values.urlPhoto === "" ? (
                <>Seleccione una foto</>
              ) : (
                <>
                  <img
                    src={values.urlPhoto}
                    ref={previewRef}
                    alt="archivo"
                    className="img-fluid"
                    style={{
                      minHeight: "350px",
                      maxWidth: "100%"
                    }}
                    onLoad={() => handleScreenshot(values.urlPhoto)}
                    res
                  />
                </>
              )}
            </div>
            <div className="mb-3 custom-file">
              <input type="file" name="photo" className="custom-file-input" id="photo" ref={refInpFile} onChange={onChange} />
              <label htmlFor="file" className="custom-file-label">Seleccione una fotografia</label>
            </div>
            <p className="text-center font-weight-bold mt-3">ó</p>
            <div className="text-center">
              <Button onClick={() => setVisible(true)}>Tomar fotografia</Button>
            </div>
          </Col>
          <Col xs={12} lg={5}>
            <div
              className="headline-1 text-center d-flex justify-content-around align-items-end mb-3"
              style={{ minHeight: height, maxHeight: height }}
            >
              {values.urlSign === "" ? (
                <>Seleccione una firma</>
              ) : (
                <>
                  <img
                    src={values.urlSign}
                    alt="archivo"
                    className="img-fluid border"
                    style={{
                      maxHeight: "350px",
                      minHeight: "350px",
                    }}
                  />
                </>
              )}
            </div>
            <div className="mb-3 custom-file">
              <input type="file" name="sign" className="custom-file-input" id="sign" lang="es" onChange={onChange} />
              <label htmlFor="sign" className="custom-file-label">Seleccione una firma</label>
            </div>
            <div className="d-flex flex-column mx-auto" style={{ maxWidth: "fit-content" }}>
              <p className="text-center mt-3 font-weight-bold">ó</p>
              <h6 className="text-center">Realize una firma autografa en el recuadro</h6>
              <div className="text-center mb-3">
                <Button variant="warning" onClick={cleanSign}>Limpiar</Button>
              </div>
              <div className="text-center border" style={{ width: "fit-content", height: "fit-content", backgroundColor: "#fff" }}>
                <SignaturePad
                  canvasProps={{ width: 400, height: 200 }}
                  ref={sigPad1}
                  clearOnResize={false}
                  onEnd={onEndSignature}
                />
              </div>
            </div>
          </Col>
        </Row>

        <Row className="d-flex justify-content-around mt-3">
          <Col xs={12} md={12} lg={5}>
            <SelectTypeHeadSingle
              label="Plantilla Gafete"
              isRequired={true}
              inputName="idTemplateNameTags"
              optionsArray={templates}
              defaultOption="Seleccione una plantilla"
              onChangeMethod={onChange}
              onBlur={handleBlur}
              value={values.idTemplateNameTags}
              touched={touched.idTemplateNameTags}
              errors={errors.idTemplateNameTags}
              optionValue="idTemplateNameTags"
              optionName="nameTemplateNameTag"
            />
          </Col>
        </Row>
        <Row className="d-flex justify-content-around">
          <Col xs={12} md={12} lg={5}>
            <TextInput
              label="Fecha de termino"
              inputType="date"
              inputName="endDate"
              onChangeMethod={onChange}
              onBlurMethod={handleBlur}
              value={values.endDate}
              isRequired={true}
              touched={touched.endDate}
              errors={errors.endDate}
              isMin={0}
            />
          </Col>
        </Row>

        <Row className="d-flex justify-content-center m-5 text-center">
          <Col xs={12} md={12} lg={6}>
            <Button
              color="danger"
              type="reset"
              onClick={() => resetDefaultValues()}
            >
              LIMPIAR
            </Button>
          </Col>
        </Row>
        <div className="border">
          <Row className="d-flex justify-content-center m-5 text-center">
            <Col xs={12} md={12} lg={6}>
              <span className="headline-1 text-secondary mb-3">
                Informacion del gafete
              </span>
            </Col>
          </Row>

          <Row className="d-flex justify-content-around aling-items-center m-5">
            <Col
              xs={12}
              md={12}
              lg={3}
              className="d-flex flex-column justify-content-around aling-items-center text-center"
            >
              <span className="headline-2 text-secondary mb-3">
                Tipo de sangre:
              </span>
              <span className="headline-3">
                {dataStaff.nameBloodType
                  ? dataStaff.nameBloodType
                  : "Sin Registro"}
              </span>
            </Col>
            <Col
              xs={12}
              md={12}
              lg={3}
              className="d-flex flex-column justify-content-around aling-items-center text-center"
            >
              <span className="headline-2 text-secondary mb-3">Alergias:</span>
              <span className="headline-3">
                {" "}
                {dataStaff.kindAlergy ? dataStaff.kindAlergy : "Sin Registro"}
              </span>
            </Col>
            <Col
              xs={12}
              md={12}
              lg={3}
              className="d-flex flex-column justify-content-around aling-items-center text-center"
            >
              <span className="headline-2 text-secondary mb-3">Curp:</span>
              <span className="headline-3">
                {dataStaff.curp ? dataStaff.curp : "Sin Registro"}
              </span>
            </Col>
          </Row>
          <Row className="d-flex justify-content-around m-5">
            <Col
              xs={12}
              md={12}
              lg={3}
              className="d-flex flex-column justify-content-around aling-items-center text-center"
            >
              <span className="headline-2 text-secondary mb-3">
                Departamento:
              </span>
              <span className="headline-3">
                {dataStaff.area ? dataStaff.area : "Sin Registro"}
              </span>
            </Col>
            <Col
              xs={12}
              md={12}
              lg={3}
              className="d-flex flex-column justify-content-around aling-items-center text-center"
            >
              <span className="headline-2 text-secondary mb-3">Puesto:</span>
              <span className="headline-3">
                {dataStaff.nameJob ? dataStaff.nameJob : "Sin Registro"}
              </span>
            </Col>
          </Row>
        </div>

        {loader ? (
          <Loader />
        ) : (
          <div className="row mt-3">
            <div className="col-sm-6 order-2 order-sm-1 text-center text-sm-left mb-4">
              <Button
                color="danger"
                type="reset"
                onClick={() => history.push("/template/gafetes/historial")}
              >
                Cancelar
              </Button>
            </div>
            <div className="col-sm-6 order-1 order-sm-2 text-center text-sm-right mb-4">
              <Button color="success" type="submit">
                Guardar
              </Button>
            </div>
          </div>
        )}

        <ModalConfirmation
          modalTitle={"Crear"}
          modal={modal}
          setModal={setModal}
          crear={chooseAdds}
          isEdit={false}
          values={values}
          reset={resetForm}
        >
          <div className="d-flex justify-content-center">
            <h6>¿Desea continuar con el registro?</h6>
          </div>
        </ModalConfirmation>
      </form>
      <Camera
        setFieldValue={setImage}
        setVisible={setVisible}
        visible={visible}
      />
      <FullFormLoader message={"Cargando información de la imagen"} setShow={setLoadingDescr} show={loadingDescr} />
      <FullFormLoader message={"Cargando..."} setShow={setLoadingModels} show={loadingModels} />
    </Widget>

  );
};
export default CrearGafete;

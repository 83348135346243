import { Document, Page, StyleSheet, Text, View } from "@react-pdf/renderer";
import HeaderReport from "../../../../components/Pdf/Header";

const fmtMny = new Intl.NumberFormat("es-MX", {
  style: "currency",
  currency: "MXN",
});

const s = StyleSheet.create({
  body: {
    paddingVertical: "30px",
    paddingHorizontal: "20px",
  },

  title: {
    fontSize: "12px",
    textAlign: "center",
    fontFamily: "Helvetica-Bold",
  },

  header: {
    backgroundColor: "#730A29",
    textAlign: "center",
    color: "#FFFFFF",
  },

  mCell: { padding: "2px" },
  mxCell: { paddingHorizontal: "2px" },

  vCenter: { alignItems: "center", justifyContent: "center" },
  vCenterL: { alignItems: "left", justifyContent: "center" },
  vCenterR: { alignItems: "right", justifyContent: "center" },
  vCenterBtw: { alignItems: "stretch", justifyContent: "center" },

  dflexBtw: { display: "flex", justifyContent: "space-between" },
  dflexEnd: { display: "flex", justifyContent: "flex-end" },

  alignE: { alignContent: "flex-end" },

  row: { flexDirection: "row" },
  flexWrap: { display: "flex", flexWrap: "wrap", flexDirection: "row" },

  w5: { width: "5%" },
  w10: { width: "10%" },
  w15: { width: "15%" },
  w20: { width: "20%" },
  w25: { width: "25%" },
  w30: { width: "30%" },
  w35: { width: "35%" },
  w40: { width: "40%" },
  w45: { width: "45%" },
  w50: { width: "50%" },
  w55: { width: "55%" },
  w60: { width: "60%" },
  w65: { width: "65%" },
  w70: { width: "70%" },
  w75: { width: "75%" },
  w80: { width: "80%" },
  w90: { width: "90%" },
  w100: { width: "100%" },
  wAuto: { width: "auto" },

  border: { border: "1px solid #000", borderBottom: "0px" },
  borderT: { borderBottom: "1px solid #000" },
  borderB: { borderBottom: "1px solid #000" },
  borderB2: { borderBottom: "2px solid #000" },
  borderE: { borderRight: "1px solid #000" },
  borderS: { borderLeft: "1px solid #000" },
  borderBS: { borderBottom: "4px solid #730A29" },
  borderHE: { borderRight: "1px solid #FFFFFF" },

  bg: { backgroundColor: "#BEBEBE" },
  bg2: { backgroundColor: "#BEBEBE" },

  fs7: { fontSize: "7px" },
  fs8: { fontSize: "8px" },
  fs9: { fontSize: "9px" },
  fs10: { fontSize: "10px" },
  fs11: { fontSize: "11px" },
  fs12: { fontSize: "12px" },
  fs13: { fontSize: "13px" },

  tend: { textAlign: "right", paddingRight: "2px" },
  tstart: { textAlign: "left" },
  tcenter: { textAlign: "center" },
  tBold: { fontFamily: "Helvetica-Bold" },

  mT8: { marginTop: "8" },
  mT10: { marginTop: "10px" },
  mT20: { marginTop: "20px" },
  mT40: { marginTop: "40px" },
  mx18: { marginHorizontal: "18px" },
  mx20: { marginHorizontal: "20px" },

  spaceFooter: {
    bottom: 10,
    left: 0,
    right: 0,
    width: "auto",
    height: 30,
    marginHorizontal: 10,
  },

  footer: {
    position: "absolute",
    bottom: 10,
    left: 0,
    right: 0,
    width: "auto",
    height: 30,
    marginHorizontal: 10,
    borderTopWidth: 1,
    flexDirection: "row",
    alignContent: "space-around",
    alignItems: "center",
  },
  textFooter: {
    width: "50%",
    textAlign: "center",
    fontSize: 8,
  },
});

export default async function PdfPensionesBancos(data, config, t, fileNo) {
  const cfg = config.cfg;
  return {
    report: (
      <Document>
        <Page style={[s.body, s.fs12]} orientation="landscape">
          <HeaderReport />
          <View>
            <View style={[s.tcenter]}>
              <Text>PENSIONES BANCOS</Text>
            </View>
            {cfg.bank !== "" && (
              <View style={[s.tcenter]}>
                <Text>{cfg.bank}</Text>
              </View>
            )}
            <View style={[s.tcenter]}>
              <Text>{`del Periodo ${cfg.period} del Año ${cfg.year}`}</Text>
            </View>
          </View>

          <View style={[s.border, s.mT10, s.fs11]}>
            <View style={[s.row, s.header, s.borderB]} fixed>
              <View style={[s.w10, s.vCenter, s.borderHE]}>
                <Text style={s.mxCell}>NÚMERO{"\n"}EMPLEADO</Text>
              </View>
              <View style={[s.w20, s.vCenter, s.borderHE]}>
                <Text style={s.mxCell}>NOMBRE EMPLEADO</Text>
              </View>
              <View style={[s.w10, s.vCenter, s.borderHE]}>
                <Text style={s.mxCell}>ID PENSIÓN</Text>
              </View>
              <View style={[s.w20, s.vCenter, s.borderHE]}>
                <Text style={s.mxCell}>NOMBRE BENEFICIARIO</Text>
              </View>
              <View style={[s.w10, s.vCenter, s.borderHE]}>
                <Text style={s.mxCell}>CUENTA{"\n"}/CLABE</Text>
              </View>
              <View style={[s.w10, s.vCenter, s.borderHE]}>
                <Text style={s.mxCell}>ID BANCO</Text>
              </View>
              <View style={[s.w10, s.vCenter, s.borderHE]}>
                <Text style={s.mxCell}>BANCO</Text>
              </View>
              <View style={[s.w10, s.vCenter]}>
                <Text style={s.mxCell}>IMPORTE</Text>
              </View>
            </View>
            {data.map((d, id) => (
              <View style={[s.row, s.borderB]} key={"tcr" + id}>
                <View style={[s.w10, s.vCenter, s.borderE]}>
                  <Text style={s.mxCell}>{d.staffNumber}</Text>
                </View>
                <View style={[s.w20, s.vCenterL, s.borderE]}>
                  <Text style={s.mxCell}>{d.nombreEmpleado}</Text>
                </View>
                <View style={[s.w10, s.vCenter, s.borderE]}>
                  <Text style={s.mxCell}>{d.idPension}</Text>
                </View>
                <View style={[s.w20, s.vCenterL, s.borderE]}>
                  <Text style={s.mxCell}>{d.nombreBeneficiario}</Text>
                </View>
                <View style={[s.w10, s.vCenterL, s.borderE]}>
                  <Text style={s.mxCell}>{d.account}</Text>
                </View>
                <View style={[s.w10, s.vCenter, s.borderE]}>
                  <Text style={s.mxCell}>{d.keyBank}</Text>
                </View>
                <View style={[s.w10, s.vCenterL, s.borderE]}>
                  <Text style={s.mxCell}>{d.nameBank}</Text>
                </View>
                <View style={[s.w10, s.vCenterR, s.tend]}>
                  <Text style={s.mxCell}>{fmtMny.format(d.amount)}</Text>
                </View>
              </View>
            ))}
          </View>

          <View fixed style={s.spaceFooter}></View>
          <View fixed style={s.footer}>
            <Text style={s.textFooter}>{fileNo}</Text>
            <Text
              style={s.textFooter}
              render={({ pageNumber, totalPages }) =>
                `PAGINA : ${pageNumber} / ${totalPages}`
              }
            />
          </View>
        </Page>
      </Document>
    ),
  };
}

import { useEffect, useState } from "react";
import Datatable from "../../../components/Datatable/Datatable";
import { doRequestSaveRes } from "../../../helpers/requests";
import { fDateTime } from "../../../libs/tools/format";
import ModalViewFile from "../../../components/Modal/ModalViewFile";

const headers = ["Folio", "Tipo de compra", "Proveedor", "Compra", "Inicio de recepción", "Fin de recepción", "Ver", "Documento de adquisición"];
const columnDefs = [
    {
        targets: [0, 4, 5, 6, 7],
        className: "text-center",
    },
    {
        orderable: false,
        targets: [6, 7],
    }
];

const sAreas = {minWidth: "300px", maxWidth: "350px"}; 

export default function Consulta({idArea, setInfo}){
    const [refresh, setRefresh] = useState(false);
    const [modalFile, setModalFile] = useState(false);
    const [linkFile, setLinkFile] = useState("");
    
    const columns = [
        {data: "folio"},
        {data: "nameTypePurchase"},
        {data: "socialName"},
        {data: "object", render:(d)=><div style={sAreas}>{d}</div>},
        {data: "startDate", format: fDateTime},
        {data: "endDate", format: fDateTime},
        {
            data: null, 
            render: (row)=> <i className="fa fa-eye text-info cursor-pointer" onClick={()=>{setInfo({id: row.idPurchaseRequest, provedor: row.socialName, rfc: row.itin, compra: row.object, idProvider: row.idProvider})}}/>
        },
        {
            data: null, 
            render: (row)=> row.src && <i className="fa fa-file-pdf-o text-danger cursor-pointer" onClick={() => setLinkFile(row.src)}/>
        }
    ]

    async function getData(ordCol, itemsPerPage, currentPage, search, multi) {
        const res = await doRequestSaveRes(
            "receiver/receiver.php",
            getParams(ordCol, itemsPerPage, currentPage, search, multi),
            false,
            false
        );
        if (res.code === "200") return res;
        return null;
    }

    function getParams(ordCol, itemsPerPage, currentPage, search) {
        const params = {
            action: "datatable", /// quotes H ON H.idQuote =  G.idQuote
            table: "purchaserequests A INNER JOIN types_purchases B ON B.keyTypePurchase = A.keyTypePurchase INNER JOIN purchasewinners C on C.idPurchaseRequest = A.idPurchaseRequest INNER JOIN providerquotes D on D.idProviderQuote = C.idProviderQuote INNER JOIN providers E on E.idProvider= D.idProvider INNER JOIN quotes F ON F.idQuote = A.idQuote INNER JOIN receptions G on G.idWinner = C.idWinner  INNER JOIN quotesdetail H on H.idDetail = D.idDetail LEFT JOIN purchasefiles I on I.idPurchaseRequest = A.idPurchaseRequest LEFT JOIN types_purchasedocs J ON J.keyTypePurchaseDoc = I.keyTypePurchaseDoc LEFT JOIN bookcase L ON L.reference = I.idFile LEFT JOIN files M ON M.idBook = L.idBook",
            rows: "CONCAT(A.numberRequest,'/',A.yearRequest) AS folio, B.nameTypePurchase, E.socialName, F.object, MIN(G.startDate) AS startDate, MAX(G.endDate) AS endDate, E.itin, IF(J.keyPurchaseDoc = 9, IF(M.enabled = 1 AND L.keyFileType = 58, M.src, ''), '') AS src, A.idPurchaseRequest,E.idProvider",
            conditions: `A.enabled = 1 AND A.idArea = ${idArea} AND C.enabled = 1 AND D.enabled = 1 AND G.enabled = 1 AND H.enabled = 1 AND H.idService IS NOT NULL AND I.enabled = 1`,
            order: ordCol,
            records: itemsPerPage.toString(),
            page: (itemsPerPage * currentPage).toString(),
            group: "E.idProvider, A.idPurchaseRequest",
            search
        };
        return params;
    }

    /* Modal file */
    useEffect(() => {
        if (linkFile !== "") {
        setModalFile(true);
        }
    }, [linkFile]);

    useEffect(() => {
        if (!modalFile) {
        setLinkFile("");
        }
    }, [modalFile]);

    return(
        <div className="mt-2">
            {
                // permissions.CON &&
                <Datatable
                    headers={headers}
                    columns={columns}
                    columnDefs={columnDefs}
                    petition={getData}
                    order={{ col: 1, opt: "asc" }}
                    control="back"
                    stateRefresh={[refresh, setRefresh]}
                    searching={true}
                    className="px-0 mt-4"
                />
            }
            <ModalViewFile
                modal={modalFile}
                setModal={setModalFile}
                backdrop={true}
                keyboard={true}
                btnClose={true}
                fileLink={linkFile}
            />
        </div>
    )
}
import React, { useState } from "react";
import TableComponent from "../../../components/TableComponent/TableComponentFiltro";


export const Edificios = () => {
  const cabeceras = [
    "Id",
    "Edificio",
    "Calle",
    "Colonia",
    "N.º Exterior",
    "Código postal",
    "Ciudad",
    "Editar",
    "Eliminar",
  ];  
  const [filtro] = useState([true, true, true, true, true, true, true, false, false, false, false, false, false, false]);
  const [parametros] = useState({
    action: "datatable",
    table: "buildings A INNER JOIN cities B ON A.keyCity = B.keyCity INNER JOIN counties C ON B.keyCounty = C.keyCounty INNER JOIN states D ON C.keyState = D.keyState INNER JOIN countries E ON D.keyCountry = E.keyCountry", 
    rows: "A.idBuilding, A.nameBuilding, A.streetName, A.subStreet,A.extNumber, A.zipCode, B.nameCity, A.latitude, A.longitude,A.intNumber,B.keyCity,C.keyCounty,D.keyState,E.keyCountry",///sin espacios y comas
    conditions:"A.enabled = 1 AND B.enabled = 1 AND C.enabled = 1 AND D.enabled = 1 AND E.enabled = 1",
    page:0,
    records:5,
    search:"",
    order:""
  });
  return (
    <div>
        <TableComponent
          titulo="Edificios"
          cabecerasTabla={cabeceras}
          //contenido={edificios}
          filtro={filtro}
          rutaCrear="/template/edificios/crear"
          rutaEditar="/template/edificios/editar"
          parametros={parametros}
        ></TableComponent>
    </div>
  );
};
export default Edificios;

import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Button, Collapse, Row, Col } from "reactstrap";
import { peticionesReceiver } from "../../../helpers/peticionesReceiver";
import ModalTableVer from "../../../components/Modal/ModalTableVer";
import { toast } from "react-toastify";
import Notification from "../../../components/Notification/Notification";
import ModalConfirmation from "../../../components/Modal/ModalConfirmation";
import { CancelOutlined } from "@material-ui/icons";
import s from "./Components/InputClear.module.scss";
import TableWarehouse from "./Components/TableWarehouse";

import Loader from "../../../components/Loader/Loader";

const FormOrdenPago = (props) => {
  const {
    collapse,
    setCollapse,
    setRefreshP,
    data,
    setData,
    permissions,
    idAreaLogged,
  } = props;
  const API = peticionesReceiver();

  const [modalConfirmation, setModalConfirmation] = useState(false);
  const [editando, setEditando] = useState(false);
  const [payOrder, setPayOrder] = useState(null);
  const [loading, setLoading] = useState(true);
  const [refresh1, setRefresh1] = useState(false);
  const [refresh2, setRefresh2] = useState(false);

  useEffect(() => {
    if (data !== "") {
      getForEdit(data);
    } else {
      setLoading(false);
    }
  }, [data]);

  const updateRefresh = () => {
    setRefresh1(true);
    setRefresh2(true);
  };

  const FormSchema = Yup.object().shape({
    nameStaff: Yup.string().required("Seleccione un usuario"),
  });

  const {
    handleSubmit,
    values,
    errors,
    setFieldValue,
    touched,
    handleBlur,
    resetForm,
  } = useFormik({
    initialValues: {
      idStaff: "",
      nameStaff: "",
    },
    onSubmit: () => {
      setModalConfirmation(true);
    },
    validationSchema: FormSchema,
    enableReinitialize: true,
  });

  const onChange = (e) => {
    const { name, value } = e.target;
    setFieldValue([name], value);
  };

  const getForEdit = async (row) => {
    setPayOrder(data);
    setFieldValue("nameStaff", `${data.staffNumber} - ${data.fullName}`);
    setFieldValue("idStaff", data.idStaff);
    setEditando(true);
    setCollapse(true);
    setLoading(false);
  };

  const getPayOrder = async (idOrder) => {
    const data = {
      action: "multiselect",
      table:
        "payorders A LEFT JOIN  status B on (A.keyStat = B.keyStat) INNER JOIN staff C on (A.idStaffApproves = C.idStaff) INNER JOIN jobstaff  E ON (E.idStaff = C.idStaff)  INNER JOIN areas F ON (E.idArea = F.idArea) INNER JOIN jobs G ON (G.keyJob= E.keyJob)",
      rows: "A.idOrder,B.nameStat,A.numberOrder,A.comments,A.dateSend,A.datePaid,A.created,B.nameStat,A.yearOrder,concat(C.name,' ',C.pName,'',C.mName) as fullName,C.idStaff,C.staffNumber,F.name as nameArea,G.nameJob,A.keyStat",
      conditions: `A.enabled = 1 and B.enabled = 1 and A.idOrder = ${idOrder}`,
    };
    
    await API.peticion(data)
      .then((res) => {
        if (res.status === 200 && res.data.code === "200") {
          
            setPayOrder(res.data.data[0]);
          
        } else {
          toast(<Notification type={"consultar_error"} withIcon />);
        }
      })
      .catch((err) => {
        toast(<Notification type={"consultar_servidor_error"} withIcon />);
      });
  };

  const createPayOrder = async () => {
    const data = {
      action: "newPayOrder",
      idStaffApproves: values.idStaff,
    };
    
    const res = await API.peticionEndPoint(
      data,
      "app/facades/warehouse/warehouseF.php"
    );
    
    if (res.status === 200 && res.data.status === "success") {
      toast(
        <Notification
          type={"agrega_exito"}
          backMessage={res.data.message}
          withIcon
        />
      );
      
      getPayOrder(res.data.data[0].idOrder);
      setRefreshP(true);
    } else {
      toast(
        <Notification
          type={"agrega_error"}
          backMessage={res.data.message}
          withIcon
        />
      );
    }
  };

  const [modalTabla, setModalTabla] = useState(false);
  const [loader, setLoader] = useState(true);
  const filtroModalTabla = [true, true, true, true, true];
  const cabecerasModalTabla = [
    "#",
    "Núm. Empleado",
    "Nombre",
    "Unidad de trabajo",
    "Puesto",
    "Seleccionar",
  ];
  const [paramsModalTabla] = useState({
    action: "datatable",
    table:
      "staff STF INNER JOIN employtypes AS EMT ON STF.keyEmployType = EMT.keyEmployType INNER JOIN jobstaff AS JBS ON JBS.idStaff = STF.idStaff INNER JOIN areas AS AR ON AR.idArea = JBS.idArea INNER JOIN jobtypes AS JBT ON JBT.keyJobType = STF.keyEmployType",
    rows: "STF.idStaff,STF.staffNumber,CONCAT(STF.name,' ',STF.pName,' ',STF.mName) as fullName,AR.name,JBT.nameJobType",
    conditions: `STF.enabled = 1 AND JBS.valid = 1 AND STF.keyStat = 1 AND JBS.enabled = 1 and JBS.idArea = ${idAreaLogged}`,
    page: 0,
    records: 5,
    search: "",
    order: "",
  });

  function handleClear() {
    setFieldValue("idStaff", "");
    setFieldValue("nameStaff", "");
  }

  const selectEmpleado = (el) => {
    setFieldValue("idStaff", el[0]);
    setFieldValue("nameStaff", `${el[1]} - ${el[2]}`);
    setModalTabla(false);
  };

  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <>
          <form onSubmit={handleSubmit}>
            <Row className="justify-content-around">
              <Col xs={12} lg={5}>
                <div className="form-group position-relative">
                  <label htmlFor="nameStaff">
                    Empleado Solicitante <span className="text-danger">*</span>
                  </label>
                  <div className="input-group">
                    <input
                      name="nameStaff"
                      onChange={onChange}
                      onBlur={handleBlur}
                      className={
                        "form-control" +
                        (errors.nameStaff && touched.nameStaff
                          ? " is-invalid"
                          : "")
                      }
                      value={values.nameStaff}
                      disabled
                    />
                    <div className="input-group-append">
                      <button
                        className="btn btn-secondary"
                        style={{ zIndex: "auto" }}
                        type="button"
                        onClick={() => setModalTabla(true)}
                        disabled={payOrder && payOrder.idOrder}
                      >
                        <i className="fa fa-search" />
                      </button>
                    </div>
                  </div>
                  {values.nameStaff !== "" && payOrder && !payOrder.idOrder && (
                    <CancelOutlined
                      className={s.btnClear}
                      onClick={handleClear}
                    />
                  )}
                  {touched.nameStaff && errors.nameStaff && (
                    <span style={{ color: "red" }}>{errors.nameStaff}</span>
                  )}
                </div>
              </Col>
            </Row>

            {!payOrder   && (
              <div className="d-flex justify-content-around mt-2 mb-5">
                {permissions.INS && (
                  <div>
                    <Button type="submit" color="success">
                      Agregar
                    </Button>
                  </div>
                )}
              </div>
            )}

            <ModalConfirmation
              modalTitle={editando ? "Editar" : "Crear"}
              modal={modalConfirmation}
              setModal={setModalConfirmation}
              crear={createPayOrder}
              isEdit={false}
              values={values}
            >
              <div className="text-center">
                Esta seguro de continuar con el registro
              </div>
            </ModalConfirmation>
          </form>

          <ModalTableVer
            modal={modalTabla}
            setModal={setModalTabla}
            title="Empleados"
            cabeceras={cabecerasModalTabla}
            filtro={filtroModalTabla}
            parametros={paramsModalTabla}
            selectRegistro={selectEmpleado}
            backdrop={"static"}
            keyboard={false}
            btnClose={true}
            resetForm={resetForm}
          />

          {payOrder &&
            payOrder.idOrder &&
            +payOrder.keyStat !== 149 &&
            +payOrder.keyStat !== 154 && (
              <>
                <TableWarehouse
                  modeAdd={true}
                  order={payOrder}
                  refresh={refresh1}
                  setRefresh={setRefresh1}
                  updateTables={updateRefresh}
                  setCollapse={setCollapse}
                  permissions={permissions}
                />
              </>
            )}

          {payOrder && payOrder.idOrder && (
            <>
              <Row>
                <Col className="text-center mt-5 mb-5">
                  <p className="headline-1">RESUMEN DE ORDEN DE PAGO</p>
                </Col>
              </Row>
              <TableWarehouse
                order={payOrder}
                refresh={refresh2}
                setRefresh={setRefresh2}
                updateTables={updateRefresh}
                setCollapse={setCollapse}
                permissions={permissions}
              />
            </>
          )}
        </>
      )}
    </div>
  );
};

export default FormOrdenPago;

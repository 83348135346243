import React from "react";

export const CheckBoxInput = (props) => {
  const {
    label,
    inputName,
    onChangeMethod,
    onBlurMethod,
    value,
    isRequired,
    touched,
    errors,
    isDisabled
  } = props;

  return (
    <>
      <div className="form-group">
        <label htmlFor={inputName}>
          {label} {isRequired && <span className="text-danger">*</span>}
        </label>
        <input
          name={inputName}
          type="checkbox"
          id={inputName}
          onChange={onChangeMethod}
          onBlur={onBlurMethod}
          className={!isRequired  ? "form-control" : "form-control" + (errors && touched ? " is-invalid" : "")}
          value={value}
          checked={value}
          disabled={isDisabled === true ? 'true' : false}
        />
        {isRequired && touched && errors && <span style={{ color: "red" }}>{errors}</span>}
      </div>
    </>
  );
};

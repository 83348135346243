import { useEffect } from "react";
import Widget from "../../../../components/Widget/Widget";
import format from "date-fns/format";
import { SelectTypeHeadSingle, TextInput } from "../../../../components/GenericInputsFormik";
import { Button, Col, Row } from "reactstrap";
import ModalConfirmation from "../../../../components/Modal/ModalConfirmation";
import { doRequest } from "../../../../helpers/requests";
import FullFormLoader from "../../../../components/Loader/FullFormLoader";
import { useHistory } from "react-router-dom";
import { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup"

const defaultForm = {
    idIncident: "",
    keyTypeIncident: "",
    dateIncident: "",
    keyStat: "",
};

const FormSchema = Yup.object().shape({
    keyTypeIncident: Yup.string().required("Seleccione un tipo de incidencia"),
    dateIncident: Yup.string().required("Seleccione una fecha"),
    keyStat: Yup.string().required("Seleccione un estatus"),
});

const cDate = new Date();
const cYear = cDate.getFullYear();

export default function EditarAutorizarIncidencia() {
    const [loading, setLoading] = useState(true);
    const [modal, setModal] = useState(false);
    const [types, setTypes] = useState([]);
    const [status, setStatus] = useState([]);
    const incident = localStorage.getItem("incident");
    const history = useHistory();

    const {
        handleSubmit,
        values,
        handleBlur,
        errors,
        touched,
        setFieldValue,
        handleReset,
        setValues,
    } = useFormik({
        initialValues: defaultForm,
        validationSchema: FormSchema,
        onSubmit: () => setModal(true),
        enableReinitialize: true,
    });

    useEffect(()=>{
        getTypes();
        getStatus();
        const obj = JSON.parse(incident);
        setValues({
            idIncident: obj.idIncident,
            keyTypeIncident: obj.keyTypeIncident,
            dateIncident: obj.dateIncident,
            keyStat: obj.keyStat,
        });
    }, []);
    
    async function getTypes() {
        const params = {
            action: "select",
            table: "types_incidents",
            order: "nameTypeIncident ASC",
            condition: {enabled: 1}
        }
        const res = await doRequest("receiver/receiver.php", params, false);
        if(res.length > 0) setTypes(res);
        setLoading(false);
    }
    
    async function getStatus() {
        const params = {
            action: "select",
            table: "status",
            order: "nameStat ASC",
            condition: {enabled: 1, keyTypeStat: 31}
        }
        const res = await doRequest("receiver/receiver.php", params, false);
        if(res.length > 0) setStatus(res.filter(el => +el.keyStat !== 127));
    }
    const onChange = ({target}) => {
        setFieldValue(target.name, target.value);
    }

    async function edit(){
        const params = {
            action: "update",
            table: "incidents",
            rows: values,
            condition: {
                idIncident: values.idIncident,
            },
        }

        const res = await doRequest("receiver/receiver.php", params, true);
        setLoading(false);
        if(res.length > 0){
            goBack()
        }
    }

    function goBack(){
        localStorage.removeItem("incident");
        history.goBack();
    }

    return (
        <Widget className="widget-p-md">
            <form onSubmit={handleSubmit}>
                <Row>
                    <Col xs={12} md={6}>
                        <TextInput
                            label="Fecha de incidencia"
                            inputName="dateIncident"
                            inputType="date"
                            onChangeMethod={onChange}
                            onBlurMethod={() => { }}
                            errors={errors.dateIncident}
                            value={values.dateIncident}
                            touched={touched.dateIncident}
                            isMin={format(new Date(cYear - 2), "yyyy-MM-dd")}
                            isMax={format(cDate, "yyyy-MM-dd")}
                            isRequired
                        />
                    </Col>
                    <Col xs={12} md={6}>
                        <SelectTypeHeadSingle
                            label="Tipo de incidencia"
                            errors={errors.keyTypeIncident}
                            value={values.keyTypeIncident}
                            touched={touched.keyTypeIncident}
                            optionsArray={types}
                            inputName="keyTypeIncident"
                            isRequired
                            onBlurMethod={handleBlur}
                            optionName="nameTypeIncident"
                            optionValue="keyTypeIncident"
                            onChangeMethod={onChange}
                        />
                    </Col>
                    <Col xs={12} md={6}>
                        <SelectTypeHeadSingle
                            label="Estatus"
                            errors={errors.keyStat}
                            value={values.keyStat}
                            touched={touched.keyStat}
                            optionsArray={status}
                            inputName="keyStat"
                            isRequired
                            onBlurMethod={handleBlur}
                            optionName="nameStat"
                            optionValue="keyStat"
                            onChangeMethod={onChange}
                        />
                    </Col>
                </Row>
                <div className="my-3 d-flex flex-md-row flex-column-reverse justify-content-md-around">
                    <Button color="danger" type="reset" className="mt-3" onClick={()=>goBack()}>
                        Cancelar
                    </Button>
                    <Button color="success" type="submit" className="mt-2 mt-md-3">
                        Guardar
                    </Button>
                </div>
            </form>
            <ModalConfirmation
                modalTitle={"Registrar"}
                modal={modal}
                crear={edit}
                setModal={setModal}
                values={values}
            >
                <div className="d-flex justify-content-center">
                    <h6>¿Está seguro de editar la incidecia?</h6>
                </div>
            </ModalConfirmation>

            <FullFormLoader show={loading && status.length > 0 && types.length > 0} />
        </Widget>
    )
}
import {
  Document,
  Page,
  StyleSheet,
  Text,
  View,
  Image,
} from "@react-pdf/renderer";
import { fDate, fDateLocal, fDateTime, fMoney } from "../../../../libs/tools/format";

const s = StyleSheet.create({
  Text: { paddingVertical: "4px" },
  body: {
    paddingVertical: 20,
    paddingHorizontal: 20,
  },

  header: {
    fontSize: "10px",
    backgroundColor: "#BEBEBE",
    textAlign: "center",
    paddingVertical: "2px",
    border: "1px solid #5D6D7E",
    marginTop: "5px",
    marginBottom: "2px",
  },

  marginB1: { marginBottom: "1px" },
  textP: { paddingVertical: "3px", paddingHorizontal: "2px" },

  vCenter: { alignItems: "center", justifyContent: "center" },
  vCenterL: { alignItems: "left", justifyContent: "center" },
  vCenterBtw: { alignItems: "stretch", justifyContent: "center" },

  row: { flexDirection: "row" },
  flexWrap: { display: "flex", flexWrap: "wrap", flexDirection: "row" },

  w8: { width: "8%" },
  w10: { width: "10%" },
  w11: { width: "11%" },
  w13: { width: "13%" },
  w14: { width: "14%" },
  w15: { width: "15%" },
  w16: { width: "16%" },
  w20: { width: "20%" },
  w25: { width: "25%" },
  w26: { width: "26%" },
  w29: { width: "29%" },
  w30: { width: "30%" },
  w39: { width: "39%" },
  w40: { width: "40%" },
  w44: { width: "44%" },
  w45: { width: "45%" },
  w50: { width: "50%" },
  w55: { width: "55%" },
  w58: { width: "58%" },
  w60: { width: "60%" },
  w65: { width: "65%" },
  w70: { width: "70%" },
  w80: { width: "80%" },
  w86: { width: "86%" },
  w87: { width: "87%" },
  w90: { width: "90%" },
  wAuto: { width: "auto" },

  border: { border: "1px solid #5D6D7E" },
  borderT: { borderBottom: "1px solid #5D6D7E" },
  borderB: { borderBottom: "1px solid #5D6D7E" },
  borderE: { borderRight: "1px solid #5D6D7E" },
  borderS: { borderLeft: "1px solid #5D6D7E" },
  borderD: { border: "1px solid #5D6D7E", borderTop: "0px" },
  borderU: { border: "1px solid #5D6D7E", borderBottom: "0px" },

  bg: { backgroundColor: "#BEBEBE" },
  bg2: { backgroundColor: "#BEBEBE" },

  fs8: { fontSize: "8px" },
  fs9: { fontSize: "9px" },
  fs10: { fontSize: "10px" },

  tend: { textAlign: "right", paddingRight: "2px" },
  tstart: { textAlign: "left" },
  tcenter: { textAlign: "center" },
  tBold: { fontFamily: "Helvetica-Bold" },
});

export const fumpPdf = (data, urlQr, pre) => {
  const tipoMovimineto = data.nameMove.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toUpperCase().trim();

  const getPuestoAnt = () => {
    return ![
      "BAJA",
      "ALTA",
      "LICENCIA",
      "CIERRE DE PLAZA",
      "COMISION",
      "REMOCION",
      "RENOVACION",
      "REINGRESO POR LICENCIA",
      "SUSPENSION",
      "REINGRESO POR SUSPENSION",
    ].includes(tipoMovimineto);
  };

  const getSustituido = () => {
    return ![
      "BAJA",
      "CAMBIO DE RANGO",
      "CIERRE DE PLAZA",
      "COMISION",
      "LICENCIA",
      "REINGRESO DE INCAPACIDAD",
      "REINGRESO DE LICENCIA", 
      "REINGRESO DE SUSPENSION", 
      "REINGRESO",
      "REMOCION",
      "RENOVACION",
      "SEPARACION DEFINITIVA DEL CARGO",
      "SUSPENSION TEMPORAL",
    ].includes(tipoMovimineto) && 
    !tipoMovimineto.includes("REINCORPORACION") && 
    !tipoMovimineto.includes("REINGRESO") && 
    !tipoMovimineto.includes("SUSPENSION")
  };

  const getSindicato = () => {
    if (+data.unionized === 1) return "SINDICALIZADO";
    return "NO SINDICALIZADO";
  };

  const getTipoImuesto = () => {
    if (+data.taxes > 0) return "SI PAGA";
    return "NO PAGA";
  };

  const getSistCap = () => {
    if (+data.capitalization === 1) return "SI";
    return "NO";
  };

  const getNameMove = () => {
    if(data.nameMove === "CIERRE DE PLAZA") return "BAJA";
    return data.nameMove;
  }

  function licenciasType(){
    const isArray = JSON.stringify(data.licencia).includes("[");
    if(isArray) return data.licencia.length > 0;
    return !!data.licencia.nameTypeLeave;
  }

  function isNotProDem(){
    return !["PROMOCION", "DEMOCION"].includes(tipoMovimineto)
  }

  return (
    <Document>
      <Page style={s.body}>
        <LocalHeader />

        <View style={[s.header]}>
          <Text>FORMATO ÚNICO DE MOVIMIENTO DE PERSONAL</Text>
        </View>

        {InsertMovPlazaInformation(data, pre)}

        <View style={[s.fs8]}>
          {InsertStaffInformation(data)}

          <View style={[s.row, s.border]}>
            <View style={[s.w14, s.bg, s.tcenter, s.borderE, s.vCenter]}>
              <Text style={[s.textP]}>MOVIMIENTO</Text>
            </View>
            <View style={s.w86}>
              <Text style={[s.textP, s.tcenter]}>{getNameMove()}</Text>
            </View>
          </View>

          {getPuestoAnt() && <Plaza data={data} plaza="Before" />}
          <Plaza data={data} plaza="" />

          {getSustituido() && (
            <View style={[s.row, s.border]} wrap={false}>
              <View style={[s.w14, s.bg, s.tcenter, s.borderE, s.vCenter]}>
                <Text style={[{ paddingVertical: "2px" }]}>
                  {"DATOS DEL\nSUSTITUIDO"}
                </Text>
              </View>
              <View style={[s.w86, s.vCenterL]}>
                <Text style={[s.textP]}>
                  { 
                    !!data.nombreSustituido ? 
                    (data.nombreSustituido +(isNotProDem() ? ` - ${fDate(data.startDateSustituido)} - ${data.nameMoveSustituido}` : ""))
                    : "PLAZA VACANTE"
                  }
                </Text>
              </View>
            </View>
          )}

          <View style={[s.row, s.border]} wrap={false}>
            <View style={[s.w14, s.bg, s.tcenter, s.borderE, s.vCenter]}>
              <Text style={[s.textP]}>
                {"DATOS\nLABORALES DEL\nSERVIDOR\nPÚBLICO"}
              </Text>
            </View>
            <View style={[s.w86]}>
              <View style={[s.row, s.borderB]}>
                <View style={[s.w55, s.borderE]}>
                  <Text style={[s.textP]}>HORARIO: {data.nameJobSchedule}</Text>
                </View>
                <View style={s.w45}>
                  <Text style={[s.textP]}>
                    CLAVE DEL ISSEMYM: {data.socialSecurityKey}
                  </Text>
                </View>
              </View>

              <View style={[s.row, s.borderB]}>
                <View style={[s.w55, s.borderE]}>
                  <Text style={[s.textP]}>
                    TIPO DE RELACIÓN LABORAL: {data.nameAssignment}
                  </Text>
                </View>
                <View style={s.w45}>
                  <Text style={[s.textP]}>
                    TIPO DE APORTACIÓN:{" "}
                    {`${data.tipoAportacion} ${data.tipoAportacionSCI}`}
                  </Text>
                </View>
              </View>

              <View style={[s.row]}>
                <View style={[s.w55, s.borderE, s.vCenterL]}>
                  <Text style={[s.textP]}>
                    TIPO DE SINDICATO: {getSindicato()}
                  </Text>
                </View>
                <View style={s.w45}>
                  <View style={s.borderB}>
                    <Text style={[s.textP]}>
                      TIPO DE IMPUESTO: {getTipoImuesto()}
                    </Text>
                  </View>
                  <View>
                    <Text style={[s.textP]}>
                      SISTEMA CAPITALIZACIÓN: {getSistCap()}
                    </Text>
                  </View>
                </View>
              </View>
            </View>
          </View>

          {["BAJA", "CIERRE DE PLAZA"].includes(data.nameMove) && (
            <View style={[s.row, s.border]} wrap={false}>
              <View style={[s.w14, s.bg, s.tcenter, s.borderE, s.vCenter]}>
                <Text style={[s.textP]}>{"DATOS DE LA\nBAJA"}</Text>
              </View>
              <View style={s.w86}>
                <View style={[s.row]}>
                  <View
                    style={[s.w55, s.borderE, { height: "28px" }, s.vCenterL]}
                  >
                    <Text style={[s.textP]}>
                      FECHA DE BAJA: {fDate(data.firedDate)}
                    </Text>
                  </View>
                  <View style={[s.w45, s.vCenterL]}>
                    <Text style={[s.textP]}>
                      MOTIVO: {pre ? data.motivoBaja : data.statusStaff}
                    </Text>
                  </View>
                </View>
              </View>
            </View>
          )}

          {data.nameMove === "LICENCIA" && (
            <View style={[s.row, s.border]} wrap={false}>
              <View style={[s.w14, s.bg, s.tcenter, s.borderE, s.vCenter]}>
                <Text style={[s.textP]}>LICENCIA</Text>
              </View>
              {
                licenciasType() ?                 
                <View style={s.w86}>
                  <View style={[s.row, s.borderB]}>
                    <View style={[s.w55, s.borderE]}>
                      <Text style={[s.textP]}>
                        TIPO DE LICENCIA: {data.licencia.nameTypeLeave}
                      </Text>
                    </View>
                    <View style={s.w45}>
                      <Text style={[s.textP]}>
                        VIGENCIA DEL: {fDate(data.licencia.startDate)} AL{" "}
                        {fDate(data.licencia.endDate)}
                      </Text>
                    </View>
                  </View>
                  <View style={s.row}>
                    <Text style={[s.textP]}>
                      APLICACIÓN: {data.licencia.nameTypeApplicationSalary}
                    </Text>
                  </View>
              </View> :
              <View style={s.w86}>
                <Text style={[s.textP]}>NO SE ENCOTRARON LICENCIAS ACTIVAS</Text>
              </View>
              }
            </View>
          )}

          <View style={[s.row, s.border]} wrap={false}>
            <View style={[s.w14, s.bg, s.tcenter, s.borderE, s.vCenter]}>
              <Text style={[s.textP]}>FIRMAS</Text>
            </View>
            <View style={[s.w86]}>
              <View style={[s.row, { height: "100px" }]}>
                <View style={[s.w50, s.borderE, s.vCenter]}>
                  <Text style={{ position: "absolute", top: "0" }}></Text>
                  <Text
                    style={{
                      position: "absolute",
                      bottom: "0",
                      textAlign: "center",
                    }}
                  >
                    DIRECTORA DE ADMINISTRACIÓN DE PERSONAL Y NÓMINA
                  </Text>
                </View>
                <View style={[s.w50, s.vCenter]}>
                  <Text style={{ position: "absolute", top: "0" }}></Text>
                  <Text style={{ position: "absolute", bottom: "0" }}>
                    DIRECTOR GENERAL DE ADMINISTRACIÓN
                  </Text>
                </View>
              </View>
            </View>
          </View>
        </View>        
      </Page>
      {urlQr && InsertQr(urlQr)}
    </Document>
  );
};

export default fumpPdf;

function Plaza({ data, plaza }) {
  return (
    <View>
      <InfoPlaza data={data} plaza={plaza} />
      <Conceptos data={data} plaza={plaza} type={true} />
      <Conceptos data={data} plaza={plaza} type={false} />
    </View>
  );
}

function InfoPlaza({ data, plaza }) {
  function getVigenciaLabel() {
    const datess = `${fDate(data[`startDate${plaza}`])} AL ${fDate(data[`endDate${plaza}`])}`;
    const typeMove = data[`nameMove${plaza}`]||"";
    if(typeMove.includes("REINCORPORACIÓN")) return`FECHA DE INICIO DE REINCORPORACIÓN: ${datess}`;
    else if (typeMove.includes("SUSPENSI")) return `FECHA DE INICIO DE LA SUSPENSION: ${datess}`;
    else return `VIGENCIA DEL: ${datess}`;
  }

  return (
    <View style={[s.row, s.border]} wrap={false}>
      <View style={[s.w14, s.bg, s.tcenter, s.borderE, s.vCenter]}>
        <Text style={[s.textP]}>
          {"DATOS DE LA\nPLAZA" + (plaza ? "\nANTERIOR" : "")}
        </Text>
      </View>

      <View style={s.w86}>
        <View style={[s.row, s.borderB]}>
          <View style={[s.w40, s.borderE]}>
            <Text style={[s.textP]}>
              NÚMERO DE PLAZA: {data[`numberJobPositions${plaza}`]}
            </Text>
          </View>
          <View style={s.w60}>
            <Text style={[s.textP]}>
              TIPO DE PUESTO: {data[`nameJobType${plaza}`]}
            </Text>
          </View>
        </View>

        <View style={[s.row, s.borderB]}>
          <View style={[s.w40, s.borderE]}>
            <Text style={[s.textP]}>
              CÓDIGO DEL PUESTO: {data[`idPuesto${plaza}`]}
            </Text>
          </View>
          <View style={s.w60}>
            <Text style={[s.textP]}>
              PUESTO FUNCIONAL: {data[`nameJob${plaza}`]}
            </Text>
          </View>
        </View>

        <View style={[s.row]}>
          <View style={[s.w60]}>
            <Text style={[s.textP]}>
              {/* FECHAS VIGENCIA */}
              {getVigenciaLabel()}
            </Text>
          </View>
          <View style={[s.w40, s.borderS]}>
            <Text style={[s.textP]}>CCT: {data[`nameCCT${plaza}`]}</Text>
          </View>
        </View>
      </View>
    </View>
  );
}

function Conceptos({ data, plaza, type }) {
  let conceptos = [];

  function mapConcepos() {
    const cpyConc = type ? percepciones : deducciones;
    cpyConc.forEach((c) => {
      if (+data[`${c.key}${plaza}`] > 0) {
        conceptos.push({ ...c, amount: fMoney(+data[`${c.key}${plaza}`]) });
      }
    });
  }
  mapConcepos();

  return (
    <View style={[s.row, s.border]} wrap={false}>
      <View style={[s.w14, s.bg, s.tcenter, s.vCenter, s.borderE]}>
        <Text style={[]}>{type ? "PERCEPCIONES" : "DEDUCCIONES"}</Text>
      </View>
      <View style={s.w86}>
        <View style={[s.row, s.bg2, s.borderB, s.tcenter]}>
          <View style={[s.w29, s.borderE]}>
            <Text style={[s.textP]}>CONCEPTO</Text>
          </View>
          <View style={[s.w8, s.borderE]}>
            <Text style={[s.textP]}>CLAVE</Text>
          </View>
          <View style={[s.w13, s.borderE]}>
            <Text style={[s.textP]}>IMPORTE</Text>
          </View>
          <View style={[s.w29, s.borderE]}>
            <Text style={[s.textP]}>CONCEPTO</Text>
          </View>
          <View style={[s.w8, s.borderE]}>
            <Text style={[s.textP]}>CLAVE</Text>
          </View>
          <View style={[s.w13]}>
            <Text style={[s.textP]}>IMPORTE</Text>
          </View>
        </View>
        <View style={s.flexWrap}>
          {conceptos.map((c, ix) => (
            <View style={[s.w50, s.row]} key={"rc" + c.clave}>
              <View style={[s.w58, s.borderE]}>
                <Text style={[s.textP]}>{c.concepto}</Text>
              </View>
              <View style={[s.w16, s.borderE, s.tcenter]}>
                <Text style={[s.textP]}>{c.clave}</Text>
              </View>
              <View style={[s.w26, s.tend, ix % 2 !== 1 ? s.borderE : {}]}>
                <Text style={[s.textP]}>{c.amount}</Text>
              </View>
            </View>
          ))}
          {conceptos.length % 2 !== 0 && (
            <View style={[s.w50, s.row]}>
              <View style={[s.w58, s.borderE]}></View>
              <View style={[s.w16, s.borderE, s.tcenter]}></View>
              <View style={[s.w26, s.tend]}></View>
            </View>
          )}
        </View>
      </View>
    </View>
  );
}

function InsertQr(urlQr) {
  return (
    <Page style={s.body}>
      <View style={[{ flexDirection: "row", marginTop: 30 }]} wrap={false}>
        <View
          style={[
            {
              width: "40%",
              height: "200",
              borderWidth: "2",
              flexDirection: "column",
              justifyContent: "space-around",
              margin: "auto",
            },
          ]}
        >
          <Image
            src={urlQr}
            style={{ /* width: "100%", height: "100%", */ padding: "5" }}
          />

          <Text
            style={{
              fontSize: "11",
              textAlign: "center",
              padding: "5",
              marginBottom: "5",
            }}
          >
            Válida este documento escaneando el siguiente código QR
          </Text>
        </View>
      </View>
    </Page>
  );
}

function InsertMovPlazaInformation(data, pre) {
  const getPeriodAplica = () => {
    if (data.lapseFUMP) return data.lapseFUMP;
    else if (pre) {
      // let sd = data.startDate.split("-");
      // if(["CIERRE DE PLAZA"].includes(data.nameMove)) sd = data.firedDate.split("-");
      // let day = +sd[2];
      // let month = +sd[1];
      const today = new Date();
      let day = today.getDate();
      let month = today.getMonth() + 1;
      const numeroQuincena = (month - 1) * 2 + +(day > 15) + 1;
      // return numeroQuincena+"/"+sd[0];
      return numeroQuincena+"/"+today.getFullYear();

    } else {
      return data.periodoAplica;
    }
  };

  return (
    <View>
      <View style={[s.row, s.fs8, s.border, s.tcenter, s.marginB1]}>
        <View style={[s.w20, s.bg, s.borderE]}>
          <Text style={s.textP}>CLAVE DE EMPLEADO</Text>
        </View>
        <View style={[s.w15, s.borderE]}>
          <Text style={s.textP}>{data.staffNumber}</Text>
        </View>
        <View style={[s.w20, s.bg, s.borderE]}>
          <Text style={s.textP}>FECHA DE CAPTURA</Text>
        </View>
        <View style={[s.w15, s.borderE]}>
          <Text style={s.textP}>
            { pre ? fDateLocal(new Date()) : fDateTime(data.fechaCaptura) }
          </Text>
        </View>
        <View style={[s.w20, s.bg, s.borderE]}>
          <Text style={s.textP}>PERIODO APLICACIÓN</Text>
        </View>
        <View style={[s.w10]}>
          <Text style={s.textP}>{getPeriodAplica()}</Text>
        </View>
      </View>
    </View>
  );
}

function InsertStaffInformation(data) {
  return (
    <View>
      <View style={[s.row, s.border]}>
        <View style={[s.w14, s.borderE, s.tcenter, s.bg, s.vCenter]}>
          <Text style={[s.textP]}>UBICACIÓN</Text>
        </View>
        <View style={[s.w86]}>
          <Text style={s.textP}>UNIDAD ADMINISTRATIVA: {data.name}</Text>
        </View>
      </View>

      <View style={[s.row, s.border]} wrap={false}>
        <View style={[s.w14, s.bg, s.tcenter, s.borderE, s.vCenter]}>
          <Text style={[s.textP]}>{"DATOS\nGENERALES"}</Text>
        </View>

        <View style={[s.w86]}>
          <View style={[s.row, s.borderB]}>
            <View style={[s.w55, s.borderE]}>
              <Text style={[s.textP]}>NOMBRE: {data.nombre}</Text>
            </View>
            <View style={s.w45}>
              <Text style={[s.textP]}>R.F.C.: {data.rfc}</Text>
            </View>
          </View>
          <View style={[s.row, s.borderB]}>
            <View style={[s.w55, s.borderE]}>
              <Text style={[s.textP]}>C.U.R.P.: {data.curp}</Text>
            </View>
            <View style={s.w45}>
              <Text style={[s.textP]}>
                {" "}
                ESTADO CIVIL: {data.nameTypeMarital}{" "}
              </Text>
            </View>
          </View>
          {/* <View style={[s.bg, s.tcenter, s.borderB]}>
                        <Text style={[s.textP]}>DOMICILIO</Text>
                    </View> */}
          <View style={[s.row, s.borderB]}>
            <View style={[s.w55, s.borderE]}>
              <View style={s.row}>
                <View style={s.w50}>
                  <Text style={[s.textP]}>
                    NÚMERO EXTERIOR: {data.extNumber}
                  </Text>
                </View>
                <View style={s.w50}>
                  <Text style={[s.textP]}>
                    NÚMERO INTERIOR: {data.intNumber}
                  </Text>
                </View>
              </View>
            </View>
            <View style={s.w45}>
              <Text style={[s.textP]}>COLONIA: {data.subStreet}</Text>
            </View>
          </View>
          <View style={[s.row, s.borderB]}>
            <View style={[s.w55, s.borderE]}>
              <Text style={[s.textP]}>MUNICIPIO: {data.nameCity}</Text>
            </View>
            <View style={s.w45}>
              <Text style={[s.textP]}>C.P.: {data.zipCode}</Text>
            </View>
          </View>
          <View style={[s.row, s.borderB]}>
            <View style={[s.w55, s.borderE]}>
              <Text style={[s.textP]}>
                LUGAR DE NACIMIENTO: 
                {/*{data.nameCountyBorn} {data.nameCityBorn},  {data.nameStateBorn}, {data.nameCountryBorn} */}
              </Text>
            </View>
            <View style={s.w45}>
              <Text style={[s.textP]}>CALLE: {data.street}</Text>
            </View>
          </View>
          <View style={[s.row]}>
            <Text style={[s.textP]}>ESCOLARIDAD: {data.escolaridad}</Text>
          </View>
        </View>
      </View>
    </View>
  );
}

const percepciones = [
  { key: "base", clave: "0102", concepto: "SUELDO BASE" },
  { key: "reward", clave: "0105", concepto: "GRATIFICACION BUROCRATA" },
  { key: "larder", clave: "0512", concepto: "DESPENSA" },
  { key: "viaticos", clave: "0311", concepto: "VIATICOS" },
  { key: "clearance", clave: "0104", concepto: "COMPENSACION" },
  {
    key: "fortalecimiento",
    clave: "0137",
    concepto: "FORTALECIMIENTO AL SALARIO",
  },
];

const deducciones = [
  { key: "taxes", clave: "5408", concepto: "IMPUESTO SOBRE LA RENTA" },
  {
    key: "fondo",
    clave: "5541",
    concepto: "CUOTA DEL FONDO\nSOLIDARIO DE REPARTO",
  },
  { key: "salud", clave: "5540", concepto: "CUOTA DE SERVICIO DE SALUD" },
  { key: "sci", clave: "5542", concepto: "CUOTA OBLIGATORIA S C I" },
];

function LocalHeader({}) {
  return (
    <View style={[s.row, {justifyContent:"space-between"}]}>
      <Image style={sh.h} src={`${origin}/images/pdf/gobEDOMEX.png`} />
      {/* <View style={[sh.max, sh.mxauto]}>
                <Text style={[sh.title, sh.mb5, sh.mt50, sh.textBold]}>
                Sistema de Administración
                </Text>
                <Text style={[sh.title, sh.textBold]}>
                de Personal y Presupuesto
                </Text>
            </View> */}
      <Image style={[sh.h]} src={`${origin}/images/logo.png`} />
    </View>
  );
}
const sh = StyleSheet.create({
  h: { height: "70px" },
  mxauto: { marginHorizontal: "auto" },
  max: { width: "70%" },
  mb5: { marginBottom: 5 },
  mt50: { marginTop: 30 },
  textBold: { fontFamily: "Helvetica-Bold" },
  title: {
    width: "100%",
    textAlign: "center",
    marginBottom: 10,
    fontSize: 16,
  },
});

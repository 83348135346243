import React, { useState, useEffect } from "react";
import Loader from "../Loader/Loader";
import { Table } from "reactstrap";
import s from "../../pages/tables/Tables.module.scss";
import sc from "./TableComponentFiltro.module.scss";

/**
 * @param {Object} props
 * @param {Array<string>} props.headers - Arreglo con las cabeceras de la tabla
 * @param {Array<boolean>} props.registros - Arreglo con datos
 * @param {Function} props.handleDelete - Funcion para ejecutar en el boton eliminar
 * @param {Object} props.permissions - Objeto con los permisos del usuario sobre el modulo
 */
const TableComponentDeleteRecord = (props) => {
  const { headers, registros, handleDelete, permissions } = props;
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(false);
  }, [registros]);

  const registers = (item) => {
    return Object.values(item).map((it, ix) => (
      <td className="text-center" key={"ok"+ix}>{it}</td>
    ));
  };

  return (
    <div className="widget-table-overflow mt-3">
      <Table
        className={`table-striped table-borderless table-hover ${s.statesTable}`}
        responsive
      >
        <thead className="border-bottom border-secondary">
          <tr className={sc.userSelectNone}>
            {headers.map(
              (header, ix) =>
                (header.toLowerCase() !== "eliminar" ||
                  (header.toLowerCase() === "eliminar" && permissions.DEL)) && (
                  <th className="text-center" key={"rhh"+ix}>{header}</th>
                )
            )}
          </tr>
        </thead>
        <tbody>
          {loading ? (
            <tr key={"loading"}>
              <td colSpan={4}>
                <Loader />
              </td>
            </tr>
          ) : (
            <>
              {registros.map((item, ix) => (
                <tr key={"rhd"+ix}>
                  {registers(item)}
                  {permissions.DEL && <td
                    className="text-center"
                    onClick={() => handleDelete(ix)}
                  >
                    <i className="fa fa-trash text-danger" />
                  </td>}
                </tr>
              ))}
            </>
          )}
        </tbody>
      </Table>
    </div>
  );
};

export default TableComponentDeleteRecord;

import Notification from "../../../../components/Notification/Notification";
import { toast } from "react-toastify";
import { peticionesReceiver } from "../../../../helpers/peticionesReceiver";
const API = peticionesReceiver();

export const createContratoHonorario = async (leaveN, setParametros, idEmployee, limpia, genReport) => {
    delete leaveN.idContractFees
    delete leaveN.numberContract
    if (leaveN) {
      const params = {
        action: "saveContractFees",
        table: "contractFees",
        rows: leaveN,
      }
      API.peticionEndPoint(params, "app/facades/jobs/jobsF.php").then((res) => { 
          if (res.status === 200 && res.data.status === "success") {
            toast(<Notification type={"agrega_exito"} backMessage={res.data.message} withIcon />);
            setParametros({
              action: "datatable",
              table: "contractFees CF INNER JOIN types_move TM on CF.keyMove=TM.keyMove INNER JOIN areas A on CF.idArea=A.idArea INNER JOIN jobs J on CF.keyJob=J.keyJob INNER JOIN staff SF ON CF.idStaff=SF.idStaff",
              rows: "CF.idContractFees,J.nameJob,A.name,TM.nameMove,CF.valid,CF.startDate,CF.endDate",
              conditions: `CF.enabled = 1 AND SF.idStaff = ${idEmployee}`,
              page: 0,
              records: 5,
              search: "",
              order: "",
            })
            limpia();
            const re = Object.values(res.data.data);
            genReport(re)
          }else{
            toast(<Notification type={"agrega_error"} backMessage={res.data.message} withIcon />);
          }
        }).catch((err) =>{
        toast(<Notification type={"consultar_servidor_error"} withIcon />);
      });
    }
  };

  export const updateContratoHonorario = async (leaveU, setParametros, idEmployee, limpia, noEmp) => {
    if (leaveU) {
        const params = {
            action: "saveContractFees",
            table: "contractFees",
            rows: leaveU,
            condition: {
                idContractFees: leaveU.idContractFees,
            },
        }
      API.peticionEndPoint(params, "app/facades/jobs/jobsF.php").then((res) => { 
            if (res.status === 200 && res.data.status === "success") {
              toast(<Notification type={"modifica_exito"} withIcon />);
              if (noEmp) {
                setParametros({
                  action: "datatable",
                  table: "contractFees CF INNER JOIN types_move TM on CF.keyMove=TM.keyMove INNER JOIN areas A on CF.idArea=A.idArea INNER JOIN jobs J on CF.keyJob=J.keyJob INNER JOIN staff SF ON CF.idStaff=SF.idStaff",
                  rows: "CF.idContractFees,J.nameJob,A.name,TM.nameMove,CF.valid,CF.startDate,CF.endDate",
                  conditions: `CF.enabled = 1`,
                  page: 0,
                  records: 5,
                  search: "",
                  order: "",
                });
              } else {
                setParametros({
                  action: "datatable",
                  table: "contractFees CF INNER JOIN types_move TM on CF.keyMove=TM.keyMove INNER JOIN areas A on CF.idArea=A.idArea INNER JOIN jobs J on CF.keyJob=J.keyJob INNER JOIN staff SF ON CF.idStaff=SF.idStaff",
                  rows: "CF.idContractFees,J.nameJob,A.name,TM.nameMove,CF.valid,CF.startDate,CF.endDate",
                  conditions: `CF.enabled = 1 AND SF.idStaff = ${idEmployee}`,
                  page: 0,
                  records: 5,
                  search: "",
                  order: "",
                });
              }
              limpia()
            }else{
                toast(<Notification type={"modifica_error"} backMessage={res.data.message} withIcon />);
            }
            }).catch((err) =>{
            toast(<Notification type={"consultar_servidor_error"} withIcon />);
        });
    }
  };
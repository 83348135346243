import * as XlsxPopulate from "xlsx-populate";
import { saveAs } from "file-saver";
import { months } from "../filtros/Inputs/SelectMonths";

/**
 * Formateador de numeros de concurrencias
 */
const formatter = new Intl.NumberFormat("es-MX", {
  style: "currency",
  currency: "MXN",
});

const formatterNums = new Intl.NumberFormat('es-MX');

/**
 * Hoja de estilos del excel
 */
const styles = {
  title: {
    horizontalAlignment: 'center',
    bold: true
  },
  headerBorder: {
    bold: "true",
    border: true,
    fill: "BFBFBF",
  },
  header: {
    bold: "true",
    border: true,
    fill: "BFBFBF",
  },
  headerBoderBold: {
    bold: "true",
    border: true,
  },
  contentBorder: {
    border: true,
  },
  contentBorderEnd: {
    border: true,
    horizontalAlignment: "right"
  }
};

/**
 * 
 * @param {*} number numero del mes
 * @returns 
 */
export function numberToMonth(number) {
  return months.find(month => month.value == number).label.toUpperCase();
}

/**
 * 
 * @param {Reporte entregado de la BD} report 
 * @param {Titulo (Nombre de la institución)} title 
 * @param {Parametros pasados a la BD para su consulta} data 
 * @param {configuración de headers} config 
 */
export async function saveReport32AsExcel(report, title, data, config) {

  //Inicio de excel
  XlsxPopulate.fromBlankAsync().then(async (workbook) => {
    const sheet1 = workbook.sheet(0);

    //Titulo
    sheet1
        .range("A1:C1")
        .merged(true)
        .value("SAPP")
        .style(styles.title);
    //Subtitulos
    sheet1
        .range("A2:C2")
        .merged(true)
        .value(title)
        .style(styles.title);
    sheet1
      .range("A3:C3")
      .merged(true)
      .value(`DE ${numberToMonth(data.month)} DE ${data.year}`)
      .style(styles.title);
    //Cabeceras
    sheet1
      .cell("A4")
      .value(config.headers[0])
      .style(styles.headerBoderBold)
    
    sheet1
      .cell("B4")
      .value(config.headers[1])
      .style(styles.headerBoderBold)
    
    sheet1
      .cell("C4")
      .value(config.headers[2])
      .style(styles.headerBoderBold)
    
    addData(report, config, sheet1)
    
    sheet1.column("A").width(50);
    sheet1.column("B").width(30)
    sheet1.column("C").width(20)
    
    return workbook.outputAsync().then((res) => {
        saveAs(res, `${title}.xlsx`);
    });
  });
}

/**
 * 
 * @param {Tabla principal} fTable 
 * @param {Tabla secundaria} sTable 
 * @param {Configuración del excel} config 
 * @param {Hoja de excel} sheet1 
 */
function addData(table, config, sheet1) {
  const lengthTable = table.length;
  if (lengthTable > 4000) { //EN CASO DE SOBREPASAR LOS 4000 registros
    //PRIMERA TABLA SIN ESTILOS
    let FTImporte = 0;
    table.forEach((value, index) => {
      sheet1
        .cell(`A${index + 5}`)
        .value(value[config.content[0].value])
      sheet1
        .cell(`B${index + 5}`)
        .value(formatterNums.format(value[config.content[1].value]))
      const importe = value[config.content[2].value];
      sheet1
        .cell(`C${index + 5}`)
        .value(importe)
      const NImp = importe.replace("$", "").replace(/,/g, "");
      FTImporte += Number(NImp);
    })
    sheet1
      .cell(`B${lengthTable + 5}`)
      .value("TOTAL")
    sheet1
      .cell(`C${lengthTable + 5}`)
      .value(formatter.format(FTImporte))
  } else { //SI NO SOBREPASA LOS 4000 registros
    //PRIMERA TABLA CON ESTILOS
    let FTImporte = 0;
    table.forEach((value, index) => {
      sheet1
        .cell(`A${index + 5}`)
        .value(value[config.content[0].value])
        .style(styles.contentBorder)
      sheet1
        .cell(`B${index + 5}`)
        .value(formatterNums.format(value[config.content[1].value]))
        .style(styles.contentBorderEnd)
      const importe = value[config.content[2].value];
      sheet1
        .cell(`C${index + 5}`)
        .value(importe)
        .style(styles.contentBorderEnd)
      const NImp = importe.replace("$", "").replace(/,/g, "");
      FTImporte += Number(NImp);
    })
    sheet1
      .cell(`B${lengthTable + 5}`)
      .value("TOTAL")
      .style(styles.contentBorderEnd)
    sheet1
      .cell(`C${lengthTable + 5}`)
      .value(formatter.format(FTImporte))
      .style(styles.contentBorderEnd)
  }
}
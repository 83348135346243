import { toast } from "react-toastify";

import Notification from "../../../components/Notification/Notification";
import Datatable from "../../../components/Datatable/Datatable";
import Widget from "../../../components/Widget/Widget";
import { peticionesReceiver } from "../../../helpers/peticionesReceiver";
import { Button } from "reactstrap";
import EditarJustificacionSolicitud from "./Forms/Editar/EditarJustificacion";
import { useState } from "react";
import ModalComponent from "../../../components/Modal/ModalDelete";
import { GetPermisos } from "../../../libs/permissions/hook"
import ModalViewFile from "../../../components/Modal/ModalViewFile";

export default function SolicitudCotizacion() {
    const [refresh, setRefresh] = useState(false)
    const [permisos, setPermisos] = useState("")
    const [linkFile, setlinkFile] = useState();
    const [modalFile, setModalFile] = useState(false);
    GetPermisos(setPermisos);

    const API = peticionesReceiver()

    const headers = [
        "Folio",
        "Estatus",
        "Justificación para la adquisición del bien o contratación del servicio",
        "Solicitante",
        "Fecha de registro",
        "Acciones"
    ];

    const columns = [
        {
            data: null,
            render: function (row) {
                return row.numberQuote && row.yearQuote ? `${row.numberQuote}/${row.yearQuote}` : ""
            },
            orderValue: "CONCAT(numberQuote,'/',yearQuote)"
        },
        { data: "nameStat" },
        {
            data: null,
            render: function (row) {
                return (
                    <div>
                        <div>{row.object}</div>
                        {(row.keyStat === "101" || row.keyStat === "104") && permisos.UPD === "1" && (
                            <div className="d-flex justify-content-center">
                                <EditarJustificacionSolicitud id={row.idQuote} object={row.object} setRefresh={setRefresh} />
                            </div>
                        )}
                    </div>
                )
            }
        },
        { data: "nameStaff" },
        { data: "dateSend" },
        {
            data: null,
            render: function (row) {
                const typeFile = row.keyStat === "102" || row.keyStat === "103" || row.keyStat === "104" ? "42" : "45"
                return (
                    <>
                        {(row.keyStat === "101" || row.keyStat === "104") && permisos.UPD === "1" && (
                            <Button color="warning" size="sm" className="d-flex align-items-center text-nowrap py-1 px-2 mb-2 w-100" onClick={() => EditarSolicitud(row.idQuote)}>
                                <i className="fa fa-pencil mr-2" />Editar solicitud
                            </Button>
                        )}
                        
                        {row.keyStat !== "101" && permisos.CON === "1" && (
                            <Button color="info" size="sm" className="d-flex align-items-center text-nowrap py-1 px-2 mb-2 w-100" onClick={() => getFiles(row.idQuote, typeFile)}>
                                <i className="fa fa-file-pdf-o mr-2" />Solicitud
                            </Button>
                        )}
                        {row.keyStat === "101" && permisos.DEL === "1" && (
                            <ModalComponent
                                modalTitle="Eliminar"
                                id={row.idQuote}
                                table={["quotes"]}
                                nameColumn={["idQuote"]}
                                elimina={() => OnDelete(row.idQuote)}
                                button={true}
                                specialIco={true}
                            >
                                <h6>
                                    ¿Seguro de eliminar el registro?
                                </h6>
                                No podrá recuperar la información después de ser eliminada
                            </ModalComponent>
                        )}
                    </>
                )
            }
        }
    ]

    function EditarSolicitud(row) {
        localStorage.setItem("cotedit", row);
        window.location.href = "#/template/cotizacion/editar"
    }

    async function OnDelete(row) {
        const API = peticionesReceiver();
        const params = {
            action: "delete",
            table: "quotes",
            condition: {
                idQuote: row
            },
        };
        try {
            const res = await API.peticion(params)
            if (res.status === 200 && res.data.code === "200") {
                toast(<Notification type={"elimina_exito"} backMessage={res.data.message} withIcon />);
                setRefresh(true)
            } else {
                toast(<Notification type={"elimina_error"} backMessage={res.data.message} withIcon />);
            }
        } catch (err) {
            toast(<Notification type={"elimina_error"} withIcon />);
        }
    }

    const columnDefs = [
        { targets: [5], className: "text-center", orderable: false }
    ]

    const getParams = (order, records, page, search) => {
        const params = {
            action: "datatable",
            table: "quotes q inner join status s on (q.keyStat = s.keyStat) left join staff st on (q.idStaffRequire = st.idStaff)",
            rows: "q.numberQuote,q.yearQuote,s.keyStat,s.nameStat,q.object,CONCAT(st.name,' ',st.pName,' ',st.mName) as nameStaff,q.dateSend,q.idQuote",
            conditions: "q.enabled=1",
            order,
            records: records.toString(),
            page: (page * records).toString(),
            search
        }
        return params
    }

    async function getData(order, records, page, search) {
        try {
            const res = await API.peticion(getParams(order, records, page, search));
            if (res.status === 200 && res.data.code === "200") {
                return res.data;
            } else {
                // toast(<Notification type={"consultar_error"} backMessage={res.data.message} />);
                return null;
            }
        } catch (err) {
            toast(<Notification type={"consultar_servidor_error"} withIcon />);
            return null
        }
    }

    const getFiles = async (idCot, typeFile) => {
        const paramFiles = {
            action: "multiselect",
            rows: "G.src", ///sin espacios y comas
            table: "quotes A INNER JOIN bookcase BC ON (BC.reference = A.idQuote) LEFT JOIN files G ON (BC.idBook = G.idBook) INNER JOIN filetypes FT ON FT.keyFileType=BC.keyFileType",
            conditions: `A.enabled = 1 AND BC.enabled = 1 AND G.enabled = 1 AND FT.enabled = 1 AND A.idQuote=${idCot} AND FT.keyFileType = ${typeFile}`,
        }
        const res = await API.peticion(paramFiles)
        if (res.status === 200 && res.data.code === "200") {
            const data = res.data.data;
            if (data.length > 0) {
                setlinkFile(res.data.data[0].src)
                setModalFile(true)
            } else {
                toast(<Notification type={"consultar_error"} withIcon />);
            }
        } else {
            toast(<Notification type={"consultar_error"} withIcon />);
        }
    }

    return (
        <>
            <Widget>
                <h2 className="text-secondary text-center text-md-left p-3">Solicitud de cotización</h2>
                {permisos.INS === "1" && (
                    <div className="text-center text-md-right px-3 mb-3">
                        <Button onClick={() => window.location.href = "#/template/cotizacion/agregar"}>Agregar</Button>
                    </div>
                )}
                {permisos.CON === "1" ? (
                    <Datatable
                        headers={headers}
                        columns={columns}
                        columnDefs={columnDefs}
                        petition={getData}
                        control="back"
                        stateRefresh={[refresh, setRefresh]}
                        order={{col: 4, opt: "desc"}}
                    />
                ) : (<div className="text-center p-4">No tienes permitido ver los registros de esta seccion</div>)}
            </Widget>
            {linkFile ? (
                <ModalViewFile
                    modal={modalFile}
                    setModal={setModalFile}
                    backdrop={true}
                    keyboard={true}
                    btnClose={true}
                    fileLink={linkFile}
                />
            ) : null}
        </>
    )
}
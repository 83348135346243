import React, { useState } from "react";
import { toast } from "react-toastify";
import Notification from "../../../../components/Notification/Notification";
import { peticionesReceiver } from "../../../../helpers/peticionesReceiver";
import Datatable from "../../../../components/Datatable/Datatable";
import FormNuevaSolicitud from "./FormNuevaSolicitud";
import { Button, Col, Row } from "reactstrap";
import { useEffect } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  SelectTypeHeadSingle,
  TextInput,
} from "../../../../components/GenericInputsFormik";

const ConsultaInicial = (props) => {
  const { idArea, setBudgetrequests, permissions, controlStatus, isAuthorizedArea } = props;
  const API = peticionesReceiver();
  const [searchWithFilter, setSearchWithFilter] = useState(false);
  const [status, setStatus] = useState([]);

  const headers = [
    "#",
    "Folio",
    "Año",
    "Justificacion",
    "Fecha de registro",
    "Fecha de recepcion",
    "Estatus",
    "Monto Solicitado",
    "Ver detalles",
  ];
  const columns = [
    { data: "index" },
    {
      data: null,
      orderValue: "numberRequest",
      render: function (row) {
        return (
          <td className="text-center d-flex justify-content-center">
            {row.numberRequest !== "" ? row.numberRequest : "-"}
          </td>
        );
      },
    },

    {
      data: null,
      orderValue: "yearRequest",
      render: function (row) {
        return (
          <td className="text-center d-flex justify-content-center">
            {row.yearRequest !== "" ? row.yearRequest : "-"}
          </td>
        );
      },
    },

    { data: "justification" },
    // { data: "dataSend" },
    {
      data: null,
      orderValue: "dateSend",
      render: function (row) {
        return (
          <td className="text-center d-flex justify-content-center">
            {row.dateSend !== "" ? row.dateSend : "-"}
          </td>
        );
      },
    },

    { data: "modified" },
    { data: "nameStat" },
    // { data: "requestedAmount" },
    {
      data: null,
      orderValue: "requestedAmount",
      render: function (row) {
        return (
          <td className="text-center d-flex justify-content-center">
            {row.requestedAmount !== "" ? `$${row.requestedAmount}` : "-"}
          </td>
        );
      },
    },
    {
      data: null,
      render: function (row) {
        return (
          <td className="text-center d-flex justify-content-center">
            <i
              className={"fa fa-eye text-info handCursor"}
              onClick={() => {
                setBudgetrequests(row);
              }}
            />
          </td>
        );
      },
    },
  ];

  const FormSchema = Yup.object().shape({
    keyStat: Yup.string().required("Seleccione un estatus"),
    startDate: Yup.string().required("Ingrese una fecha de inicio"),
    endDate: Yup.string().required("Ingrese una final"),
  });

  const defaultForm = {
    keyState: "",
    startDate: "",
    endDate: "",
  };
  const {
    handleSubmit,
    values,
    handleBlur,
    errors,
    touched,
    setFieldValue,
    handleReset,
    resetForm,
  } = useFormik({
    initialValues: defaultForm,
    validationSchema: FormSchema,
    onSubmit: () => {
      setSearchWithFilter(true);
      setRefresh(true);
    },
    onReset: () => {},
    enableReinitialize: true,
  });

  const onChange = ({ target }) => {
    if (target.name === "startDate") {
      setFieldValue("endDate", "");
    }
    setFieldValue(target.name, target.value);
  };

  async function getData(order, records, page, search) {
    try {
      const res = await API.peticion(
        getParamsTableElmt(order, records, page, search)
      );
      if (res.status === 200 && res.data.code === "200") {
        const data = res.data;
        data.data.forEach((value, index) => (value.index = 1 + index));
        return data;
      } else {
        toast(
          <Notification
            type={"consultar_error"}
            backMessage={res.data.message}
          />
        );
        return null;
      }
    } catch (err) {
      toast(<Notification type={"consultar_servidor_error"} withIcon />);
      return null;
    }
  }

  const [refresh, setRefresh] = useState(false);

  const getParamsTableElmt = (
    order = "dateSend asc",
    records,
    page,
    search
  ) => {
    const params = {
      action: "datatable",
      table: "budgetrequests A INNER JOIN status B on (A.keyStat = B.keyStat) ",
      // "budgetrequests A INNER JOIN status B on (A.keyStat = B.keyStat)",
      rows: "A.idBudgetRequest,A.numberRequest,A.yearRequest,A.justification,A.dateSend,A.modified,A.keyStat,A.requestedAmount,B.nameStat,A.keyStat,A.notes",
      conditions:
        searchWithFilter === false
          ? `A.enabled = 1 and A.idArea = ${idArea}`
          : `A.enabled = 1 and A.idArea = ${idArea} and A.keyStat = ${
              values.keyStat
            } and A.modified BETWEEN str_to_date('${values.startDate.toString()}', '%Y-%m-%d')  and str_to_date( '${values.endDate.toString()}', '%Y-%m-%d') `,
      order,
      records: records.toString(),
      page: (page * records).toString(),
      search,
    };
    return params;
  };

  const columnDefs = [
    {
      targets: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
      className: "text-center",
    },
    {
      orderable: false,
      targets: [0],
    },
  ];

  useEffect(() => {
    getStatus();
  }, []);

  const getStatus = async () => {
    const params = {
      action: "multiselect",
      table: "status",
      rows: "keyStat,nameStat",
      conditions: `enabled = 1 and keyTypeStat = 27`,
    };
    await API.peticion(params)
      .then((res) => {
        if (res.status === 200 && res.data.code === "200") {
          setStatus(res.data.data);
        } else {
          toast(
            <Notification
              type={"consultar_error"}
              backMessage={res.data.message}
              withIcon
            />
          );
        }
      })
      .catch((err) => {
        toast(<Notification type={"consultar_servidor_error"} withIcon />);
      });
  };

  return (
    <div>
      <>
        <div className="mb-5">
          <h3 className="text-center">Solicitudes</h3>
        </div>

        <form
          className="mt-5 mb-5"
          onSubmit={handleSubmit}
          onReset={handleReset}
        >
          <Row className="d-flex justify-content-around">
            <Col xs={12} lg={4}>
              <SelectTypeHeadSingle
                label="Estatus"
                inputName="keyStat"
                onChangeMethod={onChange}
                onBlurMethod={handleBlur}
                value={values.keyStat}
                optionsArray={status}
                optionValue="keyStat"
                optionName="nameStat"
                errors={errors.keyStat}
                touched={touched.keyStat}
                isRequired
              />
            </Col>

            <Col xs={12} lg={4}>
              <TextInput
                label="Fecha inicial"
                inputType="date"
                inputName="startDate"
                onChangeMethod={onChange}
                onBlurMethod={handleBlur}
                value={values.startDate}
                isRequired
                touched={touched.startDate}
                errors={errors.startDate}
              />
            </Col>
            <Col xs={12} lg={4}>
              <TextInput
                label="Fecha final"
                inputType="date"
                inputName="endDate"
                onChangeMethod={onChange}
                onBlurMethod={handleBlur}
                value={values.endDate}
                isRequired
                touched={touched.endDate}
                errors={errors.endDate}
                isMin={values.startDate}
                isDisabled={values.startDate === ""}
              />
            </Col>
          </Row>
          <div className=" d-flex flex-md-row flex-column-reverse justify-content-md-around">
            <Button
              color="danger"
              className="mt-3"
              onClick={() => {
                setSearchWithFilter(false);
                resetForm();
                setRefresh(true);
              }}
            >
              Limpiar
            </Button>
            <Button color="success" type="submit" className="mt-2 mt-md-3">
              Buscar
            </Button>
          </div>
        </form>

        <Datatable
          headers={headers}
          columns={columns}
          columnDefs={columnDefs}
          petition={getData}
          control="back"
          stateRefresh={[refresh, setRefresh]}
          searching={true}
          order={{ col: 5, opt: "desc" }}
        />

        {(+controlStatus.enabled === 1 || isAuthorizedArea ) && permissions.INS && (
          <FormNuevaSolicitud setRefresh={setRefresh} idArea={idArea} />
        )}
      </>
    </div>
  );
};

export default ConsultaInicial;

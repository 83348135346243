import TableComponent from "../../../../components/TableComponent/TableComponentFiltro";
import { useState } from "react";

export const Sistemas = () => {
  const cabeceras = ["Id", "Nombre", "Editar", "Eliminar"];
  const [parametros] = useState({
    action: "datatable",
    table: "apparatus", 
    rows: "keySystem,nameSystem",
    conditions:"enabled = 1",
    page:0,
    records:5,
    search:"",
    order:""
  });
  
  return (
    <div>
        <TableComponent
          titulo="Sistemas"
          cabecerasTabla={cabeceras}
          filtro={null}
          rutaCrear="/template/sistemas/crear"
          rutaEditar="/template/sistemas/editar"
          parametros={parametros}
        ></TableComponent>
    </div>
  );
};
export default Sistemas;

import React, { useState,useEffect,useRef } from "react";
import { Col, Row } from "reactstrap";
import Widget from "../../../components/Widget/Widget";
import ConsultaBien from "./ConsultaBien";
import TableComponentCustom from "../../../components/TableComponent/TableComponentCustom";
import {Button} from "reactstrap"
import TableAgregados from "./TableAgregados";

const headers = [
  "Id",
  "DENOMINACION",
  "CODIGO DE BARRAS",
  "NUMERO DE SERIE",
  "NOMBRE",
  "MARCA",
  "MODELO",
  "ESTATUS",
  "SELECCIONAR",
];

const filtro = [true,true,true,true,true,true,true,true]



const Rotulacion = () => {
  const [params, setParams] = useState("");
  const [checks, setChecks] = useState([]);
  const [bienes, setBienes] = useState([]);

  
  const addBien = (value) => {
    let bienesTemp = bienes.slice(0, bienes.length)
    bienesTemp.push(value)
    setBienes(bienesTemp)
    
  }

  const addButton = (value, key) => {
    return (
      <Button color="success" onClick = {() => addBien(value)} key={key}>
      Agregar 
    </Button>        
    );
  };

  

  return (
    <Widget className="widget-p-md">
      <Row>
        <Col xs={12} lg={12} className="text-center">
          <span className="text-center text-succes headline-1">Rotulacion</span>
        </Col>
      </Row>

      <ConsultaBien setParams={setParams} />



      


      {params !== "" && (
        <TableComponentCustom
          titulo={"Bienes"}
          cabecerasTabla={headers}
          filtro={filtro}
          parametros={params}
          specialColumns={[addButton]}
          
        />
      )}

      {bienes.length > 0 && <TableAgregados bienes={bienes} setBienes={setBienes}/>}

    </Widget>
  );
};

export default Rotulacion;

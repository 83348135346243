/* eslint-disable react-hooks/exhaustive-deps */
import * as Yup from "yup";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { Col, Row, Button, Collapse } from "reactstrap";
import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import DatePicker from "react-datepicker";
import Loader from "../../../components/Loader/Loader";
import { peticionesReceiver } from "../../../helpers/peticionesReceiver";
import Notification from "../../../components/Notification/Notification";
import ModalConfirmation from "../../../components/Modal/ModalConfirmation";
import {
  SelectTypeHeadSingle,
  TextInput,
} from "../../../components/GenericInputsFormik";
import { NumberInput } from "../../../components/GenericInputsFormik/NumberInput";
import TableComponentEliminar from "../../../components/TableComponent/TableComponentEliminar";
import { validarRFC } from "../../../libs/personalData/RFC";
import { GetPermissions } from "../../../libs/permissions/getPermissions";

export default function FormProveedores({ crear, data, editar }) {
  const [permissions, setPermissions] = useState({});
  GetPermissions(setPermissions);

  const defaultForm = {
    idProviderPayroll: "",
    businessName: "",
    rfc: "",
    signatureAgreementDate: "",
    titleHolder: "",
    accountHolder: "",
    switfCode: "",
    keyBank: 0,
    account: "",
    keyStat: 0,
    startAgreementDate: "",
    endAgreementDate: "",
    nameContact: "",
    phoneContact: "",
    movilContact: "",
    emailContact: "",
    idAccountList: "",
  };

  const history = useHistory();
  const API = peticionesReceiver();

  const [modal, setModal] = useState(false);
  const [banks, setBanks] = useState([]);
  const [status, setStatus] = useState([]);
  const [lista, setLista] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [loadingBanks, setLoadingBanks] = useState(true);
  const [loadingStatus, setLoadingStatus] = useState(true);
  const [loadingData, setLoadingData] = useState(true);
  //Asignación de cuentas contables
  const [loadingLista, setLoadingLista] = useState(true);
  const [collapseArea, setCollapseArea] = useState(false);
  const [cabecerasLista] = useState([
    "Id",
    "L. C. Propio",
    "Denominación",
    "Acción",
  ]);
  const [filtroLista] = useState([true, true, true, true]);
  const [parametrosLista, setParametrosLista] = useState();

  useEffect(() => {
    getBanks();
    getStatus();
    if (data && !loadingBanks && !loadingStatus) {
      getProviderById(Number(data[0]));
      getListas();
    }
  }, [data, loadingBanks, loadingStatus]);

  useEffect(() => {
    if (data) {
      setIsLoading(
        loadingBanks || loadingStatus || loadingData || loadingLista
      );
    } else {
      setIsLoading(loadingBanks || loadingStatus);
    }
  }, [loadingBanks, loadingStatus, loadingData, loadingLista, data]);

  const getProviderById = async (id) => {
    const params = {
      action: "select",
      table: "providerspayroll",
      condition: {
        enabled: 1,
        idProviderPayroll: id,
      },
    };
    const res = await API.peticion(params);
    if (res.status === 200 && res.data.code === "200") {
      const data = res.data.data[0];
      setFieldValue("idProviderPayroll", data.idProviderPayroll);
      setFieldValue("businessName", data.businessName);
      setFieldValue("rfc", data.rfc);
      setFieldValue(
        "signatureAgreementDate",
        data.signatureAgreementDate || ""
      );
      setFieldValue("titleHolder", data.titleHolder || "");
      setFieldValue("accountHolder", data.accountHolder || "");
      setFieldValue("switfCode", data.switfCode || "");
      setFieldValue("keyBank", data.keyBank);
      setFieldValue("account", data.account);
      setFieldValue("keyStat", `${data.keyStat}`);
      setFieldValue("startAgreementDate", data.startAgreementDate || "");
      setFieldValue("endAgreementDate", data.endAgreementDate || "");
      setFieldValue("nameContact", data.nameContact || "");
      setFieldValue("phoneContact", data.phoneContact || "");
      setFieldValue("movilContact", data.movilContact || "");
      setFieldValue("emailContact", data.emailContact || "");
      updateParamsLista(data.idProviderPayroll);
      setCollapseArea(true);
      setLoadingData(false);
    } else {
      toast(<Notification type={"consultar_error"} withIcon />);
    }
  };

  const getBanks = async () => {
    const params = {
      action: "select",
      table: "banks",
      condition: {
        enabled: 1,
      },
      order: "nameBank ASC",
    };
    const res = await API.peticion(params);
    if (res.status === 200 && res.data.code === "200") {
      setBanks(res.data.data);
      setLoadingBanks(false);
    } else {
      toast(<Notification type={"consultar_error"} withIcon />);
      setLoadingBanks(false);
    }
  };

  const getStatus = async () => {
    const params = {
      action: "multiselect",
      table:
        "kindstatus A INNER JOIN status B ON A.keyKindStat = B.keyTypeStat",
      conditions: "A.enabled=1 AND B.enabled=1 AND A.keyKindStat=23",
      rows: "B.keyStat,B.nameStat",
    };
    const res = await API.peticion(params);
    if (res.status === 200 && res.data.code === "200") {
      setStatus(res.data.data);
      setLoadingStatus(false);
    } else {
      toast(<Notification type={"consultar_error"} withIcon />);
      setLoadingStatus(false);
    }
  };

  const getListas = async () => {
    const params = {
      action: "multiselect",
      table: "account_list",
      conditions: "keyPlan = 147 and enabled = 1",
      rows: "idAccountList,CONCAT(fullkey,': ',nameAccountList) as nameAccountList",
    };
    const res = await API.peticion(params);
    if (res.status === 200 && res.data.code === "200") {
      setLista(res.data.data);
      setLoadingLista(false);
    } else {
      toast(<Notification type={"consultar_error"} withIcon />);
      setLoadingLista(false);
    }
  };

  Yup.addMethod(Yup.string, "rfc", function (errorMessage) {
    return this.test("rfc_valid", errorMessage, function (value) {
      const { path, createError } = this;
      return (
        // eslint-disable-next-line no-mixed-operators
        (value && validarRFC(value)) ||
        createError({ path, message: errorMessage })
      );
    });
  });

  const FormSchema = Yup.object().shape({
    businessName: Yup.string().required("El campo es obligatorio"),
    rfc: Yup.string()
      .required("El campo es obligatorio")
      .rfc("Ingrese un RFC valido"),
    signatureAgreementDate: Yup.date(),
    titleHolder: Yup.string(),
    accountHolder: Yup.string(),
    switfCode: Yup.string()
      .test(
        "oneOfRequired",
        "Al menos una cuenta o CLABE es requerido",
        function (item) {
          return this.parent.account || this.parent.switfCode;
        }
      )
      .max(18, "Ingrese una CLABE valida")
      .min(18, "Ingrese una CLABE valida"),
    keyBank: Yup.number().required("Seleccione un banco valido"),
    account: Yup.number().test(
      "oneOfRequired",
      "Al menos una cuenta o CLABE es requerido",
      function (item) {
        return this.parent.account || this.parent.switfCode;
      }
    ),
    keyStat: Yup.number().required("Seleccione un estado valido"),
    startAgreementDate: Yup.date(),
    endAgreementDate: Yup.date(),
    nameContact: Yup.string(),
    phoneContact: Yup.string().max(
      12,
      "La longitud maximad de un telefono es de 12"
    ),
    movilContact: Yup.string().max(
      12,
      "La longitud maximad de un numero celular es de 12"
    ),
    emailContact: Yup.string().email("Correo electrónico invalido"),
    idAccountList: Yup.number(),
  });

  const onChange = (e) => {
    const { name, value } = e.target;
    setFieldValue([name], value);
  };

  const limpia = (reset) => {
    reset();
    history.push("/template/proveedoresnomina");
  };

  const {
    handleSubmit,
    values,
    handleBlur,
    errors,
    touched,
    setFieldValue,
    resetForm,
  } = useFormik({
    initialValues: defaultForm,
    onSubmit: () => setModal(true),
    validationSchema: FormSchema,
  });

  const addLista = (id) => {
    

    if (id === 0 || id === "0") {
      toast(
        <Notification
          type={"agrega_error"}
          backMessage={"Seleccione una lista "}
          withIcon
        />
      );
    } else {
      const final = {
        idProviderPayroll: values.idProviderPayroll,
        idAccountList: id,
      };
      const data = {
        action: "insert",
        table: "providerspayrollaccountList",
        rows: final,
        validate: [["idProviderPayroll", "idAccountList"]],
      };
      API.peticion(data)
        .then((res) => {
          if (res.status === 200 && res.data.status === "success") {
            toast(
              <Notification
                type={"agrega_exito"}
                backMessage={res.data.message}
                withIcon
              />
            );
            updateParamsLista(final.idProviderPayroll);
            setFieldValue("idAccountList", "");
          } else {
            toast(
              <Notification
                type={"agrega_error"}
                backMessage={res.data.message}
                withIcon
              />
            );
          }
        })
        .catch((err) => {
          toast(err);
          console.log(err);
        });
    }
  };

  function updateParamsLista(id) {
    setParametrosLista({
      action: "datatable",
      table:
        "providerspayrollaccountList A INNER JOIN account_list B on (A.idAccountList = B.idAccountList)",
      rows: "A.idProviderPayrollAccountList,B.accountListKey,B.nameAccountList",
      conditions: `A.enabled = 1 AND A.idProviderPayroll = ${id}`,
      page: 0,
      records: 10,
      search: "",
      order: "",
    });
  }

  const actualizaElimina = () => {
    getListas(true);
  };

  function formatDateShow(date) {
    const tempDate = new Date(date);

    return [
      padTo2Digits(tempDate.getDate()),
      padTo2Digits(tempDate.getMonth() + 1),
      tempDate.getFullYear(),
    ].join("/");
  }

  function padTo2Digits(num) {
    return num.toString().padStart(2, "0");
  }

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <form onSubmit={handleSubmit} className="mt-4" noValidate>
            <ModalConfirmation
              modalTitle={data ? "Editar" : "Crear"}
              modal={modal}
              setModal={setModal}
              crear={crear}
              editar={editar}
              isEdit={data ? true : false}
              values={values}
            >
              {data ? (
                <div className="d-flex justify-content-center">
                  <h6>¿Está seguro de editar el registro?</h6>
                </div>
              ) : (
                <div className="d-flex justify-content-center">
                  <h6>¿Desea continuar con el registro?</h6>
                </div>
              )}
            </ModalConfirmation>

            <Row className="mt-2">
              <Col>
                <TextInput
                  label="Razón social"
                  inputType="text"
                  inputName="businessName"
                  onChangeMethod={onChange}
                  onBlurMethod={handleBlur}
                  value={values.businessName}
                  errors={errors.businessName}
                  touched={touched.businessName}
                  isRequired
                />
              </Col>
            </Row>
            <Row className="mt-2">
              <Col xs={12} md={4}>
                <TextInput
                  label="RFC"
                  inputType="text"
                  inputName="rfc"
                  onChangeMethod={onChange}
                  onBlurMethod={handleBlur}
                  value={values.rfc}
                  errors={errors.rfc}
                  touched={touched.rfc}
                  isRequired
                />
              </Col>
              <Col xs={12} md={4}>
                <div className="form-group">
                  <label htmlFor={values.signatureAgreementDate}>
                    Fecha de convenio<span className="text-danger">*</span>
                  </label>
                  <DatePicker
                    locale="es"
                    onChange={(date) =>
                      onChange({
                        target: { name: "signatureAgreementDate", value: date },
                      })
                    }
                    /* maxDate={values.endDate !== "" && values.endDate} */
                    value={
                      values.signatureAgreementDate !== ""
                        ? formatDateShow(values.signatureAgreementDate)
                        : "dd/mm/yyyy"
                    }
                    className={
                      "form-control" +
                      (errors.signatureAgreementDate &&
                      touched.signatureAgreementDate
                        ? " is-invalid"
                        : "")
                    }
                  />
                  {touched.signatureAgreementDate &&
                    errors.signatureAgreementDate && (
                      <span style={{ color: "red" }}>
                        {errors.signatureAgreementDate}
                      </span>
                    )}
                </div>
                {/* 
                <TextInput
                  label="Fecha de convenio"
                  inputType="date"
                  inputName="signatureAgreementDate"
                  onChangeMethod={onChange}
                  onBlurMethod={handleBlur}
                  value={values.signatureAgreementDate}
                  errors={errors.signatureAgreementDate}
                  touched={touched.signatureAgreementDate}
                /> */}
              </Col>
              <Col xs={12} md={4}>
                <TextInput
                  label="Cargo del titular"
                  inputType="text"
                  inputName="titleHolder"
                  onChangeMethod={onChange}
                  onBlurMethod={handleBlur}
                  value={values.titleHolder}
                  errors={errors.titleHolder}
                  touched={touched.titleHolder}
                />
              </Col>
            </Row>
            <Row className="mt-2">
              <Col xs={12} md={8}>
                <TextInput
                  label="Titular de la cuenta"
                  inputType="text"
                  inputName="accountHolder"
                  onChangeMethod={onChange}
                  onBlurMethod={handleBlur}
                  value={values.accountHolder}
                  errors={errors.accountHolder}
                  touched={touched.accountHolder}
                />
              </Col>
              <Col xs={12} md={4}>
                <TextInput
                  label="CLABE"
                  inputType="number"
                  inputName="switfCode"
                  onChangeMethod={onChange}
                  onBlurMethod={handleBlur}
                  value={values.switfCode}
                  errors={errors.switfCode}
                  touched={touched.switfCode}
                  isRequired
                />
              </Col>
            </Row>
            <Row className="mt-2">
              <Col xs={12} md={4}>
                <SelectTypeHeadSingle
                  label="Banco"
                  isRequired={true}
                  optionsArray={banks}
                  inputName="keyBank"
                  onChangeMethod={onChange}
                  onBlurMethod={handleBlur}
                  touched={touched.keyBank}
                  errors={errors.keyBank}
                  optionValue="keyBank"
                  optionName="nameBank"
                  value={values.keyBank}
                />
              </Col>
              <Col xs={12} md={4}>
                <TextInput
                  label="Número de cuenta"
                  inputType="number"
                  inputName="account"
                  onChangeMethod={onChange}
                  onBlurMethod={handleBlur}
                  value={values.account}
                  errors={errors.account}
                  touched={touched.account}
                  isRequired
                />
              </Col>
              <Col xs={12} md={4}>
                <SelectTypeHeadSingle
                  label="Estatus"
                  isRequired={true}
                  optionsArray={status}
                  inputName="keyStat"
                  onChangeMethod={onChange}
                  onBlurMethod={handleBlur}
                  touched={touched.keyStat}
                  errors={errors.keyStat}
                  optionValue="keyStat"
                  optionName="nameStat"
                  value={values.keyStat}
                  defaultOption="Seleccione un estado"
                />
              </Col>
            </Row>
            <Row className="mt-2">
              <Col xs={12} md={6}>
                <div className="form-group">
                  <label htmlFor={values.startAgreementDate}>
                    Fecha de inicio de contrato
                    <span className="text-danger">*</span>
                  </label>
                  <DatePicker
                    locale="es"
                    onChange={(date) =>
                      onChange({
                        target: { name: "startAgreementDate", value: date },
                      })
                    }
                    /* maxDate={values.endDate !== "" && values.endDate} */
                    value={
                      values.startAgreementDate !== ""
                        ? formatDateShow(values.startAgreementDate)
                        : "dd/mm/yyyy"
                    }
                    className={
                      "form-control" +
                      (errors.startAgreementDate && touched.startAgreementDate
                        ? " is-invalid"
                        : "")
                    }
                  />
                  {touched.startAgreementDate && errors.startAgreementDate && (
                    <span style={{ color: "red" }}>
                      {errors.startAgreementDate}
                    </span>
                  )}
                </div>
                {/* <TextInput
                    label="Fecha inicio contrato"
                    inputType="date"
                    inputName="startAgreementDate"
                    onChangeMethod={onChange}
                    onBlurMethod={handleBlur}
                    value={values.startAgreementDate}
                    errors={errors.startAgreementDate}
                    touched={touched.startAgreementDate}
                  /> */}
              </Col>
              <Col xs={12} md={6}>
                <div className="form-group">
                  <label htmlFor={values.endAgreementDate}>
                    Fecha final de contrado
                    <span className="text-danger">*</span>
                  </label>
                  <DatePicker
                    locale="es"
                    onChange={(date) =>
                      onChange({
                        target: { name: "endAgreementDate", value: date },
                      })
                    }
                    minDate={values.startAgreementDate}
                    value={
                      values.endAgreementDate !== ""
                        ? formatDateShow(values.endAgreementDate)
                        : "dd/mm/yyyy"
                    }
                    className={
                      "form-control" +
                      (errors.endAgreementDate && touched.endAgreementDate
                        ? " is-invalid"
                        : "")
                    }
                  />
                  {touched.endAgreementDate && errors.endAgreementDate && (
                    <span style={{ color: "red" }}>
                      {errors.endAgreementDate}
                    </span>
                  )}
                </div>

                {/* <TextInput
                  label="Fecha final contrato"
                  inputType="date"
                  inputName="endAgreementDate"
                  onChangeMethod={onChange}
                  onBlurMethod={handleBlur}
                  value={values.endAgreementDate}
                  errors={errors.endAgreementDate}
                  touched={touched.endAgreementDate}
                  isMin={values.startAgreementDate}
                /> */}
              </Col>
            </Row>
            <Row className="mt-2">
              <Col className="text-center font-weight-bold mb-2">
                DATOS DE CONTACTO
              </Col>
            </Row>
            <Row className="mt-2">
              <Col xs={12} md={3}>
                <TextInput
                  label="Nombre"
                  inputType="text"
                  inputName="nameContact"
                  onChangeMethod={onChange}
                  onBlurMethod={handleBlur}
                  value={values.nameContact}
                  errors={errors.nameContact}
                  touched={touched.nameContact}
                />
              </Col>
              <Col xs={12} md={3}>
                <NumberInput
                  label="Teléfono"
                  inputName="phoneContact"
                  onChangeMethod={onChange}
                  onBlurMethod={handleBlur}
                  value={values.phoneContact}
                  errors={errors.phoneContact}
                  touched={touched.phoneContact}
                  disableSep
                />
              </Col>
              <Col xs={12} md={3}>
                <NumberInput
                  label="Movil"
                  inputName="movilContact"
                  onChangeMethod={onChange}
                  onBlurMethod={handleBlur}
                  value={values.movilContact}
                  errors={errors.movilContact}
                  touched={touched.movilContact}
                  disableSep
                />
              </Col>
              <Col xs={12} md={3}>
                <TextInput
                  label="Correo"
                  inputType="email"
                  inputName="emailContact"
                  onChangeMethod={onChange}
                  onBlurMethod={handleBlur}
                  value={values.emailContact}
                  errors={errors.emailContact}
                  touched={touched.emailContact}
                />
              </Col>
            </Row>
            <div className="row mt-3">
              <div className="col-sm-6 order-2 order-sm-1 text-center text-sm-left mb-4">
                <Button
                  color="danger"
                  type="reset"
                  onClick={() => limpia(resetForm)}
                >
                  {" "}
                  Cancelar{" "}
                </Button>
              </div>

              <div className="col-sm-6 order-1 order-sm-2 text-center text-sm-right mb-4">
                <Button color="success" type="submit">
                  {" "}
                  Guardar{" "}
                </Button>
              </div>
            </div>
          </form>
          {data ? (
            <Collapse isOpen={collapseArea}>
              <Row className="mt-2">
                <Col className="text-center font-weight-bold mb-2">
                  ASIGNACIÓN DE CUENTAS CONTABLES
                </Col>
              </Row>
              <Row className="d-flex justify-content-around">
                <Col xs={12} md={12} lg={8}>
                  <SelectTypeHeadSingle
                    label="Lista de cuentas"
                    optionsArray={lista}
                    inputName="idAccountList"
                    onChangeMethod={onChange}
                    onBlurMethod={handleBlur}
                    touched={touched.idAccountList}
                    errors={errors.idAccountList}
                    optionValue="idAccountList"
                    optionName="nameAccountList"
                    value={values.idAccountList}
                  />
                </Col>
              </Row>

              <div className="text-center">
                <Button
                  color="add"
                  onClick={() => addLista(values.idAccountList)}
                >
                  Agregar
                </Button>
              </div>

              <TableComponentEliminar
                titulo="Areas"
                cabecerasTabla={cabecerasLista}
                filtro={filtroLista}
                parametros={parametrosLista}
                limpia={actualizaElimina}
                reset={resetForm}
                permissions={permissions}
              />

              <div className="row m-3">
                <div className="col-sm-6 order-2 order-sm-1 text-center text-sm-left mb-4">
                  <Button
                    color="danger"
                    type="reset"
                    onClick={() => limpia(resetForm)}
                  >
                    Cancelar
                  </Button>
                </div>
              </div>
            </Collapse>
          ) : null}
        </>
      )}
    </>
  );
}

import * as XlsxPopulate from "xlsx-populate";
import { saveAs } from "file-saver";
import axios from "axios";
import getLayout from "../../../../components/excel/layoutExcel";

const fmt = new Intl.NumberFormat("es-MX", {
  maximumFractionDigits: 2,
  minimumFractionDigits: 2,
});
const fmtMny = new Intl.NumberFormat("es-MX", {
  style: "currency",
  currency: "MXN",
});

const headersA = [
  "NOMBRE DE LA CUENTA",
  "NÚMERO DE LA CUENTA",
  "INTEGRACION",
  "TOTAL A PAGAR",
  "BENEFICIARIO",
  "CLABE BANCARIA",
  "BANCO DESTINO",
];

const headersB = ["NO.", "NO. EMPLEADO", "NOMBRE COMPLETO", "IMPORTE"];

const s = {
  tC: { horizontalAlignment: "center", verticalAlignment: "center" },
  tS: { horizontalAlignment: "left" },
  tE: { horizontalAlignment: "right", verticalAlignment: "center" },
  tt: {
    horizontalAlignment: "center",
    verticalAlignment: "bottom",
    wrapText: true,
    shrinkToFit: true,
    bold: true,
  },

  tN: {
    border: true,
    wrapText: true,
    shrinkToFit: true,
    verticalAlignment: "center",
  },
  tNC: {
    horizontalAlignment: "center",
    verticalAlignment: "center",
    wrapText: true,
    shrinkToFit: true,
    border: true,
  },
  tNE: {
    horizontalAlignment: "right",
    verticalAlignment: "center",
    wrapText: true,
    shrinkToFit: true,
    border: true,
  },

  header: {
    horizontalAlignment: "center",
    bold: true,
    verticalAlignment: "center",
    fill: "730A29",
    fontColor: "FFFFFF",
    wrapText: true,
    shrinkToFit: true,
    border: true,
  },
  tB: { bold: true },
  wt: { wrapText: true, verticalAlignment: "center" },
};

export async function excelProveedoresN(data, cfg) {
  const excel = await getLayout();
  if (excel)
    XlsxPopulate.fromDataAsync(excel).then(async (workbook) => {
      const hoja = workbook.sheet(0);
      let row = 4;
      hoja.range(`A${row}:H${row}`).merged(true).value(cfg.year).style(s.tt);
      row++;
      hoja.range(`A${row}:H${row}`).merged(true).value(
        "SOLICITUD DE PAGO POR TRANSFERENCIA DE DEDUCCIONES VÍA NÓMINA A TERCEROS"
      ).style(s.tt);
      row += 2;
      if (cfg.detalle === "") pageA(hoja, row, data);
      if (cfg.detalle === "1") pageB(hoja, row, cfg, data);

      return workbook.outputAsync().then((res) => {
        saveAs(
          res,
          `Solicitudes de Pago de Proveedores de Nomina ${cfg.period}-${cfg.year}.xlsx`
        );
      });
    });
}

function pageA(hoja, row, data) {
  for (let i = 0; i < headersA.length; i++) {
    hoja.column(i + 1).width(30);
    hoja
      .row(row)
      .cell(i + 1)
      .value(headersA[i])
      .style(s.header);
  }
  data.forEach((d, i) => {
    row++;
    const validDif = +d.importes > 0 && +d.deducciones > 0;
    const integracion = validDif || d.conceptosDed.length > 1 ? "SI" : "NO";
    const tt = validDif ? +d.deducciones - +d.importes : +d.deducciones;
    hoja.row(row).cell(1).value(d.nameAccountList).style(s.tN);
    hoja.row(row).cell(2).value(d.lcPropio).style(s.tNC);
    hoja.row(row).cell(3).value(integracion).style(s.tNC);
    hoja.row(row).cell(4).value(fmtMny.format(tt)).style(s.tNE);
    hoja.row(row).cell(5).value(d.rfc).style(s.tN);
    hoja.row(row).cell(6).value(d.cuenta).style(s.tN);
    hoja.row(row).cell(7).value(d.nameBank).style(s.tN);
  });
  return row;
}

function pageB(hoja, row, cfg, data) {
  data.forEach((d, i) => {
    if (d.conceptos.length > 0) {
      row=mapComceptos(hoja, row, cfg, d.conceptos, d.conceptosTotal, d.conceptosTotalPersonas);
      row += 2;
    }
  });

  return row;
}

function mapComceptos(hoja, row, cfg, cct, ttc, ttp){
  cct.map((c, ix)=>{
    if(c.status === "success" && c.totalRows > 0){
      hoja.range(`C${row}:F${row}`).merged(true).value(`Concepto: ${c.data[0].nameUnder}`).style(s.tC);
      row++;
      hoja.range(`C${row}:F${row}`).merged(true).value(`Periodo ${cfg.period} de ${cfg.year}`).style(s.tC);
      row++;
      row=tableB(hoja, row, c.data, ttc[ix], ttp[ix], ix);
      row+=3;
    }
  })
  return row;
}

function tableB(hoja, row, con, ttc, ttp, ix) {
  for (let i = 0; i < headersB.length; i++) {
    if(ix === 0) hoja.column(i + 3).width(i + 3 === 5 ? 50 : i + 3 === 6 ? 40 : 25);
    hoja.row(row).cell(i + 3).value(headersB[i]).style(s.header);
  }
  con.forEach((c, i) => {
    row++;
    hoja.row(row).cell(3).value(ttp + 1 + i).style(s.tNC);
    hoja.row(row).cell(4).value(c.staffNumber).style(s.tNC);
    hoja.row(row).cell(5).value(c.nombreCompleto).style(s.tN);
    hoja.row(row).cell(6).value(fmtMny.format(c.amount)).style(s.tNE);
  });
    row++;
    hoja.range(`C${row}:E${row}`).merged(true).value("Total").style(s.tNC);
    hoja.row(row).cell(6).value(fmtMny.format(ttc)).style(s.tNC);
  return row;
}

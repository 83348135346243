import React, { useEffect, useState } from "react";
import ModalTableVer from "../../../components/Modal/ModalTableVer";
import ModalViewFile from "../../../components/Modal/ModalViewFile";
import TableComponentCustom from "../../../components/TableComponent/TableComponentCustom";
import { useLocation } from "react-router-dom";
import classNames from "classnames";
import sc from "../../../components/TableComponent/TableComponentFiltro.module.scss";

const cabeceras = [
  "Id",
  "Estatus",
  "Periodo/Año",
  "Fecha de registro",
  "Oficios",
  "Bitacora de comentarios",
];
const cabecerasE = [
  "Id",
  "Estatus",
  "Periodo/Año",
  "Fecha de registro",
  "Editar",
  "Oficios",
  "Bitacora de comentarios",
];
const filtro = [true, true, true, true];

const cabecerasModal = ["Id", "Observación", "Acciones", "Fecha de registro"];
const filtroModal = [true, true, true, true];

export const SolicitudPago = () => {
  const [parametros] = useState({
    action: "datatable",
    table:
      "payrollrequest A INNER JOIN status B ON A.keyStat = B.keyStat LEFT JOIN bookcase C ON A.idPayrollRequest = C.reference INNER JOIN files D ON C.idBook = D.idBook",
    rows: "A.idPayrollRequest, B.nameStat, CONCAT(A.period,'/',A.year) AS periodYear, A.created, A.period, A.year, A.keyStat, D.src",
    conditions:
      "A.enabled = 1 AND C.enabled = 1 AND D .enabled = 1 AND C.keyFileType = 19",
    page: 0,
    records: 5,
    search: "",
    order: "",
  });
  const [parametrosModal, setParametrosModal] = useState({
    action: "datatable",
    table: "payrollrequestnotes A INNER JOIN status B ON A.keyStat = B.keyStat",
    rows: "A.idPayrollRequest,  A.notes,  B.nameStat, A.created",
    conditions: "A.enabled = 1",
    page: 0,
    records: 5,
    search: "",
    order: "",
  });
  const [modalTabla, setModalTabla] = useState(false);
  const [modalFile, setModalFile] = useState(false);
  const [linkFile, setlinkFile] = useState("");
  const rute = useLocation();
  const module = rute.pathname.split("/")[2];

  const openOficio = (el) => {
    setlinkFile(el[7]);
    setModalFile(true);
  };

  const openBitacora = (el) => {
    const params = { ...parametrosModal };
    params.conditions = `A.idPayrollRequest = ${el[0]} AND A.enabled = 1`;
    setParametrosModal(params);
    setModalTabla(true);
  };

  const ColOficios = (value) => {
    return (
      <i
        className={classNames("fa fa-file-pdf-o text-secondary-red", sc.handCursor)}
        onClick={() => openOficio(value)}
      />
    );
  };

  const ColBitacora = (value) => {
    return (
      <i
        className={classNames("fa fa-eye text-info", sc.handCursor)}
        onClick={() => openBitacora(value)}
      />
    );
  };

  // const ColEditar = (value) => {
  //   return <i className={classNames("fa fa-pencil text-warning", sc.handCursor)} onClick={() => openOficio(value)} />;
  // };

  const specials = [ColOficios, ColBitacora];

  return (
    <div>
      <TableComponentCustom
        titulo="Solicitudes de Pago"
        cabecerasTabla={module === "solicitudpago" ? cabeceras : cabecerasE}
        filtro={filtro}
        parametros={parametros}
        addNew={module === "solicitudpago"}
        rutaCrear="solicitudpago/crear"
        addEdit={module !== "solicitudpago"}
        rutaEditar="solicitud-pago/editar"
        specialColumns={specials}
      />

      <ModalViewFile
        modal={modalFile}
        setModal={setModalFile}
        backdrop={true}
        keyboard={true}
        btnClose={true}
        fileLink={linkFile}
      />

      <ModalTableVer
        modal={modalTabla}
        setModal={setModalTabla}
        title="Registro de Movimientos de la Solicitud de Pago de Nómina"
        titleTable="Registro de Movimientos de la Solicitud de Pago de Nómina"
        hideTitleTable
        cabeceras={cabecerasModal}
        filtro={filtroModal}
        parametros={parametrosModal}
        btnClose
        backdrop
        keyboard
        canSee={false}
        style={{ minWidth: "95%" }}
      />
    </div>
  );
};
export default SolicitudPago;

import { useEffect, useState } from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, Spinner, Table } from "reactstrap";
import { peticionesReceiver } from "../../../helpers/peticionesReceiver";
import { toast } from "react-toastify";
import Notification from "../../../components/Notification/Notification";
import ModalDelete from "../../../components/Modal/ModalDelete";
import RowPartidas from "./rowPartidas";
import ModalConfirmation from "../../../components/Modal/ModalConfirmation";

const CurrencyFormatter = new Intl.NumberFormat("es-MX", {
    style: "currency",
    currency: "MXN",
});

const DecimSeparatorFormatter = new Intl.NumberFormat('en-US');

export default function ModalCotizacion({ visible, setVisible, idQuote, idBudgetRequest, idArea, idPurchaseRequest, setIdPurchaseRequest, setIdQuote, setIdBudgetRequest, added, setAdded }) {
    const [loadingQuotes, setLoadingQuotes] = useState(true);
    const [loadingAproved, setLoadingAproved] = useState(true);
    const [loadingDetails, setLoadingDetails] = useState(false);
    const [bienService, setBienService] = useState([])
    const [bienOriginal, setBienOriginal] = useState([])
    const [refresh, setRefresh] = useState(false)
    const [approved, setApproved] = useState([])
    const [deleteA, setDeleteA] = useState(false);
    const [modalDel, setModalDel] = useState(false);

    const [details, setDetails] = useState([])
    const API = peticionesReceiver()

    async function getQuotesDetail(idQuote) {
        const params = {
            action: "multiselect",
            table: "quotesdetail qt left join services sv on (qt.idService=sv.idService) left join furnitures f on (f.idFurniture=qt.idFurniture) left join messureunits mu on (f.keyUnit=mu.keyUnit) left join servicetimes sti on (sv.keyServiceTime=sti.keyServiceTime)",
            rows: "qt.idDetail,qt.amount,IFNULL(qt.total,0) as total,if(isnull(sv.nameService),f.nameFurniture,sv.nameService) as descripcion,f.brand,if(isnull(mu.nameUnit),sti.nameServiceTime,mu.nameUnit) as unit",
            conditions: `qt.idQuote=${idQuote} AND qt.enabled=1 AND qt.idDetail NOT IN (SELECT idDetail from purchaserequestdetail where enabled = 1)`
        }
        try {
            const res = await API.peticion(params);
            if (res.status === 200 && res.data.code === "200") {
                const data = res.data.data.map(row => { return { idDetail: row.idDetail, desc: `Cantidad: ${DecimSeparatorFormatter.format(row.amount)} ${row.descripcion}${row.brand ? `, ${row.brand}` : ""} Peridiocidad: ${row.unit} Costo: ${CurrencyFormatter.format(row.total)}` } })
                setBienService(data)
                setBienOriginal(res.data.data);
                setLoadingQuotes(false)
            } else {
                toast(<Notification type={"consultar_error"} backMessage={res.data.message} />);
                setLoadingQuotes(false)
            }
        } catch (err) {
            toast(<Notification type={"consultar_servidor_error"} withIcon />);
            setLoadingQuotes(false)
        }
    }

    async function getApprovedDetails(idBudgetRequest) {
        const params = {
            action: "getApprovedDetails",
            idBudgetRequest: idBudgetRequest
        }
        try {
            const res = await API.peticionEndPoint(params, "app/facades/budgets/budgetsF.php");
            if (res.status === 200 && res.data.code === "200") {
                setApproved(res.data.data)
                setLoadingAproved(false)
            } else {
                toast(<Notification type={"consultar_error"} backMessage={res.data.message} />);
                setLoadingAproved(false)
            }
        } catch (err) {
            toast(<Notification type={"consultar_servidor_error"} withIcon />);
            setLoadingAproved(false)
        }
    }

    useEffect(() => {
        if (idQuote && idBudgetRequest && refresh) {
            getQuotesDetail(idQuote);
            getApprovedDetails(idBudgetRequest);
        }
    }, [refresh])

    useEffect(() => {
        if (idQuote && idBudgetRequest && visible) {
            setLoadingQuotes(true)
            setLoadingAproved(true)
            setRefresh(true)
            getQuotesDetail(idQuote);
            getApprovedDetails(idBudgetRequest);
            getPurchaseRequest()
        }
    }, [idQuote, idBudgetRequest, visible])

    const toggle = () => {
        if (deleteA) {
            setModalDel(true)
        } else {
            if (idPurchaseRequest === "" || !added) {
                setIdQuote("");
                setIdBudgetRequest("");
                setIdPurchaseRequest("");
            }
            setVisible(!visible)
        }
    };

    function onCancel() {
        setModalDel(false)
        setIdQuote("");
        setIdBudgetRequest("");
        setIdPurchaseRequest("");
        setVisible(!visible)
    }

    async function getDetails(idPurchaseRequest) {
        const params = {
            action: "multiselect",
            table: "purchaserequestdetail prd inner join quotesdetail qd on (prd.idDetail = qd.idDetail) left join services sv on (qd.idService=sv.idService) left join furnitures f on (f.idFurniture=qd.idFurniture) left join messureunits mu on (f.keyUnit=mu.keyUnit) left join servicetimes sti on (sv.keyServiceTime=sti.keyServiceTime) inner join expense_objects exo on (prd.idExpenseObject=exo.idExpenseObject)",
            rows: "prd.idRequestDetail,prd.amount,qd.amount as amountqt,if(isnull(sv.nameService),f.nameFurniture,sv.nameService) as descripcion,f.brand,if(isnull(mu.nameUnit),sti.nameServiceTime,mu.nameUnit) as unit,exo.fullKey,exo.nameObject",
            conditions: `prd.enabled=1 AND prd.idPurchaseRequest=${idPurchaseRequest}`
        }
        try {
            const res = await API.peticion(params);
            if (res.status === 200 && res.data.code === "200") {
                setDetails(res.data.data)
                if (res.data.data.length === 0) {
                    setDeleteA(true)
                } else {
                    setDeleteA(false)
                }
                setLoadingDetails(false);
                setRefresh(false)
            } else {
                setDetails([])
                setDeleteA(true)
                toast(<Notification type={"consultar_error"} backMessage={res.data.message} />);
                setLoadingDetails(false);
            }
        } catch (err) {
            setDetails([])
            setLoadingDetails(false);
            setDeleteA(true)
            toast(<Notification type={"consultar_servidor_error"} withIcon />);
        }
    }

    useEffect(() => {
        if (idPurchaseRequest !== "" && refresh) {
            setLoadingDetails(true);
            getDetails(idPurchaseRequest)
        }
    }, [idPurchaseRequest, refresh])

    async function getPurchaseRequest() {
        const params = {
            action: "multiselect",
            table: "purchaserequests",
            rows: "idPurchaseRequest",
            conditions: `keyStat=124 AND enabled=1 AND idBudgetRequest=${idBudgetRequest} AND idQuote=${idQuote}`
        }
        try {
            const res = await API.peticion(params);
            if (res.status === 200 && res.data.code === "200") {
                if (res.data.data.length > 0) {
                    setIdPurchaseRequest(res.data.data[0].idPurchaseRequest)
                    getDetails(res.data.data[0].idPurchaseRequest)
                } else {
                    check()
                }
            } else {
                check()
            }
        } catch (err) {
            console.log(err);
            toast(<Notification type={"consultar_servidor_error"} withIcon />);
        }
    }

    async function deleteModal(id) {
        const params = {
            action: "delete",
            table: "purchaserequestdetail",
            condition: {
                idRequestDetail: id
            },
        };
        try {
            const res = await API.peticion(params)
            if (res.status === 200 && res.data.code === "200") {
                toast(<Notification type={"elimina_exito"} backMessage={res.data.message} withIcon />);
                setRefresh(true)
            } else {
                toast(<Notification type={"elimina_error"} backMessage={res.data.message} withIcon />);
            }
        } catch (err) {
            toast(<Notification type={"elimina_error"} withIcon />);
        }
    }

    async function check() {
        const params = {
            action: "multiselect",
            table: "purchaserequests pr inner join quotes q using(idQuote) inner join budgetrequests br using(idBudgetRequest)",
            rows: "numberQuote,yearQuote,br.numberRequest,br.yearRequest,idBudgetRequest,idQuote",
            conditions: `pr.enabled=1 AND (pr.idBudgetRequest=${idBudgetRequest} or pr.idQuote=${idQuote})`
        }
        try {
            const res = await API.peticion(params);
            if (res.status === 200 && res.data.code === "200") {
                if (res.data.data.length === 1) {
                    const data = res.data.data[0];
                    if (data.idBudgetRequest !== idBudgetRequest || idQuote !== data.idQuote) {
                        toast(<Notification type={"consultar_error"} backMessage={`La cotizacion ${data.numberQuote}/${data.yearQuote} ya se encuentra ligada a la suficiencia ${data.numberRequest}/${data.yearRequest}`} withIcon />);
                        setVisible(false);
                        setIdQuote("")
                        setIdBudgetRequest("")
                    }
                } else if (res.data.data.length > 1) {
                    const data = res.data.data[0];
                    const data2 = res.data.data[1];
                    toast(<Notification type={"consultar_error"} backMessage={`La cotizacion ${data.numberQuote}/${data.yearQuote} ya se encuentra ligada a la suficiencia ${data.numberRequest}/${data.yearRequest} y la suficiencia ${data2.numberRequest}/${data2.yearRequest} ya se encuentra ligada con la cotizacion ${data2.numberQuote}/${data2.yearQuote}`} withIcon />);
                    setVisible(false);
                    setIdQuote("")
                    setIdBudgetRequest("")
                }
            }
        } catch (err) {
            console.log(err);
            toast(<Notification type={"consultar_servidor_error"} withIcon />);
        }
    }

    async function deletePurchaseRequest() {
        const params = {
            action: "delete",
            table: "purchaserequests",
            condition: {
                idPurchaseRequest: idPurchaseRequest
            },
        };
        try {
            const res = await API.peticion(params)
            if (res.status === 200 && res.data.code === "200") {
                toast(<Notification type={"elimina_exito"} backMessage={res.data.message} withIcon />);
            } else {
                toast(<Notification type={"elimina_error"} backMessage={res.data.message} withIcon />);
            }
        } catch (err) {
            toast(<Notification type={"elimina_error"} withIcon />);
        }
        setIdQuote("");
        setIdBudgetRequest("");
        setIdPurchaseRequest("")
        setVisible(!visible)
    }

    return (
        <Modal
            isOpen={visible}
            toggle={toggle}
            backdrop={"static"}
            keyboard
            size="xl"
            centered
        >
            <ModalHeader toggle={toggle}>
                Asignación
            </ModalHeader>
            {loadingQuotes || loadingDetails || loadingAproved ? (
                <div className="d-flex align-items-center justify-content-center" style={{ height: "200px" }}>
                    <Spinner
                        color="primary"
                        style={{
                            height: '3rem',
                            width: '3rem'
                        }}
                        type="grow"
                    >
                        Loading...
                    </Spinner>
                </div>
            ) : (
                <>

                    <ModalBody className="text-dark">
                        <Table striped hover className="border mb-3">
                            <thead>
                                <tr>
                                    <th>PARTIDAS PRESUPUESTALES</th>
                                    <th>BIENES Y/O SERVICIOS</th>
                                </tr>
                            </thead>
                            <tbody>
                                {approved.map(item => (
                                    <RowPartidas
                                        bienes={bienService}
                                        bienesOriginal={bienOriginal}
                                        idPurchaseRequest={idPurchaseRequest}
                                        idArea={idArea}
                                        idBudgetRequest={idBudgetRequest}
                                        idQuote={idQuote}
                                        item={item}
                                        setRefresh={setRefresh}
                                        setIdPurchaseRequest={setIdPurchaseRequest}
                                        setAdded={setAdded}
                                        getPartidas={getApprovedDetails}
                                    />
                                ))}
                            </tbody>
                        </Table>
                        <Table striped className="border">
                            <thead>
                                <tr>
                                    <th>DESCRIPCIÓN BIEN Y/O SERVICIO</th>
                                    <th>PROYECTO Y PARTIDA PRESUPUESTAL</th>
                                    <th>ACCIONES</th>
                                </tr>
                            </thead>
                            <tbody>
                                {details.map(row => (
                                    <tr>
                                        <td>
                                            Cantidad: {row.amountqt} <br />
                                            {row.descripcion} <br />
                                            Peridiocidad: {row.unit} <br />
                                            Costo: {CurrencyFormatter.format(row.amount)}
                                        </td>
                                        <td>
                                            Partida: {row.fullKey} - {row.nameObject}
                                        </td>
                                        <td>
                                            <ModalDelete
                                                modalTitle="Eliminar"
                                                table="purchaserequestdetail"
                                                nameColumn={["idRequestDetail"]}
                                                elimina={deleteModal}
                                                id={row.idRequestDetail}
                                            >
                                                <h6>
                                                    ¿Seguro de eliminar el registro?
                                                </h6>
                                                No podrá revertir la acción
                                            </ModalDelete>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </ModalBody>
                </>
            )
            }
            <ModalFooter className="d-flex justify-content-center">
                <Button onClick={toggle}>Aceptar</Button>
            </ModalFooter>
            <ModalConfirmation
                modalTitle={"Se han eliminado las asignaciones"}
                modal={modalDel}
                setModal={setModalDel}
                crear={deletePurchaseRequest}
                values={idPurchaseRequest}
                buttonOkMsg="Eliminar"
                centered
                cancelOverride={true}
                cancel={onCancel}
            >
                <div className="d-flex justify-content-center">
                    <h6 className="text-center">¿Desea eliminar la relación existente?<br />Esto permitira usar la cotizacion y suficiencia usadas previamente</h6>
                </div>
            </ModalConfirmation>
        </Modal >
    );
}
import React from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import es from "date-fns/locale/es";

registerLocale("es", es);
export const DatePickerInput = (props) => {
  const {
    label,
    inputName,
    onChangeMethod,
    onBlurMethod,
    value,
    isRequired,
    touched,
    errors,
    isDisabled,
    isMin,
    isMax
  } = props;
  return (
    <div className="form-group">
      <label htmlFor={inputName}>
        {label} {isRequired && <span className="text-danger">*</span>}
      </label>
      <DatePicker
        locale="es"
        name={inputName}
        id={inputName}
        minDate={isMin? new Date(`${isMin}`) : null}
        maxDate={isMax? new Date(`${isMax}`) : null}
        selected={value}
        onChange={(date) => onChangeMethod({ target: { name: inputName, value: date } })}
        onBlur={onBlurMethod}
        dateFormat="dd/MM/yyyy"
        className={" form-control " + (errors && touched? " is-invalid" : "")}
        disabled={isDisabled? true : undefined}
        placeholderText="dd/mm/yyyy"
      />
      {touched && errors && (
        <span style={{ color: "red" }}>{errors}</span>
      )}
    </div>
  );
};

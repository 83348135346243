import {
  AUTH_FAILURE,
  LOGIN_SUCCESS,
  LOGOUT_SUCCESS,
} from "../types"

const initialValues = {
  isFetching: false,
  errorMessage: '',
  currentUser: null,
  loadingInit: true
}

export default function auth(state = initialValues, { type, payload }) {
  switch (type) {
    case LOGIN_SUCCESS:
    case LOGOUT_SUCCESS:
      return {...state, isFetching: false}

    case AUTH_FAILURE:
      return {...state, isFetching: false, errorMessage: payload}

    default:
      return state;
  }
}
import { Col, Row } from "reactstrap";
import {
  RadioInput,
  SelectTypeHeadSingle,
} from "../../../../../components/GenericInputsFormik";

export const opt = [
  { name: "Sin detalle", val: "0" },
  { name: "Con detalle", val: "1" },
];

export default function RadioDetalle({
  handleBlur,
  touched,
  errors,
  values,
  setFieldValue,
}) {
  const onChange = (event) => {
    setFieldValue(event.target.name, event.target.value);
  };

  return (
    <RadioInput
      label="Detalle"
      inputName="detalle"
      onChangeMethod={onChange}
      onBlurMethod={handleBlur}
      value={values.detalle}
      optionsArray={opt}
      optionValue="val"
      optionName="name"
      touched={touched.detalle}
      errors={errors.detalle}
      isRequired
      isCenter
    />
  );
}

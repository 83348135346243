import { Row, Col, Button } from "reactstrap";
import { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";

import { SelectTypeHeadSingle } from "../../../components/GenericInputsFormik"
import Datatable from "../../../components/Datatable/Datatable";
import { GetPermisos } from "../../../libs/permissions/hook";
import Notification from "../../../components/Notification/Notification";
import { peticionEncript } from "../../../helpers/peticionesEncripted";
import { peticionesReceiver } from "../../../helpers/peticionesReceiver";
import ModalDatosPersonales from "./ModalDatos";
import Loader from "../../../components/Loader/Loader";
import { saveGeneralReport } from "./reportes/excelDatosPersonales";
import FullFormLoader from "../../../components/Loader/FullFormLoader";
import exportDatosPersonales from "./reportes/pdfDatosPersonales";
import ModalReports from "../../../components/Modal/ModalReports";
import Widget from "../../../components/Widget/Widget";

export default function ConsultaDatosPersonales() {
    const API = peticionesReceiver();
    const [loader, setLoader] = useState(false)
    const [modal, setModal] = useState(false);
    const [currStaff, setCurrStaff] = useState(null);

    //TABLA
    const headers = ["No. Empleado", "Nombre", "Familiares registrados", "Datos del empleo", "domicilio"]
    const columns = [
        {
            data: "staffNumber",
            render: function (data) {
                return data
            }
        },
        {
            data: null,
            orderValue: "nameStaff",
            render: function (data) {
                return (<>
                    <b>{data.nameStaff}</b><br />
                    <b>RFC: </b>{data.rfc}<br />
                    <b>CURP: </b>{data.curp}<br />
                    <b>FECHA DE NACIMIENTO: </b>{data.bornDate}<br />
                    <b>CORREO INSTITUCIONAL: </b>{data.jobMail}<br />
                    <b>CORREO: </b>{data.email}<br />
                    <b>TELEFONO: </b>{data.phone} {data.phoneExtension !== "" && `EXT: ${data.phoneExtension}`}<br />
                </>)
            }
        },
        {
            data: "datos",
            render: function (data) {
                const datos = data.split(",")
                const map = datos.map(fam => (
                    <>
                        {fam.split(":").map((it, index) => (<>{index === 0 ? (<b>{it.toUpperCase()}</b>) : (<>: {it}<br /></>)}</>))}
                    </>
                ))
                return map
            }
        },
        {
            data: null,
            orderValue: "nameRegion",
            render: function (data) {
                return (
                    <>
                        {data.nameRegion !== "" && (<><b>REGIÓN: </b>{data.nameRegion}<br /></>)}
                        {data.nameArea !== "" && (<><b>ADSCRIPCIÓN: </b>{data.nameArea}<br /></>)}
                        {data.nameJob !== "" && (<><b>PUESTO: </b>{data.nameJob} {data.level}-{data.rango}<br /></>)}
                        {data.startJobDate !== "" && (<><b>FECHA INGRESO: </b>{data.startJobDate}<br /></>)}
                        {data.nameBuilding !== "" && (<><b>EDIFICIO: </b>{data.nameBuilding}<br /></>)}
                    </>
                )
            }
        },
        {
            data: null,
            orderValue: "street",
            render: function (data) {
                const haveDom = data.street !== "" || data.extNumber !== "" || data.intNumber !== "" || data.nameCity !== "" || data.nameCounty !== "" || data.nameCountry !== "" || data.zipCode !== "";
                if (!haveDom) {
                    return "SIN INFORMACIÓN"
                }
                return (
                    <>
                        {data.street !== "" && <>CALLE: {data.street}</>} {data.intNumber !== "" && <>INT. {data.intNumber}</>} {data.extNumber !== "" && <>EXT. {data.extNumber}, </>}{data.nameCity !== "" && <>{data.nameCity}</>} {data.nameCounty !== "" && <><br />{data.nameCounty}, </>} {data.nameCountry !== "" && <>{data.nameCountry}</>} {data.zipCode !== "" && <>C.P.: {data.zipCode}</>}
                    </>
                )
            }
        }
    ]

    const columnDefs = [
        {
            targets: [0],
            className: "text-center"
        }
    ]

    const onDoubleClick = async (row) => {
        try {
            const idStaff = row.idStaff;
            if (idStaff) {
                const params = {
                    action: "getConsultaDatosDetalle",
                    idStaff
                }
                const res = await API.peticionEndPoint(params, "app/facades/reports/reportsF.php")
                if (res.status === 200 && res.data.code === "200") {
                    const data = res.data.data[0];
                    setCurrStaff(data);
                    setModal(true);
                } else {
                    toast(<Notification type={"consultar_error"} withIcon />);
                }
            }
        } catch (err) {
            toast(<Notification type={"consultar_error"} withIcon />);
        }
    }
    const [refresh, setRefresh] = useState(false);

    //FIELDS
    const [region, setRegion] = useState("");
    const [area, setArea] = useState("");
    const [puesto, setPuesto] = useState("");

    //DATA
    const [regions, setRegions] = useState([])
    const [loadingRegions, setLoadingRegions] = useState(true)
    const [areas, setAreas] = useState([])
    const [loadingAreas, setLoadingAreas] = useState(true)
    const [puestos, setPuestos] = useState([])
    const [loadingPuestos, setLoadingPuestos] = useState(true);
    const [loading, setLoading] = useState(true);

    const [permissionsForm, setPermissionsForm] = useState("");

    GetPermisos(setPermissionsForm);

    function onChange(e) {
        const { name, value } = e.target;
        switch (name) {
            case "keyRegion":
                setRegion(value);
                setRefresh(true);
                break;
            case "idArea":
                setArea(value);
                setRefresh(true);
                break;
            case "keyJob":
                setPuesto(value);
                setRefresh(true);
                break;
            default:
                break;
        }
    }

    function getParams(order, records, page, search) {
        const params = {
            action: "getConsultaDatos",
            order,
            records: records.toString(),
            page: (page * records).toString(),
            search
        }
        if (area !== "") {
            params.idArea = area;
        }
        if (region !== "") {
            params.keyRegion = region;
        }
        if (puesto !== "") {
            params.keyJob = puesto;
        }
        return params
    }
 
    async function getData(order, records, page, search) {
        const finalData = peticionEncript(getParams(order, records, page, search));
        const route = "app/facades/reports/reportsF.php"
        try {
            const res = await axios.post(`${process.env.REACT_APP_API}${route}`, finalData)
            if (res.status === 200 && res.data.code === "200") {
                return res.data;
            } else {
                toast(<Notification type={"consultar_error"} backMessage={res.data.message} />);
                return null;
            }
        } catch (err) {
            toast(<Notification type={"consultar_servidor_error"} withIcon />);
            return null
        }
    }

    async function getDataRegions() {
        const params = {
            action: "multiselect",
            table: "regions",
            rows: "keyRegion,nameRegion",
            conditions: `enabled = 1`,
        };
        const res = await API.peticion(params)
        if (res.status === 200 && res.data.code === "200") {
            const data = res.data.data;
            setRegions(data)
            setLoadingRegions(false)
        } else {
            setRegions([])
            toast(<Notification type={"consultar_error"} withIcon />);
        }
    }

    async function getDataAreas() {
        const params = {
            action: "multiselect",
            table: "areas",
            rows: "idArea,name",
            conditions: `enabled = 1`,
        };
        const res = await API.peticion(params)
        if (res.status === 200 && res.data.code === "200") {
            const data = res.data.data;
            setAreas(data)
            setLoadingAreas(false)
        } else {
            setAreas([])
            toast(<Notification type={"consultar_error"} withIcon />);
        }
    }

    async function getDataJobs() {
        const params = {
            action: "multiselect",
            table: "jobs",
            rows: "keyJob,nameJob",
            conditions: `enabled = 1`,
        };
        const res = await API.peticion(params)
        if (res.status === 200 && res.data.code === "200") {
            const data = res.data.data;
            setPuestos(data)
            setLoadingPuestos(false)
        } else {
            setPuestos([])
            toast(<Notification type={"consultar_error"} withIcon />);
        }
    }

    useEffect(() => {
        getDataAreas();
        getDataRegions();
        getDataJobs();
    }, [])

    useEffect(() => {
        setLoading(loadingAreas || loadingPuestos || loadingRegions)
    }, [loadingAreas, loadingPuestos, loadingRegions])

    const genExcel = async () => {
        const params = {
            action: "getConsultaDatos"
        };
        setLoader(true)
        try {
            const res = await API.peticionEndPoint(params, "app/facades/reports/reportsF.php");
            if (res.status === 200 && res.data.code === "200") {
                const config = {
                    headers: ["No", "No. Empleado", "Nombre", "RFC", "CURP", "Contacto", "Puesto y categoria", "Adscripcion", "Región", "Domicilio", "Fecha Ingreso", "Fecha Nacimiento"],
                    sizes: [10, 20, 30, 20, 30, 50, 40, 40, 40, 40, 40, 20],
                    content: [
                        { header: "No", index: true },
                        { header: "No. Empleado", value: "staffNumber", format: false },
                        { header: "Nombre", value: "nameStaff", format: false },
                        { header: "RFC", value: "rfc", format: false },
                        { header: "CURP", value: "curp", format: false },
                        { header: "Contacto", value: "contact", format: false },
                        { header: "Puesto y categoria", value: "job", format: false },
                        { header: "Adscripcion", value: "nameArea", format: false },
                        { header: "Región", value: "nameRegion", format: false },
                        { header: "Domicilio", value: "address", format: false },
                        { header: "Fecha Ingreso", value: "startJobDate", format: false },
                        { header: "Fecha Nacimiento", value: "bornDate", format: false },
                    ]
                }
                setLoader(false)
                saveGeneralReport(res.data.data, "Consulta de datos personales", null, config)
            } else {
                setLoader(false)
                toast(<Notification type={"consultar_error"} backMessage={res.data.message} />);
            }
        } catch (err) {
            setLoader(false)
            toast(<Notification type={"consultar_error"} />);
        }
    }

    const [report, setReport] = useState(null)
    const [modalReports, setModalReports] = useState(false)
    const PDFconfig = {
        headers: ["No", "No. Empleado", "Nombre", "Contacto", "Puesto y categoria", "Adscripcion", "Región", "Domicilio", "Fecha Ingreso", "Fecha Nacimiento"],
        orientation: "h",
        sizes: [5, 5, 10, 15, 15, 15, 15, 10, 5, 5],
        content: [
            { header: "No", index: true },
            { header: "No. Empleado", value: "staffNumber" },
            { header: "Nombre", value: "name" },
            { header: "Contacto", value: "contact" },
            { header: "Puesto y categoria", value: "job" },
            { header: "Adscripcion", value: "nameArea" },
            { header: "Región", value: "nameRegion" },
            { header: "Domicilio", value: "address" },
            { header: "Fecha Ingreso", value: "startJobDate" },
            { header: "Fecha Nacimiento", value: "bornDate" },
        ]
    }

    const genPdf = async () => {
        const params = {
            action: "getConsultaDatos"
        };
        setReport(null)
        setLoader(true)
        try {
            const res = await API.peticionEndPoint(params, "app/facades/reports/reportsF.php");
            if (res.status === 200 && res.data.code === "200") {
                setReport(res.data.data);
                setLoader(false)
                setModalReports(true)
            } else {
                setLoader(false)
                toast(<Notification type={"consultar_error"} backMessage={res.data.message} />);
            }
        } catch (err) {
            setLoader(false)
            toast(<Notification type={"consultar_error"} />);
        }
    }

    function cleanSelects() {
        setArea("");
        setPuesto("");
        setRegion("");
        setRefresh(true);
    }

    if (loading) {
        return (
            <div className="d-flex align-items-center justify-content-center" style={{ height: "200px" }}>
                <Loader />
            </div>
        )
    }
    return (
        <>
            <Widget className="widget-p-md">
                <h2>Consulta de datos personales</h2>
                <h6>Secretaria general de acuerdos</h6>
                <span className="mt-2" style={{ fontSize: "small" }}><b>Instrucciones.</b> Utilice los filtros de busqueda para consultar los empleados puede dar doble click sobre un registro para ver los detalles del empleado</span>
                <Row>
                    <Col xs={12} md={4}>
                        <SelectTypeHeadSingle
                            label="Región"
                            isRequired={true}
                            optionsArray={regions}
                            inputName="keyRegion"
                            onChangeMethod={onChange}
                            optionValue="keyRegion"
                            optionName="nameRegion"
                            value={region}
                        />
                    </Col>
                    <Col xs={12} md={4}>
                        <SelectTypeHeadSingle
                            label="Aréa"
                            isRequired={true}
                            optionsArray={areas}
                            inputName="idArea"
                            onChangeMethod={onChange}
                            optionValue="idArea"
                            optionName="name"
                            value={area}
                        />
                    </Col>
                    <Col xs={12} md={4}>
                        <SelectTypeHeadSingle
                            label="Puesto"
                            isRequired={true}
                            optionsArray={puestos}
                            inputName="keyJob"
                            onChangeMethod={onChange}
                            optionValue="keyJob"
                            optionName="nameJob"
                            value={puesto}
                        />
                    </Col>
                </Row>
                <div className="d-flex justify-content-md-end flex-md-row flex-column">
                    <Button color="warning" className="mx-sm-0 mx-md-1 my-2" onClick={cleanSelects}>Limpiar</Button>
                    <Button color="secondary" className="mx-sm-0 mx-md-1 my-2" onClick={genExcel}>Generar Excel</Button>
                    <Button color="secondary" className="mx-sm-0 mx-md-1 my-2" onClick={genPdf}>Generar PDF</Button>
                </div>

                {permissionsForm.CON === "1" && (
                    <Datatable
                        className="mt-3"
                        headers={headers}
                        petition={getData}
                        columns={columns}
                        columnDefs={columnDefs}
                        control='back'
                        key={"table"}
                        eventsRow={{ onDoubleClick }}
                        stateRefresh={[refresh, setRefresh]}
                    />
                )}

                <FullFormLoader
                    show={loader}
                    message="Consultando datos, espere un momento"
                />
                {report !== null &&
                    <ModalReports
                        modal={modalReports}
                        setModal={setModalReports}
                        title={"Consulta de datos personales"}
                        backdrop={"static"}
                        keyboard={false}
                        report={report}
                        pdfConfig={PDFconfig}
                        generatePdfMethod={{
                            method: exportDatosPersonales,
                            type: "DatosPersonales"
                        }}
                        records={1000}
                    />
                }

                {currStaff &&
                    <ModalDatosPersonales
                        modal={modal}
                        setModal={setModal}
                        staff={currStaff}
                    />
                }
            </Widget>
        </>
    )
}
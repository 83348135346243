import { useEffect, useRef, useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import ModalConfirmation from "../../../components/Modal/ModalConfirmation";
import { Alert, Button, Col, Collapse, Row, Spinner } from "reactstrap";
import {
  SelectTypeHeadSingle,
  TextInput,
} from "../../../components/GenericInputsFormik";
import { doRequest } from "../../../helpers/requests";
import { NumberInput } from "../../../components/GenericInputsFormik/NumberInput";
import FullFormLoader from "../../../components/Loader/FullFormLoader";
import Geocode from "react-geocode";
import { toast } from "react-toastify";
import { Marker, GoogleMap, useJsApiLoader } from "@react-google-maps/api";
import {
  getCiudades,
  getEstados,
  getMunicipios,
  getPaises,
} from "../../personal/expediente-personal/functionsLocation";
import TableComponentCustom from "../../../components/TableComponent/TableComponentCustom";
import Notification from "../../../components/Notification/Notification";

const gmap = process.env.REACT_APP_GMAP;

const containerStyle = {
  width: "700px",
  height: "400px",
};

const centerMx = {
  lat: 20.0,
  lng: -100.0,
};

const cabeceras = [
  "Id",
  "País",
  "Estado",
  "Municpio",
  "Localidad",
  "Calle",
  "Colonia",
  "Estatus",
  "Modificar",
];
const filtro = [true, true, true, true, true, true, true, true];

const defaultForm = {
  idProviderAdress: "",
  keyCity: "",
  street: "",
  subStreet: "",
  intNumber: "",
  extNumber: "",
  zipCode: "",
  lat: "",
  long: "",
  /*doesnt required*/
  keyCountry: "151",
  keyState: "",
  keyCounty: "",
};

const FormSchema = Yup.object().shape({
  keyCountry: Yup.string().required("Seleccione un país"),
  keyState: Yup.string().required("Seleccione una ciudad"),
  keyCounty: Yup.string().required("Seleccione un municipio"),
  keyCity: Yup.string().required("Seleccione una localidad"),
  street: Yup.string()
    .min(3, "La calle debe tener al menos 3 caracteres")
    .max(100, "El nombre de la calle es muy largo")
    .required("Ingrese una calle"),
  subStreet: Yup.string()
    .min(3, "La colinia debe tener al menos 3 caracteres")
    .max(100, "El nombre de la colinia es muy largo")
    .required("Ingrese una colinia"),
  extNumber: Yup.string()
    .max(45, "Número exterior demasiado largo")
    .required("Ingrese un número exterior"),
  zipCode: Yup.string()
    .min(3, "Código postal inválido")
    .max(10, "Código postal inválido")
    .required("Ingrese un código postal"),
  lat: Yup.number().required(
    "No se ha establecido una localización en el mapa"
  ),
});

const Domicilio = ({ idProvider, permissions }) => {
  const [modalConfirm, setModalConfirm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openForm, setOpenForm] = useState(false);
  const [showMap, setShowMap] = useState(false);
  const [title, setTitle] = useState("");
  const [selectsData, setSelectsData] = useState({
    paisesData: [],
    estadosData: [],
    municipiosData: [],
    ciudadesData: [],
  });
  const [parametros, setParametros] = useState({
    action: "datatable",
    table:
      "provideradress A JOIN cities B USING (keyCity) JOIN counties C USING (keyCounty) JOIN states D USING (keyState) JOIN countries E USING (keyCountry)",
    rows: "A.idProviderAdress, E.nameCountry, D.nameState, C.nameCounty, B.nameCity, A.street, A.subStreet, IF(A.enabled=0,'INACTIVO','ACTIVO') AS edo, A.enabled,B.keyCity,C.keyCounty, D.keyState, E.keyCountry, A.zipCode, A.extNumber, A.intNumber, A.latitude, A.longitude",
    conditions: `A.idProvider = ${idProvider}`,
    page: 0,
    records: 5,
    search: "",
    order: "7 desc",
  });
  const [center, setCenter] = useState(centerMx);
  const [marker, setMarker] = useState();
  const [zoom, setZoom] = useState(15);
  const { isLoaded, loadError } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: gmap,
  });
  const refs = [
    useRef(null), //select contry
    useRef(null), //select state
    useRef(null), //select county
    useRef(null), //select 1city
    useRef(null), //To scroll
    useRef(null), //mapRef
  ];

  const {
    handleSubmit,
    values,
    handleBlur,
    errors,
    touched,
    setFieldValue,
    handleReset,
  } = useFormik({
    initialValues: defaultForm,
    validationSchema: FormSchema,
    onSubmit: (values) => setModalConfirm(true),
    onReset: () => {
      setMarker();
      setCenter(centerMx);
      setTitle("");
      setShowMap(false);
    },
    enableReinitialize: true,
  });

  useEffect(() => {
    getPaises(setSelectsData).then(() =>
      onChange({ target: { name: "keyCountry", value: "151" } })
    );
    Geocode.setApiKey(gmap);
    Geocode.setLocationType("ROOFTOP");
    Geocode.setRegion("mx");
  }, []);

  const onChange = (e) => {
    if (e.target.name === "keyCountry") {
      setFieldValue("keyState", "");
      setFieldValue("keyCounty", "");
      setFieldValue("keyCity", "");
      getEstados(e.target.value, setSelectsData);
    } else if (e.target.name === "keyState") {
      setFieldValue("keyCounty", "");
      setFieldValue("keyCity", "");
      getMunicipios(e.target.value, setSelectsData);
    } else if (e.target.name === "keyCounty") {
      setFieldValue("keyCity", "");
      getCiudades(e.target.value, setSelectsData);
    }
    setFieldValue(e.target.name, e.target.value);
  };

  const editInfo = async () => {
    const params =
      values.idProviderAdress === ""
        ? {
            ...values,
            idProvider: idProvider,
            action: "saveProviderAdress",
          }
        : {
            action: "update",
            table: "provideradress",
            rows: { ...values, latitude: values.lat, longitude: values.long },
            condition: { idProviderAdress: values.idProviderAdress },
            validate: [
              [
                // "idProvider",
                "street",
                "subStreet",
                "extNumber",
                "zipCode",
                "lat",
                "long",
              ],
            ],
          };
    let rute = "";
    if (values.idProviderAdress === "") {
      rute = "app/facades/inventory/inventoryF.php";
    } else {
      rute = "receiver/receiver.php";
      delete params.rows.lat;
      delete params.rows.long;
      delete params.rows.keyCountry;
      delete params.rows.keyState;
      delete params.rows.keyCounty;
    }
    setLoading(true);
    const data = await doRequest(rute, params, true);
    if (data.length > 0) {
      setParametros({ ...parametros });
      setOpenForm(false);
    }
    setLoading(false);
  };

  const searchLocation = async () => {
    if (
      values.keyState &&
      values.keyCounty &&
      values.keyCity &&
      values.subStreet &&
      values.street &&
      values.zipCode &&
      values.extNumber
    ) {
      const address =
        values.street +
        " " +
        values.extNumber +
        ", " +
        values.subStreet +
        ", " +
        values.zipCode +
        " " +
        refs[3].current.state.text +
        ", " +
        refs[2].current.state.text +
        ", " +
        refs[1].current.state.text;
      // console.log(address);
      await Geocode.fromAddress(address).then(
        (response) => {
          if (response.results.length !== 0) {
            const location = response.results[0].geometry.location;
            setFieldValue("lat", location.lat);
            setFieldValue("long", location.lng);
            setMarker(location);
            setCenter(location);
          } else {
            toast(
              <Notification
                type={"modifica_error"}
                backMessage="No se encontraron resultados"
                withIcon
              />,
              {
                closeButton: false,
              }
            );
          }
        },
        (error) => {
          toast(
            <Notification
              type={"modifica_error"}
              backMessage="No se encontraron ubicaciones"
              withIcon
            />,
            {
              closeButton: false,
            }
          );
        }
      );
    } else {
      toast(
        <Notification
          type={"warning"}
          backMessage="Selecione un país, estado, municipio, localidad, colonia y calle"
          withIcon
        />,
        {
          closeButton: false,
        }
      );
    }
  };

  const onClick = (event) => {
    const location = event.latLng.toJSON();
    setFieldValue("lat", location.lat);
    setFieldValue("long", location.lng);
    setMarker(location);
    setZoom(15);
  };

  const renderMap = () => {
    return (
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        zoom={zoom}
        onClick={onClick}
      >
        <Marker position={marker} />
      </GoogleMap>
    );
  };

  const scrollTo = () => {
    refs[4].current.scrollIntoView({ behavior: "smooth" });
  };

  const getForEdit = (el) => {
    setFieldValue("idProviderAdress", el[0]);
    setFieldValue("street", el[5]);
    setFieldValue("subStreet", el[6]);
    setFieldValue("zipCode", el[13]);
    setFieldValue("extNumber", el[14]);
    setFieldValue("intNumber", el[15] || "");

    setCenter({ lat: +el[16], lng: +el[17] });
    setMarker({ lat: +el[16], lng: +el[17] });
    setFieldValue("lat", +el[16]);
    setFieldValue("long", +el[17]);
    setTitle("Editar Domicilio");
    setShowMap(true);

    onChange({ target: { name: "keyCountry", value: el[12] } });
    onChange({ target: { name: "keyState", value: el[11] } });
    onChange({ target: { name: "keyCounty", value: el[10] } });
    onChange({ target: { name: "keyCity", value: el[9] } });
    setOpenForm(true);
  };

  const ActionCol = (el) => {
    if (permissions.UPD) {
      return (
        <div>
          {/* {el[7] === "ACTIVO" ? ( */}
          {el[7] === "ACTIVO" && (
            <i
              className={"fa fa-pencil text-warning"}
              style={{ cursor: "pointer" }}
              onClick={() => getForEdit(el)}
            />
          )}
          {/* ) : (
        <ModalComponentEliminar
          modalTitle="Eliminar"
          id={registro[0]}
          table=""
          nameColumn={nameColumn}
          getData={getData}
        >
          <h6>
            ¿Seguro de eliminar este domicilio"?
          </h6>
          No podrá recuperar la información después de ser eliminada
        </ModalComponentEliminar>
      )} */}
        </div>
      );
    }
  };

  const openToCreate = () => {
    setOpenForm(true);
    setTitle("Agregar Cuenta");
    setShowMap(true);
  };

  return (
    <div className="mx-4">
      <TableComponentCustom
        cabecerasTabla={cabeceras}
        filtro={filtro}
        parametros={parametros}
        specialColumns={[ActionCol]}
        hiddeTitle
        defaultOrder="9 desc"
      />

      {permissions.INS && (
        <Row className="mb-1">
          <Col xs={12} md={6} className="text-center text-md-left">
            {openForm && <h5>{title}</h5>}
          </Col>
          <Col xs={12} md={6} className="text-center text-md-right mt-2">
            <Button color="add" onClick={() => openToCreate()}>
              Agregar
            </Button>
          </Col>
        </Row>
      )}

      <Collapse
        isOpen={openForm}
        onExited={() => handleReset()}
        onEntered={() => scrollTo()}
        className=""
      >
        <form
          onSubmit={handleSubmit}
          onReset={handleReset}
          className="mt-3 mb-2"
        >
          <ModalConfirmation
            modalTitle="Guardar proveedor"
            modal={modalConfirm}
            setModal={setModalConfirm}
            editar={() => editInfo()}
            isEdit={true}
          >
            <div className="d-flex justify-content-center text-center">
              <h5>
                {values.idProviderAdress === ""
                  ? "¿Esta seguro de guardar este domicilio?"
                  : "¿Está seguro de actualizar la información de este domicilio?"}
              </h5>
            </div>
          </ModalConfirmation>

          <Row>
            <Col xs={12} lg={4}>
              <SelectTypeHeadSingle
                label="País"
                isRequired={true}
                inputName="keyCountry"
                optionsArray={selectsData.paisesData}
                defaultOption="Seleccionar un país"
                onChangeMethod={onChange}
                onBlurMethod={handleBlur}
                value={values.keyCountry}
                touched={touched.keyCountry}
                errors={errors.keyCountry}
                optionValue="keyCountry"
                optionName="nameCountry"
                refSelect={refs[0]}
              />
            </Col>
            <Col xs={12} lg={4}>
              <SelectTypeHeadSingle
                label="Estado"
                isRequired={true}
                inputName="keyState"
                optionsArray={selectsData.estadosData}
                defaultOption="Seleccionar un estado"
                onChangeMethod={onChange}
                onBlurMethod={handleBlur}
                isDisabled={
                  values.keyCountry === "" ||
                  selectsData.estadosData.length === 0
                }
                value={values.keyState}
                touched={touched.keyState}
                errors={errors.keyState}
                optionValue="keyState"
                optionName="nameState"
                refSelect={refs[1]}
              />
            </Col>
            <Col xs={12} lg={4}>
              <SelectTypeHeadSingle
                label="Municipio"
                isRequired={true}
                inputName="keyCounty"
                optionsArray={selectsData.municipiosData}
                defaultOption="Seleccionar un municipio"
                onChangeMethod={onChange}
                onBlurMethod={handleBlur}
                isDisabled={
                  values.keyState === "" ||
                  selectsData.municipiosData.length === 0
                }
                value={values.keyCounty}
                touched={touched.keyCounty}
                errors={errors.keyCounty}
                optionValue="keyCounty"
                optionName="nameCounty"
                refSelect={refs[2]}
              />
            </Col>
            <Col xs={12} lg={4}>
              <SelectTypeHeadSingle
                label="Localidad"
                isRequired={true}
                inputName="keyCity"
                optionsArray={selectsData.ciudadesData}
                defaultOption="Selecciona una ciudad"
                onChangeMethod={onChange}
                onBlurMethod={handleBlur}
                isDisabled={
                  values.keyCounty === "" ||
                  selectsData.ciudadesData.length === 0
                }
                value={values.keyCity}
                touched={touched.keyCity}
                errors={errors.keyCity}
                optionValue="keyCity"
                optionName="nameCity"
                refSelect={refs[3]}
              />
            </Col>
            <Col xs={12} lg={4}>
              <TextInput
                label="Calle"
                inputType="text"
                inputName="street"
                onChangeMethod={onChange}
                onBlurMethod={handleBlur}
                value={values.street}
                touched={touched.street}
                errors={errors.street}
                isRequired
              />
            </Col>
            <Col xs={12} lg={4}>
              <TextInput
                label="Colonia"
                inputType="text"
                inputName="subStreet"
                onChangeMethod={onChange}
                onBlurMethod={handleBlur}
                value={values.subStreet}
                touched={touched.subStreet}
                errors={errors.subStreet}
                isRequired
              />
            </Col>
            <Col xs={12} lg={4}>
              <NumberInput
                label="Código Postal"
                inputType="text"
                inputName="zipCode"
                onChangeMethod={onChange}
                onBlurMethod={handleBlur}
                value={values.zipCode}
                touched={touched.zipCode}
                errors={errors.zipCode}
                isRequired
                maxLength={10}
                disableSep
              />
            </Col>
            <Col xs={12} lg={4}>
              <TextInput
                label="No. Exterior"
                inputType="text"
                inputName="extNumber"
                onChangeMethod={onChange}
                onBlurMethod={handleBlur}
                value={values.extNumber}
                touched={touched.extNumber}
                errors={errors.extNumber}
                isRequired
              />
            </Col>
            <Col xs={12} lg={4}>
              <NumberInput
                label="No. Interior"
                inputType="text"
                inputName="intNumber"
                onChangeMethod={onChange}
                onBlurMethod={handleBlur}
                value={values.intNumber}
                touched={touched.intNumber}
                errors={errors.intNumber}
                maxLength={10}
                disableSep
              />
            </Col>
          </Row>

          <div className="d-flex justify-content-center">
            <Button
              color="info"
              onClick={() => searchLocation()}
              className="position-relative"
            >
              <i
                className="eva eva-pin-outline"
                style={{ fontSize: "20px", verticalAlign: "top" }}
              />
              Ubicar
            </Button>
          </div>
          <div>
            {errors.lat && touched.lat && (
              <span className="text-danger">
                Ubique o seleccione un punto en el mapa
              </span>
            )}
          </div>
          <div className="d-flex justify-content-center mt-4">
            {loadError ? (
              <div>Ocurrio un error al cargar el mapa</div>
            ) : isLoaded ? (
              showMap && renderMap()
            ) : (
              <Spinner color="primary" />
            )}
          </div>
          <p className="label text-center mt-1">
            Para mayor precisión, seleccione País, Estado, Municipio, Localidad,
            Colonia, Calle, No. exterior y Código Postal
          </p>

          <Row className="mt-5">
            <div
              className="col-sm-6 order-2 order-sm-1 text-center text-sm-left mb-4"
              ref={refs[4]}
            >
              <Button
                color="danger"
                type="reset"
                onClick={() => {
                  setOpenForm(false);
                }}
              >
                Cancelar
              </Button>
            </div>
            <div className="col-sm-6 order-1 order-sm-2 text-center text-sm-right mb-4">
              <Button color="success" type="submit">
                Guardar
              </Button>
            </div>
          </Row>
        </form>
      </Collapse>

      <FullFormLoader show={loading} />
    </div>
  );
};

export default Domicilio;

import React, { useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Button, Row, Col } from "reactstrap";
import ModalConfirmation from "../../../../../components/Modal/ModalConfirmation";
import { NumberInput } from "../../../../../components/GenericInputsFormik/NumberInput";
import {
  TextInput,
  SelectTypeHeadSingle,
} from "../../../../../components/GenericInputsFormik/index";
import ModalTableVer from "../../../../../components/Modal/ModalTableVer";
import { CancelOutlined } from "@material-ui/icons";
import s from "./InputClear.module.scss";
import { useEffect } from "react";
import { peticionesReceiver } from "../../../../../helpers/peticionesReceiver";
import { toast } from "react-toastify";
import Notification from "../../../../../components/Notification/Notification";
import Loader from "../../../../../components/Loader/Loader";
import { TextAreaInput } from "../../../../../components/GenericInputsFormik/index";

const FormTickets = ({
  permissions,
  idAreaLogged,
  setCollapse,
  setRefreshP,
  ticketForEdit,
}) => {
  const [modalConfirmation, setModalConfirmation] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [countLoadings, setCountLoadings] = useState(0);
  const [areas, setAreas] = useState([]);
  const [severities, setSeverities] = useState([]);
  const [topics, setTopics] = useState([]);
  const [staffSelected, setStaffSelected] = useState("");
  const [errorName, setErrorName] = useState(true);
  const API = peticionesReceiver();

  const FormSchema = Yup.object().shape({
    keyTopic: Yup.string().required("Seleccione un tipo de tema"),
    keySeverity: Yup.string().required("Seleccione un tipo de severidad"),
    phone: Yup.string()
      .required("Ingrese un numero")
      .max(10, "El numero de telefono debe contener 10 digitos")
      .min(10, "El numero de telefono debe contener 10 digitos"),
    email: Yup.string()
      .required("Ingrese un correo electronico")
      .email("Seleccione un correo valido"),
    title: Yup.string().required("Ingrese un titulo para el ticket"),
    description: Yup.string().required("Ingrese una descripcion"),
  });

  const getAreas = async () => {
    const data = {
      action: "multiselect",
      table: "areas",
      rows: "idArea, name",
      conditions: `enabled = 1 and idArea = ${idAreaLogged}`,
      order: "name ASC",
    };
    await API.peticion(data)
      .then((res) => {
        if (res.status === 200 && res.data.code === "200") {
          setAreas(res.data.data);
        } else {
          toast(<Notification type={"consultar_error"} withIcon />);
        }
      })
      .catch((err) => {
        toast(<Notification type={"consultar_servidor_error"} withIcon />);
      });
    setCountLoadings(countLoadings + 1);
  };
  const getSeverities = async () => {
    const data = {
      action: "multiselect",
      table: "types_severity",
      rows: "keySeverity, nameSeverity",
      conditions: `enabled = 1 `,
      order: "nameSeverity ASC",
    };
    await API.peticion(data)
      .then((res) => {
        if (res.status === 200 && res.data.code === "200") {
          setSeverities(res.data.data);
        } else {
          toast(<Notification type={"consultar_error"} withIcon />);
        }
      })
      .catch((err) => {
        toast(<Notification type={"consultar_servidor_error"} withIcon />);
      });
    setCountLoadings(countLoadings + 1);
  };
  const getTopics = async () => {
    const data = {
      action: "multiselect",
      table: "topics",
      rows: "keyTopic, nameTopic",
      conditions: `enabled = 1 `,
      order: "nameTopic ASC",
    };
    await API.peticion(data)
      .then((res) => {
        if (res.status === 200 && res.data.code === "200") {
          setTopics(res.data.data);
        } else {
          toast(<Notification type={"consultar_error"} withIcon />);
        }
      })
      .catch((err) => {
        toast(<Notification type={"consultar_servidor_error"} withIcon />);
      });
    setCountLoadings(countLoadings + 1);
  };

  const {
    handleSubmit,
    values,
    errors,
    setFieldValue,
    touched,
    handleBlur,
  } = useFormik({
    initialValues: {
      keyTopic: "",
      keySeverity: "",
      idArea: "",
      nameReporter: "",
      idStaff: "",
      phone: "",
      email: "",
      title: "",
      description: "",
    },
    onSubmit: () => {
      if (!errorName) {
        setModalConfirmation(true);
      }
    },
    validationSchema: FormSchema,
    enableReinitialize: true,
  });

  const onChange = (e) => {
    const { name, value } = e.target;
    if (name === "checkEmpleados") {
      setFieldValue(
        "checkEmpleados",
        values.checkEmpleados === "1" ? "0" : "1"
      );

      if (values.checkEmpleados === "1") {
        setFieldValue("phone", "");
        setFieldValue("email", "");
      } else {
        setFieldValue("phone", staffSelected.phone);
        setFieldValue("email", staffSelected.email);
      }
    } else {
      setFieldValue([name], value);
      if (name === "nameReporter") {
        validateName(value, "nameReporter");
      }
    }
  };

  const saveTicket = async () => {
    
    const data =
      !isEdit === true
        ? {
            action: "newTicket",
            keyTopic: values.keyTopic,
            keySeverity: values.keySeverity,
            idArea: idAreaLogged,
            nameReporter: values.idStaff !== "" ? "" : values.nameReporter,
            idStaff: values.nameReporter !== "" ? "" : values.idStaff,
            phoneContact: values.phone,
            emailContact: values.email,
            title: values.title,
            description: values.description,
          }
        : {
            action: "update",
            table: "tickets",
            rows: {
              keyTopic: values.keyTopic,
              keySeverity: values.keySeverity,
              idArea: idAreaLogged,
              nameReporter: values.idStaff !== "" ? "" : values.nameReporter,
              idStaff: values.nameReporter !== "" ? "" : values.idStaff,
              phoneContact: values.phone,
              emailContact: values.email,
              title: values.title,
              description: values.description,
            },
            condition: {
              idTicket: ticketForEdit.idTicket,
            },
          };
    const res = await API.peticionEndPoint(
      data,
      data.action === "newTicket"
        ? "app/facades/helpdesk/helpdeskF.php"
        : "receiver/receiver.php"
    );
    if (res.status === 200 && res.data.status === "success") {
      toast(
        <Notification
          type={"agrega_exito"}
          backMessage={res.data.message}
          withIcon
        />
      );
      setCollapse("home");
      setRefreshP(true);
      //   if (editando) {
      //     setRefreshP(true);
      //     setData(res.data.data[0]);
      //   } else {
      //     setRefreshP(true);
      //     setData(res.data.data[0]);
      //   }
      /* setRefreshP(true);
      resetFormuario(); */
    } else {
      toast(
        <Notification
          type={"agrega_error"}
          backMessage={res.data.message}
          withIcon
        />
      );
    }
    
  };

  //-------------ModalTableVer

  const [modalTabla, setModalTabla] = useState(false);
  const filtroModalTabla = [true, true, true, true, true];
  const cabecerasModalTabla = [
    "#",
    "Núm. Empleado",
    "Nombre",
    "Unidad de trabajo",
    "Puesto",
    "Seleccionar",
  ];
  const [paramsModalTabla] = useState({
    action: "datatable",
    table:
      "staff STF INNER JOIN employtypes AS EMT ON STF.keyEmployType = EMT.keyEmployType INNER JOIN jobstaff AS JBS ON JBS.idStaff = STF.idStaff INNER JOIN areas AS AR ON AR.idArea = JBS.idArea INNER JOIN jobtypes AS JBT ON JBT.keyJobType = STF.keyEmployType",
    rows: "STF.idStaff,STF.staffNumber,CONCAT(STF.name,' ',STF.pName,' ',STF.mName) as fullName,AR.name,JBT.nameJobType, STF.jobNumber,STF.jobMail",
    conditions: `STF.enabled = 1 AND JBS.valid = 1 AND STF.keyStat = 1 AND JBS.enabled = 1 and JBS.idArea = ${idAreaLogged}`,
    page: 0,
    records: 5,
    search: "",
    order: "",
  });

  const selectEmpleado = (el) => {
    setStaffSelected({
      idStaff: el[0],
      nameStaff: el[1],
      phone: el[5],
      email: el[6],
    });

    setFieldValue("idStaff", el[0]);
    setFieldValue("nameStaff", `${el[1]} - ${el[2]}`);
    setModalTabla(false);
    validateName(el[0], "idStaff");

    setFieldValue("checkEmpleados", "0");
    setFieldValue("phone", "");
    setFieldValue("email", "");
  };

  function handleClearStaff() {
    setStaffSelected("");
    validateName("", "idStaff");
    setFieldValue("nameStaff", "");
    setFieldValue("idStaff", "");
  }

  const validateName = (value, type) => {
    
    if (type === "nameReporter") {
      if (
        (values.idStaff && values.idStaff !== "") ||
        (value && value !== "")
      ) {
        
        setErrorName(false);
      } else {
        
        setErrorName(true);
      }
    } else if (type === "idStaff") {
      if (
        (values.nameReporter && values.nameReporter !== "") ||
        (value && value !== "")
      ) {
        
        setErrorName(false);
      } else {
        
        setErrorName(true);
      }
    } else if (type === "general") {
      if (
        (values.nameReporter && values.nameReporter !== "") ||
        (values.idStaff && values.idStaff !== "")
      ) {
        
        setErrorName(false);
      } else {
        
        setErrorName(true);
      }
    }
  };

  useEffect(() => {
    
    getAreas();
    getSeverities();
    getTopics();

    if (ticketForEdit && ticketForEdit !== "") {
      setDataForEdit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setDataForEdit = () => {
    setIsEdit(true);

    setFieldValue("keyTopic", ticketForEdit.keyTopic);
    setFieldValue("keySeverity", ticketForEdit.keySeverity);
    setFieldValue("idArea", ticketForEdit.idArea);
    setFieldValue("nameReporter", ticketForEdit.nameReporter);
    setFieldValue("idStaff", ticketForEdit.idStaff);
    setFieldValue("phone", ticketForEdit.phoneContact);
    setFieldValue("email", ticketForEdit.emailContact);
    setFieldValue("title", ticketForEdit.title);
    setFieldValue("description", ticketForEdit.description);

    if (ticketForEdit.idStaff && ticketForEdit.idStaff !== "") {
      setFieldValue("nameStaff", ticketForEdit.fullName);
      setStaffSelected({
        idStaff: ticketForEdit.idStaff,
        nameStaff: ticketForEdit.fullName,
        phone: ticketForEdit.phoneContact,
        email: ticketForEdit.emailContact,
      });
    }

    validateName(
      ticketForEdit.idStaff !== ""
        ? ticketForEdit.idStaff
        : ticketForEdit.nameReporter,
      ticketForEdit.idStaff !== "" ? "idStaff" : "nameReporter"
    );
  };

  return (
    <div>
      {true ? (
        <>
          <form onSubmit={handleSubmit}>
            <div className="text-center">
              <h2>Nuevo ticket</h2>
            </div>
            <div className="text-center">
              <h3 className="mb-4 mt-4 ">Información del solicitante</h3>
            </div>

            <Row className="d-flex justify-content-around mb-3">
              <Col xs={12} md={12} lg={5}>
                <SelectTypeHeadSingle
                  label="Area Solicitante"
                  isRequired={true}
                  inputName="idArea"
                  optionsArray={areas}
                  defaultOption="Seleccione un Area"
                  onChangeMethod={onChange}
                  value={idAreaLogged}
                  optionValue="idArea"
                  optionName="name"
                  onBlurMethod={handleBlur}
                  errors={errors.idArea}
                  touched={touched.idArea}
                  isDisabled={true}
                />
              </Col>
            </Row>

            <Row className="d-flex justify-content-around">
              <Col xs={12} lg={4}>
                <div className="form-group position-relative">
                  <label htmlFor="nameStaff">
                    Personal
                  </label>
                  <div className="input-group">
                    <input
                      name="nameStaff"
                      onChange={onChange}
                      onBlur={handleBlur}
                      className={
                        "form-control" +
                        (errors.nameStaff && touched.nameStaff
                          ? " is-invalid"
                          : "")
                      }
                      value={values.nameStaff}
                      disabled
                    />
                    <div className="input-group-append">
                      <button
                        className="btn btn-secondary"
                        style={{ zIndex: "auto" }}
                        type="button"
                        onClick={() => setModalTabla(true)}
                        disabled={
                          values.nameReporter && values.nameReporter !== ""
                            ? true
                            : false
                        }
                        /* disabled={
                          [10, 11, 12, 13, 14, 15].includes(+values.idReport) &&
                          (values.period !== "" || values.initialDate !== "")
                        } */
                      >
                        <i className="fa fa-search" />
                      </button>
                    </div>
                  </div>
                  {values.nameStaff !== "" && (
                    <CancelOutlined
                      className={s.btnClear}
                      onClick={handleClearStaff}
                    />
                  )}
                  {touched.nameStaff && errors.nameStaff && (
                    <span style={{ color: "red" }}>{errors.nameStaff}</span>
                  )}
                </div>
              </Col>

              <Col
                xs={12}
                lg={1}
                className="d-flex  align-items-center justify-content-around"
              >
                <h3> O</h3>
              </Col>
              <Col xs={12} lg={4}>
                <TextInput
                  label="Nombre del solicitante"
                  inputType="text"
                  inputName="nameReporter"
                  onChangeMethod={onChange}
                  onBlurMethod={handleBlur}
                  value={values.nameReporter}
                  touched={touched.nameReporter}
                  errors={errors.nameReporter}
                  isDisabled={
                    values.idStaff && values.idStaff !== "" ? true : false
                  }
                />
              </Col>
            </Row>

            <div className="text-center mt-3 mb-3">
              {errorName && (
                <span className="text-danger ">
                  Seleccione un empleado o ingrese un nombre
                </span>
              )}
            </div>

            <Row className="d-flex justify-content-around">
              <Col xs={4} lg={4}>
                <div className="form-group d-flex flex-column text-center justify-content-center">
                  <label htmlFor="checkEmpleados" className="fs-5">
                    Usar datos del empleado
                  </label>
                  <input
                    type="checkbox"
                    name="checkEmpleados"
                    className="form-control"
                    onChange={onChange}
                    value={values.checkEmpleados}
                    checked={values.checkEmpleados === "1" ? true : false}
                    disabled={
                      values.idStaff && values.idStaff !== "" ? false : true
                    }
                  />
                </div>
                {touched.checkEmpleados && errors.checkEmpleados && (
                  <span style={{ color: "red" }}>{errors.checkEmpleados}</span>
                )}
              </Col>
            </Row>

            <Row className="d-flex justify-content-around mt-5">
              <Col xs={12} lg={5}>
                <NumberInput
                  label="Telefono"
                  inputName="phone"
                  isRequired
                  onChangeMethod={onChange}
                  onBlurMethod={handleBlur}
                  value={
                    values.phone
                  }
                  touched={touched.phone}
                  errors={errors.phone}
                  required
                  disableSep={true}
                  enabled
                  isDisabled={
                    values.checkEmpleados === "1" &&
                    values.idStaff &&
                    values.idStaff !== ""
                  }
                />
              </Col>
              <Col xs={12} lg={5}>
                <TextInput
                  label="Correo"
                  inputType="text"
                  inputName="email"
                  isRequired
                  onChangeMethod={onChange}
                  onBlurMethod={handleBlur}
                  value={
                    values.email
                  }
                  touched={touched.email}
                  errors={errors.email}
                  isDisabled={
                    values.checkEmpleados === "1" &&
                    values.idStaff &&
                    values.idStaff !== ""
                  }
                />
              </Col>
            </Row>

            <div className="text-center">
              <h3 className="mb-4 mt-4 ">Información del ticket</h3>
            </div>

            <Row className="d-flex justify-content-around">
              <Col xs={12} md={12} lg={5}>
                <SelectTypeHeadSingle
                  label="Severidad"
                  isRequired={true}
                  inputName="keySeverity"
                  optionsArray={severities}
                  defaultOption="Seleccione una severidad"
                  onChangeMethod={onChange}
                  value={values.keySeverity}
                  optionValue="keySeverity"
                  optionName="nameSeverity"
                  onBlurMethod={handleBlur}
                  errors={errors.keySeverity}
                  touched={touched.keySeverity}
                />
              </Col>
            </Row>

            <Row className="d-flex justify-content-around">
              <Col xs={12} lg={5}>
                <TextInput
                  label="Titulo del ticket"
                  inputType="text"
                  inputName="title"
                  onChangeMethod={onChange}
                  onBlurMethod={handleBlur}
                  value={values.title}
                  touched={touched.title}
                  errors={errors.title}
                />
              </Col>
              <Col xs={12} md={12} lg={5}>
                <SelectTypeHeadSingle
                  label="Tipo de ticket"
                  isRequired={true}
                  inputName="keyTopic"
                  optionsArray={topics}
                  defaultOption="Seleccione un tipo de ticket"
                  onChangeMethod={onChange}
                  value={values.keyTopic}
                  optionValue="keyTopic"
                  optionName="nameTopic"
                  onBlurMethod={handleBlur}
                  errors={errors.keyTopic}
                  touched={errors.keyTopic}
                />
              </Col>
            </Row>

            <Row className="d-flex justify-content-center">
              <Col md={12} lg={6}>
                <TextAreaInput
                  label="Descripción del problema"
                  inputName="description"
                  inputRows={6}
                  onChangeMethod={onChange}
                  onBlurMethod={handleBlur}
                  value={values.description}
                  isRequired
                  touched={touched.description}
                  errors={errors.description}
                />
              </Col>
            </Row>

            <div className="d-flex justify-content-around">
              <Button onClick={() => {setCollapse("home")}} color="danger">
                Cancelar
              </Button>

              {isEdit
                ? permissions.UPD && (
                    <div>
                      <Button type="submit" color="success">
                        Editar
                      </Button>
                    </div>
                  )
                : permissions.INS && (
                    <div>
                      <Button type="submit" color="success">
                        Agregar
                      </Button>
                    </div>
                  )}
            </div>
          </form>

          <ModalConfirmation
            modalTitle={isEdit ? "Editar" : "Crear"}
            modal={modalConfirmation}
            setModal={setModalConfirmation}
            crear={saveTicket}
            editar={saveTicket}
            isEdit={false}
            values={values}
          >
            <div className="text-center">
              Esta seguro de continuar con el registro
            </div>
          </ModalConfirmation>

          <ModalTableVer
            modal={modalTabla}
            setModal={setModalTabla}
            title="Empleados"
            cabeceras={cabecerasModalTabla}
            filtro={filtroModalTabla}
            parametros={paramsModalTabla}
            selectRegistro={selectEmpleado}
            backdrop={"static"}
            keyboard={false}
            btnClose={true}
          />
        </>
      ) : (
        <div>
          <Loader />
        </div>
      )}
    </div>
  );
};

export default FormTickets;

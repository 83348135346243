import React from "react";
import { useState } from "react";
import TableComponent from "../../../components/TableComponent/TableComponentFiltro";

export const Ciudades = () => {
  const cabeceras = [
    "Id",
    "Ciudad",
    "Clave INEGI",
    "Municipio",
    "Editar",
    "Eliminar",
  ];

  const [parametros] = useState({
    action: "datatable",
    table: "cities A INNER JOIN counties B USING (keyCounty)",
    /* rows: "keyCity,keyCounty,nameCounty,keyCounty,A.economicKey,nameCity,A.latitude,A.longitude", */
    rows: "keyCity,nameCity,A.economicKey,nameCounty,A.latitude,A.longitude,keyCounty",
    conditions: "A.enabled = 1 AND B.enabled = 1",
    page: 0,
    records: 5,
    search: "",
    order: "",
  });
  const [filtro] = useState([true, true, true, true, false, false, false]);
  return (
    <div>
      <TableComponent
        titulo="Localidades"
        cabecerasTabla={cabeceras}
        filtro={filtro}
        rutaCrear="/template/ciudades/crear"
        rutaEditar="/template/ciudades/editar"
        parametros={parametros}
      ></TableComponent>
    </div>
  );
};
export default Ciudades;

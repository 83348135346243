import React from "react";
import {
  Button,
} from "reactstrap";
import { Link } from "react-router-dom";
import s from "./ErrorPage.module.scss";

export default function ErrorPage() {

  const origin = window.location.origin

  return (
    <div className={s.pageContainer}>
      <div className={s.errorContainer}>
        <h1 className={s.errorCode}>404</h1>
        <p className={s.errorInfo}>
          Opps... Parece que la página que estas buscando no se encuentra disponible o dejo de existir.
        </p>
        <p className={`text-dark ${s.errorHelp}`}>
          Dar click para regresar
        </p>
        <Link to="/template">
          <Button className={`${s.errorBtn} rounded-pill`} type="submit" color="secondary-red">
            Volver al inicio
          </Button>
        </Link>
      </div>
      <div className={s.imageContainer}>
        <img className="w-50" src={`${origin}/images/logo.png`} alt="Error page" />
      </div>
    </div>
  );
}

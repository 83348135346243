import { useEffect, useState } from "react"
import {
    Row,
    Col,
    Pagination,
    PaginationItem,
    PaginationLink,
    Input
} from "reactstrap"

export default function Paging({
    currentPageItems = [],
    itemsPerPage = 0,
    currentPage = 0,
    setCurrentPage,
    items = 0,
    setItemsPerPage,
    lengthMenu,
    paging,
    offset = 0,
    setOffset,
    info
}) {

    const [pagination, setPagination] = useState([])

    useEffect(() => {
        const newOffset = currentPage * itemsPerPage;
        if (newOffset !== offset) {
            setOffset(newOffset);
        }

        const pages = Math.ceil(items / itemsPerPage);
        const maxPages = Math.min(pages, 5);
        const startPage = Math.max(Math.min(pages - maxPages, currentPage - Math.floor(maxPages / 2)), 0);
        const endPage = Math.min(startPage + maxPages, pages);
        const tempPages = Array.from({ length: endPage - startPage }, (_, i) => i + startPage);
        setPagination(tempPages);
    }, [currentPage, itemsPerPage, offset, items]);


    return (
        <Row className="mt-3">
            <Col sm={6} className="d-flex justify-content-start align-items-center mt-3">
                {info &&
                    <div className="mx-2">
                        Mostrando <b>{itemsPerPage * currentPage === 0 ? (items > 0 ? 1 : 0) : (itemsPerPage * currentPage + 1)}</b> a <b>{paging ? (itemsPerPage * (currentPage + 1) <= items ? itemsPerPage * (currentPage + 1) : items) : items}</b> de <b>{items}</b> registros
                    </div>
                }

                {paging &&
                    <Input
                        type="select"
                        bsSize="sm"
                        style={{ width: "78px" }}
                        value={itemsPerPage}
                        onChange={(e) => { setItemsPerPage(Number(e.currentTarget.value)); setCurrentPage(0) }}
                    >
                        {lengthMenu.map((value, index) => (
                            <option value={value.v} key={`op${index}pg`}>
                                {value.l}
                            </option>
                        ))}
                    </Input>
                }
            </Col>
            <Col sm={6} className="d-flex justify-content-center align-items-center justify-content-sm-end">
                {paging &&
                    <Pagination
                        className="pagination-borderless d-flex mt-3 "
                    >
                        {pagination.length !== 0 ?
                            <PaginationItem disabled={currentPage <= 0} className="m-0">
                                <PaginationLink
                                    onClick={(e) => {
                                        setCurrentPage(currentPage - 1)
                                        e.preventDefault()
                                    }}
                                    previous
                                />
                            </PaginationItem>
                            : null}
                        {pagination.map((page, i) => (
                            <PaginationItem
                                key={i}
                                disabled={
                                    page === currentPage
                                }
                                className="m-1"
                            >
                                <PaginationLink
                                    onClick={(e) => {
                                        setCurrentPage(page);
                                        e.preventDefault();
                                    }}
                                    className={
                                        page === currentPage
                                            ? "text-bold text-white bg-primary"
                                            : "text-secondary"
                                    }
                                >
                                    {page + 1}
                                </PaginationLink>
                            </PaginationItem>
                        ))}
                        {pagination.length !== 0 &&
                            <PaginationItem disabled={currentPage >= Math.ceil(items / itemsPerPage)} className="m-0">
                                <PaginationLink
                                    disabled={currentPage >= Math.floor(Number(items) / Number(itemsPerPage))}
                                    onClick={(e) => {
                                        setCurrentPage(currentPage + 1)
                                        e.preventDefault()
                                    }}
                                    next
                                />
                            </PaginationItem>
                        }
                    </Pagination>
                }
            </Col>
        </Row>
    )
}
import { Document, Page, Text, View, StyleSheet } from "@react-pdf/renderer";
import { styles } from "../../../../components/Pdf/pdfGeneral/EstiloPdf";

import { format } from "date-fns"
import { es } from "date-fns/locale"
import HeaderReport from "../../../../components/Pdf/Header";

/**
 * Estilo que se repite en todas los headers de la tabla
 */
const fonts = {
    fontSize: 11,
}

const styleHeadForAll = {
    borderStyle: "solid", 
    borderWidth: 1,
    paddingLeft: 10,
    justifyContent: 'center',
    fontSize: 11
}
  
const styleCellForAll = {
    borderStyle: "solid", 
    borderWidth: 1, 
    paddingLeft: 10,
    justifyContent: 'center',
    fontSize: 11
}

const s = StyleSheet.create({
    body: {
        paddingTop: 35,
        paddingBottom: 35,
        paddingHorizontal: 40,
    },
    alignItems: 'center',
    title: { //TITULOS
        width: "100%",
        textAlign: 'center',
        fontFamily: 'Helvetica-Bold',
        fontSize: 12,
    },
    content: {
        width: "100%",
        justifyContent: "center",
        ...fonts
    },
    bold: {
        fontFamily: 'Helvetica-Bold'
    },
    underline: {
        textDecoration: "underline"
    },
    textCenter: {
        textAlign: 'center',
    },
    textRight: {
        textAlign: 'right',
    },
    center: {
        justifyContent: "center",
        marginHorizontal: "auto"
    },
    mt1: {
        marginTop: 10
    },
    mt2: {
        marginTop: 20
    },
    mt3: {
        marginTop: 30
    },
    mt4: {
        marginTop: 40
    },
    mt5: {
        marginTop: 50
    },
    mt6: {
        marginTop: 60
    },
    mt7: {
        marginTop: 70
    },
    px6: {
        paddingHorizontal: 60
    },
    borderTop: {
        borderTopWidth: 1,
        borderTopStyle: "solid",
        borderTopColor: "black",
    },
    tableColHeader: { 
        width: "20%",
        height: 20, 
        ...styleHeadForAll,
        borderBottomWidth: 0,
    }, 
    tableColContent: { 
        width: "80%", 
        height: 20,
        ...styleCellForAll,
        borderBottomWidth: 0,
        borderLeftWidth: 0
    },
    tableColHeaderFin: { 
        width: "20%",
        height: 20, 
        ...styleHeadForAll
    }, 
    tableColContentFin: { 
        width: "80%", 
        height: 20,
        ...styleCellForAll,
        borderLeftWidth: 0
    },
    tableSign: {
        width: "50%",
        justifyContent: 'center',
        ...fonts
    }
})

export const configPDF = {
    content: [
        { header: "Folio del contrato", value: "numberContract"},
        { header: "Tipo de movimiento", value: "nameMove"},
        { header: "Puesto laboral", value: "nameJob"},
        { header: "Adscripción", value: "name"},
        { header: "Edificio", value: "nameBuilding"},
        { header: "Fecha inicio", value: "startDate"},
        { header: "Fecha fin", value: "endDate"},
        { header: "Notas", value: "notes"},
    ]
}
export const cabecerasPDF = [
    "Folio del contrato",
    "Tipo de movimiento",
    "Puesto laboral",
    "Adscripción",
    "Edificio",
    "Fecha inicio",
    "Fecha fin",
    "Notas",
]

const formatter = new Intl.NumberFormat("es-MX", {
    style: "currency",
    currency: "MXN",
});

export default function exportPDF(data, model, title, fileNo) {
    const ori = model.orientation === "h" ? "landscape" : "portrait";

    const tempStartDate = format(new Date(data.empleado.startDate), "PPP", { locale: es }).toUpperCase();
    const startDate = tempStartDate.substring(0, tempStartDate.lastIndexOf("DE") - 1);
    const endDate = format(new Date(data.empleado.endDate), "PPP", { locale: es }).toUpperCase();

    const salaryMonth = formatter.format(Number(data.empleado.salaryMonth));
    const spellNumber = data.empleado.spellNumber;
    
    const puestoEmpleado = data.empleado.nameJob;

    const nombreEmpleado = data.empleado.nombre;

    const domicilio = data.empleado.direccion;

    const estadoCivil = data.empleado.nameTypeMarital;

    const rfc = data.empleado.rfc;

    const curp = data.empleado.curp;

    const years = data.empleado.bornDate

    const p1 = `CONTRATO DE PRESTACIÓN DE SERVICIOS MEDIANTE EL PAGO DE SERVICIOS PROFESIONALES POR HONORARIOS QUE CELEBRAN POR UNA PARTE EL GOBIERNO DEL ESTADO DE SONORA, REPRESENTADO EN ESTE ACTO POR EL C. el titular de la dependencia, EN SU CALIDAD DE puesto del titular, A QUIEN EN LO SUCESIVO SE LE DENOMINARÁ “LA ENTIDAD” Y POR LA OTRA PARTE C. ${nombreEmpleado}, A QUIEN EN LO SUCESIVO SE DENOMINARÁ “EL PRESTADOR”, AL TENOR DE LAS SIGUIENTES:`

    const p2 = <><Text style={[s.content, s.bold]}>PRIMERA.- “LA ENTIDAD”</Text> MANIFIESTA SER GOBIERNO DEL ESTADO DE SONORA, REPRESENTADO EN ESTE ACTO POR EL <Text style={[s.content, s.bold, s.underline]}>C. el titular de la dependencia</Text>, EN SU CALIDAD DE <Text style={[s.content, s.bold, s.underline]}>puesto del titular</Text>.</>

    const p3 = <><Text style={[s.content, s.bold]}>SEGUNDA.- “LA ENTIDAD”</Text> MANIFIESTA QUE PARA EL CUMPLIMIENTO DE SUS OBJETIVOS, REQUIERE CONTRATAR LOS SERVICIOS DE PERSONAL EXPERTO EN DIFERENTES ÁREAS, PARA LA EJECUCIÓN Y DESARROLLO DE SUS DIFERENTES ACTIVIDADES, MOTIVO POR EL CUAL, EN ESTE MOMENTO SE CELEBRA EL PRESENTE CONTRATO.</>

    const p4 = <><Text style={[s.content, s.bold]}>TERCERA.- “EL PRESTADOR”</Text> DECLARA POR SUS GENERALES LOS SIGUIENTES:</>

    const p5 = `EXPUESTO LO ANTERIOR, AMBAS PARTES CONVIENEN EN OTORGAR LAS SIGUIENTES:`

    const c1 = <><Text style={[s.content, s.bold]}>PRIMERA.-</Text> OBJETO. <Text style={[s.content, s.bold]}>"EL PRESTADOR"</Text> SE OBLIGA A PRESTAR SUS SERVICIOS A “LA ENTIDAD” DESARROLLANDO LAS ACTIVIDADES DE <Text style={[s.content, s.bold, s.underline]}>{puestoEmpleado}</Text>, EN EL DOMICILIO DE <Text style={[s.content, s.bold]}>"LA ENTIDAD"</Text> O DONDE ESTA LE INDIQUE, EN RAZÓN DE LAS NECESIDADES DE ESTA ÚLTIMA.</>

    const c2 = <><Text style={[s.content, s.bold]}>SEGUNDA.-</Text> SERVICIOS. <Text style={[s.content, s.bold]}>"EL PRESTADOR"</Text> SE OBLIGA A APLICAR AL MÁXIMO SU CAPACIDAD Y CONOCIMIENTOS PARA CUMPLIR SATISFACTORIAMENTE CON EL OBJETO DE ESTE CONTRATO; TAMBIÉN SE OBLIGA A CUMPLIR CON LAS NORMAS Y LINEAMIENTOS QUE <Text style={[s.content, s.bold]}>"LA ENTIDAD"</Text> LE ESTABLEZCA PARA LA MEJOR PRESTACIÓN DE SUS SERVICIOS, ASÍ COMO A UTILIZAR TODO EL TIEMPO QUE REQUIERA EL CUMPLIMIENTO SATISFACTORIO DE LOS MISMOS Y LAS NECESIDADES PARTICULARES DEL TRABAJO A DESARROLLAR.</>

    const c3 = <><Text style={[s.content, s.bold]}>TERCERA.-</Text> HONORARIOS. <Text style={[s.content, s.bold]}>"LA ENTIDAD"</Text> SE OBLIGA A CUBRIR A <Text style={[s.content, s.bold]}>"EL PRESTADOR"</Text> POR LOS SERVICIOS QUE ÉSTE PRESTE EN LOS TÉRMINOS DE ESTE CONTRATO, UN HONORARIO MENSUAL; POR LA CANTIDAD DE: {salaryMonth} ({spellNumber} PESOS 00/100 MONEDA NACIONAL), ASI COMO UNA REMUNERACIÓN ANUAL POR CONCEPTO DE APOYO A LA PRODUCTIVIDAD EQUIVALENTE A UN MES, QUE SE PAGARÁ DURANTE EL MES DE DICIEMBRE O EN SU CASO LA PARTE PROPORCIONAL. LOS PAGOS SE HARAN A <Text style={[s.content, s.bold]}>"EL PRESTADOR"</Text> EN LAS OFICINAS QUE OCUPA <Text style={[s.content, s.bold]}>"LA ENTIDAD"</Text> Y SE HARÁN NORMALMENTE EN FORMA MENSUAL, BAJO NINGUNA CIRCUNSTANCIA, LOS HONORARIOS FIJADOS VARIARAN DURANTE LA VIGENCIA DE ESTE CONTRATO.</>

    const c4 = <><Text style={[s.content, s.bold]}>CUARTA.-</Text> RETENCIONES. <Text style={[s.content, s.bold]}>"EL PRESTADOR"</Text> ACEPTA EXPRESAMENTE QUE <Text style={[s.content, s.bold]}>"LA ENTIDAD"</Text> LE RETENGA DE SUS HONORARIOS EL IMPUESTO SOBRE LA RENTA CORRESPONDIENTE, QUE SE CAUSE EN LOS TÉRMINOS DEL ARTICULO 94 FRACCION IV Y V; Y ARTICULO 96 SEGUNDO PARRAFO DE LA LEY DEL IMPUESTO SOBRE LA RENTA.</>

    const c5 = <><Text style={[s.content, s.bold]}>QUINTA.-</Text> PAGOS ADICIONALES. CUANDO POR MOTIVO DE LOS SERVICIOS SE REQUIERA QUE <Text style={[s.content, s.bold]}>"EL PRESTADOR"</Text> SE TRASLADE EN FORMA TRANSITORIA A UNA POBLACIÓN DISTINTA A DONDE FUE CONTRATADO, <Text style={[s.content, s.bold]}>"LA ENTIDAD"</Text> PODRÁ CUBRIRLE LOS VIÁTICOS RESPECTIVOS DE ACUERDO A LAS TARIFAS VIGENTES, ASI COMO TAMBIEN, EN CASO DE SER NECESARIO, SE LE PODRA AUTORIZAR EL USO DE VEHÍCULO OFICIAL, DE ACUERDO A LAS NORMAS ESTABLECIDAS.</>

    const c6 = <><Text style={[s.content, s.bold]}>SEXTA.-</Text> VIGENCIA. EL PRESENTE CONTRATO TENDRA VIGENCIA POR UN PERIODO COMPRENDIDO DEL <Text style={[s.content, s.bold]}>{startDate} AL {endDate}</Text>. LAS PARTES PODRÁN DAR POR TERMINADO EL PRESENTE CONTRATO EN FORMA UNILATERAL SIN MAS REQUISITOS QUE DAR AVISO CON QUINCE DIAS DE ANTICIPACIÓN A LA OTRA PARTE.</>

    const c7 = <><Text style={[s.content, s.bold]}>SEPTIMA.-</Text> PRINCIPIOS LEGALES. LAS OBLIGACIONES DERIVADAS DEL PRESENTE CONTRATO, SE REGIRÁN POR LO EXPRESAMENTE PACTADO EN ESTE INSTRUMENTO Y POR LAS DISPOSICIONES APLICABLES DEL CODIGO CIVIL DEL ESTADO DE SONORA; POR LO TANTO, <Text style={[s.content, s.bold]}>"LA ENTIDAD"</Text> NO ADQUIERE NI RECONOCE OBLIGACIONES DISTINTAS DE LAS MISMAS A FAVOR DE <Text style={[s.content, s.bold]}>"EL PRESTADOR"</Text>, EN VIRTUD DE NO SER APLICABLES LAS LEYES DEL SEGURO SOCIAL E ISSSTESON, EN LOS TÉRMINOS DE LOS PROPIOS ORDENAMIENTOS.</>

    const c8 = <><Text style={[s.content, s.bold]}>OCTAVA.-</Text> RELACIÓN CONTRACTUAL. <Text style={[s.content, s.bold]}>"EL PRESTADOR"</Text> ESTA DE ACUERDO EN NO CONSIDERARSE COMO EMPLEADO DEL GOBIERNO DEL ESTADO, POR LO QUE NO SE HARÁ ACREEDOR A LAS PRESTACIONES Y DEMÁS BENEFICIOS QUE ESTE ORGANISMO OTORGA A SU PERSONAL, EN LOS TÉRMINOS DE LAS LEYES LABORALES APLICABLES A LA MATERIA.</>

    const c9 = <><Text style={[s.content, s.bold]}>NOVENA.-</Text> AMBAS PARTES CONVIENEN QUE LOS CONFLICTOS DERIVADOS DEL INCUMPLIMIENTO DEL PRESENTE CONTRATO, SERÁN RESUELTOS POR LOS TRIBUNALES COMPETENTES DEL FUERO COMÚN CORRESPONDIENTES AL DOMICILIO DEL GOBIERNO DEL ESTADO, EN LA CIUDAD DE HERMOSILLO, SONORA, RENUNCIANDO EL PRESTADOR AL FUERO QUE PUDIERA CORRESPONDERLE POR SU DOMICILIO ACTUAL O EL QUE PUDIERA ADQUIRIR EN EL FUTURO.</>

    const pf = `LEÍDO POR AMBAS PARTES QUE INTERVIENEN EN EL PRESENTE CONTRATO Y ENTERADO DEL VALOR, ALCANCE Y FUERZA LEGAL DE LAS CLÁUSULAS QUE CONTIENE, LO FIRMAN EN LA CIUDAD DE HERMOSILLO, SONORA, EL DIA PRIMERO DE ENERO DEL DOS MIL DIECISIETE.`

    const directorLabel = `EL DIRECTOR GENERAL DE PLANEACION,
    ADMINISTRACIÓN Y EVALUACION
    DE LA DEPENDENCIA`

    const fecha = format(new Date(), "PPP", { locale: es }).toUpperCase();

    const pres1 = <>POR MEDIO DEL PRESENTE LE COMUNICO QUE ES MI DESEO EL CELEBRAR CON LA DEPENDENCIA QUE USTED PRESIDE, UN CONTRATO DE PRESTACIÓN DE SERVICIOS DE HONORARIOS ASIMILADOS A SUELDO, CON VIGENCIA DEL <Text style={[s.content, s.bold]}>{startDate} AL {endDate}</Text>, POR LAS ACTIVIDADES DE <Text style={[s.content, s.bold]}>{puestoEmpleado}</Text>. ADICIONALMENTE SOLICITO A USTED (ES) QUE EL PAGO DE MIS SERVICIOS SEAN CONSIDERADOS COMO UN HONORARIO ASIMILADO A SUELDO EN LOS TÉRMINOS DEL ARTICULO 94 FRACCION IV Y V DE LA LEY DEL IMPUESTO SOBRE LA RENTA EN VIGOR; POR LO TANTO, DE MIS INGRESOS, DEBERAN EFECTUAR RETENCIÓN ÚNICAMENTE DEL IMPUESTO SOBRE LA RENTA CALCULADO EN BASE AL ARTICULO 96 SEGUNDO PARRAFO DE LA CITADA LEY.</>

    const pres2 = "ASIMISMO RECONOZCO QUE LAS OBLIGACIONES DERIVADAS DEL CONTRATO DE PRESTACIÓN DE SERVICIOS MEDIANTE EL PAGO DE HONORARIOS ASIMILADOS A SUELDO A CELEBRAR CON USTED (ES), SE REGIRÁN POR LO EXPRESAMENTE PACTADO EN DICHO CONTRATO Y POR LAS DISPOSICIONES APLICABLES DEL CÓDIGO CIVIL DEL ESTADO DE SONORA, POR LO TANTO ESE ORGANISMO NO ADQUIRIRÁ NI RECONOCERA OBLIGACIONES DISTINTAS DE LAS MISMAS A FAVOR DE MI PERSONA, EN VIRTUD DE NO SER APLICABLES LAS LEYES DEL SEGURO SOCIAL O DEL ISSSTESON, EN TÉRMINOS DE SUS ORDENAMIENTOS."

    const pres3 = "AGRADEZCO DE ANTEMANO LAS ATENCIONES QUE LA PRESENTE MEREZCA, ASÍ COMO LA OPORTUNIDAD DE PRESTAR MIS SERVICIOS Y DESARROLLARME EN ESA DISTINGUIDA INSTITUCIÓN QUE REPRESENTAN."

    return {
        report: (
            <>
                <Document>
                    <Page style={s.body} orientation={ori}>
                        <HeaderReport/>
                        <View>
                            <Text style={s.title}>{title}</Text>
                        </View>
                        <View>
                            <Text style={[s.content, s.bold, s.mt2]}>{p1}</Text>
                        </View>
                        <View>
                            <Text style={[s.title, s.mt3]}>DECLARACIONES</Text>
                        </View>
                        <View>
                            <Text style={[s.content, s.mt3]}>{p2}</Text>
                        </View>
                        <View>
                            <Text style={[s.content, s.mt1]}>{p3}</Text>
                        </View>
                        <View>
                            <Text style={[s.content, s.mt1]}>{p4}</Text>
                        </View>
                        <View style={[styles.table, s.mt2]}>
                            <View style={styles.tableRow} fixed>
                                <View style={s.tableColHeader} fixed>
                                    <Text style={s.bold}>NOMBRE</Text>
                                </View>
                                <View style={s.tableColContent} fixed>
                                    <Text>{nombreEmpleado}</Text>
                                </View>
                            </View>
                            <View style={styles.tableRow} fixed>
                                <View style={s.tableColHeader} fixed>
                                    <Text style={s.bold}>DOMICILIO</Text>
                                </View>
                                <View style={s.tableColContent} fixed>
                                    <Text>{domicilio ? `${domicilio.fullCalle}, CP: ${domicilio.zipCode}, ${domicilio.nameCity}, ${domicilio.nameState}`: "SIN DEFINIR" }</Text>
                                </View>
                            </View>
                            <View style={styles.tableRow} fixed>
                                <View style={s.tableColHeader} fixed>
                                    <Text style={s.bold}>EDAD</Text>
                                </View>
                                <View style={s.tableColContent} fixed>
                                    <Text>{years} AÑOS</Text>
                                </View>
                            </View>
                            <View style={styles.tableRow} fixed>
                                <View style={s.tableColHeader} fixed>
                                    <Text style={s.bold}>ESTADO CIVIL</Text>
                                </View>
                                <View style={s.tableColContent} fixed>
                                    <Text>{estadoCivil}</Text>
                                </View>
                            </View>
                            <View style={styles.tableRow} fixed>
                                <View style={s.tableColHeader} fixed>
                                    <Text style={s.bold}>RFC</Text>
                                </View>
                                <View style={s.tableColContent} fixed>
                                    <Text>{rfc}</Text>
                                </View>
                            </View>
                            <View style={styles.tableRow} fixed>
                                <View style={s.tableColHeaderFin} fixed>
                                    <Text style={s.bold}>CURP</Text>
                                </View>
                                <View style={s.tableColContentFin} fixed>
                                    <Text>{curp}</Text>
                                </View>
                            </View>
                        </View>
                        <View>
                            <Text style={[s.content, s.mt1]}>{p5}</Text>
                        </View>
                        <View>
                            <Text style={[s.title, s.mt2]}>CLÁUSULAS</Text>
                        </View>
                        <View>
                            <Text style={[s.content, s.mt1]}>{c1}</Text>
                        </View>
                        <View>
                            <Text style={[s.content, s.mt2]}>{c2}</Text>
                        </View>
                        <View>
                            <Text style={[s.content, s.mt2]}>{c3}</Text>
                        </View>
                        <View>
                            <Text style={[s.content, s.mt2]}>{c4}</Text>
                        </View>
                        <View>
                            <Text style={[s.content, s.mt2]}>{c5}</Text>
                        </View>
                        <View>
                            <Text style={[s.content, s.mt2]}>{c6}</Text>
                        </View>
                        <View>
                            <Text style={[s.content, s.mt2]}>{c7}</Text>
                        </View>
                        <View>
                            <Text style={[s.content, s.mt2]}>{c8}</Text>
                        </View>
                        <View>
                            <Text style={[s.content, s.mt2]}>{c9}</Text>
                        </View>
                        <View>
                            <Text style={[s.content, s.mt1, s.bold]}>{pf}</Text>
                        </View>
                        <View style={[styles.table, s.mt3]}>
                            <View style={styles.tableRow} fixed>
                                <View style={s.tableSign} fixed>
                                    <Text style={[s.content, s.bold, s.textCenter]}>Titular de la dependencia</Text>
                                    <View style={[s.px6, s.mt5]}>
                                        <View style={s.borderTop}>
                                            <Text style={[s.content, s.bold, s.textCenter]}>M.S.</Text>
                                        </View>
                                    </View>
                                </View>
                                <View style={s.tableSign} fixed>
                                    <Text style={[s.content, s.bold, s.textCenter]}>“EL PRESTADOR”</Text>
                                    <View style={[s.px6, s.mt5]}>
                                        <View style={s.borderTop}>
                                            <Text style={[s.content, s.bold, s.textCenter]}>C. {nombreEmpleado}</Text>
                                        </View>
                                    </View>
                                </View>
                            </View>
                        </View>
                        <View style={[s.tableSign, s.center, s.mt3]}>
                            <Text style={[s.content, s.bold, s.textCenter]}>
                                {directorLabel}
                            </Text>
                            <View style={[s.px6, s.mt5]}>
                                <View style={s.borderTop}>
                                    <Text style={[s.content, s.bold, s.textCenter]}>Lic.</Text>
                                </View>
                            </View>
                        </View>
                        <View style={s.mt4}>
                            <Text style={[s.content, s.bold, s.textRight]}>HERMOSILLO, SONORA, A {fecha}</Text>
                        </View>
                        <View style={s.mt2}>
                            <Text style={[s.content, s.bold, s.underline]}>C.Titular</Text>
                        </View>
                        <View>
                            <Text style={[s.content, s.underline]}>Puesto</Text>
                        </View>
                        <View>
                            <Text style={[s.content, s.bold, s.mt2]}>P R E S E N T E .</Text>
                        </View>
                        <View>
                            <Text style={[s.content, s.mt1]}>{pres1}</Text>
                        </View>
                        <View>
                            <Text style={[s.content, s.mt1]}>{pres2}</Text>
                        </View>
                        <View>
                            <Text style={[s.content, s.mt1]}>{pres3}</Text>
                        </View>
                        <View style={[s.tableSign, s.center, s.mt3]}>
                            <Text style={[s.content, s.bold, s.textCenter]}>
                                A T E N T A M E N T E
                            </Text>
                            <View style={[s.px6, s.mt5]}>
                                <View style={s.borderTop}>
                                    <Text style={[s.content, s.bold, s.textCenter]}>C. {nombreEmpleado}</Text>
                                </View>
                            </View>
                        </View>
                        <View fixed style={styles.spaceFooter}></View>
                    </Page>
                </Document>
            </>
        ),
    };
}
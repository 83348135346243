
import React from "react";
import Widget from "../../../../components/Widget/Widget";
import FormCalculo from "../FormCalculo";

export const CrearCalculo = (props) => {

  return (
    <Widget className="widget-p-md">
      <h3>Crear Calculo</h3>
      <FormCalculo />
    </Widget>
  )
}

export default CrearCalculo;
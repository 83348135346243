import React, { useState } from "react";
import TableComponent from "../../../components/TableComponent/TableComponentFiltro";

export const Salarios = () => {
  const cabeceras = [
    "#",
    "Nivel - Rango",
    "Estado",
    "Sueldo Base",
    "Sueldo Bruto",
    "Sueldo Neto",
    "Gratificacion",
    "Despensa",
    "Compensación",
    "Fortalecimiento",
    "Viaticos",
    "Impuestos",
    "Cuotas de Salud",
    "Tipo de estructura",
    "Editar",
    "Eliminar",
  ];
  const filtro = [true, true, true, true, true, true, true, true,true, true, true, true,true, true,false]
  const [parametros] = useState({
    action: "datatable",
    table: "salaries As Sal INNER JOIN status AS St ON St.keyStat = Sal.keyStat INNER JOIN salariestypes AS Sat ON Sat.keySalaryType = Sal.keySalaryType",
    rows: "Sal.idSalary,CONCAT(Sal.level,' - ',Sal.rango) AS 'levelrange',St.nameStat,Sal.base,Sal.gross,Sal.net,Sal.reward,Sal.larder,Sal.clearance,Sal.fortalecimiento,Sal.travels,Sal.taxes,Sal.ssecurity,Sat.nameSalaryType,Sal.subLevel as subnivel,Sal.keySalaryType", ///sin espacios y comas
    conditions: "Sal.enabled = 1",
    page: 0,
    records: 5,
    search: "",
    order: "",
  });

  return (
    <div>
      <TableComponent
        titulo="Salarios"
        cabecerasTabla={cabeceras}
        filtro={filtro}
        rutaCrear="/template/salarios/crear"
        rutaEditar="/template/salarios/editar"
        parametros={parametros}
      />
    </div>
  );
};
export default Salarios;
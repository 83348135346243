import { useEffect, useState } from "react";
import Datatable from "../../../components/Datatable/Datatable";
import { doRequest, doRequestSaveRes } from "../../../helpers/requests";
import { Button } from "reactstrap";
import ModalConfirmation from "../../../components/Modal/ModalConfirmation";
import FullFormLoader from "../../../components/Loader/FullFormLoader";

const headers = ["#", "Bien", "Unidad de medida", "Cantidad a traspasar"];
const columnDefs = [
    {
        targets: [3, 4],
        className: "text-right",
    },
    {
        orderable: false,
        targets: [0],
        className: "text-center",
    }
];

const columns = [
    {data: "ix"},
    {data: "nameFurniture"},
    {data: "nameUnit"},
    {data: "quantity"}
]

export default function Detalle({permissions, info}){
    const [refresh, setRefresh] = useState(false);
    const [loading, setLoading] = useState(false);
    const [modal, setModal] = useState(false);
    const [showButton, setShowButton] = useState(false);
    
    useEffect(()=>{
        if(+info.stat !== 133) setShowButton(true);
    }, []);

    async function getData(ordCol, itemsPerPage, currentPage, search, multi) {
        const res = await doRequestSaveRes(
            "receiver/receiver.php",
            getParams(ordCol, itemsPerPage, currentPage, search, multi),
            false,
            false
        );
        if (res.code === "200"){
            res.data = res.data.map((el, ix) => {
                return { ...el, ix: ix + 1 };
            });
            return res;
        }
        return null;
    }

    function getParams(ordCol, itemsPerPage, currentPage, search) {
        const params = {
            action: "datatable",
            table: "warehousetransfersdetail A INNER JOIN warehouseinventory B USING(idInventory) INNER JOIN furnitures C USING(idFurniture)  INNER JOIN messureunits D USING(keyUnit) ",
            rows: "C.nameFurniture, D.nameUnit, A.quantity",
            conditions: `A.idTransfer = ${info.id} AND A.enabled = 1`,
            order: ordCol,
            records: itemsPerPage.toString(),
            page: (itemsPerPage * currentPage).toString(),
            search
        };
        return params;
    }

    async function confirm(){
        setLoading(true);
        const params = {
            action: "confirmTransfer",
            idTransfer: info.id
        }
        const res = await doRequest("app/facades/warehouse/warehouseF.php", params, true);
        if(res.length > 0){
            setShowButton(false);
        }
        setLoading(false);
    }

    return(
        <div className="mt-2">
            <Datatable
                headers={headers}
                columns={columns}
                columnDefs={columnDefs}
                petition={getData}
                order={{ col: 1, opt: "asc" }}
                control="back"
                stateRefresh={[refresh, setRefresh]}
                searching={true}
                className="px-0 mt-4"
            />

            {
                permissions.UPD && showButton && 
                <div className="mt-3 d-flex flex-column flex-md-row justify-content-center">
                    <Button color="info" onClick={()=>setModal(true)}>Confirmar Recepción</Button>
                </div>
            }

            
            <ModalConfirmation
                modalTitle="Confirmar Recepción"
                modal={modal}
                crear={confirm}
                setModal={setModal}
            >
                <div className="text-center">
                    <h6>¿Está seguro de confirmar la recepción de los articulos?</h6>
                    <p>No podrá deshacer esta acción</p>
                </div>
            </ModalConfirmation>
            
            <FullFormLoader show={loading} />
        </div>
    )
}
import React, { useState } from "react";
import Loader from "../../../components/Loader/Loader";
import Widget from "../../../components/Widget/Widget";
import { GetPermissions } from "../../../libs/permissions/getPermissions";
import Datatable from "../../../components/Datatable/Datatable";
import { peticionesReceiver } from "../../../helpers/peticionesReceiver";
import { toast } from "react-toastify";
import Notification from "../../../components/Notification/Notification";
import { Button, Collapse } from "reactstrap";
import FormSolicitudes from "./components/FormSolicitudes";
import FormDetails from "./components/FormDetails";
import { useSelector } from "react-redux";

const TraspasosArticulos = () => {
  const [permissions, setPermissions] = useState(true);
  const [loading, setLoading] = useState(true);
  const API = peticionesReceiver();

  GetPermissions(setPermissions, setLoading);

  const [refresh, setRefresh] = useState(false);
  const [collapse, setCollapse] = useState(false);
  const [trasnferRequest, setTransferRequest] = useState(undefined); // object || undefined
  const { profileType } = useSelector((state) => state.userInfo);
  const idAreaLogged = profileType.selectProfile.idArea;
  

  const headers = [
    "#",
    "Estatus",
    "Folio",
    "Origen",
    "Destino",
    "Descripción",
    "Detalle",
  ];

  const columns = [
    { data: "index" },
    { data: "nameStat" },
    { data: "numberTransfer" },
    { data: "areaOrigin" },
    { data: "areaDestiny" },
    { data: "description" },
    {
      data: null,
      render: function (row) {
        return (
          <td
            onClick={() => {
              showDetail(row);
            }}
            className="text-center d-flex justify-content-center"
          >
            <i className={" fa fa-eye text-info handCursor"} />
          </td>
        );
      },
    },
  ];

  const showDetail = (row) => {
   
    setTransferRequest(row);
  };

  const columnDefs = [
    {
      targets: [0, 1, 2, 3, 4, 5],
      className: "text-center",
    },
    {
      orderable: false,
      targets: [0],
    },
  ];

  const getParamsTableElmt = (order, records, page, search) => {
    const params = {
      action: "datatable",
      table:
        "warehousetransfers A INNER JOIN areas B on (A.idAreaOrigin = B.idArea) INNER JOIN areas C on (A.idAreaDestiny = C.idArea) INNER JOIN status D on (A.keyStat = D.keyStat)",
      rows: "A.idTransfer,A.numberTransfer,A.yearTransfer,A.description,B.name as areaOrigin,C.name as areaDestiny, D.nameStat,A.idAreaDestiny,A.idAreaOrigin,A.keyStat",
      conditions: `A.enabled = 1 and B.enabled = 1 and C.enabled = 1 and  D.enabled = 1 and A.idAreaOrigin = ${idAreaLogged}`,
      /* conditions: `A.enabled = 1 and B.enabled = 1 and C.enabled = 1 and  D.enabled = 1`, */
      order,
      records: records.toString(),
      page: (page * records).toString(),
      search,
    };
    return params;
  };

  async function getData(order, records, page, search) {
    try {
      const res = await API.peticion(
        getParamsTableElmt(order, records, page, search)
      );
      if (res.status === 200 && res.data.code === "200") {
        const data = res.data;

        /* data.forEach((value, index) => (value.index = 1 + index)); */
        data.data.forEach((value, index) => (value.index = 1 + index));
        return data;
      } else {
        toast(
          <Notification
            type={"consultar_error"}
            backMessage={res.data.message}
          />
        );
        return null;
      }
    } catch (err) {
      toast(<Notification type={"consultar_servidor_error"} withIcon />);
      return null;
    }
  }

  return (
    <Widget className="widget-p-md">
      {loading ? (
        <div className="text-center">
          <Loader />
        </div>
      ) : (
        <>
          {permissions.CON ? (
            <>
              <Collapse isOpen={trasnferRequest !== undefined ? false : true}>
                <div className="text-center">
                  <h1> Nueva solicitud</h1>
                </div>
                <Datatable
                  headers={headers}
                  columns={columns}
                  columnDefs={columnDefs}
                  petition={getData}
                  control="back"
                  stateRefresh={[refresh, setRefresh]}
                  searching={true}
                  order={{ col: 1, opt: "desc" }}
                />

                {permissions.INS && (
                  <div className="text-right">
                    <Button
                      color={collapse ? "danger" : "success"}
                      onClick={() => {
                        setCollapse(!collapse);
                      }}
                    >
                      {collapse ? "Cancelar" : "Agregar Solicitud"}
                    </Button>
                  </div>
                )}

                <Collapse isOpen={collapse}>
                  <FormSolicitudes
                    collapse={collapse}
                    permissions={permissions}
                    setTrasnferRequest={setTransferRequest}
                    setRefresh={setRefresh}
                    setCollapse={setCollapse}
                    idAreaLogged={idAreaLogged}
                    
                  />
                </Collapse>
              </Collapse>

              <Collapse isOpen={trasnferRequest !== undefined ? true : false}>
                <div className="text-left">
                  <Button
                    color="danger"
                    onClick={() => {
                      showDetail(undefined);
                    }}
                  >
                    Regresar
                  </Button>
                </div>
                {trasnferRequest !== undefined && (
                  <FormDetails
                    collapse={collapse}
                    permissions={permissions}
                    transfer={trasnferRequest}
                    setTrasnferRequest={setTransferRequest}
                    idAreaLogged={idAreaLogged}
                    setRefreshPrinc={setRefresh}
                  />
                )}
              </Collapse>
            </>
          ) : (
            "No tiene permiso de consultar este modulo"
          )}
        </>
      )}
    </Widget>
  );
};

export default TraspasosArticulos;

import React, { useState } from "react";
import { Nav, NavItem, NavLink, Spinner } from "reactstrap";
import Widget from "../../../components/Widget/Widget";
import s from "../../personal/expediente-personal/nav.module.scss";
import ConceptosNomina from "./ConceptosNomina";
import TiposGruposConceptos from "./TiposGruposConceptos";
import GruposNomina from "./GruposNomina";
import { GetPermissions } from "../../../libs/permissions/getPermissions";

export default function GruposNominaPrincipal() {
  const [loading, setLoading] = useState(true);
  const [permissions, setPermissions] = useState(true);
  GetPermissions(setPermissions, setLoading);

  const [activeTab, setActiveTab] = useState(1);

  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  return (
    <Widget className="widget-p-md">
      <div className="headline-1">Conceptos</div>
      <Nav fill tabs style={{ overflowY: "hidden", overflowX: "auto" }}>
        <NavItem active={activeTab === 1}>
          <NavLink
            href="#"
            active={activeTab === 1}
            onClick={() => toggleTab(1)}
          >
            <i className={s.navIco + " fa fa-list-alt"} />
            Tipos de grupos de conceptos
          </NavLink>
        </NavItem>
        <NavItem active={activeTab === 2}>
          <NavLink
            href="#"
            active={activeTab === 2}
            onClick={() => toggleTab(2)}
          >
            <i className={s.navIco + " fa fa-pie-chart"} />
            Grupos de nómina
          </NavLink>
        </NavItem>
        <NavItem active={activeTab === 3}>
          <NavLink
            href="#"
            active={activeTab === 3}
            onClick={() => toggleTab(3)}
          >
            <i className={s.navIco + " fa fa-money"} />
            Conceptos de nómina
          </NavLink>
        </NavItem>
      </Nav>
      {loading && permissions !== true ? (
        <div className="my-5 text-center">
          <Spinner />
        </div>
      ) : (
        <div className="mt-4">
          {activeTab === 1 ? (
            <TiposGruposConceptos permissions={permissions}/>
          ) : activeTab === 2 ? (
            <GruposNomina permissions={permissions}/>
          ) : (
            activeTab === 3 && <ConceptosNomina permissions={permissions}/>
          )}
        </div>
      )}
    </Widget>
  );
}

import XlsxPopulate from "xlsx-populate";
import getLayout from "../../../../components/excel/layoutExcel";
import { fMoney } from "../../../../libs/tools/format";
import { saveAs } from "file-saver";

export default async function excelPrestacionesExtraordinarias(data, config){
    const s = {
        header: {horizontalAlignment: "center", fill: "AEAAAA", fontColor: "FFFFFF", border: true},
        tt: {horizontalAlignment: "center", bold: true, fontSize: "14"},
        txs: { wrapText: true, verticalAlignment: "center", horizontalAlignment: "left", border: true },
        txc: { wrapText: true, verticalAlignment: "center", horizontalAlignment: "center", border: true },
        txe: { wrapText: true, verticalAlignment: "center", horizontalAlignment: "right", border: true }
    }
    const excel = await getLayout();
    if (!excel) throw new Error("error excel");
      
    XlsxPopulate.fromDataAsync(excel).then(async (workbook) => {
        const hoja = workbook.sheet(0);
        hoja.range("A3:H3").merged(true).value("PRESTACIONES EXTRAORDINARIAS").style(s.tt);
        hoja.range("A4:H4").merged(true).value(`${config.staff.staffNumber} - ${config.staff.fullName}`).style(s.tt);

        for (let i = 1; i <= 8; i++) {
            if(i == 1) hoja.column(i).width(35);
            else if(i == 8) hoja.column(i).width(30);
            else hoja.column(i).width(20);
        }
        hoja.row(6).cell(1).value("Concepto").style(s.header);
        hoja.row(6).cell(2).value("Monto total").style(s.header);
        hoja.row(6).cell(3).value("Monto Quincenal").style(s.header);
        hoja.row(6).cell(4).value("Periodo de inicio").style(s.header);
        hoja.row(6).cell(5).value("Periodo termino").style(s.header);
        hoja.row(6).cell(6).value("Pagos").style(s.header);
        hoja.row(6).cell(7).value("Pagos aplicados").style(s.header);
        hoja.row(6).cell(8).value("Estatus").style(s.header);

        data.forEach((d, ix) => {
            hoja.row(7+ix).cell(1).value(`${d.under} - ${d.nameUnder}`).style(s.txs);
            hoja.row(7+ix).cell(2).value(fMoney(d.amountTotal)).style(s.txe);
            hoja.row(7+ix).cell(3).value(fMoney(d.amountBiweekly)).style(s.txe);
            hoja.row(7+ix).cell(4).value(`${d.startPeriod}/${d.startYear}`).style(s.txc);
            hoja.row(7+ix).cell(5).value(`${d.endPeriod}/${d.endYear}`).style(s.txc);
            hoja.row(7+ix).cell(6).value(d.noPays).style(s.txe);
            hoja.row(7+ix).cell(7).value(d.noPaysMade).style(s.txe);
            hoja.row(7+ix).cell(8).value(d.nameStat).style(s.txs);
        });

        return workbook.outputAsync().then((res) => {
            saveAs(res, `Prestaciones Extraordinarias ${config.staff.staffNumber} .xlsx`);
        });
    });
}
import { useState, useEffect } from "react";
import ReportViewer from "../../../components/Iframe/report";
import pdfAcucseRecibo from "../recepcion-servicios/acuseRecibo";
import FullFormLoader from "../../../components/Loader/FullFormLoader";
import { doRequest } from "../../../helpers/requests";

export default function AcuseEntrada({solicitud, entrada}){
    const [dataReport, setDataReport] = useState();

    useEffect(()=>{
        getReport();
    }, []);

    async function getReport(){
        const income = entrada.split(","); 
        const params = {
            action: "multiselect",
            table: "warehouseincomes A INNER JOIN purchaserequests Z ON Z.idPurchaseRequest = A.idPurchaseRequest INNER JOIN warehouseincomereceptions B ON B.idIncome = A.idIncome INNER JOIN receptions C ON C.idReception = B.idReception INNER JOIN purchasewinners D ON D.idWinner = C.idWinner INNER JOIN providerquotes E ON E.idProviderQuote = D.idProviderQuote INNER JOIN areas F ON F.idArea = Z.idArea INNER JOIN staff G ON G.idStaff = A.idStaffReceive INNER JOIN providers H on H.idProvider= E.idProvider INNER JOIN quotesdetail I on I.idDetail = E.idDetail LEFT JOIN services J on J.idService = I.idService LEFT JOIN furnitures K on K.idFurniture = I.idFurniture",
            rows: "A.dateIncome, A.numberIncome, F.name AS nameArea, CONCAT_WS(' ',G.name,G.pName,G.mName) AS fullName, H.socialName, H.itin, H.telephone, H.email, J.nameService, K.nameFurniture, C.quantity, B.incomeTotal, E.total",
            conditions: `A.idPurchaseRequest = ${solicitud} AND A.enabled = 1 AND B.enabled = 1 AND E.idProvider = ${income[1]} AND A.idIncome = ${income[0]}`,
            order: "nameService ASC",
            group: ""
        }
        const res = await doRequest("receiver/receiver.php", params, false);
        if(res.length > 0){
            const service = !!res[0].nameService;
            setDataReport({
                data: res,
                service: service
            });
        }
    }

    return(
        <div>
            {
                dataReport ?
                <ReportViewer
                    title={"Acuse de entrada"}
                    report={dataReport}
                    pdfConfig={{}}
                    generatePdfMethod={{
                        method: pdfAcucseRecibo,
                        type: "reporteAcuseEntrada",
                    }}
                /> :
                <div className="position-relative" style={{height: "100px"}}>
                    <FullFormLoader show={true}/>
                </div>
            }
            
        </div>
    )
}
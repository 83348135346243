import Datatable from "../../../components/Datatable/Datatable";
import { doRequest, doRequestSaveRes } from "../../../helpers/requests";
import * as Yup from "yup";
import { useFormik } from "formik";
import FullFormLoader from "../../../components/Loader/FullFormLoader";
import ModalDelete from "../../../components/Modal/ModalDelete";
import { useState } from "react";
import { Button, Col, Collapse, Row } from "reactstrap";
import { NumberInput } from "../../../components/GenericInputsFormik/NumberInput";
import { SelectTypeHeadSingle } from "../../../components/GenericInputsFormik";
import { useEffect } from "react";
import ButtonInputM from "../../../components/GenericInputsFormik/ButtonInputModal";
import ModalConfirmation from "../../../components/Modal/ModalConfirmation";
import { toast } from "react-toastify";
import Notification from "../../../components/Notification/Notification";
import { validValue } from "../../../libs/tools/format";

const headers = ["Bien", "Cantidad", "Periodo", "Editar", "Eliminar"];

const columnDefs = [
    {
        orderable: true,
        targets: [1],
        className: "text-right",
    },
    {
        orderable: false,
        targets: [3, 4],
        className: "text-center",
    },
];

const defaultForm = { idDetail: "", keyTypeDelivery: "", total: "", idFurniture: "", nameBien: "" };

const FormSchema = Yup.object().shape({
    keyTypeDelivery: Yup.string().required("Seleccione el periodo de recepción"),
    total: Yup.number().required("Ingrese la cantidad a solicitar").min(1, "Cantidad del artículo insufuciente"),
    nameBien: Yup.string().required("Seleccione un artículo"),
});

export default function Articulos({id, periods, permissions}){
    const [loading, setLoading] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [modal, setModal] = useState(false);
    const [showForm, setShowForm] = useState(permissions.INS);

    const {
        handleSubmit,
        values,
        handleBlur,
        errors,
        touched,
        setFieldValue,
        handleReset,
        setValues,
    } = useFormik({
        initialValues: defaultForm,
        validationSchema: FormSchema,
        onSubmit: () => setModal(true),
        onReset: () => {
            if(!permissions.INS) setShowForm(false);
        },
        enableReinitialize: true,
    });

    const columns = [
        { data: "nameFurniture" },
        { data: "total" },
        { data: "nameTypeDelivery" },
        {
            data: null, render: (row) => permissions.UPD && <i className="fa fa-pencil text-warning cursor-pointer" onClick={()=>openToEdit(row)}/>
        },
        {
            data: null,
            render: (row) => (
                permissions.DEL &&
                <ModalDelete
                    modalTitle="Eliminar"
                    table=""
                    nameColumn={[""]}
                    elimina={fnDelete}
                    id={row.idDetail}
                >
                    <h6>
                        Está seguro de eliminar el atículo{" "}
                        {row.nameFurniture} de la requisición?
                    </h6>
                    No podrá revertir esta acción
                </ModalDelete>
            ),
        },
    ];
    
    const modalTablaParams = {
        title: "Bienes",
        headers: ["Nombre", "Modelo", "Marca", "Material"],
        columns: [
            {data: "nameFurniture"},
            {data: "model"},
            {data: "brand"},
            {data: "nameMaterial"}
        ],
        columnDefs: [{className: "text-center", targets: [3]}],
        params: {
            table: "furnitures A INNER JOIN materials B USING(keyMaterial)",
            rows: "A.nameFurniture, A.model, A.brand, B.nameMaterial, A.idFurniture",
            conditions: "A.enabled = 1"
        }
    }

    function onChange({target}){
        setFieldValue(target.name, target.value);
    }
    
    async function getData(ordCol, itemsPerPage, currentPage, search) {
        const res = await doRequestSaveRes(
            "receiver/receiver.php",
            getParams(ordCol, itemsPerPage, currentPage, search),
            false,
            false
        );
        if (res.code === "200") return res;
        else return null;
    }

    function getParams(ordCol, itemsPerPage, currentPage, search) {
        const params = {
            action: "datatable",
            table: "warehouserequerimentdetail A INNER JOIN furnitures B USING(idFurniture) INNER JOIN types_delivery C USING(keyTypeDelivery)",
            rows: "B.nameFurniture, A.total, C.nameTypeDelivery, A.idDetail, A.keyTypeDelivery, A.idFurniture",
            conditions: `A.idRequeriment = ${id} AND A.enabled = 1`,
            order: ordCol,
            records: itemsPerPage.toString(),
            page: (itemsPerPage * currentPage).toString(),
            search
        };
        return params;
    }

    async function fnDelete(id) {
        setLoading(true);
        const params = {
            action: "delete",
            table: "warehouserequerimentdetail",
            condition: {
                idDetail: id,
            },
            force: 1
        };
        const res = await doRequest("receiver/receiver.php", params, true);
        if (res.length > 0) {
            setRefresh(true);
            if(+values.idDetail === +id) handleReset();
        }
        setLoading(false);
    }

    function selectRow(row){
        setFieldValue("idFurniture", row.idFurniture);
        setFieldValue("nameBien", row.nameFurniture);
    }

    function clearInput(){
        setFieldValue("idFurniture", "");
        setFieldValue("nameBien", "");
    }

    async function save(){
        setLoading(true);
        const params = {
            action: "insert",
            table: "warehouserequerimentdetail",
            rows: {...values, idRequeriment: id},
            validate: [["idRequeriment", "idFurniture"]]
        }
        delete params.rows.nameBien;
        if(values.idDetail){
            params.condition = { idDetail: values.idDetail };
            params.action = "update";
        }
        const res = await doRequest("receiver/receiver.php", params, true);
        if(res.length > 0) {
            handleReset();
            setRefresh(true);
        }
        setLoading(false);
    }

    function openToEdit(row){
        if(!showForm) setShowForm(true);
        if(!validValue(values.keyTypeDelivery) && !validValue(values.total) && !validValue(values.idFurniture) ){
            setValues({
                idDetail: row.idDetail, 
                idFurniture: row.idFurniture, 
                keyTypeDelivery: +row.keyTypeDelivery,
                nameBien: row.nameFurniture,
                total: row.total
            }, true);
        }else{
            toast(
                <Notification
                  type={"warning"}
                  backMessage="Guarde los cambios antes de continuar"
                  withIcon
                />,
                { closeButton: false }
              );
        }
    }

    return(
        <div>
            <h5>Artículos</h5>
            
                <Collapse isOpen={(permissions.INS || showForm)}>
                    <form onSubmit={handleSubmit} onReset={handleReset}>
                    <Row>
                        <Col xs={12} md={6}>
                        <ButtonInputM
                            label="Bienes"
                            inputName="idFurniture"
                            value={values.nameBien}
                            touched={touched.nameBien}
                            errors={errors.nameBien}
                            isRequired={true}
                            modalParams={modalTablaParams}
                            selectRow={selectRow}
                            clearFun={clearInput}
                        />
                        </Col>
                        <Col xs={12} md={6}>
                            <SelectTypeHeadSingle
                                label="Periodo"
                                isRequired={true}
                                inputName="keyTypeDelivery"
                                optionsArray={periods}
                                defaultOption="Seleccionar un país"
                                onChangeMethod={onChange}
                                onBlur={handleBlur}
                                value={values.keyTypeDelivery}
                                touched={touched.keyTypeDelivery}
                                errors={errors.keyTypeDelivery}
                                optionValue="keyTypeDelivery"
                                optionName="nameTypeDelivery"
                            />
                        </Col>
                        <Col xs={12} md={6}>
                            <NumberInput
                                label="Cantidad"
                                inputName="total"
                                onChangeMethod={onChange}
                                onBlurMethod={handleBlur}
                                value={values.total}
                                touched={touched.total}
                                errors={errors.total}
                                isRequired
                            />
                        </Col>
                    </Row>
                    <div className="mb-5 d-flex flex-md-row flex-column-reverse justify-content-md-around">
                        <Button color="danger" type="reset" className="mt-3">
                            Cancelar
                        </Button>
                        <Button color="success" type="submit" className="mt-2 mt-md-3">
                            Guardar
                        </Button>
                    </div>
                </form>
                </Collapse>
            
            
            <Datatable
                headers={headers}
                columns={columns}
                columnDefs={columnDefs}
                petition={getData}
                control="back"
                stateRefresh={[refresh, setRefresh]}
                searching={true}
                className="p-0"
            />

            <ModalConfirmation
                modalTitle={(values.idDetail? "Editar": "Agregar" ) +" artículo"}
                modal={modal}
                setModal={setModal}
                crear={save}
            >
                <div className="d-flex justify-content-center">
                    <h6>¿Está seguro de {values.idDetail? "editar el articulo" : "Agregar este artículo a la requisición"} ?</h6>
                </div>
            </ModalConfirmation>

            <FullFormLoader show={loading} />
        </div>
    )
}
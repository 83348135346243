import React, { useEffect, useRef, useState } from "react";
import JsBarcode from "jsbarcode";

const CodigoBarras = (props) => {
  const { value } = props;
  const canvasRef = useRef(null);

  function generateBarCode() {
    let barCode = value.toString(); 
    while (barCode.length < 10) {
      barCode = "0" + barCode;
    }
    return barCode;
  }

  useEffect(() => {
    JsBarcode(canvasRef.current, generateBarCode(),{
        displayValue: false,
    });
  }, [value]);
  return (
      <canvas
        ref={canvasRef}
        id={value}
        style={{ display:"none" }}
      ></canvas>
    
  );
};

export default CodigoBarras;

import * as XlsxPopulate from "xlsx-populate";
import { saveAs } from "file-saver";
import getLayout from "../../../../components/excel/layoutExcel";
import { fDatewhitTime, fMoney } from "../../../../libs/tools/format";

const s = {
    tC: { horizontalAlignment: "center", verticalAlignment: "center" },
    tS: { horizontalAlignment: "left" },
    tE: { horizontalAlignment: "right", verticalAlignment: "center" },
    header: {
        horizontalAlignment: "center",
        bold: true,
        verticalAlignment: "center",
        fill: "730A29",
        fontColor: "FFFFFF",
        wrapText: true,
        shrinkToFit: true,
        border: true,
    },
    tt: {
        bold: true,
        horizontalAlignment: "center",
        verticalAlignment: "center",
        wrapText: true,
        shrinkToFit: true,
        fontSize: 13
    },
    tB: { bold: true },
    tN: {
        border: true,
        wrapText: true,
        shrinkToFit: true,
        verticalAlignment: "center",
    },
    tNC: {
        horizontalAlignment: "center",
        verticalAlignment: "center",
        wrapText: true,
        shrinkToFit: true,
        border: true,
    },
    tNE: {
        horizontalAlignment: "right",
        verticalAlignment: "center",
        wrapText: true,
        shrinkToFit: true,
        border: true,
    },
    wt: { wrapText: true, verticalAlignment: "center" },
};

export async function Detalle(data, titles) {
    const excel = await getLayout();
    const headers = [
        "",
        "NO.",
        "FOLIO",
        "ADSCRIPCIÓN",
        "JUSTIFICACIÓN",
        "FECHA RECEPCIÓN",
        "FECHA ATENCIÓN",
        "MONTO SOLICITADO",
        "ESTATUS",
        "CLAVE PROYECTO",
        "PROYECTO PRESUPUESTAL",
        "PARTIDA",
        "DENOMINACIÓN",
        "DESCRIPCIÓN",
        "MONTO PARTIDA",
        "ENERO",
        "FEBRERO",
        "MARZO",
        "ABRIL",
        "MAYO",
        "JUNIO",
        "JULIO",
        "AGOSTO",
        "SEPTIEMBRE",
        "OCTUBRE",
        "NOVIEMBRE",
        "DICIEMBRE",
        "OBSERVACIONES",
    ];

    const sizes = [
        -1,
        14,
        18,
        38,
        50,
        25,
        25,
        20,
        25,
        15,
        35,
        13,
        30,
        25,
        20,
        20,
        20,
        20,
        20,
        20,
        20,
        20,
        20,
        20,
        20,
        20,
        20,
        40];

    if (excel)
        XlsxPopulate.fromDataAsync(excel).then(async (workbook) => {
            const hoja = workbook.sheet(0);
            let cRow = 3;
            hoja.range(`D${cRow}:E${cRow}`).merged(true).value("Poder Judicial del Estado de México").style(s.tt);
            cRow++;
            hoja.range(`D${cRow}:E${cRow}`).merged(true).value("Reporte Suficiencias Presupuestales").style(s.tt);

            if(titles.length > 0){
                titles.forEach((t)=>{
                    cRow++;
                    hoja.range(`D${cRow}:E${cRow}`).merged(true).value(t).style(s.tt);
                });
            }
            cRow += 2;

            for (let i = 1; i < headers.length; i++) {
                hoja.column(i).width(sizes[i]);
                hoja.row(cRow).cell(i).value(headers[i]).style(s.header);
            }
            data.forEach((el, ix) => {
                cRow++;
                const meses = el.meses.split(",");
                hoja.row(cRow).cell(1).value(ix+1).style(s.tNC);
                hoja.row(cRow).cell(2).value(el.folio).style(s.tNC);
                hoja.row(cRow).cell(3).value(el.nameArea).style(s.tN);
                hoja.row(cRow).cell(4).value(el.justification).style(s.tN);
                hoja.row(cRow).cell(5).value(fDatewhitTime(el.dateSend)).style(s.tNC);
                hoja.row(cRow).cell(6).value(fDatewhitTime(el.dateApproved)).style(s.tNC);
                hoja.row(cRow).cell(7).value(fMoney(el.requestedAmount)).style(s.tNE);
                hoja.row(cRow).cell(8).value(el.nameStat).style(s.tNC);
                hoja.row(cRow).cell(9).value(el.stateFundKey).style(s.tNC);
                hoja.row(cRow).cell(10).value(el.nameStateFund).style(s.tN);
                hoja.row(cRow).cell(11).value(el.fullKey).style(s.tNC);
                hoja.row(cRow).cell(12).value(el.nameObject).style(s.tN);
                hoja.row(cRow).cell(13).value(el.concept).style(s.tNC);
                hoja.row(cRow).cell(14).value(fMoney(el.remain)).style(s.tNE);
                hoja.row(cRow).cell(15).value(fMoney(meses[0])).style(s.tNE);
                hoja.row(cRow).cell(16).value(fMoney(meses[1])).style(s.tNE);
                hoja.row(cRow).cell(17).value(fMoney(meses[2])).style(s.tNE);
                hoja.row(cRow).cell(18).value(fMoney(meses[3])).style(s.tNE);
                hoja.row(cRow).cell(19).value(fMoney(meses[4])).style(s.tNE);
                hoja.row(cRow).cell(20).value(fMoney(meses[5])).style(s.tNE);
                hoja.row(cRow).cell(21).value(fMoney(meses[6])).style(s.tNE);
                hoja.row(cRow).cell(22).value(fMoney(meses[7])).style(s.tNE);
                hoja.row(cRow).cell(23).value(fMoney(meses[8])).style(s.tNE);
                hoja.row(cRow).cell(24).value(fMoney(meses[9])).style(s.tNE);
                hoja.row(cRow).cell(25).value(fMoney(meses[10])).style(s.tNE);
                hoja.row(cRow).cell(26).value(fMoney(meses[11])).style(s.tNE);
                hoja.row(cRow).cell(27).value(el.notes).style(s.tN);
            });

            return workbook.outputAsync().then((res) => {
                saveAs(res, `Reporte Suficiencias Presupuestales Detalle.xlsx`);
            });
        });
}


import { Document, Page, StyleSheet, Text, View } from "@react-pdf/renderer";
import HeaderReport from "../../../../components/Pdf/Header";
import { fMoney } from "../../../../libs/tools/format";
import FooterReport from "../../../../components/Pdf/Footer";

export default function PdfPrestacionesExtraordinarias(data, cfg, title, fileNo) {
    return {
        report: (
            <Document>
                <Page style={s.body} orientation="landscape">
                    <HeaderReport />
                    <View style={[s.title, s]}>
                        <View><Text>Prestaciones Extraordinarias</Text></View>
                        <View><Text>{cfg.staff.staffNumber} - {cfg.staff.fullName}</Text></View>
                    </View>
                    <View style={[s.fs9]}>
                        <View style={[s.row, s.border, s.header]} fixed>
                            <View style={[s.borderHE, s.w20]}><Text>Concepto</Text></View>
                            <View style={[s.borderHE, s.w10]}><Text>Monto total</Text></View>
                            <View style={[s.borderHE, s.w10]}><Text>Monto Quincenal</Text></View>
                            <View style={[s.borderHE, s.w10]}><Text>Periodo de inicio</Text></View>
                            <View style={[s.borderHE, s.w10]}><Text>Periodo termino</Text></View>
                            <View style={[s.borderHE, s.w10]}><Text>Pagos</Text></View>
                            <View style={[s.borderHE, s.w10]}><Text>Pagos aplicados</Text></View>
                            <View style={[s.w20]}><Text>Estatus</Text></View>
                        </View>
                        <View>
                            {
                                data.map((d, ix) => (
                                    <View style={[s.row, s.borderD]} key={ix} wrap={false}>
                                        <View style={[s.mCell, s.borderE, s.w20]}><Text>{d.under} - {d.nameUnder}</Text></View>
                                        <View style={[s.mCell, s.borderE, s.w10, s.tend]}><Text>{fMoney(d.amountTotal)}</Text></View>
                                        <View style={[s.mCell, s.borderE, s.w10, s.tend]}><Text>{fMoney(d.amountBiweekly)}</Text></View>
                                        <View style={[s.mCell, s.borderE, s.w10, s.tcenter]}><Text>{`${d.startPeriod}/${d.startYear}`}</Text></View>
                                        <View style={[s.mCell, s.borderE, s.w10, s.tcenter]}><Text>{`${d.endPeriod}/${d.endYear}`}</Text></View>
                                        <View style={[s.mCell, s.borderE, s.w10, s.tend]}><Text>{d.noPays}</Text></View>
                                        <View style={[s.mCell, s.borderE, s.w10, s.tend]}><Text>{d.noPaysMade}</Text></View>
                                        <View style={[s.mCell, s.w20]}><Text>{d.nameStat}</Text></View>
                                    </View>
                                ))
                            }
                        </View>
                    </View>
                    <FooterReport fileNo={fileNo} />
                </Page>
            </Document>
        ),
    };
}
const s = StyleSheet.create({
    body: {
        paddingVertical: "30px",
        paddingHorizontal: "20px",
    },

    title: {
        fontSize: "13px",
        textAlign: "center",
        fontFamily: "Helvetica-Bold",
        marginTop: "5px",
        marginBottom: "10px"
    },

    header: {
        backgroundColor: "#730A29",
        textAlign: "center",
        color: "#FFFFFF",
        fontWeight: "bold"
    },

    mCell: { padding: "1px" },
    mxCell: { paddingHorizontal: "2px" },

    vCenter: { alignItems: "center", justifyContent: "center" },
    vCenterL: { alignItems: "flex-start", justifyContent: "center",  },
    vCenterR: { alignItems: "flex-end", justifyContent: "center" },
    vCenterBtw: { alignItems: "stretch", justifyContent: "center" },

    dflexBtw: { display: "flex", justifyContent: "space-between" },
    dflexEnd: { display: "flex", justifyContent: "flex-end" },

    alignE: { alignContent: "flex-end" },

    row: { flexDirection: "row" },
    flexWrap: { display: "flex", flexWrap: "wrap", flexDirection: "row" },

    w5: { width: "5%" },
    w8: { width: "8%" },
    w10: { width: "10%" },
    w125: { width: "12.5%" },
    w15: { width: "15%" },
    w20: { width: "20%" },
    w21: { width: "21.1%" },
    w25: { width: "25%" },
    w30: { width: "30%" },
    w33: { width: "33%" },
    w34: { width: "34%" },
    w36: { width: "36%" },
    w29: { width: "28.9%" },
    w40: { width: "40%" },
    w45: { width: "45%" },
    w50: { width: "50%" },
    w55: { width: "55%" },
    w60: { width: "60%" },
    w65: { width: "65%" },
    w80: { width: "80%" },
    w90: { width: "90%" },
    w100: { width: "100%" },
    wAuto: { width: "auto" },

    fborder: { border: "1px solid #5D6D7E" },
    border: { border: "1px solid #5D6D7E", borderBottom: "0px" },
    borderT: { borderBottom: "1px solid #5D6D7E" },
    borderB: { borderBottom: "1px solid #5D6D7E" },
    borderE: { borderRight: "1px solid #5D6D7E" },
    borderS: { borderLeft: "1px solid #5D6D7E" },
    borderHE: { borderRight: "1px solid #5D6D7E" },
    borderD: { border: "1px solid #5D6D7E", borderTop: "0px" },
    borderU: { border: "1px solid #5D6D7E", borderBottom: "0px" },

    bg: { backgroundColor: "#BEBEBE" },
    bg2: { backgroundColor: "#BEBEBE" },
    bglg: { backgroundColor: "#adb5bd" },

    fs7: { fontSize: "7px" },
    fs8: { fontSize: "8px" },
    fs9: { fontSize: "9px" },
    fs10: { fontSize: "10px" },
    fs11: { fontSize: "11px" },
    fs12: { fontSize: "12px" },
    fs13: { fontSize: "13px" },

    tend: { textAlign: "right", paddingRight: "2px" },
    tstart: { textAlign: "left" },
    tcenter: { textAlign: "center" },
    tBold: { fontFamily: "Helvetica-Bold" },

    mT1: { marginTop: "1px" },
    mT2: { marginTop: "2px" },
    mT8: { marginTop: "8px" },
    mT30: { marginTop: "30px" },
    mT20: { marginTop: "20px" },
    mT70: { marginTop: "70px" },
    mx5: { marginHorizontal: "5px" },
    mx18: { marginHorizontal: "18px" },
    mx20: { marginHorizontal: "20px" },
    mb2: { marginBottom: "2px" },

    spaceFooter: {
        bottom: 10,
        left: 0,
        right: 0,
        width: "auto",
        height: 30,
        marginHorizontal: 10,
    },
});

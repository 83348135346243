import React from "react";
import { useState } from "react";
import { Col, Row, Button } from "reactstrap";
import FullFormLoader from "../../../../../components/Loader/FullFormLoader";
import ModalReports from "../../../../../components/Modal/ModalReports";
import { peticionesReceiver } from "../../../../../helpers/peticionesReceiver";
import { toast } from "react-toastify";
import Notification from "../../../../../components/Notification/Notification";
import { saveHistorialEmpleadoAsExcel } from "./ExcelHistorialEmpleado";
import { generatePdfHistorialEmpleado } from "./PdfHistorialEmpleado";
import { useEffect } from "react";

const Reportes = (props) => {
  const { staff } = props;

  useEffect(() => {
  }, []);

  const [modalReports, setModalReports] = useState(false);
  const [report, setReport] = useState("");
  const [loader, setLoader] = useState(false);
  const [pdfConfig, setPdfConfig] = useState("");
  const API = peticionesReceiver();

  

  const getReport = async () => {
    setPdfConfig({
      id: { id: "year" },
      staffInfo: staff,
      headers: [
        "Año",
        "Sueldo",
        "Antiguedad en quincenas",
        "Monto de beneficio",
      ],
      content: [
        [
          { header: "Año", value: "year" },
          { header: "Sueldo", value: "amountYear" },
          { header: "Antiguedad en quincenas", value: "beweeklys" },
          { header: "Monto de beneficio", value: "mount" },
        ],
      ],
    });
    setReport("");
    const params = {
      action: "datatable",
      table: "foremex",
      rows: "idForemex,year, amountYear, beweeklys, mount",
      conditions: `enabled = 1 and idStaff=${staff.idStaff}`,
      page: 0,
      records: 5,
      search: "",
      order: "",
    };

    

    let report = null;
    await API.peticion(params)
      .then((res) => {
        if (
          res.status === 200 &&
          res.data.code === "200" &&
          res.data.message === "Se ejecuto correctamente la consulta"
        ) {
          setReport(res.data.data);
          report = res.data.data;
        } else {
          if(res.data.data.length < 1 ){
            toast( 
              <Notification
                type={"consultar_error"}
                backMessage={"No se encontraron datos"}
                withIcon
              />,
              { closeButton: false }
            );
              
          }else{
            toast( 
              <Notification
                type={"consultar_error"}
                backMessage={res.data.message}
                withIcon
              />,
              { closeButton: false }
            );
          }
          
        }
      })
      .finally(() => {
        setLoader(false);
      })
      .catch((err) => {
        toast(<Notification type={"consultar_servidor_error"} withIcon />, {
          closeButton: false,
        });
      });

    return report;
  };

  const generateExcel = async () => {
    const config = {
      id: { id: "year" },
      staffInfo: staff,
      headers: [
        "Año",
        "Sueldo",
        "Antiguedad en quincenas",
        "Monto de beneficio",
      ],
      content: [
        [
          { header: "Año", value: "year" },
          { header: "Sueldo", value: "amountYear" },
          { header: "Antiguedad en quincenas", value: "beweeklys" },
          { header: "Monto de beneficio", value: "mount" },
        ],
      ],
    }


    const rep = await getReport();
    if (rep !== null && rep !== undefined && rep !== "") {
      saveHistorialEmpleadoAsExcel(
        rep,
        config.headers,
        "FOREMEX POR EMPLEADO",
        config,
        ""
      );
    }
  };

  const generatePdf = async () => {
    const rep = await getReport();
    if (rep !== null && rep !== undefined && rep !== "") {
      setModalReports(true);
    }
  };

  /* useEffect(() => {
    if(report !== "" && !modalReports){
      
    }
  },[report]) */

  /* useEffect(() => {
    if(report !== "" && !modalReports){
      saveAsExcel(
        report,
        pdfConfig.headers,
        "REPORTES DE BIENES POR PERSONA",
        pdfConfig,
        ""
      );
    }
  },[report]) */

  return (
    <div>
      <Row className="mt-5 d-flex justify-content-around">
        <Col xs="12" lg="4" className="text-center mt-3 mb-3">
          <Button
            color="success"
            onClick={() => {
              generatePdf();
            }}
          >
            Generar Pdf
          </Button>
        </Col>
        <Col xs="12" lg="4" className="text-center mt-3 mb-3">
          <Button
            color="success"
            onClick={() => {
              generateExcel();
            }}
          >
            Generar Excel
          </Button>
        </Col>
      </Row>

      <FullFormLoader
        show={loader}
        message="Consultando datos , espere un momento"
      />

      {modalReports && report !== "" && (
        <ModalReports
          modal={modalReports}
          setModal={setModalReports}
          title={"BIENES POR PERSONA"}
          backdrop={"static"}
          keyboard={false}
          report={report}
          pdfConfig={pdfConfig}
          generatePdfMethod={{
            method: generatePdfHistorialEmpleado,
            type: "general",
          }}
          records={1000}
        />
      )}
    </div>
  );
};

export default Reportes;

import React, { useState, useEffect } from "react";

import { Col, Row, Button } from "reactstrap";
import { Formik, Form, Field, ErrorMessage, useFormikContext } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import Notification from "../../../components/Notification/Notification";
import Loader from "../../../components/Loader/Loader";
import { useHistory } from "react-router-dom";
import ModalConfirmation from "../../../components/Modal/ModalConfirmation";
import { peticionesReceiver } from "../../../helpers/peticionesReceiver";
import { SelectTypeHeadSingle } from "../../../components/GenericInputsFormik/SelectTypeHeadSingle";

const defaultForm = {
  keySystem: "",
  nameProfile: "",
  keySystem: "",
};

const FormPerfil = ({ crear, data, editar }) => {
  const history = useHistory();
  const API = peticionesReceiver();
  const [form] = useState(defaultForm);
  const [sistemas, setSistemas] = useState();
  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(true);

  const getSistemas = async () => {
    const data = {
      action: "select",
      table: "apparatus",
      order: "nameSystem ASC",
    };
    API.peticion(data)
      .then((res) => {
        if (res.status === 200 && res.data.code === "200") {
          setSistemas(res.data.data);
        } else {
          toast(<Notification type={"consultar_error"} withIcon />);
        }
        setLoading(false);
      })
      .catch((err) => {
        toast(<Notification type={"consultar_servidor_error"} withIcon />);
        setLoading(false);
      });
  };

  useEffect(() => {
    getSistemas();
  }, []);

  const FormSchema = Yup.object().shape({
    nameProfile: Yup.string()
      .min(3, "Nombre debe contener al menos 3 letras")
      .required("Ingrese un nombre"),
    keySystem: Yup.string().required("Seleccione un sistema"),
  });

  const submitFunction = (fields) => {
    setModal(true);
  };

  const cancelOp = () => {
    history.push("/template/perfiles");
  };

  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <Formik
          initialValues={form}
          validationSchema={FormSchema}
          onSubmit={(fields) => {
            submitFunction(fields);
          }}
          onReset={(fields) => {
            cancelOp();
          }}
        >
          {({ errors, status, touched, values, handleBlur, setFieldValue }) => (
            <Form className="mt-4">
              <ModalConfirmation
                modalTitle={data ? "Editar" : "Crear"}
                modal={modal}
                setModal={setModal}
                crear={crear}
                editar={editar}
                isEdit={data ? true : false}
                values={values}
              >
                {data ? (
                  <div className="d-flex justify-content-center">
                    <h6>¿Está seguro de editar el registro?</h6>
                  </div>
                ) : (
                  <div className="d-flex justify-content-center">
                    <h6>¿Desea continuar con el registro?</h6>
                  </div>
                )}
              </ModalConfirmation>
              <Row>
                <Col xs="12" md="6">
                  <div className="form-group">
                    <label htmlFor="nameProfile">
                      Nombre de perfil<span className="text-danger">*</span>
                    </label>
                    <Field
                      name="nameProfile"
                      type="text"
                      placaceholder="Nombre del perfil"
                      className={
                        "form-control" +
                        (errors.nameProfile && touched.nameProfile
                          ? " is-invalid"
                          : "")
                      }
                    />
                    <ErrorMessage
                      name="nameProfile"
                      component="div"
                      className="invalid-feedback"
                    />
                  </div>
                </Col>

                <Col xs="12" md="6">
                  <SelectTypeHeadSingle
                    label="Sistema"
                    inputName="keySystem"
                    onChangeMethod={(e) =>
                      setFieldValue([e.target.name], e.target.value)
                    }
                    onBlurMethod={handleBlur}
                    value={values.keySystem}
                    isRequired={true}
                    touched={touched.keySystem}
                    errors={errors.keySystem}
                    optionsArray={sistemas}
                    optionValue="keySystem"
                    optionName="nameSystem"
                    defaultOption="Seleccione un sistema"
                  />
                </Col>
              </Row>

              

              <div className=" d-flex flex-md-row flex-column-reverse justify-content-md-around">
                <Button color="danger" type="reset" className="mt-3">
                  Cancelar
                </Button>
                <Button color="success" type="submit" className="mt-2 mt-md-3">
                  Guardar
                </Button>
              </div>
              <FormikData info={data} />
            </Form>
          )}
        </Formik>
      )}
    </div>
  );
};

const FormikData = ({ info }) => {
  const { setFieldValue } = useFormikContext();
  useEffect(() => {
    if (info) {
      setFieldValue("keyProfile", info[0]);
      setFieldValue("nameProfile", info[1]);
      setFieldValue("keySystem", info[3]);
    }
  }, [info]);
  return null;
};

export default FormPerfil;

import React, { useState } from "react";
import TableComponentReport from "../../../components/TableComponent/TableComponentReport";
import { peticionesReceiver } from "../../../helpers/peticionesReceiver";
import { toast } from "react-toastify";
import Notification from "../../../components/Notification/Notification";
import { Collapse } from "reactstrap";
import Widget from "../../../components/Widget/Widget";
import * as Yup from "yup";
import { useFormik } from "formik";
import FormTrapasos from "./form/FormTraspasos";
import ModalReports from "../../../components/Modal/ModalReports";
import { generatePdfTraspasos } from "./pdf/PdfTraspasos";
import { saveExcelTraspasos } from "./excel/Traspasos";
import { GetPermissions } from "../../../libs/permissions/getPermissions";

const defaultData = {
  idFormat: "",
  startDate: "",
  endDate: "",
};

const cabeceras = ["IdINDEX", "FOLIO", "MOTIVO", "MONTO", "FECHA", "CONSULTAR"];
const cabecerasDetails = [
  "Id",
  "DATOS DE TRASPASO",
  "MES DE ORIGEN",
  "PARTIDA DE ORIGEN",
  "MES DESTINO",
  "PARTIDA DE DESTINO",
  "MONTO",
];

const ConsultaTrapasos = () => {
  const [permissions, setPermissions] = useState({});
  GetPermissions(setPermissions);

  const API = peticionesReceiver();

  const [modalReports, setModalReports] = useState(false);
  const [details, setDetails] = useState("");


  /* -----------------------Report------------------------ */
  const [collapseReport, setCollapseReport] = useState(false);
  const [parametrosReport, setParametrosReport] = useState("");
  const [pdfConfigReport] = useState({
    headers: ["FOLIO", "MOTIVO", "MONTO", "FECHA", "CONSULTAR"],
    id: { id: "idTransfer" },
    content: [
      [
        { header: "FOLIO", value: "number", columns: 1 },
        { header: "MOTIVO", value: "description", columns: 1 },
        { header: "MONTO", value: "amount", columns: 1 },
        { header: "FECHA", value: "created", columns: 3 },
        { header: "transfer", value: "created", columns: 3 },
      ],
    ],
  });

  const searchReport = (values) => {
    setCollapseDetails(false);

    if(values.startDate !== null && values.startDate !== "" && values.startDate !== undefined){
      setParametrosReport(
        {
          action: "report35",
          startDate: values.startDate,
          endDate: values.endDate,
          page: 1,
          records: 5,
          search: "",
          order: "",
        }
      )
    }else{
      setParametrosReport(
        {
          action: "report35",
          page: 1,
          records: 5,
          search: "",
          order: "",
        }
      )

    }

    

    setCollapseReport(true);
  };


  /* -----------------------DETAILS------------------------ */
  const [collapseDetails, setCollapseDetails] = useState(false);
  const [parametrosDetail, setParametrosDetail] = useState();
  const [pdfConfigDetail, setPdfConfigDetail] = useState({
    headers: [
      "TIPO DE TRASPASO",
      "MES ORIGEN",
      "PARTIDA ORIGEN ",
      "MES DESTINO",
      "PARTIDA SOLICITADA ",
      "MONTO",
    ],
    id: { id: "number" },
    content: [
      [
        /* { header: "DATOS DE TRASPASO", value: "number", columns: 1 }, */
        { header: "TIPO DE TRASPASO", value: "nameTypeTransfer", columns: 1 },
        { header: "MES ORIGEN", value: "monthOrigin", columns: 1 },
        { header: "PARTIDA ORIGEN ", value: "sfOrigin", columns: 1 },
        { header: "MES DESTINO", value: "monthDest", columns: 1 },
        { header: "PARTIDA SOLICITADA ", value: "sfDest", columns: 1 },
        { header: "MONTO", value: "amount", columns: 1 },
      ],
    ],
  });
  const [pdfConfigDetailTable, setPdfConfigDetailTable] = useState({
    headers: [
      "PERIODO DE TRASPASO",
      "TIPO DE TRASPASO",
      "MES ORIGEN",
      "PARTIDA ORIGEN 1",
      "PARTIDA ORIGEN 2",
      "MES DESTINO",
      "PARTIDA SOLICITADA 1",
      "PARTIDA SOLICITADA 2",
      "MONTO",
    ],
    id: { id: "number" },
    content: [
      [
        { header: "DATOS DE TRASPASO", value: "number", columns: 1 },
        { header: "PERIODO DE TRASPASO", value: "nameTypeTransfer", columns: 1 },
        { header: "MES ORIGEN", value: "monthOrigin", columns: 1 },
        { header: "PARTIDA ORIGEN 1", value: "sfOrigin", columns: 1 },
        { header: "PARTIDA ORIGEN 2", value: "noOrigin", columns: 1 },
        { header: "MES DESTINO", value: "monthDest", columns: 1 },
        { header: "PARTIDA SOLICITADA 1", value: "sfDest", columns: 1 },
        { header: "PARTIDA SOLICITADA 2", value: "noDest", columns: 1 },
        { header: "MONTO", value: "amount", columns: 1 },
      ],
    ],
  });

  const FormSchema = Yup.object().shape({
    idFormat: Yup.string().required("Seleccione un formato"),
    /* endDate: Yup.date.when("startDate") */
  });

  const {
    handleSubmit,
    values,
    handleBlur,
    errors,
    touched,
    setFieldValue,
    resetForm,
  } = useFormik({
    initialValues: defaultData,
    onSubmit: (values) => {
      searchReport(values);
    },
    validationSchema: FormSchema,
    enableReinitialize: true,
    onReset: () => {},
  });

  const onChange = (e) => {
    setCollapseDetails(false);
    
    if (e.target.name === "startDate") {
      setFieldValue("endDate", ""); 
    }
    setFieldValue([e.target.name], e.target.value);
  };

  const iconDetails = (value, key) => {
    return (
      <i
        className={
          values.idFormat === 3
            ? "fa fa-table text-info"
            : values.idFormat === 2
            ? "fa fa-file-excel-o text-success"
            : values.idFormat === 1 && "fa fa-file-pdf-o text-danger"
        }
        key={key}
        onClick={() => openDetail(value)}
        style={{ cursor: "pointer" }}
      ></i>
    );
  };
  const openDetail = async (value) => {
    if (values.idFormat === 1 || values.idFormat === 2) {
      const params = {
        action: "report35Detail",
        idTransfer: value[0],
      };
      const data = await getReport(params);
      if (data !== null && data.length > 0) {
        if (values.idFormat === 1) {
          setDetails(data);
          setModalReports(true);
        } else {
          saveExcelTraspasos(
            data,
            pdfConfigDetail.headers,
            "Reporte de traspasos",
            pdfConfigDetail,
            "Filtro"
          );
        }
      } else {
      }
    } else {
      setParametrosDetail({
        action: "report35Detail",
        idTransfer: value[0],
        page: 1,
        records: 5,
        search: "",
        order: "",
      });
      
      setCollapseDetails(true);
    }

    /* setParametrosDetail({
      action: "report35Detail",
      idTransfer: value[0],
      page: 1,
      records: 5,
      search: "",
      order: "",
    }); */

    /* setCollapseReport(false) */
  };

  const getReport = async (params) => {
    let data = null;
    await API.peticionEndPoint(params, "app/facades/reports/reportsF.php")
      .then((res) => {
        if (res.status === 200 && res.data.code === "200") {
          data = res.data.data;
        } else {
          toast(
            <Notification
              type={"consultar_error"}
              backMessage={res.data.message}
              withIcon
            />,
            { closeButton: false }
          );
        }
      })
      .catch((err) => {
        toast(<Notification type={"consultar_servidor_error"} withIcon />, {
          closeButton: false,
        });
      });
    return data;
  };

  return (
    <Widget className="widget-p-md">
      <div className="mt-3 mb-5">
        <h2>Consulta de Traspasos</h2>
      </div>
      {permissions.CON && <form onSubmit={handleSubmit} className="mt-4" noValidate>
        <FormTrapasos
          onChange={onChange}
          handleBlur={handleBlur}
          values={values}
          touched={touched}
          errors={errors}
          setFieldValue={setFieldValue}
          collapseReport={collapseReport}
        />
      </form>}

        {parametrosReport !== "" &&  collapseReport && (
          <Collapse isOpen={collapseReport}>
            <TableComponentReport
              titulo={""}
              cabecerasTabla={cabeceras}
              parametros={parametrosReport}
              filtro={pdfConfigReport}
              reporte={35}
              specialColumns={[iconDetails]}
            />
          </Collapse>
        )}

      {modalReports && details !== "" ? (
        <ModalReports
          modal={modalReports}
          setModal={setModalReports}
          title={"REPORTES DE TRASPASOS"}
          backdrop={"static"}
          keyboard={false}
          report={details}
          pdfConfig={pdfConfigDetail}
          generatePdfMethod={{
            method: generatePdfTraspasos,
            type: "general",
          }}
          records={1000}
        />
      ) : (
        ""
      )}

      {parametrosDetail !== "" && collapseDetails && (
        <Collapse isOpen={collapseDetails}>
          <TableComponentReport
            titulo={"Detalles de traspaso"}
            cabecerasTabla={pdfConfigDetailTable.headers}
            cabeceras={cabecerasDetails}
            parametros={parametrosDetail}
            filtro={pdfConfigDetailTable}
            reporte={35}
          />
        </Collapse>
      )}
    </Widget>
  );
};

export default ConsultaTrapasos;

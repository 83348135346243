/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import { peticionesReceiver } from "../../../../../helpers/peticionesReceiver";
import { toast } from "react-toastify";
import Notification from "../../../../../components/Notification/Notification";
import { Loader } from "react-bootstrap-typeahead";
import { SelectTypeHeadSingle } from "../../../../../components/GenericInputsFormik/index";
import { TextAreaInput } from "../../../../../components/GenericInputsFormik/index";

const ModalAsignArea = (props) => {
  const {
    modal,
    setModal,
    hiddeOk,
    hiddeCancel,
    // size,
    centered,
    cancel,
    cancelOverride = false,
    detailTrazability,
    updateRoute,
    formatearFecha,
    setTicket
  } = props;

  const API = peticionesReceiver();

  const toggle = () => setModal("false");

  const CloseBtn = (
    <i className="eva eva-close cursor-pointer" onClick={toggle} />
  );

  const [topics, setTopics] = useState([]);
  const [loader, setLoader] = useState([]);

  useEffect(() => {
    getTopic();
  }, []);

  useEffect(() => {
    getTopic();
  }, [detailTrazability]);

  const getTopic = async () => {
    const params = {
      action: "multiselect",
      table: "topics",
      conditions: `enabled = 1 and keyTopic != ${detailTrazability.keyTopic}`,
      rows: "keyTopic,nameTopic",
      order: "nameTopic ASC",
    };
    API.peticion(params)
      .then((res) => {
        if (res.status === 200 && res.data.code === "200") {
          setTopics(res.data.data);
        } else {
          toast(
            <Notification
              type={"consultar_error"}
              backMessage={res.data.message}
              withIcon
            />
          );
        }
      })
      .catch((err) => {
        toast(<Notification type={"consultar_servidor_error"} withIcon />);
      });

    setLoader(false);
  };

  const FormSchema = Yup.object().shape({
    keyTopic: Yup.string().when("act", {
      is: (v) => {
        return modal === "closeTicket" ? false : true;
      },
      then: Yup.string().required("Seleccione un empleado"),
    }),
    notes: Yup.string().required(
      "Ingresen la razon por la que asigna el ticket"
    ),
  });

  const {
    handleSubmit,
    values,
    errors,
    setFieldValue,
    touched,
    handleBlur,
    resetForm,
  } = useFormik({
    initialValues: {
      keyTopic: "",
      notes: "",
    },
    onSubmit: () => {
      asignTicket();
    },
    validationSchema: FormSchema,
    enableReinitialize: true,
  });

  const onChange = (e) => {
    const { name, value } = e.target;
    setFieldValue([name], value);
  };

  useEffect(() => {
    if (!modal) {
      resetForm();
    }
  }, [modal]);


  const changeStatusToClose = async() => {
    

    const params = {
      action: "update",
      table: "tickets",
      rows: {
        keyStat: "146",
        dateClosed: formatearFecha(new Date()),
        dateSolved: formatearFecha(new Date())
      },
      condition: {
        idTicket: detailTrazability.idTicket,
      },
    };

    await API.peticion(params)
      .then((res) => {
        if (res.status === 200 && res.data.code === "200") {
          setTicket(res.data.data[0])
          
          toast(
            <Notification
              type={"agrega_exito"}
              backMessage={res.data.message}
              withIcon
            />
          );
        } else {
          toast(<Notification type={"consultar_error"} withIcon />);
        }
      })
      .catch((err) => {
        toast(<Notification type={"consultar_servidor_error"} withIcon />);
      });



  }

  const asignTicket = () => {
    let params = {};
    
    if (modal === "closeTicket") {

      params = {
        action: "insert",
        table: "tickettrazability",
        rows: {
          idTicket: detailTrazability.idTicket,
          keyTopic: detailTrazability.keyTopic,
          idTopicAgent: detailTrazability.idTopicAgent,
          notes: `12$/TICKET CERRADO12$/: ${values.notes}`,
          dateAssign: formatearFecha(new Date()),
          dateUpdated: formatearFecha(new Date()),
          enabled: 1,
        },
      };      
      
    } else {
      params = {
        action: "assignTicket",
        keyTopic: values.keyTopic,
        idTicket: detailTrazability.idTicket,
        description: values.notes,
      };
    }
    API.peticionEndPoint(params, modal === "closeTicket" ? "receiver/receiver.php" :  "app/facades/helpdesk/helpdeskF.php")
      .then( async(res) => {
        if (res.status === 200 && res.data.code === "200") {
          <Notification
            type={"agrega_exito"}
            backMessage={res.data.message}
            withIcon
          />;

          if (modal === "closeTicket"){

            await changeStatusToClose()

          }
          
          setModal("false");
          updateRoute();
        } else {
          toast(
            <Notification
              type={"consultar_error"}
              backMessage={res.data.message}
              withIcon
            />
          );
        }
      })
      .catch((err) => {
        toast(<Notification type={"consultar_servidor_error"} withIcon />);
      });

    setLoader(false);
  };

  return (
    <Modal
      isOpen={modal !== "false"}
      toggle={toggle}
      size={"lg"}
      centered={centered}
    >
      {loader ? (
        <div>
          <Loader />
        </div>
      ) : (
        <>
          <form onSubmit={handleSubmit}>
            <ModalHeader
              toggle={toggle}
              close={CloseBtn}
              className="headline-1"
            >
              <div className="text-center">
                <h1>
                  {" "}
                  {modal === "closeTicket"
                    ? "Cierre de ticket" 
                    : "Asignación a otra Areá"}{" "}
                </h1>
              </div>
            </ModalHeader>
            <ModalBody className="pb-2">
              {modal === "asignArea" && (
                <Row className="d-flex justify-content-center">
                  <Col md={12} lg={10}>
                    <SelectTypeHeadSingle
                      label="Tema a trasladar"
                      isRequired={true}
                      inputName="keyTopic"
                      optionsArray={topics}
                      defaultOption="Seleccione un tema"
                      onChangeMethod={onChange}
                      value={values.keyTopic}
                      optionValue="keyTopic"
                      optionName="nameTopic"
                      onBlurMethod={handleBlur}
                      errors={errors.keyTopic}
                      touched={touched.keyTopic}
                    />
                  </Col>
                </Row>
                 )} 

              <Row className="d-flex justify-content-center">
                <Col md={12} lg={10}>
                  <TextAreaInput
                    label={
                      modal === "closeTicket"
                        ? "Descripción de la solucion"
                        : "Motivo por el cual asigna el ticket"
                    }
                    inputName="notes"
                    inputRows={6}
                    onChangeMethod={onChange}
                    onBlurMethod={handleBlur}
                    value={values.notes}
                    isRequired
                    touched={touched.notes}
                    errors={errors.notes}
                  />
                </Col>
              </Row>
            </ModalBody>
            <ModalFooter className="pb-3">
              <div className="w-100 d-flex flex-md-row flex-column-reverse justify-content-md-around mx-5 mx-md-5 px-3">
                {!hiddeCancel && (
                  <Button
                    className="mt-md-3"
                    color="danger"
                    onClick={cancelOverride ? cancel : toggle}
                  >
                    Cancelar
                  </Button>
                )}
                {!hiddeOk && (
                  <Button color="primary" type="submit" className="mt-md-3">
                    {modal === "closeTicket" ? "Cerrar Ticket" : "Asignar"}
                  </Button>
                )}
              </div>
            </ModalFooter>
          </form>
        </>
      )}
    </Modal>
  );
};

export default ModalAsignArea;

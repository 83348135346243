
import React from "react";
import Widget from "../../../../components/Widget/Widget";
import FormObjetos from "../FormObjetos";
import { createObjetoGasto } from "../functions";

export const CrearObjetoGasto = (props) => {

  return (
    <Widget className="widget-p-md">
        <h3>Crear objeto de gasto</h3>
        <FormObjetos crear={createObjetoGasto}/>
    </Widget>
  )
}

export default CrearObjetoGasto;
// -- Consulta de avances -- //
import ConsultaAvances from "../../../pages/planeacion/consulta-avances/Principal";
// -- Registro de proyectos  -- //
import RegistroProyectos from "../../../pages/planeacion/registro-proyectos/RegistroProyectos";
// -- Validacion de proyectos  -- //
import ValidacionProyectos from "../../../pages/planeacion/validacion-proyectos/ValidacionProyectos";
// -- Registro Avances  -- //
import RegistroAvances from "../../../pages/planeacion/registro-avances/RegistroAvances";
// -- Habilitar edicion  -- //
import HablitarEdicion from "../../../pages/planeacion/habilitar-edicion/HabilitatEdicion";

const rutasPlaneacion = [
    //Solicitud de pago Finanzas
    { path: "/template/habilitar-edicion", component: HablitarEdicion },
    //Registro de Proyectos 
    { path: "/template/proyects", component: RegistroProyectos },
    //Validacion de Proyectos 
    { path: "/template/validacionproyectos", component: ValidacionProyectos },
    //Consulta de Avances 
    { path: "/template/consultaavances", component: ConsultaAvances },
    //Registro de Avances 
    { path: "/template/registro-avances", component: RegistroAvances },
    //Solicitud de pago Finanzas
    { path: "/template/habilitar-edicion", component: HablitarEdicion },
]

export default rutasPlaneacion;
import { fMoney, fPercent } from "../../../libs/tools/format";

export function clearRep14(row, key) {
    if (key === 'amount') {
        return row.typeDiscount === "PORCENTAJE" ? fPercent(row[key]) : fMoney(row[key]);
    }
    return row[key]
}

export function clearRep17(row, key) {
    if (key === "fullKey" && row.keyTypeUnder === "2") { // d: Registro completo, k: campo_nombre
        return row.accountKey;
    }
    return row[key];
}

export function clearRep24(row, key) { // d: Registro completo, k: campo_nombre
    if (key === "deducciones" && +row.importes > 0 && +row.deducciones > 0) {
        return +row.deducciones - +row.importes
    }
    return row[key];
}


export function clearRep46(row, key) { // d: Registro completo, k: campo_nombre
    if (key === "street") {
        return `${row.street} #${row.extNumber} ${row.intNumber !== "" && `int. ${row.intNumber}`} ${row.subStreet}, ${row.nameCity} ${row.nameCounty} ${row.nameState}`
    }
    if (key === "unionized") {
        return +row.unionized === 1 ? "NO" : "SI"
    }
    return row[key];
}
import Datatable from "../../../components/Datatable/Datatable";
import { doRequest, doRequestSaveRes } from "../../../helpers/requests";
import * as Yup from "yup";
import { useFormik } from "formik";
import FullFormLoader from "../../../components/Loader/FullFormLoader";
import ModalDelete from "../../../components/Modal/ModalDelete";
import { useState } from "react";
import { Button, Col, Collapse, Row } from "reactstrap";
import ButtonInputM from "../../../components/GenericInputsFormik/ButtonInputModal";
import ModalConfirmation from "../../../components/Modal/ModalConfirmation";

const headers = ["#", "Adscripción", "Eliminar"];

const columnDefs = [{orderable: false, targets: [0, 2], className: "text-center"}];

const defaultForm = { idRequerimentArea: "", idArea: "", nameArea: ""};

const FormSchema = Yup.object().shape({
    nameArea: Yup.string().required("Seleccione una adscripción"),
});

export default function AreasR({id, permissions}){
    const [loading, setLoading] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [modal, setModal] = useState(false);
    const [selectedArea, setSelectedArea] = useState({});

    const {
        handleSubmit,
        values,
        handleBlur,
        errors,
        touched,
        setFieldValue,
        handleReset,
        setValues,
    } = useFormik({
        initialValues: defaultForm,
        validationSchema: FormSchema,
        onSubmit: () => setModal(true),
        enableReinitialize: true,
    });

    const columns = [
        { data: "ix" },
        { data: "nameArea" },
        {
            data: null,
            render: (row) => (
                permissions.DEL && <ModalDelete
                    modalTitle="Eliminar"
                    table=""
                    nameColumn={[""]}
                    elimina={fnDelete}
                    id={row.idRequerimentArea}
                >
                    <h6>
                        Está seguro de eliminar el area{" "}
                        {row.nameFurniture} de la requisición?
                    </h6>
                    No podrá revertir esta acción
                </ModalDelete>
            ),
        },
    ];
    
    const modalTablaParams = {
        title: "Adscripciones",
        headers: ["Adscripción"],
        columns: [
            {data: "nameArea"},
        ],
        params: {
            table: "warehouserequerimemtareas A RIGHT JOIN areas B ON B.idArea = A.idArea",
            rows: "B.idArea, B.name AS nameArea",
            conditions: `IF(ISNULL(A.enabled), TRUE, (SELECT GROUP_CONCAT(enabled) FROM warehouserequerimemtareas) NOT LIKE '%1%') AND B.enabled = 1`,
            group: "B.idArea"
        },
    }
    
    async function getData(ordCol, itemsPerPage, currentPage, search) {
        const res = await doRequestSaveRes(
            "receiver/receiver.php",
            getParams(ordCol, itemsPerPage, currentPage, search),
            false,
            false
        );
        if (res.code === "200"){
            res.data = res.data.map((el, ix) => {
                return { ...el, ix: ix + 1 };
            });
            return res;
        }
        else return null;
    }

    function getParams(ordCol, itemsPerPage, currentPage, search) {
        const params = {
            action: "datatable",
            table: "warehouserequerimemtareas A INNER JOIN areas B USING(idArea)",
            rows: "B.name AS nameArea, A.idRequerimentArea",
            conditions: `A.idRequeriment = ${id} AND A.enabled = 1`,
            order: ordCol,
            records: itemsPerPage.toString(),
            page: (itemsPerPage * currentPage).toString(),
            search
        };
        return params;
    }

    async function fnDelete(id) {
        setLoading(true);
        const params = {
            action: "delete",
            table: "warehouserequerimemtareas",
            condition: {
                idRequerimentArea: id,
            }
        };
        const res = await doRequest("receiver/receiver.php", params, true);
        if (res.length > 0) {
            if(+values.idDetail === +id) handleReset();
            setRefresh(true);
        }
        setLoading(false);
    }

    function selectRow(row){
        setFieldValue("idArea", row.idArea);
        setFieldValue("nameArea", row.nameArea);
    }

    function clearInput(){
        setFieldValue("idArea", "");
        setFieldValue("nameArea", "");
    }

    async function save(){
        setLoading(true);
        const params = {
            action: "insert",
            table: "warehouserequerimemtareas",
            rows: {...values, idRequeriment: id},
            validate: [["idRequerimentArea", "idArea"]]
        }
        delete params.rows.nameArea;
        const res = await doRequest("receiver/receiver.php", params, true);
        if(res.length > 0) {
            handleReset();
            setRefresh(true);
        }
        setLoading(false);
    }

    return(
        <div>
        <h5>Adscripciones</h5>
        <Collapse isOpen={permissions.INS}>
            <form onSubmit={handleSubmit} onReset={handleReset}>
                <Row>
                    <Col xs={12} md={10} className="mx-auto">
                    <ButtonInputM
                        label="Adscripción"
                        inputName="nameArea"
                        value={values.nameArea}
                        touched={touched.nameArea}
                        errors={errors.nameArea}
                        isRequired={true}
                        modalParams={modalTablaParams}
                        selectRow={selectRow}
                        clearFun={clearInput}
                    />
                    </Col>
                </Row>
                <div className="mb-5 d-flex flex-md-row flex-column-reverse justify-content-md-around">
                    <Button color="danger" type="reset" className="mt-3">
                        Cancelar
                    </Button>
                    <Button color="success" type="submit" className="mt-2 mt-md-3">
                        Guardar
                    </Button>
                </div>
            </form>
        </Collapse>
            
            <Datatable
                headers={headers}
                columns={columns}
                columnDefs={columnDefs}
                petition={getData}
                control="back"
                stateRefresh={[refresh, setRefresh]}
                searching={true}
                className="p-0"
                order={{col: 1, opt: "asc"}}
            />

            <ModalConfirmation
                modalTitle="Aagregar adscripción"
                modal={modal}
                setModal={setModal}
                crear={save}
            >
                <div className="d-flex justify-content-center">
                    <h6>¿Está seguro de Agregar esta área a la requisición ?</h6>
                </div>
            </ModalConfirmation>

            <FullFormLoader show={loading} />
        </div>
    )
}
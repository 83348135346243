import { Button, Spinner } from "reactstrap";
import Widget from "../../../components/Widget/Widget";
import { peticionesReceiver } from "../../../helpers/peticionesReceiver";
import { useState } from "react";
import { GetPermissions } from "../../../libs/permissions/getPermissions";
import { toast } from "react-toastify";
import Notification from "../../../components/Notification/Notification";
import Datatable from "../../../components/Datatable/Datatable";
import ModalViewFile from "../../../components/Modal/ModalViewFile";
import { useHistory } from "react-router-dom";

export default function SolicitudAdquisicion() {
    const API = peticionesReceiver();
    const [permisos, setPermisos] = useState("");
    const [loading, setLoading] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [linkFile, setlinkFile] = useState();
    const [modalFile, setModalFile] = useState(false);
    GetPermissions(setPermisos, setLoading);
    const history = useHistory()

    const headers = [
        "Folio",
        "Estatus",
        "Justificación para la adquisición del bien o contratación del servicio",
        "Solicitante",
        "Fecha de registro",
        "Acciones"
    ];

    const columns = [
        { data: "folio" },
        { data: "nameStat" },
        { data: "object" },
        { data: "fullname" },
        { data: "modified" },
        {
            data: null,
            render: function (row) {
                return (
                    <>
                        {row.keyStat !== "101" && permisos.CON && (
                            <Button color="info" size="sm" className="d-flex align-items-center text-nowrap py-1 px-2 mb-2 w-100" onClick={() => {
                                setlinkFile(row.src)
                                setModalFile(true)
                            }}>
                                <i className="fa fa-file-pdf-o mr-2" />Solicitud
                            </Button>
                        )}
                    </>
                )
            }
        }
    ]



    const getParams = (order, records, page, search) => {
        const params = {
            action: "datatable",
            table: "quotes q inner join purchaserequests pr on (pr.idQuote=q.idQuote) inner join status st on (st.keyStat = pr.keyStat AND pr.keyStat=125) inner join bookcase b on (b.reference=pr.idPurchaseRequest) inner join filetypes ft on (ft.keyFileType = 57 AND ft.keyFileType = b.keyFileType) inner join files f on (f.idBook = b.idBook AND f.enabled = 1) inner join staff s on (s.idStaff = pr.idStaffRequire)",
            rows: "CONCAT(s.name,' ',s.pName,' ',s.mName) as fullname,q.object,st.nameStat,pr.numberRequest,f.src,pr.modified,CONCAT(pr.numberRequest,'/',pr.yearRequest) as folio",
            conditions: "pr.enabled=1",
            order,
            records: records.toString(),
            page: (page * records).toString(),
            search
        }
        return params
    }

    async function getData(order, records, page, search) {
        try {
            const res = await API.peticion(getParams(order, records, page, search));
            if (res.status === 200 && res.data.code === "200") {
                return res.data;
            } else {
                // toast(<Notification type={"consultar_error"} backMessage={res.data.message} />);
                return null;
            }
        } catch (err) {
            toast(<Notification type={"consultar_servidor_error"} withIcon />);
            return null
        }
    }

    const columnDefs = [
        { targets: [5], className: "text-center", orderable: false }
    ]

    function ChangeRoute() {
        history.push("/template/solicitud-adquisicion/agregar")
    }

    if (loading) {
        return (
            <div className="d-flex align-items-center justify-content-center" style={{ height: "200px" }}>
                <Spinner
                    color="primary"
                    style={{
                        height: '3rem',
                        width: '3rem'
                    }}
                    type="grow"
                >
                    Loading...
                </Spinner>
            </div>
        )
    }
    return (
        <Widget className={"widget-p-md"}>
            <h3 className="text-secondary">Solicitudes registradas</h3>
            {permisos.INS && (
                <div className="text-right">
                    <Button onClick={ChangeRoute}>Agregar</Button>
                </div>
            )}
            {permisos.CON ? (
                <Datatable
                    headers={headers}
                    columns={columns}
                    columnDefs={columnDefs}
                    petition={getData}
                    control="back"
                    stateRefresh={[refresh, setRefresh]}
                    order={{col: 0, opt: "desc"}}
                />
            ) : (<div className="text-center p-4">No tienes permitido ver los registros de esta seccion</div>)}
            {linkFile ? (
                <ModalViewFile
                    modal={modalFile}
                    setModal={setModalFile}
                    backdrop={true}
                    keyboard={true}
                    btnClose={true}
                    fileLink={linkFile}
                />
            ) : null}
        </Widget>
    )
}
// -- Tipos de Severidad -- //
import TiposSeveridad from "../../../pages/mesa-ayuda/tipos-severidad/Inicio";
import FormSeveridad from "../../../pages/mesa-ayuda/tipos-severidad/form";

import Temas from "../../../pages/mesa-ayuda/temas/Temas";

import Agentes from "../../../pages/mesa-ayuda/agentes/Agentes";

import Tickets from "../../../pages/mesa-ayuda/tickets/Tickets";

const rutasMesa = [
    //Tipos de Severidad
    {path:"/template/tipos-severidad", component: TiposSeveridad},
    {path:"/template/tipos-severidad/crear", component: FormSeveridad},
    {path:"/template/tipos-severidad/editar", component: FormSeveridad},

    //Tickets
    {path:"/template/temas", component: Temas},

    //Tickets
    {path:"/template/tickets", component: Tickets},

    //Agentes
    {path:"/template/agentes", component: Agentes},
]

export default rutasMesa;
import * as Yup from "yup";
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Button } from "reactstrap";
import ModalConfirmation from "../../../components/Modal/ModalConfirmation";
import { peticionesReceiver } from "../../../helpers/peticionesReceiver";
import { toast } from "react-toastify";
import Notification from "../../../components/Notification/Notification";
import Loader from "../../../components/Loader/Loader";
import CurrencyInput from "react-currency-input-field";
import {
  SelectTypeHeadSingle,
  TextInput,
} from "../../../components/GenericInputsFormik/index";
import { useFormik } from "formik";


const FormComisiones = ({ crear, data, editar }) => {
  console.log(data);
  const API = peticionesReceiver();
  const history = useHistory();
  const [form] = useState({
    keyKindMove: "",
    keyBankMove: "",
    keyTax: "",
    nameKindBankMove: "",
    tax: "",
  });
  const [loading, setLoading] = useState(true);
  const [selectsData, setSelectsData] = useState({
    types_bankmoves: "",
    taxes: "",
  });
  const [modal, setModal] = useState(false);

  const FormSchema = Yup.object().shape({
    keyBankMove: Yup.string().required(
      "Seleccione un tipo de movimiento bancario"
    ),
    keyTax: Yup.string().required("Seleccione un tipo de impuesto"),
    nameKindBankMove: Yup.string()
      .min(3, "El nombre de la comisión debe contener al menos 3 letras")
      .required("Ingrese un nombre"),
    tax: Yup.string().required("Ingrese un procentaje de comisión"),
  });

  const getBanksMoves = async () => {
    const params = {
      action: "select",
      table: "types_bankmoves",
      condition: {
        enabled: 1,
      },
      order: "nameBankMove ASC",
    };
    API.peticion(params)
      .then((res) => {
        if (res.status === 200 && res.data.code === "200") {
          setSelectsData((prevState) => ({
            ...prevState,
            types_bankmoves: res.data.data,
          }));
        } else {
          toast(<Notification type={"consultar_error"} withIcon />);
        }
        getTaxes();
      })
      .catch((err) => {
        toast(<Notification type={"consultar_servidor_error"} withIcon />);
      });
  };

  const getTaxes = async () => {
    const params = {
      action: "select",
      table: "taxes",
      condition: {
        enabled: 1,
      },
      order: "nameTax ASC",
    };
    API.peticion(params)
      .then((res) => {
        if (res.status === 200 && res.data.code === "200") {
          setSelectsData((prevState) => ({
            ...prevState,
            taxes: res.data.data,
          }));
        } else {
          toast(<Notification type={"consultar_error"} withIcon />);
        }
      })
      .catch((err) => {
        toast(<Notification type={"consultar_servidor_error"} withIcon />);
      });
    setLoading(false);
  };

  useEffect(() => {
    if (data) {
      setFieldValue("keyKindMove", data[0]);
      setFieldValue("nameKindBankMove", data[3]);
      setFieldValue("keyBankMove", data[5]);
      setFieldValue("keyTax", data[6]);
      setFieldValue("tax", data[4]);
    }
    getBanksMoves();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const enviaDatos = () => {
    setModal(true);
  };

  const cancelOp = () => {
    history.push("/template/comisiones");
  };

  const onChange = (e) => {
    setFieldValue([e.target.name], e.target.value);
  };

  const { handleSubmit, values, handleBlur, errors, touched, setFieldValue } =
    useFormik({
      initialValues: form,
      onSubmit: (values) => {
        enviaDatos();
      },
      validationSchema: FormSchema,
      enableReinitialize: true,
    });

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <form onSubmit={handleSubmit} className="mt-4" noValidate>
          <ModalConfirmation
            modalTitle={data ? "Editar" : "Crear"}
            modal={modal}
            setModal={setModal}
            crear={crear}
            editar={editar}
            isEdit={data ? true : false}
            values={values}
          >
            {data ? (
              <div className="d-flex justify-content-center">
                <h6>¿Está seguro de editar el registro?</h6>
              </div>
            ) : (
              <div className="d-flex justify-content-center">
                <h6>¿Desea continuar con el registro?</h6>
              </div>
            )}
          </ModalConfirmation>
          <TextInput
            label="Nombre de la comisión"
            inputType="text"
            inputName="nameKindBankMove"
            onChangeMethod={onChange}
            onBlurMethod={handleBlur}
            value={values.nameKindBankMove}
            isRequired={true}
            touched={touched.nameKindBankMove}
            errors={errors.nameKindBankMove}
          />
          <SelectTypeHeadSingle
            label="Seleccione un tipo de movimiento bancario"
            inputName="keyBankMove"
            onChangeMethod={onChange}
            onBlurMethod={handleBlur}
            value={values.keyBankMove}
            isRequired={true}
            touched={touched.keyBankMove}
            errors={errors.keyBankMove}
            optionsArray={selectsData.types_bankmoves}
            optionValue="keyBankMove"
            optionName="nameBankMove"
            defaultOption="Seleccione un tipo de movimiento bancario"
          />
          <SelectTypeHeadSingle
            label="Tipo de impuesto"
            inputName="keyTax"
            onChangeMethod={onChange}
            onBlurMethod={handleBlur}
            value={values.keyTax}
            isRequired={true}
            touched={touched.keyTax}
            errors={errors.keyTax}
            optionsArray={selectsData.taxes}
            optionValue="keyTax"
            optionName="nameTax"
            defaultOption="Seleccione un tipo de impuesto"
          />
          <div className="form-group">
            <label htmlFor="keyBankMove">
              Porcentaje de comisión<span className="text-danger">*</span>
            </label>
            <CurrencyInput
              id="tax"
              name="tax"
              className={
                "form-control" +
                (errors.tax && touched.tax ? " is-invalid" : "")
              }
              allowDecimals={true}
              allowNegativeValue={false}
              onValueChange={(value, name) =>
                onChange({
                  target: { name: name, value: value },
                })
              }
              value={values.tax}
              onBlur={handleBlur}
              prefix="%"
              decimalSeparator="."
              groupSeparator=","
            />
            {touched.tax && errors.tax && (
              <span className="text-danger">{errors.tax}</span>
            )}
          </div>
          <div className="d-flex justify-content-between mt-5">
            <Button color="danger" onClick={() => cancelOp()}>
              Cancelar
            </Button>
            <Button color="success" type="submit">
              Guardar
            </Button>
          </div>
        </form>
      )}
    </>
  );
};
export default FormComisiones;

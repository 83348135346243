import { Button, Card, CardText, CardTitle, Col, Modal, ModalBody, ModalHeader, Nav, NavItem, NavLink, Row, Spinner, TabContent, TabPane } from "reactstrap";
import Widget from "../../../components/Widget/Widget";
import { useHistory, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMoneyBill, faMoneyBill1 } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import AnexosTecnicos from "../solicitud-cotizacion/Forms/Editar/AnexosTecnicos";
import { toast } from "react-toastify";
import Notification from "../../../components/Notification/Notification";
import { peticionesReceiver } from "../../../helpers/peticionesReceiver";
import OrganizarRecepcion from "./recepcion-bienes/OrganizarRecepcion";
import ViewerFile from "../../../components/Iframe/viewer";
import { doRequest } from "../../../helpers/requests";
import ReportViewer from "../../../components/Iframe/report";
import pdfSolicitud from "../../presupuesto/dictamen-presupuestal/reports/pdfSolicitud";
import Adjudicacion from "./adjudicacion";
import FilesTab from "./files";
import FinalizarCompra from "./finalizar/Finalizar";
import AcuseEntrada from "../revision-adquisiciones/AcuseEntrada";
import ProveedorRev from "../revision-adquisiciones/Proveedor";
import DetalleProveedorCargaFacturas from "../carga-facturas/detalles/detailProvider";
import { useSelector } from "react-redux";
import AprobarAdquisicion from "../revision-adquisiciones/AprobarAdquisicion";

const CurrencyFormatter = new Intl.NumberFormat("es-MX", {
    style: "currency",
    currency: "MXN",
});

const mNames = [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre"
]

export default function ResolverSolicitud() {
    const API = peticionesReceiver()
    const history = useHistory();
    const [modal, setModal] = useState(false);
    const lsData = localStorage.getItem("solvProc").split("|");
    const idSolicitud = lsData[0];
    const idEntrada = lsData[1];
    const [tab, setTab] = useState("1");
    const [idCot, setIdCot] = useState("");
    const [idBudgetRequest, setIdBudgetRequest] = useState("");
    const [solicitud, setSolicitud] = useState();
    const [linkEstudio, setLinkEstudio] = useState("");
    const [linkOficio, setLinkOficio] = useState("");
    const [solicitud2, setSolicitud2] = useState("")
    const [calend, setCalend] = useState("")
    const [total, setTotal] = useState("")
    const [notes, setNotes] = useState("")
    const [proyP, setProyP] = useState("")
    const [report, setReport] = useState(null)
    const {profileType} = useSelector((state) => state.userInfo);
    /*Vista */
  const location = useLocation();
  const revision = location.pathname.includes("revision-adquisiciones") ? 1 : 0;
  const [status, setStatus] = useState(lsData[2] === "142")

    const handleOpenModal = () => setModal(true);
    const handleCloseModal = () => setModal(false);

    useEffect(() => {
        if (!idSolicitud) {
            Regresar();
        }
        getSolicitud()
    }, [idSolicitud])

    async function getSolicitud() {
        try {
            const res = await API.peticion({
                action: "multiselect",
                rows: "pr.idQuote,br.idBudgetRequest,br.numberRequest as numberRequestBR,br.yearRequest as yearRequestBR,br.approvedAmount,pr.numberRequest,pr.yearRequest,q.object",
                table: "purchaserequests pr inner join budgetrequests br on (pr.idBudgetRequest=br.idBudgetRequest) inner join quotes q on (q.idQuote=pr.idQuote)",
                conditions: `pr.idPurchaseRequest = ${idSolicitud}`
            });
            if (res.status === 200 && res.data.code === "200") {
                setSolicitud(res.data.data[0])
                setIdCot(res.data.data[0].idQuote)
                setIdBudgetRequest(res.data.data[0].idBudgetRequest)
            } else {
                toast(<Notification type={"consultar_error"} backMessage={res.data.message} />);
            }
        } catch (err) {
            toast(<Notification type={"consultar_servidor_error"} withIcon />);
        }
    }

    const Regresar = () => {
        localStorage.removeItem("solvProc");
        // history.push("/template/solventacion-proceso")
        history.goBack();
    }

    const getEstudio = async (row) => {
        const params = {
            action: "multiselect",
            table: "quotes A LEFT JOIN bookcase D ON A.idQuote = D.reference AND D.keyFileType = 44 LEFT JOIN files E ON D.idBook = E.idBook AND E.enabled = 1",
            rows: "E.src",
            conditions: `A.enabled=1 AND A.idQuote=${row}`,
        };
        const API = peticionesReceiver()
        try {
            const res = await API.peticion(params)
            if (res.status === 200 && res.data.code === "200") {
                const data = res.data.data;
                if (data.length > 0) {
                    setLinkEstudio(res.data.data[0].src)
                } else {
                    toast(<Notification type={"consultar_error"} withIcon />);
                }
            } else {
                toast(<Notification type={"consultar_error"} backMessage={res.data.message} />);
            }
        } catch (err) {
            toast(<Notification type={"consultar_error"} withIcon />);
        }
    }

    const getOficio = async (row) => {
        const params = {
            action: "multiselect",
            table: "quotes q inner join purchaserequests pr on (pr.idQuote=q.idQuote) inner join bookcase b on (b.reference=pr.idPurchaseRequest) inner join filetypes ft on (ft.keyFileType = 57 AND ft.keyFileType = b.keyFileType) inner join files f on (f.idBook = b.idBook AND f.enabled = 1)",
            rows: "f.src",
            conditions: `q.idQuote=${row}`,
        };
        const API = peticionesReceiver()
        try {
            const res = await API.peticion(params)
            if (res.status === 200 && res.data.code === "200") {
                const data = res.data.data;
                if (data.length > 0) {
                    setLinkOficio(res.data.data[0].src)
                } else {
                    toast(<Notification type={"consultar_error"} withIcon />);
                }
            } else {
                toast(<Notification type={"consultar_error"} backMessage={res.data.message} />);
            }
        } catch (err) {
            toast(<Notification type={"consultar_error"} withIcon />);
        }
    }

    async function getDocument(idBudgetRequest) {
        setSolicitud2("");
        setCalend("");
        setTotal("");
        setProyP("");
        setNotes("");
        const params = {
            action: "multiselect",
            table:
                "budgetrequests A INNER JOIN areas B USING (idArea) INNER JOIN staff C ON C.idStaff = A.idStaffRequest INNER JOIN status D ON D.keyStat = A.keyStat",
            rows: "B.name AS nameArea, CONCAT(A.numberRequest,'/',A.yearRequest) AS folio, A.justification, CONCAT(C.name,' ',C.pName,' ', C.mName) as fullName, A.dateSend, D.nameStat, A.requestedAmount,A.idBudgetRequest, A.keyStat, A.notes",
            conditions: `A.idBudgetRequest=${idBudgetRequest}`
        }
        try {
            const res = await API.peticion(params);
            if (res.status === 200 && res.data.code === "200") {
                setSolicitud2(res.data.data[0])
                setNotes(res.data.data[0].notes);
                getMonths(idBudgetRequest)
                getProy(idBudgetRequest)
            } else {
                toast(<Notification type={"consultar_error"} backMessage={res.data.message} />);
                return null;
            }
        } catch (err) {
            toast(<Notification type={"consultar_servidor_error"} withIcon />);
            return null
        }
    }

    async function getMonths(idBudgetRequest) {
        const params = {
            action: "multiselect",
            table:
                "budgetrequestdetail A INNER JOIN budgetrequestschedule B USING(idDetail) INNER JOIN months C USING (keyMonth)",
            rows: "C.nameMonth, SUM(IF(A.keyStat = 121, B.required, IF(A.keyStat = 122,B.approved, 0))) AS acum, C.keyMonth",
            conditions: `A.idBudgetRequest = ${idBudgetRequest} AND A.enabled = 1 AND B.enabled = 1`,// AND A.keyStat != 123
            order: "C.keyMonth",
            group: "C.keyMonth",
        };
        const res = await doRequest("receiver/receiver.php", params, false, false);
        if (res.length > 0) {
            let tt = 0;
            const data = mNames.map((el, ix) => {
                const fnd = res.find((mb) => +mb.keyMonth === ix + 1);
                const nMes = { nameMonth: el, acum: 0 };
                if (fnd) {
                    tt += +fnd.acum;
                    nMes.acum = +fnd.acum;
                }
                return nMes;
            });
            setCalend(data);
            setTotal(tt);
        }
    }

    async function getProy(idBudgetRequest) {
        const params = {
            action: "multiselect",
            table: "budgetrequestdetail A INNER JOIN statefunds B USING (idStateFund) INNER JOIN expense_objects C USING (idExpenseObject) INNER JOIN budgetrequestschedule G USING (idDetail) INNER JOIN status H USING (keyStat) INNER JOIN budgetrequests I USING (idBudgetRequest) LEFT JOIN staff SS ON SS.idStaff = I.idStaffRequest LEFT JOIN staff SR ON SR.idStaff = I.idStaffRevision LEFT JOIN staff SA ON SA.idStaff = I.idStaffAproves",
            rows: "A.idStateFund, CONCAT(B.stateFundKey,' - ',B.nameStateFund) AS prPres, C.fullKey as COG, GROUP_CONCAT(G.required) AS rMonth, C.nameObject, SUM(G.required) AS acum, H.nameStat, A.idDetail,A.keyStat,A.concept, CONCAT(SS.name,' ',SS.pName,' ',SS.mName) AS solicita, CONCAT(SR.name,' ',SR.pName,' ',SR.mName) AS revisa, CONCAT(SA.name,' ',SA.pName,' ',SA.mName) AS aprueba",
            conditions: `A.idBudgetRequest = ${idBudgetRequest} AND A.enabled = 1 AND G.enabled = 1`,
            order: "prPres",
            group: "C.idExpenseObject, B.idStateFund",
        };
        const res = await doRequest("receiver/receiver.php", params, false, false);
        if (res.length > 0) {
            setProyP(res);
        }
    }

    useEffect(() => {
        if (idCot !== "" && idBudgetRequest !== "") {
            if (tab === "1" && linkEstudio === "") {
                getEstudio(idCot)
            } else if (tab === "2" && solicitud2 === "" && calend === "" && total === "" && notes === "" && proyP === "") {
                getDocument(idBudgetRequest)
            } else if (tab === "3" && linkOficio === "") {
                getOficio(idCot)
            }
        }
    }, [tab, idCot, idBudgetRequest])

    useEffect(() => {
        if ((solicitud2 !== "" && calend !== "" && total !== "" && proyP !== "")) {
            setReport({ meses: calend, proyects: proyP, solicitud: solicitud2, total: total, notas: notes })
        }
    }, [solicitud2, calend, total, proyP])


    if (!solicitud) {
        return (
            <div className="d-flex align-items-center justify-content-center" style={{ height: "200px" }}>
                <Spinner
                    color="primary"
                    style={{
                        height: '3rem',
                        width: '3rem'
                    }}
                    type="grow"
                >
                    Loading...
                </Spinner>
            </div>
        )
    }
    return (
        <>
            <Widget className="widget-p-md">
                <Button size="sm" color="danger" onClick={Regresar}>Regresar</Button>
                <Row className="my-3">
                    <Col sm={12} md={8}>
                        <h4>Datos de la compra</h4>
                        <p><b>Fecha de solicitud:</b> {solicitud.numberRequest}/{solicitud.yearRequest}</p>
                        <p><b>Fecha de suficiencia:</b> {solicitud.numberRequestBR}/{solicitud.yearRequestBR}</p>
                        <p><b>Justificación:</b> {solicitud.object}</p>
                    </Col>
                    <Col sm={12} md={4}>
                        <div style={{ background: "green" }} className="rounded d-flex align-items-center">
                            <FontAwesomeIcon icon={faMoneyBill1} className="text-white m-3" style={{ fontSize: "70px" }} />
                            <div className="ml-auto mx-4">
                                <h3 className="text-white text-right">{solicitud.approvedAmount !== "" ? CurrencyFormatter.format(solicitud.approvedAmount) : "$0.00"}</h3>
                                <h6 className="text-white text-right">Monto disponible</h6>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Button color="add" className="mb-3" onClick={handleOpenModal}>Ver anexos técnico administrativo</Button>
                <Nav tabs>
                    <NavItem>
                        <NavLink
                            className={tab === "1" ? "active" : ""}
                            onClick={() => setTab("1")}
                        >
                            Estudios de mercado
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={tab === "2" ? "active" : ""}
                            onClick={() => setTab("2")}
                        >
                            Suficiencia presupuestal
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={tab === "3" ? "active" : ""}
                            onClick={() => setTab("3")}
                        >
                            Oficio de solicitud
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={tab === "4" ? "active" : ""}
                            onClick={() => setTab("4")}
                        >
                            {revision ? "Proveedor" : "Adjudicación"}
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={tab === "5" ? "active" : ""}
                            onClick={() => setTab("5")}
                        >
                            Documentos de compra
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={tab === "6" ? "active" : ""}
                            onClick={() => setTab("6")}
                        >
                            Recepción/Bienes
                        </NavLink>
                    </NavItem>
                    {
                        revision ?
                        <>
                            <NavItem>
                                <NavLink
                                    className={tab === "8" ? "active" : ""}
                                    onClick={() => setTab("8")}
                                >
                                    Acuse entrada
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    className={tab === "9" ? "active" : ""}
                                    onClick={() => setTab("9")}
                                >
                                    Factura
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    className={tab === "10" ? "active" : ""}
                                    onClick={() => setTab("10")}
                                >
                                    Aprobar adquisición
                                </NavLink>
                            </NavItem>
                        </> : 
                        <NavItem>
                            <NavLink
                                className={tab === "7" ? "active" : ""}
                                onClick={() => setTab("7")}
                            >
                                Finalizar compra
                            </NavLink>
                        </NavItem>
                    }
                </Nav>
                <TabContent activeTab={tab}>
                    <TabPane tabId="1">
                        {tab !== "1" || linkEstudio === "" ? (
                            <div className="d-flex align-items-center justify-content-center" style={{ height: "200px" }}>
                                <Spinner
                                    color="primary"
                                    style={{
                                        height: '3rem',
                                        width: '3rem'
                                    }}
                                    type="grow"
                                >
                                    Loading...
                                </Spinner>
                            </div>
                        ) : (
                            <>
                                <ViewerFile fileLink={linkEstudio} />
                            </>
                        )}
                    </TabPane>
                    <TabPane tabId="2">
                        {tab !== "2" || !report ? (
                            <div className="d-flex align-items-center justify-content-center" style={{ height: "200px" }}>
                                <Spinner
                                    color="primary"
                                    style={{
                                        height: '3rem',
                                        width: '3rem'
                                    }}
                                    type="grow"
                                >
                                    Loading...
                                </Spinner>
                            </div>
                        ) : (
                            <>
                                <ReportViewer
                                    title={"Solicitud de suficiencia presupuestal y dictamen"}
                                    report={report}
                                    pdfConfig={{}}
                                    generatePdfMethod={{
                                        method: pdfSolicitud,
                                        type: "reporteAuxiliar",
                                    }}
                                />
                            </>
                        )}
                    </TabPane>
                    <TabPane tabId="3">
                        {tab !== "3" || linkOficio === "" ? (
                            <div className="d-flex align-items-center justify-content-center" style={{ height: "200px" }}>
                                <Spinner
                                    color="primary"
                                    style={{
                                        height: '3rem',
                                        width: '3rem'
                                    }}
                                    type="grow"
                                >
                                    Loading...
                                </Spinner>
                            </div>
                        ) : (
                            <>
                                <ViewerFile fileLink={linkOficio} />
                            </>
                        )}
                    </TabPane>
                    <TabPane tabId="4" className="m-4">
                        {tab !== "4" || idCot === "" ? (
                            <div className="d-flex align-items-center justify-content-center" style={{ height: "200px" }}>
                                <Spinner
                                    color="primary"
                                    style={{
                                        height: '3rem',
                                        width: '3rem'
                                    }}
                                    type="grow"
                                >
                                    Loading...
                                </Spinner>
                            </div>
                        ) : (
                            revision ? <ProveedorRev data={idEntrada} status={status} /> : <Adjudicacion idQuote={idCot} />
                        )}
                    </TabPane>
                    <TabPane tabId="5">
                        {tab !== "5" ? (
                            <div className="d-flex align-items-center justify-content-center" style={{ height: "200px" }}>
                                <Spinner
                                    color="primary"
                                    style={{
                                        height: '3rem',
                                        width: '3rem'
                                    }}
                                    type="grow"
                                >
                                    Loading...
                                </Spinner>
                            </div>
                        ) : (
                            <FilesTab idPurchaseRequest={idSolicitud} revision={revision} />
                        )}
                    </TabPane>
                    <TabPane tabId="6" className="m-4">
                        <OrganizarRecepcion 
                            idPR={idSolicitud} 
                            tab={tab} 
                            idArea={profileType.selectProfile.idArea}
                            revision={revision}
                        />
                    </TabPane>
                    {
                        revision ?
                        <>
                            <TabPane tabId="8" className="m-4">
                                <AcuseEntrada solicitud={idSolicitud} entrada={idEntrada}/>
                            </TabPane>
                            <TabPane tabId="9" className="m-4">
                                <DetalleProveedorCargaFacturas idWinner={idEntrada.split(",")[2]} setIdWinner={false}/>
                            </TabPane>
                            <TabPane tabId="10" className="m-4">
                                <AprobarAdquisicion id={idEntrada.split(",")[0]} status={status} setStatus={setStatus}/>
                            </TabPane>
                        </> : 
                        <TabPane tabId="7" className="m-4">
                            <FinalizarCompra idPR={idSolicitud} goBack={Regresar}/>
                        </TabPane>
                    }
                    
                </TabContent>
            </Widget>
            <Modal isOpen={modal} toggle={handleCloseModal} fullscreen="lg" size="xl" backdrop="static" >
                <ModalHeader className="pb-0" toggle={handleCloseModal} />
                <ModalBody className="m-0 p-0">
                    <AnexosTecnicos idCot={idCot} add={false} />
                </ModalBody>
            </Modal>
        </>
    )
}
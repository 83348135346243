import { string2DateInput } from "../../../libs/tools/format";

export function getParamsDatosGenerales(data) {
    const rows = {
        idStaff: "",
        keyEmployType: "",
        keyGenre: data.INE.sexo === "H" ? 1 : data.INE.sexo === "M" ? 2 : 3,
        keyMarital: "",
        keyBloodType: "",
        keyStat: 1,
        keyDisability: 34,
        keyCityBorn: "",
        staffNumber: "",
        name: data.RENAPO.nombre,
        pName: data.RENAPO.apellidoPaterno,
        mName: data.RENAPO.apellidoMaterno,
        capitalization: 0,
        unionized: 0,
        unionizedDate: "",
        startJobDate: "",
        bornDate: string2DateInput(data.RENAPO.fechaNacimiento),
        firedDate: "",
        alergic: 0,
        kindAlergy: "",
        rfc: data.SAT.rfc,
        curp: data.RENAPO.curp,
        postalCodeTax: data.SAT.datosUbicacion.cp,
        identificationNumber: data.INE.claveElector,
        jobMail: "",
        jobNumber: "",
        jobExtension: "",
        descriptor: data.descriptor
    }
    return rows
}


export function getParamsExperiencia(data, value) {
    const rows = {
        idStaffWork: "",
        idStaff: data,
        employeer: value.nombrePatron,
        position: "",
        startDate: string2DateInput(value.fechaAlta),
        endDate: value.fechaBaja.toUpperCase() !== "VIGENTE" ? string2DateInput(value.fechaBaja) : "",
        public: false,
        notes: "",
    };
    return rows;
}

export function getParamsDomicilio(idStaff, value) {
    const rows = {
        idStaff,
        keyCity: "",
        street: value?.datosUbicacion?.nombreVialidad,
        intNumber: value?.datosUbicacion?.numeroInterior,
        extNumber: value?.datosUbicacion?.numeroExterior,
        subStreet: value?.datosUbicacion?.colonia,
        zipCode: value?.datosUbicacion?.cp,
        latitude: "",
        longitude: "",
        keyCountry: "",
        keyState: "",
        keyCounty: "",
    };
    return rows;
}
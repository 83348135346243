import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import { Button, Row, Col } from "reactstrap";
import { peticionesReceiver } from "../../../../helpers/peticionesReceiver";
import * as Yup from "yup";
import ModalConfirmation from "../../../../components/Modal/ModalConfirmation";
import Datatable from "../../../../components/Datatable/Datatable";
import { toast } from "react-toastify";
import Notification from "../../../../components/Notification/Notification";
import ModalDelete from "../../../../components/Modal/ModalDelete";
import {
  SelectTypeHeadSingle,
  TextAreaInput,
} from "../../../../components/GenericInputsFormik";
import { NumberInput } from "../../../../components/GenericInputsFormik/NumberInput";

const FormDetailsSolicitadas = ({
  exit,
  permissions,
  setTrasnferRequest,
  setRefreshPrinc,
  idAreaLogged,
}) => {
  const [modalConfirmation, setModalConfirmation] = useState(false);
  const [modalConfirmation2, setModalConfirmation2] = useState(false);
  const API = peticionesReceiver();
  const [refresh, setRefresh] = useState(false);

  

  

  const headers = [
    "#",
    "Bien",
    "Unidad de Medida",
    "Cantidad a traspasar",
    "Cantidad a traspasar",
    "Descripcion",
  ];
  const [exits, setExits] = useState([]);

  const columns = [
    { data: "index" },
    { data: "bien" },
    { data: "nameUnit" },
    { data: "quantityRequired" },
    {
      data: null,
      render: function (row) {
        return (
          <>
            <NumberInput
              inputName="quantittyApproved2"
              value={
                /* recepcions[row.index - 1] !== undefined
                  ? recepcions[row.index - 1].quantityToSend
                  : "" */
                exits[row.index - 1].quantittyApproved2 !== undefined
                  ? exits[row.index - 1].quantittyApproved2
                  : ""
              }
              onChangeMethod={(e) => changeArrayValue(e, row)}
              disableSep
              negatives={false}
              isMin={1}
              decimals={false}
            />
          </>
        );
      },
    },
    { data: "coments" },
  ];

  const changeArrayValue = (e, row) => {
    const { name, value } = e.target;

    
    let tmpList = exits.slice(0, exits.length);
    tmpList[+row.index - 1][name] = value;
    setExits(tmpList);
  };

  const onChange = (e) => {
    const { name, value } = e.target;

    setFieldValue([name], value);
  };

  const columnDefs = [
    {
      targets: [0, 1, 2, 3, 4, 5],
      className: "text-center",
    },
    {
      orderable: false,
      targets: [0],
    },
  ];

  const getParamsTableElmt = (order, records, page, search) => {
    const params = {
      action: "datatable",
      table:
        "warehouseexitsdetail  A LEFT JOIN warehouseexits X on (A.idExit = X.idExit) LEFT JOIN furnitures B on (A.idFurniture = B.idFurniture) LEFT JOIN messureunits C ON (B.keyUnit = C.keyUnit)  ",
      rows: "A.idDetail, B.idFurniture, concat(B.nameFurniture,' ',B.model,' ',B.brand) as bien, C.nameUnit,A.quantityRequired,ifnull(A.quantityApproved,'0') as quantittyApproved2,A.coments",
      conditions: `A.enabled = 1 and A.idExit = ${exit.idExit}`,
      order,
      records: records.toString(),
      page: (page * records).toString(),
      search,
    };
    return params;
  };

  async function getData(order, records, page, search) {
    try {
      const res = await API.peticion(
        getParamsTableElmt(order, records, page, search)
      );
      if (res.status === 200 && res.data.code === "200") {
        const data = res.data;
        data.data.forEach((value, index) => (value.index = 1 + index));
        setExits(data.data);
        return data;
      } else {
        toast(
          <Notification
            type={"consultar_error"}
            backMessage={res.data.message}
          />
        );
        return null;
      }
    } catch (err) {
      toast(<Notification type={"consultar_servidor_error"} withIcon />);
      return null;
    }
  }

  

  const FormSchema = Yup.object().shape({
    description: Yup.string().required("Ingrese una descripción"),
  });

  const {
    handleSubmit,
    values,
    errors,
    setFieldValue,
    touched,
    handleBlur,
    resetForm,
  } = useFormik({
    initialValues: {
      idInventory: "",
      featuresToPass: "",
      idFurniture: "",
      unit: "",
      description: "",
    },
    onSubmit: () => {
      setModalConfirmation2(true);
    },
    validationSchema: FormSchema,
    enableReinitialize: true,
  });

  const sendRequest = async () => {
    if (insertDataQuantitys()) {
      let peticion = { params: {}, route: "" };
      if (exit.keyTypeExit === "1") {
        peticion.params = {
          action: "update",
          table: "warehouseexits",
          rows: {
            keyStat: "137",
          },
          condition: {
            idExit: exit.idExit,
          },
        };
        peticion.route = "receiver/receiver.php";
      } else {
        peticion.params = {
          action: "saveExit",
          idExit: exit.idExit,
        };
        peticion.route = "app/facades/warehouse/warehouseF.php";
      }

      
      
      try {
        const res = await API.peticionEndPoint(peticion.params, peticion.route);
        if (res.status === 200 && res.data.code === "200") {
          toast(
            <Notification
              type={"agrega_exito"}
              backMessage={res.data.message}
              withIcon
            />
          );
          setTrasnferRequest(undefined);
          setRefreshPrinc(true);
        } else {
          toast(
            <Notification
              type={"consultar_error"}
              backMessage={res.data.message}
              withIcon
            />
          );
        }
      } catch (err) {
        toast(<Notification type={"consultar_servidor_error"} withIcon />);
      }
    }
  };
  const rejectRequest = async () => {
    let peticion = { params: {}, route: "" };
      peticion.params = {
        action: "update",
        table: "warehouseexits",
        rows: {
          keyStat: "139",
          notes: values.description,
        },
        condition: {
          idExit: exit.idExit,
        },
      /* peticion.route = "receiver/receiver.php"; */
    }

    try {
      const res = await API.peticion(peticion.params);
      if (res.status === 200 && res.data.code === "200") {
        toast(
          <Notification
            type={"agrega_exito"}
            backMessage={res.data.message}
            withIcon
          />
        );
        setTrasnferRequest(undefined);
        setRefreshPrinc(true);
      } else {
        toast(
          <Notification
            type={"consultar_error"}
            backMessage={res.data.message}
            withIcon
          />
        );
      }
    } catch (err) {
      toast(<Notification type={"consultar_servidor_error"} withIcon />);
    }
  };

  const insertDataQuantitys = () => {
    let isValid = true
    exits.forEach((exit) => {
      if (
        exit.quantittyApproved2 !== "" &&
        +exit.quantittyApproved2 > 0 &&
        +exit.quantityRequired >= +exit.quantittyApproved2
      ) {
        if (!insertDataQuantity(exit)) {
      
          isValid = false;
        }
      } else {
        toast(
          <Notification
            type={"elimina_error"}
            backMessage={`Favor verificar que el registro ${exit.index} haya sido llenado correctamente`}
            withIcon
          />
        );
        
        isValid = false;
      }
    });
    
    return isValid;
  };

  const insertDataQuantity = async (exit) => {
    let allCorrect = false;
    const params = {
      action: "update",
      table: "warehouseexitsdetail",
      rows: {
        quantityApproved: exit.quantittyApproved2,
      },
      condition: {
        idDetail: exit.idDetail,
      },
    };

    try {
      const res = await API.peticion(params);
      if (res.status === 200 && res.data.code === "200") {
        allCorrect = true;
      } else {
        toast(
          <Notification
            type={"consultar_error"}
            backMessage={res.data.message}
            withIcon
          />
        );
      }
    } catch (err) {
      toast(<Notification type={"consultar_servidor_error"} withIcon />);
    }

    return allCorrect;
  };
  return (
    <div className="mt-3">
      <form onSubmit={handleSubmit}>
        <div className="text-center mb-5">
          <h1>Detalle de solicitud</h1>
        </div>

        <ModalConfirmation
          modalTitle={"Crear"}
          modal={modalConfirmation}
          setModal={setModalConfirmation}
          crear={sendRequest}
          isEdit={false}
          values={values}
        >
          <div className="text-center">
            Esta seguro de continuar con el registro
          </div>
        </ModalConfirmation>
        <ModalConfirmation
          modalTitle={"Rechazar"}
          modal={modalConfirmation2}
          setModal={setModalConfirmation2}
          crear={rejectRequest}
          isEdit={false}
          values={values}
        >
          <div className="text-center">Esta seguro de rechazar el registro</div>
        </ModalConfirmation>

        <Datatable
          headers={headers}
          columns={columns}
          columnDefs={columnDefs}
          petition={getData}
          control="back"
          stateRefresh={[refresh, setRefresh]}
          searching={false}
          order={{ col: 1, opt: "desc" }}
          paging={false}
        />

        <Row className="d-flex justify-content-around mt-5">
          <Col xs={12} md={8}>
            <TextAreaInput
              label="Observación de rechazo"
              inputName="description"
              inputRows={3}
              onChangeMethod={onChange}
              onBlurMethod={handleBlur}
              value={values.description}
              isRequired
              touched={touched.description}
              errors={errors.description}
            />
          </Col>
        </Row>

        {/* {exit.keyStat === "137" ? (
        <>
          {permissions.INS && (
            <div className="text-center mt-5">
              <Button
                type="submit"
                color="success"
                onClick={() => setModalConfirmation(true)}
              >
                {exit.keyTypeExit === "1" ? "Enviar" : "Finalizar "}
              </Button>
            </div>
          )}
        </>
      ) : (
        <Row className="d-flex  justify-content-center mb-5 text-center mt-5">
          <Col>
            <h3 style={{ color: "red" }}>La solicitud ya ha sido enviada</h3>
          </Col>
        </Row>
      )} */}
        {permissions.INS && (
          <Row>
            <Col className="text-center mt-5 d-flex justify-content-around aling-items-center">
              <Button
                color="success"
                onClick={() => setModalConfirmation(true)}
              >
                Finalizar
              </Button>
              <Button type="submit" color="danger">
                Rechazar
              </Button>
            </Col>
          </Row>
        )}
      </form>
    </div>
  );
};

export default FormDetailsSolicitadas;

import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import DatePicker from "react-datepicker";
import { useHistory } from "react-router-dom";
import { Col, Row, Button } from "reactstrap";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import { PERIOD } from "../../../helpers/regex";
import { toolbar } from './helpers/configTextEditor'
import Loader from "../../../components/Loader/Loader";
import { peticionesReceiver } from "../../../helpers/peticionesReceiver";
import Notification from "../../../components/Notification/Notification";
import ModalConfirmation from "../../../components/Modal/ModalConfirmation";
import { SelectTypeHeadSingle } from "../../../components/GenericInputsFormik";

import "react-datepicker/dist/react-datepicker.css";
import "./helpers/_textEditor.scss";

const FormHorarios = ({ crear, data, actualizar,obtener }) => {

  const defaultForm = {
    idAdvertisement: '',
    keyTypeAdverstisement: 0,
    descripcion: '',
    period: 0,
    year: new Date (),
    aplicationDate: new Date()
  };

  const history = useHistory();
  const API = peticionesReceiver();
  const date = new Date().getFullYear();

  const [modal, setModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true)
  const [advertisement, setAdvertisement] = useState([])

  useEffect(() => {
    if(data) {
      getDescription(data[0])
      const periodFind = PERIOD.find(it => it.value === Number(data[3]))
      setFieldValue('idAdvertisement', data[0]);
      setFieldValue('period', periodFind.value);
      setFieldValue('year', new Date().setFullYear(data[4]));
      setFieldValue('aplicationDate', data[5] !== '' ? new Date(data[5]) : new Date());
    }
    getData()
  }, [])

  useEffect(() => {
    if(data && advertisement.length > 0 ){
      const findAdvertisement = advertisement.find(it => it.nameTypeAdvertisement === data[1])
      if(Object.keys(findAdvertisement).length > 0){
        setFieldValue('keyTypeAdverstisement', findAdvertisement.keyTypeAdvertisement);
        setIsLoading(false)
      }
      else{
        setFieldValue('keyTypeAdverstisement', 0);
        setIsLoading(false)
      }
    }
  }, [data && advertisement.length > 0])
  
  const getData = async() => {
    const params = {
      action: "select",
      table: 'advertisement_types',
      condition: { enabled: 1 },
      order: 'nameTypeAdvertisement ASC',
    };
    const res = await API.peticion(params)
    if(res.status === 200 && res.data.code === "200"){
      setAdvertisement(res.data.data)
      setIsLoading(false)
    }
    else {
      toast(<Notification type={"consultar_error"} withIcon />);
      setIsLoading(false)
    }
  };

  const getDescription = async(id) => {
    const result = await obtener(id)
    if(result.success){
      setFieldValue('descripcion', result.info.descripcion);
    }
  }

  const FormSchema = Yup.object().shape({
    keyTypeAdverstisement: Yup.number()
      .moreThan(0,"Favor de seleccionar un tipo de leyenda")
      .required("Favor de seleccionar un tipo de leyenda"),
    descripcion: Yup.string()
    .required("Favor de escribir una descripción"),
    period: Yup.number()
    .moreThan(0,"Favor de seleccionar un periodo")
    .required("Favor de seleccionar un periodo"),
    year: Yup.string()
    .required("Favor de seleccionar un año"),
    aplicationDate: Yup.string()
    .required("Favor de seleccionar una fecha de aplicacion"),
  });

  const goBack = () =>{
    history.goBack()
  }

  const onChange = (e) => {
    const { name, value } = e.target
    setFieldValue([name], value);
  }

  const onChangeDate = (event) => {
    setFieldValue(event.name, event.value);
  };

  const handleEditor = (editorState)=> {
    setFieldValue('descripcion',editorState)
  }

  const formatDateTime = (date) => {
    const today = new Date(date)
    const month = today.getMonth() + 1
    const day = today.getDate()
    const formattMonth = month < 10 ? `0${month}` : month
    const formattDay = day < 10 ? `0${day}` : day
    return `${today.getFullYear()}-${formattMonth}-${formattDay} ${today.getHours()}:${today.getMinutes()}:00`
  }

  const saveAdvertisement = async() => {
    setIsLoading(true)
    const info = {
      ...values,
      year: new Date(values.year).getFullYear(),
      descripcion: values.descripcion.replaceAll('\"', "'"),
      aplicationDate: formatDateTime(values.aplicationDate)
    }
    
    try{
      const response = data ? await actualizar(info) : await crear(info)
      if(response.success){
        toast(<Notification type={data ? "modifica_exito" :"agrega_exito"} withIcon />);
        localStorage.removeItem('dataContent')
        goBack()
      }
      else {
        toast(<Notification type={data ? "modifica_error" : "agrega_error"} backMessage={response.message} withIcon />);
      }
      setIsLoading(false)
    }
    catch(error){
      toast(<Notification type={"consultar_servidor_error"} withIcon />);
      setIsLoading(false)
    }
  }

  const { handleSubmit, values, handleBlur, errors, touched, setFieldValue,resetForm } =
    useFormik({
      initialValues: defaultForm,
      onSubmit: () => setModal(true),
      validationSchema: FormSchema,
  });

  return (
    <>
      {
        isLoading ? <Loader/>
        :
        <form onSubmit={handleSubmit} className="mt-4" noValidate>
          <ModalConfirmation
            modalTitle={data ? "Editar" : "Crear"}
            modal={modal}
            setModal={setModal}
            crear={saveAdvertisement}
            editar={saveAdvertisement}
            isEdit={data ? true : false}
            values={values}
          >
            {data ? (
              <div className="d-flex justify-content-center">
                <h6>¿Está seguro de editar el registro?</h6>
              </div>
            ) : (
              <div className="d-flex justify-content-center">
                <h6>¿Desea continuar con el registro?</h6>
              </div>
            )}
          </ModalConfirmation>

          <Row className="mt-2">

            <Col xs={12} md={12} lg={4}>
              <SelectTypeHeadSingle
                label="Periodo"
                isRequired={true}
                optionsArray={PERIOD}
                inputName="period"
                onChangeMethod={onChange}
                onBlurMethod={handleBlur}
                touched={touched.period}
                errors={errors.period}
                optionValue="value"
                optionName="label"
                value={values.period}
                defaultOption="Seleccione un periodo"
              />
            </Col>

            <Col xs={12} md={12} lg={4}>
              <label htmlFor={values.year}>  Año <span className="text-danger"> * </span> </label>
              <DatePicker
                minDate={new Date(`${date}`)}
                selected={values.year}
                onChange={(date) => onChangeDate({ name: "year", value: date })}
                showYearPicker
                dateFormat="yyyy"
                yearItemNumber={6}
                className={
                  "form-control" +
                  (errors.year && touched.year ? " is-invalid" : "")
                }
              />

              {touched.year && errors.year && (
                <span style={{ color: "red" }}>{errors.year}</span>
              )}
            </Col>

            <Col xs={12} md={12} lg={4}>
              <SelectTypeHeadSingle
                label="Tipo de leyenda"
                isRequired={true}
                optionsArray={advertisement}
                inputName="keyTypeAdverstisement"
                onChangeMethod={onChange}
                onBlurMethod={handleBlur}
                touched={touched.keyTypeAdverstisement}
                errors={errors.keyTypeAdverstisement}
                optionValue="keyTypeAdvertisement"
                optionName="nameTypeAdvertisement"
                value={values.keyTypeAdverstisement}
                defaultOption="Seleccione un tipo"
              />
            </Col>

            <Col xs={12} md={12} lg={4}>
              <label htmlFor={values.aplicationDate}> Fecha de Aplicacion <span className="text-danger"> * </span> </label>
              <DatePicker
                minDate={new Date(`${date}`)}
                selected={values.aplicationDate}
                onChange={(date) => onChangeDate({ name: "aplicationDate", value: date })}
                showTimeSelect
                timeFormat="p"
                timeIntervals={1}
                dateFormat="dd/MM/yyyy hh:mm"
                className={
                  "form-control" +
                  (errors.aplicationDate && touched.aplicationDate ? " is-invalid" : "")
                }
              />

              {touched.aplicationDate && errors.aplicationDate && (
                <span style={{ color: "red" }}>{errors.aplicationDate}</span>
              )}
            </Col>
            
          </Row>
              
          <div className="form-group" style={{marginTop: 20}}>
            <h5>Leyendas del comprobande de pago: </h5>
            <ReactQuill 
              theme="snow" 
              value={values.descripcion} 
              className={errors.descripcion && touched.descripcion ? "ql-error" : ''} // Add a dynamic class 
              onChange={handleEditor}
              modules={toolbar}
            />
            {errors.descripcion && touched.descripcion && (
              <span style={{ color: "red" }}>{errors.descripcion}</span>
            )}
          </div>

          <div className="row mt-3">
            <div className="col-sm-6 order-2 order-sm-1 text-center text-sm-left mb-4">
              <Button color="danger" type="reset" onClick={() => goBack()} > Cancelar </Button>
            </div>

            <div className="col-sm-6 order-1 order-sm-2 text-center text-sm-right mb-4">
              <Button color="success" type="submit"> Guardar </Button>
            </div>
            
          </div>

        </form>
      }
    </>
  );
};

export default FormHorarios;
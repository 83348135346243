import { useEffect } from "react";
import { useLocation } from "react-router";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { toast } from "react-toastify";
import axios from "axios";

import { peticionesReceiver } from "../../../helpers/peticionesReceiver";

import Notification from "../../../components/Notification/Notification";
import getActions from "../../../helpers/getPermissionForms";
import getRouters from "../../../components/Layout/function";

export function GetPermisos(setPermissionsForm) {
    // PERMISOS

    const loc = useLocation();
    const user = useSelector((state) => state.userInfo);
    const { id, profileType } = useSelector((state) => state.userInfo);

    //PERMISOS
    const history = useHistory();
    const getActionsUser = async () => {
        const pathname = loc.pathname.split("/")[2];
        if (pathname !== "dashboard") {
            const route = await getRouters(user.permission, pathname);
            if (!route.permission) {
                history.push("/template");
            } else {
                getPermissionsUser();
            }
        }
    };

    const getPermissionsUser = async () => {
        const pathname = loc.pathname.split("/")[2];
        const result = await getActions(
            id,
            profileType.selectProfile.keyProfile,
            pathname
        );
        setPermissionsForm(result.actions);
    };

    useEffect(() => {
        getActionsUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
}

function getParamsInsertUpdate(rows, insert) {
    const parametros = {
        table: "furnitures"
    }
    const datos = {
        idFurniture: rows.idFurniture,
        keyClass: rows.keyClass,
        keyColor: rows.keyColor,
        keyMaterial: rows.keyMaterial,
        keyUnit: rows.keyUnit,
        nameFurniture: rows.nameFurniture,
        model: rows.model,
        brand: rows.brand,
    }
    if (insert) {
        delete datos.idFurniture
        parametros.action = "insert"
        parametros.rows = datos;
    } else {
        parametros.action = "update"
        parametros.condition = { idFurniture: datos.idFurniture };
        parametros.rows = datos;
    }
    return parametros;
}

async function subirArchivo(formData, OnSuccess, data) {
    const params = {
        method: "post",
        url: `${process.env.REACT_APP_API}app/facades/files/fileReceiver.php`,
        data: formData,
    };
    try {
        await axios(params)
        toast(<Notification type={"agrega_exito"} withIcon />);
        if(OnSuccess){ OnSuccess(data);}
        else {window.location.href = "#/template/bienesmuebles"}
        // window.location.href = "#/template/bienesmuebles"
    } catch (err) {
        toast(<Notification type={"agrega_error"} withIcon />);
    }
}

export async function OnCreate(rows, OnSuccess) {
    const API = peticionesReceiver();
    const params = getParamsInsertUpdate(rows, true);
    try {
        const res = await API.peticion(params)
        if (res.status === 200 && res.data.code === "200") {
            const data = res.data.data[0];
            toast(<Notification type={"agrega_exito"} backMessage={res.data.message} withIcon />);
            if (rows.file) {
                const formData = new FormData();
                formData.append("action", "saveFile")
                formData.append("keyFileType", 24);
                formData.append('binary', rows.file)
                formData.append('reference', data.idFurniture)
                await subirArchivo(formData, OnSuccess, data)
            } else {
                if(OnSuccess){ OnSuccess(data);
                }else {window.location.href = "#/template/bienesmuebles"}
            }
        } else {
            toast(<Notification type={"agrega_error"} backMessage={res.data.message} withIcon />);
        }
    } catch (err) {
        toast(<Notification type={"agrega_error"} withIcon />);
    }
}

export async function OnEdit(rows, firstSRC) {
    const API = peticionesReceiver();
    const params = getParamsInsertUpdate(rows, false);
    try {
        const res = await API.peticion(params)
        if (res.status === 200 && res.data.code === "200") {
            const data = res.data.data[0];
            toast(<Notification type={"modifica_exito"} backMessage={res.data.message} withIcon />);
            if (rows.file && firstSRC === "") {
                const formData = new FormData();
                formData.append("action", "saveFile")
                formData.append("keyFileType", 24);
                formData.append('binary', rows.file)
                formData.append('reference', data.idFurniture)
                await subirArchivo(formData)
            } else if (rows.file && firstSRC !== "") {
                const formData = new FormData();
                formData.append("action", "saveFileReplace");
                formData.append("keyFileType", 24);
                formData.append('binary', rows.file)
                formData.append('reference', data.idFurniture)
                await subirArchivo(formData)
            } else {
                window.location.href = "#/template/bienesmuebles"
            }
        } else {
            toast(<Notification type={"modifica_error"} backMessage={res.data.message} withIcon />);
        }
    } catch (err) {
        toast(<Notification type={"modifica_error"} withIcon />);
    }
}

export async function OnDelete(row, refreshData) {
    if (row.idFurniture) {
        const API = peticionesReceiver();
        const params = {
            action: "delete",
            table: "furnitures",
            condition: {
                idFurniture: row.idFurniture,
            },
        };
        try {
            const res = await API.peticion(params)
            if (res.status === 200 && res.data.code === "200") {
                toast(<Notification type={"elimina_exito"} backMessage={res.data.message} withIcon />);
                refreshData()
            } else {
                toast(<Notification type={"elimina_error"} backMessage={res.data.message} withIcon />);
            }
        } catch (err) {
            toast(<Notification type={"elimina_error"} withIcon />);
        }
    } else {
        toast(<Notification type={"elimina_error"} backMessage={"El articulo no puede ser eliminado"} withIcon />);
    }
}
import { Button, Col, Row } from 'reactstrap';
import QrReader from 'react-qr-scanner'
import Notification from '../../../components/Notification/Notification';
import { toast } from 'react-toastify';

export default function LecturaQr({ setData, before }) {

    function handleScan(data){
        if (data && data.text.includes("verificatedNameTags.php?token=")) {
            setData(data.text)
        } else if (data) {
            toast(<Notification type={"consultar_error"} backMessage={"Ingrese un codigo QR valido"} withIcon />);
        }
    }

    function handleError(err){
        console.error(err)
    }


    return (
        <>
            <p className='font-weight-bold'>Paso 1:</p>
            <p>Escanee el codigo QR que viene en la credencial</p>
            <Row className='d-flex justify-content-center'>
                <Col sm={12} md={6}>
                    <QrReader
                        delay={100}
                        style={{ height: "100%", objectFit: "cover" }}
                        onError={handleError}
                        onScan={handleScan}
                    />
                </Col>
            </Row>
            <div className="text-center mt-3">
                <Button color='danger' onClick={before}>Regresar</Button>
            </div>
        </>
    )
}
import * as Yup from "yup";
import { useFormik } from "formik";
import { useState } from "react";
import { Button, Spinner } from "reactstrap";

import { SelectTypeHeadSingle, TextInput } from "../../../components/GenericInputsFormik";
import { months } from "../reportes/filtros/Inputs/SelectMonths";
import ModalConfirmation from "../../../components/Modal/ModalConfirmation";
import { peticionesReceiver } from "../../../helpers/peticionesReceiver";
import { toast } from "react-toastify";
import Notification from "../../../components/Notification/Notification";

const Reprogramacion = ({ annualBudget }) => {
  const actualDate = new Date();
  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);
  actualDate.setMonth(actualDate.getMonth() + 1);

  const defaultForm = {
    month: actualDate.getMonth(),
    description: ""
  }

  const FormSchema = Yup.object().shape({
    month: Yup.number().required("Error, no se a seleccionado el proximo mes"),
    description: Yup.string().required("Se requiere especificar el motivo de reprogramación")
  })

  const onChange = (e) => {
    const { name, value } = e.target
    setFieldValue([name], value);
  }

  const { handleSubmit, values, handleBlur, errors, touched, setFieldValue, resetForm } =
    useFormik({
      initialValues: defaultForm,
      onSubmit: () => setModal(true),
      validationSchema: FormSchema,
    });
  
  const editar = async (leaveU) => {
    setLoading(true)
    const API = peticionesReceiver();
    const params = {
      action: "saveReprogram",
      description: leaveU.description,
      idAnnualBudget: annualBudget.id,
      keyMonth: leaveU.month
    }
    await API.peticionEndPoint(params, "app/facades/budgets/budgetsF.php")
      .then((res) => {
        if (res.status === 200 && res.data.code === "200") {
          resetForm();
          setLoading(false);
          toast(<Notification type={"agrega_exito"} backMessage={res.data.message} withIcon />);
        } else {
          setLoading(false);
          toast(
            <Notification
              type={"consultar_error"}
              backMessage={res.data.message}
              withIcon
            />
          );
        }
      })
      .catch((err) => {
        setLoading(false);
        toast(<Notification type={"consultar_servidor_error"} withIcon />);
      });
  }

  return (
    <>
      <div className="mt-2">
        <h4>Reprogramación</h4>
        <form onSubmit={handleSubmit}>
          <SelectTypeHeadSingle
            label="Mes"
            inputName="month"
            onChangeMethod={onChange}
            onBlurMethod={handleBlur}
            value={values.month}
            isRequired={true}
            errors={errors.month}
            optionsArray={months}
            optionValue="value"
            optionName="label"
          />
          <TextInput
            label="Motivo de reprogramación"
            inputName="description"
            inputType="text"
            onChangeMethod={onChange}
            onBlurMethod={handleBlur}
            value={values.description}
            isRequired
            touched={touched.description}
            errors={errors.description}
          />
          <div className="text-right">
            <Button color="add" type="submit" disabled={loading}>
              {loading ? (
                <>
                  Cargando
                  <Spinner
                    color="light"
                    size="sm"
                    type="grow"
                    className="ml-2"
                  >
                    Loading...
                  </Spinner>
                </>
              ): (<>Reprogramar</>)}
            </Button>
          </div>
        </form>
      </div>
      <ModalConfirmation
        modalTitle="Reprogramar"
        modal={modal}
        setModal={setModal}
        editar={(rows) => editar(rows)}
        isEdit={true}
        values={values}
      >
        <div className="d-flex justify-content-center">
          <h6>¿Está seguro de reprogramar el registro?</h6>
        </div>
      </ModalConfirmation>
    </>
  );
};
export default Reprogramacion;

import * as Yup from "yup";
import { useFormik } from "formik";
import { Button, Col, Collapse, Container, Row } from "reactstrap";
import React, { useState, useEffect } from "react";
import Widget from "../../../components/Widget/Widget";
import ModalTableVer from "../../../components/Modal/ModalTableVer";
import Notification from "../../../components/Notification/Notification";
import { toast } from "react-toastify";
import axios from "axios";
import { peticionEncript } from "../../../helpers/peticionesEncripted";
import { SelectTypeHeadSingle } from "../../../components/GenericInputsFormik/SelectTypeHeadSingle";
import ModalConfirmation from "../../../components/Modal/ModalConfirmation";
import { GetPermissions } from "../../../libs/permissions/getPermissions";
import { ButtonInput } from "../../../components/GenericInputsFormik/ButtonInput";
import { fDate, fDateInput, fMoney } from "../../../libs/tools/format";
import FullFormLoader from "../../../components/Loader/FullFormLoader";
import pdfEstatusEmpleado from "./pdfEstatusEmpleado";
import { saveAs } from "file-saver";
import { pdf } from "@react-pdf/renderer";
import { TextAreaInput } from "../../../components/GenericInputsFormik";
import { getStaffPic } from "../../../libs/tools/images";

/* MODAL TABLA */
const filtroModalTabla = [true, true, true, true, true];
const cabecerasModalTabla = [
  "#",
  "Núm. Empleado",
  "Nombre",
  "Unidad de trabajo",
  "Puesto",
  "Seleccionar",
];
const paramsModalTabla = {
  action: "datatable",
  table:
    "staff STF LEFT JOIN employtypes AS EMT ON STF.keyEmployType = EMT.keyEmployType LEFT JOIN jobstaff AS JBS ON JBS.idStaff = STF.idStaff LEFT JOIN areas AS AR ON AR.idArea = JBS.idArea LEFT JOIN jobtypes AS JBT ON JBT.keyJobType = STF.keyEmployType",
  rows: "STF.idStaff,STF.staffNumber,CONCAT(STF.name,' ',STF.pName,' ',STF.mName) as fullName,AR.name,JBT.nameJobType",
  conditions: "STF.enabled = 1 AND IF(ISNULL(JBS.VALID), TRUE, JBS.VALID = 1 AND JBS.enabled = 1)",
  page: 0,
  records: 5,
  search: "",
  order: "",
};

/* CONSULTA BACK */
async function getBackInfo(params, rute, canSee) {
  let info = [];
  const dataEncrypted = peticionEncript(params);
  await axios
    .post(process.env.REACT_APP_API + rute, dataEncrypted)
    .then((res) => {
      if (res.status === 200 && res.data.status === "success") {
        info = res.data.data;
      } else {
        if (canSee !== false) {
          toast(
            <Notification
              type={"consultar_error"}
              backMessage={res.data.message}
            />,
            { closeButton: false }
          );
        }
      }
    })
    .catch((err) => {
      toast(<Notification type={"consultar_servidor_error"} withIcon />);
    });
  return info;
}

const getStatus = async (setStatus) => {
  const params = {
    action: "select",
    table: "status",
    order: "nameStat ASC",
    condition: { keyTypeStat: 1 },
  };
  let data = await getBackInfo(params, "receiver/receiver.php");
  setStatus(data);
};

const getStaffInfo = async (idStaff) => {
  const params1 = { action: "getStaffData", idStaff: idStaff };
  let data = await getBackInfo(params1, "app/facades/reports/reportsF.php");
  if (data.length > 0) {
    let cleanData = { ...data[0] };
    const params2 = {
      action: "multiselect",
      table: "staffview A",
      rows: `A.nameGender, A.domicilio, A.jobNumber, A.telefonoPersonal, TIMESTAMPDIFF(YEAR, A.bornDate, '${fDateInput(
        new Date()
      )}') AS age, A.gross`,
      conditions: `idStaff = ${idStaff}`,
    };
    const params3 = {
      action: "multiselect",
      table:
        "staffschoolarity ssh INNER JOIN schoolaritytypes st ON ssh.keySchoolarityType = st.keySchoolarityType INNER JOIN careertypes cr ON ssh.keyCareerType = cr.keyCareerType",
      rows: `nameCareerType`,
      conditions: `ssh.idStaff = ${idStaff} AND ssh.enabled=1`,
      order: "priority DESC",
    };
    const params4 = {
      action: "multiselect",
      table: "jobstaff A LEFT JOIN areas B ON B.idArea = A.idAreaComission",
      rows: `B.name AS nameFisica`,
      conditions: `A.idStaff = ${idStaff} AND A.valid = 1 AND A.enabled = 1`,
    };
    const params5 = {
      action: "multiselect",
      table: "staffadress A",
      rows: `A.*`,
      conditions: `A.idStaff = ${idStaff} AND A.enabled = 1`,
    };

    const [ewe, ewe2, ewe3, ewe4] = await Promise.all([
      getBackInfo(params2, "receiver/receiver.php", false),
      getBackInfo(params3, "receiver/receiver.php", false),
      getBackInfo(params4, "receiver/receiver.php", false),
      getBackInfo(params5, "receiver/receiver.php", false),
    ]);

    cleanData = {
      ...cleanData,
      ...(ewe[0] || {}),
      ...(ewe2[0] || {}),
      ...(ewe3[0] || {}),
      ...(ewe4[0] || {}),
    };
    return cleanData;
  }
  return null;
};

const defaultForm = {
  idStaff: "",
  nameStaff: "",
};

const FormSchema = Yup.object().shape({
  nameStaff: Yup.string().required("Seleccione un empleado"),
});

const date = new Date().getFullYear();

export const EstatusEmpleados = () => {
  const [permissions, setPermissions] = useState({ LOD: true });
  GetPermissions(setPermissions);

  const [modalTabla, setModalTabla] = useState(false);
  const [status, setStatus] = useState([]);
  const [collapseForm, setCollapseForm] = useState(false);
  const [employeeForm, setEmployeeForm] = useState(false);
  const [employee, setEmployee] = useState("");
  const [employeeEstatus, setEmployeeEstatus] = useState("");
  const [loading, setLoading] = useState(false);
  const [modalConfirm, setModalConfirm] = useState(false);
  const [notes, setNotes] = useState("");

  const { handleSubmit, values, errors, touched, setFieldValue, resetForm } =
    useFormik({
      initialValues: defaultForm,
      onSubmit: () => {
        getEmployee();
      },
      validationSchema: FormSchema,
      enableReinitialize: true,
    });

  useEffect(() => {
    getStatus(setStatus);
  }, []);

  /* Limpiar el empleado seleccionado */
  function clearStaff() {
    resetForm();
    if (collapseForm) {
      setCollapseForm(false);
    }
  }

  /* Funcion que se envia al modal al seleccionar un empleado */
  const selectEmpleado = (el) => {
    setFieldValue("idStaff", el[0]);
    setFieldValue("nameStaff", `${el[1]} - ${el[2]}`);
    setModalTabla(false);
    setCollapseForm(false);
  };

  /* Funcion para buscar y mostrar al empleado */
  const getEmployee = async () => {
    setLoading(true);
    const data = await getStaffInfo(values.idStaff);
    setLoading(false);
    if (data) {
      console.log(data);
      setEmployee(data);
      setEmployeeEstatus(data.statusStaff);
      setEmployeeForm(true);
      setCollapseForm(true);
    }
  };

  /* Funcion para cambiar el estatus del empleado */
  const changeStatus = (e) => {
    setEmployeeEstatus(e.target.value);
  };

  /* Funcion para guardar el estatus del empleado */
  const updatEstatus = async () => {
    const params = {
      action: "update",
      table: "staff",
      rows: { keyStat: employeeEstatus },
      condition: {
        idStaff: values.idStaff,
      },
      force: "1",
    };
    const dataEncrypted = peticionEncript(params);
    await axios
      .post(process.env.REACT_APP_API + "receiver/receiver.php", dataEncrypted)
      .then((res) => {
        if (res.status === 200 && res.data.code === "200") {
          toast(
            <Notification
              type={"modifica_exito"}
              backMessage={res.data.message}
              withIcon
            />,
            { closeButton: false }
          );
        } else {
          toast(
            <Notification
              type={"modifica_error"}
              backMessage={res.data.message}
              withIcon
            />,
            { closeButton: false }
          );
        }
      })
      .catch((err) => {
        toast(<Notification type={"consultar_servidor_error"} withIcon />, {
          closeButton: false,
        });
      });
  };

  async function makePDF() {
    setLoading(true);
    try {
      const nameStat = employeeEstatus
        ? status.find(
            (stat) => stat.keyStat.toString() === employee.statusStaff
          )
        : "";
      const blobPdf = await pdf(
        pdfEstatusEmpleado(
          employee,
          nameStat && nameStat !== "" ? nameStat.nameStat : "",
          notes
        )
      ).toBlob();
      if (!!blobPdf && typeof blobPdf === "object") {
        const file = new File(
          [blobPdf],
          `Estatus - ${employee.staffNumber}.pdf`
        );
        saveAs(file, `Estatus - ${employee.staffNumber}.pdf`);
      }
    } catch (e) {
      console.error(e);
      toast(
        <Notification
          type={"modifica_error"}
          backMessage="Ocurrio un problema al generar el archivo"
          withIcon
        />,
        { closeButton: false }
      );
    }
    setLoading(false);
  }

  return (
    <Widget className="widget-p-md">
      <div className="text-center text-md-left mb-4">
        <h3 className="text-secondary">Estatus empleado</h3>
      </div>

      <div>
        <form onSubmit={handleSubmit}>
          <Row>
            <Col xs={12}>
              <ButtonInput
                label="Empleado"
                inputName="nameStaff"
                value={values.nameStaff}
                touched={touched.nameStaff}
                errors={errors.nameStaff}
                clearFun={clearStaff}
                onButtonClicik={() => setModalTabla(true)}
                isRequired
              />
            </Col>
          </Row>
          <div className="text-center mt-4 mb-4 pb-3">
            <Button color="info" type="submit">
              Consultar empleado
            </Button>
          </div>
        </form>
      </div>
      <Collapse isOpen={collapseForm} onExited={() => setEmployeeForm(false)}>
        {employeeForm && (
          <>
            <Row className="text-dark">
              <Col xs={12} lg={3} className="py-4 text-center">
                <img
                  src={getStaffPic(employee.nameGender, employee.src)}
                  className="img-thumbnail w-100"
                  alt="employe-picture"
                  style={{ maxWidth: "300px" }}
                />
              </Col>
              <Col xs={12} lg={9}>
                <Container className="border border-right-0">
                  <Row className="border-bottom bg-secondary border-right">
                    <Col xs={12} className="font-weight-bold text-light py-1">
                      DATOS GENERALES DEL EMPLEADO
                    </Col>
                  </Row>
                  <Row>
                    <Col
                      xs={12}
                      lg={3}
                      className="border-bottom border-right px-2"
                    >
                      <strong>No. Empleado: </strong>
                      <p>{employee.staffNumber}</p>
                    </Col>
                    <Col
                      xs={12}
                      lg={5}
                      className="border-bottom border-right px-2"
                    >
                      <strong>Nombre: </strong>
                      <p>{employee.name}</p>
                    </Col>
                    <Col
                      xs={12}
                      lg={4}
                      className="border-bottom border-right px-2"
                    >
                      <strong>Tipo nombramiento: </strong>
                      <p>{employee.nameAssignment || "SIN ASIGNAR"}</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col
                      xs={12}
                      lg={3}
                      className="border-bottom border-right px-2"
                    >
                      <strong>Edad: </strong>
                      <p>{employee.age}</p>
                    </Col>
                    <Col
                      xs={12}
                      lg={3}
                      className="border-bottom border-right px-2"
                    >
                      <strong>Género: </strong>
                      <p>{employee.nameGender}</p>
                    </Col>
                    <Col
                      xs={12}
                      lg={3}
                      className="border-bottom border-right px-2"
                    >
                      <strong>Teléfono: </strong>
                      <p>{employee.jobNumber}</p>
                    </Col>
                    <Col
                      xs={12}
                      lg={3}
                      className="border-bottom border-right px-2"
                    >
                      <strong>Teléfono personal: </strong>
                      <p>{employee.telefonoPersonal}</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col
                      xs={12}
                      lg={6}
                      className="border-bottom border-right px-2"
                    >
                      <strong>Escolaridad: </strong>
                      <p>{employee.nameCareerType}</p>
                    </Col>
                    <Col
                      xs={12}
                      lg={6}
                      className="border-bottom border-right px-2"
                    >
                      <strong>Domicilio: </strong>
                      <p>{employee.domicilio}</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col
                      xs={12}
                      lg={12}
                      className="border-bottom border-right px-2"
                    >
                      <span className="font-weight-bold">Adscripción:</span>{" "}
                      <p>{employee.nameArea || "SIN ASIGNAR"}</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col
                      xs={12}
                      lg={8}
                      className="border-bottom border-right px-2"
                    >
                      <span className="font-weight-bold">
                        Adscripción física:
                      </span>
                      <p>{employee.nameFisica || "SIN ASIGNAR"}</p>
                    </Col>
                    <Col
                      xs={12}
                      lg={4}
                      className="border-bottom border-right px-2"
                    >
                      <span className="font-weight-bold">Región:</span>{" "}
                      <p>{employee.nameRegionArea}</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col
                      xs={12}
                      lg={4}
                      className="border-bottom border-right px-2"
                    >
                      <strong>Puesto laboral: </strong>
                      <p>{employee.nameJob || "SIN ASIGNAR"}</p>
                    </Col>
                    <Col
                      xs={12}
                      lg={4}
                      className="border-bottom border-right px-2"
                    >
                      <strong>Nivel-Rango: </strong>
                      <p>{employee.levelrange || "SIN ASIGNAR"}</p>
                    </Col>
                    <Col
                      xs={12}
                      lg={4}
                      className="border-bottom border-right px-2"
                    >
                      <strong>Sueldo neto: </strong>
                      <p>{fMoney(employee.net || "SIN ASIGNAR")}</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col
                      xs={12}
                      lg={4}
                      className="border-bottom border-right px-2"
                    >
                      <strong>Fecha ingreso: </strong>
                      <p>{fDate(employee.startJobDate)}</p>
                    </Col>
                    <Col
                      xs={12}
                      lg={4}
                      className="border-bottom border-right px-2"
                    >
                      <strong>CURP: </strong>
                      <p>{employee.curp}</p>
                    </Col>
                    <Col
                      xs={12}
                      lg={4}
                      className="border-bottom border-right px-2"
                    >
                      <strong>RFC: </strong>
                      <p>{employee.rfc}</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col
                      xs={12}
                      lg={4}
                      className="border-bottom border-right px-2"
                    >
                      <strong>Fecha termino contrato: </strong>
                      <p>{fDate(employee.firedDate || "SIN ASIGNAR")}</p>
                    </Col>
                    <Col
                      xs={12}
                      lg={4}
                      className="border-bottom border-right px-2"
                    >
                      <strong>Fecha ingreso Issemym: </strong>
                      <p>{fDate(employee.SecurityKeyStartDate)}</p>
                    </Col>
                    <Col
                      xs={12}
                      lg={4}
                      className="border-bottom border-right px-2"
                    >
                      <strong>Clave Issemym: </strong>
                      <p>{employee.socialSecurityKey}</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col
                      xs={12}
                      lg={4}
                      className="border-bottom border-right px-2"
                    >
                      <strong>Banco: </strong>
                      <p>{employee.nameBank}</p>
                    </Col>
                    <Col
                      xs={12}
                      lg={4}
                      className="border-bottom border-right px-2"
                    >
                      <strong>Cuenta: </strong>
                      <p>{employee.cuenta}</p>
                    </Col>
                    <Col
                      xs={12}
                      lg={4}
                      className="border-bottom border-right px-2"
                    >
                      <strong>Clabe: </strong>
                      <p>{employee.clabe || "S/N"}</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} className="border-right px-2">
                      <div className="mt-1">
                        <strong>Estatus: </strong>
                        <strong className="text-danger">(*)</strong>
                      </div>
                      <SelectTypeHeadSingle
                        // label="Estatus"
                        // isRequired={true}
                        inputName="keyStat"
                        optionsArray={status}
                        defaultOption="Seleccione un estatus"
                        onChangeMethod={changeStatus}
                        isDisabled={!permissions.UPD}
                        value={employeeEstatus}
                        optionValue="keyStat"
                        optionName="nameStat"
                      />
                    </Col>
                  </Row>
                </Container>
              </Col>
            </Row>
            <Row>
              <Col
                xs={12}
                lg={3}
                className="d-flex flex-md-row flex-column justify-content-center p-3"
              ></Col>
              {permissions.UPD && (
                <Col
                  xs={12}
                  lg={9}
                  className="d-flex flex-md-row flex-column justify-content-center p-3"
                >
                  <Button
                    color="success"
                    disabled={employeeEstatus === ""}
                    onClick={() => setModalConfirm(true)}
                    className="my-1"
                  >
                    Guardar
                  </Button>
                </Col>
              )}
            </Row>
          </>
        )}
        <div>
          <TextAreaInput
            label="Notas:"
            inputName="notes"
            inputRows={5}
            onChangeMethod={({ target }) => setNotes(target.value)}
            value={notes}
          />
          <Button color="danger" onClick={() => makePDF()} className="my-1">
            <i className="fa fa-file-pdf-o mr-1" style={{ fontSize: "15px" }} />
            Descargar
          </Button>
        </div>
      </Collapse>
      <FullFormLoader show={loading} />
      <ModalConfirmation
        modalTitle="Actualizar estatus"
        modal={modalConfirm}
        setModal={setModalConfirm}
        editar={updatEstatus}
        isEdit={true}
      >
        <div className="d-flex justify-content-center">
          <h6>¿Está seguro de modificar el estatus del empleado?</h6>
        </div>
      </ModalConfirmation>

      <ModalTableVer
        modal={modalTabla}
        setModal={setModalTabla}
        title="Empleados"
        cabeceras={cabecerasModalTabla}
        filtro={filtroModalTabla}
        parametros={paramsModalTabla}
        selectRegistro={selectEmpleado}
        backdrop
        keyboard
        btnClear
      />
    </Widget>
  );
};

export default EstatusEmpleados;

import {
    Document,
    Page,
    StyleSheet,
    Text,
    View,
} from "@react-pdf/renderer";
import HeaderReport from "../../../../../components/Pdf/Header";
import FooterReport from "../../../../../components/Pdf/Footer";
import { fDateTime, fMoney, fPercent } from "../../../../../libs/tools/format";


const s = StyleSheet.create({
    body: {
        paddingVertical: "30px",
        paddingHorizontal: "20px",
    },

    title: {
        fontSize: "12px",
        textAlign: "center",
        fontFamily: "Helvetica-Bold",
    },

    header: {
        backgroundColor: "#730A29",
        textAlign: "center",
        color: "#FFFFFF",
        fontWeight: "bold"
    },

    mCell: { padding: "1px" },
    mxCell: { paddingHorizontal: "2px" },

    vCenter: { alignItems: "center", justifyContent: "center" },
    vCenterL: { alignItems: "flex-start", justifyContent: "center",  },
    vCenterR: { alignItems: "flex-end", justifyContent: "center" },
    vCenterBtw: { alignItems: "stretch", justifyContent: "center" },

    dflexBtw: { display: "flex", justifyContent: "space-between" },
    dflexEnd: { display: "flex", justifyContent: "flex-end" },

    alignE: { alignContent: "flex-end" },

    row: { flexDirection: "row" },
    flexWrap: { display: "flex", flexWrap: "wrap", flexDirection: "row" },

    w5: { width: "5%" },
    w8: { width: "8%" },
    w10: { width: "10%" },
    w15: { width: "15%" },
    w20: { width: "20%" },
    w21: { width: "21.1%" },
    w25: { width: "25%" },
    w30: { width: "30%" },
    w33: { width: "33%" },
    w34: { width: "34%" },
    w36: { width: "36%" },
    w29: { width: "28.9%" },
    w40: { width: "40%" },
    w45: { width: "45%" },
    w50: { width: "50%" },
    w55: { width: "55%" },
    w60: { width: "60%" },
    w65: { width: "65%" },
    w80: { width: "80%" },
    w90: { width: "90%" },
    w100: { width: "100%" },
    wAuto: { width: "auto" },

    fborder: { border: "1px solid #000" },
    border: { border: "1px solid #000", borderBottom: "0px" },
    borderT: { borderBottom: "1px solid #000" },
    borderB: { borderBottom: "1px solid #000" },
    borderB2: { borderBottom: "2px solid #000" },
    borderE: { borderRight: "1px solid #000" },
    borderS: { borderLeft: "1px solid #000" },
    borderBS: { borderBottom: "4px solid #730A29" },
    borderHE: { borderRight: "1px solid #FFFFFF" },
    borderD: { border: "1px solid #000", borderTop: "0px" },

    bg: { backgroundColor: "#BEBEBE" },
    bg2: { backgroundColor: "#BEBEBE" },
    bglg: { backgroundColor: "#adb5bd" },

    fs7: { fontSize: "7px" },
    fs8: { fontSize: "8px" },
    fs9: { fontSize: "9px" },
    fs10: { fontSize: "10px" },
    fs11: { fontSize: "11px" },
    fs12: { fontSize: "12px" },
    fs13: { fontSize: "13px" },

    tend: { textAlign: "right", paddingRight: "2px" },
    tstart: { textAlign: "left" },
    tcenter: { textAlign: "center" },
    tBold: { fontFamily: "Helvetica-Bold" },

    mT1: { marginTop: "1px" },
    mT2: { marginTop: "2px" },
    mT8: { marginTop: "8px" },
    mT30: { marginTop: "30px" },
    mT20: { marginTop: "20px" },
    mT70: { marginTop: "70px" },
    mx5: { marginHorizontal: "5px" },
    mx18: { marginHorizontal: "18px" },
    mx20: { marginHorizontal: "20px" },
    mb2: { marginBottom: "2px" },

    spaceFooter: {
        bottom: 10,
        left: 0,
        right: 0,
        width: "auto",
        height: 30,
        marginHorizontal: 10,
    },
});

export default async function SalariosDetallePDF(data, cfg, t, fileNo) {
    return {
        report: (
            <Document>
                <Page style={[s.body, s.fs10]} orientation="landscape">
                    <HeaderReport />
                    <View style={[s.title]}>
                        <Text>TABULADOR HISTORICO - DETALLES SALARIOS</Text>
                    </View>
                    <View style={[{marginVertical: "4px"}]}>
                        <Text>
                            <Text style={[s.tBold]}>Incremento Salarial: </Text>
                            <Text>{cfg.idSalaryIncrease}</Text>
                        </Text>
                        <Text>
                            <Text style={[s.tBold]}>Fecha Apliación: </Text>
                            <Text>{fDateTime(cfg.fechaApliacion)}</Text>
                        </Text>
                    </View>
                    <View style={[s.mT8, s.fs10]}>
                        <View style={[s.row, s.header, s.tcenter, s.fborder]} fixed>
                            <View style={[s.w5, s.borderHE]}>
                                <Text style={[s.mCell]}>#</Text>
                            </View>
                            <View style={[s.w5, s.borderHE]}>
                                <Text style={[s.mCell]}>Nivel</Text>
                            </View>
                            <View style={[s.w5, s.borderHE]}>
                                <Text style={[s.mCell]}>Rango</Text>
                            </View>
                            <View style={[s.w8, s.borderHE]}>
                                <Text style={[s.mCell]}>S. Base</Text>
                            </View>
                            <View style={[s.w8, s.borderHE]}>
                                <Text style={[s.mCell]}>S. Bruto</Text>
                            </View>
                            <View style={[s.w8, s.borderHE]}>
                                <Text style={[s.mCell]}>S. Neto</Text>
                            </View>
                            <View style={[s.w8, s.borderHE]}>
                                <Text style={[s.mCell]}>Gratificación</Text>
                            </View>
                            <View style={[s.w8, s.borderHE]}>
                                <Text style={[s.mCell]}>Despensa</Text>
                            </View>
                            <View style={[s.w8, s.borderHE]}>
                                <Text style={[s.mCell]}>Viáticos</Text>
                            </View>
                            <View style={[s.w10, s.borderHE]}>
                                <Text style={[s.mCell]}>Compensación</Text>
                            </View>
                            <View style={[s.w10, s.borderHE]}>
                                <Text style={[s.mCell]}>Fortalecimiento</Text>
                            </View>
                            <View style={[s.w8, s.borderHE]}>
                                <Text style={[s.mCell]}>Impuestos</Text>
                            </View>
                            <View style={[s.w8]}>
                                <Text style={[s.mCell]}>Issemym</Text>
                            </View>
                        </View>
                        {
                            data.map((el, ix)=>(
                                <View style={[s.row, s.borderD]} key={"r"+ix} wrap={false}>
                                    <View style={[s.w5, s.borderE, s.vCenter]}>
                                        <Text style={[s.mCell]}>{ix+1}</Text>
                                    </View>
                                    <View style={[s.w5, s.borderE, s.vCenter]}>
                                        <Text style={[s.mCell]}>{el.level}</Text>
                                    </View>
                                    <View style={[s.w5, s.borderE, s.vCenter]}>
                                        <Text style={[s.mCell]}>{el.rango}</Text>
                                    </View>
                                    <View style={[s.w8, s.borderE, s.vCenterR]}>
                                        <Text style={[s.mCell]}>{fMoney(el.sueldoBase)}</Text>
                                    </View>
                                    <View style={[s.w8, s.borderE, s.vCenterR]}>
                                        <Text style={[s.mCell]}>{fMoney(el.sueldoBruto)}</Text>
                                    </View>
                                    <View style={[s.w8, s.borderE, s.vCenterR]}>
                                        <Text style={[s.mCell]}>{fMoney(el.sueldoNeto)}</Text>
                                    </View>
                                    <View style={[s.w8, s.borderE, s.vCenterR]}>
                                        <Text style={[s.mCell]}>{fMoney(el.gratificacion)}</Text>
                                    </View>
                                    <View style={[s.w8, s.borderE, s.vCenterR]}>
                                        <Text style={[s.mCell]}>{fMoney(el.despensa)}</Text>
                                    </View>
                                    <View style={[s.w8, s.borderE, s.vCenterR]}>
                                        <Text style={[s.mCell]}>{fMoney(el.viaticos)}</Text>
                                    </View>
                                    <View style={[s.w10, s.borderE, s.vCenterR]}>
                                        <Text style={[s.mCell]}>{fMoney(el.compensacion)}</Text>
                                    </View>
                                    <View style={[s.w10, s.borderE, s.vCenterR]}>
                                        <Text style={[s.mCell]}>{fMoney(el.fortalecimiento)}</Text>
                                    </View>
                                    <View style={[s.w8, s.borderE, s.vCenterR]}>
                                        <Text style={[s.mCell]}>{fMoney(el.impuestos)}</Text>
                                    </View>
                                    <View style={[s.w8, s.vCenterR]}>
                                        <Text style={[s.mCell]}>{fMoney(el.issemym)}</Text>
                                    </View>
                                </View>
                            ))
                        }
                    </View>
                    <FooterReport fileNo={fileNo}/>
                </Page>
            </Document>
        ),
    };
}


import React, { useEffect, useState } from "react";
import Widget from "../../../components/Widget/Widget";
import { Row, Col, CustomInput } from "reactstrap";
import { peticionesReceiver } from "../../../helpers/peticionesReceiver";
import { toast } from "react-toastify";
import Notification from "../../../components/Notification/Notification";
import { GetPermissions } from "../../../libs/permissions/getPermissions";

const ControlSuficiencia = () => {
  const API = peticionesReceiver();
  const [status, setStatus] = useState("");
  const [permissions, setPermissions] = useState(true);
  const [loading, setLoading] = useState(true);



  const [prueba,setPrueba] = useState(true)

  GetPermissions(setPermissions, setLoading);

  useEffect(() => {
    getStatus();
  }, []);

  const getStatus = async () => {
    const params = {
      action: "multiselect",
      table: "budgetcontrol",
      rows: "*",
      conditions: "",
    };
    await API.peticion(params)
      .then((res) => {
        if (res.status === 200 && res.data.code === "200") {
          setStatus(res.data.data[0]);
        } else {
          toast(
            <Notification
              type={"consultar_error"}
              backMessage={res.data.message}
              withIcon
            />
          );
        }
      })
      .catch((err) => {
        toast(<Notification type={"consultar_servidor_error"} withIcon />);
      });
  };

  const sendPetitionChangestatus = async () => {
    if (permissions.UPD) {
      const params = {
        action: "update",
        table: "budgetcontrol",
        rows: {
          enabled: +status.enabled === 1 ? 0 : 1,
        },
        condition: {
          keyBudgetControl: status.keyBudgetControl,
        },
      };

      const res = await API.peticion(params);
      if (res.status === 200 && res.data.status === "success") {
        toast(
          <Notification
            type={"agrega_exito"}
            backMessage={res.data.message}
            withIcon
          />
        );
        setStatus(res.data.data[0]);
      } else {
        toast(
          <Notification
            type={"agrega_error"}
            backMessage={res.data.message}
            withIcon
          />
        );
      }
    } else {
      toast(
        <Notification
          type={"agrega_error"}
          backMessage={"NO TIENE PERMISOS PARA EDITAR ESTE MODULO"}
          withIcon
        />
      );
    }
  };

  return (
    <Widget className={"widget-p-md"}>
      <Row className="mb-5">
        <Col className="text-center mt-3">
          <h1 className="mt-5 mb-5">Control de suficiencia presupuestal</h1>

          <h5 className="mt-5">
            Utilice el interruptor en la parte superior para habilitar o
            deshabilitar la emision de Suficiencias Presupuestales
          </h5>
        </Col>
      </Row>

      <Row classname="d-flex flex-row  justify-content-center ">
        {permissions.CON ? (
          <>
            <Col style={{display:"flex",justifyContent:"center",height:"fit-content"}}>


            <div className="toggle-container" onClick={() => sendPetitionChangestatus()}>
              <div className={`toggle-btn ${+status.enabled !== 1 ? "disable" : ""}` }>
                {+status.enabled === 1 ? "ON" : "OFF"}
              </div>
            </div>

              {/* <label
                className={`switch ${status.enabled === 1 ? "checked" : ""}`}
              >
                <CustomInput
                  type="switch"
                  id="forPeriod"
                  name="forPeriod "
                  className="mx-auto text-center"
                  onChange={() => {
                    sendPetitionChangestatus();
                  }}
                  checked={status.enabled === 1 ? true : false}
                />


              </label> */}
            </Col>
          </>
        ) : (
          <h3 className="mt-5">No tiene permisos para consultar este modulo</h3>
        )}
      </Row>
    </Widget>
  );
};

export default ControlSuficiencia;

import { peticionesReceiver } from "../../../helpers/peticionesReceiver";
import Notification from "../../../components/Notification/Notification";
import { toast } from "react-toastify";
const API = peticionesReceiver();

export const createCountry = async (paisN) => {
    if (paisN) {
      const params = {
        action: "insert",
        table: "countries",
        rows: paisN,
        order: "nameCountry asc",
        validate: [
          ["acronim", "nameCountry", "phoneKey"],
          ["acronim"],
          ["nameCountry"],
          ["phoneKey"]
      ]
      };
      API.peticion(params).then((res) => { 
          if (res.status === 200 && res.data.code === "200") {
            toast(<Notification type={"agrega_exito"} withIcon />);
            window.location.href = "#/template/paises"
          }else{
            toast(<Notification type={"agrega_error"} backMessage={res.data.message} withIcon />);
          }
        })
        .catch((err) =>{
        toast(<Notification type={"consultar_servidor_error"} withIcon />);
      });
    }
  };

 export const updateCountry = async (paisU) => {
    if (paisU) {
      const params = {
        action: "update",
        table: "countries",
        rows: paisU,
        condition: {
          keyCountry: paisU.keyCountry,
        },
        validate: [["acronim"], ["nameCountry"], ["phoneKey"]]
      };
      API.peticion(params).then((res) => { 
          if (res.status === 200 && res.data.code === "200") {
            toast(<Notification type={"modifica_exito"} withIcon />);
            window.location.href = "#/template/paises"
          }else{
            toast(<Notification type={"modifica_error"} backMessage={res.data.message} withIcon />);
          }
        })
        .catch((err) =>{
        toast(<Notification type={"consultar_servidor_error"} withIcon />);
      });
    }
  };
import React, { useState, useEffect, useRef } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Geocode from "react-geocode";
import { toast } from "react-toastify";
import { Marker, GoogleMap, useJsApiLoader } from "@react-google-maps/api";
import { NumberInput } from "../../../components/GenericInputsFormik/NumberInput";
import {
  Row,
  Col,
  Label,
  Input,
  Button,
  Collapse,
  FormGroup,
  FormFeedback,
} from "reactstrap";

import {
  getPaises,
  getEstados,
  getMunicipios,
  getCiudades,
} from "./functionsLocation";

import { peticionPersonal } from "../../../helpers/peticionesPersonal";
import Notification from "../../../components/Notification/Notification";
import ModalConfirmation from "../../../components/Modal/ModalConfirmation";
import { SelectTypeHeadSingle, TextInput } from "../../../components/GenericInputsFormik/index";
import TableComponentPersonalNoEdit from "../../../components/TableComponent/TableComponentPersonalNoEdit";

import Loader from "../../../components/Loader/Loader";
import FullFormLoader from "../../../components/Loader/FullFormLoader";
import { getParamsDomicilio } from "./functions";

const containerStyle = {
  width: "700px",
  height: "400px",
};

const centerMx = {
  lat: 20.0,
  lng: -100.0,
};

const gmap = process.env.REACT_APP_GMAP;

const defaultForm = {
  idStaffAdress: "",
  idStaff: "",
  keyCity: "",
  street: "",
  intNumber: "",
  extNumber: "",
  subStreet: "",
  zipCode: "",
  latitude: "",
  longitude: "",
  keyCountry: "",
  keyState: "",
  keyCounty: "",
};

const FormSchema = Yup.object().shape({
  keyCountry: Yup.string().required("Seleccione un país"),
  keyState: Yup.string().required("Seleccione una ciudad"),
  keyCounty: Yup.string().required("Seleccione un municipio"),
  keyCity: Yup.string().required("Seleccione una localidad"),
  street: Yup.string()
    .min(3, "La calle debe tener al menos 3 caracteres")
    .max(100, "El nombre de la calle es muy largo")
    .required("Ingrese una calle"),
  subStreet: Yup.string()
    .min(3, "El nombre de la colonia debe tener al menos 3 caracteres")
    .max(100, "El nombre de la colonia es muy largo")
    .required("Ingrese una colonia"),
  extNumber: Yup.string()
    .max(45, "Número exterior demasiado largo")
    .required("Ingrese un número exterior"),
  zipCode: Yup.string()
    .min(5, "Código postal debe de contener 5 digitos")
    .max(5, "Código postal 5 digitos")
    .required("Ingrese un código postal"),
  latitude: Yup.number().required(
    "No se ha establecido una localización en el mapa"
  ),
});

const PersonalDomicilio = ({ idStaff, permissions, staffValid, setActiveTab, setUpload, upload }) => {
  const [center, setCenter] = useState(centerMx);
  const [marker, setMarker] = useState();
  const [showMap, setShowMap] = useState(false);
  const [zoom, setZoom] = useState(15);
  const [loading, setLoading] = useState(false);
  const [selectsData, setSelectsData] = useState({
    paisesData: [],
    estadosData: [],
    municipiosData: [],
    ciudadesData: [],
  });
  const [finalData, setFinalData] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [modal, setModal] = useState(false);
  const [title, setTitle] = useState("");
  const [form, setForm] = useState(defaultForm);
  const [editando, setEditando] = useState(false);
  const cabeceras = [
    "Id",
    "Calle", //street, extNumber
    "Colonia", //subStreet
    "Código Postal", //zipCode
    "Localidad", //nameCity ~ keyCity
    "Vigente",
  ];
  const filtro = [true, true, true, true, true, true];
  const [parametros, setParametros] = useState({
    action: "datatable",
    table:
      "staffadress A INNER JOIN cities B ON A.keyCity = B.keyCity INNER JOIN counties C ON B.keyCounty = C.keyCounty INNER JOIN states D ON C.keyState = D.keyState INNER JOIN countries E ON D.keyCountry = E.keyCountry",
    rows: "A.idStaffAdress,CONCAT(A.street,' ',A.extNumber) as fullCalle,A.subStreet,A.zipCode,B.nameCity,IF(A.enabled=1,'SI','NO') as enabled,A.street,A.idStaff,A.extNumber,A.latitude,A.longitude,A.intNumber,B.keyCity,C.keyCounty,D.keyState,E.keyCountry",
    conditions: `idStaff=${idStaff}`,
    page: 0,
    records: 5,
    search: "",
    order: "A.created desc  ",
  });
  const refScroll = useRef(null);

  const openForm = () => {
    setTitle("Agregar dirección");
    setShowMap(true);
    setIsOpen(true);
  };

  useEffect(() => {
    if (staffValid !== null && upload !== null && !upload.dom) {
      const params = getParamsDomicilio(idStaff, staffValid.SAT)
      setFieldValue("idStaff", params.idStaff);
      setFieldValue("keyCity", params.keyCity);
      setFieldValue("street", params.street);
      setFieldValue("intNumber", params.intNumber);
      setFieldValue("extNumber", params.extNumber);
      setFieldValue("subStreet", params.subStreet);
      setFieldValue("zipCode", params.zipCode);
      setFieldValue("latitude", params.latitude);
      setFieldValue("longitude", params.longitude);
      setFieldValue("keyCountry", params.keyCountry);
      setFieldValue("keyState", params.keyState);
      setFieldValue("keyCounty", params.keyCounty);
      openForm()
    }
  }, [staffValid])

  const getForEdit = (el) => {
    if (title === "") {
      setFieldValue("idStaffAdress", el[0]);
      setFieldValue("idStaff", el[6]);
      setFieldValue("keyCity", el[11]);
      setFieldValue("street", el[5]);
      setFieldValue("intNumber", el[10]);
      setFieldValue("extNumber", el[7]);
      setFieldValue("subStreet", el[2]);
      setFieldValue("zipCode", el[3]);
      setFieldValue("latitude", el[8]);
      setFieldValue("longitude", el[9]);
      setFieldValue("keyCountry", el[14]);
      setFieldValue("keyState", el[13]);
      setFieldValue("keyCounty", el[12]);
      setZoom(15);
      setMarker({ lat: +el[8], lng: +el[9] });
      setCenter({ lat: +el[8], lng: +el[9] });
      setTitle("Editar dirección");
      setEditando(true);
      setIsOpen(true);
    } else {
      toast(
        <Notification
          type="warning"
          backMessage="Guarde sus cambios antes de continuar"
        />,
        { closeButton: false }
      );
    }
  };

  const editInfo = async (values) => {
    const params = {
      action: "saveStaffAdress",
      table: "staffadress",
      rows: values,
    };
    setLoading(true);
    const res = await peticionPersonal(params);
    if (res.status === 200 && res.data.code === 200) {
      toast(
        <Notification
          type={"modifica_exito"}
          backMessage={res.data.message}
          withIcon
        />
      );
      setParametros({ ...parametros });
      setTitle("");
      setCenter(centerMx);
      setZoom(15);
      resetForm();
      setIsOpen(false);
      if (staffValid !== null) {
        setActiveTab(8)
        const uppload = JSON.parse(JSON.stringify(upload));
        uppload.dom = true;
        setUpload(uppload)
      }
    } else {
      toast(
        <Notification
          type={"modifica_error"}
          backMessage={res.data.message}
          withIcon
        />,
        { closeButton: false }
      );
    }
    setLoading(false);
  };

  useEffect(() => {
    if (values.idStaffAdress) {
      getEstados(values.keyCountry, setSelectsData).finally(() => {
        getMunicipios(values.keyState, setSelectsData).finally(() => {
          getCiudades(values.keyCounty, setSelectsData);
        });
      });
    }
  }, [isOpen]);

  useEffect(() => {
    getPaises(setSelectsData);
    Geocode.setApiKey(gmap);
    Geocode.setLocationType("ROOFTOP");
    Geocode.setRegion("mx");
  }, []);

  const enviaDatos = () => {
    setFinalData((prevState) => ({
      ...prevState,
      idStaffAdress: values.idStaffAdress,
      idStaff: values.idStaff | idStaff,
      keyCity: values.keyCity,
      street: values.street,
      subStreet: values.subStreet,
      intNumber: values.intNumber,
      extNumber: values.extNumber,
      zipCode: values.zipCode,
      latitude: values.latitude,
      longitude: values.longitude,
    }));
    setModal(true);
  };

  const {
    handleSubmit,
    values,
    handleBlur,
    errors,
    touched,
    resetForm,
    setFieldValue,
    handleReset,
  } = useFormik({
    initialValues: form,
    onSubmit: (values) => {
      enviaDatos();
    },
    onReset: () => { },
    validationSchema: FormSchema,
    enableReinitialize: true,
  });

  const limpia = (reset) => {
    setTitle("");
    setCenter(centerMx);
    setMarker();
    setSelectsData((prevState) => ({
      ...prevState,
      estadosData: [],
      municipiosData: [],
      ciudadesData: [],
    }));
    reset();
    setEditando(false);
    setIsOpen(false);
  };

  const onChange = (e) => {
    if (e.target.name === "keyCountry") {
      setFieldValue("keyState", "");
      setFieldValue("keyCounty", "");
      setFieldValue("keyCity", "");
      getEstados(e.target.value, setSelectsData);
    } else if (e.target.name === "keyState") {
      setFieldValue("keyCounty", "");
      setFieldValue("keyCity", "");
      getMunicipios(e.target.value, setSelectsData);
    } else if (e.target.name === "keyCounty") {
      setFieldValue("keyCity", "");
      getCiudades(e.target.value, setSelectsData);
    }
    setFieldValue([e.target.name], e.target.value);
  };

  const searchLocation = async () => {
    if (
      values.keyState &&
      values.keyCounty &&
      values.keyCity &&
      values.subStreet &&
      values.street &&
      values.zipCode &&
      values.extNumber
    ) {
      const address =
        values.street +
        " " +
        values.extNumber +
        ", " +
        values.subStreet +
        ", " +
        values.zipCode +
        " " +
        findName(values.keyCity, "ciudades") +
        ", " +
        findName(values.keyCounty, "municipios") +
        ", " +
        findName(values.keyState, "estados");
      await Geocode.fromAddress(address).then(
        (response) => {
          if (response.results.length !== 0) {
            const location = response.results[0].geometry.location;
            setFieldValue("latitude", location.lat);
            setFieldValue("longitude", location.lng);
            setMarker(location);
            setCenter(location);
          } else {
            toast(
              <Notification
                type={"modifica_error"}
                backMessage="No se encontraron resultados"
                withIcon
              />,
              {
                closeButton: false,
              }
            );
          }
        },
        (error) => {
          toast(
            <Notification
              type={"modifica_error"}
              backMessage="No se encontraron ubicaciones"
              withIcon
            />,
            {
              closeButton: false,
            }
          );
        }
      );
    } else {
      toast(
        <Notification
          type={"warning"}
          backMessage="Selecione un país, estado, municipio, localidad, colonia y calle"
          withIcon
        />,
        { closeButton: false }
      );
    }
  };

  const findName = (id, hookPorBuscar) => {
    var registro = "";
    if (hookPorBuscar === "ciudades") {
      registro = selectsData.ciudadesData.find(
        (e) => e.keyCity === parseInt(id)
      );
      registro = registro.nameCity;
    } else if (hookPorBuscar === "municipios") {
      registro = selectsData.municipiosData.find(
        (e) => e.keyCounty === parseInt(id)
      );
      registro = registro.nameCounty;
    } else {
      registro = selectsData.estadosData.find(
        (e) => e.keyState === parseInt(id)
      );
      registro = registro.nameState;
    }
    return registro;
  };

  const onClick = (event) => {
    setZoom(15);
    const location = event.latLng.toJSON();
    setFieldValue("latitude", location.lat);
    setFieldValue("longitude", location.lng);
    setZoom(15);
    setMarker(location);
  };

  const renderMap = () => {
    return (
      showMap && (
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={center}
          zoom={zoom}
          onClick={onClick}
        >
          <Marker position={marker} />
        </GoogleMap>
      )
    );
  };

  /* Función para scrolear a un punto de la aplicación */
  const scrollTo = () => {
    refScroll.current.scrollIntoView({ behavior: "smooth" });
  };

  const { isLoaded, loadError } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: gmap,
  });

  return (
    <div>
      <TableComponentPersonalNoEdit
        titulo={"Domicilios"}
        cabecerasTabla={cabeceras}
        filtro={filtro}
        editar={getForEdit}
        parametros={parametros}
        limpia={limpia}
        reset={resetForm}
      />
      {permissions.INS && <div className="d-grid d-flex justify-content-center justify-content-sm-end">
        <Button color="add" onClick={openForm} disabled={title !== ""}>
          Agregar
        </Button>
      </div>}
      <Collapse
        isOpen={isOpen}
        onEntered={() => scrollTo()}
        onExited={() => setShowMap(false)}
      >
        <form onSubmit={handleSubmit} onReset={handleReset} className="mt-4" ref={refScroll}>
          <ModalConfirmation
            modalTitle={title}
            modal={modal}
            setModal={setModal}
            editar={editInfo}
            isEdit={true}
            values={finalData}
          >
            <div className="d-flex justify-content-center">
              <h6>¿Está seguro de guardar el registro?</h6>
            </div>
          </ModalConfirmation>
          <Col className="d-grid d-flex justify-content-sm-end justify-content-center mt-3">
            <h6 className="text-center">
              Estado:{" "}
              <span className="text-primary">
                {editando ? "Editando" : "Guardando"}
              </span>
            </h6>
          </Col>
          <Row>
            <Col xs="12" lg="6">
              <FormGroup>
                <SelectTypeHeadSingle
                  label="País"
                  isRequired={true}
                  inputName="keyCountry"
                  optionsArray={selectsData.paisesData}
                  defaultOption="Seleccionar un país"
                  onChangeMethod={onChange}
                  onBlur={handleBlur}
                  isDisabled={false}
                  value={values.keyCountry}
                  touched={touched.keyCountry}
                  errors={errors.keyCountry}
                  optionValue="keyCountry"
                  optionName="nameCountry"
                />
              </FormGroup>
            </Col>
            <Col xs="12" lg="6">
              <FormGroup>
                <SelectTypeHeadSingle
                  label="Estado"
                  isRequired={true}
                  inputName="keyState"
                  optionsArray={selectsData.estadosData}
                  defaultOption="Seleccionar un estado"
                  onChangeMethod={onChange}
                  onBlur={handleBlur}
                  isDisabled={
                    values.keyCountry !== "" &&
                      selectsData.estadosData.length > 0
                      ? false
                      : true
                  }
                  value={values.keyState}
                  touched={touched.keyState}
                  errors={errors.keyState}
                  optionValue="keyState"
                  optionName="nameState"
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col xs="12" lg="6">
              <FormGroup>
                <SelectTypeHeadSingle
                  label="Municipio"
                  isRequired={true}
                  inputName="keyCounty"
                  optionsArray={selectsData.municipiosData}
                  defaultOption="Seleccionar un municipio"
                  onChangeMethod={onChange}
                  onBlur={handleBlur}
                  isDisabled={
                    values.keyState !== "" &&
                      selectsData.municipiosData.length > 0
                      ? false
                      : true
                  }
                  value={values.keyCounty}
                  touched={touched.keyCounty}
                  errors={errors.keyCounty}
                  optionValue="keyCounty"
                  optionName="nameCounty"
                />
              </FormGroup>
            </Col>
            <Col xs="12" lg="6">
              <FormGroup>
                <SelectTypeHeadSingle
                  label="Localidad"
                  isRequired={true}
                  inputName="keyCity"
                  optionsArray={selectsData.ciudadesData}
                  defaultOption="Selecciona una ciudad"
                  onChangeMethod={onChange}
                  onBlur={handleBlur}
                  isDisabled={
                    values.keyState !== "" &&
                      selectsData.ciudadesData.length > 0
                      ? false
                      : true
                  }
                  value={values.keyCity}
                  touched={touched.keyCity}
                  errors={errors.keyCity}
                  optionValue="keyCity"
                  optionName="nameCity"
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col xs="12" lg="6">
              <FormGroup>
                <Label for="subStreet">
                  Colonia<span className="text-danger">*</span>
                </Label>
                <Input
                  name="subStreet"
                  invalid={errors.subStreet && touched.subStreet}
                  onChange={onChange}
                  onBlur={handleBlur}
                  value={values.subStreet}
                />
                <FormFeedback>{errors.subStreet}</FormFeedback>
              </FormGroup>
            </Col>
            <Col xs="12" lg="6">
              <FormGroup>
                <Label for="street">
                  Calle<span className="text-danger">*</span>
                </Label>
                <Input
                  name="street"
                  invalid={errors.street && touched.street}
                  onChange={onChange}
                  onBlur={handleBlur}
                  value={values.street}
                />
                <FormFeedback>{errors.street}</FormFeedback>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col xs="12" lg="4">
              <FormGroup>
                <Label for="extNumber">
                  Número exterior<span className="text-danger">*</span>
                </Label>
                <Input
                  name="extNumber"
                  type="text"
                  invalid={errors.extNumber && touched.extNumber}
                  onChange={onChange}
                  onBlur={handleBlur}
                  value={values.extNumber}
                />
                <FormFeedback>{errors.extNumber}</FormFeedback>
              </FormGroup>
            </Col>
            <Col xs="12" lg="4">
              <FormGroup>
                <Label for="intNumber">Número interior</Label>
                <Input
                  name="intNumber"
                  onChange={onChange}
                  onBlur={handleBlur}
                  value={values.intNumber}
                />
              </FormGroup>
            </Col>
            <Col xs="12" lg="4">
              <TextInput
                label="Código Postal"
                inputName="zipCode"
                onChangeMethod={onChange}
                onBlurMethod={handleBlur}
                value={values.zipCode}
                touched={touched.zipCode}
                errors={errors.zipCode}
                maxLength={5}
                isRequired
              />

              {/* <FormGroup>
                <Label htmlFor="zipCode">
                  Código postal<span className="text-danger">*</span>
                </Label>
                <Input
                  name="zipCode"
                  type="number"
                  invalid={errors.zipCode && touched.zipCode}
                  onChange={onChange}
                  onBlur={handleBlur}
                  value={values.zipCode}
                />
                <FormFeedback>{errors.zipCode}</FormFeedback>
              </FormGroup> */}
            </Col>
          </Row>
          <Row>
            <Col xs="12" lg="6">
              <FormGroup>
                <Input
                  name="latitude"
                  type="hidden"
                  value={values.latitude}
                  onChange={onChange}
                />
              </FormGroup>
            </Col>
            <Col xs="12" lg="6">
              <FormGroup>
                <Input
                  name="longitude"
                  type="hidden"
                  value={values.longitude}
                />
              </FormGroup>
            </Col>
          </Row>

          <div className="d-flex justify-content-center">
            <Button
              color="info"
              onClick={() => searchLocation()}
              className="position-relative"
            >
              <i
                className="eva eva-pin-outline"
                style={{ fontSize: "20px", verticalAlign: "top" }}
              />
              Identificar ubicación
            </Button>
          </div>
          <div className="d-flex justify-content-center mt-4">
            {loadError ? (
              <div>Ocurrio un error al cargar el mapa</div>
            ) : isLoaded ? (
              renderMap()
            ) : (
              <Loader />
            )}
          </div>
          <p className="label text-center mt-1">
            Para mayor precisión, seleccione País, Estado, Municipio, Localidad,
            Colonia, Calle, No. exterior y Código Postal
          </p>
          <div className="row mt-5">
            <div className="col-sm-6 order-2 order-sm-1 text-center text-sm-left mb-4">
              <Button
                color="danger"
                type="reset"
                onClick={() => limpia(resetForm)}
              >
                Cancelar
              </Button>
            </div>
            <div className="col-sm-6 order-1 order-sm-2 text-center text-sm-right mb-4">
              <Button color="success" type="submit">
                Guardar
              </Button>
            </div>
          </div>
        </form>
      </Collapse>
      <FullFormLoader show={loading} />
    </div>
  );
};

export default PersonalDomicilio;

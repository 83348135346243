import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Collapse,
  Row,
  Table,
} from "reactstrap";
import {
  SelectTypeHeadSingle,
  TextAreaInput,
} from "../../../components/GenericInputsFormik";
import { NumberInput } from "../../../components/GenericInputsFormik/NumberInput";
import { YearInput } from "../../../components/GenericInputsFormik/YearInput";
import FullFormLoader from "../../../components/Loader/FullFormLoader";
import Notification from "../../../components/Notification/Notification";
import Widget from "../../../components/Widget/Widget";
import { peticionEncript } from "../../../helpers/peticionesEncripted";
import { PERIOD } from "../../nomina/calculo/regex";
import { useLocation } from "react-router-dom";
import { saveAs } from "file-saver";
import ModalConfirmation from "../../../components/Modal/ModalConfirmation";
import st from "../pagos-transferencias-empleados/PagosTrasferenciasEmpleados.module.scss";
import { excelCheques } from "./excelCheques";
import { pdf } from "@react-pdf/renderer";
import pdfCheques from "./pdfCheques";
import ModalViewFile from "../../../components/Modal/ModalViewFile";
import ModalReports from "../../../components/Modal/ModalReports";
import chequePdf from "./chequePdf";
import classNames from "classnames";
import { GetPermissions } from "../../../libs/permissions/getPermissions";

const fmt = new Intl.NumberFormat("es-MX", {
  style: "currency",
  currency: "MXN",
});

const defaultFiltro = {
  period: "",
  year: new Date(),
};
const date = new Date().getFullYear();

const finanzas = "generacion-cheques";
const nomina = "generacioncheques";

async function doRequest(route, params, seeOk, encript, spec) {
  let info = [];
  const dataEncrypted = encript !== false ? peticionEncript(params) : params;
  await axios
    .post(process.env.REACT_APP_API + route, dataEncrypted)
    .then((res) => {
      if (res.status === 200 && res.data.status === "success") {
        info = res.data.data;
        if (seeOk) {
          toast(
            <Notification
              type={"elimina_exito"}
              backMessage={res.data.message}
              withIcon
            />,
            { closeButton: false }
          );
        }
        if (spec) info = res.data;
      } else {
        toast(
          <Notification
            type={"consultar_error"}
            backMessage={res.data.message}
            withIcon
          />,
          { closeButton: false }
        );
      }
    })
    .catch((err) => {
      toast(<Notification type={"consultar_servidor_error"} withIcon />, {
        closeButton: false,
      });
    });
  return info;
}

const GeneracionCheques = () => {
  const [permissions, setPermissions] = useState({});
  GetPermissions(setPermissions);

  const [activeCollapse, setActiveCollapse] = useState(1);
  const [loadingTab1, setLoadingTab1] = useState(false);
  const [loadingTab2, setLoadingTab2] = useState(false);
  const [filtro, setFiltro] = useState({ ...defaultFiltro }); /// Formulario apra seleccionar la solicitus de pago
  const [infoCheques, setInfoCheques] = useState({}); /// Informacion de la solicitud de pago
  const [archivosDispersion, setArchivosDispersion] = useState([]); /// Informacion de los layouts
  const [cuentasBancarias, setCuentasBancarias] = useState([]); /// Informacion de selects
  const [chequesSinAsignar, setChequesSinAsignar] = useState([]); /// Cheques sin asignar
  const [chequesAsignados, setChequesAsignados] = useState([]); /// Cheques asignados
  const [bancosAsignados, setBancosAsignados] = useState([]); /// Bancos asignados
  const [canSave, setCanSave] = useState(true); /// Para mostrar el botón de guardar pagos
  const [notesPagos, setNotesPagos] = useState(""); /// Observacion de los pagos
  const [modalSaveCheques, setModalSaveCheques] = useState(false); /// Modal para guardar todos los cheques
  const [alertOpen, setAlertOpen] = useState(false); /// Mostrar la alerta con multiples errores
  const [errorsArr, setErrorsArr] = useState([]); /// Arreglo con errores

  const location = useLocation();
  const route = location.pathname.split("/")[2];
  const alertRef = useRef(null);

  /* Cmabiar de tab automaticamente */
  useEffect(() => {
    if (activeCollapse === 1) {
      if (loadingTab1) setLoadingTab1(false);
    } else {
      if (loadingTab2) setLoadingTab2(false);
    }
  }, [activeCollapse]);

  const onChange = (e) => {
    if (e.target.name === "year") {
      if (e.target.value !== null)
        setFiltro((prevState) => ({ ...prevState, year: e.target.value }));
      else setFiltro((prevState) => ({ ...prevState, year: new Date() }));
    } else if (e.target.name === "period") {
      setFiltro((prevState) => ({ ...prevState, period: e.target.value }));
    }
  };

  /* Buscar solicitud de pago */
  const findSolicitudPago = async () => {
    setLoadingTab1(true);
    const params = {
      action: "multiselect",
      table:
        "payrollrequest A INNER JOIN payrollrequestnotes B ON B.idPayrollRequest = A.idPayrollRequest",
      rows: "A.idPayrollRequest AS id, GROUP_CONCAT(B.keyStat) AS statuses",
      conditions: ` A.period = ${
        filtro.period
      } AND A.year = ${filtro.year.getFullYear()} AND A.enabled = 1`,
      group: "id",
    };
    const data = await doRequest("receiver/receiver.php", params);
    if (data.length > 0) {
      const statuses = data[0].statuses.split(",");
      if (checkStat(statuses)) {
        await getCheques(data[0].id, statuses);
      } else {
        toast(
          <Notification
            type={"warning"}
            backMessage="No se ha concluido la generación de cheques de este periodo"
            withIcon
          />,
          { closeButton: false }
        );
      }
    }
    setLoadingTab1(false);
  };

  /* Buscar informacion de la solicitud de pago */
  const getCheques = async (id, statuses) => {
    const params = {
      action: "searchCheques",
      rows: { idPayrollRequest: id ? id : infoCheques.id },
    };
    const data = await doRequest(
      "app/facades/payslips/payrollrequestF.php",
      params
    );
    if (data.length > 0) {
      const bancosAsig = await getBancosAsignados(id);
      const arcDis = data[0].archivosDispersion.map((el, id) => {
        const nameFile = el.url.split("/");
        return {
          COL1: el.nameTypePayrollclerk,
          COL2: el.nameLayoutClass,
          COL3: nameFile[nameFile.length - 1],
          COL4: el.url,
        };
      });
      setArchivosDispersion(arcDis);
      setCuentasBancarias(data[0].cuentasBancariasCheques);
      setChequesSinAsignar(data[0].chequesSinAsignar);
      const cheAs = data[0].chequesAsignados.map((el) => {
        let cuenta = undefined;
        if (bancosAsig.length > 0)
          cuenta = bancosAsig.find((b) => b.cheques.includes(el.idCheck));
        return {
          idCheck: el.idCheck,
          keyTypeCheck: el.keyTypeCheck,
          monto: el.monto,
          nombreEmpleado: el.nombreEmpleado,
          staffNumber: el.staffNumber,
          numberCheck: el.numberCheck,
          banco: cuenta ? cuenta.banco : "",
          idCheckBook: cuenta ? cuenta.idCB : "",
          nameTypeCheck: el.nameTypeCheck
        };
      });
      setChequesAsignados(cheAs);
      setInfoCheques({
        id: data[0].idPayrollRequest,
        statuses: statuses? statuses : infoCheques.statuses
      });
      setCanSave(data[0].chequesSinAsignar.length === 0);
      setLoadingTab2(false);
      setActiveCollapse(2);
    }else{
      setActiveCollapse(1);
      setLoadingTab1(false);
    }
  };

  /* Buscar  bancos asignados a cheques */
  const getBancosAsignados = async (id) => {
    const idPag = id ? id : infoCheques.id;
    const params = {
      action: "multiselect",
      table:
        "bankcheks A INNER JOIN checkbooks B  USING (idCheckBook) INNER JOIN bankaccounts C USING (idBankAccount) INNER JOIN banks D USING (keyBank)",
      rows: "A.idCheckBook, D.nameBank, C.swiftCode, C.account, GROUP_CONCAT(A.idCheck) AS bancos",
      conditions: `A.idCheckBook IS NOT NULL AND A.idPayrollRequest = ${idPag}`,
      group: "A.idCheckBook",
    };
    const banks = await doRequest("receiver/receiver.php", params, false, true);
    let banClr = [];
    if (banks.length > 0) {
      banClr = banks.map((el) => {
        const cheq = el.bancos.split(",");
        return {
          banco: el.nameBank,
          clave: el.swiftCode,
          cuenta: el.account,
          cheques: cheq,
          idCB: el.idCheckBook,
        };
      });
      setBancosAsignados(banClr);
    }
    return banClr;
  };

  /* Descargar el layout */
  const downloadLayout = (el) => {
    saveAs(el.COL4, el.COL3);
  };

  /* Revisar historia de status de la solicitud */
  const checkStat = (statuss) => {
    if (route === nomina) return statuss.includes("78");
    return true;
  };

  const showButton = () => {
    if (route === finanzas) {
      if (infoCheques.statuses) return !infoCheques.statuses.includes("78");
    }
    return false;
  };

  /* Descargar excel/pdf */
  const generateFile = async (ty) => {
    if (chequesAsignados.length > 0 && bancosAsignados.length > 0) {
      setLoadingTab2(true);
      const empelados = clasificaCheques(["1", "3"]);
      const pensiones = clasificaCheques(["2", "4"]);
      try {
        if (ty === 1) {
          await excelCheques(
            empelados,
            pensiones,
            `${filtro.period} DEL AÑO ${filtro.year.getFullYear()}`
          );
        } else if (ty === 2) {
          let blobPdf = await pdf(
            await pdfCheques(empelados, pensiones, filtro)
          ).toBlob();
          if (blobPdf != undefined && typeof blobPdf === "object") {
            saveAs(blobPdf, "RELACIÓN DE CHEQUES.pdf");
          }
        }
      } catch (error) {
        toast(
          <Notification
            type={"warning"}
            backMessage="No fue posible generar el archivo"
            withIcon
          />,
          { closeButton: false }
        );
      }
      setLoadingTab2(false);
    } else {
      toast(
        <Notification
          type={"warning"}
          backMessage="No se han generado cheques"
          withIcon
        />,
        { closeButton: false }
      );
    }
  };

  /* Clsificar los cheques por banco y tipo */
  const clasificaCheques = (arrFlt) => {
    let data = bancosAsignados.map((b) => {
      const cheques = chequesAsignados.filter(
        (c) => c.idCheckBook === b.idCB && arrFlt.includes(c.keyTypeCheck)
      );
      return {
        banco: b.banco,
        clave: b.clave,
        cuenta: b.cuenta,
        cheques: cheques,
      };
    });
    data = data.filter((el) => el.cheques.length > 0);
    return data;
  };

  /* Función limpair la info al cerrar */
  const clearData = () => {
    setCuentasBancarias([]);
    setChequesSinAsignar([]);
    setChequesAsignados([]);
    setInfoCheques({});
    setErrorsArr([]);
    setAlertOpen(false);
    setNotesPagos("");
    setCanSave(true);
  };

  /* Guardar cheques de empelados y pensiones */
  const saveCheques = async () => {
    setLoadingTab2(true);
    const params = {
      action: "finishPagosCheques",
      rows: { idPayrollRequest: infoCheques.id, notes: notesPagos },
    };
    const data = await doRequest(
      "app/facades/payslips/payrollrequestF.php",
      params,
      true,
      true
    );
    if (data.length > 0) findSolicitudPago();
    else {
      setErrorsArr(data.message);
      setAlertOpen(true);
      scrollTo();
      setLoadingTab2(false);
    }
  };

  /* Función para scrolear a un punto de la aplicación */
  const scrollTo = () => {
    setTimeout(
      () => alertRef.current.scrollIntoView({ behavior: "smooth" }),
      200
    );
  };

  return (
    <Widget className="widget-p-md">
      <div className="mb-4 d-flex justify-content-between flex-column flex-md-row text-center text-md-left">
        <h4>Generación de Cheques Empleados y Pensiones</h4>

        {Object.keys(infoCheques).length > 0 && (
          <div className="mt-2 mt-md-0">
            <Button
              className="btn text-light"
              style={{ backgroundColor: "#ba262f", border: "none" }}
              onClick={() => setActiveCollapse(1)}
            >
              Cerrar
              <i className="fa fa-sign-out ml-2" style={{ fontSize: "16px" }} />
            </Button>
          </div>
        )}
      </div>

      {/* Form filtro */}
      <Collapse isOpen={activeCollapse === 1}>
        <Row>
          <Col xs={12} lg={4}>
            <SelectTypeHeadSingle
              label="Periodo"
              isRequired={true}
              inputName="period"
              optionsArray={PERIOD}
              defaultOption="Seleccione un periodo"
              onChangeMethod={onChange}
              value={filtro.period}
              optionValue="value"
              optionName="label"
            />
          </Col>
          <Col xs={12} lg={4}>
            <YearInput
              label="Año"
              inputName="year"
              onChangeMethod={onChange}
              value={filtro.year}
              isMin={date - 1}
              isRequired
            />
          </Col>
          <Col xs={12} lg={2}>
            <div className="pt-lg-4 mt-lg-2 text-center">
              <Button
                color="secondary"
                onClick={() => findSolicitudPago()}
                disabled={filtro.period === "" || filtro.year === ""}
              >
                Consultar
              </Button>
            </div>
          </Col>
        </Row>
        <FullFormLoader show={loadingTab1} />
      </Collapse>

      {/* Content */}
      <Collapse
        isOpen={activeCollapse === 2}
        onExited={clearData}
        className="border-top"
      >
        <div className="mt-4 pt-3">
          <Card className="mb-4 border border-secondary">
            <CardHeader
              className="text-center bg-secondary text-white"
              tag="h6"
            >
              Layout Dispersión
            </CardHeader>
            <CardBody>
              <Table responsive className="table-striped table-borderless">
                <thead
                  className={classNames([
                    "text-center",
                    st.thBB,
                    "border-secondary",
                  ])}
                >
                  <tr>
                    <th className="py-2">NO.</th>
                    <th className="py-2">Tipo Dispersión</th>
                    <th className="py-2">Tipo Layout</th>
                    <th className="py-2">Archivo</th>
                  </tr>
                </thead>
                <tbody>
                  {archivosDispersion.length > 0 ? (
                    archivosDispersion.map((el, id) => (
                      <tr
                        key={"rl" + id}
                        className={classNames([st.rowHover, st.tableText])}
                      >
                        <td className="text-center">{id + 1}</td>
                        <td>{el.COL1}</td>
                        <td>{el.COL2}</td>
                        <td>
                          <a
                            className={st.hoverLink}
                            onClick={() => {
                              downloadLayout(el);
                            }}
                          >
                            {el.COL3}
                          </a>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr
                      key="nothing"
                      className={classNames([
                        st.rowHover,
                        st.tableText,
                        "text-center",
                      ])}
                    >
                      <td colSpan={4}>No existen layouts</td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </CardBody>
          </Card>
        </div>

        <div className="mt-3">
          <Alert
            isOpen={alertOpen}
            toggle={() => setAlertOpen(!alertOpen)}
            color="danger"
            style={{
              backgroundColor: "#f6435550",
              borderLeft: "4px solid #FF4B23",
              color: "#f64355",
            }}
          >
            {errorsArr.map((el, id) => (
              <li key={id}>{el}</li>
            ))}
          </Alert>
        </div>

        <TableCheque
          tt="Cheques por Generar"
          data={chequesSinAsignar}
          cuentas={cuentasBancarias}
          setLoading={setLoadingTab2}
          getCheques={getCheques}
          type={1}
          permissions={permissions}
          route={route}
        />

        <TableCheque
          tt="Cheques Generados"
          data={chequesAsignados}
          cuentas={cuentasBancarias}
          setLoading={setLoadingTab2}
          getCheques={getCheques}
          type={2}
          generateFile={generateFile}
          filtro={filtro}
          permissions={permissions}
          route={route}
        />

        {showButton() && permissions.INS && (
          <div className="text-center">
            <div className="mb-5 mx-0 mx-lg-5 mt-4">
              <TextAreaInput
                label="Observaciones"
                inputName="notesPagos"
                inputRows={6}
                onChangeMethod={(e) => {
                  setNotesPagos(e.target.value);
                }}
                value={notesPagos}
                isDisabled={!canSave}
              />
            </div>
            <Button color="success" onClick={() => setModalSaveCheques(true)} disabled={!canSave}>
              Guardar Cheques
            </Button>
          </div>
        )}

        <FullFormLoader show={loadingTab2} />
      </Collapse>

      <ModalConfirmation
        modal={modalSaveCheques}
        setModal={setModalSaveCheques}
        editar={() => saveCheques()}
        isEdit={true}
      >
        <div className="d-flex justify-content-center">
          <div className="text-center">
            <p style={{ fontSize: "22px" }} className="mb-2">
              ¿Está seguro de guardar los cheques de para el periodo{" "}
              <span style={{ fontWeight: "bold" }}>
                {filtro.period}/{filtro.year.getFullYear()}
              </span>
              ?
            </p>
            <p className="mt-3">Esta acción no se podrá deshacer</p>
          </div>
        </div>
      </ModalConfirmation>
    </Widget>
  );
};

export default GeneracionCheques;

const TableCheque = ({
  tt,
  data,
  cuentas,
  setLoading,
  getCheques,
  type,
  generateFile,
  filtro,
  permissions,
  route
}) => {
  function showAction(){
    if(route === nomina) return permissions.CON;
    else if(route === finanzas) return permissions.INS;
  }
  return (
    <div className="mt-5 mb-3 pt-2">
      <div className="text-center">
        <h4>{tt}</h4>
      </div>

      {type === 2 && (
        <div className="mt-4 d-flex justify-content-end  flex-wrap">
          <Button
            color="primary"
            className="px-3 my-2"
            onClick={() => generateFile(1)}
          >
            Descargar Excel
            <i className="fa fa-file-excel-o ml-2" />
          </Button>
          <Button
            color="secondary"
            className="px-3 ml-2 my-2"
            onClick={() => generateFile(2)}
          >
            Descargar Comprobantes
            <i className="fa fa-file-pdf-o ml-2" />
          </Button>
        </div>
      )}

      <Table responsive className="table-striped table-borderless mt-2">
        <thead
          className={classNames(["text-center", st.thBB, "border-secondary"])}
        >
          <tr>
            <th className="px-2 py-2">#</th>
            <th className="px-2 py-2">No. Empleado/<br />Pension</th>
            <th className="px-2 py-2">Nombre</th>
            <th className="px-2 py-2">Tipo</th>
            <th className="px-2 py-2">Monto</th>
            <th className="px-2 py-2" style={{ minWidth: "280px" }}>
              Cuenta
            </th>
            <th className="px-2 py-2" style={{ minWidth: "80px" }}>
              Número
              <p />
              Cheque
            </th>
            {showAction() && <th className="px-2 py-2">{type === 1 ? "Guardar" : "Ver"}</th>}
          </tr>
        </thead>
        <tbody>
          {data.length > 0 ? (
            data.map((el, id) => (
              <TableRow
                key={"tr" + type + id}
                el={el}
                id={id}
                cuentas={cuentas}
                setLoading={setLoading}
                getCheques={getCheques}
                type={type}
                filtro={filtro}
                permissions={permissions}
              />
            ))
          ) : (
            <tr
              key="nothing"
              className={classNames([st.rowHover, st.tableText, "text-center"])}
            >
              <td colSpan={7}>
                {type === 1
                  ? "No hay cheques por generar"
                  : "No hay cheques generados"}
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </div>
  );
};

const TableRow = ({
  el,
  id,
  cuentas,
  setLoading,
  getCheques,
  type,
  filtro,
  permissions
}) => {
  const [modalConfirm, setModalConfirm] = useState(false);
  const [idCheckBook, setIdCheckBook] = useState("");
  const [numberCheck, setNumberCheck] = useState("");
  const [textAccount, settextAccount] = useState("");
  const [infoReport, setInfoReport] = useState({});
  const [modalFile, setModalFile] = useState(false);
  const selectRef = useRef(null);

  const onChange = (e) => {
    if (e.target.name === "idCheckBook") {
      setIdCheckBook(e.target.value);
    } else if (e.target.name === "numberCheck") {
      setNumberCheck(e.target.value);
    }
  };

  /* Guardar un cheque */
  const saveCheque = async () => {
    setLoading(true);
    const params = {
      action: "insertCheques",
      rows: {
        idCheckBook: idCheckBook,
        idCheck: el.idCheck,
        numberCheck: numberCheck,
      },
    };
    const data = await doRequest(
      "app/facades/payslips/payrollrequestF.php",
      params,
      true,
      true
    );
    if (data.length > 0) await getCheques();
    else setLoading(false);
  };

  /* Para validar la info al guardar */
  const handleSubmit = () => {
    let msg = "";

    if (idCheckBook === "" && numberCheck === "")
      msg = "Ingrese en número de cheque y selecione una cuenta";
    else if (numberCheck === "") msg = "Ingrese en número de cheque";
    else if (idCheckBook === "") msg = "Selecione una cuenta";
    if (msg === "") {
      settextAccount(selectRef.current.state.text);
      setModalConfirm(true);
    } else {
      toast(
        <Notification
          type={"consultar_error"}
          backMessage={`${msg} para ${el.nombreEmpleado}`}
          withIcon
        />,
        { closeButton: false }
      );
    }
  };

  const viewCheque = async () => {
    setLoading(true);
    const params = {
      action: "numbertotext",
      number: el.monto.replaceAll(",", ""),
    };
    const data = await doRequest(
      "app/facades/tools/toolsF.php",
      params,
      false,
      true,
      true
    );
    let info = {
      nombre: el.nombreEmpleado,
      cantidad: "$" + el.monto,
      msg: `PAGO DE NÓMINA DEL PERIODO ${
        filtro.period
      } DEL AÑO ${filtro.year.getFullYear()}`,
      letra: "",
    };
    setLoading(false);
    if (data.code === "200") {
      info.letra = data.number;
      setInfoReport([info]);
      setModalFile(true);
    } else {
      console.error("No se convirtio la cantidad a texto");
    }
  };
  
  return (
    <>
      <tr className={classNames([st.rowHover, st.tableText])}>
        <td className="px-2 text-center">{id + 1}</td>
        <td className="px-2 text-center">{el.staffNumber}</td>
        <td className="px-2">{el.nombreEmpleado}</td>
        <td className="px-2 text-center">{el.nameTypeCheck}</td>
        <td className="px-2 text-right">${el.monto}</td>
        <td className={"px-2 text-center " + (type === 1 && "py-0 pt-3")}>
          {type === 1 ? (
            <SelectTypeHeadSingle
              inputName={"idCheckBook"}
              optionsArray={cuentas}
              defaultOption="Seleccione una cuenta"
              onChangeMethod={onChange}
              value={idCheckBook}
              optionValue="idCheckBook"
              optionName="cuenta"
              isFixed
              refSelect={selectRef}
              isDisabled={!permissions.INS}
            />
          ) : (
            <p>{el.banco}</p>
          )}
        </td>
        <td
          className={"px-2 text-center " + (type === 1 && "py-0 pt-3")}
          style={{ maxWidth: "100px" }}
        >
          {type === 1 ? (
            <NumberInput
              inputName="numberCheck"
              onChangeMethod={onChange}
              value={numberCheck}
              isMin={0}
              disableSep
              maxLength={4}
              isDisabled={!permissions.INS}
            />
          ) : (
            <p>{el.numberCheck}</p>
          )}
        </td>
        <td className="px-2 text-center" id={"tt-" + el.COL8}>
          {type === 1 && permissions.INS ? (
            <Button
              outline
              color="success"
              className="px-3"
              type="button"
              onClick={() => handleSubmit()}
            >
              <i className="fa fa-floppy-o" />
            </Button>
          ) : (type === 2 &&
            <Button
              outline
              color="info"
              className="px-3"
              type="button"
              onClick={() => viewCheque()}
            >
              <i className="fa fa-eye" />
            </Button>
          )}
        </td>
      </tr>

      <ModalConfirmation
        modal={modalConfirm}
        setModal={setModalConfirm}
        editar={() => saveCheque()}
        isEdit={true}
        buttonOkMsg="Continuar"
      >
        <div className="d-flex justify-content-center">
          <div className="text-center">
            <p style={{ fontSize: "22px" }} className="mb-2">
              ¿Está seguro de guardar el cheque:
              <br />
              <span style={{ fontWeight: "bold" }}>
                {el.staffNumber} - {el.nombreEmpleado}
              </span>
              <br /> con la cuenta
              <br />
              <span style={{ fontWeight: "bold" }}>{textAccount}</span>?
            </p>
            <p className="mt-3">Esta acción no se podrá deshacer</p>
          </div>
        </div>
      </ModalConfirmation>

      <ModalReports
        modal={modalFile}
        setModal={setModalFile}
        title={"Cheque"}
        backdrop
        keyboard
        report={infoReport}
        pdfConfig={{ a: "" }}
        generatePdfMethod={{
          method: chequePdf,
          type: "cheque",
        }}
      />
    </>
  );
};

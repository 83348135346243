import React, { useEffect, useState } from "react"
import { Container } from "reactstrap"
import InputEmployee from "./Inputs/InputEmployee"
import TableComponent from "../../../components/TableComponent/TableComponentVer";
import Form from "./Form/Form";
import Widget from "../../../components/Widget/Widget";
import ModalReports from "../../../components/Modal/ModalReports";
import exportPDF, { cabecerasPDF, configPDF } from "./PDF/exportPDF";
import { peticionesReceiver } from "../../../helpers/peticionesReceiver";
import { toast } from "react-toastify";
import Notification from "../../../components/Notification/Notification";
import { differenceInYears } from "date-fns"

export default function ContratoHonorarios() {
  const API = peticionesReceiver();
  const [data, setData] = useState();
  const [title, setTitle] = useState("Alta contrato");
  const [isOpenForm, setIsOpenForm] = useState(false);
  const [employee, setEmployee] = useState(null);
  const [employeeRep, setEmployeeRep] = useState();
  const [report, setReport] = useState();
  const [modalReports, setModalReports] = useState(false);
  const [paramsModalTable] = useState({
    action: "datatable",
    table:
      "staff STF INNER JOIN employtypes AS EMT ON STF.keyEmployType = EMT.keyEmployType INNER JOIN jobstaff AS JBS ON JBS.idStaff = STF.idStaff INNER JOIN areas AS AR ON AR.idArea = JBS.idArea INNER JOIN jobtypes AS JBT ON JBT.keyJobType = STF.keyEmployType",
    rows: "STF.idStaff,STF.staffNumber,CONCAT(STF.name,' ',STF.pName,' ',STF.mName) as fullName,AR.name,JBT.nameJobType",
    conditions: `STF.enabled = 1 AND JBS.valid = 1 AND STF.keyStat = 1 AND JBS.enabled = 1 AND EMT.keyEmployType=2`,
    page: 0,
    records: 5,
    search: "",
    order: "",
  });

  const cabeceras = [
    "FOLIO",
    "ADSCRIPCION",
    "PUESTO",
    "MOVIMIENTO",
    "VIGENTE",
    "FECHA INICIO",
    "FECHA FIN",
    "CONTRATO",
    "VER"
  ]

  const [parametros, setParametros] = useState({
    action: "datatable",
    table: "contractFees CF INNER JOIN types_move TM on CF.keyMove=TM.keyMove INNER JOIN areas A on CF.idArea=A.idArea INNER JOIN jobs J on CF.keyJob=J.keyJob INNER JOIN staff SF ON CF.idStaff=SF.idStaff",
    rows: "CF.idContractFees,A.name,J.nameJob,TM.nameMove,CF.valid,CF.startDate,CF.endDate",
    conditions: `CF.enabled = 1`,
    page: 0,
    records: 5,
    search: "",
    order: "",
  });

  const onSelectEmployee = (value) => {
    setEmployee(value);
    const idEmploye = value[0];
    setParametros({
      action: "datatable",
      table: "contractFees CF INNER JOIN types_move TM on CF.keyMove=TM.keyMove INNER JOIN areas A on CF.idArea=A.idArea INNER JOIN jobs J on CF.keyJob=J.keyJob INNER JOIN staff SF ON CF.idStaff=SF.idStaff",
      rows: "CF.idContractFees,A.name,J.nameJob,TM.nameMove,CF.valid,CF.startDate,CF.endDate",
      conditions: `CF.enabled = 1 AND SF.idStaff = ${idEmploye}`,
      page: 0,
      records: 5,
      search: "",
      order: "",
    })
  }

  const getForEdit = async (el) => {
    setTitle("Editar oficio")
    if (!employee) {
      const params = {
        action: "multiselect",
        table: 'contractFees CF',
        conditions: `CF.idContractFees=${el[0]}`,
        rows: "CF.idStaff"
      };
      const res = await API.peticion(params)
      if (res.status === 200 && res.data.code === "200") {
        const data = res.data.data[0];
        setEmployeeRep(Object.values(data));
        setIsOpenForm(true);
        setData(el);
      } else {
        toast(<Notification type={"consultar_error"} withIcon />);
      }
    } else {
      setData(el);
    }
  }

  const genReport = async (el) => {
    const params = {
      action: "getDatosHonorarios",
      rows: { idContractFees: el[0] }
    }
    const res = await API.peticionEndPoint(params, "app/facades/jobs/jobsF.php")
    if (res.status === 200) {
      const data = { empleado: res.data.data[0] };
      if (data.empleado.zipCode !== "" && data.empleado.subStreet !== "" && data.empleado.street !== "" && data.empleado.extNumber !== "" && data.empleado.nameCity !== "" && data.empleado.nameState !== "" && data.empleado.nameCountry !== "") {
        data.empleado.direccion = { zipCode: data.empleado.zipCode, subStreet: data.empleado.subStreet, fullCalle: `${data.empleado.street} ${data.empleado.extNumber}`, nameCity: data.empleado.nameCity, nameState: data.empleado.nameState, nameCountry: data.empleado.nameCountry }
      } else {
        data.empleado.direccion = null
      }
      data.empleado.bornDate = differenceInYears(new Date(), new Date(data.empleado.bornDate));
      if ((Number(data.empleado.salaryMonth) > 0)) {
        const currency = await API.peticionEndPoint({
          action: "numbertotext",
          number: data.empleado.salaryMonth
        }, "app/facades/tools/toolsF.php")
        if (currency.status === 200 && currency.data.code === "200") {
          data.empleado.spellNumber = currency.data.number.toUpperCase();
        }
      } else {
        data.empleado.spellNumber = "CERO"
      }
      setReport(data)
      setModalReports(true)
    } else {
      toast(<Notification type={"consultar_error"} withIcon />);
    }
  }

  useEffect(() => {
    if (employee){
      setEmployeeRep(null)
    }
  }, [employee])

  useEffect(() => {
    if (employeeRep){
      setEmployee(null)
    }
  }, [employeeRep])

  return (
    <Widget className="widget-p-md">
      <InputEmployee
        employee={employee}
        setEmployee={setEmployee}
        paramsModalTable={paramsModalTable}
        selectEmployee={onSelectEmployee}
        setParametros={setParametros}
        setIsOpenForm={setIsOpenForm}
        setData={setData}
        title={"Seleccionar empleado"}
        parametros={{
          action: "datatable",
          table: "contractFees CF INNER JOIN types_move TM on CF.keyMove=TM.keyMove INNER JOIN areas A on CF.idArea=A.idArea INNER JOIN jobs J on CF.keyJob=J.keyJob INNER JOIN staff SF ON CF.idStaff=SF.idStaff",
          rows: "CF.idContractFees,A.name,J.nameJob,TM.nameMove,CF.valid,CF.startDate,CF.endDate",
          conditions: `CF.enabled = 1`,
          page: 0,
          records: 5,
          search: "",
          order: "",
        }}
      />
      <Container>
        <TableComponent
          titulo="Historico de contratos"
          cabecerasTabla={cabeceras}
          filtro={null}
          parametros={parametros}
          ver={getForEdit}
          disabledDelete
          special="CONTRATO"
          specialIco={"fa-file-pdf-o"}
          specialFun={(d) => genReport(d)}
        />
      </Container>
      <Container>
        <Form
          title={title}
          setTitle={setTitle}
          employee={employee ? employee : employeeRep}
          setEmployee={employee ? setEmployee : setEmployeeRep}
          noEmp={employee ? false : true}
          data={data}
          setData={setData}
          setParametros={setParametros}
          isOpen={isOpenForm}
          setIsOpen={setIsOpenForm}
          genReport={genReport}
        />
      </Container>
      {report !== undefined ? (
        <ModalReports
          modal={modalReports}
          setModal={setModalReports}
          title={`CONTRATO DE PRESTACIÓN DE SERVICIOS MEDIANTE EL PAGO DE
          SERVICIOS PROFESIONALES POR HONORARIOS`}
          cabeceras={cabecerasPDF}
          backdrop={"static"}
          keyboard={false}
          report={report}
          pdfConfig={configPDF}
          generatePdfMethod={{
            method: exportPDF,
            type: "PDFHonorarios",
          }}
          records={1000}
        />
      ) : null}
    </Widget>
  )
}
import { Document, Page, StyleSheet, Text, View, Image } from "@react-pdf/renderer";
import HeaderReport from "../../../components/Pdf/Header";
import { fDate, fMoney } from "../../../libs/tools/format";

const s = StyleSheet.create({
    Text: { paddingVertical: "4px" },
    body: {
        paddingVertical: 20,
        paddingHorizontal: 20,
    },

    header: {
        backgroundColor: "#BEBEBE",
        textAlign: "center"
    },

    marginB1: { marginBottom: "1px" },
    textP: { paddingVertical: "3px", paddingHorizontal: "2px" },
    textBold: { fontFamily: "Helvetica-Bold" },

    vCenter: { alignItems: "center", justifyContent: "center" },
    vCenterL: { alignItems: "left", justifyContent: "center" },
    vCenterBtw: { alignItems: "stretch", justifyContent: "center" },

    row: { flexDirection: "row" },
    flexWrap: { display: "flex", flexWrap: "wrap", flexDirection: "row" },

    w8: { width: "8%" },
    w10: { width: "10%" },
    w11: { width: "11%" },
    w13: { width: "13%" },
    w14: { width: "14%" },
    w15: { width: "15%" },
    w16: { width: "16%" },
    w20: { width: "20%" },
    w25: { width: "25%" },
    w26: { width: "26%" },
    w29: { width: "29%" },
    w30: { width: "30%" },
    w39: { width: "39%" },
    w40: { width: "40%" },
    w44: { width: "44%" },
    w45: { width: "45%" },
    w50: { width: "50%" },
    w55: { width: "55%" },
    w58: { width: "58%" },
    w60: { width: "60%" },
    w65: { width: "65%" },
    w70: { width: "70%" },
    w80: { width: "80%" },
    w86: { width: "86%" },
    w87: { width: "87%" },
    w90: { width: "90%" },
    wAuto: { width: "auto" },

    border: { border: "1px solid #5D6D7E" },
    borderT: { borderBottom: "1px solid #5D6D7E" },
    borderB: { borderBottom: "1px solid #5D6D7E" },
    borderE: { borderRight: "1px solid #5D6D7E" },
    borderS: { borderLeft: "1px solid #5D6D7E" },
    borderD: { border: "1px solid #5D6D7E", borderTop: "0px" },
    borderU: { border: "1px solid #5D6D7E", borderBottom: "0px" },

    bg: { backgroundColor: "#BEBEBE" },
    bg2: { backgroundColor: "#BEBEBE" },

    fs8: { fontSize: "8px" },
    fs9: { fontSize: "9px" },
    fs10: { fontSize: "10px" },
    fs11: { fontSize: "11px" },

    tend: { textAlign: "right", paddingRight: "2px" },
    tstart: { textAlign: "left" },
    tcenter: { textAlign: "center" },
    tBold: { fontFamily: "Helvetica-Bold" },
    mCell: { padding: "1px" },
});


export default function pdfEstatusEmpleado(staff, status, notes) {
    return (
        <Document>
            <Page style={[s.body]}>
                <HeaderReport />
                <View style={[s.fs10, {marginTop: "15px"}]}>
                    <View style={[s.row, { marginBottom: "15px" }]}>
                        <View style={[s.w60]}>
                            <View style={[s.fs11, { marginBottom: "10px" }]}>
                                <Text style={[s.tBold]}>{staff.name}</Text>
                            </View>
                            <View style={[{ marginBottom: "15px" }]}>
                                <Text style={[s.tBold, s.mCell]}>PUESTO: {staff.nameJob||"SIN ASIGNAR"}</Text>
                                <Text style={[s.mCell]}> <Text style={[s.tBold]}>PLAZA: </Text>{staff.numberJobPositions||"SIN ASIGNAR"}</Text>
                                <Text style={[s.mCell]}> <Text style={[s.tBold]}>NÚMERO DE SERVIDOR PÚBLICO: </Text>{staff.staffNumber}</Text>
                                <Text style={[s.mCell]}> <Text style={[s.tBold]}>ADSCRIPCIÓN ACTUAL: </Text>{staff.nameArea}</Text>
                            </View>
                            <View style={[{ marginBottom: "20px" }]}>
                                <Text style={[s.mCell]}><Text style={[s.tBold]}>FECHA DE INGRESO:</Text> {fDate(staff.startJobDate)}</Text>
                                <Text style={[s.mCell]}><Text style={[s.tBold]}>RFC:</Text> {staff.rfc}</Text>
                                <Text style={[s.mCell]}><Text style={[s.tBold]}>EDAD:</Text> {staff.age}</Text>
                                <Text style={[s.mCell]}><Text style={[s.tBold]}>NO. DE GAFETE:</Text> *********</Text>
                            </View>
                        </View>
                        <View style={[s.w40]}>
                            <View style={[s.w50, {marginHorizontal: "auto"}]}>
                                <Image source={{ uri: staff.src }} />
                            </View>
                        </View>
                    </View>
                    <View style={[s.border, s.w40]}>
                        <View style={[s.row, s.tBold, s.header, s.borderB]}>
                            <View style={[s.w50, s.borderE]}>
                                <Text style={[s.mCell]}>SUELDO BRUTO</Text>
                            </View>
                            <View style={[s.w50]}>
                                <Text style={[s.mCell]}>SUELDO NETO</Text>
                            </View>
                        </View>
                        <View style={[s.row, s.tcenter]}>
                            <View style={[s.w50, s.borderE]}>
                                <Text style={[s.mCell]}>{fMoney(staff.net||"SIN ASIGNAR")}</Text>
                            </View>
                            <View style={[s.w50]}>
                                <Text style={[s.mCell]}>{fMoney(staff.gross||"SIN ASIGNAR")}</Text>
                            </View>
                        </View>
                    </View>
                    <View style={[{ marginVertical: "10px" }]}>
                        <Text style={[s.tBold]}>DOMICILIO REGISTRADO EN PLANTILLA:</Text>
                    </View>
                    <View style={[s.border, { marginBottom: "20px" }]}>
                        <View style={[s.row, s.tBold, s.header, s.borderB]}>
                            <View style={[s.w90, s.borderE]}>
                                <Text style={[s.mCell]}>Domicilio</Text>
                            </View>
                            <View style={[s.w10, s.tcenter]}>
                                <Text style={[s.mCell]}>C.P.</Text>
                            </View>
                        </View>
                        <View style={[s.row]}>
                            <View style={[s.w90, s.borderE]}>
                                <Text style={[s.mCell]}>{staff.domicilio}</Text>
                            </View>
                            <View style={[s.w10]}>
                                <Text style={[s.mCell]}>{staff.zipCode}</Text>
                            </View>
                        </View>
                    </View>
                    <View style={[{ marginVertical: "10px" }]}>
                        <Text style={[s.tBold]}>TELÉFONOS DE CONTACTO REGISTRADOS EN PLANTILLA:</Text>
                    </View>
                    <View style={[s.border, s.w45]}>
                        <View style={[s.row, s.tBold, s.header, s.borderB]}>
                            <View style={[s.w50, s.borderE]}>
                                <Text style={[s.mCell]}>TELÉFONO</Text>
                            </View>
                            <View style={[s.w50]}>
                                <Text style={[s.mCell]}>TELÉFONO PERSONAL</Text>
                            </View>
                        </View>
                        <View style={[s.row, s.tcenter]}>
                            <View style={[s.w50, s.borderE]}>
                                <Text style={[s.mCell]}>{staff.jobNumber}</Text>
                            </View>
                            <View style={[s.w50]}>
                                <Text style={[s.mCell]}>{staff.telefonoPersonal}</Text>
                            </View>
                        </View>
                    </View>
                    <View style={[{ marginTop: "15px" }]}>
                        <Text style={[s.tBold, { textDecoration: "underline" }]}>NOTAS:</Text>
                        <Text style={[{ marginTop: "5px" }]}>{notes}</Text>
                    </View>
                </View>
            </Page>
        </Document>
    );
}

import { Document, Page, Text, View, StyleSheet } from "@react-pdf/renderer";
import { styles } from "../../../../components/Pdf/pdfGeneral/EstiloPdf";
import HeaderReport from "../../../../components/Pdf/Header";
import { sumValues } from "../../../../libs/tools/arrays";

    /**
     * Estilo que se repite en todas los headers de la tabla
     */
    const styleHeadForAll = {
        borderStyle: "solid",
        borderWidth: 1,
        backgroundColor: "#646464",
        color: 'white',
        textAlign: 'center',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: 10
    }
    
    const styleCellForAll = {
        borderStyle: "solid",
        borderWidth: 1,
        borderTopWidth: 0,
        fontSize: 11
    }
    
    /**
     * Estilos del pdf
     */
const s = StyleSheet.create({
    subTitle: { //SUBTITULOS
        width: "100%",
        textAlign: 'center',
        marginBottom: 10,
        marginLeft: 10,
        fontSize: 14
    },
    f6: {
        fontSize: 6
    },
    f5: {
        fontSize: 5
    },
    f8: {
        fontSize: 8
    },
    f10: {
        fontSize: 10
    },
    thIn: {
        height: 20,
        ...styleHeadForAll
    },
    th: {
        height: 20,
        ...styleHeadForAll,
        borderLeftWidth: 0
    },
    w10: {
        width: "10%"
    },
    w20: {
        width: "20%"
    },
    w18: {
        width: "18%"
    },
    w25: {
        width: "25%"
    },
    w30: {
        width: "30%"
    },
    w33: {
        width: "33%"
    },
    w34: {
        width: "34%"
    },
    w40: {
        width: "40%"
    },
    w50: {
        width: "50%"
    },
    w60: {
        width: "60%"
    },
    w70: {
        width: "70%"
    },
    w90: {
        width: "90%"
    },
    w100: {
        width: "100%"
    },
    mt2: {
        marginTop: 40
    },
    px2: {
        paddingHorizontal: 10
    },
    px1: {
        paddingHorizontal: 5
    },
    bt0: {
        borderTopWidth: 0
    },
    center: {
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
    },
    textBold: {
        fontFamily: 'Helvetica-Bold'
    },
    textRight: {
        textAlign: "right"
    },
    tdIn: { //Cell CC
        ...styleCellForAll
    },
    td: { //Cell Concepto
        ...styleCellForAll,
        borderLeftWidth: 0
    },
    red: {
        backgroundColor: "red",
    },
    green: {
        backgroundColor: "green",
    },
    gray: {
        backgroundColor: "gray",
    }
})


const formatter = new Intl.NumberFormat("es-MX", {
    style: "currency",
    currency: "MXN",
});



export default function exportContributionsIssemym(data, model, title, fileNo) {
    const ori = model.orientation === "h" ? "landscape" : "portrait";
    const values = Object.values(data).map(value => value[0]);
    
    const totalSS = sumValues(values, "SS");
    const totalFSSR = sumValues(values, "FSSR");
    const totalAOSCI = sumValues(values, "AOSCI");
    const totalGA = sumValues(values, "GA");
    const totalRT = sumValues(values, "RT");
    const totales = ["TOTALES", totalSS, totalFSSR, totalAOSCI, totalGA, totalRT]

    return {
        report: (
            <>
                <Document>
                    <Page style={s.body} orientation={ori}>
                        <HeaderReport/>
                        <View>
                            <Text style={s.subTitle}>{title}</Text>
                            <Text style={s.subTitle}>{`Quincena ${model.descr.period} del Año ${model.descr.year}`}</Text>
                        </View>
                        <View style={styles.table}>
                            <View style={styles.tableRow} fixed>
                                {model.headers.map((header, index) => (
                                    <View style={[index === 0 ? s.thIn : s.th, index === 0 ? s.w10 : s.w18]}>
                                        <Text>{header}</Text>
                                    </View>
                                ))}
                            </View>
                            {values.map(row => (
                                <View style={styles.tableRow} fixed>
                                    {model.content.map((value, index) => (
                                        <View style={[index === 0 ? s.tdIn : s.td, index === 0 ? s.w10 : s.w18]}>
                                            <Text style={index !== 0 ? [s.textRight, s.px1] : s.center}>{index === 0 ? row[value.value] : formatter.format(row[value.value])}</Text>
                                        </View>
                                    ))}
                                </View>
                            ))}
                            <View style={styles.tableRow} fixed>
                                {totales.map((value, index) => (
                                    <View style={[index === 0 ? s.tdIn : s.td, index === 0 ? s.w10 : s.w18]}>
                                        <Text style={[s.textRight, s.px1, s.textBold]}>{index === 0 ? value : formatter.format(value)}</Text>
                                    </View>
                                ))}
                            </View>
                        </View>
                        
                    </Page>
                </Document>
            </>
        )
    };
}
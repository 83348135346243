import { NumberInput } from "../../../../components/GenericInputsFormik/NumberInput";

export default function CantidadProveedor({
  idDetail,
  indexRecepcion,
  arrayRecepcionCompras,
  setArrayRecepcionCompras,
}) {
  

  const onChange = (e) => {
    const { name, value } = e.target;
    let tmpList = arrayRecepcionCompras.slice(0, arrayRecepcionCompras.length);
    tmpList[indexRecepcion].diasPaga = value;
    setArrayRecepcionCompras(tmpList);
  };

  return (
    <div>
      <NumberInput
        inputName={`quantity${idDetail}`}
        id={`quantity${idDetail}`}
        onChangeMethod={onChange}
        value={arrayRecepcionCompras[indexRecepcion].diasPaga}
        isMin={1}
        disableSep={true}
        decimals={false}
      />
    </div>
  );
}

import { useEffect, useState } from "react";
import { doRequest } from "../../../../../helpers/requests";
import { Button, Table } from "reactstrap";
import { QRImageComponet, generatedQrImage } from "../../../../../libs/reports/fump/createFump";
import { fMoney } from "../../../../../libs/tools/format";
import FullFormLoader from "../../../../../components/Loader/FullFormLoader";
import ModalReports from "../../../../../components/Modal/ModalReports";
import { generatePdfPercepcionesDeducciones2 } from "../../../comprobantes-pago/comprobantes/percepcionesDeducciones/pdf/PdfPercepcionesDeducciones";


export default function EmisionComprobante({finiquito, permissions}){
    const [loading, setLoading] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [data, setData] = useState([]);
    const [totales, setTotales] = useState([0, 0]);
    const [pdfConfig, setPdfConfig] = useState({});
    const [modalReports, setModalReports] = useState(false);

    /* TABLE */
    const columns = [
        { data: "under" },
        { data: "nameUnder" },
        { data: null, render: (row) => amountCol(row, 1) },
        { data: null, render: (row) => amountCol(row, 2) },
    ];

    function amountCol(row, type){
        return +row.keyTypeUnder === type ? fMoney(row.amount) : "-"
    }
    /* END TABLE */

    async function calculate() {
        const params = {
            action: "multiselect",
            table: "paysettlements A INNER JOIN unders B USING(idUnder)",
            rows: "B.under,B.nameUnder, A.amount, B.keyTypeUnder",
            conditions: `A.idStaff=${finiquito.idStaff} AND A.idJobStaff = ${finiquito.idJobStaff} AND A.enabled = 1`,
        }
        setLoading(true);
        const res = await doRequest("receiver/receiver.php", params, false);
        if (res.length > 0) {
            let totales = [0, 0, 0]
            res.forEach((row) => {
                const ix = +row.keyTypeUnder !== 1;
                totales[+ix] = totales[+ix] + +row.amount;
                totales[2] = totales[2] += ((ix ? -1 : 1 ) * row.amount);
                row.idStaff = finiquito.idStaff;
            });
            setTotales(totales);
            setData(res);
        }
        setLoading(false);
    }

    useEffect(() => {
        setRefresh(true);
    }, [data]);

    function showReport(){
        setPdfConfig({
            period: "****",
            fechaP: "****",
            pngUrl: generatedQrImage(),
        });
        setModalReports(true);
    }


    if(!permissions.CON){
        return(
            <div className="my-5 py-5 text-center">
                <h5>No tiene permisos para consultar este apartado</h5>
            </div>
        )
    }

    return(
        <div>
            <div className="text-center my-3">
                <Button color="info" onClick={() => calculate()}>Generar</Button>
            </div>
            {
                data.length > 0 && 
                <>
                    <div className="text-right my-1">
                        <Button color="danger" onClick={()=>showReport()}>
                            <i className="fa fa-file-pdf-o navIco mr-2" />
                            Generar PDF
                        </Button>
                    </div>
                    <Table striped hover responsive>
                        <thead className="border-bottom border-secondary">
                            <tr className="text-center">
                                <th>Clave</th>
                                <th>Concepto</th>
                                <th>Percepciones</th>
                                <th>Deducciones</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                data.map((row, ix) => (
                                    <tr key={"r"+ix}>
                                        <td>{row.under}</td>
                                        <td>{row.nameUnder}</td>
                                        <td className="text-right">{amountCol(row, 1)}</td>
                                        <td className="text-right">{amountCol(row, 2)}</td>
                                    </tr>
                                ))
                            }
                            <tr style={{ borderTop: "2px solid gray"}}>
                                <td className="text-center font-weight-bold" colSpan={2}>Total</td>
                                <td className=" text-right">{fMoney(totales[0])}</td>
                                <td className=" text-right">{fMoney(totales[1])}</td>
                            </tr>
                            <tr>
                                <td className="text-center font-weight-bold" colSpan={3}>Total Neto</td>
                                <td className=" text-center">{fMoney(totales[2])}</td>
                            </tr>
                        </tbody>
                    </Table>
                </>
            }
            <ModalReports
                modal={modalReports}
                setModal={setModalReports}
                title="REPORTE DE PAGO FINIQUITO"
                backdrop={"static"}
                keyboard={false}
                report={data}
                pdfConfig={pdfConfig}
                generatePdfMethod={{
                    method: generatePdfPercepcionesDeducciones2,
                    type: "percepcionesDeducciones",
                }}
                records={1000}
            />

            <QRImageComponet />
            <FullFormLoader show={loading} />
        </div>
    )
}
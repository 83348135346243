import { useState } from "react";
import { NavItem, Nav, NavLink, Button } from "reactstrap";

import Widget from "../../../../components/Widget/Widget";
import s from "../../../personal/expediente-personal/nav.module.scss";
import CarouselInmuebles from "../forms/Carousel";
import DatosInmueble from "../forms/DatosInmueble";
import Documents from "../forms/Documents";
import { getDataReport } from "../functions";
import ModalReports from "../../../../components/Modal/ModalReports";
import { toast } from "react-toastify";
import Notification from "../../../../components/Notification/Notification";
import exportFicha from "../reporte/pdf";
import { useHistory } from "react-router";




export default function TabsInmueble({ data }) {
    const history = useHistory()
    const [activeTab, setActiveTab] = useState(1)
    const [report, setReport] = useState()
    const [modalReports, setModalReports] = useState(false);

    const regresa = () => {
        localStorage.removeItem("propertyData");
        history.push("/template/bienesinmuebles")
    }
    //

    const genReport = async () => {
        const reporte = await getDataReport(data);
        if (reporte) {
            if (reporte.length > 0) {
                setReport(reporte)
                setModalReports(true)
            } else {
                toast(<Notification type={"consultar_error"} withIcon backMessage={"No se encuentran datos disponibles"} />);
            }
        } else {
            toast(<Notification type={"consultar_error"} withIcon />);
        }
    }

    return (
        <Widget>
            <div className="text-right mb-3">
                <Button onClick={genReport}>Generar reporte</Button>
            </div>
            <Nav fill tabs style={{ overflowY: "hidden", overflowX: "auto" }}>
                <NavItem active={activeTab === 1}>
                    <NavLink
                        href="#"
                        active={activeTab === 1}
                        onClick={() => setActiveTab(1)}
                    >
                        <i className={s.navIco + " fa fa-building"} />
                        Datos del inmueble
                    </NavLink>
                </NavItem>
                <NavItem active={activeTab === 2} >
                    <NavLink
                        href="#"
                        active={activeTab === 2}
                        onClick={() => setActiveTab(2)}
                        disabled={!data}
                    >
                        <i className={s.navIco + " fa fa-picture-o"} />
                        Galeria de fotos
                    </NavLink>
                </NavItem>
                <NavItem active={activeTab === 3}>
                    <NavLink
                        href="#"
                        active={activeTab === 3}
                        onClick={() => setActiveTab(3)}
                        disabled={!data}
                    >
                        <i className={s.navIco + " fa fa-files-o"} />
                        Documentación
                    </NavLink>
                </NavItem>
            </Nav>
            <div className="mt-4">
                {activeTab === 1 ?
                    <DatosInmueble data={data} />
                    : activeTab === 2 ?
                        <CarouselInmuebles data={data} />
                        : <Documents data={data} />
                }
            </div>
            <div className="text-center mt-2">
                <Button color="danger" onClick={regresa}>Cancelar</Button>
            </div>
            <ModalReports
                modal={modalReports}
                setModal={setModalReports}
                title={"REPORTE GENERAL DEL INMUEBLE"}
                cabeceras={""}
                backdrop={"static"}
                keyboard={false}
                report={report}
                pdfConfig={{}}
                generatePdfMethod={
                    {
                        method: exportFicha,
                        type: "general"
                    }
                }
                records={1000}
            />
        </Widget>
    )
}
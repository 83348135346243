import * as XlsxPopulate from "xlsx-populate";
import { saveAs } from "file-saver";
import { number2Alphabet } from "../../../../libs/tools/strings";
import { sumValues } from "../../../../libs/tools/arrays";

/**
 * Formateador de numeros de concurrencias
 */
const formatter = new Intl.NumberFormat("es-MX", {
  style: "currency",
  currency: "MXN",
});

/**
 * Hoja de estilos del excel
 */
const styles = {
  title: {
    horizontalAlignment: 'center', bold: true
  },
  headerBorder: {
    bold: "true",
    border: true,
    fill: "BFBFBF",
  },
  header: {
    bold: "true",
    border: true,
    fill: "BFBFBF",
  },
  headerBoderBold: {
    bold: "true",
    border: true,
    fill: "BFBFBF",
  },
  contentBorder: {
    border: true,
  },
  contentBorderB: {
    border: true,
    bold: true
  },
  contentBorderRigth: {
    border: true,
    horizontalAlignment: 'right'
  },
  contentBorderRigthB: {
    border: true,
    horizontalAlignment: 'right',
    bold: true
  },
};

/**
 * 
 * @param {Reporte entregado de la BD} report 
 * @param {Titulo (Nombre de la institución)} title 
 * @param {Parametros pasados a la BD para su consulta} data 
 * @param {configuración de headers} config 
 */
export async function saveGeneralReport(report, title, data, config) {


  //Inicio de excel
  XlsxPopulate.fromBlankAsync().then(async (workbook) => {
    const sheet1 = workbook.sheet(0);

    const hl = config.headers.length;

    //Titulo
    sheet1
      .range(`A1:${number2Alphabet(hl - 1)}2`)
      .merged(true)
      .value("SAPP")
      .style(styles.title);
    //Subtitulos
    sheet1
      .range(`A3:${number2Alphabet(hl - 1)}3`)
      .merged(true)
      .value(title)
      .style(styles.title);

    const dataReport = report.map(data => {
      const temp = data;
      temp.contact = `CORREO INSTITUCIONAL: ${data.jobMail}\nCORREO: ${data.email}\nTELEFONO: ${data.phone} ${data.phoneExtension !== "" ? `EXT: ${data.phoneExtension}` : ''}`
      temp.job = `${data.nameJob} ${data.level}-${data.rango}`
      const haveDom = data.street !== "" || data.extNumber !== "" || data.intNumber !== "" || data.nameCity !== "" || data.nameCounty !== "" || data.nameCountry !== "" || data.zipCode !== "";
      if (!haveDom) {
        temp.address = "SIN INFORMACIÓN"
      } else {
        temp.address = `${data.street !== "" ? `CALLE: ${data.street}` : ''}${data.intNumber !== "" ? `\nINT. ${data.intNumber}` : ''}${data.extNumber !== "" ? `\nEXT. ${data.extNumber}, ` : ''}${data.nameCity !== "" ? `\n${data.nameCity}` : ''}${data.nameCounty !== "" ? `\n${data.nameCounty}, ` : ''}${data.nameCountry !== "" ? `\n${data.nameCountry}` : ''}${data.zipCode !== "" ? `\nC.P.: ${data.zipCode}` : ''}`
      }
      return temp
    })

    //Cabeceras
    config.headers.forEach((header, index) => {
      sheet1
        .cell(`${number2Alphabet(index)}6`)
        .value(header)
        .style(styles.headerBoderBold)
    });

    if (dataReport.length > 3000) {
      //Data
      dataReport.forEach((value, index) => {
        config.content.forEach((key, ind) => {
          sheet1
            .cell(`${number2Alphabet(ind)}${index + 7}`)
            .value(key.index ? (index + 1) : !key.format ? value[key.value] : formatter.format(value[key.value]))
          sheet1.row(index + 7).height(74)
        })
      })
    } else {
      //Data
      dataReport.forEach((value, index) => {
        config.content.forEach((key, ind) => {
          sheet1
            .cell(`${number2Alphabet(ind)}${index + 7}`)
            .value(key.index ? (index + 1) : !key.format ? value[key.value] : formatter.format(value[key.value]))
            .style(!key.format ? styles.contentBorder : styles.contentBorderRigth);
          sheet1.row(index + 7).height(74)
        })
      })
    }


    config.sizes.forEach((size, index) => {
      sheet1.column(`${number2Alphabet(index)}`).width(size);
    })

    return workbook.outputAsync().then((res) => {
      saveAs(res, `${title}.xlsx`);
    });
  });
}
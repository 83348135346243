import React from "react";

import Widget from "../../../../components/Widget/Widget";
import FormDistrito from "../FormDistrito";
import { updateDistrict } from "../functions";

export const EditarDistrito = (props) => {
  return (
    <Widget className="widget-p-md">
        <h3>Editar distrito</h3>
        <FormDistrito data={localStorage.getItem('dataContent').split('|-|')} editar={updateDistrict}/>
    </Widget>
  )
}

export default EditarDistrito;
import React, { useState } from "react";
import TableComponent from "../../../components/TableComponent/TableComponentFiltro";

export const Modulos = () => {
  const [cabeceras] = useState([
    "Id",               ///A.idModule
    "Nombre del módulo",///A.nameModule
    "Ruta de archivo",  ///A.fileRoute
    "Módulo padre",     ///A.pModule
    "Nivel",            ///A.level
    "Sistema",          ///ifnull(B.nameModule,'raiz') as BnameModule
    "Editar",           
    "Eliminar",         
  ]);
  const [filtro] = useState([true, true, true, true, true, true]);
  const [parametros] = useState({
    action: "datatable",
    table: "modules A LEFT JOIN modules B on (A.pModule = B.idModule and B.enabled = 1) INNER JOIN apparatus C on A.keySystem = C.keySystem",
    rows: "A.idModule,A.nameModule,A.fileRoute,ifnull(B.nameModule,'RAIZ') as BnameModule,A.level,C.nameSystem,C.keySystem,A.pModule",
    conditions: "A.enabled = 1",
    page:0,
    records:5,
    search:"",
    order:""
  });

  return (
    <div>
        <TableComponent
          titulo="Módulos"
          cabecerasTabla={cabeceras}
          filtro={filtro}
          rutaCrear="/template/modulos/crear"
          rutaEditar="/template/modulos/editar"
          parametros={parametros}
        ></TableComponent>
    </div>
  );
};
export default Modulos;

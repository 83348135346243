export function validarRFC(RFC) {
    if (!RFC) {
        return false;
    }
    const rfc = RFC.trim().replaceAll("\\s{2,}", " ").toUpperCase();
    if (rfc.length > 11 && rfc.length < 14) {
        if (RFCValido(rfc)) {
            return true;
        } else {
            return false;
        }
    }
    return false;
}
  
function RFCValido(rfc, aceptarGenerico = true) {
    const re = /^([A-ZÑ&]{3,4}) ?(?:- ?)?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])) ?(?:- ?)?([A-Z\d]{2})([A\d])$/;
    const validado = rfc.match(re);
    if (!validado) {
        return false;
    }
    //Separar el dígito verificador del resto del RFC
    const digitoVerificador = validado.pop(),
        rfcSinDigito = validado.slice(1).join(''),
        len = rfcSinDigito.length,
        diccionario = "0123456789ABCDEFGHIJKLMN&OPQRSTUVWXYZ Ñ",
        indice = len + 1;
    let suma, digitoEsperado;
    suma = (len == 12) ? 0 : 481; //Si es fisica o moral
    for (let i = 0; i < len; i++){
        suma += diccionario.indexOf(rfcSinDigito.charAt(i)) * (indice - i);
    }
    digitoEsperado = 11 - suma % 11;
    if (digitoEsperado == 11) {
        digitoEsperado = 0;   
    } else if (digitoEsperado == 10) {
        digitoEsperado = "A";  
    }
    //El dígito verificador coincide con el esperado?
    // o es un RFC Genérico (ventas a público general)?
    if ((digitoVerificador != digitoEsperado) && (!aceptarGenerico || rfcSinDigito + digitoVerificador != "XAXX010101000")) {
        return false;
    } else if (!aceptarGenerico && rfcSinDigito + digitoVerificador == "XEXX010101000") {
        return false;
    } else {
        return true;    
    }
}
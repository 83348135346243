import React, { useState, useEffect } from "react";
import ModalComponentEliminar from "../../../../components/Modal/ModalDelete";
import Loader from "../../../../components/Loader/Loader";
import axios from "axios";
import {
  Col,
  Row,
  Table,
  Input,
  Pagination,
  PaginationItem,
  PaginationLink,
  Button,
} from "reactstrap";
import Widget from "../../../../components/Widget/Widget.js";
import s from "../../../../pages/tables/Tables.module.scss";
import { useHistory } from "react-router-dom";
import sc from "./TableComponentFiltro.module.scss";
import { toast } from "react-toastify";
import Notification from "../../../../components/Notification/Notification";
import { peticionEncript } from "../../../../helpers/peticionesEncripted";
import getActions from "../../../../helpers/getPermissionForms";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { formatCell } from "./cellsFormat";

import { Progress } from "reactstrap";
import getRouters from "../../../../components/Layout/function";

export const TableProgressBar = (props) => {
  const {
    titulo,
    cabecerasTabla,
    filtro,
    disabledDelete,
    parametros, //si en parametros no viene un nombreTable , no funcionara el elimina a menos que le envies tu propio elimina
    routePeticion, //ruta en caso de hacer una peticion a un enpoint especial
    //edit
    rutaEditar,
    addEdit,
    actionEdit,
    //crear
    addNew,
    rutaCrear,
    actionNew,
    //delete
    addDelete,
    elimina,
    //watch
    addWatch,
    actionWatch,
    rutaWatch,
    //columnEspecial
    specialColumns, //no funciona para componentes
  } = props;

  const colsBdNames = parametros.rows.split(","); // Columnas del back
  const nameTable = parametros.table ? parametros.table.split(" ") : "";
  const nameColumn = colsBdNames[0].split(".");
  const deleteRow = disabledDelete === true ? true : false;
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [tableContent, setTableContent] = useState([]); // Información mostrada en la tabla
  const [pageSize, setPageSize] = useState(5); // Tamaño de las paginas
  const [totalRegistros, setTotalRegistros] = useState(0); // Total de registros en BD
  const [pagesCount, setPagesCount] = useState(0); // Cantidad total de paginas
  const [seccionActual, setSeccionActual] = useState(0); // Seccion actual
  const [sortCol, setSortCol] = useState({
    //Parametros para aplicar filtros a la busqueda
    direction: "",
    column: "",
    page: "",
    currentPage: 0,
    search: "",
    records: 5,
  });

  const [permissionsForm, setPermissionsForm] = useState("");

  const settableContentPage = (e, index) => {
    if (index + 1 > (seccionActual + 1) * 5) {
      setSeccionActual(seccionActual + 1);
    } else if (seccionActual != 0 && index + 1 <= seccionActual * 5) {
      setSeccionActual(seccionActual - 1);
    }
    e.preventDefault();
    setSortCol((prevState) => ({
      ...prevState,
      page: pageSize * index,
      currentPage: index,
    }));
  };

  function handleSubmit(dataContent, type, route) {
    if (route) {
      history.push({
        pathname: route,
      });
      localStorage.setItem("dataContent", dataContent.join("|-|"));
    } else {
      if (type === "watch") {
        history.push({
          pathname: rutaWatch,
        });
        localStorage.setItem("dataContent", dataContent.join("|-|"));
      } else {
        if (dataContent === undefined) {
          history.push({
            pathname: rutaCrear,
          });
        } else {
          history.push({
            pathname: rutaEditar,
          });
          localStorage.setItem("dataContent", dataContent.join("|-|"));
        }
      }
    }
  }

  const mapea = (element, indexCol, indexRow) => {
    return formatCell(
      element,
      indexCol,
      indexRow,
      filtro[indexCol],
      sortCol,
      cabecerasTabla[indexCol]
    );
  };

  const sortTable = (cabecera, indexHeader) => {
    if (
      !["Editar", "Eliminar", "Id", "Ver", "Seleccionar", "Modificar"].includes(
        cabecera
      )
    ) {
      if (sortCol.column !== indexHeader) {
        setSortCol((ps) => ({ ...ps, column: indexHeader, direction: "asc" }));
      } else {
        setSortCol((ps) => ({
          ...ps,
          direction:
            sortCol.direction === "asc"
              ? "desc"
              : sortCol.direction === "desc"
              ? ""
              : "asc",
        }));
      }
    }
  };

  const getData = async (orderOb) => {
    //LA PRIMERA VEZ QUE ENTRAMOS VIENE UN ORDEROB = EMPTY Y EN EL PARAMS = ORDER = "", PAGE = 0 Y RECORDS = 5
    //CUANDO SELECCIONO EL HEADER VIENE ORDEROB = COLUMN = 1 Y DIRECTION = ASC Y EN EL PARAMS = ORDER = "ASC", PAGE = 0 Y RECORDS = 5
    //DESPUES DE ESO SELECCIONO EL PAGINADO Y MUESTRA ORDEROB = COLUMN = "", DIRECTION = "" Y PAGE = 5 Y EN EL PARAMS = ORDER = "", PAGE = 5 Y RECORDS = 5
    //SOLO HAY QUE VERIFICAR EL ESTADO DEL HOOK Y QUE NO CAMBIE LOS PARAMS
    var params = parametros;
    if (sortCol.column !== "" && sortCol.direction !== "") {
      //AQUI QUIERE DECIR QUE TRAE UN ORDENAMIENTO POR HEADER
      params.order = orderOb.column + " " + orderOb.direction;
    } else {
      params.order = "";
    }
    if (sortCol.page !== "") {
      //QUIERE DECIR QUE CAMBIAMOS DE PAGINA
      params.page = sortCol.page;
    } else {
      params.page = 0;
    }
    if (sortCol.search !== "") {
      params.search = sortCol.search;
    } else {
      params.search = "";
    }
    if (orderOb) {
    } else {
      if (sortCol.column !== "" && sortCol.direction !== "") {
        //AQUI TRAE UN ORDENAMIENTO POR HEADER
        params.order = orderOb.column + " " + orderOb.direction;
      } else {
        params.order = "";
      }
      if (sortCol.page !== "") {
        //CAMBIAMOS DE PAGINA
        params.page = sortCol.page;
      } else {
        params.page = 0;
      }
      if (sortCol.search !== "") {
        params.search = sortCol.search;
      } else {
        params.search = "";
      }
    }
    params.records = sortCol.records;
    setLoading(true);
 
    const finalData = peticionEncript(params);
    let route = routePeticion ? routePeticion : "receiver/receiver.php";
    await axios
      .post(`${process.env.REACT_APP_API}${route}`, finalData)
      .then((res) => {
        let data = [];
        if (res.status === 200 && res.data.code === "200") {
          setTotalRegistros(res.data.totalRecords);
          setPagesCount(Math.ceil(res.data.totalRecords / pageSize));
          res.data.data.map((el) => {
            data.push(Object.values(el));
          });
        }
        setTableContent(data);
        setLoading(false);
      })
      .catch((err) => {
        toast(<Notification type={"consultar_servidor_error"} withIcon />);
        setLoading(false);
      });
  };

  const loc = useLocation();
  const user = useSelector((state) => state.userInfo);
  const { id, profileType } = useSelector((state) => state.userInfo);
  const getActionsUser = async () => {
    const pathname = loc.pathname.split("/")[2];
    if (pathname !== "dashboard") {
      const route = await getRouters(user.permission, pathname);
      if (!route.permission) {
        history.push("/template");
      } else {
        getPermissionsUser();
      }
    }
  };

  const getPermissionsUser = async () => {
    const pathname = loc.pathname.split("/")[2];
    const result = await getActions(
      id,
      profileType.selectProfile.keyProfile,
      pathname
    );
    setPermissionsForm(result.actions);
  };

  useEffect(() => {
    getData(sortCol);
  }, [sortCol, parametros]);

  useEffect(() => {
    getActionsUser();
  }, []);

  const handleOnChange = (e) => {
    setSeccionActual(0);
    setSortCol((prevState) => ({
      ...prevState,
      search: e.target.value,
      currentPage: 0,
      page: "",
    }));
  };

  const handleOnChangeSelect = (e) => {
    setSeccionActual(0);
    setPageSize(e.target.value);
    setSortCol((prevState) => ({
      ...prevState,
      records: e.target.value,
      currentPage: 0,
      page: "",
    }));
  };

  const namesWatch = ["ver", "seleccionar"];
  const isForWatch = (header) => {
    return namesWatch.includes(header);
  };

  return (
    <div>
      <Widget className="widget-p-md">
        <Row className="mb-4">
          <Col>
            <div className={s.tableTitle}>
              <h1 className="text-secondary text-center">{titulo} </h1>
            </div>
            <div className="d-flex flex-wrap justify-content-between">
              {permissionsForm.CON === "1" ? (
                <Input
                  value={sortCol.search}
                  name="search"
                  onChange={handleOnChange}
                  className="col-12 col-sm-6 order-last order-sm-first"
                  placeholder="Buscar..."
                ></Input>
              ) : (
                <div className="col-12 col-sm-6 order-first order-sm-last mb-3 mt-sm-0 text-center text-sm-right"></div>
              )}
              {permissionsForm.INS === "1" && addNew && (
                <div className="col-12 col-sm-6 order-first order-sm-last mb-3 mt-sm-0 text-center text-sm-right">
                  <Button
                    color="add"
                    className="btn-rounded with-icon"
                    onClick={() => {
                      actionNew ? actionNew() : handleSubmit();
                    }}
                  >
                    Agregar
                  </Button>
                </div>
              )}
            </div>
            <div className="widget-table-overflow mt-3">
              <Table
                className={`table-striped table-borderless table-hover ${s.statesTable}`}
                responsive
              >
                {permissionsForm.CON === "1" && (
                  <thead className="border-bottom border-secondary">
                    <tr className={sc.userSelectNone}>
                      <>
                        {cabecerasTabla.map((cabecera, indexHeaders) => {
                          if (cabecera.toLowerCase() === "editar") {
                            if (permissionsForm.UPD === "1" && addEdit) {
                              return (
                                <th key={cabecera} className="text-center">
                                  {cabecera}
                                </th>
                              );
                            }
                          } else if (cabecera.toLowerCase() === "eliminar") {
                            if (permissionsForm.DEL === "1" && addDelete) {
                              return (
                                <th key={cabecera} className="text-center">
                                  {cabecera}
                                </th>
                              );
                            }
                          } else if (isForWatch(cabecera.toLowerCase())) {
                            if (addWatch) {
                              return (
                                <th key={cabecera} className="text-center">
                                  {cabecera}
                                </th>
                              );
                            }
                          } else if (cabecera.toLowerCase() === "registrar avance") {
                            return permissionsForm.INS === "1" && (
                              <th key={cabecera} className="text-center">
                                {cabecera}
                              </th>
                            );
                          } else {
                            return (
                              <th
                                key={cabecera}
                                className="text-center"
                                onClick={() =>
                                  sortTable(cabecera, indexHeaders + 1)
                                }
                              >
                                {cabecera === "Id" ? "#" : cabecera}
                                <i
                                  className={
                                    "align-top eva" +
                                    (sortCol.column === indexHeaders + 1
                                      ? sortCol.direction === "asc"
                                        ? " eva-arrow-ios-upward-outline"
                                        : sortCol.direction === "desc"
                                        ? " eva-arrow-ios-downward-outline"
                                        : ""
                                      : "")
                                  }
                                ></i>
                              </th>
                            );
                          }
                        })}
                      </>
                    </tr>
                  </thead>
                )}
                <tbody>
                  {loading ? (
                    <tr key={"loading"}>
                      <td colSpan={cabecerasTabla.length}>
                        <Loader />
                      </td>
                    </tr>
                  ) : tableContent.length === 0 ? (
                    <tr key={"nothing"}>
                      <td
                        colSpan={cabecerasTabla.length}
                        className="text-center"
                      >
                        No se encontraron registros
                      </td>
                    </tr>
                  ) : permissionsForm.CON !== "1" ? (
                    <tr key={"nothing"}>
                      <td
                        colSpan={cabecerasTabla.length}
                        className="text-center"
                      >
                        No tienes permitido ver los registros de esta seccion
                      </td>
                    </tr>
                  ) : (
                    tableContent.map((registro, index) => (
                      <tr key={index}>
                        {registro.map((elemento, indexCol) => {
                          if (indexCol === 5) {
                            return (
                              <td className="text-center" key={indexCol}>
                                <Progress color="success" value={elemento} />
                                {`${elemento}%`}
                              </td>
                            );
                          } else {
                            if (filtro !== null) {
                              return mapea(elemento, indexCol, index);
                            } else {
                              return (
                                <td className="text-center">{elemento}</td>
                              );
                            }
                          }
                        })}

                        {
                          // * Columna editar * //
                          rutaEditar !== null && (
                            <>
                              {permissionsForm.UPD === "1" && addEdit && (
                                <td
                                  onClick={() =>
                                    actionEdit
                                      ? actionEdit(registro)
                                      : handleSubmit(registro, "editar")
                                  }
                                  className=" text-center"
                                >
                                  <i
                                    className={
                                      sc.handCursor +
                                      " fa fa-pencil text-warning"
                                    }
                                  />
                                </td>
                              )}
                            </>
                          )
                        }

                        {
                          // * Columna eliminar * //
                          deleteRow === false && (
                            <>
                              {permissionsForm.DEL === "1" && addDelete && (
                                <td className="text-center">
                                  <div>
                                    <ModalComponentEliminar
                                      modalTitle="Eliminar"
                                      id={registro[0]}
                                      table={nameTable}
                                      nameColumn={nameColumn}
                                      getData={getData}
                                      elimina={elimina}
                                    >
                                      <h6>
                                        ¿Seguro de eliminar el registro "
                                        {registro[1]}
                                        "?
                                      </h6>
                                      No podrá recuperar la información después
                                      de ser eliminada
                                    </ModalComponentEliminar>
                                  </div>
                                </td>
                              )}
                            </>
                          )
                        }
                        {deleteRow === false && (
                          <>
                            {addWatch && (
                              <td className="text-center">
                                <i
                                  className={
                                    sc.handCursor + " fa fa-eye text-info"
                                  }
                                  onClick={() =>
                                    actionWatch
                                      ? actionWatch(registro)
                                      : handleSubmit(registro, "watch")
                                  }
                                />
                              </td>
                            )}
                          </>
                        )}

                        {specialColumns &&
                          specialColumns.length > 0 &&
                          specialColumns.map((column, index) => (
                            <td
                              className="d-flex flex-column justify-content-center align-items-center text-center "
                              key={index}
                            >
                              {permissionsForm.INS === "1" && (
                                <div>
                                  <i
                                    className={sc.handCursor + column.icon}
                                    onClick={
                                      () =>
                                        column.method && column.method(registro)
                                      /* : handleSubmit(registro, "especial",column.route) */
                                    }
                                  />
                                  {column.content}
                                  {column.component}
                                </div>
                              )}
                            </td>
                          ))}
                      </tr>
                    ))
                  )}
                </tbody>
              </Table>
              {permissionsForm.CON === "1" && (
                <div className="row">
                  <div className="col-12 col-sm-6 d-flex justify-content-around mt-3">
                    <div>
                      Mostrando{" "}
                      <span className="text-primary">
                        {sortCol.currentPage * pageSize +
                          (tableContent.length === 0 ? 0 : 1)}
                      </span>{" "}
                      a{" "}
                      <span className="text-primary">
                        {pageSize * (sortCol.currentPage + 1) <= totalRegistros
                          ? pageSize * (sortCol.currentPage + 1)
                          : totalRegistros}{" "}
                      </span>
                      de {totalRegistros} registros
                    </div>
                    <select
                      className="form-control form-control-sm"
                      style={{ width: "78px" }}
                      value={sortCol.records}
                      onChange={handleOnChangeSelect}
                    >
                      <option value={5} key={5}>
                        5
                      </option>
                      <option value={10} key={10}>
                        10
                      </option>
                      <option value={15} key={15}>
                        15
                      </option>
                      <option value={20} key={20}>
                        20
                      </option>
                      <option value={100} key={100}>
                        100
                      </option>
                    </select>
                  </div>
                  <div className="col-12 col-sm-6 d-flex justify-content-center justify-content-sm-end">
                    <Pagination
                      className="pagination-borderless d-flex mt-3 "
                      aria-label="Page navigation example"
                    >
                      <PaginationItem disabled={sortCol.currentPage <= 0}>
                        <PaginationLink
                          onClick={(e) =>
                            settableContentPage(e, sortCol.currentPage - 1)
                          }
                          previous
                          href="#top"
                        />
                      </PaginationItem>
                      {[...Array(5)].map(
                        (page, i) =>
                          5 * seccionActual + i < pagesCount && (
                            <PaginationItem
                              key={i}
                              disabled={
                                5 * seccionActual + i === sortCol.currentPage
                              }
                            >
                              <PaginationLink
                                onClick={(e) =>
                                  settableContentPage(e, 5 * seccionActual + i)
                                }
                                href={5 * seccionActual + (i + 1)}
                                className={
                                  5 * seccionActual + i === sortCol.currentPage
                                    ? "text-primary text-bold"
                                    : "text-secondary"
                                }
                              >
                                {5 * seccionActual + (i + 1)}
                              </PaginationLink>
                            </PaginationItem>
                          )
                      )}
                      <PaginationItem
                        disabled={sortCol.currentPage >= pagesCount - 1}
                      >
                        <PaginationLink
                          onClick={(e) =>
                            settableContentPage(e, sortCol.currentPage + 1)
                          }
                          next
                          href="#top"
                        />
                      </PaginationItem>
                    </Pagination>
                  </div>
                </div>
              )}
            </div>
          </Col>
        </Row>
      </Widget>
    </div>
  );
};
export default TableProgressBar;


import React from "react";

import Widget from "../../../../components/Widget/Widget";
import FormReglasHorarios from "../FormReglasHorarios";
import { deleteSchedule,createSchedule,getSchedule } from "../functions";

export const EditarReglasHorarios = () => {
  return (
    <Widget className="widget-p-md">
        <h3>Editar Regla de Horario</h3>
        <FormReglasHorarios 
          data={localStorage.getItem('dataContent').split('|-|')} 
          eliminar={deleteSchedule} 
          crear={createSchedule} 
          obtener={getSchedule}
        />
    </Widget>
  )
}

export default EditarReglasHorarios;
import React, { useState,useEffect  } from "react";
import FormSistema from '../FormSistema'
import Widget from "../../../../components/Widget/Widget";
import {editar} from "../functions"


export const EditarSistema = (props) => {
  return (
    <div>
      <Widget className="widget-p-md">
      <h3>Editar Sistema</h3>
      <FormSistema data={localStorage.getItem('dataContent').split('|-|')} editar={editar}/>
      </Widget>
    </div>
  )
}
export default EditarSistema
import React from "react";
import Flatpickr from "react-flatpickr";
import {Spanish} from "flatpickr/dist/l10n/es"

import "react-datepicker/dist/react-datepicker.css";
import CustomTooltip from "../CustomTooltip/CustomTooltip";

export const DateInput = (props) => {
  const {
    label,
    inputName,
    onChangeMethod,
    value,
    isRequired,
    touched,
    errors,
    isMin,
    isMax,
    tooltip,
  } = props;

  const padTo2Digits = (num) => num.toString().padStart(2, "0");

  const formatDate = (date) => {
    return [
      padTo2Digits(date.getDate()),
      padTo2Digits(date.getMonth() + 1),
      date.getFullYear(),
    ].join("-");
  }

  return (
    <>
      <div className="form-group">
        <div className="d-flex">
          <label htmlFor={inputName}>
            {label} {isRequired && <span className="text-danger">*</span>}
          </label>
          {
            tooltip &&
            <CustomTooltip
              className="mr-3 mb-3"
              text={props.textTooltip}
              idTooltip={`tooltip-${inputName}`}
            />
          }
        </div>

        <Flatpickr
          required
          id={inputName}
          name={inputName}
          className={
            "form-control" +
            (errors && touched ? " is-invalid" : "")
          }
          onChange={onChangeMethod}
          value={value !== "" ? formatDate(value) : ""}
          placeholder={value === "" ? "dd/mm/yyyy" : ""}
          options={{
            minDate: isMin !== undefined ? formatDate(isMin) : null,
            maxDate: isMax !== undefined ? formatDate(isMax) : 'today',
            altInput: true,
            altFormat: "d/m/Y",
            dateFormat: "d-m-Y",
            locale: Spanish,

          }}
        />
        {/* <DatePicker
          name={inputName}
          key={inputName}
          onChange={onChangeMethod}
          onBlur={onBlurMethod}
          className={
            "form-control" +
            (errors && touched? " is-invalid" : "")
          }
          value={ value !== "" ? formatDate(value): "dd/mm/yyyy" }
          disabled={isDisabled === true ? true : false}
          minDate={isMin !== undefined ? isMin : null}
          maxDate={isMax !== undefined ? isMax : null}
          placeholderText = {placeholder ? placeholder : null}
        /> */}
        {touched && errors && (
          <span style={{ color: "red" }}>{errors}</span>
        )}
      </div>
    </>
  );
};

import React from "react";
import { Spinner } from "reactstrap";
import s from "./FullScreenLoader.module.scss";

const FullFormLoader = ({ show, setShow, message }) => {
  return (
    <div className={s.loaderFormBack + " " + (show && s.show) }>
      <div className={"text-center " + s.ico}>
        <Spinner color="info"/>
        <h5 className="text-info">{message?message:""}</h5>
      </div>
    </div>
  );
};

export default FullFormLoader;

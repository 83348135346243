import { useEffect } from "react";
import { useLocation } from "react-router";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import getActions from "../../helpers/getPermissionForms";
import getRouters from "../../components/Layout/function";

export function GetPermisos(setPermissionsForm) {
    // PERMISOS

    const loc = useLocation();
    const user = useSelector((state) => state.userInfo);
    const { id, profileType } = useSelector((state) => state.userInfo);

    //PERMISOS
    const history = useHistory();
    const getActionsUser = async () => {
        const pathname = loc.pathname.split("/")[2];
        if (pathname !== "dashboard") {
            const route = await getRouters(user.permission, pathname);
            if (!route.permission) {
                history.push("/template");
            } else {
                getPermissionsUser();
            }
        }
    };

    const getPermissionsUser = async () => {
        const pathname = loc.pathname.split("/")[2];
        const result = await getActions(
            id,
            profileType.selectProfile.keyProfile,
            pathname
        );
        setPermissionsForm(result.actions);
    };

    useEffect(() => {
        getActionsUser();
    }, [])
}
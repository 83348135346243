import React, { useState } from "react";
import TableComponent from "../../../components/TableComponent/TableComponentFiltro";

export const Regiones = () => {
  const cabeceras = ["Id", "Nombre", "Editar", "Eliminar"];

  const [parametros] = useState({
    action: "datatable",
    table: "regions",
    rows: "keyRegion,nameRegion", 
    conditions: "enabled = 1",
    page: 0,
    records: 5,
    search: "",
    order: "",
  });

  return (
    <div>
      <TableComponent
        titulo="Regiones"
        cabecerasTabla={cabeceras}
        filtro={null}
        rutaCrear="/template/regiones/crear"
        rutaEditar="/template/regiones/editar"
        parametros={parametros}
      />
    </div>
  );
};
export default Regiones;

import React from 'react';

const SofiaLogo = ({minWidth = true, onClick}) => {
  const origin = window.location.origin
  return (
    <img className={`${minWidth ? "" : "my-2 mx-2"}`} style={{width: minWidth ? "87px" : "55px"}} src={`${origin}/images/logo.png`} alt="Logo" onClick={onClick}/>
  );
}

export default SofiaLogo;

import { useState } from "react";
import { Button, Spinner } from "reactstrap";
import {
  TextAreaInput,
} from "../../../components/GenericInputsFormik";
import { peticionEncript } from "../../../helpers/peticionesEncripted";
import axios from "axios";
import { toast } from "react-toastify";
import Notification from "../../../components/Notification/Notification";
import FullFormLoader from "../../../components/Loader/FullFormLoader";


async function doRequest(route, params) {
  let info = [];
  const dataEncrypted = peticionEncript(params);
  await axios
    .post(process.env.REACT_APP_API + route, dataEncrypted)
    .then((res) => {
      if (res.status === 200 && res.data.status === "success") {
        info = res.data.data;
        toast(
          <Notification
            type={"elimina_exito"}
            backMessage={res.data.message}
            withIcon
          />,
          {
            closeButton: false,
          }
        );
      } else {
        toast(
          <Notification
            type={"consultar_error"}
            backMessage={res.data.message}
            withIcon
          />,
          { closeButton: false }
        );
      }
    })
    .catch((err) => {
      toast(<Notification type={"consultar_servidor_error"} withIcon />, {
        closeButton: false,
      });
    });
  return info;
}

const AprobarProyecto = ({ proyect, setProyect, permissions }) => {
  const [loading, setLoading] = useState(false);
  const [notes, setNotes] = useState("");

  const updateProyect = async (type) => {
    setLoading(true);
    const params = {
      action: "update",
      table: "proyects",
      rows: { keyStat: type, notes: notes },
      condition: { idProyect: proyect.id },
    }

    const data = await doRequest("receiver/receiver.php", params);
    if(data.length > 0){
      setProyect({
        id: 0,
        name: "",
        objetive: "",
        keyStat: "",
        canEdit: true,
        notes: ""
      });
    }
    setLoading(false);
  };


  return (
    <div className="mt-3">
      <div className="mb-2">
        <h4>Aprobación del Proyecto</h4>

        <TextAreaInput
          label="Notas u observaciones"
          inputName="notes"
          inputRows={6}
          onChangeMethod={(e) => {
            if(permissions.UPD) setNotes(e.target.value);
          }}
          value={notes}
        />

        {permissions.UPD && <div className="row mt-2">
          <div className="col-12 col-md-6 order-2 order-sm-1 text-center my-3">
            <Button color="primary" type="button" onClick={() => updateProyect(68)}>
              Aprobar
            </Button>
          </div>
          <div className="col-12 col-md-6 order-1 order-sm-2 text-center my-3">
            <Button color="danger" type="button" onClick={() => updateProyect(69)}>
              Rechazar
            </Button>
          </div>
        </div>}
        <FullFormLoader show={loading}/>
      </div>
    </div>
  );
};

export default AprobarProyecto;

import React, { useState } from "react";
import { Col, Row } from "reactstrap";
import Loader from "../../../components/Loader/Loader";
import { useEffect } from "react";
import Widget from "../../../components/Widget/Widget";
import ConsultaBienes from "./consultaBienes/ConsultaBienes";
import AsignarBien from "./asiganarBien/AsignarBien";
import TableComponentCustom from "../../../components/TableComponent/TableComponentCustom";
// import ReportesBienes from "./reportes/ReportesBienes";
import ReportesBienes from "./reportes/ReportesBienes";

const headers = [
  "Id",
  "EMPLEADO",
  "NUMERO DE INVENTARIO ",
  "NUMERO DE SERIE",
  "DENOMINACION",
  "ADSCRIPCION DE RESGUARDO",
  "ESTATUS",
  "PRECIO",
  "AREA",
  "ELIMINAR",
];

export const AsignacionBienes = () => {
  const [loading] = useState(false);
  const [collapse, setCollapse] = useState(false);

  const [obj, setObj] = useState("");
  const [params, setParams] = useState("");

  useEffect(() => {
    if (obj !== "") {
      setParams(
        obj.type === "staff"
          ? {
              action: "datatable",
              table:
                "assignment B INNER JOIN inventories A ON (A.idInventory = B.idInventory and B.enabled=1) INNER JOIN furnitures C USING (idFurniture) INNER JOIN status D using (keyStat) INNER JOIN furniture_classes E USING (keyClass) inner join furniture_subgroups F USING (keySubGroup) inner join furniture_groups G USING (keyGroup) INNER JOIN staff H ON (H.idStaff = B.idStaff) LEFT JOIN areas I ON (I.idArea = B.idArea)",
              rows: "B.idAssignment,CONCAT(H.name,' ',H.pName,' ',H.mName) as nameStaff,inventorykey,serialkey,CONCAT(nameFurniture,' Marca: ',brand,' Modelo: ',model) as nameFurniture,CONCAT(nameGroup,'\n',nameSubGroup,'\n',nameClass) as setup,nameStat,actualPrice,I.name as nameArea",
              conditions: `A.enabled =1 and B.enabled = 1 and H.idStaff = ${obj.id}`,
              page: 0,
              records: 5,
              search: "",
              order: "",
            }
          : {
              action: "datatable",
              table:
                "assignment B INNER JOIN inventories A ON (A.idInventory = B.idInventory and B.enabled=1) INNER JOIN furnitures C USING (idFurniture) INNER JOIN status D using (keyStat) INNER JOIN furniture_classes E USING (keyClass) inner join furniture_subgroups F USING (keySubGroup) inner join furniture_groups G USING (keyGroup) INNER JOIN staff H ON (H.idStaff = B.idStaff) LEFT JOIN areas I ON (I.idArea = B.idArea)",
              rows: "B.idAssignment, CONCAT(H.name,' ',H.pName,' ',H.mName) as nameStaff,inventorykey,serialkey,CONCAT(nameFurniture,' Marca: ',brand,' Modelo: ',model) as nameFurniture,CONCAT(nameGroup,'\n',nameSubGroup,'\n',nameClass) as setup,nameStat,actualPrice,I.name as nameArea",
              conditions: `A.enabled = 1 and B.enabled = 1 and B.idArea= ${obj.id}`,
              page: 0,
              records: 5,
              search: "",
              order: "",
            }
      );
    }
  }, [obj]);

  

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <Widget className="widget-p-md">
          <Row>
            <Col xs={12} lg={12} className="text-center">
              <span className="text-center text-succes headline-1">
                Asignacion de bienes
              </span>
            </Col>
          </Row>

          <ConsultaBienes obj={obj} setObj={setObj} setCollapse={setCollapse} />

          {obj !== "" && (
            <>
              <ReportesBienes obj={obj} />

              {params !== "" && (
                <TableComponentCustom
                  titulo={"Bienes"}
                  cabecerasTabla={headers}
                  filtro={null}
                  parametros={params}
                  addDelete={true}
                  /* elimina={deleteBien} */
                />
              )}
              <AsignarBien
                collapse={collapse}
                setCollapse={setCollapse}
                obj={obj}
                setParams={setParams}
              />
            </>
          )}
        </Widget>
      )}
    </>
  );
};
export default AsignacionBienes;

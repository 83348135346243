
import * as Yup from "yup";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import {
    Alert,
    Button,
    Col,
    Modal,
    ModalBody,
    ModalHeader,
    Row
} from "reactstrap";
import { fMoney } from "../../../libs/tools/format";
import { NumberInput } from "../../../components/GenericInputsFormik/NumberInput";
import { TextAreaInput } from "../../../components/GenericInputsFormik";
import { doRequest } from "../../../helpers/requests";
import FullFormLoader from "../../../components/Loader/FullFormLoader";
import ModalConfirmation from "../../../components/Modal/ModalConfirmation";
import { useHistory } from "react-router-dom";

const defaultForm = {
    m1: 0,
    m2: 0,
    m3: 0,
    m4: 0,
    m5: 0,
    m6: 0,
    m7: 0,
    m8: 0,
    m9: 0,
    m10: 0,
    m11: 0,
    m12: 0,
    tt: 0,
    notes: ""
}

const cMonth = new Date().getMonth();

export default function ModalDictaminar({ modal, setModal, data, getMonths, updProyect }) {
    const [loading, setLoading] = useState(false);
    const [modalDictamen, setModalDictamen] = useState(false);
    const history = useHistory();

    function validMonth(m) {
        return Yup.number()
            .required("Ingrese una cantidad")
            .min(0, "Ingrese un valor positivo")
            .test("balance-exceeded", "Saldo actual excedido", (value) => +value <= +data.aMes[m].amount)
            .test("required-exceeded", "Monto solicitado excedido", (value) => data.case === 201 || +value <= +data.rMes[m])
    }

    const FormSchema = Yup.object().shape({
        m1: validMonth(0),
        m2: validMonth(1),
        m3: validMonth(2),
        m4: validMonth(3),
        m5: validMonth(4),
        m6: validMonth(5),
        m7: validMonth(6),
        m8: validMonth(7),
        m9: validMonth(8),
        m10: validMonth(9),
        m11: validMonth(10),
        m12: validMonth(11),
        tt: Yup.number().required("No se han asignado saldos").min(0, "Cantidad invalida").lessThan(+data.mSolicitado+0.01, "Monto solicitado excedido")
    });

    const {
        handleSubmit,
        values,
        handleBlur,
        errors,
        touched,
        setFieldValue,
        handleReset,
        setValues,
        setTouched
    } = useFormik({
        initialValues: defaultForm,
        validationSchema: FormSchema,
        onSubmit: () => setModalDictamen(true),
        onReset: () => { setModal(false) },
        enableReinitialize: true,
    });

    useEffect(() => {
        setTouched({
            m1: true,
            m2: true,
            m3: true,
            m4: true,
            m5: true,
            m6: true,
            m7: true,
            m8: true,
            m9: true,
            m10: true,
            m11: true,
            m12: true,
            tt: true
        });
        setValues({
            m1: data.rMes[0],
            m2: data.rMes[1],
            m3: data.rMes[2],
            m4: data.rMes[3],
            m5: data.rMes[4],
            m6: data.rMes[5],
            m7: data.rMes[6],
            m8: data.rMes[7],
            m9: data.rMes[8],
            m10: data.rMes[9],
            m11: data.rMes[10],
            m12: data.rMes[11],
            tt: +data.mSolicitado
        }, true);
        
    }, [data]);

    const toggle = () => setModal(!modal);

    const onChange = ({ target }) => {
        if(target.name !== "notes"){
            const total = values.tt - (values[target.name] || 0) + (+target.value || 0);
            setFieldValue("tt", total);
        }
        setFieldValue(target.name, target.value);
    };

    async function aprobar() {
        setLoading(true);
        const params = { 
            action: "approveBudgetDetail", 
            idDetail: data.idDetail, 
            months: [...Array(12)].map((el, ix) => values["m"+(ix+1)]),
            notes: values.notes
        }
        const res = await doRequest("app/facades/budgets/budgetsF.php", params, true);
        setLoading(false);
        if(res.length > 0) {
            getMonths();
            updProyect(data.idIx, "APROBADO", 122);
            handleReset();
        }
        
    }

    function getMaxField(ix) {
        return +data.rMes[ix] > +data.aMes[ix].month ? +data.rMes[ix] : +data.aMes[ix].month
    }

    function blockField(ix){
        if(data.case === 200) {
            if(+data.rMes[ix] === 0) {
                if(ix >= cMonth) return +data.aMes[ix].amount < 1
                else return true;
            } else return false;
        }
        else{
            if(+data.rMes[ix] === 0){
                if(+data.aMes[ix].amount !== 0) return ix < cMonth
                else return true;
            }else return false;
        }
    }

    return (
        <>
            <Modal isOpen={modal} toggle={toggle} keyboard={false} backdrop="static" size="lg" centered style={{ minWidth: "95%" }}>
                <ModalHeader toggle={toggle} className="">
                    <p>Dictamen presupuestal</p>
                </ModalHeader>
                <ModalBody className="px-4 pt-0">
                    <div className="mx-4">
                        <Row className="border border-bottom-0 border-right-0 text-dark">
                            <Col xs={12} className="bg-primary p-2 border-right">
                                <h6 className="m-0 text-light ">Presupuesto solicitado</h6>
                            </Col>
                            <Col xs={12} md={6}>
                                <Row>
                                    <Col xs={12} md={4} className="p-1 text-center border-bottom border-right">
                                        <p className="font-weight-bold">Proyecto presupuestal</p>
                                    </Col>
                                    <Col xs={12} md={8} className="p-1 border-bottom border-right">
                                        {data.proyecto}
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs={12} md={6}>
                                <Row className="h-100">
                                    <Col xs={12} md={2} className="p-1 text-center border-bottom border-right">
                                        <p className="font-weight-bold">Partida</p>
                                    </Col>
                                    <Col xs={12} md={10} className="p-1 border-bottom border-right">
                                        {data.partida}
                                    </Col>
                                </Row>
                            </Col>
                        </Row>

                        <Row className="text-center mt-2 text-dark" style={{fontSize: "15px"}}>
                            <Col xs={12} md={4} className="p-1">
                                <p>Monto Solicitado:</p>
                                <h5 className="text-danger border-bottom border-middle-gray mx-5">{fMoney(data.mSolicitado)}</h5>
                            </Col>
                            <Col xs={12} md={4} className="p-1">
                                <p>Saldo Disponible:</p>
                                <h5 className="text-danger border-bottom border-middle-gray mx-5">{fMoney(data.mDisponible)}</h5>
                            </Col>
                            <Col xs={12} md={4} className="p-1">
                                <p>Monto a Aprobar <span className="text-danger">*</span>:</p>
                                <h5 className={"text-danger border-bottom mx-5 border-" + (errors.tt ? "danger": "middle-gray")}>{fMoney(values.tt)}</h5>
                                <span className="text-danger">{errors.tt}</span>
                            </Col>
                        </Row>
                    </div>

                    <form onSubmit={handleSubmit} onReset={handleReset}>
                        <Row className="border border-middle-gray border-right-0 mx-1 mt-1">
                            {data.aMes.map((el, ix) => (
                                <Col key={"rcg" + ix} xs={12} sm={4} md={3} className="p-0 border-right border-middle-gray">
                                    <div className="bg-dark-gray text-center text-light p-1">{el.nameMonth}</div>
                                    <div className="text-middle text-center p-2 text-dark">
                                        <p>
                                            Saldo actual: <strong>{fMoney(el.amount)}</strong>
                                        </p>
                                        <p>
                                            Diferencia: <strong>{fMoney(+el.amount - (+values[`m${ix + 1}`]||0))}</strong>
                                        </p>
                                        <NumberInput
                                            inputName={`m${ix + 1}`}
                                            onChangeMethod={onChange}
                                            onBlurMethod={handleBlur}
                                            value={values[`m${ix + 1}`]}
                                            errors={errors[`m${ix + 1}`]}
                                            touched={touched[`m${ix + 1}`]}//
                                            prefix="$"
                                            isRequired
                                            className="text-center"
                                            decimals
                                            isMin={0}
                                            isMax={getMaxField(ix)}
                                            readOnly={blockField(ix)}
                                        />
                                    </div>
                                </Col>
                            ))}
                        </Row>

                        {
                            data.case !== 200 &&
                            <div className="my-5">
                                <Alert color="primary" className="opacity-75">
                                    <h4 className="alert-heading">¡Saldo Insuficiente!</h4>
                                    <p>
                                        No hay suficiente saldo para el proyecto y partida para los meses solicitdados en el presupuesto vigente.
                                    </p>
                                    <hr />
                                    <p className="mb-0">
                                        Puede realizar un traspaso a través del módulo Adecuaciones Presupuestales.
                                    </p>
                                    <div className="text-center mt-2">
                                        <Button color="info" type="button" onClick={()=>history.push("/template/adecuaciones")}>
                                            Solicitar traspaso
                                        </Button>
                                    </div>
                                </Alert>
                            </div>

                        }

                        <div className="my-4 mx-1">
                            <TextAreaInput
                                label="Observaciones"
                                inputName="notes"
                                inputRows={6}
                                onChangeMethod={onChange}
                                value={values.notes}
                            />
                        </div>

                        <div className="my-2 d-flex flex-md-row flex-column-reverse justify-content-md-around">
                            <Button color="danger" type="reset" className="mt-3">
                                Cancelar
                            </Button>
                            <Button color="success" type="submit" className="mt-2 mt-md-3">
                                Guardar
                            </Button>
                        </div>
                    </form>
                </ModalBody>
                <FullFormLoader show={loading} message="" />
            </Modal>

            {/* Modal guardar dictamen */}
            <ModalConfirmation
                modalTitle="Guardar dictamen"
                modal={modalDictamen}
                setModal={setModalDictamen}
                crear={aprobar}
                centered
            >
                <div className="text-center">
                    <h6>¿Desea guardar el dictamen para este proyecto presupuestal?</h6>
                    <p>No podrá realizar modificaciones después de dictaminarlo.</p>
                </div>
            </ModalConfirmation>
        </>
    );
}

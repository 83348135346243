
import Datatable from "../../../../../components/Datatable/Datatable";
import { Row, Col, Button } from "reactstrap";


const ListTableStaffs = (props) => {
  const {
    staffList,
    setStaffList,
    refresh,
    setRefresh,
    title,
    deleteRow,
    deleteAll,
  } = props;

  const headers = ["Numero de empleado", "Nombre del empelado", "Eliminar"];
  const headersWithOuthDelete = ["Numero de empleado", "Nombre del empelado"];

  const columns = [
    { data: "staffNumber" },
    { data: "nameStaff" },
    {
      data: null,
      render: function (row) {
        return (
          <Button color="danger" onClick={() => deleteStaff(row)}>
            {" "}
            Eliminar
          </Button>
        );
      },
    },
  ];

  const columnsWithOutDelete = [{ data: "staffNumber" }, { data: "nameStaff" }];

  const deleteStaff = (staff) => {
    let tmpList = staffList.slice(0, staffList.length);
    const arrDel = tmpList.filter(
      (staffElement) => staffElement.idStaff !== staff.idStaff
    );
    setStaffList(arrDel);
    setRefresh(true);
  };

  const deleteAllStaff = () => {
    setStaffList([]);
    setRefresh(true);
  };

  const columnDefs = [
    {
      targets: [0, 1, 2, 3, 4, 5],
      className: "text-center",
    },
  ];

  return (
    <div className="mt-5">
      <Row className="d-flex justify-content-center">
        <h2 className="text-center mb-5">{title}</h2>
        <Col xs={10} lg={10}>
          <div
            style={{
              height: "300px",
              overflowY: "auto",
              overflowX: "hidden",
            }}
          >
            <Datatable
              headers={deleteRow ?  headers:headersWithOuthDelete }
              columns={deleteRow ?  columns:columnsWithOutDelete }
              control="front"
              data={staffList}
              stateRefresh={[refresh, setRefresh]}
              paging={false}
              columnDefs={columnDefs}
            />
          </div>
          {deleteAll && (
            <div className="text-center mt-3">
              <Button
                color={"danger"}
                onClick={() => {
                  deleteAllStaff();
                }}
              >
                Eliminar Empleados Asignados
              </Button>
            </div>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default ListTableStaffs;

import { useEffect } from "react";
import { useLocation } from "react-router";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import getActions from "../../helpers/getPermissionForms";
import getRouters from "../../components/Layout/function";
import getChildPermissions from "./getChildPermissions";

/**
 * Mandar a llamar la funcion en el componente, el cual establecera los estados en los useEffect
 * @param {*} setPermissions set del hook de permiso, guarda en el use state el valor de los permisos del CRUD del modulo actual
 * @param {*} setLoading set del hook de loading, manda en caso de estar en proceso de carga un Loader
 *
 * @param {number} iModule (opcional) id del modulo
 * @param {*} setChildPermissions (obligatorio si existe iModule) set del hook de permisos de los hijos
 */
export function GetPermissions(
  setPermissions,
  setLoading,
  idModule,
  setChildPermissions
) {
  const loc = useLocation();
  const user = useSelector((state) => state.userInfo);
  const { id, profileType } = useSelector((state) => state.userInfo);

  //PERMISOS
  const history = useHistory();
  const getActionsUser = async () => {
    const pathname = loc.pathname.split("/")[2];
    if (pathname !== "dashboard") {
      const route = await getRouters(user.permission, pathname);
      if (!route.permission) {
        history.push("/template");
      } else {
        getPermissionsUser();
      }
    }
  };

  const getPermissionsUser = async () => {
    const pathname = loc.pathname.split("/")[2];
    const res = await getActions(
      id,
      profileType.selectProfile.keyProfile,
      pathname
    );
    if (res.success) {
      const permissions = {
        CON: res.actions.CON === "1",
        INS: res.actions.INS === "1",
        UPD: res.actions.UPD === "1",
        DEL: res.actions.DEL === "1",
      };
      if (setLoading) setLoading(false);
      if (setPermissions) setPermissions(permissions);
      if (idModule) {
        const permsChilds = await getChildPermissions(
          idModule,
          user.permission,
          setChildPermissions
        );
        setChildPermissions(permsChilds);
      }
    } else history.push("/template");
  };

  useEffect(() => {
    getActionsUser();
  }, []);
}

export function checkSpecialColumn(cabeceras, spec, permissions) {
  spec.forEach((e) => {
    if (permissions[e[1]] !== true || permissions[e[1]] !== "1")
      cabeceras.splice(e[0], 1);
  });
  return cabeceras;
}

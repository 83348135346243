import Datatable from "../../../components/Datatable/Datatable";
import ModalDelete from "../../../components/Modal/ModalDelete";
import { doRequestSaveRes, doRequest } from "../../../helpers/requests";
import { Button, Collapse } from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import { TextInput } from "../../../components/GenericInputsFormik";
import ModalConfirmation from "../../../components/Modal/ModalConfirmation";
import { toast } from "react-toastify";
import Notification from "../../../components/Notification/Notification";
import { useState } from "react";
import FullFormLoader from "../../../components/Loader/FullFormLoader";

const headers = ["Requisición", "Artículos", "Áreas", "Ver", "Eliminar"];

const columnDefs = [
    {
        orderable: true,
        targets: [1, 2],
        className: "text-right",
    },
    {
        orderable: false,
        targets: [3, 4],
        className: "text-center",
    },
];

const defaultForm = { nameRequeriment: "" };

const FormSchema = Yup.object().shape({
    nameRequeriment: Yup.string().required("Ingrese un nombre para la requisición").max(200, "Nombre de requisición muy largo"),
});

export default function Consulta({setReq, permissions}) {
    const [loading, setLoading] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [modal, setModal] = useState(false);
    const [openForm, setOpenForm] = useState(false);

    const {
        handleSubmit,
        handleReset,
        handleBlur,
        values,
        errors,
        touched,
        setFieldValue,
        setValues
    } = useFormik({
        initialValues: defaultForm,
        validationSchema: FormSchema,
        enableReinitialize: true,
        onSubmit: () => setModal(true),
        onReset: () => setOpenForm(false),
    });

    function onChange({target}){
        setFieldValue(target.name, target.value);
    }

    async function getData(ordCol, itemsPerPage, currentPage, search) {
        const res = await doRequestSaveRes(
            "receiver/receiver.php",
            getParams(ordCol, itemsPerPage, currentPage, search),
            false,
            false
        );
        if (res.code === "200") return res;
        else return null;
    }

    function getParams(ordCol, itemsPerPage, currentPage, search) {
        const params = {
            action: "datatable",
            table: "warehouserequeriments A LEFT JOIN warehouserequerimentdetail B USING (idRequeriment) LEFT JOIN warehouserequerimemtareas C USING (idRequeriment)",
            rows: "A.idRequeriment, A.nameRequeriment, COUNT(distinct IF(B.enabled = 1, B.idDetail, NULL)) AS cDetails, COUNT(distinct IF(C.enabled = 1, C.idRequerimentArea, NULL)) AS cAreas",
            conditions: `A.enabled = 1`,
            order: ordCol,
            records: itemsPerPage.toString(),
            page: (itemsPerPage * currentPage).toString(),
            search,
            group: "A.idRequeriment",
        };
        return params;
    }

    const columns = [
        { data: "nameRequeriment" },
        { data: "cDetails" },
        { data: "cAreas" },
        {
            data: null,
            render: (row) => <i className="fa fa-eye text-info cursor-pointer" onClick={()=>setReq({id: row.idRequeriment, name: row.nameRequeriment})} />,
        },
        {
            data: null,
            render: (row) => (
                permissions.DEL &&
                <ModalDelete
                    modalTitle="Eliminar"
                    table=""
                    nameColumn={[""]}
                    elimina={fnDelete}
                    id={row.idRequeriment}
                >
                    <h6>
                        Está seguro de eliminar la requisición{" "}
                        {row.nameRequeriment}?
                    </h6>
                    No podrá revertir esta acción
                </ModalDelete>
            ),
        },
    ];

    async function fnDelete(id) {
        setLoading(true);
        const params = {
            action: "delete",
            table: "warehouserequeriments",
            condition: {
                idRequeriment: id,
            },
            force: 1
        };
        const res = await doRequest("receiver/receiver.php", params, true);
        if (res.length > 0) {
            setRefresh(true);
            params.table = "warehouserequerimemtareas";
            await doRequest("receiver/receiver.php", params, false, false);
            params.table = "warehouserequerimentdetail";
            await doRequest("receiver/receiver.php", params, false, false);
            if(+values.idRequeriment === +id) handleReset();
        }
        setLoading(false);
    }

    async function save() {
        setLoading(true);
        const params = {
            action: "insert",
            table: "warehouserequeriments",
            rows: values,
            validate: [["nameRequeriment"]],
        };
        const res = await doRequest("receiver/receiver.php", params, true);
        if (res.length > 0){
            setRefresh(true);
            handleReset();
        }
        setLoading(false);
    }

    return (
        <div>
            {
                permissions.CON &&
                <Datatable
                    headers={headers}
                    columns={columns}
                    columnDefs={columnDefs}
                    petition={getData}
                    control="back"
                    stateRefresh={[refresh, setRefresh]}
                    searching={true}
                    className="p-0"
                />
            }
            {
                permissions.INS &&
                <div className=" d-flex flex-md-row flex-column justify-content-md-end">
                    <Button color="info" onClick={() => setOpenForm(true)}>
                        Agregar
                    </Button>
                </div>
            }

            <Collapse isOpen={openForm} className="mt-3 pt-3 border-top">
                <h6 className="mb-2">Crear requisición</h6>
                <form onSubmit={handleSubmit} onReset={handleReset}>
                    <TextInput
                        label="Requisición"
                        value={values.nameRequeriment}
                        touched={touched.nameRequeriment}
                        errors={errors.nameRequeriment}
                        inputName="nameRequeriment"
                        isRequired
                        onBlurMethod={handleBlur}
                        onChangeMethod={onChange}
                    />
                    <div className=" d-flex flex-md-row flex-column-reverse justify-content-md-around">
                        <Button color="danger" type="reset" className="mt-3">
                            Cancelar
                        </Button>
                        <Button color="success" type="submit" className="mt-2 mt-md-3">
                            Guardar
                        </Button>
                    </div>
                </form>
            </Collapse>

            <ModalConfirmation
                modalTitle="Crear requisición"
                modal={modal}
                setModal={setModal}
                crear={save}
                values={values}
            >
                <div className="d-flex justify-content-center">
                    <h6>¿Está seguro de guardar la requisición?</h6>
                </div>
            </ModalConfirmation>

            <FullFormLoader show={loading} />
        </div>
    );
}

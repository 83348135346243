import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import CurrencyInput from "react-currency-input-field";
import {
  Col,
  Row,
  Button,
  Alert,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
} from "reactstrap";
import s from "../../../tables/Tables.module.scss";

import Widget from "../../../../components/Widget/Widget";
import Loader from "../../../../components/Loader/Loader";
import Notification from "../../../../components/Notification/Notification";
import ModalConfirmation from "../../../../components/Modal/ModalConfirmation";
import { SelectTypeHeadSingle } from "../../../../components/GenericInputsFormik/index";
import { peticionesReceiver } from "../../../../helpers/peticionesReceiver";
import { YearInput } from "../../../../components/GenericInputsFormik/YearInput";

const CrearDispersion = (props) => {
  

  //datos para el select
  const [typespayrollclerk, setTypesPayRollClerk] = useState([]);
  const [typesLayaoutclass, setTypesLayaoutClass] = useState([]);
  //modales
  const [modal, setModal] = useState(false);
  const [modalErrors, setModalErrors] = useState(false);

  //errores
  const [errorsAdd, seterrorsAdd] = useState([]);

  const [loader, setLoader] = useState(true);

  const API = peticionesReceiver();
  const history = useHistory();

  const [payrollclerk, setPayRollClerk] = useState({
    idPayrollclerk: "",
    keyTypePayrollclerk: "",
    keyLayoutClass: "",
    period: "",
    year: "",
    /* url: "", */
  });
  const FormSchema = Yup.object().shape({
    keyTypePayrollclerk: Yup.string().required(
      "Seleccione un tipo de dispersion"
    ),
    keyLayoutClass: Yup.string().required("Seleccione un tipo de layout"),
    period: Yup.number()
      .min(1, "Seleccione un periodo")
      .max(24, "Seleccione un periodo menor a 24")
      .required("Seleccione un periodo"),
    year: Yup.date().required("Seleccione una año"),
  });

  useEffect(() => {
    getTypesPayRollclerk();
    getTypesLayaoutClass();
  }, []);
  useEffect(() => {
    if (errorsAdd.length !== 0) {
      setModalErrors(true);
    }
  }, [errorsAdd]);

  const closeModalErrors = () => {
    setModalErrors(false);
  };
  const CloseBtn = (
    <i className="eva eva-close cursor-pointer" onClick={closeModalErrors} />
  );

  const createPayrollclerk = async (data) => {
    setLoader(false)
    let clearData = {
      keyTypePayrollclerk: data.keyTypePayrollclerk,
      keyLayoutClass: data.keyLayoutClass,
      period: data.period,
      year: new Date(values.year).getFullYear(),
    };
    let mensaje = "";
    const params = {
      action: "saveDispersion",
      /* table: "regions", */
      rows: clearData,
      /* validate: [["nameRegion"]] */
    };
    API.peticionEndPoint(params, "app/facades/payslips/payrollF.php")
      .then((res) => {
        if (res.data.status === "success") {
          toast(
            <Notification
              type={"agrega_exito"}
              backMessage={res.data.message}
              withIcon
            />
          );
          history.push("/template/dispersion");
        } else {
          seterrorsAdd(res.data);
          toast(
            <Notification
              type={"agrega_error"}
              backMessage={res.data.message}
              withIcon
            />
          );
        }
      })
      
      .catch((err) => {
        toast(<Notification type={"consultar_servidor_error"} withIcon />);
      })
      .finally(() => {
        setLoader(true)
      });
    return mensaje;
  };

  const getTypesLayaoutClass = async () => {
    const params = {
      action: "select",
      table: "types_layaoutclass",
      condition: {
        enabled: 1,
      },
    };
    await API.peticion(params)
      .then((res) => {
        if (res.status === 200 && res.data.status === "success") {
          setTypesLayaoutClass(res.data.data);
        } else {
          toast(
            <Alert color="warning">Error al consultar tipos de Paises</Alert>
          );
        }
      })
      .catch((err) => {
        toast(<Notification type={"consultar_servidor_error"} withIcon />);
      });
  };
  const getTypesPayRollclerk = async () => {
    const params = {
      action: "select",
      table: "types_payrollclerk",
      condition: {
        enabled: 1,
      },
    };
    await API.peticion(params)
      .then((res) => {
        if (res.status === 200 && res.data.status === "success") {
          setTypesPayRollClerk(res.data.data);
        } else {
          toast(
            <Alert color="warning">Error al consultar tipos de Paises</Alert>
          );
        }
      })
      .catch((err) => {
        toast(<Notification type={"consultar_servidor_error"} withIcon />);
      });
  };

  const onChange = (e) => {
    setFieldValue([e.target.name], e.target.value);
  };

  const {
    handleSubmit,
    handleBlur,
    values,
    errors,
    touched,
    setFieldValue,
    resetForm,
  } = useFormik({
    initialValues: payrollclerk,
    onSubmit: () => {
      setModal(true);
    },
    validationSchema: FormSchema,
    enableReinitialize: true,
  });

  const OnChangeSelect = (event) => {
    const { name, value } = event.target;
    setFieldValue(name, value);
  };

  return (
    <Widget className="widget-p-md">
      {loader ? (
        <div>
          <form onSubmit={handleSubmit} className="mt-4" noValidate>
            <Row className="d-flex justify-content-around">
              <Col xs={12} md={12} lg={5}>
                <SelectTypeHeadSingle
                  label="Tipo de dispersión"
                  isRequired={true}
                  inputName="keyTypePayrollclerk"
                  optionsArray={typespayrollclerk}
                  defaultOption="Seleccione un tipo de dispersión"
                  onChangeMethod={OnChangeSelect}
                  onBlur={handleBlur}
                  isDisabled={false}
                  value={values.keyTypePayrollclerk}
                  touched={touched.keyTypePayrollclerk}
                  errors={errors.keyTypePayrollclerk}
                  optionValue="keyTypePayrollclerk"
                  optionName="nameTypePayrollclerk"
                />
              </Col>
              <Col xs={12} md={12} lg={5}>
                <SelectTypeHeadSingle
                  label="Tipo de layout"
                  isRequired={true}
                  inputName="keyLayoutClass"
                  optionsArray={typesLayaoutclass}
                  defaultOption="Seleccione un tipo de layout"
                  onChangeMethod={OnChangeSelect}
                  onBlur={handleBlur}
                  isDisabled={false}
                  value={values.keyLayoutClass}
                  touched={touched.keyLayoutClass}
                  errors={errors.keyLayoutClass}
                  optionValue="keyLayoutClass"
                  optionName="nameLayoutClass"
                />
              </Col>
            </Row>
            <Row className="d-flex justify-content-around">
              <Col xs={12} md={12} lg={5}>
                <label>
                  Periodo <span className="text-danger">*</span>
                </label>
                <CurrencyInput
                  id="period"
                  name="period"
                  className={
                    "form-control" +
                    (errors.period && touched.period ? " is-invalid" : "")
                  }
                  allowDecimals={false}
                  allowNegativeValue={false}
                  onValueChange={(value, name) =>
                    onChange({ target: { name: name, value: value } })
                  }
                  value={values.period}
                  onBlur={handleBlur}
                  min={1}
                  max={24}
                />
                {touched.period && errors.period && (
                  <span className="text-danger">{errors.period}</span>
                )}
              </Col>
              <Col xs={12} md={12} lg={5}>
                <YearInput
                  label="Año"
                  inputName="year"
                  onChangeMethod={onChange}
                  onBlurMethod={handleBlur}
                  value={values.year}
                  touched={touched.year}
                  errors={errors.year}
                  isRequired
                />

                {/* <label>Año <span className="text-danger">*</span></label>
                <CurrencyInput
                  id="year"
                  name="year"
                  className={
                    "form-control" +
                    (errors.year && touched.year ? " is-invalid" : "")
                  }
                  allowDecimals={false}
                  allowNegativeValue={false}
                  onValueChange={(value, name) =>
                    onChange({ target: { name: name, value: value } })
                  }
                  value={values.year}
                  onBlur={handleBlur}
                  maxLength={4}
                  min={1}
                  disableGroupSeparators={true}
                />
                {touched.year && errors.year && (
                  <span className="text-danger">{errors.year}</span>
                )} */}
              </Col>
            </Row>

            <div className="row mt-3">
              <div className="col-sm-6 order-2 order-sm-1 text-center text-sm-left mb-4">
                <Button
                  color="danger"
                  onClick={() => history.push("/template/dispersion")}
                >
                  Cancelar
                </Button>
              </div>
              <div className="col-sm-6 order-1 order-sm-2 text-center text-sm-right mb-4">
                <Button color="success" type="submit">
                  Guardar
                </Button>
              </div>
            </div>
          </form>
          <ModalConfirmation
            modalTitle={"Crear"}
            modal={modal}
            setModal={setModal}
            crear={createPayrollclerk}
            isEdit={false}
            values={values}
            reset={resetForm}
          >
            <div className="d-flex justify-content-center">
              <h6>¿Desea continuar con el registro?</h6>
            </div>
          </ModalConfirmation>

          <Modal
            isOpen={modalErrors}
            toggle={closeModalErrors}
            scrollable={true}
            style={{ minWidth: "95%", maxHeight: "90%" }}
          >
            <ModalHeader
              toggle={closeModalErrors}
              close={CloseBtn}
            ></ModalHeader>
            <ModalBody>
              {errorsAdd.tipoError === "cuentas" ? (
                <>
                  <span className="text-center">Errores</span>
                  <Table
                    className={`table-striped table-borderless table-hover ${s.statesTable}`}
                    responsive
                  >
                    <thead className="border-bottom border-secondary">
                      <tr /* className={sc.userSelectNone} */>
                        <th className="text-center">Numero</th>
                        <th className="text-center">Error</th>
                      </tr>
                    </thead>
                    <tbody>
                      <>
                        {modalErrors &&
                          errorsAdd.msj.map((error, index) => (
                            <tr>
                              <td className="text-center">{index}</td>
                              <td className="text-center">{error}</td>
                            </tr>
                          ))}
                      </>
                    </tbody>
                  </Table>
                </>
              ) : (
                <div className="d-flex justify-content-around">
                  {errorsAdd.msj}
                </div>
              )}

              {/* <ol>
            
              </ol> */}
            </ModalBody>
            <ModalFooter>
              <div className="w-100 d-flex justify-content-around">
                <Button
                  className="btn-outline-secondary mr-3"
                  outline
                  onClick={closeModalErrors}
                >
                  Cerrar
                </Button>
              </div>
            </ModalFooter>
          </Modal>
        </div>
      ) : (
        <Loader />
      )}
    </Widget>
  );
};
export default CrearDispersion;

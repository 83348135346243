import { Modal, ModalHeader, ModalBody, Button, Row, Col } from "reactstrap"
import { useEffect, useState } from "react"
import { toast } from "react-toastify";
import { format } from "date-fns"
import { es } from "date-fns/locale"

import Notification from "../../../../components/Notification/Notification";
import { getFile } from "../Forms/functions";
import s from "../Forms/cell.module.css"

export default function GetXML({ idInventory, modal, setModal }) {

    const stateCFDI = "VIGENTE";
    const [data, setData] = useState({})


    const formatter = new Intl.NumberFormat("es-MX", {
        style: "currency",
        currency: "MXN",
    });

    function verifyCFDI(cfdi) {
        /*const url = `https://consultaqr.facturaelectronica.sat.gob.mx/ConsultaCFDIService.svc`;
        const soapRequest = `<?xml version="1.0" encoding="utf-8"?>
                            <soapenv:Envelope xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/" xmlns:tem="http://tempuri.org/">
                                <soapenv:Header/>
                                <soapenv:Body>
                                <tem:Consulta>
                                    <tem:expresionImpresa>${cfdi}</tem:expresionImpresa>
                                </tem:Consulta>
                                </soapenv:Body>
                            </soapenv:Envelope>`;
        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'text/xml;charset=UTF-8',
                'SOAPAction': 'http://tempuri.org/IConsultaCFDIService/Consulta'
            },
            body: soapRequest
        })
            .then(response => response.text())
            .then(data => setStateCFDI(data))
            .catch(error => console.error(error));*/
    };

    const getRFC = (att) => {
        try {
            return att.getNamedItem("Rfc").value
        } catch (err) {
            return att.getNamedItem("rfc").value
        }
    }

    const getNombre = (att) => {
        try {
            return att.getNamedItem("Nombre").value
        } catch (err) {
            return att.getNamedItem("nombre").value
        }
    }

    const getTotal = (att) => {
        try {
            return att.getNamedItem("Total").value
        } catch (err) {
            return att.getNamedItem("total").value
        }
    }

    const getFecha = (att) => {
        try {
            return att.getNamedItem("Fecha").value
        } catch (err) {
            return att.getNamedItem("fecha").value
        }
    }

    const getPAC = (att) => {
        try {
            return att.getNamedItem("RfcProvCertif").value
        } catch (err) {
            return att.getNamedItem("noCertificadoSAT").value
        }
    }

    const getTComp = (att) => {
        try {
            return att.getNamedItem("TipoDeComprobante").value
        } catch (err) {
            return att.getNamedItem("tipoDeComprobante").value
        }
    }

    async function getXML(id) {
        const fileType = 39;
        const src = await getFile(fileType, id);
        if (src) {
            fetch(src, {
                method: "GET",
                mode: 'cors',
                headers: {
                    'Content-Type': "application/x-www-form-urlencoded, multipart/form-data",
                },
            })
                .then(response => response.text())
                .then(data => {
                    const parser = new DOMParser();
                    const xmlDoc = parser.parseFromString(data, 'application/xml');
                    verifyCFDI(xmlDoc);
                    const value = xmlDoc.getElementsByTagName("cfdi:Comprobante")[0];
                    const childrens = [...value.children];
                    const emisor = childrens.filter(item => item.nodeName === "cfdi:Emisor")[0];
                    const receptor = childrens.filter(item => item.nodeName === "cfdi:Receptor")[0];
                    const complemento = childrens.filter(item => item.nodeName === "cfdi:Complemento")[0];
                    const rfcEmisor = getRFC(emisor.attributes);
                    const razonEmisor = getNombre(emisor.attributes);
                    const rfcReceptor = getRFC(receptor.attributes);
                    const razonReceptor = getNombre(receptor.attributes);
                    const folioFiscal = complemento.firstChild.attributes.getNamedItem("UUID").value
                    const fechaExp = getFecha(value.attributes);
                    const fechaCertSAT = complemento.firstChild.attributes.getNamedItem("FechaTimbrado").value
                    const PAC = getPAC(complemento.firstChild.attributes);
                    const totalCFDI = formatter.format(Number(getTotal(value.attributes)))
                    const efecto = getTComp(value.attributes)
                    setData({
                        rfcEmisor,
                        razonEmisor,
                        rfcReceptor,
                        razonReceptor,
                        folioFiscal,
                        fechaExp: format(new Date(fechaExp), "PPpp", { locale: es }),
                        fechaCertSAT: format(new Date(fechaCertSAT), "PPpp", { locale: es }),
                        PAC,
                        totalCFDI,
                        efecto
                    })
                })
                .catch(error => {
                    toast(<Notification type={"consultar_error"} withIcon />)
                    setModal(false);
                });
        } else {
            toast(<Notification type={"consultar_error"} withIcon />)
            setModal(false);
        }
    }

    useEffect(() => {
        if (modal) {
            getXML(idInventory);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [idInventory, modal])

    return (
        <>
            <Modal isOpen={modal} toggle={() => setModal(false)} fullscreen="lg" size="xl" backdrop="static" >
                <ModalHeader toggle={() => setModal(false)}>Visualización de factura</ModalHeader>
                <ModalBody>
                    <Row>
                        <Col xs={12} md={6} lg={3} className="p-0">
                            <div className="border-bottom border-primary text-center p-2">
                                RFC del emisor
                            </div>
                            <div className={`text-center p-2 ${s.cellCustom}`}>
                                {data.rfcEmisor}
                            </div>
                        </Col>
                        <Col xs={12} md={6} lg={3} className="p-0">
                            <div className="border-bottom border-primary text-center p-2">
                                Nombre o razón social del emisor
                            </div>
                            <div className={`text-center p-2 ${s.cellCustom}`}>
                                {data.razonEmisor}
                            </div>
                        </Col>
                        <Col xs={12} md={6} lg={3} className="p-0">
                            <div className="border-bottom border-primary text-center p-2">
                                RFC del receptor
                            </div>
                            <div className={`text-center p-2 ${s.cellCustom}`}>
                                {data.rfcReceptor}
                            </div>
                        </Col>
                        <Col xs={12} md={6} lg={3} className="p-0">
                            <div className="border-bottom border-primary text-center p-2">
                                Nombre o razón social del receptor
                            </div>
                            <div className={`text-center p-2 ${s.cellCustom}`}>
                                {data.razonReceptor}
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} md={6} lg={3} className="p-0">
                            <div className="border-bottom border-primary text-center p-2">
                                Folio fiscal
                            </div>
                            <div className={`text-center p-2 ${s.cellCustom}`}>
                                {data.folioFiscal}
                            </div>
                        </Col>
                        <Col xs={12} md={6} lg={3} className="p-0">
                            <div className="border-bottom border-primary text-center p-2">
                                Fecha de expedición
                            </div>
                            <div className={`text-center p-2 ${s.cellCustom}`}>
                                {data.fechaExp}
                            </div>
                        </Col>
                        <Col xs={12} md={6} lg={3} className="p-0">
                            <div className="border-bottom border-primary text-center p-2">
                                Fecha certificación SAT
                            </div>
                            <div className={`text-center p-2 ${s.cellCustom}`}>
                                {data.fechaCertSAT}
                            </div>
                        </Col>
                        <Col xs={12} md={6} lg={3} className="p-0">
                            <div className="border-bottom border-primary text-center p-2">
                                PAC que certifico
                            </div>
                            <div className={`text-center p-2 ${s.cellCustom}`}>
                                {data.PAC}
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} md={6} lg={4} className="p-0">
                            <div className="border-bottom border-primary text-center p-2">
                                Total del CFDI
                            </div>
                            <div className={`text-center p-2 ${s.cellCustom}`}>
                                {data.totalCFDI}
                            </div>
                        </Col>
                        <Col xs={12} md={6} lg={4} className="p-0">
                            <div className="border-bottom border-primary text-center p-2">
                                Efecto del comprobante
                            </div>
                            <div className={`text-center p-2 ${s.cellCustom}`}>
                                {data.efecto}
                            </div>
                        </Col>
                        <Col xs={12} md={6} lg={4} className="p-0">
                            <div className="border-bottom border-primary text-center p-2">
                                Estado CFDI
                            </div>
                            <div className={`text-center p-2 ${s.cellCustom}`}>
                                {stateCFDI}
                            </div>
                        </Col>
                    </Row>
                    <div className="text-center mt-2">
                        <Button color="danger" className="mx-1" onClick={() => setModal(false)}>Cerrar</Button>
                    </div>
                </ModalBody>
            </Modal>
        </>
    )
}
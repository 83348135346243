import {
  Document,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
  Path
} from "@react-pdf/renderer";
import { toast } from "react-toastify";
import Notification from "../../../../components/Notification/Notification";
import { peticionesReceiver } from "../../../../helpers/peticionesReceiver";
import gobLogo from "../../../../components/img/gobLogo.jpg";

const colors = {
  backgroundHeaders: "#646464",
};

const API = peticionesReceiver();

const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 35,
    paddingHorizontal: 10,
  },
  bordersBT: {
    borderWidth: 2,
    borderRightWidth: 0,
  },
  borders: {
    borderWidth: 2,
  },
  bordersLB: {
    borderLeftWidth: 2,
    borderBottomWidth: 2,
  },
  bordersBR: {
    borderWidth: 2,
    borderTopWidth: 0,
  },
  tableRow: {
    flexDirection: "row",
  },
  tableColumn: {
    flexDirection: "column",
  },

  /* containers */

  periodContainer: {
    backgroundColor: "black",
  },
  containerPhoto: {
    borderWidth: 2.5,
    borderRadius: 25,
  },
  /* ----------------------------ESTILOS DE TEXTO ------------------------------ */
  centerTextRow: {
    flexDirection: "row",
    justifyContent: "center-center",
    alignItems: "center",
    textAlign: "center",
  },
  centerText: {
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
  },
  alingTextsColumn: {
    flexDirection: "column",
    justifyContent: "space-around",
    alignItems: "center",
  },
  alingTextsRow: {
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
  },
  titleText: {
    fontSize: 14,
    fontFamily: "Helvetica-Bold",
  },
  contentText: {
    fontSize: 11,
    /* fontFamily: "Helvetica-Bold", */
  },
  periodText: {
    fontSize: 24,
    color: "white",
    fontFamily: "Helvetica-Bold",
  },
  titleSign: {
    fontSize: 13,
    fontFamily: "Helvetica-Bold",
  },
  contentTextSign: {
    fontSize: 8,
    /* fontFamily: "Helvetica-Bold", */
  },

  triangle: {
    position: "absolute",
    backgroundColor: "white",
    zIndex: "-10",
    top: -40,
    right: 1,
    width: 0,
    height: 0,
    borderLeft: "545px solid #fff",
    borderBottom: "340px solid #ccc",
  },
  triangleFullPage: {
    position: "absolute",
    backgroundColor: "white",
    zIndex: "-10",
    top: 390,
    right: 10,
    width: 0,
    height: 0,
    borderLeft: "700px solid #fff",
    borderBottom: "390px solid #ccc",
  },
  finalBack: {
    position: "absolute",
    backgroundColor: "red",
    zIndex: "-10",
    top: 280,
    right: 1,
    width: "100%",
    height: 20,
    borderBottomRightRadius: 270
    
  },
  triangle2: {
    position: "absolute",
    backgroundColor: "white",
    top: 0,
    left: 1,
    width: 0,
    height: 0,
    borderRigth: "1800px solid #fff",
    borderBottom: "1000px solid #FF0000",
  },
});

const insertNames = (nameTag) => {
  return (
    <View style={[styles.tableColumn]}>
      <View style={[styles.alingTextsColumn, { textAlign: "left" }]}>
        <Text style={[styles.titleText, { marginBottom: 20 }]}>
          {nameTag.name ? nameTag.name : "NOMBRE NO REGISTRADO"}{" "}
        </Text>
        <Text style={[styles.titleText, { marginBottom: 20 }]}>
          {nameTag.pName ? nameTag.pName : "APELLIDO NO REGISTRADO"}{" "}
        </Text>
        <Text style={[styles.titleText, { marginBottom: 20 }]}>
          {nameTag.mName ? nameTag.mName : "APELLIDO MATERNO NO REGISTRADO"}{" "}
        </Text>
      </View>
    </View>
  );
};
const insertNamesTitular = (nameTag) => {
  return (
    <View style={[styles.tableColumn]}>
      <View style={[styles.alingTextsColumn]}>
        <Text style={[styles.titleText]}>NOMBRE: </Text>
        <Text style={[styles.contentText]}>
          {nameTag.nameTitular
            ? nameTag.nameTitular
            : "NOMBRE-TITULAR NO REGISTRADO"}{" "}
        </Text>
        <Text style={[styles.contentText]}>
          {nameTag.pNameTitular
            ? nameTag.pNameTitular
            : "APELLIDO-TITULAR NO REGISTRADO"}{" "}
        </Text>

        <Text style={[styles.contentText]}>
          {nameTag.mNameTitular
            ? nameTag.mNameTitular
            : "APELLIDO MATERNO-TITULAR NO REGISTRADO"}{" "}
        </Text>
      </View>
      <View style={[styles.alingTextsColumn]}>
        <Text style={[styles.titleText]}>APELLIDO PATERNO: </Text>
      </View>
      <View style={[styles.alingTextsColumn]}>
        <Text style={[styles.titleText]}>APELLIDO MATERNO </Text>
      </View>
    </View>
  );
};

const insertColumn1A = () => {
  return (
    /* Logo Gobierno */
    <View style={styles.tableRow}>
      <View
        style={[
          styles.bordersBT,
          {
            width: "50%",
            height: "75",
            borderBottomWidth: "0",
            borderTopLeftRadius: "90",
          },
          styles.alingTextsColumn,
        ]}
      >
        <View>
          <Image src={gobLogo} style={{ width: "90%", height: "90%" }}></Image>
        </View>
      </View>
      {/* Titulo */}
      <View
        style={[
          styles.alingTextsColumn,
          {
            width: "50%",
            height: "75",
            borderWidth: "2",
            borderTopRightRadius: "90",
            borderLeftWidth: "0",
            borderBottomWidth: "0",
          },
        ]}
      >
        <View>
          <Text style={[styles.titleText]}>FISCALIA GENERAL DE JUSTICIA</Text>
          <Text style={[styles.titleText]}>DEL ESTADO DE MEXICO</Text>
        </View>
      </View>
    </View>
  );
};
//300
const insertColumn2A = (nameTag) => {
  return (
    <View style={styles.tableRow}>
      <View
        style={[
          { width: "50%", height: "300", borderLeftWidth: "2" },
          styles.alingTextsColumn,
        ]}
      >
        <View
          style={[
            styles.containerPhoto,
            { width: "75%", height: "75%", marginTop: "5" },
          ]}
        >
          <Image
            source={{ uri: nameTag.foto }}
            style={{
              borderRadius: "23",
              minWidth: "100%",
              width: "100%",
              maxWidth: "100%",
              height:"100%"
            }}
          />
        </View>
      </View>
      <View style={[{ width: "50%", height: "300", borderRightWidth: "2" }]}>
        <View style={[styles.tableColumn, { height: "50%", marginTop: "50" }]}>
          {/* --------------------------------------CURP----------------------------------------- */}
          <View
            style={[
              /* styles.alingTextsColumn */ { height: "100%", width: "100%" },
            ]}
          >
            <View
              style={
                [
                  /* { height: "50%" } ,*/
                  /* styles.alingTextsColumn */
                ]
              }
            >
              <Text style={[styles.contentText]}>CURP </Text>
            </View>
            <View
              style={
                [
                  /* { height: "50%" } */
                ]
              }
            >
              <Text style={styles.titleText}>
                {nameTag.curp ? nameTag.curp : "CURP NO REGISTRADO"}
              </Text>
            </View>
          </View>

          {/* --------------------------------------CARGO----------------------------------------- */}
          <View
            style={[
              /* styles.alingTextsColumn */ { height: "100%", width: "100%" },
            ]}
          >
            <View
              style={[
                {
                  /* height: "50%" */
                } /* styles.alingTextsColumn */,
              ]}
            >
              <Text style={[styles.contentText]}>CARGO</Text>
            </View>
            <View
              style={[
                {
                  /* height: "50%" */
                },
              ]}
            >
              <Text style={styles.titleText}>
                {nameTag.nameJob ? nameTag.nameJob : "EMPLEO NO REGISTRADO"}{" "}
              </Text>
            </View>
          </View>
        </View>

        {/* ------------------ FIRMA --------------------- */}
        <View style={[styles.tableColumn]}>
          <View /* style={[styles.centerText]} */>
            <Text style={styles.titleText}>FIRMA DEL INTERESADO</Text>
          </View>
          <View
            style={[
              {
                width: "70%",
                height: "70%",
                marginTop: "10",
              },
            ]}
          >
            <View /* style={[styles.centerText]} */>
              <Image
                src={nameTag.firma}
                style={{ width: "90%", height: "90%" }}
              />
            </View>
          </View>
        </View>
      </View>
    </View>
  );
};

const insertColumn3A = (nameTag) => {
  const origin = window.location.origin;
  return (
    <View style={styles.tableRow}>
      {/* Informacion personal */}
      <View style={[{ width: "50%", height: "250", borderLeftWidth: "2" }]}>
        {insertNames(nameTag)}
      </View>

      <View
        style={[
          { width: "50%", height: "250", borderRightWidth: "2" },
          styles.alingTextsColumn,
        ]}
      >
        <Image
          src={`${origin}/images/logo.png`}
          style={[{ width: "50%", height: "150" }, styles.alingTextsColumn]}
        />
      </View>
    </View>
  );
};
//50
const insertColumn4A = (nameTag) => {
  return (
    <View style={styles.tableRow}>
      <View
        style={[
          styles.bordersBR,
          {
            width: "100%",
            height: "50",
            borderBottomLeftRadius: 90,
            borderBottomRightRadius: 90,
          },
        ]}
      >
        <View style={styles.alingTextsColumn}>
          <Text
            style={styles.titleText}
          >Av. J. M. Morelos Y P. 4 Ote. 1300, San Sebastián, 50090 Toluca de Lerdo, Méx.</Text>
          <Text style={styles.titleText}>
            Telefono: 722 226 1600
          </Text>
        </View>
      </View>
    </View>
  );
};

//===725

//75
const insertColumn1B = () => {
  const origin = window.location.origin;
  return (
    <View style={styles.tableRow}>
      <View
        style={[
          {
            width: "50%",
            height: "75",
            borderLeftWidth: "2",
            borderTopWidth: "2",
            borderTopLeftRadius: 90,
          },
          styles.alingTextsColumn,
        ]}
      >
        <View style={{marginTop:"5"}}>
          <Image src={gobLogo} style={{ width: "90%", height: "90%" }}></Image>
        </View>
      </View>
      <View
        style={[
          styles.alingTextsColumn,
          { width: "16.67%", height: "75", borderTopWidth: "2" },
        ]}
      ></View>
      <View
        style={[
          styles.alingTextsColumn,
          {
            width: "33.33%",
            height: "75",
            borderWidth: "2",
            borderLeftWidth: "0",
            borderBottomWidth: "0",
            borderTopRightRadius: 90,
          },
        ]}
      >
        <View style={{marginTop:"5"}}>
          <Image
            style={{ width: "75", marginLeft: "auto" }}
            src={`${origin}/images/logo.png`}
          />
        </View>
      </View>
    </View>
  );
};
//50
const insertColumn2B = (nameTag) => {
  return (
    <View style={styles.tableRow}>
      <View
        style={[
          {
            width: "100%",
            height: "50",
            borderLeftWidth: "2",
            borderRightWidth: "2",
          },
        ]}
      >
        <View style={[styles.alingTextsRow, { marginTop: "5" }]}>
          <Text style={[styles.titleText, { height: "50%" }]}>
            {" "}
            PERIODO {nameTag.folio}
          </Text>
        </View>
      </View>
    </View>
  );
};
//275
const insertColumn3B = (model, nameTag) => {
  return (
    <View style={styles.tableRow}>
      <View style={[{ width: "50%", height: "275", borderLeftWidth: "2" }]}>
        <View style={[styles.tableColumn, { height: "100%", marginTop: 60 }]}>
          {/* --------------------------------------NOMBRES----------------------------------------- */}
          <View style={[styles.alingTextsColumn]}>{insertNames(nameTag)}</View>

          {/* --------------------------------------CARGO----------------------------------------- */}
          <View style={[styles.alingTextsColumn]}>
            <View style={[styles.alingTextsColumn]}>
              <Text style={[styles.contentText, { marginBottom: 15 }]}>
                NUMERO DE GAFETE
              </Text>
              <Text style={[styles.titleText, { fontSize: 16 }]}>
                {nameTag.folio}
              </Text>
            </View>
          </View>
        </View>
      </View>

      <View style={[{ width: "50%", height: "275", borderRightWidth: "2" }]}>
        {/* <Image src={persona1} style={{ width: "100%", height: "100%" }}></Image> */}
        <Image
          src={model.pngUrl}
          style={{ width: "100%", height: "100%", padding: "10" }}
        />
      </View>
    </View>
  );
};
//275
const insertColumn4B = (nameTag) => {
  return (
    <View style={styles.tableRow}>
      <View
        style={[
          styles.alingTextsRow,
          {
            width: "100%",
            height: "275",
            borderLeftWidth: "2",
            borderRightWidth: "2",
          },
        ]}
      >
        <View style={[{ width: "50%", height: "80%" }]}>
          <View>
            <Image
              src={nameTag.firma}
              style={{
                width: "100%",
                height: "100%",
                padding: "10",
              }}
            />
          </View>
          <View style={[styles.tableColumn, { marginBottom: "5" }]}>
            <View style={[styles.alingTextsColumn, { marginBottom: "2" }]}>
              <Text
                style={[
                  styles.titleSign,
                  { textAlign: "center", marginBottom: 10 },
                ]}
              >
                {nameTag.titulo !== "" ? nameTag.titulo : " "}{" "}
                {`${nameTag.nameTitular} ${nameTag.pNameTitular} ${nameTag.mNameTitular}`}
              </Text>
              <Text style={[styles.titleSign, { textAlign: "center" }]}>
                {nameTag.nameJobTitular}
              </Text>
              {/* <Text
                style={[
                  styles.contentTextSign,
                  { width: "70%", textAlign: "left" },
                ]}
              >
                {nameTag.titulo !== "" ? nameTag.titulo : "POR ASIGNAR"}
              </Text> */}
            </View>
            {/* <View style={[styles.alingTextsRow, { marginBottom: "2" }]}>
              <Text
                style={[styles.titleSign, { width: "30%", textAlign: "right" }]}
              >
                TITULO:{" "}
              </Text>
              <Text
                style={[
                  styles.contentTextSign,
                  { width: "70%", textAlign: "left" },
                ]}
              >
                {nameTag.titulo !== "" ? nameTag.titulo : "POR ASIGNAR"}
              </Text>
            </View>
            <View style={[styles.alingTextsRow, { marginBottom: "2" }]}>
              <Text
                style={[styles.titleSign, { width: "30%", textAlign: "right" }]}
              >
                NOMBRE:{" "}
              </Text>
              <Text
                style={[
                  styles.contentTextSign,
                  { width: "70%", textAlign: "left" },
                ]}
              >{`${nameTag.name} ${nameTag.pName} ${nameTag.mName}`}</Text>
            </View>
            <View style={[styles.alingTextsRow, { marginBottom: "2" }]}>
              <Text
                style={[styles.titleSign, { width: "30%", textAlign: "right" }]}
              >
                CARRERA:{" "}
              </Text>
              <Text
                style={[
                  styles.contentTextSign,
                  { width: "70%", textAlign: "left" },
                ]}
              >
                {nameTag.carrera !== "" ? nameTag.carrera : "POR ASIGNAR"}{" "}
              </Text>
            </View>
            <View style={[styles.alingTextsRow, { marginBottom: "2" }]}>
              <Text
                style={[styles.titleSign, { width: "30%", textAlign: "right" }]}
              >
                PUESTO:{" "}
              </Text>
              <Text
                style={[
                  styles.contentTextSign,
                  { width: "70%", textAlign: "left" },
                ]}
              >
                {nameTag.nameJob}{" "}
              </Text>
            </View> */}
          </View>
        </View>
      </View>
    </View>
  );
};
//50
const insertColumn5B = () => {
  return (
    <View style={styles.tableRow}>
      <View
        style={[
          styles.bordersBR,
          {
            width: "100%",
            height: "50",
            borderBottomLeftRadius: 90,
            borderBottomRightRadius: 90,
          },
        ]}
      >
        <View style={styles.alingTextsColumn}>
          <Text style={[styles.titleText, { fontSize: "12" }]}>
            ESTE GAFETE SERA PORTADO POR EL SERVIDOR PÚBLICO DE MANERA VISIBLE
            MIENTRAS
          </Text>
          <Text style={[styles.titleText, { fontSize: "12" }]}>
            PERMANEZCA DENTRO DE LAS INSTALACIONES O BIEN, MIENTRAS REALICE UNA
            FUNCIÓN OFICIAL FUERA DE LAS MISMAS QUIEN NO LO PORTE SERA
            SANCIONADO
          </Text>
        </View>
      </View>
    </View>
  );
};

const getNameTag = async (idNameTags) => {
  let data = [];
  const params = {
    action: "getNameTagTemplate",
    rows: {
      idNameTags: idNameTags,
    },
  };
  await API.peticionEndPoint(params, "app/facades/employees/nameTagsF.php")
    .then((res) => {
      if (res.data.status === "success") {
        data = res.data.data[0];
      } else {
        toast(
          <Notification
            type={"consultar_error"}
            backMessage={res.data.message}
            withIcon
          />
        );
      }
    })
    .catch((err) => {
      toast(<Notification type={"consultar_servidor_error"} withIcon />, {
        closeButton: false,
      });
    });

  return data;
};

const insertPeriod = (nameTag) => {
  return (
    <View
      style={[styles.tableRow, { borderLeftWidth: "2", borderRightWidth: "2" }]}
    >
      <View
        style={[
          styles.periodContainer,
          styles.alingTextsRow,
          {
            width: "100%",
            height: "50",
            marginTop: 20,
          },
        ]}
      >
        <View>
          <Text style={[styles.periodText]}>
            {`${+nameTag.periodo} - ${+nameTag.periodo + 1}`}
          </Text>
        </View>
      </View>
    </View>
  );
};

export async function generarGafete(data, model, title, fileNo) {
  const nameTag = await getNameTag(model.idNameTags);
  /* fetch(nameTag.foto)
    .then(res => res.blob())
    .then(blod => console.log("BLOOD",blod)) */

  return {
    report: (
      <>
        <Document>
          <Page style={styles.body}>
            {/* 75 */}
            {insertColumn1A()}
            {/* 50 */}
            {insertPeriod(nameTag)}
            {/* 300 */}
            {insertColumn2A(nameTag)}

            {/* behind */}
            <View style={[styles.triangleFullPage]}></View>
            {/* 250 */}
            {insertColumn3A(nameTag)}
            {/* 50 */}
            {insertColumn4A(nameTag)}

            {/* 725 */}
          </Page>
          <Page style={styles.body}>

            {/* 75 */}
            {insertColumn1B(nameTag)}

            {/* 50 */}
            {insertPeriod(nameTag)}

            {/* 275 */}
            {insertColumn3B(model, nameTag)}

            {/* 275 */}
            {insertColumn4B(nameTag)}

            {/* 50 */}
            {insertColumn5B(nameTag)}

            {/* 725 */}
          </Page>
        </Document>
      </>
    ),
  };
}

import React, { useEffect, useState } from "react";
import { Button, Alert, Col, Row } from "reactstrap";
import { peticionesReceiver } from "../../../../helpers/peticionesReceiver";
import ModalDelete from "../../../../components/Modal/ModalDelete";
import Datatable from "../../../../components/Datatable/Datatable";
import { toast } from "react-toastify";
import Notification from "../../../../components/Notification/Notification";

import { GetPermissions } from "../../../../libs/permissions/getPermissions";
import { SelectTypeHeadSingle } from "../../../../components/GenericInputsFormik";

import { Loader } from "react-bootstrap-typeahead";

import Widget from "../../../../components/Widget/Widget";
import { useHistory } from "react-router-dom";

const GruposIncidencias = (props) => {
  const [permissions, setPermissions] = useState(true);
  const API = peticionesReceiver();

  const [data, setData] = useState("");
  const [loading, setLoading] = useState(false);
  const [autorizedUsers, setautorizedUsers] = useState([]);
  const [idStaffAutorized, setidStaffAutorized] = useState("0");
  const history = useHistory()

  GetPermissions(setPermissions, setLoading);

  const [refresh, setRefresh] = useState(false);

  const headers = ["#","Autorizador","Nombre del personal", "Eliminar"];

  useEffect(() => {
    getAutorizedUser();
  }, []);

  const getAutorizedUser = () => {
    const data = {
      action: "multiselect",
      table: "incidentsgroups A INNER JOIN staff B on (A.idStaffAutorized = B.idStaff)",
      rows: "A.idStaffAutorized, concat(B.name,' ', B.pName,' ',B.mName) as fullName",
      conditions: `A.enabled = 1 and B.enabled = 1 `,
      group: "A.idStaffAutorized"
    };
    console.log(data)
    API.peticion(data)
      .then((res) => {
        if (res.status === 200 && res.data.status === "success") {
          console.log(res)
          setautorizedUsers(res.data.data);
        } else {
          toast(
            <Alert color="danger">
              Error al consultar Usuarios Autorizados
            </Alert>
          );
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const columns = [
    { data: "index" },
    {data: "fullNameAutorized"},
    { data: "fullName" },
    {
      data: null,
      render: function (row) {
        return permissions.DEL ? (
          <>
            <ModalDelete
              modalTitle="Eliminar"
              id={row.idSetup}
              table={[""]}
              nameColumn={[""]}
              elimina={() => deleteIncidentGroup(row)}
              setRefresh={setRefresh}
              type="icon"
            >
              <h6>¿Seguro de eliminar el registro?</h6>
              No podrá recuperar la información después de ser eliminada
            </ModalDelete>
          </>
        ) : (
          "Accion no permitida"
        );
      },
    },
  ];

  const deleteIncidentGroup = async (obj) => {
    const params = {
      action: "delete",
      table: "incidentsgroups",
      condition: { idIncidentGroups: obj.idIncidentGroups },
    };

    await API.peticion(params)
      .then((res) => {
        if (res.data.status === "success" && res.data.code === "200") {
          toast(
            <Notification
              type={"elimina_exito"}
              backMessage={res.data.message}
              withIcon
            />
          );
          setRefresh(true);
        } else {
          toast(
            <Notification
              type={"elimina_error"}
              backMessage={res.data.message}
              withIcon
            />,
            { closeButton: false }
          );
        }
      })
      .catch((err) => {
        toast(<Notification type={"consultar_servidor_error"} withIcon />);
      });
  };

  const columnDefs = [
    {
      targets: [0, 1, 2, 3, 4, 5],
      className: "text-center",
    },
    {
      orderable: false,
      targets: [0],
    },
  ];

  const getParamsTableElmt = (order, records, page, search) => {
    const params = {
      action: "datatable",
      table: "incidentsgroups A INNER JOIN staff B on (A.idStaff = B.idStaff) INNER JOIN staff C on (A.idStaffAutorized = C.idStaff and C.enabled = 1) ",
      rows: "A.idIncidentGroups, concat(B.name,' ', B.pName,' ',B.mName) as fullName, concat(C.name,' ', C.pName,' ',C.mName) as fullNameAutorized ",
      conditions: `A.enabled = 1 and B.enabled = 1 and A.idStaffAutorized = ${idStaffAutorized}`,
      order,
      records: records.toString(),
      page: (page * records).toString(),
      search,
    };
    return params;
  };

  async function getData(order, records, page, search) {
    try {
      const res = await API.peticion(
        getParamsTableElmt(order, records, page, search)
      );
      if (res.status === 200 && res.data.code === "200") {
        const data = res.data;

        data.data.forEach((value, index) => (value.index = 1 + index));
        return data;
      } else {
        toast(
          <Notification
            type={"consultar_error"}
            backMessage={res.data.message}
          />
        );
        return null;
      }
    } catch (err) {
      toast(<Notification type={"consultar_servidor_error"} withIcon />);
      return null;
    }
  }

  const changeFilter = (event) => {
    const { name, value } = event.target;
    setidStaffAutorized(value);
  };

  useEffect(() => {
    setRefresh(true);
  }, [idStaffAutorized]);

  return (
    <Widget className={"widget-p-md"}>
      {loading ? (
        <Loader />
      ) : (
        <>
          {permissions.CON ? (
            <>
              <div className="text-center mb-5">
                <h1>Grupos de Incidencias</h1>
              </div>

              <div>
                <Row className="justify-content-center align-items-center mb-3">
                <Col xs={12} lg={5} className="text-center">
                    <Button onClick={() => {history.push("/template/grupos-incidencias/crear")}} color="success">Agregar Usuario autorizador</Button>
                  </Col>                  
                </Row>
                <Row>
                  <h2>Filtro por usuario autorizador</h2>

                </Row>
                <Row className="justify-content-center align-items-center"> 
                <Col xs={12} lg={5}>
                    <SelectTypeHeadSingle
                      label="Usuario autorizador"
                      isRequired={true}
                      inputName="filterKeyTypeUnderGroup"
                      optionsArray={autorizedUsers}
                      defaultOption="Seleccione un tipo de grupo"
                      onChangeMethod={(event) => {
                        changeFilter(event);
                      }}
                      isDisabled={false}
                      value={idStaffAutorized}
                      optionValue="idStaffAutorized"
                      optionName="fullName"
                    />
                  </Col>
                  

                </Row>

                <Row >
                <Datatable
                      headers={headers}
                      columns={columns}
                      columnDefs={columnDefs}
                      petition={getData}
                      control="back"
                      stateRefresh={[refresh, setRefresh]}
                      searching={false}
                      order={{ col: 1, opt: "desc" }}
                    />

                </Row>
              </div>
            </>
          ) : (
            "No tiene permisos para consultar  este modulo"
          )}
        </>
      )}
    </Widget>
  );
};

export default GruposIncidencias;
import Widget from "../../../../components/Widget/Widget";
import FormDiscapacidades from "../FormDiscapacidades";
import { editar } from "../functions";

const EditarDiscapacidad = (props) => {
  return (
    <div>
      <Widget className="widget-p-md">
        <div className="mb-12">
          <h1 className=" text-center tt-responsive">Editar Discapacidades</h1>
        </div>
        <FormDiscapacidades
          data={localStorage.getItem("dataContent").split("|-|")}
          editar={editar}
        />
      </Widget>
    </div>
  );
};

export default EditarDiscapacidad;

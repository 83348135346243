import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { Row, Col, FormGroup, Label, Button, Collapse } from "reactstrap";
import { Formik, Form, Field, ErrorMessage, useFormikContext } from "formik";

import { peticionesReceiver } from "../../../helpers/peticionesReceiver";

import Notification from "../../../components/Notification/Notification";
import ModalConfirmation from "../../../components/Modal/ModalConfirmation";
import { SelectTypeHeadSingle } from "../../../components/GenericInputsFormik";
import TableComponentOnePage from "../../../components/TableComponent/TableComponentOnePage";

const defaultForm = {
  idUnder: "",
  nameUnder: "",
  under: "",
  idExpenseObject: "",
  idAccountList: "",
  budget: "1",
  keyTypeUnder: "",
  idUnderSAT: "",
  keyRollUnder: "",
};

const FormSchema = Yup.object().shape({
  nameUnder: Yup.string()
    .min(3, "Nombre de concepto muy corto")
    .max(200, "Nombre de concepto invalido")
    .required("Ingrese el nombre del concepto"),
  under: Yup.string()
    .min(3, "Concepto invalido")
    .max(200, "Concepto invalido")
    .required("Ingrese el concepto"),
  budget: Yup.string(),
  keyTypeUnder: Yup.string().required("Seleccione un tipo de concepto"),
  idUnderSAT: Yup.string().required("Seleccione un concepto del SAT"),
  idExpenseObject: Yup.string().when("budget", {
    is: "1",
    then: Yup.string().required("Seleccione un COG")
  }),
  idAccountList: Yup.string().when("budget", {
    is: "0",
    then: Yup.string().required("Seleccione una lista de cuentas")
  }),
  keyRollUnder: Yup.string().required("Seleccione una aplicación"),
});

const ConceptosNomina = ({ permissions }) => {

  const API = peticionesReceiver();
  const [isOpen, setIsOpen] = useState(false);
  const [modal, setModal] = useState(false);
  const [title, setTitle] = useState("");
  const [loader, setLoader] = useState(true);
  const [info, setInfo] = useState(defaultForm);
  const [tiposConcepto, setTiposConcepto] = useState([]);
  const [conceptosSAT, setConceptosSAT] = useState([]);
  const [aplicaciones, setAplicaciones] = useState([]);
  const [listaCuentas, setListaCuentas] = useState([]);
  const [cogs, setCogs] = useState([]);
  const cabeceras = [
    "Clave",
    "Nombre",
    "Concepto",
    "Cog/Lista cuentas",
    "Gasto",
    "Tipo concepto",
    "Editar",
    "Eliminar",
  ];
  const filtro = [true, true, true, true, true, true];
  const [parametros, setParametros] = useState({
    action: "datatable",
    table:
      "unders A INNER JOIN type_unders B ON A.keyTypeUnder = B.keyTypeUnder LEFT JOIN expense_objects C ON A.idExpenseObject = C.idExpenseObject LEFT JOIN account_list D ON A.idAccountList = D.idAccountList",
    rows: "A.idUnder,A.nameUnder,A.under,if(A.budget = 1, C.nameObject, D.nameAccountList) AS obAc,if(A.budget = 1, 'Si', 'No') AS budgetif,B.nameTypeUnder,A.keyTypeUnder,A.idUnderSAT,A.keyRollUnder,A.idExpenseObject,A.idAccountList",
    conditions: "A.enabled = 1",
    page: 0,
    records: 5,
    search: "",
    order: "",
  });

  useEffect(() => {
    if (isOpen) {
      setLoader(true);
      getTiposonceptos().finally(() => {
        getConceptosSAT().finally(() => {
          getListaCuentas().finally(() => {
            getCogs().finally(() => {
              getAplicaciones();
            });
          });
        });
      });
    }
  }, [isOpen]);

  const editInfo = (values, reset) => {
    const rows = {
      idUnder: values.idUnder,
      nameUnder: values.nameUnder,
      under: values.under,
      budget: values.budget,
      keyTypeUnder: values.keyTypeUnder,
      idUnderSAT: values.idUnderSAT,
      keyRollUnder: values.keyRollUnder,
    };
    if(rows.budget === "1"){
      rows["idExpenseObject"] = values.idExpenseObject;
    }else{
      rows["idAccountList"] = values.idAccountList;
    }
    const params = {
      action: "",
      table: "unders",
      rows: rows,
      validate: [["nameUnder", "under"]],
    };
    if (values.idUnder) {
      params.action = "update";
      params.condition = { idUnder: values.idUnder };
    } else {
      params.action = "insert";
    }
    API.peticion(params)
      .then((res) => {
        if (res.status === 200 && res.data.code === "200") {
          toast(
            <Notification
              type={"modifica_exito"}
              backMessage={res.data.message}
              withIcon
            />
          );
          setParametros({ ...parametros });
          setTitle("");
          setIsOpen(false);
          reset();
        } else {
          toast(
            <Notification
              type={"modifica_error"}
              backMessage={res.data.message}
              withIcon
            />
          );
        }
      })
      .catch((err) => {
        toast(<Notification type={"consultar_servidor_error"} withIcon />);
      });
  };

  const openForm = () => {
    setTitle("Agregar concepto de nómina");
    setIsOpen(true);
  };

  const getForEdit = (el) => {
    if (title === "") {
      setInfo({
        idUnder: el[0],
        nameUnder: el[1],
        under: el[2],
        idExpenseObject: el[9],
        idAccountList: el[10],
        budget: el[4] === "Si" ? "1" : "0",
        keyTypeUnder: el[6],
        idUnderSAT: el[7],
        keyRollUnder: el[8],
      });
      setTitle("Editar concepto de nómina");
      setIsOpen(true);
    } else {
      toast(
        <Notification
          type="warning"
          backMessage="Guarde sus cambios antes de continuar"
        />,
        { closeButton: false }
      );
    }
  };

  const getTiposonceptos = async () => {
    const params = {
      action: "select",
      table: "type_unders",
      order: "nameTypeUnder ASC",
    };
    await API.peticion(params)
      .then((res) => {
        if (res.status === 200 && res.data.code === "200") {
          let data = [];
          res.data.data.map((el) => {
            data.push({
              keyTypeUnder: el.keyTypeUnder,
              nameTypeUnder: el.nameTypeUnder,
            });
          });
          setTiposConcepto(data);
        } else {
          toast(
            <Notification
              type={"consultar_error"}
              backMessage={res.data.message}
              withIcon
            />
          );
        }
      })
      .catch((err) => {
        toast(<Notification type={"consultar_servidor_error"} withIcon />);
      });
  };

  const getConceptosSAT = async () => {
    const params = {
      action: "select",
      table: "underssat",
      order: "nameUnderSAT ASC",
    };
    await API.peticion(params)
      .then((res) => {
        if (res.status === 200 && res.data.code === "200") {
          let data = [];
          res.data.data.map((el) => {
            data.push({
              idUnderSAT: el.idUnderSAT,
              nameUnderSAT: `${el.underSAT} - ${el.nameUnderSAT}`,
            });
          });
          setConceptosSAT(data);
        } else {
          toast(
            <Notification
              type={"consultar_error"}
              backMessage={res.data.message}
              withIcon
            />
          );
        }
      })
      .catch((err) => {
        toast(<Notification type={"consultar_servidor_error"} withIcon />);
      });
  };

  const getAplicaciones = async () => {
    const params = {
      action: "select",
      table: "rolloutunders",
      order: "nameRollUnder ASC",
    };
    await API.peticion(params)
      .then((res) => {
        if (res.status === 200 && res.data.code === "200") {
          let data = [];
          res.data.data.map((el) => {
            data.push({
              keyRollUnder: el.keyRollUnder,
              nameRollUnder: el.nameRollUnder,
            });
          });
          setAplicaciones(data);
        } else {
          toast(
            <Notification
              type={"consultar_error"}
              backMessage={res.data.message}
              withIcon
            />
          );
        }
        setLoader(false);
      })
      .catch((err) => {
        toast(<Notification type={"consultar_servidor_error"} withIcon />);
        setLoader(false);
      });
  };

  const getListaCuentas = async () => {
    const params = {
      action: "select",
      table: "account_list",
      order: "nameAccountList ASC",
    };
    await API.peticion(params)
      .then((res) => {
        if (res.status === 200 && res.data.status === "success") {
          let data = [];
          res.data.data.map((el) => {
            data.push({
              idAccountList: el.idAccountList,
              nameAccountList: el.nameAccountList,
              fullKey: el.fullKey,
            });
          });
          setListaCuentas(data);
        } else {
          toast(
            <Notification
              type={"consultar_error"}
              backMessage={res.data.message}
              withIcon
            />
          );
        }
        setLoader(false);
      })
      .catch((err) => {
        toast(<Notification type={"consultar_servidor_error"} withIcon />);
        setLoader(false);
      });
  };

  const getCogs = async () => {
    const params = {
      action: "select",
      table: "expense_objects",
      order: "nameObject ASC",
    };
    await API.peticion(params)
      .then((res) => {
        if (res.status === 200 && res.data.code === "200") {
          const data = res.data.data.map((el) => {
            return {
              idExpenseObject: el.idExpenseObject,
              fullKey: el.fullKey,
              nameObject: el.nameObject,
            }
          });
          setCogs(data);
        } else {
          toast(
            <Notification
              type={"consultar_error"}
              backMessage={res.data.message}
              withIcon
            />
          );
        }
        setLoader(false);
      })
      .catch((err) => {
        toast(<Notification type={"consultar_servidor_error"} withIcon />);
        setLoader(false);
      });
  };

  const limpia = (reset) => {
    setTitle("");
    setIsOpen(false);
    reset()
  }

  return (
    <div>
      <Formik
        initialValues={defaultForm}
        validationSchema={FormSchema}
        onSubmit={() => {
          setModal(true);
        }}
        onReset={() => {}}
      >
        {({ errors, touched, values, resetForm,setFieldValue,handleBlur }) => (
          <div>
            {permissions.CON && (
            <TableComponentOnePage
              titulo={"Conceptos de nómina"}
              cabecerasTabla={cabeceras}
              filtro={filtro}
              editar={getForEdit}
              parametros={parametros}
              limpia={limpia}
              reset={resetForm}
              permissions={permissions}
              enableContador={false}
            />
            )}
            <div className="row mt-2">
              <div className="col-12 col-sm-6">
                <h5 className="text-center text-sm-left">{title}</h5>
              </div>
              {permissions.INS && <div className="col-12 col-sm-6">
                <div className="text-center text-sm-right">
                  <Button
                    color="add"
                    onClick={() => openForm()}
                    disabled={title !== ""}
                  >
                    Agregar
                  </Button>
                </div>
              </div>}
            </div>

            <Collapse isOpen={isOpen}>
              <Form className="mt-4">
                <ModalConfirmation
                  modalTitle={title}
                  modal={modal}
                  setModal={setModal}
                  editar={editInfo}
                  isEdit={true}
                  values={values}
                  reset={resetForm}
                >
                  <div className="d-flex justify-content-center">
                    <h6>¿Está seguro de guardar el registro?</h6>
                  </div>
                </ModalConfirmation>
                <Row>
                  <Col xs={12} lg={6}>
                    <Label>¿Es presupuestal?</Label>
                    <FormGroup>
                      <Label>
                        <Field type="radio" name="budget" value="1">
                          {({ field }) => (
                            <FormGroup className="radio abc-radio abc-radio-primary">
                              <input
                                type="radio"
                                {...field}
                                id="r1"
                                className="form-check form-check-inline"
                              />
                              <Label htmlFor="r1">Si</Label>
                            </FormGroup>
                          )}
                        </Field>
                      </Label>
                      <Label>
                        <Field type="radio" name="budget" value="0">
                          {({ field }) => (
                            <FormGroup className="radio abc-radio abc-radio-info">
                              <input
                                type="radio"
                                {...field}
                                id="r0"
                                className="form-check form-check-inline"
                              />
                              <Label htmlFor="r0">No</Label>
                            </FormGroup>
                          )}
                        </Field>
                      </Label>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} lg={6}>
                    <FormGroup>
                      <Label for="nameUnder">
                        Nombre de concepto<span className="text-danger">*</span>
                      </Label>
                      <Field
                        type="text"
                        name="nameUnder"
                        className={
                          "form-control" +
                          (errors.nameUnder && touched.nameUnder
                            ? " is-invalid"
                            : "")
                        }
                      />
                      <ErrorMessage
                        name="nameUnder"
                        component="div"
                        className="invalid-feedback"
                      />
                    </FormGroup>
                  </Col>
                  <Col xs={12} lg={6}>
                    <FormGroup>
                      <Label for="under">
                        Concepto<span className="text-danger">*</span>
                      </Label>
                      <Field
                        type="text"
                        name="under"
                        className={
                          "form-control" +
                          (errors.under && touched.under ? " is-invalid" : "")
                        }
                      />
                      <ErrorMessage
                        name="under"
                        component="div"
                        className="invalid-feedback"
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} lg={6}>
                    <SelectTypeHeadSingle
                      label="Tipo de concepto"
                      isRequired={true}
                      inputName="keyTypeUnder"
                      optionsArray={tiposConcepto}
                      defaultOption="Seleccione un tipo de concepto"
                      onChangeMethod={(event) =>
                        {
                          const {name,value} = event.target
                          setFieldValue(name, value)
                        }
                      }
                      onBlur={handleBlur}
                      isDisabled={false}
                      value={values.keyTypeUnder}
                      touched={touched.keyTypeUnder}
                      errors={errors.keyTypeUnder}
                      optionValue="keyTypeUnder"
                      optionName="nameTypeUnder"
                    />
                  </Col>
                  <Col xs={12} lg={6}>
                    <SelectTypeHeadSingle
                      label="Concepto SAT"
                      isRequired={true}
                      inputName="idUnderSAT"
                      optionsArray={conceptosSAT}
                      defaultOption="Seleccione un concepto SAT"
                      onChangeMethod={(event) =>
                        {
                          const {name,value} = event.target
                          setFieldValue(name, value)
                        }
                      }
                      onBlur={handleBlur}
                      isDisabled={false}
                      value={values.idUnderSAT}
                      touched={touched.idUnderSAT}
                      errors={errors.idUnderSAT}
                      optionValue="idUnderSAT"
                      optionName="nameUnderSAT"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} lg={6}>
                    {
                      values.budget === "1" && 
                      <SelectTypeHeadSingle
                          label="COG"
                          isRequired={true}
                          inputName="idExpenseObject"
                          optionsArray={cogs}
                          defaultOption="Seleccione un COG"
                          onChangeMethod={(event) =>
                            {
                              const {name,value} = event.target
                              setFieldValue(name, value)
                            }
                          }
                          onBlur={handleBlur}
                          isDisabled={false}
                          value={values.idExpenseObject}
                          touched={touched.idExpenseObject}
                          errors={errors.idExpenseObject}
                          multipleLabels={["fullKey","nameObject"]}
                          optionValue="idExpenseObject"
                          optionName = "name"
                        />
                    }
                    {
                      values.budget === "0" && 
                        <SelectTypeHeadSingle
                          label="Lista cuentas"
                          isRequired={true}
                          inputName="idAccountList"
                          optionsArray={listaCuentas}
                          defaultOption="Seleccione una lista cuentas"
                          onChangeMethod={(event) =>
                            {
                              const {name,value} = event.target
                              setFieldValue(name, value)
                            }
                          }
                          onBlur={handleBlur}
                          isDisabled={false}
                          value={values.idAccountList}
                          touched={touched.idAccountList}
                          errors={errors.idAccountList}
                          multipleLabels={["fullKey","nameAccountList"]}
                          optionValue="idAccountList"
                          optionName="name"
                        />
                    }
                  </Col>
                  <Col xs={12} lg={6}>
                      <SelectTypeHeadSingle
                        label="Aplicación"
                        isRequired={true}
                        inputName="keyRollUnder"
                        optionsArray={aplicaciones}
                        defaultOption="Seleccione una aplicación"
                        onChangeMethod={(event) =>
                          {
                            const {name,value} = event.target
                            setFieldValue(name, value)
                          }
                        }
                        onBlur={handleBlur}
                        isDisabled={false}
                        value={values.keyRollUnder}
                        touched={touched.keyRollUnder}
                        errors={errors.keyRollUnder}
                        optionValue="keyRollUnder"
                        optionName="nameRollUnder"
                      />
                  </Col>
                </Row>
                <div className="row mt-3">
                  <div className="col-sm-6 order-2 order-sm-1 text-center text-sm-left mb-4">
                    <Button color="danger" onClick={() => (limpia(resetForm),setFieldValue("idExpenseObject", "")
)}>
                      Cancelar
                    </Button>
                  </div>
                  <div className="col-sm-6 order-1 order-sm-2 text-center text-sm-right mb-4">
                    <Button color="success" type="submit">
                      Guardar
                    </Button>
                  </div>
                </div>
                <FormikData info={info} />
              </Form>
            </Collapse>
          </div>
        )}
      </Formik>
    </div>
  );
};

const FormikData = ({ info }) => {
  const { setFieldValue } = useFormikContext();
  useEffect(() => {
    if (info) {
      setFieldValue("idUnder", info.idUnder);
      setFieldValue("nameUnder", info.nameUnder);
      setFieldValue("under", info.under);
      setFieldValue("idExpenseObject", info.idExpenseObject);
      setFieldValue("idAccountList", info.idAccountList);
      setFieldValue("budget", info.budget);
      setFieldValue("keyTypeUnder", info.keyTypeUnder);
      setFieldValue("idUnderSAT", info.idUnderSAT);
      setFieldValue("keyRollUnder", info.keyRollUnder);
    }
  }, [info]);
  return null;
};

export default ConceptosNomina;

import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import CurrencyInput from "react-currency-input-field";
import { Collapse, Col, Row, Button } from "reactstrap";

import { getKindToStatus } from "./consultasSelect";
import { peticionesReceiver } from "../../../helpers/peticionesReceiver";

import Notification from "../../../components/Notification/Notification";
import ModalConfirmation from "../../../components/Modal/ModalConfirmation";
import {
  TextInput,
  SelectTypeHeadSingle,
  FilesInput,
} from "../../../components/GenericInputsFormik/index";
import { doRequest, uploadFile } from "../../../helpers/requests";

import TableComponentCustom from "../../../components/TableComponent/TableComponentCustom";
import ModalViewFile from "../../../components/Modal/ModalViewFile";
import ModalComponentEliminar from "../../../components/Modal/ModalDelete";
import { useRef } from "react";
import Loader from "../../../components/Loader/Loader";
import { NumberInput } from "../../../components/GenericInputsFormik/NumberInput";

export const ExpedienteNominaPrestamos = ({ idStaff, permissions }) => {
  const API = peticionesReceiver();
  const [unders, setUnders] = useState([]);
  const [status, setStatus] = useState([]);
  const [typesLoan, setTypesLoan] = useState([]);
  const [institutions, setInstitutions] = useState([]);
  const staffLoanDefault = {
    idStaffLoan: "",
    idStaff: idStaff,
    idUnder: "",
    keyInstitution: "",
    keyTypeLoan: "",
    keyStat: "",
    policy: "",
    startDate: "",
    endDate: "",
    amountTotal: "",
    amountBiweekly: "",
    noPays: "",
    noPaysMade: 0,
    notes: "",
    comprobante: "",
  };
  const refInpFile = useRef(null);
  const [modalFile, setModalFile] = useState(false); ///Visualziar archivos
  const [linkFile, setLinkFile] = useState(""); ///Link de archivo
  const [editHasFile, setEditHasFile] = useState(false);
  const [loading, setLoading] = useState(false);

  const [collapse, setCollapse] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [modal, setModal] = useState(false);
  const select = {
    action: "datatable",
    table:
      "stafflaon A INNER JOIN types_loan B on (A.keyTypeLoan = B.keyTypeLoan) INNER JOIN institutions C on (A.keyInstitution = C.keyInstitution) INNER JOIN unders D on (A.idUnder = D.idUnder) INNER JOIN status E on (A.keyStat = E.keyStat) LEFT JOIN bookcase X ON A.idStaffLoan = X.reference AND X.keyFileType = 51 LEFT JOIN files Y ON X.idBook = Y.idBook ",
    rows: "A.idStaffLoan,D.nameUnder,B.nameTypeLoan,C.nameInstitution,A.policy,A.startDate,A.endDate,A.amountTotal,A.amountBiweekly,A.noPays,A.noPaysMade,E.nameStat,A.notes,A.keyTypeLoan,A.keyInstitution,A.idUnder,A.keyStat,Y.src",
    conditions: `A.enabled = 1 AND idStaff=${idStaff} AND IF(ISNULL(Y.enabled), TRUE, Y.enabled = 1)`,
    page: 0,
    records: 5,
    search: "",
    order: "",
  };

  const toggle = () => setCollapse(!collapse);
  const [cabeceras] = useState([
    "Id",
    "Concepto",
    "Tipo de préstamo",
    "Institucion",
    "Poliza",
    "Fecha de inicio",
    "Fecha de termino",
    "Monto total",
    "Monto Quincenal",
    "Pagos",
    "Pagos aplicados",
    "Estatus",
    "Evidencia",
    "Editar",
    "Eliminar",
  ]);
  const [filtro] = useState([
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    false,
    false,
    false,
  ]);
  const [parametros, setParametros] = useState(select);

  const getUnders = async () => {
    const params = {
      action: "multiselect",
      table: "unders",
      rows: "idUnder,concat(under,' - ',nameUnder) as name",
      conditions: "enabled = 1 and keyRollUnder = 2",
      order: "cast(under as float) asc",
    };
    API.peticion(params)
      .then((res) => {
        if (res.status === 200 && res.data.code === "200") {
          setUnders(res.data.data);
        } else {
          toast(
            <Notification
              type={"consultar_error"}
              backMessage={res.data.message}
              withIcon
            />
          );
        }
      })
      .catch((err) => {
        toast(<Notification type={"consultar_servidor_error"} withIcon />);
      });
  };
  const getTypesLoan = async () => {
    const params = {
      action: "select",
      table: "types_loan",
      condition: {
        enabled: 1,
      },
      order: "nameTypeLoan ASC",
    };
    API.peticion(params)
      .then((res) => {
        if (res.status === 200 && res.data.code === "200") {
          setTypesLoan(res.data.data);
        } else {
          toast(
            <Notification
              type={"consultar_error"}
              backMessage={res.data.message}
              withIcon
            />
          );
        }
      })
      .catch((err) => {
        toast(<Notification type={"consultar_servidor_error"} withIcon />);
      });
  };
  const getInstitutions = async () => {
    const params = {
      action: "select",
      table: "institutions",
      condition: {
        enabled: 1,
      },
      order: "nameInstitution ASC",
    };
    API.peticion(params)
      .then((res) => {
        if (res.status === 200 && res.data.code === "200") {
          setInstitutions(res.data.data);
        } else {
          toast(
            <Notification
              type={"consultar_error"}
              backMessage={res.data.message}
              withIcon
            />
          );
        }
      })
      .catch((err) => {
        toast(<Notification type={"consultar_servidor_error"} withIcon />);
      });
  };

  useEffect(() => {
    getUnders();
    getKindToStatus(setStatus, false, null, "LOAN");
    getTypesLoan();
    getInstitutions();
  }, []);

  useEffect(() => {
    if (collapse === true) {
      getUnders();
      getKindToStatus(setStatus, false, null, "LOAN");
    }
  }, [collapse]);

  const FormSchema = Yup.object().shape({
    idUnder: Yup.string().required("Seleccione una nómina"),
    keyStat: Yup.string().required("Seleccione un estado"),
    keyInstitution: Yup.string().required("Seleccione una institución"),
    // keyTypeLoan: Yup.string().required("Seleccione un tipo de préstamo"),
    startDate: Yup.date().required("Seleccione una fecha"),
    // endDate: Yup.date()
    //   .required("Seleccione una fecha")
    //   .min(
    //     Yup.ref("startDate"),
    //     ({ min }) => "La fecha debe ser mayor a la fecha de inicio"
    //   ),
    policy: Yup.string().required("Ingrese una poliza"),
    amountTotal: Yup.number()
      .min(1, "Ingrese un número mayor a 1")
      .required("Ingrese una cantidad"),
    noPays: Yup.number()
      .integer("Ingrese un número entero")
      .min(1, "Ingrese un número mayor a 1")
      .max(999, "Ingrese un número menor a 1000")
      .required("Ingrese un pago"),
    comprobante: Yup.mixed().when("act", {
      is: (v) => {
        return editHasFile;
      },
      otherwise: Yup.mixed()
        .required("Agregue un comprobante")
        .test("file-type", "El documento debe ser en formato PDF", (value) => {
          return value && value.type === "application/pdf";
        })
        .test({
          message: `El documento debe pesar menos de 4MB`,
          test: (value) => value?.size < 4000000,
        }),
    }),
  });

  const onChange = (e) => {
    if (e.target.name === "amountTotal") {
      if (e.target.name === "") var formato;
      formato = new Intl.NumberFormat("ES-MX", {
        style: "currency",
        currency: "MXN",
      }).format(e.target.value);
      setFieldValue([e.target.name], e.target.value);
    } else {
      setFieldValue([e.target.name], e.target.value);
    }
    /* if(e.target.name === "startPeriod" || e.target.name === "startYear" || e.target.name === "noPays" 
      || e.target.name === "amountTotal"){
        calculaPrestamo();
      } */
  };

  const OnChangeSelect = (event) => {
    const { name, value } = event.target;
    setFieldValue(name, value);
  };

  const onChangeFile = (e) => {
    var { files } = e.target;
    if (files.length === 0) setFieldValue("comprobante", "");
    else setFieldValue("comprobante", files[0]);
  };

  const calculaPrestamo = () => {
    setFieldValue(
      ["amountBiweekly"],
      (+values.amountTotal / +values.noPays).toFixed(2)
    );
  };

  const {
    handleSubmit,
    handleReset,
    handleBlur,
    values,
    errors,
    touched,
    setFieldValue,
    resetForm,
  } = useFormik({
    initialValues: staffLoanDefault,
    onSubmit: () => {
      setModal(true);
    },
    onReset: () => {
      if (refInpFile.current !== null) refInpFile.current.value = "";
    },
    validationSchema: FormSchema,
    enableReinitialize: true,
  });

  const modoEdicion = (prestamo) => {
    if (!collapse) {
      if (prestamo[17]) setEditHasFile(true);
      //rows: "A.idStaffLoan,D.nameUnder,B.nameTypeLoan,C.nameInstitution,E.nameStat,A.policy,
      //A.startDate,A.endDate,A.amountTotal,A.amountBeweekly,A.noPays,A.noPaysMade,A.notes,
      //A.keyTypeLoan,
      //A.keyInstitution,A.idUnder,A.keyStat",
      setFieldValue("idStaffLoan", prestamo[0]);
      setFieldValue("policy", prestamo[4]);
      setFieldValue("startDate", prestamo[5]);
      setFieldValue("endDate", prestamo[6]);
      setFieldValue("amountTotal", prestamo[7]);
      setFieldValue("keyStat", prestamo[16]);
      setFieldValue("amountBiweekly", prestamo[8]);
      setFieldValue("noPays", prestamo[9]);
      setFieldValue("noPaysMade", prestamo[10]);
      setFieldValue("notes", prestamo[12] === "SIN NOTAS" ? "" : prestamo[12]);
      setFieldValue("idUnder", prestamo[15]);
      setFieldValue("keyInstitution", prestamo[14]);
      setFieldValue("keyTypeLoan", prestamo[13]);
      setIsEditing(true);
      setCollapse(true);
    } else {
      toast(
        <Notification
          type="warning"
          backMessage="Guarde sus cambios antes de continuar"
        />
      );
    }
  };

  const limpia = () => {
    setIsEditing(false);
    setCollapse(false);
    setEditHasFile(false);
    resetForm();
  };

  const agregar = (prestamo, reset) => {
    const press = { ...prestamo };
    delete press.comprobante;
    const data = {
      action: "insert",
      table: "stafflaon",
      rows: press,
      validate: [[
        "idStaff",
        "idUnder",
        "keyInstitution",
        "keyTypeLoan",
        "policy",
        "startDate",
        "endDate",
        "amountTotal",
        "amountBiweekly",
        "noPays",
      ]],
    };
    API.peticion(data)
      .then(async (res) => {
        if (res.status === 200 && res.data.status === "success") {
          if (values.comprobante) {
            await uploadFile(
              res.data.data[0].idStaffLoan,
              "saveFileReplace",
              51,
              values.comprobante,
              false
            );
          }
          toast(
            <Notification
              type={"agrega_exito"}
              backMessage={res.data.message}
              withIcon
            />
          );
          setParametros(select);
          reset();
          setCollapse(false);
        } else {
          toast(
            <Notification
              type={"agrega_error"}
              backMessage={res.data.message}
              withIcon
            />
          );
        }
      })
      .catch((err) => {
        toast(err);
        console.log(err);
      });
  };
  const editar = (permiso, reset) => {
    const press = { ...permiso };
    delete press.comprobante;
    const data = {
      action: "update",
      table: "stafflaon",
      rows: press,
      condition: {
        idStaffLoan: press.idStaffLoan,
      },
      validate: [
        [
          "idStaff",
          "idUnder",
          "keyInstitution",
          "keyTypeLoan",
          "policy",
          "startDate",
          "endDate",
          "amountTotal",
          "amountBiweekly",
          "noPays",
        ]
      ],
    };
    API.peticion(data)
      .then(async (res) => {
        if (res.status === 200 && res.data.status === "success") {
          await uploadFile(
            res.data.data[0].idStaffLoan,
            "saveFileReplace",
            51,
            values.comprobante,
            false
          );
          toast(
            <Notification
              type={"modifica_exito"}
              backMessage={res.data.message}
              withIcon
            />
          );
          setParametros(select);
          reset();
          setCollapse(false);
        } else {
          toast(
            <Notification
              type={"modifica_error"}
              backMessage={res.data.message}
              withIcon
            />
          );
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (
      values.noPays &&
      values.amountTotal &&
      values.amountTotal > 0 &&
      values.noPays > 0
    ) {
      calculaPrestamo();
    } else {
      setFieldValue(["amountBiweekly"], "");
    }
  }, [values.startPeriod, values.startYear, values.amountTotal, values.noPays]);

  // -------------------- COLUMNAS TABLA ---------------- //
  const PdfCol = (el) => {
    return (
      <div>
        <i
          className="fa fa-file-pdf-o cursor-pointer"
          onClick={() => {
            showFile(el[17]);
          }} //console.log(el);
        />
      </div>
    );
  };
  const editCol = (el) => {
    if (permissions.UPD)
      return (
        <div>
          <i
            className="fa fa-pencil text-warning cursor-pointer"
            onClick={() => modoEdicion(el)}
          />
        </div>
      );
  };
  const deleteCol = (el) => {
    if (permissions.DEL)
      return (
        <div>
          <ModalComponentEliminar
            modalTitle="Eliminar"
            id={el[0]}
            table="stafflaon"
            nameColumn={["idStaffLoan"]}
            limpia={limpia}
            elimina={elimianrRegistro}
          >
            <h6>¿Seguro de eliminar el registro "{el[1]}"?</h6>
            No podrá recuperar la información después de ser eliminada
          </ModalComponentEliminar>
        </div>
      );
  };

  async function elimianrRegistro(id) {
    const params = {
      action: "delete",
      table: "stafflaon",
      condition: { idStaffLoan: id },
      force: 1,
    };
    setLoading(true);
    const data = await doRequest("receiver/receiver.php", params, true);
    if (data.length > 0) {
      limpia(resetForm);
      setParametros({ ...select });
    }
    setLoading(false);
  }

  useEffect(() => {
    if (linkFile !== "") {
      setModalFile(true);
    }
  }, [linkFile]);

  useEffect(() => {
    if (!modalFile) {
      setLinkFile("");
    }
  }, [modalFile]);

  function showFile(src) {
    if (src === "") {
      toast(
        <Notification
          type={"warning"}
          backMessage="Este registro no cuenta con una evidencia"
          withIcon
        />,
        { closeButton: false }
      );
    } else setLinkFile(src);
  }

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div>
          <TableComponentCustom
            titulo="Préstamos"
            cabecerasTabla={cabeceras}
            filtro={filtro}
            parametros={parametros}
            specialColumns={[PdfCol, editCol, deleteCol]}
          />
          <ModalConfirmation
            modalTitle={isEditing ? "Editar" : "Crear"}
            modal={modal}
            setModal={setModal}
            crear={agregar}
            editar={editar}
            isEdit={isEditing}
            values={values}
            reset={resetForm}
          >
            {isEditing ? (
              <div className="d-flex justify-content-center">
                <h6>¿Está seguro de editar el registro?</h6>
              </div>
            ) : (
              <div className="d-flex justify-content-center">
                <h6>¿Desea continuar con el registro?</h6>
              </div>
            )}
          </ModalConfirmation>

          {permissions.INS && (
            <div className="d-grid d-flex justify-content-center justify-content-sm-end">
              <Button
                color="add"
                onClick={toggle}
                style={{ marginBottom: "1rem" }}
                disabled={collapse}
              >
                Agregar
              </Button>
            </div>
          )}

          <Collapse isOpen={collapse}>
            <Col className="d-grid d-flex justify-content-sm-end justify-content-center">
              <h6 className=" text-center">
                Estado:{" "}
                <span className="text-primary">
                  {isEditing ? "Editando" : "Guardando"}{" "}
                </span>{" "}
              </h6>
            </Col>
            {/* Formulario */}
            <Row className="d-flex justify-content-center">
              <Col>
                <h4 className="text-center">Préstamo</h4>
              </Col>
            </Row>
            <form
              onSubmit={handleSubmit}
              onReset={handleReset}
              className="mt-4"
              noValidate
            >
              <Row className="d-flex justify-content-around">
                <Col xs={12} md={12} lg={5}>
                  <TextInput
                    label="Poliza"
                    inputType="text"
                    inputName="policy"
                    onChangeMethod={onChange}
                    onBlurMethod={handleBlur}
                    value={values.policy}
                    isRequired={true}
                    touched={touched.policy}
                    errors={errors.policy}
                  />
                </Col>
                <Col xs={12} md={12} lg={5}>
                  <SelectTypeHeadSingle
                    label="Tipo de préstamo"
                    inputName="keyTypeLoan"
                    optionsArray={typesLoan}
                    defaultOption="Seleccione un tipo de préstamo"
                    onChangeMethod={OnChangeSelect}
                    onBlur={handleBlur}
                    isDisabled={false}
                    value={values.keyTypeLoan}
                    touched={touched.keyTypeLoan}
                    errors={errors.keyTypeLoan}
                    optionValue="keyTypeLoan"
                    optionName="nameTypeLoan"
                    // isRequired={true}
                  />
                </Col>
              </Row>
              <Row className="d-flex justify-content-around">
                <Col xs={12} md={12} lg={5}>
                  <SelectTypeHeadSingle
                    label="Institucion"
                    isRequired={true}
                    inputName="keyInstitution"
                    optionsArray={institutions}
                    defaultOption="Seleccione una institucion"
                    onChangeMethod={OnChangeSelect}
                    onBlur={handleBlur}
                    isDisabled={false}
                    value={values.keyInstitution}
                    touched={touched.keyInstitution}
                    errors={errors.keyInstitution}
                    optionValue="keyInstitution"
                    optionName="nameInstitution"
                  />
                </Col>
                <Col xs={12} md={12} lg={5}>
                  <SelectTypeHeadSingle
                    label="Concepto"
                    isRequired={true}
                    inputName="idUnder"
                    optionsArray={unders}
                    defaultOption="Seleccione un concepto"
                    onChangeMethod={OnChangeSelect}
                    onBlur={handleBlur}
                    isDisabled={false}
                    value={values.idUnder}
                    touched={touched.idUnder}
                    errors={errors.idUnder}
                    optionValue="idUnder"
                    optionName="name"
                  />
                </Col>
              </Row>

              <Row className="d-flex justify-content-around">
                <Col xs={12} md={12} lg={5}>
                  <TextInput
                    label="Fecha de inicio"
                    inputType="date"
                    inputName="startDate"
                    onChangeMethod={onChange}
                    onBlurMethod={handleBlur}
                    value={values.startDate}
                    touched={touched.startDate}
                    errors={errors.startDate}
                    isRequired
                  />
                </Col>
                <Col xs={12} md={12} lg={5}>
                  <TextInput
                    label="Fecha de termino"
                    inputType="date"
                    inputName="endDate"
                    onChangeMethod={onChange}
                    onBlurMethod={handleBlur}
                    value={values.endDate}
                    touched={touched.endDate}
                    errors={errors.endDate}
                    isMin={values.startDate}
                    isDisabled={!values.startDate}
                    isRequired
                  />
                </Col>
              </Row>

              <Row className="d-flex justify-content-around">
                <Col xs={12} md={12} lg={5}>
                  <NumberInput
                    label="Cantidad total"
                    inputName="amountTotal"
                    onChangeMethod={onChange}
                    onBlurMethod={handleBlur}
                    value={values.amountTotal}
                    errors={errors.amountTotal}
                    touched={touched.amountTotal}
                    isMin={1}
                    decimals
                    prefix="$"
                    isRequired
                  />
                </Col>
                <Col xs={12} md={12} lg={5}>
                  <NumberInput
                    label="Número de Parcialidades"
                    inputName="noPays"
                    onChangeMethod={onChange}
                    onBlurMethod={handleBlur}
                    value={values.noPays}
                    errors={errors.noPays}
                    touched={touched.noPays}
                    isMin={1}
                    maxLength={3}
                    isRequired
                  />
                </Col>
              </Row>

              <Row className="d-flex justify-content-around">
                <Col xs={12} md={12} lg={5}>
                    <NumberInput
                      label="Importe quincenal"
                      inputName="amountBiweekly"
                      onChangeMethod={onChange}
                      onBlurMethod={handleBlur}
                      value={values.amountBiweekly}
                      errors={errors.amountBiweekly}
                      touched={touched.amountBiweekly}
                      isMin={1}
                      decimals
                      prefix="$"
                      isRequired
                      isDisabled
                    />
                </Col>
                <Col xs={12} md={12} lg={5}>
                  <TextInput
                    label="Pagos Aplicados"
                    inputType="number"
                    inputName="noPaysMade"
                    onChangeMethod={onChange}
                    onBlurMethod={handleBlur}
                    value={values.noPaysMade}
                    isRequired={false}
                    touched={touched.noPaysMade}
                    errors={errors.noPaysMade}
                    isDisabled
                  />
                </Col>
              </Row>
              <Row className="d-flex justify-content-center">
                <Col xs={12} md={12} lg={5}>
                  <SelectTypeHeadSingle
                    label="Estado"
                    isRequired={true}
                    inputName="keyStat"
                    optionsArray={status}
                    defaultOption="Seleccione un estado"
                    onChangeMethod={OnChangeSelect}
                    onBlur={handleBlur}
                    isDisabled={false}
                    value={values.keyStat}
                    touched={touched.keyStat}
                    errors={errors.keyStat}
                    optionValue="keyStat"
                    optionName="nameStat"
                  />
                </Col>
                <Col xs={12} md={12} lg={5}>
                  <FilesInput
                    label="Evidencia"
                    inputName="comprobante"
                    onChangeMethod={onChangeFile}
                    onBlurMethod={handleBlur}
                    fileAccept="application/pdf"
                    isRequired
                    touched={touched.comprobante}
                    errors={errors.comprobante}
                    basicReq={false}
                    refInput={refInpFile}
                  />
                </Col>
              </Row>

              <Row className="d-flex justify-content-around">
                <Col xs={12} md={12} lg={10}>
                  <label htmlFor="notes">Notas</label>
                  <textarea
                    name="notes"
                    onChange={onChange}
                    onBlur={handleBlur}
                    value={values.notes}
                    className={
                      "form-control" +
                      (errors.notes && touched.notes ? " is-invalid" : "")
                    }
                  />
                </Col>
              </Row>

              <div className="row mt-3">
                <div className="col-sm-6 order-2 order-sm-1 text-center text-sm-left mb-4">
                  <Button
                    color="danger"
                    type="reset"
                    onClick={() => limpia(resetForm)}
                  >
                    Cancelar
                  </Button>
                </div>
                <div className="col-sm-6 order-1 order-sm-2 text-center text-sm-right mb-4">
                  <Button color="success" type="submit">
                    Guardar
                  </Button>
                </div>
              </div>
            </form>
          </Collapse>

          <ModalViewFile
            modal={modalFile}
            setModal={setModalFile}
            backdrop={true}
            keyboard={true}
            btnClose={true}
            fileLink={linkFile}
          />
        </div>
      )}
    </>
  );
};
export default ExpedienteNominaPrestamos;

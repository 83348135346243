import React, { useState } from "react";
import TableComponent from "../../../components/TableComponent/TableComponentFiltro";

export const Carreras = () => {
  const [cabeceras] = useState([
    "Id",               
    "Nombre de la carrera",
    "Titulo",  
    "Nivel Academico",  
    "Editar",
    "Eliminar" 
  ]);
  const [filtro] = useState([true, true, true,true, false, true, true]);
  const [parametros] = useState({
    action: "datatable",
    table: "careertypes A LEFT JOIN  schoolaritytypes B on (A.keySchoolarityType = B.keySchoolarityType)",
    rows: "A.keyCareerType,A.nameCareerType,A.careerTitle,B.nameSchoolarityType,B.keySchoolarityType",
    conditions: "A.enabled = 1",
    page:0,
    records:5,
    search:"",
    order:""
  });

  return (
    <div>
        <TableComponent
          titulo="Carreras"
          cabecerasTabla={cabeceras}
          filtro={filtro}
          rutaCrear="/template/carreras/crear"
          rutaEditar="/template/carreras/editar"
          parametros={parametros}
        ></TableComponent>
    </div>
  );
};
export default Carreras;

import { Document, Page, Text, View, StyleSheet } from "@react-pdf/renderer";
import { styles } from "../../../../components/Pdf/pdfGeneral/EstiloPdf";
import HeaderReport from "../../../../components/Pdf/Header";

/**
 * Estilo que se repite en todas los headers de la tabla
 */
const styleHeadForAll = {
  borderStyle: "solid", 
  borderWidth: 1,
  backgroundColor: "#646464",
  color: 'white',
  textAlign: 'center',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: 12
}

const styleCellForAll = {
  borderStyle: "solid", 
  borderWidth: 1, 
  borderTopWidth: 0,
  fontSize: 12
}

/**
 * Estilos del pdf
 */
const s = StyleSheet.create({
  subTitle:{ //SUBTITULOS
    width:"100%",
    textAlign: 'center',
    marginBottom: 10,
    marginLeft: 10,
    fontSize: 14
  },
  thIn: { 
    height: 20, 
    ...styleHeadForAll
  }, 
  th: {
    height: 20,
    ...styleHeadForAll,
    borderLeftWidth: 0
  },
  w10: {
    width: "10%"
  },
  w40: {
    width: "40%"
  },
  w60: {
    width: "60%"
  },
  w100: {
    width: "100%"
  },
  mt2: {
    marginTop: 40
  },
  center: {
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
  tdIn: { //Cell CC
    ...styleCellForAll
  }, 
  td: { //Cell Concepto
    ...styleCellForAll,
    borderLeftWidth: 0
  }
})


const formatter = new Intl.NumberFormat("es-MX", {
  style: "currency",
  currency: "MXN",
});

export default function generatePDF(data, model, title, fileNo) {
  const ori = model.orientation === "h" ? "landscape" : "portrait";

  const { poliza } = data;

  function toCurrency(value) {
    if (value && !isNaN(value)) {
      return formatter.format(Number(value))
    } else if (isNaN(value)) {
      return value
    } else {
      return ""
    }
  }

  return {
    report: (
      <>
        <Document>
          <Page style={styles.body} orientation={ori}>
            <HeaderReport/>
            <View>
              <Text style={s.subTitle}>{title}</Text>
            </View>
            <View styles={styles.table}>
              <View style={styles.tableRow} fixed>
                <View style={[s.w60, s.thIn]} fixed>
                  <Text>Datos generales</Text>
                </View>
                <View style={[s.w40, s.th]} fixed>
                  <Text>Detalle de poliza</Text>
                </View>
              </View>
              <View style={styles.tableRow} fixed>
                <View style={[s.w60, s.tdIn]} fixed>
                  <Text>Enté público: SAPP</Text>
                </View>
                <View style={[s.w40, s.td]} fixed>
                  <Text>Poliza: {poliza.number}/{poliza.year} - {poliza.acronim} {poliza.nameKindPolicy}</Text>
                </View>
              </View>
              <View style={styles.tableRow} fixed>
                <View style={[s.w60, s.tdIn]} fixed>
                </View>
                <View style={[s.w40, s.td]} fixed>
                  <Text>Fecha: {poliza.created}</Text>
                </View>
              </View>
              <View style={styles.tableRow} fixed>
                <View style={[s.w60, s.tdIn]} fixed>
                  <Text>Descripción: {poliza.nameEvent}</Text>
                </View>
                <View style={[s.w40, s.td]} fixed>
                  <Text>Mes: {poliza.nameMonth}</Text>
                </View>
              </View>
              <View style={styles.tableRow} fixed>
                <View style={[s.w60, s.tdIn]} fixed>
                  <Text>Elaboró: {poliza.elaboro}</Text>
                </View>
                <View style={[s.w40, s.td]} fixed>
                  <Text>Evento del sistema: {poliza.idPolicy}</Text>
                </View>
              </View>
            </View>
            <View styles={[styles.table, s.mt2]}>
              {/* Cabeceras */}
              <View style={styles.tableRow} fixed>
                {model.content.map((value, index) => (
                  <View style={index === 0 ? [s.w10, s.thIn] : [s.w10, s.th]} fixed>
                    <Text>{value.header}</Text>
                  </View>
                ))}
              </View>
              {/* Content */}
              {data.detalles.map((data) => 
                <View style={styles.tableRow} fixed>
                  {model.content.map((value, index) => (
                    <View style={index === 0 ? [s.w10, s.tdIn] : [s.w10, s.td]} fixed>
                      <Text>{index === 8 || index === 9 ? toCurrency(data[value.value]) : data[value.value]}</Text>
                    </View>
                  ))}
                </View>
              )}
            </View>

            <View fixed style={styles.spaceFooter}></View>
            <View fixed style={styles.footer}>
              <Text style={styles.textFooter}>{fileNo}</Text>
              <Text
                style={styles.textFooter}
                render={({ pageNumber, totalPages }) =>
                  `PAGINA : ${pageNumber} / ${totalPages}`
                }
              />
            </View>
          </Page>
        </Document>
      </>
    ),
  }
}

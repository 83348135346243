import React from "react";
import { Spinner } from "reactstrap";
import s from "./FullScreenLoader.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleExclamation } from "@fortawesome/free-solid-svg-icons";

const FullAlertLoader = ({ show, setShow, message }) => {
  return (
    <div className={s.loaderAlertBack + " " + (show && s.loaderFormBack)}>
      <div className={"text-center " + s.ico}>
        {/* <Spinner color="info" /> */}
        {message ? (
          <FontAwesomeIcon
            icon={faCircleExclamation}
            style={{ width: "50px", height: "50px" }}
            className="mt-5 danger"
          />
        ) : (
          <Spinner color="info" />
        )}
        {/* <i className=" fa-solid fa-circle-exclamation"></i> */}
        <h5 className="text-info">{message ? message : "Cargando..."}</h5>
      </div>
    </div>
  );
};

export default FullAlertLoader;

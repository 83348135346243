import { Modal, ModalHeader, ModalBody, Row, Col, Button } from "reactstrap";
import { useEffect, useState } from "react";
import Loader from "../../../../components/Loader/Loader";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as Yup from "yup";

import Notification from "../../../../components/Notification/Notification";
import { peticionesReceiver } from "../../../../helpers/peticionesReceiver";
import { SelectTypeHeadSingle, TextInput, TextAreaInput } from "../../../../components/GenericInputsFormik";
import { CurrencyInput } from "../../../../components/GenericInputsFormik";
import InputWithModal from "../../../../components/GenericInputsFormik/InputWithModal";
import ModalConfirmation from "../../../../components/Modal/ModalConfirmation";

export default function EditarBien({ idInventory, setIdInventory, modal, setModal, onRefresh }) {
    const API = peticionesReceiver();

    const [modalConfirmation, setModalConfirmation] = useState(false);

    const [status, setStatus] = useState([])
    const [loadStatus, setLoadStatus] = useState(true);

    const [reasons, setReasons] = useState([])
    const [loadRazones, setLoadRazones] = useState(true);
    const [loading, setLoading] = useState(true)

    const cabecerasMTBien = [
        "#",
        "Nombre",
        "Seleccionar",
    ];
    const filtroMTBien = [true, true];
    const paramsMTBien = {
        action: "datatable",
        table: "furnitures",
        rows: "idFurniture,nameFurniture",
        conditions: "enabled = 1",
        page: 0,
        records: 5,
        search: "",
        order: "",
    };

    async function getInventory(id) {
        const params = {
            action: "multiselect",
            table: "inventories join furnitures using(idFurniture)",
            rows: "idInventory,idFurniture,nameFurniture,keyReasonUp,keyStat,actualPrice,serialKey",
            conditions: `idInventory = ${id}`
        }
        const res = await API.peticion(params)
        if (res.status === 200 && res.data.code === "200") {
            const data = res.data.data[0];
            setFieldValue(["idInventory"], data.idInventory)
            setFieldValue(["idFurniture"], data.idFurniture)
            setFieldValue(["nameFurniture"], data.nameFurniture)
            setFieldValue(["keyReason"], data.keyReasonUp)
            setFieldValue(["keyStat"], data.keyStat)
            setFieldValue(["notes"], data.notes)
            setFieldValue(["actualPrice"], data.actualPrice)
            setFieldValue(["serialKey"], data.serialKey)
            setModal(true)
        } else {
            toast(<Notification type={"consultar_error"} withIcon />);
        }
    }

    useEffect(() => {
        if (modal) {
            if (!loading) {
                getInventory(idInventory)
            }
            getDataStatus();
            getDataRazones();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [idInventory, loading, modal])

    useEffect(() => {
        setLoading(loadRazones && loadStatus);
    }, [loadStatus, loadRazones])

    async function getDataStatus() {
        const params = {
            action: "multiselect",
            table: "status",
            rows: "keyStat,nameStat",
            conditions: `enabled = 1 AND keyTypeStat=25`,
        }
        const res = await API.peticion(params)
        if (res.status === 200 && res.data.code === "200") {
            const data = res.data.data;
            setStatus(data);
            setLoadStatus(false)
        } else {
            toast(<Notification type={"consultar_error"} withIcon />);
        }
    }

    async function getDataRazones() {
        const params = {
            action: "multiselect",
            table: "reasonadd",
            rows: "keyReason,nameReason",
            conditions: `enabled = 1`,
        }
        const res = await API.peticion(params)
        if (res.status === 200 && res.data.code === "200") {
            const data = res.data.data;
            setReasons(data);
            setLoadRazones(false)
        } else {
            toast(<Notification type={"consultar_error"} withIcon />);
        }
    }

    const defaultForm = {
        idInventory: "",
        idFurniture: "",
        nameFurniture: "",
        keyReason: "",
        keyStat: "",
        notes: "",
        actualPrice: "",
        serialKey: ""
    }

    const FormSchema = Yup.object().shape({
        idInventory: Yup.number(),
        idFurniture: Yup.number(),
        nameFurniture: Yup.string().required("Seleccione un bien valido"),
        keyReason: Yup.number().required("Seleccione una razón valida"),
        keyStat: Yup.number().required("Seleccione un estado valido"),
        notes: Yup.string().required("Ingrese un motivo por el cual se modificara el registro"),
        actualPrice: Yup.number().required("Ingrese un monto valido"),
        serialKey: Yup.string().required("El campo es obligatorio")
    });

    const { handleSubmit, values, handleBlur, errors, touched, setFieldValue, resetForm } =
        useFormik({
            initialValues: defaultForm,
            onSubmit: (values) => {
                setModalConfirmation(true)
            },
            validationSchema: FormSchema,
            enableReinitialize: true,
        });

    const onChange = (e) => {
        setFieldValue(e.target.name, e.target.value);
    };

    const selectBien = (el) => {
        setFieldValue(["idFurniture"], el[0])
        setFieldValue(["nameFurniture"], el[1])
    };
    const clearBien = () => {
        setFieldValue(["idFurniture"], "")
        setFieldValue(["nameFurniture"], "")
    }

    const closeModal = () => {
        setIdInventory(null)
        setLoadStatus(true)
        setLoadRazones(true)
        setLoading(true)
        resetForm()
        setModal(false)
    }

    async function OnEdit(rows) {
        const API = peticionesReceiver();
        const params = {
            table: "inventories",
            action: "update",
            rows: {
                idFurniture: rows.idFurniture,
                keyReasonUp: rows.keyReason,
                keyStat: rows.keyStat,
                actualPrice: rows.actualPrice,
                serialKey: rows.serialKey
            },
            condition: { idInventory: rows.idInventory }
        }
        try {
            const res = await API.peticion(params)
            if (res.status === 200 && res.data.code === "200") {
                toast(<Notification type={"modifica_exito"} backMessage={res.data.message} withIcon />);
                setModal(false)
                onRefresh()
            } else {
                toast(<Notification type={"modifica_error"} backMessage={res.data.message} withIcon />);
            }
        } catch (err) {
            toast(<Notification type={"modifica_error"} withIcon />);
        }
    }

    return (
        <>
            <Modal isOpen={modal} toggle={closeModal} fullscreen="lg" size="xl" backdrop="static" >
                <ModalHeader toggle={closeModal}>Editar bien</ModalHeader>
                <ModalBody>
                    {loading ?
                        <Loader />
                        :
                        <form onSubmit={handleSubmit}>
                            <Row>
                                <Col xs={12}>
                                    <InputWithModal
                                        cabeceras={cabecerasMTBien}
                                        error={errors.nameFurniture}
                                        filtro={filtroMTBien}
                                        handleBlur={handleBlur}
                                        onChange={onChange}
                                        actionSelect={selectBien}
                                        onClear={clearBien}
                                        parametros={paramsMTBien}
                                        touched={touched.nameFurniture}
                                        value={values.nameFurniture}
                                        label="Bien mueble"
                                        name={"nameFurniture"}
                                        title={"Seleccionar bien mueble"}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12} md={6}>
                                    <CurrencyInput
                                        label="Costo del bien"
                                        inputName="actualPrice"
                                        onChangeMethod={(value, name) => onChange({ target: { name: name, value: value } })}
                                        onBlurMethod={handleBlur}
                                        value={values.actualPrice}
                                        isRequired
                                        touched={touched.actualPrice}
                                        errors={errors.actualPrice}
                                    />
                                </Col>
                                <Col xs={12} md={6}>
                                    <TextInput
                                        label="Numero de serie"
                                        inputName="serialKey"
                                        inputType="text"
                                        onChangeMethod={onChange}
                                        onBlurMethod={handleBlur}
                                        value={values.serialKey}
                                        isRequired
                                        touched={touched.serialKey}
                                        errors={errors.serialKey}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12}>
                                    <TextAreaInput
                                        label="Ingrese el motivo por el cual se realiza la modificación"
                                        inputType="text"
                                        inputName="notes"
                                        inputRows={6}
                                        onChangeMethod={onChange}
                                        onBlurMethod={handleBlur}
                                        value={values.notes}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12} md={6}>
                                    <SelectTypeHeadSingle
                                        label="Motivo alta"
                                        inputName="keyReason"
                                        onChangeMethod={onChange}
                                        value={values.keyReason}
                                        optionsArray={reasons}
                                        optionValue="keyReason"
                                        optionName="nameReason"
                                        isRequired
                                    />
                                </Col>
                                <Col xs={12} md={6}>
                                    <SelectTypeHeadSingle
                                        label="Estado del bien"
                                        inputName="keyStat"
                                        onChangeMethod={onChange}
                                        value={values.keyStat}
                                        optionsArray={status}
                                        optionValue="keyStat"
                                        optionName="nameStat"
                                        isRequired
                                    />
                                </Col>
                            </Row>
                            <div className="text-right">
                                <Button color="danger" className="mx-1" onClick={closeModal}>Cancelar</Button>
                                <Button color="add" className="mx-1" type="submit">Guardar</Button>
                            </div>
                        </form>
                    }
                </ModalBody>
            </Modal>
            <ModalConfirmation
                modalTitle={"Editar"}
                modal={modalConfirmation}
                editar={(rows) => OnEdit(rows)}
                setModal={setModalConfirmation}
                isEdit={true}
                values={values}
            >
                <div className="d-flex justify-content-center">
                    <h6>¿Desea continuar con el registro?</h6>
                </div>
            </ModalConfirmation>
        </>
    )

}
import React, { useState } from "react";
import TableComponent from "../../../components/TableComponent/TableComponentFiltro";

export const FuentesFinanciamiento = () => {
  const cabeceras = [
    "#",
    "Fuentes de financiamiento",
    "Clave",
    "Editar",
    "Eliminar",
  ];
  const [parametros] = useState({
    action: "datatable",
    table: `financialsources`,
    rows: "idFinancialSource, nameFinancialSource, financialSourceKey", ///sin espacios y comas
    conditions: "enabled = 1",
    page: 0,
    records: 5,
    search: "",
    order: "",
  });

  return (
    <div>
      <TableComponent
        titulo="Fuentes de Financiamiento"
        cabecerasTabla={cabeceras}
        filtro={null}
        rutaCrear="/template/fuentes/crear"
        rutaEditar="/template/fuentes/editar"
        parametros={parametros}
      />
    </div>
  );
};
export default FuentesFinanciamiento;

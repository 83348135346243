/**
 * @param params setters from Format
 */
export function setConfigR27({ setHeaders, setPdfConfig, setExcelConfig, setHtmlConfig, setData, filter }) {
    const headers = [
        "Id",
        "Periodo",
        "Año",
        "Fecha",
        "Editar"
    ]
    setHeaders(headers)
    const tempData = {
        action: "datatable",
        table: "prisma",
        rows: "idPrisma,period,year,datePrisma",
        conditions: `enabled = 1`,
        order: "datePrisma desc"
    }
    if (filter.year) {
        tempData.conditions += ` AND year = ${(new Date(filter.year)).getFullYear()}`
    }
    if (filter.period) {
        tempData.conditions += ` AND period = ${filter.period}`
    }
    setData(tempData);
    const content = [
        { header: "Periodo", value: "period", format: false },
        { header: "Año", value: "year", format: false },
        { header: "Fecha", value: "datePrisma", format: false },
    ];
    setHtmlConfig({
        headers,
        id: { id: "idPrisma" },
        content: [content]
    });
    setPdfConfig({
        orientation: "h",
        subTitle: `Calculo de ISSEMYM - ${filter.period}/${(new Date(filter.year)).getFullYear()}`,
        content: {
            headersConceptos: ["CONCEPTO", "FECHA", "IMPORTE ORIGINAL", "ACTUALIZACIÓN", "RECARGOS", "SUMA DE ACCESORIOS", "INTEGRACIÓN"],
            aportaciones: [
                { header: "APORTACION POR PRIMA DE SINIESTRALIDAD", values: ["datePrisma", "aporPrimSin", "", "", "", ""] },
                { header: "ACT./EXTEMP. APORTACION PRIMA DE SINIESTRALIDAD", values: ["datePrisma", "", "actAporPrimSin", "recAporPrimSin", "accPrimSin", "totalPrimSin"] },
                { header: "APORTACION POR PRIMA BASICA", values: ["datePrisma", "aporPrimBas", "", "", "", ""] },
                { header: "ACT./EXTEMP. APORTACION POR PRIMA BASICA", values: ["datePrisma", "", "actAporPrimBas", "recAporPrimBas", "accPrimBas", "totalPrimBas"] },
                { header: "APORTACION POR GASTOS DE ADMON.", values: ["datePrisma", "aporGastosAdmon", "", "", "", ""] },
                { header: "ACT./EXTEMP. APORTACION POR GTOS. DE ADMON.", values: ["datePrisma", "", "actAporGastosAdmon", "recAporGastosAdmon", "accGastosAdmon", "totalGastosAdmon"] },
                { header: "APORTACION EXTRAORDINARIA", values: ["datePrisma", "aporExtraordinaria", "", "", "", "totalExtraordinaria"] },
                { header: "APORTACION POR FONDO SOLIDARIO DE REPARTO", values: ["datePrisma", "aporFondoSolRep", "", "", "", ""] },
                { header: "ACT./EXTEMP. APORT. POR FONDO SOLIDARIO DE REPARTO", values: ["datePrisma", "", "actAporFondoSolRep", "recAporFondoSolRep", "accFondoSolRep", "totalFondoSolRep"] },
                { header: "APORTACION POR SERVICIOS DE SALUD", values: ["datePrisma", "aporServSalud", "", "", "", ""] },
                { header: "ACT./EXTEMP. APORT.POR SERVICIOS DE SALUD", values: ["datePrisma", "", "actAporServSalud", "recAporServSalud", "accServSalud", "totalServSalud"] },
            ],
            sumaAportaciones: ["sumaAportaciones", "sumaAccesoriosAportaciones", "sumaIntegracionAportaciones"],
            cuotas: [
                { header: "CUOTA EXTRAORDINARIA", values: ["datePrisma", "cuotaExtraordinaria", "", "", "", "totalExtraordinariaCuota"] },
                { header: "CUOTA POR FONDO SOLIDARIO DE REPARTO", values: ["datePrisma", "cuotaFondSolRep", "", "", "", ""] },
                { header: "ACT./EXTEMP. CUOTA POR FONDO SOLIDARIO DE REPARTO", values: ["datePrisma", "", "actCuotaFondSolRep", "recCuotaFondSolRep", "accFondoSolRepCuota", "totalFondoSolRepCuota"] },
                { header: "CUOTA POR SERVICIO DE SALUD", values: ["datePrisma", "cuotaServSalud", "", "", "", ""] },
                { header: "ACT./EXTEMP. CUOTA POR SERVICIOS DE SALUD", values: ["datePrisma", "", "actCuotaServSalud", "recCuotaServSalud", "accServSaludCuota", "totalServSaludCuota"] },
                { header: "APORTACION SCI OBLIGATORIO", values: ["datePrisma", "aporSCIOblig", "", "", "", ""] },
                { header: "ACT./EXTEMP. APORTACION SCI OBLIGATORIO", values: ["datePrisma", "", "actAporSCIOblig", "recAporSCIOblig", "accSCIOblig", "totalSCIOblig"] },
                { header: "CUOTA SCI VOLUNTARIO", values: ["datePrisma", "cuotaSCIVol", "", "", "", "totalSCIVolCuota"] },
                { header: "CUOTA SCI OBLIGATORIO", values: ["datePrisma", "cuotaSCIOblig", "", "", "", ""] },
                { header: "ACT./EXTEMP. CUOTA SCI OBLIGATORIO", values: ["datePrisma", "", "actCuotaSCIOblig", "recCuotaSCIOblig", "accSCIObligCuota", "totalSCIObligCuota"] },
                { header: "RETENCIONES INSTITUCIONALES", values: ["datePrisma", "retInstitucionales", "", "", "", "totalInstitucionales"] },
                { header: "RETENCIONES A TERCEROS", values: ["datePrisma", "retTerceros", "", "", "", "totalTerceros"] },
            ],
            sumaCuotas: ["sumaCuotas", "sumaAccesorioCuotas", "sumaIntegracionCuotas"],
            sumaDeterminacion: ["sumaDeterminacion", "sumaAccesorioDeterminacion", "sumaIntegracionDeterminacion"],
            arrays: ["SS", "FSSR", "AOSCI", "GA", "RT"]
        },
        sizes: [22, 8, 10, 10, 10, 10, 10, 7, 5, 8],
        sizesCon: [40, 10, 10, 10, 10, 10, 10]
    });
    setExcelConfig({
        subTitle: `Calculo de ISSEMYM - ${filter.period}/${(new Date(filter.year)).getFullYear()}`,
        content: {
            headersConceptos: ["CONCEPTO", "FECHA", "IMPORTE ORIGINAL", "ACTUALIZACIÓN", "RECARGOS", "SUMA DE ACCESORIOS", "INTEGRACIÓN"],
            aportaciones: [
                { header: "APORTACION POR PRIMA DE SINIESTRALIDAD", values: ["datePrisma", "aporPrimSin"] },
                { header: "ACT./EXTEMP. APORTACION PRIMA DE SINIESTRALIDAD", values: ["datePrisma", "", "actAporPrimSin", "recAporPrimSin", "accPrimSin", "totalPrimSin"] },
                { header: "APORTACION POR PRIMA BASICA", values: ["datePrisma", "aporPrimBas"] },
                { header: "ACT./EXTEMP. APORTACION POR PRIMA BASICA", values: ["datePrisma", "", "actAporPrimBas", "recAporPrimBas", "accPrimBas", "totalPrimBas"] },
                { header: "APORTACION POR GASTOS DE ADMON.", values: ["datePrisma", "aporGastosAdmon"] },
                { header: "ACT./EXTEMP. APORTACION POR GTOS. DE ADMON.", values: ["datePrisma", "", "actAporGastosAdmon", "recAporGastosAdmon", "accGastosAdmon", "totalGastosAdmon"] },
                { header: "APORTACION EXTRAORDINARIA", values: ["datePrisma", "aporExtraordinaria", "", "", "", "totalExtraordinaria"] },
                { header: "APORTACION POR FONDO SOLIDARIO DE REPARTO", values: ["datePrisma", "aporFondoSolRep"] },
                { header: "ACT./EXTEMP. APORT. POR FONDO SOLIDARIO DE REPARTO", values: ["datePrisma", "", "actAporFondoSolRep", "recAporFondoSolRep", "accFondoSolRep", "totalFondoSolRep"] },
                { header: "APORTACION POR SERVICIOS DE SALUD", values: ["datePrisma", "aporServSalud"] },
                { header: "ACT./EXTEMP. APORT.POR SERVICIOS DE SALUD", values: ["datePrisma", "", "actAporServSalud", "recAporServSalud", "accServSalud", "totalServSalud"] },
            ],
            sumaAportaciones: ["sumaAportaciones", "sumaAccesoriosAportaciones", "sumaIntegracionAportaciones"],
            cuotas: [
                { header: "CUOTA EXTRAORDINARIA", values: ["datePrisma", "cuotaExtraordinaria", "", "", "", "totalExtraordinariaCuota"] },
                { header: "CUOTA POR FONDO SOLIDARIO DE REPARTO", values: ["datePrisma", "cuotaFondSolRep"] },
                { header: "ACT./EXTEMP. CUOTA POR FONDO SOLIDARIO DE REPARTO", values: ["datePrisma", "", "actCuotaFondSolRep", "recCuotaFondSolRep", "accFondoSolRepCuota", "totalFondoSolRepCuota"] },
                { header: "CUOTA POR SERVICIO DE SALUD", values: ["datePrisma", "cuotaServSalud"] },
                { header: "ACT./EXTEMP. CUOTA POR SERVICIOS DE SALUD", values: ["datePrisma", "", "actCuotaServSalud", "recCuotaServSalud", "accServSaludCuota", "totalServSaludCuota"] },
                { header: "APORTACION SCI OBLIGATORIO", values: ["datePrisma", "aporSCIOblig"] },
                { header: "ACT./EXTEMP. APORTACION SCI OBLIGATORIO", values: ["datePrisma", "", "actAporSCIOblig", "recAporSCIOblig", "accSCIOblig", "totalSCIOblig"] },
                { header: "CUOTA SCI VOLUNTARIO", values: ["datePrisma", "cuotaSCIVol", "", "", "", "totalSCIVolCuota"] },
                { header: "CUOTA SCI OBLIGATORIO", values: ["datePrisma", "cuotaSCIOblig"] },
                { header: "ACT./EXTEMP. CUOTA SCI OBLIGATORIO", values: ["datePrisma", "", "actCuotaSCIOblig", "recCuotaSCIOblig", "accSCIObligCuota", "totalSCIObligCuota"] },
                { header: "RETENCIONES INSTITUCIONALES", values: ["datePrisma", "retInstitucionales", "", "", "", "totalInstitucionales"] },
                { header: "RETENCIONES A TERCEROS", values: ["datePrisma", "retTerceros", "", "", "", "totalTerceros"] },
            ],
            sumaCuotas: ["sumaCuotas", "sumaAccesorioCuotas", "sumaIntegracionCuotas"],
            sumaDeterminacion: ["sumaDeterminacion", "sumaAccesorioDeterminacion", "sumaIntegracionDeterminacion"],
            arrays: ["SS", "FSSR", "AOSCI", "GA", "RT"]
        }
    });
}

export function configRep27(values) {
    return {
        action: "multiselect",
        table: "prisma",
        rows: "idPrisma",
        conditions: `enabled = 1 AND year = ${(new Date(values.year)).getFullYear()} AND period = ${values.period}`,
    }
}

export function configEnd27(data) {
    const idPrisma = data[0].idPrisma;
    return {
        "action": "getPrisma",
        "idPrisma": idPrisma
    }
}

import React from "react";

import Widget from "../../../../components/Widget/Widget";
import FormMultas from "../FormMultas";
import { updatePenalty } from "../functions";

export const EditarMulta = (props) => {
  return (
    <Widget className="widget-p-md">
        <h3>Editar tipo de sanción</h3>
        <FormMultas data={localStorage.getItem('dataContent').split('|-|')} editar={updatePenalty}/>
    </Widget>
  )
}

export default EditarMulta;
import { useEffect, useState } from "react";
import { Button, Col, Collapse, Row } from "reactstrap";
import { doRequest, doRequestSaveRes } from "../../../../../helpers/requests";
import FullFormLoader from "../../../../../components/Loader/FullFormLoader";
import Datatable from "../../../../../components/Datatable/Datatable";
import ModalDelete from "../../../../../components/Modal/ModalDelete";
import { SelectTypeHeadSingle } from "../../../../../components/GenericInputsFormik";
import { PERIOD } from "../../../../../helpers/regex";
import { YearInput } from "../../../../../components/GenericInputsFormik/YearInput";
import { NumberInput } from "../../../../../components/GenericInputsFormik/NumberInput";
import * as Yup from "yup";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import Notification from "../../../../../components/Notification/Notification";
import ModalConfirmation from "../../../../../components/Modal/ModalConfirmation";
import { periodDays } from "../../../../../libs/tools/format";

export default function Calculo({ finiquito, permissions }) {
    const [loading, setLoading] = useState(true);
    const [refresh, setRefresh] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [modal, setModal] = useState(false);
    const [canCalculate, setCanCalculate] = useState(false);
    const [conceptos, setConceptos] = useState([]);
    const [estatuss, setEstatuss] = useState([]);

    /* CONSULTAS INI */
    useEffect(()=>{
        getUnders();
        getStatus();
    }, []);

    useEffect(()=>{
        setCanCalculate(+finiquito.keyStatSettlement !== 159);
    }, [finiquito]);

    async function getUnders() {
        const params = {
        action: "multiselect",
        table: "unders",
        rows: "idUnder,concat(under,' - ',nameUnder) as name",
        conditions: "enabled = 1",
        order: "cast(under as float) asc",
        };
        let data = await doRequest("receiver/receiver.php", params, false);
        setConceptos(data);
    };
    
    async function getStatus() {
        const params = {
        action: "select",
        table: "status",
        order: "nameStat ASC",
        condition: { keyTypeStat: 12 },
        };
        let data = await doRequest("receiver/receiver.php", params, false);
        setEstatuss(data);
        setLoading(false);
    };
    /* END CONSULTAS INI */

    /* FORM */
    const {
        handleSubmit,
        handleReset,
        handleBlur,
        values,
        errors,
        touched,
        setFieldValue,
        setValues
    } = useFormik({
        initialValues: { ...defaultForm, idStaff: finiquito.idStaff , idJobStaff: finiquito.idJobStaff },
        onSubmit: () => setModal(true),
        onReset: () => setIsOpen(false),
        validationSchema: FormSchema,
        enableReinitialize: true,
    });

    const onChange = ({target}) => {
        setFieldValue(target.name, target.value);
    };

    async function editInfo(){
        setLoading(true);
        const params = {
            action: values.idPaySettlements ? "update" : "insert",
            table: "paysettlements",
            rows: { ...values, year: values.year.getFullYear() },
            validate: [["idStaff", "idJobStaff", "idUnder", "period", "year"]]
        }
        if(values.idPaySettlements){
            params.condition = { idPaySettlements: values.idPaySettlements }
        }   
        const res = await doRequest("receiver/receiver.php", params, false);
        if(res.length > 0){
            handleReset();
            setRefresh(true);
        }
        setLoading(false);
    }
    /* END FORM */


    /* TABLE */
    const columns = [
        { data: "ix" },
        { data: "underName" },
        { data: "nameTypeUnder" },
        { data: "amount", format: "currency" },
        { data: null, render: (row) => `${row.period}/${row.year}`, orderValue: "CONCAT(A.period,'/',A.year)" },
        { data: "nameStat" },
        {
            data: null,
            render: (row) => permissions.UPD && (
                <i
                    className="fa fa-pencil text-warning cursor-pointer"
                    onClick={() => editRow(row)}
                />
            ),
        },
        {
            data: null,
            render: (row) =>
                permissions.DEL && (
                    <ModalDelete
                        modalTitle="Eliminar"
                        table=""
                        nameColumn={[""]}
                        elimina={deleteRow}
                        id={row.idPaySettlements}
                    >
                        <h6>Está seguro de eliminar esta área?</h6>
                        No podrá revertir esta acción
                    </ModalDelete>
                ),
        },
    ];

    async function getData(ordCol, itemsPerPage, currentPage, search, multi) {
        const res = await doRequestSaveRes(
            "receiver/receiver.php",
            getParams(ordCol, itemsPerPage, currentPage, search, multi),
            false,
            false
        );
        if (+res.total > 0) {
            res.data = res.data.map((el, ix) => {
                return { ...el, ix: ix + 1 };
            });
            return res;
        }
        return null;
    }

    function getParams(ordCol, itemsPerPage, currentPage, search) {
        const params = {
            action: "datatable",
            table: "paysettlements A INNER JOIN unders B USING(idUnder) INNER JOIN type_unders C USING(KeyTypeUnder) INNER JOIN status D ON A.keyStat = D.keyStat",
            rows: "A.idPaySettlements, concat(B.under,' - ',B.nameUnder) as underName, C.nameTypeUnder, A.amount, D.nameStat, A.idUnder, A.period, A.year, A.keyStat, A.noDays",
            conditions: `A.idStaff=${finiquito.idStaff} AND A.idJobStaff = ${finiquito.idJobStaff} AND A.enabled = 1`,
            order: ordCol,
            records: itemsPerPage.toString(),
            page: (itemsPerPage * currentPage).toString(),
            search,
        };
        return params;
    }

    function editRow(row) {
        if (isOpen) {
            toast(
                <Notification
                    type="warning"
                    backMessage="Guarde sus cambios antes de continuar"
                />,
                { closeButton: false }
            );
            return;
        }
        setValues({
            idPaySettlements: row.idPaySettlements,
            idStaff: row.idStaff,
            idJobStaff: row.idJobStaff,
            idUnder: row.idUnder,
            keyStat: row.keyStat,
            noDays: +row.noDays,
            period: row.period,
            year: new Date(+row.year, 0, 1),
            amount: row.amount,
        },true);
        setIsOpen(true);
    }

    async function deleteRow(id) {
        setLoading(true);
        const params = {
            action: "delete",
            table: "paysettlements",
            condition: {
                idPaySettlements: id,
            },
            force: 1,
        };
        const res = await doRequest("receiver/receiver.php", params, true);
        if (res.length > 0){
            setRefresh(true);
            handleReset();
        }
        setLoading(false);
    }
    /* END TABLE */

    return (
        <div>
            {canCalculate && permissions.UPD && (
                <FormCalculo finiquito={finiquito} setRefresh={setRefresh} setLoading={setLoading} />
            )}

            <Datatable
                headers={["#","Concepto RH","Tipo Concepto RH","Importe","Periodo", "Estatus","Editar","Eliminar"]}
                columns={columns}
                columnDefs={columnDefs}
                petition={getData}
                control="back"
                stateRefresh={[refresh, setRefresh]}
                className="px-0 mt-4"
                order={{ col: 1, opt: "desc" }}
            />
            <div className="d-flex flex-column-reverse flex-md-row justify-content-md-between text-center mt-4 mb-2">
                <div>
                    {
                        isOpen && <h4 className="my-3 my-md-2">{`${values.idPaySettlements ? "Editar" : "Agregar"} Concepto`}</h4>
                    }
                </div>
                {permissions.INS && (
                    <Button color="add" onClick={() => setIsOpen(true)} disabled={isOpen} >
                        Agregar
                    </Button>
                )}
            </div>

            <Collapse isOpen={isOpen}>
                <form onSubmit={handleSubmit} onReset={handleReset}>
                    <Row>
                        <Col xs={12} md={12} lg={6}>
                            <SelectTypeHeadSingle
                                label="Concepto"
                                isRequired={true}
                                inputName="idUnder"
                                optionsArray={conceptos}
                                defaultOption="Seleccione un concepto"
                                onChangeMethod={onChange}
                                onBlurMethod={handleBlur}
                                isDisabled={false}
                                value={values.idUnder}
                                touched={touched.idUnder}
                                errors={errors.idUnder}
                                optionValue="idUnder"
                                optionName="name"
                            />
                        </Col>
                        <Col xs={12} md={12} lg={6}>
                            <SelectTypeHeadSingle
                                label="Estatus"
                                isRequired={true}
                                inputName="keyStat"
                                optionsArray={estatuss}
                                defaultOption="Seleccione un estatus"
                                onChangeMethod={onChange}
                                onBlurMethod={handleBlur}
                                isDisabled={false}
                                value={values.keyStat}
                                touched={touched.keyStat}
                                errors={errors.keyStat}
                                optionValue="keyStat"
                                optionName="nameStat"
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} md={12} lg={4}>
                            <YearInput
                                label="Año"
                                inputName="year"
                                onChangeMethod={onChange}
                                value={values.year}
                                isMin={cYear - 5}
                                isMax={cYear + 1}
                                onBlurMethod={handleBlur}
                                touched={touched.year}
                                errors={errors.year}
                                isRequired
                            />
                        </Col>
                        <Col xs={12} md={12} lg={4}>
                            <SelectTypeHeadSingle
                                label="Periodo"
                                isRequired={true}
                                optionsArray={PERIOD}
                                inputName="period"
                                onChangeMethod={onChange}
                                onBlurMethod={handleBlur}
                                touched={touched.period}
                                errors={errors.period}
                                optionValue="value"
                                optionName="label"
                                value={values.period}
                                defaultOption="Seleccione un periodo"
                            />
                        </Col>
                        <Col xs={12} md={12} lg={4}>
                            <NumberInput
                                label="No. Días"
                                inputType="text"
                                inputName="noDays"
                                onChangeMethod={onChange}
                                onBlurMethod={handleBlur}
                                value={values.noDays}
                                touched={touched.noDays}
                                errors={errors.noDays}
                                isRequired
                                maxLength={2}
                                isMax={16}
                                isDisabled={!values.year || !values.period }
                            />
                        </Col>
                        <Col xs={12} md={12} lg={4}>
                            <NumberInput
                                label="Monto"
                                inputType="text"
                                inputName="amount"
                                onChangeMethod={onChange}
                                onBlurMethod={handleBlur}
                                value={values.amount}
                                touched={touched.amount}
                                errors={errors.amount}
                                prefix="$"
                                decimals
                                isRequired
                            />
                        </Col>
                    </Row>

                    <div className=" d-flex flex-md-row flex-column-reverse justify-content-md-around">
                        <Button color="danger" type="reset" className="mt-3">
                            Cancelar
                        </Button>
                        <Button color="success" type="submit" className="mt-2 mt-md-3">
                            Guardar
                        </Button>
                    </div>
                </form>
            </Collapse>
            
            <ModalConfirmation
                modalTitle={values.idPaySettlements ? "Editar" : "Agregar"}
                modal={modal}
                setModal={setModal}
                editar={editInfo}
                isEdit
            >
                <div className="d-flex justify-content-center">
                    <h6>¿Está seguro de {values.idPaySettlements ? "editar" : "crear"} el registro?</h6>
                </div>
            </ModalConfirmation>

            <FullFormLoader show={loading} />
        </div>
    );
}

function FormCalculo({ finiquito, setRefresh, setLoading }){

    /* FORM */
    const {
        handleSubmit,
        handleReset,
        handleBlur,
        values,
        errors,
        touched,
        setFieldValue,
    } = useFormik({
        initialValues: { period: "", year: "" },
        onSubmit: () => calculate(),
        validationSchema: FormSchemaCalc,
        enableReinitialize: true,
    });

    const onChange = ({target}) => {
        setFieldValue(target.name, target.value);
    };
    
    async function calculate() {
        setLoading(true);
        const params = {
            action: "calculoFiniquito",
            rows:{
                idStaff: finiquito.idStaff,
                idJobStaff: finiquito.idJobStaff,
                keyStat: finiquito.keyStatStaff,
                period: values.period,
                year: values.year.getFullYear()
            }
        };
        const res = await doRequestSaveRes("app/facades/payslips/paysettlementsF.php", params, false);
        if (res.status === "success") {
            toast(
                <Notification
                    type="success"
                    backMessage={res.message.duracion}
                />,
                { closeButton: false }
            );
            setRefresh(true);
        }
        setLoading(false);
    }
    /* FORM */

    return(
        <form onSubmit={handleSubmit} onReset={handleReset}>
            <Row>
                <Col xs={12} md={12} lg={6}>
                    <YearInput
                        label="Año"
                        inputName="year"
                        onChangeMethod={onChange}
                        value={values.year}
                        isMin={cYear}
                        onBlurMethod={handleBlur}
                        touched={touched.year}
                        errors={errors.year}
                        isRequired
                    />
                </Col>
                <Col xs={12} md={12} lg={6}>
                    <SelectTypeHeadSingle
                        label="Periodo"
                        isRequired={true}
                        optionsArray={PERIOD}
                        inputName="period"
                        onChangeMethod={onChange}
                        onBlurMethod={handleBlur}
                        touched={touched.period}
                        errors={errors.period}
                        optionValue="value"
                        optionName="label"
                        value={values.period}
                        defaultOption="Seleccione un periodo"
                    />
                </Col>
            </Row>
            <div className="text-center mb-3">
                <Button color="info" type="submit">
                    Generar cálculo
                </Button>
            </div>
        </form>
    )
}

const defaultForm = {
    idPaySettlements: "",
    idStaff: "",
    idJobStaff: "",
    idUnder: "",
    keyStat: "",
    noDays: "",
    period: "",
    year: new Date(),
    amount: "",
};

const FormSchema = Yup.object().shape({
    idUnder: Yup.string().required("Seleccione un Concepto"),
    keyStat: Yup.string().required("Seleccione un estatus"),
    noDays: Yup.number().required("Ingrese una cantidad de días")
        .min(1, "La cantidad de días debe ser mayor a 0")
        .test({
            name: "periodDays", 
            message: "La cantidad de días excede los del periodo", 
            test: (value, cx) => validPeriodDays(value, cx.parent)
        }),
    period: Yup.number().required("Seleccione un periodo").min(1, "Periodo invalido").max(24, "Periodo debe ser menor a 25"),
    year: Yup.string().required("Seleccione un año"),
    amount: Yup.number().required("Ingreese una cantidad").min(1, "La cantidad de días debe ser mayor a 0"),
});

function validPeriodDays(days, vals){
    const period = +vals.period;
    const year = new Date(vals.year).getFullYear();
    const daysP = periodDays(period, year);
    return +days <= daysP;
}

const columnDefs = [
    { targets: [0, 2, 4, 5, 6, 7], className: "text-center" },
    { targets: [3], className: "text-right" },
    { targets: [0], orderable: false }
];

const cYear = new Date().getFullYear();

const FormSchemaCalc = Yup.object().shape({
    period: Yup.number().required("Seleccione un periodo").min(1, "Periodo invalido").max(24, "Periodo debe ser menor a 25"),
    year: Yup.string().required("Seleccione un año"),
});
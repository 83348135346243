import React, { useState, useEffect } from "react";
import { Col, Row, Button, Card, CardBody, CardTitle, CardText } from 'reactstrap'
import { toast } from "react-toastify";
import { useHistory } from "react-router";

import { GetPermisos, OnDelete } from "./functions";
import Cards from "../../../components/CardsComponent/Cards";
import { SelectTypeHeadSingle } from "../../../components/GenericInputsFormik/SelectTypeHeadSingle";
import Widget from "../../../components/Widget/Widget";
import { peticionesReceiver } from "../../../helpers/peticionesReceiver";
import Notification from "../../../components/Notification/Notification";

import ModalComponent from "../../../components/Modal/ModalDelete";

export default function BienesMuebles() {
    const API = peticionesReceiver();
    const [params, setParams] = useState(null);
    const [keyGroup, setKeyGroup] = useState("");
    const [keySubGroup, setKeySubGroup] = useState("");
    const [keyClass, setKeyClass] = useState("");

    const [groups, setGroups] = useState([]);
    const [subgroups, setSubgroups] = useState([]);
    const [classes, setClasses] = useState([]);

    const [refresh, setRefresh] = useState(true);

    const filters = [
        { value: "nameFurniture", label: "Nombre" },
        { value: "model", label: "Modelo" },
        { value: "brand", label: "Marca" },
        { value: "nameColor", label: "Color" },
        { value: "nameMaterial", label: "Material" }
    ]

    const history = useHistory();

    const onChange = (evt) => {
        const { name, value } = evt.target;
        switch (name) {
            case "keyGroup":
                setKeyGroup(value);
                break;
            case "keySubGroup":
                setKeySubGroup(value);
                break;
            case "keyClass":
                setKeyClass(value);
                break;
            default: break;
        }
    }

    async function getDataGrupo() {
        const params = {
            action: "multiselect",
            table: "furniture_groups",
            rows: "keyGroup,nameGroup",
            conditions: `enabled = 1`,
        }
        const res = await API.peticion(params)
        if (res.status === 200 && res.data.code === "200") {
            const data = res.data.data;
            setGroups(data)
        } else {
            setGroups([])
            toast(<Notification type={"consultar_error"} withIcon />);
        }
    }

    async function getDataSubGrupo(keyGroup) {
        const params = {
            action: "multiselect",
            table: "furniture_subgroups",
            rows: "keySubGroup,nameSubGroup",
            conditions: `enabled = 1 AND keyGroup = ${keyGroup}`,
        };
        const res = await API.peticion(params)
        if (res.status === 200 && res.data.code === "200") {
            const data = res.data.data;
            setSubgroups(data)
        } else {
            setSubgroups([])
            toast(<Notification type={"consultar_error"} withIcon />);
        }
    }

    async function getDataClases(keySubGroup) {
        const params = {
            action: "multiselect",
            table: "furniture_classes",
            rows: "keyClass,nameClass",
            conditions: `enabled = 1 AND keySubGroup = ${keySubGroup}`,
        }
        const res = await API.peticion(params)
        if (res.status === 200 && res.data.code === "200") {
            const data = res.data.data;
            setClasses(data)
        } else {
            setClasses([])
            toast(<Notification type={"consultar_error"} withIcon />);
        }
    }

    useEffect(() => {
        if (groups.length === 0) {
            getDataGrupo()
        } else {
            if (keyGroup) {
                getDataSubGrupo(keyGroup);
                if (keySubGroup) {
                    getDataClases(keySubGroup)
                } else {
                    setKeyClass("")
                }
            } else {
                setKeySubGroup("")
            }
        }
        // eslint-disable-next-line
    }, [keyClass, keyGroup, keySubGroup, groups])

    useEffect(() => {
        const parametros = {
            action: "report37",
            page: "0",
            records: "10",
            search: "",
            order: ""
        }
        parametros.keyGroup = keyGroup;
        parametros.keySubGroup = keySubGroup;
        parametros.keyClass = keyClass;
        setParams(parametros);
        refreshData();
    }, [keyClass, keyGroup, keySubGroup])

    const [permissionsForm, setPermissionsForm] = useState("");

    GetPermisos(setPermissionsForm);

    function onEdit(row) {
        localStorage.setItem("furnitureData", JSON.stringify(row))
        history.push({ pathname: "/template/bienesmuebles/editar" });
    }

    const refreshData = () => setRefresh(true); 

    const CardRender = (row) => {
        return (
            <Card className="p-2 shadow rounded" key={`card${row.idFurniture}`}>
                <div className="text-right">
                    <i className="fa fa-pencil-square-o text-warning pr-2" style={{ cursor: "pointer" }} onClick={() => onEdit(row)} />
                    <ModalComponent
                        modalTitle="Eliminar"
                        id={row.idFurniture}
                        table={["furnitures"]}
                        nameColumn={["idFurniture"]}
                        elimina={() => OnDelete(row, refreshData)}
                    >
                        <h6>
                            ¿Seguro de eliminar el registro "{row.nameFurniture}"?
                        </h6>
                        No podrá recuperar la información después de ser eliminada
                    </ModalComponent>
                </div>
                <Row className="no-gutters align-items-center" style={{ height: "100%" }}>
                    <Col md={4}>
                        <img src={row.src} alt={row.nameFurniture} style={{ maxWidth: "100%" }} />
                    </Col>
                    <Col md={8} style={{ height: "100%" }}>
                        <CardBody>
                            <CardTitle><h5>{row.nameFurniture}</h5></CardTitle>
                            <CardText>MARCA: {row.brand}</CardText>
                            <CardText>MODELO: {row.model}</CardText>
                            <CardText>COLOR: {row.nameColor}</CardText>
                        </CardBody>
                    </Col>
                </Row>
            </Card>
        )
    }

    return (
        <Widget className="p-3">
            <div className="text-center text-md-left mb-4 text-secondary">
                <h2>Bienes muebles</h2>
            </div>
            {permissionsForm.INS === "1" ? (
                <div className="text-right">
                    <Button color="add" className="mb-3" onClick={() => history.push({ pathname: "/template/bienesmuebles/crear" })}>Agregar bien mueble</Button>                </div>
            ) : null}
            <Row>
                <Col xs={12} md={4}>
                    <SelectTypeHeadSingle
                        label="Grupo"
                        inputName="keyGroup"
                        onChangeMethod={onChange}
                        value={keyGroup}
                        optionsArray={groups}
                        optionValue="keyGroup"
                        optionName="nameGroup"
                        isRequired
                    />
                </Col>
                <Col xs={12} md={4}>
                    <SelectTypeHeadSingle
                        label="Subgrupo"
                        inputName="keySubGroup"
                        onChangeMethod={onChange}
                        value={keySubGroup}
                        optionsArray={subgroups}
                        optionValue="keySubGroup"
                        optionName="nameSubGroup"
                        isRequired
                    />
                </Col>
                <Col xs={12} md={4}>
                    <SelectTypeHeadSingle
                        label="Clase"
                        inputName="keyClass"
                        onChangeMethod={onChange}
                        value={keyClass}
                        optionsArray={classes}
                        optionValue="keyClass"
                        optionName="nameClass"
                        isRequired
                    />
                </Col>
            </Row>
            {params !== null && (
                <Cards
                    title={"Consulta"}
                    params={params}
                    endpoint={"app/facades/reports/reportsF.php"}
                    card={CardRender}
                    filters={filters}
                    refresh={refresh}
                    setRefresh={setRefresh}
                />
            )}

        </Widget>
    )
}
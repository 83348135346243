
import {
  Document,
  Page,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
import { toast } from "react-toastify";
import Notification from "../../../../components/Notification/Notification";
import { peticionesReceiver } from "../../../../helpers/peticionesReceiver";
import HeaderReport from "../../../../components/Pdf/Header";


const colors = {
  backgrounActivity: "#808080",
  textActivity: "#FFFFFF",
  backgroundChapter: "#643030",
  backgroundMonth: "#641010",
  backgroundTotalChapter: "#503030",
  backgroundTotalActivity: "#203030",
};

const API = peticionesReceiver();

const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 35,
    paddingHorizontal: 10,
  },

  bordersLRB: {
    borderWidth: 1,
    borderTopWidth: 0
  },
  bordersRB: {
    borderRightWidth: 1,
    borderBottomWidth: 1
  },
  /* bordersBT: {
    borderWidth: 2,
    borderRightWidth: 0,
  },
  borders: {
    borderWidth: 2,
  },
  bordersLB: {
    borderLeftWidth: 2,
    borderBottomWidth: 2,
  },
  bordersBR: {
    borderWidth: 2,
    borderTopWidth: 0,
  }, */
  tableRow: {
    flexDirection: "row",
  },
  tableColumn: {
    flexDirection: "column",
  },
  flexRow: {
    flexDirection: "row",
  },
  flexColumn: {
    flexDirection: "column",
  },

  /* ----------------------------ESTILOS DE TEXTO ------------------------------ */
  centerTextRow: {
    flexDirection: "row",
    justifyContent: "center-center",
    alignItems: "center",
    textAlign: "center",
  },
  centerText: {
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
  },
  alingTextsColumnLeft: {
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },
  alingTextsColumn: {
    flexDirection: "column",
    justifyContent: "space-around",
    alignItems: "center",
  },
  alingTextsRow: {
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
  },
  alingTextsRowLeft: {
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "start",
  },
  titleText: {
    fontSize: 10,
    fontFamily: "Helvetica-Bold",
  },
  contentTextBold: {
    fontSize: 8,
    fontFamily: "Helvetica-Bold",
  },
  contentText: {
    fontSize: 8,
    /* fontFamily: "Helvetica-Bold", */
  },
  textFooter: {
    width: "50%",
    /* color: '#a9a9a9', */
    textAlign: "center",
    fontSize: 8,
  },

  spaceFooter: {
    fontSize: 12,
    bottom: 10,
    left: 0,
    right: 0,
    width: "auto",
    height: 50,
    marginHorizontal: 10,
  },
  footer: {
    position: 'absolute',
    fontSize: 12,
    bottom: 10,
    left: 0,
    right: 0,
    width: "auto",
    height: 30,
    marginHorizontal: 10,
    /*       borderColor:"#a9a9a9", */
    borderTopWidth: 1,
    flexDirection: "row",
    alignContent: "space-around",
    alignItems: "center",
  },
});

const getAllProjectCost = async (project) => {
  let data = null;
  const params = {
    action: "getAllProjectCost",
    /* idProyect: 5, */
    idProyect: project.id,
  };
  await API.peticionEndPoint(params, "app/facades/planning/planningF.php")
    .then((res) => {
      if (res.data.status === "success" && res.data.code === "200") {
        data = res.data.data[0];
      } else {
        toast(
          <Notification
            type={"consultar_error"}
            backMessage={res.data.message}
            withIcon
          />
        );
      }
    })
    .catch((err) => {
      toast(<Notification type={"consultar_servidor_error"} withIcon />);
    });

  return data;
};

const insertTitles = (project) => {
  return (
    <>

      <HeaderReport/>

      <View style={[styles.alingTextsRow, { marginBottom: 10 }]}>
        <Text style={[styles.titleText]}>
          CELULA ANALITICA DE GASTO Y JUSTIFICACIÓN
        </Text>
      </View>

      <View style={styles.alingTextsColumnLeft}>
        <View style={styles.alingTextsRow}>
          <Text style={[styles.contentTextBold]}>UNIDAD RESPONSABLE:</Text>
          <Text style={styles.contentText}>{project.nameArea}</Text>
        </View>

        <View style={styles.alingTextsRow}>
          <Text style={styles.contentTextBold}>PROYECTO PRESUPUESTAL:</Text>
          <Text style={styles.contentText}>{project.nameStateFund}</Text>
        </View>

        <View style={styles.alingTextsRow}>
          <Text style={styles.contentTextBold}>PROYECTO:</Text>
          <Text style={styles.contentText}>{project.nameProyect}</Text>
        </View>
      </View>
    </>
  );
};

const insertActivity = (project) => {
  return project.activities.data.map((activity) => (
    <View wrap={false}>
      {activity.chapters &&
        <>
          <View
            style={[styles.alingTextsRow, {
              width: "100%",
              borderWidth: "1",
              marginTop: 10,
              backgroundColor: colors.backgrounActivity,
              height: 15
            }]}
            key={activity.idActivity}
          >
            <Text
              style={[
                styles.contentText,
                styles.centerText,
                { color: 'white' }
              ]}
            >
              {activity.nameActivity}
            </Text>
          </View>
          {insertChapter(activity.chapters.data)}
        </>
      }



      {activity.months.data.length > 0 &&
        <View wrap={false}>
          <View
            style={[styles.alingTextsRow, {
              width: "100%",
              borderWidth: "1",
              borderTopWidth: "0",
              /* backgroundColor: colors.backgrounActivity, */
              height: 15
            }]}
            key={activity.idActivity}
          >
            <Text
              style={[
                styles.contentText,
                styles.centerText,
                styles.contentTextBold
                /* {color:'white'} */
              ]}
            >
              TOTAL POR ACTIVIDAD
            </Text>
          </View>
          {insertMonths(activity.months.data)}
        </View>
      }
    </View>
  ));
};

const insertChapter = (chapters) => {

  return chapters.map((chapter, index) => (
    <>
      <View wrap={false}
        style={[styles.alingTextsRow,
        {
          width: "100%",
          borderWidth: "1",
          borderTopWidth: "0",
          height: 15
        }]}
        key={chapter.idActivity}
      >
        <Text
          style={[
            styles.contentText,
            { background: colors.backgrounActivity },
          ]}
        >
          <Text style={styles.contentTextBold}>Capitulo: </Text>{chapter.nameChapeter}
        </Text>
      </View>

      {chapter.expenses.data && insertExpenses(chapter.expenses.data)}

      {chapter.months.data.length > 0 &&
        <>
          <View wrap={false}
            style={[styles.alingTextsRow, {
              width: "100%",
              borderWidth: "1",
              borderTopWidth: "0",
              /* backgroundColor: colors.backgrounActivity, */
              height: 15
            }]}
            key={index}
          >
            <Text
              style={[
                styles.contentText,
                styles.centerText,
                styles.contentTextBold
                /* {color:'white'} */
              ]}
            >
              TOTAL POR CAPITULO
            </Text>
          </View>

          {insertMonths(chapter.months.data)}
        </>
      }
    </>
  ));
};


const insertExpenses = (expenses) => {
  return expenses.map((expense, index) => (
    <>
      {/* <View wrap={false}
        style={[styles.alingTextsRow, {
          width: "100%",
          borderWidth: "1",
          borderTopWidth: "0",
          height: 15
        }]}
        key={expense.idExpenseObject}
      >
        <Text
          style={[
            styles.contentText,
            { background: colors.backgrounActivity },
          ]}
        >
          {expense.nameObject}
        </Text>
      </View> */}
      {insertMonths(expense.months.data, expense.fullKey, expense.nameObject)}
    </>
  ));
};

const insertMonths = (months, partida, descripcion) => {
  let total = getTotal(months)

  return (
    <View style={styles.tableRow} wrap={false}>

      {partida ?

        <>
          {insertcellMonths("Partida", partida, 0)}

          {insertcellMonths("Descripcion", descripcion, 2)}
        </>
        : insertcellMonths("", "Total", 0, true, true)
      }




      {months.map((month, index) => (
        <>

          {insertcellMonths(month.nameMonth, month.amount, index + 1)}
        </>
      ))}


      {insertcellMonths(total.nameMonth, total.amount, 2)}

    </View>
  )
};

const getTotal = (months) => {
  let total = 0
  months.map((month, index) => { total += +month.amount })
  return { nameMonth: "Total", amount: total }

}

const insertcellMonths = (month, amount, index, bold, total) => {
  if (total) {

    return (
      <View style={[{ height: 25, maxHeight: 25, minHeight: 25, width: "13.332%" }]}>,
        <View
          style={{ borderWidth: 1, borderTopWidth: 0 }}
          key={month.keyMonth}
        >
          <Text
            style={[
              styles.contentTextBold,
              { background: colors.backgrounActivity },
            ]}
          >
            {`\n`}
          </Text>
        </View>
        <View
          style={[index === 0 ? styles.bordersLRB : styles.bordersRB, styles.alingTextsRow, { height: 25 }]}
          key={`M-${month.keyMonth}`}
        >
          <Text
            style={[
              bold ? styles.contentTextBold : styles.contentText,
              { background: colors.backgrounActivity },
            ]}
          >
            TOTAL:
          </Text>
        </View>
      </View>
    )

  } else {

    return (
      <View style={[{ height: 25, width: "6.666%" }]}>,
        <View
          style={[index === 0 ? styles.bordersLRB : styles.bordersRB, styles.alingTextsRow]}
          key={month.keyMonth}
        >
          <Text
            style={[
              styles.contentTextBold,
              { background: colors.backgrounActivity },
            ]}
          >
            {month}
          </Text>
        </View>
        <View
          style={[index === 0 ? styles.bordersLRB : styles.bordersRB, styles.alingTextsRow, { height: 25 }]}
          key={`M-${month.keyMonth}`}
        >
          <Text
            style={[
              bold ? styles.contentTextBold : styles.contentText,
              { background: colors.backgrounActivity },
            ]}
          >
            {amount}
          </Text>
        </View>
      </View>
    )

  }





}
export async function generatedPdfProjects(data, model, title, fileNo) {
  const project = await getAllProjectCost(model.project);
  return {
    report: (
      <>
        <Document>
          <Page style={styles.body} orientation="landscape">



            {insertTitles(project)}


            {insertActivity(project)}


            <View fixed style={styles.spaceFooter}></View>
            <View fixed style={styles.footer}>
              <Text style={styles.textFooter}>{fileNo}</Text>
              <Text
                style={styles.textFooter}
                render={({ pageNumber, totalPages }) =>
                  `PAGINA : ${pageNumber} / ${totalPages}`
                }
              />
            </View>
          </Page>
        </Document>
      </>
    ),
  };

}

export default function SATLogo({className = ""}) {
    return (
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
            width="254.000000pt" height="254.000000pt" viewBox="0 0 254.000000 254.000000"
            preserveAspectRatio="xMidYMid meet" className={className}>

            <g transform="translate(0.000000,254.000000) scale(0.100000,-0.100000)"
                fill="#015699" stroke="none">
                <path d="M480 2524 c-175 -37 -332 -158 -409 -315 -67 -137 -85 -258 -56 -393
30 -142 111 -270 225 -357 243 -184 574 -162 788 53 213 212 236 532 58 782
-132 186 -379 279 -606 230z"/>
                <path d="M1810 2524 c-85 -18 -212 -82 -276 -138 -154 -135 -232 -368 -190
-566 97 -464 656 -644 997 -323 126 119 190 262 191 428 1 50 -5 115 -12 145
-75 319 -393 522 -710 454z"/>
                <path d="M484 1195 c-127 -28 -254 -101 -333 -192 -57 -65 -118 -192 -137
-283 -49 -233 61 -487 270 -622 141 -91 338 -116 502 -64 286 92 462 381 410
676 -59 332 -387 556 -712 485z"/>
                <path d="M1815 1195 c-277 -61 -461 -273 -481 -555 -19 -257 152 -513 400
-600 161 -57 359 -39 502 45 63 37 157 127 200 193 101 151 125 355 64 528
-98 280 -401 452 -685 389z"/>
            </g>
        </svg>
    )
}
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import axios from "axios";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Alert, Collapse } from "reactstrap";
import { toast } from "react-toastify";
import { Col, Row, Button } from "reactstrap";
import { REGEX_NUMBER } from "../../../helpers/regex";
import { peticionEncript } from "../../../helpers/peticionesEncripted";
import { peticionesReceiver } from "../../../helpers/peticionesReceiver";
import Loader from "../../../components/Loader/Loader";
import Widget from "../../../components/Widget/Widget";
import Notification from "../../../components/Notification/Notification";
import ModalConfirmation from "../../../components/Modal/ModalConfirmation";
import {
  TextInput,
  TextAreaInput,
  SelectTypeHeadSingle,
  FilesInput,
} from "../../../components/GenericInputsFormik/index";
import { useRef } from "react";
import { doRequest, uploadFile } from "../../../helpers/requests";

import TableComponentCustom from "../../../components/TableComponent/TableComponentCustom";
import ModalViewFile from "../../../components/Modal/ModalViewFile";
import ModalComponentEliminar from "../../../components/Modal/ModalDelete";
import { DatePickerInput } from "../../../components/GenericInputsFormik/DatePickerInput";
import { NumberInput } from "../../../components/GenericInputsFormik/NumberInput";
import { fDateInput } from "../../../libs/tools/format";
import {
  getInstitutions,
  getKindToStatus,
  getTypesInsurance,
  getUnders,
} from "../../nomina/expediente-nomina/consultasSelect";
import FormBeneficiarios from "./extra-forms/FormBeneficiarios";

export default function PersonalSeguros({ idStaff, permissions, idModule }) {
  const API = peticionesReceiver();
  const [modalFile, setModalFile] = useState(false);
  const [linkFile, setLinkFile] = useState("");
  const [editando, setEditando] = useState(false);
  const [collapse, setCollapse] = useState(false);
  const [modal, setModal] = useState(false);
  const [parametros, setParametros] = useState({
    action: "datatable",
    table: `staffinsurance  AS SI INNER JOIN institutions AS INS ON INS.keyInstitution = SI.keyInstitution INNER JOIN types_insurance AS TI ON TI.keyTypeInsurance = SI.keyTypeInsurance INNER JOIN status AS ST ON ST.keyStat = SI.keyStat LEFT JOIN bookcase X ON SI.idStaffInsurance = X.reference AND X.keyFileType = 50 LEFT JOIN files Y ON X.idBook = Y.idBook `,
    rows: `SI.idStaffInsurance,SI.policy,INS.nameInstitution,TI.nameTypeInsurance,SI.startDate,ST.nameStat,Y.src`,
    conditions: `SI.enabled = 1 AND SI.idStaff=${idStaff} AND TI.idModule=${idModule} AND IF(ISNULL(X.enabled), TRUE, X.enabled = 1 AND Y.enabled = 1)`,
    page: 0,
    records: 5,
    search: "",
    order: "",
  });
  const [selectsData, setSelectsData] = useState({
    statusInsurance: [],
    institutionsInsurance: [],
    typesInsurance: [],
    unders: [],
  });
  const [loading, setLoading] = useState(true);
  const [relaciones, setRelaciones] = useState([]);
  const [defaultStatus, setDefaultStatus] = useState("");
  const refInpFile = useRef(null);
  const refInpTipo = useRef(null);

  /* INITIAL */
  useEffect(() => {
    getKindToStatus(setSelectsData, true, "statusInsurance", "insurance");
    getInstitutions(setSelectsData, true, "institutionsInsurance");
    getTypesInsurance(setSelectsData, true, "typesInsurance", idModule);
    getUnders(setSelectsData, true, "unders", " = 3");
    getRelaciones();
    setLoading(false);
  }, []);

  useEffect(()=>{
    if(selectsData.statusInsurance.length > 3){
      const filtered = selectsData.statusInsurance.filter((d) => ["CANCELADA", "HISTORICO DEL EMPLEADO "].includes(d.nameStat));
      const defs = filtered.find(d => d.nameStat === "HISTORICO DEL EMPLEADO ");
      if(defs) setDefaultStatus(defs.keyStat);
      setSelectsData((pv)=> ({
        ...pv,
        statusInsurance: filtered
      }));

    }
  }, [selectsData.statusInsurance]);

  async function getRelaciones(){
    const params = {
      action: "select",
      table: "relationshiptypes",
      order: "nameRelationshipType ASC"
    };
    const res = await doRequest("receiver/receiver.php", params, false);
    if(res.length > 0) setRelaciones(res);
  }
  /* END INITIAL */

  const limpia = (reset) => {
    setEditando(false);
    setCollapse(false);
    reset();
  };

  /* FORM */
  const FormSchema = Yup.object().shape({
    keyTypeInsurance: Yup.number().required("Seleccione un tipo de seguro"),
    policy: Yup.string().when("keyTypeInsurance", {
      is: () => tipoSeguro("num-poliza"),
      then: Yup.string()
        .max(30, "La poliza ingresada es muy larga")
        .required("Ingrese una poliza")
    }),
    keyInstitution: Yup.string().when("keyTypeInsurance", {
      is: () => tipoSeguro("institucion"),
      then:  Yup.string().required("Seleccione una institucion"),
    }),
    startDate: Yup.date().when("keyTypeInsurance", {
      is: () => tipoSeguro("inicio-vigencia"),
      then: Yup.date().required("Seleccione una fecha de inicio").nullable()
    }).nullable(),
    endDate: Yup.date().when("keyTypeInsurance", {
      is: () => tipoSeguro("fin-vigencia"),
      then:  Yup.date()
        .required("Seleccionar una fecha de término")
        .min(Yup.ref("startDate"), "Fecha de término debe ser posterior a la de inicio").nullable(),
    }).nullable(),
    keyStat: Yup.number()
      .moreThan(0, "Favor de seleccionar un estado")
      .required("Seleccione un estado"),
    porcentage: Yup.number().when("keyTypeInsurance", {
      is: () => tipoSeguro("porcentaje"),
      then: Yup.number()
        .moreThan(0, "Ingrese un valor mayor a 0")
        .max(100, "El valor máximo es 100")
        .required("Ingrese un porcentaje")
    }),
    beneficiary: Yup.array().when("keyTypeInsurance", {
      is: () => tipoSeguro("beneficiario"),
      then: Yup.array()
        .min(0, "Ingregue al menos un beneficiario")
        .test({
          name: "total-percent", 
          message: "El acumulado de procentajes es mayor a 100%",
          test: (value, cx) => acumPercent()
      })
    })
  });

  function acumPercent(){
      const acum = values.beneficiary.reduce((acc, d, ix) => acc + ((+d.enabled !== 0) ? +d.porcentage : 0), 0);
      return acum <= 100;
  }

  const {
    handleSubmit,
    handleReset,
    values,
    handleBlur,
    errors,
    touched,
    setFieldValue,
    resetForm,
    setValues
  } = useFormik({
    initialValues: { ...initialValues, idStaff: idStaff, keyStat: defaultStatus },
    onSubmit: (values) => {
      setModal(true);
    },
    validationSchema: FormSchema,
    enableReinitialize: true,
  });

  const onChange = (e) => {
    const { name, value, type } = e.target;
    setFieldValue(name, value);
    if (type === "number") {
      if (REGEX_NUMBER.test(value)) {
        if (value === "") setFieldValue([name], 1);
      }
    }
    if(name === "startDate"){
      setFieldValue("endDate", "");
    }
    if(name === "keyTypeInsurance"){
      setFieldValue("porcentage", "");
      setFieldValue("noCertificate", "");
    }
  };

  const onChangeFile = (e) => {
    var { files } = e.target;
    if (files.length === 0) setFieldValue("comprobante", "");
    else setFieldValue("comprobante", files[0]);
  };

  const sendFormInformation = async () => {
    setLoading(true);
    const params = {
      action: "saveInsurance",
      rows: {
        ...values,
        startDate: fDateInput(values.startDate),
        endDate: values.endDate ? fDateInput(values.endDate) : "",
      },
    };
    if(!tipoSeguro("fin-vigencia")) params.rows.endDate = ""; 
    const finalData = peticionEncript(params);
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API}app/facades/employees/benefitsF.php`,
        finalData
      );
      if (res.status === 200 && res.data.status === "success") {
        if (values.comprobante) {
          await uploadFile(
            res.data.data[0].idStaffInsurance,
            "saveFileReplace",
            50,
            values.comprobante,
            false
          );
        }
        setParametros({ ...parametros });
        toast(<Notification type={"agrega_exito"} withIcon />);
        limpia(resetForm);
      } else {
        toast(
          <Notification
            type={"agrega_error"}
            backMessage={res.data.message}
            withIcon
          />
        );
      }
    } catch (err) {
      console.log(err);
      toast(<Notification type={"consultar_error"} withIcon />);
    }
    setLoading(false);
  };

  const getInsuranceById = async (id) => {
    const params = {
      action: "select",
      table: "staffinsurance",
      condition: {
        enabled: 1,
        idStaffInsurance: id,
      },
    };
    const res = await doRequest("receiver/receiver.php", params, false);
    if (res.length > 0) {
      const data = res[0];
      console.log(data)
      const sd = data.startDate.split("-");
      const ed = data.endDate.split("-");
      const startDate = new Date(sd[0], +sd[1]-1, sd[2]);
      const endDate = new Date(ed[0], +ed[1]-1, ed[2]);
      const ladata = { 
        ...data, 
        startDate: startDate,
        endDate: endDate,
        beneficiary: [],
        notes: data.notes || "",
        noCertificate: data.noCertificate || "",
        porcentage: data.porcentage || ""
      };
      const tipo = selectsData.typesInsurance.find(t => +t.keyTypeInsurance === +data.keyTypeInsurance);
      if(tipo && tipo.nameTypeInsurance.includes("VIDA")){
        const params2 = {
          action: "select",
          table: "staffinsurancebeneficiary",
          condition: {
            enabled: 1,
            idStaffInsurance: id,
          },
        };
        const res2 = await doRequest("receiver/receiver.php", params2, false);
        if(res2.length > 0){
          ladata.beneficiary = res2;
        }
      }
      setEditando(true);
      setValues(ladata, true);
      setCollapse(true);
    } else {
      toast(<Notification type={"consultar_error"} withIcon />);
    }
    setLoading(false);
  };
  /* END FORM */

  /* TABLE */
  const PdfCol = (el) => {
    return el[6] && (
      <div>
        <i
          className="fa fa-file-pdf-o cursor-pointer"
          onClick={() => showFile(el[6])}
        />
      </div>
    );
  };

  const editCol = (el) => {
    if (permissions.UPD)
      return (
        <div>
          <i
            className="fa fa-pencil text-warning cursor-pointer"
            onClick={() => updated(el)}
          />
        </div>
      );
  };

  const deleteCol = (el) => {
    if (permissions.DEL)
      return (
        <div>
          <ModalComponentEliminar
            modalTitle="Eliminar"
            id={el[0]}
            table="staffinsurance"
            nameColumn={["idStaffInsurance"]}
            limpia={limpia}
            elimina={elimianrRegistro}
          >
            <h6>¿Seguro de eliminar el registro "{el[1]}"?</h6>
            No podrá recuperar la información después de ser eliminada
          </ModalComponentEliminar>
        </div>
      );
  };

  function showFile(src) {
    if (!src) {
      toast(
        <Notification
          type={"warning"}
          backMessage="Este registro no cuenta con una evidencia"
          withIcon
        />,
        { closeButton: false }
      );
    } else setLinkFile(src);
  }

  const updated = async (data) => {
    if (!collapse) {
      setLoading(true);
      await getInsuranceById(data[0]);
    } else {
      toast(
        <Notification
          type="warning"
          backMessage="Guarde sus cambios antes de continuar"
        />
      );
    }
  };

  async function elimianrRegistro(id) {
    const params = {
      action: "delete",
      table: "staffinsurance",
      condition: { idStaffInsurance: id },
      force: 1,
    };
    setLoading(true);
    const data = await doRequest("receiver/receiver.php", params, true);
    if (data.length > 0) {
      limpia(resetForm);
      setParametros({ ...parametros });
    }
    setLoading(false);
  }
  /* END TABLE */

  useEffect(() => {
    if (linkFile !== "") {
      setModalFile(true);
    }
  }, [linkFile]);

  useEffect(() => {
    if (!modalFile) {
      setLinkFile("");
    }
  }, [modalFile]);

  useEffect(()=>{
    if(editando) setFieldValue("keyTypeInsurance", values.keyTypeInsurance);
  }, [collapse]);

  function tipoSeguro(field) {
    const tipo = refInpTipo.current?.state.text + "" || "";
    if (!tipo) return false;

    switch (field) {
      case "beneficiario":
        return tipo.includes("VIDA");

      case "porcentaje":
        return tipo.includes("AHORRO");

      case "num-poliza":
        return tipo.endsWith("GASTOS MEDICOS MAYORES") || tipo.includes("VIDA 1");
        
      case "cerificado":
        return tipo.endsWith("GASTOS MEDICOS MAYORES") || tipo.includes("VIDA 1");

      case "institucion":
      case "inicio-vigencia":
      case "fin-vigencia":
        return tipo.endsWith("GASTOS MEDICOS MAYORES") || tipo.includes("VIDA") || tipo.includes("PATROMONIAL") || tipo.includes("AHORRO");

      default:
        return false;
    }
  }

  return (
    <>
      <Widget className="widget-p-md">
        <TableComponentCustom
          titulo="Seguros"
          cabecerasTabla={cabeceras}
          filtro={filtro}
          parametros={parametros}
          specialColumns={[PdfCol, editCol, deleteCol]}
        />
        {permissions.INS && (
          <div className="d-grid d-flex justify-content-center justify-content-sm-end">
            <Button
              color="add"
              onClick={() => setCollapse(true)}
              style={{ marginBottom: "1rem" }}
              disabled={collapse}
            >
              Agregar
            </Button>
          </div>
        )}

        <Collapse isOpen={collapse}>
          <div>
            {loading ? (
              <Loader />
            ) : (
              <>
                <div>
                  <Col className="d-grid d-flex justify-content-sm-end justify-content-center">
                    <h6 className=" text-center">
                      Estado:{" "}
                      <span className="text-primary">
                        {editando ? "Editando" : "Guardando"}{" "}
                      </span>{" "}
                    </h6>
                  </Col>
                </div>
                <form onSubmit={handleSubmit} onReset={handleReset} className="mt-4">
                  <ModalConfirmation
                    modalTitle={editando ? "Editar" : "Crear"}
                    modal={modal}
                    setModal={setModal}
                    crear={sendFormInformation}
                    editar={sendFormInformation}
                    isEdit={idStaff ? true : false}
                    values={values}
                  >
                    {editando ? (
                      <div className="d-flex justify-content-center">
                        <h6>¿Está seguro de editar el registro?</h6>
                      </div>
                    ) : (
                      <div className="d-flex justify-content-center">
                        <h6>¿Desea continuar con el registro?</h6>
                      </div>
                    )}
                  </ModalConfirmation>

                  <Row className="mt-2">
                    <Col xs={12} md={12} lg={4}>
                      <SelectTypeHeadSingle
                        label="Tipo de seguro"
                        inputName="keyTypeInsurance"
                        optionsArray={selectsData.typesInsurance}
                        defaultOption="Seleccione un tipo de seguro"
                        onChangeMethod={onChange}
                        onBlurMethod={handleBlur}
                        isDisabled={false}
                        value={values.keyTypeInsurance}
                        touched={touched.keyTypeInsurance}
                        errors={errors.keyTypeInsurance}
                        optionValue="keyTypeInsurance"
                        optionName="nameTypeInsurance"
                        isRequired
                        refSelect={refInpTipo}
                      />
                    </Col>
                    
                    {
                      tipoSeguro("institucion") &&
                      <Col xs={12} md={12} lg={4}>
                        <SelectTypeHeadSingle
                          label="Institución"
                          isRequired={true}
                          inputName="keyInstitution"
                          optionsArray={selectsData.institutionsInsurance}
                          defaultOption="Seleccione un tipo una institución"
                          onChangeMethod={onChange}
                          onBlurMethod={handleBlur}
                          isDisabled={false}
                          value={values.keyInstitution}
                          touched={touched.keyInstitution}
                          errors={errors.keyInstitution}
                          optionValue="keyInstitution"
                          optionName="nameInstitution"
                        />
                      </Col>
                    }

                    {
                      tipoSeguro("num-poliza") &&
                      <Col xs={12} md={12} lg={4}>
                        <TextInput
                          label="Poliza"
                          inputType="text"
                          inputName="policy"
                          onChangeMethod={onChange}
                          onBlurMethod={handleBlur}
                          value={values.policy}
                          isRequired={true}
                          touched={touched.policy}
                          errors={errors.policy}
                          isMax={30}
                        />
                      </Col>
                    }

                    {
                      tipoSeguro("inicio-vigencia") &&
                      <Col xs={12} md={12} lg={4} className="d-flex flex-column">
                        <DatePickerInput
                          label="Fecha de inicio"
                          inputName="startDate"
                          onChangeMethod={onChange}
                          onBlurMethod={handleBlur}
                          value={values.startDate}
                          touched={touched.startDate}
                          errors={errors.startDate}
                          isRequired
                          isMax={new Date()}
                        />
                      </Col>
                    }

                    {
                      tipoSeguro("fin-vigencia") && 
                      <Col xs={12} md={12} lg={4} className="d-flex flex-column">
                        <DatePickerInput
                          label=" Fecha de término"
                          inputName="endDate"
                          onChangeMethod={onChange}
                          onBlurMethod={handleBlur}
                          value={values.endDate}
                          touched={touched.endDate}
                          errors={errors.endDate}
                          isMin={values.startDate}
                          isDisabled={!values.startDate}
                          isRequired
                        />
                      </Col>
                    }

                    <Col xs={12} md={12} lg={4}>
                      <SelectTypeHeadSingle
                        label="Estatus"
                        isRequired={true}
                        inputName="keyStat"
                        optionsArray={selectsData.statusInsurance}
                        defaultOption="Seleccione un estado"
                        onChangeMethod={onChange}
                        onBlurMethod={handleBlur}
                        isDisabled={false}
                        value={values.keyStat}
                        touched={touched.keyStat}
                        errors={errors.keyStat}
                        optionValue="keyStat"
                        optionName="nameStat"
                      />
                    </Col>
                    
                    {tipoSeguro("cerificado") && (
                      <Col xs={12} md={12} lg={4}>
                        <TextInput
                          label="Número de certificado"
                          inputType="text"
                          inputName="noCertificate"
                          onChangeMethod={onChange}
                          onBlurMethod={handleBlur}
                          value={values.noCertificate}
                          touched={touched.noCertificate}
                          errors={errors.noCertificate}
                        />
                      </Col>
                    )}

                    {tipoSeguro("porcentaje") && (
                      <Col xs={12} md={12} lg={4}>
                        <NumberInput
                          label="Porcentaje"
                          inputName="porcentage"
                          onChangeMethod={onChange}
                          onBlurMethod={handleBlur}
                          value={values.porcentage}
                          errors={errors.porcentage}
                          touched={touched.porcentage}
                          isMin={1}
                          isMax={100}
                          maxLength={5}
                          decimals
                          suffix="%"
                          isRequired
                        />
                      </Col>
                    )}

                    <Col xs={12} md={12} lg={4}>
                      <FilesInput
                        label="Evidencia"
                        inputName="comprobante"
                        onChangeMethod={onChangeFile}
                        onBlurMethod={handleBlur}
                        fileAccept="application/pdf"
                        touched={touched.comprobante}
                        errors={errors.comprobante}
                        basicReq={false}
                        refInput={refInpFile}
                      />
                    </Col>
                  </Row>
                  
                  <Alert isOpen={!!errors.beneficiary} className="border border-danger text-danger font-weight-bold mx-1 my-2" style={{ backgroundColor: "#FF4B2340" }}>
                      {errors.beneficiary}
                  </Alert>
                  <div>
                    {tipoSeguro("beneficiario") && (
                        <FormBeneficiarios data={values.beneficiary} setData={setFieldValue} relaciones={relaciones} />
                    )}
                  </div>

                  <div className="form-group">
                    <TextAreaInput
                      label="Información Adicional"
                      inputName="notes"
                      inputRows={10}
                      onChangeMethod={onChange}
                      onBlurMethod={handleBlur}
                      value={values.notes}
                      isRequired={false}
                      touched={touched.notes}
                      errors={errors.notes}
                    />
                  </div>

                  <div className="row mt-3">
                    <div className="col-sm-6 order-2 order-sm-1 text-center text-sm-left mb-4">
                      <Button
                        color="danger"
                        type="reset"
                        onClick={() => limpia(resetForm)}
                      >
                        Cancelar
                      </Button>
                    </div>
                    <div className="col-sm-6 order-1 order-sm-2 text-center text-sm-right mb-4">
                      <Button color="success" type="submit">
                        Guardar
                      </Button>
                    </div>
                  </div>
                </form>
              </>
            )}
          </div>
        </Collapse>
        <ModalViewFile
          modal={modalFile}
          setModal={setModalFile}
          backdrop={true}
          keyboard={true}
          btnClose={true}
          fileLink={linkFile}
        />
      </Widget>
    </>
  );
}

const initialValues = {
  idStaffInsurance: "",
  idStaff: "",
  idUnder: "",
  keyInstitution: "",
  keyTypeInsurance: "",
  keyStat: "",
  policy: "0",
  startDate: "",
  endDate: new Date(2099, 11, 31),
  amountTotal: 0,
  amountBiweekly: 0,
  noPays: 0,
  noPaysMade: "",
  notes: "",
  comprobante: "",
  porcentage: "",
  noCertificate: "",
  beneficiary: []
};

const cabeceras = [
  "Id",
  "Poliza",
  "Institución",
  "Tipo de seguro",
  "Fecha de inicio",
  "Estatus",
  "Evidencia",
  "Editar",
  "Eliminar",
];
const filtro = [
  true,
  true,
  true,
  true,
  true,
  true,
  false
];

import React, { useState, useEffect } from "react";
import {  Row, Col } from "reactstrap";
import Loader from "../../../../../components/Loader/Loader";

const DetailsView = ({
  permissions,
  detailTrazability,
  getDetailUpdated,
  trazabilities,
  activeTab,
}) => {
  const [dataUpdated, setDataUpdated] = useState(null);

  useEffect(() => {
    if (trazabilities) {
      setDataUpdated(getDetailUpdated());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trazabilities, activeTab]);

  const dateformatter = (fecha) => {
    let date = new Date(fecha);
    return `${date.getDate()}/${
      date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1
    }/${date.getFullYear()} ${
      date.getHours() < 10 ? `0${date.getHours()}` : date.getHours()
    } : ${
      date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes()
    } : ${
      date.getSeconds() < 10 ? `0${date.getSeconds()}` : date.getSeconds()
    }`;
  };

  return (
    <div>
      {!dataUpdated ? (
        <div className={"text-center"}>
          <Loader />
        </div>
      ) : (
        <>
          <div className="border border-dark mb-3 p-5">
            <Row className="d-flex justify-content-between ml-3 mr-3 mb-3 text-center">
              <Col xs={12} lg={4}>
                <div>
                  <strong>NÚMERO DE TICKET:</strong>
                  <p>{dataUpdated.numTicket ? dataUpdated.numTicket : ""}</p>
                </div>
              </Col>
              <Col xs={12} lg={4}>
                <div>
                  <strong>FECHA:</strong>
                  <p>
                    {dataUpdated.dateAssign
                      ? dateformatter(dataUpdated.dateAssign)
                      : ""}
                  </p>
                </div>
              </Col>
            </Row>
            <Row className="d-flex justify-content-between mb-3 text-justify">
              <Col xs={12} lg={4}>
                <div>
                  <strong>AÑO:</strong>
                  <p>{dataUpdated.yearTicket ? dataUpdated.yearTicket : ""}</p>
                </div>
              </Col>
            </Row>
            <Row className="d-flex  justify-content-between mb-3 text-justify">
              <Col xs={12} lg={4}>
                <div>
                  <strong>ESTATUS:</strong>
                  <p>{dataUpdated.nameStat ? dataUpdated.nameStat : ""}</p>
                </div>
              </Col>

              <Col xs={12} lg={4} className="text-center">
                <div>
                  <strong>FECHA DE SOLICITUD:</strong>
                  <p>
                    {dataUpdated.dateAssigned
                      ? dateformatter(dataUpdated.dateAssigned)
                      : ""}
                  </p>
                </div>
              </Col>
            </Row>
            <Row className="d-flex  justify-content-between mb-3 text-justify">
              <Col xs={12} lg={4}>
                <div>
                  <strong>SEVERIDAD:</strong>
                  <p>
                    {dataUpdated.nameSeverity ? dataUpdated.nameSeverity : ""}
                  </p>
                </div>
              </Col>
              {dataUpdated.dateAttended && (
                <Col xs={12} lg={4} className="text-center">
                  <div>
                    <strong>FECHA ATENDIDO:</strong>
                    <p>
                      {dataUpdated.dateAttended
                        ? dateformatter(dataUpdated.dateAttended)
                        : ""}
                    </p>
                  </div>
                </Col>
              )}
            </Row>
            <Row className="d-flex justify-content-between mb-3 text-justify">
              <Col xs={12} lg={4}>
                <div>
                  <strong>AREA Y EMPLEADO SOLICITADO:</strong>
                  <p>
                    {dataUpdated.fullName && dataUpdated.nameArea
                      ? dataUpdated.fullName + " - " + dataUpdated.nameArea
                      : ""}
                  </p>
                </div>
              </Col>

              {dataUpdated.dateClosed && (
                <Col xs={12} lg={4} className="text-center">
                  <div>
                    <strong>FECHA DE CIERRE:</strong>
                    <p>{dateformatter(dataUpdated.dateClosed)}</p>
                  </div>
                </Col>
              )}
            </Row>
            <Row className="d-flex justify-content-center mb-3 text-center">
              <Col xs={12} lg={4}>
                <div>
                  <strong>TITULO:</strong>
                  <p>{dataUpdated.title ? dataUpdated.title : ""}</p>
                </div>
              </Col>
            </Row>
            <Row className="d-flex justify-content-between mb-3 text-justify">
              <Col xs={12} lg={12}>
                <div>
                  <strong>DESCRIPCIÓN:</strong>
                  <p>
                    {dataUpdated.description ? dataUpdated.description : ""}
                  </p>
                </div>
              </Col>
            </Row>

            {dataUpdated.notesClose && (
              <Row className="d-flex justify-content-between mb-3 text-justify">
                <Col xs={12} lg={12}>
                  <div>
                    <strong>NOTAS DE CIERRE:</strong>
                    <p>
                      {dataUpdated.notesClose ? dataUpdated.notesClose : ""}
                    </p>
                  </div>
                </Col>
              </Row>
            )}
          </div>

          <Row className=" text-left border border-dark mb-5 p-5 ml-3 mr-3 ">
            <Col xs={12} lg={12}>
              <div>
                <strong>NOTAS DEL AREA ANTERIOR: </strong>
                <span>{dataUpdated.notes ? dataUpdated.notes : ""}</span>
              </div>
            </Col>
            <Col xs={12} lg={12}>
              <div>
                <strong>FECHA DE ACTUALIZACION: </strong>
                <span>
                  {dataUpdated.dateUpdated
                    ? dateformatter(dataUpdated.dateUpdated)
                    : ""}
                </span>
              </div>
            </Col>
          </Row>
        </>
      )}

      {/* <Row className="d-flex justify-content-center mt-5 mb-5">
        <Col xs={12} lg={11}>
          <Container className="border border-right-0 text-dark mt-3 mt-lg-0">
            <Row className="bg-primary">
              <Col
                xs={12}
                className="border-bottom border-right py-2 text-light text-center"
              >
                <strong>
                  <p>
                    {detailTrazability.numTicket} - {detailTrazability.title}
                  </p>
                </strong>
              </Col>
            </Row>
            <Row>
              <Col
                xs={12}
                lg={12}
                className="border-bottom border-right pb-3 pt-3 "
              >
                <strong>ESTATUS DEL TICKET:</strong>{" "}
                <div className="d-flex flex-row justify-content-around  pb-3 pt-3 ">
                  <div className="d-flex">
                    <strong>FECHA ASINGACIÓN: </strong>
                    <p>{dateformatter(detailTrazability.dateAssign)}</p>
                  </div>

                  <div className="d-flex">
                    <strong>FECHA ATENDIDO: </strong>
                    <p>{dateformatter(detailTrazability.dateAttended)}</p>
                  </div>
                </div>
                <div className="d-flex flex-row justify-content-around align-items-center pb-3 pt-3 ">
                  <div className="d-flex">
                    <strong>ESTATUS:</strong>
                    <p>{detailTrazability.nameStat}</p>
                  </div>
                  <div className="d-flex">
                    <strong>SEVERIDAD: </strong>
                    <p>{detailTrazability.nameSeverity}</p>
                  </div>
                </div>
                {detailTrazability.dateClosed && (
                  <>
                    <div className="d-flex flex-row justify-content-around align-items-center pb-3 pt-3 ">
                      <div className="d-flex">
                        <strong>FECHA DE CIERRE:</strong>
                        <p>{detailTrazability.dateClosed}</p>
                      </div>
                    </div>
                    <div className="d-flex flex-row justify-content-start align-items-center pb-3 pt-3 ">
                      <div className="d-flex">
                        <strong>DATOS DE CIERRE:</strong>
                        <p>
                          {"AQUI DEBERIAN IR EL MENSAJE DE SOLUCION O CIERRE"}
                        </p>
                      </div>
                    </div>
                  </>
                )}
              </Col>
            </Row>

            <Row>
              <Col
                xs={12}
                lg={12}
                className="border-bottom border-right pb-3 pt-3"
              >
                <strong>DETALLES DEL SOLICITANTE:</strong>{" "}
                <div className="d-flex flex-row justify-content-around align-items-center pb-3 pt-3">
                  <div className="d-flex">
                    <strong>NOMBRE DEL EMPLEADO: </strong>
                    <p>{detailTrazability.fullName}</p>
                  </div>

                  <div className="d-flex">
                    <strong>AREA DEL EMPLEADO:</strong>
                    <p>{detailTrazability.nameArea}</p>
                  </div>
                </div>
              </Col>
            </Row>

            <Row>
              <Col
                xs={12}
                lg={12}
                className="border-bottom border-right pb-3 pt-3"
              >
                <div className="d-flex flex-row justify-content-start align-items-center pb-3 pt-3">
                  <div className="d-flex">
                    <strong>DESCRIPCION: </strong>
                    <p>{detailTrazability.description}</p>
                  </div>
                </div>
              </Col>
            </Row>

            <Row>
              <Col
                xs={12}
                lg={12}
                className="border-bottom border-right pb-3 pt-3"
              >
                <div className="d-flex flex-row justify-content-start align-items-center pb-3 pt-3">
                  <div className="d-flex">
                    <strong>NOTAS DEL AREA ANTERIOR: </strong>
                    <p>{detailTrazability.notes}</p>
                  </div>
                </div>
                <div className="d-flex flex-row justify-content-center align-items-center pb-3 pt-3">
                  <div className="d-flex">
                    <strong>FECHA DE ACTIALIZACION: </strong>
                    <p>{dateformatter(detailTrazability.dateUpdated)}</p>
                  </div>
                </div>
              </Col>
            </Row> 

          </Container>
        </Col>
      </Row>
            */}
    </div>
  );
};

export default DetailsView;

import React, { useEffect, useState } from "react";
import { Card, CardBody, CardText, Input, Row, Col, InputGroup, Button } from "reactstrap";
import { peticionEncript } from "../../helpers/peticionesEncripted";
import axios from "axios";
import { toast } from "react-toastify";
import Loader from "../Loader/Loader";

import Paggination from "./Paggination";
import Notification from "../Notification/Notification";
import { GetPermisos } from "../../pages/patrimonio/bienes-muebles/functions";

export default function Cards({
    title,
    params,
    endpoint,
    card,
    filters,
    refresh,
    setRefresh
}) {

    //FILTROS
    const [column, setColumn] = useState("");
    const [order, setOrder] = useState("ASC");

    //CONTENIDO
    const [content, setContent] = useState([]);
    const [pageSize, setPageSize] = useState(6);
    const [pagesCount, setPagesCount] = useState(0);
    const [totalRegistros, setTotalRegistros] = useState(0);
    const [seccionActual, setSeccionActual] = useState(0);
    const [filtContent, setFiltContent] = useState({
        //Parametros para aplicar filtros a la busqueda
        page: 0,
        currentPage: 0,
        search: "",
        records: 6,
    });

    //CARGA
    const [loading, setLoading] = useState(true)

    async function getData(params, endpoint) {
        const parametros = params;
        parametros.page = filtContent.page ? filtContent.page : 0;
        parametros.search = filtContent.search ? filtContent.search : "";
        parametros.records = filtContent.records ? filtContent.records : 6;
        if (column !== "") {
            parametros.order = `${column} ${order}`
        }
        const route = endpoint ? endpoint : "receiver/receiver.php";
        const finalData = peticionEncript(parametros);
         
        try {
            const res = await axios.post(`${process.env.REACT_APP_API}${route}`, finalData);
            if (res.status === 200 && res.data.code === "200") {
                setTotalRegistros(res.data.totalRecords);
                setPagesCount(Math.ceil(res.data.totalRecords / pageSize));
                setContent(res.data.data)
                setLoading(false);
                setRefresh(false)
            } else {
                setTotalRegistros(0);
                setPagesCount(0);
                setContent([])
                toast(<Notification type={"consultar_error"} backMessage={"Sin registros"} withIcon />);
                setLoading(false);
                setRefresh(false)
            }
        } catch (err) {
            console.log(err);
            toast(<Notification type={"consultar_servidor_error"} withIcon />);
        }
    }

    useEffect(() => {
        if (refresh) {
            setLoading(true)
            getData(params, endpoint)
        }
    }, [refresh])


    const handleOnChange = (e) => {
        setSeccionActual(0);
        setRefresh(true)
        setFiltContent((prevState) => ({
            ...prevState,
            search: e.target.value,
            currentPage: 0,
            page: "",
        }));
    };

    const [permissionsForm, setPermissionsForm] = useState("");

    GetPermisos(setPermissionsForm);

    return (<>
        {title ?
            <h3 className="text-center mb-4">
                {title}
            </h3>
            : null}
        {params ?
            <>
                {permissionsForm.CON === "1" ? (
                    <Row>
                        <Col xs={12} md={6} className="mb-4">
                            <Input
                                value={filtContent.search}
                                name="search"
                                onChange={handleOnChange}
                                placeholder="Buscar..."
                            ></Input>
                        </Col>
                        {filters ? (
                            <Col xs={12} md={6} className="mb-4">
                                <InputGroup>
                                    <Input
                                        autoComplete="off"
                                        placeholder="Ordenar por..."
                                        onChange={(e) => { setRefresh(true); setColumn(e.target.value) }}
                                        type="select"
                                        defaultValue={""}
                                    >
                                        <option disabled value="">Ordenar por</option>
                                        {filters.map((item, index) => (
                                            <option value={item.value} key={`opt${index}`}>
                                                {item.label}
                                            </option>
                                        ))}
                                    </Input>
                                    {column !== "" ?
                                        <Button className="px-3" style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }} onClick={() => { setRefresh(true); setOrder(order === "ASC" ? "DESC" : "ASC") }}>
                                            {order === "ASC" ? <i className="fa fa-sort-amount-asc" /> : <i className="fa fa-sort-amount-desc" />}
                                        </Button>
                                        : null}
                                </InputGroup>
                            </Col>
                        ) : null}
                    </Row>
                ) : null}
                {loading ? (
                    <>
                        <Row className="align-items-center" style={{ minHeight: "200px" }}>
                            <Col>
                                <Loader />
                            </Col>
                        </Row>
                    </>
                ) : (
                    <>
                        <Row>
                            {content.length > 0 ? content.map((item, index) => (
                                <Col md={12} lg={6} className="mb-3" key={`colCard${index}`}>
                                    {card(item)}
                                </Col>
                            )) : (<Col xs={12} className="text-center text-muted my-1"><p>No se encontraron registros</p></Col>)}
                        </Row>
                    </>
                )}
                {permissionsForm.CON === "1" ? (
                    <Paggination
                        filtContent={filtContent}
                        setFiltContent={setFiltContent}
                        pageSize={pageSize}
                        setPageSize={setPageSize}
                        pagesCount={pagesCount}
                        content={content}
                        totalRegistros={totalRegistros}
                        seccionActual={seccionActual}
                        setSeccionActual={setSeccionActual}
                        setRefresh={setRefresh}
                    />
                ) : null}
            </> :
            <>
                <Card className="shadow p-3 mb-5 rounded">
                    <CardBody>
                        <CardText className="text-center">
                            Sin resultados
                        </CardText>
                    </CardBody>
                </Card>
            </>
        }
    </>)
}
import * as XlsxPopulate from "xlsx-populate";
import { saveAs } from "file-saver";

export async function NominaGeneral(report, headers, title, period, filter) {
  // console.log(report);
  const filas = report.map(el => {
    return [el.staffNumber, el.name, el.nameArea, el.payments]
  });

  XlsxPopulate.fromBlankAsync().then(async (workbook) => {
    //** STYLES **//
    const s = {
      title: {
        horizontalAlignment: "center",
        verticalAlignment: "center",
        bold: true
      },
      h: {
        topBorder: "medium",
        bottomBorder: true,
        rightBorder: true,
        fill: "D6D1C4",
        bold: true
      },
      hLeft: {
        topBorder: "medium",
        leftBorder: "medium",
        rightBorder: true,
        bottomBorder: true,
        fill: "D6D1C4",
        bold: true
      },
      hRight: {
        topBorder: "medium",
        rightBorder: "medium",
        leftBorder: true,
        bottomBorder: true,
        fill: "D6D1C4",
        bold: true
      },
      btBold: { topBorder: "medium" },
      row: {
        rightBorder: true,
        bottomBorder: true,
        verticalAlignment: "top",
        wrapText: true
      },
      rowL: {
        leftBorder: "medium",
        rightBorder: true,
        bottomBorder: true,
        verticalAlignment: "top"
      },
      rowR: {
        rightBorder: "medium",
        leftBorder: true,
        bottomBorder: true,
        verticalAlignment: "top",
        wrapText: true
      },
      lRow: {
        rightBorder: true,
        bottomBorder: "medium",
        verticalAlignment: "top",
        wrapText: true
      },
      lRowL: {
        leftBorder: "medium",
        rightBorder: true,
        bottomBorder: "medium",
        verticalAlignment: "top"
      },
      lRowR: {
        rightBorder: "medium",
        leftBorder: true,
        bottomBorder: "medium",
        verticalAlignment: "top",
        wrapText: true
      }
    };

    const sheet1 = workbook.sheet(0);
    sheet1.range("A1:D1").merged(true).value("SAPP").style(s.title);
    sheet1.range("A2:D2").merged(true).value(title).style(s.title);
    sheet1.range("A3:D3").merged(true).value(period).style(s.title);

    const colSize = [25, 35, 50, 70];
    for (let h = 0; h < 4; h++) {
      sheet1.column(h + 1).width(colSize[h]);
      const ht = h === 0 ? s.hLeft : h === 3 ? s.hRight : s.h;
      sheet1
        .row(5)
        .cell(h + 1)
        .value(headers[h])
        .style(ht);
    }
    let st = [];
    for (let i = 0; i < filas.length; i++) {
      if(i === filas.length-1) st = [s.lRowL, s.lRow, s.lRowR];
      else st = [s.rowL, s.row, s.rowR];
      sheet1.row(i+6).cell(1).value(filas[i][0]).style(st[0]);
      sheet1.row(i+6).cell(2).value(filas[i][1]).style(st[1]);
      sheet1.row(i+6).cell(3).value(filas[i][2]).style(st[1]);
      sheet1.row(i+6).cell(4).value(filas[i][3]).style(st[2]);
    }

    return workbook.outputAsync().then((res) => {
      saveAs(res, `${title}.xlsx`);
    });
  });
}


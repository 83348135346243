import React, { useEffect, useState } from "react"
import Inicial from "./Inicial"
import Detalle from "./Detalle";
import Notification from "../../../components/Notification/Notification";
import { toast } from "react-toastify";
import Loader from "../../../components/Loader/Loader";
import Widget from "../../../components/Widget/Widget";

import { peticionesReceiver } from "../../../helpers/peticionesReceiver";
import { GetPermissions } from "../../../libs/permissions/getPermissions";

export default function ConsultaPolizas() {
    GetPermissions();

    const [poliza, setPoliza] = useState();
    const [policy, setPolicy] = useState(null);
    const [parameters, setParameters] = useState(null);

    async function getPolitica(el) {
        const API = peticionesReceiver();
        const params = {
            action: "multiselect",
            rows: "A.idPolicy,B.acronim,A.number,A.year,M.nameMonth,B.nameKindPolicy,D.description,C.nameEvent,A.created,CONCAT(U.name,' ',U.pName,' ',U.mName) as elaboro", ///sin espacios y comas
            table: "policies A INNER JOIN types_policies B USING (keyKIndPolicy) INNER JOIN account_events C USING (idEvent) INNER JOIN policiesdetail D USING (idPolicy) INNER JOIN months M using (keyMonth) INNER JOIN users U using(idUser) ",
            conditions: `A.enabled = 1 AND A.idPolicy=${el[0]}`,
        };
        const res = await API.peticion(params)
        if (res.status === 200 && res.data.code === "200") {
            const data = res.data.data[0];
            if (data) {
                setPolicy(data);
                setParameters({
                    action: "datatable",
                    table: "policiesdetail PD INNER JOIN financialsources FS using(idFinancialSource)",
                    rows: "PD.account,PD.level2,PD.level3,PD.level4,PD.level5,PD.nameAccount,PD.description,FS.nameFinancialSource,PD.charge,PD.pay",
                    conditions: `PD.enabled = 1 && PD.idPolicy = ${data.idPolicy}`,
                    page: 0,
                    records: 5,
                    search: "",
                    order: "",
                })
            } else {
                setPoliza(null)
                setPolicy(null)
                localStorage.removeItem("poliza");
                toast(<Notification type={"consultar_error"} withIcon />);
            }
        } else {
            setPoliza(null)
            setPolicy(null)
            localStorage.removeItem("poliza");
            toast(<Notification type={"consultar_error"} withIcon />);
        }
    }

    useEffect(() => {
        if (!poliza) {
            const tempPoliza = localStorage.getItem("poliza");
            if (tempPoliza) {
                const temp = JSON.parse(tempPoliza)
                getPolitica(temp)
                setPoliza(temp);
            }
        } else {
            getPolitica(poliza)
        }
    }, [poliza])


    return (
        <>
            <Widget className="widget-p-md">
                {!poliza ?
                    <Inicial setPoliza={setPoliza} /> :
                    policy && parameters ?
                        <Detalle setPoliza={setPoliza} poliza={policy} parametros={parameters} /> :
                        <Loader />
                }
            </Widget>
        </>
    )
}
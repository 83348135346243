
import React from "react";

import Widget from "../../../../components/Widget/Widget";
import FormSeguros from "../FormSeguros";
import { updateSeguro } from "../functions";

export const EditarSeguro = (props) => {
  return (
    <Widget className="widget-p-md">
        <h3>Editar tipo de seguro</h3>
        <FormSeguros data={localStorage.getItem('dataContent').split('|-|')} editar={updateSeguro}/>
    </Widget>
  )
}

export default EditarSeguro;
import React from "react";
import Widget from "../../../components/Widget/Widget";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { YearInput } from "../../../components/GenericInputsFormik/YearInput";
import { PERIOD } from "../../../helpers/regex";
import {
  TextInput,
  SelectTypeHeadSingle,
} from "../../../components/GenericInputsFormik/index";
import { Col, Row, Button, Collapse } from "reactstrap";
import CurrencyInput from "react-currency-input-field";
import { useEffect } from "react";

const CalculadoraLayout = () => {
    const actualYear = new Date();

  const FormSchema = Yup.object().shape({
    keyStat: Yup.string().required("Seleccione un estatus"),
    idUnder: Yup.string().required(
      "El impuesto de la multa/sancion es requerido"
    ),
    periodAbcense: Yup.string().required("Seleccione un periodo a descontar"),
    yearAbcense: Yup.string().required("Seleccione un año a descontar"),
    comprobante: Yup.mixed()
      .required("Agregue un comprobante")
      .test("file-type", "El documento debe ser en formato PDF", (value) => {
        return value && value.type === "application/pdf";
      })
      .test({
        message: `El documento debe pesar menos de 4MB`,
        test: (value) => value?.size < 4000000,
      }),
    /* F */
  });
  const getActualPeriod = () => {
    let fecha = new Date();
    let inicio = new Date(fecha.getFullYear(), 0, 1);
    let actual = new Date(fecha.getTime());
    let dias = Math.ceil((actual - inicio + 1) / 86400000);
    return  Math.floor(dias / 15);
  };


  const {
    handleSubmit,
    handleReset,
    values,
    handleBlur,
    errors,
    touched,
    setFieldValue,
    resetForm,
  } = useFormik({
    initialValues: {
        /*
        startPeriod: getActualPeriod(),
        startYear: actualYear,
            endPeriod: 0,
            endYear: "",
            amountTotal: 0,
            amountBiweekly: 0, */
    },
    onSubmit: (values) => {
      
    },
    validationSchema: FormSchema,
    enableReinitialize: true,
  });

  
  const onChange = (e) => {
    setFieldValue([e.target.name], e.target.value);
  };

  useEffect(() => {
    setFieldValue("startPeriod",getActualPeriod())
    setFieldValue("startYear",actualYear)
  },[])
  useEffect(() => {
    
    if (
      values.startYear !== null &&
      values.startPeriod !== "" &&
      values.startYear !== "" &&
      values.noPays &&
      values.amountTotal &&
      values.startPeriod <= 24 &&
      values.amountTotal > 0 &&
      values.noPays > 0
    ) {
      calculaPrestamo();
    }else{
        setFieldValue(["endPeriod"], "");
        setFieldValue(["endYear"], "");
        setFieldValue(["amountBiweekly"], "");
    } 
  }, [values.startPeriod, values.startYear, values.amountTotal, values.noPays]);

  const calculaPrestamo = (e) => {
    var periodoFinal = +values.startPeriod + +values.noPays - 1;
    var anioFinal =
      typeof values.startYear === "number"
        ? values.startYear
        : values.startYear.getFullYear();
    var auxPeriodo = periodoFinal / 24;
    auxPeriodo = Math.floor(auxPeriodo);

    if (periodoFinal % 24 > 0) {
      periodoFinal = periodoFinal - 24 * auxPeriodo;
      //
      anioFinal =
        typeof values.startYear === "number"
          ? values.startYear + auxPeriodo
          : values.startYear.getFullYear() + auxPeriodo;
    } else {
      periodoFinal = 24;
      anioFinal =
        typeof values.startYear === "number"
          ? values.startYear + auxPeriodo - 1
          : values.startYear.getFullYear() + auxPeriodo - 1;
    }

    setFieldValue(["endPeriod"], periodoFinal);
    setFieldValue(["endYear"], new Date(anioFinal, 0, 1));
    setFieldValue(
      ["amountBiweekly"],
      (+values.amountTotal / +values.noPays).toFixed(2)
    );
  };

  return (
    <Widget className="widget-p-md">
      <form>
        {true && (
          <>
            <Row className="mt-2">
              <Col className="text-center">
                <h4>Calculo de layout</h4>
              </Col>
            </Row>
            <Row className="d-flex justify-content-around">
              <Col xs={12} md={5} lg={5}>
                <label htmlFor="amountTotal">
                  Cantidad total <span className="text-danger">*</span>
                </label>
                <CurrencyInput
                  id="amountTotal"
                  name="amountTotal"
                  className={
                    "form-control" +
                    (errors.amountTotal && touched.amountTotal
                      ? " is-invalid"
                      : "")
                  }
                  allowDecimals={true}
                  allowNegativeValue={false}
                  onValueChange={(value, name, e) =>
                    onChange({
                      target: { name: name, value: value },
                    })
                  }
                  value={values.amountTotal}
                  onBlur={handleBlur}
                  min={1}
                  prefix="$"
                  decimalSeparator="."
                  groupSeparator=","
                />
                {touched.amountTotal && errors.amountTotal && (
                  <span className="text-danger">{errors.amountTotal}</span>
                )}
              </Col>
              <Col xs={12} md={5} lg={5}>
                <div className="form-group">
                  <label htmlFor="noChildren">
                    Número de Parcialidades{" "}
                    <span className="text-danger">*</span>
                  </label>
                  <CurrencyInput
                    id="noPays"
                    name="noPays"
                    className={
                      "form-control" +
                      (errors.noPays && touched.noPays ? " is-invalid" : "")
                    }
                    allowDecimals={false}
                    allowNegativeValue={false}
                    onValueChange={(v, n) =>
                      onChange({
                        target: { name: n, value: v },
                      })
                    }
                    // onChange={onChange}
                    onBlur={handleBlur}
                    maxLength={3}
                    value={values.noPays}
                    // step={1}
                    min={1}
                  />
                  {touched.noPays && errors.noPays && (
                    <span className="text-danger">{errors.noPays}</span>
                  )}
                </div>
              </Col>
            </Row>

            <Row className="d-flex justify-content-around">
              <Col xs={12} md={5} lg={5}>
                <SelectTypeHeadSingle
                  label="Inicio de periodo"
                  isRequired={true}
                  optionsArray={PERIOD}
                  inputName="startPeriod"
                  onChangeMethod={onChange}
                  onBlurMethod={handleBlur}
                  touched={touched.startPeriod}
                  errors={errors.startPeriod}
                  optionValue="value"
                  optionName="label"
                  value={values.startPeriod}
                  defaultOption="Seleccione un periodo de inicio"
                />
              </Col>
              <Col xs={12} md={5} lg={5}>
                <YearInput
                  label="Año"
                  inputName="startYear"
                  onChangeMethod={onChange}
                  onBlurMethod={handleBlur}
                  value={values.startYear}
                  isRequired
                  touched={touched.startYear}
                  errors={errors.startYear}
                  isMin={new Date("2019")}
                  isMax={new Date("2500")}
                />
              </Col>
            </Row>
            <Row className="d-flex justify-content-around">
              <Col xs={12} md={5} lg={5}>
                <TextInput
                  label="Periodo de finalización"
                  inputType="number"
                  inputName="endPeriod"
                  onChangeMethod={onChange}
                  onBlurMethod={handleBlur}
                  value={values.endPeriod}
                  isRequired={true}
                  touched={touched.endPeriod}
                  errors={errors.endPeriod}
                  isDisabled={true}
                />
              </Col>
              <Col xs={12} md={5} lg={5}>
              <YearInput
                  label="Año de finalización"
                  inputName="endYear"
                  onChangeMethod={onChange}
                  onBlurMethod={handleBlur}
                  value={values.endYear}
                  isRequired
                  touched={touched.endYear}
                  errors={errors.endYear}
                  isDisabled
                  /* isMin={new Date("2019")}
                  isMax={new Date("2500")} */
                />
                {/* <TextInput
                  label="Año de finalización"
                  inputType="number"
                  inputName="endYear"
                  onChangeMethod={onChange}
                  onBlurMethod={handleBlur}
                  value={values.endYear}
                  isRequired={true}
                  touched={touched.endYear}
                  errors={errors.endYear}
                  isDisabled={true}
                /> */}
              </Col>
            </Row>

            <Row className="d-flex justify-content-around">
              <Col xs={12} md={5} lg={5}>
                <div className="form-group">
                  <label htmlFor="amountBiweekly">
                    Cantidad Quincenal <span className="text-danger">*</span>
                  </label>
                  <CurrencyInput
                    id="amountBiweekly"
                    name="amountBiweekly"
                    className={
                      "form-control" +
                      (errors.amountBiweekly && touched.amountBiweekly
                        ? " is-invalid"
                        : "")
                    }
                    allowDecimals={true}
                    allowNegativeValue={false}
                    onValueChange={(value, name, e) =>
                      onChange({
                        target: { name: name, value: value },
                      })
                    }
                    value={values.amountBiweekly}
                    onBlur={handleBlur}
                    min={1}
                    prefix="$"
                    decimalSeparator="."
                    groupSeparator=","
                    disabled={true}
                  />
                  {touched.amountBiweekly && errors.amountBiweekly && (
                    <span className="text-danger">{errors.amountBiweekly}</span>
                  )}
                </div>
              </Col>
            </Row>
          </>
        )}
      </form>
    </Widget>
  );
};

export default CalculadoraLayout;

import React, { useState, useEffect } from "react";
import { Nav, NavItem, NavLink } from "reactstrap";
import Widget from "../../../components/Widget/Widget";
import s from "./nav.module.scss";
import ExpedienteNominaBuscar from "./ExpedienteNominaBuscar";
import ExpedienteNominaLicencias from "./ExpedienteNominaLicencias";
import ExpedienteNominaPrestacionesGenerales from "./ExpedienteNominaPrestacionesGenerales";
import ExpedienteNominaPrestamos from "./ExpedienteNominaPrestamos";
import ExpedienteNominaPensiones from "./ExpedienteNominaPensiones";
import ExpedienteNominaGuarderias from "./ExpedienteNominaGuarderias";
import ExpedienteNominaIncapacidades from "./ExpedienteNominaIncapacidades";
import ExpedienteNominaSeguros from "./ExpedienteNominaSeguros";
import ExpedienteNominaMultas from "./ExpedienteNominaMultas";
import ExpedienteNominaInasistencias from "./ExpedienteNominaInasistencias";
import { peticionesReceiver } from "../../../helpers/peticionesReceiver";
import * as Icons from "@material-ui/icons";
import { GetPermissions } from "../../../libs/permissions/getPermissions";
import FullFormLoader from "../../../components/Loader/FullFormLoader";
import { doRequest } from "../../../helpers/requests";
import ExpedienteNominaPlazas from "./ExpedienteNominaPlazas";

const tabNumbers = {
  ASIGNACIONDEPLAZAS: 3,
  PRESTACIONESGENERALES: 4,
  LICENICAS: 5,
  INCAPACIDADES: 6,
  MULTAS: 7,
  INASISTENCIAS: 8,
  SEGUROS: 9,
  PRESTAMOS: 10,
  ESTANCIAS: 11,
  PENSIONES: 12,
}
const ExpedienteNomina = () => {
  const [permissions, setPermissions] = useState({});
  const [permissionsTabs, setPermissionsTabs] = useState({});
  GetPermissions(setPermissions, null, 33, setPermissionsTabs);

  const API = peticionesReceiver();
  const [activeTab, setActiveTab] = useState(1);
  const [staff, setStaff] = useState({
    idStaff: "",
    staffNumber: "",
    fullName: "",
    employType: "",
    nameStat: "",
    nameArea: "",
    nameJob: "",
    picture: "",
  });

  useEffect(() => {
    if (staff.idStaff !== "") {
      const ltab = findTab();
      toggleTab(ltab);
    }
  }, [staff]);

  function findTab(){
    const keysTabs = Object.keys(tabNumbers);
    const keysPerm = Object.keys(permissionsTabs);
    const fnd = keysTabs.find(k => keysPerm.includes(k));
    if(fnd) return tabNumbers[fnd];
    return 1;
  }

  const resetStaff = () => {
    setStaff({ idStaff: "", fullName: "" });
    toggleTab(1);
  };
  
  const updateJob = async (idStaff, areafisica) => {
    const params = {
      action: "datatable",
      table: "staffview A, status B, areas C",
      rows: "A.nameArea, A.nameJob, B.nameStat, C.name AS nameFisica",
      conditions: `A.idStaff = ${idStaff} AND B.keyStat = A.keyStat AND C.idArea = ${areafisica}`,
      page: 0,
      records: 5,
      search: "",
      order: "",
    };
    const res = await doRequest("receiver/receiver.php", params, false, false);
    if (res.length > 0) {
      setStaff((prevState) => ({
        ...prevState,
        nameArea: res[0].nameArea,
        nameBuilding: res[0].nameFisica,
        nameJob: res[0].nameJob,
        nameStat: res[0].nameStat,
      }));
    }
  };

  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  function showNav(nmd) {
    if (permissionsTabs[nmd]) return permissionsTabs[nmd].CON;
    return Object.keys(permissionsTabs).length < 1;
  }

  return (
    <Widget className="widget-p-md">
      <h3 className="text-secondary">Expediente de Nomina</h3>
      <Nav fill tabs style={{ overflowY: "hidden", overflowX: "auto" }}>
        <NavItem active={activeTab === 1}>
          <NavLink
            href="#"
            active={activeTab === 1}
            onClick={() => toggleTab(1)}
            disabled={staff.idStaff === "" ? false : true}
            className={activeTab === 1 ? "text-primary" : ""}
          >
            <i className={s.navIco + " fa fa-user"} />
            Buscar empleado
          </NavLink>
        </NavItem>
        {showNav("ASIGNACIONDEPLAZAS") && (
          <NavItem active={activeTab === 3}>
            <NavLink
              href="#"
              active={activeTab === 3}
              onClick={() => toggleTab(3)}
              disabled={staff.idStaff === "" ? true : false}
              className={activeTab === 3 ? "text-primary" : ""}
            >
              <i className={s.navIco + " fa fa-id-card-o"} />
              Asignación de plazas
            </NavLink>
          </NavItem>
        )}
        {showNav("PRESTACIONESGENERALES") && (
          <NavItem active={activeTab === 4}>
            <NavLink
              href="#"
              active={activeTab === 4}
              onClick={() => toggleTab(4)}
              disabled={staff.idStaff === "" ? true : false}
              className={activeTab === 4 ? "text-primary" : ""}
            >
              <i className={s.navIco + " fa fa-university"} />
              Prestaciones Extraordinarias
            </NavLink>
          </NavItem>
        )}
        {showNav("LICENICAS") && (
          <NavItem active={activeTab === 5}>
            <NavLink
              href="#"
              active={activeTab === 5}
              onClick={() => toggleTab(5)}
              disabled={staff.idStaff === "" ? true : false}
              className={activeTab === 5 ? "text-primary" : ""}
            >
              <i className={s.navIco + " fa fa-id-card-o"} />
              Licencias
            </NavLink>
          </NavItem>
        )}
        {showNav("INCAPACIDADES") && (
          <NavItem active={activeTab === 6}>
            <NavLink
              href="#"
              active={activeTab === 6}
              onClick={() => toggleTab(6)}
              disabled={staff.idStaff === "" ? true : false}
              className={activeTab === 6 ? "text-primary" : ""}
            >
              <i className={s.navIco + " fa fa-wheelchair"} />
              Incapacidades
            </NavLink>
          </NavItem>
        )}
        {showNav("MULTAS") && (
          <NavItem active={activeTab === 7}>
            <NavLink
              href="#"
              active={activeTab === 7}
              onClick={() => toggleTab(7)}
              disabled={staff.idStaff === "" ? true : false}
              className={activeTab === 7 ? "text-primary" : ""}
            >
              <Icons.GavelOutlined className={s.navIco} />
              Multas
            </NavLink>
          </NavItem>
        )}
        {showNav("INASISTENCIAS") && (
          <NavItem active={activeTab === 8}>
            <NavLink
              href="#"
              active={activeTab === 8}
              onClick={() => toggleTab(8)}
              disabled={staff.idStaff === "" ? true : false}
              className={activeTab === 8 ? "text-primary" : ""}
            >
              <Icons.GavelOutlined className={s.navIco} />
              Inasistencias
            </NavLink>
          </NavItem>
        )}
        {showNav("SEGUROS") && (
          <NavItem active={activeTab === 9}>
            <NavLink
              href="#"
              active={activeTab === 9}
              onClick={() => toggleTab(9)}
              disabled={staff.idStaff === "" ? true : false}
              className={activeTab === 9 ? "text-primary" : ""}
            >
              <i className={s.navIco + " eva eva-shield-outline"} />
              Seguros
            </NavLink>
          </NavItem>
        )}
        {showNav("PRESTAMOS") && (
          <NavItem active={activeTab === 10}>
            <NavLink
              href="#"
              active={activeTab === 10}
              onClick={() => toggleTab(10)}
              disabled={staff.idStaff === "" ? true : false}
              className={activeTab === 10 ? "text-primary" : ""}
            >
              <i className={s.navIco + " fa fa-money"} />
              Prestamos
            </NavLink>
          </NavItem>
        )}
        {showNav("ESTANCIAS") && (
          <NavItem active={activeTab === 11}>
            <NavLink
              href="#"
              active={activeTab === 11}
              onClick={() => toggleTab(11)}
              disabled={staff.idStaff === "" ? true : false}
              className={activeTab === 11 ? "text-primary" : ""}
            >
              <i className={s.navIco + " fa fa-child"} />
              Guarderias
            </NavLink>
          </NavItem>
        )}
        {showNav("PENSIONES") && (
          <NavItem active={activeTab === 12}>
            <NavLink
              href="#"
              active={activeTab === 12}
              onClick={() => toggleTab(12)}
              disabled={staff.idStaff === "" ? true : false}
              className={activeTab === 12 ? "text-primary" : ""}
            >
              <i className={s.navIco + " fa fa-user-plus"} />
              Pensiones
            </NavLink>
          </NavItem>
        )}
      </Nav>
      <div className="mt-4">
        {staff.idStaff !== "" && activeTab !== 1 && (
          <div className="d-flex flex-md-row flex-column-reverse justify-content-md-between justify-content-center">
            <div className="d-flex flex-md-row flex-column">
              <div className="d-flex justify-content-center">
                {staff.picture !== "" && (
                  <img src={staff.picture} className="img-thumbnail w-100 exp-photo" alt="employe" style={{minWidth: "150px"}}/>
                )}
              </div>
              <div className="text-dark mt-2 mt-md-0 pl-0 pl-md-2">
                <p><strong>No. Empleado: </strong>{ staff.staffNumber}</p>
                <p><strong>Nombre: </strong>{ staff.fullName}</p>
                <p><strong>Puesto Laboral: </strong>{ staff.nameJob || "POR ASIGNAR"}</p>
                <p><strong>Adscripción Nominal: </strong>{ staff.nameArea || "POR ASIGNAR"}</p>
                <p><strong>Adscripción Fisica: </strong>{ staff.nameBuilding || "POR ASIGNAR"}</p>
                <p><strong>Estatus: </strong>{ staff.nameStat}</p>
              </div>
            </div>
            <div className="my-2 my-md-0 text-center">
              <button className="btn text-light btn-block" style={{ backgroundColor: "#cc0a44", border: "none" }} onClick={resetStaff} >
                Cerrar expediente
              </button>
            </div>
          </div>
        )}
        {activeTab === 1 ? (
          <ExpedienteNominaBuscar setStaff={setStaff} toggleTab={toggleTab} />
        ) : activeTab === 3 ? (
          <div>
            <ExpedienteNominaPlazas
              idStaff={staff.idStaff}
              updateData={updateJob}
              permissions={permissionsTabs.ASIGNACIONDEPLAZAS}
            />
          </div>
        ) : activeTab === 4 ? (
          <div>
            <ExpedienteNominaPrestacionesGenerales
              idStaff={staff.idStaff}
              permissions={permissionsTabs.PRESTACIONESGENERALES}
              staffData={staff}
            />
          </div>
        ) : activeTab === 5 ? (
          <ExpedienteNominaLicencias
            idStaff={staff.idStaff}
            permissions={permissionsTabs.LICENICAS}
          />
        ) : activeTab === 6 ? (
          <div>
            <ExpedienteNominaIncapacidades
              idStaff={staff.idStaff}
              permissions={permissionsTabs.INCAPACIDADES}
            />
          </div>
        ) : activeTab === 7 ? (
          <div>
            <ExpedienteNominaMultas
              idStaff={staff.idStaff}
              permissions={permissionsTabs.MULTAS}
            />
          </div>
        ) : activeTab === 8 ? (
          <div>
            <ExpedienteNominaInasistencias
              idStaff={staff.idStaff}
              permissions={permissionsTabs.INASISTENCIAS}
            />
          </div>
        ) : activeTab === 9 ? (
          <div>
            <ExpedienteNominaSeguros
              idStaff={staff.idStaff}
              permissions={permissionsTabs.SEGUROS}
              idModule={33}
            />
          </div>
        ) : activeTab === 10 ? (
          <div>
            <ExpedienteNominaPrestamos
              idStaff={staff.idStaff}
              permissions={permissionsTabs.PRESTAMOS}
            />
          </div>
        ) : activeTab === 11 ? (
          <ExpedienteNominaGuarderias
            idStaff={staff.idStaff}
            permissions={permissionsTabs.ESTANCIAS}
          />
        ) : (
          <div>
            <ExpedienteNominaPensiones
              idStaff={staff.idStaff}
              toggleTab={toggleTab}
              permissions={permissionsTabs.PENSIONES}
            />
          </div>
        )}
      </div>
      <FullFormLoader show={Object.keys(permissionsTabs).length < 1} />
    </Widget>
  );
};
export default ExpedienteNomina;

import { useFormik } from "formik";
import * as Yup from "yup";
import React, { useEffect, useState } from "react";
import { Modal, ModalHeader, ModalBody, Row, Col, Button } from "reactstrap";
import axios from "axios";
import { toast } from "react-toastify";
import {
  FilesInput,
  SelectTypeHeadSingle,
  TextAreaInput,
  TextInput,
} from "../../../components/GenericInputsFormik";
import FullFormLoader from "../../../components/Loader/FullFormLoader";
import ModalConfirmation from "../../../components/Modal/ModalConfirmation";
import Notification from "../../../components/Notification/Notification";
import { peticionEncript } from "../../../helpers/peticionesEncripted";

/* Formualrio */
const defaultForm = {
  keyBank: "", //
  keyAccountType: "",
  account: "",
  keyTypePayrollRequestReturns: "",
  afterReturn: "",
};

const FormSchema = Yup.object().shape({
  keyBank: Yup.string().when("keyTypePayrollRequestReturns", {
    is: (val) => +val === 2,
    then: Yup.string().required("Seleccione un banco"),
  }),
  keyAccountType: Yup.string().when("keyTypePayrollRequestReturns", {
    is: (val) => +val === 2,
    then: Yup.string().required("Seleccione un tipo de cuenta"),
  }),
  account: Yup.string().when("keyTypePayrollRequestReturns", {
    is: (val) => +val === 2,
    then: Yup.string().required("Ingrese un No. de cuenta o Clabe"),
  }),
  keyTypePayrollRequestReturns: Yup.string().required(
    "Seleccione una solución"
  ),
  afterReturn: Yup.string().required("Ingrese una Descripción"),
});

async function doRequest(route, params, seeOk, encript) {
  let info = [];
  const dataEncrypted = encript !== false ? peticionEncript(params) : params;
  await axios
    .post(process.env.REACT_APP_API + route, dataEncrypted)
    .then((res) => {
      if (res.status === 200 && res.data.status === "success") {
        info = res.data;
        if (seeOk) {
          toast(
            <Notification
              type={"elimina_exito"}
              backMessage={res.data.message}
              withIcon
            />,
            { closeButton: false }
          );
        }
      } else {
        toast(
          <Notification
            type={"consultar_error"}
            backMessage={res.data.message}
            withIcon
          />,
          { closeButton: false }
        );
      }
    })
    .catch((err) => {
      toast(<Notification type={"consultar_servidor_error"} withIcon />, {
        closeButton: false,
      });
    });
  return info;
}

function ResolverRechazo(props) {
  const {
    modal,
    setModal,
    idPayrollRequestReturn,
    idPayrollRequest,
    getRechazos,
    empleado,
    soluciones,
    bancos,
    tiposCuentas,
  } = props;
  const [modalConfirm, setModalConfirm] = useState(false);
  const [loadingForm, setLoadingForm] = useState(false);

  const {
    handleSubmit,
    handleReset,
    handleBlur,
    values,
    errors,
    touched,
    setFieldValue,
  } = useFormik({
    initialValues: {
      ...defaultForm,
      keyStat: module === "nomina" ? "75" : "",
    },
    validationSchema: FormSchema,
    onSubmit: () => setModalConfirm(true),
    onReset: () => {},
    enableReinitialize: true,
  });

  useEffect(() => {
    if (modal) {
    } else {
      handleReset();
    }
  }, [modal]);

  const onChange = (e) => {
    setFieldValue(e.target.name, e.target.value);
  };

  const saveAclaracion = async () => {
    setLoadingForm(true);
    const rows = {
      ...values,
      idPayrollRequestReturn: idPayrollRequestReturn,
      idPayrollRequest: idPayrollRequest,
    };
    const params = {
      action: "insertAclaracion",
      rows: rows,
    };
    const data = await doRequest(
      "app/facades/payslips/payrollrequestF.php",
      params,
      true,
      true
    );
    if (data.status === "success") {
      await getRechazos();
      setModal(false);
    }
    setLoadingForm(false);
  };

  const toggle = () => {
    setModal(!modal);
  };

  const CloseBtn = (
    <i className="eva eva-close cursor-pointer" onClick={toggle} />
  );

  return (
    <Modal
      isOpen={modal}
      backdrop={true}
      keyboard
      toggle={toggle}
      style={{ minWidth: "95%" }}
    >
      <ModalHeader toggle={toggle} close={CloseBtn}>
        {/* Rechazo de pago por Empleado */}
      </ModalHeader>
      <ModalBody className="px-5 pt-0 mt-0 text-default">
        <h4 className="text-default">Realizar aclaración</h4>
        <form onSubmit={handleSubmit} onReset={handleReset} className="m-5">
          <ModalConfirmation
            modalTitle="Guardar aclaración"
            modal={modalConfirm}
            setModal={setModalConfirm}
            editar={() => saveAclaracion()}
            isEdit={true}
            values={values}
          >
            <div className="d-flex justify-content-center text-center mx-3">
              <h6>
                ¿Está seguro de guardar la aclaración para el pago del empleado{" "}
                <span style={{ fontWeight: "bold" }}>{empleado}</span>?
              </h6>
            </div>
          </ModalConfirmation>
          <Row>
            <Col xs={12} lg={5}>
              <SelectTypeHeadSingle
                label="Solución"
                isRequired={true}
                inputName="keyTypePayrollRequestReturns"
                optionsArray={soluciones}
                defaultOption="Seleccione una solución"
                onChangeMethod={onChange}
                onBlurMethod={handleBlur}
                value={values.keyTypePayrollRequestReturns}
                touched={touched.keyTypePayrollRequestReturns}
                errors={errors.keyTypePayrollRequestReturns}
                optionName="nameTypePayrollRequestReturns"
                optionValue="keyTypePayrollRequestReturns"
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <TextAreaInput
                label="Descripción"
                inputName="afterReturn"
                inputRows={6}
                onChangeMethod={onChange}
                onBlurMethod={handleBlur}
                value={values.afterReturn}
                isRequired
                touched={touched.afterReturn}
                errors={errors.afterReturn}
              />
            </Col>
          </Row>

          {values.keyTypePayrollRequestReturns === 2 && (
            <Row>
              <Col xs={12} lg={6}>
                <SelectTypeHeadSingle
                  label="Banco"
                  isRequired={true}
                  inputName="keyBank"
                  optionsArray={bancos}
                  defaultOption="Seleccione un banco"
                  onChangeMethod={onChange}
                  onBlurMethod={handleBlur}
                  value={values.keyBank}
                  touched={touched.keyBank}
                  errors={errors.keyBank}
                  optionName="nameBank"
                  optionValue="keyBank"
                />
              </Col>
              <Col xs={12} lg={6}>
                <SelectTypeHeadSingle
                  label="Tipo cuenta"
                  isRequired={true}
                  inputName="keyAccountType"
                  optionsArray={tiposCuentas}
                  defaultOption="Seleccione un tipo de cuenta"
                  onChangeMethod={onChange}
                  onBlurMethod={handleBlur}
                  value={values.keyAccountType}
                  touched={touched.keyAccountType}
                  errors={errors.keyAccountType}
                  optionName="nameAccountType"
                  optionValue="keyAccountType"
                />
              </Col>
              <Col xs={12} lg={6}>
                <TextInput
                  label="No. de cuenta o Clabe"
                  inputName="account"
                  inputType="text"
                  onChangeMethod={onChange}
                  onBlurMethod={handleBlur}
                  value={values.account}
                  isRequired
                  touched={touched.account}
                  errors={errors.account}
                />
              </Col>
            </Row>
          )}
          <div className="text-center text-md-right my-4">
            <Button color="success" type="submit">
              Aceptar
            </Button>
          </div>
        </form>
        <FullFormLoader show={loadingForm} />
      </ModalBody>
    </Modal>
  );
}

export default ResolverRechazo;

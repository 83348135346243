import classNames from "classnames";
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Collapse,
  Row,
  Table,
  Tooltip,
} from "reactstrap";
import { useLocation } from "react-router-dom";
import {
  SelectTypeHeadSingle,
  TextAreaInput,
} from "../../../components/GenericInputsFormik";
import { YearInput } from "../../../components/GenericInputsFormik/YearInput";
import FullFormLoader from "../../../components/Loader/FullFormLoader";
import ModalConfirmation from "../../../components/Modal/ModalConfirmation";
import st from "../pagos-transferencias-empleados/PagosTrasferenciasEmpleados.module.scss";
import React, { useEffect, useRef, useState } from "react";
import Notification from "../../../components/Notification/Notification";
import { toast } from "react-toastify";
import axios from "axios";
import { peticionEncript } from "../../../helpers/peticionesEncripted";
import Widget from "../../../components/Widget/Widget";
import { PERIOD } from "../../nomina/calculo/regex";
import ModalViewFile from "../../../components/Modal/ModalViewFile";
import { saveAs } from "file-saver";
import { PDFDocument } from "pdf-lib";
import ModalReports from "../../../components/Modal/ModalReports";
import pdfProveedoresN from "../reportes/pdf/pdfProveedoresN";
import { GetPermissions } from "../../../libs/permissions/getPermissions";

const finanzas = "pago-proveedores-nomina";
const nomina = "pagoproveedoresnomina";

const fmt = new Intl.NumberFormat("es-MX", {
  style: "currency",
  currency: "MXN",
});

const defaultFiltro = {
  period: "",
  year: new Date(),
};
const date = new Date().getFullYear();

async function doRequest(route, params, seeOk, encript, specialRes) {
  let info = [];
  const dataEncrypted = encript !== false ? peticionEncript(params) : params;
  await axios
    .post(process.env.REACT_APP_API + route, dataEncrypted)
    .then((res) => {
      if (res.status === 200 && res.data.status === "success") {
        info = specialRes ? res.data : res.data.data;
        if (seeOk) {
          toast(
            <Notification
              type={"elimina_exito"}
              backMessage={res.data.message}
              withIcon
            />,
            { closeButton: false }
          );
        }
      } else {
        toast(
          <Notification
            type={"consultar_error"}
            backMessage={res.data.message}
            withIcon
          />,
          { closeButton: false }
        );
      }
    })
    .catch((err) => {
      toast(<Notification type={"consultar_servidor_error"} withIcon />, {
        closeButton: false,
      });
    });
  return info;
}

const PagosProveedoresNomina = () => {
  const [permissions, setPermissions] = useState({});
  GetPermissions(setPermissions);

  const [activeCollapse, setActiveCollapse] = useState(1);
  const [loadingTab1, setLoadingTab1] = useState(false);
  const [loadingTab2, setLoadingTab2] = useState(false);
  const [modalSavePagos, setModalSavePagos] = useState(false); /// Modal para guardar todos los pagos
  const [modalReport, setModalReport] = useState(false); /// Modal para reportes
  const [filtro, setFiltro] = useState({ ...defaultFiltro }); /// Formulario apra seleccionar la solicitus de pago
  const [infoProveedores, setInfoProveedores] = useState({}); /// Informacion de los proveedores
  const [cuentasBancarias, setCuentasBancarias] = useState([]); /// Informacion de selects
  const [proveedores, setProveedores] = useState([]); /// Lista de proveddores
  const [canSave, setCanSave] = useState(true); /// Para mostrar el botón de guardar pagos
  const [notesPagos, setNotesPagos] = useState(""); /// Observacion de los pagos
  const [alertOpen, setAlertOpen] = useState(false); /// Mostrar la alerta con multiples errores
  const [errorsArr, setErrorsArr] = useState([]); /// Arreglo con errores
  const [infoReport, setInfoReport] = useState({}); //Info para hacer el reporte
  const [pdfConfig, setPdfConfig] = useState({}); //PDFConfig

  const location = useLocation();
  const route = location.pathname.split("/")[2];
  const alertRef = React.useRef(null);

  /* Cambiar */
  useEffect(() => {
    if (activeCollapse === 1) {
      if (loadingTab1) setLoadingTab1(false);
    } else {
      if (loadingTab2) setLoadingTab2(false);
    }
  }, [activeCollapse]);

  const onChange = (e) => {
    if (e.target.name === "year") {
      if (e.target.value !== null)
        setFiltro((prevState) => ({ ...prevState, year: e.target.value }));
      else setFiltro((prevState) => ({ ...prevState, year: new Date() }));
    } else if (e.target.name === "period") {
      setFiltro((prevState) => ({ ...prevState, period: e.target.value }));
    }
  };

  /* Buscar solicitud de pago */
  const findSolicitudPago = async () => {
    setLoadingTab1(true);
    const params = {
      action: "multiselect",
      table:
        "payrollrequest A INNER JOIN payrollrequestnotes B ON B.idPayrollRequest = A.idPayrollRequest",
      rows: "A.idPayrollRequest AS id, GROUP_CONCAT(B.keyStat) AS statuses",
      conditions: ` A.period = ${
        filtro.period
      } AND A.year = ${filtro.year.getFullYear()} AND A.enabled = 1`,
      group: "id",
    };
    const data = await doRequest("receiver/receiver.php", params);
    if (data.length > 0) {
      const statuses = data[0].statuses.split(",");
      if (checkStat(statuses)) {
        await getProveedores(data[0].id, statuses);
      } else {
        toast(
          <Notification
            type={"warning"}
            backMessage="No se ha concluido el pago a proveedores de este periodo"
            withIcon
          />,
          { closeButton: false }
        );
      }
    }
    setLoadingTab1(false);
  };

  const getProveedores = async (id, statuses) => {
    const params = {
      action: "searchProvidersbanks",
      rows: { idPayrollRequest: id ? id : infoProveedores.id },
    };
    const data = await doRequest(
      "app/facades/payslips/payrollrequestF.php",
      params
    );
    if (data.length > 0) {
      setCuentasBancarias(data[0].cuentasBancarias);
      let badCase = false;
      const prov = data[0].proveedores.map((p) => {
        let chequera = "";
        if (p.idBankAccount !== "") {
          chequera = data[0].cuentasBancarias.find(
            (b) => b.idBankAccount === p.idBankAccount
          );
        } else if (!badCase) badCase = true;
        if (chequera !== null) chequera = chequera.cuenta;
        return {
          ...p,
          chequera: chequera,
        };
      });
      setProveedores(prov);
      const sumAmmount = data[0].proveedores.reduce(
        (acc, b) => acc + +b.amount,
        0
      );
      setCanSave(!badCase);
      setInfoProveedores({
        id: id ? id : infoProveedores.id,
        depositos: data[0].proveedores.length,
        importe: sumAmmount,
        keyStat: data[0].keyStat,
        statuses: statuses ? statuses : infoProveedores.statuses,
      });
      setLoadingTab2(false);
      setActiveCollapse(2);
    } else {
      setActiveCollapse(1);
      setLoadingTab1(false);
    }
  };

  /* Guardar pagos de empelados o pensiones */
  const savePago = async () => {
    setLoadingTab2(true);
    const params = {
      action: "finishPagosProveedores",
      rows: { idPayrollRequest: infoProveedores.id, notes: notesPagos },
    };
    const data = await doRequest(
      "app/facades/payslips/payrollrequestF.php",
      params,
      true,
      true,
      false
    );
    if (data.length > 0) findSolicitudPago();
    else {
      setErrorsArr(data.message);
      setAlertOpen(true);
      scrollTo();
      setLoadingTab2(false);
    }
  };

  /* Revisar historia de status de la solicitud */
  const checkStat = (statuss) => {
    if (route === nomina) return statuss.includes("79");
    return true;
  };

  const showButton = () => {
    if (route === finanzas) {
      if (infoProveedores.statuses)
        return !infoProveedores.statuses.includes("79");
    }
    return false;
  };

  /* Juntar y descargar todos los comprobantes */
  const downloadAllComp = async () => {
    let comprobantes = proveedores.filter((b) => b.rutaComprobante !== "");
    if (comprobantes.length === 0) {
      toast(
        <Notification
          type={"warning"}
          backMessage="No se han subido comprobantes de pago"
          withIcon
        />,
        { closeButton: false }
      );
      return;
    }
    setLoadingTab2(true);
    try {
      const pdfDoc = await PDFDocument.create();
      for (const f in comprobantes) {
        /// Obtener comprobantes
        let res = await axios.get(comprobantes[f].rutaComprobante, {
          responseType: "arraybuffer",
        });
        const doc = await PDFDocument.load(res.data);
        const [page] = await pdfDoc.copyPages(doc, [0]);
        pdfDoc.addPage(page);
      }

      const pdfBytes = await pdfDoc.save(); /// Genear pdf
      const bytes = new Uint8Array(pdfBytes);
      const blobFile = new Blob([bytes], { type: "application/pdf" });

      saveAs(
        /// Guardar pdf
        blobFile,
        `Comprobantes de pago ${filtro.period}_${filtro.year.getFullYear()}`
      );
    } catch (error) {
      console.error(error);
      toast(
        <Notification
          type={"consultar_error"}
          backMessage={`No fue posible descargar los comprobantes`}
          withIcon
        />,
        { closeButton: false }
      );
    }
    setLoadingTab2(false);
  };

  /* Función limpair la info al cerrar */
  const clearData = () => {
    setCuentasBancarias([]);
    setProveedores([]);
    setInfoProveedores({});
    setErrorsArr([]);
    setAlertOpen(false);
    setNotesPagos("");
    setCanSave(true);
  };

  /* Función para scrolear a un punto de la aplicación */
  const scrollTo = () => {
    setTimeout(
      () => alertRef.current.scrollIntoView({ behavior: "smooth" }),
      200
    );
  };

  /* Generar reporte Auxiliar*/
  const makeReport = async (idAL) => {
    const params = {
      action: "getPagosProveedoresNomina",
      // search: "",
      // order: "",
      period: filtro.period,
      year: filtro.year.getFullYear(),
      detalle: "1",
      idAccountList: idAL,
    };
    setLoadingTab2(true);
    const data = await doRequest(
      "app/facades/reports/reportsFiTeF.php",
      params
    );

    if (data.length > 0) {
      setInfoReport(data);
      setPdfConfig({
        period: filtro.period,
        year: filtro.year.getFullYear(),
        detalle: "1",
      });
      setModalReport(true);
    }
    setLoadingTab2(false);
  };

  /* Para mostrar titluod e la columna crear/editar */
  const setHeader = () => {
    if(permissions.INS && permissions.UPD) return (<>Guardar/ <br/>Reverso</>);
    if(permissions.INS) return (<>Guardar</>);
    if(permissions.UPD) return (<>Reverso</>);
  }

  return (
    <Widget className="widget-p-md">
      <div className="mb-4 d-flex justify-content-between flex-column flex-md-row text-center text-md-left">
        <h4>Pagos de Proveedores de Nómina</h4>

        {Object.keys(infoProveedores).length > 0 && (
          <div className="mt-2 mt-md-0">
            <Button
              className="btn text-light"
              style={{ backgroundColor: "#ba262f", border: "none" }}
              onClick={() => setActiveCollapse(1)}
            >
              Cerrar
              <i className="fa fa-sign-out ml-2" style={{ fontSize: "16px" }} />
            </Button>
          </div>
        )}
      </div>

      {/* Form filtro */}
      <Collapse isOpen={activeCollapse === 1}>
        <Row>
          <Col xs={12} lg={4}>
            <SelectTypeHeadSingle
              label="Periodo"
              isRequired={true}
              inputName="period"
              optionsArray={PERIOD}
              defaultOption="Seleccione un periodo"
              onChangeMethod={onChange}
              value={filtro.period}
              optionValue="value"
              optionName="label"
            />
          </Col>
          <Col xs={12} lg={4}>
            <YearInput
              label="Año"
              inputName="year"
              onChangeMethod={onChange}
              value={filtro.year}
              isMin={date - 1}
              isRequired
            />
          </Col>
          <Col xs={12} lg={2}>
            <div className="pt-lg-4 mt-lg-2 text-center">
              <Button
                color="info"
                onClick={() => findSolicitudPago()}
                disabled={filtro.period === "" || filtro.year === ""}
              >
                Consultar
              </Button>
            </div>
          </Col>
        </Row>
        <FullFormLoader show={loadingTab1} />
      </Collapse>

      {/* Content */}
      <Collapse
        isOpen={activeCollapse === 2}
        onExited={clearData}
        className="border-top"
      >
        <div className="mt-4 pt-3"></div>
        <Card className="mb-4 border border-secondary">
          <CardHeader className="text-center bg-secondary text-white" tag="h6">
            Importe a Pagar
          </CardHeader>
          <CardBody>
            <div className="text-center text-wrap" style={{ color: "#343a40" }}>
              <h4 className="font-weight-bold">
                Proveedores de Nómina
              </h4>

              <Row className="mt-3" style={{ fontSize: "20px" }}>
                <Col xs={12} md={5}>
                  <div className="text-md-right">No. de Depositos:</div>
                </Col>
                <Col xs={12} md={6}>
                  <div>{infoProveedores.depositos}</div>
                </Col>
              </Row>
              <Row className="mt-3" style={{ fontSize: "20px" }}>
                <Col xs={12} md={5}>
                  <div className="text-md-right">TOTAL NETO:</div>
                </Col>
                <Col xs={12} md={6}>
                  <div>
                    <span className="border-bottom border-dark px-3">
                      {fmt.format(infoProveedores.importe)}
                    </span>
                  </div>
                </Col>
              </Row>
            </div>
          </CardBody>
        </Card>

        <div className="mt-5">
          <div className="text-center" ref={alertRef}>
            <h4>Servidores Publicos</h4>
          </div>

          <div className="mt-4 d-flex justify-content-end  flex-wrap">
            <Button
              color="primary"
              className=" my-2"
              onClick={() => downloadAllComp()}
            >
              Descargar Comprobantes
              <i className="fa fa-download pl-2" />
            </Button>
          </div>

          <div className="mt-3">
            <Alert
              isOpen={alertOpen}
              toggle={() => setAlertOpen(!alertOpen)}
              color="danger"
              style={{
                backgroundColor: "#f6435550",
                borderLeft: "4px solid #FF4B23",
                color: "#f64355",
              }}
            >
              {errorsArr.map((el, id) => (
                <li key={id}>{el}</li>
              ))}
            </Alert>
          </div>

          <Table
            responsive
            className="table-striped table-borderless mt-3 mb-2"
          >
            <thead
              className={classNames([
                "text-center",
                st.thBB,
                "border-secondary",
              ])}
            >
              <tr>
                <th className="px-2 py-2">Auxiliar</th>
                <th className="px-2 py-2">Proveedor</th>
                <th className="px-2 py-2">Cuenta</th>
                <th className="px-2 py-2">Banco</th>
                <th className="px-2 py-2" style={{ minWidth: "170px" }}>
                  Cuenta Proveedor
                </th>
                <th className="px-2 py-2">Importe</th>
                {route === finanzas && (
                  <th className="px-2 py-2">Comprobante</th>
                )}
                <th
                  className="text-center px-2 py-2"
                  style={{ minWidth: "270px" }}
                >
                  Cuenta
                </th>
                {route === finanzas && (permissions.INS || permissions.UPD) && (
                  <th className="text-center px-2 py-2">
                    {setHeader()}
                  </th>
                )}
                <th className="text-center px-2 py-2">Ver Archivo</th>
              </tr>
            </thead>
            <tbody>
              {proveedores.length > 0 ? (
                proveedores.map((el, id) => (
                  <TableRow
                    key={"tr" + id}
                    el={el}
                    cuentas={cuentasBancarias}
                    getProveedores={getProveedores}
                    setLoading={setLoadingTab2}
                    route={route}
                    showButton={showButton}
                    makeReport={makeReport}
                    permissions={permissions}
                  />
                ))
              ) : (
                <tr
                  key="nothing"
                  className={classNames([
                    st.rowHover,
                    st.tableText,
                    "text-center",
                  ])}
                >
                  <td colSpan={8}>No hay proveedores</td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>

        {showButton() && permissions.INS  && ( //
            <>
              <div className="mb-5 mx-0 mx-lg-5 mt-4">
                <TextAreaInput
                  label="Observaciones"
                  inputName="notesPagos"
                  inputRows={6}
                  onChangeMethod={(e) => {
                    setNotesPagos(e.target.value);
                  }}
                  value={notesPagos}
                  isDisabled={!canSave}
                />
              </div>
              <div className="text-center">
                <Button color="success" onClick={() => setModalSavePagos(true)} disabled={!canSave}>
                  Guardar Pagos
                </Button>
              </div>
            </>
          )}

        <FullFormLoader show={loadingTab2} />
      </Collapse>

      <ModalConfirmation
        modal={modalSavePagos}
        setModal={setModalSavePagos}
        editar={() => savePago()}
        isEdit={true}
      >
        <div className="d-flex justify-content-center">
          <div className="text-center">
            <p style={{ fontSize: "22px" }} className="mb-2">
              ¿Está seguro de guardar los pagos del proveedor{" "}
              {route === finanzas ? "Empleados" : "Pensiones"} para el periodo{" "}
              <span style={{ fontWeight: "bold" }}>
                {filtro.period}/{filtro.year.getFullYear()}
              </span>
              ?
            </p>
            <p className="mt-3">Esta acción no se podrá deshacer</p>
          </div>
        </div>
      </ModalConfirmation>

      <ModalReports
        modal={modalReport}
        setModal={setModalReport}
        title={"Reporte de Pagos Proveedores de Nomina"}
        backdrop
        keyboard
        report={infoReport}
        pdfConfig={pdfConfig}
        generatePdfMethod={{
          method: pdfProveedoresN,
          type: "reporteAuxiliar",
        }}
      />
    </Widget>
  );
};

export default PagosProveedoresNomina;

const TableRow = ({
  el,
  cuentas,
  getProveedores,
  setLoading,
  route,
  showButton,
  makeReport,
  permissions
}) => {
  const [modalConfirm, setModalConfirm] = useState(false);
  const [modalFile, setModalFile] = useState(false);
  const [linkFile, setlinkFile] = useState("");
  const [idBankAccount, setidBankAccount] = useState("");
  const [textAccount, settextAccount] = useState("");
  const [modalConfirmMode, setModalConfirmMode] = useState(0);
  const [file, setfile] = useState("");
  const inputRef = useRef(null);
  const selectRef = useRef(null);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);

  const onChange = (e) => {
    if (e.target.name === "file") {
      if (e.target.files.length === 0) setfile("");
      else setfile(e.target.files[0]);
    } else {
      setidBankAccount(e.target.value);
    }
  };

  /* Guardar comprobante de pago de un baco */
  const saveInfo = async () => {
    setLoading(true);
    const rows = {
      idBankAccount: idBankAccount,
      idPayrollProviderBank: el.idPayrollProviderBank,
    };
    const formData = new FormData();
    formData.append("action", "insertCuentayComprobanteProviders");
    formData.append("rows", JSON.stringify(rows));
    formData.append("binary", file);

    const data = await doRequest(
      "app/facades/payslips/payrollrequestF.php",
      formData,
      true,
      false
    );
    if (data.length > 0) await getProveedores();
    else setLoading(false);
  };

  /* Para validar la info al guardar */
  const handleSubmit = () => {
    let msg = "";

    if (file === "" && idBankAccount === "")
      msg = "Cargue un comprobante y selecione una cuenta";
    else if (file === "") msg = "Cargue un comprobante";
    else if (idBankAccount === "") msg = "Selecione una cuenta";
    else if (file.type !== "application/pdf") msg = "Tipo de archivo invalido";
    if (msg === "") {
      setModalConfirmMode(1);
      settextAccount(selectRef.current.state.text);
      setModalConfirm(true);
    } else {
      toast(
        <Notification
          type={"consultar_error"}
          backMessage={`${msg} para el proveedor ${el.businessName}`}
          withIcon
        />,
        { closeButton: false }
      );
    }
  };

  /* Mostrar modal pdf */
  const openFile = () => {
    if (el.COL4 !== "") {
      setlinkFile(el.rutaComprobante);
      setModalFile(true);
    }
  };

  /* reverso el guardado del comprobante de pago */
  const reversoMovimiento = async () => {
    setLoading(true);
    const params = {
      action: "insertRevertirMovimientoProvider",
      rows: { idPayrollProviderBank: el.idPayrollProviderBank },
    };
    const data = await doRequest(
      "app/facades/payslips/payrollrequestF.php",
      params,
      true,
      true
    );
    if (data.length > 0) await getProveedores();
    else setLoading(false);
  };

  /* Mostrar modal de revertir pago */
  const handleDelete = () => {
    setModalConfirmMode(2);
    setModalConfirm(true);
  };

  return (
    <>
      <tr className={classNames(st.rowHover, st.tableText)}>
        <td className="px-2 text-center">
          <Button
            outline
            color="secondary"
            className="px-3"
            type="button"
            onClick={() => makeReport(el.idAccountList)}
          >
            <i className="fa fa-file-text-o" />
          </Button>
        </td>
        <td className="px-2" style={{ maxWidth: "170px" }}>
          {el.businessName}
        </td>
        <td className="px-2">{el.nameAccountList}</td>
        <td className="px-2">{el.nameBankProvider}</td>
        <td className="px-2">
          <p className="font-weight-bold">No. cuenta/CLABE:</p>
          {el.account !== "" ? el.account : el.switfCode}
        </td>
        <td className="px-2">{fmt.format(el.amount)}</td>
        {route === finanzas && (
          <td className="px-2 text-center">
            {el.idBankAccount === "" && (
              <>
                <Button
                  outline
                  color="secondary-red"
                  className="px-3"
                  type="button"
                  onClick={() => inputRef.current.click()}
                  disabled={!permissions.INS}
                >
                  <i className="fa fa-file-pdf-o" />
                </Button>
                <input
                  onChange={onChange}
                  style={{ display: "none" }}
                  type="file"
                  ref={inputRef}
                  name="file"
                  id="file"
                  accept="application/pdf"
                />
              </>
            )}
          </td>
        )}
        <td className={"px-2 text-center " + (el.COL4 === "" && "py-0 pt-3")}>
          {el.idBankAccount === "" ? (
            <div className="">
              <SelectTypeHeadSingle
                inputName={"idBankAccount"}
                optionsArray={cuentas}
                defaultOption="Seleccione una cuenta"
                onChangeMethod={onChange}
                value={idBankAccount}
                optionValue="idBankAccount"
                optionName="cuenta"
                isFixed
                refSelect={selectRef}
                isDisabled={!permissions.INS}
              />
            </div>
          ) : (
            <p>{el.chequera}</p>
          )}
        </td>
        {route === finanzas && (
          <td className="px-2 text-center" id={"tt-" + el.COL8}>
            {el.idBankAccount === "" && permissions.INS ? (
              <Button
                outline
                color="success"
                className="px-3"
                type="button"
                onClick={() => handleSubmit()}
              >
                <i className="fa fa-floppy-o" />
              </Button>
            ) : (el.idBankAccount !== "" && permissions.UPD &&
              showButton() && (
                <Button
                  outline
                  color={"danger"}
                  className="p-0"
                  onClick={() => handleDelete()}
                >
                  <i className="fa fa-reply" style={{ padding: "9px 16px" }} />
                </Button>
              )
            )}
          </td>
        )}
        <td className="px-2 text-center">
          {el.idBankAccount !== "" && (
            <Button
              outline
              color="info"
              className="px-3"
              onClick={() => openFile()}
            >
              <i className="fa fa-eye" />
            </Button>
          )}
        </td>
      </tr>

      <ModalConfirmation
        modal={modalConfirm}
        setModal={setModalConfirm}
        editar={() =>
          modalConfirmMode === 1 ? saveInfo() : reversoMovimiento()
        }
        isEdit={true}
        buttonOkMsg="Continuar"
      >
        {modalConfirmMode === 1 ? (
          <div className="d-flex justify-content-center">
            <div className="text-center">
              <p style={{ fontSize: "22px" }} className="mb-2">
                ¿Está seguro de guardar este comprobante con la cuenta: <br />
                <span style={{ fontWeight: "bold" }}>{textAccount}</span>?
              </p>
            </div>
          </div>
        ) : (
          <div className="d-flex justify-content-center">
            <div className="text-center">
              <p style={{ fontSize: "22px" }} className="mb-2">
                ¿Está seguro de revertir el pago del proveedor <br />
                <span style={{ fontWeight: "bold" }}>{el.businessName}</span>?
              </p>
              <p className="mt-3">Esta acción no se podrá deshacer</p>
            </div>
          </div>
        )}
      </ModalConfirmation>

      <ModalViewFile
        modal={modalFile}
        setModal={setModalFile}
        backdrop={true}
        keyboard={true}
        btnClose={true}
        fileLink={linkFile}
      />
    </>
  );
};

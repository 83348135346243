import { useSelector } from "react-redux";
import Widget from "../../../../components/Widget/Widget";
import { useEffect, useState } from "react";
import { peticionesReceiver } from "../../../../helpers/peticionesReceiver";
import { toast } from "react-toastify";
import Notification from "../../../../components/Notification/Notification";
import { GetPermissions } from "../../../../libs/permissions/getPermissions";
import { Button, Col, Row, Spinner } from "reactstrap";
import Datatable from "../../../../components/Datatable/Datatable";
import ModalDelete from "../../../../components/Modal/ModalDelete";
import { useHistory } from "react-router-dom";
import { DateInput } from "../../../../components/GenericInputsFormik";
import { format } from "date-fns"

export default function RegistroIncidencias() {
    const API = peticionesReceiver()
    const { id } = useSelector(state => state.userInfo);
    const [idStaff, setIdStaff] = useState(null)
    const [loadingUser, setLoadingUser] = useState(false)
    const [permisos, setPermisos] = useState("")
    const [loading, setLoading] = useState("")
    const history = useHistory();
    const [refresh, setRefresh] = useState(false)
    const [init, setInit] = useState("");
    const [end, setEnd] = useState("");
    GetPermissions(setPermisos, setLoading);

    useEffect(() => {
        getStaffAttent()
    }, [])

    async function getStaffAttent() {
        try {
            const res = await API.peticion({
                action: "multiselect",
                rows: "s.idStaff",
                table: "users u join staff s on(u.idStaff = s.idStaff)",
                conditions: `u.idUser = ${id}`
            });
            console.log(res)
            if (res.status === 200 && res.data.code === "200") {
                setIdStaff(res.data.data[0].idStaff);
                setLoadingUser(false);
            } else {
                setLoadingUser(false);
                toast(<Notification type={"consultar_error"} backMessage={res.data.message} />);
            }
        } catch (err) {
            setLoadingUser(false);
            toast(<Notification type={"consultar_servidor_error"} withIcon />);
        }
    }

    const columns = [
        { data: "dateIncident" },
        { data: "nameTypeIncident" },
        { data: "notes" },
        { data: "nameStat" },
        {
            data: null,
            render: function (row) {
                return (
                    <>
                        {permisos.DEL && row.keyStat === "127" && (
                            <ModalDelete
                                modalTitle="Eliminar"
                                table="types_incidents"
                                nameColumn={["keyTypeIncident"]}
                                elimina={deleteIncidente}
                                id={row.idIncident}
                            >
                                <h6>
                                    ¿Seguro de eliminar el registro?
                                </h6>
                                No podrá restaurar el contenido
                            </ModalDelete>
                        )}
                    </>
                )
            }
        },
    ]

    const columnDefs = [
        { targets: [4], className: "text-center", orderable: false }
    ]

    async function deleteIncidente(id) {
        const params = {
            action: "delete",
            table: "incidents",
            condition: { idIncident: id },
        };
        try {
            const res = await API.peticion(params);
            if (res.status === 200 && res.data.code === "200") {
                toast(<Notification type={"elimina_exito"} backMessage={res.data.message} withIcon />);
                setRefresh(true)
            } else {
                toast(<Notification type={"elimina_error"} backMessage={res.data.message} withIcon />);
            }
        } catch (err) {
            toast(<Notification type={"elimina_error"} withIcon />);
        }
    }

    const headers = [
        "Fecha",
        "Tipo de incidencia",
        "Justificación",
        "Estatus",
        "Eliminar"
    ]

    function getConditions() {
        if (init && end) {
            return `i.enabled = 1 AND i.idStaff=${idStaff} && i.dateIncident >= '${format(new Date(init), "yyyy-MM-dd")}' AND i.dateIncident <= '${format(new Date(end), "yyyy-MM-dd")}'`
        } else {
            return `i.enabled = 1 AND i.idStaff=${idStaff}`
        }
    }

    const getParamsTableElmt = (order, records, page, search) => {
        const params = {
            action: "datatable",
            table: "incidents i inner join types_incidents ti on (i.keyTypeIncident=ti.keyTypeIncident) inner join status s on (i.keyStat=s.keyStat)",
            rows: "i.idIncident,ti.nameTypeIncident,i.keyStat,s.nameStat, i.dateIncident,i.notes",
            conditions: getConditions(),
            order,
            records: records.toString(),
            page: (page * records).toString(),
            search,
        };
        return params;
    };

    async function getData(order, records, page, search) {
        try {
            const res = await API.peticion(
                getParamsTableElmt(order, records, page, search)
            );
            if (res.status === 200 && res.data.code === "200") {
                return res.data;
            } else {
                toast(
                    <Notification
                        type={"consultar_error"}
                        backMessage={res.data.message}
                    />
                );
                return null;
            }
        } catch (err) {
            toast(<Notification type={"consultar_servidor_error"} withIcon />);
            return null;
        }
    }

    const agregar = () => history.push("/template/registro-incidencias/agregar")

    useEffect(() => {
        if (init !== "" && end !== "") {
            setRefresh(true)
        }else if (init === "" && end === "") {
            setRefresh(true)
        }
    }, [init, end])

    if (loading || loadingUser) {
        return (
            <div className="d-flex flex-column align-items-center justify-content-center" style={{ minHeight: "300px" }}>
                <Spinner animation="grow" variant="primary" />
            </div>
        )
    } else if (idStaff === null) {
        return (
            <div className="text-center p-4">
                El usuario necesita tener un empleado asignado y una plaza para acceder a este apartado
            </div>
        )
    }
    return (
        <Widget className="widget-p-md">
            <h3 className="text-secondary">Registro de incidencias</h3>
            {permisos.INS && (
                <Row>
                    <Col sm={12} md={4}>
                        <DateInput
                            label="Fecha de inicio"
                            inputName={"init"}
                            onChangeMethod={(date) => setInit(date.length > 0 ? new Date(date[0]) : "")}
                            value={init}
                        />
                    </Col>
                    <Col sm={12} md={4}>
                        <DateInput
                            label="Fecha final"
                            inputName={"end"}
                            onChangeMethod={(date) => setEnd(date.length > 0 ? new Date(date[0]) : "")}
                            value={end}
                        />
                    </Col>
                    <Col sm={12} md={4}>
                        <div className="text-right">
                            <Button onClick={agregar}>Agregar</Button>
                        </div>
                    </Col>
                </Row>
            )}
            {permisos.CON ? (
                <Datatable
                    className="p-0 my-3"
                    columns={columns}
                    headers={headers}
                    control="back"
                    petition={getData}
                    stateRefresh={[refresh, setRefresh]}
                    columnDefs={columnDefs}
                />
            ) : (
                <div className="text-center p-4">
                    No tienes permitido ver los registros de esta seccion
                </div>
            )}
        </Widget>
    )
} 
import React, { useState, useEffect } from "react";
import Loader from "../../components/Loader/Loader";
import axios from "axios";
import {
  Col,
  Row,
  Table,
  Input,
  Pagination,
  PaginationItem,
  PaginationLink,
} from "reactstrap";
import s from "../../pages/tables/Tables.module.scss";
import sc from "./TableComponentFiltro.module.scss";
import { toast } from "react-toastify";
import Notification from "../../components/Notification/Notification";
import { peticionEncript } from "../../helpers/peticionesEncripted";
import { formatCell } from "./cellsFormat";
import {clearRep17, clearRep24, clearRep46} from "./clearReports/clearReports";
import FullFormLoader from "../Loader/FullFormLoader";
import { fDateTime, fMoney, fPercent } from "../../libs/tools/format";

export const TableComponentReport = (props) => {
  const {
    titulo,
    cabecerasTabla,
    parametros, //sobre que tabla hacer el select
    filtro, // filtro para mapear la info
    reporte, // Reporte enviado
    specialColumns,
    route
  } = props;
  const [sizes, setSizes] = useState([]); //Columnas con tamaño extendido
  const [indexCol, setIndexCol] = useState([]); /// Index de columnas en bd
  const [loading, setLoading] = useState(true);
  const [tableContent, setTableContent] = useState([]); // Información mostrada en la tabla
  const [pageSize, setPageSize] = useState(5); // Tamaño de las paginas
  const [totalRegistros, setTotalRegistros] = useState(0); // Total de registros en BD
  const [pagesCount, setPagesCount] = useState(0); // Cantidad total de paginas
  const [seccionActual, setSeccionActual] = useState(0); // Seccion actual
  const [disablePagination, setDisablePagination] = useState(false); // Desactivar la paginacion
  const [sortCol, setSortCol] = useState({
    //Parametros para aplicar filtros a la busqueda
    direction: "",
    column: "",
    page: "",
    currentPage: 0,
    search: "",
    records: 5,
  });
  const [pagination, setPagination] = useState([]);

  const settableContentPage = (e, index) => {
    e.preventDefault();
    if (index + 1 > (seccionActual + 1) * 5) {
      setSeccionActual(seccionActual + 1);
    } else if (seccionActual != 0 && index + 1 <= seccionActual * 5) {
      setSeccionActual(seccionActual - 1);
    }
    // const curPage = index !== 0? index : 1;
    setSortCol((prevState) => ({
      ...prevState,
      page: pageSize * index,
      currentPage: index,
    }));
  };

  const mapea = (element, indexCol, indexRow) => {
    // const reg = /\d{4}-\d{2}-\d{2}/g;
    // let texto = element;
    // if (reg.test(element)) {
    //   if(isNaN(element)){
    //     let date = new Date (element)
    //     if(isNaN(date.getDate()) || isNaN(date.getMonth() ||date.getFullYear() )){

    //       texto = element.split("-").reverse().toString().replaceAll(",", "/");
    //     }else{

    //       texto = `${date.getDate()}/${date.getMonth()}/${date.getFullYear()}`
    //     }
    //   }else{
    //     texto = element.split("-").reverse().toString().replaceAll(",", "/");
    //   }
    // }
    // return (
    //   <td
    //     className="text-center" style={{whiteSpace: "pre-line"}}
    //   >
    //     {texto}
    //   </td>
    // );
    return formatCell(
      element,
      indexCol,
      indexRow,
      getFiltro(indexCol),
      sortCol,
      cabecerasTabla[indexCol]+"-"+titulo,
      [60].includes(+reporte), /// Reportes que si deben mostrar la columna de numeración
      // tableContent[indexRow]
    );
  };

  const sortTable = (cabecera, indexHeader) => {
    // if (!["Editar","Eliminar","Id","Ver","Seleccionar","Modificar"].includes(cabecera)) {
    // const idBd = namesBd.indexOf();
    const id = indexCol[indexHeader];
    if (sortCol.column !== id) {
      setSortCol((ps) => ({ ...ps, column: id, direction: "asc" }));
    } else {
      setSortCol((ps) => ({
        ...ps,
        direction:
          sortCol.direction === "asc"
            ? "desc"
            : sortCol.direction === "desc"
              ? ""
              : "asc",
      }));
    }
    // }
  };

  const getData = async (orderOb) => {
    //LA PRIMERA VEZ QUE ENTRAMOS VIENE UN ORDEROB = EMPTY Y EN EL PARAMS = ORDER = "", PAGE = 0 Y RECORDS = 5
    //CUANDO SELECCIONO EL HEADER VIENE ORDEROB = COLUMN = 1 Y DIRECTION = ASC Y EN EL PARAMS = ORDER = "ASC", PAGE = 0 Y RECORDS = 5
    //DESPUES DE ESO SELECCIONO EL PAGINADO Y MUESTRA ORDEROB = COLUMN = "", DIRECTION = "" Y PAGE = 5 Y EN EL PARAMS = ORDER = "", PAGE = 5 Y RECORDS = 5
    //SOLO HAY QUE VERIFICAR EL ESTADO DEL HOOK Y QUE NO CAMBIE LOS PARAMS
    var params = parametros;
    if (sortCol.column !== "" && sortCol.direction !== "") {
      //AQUI QUIERE DECIR QUE TRAE UN ORDENAMIENTO POR HEADER
      params.order = orderOb.column + " " + orderOb.direction;
    } else {
      params.order = "";
    }
    if (sortCol.page !== "") {
      //QUIERE DECIR QUE CAMBIAMOS DE PAGINA
      params.page = sortCol.page.toString();
    } else {
      params.page = "0";
    }
    if (sortCol.search !== "") {
      params.search = sortCol.search;
    } else {
      params.search = "";
    }
    if (orderOb) {
    } else {
      if (sortCol.column !== "" && sortCol.direction !== "") {
        //AQUI TRAE UN ORDENAMIENTO POR HEADER
        params.order = orderOb.column + " " + orderOb.direction;
      } else {
        params.order = "";
      }
      if (sortCol.page !== "") {
        //CAMBIAMOS DE PAGINA
        params.page = sortCol.page.toString();
      } else {
        params.page = "0";
      }
      if (sortCol.search !== "") {
        params.search = sortCol.search;
      } else {
        params.search = "";
      }
    }
    params.records = sortCol.records.toString();
    params = hiddePagination(params);
    setLoading(true); 
    const finalData = peticionEncript(params);
    const peticionRoute = route ? route : "app/facades/reports/reportsF.php"
    await axios
      .post(
        `${process.env.REACT_APP_API}${peticionRoute}`,
        finalData
      )
      .then((res) => {
        let data = [];
        if (res.status === 200 && res.data.code === "200") {
          if (res.data.data.length != 0) {
            setTotalRegistros(res.data.totalRecords);
            setPagesCount(Math.ceil(res.data.totalRecords / pageSize));
            data = clearData(res.data.data);
          }
        } else {
          // toast(
          //   <Notification
          //     type={"consultar_error"}
          //     backMessage={res.data.message}
          //     withIcon
          //   />,
          //   { closeButton: false }
          // );
        }
        setTableContent(data);
        setLoading(false);
      })
      .catch((err) => {
        console.error(err)
        toast(<Notification type={"consultar_servidor_error"} withIcon />, {
          closeButton: false,
        });
        setLoading(false);
      });
  };

  function clearData(data) {
    let keys = [];
    cabecerasTabla.forEach((h) => {
      filtro.content.every((arr) => {
        let flg = true;
        arr.every((ob) => {
          if (ob.header === h) {
            keys.push([ob.value, ob.format]);
            flg = false;
            return false;
          }
          return true;
        });
        return flg;
      });
    });
    /// Id´s de reportes especiales
    if (![17, 20, 22, 36, 63].includes(+reporte)) {
      keys.unshift([filtro.id.id, undefined]);
    }
    const keysBD = Object.keys(data[0]);
    const indexs = [];
    let sizes = [];
    let pageData = data.map((d, idx) => {
      let reg = keys.map((k, idxk) => {
        if (idx === 0) {
          /// guardamos el nombre de cada columna de la bd
          indexs.push(keysBD.indexOf(k[0]) + 1);
        }
        let campo = "";
        // limpieza especial del dato de acuerdo a su reporte
        switch (+reporte) {
          case 17:
            campo = clearRep17(d, k[0]);
            break;
          case 24:
            campo = clearRep24(d, k[0]);
            break;
          case 30:
            if (k[0] == "keyTypeUnder") {
              if (d[k[0]] == 1) {
                campo = "PERCEPCIÓN";
              } else {
                campo = "DEDUCCIÓN";
              }
            } else {
              campo = d[k[0]];
            }
            break;
          case 46:
            campo = clearRep46(d, k[0]);
            break;
          default:
          campo = d[k[0]];
        }
        // formateo del campo, ya sea moneda, fecha, etc
        switch (k[1]){
          case "moneda":
            campo = fMoney(campo);
            break;
          case "porcentaje": 
            campo = fPercent(campo);
            break;
          case "fecha": 
          campo = fDateTime(campo);
            break;
          default: 
            if(k[1]){
              campo = k[1](d, k[0]);
            }
        }

        if (campo && campo.length >= 100) {
          if (!sizes.includes(idxk)) {
            sizes.push(idxk);
          }
        }

        return campo;
      });
      return reg;
    });
    setIndexCol(indexs);
    setSizes(sizes);
    return pageData;
  }

  function getFiltro(col){
    if(!filtro.hidde) return true;
    return !filtro.hidde.includes(col);
  }

  function hiddePagination(params) {
    if ([24].includes(+reporte)) {
      if (!disablePagination) setDisablePagination(true);
      return {
        ...params,
        records: ""
      }
    }
    if (disablePagination) setDisablePagination(false);
    return params
  }

  useEffect(() => {
    getData(sortCol);
  }, [sortCol, parametros]);

  const handleOnChange = (e) => {
    setSeccionActual(0);
    setSortCol((prevState) => ({
      ...prevState,
      search: e.target.value,
      currentPage: 0,
      page: "",
    }));
  };

  const handleOnChangeSelect = (e) => {
    setSeccionActual(0);
    setPageSize(e.target.value);
    setSortCol((prevState) => ({
      ...prevState,
      records: e.target.value,
      currentPage: 0,
      page: "",
    }));
  };

  /*Paginacion @luixeduard */
  useEffect(() => {
    const pages = Math.ceil(totalRegistros / pageSize);
    const maxPages = Math.min(pages, 5);
    const startPage = Math.max(Math.min(pages - maxPages, sortCol.currentPage - Math.floor(maxPages / 2)), 0);
    const endPage = Math.min(startPage + maxPages, pages);
    const tempPages = Array.from({ length: endPage - startPage }, (_, i) => i + startPage);

    setPagination(tempPages);
}, [tableContent]);

  return (
    <div>
      <Row className="mb-4">
        <Col>
          <div className={s.tableTitle}>
            <h1 className="text-secondary text-center">{titulo} </h1>
          </div>
          <div className="d-flex justify-content-between">
            <Input
              value={sortCol.search}
              name="search"
              onChange={handleOnChange}
              className="col-12 col-md-6"
              placeholder="Buscar..."
            />
            {/* <Button
                color="success"
                className="btn-rounded with-icon"
                onClick={() => {
                  handleSubmit();
                }}
              >
                Agregar
              </Button> */}
          </div>
          <div className="widget-table-overflow mt-3">
            <Table
              className={`table-striped table-borderless table-hover ${s.statesTable}`}
              responsive
            >
              <thead className="border-bottom border-secondary">
                <tr className={sc.userSelectNone}>
                  {cabecerasTabla.map((cabecera, indexHeaders) => (
                    <th
                      key={cabecera}
                      className="text-center"
                      onClick={() => sortTable(cabecera, indexHeaders)}
                      style={
                        sizes.includes(indexHeaders)
                          ? { minWidth: "400px" }
                          : {}
                      }
                    >
                      {cabecera === "Id" ? "#" : cabecera}
                      <i
                        className={
                          "align-top eva" +
                          (indexCol.indexOf(sortCol.column) === indexHeaders
                            ? sortCol.direction === "asc"
                              ? " eva-arrow-ios-upward-outline"
                              : sortCol.direction === "desc"
                                ? " eva-arrow-ios-downward-outline"
                                : ""
                            : "")
                        }
                      ></i>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <tr key={"loading"}>
                    <td colSpan={cabecerasTabla.length}>
                      <Loader />
                    </td>
                  </tr>
                ) : tableContent.length === 0 ? (
                  <tr key={"nothing"}>
                    <td colSpan={cabecerasTabla.length} className="text-center">
                      No se encontraron registros
                    </td>
                  </tr>
                ) : (
                  tableContent.map((registro, index) => (
                    <tr key={index}>
                      {registro.map((elemento, indexCol) => {
                        return mapea(elemento, indexCol, index);
                      })}

                      {specialColumns && specialColumns.length > 0
                        ? specialColumns.map((column, index) => (
                          <td className="text-center" key={`espCol${index}`}>
                            <div className="d-flex flex-column justify-content-center align-items-center">
                              {column(registro, `spc${index}`)}
                            </div>
                          </td>
                        ))
                        : null}
                    </tr>
                  ))
                )}
              </tbody>
            </Table>
            {!disablePagination && <div className="row">
                <div className="col-12 col-sm-6 d-flex justify-content-around mt-3">
                    <div>
                      Mostrando{" "}
                      <span className="text-primary">
                        {sortCol.currentPage * pageSize +
                          (tableContent.length === 0 ? 0 : 1)}
                      </span>{" "}
                      a{" "}
                      <span className="text-primary">
                        {pageSize * (sortCol.currentPage + 1) <= totalRegistros
                          ? pageSize * (sortCol.currentPage + 1)
                          : totalRegistros}{" "}
                      </span>
                      de {totalRegistros} registros
                    </div>
                    <select
                      className="form-control form-control-sm"
                      style={{ width: "78px" }}
                      value={sortCol.records}
                      onChange={handleOnChangeSelect}
                    >
                      <option value={5} key={5}>
                        5
                      </option>
                      <option value={10} key={10}>
                        10
                      </option>
                      <option value={15} key={15}>
                        15
                      </option>
                      <option value={20} key={20}>
                        20
                      </option>
                      <option value={100} key={100}>
                        100
                      </option>
                    </select>
                </div>
                <div className="col-12 col-sm-6 d-flex justify-content-center justify-content-md-end">
                    <Pagination className="pagination-borderless d-flex mt-3">
                      <PaginationItem className="m-0">
                        <PaginationLink 
                          disabled={sortCol.currentPage <= 0}
                          onClick={(e) => settableContentPage(e, sortCol.currentPage - 1)}
                          previous 
                          className={sortCol.currentPage <= 0 ? "text-muted" : ""}
                        />
                      </PaginationItem>
                      {pagination.map((page, i) => (
                        <PaginationItem key={i} disabled={page === sortCol.currentPage} className="m-1" >
                            <PaginationLink
                                onClick={(e) => settableContentPage(e, page)}
                                className={page === sortCol.currentPage ? "text-bold text-white bg-primary":"text-secondary"}
                            >
                                {page + 1}
                            </PaginationLink>
                        </PaginationItem>
                      ))}
                      <PaginationItem className="m-0">
                        <PaginationLink 
                          disabled={sortCol.currentPage + 1 >= pagesCount}
                          onClick={(e) => settableContentPage(e, sortCol.currentPage + 1)}
                          next 
                          className={sortCol.currentPage + 1 >= pagesCount ? "text-muted" : ""}
                        />
                      </PaginationItem>
                    </Pagination>
                </div>
            </div>}
          </div>
        </Col>
      </Row>
      <FullFormLoader
        show={loading}
        message={"Consultando datos , espere un momento"}
      />
    </div>
  );
};
export default TableComponentReport;

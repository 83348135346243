import { Document, Page, Text, View } from "@react-pdf/renderer";
import HeaderReport from "../../../../components/Pdf/Header";
import { StyleSheet } from "@react-pdf/renderer";
import { left, right } from "@popperjs/core";
import { element } from "prop-types";
import { peticionesReceiver } from "../../../../helpers/peticionesReceiver";

const API = peticionesReceiver();

const formatter = new Intl.NumberFormat("es-MX", {
  style: "currency",
  currency: "MXN",
});
const dateformatter = (fecha) => {
  let date = new Date(fecha);
  return `${date.getDate()}/${date.getMonth()}/${date.getFullYear()}`;
};

const padTo2Digits = (num) => num.toString().padStart(2, "0");

const formatDate = (date) => {
  return [
    padTo2Digits(date.getDate()),
    padTo2Digits(date.getMonth() + 1),
    date.getFullYear(),
  ].join("/");
};

const colors = {
  backgroundHeaders: "#646464",
};

const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 35,
    paddingHorizontal: 10,
  },
  bodyTableGeneral: {
    paddingTop: 35,
    paddingBottom: 10,
    paddingHorizontal: 10,
  },
  spaceFooterTableGeneral: {
    fontSize: 12,
    bottom: 10,
    left: 0,
    right: 0,
    width: "auto",
    height: 50,
    marginHorizontal: 10,
  },
  spaceFooter: {
    fontSize: 12,
    bottom: 10,
    left: 0,
    right: 0,
    width: "auto",
    height: 50,
    marginHorizontal: 10,
  },
  footer: {
    position: "absolute",
    fontSize: 12,
    bottom: 10,
    left: 0,
    right: 0,
    width: "auto",
    height: 30,
    marginHorizontal: 10,
    /*       borderColor:"#a9a9a9", */
    borderTopWidth: 1,
    flexDirection: "row",
    alignContent: "space-around",
    alignItems: "center",
  },
  title: {
    width: "100%",
    textAlign: "center",
    marginBottom: 10,
    /* color: '#a9a9a9', */
    fontSize: 14,
  },
  subTitle: {
    width: "100%",
    textAlign: "center",
    marginBottom: 10,
    /* color: '#a9a9a9', */
    fontSize: 14,
  },
  textFooter: {
    width: "50%",
    /* color: '#a9a9a9', */
    textAlign: "center",
    fontSize: 8,
  },

  table: {
    display: "table",
    width: "auto",
    /*       borderStyle: "solid", 
      borderColor: "red",
      borderWidth: 1, 
      borderRightWidth: 0, 
      borderLeftWidth: 0 , */
    margin: 10,
  },
  //cabeceras
  tableColHeaderNo: {
    width: "27.5%",
    height: 20,
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    backgroundColor: colors.backgroundHeaders,
    color: "white",
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center",
    fontSize: 9,
  },
  tableColHeaderNoL: {
    width: "50%",
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: colors.backgroundHeaders,
    color: "white",
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center",
    fontSize: 7,
  },
  tableColHeaderReport: {
    width: "50%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    backgroundColor: colors.backgroundHeaders,
    color: "white",
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center",
    fontSize: 7,
  },
  tableHeaderComplete: {
    width: "100%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    backgroundColor: colors.backgroundHeaders,
    color: "white",
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center",
    fontSize: 7,
  },

  //contenido
  tableColContentNoBI: {
    width: "50%",
    borderStyle: "solid",
    borderWidth: 1,
    // borderLeftWidth: 0,
    borderTopWidth: 0,
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
    textAlign: "center",
  },

  tableColContentNo: {
    width: "50%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    flexDirection: "column",
    justifyContent: "space-around",
    alignItems: "center",
    textAlign: "center",
    fontSize: 7,
  },

  tableColContentReport: {
    width: "50%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  //filas
  tableRow: {
    flexDirection: "row",
  },

  //Filas de contenido, max 3
  displayContent: {
    paddingLeft: 9, //centra el el content
    margin: 9,
    /* marginTop: 9,
      marginBottom: 9, */
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "space-around",
  },
  displayContentColumn: {
    /* marginTop: 9,
      marginBottom: 9, */
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
  },

  //Tamaño de celda deacuerdo a tamaño del contenido
  cellOneColumn: {
    width: "30%",
    fontSize: 8,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  cell80: {
    width: "90%",
    fontSize: 8,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  cellTwoColumn: {
    width: "63%",
    fontSize: 8,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  /* En caso de usar formato de dos columnas */
  cellTwoColumn2: {
    width: "80%",
    fontSize: 8,
    justifyContent: "center",
    alignItems: "center",
  },
  cellThreeColumn: {
    width: "96.3%",
    fontSize: 8,
  },
  textBold: {
    fontFamily: "Helvetica-Bold",
    /* fontWeight: 900, */
    /* fontFamily: 'Courier-Bold' */
    /* color: 'red' */
  },
});

const insertTableHeaders = (model) => {
  return (
    <View style={[styles.tableRow, { fontSize: 6 }]}>
      <View style={styles.tableColHeaderNoL}>
        <Text>NO</Text>
      </View>
      <View style={styles.tableColHeaderReport}>
        <Text>NÚMERO DE CONTRATO </Text>
      </View>
      <View style={styles.tableColHeaderReport}>
        <Text>FOLIO DE LA </Text>
        <Text>SUFICIENCIA </Text>
        <Text>PRESUPUESTAL </Text>
      </View>
      <View style={styles.tableColHeaderReport}>
        <Text>PROVEDOR </Text>
      </View>
      <View style={styles.tableColHeaderReport}>
        <Text>FOLIO FISCAL </Text>
      </View>
      <View style={styles.tableColHeaderReport}>
        <Text>FECHA DE FACTURA </Text>
      </View>
      <View style={styles.tableColHeaderReport}>
        <Text>NÚMERO DE</Text>
        <Text>PARTIDA</Text>
        <Text>PRESUPUESTAL</Text>
      </View>
      <View style={styles.tableColHeaderReport}>
        <Text>DESCRIPCIÓN</Text>
      </View>
      <View style={styles.tableColHeaderReport}>
        <Text>IMPORTE CON I.V.A</Text>
      </View>
      <View style={styles.tableColHeaderReport}>
        <Text>OBSERVACION</Text>
      </View>
    </View>
  );
};

const insertTableContent = (data, order, total) => {
  return (
    <View style={{ marginBottom: 15 }}>
      {data.map((element, index) => {
        return (
          <View style={[styles.tableRow, { fontSize: 6 }]} key={index}>
            <View style={styles.tableColContentNoBI}>
              <Text>{element.index}</Text>
            </View>
            <View style={styles.tableColContentNo}>
              <Text> {`${order.numberOrder} / ${order.yearOrder}`} </Text>
            </View>
            <View style={styles.tableColContentNo}>
              <Text> {`${element.numberIncome} / ${element.yearIncome}`} </Text>
            </View>
            <View style={styles.tableColContentNo}>
              <Text>{element.socialName}</Text>
            </View>
            <View style={styles.tableColContentNo}>
              <Text> {element.taxNumber} </Text>
            </View>
            <View style={styles.tableColContentNo}>
              <Text>{formatDate(new Date(element.created))} </Text>
            </View>
            <View style={styles.tableColContentNo}>
              <Text>{element.fullkey}</Text>
            </View>
            <View style={styles.tableColContentNo}>
              <Text>{element.notes}</Text>
            </View>
            <View style={styles.tableColContentNo}>
              <Text>{formatter.format(element.amount)} </Text>
            </View>
            <View style={styles.tableColContentNo}>
              <Text>{order.comments}</Text>
            </View>
          </View>
        );
      })}

      <View style={[styles.tableRow, { fontSize: 6 }]}>
        <View style={{ width: "50%" }}>
          <Text></Text>
        </View>
        <View style={{ width: "50%" }}>
          <Text> </Text>
        </View>
        <View style={{ width: "50%" }}>
          <Text> </Text>
        </View>
        <View style={{ width: "50%" }}>
          <Text></Text>
        </View>
        <View style={{ width: "50%" }}>
          <Text> </Text>
        </View>
        <View style={{ width: "50%" }}>
          <Text></Text>
        </View>
        <View style={{ width: "50%" }}>
          <Text></Text>
        </View>
        <View style={[styles.tableColContentNo, { borderLeftWidth: 1 }]}>
          <Text style={styles.textBold}>Total:</Text>
        </View>
        <View style={styles.tableColContentNo}>
          <Text>{formatter.format(total)}</Text>
        </View>
        <View style={{ width: "50%" }}>
          <Text></Text>
        </View>
      </View>
    </View>
  );
};

const insertHeader = (order) => {
  return (
    <View style={{ marginBottom: 25 }}>
      <View style={{ textAlign: right, fontSize: "10" }}>
        <Text>
          Toluca, Estado de México a : {formatDate(new Date(order.created))}{" "}
        </Text>
        <Text>
          <Text style={styles.textBold}>ASUNTO:</Text> Solicitud de Pago{" "}
        </Text>
        <Text>
          <Text style={styles.textBold}>FOLIO:</Text> {order.numberOrder}
        </Text>
      </View>

      <View style={{ textAlign: left, fontSize: "10" }}>
        <Text style={styles.textBold}>{order.fullName}</Text>
        <Text style={styles.textBold}>{order.nameJob}</Text>
        <Text style={styles.textBold}>P R E S E N T E:</Text>
      </View>

      <View style={{ textAlign: left, fontSize: "10" }}>
        <Text>
          Anexo remito a Usted la(s) factura(s) original(es) que a continuación
          se describe(n) para su revisión y tramite de pago, correspondiente(s)
          al(los) siguiente(s) contrato(s):
        </Text>
      </View>
    </View>
  );
};

const insertDescription = (totalLetters) => {
  return (
    <View style={{ marginBottom: 50 }}>
      <View style={{ textAlign: left, fontSize: "10", marginBottom: 5 }}>
        <Text>
          IMPORTE TOTAL CON LETRA E I.V.A INCLUIDO DE LA(S) FACTURA(S)
          ENTREGADA(S)
        </Text>
        <Text style={styles.textBold}>{`(${totalLetters} 00/100 M.N.)`}</Text>
      </View>
      <View style={{ textAlign: left, fontSize: "10", marginBottom: 5 }}>
        <Text style={styles.textBold}>
          DOCUMENTACÓN SOPORTE ENTRAGADA PARA TRÁMITE DE PAGO:
        </Text>
        <Text>
          UNO (1) factura(s) original(es); copia de la Solicitud de Suficiencia
          Presupuestal y Dictamen 2022; e impresión de la verificación de cada
          uno de los comprobantes fiscales digitales en el portal electrónico
          del SAT, y del correo electrónico, por medio del cual, en archivo
          adjunto a éste, el servidor público que solicita el pago, remitió
          la(s) factura(s) telacionada(s) en la Dirección de Finanzas.
        </Text>
      </View>
      <View style={{ textAlign: left, fontSize: "10", marginBottom: 5 }}>
        <Text style={styles.textBold}>
          NOTA: CUALQUIER CAMBIO Y/O MODIFICACIÓN REALIZADA A LAS FACTURAS
          RELACIONADAS EN LA PRESENTE SOLICITUD DE PAGO, DESPUÉS DE SER
          RECIBIDAS POR LA DIRECCIÓN DE FINANZAS, ES RESPONSABLIDAD DE QUIEN LAS
          EFECTUE.
        </Text>
      </View>
    </View>
  );
};

const insertSigns = (order) => {
  return (
    <View
      style={[
        {
          flexDirection: "row",
          justifyContent: "space-around",
          fontSize: 8,
          textAlign: "center",
        },
      ]}
    >
      <View
        style={[
          {
            borderTop: 1,
            width: "25%",
          },
        ]}
      >
        <Text>{order.fullName}</Text>
        <Text>{order.nameJob}</Text>
      </View>
      {/* <View
        style={[
          {
            borderTop: 1,
            width: "25%",
          },
        ]}
      >
        <Text>LUIS ZEPEDA ZUÑIGA</Text>
        <Text>CORDINADOR GENERAL</Text>
      </View>
      <View
        style={[
          {
            borderTop: 1,
            width: "25%",
          },
        ]}
      >
        <Text>MARIA DE LA LIZ LOPEZ MARTINEZ</Text>
        <Text>ENCARGADO DE AREA</Text>
      </View> */}
    </View>
  );
};

const changeTotalToLettters = async (total) => {
  const params = {
    action: "numbertotext",
    number: total,
  };
  const res = await API.peticionEndPoint(
    params,
    "app/facades/tools/toolsF.php"
  );
  if (res.status === 200 && res.data.code === "200") {
    
    return res.data.number;
  } else {
    return null;
  }
};

export async function generatePdfOrdenPago(data, model, title, fileNo, cont) {
  debugger
  const total =
    data.warehouse.length > 1
      ? data.warehouse.reduce((acum, element) => +acum + +element.amount, 0)
      : data.warehouse[0].amount;
  

  const totalLetters =
    data.warehouse.length > 1
      ? await changeTotalToLettters(total)
      : data.warehouse[0].amount && data.warehouse[0].amount !== "0" ? changeTotalToLettters(total) : "CERO PUNTO CERO" ;

  return {
    report: (
      <>
        <Document>
          <Page style={styles.body}>
            <HeaderReport />
            <View>
              <Text style={styles.title}>Orden de pago</Text>
            </View>
            <View style={styles.table}>
              {/* Cabeceras */}

              {insertHeader(data.order)}

              {insertTableHeaders()}

              {insertTableContent(data.warehouse, data.order, total)}

              {insertDescription(totalLetters)}

              {insertSigns(data.order)}
            </View>

            <View fixed style={styles.spaceFooter}></View>
            <View fixed style={styles.footer}>
              <Text style={styles.textFooter}>{fileNo}</Text>
              <Text
                style={styles.textFooter}
                render={({ pageNumber, totalPages }) =>
                  `PAGINA : ${pageNumber} / ${totalPages}`
                }
              />
            </View>
          </Page>
        </Document>
      </>
    ),
  };
}

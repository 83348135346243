import { Document, Page, Text, View, StyleSheet } from "@react-pdf/renderer";
import { styles } from "../../../../components/Pdf/pdfGeneral/EstiloPdf";

import HeaderReport from "../../../../components/Pdf/Header";

import { format } from "date-fns"

const DecimSeparatorFormatter = new Intl.NumberFormat('en-US');

/**
 * Estilo que se repite en todas los headers de la tabla
 */
const styleHeadForAll = {
    fontFamily: 'Helvetica-Bold',
    paddingVertical: 3,
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "#646464",
    color: 'white',
    textAlign: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 8,
    borderBottom: 0
}

const styleCellForAll = {
    paddingVertical: 3,
    borderStyle: "solid",
    borderWidth: 1,
    borderTopWidth: 0,
    fontSize: 8
}

/**
 * Estilos del pdf
 */
const s = StyleSheet.create({
    body: {
        padding: 10
    },
    subTitle: { //SUBTITULOS
        width: "100%",
        textAlign: 'center',
        marginBottom: 10,
        marginLeft: 10,
        fontSize: 12
    },
    f6: {
        fontSize: 6
    },
    f5: {
        fontSize: 5
    },
    f8: {
        fontSize: 8
    },
    f10: {
        fontSize: 10
    },
    thIn: {
        ...styleHeadForAll
    },
    th: {
        ...styleHeadForAll,
        borderLeftWidth: 0
    },
    h130: {
        height: "130"
    },
    w5: {
        width: "5%"
    },
    w10: {
        width: "10%"
    },
    w15: {
        width: "15%"
    },
    w20: {
        width: "20%"
    },
    w18: {
        width: "18%"
    },
    w25: {
        width: "25%"
    },
    w30: {
        width: "30%"
    },
    w33: {
        width: "33%"
    },
    w34: {
        width: "34%"
    },
    w40: {
        width: "40%"
    },
    w45: {
        width: "45%"
    },
    w50: {
        width: "50%"
    },
    w60: {
        width: "60%"
    },
    w70: {
        width: "70%"
    },
    w80: {
        width: "80%"
    },
    w90: {
        width: "90%"
    },
    w100: {
        width: "100%"
    },
    m1: {
        margin: 3
    },
    mt2: {
        marginTop: 5
    },
    mt3: {
        marginTop: 10
    },
    mx2: {
        marginHorizontal: 5
    },
    mt4: {
        marginTop: 40
    },
    pt1: {
        paddingTop: 10
    },
    px2: {
        paddingHorizontal: 10
    },
    px1: {
        paddingHorizontal: 5
    },
    b1: {
        border: 1
    },
    bt0: {
        borderTopWidth: 0
    },
    bt1: {
        borderTopWidth: 1
    },
    center: {
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
    },
    textCenter: {
        textAlign: 'center',
    },
    textBold: {
        fontFamily: 'Helvetica-Bold'
    },
    textRight: {
        textAlign: "right"
    },
    tdIn: { //Cell CC
        ...styleCellForAll
    },
    td: { //Cell Concepto
        ...styleCellForAll,
        borderLeftWidth: 0
    },
    td0: { //Cell Concepto
        ...styleCellForAll,
        borderLeftWidth: 0,
        borderRightWidthWidth: 0
    },
    red: {
        backgroundColor: "red",
    },
    green: {
        backgroundColor: "green",
    },
    gray: {
        backgroundColor: "gray",
    }
})


export default function exportSolicitudAdquisicion(report) {
    const ori = "portrait";
    const folio = (report[0].numberQuote === "" ? "0" : report[0].numberQuote) + "/" + (report[0].yearQuote === "" ? "0000" : report[0].yearQuote)
    const object = report[0].object;
    let dateSend = report[0].dateSend === "" ? format(new Date(), "dd/MM/yyyy") : report[0].dateSend.substring(0, report[0].dateSend.indexOf(" "));
    if (report[0].dateSend !== "") {
        const split = dateSend.split("-")
        dateSend = `${split[2]}/${split[1]}/${split[0]}`
    }

    return {
        report: (
            <>
                <Document>
                    <Page style={s.body} orientation={ori}>
                        <HeaderReport fixed={true} showTT={false} />
                        <View>
                            <Text style={[s.center, s.f10, s.textBold]}>SOLICITUD PARA ADQUISICIÓN DE BIENES Y DE CONTRATACIÓN DE</Text>
                            <Text style={[s.center, s.f10, s.textBold]}>SERVICIOS Y TRAMITE DE LIMITE PRESUPUESTAL</Text>
                        </View>
                        <View style={styles.table}>
                            <View style={styles.tableRow}>
                                <View style={s.w80}>
                                </View>
                                <View style={[s.tdIn, s.w10, s.textBold, s.bt1]}>
                                    <Text>Folio</Text>
                                </View>
                                <View style={[s.td, s.w10, s.textBold, s.bt1, s.textRight]}>
                                    <Text>{folio}</Text>
                                </View>
                            </View>
                            <View style={styles.tableRow}>
                                <View style={s.w80}>
                                </View>
                                <View style={[s.tdIn, s.w10, s.textBold]}>
                                    <Text>Fecha</Text>
                                </View>
                                <View style={[s.td, s.w10, s.textBold, s.textRight]}>
                                    <Text>{dateSend}</Text>
                                </View>
                            </View>
                        </View>
                        <View style={s.mx2}>
                            <Text style={[s.f10, s.textBold]}>DIRECCIÓN DE MATERIALES Y SERVICIOS</Text>
                            <Text style={[s.f10, s.textBold]}>PRESENTE</Text>
                            <Text style={[s.f10, s.mt3]}>
                                Por medio del presente, se solicita llevar a cabo la adquisición del (os) bien(es) y/o contratación del (os) servicio (s), asi como la cotización para el tramite del limite presupuestal, cuyas especificaciones y cantidades a continuación se refieren:
                            </Text>
                        </View>
                        <View style={styles.table}>
                            <View style={styles.tableRow}>
                                <View style={[s.thIn, s.w10]}>
                                    <Text>No. de</Text>
                                    <Text>Partida</Text>
                                </View>
                                <View style={[s.th, s.w40]}>
                                    <Text>Descripción del Bien o Servicio</Text>
                                </View>
                                <View style={[s.th, s.w10]}>
                                    <Text>Unidad de medida</Text>
                                </View>
                                <View style={[s.th, s.w10]}>
                                    <Text>Cantidad</Text>
                                    <Text>solicitada</Text>
                                </View>
                                <View style={[s.th, s.w30]}>
                                    <Text>Importe para solicitud de suficiencia</Text>
                                    <Text>presupuestal</Text>
                                </View>
                            </View>
                            {report.map((row, index) => (
                                <View style={styles.tableRow}>
                                    <View style={[s.tdIn, s.w10]}>
                                        <Text>{index + 1}</Text>
                                    </View>
                                    <View style={[s.td, s.w40]}>
                                        <Text>{row.descripcion}</Text>
                                    </View>
                                    <View style={[s.td, s.w10]}>
                                        <Text>{row.unit}</Text>
                                    </View>
                                    <View style={[s.td, s.w10, s.textCenter]}>
                                        <Text>{DecimSeparatorFormatter.format(row.amount)}</Text>
                                    </View>
                                    <View style={[s.td, s.w30]}>
                                        <Text></Text>
                                    </View>
                                </View>
                            ))}
                            <View style={styles.tableRow}>
                                <View style={[s.f8, s.textRight, s.w40, s.m]}>
                                    <Text style={s.m1}>Total</Text>
                                </View>
                                <View style={[s.tdIn, s.w30]}>
                                    <Text></Text>
                                </View>
                                <View style={[s.td, s.w30]}>
                                    <Text></Text>
                                </View>
                            </View>
                        </View>
                        <View style={[s.f10, s.mx2, s.mt3]}>
                            <Text style={s.textBold}>Nota:</Text><Text> El importe correspondiente incluye el impuesto al Valor Agregado</Text>
                        </View>
                        <View style={[s.b1, s.f10, s.mx2, s.mt3]}>
                            <Text style={s.textBold}>Justificación para la adquisición del bien o contratación del servicio</Text>
                            <Text>{object}</Text>
                        </View>
                        <View style={[s.mx2, s.mt3]}>
                            <Text style={s.f10}>
                                Se menciona que la solicitud de suficiencia presupuestal debidamente dictaminada, será remitida en cuanto sea autorizaa por la Dirección de Finanzas, sabedor de que el procedimiento adquisitivo ddara inicio hasta en tanto se cuente con la mimsa en la Direccion de Recursos Materiales y Servicios, de conformidad con lo establecido en los articulos 15 y 16 del Reglamento de la Ley de Contratación Pública del Estado de México y Municipios
                            </Text>
                        </View>
                        <View style={styles.table}>
                            <View style={styles.tableRow}>
                                <View style={[s.tdIn, s.w33, s.h130, s.textCenter, s.textBold, s.bt1]}>
                                </View>
                                <View style={[s.td, s.w33, s.h130, s.textCenter, s.textBold, s.bt1]}>
                                    <Text>TITULAR DE LA SUBDIRECCIÓN DE</Text><Text>ADQUISICIONES</Text>
                                </View>
                                <View style={[s.td, s.w33, s.h130, s.textCenter, s.textBold, s.bt1]}>
                                    <Text>DIRECCIÓN DE RECURSOS MATERIALES Y</Text><Text> SERVICIOS</Text>
                                </View>
                            </View>
                        </View>
                        <View style={[s.f10, s.mx2, s.mt3]}>
                            <Text style={s.textBold}>Nota:</Text><Text> En caso de modificación y/o cancelación del presente documento, se deberá emitir un comunicado signado por el titular de la unidad administrativa requirente</Text>
                        </View>
                        <View fixed style={styles.spaceFooter}></View>
                        <View fixed style={styles.footer}>
                            <Text
                                style={[styles.textFooter, s.w100]}
                                render={({ pageNumber, totalPages }) =>
                                    `PAGINA : ${pageNumber} / ${totalPages}`
                                }
                            />
                        </View>
                    </Page>
                </Document>
            </>)
    }
}
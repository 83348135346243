/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Nav, NavItem, NavLink, Button, Modal, ModalBody, ModalFooter, ModalHeader, Row, Col } from 'reactstrap';
import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";
import { toast } from 'react-toastify';
import { useHistory } from "react-router";
import { SelectTypeHeadSingle } from '../../../components/GenericInputsFormik';

import Notification from '../../../components/Notification/Notification';
import { peticionesReceiver } from '../../../helpers/peticionesReceiver';
import s from "../../personal/expediente-personal/nav.module.scss";
import Widget from '../../../components/Widget/Widget';

import { GetPermisos } from './functions';
import Loader from '../../../components/Loader/Loader';
import TableComponentCustom from '../../../components/TableComponent/TableComponentCustom';

import { v4 as uuidv4 } from 'uuid';


const gmap = process.env.REACT_APP_GMAP;

const centerMx = {
    lat: 19.4508442,
    lng: -99.3823459,
};

const containerStyle = {
    width: "100%",
    height: "400px",
    position: "relative",
    overflow: "hidden",
};

export default function Inmuebles() {
    const API = peticionesReceiver();
    console.log(gmap)

    //FILTRO
    const [keyGroup, setKeyGroup] = useState("");
    const [keySubGroup, setKeySubGroup] = useState("");
    const [keyClass, setKeyClass] = useState("");
    const [keySubClass, setKeySubClass] = useState("");

    const [loadingGroups, setLoadingGroups] = useState(true)
    const [groups, setGroups] = useState([]);
    const [loadingSubgroups, setLoadingSubgroups] = useState(false)
    const [subgroups, setSubgroups] = useState([]);
    const [loadingClasses, setLoadingClasses] = useState(false)
    const [classes, setClasses] = useState([]);
    const [loadingSubclasses, setLoadingSubclasses] = useState(false)
    const [subclasses, setSubclasses] = useState([]);

    const [loading, setLoading] = useState(true)

    const [permissionsForm, setPermissionsForm] = useState("")
    const [locations, setLocations] = useState([]);
    const [activeTab, setActiveTab] = useState(1);
    const [modal, setModal] = useState(false);
    const [selectedItem, setSelectedItem] = useState({});
    const [params, setParams] = useState({
        action: "report38",
        rows: "idProperty,nameBuilding,nameTypeProperty,streetName,subStreet,intNumber,extNumber,zipCode,nameCity,nameCounty,nameState",
        page: "0",
        records: "5",
        search: "",
        order: ""
    });
    const toggle = () => setModal(!modal);
    //"idProperty", "keyProperty", "nameBuilding", "surface", "registerNumber", "nameTypeProperty", "latitude", "longitude", "streetName", "subStreet", "intNumber", "extNumber", "zipCode", "nameCity", "nameCounty", "nameState"
    const cabeceras = ["No", "Nombre", "Tipo de propiedad", "Calle", "Colonia", "No. Int.", "No. Ext.", "CP", "Ciudad", "Municipio", "Estado", "Ver"];

    const filtro = [true, false, true, false, false, true, false, false, true, true, true, true, true, true, true, true]

    async function getBuildsMap() {
        const API = peticionesReceiver();
        const tempParams = JSON.parse(JSON.stringify(params))
        delete tempParams.rows
        delete tempParams.page
        delete tempParams.records
        const parametros = tempParams
        const res = await API.peticionEndPoint(parametros, "app/facades/reports/reportsF.php");
        if (res.status === 200 && res.data.code === "200") {
            const data = res.data.data;
            const filtered = data.filter(build => build.latitude !== "" && build.longitude !== "")
            const mapped = filtered.map(build => {
                build.lat = Number(build.latitude)
                build.lng = Number(build.longitude)
                return build
            })
            setLocations(mapped)
        } else {
            setLocations([])
            toast(<Notification type={"consultar_error"} withIcon />);
        }
    }

    async function getDataGrupo() {
        const params = {
            action: "multiselect",
            table: "property_groups",
            rows: "keyGroup,nameGroup",
            conditions: `enabled = 1`,
        }
        const res = await API.peticion(params)
        if (res.status === 200 && res.data.code === "200") {
            const data = res.data.data;
            setGroups(data)
            setLoadingGroups(false)
        } else {
            setGroups([])
            toast(<Notification type={"consultar_error"} withIcon />);
        }
    }

    async function getDataSubGrupo(keyGroup) {
        setLoadingSubgroups(true)
        setKeySubGroup("")
        setKeyClass("")
        setKeySubClass("")
        const params = {
            action: "multiselect",
            table: "property_subgroups",
            rows: "keySubGroup,nameSubGroup",
            conditions: `enabled = 1 AND keyGroup = ${keyGroup}`,
        };
        const res = await API.peticion(params)
        if (res.status === 200 && res.data.code === "200") {
            const data = res.data.data;
            setSubgroups(data)
            setLoadingSubgroups(false)
        } else {
            setSubgroups([])
            toast(<Notification type={"consultar_error"} withIcon />);
        }
    }

    async function getDataClases(keySubGroup) {
        setLoadingClasses(true)
        setKeyClass("")
        setKeySubClass("")
        const params = {
            action: "multiselect",
            table: "property_classes",
            rows: "keyClass,nameClass",
            conditions: `enabled = 1 AND keySubGroup = ${keySubGroup}`
        }
        const res = await API.peticion(params)
        if (res.status === 200 && res.data.code === "200") {
            const data = res.data.data;
            setClasses(data)
            setLoadingClasses(false)
        } else {
            setClasses([])
            toast(<Notification type={"consultar_error"} withIcon />);
        }
    }

    async function getDataSubClases(keyClass) {
        setLoadingSubclasses(true)
        setKeySubClass("")
        const params = {
            action: "multiselect",
            table: "property_subclasses",
            rows: "keySubClasses,nameSubClass",
            conditions: `enabled = 1 AND keyClass = ${keyClass}`
        }
        const res = await API.peticion(params)
        if (res.status === 200 && res.data.code === "200") {
            const data = res.data.data;
            setSubclasses(data)
            setLoadingSubclasses(false)
        } else {
            setSubclasses([])
            toast(<Notification type={"consultar_error"} withIcon />);
        }
    }

    const history = useHistory();

    GetPermisos(setPermissionsForm);

    useEffect(() => {
        getBuildsMap()
    }, [params])

    useEffect(() => {
        if (groups.length === 0) {
            getDataGrupo();
        }
    }, [groups]);

    useEffect(() => {
        if (keyGroup!=="") {
            getDataSubGrupo(keyGroup);
        } else {
            setKeySubGroup("")
            setKeySubClass("")
            setKeyClass("")
        }
    }, [keyGroup]);

    useEffect(() => {
        if (keySubGroup!=="") {
            getDataClases(keySubGroup)
        } else {
            setKeySubClass("")
            setKeyClass("")
        }
    }, [keySubGroup]);

    useEffect(() => {
        if (keyClass!=="") {
            getDataSubClases(keyClass)
        } else {
            setKeySubClass("")
        }
    }, [keyClass]);

    useEffect(() => {
        if (!loading) {
            const tempParams = {
                action: "report38",
                rows: "idProperty,nameBuilding,nameTypeProperty,streetName,subStreet,intNumber,extNumber,zipCode,nameCity,nameCounty,nameState",
                page: "0",
                records: "5",
                search: "",
                order: ""
            }
            tempParams.keyGroup = keyGroup
            tempParams.keySubGroup = keySubGroup
            tempParams.keyClass = keyClass
            tempParams.keySubClass = keySubClass
            setParams(tempParams)
        }
    }, [keyGroup, keySubGroup, keyClass, keySubClass, loading])

    useEffect(() => {
        setLoading(loadingGroups && loadingSubgroups && loadingClasses && loadingSubclasses)
    }, [loadingGroups, loadingSubgroups, loadingClasses, loadingSubclasses])

    function onClick(row) {
        setSelectedItem(row);
        setModal(true);
    }

    function toEdit() {
        localStorage.setItem("propertyData", JSON.stringify(selectedItem));
        history.push({ pathname: "/template/bienesinmuebles/editar" })
    }

    const renderMap = () => {
        return (
            <GoogleMap
                mapContainerStyle={containerStyle}
                center={centerMx}
                zoom={8}
            >
                {locations.map(location => (
                    <Marker
                        key={uuidv4()}
                        position={location}
                        icon={{
                            url: `data:image/svg+xml;charset=UTF-8,<svg id="Layer_1" style="enable-background:new 0 0 91 91; fill: red;" version="1.1" viewBox="0 0 91 91" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><g><path d="M36.9,51.6h11.6V39h0v-5.7H36.9V51.6z M43.4,35.5h3.4v2.8h-3.4V35.5z M43.4,41.1h3.4v2.8h-3.4V41.1z M43.4,46.7h3.4v2.8   h-3.4V46.7z M38.7,35.5H42v2.8h-3.4V35.5z M38.7,41.1H42v2.8h-3.4V41.1z M38.7,46.7H42v2.8h-3.4V46.7z"/><path d="M51.9,51.6h4.5c0.3,0,0.6-0.3,0.6-0.6v-8.7h-5.1V51.6z"/><path d="M46.3,20c-12.6,0-22.9,10.3-22.9,22.9c0,10.4,7.2,19.6,17.2,22.2L45.1,77c0.2,0.5,0.6,0.8,1.2,0.8c0,0,0,0,0,0   c0.5,0,1-0.3,1.2-0.8l4.3-11.8c10.1-2.5,17.4-11.7,17.4-22.2C69.1,30.3,58.9,20,46.3,20z M60.4,51.1c0,2.2-1.8,3.9-3.9,3.9h-7.9v0   h-15V29.9h18.3V39l8.5,0V51.1z"/></g></svg>`,
                            scaledSize: new window.google.maps.Size(50, 50)
                        }}
                        onClick={() => onClick(location)}
                    />
                ))}
            </GoogleMap>
        )
    }

    const { isLoaded, loadError } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: gmap
    });

    const getBuild = (rows) => {
        const data = { idProperty: rows[0] };
        localStorage.setItem("propertyData", JSON.stringify(data));
        history.push({ pathname: "/template/bienesinmuebles/editar" })
    }

    const onChange = (e) => {
        const { name, value } = e.target
        switch (name) {
            case "keyGroup":
                setKeyGroup(value);
                break;
            case "keySubGroup":
                setKeySubGroup(value);
                break;
            case "keyClass":
                setKeyClass(value);
                break;
            case "keySubClass":
                setKeySubClass(value)
                break;
            default: break;
        }
    }

    return (
        <>
            <Widget className="widget-p-md">
                <h2>Bienes inmuebles</h2>
                {permissionsForm.INS === "1" ? (
                    <div className="text-right">
                        <Button color="success" className="mb-3" onClick={() => history.push({ pathname: "/template/bienesinmuebles/crear" })}>Agregar bien inmueble</Button>                </div>
                ) : null}
                {permissionsForm.CON === "1" && !loading ?
                    (
                        <>
                            <Row>
                                <Col xs={12} md={6}>
                                    <SelectTypeHeadSingle
                                        label="Grupo"
                                        inputName="keyGroup"
                                        onChangeMethod={onChange}
                                        value={keyGroup}
                                        optionsArray={groups}
                                        optionValue="keyGroup"
                                        optionName="nameGroup"
                                        isRequired
                                    />
                                </Col>
                                <Col xs={12} md={6}>
                                    <SelectTypeHeadSingle
                                        label="Subgrupo"
                                        inputName="keySubGroup"
                                        onChangeMethod={onChange}
                                        value={keySubGroup}
                                        optionsArray={subgroups}
                                        optionValue="keySubGroup"
                                        optionName="nameSubGroup"
                                        isRequired
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12} md={6}>
                                    <SelectTypeHeadSingle
                                        label="Clase"
                                        inputName="keyClass"
                                        onChangeMethod={onChange}
                                        value={keyClass}
                                        optionsArray={classes}
                                        optionValue="keyClass"
                                        optionName="nameClass"
                                        isRequired
                                    />
                                </Col>
                                <Col xs={12} md={6}>
                                    <SelectTypeHeadSingle
                                        label="Subclase"
                                        inputName="keySubClass"
                                        onChangeMethod={onChange}
                                        value={keySubClass}
                                        optionsArray={subclasses}
                                        optionValue="keySubClasses"
                                        optionName="nameSubClass"
                                        isRequired
                                    />
                                </Col>
                            </Row>
                        </>
                    )
                    : <Loader />}

                <Nav fill tabs style={{ overflowY: "hidden", overflowX: "auto" }}>
                    <NavItem active={activeTab === 1}>
                        <NavLink
                            href="#"
                            active={activeTab === 1}
                            onClick={() => setActiveTab(1)}
                        >
                            <i className={s.navIco + " fa fa-globe"} />
                            Mapa
                        </NavLink>
                    </NavItem>
                    <NavItem active={activeTab === 2}>
                        <NavLink
                            href="#"
                            active={activeTab === 2}
                            onClick={() => setActiveTab(2)}
                        >
                            <i className={s.navIco + " fa fa-list-alt"} />
                            Tabla
                        </NavLink>
                    </NavItem>
                </Nav>
                <div className="mt-4">
                    {activeTab === 1 ?
                        (<>
                            <div className="d-flex justify-content-center mt-1">
                                {loadError ?
                                    (<div>Ocurrio un error al cargar el mapa</div>)
                                    : isLoaded ?
                                        renderMap()
                                        : <Loader />}
                            </div>
                        </>
                        )
                        : (<>
                            <TableComponentCustom
                                titulo="Archivos registrados"
                                cabecerasTabla={cabeceras}
                                filtro={filtro}
                                parametros={params}
                                addWatch
                                routePeticion="app/facades/reports/reportsF.php"
                                actionWatch={getBuild}
                            />
                        </>)}
                </div>
            </Widget>
            <Modal isOpen={modal} toggle={toggle} backdrop={false}>
                <ModalHeader toggle={toggle}>{selectedItem.nameBuilding}</ModalHeader>
                <ModalBody>{selectedItem.streetName}, {selectedItem.subStreet}, {selectedItem.intNumber ? `NO. ${selectedItem.intNumber}` : ""}, {selectedItem.extNumber ? `EXT. ${selectedItem.extNumber}` : ""}</ModalBody>
                <ModalFooter className='text-center'>
                    <Button color="primary" onClick={() => toEdit()}>
                        Ver detalles
                    </Button>
                </ModalFooter>
            </Modal>
        </>
    )
}
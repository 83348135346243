import * as XlsxPopulate from "xlsx-populate";
import { saveAs } from "file-saver";
import RichText from "xlsx-populate/lib/RichText";


export async function saveExcelTraspasos(
  report,
  headers,
  title,
  filter,
  params
) {
  //console.log(report[0])
  XlsxPopulate.fromBlankAsync().then(async (workbook) => {
    //Estilos
    // const dataStyle = workbook.styleSheet().createStyle();
    // dataStyle.style('wrapText', true);
    // dataStyle.style('horizontalAlignment', 'center');
    // dataStyle.style('verticalAlignment', 'center');
    // dataStyle.style('border', true);

    const ds = {
      wrapText: true,
      horizontalAlignment: "center",
      verticalAlignment: "center",
      border: true,
    };

    // const headerStyle = workbook.styleSheet().createStyle();
    // headerStyle.style('horizontalAlignment', 'center');
    // headerStyle.style('verticalAlignment', 'center');
    // headerStyle.style('border', true);
    // headerStyle.style('bold', true);

    const hs = {
      horizontalAlignment: "center",
      verticalAlignment: "center",
      border: true,
      bold: true,
    };

    // const titleStyle = workbook.styleSheet().createStyle();
    // titleStyle.style('horizontalAlignment', 'center');
    // titleStyle.style('verticalAlignment', 'center');
    // titleStyle.style('bold', true);

    const ts = {
      horizontalAlignment: "center",
      verticalAlignment: "center",
      bold: true,
    };

    const sheet1 = workbook.sheet(0);
    const mid = Math.ceil(headers.length / 2);

    // Unir tres columnas del centro
    let colL = sheet1.column(mid - 1).columnName();
    let colR = sheet1.column(mid + 1).columnName();

    sheet1.range(`${colL}1:${colR}1`).merged(true).value("SAPP").style(ts);
    sheet1.range(`${colL}2:${colR}2`).merged(true).value(title).style(ts);
    if (params && params !== "") {
      sheet1.range(`${colL}3:${colR}3`).merged(true).value(params).style(ts);
    }

    headers.forEach((header, i) => {
      sheet1
        .row(6)
        .cell(1 + i)
        .value(header)
        .style(hs);
    });

    const richText = new RichText();
    report.forEach((rep, i) => {
      richText.clear();
      richText.add(`${rep.number} \n \n  ${rep.nameTypeTransfer} \n \n`);

      sheet1
        .row(7 + i)
        .cell(1)
        .value(richText)
        .style(ds);
      sheet1
        .row(7 + i)
        .cell(2)
        .value(rep.monthOrigin)
        .style(ds);

      richText.clear();
      richText.add(`${rep.sfOrigin} \n \n ${rep.noOrigin} \n \n`);

      sheet1
        .row(7 + i)
        .cell(3)
        .value(richText)
        .style(ds);
      sheet1
        .row(7 + i)
        .cell(4)
        .value(rep.monthDest)
        .style(ds);

      richText.clear();
      richText.add(`${rep.sfDest} \n \n ${rep.noDest} \n \n`);
      sheet1
        .row(7 + i)
        .cell(5)
        .value(richText)
        .style(ds);

      sheet1
        .row(7 + i)
        .cell(6)
        .value(rep.amount)
        .style(ds);
    });

    /* if(report.length > 4000){
      sheet1.row(6).cell(1).value(sheetData);
    }else{
      sheet1.row(6).cell(1).value(sheetData).style(ds);
    } */

    for (let i = 1; i <= headers.length; i++) {
      sheet1.column(i).width(30);

      sheet1.row(6).cell(i).style(hs);
    }

    // sheet1.usedRange().style("horizontalAlignment", "center");

    return workbook.outputAsync().then((res) => {
      saveAs(res, `${title}.xlsx`);
    });
  });
}



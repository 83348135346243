import { useEffect, useState } from "react";
import Datatable from "../../../../components/Datatable/Datatable";
import Widget from "../../../../components/Widget/Widget";
import { doRequest, doRequestSaveRes } from "../../../../helpers/requests";
import { Button, Col, Collapse, Row } from "reactstrap";
import ButtonInputM from "../../../../components/GenericInputsFormik/ButtonInputModal";
import { useFormik } from "formik";
import * as Yup from "yup";
import ModalConfirmation from "../../../../components/Modal/ModalConfirmation";
import FullFormLoader from "../../../../components/Loader/FullFormLoader";
import ModalDelete from "../../../../components/Modal/ModalDelete";
import ModalTableVer from "../../../../components/Modal/ModalTableVer";
import { GetPermissions } from "../../../../libs/permissions/getPermissions";

const columnDefs = [
    { targets: [3], orderable: false },
    { targets: [0, 3], className: "text-center" },
];

const modalTablaAreaParams = {
    title: "Adscripciones",
    headers: ["Adscripción"],
    columns: [{ data: "nameArea" }],
    params: {
        table: "areas",
        rows: "idArea, name AS nameArea",
        conditions: `enabled = 1`,
    },
};

const defaultForm = {
    idSettlementArea: "",
    idArea: "",
    idStaffAproves: "",
    nameArea: "",
    nameStaff: "",
};

const FormSchema = Yup.object().shape({
    nameArea: Yup.string().required("Seleccione una adscripción"),
    nameStaff: Yup.string().required("Seleccione un empleado"),
});

export default function AreasDictaminadoras() {
    const [permissions, setPermissions] = useState({});
    GetPermissions(setPermissions);

    const [refresh, setRefresh] = useState(false);
    const [loading, setLoading] = useState(false);
    const [form, setForm] = useState(false);
    const [modal, setModal] = useState(false);
    const [modalT, setModalT] = useState(false);
    const [registro, setRegistro] = useState({});

    const {
        handleSubmit,
        handleReset,
        handleBlur,
        values,
        errors,
        touched,
        setFieldValue,
        setValues,
    } = useFormik({
        initialValues: defaultForm,
        validationSchema: FormSchema,
        enableReinitialize: true,
        onSubmit: () => setModal(true),
        onReset: () => setForm(false),
    });

    /* Table */
    const columns = [
        { data: "areaKey" },
        { data: "name" },
        {
            data: null,
            orderValue: "fullName",
            render: (row) => (
                <div className="d-flex flex-row">
                    <p>{row.fullName}</p>
                    {permissions.UPD && (
                        <i
                            className="fa fa-pencil text-warning cursor-pointer ml-2"
                            onClick={() => editStaff(row)}
                        />
                    )}
                </div>
            ),
        },
        {
            data: null,
            render: (row) =>
                permissions.DEL && (
                    <ModalDelete
                        modalTitle="Eliminar"
                        table=""
                        nameColumn={[""]}
                        elimina={fnDelete}
                        id={row.idSettlementArea}
                    >
                        <h6>Está seguro de eliminar esta área?</h6>
                        No podrá revertir esta acción
                    </ModalDelete>
                ),
        },
    ];

    async function getData(ordCol, itemsPerPage, currentPage, search, multi) {
        const res = await doRequestSaveRes(
            "receiver/receiver.php",
            getParams(ordCol, itemsPerPage, currentPage, search, multi),
            false,
            false
        );
        if (res.code === "200") return res;
        return null;
    }

    function getParams(ordCol, itemsPerPage, currentPage, search) {
        const params = {
            action: "datatable",
            table: "settlementareas A INNER JOIN areas B USING (idArea) INNER JOIN staff C ON C.idStaff = A.idStaffAproves",
            rows: "A.idSettlementArea, B.areaKey, B.name, CONCAT_WS(' ',C.name,C.pName,C.mName) AS fullName, B.idArea",
            conditions: `A.enabled = 1 AND C.enabled = 1`,
            order: ordCol,
            records: itemsPerPage.toString(),
            page: (itemsPerPage * currentPage).toString(),
            search,
        };
        return params;
    }

    /*Input modal */
    const modalTablaStaffParams = {
        title: "Empelados",
        headers: ["No. Empleado", "Nombre", "Puesto"],
        columns: [
            { data: "staffNumber" },
            { data: "fullName" },
            { data: "nameJobType" },
        ],
        columnDefs: [{ targets: [0, 2], className: "text-center" }],
        params: {
            table: "staff A INNER JOIN employtypes B ON A.keyEmployType = B.keyEmployType INNER JOIN jobstaff C ON C.idStaff = A.idStaff INNER JOIN jobtypes D ON D.keyJobType = A.keyEmployType",
            rows: "A.idStaff, A.staffNumber, CONCAT(A.name,' ',A.pName,' ',A.mName) as fullName, D.nameJobType",
            conditions: `A.enabled = 1 AND A.keyStat = 1 AND C.valid = 1 AND C.enabled = 1 AND C.idArea = ${values.idArea}`,
        },
    };

    function selectRow(row) {
        if (row.idArea) {
            setFieldValue("idArea", row.idArea);
            setFieldValue("nameArea", row.nameArea);
        } else {
            setFieldValue("idStaffAproves", row.idStaff);
            setFieldValue("nameStaff", row.fullName);
        }
    }

    function clearInput(field) {
        if (field === "nameArea") {
            setFieldValue("idArea", "");
            setFieldValue("nameArea", "");
        }
        setFieldValue("idStaffReturn", "");
        setFieldValue("nameStaff", "");
    }

    async function save(param) {
        setLoading(true);
        const params = param || {
            action: "insert",
            table: "settlementareas",
            rows: {
                idArea: values.idArea,
                idStaffAproves: values.idStaffAproves,
            },
            validate: [["idArea"]],
        };
        const res = await doRequest("receiver/receiver.php", params, true);
        if (res.length > 0) {
            setRefresh(true);
            handleReset();
        }
        setLoading(false);
        return res.length > 0;
    }

    async function fnDelete(id) {
        setLoading(true);
        const params = {
            action: "delete",
            table: "settlementareas",
            condition: {
                idSettlementArea: id,
            },
            force: 1,
        };
        const res = await doRequest("receiver/receiver.php", params, true);
        if (res.length > 0) {
            setRefresh(true);
        }
        setLoading(false);
    }

    function editStaff(row) {
        setRegistro({
            params: {
                ...modalTablaStaffParams.params,
                conditions: `A.enabled = 1 AND A.keyStat = 1 AND C.valid = 1 AND C.enabled = 1 AND C.idArea = ${row.idArea}`,
                action: "datatable",
            },
            idRow: row.idSettlementArea,
        });
    }

    useEffect(() => {
        if (registro.idRow) setModalT(true);
        else setModalT(false);
    }, [registro]);

    async function selectStaff(row) {
        const params = {
            action: "update",
            table: "settlementareas",
            rows: { idStaffAproves: row[0] },
            condition: { idSettlementArea: registro.idRow },
            validate: [],
        };
        const res = await save(params);
        if (res) setModalT(false);
    }

    return (
        <Widget className="widget-p-md">
            <h4>Áreas Dictaminadoras</h4>

            {permissions.CON && (
                <Datatable
                    headers={[
                        "Clave de Adscripción",
                        "Adscripción",
                        "Empleado Responsable",
                        "Eliminar",
                    ]}
                    columns={columns}
                    columnDefs={columnDefs}
                    petition={getData}
                    control="back"
                    stateRefresh={[refresh, setRefresh]}
                    searching={true}
                    className="px-0 mt-4"
                />
            )}

            {permissions.INS && (
                <div className=" d-flex flex-md-row flex-column justify-content-md-end my-2">
                    <Button color="info" onClick={() => setForm(true)}>
                        Agregar
                    </Button>
                </div>
            )}

            <Collapse isOpen={form}>
                <h5 className="mb-2">Agregar adscripción</h5>
                <form onSubmit={handleSubmit} onReset={handleReset}>
                    <Row>
                        <Col xs={12} md={6}>
                            <ButtonInputM
                                label="Adscripción"
                                inputName="nameArea"
                                value={values.nameArea}
                                touched={touched.nameArea}
                                errors={errors.nameArea}
                                isRequired={true}
                                modalParams={modalTablaAreaParams}
                                selectRow={selectRow}
                                clearFun={clearInput}
                                isDisabled={values.idSettlementArea}
                            />
                        </Col>
                        <Col xs={12} md={6}>
                            <ButtonInputM
                                label="Empleado"
                                inputName="nameStaff"
                                value={values.nameStaff}
                                touched={touched.nameStaff}
                                errors={errors.nameStaff}
                                isRequired={true}
                                modalParams={modalTablaStaffParams}
                                selectRow={selectRow}
                                clearFun={clearInput}
                                isDisabled={!values.idArea}
                            />
                        </Col>
                    </Row>

                    <div className=" d-flex flex-md-row flex-column-reverse justify-content-md-around">
                        <Button color="danger" type="reset" className="mt-3">
                            Cancelar
                        </Button>
                        <Button
                            color="success"
                            type="submit"
                            className="mt-2 mt-md-3"
                        >
                            Guardar
                        </Button>
                    </div>
                </form>
            </Collapse>

            <ModalConfirmation
                modalTitle="Agregar área"
                modal={modal}
                setModal={setModal}
                crear={save}
            >
                <div className="d-flex justify-content-center">
                    <h6>¿Está seguro de registrar esta área?</h6>
                </div>
            </ModalConfirmation>

            <ModalTableVer
                modal={modalT}
                setModal={setModalT}
                title="Empleados"
                cabeceras={[
                    "Id",
                    "No. Empleado",
                    "Nombre",
                    "Puesto",
                    "Seleccionar",
                ]}
                filtro={[true, true, true, true]}
                parametros={registro.params || {}}
                selectRegistro={selectStaff}
                backdrop
                keyboard
                btnClose
            />

            <FullFormLoader show={loading} />
        </Widget>
    );
}


import React from "react";
import Widget from "../../../../components/Widget/Widget";
import FormComisiones from "../FormComisiones";
import { createComision } from "../functions";

export const CrearComision = (props) => {

  return (
    <Widget className="widget-p-md">
        <h3>Crear comisión</h3>
        <FormComisiones crear={createComision}/>
    </Widget>
  )
}

export default CrearComision;
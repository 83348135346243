import React, { useState } from "react";
import { GetPermissions } from "../../../libs/permissions/getPermissions";
import Widget from "../../../components/Widget/Widget";
import { Button, Nav, NavItem, NavLink } from "reactstrap";
import TiposCompras from "./views/TiposCompras";
import CatalogoDocumentos from "./views/CatalogoDocumentos";
import Loader from "../../../components/Loader/Loader";

const PrincipalTiposCompras = () => {
  const [permissions, setPermissions] = useState(true);
  const [loading, setLoading] = useState(true);

  GetPermissions(setPermissions, setLoading);
  const [activeTab, setActiveTab] = useState(1);

  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  return (
    <Widget className="widget-p-md">

      {loading ? (
        <Loader/>
      ):(
        <>
        <Nav fill tabs style={{ overflowY: "hidden", overflowX: "auto" }}>
        <NavItem active={activeTab === 1}>
          <NavLink
            href="#"
            active={activeTab === 1}
            onClick={() => toggleTab(1)}
          >
            <i class="fa fa-shopping-cart" aria-hidden="true" style={{ fontSize: "18px", marginRight: "5px" }}></i>
            {/* <i
              className={"fa fa-cogs"}
            /> */}
            Tipos de Compra
          </NavLink>
        </NavItem>
        <NavItem active={activeTab === 2}>
          <NavLink
            href="#"
            active={activeTab === 2}
            onClick={() => toggleTab(2)}
          >
            <i
              className={"fa fa-file-text-o"}
              style={{ fontSize: "18px", marginRight: "5px" }}
            />
            Catalogo de documentos
          </NavLink>
        </NavItem>
      </Nav>

      <div className="mt-5">
      {activeTab === 1 ? (
        <TiposCompras permissions = {permissions}/>
      ) : (
        activeTab === 2 && (<CatalogoDocumentos   permissions = {permissions}/>)
      )}
      </div>
        </>

      )}
      
      
    </Widget>
  );
};

export default PrincipalTiposCompras;

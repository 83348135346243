import { Document, Page, Text, View, StyleSheet } from "@react-pdf/renderer";

import { format } from "date-fns"
import { es } from "date-fns/locale"

import { splitString } from "../../../../libs/tools/strings";

import HeaderReport from "../../../../components/Pdf/Header";

const fonts = {
    fontSize: 11,
}

/**
 * Estilo que se repite en todas los headers de la tabla
 */
const s = StyleSheet.create({
    body: {
        paddingTop: 35,
        paddingBottom: 35,
        paddingHorizontal: 40,
    },
    title: { //TITULOS
        width: "100%",
        textAlign: 'center',
        fontFamily: 'Helvetica-Bold',
        fontSize: 11,
    },
    content: {
        width: "100%",
        justifyContent: "center",
        ...fonts
    },
    legend: {
        fontSize: 6
    },
    w50: {
        width: "50%"
    },
    bold: {
        fontFamily: 'Helvetica-Bold'
    },
    underline: {
        textDecoration: "underline"
    },
    textCenter: {
        textAlign: 'center',
    },
    textRight: {
        textAlign: 'right',
    },
    center: {
        justifyContent: "center",
        marginHorizontal: "auto"
    },
    right: {
        justifyContent: "center",
        marginLeft: "auto"
    },
    mt1: {
        marginTop: 10
    },
    mt2: {
        marginTop: 20
    },
    mt3: {
        marginTop: 30
    },
    mt4: {
        marginTop: 40
    },
    mt5: {
        marginTop: 50
    },
    mt6: {
        marginTop: 60
    },
    mt7: {
        marginTop: 70
    },
    px6: {
        paddingHorizontal: 60
    },
    borderTop: {
        borderTopWidth: 1,
        borderTopStyle: "solid",
        borderTopColor: "black",
    },
    
})

export const configPDF = {
    content: [
        { header: "Folio del contrato", value: "numberContract"},
        { header: "Tipo de movimiento", value: "nameMove"},
        { header: "Folio del pleno", value: "authorizationFolio"},
        { header: "Fecha del pleno", value: "authorizationDate"},
        { header: "Puesto laboral", value: "nameJob"},
        { header: "Adscripción", value: "name"},
        { header: "Edificio", value: "nameBuilding"},
        { header: "Inicio nombramiento", value: "startDate"},
        { header: "Fin nombramiento", value: "endDate"},
        { header: "Horas", value: "hours"},
        { header: "Fecha evaluación", value: "evaluationDate"},
        { header: "Resultado", value: "approved"},
        { header: "Notas", value: "notes"},
    ]
}
export const cabecerasPDF = [
    "Folio del contrato",
    "Tipo de movimiento",
    "Folio del pleno",
    "Fecha del pleno",
    "Puesto laboral",
    "Adscripción",
    "Edificio",
    "Inicio nombramiento",
    "Fin nombramiento",
    "Horas",
    "Fecha evaluación",
    "Resultado",
    "Notas",
]



export default function exportPDF(data, model, title, fileNo) {
    const ori = model.orientation === "h" ? "landscape" : "portrait";

    const authorizationDate = format(new Date(data.authorizationDate), "PPP", { locale: es })

    const dirigido = `L. EN D. LÁZARO SÁNCHEZ CONTRERAS`
    const puestoDirigido = `JUEZ DE LA PRIMERA INSTANCIA DEL PODER JUDICIAL DEL ESTADO DE MÉXICO, ADSCRITO AL JUZGADO SEGUNDO CIVIL DEL DISTRITO JUDICIAL DE TOLUCA`

    const puestoComponent = splitString(puestoDirigido, "11pt Helvetica-Bold", 257 / 0.75)
        .map(string => { return (<View style={s.content}><Text>{string}</Text></View>) });

    const acargo = "Lic. en A. Roberto Cervantes Martínez"

    const puestoACargo = "Director General de Administración del Poder Judicial del Estado de México"

    const nombreEmpleado = data.name

    const trabajo = data.nameJob

    const movimiento = data.nameMove

    const startDate = format(new Date(data.startDate), "PPP", { locale: es })

    const endDate = format(new Date(data.endDate), "PPP", { locale: es })

    const horas = data.hours

    const estudios = "Estudiante"
    
    const carrera = data.escolaridad

    const p1 = `Por instrucción del ${acargo}, ${puestoACargo}, comunico que el ${estudios} de la ${carrera}, ${nombreEmpleado}, realizará el ${trabajo}, en el Órgano Jurisdiccional a su cargo, por el periodo que comprenderá del ${startDate} al ${endDate}, en el horario acordado para ese Órgano Jurisdiccional, para así cubrir un total de ${horas} horas; solicitando su apoyo para que al concluir el ${trabajo}, rinda un reporte de evaluación final.`
    
    const p1Component = splitString(p1, "11pt Helvetica", 515 / 0.75)
    .map(string => { return (<View style={s.content}><Text>{string}</Text></View>) });

    const p2 = `Así mismo, con fundamento en el artículo 20 fracciones VII, VIII Y IX, artículo 23 fracción 111 del Reglamento para la Prestación de Servicio Social, Prácticas Profesionales y Meritorias en el Poder Judicial del Estado de México, publicado en la Gaceta del Gobierno de fecha 11 de junio de 2021, la prestadora de Servicio Social deberá rendir ante la Coordinación General de Evaluación, un informe trimestral de actividades, dentro de los tres días hábiles del mes posterior al periodo del informe que corresponda y al finalizar el periodo asignado, un informe final de actividades; así mismo deberá entregar el medio de identificación que la acredita como prestadora.`

    const p2Component = splitString(p2, "11pt Helvetica", 515 / 0.75)
    .map(string => { return (<View style={s.content}><Text>{string}</Text></View>) });

    const p3 = `Esta designación no genera derecho a obtener a alguna plaza dentro del Poder Judicial del Estado de México.`

    const p4 = `Sin otro particular, reitero la seguridad de mi atenta y distinguida consideración.`

    const signName = "JUEZA DRA. ASTRID LORENA AVILEZ VILLENA"
    
    const puestoSignName = `SECRETARIA GENERAL DE ACUERDOS DEL TRIBUNAL SUPERIOR DE JUSTICIA Y DEL 
    CONSEJO DE LA JUDICATURA DEL ESTADO DE MÉXICO`

    const legendF = `C.C.P AGUEDA LÓPEZ HUITRON, para su conocimiento y efectos conducentes,
    Expediente`



    return {
        report: (
            <>
                <Document>
                    <Page style={s.body} orientation={ori}>
                        <HeaderReport/>
                        <View style={s.content}>
                            <View style={[s.w50, s.right]}>
                                <Text style={[s.bold, s.mt1]}>PRESIDENCIA</Text>
                                <Text style={[s.bold, s.mt1]}>NUMERO DE OFICIO: {data.numberContract}</Text>
                                <Text style={[s.bold, s.mt1]}>ASUNTO: {movimiento} DE {trabajo}</Text>
                                <Text style={[s.bold, s.mt1]}>Toluca de Lerdo, México a {authorizationDate}</Text>
                            </View>
                        </View>
                        <View style={[s.content, s.mt3]}>
                            <View>
                                <Text style={[s.bold]}>{dirigido}</Text>
                            </View>
                            <View style={s.w50}>
                                {puestoComponent}
                            </View>
                            <View style={s.mt2}>
                                {p1Component}
                            </View>
                            <View style={s.mt2}>
                                {p2Component}
                            </View>
                            <View style={s.mt2}>
                                <Text>
                                    {p3}
                                </Text>
                            </View>
                            <View style={s.mt2}>
                                <Text>
                                    {p4}
                                </Text>
                            </View>
                            <View>
                                <Text style={[s.textCenter, s.bold, s.mt4]}>
                                    {signName}
                                </Text>
                            </View>
                            <View>
                                <Text style={[s.textCenter, s.bold]}>
                                    {puestoSignName}
                                </Text>
                            </View>
                            <View style={[s.mt4, s.legend]}>
                                <Text>{legendF}</Text>
                            </View>
                        </View>
                        
                    </Page>
                </Document>
            </>
        ),
    };
}
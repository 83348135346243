import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import CurrencyInput from "react-currency-input-field";
import { toast } from "react-toastify";
import Notification from "../../../components/Notification/Notification";
import Loader from "../../../components/Loader/Loader";
import { Button, Col, Row } from "reactstrap";
import axios from "axios";
import { peticionEncript } from "../../../helpers/peticionesEncripted";

const PresupuestosDependientesDetalles = () => {
  const [loading, setLoading] = useState(true);
  const history = useHistory();
  const [form, setForm] = useState({
    idBudget:
      localStorage.getItem("idDetailMonths") !== null ||
      localStorage.getItem("idDetailMonths") !== undefined
        ? localStorage.getItem("idDetailMonths")
        : "",
    total: "",
    nameStateFund: "",
    nameFinancialSource: "",
    nameObject: "",
  });
  const [months, setMonths] = useState([
    {
      idBudgetMonth: "",
      keyMonth: 1,
      nameMonth: "Enero",
      amount: "",
    },
    {
      idBudgetMonth: "",
      keyMonth: 2,
      nameMonth: "Febrero",
      amount: "",
    },
    {
      idBudgetMonth: "",
      keyMonth: 3,
      nameMonth: "Marzo",
      amount: "",
    },
    {
      idBudgetMonth: "",
      keyMonth: 4,
      nameMonth: "Abril",
      amount: "",
    },
    {
      idBudgetMonth: "",
      keyMonth: 5,
      nameMonth: "Mayo",
      amount: "",
    },
    {
      idBudgetMonth: "",
      keyMonth: 6,
      nameMonth: "Junio",
      amount: "",
    },
    {
      idBudgetMonth: "",
      keyMonth: 7,
      nameMonth: "Julio",
      amount: "",
    },
    {
      idBudgetMonth: "",
      keyMonth: 8,
      nameMonth: "Agosto",
      amount: "",
    },
    {
      idBudgetMonth: "",
      keyMonth: 9,
      nameMonth: "Septiembre",
      amount: "",
    },
    {
      idBudgetMonth: "",
      keyMonth: 10,
      nameMonth: "Octubre",
      amount: "",
    },
    {
      idBudgetMonth: "",
      keyMonth: 11,
      nameMonth: "Noviembre",
      amount: "",
    },
    {
      idBudgetMonth: "",
      keyMonth: 12,
      nameMonth: "Diciembre",
      amount: "",
    },
  ]);

  const getBudgetInfo = async () => {
    const params = {
      action: "getBudgetMonthDetail",
      idBudget: form.idBudget,
    }; 
    const finalData = peticionEncript(params);
    await axios
      .post(
        `${process.env.REACT_APP_API}app/facades/budgets/budgetsF.php`,
        finalData
      )
      .then((res) => {
        if (res.status === 200 && res.data.code === "200") {
          setMonths(res.data.data);
          setForm((prevState) => ({
            ...prevState,
            nameStateFund: res.data.data[0].nameStateFund,
            nameFinancialSource: res.data.data[0].nameFinancialSource,
            nameObject: res.data.data[0].nameObject,
          }));
          res.data.data.forEach((e) => {
            if (e.keyMonth === "TOTAL") {
              setForm((prevState) => ({
                ...prevState,
                total: e.amount,
              }));
            }
          });
          setLoading(false);
        } else {
          toast(
            <Notification
              type={"consultar_servidor_error"}
              backMessage={res.data.message}
              withIcon
            />
          );
        }
      })
      .catch((err) => {
        toast(<Notification type={"consultar_servidor_error"} withIcon />);
      });
  };

  const updateMonth = async (idMonth, value) => {
    const params = {
      action: "updateBudgetMonth",
      amount: value,
      idBudgetMonth: idMonth,
    };
    const finalData = peticionEncript(params);
    await axios
      .post(
        `${process.env.REACT_APP_API}app/facades/budgets/budgetsF.php`,
        finalData
      )
      .then((res) => {
        if (res.status === 200 && res.data.code === "200") {
          setForm((prevState) => ({
            ...prevState,
            total: res.data.data[0].total,
          }));
          setLoading(false);
        } else {
          toast(
            <Notification
              type={"consultar_servidor_error"}
              backMessage={res.data.message}
              withIcon
            />
          );
        }
      })
      .catch((err) => {
        toast(<Notification type={"consultar_servidor_error"} withIcon />);
      });
  };

  const onChange = (name, value) => {
    const newArray = months.map((e) => {
      if (e.idBudgetMonth === name) {
        return {
          idBudgetMonth: e.idBudgetMonth,
          keyMonth: e.keyMonth,
          nameMonth: e.nameMonth,
          amount: value,
        };
      } else {
        return e;
      }
    });
    setMonths(newArray);
  };

  const onHandleBlur = (e) => {
    setLoading(true);
    let newValue = e.target.value.replace("$", "");
    newValue = newValue.split(",").join("");
    updateMonth(e.target.name, newValue);
  };

  const regresa = () => {
    history.push({
      pathname: "/template/presupuestos/presupuestos-dependientes",
  });
  }

  useEffect(() => {
    getBudgetInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <form className="mt-4" noValidate>
          <Row className="mt-2">
            <Col xs={12}>
              <div>
                <p>{form.nameStateFund}</p>
              </div>
            </Col>
          </Row>
          <Row className="mt-2">
            <Col xs={12}>
              <div>
                <p>{form.nameFinancialSource}</p>
              </div>
            </Col>
          </Row>
          <Row className="mt-2 mb-5">
            <Col xs={12}>
              <div>
                <p>{form.nameObject}</p>
              </div>
            </Col>
          </Row>
          <Row className="mt-5">
            {months.map((e) => {
              if (e.keyMonth !== "TOTAL") {
                return (
                  <Col xs={12} md={6} lg={4}>
                    <div className="form-group">
                      <label htmlFor={e.idBudgetMonth}>{e.nameMonth}</label>
                      <CurrencyInput
                        id={e.idBudgetMonth}
                        name={e.idBudgetMonth}
                        className="form-control"
                        allowDecimals={true}
                        allowNegativeValue={false}
                        onValueChange={(value, name) => onChange(name, value)}
                        disabled={false}
                        value={e.amount}
                        onBlur={onHandleBlur}
                        maxLength={1000}
                        prefix="$"
                        decimalSeparator="."
                        groupSeparator=""
                      />
                    </div>
                  </Col>
                );
              }
            })}
          </Row>
          <Row className="mt-2">
            <Col xs={12} md={6} lg={4}>
              <div className="form-group">
                <label htmlFor="total">Total</label>
                <CurrencyInput
                  id={form.total}
                  name={form.total}
                  className="form-control"
                  allowDecimals={true}
                  disabled={true}
                  allowNegativeValue={false}
                  onValueChange={(value, name) => onChange(name, value)}
                  value={form.total}
                  onBlur={onHandleBlur}
                  maxLength={1000}
                  prefix="$"
                  decimalSeparator="."
                  groupSeparator=""
                />
              </div>
            </Col>
          </Row>
          <Row className="mt-2">
            <Col xs={12} md={6} lg={4}>
              <Button onClick={() => regresa()}>Regresar</Button>
            </Col>
          </Row>
        </form>
      )}
    </div>
  );
};
export default PresupuestosDependientesDetalles;

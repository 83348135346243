import * as Yup from "yup"
import { useFormik } from "formik";

import { FilesInput } from "../../../../components/GenericInputsFormik";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import { useRef, useState } from "react";
import ModalConfirmation from "../../../../components/Modal/ModalConfirmation";
import { AddFile } from "../../../patrimonio/bienes-inmuebles/functions";
import { toast } from "react-toastify";
import Notification from "../../../../components/Notification/Notification";
import ModalViewFile from "../../../../components/Modal/ModalViewFile";
import { peticionesReceiver } from "../../../../helpers/peticionesReceiver";

export default function ArchivoCotizacion({ idProviderQuote }) {
    const refInput = useRef()
    const [modal, setModal] = useState(false)
    const [modalAdd, setModalAdd] = useState(false)
    const [linkFile, setlinkFile] = useState();
    const [modalFile, setModalFile] = useState(false);

    const handleCloseModal = () => setModalAdd(false)
    const handleOpenModal = () => setModalAdd(true)

    const defaultForm = {
        reference: idProviderQuote,
        keyFileType: "43",
        nameFile: "",
        file: ""
    }

    const FormSchema = Yup.object().shape({
        file: Yup.mixed()
            .test("file-type", "El documento debe ser de tipo pdf", (value) => {
                const files = ["application/pdf"]
                return value && files.includes(value.type);
            })
            .test({
                message: `El documento debe pesar menos de 4MB`,
                test: (value) => value?.size < 4000000,
            })
    })

    const { handleSubmit, values, handleBlur, errors, touched, setFieldValue, resetForm } =
        useFormik({
            initialValues: defaultForm,
            onSubmit: () => setModal(true),
            validationSchema: FormSchema,
        });

    const handleFiles = event => {
        const { files } = event.target
        setFieldValue('file', files[0]);
    }

    const getDocument = async (value) => {
        const params = {
            action: "multiselect",
            table: "files f join bookcase b on (f.idBook=b.idBook) join filetypes ft on (ft.keyFileType=b.keyFileType)",
            rows: "src",
            conditions: `ft.keyFileType = 43 AND f.enabled = 1 AND b.reference=${idProviderQuote}`,
        };
        const API = peticionesReceiver()
        const res = await API.peticion(params)
        if (res.status === 200 && res.data.code === "200") {
            const data = res.data.data;
            if (data.length > 0) {
                setlinkFile(res.data.data[0].src)
                setModalFile(true)
            } else {
                toast(<Notification type={"consultar_error"} withIcon />);
            }
        } else {
            toast(<Notification type={"consultar_error"} withIcon />);
        }
    }

    function resetFormModal(){
        resetForm();
        refInput.current.value = ""
        handleCloseModal()
    }

    return (
        <>
            <Button color="add" size="sm" className="d-flex align-items-center py-1 px-2 mb-2 mr-3" onClick={handleOpenModal} >
                <i className="fa fa-cloud-upload mr-2" />Subir archivo de cotizacion
            </Button>
            <Button color="info" size="sm" className="d-flex align-items-center text-nowrap py-1 px-2 mb-2 mr-3" onClick={getDocument} >
                <i className="fa fa-file-text-o mr-2" />Ver archivo
            </Button>
            <Modal isOpen={modalAdd} toggle={handleCloseModal} fullscreen="lg" size="xl" backdrop="static" >
                <ModalHeader className="pb-0" toggle={handleCloseModal} />
                <ModalBody className="m-0 p-5">
                    <h2>Subir archivo de cotización</h2>
                    <form onSubmit={handleSubmit}>
                        <FilesInput
                            label="Adjuntar cotizacion"
                            inputName="file"
                            fileAccept=".pdf"
                            onChangeMethod={handleFiles}
                            onBlurMethod={handleBlur}
                            value={values.file}
                            errors={errors.file}
                            touched={touched.file}
                            refInput={refInput}
                        />
                        <div className="text-center">
                            <Button>Agregar cotizacion</Button>
                        </div>
                    </form>
                </ModalBody>
            </Modal>

            <ModalConfirmation
                modalTitle={"AGREGAR DOCUMENTO"}
                modal={modal}
                crear={(rows) => AddFile(rows, resetFormModal, "saveFileReplace")}
                setModal={setModal}
                isEdit={false}
                values={values}
            >
                <div className="d-flex justify-content-center">
                    <h6>¿Desea continuar con el registro?</h6>
                </div>
            </ModalConfirmation>
            {linkFile ? (
                <ModalViewFile
                    modal={modalFile}
                    setModal={setModalFile}
                    backdrop={true}
                    keyboard={true}
                    btnClose={true}
                    fileLink={linkFile}
                />
            ) : null}
        </>
    )
}
import { Document, Page, StyleSheet, Text, View } from "@react-pdf/renderer";
import { pt } from "date-fns/locale";
import axios from "axios";
import { toast } from "react-toastify";
import Notification from "../../../../components/Notification/Notification";
import { peticionEncript } from "../../../../helpers/peticionesEncripted";
import HeaderReport from "../../../../components/Pdf/Header";

const formatter = new Intl.NumberFormat("es-MX", {
  style: "currency",
  currency: "MXN",
});

const s = StyleSheet.create({
  body: {
    paddingBottom: 35,
    paddingHorizontal: 20,
  },
  titleStyle: {
    fontWeight: "bold",
    textAlign: "center",
    fontSize: "10px",
    marginBottom: "5px",
    fontFamily: "Helvetica-Bold",
  },
  header: {
    backgroundColor: "#AEAAAA",
    color: "#FFFFFF",
    textAlign: "center",
    marginBottom: "1px"
  },
  vCenter: { height: "100%", paddingTop: "7px" },
  mPage: { margin: "10px" },
  mCell: { padding: "2px" },
  mB2: { marginBottom: "2px" },
  mB5: { marginBottom: "5px" },
  mB10: { marginBottom: "10px" },
  mB12: {marginBottom: "12px"},
  mT11: { marginTop: "11px" },
  row: { flexDirection: "row" },
  w10: { width: "10%" },
  w15: { width: "15%" },
  w20: { width: "20%" },
  w25: { width: "25%" },
  w30: { width: "30%" },
  w35: { width: "35%" },
  w40: { width: "40%" },
  w45: { width: "45%" },
  w50: { width: "50%" },
  w60: { width: "60%" },
  w65: { width: "65%" },
  w70: { width: "70%" },
  w80: { width: "80%" },
  w85: { width: "85%" },
  w90: { width: "90%" },
  w100: { width: "100%" },
  wAuto: { width: "auto" },
  border: { border: "1px solid black" },
  borderT: { borderTop: "1px solid black" },
  borderB: { borderBottom: "1px solid black" },
  borderE: { borderRight: "1px solid black" },
  borderBx2: { borderBottom: "2px solid black" },
  borderTx2: { borderTop: "2px solid black" },
  borderG: { border: "1px solid grey" },
  borderBG: { borderBottom: "1px solid grey" },
  fs8: { fontSize: "8px" },
  fs9: { fontSize: "9px" },
  fs10: { fontSize: "10px" },
  fs12: { fontSize: "12px" },
  fs13: { fontSize: "13px" },
  tend: { textAlign: "right", paddingRight: "3px" },
  tcenter: { textAlign: "center" },
  tBold: { fontFamily: "Helvetica-Bold" },
  spaceFooter: {
    bottom: "10",
    left: "0",
    right: "0",
    width: "100%",
    height: "50px",
  },
  footer: {
    position: "absolute",
    fontSize: "12",
    bottom: "10",
    left: "0",
    right: "0",
    width: "auto",
    height: "30px",
    marginHorizontal: "10px",
    borderTopWidth: "1px",
    flexDirection: "row",
    alignContent: "space-around",
    alignItems: "center",
  },
  textFooter: {
    width: "50%",
    textAlign: "center",
    fontSize: 8,
  },
  bd: {border: "1px solid red"}
});


async function consulta(params) {
  const finalData = peticionEncript(params);
  let info = [];
  await axios
    .post(process.env.REACT_APP_API + "receiver/receiver.php", finalData)
    .then((res) => {
      if (res.status === 200 && res.data.code === "200") {
        info = res.data.data;
      }
    })
    .catch((err) => {
      toast(<Notification type={"consultar_servidor_error"} withIcon />, {
        closeButton: false,
      });
    });
  return info;
}

async function countEmployees(){
  const params = {
    "action": "multiselect",
    "table": "staff S INNER JOIN jobstaff J ON J.idStaff = S.idStaff",
    "rows": "COUNT(S.idStaff) cant",
    "conditions": "S.enabled = 1 AND J.valid = 1 AND J.enabled = 1",
    "order": ""
  }
  const cant = await consulta(params);
  if(cant.length !== 0) return cant[0].cant;
  return 0;
}

async function countPensions(){
  const params = {
    "action": "multiselect",
    "table": "staff S INNER JOIN pensions P ON P.idStaff = S.idStaff INNER JOIN jobstaff J ON J.idStaff = S.idStaff ",
    "rows": "COUNT(S.idStaff) cant",
    "conditions": "S.enabled = 1 AND S.keyStat NOT IN (2, 7, 9, 10, 11, 12, 13) AND J.enabled = 1 AND J.valid = 1 AND P.enabled = 1",
    "order": ""
  }
  const cant = await consulta(params);
  if(cant.length !== 0) return cant[0].cant;
  return 0;
}


export async function pdfResumenGlobalNomina(data, cfg, fileNo) {
  const ban = {
    dep: +data.depositos.totalDepositos,
    dtp: +data.depositos.totalPersonas,
    che: +data.cheques.totalCheques,
    cche: +data.cheques.totalRows
  }
  const pen = {
    dep: +data.pensionesDeposito.totalPensiones,
    ptd: +data.pensionesDeposito.totalPersonas,
    che: +data.pensionesCheque.totalCheques,
    cche: +data.pensionesCheque.totalRows
  }
  const cntEmpl = await countEmployees();
  const cntPens = await countPensions();
  return {
    report: (
      <Document>
        <Page style={s.body}>
          <HeaderReport/>
          <View style={[s.row, {marginTop: "20px"}]}>
            <View style={s.w50}></View>
            <View style={[s.w50, s.fs10]}>
              <View style={s.tcenter}>
                <Text>RESUMEN GLOBAL DE NÓMINA</Text>
              </View>
              <View>
                <View style={[s.row]}>
                  <View style={[s.w60, s.tcenter]}>
                    <Text>QUINCENA:</Text>
                  </View>
                  <View style={[s.w40, s.tcenter]}>
                    <Text>{cfg.period}</Text>
                  </View>
                </View>
                
                <View style={[s.row, s.mB10]}>
                  <View style={[s.w60, s.tend]}>
                    <Text>SERVIDORES PÚBLICOS:</Text>
                  </View>
                  <View style={[s.w40, s.tend]}>
                    <Text>{cntEmpl}</Text>
                  </View>
                </View>

                <View style={[s.row, s.mB10]}>
                  <View style={[s.w60, s.tend]}>
                    <Text>PENSIONES ALIMENTICIAS:</Text>
                  </View>
                  <View style={[s.w40, s.tend]}>
                    <Text>{cntPens}</Text>
                  </View>
                </View>
              </View>
            </View>
          </View>

          <View style={[s.header, s.fs9]}>
              <Text>IPORTE A PAGAR</Text>
          </View>
          <View style={[s.border, {paddingHorizontal: "20px", marginBottom: "2px"}]}>
            <View style={[s.row, s.fs9]}>
              <View style={[s.w10, s.tend]}>
                <View style={[s.mT11]}><Text> </Text></View>
                <View>
                  <Text>BANCO:</Text>
                </View>
                <View>
                  <Text>CHEQUE:</Text>
                </View>
              </View>
              <View style={[s.w40]}>
                <TabSubTotal tt={"SERVIDORES PUBLICOS"} ob={ban} />
              </View>
              <View style={[s.w10]}></View>
              <View style={[s.w40]}>
                <TabSubTotal tt={"PENSIONES ALIMENTICIAS"} ob={pen} />
              </View>
            </View>
            <View style={[s.row, s.mT11, s.mB2, s.fs13]}>
              <View style={s.w50}></View>
              <View style={[s.w50, s.borderBx2]}>
                <View style={s.row}>
                  <View style={[s.w35]}>
                    <Text>TOTAL NETO:</Text>
                  </View>
                  <View style={[s.w65, s.tend]}>
                    <Text>{formatter.format(ban.dep + ban.che + pen.dep + pen.che)}</Text>
                  </View>
                </View>
                
              </View>
            </View>
          </View>

          <View style={[s.header, s.fs9]}>
              <Text>DESGLOCE DE BANCOS</Text>
          </View>
          <View style={[s.row, s.mB5]}>
            <View style={s.w50}>
            <TabDeposito tt={"SERVIDORES PUBLICOS"} ob={data.depositos}/>
            </View>
            <View style={s.w50}>
            <TabDeposito tt={"PENSIONES ALIMENTARIAS"}ob={data.pensionesDeposito}/>
            </View>
          </View>
          <View style={[s.row, s.fs9, s.borderT]}>
            <View style={[s.w50, {marginHorizontal: "10px"}]}>
              <View style={s.row}>
                <View style={[s.w15, s.tcenter]}>
                  <Text>{ban.dtp}</Text>
                </View>
                <View style={[s.w85, s.tend]}>
                  <Text>{formatter.format(ban.dep)}</Text>
                </View>
              </View>
            </View>

            <View style={[s.w50, {marginHorizontal: "10px"}]}>
              <View style={s.row}>
              <View style={[s.w15, s.tcenter]}>
                  <Text>{pen.ptd}</Text>
                </View>
                <View style={[s.w85, s.tend]}>
                  <Text>{formatter.format(pen.dep)}</Text>
                </View>
              </View>
            </View>
          </View>

          <View style={[s.fs9, s.borderB, s.row, {marginTop: "20px"}]}>
            <View style={s.w50}>
              <Text>TOTAL DE DEPÓSITOS EN BANCOS</Text>
            </View>
            <View style={[s.w50, s.fs12, s.tend]}>
              <Text>{formatter.format(ban.dep + pen.dep)}</Text>
            </View>
          </View>

          <View fixed style={s.spaceFooter}></View>
          <View fixed style={s.footer}>
            <Text style={s.textFooter}>{fileNo}</Text>
            <Text
              style={s.textFooter}
              render={({ pageNumber, totalPages }) =>
                `PÁGINA : ${pageNumber} / ${totalPages}`
              }
            />
          </View>
        </Page>
      </Document>
    ),
  };
}

const TabSubTotal = ({tt, ob}) => {
  return(
    <View style={s.mB10}>
      <View style={[s.tcenter, s.mB10]}>
        <Text>{tt}</Text>
      </View>
      <View style={s.row}>
        <View style={s.w20}>
          <View style={s.mB2}><Text> </Text></View>
          <View style={[s.tcenter]}>
            <Text>{ob.cche}</Text>
          </View>
        </View>
        <View style={[s.w80, s.borderG, s.mB10]}>
          <View style={[s.tend, s.borderBG]}>
            <Text>{formatter.format(ob.dep)}</Text>
          </View>
          <View style={[s.tend, pt]}>
            <Text>{formatter.format(ob.che)}</Text>
          </View>
        </View>
      </View>
      <View>
        <View style={[s.borderTx2, s.tend, {paddingTop: "4px"}]}>
            <Text>{formatter.format(ob.dep + ob.che)}</Text>
          </View>
      </View>
      
    </View>
  )
}

const TabDeposito = ({tt, ob}) => {
  return(
    <View style={{marginHorizontal: "10px"}}>
      <View style={[s.fs9, s.tcenter, s.mB2]}>
        <Text>{tt}</Text>
      </View>
      <View style={s.fs9}>
        <View style={s.row}>
          <View style={[s.w15, s.tcenter]}>
            <Text>Depósito</Text>
          </View>
          <View style={[s.w50, s.tcenter]}>
            <Text>Banco</Text>
          </View>
          <View style={[s.w35, s.tcenter]}>
            <Text>Importe</Text>
          </View>
        </View>
        {
          ob.data.map((d, i) => (
            <View style={[s.row, {marginBottom: "1px"}]} key={"R"+i}>
              <View style={[s.w15, s.tcenter]}>
                <Text>{d.totalStaff}</Text>
              </View>
              <View style={[s.w50, {paddingHorizontal: "2px"}]}>
                <Text>{d.nameBank}</Text>
              </View>
              <View style={[s.w35, s.tend]}>
                <Text>{formatter.format(d.total)}</Text>
              </View>
            </View>
          ))
        }
      </View>
    </View>
  )
}

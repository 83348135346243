import {
    Document,
    Page,
    StyleSheet,
    Text,
    View,
} from "@react-pdf/renderer";
import HeaderReport from "../../../../../components/Pdf/Header";
import FooterReport from "../../../../../components/Pdf/Footer";
import { fDateTime, fPercent } from "../../../../../libs/tools/format";


const s = StyleSheet.create({
    body: {
        paddingVertical: "30px",
        paddingHorizontal: "20px",
    },

    title: {
        fontSize: "12px",
        textAlign: "center",
        fontFamily: "Helvetica-Bold",
    },

    header: {
        backgroundColor: "#730A29",
        textAlign: "center",
        color: "#FFFFFF",
        fontWeight: "bold"
    },

    mCell: { padding: "1px" },
    mxCell: { paddingHorizontal: "2px" },

    vCenter: { alignItems: "center", justifyContent: "center" },
    vCenterL: { alignItems: "left", justifyContent: "center" },
    vCenterR: { alignItems: "end", justifyContent: "center" },
    vCenterBtw: { alignItems: "stretch", justifyContent: "center" },

    dflexBtw: { display: "flex", justifyContent: "space-between" },
    dflexEnd: { display: "flex", justifyContent: "flex-end" },

    alignE: { alignContent: "flex-end" },

    row: { flexDirection: "row" },
    flexWrap: { display: "flex", flexWrap: "wrap", flexDirection: "row" },

    w5: { width: "5%" },
    w10: { width: "10%" },
    w15: { width: "15%" },
    w20: { width: "20%" },
    w21: { width: "21.1%" },
    w25: { width: "25%" },
    w30: { width: "30%" },
    w33: { width: "33%" },
    w34: { width: "34%" },
    w36: { width: "36%" },
    w29: { width: "28.9%" },
    w40: { width: "40%" },
    w45: { width: "45%" },
    w50: { width: "50%" },
    w55: { width: "55%" },
    w60: { width: "60%" },
    w65: { width: "65%" },
    w70: { width: "70%" },
    w75: { width: "75%" },
    w80: { width: "80%" },
    w90: { width: "90%" },
    w100: { width: "100%" },
    wAuto: { width: "auto" },

    fborder: { border: "1px solid #000" },
    border: { border: "1px solid #000", borderBottom: "0px" },
    borderT: { borderBottom: "1px solid #000" },
    borderB: { borderBottom: "1px solid #000" },
    borderB2: { borderBottom: "2px solid #000" },
    borderE: { borderRight: "1px solid #000" },
    borderS: { borderLeft: "1px solid #000" },
    borderBS: { borderBottom: "4px solid #730A29" },
    borderHE: { borderRight: "1px solid #FFFFFF" },
    borderD: { border: "1px solid #000", borderTop: "0px" },

    bg: { backgroundColor: "#BEBEBE" },
    bg2: { backgroundColor: "#BEBEBE" },
    bglg: { backgroundColor: "#adb5bd" },

    fs7: { fontSize: "7px" },
    fs8: { fontSize: "8px" },
    fs9: { fontSize: "9px" },
    fs10: { fontSize: "10px" },
    fs11: { fontSize: "11px" },
    fs12: { fontSize: "12px" },
    fs13: { fontSize: "13px" },

    tend: { textAlign: "right", paddingRight: "2px" },
    tstart: { textAlign: "left" },
    tcenter: { textAlign: "center" },
    tBold: { fontFamily: "Helvetica-Bold" },

    mT1: { marginTop: "1px" },
    mT2: { marginTop: "2px" },
    mT8: { marginTop: "8px" },
    mT30: { marginTop: "30px" },
    mT20: { marginTop: "20px" },
    mT70: { marginTop: "70px" },
    mx5: { marginHorizontal: "5px" },
    mx18: { marginHorizontal: "18px" },
    mx20: { marginHorizontal: "20px" },
    mb2: { marginBottom: "2px" },

    spaceFooter: {
        bottom: 10,
        left: 0,
        right: 0,
        width: "auto",
        height: 30,
        marginHorizontal: 10,
    },
});

export default async function TabuladorHistoricoPDF(data, cfg, t, fileNo) {
    return {
        report: (
            <Document>
                <Page style={[s.body, s.fs10]} orientation="landscape">
                    <HeaderReport />
                    <View style={[s.title]}>
                        <Text>TABULADORES HISTORICO</Text>
                    </View>
                    <View style={[s.mT8]}>
                        <View style={[s.row, s.header, s.tcenter, s.fborder]} fixed>
                            <View style={[s.w10, s.borderHE]}>
                                <Text style={[s.mCell]}>Incremento Salarial</Text>
                            </View>
                            <View style={[s.w10, s.borderHE]}>
                                <Text style={[s.mCell]}>{"Año\nAplicación"}</Text>
                            </View>
                            <View style={[s.w20, s.borderHE]}>
                                <Text style={[s.mCell]}>{"Fecha\nAplicación"}</Text>
                            </View>
                            <View style={[s.w10, s.borderHE, s.vCenter]}>
                                <Text style={[s.mCell]}>{"Porcentaje\nBase"}</Text>
                            </View>
                            <View style={[s.w10, s.borderHE, s.vCenter]}>
                                <Text style={[s.mCell]}>{"Porcentaje\nRecompensa"}</Text>
                            </View>
                            <View style={[s.w10, s.borderHE, s.vCenter]}>
                                <Text style={[s.mCell]}>{"Porcentaje\nDespensa"}</Text>
                            </View>
                            <View style={[s.w10, s.borderHE, s.vCenter]}>
                                <Text style={[s.mCell]}>{"Porcentaje\nLiquidación"}</Text>
                            </View>
                            <View style={[s.w10, s.borderHE]}>
                                <Text style={[s.mCell]}>{"Porcentaje\nFortalecimiento"}</Text>
                            </View>
                            <View style={[s.w10, s.vCenter]}>
                                <Text style={[s.mCell]}>Afectados</Text>
                            </View>
                        </View>
                        {
                            data.map((el, ix)=>(
                                <View style={[s.row, s.borderD]} key={"r"+ix}>
                                    <View style={[s.w10, s.borderE, s.vCenter]}>
                                        <Text style={[s.mCell]}>{el.idSalaryIncrease}</Text>
                                    </View>
                                    <View style={[s.w10, s.borderE, s.vCenter]}>
                                        <Text style={[s.mCell]}>{el.anioAplicacion}</Text>
                                    </View>
                                    <View style={[s.w20, s.borderE, s.vCenter]}>
                                        <Text style={[s.mCell]}>{fDateTime(el.fechaApliacion)}</Text>
                                    </View>
                                    <View style={[s.w10, s.borderE, s.vCenter]}>
                                        <Text style={[s.mCell]}>{fPercent(el.porcetageBase)}</Text>
                                    </View>
                                    <View style={[s.w10, s.borderE, s.vCenter]}>
                                        <Text style={[s.mCell]}>{fPercent(el.porcentageReward)}</Text>
                                    </View>
                                    <View style={[s.w10, s.borderE, s.vCenter]}>
                                        <Text style={[s.mCell]}>{fPercent(el.porcentageLarder)}</Text>
                                    </View>
                                    <View style={[s.w10, s.borderE, s.vCenter]}>
                                        <Text style={[s.mCell]}>{fPercent(el.porcentageClearance)}</Text>
                                    </View>
                                    <View style={[s.w10, s.borderE, s.vCenter]}>
                                        <Text style={[s.mCell]}>{fPercent(el.porcentageFortalecimiento)}</Text>
                                    </View>
                                    <View style={[s.w10, s.borderE, s.vCenter]}>
                                        <Text style={[s.mCell]}>{el.cantAfectados}</Text>
                                    </View>
                                </View>
                            ))
                        }
                    </View>
                    <FooterReport fileNo={fileNo}/>
                </Page>
            </Document>
        ),
    };
}


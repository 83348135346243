import Widget from "../../../../components/Widget/Widget";
import FormDiscapacidades from "../FormDiscapacidades";
import { agregar } from "../functions";

const CrearDiscapacidad = (props) => {
  return (
    <div>
      <Widget className="widget-p-md">
        <div className="mb-12">
          <h2 className=" text-center tt-responsive" >Crear Discapacidades</h2>
        </div>
        <FormDiscapacidades crear={agregar} />
      </Widget>
    </div>
  );
};

export default CrearDiscapacidad;

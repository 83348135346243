import React from "react";
import Widget from "../../../../components/Widget/Widget";
import FormPerfil from "../FormPerfil";
import { agregarPerfil } from "../functions";

export const CrearPerfil = (props) => {

  return (
    <Widget className="widget-p-md">
        <h3>Crear perfil</h3>
        <FormPerfil crear={agregarPerfil}/>
    </Widget>
  )
}

export default CrearPerfil;
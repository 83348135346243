import { Document, Page, Text, View } from "@react-pdf/renderer";
import { styles } from "../../../../components/Pdf/pdfGeneral/EstiloPdf";
import { peticionesReceiver } from "../../../../helpers/peticionesReceiver";
import Notification from "../../../../components/Notification/Notification";
import HeaderReport from "../../../../components/Pdf/Header";
import { toast } from "react-toastify";

const API = peticionesReceiver();
let totalPercepciones = 0;
let totalDeducciones = 0;

const formatter = new Intl.NumberFormat("es-MX", {
  style: "currency",
  currency: "MXN",
});

const insertDataStaff = (staff) => {
  return (
    <>
      <View style={styles.tableCell}>
        <View style={styles.cellHeader100Borders}>
          <Text>{"Acumulado Anual"}</Text>
        </View>

        <View style={styles.tableRow}>
          <View style={styles.cellContent50BordersLB}>
            <Text style={styles.textBold}>CLAVE DE SERVIDOR PUBLICO</Text>
          </View>
          <View style={styles.cellContent50BordersRB}>
            <Text>{staff.staffNumber}</Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={styles.cellContent50BordersLB}>
            <Text style={styles.textBold}>NOMBRE</Text>
          </View>
          <View style={styles.cellContent50BordersRB}>
            <Text>{staff.name}</Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={styles.cellContent50BordersLB}>
            <Text style={styles.textBold}>PUESTO</Text>
          </View>
          <View style={styles.cellContent50BordersRB}>
            <Text>{staff.nameJob}</Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={styles.cellContent50BordersLB}>
            <Text style={styles.textBold}>ADSCRIPCION</Text>
          </View>
          <View style={styles.cellContent50BordersRB}>
            <Text>{staff.nameArea}</Text>
          </View>
        </View>
      </View>
    </>
  );
};

const sum = (obj) => {
  if (obj.keyTypeUnder === "1") {
    totalPercepciones += obj.amount ? parseFloat(obj.amount) : 0;
  } else {
    totalDeducciones += obj.amount ? parseFloat(obj.amount) : 0;
  }
};

const insertAccomulated = (data) => {
  return (
    <>
      {/* Headers */}
      <View style={styles.tableCell}>
        <View style={styles.tableRow} fixed>
          <View style={styles.cellHeader25Borders}>
            <Text>CLAVE</Text>
          </View>
          <View style={styles.cellHeader25BordersRTB}>
            <Text>CONCEPTO</Text>
          </View>
          <View style={styles.cellHeader25BordersRTB}>
            <Text>PERCEPCIONES</Text>
          </View>
          <View style={styles.cellHeader25BordersRTB}>
            <Text>DEDUCCIONES</Text>
          </View>
        </View>
        {data.length > 0 &&
          data.map((obj, index) => (
            <View style={styles.tableRow} wrap={false}>
              <View style={styles.cellContent25BordersLBR}>
                <Text>{obj.keyRollUnder} </Text>
              </View>
              <View style={styles.cellContent25BordersRB}>
                <Text>{obj.nameUnder} </Text>
              </View>
              <View style={styles.cellContent25BordersRB}>
                <Text>
                  {" "}
                  {obj.keyTypeUnder === "1"
                    ? formatter.format(obj.amount)
                    : "$0.00"}{" "}
                </Text>
              </View>
              <View style={styles.cellContent25BordersRB}>
                <Text>
                  {" "}
                  {obj.keyTypeUnder === "2"
                    ? formatter.format(obj.amount)
                    : "$0.00"}{" "}
                </Text>
              </View>
              {sum(obj, totalPercepciones, totalDeducciones)}
            </View>
          ))}

        {/* Totales */}
        <View style={styles.tableRow}>
          <View style={styles.cellContent50BordersLBR}>
            <Text style={styles.textBold}>Total</Text>
          </View>
          <View style={styles.cellContent25BordersRB}>
            <Text>{formatter.format(totalPercepciones)} </Text>
          </View>
          <View style={styles.cellContent25BordersRB}>
            <Text>{formatter.format(totalDeducciones)} </Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={styles.cellContent75BordersLBR}>
            <Text style={styles.textBold}>Total Neto</Text>
          </View>
          <View style={styles.cellContent25BordersRB}>
            <Text>{formatter.format(totalPercepciones - totalDeducciones)} </Text>
          </View>
        </View>
      </View>
    </>
  );
};

const getStaff = async (data) => {
  let staff = [];
  const params = {
    action: "getStaffData",
    idStaff: data[0].idStaff,
  };
  await API.peticionEndPoint(params, "app/facades/reports/reportsF.php")
    .then((res) => {
      if (res.status === 200) {
        staff = res.data.data[0];
      } else {
        toast(
          <Notification
            type={"consultar_error"}
            backMessage={res.data.message}
            withIcon
          />
        );
      }
    })
    .catch((err) => {
      toast(<Notification type={"consultar_servidor_error"} withIcon />);
    });
  return staff;
};

const insertTitles = (model, title) => {
  return (
    <>
      <HeaderReport/>
      <View>
        <Text style={styles.title}>{title}</Text>
      </View>
      <View>
        <Text style={styles.subTitle}>
          {model.filters !== undefined
            ? model.filters.onlyMessage === true
              ? model.filters.message
              : ""
            : ""}
        </Text>
      </View>
    </>
  );
};

export async function generatePdfAcumulado(data, model, title, fileNo) {
  let staff = await getStaff(data);

  return {
    report: (
      <>
        <Document>
          <Page style={styles.body}>
            {insertTitles(model, title)}

            {insertDataStaff(staff)}
            {insertAccomulated(data)}

            <View fixed style={styles.spaceFooter}></View>
            <View fixed style={styles.footer}>
              <Text style={styles.textFooter}>{fileNo}</Text>
              <Text
                style={styles.textFooter}
                render={({ pageNumber, totalPages }) =>
                  `PAGINA : ${pageNumber} / ${totalPages}`
                }
              />
            </View>
          </Page>
        </Document>
      </>
    ),
  };
}

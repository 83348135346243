import {
    Document,
    Page,
    StyleSheet,
    Text,
    View,
} from "@react-pdf/renderer";
import HeaderReport from "../../../components/Pdf/Header";
import { fDateTime, fMoney } from "../../../libs/tools/format";
import FooterReport from "../../../components/Pdf/Footer";


const s = StyleSheet.create({
    body: {
        paddingVertical: "30px",
        paddingHorizontal: "20px",
    },

    title: {
        fontSize: "12px",
        textAlign: "center",
        fontFamily: "Helvetica-Bold",
    },

    header: {
        backgroundColor: "#730A29",
        textAlign: "center",
        color: "#FFFFFF",
    },

    mCell: { padding: "1px" },
    mxCell: { paddingHorizontal: "1px" },

    vCenter: { alignItems: "center", justifyContent: "center" },
    vCenterL: { alignItems: "left", justifyContent: "center" },
    vCenterR: { alignItems: "end", justifyContent: "center" },
    vCenterBtw: { alignItems: "stretch", justifyContent: "center" },

    dflexBtw: { display: "flex", justifyContent: "space-between" },
    dflexEnd: { display: "flex", justifyContent: "flex-end" },

    alignE: { alignContent: "flex-end" },

    row: { flexDirection: "row" },
    flexWrap: { display: "flex", flexWrap: "wrap", flexDirection: "row" },

    w5: { width: "5%" },
    w10: { width: "10%" },
    w15: { width: "15%" },
    w20: { width: "20%" },
    w21: { width: "21.1%" },
    w25: { width: "25%" },
    w32: { width: "32%" },
    w33: { width: "33%" },
    w34: { width: "34%" },
    w36: { width: "36%" },
    w29: { width: "28.9%" },
    w40: { width: "40%" },
    w45: { width: "45%" },
    w50: { width: "50%" },
    w55: { width: "55%" },
    w60: { width: "60%" },
    w65: { width: "65%" },
    w70: { width: "70%" },
    w79: { width: "78.9%" },
    w80: { width: "80%" },
    w90: { width: "90%" },
    w100: { width: "100%" },
    wAuto: { width: "auto" },

    fborder: { border: "1px solid #000" },
    border: { border: "1px solid #000", borderBottom: "0px" },
    borderT: { borderBottom: "1px solid #000" },
    borderB: { borderBottom: "1px solid #000" },
    borderB2: { borderBottom: "2px solid #000" },
    borderE: { borderRight: "1px solid #000" },
    borderS: { borderLeft: "1px solid #000" },
    borderBS: { borderBottom: "4px solid #730A29" },
    borderHE: { borderRight: "1px solid #FFFFFF" },

    bg: { backgroundColor: "#BEBEBE" },
    bg2: { backgroundColor: "#BEBEBE" },
    bglg: { backgroundColor: "#adb5bd" },

    fs7: { fontSize: "7px" },
    fs8: { fontSize: "8px" },
    fs9: { fontSize: "9px" },
    fs10: { fontSize: "10px" },
    fs11: { fontSize: "11px" },
    fs12: { fontSize: "12px" },
    fs13: { fontSize: "13px" },

    tend: { textAlign: "right", paddingRight: "2px" },
    tstart: { textAlign: "left" },
    tcenter: { textAlign: "center" },
    tBold: { fontFamily: "Helvetica-Bold" },

    mT1: { marginTop: "1px" },
    mT2: { marginTop: "2px" },
    mT8: { marginTop: "8px" },
    mT30: { marginTop: "30px" },
    mT20: { marginTop: "20px" },
    mT70: { marginTop: "70px" },
    mx5: { marginHorizontal: "5px" },
    mx18: { marginHorizontal: "18px" },
    mx20: { marginHorizontal: "20px" },

    spaceFooter: {
        bottom: 10,
        left: 0,
        right: 0,
        width: "auto",
        height: 30,
        marginHorizontal: 10,
    },
});

export default async function pdfAcucseRecibo(dataf, cfg, t, fileNo) {
    const data = dataf.data;
    const service = dataf.service;
    const fecha = fDateTime(data[0].dateIncome).split("/");
    return {
        report: (
            <Document>
                <Page style={[s.body, s.fs10]}>
                    <HeaderReport />
                    <View style={[s.fborder]}>
                        <View style={[s.row]}>
                            <View style={[s.w32, s.tcenter, s.borderB]}>
                                <View style={[s.tBold, s.borderB, s.bg]}>
                                    <Text>Fecha de Recepción</Text>
                                </View>
                                <View style={[s.row, s.borderB]}>
                                    <View style={[s.w33, s.borderE]}>
                                        <Text>Día</Text>
                                    </View>
                                    <View style={[s.w33, s.borderE]}>
                                        <Text>Mes</Text>
                                    </View>
                                    <View style={[s.w33]}>
                                        <Text>Año</Text>
                                    </View>
                                </View>
                                <View style={[s.row]}>
                                    <View style={[s.w33, s.borderE, {paddingTop: "1px"}]}>
                                        <Text>{fecha[0]}</Text>
                                    </View>
                                    <View style={[s.w33, s.borderE, {paddingTop: "1px"}]}>
                                        <Text>{fecha[1]}</Text>
                                    </View>
                                    <View style={[s.w33, {paddingTop: "1px"}]}>
                                        <Text>{fecha[2]}</Text>
                                    </View>
                                </View>
                            </View>
                            <View style={[s.w36, s.bg, s.tcenter, s.borderS, s.borderE, s.borderB, s.tBold, s.vCenter]}>
                                <Text>ACUSE DE RECEPCIÓN DE {service ? "SERVICIOS" : "MERCANCIA"}</Text>
                            </View>
                            <View style={[s.w32, s.tcenter, s.borderB]}>
                                <View style={[s.tBold, s.borderB, s.bg]}>
                                    <Text>Folio</Text>
                                </View>
                                <View style={[s.vCenter, {height: "24px"}]}>
                                    <Text>{data[0].numberIncome}</Text>
                                </View>
                            </View>
                        </View>
                        <View style={[s.row, s.borderB]}>
                            <View style={[s.tBold, s.tcenter, s.borderE, s.w21]}>
                                <Text>Área Solicitante</Text>
                            </View>
                            <View style={[s.w79]}>
                                <Text style={s.mxCell}>{data[0].nameArea}</Text>
                            </View>
                        </View>
                        <View style={[s.row, s.borderB]}>
                            <View style={[s.tBold, s.tcenter, s.borderE, s.w21]}>
                                <Text>Recibió</Text>
                            </View>
                            <View style={[s.w79]}>
                                <Text style={s.mxCell}>{data[0].fullName}</Text>
                            </View>
                        </View>
                        <View style={[s.borderB, s.tcenter, s.bg, s.tBold]}>
                            <Text>Datos Generales del Proveedor</Text>
                        </View>
                        <View style={[]}>
                            <View style={[s.row, s.borderB]}>
                                <View style={[s.tBold, s.borderE, s.w21]}>
                                    <Text style={[s.mxCell]}>Denominación o Razón Social</Text>
                                </View>
                                <View style={[s.w79]}>
                                    <Text style={s.mxCell}>{data[0].socialName}</Text>
                                </View>
                            </View>
                            <View style={[s.row, s.borderB]}>
                                <View style={[s.tBold, s.borderE, s.w21]}>
                                    <Text style={[s.mxCell]}>RFC</Text>
                                </View>
                                <View style={[s.borderE, s.w29]}>
                                    <Text style={s.mxCell}>{data[0].itin}</Text>
                                </View>
                                <View style={[s.tBold, s.borderE, s.w21]}>
                                    <Text style={[s.mxCell]}>Teléfono</Text>
                                </View>
                                <View style={[s.w29]}>
                                    <Text style={s.mxCell}>{data[0].telephone}</Text>
                                </View>
                            </View>
                            <View style={[s.row]}>
                                <View style={[s.tBold, s.borderE, s.w21]}>
                                    <Text style={[s.mxCell]}>Correo</Text>
                                </View>
                                <View style={[s.w79]}>
                                    <Text style={s.mxCell}>{data[0].email}</Text>
                                </View>
                            </View>
                        </View>
                    </View>

                    <View style={[s.border, s.mT20]}>
                        <View style={[s.tBold, s.tcenter, s.bg, s.borderB]}>
                            <Text>{service ? "Servicios " : "Artículos"}Entregados</Text>
                        </View>
                        <View style={[s.row, s.borderB, s.tBold]}>
                            <View style={[s.tcenter, s.w40, s.borderE]}>
                                <Text>{service ? "Servicio" : "Artículo"}</Text>
                            </View>
                            <View style={[s.tcenter, s.w20, s.borderE]}>
                                <Text>Cantidad Programada</Text>
                            </View>
                            <View style={[s.tcenter, s.w20, s.borderE]}>
                                <Text>Cantidad Recibida</Text>
                            </View>
                            <View style={[s.tcenter, s.w20]}>
                                <Text>Costo Unitario con IVA</Text>
                            </View>
                        </View>
                        {
                            data.map((el, ix)=>(
                                <View style={[s.row, s.borderB]} key={"r"+ix}>
                                    <View style={[s.tcenter, s.w40, s.borderE]}>
                                        <Text style={[s.mxCell]}>{service ? el.nameService : el.nameFurniture}</Text>
                                    </View>
                                    <View style={[s.tcenter, s.w20, s.borderE, s.tend]}>
                                        <Text style={[s.mxCell]}>{el.quantity}</Text>
                                    </View>
                                    <View style={[s.tcenter, s.w20, s.borderE, s.tend]}>
                                        <Text style={[s.mxCell]}>{el.incomeTotal}</Text>
                                    </View>
                                    <View style={[s.tcenter, s.w20, s.tend]}>
                                        <Text style={[s.mxCell]}>{fMoney(el.total)}</Text>
                                    </View>
                                </View>
                            ))
                        }
                    </View>
                    
                    <View style={[s.row, s.mT30, s.tcenter]}>
                        <View style={[s.w50]}>
                            <View>
                                <Text style={[s.fs9]}>Recibio</Text>
                                <Text style={[s.fs10, s.mT2]}>{data[0].fullName}</Text>
                            </View>
                            <View style={[s.mT70, s.borderB, s.mx20]}></View>
                        </View>
                        <View style={[s.w50]}>
                            <View>
                                <Text style={[s.fs9]}>Proveedor</Text>
                                <Text style={[s.fs10, s.mT2]}>{data[0].socialName}</Text>
                            </View>
                            <View style={[s.mT70, s.borderB, s.mx20]}></View>
                        </View>
                    </View>

                    <FooterReport fileNo={fileNo}/>
                </Page>
            </Document>
        ),
    };
}

function Persona({ tt, name, c1 }) {
    return (
        <View style={[s.w32, s.mx5, s.fs9]}>
            <View style={[s.fborder, s.bglg, s.mT1, s.mCell, s.tcenter]}>
                <Text>{tt}</Text>
            </View>
            <View style={[s.fborder, s.mT1, s.tcenter, { minHeight: "40px" }]}>
                <Text>{c1}</Text>
            </View>
            <View style={[s.fborder, s.mT1, { minHeight: "30px" }]}>

            </View>
            <View style={[s.fborder, s.mT1, s.vCenter, { minHeight: "30px" }]}>
                <Text>{name}</Text>
            </View>
        </View>
    )
}

import React from "react";

import Widget from "../../../../components/Widget/Widget";
import FormEdificio from "../FormEdificio";
import { updateBuilding } from "../functions";

export const EditarEdificio = (props) => {
  return (
    <Widget className="widget-p-md">
        <h3>Editar edificio</h3>
        <FormEdificio data={localStorage.getItem('dataContent').split('|-|')} editar={updateBuilding}/>
    </Widget>
  )
}

export default EditarEdificio;
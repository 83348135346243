import { toast } from "react-toastify";
import Datatable from "../../../../components/Datatable/Datatable";
import Widget from "../../../../components/Widget/Widget";
import { peticionesReceiver } from "../../../../helpers/peticionesReceiver";
import Notification from "../../../../components/Notification/Notification";
import { Button, Spinner } from "reactstrap";
import ModalDelete from "../../../../components/Modal/ModalDelete";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { GetPermissions } from "../../../../libs/permissions/getPermissions";

export default function CatalogoIncidencias() {
    const API = peticionesReceiver();
    const history = useHistory()
    const [refresh, setRefresh] = useState(false)
    const [permisos, setPermisos] = useState("")
    const [loading, setLoading] = useState("")
    GetPermissions(setPermisos, setLoading);

    const headers = ["#", "Nombre", "Límite de días permitidos", "Editar", "Eliminar"]

    const columns = [
        { data: "index" },
        { data: "nameTypeIncident" },
        {
            data: "limitDays",
            render: function (row) {
                return row === "-1" ? "N/A" : row
            }
        },
        {
            data: "keyTypeIncident",
            render: function (row) {
                return (
                    <>
                        {permisos.UPD && (
                            <i className="fa fa-pencil-square-o text-warning cursor-pointer" onClick={() => editar(row)} />
                        )}
                    </>
                )
            }
        },
        {
            data: "keyTypeIncident",
            render: function (row) {
                return (
                    <>
                        {permisos.DEL && (
                            <ModalDelete
                                modalTitle="Eliminar"
                                table="types_incidents"
                                nameColumn={["keyTypeIncident"]}
                                elimina={deleteCuenta}
                                id={row}
                            >
                                <h6>
                                    ¿Seguro de eliminar el registro?
                                </h6>
                                No podrá restaurar el contenido
                            </ModalDelete>
                        )}
                    </>
                )
            }
        }
    ]

    async function deleteCuenta(id) {
        const params = {
            action: "delete",
            table: "types_incidents",
            condition: { keyTypeIncident: id },
        };
        try {
            const res = await API.peticion(params);
            if (res.status === 200 && res.data.code === "200") {
                toast(<Notification type={"elimina_exito"} backMessage={res.data.message} withIcon />);
                setRefresh(true)
            } else {
                toast(<Notification type={"elimina_error"} backMessage={res.data.message} withIcon />);
            }
        } catch (err) {
            toast(<Notification type={"elimina_error"} withIcon />);
        }
    }

    const columnDefs = [
        { targets: [0], orderable: false, className: "text-center" },
        { targets: [2, 3, 4], className: "text-center" }
    ]

    const getParamsTableElmt = (order, records, page, search) => {
        const params = {
            action: "datatable",
            table: "types_incidents",
            rows: "keyTypeIncident,nameTypeIncident,limitDays",
            conditions: `enabled=1`,
            order,
            records: records.toString(),
            page: (page * records).toString(),
            search,
        };
        return params;
    };

    async function getData(order, records, page, search) {
        try {
            const res = await API.peticion(
                getParamsTableElmt(order, records, page, search)
            );
            if (res.status === 200 && res.data.code === "200") {
                const data = res.data;
                data.data.forEach((value, index) => (value.index = 1 + index));
                return data;
            } else {
                toast(
                    <Notification
                        type={"consultar_error"}
                        backMessage={res.data.message}
                    />
                );
                return null;
            }
        } catch (err) {
            toast(<Notification type={"consultar_servidor_error"} withIcon />);
            return null;
        }
    }

    const agregar = () => history.push("/template/catalogo-incidencias/agregar")
    const editar = (value) => {
        localStorage.setItem("incCat", value)
        history.push("/template/catalogo-incidencias/editar")
    }

    if (loading) {
        return (
            <div className="d-flex flex-column align-items-center justify-content-center" style={{ minHeight: "300px" }}>
                <Spinner animation="grow" variant="primary" />
            </div>
        )
    }
    return (
        <Widget className="widget-p-md">
            <h3 className="text-secondary">Catalogo de incidencias</h3>
            {permisos.INS && (
                <div className="text-right">
                    <Button onClick={agregar}>Agregar</Button>
                </div>
            )}
            {permisos.CON ? (
                <Datatable
                    headers={headers}
                    columns={columns}
                    columnDefs={columnDefs}
                    order={{ col: 1, opt: "asc" }}
                    control="back"
                    petition={getData}
                    stateRefresh={[refresh, setRefresh]}
                />
            ) : (
                <div className="text-center p-4">
                    No tienes permitido ver los registros de esta seccion
                </div>
            )}

        </Widget>
    )
}
import * as Yup from "yup";
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Button, Col, Row } from "reactstrap";
import ModalConfirmation from "../../../components/Modal/ModalConfirmation";
import { peticionesReceiver } from "../../../helpers/peticionesReceiver";
import { toast } from "react-toastify";
import Notification from "../../../components/Notification/Notification";
import Loader from "../../../components/Loader/Loader";
import CurrencyInput from "react-currency-input-field";
import {
  SelectInputTwoValues,
  TextInput,
  SelectInput,
} from "../../../components/GenericInputsFormik/index";
import { useFormik } from "formik";

const FormObjetos = ({ crear, data, editar }) => {
  const API = peticionesReceiver();
  const history = useHistory();
  const [form, setForm] = useState({
    idExpenseObject: "",
    keyItem: "",
    keyExpenseType: "",
    nameObject: "",
    objectKey: "",
    fullKey: "",
    keyConcept: "",
    keyChapter: "",
  });
  const [loading, setLoading] = useState(true);
  const [selectsData, setSelectsData] = useState({
    expense_types: "",
    expenses_items: "",
    expenses_chapters: "",
    expenses_concepts: "",
  });
  const [modal, setModal] = useState(false);

  const FormSchema = Yup.object().shape({
    keyItem: Yup.string().required("Seleccione una partida de concepto "),
    keyExpenseType: Yup.string().required("Seleccione un tipo de gasto "),
    keyConcept: Yup.string().required("Seleccione un concepto de gasto "),
    keyChapter: Yup.string().required("Seleccione un capitulo de gasto "),
    nameObject: Yup.string().required("Ingrese un nombre de gasto"),
    objectKey: Yup.string().required("Ingrese una partida especifica"),
  });

  const getItems = async (keyConcept) => {
    const params = {
      action: "select",
      table: "expenses_items",
      condition: {
        enabled: 1,
        keyConcept: keyConcept,
      },
      order: "nameItem ASC",
    };
    API.peticion(params)
      .then((res) => {
        if (res.status === 200 && res.data.code === "200") {
          setSelectsData((prevState) => ({
            ...prevState,
            expenses_items: res.data.data,
          }));
          if (data && form.keyItem === "") {
            res.data.data.map((e) => {
              if (e.keyItem === Number(data[7])) {
                setForm((prevState) => ({
                  ...prevState,
                  keyItem: [e.keyItem, e.itemKey].toString(),
                }));
              }
            })
          }
          setLoading(false);
        } else {
          toast(<Notification type={"consultar_error"} withIcon />);
        }
      })
      .catch((err) => {
        toast(<Notification type={"consultar_servidor_error"} withIcon />);
      });
  };

  const getTypes = async () => {
    const params = {
      action: "select",
      table: "expense_types",
      condition: {
        enabled: 1,
      },
      order: "nameExpenseType ASC",
    };
    API.peticion(params)
      .then((res) => {
        if (res.status === 200 && res.data.code === "200") {
          setSelectsData((prevState) => ({
            ...prevState,
            expense_types: res.data.data,
          }));
        } else {
          toast(<Notification type={"consultar_error"} withIcon />);
        }
      })
      .catch((err) => {
        toast(<Notification type={"consultar_servidor_error"} withIcon />);
      });
  };

  const getConcepts = async (keyChapter) => {
    const params = {
      action: "select",
      table: "expenses_concepts",
      condition: {
        enabled: 1,
        keyChapter: keyChapter,
      },
      order: "nameConcept ASC",
    };
    API.peticion(params)
      .then((res) => {
        if (res.status === 200 && res.data.code === "200") {
          setSelectsData((prevState) => ({
            ...prevState,
            expenses_concepts: res.data.data,
          }));
          if (data && form.keyConcept === "") {
            res.data.data.map((e) => {
              if (e.keyConcept === Number(data[9])) {
                setForm((prevState) => ({
                  ...prevState,
                  keyConcept: [
                    e.keyConcept,
                    e.conceptKey,
                  ].toString(),
                }));
              }
            })
          }
        } else {
          toast(<Notification type={"consultar_error"} withIcon />);
        }
      })
      .catch((err) => {
        toast(<Notification type={"consultar_servidor_error"} withIcon />);
      });
  };
  

  const getChapters = async () => {
    const params = {
      action: "select",
      table: "expenses_chapters",
      condition: {
        enabled: 1,
      },
      order: "nameChapter ASC",
    };
    API.peticion(params)
      .then((res) => {
        if (res.status === 200 && res.data.code === "200") {
          setSelectsData((prevState) => ({
            ...prevState,
            expenses_chapters: res.data.data,
          }));
        } else {
          toast(<Notification type={"consultar_error"} withIcon />);
        }
      })
      .catch((err) => {
        toast(<Notification type={"consultar_servidor_error"} withIcon />);
      });
  };

  const getChaptersById = async (keyChapter) => {
    const params = {
      action: "select",
      table: "expenses_chapters",
      condition: {
        enabled: 1,
        keyChapter: keyChapter,
      },
      order: "nameChapter ASC",
    };
    API.peticion(params)
      .then((res) => {
        if (res.status === 200 && res.data.code === "200") {
          setForm((prevState) => ({
            ...prevState,
            keyChapter: [
              res.data.data[0].keyChapter,
              res.data.data[0].chapterKey,
            ].toString(),
          }));
        } else {
          toast(<Notification type={"consultar_error"} withIcon />);
        }
      })
      .catch((err) => {
        toast(<Notification type={"consultar_servidor_error"} withIcon />);
      });
  };

  useEffect(() => {
    if (data) {
      setForm({
        idExpenseObject: data[0],
        keyExpenseType: data[8],
        nameObject: data[5],
        objectKey: data[11],
        fullKey: data[1],
      });
      getTypes();
      getChapters();
      getChaptersById(data[10]).finally(() => {
        getConcepts(data[10]).finally(() => {
          getItems(data[9]);
        });
      });
    } else {
      getChapters();
      getTypes();
      setLoading(false);
    }
  }, []);

  const enviaDatos = () => {
    const chapterKey = values.keyChapter.split(",")[1];
    const conceptKey = values.keyConcept.split(",")[1];
    const itemKey = values.keyItem.split(",")[1];
    setFieldValue(
      "fullKey",
      chapterKey + conceptKey + itemKey + values.objectKey
    );
    setModal(true);
  };

  const cancelOp = () => {
    history.push("/template/objetos-gasto");
  };

  const onChange = (e) => {
    if (e.target.name === "keyChapter") {
      const finalValues = e.target.value.split(",");
      setFieldValue([e.target.name], e.target.value);
      if (values.keyConcept !== "") {
        setFieldValue("keyConcept", "");
        setFieldValue("keyItem", "");
      }
      getConcepts(finalValues[0]);
    } else if (e.target.name === "keyConcept") {
      const finalValues = e.target.value.split(",");
      setFieldValue([e.target.name], e.target.value);
      if (values.keyItem !== "") {
        setFieldValue("keyItem", "");
      }
      getItems(finalValues[0]);
    } else if (e.target.name === "keyItem") {
      setFieldValue([e.target.name], e.target.value);
    } else {
      setFieldValue([e.target.name], e.target.value);
    }
  };

  const { handleSubmit, values, handleBlur, errors, touched, setFieldValue } =
    useFormik({
      initialValues: form,
      onSubmit: () => {
        enviaDatos();
      },
      validationSchema: FormSchema,
      enableReinitialize: true,
    });

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <form onSubmit={handleSubmit} className="mt-4" noValidate>
          <ModalConfirmation
            modalTitle={data ? "Editar" : "Crear"}
            modal={modal}
            setModal={setModal}
            crear={crear}
            editar={editar}
            isEdit={data ? true : false}
            values={values}
          >
            {data ? (
              <div className="d-flex justify-content-center">
                <h6>¿Está seguro de editar el registro?</h6>
              </div>
            ) : (
              <div className="d-flex justify-content-center">
                <h6>¿Desea continuar con el registro?</h6>
              </div>
            )}
          </ModalConfirmation>
          <Row>
            <Col>
              <SelectInputTwoValues
                label="Capitulo"
                inputName="keyChapter"
                onChangeMethod={onChange}
                onBlurMethod={handleBlur}
                value={values.keyChapter}
                databaseValue={"chapterKey"}
                isRequired={true}
                touched={touched.keyChapter}
                errors={errors.keyChapter}
                optionsArray={selectsData.expenses_chapters}
                optionValue="keyChapter"
                optionName="nameChapter"
                defaultOption="Seleccione un capitulo"
              />
            </Col>
            <Col>
              <SelectInputTwoValues
                label="Concepto"
                inputName="keyConcept"
                onChangeMethod={onChange}
                onBlurMethod={handleBlur}
                value={values.keyConcept}
                databaseValue={"conceptKey"}
                isRequired={true}
                touched={touched.keyConcept}
                errors={errors.keyConcept}
                isDisabled={values.keyChapter === "" ? true : false}
                optionsArray={selectsData.expenses_concepts}
                optionValue="keyConcept"
                optionName="nameConcept"
                defaultOption="Seleccione un concepto"
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <SelectInputTwoValues
                label="Partida generica"
                inputName="keyItem"
                onChangeMethod={onChange}
                onBlurMethod={handleBlur}
                value={values.keyItem}
                databaseValue={"itemKey"}
                isRequired={true}
                touched={touched.keyItem}
                errors={errors.keyItem}
                optionsArray={selectsData.expenses_items}
                isDisabled={values.keyConcept === "" ? true : false}
                optionValue="keyItem"
                optionName="nameItem"
                defaultOption="Seleccione una partida generica"
              />
            </Col>
            <Col>
              <div className="form-group">
                <label htmlFor="keyBankMove">
                  Partida Especifica<span className="text-danger">*</span>
                </label>
                <CurrencyInput
                  id="objectKey"
                  name="objectKey"
                  className={
                    "form-control" +
                    (errors.objectKey && touched.objectKey ? " is-invalid" : "")
                  }
                  allowDecimals={false}
                  allowNegativeValue={false}
                  onValueChange={(value, name) =>
                    onChange({
                      target: { name: name, value: value },
                    })
                  }
                  disabled={values.keyItem === "" ? true : false}
                  value={values.objectKey}
                  onBlur={handleBlur}
                  maxLength={1}
                  prefix=""
                  decimalSeparator="."
                  groupSeparator=""
                />
                {touched.objectKey && errors.objectKey && (
                  <span className="text-danger">{errors.objectKey}</span>
                )}
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <SelectInput
                label="Tipo de gasto"
                inputName="keyExpenseType"
                onChangeMethod={onChange}
                onBlurMethod={handleBlur}
                value={values.keyExpenseType}
                isRequired={true}
                touched={touched.keyExpenseType}
                errors={errors.keyExpenseType}
                optionsArray={selectsData.expense_types}
                optionValue="keyExpenseType"
                optionName="nameExpenseType"
                defaultOption="Seleccione una tipo de gasto"
              />
            </Col>
            <Col>
              <TextInput
                label="Denominación"
                inputType="text"
                inputName="nameObject"
                onChangeMethod={onChange}
                onBlurMethod={handleBlur}
                value={values.nameObject}
                isRequired={true}
                touched={touched.nameObject}
                errors={errors.nameObject}
              />
            </Col>
          </Row>
          <div className="d-flex justify-content-between mt-5">
            <Button color="danger" onClick={() => cancelOp()}>
              Cancelar
            </Button>
            <Button color="success" type="submit">
              Guardar
            </Button>
          </div>
        </form>
      )}
    </>
  );
};
export default FormObjetos;
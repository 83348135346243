import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import Notification from "../../../../../components/Notification/Notification";
import { peticionesReceiver } from "../../../../../helpers/peticionesReceiver";
import ModalViewGasto from "../../../componentes/modal/ModalViewGasto";
import { Row, Col } from "reactstrap";
import CollapseTableColor from "../../../componentes/others/CollapseTableColor";

const GastosProyecto = ({ proyect }) => {
  const API = peticionesReceiver();

  const [activities, setActivities] = useState("");
  const [modalViewGasto, setModalViewGasto] = useState(false);

  const [progresSelected, setProgresSelected] = useState("");

  useEffect(() => {
    getActivityExpenses();
  }, []);  

  const getActivityExpenses = () => {
    const params = {
      action: "getActivityEvidencesApproved",
      idProyect: proyect.id,
    };
    API.peticionEndPoint(params, "app/facades/planning/planningF.php")
      .then((res) => {
        if (res.data.status === "success" && res.data.code === "200") {
          setActivities(res.data.data);
        } else {
          toast(
            <Notification
              type={"consultar_error"}
              backMessage={res.data.message}
              withIcon
            />
          );
        }
      })
      .catch((err) => {
        toast(<Notification type={"consultar_servidor_error"} withIcon />);
      });
  };

  const refreshMethod = () => {
    console.log("Refresh Method");
  };

  const showFile = (values) => {
    setProgresSelected(values);
    setModalViewGasto(true);
  };

  return (
    <div>
      {activities !== "" &&
        activities.map((activity) => (
          <Row className="mt-3" key={activity.idActivity}>
            <Col xs={12} md={12} lg={12}>
              <CollapseTableColor
                activity={activity}
                proyect={proyect}
                data={activity}
                refreshMethod={refreshMethod}
                methodViewFile={showFile}
              />
            </Col>
          </Row>
        ))}

      {modalViewGasto && progresSelected !== "" && (
        <ModalViewGasto
          modal={modalViewGasto}
          setModal={setModalViewGasto}
          title={"Gastos realizados"}
          backdrop={"static"}
          keyboard={true}
          progress={progresSelected}
          btnClose={true}
        />
      )}
    </div>
  );
};

export default GastosProyecto;

import React, { useState, useEffect } from "react";
import { Modal, ModalBody, Col, Row, Button } from "reactstrap";
import { SelectTypeHeadSingle } from "../../../components/GenericInputsFormik";
import ModalConfirmation from "../../../components/Modal/ModalConfirmation";
import { useFormik } from "formik";
import * as Yup from "yup";
import { peticionesReceiver } from "../../../helpers/peticionesReceiver";
import { toast } from "react-toastify";
import Notification from "../../../components/Notification/Notification";
import { NumberInput } from "../../../components/GenericInputsFormik/NumberInput";




const ModalEdit = (props) => {
  const { modal, setModal, setReport, objForEdit, valuesReport, setvaluesReport } = props;

  const defaultInfo = {
    idUnder: objForEdit.idUnder,
    amount: objForEdit.amount,
    /* typeEdit: 0, */
  }

  /* const types = [
    {
      id: 1,
      value: "Concepto",
    },
    {
      id: 2,
      value: "Monto",
    },
    {
      id: 3,
      value: "Ambos",
    },
  ]; */

  const API = peticionesReceiver();

  const [modalConfirm, setModalConfirm] = useState(false);
  const [unders, setUnders] = useState("");

  /* useEffect(() => {
    console.log(objForEdit);
  }, [modal]); */
  useEffect(() => {
    getUnders();
  }, []);

  const getUnders = async () => {
    const params = {
      action: "multiselect",
      table: "unders",
      rows: "idUnder,concat(under,' - ',nameUnder) as name",
      conditions: "enabled = 1",
      order: "cast(under as float) asc",
    };
    API.peticion(params)
      .then((res) => {
        if (res.status === 200 && res.data.code === "200") {
          
          setUnders(res.data.data);
        } else {
          toast(
            <Notification
              type={"consultar_error"}
              backMessage={res.data.message}
              withIcon
            />
          );
        }
      })
      .catch((err) => {
        toast(<Notification type={"consultar_servidor_error"} withIcon />);
      });
  };

  const toggle = () => {
    setModal(!modal);
  };

  const CloseBtn = (
    <i className="eva eva-close cursor-pointer" onClick={toggle} />
  );

  const FormSchema = Yup.object().shape({
    /* typeEdit: Yup.string().required("Ingrese un concepto"), */
    /* idUnder: Yup.string().when("typeEdit", {
      is: (val) => +val === 1 || +val ===3,
      then: Yup.string().required("Seleccione un concepto"),
    }),
    amount: Yup.string().when("typeEdit", {
      is: (val) => +val === 2 || +val ===3,
      then: Yup.string().required("Ingrese un monto"),
    }),
     */
    idUnder: Yup.string().required("Ingrese un concepto"),
    amount: Yup.string().required("Ingrese un monto"),
    
  });

  const { handleSubmit, handleBlur, values, errors, touched, setFieldValue } =
    useFormik({
      initialValues: defaultInfo,
      onSubmit: (values) => {
        setModalConfirm(true);
      },
      validationSchema: FormSchema,
      enableReinitialize: true,
    });

  const sendInformation = () => {

    /* var paySlip = values */
    /* delete paySlip.typeEdit 

    if(values.typeEdit ===1){
      delete paySlip.amount 
    }else if (values.typeEdit ===2) {
      delete paySlip.idUnder
    } */
    
    const params = {
      action: "update",
      table: "payslips",
      rows: values,
      condition: {
        idPayslip: objForEdit.idPayslip,
      },
    };


    console.log("Params a a enviar",params)
    API.peticion(params)
      .then((res) => {
        if (
          res.status === "success" ||
          res.status === "200" ||
          res.status === 200
        ) {
          toast(
            <Notification
              type={"agrega_exito"}
              backMessage={res.data.message}
              withIcon
            />
          );
          setModal(false)
          console.log("values report",valuesReport)
          setvaluesReport({
            idStaff: valuesReport.idStaff,
            nameStafff: valuesReport.nameStafff,
            period: valuesReport.period,
            year: valuesReport.year,
          })
        } else {
          toast(
            <Notification
              type={"consultar_error"}
              backMessage={res.data.message}
              withIcon
            />
          );
        }
      })
      .catch((err) => {
        toast(<Notification type={"consultar_servidor_error"} withIcon />, {
          closeButton: false,
        });
      });
  };

  const onChange = (e) => {
    setFieldValue([e.target.name], e.target.value);
  };

  return (
    <div>
      <Modal
        isOpen={modal}
        backdrop={true}
        keyboard={true}
        toggle={toggle}
        style={{ minWidth: "95%", marginTop: "300px" }}
      >
        <form onSubmit={handleSubmit} className="mt-4">
          <ModalBody className="mx-2">
            <Row className="d-flex justify-content-end">{CloseBtn}</Row>
            <h1 className="text-secondary text-center">
              Modificacion de saldo
            </h1>
            {/* <div className="text-center"><span style={{ color: "red" }}>Seleccione el valor a editar</span></div> */}
            
            <Row className="d-flex flex-column justify-content-center align-items-center">
              {/* <Col xs={12} md={12} lg={6}>
                <SelectTypeHeadSingle
                  label="Tipo de edicion"
                  isRequired={true}
                  inputName="typeEdit"
                  optionsArray={types}
                  defaultOption="Seleccione el tipo de edicion "
                  onChangeMethod={(event) => {
                    const { name, value } = event.target;
                    setFieldValue(name, value);
                    setFieldValue("idUnder",objForEdit.idUnder)
                    setFieldValue("amount",objForEdit.amount)
                  }}
                  onBlur={handleBlur}
                  isDisabled={false}
                  value={values.typeEdit}
                  touched={touched.typeEdit}
                  errors={errors.typeEdit}
                  optionValue="id"
                  optionName="value"
                />
              </Col> */}

                  <Col xs={12} md={12} lg={6}>
                    <SelectTypeHeadSingle
                      label="Concepto"
                      isRequired={true}
                      inputName="idUnder"
                      optionsArray={unders}
                      defaultOption="Seleccione un concepto"
                      onChangeMethod={(event) => {
                        const { name, value } = event.target;
                        setFieldValue(name, value);
                      }}
                      onBlur={handleBlur}
                      isDisabled={false}
                      value={values.idUnder}
                      touched={touched.idUnder}
                      errors={errors.idUnder}
                      optionValue="idUnder"
                      optionName="name"
                    />
                  </Col>

                

              
                  <Col xs={12} lg={6}>
                    <NumberInput
                      label="Monto"
                      value={values.amount}
                      errors={errors.amount}
                      inputName="amount"
                      onBlurMethod={handleBlur}
                      onChangeMethod={onChange}
                      touched={touched.amount}
                      prefix="$"
                      decimals
                    />
                  </Col>
                

              <Col className="text-center" xs={12} md={12} lg={6}>
                <Button
                  color="success"
                  className="text-center mt-3"
                  type="submit"
                >
                  Actualizar
                </Button>
              </Col>
            </Row>
            <ModalConfirmation
              modalTitle={"Editar "}
              modal={modalConfirm}
              setModal={setModalConfirm}
              editar={sendInformation}
              isEdit={true}
              values={values}
              /* reset={resetForm} */
            >
              <div className="d-flex justify-content-center">
                <h6>¿Desea editar el regustro?</h6>
              </div>
            </ModalConfirmation>
          </ModalBody>
        </form>
      </Modal>
    </div>
  );
};

export default ModalEdit;

import React, { useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { Col, Row, Button, Collapse } from "reactstrap";

import { REGEX_NUMBER } from "../../../helpers/regex";

import Loader from "../../../components/Loader/Loader";
import Notification from "../../../components/Notification/Notification";
import { TextInput } from "../../../components/GenericInputsFormik/index";
import ModalConfirmation from "../../../components/Modal/ModalConfirmation";
import TableComponentOnePage from "../../../components/TableComponent/TableComponentOnePage";

export const Chequera = ({ idBankAccount, crear, editar, permissions }) => {
  const defaultForm = {
    idCheckBook: "",
    idBankAccount: idBankAccount,
    checkBookNumber: "",
    initialCheck: "",
    finalCheck: "",
    actualCheck: "",
  };

  const [isOpen, setIsOpen] = useState(false);
  const [title, setTitle] = useState("");
  const [modal, setModal] = useState(false);
  const [finalData, setFinalData] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const cabeceras = [
    "#",
    "Chequera",
    "Cheque Inicial",
    "Cheque Final",
    "Cheque Actual",
    "Editar",
  ];
  const filtro = [true, true, true, true, true, true, false, false];

  const [parametros] = useState({
    action: "datatable",
    table: `checkbooks AS CHB
    INNER JOIN bankaccounts AS BNK
    ON BNK.idBankAccount = CHB.idBankAccount`,
    rows: `CHB.idCheckBook,CHB.checkBookNumber,CHB.initialCheck,CHB.finalCheck,CHB.actualCheck`,
    conditions: `BNK.enabled = 1 AND CHB.idBankAccount = ${idBankAccount}`,
    page: 0,
    records: 5,
    search: "",
    order: "",
  });

  const onChange = (event) => {
    const { name, value } = event.target;
    if (name === "account" || name === "numberCard") {
      if (REGEX_NUMBER.test(value)) {
        setFieldValue([name], value);
      }
    } else {
      setFieldValue([name], value);
    }
  };

  const openForm = (isEdit = false) => {
    setTitle(`${isEdit ? "Editar" : "Crear"} chequera`);
    setIsOpen(true);
  };

  const clear = (reset) => {
    setTitle("");
    reset();
    setIsOpen(false);
  };

  const getForEdit = async (row) => {
    if (!isOpen) {
      setFieldValue("idCheckBook", row[0]);
      setFieldValue("checkBookNumber", row[1]);
      setFieldValue("initialCheck", row[2]);
      setFieldValue("finalCheck", row[3]);
      setFieldValue("actualCheck", row[4]);
      setFinalData(true);
      openForm(true);
    } else {
      toast(
        <Notification
          type="warning"
          backMessage="Guarde sus cambios antes de continuar"
        />,
        { closeButton: false }
      );
    }
  };

  const saveInfo = async (reset) => {
    setIsLoading(true);

    const data = {
      ...values,
      checkBookNumber: Number(values.checkBookNumber),
      initialCheck: Number(values.initialCheck),
      finalCheck: Number(values.finalCheck),
      actualCheck: Number(values.actualCheck),
    };

    const params = {
      nameId: "idCheckBook",
      table: "checkbooks",
      rows: data,
      column: "idCheckBook",
      validations: [
        "idBankAccount",
        "checkBookNumber",
        "initialCheck",
        "finalCheck",
        "actualCheck",
      ],
    };

    try {
      const response =
        finalData === true ? await editar(params) : await crear(params);
      if (response.success) {
        toast(
          <Notification
            type={finalData ? "modifica_exito" : "agrega_exito"}
            withIcon
          />
        );
        clear(reset);
        setIsLoading(false);
      } else {
        toast(
          <Notification
            type={finalData ? "modifica_error" : "agrega_error"}
            backMessage={response.message}
            withIcon
          />
        );
        setIsLoading(false);
      }
    } catch (error) {
      toast(<Notification type={"consultar_servidor_error"} withIcon />);
      setIsLoading(false);
    }
  };

  const FormSchema = Yup.object().shape({
    initialCheck: Yup.string().required("Favor de ingresar el cheque inicial"),
    finalCheck: Yup.string().required("Favor de ingresar el cheque final"),
    actualCheck: Yup.string().required("Favor de ingresar el cheque actual"),
    checkBookNumber: Yup.string().required(
      "Favor de ingresar el número de chequera"
    ),
  });

  const {
    handleSubmit,
    values,
    handleBlur,
    errors,
    touched,
    setFieldValue,
    resetForm,
    handleReset,
  } = useFormik({
    initialValues: defaultForm,
    validationSchema: FormSchema,
    onSubmit: () => setModal(true),
    enableReinitialize: true,
  });

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="mt-4">
          <TableComponentOnePage
            titulo="Chequera"
            cabecerasTabla={cabeceras}
            filtro={filtro}
            editar={getForEdit}
            parametros={parametros}
            values={values}
            limpia={clear}
            reset={resetForm}
            eliminar={false}
            permissions={permissions}
          />

          <div className="row mt-2">
            <div className="col-12 col-sm-6">
              <h5 className="text-center text-sm-left">{title}</h5>{" "}
            </div>
            {permissions.INS && <div className="col-12 col-sm-6">
              <div className="text-center text-sm-right">
                <Button
                  color="add"
                  onClick={() => {openForm(); setFinalData(false);}}
                  disabled={isOpen}
                >
                  Agregar
                </Button>
              </div>
            </div>}
          </div>
          <Collapse isOpen={isOpen} className="mt-4 border-top">
            <form
              onSubmit={handleSubmit}
              onReset={handleReset}
              className="mt-4"
            >
              <ModalConfirmation
                modalTitle={finalData ? "Editar" : "Crear"}
                modal={modal}
                setModal={setModal}
                crear={() => saveInfo(resetForm)}
                editar={() => saveInfo(resetForm)}
                isEdit={finalData ? true : false}
                values={values}
              >
                <div className="d-flex justify-content-center">
                  <h6>¿Está seguro de guardar el registro?</h6>
                </div>
              </ModalConfirmation>

              <Row>
                <Col xs={12} md={6}>
                  <TextInput
                    label="Número de Chequera"
                    isRequired
                    inputType="text"
                    inputName="checkBookNumber"
                    onChangeMethod={onChange}
                    onBlurMethod={handleBlur}
                    value={values.checkBookNumber}
                    touched={touched.checkBookNumber}
                    errors={errors.checkBookNumber}
                    isMax={20}
                  />
                </Col>

                <Col xs={12} md={6}>
                  <TextInput
                    label="Cheque Inicial"
                    isRequired
                    inputType="text"
                    inputName="initialCheck"
                    onChangeMethod={onChange}
                    onBlurMethod={handleBlur}
                    value={values.initialCheck}
                    touched={touched.initialCheck}
                    errors={errors.initialCheck}
                    isMax={20}
                  />
                </Col>

                <Col xs={12} md={6}>
                  <TextInput
                    label="Cheque Final"
                    isRequired
                    inputType="text"
                    inputName="finalCheck"
                    onChangeMethod={onChange}
                    onBlurMethod={handleBlur}
                    value={values.finalCheck}
                    touched={touched.finalCheck}
                    errors={errors.finalCheck}
                    isMax={20}
                  />
                </Col>

                <Col xs={12} md={6}>
                  <TextInput
                    label="Cheque Actual"
                    isRequired
                    inputType="text"
                    inputName="actualCheck"
                    onChangeMethod={onChange}
                    onBlurMethod={handleBlur}
                    value={values.actualCheck}
                    touched={touched.actualCheck}
                    errors={errors.actualCheck}
                    isMax={18}
                  />
                </Col>
              </Row>
              <Row className="mt-5">
                <div className="col-sm-6 order-2 order-sm-1 text-center text-sm-left mb-4">
                  <Button
                    color="danger"
                    type="reset"
                    onClick={() => clear(resetForm)}
                  >
                    Cancelar
                  </Button>
                </div>

                <div className="col-sm-6 order-1 order-sm-2 text-center text-sm-right mb-4">
                  <Button color="success" type="submit">
                    {" "}
                    Guardar{" "}
                  </Button>
                </div>
              </Row>
            </form>
          </Collapse>
        </div>
      )}
    </>
  );
};
export default Chequera;

import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import { Col, Row, Button } from "reactstrap";
import datefnsFmt from "date-fns/format";
import FullFormLoader from "../../../components/Loader/FullFormLoader";
import { SelectWithSearchInput } from "../../../components/GenericInputsFormik/index";
import * as Yup from "yup";
import { peticionesReceiver } from "../../../helpers/peticionesReceiver";
import { toast } from "react-toastify";
import Notification from "../../../components/Notification/Notification";

import TableComponentReport from "../../../components/TableComponent/TableComponentReport";
import Widget from "../../../components/Widget/Widget";

import { saveAsExcelTotal } from "../../../components/excel/ExcelTotal";
import { saveReport7AsExcel } from "./excel/saveReport7AsExcel";
import { saveReport8AsExcel } from "./excel/AcumuladoAnual";
import Filtro1015 from "./filtros/Filtro1015";
import ModalTableVer from "../../../components/Modal/ModalTableVer";
import ModalReports from "../../../components/Modal/ModalReports";
import { generatePdfGeneral } from "../../../components/Pdf/pdfGeneral/PdfGeneral";
import { generatePdfAcumulado } from "./pdf/PdfAcumulado";
import { generatePdfPercepcionesDeducciones } from "./pdf/PdfPercepcionesDeducciones";
import { generatePdfComprobantePago } from "./pdf/PdfComprobantePago";
import { generatePdfGlobalPercepciones } from "./pdf/PdfGlobalPercepciones";
import { generatePdfConceptoNomina } from "./pdf/PdfConceptoNomina";
import { generatePdfReporteProyectoPresupuestales } from "./pdf/PdfReporteProyectoPresupuestal";
import { generatePdfReportePartida } from "./pdf/PdfReportePartida";
import { saveReport17AsExcel } from "./excel/GlobalPercepciones";
import { generateExcelHistoricoPlazas } from "./excel/HistoricoPlazas";
import { generateExcelAportacionesIssemym } from "./excel/CoutasAportacionesIssemym";
import { saveAsExcel } from "../../../components/excel/Excel";
import { saveReport9AsExcel } from "./excel/ComprovantePago";
import { NominaGeneral } from "./excel/NominaGeneral";
import { PdfNominaGneral } from "./pdf/PdfNominGeneral";
import { resumenGlobalNomina } from "./excel/resumenGlobalNomina";
import { pdfResumenGlobalNomina } from "./pdf/pdfResumenGlobalNomina";
import Reporte20Web from "./filtros/Reporte20Web";
import { generatePDFVacantes } from "./pdf/PdfVacantes";
import { generatePdfVencimientoPlazas } from "./pdf/PdfVencimientoPlazas";
import { generatePdfSaldosNegativos } from "./pdf/PdfSaldosNegativos";
import { generatePdfSaldosMenores30 } from "./pdf/PdfSaldosMenores30";
import { generatePdfHistoricoPlazas } from "./pdf/PdfHistoricoPlazas";
import { generatePdfCoutasAportacionesIssemym } from "./pdf/PdfCoutasAportacionesIssemym";
import { generatePdfNominaDesglosadoGen } from "./pdf/PdfNominaDesglosadoGen";
import { pensionesBancos } from "./excel/pensionesBancos";
import { nominaDesglosadoExcel } from "./excel/NominaDesglosado";
import PdfPensionesBancos from "./pdf/PdfPensionesBancos";
import { generatePdfEmpleadosBancos } from "./pdf/PdfEmpleadosBancos";
import { detallesPensionesExcel } from "./excel/DetallesPensiones";
import { generatePdfDetallePensiones } from "./pdf/PdfDetallePensiones";
import { generatePdfDeduccionesAdicionales } from "./pdf/PdfDeduccionesAdicionales";
import Report47 from "./html/detallePensiones";
import NominaDesglosado from "./html/NominaDesglosado";
import { empleadosUnidad } from "./excel/empleadosUnidad";
import PdfEmpleadosUnidad from "./pdf/PdfEmpleadosUnidad";
import { GetPermissions } from "../../../libs/permissions/getPermissions";
import MinimoVital from "./pdf/MinimoVita";
import TabuladoresHistorico from "./html/tabuladores-historico/TabuladoresHistorico";
import TabuladorHistoricoPDF from "./pdf/tabuladores-historico/TabuladoresHistoricoPDF";
import { tabuladoresHistoricoExcel } from "./excel/tabuladores-historico/tabuladoresHistoricoExcel";
import ComprobantePagoRepHTML from "./html/ComprobatedePago";
import { clearRep14 } from "../../../components/TableComponent/clearReports/clearReports";
import deduccionesAdicionalesExcel from "./excel/deduccionesAdicionalesExcel";
import { getStartEndPeriod } from "../../../libs/tools/format";

export const ReportesNomina = (props) => {
  GetPermissions();
  const API = peticionesReceiver();
  const [loader, setLoader] = useState(false);
  const [route, setRoute] = useState("app/facades/reports/reportsF.php");
  //modalTabla general
  const [modalTabGeneral, setModalTabGeneral] = useState(false);
  const [modalTabGTitle, setModalTabGTitle] = useState("");
  const [modalTabGCabeceras, setModalTabGCabeceras] = useState([]);
  const [modalTabGFiltro, setModalTabGFiltro] = useState([]);
  const [fieldToShow, setFieldToShow] = useState("");
  const [modalTabGParams, setModalTabGParams] = useState({});
  //variables ModalTabla Jobs
  const [modalTablaJobs, setModalTablaJobs] = useState(false);
  const cabecerasModalTablaJobs = ["#", "Puesto"];
  const [paramsModalTablaJobs] = useState({
    action: "datatable",
    table:
      "jobpositions A INNER JOIN status B USING (keyStat)INNER JOIN positiontypes C USING (keyPositionType)inner join jobs D USING (keyJob)inner join areas E USING (idArea)INNER JOIN salaries tbs USING (idSalary)",
    rows: "numberJobPositions, CONCAT(numberJobPositions,' - ',CONCAT(tbs.Level,'',if(tbs.subLevel is not null and tbs.subLevel != '',CONCAT('-',tbs.subLevel),''),'-',tbs.rango),' - ',nameJob,' - ',E.name) as positionData",
    conditions: "A.enabled = 1",
    page: 0,
    records: 5,
    search: "",
    order: "",
  });
  //bancos
  const [modalTablaBanks, setModalTablaBanks] = useState(false);
  const cabecerasModalTablaBanks = ["#", "Banco"];
  const [paramsModalTablaBanks] = useState({
    action: "datatable",
    table: "banks",
    rows: "keyBank,nameBank",
    conditions: "enabled = 1",
    page: 0,
    records: 5,
    search: "",
    order: "",
  });
  //Varaibles de modalTabla
  const [modalTablaStaff, setModalTablaStaff] = useState(false);
  const [modalReports, setModalReports] = useState(false);
  const filtroModalTabla = [true, true, true, true, true];
  const cabecerasModalTabla = [
    "#",
    "Núm. Empleado",
    "Nombre",
    "Unidad de trabajo",
    "Puesto",
    "Seleccionar",
  ];
  const [paramsModalTabla] = useState({
    action: "datatable",
    table:
      "staff STF INNER JOIN employtypes AS EMT ON STF.keyEmployType = EMT.keyEmployType INNER JOIN jobstaff AS JBS ON JBS.idStaff = STF.idStaff INNER JOIN areas AS AR ON AR.idArea = JBS.idArea INNER JOIN jobtypes AS JBT ON JBT.keyJobType = STF.keyEmployType",
    rows: "STF.idStaff,STF.staffNumber,CONCAT(STF.name,' ',STF.pName,' ',STF.mName) as fullName,AR.name,JBT.nameJobType",
    conditions: "JBS.valid = 1 AND JBS.enabled = 1",// AND STF.keyStat IN (1 , 2)
    page: 0,
    records: 5,
    search: "",
    order: "",
  });
  // Varaible para tipo de reporte
  const [descTipo, setDescTipo] = useState("");
  // variables para cargar los selects
  const [typeReports, setTypeReports] = useState([]);
  /* Parametros para enviar a los endPoint */
  const [data, setData] = useState("");
  /* Variable donde se guarda la informacion del reporte */
  const [report, setReport] = useState();
  const [title, setTitle] = useState("");
  /* Variable para habilitar  */
  const [showReportFormat, setShowReportFormat] = useState("0");
  // pruebaParaTabla
  // Configuracion de pdf
  const [pdfConfig, setPdfConfig] = useState("");
  /* Cabeceras que se enviaran  */
  const [cabeceras, setCabeceras] = useState([]);

  /* Trae los reportes  */
  /* use Effect inicial */
  useEffect(() => {
    getTypeReports();
  }, []);

  /* Info para llenar select de los tipos de reportes */
  const getTypeReports = () => {
    const params = {
      action: "getReports",
      rows: {
        idModule: "33",
      },
    };
    API.peticionEndPoint(params, "app/facades/reports/reportsF.php")
      .then((res) => {
        if (res.status === 200) {
          setTypeReports(res.data.data);
        } else {
          toast(
            <Notification
              type={"consultar_error"}
              backMessage={res.data.message}
              withIcon
            />,
            { closeButton: false }
          );
        }
      })
      .catch((err) => {
        toast(<Notification type={"consultar_servidor_error"} withIcon />, {
          closeButton: false,
        });
      });
  };

  const onChange = (e) => {
    if (!e.target.value) {
      if (e.target.name === "initialDate") setFieldValue("finalDate", "");
      if (e.target.name === "period") setFieldValue("year", "");
    }
    setFieldValue(e.target.name, e.target.value);
    setShowReportFormat("0");
  };

  const getData = (reportConfig, filters) => {
    //Reinicia variables en caso de consultar mas de 1 reporte
    setReport(undefined);
    setTitle("");
    setShowReportFormat("0");
    setDescTipo("");
    
    /*
      Deacuerdo al tipo de reporte , prepara configuracion para cada uno
         ++ Data  = Parametros que se enviara en la peticion
         ++ Titulo = Titulo que se mostrara en archivo
         ++ Cabeceras = Cabeceras a utilizar {Tabla o excel}
         ++ PdfConfig = Configuracion para enviar al pdf

    
                Estructura de PdfConfig 

            headers:["NO","CLAVE AREA","REPORTE GENERAL DEL AREA"],    === Cabeceras de las tablas (Rojo)                                             
            id:"idArea",  ===   Id que retorna la peticion , (Se mapea aparte de los datos generales)

            La estructura para el content es un arreglo que contiene arreglos de objetos 
            Estos arreglos representan una Fila  en el pdf 

                      content se establece como queremos mapear nuestro pdf  cuenta con los siguientes campos
                           ++header = Es como aparecera nuestro campo escrito en negritas, ejemplo NOMBRE DEL AREA, NOMBRE DEL USUARIO
                           ++value = Es el valor al que corresponde nuestra cabecera ejemplo: name, nameArea,nameBuilding
                              estos campos en el pdf se veran asi -> NOMBRE: JORGE
                           ++colums: es el numero de columnas que recibira el pdf, puede recibi un maximo de 3 y puede abarcar solo una o las 3 columnas
        content:[
          [ 
            {  header:"CURP",value:"curp",columns:2},
            {  header:"TEL.EMPLEO",value:"jobNumber",columns:1},
          ],
          [
            {  header:"GENERO",value:"nameGender",columns:1},
            {  header:"ESTADO CIVIL",value:"nameTypeMarital",columns:1},
            {  header:"DISCAPACIDAD",value:"nameDisability",columns:1}
          ],
          [ {header:"LUGAR DE NACIMIENTO",value:"nacidoEn",columns:3} ], 
        ]

        El ejemplo anterior se veria de la siguiente forma:
        CURP: KLASJDKLJKLASLK                               TEL.EMPLEO: 7178981289
        GENERO: MASCULINO        ESTADO CIVIL: CADASO       DISCAPACIDAD: NINGUNA
        LUGAR DE NACIMIENTO: CALLE MONTES DE OCA , COLONIA ORTIZ, ESTADO DE MEXICO
    */
    if (reportConfig.idReport === "6" || reportConfig.idReport === 6) {
      setTitle("Reporte de Plantilla Nomina");
      setCabeceras([
        "NUMERO DE EMPLEADO",
        "NOMBRE",
        "RFC",
        "NOMBRE DE EMPLEO",
        "NOMBRE DEL AREA",
        "EDIFICIO",
        "RANGO DE NIVEL",
        "S.BASE",
        "S.BRUTO",
        "S.NETO",
        "PREMIO",
        "RETARDO",
        "VIAJES",
        "AUTORIZACION",
        "FORTALECIMIENTO",
        "IMPUESTOS",
        "SEGURIDAD",
      ]);
      setPdfConfig({
        headers: ["No", "No.EMPLEADO", "REPORTE GENERAL DE NOMINA"],
        id: { id: "staffNumber" },
        content: [
          [
            { header: "NOMBRE", value: "name", columns: 2 },
            { header: "RFC", value: "rfc", columns: 1 },
          ],
          [{ header: "NOMBRE DE EMPLEO", value: "nameJob", columns: 3 }],
          [{ header: "NOMBRE DEL AREA", value: "NameArea", columns: 3 }],
          [
            { header: "EDIFICIO", value: "nameBuilding", columns: 2 },
            { header: "RANGO DE NIVEL", value: "levelrang", columns: 1 },
          ],
          [
            { header: "S.BASE", value: "base", columns: 1, format: "moneda" },
            { header: "S.BRUTO", value: "gross", columns: 1, format: "moneda" },
            { header: "S.NETO", value: "net", columns: 1, format: "moneda" },
          ],

          [
            { header: "PREMIO", value: "reward", columns: 1, format: "moneda" },
            {
              header: "RETARDO",
              value: "larder",
              columns: 1,
              format: "moneda",
            },
            {
              header: "VIAJES",
              value: "travels",
              columns: 1,
              format: "moneda",
            },
          ],
          [
            {
              header: "AUTORIZACION",
              value: "clearance",
              columns: 2,
              format: "moneda",
            },
            {
              header: "FORTALECIMIENTO",
              value: "fortalecimiento",
              columns: 1,
              format: "moneda",
            },
          ],
          [
            {
              header: "IMPUESTOS",
              value: "taxes",
              columns: 2,
              format: "moneda",
            },
            {
              header: "SEGURIDAD",
              value: "ssecurity",
              columns: 1,
              format: "moneda",
            },
          ],
        ],
      });
      setData({
        action: "report6",
        /* page: 1,
        records: 500, */
        search: "",
        order: "",
      });
    } else if (reportConfig.idReport === "7" || reportConfig.idReport === 7) {
      /* const resultado = areas.find( area => area.id === reportConfig.idArea ); */
      setTitle("Reporte de Percepciones y Deducciones");
      setCabeceras(cabeceraGeneral);
      setPdfConfig({
        headers: ["NO", "NO. EMPLEADO", "REPORTE GENERAL DEL AREA"],
        filters: {
          message: `DEL PERIODO ${
            values.period
          } DEL AÑO ${values.year.getFullYear()}`,
          onlyMessage: true,
        },
        id: { id: "staffNumber" },
        content: contentPdfGeneral,
      });
      setData({
        action: "report7",
        search: "",
        order: "",
        idStaff: reportConfig.idStaff,
        period: reportConfig.period,
        year: reportConfig.year.getFullYear(),
      });
    } else if (reportConfig.idReport === "8" || reportConfig.idReport === 8) {
      setTitle("Reporte de acumulado anual");
      setCabeceras(cabeceraGeneral);
      setPdfConfig({
        headers: ["NO", "NO. EMPLEADO", "REPORTE GENERAL DEL AREA"],
        filters: {
          message: `AÑO ${values.year.getFullYear()}`,
          onlyMessage: true,
        },
        id: { id: "staffNumber" },
        content: contentPdfGeneral,
      });
      setData({
        action: "report8",
        search: "",
        order: "",
        idStaff: reportConfig.idStaff,
        year: reportConfig.year.getFullYear(),
      });
    } else if (+reportConfig.idReport === 9) {
      setTitle("Comprobante de Pago");
      setCabeceras(cabeceraGeneral);
      setPdfConfig({
        headers: ["NO", "NO. EMPLEADO", "REPORTE GENERAL DEL EMPLEADO"],
        id: { id: "staffNumber" },
        content: contentPdfGeneral,
        filters: { message: `${values.period}`, onlyMessage: true },
      });
      setData({
        action: "report7",
        search: "",
        order: "",
        idStaff: reportConfig.idStaff,
        period: reportConfig.period,
        year: reportConfig.year.getFullYear(),
      });
    } else if ((+reportConfig.idReport >= 10 && +reportConfig.idReport <= 17 ) ||  [63, 66].includes(+reportConfig.idReport)) {
      config1017(reportConfig);
    } else if (+reportConfig.idReport === 20) {
      setTitle("RESUMEN GLOBAL DE NOMINA");
      setCabeceras(["NO.EMPLEADO"]);
      setPdfConfig({
        period: `${reportConfig.period}${reportConfig.year.getFullYear()}`,
      });
      setData({
        action: "report20",
        search: "",
        order: "",
        period: reportConfig.period,
        year: reportConfig.year.getFullYear(),
      });
    } else if (reportConfig.idReport === "21" || reportConfig.idReport === 21) {
      setTitle("Reporte de conceptos de Nomina");
      setCabeceras([
        "NO.EMPLEADO",
        "NOMBRE",
        "ADSCRIPCION",
        "PUESTO LABORAL",
        "NIVEL - RANGO",
        "FECHA DE INICIO",
        "FECHA DE TERMINO",
        "NETO",
        "IMPORTE",
      ]);
      setPdfConfig({
        headers: [
          "NUMERO DE EMPLEADO",
          "NOMBRE",
          "ADSCRIPCION",
          "PUESTO LABORAL",
          "NIVEL - RANGO",
          "FECHA DE INICIO",
          "FECHA DE TERMINO",
          "NETO",
          "IMPORTE",
        ],

        columnsHeaders: [11, 11, 11, 11, 11, 11, 11, 11, 11],
        id: { id: "staffNumber" },
        filters: {
          message: values.under,
          message2: ` DEL PERIODO ${
            values.period
          } DEL AÑO ${values.year.getFullYear()}`,
          onlyMessage: true,
        },
        totals: [
          {
            header: "TOTAL NETO",
            headerColumn: -75,
            value: "net",
            valueColumn: -25,
          },
          {
            header: "TOTAL MONTO",
            headerColumn: -75,
            value: "amount",
            valueColumn: -25,
          },
        ],
        opTotals: [
          {
            header: "TOTAL TOTALES",
            headerColumn: -75,
            valueColumn: -25,
            op: ["+", "+"],
            values: [
              { val: 0, total: true },
              { val: 1, total: true },
              { val: 1, total: false },
            ],
          },
        ],
        content: [
          [{ header: "NUMERO DE EMPLEADO", value: "staffNumber", columns: 11 }],
          [{ header: "NOMBRE", value: "staffName", columns: 11 }],
          [
            { header: "ADSCRIPCION", value: "nameArea", columns: 11 },
            { header: "PUESTO LABORAL", value: "nameJob", columns: 11 },
          ],
          [{ header: "NIVEL - RANGO", value: "levelRange", columns: 11 }],
          [
            {
              header: "FECHA DE INICIO",
              value: "startDate",
              columns: 11,
              format: "fecha",
            },
          ],
          [
            {
              header: "FECHA DE TERMINO",
              value: "endDate",
              columns: 11,
              format: "fecha",
            },
          ],
          [{ header: "NETO", value: "net", columns: 11, format: "moneda" }],
          [
            {
              header: "IMPORTE",
              value: "amount",
              columns: 11,
              format: "moneda",
            },
          ],
        ],
      });
      setData({
        action: "report21",
        /* page: 1,
        records: 500, */
        search: "",
        order: "",
        period: reportConfig.period,
        year: reportConfig.year.getFullYear(),
        idUnder: reportConfig.idUnder,
      });
    } else if (+reportConfig.idReport === 22) {
      setTitle("REPORTE DE NOMINA GENERAL");
      setCabeceras([
        "NÚMERO EMPLEADO",
        "NOMBRE COMPLETO",
        "ADSCRIPCIÓN",
        "CONCEPTO RH",
      ]);
      setPdfConfig({
        headers: [
          "NÚMERO EMPLEADO",
          "NOMBRE COMPLETO",
          "ADSCRIPCIÓN",
          "CONCEPTO RH",
        ],
        content: [
          [
            { header: "NÚMERO EMPLEADO", value: "staffNumber" },
            { header: "NOMBRE COMPLETO", value: "name" },
            { header: "ADSCRIPCIÓN", value: "nameArea" },
            { header: "CONCEPTO RH", value: "payments" },
          ],
        ],
        period: `PERIODO ${
          reportConfig.period
        } DE ${reportConfig.year.getFullYear()}`,
      });
      setDescTipo(
        `PERIODO ${reportConfig.period} DE ${reportConfig.year.getFullYear()}`
      );
      setData({
        action: "report22",
        search: "",
        order: "",
        period: reportConfig.period,
        year: reportConfig.year.getFullYear(),
      });
    } else if (reportConfig.idReport === "28" || reportConfig.idReport === 28) {
      setTitle("REPORTE DE VACANTES");
      const period = `AL ${datefnsFmt(new Date(), "dd/MM/yyyy")}`;
      setDescTipo(period);
      setCabeceras([
        "ID",
        "NÚMERO DE PLAZA",
        "PUESTO LABORAL",
        "NIVEL/RANGO",
        "NOTAS",
        "ULTIMO EMPLEADO QUE OCUPO",
        "ULTIMA AREA ASIGNADA",
      ]);
      setPdfConfig({
        id: { id: "idJobPosition" },
        headers: [
          "NÚMERO DE PLAZA",
          "PUESTO LABORAL",
          "NIVEL/RANGO",
          "NOTAS",
          "ULTIMO EMPLEADO QUE OCUPO",
          "ULTIMA AREA ASIGNADA",
        ],
        content: [
          [
            { header: "NÚMERO DE PLAZA", value: "numberJobPositions" },
            { header: "PUESTO LABORAL", value: "nameJob" },
            { header: "NIVEL/RANGO", value: "levelrang" },
            { header: "NOTAS", value: "notes" },
            { header: "ULTIMO EMPLEADO QUE OCUPO", value: "lastEmployee" },
            { header: "ULTIMA AREA ASIGNADA", value: "lastArea" },
          ],
        ],
        period,
      });
      setData({
        action: "report28",
        search: "",
        order: "",
      });
    } else if (reportConfig.idReport === "36" || reportConfig.idReport === 36) {
      setTitle("Reporte de vencimiento de plazas");
      const period = `AL ${datefnsFmt(new Date(), "dd/MM/yyyy")}`;
      setDescTipo(period);
      setCabeceras([
        "NUMERO DE EMPLEADO",
        "NOMBRE",
        "AREA",
        "MOVIMIENTO",
        "FECHA DE CONCLUCION",
        "PUESTO",
        "CATEGORIA",
        "FECHA INICIO",
      ]);
      setPdfConfig({
        id: { id: "staffNumber" },
        filters: {
          message: `Del ${formatDate(reportConfig.initialDate)} al ${formatDate(
            reportConfig.finalDate
          )}`,
          onlyMessage: true,
        },
        headers: [
          "NUMERO DE EMPLEADO",
          "NOMBRE",
          "AREA",
          "MOVIMIENTO",
          "FECHA DE CONCLUCION",
          "PUESTO",
          "CATEGORIA",
          "FECHA INICIO",
        ],
        content: [
          [
            [
              {
                header: "NUMERO DE EMPLEADO",
                value: "staffNumber",
                columns: 11,
              },
            ],
            { header: "NUMERO DE EMPLEADO", value: "staffNumber", columns: 11 },
            { header: "NOMBRE", value: "nombreCompleto", columns: 11 },
            { header: "AREA", value: "areaName", columns: 11 },
            { header: "MOVIMIENTO", value: "nameMove" },
            {
              header: "FECHA DE CONCLUCION",
              value: "fechaConclusion",
              columns: 11,
            },
            { header: "PUESTO", value: "nameJob", columns: 11 },
            { header: "CATEGORIA", value: "rango", columns: 11 },
            { header: "FECHA INICIO", value: "startDate", columns: 11 },
          ],
        ],
        period,
      });
      setData({
        action: "report36",
        startDate: reportConfig.initialDate,
        endDate: reportConfig.finalDate,
        search: "",
        order: "",
      });
    } else if (reportConfig.idReport === "39" || reportConfig.idReport === 39) {
      setTitle("Verificacion de saldos negativos");

      setCabeceras([
        "NUMERO DE EMPLEADO",
        "NOMBRE",
        "AREA",
        "PUESTO",
        "TIPO DE PAGO",
        "BANCO",
        "IMPORTE",
      ]);
      setPdfConfig({
        id: { id: "staffNumber" },
        filters: {
          message: `Del periodo: ${
            reportConfig.period
          }/${reportConfig.year.getFullYear()}`,
          onlyMessage: true,
        },
        headers: [
          "NUMERO DE EMPLEADO",
          "NOMBRE",
          "AREA",
          "PUESTO",
          "TIPO DE PAGO",
          "BANCO",
          "IMPORTE",
        ],
        content: [
          [
            { header: "NOMBRE", value: "nombre", columns: 11 },
            { header: "AREA", value: "nameArea", columns: 11 },
            { header: "PUESTO", value: "nameJob" },
            { header: "TIPO DE PAGO", value: "namePaymentType", columns: 11 },
            { header: "PUESTO", value: "nameJob", columns: 11 },
            { header: "BANCO", value: "nameBank", columns: 11 },
            {
              header: "IMPORTE",
              value: "amount",
              columns: 11,
              format: "moneda",
            },
          ],
        ],
      });
      setData({
        action: "getNominaNegativos",
        period: reportConfig.period,
        year: reportConfig.year.getFullYear(),
        search: "",
        order: "",
      });
    } else if (reportConfig.idReport === "40" || reportConfig.idReport === 40) {
      setTitle("Verificacion de saldo al 30%");
      setCabeceras([
        "NUMERO DE EMPLEADO",
        "NOMBRE",
        "AREA",
        "PUESTO",
        "TIPO DE PAGO",
        "BANCO",
        "IMPORTE",
      ]);
      setPdfConfig({
        id: { id: "staffNumber" },
        filters: {
          message: `Del periodo: ${
            reportConfig.period
          }/${reportConfig.year.getFullYear()}`,
          onlyMessage: true,
        },
        headers: [
          "NUMERO DE EMPLEADO",
          "NOMBRE",
          "AREA",
          "PUESTO",
          "TIPO DE PAGO",
          "BANCO",
          "IMPORTE",
        ],
        content: [
          [
            { header: "NOMBRE", value: "nombre", columns: 11 },
            { header: "AREA", value: "nameArea", columns: 11 },
            { header: "PUESTO", value: "nameJob" },
            { header: "TIPO DE PAGO", value: "namePaymentType", columns: 11 },
            { header: "PUESTO", value: "nameJob", columns: 11 },
            { header: "BANCO", value: "nameBank", columns: 11 },
            {
              header: "IMPORTE",
              value: "amount",
              columns: 11,
              format: "moneda",
            },
          ],
        ],
      });
      setData({
        action: "getNominaImporteMinimo",
        period: reportConfig.period,
        year: reportConfig.year.getFullYear(),
        search: "",
        order: "",
      });
    } else if (reportConfig.idReport === "41" || reportConfig.idReport === 41) {
      setTitle("Historico de plazas");
      setCabeceras([
        "NUMERO DE EMPLEADO",
        "NOMBRE",
        "ESTATUS DE EMPLEADO",
        "AREA",
        "VIGENTE",
        "FECHA DE INICIO",
        "FECHA DE TERMINO",
      ]);
      setPdfConfig({
        id: { id: "staffNumber" },
        // filters: {
        //   message: `Del periodo: ${
        //     reportConfig.period
        //   }/${reportConfig.year.getFullYear()}`,
        //   onlyMessage: true,
        // },
        headers: [
          "NUMERO DE EMPLEADO",
          "NOMBRE",
          "ESTATUS DE EMPLEADO",
          "AREA",
          "VIGENTE",
          "FECHA DE INICIO",
          "FECHA DE TERMINO",
        ],
        content: [
          [
            { header: "NOMBRE", value: "nombreCompleto", columns: 11 },
            { header: "ESTATUS DE EMPLEADO", value: "nameStat" },
            { header: "AREA", value: "nameArea", columns: 11 },
            { header: "VIGENTE", value: "vigente", columns: 11 },
            { header: "FECHA DE INICIO", value: "startDate", columns: 11 },
            { header: "FECHA DE TERMINO", value: "endDate", columns: 11 },
          ],
        ],
      });
      setData({
        action: "report41",
        page: "0",
        records: "10",
        search: "",
        order: "",
        numberJobPositions: reportConfig.numberJobPositions,
      });
    } else if (reportConfig.idReport === "42" || reportConfig.idReport === 42) {
      setRoute("app/facades/reports/reportsFiTeF.php");
      setTitle("APORTACIONES ISSEMYM");
      setCabeceras([
        "Id",
        "TIPO DE REGISTRO",
        "CURP DEL SERVIDOR PUBLICO",
        "RFC DEL SERVIDOR PUBLICO",
        "CLAVE ISSEMYM",
        "APELLIDO PATERNO DEL SERVIDOR PUBLICO",
        "APELLIDO MATERNO DEL SERVIDOR PUBLICO",
        "NOMBRE (S) DEL SERVIDOR PUBLICO",
        "REGIMEN LABORAL",
        "CLAVE DE LA ENTIDAD RECEPTORA",
        "FECHA DE INGRESO A LA INSTITUCION PUBLICA",
        "FECHA INICIAL DE CONTRATACION",
        "FECHA FINAL DE CONTRATACION",
        "INDICADOR DEL SCI",
        "INDICADOR DE SUPLENCIA",
        "DIAS COTIZADOS EN LA QUINCENA",
        "SUELDO NETO SUJETO DE COTIZACION", //SUMATORIAS
        "SCI COUTAS OBLIGATORIAS",
        "SCI COUTAS VOLUNTARIAS",
        "SCI APORTACIONES OBLIGATORIAS",
        "SCI APORTACIONES VOLUNTARIAS",
        "SERVICIOS DE SALUD",
        "FONDO DEL SISTEMA SOLIDARIO DE REPARTO",
        "RETENCIONES INSTITUCIONALES",
        "RETENCIONES DE TERCEROS",
        "SERVICIOS DE SALUD",
        "FONDO DEL SISTEMA SOLIDARIO DE REPARTO",
        "APORTACIONES EXTRAORDINARIAS",
        "COUTAS EXTRAORDINARIAS",
        "GASTOS DE ADMINISTRACION",
        "PRIMA BASICA",
        "PRIMA DE SERVICIO",
        "PRIMA SINISESTRALIDAD",
        "PRIMA DE RIESGO NO CONTROLADO",
        "QUINCENA",
      ]);
      setPdfConfig({
        id: { id: "staffNumber" },
        filters: {
          message: `Del periodo: ${
            reportConfig.period
          }/${reportConfig.year.getFullYear()}`,
          onlyMessage: true,
        },
        headers: [
          "NUMERO DE EMPLEADO",
          "TIPO DE REGISTRO",
          "CURP DEL SERVIDOR PUBLICO",
          "RFC DEL SERVIDOR PUBLICO",
          "CLAVE ISSEMYM",
          "APELLIDO PATERNO DEL SERVIDOR PUBLICO",
          "APELLIDO MATERNO DEL SERVIDOR PUBLICO",
          "NOMBRE (S) DEL SERVIDOR PUBLICO",
          "REGIMEN LABORAL",
          "CLAVE DE LA ENTIDAD RECEPTORA",
          "FECHA DE INGRESO A LA INSTITUCION PUBLICA",
          "FECHA INICIAL DE CONTRATACION",
          "FECHA FINAL DE CONTRATACION",
          "INDICADOR DEL SCI",
          "INDICADOR DE SUPLENCIA",
          "DIAS COTIZADOS EN LA QUINCENA",
          "SUELDO NETO SUJETO DE COTIZACION", //SUMATORIAS
          "SCI COUTAS OBLIGATORIAS",
          "SCI COUTAS VOLUNTARIAS",
          "SCI APORTACIONES OBLIGATORIAS",
          "SCI APORTACIONES VOLUNTARIAS",
          "SERVICIOS DE SALUD",
          "FONDO DEL SISTEMA SOLIDARIO DE REPARTO",
          "RETENCIONES INSTITUCIONALES",
          "RETENCIONES DE TERCEROS",
          "SERVICIOS DE SALUD",
          "FONDO DEL SISTEMA SOLIDARIO DE REPARTO",
          "APORTACIONES EXTRAORDINARIAS",
          "COUTAS EXTRAORDINARIAS",
          "GASTOS DE ADMINISTRACION",
          "PRIMA BASICA",
          "PRIMA DE SERVICIO",
          "PRIMA SINISESTRALIDAD",
          "PRIMA DE RIESGO NO CONTROLADO",
          "QUINCENA",
        ],
        content: [
          [
            { header: "NUMERO DE EMPLEADO", value: "staffNumber" },
            { header: "TIPO DE REGISTRO", value: "TipoRegistro" },
            { header: "CURP DEL SERVIDOR PUBLICO", value: "curp" },
            { header: "RFC DEL SERVIDOR PUBLICO", value: "rfc" },
            { header: "CLAVE ISSEMYM", value: "claveIssemym" },
            { header: "APELLIDO PATERNO DEL SERVIDOR PUBLICO", value: "pName" },
            { header: "APELLIDO MATERNO DEL SERVIDOR PUBLICO", value: "mName" },
            { header: "NOMBRE (S) DEL SERVIDOR PUBLICO", value: "name" },
            { header: "REGIMEN LABORAL", value: "RegimenLaboral" },
            { header: "CLAVE DE LA ENTIDAD RECEPTORA", value: "cveEntidad" },
            {
              header: "FECHA DE INGRESO A LA INSTITUCION PUBLICA",
              value: "startJobDate",
            },
            { header: "FECHA INICIAL DE CONTRATACION", value: "iniContra" },
            { header: "FECHA FINAL DE CONTRATACION", value: "finContra" },
            { header: "INDICADOR DEL SCI", value: "sci" },
            { header: "INDICADOR DE SUPLENCIA", value: "suplencia" },
            { header: "DIAS COTIZADOS EN LA QUINCENA", value: "DCQ" },
            {
              header: "SUELDO NETO SUJETO DE COTIZACION",
              value: "sueldoNetoSujetoCotizacion",
            },
            { header: "SCI COUTAS OBLIGATORIAS", value: "SCI" },
            { header: "SCI COUTAS VOLUNTARIAS", value: "sciVoluntario" },
            { header: "SCI APORTACIONES OBLIGATORIAS", value: "AOSCI" },
            { header: "SCI APORTACIONES VOLUNTARIAS", value: "AVSCI" },
            { header: "SERVICIOS DE SALUD", value: "CSS" },
            { header: "FONDO DEL SISTEMA SOLIDARIO DE REPARTO", value: "CSSR" },
            { header: "RETENCIONES INSTITUCIONALES", value: "RI" },
            { header: "RETENCIONES DE TERCEROS", value: "RT" },
            { header: "SERVICIOS DE SALUD", value: "SS" },
            { header: "FONDO DEL SISTEMA SOLIDARIO DE REPARTO", value: "FSSR" },
            { header: "APORTACIONES EXTRAORDINARIAS", value: "AE" },
            { header: "COUTAS EXTRAORDINARIAS", value: "CE" },
            { header: "GASTOS DE ADMINISTRACION", value: "GA" },
            { header: "PRIMA BASICA", value: "PB" },
            { header: "PRIMA DE SERVICIO", value: "PSer" },
            { header: "PRIMA SINISESTRALIDAD", value: "PS" },
            { header: "PRIMA DE RIESGO NO CONTROLADO", value: "PRNC" },
            { header: "QUINCENA", value: "Quincena" },
          ],
        ],
      });
      if (reportConfig.idFormat === "3") {
        setData({
          action: "getCuotasyAportacionesISSEMYM",
          period: reportConfig.period,
          year: reportConfig.year.getFullYear(),
          records: 5,
          page: 1,
        });
      } else {
        setData({
          action: "getCuotasyAportacionesISSEMYM",
          period: reportConfig.period,
          year: reportConfig.year.getFullYear(),
          records: 5,
          page: 0,
        });
      }
    } else if (reportConfig.idReport === "44" || reportConfig.idReport === 44) {
      /* setRoute("app/facades/reports/reportsFiTeF.php") */
      setTitle("Empleados Bancos");

      if (reportConfig.idFormat === "2" || reportConfig.idFormat === 2) {
        setCabeceras([
          "NUMERO DE EMPLEADO",
          "NOMBRE COMPLETO",
          "CUENTA",
          "CLABE",
          "BANCO",
          "IDBANCO",
          "IMPORTE",
        ]);
      } else {
        setCabeceras([
          "Id",
          "NUMERO DE EMPLEADO",
          "NOMBRE COMPLETO",
          "CUENTA",
          "CLABE",
          "BANCO",
          "IDBANCO",
          "IMPORTE",
        ]);
      }

      setDescTipo(`Periodo: ${
        reportConfig.period
      }/${reportConfig.year.getFullYear()}      ${
        reportConfig.bank !== "" ? "Banco:" + reportConfig.bank : ""
      }           
      `);
      setPdfConfig({
        id: { id: "staffNumber" },
        filters: {
          message: `Periodo: ${
            reportConfig.period
          }/${reportConfig.year.getFullYear()}`,
          message2: reportConfig.bank ? `Banco: ${reportConfig.bank}` : "",
        },
        headers: [
          "NUMERO DE EMPLEADO",
          "NOMBRE COMPLETO",
          "CUENTA",
          "CLABE",
          "BANCO",
          "IDBANCO",
          "IMPORTE",
        ],

        content:
          reportConfig.idFormat === "2" || reportConfig.idFormat === 2
            ? [
                [
                  { header: "NOMBRE COMPLETO", value: "nombre" },
                  { header: "CUENTA", value: "account" },
                  { header: "CLABE", value: "switfCode" },
                  { header: "BANCO", value: "nameBank" },
                  { header: "IDBANCO", value: "keyBank" },
                  {
                    header: "IMPORTE",
                    value: "totalQuincena",
                    format: "moneda",
                  },
                ],
              ]
            : [
                [
                  { header: "Id", value: "staffNumber" },
                  { header: "NOMBRE COMPLETO", value: "nombre" },
                  { header: "CUENTA", value: "account" },
                  { header: "CLABE", value: "switfCode" },
                  { header: "BANCO", value: "nameBank" },
                  { header: "IDBANCO", value: "keyBank" },
                  {
                    header: "IMPORTE",
                    value: "totalQuincena",
                    format: "moneda",
                  },
                ],
              ],
      });
      if (reportConfig.idFormat === "3") {
        setData({
          action: "report42",
          period: reportConfig.period,
          year: reportConfig.year.getFullYear(),
          bank: reportConfig.keyBank ? reportConfig.keyBank : "",
          records: 5,
          page: 1,
        });
      } else {
        setData({
          action: "report42",
          period: reportConfig.period,
          year: reportConfig.year.getFullYear(),
          keyBank: reportConfig.keyBank ? reportConfig.keyBank : "",
        });
      }
    } else if (+reportConfig.idReport === 45) {
      setTitle("Reporte de Pensiones Bancos");
      setCabeceras([
        "Id",
        "NÚMERO EMPLEADO",
        "NOMBRE EMPLEADO",
        "ID PENSIÓN",
        "NOMBRE BENEFICIARIO",
        "CUENTA/CLABE",
        "ID BANCO",
        "BANCO",
        "IMPORTE",
      ]);
      setPdfConfig({
        cfg: {
          period: reportConfig.period,
          year: reportConfig.year.getFullYear(),
          bank: reportConfig.bank,
        },
        id: { id: "idPension" },
        content: [
          [
            { header: "NÚMERO EMPLEADO", value: "staffNumber" },
            { header: "NOMBRE EMPLEADO", value: "nombreEmpleado" },
            { header: "ID PENSIÓN", value: "idPension" },
            { header: "NOMBRE BENEFICIARIO", value: "nombreBeneficiario" },
            { header: "CUENTA/CLABE", value: "account" },
            { header: "ID BANCO", value: "keyBank" },
            { header: "BANCO", value: "nameBank" },
            { header: "IMPORTE", value: "amount" },
          ],
        ],
      });
      setData({
        action: "report43",
        period: reportConfig.period,
        year: reportConfig.year.getFullYear(),
        keyBank: reportConfig.keyBank,
      });
    } else if (+reportConfig.idReport === 47) {
      setTitle("DETALLES PENSIONES");
      setCabeceras([
        "Id",
        "NUMERO DE PENSION",
        "NUMERO DE EMPLEADO",
        "NOMBRE EMPLEADO",
        "PUESTO LABORAL",
        "NIVEL-RANGO",
        "NOMBRE BENEFICIARIO",
        "TIPO DE PENSION",
        "IMPORTE",
        "TIPO DE PAGO",
        "BANCO",
        "CUENTA",
        "ESPECIAL",
      ]);
      setPdfConfig({
        id: { id: "staffNumber" },
        headers: [
          "NUMERO DE PENSION",
          "NUMERO DE EMPLEADO",
          "NOMBRE EMPLEADO",
          "PUESTO LABORAL",
          "NIVEL-RANGO",
          "NOMBRE BENEFICIARIO",
          "TIPO DE PENSION",
          "UNIDAD DE MEDIDA",
          "IMPORTE",
          "TIPO DE PAGO",
          "BANCO",
          "CUENTA",
          "ESPECIAL",
        ],
        content: [
          [
            { header: "NUMERO DE PENSION", value: "numberPension" },
            { header: "NUMERO DE EMPLEADO", value: "staffNumber" },
            { header: "NOMBRE EMPLEADO", value: "nombreCompleto" },
            { header: "PUESTO LABORAL", value: "nameJob" },
            { header: "NIVEL-RANGO", value: "rango" },
            { header: "NOMBRE BENEFICIARIO", value: "beneficiario" },
            { header: "TIPO DE PENSION", value: "nameTypePension" },
            { header: "IMPORTE", value: "amount" },
            { header: "PORCENTAJE", value: "namePaymentType" },
            { header: "BANCO", value: "nameBank" },
            { header: "CUENTA", value: "account" },
          ],
        ],
      });
      if (+reportConfig.idFormat === 3) {
        setData({
          action: "report45",
          records: 5,
          page: 1,
        });
      } else {
        setData({
          action: "report45",
          records: 5,
          page: 0,
        });
      }
    } else if (+reportConfig.idReport === 46) {
      setTitle("Plantilla de Empleados por Unidad");
      setCabeceras([
        "Id",
        "NUMERO EMPLEADO",
        "NOMBRE COMPLETO",
        "ADSCRIPCION",
        "RANGO",
        "PUESTO LABORAL",
        "SEXO",
        "SUELDO BASE",
        "SUELDO BRUTO",
        "SUELDO NETO",
        "GRATIFICACION",
        "DESPENSA",
        "FORTALECIMIENTO",
        "COMPENSACION",
        "ISR",
        "ISSEMYM",
        "TIPO PAGO",
        "TIPO DE DEPOSITO",
        "BANCO",
        "CUANTA BANCARIA",
        "SINDICALIZADO",
        "FECHA INGRESO",
        "TIPO NOMBRAMIENTO",
        "FECHA INICIO NOMBRAMIENTO",
        "FECHA FIN NOMBRAMIENTO",
        "DIRECCION",
        "CURP",
        "RFC",
        "CLAVE ISSEMYM",
        "FOTOGRAFÍA",
      ]);
      setPdfConfig({
        cfg: {
          options: [
            reportConfig.nameArea,
            reportConfig.nameAreaComission,
            reportConfig.nameJob,
            reportConfig.nameJobRole,
          ],
          photo: +reportConfig.photo === 1,
        },
        id: { id: "staffNumber" },
        content: [
          [
            { header: "NUMERO EMPLEADO", value: "staffNumber" },
            { header: "NOMBRE COMPLETO", value: "nombre" },
            { header: "ADSCRIPCION", value: "nameArea" },
            { header: "RANGO", value: "NO" },
            { header: "PUESTO LABORAL", value: "nameJob" },
            { header: "SEXO", value: "nameGender" },
            { header: "SUELDO BASE", value: "NO" },
            { header: "SUELDO BRUTO", value: "NO" },
            { header: "SUELDO NETO", value: "NO" },
            { header: "GRATIFICACION", value: "NO" },
            { header: "DESPENSA", value: "NO" },
            { header: "FORTALECIMIENTO", value: "NO" },
            { header: "COMPENSACION", value: "NO" },
            { header: "ISR", value: "NO" },
            { header: "ISSEMYM", value: "NO" },
            { header: "TIPO PAGO", value: "namePaymentType" },
            { header: "TIPO DE DEPOSITO", value: "nameAccountType" },
            { header: "BANCO", value: "nameBank" },
            { header: "CUANTA BANCARIA", value: "account" },
            { header: "SINDICALIZADO", value: "unionized" },
            { header: "FECHA INGRESO", value: "startJobDate" },
            { header: "TIPO NOMBRAMIENTO", value: "nameAssignment" },
            { header: "FECHA INICIO NOMBRAMIENTO", value: "startDate" },
            { header: "FECHA FIN NOMBRAMIENTO", value: "endDate" },
            { header: "DIRECCION", value: "street" },
            { header: "CURP", value: "curp" },
            { header: "RFC", value: "rfc" },
            { header: "CLAVE ISSEMYM", value: "socialSecurityKey" },
            { header: "FOTOGRAFÍA", value: "foto" },
          ],
        ],
        hidde: [29],
      });
      setData({
        action: "report44",
        idArea: reportConfig.idArea, //(opcional)
        idAreaComission: reportConfig.idAreaComission, //(opcional)
        keyJob: reportConfig.keyJob, //(opcional)
        keyJobRole: reportConfig.keyJobRole, //(opcional)
      });
    } else if (+reportConfig.idReport === 59) {
      setTitle("Reporte por Proyecto Presupuestal");
      setCabeceras([
        "ID",
        "PROYECTO PRESUPUESTAL",
        "PARTIDA",
        "NOMBRE",
        "CLAVE NOMINAL",
        "TOTAL",
      ]);
      setPdfConfig({
        id: { id: "stateFundKey", show: false },
        content: [
          [
            /* { header: "NUMERO EMPLEADO", value: "staffNumber" }, */
            { header: "PROYECTO PRESUPUESTAL", value: "stateFundKey" },
            { header: "PARTIDA", value: "partida" },
            { header: "NOMBRE", value: "name" },
            { header: "CLAVE NOMINAL", value: "claveNominal" },
            { header: "TOTAL", value: "total" },
          ],
        ],
        headers: [
          "PROYECTO PRESUPUESTAL",
          "PARTIDA",
          "NOMBRE",
          "CLAVE NOMINAL",
          "TOTAL",
        ],
      });
      setData({
        action: "report59",
        search: "",
        order: "",
        period: reportConfig.period,
        year: reportConfig.year.getFullYear(),
      });
    } else if (+reportConfig.idReport === 60) {
      setTitle("Reporte por partida");
      setCabeceras(["ID", "PARTIDA", "NOMBRE", "CLAVE NOMINAL", "TOTAL"]);
      setPdfConfig({
        id: { id: "stateFundKey", show: false },
        content: [
          [
            /* { header: "NUMERO EMPLEADO", value: "staffNumber" }, */
            { header: "PARTIDA", value: "partida" },
            { header: "NOMBRE", value: "name" },
            { header: "CLAVE NOMINAL", value: "claveNominal" },
            { header: "TOTAL", value: "total" },
          ],
        ],
        headers: ["PARTIDA", "NOMBRE", "CLAVE NOMINAL", "TOTAL"],
      });
      setData({
        action: "report60",
        search: "",
        order: "",
        period: reportConfig.period,
        year: reportConfig.year.getFullYear(),
      });
    } else if (+reportConfig.idReport === 61) {
      setTitle("Nomina General Desglosado");
      setCabeceras(["NUMERO EMPLEADO", "NOMBRE COMPLETO", "NOMINA"]);
      setPdfConfig({
        id: { id: "idStaff", show: false },
        /* content: [
          [
            { header: "NUMERO EMPLEADO", value: "staffNumber" },
            { header: "NOMBRE COMPLETO", value: "name" },
            { header: "PUESTO LABORAL", value: "nameJob" },
            { header: "ADSCRIPCION", value: "nameArea" },
            { header: "SUELDO BASE", value: "SUELDO BASE" },
            { header: "GRATIFICACION", value: "GRATIFICACION BUROCRATA" },
            { header: "DESPENSA", value: "DESPENSA2" },
            { header: "COMPENSACION", value: "COMPENSACION" },
            { header: "FORTALECIMIENTO", value: "FORTALECIMIENTO AL SALARIO" },
            { header: "RET. SUELDO BASE", value: "RET. SUELDO BASE" },
            {
              header: "RET. GRATIFICACION",
              value: "RET. GRATIFICACION BUROCRATA",
            },
            { header: "RET. DESPENSA", value: "RET. DESPENSA" },
            { header: "RET. COMPENSACION", value: "RET. COMPENSACION" },
            {
              header: "RET. FORTALECIMIENTO AL SALARIO",
              value: "RET. FORTALECIMIENTO AL SALARIO",
            },
          ],
        ], */
        headers: ["NO EMPLEADO", "NOMBRE", "NOMINA"],
        filters: {
          period: reportConfig.period,
          year: reportConfig.year.getFullYear(),
          dates: getStartEndPeriod(reportConfig.period, reportConfig.year.getFullYear())
        }
      });
      setData({
        action: "report61",
        search: "",
        order: "",
        period: reportConfig.period,
        year: reportConfig.year.getFullYear(),
      });
      /* setData({
        action: "report61",
        order: "name desc",
        page: "0",
        period: 1,
        records: "5",
        search: "",
        year: 2023,
      }); */
    } else if (+reportConfig.idReport === 62) {
      setTitle("EMPLEADOS CON EL MINIMO VITAL");
      setCabeceras([
        "NUM EMPLEADO",
        "NOMBRE",
        "PUESTO",
        "UNIDAD DE TRABAJO",
        "FECHA INICIO",
        "FECHA TÉRMINO",
        "PORCENTAJE",
        "SUELDO TABULADOR",
        "SUELDO TABULADOR C/PORCENTAJE",
      ]);
      setPdfConfig({
        headers: ["NO.", "NO.EMPLEADO", "DETALLES"],
        id: { id: "staffNumber" },
        content: [
          [{ header: "NOMBRE", value: "fullName", columns: 2 }],
          [{ header: "PUESTO", value: "nameJob", columns: 2 }],
          [{ header: "UNIDAD DE TRABAJO", value: "name", columns: 3 }],
          [
            {
              header: "FECHA INICIO",
              value: "created",
              columns: 1,
              format: "fecha",
            },
            {
              header: "FECHA TÉRMINO",
              value: "modified",
              columns: 1,
              format: "fecha",
            },
          ],
          [
            { header: "PORCENTAJE", value: "minVital", columns: 1 },
            {
              header: "SUELDO TABULADOR",
              value: "net",
              columns: 1,
              format: "moneda",
            },
            {
              header: "SUELDO TABULADOR C/PORCENTAJE",
              value: "suPor",
              columns: 1,
              format: "moneda",
            },
          ],
        ],
      });
      setRoute("receiver/receiver.php");
      setData({
        action: "multiselect",
        table:
          "jobstaff A INNER JOIN staff B USING (idStaff) INNER JOIN areas C USING(idArea) INNER JOIN jobs D USING(keyJob) INNER JOIN salaries E USING(idSalary)",
        rows: "B.staffNumber, CONCAT_WS(' ',B.name,B.pName,B.mName) as fullName, D.nameJob, C.name, A.created, A.modified, CONCAT(A.minVital,'%') AS minVital, E.net,  E.net * (A.minVital/100) AS suPor",
        conditions: "A.valid = 1 AND A.enabled = 1 AND A.keyMove = 18",
        page: 0,
      });
    } else if (+reportConfig.idReport === 65) {
      setTitle("TABULADORES HISTÓRICO");
      setCabeceras(["A"]);
      setPdfConfig({
        content: [],
        headers: [
          "INCREMENTO SALARIAL",
          "AÑO APLICACIÓN",
          "FECHA APLICACIÓN",
          "PORCENTAJE BASE ",
          "PORCENTAJE RECOMPENSA",
          "PORCENTAJE DESPENSA",
          "PORCENTAJE LIQUIDACIÓN",
          "PORCENTAJE FORTALECIMIENTO",
          "PORCENTAJE FORTALECIMIENTO",
          "AFECTADOS",
        ],
      });
      setData({
        action: "getHistoricoSalarios",
        // search: "",
        // order: "",
        // page: "0",
        // records: "5"
      });
    }      
  };

  const config1017 = (fields) => {
    let params = {
      action: "report" + fields.idReport,
      search: "",
      order: "",
    };
    let config = {
      headers: ["No", "NUM EMPLEADO", "REPORTE GENERAL DE EMPLEADO"],
      id: { id: "staffNumber" },
      content: configuracion[+fields.idReport - 10],
      filters: {}
    };
    let lcTitle = titulos[+fields.idReport - 10];
    let lcCabeceras = cabecerasr1017[+fields.idReport - 10];
    if (+fields.idReport === 16) {
      config.headers = ["No", "TOTAL DE PLAZAS", "REPORTE GENERAL DE PLAZAS"];
      config.id = { id: "total" };
    } else if (+fields.idReport === 17) {
      delete params.page;
      delete params.records;
      config.headers = [
        "CLAVE",
        "CLAVE PRESUP.",
        "LEYENDA",
        "OCURRENCIAS",
        "IMPORTE",
      ];
      config.id = { id: "under" };
      if (+fields.idFormat === 3) {
        config.headers.splice(1, 0, "TIPO");
        config.content[0].push({ header: "TIPO", value: "nameTypeUnder" });
        lcCabeceras = config.headers;
      }
    } else if([63, 66].includes(+fields.idReport)){
      config = {
        headers: ["No", "NUM EMPLEADO", "REPORTE GENERAL DE EMPLEADO"],
        filters: {},
        id: { id: "staffNumber" },
        content: [],
        // byStaff: !!values.nameStaff,
      }
      if(+fields.idReport === 63){
        lcTitle = "PERCEPCIONES Y DEDUCCIONES ADICIONALES";
        config.content = [
          [
            { header: "CVE DESCUENTO", value: "under", columns: 1 },
            { header: "CONCEPTO", value: "nameUnder", columns: 1 },
            { header: "FOLIO", value: "folio", columns: 1 },
            { header: "EMPLEADO", value: "staffNumber", columns: 1, format: (row)=> `${row.staffNumber} - ${row.nombre}` },
            { header: "FECHA DE INICIO", value: "fechaInicio", columns: 1 },
            { header: "FECHA FIN", value: "fechaFin", columns: 1 },
            { header: "IMPORTE INICIAL", value: "importeInicial", columns: 1, format: "moneda" },
            { header: "DESCUENTO FIJO", value: "descuentoFijo", columns: 1, format: "moneda" },
            { header: "IMPORTE RETENIDO", value: "importeRetenido", columns: 1, format: "moneda" },
            { header: "SALDO", value: "saldo", columns: 1, format: "moneda" },
          ]
        ];
        lcCabeceras = [
          "CVE DESCUENTO",
          "CONCEPTO",
          "FOLIO",
          "EMPLEADO",
          "FECHA DE INICIO",
          "FECHA FIN",
          "IMPORTE INICIAL",
          "DESCUENTO FIJO",
          "IMPORTE RETENIDO",
          "SALDO",
        ];
      }else if(66){
        lcTitle = "MULTAS / SANCIONES";
        config.content = [
          [
            { header: "NOMBRE COMPLETO", value: "nameStaff", columns: 3 },
          ],
          [
            { header: "ADSCRIPCIÓN", value: "nameArea", columns: 3 },
          ],
          [
            { header: "ESTATUS", value: "nameStat", columns: 2 },
            { header: "TIPO SANCIÓN", value: "namePenalty", columns: 2 },
          ],
          [
            { header: "CLAVE", value: "under", columns: 1 },
            { header: "CONCEPTO", value: "nameUnder", columns: 2 },
          ],
          [
            { header: "OFICIO", value: "policy", columns: 1 },
            { header: "CAUSA", value: "expediente", columns: 2 },
            { header: "UMAS", value: "umas", columns: 1 },
          ],
          [
            { header: "MONTO TOTAL", value: "montoTotal", columns: 2, format: "moneda" },
            { header: "MONTO QUINCENAL", value: "montoQuincenal", columns: 2, format: "moneda" },
          ],
          [
            { header: "PERIODO INICIO", value: "startPeriod", columns: 2 },
            { header: "AÑO INICIO", value: "startYear", columns: 2 },
            { header: "PERIODO TERMINO", value: "endPeriod", columns: 2 },
            { header: "AÑO TERMINO", value: "endYear", columns: 2 },
          ],
          [
            { header: "PARCIALIDADES", value: "totalPagos", columns: 2 },
            { header: "PAGOS APLICADOS", value: "pagosRealizados", columns: 2 },
          ],
        ];
        lcCabeceras = [
          "NUM EMPLEADO",
          "NOMBRE COMPLETO",
          "ADSCRIPCIÓN",
          "OFICIO",
          "TIPO SANCIÓN",
          "CLAVE",
          "CONCEPTO",
          "UMAS",
          "CAUSA",
          "MONTO TOTAL",
          "MONTO QUINCENAL",
          "PERIODO INICIO",
          "AÑO INICIO",
          "PERIODO TERMINO",
          "AÑO TERMINO",
          "PARCIALIDADES",
          "PAGOS APLICADOS",
          "ESTATUS",
        ];
      }
      params = {
        action: +fields.idReport === 63 ? "reportDeduccionesDetalleEmpleado" : "report64",
        search: "",
        order: ""
      }
    }

    config.filters = { message: "", onlyMessage: true };
    let filterMsg = "";
    if (fields.nameStaff) {
      params.idStaff = fields.idStaff;
      filterMsg = "Empleado: " + fields.nameStaff.split(" - ")[1];
    }
    if (fields.initialDate) {
      params.initialDate = formatDate(fields.initialDate);
      params.finalDate = formatDate(fields.finalDate);
      filterMsg +=`${filterMsg ? "\n": "" }Del: ${formatDate(fields.initialDate)} al ${formatDate(fields.finalDate)}`;
    }
    if (fields.period) {
      params.period = fields.period;
      params.year = fields.year.getFullYear();
      filterMsg += `${filterMsg ? "\n": "" }Periodo: ${fields.period}/${fields.year.getFullYear()}`;
    }
    if ([63, 66].includes(+fields.idReport) && fields.nameUnder) {
      params.unders = fields.idUnder;
      filterMsg += `${filterMsg ? "\n": "" }Concepto: ${values.nameUnder}`;
    }
    setTitle(lcTitle);
    setCabeceras(lcCabeceras);

    config.filters.message = filterMsg;
    setDescTipo(filterMsg);
    setPdfConfig(config);
    setData(params);
  };

  function formatDate(date) {
    return date.split("-").reverse().toString().replaceAll(",", "/");
  }

  const {
    handleSubmit,
    handleBlur,
    values,
    errors,
    touched,
    setFieldValue,
    resetForm,
  } = useFormik({
    initialValues: defaultForm,
    onSubmit: (values) => {
      getData(values);
    },
    validationSchema: FormSchema,
    enableReinitialize: true,
  });

  /* Consulta para traer los datos para llenar el pdf o excel,  */
  const getReport = (params) => {
    setLoader(true);
    let route = "app/facades/reports/reportsF.php";

    if (values.idReport === "42" || values.idReport === 42) {
      route = "/app/facades/reports/reportsFiTeF.php";
    }
    if (+values.idReport === 62) route = "receiver/receiver.php";

    API.peticionEndPoint(params, route)
      .then((res) => {
        if (res.status === 200 && res.data.code === "200") {
          setReport(res.data.data);
        } else {
          toast(
            <Notification
              type={"consultar_error"}
              backMessage={res.data.message}
              withIcon
            />,
            { closeButton: false }
          );
        }
      })
      .finally(() => {
        setLoader(false);
        setModalReports(true);
      })
      .catch((err) => {
        toast(<Notification type={"consultar_servidor_error"} withIcon />, {
          closeButton: false,
        });
      });
  };

  useEffect(() => {
    if (data !== "") {
      /* Si no es html trae los datos para pdf o excel */
      if (values.idFormat.toString() !== "3") {
        //AQUI DIFERENTE DE 3 POR QUE LA OPCION 3 ES EL DATATABLE

        // DATA es como se forma la peticion para el getReport
        getReport(data);
      } else if ([9, 20].includes(+values.idReport)) {
        getReport(data);
      }
      /* show report format  es la que permite en la vista si se ve html , pdf o excel */
      setShowReportFormat(values.idFormat.toString());
    }
  }, [data]);

  /* Se usa para llamar al excel */
  useEffect(() => {
    if (report !== undefined && showReportFormat === "2") {
      setShowReportFormat("0");
      if (values.idReport === "7") {
        saveReport7AsExcel(report, cabeceras, title);
      } else if (values.idReport === "8") {
        saveReport8AsExcel(report, cabeceras, title);
      } else if (values.idReport === "9") {
        saveReport9AsExcel(report, title, [
          values.period,
          values.year.getFullYear(),
        ]);
      } else if (values.idReport === "17") {
        saveReport17AsExcel(
          report,
          cabeceras,
          title,
          values.period + "/" + values.year.getFullYear(),
          pdfConfig
        );
      } else if (values.idReport === "20") {
        resumenGlobalNomina(report, pdfConfig);
      } else if (values.idReport === "21") {
        /* saveAsExcel(report, cabeceras, title, pdfConfig, descTipo); */
        saveAsExcelTotal(report, cabeceras, title, pdfConfig);
      } else if (values.idReport === "22") {
        NominaGeneral(report, cabeceras, title, descTipo, pdfConfig);
      } else if (values.idReport === "41") {
        generateExcelHistoricoPlazas(
          report,
          cabeceras,
          title,
          pdfConfig,
          descTipo
        );
      } else if (values.idReport === "42") {
        generateExcelAportacionesIssemym(
          report,
          cabeceras,
          title,
          pdfConfig,
          descTipo
        );
      } else if (values.idReport === "45") {
        pensionesBancos(report, pdfConfig);
      } else if (values.idReport === "46") {
        empleadosUnidad(report, pdfConfig);
      } else if (values.idReport === "47") {
        detallesPensionesExcel(report, cabeceras, title, pdfConfig, descTipo);
      } else if (values.idReport === "61") {
        nominaDesglosadoExcel(report, cabeceras, title, data, pdfConfig);
      } else if (values.idReport === "63") {
        deduccionesAdicionalesExcel(report, pdfConfig);
      } else if (+values.idReport === 65) {
        tabuladoresHistoricoExcel(report);
      } else {
        saveAsExcel(report, cabeceras, title, pdfConfig, descTipo.replaceAll("\n", " - "));
      }
    }
  }, [report]);

  /* Funcion que se envia al modal al seleccionar un empleado*/
  const selectEmpleado = (el) => {
    setFieldValue("idStaff", el[0]);
    setFieldValue("nameStaff", `${el[1]} - ${el[2]}`);
    setModalTablaStaff(false);
  };
  const selectJob = (el) => {
    setFieldValue("numberJobPositions", el[0]);
    setFieldValue("jobPostition", `${el[1]}`);
    setModalTablaJobs(false);
  };
  const selectBank = (el) => {
    setFieldValue("keyBank", el[0]);
    setFieldValue("bank", `${el[1]}`);
    setModalTablaBanks(false);
  };

  // const getSpecialColumns = (idReport) => {
  //   switch (idReport) {
  //     case "46":
  //       return [showPicture];
  //     default:
  //       return [];
  //   }
  // };

  /*  Función obtener pdf a generar */
  function getPDF(){
    switch(+values.idReport){
      case 7: return {
        method: generatePdfPercepcionesDeducciones,
        type: "percepcionesDeducciones",
      }
      case 8 : return{
        method: generatePdfAcumulado,
        type: "acumuladoAnual",
      }
      case 9: return {
        method: generatePdfComprobantePago,
        type: "comprobantePago",
      }
      case 17: return {
        method: generatePdfGlobalPercepciones,
        type: "percepcionesGlobales",
      }
      case 20: return {
        method: pdfResumenGlobalNomina,
        type: "resumenGlobalNomina",
      }
      case 21: return {
        method: generatePdfConceptoNomina,
        type: "conceptoNomina",
      }
      case 22: return {
        method: PdfNominaGneral,
        type: "nominaGeneral",
      }
      case 28: return {
        method: generatePDFVacantes,
        type: "vacantes",
      }
      case 36: return {
        method: generatePdfVencimientoPlazas,
        type: "vencimientoPlazas",
      }
      case 39: return {
        method: generatePdfSaldosNegativos,
        type: "saldosNegativos",
      }
      case 40: return {
        method: generatePdfSaldosMenores30,
        type: "saldosmenores30",
      }
      case 41: return {
        method: generatePdfHistoricoPlazas,
        type: "historicoPlazas",
      }
      case 42: return {
        method: generatePdfCoutasAportacionesIssemym,
        type: "coutasAportacionesIssemym",
      }
      case 44: return {
        method: generatePdfEmpleadosBancos,
        type: "empleadosBancos",
      }
      case 45: return {
        method: PdfPensionesBancos,
        type: "pensionesBancos",
      }
      case 46: return {
        method: PdfEmpleadosUnidad,
        type: "plantillaempleadosUnidad",
      }
      case 47: return {
        method: generatePdfDetallePensiones,
        type: "detallesPensiones",
      }
      case 59: return {
        method: generatePdfReporteProyectoPresupuestales,
        type: "ReportePRoyectoPresupuestal",
      }
      case 60: return {
        method: generatePdfReportePartida,
        type: "reportePartida",
      }
      case 61: return {
        method: generatePdfNominaDesglosadoGen,
        type: "NominaDesglosado",
      }
      case 62: return {
        method: MinimoVital,
        type: "MinimoVital",
      }
      case 63: return {
        method: generatePdfDeduccionesAdicionales,
        type: "deduccionesAdicionales",
      }
      case 65: return {
        method: TabuladorHistoricoPDF,
        type: "tabuladorhistorico",
      }
      default: return {
        method: generatePdfGeneral,
        type: "general",
      }
    }
  }

  return (
    <>
      <Widget className="widget-p-md">
        <form onSubmit={handleSubmit} className="mt-4" noValidate>
          <div className="headline-1 text-center mb-4">Reportes Nómina</div>
          <Row className="d-flex justify-content-center">
            <Col xs={12} md={12} lg={5}>
              <SelectWithSearchInput
                label="Seleccione el reporte a generar"
                inputOptions={typeReports}
                inputName="idReport"
                onChangeMethod={(e)=>onChange({target: {name: "idReport", value: e.value}})}
                onBlurMethod={handleBlur}
                touched={touched.idReport}
                errors={errors.idReport}
                optionValue="idReport"
                optionName="nameReport"
                inputValue={values.idReport}
                isRequired
              />
            </Col>
            <Col xs={12} md={12} lg={5}>
              <SelectWithSearchInput
                label="Seleccione un formato"
                inputOptions={format}
                inputName="idFormat"
                onChangeMethod={(e)=>onChange({target: {name: "idFormat", value: e.value}})}
                onBlurMethod={handleBlur}
                touched={touched.idFormat}
                errors={errors.idFormat}
                optionValue="id"
                optionName="value"
                inputValue={values.idFormat}
                /* isDisabled={data ? true : false} */
                isRequired
              />
            </Col>
          </Row>

          <Filtro1015
            onChange={onChange}
            handleBlur={handleBlur}
            values={values}
            touched={touched}
            errors={errors}
            setModalTabla={setModalTablaStaff}
            setModalTablaJobs={setModalTablaJobs}
            setModalTablaBanks={setModalTablaBanks}
            setFieldValue={setFieldValue}
            report={values.idReport}
            resetForm={resetForm}
            setShowReportFormat={setShowReportFormat}
            setModalTabGeneral={setModalTabGeneral}
            setModalTabGParams={setModalTabGParams}
            setModalTabGCabeceras={setModalTabGCabeceras}
            setFieldToShow={setFieldToShow}
            setModalTabGTitle={setModalTabGTitle}
          />

          <div className="text-center my-3">
            <Button color="success" type="submit">Generar</Button>
          </div>
        </form>

        <FullFormLoader
          show={loader}
          message="Consultando datos , espere un momento"
        />

        {
          /* Muestra la tabla  en caso de que formato sea 3  */
          showReportFormat === "3" && title !== "" ? (
            +values.idReport === 20 ? (
              <Reporte20Web data={report} cfg={pdfConfig} />
            ) : +values.idReport === 65 ? (
              <TabuladoresHistorico />
            ) : +values.idReport === 9 ? (
              <ComprobantePagoRepHTML data={report} cfg={pdfConfig} />
            ) : +values.idReport === 47 ? (
              <Report47 />
            ) : +values.idReport === 61 ? (
                  <NominaDesglosado
                    period={values.period}
                    year={values.year.getFullYear()}
                  />
            ) : (
              <TableComponentReport
                titulo={title}
                cabecerasTabla={cabeceras}
                parametros={data}
                filtro={pdfConfig}
                reporte={values.idReport}
                route={route}
              />
            )
          ) : // Muestra pdf en caso de que el tipo sea  1
          showReportFormat === "1" && report !== undefined && (
            <div>
              <ModalReports
                modal={modalReports}
                  setModal={setModalReports}
                  title={title}
                  cabeceras={cabecerasModalTabla}
                  backdrop={"static"}
                  keyboard={false}
                  report={report}
                  pdfConfig={pdfConfig}
                  generatePdfMethod={getPDF()}
                  records={1000}
              />
            </div>
          )
        }
      </Widget>

      <ModalTableVer
        modal={modalTabGeneral}
        setModal={setModalTabGeneral}
        title={modalTabGTitle}
        cabeceras={modalTabGCabeceras}
        filtro={filtroModalTabla} //modalTabGFiltro
        parametros={modalTabGParams}
        selectRegistro={(re) => {
          const second = fieldToShow[1] === "nameUnder" ? 2 : 1;
          setFieldValue(fieldToShow[0], re[0]);
          setFieldValue(fieldToShow[1], re[second]);
          setModalTabGeneral(false);
        }}
      />

      <ModalTableVer
        modal={modalTablaStaff}
        setModal={setModalTablaStaff}
        title="Empleados"
        cabeceras={cabecerasModalTabla}
        filtro={filtroModalTabla}
        parametros={paramsModalTabla}
        selectRegistro={selectEmpleado}
        backdrop={"static"}
        keyboard={[10, 11, 12, 13, 14, 15, 16, 17, 63].includes(+values.idReport)}
      />
      <ModalTableVer
        modal={modalTablaJobs}
        setModal={setModalTablaJobs}
        title="Puestos"
        cabeceras={cabecerasModalTablaJobs}
        filtro={[true, true]}
        parametros={paramsModalTablaJobs}
        selectRegistro={selectJob}
        backdrop={"static"}
        keyboard={false}
      />
      <ModalTableVer
        modal={modalTablaBanks}
        setModal={setModalTablaBanks}
        title="Bancos"
        cabeceras={cabecerasModalTablaBanks}
        filtro={[true, true]}
        parametros={paramsModalTablaBanks}
        selectRegistro={selectBank}
        backdrop={"static"}
        keyboard={false}
      />
    </>
  );
};
export default ReportesNomina;

const defaultForm = {
  idFormat: "",
  idReport: "",
  idMonth: "",
  idUnder: "",
  idStaff: "",
  nameStaff: "",
  under: "",
  initialDate: "",
  finalDate: "",
  period: "",
  endPeriod: "",
  year: "",
  numberJobPositions: "",
  jobPostition: "",
  keyBank: "",
  bank: "",
  idArea: "",
  nameArea: "",
  idAreaComission: "",
  nameAreaComission: "",
  keyJob: "",
  nameJob: "",
  keyJobRole: "",
  nameJobRole: "",
  photo: "0",
  unders: [],
  nameUnder: "",
  idUnder: "",
}

const FormSchema = Yup.object().shape({
  idFormat: Yup.string().required("Seleccione un formato"),
  idReport: Yup.string().required("Seleccione un tipo de reporte"),
  idUnder: Yup.string().when("idReport", {
    is: (val) => [21].includes(+val),
    then: Yup.string().required("Seleccione un tipo nomina"),
  }),
  idMonth: Yup.string().when("idReport", {
    is: "3",
    then: Yup.string().required("Ingrese un mes"),
  }),
  nameStaff: Yup.string()
    .when("idReport", {
      is: (val) => [10, 11, 12, 13, 14, 15, 63, 66].includes(+val),
      then: Yup.string().when(
        ["initialDate", "period", "nameUnder"],
        (initialDate, period, nameUnder, schema) => {
          return !initialDate && !period && !nameUnder
            ? schema.required("Seleccione un empleado")
            : schema;
        }
      ),
    })
    .when("idReport", {
      is: (val) => [7, 8, 9].includes(+val),
      then: Yup.string().required("Seleccione un empleado"),
    }),
  initialDate: Yup.date().when("idReport", {
    is: (val) => [10, 11, 12, 13, 14, 15, 36, 63, 66].includes(+val),
    then: Yup.date().when(
      ["nameStaff", "period", "nameUnder"],
      (nameStaff, period, nameUnder, schema) => {
        return !nameStaff && !period && !nameUnder
          ? schema.required("Seleccione una fecha de inicio")
          : schema;
      }
    ),
  }),
  finalDate: Yup.date().when("idReport", {
    is: (val) => [10, 11, 12, 13, 14, 15, 36, 63, 66].includes(+val),
    then: Yup.date().when(
      ["nameStaff", "period", "initialDate", "nameUnder"],
      (nameStaff, period, initialDate, nameUnder, schema) => {
        return (!nameStaff && !period && !nameUnder) || !!initialDate
          ? schema
              .required("Seleccione una fecha limite")
              .min(Yup.ref("initialDate"),"La fecha debe ser posterior a la fecha de inicio")
          : schema;
      }
    ),
  }),
  period: Yup.number()
    .max(24, "Periodo debe ser menor a 25")
    .min(1, "Periodo debe ser mayor a 0")
    .when("idReport", {
      is: (val) => [10, 11, 12, 13, 14, 15, 44].includes(+val),
      then: Yup.number().when(
        ["nameStaff", "initialDate"],
        (nameStaff, initialDate, schema) => {
          return !nameStaff && !initialDate
            ? schema.required("Ingrese un periodo")
            : schema;
        }
      ),
    })
    .when("idReport", {
      is: (val) => [7, 9, 17, 20, 21, 22, 45].includes(+val),
      then: Yup.number().required("Ingrese un periodo"),
    })
    .when("idReport", {
      is: (val) => [63, 66].includes(+val),
      then: Yup.number().when(
        ["nameStaff", "nameUnder", "initialDate"],
        (nameStaff, nameUnder, initialDate, schema) => {
          return !nameStaff && !nameUnder && !initialDate ? schema.required("Seleccione un periodo") : schema;
        }
      ),
    }),
  year: Yup.string().nullable()
    .when("idReport", {
      is: (val) => [10, 11, 12, 13, 14, 15, 44, 63, 66].includes(+val),
      then: Yup.string().when(
        ["nameStaff", "initialDate", "nameUnder", "period"],
        (nameStaff, initialDate, nameUnder, period, schema) => {
          return (!nameStaff && !initialDate && !nameUnder) || !!period ? schema.required("Seleccione un año") : schema;
        }
      ).nullable(),
    })
    .when("idReport", {
      is: (val) => [7, 8, 9, 17, 20, 21, 22, 45, 61].includes(+val),
      then: Yup.string().required("Ingrese un año").nullable(),
    }),
  jobPostition: Yup.string().when("idReport", {
    is: "41",
    then: Yup.string().required("Seleccione un puesto"),
  }),
  /* REPORT 46*/
  nameArea: Yup.string().when("idReport", {
    is: "46",
    then: Yup.string().when(
      ["nameAreaComission", "nameJob", "nameJobRole"],
      (nameAreaComission, nameJob, nameJobRole, schema) => {
        return nameAreaComission === undefined &&
          nameJob === undefined &&
          nameJobRole === undefined
          ? schema.required("Seleccione una Unidad de trabajo o")
          : schema;
      }
    ),
  }),
  nameAreaComission: Yup.string().when("idReport", {
    is: "46",
    then: Yup.string().when(
      ["nameArea", "nameJob", "nameJobRole"],
      (nameArea, nameJob, nameJobRole, schema) => {
        return nameArea === undefined &&
          nameJob === undefined &&
          nameJobRole === undefined
          ? schema.required("Seleccione una Unidad de trabajo de comisión o")
          : schema;
      }
    ),
  }),
  nameJob: Yup.string().when("idReport", {
    is: "46",
    then: Yup.string().when(
      ["nameAreaComission", "nameArea", "nameJobRole"],
      (nameAreaComission, nameArea, nameJobRole, schema) => {
        return nameAreaComission === undefined &&
          nameArea === undefined &&
          nameJobRole === undefined
          ? schema.required("Seleccione un puesto o")
          : schema;
      }
    ),
  }),
  nameJobRole: Yup.string().when("idReport", {
    is: "46",
    then: Yup.string().when(
      ["nameAreaComission", "nameArea", "nameJob"],
      (nameAreaComission, nameArea, nameJob, schema) => {
        return nameAreaComission === undefined &&
          nameArea === undefined &&
          nameJob === undefined
          ? schema.required("Seleccione un puesto de comisión")
          : schema;
      }
    ),
  }),
  /* END REPORT 46*/
  nameUnder: Yup.string().when("idReport", {
    is: (val) => [63, 66].includes(+val),
    then: Yup.string().when(["nameStaff", "period", "initialDate"], (nameStaff, period, initialDate, schema) => 
        !nameStaff && !period && !initialDate ? schema.required("Seleccione un concepto") : schema
    ),
  }),
});

/* CABECERAS REPORTES 10-17 */
const cabecerasr1017 = [
  [
    "NUMERO EMPLEADO",
    "NOMBRE COMPLETO",
    "ESTATUS EMPLEADO",
    "ADSCRIPCION",
    "FOLIO INCAPACIDAD",
    "FECHA INICIO",
    "FECHA FINAL",
    "NO. DIA INCAPACIDAD",
    "ESTATUS INCAPACIDAD",
    "DIAGNOSTICO",
    "TIPO SUELDO",
  ], //R10
  [
    "NUMERO EMPLEADO",
    "NOMBRE COMPLETO",
    "ADSCRIPCION",
    "ESTATUS EMPLEADO",
    "OFICIO LICENCIA",
    "TIPO DE LICENCIA",
    "OBSERVACIONES",
    "FECHA INICIO",
    "FECHA FINAL",
    "NO. DIAS LICENCIA",
    "ESTATUS LICENCIA",
    "DESGLOSE",
  ], //R11
  [
    "NUMERO EMPLEADO",
    "NOMBRE COMPLETO",
    "ADSCRIPCION",
    "TIPO DE PRESTAMO",
    "INSTITUCION",
    "POLIZA",
    "FECHA INICIO",
    "FECHA FINAL",
    "IMPORTE QUINCENAL",
    "IMPORTE TOTAL",
    "NO. DE PARCIALIDADES",
    "NO. DE PAGOS REALIZADOS",
    "CONCEPTO",
    "DESCRIPCION",
    "ESTATUS PRESTAMO",
    "OBSERVACIONES",
  ], //R12
  [
    "NUMERO EMPLEADO",
    "NOMBRE COMPLETO",
    "ADSCRIPCION",
    "TIPO DE SEGURO",
    "CONCEPTO",
    "DESCRIPCION CONCEPTO",
    "INSTITUCION",
    "POLIZA",
    "FECHA INICIO",
    "IMPORTE QUINCENAL",
    "NO. DE PAGOS REALIZADOS",
    "ESTATUS SEGURO",
    "OBSERVACIONES",
  ], //R13
  [
    "NO EMPLEADO",
    "NOMBRE COMPLETO",
    "ADSCRIPCION",
    "RANGO",
    "NO. OFICIO",
    "BENEFICIARIO",
    "NO DE CUENTA",
    "TIPO CUENTA",
    "BANCO",
    "TIPO DE PAGO",
    "INICIO PENSION",
    "FIN PENSION",
    "TIPO DE PENSION",
    "CANTIDAD",
    "UNIDAD",
  ], //R14
  [
    "NUMERO EMPLEADO",
    "NOMBRE COMPLETO",
    "ADSCRIPCION",
    "CONCEPTO RH",
    "ESTATUS ESTANCIA",
    "OFICIO",
    "FECHA INICIO",
    "FECHA FINAL",
    "IMPORTE TOTAL",
    "IMPORTE QUINCENAL",
    "NO. DE PARCIALIDADES",
    "NO. DE PAGOS REALIZADOS",
    "NO. DE INFANTES",
    "OBSERVACIONES",
  ], //R15
  [
    "TOTAL PLAZAS",
    "PUESTO LABORAL",
    "RANGO",
    "SUELDO BASE",
    "SUELDO BRUTO",
    "SUELDO NETO",
    "GRATIFICACION",
    "DESPENSA",
    "COMPENSACION",
    "ISR",
    "ISSEMYM",
  ], //R16
  ["CLAVE", "CLAVE PRESUP.", "LEYENDA", "OCURRENCIAS", "IMPORTE"], //R17
];

/* CABECERA REPORTES 7-9 */
const cabeceraGeneral = [
  "NUM. EMPLEADO",
  "NOMBRE",
  "CONCEPTO",
  "DESCRIPCIÓN CONCEPTO",
  "TIPO CONCEPTO",
  "PERIODO",
  "AÑO",
  "ESTADO",
  "MONTO",
  "NO. CUENTA",
];

/* TITULOS REPORTES 10-17 */
const titulos = [
  "Reporte de incapacidades",
  "Reporte de licencias",
  "Reporte de prestamos",
  "Reporte de seguros",
  "Reporte de pensiones",
  "Reporte de estancias",
  "Tabulador de salarios",
  "Cifras por Partida/C. Contable",
];

/* CONFIGURACION PDF/HTML 10-17 */
const configuracion = [
  [
    [
      { header: "NOMBRE COMPLETO", value: "nameStaff", columns: 2 }, 
      { header: "ESTATUS EMPLEADO", value: "statusStaff", columns: 1 },
    ],
    [
      { header: "ADSCRIPCION", value: "nameArea", columns: 3 },
    ],
    [
      { header: "FOLIO INCAPACIDAD", value: "policy", columns: 1 },
      { header: "FECHA INICIO", value: "startDate", columns: 1, format: "fecha" },
      { header: "FECHA FINAL", value: "endDate", columns: 1, format: "fecha" },
    ],
    [
      { header: "NO. DIA INCAPACIDAD", value: "noDays", columns: 1 },
      { header: "ESTATUS INCAPACIDAD", value: "statusIncapacity", columns: 2 },
    ],
    [
      { header: "DIAGNOSTICO", value: "clinicalDiagnosis", columns: 2 },
      { header: "TIPO SUELDO", value: "nameTypeApplicationSalary", columns: 2 },
    ],
  ], //R10
  [
    [
      { header: "NOMBRE COMPLETO", value: "nameStaff", columns: 2 },
      { header: "ESTATUS EMPLEADO", value: "statusStaff", columns: 1 },
    ],
    [
      { header: "ADSCRIPCION", value: "nameArea", columns: 2 },
      { header: "OFICIO LICENCIA", value: "policy", columns: 1 },
    ],
    [
      { header: "TIPO DE LICENCIA", value: "nameTypeLeave", columns: 2 },
      { header: "ESTATUS LICENCIA", value: "statusLicence", columns: 2 },
    ],
    [
      { header: "FECHA INICIO", value: "startDate", columns: 1, format: "fecha" },
      { header: "FECHA FINAL", value: "endDate", columns: 1, format: "fecha" },
      { header: "NO. DIAS LICENCIA", value: "noDays", columns: 1 },
    ],
    [{ header: "OBSERVACIONES", value: "notes", columns: 3 }],
    [{ header: "DESGLOSE", value: "desgloce", columns: 3 }],
  ], //R11
  [
    [
      { header: "NOMBRE COMPLETO", value: "nameStaff", columns: 2 },
      { header: "ADSCRIPCION", value: "nameArea", columns: 2 },
    ],
    [
      { header: "TIPO DE PRESTAMO", value: "nameTypeLoan", columns: 2 },
      { header: "INSTITUCION", value: "nameInstitution", columns: 2 },
    ],
    [
      { header: "FECHA INICIO", value: "startDate", columns: 1, format: "fecha" },
      { header: "FECHA FINAL", value: "endDate", columns: 1, format: "fecha" },
      { header: "NO. DE PARCIALIDADES", value: "noPays", columns: 1 },
    ],
    [
      {
        header: "IMPORTE TOTAL",
        value: "amountTotal",
        columns: 1,
        format: "moneda",
      },
      {
        header: "IMPORTE QUINCENAL",
        value: "amountBiweekly",
        columns: 1,
        format: "moneda",
      },
      { header: "NO. DE PAGOS REALIZADOS", value: "noPaysMade", columns: 1 },
    ],
    [
      { header: "POLIZA", value: "policy", columns: 1 },
      { header: "OBSERVACIONES", value: "notes", columns: 2 },
    ],
    [
      { header: "CONCEPTO", value: "under", columns: 1 },
      { header: "DESCRIPCION", value: "nameUnder", columns: 1 },
      { header: "ESTATUS PRESTAMO", value: "nameStateLoan", columns: 1 },
    ],
  ], //R12
  [
    [
      { header: "NOMBRE COMPLETO", value: "nameStaff", columns: 3 },
    ],
    [
      { header: "ADSCRIPCION", value: "nameArea", columns: 3 },
    ],
    [
      { header: "POLIZA", value: "policy", columns: 1 },
      { header: "TIPO DE SEGURO", value: "nameTypeInsurance", columns: 2 },
    ],
    [
      { header: "CONCEPTO", value: "under", columns: 1 },
      { header: "DESCRIPCION CONCEPTO", value: "nameUnder", columns: 2 },
    ],
    [
      { header: "FECHA INICIO", value: "startDate", columns: 2, format: "fecha" },
      { header: "ESTATUS SEGURO", value: "statusInsurance", columns: 2 },
    ],
    [
      { header: "IMPORTE QUINCENAL", value: "amountBiweekly", columns: 2, format: "moneda" },
      { header: "NO. DE PAGOS REALIZADOS", value: "noPaysMade", columns: 2 },
    ],
    [
      { header: "INSTITUCION", value: "nameInstitution", columns: 1 },
      { header: "OBSERVACIONES", value: "notes", columns: 2 },
    ],
  ], //R13
  [
    [
      { header: "NOMBRE COMPLETO", value: "nameStaff", columns: 2 },
      { header: "ADSCRIPCION", value: "nameArea", columns: 2 },
    ],
    [
      { header: "BANCO", value: "nameBank", columns: 1 },
      { header: "UNIDAD", value: "typeDiscount", columns: 1 },
      { header: "CANTIDAD", value: "amount", columns: 1, format: (row) => clearRep14(row, "amount") },
    ],
    [
      { header: "BENEFICIARIO", value: "beneficiario", columns: 2 },
      { header: "NO DE CUENTA", value: "account", columns: 1 },
    ],
    [
      { header: "TIPO DE PENSION", value: "nameTypePension", columns: 1 },
      { header: "TIPO DE PAGO", value: "namePaymentType", columns: 1 },
      { header: "TIPO CUENTA", value: "nameAccountType", columns: 1 },
    ],
    [
      { header: "INICIO PENSION", value: "startDate", columns: 1 },
      { header: "FIN PENSION", value: "endDate", columns: 1 },
    ],
    [
      { header: "RANGO", value: "rango", columns: 1 },
      { header: "NO. OFICIO", value: "policy", columns: 1 },
    ],
  ], //R14
  [
    [
      { header: "NOMBRE COMPLETO", value: "nameStaff", columns: 2 },
      { header: "ADSCRIPCION", value: "nameArea", columns: 2 },
    ],
    [
      { header: "OFICIO", value: "policy", columns: 1 },
      { header: "NO. DE INFANTES", value: "noChildren", columns: 1 },
    ],
    [
      { header: "ESTATUS ESTANCIA", value: "statusChildService", columns: 2 },
      { header: "CONCEPTO RH", value: "conceptoRH", columns: 2 },
    ],
    [
      { header: "FECHA INICIO", value: "startDate", columns: 1, format: "fecha" },
      { header: "FECHA FINAL", value: "endDate", columns: 1, format: "fecha" },
      { header: "NO. DE PARCIALIDADES", value: "noPays", columns: 1 },
    ],
    [
      {
        header: "IMPORTE TOTAL",
        value: "amountTotal",
        columns: 1,
        format: "moneda",
      },
      {
        header: "IMPORTE QUINCENAL",
        value: "amountBiweekly",
        columns: 1,
        format: "moneda",
      },
      { header: "NO. DE PAGOS REALIZADOS", value: "noPaysMade", columns: 1 },
    ],
    [{ header: "OBSERVACIONES", value: "notes", columns: 2 }],
  ], //R15
  [
    [
      // { header: "TOTAL PLAZAS", value: "total", columns: 1 },
      { header: "PUESTO LABORAL", value: "nameJob", columns: 2 },
    ],
    [
      { header: "RANGO", value: "rango", columns: 1 },
      { header: "SUELDO BASE", value: "base", columns: 1, format: "moneda" },
      { header: "SUELDO BRUTO", value: "gross", columns: 1, format: "moneda" },
    ],
    [
      { header: "SUELDO NETO", value: "net", columns: 1, format: "moneda" },
      {
        header: "GRATIFICACION",
        value: "reward",
        columns: 1,
        format: "moneda",
      },
      { header: "DESPENSA", value: "larder", columns: 1, format: "moneda" },
    ],
    [
      {
        header: "COMPENSACION",
        value: "clearance",
        columns: 1,
        format: "moneda",
      },
      { header: "ISR", value: "taxes", columns: 1, format: "moneda" },
      { header: "ISSEMYM", value: "ssecurity", columns: 1, format: "moneda" },
    ],
  ], //R16
  [
    [
      { header: "CLAVE", value: "under", columns: 1 },
      { header: "LEYENDA", value: "nameUnder", columns: 2 },
    ],
    [
      { header: "CLAVE PRESUP.", value: "fullKey", columns: 1 },
      { header: "OCURRENCIAS", value: "numberRecords", columns: 1 },
      { header: "IMPORTE", value: "ammount", columns: 1, format: "moneda" },
    ],
  ], //R17
];

/* CONFIGURACION PDF/HTML 7, 9 */
const contentPdfGeneral = [
  [
    { header: "NOMBRE", value: "nameStaff", columns: 1 },
    { header: "CONCEPTO", value: "under", columns: 1 },
    { header: "DESCRIPCIÓN CONCEPTO", value: "nameUnder", columns: 1 },
    { header: "TIPO CONCEPTO", value: "nameTypeUnder", columns: 1 },
    { header: "PERIODO", value: "period", columns: 1 },
    { header: "AÑO", value: "year", columns: 1 },
    { header: "MONTO", value: "amount", columns: 1, format: "moneda" },
    { header: "ESTADO", value: "nameStat", columns: 1 },
    { header: "NO. CUENTA", value: "account", columns: 1 },
  ],
];

/* Arrays estaticos para selects Formato y Meses */
  //formato
  const format = [
    {
      id: 1,
      value: "Pdf",
    },
    {
      id: 2,
      value: "Hoja de calculo",
    },
    {
      id: 3,
      value: "Html",
    },
  ];

import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import * as Yup from "yup";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import DatePicker, { registerLocale } from "react-datepicker";
import { Col, Row, Button, Collapse } from "reactstrap";
import { eachDayOfInterval } from "date-fns";
import "react-datepicker/dist/react-datepicker.css";

import { getAbcensesType, getKindToStatus, getUnders } from "./consultasSelect";
import { peticionEncript } from "../../../helpers/peticionesEncripted";

import Notification from "../../../components/Notification/Notification";
import ModalConfirmation from "../../../components/Modal/ModalConfirmation";
import {
    TextInput,
    SelectTypeHeadSingle,
    FilesInput,
} from "../../../components/GenericInputsFormik/index";
import TableComponentAbsences from "../../../components/TableComponent/TableComponentAbsences";
import { YearInput } from "../../../components/GenericInputsFormik/YearInput";
import { PERIOD } from "../../../helpers/regex";
/* import MaskedInput from "react-maskedinput"; */
import es from "date-fns/locale/es";

import { doRequest, uploadFile } from "../../../helpers/requests";

import TableComponentCustom from "../../../components/TableComponent/TableComponentCustom";
import ModalViewFile from "../../../components/Modal/ModalViewFile";
import ModalComponentEliminar from "../../../components/Modal/ModalDelete";
import FullFormLoader from "../../../components/Loader/FullFormLoader";
import { NumberInput } from "../../../components/GenericInputsFormik/NumberInput";
import { fDateLocal, fDateTimeInput } from "../../../libs/tools/format";

registerLocale("es", es);

const actualYear = new Date();

const defaultForm = {
    idAbcense: "",
    keyStat: "",
    idUnder: "",
    periodAbcense: "",
    yearAbcense: "",
    noDays: 0,
    application: "",
    amountTotal: "",
    amountBiweekly: "",
    noPays: "",
    noPaysMade: 0,
    notes: "",
    startPeriod: "",
    startYear: "",
    endPeriod: "",
    endYear: "",
    horaInicio: "00:00",
    horaTermino: "",
    comprobante: "",
};

export const ExpedienteNominaInasistencias = ({ idStaff, permissions }) => {
    const refInpFile = useRef(null);
    const [modalFile, setModalFile] = useState(false); ///Visualziar archivos
    const [linkFile, setLinkFile] = useState(""); ///Link de archivo

    // || ------------------- INICIO DE LOS HOOKS ---------------- || //

    // ------------------- SELECTS PARA EL FORMULARIO ---------------- //

    const [selectsData, setSelectsData] = useState({
        statusTypes: [],
        applicationSalaryTypes: [],
        underTypes: [],
    });

    const [salaryTypesBack, setSalaryTypesBack] = useState([]);

    const [periodDates, setPeriodDates] = useState({
        startDate: "",
        endDate: "",
    });

    // -------------------  INFORMACION PARA EL  BACK ---------------- //

    const [finalData, setFinalData] = useState("");

    // ------------------- PARA EL DESCUENTO Y LA APLICACIÓN Y FECHA ---------------- //

    const [discounts, setDiscounts] = useState([]);

    const [descuentosAplicacion, setDescuentosAplicacion] = useState({
        idAbcenseDays: "",
        descuentosAplicacionKeyTypeApplicationSalary: "",
        descuentosAplicacionStartDate: null,
        enabled: 1,
    });

    const [errorsDiscount, setErrorsDiscount] = useState(false);

    const [errorApplications, setErrorApplications] = useState(false);

    const [excludeDates, setExcludeDates] = useState([]);

    const [noActiveJob, setNoActiveJob] = useState(false);

    const [editHasFile, setEditHasFile] = useState(false);

    // ------------------- PARA LA TABLA ONE PAGE Y FORMULARIO ------------------- //

    const [editando, setEditando] = useState(false);

    const [parametros, setParametros] = useState({
        action: "datatable",
        table: "abcenses a inner join unders u on a.idUnder = u.idUnder inner join status s on a.keyStat = s.keyStat LEFT join abcensesdays t on a.idAbcense = t.idAbcense LEFT join types_abcenses k on t.keyTypeAbcense = k.keyTypeAbcense AND t.enabled = 1 LEFT JOIN bookcase X ON a.idAbcense = X.reference AND X.keyFileType = 49 LEFT JOIN files Y ON X.idBook = Y.idBook",
        rows: "a.idAbcense, u.nameUnder, a.periodAbcense, a.startPeriod, a.endPeriod, a.noDays, a.amountTotal, a.amountBiweekly, a.noPaysMade, s.nameStat, group_concat(nameTypeAbcense,dayAbcense,',') diasDescuento,Y.src",
        conditions: `a.enabled=1 and a.idStaff=${idStaff} AND IF(ISNULL(X.enabled), TRUE, X.enabled = 1 AND Y.enabled = 1)`,
        page: 0,
        records: 5,
        search: "",
        order: "",
        group: "a.idAbcense",
    });

    const [cabeceras] = useState([
        "Id",
        "Concepto",
        "Periodo a descontar",
        "Periodo de inicio",
        "Peridio de fin",
        "No. de dias",
        "Importe total",
        "Importe quincenal",
        "Pagos realizados",
        "Estatus",
        "Detalle dias",
        "Evidencia",
        "Editar",
        "Eliminar",
    ]);

    const [collapse, setCollapse] = useState(false);

    const [filtro] = useState([
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
    ]);

    // ------------------- GENERALES ---------------- //

    const [modal, setModal] = useState(false);

    const [loading, setLoading] = useState(true);

    // const [showSection, setShowSection] = useState(false);
    const [startRange, setStartRange] = useState(null);
    const [endRange, setEndRange] = useState(null);
    const [flagStart, setFlagStart] = useState(false);

    // || ------------------- FIN DE LO HOOKS ---------------- || //

    // || ------------------- INICIO DE LAS FUNCIONES ---------------- || //

    // ------------------- FUNCIONES PARA LA TABLA Y MOSTRAR FORMULARIO ----------------

    const toggle = () => setCollapse(!collapse);

    const modoEdicion = async (e) => {
        if (!collapse) {
            if (e[11]) setEditHasFile(true);
            //LLENAMOS EL HOOK CON LOS DATOS UNICAMENTE EL INCAPACITY, ADICIONALMENTE HAY QUE HACER EL LLAMADO A LAS FUNCIONES PARA TRAER APPLICATION
            setLoading(true);
            await getApplicationByidAbcense(e);
            setLoading(false);
        } else {
            toast(
                <Notification
                    type="warning"
                    backMessage="Guarde sus cambios antes de continuar"
                />
            );
        }
    };

    const limpia = (reset) => {
        //LIMPIO MI HOOK
        setDiscounts([]);
        setEditando(false);
        setCollapse(false);
        reset();
    };

    const getApplicationByidAbcense = async (e) => {
        const data = {
            action: "searchAbcensesById",
            rows: {
                idAbcense: e[0],
            },
        };
        const finalData = peticionEncript(data);
        await axios
            .post(
                `${process.env.REACT_APP_API}app/facades/employees/benefitsF.php`,
                finalData
            )
            .then((res) => {
                setFlagStart(false);
                var startDate = new Date(res.data.data[0].startDate);
                startDate.setDate(startDate.getDate() + 1);
                var newYearPeriod = new Date();
                newYearPeriod.setFullYear(res.data.data[0].yearAbcense);
                setValues(
                    {
                        idAbcense: res.data.data[0].idAbcense,
                        idUnder: res.data.data[0].idUnder,
                        periodAbcense: res.data.data[0].periodAbcense,
                        yearAbcense: newYearPeriod,
                        noDays: +res.data.data[0].noDays,
                        amountTotal: res.data.data[0].amountTotal,
                        startYear: new Date(res.data.data[0].startYear, 0, 1),
                        startPeriod: res.data.data[0].startPeriod,
                        endYear: res.data.data[0].endYear,
                        endPeriod: res.data.data[0].endPeriod,
                        amountBiweekly: +res.data.data[0].amountBiweekly,
                        noPays: res.data.data[0].noPays,
                        noPaysMade: res.data.data[0].noPaysMade,
                        notes: res.data.data[0].notes,
                        keyStat: res.data.data[0].keyStat,
                        application: res.data.data[0].application,
                    },
                    true
                );
                res.data.data[0].application.forEach((e) => {
                    addDiscountByAbcense(e);
                });
                getDatesByPeriodYear(
                    res.data.data[0].periodAbcense,
                    newYearPeriod
                );
            })
            .catch((err) => {
                toast(<Notification type={"consultar_error"} withIcon />);
            });
        setEditando(true);
        setCollapse(true);
    };

    const addDiscountByAbcense = (e) => {
        var startDate = new Date(e.dayAbcense);
        var finalObject = {
            idAbcenseDays: e.idAbcenseDays,
            descuentosAplicacionKeyTypeApplicationSalary: e.keyTypeAbcense,
            descuentosAplicacionStartDate: startDate,
            enabled: 1,
        };
        setDiscounts((oldArray) => [...oldArray, finalObject]);
    };

    const formatDateTime = (date) => {
        const today = new Date(date);
        const month = today.getMonth() + 1;
        const day = today.getDate();
        const formattMonth = month < 10 ? `0${month}` : month;
        const formattDay = day < 10 ? `0${day}` : day;
        return `${today.getFullYear()}-${formattMonth}-${formattDay} ${today.getHours()}:${today.getMinutes()}:00`;
    };

    // function onlyNumbers(str) {
    //     return /^[0-9]+$/.test(str);
    // }

    const addDiscount = () => {
        setErrorsDiscount(false);
        if (
            descuentosAplicacion.descuentosAplicacionKeyTypeApplicationSalary &&
            descuentosAplicacion.descuentosAplicacionStartDate
        ) {
            if (
                +descuentosAplicacion.descuentosAplicacionKeyTypeApplicationSalary ===
                1
            ) {
                /// por dia
                if (!startRange && !endRange) return;
                const interval = eachDayOfInterval({
                    start: startRange,
                    end: endRange,
                });
                const newObjects = interval.map((d) => {
                    return {
                        ...descuentosAplicacion,
                        descuentosAplicacionStartDate: d,
                    };
                });
                setDiscounts((oldArray) => [...oldArray, ...newObjects]);
                setStartRange(null);
                setEndRange(null);
            } else {
                /// por tiempo
                setDiscounts((oldArray) => [...oldArray, descuentosAplicacion]);
            }
            setDescuentosAplicacion((prevState) => ({
                ...prevState,
                idAbcenseDays: "",
                descuentosAplicacionKeyTypeApplicationSalary: "",
                descuentosAplicacionStartDate: null,
            }));
        } else {
            setErrorsDiscount(true);
        }
    };

    // const validaDescuentosDias = () => {
    //     var finalApplication = [];
    //     if (discounts.length > 0) {
    //         discounts.forEach((e) => {
    //             var startDate = formatDateTime(e.descuentosAplicacionStartDate);
    //             var data = {
    //                 idAbcenseDays: e.idAbcenseDays,
    //                 keyTypeApplicationSalary:
    //                     e.descuentosAplicacionKeyTypeApplicationSalary,
    //                 startDate: startDate,
    //                 enabled: e.enabled,
    //             };
    //             finalApplication.push(data);
    //         });
    //         return false;
    //     } else {
    //         return false;
    //     }
    // };

    // ------------------- FUNCIONES PARA LAS APLICACIONES ------------------- //

    // const fillNominaApplication = (valor, name) => {
    //     var auxPeriodo = "";
    //     var anioFinal = "";
    //     var periodoFinal = "";
    //     if (name === "startYear") {
    //         periodoFinal =
    //             parseInt(values.startPeriod) + parseInt(values.noPays) - 1;
    //         anioFinal = parseInt(valor.getFullYear());
    //         auxPeriodo = periodoFinal / 24;
    //         auxPeriodo = Math.floor(auxPeriodo);
    //         if (periodoFinal % 24 > 0) {
    //             periodoFinal = periodoFinal - 24 * auxPeriodo;
    //             anioFinal = parseInt(valor.getFullYear()) + auxPeriodo;
    //         } else {
    //             periodoFinal = 24;
    //             anioFinal = parseInt(valor.getFullYear()) + auxPeriodo - 1;
    //         }
    //         const cantQ = (
    //             Number(values.amountTotal) / Number(values.noPays)
    //         ).toFixed(2);
    //         setFieldValue("endPeriod", periodoFinal);
    //         setFieldValue("endYear", anioFinal);
    //         setFieldValue("amountBiweekly", cantQ);

    //         setFieldValue("endPeriod", periodoFinal);
    //         setFieldValue("endYear", anioFinal);
    //         setFieldValue("amountBiweekly", cantQ);
    //     } else if (name === "startPeriod") {
    //         periodoFinal = parseInt(valor) + parseInt(values.noPays) - 1;
    //         anioFinal = parseInt(values.startYear.getFullYear());
    //         auxPeriodo = periodoFinal / 24;
    //         auxPeriodo = Math.floor(auxPeriodo);
    //         if (periodoFinal % 24 > 0) {
    //             //
    //             periodoFinal = periodoFinal - 24 * auxPeriodo;
    //             //
    //             anioFinal =
    //                 parseInt(values.startYear.getFullYear()) + auxPeriodo;
    //         } else {
    //             periodoFinal = 24;
    //             anioFinal =
    //                 parseInt(values.startYear.getFullYear()) + auxPeriodo - 1;
    //         }
    //         const cantQ = (
    //             Number(values.amountTotal) / Number(values.noPays)
    //         ).toFixed(2);

    //         setFieldValue("endPeriod", periodoFinal);
    //         setFieldValue("endYear", anioFinal);
    //         setFieldValue("amountBiweekly", cantQ);

    //         setFieldValue("endPeriod", periodoFinal);
    //         setFieldValue("endYear", anioFinal);
    //         setFieldValue("amountBiweekly", cantQ);
    //     } else if (name === "noPays") {
    //         periodoFinal = parseInt(values.startPeriod) + parseInt(valor) - 1;
    //         anioFinal = parseInt(values.startYear.getFullYear());
    //         auxPeriodo = periodoFinal / 24;
    //         auxPeriodo = Math.floor(auxPeriodo);
    //         if (periodoFinal % 24 > 0) {
    //             //
    //             periodoFinal = periodoFinal - 24 * auxPeriodo;
    //             //
    //             anioFinal =
    //                 parseInt(values.startYear.getFullYear()) + auxPeriodo;
    //         } else {
    //             periodoFinal = 24;
    //             anioFinal =
    //                 parseInt(values.startYear.getFullYear()) + auxPeriodo - 1;
    //         }
    //         const cantQ = (Number(values.amountTotal) / Number(valor)).toFixed(
    //             2
    //         );

    //         setFieldValue("endPeriod", periodoFinal);
    //         setFieldValue("endYear", anioFinal);
    //         setFieldValue("amountBiweekly", cantQ);

    //         setFieldValue("endPeriod", periodoFinal);
    //         setFieldValue("endYear", anioFinal);
    //         setFieldValue("amountBiweekly", cantQ);
    //     } else if (name === "amountTotal") {
    //         periodoFinal =
    //             parseInt(values.startPeriod) + parseInt(values.noPays) - 1;
    //         anioFinal = parseInt(values.startYear);
    //         auxPeriodo = periodoFinal / 24;
    //         auxPeriodo = Math.floor(auxPeriodo);
    //         if (periodoFinal % 24 > 0) {
    //             //
    //             periodoFinal = periodoFinal - 24 * auxPeriodo;
    //             //
    //             anioFinal = parseInt(values.startYear) + auxPeriodo;
    //         } else {
    //             periodoFinal = 24;
    //             anioFinal = parseInt(values.startYear) + auxPeriodo - 1;
    //         }
    //         const cantQ = (Number(valor) / Number(values.noPays)).toFixed(2);

    //         setFieldValue("endPeriod", periodoFinal);
    //         setFieldValue("endYear", anioFinal);
    //         setFieldValue("amountBiweekly", cantQ);

    //         setFieldValue("endPeriod", periodoFinal);
    //         setFieldValue("endYear", anioFinal);
    //         setFieldValue("amountBiweekly", cantQ);
    //     } else if (name === "fromResponse") {
    //         periodoFinal =
    //             parseInt(valor.startPeriod) + parseInt(valor.noPays) - 1;
    //         anioFinal = parseInt(valor.startYear.getFullYear());
    //         auxPeriodo = periodoFinal / 24;
    //         auxPeriodo = Math.floor(auxPeriodo);
    //         if (periodoFinal % 24 > 0) {
    //             //
    //             periodoFinal = periodoFinal - 24 * auxPeriodo;
    //             //
    //             anioFinal =
    //                 parseInt(valor.startYear.getFullYear()) + auxPeriodo;
    //         } else {
    //             periodoFinal = 24;
    //             anioFinal =
    //                 parseInt(valor.startYear.getFullYear()) + auxPeriodo - 1;
    //         }
    //         const cantQ = (
    //             valor.amountTotal < 0
    //                 ? 0.0
    //                 : Number(valor.amountTotal) / Number(valor.noPays)
    //         ).toFixed(2);

    //         setFieldValue("endPeriod", periodoFinal);
    //         setFieldValue("endYear", anioFinal);
    //         setFieldValue("amountBiweekly", cantQ);

    //         setFieldValue("endPeriod", periodoFinal);
    //         setFieldValue("endYear", anioFinal);
    //         setFieldValue("amountBiweekly", cantQ);
    //     }
    // };

    // const validaAplicacionCampos = () => {
    //     if (values.amountTotal !== "") {
    //         if (values.idAbcense !== "") {
    //             // QUIERE DECIR QUE ESTA EDITANDO
    //             if ( +values.amountTotal === 0) {
    //                 // QUIERE DECIR QUE LO DEJO IGUAL
    //                 return false;
    //             }
    //         } else {
    //             //QUIERE DECIR QUE TIENE QUE MOSTRAR ESTO
    //             if (
    //                 values.noPays === "" ||
    //                 values.amountBiweekly === "" ||
    //                 values.startPeriod === "" ||
    //                 values.startYear === "" ||
    //                 values.endPeriod === "" ||
    //                 values.endYear === ""
    //             ) {
    //                 return true;
    //             } else {
    //                 return false;
    //             }
    //         }
    //     } else {
    //         return "Agrega 0";
    //     }
    // };

    // ------------------- FUNCIONES PARA EL CAMBIO DE INPUTS ------------------- //

    const onChangeDate = (e) => {
        // if (e.value > periodDates.endDate || e.value < periodDates.startDate) {
        //   toast(
        //     <Notification
        //       type={"warning"}
        //       backMessage={
        //         "La fecha que deseas seleccionar no corresponde al periodo indicado arriba"
        //       }
        //       withIcon
        //     />
        //   );
        // } else {
        setDescuentosAplicacion((prevState) => ({
            ...prevState,
            [e.name]: e.value,
        }));
        // }
    };

    const onChangeYearAbsence = (e) => {
        setFieldValue("yearAbcense", e.value);

        setFieldValue([e.name], e.value);
        if (values.periodAbcense !== "") {
            getDatesByPeriodYear(values.periodAbcense, e.value);
        }
    };

    const onChange = (e) => {
        if (e.target.name === "descuentosAplicacionKeyTypeApplicationSalary") {
            setDescuentosAplicacion((prevState) => ({
                ...prevState,
                [e.target.name]: e.target.value,
            }));
            if (!e.target.value)
                onChangeDate({
                    name: "descuentosAplicacionStartDate",
                    value: null,
                });
        } else {
            if (e.target.name === "periodAbcense" && e.target.value && !!values.yearAbcense) {
                getDatesByPeriodYear(e.target.value, values.yearAbcense);
            }
            setFieldValue(e.target.name, e.target.value);
        }
    };

    const onChangeFile = (e) => {
        var { files } = e.target;
        if (files.length === 0) {
            setFieldValue("comprobante", "");
        } else {
            setFieldValue("comprobante", files[0]);
        }
    };
    // ------------------- FUNCIONES PARA EL ENVIO DE DATOS ------------------- //

    const enviaDatos = () => {
        // var flag = validaDescuentosDias();
        // if (!flag) {
        //     //QUIERE DECIR QUE CUMPLIO CON EL NUMERO DE DIAS
        //     flag = validaAplicacionCampos();
        //     if (flag !== true) {
        const application = discounts.map((d) => {
            return {
                idAbcenseDays: d.idAbcenseDays,
                dayAbcense: fDateTimeInput(d.descuentosAplicacionStartDate, true),
                keyTypeAbcense: d.descuentosAplicacionKeyTypeApplicationSalary,
                enabled: d.enabled 
            }
        });
        setFinalData((prevState) => ({
            ...prevState,
            idStaff: idStaff,
            idAbcense: values.idAbcense,
            idUnder: values.idUnder,
            periodAbcense: values.periodAbcense,
            yearAbcense: new Date(values.yearAbcense).getFullYear(),
            keyStat: values.keyStat,
            noDays: parseInt(values.noDays),
            application: application,
            amountTotal: values.amountTotal,
            amountBiweekly: +values.amountBiweekly,
            noPays: values.noPays,
            noPaysMade: values.noPaysMade,
            notes: values.notes,
            startPeriod: values.startPeriod,
            startYear: values.startYear
                ? values.startYear.getFullYear()
                : "",
            endPeriod: values.endPeriod,
            endYear: values.endYear,
        }));
        setModal(true);
        //     } else {
        //         setErrorApplications("Llene todos los campos marcados con (*)");
        //     }
        // } else {
        //     setErrorsDiscount(true);
        // }
    };

    const sendFormInformation = async () => {
        setLoading(true);
        const data = {
            action: "saveAbcenses",
            rows: finalData,
        };
        const res = await doRequest("app/facades/employees/benefitsF.php", data, true);
        if (res.length > 0) {
            if (values.comprobante) {
                await uploadFile(res[0].idAbcense, "saveFileReplace", 49, values.comprobante, false);
            }
            setParametros({ ...parametros });
            cleanForm();
        }
        setLoading(false);
    };

    const getDatesByPeriodYear = async (abcensePeriod, abcenseYear) => {
        const data = {
            action: "periodoAFechas",
            rows: {
                period: abcensePeriod,
                year: abcenseYear.getFullYear(),
            },
        };
        const finalDataEncrypted = peticionEncript(data);
        await axios
            .post(
                `${process.env.REACT_APP_API}app/facades/employees/benefitsF.php`,
                finalDataEncrypted
            )
            .then((res) => {
                if (res.data.status === "success") {
                    var startDate = new Date(res.data.data.fechaInicio);
                    startDate.setHours(0);
                    startDate.setMinutes(0);
                    startDate.setSeconds(0);
                    var endDate = new Date(res.data.data.fechaFin);
                    endDate.setHours(23);
                    endDate.setMinutes(59);
                    endDate.setSeconds(59);
                    setPeriodDates((prevState) => ({
                        ...prevState,
                        startDate: startDate,
                        endDate: endDate,
                    }));
                } else {
                    toast(
                        <Notification
                            type={"consultar_error"}
                            backMessage={
                                "El periodo debe de comprender valores entre 1 y 24"
                            }
                            withIcon
                        />
                    );
                }
            })
            .catch((err) => {
                toast(<Notification type={"consultar_error"} withIcon />);
            });
    };

    const cleanForm = () => {
        setNoActiveJob(false);
        setEditHasFile(false);
        setDiscounts([]);
        setExcludeDates([]);
        setDescuentosAplicacion((prevState) => ({
            ...prevState,
            idAbcenseDays: "",
            descuentosAplicacionKeyTypeApplicationSalary: "",
            descuentosAplicacionStartDate: null,
            descuentosAplicacionTime: "",
            enabled: 1,
        }));
        setSelectsData((prevState) => ({
            ...prevState,
            applicationSalaryTypes: [],
        }));
        setSalaryTypesBack([]);
        setEditando(false);
        setCollapse(false);
        refInpFile.current.value = "";
    };

    // || ------------------- FIN DE LAS FUNCIONES ---------------- || //

    // -------------------------------------------------------------------- //

    // || ------------------- INICIO DE LOS USEEFFECT ---------------- || //

    // ------------------- LLENADO DE LOS SELECTS ------------------- //

    useEffect(() => {
        if (
            selectsData.applicationSalaryTypes.length === 0 ||
            selectsData.applicationSalaryTypes === ""
        ) {
            getUnders(setSelectsData, true, "underTypes", "in (9)");
        }
    }, [selectsData.applicationSalaryTypes]);

    useEffect(() => {
        getKindToStatus(setSelectsData, true, "statusTypes", "ABCENSES");
    }, []);

    useEffect(() => {
        if (
            selectsData.applicationSalaryTypes.length > 0 &&
            selectsData.statusTypes.length > 0 &&
            loading
        ) {
            setLoading(false);
        }
    }, [loading, selectsData]);

    // useEffect(() => {
    //   if (abcenseData.idAbcense !== "") {
    //     abcenseData.application.forEach((e) => {
    //       if (e.keyTypeApplicationSalary !== "1") {
    //         // setShowSection(true);
    //       }
    //     });
    //   }
    // }, []);

    // -------------------- LLENADO DEL HOOK DE OPCIONES PARA SELECCIONAR TIPOS DE DESCUENTOS ---------------- //

    useEffect(() => {
        if (
            discounts.length === 0 &&
            selectsData.applicationSalaryTypes.length === 0 &&
            salaryTypesBack.length === 0
        ) {
            getAbcensesType(
                setSelectsData,
                true,
                "applicationSalaryTypes"
            ).finally(() => {
                setLoading(false);
            });
        } else {
            var flag = false;
            if (selectsData.applicationSalaryTypes.length > 0) {
                for (
                    var i = 0;
                    i < selectsData.applicationSalaryTypes.length;
                    i++
                ) {
                    if (selectsData.applicationSalaryTypes[i] === undefined) {
                        selectsData.applicationSalaryTypes.splice(i, 1);
                    }
                }
            }
            if (salaryTypesBack.length > 0) {
                for (var j = 0; j < salaryTypesBack.length; j++) {
                    if (salaryTypesBack[j] === undefined) {
                        salaryTypesBack.splice(j, 1);
                    }
                }
            }

            if (selectsData.applicationSalaryTypes.length > 0) {
                salaryTypesBack.forEach((e) => {
                    selectsData.applicationSalaryTypes.forEach((s) => {
                        if (e !== undefined) {
                            if (
                                e.keyTypeApplicationSalary ===
                                s.keyTypeApplicationSalary
                            ) {
                                flag = true;
                            }
                        }
                    });
                });
            }
            if (
                flag &&
                salaryTypesBack.length +
                    selectsData.applicationSalaryTypes.length >
                    3
            ) {
                //Quiere decir que coincidio con un registro entonces, que lo actualice
                discounts.forEach((e) => {
                    let finalArray = "";
                    if (
                        e.descuentosAplicacionKeyTypeApplicationSalary === "1"
                    ) {
                        finalArray = selectsData.applicationSalaryTypes.filter(
                            (element) => element.keyTypeApplicationSalary !== 1
                        );
                    }
                    if (
                        e.descuentosAplicacionKeyTypeApplicationSalary === "2"
                    ) {
                        finalArray = selectsData.applicationSalaryTypes.filter(
                            (element) => element.keyTypeApplicationSalary !== 2
                        );
                    }
                    setSelectsData((prevState) => ({
                        ...prevState,
                        applicationSalaryTypes: finalArray,
                    }));
                });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectsData.applicationSalaryTypes, salaryTypesBack]);

    // || ------------------- FIN DE LOS USEEFFECT ---------------- || //

    // -------------------------------------------------------------------- //

    const FormSchema = Yup.object().shape({
      keyStat: Yup.string().required("Seleccione un estatus"),
      idUnder: Yup.string().required(
          "El impuesto de la multa/sancion es requerido"
      ),
      periodAbcense: Yup.string().required(
          "Seleccione un periodo a descontar"
      ),
      yearAbcense: Yup.date().required("Seleccione un año a descontar").nullable(),
      comprobante: Yup.mixed().when("act", {
          is: (v) => {
              return editHasFile;
          },
          otherwise: Yup.mixed()
              .required("Agregue un comprobante")
              .test(
                  "file-type",
                  "El documento debe ser en formato PDF",
                  (value) => {
                      return value && value.type === "application/pdf";
                  }
              )
              .test({
                  message: `El documento debe pesar menos de 4MB`,
                  test: (value) => value?.size < 4000000,
              }),
      }),
      amountTotal: Yup.number().required("Ingrese una cantidad").min(1, "Cantidad insuficiente")
      /* F */
    });

    const {
        handleSubmit,
        handleReset,
        handleBlur,
        setFieldValue,
        setValues,
        values,
        errors,
        touched,
    } = useFormik({
        initialValues: defaultForm,
        onSubmit: (_values) => {
            enviaDatos();
        },
        onReset: () => setErrorsDiscount(false),
        validationSchema: FormSchema,
        enableReinitialize: true,
    });

    // -------------------- COLUMNAS TABLA ---------------- //
    const PdfCol = (el) => {
        return (
            <div>
                <i
                    className="fa fa-file-pdf-o cursor-pointer"
                    onClick={() => {
                        showFile(el[11]);
                    }}
                />
            </div>
        );
    };
    const editCol = (el) => {
        if (permissions.UPD)
            return (
                <div>
                    <i
                        className="fa fa-pencil text-warning cursor-pointer"
                        onClick={() => modoEdicion(el)}
                    />
                </div>
            );
    };
    const deleteCol = (el) => {
        if (permissions.DEL)
            return (
                <div>
                    <ModalComponentEliminar
                        modalTitle="Eliminar"
                        id={el[0]}
                        table="abcenses"
                        nameColumn={["idAbcense"]}
                        limpia={limpia}
                        elimina={elimianrRegistro}
                    >
                        <h6>¿Seguro de eliminar el registro "{el[1]}"?</h6>
                        No podrá recuperar la información después de ser
                        eliminada
                    </ModalComponentEliminar>
                </div>
            );
    };

    async function elimianrRegistro(id) {
        const params = {
            action: "delete",
            table: "abcenses",
            condition: { idAbcense: id },
            force: 1,
        };
        setLoading(true);
        const data = await doRequest("receiver/receiver.php", params, true);
        if (data.length > 0) {
            cleanForm();
            setParametros({ ...parametros });
        }
        setLoading(false);
    }

    useEffect(() => {
        if (linkFile !== "") {
            setModalFile(true);
        }
    }, [linkFile]);

    useEffect(() => {
        if (!modalFile) {
            setLinkFile("");
        }
    }, [modalFile]);

    function showFile(src) {
        if (src === "") {
            toast(
                <Notification
                    type={"warning"}
                    backMessage="Este registro no cuenta con una evidencia"
                    withIcon
                />,
                { closeButton: false }
            );
        } else setLinkFile(src);
    }

    /* Calcula los excludes */
    useEffect(() => {
        const excl = discounts.filter((d) => d.enabled === 1).map((d) => d.descuentosAplicacionStartDate);
        excl.sort(function (a, b) {
            return a - b;
        });
        setExcludeDates(excl);
        setFieldValue("noDays", excl.length);
        if (flagStart && collapse) sendDiscount();
        else setFlagStart(true);
    }, [discounts]);

    function onChangeMultipleDates(dates) {
        const [st, ed] = dates;
        let found = false;
        if (st && ed) {
            const interval = eachDayOfInterval({ start: st, end: ed }).map(
                (d) => fDateLocal(d)
            );
            const exDTx = excludeDates.map((d) => fDateLocal(d));
            found = exDTx.some((d) => interval.includes(d));
        }
        setStartRange(found ? null : st);
        setEndRange(found ? null : ed);
        onChangeDate({
            name: "descuentosAplicacionStartDate",
            value: found ? null : st,
        });
    }

    async function sendDiscount() {
        var finalApplication = [];
        if (discounts.length > 0) {
            finalApplication = discounts.map((e) => {
                return {
                    idAbcenseDays: e.idAbcenseDays ? e.idAbcenseDays : "",
                    keyTypeAbcense: e.descuentosAplicacionKeyTypeApplicationSalary,
                    dayAbcense: formatDateTime(e.descuentosAplicacionStartDate),
                    enabled: e.enabled,
                }
            });
        }

        var params = {
            action: "calcularDescuentoConTiempo",
            rows: {
                idStaff: idStaff,
                application: finalApplication,
            },
        };
        const res = await doRequest("app/facades/employees/benefitsF.php", params, false, true, true, "No existen plazas activas con entre las fechas seleccionadas, intente con otras fechas");
        if(typeof res === "number"){
          if (res > 0) setFieldValue("amountTotal", +res);
          setNoActiveJob(false);
        }else{
          setNoActiveJob(true);
        }
    }

    /* Calcula la info del form Aplicacion */
    useEffect(() => {
      if(collapse) calcEndAplication();
    }, [values.noPays,values.startPeriod,values.startYear,values.amountTotal]);

    function calcEndAplication(){
      const parcialidades = +values.noPays || null;
      const sPeriod = +values.startPeriod || null;
      const sYear = values.startYear ? values.startYear.getFullYear() : null;
      const tt = +values.amountTotal || null;
      
      if(tt && parcialidades){
        const cantQ = (tt / parcialidades).toFixed(2);
        setFieldValue("amountBiweekly", cantQ);
      } else  setFieldValue("amountBiweekly", "");
      
      if(sPeriod && sYear){
        let periodoFinal = sPeriod + parcialidades - 1;
        let anioFinal = sYear;
        let auxPeriodo = periodoFinal / 24;
        auxPeriodo = Math.floor(auxPeriodo);
        if (periodoFinal % 24 > 0) {
            periodoFinal = periodoFinal - 24 * auxPeriodo;
            anioFinal = sYear + auxPeriodo;
        } else {
            periodoFinal = 24;
            anioFinal = sYear + auxPeriodo - 1;
        }

        setFieldValue("endYear", anioFinal);
        setFieldValue("endPeriod", periodoFinal);
      }else{
        setFieldValue("endYear", "");
        setFieldValue("endPeriod", "");
      }
    }

    return (
        <div>
            <TableComponentCustom
                titulo="Inasistencias"
                cabecerasTabla={cabeceras}
                filtro={filtro}
                parametros={parametros}
                specialColumns={[PdfCol, editCol, deleteCol]}
            />
            {permissions.INS && (
                <div className="d-grid d-flex justify-content-center justify-content-sm-end">
                    <Button
                        color="add"
                        onClick={toggle}
                        style={{ marginBottom: "1rem" }}
                        disabled={collapse}
                    >
                        Agregar
                    </Button>
                </div>
            )}
            <Collapse isOpen={collapse}>
                <div>
                    <Col className="d-grid d-flex justify-content-sm-end justify-content-center">
                        <h6 className=" text-center">
                            Estado:{" "}
                            <span className="text-primary">
                                {editando ? "Editando" : "Guardando"}{" "}
                            </span>{" "}
                        </h6>
                    </Col>
                </div>
                <>
                    <form
                        onSubmit={handleSubmit}
                        onReset={handleReset}
                        className="mt-4"
                        noValidate
                    >
                        <ModalConfirmation
                            modalTitle={editando ? "Editar" : "Crear"}
                            modal={modal}
                            setModal={setModal}
                            crear={sendFormInformation}
                            editar={sendFormInformation}
                            isEdit={idStaff ? true : false}
                            values={finalData}
                        >
                            {editando ? (
                                <div className="d-flex justify-content-center">
                                    <h6>¿Está seguro de editar el registro?</h6>
                                </div>
                            ) : (
                                <div className="d-flex justify-content-center">
                                    <h6>¿Desea continuar con el registro?</h6>
                                </div>
                            )}
                        </ModalConfirmation>
                        <Row className="mt-2">
                            <Col xs={12} md={12} lg={4}>
                                <SelectTypeHeadSingle
                                    label="Concepto"
                                    isRequired={true}
                                    inputName="idUnder"
                                    optionsArray={selectsData.underTypes}
                                    defaultOption="Seleccione un concepto"
                                    onChangeMethod={onChange}
                                    onBlurMethod={handleBlur}
                                    isDisabled={false}
                                    value={values.idUnder}
                                    touched={touched.idUnder}
                                    errors={errors.idUnder}
                                    optionValue="idUnder"
                                    optionName="name"
                                />
                            </Col>
                            <Col xs={12} md={12} lg={4}>
                                <SelectTypeHeadSingle
                                    label="Estado"
                                    isRequired={true}
                                    inputName="keyStat"
                                    optionsArray={selectsData.statusTypes}
                                    defaultOption="Seleccione un estado"
                                    onChangeMethod={onChange}
                                    onBlurMethod={handleBlur}
                                    isDisabled={false}
                                    value={values.keyStat}
                                    touched={touched.keyStat}
                                    errors={errors.keyStat}
                                    optionValue="keyStat"
                                    optionName="nameStat"
                                />
                            </Col>
                            <Col xs={12} md={12} lg={4}>
                                <FilesInput
                                    label="Evidencia"
                                    inputName="comprobante"
                                    onChangeMethod={onChangeFile}
                                    onBlurMethod={handleBlur}
                                    fileAccept="application/pdf"
                                    isRequired
                                    touched={touched.comprobante}
                                    errors={errors.comprobante}
                                    basicReq={false}
                                    refInput={refInpFile}
                                />
                            </Col>
                        </Row>
                        <Row className="mt-2">
                            <Col xs={12} md={12} lg={4}>
                                <SelectTypeHeadSingle
                                    label="Periodo de la incidencia"
                                    isRequired={true}
                                    optionsArray={PERIOD}
                                    inputName="periodAbcense"
                                    onChangeMethod={onChange}
                                    onBlurMethod={handleBlur}
                                    touched={touched.periodAbcense}
                                    errors={errors.periodAbcense}
                                    optionValue="value"
                                    optionName="label"
                                    value={values.periodAbcense}
                                    defaultOption="Seleccione el periodo a descontar"
                                />
                            </Col>
                            <Col xs={12} md={12} lg={4}>
                                <YearInput
                                    label="Año"
                                    inputName="yearAbcense"
                                    onChangeMethod={(data) =>
                                        onChangeYearAbsence(data.target)
                                    }
                                    onBlurMethod={handleBlur}
                                    value={values.yearAbcense}
                                    isRequired
                                    touched={touched.yearAbcense}
                                    errors={errors.yearAbcense}
                                />
                            </Col>
                            <Col xs={12} md={12} lg={4}>
                                <TextInput
                                    label="No. días"
                                    inputType="number"
                                    inputName="noDays"
                                    value={values.noDays}
                                    isDisabled
                                />
                            </Col>
                        </Row>
                        <section className="mt-2">
                            <h4>Aplicación en nomina</h4>
                            <Row className="mt-2">
                                <Col xs={12} md={12} lg={4}>
                                    <NumberInput
                                        label="Cantidad total"
                                        inputName="amountTotal"
                                        onChangeMethod={onChange}
                                        onBlurMethod={handleBlur}
                                        value={values.amountTotal}
                                        errors={errors.amountTotal}
                                        touched={touched.amountTotal}
                                        isMin={1}
                                        decimals
                                        prefix="$"
                                        isRequired
                                    />
                                </Col>
                                <Col xs={12} md={12} lg={4}>
                                    <NumberInput
                                        label="Número de Parcialidades"
                                        inputName="noPays"
                                        onChangeMethod={onChange}
                                        onBlurMethod={handleBlur}
                                        value={values.noPays}
                                        errors={errors.noPays}
                                        touched={touched.noPays}
                                        isMin={1}
                                        maxLength={2}
                                    />
                                </Col>
                                <Col xs={12} md={12} lg={4}>
                                    <NumberInput
                                        label="Cantidad Quincenal"
                                        inputName="amountBiweekly"
                                        onChangeMethod={onChange}
                                        onBlurMethod={handleBlur}
                                        value={values.amountBiweekly}
                                        errors={errors.amountBiweekly}
                                        touched={touched.amountBiweekly}
                                        isMin={1}
                                        decimals
                                        prefix="$"
                                        isDisabled
                                    />
                                </Col>
                            </Row>
                            <Row className="mt-2">
                                <Col xs={12} md={12} lg={3}>
                                    <SelectTypeHeadSingle
                                        label="Inicio de periodo"
                                        optionsArray={PERIOD}
                                        inputName="startPeriod"
                                        onChangeMethod={onChange}
                                        onBlurMethod={handleBlur}
                                        touched={touched.startPeriod}
                                        errors={errors.startPeriod}
                                        optionValue="value"
                                        optionName="label"
                                        value={values.startPeriod}
                                        defaultOption="Seleccione un periodo de inicio"
                                    />
                                </Col>
                                <Col xs={12} md={12} lg={3}>
                                    <YearInput
                                        label="Año"
                                        inputName="startYear"
                                        onChangeMethod={onChange}
                                        onBlurMethod={handleBlur}
                                        value={values.startYear}
                                        touched={touched.startYear}
                                        errors={errors.startYear}
                                        isMin={2019}
                                        isMax={2500}
                                    />
                                </Col>
                                <Col xs={12} md={12} lg={3}>
                                    <TextInput
                                        label="Periodo de finalización"
                                        inputType="number"
                                        inputName="endPeriod"
                                        onChangeMethod={onChange}
                                        onBlurMethod={handleBlur}
                                        value={values.endPeriod}
                                        touched={touched.endPeriod}
                                        errors={errors.endPeriod}
                                        isDisabled={true}
                                    />
                                </Col>
                                <Col xs={12} md={12} lg={3}>
                                    <TextInput
                                        label="Año de finalización"
                                        inputType="number"
                                        inputName="endYear"
                                        onChangeMethod={onChange}
                                        onBlurMethod={handleBlur}
                                        value={values.endYear}
                                        touched={touched.endYear}
                                        errors={errors.endYear}
                                        isDisabled={true}
                                    />
                                </Col>
                            </Row>
                            <Row className="mt-2">
                                <Col xs={12} md={12} lg={4}>
                                    <TextInput
                                        label="Pagos Aplicados"
                                        inputType="number"
                                        inputName="noPaysMade"
                                        onChangeMethod={onChange}
                                        onBlurMethod={handleBlur}
                                        value={values.noPaysMade}
                                        isRequired={true}
                                        touched={touched.noPaysMade}
                                        errors={errors.noPaysMade}
                                        isDisabled={true}
                                    />
                                </Col>
                            </Row>
                            {errorApplications !== false && (
                                <Row>
                                    <Col xs={12}>
                                        <h6 style={{ color: "red" }}>
                                            {errorApplications}
                                        </h6>
                                    </Col>
                                </Row>
                            )}
                        </section>
                        {/* AQUI INICIAMOS CON EL PROCESO QUE SE VA A COMPARTIR CON LOS OTROS 2 MODULOS */}
                        {!!values.periodAbcense && !!values.yearAbcense && (
                                <section className="mt-3">
                                    {/* FORMAS DE DESCUENTO */}
                                      <h4>Formas de descuento</h4>
                                    <Row className="mt-2">
                                        <Col xs={12}>
                                            <Row className="mt-2">
                                                <Col xs={12} md={12} lg={4}>
                                                    <SelectTypeHeadSingle
                                                        label="Seleccione un tipo de descuento"
                                                        isRequired={false}
                                                        inputName="descuentosAplicacionKeyTypeApplicationSalary"
                                                        optionsArray={
                                                            selectsData.applicationSalaryTypes
                                                        }
                                                        defaultOption="Seleccione un tipo de descuento"
                                                        onChangeMethod={
                                                            onChange
                                                        }
                                                        onBlurMethod={
                                                            handleBlur
                                                        }
                                                        isDisabled={
                                                            !values.yearAbcense ||
                                                            !values.periodAbcense
                                                        }
                                                        value={
                                                            descuentosAplicacion.descuentosAplicacionKeyTypeApplicationSalary
                                                        }
                                                        optionValue="keyTypeApplicationSalary"
                                                        optionName="nameTypeApplicationSalary"
                                                    />
                                                </Col>
                                                <Col xs={12} md={12} lg={4}>
                                                    <div className="form-group">
                                                        <label>
                                                            Fecha y hora de
                                                            inicio
                                                            <span className="text-danger">
                                                                *
                                                            </span>
                                                        </label>
                                                        <DatePicker
                                                            // excludeDateIntervals={excludeDates}
                                                            excludeDates={
                                                                excludeDates
                                                            }
                                                            selected={
                                                                descuentosAplicacion.descuentosAplicacionStartDate
                                                            }
                                                            onChange={(date) =>
                                                                +descuentosAplicacion.descuentosAplicacionKeyTypeApplicationSalary !==
                                                                1
                                                                    ? onChangeDate(
                                                                          {
                                                                              name: "descuentosAplicacionStartDate",
                                                                              value: date,
                                                                          }
                                                                      )
                                                                    : onChangeMultipleDates(
                                                                          date
                                                                      )
                                                            }
                                                            locale="es"
                                                            // value={descuentosAplicacion.descuentosAplicacionStartDate}
                                                            timeIntervals={1}
                                                            timeFormat="p"
                                                            timeInputLabel="Hora"
                                                            dateFormat="dd/MM/yyyy HH:mm"
                                                            placeholderText="dd/MM/yyyy HH:mm"
                                                            showTimeInput={
                                                                +descuentosAplicacion.descuentosAplicacionKeyTypeApplicationSalary !==
                                                                1
                                                            }
                                                            className="form-control"
                                                            disabled={
                                                                !values.yearAbcense ||
                                                                !values.periodAbcense ||
                                                                !descuentosAplicacion.descuentosAplicacionKeyTypeApplicationSalary
                                                            }
                                                            startDate={
                                                                startRange
                                                            }
                                                            endDate={endRange}
                                                            selectsRange={
                                                                +descuentosAplicacion.descuentosAplicacionKeyTypeApplicationSalary ===
                                                                1
                                                            }
                                                            isClearable
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col xs={12}>
                                            <Row className="mt-2">
                                                <Col xs={12}>
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            justifyContent:
                                                                "center",
                                                            alignContent:
                                                                "center",
                                                        }}
                                                    >
                                                        <Button
                                                            color="success"
                                                            onClick={() =>
                                                                addDiscount()
                                                            }
                                                        >
                                                            +
                                                        </Button>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Col>
                                        {errorsDiscount && (
                                            <Col>
                                                <Row>
                                                    <Col xs={12}>
                                                        <h6 className="text-danger mt-2">
                                                            Error, ingrese todos
                                                            los datos y valide
                                                            que la suma de sus
                                                            dias de descuento
                                                            sea igual a la de
                                                            solicitada arriba y
                                                            seleccione una hora
                                                        </h6>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        )}
                                    </Row>
                                    {discounts.length > 0 && (
                                        <div>
                                            <TableComponentAbsences
                                                registros={discounts}
                                                setRegistros={setDiscounts}
                                                salaryTypesBack={
                                                    salaryTypesBack
                                                }
                                                selectsData={selectsData}
                                                excludeDates={excludeDates}
                                                setExcludeDates={
                                                    setExcludeDates
                                                }
                                            />
                                        </div>
                                    )}
                                </section>
                            )}
                        <Row className="mt-2">
                            <Col xs={12} md={12} lg={12}>
                                <TextInput
                                    label="Notas"
                                    inputType="text"
                                    inputName="notes"
                                    onChangeMethod={onChange}
                                    onBlurMethod={handleBlur}
                                    value={values.notes}
                                    isRequired={false}
                                />
                            </Col>
                        </Row>
                        <div className="row mt-3">
                            <div className="col-sm-6 order-2 order-sm-1 text-center text-sm-left mb-4">
                                <Button
                                    color="danger"
                                    type="reset"
                                    onClick={() => cleanForm()}
                                >
                                    Cancelar
                                </Button>
                            </div>
                            {!noActiveJob && (
                                <div className="col-sm-6 order-1 order-sm-2 text-center text-sm-right mb-4">
                                    <Button color="success" type="submit">
                                        Guardar
                                    </Button>
                                </div>
                            )}
                        </div>
                    </form>
                </>
            </Collapse>
            <ModalViewFile
                modal={modalFile}
                setModal={setModalFile}
                backdrop={true}
                keyboard={true}
                btnClose={true}
                fileLink={linkFile}
            />
            <FullFormLoader show={loading} />
        </div>
    );
};
export default ExpedienteNominaInasistencias;

import { Document, Page, Text, View, StyleSheet } from "@react-pdf/renderer";
import { styles } from "../../../../components/Pdf/pdfGeneral/EstiloPdf";
import { sumValues } from "../../../../libs/tools/arrays";
import HeaderReport from "../../../../components/Pdf/Header";

/**
 * Estilo que se repite en todas los headers de la tabla
 */
const styleHeadForAll = {
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "#646464",
    color: 'white',
    textAlign: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 6
}

const styleCellForAll = {
    borderStyle: "solid",
    borderWidth: 1,
    borderTopWidth: 0,
    fontSize: 6,
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
}

/**
 * Estilos del pdf
 */
const s = StyleSheet.create({
    subTitle: { //SUBTITULOS
        width: "100%",
        textAlign: 'center',
        marginBottom: 10,
        marginLeft: 10,
        fontSize: 14
    },
    thIn: {
        height: 20,
        ...styleHeadForAll
    },
    th: {
        height: 20,
        ...styleHeadForAll,
        borderLeftWidth: 0
    },
    w5: {
        width: "5%"
    },
    w10: {
        width: "10%"
    },
    w15: {
        width: "15%"
    },
    tdIn: { //Cell CC
        ...styleCellForAll
    },
    td: { //Cell Concepto
        ...styleCellForAll,
        borderLeftWidth: 0
    }
})


export default function exportDatosPersonales(data, model, title, fileNo) {
    const ori = model.orientation === "h" ? "landscape" : "portrait";

    function getWidth(width) {
        switch (width) {
            case 5:
                return s.w5;
            case 10:
                return s.w10;
            case 15:
                return s.w15
            default:
                return s.w15
        }
    }

    const dataReport = data.map(data => {
        const temp = data;
        temp.name = `${data.nameStaff}\nRFC:${data.rfc}\nCURP${data.curp}`
        temp.contact = `CORREO INSTITUCIONAL: ${data.jobMail}\nCORREO: ${data.email}\nTELEFONO: ${data.phone} ${data.phoneExtension !== "" ? `EXT: ${data.phoneExtension}` : ''}`
        temp.job = `${data.nameJob} ${data.level}-${data.rango}`
        const haveDom = data.street !== "" || data.extNumber !== "" || data.intNumber !== "" || data.nameCity !== "" || data.nameCounty !== "" || data.nameCountry !== "" || data.zipCode !== "";
        if (!haveDom) {
            temp.address = "SIN INFORMACIÓN"
        } else {
            temp.address = `${data.street !== "" ? `CALLE: ${data.street}\n` : ''}${data.intNumber !== "" ? `INT. ${data.intNumber}\n` : ''}${data.extNumber !== "" ? `EXT. ${data.extNumber}, \n` : ''}${data.nameCity !== "" ? `${data.nameCity}\n` : ''}${data.nameCounty !== "" ? `${data.nameCounty}, \n` : ''}${data.nameCountry !== "" ? `${data.nameCountry}\n` : ''}${data.zipCode !== "" ? `C.P.: ${data.zipCode}` : ''}`
        }
        return temp
    })

    return {
        report: (
            <>
                <Document>
                    <Page style={styles.body} orientation={ori}>
                        <HeaderReport />
                        <View>
                            <Text style={s.subTitle}>{title}</Text>
                        </View>
                        <View style={styles.table}>
                            <View style={styles.tableRow}>
                                {model.headers.map((header, index) => (
                                    <View style={[index === 0 ? s.thIn : s.th, getWidth(model.sizes[index])]}>
                                        <Text>{header}</Text>
                                    </View>
                                ))}
                            </View>
                            {dataReport.map((row, ind) => (
                                <View style={styles.tableRow} wrap>
                                    {model.content.map((value, index) => (
                                        <View style={[index === 0 ? s.tdIn : s.td, getWidth(model.sizes[index])]}>
                                            <Text>{value.index ? (ind + 1) : row[value.value]}</Text>
                                        </View>
                                    ))}
                                </View>
                            ))}
                        </View>

                    </Page>
                </Document>
            </>
        )
    };
}
// -- PAISES -- //
import Paises from "../../../pages/estructura-institucional/paises/Paises";
import CrearPais from "../../../pages/estructura-institucional/paises/crear/CrearPais";
import EditarPais from "../../../pages/estructura-institucional/paises/editar/EditarPais";

// -- ESTADOS -- //
import Estados from "../../../pages/estructura-institucional/estados/Estados";
import CrearEstado from "../../../pages/estructura-institucional/estados/crear/CrearEstado";
import EditarEstado from "../../../pages/estructura-institucional/estados/editar/EditarEstado";

// -- MUNICIPIOS -- //
import Municipios from "../../../pages/estructura-institucional/municipios/Municipios";
import CrearMunicipio from "../../../pages/estructura-institucional/municipios/crear/CrearMunicipio";
import EditarMunicipio from "../../../pages/estructura-institucional/municipios/editar/EditarMunicipio";

// -- CIUDADES -- //
import Ciudades from "../../../pages/estructura-institucional/ciudades/Ciudades";
import CrearCiudad from "../../../pages/estructura-institucional/ciudades/crear/CrearCiudad";
import EditarCiudad from "../../../pages/estructura-institucional/ciudades/editar/EditarCiudad";

// -- REGIONES -- //
import Regiones from "../../../pages/estructura-institucional/regiones/Regiones";
import CrearRegion from "../../../pages/estructura-institucional/regiones/crear/CrearRegion";
import EditarRegion from "../../../pages/estructura-institucional/regiones/editar/EditarRegion";

// -- AREAS -- //
import Areas from "../../../pages/estructura-institucional/areas/Areas";
import CrearArea from "../../../pages/estructura-institucional/areas/crear/CrearArea";
import EditarArea from "../../../pages/estructura-institucional/areas/editar/EditarArea";

// -- EDIFICIOS -- //
import Edificios from "../../../pages/estructura-institucional/edificios/Edificios";
import CrearEdificio from "../../../pages/estructura-institucional/edificios/crear/CrearEdificio";
import EditarEdificio from "../../../pages/estructura-institucional/edificios/editar/EditarEdificio";

// -- DISTRITOS -- //
import Distritos from "../../../pages/estructura-institucional/distritos/Distritos";
import CrearDistrito from "../../../pages/estructura-institucional/distritos/crear/CrearDistrito";
import EdiarDistrito from "../../../pages/estructura-institucional/distritos/editar/EdiarDistrito";

const rutasEstructuraInstitucional = [
    //Countries Routes
    {path:"/template/distritos", component:Distritos},
    {path:"/template/distritos/crear", component:CrearDistrito},
    {path:"/template/distritos/editar", component:EdiarDistrito},
    //Estados Routes
    {path:"/template/estados", component:Estados},
    {path:"/template/estados/crear", component:CrearEstado},
    {path:"/template/estados/editar", component:EditarEstado},
    //Regions Routes
    {path:"/template/regiones", component:Regiones},
    {path:"/template/regiones/crear", component:CrearRegion},
    {path:"/template/regiones/editar", component:EditarRegion},
    //Countries Routes
    {path:"/template/paises", component:Paises},
    {path:"/template/paises/crear", component:CrearPais},
    {path:"/template/paises/editar", component:EditarPais},
    //Areas Routes
    {path:"/template/areas", component:Areas},
    {path:"/template/areas/crear", component:CrearArea},
    {path:"/template/areas/editar", component:EditarArea},
    //Cities Routes
    {path:"/template/ciudades", component:Ciudades},
    {path:"/template/ciudades/crear", component:CrearCiudad},
    {path:"/template/ciudades/editar", component:EditarCiudad},
    //Buildings Routes
    {path:"/template/edificios", component:Edificios},
    {path:"/template/edificios/crear", component:CrearEdificio},
    {path:"/template/edificios/editar", component:EditarEdificio},
    //Counties Routes
    {path:"/template/municipios", component:Municipios},
    {path:"/template/municipios/crear", component:CrearMunicipio},
    {path:"/template/municipios/editar", component:EditarMunicipio},
]

export default rutasEstructuraInstitucional
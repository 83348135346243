import React, { useState } from "react";
import ModalComponentEliminar from "../../../components/Modal/ModalDelete";
import { doRequestSaveRes } from "../../../helpers/requests";
import Datatable from "../../../components/Datatable/Datatable";
import { fDate } from "../../../libs/tools/format";
import Widget from "../../../components/Widget/Widget";
import { useHistory } from "react-router-dom";
import { GetPermissions } from "../../../libs/permissions/getPermissions";
import { Button } from "reactstrap";
import DOMPurify from "dompurify";

export const Leyendas = () => {
  const [permissions, setPermissions] = useState({});
  GetPermissions(setPermissions);

  const [refresh, setRefresh] = useState(false);
  const history = useHistory();

  const cabeceras = [
    "#",
    "Tipo",
    "Leyenda",
    "Periodo",
    "Año",
    "Fecha de aplicacion",
    "Editar",
    "Eliminar",
  ];

  const columnDefs = [
    {
      orderable: true,
      targets: [0, 1, 3, 4, 5],
      className: "text-center",
    },
    { targets: [0, 6, 7], orderable: false },
  ];
  // const filtro = [true,true,true,true,true,true,false,false]

  const columns = [
    { data: "index" },
    { data: "nameTypeAdvertisement" },
    {
      data: "descripcion",
      render: function (d) {
        return (
          <div
            className="content"
            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(d.replace(/(<([^>]+)>)/gi, "")) }}
          ></div>
        );
      },
    },
    { data: "period" },
    { data: "year" },
    { data: "aplicationDate", format: fDate },
    {
      data: null,
      render: function (row) {
        return (
          <div className="text-center">
            {permissions.UPD && (
              <i
                className="fa fa-pencil text-warning cursor-pointer"
                onClick={() => redirectToEdit(row)}
              />
            )}
          </div>
        );
      },
    },
    {
      data: null,
      render: function (row) {
        return (
          <div className="text-center">
            {permissions.DEL && (
              <ModalComponentEliminar
                modalTitle="Eliminar"
                id={row.idAdvertisement}
                table={["advertisements"]}
                nameColumn={["idAdvertisement"]}
                getData={() => {
                  setRefresh(true);
                }}
              >
                <h6>
                  ¿Seguro de eliminar esta leyenda de periodo {row.period}/
                  {row.year}?
                </h6>
                No podrá recuperar la información después de ser eliminada
              </ModalComponentEliminar>
            )}
          </div>
        );
      },
    },
  ];

  async function getData(ordCol, itemsPerPage, currentPage, search) {
    const res = await doRequestSaveRes(
      "receiver/receiver.php",
      getParams(ordCol, itemsPerPage, currentPage, search),
      false,
      false
    );
    if (res.code === "200") {
      res.data.forEach(
        (value, index) => (value.index = currentPage + 1 + index)
      );
    }
    return res;
  }

  function getParams(ordCol, itemsPerPage, currentPage, search) {
    const params = {
      action: "datatable",
      table:
        "advertisements AS ADS INNER JOIN advertisement_types AS ADT ON ADT.keyTypeAdvertisement = ADS.keyTypeAdverstisement",
      rows: "ADS.idAdvertisement,ADT.nameTypeAdvertisement,ADS.descripcion,ADS.period,ADS.year, aplicationDate",
      conditions: `ADS.enabled = 1`, //
      order: ordCol,
      records: itemsPerPage.toString(),
      page: (itemsPerPage * currentPage).toString(),
      search,
    };
    return params;
  }

  function redirectToEdit(row) {
    const data = [
      row.idAdvertisement,
      row.nameTypeAdvertisement,
      "leyenda",
      row.period,
      row.year,
      row.aplicationDate,
    ];
    localStorage.setItem("dataContent", data.join("|-|"));
    history.push({
      pathname: "/template/leyendas/editar",
    });
  }

  return (
    <Widget className="widget-p-md">
      <div className="mb-4 mx-3 d-flex flex-md-row flex-column justify-content-md-between">
        <h2 className="text-center text-md-left">Leyendas</h2>
        <div className="mt-2 mb-1 my-md-0 text-center text-md-right">
          <Button
            onClick={() => {
              history.push("/template/leyendas/crear");
            }}
          >
            Agregar
          </Button>
        </div>
      </div>
      <Datatable
        headers={cabeceras}
        columns={columns}
        columnDefs={columnDefs}
        order={{ col: 1, opt: "asc" }}
        petition={getData}
        control="back"
        stateRefresh={[refresh, setRefresh]}
      />
    </Widget>
  );
};
export default Leyendas;
import * as Yup from "yup";
import { useFormik } from "formik";
import { Button, Col, Collapse, Row, Spinner } from "reactstrap";
import React, { useState, useEffect } from "react";
import Widget from "../../../components/Widget/Widget";
import s from "../reportes/filtros/InputClear.module.scss";
import DatePicker from "react-datepicker";
import ModalTableVer from "../../../components/Modal/ModalTableVer";
import CurrencyInput from "react-currency-input-field";
import { CancelOutlined } from "@material-ui/icons";
import FormMosidicarPagoTimbrado from "./FormModificarPagoTimbrado";
import TableComponentOnePage from "../../../components/TableComponent/TableComponentOnePage";
import Notification from "../../../components/Notification/Notification";
import { toast } from "react-toastify";
import axios from "axios";
import { peticionEncript } from "../../../helpers/peticionesEncripted";
import { GetPermissions } from "../../../libs/permissions/getPermissions";
import { SelectTypeHeadSingle } from "../../../components/GenericInputsFormik/SelectTypeHeadSingle";
import { PERIOD } from "../../../helpers/regex";

/* MODAL TABLA */
const filtroModalTabla = [true, true, true, true, true];
const cabecerasModalTabla = [
  "#",
  "Núm. Empleado",
  "Nombre",
  "Unidad de trabajo",
  "Puesto",
  "Seleccionar",
];
const paramsModalTabla = {
  action: "datatable",
  table:
    "staff STF INNER JOIN employtypes AS EMT ON STF.keyEmployType = EMT.keyEmployType INNER JOIN jobstaff AS JBS ON JBS.idStaff = STF.idStaff INNER JOIN areas AS AR ON AR.idArea = JBS.idArea INNER JOIN jobtypes AS JBT ON JBT.keyJobType = STF.keyEmployType",
  rows: "STF.idStaff,STF.staffNumber,CONCAT(STF.name,' ',STF.pName,' ',STF.mName) as fullName,AR.name,JBT.nameJobType",
  conditions:
    "STF.enabled = 1 AND JBS.valid = 1 AND STF.keyStat = 1 AND JBS.enabled = 1",
  page: 0,
  records: 5,
  search: "",
  order: "",
};
/* TABLA */
const filtroTP = [true, true, true, true, false];
const cabecerasTP = [
  "Id",
  "Concepto RH",
  "Tipo Concepto RH",
  "Importe",
  "Editar",
  "Eliminar",
];
const parametrosTP = {
  action: "datatable",
  table:
    "payslipsstamps A INNER JOIN unders B ON A.idUnder = B.idUnder INNER JOIN type_unders C ON B.KeyTypeUnder = C.keyTypeUnder",
  rows: "A.idPayslipstamp, concat(B.under,' - ',B.nameUnder) as underName, C.nameTypeUnder, A.amount,A.idUnder, A.period, A.year",
  conditions: `A.enabled = 1`,
  page: 0,
  records: 5,
  search: "",
  order: "",
};

/* CONSULTA BACK */
async function getBackInfo(params) {
  let info = [];
  const dataEncrypted = peticionEncript(params);
  await axios
    .post(process.env.REACT_APP_API + "receiver/receiver.php", dataEncrypted)
    .then((res) => {
      if (res.status === 200 && res.data.status === "success") {
        info = res.data.data;
      } else {
        toast(
          <Notification
            type={"consultar_error"}
            backMessage={res.data.message}
          />,
          { closeButton: false }
        );
      }
    })
    .catch((err) => {
      toast(<Notification type={"consultar_servidor_error"} withIcon />);
    });
  return info;
}

const getUnders = async (setUnders) => {
  const params = {
    action: "multiselect",
    table: "unders",
    rows: "idUnder,concat(under,' - ',nameUnder) as name",
    conditions: "enabled = 1",
    order: "cast(under as float) asc",
  };
  let data = await getBackInfo(params);
  setUnders(data);
};

const getStatus = async (setStatus) => {
  const params = {
    action: "select",
    table: "status",
    order: "nameStat ASC",
    condition: { keyTypeStat: 12 },
  };
  let data = await getBackInfo(params);
  setStatus(data);
};

const FormSchema = Yup.object().shape({
  nameStaff: Yup.string().required("Seleccione un empleado"),
  period: Yup.number()
    .required("Ingrese un periodo")
    .min(1, "Periodo invalido")
    .max(24, "Periodo debe ser menor a 25"),
  year: Yup.string().required("Seleccione un año"),
});

const date = new Date().getFullYear();

export const ModificarPagoTimbrado = ({ tempData = null, setModificar }) => {
  const [permissions, setPermissions] = useState("");
  const [loading, setLoading] = useState(true)
  GetPermissions(setPermissions, setLoading);

  const [modalTabla, setModalTabla] = useState(false);
  const [collapseTable, setcollapseTable] = useState(false);
  const [showTable, setShowTable] = useState(false);
  const [collapseForm, setcollapseForm] = useState(false);
  const [showForm, setshowForm] = useState(false);
  const [titleForm, setTitleForm] = useState("");
  const [paramsTabla, setParamsTabla] = useState("");
  const [dataForEdit, setDataForEdit] = useState(null);
  const [unders, setUnders] = useState([]);
  const [status, setStatus] = useState([]);
  const [count, setCount] = useState(null)
  const [defaultForm, setDefaultForm] = useState({
    idStaff: "",
    nameStaff: "",
    period: "",
    year: new Date(),
  })

  useEffect(() => {
    if (tempData !== null && !loading) {
      setDefaultForm({
        idStaff: tempData.idStaff,
        nameStaff: `${tempData.staffNumber} - ${tempData.nameStaff}`,
        period: tempData.period,
        year: tempData.year
      })
      showPays(tempData.idStaff, tempData.period, tempData.year);
    }
  }, [tempData, loading])

  useEffect(() => {
    if (tempData !== null && count !== null && !loading) {
      if (count === 0) {
        toast(
          <Notification
            type="warning"
            backMessage="El empleado no cuenta con pagos"
          />,
          { closeButton: false }
        );
        setModificar(false)
      }
    }
  }, [tempData, loading, count])

  const { handleSubmit, values, handleBlur, errors, touched, setFieldValue, resetForm } =
    useFormik({
      initialValues: defaultForm,
      onSubmit: (values) => {
        showPays();
      },
      validationSchema: FormSchema,
      enableReinitialize: true,
    });

  useEffect(() => {
    getUnders(setUnders);
    /* getStatus(setStatus); */
  }, []);

  const onChange = (e) => {
    setFieldValue(e.target.name, e.target.value);
    if (collapseTable) {
      setcollapseTable(false);
      setcollapseForm(false);
    }
  };

  /* Limpiar el empleado seleccionado */
  function clearStaff() {
    setFieldValue("idStaff", "");
    setFieldValue("nameStaff", "");
    if (collapseTable) {
      setcollapseTable(false);
      setcollapseForm(false);
    }
  }

  /* Funcion que se envia al modal al seleccionar un empleado */
  const selectEmpleado = (el) => {
    setFieldValue("idStaff", el[0]);
    setFieldValue("nameStaff", `${el[1]} - ${el[2]}`);
    setModalTabla(false);
  };

  /* Muestra tabla con pagos */
  const showPays = (idStaff = values.idStaff, period = values.period, year = values.year) => {
    setParamsTabla({
      ...parametrosTP,
      conditions: `A.enabled = 1 AND A.idStaff=${idStaff} AND A.period=${period} AND A.year=${new Date(year).getFullYear()}`,
    });
    setShowTable(true);
    setcollapseTable(true);
  };

  /* Muestra el formulario para agregar un pago*/
  const openForm = () => {
    setDataForEdit(null);
    setTitleForm("Agregar pago timbrado");
    setcollapseForm(true);
    setshowForm(true);
  };

  /* Muestra el formulario para editar un pago*/
  const getForEdit = (el) => {
    if (!showForm) {
      setTitleForm("Modificar pago timbrado");
      setDataForEdit({
        idPayslipstamp: el[0],
        idStaff: el[9],
        idUnder: el[4],
        period: el[5],
        year: new Date().setFullYear(el[6]),
        amount: el[3],
      });
      setcollapseForm(true);
      setshowForm(true);
    } else {
      toast(
        <Notification
          type="warning"
          backMessage="Guarde sus cambios antes de continuar"
        />,
        { closeButton: false }
      );
    }
  };

  return (
    <Widget className="widget-p-md">
      {loading ? (
        <div className="my-5 text-center">
          <Spinner />
        </div>
      ) : (
        <>
          <div className="text-center text-md-left mb-3">
            <h3>Modificacion de pagos timbrados</h3>
          </div>

          <form onSubmit={handleSubmit}>
            <Row>
              <Col xs={12}>
                <div className="form-group position-relative">
                  <label htmlFor="nameStaff">
                    Empleado <span className="text-danger">*</span>
                  </label>
                  <div className="input-group">
                    <input
                      name="nameStaff"
                      onChange={onChange}
                      onBlur={handleBlur}
                      className={
                        "form-control" +
                        (errors.nameStaff && touched.nameStaff ? " is-invalid" : "")
                      }
                      value={values.nameStaff}
                      readOnly
                      onClick={() => setModalTabla(true)}
                    />
                    <div className="input-group-append">
                      <button
                        className="btn btn-secondary"
                        style={{ zIndex: "auto" }}
                        type="button"
                        disabled={tempData !== null}
                        onClick={() => setModalTabla(true)}
                      >
                        <i className="fa fa-search" />
                      </button>
                    </div>
                  </div>
                  {values.nameStaff !== "" && (
                    <CancelOutlined className={s.btnClear} onClick={clearStaff} style={{zIndex: 10}} />
                  )}
                  {touched.nameStaff && errors.nameStaff && (
                    <span style={{ color: "red" }}>{errors.nameStaff}</span>
                  )}
                </div>
              </Col>
              <Col xs={12} md={12} lg={6}>
                <SelectTypeHeadSingle
                  label="Periodo"
                  isRequired={true}
                  optionsArray={PERIOD}
                  inputName="period"
                  onChangeMethod={onChange}
                  onBlurMethod={handleBlur}
                  touched={touched.period}
                  errors={errors.period}
                  optionValue="value"
                  optionName="label"
                  value={values.period}
                  isDisabled={tempData !== null}
                  defaultOption="Seleccione un periodo"
                />

              </Col>
              <Col xs={12} md={12} lg={6}>
                <div className="form-group">
                  <label htmlFor={values.year}>
                    {" "}
                    Año <span className="text-danger"> * </span>{" "}
                  </label>
                  <DatePicker
                    minDate={new Date(`${date - 1}`)}
                    selected={values.year}
                    onChange={(date) =>
                      onChange({ target: { name: "year", value: date } })
                    }
                    disabled={tempData !== null}
                    showYearPicker
                    dateFormat="yyyy"
                    yearItemNumber={6}
                    className={
                      "form-control" +
                      (errors.year && touched.year ? " is-invalid" : "")
                    }
                  />
                  {touched.year && errors.year && (
                    <span style={{ color: "red" }}>{errors.year}</span>
                  )}
                </div>
              </Col>
            </Row>
            <div className="text-center my-4">
              {tempData && (
                <Button color="danger" className="mx-1" onClick={() => setModificar(false)}>
                  Regresar
                </Button>
              )}

              <Button color="success" className="mx-1" type="submit">
                Consultar pagos
              </Button>
            </div>
          </form>

          <Collapse
            isOpen={collapseTable}
            className="mt-4 pt-3"
            onExited={() => {
              setShowTable(false);
            }}
          >
            {showTable && (
              <>
                <TableComponentOnePage
                  cabecerasTabla={cabecerasTP}
                  filtro={filtroTP}
                  editar={getForEdit}
                  parametros={paramsTabla}
                  limpia={() => { setcollapseForm(false) }}
                  permissions={permissions}
                  setCount={setCount}
                />
                <Row>
                  <Col xs={12} sm={6}>
                    <h5 className="text-center text-sm-left">{titleForm}</h5>
                  </Col>
                  {permissions.INS && <Col xs={12} sm={6}>
                    <div className="text-center text-sm-right">
                      {tempData && (
                        <Button color="danger" className="mx-1" onClick={() => setModificar(false)}>
                          Regresar
                        </Button>
                      )}
                      <Button
                        color="add"
                        onClick={() => openForm()}
                        disabled={showForm}
                      >
                        Agregar
                      </Button>
                    </div>
                  </Col>}
                </Row>
              </>
            )}
          </Collapse>

          {/* {showForm && <FormMosidicarPagoTimbrado idStaff={values.idStaff} setshowForm={setshowForm} />} */}
          <Collapse
            isOpen={collapseForm}
            onExited={() => {
              setshowForm(false);
              setTitleForm("");
            }}
          >
            {showForm && (
              <FormMosidicarPagoTimbrado
                idStaff={values.idStaff}
                setcollapseForm={setcollapseForm}
                data={dataForEdit}
                unders={unders}
                status={status}
                updateTable={showPays}
              />
            )}
          </Collapse>

          <ModalTableVer
            modal={modalTabla}
            setModal={setModalTabla}
            title="Empleados"
            cabeceras={cabecerasModalTabla}
            filtro={filtroModalTabla}
            parametros={paramsModalTabla}
            selectRegistro={selectEmpleado}
            backdrop
            keyboard
            btnClear
          />
        </>
      )}
    </Widget>
  );
};
export default ModificarPagoTimbrado;

import { Document, Page, Text, View, StyleSheet } from "@react-pdf/renderer";
import { styles } from "../../../../../../components/Pdf/pdfGeneral/EstiloPdf";

import HeaderReport from "../../../../../../components/Pdf/Header";

/**
 * Estilo que se repite en todas los headers de la tabla
 */
const styleHeadForAll = {
    fontFamily: 'Helvetica-Bold',
    paddingVertical: 3,
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "#646464",
    color: 'white',
    textAlign: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 9,
    borderBottom: 0
}

const styleCellForAll = {
    paddingVertical: 3,
    borderStyle: "solid",
    borderWidth: 1,
    borderTopWidth: 0,
    fontSize: 8
}

/**
 * Estilos del pdf
 */
const s = StyleSheet.create({
    body: {
        padding: 10
    },
    subTitle: { //SUBTITULOS
        width: "100%",
        textAlign: 'center',
        marginBottom: 10,
        marginLeft: 10,
        fontSize: 12
    },
    f6: {
        fontSize: 6
    },
    f5: {
        fontSize: 5
    },
    f8: {
        fontSize: 8
    },
    f10: {
        fontSize: 10
    },
    thIn: {
        ...styleHeadForAll
    },
    th: {
        ...styleHeadForAll,
        borderLeftWidth: 0
    },
    w5: {
        width: "5%"
    },
    w10: {
        width: "10%"
    },
    w20: {
        width: "20%"
    },
    w18: {
        width: "18%"
    },
    w25: {
        width: "25%"
    },
    w30: {
        width: "30%"
    },
    w33: {
        width: "33%"
    },
    w34: {
        width: "34%"
    },
    w40: {
        width: "40%"
    },
    w45: {
        width: "45%"
    },
    w50: {
        width: "50%"
    },
    w60: {
        width: "60%"
    },
    w70: {
        width: "70%"
    },
    w90: {
        width: "90%"
    },
    w100: {
        width: "100%"
    },
    mt2: {
        marginTop: 5
    },
    mt4: {
        marginTop: 40
    },
    pt1: {
        paddingTop: 10
    },
    px2: {
        paddingHorizontal: 10
    },
    px1: {
        paddingHorizontal: 5
    },
    bt0: {
        borderTopWidth: 0
    },
    bt1: {
        borderTopWidth: 1
    },
    center: {
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
    },
    textBold: {
        fontFamily: 'Helvetica-Bold'
    },
    textRight: {
        textAlign: "right"
    },
    tdIn: { //Cell CC
        ...styleCellForAll
    },
    td: { //Cell Concepto
        ...styleCellForAll,
        borderLeftWidth: 0
    },
    td0: { //Cell Concepto
        ...styleCellForAll,
        borderLeftWidth: 0,
        borderRightWidthWidth: 0
    },
    red: {
        backgroundColor: "red",
    },
    green: {
        backgroundColor: "green",
    },
    gray: {
        backgroundColor: "gray",
    }
})


export default function exportPDFReport54(report, model, title, fileNo) {
    const ori = model.orientation === "h" ? "landscape" : "portrait";

    return {
        report: (
            <>
                <Document>
                    <Page style={s.body} orientation={ori}>
                        <HeaderReport fixed={true} />
                        <View style={styles.table}>
                            <View style={styles.tableRow}>
                                <View style={[s.thIn, s.w100]}>
                                    <Text>
                                        Fiscalia General de Justicia del Estado de México
                                    </Text>
                                </View>
                            </View>
                            <View style={styles.tableRow}>
                                <View style={[s.thIn, s.w100]}>
                                    <Text>
                                        Presupuesto de Egresos para el Ejercicio Fiscal {title}
                                    </Text>
                                </View>
                            </View>
                            <View style={styles.tableRow}>
                                <View style={[s.thIn, s.w100]}>
                                    <Text>
                                        Programas y Proyectos
                                    </Text>
                                </View>
                            </View>
                            {report.map(row => (
                                <>
                                    <View style={styles.tableRow}>
                                        <View style={[s.tdIn, s.w100, s.center]}>
                                            <Text>
                                                {row.nameStateFund}
                                            </Text>
                                        </View>
                                    </View>
                                </>
                            ))}
                        </View>
                    </Page>
                </Document>
            </>)
    }
}